import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../../controllers/enum/EnumController";
import EnumBaseReadDto from "../../../../../model/sifarnik/EnumBaseReadDto";
import QueryKeys from "../../../QueryKeys";

export function useTipKategorijePopustaList() {
  const { axiosGetTipKategorijePopustaList } = EnumController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.TIP_KATEGORIJE_POPUSTA],
    queryFn: () => axiosGetTipKategorijePopustaList(),
  });

  const tipKategorijePopustaList: Array<EnumBaseReadDto> = data?.data?.data;

  return { tipKategorijePopustaList, refetch, isLoading };
}
