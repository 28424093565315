import { useQuery } from "@tanstack/react-query";
import { PacijentController } from "../../../../controllers/pacijent/PacijentController";
import QueryKeys from "../../QueryKeys";

export function usePacijent(pacijentID?: number) {
  const { axiosGetPacijentById } = PacijentController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.PACIJENT_LIST, pacijentID],
    queryFn: () => axiosGetPacijentById(pacijentID!),
    enabled: !!pacijentID,
  });

  const pacijent = data?.data?.data;

  return { pacijent, isLoading };
}
