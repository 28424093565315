import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import actionsDodavanjePostojecegRadnikaUstanova from "../../../../infrastructure/system/hooks/dialog-dodavanje-postojeceg-radnika-ustanova-reducer/actionsDodavanjePostojecegRadnikaUstanova";
import RadnikUstanovaReadDto from "../../../../model/radnik/RadnikUstanovaReadDto";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import UstanovaRadniciLogical from "./UstanovaRadnikListLogical";
import DialogDodavanjePostojecegRadnika from "./dialog/DialogDodavanjePostojecegRadnika";

interface UstanovaViewType {
  ustanovaId?: number;
  ustanova?: UstanovaReadDto;
  isDisabled: boolean;
}
export default function UstanovaRadniciView(props: UstanovaViewType) {
  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();
  const { ustanovaId, ustanova, isDisabled } = props;
  const { tableRows, openRadnik, radniciList, radnikListLoading, searchRadnikAdd, searchString, onRadnikAdd, state, dispatch } = UstanovaRadniciLogical({
    ustanovaId: ustanovaId,
    ustanova: ustanova,
  });
  const leftContentsToolbar = () => (
    <div className="flex justify-content-end">
      <Button label={Labels.BUTTON_ADD_RADNIK} tooltip={Labels.RADNIK_TITLE_DIALOG_CREATE} icon="pi pi-plus" className="p-button-success mr-2" onClick={() => openRadnik(EntityOperation.CREATE)} />
      {ustanovaId && (
        <Button
          label={Labels.BUTTON_ADD_RADNIK_OTHER}
          tooltip={Labels.RADNIK_TITLE_DIALOG_CREATE}
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={() => dispatch({ type: actionsDodavanjePostojecegRadnikaUstanova.OPEN_DIALOG })}
        />
      )}
    </div>
  );

  const buttonListTemplate = (rowRadnikUstanova: RadnikUstanovaReadDto) => {
    return (
      <div className="flex justify-content-end layout-generic-content-list">
        <Button
          type="button"
          tooltip={Labels.RADNIK_TITLE_DIALOG_UPDATE}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowRadnikUstanova && openRadnik(EntityOperation.UPDATE, rowRadnikUstanova.id)}
        />
        <Button
          type="button"
          tooltip={Labels.RADNIK_TITLE_DIALOG_DELETE}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowRadnikUstanova && openRadnik(EntityOperation.DELETE, rowRadnikUstanova.id)}
        />
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.RADNIK_IME, filter: true, sortrable: true },
    { columnName: Labels.RADNIK_PREZIME, filter: true, sortrable: true },
    { columnName: Labels.RADNIK_USERNAME, filter: true, sortrable: true },
    { columnName: Labels.RADNIK_EMAIL, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        {!isDisabled && <Toolbar left={leftContentsToolbar} />}
        {radnikListLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={!isDisabled} />
        ) : (
          <DataTable
            value={radniciList}
            paginator
            filterDisplay="row"
            rows={tableRows}
            paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"ime"} header={Labels.RADNIK_IME} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"prezime"} header={Labels.RADNIK_PREZIME} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"username"} header={Labels.RADNIK_USERNAME} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"email"} header={Labels.RADNIK_EMAIL} className="wrap-line-anywhere" sortable filter filterMatchMode="contains" showFilterMenu={false} />
            {!isDisabled && <Column body={buttonListTemplate} />}
          </DataTable>
        )}
      </Panel>
      <DialogDodavanjePostojecegRadnika state={state} dispatch={dispatch} searchRadnikAdd={searchRadnikAdd} searchString={searchString} onRadnikAdd={onRadnikAdd} isDisabled={isDisabled} />
    </div>
  );
}
