import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../../../Store";
import Endpoint from "../../../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../../../infrastructure/system/Utils";
import SearchBaseDto from "../../../../model/SearchBaseDto";
import PoljeTipEnumStavkaReadDto from "../../../../model/poljeTip/PoljeTipEnumStavkaReadDto";
import PoljeTipPublicCopyDto from "../../../../model/poljeTip/PoljeTipPublicCopyDto";
import PoljeTipReadDto from "../../../../model/poljeTip/PoljeTipReadDto";

interface RepositoryPoljeTipControllerType {
  axiosSearchRepositoryPoljeTip: (poljeTipSearchData: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosReadRepositoryPoljeTip: (poljeTipId: number) => Promise<AxiosResponse<any>>;
  axiosGetRepositoryPoljeTipEnumStavkaList: (poljeTipId: number) => Promise<AxiosResponse<any>>;
  axiosCreateRepositoryPoljeTip: (poljeTipCreate: PoljeTipReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteRepositoryPoljeTip: (poljeTipCreate: PoljeTipReadDto) => Promise<AxiosResponse<any>>;
  axiosUpdateRepositoryPoljeTip: (poljeTipCreate: PoljeTipReadDto) => Promise<AxiosResponse<any>>;
  axiosReadRepositoryPoljeTipEnumStavka: (poljeTipId: number, poljeTipEnumStavkaId: number) => Promise<AxiosResponse<any>>;
  axiosGetRepositoryPoljeTip: (poljeTipId: number) => Promise<AxiosResponse<any>>;
  axiosCreateRepositoryPoljeTipEnumStavka: (poljeTipId: number, poljeTipEnumStavkaCreate: PoljeTipEnumStavkaReadDto) => Promise<AxiosResponse<any>>;
  axiosUpdateRepositoryPoljeTipEnumStavka: (poljeTipId: number, poljeTipEnumStavkaChange: PoljeTipEnumStavkaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteRepositoryPoljeTipEnumStavka: (poljeTipId: number, poljeTipEnumStavkaId: number) => Promise<AxiosResponse<any>>;
  axiosPublicSearchRepositoryPoljeTip: (poljeTipSearchData: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosPublicCopyRepositoryPoljeTip: (poljeTipPublicCopy: PoljeTipPublicCopyDto) => Promise<AxiosResponse<any>>;
}

export function RepositoryPoljeTipController(): RepositoryPoljeTipControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchRepositoryPoljeTip = (poljeTipSearchData: SearchBaseDto) => axios.get(Endpoint.REPOSITORY_POLJE_TIP_SEARCH, axiosConfig(authData!.token, poljeTipSearchData));
  //TODO - pretaziti samo public polja_tip i pozvati WS za kopiranje na core
  const axiosPublicSearchRepositoryPoljeTip = (poljeTipSearchData: SearchBaseDto) => axios.get(Endpoint.REPOSITORY_POLJE_TIP_PUBLIC_SEARCH, axiosConfig(authData!.token, poljeTipSearchData));

  const axiosReadRepositoryPoljeTip = (poljeTipId: number) => axios.get(`${Endpoint.REPOSITORY_POLJE_TIP_LIST}/${poljeTipId}`, axiosConfig(authData!.token));

  const axiosCreateRepositoryPoljeTip = (poljeTipCreate: PoljeTipReadDto) => axios.post(Endpoint.REPOSITORY_POLJE_TIP_LIST, poljeTipCreate ? poljeTipCreate : {}, axiosConfig(authData!.token));

  const axiosDeleteRepositoryPoljeTip = (poljeTipChange: PoljeTipReadDto) => axios.delete(`${Endpoint.REPOSITORY_POLJE_TIP_LIST}/${poljeTipChange!.id}`, axiosConfig(authData!.token));

  const axiosUpdateRepositoryPoljeTip = (poljeTipChange: PoljeTipReadDto) => axios.put(`${Endpoint.REPOSITORY_POLJE_TIP_LIST}/${poljeTipChange.id}`, poljeTipChange, axiosConfig(authData!.token));

  const axiosGetRepositoryPoljeTipEnumStavkaList = (poljeTipId: number) => axios.get(`${Endpoint.REPOSITORY_POLJE_TIP_LIST}/${poljeTipId}/polje-tip-enum-stavka-list`, axiosConfig(authData!.token));

  const axiosReadRepositoryPoljeTipEnumStavka = (poljeTipId: number, poljeTipEnumStavkaId: number) =>
    axios.get(`${Endpoint.REPOSITORY_POLJE_TIP_LIST}/${poljeTipId}/polje-tip-enum-stavka-list/${poljeTipEnumStavkaId}`, axiosConfig(authData!.token));

  const axiosGetRepositoryPoljeTip = (poljeTipId: number) => axios.get(`${Endpoint.REPOSITORY_POLJE_TIP_LIST}/${poljeTipId}`, axiosConfig(authData!.token));

  const axiosCreateRepositoryPoljeTipEnumStavka = (poljeTipId: number, poljeTipEnumStavkaCreate: PoljeTipEnumStavkaReadDto) =>
    axios.post(`${Endpoint.REPOSITORY_POLJE_TIP_LIST}/${poljeTipId}/polje-tip-enum-stavka-list`, poljeTipEnumStavkaCreate ? poljeTipEnumStavkaCreate : {}, axiosConfig(authData!.token));

  const axiosUpdateRepositoryPoljeTipEnumStavka = (poljeTipId: number, poljeTipEnumStavkaChange: PoljeTipEnumStavkaReadDto) =>
    axios.put(`${Endpoint.REPOSITORY_POLJE_TIP_LIST}/${poljeTipId}/polje-tip-enum-stavka-list/${poljeTipEnumStavkaChange!.id}`, poljeTipEnumStavkaChange, axiosConfig(authData!.token));

  const axiosDeleteRepositoryPoljeTipEnumStavka = (poljeTipId: number, poljeTipEnumStavkaId: number) =>
    axios.delete(`${Endpoint.REPOSITORY_POLJE_TIP_LIST}/${poljeTipId}/polje-tip-enum-stavka-list/${poljeTipEnumStavkaId}`, axiosConfig(authData!.token));

  const axiosPublicCopyRepositoryPoljeTip = (poljeTipPublicCopy: PoljeTipPublicCopyDto) =>
    axios.post(`${Endpoint.REPOSITORY_POLJE_TIP_LIST}${Endpoint.COPY_PUBLIC_SUFIX}`, poljeTipPublicCopy, axiosConfig(authData!.token));

  return {
    axiosSearchRepositoryPoljeTip,
    axiosReadRepositoryPoljeTip,
    axiosGetRepositoryPoljeTipEnumStavkaList,
    axiosCreateRepositoryPoljeTip,
    axiosDeleteRepositoryPoljeTip,
    axiosUpdateRepositoryPoljeTip,
    axiosReadRepositoryPoljeTipEnumStavka,
    axiosGetRepositoryPoljeTip,
    axiosCreateRepositoryPoljeTipEnumStavka,
    axiosUpdateRepositoryPoljeTipEnumStavka,
    axiosDeleteRepositoryPoljeTipEnumStavka,
    axiosPublicSearchRepositoryPoljeTip,
    axiosPublicCopyRepositoryPoljeTip,
  };
}
