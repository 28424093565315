import { useLabels } from "../../../../Store";
import DialogComponent from "../../../dialog-component/DialogComponent";

interface DialogFaktorRizikaType {
  visible: boolean;
  cancel: () => void;
  confirm: () => void;
}

export default function DialogFaktorRizika(props: DialogFaktorRizikaType) {
  const { visible, cancel, confirm } = props;
  const Labels = useLabels();

  return <DialogComponent header={""} visible={visible} onHide={cancel} onReject={cancel} content={Labels.FAKTOR_RIZIKA_DELETE_DIALOG_MESSAGE} onAccept={confirm} />;
}
