import { Button } from "primereact/button";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import EnumPol from "../../../../infrastructure/system/EnumPol";
import KontrolaPravaPristupaService from "../../../../infrastructure/system/KontrolaPravaPristupaService";
import { tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import KontaktPaketReadDto from "../../../../model/kontakt/KontaktPaketReadDto";
import KontaktUslugaReadDto from "../../../../model/kontakt/KontaktUslugaReadDto";
import KontaktUtrosakReadDto from "../../../../model/kontakt/KontaktUtrosakReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import SkeletonRectangles from "../../../app/skeleton/SkeletonRectangles";
import DialogComponent from "../../../dialog-component/DialogComponent";
import KontaktPaketLogical from "./KontaktPaketLogical";
import KontaktPaketDialog from "./dialog/KontaktPaketDialog";

interface KontaktPaketListProps {
  pacijent: PacijentReadDto;
  kontaktPaketList: Array<KontaktPaketReadDto> | undefined;
  setKontaktPaketList: React.Dispatch<React.SetStateAction<Array<KontaktPaketReadDto> | undefined>>;
  naplacen: boolean;
}

export default function KontaktPaketList(props: KontaktPaketListProps) {
  const { authData } = useContext(AppContext);
  const { pacijent, kontaktPaketList, setKontaktPaketList, naplacen } = props;
  const {
    dataLoading,
    state,
    dispatch,
    openDialog,
    closeDialog,
    loadUslugeProizvodi,
    kontaktPaketChange,
    setKontaktPaketChange,
    onCreate,
    onDelete,
    dialogAction,
    kontaktPaket,
    setKontaktPaket,
    onUpdate,
    isOpenConfirmationDialog,
    closeConfirmationDialog,
  } = KontaktPaketLogical(pacijent, kontaktPaketList, setKontaktPaketList);
  const { pristup } = useContext(AppContext);
  const Labels = useLabels();
  const { hasKontaktPaketCreate, hasKontaktPaketDelete, hasKontaktPaketUpdate } = KontrolaPravaPristupaService();

  return (
    <>
      <div className="p-grid p-dir-col layout-generic-content-list">
        {dataLoading ? (
          <SkeletonRectangles />
        ) : (
          <div className="p-col flex flex-column">
            <div className="box flex flex-row pb-3">
              <div className="col-9 p-0 font-bold align-self-center">{Labels.KONTAKT_PAKETI_PRUZENI_TOKOM_PREGLEDA}</div>
              <div className="col-3 p-0 flex justify-content-end">
                <Button
                  label={Labels.KONTAKT_PAKETI_NOVI_PAKET}
                  className="p-button-success"
                  onClick={() => openDialog(EntityOperation.CREATE, undefined)}
                  disabled={!pristup || !hasKontaktPaketCreate || naplacen}
                />
              </div>
            </div>

            <div className="flex flex-column">
              {kontaktPaketList &&
                kontaktPaketList?.map((paket: KontaktPaketReadDto) => {
                  return (
                    <div key={paket.id} className="flex flex-row flex-wrap justify-content-between align-items-start border-bottom-1 border-300 py-3">
                      <div className="col-9 p-0">
                        <div className="flex flex-row flex-wrap mb-1">
                          <div className="mr-2">{Labels.KONTAKT_PAKETI_LABEL_PAKET}:</div>
                          <div>
                            <span className="font-bold">{paket.paket.sifra + " " + paket.paket.naziv}</span>
                          </div>
                        </div>
                        <div className="flex flex-row flex-wrap mb-1">
                          <div className="mr-2">{Labels.KONTAKT_PAKETI_LABEL_USLUGE}:</div>
                          <div>{paket.kontaktUslugaList.map((item: KontaktUslugaReadDto, index: number) => (index ? ", " : "") + item.sifarnikUsluga.naziv)}</div>
                        </div>
                        <div className="flex flex-row flex-wrap mb-1">
                          <div className="mr-2">{Labels.KONTAKT_PAKETI_LABEL_UTROSCI}:</div>
                          <div>{paket.kontaktProizvodList.map((item: KontaktUtrosakReadDto, index: number) => (index ? ", " : "") + item.sifarnikProizvoda.naziv)}</div>
                        </div>
                        <div className="flex flex-row flex-wrap mb-1">
                          <div className="mr-2">
                            {paket.radnikKreirao.polTrenutni?.sifra === EnumPol.MUSKI
                              ? Labels.KONTAKT_PAKETI_LABEL_POSTAVIO_MUSKI
                              : paket.radnikKreirao.polTrenutni?.sifra === EnumPol.ZENSKI
                              ? Labels.KONTAKT_PAKETI_LABEL_POSTAVIO_ZENSKI
                              : Labels.KONTAKT_PAKETI_LABEL_POSTAVIO}
                            :
                          </div>
                          <div>{paket.radnikKreirao.ime + " " + paket.radnikKreirao.prezime}</div>
                        </div>
                      </div>
                      <div className="col-3 flex justify-content-end p-0">
                        <div className="flex flex-row h-2rem">
                          <Button
                            type="button"
                            tooltip={Labels.KONTAKT_PAKETI_LABEL_IZMENI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-warning mr-2 p-button-small-size"
                            icon="pi pi-pencil"
                            onClick={() => openDialog(EntityOperation.UPDATE, paket)}
                            disabled={(authData?.currentRadnik.id === paket.radnikKreirao.id ? false : !hasKontaktPaketUpdate) || naplacen}
                          />
                          <Button
                            type="button"
                            tooltip={Labels.KONTAKT_PAKETI_LABEL_OBRISI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-danger p-button-small-size"
                            icon="pi pi-trash"
                            onClick={() => paket && onDelete(paket)}
                            disabled={!pristup || (authData?.currentRadnik.id === paket.radnikKreirao.id ? false : !hasKontaktPaketDelete) || naplacen}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
      <KontaktPaketDialog
        isOpenDialog={state.isDialogOpen}
        closeDialog={closeDialog}
        aktivniPaketiList={state.aktivniPaketiList}
        dispatch={dispatch}
        loadUslugeProizvodi={loadUslugeProizvodi}
        proizvodi={state.proizvodi}
        usluge={state.usluge}
        kontaktPaketChange={kontaktPaketChange}
        setKontaktPaketChange={setKontaktPaketChange}
        onCreate={onCreate}
        loadingUslugeProizvodi={state.loadingUslugeProizvodi}
        dialogAction={dialogAction}
        kontaktPaket={kontaktPaket}
        setKontaktPaket={setKontaktPaket}
        onUpdate={onUpdate}
      />
      <DialogComponent
        header=""
        visible={isOpenConfirmationDialog}
        onHide={closeConfirmationDialog}
        content={<div className="text-xl font-semibold">{Labels.NO_PROIZVOD_USLUGA_PAKET_CENA_WARNING}</div>}
        footer={
          <div className="layout-pacijent-dosije">
            <Button
              label={Labels.BUTTON_CANCEL}
              className="p-button-outlined p-button-tertiary align-self-center"
              onClick={() => {
                closeConfirmationDialog();
              }}
            />
            <Button
              label={Labels.BUTTON_CONFIRM}
              className="p-button-success"
              onClick={dialogAction === EntityOperation.CREATE ? () => onCreate(true) : dialogAction === EntityOperation.UPDATE ? () => onUpdate(true) : undefined}
              disabled={!pristup}
            />
          </div>
        }
      />
    </>
  );
}
