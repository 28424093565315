import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import OrganizacionaJedinicaReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import OrganizacionaJedinicaListLogical from "./OrganizacionaJedinicaListLogical";

export default function OrganizacionaJedinicaListPage() {
  const {
    organizacionaJedinicaList,
    first,
    tableRows,
    changeOrganizacionaJedinicaSearchData,
    organizacionaJedinicaSearchData,
    fetchData,
    searchOrganizacionaJedinicaByEnter,
    onPageOrganizacionaJedinica,
    exportData,
    breadCrumbItems,
    orgJedLoading,
  } = OrganizacionaJedinicaListLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMini } = PageDropdownTemplate();

  const openOrgJed = (entityOperation: string, id?: number) => {
    const idOrgJed = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-organizaciona-jedinica/${idOrgJed}`, {
      state: { orgJedOperation: entityOperation },
    });
  };

  const crudBodyTemplate = (rowOrganizacionaJedinica: OrganizacionaJedinicaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowOrganizacionaJedinica && openOrgJed(EntityOperation.UPDATE, rowOrganizacionaJedinica.id)}
        />
        <Button
          type="button"
          tooltip={Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowOrganizacionaJedinica && openOrgJed(EntityOperation.DELETE, rowOrganizacionaJedinica.id)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_ORGANIZACIONA_JEDINICA}
          tooltip={Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openOrgJed(EntityOperation.CREATE);
          }}
          disabled={!pristup}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={organizacionaJedinicaSearchData?.searchString ?? ""}
            onChange={changeOrganizacionaJedinicaSearchData}
            onKeyPress={searchOrganizacionaJedinicaByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_ORGANIZACIONA_JEDINICA}</label>
          <Button tooltip={Labels.ORGANIZACIONA_JEDINICA_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={orgJedLoading} data={exportData} name={Labels.ORGANIZACIONA_JEDINICA_LIST} dt={dt} />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.ORGANIZACIONA_JEDINICA_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.ORGANIZACIONA_JEDINICA_TIP, filter: true, sortrable: true },
    { columnName: Labels.ORGANIZACIONA_JEDINCA_NADREDJENA, filter: true, sortrable: true },
    { columnName: Labels.ORGANIZACIONA_JEDINICA_ADRESA, filter: true, sortrable: true },
    { columnName: Labels.ORGANIZACIONA_JEDINICA_TELEFON, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {orgJedLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={organizacionaJedinicaList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMini as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageOrganizacionaJedinica(e.rows, e.first);
            }}
            exportFilename={Labels.ORGANIZACIONA_JEDINICA_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.ORGANIZACIONA_JEDINICA_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"organizacionaJedinicaTip.sifra"} header={Labels.ORGANIZACIONA_JEDINICA_TIP} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"organizacionaJedinicaNadredjena.naziv"} header={Labels.ORGANIZACIONA_JEDINCA_NADREDJENA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"adresa"} header={Labels.ORGANIZACIONA_JEDINICA_ADRESA} filter filterMatchMode="contains" showFilterMenu={false} className="wrap-line-anywhere" sortable />
            <Column field={"telefon"} header={Labels.ORGANIZACIONA_JEDINICA_TELEFON} filter filterMatchMode="contains" showFilterMenu={false} className="wrap-line-anywhere" sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
