import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { OrganizacionaJedinicaController } from "../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import OrganizacionaJedinicaReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import { AppContext, useLabels } from "./../../../Store";

interface OrganizacionaJedinicaListLogicalType {
  organizacionaJedinicaList: Array<OrganizacionaJedinicaReadDto>;
  first: number;
  tableRows: number;
  changeOrganizacionaJedinicaSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  organizacionaJedinicaSearchData: SearchBaseDto;
  fetchData: () => void;
  searchOrganizacionaJedinicaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  onPageOrganizacionaJedinica: (rows: number, first: number) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  orgJedLoading: boolean;
}

export default function OrganizacionaJedinicaListLogical(): OrganizacionaJedinicaListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [organizacionaJedinicaList, setOrganizacionaJedinicaList] = useState<Array<OrganizacionaJedinicaReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [organizacionaJedinicaSearchData, setOrganizacionaJedinicaSearchData] = useState<SearchBaseDto>({});
  const [orgJedLoading, setOrgJedLoading] = useState<boolean>(false);

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.ORGANIZACIONA_JEDINICA_LIST,
    },
  ];
  const { axiosSearchOrganizacionaJedinica } = OrganizacionaJedinicaController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setOrgJedLoading(true);
    axiosSearchOrganizacionaJedinica(organizacionaJedinicaSearchData)
      .then((res: any) => {
        setOrganizacionaJedinicaList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((organizacionaJedinica: OrganizacionaJedinicaReadDto) => {
          exportList.push({
            [Labels.ORGANIZACIONA_JEDINICA_TIP]: organizacionaJedinica.organizacionaJedinicaTip.sifra,
            [Labels.ORGANIZACIONA_JEDINICA_NAZIV]: organizacionaJedinica.naziv,
            [Labels.ORGANIZACIONA_JEDINICA_ADRESA]: organizacionaJedinica.adresa,
            [Labels.ORGANIZACIONA_JEDINICA_TELEFON]: organizacionaJedinica.telefon,
            [Labels.ORGANIZACIONA_JEDINCA_NADREDJENA]: organizacionaJedinica.organizacionaJedinicaNadredjena != null ? organizacionaJedinica.organizacionaJedinicaNadredjena.naziv : "",
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setOrgJedLoading, startTime);
      });
  };

  const changeOrganizacionaJedinicaSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setOrganizacionaJedinicaSearchData({
      ...organizacionaJedinicaSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchOrganizacionaJedinicaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageOrganizacionaJedinica = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    organizacionaJedinicaList,
    first,
    tableRows,
    changeOrganizacionaJedinicaSearchData,
    organizacionaJedinicaSearchData,
    fetchData,
    searchOrganizacionaJedinicaByEnter,
    onPageOrganizacionaJedinica,
    exportData,
    breadCrumbItems,
    orgJedLoading,
  };
}
