import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import DokumentKontaktCreateDto from "../../model/dokument/DokumentKontaktCreateDto";
import DokumentPacijentCreateDto from "../../model/dokument/DokumentPacijentCreateDto";

interface DokumentControllerType {
  axiosGetDokumentListForPacijent: (pacijentID: number) => Promise<AxiosResponse<any>>;
  axiosPostDokumentPacijent: (dokumentPacijent: DokumentPacijentCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteDokument: (dokumentID: number) => Promise<AxiosResponse<any>>;
  axiosGetDokumentListForKontakt: (pacijentID: number, kontaktID: number) => Promise<AxiosResponse<any>>;
  axiosPostDokumentKontakt: (dokumentKontakt: DokumentKontaktCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetDokument: (dokumentID: number) => Promise<AxiosResponse<any>>;
}

export function DokumentController(): DokumentControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetDokumentListForPacijent = (pacijentID: number) => {
    return axios.get(`${Endpoint.DOCUMENT}/pacijent/${pacijentID}`, axiosConfig(authData!.token));
  };

  const axiosPostDokumentPacijent = (body: DokumentPacijentCreateDto) => {
    return axios.post(`${Endpoint.DOCUMENT}/pacijent`, body, axiosConfig(authData!.token));
  };

  const axiosDeleteDokument = (dokumentID: number) => {
    return axios.delete(`${Endpoint.DOCUMENT}/${dokumentID}`, axiosConfig(authData!.token));
  };

  const axiosGetDokumentListForKontakt = (pacijentID: number, kontaktID: number) => {
    return axios.get(`${Endpoint.DOCUMENT}/pacijent/${pacijentID}/kontakt/${kontaktID}`, axiosConfig(authData!.token));
  };

  const axiosPostDokumentKontakt = (body: DokumentKontaktCreateDto) => {
    return axios.post(`${Endpoint.DOCUMENT}/pacijent/kontakt`, body, axiosConfig(authData!.token));
  };

  const axiosGetDokument = (dokumentID: number) => {
    return axios.get(`${Endpoint.DOCUMENT}/${dokumentID}`, axiosConfig(authData!.token));
  };

  return {
    axiosGetDokumentListForPacijent,
    axiosPostDokumentPacijent,
    axiosDeleteDokument,
    axiosGetDokumentListForKontakt,
    axiosPostDokumentKontakt,
    axiosGetDokument,
  };
}
