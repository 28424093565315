import ProizvodCenaCreateDto from "../../../../model/sifarnik-proizvoda/ProizvodCenaCreateDto";
import ProizvodCenaReadDto from "../../../../model/sifarnik-proizvoda/ProizvodCenaReadDto";
import ProizvodCenaUpdateDto from "../../../../model/sifarnik-proizvoda/ProizvodCenaUpdateDto";
import UslugaCenaReadDto from "../../../../model/sifarnik-usluga/UslugaCenaReadDto";
import UslugaCenaCreateDto from "../../../../model/sifarnik-usluga/UslugaCenaCreateDto";

export interface InitialStateSifarnikCenaType {
  isDialogOpen: boolean;
  operation: string;
  cena: ProizvodCenaCreateDto | ProizvodCenaReadDto | ProizvodCenaUpdateDto | UslugaCenaReadDto | UslugaCenaCreateDto | undefined;
}

const InitialStateSifarnikCena: InitialStateSifarnikCenaType = {
  isDialogOpen: false,
  operation: "",
  cena: undefined,
};

export default InitialStateSifarnikCena;
