import { Button } from "primereact/button";
import { useLabels } from "../../Store";
import Images from "../../infrastructure/system/Images";
import { InitialStateType } from "./hooks/initialState";
import UstanovaReadDto from "../../model/ustanova/UstanovaReadDto";
import { ReducerType } from "./hooks/reducer";
import { InicijalizacijaKoraci } from "../../model/InicijalizacijaKoraci";

interface InicijalizacijaAppMenuType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerType>;
  continueAction: () => void;
  ustanova: UstanovaReadDto;
}

export default function InicijalizacijaAppMenu({ state, continueAction, dispatch, ustanova }: InicijalizacijaAppMenuType) {
  const Labels = useLabels();

  const steps = [
    {
      label: Labels.LABEL_INICIJALIZACIJA_USTANOVA,
      step: InicijalizacijaKoraci.USTANOVA,
    },
    {
      label: Labels.LABEL_INICIJALIZACIJA_ORG_JEDINICE,
      step: InicijalizacijaKoraci.ORGANIZACIONE_JEDINICE,
    },
    {
      label: Labels.LABEL_INICIJALIZACIJA_RADNICI,
      step: InicijalizacijaKoraci.RADNICI,
    },
    {
      label: Labels.LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA,
      step: InicijalizacijaKoraci.TIPOVI_PREGLEDA,
    },
    {
      label: Labels.LABEL_INICIJALIZACIJA_USLUGE,
      step: InicijalizacijaKoraci.USLUGE,
    },
  ];

  return (
    <div className="flex justify-content-between sidebar-inicijalizacija p-4">
      <div>
        <img alt={Labels.HELIANT} src={Images.LOGO_HELIANT_BLUE} className="logo-image" />
      </div>
      <div className="all-steps text-lg font-bold">
        {steps.map((step) => (
          <div key={step.step} className={`flex align-content-center py-3 ${state?.step! < step.step ? "opacity-60" : ""}`}>
            <div className="step-circle bg-primary inline-block border-circle">{ustanova?.inicijalizacijaKorak?.id! > step.step && <i className="pi pi-check"></i>}</div>
            <span className="pl-4">{step.label}</span>
          </div>
        ))}
      </div>
      <div className="flex justify-content-between">
        <div
          className={`flex align-items-center cursor-pointer ${state?.step === 1 ? "opacity-60" : ""}`}
          onClick={() => {
            if (state?.step! > 1) {
              dispatch({ type: "decrement_step" });
            }
          }}
        >
          <Button icon="pi pi-arrow-left" size="small" rounded />
          <span className="font-bold pl-2">{Labels.LABEL_BUTTON_PRETHODNI}</span>
        </div>
        <div className="current-step">
          <div className="flex align-content-center py-3">
            <div className="step-circle bg-primary inline-block border-circle">{ustanova?.inicijalizacijaKorak?.id! > state.step! && <i className="pi pi-check"></i>}</div>
            <span className="pl-4">{steps.find((step) => step.step === state?.step!)?.label}</span>
          </div>
        </div>
        {state?.step! < 5 && (
          <div className={`flex align-items-center cursor-pointer ${state?.step === 5 ? "opacity-60" : ""}`} onClick={continueAction}>
            <span className="font-bold pr-2">{Labels.LABEL_BUTTON_SLEDECI}</span>
            <Button icon="pi pi-arrow-right" rounded />
          </div>
        )}
        {state?.step === 5 && (
          <div
            className="flex align-items-center cursor-pointer"
            onClick={() => {
              continueAction();
            }}
          >
            <span className="font-bold pr-2">{Labels.LABEL_BUTTON_ZAVRSI}</span>
            <Button icon="pi pi-check" size="small" rounded />
          </div>
        )}
      </div>
    </div>
  );
}
