import { useQuery } from "@tanstack/react-query";
import PartnerController from "../../../controllers/partner/PartnerController";
import QueryKeys from "../../../infrastructure/system/QueryKeys";

export function usePartnerList(searchString?: string) {
  const { axiosSearchPartneri } = PartnerController();

  const { data } = useQuery({
    queryKey: [QueryKeys.PARTNER_LIST, searchString],
    queryFn: () => axiosSearchPartneri({ searchString }),
  });

  return { partnerList: data?.data?.data ?? [] };
}
