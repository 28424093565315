import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { formatDate2 } from "../../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import CalendarComponent from "../../calendar-component/CalendarComponent";
import UstanovaIzmenaPlacanjeLogical from "./CrudPlacanjeLogical";
import { useLabels } from "../../../../Store";

export default function CrudPlacanje() {
  const {
    index,
    setIndex,
    breadCrumbItems,
    placanjeChange,
    placanjeLoading,
    onCancel,
    obracunatIznos,
    setPlacanjeChange,
    onCreate,
    placanjeOperation,
    onUpdate,
    onDelete,
    checkedObavezaIzmirena,
    setCheckedObavezaIzmirena,
    getLastDayOfMonth,
    placanjeObracunatiIznosLoading,
    datumRef,
  } = UstanovaIzmenaPlacanjeLogical();
  const isDisabled: boolean = placanjeOperation === EntityOperation.DELETE;

  const Labels = useLabels();

  const rightContentsToolbar = () => <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={onCancel} />;

  const leftContentsToolbar = () => (
    <>
      {placanjeOperation === EntityOperation.CREATE && <Button label={Labels.BUTTON_CREATE} tooltip={Labels.PLACANJE_TITLE_CREATE} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />}
      {placanjeOperation === EntityOperation.UPDATE && (
        <Button label={Labels.BUTTON_UPDATE} tooltip={Labels.PLACANJE_TITLE_DIALOG_UPDATE} className="p-button-warning mr-2" icon="pi pi-pencil" onClick={onUpdate} />
      )}
      {placanjeOperation === EntityOperation.DELETE && (
        <Button label={Labels.BUTTON_DELETE} tooltip={Labels.PLACANJE_TITLE_DIALOG_DELETE} className="p-button-danger mr-2" icon="pi pi-trash" onClick={onDelete} />
      )}
    </>
  );
  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.PLACANJE_LIST}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-3 sm:px-3">
                  <div>{Labels.OBRACUNSKI_PERIOD_OD}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {placanjeLoading ? (
                    <SkeletonInputItem />
                  ) : (
                    <CalendarComponent
                      disabled={isDisabled}
                      value={placanjeChange?.obracunskiPeriodOd}
                      onChange={(e: CalendarChangeEvent) => {
                        setPlacanjeChange({
                          ...placanjeChange!,
                          obracunskiPeriodOd: formatDate2(e.target.value !== null ? (e.target.value as Date) : undefined),
                          obracunskiPeriodDo: "",
                        });
                      }}
                    />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-3 sm:px-3">
                  <div>{Labels.OBRACUNSKI_PERIOD_DO}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {placanjeLoading ? (
                    <SkeletonInputItem />
                  ) : (
                    <CalendarComponent
                      disabled={isDisabled || placanjeChange?.obracunskiPeriodOd === undefined || placanjeChange?.obracunskiPeriodOd === ""}
                      value={placanjeChange?.obracunskiPeriodDo}
                      minDate={placanjeChange?.obracunskiPeriodOd !== undefined ? new Date(placanjeChange?.obracunskiPeriodOd) : undefined}
                      maxDate={placanjeChange?.obracunskiPeriodOd !== undefined && placanjeChange?.obracunskiPeriodOd !== "" ? getLastDayOfMonth(placanjeChange.obracunskiPeriodOd) : undefined}
                      viewDate={placanjeChange?.obracunskiPeriodOd !== undefined ? new Date(placanjeChange?.obracunskiPeriodOd) : undefined}
                      onChange={(e: CalendarChangeEvent) => {
                        setPlacanjeChange({
                          ...placanjeChange!,
                          obracunskiPeriodDo: formatDate2(e.target.value !== null ? (e.target.value as Date) : undefined),
                        });
                      }}
                    />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-3 sm:px-3">
                  <span>{Labels.OBRACUNATI_IZNOS_RSD}</span>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {placanjeLoading ? (
                    <SkeletonInputItem />
                  ) : (
                    <InputNumber disabled={true} value={obracunatIznos?.obracunatiIznos} mode="decimal" minFractionDigits={2} maxFractionDigits={2} locale="sr-Rs" />
                  )}
                </div>
                {placanjeObracunatiIznosLoading && <ProgressSpinner className="w-2rem h-2rem" strokeWidth="4" animationDuration="2s" />}
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-3 sm:px-3">
                  <div>{Labels.DATUM_PLACANJA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {placanjeLoading ? (
                    <SkeletonInputItem />
                  ) : (
                    <CalendarComponent
                      ref={datumRef}
                      disabled={isDisabled}
                      value={placanjeChange?.datumPlacanja}
                      onChange={(e: CalendarChangeEvent) => {
                        setPlacanjeChange({
                          ...placanjeChange!,
                          datumPlacanja: formatDate2(e.target.value !== null ? (e.target.value as Date) : undefined),
                        });
                        if (e.target.value) datumRef?.current?.hide();
                      }}
                    />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-3 sm:px-3">
                  <div>{Labels.UPLACEN_IZNOS_RSD}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {placanjeLoading ? (
                    <SkeletonInputItem />
                  ) : (
                    <InputNumber
                      disabled={isDisabled}
                      value={placanjeChange?.uplacenIznos ?? 0}
                      mode="decimal"
                      minFractionDigits={2}
                      locale="sr-Rs"
                      onChange={(e) => {
                        setPlacanjeChange({
                          ...placanjeChange!,
                          uplacenIznos: e.value ?? 0,
                        });
                      }}
                    />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-3 sm:px-3">
                  <div>{Labels.USTANOVA_PLACANJE_OBAVEZE_IZMIRENE}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  <Checkbox
                    checked={checkedObavezaIzmirena}
                    onChange={(e) => {
                      setCheckedObavezaIzmirena(e.checked);
                      setPlacanjeChange({
                        ...placanjeChange!,
                        obavezaIzmirena: e.checked ?? false,
                      });
                    }}
                  ></Checkbox>
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-3 sm:px-3">
                  <div>{Labels.NAPOMENA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {placanjeLoading ? (
                    <SkeletonInputItem />
                  ) : (
                    <InputTextarea
                      disabled={isDisabled}
                      value={placanjeChange?.napomena ?? ""}
                      rows={12}
                      cols={30}
                      autoResize
                      onChange={(e) =>
                        setPlacanjeChange({
                          ...placanjeChange!,
                          napomena: e.target.value !== null ? (e.target.value as string) : "",
                        })
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
