import axios, { AxiosResponse } from "axios";
import moment from "moment/moment";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { KontaktController } from "../../../../controllers/kontakt/KontaktController";
import { ReceptController } from "../../../../controllers/recept/ReceptController";
import { SifarnikJedinicaMereController } from "../../../../controllers/sifarnik-jedinica-mere/SifarnikJedinicaMereController";
import { SifarnikLekovaNaReceptController } from "../../../../controllers/sifarnik-lekova-na-recept/SifarnikLekovaNaReceptController";
import { SifarnikController } from "../../../../controllers/sifarnik/SifarnikController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import KontaktDijagnozaReadDto from "../../../../model/kontakt/KontaktDijagnozaReadDto";
import ReceptCreateDto from "../../../../model/kontakt/ReceptCreateDto";
import ReceptReadDto from "../../../../model/kontakt/ReceptReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import SifarnikJedinicaMereReadDto from "../../../../model/repository/jedinice-mere/SifarnikJedinicaMereReadDto";
import LrAtributReadDto from "../../../../model/sifarnik-lekova-na-recept/LrAtributReadDto";
import SifarnikLekovaReadDto from "../../../../model/sifarnik-lekova-na-recept/SifarnikLekovaReadDto";
import SifarnikMkbDto from "../../../../model/sifarnik/SifarnikMkbDto";

interface UseParamsType {
  id?: string;
}

interface KontaktTerapijeLogicalType {
  isOpenTerapijaDialog: boolean;
  closeTerapijaDialog: () => void;
  openTerapijaDialog: () => void;
  handleSubmit: () => void;
  kontaktDijagnozaList: Array<KontaktDijagnozaReadDto>;
  dataLoading: boolean;
  receptListLoading: boolean;
  terapijaChange: ReceptCreateDto | undefined;
  setTerapijaChange: any;
  deleteTerapija: (recept: ReceptReadDto) => void;
  previewTerapija: (recept: ReceptReadDto) => void;
  deleteTerapijaPreview: (recept: ReceptReadDto) => void;
  isPreviewOpen: boolean;
  closePreview: () => void;
  receptPreview: ReceptReadDto | undefined;
  lrAtributList: Array<LrAtributReadDto>;
  lekoviList: any;
  searchLekovi: (e: AutoCompleteCompleteEvent) => void;
  getAttributListByLekIdAndSifraDijagnoze: (lekId: number, sifraDijagnoze: string) => void;
  isAttributListLoading: boolean;
  jediniceMereList: Array<SifarnikJedinicaMereReadDto>;
  jedinicaMere: SifarnikJedinicaMereReadDto | undefined;
  getJedinicaMere: (jedinicaMereId: number) => void;
  isDeletePreviewOpen: boolean;
  printRecept: (receptId: number) => void;
}

export default function KontaktTerapijeLogical(
  pacijent: PacijentReadDto,
  receptList: Array<ReceptReadDto> | undefined,
  setReceptList: React.Dispatch<React.SetStateAction<Array<ReceptReadDto> | undefined>>
): KontaktTerapijeLogicalType {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const id = Number(useParams<keyof UseParamsType>()["id"]);
  const [isOpenTerapijaDialog, setIsOpenTerapijaDialog] = useState<boolean>(false);
  const [kontaktDijagnozaList, setKontaktDijagnozaList] = useState<Array<KontaktDijagnozaReadDto>>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [receptListLoading, setReceptListLoading] = useState<boolean>(false);
  const [terapijaChange, setTerapijaChange] = useState<ReceptCreateDto>();
  const [receptPreview, setReceptPreview] = useState<ReceptReadDto | undefined>(undefined);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [lrAtributList, setLrAtributList] = useState<Array<LrAtributReadDto>>([]);
  const [lekoviList, setLekoviList] = useState<SifarnikLekovaReadDto>();
  const [jediniceMereList, setJediniceMereList] = useState<Array<SifarnikJedinicaMereReadDto>>([]);
  const [jedinicaMere, setJedinicaMere] = useState<SifarnikJedinicaMereReadDto>();
  const [isAttributListLoading, setIsAttributListLoading] = useState<boolean>(false);
  const [isDeletePreviewOpen, setIsDeletePreviewOpen] = useState<boolean>(false);
  const postLogHighLevel = useLogHighLevel();
  const { axiosGetDijagnozaListByKontaktID } = KontaktController();
  const { axiosGetSifarnikMkbList } = SifarnikController();
  const { axiosDeleteRecept, axiosPostRecept, axiosGetReceptPrint, axiosGetReceptListByKontaktID, axiosDopuniTerapiju } = ReceptController();
  const { axiosGetLrAtributById, axiosSearchSifarnikLekovaNaReceptGlobal, axiosGetLrAtributListByLekIdAndSifraDijagnoze } = SifarnikLekovaNaReceptController();
  const { axiosGetSifarnikJedinicaMereListRepository, axiosGetJedinicaMereByIdRepository } = SifarnikJedinicaMereController();

  useEffectOnce(() => {
    if (!receptList) {
      fetchData();
    }
  });

  const fetchData = () => {
    if (id) {
      let startTime = moment(new Date());
      setReceptListLoading(true);
      axiosGetReceptListByKontaktID(id)
        .then(async (responseReceptList: any) => {
          const receptList = responseReceptList.data.data;
          if (receptList.length > 0) {
            const lekovi = receptList.map((recept: any) => recept.idRepositoryLek).reduce((f: any, s: any) => `${f},${s}`);
            const dijagnoze = receptList.map((recept: any) => recept.dijagnoza.sifarnikMkbId).reduce((f: any, s: any) => `${f},${s}`);
            const terapijaFillerSearchDto = { lekovi, dijagnoze };

            axiosDopuniTerapiju(terapijaFillerSearchDto)
              .then((res) => {
                const lekovi = res.data.data.lekovi;
                const dijagnoze = res.data.data.dijagnoze;
                const receptListFull = receptList.map((recept: ReceptReadDto) => ({
                  ...recept,
                  lek: lekovi.find((l: SifarnikLekovaReadDto) => l.id === recept.idRepositoryLek),
                  dijagnoza: { sifarnikMkb: dijagnoze.find((d: SifarnikMkbDto) => d.id === recept.dijagnoza.sifarnikMkbId) },
                }));
                setReceptList(receptListFull);
              })
              .catch((error) => {
                handleAxiosCallError(showMessage, error);
              });
          } else {
            setReceptList([]);
          }
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setReceptListLoading, startTime);
        });
    }
  };

  const closeTerapijaDialog = () => {
    setIsOpenTerapijaDialog(false);
    setTerapijaChange(undefined);
  };

  const openTerapijaDialog = () => {
    setIsOpenTerapijaDialog(true);
    setDataLoading(true);
    setTerapijaChange({
      ...terapijaChange!,
      stampaRecepta: true,
    });
    let startTime = moment(new Date());
    if (id) {
      axios
        .all([axiosGetDijagnozaListByKontaktID(id), axiosGetSifarnikJedinicaMereListRepository()])
        .then(
          axios.spread(async (responseTrenutneDijagnoze: any, responseJediniceMere: any) => {
            const trenutneList = responseTrenutneDijagnoze.data.data;
            await Promise.all(
              trenutneList.map(async (dijagnoza: KontaktDijagnozaReadDto) => {
                await axiosGetSifarnikMkbList(dijagnoza.sifarnikMkbId).then((responseSifarnikMkb: any) => {
                  dijagnoza.sifarnikMkb = responseSifarnikMkb.data.data;
                });
              })
            );
            setKontaktDijagnozaList(trenutneList);
            setJediniceMereList(responseJediniceMere.data.data);
          })
        )
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setDataLoading, startTime);
        });
    }
  };

  const handleSubmit = () => {
    if (terapijaChange) {
      axiosPostRecept(terapijaChange)
        .then((response: AxiosResponse) => {
          showMessage(MessageType.SUCCESS, Labels.KONTAKT_NOVA_TERAPIJA_CREATE);
          fetchData();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_1 +
              terapijaChange?.dijagnoza.id +
              Labels.LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_2 +
              terapijaChange?.tipTerapije?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_3 +
              terapijaChange?.lek?.naziv
          );
          if (terapijaChange?.stampaRecepta) printRecept(response.data.data.id);
          closeTerapijaDialog();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const deleteTerapijaPreview = async (recept: ReceptReadDto) => {
    if (recept.idRepositoryLrAtribut && !recept.lrAtribut) {
      await axiosGetLrAtributById(recept?.idRepositoryLrAtribut)
        .then((responseAtribut: any) => {
          recept.lrAtribut = responseAtribut.data.data;
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }

    if (recept.jedinicaMere && !recept.jedinicaMereFull) {
      await axiosGetJedinicaMereByIdRepository(recept?.jedinicaMere)
        .then((responseJedinicaMere: any) => {
          recept.jedinicaMereFull = responseJedinicaMere.data.data;
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    setIsDeletePreviewOpen(true);
    setReceptPreview(recept);
  };

  const deleteTerapija = (recept: ReceptReadDto) => {
    let startTime = moment(new Date());
    setReceptListLoading(true);
    let receptId = recept.id;
    setShowBlockUI(true);
    axiosDeleteRecept(recept.id)
      .then(() => {
        setReceptList(receptList?.filter((recept) => recept.id !== receptId));
        showMessage(MessageType.SUCCESS, Labels.KONTAKT_TERAPIJA_DELETE_SUCCESS);

        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_1 +
            recept.id +
            Labels.LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_2 +
            recept?.lek?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_3 +
            recept?.dijagnoza?.sifarnikMkb?.sifraBezTacke +
            " " +
            recept?.dijagnoza?.sifarnikMkb.nazivLatinski
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setReceptListLoading, startTime);
        closePreview();
        setShowBlockUI(false);
      });
  };

  const previewTerapija = async (recept: ReceptReadDto) => {
    if (recept.idRepositoryLrAtribut && !recept.lrAtribut) {
      await axiosGetLrAtributById(recept?.idRepositoryLrAtribut)
        .then((responseAtribut: any) => {
          recept.lrAtribut = responseAtribut.data.data;
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }

    if (recept.jedinicaMere && !recept.jedinicaMereFull) {
      await axiosGetJedinicaMereByIdRepository(recept?.jedinicaMere)
        .then((responseJedinicaMere: any) => {
          recept.jedinicaMereFull = responseJedinicaMere.data.data;
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    setIsPreviewOpen(true);
    setReceptPreview(recept);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setIsDeletePreviewOpen(false);
  };

  const searchLekovi = (e: AutoCompleteCompleteEvent) => {
    if (e.query.length > 2) {
      axiosSearchSifarnikLekovaNaReceptGlobal({ searchString: e.query })
        .then((res: any) => {
          setLekoviList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const getAttributListByLekIdAndSifraDijagnoze = (lekId: number, sifraDijagnoze: string) => {
    if (lekId) {
      let startTime = moment(new Date());
      setIsAttributListLoading(true);
      axiosGetLrAtributListByLekIdAndSifraDijagnoze(lekId, sifraDijagnoze)
        .then((res: any) => {
          setLrAtributList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setIsAttributListLoading, startTime);
        });
    }
  };

  const getJedinicaMere = (jedinicaMereId: number) => {
    if (jedinicaMereId) {
      axiosGetJedinicaMereByIdRepository(jedinicaMereId)
        .then((res: any) => {
          setJedinicaMere(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const printRecept = (receptId: number) => {
    setShowBlockUI(true);
    axiosGetReceptPrint(receptId)
      .then((response: AxiosResponse) => {
        const byteChars = atob(response.data.data);
        const dataArray = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
          dataArray[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(dataArray);
        const file = new Blob([byteArray], { type: "application/pdf" });
        const url = URL.createObjectURL(file);
        const w = window.open(url, "_blank");
        if (w) {
          w!.document.write(
            "<html><head><title>" +
              receptId +
              "</title></head><body>" +
              '<embed width="100%" height="100%" name="plugin" src="' +
              url +
              '" ' +
              'type="application/pdf" internalinstanceid="21"></body></html>'
          );
        } else {
          showMessage(MessageType.WARNING, Labels.KONTAKT_TERAPIJA_STAMPA_POPUP_MESSAGE);
        }
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return {
    isOpenTerapijaDialog,
    closeTerapijaDialog,
    openTerapijaDialog,
    handleSubmit,
    kontaktDijagnozaList,
    dataLoading,
    terapijaChange,
    setTerapijaChange,
    deleteTerapija,
    previewTerapija,
    isPreviewOpen,
    receptListLoading,
    closePreview,
    receptPreview,
    lrAtributList,
    lekoviList,
    searchLekovi,
    getAttributListByLekIdAndSifraDijagnoze,
    isAttributListLoading,
    jediniceMereList,
    jedinicaMere,
    getJedinicaMere,
    deleteTerapijaPreview,
    isDeletePreviewOpen,
    printRecept,
  };
}
