import PartnerCenaCreateDto from "../../../../model/partner/PartnerCenaCreateDto";

export interface InitialStatePartnerCenaType {
  isDialogOpen: boolean;
  operation: string;
  cena: PartnerCenaCreateDto | any;
}

const InitialStatePartnerCena: InitialStatePartnerCenaType = {
  isDialogOpen: false,
  operation: "",
  cena: undefined,
};

export default InitialStatePartnerCena;
