import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { StorageLimitController } from "../../../../controllers/storage-limit/StorageLimitController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import StorageLimitReadDto from "../../../../model/licenca/storage-limit/StorageLimitReadDto";

interface StorageLimitListLogicalType {
  slModul: any;
  storageLimitList: Array<StorageLimitReadDto>;
  firstModul: number;
  tableModulRows: number;
  onPageModul: (rows: number, first: number) => void;
  modulLoading: boolean;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  openStorageLimit: (entityOperation: string, id?: number) => void;
}

export default function StorageLimitListLogical(): StorageLimitListLogicalType {
  const { showMessage } = useContext(AppContext);
  const [storageLimitList, setStorageLimitList] = useState<Array<StorageLimitReadDto>>([]);
  const [firstModul, setFirstModul] = useState(0);
  const [tableModulRows, setTableModulRows] = useState(10);
  const slModul = useRef<any>(null);
  const { axiosSearchStorageLimitList } = StorageLimitController();
  const [modulLoading, setModulLoading] = useState(false);
  const navigate = useNavigate();
  const Labels = useLabels();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LICENCA_LIST,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const startTime = moment(new Date());
    setModulLoading(true);
    axiosSearchStorageLimitList({})
      .then(({ data: { data } }: { data: { data: Array<StorageLimitReadDto> } }) => {
        setStorageLimitList(data);
        setFirstModul(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, startTime);
      })
      .finally(() => {
        skeletonTimeout(setModulLoading, startTime);
      });
  };

  const onPageModul = (rows: number, first: number) => {
    setTableModulRows(rows);
    setFirstModul(first);
  };

  const openStorageLimit = (entityOperation: string, id?: number) => {
    const storageLimitId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-storage-limit/${storageLimitId}`, {
      state: { storageLimitOperation: entityOperation },
    });
  };

  return {
    slModul,
    storageLimitList,
    firstModul,
    tableModulRows,
    onPageModul,
    modulLoading,
    breadCrumbItems,
    openStorageLimit,
  };
}
