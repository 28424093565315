import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import { AppContext } from "../../../Store";
import EnumAplikativnaPrava from "../../../infrastructure/system/EnumAplikativnaPrava";
import EnumRola from "../../../infrastructure/system/EnumRola";
import RadnikUlogaReadDto from "../../../model/radnik/RadnikUlogaReadDto";

interface RequireRightType {
  allowedRoles?: EnumRola[];
  allowedRights?: EnumAplikativnaPrava[];
}

const RequireRight = ({ allowedRoles = [], allowedRights = [] }: RequireRightType) => {
  const { authData } = useContext(AppContext);
  const location = useLocation();

  const aplPravaList =
    authData?.currentRadnik.radnikUlogaList
      .filter((ru) => ru.uloga.ustanova.id === authData.currentRadnik.ustanovaPoslednjaIzabrana.id)
      .flatMap((filteredRU) => filteredRU.uloga.ulogaAplikativnoPravoList)
      .map((aplPravo) => aplPravo.aplikativnoPravo)
      .filter((obj, index, self) => index === self.findIndex((o) => o.id === obj.id)) ?? [];

  return authData?.currentRadnik.radnikUlogaList.map((radnikUloga: RadnikUlogaReadDto) => radnikUloga.uloga.rola).some((rola) => allowedRoles.some((allowedRola) => allowedRola === rola.sifra)) ||
    aplPravaList.some((aplPravo) => allowedRights.some((allowedRight) => allowedRight === aplPravo.sifra)) ? (
    <Outlet />
  ) : (
    <Navigate to={"/forbidden"} state={{ from: location }} replace />
  );
};

export default RequireRight;
