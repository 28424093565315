import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { Panel } from "primereact/panel";
import { useLabels } from "../../../Store";
import Images from "../../../infrastructure/system/Images";
import { tooltipOptionsRight } from "../../../infrastructure/system/Utils";
import ZakazivacReadDto from "../../../model/eksterni-zakazivac/ZakazivacReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import DialogComponent from "../../dialog-component/DialogComponent";
import EksterniZakazivaciLogical from "./EksterniZakazivaciLogical";

export default function EksterniZakazivaciView() {
  const {
    eksterniZakazivacRef,
    breadCrumbItems,
    zakazivaciLoading,
    eksterniZakazivaci,
    partnerList,
    setPartnerForZakazivac,
    showActionDialog,
    setShowActionDialog,
    openActionDialog,
    isButtonConnect,
    statusBodyTemplateValues,
    isConnectingExternalSchedulerOption,
    connectSchedulerAndProvider,
    disconnectSchedulerAndProvider,
  } = EksterniZakazivaciLogical();
  const Labels = useLabels();

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.LABEL_DOSTUPNI_ZAKAZIVACI },
    { columnName: Labels.LABEL_EKSTERNI_ZAKAZIVACI_PARTNERI },
    { columnName: Labels.LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE },
  ];

  const partnerBodyTemplate = (eksterniZakazivac: ZakazivacReadDto) => {
    return (
      <>
        <Dropdown
          options={partnerList}
          optionLabel="naziv"
          optionValue="id"
          value={eksterniZakazivac.partner?.id ?? undefined}
          onChange={(e) => {
            setPartnerForZakazivac(e.target.value, eksterniZakazivac.scheduler.id);
          }}
          emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
          placeholder={Labels.LABEL_PARTNERI_PLACEHOLDER}
          className="external-scheduler-dropdown-width"
        />
      </>
    );
  };

  const statusBodyTemplate = (eksterniZakazivac: ZakazivacReadDto) => {
    const zakazivacStatusClassName: string = statusBodyTemplateValues(eksterniZakazivac).zakazivacStatusClassName;
    const zakazivacStatusLabel: string = statusBodyTemplateValues(eksterniZakazivac).zakazivacStatusLabel;
    return <div className={`status-scheduler-badge ${zakazivacStatusClassName}`}>{zakazivacStatusLabel}</div>;
  };

  const buttonBodyTemplate = (eksterniZakazivac: ZakazivacReadDto) => {
    return (
      <div className="flex justify-content-end">
        {!isButtonConnect(eksterniZakazivac) && (
          <Button
            label={Labels.LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_DISCONNECT}
            disabled={eksterniZakazivac.partner === null}
            tooltip={Labels.LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_DISCONNECT}
            tooltipOptions={tooltipOptionsRight}
            className="p-button-info external-scheduler-button mr-2"
            icon={() => <Image className="mr-2" width={"16px"} src={Images.ICON_EKSTERNI_ZAKAZIVACI_UNLINK} />}
            outlined
            onClick={() => {
              openActionDialog(eksterniZakazivac);
            }}
          />
        )}
        {isButtonConnect(eksterniZakazivac) && (
          <Button
            label={Labels.LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_CONNECT}
            disabled={eksterniZakazivac.partner === null}
            type="button"
            tooltip={Labels.LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_CONNECT}
            tooltipOptions={tooltipOptionsRight}
            className="external-scheduler-button mr-2"
            icon="pi pi-link"
            onClick={() => {
              openActionDialog(eksterniZakazivac);
            }}
          />
        )}
      </div>
    );
  };

  const renderIconDisconnectButton = () => (
    <>
      <Image className="mr-2" width={"16px"} src={Images.ICON_EKSTERNI_ZAKAZIVACI_UNLINK} />
    </>
  );

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <div className="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-8 lg:m-auto">
          {zakazivaciLoading ? (
            <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={false} isVisibleButtonList />
          ) : (
            <DataTable ref={eksterniZakazivacRef} value={eksterniZakazivaci} emptyMessage={Labels.TABLE_EMPTY_MESSAGE} rowHover stripedRows>
              <Column className="w-4" header={Labels.LABEL_DOSTUPNI_ZAKAZIVACI} field={"scheduler.name"} />
              <Column className="w-4" header={Labels.LABEL_EKSTERNI_ZAKAZIVACI_PARTNERI} body={partnerBodyTemplate} />
              <Column className="w-3" header={Labels.LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE} body={statusBodyTemplate} />
              <Column body={buttonBodyTemplate} />
            </DataTable>
          )}
        </div>
      </Panel>
      <DialogComponent
        header={Labels.LABEL_EKSTERNI_ZAKAZIVACI_NEPOVRATNA_AKCIJA}
        content={
          <div className="text-center">
            <div className="text-lg font-bold mb-4">
              {isConnectingExternalSchedulerOption ? Labels.LABEL_EKSTERNI_ZAKAZIVACI_DA_LI_STE_SIGURNI_POVEZIVANJE : Labels.LABEL_EKSTERNI_ZAKAZIVACI_DA_LI_STE_SIGURNI_RAZVEZIVANJE}
            </div>
          </div>
        }
        visible={showActionDialog}
        onHide={() => setShowActionDialog(false)}
        footer={
          <div className="flex justify-content-center">
            <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-outlined mr-4" onClick={() => setShowActionDialog(false)} />
            <Button
              label={isConnectingExternalSchedulerOption ? Labels.LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_CONNECT : Labels.LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_DISCONNECT}
              tooltipOptions={tooltipOptionsRight}
              tooltip={isConnectingExternalSchedulerOption ? Labels.LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_CONNECT : Labels.LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_DISCONNECT}
              icon={isConnectingExternalSchedulerOption ? "pi pi-link" : renderIconDisconnectButton()}
              className={`${isConnectingExternalSchedulerOption ? "" : "p-button-info"} external-scheduler-button mr-2`}
              outlined={!isConnectingExternalSchedulerOption}
              onClick={() => {
                if (isConnectingExternalSchedulerOption) {
                  connectSchedulerAndProvider();
                } else {
                  disconnectSchedulerAndProvider();
                }
              }}
            />
          </div>
        }
      />
    </div>
  );
}
