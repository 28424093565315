import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import GenerisaniIzvestajSearchDto from "../../model/generisaniIzvestaj/GenerisaniIzvesajSearchDto";
import GenerisaniIzvestajCreateDto from "../../model/generisaniIzvestaj/GenerisaniIzvestajCreateDto";

interface GenerisaniIzvestajControllerType {
  axiosGetKategorijaWithStatistickiIzvestaji: () => Promise<AxiosResponse<any>>;
  axiosGetGenerisaniIzvestajList: (params?: { page: number; size: number; radnikID?: number; statistickiIzvestajID?: number }) => Promise<AxiosResponse<any>>;
  axiosGetParameters: (izvestajId: number) => Promise<AxiosResponse<any>>;
  axiosGetGenerisaniIzvestajiByStatistickiIzvestaj: (izvestajId: number) => Promise<AxiosResponse<any>>;
  axiosGetGenerisaniIzvestajByID: (generisaniIzvestajID: number) => Promise<AxiosResponse<any>>;
  axiosDeleteGenerisaniIzvestaj: (generisaniIzvestajID: number) => Promise<AxiosResponse<any>>;
  axiosGetGenerisaniIzvestajParameters: (generisaniIzvestajID: number) => Promise<AxiosResponse<any>>;
  axiosCreateGenerisaniIzvestaj: (genIzvestaj: GenerisaniIzvestajCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetGenerisaniIzvestajExport: (format: string, generisaniIzvestajID: number) => Promise<AxiosResponse<any>>;
  axiosGetGenerisaniIzvestajListCount: (params?: { radnikID?: number; statistickiIzvestajID?: number }) => Promise<AxiosResponse<any>>;
}

export default function GenerisaniIzvestajController(): GenerisaniIzvestajControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetKategorijaWithStatistickiIzvestaji = (generisaniIzvestajSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.KATEGORIJA_STATISTICKIH_IZVESTAJA, axiosConfig(authData!.token, generisaniIzvestajSearchData));
  };

  const axiosGetGenerisaniIzvestajList = (params?: GenerisaniIzvestajSearchDto) => {
    return axios.get(Endpoint.GENERISANI_IZVESTAJ_SEARCH, axiosConfig(authData!.token, params));
  };

  const axiosGetParameters = (izvestajId: number) => {
    return axios.get(`${Endpoint.IZVESTAJ_LIST}/${izvestajId}/${Endpoint.IZVESTAJ_PARAMETAR_LIST_END_URL}`, axiosConfig(authData!.token));
  };

  const axiosGetGenerisaniIzvestajiByStatistickiIzvestaj = (izvestajId: number) => {
    return axios.get(`${Endpoint.GENERISANI_IZVESTAJ_BY_STAT_IZVESTAJ}/${izvestajId}`, axiosConfig(authData!.token));
  };

  const axiosGetGenerisaniIzvestajByID = (generisaniIzvestajID: number) => {
    return axios.get(`${Endpoint.GENERISANI_IZVESTAJ_LIST}/${generisaniIzvestajID}`, axiosConfig(authData!.token));
  };

  const axiosDeleteGenerisaniIzvestaj = (generisaniIzvestajID: number) => {
    return axios.delete(`${Endpoint.GENERISANI_IZVESTAJ_LIST}/${generisaniIzvestajID}`, axiosConfig(authData!.token));
  };

  const axiosGetGenerisaniIzvestajExport = (format: string, generisaniIzvestajID: number) => {
    return axios.get(`${Endpoint.GENERISANI_IZVESTAJI_EXPORT}?format=${format}&generisaniIzvestajID=${generisaniIzvestajID}`, axiosConfig(authData!.token, {}, "arraybuffer"));
  };

  const axiosGetGenerisaniIzvestajParameters = (generisaniIzvestajID: number) => {
    return axios.get(`${Endpoint.GENERISANI_IZVESTAJ_PARAMETAR_LIST}/${generisaniIzvestajID}`, axiosConfig(authData!.token));
  };

  const axiosCreateGenerisaniIzvestaj = (genIzvestaj: GenerisaniIzvestajCreateDto) => {
    return axios.post(Endpoint.GENERISANJE_IZVESTAJA, genIzvestaj, axiosConfig(authData!.token));
  };

  const axiosGetGenerisaniIzvestajListCount = (params?: { radnikID?: number; statistickiIzvestajID?: number }) => {
    return axios.get(Endpoint.GENERISANI_IZVESTAJ_SEARCH_COUNT, axiosConfig(authData!.token, params));
  };

  return {
    axiosGetKategorijaWithStatistickiIzvestaji,
    axiosGetGenerisaniIzvestajList,
    axiosGetParameters,
    axiosGetGenerisaniIzvestajiByStatistickiIzvestaj,
    axiosGetGenerisaniIzvestajByID,
    axiosDeleteGenerisaniIzvestaj,
    axiosGetGenerisaniIzvestajParameters,
    axiosCreateGenerisaniIzvestaj,
    axiosGetGenerisaniIzvestajExport,
    axiosGetGenerisaniIzvestajListCount,
  };
}
