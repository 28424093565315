import axios from "axios";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import { EnumController } from "../../../controllers/enum/EnumController";
import { OrganizacionaJedinicaController } from "../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import ProtokolControler from "../../../controllers/protokol/ProtokolController";
import { UstanovaController } from "../../../controllers/ustanova/UstanovaController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../infrastructure/system/MessageType";
import RadnoVremeUtils from "../../../infrastructure/system/RadnoVremeUtils";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import OrganizacionaJedinicaRadniDanReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaRadniDanReadDto";
import OrganizacionaJedinicaReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import OrganizacionaJedinicaTipDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaTipDto";
import ProtokolReadDto from "../../../model/protokol/ProtokolReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import UstanovaGridRadniDanReadDto from "../../../model/ustanova/UstanovaGridRadniDanReadDto";
interface OrganizacionaJedinicaCrudLogicalType {
  orgJedOperation: string;
  isDisabled: boolean;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  organizacionaJedinicaChange: OrganizacionaJedinicaReadDto | undefined;
  setOrganizacionaJedinicaChange: any;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  nadredjeneOrgJed: Array<OrganizacionaJedinicaReadDto>;
  orgJedId: number | undefined;
  location: any;
  organizacionaJedinicaTipList: Array<OrganizacionaJedinicaTipDto>;
  ustanovaRadniDanList: Array<UstanovaRadniDanHolder>;
  orgJedOptionsDataLoading: boolean;
  orgJedLoading: boolean;
  getRadnoVremeValue: (radnoVreme: string | undefined) => Date | undefined;
  protokolList: Array<ProtokolReadDto>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  vremeOdRef: HTMLDivElement | any;
  vremeDoRef: HTMLDivElement | any;
}

export interface UstanovaRadniDanHolder {
  radniDan: EnumBaseReadDto;
}
export default function CrudOrganizacionaJedinicaLogical(): OrganizacionaJedinicaCrudLogicalType {
  const { showMessage, setShowBlockUI, authData } = useContext(AppContext);
  const navigate = useNavigate();
  const orgJedId = Number(useParams<{ orgJedId?: string }>()["orgJedId"]);
  const location = useLocation();
  const ustanovaId = authData?.currentRadnik.ustanovaPoslednjaIzabrana.id;
  const [orgJedOperation, setOrgJedOperation] = useState<string>(location.state && location.state.orgJedOperation !== undefined ? location.state.orgJedOperation : EntityOperation.UPDATE);
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const Labels = useLabels();
  const [nadredjeneOrgJed, setNadredjeneOrgJed] = useState<Array<OrganizacionaJedinicaReadDto>>([]);
  const [isDisabled] = useState(isFormDisabled(orgJedOperation));
  const [organizacionaJedinicaChange, setOrganizacionaJedinicaChange] = useState<OrganizacionaJedinicaReadDto | undefined>();
  const [index, setIndex] = useState<number>(tabIndex);
  const [organizacionaJedinicaTipList, setOrganizacionaJedinicaTipList] = useState<Array<OrganizacionaJedinicaTipDto>>([]);
  const [ustanovaRadniDanList, setUstanovaRadniDanList] = useState<Array<UstanovaRadniDanHolder>>([]);
  const [orgJedOptionsDataLoading, setOrgJedOptionsDataLoading] = useState<boolean>(true);
  const [orgJedLoading, setOrgJedLoading] = useState<boolean>(true);
  const [protokolList, setProtokolList] = useState<Array<ProtokolReadDto>>([]);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const vremeOdRef = useRef<HTMLDivElement | any>(null);
  const vremeDoRef = useRef<HTMLDivElement | any>(null);
  const { axiosSearchOrganizacionaJedinica, axiosCreateOrganizacionaJedinica, axiosUpdateOrganizacionaJedinica, axiosDeleteOrganizacionaJedinica, axiosReadOrganizacionaJedinica } =
    OrganizacionaJedinicaController();
  const { axiosGetProtokolList } = ProtokolControler();
  const { axiosGetUstanovaGrid } = UstanovaController();
  const { axiosGetOrganizacionaJedinicaTipList } = EnumController();
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.ORGANIZACIONA_JEDINICA_LIST,
      command: () => {
        navigate("/organizaciona-jedinica-list/");
      },
    },
  ]);
  const { getRadnoVremeValue } = RadnoVremeUtils();

  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    if (orgJedOperation === EntityOperation.CREATE) {
      setOrganizacionaJedinicaChange({
        ...organizacionaJedinicaChange!,
        dostupanZaEksterneZakazivace: false,
      });
    }

    const requestOrgJedTipList = axiosGetOrganizacionaJedinicaTipList();
    const requestUstanovaGrid = axiosGetUstanovaGrid();
    const requestSearchOrgJed = axiosSearchOrganizacionaJedinica();
    const requestProtokolList = axiosGetProtokolList(ustanovaId!);
    let startTime = moment(new Date());
    axios
      .all([requestOrgJedTipList, requestUstanovaGrid, requestSearchOrgJed, requestProtokolList])
      .then(
        axios.spread(({ data: { data: orgJedTipList } }, { data: { data: ustanovaGrid } }, { data: { data: searchOrgJed } }, { data: { data: protokolList } }) => {
          setOrganizacionaJedinicaTipList(orgJedTipList);
          let sortedUstanovaRadniDanList = ustanovaGrid.ustanovaGridRadniDanList.sort((a: UstanovaGridRadniDanReadDto, b: UstanovaGridRadniDanReadDto) => {
            return a.radniDan.prikazniRedosled - b.radniDan.prikazniRedosled;
          });
          setUstanovaRadniDanList(
            sortedUstanovaRadniDanList.map((ustanovaGridRadniDan: UstanovaGridRadniDanReadDto) => {
              return { radniDan: ustanovaGridRadniDan.radniDan };
            })
          );
          setNadredjeneOrgJed(searchOrgJed);
          setProtokolList(protokolList);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setOrgJedOptionsDataLoading, startTime);
      });
    fetchData();
  });

  const fetchData = (ojId?: number | undefined, newOperation?: string | undefined) => {
    let newOrgJedinicaId = ojId ? ojId : orgJedId;
    let operation = newOperation ? newOperation : orgJedOperation;
    let startTime = moment(new Date());
    if (newOrgJedinicaId) {
      const requestOrgJed = axiosReadOrganizacionaJedinica(newOrgJedinicaId);
      axios
        .all([requestOrgJed])
        .then(
          axios.spread((responseOrgJed: any) => {
            setOrganizacionaJedinicaChange(responseOrgJed.data.data);
            if (breadCrumbItems.length >= 2) breadCrumbItems.pop();
            setBreadCrumbItems([
              ...breadCrumbItems,
              {
                label:
                  operation === EntityOperation.UPDATE
                    ? Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_UPDATE + responseOrgJed.data.data.naziv
                    : operation === EntityOperation.READ
                      ? Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DETAILS + responseOrgJed.data.data.naziv
                      : Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DELETE + responseOrgJed.data.data.naziv,
              },
            ]);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setOrgJedLoading, startTime);
        });
    } else {
      setOrgJedLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_CREATE,
        },
      ]);
    }
  };

  const validateInput = (organizacionaJedinica: OrganizacionaJedinicaReadDto | undefined) => {
    let isInvalid = false;
    if (validateStringEmpty(organizacionaJedinica?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (!organizacionaJedinica?.organizacionaJedinicaTip?.sifra) {
      setInvalidFields((prev) => ({ ...prev, tipOrgJedinice: true }));
      isInvalid = true;
    }
    if (!organizacionaJedinica?.organizacionaJedinicaRadniDanList || organizacionaJedinica?.organizacionaJedinicaRadniDanList?.length === 0) {
      setInvalidFields((prev) => ({ ...prev, radniDani: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(organizacionaJedinicaChange)) {
      return;
    }
    if (organizacionaJedinicaChange) {
      setShowBlockUI(true);
      axiosCreateOrganizacionaJedinica(organizacionaJedinicaChange)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_CREATE_ORGANIZACIONA_JEDINICA_SUCCESS);
          setOrgJedOperation(EntityOperation.UPDATE);
          fetchData(res.data.data.id, EntityOperation.UPDATE);
          navigate(`/crud-organizaciona-jedinica/${res.data.data.id}`, { state: { orgJedOperation: EntityOperation.UPDATE } });

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_1 +
              organizacionaJedinicaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_2 +
              res.data.data.organizacionaJedinicaTip.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_3 +
              res.data.data.organizacionaJedinicaRadniDanList.map((e: OrganizacionaJedinicaRadniDanReadDto) => e.radniDan.naziv) +
              "."
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(organizacionaJedinicaChange)) {
      return;
    }
    if (organizacionaJedinicaChange) {
      setShowBlockUI(true);
      axiosUpdateOrganizacionaJedinica(organizacionaJedinicaChange)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_UPDATE_ORGANIZACIONA_JEDINICA_SUCCESS);
          onCancel();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_1 +
              organizacionaJedinicaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_2 +
              res.data.data.organizacionaJedinicaTip.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_3 +
              res.data.data.organizacionaJedinicaRadniDanList.map((e: OrganizacionaJedinicaRadniDanReadDto) => e.radniDan.naziv) +
              "."
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeleteOrganizacionaJedinica(orgJedId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_DELETE_ORGANIZACIONA_JEDINICA_SUCCESS);
        onCancel();

        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_1 +
            organizacionaJedinicaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_2 +
            organizacionaJedinicaChange?.organizacionaJedinicaTip.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_3 +
            organizacionaJedinicaChange?.organizacionaJedinicaRadniDanList.map((e) => e.radniDan.naziv) +
            "."
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/organizaciona-jedinica-list`);
    }
  };

  return {
    orgJedOperation,
    isDisabled,
    organizacionaJedinicaChange,
    breadCrumbItems,
    setOrganizacionaJedinicaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    nadredjeneOrgJed,
    orgJedId,
    location,
    organizacionaJedinicaTipList,
    ustanovaRadniDanList,
    orgJedOptionsDataLoading,
    orgJedLoading,
    getRadnoVremeValue,
    protokolList,
    invalidFields,
    setInvalidFields,
    vremeOdRef,
    vremeDoRef,
  };
}
