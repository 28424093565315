import GenerisaniIzvestajSmallReadDto from "../../../../model/generisaniIzvestaj/GenerisaniIzvestajSmallReadDto";

export interface InitialStateBrisanjeIzvestajaType {
  isDialogOpen: boolean;
  izvestaj: GenerisaniIzvestajSmallReadDto | undefined;
  flag: string;
  loading: boolean;
  from?: string;
}

const InitialStateBrisanjeIzvestaja: InitialStateBrisanjeIzvestajaType = {
  isDialogOpen: false,
  izvestaj: undefined,
  flag: "",
  loading: true,
  from: "",
};

export default InitialStateBrisanjeIzvestaja;
