const EnumPodrazumevanaStrana = {
  POCETNA: "POCETNA",
  ZAKAZIVANJE_PREGLEDA: "ZAKAZIVANJE_PREGLEDA",
  HOMEPAGE: "/",
  SUPERADMIN: "SUPERADMIN",
};

const EnumPodrazumevanaStranaURLMap = {
  [EnumPodrazumevanaStrana.POCETNA]: "/pretraga-pacijenata",
  [EnumPodrazumevanaStrana.ZAKAZIVANJE_PREGLEDA]: "/zakazivanje-pregleda",
  [EnumPodrazumevanaStrana.HOMEPAGE]: "/",
  [EnumPodrazumevanaStrana.SUPERADMIN]: "/ustanova-list",
};

const pronadjiSifruEnumPodrazumevanaStrana = (urlPath: string) => {
  return Object.keys(EnumPodrazumevanaStranaURLMap).find((key: string) => EnumPodrazumevanaStranaURLMap[key] === urlPath);
};

export { EnumPodrazumevanaStrana, EnumPodrazumevanaStranaURLMap, pronadjiSifruEnumPodrazumevanaStrana };
