import { BreadCrumb } from "primereact/breadcrumb";
import { Panel } from "primereact/panel";
import { useLabels } from "../../../Store";
import { DATE_FORMAT, formatDate } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import VerzijaReadDto from "../../../model/verzija/VerzijaReadDto";
import { Accordion, AccordionTab } from "primereact/accordion";
import VerzijaStavkaReadDto from "../../../model/verzija/VerzijaStavkaReadDto";
import { useVerzijaPublicList } from "../../superadministracija/verzija/hooks/useVerzijaPublicList";

export default function IstorijaVerzijaView() {
  const Labels = useLabels();

  const { data: verzijaList } = useVerzijaPublicList();

  const breadCrumbItems: BreadCrumbItemDto[] = [
    {
      label: Labels.ISTORIJA_VERZIJA,
    },
  ];

  function renderVerzijaStavke(verzija: VerzijaReadDto) {
    return (
      <ul className="px-6 py-4 my-0 flex flex-column gap-3">
        {verzija.verzijaStavkaList.map((vs: VerzijaStavkaReadDto) => (
          <li key={vs.id} className="text-lg">
            <span>{vs.opis}</span>
          </li>
        ))}
      </ul>
    );
  }

  const renderHeader = (v: VerzijaReadDto) => {
    return (
      <div className="flex justify-content-between p-1">
        <div className="text-xl font-bold">{v.naziv}</div>
        <div className="text-xl font-bold pr-4 p-text-secondary">{formatDate(v.datum, DATE_FORMAT)}</div>
      </div>
    );
  };

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3 font-bold" />
        <Accordion className="mx-auto mt-6 mb-2 verzija-accordion" multiple>
          {verzijaList?.map((verzija: VerzijaReadDto) => (
            <AccordionTab key={verzija.id} header={renderHeader(verzija)}>
              {renderVerzijaStavke(verzija)}
            </AccordionTab>
          ))}
        </Accordion>
      </Panel>
    </div>
  );
}
