const Images = {
  ERROR: require("../images/error.png").default,
  NO_PROFILE: require("../images/no_profile.png").default,
  LOGO_STRIP: require("../images/logo_strip.png").default,
  LOGO_HELIANT: require("../images/logo_heliant.png").default,
  LOGO_HELIANT_BLUE: require("../images/logo_heliant_blue.svg").default,
  MEDICAL_KIT: require("../images/medical_kit.svg").default,
  CALENDAR_SCHEDULE: require("../images/calendar_schedule.svg").default,

  ADDRESS: require("../images/pacijent-informacije/address.svg").default,
  CALENDAR: require("../images/pacijent-informacije/calendar.svg").default,
  CARD: require("../images/pacijent-informacije/card.svg").default,
  EMAIL: require("../images/pacijent-informacije/email.svg").default,
  GENDER_FEMALE: require("../images/pacijent-informacije/female.svg").default,
  GENDER_MALE: require("../images/pacijent-informacije/male.svg").default,
  PERSONAL_CARD: require("../images/pacijent-informacije/personal_card.svg").default,
  PHONE: require("../images/pacijent-informacije/phone.svg").default,
  SECURITY: require("../images/pacijent-informacije/security.svg").default,

  DUVAN: require("../images/rizici/duvan.svg").default,
  ALKOHOL: require("../images/rizici/alkohol.svg").default,
  SLABA_ISHRANA_VOCE_POVRCE: require("../images/rizici/slaba_ishrana_voce_povrce.svg").default,
  FIZICKA_NEKATIVNOST: require("../images/rizici/fizicka_neaktivnost.svg").default,
  VISOK_KRVI_PRITISAK: require("../images/rizici/visoki_krvi_pritisak.svg").default,
  VISOK_HOLESTEROL: require("../images/rizici/visok_holesterol.svg").default,
  GOJAZNOST: require("../images/rizici/gojaznost.svg").default,
  VISOK_NIVO_SECERA: require("../images/rizici/visok_nivo_secera.svg").default,
  NEUHRANJENOST: require("../images/rizici/neuhranjenost.svg").default,
  DUVAN_AKTIVAN: require("../images/rizici/duvan_aktivan.svg").default,
  ALKOHOL_AKTIVAN: require("../images/rizici/alkohol_aktivan.svg").default,
  SLABA_ISHRANA_VOCE_POVRCE_AKTIVAN: require("../images/rizici/slaba_ishrana_voce_povrce_aktivan.svg").default,
  FIZICKA_NEKATIVNOST_AKTIVAN: require("../images/rizici/fizicka_neaktivnost_aktivan.svg").default,
  VISOK_KRVI_PRITISAK_AKTIVAN: require("../images/rizici/visoki_krvi_pritisak_aktivan.svg").default,
  VISOK_HOLESTEROL_AKTIVAN: require("../images/rizici/visok_holesterol_aktivan.svg").default,
  GOJAZNOST_AKTIVAN: require("../images/rizici/gojaznost_aktivan.svg").default,
  VISOK_NIVO_SECERA_AKTIVAN: require("../images/rizici/visok_nivo_secera_aktivan.svg").default,
  NEUHRANJENOST_AKTIVAN: require("../images/rizici/neuhranjenost_aktivan.svg").default,

  OPSTI_MEDICINSKI_PODACI_ISTORIJA: require("../images/opsti-medicinski-podaci/history.svg").default,
  OPSTI_MEDICINSKI_PODACI_OD_5: require("../images/opsti-medicinski-podaci/od-5.svg").default,
  OPSTI_MEDICINSKI_PODACI_DO_5: require("../images/opsti-medicinski-podaci/do-5.svg").default,

  KOPIRAJ: require("../images/formular/copy.svg").default,
  IZMENI: require("../images/formular/edit.svg").default,
  OBRISI: require("../images/formular/trash-2.svg").default,

  ICON_DOC: require("../images/poruke/doc.svg").default,
  ICON_DOCX: require("../images/poruke/docx.svg").default,
  ICON_JPG: require("../images/poruke/jpg.svg").default,
  ICON_PDF: require("../images/poruke/pdf.svg").default,
  ICON_PPT: require("../images/poruke/ppt.svg").default,
  ICON_PPTX: require("../images/poruke/pptx.svg").default,
  ICON_RAR: require("../images/poruke/rar.svg").default,
  ICON_TXT: require("../images/poruke/txt.svg").default,
  ICON_XLS: require("../images/poruke/xls.svg").default,
  ICON_ZIP: require("../images/poruke/zip.svg").default,
  ICON_IMAGE: require("../images/poruke/picture.svg").default,
  PRIKAZI_PORUKU_PLACEHOLDER: require("../images/poruke/messages-bubble-square-quotation.svg").default,

  ICON_DELETE: require("../images/poruke/delete.svg").default,
  ICON_FORWARD: require("../images/poruke/forward.svg").default,
  ICON_REPLY: require("../images/poruke/reply.svg").default,
  ICON_REPLY_ALL: require("../images/poruke/reply_all.svg").default,

  ICON_NALAZI: require("../images/virtuelna-cekaonica/nalazi.svg").default,
  ICON_DIJAGNOZE: require("../images/virtuelna-cekaonica/dijagnoze.svg").default,
  ICON_TERAPIJE: require("../images/virtuelna-cekaonica/terapije.svg").default,
  ICON_USLUGE: require("../images/virtuelna-cekaonica/usluge.svg").default,
  ICON_UTROSCI: require("../images/virtuelna-cekaonica/utrosci.svg").default,
  ICON_PAKETI: require("../images/virtuelna-cekaonica/setovi.svg").default,

  ICON_PARTNERI: require("../images/partneri/partneri.svg").default,
  ICON_NAPLATA: require("../images/naplata/naplata.svg").default,
  ICON_NAPLATA_DOPUNA: require("../images/naplata/dopuna_iznosa.svg").default,
  ICON_MAPLATA_UPLATIOCI: require("../images/naplata/uplatioci_white.svg").default,
  ICON_MAPLATA_RACUNI: require("../images/naplata/racuni_white.svg").default,
  ICON_MAPLATA_CASH: require("../images/naplata/cash_white.svg").default,

  ICON_USTANOVA: require("../images/ustanova/ustanova.svg").default,

  ICON_DOUBLE_CHECK: require("../images/obavestenja/double-check.png").default,

  ICON_OLD_PATIENT: require("../images/pacijenti/stari_pacijent.svg").default,
  ICON_CURRENT_PATIENT: require("../images/pacijenti/trenutni_pacijent.svg").default,
  ICON_EKSTERNI_ZAKAZIVACI_UNLINK: require("../images/unlink.svg").default,
  ICON_SCHEDULER: require("../images/zakazan-termin/hospital-shield.svg").default,
  ICON_PROVIDER: require("../images/zakazan-termin/hospital-building.svg").default,
  ICON_BULB: require("../images/inicijalizacija/bulb.svg").default,
  ICON_MONITOR: require("../images/inicijalizacija/monitor_clock.svg").default,
  VANREDNI_TERMIN: require("../images/virtuelna-cekaonica/vanredni_termin.svg").default,
  ISTORIJA_VERZIJA: require("../images/istorija-verzija/version_history.svg").default,
};

export default Images;
