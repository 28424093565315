import { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { AppContext } from "../../../Store";
import { KontaktController } from "../../../controllers/kontakt/KontaktController";
import { OrganizacionaJedinicaController } from "../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { RolaService } from "../../../controllers/rola/RolaService";
import { ZakazaniTerminController } from "../../../controllers/zakazani-termin/ZakazaniTerminController";
import { DATE_TIME_FORMAT_3, WARNING_CODE, handleAxiosCallError, handleAxiosCallWarning, useEffectOnce } from "../../../infrastructure/system/Utils";
import KontaktCreateDto from "../../../model/kontakt/KontaktCreateDto";
import TipKontaktaReadDto from "../../../model/kontakt/TipKontaktaReadDto";
import OrganizacionaJedinicaCvorDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaCvorDto";
import OrganizacionaJedinicaKontaktTipReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaKontaktTipReadDto";
import RadnikSkracenoDto from "../../../model/radnik/RadnikSkracenoDto";
import RadnikSkracenoReadDto from "../../../model/radnik/RadnikSkracenoReadDto";
import ZakazanTerminReadDto from "../../../model/zakazan-termin/ZakazanTerminReadDto";
import ZakazanTerminSimpleDto from "../../../model/zakazan-termin/ZakazanTerminSimpleDto";

interface VirtuelnaCekaonicaLogicalProps {
  odabranaOrgJed: Array<OrganizacionaJedinicaCvorDto>;
  selectedDate?: moment.Moment;
  selectedRadnik: RadnikSkracenoReadDto;
  radnikList: Array<RadnikSkracenoReadDto>;
  prikaziNeaktivne: boolean;
}

interface VirtuelnaCekaonicaLogicalType {
  first: number;
  tableRows: number;
  onPageTermin: (rows: number, first: number) => void;
  terminList: Array<ZakazanTerminSimpleDto>;
  changeStatus: (id: number, status: string) => void;
  zapocniKontakt: (zakazanTermin: ZakazanTerminReadDto) => void;
  tipKontaktaList: Array<TipKontaktaReadDto>;
  createKontaktDialog: boolean;
  closeDialogTipKontakta: () => void;
  odabranTipKontakta: TipKontaktaReadDto | undefined;
  setOdabranTipKontakta: React.Dispatch<React.SetStateAction<TipKontaktaReadDto | undefined>>;
  createKontakt: () => void;
  selectedColumns: Array<ZakazanTerminSimpleDto>;
  setSelectedColumns: React.Dispatch<React.SetStateAction<ZakazanTerminSimpleDto[]>>;
  closeKontaktList: () => void;
  changeDoctorDialog: boolean;
  openDialogChangePrimaryDoctor: () => void;
  closeDialogChangePrimaryDoctor: () => void;
  odabranDoktor: RadnikSkracenoDto | undefined;
  setOdabranDoktor: React.Dispatch<React.SetStateAction<RadnikSkracenoDto | undefined>>;
  changePrimaryDoctor: () => void;
  cekaonicaDataLoading: boolean;
  deleteTerminList: () => void;
}

export default function VirtuelnaCekaonicaLogical(props: VirtuelnaCekaonicaLogicalProps): VirtuelnaCekaonicaLogicalType {
  const { odabranaOrgJed, selectedDate, selectedRadnik, radnikList, prikaziNeaktivne } = props;
  const { showMessage, setShowBlockUI, authData } = useContext(AppContext);
  const { axiosGetZakazaniTermini, axiosPutZakazaniTerminiStatus, axiosPatchCloseKontaktList, axiosPatchDeleteZakazanTerminList, axiosZakazanTerminChangeDoctor } = ZakazaniTerminController();
  const { axiosPostCreateKontakt } = KontaktController();
  const { axiosGetOrganizacionaJedinicaKontaktTipList } = OrganizacionaJedinicaController();
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [terminList, setTerminList] = useState<Array<ZakazanTerminSimpleDto>>([]);
  const [tipKontaktaList, setTipKontaktaList] = useState<Array<TipKontaktaReadDto>>([]);
  const [odabranTipKontakta, setOdabranTipKontakta] = useState<TipKontaktaReadDto>();
  const [odabranZakazanTermin, setOdabranZakazanTermin] = useState<ZakazanTerminReadDto>();
  const [createKontaktDialog, setCreateKontaktDialog] = useState<boolean>(false);
  const [odabranDoktor, setOdabranDoktor] = useState<RadnikSkracenoDto>();
  const [changeDoctorDialog, setChangeDoctorDialog] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<Array<ZakazanTerminSimpleDto>>([]);
  const navigate = useNavigate();
  const [cekaonicaDataLoading, setCekaonicaDataLoading] = useState<boolean>(false);
  const { isLekar } = RolaService();

  const formatDate = (date?: moment.Moment) => {
    return date ? moment(date).startOf("day").format(DATE_TIME_FORMAT_3) : "";
  };

  const onPageTermin = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const odabranaOrgJedRef = useRef<Array<OrganizacionaJedinicaCvorDto>>();

  useEffect(() => {
    if (odabranaOrgJedRef.current !== odabranaOrgJed) {
      if (!odabranaOrgJedRef.current && radnikList.find((radnik: RadnikSkracenoReadDto) => radnik.id === selectedRadnik.id) && selectedDate) {
        fetchData();
      }
      odabranaOrgJedRef.current = odabranaOrgJed;
    } else if (radnikList.find((radnik: RadnikSkracenoReadDto) => radnik.id === selectedRadnik.id) && selectedDate) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRadnik, odabranaOrgJed, selectedDate]);

  const fetchData = () => {
    if (odabranaOrgJed && odabranaOrgJed[odabranaOrgJed.length - 1].podredjeneOrganizacioneJedinice.length === 0) {
      const params = {
        radnikId: selectedRadnik?.id,
        datumOd: formatDate(selectedDate),
        datumDo: formatDate(moment(selectedDate).add(1, "days")),
        organizacionaJedinicaId: odabranaOrgJed[odabranaOrgJed.length - 1].organizacionaJedinica.id,
        rezervisaniTerminiIsShown: false,
        viewNeaktivni: selectedRadnik?.viewNeaktivni && prikaziNeaktivne,
      };
      if (params.radnikId === undefined) {
        setShowBlockUI(false);
        return;
      }
      setCekaonicaDataLoading(true);
      axiosGetZakazaniTermini(params)
        .then((response: any) => {
          setTerminList(response.data.data);
          setFirst(0);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setCekaonicaDataLoading(false);
        });
    }
  };

  useEffectOnce(() => {
    axiosGetOrganizacionaJedinicaKontaktTipList(odabranaOrgJed[odabranaOrgJed.length - 1].organizacionaJedinica.id)
      .then((response: AxiosResponse) => {
        setTipKontaktaList(response.data.data.map((orgJedKontaktTip: OrganizacionaJedinicaKontaktTipReadDto) => orgJedKontaktTip.kontaktTip));
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  });

  useEffect(() => {
    if (odabranZakazanTermin) {
      if (tipKontaktaList?.length === 1) {
        createKontakt();
      } else if (!odabranZakazanTermin.kontaktTip) {
        setCreateKontaktDialog(true);
        if (tipKontaktaList && tipKontaktaList.length > 0) setOdabranTipKontakta(tipKontaktaList[0]);
      } else {
        createKontakt();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odabranZakazanTermin]);

  const changeStatus = (id: number, status: string) => {
    setShowBlockUI(true);
    const params = {
      sifra: status,
    };
    axiosPutZakazaniTerminiStatus(id, params)
      .then((response: any) => {
        setTerminList((terminListOld) => terminListOld.map((termin) => (termin.id === id ? response.data.data : termin)));
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const closeKontaktList = () => {
    setShowBlockUI(true);
    const zakazanTerminIdList = selectedColumns?.filter((zt: ZakazanTerminSimpleDto) => zt.kontakt).map((zt: ZakazanTerminSimpleDto) => zt.id);
    axiosPatchCloseKontaktList(zakazanTerminIdList)
      .then((response: any) => {
        const temp = [...terminList];
        response.data.data.forEach((res: ZakazanTerminSimpleDto) => {
          const indexElement = temp.findIndex((zt: ZakazanTerminSimpleDto) => zt.id === res.id);
          if (indexElement !== -1) temp[indexElement] = res;
        });
        setSelectedColumns([]);
        setTerminList(temp);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const deleteTerminList = () => {
    setShowBlockUI(true);
    const zakazanTerminIdList = selectedColumns?.map((zt: ZakazanTerminSimpleDto) => zt.id);
    axiosPatchDeleteZakazanTerminList(zakazanTerminIdList)
      .then(() => {
        const temp = terminList.filter((termin: ZakazanTerminSimpleDto) => !zakazanTerminIdList?.includes(termin.id));
        setSelectedColumns([]);
        setTerminList(temp);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const changePrimaryDoctor = () => {
    setShowBlockUI(true);
    const zakazanTerminIdList = selectedColumns?.map((zt: ZakazanTerminSimpleDto) => zt.id);
    axiosZakazanTerminChangeDoctor(Number(odabranDoktor?.id), zakazanTerminIdList)
      .then((res: any) => {
        setTerminList((terminList) => terminList.filter((termin) => !zakazanTerminIdList.includes(termin?.id)));
        closeDialogChangePrimaryDoctor();
        setSelectedColumns([]);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const closeDialogTipKontakta = () => {
    setCreateKontaktDialog(false);
    setOdabranTipKontakta(undefined);
  };

  const openDialogChangePrimaryDoctor = () => {
    setChangeDoctorDialog(true);
  };

  const closeDialogChangePrimaryDoctor = () => {
    setChangeDoctorDialog(false);
    setOdabranDoktor(undefined);
  };

  const zapocniKontakt = (zakazanTermin: ZakazanTerminReadDto) => {
    setOdabranZakazanTermin(zakazanTermin);
  };

  const createKontakt = () => {
    let primarniDoktorId: number | undefined = isLekar ? authData?.currentRadnik.id : odabranZakazanTermin?.radnik?.id;
    const params: KontaktCreateDto = {
      kontaktTip: {
        id: odabranZakazanTermin?.kontaktTip?.id ?? odabranTipKontakta?.id ?? tipKontaktaList[0]?.id,
      },
      zakazanTermin: odabranZakazanTermin
        ? {
            id: odabranZakazanTermin?.id,
          }
        : undefined,
      pacijent: odabranZakazanTermin
        ? {
            id: odabranZakazanTermin?.pacijent.id,
          }
        : undefined,

      organizacionaJedinica: odabranZakazanTermin
        ? {
            id: odabranZakazanTermin?.organizacionaJedinica.id,
          }
        : undefined,
      vremeOd: moment().format(DATE_TIME_FORMAT_3),
      primarniDoktor: primarniDoktorId
        ? {
            id: primarniDoktorId,
          }
        : undefined,
    };
    setShowBlockUI(true);

    axiosPostCreateKontakt(params)
      .then((response: any) => {
        navigate(`/kontakt/${response.data.data.id}`, {
          state: {
            fromVirtuelnaCekaonica: true,
            podrazumevaniFormularId: response.data?.data?.kontaktTip?.formularPodrazumevani?.id,
          },
        });

        if (response.data.code === WARNING_CODE) handleAxiosCallWarning(showMessage, response.data.warnings);
      })

      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return {
    first,
    tableRows,
    onPageTermin,
    terminList,
    changeStatus,
    zapocniKontakt,
    tipKontaktaList,
    createKontaktDialog,
    closeDialogTipKontakta,
    odabranTipKontakta,
    setOdabranTipKontakta,
    createKontakt,
    selectedColumns,
    setSelectedColumns,
    closeKontaktList,
    changeDoctorDialog,
    openDialogChangePrimaryDoctor,
    closeDialogChangePrimaryDoctor,
    odabranDoktor,
    setOdabranDoktor,
    changePrimaryDoctor,
    cekaonicaDataLoading,
    deleteTerminList,
  };
}
