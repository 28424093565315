import { Button } from "primereact/button";
import { useMemo } from "react";
import { useLabels } from "../../../Store";
import IzvestajType from "../../../infrastructure/system/IzvestajType";
import actions from "../../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/actionsBrisanjeIzvestaja";
import { InitialStateBrisanjeIzvestajaType } from "../../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/initialStateBrisanjeIzvestaja";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import DialogComponent from "../../dialog-component/DialogComponent";
import DialogGenerisaniIzvestajLogical, { GenerisaniIzvestajParametarNazivVrednost } from "./DialogGenerisaniIzvestajLogical";
interface DialogGenerisaniIzvestajType {
  state: InitialStateBrisanjeIzvestajaType;
  dispatch: React.Dispatch<{ type: actions; data: any; flag: string; from: "" }>;
}

export default function DialogGenerisaniIzvestaj(props: DialogGenerisaniIzvestajType) {
  const { state, dispatch } = props;
  const { onDelete, parameters } = DialogGenerisaniIzvestajLogical(state);
  const Labels = useLabels();

  const renderFooter = () => {
    return (
      state?.flag === "delete" &&
      state?.izvestaj && (
        <div className="flex justify-content-end">
          <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger m-1" onClick={() => dispatch({ type: actions.CLOSE_DIALOG, data: undefined, flag: "", from: "" })} />
          <Button label={Labels.BUTTON_DELETE} tooltip={Labels.GEN_IZVESTAJ_TITLE_DIALOG_DELETE} className="p-button-danger mr-2 m-1" icon="pi pi-trash" onClick={onDelete} />
        </div>
      )
    );
  };

  const params = useMemo(
    () => (
      <div className="col-10 p-0 py-3">
        {parameters
          ?.filter((param: GenerisaniIzvestajParametarNazivVrednost) => param.vrednost !== undefined)
          .map((param: GenerisaniIzvestajParametarNazivVrednost) => (
            <div className="grid justify-between align-center" key={param.id}>
              <div className="col-4 flex align-items-center">{param.naziv}</div>
              <div className="col-8">
                {param.vrednost.split("#split#").map((vrednostPart: string, index: number) => (
                  <div key={`${param.id}_${index}`}>{vrednostPart}</div>
                ))}
              </div>
            </div>
          ))}
      </div>
    ),
    [parameters]
  );

  return (
    <DialogComponent
      header={state.from === IzvestajType.generisani ? state?.izvestaj?.naslov ?? "" : state.izvestaj?.statistickiIzvestaj.naziv ?? ""}
      visible={state?.izvestaj !== undefined}
      footer={renderFooter}
      onHide={() => dispatch({ type: actions.CLOSE_DIALOG, data: undefined, flag: "", from: "" })}
      blockScroll={true}
      content={<div className="p-0">{state.loading ? <SkeletonInputItem /> : params}</div>}
    />
  );
}
