import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SifarnikKategorijaPopustaCreateDto from "../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaCreateDto";
import SifarnikKategorijaPopustaSearchDto from "../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaSearchDto";
import SifarnikKategorijaPopustaUpdateDto from "../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaUpdateDto";

interface SifarnikKategorijaPopustaControllerType {
  axiosGetSifarnikKategorijaPopustaList: () => Promise<AxiosResponse<any>>;
  axiosGetSifarnikKategorijaPopusta: (sifarnikKategorijaPopustaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateSifarnikKategorijaPopusta: (sifarnikKategorijaPopustaChange: SifarnikKategorijaPopustaCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteSifarnikKategorijaPopusta: (sifarnikKategorijaPopustaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateSifarnikKategorijaPopusta: (sifarnikKategorijaPopusta: SifarnikKategorijaPopustaUpdateDto) => Promise<AxiosResponse<any>>;
  axiosSearchByNamenaKategorijePopusta: (sifarnikKategorijaPopustaSearchDto: SifarnikKategorijaPopustaSearchDto) => Promise<AxiosResponse<any>>;
}

export function SifarnikKategorijaPopustaController(): SifarnikKategorijaPopustaControllerType {
  const { authData } = useContext(AppContext);
  const { SIFARNIK_KATEGORIJA_POPUSTA_LIST } = Endpoint;

  const axiosGetSifarnikKategorijaPopustaList = () => {
    return axios.get(SIFARNIK_KATEGORIJA_POPUSTA_LIST, axiosConfig(authData!.token));
  };

  const axiosGetSifarnikKategorijaPopusta = (sifarnikKategorijaPopustaId: number) => {
    return axios.get(`${SIFARNIK_KATEGORIJA_POPUSTA_LIST}/${sifarnikKategorijaPopustaId}`, axiosConfig(authData!.token));
  };

  const axiosCreateSifarnikKategorijaPopusta = (sifarnikKategorijaPopustaChange: SifarnikKategorijaPopustaCreateDto) => {
    return axios.post(`${Endpoint.SIFARNIK_KATEGORIJA_POPUSTA_LIST}`, sifarnikKategorijaPopustaChange, axiosConfig(authData!.token));
  };

  const axiosDeleteSifarnikKategorijaPopusta = (sifarnikKategorijaPopustaId: number) => {
    return axios.delete(`${Endpoint.SIFARNIK_KATEGORIJA_POPUSTA_LIST}/${sifarnikKategorijaPopustaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateSifarnikKategorijaPopusta = (sifarnikKategorijaPopusta: SifarnikKategorijaPopustaUpdateDto) => {
    return axios.put(`${Endpoint.SIFARNIK_KATEGORIJA_POPUSTA_LIST}/${sifarnikKategorijaPopusta?.id}`, sifarnikKategorijaPopusta, axiosConfig(authData!.token));
  };

  const axiosSearchByNamenaKategorijePopusta = (sifarnikKategorijaPopustaSearchDto: SifarnikKategorijaPopustaSearchDto) => {
    return axios.get(`${Endpoint.SIFARNIK_KATEGORIJA_POPUSTA_LIST}`, axiosConfig(authData!.token, sifarnikKategorijaPopustaSearchDto));
  };

  return {
    axiosGetSifarnikKategorijaPopustaList,
    axiosGetSifarnikKategorijaPopusta,
    axiosCreateSifarnikKategorijaPopusta,
    axiosDeleteSifarnikKategorijaPopusta,
    axiosUpdateSifarnikKategorijaPopusta,
    axiosSearchByNamenaKategorijePopusta,
  };
}
