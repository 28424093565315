import { Button } from "primereact/button";
import { useLabels } from "../../../../Store";
import UstanovaPodesavanjaReadDto from "../../../../model/ustanova/UstanovaPodesavanjaReadDto";
import DialogComponent from "../../../dialog-component/DialogComponent";

interface DialogUstanovaPodesavanjeAutomatskoRazduzivanjePropsType {
  ustanovaPodesavanja: UstanovaPodesavanjaReadDto;
  setUstanovaPodesavanja: React.Dispatch<React.SetStateAction<UstanovaPodesavanjaReadDto>>;
  isAutomatskoRazduzivanjeDialogOpen: boolean;
  closeAutomatskoRazduzivanjeDialog: () => void;
}

export default function DialogUstanovaPodesavanjeAutomatskoRazduzivanje(props: DialogUstanovaPodesavanjeAutomatskoRazduzivanjePropsType) {
  const { ustanovaPodesavanja, setUstanovaPodesavanja, isAutomatskoRazduzivanjeDialogOpen, closeAutomatskoRazduzivanjeDialog } = props;
  const Labels = useLabels();

  const dialogFooter = (
    <div className="col-12">
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-outlined p-button-tertiary" onClick={closeAutomatskoRazduzivanjeDialog} />
      <Button
        label={Labels.BUTTON_CONFIRM}
        icon="pi pi-check"
        className="button-danger ml-4"
        onClick={() => {
          setUstanovaPodesavanja({
            ...ustanovaPodesavanja!,
            automatskoRazduzivanjePlaniranihUsluga: true,
          });
          closeAutomatskoRazduzivanjeDialog();
        }}
      />
    </div>
  );

  return (
    <DialogComponent
      header={Labels.USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA_POTVRDA}
      visible={isAutomatskoRazduzivanjeDialogOpen}
      onHide={closeAutomatskoRazduzivanjeDialog}
      footer={dialogFooter}
      className="w-4"
      content={
        <div>
          <div>{Labels.USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA_DIALOG_CONTENT}</div>
        </div>
      }
    />
  );
}
