import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../controllers/enum/EnumController";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import QueryKeys from "../../QueryKeys";

export function useTrajanjeTerminaList() {
  const { axiosGetTrajanjeTerminaList } = EnumController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.TRAJANJE_TERMINA_LIST],
    queryFn: () => axiosGetTrajanjeTerminaList(),
  });

  const trajanjeTerminaList: Array<EnumBaseReadDto> = data?.data?.data;

  return { trajanjeTerminaList, refetch, isLoading };
}
