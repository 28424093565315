import axios from "axios";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { RadnikController } from "../../../../controllers/radnik/RadnikController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import { handleAxiosCallError, useEffectOnce } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import RadnikSpecijalizacijaCreateDto from "../../../../model/radnik/RadnikSpecijalizacijaCreateDto";
import RadnikSpecijalizacijaReadDto from "../../../../model/radnik/RadnikSpecijalizacijaReadDto";
import SifarnikSpecijalizacijaReadDto from "../../../../model/sifarnik/sifarnik-specijalizacija/SifarnikSpecijalizacijaReadDto";

interface UseParamsType {
  radnikId: number;
}

interface CrudRadnikSpecijalizacijeLogicalType {
  specijalizacijeList: SifarnikSpecijalizacijaReadDto[];
  radnikSpecijalizacijeList: RadnikSpecijalizacijaReadDto[];
  isLoading: boolean;
  onMoveToSource: (specijalizacije: RadnikSpecijalizacijaReadDto[]) => void;
  onMoveToTarget: (specijalizacije: SifarnikSpecijalizacijaReadDto[]) => void;
  sourceSelection: SifarnikSpecijalizacijaReadDto[];
  setSourceSelection: React.Dispatch<React.SetStateAction<SifarnikSpecijalizacijaReadDto[]>>;
  targetSelection: RadnikSpecijalizacijaReadDto[];
  setTargetSelection: React.Dispatch<React.SetStateAction<RadnikSpecijalizacijaReadDto[]>>;
}

export default function CrudRadnikSpecijalizacijeLogical(radnik: string, ustanovaId?: number): CrudRadnikSpecijalizacijeLogicalType {
  const [specijalizacijeList, setSpecijalizacijeList] = useState<SifarnikSpecijalizacijaReadDto[]>([]);
  const [radnikSpecijalizacijeList, setRadnikSpecijalizacijeList] = useState<RadnikSpecijalizacijaReadDto[]>([]);
  const [sourceSelection, setSourceSelection] = useState<SifarnikSpecijalizacijaReadDto[]>([]);
  const [targetSelection, setTargetSelection] = useState<RadnikSpecijalizacijaReadDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { axiosGetRadnikSpecijalizacijeList, axiosCreateRadnikSpecijalizacija, axiosDeleteRadnikSpecijalizacija } = RadnikController();
  const { axiosSuperadminGetSifarnikSpecijalizacija, axiosSuperadminGetRadnikSpecijalizacijeList, axiosSuperadminCreateRadnikSpecijalizacija, axiosSuperadminDeleteRadnikSpecijalizacija } =
    SuperadminController();
  const { showMessage } = useContext(AppContext);
  const postLogHighLevel = useLogHighLevel();
  const Labels = useLabels();
  const radnikId: number = Number(useParams<keyof UseParamsType>()["radnikId"]);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    setIsLoading(true);
    const requestSpecijalizacijaList = axiosSuperadminGetSifarnikSpecijalizacija();
    const requestRadnikSpecijalizacijaList = radnikId ? (ustanovaId ? axiosSuperadminGetRadnikSpecijalizacijeList(ustanovaId, radnikId) : axiosGetRadnikSpecijalizacijeList(radnikId)) : null;

    axios
      .all([requestSpecijalizacijaList, radnikId ? requestRadnikSpecijalizacijaList : null])
      .then(
        axios.spread((responseSpecijalizacijaList, responseRadnikSpecijalizacijaList) => {
          setRadnikSpecijalizacijeList(responseRadnikSpecijalizacijaList?.data.data ?? []);
          const radnikSpecijalizacijaIdList = responseRadnikSpecijalizacijaList?.data.data.map((spec: RadnikSpecijalizacijaReadDto) => spec?.sifarnikSpecijalizacija.id);
          setSpecijalizacijeList(responseSpecijalizacijaList?.data.data.filter((specijalizacija: SifarnikSpecijalizacijaReadDto) => !radnikSpecijalizacijaIdList?.includes(specijalizacija.id)));
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onMoveToSource = (specijalizacije: RadnikSpecijalizacijaReadDto[]) => {
    setIsLoading(true);

    let radnikSpecijalizacijaChangeArray: RadnikSpecijalizacijaCreateDto[] = [];
    specijalizacije.forEach((specijalizacija: RadnikSpecijalizacijaReadDto) => {
      radnikSpecijalizacijaChangeArray.push({
        radnik: { id: radnikId },
        sifarnikSpecijalizacija: { id: specijalizacija?.sifarnikSpecijalizacija?.id },
      });
    });
    let sifarnikSpecijalizacijaIdList: Array<number> = [];

    radnikSpecijalizacijaChangeArray.map((spec) => sifarnikSpecijalizacijaIdList.push(spec?.sifarnikSpecijalizacija?.id!));

    (ustanovaId ? axiosSuperadminDeleteRadnikSpecijalizacija(ustanovaId, radnikId, [...sifarnikSpecijalizacijaIdList]) : axiosDeleteRadnikSpecijalizacija(radnikId, [...sifarnikSpecijalizacijaIdList]))
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        const specijalizacijeNazivi = specijalizacije.map((spec) => spec?.sifarnikSpecijalizacija?.naziv).join(", ");
        const sigleOrMultipleLabel = specijalizacije.length > 1 ? Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJE_2 : Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJA_2;
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJA_1 + radnik + sigleOrMultipleLabel + specijalizacijeNazivi + ".");
        fetchData();
        setSourceSelection([]);
        setTargetSelection([]);
      });
  };

  const onMoveToTarget = (specijalizacije: SifarnikSpecijalizacijaReadDto[]) => {
    setIsLoading(true);

    let radnikSpecijalizacijaChangeArray: RadnikSpecijalizacijaCreateDto[] = [];
    specijalizacije.forEach((specijalizacija: SifarnikSpecijalizacijaReadDto) => {
      radnikSpecijalizacijaChangeArray.push({
        radnik: { id: radnikId },
        sifarnikSpecijalizacija: { id: specijalizacija?.id },
      });
    });

    (ustanovaId ? axiosSuperadminCreateRadnikSpecijalizacija(ustanovaId, radnikId, radnikSpecijalizacijaChangeArray) : axiosCreateRadnikSpecijalizacija(radnikId, radnikSpecijalizacijaChangeArray))
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        const specijalizacijeNazivi = specijalizacije.map((spec) => spec.naziv).join(", ");
        const sigleOrMultipleLabel = specijalizacije.length > 1 ? Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJE_2 : Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJA_2;
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJA_1 + radnik + sigleOrMultipleLabel + specijalizacijeNazivi + ".");
        fetchData();
        setSourceSelection([]);
        setTargetSelection([]);
      });
  };

  return {
    specijalizacijeList,
    radnikSpecijalizacijeList,
    isLoading,
    onMoveToTarget,
    onMoveToSource,
    sourceSelection,
    setSourceSelection,
    targetSelection,
    setTargetSelection,
  };
}
