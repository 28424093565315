import MandatoryBaseEntityDto from "../../../../model/MandatoryBaseEntityDto";
import KontaktNaplataReadDto from "../../../../model/naplata/KontaktNaplataReadDto";
import RacunCreateDto from "../../../../model/naplata/RacunCreateDto";
import StavkaNaplate from "../../../../model/naplata/StavkaNaplate";

export interface InitialStateNaplataType {
  step: number;
  pacijent?: MandatoryBaseEntityDto;
  usluge: Record<number, StavkaNaplate[]>;
  proizvodi: Record<number, StavkaNaplate[]>;
  selectedColumns: KontaktNaplataReadDto[];
  racuni: RacunCreateDto[];
  accountsPaymentOpted: boolean;
  stampanje: {
    pacijent: boolean;
    partner: boolean;
  };
}

const initialStateNaplata: InitialStateNaplataType = {
  step: 0,
  selectedColumns: [],
  usluge: {},
  racuni: [],
  proizvodi: {},
  accountsPaymentOpted: false,
  stampanje: {
    pacijent: true,
    partner: true,
  },
};

export default initialStateNaplata;
