import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DodatniModulController } from "../../../../controllers/dodatni-modul/DodatniModulController";

import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import DodatniModulReadDto from "../../../../model/licenca/dodatni-modul/DodatniModulReadDto";

import { AxiosResponse } from "axios";
import { EnumController } from "../../../../controllers/enum/EnumController";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import { AppContext, useLabels } from "../../../../Store";

interface CrudDodatniModulLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  dodatniModulChange: DodatniModulReadDto | undefined;
  setDodatniModulChange: React.Dispatch<React.SetStateAction<DodatniModulReadDto | undefined>>;
  isDisabled: boolean;
  dodatniModulOperation: string;
  onCreate: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  dodatniModulLoading: boolean;
  sifarnikDodatniModulList: Array<EnumBaseReadDto>;
}

interface UseParamsType {
  dodatniModulId?: string;
}

export default function CrudDodatniModulLogical(): CrudDodatniModulLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const [dodatniModulChange, setDodatniModulChange] = useState<DodatniModulReadDto | undefined>();
  const [dodatniModulOperation] = useState(location.state?.dodatniModulOperation || EntityOperation.UPDATE);
  const [isDisabled] = useState(isFormDisabled(dodatniModulOperation));
  const dodatniModulId = Number(useParams<keyof UseParamsType>()["dodatniModulId"]);
  const [dodatniModulLoading, setDodatniModulLoading] = useState(true);
  const [sifarnikDodatniModulList, setSifarnikDodatniModulList] = useState<Array<EnumBaseReadDto>>([]);
  const { axiosGetDodatniModul, axiosCreateDodatniModul, axiosUpdateDodatniModul } = DodatniModulController();
  const { axiosGetDodatniModulList } = EnumController();
  const postLogHighLevel = useLogHighLevel();
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.LICENCA_LIST,
      command: () => {
        navigate("/licence", { state: { tabIndex: 1 } });
      },
    },
  ]);

  useEffectOnce(() => {
    fetchData();
    fetchSifarnikDodatnihModula();
  });

  const fetchData = (dmID?: number, operation?: string) => {
    const newDodatniModulId = dmID || dodatniModulId;
    const startTime = moment(new Date());
    if (newDodatniModulId) {
      axiosGetDodatniModul(newDodatniModulId)
        .then(({ data: { data } }: { data: { data: DodatniModulReadDto } }) => {
          if (data.ikonica) data.ikonica = "data:image/svg+xml;base64," + data.ikonica;
          setDodatniModulChange(data);
          const operationTmp = operation ?? dodatniModulOperation;
          setBreadCrumbItems([
            breadCrumbItems[0],
            {
              label:
                operationTmp === EntityOperation.UPDATE
                  ? Labels.DODATNI_MODUL_TITLE_UPDATE + data.naziv
                  : operationTmp === EntityOperation.READ
                  ? Labels.DODATNI_MODUL_TITLE_DETAILS + data.naziv
                  : Labels.DODATNI_MODUL_TITLE_DELETE + data.naziv,
            },
          ]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setDodatniModulLoading, startTime);
        });
    } else {
      setDodatniModulLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.DODATNI_MODUL_TITLE_CREATE,
        },
      ]);
    }
  };

  const fetchSifarnikDodatnihModula = () => {
    axiosGetDodatniModulList()
      .then((response: AxiosResponse) => {
        setSifarnikDodatniModulList(response.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const validateInput = (dodatniModul: DodatniModulReadDto | undefined) => {
    if (dodatniModul === undefined || validateStringEmpty(dodatniModul.naziv)) {
      showMessage(MessageType.ERROR, Labels.DODATNI_MODUL_NAZIV_IS_REQUIRED);
      return false;
    }
    if (validateStringEmpty(dodatniModul?.tip.sifra)) {
      showMessage(MessageType.ERROR, Labels.DODATNI_MODUL_SIFRA_IS_REQUIRED);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validateInput(dodatniModulChange)) {
      return;
    }

    if (dodatniModulChange) {
      setShowBlockUI(true);
      axiosCreateDodatniModul(dodatniModulChange)
        .then(({ data: { data } }: { data: { data: DodatniModulReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.DODATNI_MODUL_MESSAGE_CREATE_SUCCESS);
          onCancel();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
      postLogHighLevel(
        Labels.LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_1 +
          dodatniModulChange.naziv +
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_2 +
          dodatniModulChange.tip.sifra +
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_3 +
          dodatniModulChange.cena +
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_4
      );
    }
  };

  const onUpdate = () => {
    if (!validateInput(dodatniModulChange)) {
      return;
    }
    if (dodatniModulChange) {
      dodatniModulChange!.ikonica = dodatniModulChange!.ikonica?.replace("data:image/svg+xml;base64,", "");
      setDodatniModulChange({
        ...dodatniModulChange!,
        ikonica: dodatniModulChange!.ikonica,
      });
      setShowBlockUI(true);
      axiosUpdateDodatniModul(dodatniModulChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.DODATNI_MODUL_MESSAGE_UPDATE_SUCCESS);
          onCancel();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
      postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_DODATNI_MODUL_1 + dodatniModulChange.naziv + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_DODATNI_MODUL_2 + dodatniModulChange.tip.sifra);
    }
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      const breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/licence`, {
        state: { tabIndex: 1 },
      });
    }
  };

  return {
    breadCrumbItems,
    dodatniModulChange,
    setDodatniModulChange,
    isDisabled,
    dodatniModulOperation,
    onCreate,
    onUpdate,
    onCancel,
    dodatniModulLoading,
    sifarnikDodatniModulList,
  };
}

export type { CrudDodatniModulLogicalType };
