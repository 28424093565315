import { RadioButton } from "primereact/radiobutton";
import { PoljeType } from "../Polje";

export default function SkupovniRadio(props: PoljeType) {
  const { id, onChange, vrednost, enumStavke, vrednostList, useVrednostList, readonly } = props;

  return (
    <>
      {enumStavke?.map((unos) => {
        return (
          <div key={unos.id} className="mt-2">
            <RadioButton
              className="p-radiobutton"
              inputId={unos.id.toString()}
              name="category"
              onChange={() => {
                onChange({ id: id, vrednost: unos.id });
              }}
              value={useVrednostList && vrednostList ? vrednostList[id] ?? null : vrednost ?? null}
              checked={vrednostList?.[id] === unos.id || vrednost === unos.id}
              disabled={readonly}
            />
            <label htmlFor={unos.id.toString()} className="ml-2 mr-4 cursor-pointer">
              {unos.naziv}
            </label>
          </div>
        );
      })}
    </>
  );
}
