import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { Navigate } from "react-router-dom";
import { useLabels } from "../../../../Store";
import { RolaService } from "../../../../controllers/rola/RolaService";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { addSrLocal, tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import CrudKategorijaStatistickihIzvestajaLogical from "./CrudKategorijaStatistickihIzvestajaLogical";

export default function CrudKategorijaStatistickihIzvestajaView() {
  const { isSuperadmin } = RolaService();
  const { breadCrumbItems, location, kategorijaId, kategorijaOperation, isDisabled, kategorijaLoading, onCreate, onUpdate, onDelete, onCancel, kategorijaChange, setKategorijaChange } =
    CrudKategorijaStatistickihIzvestajaLogical(isSuperadmin);
  addSrLocal();
  const Labels = useLabels();

  if (!kategorijaId && !location.state) {
    return (
      <Navigate
        to={{
          pathname: "/izvestaj-list",
        }}
      />
    );
  }

  const leftContentsToolbar = () => (
    <>
      {kategorijaOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.KATEGORIJA_TITLE_DIALOG_CREATE} tooltipOptions={tooltipOptionsBottom} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {kategorijaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.KATEGORIJA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-pencil"
          disabled={kategorijaLoading}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {kategorijaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.KATEGORIJA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-trash"
          disabled={kategorijaLoading}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );
  const rightContentsToolbar = () => <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={onCancel} />;

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
      </Panel>
      <TabView className="mt-3" renderActiveOnly={false}>
        <TabPanel header={Labels.LABEL_KATEGORIJA_STATISTICKOG_IZVESTAJA}>
          <div className="flex">
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.KATEGORIJA_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!kategorijaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={kategorijaChange?.naziv ?? ""}
                      onChange={(e) =>
                        setKategorijaChange({
                          ...kategorijaChange!,
                          naziv: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.KATEGORIJA_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!kategorijaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={kategorijaChange?.sifra ?? ""}
                      onChange={(e) =>
                        setKategorijaChange({
                          ...kategorijaChange!,
                          sifra: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
          </div>
          <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
        </TabPanel>
      </TabView>
    </div>
  );
}
