import { PickList } from "primereact/picklist";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import SkeletonPickListItem from "../../../app/skeleton/SkeletonPickListItem";
import CrudOrganizacionaJedinicaKontaktTipLogical, { OrganizacionaJedinicaKontaktTipHolder } from "./CrudOrganizacionaJedinicaKontaktTipLogical";

interface CrudOrganizacionaJedinicaKontaktTipProps {
  isDisabled: boolean;
  orgJedOperation: string;
  orgJedNaziv?: string;
}
export default function CrudOrganizacionaJedinicaKontaktTip(props: CrudOrganizacionaJedinicaKontaktTipProps) {
  const { isDisabled, orgJedOperation, orgJedNaziv } = props;
  const { pristup } = useContext(AppContext);
  const { organizacionaJedinicaKontaktTipList, kontaktTipList, onChangeOrganizacionaJedinicaKontaktTip, isBlocked, isLoading } = CrudOrganizacionaJedinicaKontaktTipLogical(
    orgJedOperation,
    orgJedNaziv
  );
  const Labels = useLabels();
  const itemTemplate = (item: OrganizacionaJedinicaKontaktTipHolder | undefined) => {
    if (item === undefined || item === null || isBlocked) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{`${item.kontaktTip?.naziv}`}</h6>;
  };
  return (
    <div className={"layout-generic-content-list"}>
      <div className={!pristup || isDisabled || isBlocked || isLoading ? "read-only" : ""}>
        <PickList
          sourceHeader={Labels.TIP_KONTAKTA_LIST}
          targetHeader={Labels.LABEL_ORGANIZACIONA_JEDINICA_KONTAKT_TIP_LIST}
          source={kontaktTipList}
          target={organizacionaJedinicaKontaktTipList}
          itemTemplate={itemTemplate}
          onChange={onChangeOrganizacionaJedinicaKontaktTip}
          showSourceControls={false}
          showTargetControls={false}
        ></PickList>
      </div>
    </div>
  );
}
