import { BreadCrumb } from "primereact/breadcrumb";
import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import EnumStatusUvoza from "../../../infrastructure/system/EnumStatusUvoza";
import { DATE_FORMAT, formatDate } from "../../../infrastructure/system/Utils";
import SifarnikImportReadDto from "../../../model/sifarnik-import/SifarnikImportReadDto";
import SifarnikTipDto from "../../../model/sifarnik-import/SifarnikTipDto";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import SifarnikImportLogical from "./SifarnikImportLogical";

export default function SifarnikImportView() {
  const { breadCrumbItems, sifarnikTipLoading, sifarnikTipList, sifarnikImportList, uploadHandler } = SifarnikImportLogical();
  const toast: any = useRef(null);

  const importPanelSkeleton = () => (
    <>
      {Array.from({ length: 2 }, (_, i) => (
        <div key={i} className="p-row my-4">
          <div className="pt-2 p-type-title-skeleton mr-2">
            <SkeletonInputItem />
          </div>
          <Skeleton size="2.5rem" className="mr-2 ml-8 w-3rem" />
        </div>
      ))}
    </>
  );

  const importPanelContent = () => (
    <>
      {sifarnikTipList.map((sifarnikTip: SifarnikTipDto) => {
        const sifarnikImport: SifarnikImportReadDto | undefined =
          sifarnikImportList.filter((si) => si.sifarnikTip.id === sifarnikTip.id).length > 0 ? sifarnikImportList.filter((si) => si.sifarnikTip.id === sifarnikTip.id)[0] : undefined;
        return (
          <div key={sifarnikTip.id} className="my-3 flex-wrap flex-row">
            <div className="p-row">
              <div className="align-self-center p-type-title pl-2 ">{sifarnikTip.naziv}</div>
              <FileUpload
                mode="basic"
                accept={".zip, ." + sifarnikTip.fajlFormatEnum.naziv}
                className="ml-2 align-self-center"
                auto
                chooseOptions={{ icon: "pi pi-fw pi-paperclip", iconOnly: true, className: "pr-1" }}
                customUpload
                uploadHandler={(e: FileUploadHandlerEvent) => {
                  uploadHandler(e, sifarnikTip.id);
                  e.options.clear();
                }}
              />
              <div className="align-self-center ml-4 w-2rem">
                {sifarnikImport && sifarnikImport.statusUvozaEnum.sifra === EnumStatusUvoza.U_TOKU && <ProgressSpinner className="w-2rem h-2rem" strokeWidth="4" animationDuration="2s" />}
                {sifarnikImport && sifarnikImport.uspesanImport && <i className="pi pi-check-circle p-icon" />}
              </div>
            </div>
            <div className="p-row p-info-text align-self-start h-1rem">
              {sifarnikImport &&
                sifarnikImport.statusUvozaEnum.sifra === EnumStatusUvoza.USPESNO_UVEZEN &&
                sifarnikImport.statusUvozaEnum.naziv + " " + formatDate(sifarnikImport.vremeKreiranja, DATE_FORMAT)}
            </div>
          </div>
        );
      })}
    </>
  );

  const importPanel = () => (sifarnikTipLoading ? importPanelSkeleton() : importPanelContent());

  return (
    <div className="layout-sifarnik-import layout-generic-content-list">
      <Toast ref={toast}></Toast>
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Panel className="p-import-panel mt-6">{importPanel()}</Panel>
      </Panel>
    </div>
  );
}
