import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../../../Store";
import { StorageLimitController } from "../../../../../controllers/storage-limit/StorageLimitController";
import UstanovaStorageLimitController from "../../../../../controllers/ustanova/ustanova-storage-limit/UstanovaStorageLimitController";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../../../model/SearchBaseDto";
import StorageLimitReadDto from "../../../../../model/licenca/storage-limit/StorageLimitReadDto";
import UstanovaStorageLimitReadDto from "../../../../../model/ustanova/licenca/UstanovaStorageLimitReadDto";

interface UstanovaStorageLimitListLogicalType {
  UstanovaStorageLimitList: Array<UstanovaStorageLimitReadDto>;
  firstModul: number;
  tableModulRows: number;
  onPageModul: (rows: number, first: number) => void;
  modulLoading: boolean;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  openUstanovaStorageLimit: (entityOperation: string, id?: number) => void;
  nazivUstanova: string | undefined;
  ustanovaStorageLimitSearchData: SearchBaseDto;
  fetchDataAdmin: () => void;
  changeUstanovaStorageLimitSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  searchUstanovaStorageLimitByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  storageLimitList: Array<StorageLimitReadDto>;
}

interface UstanovaLicencaProps {
  ustanovaId: number | undefined;
  ustanovaNaziv: string | undefined;
}

export default function UstanovaStorageLimitListLogical(props: UstanovaLicencaProps): UstanovaStorageLimitListLogicalType {
  const { showMessage } = useContext(AppContext);
  const [UstanovaStorageLimitList, setUstanovaStorageLimitList] = useState<Array<UstanovaStorageLimitReadDto>>([]);
  const [firstModul, setFirstModul] = useState(0);
  const [tableModulRows, setTableModulRows] = useState(10);
  const { axiosGetUstanovaStorageLimitList, axiosSearchUstanovaStorageLimit } = UstanovaStorageLimitController();
  const { axiosSearchStorageLimitList } = StorageLimitController();
  const [modulLoading, setModulLoading] = useState(false);
  const navigate = useNavigate();
  const { ustanovaId, ustanovaNaziv } = props;
  const Labels = useLabels();
  const [nazivUstanova, setNazivUstanova] = useState<string>();
  const [ustanovaStorageLimitSearchData, setUstanovaStorageLimitSearchData] = useState<SearchBaseDto>({});
  const [storageLimitList, setStorageLimitList] = useState<Array<StorageLimitReadDto>>([]);

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LICENCA_USTANOVA_STORAGE_LIMIT_LIST,
    },
  ];

  useEffectOnce(() => {
    fetchStorageLimit();
    if (ustanovaId === undefined) {
      fetchDataAdmin();
    } else {
      fetchData();
    }
  });

  const fetchStorageLimit = () => {
    axiosSearchStorageLimitList({})
      .then(({ data: { data } }: { data: { data: Array<StorageLimitReadDto> } }) => {
        setStorageLimitList(data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {});
  };

  const fetchData = () => {
    const startTime = moment(new Date());
    setModulLoading(true);
    axiosGetUstanovaStorageLimitList(Number(ustanovaId))
      .then(({ data: { data } }: { data: { data: Array<UstanovaStorageLimitReadDto> } }) => {
        setUstanovaStorageLimitList(data);
        setNazivUstanova(data.length > 0 ? data[0].ustanova.naziv : "");
        setFirstModul(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, startTime);
      })
      .finally(() => {
        skeletonTimeout(setModulLoading, startTime);
      });
  };

  const fetchDataAdmin = () => {
    const startTime = moment(new Date());
    setModulLoading(true);
    axiosSearchUstanovaStorageLimit(ustanovaStorageLimitSearchData)
      .then(({ data: { data } }: { data: { data: Array<UstanovaStorageLimitReadDto> } }) => {
        setUstanovaStorageLimitList(data);
        setNazivUstanova(data.length > 0 ? data[0].ustanova.naziv : "");
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, startTime);
      })
      .finally(() => {
        skeletonTimeout(setModulLoading, startTime);
      });
  };

  const onPageModul = (rows: number, first: number) => {
    setTableModulRows(rows);
    setFirstModul(first);
  };

  const openUstanovaStorageLimit = (entityOperation: string, id?: number) => {
    const UstanovaStorageLimitId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-ustanova/${ustanovaId}/crud-ustanova-storage-limit/${UstanovaStorageLimitId}`, {
      state: { ustanovaStorageLimitOperation: entityOperation, ustanovaNaziv: ustanovaNaziv },
    });
  };

  const searchUstanovaStorageLimitByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchDataAdmin();
    }
  };

  const changeUstanovaStorageLimitSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setUstanovaStorageLimitSearchData({
      ...ustanovaStorageLimitSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  return {
    UstanovaStorageLimitList,
    firstModul,
    tableModulRows,
    onPageModul,
    modulLoading,
    breadCrumbItems,
    openUstanovaStorageLimit,
    nazivUstanova,
    ustanovaStorageLimitSearchData,
    fetchDataAdmin,
    changeUstanovaStorageLimitSearchData,
    searchUstanovaStorageLimitByEnter,
    storageLimitList,
  };
}
