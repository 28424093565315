import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../Store";
import { ObavestenjeController } from "../../../controllers/obavestenje/ObavestenjeController";
import PorukeController from "../../../controllers/poruke/PorukeController";
import { RadnikController } from "../../../controllers/radnik/RadnikController";
import { UstanovaController } from "../../../controllers/ustanova/UstanovaController";
import { handleAxiosCallError, isInicijalizacija } from "../../../infrastructure/system/Utils";
import ObavestenjeOsnovnoReadDto from "../../../model/obavestenje/ObavestenjeOsnovnoReadDto";

interface AppTopbarLogicalType {
  getCurrentRadnik: () => void;
  patchUstanovaPodrazumevanaStrana: (ustanovaId?: number, podrazumevanaStranaSifra?: string) => void;
  isDialogObavestenjeVisiable: boolean;
  closeDialogObavestenje: () => void;
  openDialogObavestenje: () => void;
  obavestenjeListNeprocitano: Array<ObavestenjeOsnovnoReadDto>;
  unreadListLoading: boolean;
  setObavestenjeListNeprocitano: React.Dispatch<React.SetStateAction<Array<ObavestenjeOsnovnoReadDto>>>;
  setUnreadListLoading: React.Dispatch<boolean>;
  neprocitanaObavestenjaCount: number;
  neprocitanePorukeCount: number;
}

export default function AppTopbarLogical(): AppTopbarLogicalType {
  const { authData, updateAuthData, showMessage, neprocitanaObavestenjaCount, setNeprocitanaObavestenjaCount, neprocitanePorukeCount, setNeprocitanePorukeCount } = useContext(AppContext);
  const { axiosReadRadnik } = RadnikController();
  const { axiosPatchUstanovaPodrazumevanaStrana } = UstanovaController();

  const [isDialogObavestenjeVisiable, setDialogObavestenjeVisiable] = useState<boolean>(false);
  const [unreadListLoading, setUnreadListLoading] = useState<boolean>(false);
  const [obavestenjeListNeprocitano, setObavestenjeListNeprocitano] = useState<Array<ObavestenjeOsnovnoReadDto>>([]);
  const { axiosGetObavestenjeUnreadList, axiosGetObavestenjeUnreadCount } = ObavestenjeController();
  const { axiosGetPorukaUnreadCount } = PorukeController();
  const location = useLocation();

  useEffect(() => {
    if (!isInicijalizacija(authData?.currentRadnik!)) {
      getUnreadObavestenjeCount();
      getUnreadPoruke();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getCurrentRadnik = () => {
    authData?.currentRadnik &&
      axiosReadRadnik(authData?.currentRadnik.id, authData?.token)
        .then((res: any) => {
          updateAuthData({
            token: authData!.token,
            currentRadnik: res.data.data,
          });
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
  };

  const patchUstanovaPodrazumevanaStrana = (ustanovaId?: number, podrazumevanaStranaSifra?: string) => {
    ustanovaId &&
      podrazumevanaStranaSifra &&
      axiosPatchUstanovaPodrazumevanaStrana({ id: ustanovaId, podrazumevanaStrana: { sifra: podrazumevanaStranaSifra } })
        .then((res: any) => {
          getCurrentRadnik();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
  };

  const getUnreadObavestenjeCount = () => {
    axiosGetObavestenjeUnreadCount(authData!.currentRadnik.id)
      .then((res: any) => {
        setNeprocitanaObavestenjaCount(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const closeDialogObavestenje = () => {
    setDialogObavestenjeVisiable(false);
    getUnreadObavestenjeCount();
  };

  const openDialogObavestenje = () => {
    setDialogObavestenjeVisiable(true);
    initObavestenjaKorisnik();
  };

  const initObavestenjaKorisnik = () => {
    setUnreadListLoading(true);
    axiosGetObavestenjeUnreadList(authData?.currentRadnik.id!)
      .then((res: any) => {
        setObavestenjeListNeprocitano(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setUnreadListLoading(false);
      });
  };

  const getUnreadPoruke = () => {
    axiosGetPorukaUnreadCount()
      .then((res: any) => {
        setNeprocitanePorukeCount(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return {
    getCurrentRadnik,
    patchUstanovaPodrazumevanaStrana,
    isDialogObavestenjeVisiable,
    closeDialogObavestenje,
    openDialogObavestenje,
    obavestenjeListNeprocitano,
    setObavestenjeListNeprocitano,
    unreadListLoading,
    setUnreadListLoading,
    neprocitanaObavestenjaCount,
    neprocitanePorukeCount,
  };
}
