import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLabels } from "../../../Store";
import Images from "../../../infrastructure/system/Images";
import PorukaCreateDto from "../../../model/poruke/PorukaCreateDto";
import Poruka from "../Poruka";
import SkeletonPorukaListItem from "../SkeletonPorukaListItem";
import PoslataPorukaListItem from "./PoslataPorukaListItem";
import PoslataPorukaListLogical from "./PoslataPorukaListLogical";

export interface PoslataPorukaListViewProps {
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  setAttachmentList: React.Dispatch<React.SetStateAction<Array<any>>>;
  setPorukaChange: React.Dispatch<React.SetStateAction<PorukaCreateDto | undefined>>;
  setRecepients: React.Dispatch<React.SetStateAction<Array<any>>>;
  setAdded: React.Dispatch<React.SetStateAction<number[]>>;
  refreshPoruke: number;
  setRefreshPoruke: React.Dispatch<React.SetStateAction<number>>;
}

export default function PoslataPorukaListView(props: PoslataPorukaListViewProps) {
  const { setIndex, setAttachmentList, setPorukaChange, setRecepients, setAdded, refreshPoruke, setRefreshPoruke } = props;

  const {
    searchPorukaByEnter,
    fetchData,
    searchPoruka,
    poslataPorukaList,
    setPorukaSearchString,
    porukaSearchString,
    poruka,
    setPoruka,
    handlePorukaClick,
    hasMoreMessages,
    handleDelete,
    handleForward,
  } = PoslataPorukaListLogical({ setIndex, setAttachmentList, setPorukaChange, setRecepients, setAdded, refreshPoruke, setRefreshPoruke });
  const Labels = useLabels();

  return (
    <div className="grid min-w-0 h-4">
      <div className="col-5 min-w-0 h-full poruka-list">
        <div className="flex w-full mb-3">
          <InputText
            id="searchString"
            placeholder={Labels.PORUKA_SEARCH}
            name="searchString"
            value={porukaSearchString ?? ""}
            className="w-full poruka-search-input"
            onChange={(e) => setPorukaSearchString(e.target.value)}
            onKeyPress={searchPorukaByEnter}
          />

          <Button className="poruka-search-button" icon={<i className="pi pi-search" />} onClick={searchPoruka} />
        </div>
        <div id="poslataPorukaList" className="min-w-0 scroll-container">
          <InfiniteScroll
            dataLength={poslataPorukaList.length}
            next={fetchData}
            hasMore={hasMoreMessages.current}
            loader={<SkeletonPorukaListItem />}
            endMessage={<p className="text-center font-bold">{Labels.PORUKE_KRAJ}</p>}
            className="poruka-list"
            scrollableTarget="poslataPorukaList"
          >
            {poslataPorukaList.map((p) => (
              <PoslataPorukaListItem key={p.id} poslataPoruka={p} izabrana={p.id === poruka?.id} onClick={() => handlePorukaClick(p)} />
            ))}
          </InfiniteScroll>
        </div>
      </div>
      {poruka ? (
        <Poruka poruka={poruka} setPoruka={setPoruka} handleDelete={handleDelete} handleForward={handleForward} />
      ) : (
        <div className="col-7 h-full align-self-center">
          <div className="flex flex-column hc-gap-5 align-items-center justify-content-center text-xl">
            <img src={Images.PRIKAZI_PORUKU_PLACEHOLDER} alt="" />
            <div>
              {Labels.PRIKAZI_PORUKU_1} <span className="font-bold">{Labels.PRIKAZI_PORUKU_2}</span> {Labels.PRIKAZI_PORUKU_3}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
