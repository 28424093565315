import { useQuery } from "@tanstack/react-query";
import QueryKeys from "../QueryKeys";
import { KursnaListaController } from "../../../controllers/kursna-lista/KursnaListaController";

export function useSrednjiKursForValuta(idSifarnikValuta?: number) {
  const { axiosGetKursForValuta } = KursnaListaController();
  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.KONTAKT_NAPLATA_LIST, idSifarnikValuta],
    queryFn: () => axiosGetKursForValuta(idSifarnikValuta!),
    enabled: !!idSifarnikValuta,
  });
  return { kurs: data?.data?.data, isLoading };
}
