import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../Store";
import { AplikativnaPravaService } from "../../infrastructure/system/AplikativnaPravaService";
import { DAY_DATE_FORMAT, showPatientFullName } from "../../infrastructure/system/Utils";
import ZakazivanjePregledaLogical from "./ZakazivanjePregledaLogical";
import OrganizacioneJediniceNavbar from "./organizacione-jedinice-navbar/OrganizacioneJediniceNavbar";
import RadnikList from "./radnik-list/RadnikList";
import Termini from "./termini/Termini";
import CalendarWeekView from "./termini/calendar-week-view/CalendarWeekView";
import VirtuelnaCekaonicaView from "./virtuelna-cekaonica/VirtuelnaCekanoicaView";

export default function ZakazivanjePregledaView() {
  const {
    selectedDate,
    setSelectedDate,
    dates,
    dateBack,
    dateForward,
    radnikList,
    radnikListIsLoading,
    selectedRadnik,
    setSelectedRadnik,
    activeIndex,
    setActiveIndex,
    filterDatesForGrid,
    selectedPacijent,
    printProtokolOrZakazaniTerminiList,
    prikaziNeaktivne,
    setPrikaziNeaktivne,
  } = ZakazivanjePregledaLogical();
  const Labels = useLabels();
  const { pristup, odabranaOrganizacionaJedinicaList } = useContext(AppContext);

  const { hasZakazivanjeTermina } = AplikativnaPravaService();

  const navigate = useNavigate();

  const headerTemplate = () => {
    return (
      <div className="p-panel-header py-2">
        <span className="p-panel-title flex" id="pr_id_1_header">
          <b>{Labels.ZAKAZIVANJE_TERMINI}</b>
          <Divider layout="vertical" className="p-0" />
          <b> {`${selectedRadnik?.ime} ${selectedRadnik?.prezime}`}</b>
          <span>{`, ${selectedDate?.format(DAY_DATE_FORMAT).toLowerCase()}`}</span>
        </span>
      </div>
    );
  };

  return (
    <div className="layout-zakazivanje">
      <Panel className={`top-border ${isMobile ? "mobile" : ""}`}>
        {selectedPacijent && (
          <Panel className="top-border mb-3 ">
            <div className="flex">
              {`${showPatientFullName(selectedPacijent)} `}
              <Button
                icon="pi pi-times"
                className="p-button-outlined ml-2 border-none py-0 orange-x"
                onClick={() => {
                  navigate(`/zakazivanje-pregleda`);
                }}
                disabled={!pristup}
              />
            </div>
          </Panel>
        )}
        <OrganizacioneJediniceNavbar printProtokolOrZakazaniTerminiList={printProtokolOrZakazaniTerminiList} showPrint />
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} renderActiveOnly>
          <TabPanel header={Labels.ZAKAZIVANJE_ZAKAZIVANJE} disabled={!hasZakazivanjeTermina}>
            {odabranaOrganizacionaJedinicaList.length > 0 && odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1]?.podredjeneOrganizacioneJedinice?.length === 0 && (
              <>
                <RadnikList
                  radnikList={radnikList}
                  radnikListIsLoading={radnikListIsLoading}
                  selectedRadnik={selectedRadnik}
                  setSelectedRadnik={setSelectedRadnik}
                  prikaziNeaktivne={prikaziNeaktivne}
                  setPrikaziNeaktivne={setPrikaziNeaktivne}
                />
                {selectedRadnik && (
                  <Termini
                    odabranaOrgJed={odabranaOrganizacionaJedinicaList}
                    radnikList={radnikList}
                    selectedRadnik={selectedRadnik}
                    filterDatesForGrid={filterDatesForGrid}
                    selectedPacijent={selectedPacijent}
                    prikaziNeaktivne={prikaziNeaktivne}
                  />
                )}
              </>
            )}
          </TabPanel>
          {!selectedPacijent && (
            <TabPanel header={Labels.ZAKAZIVANJE_CEKAONICA}>
              {odabranaOrganizacionaJedinicaList.length > 0 && odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1]?.podredjeneOrganizacioneJedinice?.length === 0 && (
                <>
                  <RadnikList
                    radnikList={radnikList}
                    radnikListIsLoading={radnikListIsLoading}
                    selectedRadnik={selectedRadnik}
                    setSelectedRadnik={setSelectedRadnik}
                    prikaziNeaktivne={prikaziNeaktivne}
                    setPrikaziNeaktivne={setPrikaziNeaktivne}
                  />
                  {selectedRadnik && (
                    <Panel headerTemplate={headerTemplate} className="mt-3 hc-scheduler hc-scheduler--v">
                      <CalendarWeekView
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        dates={dates}
                        parseDatesForGrid={filterDatesForGrid}
                        leftButton={dateBack}
                        rigthButton={dateForward}
                        dateSelectEnabled
                      />
                      <VirtuelnaCekaonicaView
                        selectedRadnik={selectedRadnik}
                        odabranaOrgJed={odabranaOrganizacionaJedinicaList}
                        selectedDate={selectedDate}
                        radnikList={radnikList}
                        prikaziNeaktivne={prikaziNeaktivne}
                      />
                    </Panel>
                  )}
                </>
              )}
            </TabPanel>
          )}
        </TabView>
      </Panel>
    </div>
  );
}
