import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { DodatniModulController } from "../../../../controllers/dodatni-modul/DodatniModulController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import DodatniModulReadDto from "../../../../model/licenca/dodatni-modul/DodatniModulReadDto";

interface CrudDodatniModulListLogicalType {
  dtModul: any;
  dodatniModulList: Array<DodatniModulReadDto>;
  firstModul: number;
  tableModulRows: number;
  onPageModul: (rows: number, first: number) => void;
  modulLoading: boolean;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  openDodatniModul: (entityOperation: string, id?: number) => void;
}

export default function CrudDodatniModulListLogical(): CrudDodatniModulListLogicalType {
  const { showMessage } = useContext(AppContext);
  const [dodatniModulList, setDodatniModulList] = useState<Array<DodatniModulReadDto>>([]);
  const [firstModul, setFirstModul] = useState(0);
  const [tableModulRows, setTableModulRows] = useState(10);
  const dtModul = useRef<any>(null);
  const { axiosSearchDodatniModulList } = DodatniModulController();
  const [modulLoading, setModulLoading] = useState(false);
  const navigate = useNavigate();
  const Labels = useLabels();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LICENCA_LIST,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const startTime = moment(new Date());
    setModulLoading(true);
    axiosSearchDodatniModulList({})
      .then(({ data: { data } }: { data: { data: Array<DodatniModulReadDto> } }) => {
        setDodatniModulList(data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setModulLoading, startTime);
      });
  };

  const onPageModul = (rows: number, first: number) => {
    setTableModulRows(rows);
    setFirstModul(first);
  };
  const openDodatniModul = (entityOperation: string, id?: number) => {
    const dodatniModulId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-dodatni-modul/${dodatniModulId}`, {
      state: { dodatniModulOperation: entityOperation },
    });
  };

  return {
    dtModul,
    dodatniModulList,
    firstModul,
    tableModulRows,
    onPageModul,
    modulLoading,
    breadCrumbItems,
    openDodatniModul,
  };
}
