import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import { formatDate, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CalendarComponent from "../calendar-component/CalendarComponent";
import CrudExportLogical from "./CrudExportLogical";

export default function CrudExportView() {
  const { breadCrumbItems, onCancel, onCreate, izvozCreate, setIzvozCreate, invalidFields, setInvalidFields, datumOdRef, datumDoRef } = CrudExportLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const leftContentsToolbar = () => (
    <>
      <Button
        label={Labels.BUTTON_CREATE}
        disabled={!pristup}
        tooltip={Labels.IZVOZ_NOVI_ZAHTEV_KREIRANJE}
        tooltipOptions={tooltipOptionsBottom}
        icon="pi pi-plus"
        className="p-button-success"
        onClick={onCreate}
      />
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={onCancel} />
    </>
  );
  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
          <TabPanel header={Labels.IZVOZ_NOVI_ZAHTEV}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.IZVOZ_DIALOG_CREATE_DATUM_POCETKA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  <>
                    <CalendarComponent
                      ref={datumOdRef}
                      value={izvozCreate?.periodOd}
                      onChange={(e: CalendarChangeEvent) => {
                        setIzvozCreate({
                          ...izvozCreate!,
                          periodOd: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                        });
                        setInvalidFields((prev) => ({ ...prev, periodOd: false, invalidPeriod: false }));
                        if (e.target.value) datumOdRef?.current?.hide();
                      }}
                      onClearButtonClick={() => {
                        setInvalidFields((prev) => ({ ...prev, periodOd: true }));
                      }}
                      disabled={!pristup}
                      className={"w-full " + ((invalidFields?.periodOd || invalidFields?.invalidPeriod) && "p-invalid")}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                    {invalidFields?.periodOd && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                  </>
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.IZVOZ_DIALOG_CREATE_DATUM_ZAVRSETKA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  <>
                    <CalendarComponent
                      ref={datumDoRef}
                      value={izvozCreate?.periodDo}
                      onChange={(e: CalendarChangeEvent) => {
                        setIzvozCreate({
                          ...izvozCreate!,
                          periodDo: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                        });
                        setInvalidFields((prev) => ({ ...prev, periodDo: false, invalidPeriod: false }));
                        if (e.target.value) datumDoRef?.current?.hide();
                      }}
                      onClearButtonClick={() => {
                        setInvalidFields((prev) => ({ ...prev, periodDo: true }));
                      }}
                      disabled={!pristup}
                      className={"w-full " + ((invalidFields?.periodDo || invalidFields?.invalidPeriod) && "p-invalid")}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                    {invalidFields?.periodDo && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    {invalidFields?.invalidPeriod && <ObaveznoPolje text={Labels.IZVOZ_VAZI_OD_IS_BEFORE_VAZI_DO} />}
                  </>
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.IZVOZ_DIALOG_CREATE_RAZLOG}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  <InputTextarea
                    rows={3}
                    cols={5}
                    disabled={!pristup}
                    value={izvozCreate?.razlogZahteva}
                    onChange={(e) => {
                      setIzvozCreate({
                        ...izvozCreate!,
                        razlogZahteva: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
