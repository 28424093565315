import { PickListChangeEvent } from "primereact/picklist";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { RadnikController } from "../../../../controllers/radnik/RadnikController";
import { RolaService } from "../../../../controllers/rola/RolaService";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import { UlogaController } from "../../../../controllers/uloga/UlogaController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import EnumRola from "../../../../infrastructure/system/EnumRola";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";

import RadnikReadDto from "../../../../model/radnik/RadnikReadDto";
import RadnikUlogaReadDto from "../../../../model/radnik/RadnikUlogaReadDto";
import UlogaReadDto from "../../../../model/uloga/UlogaReadDto";
import { AppContext, useLabels } from "../../../../Store";

interface RadnikCrudUlogaLogicalType {
  radnikUlogaList: Array<RadnikUlogaReadDto>;
  ulogaList: Array<RadnikUlogaHolder | undefined>;
  onChangeRadnikUloga: (event: PickListChangeEvent) => Promise<void>;
  isBlocked: boolean;
  isLoading: boolean;
}
interface RadnikCrudUlogaLogicalPropType {
  radnikOperation: string;
  ustanovaId?: number;
  radnikIme?: string;
  radnikPrezime?: string;
  radnikChange: RadnikReadDto | undefined;
  setRadnikChange: React.Dispatch<React.SetStateAction<RadnikReadDto | undefined>>;
}
interface UseParamsType {
  radnikId?: string;
}
export interface RadnikUlogaHolder {
  uloga: UlogaReadDto;
}
export default function CrudRadnikUlogaLogical(props: RadnikCrudUlogaLogicalPropType): RadnikCrudUlogaLogicalType {
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { radnikOperation, ustanovaId, radnikIme, radnikPrezime, radnikChange, setRadnikChange } = props;
  const { showMessage } = useContext(AppContext);
  const [radnikUlogaList, setRadnikUlogaList] = useState<Array<RadnikUlogaReadDto>>([]);
  const [ulogaList, setUlogaList] = useState<Array<RadnikUlogaHolder>>([]);
  const { axiosCreateRadnikUloga, axiosDeleteRadnikUloga, axiosGetRadnikUlogaList } = RadnikController();
  const { axiosSuperadminCreateRadnikUloga, axiosSuperadminDeleteRadnikUloga, axiosSuperadminGetRadnikUlogaList, axiosSuperadminUstanovaGetUlogaList } = SuperadminController();
  const { isSuperadmin } = RolaService();
  const { axiosSearchUloga } = UlogaController();
  const radnikId: number = Number(useParams<keyof UseParamsType>()["radnikId"]);
  const prevRadnikId = useRef<number | undefined>(undefined);
  const prevRadnikOperation = useRef<string | undefined>(undefined);
  const Labels = useLabels();

  const postLogHighLevel = useLogHighLevel();

  const getUlogaList = useCallback(
    (radnikUlogaList: Array<RadnikUlogaReadDto>) => {
      setIsLoading(true);
      let requestGetUlogaList = ustanovaId ? axiosSuperadminUstanovaGetUlogaList(ustanovaId) : axiosSearchUloga({});
      requestGetUlogaList
        .then((res: any) => {
          let ulogaListTemp: Array<RadnikUlogaHolder> = [];
          const allUloga = isSuperadmin ? res.data.data : res.data.data.filter((uloga: UlogaReadDto) => uloga.rola.sifra !== EnumRola.SUPERADMIN);
          allUloga.forEach((uloga: UlogaReadDto) => {
            if (
              radnikUlogaList.filter((radnikUloga: RadnikUlogaReadDto) => {
                return radnikUloga.uloga.id === uloga.id;
              }).length === 0
            ) {
              ulogaListTemp.push({ uloga: uloga });
            }
          });
          setUlogaList(ulogaListTemp);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosSearchUloga, setIsLoading, showMessage, axiosSuperadminUstanovaGetUlogaList, ustanovaId, isSuperadmin]
  );
  const getRadnikUloga = useCallback(() => {
    if (radnikId !== null && radnikId !== undefined) {
      setIsLoading(true);
      let requestRadnikUlogaList = ustanovaId ? axiosSuperadminGetRadnikUlogaList(ustanovaId, radnikId) : axiosGetRadnikUlogaList(radnikId);

      requestRadnikUlogaList
        .then((res: any) => {
          const radnikUlogaList = res.data.data;

          setRadnikUlogaList(radnikUlogaList);
          getUlogaList(radnikUlogaList);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setIsLoading(false);
        });
    }
  }, [axiosGetRadnikUlogaList, getUlogaList, radnikId, setIsLoading, showMessage, ustanovaId, axiosSuperadminGetRadnikUlogaList]);
  const fetchData = useCallback(() => {
    getRadnikUloga();
  }, [getRadnikUloga]);

  useEffect(() => {
    if (radnikId && prevRadnikId.current !== radnikId && prevRadnikOperation.current !== radnikOperation && radnikOperation !== EntityOperation.CREATE) {
      prevRadnikOperation.current = radnikOperation;
      prevRadnikId.current = radnikId;
      fetchData();
    }
  }, [fetchData, radnikId, radnikOperation]);

  const onChangeRadnikUloga = async (event: PickListChangeEvent) => {
    if (isBlocked || isLoading) {
      return;
    }
    let opis = "";
    setIsBlocked(true);
    let target = [...event.target];
    if (radnikUlogaList.length < event.target.length) {
      await Promise.all(
        event.target.map(async (radnikUloga: RadnikUlogaHolder) => {
          const ulogaId = radnikUloga.uloga?.id;
          const notInRadnikUloga = !radnikUlogaList.some((ru: RadnikUlogaReadDto) => ru?.uloga?.id === ulogaId);
          if (notInRadnikUloga) {
            let requestCreateRadnikUloga = ustanovaId
              ? axiosSuperadminCreateRadnikUloga(ustanovaId, radnikId, ulogaId)
              : axiosCreateRadnikUloga(radnikId, { radnik: { id: radnikId }, uloga: { id: ulogaId } });
            await requestCreateRadnikUloga
              .then((res: any) => {
                opis +=
                  opis === ""
                    ? Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_1 + radnikIme + " " + radnikPrezime + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_2 + radnikUloga.uloga.naziv
                    : ", " + radnikUloga.uloga.naziv;
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
                const deleteIndex = target.findIndex((x: any) => x.uloga.id === ulogaId);
                target.splice(deleteIndex, 1);
              });
          }
        })
      ).finally(() => {
        setRadnikUlogaList(target);
        setRadnikChange({
          ...radnikChange!,
          radnikUlogaList: target,
        });
        setUlogaList(ulogaList.filter((element?: RadnikUlogaHolder) => !target.includes(element)));
      });
    } else if (ulogaList.length < event.source.length) {
      await Promise.all(
        event.source.map(async (radnikUloga: RadnikUlogaHolder) => {
          if (!ulogaList.includes(radnikUloga)) {
            const ulogaId = radnikUloga.uloga?.id;
            let requestDeleteRadnikUloga = ustanovaId ? axiosSuperadminDeleteRadnikUloga(ustanovaId, radnikId, ulogaId) : axiosDeleteRadnikUloga(radnikId, ulogaId);
            await requestDeleteRadnikUloga
              .then((res: any) => {
                opis +=
                  opis === ""
                    ? Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_1 + radnikIme + " " + radnikPrezime + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_3 + radnikUloga.uloga.naziv
                    : ", " + radnikUloga.uloga.naziv;
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
                target = [...target, radnikUloga];
              });
          }
        })
      ).finally(() => {
        setRadnikUlogaList(target);
        setRadnikChange({
          ...radnikChange!,
          radnikUlogaList: target,
        });
        setUlogaList(ulogaList.concat(radnikUlogaList.filter((element?: RadnikUlogaReadDto) => !target.includes(element))));
      });
    }
    opis += ".";
    postLogHighLevel(opis);
    setIsBlocked(false);
  };

  return {
    onChangeRadnikUloga,
    radnikUlogaList,
    ulogaList,
    isBlocked,
    isLoading,
  };
}
