import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";

import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { RadnikController } from "../../../../controllers/radnik/RadnikController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";

import OrganizacionaJedinicaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import RadnikOrganizacionaJedinicaCreateDto from "../../../../model/radnik/RadnikOrganizacionaJedinicaCreateDto";
import RadnikOrganizacionaJedinicaReadDto from "../../../../model/radnik/RadnikOrganizacionaJedinicaReadDto";
import { AppContext, useLabels } from "../../../../Store";

interface RadnikCrudOrganizacionaJedinicaLogicalPropType {
  radnikOperation: string;
  ustanovaId?: number;
  radnikIme?: string;
  radnikPrezime?: string;
}

interface CrudRadnikOrganizacionaJedinicaLogicalType {
  radnikOrganizacionaJedinicaList: Array<RadnikOrganizacionaJedinicaReadDto>;
  organizacionaJedinicaList: Array<RadnikOrganizacionaJedinicaHolder>;
  isBlocked: boolean;
  isLoading: boolean;
  sourceSelection: RadnikOrganizacionaJedinicaHolder[];
  setSourceSelection: React.Dispatch<React.SetStateAction<RadnikOrganizacionaJedinicaHolder[]>>;
  targetSelection: RadnikOrganizacionaJedinicaReadDto[];
  setTargetSelection: React.Dispatch<React.SetStateAction<RadnikOrganizacionaJedinicaReadDto[]>>;
  onMoveToTarget: (orgJedinice: RadnikOrganizacionaJedinicaHolder[]) => void;
  onMoveToSource: (orgJedinice: RadnikOrganizacionaJedinicaReadDto[]) => void;
}
interface UseParamsType {
  radnikId?: string;
}
export interface RadnikOrganizacionaJedinicaHolder {
  organizacionaJedinica: OrganizacionaJedinicaReadDto;
}

export default function CrudRadnikOrganizacionaJedinicaLogical(props: RadnikCrudOrganizacionaJedinicaLogicalPropType): CrudRadnikOrganizacionaJedinicaLogicalType {
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { radnikOperation, ustanovaId, radnikIme, radnikPrezime } = props;
  const [radnikOrganizacionaJedinicaList, setRadnikOrganizacionaJedinicaList] = useState<Array<RadnikOrganizacionaJedinicaReadDto>>([]);
  const [organizacionaJedinicaList, setOrganizacionaJedinicaList] = useState<Array<RadnikOrganizacionaJedinicaHolder>>([]);
  const [sourceSelection, setSourceSelection] = useState<RadnikOrganizacionaJedinicaHolder[]>([]);
  const [targetSelection, setTargetSelection] = useState<RadnikOrganizacionaJedinicaReadDto[]>([]);
  const { axiosCreateRadnikOrganizacionaJedinica, axiosDeleteRadnikOrganizacionaJedinica, axiosGetRadnikOrganizacionaJedinicaList } = RadnikController();
  const {
    axiosSuperadminCreateRadnikOrganizacionaJedinica,
    axiosSuperadminDeleteRadnikOrganizacionaJedinica,
    axiosSuperadminGetRadnikOrganizacionaJedinicaList,
    axiosSuperadminGetUstanovaOrganizacionaJedinicaList,
  } = SuperadminController();

  const { axiosSearchOrganizacionaJedinica } = OrganizacionaJedinicaController();

  const { showMessage } = useContext(AppContext);
  const postLogHighLevel = useLogHighLevel();

  const Labels = useLabels();
  const radnikId: number = Number(useParams<keyof UseParamsType>()["radnikId"]);
  const prevRadnikId = useRef<number | undefined>(undefined);
  const prevRadnikOperation = useRef<string | undefined>(undefined);

  const getOrganizacionaJedinicaList = useCallback(
    (radnikOrganizacionaJedinicaList: Array<RadnikOrganizacionaJedinicaReadDto>) => {
      setIsLoading(true);
      let requestOrganizacionaJedinicaList = ustanovaId ? axiosSuperadminGetUstanovaOrganizacionaJedinicaList(ustanovaId) : axiosSearchOrganizacionaJedinica({});
      requestOrganizacionaJedinicaList
        .then((res: any) => {
          let organizacionaJedinicaListTemp: Array<RadnikOrganizacionaJedinicaHolder> = [];
          const allOrganizacionaJedinica: Array<OrganizacionaJedinicaReadDto> = res.data.data;
          allOrganizacionaJedinica.forEach((organizacionaJedinica: OrganizacionaJedinicaReadDto) => {
            if (
              radnikOrganizacionaJedinicaList.filter((radnikOrganizacionaJedinica: RadnikOrganizacionaJedinicaReadDto) => {
                return radnikOrganizacionaJedinica.organizacionaJedinica.id === organizacionaJedinica.id;
              }).length === 0
            ) {
              organizacionaJedinicaListTemp.push({ organizacionaJedinica: organizacionaJedinica });
            }
          });

          setOrganizacionaJedinicaList(organizacionaJedinicaListTemp);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosSearchOrganizacionaJedinica, setIsLoading, showMessage, axiosSuperadminGetUstanovaOrganizacionaJedinicaList, ustanovaId]
  );

  const getRadnikOrganizacionaJedinica = useCallback(() => {
    if (radnikId !== null && radnikId !== undefined) {
      setIsLoading(true);
      let requestRadnikOrganizacionaJedinicaList = ustanovaId ? axiosSuperadminGetRadnikOrganizacionaJedinicaList(ustanovaId, radnikId) : axiosGetRadnikOrganizacionaJedinicaList(radnikId);
      requestRadnikOrganizacionaJedinicaList
        .then((res: any) => {
          const radnikOrganizacionaJedinicaList: Array<RadnikOrganizacionaJedinicaReadDto> = res.data.data;

          setRadnikOrganizacionaJedinicaList(radnikOrganizacionaJedinicaList);
          getOrganizacionaJedinicaList(radnikOrganizacionaJedinicaList);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setIsLoading(false);
        });
    }
  }, [axiosGetRadnikOrganizacionaJedinicaList, getOrganizacionaJedinicaList, radnikId, setIsLoading, showMessage, axiosSuperadminGetRadnikOrganizacionaJedinicaList, ustanovaId]);

  const fetchData = useCallback(() => {
    getRadnikOrganizacionaJedinica();
  }, [getRadnikOrganizacionaJedinica]);

  useEffect(() => {
    if (radnikId && prevRadnikId.current !== radnikId && prevRadnikOperation.current !== radnikOperation && radnikOperation !== EntityOperation.CREATE) {
      prevRadnikOperation.current = radnikOperation;
      prevRadnikId.current = radnikId;
      fetchData();
    }
  }, [fetchData, radnikId, radnikOperation]);

  const onMoveToTarget = (orgJedinice: RadnikOrganizacionaJedinicaHolder[]) => {
    setIsLoading(true);
    setIsBlocked(true);

    let radnikOrgJedinicaChangeArray: RadnikOrganizacionaJedinicaCreateDto[] = [];
    orgJedinice.forEach((orgJedinica: RadnikOrganizacionaJedinicaHolder) => {
      radnikOrgJedinicaChangeArray.push({
        radnik: { id: radnikId },
        organizacionaJedinica: { id: orgJedinica?.organizacionaJedinica?.id },
      });
    });

    (ustanovaId ? axiosSuperadminCreateRadnikOrganizacionaJedinica(ustanovaId, radnikId, radnikOrgJedinicaChangeArray) : axiosCreateRadnikOrganizacionaJedinica(radnikId, radnikOrgJedinicaChangeArray))
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        const orgJediniceNazivi = orgJedinice.map((orgJedinica) => orgJedinica.organizacionaJedinica.naziv).join(", ");
        const sigleOrMultipleLabel = orgJedinice.length > 1 ? Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_4 : Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_2;
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_1 + radnikIme + " " + radnikPrezime + sigleOrMultipleLabel + orgJediniceNazivi + ".");
        fetchData();
        setSourceSelection([]);
        setTargetSelection([]);
        setIsBlocked(false);
        setIsLoading(false);
      });
  };

  const onMoveToSource = (orgJedinice: RadnikOrganizacionaJedinicaReadDto[]) => {
    setIsLoading(true);
    setIsBlocked(true);

    let radnikOrgJedinicaChangeArray: RadnikOrganizacionaJedinicaCreateDto[] = [];
    orgJedinice.forEach((orgJedinica: RadnikOrganizacionaJedinicaReadDto) => {
      radnikOrgJedinicaChangeArray.push({
        radnik: { id: radnikId },
        organizacionaJedinica: { id: orgJedinica?.organizacionaJedinica?.id },
      });
    });
    let orgJediniceIdList: Array<number> = [];

    radnikOrgJedinicaChangeArray.map((orgJedinica) => orgJediniceIdList.push(orgJedinica?.organizacionaJedinica?.id!));

    (ustanovaId ? axiosSuperadminDeleteRadnikOrganizacionaJedinica(ustanovaId, radnikId, [...orgJediniceIdList]) : axiosDeleteRadnikOrganizacionaJedinica(radnikId, [...orgJediniceIdList]))
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        const orgJediniceNazivi = orgJedinice.map((orgJedinica) => orgJedinica?.organizacionaJedinica?.naziv).join(", ");
        const sigleOrMultipleLabel = orgJedinice.length > 1 ? Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_5 : Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_3;
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_1 + radnikIme + " " + radnikPrezime + sigleOrMultipleLabel + orgJediniceNazivi + ".");
        fetchData();
        setSourceSelection([]);
        setTargetSelection([]);
        setIsBlocked(false);
        setIsLoading(false);
      });
  };

  return {
    radnikOrganizacionaJedinicaList,
    organizacionaJedinicaList,
    isBlocked,
    isLoading,
    sourceSelection,
    setSourceSelection,
    targetSelection,
    setTargetSelection,
    onMoveToTarget,
    onMoveToSource,
  };
}
