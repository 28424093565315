import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import UstanovaReadDto from "../../../model/ustanova/UstanovaReadDto";
import { AppContext, useLabels } from "./../../../Store";

interface UstanovaListLogicalType {
  ustanovaList: Array<UstanovaReadDto>;
  first: number;
  tableRows: number;
  changeUstanovaSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  ustanovaSearchData: SearchBaseDto;
  fetchData: () => void;
  searchUstanovaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  onPageUstanova: (rows: number, first: number) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  ustanovaListLoading: boolean;
}

export default function UstanovaListLogical(): UstanovaListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [ustanovaList, setUstanovaList] = useState<Array<UstanovaReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [ustanovaSearchData, setUstanovaSearchData] = useState<SearchBaseDto>({});
  const [ustanovaListLoading, setUstanovaListLoading] = useState(false);

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.USTANOVA_LIST,
    },
  ];
  const { axiosSuperadminSearchUstanova } = SuperadminController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setUstanovaListLoading(true);
    axiosSuperadminSearchUstanova(ustanovaSearchData)
      .then((res: any) => {
        setUstanovaList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((ustanova: UstanovaReadDto) => {
          exportList.push({
            [Labels.USTANOVA_SIFRA]: ustanova.sifra,
            [Labels.USTANOVA_NAZIV]: ustanova.naziv,
            [Labels.USTANOVA_ADRESA]: ustanova.adresa,
            [Labels.USTANOVA_TELEFON]: ustanova.telefon,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setUstanovaListLoading, startTime);
      });
  };

  const changeUstanovaSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setUstanovaSearchData({
      ...ustanovaSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchUstanovaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageUstanova = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    ustanovaList,
    first,
    tableRows,
    changeUstanovaSearchData,
    ustanovaSearchData,
    fetchData,
    searchUstanovaByEnter,
    onPageUstanova,
    exportData,
    breadCrumbItems,
    ustanovaListLoading,
  };
}
