import { InputText } from "primereact/inputtext";
import { PoljeType } from "../Polje";

export default function Tekst(props: PoljeType) {
  const { id, onChange, vrednost, readonly, onClick, useVrednostList, vrednostList } = props;

  return (
    <InputText
      disabled={readonly ?? false}
      className="w-full"
      onChange={(e) => {
        onChange({ id: id, vrednost: e.target.value });
      }}
      onClick={() => onClick && onClick(id)}
      value={useVrednostList && vrednostList ? vrednostList[id] ?? "" : vrednost ?? ""}
    />
  );
}
