import { decode as base64_decode } from "base-64";
import moment from "moment";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import { AppContext, useLabels } from "../../../../Store";
import { FormularController } from "../../../../controllers/formular/FormularController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { DATE_TIME_FORMAT, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import FormularPopunjenReadDto from "../../../../model/formular/FormularPopunjenReadDto";

interface ListaDocumenataLogicalType {
  selectedFormularPopunjen: FormularPopunjenReadDto | any;
  setSelectedFormularPopunjen: React.Dispatch<React.SetStateAction<FormularPopunjenReadDto | any>>;
  shiftFormularPopunjenBack: (item: FormularPopunjenReadDto) => void;
  shiftFormularPopunjenForward: (item: FormularPopunjenReadDto) => void;
  formularPopunjenWithDokumentList: Array<FormularPopunjenReadDto>;
  renderImePrezime: (column: FormularPopunjenReadDto) => string | undefined;
  renderOrd: (column: FormularPopunjenReadDto) => string;
  renderFormName: (column: FormularPopunjenReadDto) => string;
  renderDateTime: (column: FormularPopunjenReadDto) => string;
  handlePrint: (content: string, isHtml: boolean) => boolean;
  deleteDokument: (selectedFormularPopunjen: FormularPopunjenReadDto) => void;
  dokumentListLoading: boolean;
  onPageFormTable: (rows: number, first: number) => void;
  first: number;
  tableRows: number;
}
interface useParamsType {
  id?: string;
}
export default function ListaDocumenataLogical(): ListaDocumenataLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const [selectedFormularPopunjen, setSelectedFormularPopunjen] = useState<FormularPopunjenReadDto | undefined>();
  const { axiosGetFormularPopunjenWithDokumentList, axiosDeleteFormularPopunjenFromDokumentList } = FormularController();
  const [formularPopunjenWithDokumentList, setFormularPopunjenWithDokumentList] = useState<Array<FormularPopunjenReadDto>>([]);
  const [dokumentListLoading, setDokumentListLoading] = useState<boolean>(true);
  const [tableRows, setTableRows] = useState<number>(10);
  const [first, setFirst] = useState<number>(0);
  const id: number = Number(useParams<keyof useParamsType>()["id"]);
  const Labels = useLabels();

  useEffectOnce(() => {
    let startTime = moment(new Date());
    if (id)
      axiosGetFormularPopunjenWithDokumentList(id)
        .then((res: any) => {
          setFormularPopunjenWithDokumentList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setDokumentListLoading, startTime);
        });
  });

  const shiftFormularPopunjenBack = (item: FormularPopunjenReadDto) => {
    formularPopunjenWithDokumentList.forEach((formularPopunjen: FormularPopunjenReadDto, index: number) => {
      if (item?.id === formularPopunjen?.id) {
        if (index > 0) {
          setSelectedFormularPopunjen(formularPopunjenWithDokumentList[index - 1]);
        } else {
          setSelectedFormularPopunjen(formularPopunjenWithDokumentList[formularPopunjenWithDokumentList.length - 1]);
        }
      }
    });
  };

  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const shiftFormularPopunjenForward = (item: FormularPopunjenReadDto) => {
    formularPopunjenWithDokumentList.forEach((formularPopunjen: FormularPopunjenReadDto, index: number) => {
      if (item?.id === formularPopunjen?.id) {
        if (index < formularPopunjenWithDokumentList.length - 1) {
          setSelectedFormularPopunjen(formularPopunjenWithDokumentList[index + 1]);
        } else {
          setSelectedFormularPopunjen(formularPopunjenWithDokumentList[0]);
        }
      }
    });
  };

  const renderImePrezime = (column: FormularPopunjenReadDto) => {
    if (column?.radnikKreirao) {
      return `${column?.radnikKreirao?.titula ?? ""} ${column?.radnikKreirao?.ime} ${column?.radnikKreirao?.prezime}`;
    }
  };

  const renderOrd = (column: FormularPopunjenReadDto) => {
    return column?.kontakt?.organizacionaJedinica ? column?.kontakt?.organizacionaJedinica?.naziv : "";
  };

  const renderFormName = (column: FormularPopunjenReadDto) => {
    return column?.formular?.naziv ? column?.formular?.naziv : "";
  };

  const renderDateTime = (column: FormularPopunjenReadDto) => moment(column?.vremeKreiranja).format(DATE_TIME_FORMAT);

  const handlePrint = (content: string, isHtml: boolean) => {
    if (isHtml) {
      let mywindow = window.open("", "PRINT", "height=800,width=1000");
      if (mywindow) {
        mywindow.document.write(content);
        mywindow.document.close();
        mywindow.focus();
        mywindow.print();
        mywindow.close();
      }
    } else {
      let decoded = base64_decode(content);
      window.open(`data:application/pdf;base64,${decoded}`, "PRINT", "height=800,width=1000");
    }
    return true;
  };

  const deleteDokument = (selectedFormularPopunjen: FormularPopunjenReadDto) => {
    setShowBlockUI(true);
    axiosDeleteFormularPopunjenFromDokumentList(selectedFormularPopunjen?.id)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_DOKUMENT_SUCCESS);
        formularPopunjenWithDokumentList.forEach((formularPopunjen: FormularPopunjenReadDto, index: number) => {
          if (selectedFormularPopunjen?.id === formularPopunjen?.id) {
            delete formularPopunjenWithDokumentList[index];
            setSelectedFormularPopunjen(formularPopunjenWithDokumentList[index]);
          }
        });
        setFormularPopunjenWithDokumentList(formularPopunjenWithDokumentList);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return {
    selectedFormularPopunjen,
    setSelectedFormularPopunjen,
    shiftFormularPopunjenBack,
    shiftFormularPopunjenForward,
    formularPopunjenWithDokumentList,
    renderImePrezime,
    renderOrd,
    renderFormName,
    renderDateTime,
    handlePrint,
    deleteDokument,
    dokumentListLoading,
    onPageFormTable,
    first,
    tableRows,
  };
}
