import axios from "axios";
import moment, { Moment } from "moment";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dispatch, DragEvent, RefObject, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { AppContext, useLabels } from "../../../Store";
import { PacijentPoljeController } from "../../../controllers/pacijent-polje/PacijentPoljeController";
import { PacijentController } from "../../../controllers/pacijent/PacijentController";
import { RadniPeriodController } from "../../../controllers/radni-period/RadniPeriodController";
import { ZakazaniTerminController } from "../../../controllers/zakazani-termin/ZakazaniTerminController";
import EnumPacijentPoljeKategorija from "../../../infrastructure/system/EnumPacijentPoljeKategorija";
import EnumTerminTip from "../../../infrastructure/system/EnumTerminTip";
import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import EnumZakazanTerminStatus from "../../../infrastructure/system/EnumZakazanTerminStatus";
import MessageType from "../../../infrastructure/system/MessageType";
import {
  DATE_FORMAT,
  DATE_FORMAT_2,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_3,
  HOUR_FORMAT,
  TIME_FORMAT,
  checkEmpty,
  formatDate2,
  handleAxiosCallError,
  handleAxiosCallSuccess,
  radnoVremeOrgJediniceGlobal,
  terminStatusFieldType,
  useEffectOnce,
} from "../../../infrastructure/system/Utils";
import { useZakazanTerminStatusList } from "../../../infrastructure/system/hooks/enum/useZakazanTerminStatusList";
import { useOrganizacionaJedinica } from "../../../infrastructure/system/hooks/organizaciona-jedinica/useOrganizacionaJedinica";
import { useOrganizacionaJedinicaKontaktTipList } from "../../../infrastructure/system/hooks/organizaciona-jedinica/useOrganizacionaJedinicaKontaktTipList";
import useEffectWithoutWarnings from "../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import { useUstanovaGrid } from "../../../infrastructure/system/hooks/ustanova/useUstanovaGrid";
import EnumTerminPrikaz from "../../../model/enumFront/EnumTerminPrikaz";
import TipKontaktaReadDto from "../../../model/kontakt/TipKontaktaReadDto";
import OrganizacionaJedinicaCvorDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaCvorDto";
import OrganizacionaJedinicaRadniDanReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaRadniDanReadDto";
import PacijentPoljeKategorijaReadDto from "../../../model/pacijent-polje/PacijentPoljeKategorijaReadDto";
import PacijentPoljePopunjeno from "../../../model/pacijent-polje/PacijentPoljePopunjeno";
import PacijentUpdateDto from "../../../model/pacijent/PacijentUpdateDto";
import RadniPeriodReadDto from "../../../model/radni-period/RadniPeriodReadDto";
import RadnikSkracenoReadDto from "../../../model/radnik/RadnikSkracenoReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import UstanovaGridRadniDanReadDto from "../../../model/ustanova/UstanovaGridRadniDanReadDto";
import UstanovaGridReadDto from "../../../model/ustanova/UstanovaGridReadDto";
import ZakazanTermin from "../../../model/zakazan-termin/ZakazanTermin";
import ZakazanTerminReadDto from "../../../model/zakazan-termin/ZakazanTerminReadDto";
import { valueMap } from "../../formular/FormularLogical";
import { TerminType } from "./termin/Termin";

interface TerminiLogicalType {
  selectedDate: moment.Moment;
  setSelectedDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  dates: Array<moment.Moment>;
  legendOP: RefObject<OverlayPanel>;
  dateBack: () => void;
  dateForward: () => void;
  changeNumOfDays: (newNumOfDays: number, newDisplayType: number, isTabelarni?: boolean) => void;
  displayType: number;
  setDisplayType: React.Dispatch<React.SetStateAction<number>>;
  vanredni: boolean;
  setVanredni: React.Dispatch<React.SetStateAction<boolean>>;
  verticalDisplay: boolean;
  setVerticalDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  terminiList: Array<ZakazanTerminReadDto>;
  radniPeriodList: Array<RadniPeriodReadDto>;
  trajanjeTermina: number;
  dohvatiTermine: () => void;
  terminiDataLoading: boolean;
  daysForRenderOnGrid: Array<OrganizacionaJedinicaRadniDanReadDto>;
  radnoVremeOdOrgJed: moment.Moment | undefined;
  radnoVremeDoOrgJed: moment.Moment | undefined;
  zakazanTerminVremeDoOptions: Array<DropdownOptionType>;
  setZakazanTerminVremeDoOptions: React.Dispatch<React.SetStateAction<Array<DropdownOptionType>>>;
  closeTerminChangeDialog: () => void;
  changeTerminTime: () => void;
  handleDragStart: (event: React.DragEvent<HTMLDivElement>, termin: TerminType) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>, canBeDraggedOver: boolean) => void;
  handleDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>, terminCanBeDropped: boolean, termin: TerminType) => void;
  showTerminChangeDialog: boolean;
  dragging: boolean;
  makeSatnicaList: (
    zakazaniTerminList: Array<TerminType>,
    radniDanPocetak: moment.Moment,
    radniDanKraj: moment.Moment,
    radniPeriodList: Array<TerminType>,
    trajanjeTermina: number,
    vertical: boolean,
    vanredni?: boolean
  ) => TerminType[];
  dialogFromGrid: boolean;
  setDialogFromGrid: React.Dispatch<React.SetStateAction<boolean>>;
  zakazanTerminVremeOdOptions: Array<DropdownOptionType>;
  openDialogTermin: (termin: TerminType) => void;
  closeDialogTermin: () => void;
  dialogTerminVisible: boolean;
  setDialogTerminVisible: Dispatch<SetStateAction<boolean>>;
  odabranZakazanTermin: any;
  setOdabranZakazanTermin: Dispatch<SetStateAction<any>>;
  tabelarniPrikaz: boolean;
  setTabelarniPrikaz: React.Dispatch<React.SetStateAction<boolean>>;
  ustanovaGrid?: UstanovaGridReadDto;
  dragTermin: RefObject<TerminType>;
  zakazanTerminStatusList: EnumBaseReadDto[];
  kontaktTipList: TipKontaktaReadDto[];
  setDatesWithFirstDate: (firstDate: moment.Moment, newNumOfDays?: number, newDisplayType?: number, isTabelarni?: boolean) => void;
}
interface TerminiLogicalProps {
  odabranaOrgJed: Array<OrganizacionaJedinicaCvorDto> | undefined;
  selectedRadnik: RadnikSkracenoReadDto;
  prikaziNeaktivne: boolean;
}
export interface DropdownOptionType {
  label: string;
  value: Date;
}
export default function TerminiLogical(props: TerminiLogicalProps): TerminiLogicalType {
  const { odabranaOrgJed, selectedRadnik, prikaziNeaktivne } = props;
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { axiosGetRadniPeriodList } = RadniPeriodController();
  const { axiosGetZakazaniTermini, axiosPutIzmenaZakazaniTermini } = ZakazaniTerminController();
  const { axiosGetPacijentPoljePopunjenoList } = PacijentController();
  const { axiosGetPacijentPoljeKategorijaListByKategorija } = PacijentPoljeController();
  const legendOP = useRef<OverlayPanel>(null);
  const [terminiList, setTerminiList] = useState<Array<ZakazanTerminReadDto>>([]);
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
  const [dates, setDates] = useState<Array<moment.Moment>>([]);
  const [numOfDays, setNumOfDays] = useState<number>(7);
  const [displayType, setDisplayType] = useState<number>(isMobile ? EnumTerminPrikaz.DNEVNI : EnumTerminPrikaz.NEDELJNI);
  const [vanredni, setVanredni] = useState<boolean>(false);
  const [verticalDisplay, setVerticalDisplay] = useState<boolean>(true);
  const [terminiDataLoading, setTerminiDataLoading] = useState<boolean>(true);
  const [daysForRenderOnGrid, setDaysForRenderOnGrid] = useState<Array<OrganizacionaJedinicaRadniDanReadDto>>([]);
  const [radniPeriodList, setRadniPeriodList] = useState([]);
  const [zakazanTerminVremeOdOptions, setZakazanTerminVremeOdOptions] = useState<Array<DropdownOptionType>>([]);
  const [zakazanTerminVremeDoOptions, setZakazanTerminVremeDoOptions] = useState<Array<DropdownOptionType>>([]);
  const [showTerminChangeDialog, setShowTerminChangeDialog] = useState(false);
  const [dragging, setDragging] = useState(false);
  const dragNode: any = useRef();
  const dragTermin = useRef<TerminType | null>(null);
  const dropTermin: any = useRef();
  const Labels = useLabels();
  const [dialogFromGrid, setDialogFromGrid] = useState<boolean>(true);
  const [dialogTerminVisible, setDialogTerminVisible] = useState<boolean>(false);
  const [odabranZakazanTermin, setOdabranZakazanTermin] = useState<any>();
  const [tabelarniPrikaz, setTabelarniPrikaz] = useState<boolean>(false);

  const { data: ustanovaGrid } = useUstanovaGrid();
  const isVirtuelniRadniPeriodi = ustanovaGrid?.virtuelniRadniPeriodi;
  const { data: organizacionaJedinica } = useOrganizacionaJedinica(odabranaOrgJed?.[odabranaOrgJed?.length - 1]?.organizacionaJedinica?.id);
  const { data: zakazanTerminStatusList = [] } = useZakazanTerminStatusList();
  const { organizacionaJedinicaKontaktTipList } = useOrganizacionaJedinicaKontaktTipList(odabranaOrgJed?.[odabranaOrgJed?.length - 1]?.organizacionaJedinica?.id);

  const kontaktTipList = useMemo(() => {
    return organizacionaJedinicaKontaktTipList.map((orgJedKontaktTip) => orgJedKontaktTip.kontaktTip);
  }, [organizacionaJedinicaKontaktTipList]);

  const trajanjeTermina = ustanovaGrid ? +ustanovaGrid?.vremeTrajanjaPregledaEnum?.naziv : 15;
  const radnoVremeOdOrgJed = useMemo(
    () =>
      organizacionaJedinica?.radnoVremeOd
        ? moment(organizacionaJedinica.radnoVremeOd, TIME_FORMAT)
        : organizacionaJedinica?.ustanova?.radnoVremeOd
          ? moment(organizacionaJedinica?.ustanova.radnoVremeOd, TIME_FORMAT)
          : moment(radnoVremeOrgJediniceGlobal.radnoVremeHOd, HOUR_FORMAT),
    [organizacionaJedinica]
  );

  const radnoVremeDoOrgJed = useMemo(
    () =>
      organizacionaJedinica?.radnoVremeDo
        ? moment(organizacionaJedinica.radnoVremeDo, TIME_FORMAT)
        : organizacionaJedinica?.ustanova?.radnoVremeDo
          ? moment(organizacionaJedinica?.ustanova.radnoVremeDo, TIME_FORMAT)
          : moment(radnoVremeOrgJediniceGlobal.radnoVremeHDo, HOUR_FORMAT),
    [organizacionaJedinica]
  );

  useEffect(() => {
    if (odabranaOrgJed && dates.length > 0) {
      const weekDay = moment(dates[0]).isoWeekday();
      const tempSingleDay =
        odabranaOrgJed &&
        odabranaOrgJed[odabranaOrgJed.length - 1].organizacionaJedinica.organizacionaJedinicaRadniDanList?.find(
          (radniDan: OrganizacionaJedinicaRadniDanReadDto) => radniDan.radniDan.id === weekDay - 1
        );

      const tempDaysForRenderOnGrid =
        displayType === 2
          ? tempSingleDay
            ? [tempSingleDay]
            : []
          : odabranaOrgJed[odabranaOrgJed.length - 1].organizacionaJedinica.organizacionaJedinicaRadniDanList.sort(
              (radniDanA: OrganizacionaJedinicaRadniDanReadDto, radniDanB: OrganizacionaJedinicaRadniDanReadDto) => (radniDanA.radniDan.danUNedelji < radniDanB.radniDan.danUNedelji ? 1 : -1)
            );

      setDaysForRenderOnGrid(tempDaysForRenderOnGrid);
    }
  }, [odabranaOrgJed, dates, displayType]);

  useEffectOnce(() => {
    const momentNow = moment();
    setSelectedDate(momentNow);
    setDatesWithFirstDate(momentNow);
  });

  const openDialogTermin = (termin: TerminType) => {
    setOdabranZakazanTermin({
      id: termin.id,
      radnik: selectedRadnik,
      vremeZakazanoOd: termin.vremeZakazanoOd,
      vremeZakazanoDo: moment(termin.vremeZakazanoDo).format(DATE_TIME_FORMAT_3),
      zakazanTerminStatus: termin.zakazanTerminStatus ?? zakazanTerminStatusList.find((status) => status.sifra === EnumZakazanTerminStatus.ZAKAZAN),
      kontaktTip: termin.kontaktTip ?? kontaktTipList[0],
    });
    setDialogTerminVisible(true);
  };

  const closeDialogTermin = () => {
    setOdabranZakazanTermin(undefined);
    setDialogTerminVisible(false);
    setDialogFromGrid(true);
    setVanredni(false);
    setOdabranZakazanTermin(undefined);
    setZakazanTerminVremeOdOptions([]);
    setZakazanTerminVremeDoOptions([]);
    [].forEach.call(document.getElementsByClassName("p-datepicker-mask"), function (element: Element) {
      element.remove();
    });
    document.body.classList.remove("p-overflow-hidden");
  };

  useEffect(() => {
    if (odabranaOrgJed && odabranaOrgJed?.length > 0) {
      if (dates && dates.length > 0) fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRadnik, dates, odabranaOrgJed]);

  useEffectWithoutWarnings(() => {
    if (!checkEmpty(odabranZakazanTermin?.vremeZakazanoOd) && odabranaOrgJed && !vanredni) {
      const radniPeriodSearch = {
        organizacionaJedinicaID: odabranaOrgJed[odabranaOrgJed.length - 1]?.organizacionaJedinica?.id,
        radnikID: odabranZakazanTermin?.radnik?.id,
        datumOd: formatDate2(odabranZakazanTermin?.vremeZakazanoOd),
        datumDo: formatDate2(odabranZakazanTermin?.vremeZakazanoOd),
        viewNeaktivni: prikaziNeaktivne && odabranZakazanTermin?.radnik?.viewNeaktivni,
      };
      if (isVirtuelniRadniPeriodi) {
        const ztList = terminiList?.map((zt) => ({ ...zt, vremeZakazanoOd: moment(zt.vremeZakazanoOd), vremeZakazanoDo: moment(zt.vremeZakazanoDo) }));
        const satnica = makeSatnicaList(
          ztList ?? [],
          moment(moment(odabranZakazanTermin?.vremeZakazanoOd).hour(radnoVremeOdOrgJed.hour()).minute(radnoVremeOdOrgJed.minute()).toDate()),
          moment(moment(odabranZakazanTermin?.vremeZakazanoDo).hour(radnoVremeDoOrgJed.hour()).minute(radnoVremeDoOrgJed.minute()).toDate()),
          [],
          trajanjeTermina,
          true
        );
        setZakazanTerminVremeOdOptions(satnica.map((termin) => ({ label: moment(termin.vremeZakazanoOd).format(TIME_FORMAT), value: moment(termin.vremeZakazanoOd).toDate() })));

        return;
      }

      axiosGetRadniPeriodList(radniPeriodSearch)
        .then((res) => {
          if (res.data.data.length === 0) {
            showMessage(MessageType.WARNING, Labels.RADNI_PERIOD_POGRESNO_RADNO_VREME);
          }
          setZakazanTerminVremeOdOptions(
            res.data.data?.map((rp) => ({
              label: moment(rp.periodPocetak).format(TIME_FORMAT),
              value: rp.periodPocetak,
            }))
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  }, [odabranZakazanTermin, isVirtuelniRadniPeriodi]);

  const fetchData = () => {
    if (odabranaOrgJed && odabranaOrgJed[odabranaOrgJed.length - 1].podredjeneOrganizacioneJedinice.length === 0) {
      if (terminiDataLoading === false) setTerminiDataLoading(true);

      const searchData = {
        organizacionaJedinicaID: odabranaOrgJed[odabranaOrgJed.length - 1].organizacionaJedinica.id,
        radnikID: selectedRadnik?.id,
        datumOd: dates[0].format(DATE_FORMAT_2),
        datumDo: dates[dates.length - 1].format(DATE_FORMAT_2),
        viewNeaktivni: selectedRadnik?.viewNeaktivni && prikaziNeaktivne,
      };

      const params = {
        datumOd: dates[0].startOf("day").format(DATE_TIME_FORMAT_3),
        datumDo: dates[dates.length - 1].endOf("day").format(DATE_TIME_FORMAT_3),
        organizacionaJedinicaId: odabranaOrgJed[odabranaOrgJed.length - 1].organizacionaJedinica.id,
        radnikId: selectedRadnik.id,
        rezervisaniTerminiIsShown: true,
        viewNeaktivni: selectedRadnik?.viewNeaktivni && prikaziNeaktivne,
      };

      const requestZakazanTerminList = axiosGetZakazaniTermini(params);
      const requestArr = [requestZakazanTerminList];
      if (isVirtuelniRadniPeriodi === false) {
        const requestRadniPeriodList = axiosGetRadniPeriodList(searchData);
        requestArr.push(requestRadniPeriodList);
      }

      axios
        .all(requestArr)
        .then(
          axios.spread((...responses) => {
            setTerminiList(responses[0].data.data);
            if (isVirtuelniRadniPeriodi === false) setRadniPeriodList(responses?.[1].data.data);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setTerminiDataLoading(false);
        });
    }
  };

  const setDatesWithFirstDate = (firstDate: moment.Moment, newNumOfDays: number = numOfDays, newDisplayType: number = displayType, isTabelarni?: boolean) => {
    let datesNew: Array<moment.Moment>;
    if (newDisplayType === 1 && !isTabelarni) {
      const dayOfWeek = firstDate.day();
      datesNew = Array.from({ length: newNumOfDays }, (_, index: number) => moment(firstDate).add(index - dayOfWeek + 1, "days"));
    } else {
      const radniDanList = ustanovaGrid?.ustanovaGridRadniDanList;
      const dayOfWeek = firstDate.isoWeekday();
      if (radniDanList) {
        const dayIndex = radniDanList.findIndex((radniDan: UstanovaGridRadniDanReadDto) => radniDan.radniDan.id === dayOfWeek);

        datesNew = [dayIndex !== -1 ? firstDate : moment(firstDate).subtract(dayOfWeek - radniDanList[0].radniDan.id, "days")];
      } else datesNew = [];
    }
    setDates(datesNew);
  };

  const dateBack = () => {
    setTerminiDataLoading(true);
    const momentNow = moment(dates[0]);
    let datesNew: Array<moment.Moment>;
    if (displayType === 1 && !tabelarniPrikaz) {
      datesNew = Array.from({ length: numOfDays }, (_, index: number) => moment(momentNow).subtract(numOfDays - index, "days"));
    } else {
      const radniDanList = ustanovaGrid?.ustanovaGridRadniDanList;
      const dayOfWeek = dates[0].isoWeekday();
      if (radniDanList) {
        const dayIndex = radniDanList.findIndex((radniDan: UstanovaGridRadniDanReadDto) => radniDan.radniDan.id === dayOfWeek);

        const backDate =
          dayIndex === 0
            ? moment(momentNow).subtract(7 - (radniDanList[radniDanList.length - 1].radniDan.id - dayOfWeek), "days")
            : moment(momentNow).subtract(dayOfWeek - radniDanList[dayIndex - 1].radniDan.id, "days");
        datesNew = [backDate];
        setSelectedDate(backDate);
      } else datesNew = [];
    }
    setDates(datesNew);
  };

  const dateForward = () => {
    setTerminiDataLoading(true);
    const momentNow = moment(dates[displayType === 1 ? numOfDays - 1 : 0]);
    let datesNew: Array<moment.Moment>;
    if (displayType === 1 && !tabelarniPrikaz) {
      datesNew = Array.from({ length: numOfDays }, (_, index: number) => moment(momentNow).add(index + 1, "days"));
    } else {
      const radniDanList = ustanovaGrid?.ustanovaGridRadniDanList;
      const dayOfWeek = dates[0].isoWeekday();
      if (radniDanList) {
        const dayIndex = radniDanList.findIndex((radniDan: UstanovaGridRadniDanReadDto) => radniDan.radniDan.id === dayOfWeek);

        const radniDaniLength = radniDanList.length;
        const dateNext =
          dayIndex === radniDaniLength - 1
            ? moment(momentNow).add(7 - (radniDanList[dayIndex].radniDan.id - radniDanList[0].radniDan.id), "days")
            : moment(momentNow).add(radniDanList[dayIndex + 1].radniDan.id - dayOfWeek, "days");
        datesNew = [dateNext];
        setSelectedDate(dateNext);
      } else datesNew = [];
    }
    setDates(datesNew);
  };

  const changeNumOfDays = (newNumOfDays: number, newDisplayType: number, isTabelarni?: boolean) => {
    setNumOfDays(newNumOfDays);
    const momentNow = moment();
    setSelectedDate(momentNow);
    setDatesWithFirstDate(momentNow, newNumOfDays, newDisplayType, isTabelarni);
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, termin: TerminType) => {
    dragNode.current = event.currentTarget;
    dragTermin.current = termin;
    dragNode.current.addEventListener("dragend", handleDragEnd);
    setDragging(true);

    const status: string = terminStatusFieldType[(termin?.zakazanTerminStatus?.id ?? (0 as number)) - 1];
    const terminStatusClass: string = "hc-scheduler-appointment--" + status;
    const draggedTerminStatusClass: string = "dragged-termin--" + status;
    dragNode.current.classList.remove("hc-scheduler-appointment");
    dragNode.current.classList.remove(terminStatusClass);
    dragNode.current.classList.add(draggedTerminStatusClass);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>, canBeDraggedOver: boolean) => {
    event.stopPropagation();
    event.preventDefault();

    const currentTerminId = event.currentTarget.id;
    if (Number(currentTerminId) !== dragTermin?.current?.id && canBeDraggedOver) {
      event.currentTarget.classList.add("drag-over-termin");
    }
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove("drag-over-termin");
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>, terminCanBeDropped: boolean, termin: TerminType) => {
    event.preventDefault();
    event.currentTarget.classList.remove("drag-over-termin");
    const currentTerminId = event.currentTarget.id;
    const radniPeriodTerminDroppedStart: string = termin.vremeZakazanoOd.format(DATE_TIME_FORMAT);
    const draggedTerminStart = dragTermin.current?.vremeZakazanoOd.format(DATE_TIME_FORMAT);

    if (Number(currentTerminId) !== dragTermin?.current?.id && terminCanBeDropped && radniPeriodTerminDroppedStart !== draggedTerminStart) {
      setShowTerminChangeDialog(true);
    } else {
      demarkingDraggingTermin();
    }
    dropTermin.current = termin;
  };

  const handleDragEnd = () => {
    setDragging(false);
    demarkingDraggingTermin();
    dragNode.current.removeEventListener("dragend", handleDragEnd);
  };

  const demarkingDraggingTermin = () => {
    const status: string = terminStatusFieldType[(dragTermin.current?.zakazanTerminStatus?.id ?? (0 as number)) - 1];
    const terminStatusClass: string = "hc-scheduler-appointment--" + status;
    const draggedTerminStatusClass: string = "dragged-termin--" + status;
    dragNode.current.classList.remove(draggedTerminStatusClass);
    dragNode.current.classList.add("hc-scheduler-appointment");
    dragNode.current.classList.add(terminStatusClass);
  };

  const closeTerminChangeDialog = () => {
    setShowTerminChangeDialog(false);
    demarkingDraggingTermin();
  };

  const changeTerminTime = async () => {
    setShowTerminChangeDialog(false);
    const draggedTermin = dragTermin.current;

    if (draggedTermin) {
      const zakazanTermin = new ZakazanTermin(
        draggedTermin.radnik!,
        draggedTermin.pacijent,
        draggedTermin.organizacionaJedinica!,
        moment(dropTermin.current?.vremeZakazanoOd).format(DATE_TIME_FORMAT_3),
        moment(dropTermin.current?.vremeZakazanoOd).add(draggedTermin.vremeZakazanoDo.diff(draggedTermin.vremeZakazanoOd, "minutes"), "minutes").format(DATE_TIME_FORMAT_3),
        draggedTermin.zakazanTerminStatus!,
        draggedTermin.zakazanTerminTip!,
        draggedTermin.kontaktTip
      );

      await fillPacijentPolja(zakazanTermin.pacijent as PacijentUpdateDto);
      setShowBlockUI(true);
      axiosPutIzmenaZakazaniTermini(Number(draggedTermin?.id), zakazanTermin)
        .then(() => {
          handleAxiosCallSuccess(showMessage, Labels.ZAKAZIVANJE_PROMENA_TERMINA_USPESNO);
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          demarkingDraggingTermin();
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  async function fillPacijentPolja(pacijent: PacijentUpdateDto) {
    await axios
      .all([axiosGetPacijentPoljeKategorijaListByKategorija(EnumPacijentPoljeKategorija.ZAKAZIVANJE), axiosGetPacijentPoljePopunjenoList(EnumPacijentPoljeKategorija.ZAKAZIVANJE, pacijent.id!)])
      .then(
        axios.spread((responseTipZakazanogTerminaList, resPoljaPopunjena) => {
          let poljeVrednost: valueMap = {};
          let pacijetPoljeKategorijaList: Array<PacijentPoljeKategorijaReadDto> = responseTipZakazanogTerminaList.data.data;
          resPoljaPopunjena.data.data.forEach((pacijentPoljePopunjeno: PacijentPoljePopunjeno) => {
            let pacijentPoljeKategorija: PacijentPoljeKategorijaReadDto | undefined = pacijetPoljeKategorijaList.find((pacijentPoljeKategoria: PacijentPoljeKategorijaReadDto) => {
              return pacijentPoljeKategoria.pacijentPolje.id === pacijentPoljePopunjeno.pacijentPolje.id;
            });
            if (pacijentPoljeKategorija && pacijentPoljeKategorija.readOnly) {
              //Preskoci read-only polja
            } else if (
              pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.TEXT ||
              pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.LONG_TEXT
            ) {
              poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostString;
            } else if (
              pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.NUMBER_DECIMAL ||
              pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.NUMBER_INTEGER
            ) {
              poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostNumber;
            } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.DATE_TIME) {
              poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
            } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.DATE) {
              poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
            } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.TIME) {
              poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
            } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.BOOLEAN) {
              poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostBoolean;
            } else if (
              pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET ||
              pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET_RADIO
            ) {
              poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno?.vrednostEnumeratorItem?.id;
            } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET_MULTISELECT) {
              if (poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] !== undefined) {
                poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = [...poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id], pacijentPoljePopunjeno?.vrednostEnumeratorItem];
              } else {
                poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = [pacijentPoljePopunjeno?.vrednostEnumeratorItem];
              }
            }
          });
          if (Object.keys(poljeVrednost).length) {
            pacijent.polja = poljeVrednost;
          }
        })
      );
  }

  const makeSatnicaList = (zakazaniTerminList: TerminType[], radniDanPocetak: Moment, radniDanKraj: Moment, radniPeriodList: TerminType[], trajanjeTermina: number, vertical: boolean) => {
    const zTList = filterZakazanTerminList(zakazaniTerminList, radniDanPocetak, radniDanKraj);

    const rpList = !isVirtuelniRadniPeriodi ? addRadniPeriodList(zTList, radniPeriodList, radniDanPocetak, radniDanKraj) : [];
    let satnicaSorted = addRestOfTheGrid(zTList.concat(rpList), radniDanPocetak, radniDanKraj, trajanjeTermina).sort(
      (terminA: TerminType, terminB: TerminType) => Number(terminA.vremeZakazanoOd) - Number(terminB.vremeZakazanoOd)
    );

    const prviTermin = satnicaSorted[0];

    if (prviTermin?.vremeZakazanoOd?.get("minute") > 0 && !vertical) {
      satnicaSorted.unshift({ vremeZakazanoOd: moment(prviTermin.vremeZakazanoOd).set("minute", 0), vremeZakazanoDo: prviTermin.vremeZakazanoOd, disabledClick: true });
    }
    const poslednjiTermin = satnicaSorted[satnicaSorted.length - 1];
    if (poslednjiTermin && poslednjiTermin?.vremeZakazanoDo?.get("minute") !== 0 && !vertical) {
      satnicaSorted.push({ vremeZakazanoOd: poslednjiTermin.vremeZakazanoDo, vremeZakazanoDo: moment(poslednjiTermin.vremeZakazanoDo).add(1, "hour").set("minute", 0), disabledClick: true });
    }

    if (selectedRadnik?.viewNeaktivni) {
      return satnicaSorted.map((satnica) => ({ ...satnica, disabledClick: !satnica.id }));
    }
    return satnicaSorted;
  };

  const filterZakazanTerminList = (zakazaniTerminList: Array<TerminType>, radniDanPocetak: Moment, radniDanKraj: Moment) => {
    if (!isVirtuelniRadniPeriodi) {
      const draggingOnDay = dragging && radniDanPocetak.format(DATE_FORMAT) === dragTermin.current?.vremeZakazanoDo?.format(DATE_FORMAT);
      return (
        zakazaniTerminList?.filter(
          (zT: TerminType) =>
            zT.zakazanTerminTip?.sifra === EnumTerminTip.REDOVAN &&
            zT.vremeZakazanoOd.isSameOrAfter(radniDanPocetak) &&
            zT.vremeZakazanoDo.isSameOrBefore(radniDanKraj) &&
            (!draggingOnDay || zT.id !== dragTermin.current?.id)
        ) || []
      );
    }
    return (
      zakazaniTerminList?.filter(
        (zT: TerminType) => zT.zakazanTerminTip?.sifra === EnumTerminTip.REDOVAN && zT.vremeZakazanoOd.isSameOrAfter(radniDanPocetak) && zT.vremeZakazanoDo.isSameOrBefore(radniDanKraj)
      ) || []
    );
  };

  const addRadniPeriodList = (zakazaniTerminList: TerminType[], radniPeriodList: TerminType[], radniDanPocetak: Moment, radniDanKraj: Moment) => {
    const radniPeriodListWithoutTermin = radniPeriodList.filter((rp) => {
      return !zakazaniTerminList.some((zt) => areTerminObjectsSame(zt, rp)) && rp.vremeZakazanoOd.isSameOrAfter(radniDanPocetak) && rp.vremeZakazanoDo.isSameOrBefore(radniDanKraj);
    });

    if (!dragging) return radniPeriodListWithoutTermin;

    const draggedTermin = dragTermin.current;
    const draggedTerminStatus = terminStatusFieldType[(draggedTermin?.zakazanTerminStatus?.id ?? (0 as number)) - 1];

    return radniPeriodListWithoutTermin.map((radPeriod: TerminType) => {
      return areTerminObjectsSame(draggedTermin!, radPeriod) ? { ...radPeriod, draggingStatus: `${draggedTerminStatus}` } : radPeriod;
    });
  };

  const addRestOfTheGrid = (postojeciSlotovi: TerminType[], radniDanPocetak: Moment, radniDanKraj: Moment, trajanjeSlota: number) => {
    let slotPocetak = moment(radniDanPocetak);
    let slotKraj = moment(radniDanPocetak)?.add(trajanjeSlota, "minutes");
    const satnicaLocal = [...postojeciSlotovi];
    const findTermin = (slotPocetak: Moment) => satnicaLocal.find((termin) => slotPocetak.isBetween(termin.vremeZakazanoOd, termin.vremeZakazanoDo, undefined, "[)"));
    const findNepotpunTermin = (slotPocetak: Moment, slotKraj: Moment) => satnicaLocal.find((termin) => termin.vremeZakazanoOd.isBetween(slotPocetak, slotKraj));

    while (slotKraj.isSameOrBefore(radniDanKraj)) {
      const termin = findTermin(slotPocetak);
      if (termin) {
        slotPocetak = termin.vremeZakazanoDo;
        const razlika = differenceToNearestGridLine(moment(radniDanPocetak), trajanjeSlota, moment(slotPocetak));
        slotKraj = moment(slotPocetak).add(razlika, "minutes");
        continue;
      }

      const terminAddPrazno = findNepotpunTermin(slotPocetak, slotKraj);
      if (terminAddPrazno) {
        satnicaLocal.push({ vremeZakazanoOd: slotPocetak, vremeZakazanoDo: terminAddPrazno.vremeZakazanoOd, disabledClick: !isVirtuelniRadniPeriodi });
        slotPocetak = terminAddPrazno.vremeZakazanoOd;
        const razlika = differenceToNearestGridLine(moment(radniDanPocetak), trajanjeSlota, moment(slotPocetak));
        slotKraj = moment(slotPocetak).add(razlika, "minutes");
        continue;
      }

      satnicaLocal.push({ vremeZakazanoOd: slotPocetak, vremeZakazanoDo: slotKraj, disabledClick: !isVirtuelniRadniPeriodi });
      slotPocetak = slotKraj;
      slotKraj = moment(slotPocetak).add(trajanjeSlota, "minutes");
    }

    if (slotKraj.isAfter(radniDanKraj) && !slotPocetak.isSame(radniDanKraj)) {
      satnicaLocal.push({ vremeZakazanoOd: slotPocetak, vremeZakazanoDo: radniDanKraj, disabledClick: !isVirtuelniRadniPeriodi });
    }
    return satnicaLocal;
  };

  const differenceToNearestGridLine = (radniDanPocetak: Moment, trajanjeSlota: number, terminKraj: Moment) => {
    const minutiOdPocetkaDana = terminKraj.diff(radniDanPocetak, "minutes");
    return trajanjeSlota - (minutiOdPocetkaDana % trajanjeSlota);
  };

  const areTerminObjectsSame = (t1: TerminType, t2: TerminType) => {
    return (
      t1.vremeZakazanoOd.isSame(t2.vremeZakazanoOd) ||
      t1.vremeZakazanoDo.isSame(t2.vremeZakazanoDo) ||
      t1.vremeZakazanoOd.isBetween(t2.vremeZakazanoOd, t2.vremeZakazanoDo) ||
      t2.vremeZakazanoOd.isBetween(t1.vremeZakazanoOd, t1.vremeZakazanoDo)
    );
  };

  return {
    selectedDate,
    setSelectedDate,
    dates,
    legendOP,
    dateBack,
    dateForward,
    changeNumOfDays,
    displayType,
    setDisplayType,
    vanredni,
    setVanredni,
    verticalDisplay,
    setVerticalDisplay,
    terminiList,
    trajanjeTermina,
    dohvatiTermine: fetchData,
    terminiDataLoading,
    daysForRenderOnGrid,
    radniPeriodList,
    radnoVremeDoOrgJed,
    radnoVremeOdOrgJed,
    zakazanTerminVremeDoOptions,
    setZakazanTerminVremeDoOptions,
    closeTerminChangeDialog,
    changeTerminTime,
    handleDragStart,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    showTerminChangeDialog,
    dragging,
    makeSatnicaList,
    dialogFromGrid,
    setDialogFromGrid,
    zakazanTerminVremeOdOptions,
    openDialogTermin,
    closeDialogTermin,
    dialogTerminVisible,
    setDialogTerminVisible,
    odabranZakazanTermin,
    setOdabranZakazanTermin,
    tabelarniPrikaz,
    setTabelarniPrikaz,
    dragTermin,
    ustanovaGrid,
    zakazanTerminStatusList,
    kontaktTipList,
    setDatesWithFirstDate,
  };
}
