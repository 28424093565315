import { AxiosResponse } from "axios";
import { FileUploadHandlerEvent } from "primereact/fileupload";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppContext, useLabels } from "../../Store";
import PorukeController from "../../controllers/poruke/PorukeController";
import { RadnikController } from "../../controllers/radnik/RadnikController";
import MessageType from "../../infrastructure/system/MessageType";
import { MAX_FILE_UPLOAD, MAX_PORUKA_DATOTEKA_LIST_SIZE, checkEmpty, handleAxiosCallError, useEffectOnce } from "../../infrastructure/system/Utils";
import PorukaCreateDto from "../../model/poruke/PorukaCreateDto";
import PorukaDatotekaCreateDto from "../../model/poruke/PorukaDatotekaCreateDto";
import PorukaRadnikCreateDto from "../../model/poruke/PorukaRadnikCreateDto";
import RadnikReadDto from "../../model/radnik/RadnikReadDto";

interface PorukeViewLogicalType {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  documentUploadHandler: (event: FileUploadHandlerEvent) => void;
  attachment: PorukaDatotekaCreateDto | undefined;
  attachmentList: Array<PorukaDatotekaCreateDto | undefined>;
  setAttachmentList: React.Dispatch<React.SetStateAction<Array<any>>>;
  onRemoveAttachment: (attachmentName: string | undefined) => void;
  radnikList: Array<RadnikReadDto>;
  porukaChange: PorukaCreateDto | undefined;
  setPorukaChange: React.Dispatch<React.SetStateAction<PorukaCreateDto | undefined>>;
  recepients: Array<RadnikReadDto>;
  setRecepients: React.Dispatch<React.SetStateAction<Array<any>>>;
  addRecepient: (ids: Array<number>) => void;
  removeRecepient: (recepientId: number) => void;
  onCreate: () => void;
  onChangeRecepients: (e: any, id: number) => void;
  added: number[];
  setAdded: React.Dispatch<React.SetStateAction<number[]>>;
  refreshPoruke: number;
  setRefreshPoruke: React.Dispatch<React.SetStateAction<number>>;
}

export default function PorukeViewLogical(): PorukeViewLogicalType {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const location = useLocation();
  const [tabIndex] = useState(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [index, setIndex] = useState(tabIndex);
  const [attachment, setAttachment] = useState<PorukaDatotekaCreateDto>();
  const [attachmentList, setAttachmentList] = useState<Array<PorukaDatotekaCreateDto | undefined>>([]);
  const [radnikList, setRadnikList] = useState<Array<RadnikReadDto>>([]);
  const [porukaChange, setPorukaChange] = useState<PorukaCreateDto>();
  const [recepients, setRecepients] = useState<Array<RadnikReadDto>>([]);
  const { axiosSearchRadnik } = RadnikController();
  const { axiosPostPoruka } = PorukeController();
  const [added, setAdded] = useState<number[]>([]);
  const [refreshPoruke, setRefreshPoruke] = useState<number>(0);
  useEffectOnce(() => {
    getRadnikList();
  });

  const documentUploadHandler = (e: FileUploadHandlerEvent) => {
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }
        if (attachmentList.map((attachment) => attachment!.velicina).reduce((acc, current) => acc + current, 0) + file.size > MAX_PORUKA_DATOTEKA_LIST_SIZE) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.PORUKA_CREATE_PORUKA_SIZE_ERROR);
          return;
        }

        if (reader.result) {
          // kada se uploaduje rar file, file type koji je vracen je prazan string, ovo je do prime react komponente tako da ova provera mora da se odradi
          let fileType = file.type.trim().length === 0 ? "application/vnd.rar" : file.type;

          setAttachment({
            sadrzaj: reader.result.toString().substr(reader.result?.toString().indexOf(",") + 1),
            naziv: file.name,
            velicina: file.size,
            tip: fileType,
          });
          let currentAttachments = [...attachmentList];

          setAttachmentList([
            ...currentAttachments,
            {
              sadrzaj: reader.result.toString().substr(reader.result?.toString().indexOf(",") + 1),
              naziv: file.name,
              velicina: file.size,
              tip: fileType,
            },
          ]);

          let currentPorukaChangeDatotekaList = [...(porukaChange?.porukaDatotekaList ?? [])];
          setPorukaChange({
            ...porukaChange!,
            porukaDatotekaList: [
              ...currentPorukaChangeDatotekaList,
              {
                sadrzaj: reader.result.toString().substr(reader.result?.toString().indexOf(",") + 1),
                naziv: file.name,
                velicina: file.size,
                tip: fileType,
              },
            ],
          });
        }
      };
    });
  };

  const onRemoveAttachment = (attachmentName: string | undefined) => {
    if (!checkEmpty(attachmentName)) {
      setAttachmentList(attachmentList.filter((attachment: any) => attachment.naziv !== attachmentName));
      setPorukaChange({
        ...porukaChange!,
        porukaDatotekaList: porukaChange?.porukaDatotekaList!.filter((attachment: any) => attachment.naziv !== attachmentName) ?? [],
      });
    }
  };

  const getRadnikList = () => {
    axiosSearchRadnik()
      .then((response: AxiosResponse) => {
        setRadnikList(response.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onChangeRecepients = (e: any, id: number) => {
    e.preventDefault();
    if (added.includes(id)) {
      setAdded(added.filter((rec: number) => rec !== id));
      return;
    }

    setAdded((prev) => [...prev, id]);
  };

  useEffect(() => {
    addRecepient(added);
    // eslint-disable-next-line
  }, [added]);

  const addRecepient = (ids: Array<number>) => {
    let radnikListForRecepient = ids.map((idCoreRadnik: number) => {
      return { radnik: { idCoreRadnik: idCoreRadnik } };
    });
    setPorukaChange({
      ...porukaChange!,
      porukaRadnikList: radnikListForRecepient,
    });

    const addedRecepients = radnikList.filter((radnik) => ids.includes(radnik.id));
    setRecepients([...addedRecepients]);
  };

  const removeRecepient = (recepientId: number) => {
    setAdded(added.filter((item) => item !== recepientId));
    setRecepients(recepients.filter((recepient) => recepient.id !== recepientId));
    setPorukaChange({
      ...porukaChange!,
      porukaRadnikList: porukaChange?.porukaRadnikList.filter((radnik: PorukaRadnikCreateDto) => radnik.radnik.idCoreRadnik !== recepientId) ?? [],
    });
  };

  const validatePoruka = () => {
    if (checkEmpty(porukaChange?.porukaRadnikList) || porukaChange?.porukaRadnikList.length === 0) {
      showMessage(MessageType.ERROR, Labels.PORUKA_CREATE_EMPTY_RECEPIENTS_MESSAGE_ERROR);
      return false;
    }
    if (checkEmpty(porukaChange?.naslov)) {
      showMessage(MessageType.ERROR, Labels.PORUKA_CREATE_EMPTY_MESSAGE_ERROR);
      return false;
    }
    if (checkEmpty(porukaChange?.sadrzaj) && (checkEmpty(porukaChange?.porukaDatotekaList) || porukaChange?.porukaDatotekaList?.length === 0)) {
      showMessage(MessageType.ERROR, Labels.PORUKA_CREATE_EMPTY_SADRZAJ_AND_ATTACHMENT_ERROR);
      return false;
    }
    if (porukaChange?.porukaDatotekaList && porukaDatotekaListByteSize(porukaChange.porukaDatotekaList) > MAX_PORUKA_DATOTEKA_LIST_SIZE) {
      showMessage(MessageType.ERROR, Labels.PORUKA_CREATE_PORUKA_SIZE_ERROR);
      return false;
    }
    return true;
  };

  function porukaDatotekaListByteSize(porukaDatotekaList: Array<PorukaDatotekaCreateDto>) {
    let size = 0;
    porukaDatotekaList.forEach((porukaDatoteka) => {
      size += porukaDatoteka.velicina;
    });
    return size;
  }

  const onCreate = () => {
    if (!validatePoruka()) {
      return;
    }
    if (porukaChange) {
      setShowBlockUI(true);
      axiosPostPoruka(porukaChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.PORUKA_CREATE_SUCCESS_MESSAGE);
          setPorukaChange(undefined);
          setRecepients([]);
          setAttachmentList([]);
          setAdded([]);
          setRefreshPoruke(1);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  return {
    index,
    setIndex,
    documentUploadHandler,
    attachment,
    attachmentList,
    setAttachmentList,
    onRemoveAttachment,
    radnikList,
    porukaChange,
    setPorukaChange,
    recepients,
    setRecepients,
    addRecepient,
    removeRecepient,
    onCreate,
    onChangeRecepients,
    added,
    setAdded,
    refreshPoruke,
    setRefreshPoruke,
  };
}
