import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Image } from "primereact/image";
import { OverlayPanel } from "primereact/overlaypanel";
import { ScrollPanel } from "primereact/scrollpanel";
import { Tooltip } from "primereact/tooltip";
import { useContext, useMemo } from "react";
import { AppContext, useLabels } from "../../../Store";
import FaktorRizikaReadDto from "../../../model/faktor-rizika/FaktorRizikaReadDto";
import SkeletonCircleListInRow from "../../app/skeleton/SkeletonCircleListInRow";
import PacijentRizikLogical, { FaktorRizikaExtendedReadDto } from "./PacijentRizikLogical";
interface PacijentRizikType {
  id?: number;
}

export default function PacijentRizik(props: PacijentRizikType) {
  const { id } = props;
  const { changeRizikList, rizikList, saveFaktoriRizika, op, openOverlay, closeOverlay, rizikListLoading, faktorRizikaList } = PacijentRizikLogical({
    pacijentId: id,
  });
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const overlayStyles = useMemo(() => {
    return { width: "350px" };
  }, []);

  return (
    <>
      {!rizikListLoading ? (
        <div className="flex">
          {rizikList.map(({ id, ikonica, naziv }: FaktorRizikaReadDto) => (
            <div key={id} className="mt-1">
              <Image src={ikonica.ikonica} alt="medical_kit" imageClassName={`mr-2 w-2rem image-${id}`} />
              <Tooltip target={`.image-${id}`} position="bottom">
                {naziv}
              </Tooltip>
            </div>
          ))}
          <Button label={Labels.RIZICI} className="p-button-outlined p-button-secondary" icon="pi pi-ellipsis-v" iconPos="right" onClick={openOverlay} aria-haspopup aria-controls="overlay_panel" />
        </div>
      ) : (
        <SkeletonCircleListInRow size={4} />
      )}
      <OverlayPanel ref={op} id="overlay_panel" style={overlayStyles} className="overlaypanel-demo">
        <div className="layout-rizici">
          <ScrollPanel className=" w-full overflow-x-hidden rizici-panel h-35rem">
            {faktorRizikaList.map((rizik: FaktorRizikaExtendedReadDto) => (
              <div key={rizik.id} className="pr-3">
                <div className="flex justify-content-between">
                  <div>
                    <Checkbox
                      inputId={rizik.sifra}
                      value={rizik.naziv}
                      onChange={(e) => {
                        changeRizikList(e);
                      }}
                      checked={rizik.postoji ?? false}
                    />
                    <label htmlFor={rizik.sifra} className="cursor-pointer p-checkbox-label ml-2">
                      {rizik.naziv}
                    </label>
                  </div>
                  <img src={rizik?.ikonica?.ikonica} alt="medical_kit" className="w-2rem" />
                </div>
                <Divider />
              </div>
            ))}
          </ScrollPanel>
        </div>
        <div className="flex justify-content-end pr-3">
          <Button label={Labels.BUTTON_CLOSE} onClick={closeOverlay} className="p-button-outlined p-button-tertiary" />
          <Button label={Labels.BUTTON_SAVE} onClick={saveFaktoriRizika} className="p-button p-component text-white p-button-tertiary ml-3" disabled={!pristup} />
        </div>
      </OverlayPanel>
    </>
  );
}
