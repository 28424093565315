import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../controllers/enum/EnumController";
import QueryKeys from "../../QueryKeys";

export function useOrganizacionaJedinicaTipList() {
  const { axiosGetOrganizacionaJedinicaTipList } = EnumController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.ORGANIZACIONA_JEDINICA_TIP_LIST],
    queryFn: () => axiosGetOrganizacionaJedinicaTipList(),
    staleTime: Infinity,
  });

  const organizacionaJedinicaTipList = data?.data?.data ?? [];

  return { organizacionaJedinicaTipList, isLoading };
}
