import { PanelMenu } from "primereact/panelmenu";
import { useNavigate } from "react-router-dom";
import { useLabels } from "../../../Store";
import Images from "../../../infrastructure/system/Images";
import { getImage } from "../../../infrastructure/system/Utils";
import OutsideClickableComponent from "../../outside-clickable-component/OutsideClickableComponent";

interface AppMenuType {
  onMenuClick: () => void;
  model: any;
  mobileMenuActive: boolean;
  logo: string;
  websiteURL?: string;
  onMenuItemClick: () => void;
  onLogoClick: () => void;
}

export default function AppMenu(props: AppMenuType) {
  const { onMenuClick, model, onMenuItemClick, logo, onLogoClick, websiteURL } = props;
  const Labels = useLabels();
  const navigate = useNavigate();

  return (
    <OutsideClickableComponent onClick={onMenuItemClick}>
      <div className="layout-sidebar app-menu" onClick={onMenuClick}>
        <div className={`logo ${websiteURL ? "logo-cursor" : ""}`} onClick={onLogoClick} data-cy="app-logo">
          <img id="app-logo" className="logo-image" src={getImage(false, logo, Images.LOGO_HELIANT)} alt={Labels.HELIANT} />
        </div>
        <div className="app-name p-1 text-center">{Labels.APPLICATION_NAME}</div>
        <div className="layout-menu-container" data-cy="app-menu">
          <PanelMenu model={model} />
        </div>
        {logo !== "" && (
          <div className={`logo ${websiteURL ? "logo-cursor" : ""}`} onClick={(e) => navigate("/")}>
            <img alt={Labels.HELIANT} src={Images.LOGO_HELIANT} className="logo-image" />
          </div>
        )}
      </div>
    </OutsideClickableComponent>
  );
}
