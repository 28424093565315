import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { useLabels } from "../../../../Store";
import { formatDate2 } from "../../../../infrastructure/system/Utils";
import DialogComponent from "../../../dialog-component/DialogComponent";
import CalendarComponent from "../../calendar-component/CalendarComponent";
import PlacanjeUgovorDialogLogical from "./PlacanjeUgovorDialogLogical";

interface PlacanjeUgovorDialogType {
  isPreviewOpen: boolean;
  closePreview: () => void;
  ustanovaId: number;
}

export default function PlacanjeUgovorDialogView(props: PlacanjeUgovorDialogType) {
  const { ustanovaId, closePreview, isPreviewOpen } = props;
  const Labels = useLabels();

  const { placanjeTrajanjeUgovoraChange, setPlacanjeTrajanjeUgovoraChange, savePlacanjaUgovor, datumOdRef, datumDoRef } = PlacanjeUgovorDialogLogical({
    closePreview: closePreview,
    ustanovaId: ustanovaId,
  });

  const renderFooter = () => {
    return (
      <div className="flex justify-content-end">
        <Button label={Labels.BUTTON_CREATE} icon="pi pi-plus" className="p-button-success" onClick={savePlacanjaUgovor} />
        <Button label={Labels.BUTTON_CLOSE} icon="pi pi-times" className="p-button" onClick={closePreview} autoFocus />
      </div>
    );
  };

  return (
    <div className="grid align-items-center p-0">
      <DialogComponent
        header={Labels.ADD_PLACANJE_ZA_UGOVOR_TITLE_DIALOG_CREATE}
        visible={isPreviewOpen}
        footer={renderFooter}
        onHide={closePreview}
        blockScroll={true}
        className="w-11 md:w-9 lg:w-5 xl:w-6"
        contentClassName="overflow-y-visible"
        content={
          <>
            <div className="grid align-items-center p-0"></div>
            <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-5 sm:px-3">
              <div>{Labels.PLACANJE_UGOVOR_VAZI_OD}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <CalendarComponent
                ref={datumOdRef}
                name="datumOd"
                value={placanjeTrajanjeUgovoraChange?.ugovorVaziOd}
                onChange={(e: CalendarChangeEvent) => {
                  setPlacanjeTrajanjeUgovoraChange({
                    ...placanjeTrajanjeUgovoraChange!,
                    ugovorVaziOd: formatDate2(e.target.value !== null ? (e.target.value as Date) : undefined),
                    ustanovaID: ustanovaId,
                  });
                  if (e.target.value) datumOdRef?.current?.hide();
                }}
                placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
              />
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-5 sm:px-3">
              <div>{Labels.PLACANJE_UGOVOR_VAZI_DO}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <CalendarComponent
                ref={datumDoRef}
                name="datumDo"
                value={placanjeTrajanjeUgovoraChange?.ugovorVaziDo}
                onChange={(e: CalendarChangeEvent) => {
                  setPlacanjeTrajanjeUgovoraChange({
                    ...placanjeTrajanjeUgovoraChange!,
                    ugovorVaziDo: formatDate2(e.target.value !== null ? (e.target.value as Date) : undefined),
                  });
                  if (e.target.value) datumDoRef?.current?.hide();
                }}
                placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
              />
            </div>
          </>
        }
      />
    </div>
  );
}
