import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";
import PartnerCenaAndUslugaCenaReadDto from "../../model/naplata/PartnerCenaAndUslugaCenaReadDto";
import RacunCreateDto from "../../model/naplata/RacunCreateDto";
import PartnerSimpleReadDto from "../../model/partner/PartnerSimpleReadDto";
import ProizvodCenaReadDto from "../../model/sifarnik-proizvoda/ProizvodCenaReadDto";
import RacunReadDto from "../../model/naplata/RacunReadDto";

interface RacunControllerType {
  axiosGetProizvodCena: (proizvodID: number, datumPruzanja: string) => Promise<AxiosResponse<CommonResponseDto<ProizvodCenaReadDto>>>;
  axiosGetUslugaCena: (uslugaID: number, params: { datumPruzanja: string; partnerID?: number }) => Promise<AxiosResponse<CommonResponseDto<PartnerCenaAndUslugaCenaReadDto>>>;
  axiosGetRacunForKontakt: (kontaktID: number) => Promise<AxiosResponse<any>>;
  axiosCreateRacun: (racunChange: RacunCreateDto[]) => Promise<AxiosResponse<CommonResponseDto<RacunReadDto[]>>>;
  axiosGetPartnerListForUsluga: (uslugaID: number) => Promise<AxiosResponse<CommonResponseDto<PartnerSimpleReadDto[]>>>;
  axiosPrintRacun: (params: { pacijentRacunID?: number; partnerRacunID?: number }) => Promise<AxiosResponse<CommonResponseDto<any>>>;
}

export default function RacunController(): RacunControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetProizvodCena = (proizvodID: number, datumPruzanja: string) => {
    return axios.get(`${Endpoint.NAPLATA_PROIZVOD_LIST_CENA}/${proizvodID}/proizvod-cena`, axiosConfig(authData?.token!, { datumPruzanja }));
  };

  const axiosGetUslugaCena = (uslugaID: number, params: { datumPruzanja: string; partnerID?: number }) => {
    return axios.get(`${Endpoint.NAPLATA_USLUGA_LIST_CENA}/${uslugaID}/usluga-cena`, axiosConfig(authData?.token!, params));
  };

  const axiosGetRacunForKontakt = (kontaktID: number) => {
    return axios.get(`${Endpoint.NAPLATA_RACUN_LIST}/${kontaktID}`, axiosConfig(authData?.token!));
  };

  const axiosCreateRacun = (racunChange: RacunCreateDto[]) => {
    return axios.post(Endpoint.NAPLATA_RACUN_LIST, racunChange, axiosConfig(authData?.token!));
  };

  const axiosGetPartnerListForUsluga = (uslugaID: number) => {
    return axios.get(`${Endpoint.NAPLATA_USLUGA_LIST_CENA}/${uslugaID}/partner-list`, axiosConfig(authData?.token!));
  };

  const axiosPrintRacun = (params: { pacijentRacunID?: number; partnerRacunID?: number }) => {
    return axios.get(`${Endpoint.NAPLATA_STAMPA_SPECIFIKACIJE_RACUNA}`, axiosConfig(authData?.token!, params));
  };

  return { axiosGetProizvodCena, axiosGetUslugaCena, axiosGetRacunForKontakt, axiosCreateRacun, axiosGetPartnerListForUsluga, axiosPrintRacun };
}
