import { confirmPopup } from "primereact/confirmpopup";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import SablonController from "../../../controllers/sablon/SablonController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import PoljeVrednostDto from "../../../model/polje/PoljeVrednostDto";
import SablonCreateDto from "../../../model/sablon/SablonCreateDto";
import SablonReadDto from "../../../model/sablon/SablonReadDto";

interface SablonListLogicalType {
  sablonList: Array<SablonReadDto> | undefined;
  dataLoading: boolean;
  activeIndex: number | number[];
  setActiveIndex: React.Dispatch<React.SetStateAction<number | number[]>>;
  onDelete: (sablonId: number) => void;
  onChange: (vrednost: string) => void;
  onCreate: () => void;
  onUpdate: () => void;
  showAddSablonDialog: () => void;
  showUpdateSablonDialog: (sablon: SablonReadDto) => void;
  addSablonDialogShow: boolean;
  hideAddSablonDialog: () => void;
  sablon: SablonCreateDto | undefined;
  setSablon: React.Dispatch<React.SetStateAction<SablonCreateDto | undefined>>;
  isCreating: boolean;
  sablonOperation: string;
  confirmDelete: (event: any, id: number) => void;
}

interface SablonListLogicalProp {
  idPolje?: number;
  vrednostPolje?: string;
  changePoljeVrednost: (poljeVrednost: PoljeVrednostDto) => void;
}

export default function SablonListLogical(props: SablonListLogicalProp): SablonListLogicalType {
  const { changePoljeVrednost, idPolje, vrednostPolje }: SablonListLogicalProp = props;
  const [sablonList, setSablonList] = useState<Array<SablonReadDto>>();
  const { axiosGetSablonList, axiosDeleteSablon, axiosCreateSablon, axiosUpdateSablon } = SablonController();
  const { showMessage } = useContext(AppContext);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | number[]>(0);
  const Labels = useLabels();
  const [needToFetch, setNeedToFetch] = useState<boolean>(false);
  const [addSablonDialogShow, setAddSablonDialogShow] = useState<boolean>(false);
  const [sablon, setSablon] = useState<SablonCreateDto>();
  const [sablonId, setSablonId] = useState<number>();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [sablonOperation, setSablonOperation] = useState(EntityOperation.CREATE);
  const idPoljeRef = useRef<number>();

  const fetchData = useCallback(() => {
    setDataLoading(true);
    axiosGetSablonList(idPolje!)
      .then((res: any) => {
        setSablonList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, [axiosGetSablonList, showMessage, idPolje]);

  useEffectOnce(() => {
    if (idPolje !== undefined) {
      fetchData();
    } else {
      setNeedToFetch(true);
    }
  });

  useEffect(() => {
    if ((needToFetch || idPoljeRef.current !== idPolje) && idPolje !== undefined) {
      fetchData();
      setNeedToFetch(false);
      idPoljeRef.current = idPolje;
    }
  }, [needToFetch, fetchData, idPolje]);

  const showAddSablonDialog = () => {
    setSablonOperation(EntityOperation.CREATE);
    setSablon({ naslov: "", tekst: vrednostPolje ?? "" });
    setAddSablonDialogShow(true);
  };

  const showUpdateSablonDialog = (sablon: SablonReadDto) => {
    setSablonOperation(EntityOperation.UPDATE);
    setSablonId(sablon.id);
    setSablon({ naslov: sablon.naslov, tekst: sablon.tekst });
    setAddSablonDialogShow(true);
  };

  const hideAddSablonDialog = () => {
    setAddSablonDialogShow(false);
  };

  const onCreate = () => {
    if (!validateSablonDto(sablon)) {
      return;
    }
    setIsCreating(true);
    sablon &&
      axiosCreateSablon(idPolje!, sablon)
        .then(() => {
          hideAddSablonDialog();
          setNeedToFetch(true);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setIsCreating(false);
        });
  };

  const onUpdate = () => {
    if (!validateSablonDto(sablon)) {
      return;
    }
    setIsCreating(true);
    sablon &&
      sablonId &&
      axiosUpdateSablon(idPolje!, { ...sablon!, id: sablonId })
        .then(() => {
          hideAddSablonDialog();
          setNeedToFetch(true);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setIsCreating(false);
        });
  };

  const validateSablonDto = (sablon: SablonReadDto | SablonCreateDto | undefined) => {
    if (sablon === undefined || validateStringEmpty(sablon.naslov)) {
      showMessage(MessageType.ERROR, Labels.SABLON_NASLOV_IS_REQUIRED);
      return false;
    }
    if (validateStringEmpty(sablon.tekst)) {
      showMessage(MessageType.ERROR, Labels.SABLON_TEKST_IS_REQUIRED);
      return false;
    }
    return true;
  };
  const onChange = (vrednost: string) => {
    if (idPolje !== undefined) {
      changePoljeVrednost({ id: idPolje, vrednost: vrednost });
    }
  };

  const onDelete = (sablonId: number) => {
    setDataLoading(true);
    axiosDeleteSablon(idPolje!, sablonId)
      .then((res: any) => {
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setDataLoading(false);
      });
  };

  const confirmDelete = (event: any, id: number) => {
    confirmPopup({
      target: event.currentTarget,
      message: Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_SABLON,
      icon: "pi pi-exclamation-triangle w-2rem",
      acceptLabel: Labels.BUTTON_YES,
      rejectLabel: Labels.BUTTON_NO,
      acceptClassName: "p-button p-component text-white p-button-tertiary button-hover-ignore",
      rejectClassName: "p-button p-component p-button-outlined p-button-tertiary button-hover-ignore",
      accept: () => onDelete(id),
    });
  };

  return {
    sablonList,
    dataLoading,
    activeIndex,
    setActiveIndex,
    onDelete,
    onChange,
    addSablonDialogShow,
    showAddSablonDialog,
    hideAddSablonDialog,
    sablon,
    setSablon,
    onCreate,
    onUpdate,
    isCreating,
    sablonOperation,
    showUpdateSablonDialog,
    confirmDelete,
  };
}
