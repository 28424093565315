import { CalendarChangeEvent } from "primereact/calendar";
import { useRef } from "react";
import { useLabels } from "../../../../Store";
import { formatDate, handleKeyboardTimeInputChange } from "../../../../infrastructure/system/Utils";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import { PoljeType } from "../Polje";

export default function Vremenski(props: PoljeType) {
  const { id, onChange, vrednost, readonly, vrednostList, useVrednostList } = props;
  const vremeRef = useRef<HTMLDivElement | any>(null);
  const Labels = useLabels();

  return (
    <CalendarComponent
      ref={vremeRef}
      disabled={readonly ?? false}
      name="vrednost"
      className="w-full"
      value={useVrednostList && vrednostList ? vrednostList[id] : vrednost}
      timeOnly
      onChange={(e: CalendarChangeEvent) => onChange({ id: id, vrednost: formatDate(e.value as Date | undefined) })}
      icon="pi pi-clock"
      onTodayButtonClick={() => {
        onChange({ id: id, vrednost: formatDate(new Date()) });
      }}
      onInput={(e) => handleKeyboardTimeInputChange(e.currentTarget.value, e.currentTarget.name, { id: id }, onChange, vremeRef, undefined, undefined, false, false, true)}
      placeholder={Labels.PLACEHOLDER_VREME_FORMAT}
    />
  );
}
