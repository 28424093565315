import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import KontaktUslugaReadDto from "../../../../model/kontakt/KontaktUslugaReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import SifarnikUslugaDto from "../../../../model/sifarnik/SifarnikUslugaRFZODto";
import DialogComponent from "../../../dialog-component/DialogComponent";

interface KontaktUslugaProps {
  kontaktUslugaChange: KontaktUslugaReadDto | undefined;
  uslugaOperation?: string;
  setKontaktUslugaChange: any;
  onCreateUsluge: () => void;
  onUpdateUsluge: () => void;
  onDeleteUsluge: () => void;
  closeUslugaDialog: () => void;
  isOpenUslugaDialog: boolean;
  sifarnikUslugaList?: Array<SifarnikUslugaDto>;
  isDisabled: boolean;
  uslugaTipList?: Array<EnumBaseReadDto>;
}
export default function KontaktUsluga(props: KontaktUslugaProps) {
  const { isOpenUslugaDialog, closeUslugaDialog, uslugaOperation, kontaktUslugaChange, setKontaktUslugaChange, onCreateUsluge, onUpdateUsluge, onDeleteUsluge, sifarnikUslugaList, isDisabled } = props;
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const headerToolbar = () => (
    <div className="font-bold p-0">
      {uslugaOperation === EntityOperation.CREATE
        ? Labels.USLUGA_DODAVANJE
        : uslugaOperation === EntityOperation.UPDATE
          ? Labels.USLUGA_IZMENA
          : uslugaOperation === EntityOperation.DELETE
            ? Labels.USLUGA_BRISANJE
            : ""}
    </div>
  );

  const footer = (
    <div className="layout-pacijent-dosije">
      <Button label={Labels.BUTTON_CANCEL} className="p-button-outlined p-button-tertiary align-self-center" onClick={closeUslugaDialog} />
      <Button
        label={
          uslugaOperation === EntityOperation.CREATE
            ? Labels.BUTTON_SAVE
            : uslugaOperation === EntityOperation.UPDATE
              ? Labels.BUTTON_SAVE
              : uslugaOperation === EntityOperation.DELETE
                ? Labels.BUTTON_DELETE
                : ""
        }
        tooltipOptions={tooltipOptionsBottom}
        className="p-button-success"
        onClick={
          uslugaOperation === EntityOperation.CREATE
            ? () => onCreateUsluge()
            : uslugaOperation === EntityOperation.UPDATE
              ? () => onUpdateUsluge()
              : uslugaOperation === EntityOperation.DELETE
                ? onDeleteUsluge
                : undefined
        }
        disabled={!pristup}
      />
    </div>
  );

  return (
    <DialogComponent
      header={headerToolbar}
      visible={isOpenUslugaDialog}
      onHide={closeUslugaDialog}
      footer={footer}
      content={
        <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
          <div className="grid">
            <div className="col-12">
              <div className="grid">
                <div className="font-bold col-12 pl-1">
                  {Labels.USLUGA_UNOS}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="grid">
                <div className="col-12 p-fluid p-1 pl-1">
                  <Dropdown
                    optionLabel="naziv"
                    optionValue="id"
                    disabled={!pristup || isDisabled}
                    options={sifarnikUslugaList}
                    value={kontaktUslugaChange?.sifarnikUsluga?.id}
                    resetFilterOnHide
                    onChange={(e) => {
                      setKontaktUslugaChange({
                        ...kontaktUslugaChange!,
                        sifarnikUsluga: sifarnikUslugaList!.find((x: SifarnikUslugaDto) => x.id === e.value),
                      });
                    }}
                    emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    itemTemplate={(e) => `${e?.naziv} ${e?.cena ? " - " + e?.cena + " " + e?.valuta?.sifra : ""}`}
                    valueTemplate={(e) => `${e?.naziv} ${e?.cena ? " - " + e?.cena + " " + e?.valuta?.sifra : ""}`}
                    filter
                    filterBy="naziv"
                  />
                </div>
              </div>
              <div className="grid">
                <div className="font-bold col-12 pl-1 mt-3">
                  {Labels.USLUGA_BROJ_PRUZANJA}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="grid">
                <div className="col-12 p-fluid p-1 pl-1">
                  <InputNumber
                    disabled={!pristup || isDisabled}
                    value={kontaktUslugaChange?.brojPruzanja}
                    onChange={(e) => {
                      setKontaktUslugaChange({
                        ...kontaktUslugaChange!,
                        brojPruzanja: e.value ?? undefined,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="grid">
                <div className="font-bold col-12 pl-1 mt-3">{Labels.DIJAGNOZA_NAPOMENA}</div>
              </div>
              <div className="grid">
                <div className="col-12 p-fluid p-1 pl-1">
                  <InputTextarea
                    rows={3}
                    cols={5}
                    disabled={!pristup || isDisabled}
                    value={kontaktUslugaChange?.napomena ?? ""}
                    onChange={(e) => {
                      setKontaktUslugaChange({
                        ...kontaktUslugaChange!,
                        napomena: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}
