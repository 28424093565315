enum EnumAplikativnaPrava {
  PRETRAGA_PACIJENATA = "PRETRAGA_PACIJENATA",
  ZAKAZIVANJE_TERMINA = "ZAKAZIVANJE_TERMINA",
  ADMINISTRACIJA_USTANOVE = "ADMINISTRACIJA_USTANOVE",
  ADMINISTRACIJA_ORG_JED = "ADMINISTRACIJA_ORG_JED",
  ADMINISTRACIJA_ULOGA_I_RADNIKA = "ADMINISTRACIJA_ULOGA_I_RADNIKA",
  ADMINISTRACIJA_TIPOVA_PREGLEDA_FORMULARA_I_TIPOVA_POLJA = "ADMINISTRACIJA_TIPOVA_PREGLEDA_FORMULARA_I_TIPOVA_POLJA",
  ADMINISTRACIJA_SIFARNIKA = "ADMINISTRACIJA_SIFARNIKA",
  ADMINISTRACIJA_FAKTORA_RIZIKA = "ADMINISTRACIJA_FAKTORA_RIZIKA",
  ADMINISTRACIJA_OBAVESTENJA = "ADMINISTRACIJA_OBAVESTENJA",
  ADMINISTRACIJA_PACIJENT_POLJA = "ADMINISTRACIJA_PACIJENT_POLJA",
  ADMINISTRACIJA_RADNIH_PERIODA = "ADMINISTRACIJA_RADNIH_PERIODA",
  ADMINISTRACIJA_IZVESTAJA = "ADMINISTRACIJA_IZVESTAJA",
  ADMINISTRACIJA_DELOVODNIH_KNJIGA = "ADMINISTRACIJA_DELOVODNIH_KNJIGA",
  GENERISANJE_IZVESTAJA = "GENERISANJE_IZVESTAJA",
  PREGLED_LOGA = "PREGLED_LOGA",
  ADMINISTRACIJA_PAKETA = "ADMINISTRACIJA_PAKETA",
  ADMINISTRACIJA_PARTNERA = "ADMINISTRACIJA_PARTNERA",
  ADMINISTRACIJA_GRUPNIH_OBAVESTENJA = "ADMINISTRACIJA_GRUPNIH_OBAVESTENJA",
  NAPLATA = "NAPLATA",
  SPAJANJE_PACIJENATA = "SPAJANJE_PACIJENATA",
}

export default EnumAplikativnaPrava;
