import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";
import SearchBaseDto from "../../model/SearchBaseDto";
import SifarnikPoreskaStopaDto from "../../model/repository/sifarnik-usluga/SifarnikPoreskaStopaDto";
import SifarnikValutaDto from "../../model/repository/sifarnik-usluga/SifarnikValutaDto";
import SifarnikKategorijaGlobalnihParametaraReadDto from "../../model/sifarnik/sifarnik-kategorija-globalnih-parametara/SifarnikKategorijaGlobalnihParametaraReadDto";

interface SifarnikControllerType {
  axiosGetSifarnikKategorijaKontaktaList: () => Promise<AxiosResponse<any>>;
  axiosGetSifarnikMkbList: (sifarnikMkbId: number) => Promise<AxiosResponse<any>>;
  axiosSearchSifarnikMKB: (searchString: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetDijagnozaStatusList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumUslugaTipList: () => Promise<AxiosResponse<any>>;
  axiosImportSifarnikMKB: () => Promise<AxiosResponse<any>>;
  axiosSearchSifarnikProizvod: (searchString: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosSearchSifarnikPercentilnaKrivaList: (percentilnaKrivaPol: string, vrstaGrafikonaRasta: string) => Promise<AxiosResponse<any>>;
  axiosGetSifarnikEksterniSistemList: () => Promise<AxiosResponse<any>>;
  axiosGetValutaList: () => Promise<AxiosResponse<CommonResponseDto<SifarnikValutaDto[]>>>;
  axiosGetPoreskaStopaList: () => Promise<AxiosResponse<CommonResponseDto<SifarnikPoreskaStopaDto[]>>>;
  axiosGetSifarnikKategorijaGlobalnihParametara: () => Promise<AxiosResponse<CommonResponseDto<SifarnikKategorijaGlobalnihParametaraReadDto[]>>>;
}

export function SifarnikController(): SifarnikControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetSifarnikKategorijaKontaktaList = () => {
    return axios.get(Endpoint.SIFARNIK_KATEGORIJA_KONTAKTA_LIST, axiosConfig(authData!.token));
  };

  const axiosSearchSifarnikMKB = (searchString: SearchBaseDto) => {
    return axios.get(Endpoint.SIFARNIK_MKB_SEARCH, axiosConfig(authData!.token, searchString));
  };

  const axiosGetSifarnikMkbList = (sifarnikMkbId: number) => {
    return axios.get(`${Endpoint.SIFARNIK_MKB_LIST}/${sifarnikMkbId}`, axiosConfig(authData!.token));
  };

  const axiosGetDijagnozaStatusList = () => {
    return axios.get(Endpoint.SIFARNIK_DIJAGNOZA_STATUS_LIST, axiosConfig(authData!.token));
  };

  const axiosGetEnumUslugaTipList = () => {
    return axios.get(Endpoint.SIFARNIK_USLUGA_TIP_LIST, axiosConfig(authData!.token));
  };

  const axiosImportSifarnikMKB = () => {
    return axios.get(Endpoint.SIFARNIK_MKB_IMPORT, axiosConfig(authData!.token));
  };

  const axiosSearchSifarnikProizvod = (searchString: SearchBaseDto) => {
    return axios.get(Endpoint.SIFARNIK_PROIZVOD_SEARCH, axiosConfig(authData!.token, searchString));
  };

  const axiosSearchSifarnikPercentilnaKrivaList = (percentilnaKrivaPol: string, vrstaGrafikonaRasta: string) => {
    return axios.get(Endpoint.SIFARNIK_PERCENTILNA_KRIVA_SEARCH, axiosConfig(authData!.token, { percentilnaKrivaPol: percentilnaKrivaPol, vrstaGrafikonaRasta: vrstaGrafikonaRasta }));
  };

  const axiosGetSifarnikEksterniSistemList = () => {
    return axios.get(Endpoint.SIFARNIK_EKSTERNI_SISTEM_LIST, axiosConfig(authData!.token));
  };

  const axiosGetValutaList = () => {
    return axios.get(Endpoint.VALUTA_LIST, axiosConfig(authData!.token));
  };

  const axiosGetPoreskaStopaList = () => {
    return axios.get(Endpoint.PORESKA_STOPA_LIST, axiosConfig(authData!.token));
  };

  const axiosGetSifarnikKategorijaGlobalnihParametara = () => {
    return axios.get(Endpoint.SIFARNIK_KATEGORIJA_GLOBALNIH_PARAMETARA, axiosConfig(authData!.token));
  };

  return {
    axiosGetSifarnikEksterniSistemList,
    axiosGetSifarnikKategorijaKontaktaList,
    axiosSearchSifarnikMKB,
    axiosGetSifarnikMkbList,
    axiosGetDijagnozaStatusList,
    axiosGetEnumUslugaTipList,
    axiosImportSifarnikMKB,
    axiosSearchSifarnikProizvod,
    axiosSearchSifarnikPercentilnaKrivaList,
    axiosGetPoreskaStopaList,
    axiosGetValutaList,
    axiosGetSifarnikKategorijaGlobalnihParametara,
  };
}
