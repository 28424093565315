import { useQuery } from "@tanstack/react-query";
import QueryKeys from "../../QueryKeys";
import { RepositoryTipKontaktaController } from "../../../../controllers/superadmin/repository/kontakt/RepositoryTipKontaktaController";
import KontaktTipRepositoryReadDto from "../../../../model/kontakt/KontaktTipRepositoryReadDto";

export function useRepositoryTipPregledaList() {
  const { axiosPublicSearchRepositoryTipKontakta } = RepositoryTipKontaktaController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.TIP_PREGLEDA_LIST],
    queryFn: () => axiosPublicSearchRepositoryTipKontakta(),
    staleTime: Infinity,
  });

  const repositoryTipPregledaList: KontaktTipRepositoryReadDto[] = data?.data?.data ?? [];

  return { repositoryTipPregledaList, refetch, isLoading };
}
