const FileType = {
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  jpg: "image/jpg",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ppt: "application/vnd.ms-powerpoint",
  txt: "text/plain",
  zip: "application/x-zip-compressed",
  rar: "application/vnd.rar",
  xls: "application/vnd.ms-excel",
};
export default FileType;
