import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../Store";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import UstanovaFakturniPodaciLogical from "./UstanovaFakturniPodaciLogical";

interface UstanovaFakturniPodaciProps {
  isDisabled: boolean;
}

export default function UstanovaFakturniPodaciView(props: UstanovaFakturniPodaciProps) {
  const { fakturniPodaciLoading, fakturniPodaci, setFakturniPodaci, onSave } = UstanovaFakturniPodaciLogical();
  const { isDisabled } = props;

  const Labels = useLabels();

  const rightContentsToolbar = () => <Button label={Labels.BUTTON_SAVE} disabled={isDisabled || fakturniPodaciLoading} className="p-button-success bottom-0 right-0" onClick={onSave} />;

  return (
    <>
      <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
        <div className="grid align-items-center p-0">
          <div className="col-12 px-0 sm:col-4 sm:px-3">{Labels.FAKTURA_NAZIV}</div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {!fakturniPodaciLoading ? (
              <InputText
                disabled={isDisabled}
                value={fakturniPodaci?.fakturaNaziv ?? ""}
                onChange={(e) => {
                  setFakturniPodaci({
                    ...fakturniPodaci!,
                    fakturaNaziv: e.target.value,
                  });
                }}
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
          <div className="col-12 px-0 sm:col-4 sm:px-3">{Labels.FAKTURA_ADRESA}</div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {!fakturniPodaciLoading ? (
              <InputText
                disabled={isDisabled}
                value={fakturniPodaci?.fakturaAdresa ?? ""}
                onChange={(e) => {
                  setFakturniPodaci({
                    ...fakturniPodaci!,
                    fakturaAdresa: e.target.value,
                  });
                }}
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
          <div className="col-12 px-0 sm:col-4 sm:px-3">{Labels.FAKTURA_MESTO}</div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {!fakturniPodaciLoading ? (
              <InputText
                disabled={isDisabled}
                value={fakturniPodaci?.fakturaMesto ?? ""}
                onChange={(e) => {
                  setFakturniPodaci({
                    ...fakturniPodaci!,
                    fakturaMesto: e.target.value,
                  });
                }}
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
          <div className="col-12 px-0 sm:col-4 sm:px-3">{Labels.FAKTURA_MB}</div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {!fakturniPodaciLoading ? (
              <InputText
                disabled={isDisabled}
                value={fakturniPodaci?.fakturaMB ?? ""}
                onChange={(e) => {
                  setFakturniPodaci({
                    ...fakturniPodaci!,
                    fakturaMB: e.target.value,
                  });
                }}
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
          <div className="col-12 px-0 sm:col-4 sm:px-3">{Labels.FAKTURA_PIB}</div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {!fakturniPodaciLoading ? (
              <InputText
                disabled={isDisabled}
                value={fakturniPodaci?.fakturaPIB ?? ""}
                onChange={(e) => {
                  setFakturniPodaci({
                    ...fakturniPodaci!,
                    fakturaPIB: e.target.value,
                  });
                }}
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
          <div className="col-12 px-0 sm:col-4 sm:px-3">{Labels.FAKTURA_MAIL}</div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {!fakturniPodaciLoading ? (
              <InputText
                disabled={isDisabled}
                value={fakturniPodaci?.fakturaEmail ?? ""}
                onChange={(e) => {
                  setFakturniPodaci({
                    ...fakturniPodaci!,
                    fakturaEmail: e.target.value,
                  });
                }}
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
      </div>
      <Toolbar right={rightContentsToolbar} />
    </>
  );
}
