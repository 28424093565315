import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../../../Store";
import Endpoint from "../../../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../../../infrastructure/system/Utils";
import SearchBaseDto from "../../../../model/SearchBaseDto";
import TipKontaktaPublicCopyDto from "../../../../model/kontakt/TipKontaktaPublicCopyDto";
import TipKontaktaReadDto from "../../../../model/kontakt/TipKontaktaReadDto";

interface RepositoryTipKontaktaControllerType {
  axiosSearchRepositoryTipKontakta: (searchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosCreateRepositoryTipKontakta: (tipKontaktaChange: TipKontaktaReadDto) => Promise<AxiosResponse<any>>;
  axiosUpdateRepositoryTipKontakta: (tipKontaktaChange: TipKontaktaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteRepositoryTipKontakta: (tipKontaktaChange: TipKontaktaReadDto) => Promise<AxiosResponse<any>>;
  axiosGetRepositoryTipKontakta: (tipKontaktaId: number) => Promise<AxiosResponse<any>>;
  axiosPublicSearchRepositoryTipKontakta: (searchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosPublicCopyRepositoryTipKontakta: (tipKontaktaPublicCopy: TipKontaktaPublicCopyDto) => Promise<AxiosResponse<any>>;
}

export function RepositoryTipKontaktaController(): RepositoryTipKontaktaControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchRepositoryTipKontakta = (searchData?: SearchBaseDto) => {
    return axios.get(Endpoint.REPOSITORY_TIP_KONTAKTA_SEARCH, axiosConfig(authData!.token, searchData));
  };

  const axiosGetRepositoryTipKontakta = (tipKontaktaId: number) => {
    return axios.get(`${Endpoint.REPOSITORY_TIP_KONTAKTA_LIST}/${tipKontaktaId}`, axiosConfig(authData!.token));
  };

  const axiosCreateRepositoryTipKontakta = (tipKontaktaChange: TipKontaktaReadDto) => {
    return axios.post(Endpoint.REPOSITORY_TIP_KONTAKTA_LIST, tipKontaktaChange ? tipKontaktaChange : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateRepositoryTipKontakta = (tipKontaktaChange: TipKontaktaReadDto) => {
    return axios.put(`${Endpoint.REPOSITORY_TIP_KONTAKTA_LIST}/${tipKontaktaChange.id}`, tipKontaktaChange, axiosConfig(authData!.token));
  };

  const axiosDeleteRepositoryTipKontakta = (tipKontaktaChange: TipKontaktaReadDto) => {
    return axios.delete(`${Endpoint.REPOSITORY_TIP_KONTAKTA_LIST}/${tipKontaktaChange.id}`, axiosConfig(authData!.token));
  };

  const axiosPublicSearchRepositoryTipKontakta = (searchData?: SearchBaseDto) => {
    return axios.get(Endpoint.REPOSITORY_TIP_KONTAKTA_PUBLIC_SEARCH, axiosConfig(authData!.token, searchData));
  };
  const axiosPublicCopyRepositoryTipKontakta = (tipKontaktaPublicCopy: TipKontaktaPublicCopyDto) =>
    axios.post(`${Endpoint.REPOSITORY_TIP_KONTAKTA_LIST}${Endpoint.COPY_PUBLIC_SUFIX}`, tipKontaktaPublicCopy, axiosConfig(authData!.token));

  return {
    axiosSearchRepositoryTipKontakta,
    axiosCreateRepositoryTipKontakta,
    axiosUpdateRepositoryTipKontakta,
    axiosDeleteRepositoryTipKontakta,
    axiosGetRepositoryTipKontakta,
    axiosPublicSearchRepositoryTipKontakta,
    axiosPublicCopyRepositoryTipKontakta,
  };
}
