import axios from "axios";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../Store";
import { SifarnikController } from "../../../../controllers/sifarnik/SifarnikController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import SifarnikEksterniSistemReadDto from "../../../../model/eksterni-sistem/SifarnikEksterniSistemReadDto";
import UstanovaEksterniSistemReadDto from "../../../../model/eksterni-sistem/UstanovaEksterniSistemReadDto";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";

interface UstanovaEksterniSistemListLogicalType {
  eksterniSistemList: Array<UstanovaEksterniSistemReadDto>;
  dt: any;
  first: number;
  tableRows: number;
  onPage: (rows: number, first: number) => void;
  openEksterniSistem: (entityOperation: string, id?: number) => void;
  eksterniSistemListLoading: boolean;
}

interface UseParamsType {
  ustanovaId?: string;
}
interface ustanovaEksterniSistemListLogicalProps {
  ustanova?: UstanovaReadDto;
}
export default function UstanovaEksterniSistemListLogical(props: ustanovaEksterniSistemListLogicalProps): UstanovaEksterniSistemListLogicalType {
  const { ustanova } = props;
  const { axiosSuperadminGetUstanovaEksterniSistemList } = SuperadminController();
  const { axiosGetSifarnikEksterniSistemList } = SifarnikController();
  const { ustanovaId }: UseParamsType = useParams<any>();
  const { showMessage } = useContext(AppContext);
  const [eksterniSistemList, setEksterniSistemList] = useState<Array<UstanovaEksterniSistemReadDto>>([]);
  const dt = useRef<any>(null);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const navigate = useNavigate();
  const [eksterniSistemListLoading, setEksterniSistemListLoading] = useState<boolean>(true);

  useEffectOnce(() => {
    let startTime = moment(new Date());
    setEksterniSistemListLoading(true);
    const getUstanovaEksterniSistemList = axiosSuperadminGetUstanovaEksterniSistemList(Number(ustanovaId));
    const getSifarnikEksterniSistemList = axiosGetSifarnikEksterniSistemList();

    axios
      .all([getUstanovaEksterniSistemList, getSifarnikEksterniSistemList])
      .then(
        axios.spread((getUstanovaEksterniSistemResponse: any, getSifarnikEksterniSistemResposne: any) => {
          let ustanovaEskterniSistemList: Array<UstanovaEksterniSistemReadDto> = getUstanovaEksterniSistemResponse.data.data;
          let sifarnikEksterniSistemList: Array<SifarnikEksterniSistemReadDto> = getSifarnikEksterniSistemResposne.data.data;
          ustanovaEskterniSistemList.forEach((ustanovaEksterniSistem: UstanovaEksterniSistemReadDto) => {
            let sifarnikEksterniSistem = sifarnikEksterniSistemList.find((sifarnikEksterniSistem: SifarnikEksterniSistemReadDto) => {
              return Number(sifarnikEksterniSistem.id) === Number(ustanovaEksterniSistem.idRepositorySifarnikEksterniSistem);
            });
            if (sifarnikEksterniSistem) {
              ustanovaEksterniSistem.naziv = sifarnikEksterniSistem.naziv;
            }
          });
          setEksterniSistemList(ustanovaEskterniSistemList);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setEksterniSistemListLoading, startTime);
      });
  });

  const onPage = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const openEksterniSistem = (entityOperation: string, id?: number) => {
    const eksterniSistemId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-ustanova/${ustanovaId}/crud-eksterni-sistem/${eksterniSistemId}`, {
      state: { eksterniSistemOperation: entityOperation, ustanovaNaziv: ustanova?.naziv, from: "ustanova" },
    });
  };

  return {
    eksterniSistemList,
    dt,
    first,
    tableRows,
    onPage,
    openEksterniSistem,
    eksterniSistemListLoading,
  };
}
