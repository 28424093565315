import { Panel } from "primereact/panel";
import Polje, { PoljeType } from "../Polje";

export default function PanelPolje(props: PoljeType) {
  const { naziv, poljeList }: PoljeType = props;
  return (
    <Panel header={naziv}>
      {poljeList &&
        poljeList.map((polje: PoljeType) => {
          return <Polje {...polje} useVrednostList vrednostList={props.vrednostList}></Polje>;
        })}
    </Panel>
  );
}
