import moment from "moment";
import { Button } from "primereact/button";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Menubar } from "primereact/menubar";
import { TabPanel, TabView } from "primereact/tabview";
import { Dispatch, SetStateAction, useContext, useRef } from "react";
import { isMobile } from "react-device-detect";
import { AppContext, useLabels } from "../../../../Store";
import EnumPol from "../../../../infrastructure/system/EnumPol";
import EnumZakazanTerminStatus from "../../../../infrastructure/system/EnumZakazanTerminStatus";
import KontrolaPravaPristupaService from "../../../../infrastructure/system/KontrolaPravaPristupaService";
import {
  DATE_FORMAT,
  DATE_FORMAT_2,
  DATE_TIME_FORMAT_3,
  STEP_MINUTE,
  TIME_FORMAT,
  addSrLocal,
  checkEmpty,
  formatDate,
  formatDate2,
  getDateDivisibleBy5,
  removeAllMasks,
  setTimeToDate,
} from "../../../../infrastructure/system/Utils";
import TipKontaktaReadDto from "../../../../model/kontakt/TipKontaktaReadDto";
import OrganizacionaJedinicaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import RadnikSkracenoDto from "../../../../model/radnik/RadnikSkracenoDto";
import RadnikSkracenoReadDto from "../../../../model/radnik/RadnikSkracenoReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import UstanovaGridReadDto from "../../../../model/ustanova/UstanovaGridReadDto";
import ZakazanTerminReadDto from "../../../../model/zakazan-termin/ZakazanTerminReadDto";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import ConfirmComponent from "../../../confirm-component/ConfirmComponent";
import DialogComponent from "../../../dialog-component/DialogComponent";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";
import { DropdownOptionType } from "../TerminiLogical";
import { TerminType } from "../termin/Termin";
import ZakazanTerminStatusi from "../zakazan-termin-statusi/ZakazanTerminStatusi";
import DialogTerminLogical from "./DialogTerminLogical";
import { DialogTerminNoviPacijentTab } from "./DialogTerminNoviPacijentTab";
import { DialogTerminPostojeciPacijentTab } from "./DialogTerminPostojeciPacijentTab";
import { ZakazivanjeDatotekaSection } from "./ZakazivanjeDatotekaSection";
import { ZakazivanjeKomentarSection } from "./ZakazivanjeKomentarSection";

interface DialogTerminType {
  zakazanTermin?: ZakazanTerminReadDto;
  setZakazanTermin: React.Dispatch<SetStateAction<ZakazanTerminReadDto | undefined>>;
  closeDialogTermin: () => void;
  dohvatiTermine: () => void;
  trajanjeTermina: number;
  vanredni: boolean;
  setVanredni: Dispatch<SetStateAction<boolean>>;
  radnikList: Array<RadnikSkracenoReadDto>;
  zakazanTerminStatusList: Array<EnumBaseReadDto>;
  zakazanTerminVremeDoOptions: Array<DropdownOptionType>;
  setZakazanTerminVremeDoOptions: React.Dispatch<React.SetStateAction<Array<DropdownOptionType>>>;
  odabranaOrgJed: OrganizacionaJedinicaReadDto | undefined;
  selectedPacijent?: PacijentReadDto;
  ustanovaGrid: UstanovaGridReadDto | undefined;
  dialogFromGrid: boolean;
  zakazanTerminVremeOdOptions: Array<DropdownOptionType>;
  prikaziNeaktivne: boolean;
  isVirtuelniRadniPeriodi?: boolean;
  makeSatnicaList: (
    zakazaniTerminList: TerminType[],
    radniDanPocetak: moment.Moment,
    radniDanKraj: moment.Moment,
    radniPeriodList: TerminType[],
    trajanjeTermina: number,
    vertical: boolean
  ) => TerminType[];
  zakazanTerminList?: ZakazanTerminReadDto[];
  radnoVremeOdOrgJed?: moment.Moment;
  radnoVremeDoOrgJed?: moment.Moment;
  kontaktTipList: TipKontaktaReadDto[];
}

addSrLocal();

export default function DialogTermin(props: DialogTerminType) {
  const {
    zakazanTermin,
    setZakazanTermin,
    closeDialogTermin,
    dohvatiTermine,
    trajanjeTermina,
    vanredni,
    setVanredni,
    radnikList,
    zakazanTerminStatusList,
    zakazanTerminVremeDoOptions,
    setZakazanTerminVremeDoOptions,
    odabranaOrgJed,
    selectedPacijent,
    ustanovaGrid,
    dialogFromGrid,
    zakazanTerminVremeOdOptions,
    prikaziNeaktivne,
    isVirtuelniRadniPeriodi,
    makeSatnicaList,
    zakazanTerminList,
    radnoVremeDoOrgJed,
    radnoVremeOdOrgJed,
    kontaktTipList,
  } = props;

  const {
    pacijentOdabran,
    setPacijentOdabran,
    actionSubmit,
    noviPacijent,
    setNoviPacijent,
    zakazanTerminLocal,
    brisanjeZakazanogTermin,
    pacijentId,
    activeIndex,
    setActiveIndex,
    pacijentIzmenjen,
    invalidFields,
    setInvalidFields,
    pacijentExists,
    warningMessageRef,
    setPacijentExists,
    pacijentInfoRef,
    zakazanTerminKomentarList,
    zakazanTerminDatotekaList,
    sifarnikUslugaList,
    chosenUslugaList,
    addUslugaInChosenUslugaList,
    removeUslugaFromChosenUslugaList,
    fillPacijentPolja,
    pacijentRef,
    datum,
    setDatum,
    setZakazanTerminLocal,
    terminInfoRef,
  } = DialogTerminLogical({
    zakazanTermin,
    zakazanTerminVremeDoOptions,
    setZakazanTerminVremeDoOptions,
    dohvatiTermine,
    closeDialogTermin,
    trajanjeTermina,
    vanredni,
    zakazanTerminStatusList,
    odabranaOrgJed,
    prikaziNeaktivne,
    setVanredni,
    isVirtuelniRadniPeriodi,
    makeSatnicaList,
    zakazanTerminList,
    radnoVremeDoOrgJed,
    radnoVremeOdOrgJed,
    kontaktTipList,
  });

  const Labels = useLabels();
  const { pristup, authData, nadjimed } = useContext(AppContext);
  const filesRef = useRef<HTMLDivElement>(null);
  const commentsRef = useRef<HTMLDivElement>(null);
  const { hasZakazanTerminCreate, hasZakazanTerminUpdate, hasZakazanTerminDelete } = KontrolaPravaPristupaService();
  const calendarRef = useRef<Calendar>(null);

  const renderFooter = () => {
    return (
      <div className="flex justify-content-between pt-4">
        {zakazanTerminLocal?.id && !zakazanTerminLocal?.kontakt ? (
          <ConfirmComponent
            buttonLabel={Labels.LABEL_DELETE}
            buttonIcon="pi pi-trash"
            buttonClassName="p-button-outlined"
            confirmTitle={Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE}
            confirmIcon="pi pi-exclamation-triangle"
            acceptClassName="p-button p-component text-white p-button-tertiary"
            rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
            onAccept={() => brisanjeZakazanogTermin()}
            onReject={() => {}}
            disabled={!pristup || (!hasZakazanTerminDelete && authData?.currentRadnik.id !== zakazanTerminLocal?.radnikKreirao.id) || zakazanTerminLocal?.radnik?.viewNeaktivni}
          />
        ) : (
          <div />
        )}
        <div className="termin-footer">
          <Button label={Labels.BUTTON_CANCEL} className="p-button-outlined cancel-button" onClick={() => closeDialogTermin()} icon="pi pi-times" autoFocus />
          {zakazanTerminLocal && !zakazanTerminLocal.kontakt && (
            <Button
              className="submit-button"
              label={zakazanTerminLocal?.id ? Labels.BUTTON_UPDATE : Labels.ZAKAZI}
              onClick={actionSubmit}
              icon="pi pi-check"
              disabled={!pristup || (zakazanTerminLocal?.id ? !hasZakazanTerminUpdate && authData?.currentRadnik.id !== zakazanTerminLocal?.radnikKreirao.id : !hasZakazanTerminCreate)}
            />
          )}
        </div>
      </div>
    );
  };

  const renderStatus = (option: EnumBaseReadDto) => {
    return <ZakazanTerminStatusi termin={option} key={option?.id} />;
  };

  const renderGenderForLabel = (genderEnum: string | undefined) => {
    if (!checkEmpty(genderEnum)) {
      switch (genderEnum) {
        case EnumPol.MUSKI:
          return Labels.PACIJENT_EXIST_MALE_GENDER_TEXT;
        case EnumPol.ZENSKI:
          return Labels.PACIJENT_EXIST_FEMALE_GENDER_TEXT;
        case EnumPol.NIJE_UNET:
          return Labels.PACIJENT_EXIST_NOT_SPECIFIED_GENDER_TEXT;
        default:
          break;
      }
    }
  };

  const visibleFilesAndComments = () => {
    return nadjimed && !checkEmpty(zakazanTerminLocal?.id) && !checkEmpty(zakazanTerminLocal?.eksterniCaseID);
  };

  const renderHeader = () => {
    const headerMenuItems = [
      {
        label: "",
        icon: "pi pi-calendar-plus",
        command: () => terminInfoRef?.current?.scrollIntoView({ behavior: "smooth" }),
      },
      {
        label: "",
        icon: "pi pi-user",
        command: () => pacijentInfoRef?.current?.scrollIntoView({ behavior: "smooth" }),
      },
      {
        label: zakazanTerminDatotekaList?.length ? (zakazanTerminDatotekaList?.length > 9 ? "*" : zakazanTerminDatotekaList.length.toString()) : "",
        icon: "pi pi-file",
        command: () => filesRef?.current?.scrollIntoView({ behavior: "smooth" }),
        visible: visibleFilesAndComments(),
        className: "files",
      },
      {
        label: zakazanTerminKomentarList?.length ? (zakazanTerminKomentarList?.length > 9 ? "*" : zakazanTerminKomentarList.length.toString()) : "",
        icon: "pi pi-comment",
        command: () => commentsRef?.current?.scrollIntoView({ behavior: "smooth" }),
        visible: visibleFilesAndComments(),
      },
    ];

    return (
      <>
        <div className="mb-2">
          {zakazanTerminLocal?.zakazanTerminStatus ? (vanredni ? Labels.ZAKAZAN_VANREDAN_TERMIN : Labels.ZAKAZAN_TERMIN) : vanredni ? Labels.ZAKAZI_VANREDAN_TERMIN : Labels.ZAKAZI_TERMIN}
        </div>
        <Menubar model={headerMenuItems} className="bg-white border-0 border-radius-0 p-0 m-0 zakazivac-menu" />
      </>
    );
  };

  return (
    <>
      <DialogComponent
        header={renderHeader}
        visible={zakazanTerminLocal !== undefined}
        footer={renderFooter}
        onHide={closeDialogTermin}
        blockScroll={true}
        className={isMobile ? "w-11" : "w-10 sm:w-8 md:w-8 lg:w-5 xl:w-5 dialogTermin"}
        content={
          <div className="termin-content pr-3">
            <h5 className="mb-2 mt-4" ref={terminInfoRef}>
              {Labels.ZAKAZIVANJE_PODACI_O_TERMINU}
            </h5>
            <div className="flex flex-column hc-gap-3 align-items-center py-4">
              <div className="flex w-full justify-content-between align-items-center">
                <div className="pl-0 font-bold">{Labels.CEKAONICA_PACIJENT_ORDINACIJA}</div>
                <div className="col-9 p-fluid p-0">{odabranaOrgJed?.naziv ?? ""}</div>
              </div>
              <div className="flex w-full justify-content-between align-items-center">
                <div className="pl-0 font-bold">{Labels.LEKAR}</div>
                <div className="col-9 p-fluid p-0">
                  <Dropdown
                    options={radnikList}
                    optionLabel="ime"
                    dataKey="id"
                    itemTemplate={(option: RadnikSkracenoDto) => `${option?.ime ?? ""} ${option?.prezime ?? ""} - ${option?.username ?? ""}`}
                    valueTemplate={(option: RadnikSkracenoDto) => `${option?.ime ?? ""} ${option?.prezime ?? ""}`}
                    onChange={(e) => {
                      setZakazanTerminLocal((prev) => ({ ...prev!, radnik: e.value }));
                    }}
                    value={zakazanTerminLocal?.radnik}
                    emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    disabled={!pristup || !!zakazanTerminLocal?.kontakt}
                    className={invalidFields?.radnik ? "p-invalid" : ""}
                  />
                </div>
              </div>
              <div className="flex w-full justify-content-between align-items-center">
                <div className="pl-0 font-bold">{Labels.LABEL_TIP_PREGLEDA}</div>
                <div className="col-9 p-fluid p-0">
                  <Dropdown
                    filter
                    options={kontaktTipList}
                    resetFilterOnHide
                    value={zakazanTerminLocal?.kontaktTip ?? undefined}
                    optionLabel="naziv"
                    onChange={(e) => {
                      setZakazanTerminLocal((prev) => ({ ...prev!, kontaktTip: e.value }));
                    }}
                    emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    disabled={!pristup || !!zakazanTerminLocal?.kontakt || zakazanTerminLocal?.radnik?.viewNeaktivni}
                    className={invalidFields?.tipKontakta ? "p-invalid" : ""}
                  />
                </div>
              </div>
              <div className="flex w-full justify-content-between align-items-center">
                <div className="pl-0 font-bold">{Labels.VREME}</div>
                {!vanredni ? (
                  <div className="col-9 p-fluid p-0 flex align-items-center hc-gap-3">
                    <CalendarComponent
                      ref={calendarRef}
                      placeholder="DD.MM.YYYY."
                      onChange={(e: CalendarChangeEvent) => {
                        setZakazanTermin((prev) => ({
                          ...prev!,
                          vremeZakazanoOd: formatDate2(e.value as Date | undefined),
                          vremeZakazanoDo: formatDate2(e.value as Date | undefined),
                        }));
                      }}
                      baseZIndex={3000}
                      onHide={removeAllMasks}
                      appendTo={document.body}
                      value={zakazanTermin?.vremeZakazanoOd ? moment(zakazanTermin?.vremeZakazanoOd).format(DATE_FORMAT_2) : undefined}
                      disabled={(dialogFromGrid && !zakazanTerminLocal?.id) || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
                    />
                    <div className="font-bold">{Labels.LABEL_OD}</div>
                    <Dropdown
                      options={zakazanTerminVremeOdOptions?.map((vremeOd) => {
                        return { label: vremeOd.label, value: moment(vremeOd.value).format(DATE_TIME_FORMAT_3) };
                      })}
                      onChange={(e) => {
                        setZakazanTerminLocal((prev) => ({
                          ...prev!,
                          vremeZakazanoOd: e.value,
                        }));
                      }}
                      value={moment(zakazanTerminLocal?.vremeZakazanoOd).format(DATE_TIME_FORMAT_3)}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      disabled={
                        (dialogFromGrid && !zakazanTerminLocal?.id) ||
                        zakazanTerminLocal?.radnik?.viewNeaktivni ||
                        !!zakazanTerminLocal?.kontakt ||
                        (!dialogFromGrid && !zakazanTermin?.vremeZakazanoOd)
                      }
                    />
                    <div className="font-bold">{Labels.LABEL_DO}</div>
                    <Dropdown
                      options={zakazanTerminVremeDoOptions?.map((vremeDo) => {
                        return { label: vremeDo.label, value: moment(vremeDo.value).format(DATE_TIME_FORMAT_3) };
                      })}
                      onChange={(e) => {
                        setZakazanTerminLocal((prev) => ({ ...prev!, vremeZakazanoDo: moment(e.value).format(DATE_TIME_FORMAT_3) }));
                      }}
                      value={moment(zakazanTerminLocal?.vremeZakazanoDo).format(DATE_TIME_FORMAT_3)}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      disabled={zakazanTerminLocal?.radnik?.viewNeaktivni || !zakazanTerminLocal?.vremeZakazanoOd || !!zakazanTerminLocal?.kontakt}
                    />
                  </div>
                ) : (
                  <div className="col-9 p-fluid p-0 flex flex-column">
                    <div className="flex align-items-center hc-gap-3">
                      <div className="w-full">
                        <CalendarComponent
                          placeholder={moment().format(DATE_FORMAT)}
                          onChange={(e: CalendarChangeEvent) => {
                            setDatum(e.value as Date);
                            setZakazanTerminLocal((prev) => ({
                              ...prev!,
                              vremeZakazanoOd: formatDate(
                                setTimeToDate(
                                  (e.value as Date) ?? moment().format(DATE_FORMAT_2),
                                  moment(getDateDivisibleBy5(zakazanTerminLocal?.vremeZakazanoOd ? moment(zakazanTerminLocal.vremeZakazanoOd).toDate() : new Date())).format(TIME_FORMAT)
                                )
                              ),
                              vremeZakazanoDo: formatDate(
                                setTimeToDate(
                                  (e.value as Date) ?? moment().format(DATE_FORMAT_2),
                                  moment(getDateDivisibleBy5(zakazanTerminLocal?.vremeZakazanoDo ? moment(zakazanTerminLocal.vremeZakazanoDo).toDate() : new Date())).format(TIME_FORMAT)
                                )
                              ),
                            }));
                            setInvalidFields((prev) => ({ ...prev, vanredniVremeOd: false, vanredniVremeDo: false }));
                          }}
                          onClearButtonClick={() => {
                            setZakazanTerminLocal((prev) => ({
                              ...prev!,
                              vremeZakazanoOd: undefined,
                              vremeZakazanoDo: undefined,
                            }));
                          }}
                          className={invalidFields?.vanredniDatum ? "p-invalid" : ""}
                          baseZIndex={3000}
                          onHide={removeAllMasks}
                          appendTo={document.body}
                          value={zakazanTerminLocal?.vremeZakazanoOd ? moment(zakazanTerminLocal?.vremeZakazanoOd).format(DATE_FORMAT_2) : undefined}
                        />
                      </div>
                      <div className="font-bold">{Labels.LABEL_OD}</div>
                      <div className="flex flex-column">
                        <CalendarComponent
                          showTime
                          timeOnly
                          stepMinute={STEP_MINUTE}
                          placeholder={Labels.PLACEHOLDER_VREME_FORMAT}
                          icon="pi pi-clock"
                          onChange={(e: CalendarChangeEvent) => {
                            setZakazanTerminLocal((prev) => ({
                              ...prev!,
                              vremeZakazanoOd: e.value
                                ? formatDate(setTimeToDate((datum as Date) ?? moment().format(DATE_FORMAT_2), moment(getDateDivisibleBy5(e.value as Date)).format(TIME_FORMAT)))
                                : undefined,
                            }));
                            setInvalidFields((prev) => ({ ...prev, vanredniVremeOd: false, vremeEquals: false }));
                          }}
                          baseZIndex={3000}
                          onHide={removeAllMasks}
                          appendTo={document.body}
                          value={zakazanTerminLocal?.vremeZakazanoOd}
                          onClearButtonClick={() => {
                            setZakazanTerminLocal((prev) => ({
                              ...prev!,
                              vremeZakazanoOd: undefined,
                              vremeZakazanoDo: undefined,
                            }));
                          }}
                          className={invalidFields?.vanredniVremeOd || invalidFields?.vremeEquals ? "p-invalid" : ""}
                        />
                      </div>
                      <div className="font-bold">{Labels.LABEL_DO}</div>
                      <div className="flex flex-column">
                        <CalendarComponent
                          showTime
                          timeOnly
                          stepMinute={STEP_MINUTE}
                          placeholder={Labels.PLACEHOLDER_VREME_FORMAT}
                          icon="pi pi-clock"
                          onChange={(e: CalendarChangeEvent) => {
                            setZakazanTerminLocal((prev) => ({
                              ...prev!,
                              vremeZakazanoDo: e.value
                                ? formatDate(setTimeToDate((datum as Date) ?? moment().format(DATE_FORMAT_2), moment(getDateDivisibleBy5(e.value as Date)).format(TIME_FORMAT)))
                                : undefined,
                            }));
                            setInvalidFields((prev) => ({ ...prev, vanredniVremeDo: false, vremeEquals: false }));
                          }}
                          baseZIndex={3000}
                          onHide={removeAllMasks}
                          appendTo={document.body}
                          value={zakazanTerminLocal?.vremeZakazanoDo}
                          onClearButtonClick={() => {
                            setZakazanTerminLocal((prev) => ({
                              ...prev!,
                              vremeZakazanoOd: undefined,
                              vremeZakazanoDo: undefined,
                            }));
                          }}
                          className={invalidFields?.vanredniVremeDo || invalidFields?.vremeEquals ? "p-invalid" : ""}
                        />
                      </div>
                    </div>
                    {invalidFields?.vremeEquals && <ObaveznoPolje text={Labels.POCETAK_I_KRAJ_TERMINA_NOT_VALID} />}
                    {(invalidFields?.vanredniVremeOd || invalidFields?.vanredniVremeDo) && <ObaveznoPolje text={Labels.DATUM_I_VREME_REQUIRED} />}
                  </div>
                )}
              </div>
              <div className="flex w-full justify-content-between align-items-center">
                <div className="pl-0 font-bold">{Labels.ZAKAZAN_TERMIN_STATUS}</div>
                <div className="col-9 p-fluid p-0">
                  <Dropdown
                    options={zakazanTerminStatusList}
                    optionLabel="naziv"
                    itemTemplate={renderStatus}
                    valueTemplate={renderStatus}
                    onChange={(e) => {
                      setZakazanTerminLocal((prev) => ({ ...prev!, zakazanTerminStatus: e.value }));
                    }}
                    value={zakazanTerminLocal?.zakazanTerminStatus}
                    emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    disabled={!pristup || !!zakazanTerminLocal?.kontakt || zakazanTerminLocal?.radnik?.viewNeaktivni}
                    optionDisabled={(e) =>
                      e.sifra === EnumZakazanTerminStatus.ZAPOCET ||
                      e.sifra === EnumZakazanTerminStatus.ZAVRSEN ||
                      (checkEmpty(ustanovaGrid?.podrazumevanaDuzinaTrajanjaRezervacijeTermina) ||
                      (zakazanTerminLocal?.id && zakazanTerminLocal?.zakazanTerminStatus?.sifra !== EnumZakazanTerminStatus.REZERVISAN)
                        ? e.sifra === EnumZakazanTerminStatus.REZERVISAN
                        : false)
                    }
                  />
                </div>
              </div>
              <div className="flex w-full justify-content-between align-items-center">
                <div className="pl-0 font-bold">{Labels.ZAKAZAN_TERMIN_PLANIRANE_USLUGE}</div>
                <div className="col-9 p-fluid p-0">
                  <Dropdown
                    options={sifarnikUslugaList}
                    optionLabel="naziv"
                    onChange={(e) => addUslugaInChosenUslugaList(e.value)}
                    filter
                    filterBy="naziv"
                    filterPlaceholder={Labels.ZAKAZAN_TERMIN_PLANIRANE_USLUGE_PRETRAGA_USLUGE}
                    emptyFilterMessage={Labels.ZAKAZAN_TERMIN_PLANIRANE_USLUGE_EMPTY_FILTER_MESSAGE}
                    emptyMessage={Labels.ZAKAZAN_TERMIN_PLANIRANE_USLUGE_EMPTY_OPTIONS_MESSAGE}
                    showFilterClear
                    disabled={!pristup || !!zakazanTerminLocal?.kontakt || zakazanTerminLocal?.radnik?.viewNeaktivni}
                    itemTemplate={(e) => `${e?.naziv} ${e?.cena ? " - " + e?.cena + " " + e?.valuta?.sifra : ""}`}
                  />
                </div>
              </div>
              <div className="flex w-full justify-content-between align-items-center">
                <div className="pl-0 font-bold" />
                <div className="col-9 p-fluid p-0">
                  {chosenUslugaList?.map((chosenUsluga) => (
                    <Chip
                      label={chosenUsluga.naziv}
                      key={chosenUsluga.id}
                      className="mr-2 mb-2 obrazac-chip"
                      removable={pristup && !zakazanTerminLocal?.kontakt}
                      onRemove={() => {
                        removeUslugaFromChosenUslugaList(chosenUsluga);
                      }}
                      removeIcon="pi pi-times"
                    />
                  ))}
                </div>
              </div>
            </div>
            <Divider className="divider" />
            <h5 className="my-2" ref={pacijentInfoRef}>
              {Labels.ZAKAZIVANJE_PODACI_O_PACIJENTU}
            </h5>
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => {
                setActiveIndex(e.index);
                setPacijentExists(false);
              }}
              className="pt-4"
            >
              <TabPanel header={Labels.POSTOJECI_PACIJENT.toUpperCase()}>
                <DialogTerminPostojeciPacijentTab
                  fillPacijentPolja={fillPacijentPolja}
                  invalidFields={invalidFields}
                  setInvalidFields={setInvalidFields}
                  pacijentRef={pacijentRef}
                  pacijentOdabran={pacijentOdabran}
                  pacijentId={pacijentId ? Number(pacijentId) : undefined}
                  setPacijentOdabran={setPacijentOdabran}
                  zakazanTerminLocal={zakazanTerminLocal}
                />
              </TabPanel>
              {!selectedPacijent && (
                <TabPanel header={Labels.NOVI_PACIJENT.toUpperCase()} disabled={!!zakazanTerminLocal?.id} className="p-0">
                  <DialogTerminNoviPacijentTab
                    invalidFields={invalidFields}
                    setInvalidFields={setInvalidFields}
                    noviPacijent={noviPacijent}
                    setNoviPacijent={setNoviPacijent}
                    setPacijentExists={setPacijentExists}
                  />
                </TabPanel>
              )}
            </TabView>
            {pacijentIzmenjen && (
              <div className="font-bold text-primary">*{zakazanTerminLocal?.id ? Labels.ZAKAZIVANJE_PREGLEDA_UPDATE_UPOZORENJE_PACIJENT : Labels.ZAKAZIVANJE_PREGLEDA_UPOZORENJE_PACIJENT}</div>
            )}
            {pacijentExists && (
              <div className="text-warning pt-2" ref={warningMessageRef}>
                <div className="mb-1">
                  <span>{Labels.SPECIAL_CHAR_REQUIRED + " " + Labels.PACIJENT_EXIST_PATIENT_TEXT}</span>
                  <span className="font-bold">
                    {` ${noviPacijent?.ime} ${noviPacijent?.prezime}, ${renderGenderForLabel(noviPacijent?.polTrenutni?.sifra)} ${Labels.PACIJENT_EXIST_GENDER_TEXT}, ${
                      Labels.PACIJENT_EXIST_DATE_TEXT
                    } ${formatDate(noviPacijent?.vremeRodjenja as Date, DATE_FORMAT)} `}
                  </span>
                  <span>{Labels.PACIJENT_EXIST_ALREADY_EXIST_TEXT_1}</span>
                </div>
                <div>{Labels.PACIJENT_EXIST_ALREADY_EXIST_TEXT_2}</div>
              </div>
            )}
            {visibleFilesAndComments() && (
              <>
                <ZakazivanjeDatotekaSection filesRef={filesRef} zakazanTermin={zakazanTerminLocal} datotekaList={zakazanTerminDatotekaList} />
                <ZakazivanjeKomentarSection commentsRef={commentsRef} zakazanTermin={zakazanTerminLocal} komentarList={zakazanTerminKomentarList} />
              </>
            )}
          </div>
        }
      />
      <ConfirmPopup />
    </>
  );
}
