import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EnumControllerRepository } from "../../../controllers/enum/EnumControllerRepository";

import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumEntityStatus from "../../../infrastructure/system/EnumEntityStatus";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { formatDate2, handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SifarnikProizvodaRfzoReadDto from "../../../model/sifarnik/sifarnik-proizvoda-rfzo/SifarnikProizvodaRfzoReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudSifarnikProizvodaRfzoLogicalType {
  sifarnikProizvodaOperation: string;
  isDisabled: boolean;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  sifarnikProizvodaChange: SifarnikProizvodaRfzoReadDto | undefined;
  setSifarnikProizvodaChange: any;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  sifarnikId: number | undefined;
  location: any;
  onPageFormTable: (rows: number, first: number) => void;
  first: number;
  tableRows: number;
  sifarnikProizvodaLoading: boolean;
  entityStatusList: Array<EnumBaseReadDto>;
  entityStatusListLoading: boolean;
  vaziOdRef: HTMLDivElement | any;
  vaziDoRef: HTMLDivElement | any;
}

interface UseParamsType {
  sifarnikId?: string;
}

export default function CrudSifarnikProizvodaRfzoLogical(): CrudSifarnikProizvodaRfzoLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const navigate = useNavigate();
  const sifarnikId = Number(useParams<keyof UseParamsType>()["sifarnikId"]);
  const location = useLocation();
  const [sifarnikProizvodaOperation, setSifarnikProizvodaOperation] = useState(
    location.state && location.state.sifarnikProizvodaOperation !== undefined ? location.state.sifarnikProizvodaOperation : EntityOperation.UPDATE
  );
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const Labels = useLabels();
  const [isDisabled] = useState(isFormDisabled(sifarnikProizvodaOperation));
  const [sifarnikProizvodaChange, setSifarnikProizvodaChange] = useState<SifarnikProizvodaRfzoReadDto | undefined>();
  const [index, setIndex] = useState<number>(tabIndex);
  const { axiosSuperadminCreateSifarnikProizvodaRFZO, axiosSuperadminUpdateSifarnikProizvodaRFZO, axiosSuperadminDeleteSifarnikProizvodaRFZO, axiosSuperadminGetSifarnikProizvodaRFZO } =
    SuperadminController();
  const { axiosGetEntityStatusList } = EnumControllerRepository();
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [sifarnikProizvodaLoading, setSifarnikProizvodaLoading] = useState<boolean>(true);
  const [entityStatusList, setEntityStatusList] = useState<Array<EnumBaseReadDto>>([]);
  const [entityStatusListLoading, setEntityStatusListLoading] = useState<boolean>(true);
  const vaziOdRef = useRef<HTMLDivElement | any>(null);
  const vaziDoRef = useRef<HTMLDivElement | any>(null);

  const postLogHighLevel = useLogHighLevel();

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.SIFARNIK_PROIZVODA_RFZO,
      command: () => {
        navigate("/repository-sifarnik-proizvoda-rfzo");
      },
    },
  ]);

  const fetchData = (sifarnikProizvodaRFZOID?: number) => {
    const newSifarnikId = sifarnikProizvodaRFZOID ? sifarnikProizvodaRFZOID : sifarnikId;
    const startTime = moment(new Date());
    if (newSifarnikId) {
      axiosSuperadminGetSifarnikProizvodaRFZO(newSifarnikId)
        .then(({ data: { data: sifarnikProizvoda } }: { data: { data: SifarnikProizvodaRfzoReadDto } }) => {
          setSifarnikProizvodaChange(sifarnikProizvoda);
          if (breadCrumbItems.length < 2) {
            setBreadCrumbItems([
              ...breadCrumbItems,
              {
                label:
                  sifarnikProizvodaOperation === EntityOperation.UPDATE
                    ? Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_UPDATE + sifarnikProizvoda.naziv
                    : sifarnikProizvodaOperation === EntityOperation.READ
                    ? Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DETAILS + sifarnikProizvoda.naziv
                    : Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DELETE + sifarnikProizvoda.naziv,
              },
            ]);
          } else if (breadCrumbItems.length === 2) {
            setBreadCrumbItems([
              breadCrumbItems[0],
              {
                label: Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_UPDATE + sifarnikProizvoda.naziv,
              },
            ]);
          }
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setSifarnikProizvodaLoading, startTime);
        });
    } else {
      setSifarnikProizvodaLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_CREATE,
        },
      ]);
    }
  };

  useEffectOnce(() => {
    const startTime = moment(new Date());
    axiosGetEntityStatusList()
      .then(({ data: { data: entityStatusList } }: { data: { data: Array<EnumBaseReadDto> } }) => {
        setEntityStatusList(entityStatusList);
        if (sifarnikProizvodaOperation === EntityOperation.CREATE) {
          entityStatusList?.forEach((enumStatus) => {
            if (enumStatus.sifra === EnumEntityStatus.WORK_IN_PROGRESS) {
              setSifarnikProizvodaChange({ ...sifarnikProizvodaChange!, entityStatus: enumStatus });
            }
          });
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setEntityStatusListLoading, startTime);
      });

    fetchData();
  });

  const validateInput = (sifarnikProizvoda: SifarnikProizvodaRfzoReadDto | undefined) => {
    if (validateStringEmpty(sifarnikProizvoda?.naziv)) {
      showMessage(MessageType.ERROR, Labels.SIFARNIK_PROIZVODA_RFZO_NAZIV_IS_REQUIRED);
      return false;
    }

    if (validateStringEmpty(sifarnikProizvoda?.sifra)) {
      showMessage(MessageType.ERROR, Labels.SIFARNIK_PROIZVODA_RFZO_SIFRA_IS_REQUIRED);
      return false;
    }

    if (!sifarnikProizvoda?.vaziOd) {
      showMessage(MessageType.ERROR, Labels.SIFARNIK_PROIZVODA_RFZO_VAZI_OD_IS_REQUIRED);
      return false;
    }

    if (sifarnikProizvoda?.vaziDo && sifarnikProizvoda?.vaziOd > sifarnikProizvoda?.vaziDo) {
      showMessage(MessageType.ERROR, Labels.SIFARNIK_PROIZVODA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO);
      return false;
    }

    if (sifarnikProizvoda?.entityStatus === undefined) {
      showMessage(MessageType.ERROR, Labels.SIFARNIK_PROIZVODA_RFZO_STATUS_IS_REQUIRED);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validateInput(sifarnikProizvodaChange)) {
      return;
    }

    setShowBlockUI(true);
    axiosSuperadminCreateSifarnikProizvodaRFZO(sifarnikProizvodaChange!)
      .then((res) => {
        showMessage(MessageType.SUCCESS, Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_PROIZVODA_RFZO_SUCCESS);
        setSifarnikProizvodaOperation(EntityOperation.UPDATE);
        navigate("/repository-sifarnik-proizvoda-rfzo");
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_1 +
            sifarnikProizvodaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_2 +
            sifarnikProizvodaChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_3 +
            res.data.data?.entityStatus.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_4 +
            formatDate2(sifarnikProizvodaChange?.vaziOd)
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onUpdate = () => {
    if (!validateInput(sifarnikProizvodaChange)) {
      return;
    }

    setShowBlockUI(true);
    axiosSuperadminUpdateSifarnikProizvodaRFZO(sifarnikProizvodaChange!)
      .then((res) => {
        showMessage(MessageType.SUCCESS, Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_PROIZVODA_RFZO_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_1 +
            sifarnikProizvodaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_2 +
            sifarnikProizvodaChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_3 +
            res.data.data?.entityStatus.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_4 +
            sifarnikProizvodaChange?.vaziOd
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosSuperadminDeleteSifarnikProizvodaRFZO(sifarnikId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_PROIZVODA_RFZO_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_1 +
            sifarnikProizvodaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_2 +
            sifarnikProizvodaChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_3 +
            sifarnikProizvodaChange?.entityStatus.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_4 +
            sifarnikProizvodaChange?.vaziOd
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      const breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/repository-sifarnik-proizvoda-rfzo`);
    }
  };
  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    sifarnikProizvodaOperation,
    isDisabled,
    sifarnikProizvodaChange,
    breadCrumbItems,
    setSifarnikProizvodaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    sifarnikId,
    location,
    first,
    tableRows,
    onPageFormTable,
    sifarnikProizvodaLoading,
    entityStatusList,
    entityStatusListLoading,
    vaziOdRef,
    vaziDoRef,
  };
}
