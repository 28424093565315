import { PickList } from "primereact/picklist";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import SkeletonPickListItem from "../../../app/skeleton/SkeletonPickListItem";
import CrudUlogaAplikativnaPravaLogical, { UlogaAplikativnoPravoHolderDto } from "./CrudUlogaAplikativnaPravaLogical";
interface CrudUlogaAplikativnaPravaViewProps {
  isDisabled: boolean;
  ustanovaId?: number;
  ulogaNaziv?: string;
}

export default function CrudUlogaAplikativnaPravaView(props: CrudUlogaAplikativnaPravaViewProps) {
  const { isDisabled, ustanovaId, ulogaNaziv } = props;
  const { ulogaAplikativnoPravoList, aplikativnoPravoList, onChangeUlogaAplPravo, isBlocked, isLoading } = CrudUlogaAplikativnaPravaLogical({
    ustanovaId: ustanovaId,
    ulogaNaziv: ulogaNaziv,
  });
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const itemTemplate = (item: UlogaAplikativnoPravoHolderDto | undefined) => {
    if (item === undefined || item === null || isBlocked) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{item.aplikativnoPravo?.naziv}</h6>;
  };
  return (
    <div className={"layout-generic-content-list"}>
      <div className={!pristup || isDisabled || isBlocked || isLoading ? "read-only" : ""}>
        <PickList
          sourceHeader={Labels.LABEL_ULOGA_APLIKATIVNA_PRAVA}
          targetHeader={Labels.LABEL_ULOGA_IMA_APLIKATIVNA_PRAVA}
          source={aplikativnoPravoList}
          target={ulogaAplikativnoPravoList}
          itemTemplate={itemTemplate}
          onChange={onChangeUlogaAplPravo}
          showSourceControls={false}
          showTargetControls={false}
        ></PickList>
      </div>
    </div>
  );
}
