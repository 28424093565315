import axios from "axios";
import moment from "moment";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { KontaktController } from "../../../../controllers/kontakt/KontaktController";
import { SifarnikController } from "../../../../controllers/sifarnik/SifarnikController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { WIDTH_FOR_MOBILE_DEVICE, handleAxiosCallError, handleScreenSizeChange, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import KontaktDijagnozaReadDto from "../../../../model/kontakt/KontaktDijagnozaReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import SifarnikMkbDto from "../../../../model/sifarnik/SifarnikMkbDto";

interface KontaktDijagnozaLogicalType {
  id: number | undefined;
  openDijagnozaDialog: () => void;
  closeDijagnozaDialog: () => void;
  isOpenDijagnozaDialog: boolean;
  kopirajPrethodnuDijagnozu: (dt: KontaktDijagnozaReadDto) => void;
  onCreateDijagnoze: () => void;
  onUpdateDijagnoze: () => void;
  onDeleteDijagnoze: () => void;
  kontaktDijagnozaChange: KontaktDijagnozaReadDto | undefined;
  setKontaktDijagnozaChange: any;
  searchMkbDijagnoza: (e: AutoCompleteCompleteEvent) => void;
  setIsTrenutnaDijagnozaOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  dataLoading: boolean;
  mkbDijagnozeList: any;
  mainDivRef: any;
  dijagnozeLoading: boolean;
}

interface UseParamsType {
  id?: string;
}

export default function KontaktDijagnozaLogical(
  pacijent: PacijentReadDto,
  kontaktDijagnozaList: Array<KontaktDijagnozaReadDto> | undefined,
  setKontaktDijagnozaList: React.Dispatch<React.SetStateAction<Array<KontaktDijagnozaReadDto> | undefined>>,
  kontaktDijagnozaPrethodnaList: Array<KontaktDijagnozaReadDto> | undefined,
  setKontaktDijagnozaPrethodnaList: React.Dispatch<React.SetStateAction<Array<KontaktDijagnozaReadDto> | undefined>>,
  dijagnozaStatusList: Array<EnumBaseReadDto> | undefined,
  setDijagnozaStatusList: React.Dispatch<React.SetStateAction<Array<EnumBaseReadDto> | undefined>>
): KontaktDijagnozaLogicalType {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const id = Number(useParams<keyof UseParamsType>()["id"]);
  const { axiosGetDijagnozaListByKontaktID, axiosGetPrethodneDijagnozeListByKontaktID, axiosCreateDijagnozu, axiosUpdateDijagnozu, axiosDeleteDijagnozu } = KontaktController();
  const { axiosSearchSifarnikMKB, axiosGetSifarnikMkbList, axiosGetDijagnozaStatusList } = SifarnikController();
  const [isOpenDijagnozaDialog, setIsOpenDijagnozaDialog] = useState<boolean>(false);
  const [isTrenutnaDijagnozaOpenDialog, setIsTrenutnaDijagnozaOpenDialog] = useState<boolean>(false);
  const [mkbDijagnozeList, setMkbDijagnozeList] = useState<SifarnikMkbDto>();
  const [kontaktDijagnozaChange, setKontaktDijagnozaChange] = useState<KontaktDijagnozaReadDto>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [dijagnozeWithoutNaziv, setDijagnozeWithoutNaziv] = useState<boolean>(false);
  const [dijagnozeLoading, setDijagnozeLoading] = useState<boolean>(false);

  const postLogHighLevel = useLogHighLevel();
  const mainDivRef = useRef();

  window.addEventListener("resize", () => {
    setDijagnozeWithoutNaziv(!handleScreenSizeChange(mainDivRef, WIDTH_FOR_MOBILE_DEVICE));
  });

  useEffectOnce(() => {
    setDijagnozeWithoutNaziv(!handleScreenSizeChange(mainDivRef, WIDTH_FOR_MOBILE_DEVICE));
    fetchData();
  });

  const fetchData = async () => {
    if (id) {
      if (!dijagnozaStatusList) {
        axiosGetDijagnozaStatusList()
          .then((res: any) => {
            setDijagnozaStatusList(res.data.data);
          })
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
          });
      }
      if (!kontaktDijagnozaList || !kontaktDijagnozaPrethodnaList) {
        let startTime = moment(new Date());
        setDataLoading(true);
        axios
          .all([axiosGetDijagnozaListByKontaktID(id), axiosGetPrethodneDijagnozeListByKontaktID(id)])
          .then(
            axios.spread(async (responseTrenutneDijagnoze: any, responsePrethodneDijagnoze: any) => {
              const trenutneList = responseTrenutneDijagnoze.data.data;
              const prethodneList = responsePrethodneDijagnoze.data.data;

              await Promise.all(
                trenutneList.map(async (dijagnoza: KontaktDijagnozaReadDto) => {
                  await axiosGetSifarnikMkbList(dijagnoza.sifarnikMkbId).then((responseSifarnikMkb: any) => {
                    dijagnoza.sifarnikMkb = responseSifarnikMkb.data.data;
                  });
                })
              );

              await Promise.all(
                prethodneList.map(async (dijagnoza: KontaktDijagnozaReadDto) => {
                  await axiosGetSifarnikMkbList(dijagnoza.sifarnikMkbId).then((responseSifarnikMkb: any) => {
                    dijagnoza.sifarnikMkb = responseSifarnikMkb.data.data;
                  });
                })
              );
              setKontaktDijagnozaList(trenutneList);
              setKontaktDijagnozaPrethodnaList(prethodneList);
            })
          )
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
          })
          .finally(() => {
            skeletonTimeout(setDataLoading, startTime);
          });
      }
    }
  };

  const openDijagnozaDialog = () => {
    setIsOpenDijagnozaDialog(true);
  };

  const closeDijagnozaDialog = () => {
    setIsOpenDijagnozaDialog(false);
  };

  const kopirajPrethodnuDijagnozu = (prethodnaDijagnoza: KontaktDijagnozaReadDto) => {
    axiosCreateDijagnozu(id, prethodnaDijagnoza)
      .then((res: any) => {
        showMessage(MessageType.SUCCESS, Labels.DIJAGNOZA_USPESNO_PREPISANA);
        let kontaktDijagnozaNew: KontaktDijagnozaReadDto = res.data.data;
        kontaktDijagnozaNew.sifarnikMkb = prethodnaDijagnoza.sifarnikMkb;
        kontaktDijagnozaList && setKontaktDijagnozaList([...kontaktDijagnozaList, kontaktDijagnozaNew]);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const validateDijagnozu = (dijagnoza?: KontaktDijagnozaReadDto) => {
    if (!dijagnoza?.sifarnikMkbId) {
      showMessage(MessageType.ERROR, Labels.DIJAGNOZA_UNOS_JE_OBAVEZAN);
      return false;
    }
    if (!dijagnoza?.dijagnozaStatus?.sifra) {
      showMessage(MessageType.ERROR, Labels.DIJAGNOZA_STATUS_JE_OBAVEZAN);
      return false;
    }
    return true;
  };

  const getPunNaziv = (dijagnoza: SifarnikMkbDto) => {
    if (dijagnozeWithoutNaziv) {
      return dijagnoza.sifra;
    } else {
      return `${dijagnoza.sifra} - ${dijagnoza.nazivLatinski.substring(0, 100)}${dijagnoza.nazivLatinski.length > 100 ? "..." : ""}`;
    }
  };

  const searchMkbDijagnoza = (e: AutoCompleteCompleteEvent) => {
    if (e.query.length > 2) {
      setDijagnozeLoading(true);
      axiosSearchSifarnikMKB({ searchString: e.query })
        .then((res: any) => {
          setMkbDijagnozeList(
            res.data.data.map((dijagnoza: SifarnikMkbDto) => ({
              ...dijagnoza,
              punNaziv: getPunNaziv(dijagnoza),
            }))
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setDijagnozeLoading(false);
        });
    }
  };

  const onUpdateDijagnoze = () => {
    if (!validateDijagnozu(kontaktDijagnozaChange)) {
      return;
    }

    let kontaktId: number = id;
    if (!isTrenutnaDijagnozaOpenDialog && kontaktDijagnozaChange) {
      kontaktId = kontaktDijagnozaChange?.kontakt?.id;
    }
    if (kontaktDijagnozaChange) {
      setShowBlockUI(true);
      axiosUpdateDijagnozu(kontaktId, kontaktDijagnozaChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.DIJAGNOZA_USPESNO_IZMENJENA);
          isTrenutnaDijagnozaOpenDialog
            ? setKontaktDijagnozaList(kontaktDijagnozaList?.map((u: KontaktDijagnozaReadDto) => (u.id === kontaktDijagnozaChange?.id ? kontaktDijagnozaChange : u)))
            : setKontaktDijagnozaPrethodnaList(kontaktDijagnozaPrethodnaList?.map((u: KontaktDijagnozaReadDto) => (u.id === kontaktDijagnozaChange?.id ? kontaktDijagnozaChange : u)));
          closeDijagnozaDialog();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_DIJAGNOZA_1 +
              kontaktDijagnozaChange?.dijagnozaStatus.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_DIJAGNOZA_1 +
              kontaktDijagnozaChange.sifarnikMkb.naziv +
              ".",
            pacijent.id
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCreateDijagnoze = () => {
    if (!validateDijagnozu(kontaktDijagnozaChange)) {
      return;
    }
    if (kontaktDijagnozaChange) {
      setShowBlockUI(true);
      axiosCreateDijagnozu(id, kontaktDijagnozaChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.DIJAGNOZA_USPESNO_SACUVANA);
          axiosGetDijagnozaListByKontaktID(id)
            .then(async (res: any) => {
              const trenutneList = res.data.data;
              await Promise.all(
                trenutneList.map(async (dijagnoza: KontaktDijagnozaReadDto) => {
                  await axiosGetSifarnikMkbList(dijagnoza.sifarnikMkbId).then((responseSifarnikMkb: any) => {
                    dijagnoza.sifarnikMkb = responseSifarnikMkb.data.data;
                  });
                })
              );
              setKontaktDijagnozaList(trenutneList);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
            });
          closeDijagnozaDialog();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_DIJAGNOZA_1 +
              kontaktDijagnozaChange?.dijagnozaStatus.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_DIJAGNOZA_1 +
              kontaktDijagnozaChange.sifarnikMkb.naziv +
              ".",
            pacijent.id
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDeleteDijagnoze = () => {
    let kontaktId = id;
    if (!isTrenutnaDijagnozaOpenDialog && kontaktDijagnozaChange) {
      kontaktId = kontaktDijagnozaChange?.kontakt?.id;
    }
    if (kontaktDijagnozaChange) {
      setShowBlockUI(true);
      axiosDeleteDijagnozu(kontaktId, kontaktDijagnozaChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.DIJAGNOZA_USPESNO_OBRISANA);
          isTrenutnaDijagnozaOpenDialog
            ? setKontaktDijagnozaList(kontaktDijagnozaList!.filter((kd) => kd.id !== kontaktDijagnozaChange.id))
            : setKontaktDijagnozaPrethodnaList(kontaktDijagnozaPrethodnaList?.filter((kd) => kd.id !== kontaktDijagnozaChange.id));
          closeDijagnozaDialog();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_DIJAGNOZA_1 +
              kontaktDijagnozaChange?.dijagnozaStatus.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_DIJAGNOZA_1 +
              kontaktDijagnozaChange.sifarnikMkb.naziv +
              ".",
            pacijent.id
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  return {
    id,
    openDijagnozaDialog,
    closeDijagnozaDialog,
    isOpenDijagnozaDialog,
    kopirajPrethodnuDijagnozu,
    onCreateDijagnoze,
    onUpdateDijagnoze,
    onDeleteDijagnoze,
    kontaktDijagnozaChange,
    setKontaktDijagnozaChange,
    searchMkbDijagnoza,
    setIsTrenutnaDijagnozaOpenDialog,
    dataLoading,
    mkbDijagnozeList,
    mainDivRef,
    dijagnozeLoading,
  };
}
