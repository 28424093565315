import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { FileUploadHandlerEvent } from "primereact/fileupload";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EnumController } from "../../../controllers/enum/EnumController";
import IzvestajController from "../../../controllers/izvestaj/IzvestajController";
import { KategorijaStatistickogIzvestajaController } from "../../../controllers/izvestaj/KategorijaStatistickogIzvestajaController";

import { EnumControllerRepository } from "../../../controllers/enum/EnumControllerRepository";
import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, MAX_FILE_UPLOAD, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import IzvestajReadDto from "../../../model/izvestaj/IzvestajReadDto";
import KategorijaStatistickogIzvestajReadDto from "../../../model/izvestaj/kategorija/KategorijaStatistickogIzvestajaReadDto";
import StatistickiIzvestajFajlFormatReadDto from "../../../model/izvestaj/StatistickiIzvestajFajlFormatReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudIzvestajLogicType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  izvestajLoading: boolean;
  isDisabled: boolean;
  izvestajChange: IzvestajReadDto | undefined;
  setIzvestajChange: React.SetStateAction<any>;
  jasperUploadDisabled: boolean;
  setJasperUploadDisabled: any;
  jasperUploadHandler: (event: FileUploadHandlerEvent) => void;
  kategorijaIzvestajaList: Array<KategorijaStatistickogIzvestajReadDto>;
  fajlFormatList: Array<EnumBaseReadDto>;
  fajlFormatOnCheck: (fajlFormat: EnumBaseReadDto) => boolean;
  onFajlFormatChange: (check: boolean, sifra: string) => void;
  kategorijeLoading: boolean;
  formatLoading: boolean;
  izvestajOperation: string;
  izvestajNaziv: string;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onCancel: () => void;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  entityStatusList: EnumBaseReadDto[] | undefined;
  entityStatusListLoading: boolean;
}

interface UseParamsType {
  izvestajId?: string;
}

export default function CrudIzvestajLogical(isSuperAdmin?: boolean): CrudIzvestajLogicType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const location = useLocation();
  const navigate = useNavigate();

  const { axiosSearchKategorija } = KategorijaStatistickogIzvestajaController();
  const { axiosGetIzvestaj, axiosDeleteIzvestaj, axiosCreateIzvestaj, axiosUpdateIzvestaj } = IzvestajController();
  const { axiosGetEnumFajlFormatList } = EnumController();
  const { axiosGetEntityStatusList } = EnumControllerRepository();
  const {
    axiosSuperadminGetKategorijeStatistickihIzvestajaList,
    axiosSuperadminCreateStatistickiIzvestaj,
    axiosSuperadminGetStatistickiIzvestaj,
    axiosSuperadminDeleteStatistickiIzvestaj,
    axiosSuperadminUpdateStatistickiIzvestaj,
  } = SuperadminController();

  const izvestajId = Number(useParams<keyof UseParamsType>()["izvestajId"]);
  const [tabIndex] = useState(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [index, setIndex] = useState(tabIndex);
  const [izvestajLoading, setIzvestajLoading] = useState<boolean>(true);
  const [izvestajOperation, setIzvestajOperation] = useState(location.state && location.state.izvestajOperation !== undefined ? location.state.izvestajOperation : EntityOperation.UPDATE);
  const [izvestajNaziv] = useState(location.state && location.state.izvestajNaziv !== undefined ? location.state.izvestajNaziv : "");
  const [isDisabled] = useState<boolean>(isFormDisabled(izvestajOperation));
  const [izvestajChange, setIzvestajChange] = useState<IzvestajReadDto | undefined>();
  const [jasperUploadDisabled, setJasperUploadDisabled] = useState<boolean>(false);
  const [kategorijaIzvestajaList, setKategorijaIzvestajaList] = useState<Array<KategorijaStatistickogIzvestajReadDto>>([]);
  const [fajlFormatList, setFajlFormatList] = useState<Array<EnumBaseReadDto>>([]);
  const [kategorijeLoading, setKategorijeLoading] = useState<boolean>(true);
  const [formatLoading, setFormatLoading] = useState<boolean>(true);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const [entityStatusList, setEntityStatusList] = useState<EnumBaseReadDto[]>();
  const [entityStatusListLoading, setEntityStatusListLoading] = useState<boolean>(true);
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchData();
  });

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.IZVESTAJ_LIST,
      command: () => {
        navigate("/izvestaj-list", { state: { tabIndex: 1 } });
      },
    },
  ]);

  useEffectOnce(() => {
    fetchFormData();
    fetchData();
    if (isSuperAdmin) fetchEntityStatusList();
  });

  const fetchEntityStatusList = () => {
    const startTime = moment(new Date());
    axiosGetEntityStatusList()
      .then(({ data: { data: entityStatusList } }: { data: { data: Array<EnumBaseReadDto> } }) => {
        setEntityStatusList(entityStatusList);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setEntityStatusListLoading, startTime);
      });
  };

  const fetchFormData = () => {
    const requestFajlFormatList = axiosGetEnumFajlFormatList();
    const requestIzvestajKategorijaList = isSuperAdmin ? axiosSuperadminGetKategorijeStatistickihIzvestajaList() : axiosSearchKategorija({});
    const startTime = moment(new Date());
    axios
      .all([requestFajlFormatList, requestIzvestajKategorijaList])
      .then(
        axios.spread(({ data: { data: resFajlFormatList } }, { data: { data: resKategorijaIzvestajaList } }) => {
          setFajlFormatList(resFajlFormatList);
          setKategorijaIzvestajaList(resKategorijaIzvestajaList);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setFormatLoading, startTime);
        skeletonTimeout(setKategorijeLoading, startTime);
      });
  };

  const fetchData = (siID?: number, operation?: string) => {
    const statistickiIzvestajId = siID || izvestajId;
    const startTime = moment(new Date());

    if (statistickiIzvestajId) {
      setIzvestajLoading(true);
      (isSuperAdmin ? axiosSuperadminGetStatistickiIzvestaj(statistickiIzvestajId) : axiosGetIzvestaj(statistickiIzvestajId))
        .then((res) => {
          setIzvestajChange(res.data.data);
          const operationTmp = operation ?? izvestajOperation;
          if (res.data.data.jasperFajl !== undefined && res.data.data.jasperFajl !== null && res.data.data.jasperFajl !== "") {
            setJasperUploadDisabled(true);
          }
          setBreadCrumbItems([
            breadCrumbItems[0],
            {
              label: operationTmp === EntityOperation.UPDATE ? Labels.IZVESTAJ_TITLE_DIALOG_UPDATE + " " + izvestajNaziv : Labels.IZVESTAJ_TITLE_DIALOG_DELETE,
            },
          ]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setIzvestajLoading, startTime);
        });
    } else {
      setIzvestajChange({ ...izvestajChange!, prikazujeSe: false, statistickiIzvestajFajlFormatList: [] });
      setIzvestajLoading(false);
      setBreadCrumbItems([
        breadCrumbItems[0],
        {
          label: Labels.IZVESTAJ_TITLE_DIALOG_CREATE,
        },
      ]);
    }
  };

  const jasperUploadHandler = (e: FileUploadHandlerEvent) => {
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }
        if (reader.result) {
          setIzvestajChange({
            ...izvestajChange!,
            jasperFajl: reader.result.toString().substr(reader.result?.toString().indexOf(",") + 1),
            jasperFajlNaziv: file.name,
          });
        }
      };
    });
  };

  const fajlFormatOnCheck = (fajlFormat: EnumBaseReadDto) => {
    return izvestajChange?.statistickiIzvestajFajlFormatList?.find((siff) => siff.fajlFormatEnum.sifra === fajlFormat.sifra) != null;
  };

  const onFajlFormatChange = (check: boolean, sifra: string) => {
    if (check) {
      setIzvestajChange({
        ...izvestajChange!,
        statistickiIzvestajFajlFormatList: [
          ...izvestajChange?.statistickiIzvestajFajlFormatList!,
          { fajlFormatEnum: fajlFormatList.find((ff) => ff.sifra === sifra)!, statistickiIzvestaj: { id: izvestajId } },
        ],
      });
    } else {
      setIzvestajChange({
        ...izvestajChange!,
        statistickiIzvestajFajlFormatList: izvestajChange!.statistickiIzvestajFajlFormatList.filter((siff) => siff.fajlFormatEnum.sifra !== sifra),
      });
    }
  };

  const validateInput = (izvestaj: IzvestajReadDto | undefined) => {
    let isInvalid = false;
    if (!izvestaj?.kategorijaStatistickogIzvestaja) {
      setInvalidFields((prev) => ({ ...prev, kategorija: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(izvestaj?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (isSuperAdmin && !izvestaj?.jasperFajl) {
      setInvalidFields((prev) => ({ ...prev, jasper: true }));
      isInvalid = true;
    }
    if (!izvestaj?.statistickiIzvestajFajlFormatList || izvestaj?.statistickiIzvestajFajlFormatList.length === 0) {
      setInvalidFields((prev) => ({ ...prev, format: true }));
      isInvalid = true;
    }
    if (isSuperAdmin && !izvestaj?.entityStatus) {
      setInvalidFields((prev) => ({ ...prev, entityStatus: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(izvestajChange)) {
      return;
    }
    if (izvestajChange) {
      setShowBlockUI(true);
      (isSuperAdmin ? axiosSuperadminCreateStatistickiIzvestaj(izvestajChange) : axiosCreateIzvestaj(izvestajChange))
        .then((res) => {
          showMessage(MessageType.SUCCESS, Labels.IZVESTAJ_MESSAGE_CREATE_SUCCESS);
          setIzvestajOperation(EntityOperation.UPDATE);
          fetchData(res.data.data.id, EntityOperation.UPDATE);
          navigate(`/crud-izvestaj/${res.data.data.id}`);
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_IZVESTAJ_1 +
              res.data.data.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_1 +
              res.data.data.kategorijaStatistickogIzvestaja.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_2 +
              izvestajChange?.statistickiIzvestajFajlFormatList.map((elem: StatistickiIzvestajFajlFormatReadDto) => elem.fajlFormatEnum.naziv)
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(izvestajChange)) {
      return;
    }
    if (izvestajChange) {
      setShowBlockUI(true);
      (isSuperAdmin ? axiosSuperadminUpdateStatistickiIzvestaj(izvestajChange) : axiosUpdateIzvestaj(izvestajChange))
        .then((res: AxiosResponse) => {
          showMessage(MessageType.SUCCESS, Labels.IZVESTAJ_MESSAGE_UPDATE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_IZVESTAJ_1 +
              izvestajChange.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_1 +
              res.data.data.kategorijaStatistickogIzvestaja.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_2 +
              izvestajChange?.statistickiIzvestajFajlFormatList.map((elem: StatistickiIzvestajFajlFormatReadDto) => elem.fajlFormatEnum.naziv)
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    let statistickiIzvestajFajlFormatList: StatistickiIzvestajFajlFormatReadDto[] | undefined = izvestajChange?.statistickiIzvestajFajlFormatList;
    (isSuperAdmin ? axiosSuperadminDeleteStatistickiIzvestaj(izvestajId) : axiosDeleteIzvestaj(izvestajId))
      .then((res: AxiosResponse) => {
        showMessage(MessageType.SUCCESS, Labels.IZVESTAJ_MESSAGE_DELETE_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_IZVESTAJ_1 +
            izvestajChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_1 +
            izvestajChange?.kategorijaStatistickogIzvestaja.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_2 +
            statistickiIzvestajFajlFormatList?.map((elem: StatistickiIzvestajFajlFormatReadDto) => elem.fajlFormatEnum.naziv)
        );
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      const breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/izvestaj-list`);
    }
  };

  return {
    breadCrumbItems,
    index,
    setIndex,
    izvestajLoading,
    isDisabled,
    izvestajChange,
    setIzvestajChange,
    jasperUploadDisabled,
    setJasperUploadDisabled,
    jasperUploadHandler,
    kategorijaIzvestajaList,
    fajlFormatList,
    fajlFormatOnCheck,
    onFajlFormatChange,
    kategorijeLoading,
    formatLoading,
    izvestajOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    izvestajNaziv,
    invalidFields,
    setInvalidFields,
    entityStatusList,
    entityStatusListLoading,
  };
}
