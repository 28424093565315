const EnumTipKomponente = {
  TEXT: "TEXT",
  LONG_TEXT: "LONG_TEXT",
  LABEL: "LABEL",
  PANEL: "PANEL",
  BOOLEAN: "BOOLEAN",
  NUMBER_INTEGER: "NUMBER_INTEGER",
  NUMBER: "NUMBER",
  NUMBER_DECIMAL: "NUMBER_DECIMAL",
  DATE_TIME: "DATE_TIME",
  SET: "SET",
  SET_RADIO: "SET_RADIO",
  DATE: "DATE",
  TIME: "TIME",
  SET_MULTISELECT: "SET_MULTISELECT",
};

export default EnumTipKomponente;
