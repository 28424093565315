import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";

interface KursnaListaControllerType {
  axiosGetKursForValuta: (idSifarnikValuta: number) => Promise<AxiosResponse<CommonResponseDto<number>>>;
}

export function KursnaListaController(): KursnaListaControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetKursForValuta = (idSifarnikValuta: number) => {
    return axios.get(Endpoint.KURSNA_LISTA_FOR_VALUTA, axiosConfig(authData!.token, { idSifarnikValuta }));
  };

  return {
    axiosGetKursForValuta,
  };
}
