import { useState } from "react";

export function usePagination() {
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  function onPage(e: any) {
    setPage(e.page);
    setSize(e.rows);
    setFirst(e.first);
  }

  return { first, page, size, onPage, setPage, setFirst };
}
