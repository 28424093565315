import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { ConfirmPopup } from "primereact/confirmpopup";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useLabels } from "../../Store";
import Images from "../../infrastructure/system/Images";
import { getImage, imageExtention, tooltipOptionsTop } from "../../infrastructure/system/Utils";
import ConfirmComponent from "../confirm-component/ConfirmComponent";
import DialogComponent from "../dialog-component/DialogComponent";

interface AvatarUploadType {
  imagePath: string;
  saveCB: any;
  className?: string;
  ime?: string;
  prezime?: string;
}

export default function AvatarUpload(props: AvatarUploadType) {
  const { imagePath, saveCB, className, ime, prezime } = props;
  const initAvatar = {
    cropperOpen: false,
    img: getImage(false, imagePath, Images.NO_PROFILE),
    zoom: 1,
    croppedImg: "",
  };
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const Labels = useLabels();
  const [avatar, setAvatar] = useState<any>(initAvatar);
  const [editor, setEditor] = useState<any>();
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const handleCloseDialog = () => setOpenDialog(false);

  const setEditorRef = (editor: any) => setEditor(editor);

  const _handleFileChange = (e: any) => setAvatar({ ...avatar, img: e.target.files[0] });

  const handleScale = (e: ChangeEvent<HTMLInputElement>) => setAvatar({ ...avatar, zoom: parseFloat(e.target.value) });

  useEffect(() => {
    if (openDialog) {
      setImageLoaded(false);
      setAvatar(initAvatar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const onSave = () => {
    if (editor && imageLoaded) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      saveCB(croppedImg.substr(croppedImg.indexOf(",") + 1));
      handleCloseDialog();
    }
  };

  const onDelete = () => {
    saveCB("");
    handleCloseDialog();
  };

  const onCancel = () => {
    setAvatar(initAvatar);
    handleCloseDialog();
  };

  const footer = (
    <>
      <div className="grid">
        <div className="col-2">
          <ConfirmComponent
            buttonLabel={Labels.BUTTON_DELETE}
            buttonClassName={"p-button-outlined p-button-primary"}
            acceptClassName="p-button p-component text-white p-button-tertiary"
            rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
            confirmIcon="pi pi-exclamation-triangle"
            tooltipPosition={tooltipOptionsTop}
            confirmTitle={Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_PROFILNU_SLIKU}
            onAccept={onDelete}
          />
        </div>
        <div className="col-10">
          <Button label={Labels.BUTTON_CANCEL} className="p-button-outlined p-button-tertiary" onClick={onCancel} autoFocus />
          <Button label={Labels.BUTTON_SAVE} className="p-button-tertiary text-white" onClick={onSave} />
        </div>
      </div>
    </>
  );

  const avatarStyles = useMemo(() => {
    return { backgroundImage: `url(data:image/${imageExtention(imagePath)};base64,${imagePath})` };
  }, [imagePath]);

  return (
    <>
      <div className="avatar-image-container" onClick={() => setOpenDialog(true)}>
        {imagePath ? (
          <div className={`avatar-image avatar-width ${className ?? ""}`} style={avatarStyles} />
        ) : (
          <Avatar className="avatarBorder avatar-width" label={`${ime?.charAt(0)}${prezime?.charAt(0)}`} size="xlarge" shape="circle" />
        )}
        <div className="avatar-icon">
          <i className="pi pi-upload text-lg"></i>
        </div>
      </div>
      <DialogComponent
        header={Labels.TITLE_CHANGE_PROFILE_IMAGE}
        visible={openDialog}
        footer={footer}
        onHide={handleCloseDialog}
        content={
          <div className="text-center">
            <div className="grid avatar-editor-container">
              <AvatarEditor
                className="mb-3 avatar-editor-size"
                ref={setEditorRef}
                onLoadSuccess={() => setImageLoaded(true)}
                image={avatar.img}
                width={200}
                height={200}
                border={50}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={avatar.zoom}
                rotate={0}
              />
            </div>
            <br />
            <div className="grid justify-content-center">
              <input hidden id="file" type="file" accept="image/*" onChange={_handleFileChange} />
              <Button className="button-upload-image btn-small">
                <label htmlFor="file">{Labels.BUTTON_UPLOAD_IMAGE}</label>
              </Button>
            </div>
            <br />
            <div className="grid justify-content-center">
              <input name="scale" type="range" onChange={handleScale} min={avatar.allowZoomOut ? "0.1" : "1"} max="2" step="0.01" defaultValue="1" />
            </div>
          </div>
        }
      />
      <ConfirmPopup />
    </>
  );
}
