enum actionsGenerisaniIzvestaj {
  SET_KATEGORIJA_LIST = "set_kategorija_list",
  SET_IZVESTAJ_LIST = "set_izvestaj_list",
  SET_IZVESTAJ_LIST_LOADING = "set_izvestaj_list_loading",
  SET_TOTAL_COUNT = "set_total_count",
  FETCH_DATA = "fetch_data",
  ADD_PARAM_VALUE = "add_param_value",
  PARAMS_LOADED = "params_loaded",
  ADD_NEWLY_GENERATED_REPORT = "add_newly_generated_report",
}

export default actionsGenerisaniIzvestaj;
