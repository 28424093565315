import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLabels } from "../../../../Store";
import { DATE_FORMAT, TIME_FORMAT, formatDate, showPatientFullName } from "../../../../infrastructure/system/Utils";
import OrganizacionaJedinicaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import ProtokolBrojReadDto from "../../../../model/protokol/ProtokolBrojReadDto";
interface PacijentZakazanTermin {
  pacijent: PacijentReadDto;
  vremeDolaska: Date | null;
  napomena: string;
  organizacionaJedinica: OrganizacionaJedinicaReadDto;
  vremeZakazanoOd: Date;
  protokolBroj?: ProtokolBrojReadDto;
}
export default function PacijentCekaonicaView(termin: PacijentZakazanTermin) {
  const overlayPanelPacijent: any = useRef([]);
  const { pacijent, vremeDolaska, vremeZakazanoOd, organizacionaJedinica, protokolBroj = undefined } = termin;
  const Labels = useLabels();
  const navigate = useNavigate();

  return (
    <div>
      <div
        className="underline"
        onMouseEnter={(e) => {
          overlayPanelPacijent.current.toggle(e);
        }}
        onMouseLeave={(e) => {
          overlayPanelPacijent.current.toggle(e);
        }}
      >
        <div className="cursor-pointer" onClick={() => navigate(`/pacijent-dosije/${pacijent.id}`)}>{`${showPatientFullName(pacijent)}`}</div>
        <OverlayPanel ref={overlayPanelPacijent} className="overlaypanel-demo">
          {
            <div>
              {(organizacionaJedinica?.nazivKratki || organizacionaJedinica?.naziv) && (
                <div>{`${Labels.CEKAONICA_PACIJENT_ORDINACIJA}: ${organizacionaJedinica?.nazivKratki ?? organizacionaJedinica?.naziv}`}</div>
              )}
              {vremeZakazanoOd && <div>{`${Labels.CEKAONICA_PACIJENT_VREME}: ${formatDate(vremeZakazanoOd, TIME_FORMAT)}`}</div>}
              {pacijent?.ime && pacijent?.prezime && <div>{`${Labels.CEKAONICA_PACIJENT_PACIJENT}: ${showPatientFullName(pacijent)}`}</div>}
              {vremeDolaska && <div>{`${Labels.CEKAONICA_COLUMN_VREME_DOLASKA}: ${formatDate(vremeDolaska, TIME_FORMAT)}`}</div>}
              {pacijent?.vremeRodjenja && <div>{`${Labels.CEKAONICA_PACIJENT_DATUM_RODJENJA}: ${formatDate(pacijent?.vremeRodjenja as Date, DATE_FORMAT)}`}</div>}
              {pacijent?.adresa && <div>{`${Labels.CEKAONICA_PACIJENT_ADRESA}: ${pacijent?.adresa}`}</div>}
              {pacijent?.telefon && <div>{`${Labels.CEKAONICA_PACIJENT_BROJ_TELEFONA}: ${pacijent?.telefon}`}</div>}
              {pacijent?.email && <div>{`${Labels.CEKAONICA_PACIJENT_EMAIL}: ${pacijent?.email}`}</div>}
              {protokolBroj && <div>{`${Labels.PROTOKOL_BROJ}: ${protokolBroj?.kompozitniPrikaz}`}</div>}
            </div>
          }
        </OverlayPanel>
      </div>
    </div>
  );
}
