import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";
import EnumBaseReadDto from "../../model/sifarnik/EnumBaseReadDto";

interface EnumServerResponse extends AxiosResponse<CommonResponseDto<EnumBaseReadDto[]>> {}

interface EnumControllerType {
  axiosGetRolaList: () => Promise<AxiosResponse<any>>;
  axiosGetJezikList: () => Promise<AxiosResponse<any>>;
  axiosGetOrganizacionaJedinicaTipList: () => Promise<AxiosResponse<any>>;
  axiosGetSvojinaTipList: () => Promise<AxiosResponse<any>>;
  axiosGetNivoZdravstveneZastiteList: () => Promise<AxiosResponse<any>>;
  axiosGetTipKomponenteList: () => Promise<AxiosResponse<any>>;
  axiosGetPodrazumevanaStranaList: () => Promise<AxiosResponse<any>>;
  axiosGetRadniDanList: () => Promise<AxiosResponse<any>>;
  axiosGetZakazanTerminStatusList: () => Promise<AxiosResponse<CommonResponseDto<EnumBaseReadDto[]>>>;
  axiosGetPolList: () => Promise<AxiosResponse<any>>;
  axiosGetTrajanjeTerminaList: () => Promise<AxiosResponse<any>>;
  axiosGetTipZakazanogTerminaList: () => Promise<AxiosResponse<CommonResponseDto<EnumBaseReadDto[]>>>;
  axiosGetOsiguranjeTipList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumParametarKategorijaList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumPacijentPoljeKategorijaList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumAplikativnoPravoList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumVrstaPravaList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumTipObjektaList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumDokumentacijaPeriodList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumRadnikStatusList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumObavestenjeStatusList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumFajlFormatList: () => Promise<AxiosResponse<any>>;
  axiosGetIzvestajParametarTip: () => Promise<AxiosResponse<any>>;
  axiosGetEnumVremenskiOkvirList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumJedinicaKolicinePodatkaList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumPristupList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumPonavljanjeList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumPeriodList: () => Promise<AxiosResponse<any>>;
  axiosGetTipKategorijePopustaList: () => Promise<AxiosResponse<any>>;
  axiosGetNamenaKategorijePopustaList: () => Promise<AxiosResponse<any>>;
  axiosGetObavestenjeTipList: () => Promise<AxiosResponse<CommonResponseDto<EnumBaseReadDto[]>>>;
  axiosGetDodatniModulList: () => Promise<AxiosResponse<any>>;
  axiosGetKontaktPlacenList: () => Promise<EnumServerResponse>;
  axiosGetUplatilacList: () => Promise<EnumServerResponse>;
  axiosGetVremeTrajanjaKontakta: () => Promise<AxiosResponse<any>>;
  axiosGetVrstaGrafikonaRastaList: () => Promise<AxiosResponse<any>>;
  axiosGetPercentilnaKrivaUzrastList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumVrstaUplatiocaList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumPartnerTipList: () => Promise<AxiosResponse<any>>;
  axiosGetEnumPeriodPlacanjaList: () => Promise<AxiosResponse>;
  axiosGetEnumInizijalizacijaKorakList: () => Promise<AxiosResponse>;
}

export function EnumController(): EnumControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetRolaList = () => {
    return axios.get(Endpoint.ROLA_LIST, axiosConfig(authData!.token));
  };
  const axiosGetJezikList = () => {
    return axios.get(Endpoint.JEZIK_LIST, axiosConfig(authData!.token));
  };
  const axiosGetOrganizacionaJedinicaTipList = () => {
    return axios.get(Endpoint.ORGANIZACIONA_JEDINICA_TIP_LIST, axiosConfig(authData!.token));
  };
  const axiosGetSvojinaTipList = () => {
    return axios.get(Endpoint.SVOJINA_TIP_LIST, axiosConfig(authData!.token));
  };
  const axiosGetNivoZdravstveneZastiteList = () => {
    return axios.get(Endpoint.NIVO_ZDRAVSTVENE_ZASTITE_LIST, axiosConfig(authData!.token));
  };
  const axiosGetTipKomponenteList = () => {
    return axios.get(Endpoint.TIP_KOMPONENTE_LIST, axiosConfig(authData!.token));
  };
  const axiosGetPodrazumevanaStranaList = () => {
    return axios.get(Endpoint.PODRAZUMEVANA_STRANA_LIST, axiosConfig(authData!.token));
  };
  const axiosGetRadniDanList = () => {
    return axios.get(Endpoint.RADNI_DAN_LIST, axiosConfig(authData!.token));
  };

  const axiosGetZakazanTerminStatusList = () => {
    return axios.get(Endpoint.ZAKAZAN_TERMIN_STATUS_LIST, axiosConfig(authData!.token));
  };

  const axiosGetPolList = () => {
    return axios.get(Endpoint.POL_LIST, axiosConfig(authData!.token));
  };

  const axiosGetTrajanjeTerminaList = () => {
    return axios.get(Endpoint.TRAJANJE_TERMINA_LIST, axiosConfig(authData!.token));
  };

  const axiosGetTipZakazanogTerminaList = () => {
    return axios.get(Endpoint.TIP_ZAKAZANOG_TERMINA_LIST, axiosConfig(authData!.token));
  };

  const axiosGetOsiguranjeTipList = () => {
    return axios.get(Endpoint.OSIGURANJE_TIP_LIST, axiosConfig(authData!.token));
  };

  const axiosGetEnumParametarKategorijaList = () => {
    return axios.get(Endpoint.PARAMETAR_KATEGORIJA_LIST, axiosConfig(authData!.token));
  };
  const axiosGetEnumPacijentPoljeKategorijaList = () => {
    return axios.get(Endpoint.PACIJENT_POLJE_KATEGORIJA_KATEGORIJA_LIST, axiosConfig(authData!.token));
  };
  const axiosGetEnumAplikativnoPravoList = () => {
    return axios.get(Endpoint.APLIKATIVNO_PRAVO_LIST, axiosConfig(authData!.token));
  };

  const axiosGetEnumVrstaPravaList = () => {
    return axios.get(Endpoint.VRSTA_PRAVA_LIST, axiosConfig(authData!.token));
  };

  const axiosGetEnumTipObjektaList = () => {
    return axios.get(Endpoint.TIP_OBJEKTA_LIST, axiosConfig(authData!.token));
  };

  const axiosGetEnumDokumentacijaPeriodList = () => {
    return axios.get(Endpoint.DOKUMENTACIJA_PERIOD_LIST, axiosConfig(authData!.token));
  };

  const axiosGetEnumRadnikStatusList = () => {
    return axios.get(Endpoint.RADNIK_STATUS_LIST, axiosConfig(authData!.token));
  };

  const axiosGetEnumObavestenjeStatusList = () => {
    return axios.get(Endpoint.OBAVESTENJE_STATUS_LIST, axiosConfig(authData!.token));
  };

  const axiosGetEnumFajlFormatList = () => {
    return axios.get(Endpoint.FAJL_FORMAT, axiosConfig(authData!.token));
  };
  const axiosGetIzvestajParametarTip = () => {
    return axios.get(Endpoint.IZVESTAJ_PARAMETAR_TIP, axiosConfig(authData!.token));
  };

  const axiosGetEnumVremenskiOkvirList = () => {
    return axios.get(Endpoint.VREMENSKI_OKVIR, axiosConfig(authData!.token));
  };

  const axiosGetEnumJedinicaKolicinePodatkaList = () => {
    return axios.get(Endpoint.JEDINICA_KOLICINE_PODATKA, axiosConfig(authData!.token));
  };

  const axiosGetEnumPristupList = () => {
    return axios.get(Endpoint.SIFARNIK_PRISTUP_LIST_ENUM, axiosConfig(authData!.token));
  };

  const axiosGetEnumPonavljanjeList = () => {
    return axios.get(Endpoint.SIFARNIK_PONAVLJANJE_LIST, axiosConfig(authData!.token));
  };

  const axiosGetEnumPeriodList = () => {
    return axios.get(Endpoint.SIFARNIK_PERIOD_LIST, axiosConfig(authData!.token));
  };

  const axiosGetTipKategorijePopustaList = () => {
    return axios.get(Endpoint.SIFARNIK_KATEGORIJA_POPUSTA_TIP_KATEGORIJE, axiosConfig(authData!.token));
  };

  const axiosGetNamenaKategorijePopustaList = () => {
    return axios.get(Endpoint.SIFARNIK_KATEGORIJA_POPUSTA_NAMENA_KATEGORIJE, axiosConfig(authData!.token));
  };

  const axiosGetObavestenjeTipList = () => {
    return axios.get(Endpoint.SIFARNIK_OBAVESTENJE_TIP_LIST, axiosConfig(authData!.token));
  };

  const axiosGetDodatniModulList = () => {
    return axios.get(Endpoint.SIFARNIK_DODATNI_MODUL_LIST, axiosConfig(authData!.token));
  };

  const axiosGetKontaktPlacenList = () => {
    return axios.get(Endpoint.NAPLATA_SIFARNIK_KONTAKT_PLACEN_LIST, axiosConfig(authData?.token!));
  };

  const axiosGetUplatilacList = () => {
    return axios.get(Endpoint.NAPLATA_UPLATILAC_LIST, axiosConfig(authData?.token!));
  };

  const axiosGetVremeTrajanjaKontakta = () => {
    return axios.get(Endpoint.VREME_TRAJANJA_KONTAKTA_LIST, axiosConfig(authData?.token!));
  };

  const axiosGetVrstaGrafikonaRastaList = () => {
    return axios.get(Endpoint.VRSTA_GRAFIKONA_RASTA_LIST, axiosConfig(authData?.token!));
  };

  const axiosGetPercentilnaKrivaUzrastList = () => {
    return axios.get(Endpoint.PERCENTILNA_KRIVA_UZRAST_LIST, axiosConfig(authData?.token!));
  };

  const axiosGetEnumVrstaUplatiocaList = () => {
    return axios.get(Endpoint.VRSTA_UPLATIOCA_LIST, axiosConfig(authData!.token));
  };
  const axiosGetEnumPartnerTipList = () => {
    return axios.get(Endpoint.PARTNER_TIP_LIST, axiosConfig(authData!.token));
  };

  const axiosGetEnumPeriodPlacanjaList = () => {
    return axios.get(Endpoint.PERIOD_PLACANJA_ENUM_LIST, axiosConfig(authData!.token));
  };
  const axiosGetEnumInizijalizacijaKorakList = () => {
    return axios.get(Endpoint.UINICIJALIZACIJA_KORAK_LIST_ENUM, axiosConfig(authData!.token));
  };
  return {
    axiosGetRolaList,
    axiosGetJezikList,
    axiosGetOrganizacionaJedinicaTipList,
    axiosGetSvojinaTipList,
    axiosGetNivoZdravstveneZastiteList,
    axiosGetTipKomponenteList,
    axiosGetPodrazumevanaStranaList,
    axiosGetRadniDanList,
    axiosGetZakazanTerminStatusList,
    axiosGetPolList,
    axiosGetTrajanjeTerminaList,
    axiosGetTipZakazanogTerminaList,
    axiosGetOsiguranjeTipList,
    axiosGetEnumParametarKategorijaList,
    axiosGetEnumPacijentPoljeKategorijaList,
    axiosGetEnumAplikativnoPravoList,
    axiosGetEnumVrstaPravaList,
    axiosGetEnumTipObjektaList,
    axiosGetEnumDokumentacijaPeriodList,
    axiosGetEnumRadnikStatusList,
    axiosGetEnumObavestenjeStatusList,
    axiosGetEnumFajlFormatList,
    axiosGetIzvestajParametarTip,
    axiosGetEnumVremenskiOkvirList,
    axiosGetEnumJedinicaKolicinePodatkaList,
    axiosGetEnumPristupList,
    axiosGetEnumPonavljanjeList,
    axiosGetEnumPeriodList,
    axiosGetTipKategorijePopustaList,
    axiosGetNamenaKategorijePopustaList,
    axiosGetObavestenjeTipList,
    axiosGetDodatniModulList,
    axiosGetKontaktPlacenList,
    axiosGetUplatilacList,
    axiosGetVremeTrajanjaKontakta,
    axiosGetVrstaGrafikonaRastaList,
    axiosGetPercentilnaKrivaUzrastList,
    axiosGetEnumVrstaUplatiocaList,
    axiosGetEnumPartnerTipList,
    axiosGetEnumPeriodPlacanjaList,
    axiosGetEnumInizijalizacijaKorakList,
  };
}
