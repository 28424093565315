import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import PoljeVrednostDto from "../../../model/polje/PoljeVrednostDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import { valueMap } from "../FormularLogical";
import Celobrojni from "./celobrojni/Celobrojni";
import Datumski from "./datumski/Datumski";
import DatumskoVremenski from "./datumski/DatumskoVremenski";
import Vremenski from "./datumski/Vremenski";
import Decimalni from "./decimalni/Decimalni";
import Izborni from "./izborni/Izborni";
import Multiselect from "./multiselect/Multiselect";
import Naziv from "./naziv/Naziv";
import PanelPolje from "./panel/PanelPolje";
import Skupovni from "./skupovni/Skupovni";
import SkupovniRadio from "./skupovni/SkupovniRadio";
import TekstDugacak from "./tekst-dugacak/TekstDugacak";
import Tekst from "./tekst/Tekst";

interface PoljeType {
  id: number;
  naziv: string;
  opis?: string;
  tip: string;
  obavezno?: boolean;
  enumStavke?: EnumBaseReadDto[];
  format?: string;
  decimale?: any;
  onChange: (poljeVrednost: PoljeVrednostDto) => void;
  vrednost: any;
  readonly?: boolean;
  onClick?: Function;
  loading?: boolean;
  bold?: boolean;
  poljeList?: Array<PoljeType>;
  vrednostList?: valueMap;
  useVrednostList?: boolean;
  colSize?: number;
}

export default function Polje(props: PoljeType) {
  const { naziv, opis, obavezno, tip, loading, bold, colSize }: PoljeType = props;
  const col = colSize ? `col-${colSize}` : "col-10";
  if (tip === EnumTipKomponente.PANEL) {
    return <div className={`mt-2 `}>{tip === EnumTipKomponente.PANEL && <PanelPolje {...props} useVrednostList={true} />}</div>;
  } else {
    return (
      <div className={`flex col-12 p-0 justify-content-between ${tip === EnumTipKomponente.LONG_TEXT ? "align-items-start" : "align-items-center"}`}>
        <Naziv naziv={naziv} opis={opis} obavezno={obavezno} />
        <div className={`flex ${col} p-0 align-items-center justify-content-start ${bold ? "font-bold" : ""} `}>
          {loading ? (
            <SkeletonInputItem />
          ) : (
            <>
              {tip === EnumTipKomponente.TEXT && <Tekst {...props} />}
              {tip === EnumTipKomponente.SET && <Skupovni {...props} />}
              {tip === EnumTipKomponente.SET_RADIO && <SkupovniRadio {...props} />}
              {tip === EnumTipKomponente.SET_MULTISELECT && <Multiselect {...props} />}
              {tip === EnumTipKomponente.LONG_TEXT && <TekstDugacak {...props} />}
              {tip === EnumTipKomponente.BOOLEAN && <Izborni {...props} />}
              {tip === EnumTipKomponente.DATE_TIME && <DatumskoVremenski {...props} />}
              {tip === EnumTipKomponente.DATE && <Datumski {...props} />}
              {tip === EnumTipKomponente.TIME && <Vremenski {...props} />}
              {tip === EnumTipKomponente.NUMBER_INTEGER && <Celobrojni {...props} />}
              {tip === EnumTipKomponente.NUMBER_DECIMAL && <Decimalni {...props} />}
            </>
          )}
        </div>
      </div>
    );
  }
}

export type { PoljeType };
