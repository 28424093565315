import { FileUpload } from "primereact/fileupload";
import { TabPanel, TabView } from "primereact/tabview";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../Store";
import KontrolaPravaPristupaService from "../../infrastructure/system/KontrolaPravaPristupaService";
import KontaktDosijeSimpleDto from "../../model/kontakt/KontaktDosijeSimpleDto";
import KontaktReadDto from "../../model/kontakt/KontaktReadDto";
import SkeletonAccordionList from "../app/skeleton/SkeletonAccordionList";
import DatatotekaListPreview from "../pacijent-dosije/dokumentacija/lista-datoteka/DatotekaListPreview";
import { useZakazanTerminDatotekaListForZakazanTermin } from "../pacijent-dosije/dokumentacija/lista-datoteka/hooks/useZakazanTerminDatotekaForZakazanTermin";
import KontaktDatotekaListLogical from "./KontaktDatotekaListLogical";

interface DatotekaPropsType {
  kontakt: KontaktReadDto;
  radnikKreiraoId?: number;
  kontaktList?: KontaktDosijeSimpleDto[];
}

export default function KontaktDatotekaList(props: DatotekaPropsType) {
  const { kontakt, radnikKreiraoId, kontaktList } = props;
  const { pristup, authData } = useContext(AppContext);
  const Labels = useLabels();
  const { uploadHandler, datotekaListLoading, datotekaList, deleteDatoteka, handleDatotekaClick, handleZakazanTerminDatotekaClick } = KontaktDatotekaListLogical({
    pacijentID: kontakt.pacijent.id,
    kontaktID: kontakt.id,
    kontaktList,
  });
  const { hasPacijentUpdate } = KontrolaPravaPristupaService();
  const fu: any = useRef(null);
  const chooseOptions = { label: Labels.LABEL_CHOOSE, icon: "pi pi-fw pi-plus", className: "p-button-outlined p-button-tertiary align-self-center" };
  const uploadOptions = { label: Labels.LABEL_UPLOAD, icon: "pi pi-upload", className: "p-button-outlined p-button-tertiary align-self-center" };
  const cancelOptions = { className: "hidden" };
  const { zakazanTerminDatotekaList } = useZakazanTerminDatotekaListForZakazanTermin(kontakt.zakazanTermin.id!);

  const addFiles = () => {
    return (
      <div className="col-12 justify-content-center">
        <div className="flex flex-wrap col-12 p-0 justify-content-center">
          <i className="pi pi-upload mb-1"></i>
        </div>
        <div className="flex flex-wrap col-12 p-0 justify-content-center">
          <span
            className="orange p-0 font-bold cursor-pointer"
            onClick={() => {
              if (radnikKreiraoId === authData?.currentRadnik.id || hasPacijentUpdate) fu.current.getInput().click();
            }}
          >
            {Labels.DODAJ_DATOTEKU_KONTAKT}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="layout-lista_dokumenata">
      {!datotekaListLoading ? (
        <TabView>
          <TabPanel header="Pregled">
            <FileUpload
              ref={fu}
              chooseOptions={chooseOptions}
              uploadOptions={uploadOptions}
              cancelOptions={cancelOptions}
              emptyTemplate={addFiles}
              multiple={false}
              customUpload
              uploadHandler={(e) => {
                uploadHandler(e);
                fu.current.clear();
              }}
              disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
            />
            <DatatotekaListPreview
              datotekaList={datotekaList.filter((datoteka) => datoteka.kontaktID)}
              deleteDatoteka={deleteDatoteka}
              disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
              handleDatotekaClick={handleDatotekaClick}
            />
          </TabPanel>
          <TabPanel header="Zakazan termin">
            <DatatotekaListPreview
              datotekaList={zakazanTerminDatotekaList ?? []}
              disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
              handleDatotekaClick={handleZakazanTerminDatotekaClick}
            />
          </TabPanel>
        </TabView>
      ) : (
        <SkeletonAccordionList size={1} />
      )}
    </div>
  );
}
