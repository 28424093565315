import SkeletonInputItem from "./SkeletonInputItem";

interface SkeletonPoljeVrednostInRowType {
  size?: number;
}

export default function SkeletonPoljeVrednostInRow(props: SkeletonPoljeVrednostInRowType) {
  const { size } = props;

  return (
    <div className="flex flex-column hc-gap-3">
      {[...Array(size ?? 1).keys()].map((j: number) => (
        <div key={j} className="flex col-12 p-0 align-items-center justify-content-start">
          <div className="flex col-2 p-0 align-items-start justify-content-start font-bold pr-2">
            <SkeletonInputItem />
          </div>
          <div className="flex col-10 align-items-start justify-content-start font-bold p-0">
            <SkeletonInputItem />
          </div>
        </div>
      ))}
    </div>
  );
}
