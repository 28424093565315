import { Skeleton } from "primereact/skeleton";

export default function SkeletonRectangles() {
  return (
    <div>
      <Skeleton className="p-mb-2" height="1rem" width="100%" />
      <Skeleton className="p-mb-2" height="1rem" width="30rem" />
      <Skeleton height="1rem" width="15rem" />
    </div>
  );
}
