import moment from "moment";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../Store";
import SkeletonInputItem from "../../components/app/skeleton/SkeletonInputItem";
import { EnumControllerRepository } from "../../controllers/enum/EnumControllerRepository";
import EnumBaseReadDto from "../../model/sifarnik/EnumBaseReadDto";
import { cyr_to_lat, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "./Utils";

export default function RepositoryLogical() {
  const [entityStatusList, setEntityStatusList] = useState<Array<EnumBaseReadDto>>([]);
  const { axiosGetEntityStatusList } = EnumControllerRepository();
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [entityStatusListLoading, setEntityStatusListLoading] = useState<boolean>(true);

  useEffectOnce(() => {
    const startTime = moment(new Date());

    axiosGetEntityStatusList()
      .then(({ data: { data } }: { data: { data: Array<EnumBaseReadDto> } }) => {
        setEntityStatusList(data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setEntityStatusListLoading, startTime);
      });
  });

  const statusBodyTemplate = (rowData: any) => {
    const entityStatusEnum: EnumBaseReadDto = rowData?.entityStatusEnum ?? rowData?.entityStatus;

    return <div className={`status-badge status-${cyr_to_lat(entityStatusEnum?.naziv.toLowerCase())}`}>{entityStatusEnum?.naziv}</div>;
  };

  const statusRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <>
        {entityStatusListLoading ? (
          <SkeletonInputItem />
        ) : (
          <Dropdown
            value={options.value}
            options={entityStatusList}
            disabled={entityStatusListLoading}
            optionLabel="naziv"
            optionValue="sifra"
            onChange={(e) => {
              options.filterApplyCallback(e.value);
            }}
            placeholder={Labels.SIFARNIK_STATUS_IZBOR_FILTERA}
            className="p-column-filter"
            showClear
          />
        )}
      </>
    );
  };

  return { statusBodyTemplate, statusRowFilterTemplate };
}
