import axios from "axios";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { SifarnikUslugaController } from "../../../controllers/sifarnik-usluga/SifarnikUslugaController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import SifarnikUslugaReadDto from "../../../model/sifarnik-usluga/SifarnikUslugaReadDto";
import SifarnikUslugaRFZOReadDto from "../../../model/sifarnik/sifarnik-usluga-rfzo/SifarnikUslugaRfzoReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudSifarnikUslugaLogicalType {
  sifarnikUslugaOperation: string;
  isDisabled: boolean;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  sifarnikUslugaChange: SifarnikUslugaReadDto | undefined;
  setSifarnikUslugaChange: any;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  sifarnikUslugaId: number | undefined;
  location: any;
  onPageFormTable: (rows: number, first: number) => void;
  first: number;
  tableRows: number;
  sifarnikUslugaRFZOList: Array<SifarnikUslugaRFZOReadDto>;
  sifarnikUslugaRFZOListLoading: boolean;
  sifarnikUslugaLoading: boolean;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  vaziOdRef: HTMLDivElement | any;
  vaziDoRef: HTMLDivElement | any;
}

interface UseParamsType {
  sifarnikUslugaId?: string;
}
export default function CrudSifarnikUslugaLogical(): CrudSifarnikUslugaLogicalType {
  const { showMessage, setShowBlockUI, authData } = useContext(AppContext);
  const navigate = useNavigate();
  const sifarnikUslugaId = Number(useParams<keyof UseParamsType>()["sifarnikUslugaId"]);
  const location = useLocation();
  const [sifarnikUslugaOperation, setsifarnikUslugaOperation] = useState(
    location.state && location.state.sifarnikUslugaOperation !== undefined ? location.state.sifarnikUslugaOperation : EntityOperation.UPDATE
  );
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const Labels = useLabels();
  const [isDisabled] = useState(isFormDisabled(sifarnikUslugaOperation));
  const [sifarnikUslugaChange, setSifarnikUslugaChange] = useState<SifarnikUslugaReadDto | undefined>();
  const [index, setIndex] = useState<number>(tabIndex);
  const [sifarnikUslugaRFZOList, setSifarnikUslugaRFZOList] = useState<Array<SifarnikUslugaRFZOReadDto>>([]);
  const { axiosCreateSifarnikUsluga, axiosUpdateSifarnikUsluga, axiosDeleteSifarnikUsluga, axiosReadSifarnikUsluga, axiosReadSifarnikUslugaRFZOList } = SifarnikUslugaController();
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [sifarnikUslugaRFZOListLoading, setSifarnikUslugaRFZOListLoading] = useState(true);
  const [sifarnikUslugaLoading, setSifarnikUslugaLoading] = useState(true);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const vaziOdRef = useRef<HTMLDivElement | any>(null);
  const vaziDoRef = useRef<HTMLDivElement | any>(null);

  const postLogHighLevel = useLogHighLevel();
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.LABEL_SIFARNIK_USLUGA,
      command: () => {
        navigate("/sifarnik-usluga-list/");
      },
    },
  ]);

  useEffectOnce(() => {
    if (sifarnikUslugaOperation === EntityOperation.CREATE) {
      setSifarnikUslugaChange({
        ...sifarnikUslugaChange!,
        dostupanZaEksterneZakazivace: false,
      });
    }
    fetchSifarnikUslugaRFZO();
    fetchData();
  });

  const fetchSifarnikUslugaRFZO = () => {
    let startTime = moment(new Date());
    axiosReadSifarnikUslugaRFZOList()
      .then((res: any) => {
        setSifarnikUslugaRFZOList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSifarnikUslugaRFZOListLoading, startTime);
      });
  };

  const fetchData = (ppId?: number) => {
    let startTime = moment(new Date());
    let newSifarnikUslugaId = ppId ? ppId : sifarnikUslugaId;
    if (newSifarnikUslugaId) {
      const requestSifarnikUsluga = axiosReadSifarnikUsluga(newSifarnikUslugaId);
      axios
        .all([requestSifarnikUsluga])
        .then(
          axios.spread((responseSifarnikUsluga: any) => {
            setSifarnikUslugaChange(responseSifarnikUsluga.data.data);
            if (breadCrumbItems.length < 2) {
              setBreadCrumbItems([
                ...breadCrumbItems,
                {
                  label:
                    sifarnikUslugaOperation === EntityOperation.UPDATE
                      ? Labels.SIFARNIK_USLUGA_TITLE_DIALOG_UPDATE + responseSifarnikUsluga.data.data.naziv
                      : sifarnikUslugaOperation === EntityOperation.READ
                      ? Labels.SIFARNIK_USLUGA_TITLE_DIALOG_DETAILS + responseSifarnikUsluga.data.data.naziv
                      : Labels.SIFARNIK_USLUGA_TITLE_DIALOG_DELETE + responseSifarnikUsluga.data.data.naziv,
                },
              ]);
            }
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setSifarnikUslugaLoading, startTime);
        });
    } else {
      setSifarnikUslugaLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.SIFARNIK_USLUGA_TITLE_DIALOG_CREATE,
        },
      ]);
    }
  };

  const validateInput = (sifarnikUsluga: SifarnikUslugaReadDto | undefined) => {
    let isInvalid = false;
    if (sifarnikUsluga === undefined || validateStringEmpty(sifarnikUsluga.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(sifarnikUsluga?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }
    if (sifarnikUsluga?.vaziOd === undefined) {
      setInvalidFields((prev) => ({ ...prev, vaziOd: true }));
      isInvalid = true;
    }
    if (sifarnikUsluga?.vaziDo !== undefined && sifarnikUsluga.vaziOd > sifarnikUsluga.vaziDo) {
      setInvalidFields((prev) => ({ ...prev, invalidPeriod: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(sifarnikUslugaChange)) {
      return;
    }
    if (sifarnikUslugaChange) {
      setShowBlockUI(true);
      axiosCreateSifarnikUsluga({ ...sifarnikUslugaChange, ustanova: authData!.currentRadnik.ustanovaPoslednjaIzabrana })
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.SIFARNIK_USLUGA_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_SUCCESS);
          setsifarnikUslugaOperation(EntityOperation.UPDATE);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_1 +
              sifarnikUslugaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_2 +
              sifarnikUslugaChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_3 +
              res.data.data.vaziOd
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(sifarnikUslugaChange)) {
      return;
    }
    if (sifarnikUslugaChange) {
      setShowBlockUI(true);
      axiosUpdateSifarnikUsluga(sifarnikUslugaChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.SIFARNIK_USLUGA_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_1 +
              sifarnikUslugaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_2 +
              sifarnikUslugaChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_3 +
              sifarnikUslugaChange?.vaziOd
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeleteSifarnikUsluga(sifarnikUslugaId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.SIFARNIK_USLUGA_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_1 +
            sifarnikUslugaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_2 +
            sifarnikUslugaChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_3 +
            sifarnikUslugaChange?.vaziOd
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/sifarnik-usluga-list`);
    }
  };
  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    sifarnikUslugaOperation,
    isDisabled,
    sifarnikUslugaChange,
    breadCrumbItems,
    setSifarnikUslugaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    sifarnikUslugaId,
    location,
    first,
    tableRows,
    onPageFormTable,
    sifarnikUslugaRFZOList,
    sifarnikUslugaLoading,
    sifarnikUslugaRFZOListLoading,
    invalidFields,
    setInvalidFields,
    vaziOdRef,
    vaziDoRef,
  };
}
