import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { useContext, useEffect, useMemo } from "react";
import { AppContext, useLabels } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import actions from "../../infrastructure/system/hooks/generisani-izvestaj-reducer/actionsGenerisaniIzvestaj";
import useCallbackWithoutWarnings from "../../infrastructure/system/hooks/useCallbackWithoutWarnings";
import useFetch from "../../infrastructure/system/hooks/useFetch";
import actionsZakazaniIzvestaj from "../../infrastructure/system/hooks/zakazani-izvestaj-reducer/actionsZakazaniIzvestaj";
import IzvestajSimpleReadDto from "../../model/izvestaj/IzvestajSimpleReadDto";
import KategorijaStatistickogIzvestajReadDto from "../../model/izvestaj/kategorija/KategorijaStatistickogIzvestajaReadDto";
import SkeletonAccordionList from "../app/skeleton/SkeletonAccordionList";
import { GenerisaniIzvestajContext } from "./GenerisaniIzvestajLogical";

export default function GenerisaniIzvestajKategorija() {
  const { state, dispatch, fetchData, fetchZakazaniData, stateZakazani, dispatchZakazani, onCancel } = useContext(GenerisaniIzvestajContext);
  const { authData } = useContext(AppContext);
  const Labels = useLabels();

  const { data } = useFetch(Endpoint.KATEGORIJA_STATISTICKIH_IZVESTAJA);

  useEffect(() => {
    if (data) {
      dispatch({ type: actions.SET_KATEGORIJA_LIST, value: data });
    }
  }, [data, dispatch]);

  const onClick = (statistickiIzvestaj: IzvestajSimpleReadDto) => {
    fetchData(true, 0, state.pagination.rows, state.onlyMine ? authData?.currentRadnik.id : undefined, state.selectedStatistickiIzvestaj === statistickiIzvestaj ? undefined : statistickiIzvestaj);
    fetchZakazaniData(stateZakazani.onlyMine ? authData?.currentRadnik.id : undefined, state.selectedStatistickiIzvestaj === statistickiIzvestaj ? undefined : statistickiIzvestaj);
    dispatchZakazani({ type: actionsZakazaniIzvestaj.CANCEL_EDIT, data: undefined });
    onCancel();
  };

  const buttonStyle = useMemo(() => {
    return { backgroundColor: "#2196F3", color: "white" };
  }, []);

  const addContentToTabs = useCallbackWithoutWarnings(
    (kategorija: KategorijaStatistickogIzvestajReadDto) => {
      return kategorija.statistickiIzvestajList.map<any>((statistickiIzvestaj: IzvestajSimpleReadDto) => (
        <Button
          key={statistickiIzvestaj.id}
          className="p-button p-component category p-button-text p-text-left p-button-plain w-full "
          style={state.selectedStatistickiIzvestaj?.naziv === statistickiIzvestaj.naziv ? buttonStyle : {}}
          icon={state.selectedStatistickiIzvestaj?.naziv === statistickiIzvestaj.naziv ? "pi pi-times" : ""}
          iconPos="right"
          onClick={() => onClick(statistickiIzvestaj)}
          label={statistickiIzvestaj.naziv}
        />
      ));
    },
    [state.kategorijaList, state.selectedStatistickiIzvestaj, state.onlyMine]
  );

  const createAccordionTabs = useMemo(() => {
    return state.kategorijaList.map<any>((kategorija: KategorijaStatistickogIzvestajReadDto) => (
      <AccordionTab key={kategorija.id} header={kategorija.naziv} headerClassName="izvestaj-accordian">
        {addContentToTabs(kategorija)}
      </AccordionTab>
    ));
    // eslint-disable-next-line
  }, [state.kategorijaList, state.selectedStatistickiIzvestaj, state.onlyMine]);

  return (
    <div className="col-12 flex pb-0 flex-column hc-gap-4 md:col-3">
      <p className="p-card-title">{Labels.IZVESTAJ_LABEL}</p>
      <div className="border-primary h-full md:pb-0 md:pr-4 md:border-right-1">{state.kategorijaListLoading ? <SkeletonAccordionList /> : <Accordion>{createAccordionTabs}</Accordion>}</div>
    </div>
  );
}
