import { Button } from "primereact/button";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import KontrolaPravaPristupaService from "../../../infrastructure/system/KontrolaPravaPristupaService";
import { tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import NesacuvanNalaz from "../../../model/NesacuvanNalaz/NesacuvanNalaz";
import FormularPopunjenReadDto from "../../../model/formular/FormularPopunjenReadDto";
import FormularReadDto from "../../../model/formular/FormularReadDto";
import KontaktReadDto from "../../../model/kontakt/KontaktReadDto";
import ConfirmComponent from "../../confirm-component/ConfirmComponent";
import FormularView from "../../formular/FormularView";
import FormularLeaveDialog from "../../formular/formular-leave-dialog/FormularLeaveDialog";
import { valueMap } from "../../formular/FormularLogical";
import PoljePopunjenoSkracenoDto from "../../../model/polje/PoljePopunjenoSkracenoReadDto";

interface UnosType {
  izabraniTipUnosa: FormularReadDto;
  maximizeScreen: boolean;
  setMaximizeScreen: React.Dispatch<React.SetStateAction<boolean>>;
  showPrethodniNalaziSabloni: boolean;
  formularPopunjen?: FormularPopunjenReadDto;
  onSave: () => void;
  setShowNoviUnosPage: React.Dispatch<React.SetStateAction<boolean>>;
  openClosePrethodniSabloniNalazi: (showPrethodniNalaziSabloni: boolean) => void;
  kontakt: KontaktReadDto;
  deleteFormularPopunjen: () => void;
  setClickOutsideDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  clickOutsideDisabled: boolean;
  openLeaveDialog: () => void;
  closeLeaveDialog: () => void;
  confirmLeave: () => void;
  visibleLeaveDialog: boolean;
  poljeVrednostListRef: React.MutableRefObject<any>;
  nesacuvanNalazState: NesacuvanNalaz | undefined;
  izabraniTipUnosaRef: React.MutableRefObject<any>;
  izabraniPopunjeniUnosRef: React.MutableRefObject<any>;
  setNesacuvanNalazState: React.Dispatch<React.SetStateAction<NesacuvanNalaz | undefined>>;
  stampaj: (tipUnosa: FormularReadDto, popunjeniUnos: FormularPopunjenReadDto, poljeVrednostList?: valueMap) => void;
  formatPoljePopunjenoList: (poljePopunjeno: PoljePopunjenoSkracenoDto[]) => valueMap;
}
export default function Unos(props: UnosType) {
  const {
    setShowNoviUnosPage,
    izabraniTipUnosa,
    formularPopunjen,
    onSave,
    maximizeScreen,
    setMaximizeScreen,
    showPrethodniNalaziSabloni,
    openClosePrethodniSabloniNalazi,
    kontakt,
    deleteFormularPopunjen,
    setClickOutsideDisabled,
    clickOutsideDisabled,
    visibleLeaveDialog,
    confirmLeave,
    openLeaveDialog,
    closeLeaveDialog,
    poljeVrednostListRef,
    nesacuvanNalazState,
    izabraniTipUnosaRef,
    izabraniPopunjeniUnosRef,
    setNesacuvanNalazState,
    stampaj,
    formatPoljePopunjenoList,
  } = props;
  const Labels = useLabels();
  const { pristup, authData } = useContext(AppContext);

  const { hasFormularPopunjenDelete } = KontrolaPravaPristupaService();
  const [disabledActionButtons, setDisabledActionButtons] = useState<boolean>(false);

  return (
    <div>
      <FormularLeaveDialog
        izabraniPopunjeniUnosRef={izabraniPopunjeniUnosRef}
        izabraniTipUnosaRef={izabraniTipUnosaRef}
        kontakt={kontakt}
        poljeVrednostListRef={poljeVrednostListRef}
        confirmLeave={confirmLeave}
        closeDialog={closeLeaveDialog}
        visibleDialog={visibleLeaveDialog}
      />
      <div className={maximizeScreen ? "flex flex-column w-screen h-screen absolute bottom-0 p-2 left-0 bg-white z-index-1000" : "flex flex-column w-full p-0"}>
        <div className="flex justify-content-between align-items-center w-full p-0">
          <div className="flex col-2 p-0 align-items-center justify-content-start">
            <Button
              label={Labels.KONTAKT_NAZAD}
              tooltip={Labels.KONTAKT_SVI_NALAZI}
              tooltipOptions={tooltipOptionsBottom}
              icon="pi pi-chevron-left"
              className="p-button-outlined p-button-tertiary"
              onClick={() => {
                if (!clickOutsideDisabled) {
                  openClosePrethodniSabloniNalazi(true);
                  setShowNoviUnosPage(false);
                  setMaximizeScreen(false);
                } else {
                  openLeaveDialog();
                }
              }}
              disabled={disabledActionButtons}
            />
          </div>
          <div className="flex col-1 p-0 align-items-center justify-content-start font-bold">
            <Button
              tooltipOptions={tooltipOptionsBottom}
              icon={maximizeScreen ? "pi pi-window-minimize" : "pi pi-window-maximize"}
              className="p-button-outlined p-button-tertiary"
              onClick={() => {
                setMaximizeScreen(!maximizeScreen);
              }}
              tooltip={maximizeScreen ? Labels.KONTAKT_SMANJI_UNOS : Labels.KONTAKT_UVECAJ_UNOS}
              disabled={disabledActionButtons}
            />
          </div>
          <div className="flex col-8 p-0 align-items-center justify-content-center font-bold">{izabraniTipUnosa?.naziv}</div>
          <div className="flex col-1 p-0 align-items-center justify-content-end font-bold pull-right">
            <Button
              tooltipOptions={tooltipOptionsBottom}
              icon="pi pi-angle-double-left"
              className="p-button-outlined nalaz-sablon-button mb-1 p-button-tertiary"
              tooltip={Labels.KONTAKT_PRETHODNI_NALAZI_SABLONI}
              onClick={() => {
                openClosePrethodniSabloniNalazi(showPrethodniNalaziSabloni);
              }}
              disabled={disabledActionButtons}
            />
            {formularPopunjen && (
              <div className="flex justify-content-end">
                <ConfirmComponent
                  tooltip={Labels.FORMULAR_POPUNJEN_DELETE}
                  tooltipPosition={tooltipOptionsBottom}
                  buttonIcon="pi pi-trash"
                  buttonClassName="p-button-outlined nalaz-sablon-button p-button-tertiary ml-2 mb-1"
                  acceptClassName="p-button p-component text-white p-button-tertiary"
                  rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
                  confirmTitle={Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_POPUNJEN_FORMULAR}
                  confirmIcon="pi pi-exclamation-triangle"
                  onAccept={() => deleteFormularPopunjen()}
                  disabled={!pristup || (!hasFormularPopunjenDelete && authData?.currentRadnik.id !== formularPopunjen.radnikKreirao.id) || disabledActionButtons}
                />
              </div>
            )}
          </div>
        </div>
        <FormularView
          setClickOutsideDisabled={setClickOutsideDisabled}
          clickOutsideDisabled={clickOutsideDisabled}
          formularId={izabraniTipUnosa?.id}
          formular={izabraniTipUnosa}
          formularPopunjen={formularPopunjen}
          kontakt={kontakt}
          afterSave={onSave}
          maximizeScreen={maximizeScreen}
          openClosePrethodniSabloniNalazi={openClosePrethodniSabloniNalazi}
          showPrethodniNalaziSabloni={showPrethodniNalaziSabloni}
          poljeVrednostListRef={poljeVrednostListRef}
          izabraniTipUnosaRef={izabraniTipUnosaRef}
          nesacuvanNalazState={nesacuvanNalazState}
          izabraniPopunjeniUnosRef={izabraniPopunjeniUnosRef}
          setNesacuvanNalazState={setNesacuvanNalazState}
          setDisabledActionButtons={setDisabledActionButtons}
          stampaj={stampaj}
          formatPoljePopunjenoList={formatPoljePopunjenoList}
        />
      </div>
    </div>
  );
}
