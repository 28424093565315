import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Ref, RefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { RadniPeriodController } from "../../../../controllers/radni-period/RadniPeriodController";
import EnumRola from "../../../../infrastructure/system/EnumRola";
import { DATE_FORMAT, DATE_FORMAT_2, DATE_TIME_FORMAT_2, DATE_TIME_FORMAT_FULL, TIME_FORMAT, handleAxiosCallError, skeletonTimeout } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import OrganizacionaJedinicaCvorDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaCvorDto";
import OrganizacionaJedinicaRadnikReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaRadnikReadDto";
import RadniPeriodReadDto from "../../../../model/radni-period/RadniPeriodReadDto";
import RadnikSkracenoReadDto from "../../../../model/radnik/RadnikSkracenoReadDto";
import useEffectWithoutWarnings from "../../../../infrastructure/system/hooks/useEffectWithoutWarnings";

export interface RadniPeriodDan {
  datum: string;
  nizPerioda: Array<RadniPeriodReadDto>;
  radniDan: string;
}
interface RadniPeriodListLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  radniPeriodList: Array<RadniPeriodDan>;
  odabranaOrgJedList: Array<OrganizacionaJedinicaCvorDto> | undefined;
  radnikList: Array<RadnikSkracenoReadDto>;
  setSelectedRadnik: React.Dispatch<React.SetStateAction<RadnikSkracenoReadDto | undefined>>;
  selectedRadnik: RadnikSkracenoReadDto | undefined;
  datumOd: Date;
  datumDo: Date;
  setDatumOd: React.Dispatch<React.SetStateAction<Date>>;
  setDatumDo: React.Dispatch<React.SetStateAction<Date>>;
  first: number;
  tableRows: number;
  onPageRadniPeriod: (rows: number, first: number) => void;
  searchData: () => void;
  exportData: Array<any>;
  odabranaOrgJed: OrganizacionaJedinicaCvorDto | undefined;
  renderDateTime: (date: Date) => string;
  expandedRows: any;
  setExpandedRows: React.Dispatch<React.SetStateAction<any>>;
  getTime: (date: Date) => string;
  selectedRows: Array<RadniPeriodReadDto>;
  setSelectedRows: React.Dispatch<React.SetStateAction<Array<RadniPeriodReadDto>>>;
  deleteRadniPeriodList: (radniPeriodIdList: Array<number>, rowsRadniPeriod: Array<RadniPeriodReadDto>) => void;
  ustanovaOrgJedCallback: Ref<any>;
  radniPeriodLoading: boolean;
  radnikListLoading: boolean;
  datumOdRef: RefObject<Calendar>;
  datumDoRef: RefObject<Calendar>;
}

const getTime = (date: Date) => moment(date).format(TIME_FORMAT);

const dateTimeToDate = (dateTime: Date) => moment(dateTime).format(DATE_FORMAT_2);

const renderDateTime = (date: Date) => moment(date).format(DATE_TIME_FORMAT_FULL);

const dateTimeToPrettyDate = (dateTime: Date) => moment(dateTime).format(DATE_FORMAT);

const getDatumiList2 = (radniPeriodList: Array<RadniPeriodReadDto>) =>
  radniPeriodList.reduce((prevValue: Array<RadniPeriodDan>, currValue: RadniPeriodReadDto) => {
    const elWithSameName: RadniPeriodDan | undefined = prevValue.find((el: RadniPeriodDan) => el.datum === dateTimeToPrettyDate(currValue.periodPocetak));
    if (elWithSameName) elWithSameName.nizPerioda.push(currValue);
    else
      prevValue.push({
        datum: dateTimeToPrettyDate(currValue.periodPocetak),
        radniDan: currValue.radniDan.naziv,
        nizPerioda: [currValue],
      });
    return prevValue;
  }, []);

export default function RadniPeriodListLogical(): RadniPeriodListLogicalType {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI, odabranaOrganizacionaJedinicaList, updateOdabranaOrganizacionaJedinicaList } = useContext(AppContext);
  const [radniPeriodList, setRadniPeriodList] = useState<Array<RadniPeriodDan>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [radnikList, setRadnikList] = useState<Array<RadnikSkracenoReadDto>>([]);
  const [selectedRadnik, setSelectedRadnik] = useState<RadnikSkracenoReadDto>();
  const [datumOd, setDatumOd] = useState<Date>(new Date());
  const [datumDo, setDatumDo] = useState<Date>(new Date());
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedRows, setSelectedRows] = useState<Array<RadniPeriodReadDto>>([]);
  const [radniPeriodLoading, setRadniPeriodLoading] = useState<boolean>(true);
  const [radnikListLoading, setRadnikListLoading] = useState<boolean>(false);

  const firstLoadedRef = useRef(false);
  const orgJedRef = useRef<any>(null);
  const datumOdRef = useRef<Calendar>(null);
  const datumDoRef = useRef<Calendar>(null);

  const postLogHighLevel = useLogHighLevel();

  const { axiosGetOrganizacionaJedinicaRadnikListByRola } = OrganizacionaJedinicaController();
  const { axiosGetRadniPeriodList, axiosDeleteRadniPeriodList } = RadniPeriodController();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.RADNI_PERIOD_LIST,
    },
  ];

  const ustanovaOrgJedCallback: any = useCallback((odabranaOrgJedRefTmp: any) => {
    if (odabranaOrgJedRefTmp) {
      orgJedRef.current = odabranaOrgJedRefTmp;
    }
  }, []);

  useEffectWithoutWarnings(() => {
    return () => {
      updateOdabranaOrganizacionaJedinicaList([]);
    };
  }, []);

  const odabranaOrgJed = odabranaOrganizacionaJedinicaList && odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1];

  useEffect(() => {
    if (
      odabranaOrganizacionaJedinicaList &&
      odabranaOrganizacionaJedinicaList.length > 0 &&
      odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1]?.podredjeneOrganizacioneJedinice?.length === 0
    ) {
      setRadnikListLoading(true);
      let startTime = moment(new Date());
      firstLoadedRef.current && setRadniPeriodLoading(true);
      axiosGetOrganizacionaJedinicaRadnikListByRola(odabranaOrgJed?.organizacionaJedinica.id, EnumRola.LEKAR)
        .then(({ data: { data } }: { data: { data: Array<OrganizacionaJedinicaRadnikReadDto> } }) => {
          const radnikListTmp = data.map((orgJedRadnik: OrganizacionaJedinicaRadnikReadDto) => orgJedRadnik.radnik);
          setRadnikList(radnikListTmp);
          setSelectedRadnik(radnikListTmp[0]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          firstLoadedRef.current && skeletonTimeout(setRadniPeriodLoading, startTime);
          skeletonTimeout(setRadnikListLoading, startTime);
        });
    } else {
      setRadniPeriodList([]);
      setExpandedRows({});
      setSelectedRows([]);
      setRadnikList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odabranaOrganizacionaJedinicaList]);

  useEffect(() => {
    setExpandedRows({});
    setSelectedRows([]);
    if (selectedRadnik) {
      searchData();
    } else {
      setRadniPeriodList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRadnik]);

  const searchData = () => {
    let startTime = moment(new Date());
    setRadniPeriodLoading(true);

    const searchData = {
      organizacionaJedinicaID: odabranaOrgJed?.organizacionaJedinica.id,
      radnikID: selectedRadnik?.id,
      datumOd: dateTimeToDate(datumOd),
      datumDo: dateTimeToDate(datumDo),
      viewNeaktivni: false,
    };

    axiosGetRadniPeriodList(searchData)
      .then(({ data: { data } }: { data: { data: Array<RadniPeriodReadDto> } }) => {
        const radniPeriodListTmp: Array<RadniPeriodDan> = getDatumiList2(data);
        setRadniPeriodList(radniPeriodListTmp);
        setSelectedRows([]);

        const exportList = new Array<any>();
        data.forEach((radniPeriod: RadniPeriodReadDto) => {
          exportList.push({
            [Labels.RADNI_PERIOD_COLUMN_RADNIK]: `${selectedRadnik?.ime} ${selectedRadnik?.prezime}`,
            [Labels.RADNI_PERIOD_COLUMN_ORGANIZACIONA_JEDINICA]: `${odabranaOrgJed?.organizacionaJedinica.naziv}`,
            [Labels.RADNI_PERIOD_COLUMN_POCETAK_PERIODA]: renderDateTime(radniPeriod.periodPocetak),
            [Labels.RADNI_PERIOD_COLUMN_KRAJ_PERIODA]: renderDateTime(radniPeriod.periodKraj),
          });
        });
        setExportData(exportList);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setRadniPeriodLoading, startTime);
      });
  };

  const onPageRadniPeriod = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const deleteRadniPeriodList = (radniPeriodIdList: Array<number>, rowsRadniPeriod: any) => {
    setShowBlockUI(true);
    axiosDeleteRadniPeriodList({ radniPeriodIDList: radniPeriodIdList })
      .then((res: any) => {
        setSelectedRows([]);
        searchData();
        rowsRadniPeriod.forEach((rowRadniPeriod: RadniPeriodReadDto) => {
          //Belezimo u log high level da je ovaj period obrisan
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNI_PERIOD_1 +
              selectedRadnik?.ime +
              " " +
              selectedRadnik?.prezime +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_2 +
              moment(rowRadniPeriod.periodPocetak).format(DATE_TIME_FORMAT_2) +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_3 +
              moment(rowRadniPeriod.periodKraj).format(DATE_TIME_FORMAT_2) +
              " ."
          );
        });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return {
    breadCrumbItems,
    radniPeriodList,
    odabranaOrgJedList: odabranaOrganizacionaJedinicaList,
    radnikList,
    selectedRadnik,
    setSelectedRadnik,
    datumOd,
    datumDo,
    setDatumOd,
    setDatumDo,
    first,
    tableRows,
    onPageRadniPeriod,
    searchData,
    exportData,
    odabranaOrgJed,
    renderDateTime,
    expandedRows,
    setExpandedRows,
    getTime,
    setSelectedRows,
    selectedRows,
    deleteRadniPeriodList,
    ustanovaOrgJedCallback,
    radniPeriodLoading,
    radnikListLoading,
    datumOdRef,
    datumDoRef,
  };
}
