import axios from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { EnumController } from "../../../controllers/enum/EnumController";
import { RolaService } from "../../../controllers/rola/RolaService";
import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import { UlogaController } from "../../../controllers/uloga/UlogaController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumRola from "../../../infrastructure/system/EnumRola";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import UlogaReadDto from "../../../model/uloga/UlogaReadDto";
import { AppContext, useLabels } from "../../../Store";

interface UlogaCrudLogicalType {
  ulogaOperation: string;
  isDisabled: boolean;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  ulogaChange: UlogaReadDto | undefined;
  setUlogaChange: any;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  location: any;
  ulogaId: number | undefined;
  enumRolaList: Array<EnumBaseReadDto>;
  ustanovaId: number | undefined;
  ulogaLoading: boolean;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
}

interface UseParamsType {
  ulogaId?: string;
  ustanovaId?: string;
}

export default function CrudUlogaLogical(): UlogaCrudLogicalType {
  const { showMessage, authData, setShowBlockUI } = useContext(AppContext);
  const navigate = useNavigate();
  const ulogaId = Number(useParams<keyof UseParamsType>()["ulogaId"]);
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const location = useLocation();
  const [ulogaOperation, setUlogaOperation] = useState<string>(location.state && location.state.ulogaOperation !== undefined ? location.state.ulogaOperation : EntityOperation.UPDATE);
  const [ustanovaNaziv] = useState<string>(location.state && location.state.ustanovaNaziv !== undefined ? location.state.ustanovaNaziv : "");
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const Labels = useLabels();
  const [isDisabled] = useState<boolean>(isFormDisabled(ulogaOperation));
  const [ulogaChange, setUlogaChange] = useState<UlogaReadDto | undefined>();
  const [index, setIndex] = useState<number>(tabIndex);
  const [enumRolaList, setEnumRolaList] = useState<Array<EnumBaseReadDto>>([]);
  const [ulogaLoading, setUlogaLoading] = useState<boolean>(false);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const { axiosCreateUloga, axiosUpdateUloga, axiosDeleteUloga, axiosReadUloga } = UlogaController();
  const { axiosSuperadminUstanovaReadUloga, axiosSuperadminUstanovaUpdateUloga, axiosSuperadminUstanovaCreateUloga, axiosSuperadminUstanovaDeleteUloga } = SuperadminController();
  const { isSuperadmin } = RolaService();

  const postLogHighLevel = useLogHighLevel();
  const { axiosGetRolaList } = EnumController();
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>(
    ustanovaId
      ? [
          {
            label: Labels.USTANOVA_LIST,
            command: () => {
              navigate(`/crud-ustanova`);
            },
          },
          {
            label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + " " + ustanovaNaziv,
            command: () => {
              navigate(`/crud-ustanova/${ustanovaId}`, {
                state: {
                  tabIndex: 5,
                },
              });
            },
          },
        ]
      : [
          {
            label: Labels.ULOGA_LIST,
            command: () => {
              navigate("/uloga-list/");
            },
          },
        ]
  );
  useEffectOnce(() => {
    if (ulogaOperation === EntityOperation.CREATE) {
      setUlogaChange((ulogaChange: any) => ({
        ...ulogaChange!,
        ustanova: {
          id: ustanovaId ?? authData?.currentRadnik.ustanovaPoslednjaIzabrana.id,
        },
      }));
    }

    fetchData();
  });

  const fetchData = (uId?: number | undefined, newOperation?: string | undefined) => {
    axiosGetRolaList()
      .then((response: any) => {
        const enumRolaList = isSuperadmin ? response.data.data : response.data.data.filter((enumRola: EnumBaseReadDto) => enumRola.sifra !== EnumRola.SUPERADMIN);
        setEnumRolaList(enumRolaList);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
    let startTime = moment(new Date());

    let newUlogaId = uId ? uId : ulogaId;
    let operation = newOperation ? newOperation : ulogaOperation;

    if (newUlogaId) {
      setUlogaLoading(true);
      const requestUloga = ustanovaId ? axiosSuperadminUstanovaReadUloga(ustanovaId, newUlogaId) : axiosReadUloga(newUlogaId);
      axios
        .all([requestUloga])
        .then(
          axios.spread((responseUloga: any) => {
            if (!isSuperadmin && responseUloga.data.data.rola.sifra === EnumRola.SUPERADMIN) {
              if (breadCrumbItems.length < 2) {
                setBreadCrumbItems([
                  ...breadCrumbItems,
                  {
                    label:
                      operation === EntityOperation.UPDATE
                        ? Labels.ULOGA_TITLE_DIALOG_UPDATE + responseUloga.data.data.naziv
                        : operation === EntityOperation.READ
                        ? Labels.ULOGA_TITLE_DIALOG_DETAILS + responseUloga.data.data.naziv
                        : Labels.ULOGA_TITLE_DIALOG_DELETE + responseUloga.data.data.naziv,
                  },
                ]);
              }
              setUlogaOperation(EntityOperation.CREATE);
              navigate(`/crud-uloga`, {
                replace: true,
                state: { ulogaOperation: EntityOperation.CREATE },
              });
            } else {
              setUlogaChange(responseUloga.data.data);
              if (breadCrumbItems.length > 2) breadCrumbItems.pop();
              setBreadCrumbItems([
                ...breadCrumbItems,
                {
                  label:
                    operation === EntityOperation.UPDATE
                      ? Labels.ULOGA_TITLE_DIALOG_UPDATE + responseUloga.data.data.naziv
                      : operation === EntityOperation.READ
                      ? Labels.ULOGA_TITLE_DIALOG_DETAILS + responseUloga.data.data.naziv
                      : Labels.ULOGA_TITLE_DIALOG_DELETE + responseUloga.data.data.naziv,
                },
              ]);
            }
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setUlogaLoading, startTime);
        });
    } else {
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.ULOGA_TITLE_DIALOG_CREATE,
        },
      ]);
    }
  };
  const validateInput = (uloga: UlogaReadDto | undefined) => {
    let isInvalid = false;
    if (uloga === undefined || validateStringEmpty(uloga?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }

    if (validateStringEmpty(uloga?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(uloga?.rola?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, rola: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(ulogaChange)) {
      return;
    }

    const postRequestUloga = ustanovaId ? ulogaChange && axiosSuperadminUstanovaCreateUloga(ustanovaId, ulogaChange) : ulogaChange && axiosCreateUloga(ulogaChange);
    if (postRequestUloga) {
      setShowBlockUI(true);
      postRequestUloga
        .then((res: any) => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_1 +
              ulogaChange!.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_2 +
              ulogaChange!.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_3 +
              ulogaChange!.rola.sifra.toLowerCase()
          );
          showMessage(MessageType.SUCCESS, Labels.ULOGA_TITLE_MESSAGE_CREATE_ULOGA_SUCCESS);
          setUlogaOperation(EntityOperation.UPDATE);
          ustanovaId
            ? navigate(`/crud-ustanova/${ustanovaId}/crud-uloga/${res.data.data.id}`, { replace: true, state: { ulogaOperation: EntityOperation.UPDATE, ulogaId: res.data.data.id } })
            : navigate(`/crud-uloga/${res.data.data.id}`, { replace: true, state: { ulogaOperation: EntityOperation.UPDATE, ulogaId: res.data.data.id } });
          fetchData(res.data.data.id, EntityOperation.UPDATE);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(ulogaChange)) {
      return;
    }

    const updateRequestUstanovaUloga = ustanovaId ? ulogaChange && axiosSuperadminUstanovaUpdateUloga(ustanovaId, ulogaChange) : ulogaChange && axiosUpdateUloga(ulogaChange);
    if (updateRequestUstanovaUloga) {
      setShowBlockUI(true);
      updateRequestUstanovaUloga
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.ULOGA_TITLE_MESSAGE_UPDATE_ULOGA_SUCCESS);
          onCancel();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
      postLogHighLevel(
        Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_1 +
          ulogaChange!.naziv +
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_2 +
          ulogaChange!.sifra +
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_3 +
          ulogaChange!.rola.sifra.toLowerCase()
      );
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    const deleteRequestUstanovaUloga = ustanovaId ? axiosSuperadminUstanovaDeleteUloga(ustanovaId, ulogaId) : axiosDeleteUloga(ulogaId);
    deleteRequestUstanovaUloga
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.ULOGA_TITLE_MESSAGE_DELETE_ULOGA_SUCCESS);
        onCancel();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
    postLogHighLevel(
      Labels.LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_1 +
        ulogaChange!.naziv +
        Labels.LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_2 +
        ulogaChange!.sifra +
        Labels.LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_3 +
        ulogaChange!.rola.sifra.toLowerCase()
    );
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/uloga-list`);
    }
  };

  return {
    ulogaOperation,
    isDisabled,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    ulogaChange,
    setUlogaChange,
    breadCrumbItems,
    index,
    setIndex,
    location,
    ulogaId,
    enumRolaList,
    ustanovaId,
    ulogaLoading,
    invalidFields,
    setInvalidFields,
  };
}
