import { Accordion, AccordionTab } from "primereact/accordion";
import { FileUpload } from "primereact/fileupload";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../../Store";
import KontrolaPravaPristupaService from "../../../../infrastructure/system/KontrolaPravaPristupaService";
import KontaktDosijeSimpleDto from "../../../../model/kontakt/KontaktDosijeSimpleDto";
import SkeletonAccordionList from "../../../app/skeleton/SkeletonAccordionList";
import DatatotekaListPreview from "./DatotekaListPreview";
import PacijentDatotekaListLogical from "./PacijentDatotekaListLogical";
import { useZakazanTerminDatotekaListForPacijent } from "./hooks/useZakazanTerminDatotekaListForPacijent";

interface DatotekaPropsType {
  pacijentID: number;
  radnikKreiraoId?: number;
  kontaktList?: KontaktDosijeSimpleDto[];
}

export default function PacijentDatotekaList(props: DatotekaPropsType) {
  const { pacijentID, radnikKreiraoId, kontaktList } = props;
  const { pristup, authData } = useContext(AppContext);
  const Labels = useLabels();
  const { uploadHandler, datotekaListLoading, datotekaList, deleteDatoteka, handleDatotekaClick, handleZakazanTerminDatotekaClick } = PacijentDatotekaListLogical({
    pacijentID,
    kontaktList,
  });
  const { hasPacijentUpdate } = KontrolaPravaPristupaService();
  const fu: any = useRef(null);
  const chooseOptions = { label: Labels.LABEL_CHOOSE, icon: "pi pi-fw pi-plus", className: "p-button-outlined p-button-tertiary align-self-center" };
  const uploadOptions = { label: Labels.LABEL_UPLOAD, icon: "pi pi-upload", className: "p-button-outlined p-button-tertiary align-self-center" };
  const cancelOptions = { className: "hidden" };
  const headerTemplate = (naslov: string) => <div className="col-offset-1 col-9 flex align-items-center justify-content-center p-0 h-2rem">{`${naslov}`}</div>;
  const { zakazanTerminDatotekaList } = useZakazanTerminDatotekaListForPacijent(pacijentID);

  const addFiles = () => {
    return (
      <div className="col-12 justify-content-center">
        <div className="flex flex-wrap col-12 p-0 justify-content-center">
          <i className="pi pi-upload mb-1"></i>
        </div>
        <div className="flex flex-wrap col-12 p-0 justify-content-center">
          <span
            className="orange p-0 font-bold cursor-pointer"
            onClick={() => {
              if (radnikKreiraoId === authData?.currentRadnik.id || hasPacijentUpdate) fu.current.getInput().click();
            }}
          >
            {Labels.DODAJ_DATOTEKU_PACIJENT_DOSIJE}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="layout-lista_dokumenata">
      <Panel className="mt-2">
        {!datotekaListLoading ? (
          <Accordion activeIndex={null} collapseIcon="pi pi-caret-down" expandIcon="pi pi-caret-right">
            <AccordionTab key={0} headerTemplate={headerTemplate(Labels.LISTA_DATOTEKA)}>
              <TabView>
                <TabPanel header="Pacijent dosije">
                  <FileUpload
                    ref={fu}
                    chooseOptions={chooseOptions}
                    uploadOptions={uploadOptions}
                    cancelOptions={cancelOptions}
                    emptyTemplate={addFiles}
                    multiple={false}
                    customUpload
                    uploadHandler={(e) => {
                      uploadHandler(e);
                      fu.current.clear();
                    }}
                    disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                  />
                  <DatatotekaListPreview
                    datotekaList={datotekaList.filter((datoteka) => !datoteka.kontaktID)}
                    deleteDatoteka={deleteDatoteka}
                    disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                    handleDatotekaClick={handleDatotekaClick}
                  />
                </TabPanel>
                <TabPanel header="Pregledi">
                  <DatatotekaListPreview
                    datotekaList={datotekaList.filter((datoteka) => datoteka.kontaktID)}
                    deleteDatoteka={deleteDatoteka}
                    disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                    handleDatotekaClick={handleDatotekaClick}
                  />
                </TabPanel>
                <TabPanel header="Zakazani termini">
                  <DatatotekaListPreview
                    datotekaList={zakazanTerminDatotekaList ?? []}
                    disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                    handleDatotekaClick={handleZakazanTerminDatotekaClick}
                  />
                </TabPanel>
              </TabView>
            </AccordionTab>
          </Accordion>
        ) : (
          <SkeletonAccordionList size={1} />
        )}
      </Panel>
    </div>
  );
}
