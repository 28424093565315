import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { useRef } from "react";
import EnumPol from "../../../infrastructure/system/EnumPol";
import actionsAddPacijent from "../../../infrastructure/system/hooks/dialog-add-pacijent-reducer/actionsAddPacijent";
import { checkEmpty, DATE_FORMAT, formatDate, removeAllMasks, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import PacijentGrupnoObavestenjeServisDto from "../../../model/pacijent/PacijentGrupnoObavestenjeServisDto";
import PacijentObavestenjeServisDto from "../../../model/pacijent/PacijentObavestenjeServisDto";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import { useLabels } from "../../../Store";
import CalendarComponent from "../../administracija/calendar-component/CalendarComponent";
import SkeletonAccordionList from "../../app/skeleton/SkeletonAccordionList";
import DialogComponent from "../../dialog-component/DialogComponent";

export interface DialogAddPacijentType {
  isDialogOpen: boolean;
  closeDialog: () => void;
  polList: Array<EnumBaseReadDto> | undefined;
  polListLoading: boolean;
  obavestenjeTipList: Array<EnumBaseReadDto> | undefined;
  obavestenjeTipListLoading: boolean;
  pacijent: PacijentReadDto | any;
  dispatch: any;
  onCreate: () => void;
  pacijentExists: boolean;
  warningMessageRef: React.RefObject<HTMLDivElement>;
  checkPacijentExists: () => void;
}

export default function DialogAddPacijent(props: DialogAddPacijentType) {
  const { isDialogOpen, closeDialog, polList, polListLoading, pacijent, dispatch, onCreate, obavestenjeTipList, pacijentExists, warningMessageRef, checkPacijentExists } = props;
  const Labels = useLabels();
  const calendarRef = useRef<HTMLDivElement | any>(null);

  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-content-end">
        <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button p-button-outlined" onClick={closeDialog} />
        <Button
          label={Labels.BUTTON_ADD_PACIJENT}
          tooltip={Labels.LABEL_TOOLTIP_ADD_PATIENT}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-info flex-shrink-0 ml-2"
          icon="pi pi-plus"
          onClick={onCreate}
          data-cy="addPatientBtn"
        />
      </div>
    );
  };

  const renderGenderForLabel = (genderEnum: string | undefined) => {
    if (!checkEmpty(genderEnum)) {
      switch (genderEnum) {
        case EnumPol.MUSKI:
          return Labels.PACIJENT_EXIST_MALE_GENDER_TEXT;
        case EnumPol.ZENSKI:
          return Labels.PACIJENT_EXIST_FEMALE_GENDER_TEXT;
        case EnumPol.NIJE_UNET:
          return Labels.PACIJENT_EXIST_NOT_SPECIFIED_GENDER_TEXT;
        default:
          break;
      }
    }
  };

  return (
    <DialogComponent
      header={Labels.BUTTON_ADD_PACIJENT}
      visible={isDialogOpen}
      onHide={closeDialog}
      footer={renderFooter}
      className="w-10 sm:w-8 md:w-8 lg:w-5 xl:w-5 cy-addPatientDialog"
      content={
        <>
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3 font-bold">
              <div>{Labels.LABEL_IME + Labels.SPECIAL_CHAR_REQUIRED}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={pacijent?.ime ?? ""}
                name="ime"
                onChange={(e) => {
                  dispatch({ type: actionsAddPacijent.PACIJENT_CHANGE, fieldName: e.target.name, value: e.target.value });
                }}
                data-cy="ime"
                onBlur={checkPacijentExists}
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3 font-bold">
              <div>{Labels.LABEL_PREZIME + Labels.SPECIAL_CHAR_REQUIRED}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={pacijent?.prezime ?? ""}
                name="prezime"
                onChange={(e) => {
                  dispatch({ type: actionsAddPacijent.PACIJENT_CHANGE, fieldName: e.target.name, value: e.target.value });
                }}
                data-cy="prezime"
                onBlur={checkPacijentExists}
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3 font-bold">
              <div>{Labels.PACIJENT_IME_RODITELJA}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={pacijent?.imeRoditelja ?? ""}
                name="imeRoditelja"
                onChange={(e) => {
                  dispatch({ type: actionsAddPacijent.PACIJENT_CHANGE, fieldName: e.target.name, value: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3 font-bold">
              <div>{Labels.LABEL_POL_KONTAKT_OSOBE + Labels.SPECIAL_CHAR_REQUIRED}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0 flex">
              {polListLoading ? (
                <SkeletonAccordionList />
              ) : (
                polList?.map((pol: EnumBaseReadDto, key: number) => (
                  <div key={key}>
                    <RadioButton
                      inputId={pol?.sifra}
                      name="polTrenutni"
                      value={pol}
                      checked={pacijent?.polTrenutni?.sifra === pol.sifra}
                      onChange={(e) => {
                        dispatch({ type: actionsAddPacijent.PACIJENT_CHANGE, fieldName: e.target.name, value: e.value });
                      }}
                      data-cy={pol.sifra}
                    />
                    <label className="cursor-pointer mx-2" htmlFor={pol.sifra}>
                      {pol.naziv}
                    </label>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3 font-bold">
              <div>{Labels.DATUM_RODJENJA + Labels.SPECIAL_CHAR_REQUIRED}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <CalendarComponent
                ref={calendarRef}
                value={pacijent?.vremeRodjenja}
                name="vremeRodjenja"
                onChange={(e: CalendarChangeEvent) => {
                  dispatch({ type: actionsAddPacijent.PACIJENT_CHANGE, fieldName: e.target.name, value: formatDate(e.value as Date | undefined) });
                  if (e.value as Date) calendarRef?.current?.hide();
                }}
                onHide={() => {
                  removeAllMasks();
                  document.body.classList.remove("p-overflow-hidden");
                }}
                className="cy-datumRodjenja"
                placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                appendTo={document.body}
                todayButtonClassName="cy-today-btn"
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3 font-bold">
              <div>{Labels.PACIJENT_TELEFON}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={pacijent?.telefon ?? ""}
                name="telefon"
                onChange={(e) => {
                  dispatch({ type: actionsAddPacijent.PACIJENT_CHANGE, fieldName: e.target.name, value: e.target.value });
                }}
                data-cy="telefon"
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3 font-bold">
              <div>{Labels.PACIJENT_EMAIL}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={pacijent?.email ?? ""}
                name="email"
                onChange={(e) => {
                  dispatch({ type: actionsAddPacijent.PACIJENT_CHANGE, fieldName: e.target.name, value: e.target.value });
                }}
                data-cy="email"
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3 font-bold">
              <div>{Labels.PACIJENT_ADRESA}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={pacijent?.adresa ?? ""}
                name="adresa"
                onChange={(e) => {
                  dispatch({ type: actionsAddPacijent.PACIJENT_CHANGE, fieldName: e.target.name, value: e.target.value });
                }}
                data-cy="adresa"
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3 font-bold">
              <div>{Labels.PACIJENT_OBAVESTENJE_TIP_LIST}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <MultiSelect
                value={pacijent?.pacijentObavestenjeServisList?.map((pacijentObavestenjeServis: PacijentObavestenjeServisDto) => pacijentObavestenjeServis.obavestenjeTip) ?? ""}
                options={obavestenjeTipList ?? []}
                name="pacijentObavestenjeServisList"
                onChange={(e) => {
                  dispatch({ type: actionsAddPacijent.PACIJENT_CHANGE, fieldName: e.target.name, value: e.value.map((obavestenjeTip: EnumBaseReadDto) => ({ obavestenjeTip })) });
                }}
                optionLabel="naziv"
                optionValue="obavestenjeTip.sifra"
                className="w-full"
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3 font-bold">
              <div>{Labels.PACIJENT_GRUPNO_OBAVESTENJE_TIP_LIST}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <MultiSelect
                value={
                  pacijent?.pacijentGrupnoObavestenjeServisList?.map((pacijentGrupnoObavestenjeServis: PacijentGrupnoObavestenjeServisDto) => pacijentGrupnoObavestenjeServis.obavestenjeTip) ?? ""
                }
                options={obavestenjeTipList ?? []}
                name="pacijentGrupnoObavestenjeServisList"
                onChange={(e) => {
                  dispatch({ type: actionsAddPacijent.PACIJENT_CHANGE, fieldName: e.target.name, value: e.value.map((obavestenjeTip: EnumBaseReadDto) => ({ obavestenjeTip })) });
                }}
                optionLabel="naziv"
                optionValue="obavestenjeTip.sifra"
                className="w-full"
              />
            </div>
          </div>
          {pacijentExists && (
            <div className="text-warning pl-3 pt-2" ref={warningMessageRef}>
              <div className="mb-1">
                <span>{Labels.SPECIAL_CHAR_REQUIRED + " " + Labels.PACIJENT_EXIST_PATIENT_TEXT}</span>
                <span className="font-bold">
                  {` ${pacijent?.ime} ${pacijent.prezime}, ${renderGenderForLabel(pacijent?.polTrenutni?.sifra)} ${Labels.PACIJENT_EXIST_GENDER_TEXT}, ${Labels.PACIJENT_EXIST_DATE_TEXT} ${formatDate(
                    pacijent?.vremeRodjenja,
                    DATE_FORMAT
                  )} `}
                </span>
                <span>{Labels.PACIJENT_EXIST_ALREADY_EXIST_TEXT_1}</span>
              </div>
              <div>{Labels.PACIJENT_EXIST_ALREADY_EXIST_TEXT_2}</div>
            </div>
          )}
        </>
      }
    />
  );
}
