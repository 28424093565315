import axios from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EnumController } from "../../../controllers/enum/EnumController";

import { PacijentPoljeController } from "../../../controllers/pacijent-polje/PacijentPoljeController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";

import PacijentPoljeKategorijaReadDto from "../../../model/pacijent-polje/PacijentPoljeKategorijaReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudPacijentPoljeKategorijaLogicalType {
  pacijentPoljeKategorijaChange: PacijentPoljeKategorijaReadDto | undefined;
  setPacijentPoljeKategorijaChange: any;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  isDisabled: boolean;
  exportData: Array<any>;
  breadCrumbItems: Array<any>;
  pacijentPoljeKategorijaOperation: string;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  kategorijaList: Array<EnumBaseReadDto>;
  pacijentPoljeKategorijaLoading: boolean;
  kategorijaOptionsLoading: boolean;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
}

interface UseParamsType {
  pacijentPoljeKategorijaId?: string;
  pacijentPoljeId?: string;
}

export default function CrudPacijentPoljeKategorijaLogical(): CrudPacijentPoljeKategorijaLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const [exportData] = useState<Array<any>>([]);
  const location = useLocation();
  const pacijentPoljeId = Number(useParams<keyof UseParamsType>()["pacijentPoljeId"]);
  const [pacijentPoljeOperation] = useState<string>(location.state ? location.state.pacijentPoljeOperation : "");
  const { pacijentPoljeKategorijaId } = useParams<keyof UseParamsType>();
  const [isDisabled] = useState(isFormDisabled(location.state ? location.state.pacijentPoljeKategorijaOperation : EntityOperation.UPDATE));
  const [pacijentPoljeKategorijaChange, setPacijentPoljeKategorijaChange] = useState<PacijentPoljeKategorijaReadDto>();
  const navigate = useNavigate();
  const [index, setIndex] = useState<number>(0);
  const [pacijentPoljeKategorijaOperation] = useState<string>(location.state ? location.state.pacijentPoljeKategorijaOperation : EntityOperation.UPDATE);
  const [pacijentPoljeKategorijaLoading, setPacijentPoljeKategorijaLoading] = useState<boolean>(true);
  const [kategorijaOptionsLoading, setKategorijaOptionsLoading] = useState<boolean>(true);
  const [kategorijaList, setKategorijaList] = useState<Array<EnumBaseReadDto>>([]);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: any } | undefined>(undefined);
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<any>>([
    {
      label: Labels.PACIJENT_POLJE_LIST,
      command: () => {
        navigate("/pacijent-polje-list");
      },
    },
  ]);

  const { axiosCreatePacijentPoljeKategorija, axiosReadPacijentPoljeKategorija, axiosDeletePacijentPoljeKategorija, axiosUpdatePacijentPoljeKategorija, axiosReadPacijentPolje } =
    PacijentPoljeController();
  const { axiosGetEnumPacijentPoljeKategorijaList } = EnumController();

  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = async () => {
    let pacijentPoljeKategorijaNaziv = "";
    let startTime = moment(new Date());
    if (pacijentPoljeKategorijaId) {
      await axiosReadPacijentPoljeKategorija(Number(pacijentPoljeKategorijaId))
        .then((res: any) => {
          pacijentPoljeKategorijaNaziv = res.data.data.kategorija.naziv;
          setPacijentPoljeKategorijaChange(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPacijentPoljeKategorijaLoading, startTime);
        });
    } else {
      setPacijentPoljeKategorijaLoading(false);
    }
    const requestPacijentPolje = axiosReadPacijentPolje(pacijentPoljeId);
    const requestPacijentPoljeKategorijaTypeOptions = axiosGetEnumPacijentPoljeKategorijaList();

    axios
      .all([requestPacijentPoljeKategorijaTypeOptions, requestPacijentPolje])
      .then(
        axios.spread((responsePacijentPoljeKategorijaTypeOptions: any, responsePacijentPolje: any) => {
          let tempBreadCrumbItems = breadCrumbItems;
          setKategorijaList(responsePacijentPoljeKategorijaTypeOptions.data.data);
          if (breadCrumbItems.length < 2) {
            setBreadCrumbItems([
              ...tempBreadCrumbItems,
              {
                label:
                  pacijentPoljeOperation === EntityOperation.UPDATE
                    ? Labels.PACIJENT_POLJE_TITLE_DIALOG_UPDATE + responsePacijentPolje.data.data.naziv
                    : pacijentPoljeOperation === EntityOperation.READ
                    ? Labels.PACIJENT_POLJE_TITLE_DIALOG_DETAILS + responsePacijentPolje.data.data.naziv
                    : Labels.PACIJENT_POLJE_TITLE_DIALOG_DELETE + responsePacijentPolje.data.data.naziv,
                command: () => {
                  navigate(`/crud-pacijent-polje/${pacijentPoljeId}`, {
                    state: {
                      tabIndex: 1,
                      pacijentPoljeOperation: pacijentPoljeOperation,
                    },
                  });
                },
              },
              {
                label:
                  pacijentPoljeKategorijaOperation === EntityOperation.UPDATE
                    ? Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_UPDATE + pacijentPoljeKategorijaNaziv
                    : pacijentPoljeKategorijaOperation === EntityOperation.READ
                    ? Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DETAILS + pacijentPoljeKategorijaNaziv
                    : pacijentPoljeKategorijaOperation === EntityOperation.CREATE
                    ? Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_CREATE
                    : Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DELETE + pacijentPoljeKategorijaNaziv,
              },
            ]);
          }
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setKategorijaOptionsLoading, startTime);
      });
  };

  const validatePacijentPoljeKategorija = (pacijentPoljeKategorija?: PacijentPoljeKategorijaReadDto) => {
    let isInvalid = false;
    if (!pacijentPoljeKategorija?.kategorija?.sifra) {
      setInvalidFields((prev) => ({ ...prev, kategorija: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreate = () => {
    let newPacijentPoljeKategorija = pacijentPoljeKategorijaChange;
    if (newPacijentPoljeKategorija && !newPacijentPoljeKategorija?.hasOwnProperty("obavezno")) {
      newPacijentPoljeKategorija = { ...newPacijentPoljeKategorija, obavezno: false };
    }
    if (newPacijentPoljeKategorija && !newPacijentPoljeKategorija?.hasOwnProperty("readOnly")) {
      newPacijentPoljeKategorija = { ...newPacijentPoljeKategorija, readOnly: false };
    }

    if (!validatePacijentPoljeKategorija(newPacijentPoljeKategorija)) {
      return;
    }
    if (newPacijentPoljeKategorija) {
      setShowBlockUI(true);
      axiosCreatePacijentPoljeKategorija({ ...newPacijentPoljeKategorija, pacijentPolje: { id: pacijentPoljeId } })
        .then((response: any) => {
          showMessage(MessageType.SUCCESS, Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_CREATE_PACIJENT_POLJE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_KATEGORIJA_1 +
              response.data.data.kategorija.naziv +
              (pacijentPoljeKategorijaChange?.obavezno ? Labels.LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_1 : Labels.LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_2) +
              (pacijentPoljeKategorijaChange?.readOnly ? Labels.LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_3 : "")
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };
  const onDelete = () => {
    if (pacijentPoljeKategorijaChange) {
      setShowBlockUI(true);
      axiosDeletePacijentPoljeKategorija(pacijentPoljeKategorijaChange?.id)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_DELETE_PACIJENT_POLJE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_KATEGORIJA_1 +
              pacijentPoljeKategorijaChange?.kategorija.naziv +
              (pacijentPoljeKategorijaChange?.obavezno ? Labels.LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_1 : Labels.LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_2) +
              (pacijentPoljeKategorijaChange?.readOnly ? Labels.LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_3 : "") +
              ". "
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };
  const onUpdate = () => {
    if (!validatePacijentPoljeKategorija(pacijentPoljeKategorijaChange)) {
      return;
    }
    if (pacijentPoljeKategorijaChange) {
      setShowBlockUI(true);
      axiosUpdatePacijentPoljeKategorija(pacijentPoljeKategorijaChange)
        .then((response: any) => {
          showMessage(MessageType.SUCCESS, Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_UPDATE_PACIJENT_POLJE_SUCCESS);
          onCancel();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_KATEGORIJA_1 +
              response.data.data.kategorija.naziv +
              (pacijentPoljeKategorijaChange?.obavezno ? Labels.LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_1 : Labels.LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_2) +
              (pacijentPoljeKategorijaChange?.readOnly ? Labels.LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_3 : "") +
              ". "
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };
  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command();
    } else {
      navigate(`/crud-pacijent-polje/${pacijentPoljeId}`, { state: { tabIndex: 1, pacijentPoljeOperation: pacijentPoljeOperation } });
    }
  };

  return {
    pacijentPoljeKategorijaChange,
    setPacijentPoljeKategorijaChange,
    setIndex,
    index,
    isDisabled,
    exportData,
    breadCrumbItems,
    pacijentPoljeKategorijaOperation,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    kategorijaList,
    pacijentPoljeKategorijaLoading,
    kategorijaOptionsLoading,
    invalidFields,
    setInvalidFields,
  };
}
