import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../controllers/enum/EnumController";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import QueryKeys from "../../QueryKeys";

export function useRadniDaniList() {
  const { axiosGetRadniDanList } = EnumController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.RADNI_DAN_LIST],
    queryFn: () => axiosGetRadniDanList(),
  });

  const radniDanList: Array<EnumBaseReadDto> = data?.data?.data;

  let radniDani: Array<EnumBaseReadDto> = radniDanList?.map((radniDan: EnumBaseReadDto) => ({ ...radniDan, naziv: radniDan.naziv.toLowerCase() }));
  let radniDaniMap = new Map<string, EnumBaseReadDto>();
  radniDani?.forEach((radniDan: EnumBaseReadDto) => radniDaniMap?.set(radniDan.sifra, { ...radniDan }));

  return { radniDanList, refetch, isLoading, radniDani, radniDaniMap };
}
