import { AxiosResponse } from "axios";
import moment from "moment/moment";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { PacijentController } from "../../../controllers/pacijent/PacijentController";
import MessageType from "../../../infrastructure/system/MessageType";
import { DATE_FORMAT_2, handleAxiosCallError, skeletonTimeout } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import PacijentSimpleReadDto from "../../../model/pacijent/PacijentSimpleReadDto";

interface SpajanjePacijenataType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  leftPatientSearchValues: PatientType | undefined;
  setLeftPatientSearchValues: React.Dispatch<React.SetStateAction<PatientType | undefined>>;
  rightPatientSearchValues: PatientType | undefined;
  setRightPatientSearchValues: React.Dispatch<React.SetStateAction<PatientType | undefined>>;
  prepisiPodatke: () => void;
  showMergePatients: boolean;
  setShowMergePatients: React.Dispatch<React.SetStateAction<boolean>>;
  leftSelected: PacijentSimpleReadDto | undefined;
  setLeftSelected: React.Dispatch<React.SetStateAction<PacijentSimpleReadDto | any>>;
  rightSelected: PacijentSimpleReadDto | undefined;
  setRightSelected: React.Dispatch<React.SetStateAction<PacijentSimpleReadDto | any>>;
  searchPatients: (searchParams: PatientType, rows: number, page: number, isLeftTable?: boolean) => void;
  leftPatientsList: PacijentSimpleReadDto[];
  rightPatientsList: PacijentSimpleReadDto[];
  leftFirst: number;
  setLeftFirst: React.Dispatch<React.SetStateAction<number>>;
  leftTableRows: number;
  rightFirst: number;
  setRightFirst: React.Dispatch<React.SetStateAction<number>>;
  rightTableRows: number;
  onPageSpajanje: (rows: number, first: number, page: number, isLeftTable: boolean) => void;
  leftSearchTableVisible: boolean;
  rightSearchTableVisible: boolean;
  leftResultsLoading: boolean;
  rightResultsLoading: boolean;
  mergePatients: () => void;
}

export interface PatientType {
  ime: string | undefined;
  prezime: string | undefined;
  datumRodjenja: Date | string | undefined;
}

export default function SpajanjePacijenataLogical(): SpajanjePacijenataType {
  const Labels = useLabels();
  const [leftPatientSearchValues, setLeftPatientSearchValues] = useState<PatientType>();
  const [rightPatientSearchValues, setRightPatientSearchValues] = useState<PatientType>();
  const [showMergePatients, setShowMergePatients] = useState<boolean>(false);
  const [leftSelected, setLeftSelected] = useState<PacijentSimpleReadDto | undefined>(undefined);
  const [rightSelected, setRightSelected] = useState<PacijentSimpleReadDto | undefined>(undefined);
  const [leftPatientsList, setLeftPatientsList] = useState<PacijentSimpleReadDto[]>([]);
  const [rightPatientsList, setRightPatientsList] = useState<PacijentSimpleReadDto[]>([]);
  const [leftFirst, setLeftFirst] = useState<number>(0);
  const [leftTableRows, setLeftTableRows] = useState<number>(10);
  const [rightFirst, setRightFirst] = useState<number>(0);
  const [rightTableRows, setRightTableRows] = useState<number>(10);
  const [leftSearchTableVisible, setLeftSearchTableVisible] = useState<boolean>(false);
  const [rightSearchTableVisible, setRightSearchTableVisible] = useState<boolean>(false);
  const [leftResultsLoading, setLeftResultsLoading] = useState<boolean>(false);
  const [rightResultsLoading, setRightResultsLoading] = useState<boolean>(false);
  const { axiosSearchPacijentSpajanje, axiosPacijentMerge } = PacijentController();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const postLogHighLevel = useLogHighLevel();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LABEL_SPAJANJE_PACIJENATA,
    },
  ];

  const prepisiPodatke = () => {
    setRightPatientSearchValues({
      ime: leftPatientSearchValues?.ime,
      prezime: leftPatientSearchValues?.prezime,
      datumRodjenja: leftPatientSearchValues?.datumRodjenja,
    });
  };

  const searchPatients = (searchParams: PatientType, rows: number, page: number, isLeftTable?: boolean) => {
    const startTime = moment(new Date());
    isLeftTable ? setLeftResultsLoading(true) : setRightResultsLoading(true);

    axiosSearchPacijentSpajanje({ ...searchParams, datumRodjenja: moment(searchParams?.datumRodjenja).format(DATE_FORMAT_2) })
      .then((response: AxiosResponse) => {
        if (isLeftTable) {
          setLeftPatientsList(response.data.data);
          setLeftSearchTableVisible(true);
        } else {
          setRightPatientsList(response.data.data);
          setRightSearchTableVisible(true);
        }
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(isLeftTable ? setLeftResultsLoading : setRightResultsLoading, startTime);
      });
  };

  const mergePatients = () => {
    setShowBlockUI(true);
    axiosPacijentMerge({ pacijentBrisanje: { id: rightSelected?.id }, pacijentSpajanje: { id: leftSelected?.id } })
      .then(() => {
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_PACIJENT_USPESNO_SPOJEN_1 + leftSelected?.id + Labels.LOG_HIGH_LEVEL_PACIJENT_USPESNO_SPOJEN_2 + rightSelected?.id, leftSelected?.id);
        setShowMergePatients(false);
        setLeftSearchTableVisible(false);
        setRightSearchTableVisible(false);
        setRightPatientsList([]);
        setLeftPatientsList([]);
        setLeftSelected(undefined);
        setRightSelected(undefined);
        showMessage(MessageType.SUCCESS, Labels.PACIJENTI_USPESNO_SPOJENI);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onPageSpajanje = (rows: number, first: number, page: number, isLeftTable: boolean) => {
    if (isLeftTable) {
      setLeftFirst(first);
      setLeftTableRows(rows);
      searchPatients(leftPatientSearchValues!, rows, page, true);
    } else {
      setRightFirst(first);
      setRightTableRows(rows);
      searchPatients(rightPatientSearchValues!, rows, page, false);
    }
  };

  return {
    breadCrumbItems,
    leftPatientSearchValues,
    setLeftPatientSearchValues,
    rightPatientSearchValues,
    setRightPatientSearchValues,
    prepisiPodatke,
    showMergePatients,
    setShowMergePatients,
    leftSelected,
    setLeftSelected,
    rightSelected,
    setRightSelected,
    searchPatients,
    leftPatientsList,
    rightPatientsList,
    leftFirst,
    setLeftFirst,
    leftTableRows,
    rightFirst,
    setRightFirst,
    rightTableRows,
    onPageSpajanje,
    leftSearchTableVisible,
    rightSearchTableVisible,
    leftResultsLoading,
    rightResultsLoading,
    mergePatients,
  };
}
