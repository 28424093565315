import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { useLabels } from "../../../Store";
import { DATE_FORMAT } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import PacijentSimpleReadDto from "../../../model/pacijent/PacijentSimpleReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";

interface RezultatPretragePropsType {
  selected: PacijentSimpleReadDto | undefined;
  setSelected: React.Dispatch<React.SetStateAction<PacijentSimpleReadDto | any>>;
  data: PacijentSimpleReadDto[];
  first: number;
  tableRows: number;
  leftSelected?: PacijentSimpleReadDto;
  rightSelected?: PacijentSimpleReadDto;
  isLeftTable?: boolean;
  onPageSpajanje: (rows: number, first: number, page: number, isLeftTable: boolean) => void;
  resultsLoading: boolean;
}

const RezultatPretrage = (props: RezultatPretragePropsType) => {
  const Labels = useLabels();
  const { selected, setSelected, data, leftSelected, rightSelected, isLeftTable, first, tableRows, onPageSpajanje, resultsLoading } = props;
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const actionBodyTemplate = (rowData: PacijentSimpleReadDto) => {
    return <Button icon="pi pi-user" className="p-button-rounded p-button-info p-button-text" onClick={() => window.open(`/pacijent-dosije/${rowData.id}`, "_blank")} />;
  };

  const vremeRodjenjaBodyTemplate = (rowData: PacijentSimpleReadDto) => {
    return moment(rowData.vremeRodjenja as Date).format(DATE_FORMAT);
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: "", filter: false, sortrable: false, headerClassName: "w-3rem" },
    { columnName: Labels.LABEL_SPAJANJE_PACIJENATA_ID_PACIJENTA, filter: false, sortrable: false },
    { columnName: Labels.LABEL_SPAJANJE_PACIJENATA_PREZIME, filter: false, sortrable: false },
    { columnName: Labels.LABEL_SPAJANJE_PACIJENATA_IME, filter: false, sortrable: false },
    { columnName: Labels.LABEL_SPAJANJE_PACIJENATA_DATUM, filter: false, sortrable: false },
  ];

  return (
    <div className="w-12 xl:w-12">
      {resultsLoading ? (
        <SkeletonTable hasFilterColumns={false} dataTableColumnsProperty={columnsProperty} isVisibleButtonList={true} defaultButtonCount={1} />
      ) : (
        <DataTable
          value={data}
          selection={selected}
          onSelectionChange={(e) => setSelected(e.value)}
          dataKey="id"
          emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
          breakpoint="1215px"
          responsiveLayout="stack"
          showSelectionElement={(rowData) => {
            if (isLeftTable) {
              return rowData.id !== rightSelected?.id ? true : null;
            } else {
              return rowData.id !== leftSelected?.id ? true : null;
            }
          }}
          isDataSelectable={(rowData) => {
            if (isLeftTable) {
              return rowData.data.id !== rightSelected?.id;
            } else {
              return rowData.data.id !== leftSelected?.id;
            }
          }}
          paginator
          paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
          first={first}
          rows={tableRows}
          onPage={(e: DataTablePageEvent) => {
            onPageSpajanje(e.rows, e.first, e.page!, isLeftTable ?? false);
          }}
          className="spajanje-rezultati-pretrage"
        >
          <Column selectionMode="single" headerStyle={{ width: "3em" }} bodyClassName="xl:p-0"></Column>
          <Column field="id" header={Labels.LABEL_SPAJANJE_PACIJENATA_ID_PACIJENTA}></Column>
          <Column field="prezime" header={Labels.LABEL_SPAJANJE_PACIJENATA_PREZIME}></Column>
          <Column field="ime" header={Labels.LABEL_SPAJANJE_PACIJENATA_IME}></Column>
          <Column body={vremeRodjenjaBodyTemplate} header={Labels.LABEL_SPAJANJE_PACIJENATA_DATUM}></Column>
          <Column body={actionBodyTemplate} bodyClassName="p-0" />
        </DataTable>
      )}
    </div>
  );
};

export default RezultatPretrage;
