import moment from "moment";
import { useContext, useState } from "react";
import { AppContext } from "../../../../../Store";
import { ReceptController } from "../../../../../controllers/recept/ReceptController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../../infrastructure/system/Utils";
import ReceptReadDto from "../../../../../model/kontakt/ReceptReadDto";
import SifarnikLekovaReadDto from "../../../../../model/sifarnik-lekova-na-recept/SifarnikLekovaReadDto";
import SifarnikMkbDto from "../../../../../model/sifarnik/SifarnikMkbDto";

interface KontaktDetailsLogicalType {
  receptList: ReceptReadDto[] | undefined;
  receptListLoading: boolean;
}

interface KontaktDetailsLogicalProps {
  kontaktID: number;
}

export default function KontaktDetailsLogical({ kontaktID }: KontaktDetailsLogicalProps): KontaktDetailsLogicalType {
  const [receptList, setReceptList] = useState<Array<ReceptReadDto> | undefined>();
  const [receptListLoading, setReceptListLoading] = useState(true);
  const { axiosGetReceptListByKontaktID, axiosDopuniTerapiju } = ReceptController();
  const { showMessage } = useContext(AppContext);

  useEffectOnce(() => {
    fetchReceptList(kontaktID);
  });

  const fetchReceptList = (kontaktID: number) => {
    let startTime = moment(new Date());
    setReceptListLoading(true);
    axiosGetReceptListByKontaktID(kontaktID)
      .then(async (responseReceptList) => {
        const receptList = responseReceptList.data.data;
        if (receptList.length > 0) {
          const lekovi = receptList.map((recept: any) => recept.idRepositoryLek).reduce((f: any, s: any) => `${f},${s}`);
          const dijagnoze = receptList.map((recept: any) => recept.dijagnoza.sifarnikMkbId).reduce((f: any, s: any) => `${f},${s}`);
          const terapijaFillerSearchDto = { lekovi, dijagnoze };

          axiosDopuniTerapiju(terapijaFillerSearchDto)
            .then((res) => {
              const lekovi = res.data.data.lekovi;
              const dijagnoze = res.data.data.dijagnoze;
              const receptListFull = receptList.map((recept: ReceptReadDto) => ({
                ...recept,
                lek: lekovi.find((l: SifarnikLekovaReadDto) => l.id === recept.idRepositoryLek),
                dijagnoza: { sifarnikMkb: dijagnoze.find((d: SifarnikMkbDto) => d.id === recept.dijagnoza.sifarnikMkbId) },
              }));
              setReceptList(receptListFull);
            })
            .catch((error) => {
              handleAxiosCallError(showMessage, error);
            });
        } else {
          setReceptList([]);
        }
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setReceptListLoading, startTime);
      });
  };
  return {
    receptList,
    receptListLoading,
  };
}
