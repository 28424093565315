import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { useLabels } from "../../../../../../Store";
import ReceptCreateDto from "../../../../../../model/kontakt/ReceptCreateDto";

interface FourthStepType {
  terapijaChange: ReceptCreateDto | undefined;
  setTerapijaChange: any;
}

export default function FourthStep(props: FourthStepType) {
  const { terapijaChange, setTerapijaChange } = props;
  const Labels = useLabels();

  return (
    <>
      <h4 className="font-bold mb-6 pl-3">{Labels.KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_TITLE}</h4>
      <div className="terapijaDialog--content">
        <div className="flex flex-row flex-wrap">
          <div className="col-4">{Labels.KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_IZDAVANJE_LEKA_HITNO}</div>
          <div className="col-8">
            <Checkbox
              inputId="binary"
              checked={terapijaChange?.hitnoIzdavanje ?? false}
              onChange={(e) => {
                setTerapijaChange({
                  ...terapijaChange!,
                  hitnoIzdavanje: e.checked,
                });
              }}
            />
          </div>
          <div className="col-4">{Labels.KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_ZABRANA_TERAPIJSKE_PARALELE}</div>
          <div className="col-8">
            <Checkbox
              inputId="binary"
              checked={terapijaChange?.terapijskaParalelaZabranjena ?? false}
              onChange={(e) => {
                setTerapijaChange({
                  ...terapijaChange!,
                  terapijskaParalelaZabranjena: e.checked,
                });
              }}
            />
          </div>
          <div className="col-4">{Labels.KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_NAPOMENA}</div>
          <div className="col-8">
            <InputTextarea
              rows={5}
              value={terapijaChange?.napomena ?? ""}
              onChange={(e) => {
                setTerapijaChange({
                  ...terapijaChange!,
                  napomena: e.target.value,
                });
              }}
              className="w-full"
            />
          </div>
          <div className="col-4">{Labels.KONTAKT_TERAPIJA_STAMPA_TOOLTIP}</div>
          <div className="col-8">
            <Checkbox
              inputId="binary"
              checked={terapijaChange?.stampaRecepta ?? false}
              onChange={(e) => {
                setTerapijaChange({
                  ...terapijaChange!,
                  stampaRecepta: e.checked,
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
