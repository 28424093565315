import { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { PacijentController } from "../../../../controllers/pacijent/PacijentController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { DATE_FORMAT_2, handleAxiosCallError, skeletonTimeout } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import SpajanjePacijenataArhivaDto from "../../../../model/pacijent/SpajanjePacijenataArhivaDto";

interface RazdvajanjePacijenataType {
  patientSearchValues: PatientType | undefined;
  setPatientSearchValues: React.Dispatch<React.SetStateAction<PatientType | undefined>>;
  searchPatients: (searchParams: PatientType, rows: number, page: number) => void;
  resultsLoading: boolean;
  patientsList: SpajanjePacijenataArhivaDto[];
  tableRows: number;
  first: number;
  setFirst: React.Dispatch<React.SetStateAction<number>>;
  onPage: (rows: number, first: number, page: number) => void;
  splitPatients: (data: SpajanjePacijenataArhivaDto) => void;
  searchTableVisible: boolean;
}

export interface PatientType {
  ime: string | undefined;
  prezime: string | undefined;
  datumRodjenja: Date | string | undefined;
}

export default function RazdvajanjePacijenataLogical(): RazdvajanjePacijenataType {
  const [patientSearchValues, setPatientSearchValues] = useState<PatientType | undefined>();
  const [patientsList, setPatientsList] = useState<SpajanjePacijenataArhivaDto[]>([]);
  const [searchTableVisible, setSearchTableVisible] = useState<boolean>(false);
  const [resultsLoading, setResultsLoading] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<number>(10);
  const [first, setFirst] = useState<number>(0);
  const { axiosSearchPacijentRazdvajanje, axiosPacijentSplit } = PacijentController();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const postLogHighLevel = useLogHighLevel();
  const Labels = useLabels();

  const searchPatients = (searchParams: PatientType, rows: number, page: number) => {
    const startTime = moment(new Date());
    setResultsLoading(true);

    axiosSearchPacijentRazdvajanje({ ...searchParams, datumRodjenja: moment(searchParams?.datumRodjenja).format(DATE_FORMAT_2), page: page, size: rows })
      .then((response: AxiosResponse) => {
        setPatientsList(response.data.data);
        setSearchTableVisible(true);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setResultsLoading, startTime);
      });
  };

  const splitPatients = (data: SpajanjePacijenataArhivaDto) => {
    setShowBlockUI(true);
    axiosPacijentSplit({ pacijentActive: { id: data.pacijentNovi?.id }, pacijentDeleted: { id: data.pacijentStari?.id } })
      .then(() => {
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_RAZDVAJANJE_PACIJENATA_1 + `${data.pacijentNovi.ime} ${data.pacijentNovi.prezime}, ${data.pacijentStari.ime} ${data.pacijentStari.prezime}`);
        setSearchTableVisible(false);
        setPatientsList([]);
        setPatientSearchValues(undefined);
        showMessage(MessageType.SUCCESS, Labels.LABEL_RAZDVAJANJE_PACIJENATA_SUCCESS_MESSAGE);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onPage = (rows: number, first: number, page: number) => {
    setFirst(first);
    setTableRows(rows);
    searchPatients(patientSearchValues!, rows, page);
  };

  return {
    patientSearchValues,
    setPatientSearchValues,
    searchPatients,
    resultsLoading,
    patientsList,
    tableRows,
    first,
    setFirst,
    onPage,
    splitPatients,
    searchTableVisible,
  };
}
