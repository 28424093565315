import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import SifarnikProizvodaReadDto from "../../../model/sifarnik/sifarnik-proizvoda/SifarnikProizvodaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import SifarnikProizvodaListLogical from "./SifarnikProizvodaListLogical";

export default function SifarnikProizvodaListView() {
  const {
    sifarnikProizvodaList,
    first,
    tableRows,
    changeSifarnikProizvodaSearchData,
    sifarnikProizvodaSearchData,
    fetchData,
    searchSifarnikProizvodaByEnter,
    onPageSifarnikProizvoda,
    exportData,
    breadCrumbItems,
    sifarnikProizvodaLoading,
  } = SifarnikProizvodaListLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const openSifarnikProizvoda = (entityOperation: string, id?: number) => {
    const sifarnikProizvodaId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-sifarnik-proizvoda/${sifarnikProizvodaId}`, {
      state: { sifarnikProizvodaOperation: entityOperation },
    });
  };

  const crudBodyTemplate = (rowSifarnikProizvoda: SifarnikProizvodaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_PROIZVODA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowSifarnikProizvoda && openSifarnikProizvoda(EntityOperation.UPDATE, rowSifarnikProizvoda.id)}
        />
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_PROIZVODA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowSifarnikProizvoda && openSifarnikProizvoda(EntityOperation.DELETE, rowSifarnikProizvoda.id)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_SIFARNIK_PROIZVODA}
          tooltip={Labels.SIFARNIK_PROIZVODA_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openSifarnikProizvoda(EntityOperation.CREATE);
          }}
          disabled={!pristup}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={sifarnikProizvodaSearchData?.searchString ?? ""}
            onChange={changeSifarnikProizvodaSearchData}
            onKeyPress={searchSifarnikProizvodaByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_SIFARNIK_PROIZVODA}</label>
          <Button tooltip={Labels.SEARCH_SIFARNIK_PROIZVODA} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={sifarnikProizvodaLoading} data={exportData} name={Labels.LABEL_SIFARNIK_PROIZVODA} dt={dt} />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.SIFARNIK_PROIZVODA_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_PROIZVODA_SIFRA, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {sifarnikProizvodaLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={sifarnikProizvodaList ?? []}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageSifarnikProizvoda(e.rows, e.first);
            }}
            exportFilename={Labels.LABEL_SIFARNIK_PROIZVODA}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.SIFARNIK_PROIZVODA_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.SIFARNIK_PROIZVODA_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
