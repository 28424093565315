import { InitialStatePartnerCenaType } from "./initialStatePartnerCena";
import actionsPartnerCena from "./actionsPartnerCena";

type OpenDialogType = {
  type: "open";
  data: any;
  cena: any;
};

type CloseDialogType = {
  type: "close";
};

type CenaChange = {
  type: "cena_change";
  data: {
    value: any;
    fieldName: any;
  };
};

type PartnerCenaActionType = OpenDialogType | CloseDialogType | CenaChange;

export default function reducerPartnerCena(state: InitialStatePartnerCenaType, action: PartnerCenaActionType) {
  switch (action.type) {
    case actionsPartnerCena.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
        operation: action.data,
        cena: action.cena,
      };
    }
    case actionsPartnerCena.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        cena: undefined,
      };
    }
    case actionsPartnerCena.CENA_CHANGE: {
      return {
        ...state,
        cena: { ...state.cena, [action.data.fieldName]: action.data.value },
      };
    }
    default:
      return state;
  }
}
