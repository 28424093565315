import actions from "./actions";
import { InitialStateBrisanjeUstanoveType } from "./initialStateBrisanjeUstanove";

export default function reducerBrisanjeUstanova(state: InitialStateBrisanjeUstanoveType, action: { type: actions }) {
  switch (action.type) {
    case actions.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
        isCheckedBrisanje: false,
        page: 0,
      };
    }
    case actions.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        isCheckedBrisanje: false,
        page: 0,
      };
    }
    case actions.SWITCH_PAGE: {
      return {
        ...state,
        page: state.page + 1,
      };
    }
    case actions.ENABLE_DELETE: {
      return {
        ...state,
        isCheckedBrisanje: !state.isCheckedBrisanje,
      };
    }
    case actions.START_DELETE: {
      return {
        ...state,
        brisanjeZapoceto: true,
      };
    }
    default:
      return state;
  }
}
