import { ProgressSpinner } from "primereact/progressspinner";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import Content from "./Content";
import PublicContent from "./PublicContent";
import { AppContext } from "./Store";
import AppSkeleton from "./components/app/skeleton/AppSkeleton";
import { isPublicRoute } from "./infrastructure/system/Utils";

export default function App() {
  const { authData } = useContext(AppContext);
  const location = useLocation();

  if (isPublicRoute(location.pathname)) {
    return <PublicContent />;
  }
  if (!authData?.token) {
    return (
      <AppSkeleton>
        <div className="block-ui">
          <ProgressSpinner />
        </div>
      </AppSkeleton>
    );
  }

  return <Content />;
}
