import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import { useLabels } from "../../../Store";
import { tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import SifarnikMkbListLogical from "./SifarnikMkbListLogical";

export default function SifarnikMkbListView() {
  const {
    breadCrumbItems,
    importSifarnikMkb,
    fetchData,
    sifarnikMkbSearchData,
    searchSifarnikMkbByEnter,
    changeSifarnikMkbSearchData,
    sifarnikMkbLoading,
    exportData,
    sifarnikMkbList,
    first,
    tableRows,
    onPageSifarnikMkb,
  } = SifarnikMkbListLogical();

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_IMPORT_SIFARNIK_MKB}
          tooltip={Labels.TOOLTIP_IMPORT_SIFARNIK_MKB}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            importSifarnikMkb();
          }}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={sifarnikMkbSearchData?.searchString ?? ""}
            onChange={changeSifarnikMkbSearchData}
            onKeyPress={searchSifarnikMkbByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_SIFARNIK_MKB}</label>
          <Button tooltip={Labels.SEARCH_SIFARNIK_MKB} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={sifarnikMkbLoading} data={exportData} name={Labels.LABEL_SIFARNIK_USLUGA} dt={dt} />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.SIFARNIK_MKB_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_MKB_SIFRA, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {sifarnikMkbLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={sifarnikMkbList ?? []}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageSifarnikMkb(e.rows, e.first);
            }}
            exportFilename={Labels.LABEL_SIFARNIK_USLUGA}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.SIFARNIK_MKB_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.SIFARNIK_MKB_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
