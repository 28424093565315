import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import { useNavigate } from "react-router";
import { useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import RepositoryLogical from "../../../infrastructure/system/RepositoryLogical";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import SifarnikAtributaRfzoReadDto from "../../../model/sifarnik/sifarnik-atributa-rfzo/SifarnikAtributaRfzoReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import SifarnikAtributaRfzoListLogical from "./SifarnikAtributaRfzoListLogical";

export default function SifarnikAtributaRfzoListView() {
  const {
    breadCrumbItems,
    fetchData,
    sifarnikAtributaRfzoSearchData,
    searchSifarnikAtributaRfzoByEnter,
    changeSifarnikAtributaRfzoSearchData,
    sifarnikAtributaRfzoLoading,
    exportData,
    sifarnikAtributaRfzoList,
    first,
    tableRows,
    onPageSifarnikAtributaRfzo,
  } = SifarnikAtributaRfzoListLogical();

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const { statusBodyTemplate, statusRowFilterTemplate } = RepositoryLogical();

  const openSifarnikAtributa = (entityOperation: string, id?: number) => {
    const sifarnikAtributaId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/repository-crud-sifarnik-atributa-rfzo/${sifarnikAtributaId}`, {
      state: { sifarnikAtributaOperation: entityOperation },
    });
  };

  const crudBodyTemplate = (rowSifarnikAtributa: SifarnikAtributaRfzoReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowSifarnikAtributa && openSifarnikAtributa(EntityOperation.UPDATE, rowSifarnikAtributa.id)}
        />
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowSifarnikAtributa && openSifarnikAtributa(EntityOperation.DELETE, rowSifarnikAtributa.id)}
        />
      </div>
    );
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_CREATE}
          tooltip={Labels.SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openSifarnikAtributa(EntityOperation.CREATE);
          }}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={sifarnikAtributaRfzoSearchData?.searchString ?? ""}
            onChange={changeSifarnikAtributaRfzoSearchData}
            onKeyPress={searchSifarnikAtributaRfzoByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_SIFARNIK_ATRIBUTA_RFZO}</label>
          <Button tooltip={Labels.SEARCH_SIFARNIK_ATRIBUTA_RFZO} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={sifarnikAtributaRfzoLoading} data={exportData} name={Labels.LABEL_SIFARNIK_ATRIBUTA_RFZO} dt={dt} />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.SIFARNIK_ATRIBUTA_RFZO_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_ATRIBUTA_RFZO_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_ATRIBUTA_RFZO_STATUS, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {sifarnikAtributaRfzoLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={sifarnikAtributaRfzoList ?? []}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageSifarnikAtributaRfzo(e.rows, e.first);
            }}
            exportFilename={Labels.LABEL_SIFARNIK_USLUGA}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.SIFARNIK_ATRIBUTA_RFZO_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.SIFARNIK_ATRIBUTA_RFZO_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column
              field={"entityStatus.sifra"}
              header={Labels.SIFARNIK_ATRIBUTA_RFZO_STATUS}
              body={statusBodyTemplate}
              className="w-3"
              filter
              filterElement={statusRowFilterTemplate}
              showFilterMenu={false}
              sortable
            />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
