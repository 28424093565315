import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { FormularController } from "../../../controllers/formular/FormularController";
import { TipKontaktaController } from "../../../controllers/kontakt/TipKontaktaController";
import { SuperadminKontaktTipController } from "../../../controllers/superadmin/SuperadminKontaktTipController";
import { RepositoryFormularController } from "../../../controllers/superadmin/repository/formular/RepositoryFormularController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import useEffectWithoutWarnings from "../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import FormularReadDto from "../../../model/formular/FormularReadDto";
import FormularSimpleReadDto from "../../../model/formular/FormularSimpleReadDto";
import TipKontaktaReadDto from "../../../model/kontakt/TipKontaktaReadDto";
import { AppContext, useLabels } from "./../../../Store";

interface FormularListLogicalType {
  formularList: Array<FormularSimpleReadDto>;
  first: number;
  tableRows: number;
  firstRepository: number;
  tableRepositoryRows: number;
  changeFormularSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  formularSearchData: SearchBaseDto;
  fetchData: () => void;
  searchFormularByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  onPageFormular: (rows: number, first: number) => void;
  formularLoading: boolean;
  closeDialog: () => void;
  openDialog: () => void;
  isDialogOpen: boolean;
  selectedColumns: Array<FormularReadDto>;
  setSelectedColumns: React.Dispatch<React.SetStateAction<Array<FormularReadDto>>>;
  repositoryFormularLoading: boolean;
  repositoryFormularList?: Array<FormularReadDto>;
  onPageRepositoryFormular: (rows: number, first: number) => void;
  importFormular: (tipId?: number) => void;
  tipKontaktaOptions: Array<TipKontaktaReadDto>;
  tipKontaktaLoading: boolean;
  selectedTipoviKontakta: Array<TipKontaktaReadDto>;
  setSelectedTipoviKontakta: React.Dispatch<React.SetStateAction<Array<TipKontaktaReadDto>>>;
}

export default function FormularListLogical(tipKontaktaOperation: string | undefined, ustanovaId?: number, kontaktTipId?: number, shouldRefetchFormulars?: boolean): FormularListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [formularList, setFormularList] = useState<Array<FormularSimpleReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [formularSearchData, setFormularSearchData] = useState<SearchBaseDto>({});
  const { axiosSearchFormular, axiosSearchFormularByKontaktTip } = FormularController();
  const { axiosSearchTipKontakta } = TipKontaktaController();
  const [formularLoading, setFormularLoading] = useState(false);
  const [repositoryFormularList, setRepositoryFormularList] = useState<Array<FormularReadDto>>([]);
  const [repositoryFormularLoading, setRepositoryFormularLoading] = useState<boolean>(true);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<Array<FormularReadDto>>([]);
  const [firstRepository, setFirstRepository] = useState(0);
  const [tableRepositoryRows, setTableRepositoryRows] = useState(10);
  const [tipKontaktaOptions, setTipKontaktaOptions] = useState<Array<TipKontaktaReadDto>>([]);
  const [tipKontaktaLoading, setTipKontaktaLoading] = useState<boolean>(true);
  const [selectedTipoviKontakta, setSelectedTipoviKontakta] = useState<Array<TipKontaktaReadDto>>([]);
  const { axiosPublicSearchRepositoryFormular, axiosPublicCopyRepositoryFormular } = RepositoryFormularController();
  const { axiosSuperadminSearchFormular, axiosSuperadminPublicCopyRepositoryFormular } = SuperadminKontaktTipController();
  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.FORMULAR_LIST,
    },
  ];

  useEffectWithoutWarnings(() => {
    if (shouldRefetchFormulars) fetchData();
  }, [shouldRefetchFormulars]);

  useEffectOnce(() => {
    fetchData();
    if (!tipKontaktaOperation) {
      let startTime = moment(new Date());
      axiosSearchTipKontakta()
        .then((response: any) => {
          setTipKontaktaOptions(response.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setTipKontaktaLoading, startTime);
        });
    }
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setFormularLoading(true);

    let formularSearch;
    if (ustanovaId && kontaktTipId) formularSearch = axiosSuperadminSearchFormular(ustanovaId, kontaktTipId, formularSearchData);
    else {
      if (tipKontaktaOperation && kontaktTipId) {
        formularSearch = axiosSearchFormularByKontaktTip(kontaktTipId, formularSearchData);
      } else if (!kontaktTipId && tipKontaktaOperation) {
        return;
      } else formularSearch = axiosSearchFormular(formularSearchData);
    }

    formularSearch
      .then((res: any) => {
        setFormularList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((formular: FormularReadDto) => {
          exportList.push({
            [Labels.FORMULAR_NAZIV]: formular.naziv,
            [Labels.LABEL_TIP_KONTAKTA]: formular.kontaktTip?.naziv,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setFormularLoading, startTime);
      });
  };

  const changeFormularSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setFormularSearchData({
      ...formularSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };
  const importFormular = (tipId?: number) => {
    let formularIDList: Array<number> = selectedColumns.map((formular: FormularReadDto) => formular.id);
    let kontaktTipIDList: Array<number> = tipId ? [tipId] : selectedTipoviKontakta.map((tipKontakta: TipKontaktaReadDto) => tipKontakta.id);
    (ustanovaId && kontaktTipId
      ? axiosSuperadminPublicCopyRepositoryFormular(ustanovaId, kontaktTipId, { formularIDList: formularIDList, kontaktTipIDList: kontaktTipIDList })
      : axiosPublicCopyRepositoryFormular({ formularIDList: formularIDList, kontaktTipIDList: kontaktTipIDList })
    )
      .then(() => {
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        closeDialog();
      });
  };
  const searchFormularByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageFormular = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedColumns([]);
    setSelectedTipoviKontakta([]);
  };
  const openDialog = () => {
    setIsDialogOpen(true);
    setRepositoryFormularLoading(true);
    const startTime = moment(new Date());
    axiosPublicSearchRepositoryFormular({})
      .then((res: { data: { data: Array<FormularReadDto> } }) => {
        setRepositoryFormularList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setRepositoryFormularLoading, startTime);
      });
  };
  const onPageRepositoryFormular = (rows: number, first: number) => {
    setTableRepositoryRows(rows);
    setFirstRepository(first);
  };
  return {
    formularList,
    first,
    tableRows,
    changeFormularSearchData,
    formularSearchData,
    fetchData,
    searchFormularByEnter,
    exportData,
    breadCrumbItems,
    onPageFormular,
    formularLoading,
    openDialog,
    closeDialog,
    firstRepository,
    isDialogOpen,
    repositoryFormularLoading,
    selectedColumns,
    setSelectedColumns,
    repositoryFormularList,
    tableRepositoryRows,
    onPageRepositoryFormular,
    importFormular,
    tipKontaktaOptions,
    tipKontaktaLoading,
    selectedTipoviKontakta,
    setSelectedTipoviKontakta,
  };
}
