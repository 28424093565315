import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import PaketCreateDto from "../../model/paket/PaketCreateDto";
import PaketOrganizacionaJedinicaCreateDto from "../../model/paket/PaketOrganizacionaJedinicaCreateDto";
import PaketProizvodCreateDto from "../../model/paket/PaketProizvodCreateDto";
import PaketProizvodUpdateDto from "../../model/paket/PaketProizvodUpdateDto";
import PaketReadDto from "../../model/paket/PaketReadDto";
import PaketUpdateDto from "../../model/paket/PaketUpdateDto";
import PaketUslugaCreateDto from "../../model/paket/PaketUslugaCreateDto";
import PaketUslugaUpdateDto from "../../model/paket/PaketUslugaUpdateDto";

interface PaketControllerType {
  axiosReadPaket: (id: number) => Promise<AxiosResponse<any>>;
  axiosSearchPaketList: (searchObject: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosUpdatePaket: (paket: PaketReadDto) => Promise<AxiosResponse<any>>;
  axiosCreatePaket: (paket: PaketReadDto) => Promise<AxiosResponse<any>>;
  axiosDeletePaket: (paketId: number) => Promise<AxiosResponse<any>>;
  axiosGetPaketList: () => Promise<AxiosResponse<any>>;
  axiosGetUslugeListByPaketId: (paketId: number) => Promise<AxiosResponse<any>>;
  axiosCreateUslugaForPaket: (paketId: number, usluga: PaketUslugaCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteUslugaFromPaket: (paketId: number, uslugaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateUslugaFromPaket: (paketId: number, usluga: PaketUslugaUpdateDto, uslugaId: number) => Promise<AxiosResponse<any>>;
  axiosGetProizvodiListByPaketId: (paketId: number) => Promise<AxiosResponse<any>>;
  axiosCreateProizvodForPaket: (paketId: number, proizvod: PaketProizvodCreateDto) => Promise<AxiosResponse<any>>;
  axiosUpdateProizvodFromPaket: (paketId: number, proizvod: PaketProizvodUpdateDto, proizvodId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteProizvodFromPaket: (paketId: number, paketProizvodId: number) => Promise<AxiosResponse<any>>;
  axiosGetOrgJedListByPaketId: (paketId: number) => Promise<AxiosResponse<any>>;
  axiosAddOrganizacionaJedinicaToPaket: (paketId: number, organizacionaJedinica: PaketOrganizacionaJedinicaCreateDto) => Promise<AxiosResponse<any>>;
  axiosRemoveOrganizacionaJedinicaFromPaket: (paketId: number, paketOrganizacionaJedinicaId: number) => Promise<AxiosResponse<any>>;
}

export function PaketController(): PaketControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetPaketList = () => {
    return axios.get(Endpoint.PAKET_LIST, axiosConfig(authData!.token));
  };

  const axiosReadPaket = (paketId: number) => {
    return axios.get(`${Endpoint.PAKET_LIST}/${paketId}`, axiosConfig(authData!.token));
  };

  const axiosSearchPaketList = (searchObject: SearchBaseDto) => {
    return axios.get(Endpoint.PAKET_LIST, axiosConfig(authData!.token, searchObject));
  };

  const axiosCreatePaket = (paket: PaketCreateDto) => {
    return axios.post(Endpoint.PAKET_LIST, paket ? paket : {}, axiosConfig(authData!.token));
  };

  const axiosUpdatePaket = (paket: PaketUpdateDto) => {
    return axios.put(`${Endpoint.PAKET_LIST}/${paket?.id}`, paket, axiosConfig(authData!.token));
  };

  const axiosDeletePaket = (paketId: number) => {
    return axios.delete(`${Endpoint.PAKET_LIST}/${paketId}`, axiosConfig(authData!.token));
  };

  const axiosGetUslugeListByPaketId = (paketId: number) => {
    return axios.get(`${Endpoint.PAKET_LIST}/${paketId}/paket-usluga-list`, axiosConfig(authData!.token));
  };

  const axiosCreateUslugaForPaket = (paketId: number, usluga: PaketUslugaCreateDto) => {
    return axios.post(`${Endpoint.PAKET_LIST}/${paketId}/paket-usluga-list`, usluga ? usluga : {}, axiosConfig(authData!.token));
  };

  const axiosDeleteUslugaFromPaket = (paketId: number, paketUslugaId: number) => {
    return axios.delete(`${Endpoint.PAKET_LIST}/${paketId}/paket-usluga-list/${paketUslugaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateUslugaFromPaket = (paketId: number, usluga: PaketUslugaUpdateDto, paketUslugaId: number) => {
    return axios.put(`${Endpoint.PAKET_LIST}/${paketId}/paket-usluga-list/${paketUslugaId}`, usluga, axiosConfig(authData!.token));
  };

  const axiosGetProizvodiListByPaketId = (paketId: number) => {
    return axios.get(`${Endpoint.PAKET_LIST}/${paketId}/paket-proizvod-list`, axiosConfig(authData!.token));
  };

  const axiosCreateProizvodForPaket = (paketId: number, proizvod: PaketProizvodCreateDto) => {
    return axios.post(`${Endpoint.PAKET_LIST}/${paketId}/paket-proizvod-list`, proizvod ? proizvod : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateProizvodFromPaket = (paketId: number, proizvod: PaketProizvodUpdateDto, proizvodId: number) => {
    return axios.put(`${Endpoint.PAKET_LIST}/${paketId}/paket-proizvod-list/${proizvodId}`, proizvod, axiosConfig(authData!.token));
  };

  const axiosDeleteProizvodFromPaket = (paketId: number, paketProizvodId: number) => {
    return axios.delete(`${Endpoint.PAKET_LIST}/${paketId}/paket-proizvod-list/${paketProizvodId}`, axiosConfig(authData!.token));
  };

  const axiosGetOrgJedListByPaketId = (paketId: number) => {
    return axios.get(`${Endpoint.PAKET_LIST}/${paketId}/paket-organizaciona-jedinica-list`, axiosConfig(authData!.token));
  };

  const axiosAddOrganizacionaJedinicaToPaket = (paketId: number, organizacionaJedinica: PaketOrganizacionaJedinicaCreateDto) => {
    return axios.post(`${Endpoint.PAKET_LIST}/${paketId}/paket-organizaciona-jedinica-list`, organizacionaJedinica ? organizacionaJedinica : {}, axiosConfig(authData!.token));
  };

  const axiosRemoveOrganizacionaJedinicaFromPaket = (paketId: number, paketOrganizacionaJedinicaId: number) => {
    return axios.delete(`${Endpoint.PAKET_LIST}/${paketId}/paket-organizaciona-jedinica-list/${paketOrganizacionaJedinicaId}`, axiosConfig(authData!.token));
  };
  return {
    axiosReadPaket,
    axiosSearchPaketList,
    axiosCreatePaket,
    axiosUpdatePaket,
    axiosDeletePaket,
    axiosGetPaketList,
    axiosGetUslugeListByPaketId,
    axiosCreateUslugaForPaket,
    axiosDeleteUslugaFromPaket,
    axiosUpdateUslugaFromPaket,
    axiosGetProizvodiListByPaketId,
    axiosCreateProizvodForPaket,
    axiosUpdateProizvodFromPaket,
    axiosDeleteProizvodFromPaket,
    axiosGetOrgJedListByPaketId,
    axiosAddOrganizacionaJedinicaToPaket,
    axiosRemoveOrganizacionaJedinicaFromPaket,
  };
}
