import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TipKontaktaController } from "../../../controllers/kontakt/TipKontaktaController";

import { SifarnikController } from "../../../controllers/sifarnik/SifarnikController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import TipKontaktaReadDto from "../../../model/kontakt/TipKontaktaReadDto";

import axios from "axios";
import { EnumController } from "../../../controllers/enum/EnumController";
import { FormularController } from "../../../controllers/formular/FormularController";
import { SuperadminKontaktTipController } from "../../../controllers/superadmin/SuperadminKontaktTipController";
import FormularSimpleReadDto from "../../../model/formular/FormularSimpleReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SifarnikKategorijaKontaktaDto from "../../../model/sifarnik/SifarnikKategorijaKontaktaDto";
import { AppContext, useLabels } from "../../../Store";

interface TipKontaktaCrudLogicalType {
  tipKontaktaOperation: string;
  isDisabled: boolean;
  sifKategorijaKontakta: Array<SifarnikKategorijaKontaktaDto> | undefined;
  sifarnikVremeTrajanjaKontakta: Array<EnumBaseReadDto> | undefined;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  tipKontaktaChange: TipKontaktaReadDto | undefined;
  setTipKontaktaChange: any;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  tipKontaktaId: number | undefined;
  location: any;
  tipKontaktaLoading: boolean;
  kategorijaKontaktaLoading: boolean;
  ustanovaId?: number;
  ustanovaNaziv?: string;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  formularList: Array<FormularSimpleReadDto>;
  shouldRefetchFormulars: boolean;
}

interface UseParamsType {
  tipKontaktaId?: string;
  ustanovaId?: string;
}

export default function CrudTipKontaktaLogical(): TipKontaktaCrudLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const navigate = useNavigate();
  const location = useLocation();
  const tipKontaktaId = Number(useParams<keyof UseParamsType>()["tipKontaktaId"]);
  const [tipKontaktaOperation, setTipKontaktaOperation] = useState(location.state && location.state.tipKontaktaOperation !== undefined ? location.state.tipKontaktaOperation : EntityOperation.UPDATE);
  const [tipKontaktaChange, setTipKontaktaChange] = useState<TipKontaktaReadDto | undefined>(location.state && location.state.tipKontakta !== undefined ? location.state.tipKontakta : undefined);
  const [ustanovaNaziv] = useState<string>(location.state && location.state.ustanovaNaziv !== undefined ? location.state.ustanovaNaziv : "");
  const [tabIndex] = useState(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [isDisabled] = useState<boolean>(isFormDisabled(tipKontaktaOperation));
  const [index, setIndex] = useState(tabIndex);
  const [sifKategorijaKontakta, setSifKategorijaKontakta] = useState<SifarnikKategorijaKontaktaDto[]>();
  const [sifarnikVremeTrajanjaKontakta, setSifarnikVremeTrajanjaKontakta] = useState<Array<EnumBaseReadDto>>([]);
  const [tipKontaktaLoading, setTipKontaktaLoading] = useState(true);
  const [kategorijaKontaktaLoading, setKategorijaKontaktaLoading] = useState(true);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const [formularList, setFormularList] = useState<Array<FormularSimpleReadDto>>([]);
  const [shouldRefetchFormulars, setShouldRefetchFormulars] = useState<boolean>(false);
  const { axiosCreateTipKontakta, axiosUpdateTipKontakta, axiosDeleteTipKontakta, axiosGetTipKontakta } = TipKontaktaController();
  const { axiosSuperadminCreateKontaktTip, axiosSuperadminUpdateKontaktTip, axiosSuperadminDeleteKontaktTip, axiosSuperadminGetKontaktTipList, axiosSuperadminSearchFormular } =
    SuperadminKontaktTipController();

  const { axiosGetSifarnikKategorijaKontaktaList } = SifarnikController();
  const { axiosGetVremeTrajanjaKontakta } = EnumController();
  const { axiosSearchFormularByKontaktTip } = FormularController();
  const postLogHighLevel = useLogHighLevel();

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>(
    ustanovaId
      ? [
          {
            label: Labels.USTANOVA_LIST,
            command: () => {
              navigate(`/crud-ustanova`);
            },
          },
          {
            label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + " " + ustanovaNaziv,
            command: () => {
              navigate(`/crud-ustanova/${ustanovaId}`, {
                state: {
                  tabIndex: 6,
                },
              });
            },
          },
        ]
      : [
          {
            label: Labels.TIP_KONTAKTA_LIST,
            command: () => {
              navigate("/tip-kontakta-list/");
            },
          },
        ]
  );
  useEffectOnce(() => {
    getVremeTrajanjaAndSifarnikKategorijaListAndFormularList();
    fetchData();
  });

  const getVremeTrajanjaAndSifarnikKategorijaListAndFormularList = () => {
    let startTime = moment(new Date());
    const requestSifarnikKategorijaKontaktaList = axiosGetSifarnikKategorijaKontaktaList();
    const requestVremeTrajanjaList = axiosGetVremeTrajanjaKontakta();
    const requestFormularList = tipKontaktaId ? (ustanovaId ? axiosSuperadminSearchFormular(ustanovaId, tipKontaktaId, { searchString: "" }) : axiosSearchFormularByKontaktTip(tipKontaktaId)) : null;

    axios
      .all([requestSifarnikKategorijaKontaktaList, requestVremeTrajanjaList, tipKontaktaId ? requestFormularList : null])
      .then(
        axios.spread((responseSifarnikKategorijaKontaktaList, responseVremeTrajanjaList, responseFormularList) => {
          setSifKategorijaKontakta(responseSifarnikKategorijaKontaktaList?.data.data);
          setSifarnikVremeTrajanjaKontakta(responseVremeTrajanjaList?.data.data);
          setFormularList(responseFormularList?.data.data);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setKategorijaKontaktaLoading, startTime);
      });
  };

  const fetchData = (tkId?: number | undefined, newOperation?: string | undefined) => {
    let newId = tkId ? tkId : tipKontaktaId;
    let operation = newOperation ? newOperation : tipKontaktaOperation;
    if (newId) {
      const requestTipKontakta = ustanovaId ? axiosSuperadminGetKontaktTipList(ustanovaId, Number(newId)) : axiosGetTipKontakta(Number(newId));
      let startTime = moment(new Date());
      requestTipKontakta
        .then((responseTipKontakta: any) => {
          setTipKontaktaChange(responseTipKontakta.data.data);
          let testBreadCrumbItems = breadCrumbItems;
          if (testBreadCrumbItems.length >= (ustanovaId ? 3 : 2)) testBreadCrumbItems.pop();
          setBreadCrumbItems([
            ...testBreadCrumbItems,
            {
              label:
                operation === EntityOperation.UPDATE
                  ? Labels.TIP_KONTAKTA_TITLE_DIALOG_UPDATE + responseTipKontakta.data.data.naziv
                  : operation === EntityOperation.READ
                  ? Labels.TIP_KONTAKTA_TITLE_DIALOG_DETAILS + responseTipKontakta.data.data.naziv
                  : operation === EntityOperation.CREATE
                  ? Labels.TIP_KONTAKTA_TITLE_DIALOG_CREATE
                  : Labels.TIP_KONTAKTA_TITLE_DIALOG_DELETE + responseTipKontakta.data.data.naziv,
            },
          ]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setTipKontaktaLoading, startTime);
        });
    } else {
      setTipKontaktaLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.TIP_KONTAKTA_TITLE_DIALOG_CREATE,
        },
      ]);
    }
  };

  const validateInput = (tipKontakta?: TipKontaktaReadDto) => {
    let isInvalid = false;
    if (validateStringEmpty(tipKontakta?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(tipKontakta?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (!tipKontakta?.sifarnikKategorijaKontakta?.id) {
      setInvalidFields((prev) => ({ ...prev, kategorija: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(tipKontaktaChange)) {
      return;
    }
    let newTipKontakta = tipKontaktaChange;
    if (newTipKontakta && !newTipKontakta?.hasOwnProperty("automatskoZavrsavanje")) {
      newTipKontakta = { ...newTipKontakta, automatskoZavrsavanje: false };
    }

    if (newTipKontakta) {
      setShowBlockUI(true);
      (ustanovaId ? axiosSuperadminCreateKontaktTip(ustanovaId, newTipKontakta) : axiosCreateTipKontakta(newTipKontakta))
        .then((res: any) => {
          setIndex(0);
          showMessage(MessageType.SUCCESS, Labels.TIP_KONTAKTA_TITLE_MESSAGE_CREATE_TIP_KONTAKTA_SUCCESS);
          setTipKontaktaOperation(EntityOperation.UPDATE);
          ustanovaId ? navigate(`/crud-ustanova/${ustanovaId}/crud-tip-kontakta/${res?.data?.data?.id}`) : navigate(`/crud-tip-kontakta/${res?.data?.data?.id}`);
          fetchData(res.data.data.id, EntityOperation.UPDATE);
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_1 +
              newTipKontakta?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_2 +
              tipKontaktaChange?.sifarnikKategorijaKontakta?.naziv +
              (newTipKontakta?.automatskoZavrsavanje ? Labels.LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_3 : Labels.LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_4)
          );
          setShouldRefetchFormulars(true);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(tipKontaktaChange)) {
      return;
    }
    if (tipKontaktaChange) {
      setShowBlockUI(true);
      (ustanovaId ? axiosSuperadminUpdateKontaktTip(ustanovaId, tipKontaktaChange) : axiosUpdateTipKontakta(tipKontaktaChange))
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TIP_KONTAKTA_TITLE_MESSAGE_UPDATE_TIP_KONTAKTA_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_1 +
              tipKontaktaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_2 +
              tipKontaktaChange?.sifarnikKategorijaKontakta?.naziv +
              (tipKontaktaChange?.automatskoZavrsavanje ? Labels.LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_3 : Labels.LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_4)
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    if (tipKontaktaChange) {
      setShowBlockUI(true);
      (ustanovaId ? axiosSuperadminDeleteKontaktTip(ustanovaId, tipKontaktaChange) : axiosDeleteTipKontakta(tipKontaktaChange))
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TIP_KONTAKTA_TITLE_MESSAGE_DELETE_TIP_KONTAKTA_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_1 +
              tipKontaktaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_2 +
              tipKontaktaChange?.sifarnikKategorijaKontakta?.naziv +
              (tipKontaktaChange?.automatskoZavrsavanje ? Labels.LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_3 : Labels.LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_4)
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/tip-kontakta-list`);
    }
  };

  return {
    tipKontaktaOperation,
    isDisabled,
    sifKategorijaKontakta,
    sifarnikVremeTrajanjaKontakta,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    tipKontaktaChange,
    setTipKontaktaChange,
    breadCrumbItems,
    index,
    setIndex,
    tipKontaktaId,
    location,
    kategorijaKontaktaLoading,
    tipKontaktaLoading,
    ustanovaId,
    ustanovaNaziv,
    invalidFields,
    setInvalidFields,
    formularList,
    shouldRefetchFormulars,
  };
}
