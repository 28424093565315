import { useQuery } from "@tanstack/react-query";
import { VerzijaController } from "../../../../controllers/verzija/VerzijaController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";

export function useVerzijaPublicList() {
  const { axiosGetPublicVerzijaList } = VerzijaController();

  return useQuery({
    queryKey: [QueryKeys.VERZIJA_LIST],
    queryFn: () => axiosGetPublicVerzijaList().then((res) => res.data),
  });
}
