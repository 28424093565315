import { PickList } from "primereact/picklist";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import SkeletonPickListItem from "../../../app/skeleton/SkeletonPickListItem";
import CrudOrganizacionaJedinicaRadnikLogical, { OrganizacionaJedinicaRadnikHolder } from "./CrudOrganizacionaJedinicaRadnikLogical";

interface CrudOrganizacionaJedinicaRadnikProps {
  isDisabled: boolean;
  orgJedOperation: string;
  orgJedNaziv?: string;
}
export default function CrudOrganizacionaJedinicaRadnik(props: CrudOrganizacionaJedinicaRadnikProps) {
  const { isDisabled, orgJedOperation, orgJedNaziv } = props;
  const { pristup } = useContext(AppContext);

  const { organizacionaJedinicaRadnikList, radnikList, isBlocked, isLoading, sourceSelection, setSourceSelection, targetSelection, setTargetSelection, onMoveToTarget, onMoveToSource } =
    CrudOrganizacionaJedinicaRadnikLogical(orgJedOperation, orgJedNaziv);
  const Labels = useLabels();

  const itemTemplate = (item: OrganizacionaJedinicaRadnikHolder | undefined) => {
    if (item === undefined || item === null || isBlocked) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{`${item.radnik?.ime} ${item.radnik?.prezime} - ${item.radnik?.username}`}</h6>;
  };

  return (
    <div className={"layout-generic-content-list"}>
      <div className={!pristup || isDisabled || isBlocked || isLoading ? "read-only" : ""}>
        <PickList
          sourceHeader={Labels.RADNIK_LIST}
          targetHeader={Labels.LABEL_ORGANIZACIONA_JEDINICA_RADNIK_LIST}
          source={radnikList}
          target={organizacionaJedinicaRadnikList}
          sourceItemTemplate={itemTemplate}
          targetItemTemplate={itemTemplate}
          showSourceControls={false}
          showTargetControls={false}
          sourceSelection={sourceSelection}
          targetSelection={targetSelection}
          onSourceSelectionChange={(e) => setSourceSelection(e.value)}
          onTargetSelectionChange={(e) => setTargetSelection(e.value)}
          onMoveToSource={() => onMoveToSource(targetSelection)}
          onMoveToTarget={() => onMoveToTarget(sourceSelection)}
          onMoveAllToSource={(e) => onMoveToSource(e.value)}
          onMoveAllToTarget={(e) => onMoveToTarget(e.value)}
          metaKeySelection={false}
        />
      </div>
    </div>
  );
}
