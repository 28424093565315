import actions from "./actionsBrisanjeIzvestaja";
import { InitialStateBrisanjeIzvestajaType } from "./initialStateBrisanjeIzvestaja";

export default function reducerBrisanjeIzvestaja(state: InitialStateBrisanjeIzvestajaType, action: { type: actions; data?: any; flag: string; from: string }) {
  switch (action.type) {
    case actions.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
        izvestaj: action.data,
        flag: action.flag,
        from: action.from,
      };
    }
    case actions.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        izvestaj: action.data,
        flag: action.flag,
        loading: true,
        from: "",
      };
    }
    case actions.SET_LOADED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
