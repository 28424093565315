import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { setInvalid } from "../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CrudPartnerLogical from "./CrudPartnerLogical";
import PartnerCeneListView from "./cene/PartnerCeneListView";

export default function CrudPartnerView() {
  const {
    setIndex,
    index,
    breadCrumbItems,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    isDisabled,
    partnerOperation,
    partnerLoading,
    partnerChange,
    setPartnerChange,
    popustiList,
    invalidFields,
    setInvalidFields,
    partnerTipList,
  } = CrudPartnerLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const formButtons = () => {
    return (
      <>
        <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger p-button-outlined mr-2" onClick={onCancel} />
        {partnerOperation === EntityOperation.CREATE && <Button label={Labels.LABEL_DODAJ_PARTNER} disabled={!pristup} tooltip={Labels.LABEL_PARTNER_CREATE} icon="pi pi-plus" onClick={onCreate} />}
        {partnerOperation === EntityOperation.UPDATE && (
          <Button label={Labels.BUTTON_UPDATE} tooltip={Labels.LABEL_PARTNER_UPDATE} disabled={!pristup || partnerLoading} icon="pi pi-pencil" className="p-button-warning" onClick={onUpdate} />
        )}
        {partnerOperation === EntityOperation.DELETE && (
          <Button label={Labels.BUTTON_DELETE} tooltip={Labels.LABEL_PARTNER_DELETE} disabled={!pristup || partnerLoading} icon="pi pi-trash" className="p-button-danger" onClick={onDelete} />
        )}
      </>
    );
  };

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_PARTNER_PODACI}>
            <div className="flex justify-content-center pt-5">
              <div className="grid col-8 align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  {Labels.LABEL_NAZIV}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={partnerChange?.naziv ?? ""}
                        onChange={(e) => {
                          setPartnerChange({
                            ...partnerChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", partnerChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_ADRESA_PARTNER}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={partnerChange?.adresa ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          adresa: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_POSTANSKI_BROJ_PARTNER}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={partnerChange?.postanskiBroj ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          postanskiBroj: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  {Labels.LABEL_SIFRA}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={partnerChange?.sifra ?? ""}
                        onChange={(e) => {
                          setPartnerChange({
                            ...partnerChange!,
                            sifra: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", partnerChange?.sifra);
                        }}
                        className={invalidFields?.sifra && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_SEDISTE_PARTNER}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={partnerChange?.sediste ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          sediste: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_ZIRO_RACUN_PARTNER}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={partnerChange?.ziroRacun ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          ziroRacun: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_PIB_PARTNER}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={partnerChange?.pib ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          pib: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_MATICNI_BROJ_PARTNER}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={partnerChange?.maticniBroj ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          maticniBroj: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_TELEFON_PARTNER}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={partnerChange?.telefon ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          telefon: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_EMAIL_PARTNER}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={partnerChange?.email ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          email: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_POPUST_PARTNER}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <Dropdown
                      options={popustiList}
                      disabled={!pristup || isDisabled}
                      showClear
                      optionLabel="sifra"
                      optionValue="id"
                      value={partnerChange?.kategorijaPopusta?.id ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          kategorijaPopusta: e.target.value && { id: e.target.value },
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_PARTNERI_TIP_PARTNERA}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <Dropdown
                      options={partnerTipList}
                      disabled={!pristup || isDisabled}
                      optionLabel="naziv"
                      value={partnerChange?.partnerTip ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          partnerTip: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_NAPOMENA_PARTNER}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!partnerLoading ? (
                    <InputTextarea
                      rows={3}
                      cols={5}
                      disabled={!pristup || isDisabled}
                      value={partnerChange?.napomena ?? ""}
                      onChange={(e) => {
                        setPartnerChange({
                          ...partnerChange!,
                          napomena: e.target.value,
                        });
                      }}
                      className="mt-2"
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 flex flex-row justify-content-end px-0 mt-4">{formButtons()}</div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header={Labels.LABEL_PARTNER_CENE} disabled={partnerOperation === EntityOperation.CREATE}>
            <PartnerCeneListView popustiList={popustiList} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
