import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useContext } from "react";
import { AppContext, useLabels } from "../../Store";
import KontrolaPravaPristupaService from "../../infrastructure/system/KontrolaPravaPristupaService";
import { tooltipOptionsBottom } from "../../infrastructure/system/Utils";
import NesacuvanNalaz from "../../model/NesacuvanNalaz/NesacuvanNalaz";
import FormularPopunjenReadDto from "../../model/formular/FormularPopunjenReadDto";
import FormularReadDto from "../../model/formular/FormularReadDto";
import KontaktReadDto from "../../model/kontakt/KontaktReadDto";
import PoljePopunjenoSkracenoDto from "../../model/polje/PoljePopunjenoSkracenoReadDto";
import SkeletonPoljeVrednostInRow from "../app/skeleton/SkeletonPoljeVrednostInRow";
import RouteLeavingGuard from "../route-leaving-guard/RouteLeavingGuard";
import FormularLogical, { valueMap } from "./FormularLogical";
import Polje from "./polja/Polje";
import PrethodniNalaziListView from "./prethodni-nalazi/PrethodniNalaziListView";
import SablonListView from "./sablon/SablonListView";

interface FormularViewType {
  formularId: number;
  formular?: FormularReadDto;
  formularPopunjen?: FormularPopunjenReadDto;
  kontakt: KontaktReadDto;
  afterSave: () => void;
  maximizeScreen?: boolean;
  openClosePrethodniSabloniNalazi: (openCLose: boolean) => void;
  showPrethodniNalaziSabloni?: boolean;
  setClickOutsideDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  clickOutsideDisabled?: boolean;
  poljeVrednostListRef: React.MutableRefObject<any>;
  izabraniTipUnosaRef: React.MutableRefObject<any>;
  nesacuvanNalazState: NesacuvanNalaz | undefined;
  izabraniPopunjeniUnosRef: React.MutableRefObject<any>;
  setNesacuvanNalazState: React.Dispatch<React.SetStateAction<NesacuvanNalaz | undefined>>;
  setDisabledActionButtons: React.Dispatch<React.SetStateAction<boolean>>;
  stampaj: (tipUnosa: FormularReadDto, popunjeniUnos: FormularPopunjenReadDto, poljeVrednostList?: valueMap) => void;
  formatPoljePopunjenoList: (poljePopunjeno: PoljePopunjenoSkracenoDto[]) => valueMap;
}

export default function FormularView(props: FormularViewType) {
  const {
    formularId,
    formularPopunjen,
    afterSave,
    maximizeScreen,
    openClosePrethodniSabloniNalazi,
    showPrethodniNalaziSabloni,
    kontakt,
    setClickOutsideDisabled,
    clickOutsideDisabled,
    formular,
    poljeVrednostListRef,
    izabraniTipUnosaRef,
    nesacuvanNalazState,
    izabraniPopunjeniUnosRef,
    setNesacuvanNalazState,
    setDisabledActionButtons,
    stampaj,
    formatPoljePopunjenoList,
  } = props;

  const Labels = useLabels();
  const { pristup, authData } = useContext(AppContext);
  const { poljeList, onChange, save, poljeVrednostList, setPoljeVrednostList, activeIndex, selectedPoljeId, selectedPoljeNaziv, poljeListLoading, poljeVrednostListLoading, poljeTypeList } =
    FormularLogical({
      formularId,
      formularPopunjen,
      afterSave,
      openClosePrethodniSabloniNalazi,
      setClickOutsideDisabled,
      nesacuvanNalazState,
      setNesacuvanNalazState,
      kontakt,
      setDisabledActionButtons,
      formatPoljePopunjenoList,
    });

  const { hasFormularPopunjenUpdate, hasFormularPopunjenCreate } = KontrolaPravaPristupaService();

  poljeVrednostListRef.current = poljeVrednostList;

  const closePrethodniNalaziSablonHeaderTemplate = () => {
    return (
      <Button
        tooltipOptions={tooltipOptionsBottom}
        icon="pi pi-angle-double-right"
        className={`p-button-outlined pull-right border-none p-button-success button-hover-ignore mb-1 align-self-end p-2  mb-1 ${maximizeScreen ? "mr-2" : " mr-3"}`}
        tooltip={Labels.KONTAKT_PRETHODNI_NALAZI_SABLONI}
        onClick={() => {
          openClosePrethodniSabloniNalazi(showPrethodniNalaziSabloni ?? false);
        }}
      />
    );
  };

  return (
    <div className="layout-formular">
      <RouteLeavingGuard
        izabraniPopunjeniUnosRef={izabraniPopunjeniUnosRef}
        izabraniTipUnosaRef={izabraniTipUnosaRef}
        kontakt={kontakt}
        poljeVrednostListRef={poljeVrednostListRef}
        leaveDisabled={clickOutsideDisabled}
      />
      {showPrethodniNalaziSabloni && (
        <div className={`flex flex-column ${maximizeScreen ? "prethodni-nalazi-sabloni-container-maximized-screen" : "prethodni-nalazi-sabloni-container-minimized-screen"}`}>
          <TabView className="prethodni-nalazi-sabloni-view mt-2" activeIndex={activeIndex} renderActiveOnly>
            <TabPanel header={Labels.PRETHODNI_NALAZI}>
              <PrethodniNalaziListView
                changePoljaVrednosti={setPoljeVrednostList}
                formularId={formularPopunjen?.formular?.id ?? formularId}
                formularPopunjenId={formularPopunjen?.id}
                pacijentId={kontakt?.pacijent?.id}
                poljeList={poljeList}
              />
            </TabPanel>
            <TabPanel header={Labels.SABLON_LIST}>
              <SablonListView
                changePoljeVrednost={onChange}
                idPolje={selectedPoljeId}
                nazivPolje={selectedPoljeNaziv}
                vrednostPolje={selectedPoljeId && poljeVrednostList && poljeVrednostList[selectedPoljeId]}
              />
            </TabPanel>
            <TabPanel headerClassName="absolute top-0 right-0" headerTemplate={closePrethodniNalaziSablonHeaderTemplate} />
          </TabView>
        </div>
      )}
      {!poljeListLoading ? (
        <div className="flex flex-column gap-2">
          {poljeTypeList &&
            poljeTypeList.map((polje) => {
              return (
                <Polje
                  key={polje.id}
                  {...polje}
                  useVrednostList
                  vrednostList={poljeVrednostList}
                  readonly={!pristup || (formularPopunjen?.id ? !hasFormularPopunjenUpdate && authData?.currentRadnik.id !== formularPopunjen?.radnikKreirao.id : !hasFormularPopunjenCreate)}
                />
              );
            })}
        </div>
      ) : (
        <div>
          <SkeletonPoljeVrednostInRow size={3} />
        </div>
      )}
      <div className="flex col-12 p-0 align-items-center justify-content-end mt-2">
        {((formular?.jrDokument && formular?.jrDokument.length > 0) || (formular?.htmlPredlozak && formular?.htmlPredlozak.length > 0)) && (
          <Button
            label={Labels.PREGLED_NALAZA_STAMPAJ}
            tooltip={
              (hasFormularPopunjenCreate && !formularPopunjen) || hasFormularPopunjenUpdate || authData?.currentRadnik.id === formularPopunjen?.radnikKreirao.id
                ? Labels.SACUVAJ_I_STAMPAJ
                : Labels.PREGLED_NALAZA_STAMPAJ
            }
            tooltipOptions={tooltipOptionsBottom}
            disabled={poljeVrednostListLoading || poljeListLoading}
            className="p-button-outlined p-button-tertiary mr-2"
            onClick={() => stampaj(formular, formularPopunjen!, poljeVrednostList)}
          ></Button>
        )}

        <Button
          label={formularPopunjen?.id ? Labels.BUTTON_UPDATE : Labels.BUTTON_SAVE}
          tooltip={formularPopunjen?.id ? Labels.BUTTON_UPDATE : Labels.BUTTON_SAVE}
          tooltipOptions={tooltipOptionsBottom}
          disabled={
            !pristup ||
            poljeVrednostListLoading ||
            poljeListLoading ||
            poljeTypeList.length === 0 ||
            (formularPopunjen?.id ? !hasFormularPopunjenUpdate && authData?.currentRadnik.id !== formularPopunjen?.radnikKreirao.id : !hasFormularPopunjenCreate)
          }
          className="p-button-success"
          onClick={save}
        />
      </div>
    </div>
  );
}
