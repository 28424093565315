import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { PaketController } from "../../../controllers/paket/PaketController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import PaketReadDto from "../../../model/paket/PaketReadDto";

interface PaketListLogicalType {
  paketList: Array<PaketReadDto>;
  first: number;
  tableRows: number;
  changePaketSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  paketSearchData: SearchBaseDto;
  fetchData: () => void;
  searchPaketByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  onPagePaket: (rows: number, first: number) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  paketLoading: boolean;
}

export default function PaketListLogical(): PaketListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [paketList, setPaketList] = useState<Array<PaketReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [paketSearchData, setPaketSearchData] = useState<SearchBaseDto>({});
  const [paketLoading, setPaketLoading] = useState<boolean>(true);
  const [breadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.PAKET_USLUGA_LIST,
    },
  ]);

  const { axiosSearchPaketList } = PaketController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const startTime = moment(new Date());
    setPaketLoading(true);
    axiosSearchPaketList(paketSearchData)
      .then((res: { data: { data: Array<PaketReadDto> } }) => {
        setPaketList(res.data.data);
        const exportList = new Array<any>();
        res.data.data.forEach((paket: PaketReadDto) => {
          exportList.push({
            [Labels.LABEL_SIFRA]: paket.sifra,
            [Labels.LABEL_NAZIV]: paket.naziv,
            [Labels.LABEL_NAPOMENA]: paket.napomena,
          });
          setExportData(exportList);
        });
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setPaketLoading, startTime);
      });
  };

  const changePaketSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setPaketSearchData({
      ...paketSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchPaketByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPagePaket = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    paketList,
    first,
    tableRows,
    changePaketSearchData,
    paketSearchData,
    fetchData,
    searchPaketByEnter,
    exportData,
    breadCrumbItems,
    onPagePaket,
    paketLoading,
  };
}
