import { ProgressSpinner } from "primereact/progressspinner";
import { useLabels } from "../../Store";
import EnumTipKomponente from "../../infrastructure/system/EnumTipKomponente";
import { DATE_FORMAT, formatDate } from "../../infrastructure/system/Utils";
import PoljeReadDto from "../../model/polje/PoljeReadDto";
import PoljeTipEnumStavkaReadDto from "../../model/poljeTip/PoljeTipEnumStavkaReadDto";
import { valueMap } from "./FormularLogical";

interface PrikazFormularaType {
  poljeList?: Array<PoljeReadDto>;
  poljeVrednostList?: valueMap;
}

export default function PrikazFormulara(props: PrikazFormularaType) {
  const { poljeList, poljeVrednostList } = props;
  const Labels = useLabels();

  const getVrednost = (polje: PoljeReadDto) => {
    switch (polje.poljeTip.tipKomponenteEnum.sifra) {
      case EnumTipKomponente.TEXT:
      case EnumTipKomponente.NUMBER_DECIMAL:
      case EnumTipKomponente.NUMBER_INTEGER:
        return poljeVrednostList ? poljeVrednostList[polje.id] : undefined;
      case EnumTipKomponente.LONG_TEXT:
        return poljeVrednostList
          ? poljeVrednostList[polje.id]?.split("\n").map((str: string, index: number) => (
              <p className="m-0" key={index}>
                {str}
              </p>
            ))
          : undefined;
      case EnumTipKomponente.BOOLEAN:
        return poljeVrednostList ? (poljeVrednostList[polje.id] ? Labels.LABEL_COLUMN_TRUE : Labels.LABEL_COLUMN_FALSE) : undefined;
      case EnumTipKomponente.DATE_TIME:
        return poljeVrednostList ? formatDate(poljeVrednostList[polje.id], DATE_FORMAT) : undefined;
      case EnumTipKomponente.SET:
      case EnumTipKomponente.SET_RADIO:
        return poljeVrednostList
          ? polje.poljeTip.poljeTipEnumStavkaList.find((poljeTipEnumStavka: PoljeTipEnumStavkaReadDto) => poljeTipEnumStavka?.id === poljeVrednostList[polje.id])?.naziv
          : undefined;
    }
  };

  return (
    <>
      {poljeVrednostList &&
        poljeList?.map((polje: PoljeReadDto, index: number) => (
          <div key={index}>
            <div className="font-bold">{polje.naziv}:</div>
            <div>{getVrednost(polje)}</div>
          </div>
        ))}
      {!(poljeList && poljeVrednostList) && (
        <div className="w-full h-full flex align-items-center ">
          <ProgressSpinner />
        </div>
      )}
    </>
  );
}
