import axios from "axios";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../../Store";
import { BrojNalogaController } from "../../../../../controllers/broj-naloga/BrojNalogaController";
import { UstanovaBrojNalogaController } from "../../../../../controllers/ustanova/ustanova-broj-naloga/UstanovaBrojNalogaController";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce } from "../../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../../../model/SearchBaseDto";
import BrojNalogaReadDto from "../../../../../model/licenca/broj-naloga/BrojNalogaReadDto";
import UstanovaBrojNalogaCreateDto from "../../../../../model/ustanova/licenca/UstanovaBrojNalogaCreateDto";
import UstanovaBrojNalogaReadDto from "../../../../../model/ustanova/licenca/UstanovaBrojNalogaReadDto";

interface UstanovaBrojNalogaLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  isDisabled: boolean;
  brojNalogaList: Array<BrojNalogaReadDto>;
  ustanovaBrojNalogaChange: UstanovaBrojNalogaReadDto | UstanovaBrojNalogaCreateDto | undefined;
  brojNalogaLoading: boolean;
  ustanovaBrojNalogaOperation: string;
  setUstanovaBrojNalogaChange: React.Dispatch<React.SetStateAction<UstanovaBrojNalogaReadDto | UstanovaBrojNalogaCreateDto | undefined>>;
  onUpdate: () => void;
  onCancel: () => void;
  onCreate: () => void;
  onDelete: () => void;
  vaziOdRef: HTMLDivElement | any;
  vaziDoRef: HTMLDivElement | any;
}

interface UseParamsType {
  ustanovaBrojNalogaId?: string;
  ustanovaId?: string;
}

export default function CrudUstanovaBrojNalogaLogical(): UstanovaBrojNalogaLogicalType {
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const ustanovaBrojNalogaId = Number(useParams<keyof UseParamsType>()["ustanovaBrojNalogaId"]);
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { axiosUpdateUstanovaBrojNaloga, axiosReadUstanovaBrojNaloga, axiosCreateUstanovaBrojNaloga, axiosDeleteUstanovaBrojNaloga } = UstanovaBrojNalogaController();
  const { axiosSearchBrojNalogaList } = BrojNalogaController();
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [index, setIndex] = useState<number>(tabIndex);
  const [brojNalogaLoading, setBrojNalogaLoading] = useState<boolean>(false);
  const [ustanovaBrojNalogaOperation] = useState<string>(
    location.state && location.state.ustanovaBrojNalogaOperation !== undefined ? location.state.ustanovaBrojNalogaOperation : EntityOperation.UPDATE
  );
  const postLogHighLevel = useLogHighLevel();
  const [brojNalogaSearchData] = useState<SearchBaseDto>({});
  const [ustanovaBrojNalogaChange, setUstanovaBrojNalogaChange] = useState<UstanovaBrojNalogaReadDto | UstanovaBrojNalogaCreateDto>();
  const [ustanovaNaziv] = useState<string>(location.state && location.state.ustanovaNaziv !== undefined ? location.state.ustanovaNaziv : "");
  const [isDisabled] = useState<boolean>(isFormDisabled(ustanovaBrojNalogaOperation));
  const [brojNalogaList, setBrojNalogaList] = useState<Array<BrojNalogaReadDto>>([]);
  const vaziOdRef = useRef<HTMLDivElement | any>(null);
  const vaziDoRef = useRef<HTMLDivElement | any>(null);

  const [breadCrumbItems, setBreadCrumbItems] = useState<BreadCrumbItemDto[]>([
    {
      label: Labels.USTANOVA_LIST,
      command: () => {
        navigate(`/crud-ustanova`);
      },
    },
    {
      label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + ustanovaNaziv,
      command: () => {
        navigate(`/crud-ustanova/${ustanovaId}`, {
          state: {
            tabIndex: 1,
            licenceTabIndex: 0,
          },
        });
      },
    },
  ]);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (ubnID?: number) => {
    let startTime = moment(new Date());
    setBrojNalogaLoading(true);
    axiosSearchBrojNalogaList(brojNalogaSearchData)
      .then(({ data: { data } }: { data: { data: Array<BrojNalogaReadDto> } }) => {
        setBrojNalogaList(data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setBrojNalogaLoading, startTime);
      });
    const newUstanovaBrojNalogaId = ubnID || ustanovaBrojNalogaId;
    if (newUstanovaBrojNalogaId) {
      axios
        .all([axiosReadUstanovaBrojNaloga(newUstanovaBrojNalogaId, ustanovaId)])
        .then(
          axios.spread((responseUstanovaBrojNalogaChange: any) => {
            setUstanovaBrojNalogaChange(responseUstanovaBrojNalogaChange.data.data);
            ustanovaBrojNalogaOperation === EntityOperation.UPDATE
              ? setBreadCrumbItems([
                  ...breadCrumbItems,
                  {
                    label: Labels.LICENCNI_MODEL_TITLE_DIALOG_UPDATE + " " + responseUstanovaBrojNalogaChange.data.data.brojNaloga.naziv,
                  },
                ])
              : setBreadCrumbItems([
                  ...breadCrumbItems,
                  {
                    label: Labels.LICENCNI_MODEL_TITLE_DIALOG_DELETE + " " + responseUstanovaBrojNalogaChange.data.data.brojNaloga.naziv,
                  },
                ]);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    } else {
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.LICENCNI_MODEL_TITLE_DIALOG_CREATE,
        },
      ]);
    }
  };

  const validateInput = (ustanovaBrojNaloga: UstanovaBrojNalogaReadDto | UstanovaBrojNalogaCreateDto | undefined) => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (ustanovaBrojNaloga === undefined || !ustanovaBrojNaloga?.brojNaloga) {
      showMessage(MessageType.ERROR, Labels.USTANOVA_BROJ_NALOGA_IS_REQUIRED);
      return false;
    }

    if (!ustanovaBrojNaloga?.vaziOd) {
      showMessage(MessageType.ERROR, Labels.USTANOVA_VAZI_OD_IS_REQUIRED);
      return false;
    }
    if (ustanovaBrojNalogaOperation === EntityOperation.CREATE && new Date(ustanovaBrojNaloga?.vaziOd) < today) {
      showMessage(MessageType.ERROR, Labels.USTANOVA_VAZI_OD_VECA_OD_DANAS);
      return false;
    }
    if (ustanovaBrojNaloga?.vaziDo && new Date(ustanovaBrojNaloga?.vaziOd) > new Date(ustanovaBrojNaloga?.vaziDo)) {
      showMessage(MessageType.ERROR, Labels.USTANOVA_VAZI_OD_VECA_OD_VAZI_DO);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validateInput(ustanovaBrojNalogaChange)) {
      return;
    }
    if (ustanovaBrojNalogaChange) {
      setShowBlockUI(true);
      axiosCreateUstanovaBrojNaloga(ustanovaBrojNalogaChange, ustanovaId)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.LICENCNI_MODEL_MESSAGE_CREATE_SUCCESS);

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_1 +
              ustanovaNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_2 +
              ustanovaBrojNalogaChange.brojNaloga.id +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_3 +
              ustanovaBrojNalogaChange.vaziOd +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_4 +
              (ustanovaBrojNalogaChange.vaziOd ?? "/")
          );

          navigate(`/crud-ustanova/${ustanovaId}`, {
            state: {
              tabIndex: 1,
              index: 0,
            },
          });
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };
  const onUpdate = () => {
    if (!validateInput(ustanovaBrojNalogaChange)) {
      return;
    }
    const updateRequestUstanovaBrojNaloga = ustanovaBrojNalogaChange && axiosUpdateUstanovaBrojNaloga(ustanovaBrojNalogaChange as UstanovaBrojNalogaReadDto, ustanovaId);

    if (updateRequestUstanovaBrojNaloga) {
      setShowBlockUI(true);
      updateRequestUstanovaBrojNaloga
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.LICENCNI_MODEL_MESSAGE_UPDATE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_1 +
              ustanovaNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_2 +
              ustanovaBrojNalogaChange.brojNaloga.id +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_3 +
              ustanovaBrojNalogaChange.vaziOd +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_4 +
              (ustanovaBrojNalogaChange.vaziDo ?? "/")
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeleteUstanovaBrojNaloga(ustanovaBrojNalogaId, ustanovaId)
      .then(() => {
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_1 +
            ustanovaNaziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_2 +
            ustanovaBrojNalogaChange!.brojNaloga.id +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_3 +
            ustanovaBrojNalogaChange!.vaziOd +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_4 +
            (ustanovaBrojNalogaChange!.vaziDo ?? "/")
        );
        showMessage(MessageType.SUCCESS, Labels.LICENCNI_MODEL_MESSAGE_DELETE_SUCCESS);
        onCancel();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    navigate(`/crud-ustanova/${ustanovaId}`, {
      state: {
        tabIndex: 1,
        index: 0,
      },
    });
  };
  return {
    breadCrumbItems,
    index,
    setIndex,
    isDisabled,
    brojNalogaList,
    ustanovaBrojNalogaChange,
    brojNalogaLoading,
    ustanovaBrojNalogaOperation,
    setUstanovaBrojNalogaChange,
    onUpdate,
    onCancel,
    onCreate,
    onDelete,
    vaziOdRef,
    vaziDoRef,
  };
}
