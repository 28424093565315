import { Avatar } from "primereact/avatar";
import { useState } from "react";

interface RadnikType {
  slika: string;
  ime: string;
  prezime: string;
  titula?: string;
  neaktivan?: boolean;
}

export default function Radnik(props: RadnikType) {
  const { slika, ime, prezime, titula, neaktivan } = props;
  const [error, setError] = useState(false);

  return (
    <div className="flex flex-row pl-2 align-items-center">
      {slika && !neaktivan && !error ? (
        <Avatar onImageError={() => setError(true)} image={slika} size="large" shape="circle" imageAlt={`${ime.charAt(0)}${prezime.charAt(0)}`} />
      ) : (
        <Avatar className={`avatarBorder ${neaktivan && "radnik-neaktivan"}`} label={`${ime.charAt(0)}${prezime.charAt(0)}`} size="large" shape="circle" />
      )}
      <div className="ml-2 flex flex-column profile-info align-self-center">
        <div className={`profile-name ${neaktivan && "radnik-neaktivan"}`}>{`${ime} ${prezime}`}</div>
        {titula && <div className={`profile-title ${neaktivan && "radnik-neaktivan"}`}>{titula}</div>}
      </div>
    </div>
  );
}
