import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import BrojNalogaCreateDto from "../../model/licenca/broj-naloga/BrojNalogaCreateDto";
import BrojNalogaReadDto from "../../model/licenca/broj-naloga/BrojNalogaReadDto";
import SearchBaseDto from "../../model/SearchBaseDto";
import { AppContext } from "../../Store";

interface BrojNalogaControllerType {
  axiosSearchBrojNalogaList: (searchString: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetBrojNaloga: (brojNalogaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateBrojNaloga: (brojNalogaChange: BrojNalogaCreateDto) => Promise<AxiosResponse<any>>;
  axiosUpdateBrojNaloga: (brojNaloga: BrojNalogaReadDto) => Promise<AxiosResponse<any>>;
}

export function BrojNalogaController(): BrojNalogaControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchBrojNalogaList = (searchString: SearchBaseDto) => {
    return axios.get(Endpoint.BROJ_NALOGA_SEARCH, axiosConfig(authData!.token, searchString));
  };

  const axiosGetBrojNaloga = (brojNalogaId: number) => {
    return axios.get(`${Endpoint.BROJ_NALOGA_LIST}/${brojNalogaId}`, axiosConfig(authData!.token));
  };

  const axiosCreateBrojNaloga = (brojNalogaChange: BrojNalogaCreateDto) => {
    return axios.post(`${Endpoint.BROJ_NALOGA_LIST}`, brojNalogaChange, axiosConfig(authData!.token));
  };

  const axiosUpdateBrojNaloga = (brojNaloga: BrojNalogaReadDto) => {
    return axios.put(`${Endpoint.BROJ_NALOGA_LIST}/${brojNaloga?.id}`, brojNaloga, axiosConfig(authData!.token));
  };

  return {
    axiosSearchBrojNalogaList,
    axiosGetBrojNaloga,
    axiosCreateBrojNaloga,
    axiosUpdateBrojNaloga,
  };
}
