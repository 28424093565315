import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { useLabels } from "../../../Store";
import { DATE_TIME_FORMAT } from "../../../infrastructure/system/Utils";
import ZakazanTerminFutureDto from "../../../model/zakazan-termin/ZakazanTerminFutureDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import ZakazaniPreglediLogical from "./ZakazaniPreglediLogical";

export default function ZakazaniPregledi() {
  const { zakazaniTerminiList, zakazaniTerminiListLoading } = ZakazaniPreglediLogical();
  const Labels = useLabels();

  const spojTituluImeIPrezime = (termin: ZakazanTerminFutureDto) => {
    return <div>{`${termin.radnik?.titula ?? ""} ${termin.radnik.ime} ${termin.radnik.prezime}`}</div>;
  };

  const formatirajVreme = (termin: ZakazanTerminFutureDto) => {
    return <div>{moment(new Date(termin.vremeZakazanoOd)).format(DATE_TIME_FORMAT)}</div>;
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.ZAKAZANI_PREGLEDI_PRIMARNI_DOKTOR, filter: false, sortrable: false },
    { columnName: Labels.ZAKAZANI_PREGLEDI_VREME_PREGLEDA, filter: false, sortrable: false },
    { columnName: Labels.ZAKAZANI_PREGLEDI_ORGANIZACIONA_JEDINICA, filter: false, sortrable: false },
    { columnName: Labels.ZAKAZANI_PREGLEDI_TIP_PREGLEDA, filter: false, sortrable: false },
  ];

  return (
    <div className="layout-zakazani-termini">
      <Panel>
        {zakazaniTerminiListLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={false} isVisibleButtonList={false} />
        ) : (
          <DataTable value={zakazaniTerminiList ?? []} emptyMessage={Labels.TABLE_EMPTY_MESSAGE} rowHover stripedRows>
            <Column
              field={"radnik.ime"}
              body={spojTituluImeIPrezime}
              className="pl-8 py-3"
              header={Labels.ZAKAZANI_PREGLEDI_PRIMARNI_DOKTOR}
              headerClassName="header-column-bold"
              filter={false}
              showFilterMenu={false}
              sortable={false}
            />
            <Column
              field={"vremeZakazanoOd"}
              body={formatirajVreme}
              className="pl-0 py-3"
              header={Labels.ZAKAZANI_PREGLEDI_VREME_PREGLEDA}
              headerClassName="header-column-bold"
              filter={false}
              showFilterMenu={false}
              sortable={false}
            />
            <Column
              field={"organizacionaJedinica.naziv"}
              className="pl-0 py-3"
              header={Labels.ZAKAZANI_PREGLEDI_ORGANIZACIONA_JEDINICA}
              headerClassName="header-column-bold"
              filter={false}
              showFilterMenu={false}
              sortable={false}
            />
            <Column
              field={"kontaktTip.naziv"}
              className="pl-0 py-3"
              header={Labels.ZAKAZANI_PREGLEDI_TIP_PREGLEDA}
              headerClassName="header-column-bold"
              filter={false}
              showFilterMenu={false}
              sortable={false}
            />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
