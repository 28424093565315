import axios from "axios";
import moment from "moment";
import { FileUploadHandlerEvent } from "primereact/fileupload";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import { EnumController } from "../../../controllers/enum/EnumController";
import ProtokolControler from "../../../controllers/protokol/ProtokolController";
import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumPristup from "../../../infrastructure/system/EnumPristup";
import MessageType from "../../../infrastructure/system/MessageType";
import RadnoVremeUtils from "../../../infrastructure/system/RadnoVremeUtils";
import { MAX_FILE_UPLOAD, handleAxiosCallError, isCreateForm, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import ProtokolReadDto from "../../../model/protokol/ProtokolReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import EnumPodrazumevanaStranaDto from "../../../model/sifarnik/EnumPodrazumevanaStranaDto";
import UstanovaReadDto from "../../../model/ustanova/UstanovaReadDto";

interface UstanovaCrudLogicalType {
  ustanovaOperation: string;
  isDisabled: boolean;
  isCreateDialogOpen: boolean;
  onCreate: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  ustanovaChange: UstanovaReadDto | undefined;
  setUstanovaChange: any;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  ustanovaId: number | undefined;
  location: any;
  jezikList: Array<EnumBaseReadDto>;
  svojinaTipList: Array<EnumBaseReadDto>;
  nivoZdravstveneZastiteList: Array<EnumBaseReadDto>;
  podrazumevanaStranaList: Array<EnumPodrazumevanaStranaDto>;
  ustanovaLoading: boolean;
  getRadnoVremeValue: (radnoVreme: string | undefined) => Date | undefined;
  protokolList: Array<ProtokolReadDto>;
  pristupList: Array<EnumBaseReadDto>;
  inizijalizacijaKorakList: Array<EnumBaseReadDto>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  logoUploadHandler: (event: FileUploadHandlerEvent) => void;
  vremeOdRef: HTMLDivElement | any;
  vremeDoRef: HTMLDivElement | any;
}

interface UseParamsType {
  ustanovaId?: string;
}

export default function CrudUstanovaLogical(): UstanovaCrudLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const navigate = useNavigate();
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const location = useLocation();
  const [ustanovaOperation] = useState<string>(location.state && location.state.ustanovaOperation !== undefined ? location.state.ustanovaOperation : EntityOperation.UPDATE);
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const Labels = useLabels();
  const [isDisabled] = useState(isFormDisabled(ustanovaOperation));
  const [isCreateDialogOpen] = useState<boolean>(isCreateForm(ustanovaOperation));
  const [ustanovaChange, setUstanovaChange] = useState<UstanovaReadDto | undefined>();
  const [index, setIndex] = useState<number>(tabIndex);
  const { axiosSuperadminCreateUstanova, axiosSuperadminUpdateUstanova, axiosSuperadminReadUstanova } = SuperadminController();
  const { axiosGetSvojinaTipList, axiosGetNivoZdravstveneZastiteList, axiosGetJezikList, axiosGetPodrazumevanaStranaList, axiosGetEnumPristupList, axiosGetEnumInizijalizacijaKorakList } =
    EnumController();
  const { axiosGetProtokolList } = ProtokolControler();
  const [jezikList, setJezikList] = useState<Array<EnumBaseReadDto>>([]);
  const [svojinaTipList, setSvojinaTipList] = useState<Array<EnumBaseReadDto>>([]);
  const [nivoZdravstveneZastiteList, setNivoZdravstveneZastiteList] = useState<Array<EnumBaseReadDto>>([]);
  const [podrazumevanaStranaList, setPodrazumevanaStranaList] = useState<Array<EnumPodrazumevanaStranaDto>>([]);
  const [ustanovaLoading, setUstanovaLoading] = useState<boolean>(false);
  const [protokolList, setProtokolList] = useState<Array<ProtokolReadDto>>([]);
  const [pristupList, setPristupList] = useState<Array<EnumBaseReadDto>>([]);
  const [inizijalizacijaKorakList, setInizijalizacijaKorakList] = useState<Array<EnumBaseReadDto>>([]);

  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const vremeOdRef = useRef<HTMLDivElement | any>(null);
  const vremeDoRef = useRef<HTMLDivElement | any>(null);
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.USTANOVA_LIST,
      command: () => {
        navigate("/ustanova-list/");
      },
    },
  ]);
  const { getRadnoVremeValue } = RadnoVremeUtils();
  const postLogHighLevel = useLogHighLevel();

  const getEnumList = () => {
    const requestSvojinaTipList = axiosGetSvojinaTipList();
    const requestJezikList = axiosGetJezikList();
    const requestNivoZdravstveneZastiteList = axiosGetNivoZdravstveneZastiteList();
    const requestPodrazumevanaStranaList = axiosGetPodrazumevanaStranaList();
    const requestPristupList = axiosGetEnumPristupList();
    const requestInizijalizacijaKorakList = axiosGetEnumInizijalizacijaKorakList();
    axios
      .all([requestSvojinaTipList, requestJezikList, requestNivoZdravstveneZastiteList, requestPodrazumevanaStranaList, requestPristupList, requestInizijalizacijaKorakList])
      .then(
        axios.spread(
          (
            { data: { data: responseSvojinaTipList } }: { data: { data: Array<EnumBaseReadDto> } },
            { data: { data: responseJezikList } }: { data: { data: Array<EnumBaseReadDto> } },
            { data: { data: responseNivoZdravstveneZastiteList } }: { data: { data: Array<EnumBaseReadDto> } },
            { data: { data: responsePodrazumevanaStranaList } }: { data: { data: Array<any> } },
            { data: { data: responsePristupList } }: { data: { data: Array<any> } },
            { data: { data: responseInizijalizacijaKorakList } }: { data: { data: Array<EnumBaseReadDto> } }
          ) => {
            setSvojinaTipList(responseSvojinaTipList);
            setJezikList(responseJezikList);
            setNivoZdravstveneZastiteList(responseNivoZdravstveneZastiteList);
            setPodrazumevanaStranaList(responsePodrazumevanaStranaList);
            setPristupList(responsePristupList);
            setInizijalizacijaKorakList(responseInizijalizacijaKorakList);
          }
        )
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const getProtokolList = () => {
    if (ustanovaId) {
      axiosGetProtokolList(ustanovaId)
        .then((response: any) => {
          setProtokolList(response.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  useEffectOnce(() => {
    getEnumList();
    if (ustanovaOperation === EntityOperation.CREATE) {
      setUstanovaChange({
        ...ustanovaChange!,
      });
    }
  });

  useEffectOnce(() => {
    getProtokolList();
    fetchData();
  });

  const fetchData = () => {
    if (ustanovaId) {
      const startTime = moment(new Date());
      setUstanovaLoading(true);
      const requestUstanova = axiosSuperadminReadUstanova(ustanovaId);
      axios
        .all([requestUstanova])
        .then(
          axios.spread(({ data: { data: responseUstanova } }: { data: { data: UstanovaReadDto } }) => {
            setUstanovaChange(responseUstanova);
            if (breadCrumbItems.length < 2) {
              setBreadCrumbItems([
                ...breadCrumbItems,
                {
                  label:
                    ustanovaOperation === EntityOperation.UPDATE
                      ? Labels.USTANOVA_TITLE_DIALOG_UPDATE + responseUstanova.naziv
                      : ustanovaOperation === EntityOperation.READ
                      ? Labels.USTANOVA_TITLE_DIALOG_DETAILS + responseUstanova.naziv
                      : Labels.USTANOVA_TITLE_DIALOG_DELETE + responseUstanova.naziv,
                },
              ]);
            }
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setUstanovaLoading, startTime);
        });
    } else {
      setUstanovaLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.USTANOVA_TITLE_DIALOG_CREATE,
        },
      ]);
    }
  };

  const validateInput = (ustanova: UstanovaReadDto | undefined) => {
    let isInvalid = false;
    if (validateStringEmpty(ustanova?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(ustanova?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(ustanova?.nazivKratki)) {
      setInvalidFields((prev) => ({ ...prev, nazivKratki: true }));
      isInvalid = true;
    }
    if (!ustanova?.jezik) {
      setInvalidFields((prev) => ({ ...prev, jezik: true }));
      isInvalid = true;
    }
    if (!ustanova?.svojinaTip) {
      setInvalidFields((prev) => ({ ...prev, svojinaTip: true }));
      isInvalid = true;
    }
    if (!ustanova?.nivoZdravstveneZastite) {
      setInvalidFields((prev) => ({ ...prev, nivoZastite: true }));
      isInvalid = true;
    }
    if (!ustanova?.podrazumevanaStrana) {
      setInvalidFields((prev) => ({ ...prev, podrazumevanaStrana: true }));
      isInvalid = true;
    }
    if (!ustanova?.valuta) {
      setInvalidFields((prev) => ({ ...prev, valuta: true }));
      isInvalid = true;
    }
    if (!ustanova?.radnoVremeOd) {
      setInvalidFields((prev) => ({ ...prev, radnoVremeOd: true }));
      isInvalid = true;
    }
    if (!ustanova?.radnoVremeDo) {
      setInvalidFields((prev) => ({ ...prev, radnoVremeDo: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(ustanovaChange)) {
      return;
    }
    setShowBlockUI(true);
    let ustanova = { ...ustanovaChange!, pristup: { sifra: EnumPristup.PUN } };
    axiosSuperadminCreateUstanova(ustanova)
      .then((res) => {
        showMessage(MessageType.SUCCESS, Labels.USTANOVA_TITLE_MESSAGE_CREATE_USTANOVA_SUCCESS);
        navigate(`/ustanova-list`);
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_SUPERADMIN_1 +
            ustanovaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_1 +
            ustanovaChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_2 +
            ustanovaChange?.nazivKratki +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_3 +
            res.data.data.jezik.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_4 +
            res.data.data.svojinaTip.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_5 +
            res.data.data.nivoZdravstveneZastite.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_6 +
            res.data.data.podrazumevanaStrana.naziv +
            "."
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onUpdate = () => {
    if (!validateInput(ustanovaChange)) {
      return;
    }
    setShowBlockUI(true);
    axiosSuperadminUpdateUstanova(ustanovaChange!)
      .then((res) => {
        showMessage(MessageType.SUCCESS, Labels.USTANOVA_TITLE_MESSAGE_UPDATE_USTANOVA_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_SUPERADMIN_1 +
            ustanovaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_1 +
            ustanovaChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_2 +
            ustanovaChange?.nazivKratki +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_3 +
            res.data.data.jezik.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_4 +
            res.data.data.svojinaTip.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_5 +
            res.data.data.nivoZdravstveneZastite.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_6 +
            res.data.data.podrazumevanaStrana.naziv +
            "."
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      const breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/ustanova-list`);
    }
  };

  const logoUploadHandler = (e: FileUploadHandlerEvent) => {
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }
        if (reader.result) {
          setUstanovaChange({
            ...ustanovaChange!,
            logo: reader.result?.toString().substr(reader.result?.toString().indexOf(",") + 1)!,
          });
        }
      };
    });
  };

  return {
    ustanovaOperation,
    isDisabled,
    isCreateDialogOpen,
    ustanovaChange,
    breadCrumbItems,
    setUstanovaChange,
    onCreate,
    onUpdate,
    onCancel,
    setIndex,
    index,
    ustanovaId,
    location,
    jezikList,
    svojinaTipList,
    nivoZdravstveneZastiteList,
    podrazumevanaStranaList,
    ustanovaLoading,
    getRadnoVremeValue,
    protokolList,
    pristupList,
    inizijalizacijaKorakList,
    invalidFields,
    setInvalidFields,
    logoUploadHandler,
    vremeOdRef,
    vremeDoRef,
  };
}
