import { Calendar, CalendarMonthNavigatorTemplateEvent, CalendarProps, CalendarYearNavigatorTemplateEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ForwardedRef, forwardRef, useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../../Store";
import EnumJezik from "../../../infrastructure/system/EnumJezik";
import { CALENDAR_DATE_FORMAT } from "../../../infrastructure/system/Utils";

interface CalendarComponentProps extends CalendarProps {
  ascendingListOfYears?: boolean;
}

const defaultYearRange = new Date().getFullYear() - 100 + ":" + (new Date().getFullYear() + 10);

const CalendarComponent = forwardRef((props: CalendarComponentProps, ref: ForwardedRef<Calendar>) => {
  const { ascendingListOfYears, ...rest } = props;
  const { value, placeholder, dateFormat, autoZIndex, minDate, maxDate, stepMinute, viewDate, icon, className, view, appendTo, yearRange, yearNavigator, monthNavigator, showButtonBar } = rest;

  const { locale } = useContext(AppContext);

  useEffect(() => {
    switch (locale) {
      case EnumJezik.SR_RS_LATN:
        setCurrentLocale("sr");
        break;
      case EnumJezik.SR_RS:
        setCurrentLocale("sr-cyrilic");
        break;
    }
  }, [locale]);

  const dropdownStyle = useMemo(() => {
    return { lineHeight: 1 };
  }, []);

  const [currentLocale, setCurrentLocale] = useState<string>("sr");

  const parseValue = (v: any) => (v ? new Date(v) : undefined);

  const monthTemplate = (e: CalendarMonthNavigatorTemplateEvent) => (
    <Dropdown
      value={e.value}
      options={e.options}
      onChange={({ originalEvent, value }) => {
        if (originalEvent) e.onChange(originalEvent, value);
      }}
      style={dropdownStyle}
    />
  );

  const yearTemplate = (e: CalendarYearNavigatorTemplateEvent) => (
    <Dropdown
      value={e.value}
      options={ascendingListOfYears ? e.options : e.options.reverse()}
      onChange={({ originalEvent, value }) => {
        if (originalEvent) e.onChange(originalEvent, value);
      }}
      className="ml-2"
      style={dropdownStyle}
    />
  );

  return (
    <Calendar
      {...rest}
      ref={ref}
      value={parseValue(value)}
      placeholder={placeholder ?? ""}
      dateFormat={dateFormat ?? CALENDAR_DATE_FORMAT}
      monthNavigator={monthNavigator ?? true}
      monthNavigatorTemplate={monthTemplate}
      yearNavigator={yearNavigator ?? true}
      yearNavigatorTemplate={yearTemplate}
      yearRange={yearRange ?? defaultYearRange}
      showButtonBar={showButtonBar ?? true}
      showIcon
      icon={icon ?? "pi pi-calendar"}
      locale={currentLocale}
      className={className ?? "inputfield w-full"}
      autoZIndex={autoZIndex ?? true}
      stepMinute={stepMinute ?? 1}
      view={view ?? "date"}
      {...(viewDate ? { onViewDateChange: () => {} } : {})}
      {...(minDate ? { minDate } : {})}
      {...(maxDate ? { maxDate } : {})}
      hideOnDateTimeSelect
      appendTo={appendTo ?? "self"}
      showOnFocus
    />
  );
});

export default CalendarComponent;
