import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import TipKontaktaOrganizacionaJedinicaCreateDto from "../../model/kontakt/TipKontaktaOrganizacionaJedinicaCreateDto";
import TipKontaktaReadDto from "../../model/kontakt/TipKontaktaReadDto";
import KontaktTipRepositoryUstanovaInitCopyDto from "../../model/kontakt/KontaktTipRepositoryUstanovaInitCopyDto";

interface TipKontaktaControllerType {
  axiosSearchTipKontakta: (searchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosCreateTipKontakta: (tipKontaktaChange: TipKontaktaReadDto) => Promise<AxiosResponse<any>>;
  axiosUpdateTipKontakta: (tipKontaktaChange: TipKontaktaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteTipKontakta: (tipKontaktaChange: TipKontaktaReadDto) => Promise<AxiosResponse<any>>;
  axiosGetTipKontakta: (tipKontaktaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateTipKontaktaOrganizacionaJedinica: (tipKontaktaId: number, tipKontaktaOrganizacionaJedinicaCreate: TipKontaktaOrganizacionaJedinicaCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetTipKontaktaOrganizacionaJedinicaList: (tipKontaktaId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteTipKontaktaOrganizacionaJedinica: (tipKontaktaId: number, organizacionaJedinicaId: number) => Promise<AxiosResponse<any>>;
  axiosGetInicijalizacijaTipKontaktaList: () => Promise<AxiosResponse<any>>;
  axiosCopyKontaktTipWithOrganizacionaJedinica: (kontaktTipRepositoryUstanovaInitCopyDto: KontaktTipRepositoryUstanovaInitCopyDto) => Promise<AxiosResponse<any>>;
}

export function TipKontaktaController(): TipKontaktaControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchTipKontakta = (searchData?: SearchBaseDto) => {
    return axios.get(Endpoint.TIP_KONTAKTA_SEARCH, axiosConfig(authData!.token, searchData));
  };

  const axiosGetTipKontakta = (tipKontaktaId: number) => {
    return axios.get(`${Endpoint.TIP_KONTAKTA_LIST}/${tipKontaktaId}`, axiosConfig(authData!.token));
  };

  const axiosCreateTipKontakta = (tipKontaktaChange: TipKontaktaReadDto) => {
    return axios.post(Endpoint.TIP_KONTAKTA_LIST, tipKontaktaChange ? tipKontaktaChange : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateTipKontakta = (tipKontaktaChange: TipKontaktaReadDto) => {
    return axios.put(`${Endpoint.TIP_KONTAKTA_LIST}/${tipKontaktaChange.id}`, tipKontaktaChange, axiosConfig(authData!.token));
  };

  const axiosDeleteTipKontakta = (tipKontaktaChange: TipKontaktaReadDto) => {
    return axios.delete(`${Endpoint.TIP_KONTAKTA_LIST}/${tipKontaktaChange.id}`, axiosConfig(authData!.token));
  };

  const axiosCreateTipKontaktaOrganizacionaJedinica = (tipKontaktaId: number, tipKontaktaOrganizacionaJedinicaCreate: TipKontaktaOrganizacionaJedinicaCreateDto) => {
    return axios.post(
      `${Endpoint.TIP_KONTAKTA_LIST}/${tipKontaktaId}/organizaciona-jedinica-list`,
      tipKontaktaOrganizacionaJedinicaCreate ? tipKontaktaOrganizacionaJedinicaCreate : {},
      axiosConfig(authData!.token)
    );
  };

  const axiosGetTipKontaktaOrganizacionaJedinicaList = (tipKontaktaId: number) => {
    return axios.get(`${Endpoint.TIP_KONTAKTA_LIST}/${tipKontaktaId}/organizaciona-jedinica-list`, axiosConfig(authData!.token));
  };

  const axiosDeleteTipKontaktaOrganizacionaJedinica = (tipKontaktaId: number, organizacionaJedinicaId: number) => {
    return axios.delete(`${Endpoint.TIP_KONTAKTA_LIST}/${tipKontaktaId}/organizaciona-jedinica-list/${organizacionaJedinicaId}`, axiosConfig(authData!.token));
  };

  const axiosGetInicijalizacijaTipKontaktaList = () => {
    return axios.get(Endpoint.TIP_KONTAKTA_LIST_INICIJALIZACIJA, axiosConfig(authData!.token));
  };

  const axiosCopyKontaktTipWithOrganizacionaJedinica = (kontaktTipRepositoryUstanovaInitCopyDto: KontaktTipRepositoryUstanovaInitCopyDto) => {
    return axios.post(`${Endpoint.TIP_KONTAKTA_LIST}/copyPublicWithOrganizacionaJedinica`, kontaktTipRepositoryUstanovaInitCopyDto, axiosConfig(authData!.token));
  };

  return {
    axiosSearchTipKontakta,
    axiosCreateTipKontakta,
    axiosUpdateTipKontakta,
    axiosDeleteTipKontakta,
    axiosGetTipKontakta,
    axiosCreateTipKontaktaOrganizacionaJedinica,
    axiosGetTipKontaktaOrganizacionaJedinicaList,
    axiosDeleteTipKontaktaOrganizacionaJedinica,
    axiosGetInicijalizacijaTipKontaktaList,
    axiosCopyKontaktTipWithOrganizacionaJedinica,
  };
}
