import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import IzvestajParametarEnumStavkaUpdateDto from "../../model/izvestaj/parametar/IzvestajParametarEnumStavkaUpdateDto";
import IzvestajParametarReadDto from "../../model/izvestaj/parametar/IzvestajParametarReadDto";

interface IzvestajParametarControllerType {
  axiosGetIzvestajParametarList: (izvestajId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateIzvestajParametar: (izvestajParametar: IzvestajParametarReadDto) => Promise<AxiosResponse<any>>;
  axiosGetIzvestajParametar: (izvestajParametarId: number) => Promise<AxiosResponse<any>>;
  axiosGetIzvestajParametarEnumStavkaList: (izvestajParametarId: number) => Promise<AxiosResponse<any>>;
  axiosGetIzvestajParametarEnumStavka: (izvestajParametarId: number, izvestajParametarEnumStavkaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateIzvestajParametarEnumStavka: (
    izvestajParametarEnumStavka: IzvestajParametarEnumStavkaUpdateDto,
    izvestajParametarId: number,
    izvestajParametarEnumStavkaId: number
  ) => Promise<AxiosResponse<any>>;
}

export function IzvestajParametarController(): IzvestajParametarControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetIzvestajParametarList = (izvestajId: number) => {
    return axios.get(`${Endpoint.IZVESTAJ_LIST}/${izvestajId}/izvestaj-parametar-list`, axiosConfig(authData!.token, izvestajId));
  };

  const axiosUpdateIzvestajParametar = (izvestajParametar: IzvestajParametarReadDto) => {
    return axios.put(`${Endpoint.IZVESTAJ_PARAMETAR_LIST}/${izvestajParametar.id}`, izvestajParametar, axiosConfig(authData!.token));
  };

  const axiosGetIzvestajParametar = (izvestajParametarId: number) => {
    return axios.get(`${Endpoint.IZVESTAJ_PARAMETAR_LIST}/${izvestajParametarId}`, axiosConfig(authData!.token));
  };

  const axiosGetIzvestajParametarEnumStavkaList = (izvestajParametarId: number) => {
    return axios.get(`${Endpoint.IZVESTAJ_PARAMETAR_LIST}/${izvestajParametarId}/izvestaj-parametar-enum-stavka-list`, axiosConfig(authData!.token));
  };

  const axiosGetIzvestajParametarEnumStavka = (izvestajParametarId: number, izvestajParametarEnumStavkaId: number) => {
    return axios.get(`${Endpoint.IZVESTAJ_PARAMETAR_LIST}/${izvestajParametarId}/izvestaj-parametar-enum-stavka-list/${izvestajParametarEnumStavkaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateIzvestajParametarEnumStavka = (izvestajParametarEnumStavka: IzvestajParametarEnumStavkaUpdateDto, izvestajParametarId: number, izvestajParametarEnumStavkaId: number) => {
    return axios.put(
      `${Endpoint.IZVESTAJ_PARAMETAR_LIST}/${izvestajParametarId}/izvestaj-parametar-enum-stavka-list/${izvestajParametarEnumStavkaId}`,
      izvestajParametarEnumStavka,
      axiosConfig(authData!.token)
    );
  };

  return {
    axiosGetIzvestajParametarList,
    axiosUpdateIzvestajParametar,
    axiosGetIzvestajParametar,
    axiosGetIzvestajParametarEnumStavkaList,
    axiosGetIzvestajParametarEnumStavka,
    axiosUpdateIzvestajParametarEnumStavka,
  };
}
