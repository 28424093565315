import { KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import PorukeController from "../../../controllers/poruke/PorukeController";
import { DATE_TIME_FORMAT, formatDate2, handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import PorukaCreateDto from "../../../model/poruke/PorukaCreateDto";
import PorukaReadDto from "../../../model/poruke/PorukaReadDto";
import PoslataPorukaReadDto from "../../../model/poruke/PoslataPorukaReadDto";
import { AppContext, useLabels } from "./../../../Store";

interface PoslataPorukaListLogicalType {
  fetchData: () => void;
  poslataPorukaList: Array<PoslataPorukaReadDto>;
  porukaSearchString: string;
  setPorukaSearchString: React.Dispatch<React.SetStateAction<string>>;
  searchPorukaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  poruka: PorukaReadDto | undefined;
  setPoruka: React.Dispatch<React.SetStateAction<PorukaReadDto | undefined>>;
  handlePorukaClick: (poruka: PoslataPorukaReadDto) => void;
  hasMoreMessages: React.MutableRefObject<boolean>;
  searchPoruka: () => void;
  handleDelete: () => void;
  handleForward: () => void;
}

export interface PoslataPorukaListLogicalParams {
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  setAttachmentList: React.Dispatch<React.SetStateAction<Array<any>>>;
  setPorukaChange: React.Dispatch<React.SetStateAction<PorukaCreateDto | undefined>>;
  setRecepients: React.Dispatch<React.SetStateAction<Array<any>>>;
  setAdded: React.Dispatch<React.SetStateAction<number[]>>;
  refreshPoruke: number;
  setRefreshPoruke: React.Dispatch<React.SetStateAction<number>>;
}

export default function PoslataPorukaListLogical(params: PoslataPorukaListLogicalParams): PoslataPorukaListLogicalType {
  const { setIndex, setAttachmentList, setPorukaChange, setRecepients, setAdded, refreshPoruke, setRefreshPoruke } = params;

  const Labels = useLabels();

  const { showMessage } = useContext(AppContext);
  const { axiosGetPoslataPorukaList, axiosDeletePoslataPoruka, axiosGetPoruka, axiosGetPorukaDatotekaList } = PorukeController();
  const [poslataPorukaList, setPoslataPorukaList] = useState<Array<PoslataPorukaReadDto>>([]);
  const page = useRef(0);
  const hasMoreMessages = useRef(true);
  const [porukaSearchString, setPorukaSearchString] = useState<string>("");
  const [porukaSearchStringOld, setPorukaSearchStringOld] = useState<string>("");
  const [poruka, setPoruka] = useState<PorukaReadDto>();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const searchParams = { page: page.current, size: 20, searchString: porukaSearchString };
    axiosGetPoslataPorukaList(searchParams)
      .then((res: any) => {
        res.data.data.length === 0 ? (hasMoreMessages.current = false) : (hasMoreMessages.current = true);
        page.current === 0 ? setPoslataPorukaList(res.data.data) : setPoslataPorukaList((prevList) => prevList.concat(res.data.data));
        page.current++;
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  useEffect(() => {
    if (refreshPoruke && refreshPoruke > 0) {
      page.current = 0;
      fetchData();
      setRefreshPoruke(0);
    }
    // eslint-disable-next-line
  }, [refreshPoruke, setRefreshPoruke]);

  const searchPoruka = () => {
    if (porukaSearchString !== porukaSearchStringOld) page.current = 0;
    fetchData();
    setPorukaSearchStringOld(porukaSearchString);
  };

  const searchPorukaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      if (porukaSearchString !== porukaSearchStringOld) page.current = 0;
      fetchData();
      setPorukaSearchStringOld(porukaSearchString);
    }
  };

  const handlePorukaClick = (poruka: PoslataPorukaReadDto) => {
    axiosGetPoruka(poruka.id)
      .then((res) => {
        setPoruka(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const handleDelete = () => {
    if (poruka) {
      axiosDeletePoslataPoruka(poruka?.id)
        .then(() => {
          setPoslataPorukaList((prevPorukaList) => prevPorukaList.filter((p) => p.id !== poruka.id));
          setPoruka(undefined);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const handleForward = () => {
    const sadrzaj = poruka?.sadrzaj
      ? `<br><br>
      <b>${Labels.POCETAK_PROSLEDJENE_PORUKE}</b><br>
        <b>${Labels.PORUKA_POSILJALAC}</b> ${poruka.radnikKreirao?.titula ?? ""} ${poruka.radnikKreirao.ime} ${poruka.radnikKreirao.prezime}<br>
        <b>${Labels.PORUKE_NASLOV}:</b> ${poruka.naslov}<br>
        <b>${Labels.PORUKA_DATUM}:</b> ${formatDate2(poruka.vremeKreiranja, DATE_TIME_FORMAT)}<br>
        <b>${poruka?.porukaRadnikList.length === 1 ? Labels.PORUKA_PRIMALAC : Labels.PORUKA_PRIMAOCI}</b> ${poruka?.porukaRadnikList
          .map((primalac) => `${primalac?.radnik.titula ?? ""} ${primalac?.radnik.ime} ${primalac?.radnik.prezime}`)
          .join(", ")}<br><br>
          ${poruka.sadrzaj}`
      : "";
    setPorukaChange({
      naslov: `FWD: ${poruka?.naslov}`,
      sadrzaj: sadrzaj,
      porukaRadnikList: [],
      porukaDatotekaList: [],
    });
    setIndex(0);
    if (poruka?.porukaDatotekaList) {
      axiosGetPorukaDatotekaList(poruka?.porukaDatotekaList.map((porukaDatoteka) => porukaDatoteka.id).join(","))
        .then((res: any) => {
          const updatedPorukaDatotekaList = poruka.porukaDatotekaList.map((porukaDatoteka) => ({
            ...porukaDatoteka,
            sadrzaj: res.data.data.find((pd: any) => pd.idNotificationPorukaDatoteka === porukaDatoteka.id).sadrzaj,
          }));
          setPorukaChange((prevPorukaChange) => ({ ...prevPorukaChange!, porukaDatotekaList: updatedPorukaDatotekaList }));
          setAttachmentList(updatedPorukaDatotekaList);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    } else {
      setAttachmentList([]);
    }
    setRecepients([]);
    setAdded([]);
  };

  return {
    fetchData,
    poslataPorukaList,
    porukaSearchString,
    setPorukaSearchString,
    searchPorukaByEnter,
    poruka,
    setPoruka,
    handlePorukaClick,
    hasMoreMessages,
    searchPoruka,
    handleDelete,
    handleForward,
  };
}
