import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import KontaktProtokolBrojRequestReadDto from "../../model/kontakt/KontaktProtokolBrojRequestReadDto";
import ProtokolReadDto from "../../model/protokol/ProtokolReadDto";

interface ProtokolControlerType {
  axiosSearchProtokol: (searchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetProtokol: (protokolID: number) => Promise<AxiosResponse<any>>;
  axiosCreateProtokol: (protokol: ProtokolReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteProtokol: (protokolID: number) => Promise<AxiosResponse<any>>;
  axiosUpdateProtokol: (protokol: ProtokolReadDto) => Promise<AxiosResponse<any>>;
  axiosGetProtokolList: (ustanovaID: number) => Promise<AxiosResponse<any>>;
  axiosGetKontaktBrojProtokolaList: (kontaktProtokolBrojRq: KontaktProtokolBrojRequestReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteProtokolBroj: (protokolBrojID: number) => Promise<AxiosResponse<any>>;
}

export default function ProtokolControler(): ProtokolControlerType {
  const { authData } = useContext(AppContext);

  const axiosSearchProtokol = (searchData?: SearchBaseDto) => {
    return axios.get(Endpoint.PROTOKOL_SEARCH, axiosConfig(authData!.token, searchData));
  };

  const axiosGetProtokol = (protokolID: number) => {
    return axios.get(`${Endpoint.PROTOKOL_LIST}/${protokolID}`, axiosConfig(authData!.token));
  };

  const axiosCreateProtokol = (protokol: ProtokolReadDto) => {
    return axios.post(Endpoint.PROTOKOL_LIST, protokol, axiosConfig(authData!.token));
  };

  const axiosDeleteProtokol = (protokolID: number) => {
    return axios.delete(`${Endpoint.PROTOKOL_LIST}/${protokolID}`, axiosConfig(authData!.token));
  };

  const axiosUpdateProtokol = (protokol: ProtokolReadDto) => {
    return axios.put(`${Endpoint.PROTOKOL_LIST}/${protokol.id}`, protokol, axiosConfig(authData!.token));
  };

  const axiosGetProtokolList = (ustanovaID: number) => {
    return axios.get(`${Endpoint.PROTOKOL_LIST}/ustanova/${ustanovaID}`, axiosConfig(authData!.token));
  };

  const axiosGetKontaktBrojProtokolaList = (kontaktProtokolBrojRq: KontaktProtokolBrojRequestReadDto) => {
    return axios.get(`${Endpoint.PROTOKOL_LIST}/protokol-broj-administracija`, axiosConfig(authData!.token, kontaktProtokolBrojRq));
  };

  const axiosDeleteProtokolBroj = (protokolBrojID: number) => {
    return axios.delete(`${Endpoint.PROTOKOL_BROJ_LIST}/${protokolBrojID}`, axiosConfig(authData!.token));
  };

  return {
    axiosSearchProtokol,
    axiosGetProtokol,
    axiosCreateProtokol,
    axiosDeleteProtokol,
    axiosUpdateProtokol,
    axiosGetProtokolList,
    axiosGetKontaktBrojProtokolaList,
    axiosDeleteProtokolBroj,
  };
}
