import { useState } from "react";
import { useLocation } from "react-router-dom";

interface UstanovaLicenceLogicalType {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  tabIndex: number;
}

export default function UstanovaLicenceLogical(): UstanovaLicenceLogicalType {
  const location = useLocation();
  const [tabIndex] = useState(location.state && location.state.licenceTabIndex !== undefined ? location.state.licenceTabIndex : 0);
  const [index, setIndex] = useState(tabIndex);

  return {
    index,
    setIndex,
    tabIndex,
  };
}
