import { DataTablePageEvent } from "primereact/datatable";
import { useState } from "react";

export function useTableData() {
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);

  const onPage = (e: DataTablePageEvent) => {
    setTableRows(e.rows);
    setFirst(e.first);
  };

  return { first, tableRows, onPage };
}
