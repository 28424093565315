import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import VerzijaCreateDto from "../../model/verzija/VerzijaCreateDto";
import VerzijaReadDto from "../../model/verzija/VerzijaReadDto";
import VerzijaUpdateDto from "../../model/verzija/VerzijaUpdateDto";
import { AppContext } from "./../../Store";
import VerzijaStavkaUpdateDto from "../../model/verzija/VerzijaStavkaUpdateDto";
import VerzijaStavkaCreateDto from "../../model/verzija/VerzijaStavkaCreateDto";
import VerzijaStavkaReadDto from "../../model/verzija/VerzijaStavkaReadDto";

interface VerzijaControllerType {
  axiosCreateVerzija: (verzija: VerzijaCreateDto) => Promise<AxiosResponse<VerzijaReadDto>>;
  axiosGetVerzijaList: () => Promise<AxiosResponse<VerzijaReadDto[]>>;
  axiosGetVerzija: (id: number) => Promise<AxiosResponse<VerzijaReadDto>>;
  axiosUpdateVerzija: (verzija: VerzijaUpdateDto) => Promise<AxiosResponse<VerzijaReadDto[]>>;
  axiosDeleteVerzija: (id: number) => Promise<AxiosResponse<void>>;
  axiosCreateVerzijaStavka: (verzijaId: number, verzijaStavka: VerzijaStavkaCreateDto) => Promise<AxiosResponse<VerzijaStavkaReadDto>>;
  axiosUpdateVerzijaStavka: (verzijaId: number, stavkaId: number, verzijaStavka: VerzijaStavkaUpdateDto) => Promise<AxiosResponse<VerzijaStavkaReadDto>>;
  axiosDeleteVerzijaStavka: (verzijaId: number, stavkaId: number) => Promise<AxiosResponse<void>>;
  axiosGetPublicVerzijaList: () => Promise<AxiosResponse<VerzijaReadDto[]>>;
}

export function VerzijaController(): VerzijaControllerType {
  const { authData } = useContext(AppContext);

  const axiosCreateVerzija = (verzija: VerzijaCreateDto) => {
    return axios.post(Endpoint.VERZIJE, verzija, axiosConfig(authData!.token));
  };

  const axiosGetVerzijaList = () => {
    return axios.get(Endpoint.VERZIJE, axiosConfig(authData!.token));
  };

  const axiosGetVerzija = (id: number) => {
    return axios.get(`${Endpoint.VERZIJE}/${id}`, axiosConfig(authData!.token));
  };

  const axiosUpdateVerzija = (verzija: VerzijaUpdateDto) => {
    return axios.put(`${Endpoint.VERZIJE}/${verzija.id}`, verzija, axiosConfig(authData!.token));
  };

  const axiosDeleteVerzija = (id: number) => {
    return axios.delete(`${Endpoint.VERZIJE}/${id}`, axiosConfig(authData!.token));
  };

  const axiosCreateVerzijaStavka = (verzijaId: number, verzijaStavka: VerzijaStavkaCreateDto) => {
    return axios.post(`${Endpoint.VERZIJE}/${verzijaId}/stavke`, verzijaStavka, axiosConfig(authData!.token));
  };

  const axiosUpdateVerzijaStavka = (verzijaId: number, stavkaId: number, verzijaStavka: VerzijaStavkaUpdateDto) => {
    return axios.put(`${Endpoint.VERZIJE}/${verzijaId}/stavke/${stavkaId}`, verzijaStavka, axiosConfig(authData!.token));
  };

  const axiosDeleteVerzijaStavka = (verzijaId: number, stavkaId: number) => {
    return axios.delete(`${Endpoint.VERZIJE}/${verzijaId}/stavke/${stavkaId}`, axiosConfig(authData!.token));
  };

  const axiosGetPublicVerzijaList = () => {
    return axios.get(`${Endpoint.VERZIJE}/public`, axiosConfig(authData!.token));
  };

  return {
    axiosCreateVerzija,
    axiosGetVerzijaList,
    axiosUpdateVerzija,
    axiosDeleteVerzija,
    axiosGetVerzija,
    axiosCreateVerzijaStavka,
    axiosUpdateVerzijaStavka,
    axiosDeleteVerzijaStavka,
    axiosGetPublicVerzijaList,
  };
}
