import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../Store";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import UlogaReadDto from "../../../../model/uloga/UlogaReadDto";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";

interface UstanovaUlogaListLogicalType {
  ulogaList: Array<UlogaReadDto>;
  dt: any;
  first: number;
  tableRows: number;
  onPage: (rows: number, first: number) => void;
  openUloga: (entityOperation: string, id?: number) => void;
  ulogaListLoading: boolean;
}

interface UseParamsType {
  ustanovaId?: string;
}
interface ustanovaUlogaListLogicalProps {
  ustanova?: UstanovaReadDto;
}
export default function UstanovaUlogaListLogical(props: ustanovaUlogaListLogicalProps): UstanovaUlogaListLogicalType {
  const { ustanova } = props;
  const { axiosSuperadminUstanovaGetUlogaList } = SuperadminController();
  const { ustanovaId }: UseParamsType = useParams<any>();
  const { showMessage } = useContext(AppContext);
  const [ulogaList, setUlogaList] = useState<Array<UlogaReadDto>>([]);
  const dt = useRef<any>(null);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const navigate = useNavigate();
  const [ulogaListLoading, setUlogaListLoading] = useState<boolean>(true);

  useEffectOnce(() => {
    let startTime = moment(new Date());
    setUlogaListLoading(true);
    axiosSuperadminUstanovaGetUlogaList(Number(ustanovaId))
      .then(({ data: { data } }: { data: { data: Array<UlogaReadDto> } }) => {
        setUlogaList(data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setUlogaListLoading, startTime);
      });
  });

  const onPage = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const openUloga = (entityOperation: string, id?: number) => {
    const ulogaId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-ustanova/${ustanovaId}/crud-uloga/${ulogaId}`, {
      state: { ulogaOperation: entityOperation, ustanovaNaziv: ustanova?.naziv, from: "ustanova" },
    });
  };

  return {
    ulogaList,
    dt,
    first,
    tableRows,
    onPage,
    openUloga,
    ulogaListLoading,
  };
}
