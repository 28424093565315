import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { ZakazaniTerminController } from "../../../../controllers/zakazani-termin/ZakazaniTerminController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";
import { handleAxiosCallError, handleAxiosCallSuccess } from "../../../../infrastructure/system/Utils";
import KomentarCreateDto from "../../../../model/zakazan-termin/komentari/KomentarCreateDto";

export function useCreateKomentarForZakazanTermin(zakazanTerminId?: number) {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosPostCommentForZakazaniTermin } = ZakazaniTerminController();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (komentar: KomentarCreateDto) => {
      return axiosPostCommentForZakazaniTermin(zakazanTerminId!, komentar);
    },
    onSuccess: () => {
      handleAxiosCallSuccess(showMessage, Labels.ZAKAZIVANJE_PREGLEDA_ADD_COMMENT_SUCCESS_MESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ZAKAZAN_TERMIN_KOMENTAR_LIST, { zakazanTerminId }] });
    },
    onError: (err) => {
      handleAxiosCallError(showMessage, err);
    },
  });
}
