import { useQuery } from "@tanstack/react-query";
import { UstanovaController } from "../../../../controllers/ustanova/UstanovaController";
import QueryKeys from "../../QueryKeys";
import UstanovaGridRadniDanReadDto from "../../../../model/ustanova/UstanovaGridRadniDanReadDto";

export function useUstanovaGridRadniDan() {
  const { axiosGetUstanovaGrid } = UstanovaController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.USTANOVA_GRID],
    queryFn: () => axiosGetUstanovaGrid(),
  });

  const ustanovaGridRadniDan: any = data?.data?.data?.ustanovaGridRadniDanList;
  let sortedUstanovaRadniDanList = ustanovaGridRadniDan?.sort((a: UstanovaGridRadniDanReadDto, b: UstanovaGridRadniDanReadDto) => {
    return a.radniDan.prikazniRedosled - b.radniDan.prikazniRedosled;
  });

  let radniDaniGrid = sortedUstanovaRadniDanList?.map((ustanovaGridRadniDan: UstanovaGridRadniDanReadDto) => {
    return ustanovaGridRadniDan.radniDan;
  });

  return { ustanovaGridRadniDan, refetch, isLoading, radniDaniGrid };
}
