import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toolbar } from "primereact/toolbar";
import { useEffect, useRef } from "react";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";
import UstanovaBrisanjeLogical from "./UstanovaBrisanjeLogical";
import UstanovaBrisanjeDialog from "./dialog/UstanovaBrisanjeDialog";
import { useLabels } from "../../../../Store";

interface UstanovaViewType {
  ustanovaId?: number;
  ustanova?: UstanovaReadDto;
  isDisabled: boolean;
}

export default function UstanovaBrisanjeView(props: UstanovaViewType) {
  const { ustanovaId, ustanova, isDisabled } = props;
  const Labels = useLabels();
  const { ustanovaEntityCountList, openDeleteDialog, closeDeleteDialog, state, dispatch, onDelete, brisanjeList } = UstanovaBrisanjeLogical({
    ustanovaId: ustanovaId,
    ustanova: ustanova,
  });

  const scrollerRef = useRef<any>();

  useEffect(() => {
    if (state.brisanjeZapoceto) {
      scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
    }
    // eslint-disable-next-line
  }, [brisanjeList]);

  const previewItems = (
    <div className="previewItems flex flex-column hc-gap-3">
      <div className="flex justify-content-between align-items-center p-0">
        <div>{Labels.USTANOVA_BRISANJE_LABEL_BROJ_RADNIKA}:</div>
        <div>{ustanovaEntityCountList?.radnikLength}</div>
      </div>
      <div className="flex justify-content-between align-items-center p-0">
        <div>{Labels.USTANOVA_BRISANJE_LABEL_BROJ_ORGANIZACIONIH_JEDINICA}:</div>
        <div>{ustanovaEntityCountList?.organizacionaJedinicaLength}</div>
      </div>
      <div className="flex justify-content-between align-items-center p-0">
        <div>{Labels.USTANOVA_BRISANJE_LABEL_BROJ_ZAKAZNIH_TERMINA}:</div>
        <div>{ustanovaEntityCountList?.zakazaniTerminiLength}</div>
      </div>
      <div className="flex justify-content-between align-items-center p-0">
        <div>{Labels.USTANOVA_BRISANJE_LABEL_BROJ_KONTAKATA}:</div>
        <div>{ustanovaEntityCountList?.kontaktLength}</div>
      </div>
      <div className="flex justify-content-between align-items-center p-0">
        <div>{Labels.USTANOVA_BRISANJE_LABEL_BROJ_SNIMLJENIH_DIJAGNOZA}:</div>
        <div>{ustanovaEntityCountList?.dijagnozaLength}</div>
      </div>
      <div className="flex justify-content-between align-items-center p-0">
        <div>{Labels.USTANOVA_BRISANJE_LABEL_BROJ_SNIMLJENIH_FORMULARA}:</div>
        <div>{ustanovaEntityCountList?.formularLength}</div>
      </div>
    </div>
  );

  const itemsBeingDeleted = brisanjeList.map((entitet) => (
    <div className="flex justify-content-between p-0 py-2" key={entitet.id}>
      <div>{entitet.message}</div>
      <div className="flex align-items-center">
        {entitet.finished ? (
          <div className="ml-3 inline-flex align-items-center">
            <i className="pi pi-check-circle mr-3 deleting-finished-icon text-primary"></i>
          </div>
        ) : (
          <div className="ml-3 inline-flex align-items-center">
            <ProgressSpinner className="w-1rem h-1rem mr-3" strokeWidth="4" animationDuration="2s" />
          </div>
        )}
      </div>
    </div>
  ));

  const leftContentsToolbar = () => <Button label={Labels.BUTTON_DELETE} disabled={isDisabled} icon="pi pi-trash" className="p-button-danger" onClick={openDeleteDialog} />;

  return (
    <div className="ustanova-brisanje-layout h-auto">
      <div className="font-bold my-3">{Labels.USTANOVA_BRISANJE_PODACI_O_USTANOVI}:</div>
      <div className="pb-5">
        {state.brisanjeZapoceto ? (
          <div className="scrollable-delete-wrapper pl-3 font-semibold" ref={scrollerRef}>
            {itemsBeingDeleted}
          </div>
        ) : (
          previewItems
        )}
      </div>
      {!state.brisanjeZapoceto && <Toolbar left={leftContentsToolbar} />}
      <UstanovaBrisanjeDialog
        isOpenDeleteDialog={state.isDialogOpen}
        closeDeleteDialog={closeDeleteDialog}
        page={state.page}
        checked={state.isCheckedBrisanje}
        dispatch={dispatch}
        onDelete={onDelete}
      />
    </div>
  );
}
