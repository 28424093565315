import { PickList } from "primereact/picklist";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import SkeletonPickListItem from "../../../app/skeleton/SkeletonPickListItem";
import CrudUlogaRadnikLogical, { UlogaRadnikHolder } from "./CrudUlogaRadnikLogical";
interface CrudUlogaRadnikViewPropType {
  isDisabled: boolean;
  ustanovaId?: number;
  ulogaNaziv?: string;
}
export default function CrudUlogaRadnik(props: CrudUlogaRadnikViewPropType) {
  const { isDisabled, ustanovaId, ulogaNaziv } = props;
  const { ulogaRadnikList, radnikList, onChangeUlogaRadnik, isBlocked, isLoading } = CrudUlogaRadnikLogical({ ustanovaId: ustanovaId, ulogaNaziv: ulogaNaziv });
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const itemTemplate = (item: UlogaRadnikHolder | undefined) => {
    if (item === undefined || item === null || isBlocked) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{`${item.radnik?.ime} ${item.radnik?.prezime} - ${item.radnik?.username}`}</h6>;
  };
  return (
    <div className={"layout-generic-content-list"}>
      <div className={!pristup || isDisabled || isBlocked || isLoading ? "read-only" : ""}>
        <PickList
          sourceHeader={Labels.RADNIK_LIST}
          targetHeader={Labels.LABEL_ULOGA_RADNIK_LISTA}
          source={radnikList}
          target={ulogaRadnikList}
          itemTemplate={itemTemplate}
          onChange={onChangeUlogaRadnik}
          showSourceControls={false}
          showTargetControls={false}
        ></PickList>
      </div>
    </div>
  );
}
