import { BreadCrumb } from "primereact/breadcrumb";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { useLabels } from "../../../Store";
import ProtokolLogical from "./ProtokolLogical";
import ProtokolBrojListPage from "./administracija-brojeva/ProtokolBrojListView";
import ProtokolListPage from "./administracija-knjiga/ProtokolListView";

export default function ProtokolView() {
  const { breadCrumbItems, index, setIndex } = ProtokolLogical();

  const Labels = useLabels();

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />

        <TabView
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.PROTOKOL_ADMINISTRIRANJE} key={1}>
            <ProtokolListPage />
          </TabPanel>
          <TabPanel header={Labels.PROTOKOL_ADMINISTRACIJA_BROJEVA} key={2}>
            <ProtokolBrojListPage />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
