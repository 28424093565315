import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import { RefObject, useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../../Store";
import MessageType from "../../../../infrastructure/system/MessageType";
import { MAX_FILE_UPLOAD } from "../../../../infrastructure/system/Utils";
import ZakazanTerminReadDto from "../../../../model/zakazan-termin/ZakazanTerminReadDto";
import ZakazanTerminDatotekaCreateDto from "../../../../model/zakazan-termin/datoteke/ZakazanTerminDatotekaCreateDto";
import ZakazanTerminDatotekaReadDto from "../../../../model/zakazan-termin/datoteke/ZakazanTerminDatotekaReadDto";
import { useCreateDatotekaForZakazanTermin } from "../hooks/useCreateDatotekaForZakazanTermin";
import { ZakazivanjeDatoteka } from "./ZakazivanjeDatoteka";

interface ZakazivanjeDatotekaSectionProps {
  zakazanTermin?: ZakazanTerminReadDto;
  filesRef: RefObject<HTMLDivElement>;
  datotekaList: ZakazanTerminDatotekaReadDto[];
}

export const ZakazivanjeDatotekaSection = ({ zakazanTermin, filesRef, datotekaList }: ZakazivanjeDatotekaSectionProps) => {
  const { setShowBlockUI, showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const documentUploadRef = useRef<FileUpload>(null);
  const cancelOptions = { className: "class-visibility-hidden" };

  const createDatotekaMutation = useCreateDatotekaForZakazanTermin(zakazanTermin?.id);

  const documentUploadHandler = (e: FileUploadHandlerEvent) => {
    setShowBlockUI(true);
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }
        if (reader.result && zakazanTermin?.id && zakazanTermin?.eksterniCaseID) {
          let datotekaTermin: ZakazanTerminDatotekaCreateDto = {
            ustanovaID: undefined,
            zakazanTerminID: zakazanTermin.id,
            eksterniZakazanTerminDatotekaID: null,
            sadrzaj: reader.result.toString().substr(reader.result.toString().indexOf(",") + 1),
            naziv: file.name,
            tip: file.type,
            velicina: file.size,
          };
          createDatotekaMutation.mutate(datotekaTermin);
        }
      };
    });
    setShowBlockUI(false);
  };

  const addFiles = () => {
    return (
      <div className="w-full h-full">
        <span
          className="orange p-0 font-bold cursor-pointer block w-full h-full"
          onClick={() => {
            documentUploadRef.current?.getInput().click();
          }}
        ></span>
      </div>
    );
  };

  return (
    <div>
      <h5 className="mb-4" ref={filesRef}>
        {Labels.ZAKAZIVANJE_DATOTEKE}
      </h5>
      <div className="mb-4">
        <FileUpload
          ref={documentUploadRef}
          auto
          mode="advanced"
          cancelOptions={cancelOptions}
          multiple={true}
          emptyTemplate={addFiles}
          customUpload
          uploadHandler={(e) => {
            documentUploadHandler(e);
            documentUploadRef.current?.clear();
          }}
          chooseOptions={{ className: "terminDatotekeUpload py-6", label: Labels.ZAKAZIVANJE_PREGLEDA_ADD_FILE_BUTTON_LABEL }}
          className="terminDatotekeUploadWrapper"
        />
      </div>
      {datotekaList?.map((datoteka, index) => <ZakazivanjeDatoteka key={index} datoteka={datoteka} zakazanTermin={zakazanTermin} />)}
    </div>
  );
};
