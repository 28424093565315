import classNames from "classnames";
import { camelize } from "../Utils";

/**
 * Metoda za generisanje menu linka sa href atributom,
 * po defaultu se i dalje radi redirect sa history pushom,
 * ali user sada ima mogucnost da desnim klikom otvori zelejnu stranu u novom tabu
 */
export const linkJSX = (item: any, options: any, href: string) => {
  return (
    <a href={`${href}`} role="menuitem" className={options.className} target={item.target} onClick={options.onClick} data-cy={camelize(item.label)}>
      {typeof item.icon !== "string" && item.icon}
      <span className={classNames(options.iconClassName)}></span>
      <span className="p-menuitem-text">{item.label}</span>
    </a>
  );
};
