import { useMemo } from "react";

export type LabelsType = ReturnType<typeof LabelsContext>["Labels"];

export default function LabelsContext(t: (scope: any, options?: any) => string = () => "") {
  const Labels = useMemo(() => {
    return {
      SR_RS_LATN: t("SR_RS_LATN"),
      SR_RS: t("SR_RS"),
      NEW_LINE: t("NEW_LINE"),
      TAB: t("TAB"),
      SPACE: t("SPACE"),
      DASH: t("DASH"),
      SLASH: t("SLASH"),
      TWO_DOT: t("TWO_DOT"),
      DOT_SIGN: t("DOT_SIGN"),

      // Special strings
      SPECIAL_CHAR_REQUIRED: t("SPECIAL_CHAR_REQUIRED"),
      SPECIAL_CHAR_COLON: t("SPECIAL_CHAR_COLON"),

      // Application
      APPLICATION_NAME: t("APPLICATION_NAME"),

      // Common
      COPYRIGHT: t("COPYRIGHT"),
      HELIANT: t("HELIANT"),
      LOGIN: t("LOGIN"),
      FORGOTTEN_PASSWORD: t("FORGOTTEN_PASSWORD"),
      TOKEN_EXPIRED_LABEL: t("TOKEN_EXPIRED_LABEL"),

      // Title labels
      TITLE_LOGIN: t("TITLE_LOGIN"),
      TITLE_RESET_PASSWORD: t("TITLE_RESET_PASSWORD"),
      TITLE_SET_NEW_PASSWORD: t("TITLE_SET_NEW_PASSWORD"),

      // Menu labels
      MENU_ADMIN: t("MENU_ADMIN"),
      MENU_SUPERADMIN: t("MENU_SUPERADMIN"),
      MENU_PATIENT_SEARCH: t("MENU_PATIENT_SEARCH"),
      MENU_IZVESTAJI: t("MENU_IZVESTAJI"),

      // Button labels
      BUTTON_CREATE: t("BUTTON_CREATE"),
      BUTTON_CHANGE: t("BUTTON_CHANGE"),
      BUTTON_UPDATE: t("BUTTON_UPDATE"),
      BUTTON_DETAILS: t("BUTTON_DETAILS"),
      BUTTON_LOGOUT: t("BUTTON_LOGOUT"),
      BUTTON_SAVE: t("BUTTON_SAVE"),
      BUTTON_CONFIRM: t("BUTTON_CONFIRM"),
      BUTTON_CANCEL: t("BUTTON_CANCEL"),
      BUTTON_DELETE: t("BUTTON_DELETE"),
      BUTTON_DELETE_DATA: t("BUTTON_DELETE_DATA"),
      BUTTON_UPLOAD_IMAGE: t("BUTTON_UPLOAD_IMAGE"),
      BUTTON_RESET_PASSWORD: t("BUTTON_RESET_PASSWORD"),
      BUTTON_ADD_ORGANIZACIONA_JEDINICA: t("BUTTON_ADD_ORGANIZACIONA_JEDINICA"),
      BUTTON_ADD_USTANOVA: t("BUTTON_ADD_USTANOVA"),
      BUTTON_ADD_RADNIK: t("BUTTON_ADD_RADNIK"),
      BUTTON_ADD_RADNIK_OTHER: t("BUTTON_ADD_RADNIK_OTHER"),
      BUTTON_ADD_TIP_KONTAKTA: t("BUTTON_ADD_TIP_KONTAKTA"),
      BUTTON_ADD_ULOGA: t("BUTTON_ADD_ULOGA"),
      BUTTON_ADD_FORMULAR: t("BUTTON_ADD_FORMULAR"),
      BUTTON_ADD_PACIJENT_POLJE: t("BUTTON_ADD_PACIJENT_POLJE"),
      BUTTON_ADD_PACIJENT_POLJE_KATEGORIJA: t("BUTTON_ADD_PACIJENT_POLJE_KATEGORIJA"),
      BUTTON_YES: t("BUTTON_YES"),
      BUTTON_NO: t("BUTTON_NO"),
      BUTTON_CLOSE: t("BUTTON_CLOSE"),
      BUTTON_REPOSITORY: t("BUTTON_REPOSITORY"),
      BUTTON_OVERWRITE: t("BUTTON_OVERWRITE"),
      BUTTON_LOAD_MORE: t("BUTTON_LOAD_MORE"),
      BUTTON_ADD_OBAVESTENJE: t("BUTTON_ADD_OBAVESTENJE"),
      BUTTON_ADD_IZVESTAJ_PARAMETAR: t("BUTTON_ADD_IZVESTAJ_PARAMETAR"),
      BUTTON_ADD_USTANOVA_DODATNI_MODUL: t("BUTTON_ADD_USTANOVA_DODATNI_MODUL"),
      BUTTON_ADD_PACIJENT: t("BUTTON_ADD_PACIJENT"),
      BUTTON_LOG_OUT: t("BUTTON_LOG_OUT"),

      DIJAGNOZA: t("DIJAGNOZA"),
      DIJAGNOZA_LIST_TOKOM_KONTKAKTA: t("DIJAGNOZA_LIST_TOKOM_KONTKAKTA"),
      DIJAGNOZA_LIST_IZ_PRETHODNIH_PREGLEDA: t("DIJAGNOZA_LIST_IZ_PRETHODNIH_PREGLEDA"),
      DIJAGNOZA_POSTAVLJENA: t("DIJAGNOZA_POSTAVLJENA"),
      DIJAGNOZA_STATUS: t("DIJAGNOZA_STATUS"),
      DIJAGNOZA_NAPOMENA: t("DIJAGNOZA_NAPOMENA"),
      DIJAGNOZA_POSTAVIO: t("DIJAGNOZA_POSTAVIO"),
      DIJAGNOZA_POSTAVIO_MUSKI: t("DIJAGNOZA_POSTAVIO_MUSKI"),
      DIJAGNOZA_POSTAVIO_ZENSKI: t("DIJAGNOZA_POSTAVIO_ZENSKI"),
      DIJAGNOZA_NOVA: t("DIJAGNOZA_NOVA"),
      DIJAGNOZA_IZMENI: t("DIJAGNOZA_IZMENI"),
      DIJAGNOZA_OBRISI: t("DIJAGNOZA_OBRISI"),
      DIJAGNOZA_PREPISI: t("DIJAGNOZA_PREPISI"),
      DIJAGNOZA_UNOS: t("DIJAGNOZA_UNOS"),
      DIJAGNOZA_UNOS_JE_OBAVEZAN: t("DIJAGNOZA_UNOS_JE_OBAVEZAN"),
      DIJAGNOZA_STATUS_JE_OBAVEZAN: t("DIJAGNOZA_STATUS_JE_OBAVEZAN"),
      DIJAGNOZA_USPESNO_IZMENJENA: t("DIJAGNOZA_USPESNO_IZMENJENA"),
      DIJAGNOZA_USPESNO_OBRISANA: t("DIJAGNOZA_USPESNO_OBRISANA"),
      DIJAGNOZA_USPESNO_SACUVANA: t("DIJAGNOZA_USPESNO_SACUVANA"),
      DIJAGNOZA_USPESNO_PREPISANA: t("DIJAGNOZA_USPESNO_PREPISANA"),
      DIJAGNOZA_IZMENA: t("DIJAGNOZA_IZMENA"),
      DIJAGNOZA_BRISANJE: t("DIJAGNOZA_BRISANJE"),
      DIJAGNOZA_DODAVANJE: t("DIJAGNOZA_DODAVANJE"),
      DIJAGNOZA_PREGLED: t("DIJAGNOZA_PREGLED"),
      DIJAGNOZA_UNOS_PLACEHOLDER: t("DIJAGNOZA_UNOS_PLACEHOLDER"),

      SEARCH_FORMULAR: t("SEARCH_FORMULAR"),
      FORMULAR_LIST: t("FORMULAR_LIST"),
      FORMULAR_TITLE_DIALOG_CREATE: t("FORMULAR_TITLE_DIALOG_CREATE"),
      FORMULAR_TITLE_DIALOG_UPDATE: t("FORMULAR_TITLE_DIALOG_UPDATE"),
      FORMULAR_TITLE_DIALOG_DELETE: t("FORMULAR_TITLE_DIALOG_DELETE"),
      FORMULAR_TITLE_DIALOG_DETAILS: t("FORMULAR_TITLE_DIALOG_DETAILS"),
      FORMULAR_NAZIV: t("FORMULAR_NAZIV"),
      FORMULAR_SIFRA: t("FORMULAR_SIFRA"),
      FORMULAR_NAZIV_IS_REQUIRED: t("FORMULAR_NAZIV_IS_REQUIRED"),
      FORMULAR_SIFRA_IS_REQUIRED: t("FORMULAR_SIFRA_IS_REQUIRED"),
      FORMULAR_TIP_PREGLEDA_IS_REQUIRED: t("FORMULAR_TIP_PREGLEDA_IS_REQUIRED"),
      FORMULAR_TITLE_MESSAGE_CREATE_FORMULAR_SUCCESS: t("FORMULAR_TITLE_MESSAGE_CREATE_FORMULAR_SUCCESS"),
      FORMULAR_TITLE_MESSAGE_UPDATE_FORMULAR_SUCCESS: t("FORMULAR_TITLE_MESSAGE_UPDATE_FORMULAR_SUCCESS"),
      FORMULAR_TITLE_MESSAGE_DELETE_FORMULAR_SUCCESS: t("FORMULAR_TITLE_MESSAGE_DELETE_FORMULAR_SUCCESS"),
      FORMULAR_SEARCH: t("FORMULAR_SEARCH"),
      FORMULAR_SUCCESS_SAVE: t("FORMULAR_SUCCESS_SAVE"),
      FORMULAR_SUCCESS_UPDATE: t("FORMULAR_SUCCESS_UPDATE"),
      FORMULAR_HTML_PREDLOZAK: t("FORMULAR_HTML_PREDLOZAK"),
      FORMULAR_JR_DOCUMENT: t("FORMULAR_JR_DOCUMENT"),
      FORMULAR_CHOOSE_JASPER: t("FORMULAR_CHOOSE_JASPER"),
      FORMULAR_REMOVE_JASPER: t("FORMULAR_REMOVE_JASPER"),
      FORMULAR_DELETE_PRINT: t("FORMULAR_DELETE_PRINT"),
      FORMULAR_POPUNJEN_DELETE: t("FORMULAR_POPUNJEN_DELETE"),
      FORMULAR_DA_LI_STE_SIGURNI: t("FORMULAR_DA_LI_STE_SIGURNI"),
      FORMULAR_ENTITY_STATUS_IS_REQUIRED: t("FORMULAR_ENTITY_STATUS_IS_REQUIRED"),
      FORMULAR_POPUP: t("FORMULAR_POPUP"),
      IMPORT_FORMULAR_REPOSITORY: t("IMPORT_FORMULAR_REPOSITORY"),
      FORMULAR_REPOSITORY: t("FORMULAR_REPOSITORY"),

      // Placeholder labels
      PLACEHOLDER_CHOOSE_ORG_JED: t("PLACEHOLDER_CHOOSE_ORG_JED"),
      PLACEHOLDER_DIJAGNOZA: t("PLACEHOLDER_DIJAGNOZA"),
      PLACEHOLDER_DATE_KONTAKT_FROM: t("PLACEHOLDER_DATE_KONTAKT_FROM"),
      PLACEHOLDER_DATE_KONTAKT_TO: t("PLACEHOLDER_DATE_KONTAKT_TO"),
      PLACEHOLDER_DATE_VREME_SMRTI: t("PLACEHOLDER_DATE_VREME_SMRTI"),
      PLACEHOLDER_DATUM_FORMAT: t("PLACEHOLDER_DATUM_FORMAT"),
      PLACEHOLDER_DATUM_VREME_FORMAT: t("PLACEHOLDER_DATUM_VREME_FORMAT"),
      PLACEHOLDER_VREME_FORMAT: t("PLACEHOLDER_VREME_FORMAT"),

      LABEL_OD: t("LABEL_OD"),
      LABEL_DO: t("LABEL_DO"),
      LABEL_ADDITIONAL_SEARCH: t("LABEL_ADDITIONAL_SEARCH"),
      LABEL_DESCRIPTION: t("LABEL_DESCRIPTION"),
      LABEL_NOTE: t("LABEL_NOTE"),
      LABEL_EMAIL: t("LABEL_EMAIL"),
      LABEL_SMS: t("LABEL_SMS"),
      LABEL_IME: t("LABEL_IME"),
      LABEL_PREZIME: t("LABEL_PREZIME"),
      LABEL_DELETE: t("LABEL_DELETE"),
      LABEL_PODESAVANJA: t("LABEL_PODESAVANJA"),
      LABEL_BEFORE: t("LABEL_BEFORE"),
      LABEL_MINUTES: t("LABEL_MINUTES"),
      LABEL_HOURS: t("LABEL_HOURS"),
      LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED: t("LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED"),
      LABEL_SIFRA: t("LABEL_SIFRA"),
      LABEL_NAZIV: t("LABEL_NAZIV"),
      LABEL_UPDATE_FORM: t("LABEL_UPDATE_FORM"),
      LABEL_DELETE_FORM: t("LABEL_DELETE_FORM"),
      LABEL_DETAILS_FORM: t("LABEL_DETAILS_FORM"),
      LABEL_CREATE: t("LABEL_CREATE"),
      LABEL_UPDATE_FIELD_TYPE: t("LABEL_UPDATE_FIELD_TYPE"),
      LABEL_CREATE_FIELD_TYPE: t("LABEL_CREATE_FIELD_TYPE"),
      LABEL_DELETE_FIELD_TYPE: t("LABEL_DELETE_FIELD_TYPE"),
      LABEL_UPDATE_FIELD_TYPE_ENUM_STAVKA: t("LABEL_UPDATE_FIELD_TYPE_ENUM_STAVKA"),
      LABEL_CREATE_FIELD_TYPE_ENUM_STAVKA: t("LABEL_CREATE_FIELD_TYPE_ENUM_STAVKA"),
      LABEL_DELETE_FIELD_TYPE_ENUM_STAVKA: t("LABEL_DELETE_FIELD_TYPE_ENUM_STAVKA"),
      LABEL_DETAILS_FIELD_TYPE_ENUM_STAVKA: t("LABEL_DETAILS_FIELD_TYPE_ENUM_STAVKA"),
      TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS: t("TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS"),
      TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS: t("TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS"),
      TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS: t("TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS"),
      TITLE_MESSAGE_CREATE_POLJE_SUCCESS: t("TITLE_MESSAGE_CREATE_POLJE_SUCCESS"),
      TITLE_MESSAGE_UPDATE_POLJE_SUCCESS: t("TITLE_MESSAGE_UPDATE_POLJE_SUCCESS"),
      TITLE_MESSAGE_DELETE_POLJE_SUCCESS: t("TITLE_MESSAGE_DELETE_POLJE_SUCCESS"),
      TITLE_MESSAGE_DELETE_FORMULAR_POPUNJEN_SUCCESS: t("TITLE_MESSAGE_DELETE_FORMULAR_POPUNJEN_SUCCESS"),
      TITLE_MESSAGE_DELETE_DOKUMENT_SUCCESS: t("TITLE_MESSAGE_DELETE_DOKUMENT_SUCCESS"),
      TITLE_MESSAGE_PATCH_PROFILNA_SLIKA: t("TITLE_MESSAGE_PATCH_PROFILNA_SLIKA"),
      TITLE_CHANGE_PROFILE_IMAGE: t("TITLE_CHANGE_PROFILE_IMAGE"),
      TITLE_RADNIK_ADD_OTHER: t("TITLE_RADNIK_ADD_OTHER"),
      LABEL_MIN: t("LABEL_MIN"),
      LABEL_MAX: t("LABEL_MAX"),
      LABEL_DETAILS_FIELD_TYPE: t("LABEL_DETAILS_FIELD_TYPE"),
      LABEL_DETAILS: t("LABEL_DETAILS"),
      LABEL_UNIT: t("LABEL_UNIT"),
      LABEL_OBAVESTENJE: t("LABEL_OBAVESTENJE"),
      LABEL_ORGANIZACIONA_JEDINICA: t("LABEL_ORGANIZACIONA_JEDINICA"),
      LABEL_ORGANIZACIONA_JEDINICA_RADNIK_LIST: t("LABEL_ORGANIZACIONA_JEDINICA_RADNIK_LIST"),
      LABEL_ORGANIZACIONA_JEDINICA_KONTAKT_TIP_LIST: t("LABEL_ORGANIZACIONA_JEDINICA_KONTAKT_TIP_LIST"),
      LABEL_RADNIK: t("LABEL_RADNIK"),
      LABEL_TIP_KONTAKTA: t("LABEL_TIP_KONTAKTA"),
      LABEL_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA: t("LABEL_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA"),
      LABEL_TIP_PREGLEDA: t("LABEL_TIP_PREGLEDA"),
      LABEL_PROMENI_DOKTORA: t("LABEL_PROMENI_DOKTORA"),
      LABEL_ULOGA: t("LABEL_ULOGA"),
      LABEL_PACIJENT_DOSIJE: t("LABEL_PACIJENT_DOSIJE"),
      LABEL_PACIJENT_DOSIJE_BROJ_PROTOKOLA: t("LABEL_PACIJENT_DOSIJE_BROJ_PROTOKOLA"),
      LABEL_PACIJENT_POLJE: t("LABEL_PACIJENT_POLJE"),
      LABEL_PACIJENT_POLJE_KATEGORIJA: t("LABEL_PACIJENT_POLJE_KATEGORIJA"),
      LABEL_IME_KONTAKT_OSOBE: t("LABEL_IME_KONTAKT_OSOBE"),
      LABEL_PREZIME_KONTAKT_OSOBE: t("LABEL_PREZIME_KONTAKT_OSOBE"),
      LABEL_ODNOS_SA_PACIJENTOM: t("LABEL_ODNOS_SA_PACIJENTOM"),
      LABEL_TELEFON_KONTAKT_OSOBE: t("LABEL_TELEFON_KONTAKT_OSOBE"),
      LABEL_POL_KONTAKT_OSOBE: t("LABEL_POL_KONTAKT_OSOBE"),
      LABEL_POL_MUSKO: t("LABEL_POL_MUSKO"),
      LABEL_POL_ZENSKO: t("LABEL_POL_ZENSKO"),
      LABEL_RADNIK_ULOGA_LISTA: t("LABEL_RADNIK_ULOGA_LISTA"),
      LABEL_ULOGA_RADNIK_LISTA: t("LABEL_ULOGA_RADNIK_LISTA"),
      LABEL_ULOGA_APLIKATIVNA_PRAVA: t("LABEL_ULOGA_APLIKATIVNA_PRAVA"),
      LABEL_ULOGA_IMA_APLIKATIVNA_PRAVA: t("LABEL_ULOGA_IMA_APLIKATIVNA_PRAVA"),
      LABEL_RADNIK_ORGANIZACIONA_JEDINICA_LIST: t("LABEL_RADNIK_ORGANIZACIONA_JEDINICA_LIST"),
      LABEL_RADNI_PERIOD: t("LABEL_RADNI_PERIOD"),
      LABEL_MALE: t("LABEL_MALE"),
      LABEL_FEMALE: t("LABEL_FEMALE"),
      LABEL_DECIMALS: t("LABEL_DECIMALS"),
      LABEL_FORMAT: t("LABEL_FORMAT"),
      LABEL_SIFARNIK: t("LABEL_SIFARNIK"),
      LABEL_SIFARNIK_LIST: t("LABEL_SIFARNIK_LIST"),
      LABEL_UPLOADED: t("LABEL_UPLOADED"),
      LABEL_UPLOAD: t("LABEL_UPLOAD"),
      LABEL_CHOOSE: t("LABEL_CHOOSE"),
      LABEL_REMOVE_DOCUMENT: t("LABEL_REMOVE_DOCUMENT"),
      LABEL_EXPORT: t("LABEL_EXPORT"),
      LABEL_PDF: t("LABEL_PDF"),
      LABEL_XLS: t("LABEL_XLS"),
      LABEL_CSV: t("LABEL_CSV"),
      LABEL_JSON: t("LABEL_JSON"),
      LABEL_ENTITY_STATUS: t("LABEL_ENTITY_STATUS"),
      LABEL_PRIKAZNI_REDOSLED: t("LABEL_PRIKAZNI_REDOSLED"),
      LABEL_UPDATE_POLJE: t("LABEL_UPDATE_POLJE"),
      LABEL_DETAIL_POLJE: t("LABEL_DETAIL_POLJE"),
      LABEL_CREATE_POLJE: t("LABEL_CREATE_POLJE"),
      LABEL_DELETE_POLJE: t("LABEL_DELETE_POLJE"),
      LABEL_FORM: t("LABEL_FORM"),
      LABEL_SHOW_COLUMNS: t("LABEL_SHOW_COLUMNS"),
      LABEL_PATIENT_SEARCH: t("LABEL_PATIENT_SEARCH"),
      LABEL_TOOLTIP_PATIENT_SEARCH: t("LABEL_TOOLTIP_PATIENT_SEARCH"),
      LABEL_TOOLTIP_PATIENT_SEARCH_ALL: t("LABEL_TOOLTIP_PATIENT_SEARCH_ALL"),
      LABEL_TOOLTIP_PATIENT_SEARCH_ERASE: t("LABEL_TOOLTIP_PATIENT_SEARCH_ERASE"),
      LABEL_TOOLTIP_CHOOSE_COLUMN: t("LABEL_TOOLTIP_CHOOSE_COLUMN"),
      LABEL_TOOLTIP_ADD_PATIENT: t("LABEL_TOOLTIP_ADD_PATIENT"),
      LABEL_NAPOMENA: t("LABEL_NAPOMENA"),
      LABEL_TIP_KOMPONENTE: t("LABEL_TIP_KOMPONENTE"),
      LABEL_UCESNICI: t("LABEL_UCESNICI"),
      LABEL_COLUMN_TRUE: t("LABEL_COLUMN_TRUE"),
      LABEL_COLUMN_FALSE: t("LABEL_COLUMN_FALSE"),
      TABLE_EMPTY_MESSAGE: t("TABLE_EMPTY_MESSAGE"),
      LABEL_PACIJENT_INFORMACIJE: t("LABEL_PACIJENT_INFORMACIJE"),
      TOOLTIP_PACIJENT_INFORMACIJE: t("TOOLTIP_PACIJENT_INFORMACIJE"),
      TOOLTIP_OPSTI_MEDICINSKI_PODACI: t("TOOLTIP_OPSTI_MEDICINSKI_PODACI"),
      LABEL_GODINA: t("LABEL_GODINA"),
      LABEL_PERIOD: t("LABEL_PERIOD"),
      LABEL_REPOZITORIJUM: t("LABEL_REPOZITORIJUM"),
      LABEL_DATATABLE_ROWS_PER_PAGE_DROPWDOWN_ALL: t("LABEL_DATATABLE_ROWS_PER_PAGE_DROPWDOWN_ALL"),
      LABEL_SADRZAJ: t("LABEL_SADRZAJ"),
      LABEL_VAZI_DO: t("LABEL_VAZI_DO"),
      KOPIRAJ_FORMULAR: t("KOPIRAJ_FORMULAR"),
      KOPIRAJ: t("KOPIRAJ"),
      LABEL_DATOTEKE: t("LABEL_DATOTEKE"),
      LABEL_IZVESTAJ: t("LABEL_IZVESTAJ"),
      LABEL_IZVESTAJ_PARAMETAR: t("LABEL_IZVESTAJ_PARAMETAR"),
      LABEL_REQUEST_NAME: t("LABEL_REQUEST_NAME"),
      LABEL_IZVESTAJ_PARAMETAR_NAZIV: t("LABEL_IZVESTAJ_PARAMETAR_NAZIV"),
      LABEL_IZVESTAJ_REPOSITORY: t("LABEL_IZVESTAJ_REPOSITORY"),

      LABEL_UPDATE_IZVESTAJ_PARAMETAR: t("LABEL_UPDATE_IZVESTAJ_PARAMETAR"),
      LABEL_DELETE_IZVESTAJ_PARAMETAR: t("LABEL_DELETE_IZVESTAJ_PARAMETAR"),
      LABEL_DETAILS_IZVESTAJ_PARAMETAR: t("LABEL_DETAILS_IZVESTAJ_PARAMETAR"),
      LABEL_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: t("LABEL_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA"),
      LABEL_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: t("LABEL_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA"),
      LABEL_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: t("LABEL_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA"),
      LABEL_DETAILS_IZVESTAJ_PARAMETAR_ENUM_STAVKA: t("LABEL_DETAILS_IZVESTAJ_PARAMETAR_ENUM_STAVKA"),

      LABEL_REQUIRED_FIELDS: t("LABEL_REQUIRED_FIELDS"),
      LABEL_REQUIRED_FIELD: t("LABEL_REQUIRED_FIELD"),
      MESSAGE_ZABRANJEN_PRISTUP: t("MESSAGE_ZABRANJEN_PRISTUP"),
      LABEL_PRINT: t("LABEL_PRINT"),

      TITLE_MESSAGE_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS: t("TITLE_MESSAGE_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS"),
      TITLE_MESSAGE_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS: t("TITLE_MESSAGE_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS"),
      TITLE_MESSAGE_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS: t("TITLE_MESSAGE_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS"),

      //Column header
      HEADER_NAZIV: t("HEADER_NAZIV"),
      HEADER_SIFRA: t("HEADER_SIFRA"),
      HEADER_PRIKAZNI_REDOSLED: t("HEADER_PRIKAZNI_REDOSLED"),
      HEADER_NAPOMENA: t("HEADER_NAPOMENA"),
      HEADER_FULLNAME: t("HEADER_FULLNAME"),
      HEADER_NAME: t("HEADER_NAME"),
      HEADER_SURNAME: t("HEADER_SURNAME"),
      HEADER_DOB: t("HEADER_DOB"),
      HEADER_GENDER: t("HEADER_GENDER"),
      HEADER_LBO: t("HEADER_LBO"),
      HEADER_PHONE: t("HEADER_PHONE"),
      HEADER_READ_ONLY: t("HEADER_READ_ONLY"),
      HEADER_REQUIRED: t("HEADER_REQUIRED"),
      HEADER_CATEGORY: t("HEADER_CATEGORY"),
      HEADER_VAZI_OD: t("HEADER_VAZI_OD"),
      HEADER_VAZI_DO: t("HEADER_VAZI_DO"),
      HEADER_BROJ_POLISE: t("HEADER_BROJ_POLISE"),
      HEADER_PARTNER_PZO: t("HEADER_PARTNER_PZO"),
      HEADER_PROSTOR: t("HEADER_PROSTOR"),
      HEADER_BROJ_NALOGA: t("HEADER_BROJ_NALOGA"),
      HEADER_INFO: t("HEADER_INFO"),
      HEADER_PODRAZUMEVANA_KOLICINA: t("HEADER_PODRAZUMEVANA_KOLICINA"),
      HEADER_IZNOS: t("HEADER_IZNOS"),
      HEADER_PORESKA_STOPA: t("HEADER_PORESKA_STOPA"),
      HEADER_TIP: t("HEADER_TIP"),

      //Messages
      MESSAGES_UPLOAD_IMAGE_ERROR: t("MESSAGES_UPLOAD_IMAGE_ERROR"),
      DOWNLOAD_DATA_TABLE: t("DOWNLOAD_DATA_TABLE"),
      MESSAGES_NO_RESULT_FOUND: t("MESSAGES_NO_RESULT_FOUND"),
      MESSAGES_SIFRA_REQUIRED: t("MESSAGES_SIFRA_REQUIRED"),
      MESSAGES_NAZIV_REQUIRED: t("MESSAGES_NAZIV_REQUIRED"),
      MESSAGES_TIP_POLJA_REQUIRED: t("MESSAGES_TIP_POLJA_REQUIRED"),
      MESSAGES_INVALID_FILE_SIZE: t("MESSAGES_INVALID_FILE_SIZE"),
      MESSAGES_KATEGORIJA_REQUIRED: t("MESSAGES_KATEGORIJA_REQUIRED"),
      MESSAGES_OBAVEZNO_REQUIRED: t("MESSAGES_OBAVEZNO_REQUIRED"),
      MESSAGES_READONLY_REQUIRED: t("MESSAGES_READONLY_REQUIRED"),
      MESSAGE_SAVE_VREME_SMRTI_SUCCESS: t("MESSAGE_SAVE_VREME_SMRTI_SUCCESS"),
      MESSAGE_DELETE_VREME_SMRTI_SUCCESS: t("MESSAGE_DELETE_VREME_SMRTI_SUCCESS"),
      MESSAGE_ADD_NEW_WARNING_SUCCESS: t("MESSAGE_ADD_NEW_WARNING_SUCCESS"),
      MESSAGE_DELETE_WARNING_SUCCESS: t("MESSAGE_DELETE_WARNING_SUCCESS"),
      MESSAGE_PARAMETAR_TIP_ENUM_REQUIRED: t("MESSAGE_PARAMETAR_TIP_ENUM_REQUIRED"),
      MESSAGES_REQUEST_NAME_REQUIRED: t("MESSAGES_REQUEST_NAME_REQUIRED"),

      // operator
      CONTAIS: t("CONTAIS"),
      NOT_CONTAIS: t("NOT_CONTAIS"),
      START_WITH: t("START_WITH"),
      END_WITH: t("END_WITH"),
      CHOOSE: t("CHOOSE"),
      NOT_CHOOSE: t("NOT_CHOOSE"),

      //errorPage
      ERROR_PAGE_404: t("ERROR_PAGE_404"),
      ERROR_PAGE_PAGE_NOT_FOUND: t("ERROR_PAGE_PAGE_NOT_FOUND"),

      //forbiddenPage
      ERROR_FORBIDDEN_PAGE: t("ERROR_FORBIDDEN_PAGE"),

      /* RADNIK */
      RADNIK_LIST: t("RADNIK_LIST"),
      RADNIK_IME: t("RADNIK_IME"),
      RADNIK_PREZIME: t("RADNIK_PREZIME"),
      RADNIK_USERNAME: t("RADNIK_USERNAME"),
      RADNIK_EMAIL: t("RADNIK_EMAIL"),
      RADNIK_POL: t("RADNIK_POL"),
      RADNIK_PASSWORD: t("RADNIK_PASSWORD"),
      RADNIK_SEARCH: t("RADNIK_SEARCH"),
      RADNIK_TITLE_DIALOG_CREATE: t("RADNIK_TITLE_DIALOG_CREATE"),
      RADNIK_TITLE_DIALOG_UPDATE: t("RADNIK_TITLE_DIALOG_UPDATE"),
      RADNIK_TITLE_DIALOG_DELETE: t("RADNIK_TITLE_DIALOG_DELETE"),
      RADNIK_TITLE_DIALOG_DETAILS: t("RADNIK_TITLE_DIALOG_DETAILS"),
      RADNIK_TITLE_RESET_PASSWORD: t("RADNIK_TITLE_RESET_PASSWORD"),
      RADNIK_TITLE_MESSAGE_DELETE_RADNIK_SUCCESS: t("RADNIK_TITLE_MESSAGE_DELETE_RADNIK_SUCCESS"),
      RADNIK_TITLE_MESSAGE_CREATE_RADNIK_SUCCESS: t("RADNIK_TITLE_MESSAGE_CREATE_RADNIK_SUCCESS"),
      RADNIK_TITLE_MESSAGE_UPDATE_RADNIK_SUCCESS: t("RADNIK_TITLE_MESSAGE_UPDATE_RADNIK_SUCCESS"),
      RADNIK_USERNAME_IS_REQUIRED: t("RADNIK_USERNAME_IS_REQUIRED"),
      RADNIK_EMAIL_IS_REQUIRED: t("RADNIK_EMAIL_IS_REQUIRED"),
      RADNIK_EMAIL_FORMAT_IS_NOT_VALID: t("RADNIK_EMAIL_FORMAT_IS_NOT_VALID"),
      RADNIK_POL_IS_REQUIRED: t("RADNIK_POL_IS_REQUIRED"),
      RADNIK_IME_IS_REQUIRED: t("RADNIK_IME_IS_REQUIRED"),
      RADNIK_PREZIME_IS_REQUIRED: t("RADNIK_PREZIME_IS_REQUIRED"),
      RADNIK_STATUS_IS_REQUIRED: t("RADNIK_STATUS_IS_REQUIRED"),
      RADNIK_PASSWORD_DEFAULT: t("RADNIK_PASSWORD_DEFAULT"),
      RADNIK_BROJ_PECATA: t("RADNIK_BROJ_PECATA"),
      RADNIK_STATUS: t("RADNIK_STATUS"),
      RADNIK_TITULA: t("RADNIK_TITULA"),
      RADNIK_ZANIMANJE: t("RADNIK_ZANIMANJE"),
      RADNIK_MEJL_ISKORISCEN: t("RADNIK_MEJL_ISKORISCEN"),
      RADNIK_DODAT_U_USTANOVU: t("RADNIK_DODAT_U_USTANOVU"),
      RADNIK_JE_IZMENJEN_I_DODAT_U_USTANOVU: t("RADNIK_JE_IZMENJEN_I_DODAT_U_USTANOVU"),
      RADNIK_BIRANJE_PODATAKA: t("RADNIK_BIRANJE_PODATAKA"),
      SEARCH_RADNIK: t("SEARCH_RADNIK"),
      SAVE_RADNIK_DATA: t("SAVE_RADNIK_DATA"),
      CANCEL_RADNIK_DATA: t("CANCEL_RADNIK_DATA"),
      RADNIK_WARNING_MAX_BROJ_NALOGA: t("RADNIK_WARNING_MAX_BROJ_NALOGA"),
      RADNIK_RACUNANJE_NALOGA_ZA_LIMIT: t("RADNIK_RACUNANJE_NALOGA_ZA_LIMIT"),
      RADNIK_USPESNO_DODAT: t("RADNIK_USPESNO_DODAT"),
      RADNIK_KALENDAR_INTEGRACIJA: t("RADNIK_KALENDAR_INTEGRACIJA"),
      RADNIK_DOSTUPAN_ZA_EKSTERNE_ZAKAZIVACE: t("RADNIK_DOSTUPAN_ZA_EKSTERNE_ZAKAZIVACE"),

      /*PACIJENT*/
      PACIJENT_IME: t("PACIJENT_IME"),
      PACIJENT_PREZIME: t("PACIJENT_PREZIME"),
      PACIJENT_IME_RODITELJA: t("PACIJENT_IME_RODITELJA"),
      PACIJENT_POL: t("PACIJENT_POL"),
      PACIJENT_DATUM_RODJENJA: t("PACIJENT_DATUM_RODJENJA"),
      PACIJENT_ADRESA: t("PACIJENT_ADRESA"),
      PACIJENT_USPESNO_IZMENJEN: t("PACIJENT_USPESNO_IZMENJEN"),
      PACIJENT_USPESNO_OBRISAN: t("PACIJENT_USPESNO_OBRISAN"),
      PACIJENT_USPESNO_KREIRAN: t("PACIJENT_USPESNO_KREIRAN"),
      PACIJENT_IME_OBAVEZAN_PODATAK: t("PACIJENT_IME_OBAVEZAN_PODATAK"),
      PACIJENT_PREZIME_OBAVEZAN_PODATAK: t("PACIJENT_PREZIME_OBAVEZAN_PODATAK"),
      PACIJENT_POL_OBAVEZAN_PODATAK: t("PACIJENT_POL_OBAVEZAN_PODATAK"),
      PACIJENT_DATUM_RODJENJA_OBAVEZAN_PODATAK: t("PACIJENT_DATUM_RODJENJA_OBAVEZAN_PODATAK"),
      PACIJENT_BRISANJE: t("PACIJENT_BRISANJE"),
      BUTTON_PACIJENT_DELETE: t("BUTTON_PACIJENT_DELETE"),
      PACIJENT_PRIKAZI: t("PACIJENT_PRIKAZI"),
      PACIJENT_TELEFON: t("PACIJENT_TELEFON"),
      PACIJENT_EMAIL: t("PACIJENT_EMAIL"),
      PACIJENT_OBAVESTENJE_TIP_LIST: t("PACIJENT_OBAVESTENJE_TIP_LIST"),
      PACIJENT_GRUPNO_OBAVESTENJE_TIP_LIST: t("PACIJENT_GRUPNO_OBAVESTENJE_TIP_LIST"),
      PACIJENT_EXISTS_WARNING: t("PACIJENT_EXISTS_WARNING"),
      PACIJENT_EXIST_MALE_GENDER_TEXT: t("PACIJENT_EXIST_MALE_GENDER_TEXT"),
      PACIJENT_EXIST_FEMALE_GENDER_TEXT: t("PACIJENT_EXIST_FEMALE_GENDER_TEXT"),
      PACIJENT_EXIST_NOT_SPECIFIED_GENDER_TEXT: t("PACIJENT_EXIST_NOT_SPECIFIED_GENDER_TEXT"),
      PACIJENT_EXIST_PATIENT_TEXT: t("PACIJENT_EXIST_PATIENT_TEXT"),
      PACIJENT_EXIST_GENDER_TEXT: t("PACIJENT_EXIST_GENDER_TEXT"),
      PACIJENT_EXIST_DATE_TEXT: t("PACIJENT_EXIST_DATE_TEXT"),
      PACIJENT_EXIST_ALREADY_EXIST_TEXT_1: t("PACIJENT_EXIST_ALREADY_EXIST_TEXT_1"),
      PACIJENT_EXIST_ALREADY_EXIST_TEXT_2: t("PACIJENT_EXIST_ALREADY_EXIST_TEXT_2"),
      PACIJENTI_USPESNO_SPOJENI: t("PACIJENTI_USPESNO_SPOJENI"),
      /*PACIJENT POLJE */
      SEARCH_PACIJENT_POLJE: t("SEARCH_PACIJENT_POLJE"),
      PACIJENT_POLJE_LIST: t("PACIJENT_POLJE_LIST"),
      PACIJENT_POLJE_NAZIV: t("PACIJENT_POLJE_NAZIV"),
      PACIJENT_POLJE_SIFRA: t("PACIJENT_POLJE_SIFRA"),
      PACIJENT_POLJE_TIP: t("PACIJENT_POLJE_TIP"),
      PACIJENT_POLJE_VRSTA_GRAFIKONA: t("PACIJENT_POLJE_VRSTA_GRAFIKONA"),

      PACIJENT_POLJE_SEARCH: t("PACIJENT_POLJE_SEARCH"),
      PACIJENT_POLJE_NAZIV_IS_REQUIRED: t("PACIJENT_POLJE_NAZIV_IS_REQUIRED"),
      PACIJENT_POLJE_SIFRA_IS_REQUIRED: t("PACIJENT_POLJE_SIFRA_IS_REQUIRED"),
      PACIJENT_POLJE_TIP_IS_REQUIRED: t("PACIJENT_POLJE_TIP_IS_REQUIRED"),
      PACIJENT_POLJE_TITLE_DIALOG_CREATE: t("PACIJENT_POLJE_TITLE_DIALOG_CREATE"),
      PACIJENT_POLJE_TITLE_DIALOG_UPDATE: t("PACIJENT_POLJE_TITLE_DIALOG_UPDATE"),
      PACIJENT_POLJE_TITLE_DIALOG_DETAILS: t("PACIJENT_POLJE_TITLE_DIALOG_DETAILS"),
      PACIJENT_POLJE_TITLE_DIALOG_DELETE: t("PACIJENT_POLJE_TITLE_DIALOG_DELETE"),
      PACIJENT_POLJE_TITLE_MESSAGE_CREATE_PACIJENT_POLJE_SUCCESS: t("PACIJENT_POLJE_TITLE_MESSAGE_CREATE_PACIJENT_POLJE_SUCCESS"),
      PACIJENT_POLJE_TITLE_MESSAGE_UPDATE_PACIJENT_POLJE_SUCCESS: t("PACIJENT_POLJE_TITLE_MESSAGE_UPDATE_PACIJENT_POLJE_SUCCESS"),
      PACIJENT_POLJE_TITLE_MESSAGE_DELETE_PACIJENT_POLJE_SUCCESS: t("PACIJENT_POLJE_TITLE_MESSAGE_DELETE_PACIJENT_POLJE_SUCCESS"),
      PACIJENT_POLJE_OBAVEZNO_JE_UNETI: t("PACIJENT_POLJE_OBAVEZNO_JE_UNETI"),

      PACIJENT_POLJE_KATEGORIJA_LIST: t("PACIJENT_POLJE_KATEGORIJA_LIST"),
      PACIJENT_POLJE_KATEGORIJA_KATEGORIJA: t("PACIJENT_POLJE_KATEGORIJA_KATEGORIJA"),
      PACIJENT_POLJE_KATEGORIJA_OBAVEZNO: t("PACIJENT_POLJE_KATEGORIJA_OBAVEZNO"),
      PACIJENT_POLJE_KATEGORIJA_READONLY: t("PACIJENT_POLJE_KATEGORIJA_READONLY"),
      PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_CREATE: t("PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_CREATE"),
      PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_UPDATE: t("PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_UPDATE"),
      PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DETAILS: t("PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DETAILS"),
      PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DELETE: t("PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DELETE"),
      PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_CREATE_PACIJENT_POLJE_SUCCESS: t("PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_CREATE_PACIJENT_POLJE_SUCCESS"),
      PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_UPDATE_PACIJENT_POLJE_SUCCESS: t("PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_UPDATE_PACIJENT_POLJE_SUCCESS"),
      PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_DELETE_PACIJENT_POLJE_SUCCESS: t("PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_DELETE_PACIJENT_POLJE_SUCCESS"),

      /*POLJE TIP */
      POLJE_TIP: t("POLJE_TIP"),
      POLJE_TIP_LIST: t("POLJE_TIP_LIST"),
      POLJE_TIP_KOMPONENTA_TIP: t("POLJE_TIP_KOMPONENTA_TIP"),
      POLJE_TIP_STATUS_POLJA: t("POLJE_TIP_STATUS_POLJA"),
      POLJE_TIP_NAZIV: t("POLJE_TIP_NAZIV"),
      POLJE_TIP_SIFRA: t("POLJE_TIP_SIFRA"),
      POLJE_TIP_TITLE_DIALOG_CREATE: t("POLJE_TIP_TITLE_DIALOG_CREATE"),
      POLJE_TIP_TITLE_DIALOG_UPDATE: t("POLJE_TIP_TITLE_DIALOG_UPDATE"),
      POLJE_TIP_TITLE_DIALOG_DELETE: t("POLJE_TIP_TITLE_DIALOG_DELETE"),
      POLJE_TIP_SIFRA_IS_REQUIRED: t("POLJE_TIP_SIFRA_IS_REQUIRED"),
      POLJE_TIP_NAZIV_IS_REQUIRED: t("POLJE_TIP_NAZIV_IS_REQUIRED"),
      POLJE_TIP_KOMPONENTA_TIP_IS_REQUIRED: t("POLJE_TIP_KOMPONENTA_TIP_IS_REQUIRED"),
      POLJE_TIP_STATUS_POLJA_IS_REQUIRED: t("POLJE_TIP_STATUS_POLJA_IS_REQUIRED"),
      POLJE_TIP_SEARCH: t("POLJE_TIP_SEARCH"),
      SEARCH_POLJE_TIP: t("SEARCH_POLJE_TIP"),
      BUTTON_ADD_POLJE_TIP: t("BUTTON_ADD_POLJE_TIP"),

      /*POLJE TIP ENUM_STAVKA */
      POLJE_TIP_ENUM_STAVKA_LIST: t("POLJE_TIP_ENUM_STAVKA_LIST"),
      POLJE_TIP_ENUM_STAVKA_NAZIV: t("POLJE_TIP_ENUM_STAVKA_NAZIV"),
      POLJE_TIP_ENUM_STAVKA_NAPOMENA: t("POLJE_TIP_ENUM_STAVKA_NAPOMENA"),
      POLJE_TIP_ENUM_STAVKA_OPIS: t("POLJE_TIP_ENUM_STAVKA_OPIS"),
      POLJE_TIP_ENUM_STAVKA_BROJNA_VREDNOST: t("POLJE_TIP_ENUM_STAVKA_BROJNA_VREDNOST"),
      POLJE_TIP_ENUM_STAVKA_PRIKAZNI_REDOSLED: t("POLJE_TIP_ENUM_STAVKA_PRIKAZNI_REDOSLED"),
      POLJE_TIP_ENUM_STAVKA_TITLE_DIALOG_UPDATE: t("POLJE_TIP_ENUM_STAVKA_TITLE_DIALOG_UPDATE"),
      POLJE_TIP_ENUM_STAVKA_TITLE_DIALOG_DELETE: t("POLJE_TIP_ENUM_STAVKA_TITLE_DIALOG_DELETE"),
      BUTTON_ADD_POLJE_TIP_ENUM_STAVKA: t("BUTTON_ADD_POLJE_TIP_ENUM_STAVKA"),
      TOOLTIP_CREATE_POLJE_TIP_ENUM_STAVKA: t("TOOLTIP_CREATE_POLJE_TIP_ENUM_STAVKA"),

      /* STATISTIČKI IZVEŠTAJ PARAMETAR ENUM STAVKA */
      IZVESTAJ_PARAMETAR_ENUM_STAVKA_LIST: t("IZVESTAJ_PARAMETAR_ENUM_STAVKA_LIST"),
      IZVESTAJ_PARAMETAR_ENUM_STAVKA_NAZIV: t("IZVESTAJ_PARAMETAR_ENUM_STAVKA_NAZIV"),
      IZVESTAJ_PARAMETAR_ENUM_STAVKA_TITLE_DIALOG_UPDATE: t("IZVESTAJ_PARAMETAR_ENUM_STAVKA_TITLE_DIALOG_UPDATE"),
      IZVESTAJ_PARAMETAR_ENUM_STAVKA_TITLE_DIALOG_DELETE: t("IZVESTAJ_PARAMETAR_ENUM_STAVKA_TITLE_DIALOG_DELETE"),
      BUTTON_ADD_IZVESTAJ_PARAMETAR_ENUM_STAVKA: t("BUTTON_ADD_IZVESTAJ_PARAMETAR_ENUM_STAVKA"),
      TOOLTIP_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: t("TOOLTIP_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA"),

      /*Polje tip repository */
      IMPORT_POLJE_TIP_REPOSITORY: t("IMPORT_POLJE_TIP_REPOSITORY"),
      POLJE_TIP_REPOSITORY: t("POLJE_TIP_REPOSITORY"),

      /*POLJE */
      POLJE_LIST: t("POLJE_LIST"),
      POLJE_NAZIV: t("POLJE_NAZIV"),
      POLJE_SIFRA: t("POLJE_SIFRA"),
      POLJE_OBAVEZNO: t("POLJE_OBAVEZNO"),
      POLJE_TITLE_DIALOG_CREATE: t("POLJE_TITLE_DIALOG_CREATE"),
      POLJE_TITLE_DIALOG_UPDATE: t("POLJE_TITLE_DIALOG_UPDATE"),
      POLJE_TITLE_DIALOG_DELETE: t("POLJE_TITLE_DIALOG_DELETE"),
      POLJE_TITLE_DIALOG_DETAILS: t("POLJE_TITLE_DIALOG_DETAILS"),
      POLJE_SEARCH: t("POLJE_SEARCH"),
      SEARCH_POLJE: t("SEARCH_POLJE"),
      BUTTON_ADD_POLJE: t("BUTTON_ADD_POLJE"),
      LABEL_POLJE: t("LABEL_POLJE"),
      POLJE_ENTITY_STATUS_IS_REQUIRED: t("POLJE_ENTITY_STATUS_IS_REQUIRED"),
      POLJE_NADREDJENO: t("POLJE_NADREDJENO"),
      POLJE_SQL_UPIT: t("POLJE_SQL_UPIT"),

      /* Tooltip */
      TOOLTIP_CREATE_POLJE: t("TOOLTIP_CREATE_POLJE"),
      TOOLTIP_DELETE_POLJE: t("TOOLTIP_DELETE_POLJE"),
      TOOLTIP_UPDATE_POLJE: t("TOOLTIP_UPDATE_POLJE"),

      /* radnik profil */
      RADNIK_PROFIL_CHANGE: t("RADNIK_PROFIL_CHANGE"),
      RADNIK_PROFIL_CHANGE_EMAIL: t("RADNIK_PROFIL_CHANGE_EMAIL"),
      RADNIK_PROFIL_CHANGE_IME: t("RADNIK_PROFIL_CHANGE_IME"),
      RADNIK_PROFIL_CHANGE_PREZIME: t("RADNIK_PROFIL_CHANGE_PREZIME"),
      RADNIK_PROFIL_CHANGE_JEZIK: t("RADNIK_PROFIL_CHANGE_JEZIK"),
      RADNIK_PROFIL_BUTTON_SAVE: t("RADNIK_PROFIL_BUTTON_SAVE"),
      RADNIK_PROFIL_BUTTON_CHANGE_PASSWORD: t("RADNIK_PROFIL_BUTTON_CHANGE_PASSWORD"),
      RADNIK_PROFIL_BUTTON_CANCEL: t("RADNIK_PROFIL_BUTTON_CANCEL"),
      RADNIK_PROFIL_CHANGE_PASSWORD: t("RADNIK_PROFIL_CHANGE_PASSWORD"),
      RADNIK_PROFIL_PASSWORD_CHANGE_ERROR: t("RADNIK_PROFIL_PASSWORD_CHANGE_ERROR"),
      RADNIK_PROFIL_MESSAGES_NEW_PASSWORD: t("RADNIK_PROFIL_MESSAGES_NEW_PASSWORD"),
      RADNIK_PROFIL_MESSAGES_OLD_PASSWORD: t("RADNIK_PROFIL_MESSAGES_OLD_PASSWORD"),
      RADNIK_PROFIL_MESSAGES_PASSWORD_CONFIRMATION: t("RADNIK_PROFIL_MESSAGES_PASSWORD_CONFIRMATION"),
      RADNIK_PROFIL_MESSAGES_PASSWORDS_NOT_SAME: t("RADNIK_PROFIL_MESSAGES_PASSWORDS_NOT_SAME"),
      RADNIK_PROFIL_BUTTON_SAVE_NEW_PASSWORD: t("RADNIK_PROFIL_BUTTON_SAVE_NEW_PASSWORD"),
      RADNIK_PROFIL_OLD_PASSWORD: t("RADNIK_PROFIL_OLD_PASSWORD"),
      RADNIK_PROFIL_NEW_PASSWORD: t("RADNIK_PROFIL_NEW_PASSWORD"),
      RADNIK_PROFIL_CONFIRM_NEW_PASSWORD: t("RADNIK_PROFIL_CONFIRM_NEW_PASSWORD"),
      RADNIK_PROFIL_EDIT_SUCCESS: t("RADNIK_PROFIL_EDIT_SUCCESS"),
      RADNIK_PROFIL_CHANGE_PASSWORD_SUCCESS: t("RADNIK_PROFIL_CHANGE_PASSWORD_SUCCESS"),
      RADNIK_PROFIL_ODABERI_PODATKE: t("RADNIK_PROFIL_ODABERI_PODATKE"),
      RADNIK_PROFIL_ODUSTANI_OD_ODABIRA: t("RADNIK_PROFIL_ODUSTANI_OD_ODABIRA"),

      /** usluga */
      USLUGA: t("USLUGA"),
      PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA: t("PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA"),
      PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_UKLONI_SVE: t("PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_UKLONI_SVE"),
      PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_PREBACI_SVE: t("PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_PREBACI_SVE"),
      PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_NEMA_PLANIRANIH_USLUGA: t("PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_NEMA_PLANIRANIH_USLUGA"),
      PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_BRISANJE_POJEDINACNE_USLUGE: t("PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_BRISANJE_POJEDINACNE_USLUGE"),
      PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_BRISANJE_SVIH_USLUGA: t("PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_BRISANJE_SVIH_USLUGA"),
      PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_REALIZOVANO_USPESNO: t("PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_REALIZOVANO_USPESNO"),
      PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_POJEDINACNA_REALIZOVANA_USPESNO: t("PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_POJEDINACNA_REALIZOVANA_USPESNO"),
      USLUGA_LIST_TOKOM_KONTKAKTA: t("USLUGA_LIST_TOKOM_KONTKAKTA"),
      USLUGA_BROJ_PRUZANJA: t("USLUGA_BROJ_PRUZANJA"),
      USLUGA_NAPOMENA: t("USLUGA_NAPOMENA"),
      USLUGA_PRUZIO: t("USLUGA_PRUZIO"),
      USLUGA_PRUZIO_MUSKO: t("USLUGA_PRUZIO_MUSKO"),
      USLUGA_PRUZIO_ZENSKO: t("USLUGA_PRUZIO_ZENSKO"),
      USLUGA_NOVA: t("USLUGA_NOVA"),
      USLUGA_IZMENI: t("USLUGA_IZMENI"),
      USLUGA_OBRISI: t("USLUGA_OBRISI"),
      USLUGA_IZMENA: t("USLUGA_IZMENA"),
      USLUGA_BRISANJE: t("USLUGA_BRISANJE"),
      USLUGA_DODAVANJE: t("USLUGA_DODAVANJE"),
      USLUGA_UNOS: t("USLUGA_UNOS"),
      USLUGA_TIP: t("USLUGA_TIP"),
      USLUGA_UNOS_OBAVEZAN: t("USLUGA_UNOS_OBAVEZAN"),
      USLUGA_BROJ_PRUZANJA_OBAVEZAN: t("USLUGA_BROJ_PRUZANJA_OBAVEZAN"),
      USLUGA_USPESNO_IZMENJENA: t("USLUGA_USPESNO_IZMENJENA"),
      USLUGA_USPESNO_OBRISANA: t("USLUGA_USPESNO_OBRISANA"),
      USLUGA_USPESNO_SACUVANA: t("USLUGA_USPESNO_SACUVANA"),
      NO_USLUGA_CENA_WARNING: t("NO_USLUGA_CENA_WARNING"),
      NO_USLUGA_CENA_LIST_WARNING: t("NO_USLUGA_CENA_LIST_WARNING"),
      NO_USLUGA_CENA_LIST_WARNING_ACCEPT: t("NO_USLUGA_CENA_LIST_WARNING_ACCEPT"),

      /** utrosak */
      UTROSCI: t("UTROSCI"),
      UTROSAK_BRISANJE: t("UTROSAK_BRISANJE"),
      UTROSAK_DODAVANJE: t("UTROSAK_DODAVANJE"),
      UTROSAK_IZMENA: t("UTROSAK_IZMENA"),
      UTROSAK_UNOS: t("UTROSAK_UNOS"),
      UTROSAK_LIST_TOKOM_KONTAKTA: t("UTROSAK_LIST_TOKOM_KONTAKTA"),
      UTROSAK_NOVI: t("UTROSAK_NOVI"),
      UTROSAK_IZMENI: t("UTROSAK_IZMENI"),
      UTROSAK_OBRISI: t("UTROSAK_OBRISI"),
      UTROSAK_BROJ_PRUZANJA: t("UTROSAK_BROJ_PRUZANJA"),
      UTROSAK_NAPOMENA: t("UTROSAK_NAPOMENA"),
      UTROSAK_BROJ_JE_OBAVEZAN_PODATAK: t("UTROSAK_BROJ_JE_OBAVEZAN_PODATAK"),
      UTROSAK_USPESNO_KREIRAN: t("UTROSAK_USPESNO_KREIRAN"),
      UTROSAK_USPESNO_IZMENJEN: t("UTROSAK_USPESNO_IZMENJEN"),
      UTROSAK_USPESNO_OBRISAN: t("UTROSAK_USPESNO_OBRISAN"),
      UTROSAK_DEO_NAZIVA_SIFRE: t("UTROSAK_DEO_NAZIVA_SIFRE"),
      UTROSAK_POTROSENA_KOLICINA: t("UTROSAK_POTROSENA_KOLICINA"),
      UTROSAK: t("UTROSAK"),
      NO_PROIZVOD_CENA_WARNING: t("NO_PROIZVOD_CENA_WARNING"),

      /** ustanova */
      SEARCH_USTANOVA: t("SEARCH_USTANOVA"),
      USTANOVA_NAZIV: t("USTANOVA_NAZIV"),
      USTANOVA_NAZIV_KRATKI: t("USTANOVA_NAZIV_KRATKI"),
      USTANOVA_SIFRA: t("USTANOVA_SIFRA"),
      USTANOVA_ADRESA: t("USTANOVA_ADRESA"),
      USTANOVA_TELEFON: t("USTANOVA_TELEFON"),
      USTANOVA_JEZIK: t("USTANOVA_JEZIK"),
      USTANOVA_SVOJINA_TIP: t("USTANOVA_SVOJINA_TIP"),
      USTANOVA_NIVO_ZDRAVSTVENE_ZASTITE: t("USTANOVA_NIVO_ZDRAVSTVENE_ZASTITE"),
      USTANOVA_PODRAZUMEVANA_STRANA: t("USTANOVA_PODRAZUMEVANA_STRANA"),
      USTANOVA_SIFARNIK_VALUTA: t("USTANOVA_SIFARNIK_VALUTA"),
      USTANOVA_GPS_LOKACIJA: t("USTANOVA_GPS_LOKACIJA"),
      USTANOVA_FAKS: t("USTANOVA_FAKS"),
      USTANOVA_TEKUCI_RACUN: t("USTANOVA_TEKUCI_RACUN"),
      USTANOVA_SEARCH: t("USTANOVA_SEARCH"),
      USTANOVA_LIST: t("USTANOVA_LIST"),
      USTANOVA_TITLE_DIALOG_CREATE: t("USTANOVA_TITLE_DIALOG_CREATE"),
      USTANOVA_TITLE_DIALOG_UPDATE: t("USTANOVA_TITLE_DIALOG_UPDATE"),
      USTANOVA_TITLE_DIALOG_DETAILS: t("USTANOVA_TITLE_DIALOG_DETAILS"),
      USTANOVA_TITLE_DIALOG_DELETE: t("USTANOVA_TITLE_DIALOG_DELETE"),
      USTANOVA_NAZIV_IS_REQUIRED: t("USTANOVA_NAZIV_IS_REQUIRED"),
      USTANOVA_SIFRA_IS_REQUIRED: t("USTANOVA_SIFRA_IS_REQUIRED"),
      USTANOVA_NAZIV_KRATKI_IS_REQUIRED: t("USTANOVA_NAZIV_KRATKI_IS_REQUIRED"),
      USTANOVA_TIP_SVOJINE_IS_REQUIRED: t("USTANOVA_TIP_SVOJINE_IS_REQUIRED"),
      USTANOVA_NIVO_ZDRAVSTVENE_ZASTITE_IS_REQUIRED: t("USTANOVA_NIVO_ZDRAVSTVENE_ZASTITE_IS_REQUIRED"),
      USTANOVA_PODRAZUMEVANA_STRANA_IS_REQUIRED: t("USTANOVA_PODRAZUMEVANA_STRANA_IS_REQUIRED"),
      USTANOVA_TITLE_MESSAGE_DELETE_USTANOVA_SUCCESS: t("USTANOVA_TITLE_MESSAGE_DELETE_USTANOVA_SUCCESS"),
      USTANOVA_TITLE_MESSAGE_CREATE_USTANOVA_SUCCESS: t("USTANOVA_TITLE_MESSAGE_CREATE_USTANOVA_SUCCESS"),
      USTANOVA_TITLE_MESSAGE_UPDATE_USTANOVA_SUCCESS: t("USTANOVA_TITLE_MESSAGE_UPDATE_USTANOVA_SUCCESS"),
      USTANOVA_LOGO: t("USTANOVA_LOGO"),
      USTANOVA_CHOOSE_LOGO: t("USTANOVA_CHOOSE_LOGO"),
      USTANOVA_MAX_SIZE_LOGO: t("USTANOVA_MAX_SIZE_LOGO"),
      USTANOVA_MAX_FILE_SIZE_OVERLOADED: t("USTANOVA_MAX_FILE_SIZE_OVERLOADED"),
      USTANOVA_FILE_TYPE_NOT_SUPPORTED: t("USTANOVA_FILE_TYPE_NOT_SUPPORTED"),
      USTANOVA_GRID_TITLE_MESSAGE_UPDATE_USTANOVA_GRID_SUCCESS: t("USTANOVA_GRID_TITLE_MESSAGE_UPDATE_USTANOVA_GRID_SUCCESS"),
      USTANOVA_GRID_VREME_TRAJANJA_PREGLEDA: t("USTANOVA_GRID_VREME_TRAJANJA_PREGLEDA"),
      USTANOVA_GRID_VANREDNI_TERMINI: t("USTANOVA_GRID_VANREDNI_TERMINI"),
      USTANOVA_GRID_RADNI_DANI_U_NEDELJI: t("USTANOVA_GRID_RADNI_DANI_U_NEDELJI"),
      USTANOVA_GRID_ZAKAZIVANJA: t("USTANOVA_GRID_ZAKAZIVANJA"),
      USTANOVA_GRID_SPISAK_PACIJENATA_JASPER: t("USTANOVA_GRID_SPISAK_PACIJENATA_JASPER"),
      USTANOVA_GRID_SPISAK_PACIJENATA_JASPER_CHOOSE: t("USTANOVA_GRID_SPISAK_PACIJENATA_JASPER_CHOOSE"),
      USTANOVA_GRID_OMOGUCENA_REZERVACIJA: t("USTANOVA_GRID_OMOGUCENA_REZERVACIJA"),
      USTANOVA_GRID_PODRAZUMEVANA_DUZINA_TRAJANJA_REZERVACIJE: t("USTANOVA_GRID_PODRAZUMEVANA_DUZINA_TRAJANJA_REZERVACIJE"),
      USTANOVA_GRID_MAKSIMALNA_DUZINA_TRAJANJA_REZERVACIJE: t("USTANOVA_GRID_MAKSIMALNA_DUZINA_TRAJANJA_REZERVACIJE"),
      USTANOVA_GRID_POTREBNI_RADNI_PERIODI_ZA_ZAKAZIVANJE: t("USTANOVA_GRID_POTREBNI_RADNI_PERIODI_ZA_ZAKAZIVANJE"),
      USTANOVA_LICENCE: t("USTANOVA_LICENCE"),
      USTANOVA_PARAMETRI: t("USTANOVA_PARAMETRI"),
      USTANOVA_ULOGE: t("USTANOVA_ULOGE"),
      USTANOVA_TIP_KONTAKTA: t("USTANOVA_TIP_KONTAKTA"),
      USTANOVA_RADNICI: t("USTANOVA_RADNICI"),
      USTANOVA_WEBSITE_URL: t("USTANOVA_WEBSITE_URL"),
      USTANOVA_RADNO_VREME_OD: t("USTANOVA_RADNO_VREME_OD"),
      USTANOVA_RADNO_VREME_DO: t("USTANOVA_RADNO_VREME_DO"),
      USTANOVA_BROJ_NALOGA: t("USTANOVA_BROJ_NALOGA"),
      USTANOVA_BROJ_NALOGA_BUTTON_DODAJ: t("USTANOVA_BROJ_NALOGA_BUTTON_DODAJ"),
      USTANOVA_BROJ_NALOGA_TITLE_CREATE: t("USTANOVA_BROJ_NALOGA_TITLE_CREATE"),
      USTANOVA_BROJ_NALOGA_TITLE_UPDATE: t("USTANOVA_BROJ_NALOGA_TITLE_UPDATE"),
      USTANOVA_BROJ_NALOGA_TITLE_DELETE: t("USTANOVA_BROJ_NALOGA_TITLE_DELETE"),
      USTANOVA_BROJ_NALOGA_IS_REQUIRED: t("USTANOVA_BROJ_NALOGA_IS_REQUIRED"),
      USTANOVA_VAZI_OD_IS_REQUIRED: t("USTANOVA_VAZI_OD_IS_REQUIRED"),
      USTANOVA_STORAGE_LIMIT_IS_REQUIRED: t("USTANOVA_STORAGE_LIMIT_IS_REQUIRED"),
      USTANOVA_PROTOKOL: t("USTANOVA_PROTOKOL"),
      USTANOVA_PRISTUP_PRIMARAN: t("USTANOVA_PRISTUP_PRIMARAN"),
      USTANOVA_INICIJALIZACIJA_KORAK: t("USTANOVA_INICIJALIZACIJA_KORAK"),
      USTANOVA_BRISANJE: t("USTANOVA_BRISANJE"),
      USTANOVA_BRISANJE_PODACI_O_USTANOVI: t("USTANOVA_BRISANJE_PODACI_O_USTANOVI"),
      USTANOVA_BRISANJE_LABEL_BROJ_RADNIKA: t("USTANOVA_BRISANJE_LABEL_BROJ_RADNIKA"),
      USTANOVA_BRISANJE_LABEL_BROJ_ORGANIZACIONIH_JEDINICA: t("USTANOVA_BRISANJE_LABEL_BROJ_ORGANIZACIONIH_JEDINICA"),
      USTANOVA_BRISANJE_LABEL_BROJ_ZAKAZNIH_TERMINA: t("USTANOVA_BRISANJE_LABEL_BROJ_ZAKAZNIH_TERMINA"),
      USTANOVA_BRISANJE_LABEL_BROJ_KONTAKATA: t("USTANOVA_BRISANJE_LABEL_BROJ_KONTAKATA"),
      USTANOVA_BRISANJE_LABEL_BROJ_SNIMLJENIH_DIJAGNOZA: t("USTANOVA_BRISANJE_LABEL_BROJ_SNIMLJENIH_DIJAGNOZA"),
      USTANOVA_BRISANJE_LABEL_BROJ_SNIMLJENIH_FORMULARA: t("USTANOVA_BRISANJE_LABEL_BROJ_SNIMLJENIH_FORMULARA"),
      USTANOVA_BRISANJE_DIALOG_LABEL_1: t("USTANOVA_BRISANJE_DIALOG_LABEL_1"),
      USTANOVA_BRISANJE_DIALOG_LABEL_2: t("USTANOVA_BRISANJE_DIALOG_LABEL_2"),
      USTANOVA_BRISANJE_U_TOKU: t("USTANOVA_BRISANJE_U_TOKU"),
      USTANOVA_BRISANJE_ZAVRSENO: t("USTANOVA_BRISANJE_ZAVRSENO"),
      USTANOVA_VREME_OD_REQUIRED: t("USTANOVA_VREME_OD_REQUIRED"),
      USTANOVA_VREME_DO_REQUIRED: t("USTANOVA_VREME_DO_REQUIRED"),
      USTANOVA_LOGO_UPLOAD: t("USTANOVA_LOGO_UPLOAD"),
      USTANOVA_PODESAVANJA: t("USTANOVA_PODESAVANJA"),
      USTANOVA_PODESAVANJA_UPDATE_SUCCESS: t("USTANOVA_PODESAVANJA_UPDATE_SUCCESS"),
      USTANOVA_PODESAVANJA_PODSETNIK_PACIJENT: t("USTANOVA_PODESAVANJA_PODSETNIK_PACIJENT"),
      USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA: t("USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA"),
      USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA_POTVRDA: t("USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA_POTVRDA"),
      USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA_DIALOG_CONTENT: t("USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA_DIALOG_CONTENT"),
      USTANOVA_NADJIMED: t("USTANOVA_NADJIMED"),
      USTANOVA_NADJIMED_LABEL_CENOVNICI_USLUGA: t("USTANOVA_NADJIMED_LABEL_CENOVNICI_USLUGA"),
      USTANOVA_NADJIMED_LABEL_ORGANIZACIONE_JEDINICE: t("USTANOVA_NADJIMED_LABEL_ORGANIZACIONE_JEDINICE"),
      USTANOVA_NADJIMED_LABEL_DOKTORI: t("USTANOVA_NADJIMED_LABEL_DOKTORI"),
      USTANOVA_NADJIMED_LABEL_INICIJALIZACIJA: t("USTANOVA_NADJIMED_LABEL_INICIJALIZACIJA"),
      USTANOVA_SINHRONIZUJ: t("USTANOVA_SINHRONIZUJ"),
      USTANOVA_INICIJALIZUJ: t("USTANOVA_INICIJALIZUJ"),
      USTANOVA_NADJIMED_LABEL_CENOVNICI_USLUGA_SUCCESS_MESSAGE: t("USTANOVA_NADJIMED_LABEL_CENOVNICI_USLUGA_SUCCESS_MESSAGE"),
      UUSTANOVA_NADJIMED_LABEL_ORGANIZACIONE_JEDINICE_SUCCESS_MESSAGE: t("UUSTANOVA_NADJIMED_LABEL_ORGANIZACIONE_JEDINICE_SUCCESS_MESSAGE"),
      USTANOVA_NADJIMED_LABEL_DOKTORI_SUCCESS_MESSAGE: t("USTANOVA_NADJIMED_LABEL_DOKTORI_SUCCESS_MESSAGE"),
      USTANOVA_NADJIMED_LABEL_INICIJALIZACIJA_SUCCESS_MESSAGE: t("USTANOVA_NADJIMED_LABEL_INICIJALIZACIJA_SUCCESS_MESSAGE"),

      /* ustanova eksterni sistem */
      USTANOVA_EKSTERNI_SISTEM: t("USTANOVA_EKSTERNI_SISTEM"),
      EKSTERNI_SISTEM: t("EKSTERNI_SISTEM"),
      BUTTON_ADD_USTANOVA_EKSTERNI_SISTEM: t("BUTTON_ADD_USTANOVA_EKSTERNI_SISTEM"),
      USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_CREATE: t("USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_CREATE"),
      USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_UPDATE: t("USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_UPDATE"),
      USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_DELETE: t("USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_DELETE"),
      USTANOVA_EKSTERNI_SISTEM_TITLE_UPDATE: t("USTANOVA_EKSTERNI_SISTEM_TITLE_UPDATE"),
      USTANOVA_EKSTERNI_SISTEM_TITLE_DETAILS: t("USTANOVA_EKSTERNI_SISTEM_TITLE_DETAILS"),
      USTANOVA_EKSTERNI_SISTEM_TITLE_DELETE: t("USTANOVA_EKSTERNI_SISTEM_TITLE_DELETE"),
      USTANOVA_EKSTERNI_SISTEM_TITLE_CREATE: t("USTANOVA_EKSTERNI_SISTEM_TITLE_CREATE"),
      USTANOVA_EKSTERNI_SISTEM_EKSTERNI_SISTEM_IS_REQUIRED: t("USTANOVA_EKSTERNI_SISTEM_EKSTERNI_SISTEM_IS_REQUIRED"),
      USTANOVA_EKSTERNI_SISTEM_USERNAME_IS_REQUIRED: t("USTANOVA_EKSTERNI_SISTEM_USERNAME_IS_REQUIRED"),
      USTANOVA_EKSTERNI_SISTEM_PASSWORD_IS_REQUIRED: t("USTANOVA_EKSTERNI_SISTEM_PASSWORD_IS_REQUIRED"),
      USTANOVA_EKSTERNI_SISTEM_MESSAGE_CREATE_SUCCESS: t("USTANOVA_EKSTERNI_SISTEM_MESSAGE_CREATE_SUCCESS"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_EKSTERNI_SISTEM_1: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_EKSTERNI_SISTEM_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_EKSTERNI_SISTEM_2: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_EKSTERNI_SISTEM_2"),
      USTANOVA_EKSTERNI_SISTEM_MESSAGE_UPDATE_SUCCESS: t("USTANOVA_EKSTERNI_SISTEM_MESSAGE_UPDATE_SUCCESS"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_EKSTERNI_SISTEM_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_EKSTERNI_SISTEM_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_EKSTERNI_SISTEM_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_EKSTERNI_SISTEM_2"),
      USTANOVA_EKSTERNI_SISTEM_TITLE_MESSAGE_DELETE_USTANOVA_EKSTERNI_SISTEM_SUCCESS: t("USTANOVA_EKSTERNI_SISTEM_TITLE_MESSAGE_DELETE_USTANOVA_EKSTERNI_SISTEM_SUCCESS"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_EKSTERNI_SISTEM_1: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_EKSTERNI_SISTEM_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_EKSTERNI_SISTEM_2: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_EKSTERNI_SISTEM_2"),

      /** organizaciona jedinica */
      SEARCH_ORGANIZACIONA_JEDINICA: t("SEARCH_ORGANIZACIONA_JEDINICA"),
      ORGANIZACIONA_JEDINICA_SEARCH: t("ORGANIZACIONA_JEDINICA_SEARCH"),
      ORGANIZACIONA_JEDINICA_NAZIV: t("ORGANIZACIONA_JEDINICA_NAZIV"),
      ORGANIZACIONA_JEDINICA_TIP: t("ORGANIZACIONA_JEDINICA_TIP"),
      ORGANIZACIONA_JEDINCA_NADREDJENA: t("ORGANIZACIONA_JEDINCA_NADREDJENA"),
      ORGANIZACIONA_JEDINICA_ADRESA: t("ORGANIZACIONA_JEDINICA_ADRESA"),
      ORGANIZACIONA_JEDINICA_TELEFON: t("ORGANIZACIONA_JEDINICA_TELEFON"),
      ORGANIZACIONA_JEDINICA_LIST: t("ORGANIZACIONA_JEDINICA_LIST"),
      ORGANIZACIONA_JEDINICA_TITLE_DIALOG_CREATE: t("ORGANIZACIONA_JEDINICA_TITLE_DIALOG_CREATE"),
      ORGANIZACIONA_JEDINICA_TITLE_DIALOG_UPDATE: t("ORGANIZACIONA_JEDINICA_TITLE_DIALOG_UPDATE"),
      ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DELETE: t("ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DELETE"),
      ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DETAILS: t("ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DETAILS"),
      ORGANIZACIONA_JEDINICA_NADREDJENA_ORGANIZACIONA_JEDINICA: t("ORGANIZACIONA_JEDINICA_NADREDJENA_ORGANIZACIONA_JEDINICA"),
      ORGANIZACIONA_JEDINICA_TIP_ORGANIZACIONE_JEDINICE: t("ORGANIZACIONA_JEDINICA_TIP_ORGANIZACIONE_JEDINICE"),
      ORGANIZACIONA_JEDINICA_NAZIV_IS_REQUIRED: t("ORGANIZACIONA_JEDINICA_NAZIV_IS_REQUIRED"),
      ORGANIZACIONA_JEDINICA_TIP_IS_REQUIRED: t("ORGANIZACIONA_JEDINICA_TIP_IS_REQUIRED"),
      ORGANIZACIONA_JEDINICA_RADNI_DANI_IS_REQUIRED: t("ORGANIZACIONA_JEDINICA_RADNI_DANI_IS_REQUIRED"),
      ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_CREATE_ORGANIZACIONA_JEDINICA_SUCCESS: t("ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_CREATE_ORGANIZACIONA_JEDINICA_SUCCESS"),
      ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_UPDATE_ORGANIZACIONA_JEDINICA_SUCCESS: t("ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_UPDATE_ORGANIZACIONA_JEDINICA_SUCCESS"),
      ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_DELETE_ORGANIZACIONA_JEDINICA_SUCCESS: t("ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_DELETE_ORGANIZACIONA_JEDINICA_SUCCESS"),
      ORGANIZACIONA_JEDINICA_RADNO_VREME_OD: t("ORGANIZACIONA_JEDINICA_RADNO_VREME_OD"),
      ORGANIZACIONA_JEDINICA_RADNO_VREME_DO: t("ORGANIZACIONA_JEDINICA_RADNO_VREME_DO"),
      ORGANIZACIONA_JEDINICA_RADNI_DANI: t("ORGANIZACIONA_JEDINICA_RADNI_DANI"),
      ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE: t("ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE"),
      ORGANIZACIONA_JEDINICA_LABEL_SPECIJALIZACIJE_LIST: t("ORGANIZACIONA_JEDINICA_LABEL_SPECIJALIZACIJE_LIST"),
      ORGANIZACIONA_JEDINICA_LABEL_SPECIJALIZACIJE_LIST_SEARCH: t("ORGANIZACIONA_JEDINICA_LABEL_SPECIJALIZACIJE_LIST_SEARCH"),
      ORGANIZACIONA_JEDINICA_DOSTUPNA_ZA_EKSTERNE_ZAKAZIVACE: t("ORGANIZACIONA_JEDINICA_DOSTUPNA_ZA_EKSTERNE_ZAKAZIVACE"),

      /* radni period */
      RADNI_PERIOD_LIST: t("RADNI_PERIOD_LIST"),
      RADNI_PERIOD_COLUMN_ORGANIZACIONA_JEDINICA: t("RADNI_PERIOD_COLUMN_ORGANIZACIONA_JEDINICA"),
      RADNI_PERIOD_COLUMN_RADNIK: t("RADNI_PERIOD_COLUMN_RADNIK"),
      RADNI_PERIOD_COLUMN_DATUM_OD: t("RADNI_PERIOD_COLUMN_DATUM_OD"),
      RADNI_PERIOD_COLUMN_DATUM_DO: t("RADNI_PERIOD_COLUMN_DATUM_DO"),
      RADNI_PERIOD_COLUMN_POCETAK_PERIODA: t("RADNI_PERIOD_COLUMN_POCETAK_PERIODA"),
      RADNI_PERIOD_COLUMN_KRAJ_PERIODA: t("RADNI_PERIOD_COLUMN_KRAJ_PERIODA"),
      RADNI_PERIOD_BUTTON_ADD: t("RADNI_PERIOD_BUTTON_ADD"),
      RADNI_PERIOD_SEARCH: t("RADNI_PERIOD_SEARCH"),
      RADNI_PERIOD_CREATE: t("RADNI_PERIOD_CREATE"),
      RADNI_PERIOD_COLUMN_RADNI_DANI: t("RADNI_PERIOD_COLUMN_RADNI_DANI"),
      RADNI_PERIOD_VREME_POCETKA: t("RADNI_PERIOD_VREME_POCETKA"),
      RADNI_PERIOD_VREME_KRAJ: t("RADNI_PERIOD_VREME_KRAJ"),
      RADNI_PERIOD_PAUZA_POCETAK: t("RADNI_PERIOD_PAUZA_POCETAK"),
      RADNI_PERIOD_PAUZA_KRAJ: t("RADNI_PERIOD_PAUZA_KRAJ"),
      RADNI_PERIOD_TRAJANJE_PREGLEDA: t("RADNI_PERIOD_TRAJANJE_PREGLEDA"),
      RADNI_PERIOD_OPIS: t("RADNI_PERIOD_OPIS"),
      RADNI_PERIOD_COLUMN_DATUM: t("RADNI_PERIOD_COLUMN_DATUM"),
      RADNI_PERIOD_COLUMN_RADNI_DAN: t("RADNI_PERIOD_COLUMN_RADNI_DAN"),
      RADNI_PERIOD_DANI_U_NEDELJI_IS_REQUIRED: t("RADNI_PERIOD_DANI_U_NEDELJI_IS_REQUIRED"),
      RADNI_PERIOD_TITLE_MESSAGE_CREATE_RADNI_PERIOD_SUCCESS: t("RADNI_PERIOD_TITLE_MESSAGE_CREATE_RADNI_PERIOD_SUCCESS"),
      RADNI_PERIOD_CONFIRM_DELETE_MESSAGE: t("RADNI_PERIOD_CONFIRM_DELETE_MESSAGE"),
      RADNI_PERIOD_CONFIRM_DELETE_SELECTED_MESSAGE: t("RADNI_PERIOD_CONFIRM_DELETE_SELECTED_MESSAGE"),
      RADNI_PERIOD_TITLE_DELETE_SELECTED: t("RADNI_PERIOD_TITLE_DELETE_SELECTED"),
      RADNI_PERIOD_POGRESNO_RADNO_VREME: t("RADNI_PERIOD_POGRESNO_RADNO_VREME"),
      RADNI_PERIOD_POGRESNAN_RADNI_PERIOD: t("RADNI_PERIOD_POGRESNAN_RADNI_PERIOD"),
      RADNI_PERIOD_DATUM_OD_REQUIRED: t("RADNI_PERIOD_DATUM_OD_REQUIRED"),
      RADNI_PERIOD_DATUM_DO_REQUIRED: t("RADNI_PERIOD_DATUM_DO_REQUIRED"),
      RADNI_PERIOD_VREME_POCETKA_RADNOG_DANA_REQUIRED: t("RADNI_PERIOD_VREME_POCETKA_RADNOG_DANA_REQUIRED"),
      RADNI_PERIOD_VREME_KRAJA_RADNOG_DANA_REQUIRED: t("RADNI_PERIOD_VREME_KRAJA_RADNOG_DANA_REQUIRED"),
      RADNI_PERIOD_POGRESNA_PAUZA: t("RADNI_PERIOD_POGRESNA_PAUZA"),
      RADNI_PERIOD_NEDOSTUPAN_ZA_EKSTERNE_ZAKAZIVACE: t("RADNI_PERIOD_NEDOSTUPAN_ZA_EKSTERNE_ZAKAZIVACE"),

      /* vrsta pregleda */
      TIP_KONTAKTA: t("TIP_KONTAKTA"),
      TIP_KONTAKTA_LIST: t("TIP_KONTAKTA_LIST"),
      TIP_KONTAKTA_NAZIV: t("TIP_KONTAKTA_NAZIV"),
      TIP_KONTAKTA_SIFRA: t("TIP_KONTAKTA_SIFRA"),
      TIP_KONTAKTA_AUTOMATSKO_ZAVRSAVANJE: t("TIP_KONTAKTA_AUTOMATSKO_ZAVRSAVANJE"),
      TIP_KONTAKTA_KATEGORIJA_KONTAKTA: t("TIP_KONTAKTA_KATEGORIJA_KONTAKTA"),
      TIP_KONTAKTA_BUTTON_SEARCH: t("TIP_KONTAKTA_BUTTON_SEARCH"),
      TIP_KONTAKTA_TITLE_DIALOG_CREATE: t("TIP_KONTAKTA_TITLE_DIALOG_CREATE"),
      TIP_KONTAKTA_TITLE_DIALOG_UPDATE: t("TIP_KONTAKTA_TITLE_DIALOG_UPDATE"),
      TIP_KONTAKTA_TITLE_DIALOG_DELETE: t("TIP_KONTAKTA_TITLE_DIALOG_DELETE"),
      TIP_KONTAKTA_TITLE_DIALOG_DETAILS: t("TIP_KONTAKTA_TITLE_DIALOG_DETAILS"),
      TIP_KONTAKTA_TITLE_MESSAGE_DELETE_TIP_KONTAKTA_SUCCESS: t("TIP_KONTAKTA_TITLE_MESSAGE_DELETE_TIP_KONTAKTA_SUCCESS"),
      TIP_KONTAKTA_TITLE_MESSAGE_CREATE_TIP_KONTAKTA_SUCCESS: t("TIP_KONTAKTA_TITLE_MESSAGE_CREATE_TIP_KONTAKTA_SUCCESS"),
      TIP_KONTAKTA_TITLE_MESSAGE_UPDATE_TIP_KONTAKTA_SUCCESS: t("TIP_KONTAKTA_TITLE_MESSAGE_UPDATE_TIP_KONTAKTA_SUCCESS"),
      TIP_KONTAKTA_NAZIV_IS_REQUIRED: t("TIP_KONTAKTA_NAZIV_IS_REQUIRED"),
      TIP_KONTAKTA_SIFRA_IS_REQUIRED: t("TIP_KONTAKTA_SIFRA_IS_REQUIRED"),
      TIP_KONTAKTA_KATEGORIJA_KONTAKTA_IS_REQUIRED: t("TIP_KONTAKTA_KATEGORIJA_KONTAKTA_IS_REQUIRED"),
      TIP_KONTAKTA_ENTITY_STATUS_IS_REQUIRED: t("TIP_KONTAKTA_ENTITY_STATUS_IS_REQUIRED"),
      TIP_KONTAKTA_VREME_TRAJANJA_PREGLEDA: t("TIP_KONTAKTA_VREME_TRAJANJA_PREGLEDA"),
      TIP_KONTAKTA_PODRAZUMEVANI_FORMULAR: t("TIP_KONTAKTA_PODRAZUMEVANI_FORMULAR"),

      IMPORT_TIP_KONTAKTA_REPOSITORY: t("IMPORT_TIP_KONTAKTA_REPOSITORY"),
      TIP_KONTAKTA_REPOSITORY: t("TIP_KONTAKTA_REPOSITORY"),

      /*uloga*/
      SEARCH_ULOGA: t("SEARCH_ULOGA"),
      ULOGA_LIST: t("ULOGA_LIST"),
      ULOGA_SIFRA: t("ULOGA_SIFRA"),
      ULOGA_NAZIV: t("ULOGA_NAZIV"),
      ULOGA_OPIS: t("ULOGA_OPIS"),
      ULOGA_NAPOMENA: t("ULOGA_NAPOMENA"),
      ULOGA_ROLA: t("ULOGA_ROLA"),
      ULOGA_SEARCH: t("ULOGA_SEARCH"),
      ULOGA_TITLE_DIALOG_CREATE: t("ULOGA_TITLE_DIALOG_CREATE"),
      ULOGA_TITLE_DIALOG_UPDATE: t("ULOGA_TITLE_DIALOG_UPDATE"),
      ULOGA_TITLE_DIALOG_DELETE: t("ULOGA_TITLE_DIALOG_DELETE"),
      ULOGA_TITLE_DIALOG_DETAILS: t("ULOGA_TITLE_DIALOG_DETAILS"),
      ULOGA_TITLE_MESSAGE_DELETE_ULOGA_SUCCESS: t("ULOGA_TITLE_MESSAGE_DELETE_ULOGA_SUCCESS"),
      ULOGA_TITLE_MESSAGE_CREATE_ULOGA_SUCCESS: t("ULOGA_TITLE_MESSAGE_CREATE_ULOGA_SUCCESS"),
      ULOGA_TITLE_MESSAGE_UPDATE_ULOGA_SUCCESS: t("ULOGA_TITLE_MESSAGE_UPDATE_ULOGA_SUCCESS"),
      ULOGA_NAZIV_IS_REQUIRED: t("ULOGA_NAZIV_IS_REQUIRED"),
      ULOGA_SIFRA_IS_REQUIRED: t("ULOGA_SIFRA_IS_REQUIRED"),
      ULOGA_ROLA_IS_REQUIRED: t("ULOGA_ROLA_IS_REQUIRED"),

      // Zakazivanje pregleda
      ZAKAZIVANJE_ZAKAZIVANJE_PREGLEDA: t("ZAKAZIVANJE_ZAKAZIVANJE_PREGLEDA"),
      ZAKAZIVANJE_TERMINI: t("ZAKAZIVANJE_TERMINI"),
      ZAKAZIVANJE_ZAKAZIVANJE: t("ZAKAZIVANJE_ZAKAZIVANJE"),
      ZAKAZIVANJE_CEKAONICA: t("ZAKAZIVANJE_CEKAONICA"),
      ZAKAZIVANJE_PRETRAGA_PACIJENATA: t("ZAKAZIVANJE_PRETRAGA_PACIJENATA"),
      ZAKAZIVANJE_RADNIK_IS_REQUIRED: t("ZAKAZIVANJE_RADNIK_IS_REQUIRED"),
      ZAKAZIVANJE_PREZIME_IS_REQUIRED: t("ZAKAZIVANJE_PREZIME_IS_REQUIRED"),
      ZAKAZIVANJE_IME_IS_REQUIRED: t("ZAKAZIVANJE_IME_IS_REQUIRED"),
      ZAKAZIVANJE_TIP_KONTAKTA_IS_REQUIRED: t("ZAKAZIVANJE_TIP_KONTAKTA_IS_REQUIRED"),
      ZAKAZIVANJE_PROMENA_TERMINA_PROMENI: t("ZAKAZIVANJE_PROMENA_TERMINA_PROMENI"),
      ZAKAZIVANJE_PROMENA_TERMINA_ODUSTANI: t("ZAKAZIVANJE_PROMENA_TERMINA_ODUSTANI"),
      ZAKAZIVANJE_PROMENA_TERMINA_DIALOG_POTVRDA: t("ZAKAZIVANJE_PROMENA_TERMINA_DIALOG_POTVRDA"),
      ZAKAZIVANJE_PROMENA_TERMINA_USPESNO: t("ZAKAZIVANJE_PROMENA_TERMINA_USPESNO"),
      CEKAONICA_COLUMN_SVE: t("CEKAONICA_COLUMN_SVE"),
      CEKAONICA_COLUMN_STATUS: t("CEKAONICA_COLUMN_STATUS"),
      CEKAONICA_COLUMN_VREME: t("CEKAONICA_COLUMN_VREME"),
      CEKAONICA_COLUMN_PACIJENT: t("CEKAONICA_COLUMN_PACIJENT"),
      CEKAONICA_COLUMN_VREME_DOLASKA: t("CEKAONICA_COLUMN_VREME_DOLASKA"),
      CEKAONICA_COLUMN_DATUM_RODJENJA: t("CEKAONICA_COLUMN_DATUM_RODJENJA"),
      CEKAONICA_COLUMN_DIJAGNOZE: t("CEKAONICA_COLUMN_DIJAGNOZE"),
      CEKAONICA_COLUMN_NALAZ: t("CEKAONICA_COLUMN_NALAZ"),
      CEKAONICA_COLUMN_ZAPOCNI_TERMIN: t("CEKAONICA_COLUMN_ZAPOCNI_TERMIN"),
      CEKAONICA_COLUMN_ZAPOCNI_TERMIN_TOOLTIP: t("CEKAONICA_COLUMN_ZAPOCNI_TERMIN_TOOLTIP"),
      CEKAONICA_COLUMN_ZAVRSI_TERMIN: t("CEKAONICA_COLUMN_ZAVRSI_TERMIN"),
      CEKAONICA_COLUMN_ZAVRSI_TERMIN_TOOLTIP: t("CEKAONICA_COLUMN_ZAVRSI_TERMIN_TOOLTIP"),
      CEKAONICA_PACIJENT_ORDINACIJA: t("CEKAONICA_PACIJENT_ORDINACIJA"),
      CEKAONICA_PACIJENT_VREME: t("CEKAONICA_PACIJENT_VREME"),
      CEKAONICA_PACIJENT_PACIJENT: t("CEKAONICA_PACIJENT_PACIJENT"),
      CEKAONICA_PACIJENT_DATUM_RODJENJA: t("CEKAONICA_PACIJENT_DATUM_RODJENJA"),
      CEKAONICA_PACIJENT_ADRESA: t("CEKAONICA_PACIJENT_ADRESA"),
      CEKAONICA_PACIJENT_BROJ_TELEFONA: t("CEKAONICA_PACIJENT_BROJ_TELEFONA"),
      CEKAONICA_PACIJENT_USLUGA: t("CEKAONICA_PACIJENT_USLUGA"),
      LISTA_RADNIKA_LISTA_DOKTORA: t("LISTA_RADNIKA_LISTA_DOKTORA"),
      LISTA_RADNIKA_PRETRAGA_PLACEHOLDER: t("LISTA_RADNIKA_PRETRAGA_PLACEHOLDER"),
      DA_LI_STE_SIGURNI_ZATVARANJE_KONTAKATA: t("DA_LI_STE_SIGURNI_ZATVARANJE_KONTAKATA"),
      CEKAONICA_BUTTON_PROMENA_DOKTORA: t("CEKAONICA_BUTTON_PROMENA_DOKTORA"),
      CEKAONICA_BUTTON_ZATVARANJE_KONTAKATA: t("CEKAONICA_BUTTON_ZATVARANJE_KONTAKATA"),
      DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE: t("DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE"),
      DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_POPUNJEN_FORMULAR: t("DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_POPUNJEN_FORMULAR"),
      DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_VREME_SMRTI: t("DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_VREME_SMRTI"),
      DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_JR_DOKUMENT: t("DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_JR_DOKUMENT"),
      DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_SABLON: t("DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_SABLON"),
      DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_PACIJENT: t("DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_PACIJENT"),
      DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_PROFILNU_SLIKU: t("DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_PROFILNU_SLIKU"),
      IRREVERSIBLE_ACTION: t("IRREVERSIBLE_ACTION"),
      PERMANENT_DELETE_PART_1: t("PERMANENT_DELETE_PART_1"),
      PERMANENT_DELETE_PART_2: t("PERMANENT_DELETE_PART_2"),
      NIJE_IZABRAN: t("NIJE_IZABRAN"),
      PRIMARNI_DOKTOR: t("PRIMARNI_DOKTOR"),
      PRIMARNI_PACIJENT: t("PRIMARNI_PACIJENT"),
      VANREDNI: t("VANREDNI"),
      LEGENDA: t("LEGENDA"),
      RESET_PASSWORD_POPUP_MESSAGE: t("RESET_PASSWORD_POPUP_MESSAGE"),
      DELETE_ZAKAZAN_TERMIN: t("DELETE_ZAKAZAN_TERMIN"),
      CONFIRM_DELETE_ZAKAZAN_TERMIN: t("CONFIRM_DELETE_ZAKAZAN_TERMIN"),
      DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_KONTAKT: t("DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_KONTAKT"),
      DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_UPOZORENJE: t("DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_UPOZORENJE"),
      NESACUVAN_NALAZ: t("NESACUVAN_NALAZ"),
      CEKAONICA_COLUMN_ADRESA: t("CEKAONICA_COLUMN_ADRESA"),
      CEKAONICA_COLUMN_TELEFON: t("CEKAONICA_COLUMN_TELEFON"),
      CEKAONICA_COLUMN_EMAIL: t("CEKAONICA_COLUMN_EMAIL"),
      CEKAONICA_PACIJENT_EMAIL: t("CEKAONICA_PACIJENT_EMAIL"),
      ZAKAZIVANJE_PREGLEDA_UPOZORENJE_PACIJENT: t("ZAKAZIVANJE_PREGLEDA_UPOZORENJE_PACIJENT"),
      ZAKAZIVANJE_PREGLEDA_UPDATE_UPOZORENJE_PACIJENT: t("ZAKAZIVANJE_PREGLEDA_UPDATE_UPOZORENJE_PACIJENT"),
      ZAKAZIVANJE_PREGLEDA_ADD_COMMENT_SUCCESS_MESS: t("ZAKAZIVANJE_PREGLEDA_ADD_COMMENT_SUCCESS_MESS"),
      ZAKAZIVANJE_PREGLEDA_ADD_FILE_BUTTON_LABEL: t("ZAKAZIVANJE_PREGLEDA_ADD_FILE_BUTTON_LABEL"),
      ZAKAZIVANJE_PREGLEDA_ADD_FILE_SUCCESS_MESS: t("ZAKAZIVANJE_PREGLEDA_ADD_FILE_SUCCESS_MESS"),
      ZAKAZIVANJE_PREGLEDA_REDOVNI_TERMIN: t("ZAKAZIVANJE_PREGLEDA_REDOVNI_TERMIN"),
      ZAKAZIVANJE_PREGLEDA_VANREDNI_TERMIN: t("ZAKAZIVANJE_PREGLEDA_VANREDNI_TERMIN"),
      PRIKAZI_NEAKTIVNE_RADNIKE: t("PRIKAZI_NEAKTIVNE_RADNIKE"),
      POCETAK_I_KRAJ_TERMINA_NOT_VALID: t("POCETAK_I_KRAJ_TERMINA_NOT_VALID"),
      DATUM_I_VREME_REQUIRED: t("DATUM_I_VREME_REQUIRED"),

      POL: t("POL"),
      DATUM_RODJENJA: t("DATUM_RODJENJA"),
      OSIGURANJE: t("OSIGURANJE"),
      JMBG: t("JMBG"),
      UZRAST: t("UZRAST"),

      DOKUMENTACIJA: t("DOKUMENTACIJA"),
      ARHIVIRANI_PODACI: t("ARHIVIRANI_PODACI"),
      ISTORIJA_OSIGURANJA: t("ISTORIJA_OSIGURANJA"),
      PODACI_O_PACIJENTU: t("PODACI_O_PACIJENTU"),
      PODACI_O_SMRTI: t("PODACI_O_SMRTI"),
      VREME_SMRTI: t("VREME_SMRTI"),
      ISPRAVA: t("ISPRAVA"),
      POLISE_OSIGURANJA: t("POLISE_OSIGURANJA"),
      BROJ_POLISE: t("BROJ_POLISE"),
      OSIGURANJE_TIP: t("OSIGURANJE_TIP"),
      OSIGURANJE_TIP_PRIVATNO: t("OSIGURANJE_TIP_PRIVATNO"),
      OSIGURANJE_TIP_PARTNER: t("OSIGURANJE_TIP_PARTNER"),
      PRIVATNIK_NAZIV: t("PRIVATNIK_NAZIV"),
      PARTNER_NAZIV: t("PARTNER_NAZIV"),
      VAZI_OD: t("VAZI_OD"),
      VAZI_DO: t("VAZI_DO"),
      PIB: t("PIB"),
      VREME_POSLEDNJE_IZMENE: t("VREME_POSLEDNJE_IZMENE"),
      VREME_OD: t("VREME_OD"),
      VREME_DO: t("VREME_DO"),
      PODACI_O_KONTAKTU: t("PODACI_O_KONTAKTU"),
      PROTOKOL: t("PROTOKOL"),
      LICNI_PODACI: t("LICNI_PODACI"),
      PODESAVANJA_ZAKAZIVANJA: t("PODESAVANJA_ZAKAZIVANJA"),
      PODESAVANJA_ZAKAZIVANJA_POLJE: t("PODESAVANJA_ZAKAZIVANJA_POLJE"),
      USTANOVA: t("USTANOVA"),
      NALAZ: t("NALAZ"),
      YES: t("YES"),
      NO: t("NO"),
      FORMULAR_NAME: t("FORMULAR_NAME"),
      OBRISI_KONTAKT_TOOLTIP: t("OBRISI_KONTAKT_TOOLTIP"),
      NAPLATI_KONTAKT_TOOLTIP: t("NAPLATI_KONTAKT_TOOLTIP"),
      DOKUMENT: t("DOKUMENT"),
      CHOOSE_DOCUMENT: t("CHOOSE_DOCUMENT"),
      REMOVE_DOKUMENT: t("REMOVE_DOKUMENT"),
      DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_DOKUMENT: t("DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_DOKUMENT"),
      DOKUMENT_ADD: t("DOKUMENT_ADD"),
      EMAIL_WRONG_FORMAT: t("EMAIL_WRONG_FORMAT"),
      TELEFON_WRONG_FORMAT: t("TELEFON_WRONG_FORMAT"),
      PODACI: t("PODACI"),
      ZAKAZANI_PREGLEDI_LABEL: t("ZAKAZANI_PREGLEDI_LABEL"),
      ZAKAZANI_PREGLEDI_VREME_PREGLEDA: t("ZAKAZANI_PREGLEDI_VREME_PREGLEDA"),
      ZAKAZANI_PREGLEDI_ORGANIZACIONA_JEDINICA: t("ZAKAZANI_PREGLEDI_ORGANIZACIONA_JEDINICA"),
      ZAKAZANI_PREGLEDI_TIP_PREGLEDA: t("ZAKAZANI_PREGLEDI_TIP_PREGLEDA"),
      ZAKAZANI_PREGLEDI_PRIMARNI_DOKTOR: t("ZAKAZANI_PREGLEDI_PRIMARNI_DOKTOR"),

      ADMINISTRATIVNI_PODACI: t("ADMINISTRATIVNI_PODACI"),
      KONTAKT_OSOBE: t("KONTAKT_OSOBE"),
      MEDICINSKI_PODACI: t("MEDICINSKI_PODACI"),
      PARTNER: t("PARTNER"),
      OPSTI_MEDICINSKI_PODACI: t("OPSTI_MEDICINSKI_PODACI"),

      PRETHODNI_NALAZI: t("PRETHODNI_NALAZI"),
      PRETHODNI_NALAZI_NOT_FOUND: t("PRETHODNI_NALAZI_NOT_FOUND"),
      SABLON_LIST: t("SABLON_LIST"),
      SABLON_ISPIS: t("SABLON_ISPIS"),
      SABLON_CHOOSE_POLJE: t("SABLON_CHOOSE_POLJE"),
      SABLON_ADD: t("SABLON_ADD"),
      SABLON_UPDATE: t("SABLON_UPDATE"),
      SABLON_NASLOV: t("SABLON_NASLOV"),
      SABLON_TEKST: t("SABLON_TEKST"),
      SABLON_NASLOV_IS_REQUIRED: t("SABLON_NASLOV_IS_REQUIRED"),
      SABLON_TEKST_IS_REQUIRED: t("SABLON_TEKST_IS_REQUIRED"),
      SABLON_POLJE_NAZIV: t("SABLON_POLJE_NAZIV"),

      PREGLED_NALAZA_DIJAGNOZA: t("PREGLED_NALAZA_DIJAGNOZA"),
      PREGLED_NALAZA_USLUGA: t("PREGLED_NALAZA_USLUGA"),
      PREGLED_NALAZA_KOLICINA: t("PREGLED_NALAZA_KOLICINA"),
      PREGLED_NALAZA_STAMPAJ: t("PREGLED_NALAZA_STAMPAJ"),
      PREGLED_NALAZA_PRISTUP_KONTAKTU: t("PREGLED_NALAZA_PRISTUP_KONTAKTU"),
      RIZICI: t("RIZICI"),
      PREGLED_NALAZA_PRIKAZI: t("PREGLED_NALAZA_PRIKAZI"),

      LISTA_DOKUMENATA_NASLOV: t("LISTA_DOKUMENATA_NASLOV"),
      LISTA_DOKUMENATA_ORG_JED: t("LISTA_DOKUMENATA_ORG_JED"),
      LISTA_DOKUMENATA_DATUM: t("LISTA_DOKUMENATA_DATUM"),

      PRETRAGA_PACIJENTA: t("PRETRAGA_PACIJENTA"),
      LISTA_DATOTEKA: t("LISTA_DATOTEKA"),
      MESSAGES_DELETE_DOCUMENT_SUCCESS: t("MESSAGES_DELETE_DOCUMENT_SUCCESS"),
      DODAJ_DATOTEKU_PACIJENT_DOSIJE: t("DODAJ_DATOTEKU_PACIJENT_DOSIJE"),
      DODAJ_DATOTEKU_KONTAKT: t("DODAJ_DATOTEKU_KONTAKT"),
      LISTA_DATOTEKA_PRAZNA: t("LISTA_DATOTEKA_PRAZNA"),
      DATOTEKE: t("DATOTEKE"),
      UPOZORENJE: t("UPOZORENJE"),
      POSTAVI_UPOZORENJE: t("POSTAVI_UPOZORENJE"),
      OBRISI_UPOZORENJE: t("OBRISI_UPOZORENJE"),
      NOVO_UPOZORENJE: t("NOVO_UPOZORENJE"),

      ZAKAZI_TERMIN: t("ZAKAZI_TERMIN"),
      ZAKAZI_VANREDAN_TERMIN: t("ZAKAZI_VANREDAN_TERMIN"),
      ZAKAZAN_TERMIN: t("ZAKAZAN_TERMIN"),
      ZAKAZAN_VANREDAN_TERMIN: t("ZAKAZAN_VANREDAN_TERMIN"),
      ZAKAZI: t("ZAKAZI"),
      LEKAR: t("LEKAR"),
      SESTRA: t("SESTRA"),
      VREME: t("VREME"),
      ZAKAZAN_TERMIN_STATUS: t("ZAKAZAN_TERMIN_STATUS"),
      ZAKAZAN_TERMIN_PLANIRANE_USLUGE: t("ZAKAZAN_TERMIN_PLANIRANE_USLUGE"),
      ZAKAZAN_TERMIN_PLANIRANE_USLUGE_PRETRAGA_USLUGE: t("ZAKAZAN_TERMIN_PLANIRANE_USLUGE_PRETRAGA_USLUGE"),
      ZAKAZAN_TERMIN_PLANIRANE_USLUGE_EMPTY_FILTER_MESSAGE: t("ZAKAZAN_TERMIN_PLANIRANE_USLUGE_EMPTY_FILTER_MESSAGE"),
      ZAKAZAN_TERMIN_PLANIRANE_USLUGE_EMPTY_OPTIONS_MESSAGE: t("ZAKAZAN_TERMIN_PLANIRANE_USLUGE_EMPTY_OPTIONS_MESSAGE"),
      ZAKAZI_PREGLED: t("ZAKAZI_PREGLED"),
      POSTOJECI_PACIJENT: t("POSTOJECI_PACIJENT"),
      NOVI_PACIJENT: t("NOVI_PACIJENT"),
      ZAKAZIVANJE_PODACI_O_TERMINU: t("ZAKAZIVANJE_PODACI_O_TERMINU"),
      ZAKAZIVANJE_PODACI_O_PACIJENTU: t("ZAKAZIVANJE_PODACI_O_PACIJENTU"),
      ZAKAZIVANJE_DATOTEKE: t("ZAKAZIVANJE_DATOTEKE"),
      ZAKAZIVANJE_KOMENTARI: t("ZAKAZIVANJE_KOMENTARI"),

      DA_LI_STE_SIGURNI_PROMENA_PODRAZUMEVANE_STRANE: t("DA_LI_STE_SIGURNI_PROMENA_PODRAZUMEVANE_STRANE"),

      // kontakt
      KONTAKT: t("KONTAKT"),
      NALAZI: t("NALAZI"),
      DIJAGNOZE: t("DIJAGNOZE"),
      USLUGE: t("USLUGE"),
      TERAPIJE: t("TERAPIJE"),
      PAKETI: t("PAKETI"),
      KONTAKT_NOVI_NALAZ: t("KONTAKT_NOVI_NALAZ"),
      KONTAKT_NOVI_UCESNIK: t("KONTAKT_NOVI_UCESNIK"),
      KONTAKT_NAPRAVLJENI_NALAZI: t("KONTAKT_NAPRAVLJENI_NALAZI"),
      KONTAKT_ODUSTANI: t("KONTAKT_ODUSTANI"),
      KONTAKT_IDI: t("KONTAKT_IDI"),
      KONTAKT_NAZAD: t("KONTAKT_NAZAD"),
      KONTAKT_NALAZ: t("KONTAKT_NALAZ"),
      KONTAKT_DETALJI_PREGLEDA: t("KONTAKT_DETALJI_PREGLEDA"),
      KONTAKT_KREIRAJ_NOVI_NALAZ: t("KONTAKT_KREIRAJ_NOVI_NALAZ"),
      KONTAKT_KREIRAJ_NOVI_UCESNIK: t("KONTAKT_KREIRAJ_NOVI_UCESNIK"),
      KONTAKT_PRETHODNI_NALAZI_SABLONI: t("KONTAKT_PRETHODNI_NALAZI_SABLONI"),
      KONTAKT_UVECAJ_UNOS: t("KONTAKT_UVECAJ_UNOS"),
      KONTAKT_SMANJI_UNOS: t("KONTAKT_SMANJI_UNOS"),
      KONTAKT_SVI_NALAZI: t("KONTAKT_SVI_NALAZI"),
      SAVE_KONTAKT_PODACI: t("SAVE_KONTAKT_PODACI"),
      KONTAKT_PODACI_USPESNO_IZMENJEN: t("PACIJENT_USPESNO_IZMENJEN"),
      KONTAKT_UCESNIK_DELETE_SUCCESS: t("KONTAKT_UCESNIK_DELETE_SUCCESS"),
      KONTAKT_UCESNIK_CREATE_SUCCESS: t("KONTAKT_UCESNIK_CREATE_SUCCESS"),
      KONTAKT_CONFIRM_DELETE_UCESNIK: t("KONTAKT_CONFIRM_DELETE_UCESNIK"),
      KONTAKT_NOVA_TERAPIJA: t("KONTAKT_NOVA_TERAPIJA"),
      KONTAKT_NOVA_TERAPIJA_CREATE: t("KONTAKT_NOVA_TERAPIJA_CREATE"),
      KONTAKT_PREPISANE_TERAPIJE: t("KONTAKT_PREPISANE_TERAPIJE"),
      KONTAKT_TERAPIJA_DELETE: t("KONTAKT_TERAPIJA_DELETE"),
      KONTAKT_TERAPIJA_DELETE_SUCCESS: t("KONTAKT_TERAPIJA_DELETE_SUCCESS"),
      KONTAKT_TERAPIJA_VIEW: t("KONTAKT_TERAPIJA_VIEW"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_DIJAGNOZA: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_DIJAGNOZA"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK_ATRIBUT: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK_ATRIBUT"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_TIP_TERAPIJE: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_TIP_TERAPIJE"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PROTOKOLA: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PROTOKOLA"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_IZDAVANJA: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_IZDAVANJA"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_TRAJANJE_TERAPIJE: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_TRAJANJE_TERAPIJE"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA_MESEC: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA_MESEC"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA_PAKOVANJE: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA_PAKOVANJE"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_DNEVNO: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_DNEVNO"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_UJUTRO: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_UJUTRO"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_POPODNE: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_POPODNE"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_UVECE: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_UVECE"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_NA: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_NA"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_DANA: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_DANA"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_PO_POTREBI: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_PO_POTREBI"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_PROIZVOLJNO: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_PROIZVOLJNO"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_HITNO_IZDAVANJE: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_HITNO_IZDAVANJE"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_ZABRANA_TERAPIJSKE_PARALELE: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_ZABRANA_TERAPIJSKE_PARALELE"),
      KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NAPOMENA: t("KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NAPOMENA"),
      KONTAKT_TERAPIJA_DIALOG_TITLE: t("KONTAKT_TERAPIJA_DIALOG_TITLE"),
      KONTAKT_TERAPIJA_DIALOG_FIRST_PAGE_TITLE: t("KONTAKT_TERAPIJA_DIALOG_FIRST_PAGE_TITLE"),
      KONTAKT_TERAPIJA_DIALOG_FIRST_PAGE_NO_DIAGNOSIS_NOTE: t("KONTAKT_TERAPIJA_DIALOG_FIRST_PAGE_NO_DIAGNOSIS_NOTE"),
      KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_TITLE: t("KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_TITLE"),
      KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_PRETRAGA_LEKA: t("KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_PRETRAGA_LEKA"),
      KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_ATRIBUT_LEKA: t("KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_ATRIBUT_LEKA"),
      KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_TIP_TERAPIJE: t("KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_TIP_TERAPIJE"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_TITLE: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_TITLE"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_DANA: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_DANA"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_MESECI: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_MESECI"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_BROJ_PAKOVANJA: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_BROJ_PAKOVANJA"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNAKO: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNAKO"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UJUTRU: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UJUTRU"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_U_PODNE: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_U_PODNE"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UVECE: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UVECE"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_NA_SVAKIH: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_NA_SVAKIH"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_PO_POTREBI: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_PO_POTREBI"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_PROIZVOLJNO: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_PROIZVOLJNO"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO_PRUZANJE: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO_PRUZANJE"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DANA_PO: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DANA_PO"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DATI_PO_POTREBI: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DATI_PO_POTREBI"),
      KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_JEDINICA_MERE: t("KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_JEDINICA_MERE"),
      KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_TITLE: t("KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_TITLE"),
      KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_IZDAVANJE_LEKA_HITNO: t("KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_IZDAVANJE_LEKA_HITNO"),
      KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_ZABRANA_TERAPIJSKE_PARALELE: t("KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_ZABRANA_TERAPIJSKE_PARALELE"),
      KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_NAPOMENA: t("KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_NAPOMENA"),
      KONTAKT_TERAPIJA_STAMPA_TOOLTIP: t("KONTAKT_TERAPIJA_STAMPA_TOOLTIP"),
      KONTAKT_TERAPIJA_STAMPA_POPUP_MESSAGE: t("KONTAKT_TERAPIJA_STAMPA_POPUP_MESSAGE"),
      KONTAKT_PAKETI: t("KONTAKT_PAKETI"),
      KONTAKT_PAKETI_NOVI_PAKET: t("KONTAKT_PAKETI_NOVI_PAKET"),
      KONTAKT_PAKETI_PRUZENI_TOKOM_PREGLEDA: t("KONTAKT_PAKETI_PRUZENI_TOKOM_PREGLEDA"),
      KONTAKT_PAKETI_LABEL_PAKET: t("KONTAKT_PAKETI_LABEL_PAKET"),
      KONTAKT_PAKETI_LABEL_USLUGE: t("KONTAKT_PAKETI_LABEL_USLUGE"),
      KONTAKT_PAKETI_LABEL_UTROSCI: t("KONTAKT_PAKETI_LABEL_UTROSCI"),
      KONTAKT_PAKETI_LABEL_POSTAVIO: t("KONTAKT_PAKETI_LABEL_POSTAVIO"),
      KONTAKT_PAKETI_LABEL_POSTAVIO_MUSKI: t("KONTAKT_PAKETI_LABEL_POSTAVIO_MUSKI"),
      KONTAKT_PAKETI_LABEL_POSTAVIO_ZENSKI: t("KONTAKT_PAKETI_LABEL_POSTAVIO_ZENSKI"),
      KONTAKT_PAKETI_LABEL_IZMENI: t("KONTAKT_PAKETI_LABEL_IZMENI"),
      KONTAKT_PAKETI_LABEL_OBRISI: t("KONTAKT_PAKETI_LABEL_OBRISI"),
      KONTAKT_PAKETI_DIALOG_LABEL_SNIMI: t("KONTAKT_PAKETI_DIALOG_LABEL_SNIMI"),
      KONTAKT_PAKETI_DIALOG_LABEL_ODABERITE_PAKET: t("KONTAKT_PAKETI_DIALOG_LABEL_ODABERITE_PAKET"),
      KONTAKT_PAKETI_DIALOG_LABEL_USLUGE_U_OKVIRU_PAKETA: t("KONTAKT_PAKETI_DIALOG_LABEL_USLUGE_U_OKVIRU_PAKETA"),
      KONTAKT_PAKETI_DIALOG_LABEL_UTROSCI_U_OKVIRU_PAKETA: t("KONTAKT_PAKETI_DIALOG_LABEL_UTROSCI_U_OKVIRU_PAKETA"),
      KONTAKT_PAKETI_DIALOG_CREATE_SUCCESS: t("KONTAKT_PAKETI_DIALOG_CREATE_SUCCESS"),
      KONTAKT_PAKETI_DELETE_SUCESS: t("KONTAKT_PAKETI_DELETE_SUCESS"),
      KONTAKT_PAKETI_UPDATE_SUCCESS: t("KONTAKT_PAKETI_UPDATE_SUCCESS"),
      KONTAKT_PAKET_MANDATORY_FIELD: t("KONTAKT_PAKET_MANDATORY_FIELD"),
      NO_PROIZVOD_USLUGA_PAKET_CENA_WARNING: t("NO_PROIZVOD_USLUGA_PAKET_CENA_WARNING"),

      // faktori rizika
      DUVAN: t("DUVAN"),
      ALKOHOL: t("ALKOHOL"),
      SLABA_ISHRANA_VOCE_POVRCE: t("SLABA_ISHRANA_VOCE_POVRCE"),
      FIZICKA_NEKATIVNOST: t("FIZICKA_NEKATIVNOST"),
      VISOK_KRVI_PRITISAK: t("VISOK_KRVI_PRITISAK"),
      VISOK_HOLESTEROL: t("VISOK_HOLESTEROL"),
      GOJAZNOST: t("GOJAZNOST"),
      VISOK_NIVO_SECERA: t("VISOK_NIVO_SECERA"),
      NEUHRANJENOST: t("NEUHRANJENOST"),

      CONFIRM_CREATE_OSIGURANJE: t("CONFIRM_CREATE_OSIGURANJE"),
      CONFIRM_UPDATE_OSIGURANJE: t("CONFIRM_UPDATE_OSIGURANJE"),
      CONFIRM_DELETE_ARHIVIRANO_OSIGURANJE: t("CONFIRM_DELETE_ARHIVIRANO_OSIGURANJE"),
      CONFIRM_UPDATE_ARHIVIRANO_OSIGURANJE: t("CONFIRM_UPDATE_ARHIVIRANO_OSIGURANJE"),
      MESSAGE_CREATE_OSIGURANJE_SUCCESS: t("MESSAGE_CREATE_OSIGURANJE_SUCCESS"),
      MESSAGE_DELETE_OSIGURANJE_SUCCESS: t("MESSAGE_DELETE_OSIGURANJE_SUCCESS"),
      MESSAGE_DELETE_ARHIVIRANO_OSIGURANJE_SUCCESS: t("MESSAGE_DELETE_ARHIVIRANO_OSIGURANJE_SUCCESS"),
      MESSAGE_UPDATE_OSIGURANJE_SUCCESS: t("MESSAGE_UPDATE_OSIGURANJE_SUCCESS"),

      TERMINI_NEDELJNI_PRIKAZ: t("TERMINI_NEDELJNI_PRIKAZ"),
      TERMINI_DNEVNI_PRIKAZ: t("TERMINI_DNEVNI_PRIKAZ"),

      LABEL_PRAVO_NAD_TIPOVIMA: t("LABEL_PRAVO_NAD_TIPOVIMA"),
      LABEL_TIPOVI_PRAVA: t("LABEL_TIPOVI_PRAVA"),

      //ADMIN PANEL
      ADMIN_PANEL: t("ADMIN_PANEL"),
      PRETRAGA: t("PRETRAGA"),
      SEARCH_PARAMETAR: t("SEARCH_PARAMETAR"),
      USTANOVA_RADNIK_LIST: t("USTANOVA_RADNIK_LIST"),
      PARAMETAR_SAVE_SUCCESS: t("PARAMETAR_SAVE_SUCCESS"),
      PARAMETAR_SAVE_FAIL_UNDEFINED: t("PARAMETAR_SAVE_FAIL_UNDEFINED"),

      RADNIK_COPY_TITLE_DIALOG: t("RADNIK_COPY_TITLE_DIALOG"),
      RADNIK_COPY_TITLE_DIALOG_CREATE: t("RADNIK_COPY_TITLE_DIALOG_CREATE"),
      RADNIK_COPY_TITLE_DIALOG_CREATE_TOOLTIP: t("RADNIK_COPY_TITLE_DIALOG_CREATE_TOOLTIP"),
      RADNIK_COPY_CANCEL: t("RADNIK_COPY_CANCEL"),

      //SIFARNIK USLUGA
      LABEL_SIFARNIK_USLUGA: t("LABEL_SIFARNIK_USLUGA"),
      SIFARNIK_USLUGA_NAZIV: t("SIFARNIK_USLUGA_NAZIV"),
      SIFARNIK_USLUGA_SIFRA: t("SIFARNIK_USLUGA_SIFRA"),
      SIFARNIK_USLUGA_NAZIV_ENGLESKI: t("SIFARNIK_USLUGA_NAZIV_ENGLESKI"),
      SIFARNIK_USLUGA_OPIS: t("SIFARNIK_USLUGA_OPIS"),
      SIFARNIK_USLUGA_VAZI_DO: t("SIFARNIK_USLUGA_VAZI_DO"),
      SIFARNIK_USLUGA_VAZI_OD: t("SIFARNIK_USLUGA_VAZI_OD"),
      SIFARNIK_USLUGA_LABEL_USLUGA: t("SIFARNIK_USLUGA_LABEL_USLUGA"),
      SIFARNIK_USLUGA_LABEL_CENA: t("SIFARNIK_USLUGA_LABEL_CENA"),
      SIFARNIK_USLUGA_IZMENA_CENE: t("SIFARNIK_USLUGA_IZMENA_CENE"),
      SIFARNIK_USLUGA_DODAJ_CENU: t("SIFARNIK_USLUGA_DODAJ_CENU"),
      SIFARNIK_USLUGA_IZMENI_CENU: t("SIFARNIK_USLUGA_IZMENI_CENU"),
      SIFARNIK_USLUGA_OBRISI_CENU: t("SIFARNIK_USLUGA_OBRISI_CENU"),
      SIFARNIK_USLUGA_VALUTA: t("SIFARNIK_USLUGA_VALUTA"),
      SIFANIK_USLUGA_LABEL_DODAJ: t("SIFANIK_USLUGA_LABEL_DODAJ"),
      SIFARNIK_USLUGA_SPECIJALIZACIJE: t("SIFARNIK_USLUGA_SPECIJALIZACIJE"),
      SIFARNIK_USLUGA_DOSTUPNA_ZA_EKSTERNE_ZAKAZIVACE: t("SIFARNIK_USLUGA_DOSTUPNA_ZA_EKSTERNE_ZAKAZIVACE"),

      SEARCH_SIFARNIK_USLUGA: t("SEARCH_SIFARNIK_USLUGA"),
      SIFARNIK_USLUGA_NAZIV_IS_REQUIRED: t("SIFARNIK_USLUGA_NAZIV_IS_REQUIRED"),
      SIFARNIK_USLUGA_SIFRA_IS_REQUIRED: t("SIFARNIK_USLUGA_SIFRA_IS_REQUIRED"),
      SIFARNIK_USLUGA_VAZI_OD_IS_REQUIRED: t("SIFARNIK_USLUGA_VAZI_OD_IS_REQUIRED"),
      SIFARNIK_USLUGA_VAZI_OD_IS_BEFORE_VAZI_DO: t("SIFARNIK_USLUGA_VAZI_OD_IS_BEFORE_VAZI_DO"),
      SIFARNIK_USLUGA_TITLE_DIALOG_CREATE: t("SIFARNIK_USLUGA_TITLE_DIALOG_CREATE"),
      SIFARNIK_USLUGA_TITLE_DIALOG_UPDATE: t("SIFARNIK_USLUGA_TITLE_DIALOG_UPDATE"),
      SIFARNIK_USLUGA_TITLE_DIALOG_DETAILS: t("SIFARNIK_USLUGA_TITLE_DIALOG_DETAILS"),
      SIFARNIK_USLUGA_TITLE_DIALOG_DELETE: t("SIFARNIK_USLUGA_TITLE_DIALOG_DELETE"),
      SIFARNIK_USLUGA_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_SUCCESS: t("SIFARNIK_USLUGA_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_SUCCESS"),
      SIFARNIK_USLUGA_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_SUCCESS: t("SIFARNIK_USLUGA_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_SUCCESS"),
      SIFARNIK_USLUGA_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_SUCCESS: t("SIFARNIK_USLUGA_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_SUCCESS"),
      BUTTON_ADD_SIFARNIK_USLUGA: t("BUTTON_ADD_SIFARNIK_USLUGA"),

      SIFARNIK_JEDINICA_MERE: t("SIFARNIK_JEDINICA_MERE"),
      SIFARNIK_JEDINICA_MERE_BUTTON_DODAJ: t("SIFARNIK_JEDINICA_MERE_BUTTON_DODAJ"),
      SIFARNIK_JEDINICA_MERE_TITLE_CREATE: t("SIFARNIK_JEDINICA_MERE_TITLE_CREATE"),
      SIFARNIK_JEDINICA_MERE_TITLE_UPDATE: t("SIFARNIK_JEDINICA_MERE_TITLE_UPDATE"),
      SIFARNIK_JEDINICA_MERE_TITLE_DETAILS: t("SIFARNIK_JEDINICA_MERE_TITLE_DETAILS"),
      SIFARNIK_JEDINICA_MERE_TITLE_DELETE: t("SIFARNIK_JEDINICA_MERE_TITLE_DELETE"),
      SIFARNIK_JEDINICA_MERE_NAZIV: t("SIFARNIK_JEDINICA_MERE_NAZIV"),
      SIFARNIK_JEDINICA_MERE_SIFRA: t("SIFARNIK_JEDINICA_MERE_SIFRA"),
      SIFARNIK_JEDINICA_MERE_AKRONIM: t("SIFARNIK_JEDINICA_MERE_AKRONIM"),
      SIFARNIK_JEDINICA_MERE_VAZI_OD: t("SIFARNIK_JEDINICA_MERE_VAZI_OD"),
      SIFARNIK_JEDINICA_MERE_VAZI_DO: t("SIFARNIK_JEDINICA_MERE_VAZI_DO"),
      SIFARNIK_JEDINICA_MERE_MESSAGE_CREATE_SUCCESS: t("SIFARNIK_JEDINICA_MERE_MESSAGE_CREATE_SUCCESS"),
      SIFARNIK_JEDINICA_MERE_MESSAGE_UPDATE_SUCCESS: t("SIFARNIK_JEDINICA_MERE_MESSAGE_UPDATE_SUCCESS"),
      SIFARNIK_JEDINICA_MERE_MESSAGE_DELETE_SUCCESS: t("SIFARNIK_JEDINICA_MERE_MESSAGE_DELETE_SUCCESS"),
      SIFARNIK_JEDINICA_MERE_NAZIV_IS_REQUIRED: t("SIFARNIK_JEDINICA_MERE_NAZIV_IS_REQUIRED"),
      SIFARNIK_JEDINICA_MERE_SIFRA_IS_REQUIRED: t("SIFARNIK_JEDINICA_MERE_SIFRA_IS_REQUIRED"),
      SIFARNIK_JEDINICA_MERE_VAZI_OD_IS_REQUIRED: t("SIFARNIK_JEDINICA_MERE_VAZI_OD_IS_REQUIRED"),
      SIFARNIK_JEDINICA_MERE_OPIS: t("SIFARNIK_JEDINICA_MERE_OPIS"),

      // SIFARNIK PROIZVODA
      LABEL_SIFARNIK_PROIZVODA: t("LABEL_SIFARNIK_PROIZVODA"),
      SIFARNIK_PROIZVODA_NAZIV: t("SIFARNIK_PROIZVODA_NAZIV"),
      SIFARNIK_PROIZVODA_SIFRA: t("SIFARNIK_PROIZVODA_SIFRA"),
      SIFARNIK_PROIZVODA_RFZO_LABEL: t("SIFARNIK_PROIZVODA_RFZO_LABEL"),
      SIFARNIK_PROIZVODA_NAZIV_ENGLESKI: t("SIFARNIK_PROIZVODA_NAZIV_ENGLESKI"),
      SIFARNIK_PROIZVODA_OPIS: t("SIFARNIK_PROIZVODA_OPIS"),
      SIFARNIK_PROIZVODA_VAZI_DO: t("SIFARNIK_PROIZVODA_VAZI_DO"),
      SIFARNIK_PROIZVODA_VAZI_OD: t("SIFARNIK_PROIZVODA_VAZI_OD"),

      SEARCH_SIFARNIK_PROIZVODA: t("SEARCH_SIFARNIK_PROIZVODA"),
      SIFARNIK_PROIZVODA_NAZIV_IS_REQUIRED: t("SIFARNIK_PROIZVODA_NAZIV_IS_REQUIRED"),
      SIFARNIK_PROIZVODA_SIFRA_IS_REQUIRED: t("SIFARNIK_PROIZVODA_SIFRA_IS_REQUIRED"),
      SIFARNIK_PROIZVODA_VAZI_OD_IS_REQUIRED: t("SIFARNIK_PROIZVODA_VAZI_OD_IS_REQUIRED"),
      SIFARNIK_PROIZVODA_VAZI_OD_IS_BEFORE_VAZI_DO: t("SIFARNIK_PROIZVODA_VAZI_OD_IS_BEFORE_VAZI_DO"),
      SIFARNIK_PROIZVODA_JEDINICA_MERE_IS_REQUIRED: t("SIFARNIK_PROIZVODA_JEDINICA_MERE_IS_REQUIRED"),
      SIFARNIK_PROIZVODA_TITLE_DIALOG_CREATE: t("SIFARNIK_PROIZVODA_TITLE_DIALOG_CREATE"),
      SIFARNIK_PROIZVODA_TITLE_DIALOG_UPDATE: t("SIFARNIK_PROIZVODA_TITLE_DIALOG_UPDATE"),
      SIFARNIK_PROIZVODA_TITLE_DIALOG_DETAILS: t("SIFARNIK_PROIZVODA_TITLE_DIALOG_DETAILS"),
      SIFARNIK_PROIZVODA_TITLE_DIALOG_DELETE: t("SIFARNIK_PROIZVODA_TITLE_DIALOG_DELETE"),
      SIFARNIK_PROIZVODA_TITLE_MESSAGE_CREATE_SIFARNIK_PROIZVODA_SUCCESS: t("SIFARNIK_PROIZVODA_TITLE_MESSAGE_CREATE_SIFARNIK_PROIZVODA_SUCCESS"),
      SIFARNIK_PROIZVODA_TITLE_MESSAGE_UPDATE_SIFARNIK_PROIZVODA_SUCCESS: t("SIFARNIK_PROIZVODA_TITLE_MESSAGE_UPDATE_SIFARNIK_PROIZVODA_SUCCESS"),
      SIFARNIK_PROIZVODA_TITLE_MESSAGE_DELETE_SIFARNIK_PROIZVODA_SUCCESS: t("SIFARNIK_PROIZVODA_TITLE_MESSAGE_DELETE_SIFARNIK_PROIZVODA_SUCCESS"),
      BUTTON_ADD_SIFARNIK_PROIZVODA: t("BUTTON_ADD_SIFARNIK_PROIZVODA"),
      SIFARNIK_PROIZVODA_NAZIV_MINIMUM_LENGTH: t("SIFARNIK_PROIZVODA_NAZIV_MINIMUM_LENGTH"),
      SIFARNIK_PROIZVODA_SIFRA_MINIMUM_LENGTH: t("SIFARNIK_PROIZVODA_SIFRA_MINIMUM_LENGTH"),

      SIFARNIK_PROIZVODA_CENE_LABEL: t("SIFARNIK_PROIZVODA_CENE_LABEL"),
      SIFARNIK_PROIZVODA_DODAJ_CENU: t("SIFARNIK_PROIZVODA_DODAJ_CENU"),
      SIFARNIK_PROIZVODA_IZMENI_CENU: t("SIFARNIK_PROIZVODA_IZMENI_CENU"),
      SIFARNIK_PROIZVODA_OBRISI_CENU: t("SIFARNIK_PROIZVODA_OBRISI_CENU"),

      // SIFARNIK LEKOVA NA RECEPT
      LABEL_SIFARNIK_LEKOVA_NA_RECEPT: t("LABEL_SIFARNIK_LEKOVA_NA_RECEPT"),
      SIFARNIK_LEKOVA_NA_RECEPT_JKL: t("SIFARNIK_LEKOVA_NA_RECEPT_JKL"),
      SIFARNIK_LEKOVA_NA_RECEPT_NAZIV: t("SIFARNIK_LEKOVA_NA_RECEPT_NAZIV"),
      SIFARNIK_LEKOVA_NA_RECEPT_PROIZVODJAC: t("SIFARNIK_LEKOVA_NA_RECEPT_PROIZVODJAC"),
      SIFARNIK_LEKOVA_NA_RECEPT_ATC_GRUPA: t("SIFARNIK_LEKOVA_NA_RECEPT_ATC_GRUPA"),
      SIFARNIK_LEKOVA_NA_RECEPT_CENA: t("SIFARNIK_LEKOVA_NA_RECEPT_CENA"),
      SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_DETAILS: t("SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_DETAILS"),
      SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY: t("SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY"),
      SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_CENA: t("SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_CENA"),
      SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_DOZVOLJENE_INDIKACIJE: t("SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_DOZVOLJENE_INDIKACIJE"),
      SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_INDIKACIJE: t("SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_INDIKACIJE"),
      SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_NAPOMENA: t("SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_NAPOMENA"),
      SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_DODATNIH_INDIKACIJA: t("SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_DODATNIH_INDIKACIJA"),
      SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_INDIKACIJA: t("SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_INDIKACIJA"),
      SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_NAPOMENA: t("SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_NAPOMENA"),
      SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_CENE: t("SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_CENE"),
      SIFARNIK_LEKOVA_NA_RECEPT_GENERICKI_NAZIV: t("SIFARNIK_LEKOVA_NA_RECEPT_GENERICKI_NAZIV"),
      SIFARNIK_LEKOVA_NA_RECEPT_OBLIK_LEKA: t("SIFARNIK_LEKOVA_NA_RECEPT_OBLIK_LEKA"),

      //SIFARNIK NACINA PLACANJA
      LABEL_SIFARNIK_NACINI_PLACANJA: t("LABEL_SIFARNIK_NACINI_PLACANJA"),
      LABEL_SIFARNIK_NACIN_PLACANJA: t("LABEL_SIFARNIK_NACIN_PLACANJA"),
      SIFARNIK_NACINA_PLACANJA_DODAJ_NACIN_PLACANJA: t("SIFARNIK_NACINA_PLACANJA_DODAJ_NACIN_PLACANJA"),
      SIFARNIK_NACINA_PLACANJA_TITLE_CREATE: t("SIFARNIK_NACINA_PLACANJA_TITLE_CREATE"),
      SIFARNIK_NACINA_PLACANJA_NAZIV: t("SIFARNIK_NACINA_PLACANJA_NAZIV"),
      SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE: t("SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE"),
      SIFARNIK_NACINA_PLACANJA_SIFRA: t("SIFARNIK_NACINA_PLACANJA_SIFRA"),
      SIFARNIK_NACINA_PLACANJA_PRIKAZNI_REDOSLED: t("SIFARNIK_NACINA_PLACANJA_PRIKAZNI_REDOSLED"),
      SIFARNIK_NACINA_PLACANJA_TITLE_UPDATE: t("SIFARNIK_NACINA_PLACANJA_TITLE_UPDATE"),
      SIFARNIK_NACINA_PLACANJA_TITLE_DELETE: t("SIFARNIK_NACINA_PLACANJA_TITLE_DELETE"),
      SIFARNIK_NACINA_PLACANJA_MESSAGE_DELETE_SUCCESS: t("SIFARNIK_NACINA_PLACANJA_MESSAGE_DELETE_SUCCESS"),
      SIFARNIK_NACINA_PLACANJA_MESSAGE_CREATE_SUCCESS: t("SIFARNIK_NACINA_PLACANJA_MESSAGE_CREATE_SUCCESS"),
      SIFARNIK_NACINA_PLACANJA_MESSAGE_UPDATE_SUCCESS: t("SIFARNIK_NACINA_PLACANJA_MESSAGE_UPDATE_SUCCESS"),
      SIFARNIK_NACINA_PLACANJA_VRSTA_UPLATIOCA: t("SIFARNIK_NACINA_PLACANJA_VRSTA_UPLATIOCA"),
      SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_DIALOG_HEADER: t("SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_DIALOG_HEADER"),
      SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_DIALOG_CONTENT: t("SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_DIALOG_CONTENT"),
      SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_ACCEPT_DIALOG_CONTENT: t("SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_ACCEPT_DIALOG_CONTENT"),
      SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_ACCEPT_DIALOG_CONTENT_2: t("SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_ACCEPT_DIALOG_CONTENT_2"),
      SIFARNIK_NACINA_PLACANJA_UPLATIOCI_TOOLTIP_DESCRIPTION: t("SIFARNIK_NACINA_PLACANJA_UPLATIOCI_TOOLTIP_DESCRIPTION"),
      SIFARNIK_NACINA_PLACANJA_RACUNI_TOOLTIP_DESCRIPTION: t("SIFARNIK_NACINA_PLACANJA_RACUNI_TOOLTIP_DESCRIPTION"),
      SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_TOOLTIP_DESCRIPTION: t("SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_TOOLTIP_DESCRIPTION"),

      //SIFARNIK KATEGORIJA POPUSTA
      LABEL_SIFARNIK_KATEGORIJE_POPUSTA: t("LABEL_SIFARNIK_KATEGORIJE_POPUSTA"),
      LABEL_SIFARNIK_KATEGORIJA_POPUSTA: t("LABEL_SIFARNIK_KATEGORIJA_POPUSTA"),
      SIFARNIK_KATEGORIJA_POPUSTA_DODAJ_KATEGORIJU_POPUSTA: t("SIFARNIK_KATEGORIJA_POPUSTA_DODAJ_KATEGORIJU_POPUSTA"),
      SIFARNIK_KATEGORIJA_POPUSTA_TITLE_CREATE: t("SIFARNIK_KATEGORIJA_POPUSTA_TITLE_CREATE"),
      SIFARNIK_KATEGORIJA_POPUSTA_NAZIV: t("SIFARNIK_KATEGORIJA_POPUSTA_NAZIV"),
      SIFARNIK_KATEGORIJA_POPUSTA_SIFRA: t("SIFARNIK_KATEGORIJA_POPUSTA_SIFRA"),
      SIFARNIK_KATEGORIJA_POPUSTA_PRIKAZNI_REDOSLED: t("SIFARNIK_KATEGORIJA_POPUSTA_PRIKAZNI_REDOSLED"),
      SIFARNIK_KATEGORIJA_POPUSTA_TITLE_UPDATE: t("SIFARNIK_KATEGORIJA_POPUSTA_TITLE_UPDATE"),
      SIFARNIK_KATEGORIJA_POPUSTA_TITLE_DELETE: t("SIFARNIK_KATEGORIJA_POPUSTA_TITLE_DELETE"),
      SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_DELETE_SUCCESS: t("SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_DELETE_SUCCESS"),
      SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_CREATE_SUCCESS: t("SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_CREATE_SUCCESS"),
      SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_UPDATE_SUCCESS: t("SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_UPDATE_SUCCESS"),
      LABEL_SIFARNIK_KATEGORIJE_POPUSTA_TIP_KATEGORIJE: t("LABEL_SIFARNIK_KATEGORIJE_POPUSTA_TIP_KATEGORIJE"),
      LABEL_SIFARNIK_KATEGORIJE_POPUSTA_NAMENA_KATEGORIJE: t("LABEL_SIFARNIK_KATEGORIJE_POPUSTA_NAMENA_KATEGORIJE"),
      LABEL_SIFARNIK_KATEGORIJE_POPUSTA_POPUST: t("LABEL_SIFARNIK_KATEGORIJE_POPUSTA_POPUST"),
      LABEL_SIFARNIK_KATEGORIJE_FILTER_TIP_PLACEHOLDER: t("LABEL_SIFARNIK_KATEGORIJE_FILTER_TIP_PLACEHOLDER"),
      LABEL_SIFARNIK_KATEGORIJE_FILTER_NAMENA_PLACEHOLDER: t("LABEL_SIFARNIK_KATEGORIJE_FILTER_NAMENA_PLACEHOLDER"),
      LABEL_SIFARNIK_KATEGORIJA_POPUSTA_VALUTA: t("LABEL_SIFARNIK_KATEGORIJA_POPUSTA_VALUTA"),

      //PAKETI USLUGA
      LABEL_PAKETI_USLUGA: t("LABEL_PAKETI_USLUGA"),
      PAKET_USLUGA_LIST: t("PAKET_USLUGA_LIST"),
      PAKET_USLUGA_SIFRA: t("PAKET_USLUGA_SIFRA"),
      PAKET_USLUGA_NAZIV: t("PAKET_USLUGA_NAZIV"),
      PAKET_USLUGA_NAPOMENA: t("PAKET_USLUGA_NAPOMENA"),
      PAKET_USLUGA_TITLE_DIALOG_CREATE: t("PAKET_USLUGA_TITLE_DIALOG_CREATE"),
      PAKET_USLUGA_TITLE_DIALOG_UPDATE: t("PAKET_USLUGA_TITLE_DIALOG_UPDATE"),
      PAKET_USLUGA_TITLE_DIALOG_DELETE: t("PAKET_USLUGA_TITLE_DIALOG_DELETE"),
      PAKET_USLUGA_NAZIV_IS_REQUIRED: t("PAKET_USLUGA_NAZIV_IS_REQUIRED"),
      PAKET_USLUGA_SEARCH: t("PAKET_USLUGA_SEARCH"),
      SEARCH_PAKET_USLUGA: t("SEARCH_PAKET_USLUGA"),
      BUTTON_ADD_PAKET_USLUGA: t("BUTTON_ADD_PAKET_USLUGA"),
      LABEL_PAKET_USLUGA: t("LABEL_PAKET_USLUGA"),
      LABEL_OSNOVNI_PODACI: t("LABEL_OSNOVNI_PODACI"),
      TOOLTIP_CREATE_PAKET_USLUGA: t("TOOLTIP_CREATE_PAKET_USLUGA"),
      TOOLTIP_UPDATE_PAKET_USLUGA: t("TOOLTIP_UPDATE_PAKET_USLUGA"),
      TOOLTIP_DELETE_PAKET_USLUGA: t("TOOLTIP_DELETE_PAKET_USLUGA"),
      PAKET_USLUGA_TITLE_MESSAGE_CREATE_PAKET_SUCCESS: t("PAKET_USLUGA_TITLE_MESSAGE_CREATE_PAKET_SUCCESS"),
      PAKET_USLUGA_TITLE_MESSAGE_UPDATE_PAKET_SUCCESS: t("PAKET_USLUGA_TITLE_MESSAGE_UPDATE_PAKET_SUCCESS"),
      PAKET_USLUGA_TITLE_MESSAGE_DELETE_PAKET_SUCCESS: t("PAKET_USLUGA_TITLE_MESSAGE_DELETE_PAKET_SUCCESS"),
      PAKET_USLUGA_ADD_USLUGA_SUCCESS: t("PAKET_USLUGA_ADD_USLUGA_SUCCESS"),
      PAKET_USLUGA_DELETE_USLUGA_SUCCESS: t("PAKET_USLUGA_DELETE_USLUGA_SUCCESS"),
      PAKET_USLUGA_UPDATE_USLUGA_SUCCESS: t("PAKET_USLUGA_UPDATE_USLUGA_SUCCESS"),
      PAKET_USLUGA_MANDATORY_FIELD: t("PAKET_USLUGA_MANDATORY_FIELD"),
      PAKET_USLUGA_PODRAZUMEVANA_TEXT: t("PAKET_USLUGA_PODRAZUMEVANA_TEXT"),
      PAKET_USLUGA_NE_PODRAZUMEVANA_TEXT: t("PAKET_USLUGA_NE_PODRAZUMEVANA_TEXT"),
      PAKET_USLUGA_LABEL_USLUGA: t("PAKET_USLUGA_LABEL_USLUGA"),
      PAKET_USLUGA_LABEL_PODESI: t("PAKET_USLUGA_LABEL_PODESI"),
      PAKET_USLUGA_LABEL_PRIKAZANI_REDOSLED: t("PAKET_USLUGA_LABEL_PRIKAZANI_REDOSLED"),
      PAKET_USLUGA_DIALOG_USLUGA_CREATE_TITLE: t("PAKET_USLUGA_DIALOG_USLUGA_CREATE_TITLE"),
      PAKET_USLUGA_DIALOG_USLUGA_UPDATE_TITLE: t("PAKET_USLUGA_DIALOG_USLUGA_UPDATE_TITLE"),
      PAKET_USLUGA_DIALOG_USLUGA_DELETE_TITLE: t("PAKET_USLUGA_DIALOG_USLUGA_DELETE_TITLE"),
      PAKET_USLUGA_DIALOG_PROIZVOD_CREATE_TITLE: t("PAKET_USLUGA_DIALOG_PROIZVOD_CREATE_TITLE"),
      PAKET_USLUGA_DIALOG_PROIZVOD_UPDATE_TITLE: t("PAKET_USLUGA_DIALOG_PROIZVOD_UPDATE_TITLE"),
      PAKET_USLUGA_DIALOG_PROIZVOD_DELETE_TITLE: t("PAKET_USLUGA_DIALOG_PROIZVOD_DELETE_TITLE"),
      PAKET_USLUGA_LABEL_UTROSAK: t("PAKET_USLUGA_LABEL_UTROSAK"),
      PAKET_USLUGA_LABEL_PODRAZUMEVANA_KOLICINA: t("PAKET_USLUGA_LABEL_PODRAZUMEVANA_KOLICINA"),
      PAKET_USLUGA_LABEL_PRIKAZNI_REDOSLED: t("PAKET_USLUGA_LABEL_PRIKAZNI_REDOSLED"),
      PAKET_USLUGA_LABEL_NAPOMENA: t("PAKET_USLUGA_LABEL_NAPOMENA"),
      PAKET_USLUGA_PROIZVOD_MANDATORY_FIELD: t("PAKET_USLUGA_PROIZVOD_MANDATORY_FIELD"),
      PAKET_USLUGA_ADD_PROIZVOD_SUCCESS: t("PAKET_USLUGA_ADD_PROIZVOD_SUCCESS"),
      PAKET_USLUGA_UPDATE_PROIZVOD_SUCCESS: t("PAKET_USLUGA_UPDATE_PROIZVOD_SUCCESS"),
      PAKET_USLUGA_DELETE_PROIZVOD_SUCCESS: t("PAKET_USLUGA_DELETE_PROIZVOD_SUCCESS"),
      PAKET_USLUGA_UNOS_USLUGE_PLACEHOLDER: t("PAKET_USLUGA_UNOS_USLUGE_PLACEHOLDER"),
      PAKET_USLUGA_UNOS_PROIZVODA_PLACEHOLDER: t("PAKET_USLUGA_UNOS_PROIZVODA_PLACEHOLDER"),

      //PAKET ORGANIZACIONE JEDINICE
      PAKET_ORGANIZACIONA_JEDINICA_TAB: t("PAKET_ORGANIZACIONA_JEDINICA_TAB"),
      PAKET_ORGANIZACIONA_JEDINICA_PLACEHOLDER: t("PAKET_ORGANIZACIONA_JEDINICA_PLACEHOLDER"),
      PAKET_ADD_ORGANIZACIONA_JEDINICA_SUCCESS: t("PAKET_ADD_ORGANIZACIONA_JEDINICA_SUCCESS"),
      PAKET_REMOVE_ORGANIZACIONA_JEDINICA_SUCCESS: t("PAKET_REMOVE_ORGANIZACIONA_JEDINICA_SUCCESS"),
      TOOLTIP_CREATE_PAKET_ORGANIZACIONA_JEDINICA: t("TOOLTIP_CREATE_PAKET_ORGANIZACIONA_JEDINICA"),
      TOOLTIP_DELETE_PAKET_ORGANIZACIONA_JEDINICA: t("TOOLTIP_DELETE_PAKET_ORGANIZACIONA_JEDINICA"),

      LOG_POZIVA_WS: t("LOG_POZIVA_WS"),
      LOG_METADATA_HOSTNAME: t("LOG_METADATA_HOSTNAME"),
      LOG_METADATA_PORT: t("LOG_METADATA_PORT"),
      LOG_REQUEST_TIMESTAMP: t("LOG_REQUEST_TIMESTAMP"),
      LOG_REQUEST_CLIENT_IP: t("LOG_REQUEST_CLIENT_IP"),
      LOG_REQUEST_METHOD: t("LOG_REQUEST_METHOD"),
      LOG_REQUEST_WS_ENDPOINT: t("LOG_REQUEST_WS_ENDPOINT"),
      LOG_RESPONSE_TIMESTAMP: t("LOG_RESPONSE_TIMESTAMP"),
      LOG_RESPONSE_METHOD: t("LOG_RESPONSE_METHOD"),
      LABEL_TOOLTIP_LOG_SEARCH: t("LABEL_TOOLTIP_LOG_SEARCH"),

      LOG_REQUEST_X_FORWARDED: t("LOG_REQUEST_X_FORWARDED"),
      LOG_REQUEST_QUERY_STRING: t("LOG_REQUEST_QUERY_STRING"),
      LOG_REQUEST_AUTHORIZATION: t("LOG_REQUEST_AUTHORIZATION"),
      LOG_REQUEST_USER_AGENT: t("LOG_REQUEST_USER_AGENT"),
      LOG_REQUEST_BODY: t("LOG_REQUEST_BODY"),
      LOG_RESPONSE_STATUS_CODE: t("LOG_RESPONSE_STATUS_CODE"),
      LOG_RESPONSE_BODY: t("LOG_RESPONSE_BODY"),

      LOG_HIGH_LEVEL_LIST: t("LOG_HIGH_LEVEL_LIST"),
      LOG_HIGH_LEVEL_ID_RADNIKA: t("LOG_HIGH_LEVEL_ID_RADNIKA"),
      LOG_HIGH_LEVEL_ID_PACIJENTA: t("LOG_HIGH_LEVEL_ID_PACIJENTA"),
      LOG_HIGH_LEVEL_RADNIK: t("LOG_HIGH_LEVEL_RADNIK"),
      LOG_HIGH_LEVEL_PACIJENT: t("LOG_HIGH_LEVEL_PACIJENT"),
      LOG_HIGH_LEVEL_VREME_AKCIJE: t("LOG_HIGH_LEVEL_VREME_AKCIJE"),
      LOG_HIGH_LEVEL_OPIS: t("LOG_HIGH_LEVEL_OPIS"),

      // SIFARNIK MKB
      SIFARNIK_MKB: t("SIFARNIK_MKB"),
      BUTTON_IMPORT_SIFARNIK_MKB: t("BUTTON_IMPORT_SIFARNIK_MKB"),
      TOOLTIP_IMPORT_SIFARNIK_MKB: t("TOOLTIP_IMPORT_SIFARNIK_MKB"),
      SEARCH_SIFARNIK_MKB: t("SEARCH_SIFARNIK_MKB"),
      SIFARNIK_MKB_NAZIV: t("SIFARNIK_MKB_NAZIV"),
      SIFARNIK_MKB_SIFRA: t("SIFARNIK_MKB_SIFRA"),
      IMPORT_SIFARNIK_MKB_SUCCESS: t("IMPORT_SIFARNIK_MKB_SUCCESS"),

      // SIFARNIK ATRIBUTA RFZO
      SIFARNIK_ATRIBUTA_RFZO: t("SIFARNIK_ATRIBUTA_RFZO"),
      BUTTON_IMPORT_SIFARNIK_ATRIBUTA_RFZO: t("BUTTON_IMPORT_SIFARNIK_ATRIBUTA_RFZO"),
      TOOLTIP_IMPORT_SIFARNIK_ATRIBUTA_RFZO: t("TOOLTIP_IMPORT_SIFARNIK_ATRIBUTA_RFZO"),
      SEARCH_SIFARNIK_ATRIBUTA_RFZO: t("SEARCH_SIFARNIK_ATRIBUTA_RFZO"),
      SIFARNIK_ATRIBUTA_RFZO_NAZIV: t("SIFARNIK_ATRIBUTA_RFZO_NAZIV"),
      SIFARNIK_ATRIBUTA_RFZO_SIFRA: t("SIFARNIK_ATRIBUTA_RFZO_SIFRA"),
      IMPORT_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: t("IMPORT_SIFARNIK_ATRIBUTA_RFZO_SUCCESS"),
      LABEL_SIFARNIK_ATRIBUTA_RFZO: t("LABEL_SIFARNIK_ATRIBUTA_RFZO"),
      SIFARNIK_ATRIBUTA_RFZO_OPIS: t("SIFARNIK_ATRIBUTA_RFZO_OPIS"),
      SIFARNIK_ATRIBUTA_RFZO_FAKTOR_UVECANJA_CENE: t("SIFARNIK_ATRIBUTA_RFZO_FAKTOR_UVECANJA_CENE"),
      SIFARNIK_ATRIBUTA_RFZO_OZNAKA: t("SIFARNIK_ATRIBUTA_RFZO_OZNAKA"),
      SIFARNIK_ATRIBUTA_RFZO_ID_ATRIBUTA: t("SIFARNIK_ATRIBUTA_RFZO_ID_ATRIBUTA"),
      SIFARNIK_ATRIBUTA_RFZO_VAZI_DO: t("SIFARNIK_ATRIBUTA_RFZO_VAZI_DO"),
      SIFARNIK_ATRIBUTA_RFZO_VAZI_OD: t("SIFARNIK_ATRIBUTA_RFZO_VAZI_OD"),
      SIFARNIK_ATRIBUTA_RFZO_STATUS: t("SIFARNIK_ATRIBUTA_RFZO_STATUS"),
      SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_CREATE: t("SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_CREATE"),
      SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_UPDATE: t("SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_UPDATE"),
      SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_DETAILS: t("SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_DETAILS"),
      SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_DELETE: t("SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_DELETE"),
      SIFARNIK_ATRIBUTA_RFZO_NAZIV_IS_REQUIRED: t("SIFARNIK_ATRIBUTA_RFZO_NAZIV_IS_REQUIRED"),
      SIFARNIK_ATRIBUTA_RFZO_SIFRA_IS_REQUIRED: t("SIFARNIK_ATRIBUTA_RFZO_SIFRA_IS_REQUIRED"),
      SIFARNIK_ATRIBUTA_RFZO_STATUS_IS_REQUIRED: t("SIFARNIK_ATRIBUTA_RFZO_STATUS_IS_REQUIRED"),
      SIFARNIK_ATRIBUTA_RFZO_FAKTOR_UVECANJA_IS_REQUIRED: t("SIFARNIK_ATRIBUTA_RFZO_FAKTOR_UVECANJA_IS_REQUIRED"),
      SIFARNIK_ATRIBUTA_RFZO_VAZI_OD_IS_REQUIRED: t("SIFARNIK_ATRIBUTA_RFZO_VAZI_OD_IS_REQUIRED"),
      SIFARNIK_ATRIBUTA_RFZO_VAZI_DO_IS_REQUIRED: t("SIFARNIK_ATRIBUTA_RFZO_VAZI_DO_IS_REQUIRED"),
      SIFARNIK_ATRIBUTA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO: t("SIFARNIK_ATRIBUTA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO"),
      SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: t("SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS"),
      SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: t("SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS"),
      SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: t("SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS"),

      // SIFARNIK PROIZVODA RFZO
      SIFARNIK_PROIZVODA_RFZO: t("SIFARNIK_PROIZVODA_RFZO"),
      BUTTON_IMPORT_SIFARNIK_PROIZVODA_RFZO: t("BUTTON_IMPORT_SIFARNIK_PROIZVODA_RFZO"),
      TOOLTIP_IMPORT_SIFARNIK_PROIZVODA_RFZO: t("TOOLTIP_IMPORT_SIFARNIK_PROIZVODA_RFZO"),
      SEARCH_SIFARNIK_PROIZVODA_RFZO: t("SEARCH_SIFARNIK_PROIZVODA_RFZO"),
      SIFARNIK_PROIZVODA_RFZO_NAZIV: t("SIFARNIK_PROIZVODA_RFZO_NAZIV"),
      SIFARNIK_PROIZVODA_RFZO_SIFRA: t("SIFARNIK_PROIZVODA_RFZO_SIFRA"),
      IMPORT_SIFARNIK_PROIZVODA_RFZO_SUCCESS: t("IMPORT_SIFARNIK_PROIZVODA_RFZO_SUCCESS"),
      LABEL_SIFARNIK_PROIZVODA_RFZO: t("LABEL_SIFARNIK_PROIZVODA_RFZO"),
      SIFARNIK_PROIZVODA_RFZO_OPIS: t("SIFARNIK_PROIZVODA_RFZO_OPIS"),
      SIFARNIK_PROIZVODA_RFZO_TIP: t("SIFARNIK_PROIZVODA_RFZO_TIP"),
      SIFARNIK_PROIZVODA_RFZO_OZNAKA: t("SIFARNIK_PROIZVODA_RFZO_OZNAKA"),
      SIFARNIK_PROIZVODA_RFZO_NAZIV_JEDINICE_MERE: t("SIFARNIK_PROIZVODA_RFZO_NAZIV_JEDINICE_MERE"),
      SIFARNIK_PROIZVODA_RFZO_VAZI_DO: t("SIFARNIK_PROIZVODA_RFZO_VAZI_DO"),
      SIFARNIK_PROIZVODA_RFZO_VAZI_OD: t("SIFARNIK_PROIZVODA_RFZO_VAZI_OD"),
      SIFARNIK_PROIZVODA_RFZO_STATUS: t("SIFARNIK_PROIZVODA_RFZO_STATUS"),
      SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_CREATE: t("SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_CREATE"),
      SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_UPDATE: t("SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_UPDATE"),
      SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DETAILS: t("SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DETAILS"),
      SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DELETE: t("SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DELETE"),
      SIFARNIK_PROIZVODA_RFZO_NAZIV_IS_REQUIRED: t("SIFARNIK_PROIZVODA_RFZO_NAZIV_IS_REQUIRED"),
      SIFARNIK_PROIZVODA_RFZO_SIFRA_IS_REQUIRED: t("SIFARNIK_PROIZVODA_RFZO_SIFRA_IS_REQUIRED"),
      SIFARNIK_PROIZVODA_RFZO_VAZI_OD_IS_REQUIRED: t("SIFARNIK_PROIZVODA_RFZO_VAZI_OD_IS_REQUIRED"),
      SIFARNIK_PROIZVODA_RFZO_VAZI_DO_IS_REQUIRED: t("SIFARNIK_PROIZVODA_RFZO_VAZI_DO_IS_REQUIRED"),
      SIFARNIK_PROIZVODA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO: t("SIFARNIK_PROIZVODA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO"),
      SIFARNIK_PROIZVODA_RFZO_STATUS_IS_REQUIRED: t("SIFARNIK_PROIZVODA_RFZO_STATUS_IS_REQUIRED"),
      SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_PROIZVODA_RFZO_SUCCESS: t("SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_PROIZVODA_RFZO_SUCCESS"),
      SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_PROIZVODA_RFZO_SUCCESS: t("SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_PROIZVODA_RFZO_SUCCESS"),
      SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_PROIZVODA_RFZO_SUCCESS: t("SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_PROIZVODA_RFZO_SUCCESS"),

      // SIFARNIK USLUGA RFZO
      SIFARNIK_USLUGA_RFZO: t("SIFARNIK_USLUGA_RFZO"),
      BUTTON_IMPORT_SIFARNIK_USLUGA_RFZO: t("BUTTON_IMPORT_SIFARNIK_USLUGA_RFZO"),
      TOOLTIP_IMPORT_SIFARNIK_USLUGA_RFZO: t("TOOLTIP_IMPORT_SIFARNIK_USLUGA_RFZO"),
      SEARCH_SIFARNIK_USLUGA_RFZO: t("SEARCH_SIFARNIK_USLUGA_RFZO"),
      SIFARNIK_USLUGA_RFZO_NAZIV: t("SIFARNIK_USLUGA_RFZO_NAZIV"),
      SIFARNIK_USLUGA_RFZO_SIFRA: t("SIFARNIK_USLUGA_RFZO_SIFRA"),
      IMPORT_SIFARNIK_USLUGA_RFZO_SUCCESS: t("IMPORT_SIFARNIK_USLUGA_RFZO_SUCCESS"),
      LABEL_SIFARNIK_USLUGA_RFZO: t("LABEL_SIFARNIK_USLUGA_RFZO"),
      SIFARNIK_USLUGA_RFZO_OPIS: t("SIFARNIK_USLUGA_RFZO_OPIS"),
      SIFARNIK_USLUGA_RFZO_MOZE_IMATI_UTROSAK: t("SIFARNIK_USLUGA_RFZO_MOZE_IMATI_UTROSAK"),
      SIFARNIK_USLUGA_RFZO_VAZI_DO: t("SIFARNIK_USLUGA_RFZO_VAZI_DO"),
      SIFARNIK_USLUGA_RFZO_VAZI_OD: t("SIFARNIK_USLUGA_RFZO_VAZI_OD"),
      SIFARNIK_USLUGA_RFZO_STATUS: t("SIFARNIK_USLUGA_RFZO_STATUS"),
      SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_CREATE: t("SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_CREATE"),
      SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_UPDATE: t("SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_UPDATE"),
      SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DETAILS: t("SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DETAILS"),
      SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DELETE: t("SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DELETE"),
      SIFARNIK_USLUGA_RFZO_NAZIV_IS_REQUIRED: t("SIFARNIK_USLUGA_RFZO_NAZIV_IS_REQUIRED"),
      SIFARNIK_USLUGA_RFZO_SIFRA_IS_REQUIRED: t("SIFARNIK_USLUGA_RFZO_SIFRA_IS_REQUIRED"),
      SIFARNIK_USLUGA_RFZO_VAZI_OD_IS_REQUIRED: t("SIFARNIK_USLUGA_RFZO_VAZI_OD_IS_REQUIRED"),
      SIFARNIK_USLUGA_RFZO_VAZI_DO_IS_REQUIRED: t("SIFARNIK_USLUGA_RFZO_VAZI_DO_IS_REQUIRED"),
      SIFARNIK_USLUGA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO: t("SIFARNIK_USLUGA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO"),
      SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_RFZO_SUCCESS: t("SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_RFZO_SUCCESS"),
      SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_RFZO_SUCCESS: t("SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_RFZO_SUCCESS"),
      SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_RFZO_SUCCESS: t("SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_RFZO_SUCCESS"),

      SIFARNIK_STATUS_IZBOR_FILTERA: t("SIFARNIK_STATUS_IZBOR_FILTERA"),

      /* OBAVESTENJA */

      OBAVESTENJE_LIST: t("OBAVESTENJE_LIST"),
      OBAVESTENJE_TITLE_DIALOG_CREATE: t("OBAVESTENJE_TITLE_DIALOG_CREATE"),
      OBAVESTENJE_TITLE_DIALOG_UPDATE: t("OBAVESTENJE_TITLE_DIALOG_UPDATE"),
      OBAVESTENJE_TITLE_DIALOG_DELETE: t("OBAVESTENJE_TITLE_DIALOG_DELETE"),
      OBAVESTENJE_TITLE_DIALOG_VIEW: t("OBAVESTENJE_TITLE_DIALOG_VIEW"),
      OBAVESTENJE_TITLE_DIALOG_DETAILS: t("OBAVESTENJE_TITLE_DIALOG_DETAILS"),
      SEARCH_OBAVESTENJE: t("SEARCH_OBAVESTENJE"),
      OBAVESTENJE_SEARCH: t("OBAVESTENJE_SEARCH"),
      OBAVESTENJE_NASLOV: t("OBAVESTENJE_NASLOV"),
      OBAVESTENJE_SADRZAJ: t("OBAVESTENJE_SADRZAJ"),
      OBAVESTENJE_VAZI_DO: t("OBAVESTENJE_VAZI_DO"),
      OBAVESTENJE_TITLE_MESSAGE_DELETE_OBAVESTENJE_SUCCESS: t("OBAVESTENJE_TITLE_MESSAGE_DELETE_OBAVESTENJE_SUCCESS"),
      OBAVESTENJE_TITLE_MESSAGE_UPDATE_OBAVESTENJE_SUCCESS: t("OBAVESTENJE_TITLE_MESSAGE_UPDATE_OBAVESTENJE_SUCCESS"),
      OBAVESTENJE_ULOGA: t("OBAVESTENJE_ULOGA"),
      OBAVESTENJE_ORGANIZACIONA_JEDINICA: t("OBAVESTENJE_ORGANIZACIONA_JEDINICA"),
      OBAVESTENJE_COPY_TITLE_DIALOG: t("OBAVESTENJE_COPY_TITLE_DIALOG"),
      OBAVESTENJE_NASLOV_IS_REQUIRED: t("OBAVESTENJE_NASLOV_IS_REQUIRED"),
      OBAVESTENJE_SADRZAJ_IS_REQUIRED: t("OBAVESTENJE_SADRZAJ_IS_REQUIRED"),
      OBAVESTENJE_TITLE_MESSAGE_CREATE_OBAVESTENJE_SUCCESS: t("OBAVESTENJE_TITLE_MESSAGE_CREATE_OBAVESTENJE_SUCCESS"),
      OBAVESTENJE_TITLE_MESSAGE_CREATE_OBAVESTENJE_DATOTEKA_SUCCESS: t("OBAVESTENJE_TITLE_MESSAGE_CREATE_OBAVESTENJE_DATOTEKA_SUCCESS"),
      CRUD_OBAVESTENJE: t("CRUD_OBAVESTENJE"),
      OBAVESTENJE_PROCITANO: t("OBAVESTENJE_PROCITANO"),
      OBAVESTENJE_NEPROCITANO: t("OBAVESTENJE_NEPROCITANO"),
      OBAVESTENJE_NEMA_PROCITANIH: t("OBAVESTENJE_NEMA_PROCITANIH"),
      OBAVESTENJE_NEMA_NEPROCITANIH: t("OBAVESTENJE_NEMA_NEPROCITANIH"),
      OBAVESTENJE_OZNACI_SVE_PROCITANO: t("OBAVESTENJE_OZNACI_SVE_PROCITANO"),
      OBAVESTENJE_SVE_USPESNO_PROCITANO: t("OBAVESTENJE_SVE_USPESNO_PROCITANO"),
      OBAVESTENJE_KREIRANO: t("OBAVESTENJE_KREIRANO"),
      OBAVESTENJE_DATOTEKA_ID_IS_REQUIRED: t("OBAVESTENJE_DATOTEKA_ID_IS_REQUIRED"),
      OBAVESTENJE_DATOTAKA_CREATE_SUCCESS: t("OBAVESTENJE_DATOTAKA_CREATE_SUCCESS"),
      OBAVESTENJE_DATOTEKE: t("OBAVESTENJE_DATOTEKE"),
      OBAVESTENJE_DATOTEKE_IZABERI: t("OBAVESTENJE_DATOTEKE_IZABERI"),
      OBAVESTENJE_DATOTEKE_SACUVAJ: t("OBAVESTENJE_DATOTEKE_SACUVAJ"),
      OBAVESTENJE_DATOTEKE_PONISTI: t("OBAVESTENJE_DATOTEKE_PONISTI"),

      /* IZVESTAJI */

      IZVESTAJ_LIST: t("IZVESTAJ_LIST"),
      LICENCA_LIST: t("LICENCA_LIST"),
      STATISTICKI_IZVESTAJ_LIST: t("STATISTICKI_IZVESTAJ_LIST"),
      KATEGORIJA_IZVESTAJA_LIST: t("KATEGORIJA_IZVESTAJA_LIST"),
      BUTTON_ADD_KATEGORIJA: t("BUTTON_ADD_KATEGORIJA"),
      TOOLTIP_ADD_KATEGORIJA: t("TOOLTIP_ADD_KATEGORIJA"),
      SEARCH_KATEGORIJA: t("SEARCH_KATEGORIJA"),
      KATEGORIJA_SEARCH: t("KATEGORIJA_SEARCH"),
      KATEGORIJA_NAZIV: t("KATEGORIJA_NAZIV"),
      KATEGORIJA_SIFRA: t("KATEGORIJA_SIFRA"),
      KATEGORIJA_TITLE_DIALOG_CREATE: t("KATEGORIJA_TITLE_DIALOG_CREATE"),
      KATEGORIJA_TITLE_DIALOG_UPDATE: t("KATEGORIJA_TITLE_DIALOG_UPDATE"),
      KATEGORIJA_TITLE_DIALOG_DELETE: t("KATEGORIJA_TITLE_DIALOG_DELETE"),
      CRUD_KATEGORIJA_IZVESTAJ: t("CRUD_KATEGORIJA_IZVESTAJ"),
      LABEL_KATEGORIJA_STATISTICKOG_IZVESTAJA: t("LABEL_KATEGORIJA_STATISTICKOG_IZVESTAJA"),
      LABEL_KATEGORIJA_IZVESTAJA_CREATE: t("LABEL_KATEGORIJA_IZVESTAJA_CREATE"),
      LABEL_KATEGORIJA_IZVESTAJA_UPDATE: t("LABEL_KATEGORIJA_IZVESTAJA_UPDATE"),
      LABEL_KATEGORIJA_IZVESTAJA_DELETE: t("LABEL_KATEGORIJA_IZVESTAJA_DELETE"),
      KATEGORIJA_NAZIV_IS_REQUIRED: t("KATEGORIJA_NAZIV_IS_REQUIRED"),
      KATEGORIJA_SIFRA_IS_REQUIRED: t("KATEGORIJA_SIFRA_IS_REQUIRED"),
      KATEGORIJA_TITLE_MESSAGE_CREATE_KATEGORIJA_SUCCESS: t("KATEGORIJA_TITLE_MESSAGE_CREATE_KATEGORIJA_SUCCESS"),
      KATEGORIJA_TITLE_MESSAGE_UPDATE_KATEGORIJA_SUCCESS: t("KATEGORIJA_TITLE_MESSAGE_UPDATE_KATEGORIJA_SUCCESS"),
      KATEGORIJA_TITLE_MESSAGE_DELETE_KATEGORIJA_SUCCESS: t("KATEGORIJA_TITLE_MESSAGE_DELETE_KATEGORIJA_SUCCESS"),
      IZVESTAJ_NAZIV: t("IZVESTAJ_NAZIV"),
      IZVESTAJ_LABEL: t("IZVESTAJ_LABEL"),
      GEN_IZVESTAJ_DETALJI: t("GEN_IZVESTAJ_DETALJI"),
      EMPTY_IZVESTAJI_KATEGORIJA: t("EMPTY_IZVESTAJI_KATEGORIJA"),
      GEN_IZVESTAJ_AKCIJE: t("GEN_IZVESTAJ_AKCIJE"),
      GEN_IZVESTAJ_VREME: t("GEN_IZVESTAJ_VREME"),
      GEN_IZVESTAJ_NAZIV: t("GEN_IZVESTAJ_NAZIV"),
      GEN_IZVESTAJ_TITLE_DIALOG_PRINT: t("GEN_IZVESTAJ_TITLE_DIALOG_PRINT"),
      GEN_IZVESTAJ_TITLE_DIALOG_DETALJI: t("GEN_IZVESTAJ_TITLE_DIALOG_DETALJI"),
      GEN_IZVESTAJ_TITLE_DIALOG_DELETE: t("GEN_IZVESTAJ_TITLE_DIALOG_DELETE"),
      BUTTON_GENERISI_IZVESTAJ: t("BUTTON_GENERISI_IZVESTAJ"),
      SWITCH_LABEL: t("SWITCH_LABEL"),
      IZVESTAJ_SIFRA: t("IZVESTAJ_SIFRA"),
      IZVESTAJ_JASPER: t("IZVESTAJ_JASPER"),
      IZVESTAJ_TIP_GENERISANJA: t("IZVESTAJ_TIP_GENERISANJA"),
      IZVESTAJ_FORMAT: t("IZVESTAJ_FORMAT"),
      IZVESTAJ_OPIS: t("IZVESTAJ_OPIS"),
      GEN_IZVESTAJ_PARAMETRI: t("GEN_IZVESTAJ_PARAMETRI"),
      IZVESTAJ_SVE_KATEGORIJE: t("IZVESTAJ_SVE_KATEGORIJE"),
      IZVESTAJ_PRIKAZUJE_SE: t("IZVESTAJ_PRIKAZUJE_SE"),
      BUTTON_ADD_IZVESTAJ: t("BUTTON_ADD_IZVESTAJ"),
      TOOLTIP_ADD_IZVESTAJ: t("TOOLTIP_ADD_IZVESTAJ"),
      SEARCH_IZVESTAJ: t("SEARCH_IZVESTAJ"),
      IZVESTAJ_SEARCH: t("IZVESTAJ_SEARCH"),
      IZVESTAJ_TITLE_DIALOG_CREATE: t("IZVESTAJ_TITLE_DIALOG_CREATE"),
      IZVESTAJ_TITLE_DIALOG_UPDATE: t("IZVESTAJ_TITLE_DIALOG_UPDATE"),
      IZVESTAJ_TITLE_DIALOG_DELETE: t("IZVESTAJ_TITLE_DIALOG_DELETE"),
      IZVESTAJ_PARAMETRI: t("IZVESTAJ_PARAMETRI"),
      IZVESTAJ_PARAMETRI_CREATE: t("IZVESTAJ_PARAMETRI_CREATE"),
      IZVESTAJ_PARAMETRI_UPDATE: t("IZVESTAJ_PARAMETRI_UPDATE"),
      IZVESTAJ_PARAMETRI_DELETE: t("IZVESTAJ_PARAMETRI_DELETE"),
      IZVESTAJ_PARAMETRI_LIST: t("IZVESTAJ_PARAMETRI_LIST"),
      CRUD_IZVESTAJ_PARAMETAR: t("CRUD_IZVESTAJ_PARAMETAR"),
      IZVESTAJ_MESSAGE_CREATE_SUCCESS: t("IZVESTAJ_MESSAGE_CREATE_SUCCESS"),
      IZVESTAJ_MESSAGE_UPDATE_SUCCESS: t("IZVESTAJ_MESSAGE_UPDATE_SUCCESS"),
      IZVESTAJ_MESSAGE_DELETE_SUCCESS: t("IZVESTAJ_MESSAGE_DELETE_SUCCESS"),
      IZVESTAJ_NAZIV_IS_REQUIRED: t("IZVESTAJ_NAZIV_IS_REQUIRED"),
      IZVESTAJ_KATEGORIJA_IS_REQUIRED: t("IZVESTAJ_KATEGORIJA_IS_REQUIRED"),
      IZVESTAJ_JASPER_IS_REQUIRED: t("IZVESTAJ_JASPER_IS_REQUIRED"),
      IZVESTAJ_FORMAT_IS_REQUIRED: t("IZVESTAJ_FORMAT_IS_REQUIRED"),
      IZVESTAJ_CHOOSE_JASPER: t("IZVESTAJ_CHOOSE_JASPER"),
      IZVESTAJ_REMOVE_JASPER: t("IZVESTAJ_REMOVE_JASPER"),
      CRUD_IZVESTAJ: t("CRUD_IZVESTAJ"),
      IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DELETE_SUCCESS: t("IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DELETE_SUCCESS"),
      IZVESTAJ_PARAMETAR_TIP: t("IZVESTAJ_PARAMETAR_TIP"),
      IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DIALOG_UPDATE: t("IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DIALOG_UPDATE"),
      IZVESTAJ_PARAMETAR_TITLE_DIALOG_CREATE: t("IZVESTAJ_PARAMETAR_TITLE_DIALOG_CREATE"),
      IZVESTAJ_PARAMETAR_SQL_UPIT: t("IZVESTAJ_PARAMETAR_SQL_UPIT"),
      IZVESTAJ_PARAMETAR_TITLE_DIALOG_UPDATE: t("IZVESTAJ_PARAMETAR_TITLE_DIALOG_UPDATE"),
      KATEGORIJE_STATISTICKIH_IZVESTAJA: t("KATEGORIJE_STATISTICKIH_IZVESTAJA"),

      /* IZVEŠTAJ PARAMETAR ENUM STAVKA */

      POTVRDI: t("POTVRDI"),
      OTVORITE_NESACUVAN_NALAZ: t("OTVORITE_NESACUVAN_NALAZ"),
      NASTAVITE_BEZ_CUVANJA_NALAZA: t("NASTAVITE_BEZ_CUVANJA_NALAZA"),
      ZA_PACIJENTA: t("ZA_PACIJENTA"),
      POSTOJI_NESACUVAN_NALAZ: t("POSTOJI_NESACUVAN_NALAZ"),
      DA_LI_ZELITE_DA: t("DA_LI_ZELITE_DA"),
      SACUVAJ_I_STAMPAJ: t("SACUVAJ_I_STAMPAJ"),

      RADNO_VREME_OD_NOT_EXISTS: t("RADNO_VREME_OD_NOT_EXISTS"),
      RADNO_VREME_DO_NOT_EXISTS: t("RADNO_VREME_DO_NOT_EXISTS"),
      RADNO_VREME_NOT_VALID: t("RADNO_VREME_NOT_VALID"),

      INICIJALNA_VREDNOST: t("INICIJALNA_VREDNOST"),
      TIP_OSIGURANJA_FILTER: t("TIP_OSIGURANJA_FILTER"),
      KREIRANJE_OSIGURANJA: t("KREIRANJE_OSIGURANJA"),
      IZMENA_OSIGURANJA: t("IZMENA_OSIGURANJA"),
      BRISANJE_OSIGURANJA: t("BRISANJE_OSIGURANJA"),
      DODAJ_OSIGURANJE: t("DODAJ_OSIGURANJE"),
      OSIGURANJE_TIP_IS_REQUIRED: t("OSIGURANJE_TIP_IS_REQUIRED"),
      VAZI_OD_IS_REQUIRED: t("VAZI_OD_IS_REQUIRED"),
      OSIGURANJE_VAZI_DO_AFTER_VAZI_OD: t("OSIGURANJE_VAZI_DO_AFTER_VAZI_OD"),
      CENA: t("CENA"),

      //LICENCE

      LICENCNI_MODEL_TITLE_DIALOG_UPDATE: t("LICENCNI_MODEL_TITLE_DIALOG_UPDATE"),
      LICENCNI_MODEL_TITLE_DIALOG_CREATE: t("LICENCNI_MODEL_TITLE_DIALOG_CREATE"),
      LICENCNI_MODEL_TITLE_DIALOG_DELETE: t("LICENCNI_MODEL_TITLE_DIALOG_DELETE"),
      LICENCNI_MODEL_MESSAGE_CREATE_SUCCESS: t("LICENCNI_MODEL_MESSAGE_CREATE_SUCCESS"),
      LICENCNI_MODEL_MESSAGE_UPDATE_SUCCESS: t("LICENCNI_MODEL_MESSAGE_UPDATE_SUCCESS"),
      LICENCNI_MODEL_MESSAGE_DELETE_SUCCESS: t("LICENCNI_MODEL_MESSAGE_DELETE_SUCCESS"),
      LABEL_LICENCNI_MODEL: t("LABEL_LICENCNI_MODEL"),

      DODATNI_MODULI: t("DODATNI_MODULI"),
      DODATNI_MODUL: t("DODATNI_MODUL"),
      DODATNI_MODUL_BUTTON_DODAJ: t("DODATNI_MODUL_BUTTON_DODAJ"),
      DODATNI_MODUL_TITLE_CREATE: t("DODATNI_MODUL_TITLE_CREATE"),
      DODATNI_MODUL_TITLE_UPDATE: t("DODATNI_MODUL_TITLE_UPDATE"),
      DODATNI_MODUL_TITLE_DETAILS: t("DODATNI_MODUL_TITLE_DETAILS"),
      DODATNI_MODUL_TITLE_DELETE: t("DODATNI_MODUL_TITLE_DELETE"),
      DODATNI_MODUL_NAZIV: t("DODATNI_MODUL_NAZIV"),
      DODATNI_MODUL_SIFRA: t("DODATNI_MODUL_SIFRA"),
      DODATNI_MODUL_OPIS: t("DODATNI_MODUL_OPIS"),
      DODATNI_MODUL_IKONICA: t("DODATNI_MODUL_IKONICA"),
      DODATNI_MODUL_IKONICA_DELETE: t("DODATNI_MODUL_IKONICA_DELETE"),
      DODATNI_MODUL_MESSAGE_CREATE_SUCCESS: t("DODATNI_MODUL_MESSAGE_CREATE_SUCCESS"),
      DODATNI_MODUL_MESSAGE_UPDATE_SUCCESS: t("DODATNI_MODUL_MESSAGE_UPDATE_SUCCESS"),
      DODATNI_MODUL_MESSAGE_DELETE_SUCCESS: t("DODATNI_MODUL_MESSAGE_DELETE_SUCCESS"),
      DODATNI_MODUL_NAZIV_IS_REQUIRED: t("DODATNI_MODUL_NAZIV_IS_REQUIRED"),
      DODATNI_MODUL_SIFRA_IS_REQUIRED: t("DODATNI_MODUL_SIFRA_IS_REQUIRED"),
      DODATNI_MODUL_VREME_DO_AFTER_VREME_OD: t("DODATNI_MODUL_VREME_DO_AFTER_VREME_OD"),
      MODUL: t("MODUL"),
      MODUL_IZMENA: t("MODUL_IZMENA"),
      MODUL_BRISANJE: t("MODUL_BRISANJE"),
      MODUL_TITLE_DIALOG_CREATE: t("MODUL_TITLE_DIALOG_CREATE"),
      MODUL_LICENCNI_MODUL: t("MODUL_LICENCNI_MODUL"),
      SEARCH_DODATNI_MODUL: t("SEARCH_DODATNI_MODUL"),
      DODATNI_MODUL_SEARCH: t("DODATNI_MODUL_SEARCH"),

      STORAGE_LIMIT_TITLE_UPDATE: t("STORAGE_LIMIT_TITLE_UPDATE"),
      STORAGE_LIMIT_TITLE_DETAILS: t("STORAGE_LIMIT_TITLE_DETAILS"),
      STORAGE_LIMIT_TITLE_DELETE: t("STORAGE_LIMIT_TITLE_DELETE"),
      STORAGE_LIMIT_TITLE_CREATE: t("STORAGE_LIMIT_TITLE_CREATE"),
      STORAGE_LIMIT_NAZIV: t("STORAGE_LIMIT_NAZIV"),
      STORAGE_LIMIT_SIFRA: t("STORAGE_LIMIT_SIFRA"),
      STORAGE_LIMIT_MESSAGE_CREATE_SUCCESS: t("STORAGE_LIMIT_MESSAGE_CREATE_SUCCESS"),
      STORAGE_LIMIT_UPDATE_SUCCESS: t("STORAGE_LIMIT_MESSAGE_UPDATE_SUCCESS"),
      STORAGE_LIMIT_MESSAGE_DELETE_SUCCESS: t("STORAGE_LIMIT_MESSAGE_DELETE_SUCCESS"),
      STORAGE_LIMIT_NAZIV_IS_REQUIRED: t("STORAGE_LIMIT_NAZIV_IS_REQUIRED"),
      STORAGE_LIMIT_SIFRA_IS_REQUIRED: t("STORAGE_LIMIT_SIFRA_IS_REQUIRED"),
      STORAGE_LIMIT_PROSTOR_IS_REQUIRED: t("STORAGE_LIMIT_PROSTOR_IS_REQUIRED"),
      STORAGE_LIMIT_JEDINICA_IS_REQUIRED: t("STORAGE_LIMIT_JEDINICA_IS_REQUIRED"),
      STORAGE_LIMIT_CENA_IS_REQUIRED: t("STORAGE_LIMIT_CENA_IS_REQUIRED"),
      STORAGE_LIMIT_BUTTON_DODAJ: t("STORAGE_LIMIT_BUTTON_DODAJ"),
      STORAGE_LIMIT_PROSTOR: t("STORAGE_LIMIT_PROSTOR"),
      STORAGE_LIMIT: t("STORAGE_LIMIT"),
      USTANOVA_STORAGE_LIMIT: t("USTANOVA_STORAGE_LIMIT"),
      LICENCA_USTANOVA_STORAGE_LIMIT_LIST: t("LICENCA_USTANOVA_STORAGE_LIMIT_LIST"),
      USTANOVA_STORAGE_LIMIT_NAZIV_IS_REQUIRED: t("USTANOVA_STORAGE_LIMIT_NAZIV_IS_REQUIRED"),
      USTANOVA_STORAGE_LIMIT_MESSAGE_UPDATE_SUCCESS: t("USTANOVA_STORAGE_LIMIT_MESSAGE_UPDATE_SUCCESS"),
      USTANOVA_STORAGE_LIMIT_VAZI_OD_IS_REQUIRED: t("USTANOVA_STORAGE_LIMIT_VAZI_OD_IS_REQUIRED"),
      USTANOVA_STORAGE_LIMIT_TITLE_DELETE: t("USTANOVA_STORAGE_LIMIT_TITLE_DELETE"),
      USTANOVA_VAZI_OD_VECA_OD_DANAS: t("USTANOVA_VAZI_OD_VECA_OD_DANAS"),
      USTANOVA_VAZI_OD_VECA_OD_VAZI_DO: t("USTANOVA_VAZI_OD_VECA_OD_VAZI_DO"),
      SEARCH_STORAGE_LIMIT: t("SEARCH_STORAGE_LIMIT"),
      STORAGE_LIMIT_SEARCH: t("STORAGE_LIMIT_SEARCH"),

      BROJ_NALOGA: t("BROJ_NALOGA"),
      BROJ_NALOGA_BUTTON_DODAJ: t("BROJ_NALOGA_BUTTON_DODAJ"),
      BROJ_NALOGA_TITLE_CREATE: t("BROJ_NALOGA_TITLE_CREATE"),
      BROJ_NALOGA_TITLE_UPDATE: t("BROJ_NALOGA_TITLE_UPDATE"),
      BROJ_NALOGA_TITLE_DELETE: t("BROJ_NALOGA_TITLE_DELETE"),
      SEARCH_BROJ_NALOGA: t("SEARCH_BROJ_NALOGA"),
      BROJ_NALOGA_SEARCH: t("BROJ_NALOGA_SEARCH"),
      BROJ_NALOGA_TITLE_DETAILS: t("BROJ_NALOGA_TITLE_DETAILS"),
      BROJ_NALOGA_NAZIV: t("BROJ_NALOGA_NAZIV"),
      BROJ_NALOGA_SIFRA: t("BROJ_NALOGA_SIFRA"),
      BROJ_NALOGA_UKUPNO: t("BROJ_NALOGA_UKUPNO"),
      BROJ_NALOGA_MESSAGE_CREATE_SUCCESS: t("BROJ_NALOGA_MESSAGE_CREATE_SUCCESS"),
      BROJ_NALOGA_MESSAGE_UPDATE_SUCCESS: t("BROJ_NALOGA_MESSAGE_UPDATE_SUCCESS"),
      BROJ_NALOGA_MESSAGE_DELETE_SUCCESS: t("BROJ_NALOGA_MESSAGE_DELETE_SUCCESS"),
      BROJ_NALOGA_NAZIV_IS_REQUIRED: t("BROJ_NALOGA_NAZIV_IS_REQUIRED"),
      BROJ_NALOGA_SIFRA_IS_REQUIRED: t("BROJ_NALOGA_SIFRA_IS_REQUIRED"),
      BROJ_NALOGA_BROJ_IS_REQUIRED: t("BROJ_NALOGA_BROJ_IS_REQUIRED"),
      BROJ_NALOGA_BROJ_VALUE_NOT_VALID: t("BROJ_NALOGA_BROJ_VALUE_NOT_VALID"),

      // PROTOKOL

      PROTOKOL_LIST: t("PROTOKOL_LIST"),
      PROTOKOL_ADMINISTRIRANJE: t("PROTOKOL_ADMINISTRIRANJE"),
      PROTOKOL_PREFIKS: t("PROTOKOL_PREFIKS"),
      PROTOKOL_NAZIV: t("PROTOKOL_NAZIV"),
      PROTOKOL_VREMENSKI_OKVIR: t("PROTOKOL_VREMENSKI_OKVIR"),
      PROTOKOL_POCETNI_BROJ: t("PROTOKOL_POCETNI_BROJ"),
      PROTOKOL_NAREDNI_BROJ: t("PROTOKOL_NAREDNI_BROJ"),
      BUTTON_ADD_PROTOKOL: t("BUTTON_ADD_PROTOKOL"),
      PROTOKOL_TITLE_DIALOG_CREATE: t("PROTOKOL_TITLE_DIALOG_CREATE"),
      SEARCH_PROTOKOL: t("SEARCH_PROTOKOL"),
      PROTOKOL_SEARCH: t("PROTOKOL_SEARCH"),
      PROTOKOL_TITLE_DIALOG_UPDATE: t("PROTOKOL_TITLE_DIALOG_UPDATE"),
      PROTOKOL_TITLE_DIALOG_DELETE: t("PROTOKOL_TITLE_DIALOG_DELETE"),
      CRUD_PROTOKOL: t("CRUD_PROTOKOL"),
      PROTOKOL_TITLE_MESSAGE_CREATE_PROTOKOL_SUCCESS: t("PROTOKOL_TITLE_MESSAGE_CREATE_PROTOKOL_SUCCESS"),
      PROTOKOL_TITLE_MESSAGE_DELETE_PROTOKOL_SUCCESS: t("PROTOKOL_TITLE_MESSAGE_DELETE_PROTOKOL_SUCCESS"),
      PROTOKOL_TITLE_MESSAGE_UPDATE_PROTOKOL_SUCCESS: t("PROTOKOL_TITLE_MESSAGE_UPDATE_PROTOKOL_SUCCESS"),
      PROTOKOL_PREFIKS_IS_REQUIRED: t("PROTOKOL_PREFIKS_IS_REQUIRED"),
      PROTOKOL_NAZIV_IS_REQUIRED: t("PROTOKOL_NAZIV_IS_REQUIRED"),
      PROTOKOL_VREMENSKI_OKVIR_IS_REQUIRED: t("PROTOKOL_VREMENSKI_OKVIR_IS_REQUIRED"),
      PROTOKOL_POCETNI_BROJ_IS_REQUIRED: t("PROTOKOL_POCETNI_BROJ_IS_REQUIRED"),
      PROTOKOL_NAREDNI_BROJ_IS_REQUIRED: t("PROTOKOL_NAREDNI_BROJ_IS_REQUIRED"),
      PROTOKOL_ADMINISTRACIJA_BROJEVA: t("PROTOKOL_ADMINISTRACIJA_BROJEVA"),
      PROTOKOL_BROJ_BRISANJE: t("PROTOKOL_BROJ_BRISANJE"),
      PROTOKOL_BROJ: t("PROTOKOL_BROJ"),
      PROTOKOL_BROJ_TITLE_MESSAGE_DELETE_PROTOKOL_BROJ_SUCCESS: t("PROTOKOL_BROJ_TITLE_MESSAGE_DELETE_PROTOKOL_BROJ_SUCCESS"),
      PROTOKOL_BROJ_PROTOKOL_REQUIRED: t("PROTOKOL_BROJ_PROTOKOL_REQUIRED"),
      PROTOKOL_BROJ_BROJEVI: t("PROTOKOL_BROJ_BROJEVI"),
      PROTOKOL_SELECT: t("PROTOKOL_SELECT"),
      PROTOKOL_OBRAZAC_STAMPE: t("PROTOKOL_OBRAZAC_STAMPE"),
      PROTOKOL_ADD: t("PROTOKOL_ADD"),
      PROTOKOL_ADD_FROM_REPOSITORY: t("PROTOKOL_ADD_FROM_REPOSITORY"),
      PROTOKOL_ADD_FROM_LOCAL: t("PROTOKOL_ADD_FROM_LOCAL"),
      PROTOKOL_OBRAZAC_STAMPE_OPIS: t("PROTOKOL_OBRAZAC_STAMPE_OPIS"),
      LABELS_STAMPA_PROTOKOLA: t("LABELS_STAMPA_PROTOKOLA"),

      /* generisani izvestaji */
      GENERISANJE_IZVESTAJA: t("GENERISANJE_IZVESTAJA"),
      GENERISANI_IZVESTAJ_LIST: t("GENERISANI_IZVESTAJ_LIST"),
      CRUD_GENERISANI_IZVESTAJ: t("CRUD_GENERISANI_IZVESTAJ"),
      GENERISANI_GENERISANI_IZVESTAJI: t("GENERISANI_GENERISANI_IZVESTAJI"),
      GENERISANI_PARAMETRI: t("GENERISANI_PARAMETRI"),
      GENERISANI_IZVESTAJI_DELETE: t("GENERISANI_IZVESTAJI_DELETE"),
      GENERISANI_IZVESTAJ_NAZIV: t("GENERISANI_IZVESTAJ_NAZIV"),
      GENERISANI_IZVESTAJ_OPIS: t("GENERISANI_IZVESTAJ_OPIS"),
      GENERISANI_IZVESTAJ_FILENAME: t("GENERISANI_IZVESTAJ_FILENAME"),
      GENERISANI_IZVESTAJ_FILETYPE: t("GENERISANI_IZVESTAJ_FILETYPE"),
      GENERISANI_IZVESTAJ_FILESIZE: t("GENERISANI_IZVESTAJ_FILESIZE"),
      GENERISANI_IZVESTAJ_VREME_KREIRANJA: t("GENERISANI_IZVESTAJ_VREME_KREIRANJA"),
      GENERISANI_IZVESTAJ_MESSAGE_DELETE_SUCCESS: t("GENERISANI_IZVESTAJ_MESSAGE_DELETE_SUCCESS"),
      GENERISANJE_IZVESTAJA_CONNECTION_ERROR: t("GENERISANJE_IZVESTAJA_CONNECTION_ERROR"),
      GENERISANJE_IZVESTAJA_MESSAGE_CREATE_SUCCESS: t("GENERISANJE_IZVESTAJA_MESSAGE_CREATE_SUCCESS"),
      GENERISANJE_IZVESTAJA_CLOSE_CONNECTION: t("GENERISANJE_IZVESTAJA_CLOSE_CONNECTION"),
      SVI_GENERISANI_IZVESTAJI: t("SVI_GENERISANI_IZVESTAJI"),
      SVI_GENERISANI_IZVESTAJI_STAT_IZVESTAJ: t("SVI_GENERISANI_IZVESTAJI_STAT_IZVESTAJ"),
      PONAVLJANJE_GENERISANJA_IZVESTAJI: t("PONAVLJANJE_GENERISANJA_IZVESTAJI"),
      PONAVLJANJE_GENERISANJA_JEDNOSTRUKO: t("PONAVLJANJE_GENERISANJA_JEDNOSTRUKO"),
      PONAVLJANJE_GENERISANJA_VISESTRUKO: t("PONAVLJANJE_GENERISANJA_VISESTRUKO"),
      DATUM_GENERISANJA_IZVESTAJA: t("DATUM_GENERISANJA_IZVESTAJA"),
      VREME_GENERISANJA_IZVESTAJA: t("VREME_GENERISANJA_IZVESTAJA"),
      UCESTALOST_PONAVLJANJA: t("UCESTALOST_PONAVLJANJA"),
      UCESTALOST_PONAVLJANJA_DAN: t("UCESTALOST_PONAVLJANJA_DAN"),
      UCESTALOST_PONAVLJANJA_NEDELJA: t("UCESTALOST_PONAVLJANJA_NEDELJA"),
      UCESTALOST_PONAVLJANJA_MESEC: t("UCESTALOST_PONAVLJANJA_MESEC"),
      IZVESTAJ_SLATI: t("IZVESTAJ_SLATI"),
      DAN_U_MESECU: t("DAN_U_MESECU"),
      ZAKAZIVANI_IZVESTAJI: t("ZAKAZIVANI_IZVESTAJI"),
      GENERISANI_IZVESTAJI_TITLE: t("GENERISANI_IZVESTAJI_TITLE"),
      ZAKAZANI_IZVESTAJI_TITLE: t("ZAKAZANI_IZVESTAJI_TITLE"),
      PERIOD_PONAVLJANJA: t("PERIOD_PONAVLJANJA"),
      GEN_IZVESTAJ_EMAIL_POSTOJI: t("GEN_IZVESTAJ_EMAIL_POSTOJI"),
      GEN_IZVESTAJ_EMAIL: t("GEN_IZVESTAJ_EMAIL"),
      BUTTON_ZAKAZI_IZVESTAJ: t("BUTTON_ZAKAZI_IZVESTAJ"),
      BUTTON_IZMENI_IZVESTAJ: t("BUTTON_IZMENI_IZVESTAJ"),
      LABEL_NA_NIVOU_DANA: t("LABEL_NA_NIVOU_DANA"),
      LABEL_NA_NIVOU_NEDELJE: t("LABEL_NA_NIVOU_NEDELJE"),
      LABEL_NA_NIVOU_MESECA: t("LABEL_NA_NIVOU_MESECA"),
      ZAKAZIVANJE_IZVESTAJA_POGRESNO_VREME: t("ZAKAZIVANJE_IZVESTAJA_POGRESNO_VREME"),
      ZAKAZIVANJE_IZVESTAJA_PONAVLJANJE_IS_REQUIRED: t("ZAKAZIVANJE_IZVESTAJA_PONAVLJANJE_IS_REQUIRED"),
      ZAKAZIVANJE_IZVESTAJA_UCESTALOST_IS_REQUIRED: t("ZAKAZIVANJE_IZVESTAJA_UCESTALOST_IS_REQUIRED"),
      ZAKAZIVANJE_IZVESTAJA_DATUM_VREME_IS_REQUIRED: t("ZAKAZIVANJE_IZVESTAJA_DATUM_VREME_IS_REQUIRED"),
      ZAKAZAN_IZVESTAJ_DELETE: t("ZAKAZAN_IZVESTAJ_DELETE"),
      ZAKAZAN_IZVESTAJ_CREATE: t("ZAKAZAN_IZVESTAJ_CREATE"),
      ZAKAZAN_IZVESTAJ_EDIT: t("ZAKAZAN_IZVESTAJ_EDIT"),

      /* placanje */
      PLACANJE_LIST: t("PLACANJE_LIST"),
      OBRACUNSKI_PERIOD_OD: t("OBRACUNSKI_PERIOD_OD"),
      OBRACUNSKI_PERIOD_DO: t("OBRACUNSKI_PERIOD_DO"),
      DATUM_PLACANJA: t("DATUM_PLACANJA"),
      OBRACUNATI_IZNOS: t("OBRACUNATI_IZNOS"),
      UPLACEN_IZNOS: t("UPLACEN_IZNOS"),
      NAPOMENA: t("NAPOMENA"),
      VALUTA: t("VALUTA"),
      BUTTON_DODAJ_UPLATU: t("BUTTON_DODAJ_UPLATU"),
      BUTTON_DODAJ_UPLATU_ZA_UGOVOR: t("BUTTON_DODAJ_UPLATU_ZA_UGOVOR"),
      PLACANJE_TITLE_DIALOG_UPDATE: t("PLACANJE_TITLE_DIALOG_UPDATE"),
      PLACANJE_TITLE_DIALOG_DELETE: t("PLACANJE_TITLE_DIALOG_DELETE"),
      ADD_PLACANJE_TITLE_DIALOG_CREATE: t("ADD_PLACANJE_TITLE_DIALOG_CREATE"),
      ADD_PLACANJE_ZA_UGOVOR_TITLE_DIALOG_CREATE: t("ADD_PLACANJE_ZA_UGOVOR_TITLE_DIALOG_CREATE"),
      USTANOVA_PLACANJE_DROPDOWN_MESEC: t("USTANOVA_PLACANJE_DROPDOWN_MESEC"),
      USTANOVA_PLACANJE_DROPDOWN_GODINA: t("USTANOVA_PLACANJE_DROPDOWN_GODINA"),
      USTANOVA_PLACANJE_NAPOMENA: t("USTANOVA_PLACANJE_NAPOMENA"),
      OBRACUNATI_IZNOS_RSD: t("OBRACUNATI_IZNOS_RSD"),
      UPLACEN_IZNOS_RSD: t("UPLACEN_IZNOS_RSD"),
      PLACANJE_IS_REQUIRED: t("PLACANJE_IS_REQUIRED"),
      USTANOVA_PLACANJE_OBRACUNSKI_PERIOD_OD_IS_REQUIRED: t("USTANOVA_PLACANJE_OBRACUNSKI_PERIOD_OD_IS_REQUIRED"),
      USTANOVA_PLACANJE_OBRACUNSKI_PERIOD_DO_IS_REQUIRED: t("USTANOVA_PLACANJE_OBRACUNSKI_PERIOD_DO_IS_REQUIRED"),
      PLACANJE_MESSAGE_CREATE_SUCCESS: t("PLACANJE_MESSAGE_CREATE_SUCCESS"),
      PLACANJE_TITLE_CREATE: t("PLACANJE_TITLE_CREATE"),
      USTANOVA_PLACANJE_OBAVEZE_IZMIRENE: t("USTANOVA_PLACANJE_OBAVEZE_IZMIRENE"),
      IZMENA_PLACANJE_LIST: t("IZMENA_PLACANJE_LIST"),
      PLACANJE_MESSAGE_UPDATE_SUCCESS: t("PLACANJE_MESSAGE_UPDATE_SUCCESS"),
      PLACANJE_MESSAGE_DELETE_SUCCESS: t("PLACANJE_MESSAGE_DELETE_SUCCESS"),
      PLACANJE_UGOVOR_VAZI_OD_IS_REQUIRED: t("PLACANJE_UGOVOR_VAZI_OD_IS_REQUIRED"),
      PLACANJE_UGOVOR_VAZI_DO_IS_REQUIRED: t("PLACANJE_UGOVOR_VAZI_DO_IS_REQUIRED"),
      PLACANJE_UGOVOR_VAZI_OD: t("PLACANJE_UGOVOR_VAZI_OD"),
      PLACANJE_UGOVOR_VAZI_DO: t("PLACANJE_UGOVOR_VAZI_DO"),
      PLACANJE_UGOVOR_VAZI_OD_VAZI_DO_COMPARE_VALIDATION: t("PLACANJE_UGOVOR_VAZI_OD_VAZI_DO_COMPARE_VALIDATION"),

      /* GLOBALNI PARAMETRI */
      GLOBALNI_PARAMETRI: t("GLOBALNI_PARAMETRI"),
      ADMINISTRACIJA_GLOBALNIH_PARAMETARA: t("ADMINISTRACIJA_GLOBALNIH_PARAMETARA"),
      PARAMETAR_GLOBALAN_MESSAGE_UPDATE_SUCCESS: t("PARAMETAR_GLOBALAN_MESSAGE_UPDATE_SUCCESS"),

      /* log high level */
      LOG_HIGH_LEVEL_MESS_RADNIK: t("LOG_HIGH_LEVEL_MESS_RADNIK"),
      LOG_HIGH_LEVEL_MESS_USTANOVA: t("LOG_HIGH_LEVEL_MESS_USTANOVA"),
      LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_1: t("LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_2: t("LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_3: t("LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_4: t("LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_4"),
      LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_1: t("LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_2: t("LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_3: t("LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_4: t("LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_4"),
      LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_5: t("LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_5"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_SUPERADMIN_1: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_SUPERADMIN_1"),
      LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_1: t("LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_1"),
      LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_2: t("LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_2"),
      LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_3: t("LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_3"),
      LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_4: t("LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_4"),
      LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_5: t("LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_5"),
      LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_6: t("LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_6"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_SUPERADMIN_1: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_SUPERADMIN_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_SUPERADMIN_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_SUPERADMIN_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_3: t("LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_4: t("LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_4"),
      LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_5: t("LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_5"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_3: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_4: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_4"),
      LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_1: t("LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_2: t("LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_3: t("LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_4: t("LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_DODATNI_MODUL_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_DODATNI_MODUL_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_DODATNI_MODUL_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_DODATNI_MODUL_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_1: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_2: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_3: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_4: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_4"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_1: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_2: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_3: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_4: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_4"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_1: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_2: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_3: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_4: t("LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_4"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_1: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_2: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_3: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_4: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_4"),
      LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_3: t("LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_3: t("LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_1: t("LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_2: t("LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_3: t("LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_4: t("LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_4"),
      LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_1: t("LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_2: t("LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_3: t("LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_4: t("LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_4"),
      LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_1: t("LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_2: t("LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_3: t("LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_1: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_2: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_3: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_POLJE_1: t("LOG_HIGH_LEVEL_MESS_CREATE_POLJE_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_POLJE_2: t("LOG_HIGH_LEVEL_MESS_CREATE_POLJE_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_POLJE_3: t("LOG_HIGH_LEVEL_MESS_CREATE_POLJE_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_POLJE_4: t("LOG_HIGH_LEVEL_MESS_CREATE_POLJE_4"),

      LOG_HIGH_LEVEL_MESS_CREATE_PAKET_USLUGA: t("LOG_HIGH_LEVEL_MESS_CREATE_PAKET_USLUGA"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PAKET_USLUGA: t("LOG_HIGH_LEVEL_MESS_UPDATE_PAKET_USLUGA"),
      LOG_HIGH_LEVEL_MESS_DELETE_PAKET_USLUGA: t("LOG_HIGH_LEVEL_MESS_DELETE_PAKET_USLUGA"),

      LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_FOR_PAKET_USLUGA: t("LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_FOR_PAKET_USLUGA"),
      LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_FOR_PAKET_USLUGA: t("LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_FOR_PAKET_USLUGA"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USLUGA_FOR_PAKET_USLUGA: t("LOG_HIGH_LEVEL_MESS_UPDATE_USLUGA_FOR_PAKET_USLUGA"),

      LOG_HIGH_LEVEL_MESS_CREATE_PROIZVOD_FOR_PAKET_USLUGA: t("LOG_HIGH_LEVEL_MESS_CREATE_PROIZVOD_FOR_PAKET_USLUGA"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PROIZVOD_FOR_PAKET_USLUGA: t("LOG_HIGH_LEVEL_MESS_UPDATE_PROIZVOD_FOR_PAKET_USLUGA"),
      LOG_HIGH_LEVEL_MESS_DELETE_PROIZVOD_FOR_PAKET_USLUGA: t("LOG_HIGH_LEVEL_MESS_DELETE_PROIZVOD_FOR_PAKET_USLUGA"),

      LOG_HIGH_LEVEL_MESS_CREATE_PAKET_ORGANIZACIONA_JEDINICA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_PAKET_ORGANIZACIONA_JEDINICA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_PAKET_ORGANIZACIONA_JEDINICA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_PAKET_ORGANIZACIONA_JEDINICA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_PAKET_ORGANIZACIONA_JEDINICA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_PAKET_ORGANIZACIONA_JEDINICA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_PAKET_ORGANIZACIONA_JEDINICA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_PAKET_ORGANIZACIONA_JEDINICA_2"),

      LOG_HIGH_LEVEL_MESS_CREATE_POLJE_5: t("LOG_HIGH_LEVEL_MESS_CREATE_POLJE_5"),
      LOG_HIGH_LEVEL_MESS_DELETE_POLJE_1: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_POLJE_2: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_POLJE_3: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_POLJE_4: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_4"),
      LOG_HIGH_LEVEL_MESS_DELETE_POLJE_5: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_5"),
      LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_4: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_5: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_5"),
      LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_KATEGORIJA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_KATEGORIJA_1"),
      LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_1: t("LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_1"),
      LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_2: t("LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_2"),
      LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_3: t("LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_KATEGORIJA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_KATEGORIJA_1"),

      LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_KATEGORIJA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_KATEGORIJA_1"),

      LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_1: t("LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_1"),
      LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_CREATE: t("LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_CREATE"),
      LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_UPDATE: t("LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_UPDATE"),
      LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_DELETE: t("LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_DELETE"),
      LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_3: t("LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_3"),

      LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_1: t("LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_2: t("LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_3: t("LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_1: t("LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_2: t("LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_3: t("LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_3"),

      LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_3: t("LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_3: t("LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_3"),

      LOG_HIGH_LEVEL_MESS_CREATE_OBAVESTENJE_1: t("LOG_HIGH_LEVEL_MESS_CREATE_OBAVESTENJE_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_OBAVESTENJE_2: t("LOG_HIGH_LEVEL_MESS_CREATE_OBAVESTENJE_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_OBAVESTENJE_1: t("LOG_HIGH_LEVEL_MESS_DELETE_OBAVESTENJE_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_OBAVESTENJE_2: t("LOG_HIGH_LEVEL_MESS_DELETE_OBAVESTENJE_2"),

      LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_3: t("LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_4: t("LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_4"),
      LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_3: t("LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_4: t("LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_4"),

      LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA: t("LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA"),
      LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA_2"),

      LOG_HIGH_LEVEL_MESS_CREATE_KATEGORIJA_IZVESTAJA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_KATEGORIJA_IZVESTAJA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_KATEGORIJA_IZVESTAJA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_KATEGORIJA_IZVESTAJA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_KATEGORIJA_IZVESTAJA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_KATEGORIJA_IZVESTAJA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_KATEGORIJA_IZVESTAJA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_KATEGORIJA_IZVESTAJA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_KATEGORIJA_IZVESTAJA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_KATEGORIJA_IZVESTAJA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_KATEGORIJA_IZVESTAJA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_KATEGORIJA_IZVESTAJA_2"),

      LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_5: t("LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_5"),

      LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_3: t("LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_4: t("LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_4"),
      LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_5: t("LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_5"),

      LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_5: t("LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_5"),

      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_3: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_4: t("LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_4"),

      LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_1: t("LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_2: t("LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_3: t("LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_4: t("LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_4"),
      LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_5: t("LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_5"),

      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_5: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_5"),

      LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_1: t("LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_2: t("LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_3: t("LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_4: t("LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_4"),
      LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_5: t("LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_5"),

      LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_1: t("LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_1"),
      LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_2: t("LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_2"),
      LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_3: t("LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_3"),
      LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_4: t("LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_4"),
      LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_5: t("LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_5"),
      LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_6: t("LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_6"),

      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_3: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_3"),

      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_3"),

      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_3: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_3"),

      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_1: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_2: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_3: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_3"),

      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_3"),

      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_1: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_2: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_3: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_3"),

      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_3: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_4: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_4"),

      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_4: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_4"),

      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_3: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_4: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_4"),

      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_1: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_2: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_3: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_4: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_4"),

      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_4: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_4"),

      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_1: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_2: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_3: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_4: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_4"),

      LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_CREATE: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_CREATE"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_UPDATE: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_UPDATE"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_DELETE: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_DELETE"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_2: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_2"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_3: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_3"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_4: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_4"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_5: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_5"),

      LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_CREATE: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_CREATE"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_UPDATE: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_UPDATE"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_DELETE: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_DELETE"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_2: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_2"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_3: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_3"),
      LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_4: t("LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_4"),

      LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_CREATE: t("LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_CREATE"),
      LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_UPDATE: t("LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_UPDATE"),
      LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_DELETE: t("LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_DELETE"),
      LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_2: t("LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_2"),

      LOG_HIGH_LEVEL_MESS_CREATE_RADNI_PERIOD_1: t("LOG_HIGH_LEVEL_MESS_CREATE_RADNI_PERIOD_1"),
      LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_2: t("LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_2"),
      LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_3: t("LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_3"),
      LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_4: t("LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_4"),
      LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_5: t("LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_5"),
      LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_6: t("LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_6"),
      LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_7: t("LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_7"),
      LOG_HIGH_LEVEL_MESS_DELETE_RADNI_PERIOD_1: t("LOG_HIGH_LEVEL_MESS_DELETE_RADNI_PERIOD_1"),

      LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_ENUM_1: t("LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_ENUM_1"),
      LOG_HIGH_LEVEL_MESS_INFO_POLJE_TIP_ENUM_1: t("LOG_HIGH_LEVEL_MESS_INFO_POLJE_TIP_ENUM_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_ENUM_1: t("LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_ENUM_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_ENUM_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_ENUM_1"),

      LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_1: t("LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_1"),
      LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_2: t("LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_2"),
      LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_CREATE: t("LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_CREATE"),
      LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_DELETE: t("LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_DELETE"),
      LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_UPDATE: t("LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_UPDATE"),
      LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_4: t("LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_4"),

      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_5: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_5"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_6: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_6"),

      LOG_HIGH_LEVEL_MESS_CREATE_REPO_POLJE_1: t("LOG_HIGH_LEVEL_MESS_CREATE_REPO_POLJE_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_REPO_POLJE_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_REPO_POLJE_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_REPO_POLJE_1: t("LOG_HIGH_LEVEL_MESS_DELETE_REPO_POLJE_1"),
      LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_2: t("LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_2"),
      LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_3: t("LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_3"),
      LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_4: t("LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_DIJAGNOZA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_DIJAGNOZA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_DIJAGNOZA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_DIJAGNOZA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_DIJAGNOZA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_DIJAGNOZA_1"),
      LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_DIJAGNOZA_1: t("LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_DIJAGNOZA_1"),

      LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_DIALOG_TERMIN_1: t("LOG_HIGH_LEVEL_MESS_CREATE_DIALOG_TERMIN_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_DIALOG_TERMIN_1: t("LOG_HIGH_LEVEL_MESS_DELETE_DIALOG_TERMIN_1"),
      LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_1: t("LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_1"),
      LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_2: t("LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_4"),

      LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_UTROSAK_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_UTROSAK_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_UTROSAK_1: t("LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_UTROSAK_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_UTROSAK_1: t("LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_UTROSAK_1"),
      LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_UTROSAK_1: t("LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_UTROSAK_1"),

      LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_USLUGA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_USLUGA_1"),
      LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_USLUGA_1: t("LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_USLUGA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_USLUGA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_USLUGA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_USLUGA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_USLUGA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_UNOSI_1: t("LOG_HIGH_LEVEL_MESS_CREATE_UNOSI_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_UNOSI_1: t("LOG_HIGH_LEVEL_MESS_DELETE_UNOSI_1"),

      LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_1: t("LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_1"),

      LOG_HIGH_LEVEL_MESS_PRISTUP_DOSIJE_PACIJENT: t("LOG_HIGH_LEVEL_MESS_PRISTUP_DOSIJE_PACIJENT"),
      LOG_HIGH_LEVEL_MESS_LOGOUT_KORISNIKA: t("LOG_HIGH_LEVEL_MESS_LOGOUT_KORISNIKA"),

      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_GRID: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_GRID"),
      LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_PODESAVANJA: t("LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_PODESAVANJA"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_TIP_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_TIP_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_TIP_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_TIP_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA: t("LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA"),
      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_1"),

      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_3"),

      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_1"),

      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_3: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_3"),
      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_4: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_4"),
      LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_5: t("LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_5"),

      LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA: t("LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_APLIKATIVNA_PRAVA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_APLIKATIVNA_PRAVA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_APLIKATIVNA_PRAVA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_APLIKATIVNA_PRAVA_2"),

      LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_RADNIK_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_RADNIK_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_RADNIK_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_RADNIK_2"),

      LOG_HIGH_LEVEL_MESS_CREATE_IZVESTAJ_1: t("LOG_HIGH_LEVEL_MESS_CREATE_IZVESTAJ_1"),
      LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_1: t("LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_1"),
      LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_2: t("LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_2"),

      LOG_HIGH_LEVEL_MESS_DELETE_IZVESTAJ_1: t("LOG_HIGH_LEVEL_MESS_DELETE_IZVESTAJ_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_IZVESTAJ_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_IZVESTAJ_1"),

      LOG_HIGH_LEVEL_MESS_CREATE_IZVESTAJ_PARAMETAR_1: t("LOG_HIGH_LEVEL_MESS_CREATE_IZVESTAJ_PARAMETAR_1"),
      LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_1: t("LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_1"),
      LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_2: t("LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_2"),

      LOG_HIGH_LEVEL_MESS_DELETE_IZVESTAJ_PARAMETAR_1: t("LOG_HIGH_LEVEL_MESS_DELETE_IZVESTAJ_PARAMETAR_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_IZVESTAJ_PARAMETAR_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_IZVESTAJ_PARAMETAR_1"),

      LOG_HIGH_LEVEL_MESS_CREATE_PROTOKOL_1: t("LOG_HIGH_LEVEL_MESS_CREATE_PROTOKOL_1"),
      LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_1: t("LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_1"),
      LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_2: t("LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_2"),
      LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_3: t("LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_3"),
      LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_4: t("LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_4"),

      LOG_HIGH_LEVEL_MESS_DELETE_PROTOKOL_1: t("LOG_HIGH_LEVEL_MESS_DELETE_PROTOKOL_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PROTOKOL_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_PROTOKOL_1"),

      LOG_HIGH_LEVEL_MESS_DELETE_PROTOKOL_BROJ_1: t("LOG_HIGH_LEVEL_MESS_DELETE_PROTOKOL_BROJ_1"),
      LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_1: t("LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_1"),
      LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_2: t("LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_2"),
      LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_3: t("LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_3"),
      LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_4: t("LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_4"),

      LOG_HIGH_LEVEL_MESS_DELETE_FAKTOR_RIZIKA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_FAKTOR_RIZIKA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_FAKTOR_RIZIKA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_FAKTOR_RIZIKA_1"),
      LOG_HIGH_LEVEL_MESS_INFO_FAKTOR_RIZIKA_1: t("LOG_HIGH_LEVEL_MESS_INFO_FAKTOR_RIZIKA_1"),

      LOG_HIGH_LEVEL_MESS_DELETE_GENERISANI_IZVESTAJ_1: t("LOG_HIGH_LEVEL_MESS_DELETE_GENERISANI_IZVESTAJ_1"),
      LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_1: t("LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_1"),
      LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_2: t("LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_2"),
      LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_3: t("LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_3"),

      LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_1: t("LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_1"),
      LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_2: t("LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_2"),
      LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_3: t("LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_3"),
      LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_UGOVOR_1: t("LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_UGOVOR_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_UGOVOR_2: t("LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_UGOVOR_2"),

      LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_1: t("LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_1"),
      LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_2: t("LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_2"),
      LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_3: t("LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_3"),

      LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_CREATE_1: t("LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_CREATE_1"),
      LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_CREATE_2: t("LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_CREATE_2"),

      LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_1: t("LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_1"),
      LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_2: t("LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_2"),

      LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_UPDATE_1: t("LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_UPDATE_1"),
      LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_UPDATE_2: t("LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_UPDATE_2"),

      LOG_HIGH_LEVEL_MESS_GENERISANI_IZVESTAJI_DELETE_1: t("LOG_HIGH_LEVEL_MESS_GENERISANI_IZVESTAJI_DELETE_1"),
      LOG_HIGH_LEVEL_MESS_GENERISANI_IZVESTAJI_DELETE_2: t("LOG_HIGH_LEVEL_MESS_GENERISANI_IZVESTAJI_DELETE_2"),

      LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_1: t("LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_1"),
      LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_2: t("LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_2"),
      LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_3: t("LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_3"),

      LOG_HIGH_LEVEL_MESS_DELETE_ZAKAZAN_IZVESTAJ1: t("LOG_HIGH_LEVEL_MESS_DELETE_ZAKAZAN_IZVESTAJ1"),
      LOG_HIGH_LEVEL_MESS_DELETE_ZAKAZAN_IZVESTAJ2: t("LOG_HIGH_LEVEL_MESS_DELETE_ZAKAZAN_IZVESTAJ2"),

      LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ1: t("LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ1"),
      LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ2: t("LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ2"),
      LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ3: t("LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ3"),

      LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ1: t("LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ1"),
      LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ2: t("LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ2"),
      LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ3: t("LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ3"),

      LOG_HIGH_LEVEL_MESS_CREATE_GENERISAN_IZVESTAJ1: t("LOG_HIGH_LEVEL_MESS_CREATE_GENERISAN_IZVESTAJ1"),
      LOG_HIGH_LEVEL_MESS_CREATE_GENERISAN_IZVESTAJ2: t("LOG_HIGH_LEVEL_MESS_CREATE_GENERISAN_IZVESTAJ2"),

      LOG_HIGH_LEVEL_MESS_CENA_CREATE_1: t("LOG_HIGH_LEVEL_MESS_CENA_CREATE_1"),
      LOG_HIGH_LEVEL_MESS_CENA_CREATE_USLUGA_1: t("LOG_HIGH_LEVEL_MESS_CENA_CREATE_USLUGA_1"),
      LOG_HIGH_LEVEL_MESS_CENA_CREATE_2: t("LOG_HIGH_LEVEL_MESS_CENA_CREATE_2"),
      LOG_HIGH_LEVEL_MESS_CENA_CREATE_3: t("LOG_HIGH_LEVEL_MESS_CENA_CREATE_3"),
      LOG_HIGH_LEVEL_MESS_CENA_CREATE_4: t("LOG_HIGH_LEVEL_MESS_CENA_CREATE_4"),
      LOG_HIGH_LEVEL_MESS_CENA_CREATE_5: t("LOG_HIGH_LEVEL_MESS_CENA_CREATE_5"),
      LOG_HIGH_LEVEL_MESS_CENA_DELETE_1: t("LOG_HIGH_LEVEL_MESS_CENA_DELETE_1"),
      LOG_HIGH_LEVEL_MESS_CENA_UPDATE_1: t("LOG_HIGH_LEVEL_MESS_CENA_UPDATE_1"),
      LOG_HIGH_LEVEL_MESS_CENA_DELETE_USLUGA_1: t("LOG_HIGH_LEVEL_MESS_CENA_DELETE_USLUGA_1"),
      LOG_HIGH_LEVEL_MESS_CENA_UPDATE_USLUGA_1: t("LOG_HIGH_LEVEL_MESS_CENA_UPDATE_USLUGA_1"),
      LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_1: t("LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_1"),
      LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_2: t("LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_2"),
      LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_3: t("LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_3"),
      LOG_HIGH_LEVEL_MESS_CENA_DELETE_PARTNER_1: t("LOG_HIGH_LEVEL_MESS_CENA_DELETE_PARTNER_1"),
      LOG_HIGH_LEVEL_MESS_CENA_UPDATE_PARTNER_1: t("LOG_HIGH_LEVEL_MESS_CENA_UPDATE_PARTNER_1"),

      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_NACINA_PLACANJA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_NACINA_PLACANJA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_NACINA_PLACANJA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_NACINA_PLACANJA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_NACINA_PLACANJA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_NACINA_PLACANJA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_NACINA_PLACANJA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_NACINA_PLACANJA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_NACINA_PLACANJA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_NACINA_PLACANJA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_NACINA_PLACANJA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_NACINA_PLACANJA_2"),

      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_KATEGORIJA_POPUSTA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_KATEGORIJA_POPUSTA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_KATEGORIJA_POPUSTA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_KATEGORIJA_POPUSTA_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_KATEGORIJA_POPUSTA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_KATEGORIJA_POPUSTA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_KATEGORIJA_POPUSTA_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_KATEGORIJA_POPUSTA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_KATEGORIJA_POPUSTA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_KATEGORIJA_POPUSTA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_KATEGORIJA_POPUSTA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_KATEGORIJA_POPUSTA_2"),

      LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_1: t("LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_2: t("LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PARTNER_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_PARTNER_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PARTNER_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_PARTNER_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_PARTNER_1: t("LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_PARTNER_2: t("LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_2"),

      LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_1: t("LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_2: t("LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_GRUPNO_OBAVESTENJE_1: t("LOG_HIGH_LEVEL_MESS_DELETE_GRUPNO_OBAVESTENJE_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_GRUPNO_OBAVESTENJE_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_GRUPNO_OBAVESTENJE_1"),
      LOG_HIGH_LEVEL_MESS_SEND_GRUPNO_OBAVESTENJE_1: t("LOG_HIGH_LEVEL_MESS_SEND_GRUPNO_OBAVESTENJE_1"),

      LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_3: t("LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_3"),
      LOG_HIGH_LEVEL_MESS_DELETE_OBRAZAC_STAMPE_PROTOKOLA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_OBRAZAC_STAMPE_PROTOKOLA_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_OBRAZAC_STAMPE_PROTOKOLA_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_OBRAZAC_STAMPE_PROTOKOLA_1"),

      LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_SPECIJALIZACIJA: t("LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_SPECIJALIZACIJA"),
      LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_SPECIJALIZACIJA: t("LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_SPECIJALIZACIJA"),
      LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_SPECIJALIZACIJA: t("LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_SPECIJALIZACIJA"),

      LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJE_2: t("LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJE_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJE_2: t("LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJE_2"),
      LABEL_NAPLATA_PREKOPIRAJ_PREOSTALI_IZNOS: t("LABEL_NAPLATA_PREKOPIRAJ_PREOSTALI_IZNOS"),

      LOG_HIGH_LEVEL_PACIJENT_USPESNO_SPOJEN_1: t("LOG_HIGH_LEVEL_PACIJENT_USPESNO_SPOJEN_1"),
      LOG_HIGH_LEVEL_PACIJENT_USPESNO_SPOJEN_2: t("LOG_HIGH_LEVEL_PACIJENT_USPESNO_SPOJEN_2"),

      LOG_HIGH_LEVEL_MESS_RAZDVAJANJE_PACIJENATA_1: t("LOG_HIGH_LEVEL_MESS_RAZDVAJANJE_PACIJENATA_1"),

      LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJE_2: t("LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJE_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJE_2: t("LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJE_2"),

      LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_1: t("LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_2: t("LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_2"),
      LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE_2: t("LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_1: t("LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_2: t("LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE_2: t("LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE_2"),

      LOG_HIGH_LEVEL_MESS_CREATE_PRODAJNI_PAKET_1: t("LOG_HIGH_LEVEL_MESS_CREATE_PRODAJNI_PAKET_1"),
      LOG_HIGH_LEVEL_MESS_CREATE_PRODAJNI_PAKET_2: t("LOG_HIGH_LEVEL_MESS_CREATE_PRODAJNI_PAKET_2"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PRODAJNI_PAKET_1: t("LOG_HIGH_LEVEL_MESS_UPDATE_PRODAJNI_PAKET_1"),
      LOG_HIGH_LEVEL_MESS_UPDATE_PRODAJNI_PAKET_2: t("LOG_HIGH_LEVEL_MESS_UPDATE_PRODAJNI_PAKET_2"),
      LOG_HIGH_LEVEL_MESS_DELETE_PRODAJNI_PAKET_1: t("LOG_HIGH_LEVEL_MESS_DELETE_PRODAJNI_PAKET_1"),
      LOG_HIGH_LEVEL_MESS_DELETE_PRODAJNI_PAKET_2: t("LOG_HIGH_LEVEL_MESS_DELETE_PRODAJNI_PAKET_2"),

      // FAKTORI RIZIKA
      FAKTOR_RIZIKA_LIST: t("FAKTOR_RIZIKA_LIST"),
      BUTTON_ADD_FAKTOR_RIZIKA: t("BUTTON_ADD_FAKTOR_RIZIKA"),
      FAKTOR_RIZIKA_TITLE_DIALOG_CREATE: t("FAKTOR_RIZIKA_TITLE_DIALOG_CREATE"),
      FAKTOR_RIZIKA_TITLE_DIALOG_UPDATE: t("FAKTOR_RIZIKA_TITLE_DIALOG_UPDATE"),
      FAKTOR_RIZIKA_TITLE_DIALOG_DELETE: t("FAKTOR_RIZIKA_TITLE_DIALOG_DELETE"),
      FAKTOR_RIZIKA_SEARCH: t("FAKTOR_RIZIKA_SEARCH"),
      FAKTOR_RIZIKA_SIFRA: t("FAKTOR_RIZIKA_SIFRA"),
      FAKTOR_RIZIKA_NAZIV: t("FAKTOR_RIZIKA_NAZIV"),
      LABEL_FAKTOR_RIZIKA: t("LABEL_FAKTOR_RIZIKA"),
      FAKTOR_RIZIKA_SIFRA_IKONICE: t("FAKTOR_RIZIKA_SIFRA_IKONICE"),
      FAKTOR_RIZIKA_TITLE_MESSAGE_UPDATE_FAKTOR_RIZIKA_SUCCESS: t("FAKTOR_RIZIKA_TITLE_MESSAGE_UPDATE_FAKTOR_RIZIKA_SUCCESS"),
      FAKTOR_RIZIKA_SIFRA_IS_REQUIRED: t("FAKTOR_RIZIKA_SIFRA_IS_REQUIRED"),
      FAKTOR_RIZIKA_NAZIV_IS_REQUIRED: t("FAKTOR_RIZIKA_NAZIV_IS_REQUIRED"),
      FAKTOR_RIZIKA_IKONICA_IS_REQUIRED: t("FAKTOR_RIZIKA_IKONICA_IS_REQUIRED"),
      FAKTOR_RIZIKA_TITLE_MESSAGE_CREATE_FAKTOR_RIZIKA_SUCCESS: t("FAKTOR_RIZIKA_TITLE_MESSAGE_CREATE_FAKTOR_RIZIKA_SUCCESS"),
      FAKTOR_RIZIKA_TITLE_MESSAGE_DELETE_FAKTOR_RIZIKA_SUCCESS: t("FAKTOR_RIZIKA_TITLE_MESSAGE_DELETE_FAKTOR_RIZIKA_SUCCESS"),
      FAKTOR_RIZIKA_DELETE_DIALOG_MESSAGE: t("FAKTOR_RIZIKA_DELETE_DIALOG_MESSAGE"),

      // FAKTORI RIZIKA IKONICA
      FAKTOR_RIZIKA_IKONICA_LIST: t("FAKTOR_RIZIKA_IKONICA_LIST"),
      BUTTON_ADD_FAKTOR_RIZIKA_IKONICA: t("BUTTON_ADD_FAKTOR_RIZIKA_IKONICA"),
      FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_CREATE: t("FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_CREATE"),
      FAKTOR_RIZIKA_IKONICA_SEARCH: t("FAKTOR_RIZIKA_IKONICA_SEARCH"),
      FAKTOR_RIZIKA_IKONICA_SIFRA: t("FAKTOR_RIZIKA_IKONICA_SIFRA"),
      FAKTOR_RIZIKA_IKONICA_NAZIV: t("FAKTOR_RIZIKA_IKONICA_NAZIV"),
      FAKTOR_RIZIKA_IKONICA_IKONICA: t("FAKTOR_RIZIKA_IKONICA_IKONICA"),
      FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_UPDATE: t("FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_UPDATE"),
      FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_DELETE: t("FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_DELETE"),
      LABEL_FAKTOR_RIZIKA_IKONICA: t("LABEL_FAKTOR_RIZIKA_IKONICA"),
      FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_DELETE_FAKTOR_RIZIKA_IKONICA_SUCCESS: t("FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_DELETE_FAKTOR_RIZIKA_IKONICA_SUCCESS"),
      FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_UPDATE_FAKTOR_RIZIKA_IKONICA_SUCCESS: t("FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_UPDATE_FAKTOR_RIZIKA_IKONICA_SUCCESS"),
      FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_CREATE_FAKTOR_RIZIKA_IKONICA_SUCCESS: t("FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_CREATE_FAKTOR_RIZIKA_IKONICA_SUCCESS"),
      FAKTOR_RIZIKA_IKONICA_SIFRA_IS_REQUIRED: t("FAKTOR_RIZIKA_IKONICA_SIFRA_IS_REQUIRED"),
      FAKTOR_RIZIKA_IKONICA_NAZIV_IS_REQUIRED: t("FAKTOR_RIZIKA_IKONICA_NAZIV_IS_REQUIRED"),
      FAKTOR_RIZIKA_IKONICA_IKONICA_IS_REQUIRED: t("FAKTOR_RIZIKA_IKONICA_IKONICA_IS_REQUIRED"),

      // FAKTURNI PODACI
      USTANOVA_FAKTURNI_PODACI: t("USTANOVA_FAKTURNI_PODACI"),
      FAKTURA_NAZIV: t("FAKTURA_NAZIV"),
      FAKTURA_ADRESA: t("FAKTURA_ADRESA"),
      FAKTURA_MESTO: t("FAKTURA_MESTO"),
      FAKTURA_MB: t("FAKTURA_MB"),
      FAKTURA_PIB: t("FAKTURA_PIB"),
      FAKTURA_MAIL: t("FAKTURA_MAIL"),
      USTANOVA_FAKTURNI_PODACI_TITLE_MESSAGE_UPDATE_USTANOVA_FAKTURNI_PODACI_SUCCESS: t("USTANOVA_FAKTURNI_PODACI_TITLE_MESSAGE_UPDATE_USTANOVA_FAKTURNI_PODACI_SUCCESS"),
      FAKTURA_MAIL_FORMAT_ERROR: t("FAKTURA_MAIL_FORMAT_ERROR"),
      FAKTURA_PIB_FORMAT_ERROR: t("FAKTURA_PIB_FORMAT_ERROR"),
      FAKTURA_MB_FORMAT_ERROR: t("FAKTURA_MB_FORMAT_ERROR"),
      ZAKAZIVANJE_ZA_BUDUCNOST: t("ZAKAZIVANJE_ZA_BUDUCNOST"),
      NO_IZVESTAJ_PARAMETRI: t("NO_IZVESTAJ_PARAMETRI"),

      // UVOZ SIFARNIKA
      SIFARNIK_IMPORT: t("SIFARNIK_IMPORT"),
      SIFARNIK_IMPORT_INATIATE_SUCCESS: t("SIFARNIK_IMPORT_INATIATE_SUCCESS"),
      SIFARNIK_IMPORT_USPESNO_UVEZEN: t("SIFARNIK_IMPORT_USPESNO_UVEZEN"),
      SIFARNIK_IMPORT_U_TOKU: t("SIFARNIK_IMPORT_U_TOKU"),
      IMPORT_SIFARNIK: t("IMPORT_SIFARNIK"),
      SIFARNIK_IMPORT_CONNECTION_ERROR: t("SIFARNIK_IMPORT_CONNECTION_ERROR"),
      SIFARNIK_IMPORT_STARTED_SUCCESS: t("SIFARNIK_IMPORT_STARTED_SUCCESS"),

      // IZVOZ
      IZVOZ: t("IZVOZ"),
      IZVOZ_NOVI_ZAHTEV: t("IZVOZ_NOVI_ZAHTEV"),
      IZVOZ_NOVI_ZAHTEV_KREIRANJE: t("IZVOZ_NOVI_ZAHTEV_KREIRANJE"),
      IZVOZ_IME_PREZIME_ZAHTEVAOCA: t("IZVOZ_IME_PREZIME_ZAHTEVAOCA"),
      IZVOZ_DATUM_ZAHTEVA: t("IZVOZ_DATUM_ZAHTEVA"),
      IZVOZ_VREME_ZAHTEVA: t("IZVOZ_VREME_ZAHTEVA"),
      IZVOZ_PERIOD_OD: t("IZVOZ_PERIOD_OD"),
      IZVOZ_PERIOD_DO: t("IZVOZ_PERIOD_DO"),
      IZVOZ_RAZLOG_ZAHTEVA: t("IZVOZ_RAZLOG_ZAHTEVA"),
      IZVOZ_DATUM_GENERISANJA: t("IZVOZ_DATUM_GENERISANJA"),
      IZVOZ_VREME_GENERISANJA: t("IZVOZ_VREME_GENERISANJA"),
      IZVOZ_TITLE_DIALOG_CREATE: t("IZVOZ_TITLE_DIALOG_CREATE"),
      IZVOZ_DIALOG_CREATE_DATUM_POCETKA: t("IZVOZ_DIALOG_CREATE_DATUM_POCETKA"),
      IZVOZ_DIALOG_CREATE_DATUM_ZAVRSETKA: t("IZVOZ_DIALOG_CREATE_DATUM_ZAVRSETKA"),
      IZVOZ_DIALOG_CREATE_RAZLOG: t("IZVOZ_DIALOG_CREATE_RAZLOG"),
      IZVOZ_DIALOG_MESSAGE_CREATE_IZVOZ_SUCCESS: t("IZVOZ_DIALOG_MESSAGE_CREATE_IZVOZ_SUCCESS"),
      IZVOZ_DIALOG_PERIOD_OD_IS_REQUIRED: t("IZVOZ_DIALOG_PERIOD_OD_IS_REQUIRED"),
      IZVOZ_DIALOG_PERIOD_DO_IS_REQUIRED: t("IZVOZ_DIALOG_PERIOD_DO_IS_REQUIRED"),
      IZVOZ_ZAHTEVANO: t("IZVOZ_ZAHTEVANO"),
      IZVOZ_GENERISANO: t("IZVOZ_GENERISANO"),
      IZVOZ_GENERISI: t("IZVOZ_GENERISI"),
      IZVOZ_DOWNLOAD: t("IZVOZ_DOWNLOAD"),
      IZVOZ_VAZI_OD_IS_BEFORE_VAZI_DO: t("IZVOZ_VAZI_OD_IS_BEFORE_VAZI_DO"),

      USTANOVA_HARD_DELETE_TITLE_MESSAGE_SUCCESS: t("USTANOVA_HARD_DELETE_TITLE_MESSAGE_SUCCESS"),
      WEBSOCKET_CONNECTION_ERROR: t("WEBSOCKET_CONNECTION_ERROR"),
      WEBSOCKET_CLOSE_CONNECTION: t("WEBSOCKET_CLOSE_CONNECTION"),

      KONTAKT_NEMA_REALIZOVANIH: t("KONTAKT_NEMA_REALIZOVANIH"),

      // PORUKE
      PORUKE: t("PORUKE"),
      PORUKE_PRIMLJENE: t("PORUKE_PRIMLJENE"),
      PORUKE_POSLATE: t("PORUKE_POSLATE"),
      PORUKE_NOVE: t("PORUKE_NOVE"),
      PORUKE_PRIMALAC: t("PORUKE_PRIMALAC"),
      PORUKE_NASLOV: t("PORUKE_NASLOV"),
      PORUKE_SADRZAJ: t("PORUKE_SADRZAJ"),
      PORUKE_DODAJ_DOKUMENT: t("PORUKE_DODAJ_DOKUMENT"),
      PORUKE_ISPRAZNI: t("PORUKE_ISPRAZNI"),
      PORUKE_POSALJI: t("PORUKE_POSALJI"),
      PORUKE_ZAKACI_DOKUMENT: t("PORUKE_ZAKACI_DOKUMENT"),
      PORUKA_CREATE_SUCCESS_MESSAGE: t("PORUKA_CREATE_SUCCESS_MESSAGE"),
      PORUKA_CREATE_EMPTY_RECEPIENTS_MESSAGE_ERROR: t("PORUKA_CREATE_EMPTY_RECEPIENTS_MESSAGE_ERROR"),
      PORUKA_CREATE_EMPTY_MESSAGE_ERROR: t("PORUKA_CREATE_EMPTY_MESSAGE_ERROR"),
      PORUKA_CREATE_EMPTY_SADRZAJ_AND_ATTACHMENT_ERROR: t("PORUKA_CREATE_EMPTY_SADRZAJ_AND_ATTACHMENT_ERROR"),
      PORUKA_CREATE_PORUKA_SIZE_ERROR: t("PORUKA_CREATE_PORUKA_SIZE_ERROR"),
      PORUKA_SEARCH: t("PORUKA_SEARCH"),
      PRIKAZI_PORUKU_1: t("PRIKAZI_PORUKU_1"),
      PRIKAZI_PORUKU_2: t("PRIKAZI_PORUKU_2"),
      PRIKAZI_PORUKU_3: t("PRIKAZI_PORUKU_3"),
      PORUKA_POSILJALAC: t("PORUKA_POSILJALAC"),
      PORUKA_PRIMALAC: t("PORUKA_PRIMALAC"),
      PORUKA_PRIMAOCI: t("PORUKA_PRIMAOCI"),
      PORUKE_KRAJ: t("PORUKE_KRAJ"),
      TOOLTIP_PORUKA_REPLY: t("TOOLTIP_PORUKA_REPLY"),
      TOOLTIP_PORUKA_REPLY_ALL: t("TOOLTIP_PORUKA_REPLY_ALL"),
      TOOLTIP_PORUKA_FORWARD: t("TOOLTIP_PORUKA_FORWARD"),
      TOOLTIP_PORUKA_UNREAD: t("TOOLTIP_PORUKA_UNREAD"),
      TOOLTIP_PORUKA_DELETE: t("TOOLTIP_PORUKA_DELETE"),
      PORUKA_DELETE_DIALOG_TEXT: t("PORUKA_DELETE_DIALOG_TEXT"),
      POCETAK_PROSLEDJENE_PORUKE: t("POCETAK_PROSLEDJENE_PORUKE"),
      PORUKA_DATUM: t("PORUKA_DATUM"),
      PORUKA_RODITELJ_NASLOV_1: t("PORUKA_RODITELJ_NASLOV_1"),
      PORUKA_RODITELJ_NASLOV_2: t("PORUKA_RODITELJ_NASLOV_2"),
      PORUKA_RODITELJ_NASLOV_3: t("PORUKA_RODITELJ_NASLOV_3"),
      PORUKA_RODITELJ_NASLOV_3_MUSKI: t("PORUKA_RODITELJ_NASLOV_3_MUSKI"),
      PORUKA_RODITELJ_NASLOV_3_ZENSKI: t("PORUKA_RODITELJ_NASLOV_3_ZENSKI"),

      // CENE
      CENE_CREATE_EMPTY_PROIZVOD_ERROR_MESSAGE: t("CENE_CREATE_EMPTY_PROIZVOD_ERROR_MESSAGE"),
      CENE_CREATE_EMPTY_USLUGA_ERROR_MESSAGE: t("CENE_CREATE_EMPTY_USLUGA_ERROR_MESSAGE"),
      CENE_CREATE_EMPTY_IZNOS_ERROR_MESSAGE: t("CENE_CREATE_EMPTY_IZNOS_ERROR_MESSAGE"),
      CENE_CREATE_EMPTY_VALUTA_ERROR_MESSAGE: t("CENE_CREATE_EMPTY_VALUTA_ERROR_MESSAGE"),
      CENE_CREATE_EMPTY_PORESKA_STOPA_ERROR_MESSAGE: t("CENE_CREATE_EMPTY_PORESKA_STOPA_ERROR_MESSAGE"),
      CENE_CREATE_EMPTY_DATUM_OD_ERROR_MESSAGE: t("CENE_CREATE_EMPTY_DATUM_OD_ERROR_MESSAGE"),
      CENE_CREATE_WRONG_DATUM_DO_ERROR_MESSAGE: t("CENE_CREATE_WRONG_DATUM_DO_ERROR_MESSAGE"),
      CENE_CREATE_SUCCESS_MESSAGE: t("CENE_CREATE_SUCCESS_MESSAGE"),
      CENE_DELETE_SUCCESS_MESSAGE: t("CENE_DELETE_SUCCESS_MESSAGE"),
      CENE_UPDATE_SUCCESS_MESSAGE: t("CENE_UPDATE_SUCCESS_MESSAGE"),

      // PARTNERI
      LABEL_PARTNERI: t("LABEL_PARTNERI"),
      LABEL_EDIT_PARTNER: t("LABEL_EDIT_PARTNER"),
      LABEL_DELETE_PARTNER: t("LABEL_DELETE_PARTNER"),
      LABEL_ADD_PARTNER: t("LABEL_ADD_PARTNER"),
      SEARCH_PARTNER: t("SEARCH_PARTNER"),
      LABEL_PARTNER_PODACI: t("LABEL_PARTNER_PODACI"),
      LABEL_PARTNER_CENE: t("LABEL_PARTNER_CENE"),
      LABEL_PARTNER_CREATE: t("LABEL_PARTNER_CREATE"),
      LABEL_PARTNER_UPDATE: t("LABEL_PARTNER_UPDATE"),
      LABEL_PARTNER_DELETE: t("LABEL_PARTNER_DELETE"),
      LABEL_TIP_PARTNERA: t("LABEL_TIP_PARTNERA"),
      LABEL_KATEGORIJA_POLISE_PARTNER: t("LABEL_KATEGORIJA_POLISE_PARTNER"),
      LABEL_ADRESA_PARTNER: t("LABEL_ADRESA_PARTNER"),
      LABEL_POSTANSKI_BROJ_PARTNER: t("LABEL_POSTANSKI_BROJ_PARTNER"),
      LABEL_SEDISTE_PARTNER: t("LABEL_SEDISTE_PARTNER"),
      LABEL_DRZAVA_PARTNER_PARTNER: t("LABEL_DRZAVA_PARTNER_PARTNER"),
      LABEL_ZIRO_RACUN_PARTNER: t("LABEL_ZIRO_RACUN_PARTNER"),
      LABEL_PIB_PARTNER: t("LABEL_PIB_PARTNER"),
      LABEL_MATICNI_BROJ_PARTNER: t("LABEL_MATICNI_BROJ_PARTNER"),
      LABEL_SIFRA_DELATNOSTI_PARTNER: t("LABEL_SIFRA_DELATNOSTI_PARTNER"),
      LABEL_TELEFON_PARTNER: t("LABEL_TELEFON_PARTNER"),
      LABEL_EMAIL_PARTNER: t("LABEL_EMAIL_PARTNER"),
      LABEL_BROJ_DANA_VALUTE_PLACANJA_PARTNER: t("LABEL_BROJ_DANA_VALUTE_PLACANJA_PARTNER"),
      LABEL_POPUST_PARTNER: t("LABEL_POPUST_PARTNER"),
      LABEL_STAMPANJE_FISKALNOG_ISECKA_PARTNER: t("LABEL_STAMPANJE_FISKALNOG_ISECKA_PARTNER"),
      LABEL_PLACANJE_DEVIZE_PARTNER: t("LABEL_PLACANJE_DEVIZE_PARTNER"),
      LABEL_DEO_PACIJENT_PARTNER: t("LABEL_DEO_PACIJENT_PARTNER"),
      LABEL_NAPOMENA_PARTNER: t("LABEL_NAPOMENA_PARTNER"),
      LABEL_DODAJ_PARTNER: t("LABEL_DODAJ_PARTNER"),
      LABEL_IZMENI_PARTNER: t("LABEL_IZMENI_PARTNER"),
      LABEL_OBRISI_PARTNER: t("LABEL_OBRISI_PARTNER"),
      LABEL_ADD_CENA_PARTNER: t("LABEL_ADD_CENA_PARTNER"),
      LABEL_ADD_CENA_PARTNER_DIALOG: t("LABEL_ADD_CENA_PARTNER_DIALOG"),
      LABEL_UPDATE_CENA_PARTNER_DIALOG: t("LABEL_UPDATE_CENA_PARTNER_DIALOG"),
      LABEL_DELETE_CENA_PARTNER_DIALOG: t("LABEL_DELETE_CENA_PARTNER_DIALOG"),
      LABEL_POPUST_CENA_PARTNER_DIALOG: t("LABEL_POPUST_CENA_PARTNER_DIALOG"),
      LABEL_PLACA_PACIJENT_CENA_PARTNER_DIALOG: t("LABEL_PLACA_PACIJENT_CENA_PARTNER_DIALOG"),
      LABEL_FIKSNO_PLACA_PACIJENT_CENA_PARTNER_DIALOG: t("LABEL_FIKSNO_PLACA_PACIJENT_CENA_PARTNER_DIALOG"),
      LABEL_FIKSNO_DODATAK_CENA_PARTNER_DIALOG: t("LABEL_FIKSNO_DODATAK_CENA_PARTNER_DIALOG"),
      LABEL_UPDATE_CENA_PARTNER: t("LABEL_UPDATE_CENA_PARTNER"),
      LABEL_DELETE_CENA_PARTNER: t("LABEL_DELETE_CENA_PARTNER"),
      LABEL_NAZIV_USLUGE_PARTNER: t("LABEL_NAZIV_USLUGE_PARTNER"),
      LABEL_VAZI_OD_PARTNER: t("LABEL_VAZI_OD_PARTNER"),
      LABEL_VAZI_DO_PARTNER: t("LABEL_VAZI_DO_PARTNER"),
      LABEL_PLACA_PACIJENT_PARTNER: t("LABEL_PLACA_PACIJENT_PARTNER"),
      LABEL_PLACA_PACIJENT_FIKSNO_PARTNER: t("LABEL_PLACA_PACIJENT_FIKSNO_PARTNER"),
      LABEL_CENA_PARTNER: t("LABEL_CENA_PARTNER"),
      LABEL_PORESKA_STOPA_PARTNER: t("LABEL_PORESKA_STOPA_PARTNER"),
      LABEL_POPUST_OD_PARTNER: t("LABEL_POPUST_OD_PARTNER"),
      LABEL_POPUST_DO_PARTNER: t("LABEL_POPUST_DO_PARTNER"),
      LABEL_UDEO_PACIJENTA_OD_PARTNER: t("LABEL_UDEO_PACIJENTA_OD_PARTNER"),
      LABEL_UDEO_PACIJENTA_DO_PARTNER: t("LABEL_UDEO_PACIJENTA_DO_PARTNER"),
      LABEL_FIKSNI_UDEO_OD_PARTNER: t("LABEL_FIKSNI_UDEO_OD_PARTNER"),
      LABEL_FIKSNI_UDEO_DO_PARTNER: t("LABEL_FIKSNI_UDEO_DO_PARTNER"),
      LABEL_CENA_OD_PARTNER: t("LABEL_CENA_OD_PARTNER"),
      LABEL_CENA_DO_PARTNER: t("LABEL_CENA_DO_PARTNER"),
      LABEL_DEO_PLACA_PACIJENT: t("LABEL_DEO_PLACA_PACIJENT"),
      PARTNER_MESSAGE_CREATE_SUCCESS: t("PARTNER_MESSAGE_CREATE_SUCCESS"),
      PARTNER_MESSAGE_UPDATE_SUCCESS: t("PARTNER_MESSAGE_UPDATE_SUCCESS"),
      PARTNER_MESSAGE_DELETE_SUCCESS: t("PARTNER_MESSAGE_DELETE_SUCCESS"),
      PARTNER_VAZI_DO_VALIDATION_ERROR_MESSAGE: t("PARTNER_VAZI_DO_VALIDATION_ERROR_MESSAGE"),
      LABEL_PARTNERI_TIP_PARTNERA: t("LABEL_PARTNERI_TIP_PARTNERA"),

      // NAPLATA
      LABEL_NAPLATA: t("LABEL_NAPLATA"),
      LABEL_SPISAK_NAPLATA: t("LABEL_SPISAK_NAPLATA"),
      LABEL_UPLATIOCI_NAPLATA: t("LABEL_UPLATIOCI_NAPLATA"),
      LABEL_RACUNI_NAPLATA: t("LABEL_RACUNI_NAPLATA"),
      LABEL_NASTAVI_NAPLATA: t("LABEL_NASTAVI_NAPLATA"),
      LABEL_BRZA_NAPLATA: t("LABEL_BRZA_NAPLATA"),
      LABEL_NAPLATI: t("LABEL_NAPLATI"),
      LABEL_SNIMI_NAPLATA: t("LABEL_SNIMI_NAPLATA"),
      LABEL_BROJ_PROTOKOLA_NAPLATA: t("LABEL_BROJ_PROTOKOLA_NAPLATA"),
      LABEL_PARTNER_NAPLATA: t("LABEL_PARTNER_NAPLATA"),
      LABEL_LEKAR_NAPLATA: t("LABEL_LEKAR_NAPLATA"),
      LABEL_TIP_PREGLEDA_NAPLATA: t("LABEL_TIP_PREGLEDA_NAPLATA"),
      LABEL_DATUM_NAPLATA: t("LABEL_DATUM_NAPLATA"),
      LABEL_PREGLED_REALIZOVAN_NAPLATA: t("LABEL_PREGLED_REALIZOVAN_NAPLATA"),
      LABEL_AMBULANTA_NAPLATA: t("LABEL_AMBULANTA_NAPLATA"),
      LABEL_PACIJENT_NAPLATA: t("LABEL_PACIJENT_NAPLATA"),
      LABEL_PRIKAZ_NAPLATA: t("LABEL_PRIKAZ_NAPLATA"),
      LABEL_STATUS_RACUNA_NAPLATA: t("LABEL_STATUS_RACUNA_NAPLATA"),
      LABEL_RACUN_NAPLATIV_NAPLATA: t("LABEL_RACUN_NAPLATIV_NAPLATA"),
      LABEL_UPLATILAC_NAPLATA: t("LABEL_UPLATILAC_NAPLATA"),
      LABEL_POPUST_NAPLATA: t("LABEL_POPUST_NAPLATA"),
      LABEL_PLACA_NAPLATA: t("LABEL_PLACA_NAPLATA"),
      LABEL_NAPOMENA_NAPLATA: t("LABEL_NAPOMENA_NAPLATA"),
      LABEL_OBRISI_UPLATIOCA: t("LABEL_OBRISI_UPLATIOCA"),
      LABEL_UMANJENJE_RACUNA: t("LABEL_UMANJENJE_RACUNA"),
      LABEL_CEK: t("LABEL_CEK"),
      LABEL_GOTOVINA: t("LABEL_GOTOVINA"),
      LABEL_VAUCER: t("LABEL_VAUCER"),
      LABEL_PRENOS_NA_RACUN: t("LABEL_PRENOS_NA_RACUN"),
      LABEL_INSTANT: t("LABEL_INSTANT"),
      LABEL_DRUGO_BEZGOTOVINSKO: t("LABEL_DRUGO_BEZGOTOVINSKO"),
      LABEL_KARTICA: t("LABEL_KARTICA"),
      LABEL_RACUN_ZA_UPLATIOCA_NAPLATA: t("LABEL_RACUN_ZA_UPLATIOCA_NAPLATA"),
      LABEL_REDNI_BROJ_NAPLATA: t("LABEL_REDNI_BROJ_NAPLATA"),
      LABEL_KOLICINA_NAPLATA: t("LABEL_KOLICINA_NAPLATA"),
      LABEL_VREME_PRUZANJA_NAPLATA: t("LABEL_VREME_PRUZANJA_NAPLATA"),
      LABEL_BEZ_POPUSTA_NAPLATA: t("LABEL_BEZ_POPUSTA_NAPLATA"),
      LABEL_KONTAKT_REQUIRED_NAPLATA: t("LABEL_KONTAKT_REQUIRED_NAPLATA"),
      LABEL_RACUN_KREIRAN_SUCCESS: t("LABEL_RACUN_KREIRAN_SUCCESS"),
      LABEL_UKUPNA_CENA_NAPLATA: t("LABEL_UKUPNA_CENA_NAPLATA"),
      LABEL_KONTAKT_USLUGE_UTROSCI_NAPLATA: t("LABEL_KONTAKT_USLUGE_UTROSCI_NAPLATA"),
      LABEL_DRUGI_KONTAKTI_USLUGE_UTROSCI_NAPLATA: t("LABEL_DRUGI_KONTAKTI_USLUGE_UTROSCI_NAPLATA"),
      LABEL_BEZ_POPUSTA: t("LABEL_BEZ_POPUSTA"),
      LABEL_ZBIR_PROCENATA_NAPLATA: t("LABEL_ZBIR_PROCENATA_NAPLATA"),
      LABEL_ZBIR_CENA_NAPLATA: t("LABEL_ZBIR_CENA_NAPLATA"),
      LABEL_UPLATIOCI_REQUIRED: t("LABEL_UPLATIOCI_REQUIRED"),
      LABEL_IZNOS_RACUNA_REQUIRED: t("LABEL_IZNOS_RACUNA_REQUIRED"),
      LABEL_UKUPNO_NAPLATA: t("LABEL_UKUPNO_NAPLATA"),
      LABEL_CENA_SA_POPUSTIMA: t("LABEL_CENA_SA_POPUSTIMA"),
      LABEL_PROCENAT_NULA_NAPLATA: t("LABEL_PROCENAT_NULA_NAPLATA"),
      LABEL_NACINI_PLACANJA_REQUIRED: t("LABEL_NACINI_PLACANJA_REQUIRED"),
      LABEL_INFO_OBRACUNATA_CENA: t("LABEL_INFO_OBRACUNATA_CENA"),
      LABEL_WARNING_PROMENA_POPUSTA: t("LABEL_WARNING_PROMENA_POPUSTA"),
      LABEL_SPECIFIKACIJE_RACUNA_NAPLATA: t("LABEL_SPECIFIKACIJE_RACUNA_NAPLATA"),
      LABEL_SPECIFIKACIJE_RACUNA_NAPLATA_WARNING_POPUP: t("LABEL_SPECIFIKACIJE_RACUNA_NAPLATA_WARNING_POPUP"),

      // OBAVESTENJA ZA PACIJENTE
      LABEL_OBAVESTENJA_ZA_PACIJENTE: t("LABEL_OBAVESTENJA_ZA_PACIJENTE"),
      LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TITLE_ADD: t("LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TITLE_ADD"),
      LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TITLE_UPDATE: t("LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TITLE_UPDATE"),
      LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TEKST: t("LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TEKST"),
      LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_NASLOV: t("LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_NASLOV"),
      LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_UNESITE_TEKST: t("LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_UNESITE_TEKST"),
      LABEL_OBAVESTENJA_ZA_PACIJENTE_POSALJI: t("LABEL_OBAVESTENJA_ZA_PACIJENTE_POSALJI"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_DELETE_CONFIRM_MESSAGE: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_DELETE_CONFIRM_MESSAGE"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_CREATE_SUCCESS_MESSAGE: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_CREATE_SUCCESS_MESSAGE"),
      LABEL_OBAVESTENJA_ZA_PACIJENTE_EMPTY_DATA: t("LABEL_OBAVESTENJA_ZA_PACIJENTE_EMPTY_DATA"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_DELETE_SUCCESS_MESSAGE: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_DELETE_SUCCESS_MESSAGE"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_UPDATE_SUCCESS_MESSAGE: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_UPDATE_SUCCESS_MESSAGE"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_MESSAGE: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_MESSAGE"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_LABEL: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_LABEL"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_DATE: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_DATE"),
      LABEL_OBAVESTENJA_TAB_ZA_SLANJE: t("LABEL_OBAVESTENJA_TAB_ZA_SLANJE"),
      LABEL_OBAVESTENJA_TAB_POSLATA: t("LABEL_OBAVESTENJA_TAB_POSLATA"),
      LABEL_VRSTA_OBAVESTENJA: t("LABEL_VRSTA_OBAVESTENJA"),
      LABEL_DATUM_PRIPREME_OBAVESTENJA: t("LABEL_DATUM_PRIPREME_OBAVESTENJA"),
      LABEL_DATUM_SLANJA_OBAVESTENJA: t("LABEL_DATUM_SLANJA_OBAVESTENJA"),
      LABEL_TEKST_OBAVESTENJA: t("LABEL_TEKST_OBAVESTENJA"),
      LABEL_OBAVESTENJE_KREIRANO_VREME: t("LABEL_OBAVESTENJE_KREIRANO_VREME"),
      LABEL_OBAVESTENJE_POSLATO_VREME: t("LABEL_OBAVESTENJE_POSLATO_VREME"),
      LABEL_OBAVESTENJE_KREIRANO_U: t("LABEL_OBAVESTENJE_KREIRANO_U"),
      LABEL_OBAVESTENJE_POSLEDNJE_AZURIRANO_U: t("LABEL_OBAVESTENJE_POSLEDNJE_AZURIRANO_U"),
      LABEL_OBAVESTENJE_POSLEDNJE_AZURIRANO: t("LABEL_OBAVESTENJE_POSLEDNJE_AZURIRANO"),
      LABEL_OBAVESTENJE_OBUHVACENO_PACIJENATA: t("LABEL_OBAVESTENJE_OBUHVACENO_PACIJENATA"),
      LABEL_OBAVESTENJE_GODINA: t("LABEL_OBAVESTENJE_GODINA"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_CONFIRM_MESSAGE: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_CONFIRM_MESSAGE"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_BROJ_PRIMALACA: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_BROJ_PRIMALACA"),
      LABEL_OBAVESTENJE_DIJAGNOZA_USLUGA_NOTE: t("LABEL_OBAVESTENJE_DIJAGNOZA_USLUGA_NOTE"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_POL_REQUIRED: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_POL_REQUIRED"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_DATUM_AUTOMATSKOG_SLANJA: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_DATUM_AUTOMATSKOG_SLANJA"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_TEXT: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_TEXT"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_DATE: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_DATE"),
      LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_TIME: t("LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_TIME"),
      LABEL_OBAVESTENJA_ZA_PACIJENTE_NO_PATIENTS: t("LABEL_OBAVESTENJA_ZA_PACIJENTE_NO_PATIENTS"),
      LABEL_OBAVESTENJE_ZA_PACIJENTE_SEND_NOTE: t("LABEL_OBAVESTENJE_ZA_PACIJENTE_SEND_NOTE"),

      // ZAKAZIVANJE PREGLEDA
      LABEL_DODAJ_TERMIN: t("LABEL_DODAJ_TERMIN"),

      // OBRASCI STAMPE PROTOKOLA
      LABEL_OBRASCI_STAMPE_PROTOKOLA: t("LABEL_OBRASCI_STAMPE_PROTOKOLA"),
      LABEL_OBRASCI_STAMPE_PROTOKOLA_OPIS: t("LABEL_OBRASCI_STAMPE_PROTOKOLA_OPIS"),
      LABEL_OBRASCI_STAMPE_PROTOKOLA_NAZIV_JRXML: t("LABEL_OBRASCI_STAMPE_PROTOKOLA_NAZIV_JRXML"),
      LABEL_ADD_OBRASCI_STAMPE_PROTOKOLA: t("LABEL_ADD_OBRASCI_STAMPE_PROTOKOLA"),
      LABEL_EDIT_OBRASCI_STAMPE_PROTOKOLA: t("LABEL_EDIT_OBRASCI_STAMPE_PROTOKOLA"),
      LABEL_DELETE_OBRASCI_STAMPE_PROTOKOLA: t("LABEL_DELETE_OBRASCI_STAMPE_PROTOKOLA"),
      LABEL_OBRAZAC_STAMPE_CREATE: t("LABEL_OBRAZAC_STAMPE_CREATE"),
      LABEL_OBRAZAC_STAMPE_JRXML: t("LABEL_OBRAZAC_STAMPE_JRXML"),
      LABEL_OBRAZAC_STAMPE_PROTOKOLA_CREATE_SUCCESS_MESSAGE: t("LABEL_OBRAZAC_STAMPE_PROTOKOLA_CREATE_SUCCESS_MESSAGE"),
      LABEL_OBRAZAC_STAMPE_PROTOKOLA_UPDATE_SUCCESS_MESSAGE: t("LABEL_OBRAZAC_STAMPE_PROTOKOLA_UPDATE_SUCCESS_MESSAGE"),
      LABEL_OBRAZAC_STAMPE_PROTOKOLA_DELETE_SUCCESS_MESSAGE: t("LABEL_OBRAZAC_STAMPE_PROTOKOLA_DELETE_SUCCESS_MESSAGE"),
      LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_UPDATE: t("LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_UPDATE"),
      LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_DELETE: t("LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_DELETE"),
      LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_CREATE: t("LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_CREATE"),

      // SPAJANJE PACIJENATA
      LABEL_SPAJANJE_PACIJENATA: t("LABEL_SPAJANJE_PACIJENATA"),
      LABEL_SPAJANJE_PACIJENATA_PREPISI: t("LABEL_SPAJANJE_PACIJENATA_PREPISI"),
      LABEL_SPAJANJE_PACIJENATA_IME: t("LABEL_SPAJANJE_PACIJENATA_IME"),
      LABEL_SPAJANJE_PACIJENATA_PREZIME: t("LABEL_SPAJANJE_PACIJENATA_PREZIME"),
      LABEL_SPAJANJE_PACIJENATA_DATUM: t("LABEL_SPAJANJE_PACIJENATA_DATUM"),
      LABEL_SPAJANJE_PACIJENATA_PRETRAZI: t("LABEL_SPAJANJE_PACIJENATA_PRETRAZI"),
      LABEL_SPAJANJE_PACIJENATA_SPOJI_PACIJENTE: t("LABEL_SPAJANJE_PACIJENATA_SPOJI_PACIJENTE"),
      LABEL_SPAJANJE_PACIJENATA_REZULTAT_PRETRAGE: t("LABEL_SPAJANJE_PACIJENATA_REZULTAT_PRETRAGE"),
      LABEL_SPAJANJE_PACIJENATA_ID_PACIJENTA: t("LABEL_SPAJANJE_PACIJENATA_ID_PACIJENTA"),
      LABEL_SPAJANJE_PACIJENATA_DA_LI_STE_SIGURNI: t("LABEL_SPAJANJE_PACIJENATA_DA_LI_STE_SIGURNI"),
      LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_1: t("LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_1"),
      LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_2: t("LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_2"),
      LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_3: t("LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_3"),
      LABEL_SPAJANJE_PACIJENATA_ARHIVA: t("LABEL_SPAJANJE_PACIJENATA_ARHIVA"),
      LABEL_SPAJANJE_PACIJENATA_VREME_SPAJANJA: t("LABEL_SPAJANJE_PACIJENATA_VREME_SPAJANJA"),
      LABEL_SPAJANJE_PACIJENATA_TRENUTNI_PACIJENT: t("LABEL_SPAJANJE_PACIJENATA_TRENUTNI_PACIJENT"),
      LABEL_SPAJANJE_PACIJENATA_STARI_PACIJENT: t("LABEL_SPAJANJE_PACIJENATA_STARI_PACIJENT"),
      LABEL_SPAJANJE_PACIJENATA_DOSIJE: t("LABEL_SPAJANJE_PACIJENATA_DOSIJE"),
      LABEL_SPAJANJE_PACIJENATA_BUTTON_RAZDVOJ: t("LABEL_SPAJANJE_PACIJENATA_BUTTON_RAZDVOJ"),
      LABEL_RAZDVAJANJE_PACIJENATA_SUCCESS_MESSAGE: t("LABEL_RAZDVAJANJE_PACIJENATA_SUCCESS_MESSAGE"),
      LABEL_RAZDVAJANJE_CONFIRMATION_MESSAGE: t("LABEL_RAZDVAJANJE_CONFIRMATION_MESSAGE"),

      // SIFARNIK SPECIJALIZACIJA
      LABEL_SIFARNIK_SPECIJALIZACIJA: t("LABEL_SIFARNIK_SPECIJALIZACIJA"),
      LABEL_SIFARNIK_SPECIJALIZACIJA_ADD: t("LABEL_SIFARNIK_SPECIJALIZACIJA_ADD"),
      LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE: t("LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE"),
      LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE: t("LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE"),
      LABEL_SIFARNIK_SPECIJALIZACIJA_CREATE: t("LABEL_SIFARNIK_SPECIJALIZACIJA_CREATE"),
      LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE_TITLE: t("LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE_TITLE"),
      LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE_TITLE: t("LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE_TITLE"),
      LABEL_SIFARNIK_SPECIJALIZACIJA_CREATE_SUCCESS_MESSAGE: t("LABEL_SIFARNIK_SPECIJALIZACIJA_CREATE_SUCCESS_MESSAGE"),
      LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE_SUCCESS_MESSAGE: t("LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE_SUCCESS_MESSAGE"),
      LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE_SUCCESS_MESSAGE: t("LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE_SUCCESS_MESSAGE"),
      LABEL_SPECIJALIZACIJE_LIST: t("LABEL_SPECIJALIZACIJE_LIST"),
      LABEL_RADNIK_SPECIJALIZACIJE_LIST: t("LABEL_RADNIK_SPECIJALIZACIJE_LIST"),
      LABEL_SPECIJALIZACIJE_SEARCH: t("LABEL_SPECIJALIZACIJE_SEARCH"),
      LABEL_RADNIK_SPECIJALIZACIJE_SEARCH: t("LABEL_RADNIK_SPECIJALIZACIJE_SEARCH"),
      LABEL_USLUGA_SPECIJALIZACIJE_LIST: t("LABEL_USLUGA_SPECIJALIZACIJE_LIST"),
      LABEL_USLUGA_SPECIJALIZACIJE_SEARCH: t("LABEL_USLUGA_SPECIJALIZACIJE_SEARCH"),

      // PERCENTILNA KRIVA
      LABEL_PERCENTILNA_KRIVA_ODABERITE_UZRAST: t("LABEL_PERCENTILNA_KRIVA_ODABERITE_UZRAST"),
      LABEL_PERCENTILNA_KRIVA_Z_SKOROVI: t("LABEL_PERCENTILNA_KRIVA_Z_SKOROVI"),
      LABEL_PERCENTILNA_KRIVA_PERCENTILI: t("LABEL_PERCENTILNA_KRIVA_PERCENTILI"),
      LABEL_PERCENTILNA_KRIVA_POL_NIJE_UNET: t("LABEL_PERCENTILNA_KRIVA_POL_NIJE_UNET"),
      LABEL_PERCENTILNA_KRIVA_DATUM_STAMPE: t("LABEL_PERCENTILNA_KRIVA_DATUM_STAMPE"),

      // EKSTRERNI ZAKAZIVACI
      LABEL_NADJIMED_VEZE: t("LABEL_NADJIMED_VEZE"),
      LABEL_NADJIMED: t("LABEL_NADJIMED"),
      LABEL_PARTNERI_PLACEHOLDER: t("LABEL_PARTNERI_PLACEHOLDER"),
      LABEL_DOSTUPNI_ZAKAZIVACI: t("LABEL_DOSTUPNI_ZAKAZIVACI"),
      LABEL_EKSTERNI_ZAKAZIVACI_PARTNERI: t("LABEL_EKSTERNI_ZAKAZIVACI_PARTNERI"),
      LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE: t("LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE"),
      LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_POVEZAN: t("LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_POVEZAN"),
      LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_ZATRAZENO: t("LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_ZATRAZENO"),
      LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_CEKA_NA_POTVRDU: t("LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_CEKA_NA_POTVRDU"),
      LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_NEPOVEZAN: t("LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_NEPOVEZAN"),
      LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_CONNECT: t("LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_CONNECT"),
      LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_DISCONNECT: t("LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_DISCONNECT"),
      LABEL_EKSTERNI_ZAKAZIVACI_DA_LI_STE_SIGURNI_RAZVEZIVANJE: t("LABEL_EKSTERNI_ZAKAZIVACI_DA_LI_STE_SIGURNI_RAZVEZIVANJE"),
      LABEL_EKSTERNI_ZAKAZIVACI_DA_LI_STE_SIGURNI_POVEZIVANJE: t("LABEL_EKSTERNI_ZAKAZIVACI_DA_LI_STE_SIGURNI_POVEZIVANJE"),
      LABEL_EKSTERNI_ZAKAZIVACI_NEPOVRATNA_AKCIJA: t("LABEL_EKSTERNI_ZAKAZIVACI_NEPOVRATNA_AKCIJA"),
      LABEL_EKSTERNI_ZAKAZIVACI_USPESNO_POVEZIVANJE: t("LABEL_EKSTERNI_ZAKAZIVACI_USPESNO_POVEZIVANJE"),
      LABEL_EKSTERNI_ZAKAZIVACI_USPESNO_RAZVEZIVANJE: t("LABEL_EKSTERNI_ZAKAZIVACI_USPESNO_RAZVEZIVANJE"),
      LABEL_NAZIV_ZAKAZIVACA: t("LABEL_NAZIV_ZAKAZIVACA"),
      LABEL_NADJIMED_RADNI_PERIODI: t("LABEL_NADJIMED_RADNI_PERIODI"),

      // PRODAJNI PAKETI
      LABEL_PRODAJNI_PAKETI: t("LABEL_PRODAJNI_PAKETI"),
      LABEL_PRODAJNI_PAKET_ADD: t("LABEL_PRODAJNI_PAKET_ADD"),
      LABEL_PRODAJNI_PAKET_SEARCH: t("LABEL_PRODAJNI_PAKET_SEARCH"),
      LABEL_PRODAJNI_PAKETI_CREATE: t("LABEL_PRODAJNI_PAKETI_CREATE"),
      LABEL_PRODAJNI_PAKETI_UPDATE: t("LABEL_PRODAJNI_PAKETI_UPDATE"),
      LABEL_PRODAJNI_PAKETI_DELETE: t("LABEL_PRODAJNI_PAKETI_DELETE"),
      LABEL_PRODAJNI_PAKETI_NAZIV: t("LABEL_PRODAJNI_PAKETI_NAZIV"),
      LABEL_PRODAJNI_PAKETI_CENA: t("LABEL_PRODAJNI_PAKETI_CENA"),
      LABEL_PRODAJNI_PAKETI_STATUS: t("LABEL_PRODAJNI_PAKETI_STATUS"),
      LABEL_PRODAJNI_PAKETI_BROJ_NALOGA: t("LABEL_PRODAJNI_PAKETI_BROJ_NALOGA"),
      LABEL_PRODAJNI_PAKETI_STORAGE_LIMIT: t("LABEL_PRODAJNI_PAKETI_STORAGE_LIMIT"),
      LABEL_PRODAJNI_PAKETI_PERIOD_PLACANJA: t("LABEL_PRODAJNI_PAKETI_PERIOD_PLACANJA"),
      LABEL_PRODAJNI_PAKETI_OPIS: t("LABEL_PRODAJNI_PAKETI_OPIS"),
      LABEL_PRODAJNI_PAKETI_PREPORUCEN: t("LABEL_PRODAJNI_PAKETI_PREPORUCEN"),
      LABEL_PRODAJNI_PAKETI_JAVAN: t("LABEL_PRODAJNI_PAKETI_JAVAN"),
      LABEL_PRODAJNI_PAKETI_MESSAGE_CREATE_SUCCESS: t("LABEL_PRODAJNI_PAKETI_MESSAGE_CREATE_SUCCESS"),
      LABEL_PRODAJNI_PAKETI_MESSAGE_UPDATE_SUCCESS: t("LABEL_PRODAJNI_PAKETI_MESSAGE_UPDATE_SUCCESS"),
      LABEL_PRODAJNI_PAKETI_MESSAGE_DELETE_SUCCESS: t("LABEL_PRODAJNI_PAKETI_MESSAGE_DELETE_SUCCESS"),

      // INICIJALIZACIJA USTANOVE
      LABEL_INICIJALIZACIJA_PODESAVANJE_USTANOVE: t("LABEL_INICIJALIZACIJA_PODESAVANJE_USTANOVE"),
      LABEL_INICIJALIZACIJA_USTANOVA: t("LABEL_INICIJALIZACIJA_USTANOVA"),
      LABEL_INICIJALIZACIJA_ORG_JEDINICE: t("LABEL_INICIJALIZACIJA_ORG_JEDINICE"),
      LABEL_INICIJALIZACIJA_RADNICI: t("LABEL_INICIJALIZACIJA_RADNICI"),
      LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA: t("LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA"),
      LABEL_INICIJALIZACIJA_USLUGE: t("LABEL_INICIJALIZACIJA_USLUGE"),
      LABEL_BUTTON_PRETHODNI: t("LABEL_BUTTON_PRETHODNI"),
      LABEL_BUTTON_SLEDECI: t("LABEL_BUTTON_SLEDECI"),
      LABEL_BUTTON_ZAVRSI: t("LABEL_BUTTON_ZAVRSI"),
      LABEL_INICIJALIZACIJA_DIALOG_TITLE: t("LABEL_INICIJALIZACIJA_DIALOG_TITLE"),
      LABEL_INICIJALIZACIJA_DIALOG_TEXT_1: t("LABEL_INICIJALIZACIJA_DIALOG_TEXT_1"),
      LABEL_INICIJALIZACIJA_DIALOG_TEXT_2: t("LABEL_INICIJALIZACIJA_DIALOG_TEXT_2"),
      LABEL_INICIJALIZACIJA_USTANOVA_TOOLTIP: t("LABEL_INICIJALIZACIJA_USTANOVA_TOOLTIP"),
      LABEL_INICIJALIZACIJA_USTANOVA_SUCCESS_MESSAGE: t("LABEL_INICIJALIZACIJA_USTANOVA_SUCCESS_MESSAGE"),
      LABEL_INICIJALIZACIJA_ORG_JEDINICA_TOOLTIP: t("LABEL_INICIJALIZACIJA_ORG_JEDINICA_TOOLTIP"),
      LABEL_KREIRANE_ORG_JEDINICE: t("LABEL_KREIRANE_ORG_JEDINICE"),
      LABEL_OBRISI_UNOS: t("LABEL_OBRISI_UNOS"),
      LABEL_KREIRAJ: t("LABEL_KREIRAJ"),
      LABEL_INICIJALIZACIJA_ORG_JEDINICA_DELETE_CONFIRM_MESSAGE: t("LABEL_INICIJALIZACIJA_ORG_JEDINICA_DELETE_CONFIRM_MESSAGE"),
      LABEL_INICIJALIZACIJA_ORG_JEDINICA_REQUIRED: t("LABEL_INICIJALIZACIJA_ORG_JEDINICA_REQUIRED"),
      LABEL_SNIMI: t("LABEL_SNIMI"),
      LABEL_INICIJALIZACIJA_RADNICI_TOOLTIP: t("LABEL_INICIJALIZACIJA_RADNICI_TOOLTIP"),
      LABEL_INICIJALIZACIJA_RADNICI_DELETE_CONFIRM_MESSAGE: t("LABEL_INICIJALIZACIJA_RADNICI_DELETE_CONFIRM_MESSAGE"),
      LABEL_KREIRANI_RADNICI: t("LABEL_KREIRANI_RADNICI"),
      LABEL_INICIJALIZACIJA_USLUGA_TOOLTIP: t("LABEL_INICIJALIZACIJA_USLUGA_TOOLTIP"),
      LABEL_INICIJALIZACIJA_USLUGA_DELETE_CONFIRM_MESSAGE: t("LABEL_INICIJALIZACIJA_USLUGA_DELETE_CONFIRM_MESSAGE"),
      LABEL_KREIRANE_USLUGE: t("LABEL_KREIRANE_USLUGE"),
      LABEL_OZNACI_SVE: t("LABEL_OZNACI_SVE"),
      LABEL_UBACI_U: t("LABEL_UBACI_U"),
      LABEL_TIP_PREGLEDA_SAVE_CONFIRMATION_WARNING: t("LABEL_TIP_PREGLEDA_SAVE_CONFIRMATION_WARNING"),
      LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_IN_PROGRESS_TEXT_1: t("LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_IN_PROGRESS_TEXT_1"),
      LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_IN_PROGRESS_TEXT_2: t("LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_IN_PROGRESS_TEXT_2"),
      LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_FINISHED_TEXT_1: t("LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_FINISHED_TEXT_1"),
      LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_FINISHED_TEXT_2: t("LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_FINISHED_TEXT_2"),
      LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_TOOLTIP: t("LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_TOOLTIP"),
      LABEL_TIPOVI_PREGLEDA_INICIJALIZACIJA_UPDATE_SUCCESS_MESSAGE: t("LABEL_TIPOVI_PREGLEDA_INICIJALIZACIJA_UPDATE_SUCCESS_MESSAGE"),
      LABEL_INICIJALIZACIJA_SVE_ORGANIZACIONE_JEDINICE: t("LABEL_INICIJALIZACIJA_SVE_ORGANIZACIONE_JEDINICE"),
      LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_REQUIRED: t("LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_REQUIRED"),
      LABEL_INICIJALIZACIJA_USTANOVE_UNSAVED_CHANGES_WARNING: t("LABEL_INICIJALIZACIJA_USTANOVE_UNSAVED_CHANGES_WARNING"),

      GLOBALNE_DATOTEKE: t("GLOBALNE_DATOTEKE"),
      GLOBALNA_DATOTEKA_UPDATE_SUCCESS: t("GLOBALNA_DATOTEKA_UPDATE_SUCCESS"),
      GLOBALNA_DATOTEKA_CHOOSE: t("GLOBALNA_DATOTEKA_CHOOSE"),
      GLOBALNA_DATOTEKA_DIALOG_HEADER: t("GLOBALNA_DATOTEKA_DIALOG_HEADER"),

      LABEL_TIP_TERMINA: t("LABEL_TIP_TERMINA"),
      // VERZIJE
      VERZIJE: t("VERZIJE"),
      VERZIJA_STAVKE: t("VERZIJA_STAVKE"),
      DATUM_VERZIJE: t("DATUM_VERZIJE"),
      DODAJ_VERZIJU: t("DODAJ_VERZIJU"),
      NOVA_VERZIJA: t("NOVA_VERZIJA"),
      VERZIJA_CREATE_SUCCESS: t("VERZIJA_CREATE_SUCCESS"),
      VERZIJA_UPDATE_SUCCESS: t("VERZIJA_UPDATE_SUCCESS"),
      VERZIJA_DELETE_SUCCESS: t("VERZIJA_DELETE_SUCCESS"),
      VERZIJA_DELETE_CONFIRM_MESSAGE: t("VERZIJA_DELETE_CONFIRM_MESSAGE"),
      VERZIJA_STAVKA_CREATE_SUCCESS: t("VERZIJA_STAVKA_CREATE_SUCCESS"),
      VERZIJA_STAVKA_UPDATE_SUCCESS: t("VERZIJA_STAVKA_UPDATE_SUCCESS"),
      VERZIJA_STAVKA_DELETE_SUCCESS: t("VERZIJA_STAVKA_DELETE_SUCCESS"),
      VERZIJA_STAVKA_DELETE_CONFIRM_MESSAGE: t("VERZIJA_STAVKA_DELETE_CONFIRM_MESSAGE"),
      STATUS_VERZIJE: t("STATUS_VERZIJE"),
      DODAJ_VERZIJA_STAVKU: t("DODAJ_VERZIJA_STAVKU"),
      VERZIJA_STAVKA_OPIS_MANDATORY: t("VERZIJA_STAVKA_OPIS_MANDATORY"),
      VERZIJA_NAZIV_MANDATORY: t("VERZIJA_NAZIV_MANDATORY"),
      ISTORIJA_VERZIJA: t("ISTORIJA_VERZIJA"),

      GRID: t("GRID"),
      LISTA: t("LISTA"),
      TABELA: t("TABELA"),
    };
  }, [t]);

  return { Labels };
}
