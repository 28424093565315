import { BreadCrumb } from "primereact/breadcrumb";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { useLabels } from "../../../Store";
import LicencaLogical from "./LicencaLogical";
import BrojNalogaListView from "./broj-naloga/BrojNalogaListView";
import DodatniModulListView from "./dodatni-modul/DodatniModulListView";
import StorageLimitListView from "./storage-limit/StorageLimitListView";

export default function LicencaView() {
  const { breadCrumbItems, index, setIndex } = LicencaLogical();

  const Labels = useLabels();

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />

        <TabView
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.BROJ_NALOGA}>
            <BrojNalogaListView />
          </TabPanel>
          <TabPanel header={Labels.DODATNI_MODULI}>
            <DodatniModulListView />
          </TabPanel>
          <TabPanel header={Labels.STORAGE_LIMIT}>
            <StorageLimitListView />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
