const EnumZakazanTerminStatus = {
  ZAKAZAN: "ZAKAZAN",
  POTVRDJEN: "POTVRDJEN",
  DOSAO: "DOSAO",
  ZAPOCET: "ZAPOCET",
  ZAVRSEN: "ZAVRSEN",
  REZERVISAN: "REZERVISAN",
  NIJE_DOSAO: "NIJE_DOSAO",
};

export default EnumZakazanTerminStatus;
