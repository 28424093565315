import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import GenerisaniIzvestajController from "../../../controllers/generisani-izvestaj/GenerisaniIzvestajController";
import ZakazanIzvestajController from "../../../controllers/zakazani-izvestaj/ZakazaniIzvestajController";
import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import IzvestajType from "../../../infrastructure/system/IzvestajType";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError } from "../../../infrastructure/system/Utils";
import actions from "../../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/actionsBrisanjeIzvestaja";
import { InitialStateBrisanjeIzvestajaType } from "../../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/initialStateBrisanjeIzvestaja";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import GenerisaniIzvestajParametarReadDto from "../../../model/generisaniIzvestaj/GenerisaniIzvestajParametarReadDto";
import { GenerisaniIzvestajContext } from "../GenerisaniIzvestajLogical";

interface DialogGenerisaniIzvestajLogicalType {
  onDelete: () => void;
  parameters: Array<GenerisaniIzvestajParametarNazivVrednost>;
}

export interface GenerisaniIzvestajParametarNazivVrednost {
  id: number;
  naziv: string;
  vrednost: string;
}

export default function DialogGenerisaniIzvestajLogical(state: InitialStateBrisanjeIzvestajaType): DialogGenerisaniIzvestajLogicalType {
  const { axiosGetGenerisaniIzvestajParameters, axiosDeleteGenerisaniIzvestaj } = GenerisaniIzvestajController();
  const { axiosDeleteZakazaniIzvestaj, axiosGetZakazaniIzvestajParameters } = ZakazanIzvestajController();
  const { showMessage, authData } = useContext(AppContext);
  const { dispatchDialog, fetchData, state: stateGenerisaniIzvestaj, stateZakazani, fetchZakazaniData } = useContext(GenerisaniIzvestajContext);
  const [parameters, setParameters] = useState<Array<GenerisaniIzvestajParametarNazivVrednost>>([]);
  const postLogHighLevel = useLogHighLevel();
  const Labels = useLabels();

  useEffect(() => {
    if (state.izvestaj) {
      const startTime = moment(new Date());
      const getParameters = state.from === IzvestajType.generisani ? axiosGetGenerisaniIzvestajParameters(state.izvestaj.id) : axiosGetZakazaniIzvestajParameters(state.izvestaj.id);
      getParameters
        .then((res: any) => {
          const parameters: Array<GenerisaniIzvestajParametarNazivVrednost> = [];
          res.data.data.forEach((parametar: GenerisaniIzvestajParametarReadDto) => {
            const arr: Array<GenerisaniIzvestajParametarNazivVrednost> = parameters.filter((param: GenerisaniIzvestajParametarNazivVrednost) => param.id === parametar.statistickiIzvestajParametar.id);
            if (parametar.statistickiIzvestajParametar.izvestajParametarTipEnum.sifra === EnumTipKomponente.SET_MULTISELECT) {
              const nazivi = parametar.statistickiIzvestajParametarEnumStavkaList.map((param) => param.naziv).join("#split#");
              parameters.push({
                id: parametar.statistickiIzvestajParametar.id,
                naziv: parametar.statistickiIzvestajParametar.naziv,
                vrednost: nazivi,
              });
            } else if (parametar.statistickiIzvestajParametar.izvestajParametarTipEnum.sifra === EnumTipKomponente.BOOLEAN) {
              parameters.push({
                id: parametar.statistickiIzvestajParametar.id,
                naziv: parametar.statistickiIzvestajParametar.naziv,
                vrednost: parametar.vrednostBoolean ? Labels.YES : Labels.NO,
              });
            } else {
              const vrednost = parametar.vrednostText?.toString() ?? parametar.vrednostNumber?.toString() ?? parametar.vrednostDate ?? parametar.vrednostSet?.naziv ?? undefined;
              if (vrednost === undefined) return;
              else if (arr.length !== 0) {
                arr[0].vrednost = arr[0].vrednost.concat("#split#").concat(parametar.vrednostMultiselect?.naziv);
              } else {
                parameters.push({
                  id: parametar.statistickiIzvestajParametar.id,
                  naziv: parametar.statistickiIzvestajParametar.naziv,
                  vrednost: vrednost,
                });
              }
            }
          });
          setParameters(parameters);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          if (moment(new Date()).diff(startTime) < 500) {
            setTimeout(() => {
              dispatchDialog({ type: actions.SET_LOADED, data: undefined, flag: "", from: "" });
            }, 500);
          } else {
            dispatchDialog({ type: actions.SET_LOADED, data: undefined, flag: "", from: "" });
          }
        });
    }
    // eslint-disable-next-line
  }, [state.izvestaj]);

  const onDelete = () => {
    if (state.from === IzvestajType.generisani) {
      axiosDeleteGenerisaniIzvestaj(state.izvestaj!.id)
        .then(() => {
          fetchData(
            true,
            stateGenerisaniIzvestaj.pagination.page,
            stateGenerisaniIzvestaj.pagination.rows,
            stateGenerisaniIzvestaj.onlyMine ? authData?.currentRadnik.id : undefined,
            stateGenerisaniIzvestaj.selectedStatistickiIzvestaj
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          dispatchDialog({ type: actions.CLOSE_DIALOG, data: undefined, flag: "", from: "" });
        });
    }
    if (state.from === IzvestajType.zakazani) {
      axiosDeleteZakazaniIzvestaj(state.izvestaj!.id)
        .then(() => {
          dispatchDialog({ type: actions.CLOSE_DIALOG, data: undefined, flag: "", from: "" });
          fetchZakazaniData(stateZakazani.onlyMine ? authData?.currentRadnik.id : undefined, stateZakazani.selectedIzvestaj ? stateZakazani.selectedIzvestaj : undefined);
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_ZAKAZAN_IZVESTAJ1 + state?.izvestaj?.statistickiIzvestaj?.naziv + Labels.LOG_HIGH_LEVEL_MESS_DELETE_ZAKAZAN_IZVESTAJ2);
          showMessage(MessageType.SUCCESS, Labels.ZAKAZAN_IZVESTAJ_DELETE);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };
  return {
    onDelete,
    parameters,
  };
}

export type { DialogGenerisaniIzvestajLogicalType };
