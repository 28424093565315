import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { RadioButton } from "primereact/radiobutton";
import { SplitButton } from "primereact/splitbutton";
import { Tooltip } from "primereact/tooltip";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import { RolaService } from "../../../controllers/rola/RolaService";
import EnumTerminTip from "../../../infrastructure/system/EnumTerminTip";
import EnumZakazanTerminStatus from "../../../infrastructure/system/EnumZakazanTerminStatus";
import Images from "../../../infrastructure/system/Images";
import KontrolaPravaPristupaService from "../../../infrastructure/system/KontrolaPravaPristupaService";
import { DATE_FORMAT, TIME_FORMAT, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import useEffectWithoutWarnings from "../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import { KONTAKT_DOKUMENTACIJA_INDEX } from "../../../model/IndeksiTabovaNaStrani";
import TipKontaktaReadDto from "../../../model/kontakt/TipKontaktaReadDto";
import OrganizacionaJedinicaCvorDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaCvorDto";
import ProtokolBrojReadDto from "../../../model/protokol/ProtokolBrojReadDto";
import RadnikSkracenoReadDto from "../../../model/radnik/RadnikSkracenoReadDto";
import ZakazanTerminReadDto from "../../../model/zakazan-termin/ZakazanTerminReadDto";
import ZakazanTerminSimpleDto from "../../../model/zakazan-termin/ZakazanTerminSimpleDto";
import ConfirmComponent from "../../confirm-component/ConfirmComponent";
import DialogComponent from "../../dialog-component/DialogComponent";
import ZakazanTerminStatusi from "../termini/zakazan-termin-statusi/ZakazanTerminStatusi";
import VirtuelnaCekaonicaLogical from "./VirtuelnaCekaonicaLogical";
import PacijentCekaonicaView from "./pacijent/PacijentCekaonicaView";

interface VirtuelnaCekaonicaProps {
  odabranaOrgJed: Array<OrganizacionaJedinicaCvorDto>;
  selectedDate?: moment.Moment;
  selectedRadnik: RadnikSkracenoReadDto;
  radnikList: Array<RadnikSkracenoReadDto>;
  prikaziNeaktivne: boolean;
}

export default function VirtuelnaCekaonicaView(props: VirtuelnaCekaonicaProps) {
  const { odabranaOrgJed, selectedDate, selectedRadnik, radnikList, prikaziNeaktivne } = props;
  const { isLekar, isSestra } = RolaService();
  const {
    tableRows,
    terminList,
    changeStatus,
    zapocniKontakt,
    tipKontaktaList,
    createKontaktDialog,
    closeDialogTipKontakta,
    odabranTipKontakta,
    setOdabranTipKontakta,
    createKontakt,
    selectedColumns,
    setSelectedColumns,
    closeKontaktList,
    deleteTerminList,
    changeDoctorDialog,
    openDialogChangePrimaryDoctor,
    closeDialogChangePrimaryDoctor,
    odabranDoktor,
    setOdabranDoktor,
    changePrimaryDoctor,
    cekaonicaDataLoading,
  } = VirtuelnaCekaonicaLogical({ odabranaOrgJed, selectedDate, selectedRadnik, radnikList, prikaziNeaktivne });
  const dt = useRef<any>(null);
  const Labels = useLabels();
  const navigate = useNavigate();
  const { pristup, authData } = useContext(AppContext);
  const { hasZakazanTerminUpdate, hasZakazanTerminDelete, hasKontaktCreate, hasKontaktRead } = KontrolaPravaPristupaService();

  useEffectWithoutWarnings(() => {
    setSelectedColumns([]);
  }, [selectedDate, selectedRadnik]);

  const renderButton = (label: string, tooltip: string, className: string, icon: string, onClick: () => void, badge: string, readOnly: boolean) => {
    return (
      <Button
        label={label}
        badge={badge !== "0" ? badge : ""}
        badgeClassName="kontakt-details-badge"
        tooltip={tooltip}
        tooltipOptions={tooltipOptionsTop}
        className={" mr-2 " + className}
        icon={"pi " + icon}
        onClick={onClick}
        disabled={!pristup || readOnly}
      />
    );
  };

  const renderButtonZapocniZavrsi = (zakazanTermin: ZakazanTerminReadDto) => {
    switch (zakazanTermin.zakazanTerminStatus.sifra) {
      case EnumZakazanTerminStatus.ZAPOCET:
        return renderButton(
          Labels.CEKAONICA_COLUMN_ZAVRSI_TERMIN,
          Labels.CEKAONICA_COLUMN_ZAVRSI_TERMIN_TOOLTIP,
          "zavrsiTermin",
          "",
          () => changeStatus(zakazanTermin.id, EnumZakazanTerminStatus.ZAVRSEN),
          "",
          false
        );
      case EnumZakazanTerminStatus.DOSAO:
      case EnumZakazanTerminStatus.POTVRDJEN:
      case EnumZakazanTerminStatus.ZAKAZAN:
        return renderButton(Labels.CEKAONICA_COLUMN_ZAPOCNI_TERMIN, Labels.CEKAONICA_COLUMN_ZAPOCNI_TERMIN_TOOLTIP, "zapocniTermin", "", () => zapocniKontakt(zakazanTermin), "", !hasKontaktCreate);
    }
  };

  const renderStatus = (zakazanTermin: ZakazanTerminReadDto) => {
    return (
      <div className="flex align-items-center">
        <ZakazanTerminStatusi termin={zakazanTermin?.zakazanTerminStatus} key={zakazanTermin?.zakazanTerminStatus?.id} />
        {zakazanTermin?.eksterniCaseID && (
          <div className="pl-3">
            <Tooltip target={`.termin-${zakazanTermin.id}`} position="top">
              {Labels.LABEL_NAZIV_ZAKAZIVACA}: {zakazanTermin?.partner?.naziv ?? ""}
            </Tooltip>
            <span className={`pi pi-search-plus text-2xl termin-${zakazanTermin.id}`} style={{ color: "#1199a7" }} />
          </div>
        )}
        {zakazanTermin.zakazanTerminTip.sifra === EnumTerminTip.VANREDAN && (
          <div>
            <Tooltip target=".vanredniTermin" position="top" />
            <Image data-pr-tooltip={Labels.ZAKAZIVANJE_PREGLEDA_VANREDNI_TERMIN} className="vanredniTermin ml-6" alt={Labels.VANREDNI} src={Images.VANREDNI_TERMIN} />
          </div>
        )}
      </div>
    );
  };

  const renderPacijent = (termin: ZakazanTerminReadDto) => {
    return (
      <PacijentCekaonicaView
        pacijent={termin.pacijent}
        napomena={termin.napomena}
        vremeDolaska={termin.vremeDolaska}
        vremeZakazanoOd={termin.vremeZakazanoOd as Date}
        organizacionaJedinica={termin.organizacionaJedinica}
        protokolBroj={termin.kontakt?.protokolBroj}
      />
    );
  };

  const renderTime = (date: Date) => {
    return moment(date).format(TIME_FORMAT);
  };

  const renderDate = (date: Date) => {
    return date ? moment(date).format(DATE_FORMAT) : "";
  };

  const renderProtokolBroj = (protokolBroj: ProtokolBrojReadDto) => {
    return protokolBroj ? protokolBroj.kompozitniPrikaz : "";
  };

  const renderColumn = (props: any, index: number) => {
    return <Column key={index} {...props} />;
  };

  const renderPodaciDropdown = (zakazanTermin: ZakazanTerminReadDto) => {
    const items = [
      {
        label: Labels.NALAZI,
        icon: <Image src={Images.ICON_NALAZI} alt="nalazi ikonica" />,
        command: () => navigate(`/kontakt/${zakazanTermin.kontakt.id}`, { state: { secondRowIndex: KONTAKT_DOKUMENTACIJA_INDEX.UNOSI } }),
      },
      {
        label: Labels.DIJAGNOZE,
        icon: <Image src={Images.ICON_DIJAGNOZE} alt="dijagnoze ikonica" />,
        command: () => navigate(`/kontakt/${zakazanTermin.kontakt.id}`, { state: { secondRowIndex: KONTAKT_DOKUMENTACIJA_INDEX.DIJAGNOZE } }),
      },
      {
        label: Labels.TERAPIJE,
        icon: <Image src={Images.ICON_TERAPIJE} alt="terapije ikonica" />,
        command: () => navigate(`/kontakt/${zakazanTermin.kontakt.id}`, { state: { secondRowIndex: KONTAKT_DOKUMENTACIJA_INDEX.TERAPIJE } }),
      },
      {
        label: Labels.USLUGE,
        icon: <Image src={Images.ICON_USLUGE} alt="usluge ikonica" />,
        command: () => navigate(`/kontakt/${zakazanTermin.kontakt.id}`, { state: { secondRowIndex: KONTAKT_DOKUMENTACIJA_INDEX.USLUGE } }),
      },
      {
        label: Labels.UTROSCI,
        icon: <Image src={Images.ICON_UTROSCI} alt="utrosci ikonica" />,
        command: () => navigate(`/kontakt/${zakazanTermin.kontakt.id}`, { state: { secondRowIndex: KONTAKT_DOKUMENTACIJA_INDEX.UTROSCI } }),
      },
      {
        label: Labels.PAKETI,
        icon: <Image src={Images.ICON_PAKETI} alt="paketi ikonica" />,
        command: () => navigate(`/kontakt/${zakazanTermin.kontakt.id}`, { state: { secondRowIndex: KONTAKT_DOKUMENTACIJA_INDEX.PAKETI } }),
      },
    ];
    return (
      <SplitButton
        label={Labels.PODACI}
        model={items}
        menuClassName="podaci-menu"
        className="p-button-info podaci-btn"
        disabled={!hasKontaktRead && authData?.currentRadnik.id !== zakazanTermin.kontakt.radnikKreirao.id}
      />
    );
  };

  const renderButtons = (zakazanTermin: ZakazanTerminReadDto) => {
    return (
      <div className="flex flex-row">
        {renderButtonZapocniZavrsi(zakazanTermin)}
        {zakazanTermin.kontakt && renderPodaciDropdown(zakazanTermin)}
      </div>
    );
  };

  let dynamicColumns: any = [
    { selectionMode: "multiple", headerClassName: "w-2rem" },
    { field: "redniBroj", header: Labels.CEKAONICA_COLUMN_SVE, headerClassName: "w-2em" },
    { field: "kontakt?.protokolBroj.kompozitniPrikaz", header: Labels.PROTOKOL_BROJ, sortable: true, body: (e: ZakazanTerminReadDto) => renderProtokolBroj(e.kontakt?.protokolBroj) },
    { field: "zakazanTerminStatus", header: Labels.CEKAONICA_COLUMN_STATUS, sortable: true, body: (e: ZakazanTerminReadDto) => renderStatus(e) },
    { field: "vremeZakazanoOd", header: Labels.CEKAONICA_COLUMN_VREME, sortable: true, body: (e: ZakazanTerminReadDto) => renderTime(e.vremeZakazanoOd as Date) },
    { field: "pacijent.ime", header: Labels.CEKAONICA_COLUMN_PACIJENT, sortable: true, body: (e: ZakazanTerminReadDto) => renderPacijent(e) },
    { field: "pacijent.vremeRodjenja", header: Labels.CEKAONICA_COLUMN_DATUM_RODJENJA, sortable: true, body: (e: ZakazanTerminReadDto) => renderDate(e.pacijent.vremeRodjenja! as Date) },
    (isLekar || isSestra) && { body: (e: ZakazanTerminReadDto) => renderButtons(e) },
  ];

  const footerTipKontakta = (
    <div className="layout-pacijent-dosije">
      <Button label={Labels.KONTAKT_ODUSTANI} className="p-button-outlined p-button-tertiary align-self-center" onClick={closeDialogTipKontakta} />
      <Button label={Labels.BUTTON_CREATE} disabled={!pristup} className="p-button-success" onClick={createKontakt} />
    </div>
  );

  const footerPromeniDoktora = (
    <div className="layout-pacijent-dosije">
      <Button label={Labels.KONTAKT_ODUSTANI} className="p-button-outlined p-button-tertiary align-self-center" onClick={closeDialogChangePrimaryDoctor} />
      <Button disabled={!pristup || !odabranDoktor?.id} label={Labels.BUTTON_CHANGE} className="p-button-success" onClick={changePrimaryDoctor} />
    </div>
  );

  const dodatneAkcije = [
    {
      label: Labels.CEKAONICA_BUTTON_ZATVARANJE_KONTAKATA,
      confirmTitle: Labels.DA_LI_STE_SIGURNI_ZATVARANJE_KONTAKATA,
      onClick: closeKontaktList,
      className: "p-button-outlined p-button-primary p-1 mr-2 font-semibold",
    },
  ];
  return (
    <div>
      {selectedColumns?.length > 0 && !selectedColumns?.some((e) => e.zakazanTerminStatus.sifra === EnumZakazanTerminStatus.ZAVRSEN) && (
        <Button
          label={Labels.CEKAONICA_BUTTON_PROMENA_DOKTORA}
          disabled={!pristup || !hasZakazanTerminUpdate}
          className={"p-button-outlined p-button-primary p-1 mr-2 font-semibold"}
          onClick={openDialogChangePrimaryDoctor}
        />
      )}
      {selectedColumns?.some((zt: ZakazanTerminSimpleDto) => zt.kontakt) &&
        !selectedColumns?.some((e) =>
          [EnumZakazanTerminStatus.ZAVRSEN, EnumZakazanTerminStatus.ZAKAZAN, EnumZakazanTerminStatus.POTVRDJEN, EnumZakazanTerminStatus.DOSAO].includes(e.zakazanTerminStatus.sifra)
        ) && (
          <>
            {dodatneAkcije.map(({ label, confirmTitle, icon, className, onClick }: any, i: number) => {
              return confirmTitle ? (
                <ConfirmComponent key={i} disabled={!pristup} buttonLabel={label} buttonClassName={className} confirmTitle={confirmTitle} confirmIcon={icon} onAccept={onClick} />
              ) : (
                <Button disabled={!pristup} key={i} label={label} className={className} onClick={onClick} />
              );
            })}
          </>
        )}
      {selectedColumns?.length > 0 && (
        <ConfirmComponent
          buttonLabel={Labels.BUTTON_DELETE}
          buttonClassName={"p-button-outlined p-button-primary p-1 mr-2 font-semibold"}
          confirmTitle={Labels.CONFIRM_DELETE_ZAKAZAN_TERMIN}
          confirmIcon={"pi pi pi-trash"}
          onAccept={deleteTerminList}
          disabled={!pristup || !hasZakazanTerminDelete}
        />
      )}
      <DataTable
        ref={dt}
        className="mt-0"
        value={terminList.flatMap((termin: ZakazanTerminSimpleDto, i: number) => {
          const pacijent = termin.pacijent;
          return Object.keys(pacijent).some((key) => {
            const vrednost = (pacijent as any)[key];
            return typeof vrednost === "string" || typeof vrednost === "number";
          })
            ? [{ ...termin, redniBroj: `${i + 1}.` }]
            : [];
        })}
        rows={tableRows}
        emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
        selectionMode="checkbox"
        selection={selectedColumns}
        onSelectionChange={(e) => setSelectedColumns(e.value)}
        responsiveLayout="scroll"
        loading={cekaonicaDataLoading}
        rowHover
        stripedRows
      >
        {dynamicColumns.map((column: any, index: number) => renderColumn(column, index))}
      </DataTable>
      <DialogComponent
        header={Labels.LABEL_TIP_KONTAKTA}
        visible={createKontaktDialog}
        onHide={closeDialogTipKontakta}
        footer={footerTipKontakta}
        content={
          <>
            {tipKontaktaList.map((tk: TipKontaktaReadDto) => {
              return (
                <div key={tk.id} className="mt-2">
                  <RadioButton inputId={String(tk.id)} name="category" value={tk} onChange={(e) => setOdabranTipKontakta(e.value)} checked={odabranTipKontakta?.id === tk.id} disabled={false} />
                  <label htmlFor={String(tk.id)} className="cursor-pointer ml-2">
                    {tk.naziv}
                  </label>
                </div>
              );
            })}
          </>
        }
      />
      <DialogComponent
        header={Labels.LABEL_PROMENI_DOKTORA}
        visible={changeDoctorDialog}
        onHide={closeDialogChangePrimaryDoctor}
        footer={footerPromeniDoktora}
        content={
          <>
            {radnikList
              .filter((radnik: RadnikSkracenoReadDto) => radnik.id !== selectedRadnik.id)
              .map((doktor: RadnikSkracenoReadDto) => {
                return (
                  <div key={doktor.id} className="mt-2">
                    <RadioButton
                      inputId={String(doktor.id)}
                      name="doktor"
                      value={doktor}
                      onChange={(e) => {
                        setOdabranDoktor(e.value);
                      }}
                      checked={odabranDoktor?.id === doktor.id}
                    />
                    <label htmlFor={String(doktor.id)} className="cursor-pointer ml-2">
                      {`${doktor.ime} ${doktor.prezime} - ${doktor.username} `}
                    </label>
                  </div>
                );
              })}
          </>
        }
      />
      <ConfirmPopup />
    </div>
  );
}
