import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumPol from "../../../infrastructure/system/EnumPol";
import { tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import actionsAddPatientNotification from "../../../infrastructure/system/hooks/dialog-add-patient-notification/actionsAddPatientNotification";
import EnumTipObavestenja from "../../../model/obavestenja-pacijenti/EnumTipObavestenja";
import ObavestenjaPacijentiListLogical from "./ObavestenjaPacijentiListLogical";
import ObavestenjaPoslataTab from "./ObavestenjaPoslataTab";
import ObavestenjaZaSlanjeTab from "./ObavestenjaZaSlanjeTab";
import DialogObavestenjePacijent from "./dialog/DialogObavestenjePacijent";
import DialogSendObavestenje from "./dialog/DialogSendObavestenje";
import { useLabels } from "../../../Store";

export default function ObavestenjaPacijentiListView() {
  const {
    breadCrumbItems,
    state,
    dispatch,
    invalidFields,
    setInvalidFields,
    onCreate,
    onUpdate,
    onDelete,
    obavestenjaZaSlanjeList,
    obavestenjaZaSlanjeListLoading,
    obavestenjaPoslatoList,
    obavestenjaPoslatoListLoading,
    onSend,
    hasMoreObavestenjaZaSlanje,
    hasMoreObavestenjaPoslato,
    fetchObavestenjaZaSlanje,
    fetchObavestenjaPoslata,
    index,
    setIndex,
    obavestenjeZaSlanjeFilters,
    setObavestenjeZaSlanjeFilters,
    obavestenjePoslatoFilters,
    setObavestenjePoslatoFilters,
    getPatientIncluded,
    mkbDijagnozeList,
    searchMkbDijagnoza,
    sifarnikUslugaList,
    searchSifarnikUsluga,
    fetchObavestenje,
    fetchObavestenjePreSlanja,
    scrollPanelRef,
    isOpenConfirmationDialog,
    setIsOpenConfirmationDialog,
    selectedObavestenje,
  } = ObavestenjaPacijentiListLogical();
  const Labels = useLabels();

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_OBAVESTENJA_TAB_ZA_SLANJE}>
            <div className="block sm:absolute">
              <Button
                icon="pi pi-plus"
                tooltip={Labels.OBAVESTENJE_TITLE_DIALOG_CREATE}
                tooltipOptions={tooltipOptionsTop}
                className="add-button mr-3 mb-3"
                // po defaultu checked email tip
                onClick={() => {
                  dispatch({
                    type: actionsAddPatientNotification.OPEN_DIALOG,
                    operation: EntityOperation.CREATE,
                    obavestenje: {
                      ...state.obavestenje!,
                      obavestenjeTip: { ...state.obavestenjeTypeList[0] },
                      grupnoObavestenjeFilter: {
                        polTrenutniList: [{ sifra: EnumPol.MUSKI }, { sifra: EnumPol.ZENSKI }, { sifra: EnumPol.NIJE_UNET }],
                        dijagnozaList: [],
                        uslugaList: [],
                      },
                    },
                  });
                  dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "polTrenutniSifraList", value: [EnumPol.MUSKI, EnumPol.ZENSKI, EnumPol.NIJE_UNET] });
                  dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "obavestenjeTipSifra", value: EnumTipObavestenja.EMAIL });
                }}
              />
            </div>
            <ObavestenjaZaSlanjeTab
              obavestenjeZaSlanjeFilters={obavestenjeZaSlanjeFilters}
              setObavestenjeZaSlanjeFilters={setObavestenjeZaSlanjeFilters}
              state={state}
              dispatch={dispatch}
              obavestenjaZaSlanjeListLoading={obavestenjaZaSlanjeListLoading}
              obavestenjaZaSlanjeList={obavestenjaZaSlanjeList}
              fetchObavestenjaZaSlanje={fetchObavestenjaZaSlanje}
              hasMoreObavestenjaZaSlanje={hasMoreObavestenjaZaSlanje}
              onDelete={onDelete}
              fetchObavestenje={fetchObavestenje}
              fetchObavestenjePreSlanja={fetchObavestenjePreSlanja}
            />
          </TabPanel>
          <TabPanel header={Labels.LABEL_OBAVESTENJA_TAB_POSLATA}>
            <ObavestenjaPoslataTab
              obavestenjePoslatoFilters={obavestenjePoslatoFilters}
              setObavestenjePoslatoFilters={setObavestenjePoslatoFilters}
              state={state}
              dispatch={dispatch}
              obavestenjaPoslatoListLoading={obavestenjaPoslatoListLoading}
              obavestenjaPoslatoList={obavestenjaPoslatoList}
              fetchObavestenjaPoslata={fetchObavestenjaPoslata}
              hasMoreObavestenjaPoslato={hasMoreObavestenjaPoslato}
              onDelete={onDelete}
              fetchObavestenje={fetchObavestenje}
              fetchObavestenjePreSlanja={fetchObavestenjePreSlanja}
              scrollPanelRef={scrollPanelRef}
            />
          </TabPanel>
        </TabView>
      </Panel>
      <DialogObavestenjePacijent
        state={state}
        dispatch={dispatch}
        onCreate={onCreate}
        onUpdate={onUpdate}
        invalidFields={invalidFields}
        setInvalidFields={setInvalidFields}
        mkbDijagnozeList={mkbDijagnozeList}
        searchMkbDijagnoza={searchMkbDijagnoza}
        sifarnikUslugaList={sifarnikUslugaList}
        searchSifarnikUsluga={searchSifarnikUsluga}
        getPatientIncluded={getPatientIncluded}
      />
      <DialogSendObavestenje obavestenje={selectedObavestenje} isOpenConfirmationDialog={isOpenConfirmationDialog} setIsOpenConfirmationDialog={setIsOpenConfirmationDialog} onSend={onSend} />
    </div>
  );
}
