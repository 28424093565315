import moment from "moment";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../Store";
import { ObavestenjeController } from "../../controllers/obavestenje/ObavestenjeController";
import { DATE_TIME_FORMAT, handleAxiosCallError, handleAxiosCallSuccess } from "../../infrastructure/system/Utils";
import ObavestenjeDatotekaReadDto from "../../model/obavestenje/ObavestenjeDatotekaReadDto";
import ObavestenjeOsnovnoReadDto from "../../model/obavestenje/ObavestenjeOsnovnoReadDto";
import ObavestenjeRadnikCreateDto from "../../model/obavestenje/ObavestenjeRadnikCreateDto";

interface ObavestenjeKorisnikDialogLogicalType {
  changeTab: (tabIndex: number) => void;
  activeIndex: number;
  obavestenjeListProcitano: Array<ObavestenjeOsnovnoReadDto>;
  obavestenjeListNeprocitano: Array<ObavestenjeOsnovnoReadDto>;
  readListLoading: boolean;
  unreadListLoading: boolean;
  renderDateTime: (date: Date) => string;
  readObavestenjeNeprocitano: (obavestenjeId: number) => void;
  readAllObavestenje: () => void;
  neprocitanaObavestenjaCount: number;
  obavestenjeDatotekaListMap: Map<number, Array<ObavestenjeDatotekaReadDto>>;
  readObavestenjeProcitano: (obavestenjeId: number) => void;
  handleDatotekaClick: (datoteka: ObavestenjeDatotekaReadDto) => void;
}

interface ObavestenjeKorisnikDialogLogicalParams {
  obavestenjeListNeprocitano: Array<ObavestenjeOsnovnoReadDto>;
  unreadListLoading: boolean;
  setObavestenjeListNeprocitano: React.Dispatch<React.SetStateAction<Array<ObavestenjeOsnovnoReadDto>>>;
  setUnreadListLoading: React.Dispatch<boolean>;
}

const renderDateTime = (date: Date) => (date ? moment(date).format(DATE_TIME_FORMAT) : "");

export default function ObavestenjeKorisnikDialogLogical({
  obavestenjeListNeprocitano,
  setObavestenjeListNeprocitano,
  unreadListLoading,
  setUnreadListLoading,
}: ObavestenjeKorisnikDialogLogicalParams): ObavestenjeKorisnikDialogLogicalType {
  const NEPROCITANO = 0;
  const PROCITANO = 1;

  const { authData, showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [readListLoading, setReadListLoading] = useState<boolean>(false);
  const { neprocitanaObavestenjaCount, setNeprocitanaObavestenjaCount } = useContext(AppContext);
  const [obavestenjeDatotekaListMap, setObavestenjeDatotekaListMap] = useState<Map<number, Array<ObavestenjeDatotekaReadDto>>>(new Map<number, Array<ObavestenjeDatotekaReadDto>>());
  const [procitanoObavestenjeIdList, setProcitanoObavestenjeIdList] = useState<Array<number>>([]);
  const [obavestenjeListProcitano, setObavestenjeListProcitano] = useState<Array<ObavestenjeOsnovnoReadDto>>([]);
  const { axiosGetObavestenjeReadList, axiosGetObavestenjeUnreadList, axiosReadObavestenje, axiosReadAllObavestenje, axiosGetObavestenjeDatotekaList, axiosGetObavestenjeDatoteka } =
    ObavestenjeController();

  const readObavestenjeNeprocitano = async (obavestenjeId: number) => {
    if (procitanoObavestenjeIdList.indexOf(obavestenjeId) === -1) {
      await axiosGetObavestenjeDatotekaList(obavestenjeId)
        .then(({ data: { data } }: { data: { data: Array<ObavestenjeDatotekaReadDto> } }) => {
          obavestenjeDatotekaListMap.set(obavestenjeId, [...data]);
          setObavestenjeDatotekaListMap(new Map<number, Array<ObavestenjeDatotekaReadDto>>(obavestenjeDatotekaListMap));
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });

      axiosReadObavestenje({ obavestenje: { id: obavestenjeId } })
        .then(() => {
          setProcitanoObavestenjeIdList((procitanoObavestenjeIdListOld) => [...procitanoObavestenjeIdListOld, obavestenjeId]);
          setNeprocitanaObavestenjaCount((neprocitanjaObavestenjaCountOld) => neprocitanjaObavestenjaCountOld - 1);
        })
        .catch((err: any) => {
          handleAxiosCallError(showMessage, err);
        });
    }
  };

  const readObavestenjeProcitano = async (obavestenjeId: number) => {
    if (obavestenjeDatotekaListMap.get(obavestenjeId) === undefined) {
      await axiosGetObavestenjeDatotekaList(obavestenjeId)
        .then(({ data: { data } }: { data: { data: Array<ObavestenjeDatotekaReadDto> } }) => {
          obavestenjeDatotekaListMap.set(obavestenjeId, [...data]);
          setObavestenjeDatotekaListMap(new Map<number, Array<ObavestenjeDatotekaReadDto>>(obavestenjeDatotekaListMap));
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const readAllObavestenje = () => {
    const currentUnreadObavestenje: Array<ObavestenjeRadnikCreateDto> = [];
    obavestenjeListNeprocitano.forEach((obavestenje) => {
      if (procitanoObavestenjeIdList.indexOf(obavestenje.id) === -1) currentUnreadObavestenje.push({ obavestenje: { id: obavestenje.id } });
    });
    if (currentUnreadObavestenje.length > 0) {
      axiosReadAllObavestenje(currentUnreadObavestenje)
        .then(() => {
          setNeprocitanaObavestenjaCount(0);
          setObavestenjeListNeprocitano([]);
          handleAxiosCallSuccess(showMessage, Labels.OBAVESTENJE_SVE_USPESNO_PROCITANO);
        })
        .catch((err: any) => {
          handleAxiosCallError(showMessage, err);
        });
    }
  };

  const changeTab = (tabIndex: number) => {
    setActiveIndex(tabIndex);
    setProcitanoObavestenjeIdList([]);
    if (tabIndex === PROCITANO) {
      setReadListLoading(true);
      axiosGetObavestenjeReadList(authData?.currentRadnik.id!)
        .then(({ data: { data } }: { data: { data: Array<ObavestenjeOsnovnoReadDto> } }) => {
          setObavestenjeListProcitano(data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setReadListLoading(false);
        });
    } else if (tabIndex === NEPROCITANO) {
      setUnreadListLoading(true);
      axiosGetObavestenjeUnreadList(authData?.currentRadnik.id!)
        .then(({ data: { data } }: { data: { data: Array<ObavestenjeOsnovnoReadDto> } }) => {
          setObavestenjeListNeprocitano(data);
          setNeprocitanaObavestenjaCount(data.length);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setUnreadListLoading(false);
        });
    }
  };

  const handleDatotekaClick = (datoteka: ObavestenjeDatotekaReadDto) => {
    axiosGetObavestenjeDatoteka(datoteka.id)
      .then((res) => {
        const link = document.createElement("a");
        const byteChars = atob(res.data.data.sadrzaj);
        const dataArray = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
          dataArray[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(dataArray);
        let doc = new Blob([byteArray], { type: datoteka.tip });
        const url = URL.createObjectURL(doc);
        link.download = datoteka.naziv;
        link.href = url;
        link.click();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {});
  };

  return {
    changeTab,
    activeIndex,
    obavestenjeListProcitano,
    obavestenjeListNeprocitano,
    readListLoading,
    unreadListLoading,
    renderDateTime,
    readObavestenjeNeprocitano,
    readAllObavestenje,
    neprocitanaObavestenjaCount,
    obavestenjeDatotekaListMap,
    readObavestenjeProcitano,
    handleDatotekaClick,
  };
}
