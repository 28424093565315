import classNames from "classnames";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Divider } from "primereact/divider";
import { Image } from "primereact/image";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import { RolaService } from "../../../controllers/rola/RolaService";
import { EnumPodrazumevanaStrana, EnumPodrazumevanaStranaURLMap, pronadjiSifruEnumPodrazumevanaStrana } from "../../../infrastructure/system/EnumPodrazumevanaStrana";
import Images from "../../../infrastructure/system/Images";
import { getImage, isInicijalizacija } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import { PORUKE_INDEX } from "../../../model/IndeksiTabovaNaStrani";
import RadnikUstanovaReadDto from "../../../model/radnik/RadnikUstanovaReadDto";
import ObavestenjeKorisnikDialog from "../../obavestenje/ObavestenjeKorisnikDialogView";
import OutsideClickableComponent from "../../outside-clickable-component/OutsideClickableComponent";
import Radnik from "../../zakazivanje-pregleda/termini/radnik/Radnik";
import AppBreadcrumb from "../breadcrumb/AppBreadcrumb";
import AppTopbarLogical from "./AppTopbarLogical";

interface AppTopbarPropsType {
  routers?: any;
  topbarUserMenuActive: boolean;
  onMenuButtonClick: (event: any) => void;
  onTopbarUserMenu: (event: any) => void;
  setTopbarUserMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function AppTopbar(props: AppTopbarPropsType) {
  const { authData, deleteAuthData } = useContext(AppContext);
  const {
    patchUstanovaPodrazumevanaStrana,
    isDialogObavestenjeVisiable,
    closeDialogObavestenje,
    openDialogObavestenje,
    obavestenjeListNeprocitano,
    setObavestenjeListNeprocitano,
    unreadListLoading,
    setUnreadListLoading,
    neprocitanaObavestenjaCount,
    neprocitanePorukeCount,
  } = AppTopbarLogical();
  const Labels = useLabels();
  const location = useLocation();
  const ustanova = authData?.currentRadnik.ustanovaPoslednjaIzabrana;
  const { isSuperadmin } = RolaService();
  const novaPodrazumevanaStranaSifra = pronadjiSifruEnumPodrazumevanaStrana(location.pathname);
  const izabranaPodrazumevanaStrana =
    location.pathname === EnumPodrazumevanaStranaURLMap[isSuperadmin ? "SUPERADMIN" : ustanova?.podrazumevanaStrana?.sifra ?? ""] ||
    location.pathname === EnumPodrazumevanaStranaURLMap[EnumPodrazumevanaStrana.HOMEPAGE];
  const profileItemClassName = classNames("profile-item", { "active-menuitem fadeInDown": props.topbarUserMenuActive });
  const postLogHighLevel = useLogHighLevel();
  const navigate = useNavigate();

  if (!authData) {
    return null;
  }

  return (
    <div>
      <div className="layout-topbar">
        <div className="topbar-left">
          {(!isInicijalizacija(authData?.currentRadnik!) || isSuperadmin) && (
            <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
              <i className="pi pi-chevron-left"></i>
            </button>
          )}

          <span className="topbar-separator"></span>

          <div className="layout-breadcrumb viewname uppercase">
            <AppBreadcrumb routers={props.routers} />
          </div>
        </div>

        <div className="topbar-right">
          <OutsideClickableComponent
            onClick={() => {
              props.setTopbarUserMenuActive(false);
            }}
          >
            <ul className={`topbar-menu ${isInicijalizacija(authData?.currentRadnik) ? "justify-content-end" : ""}`}>
              {(!isInicijalizacija(authData?.currentRadnik) || isSuperadmin) && (
                <li>
                  <i
                    className="pi pi-envelope mr-2 mt-2 p-text-secondary p-overlay-badge text-2xl envelope-icon"
                    onClick={() => {
                      navigate("/poruke", { state: { tabIndex: PORUKE_INDEX.PORUKE_PRIMLJENE } });
                    }}
                  >
                    {neprocitanePorukeCount > 0 && <Badge value={neprocitanePorukeCount} className="notification-badge" />}
                  </i>
                </li>
              )}
              {(!isInicijalizacija(authData?.currentRadnik) || isSuperadmin) && (
                <li>
                  <i className="pi pi-bell mt-2 p-text-secondary p-overlay-badge text-2xl bell" onClick={openDialogObavestenje}>
                    {neprocitanaObavestenjaCount > 0 && <Badge value={neprocitanaObavestenjaCount} className="notification-badge" />}
                  </i>
                </li>
              )}
              {novaPodrazumevanaStranaSifra && (
                <li>
                  <Button
                    icon={`pi pi-star${izabranaPodrazumevanaStrana ? "-fill" : ""} text-2xl`}
                    className="p-button-rounded p-button-text"
                    disabled={izabranaPodrazumevanaStrana}
                    onClick={() => {
                      confirmDialog({
                        acceptLabel: Labels.BUTTON_YES,
                        rejectLabel: Labels.BUTTON_NO,
                        message: Labels.DA_LI_STE_SIGURNI_PROMENA_PODRAZUMEVANE_STRANE,
                        icon: "pi pi-power-off",
                        accept: () => {
                          patchUstanovaPodrazumevanaStrana(ustanova?.id, novaPodrazumevanaStranaSifra);
                        },
                      });
                    }}
                  />
                </li>
              )}
              <li className={profileItemClassName}>
                <button type="button" className="p-link" data-cy="user-menu-btn" onClick={props.onTopbarUserMenu}>
                  <Radnik
                    slika={getImage(false, authData.currentRadnik.profilnaSlika, undefined)}
                    ime={authData.currentRadnik.ime}
                    prezime={authData.currentRadnik.prezime}
                    titula={authData.currentRadnik.titula}
                  />
                </button>
                <ul className="profile-menu fade-in-up">
                  {authData?.currentRadnik?.radnikUstanovaList?.length > 1 && !isInicijalizacija(authData?.currentRadnik) && (
                    <li className="ustanova-change-wrapper">
                      <div className="flex align-items-center mb-2">
                        <Image src={Images.ICON_USTANOVA} width={"20px"} className="mr-2" />
                        {Labels.USTANOVA_LIST}
                      </div>
                      <div className="pl-4 ustanova-change-wrapper__list">
                        {authData?.currentRadnik?.radnikUstanovaList.map((ustanova: RadnikUstanovaReadDto) => (
                          <span key={ustanova.id} className={`block pl-2 py-2 ${authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.id === ustanova.ustanova.id ? "selected" : ""}`}>
                            {ustanova.ustanova.naziv}
                          </span>
                        ))}
                      </div>
                      <Divider className="divider mt-2" />
                    </li>
                  )}
                  {!isInicijalizacija(authData?.currentRadnik) && (
                    <li>
                      <NavLink
                        to={`/podesavanja`}
                        type="button"
                        className="p-link"
                        onClick={() => {
                          props.setTopbarUserMenuActive(false);
                        }}
                      >
                        <i className="pi pi-cog" />
                        <span>{Labels.LABEL_PODESAVANJA}</span>
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <button
                      type="button"
                      className="p-link"
                      data-cy={"user-menu-logout-btn"}
                      onClick={() => {
                        props.setTopbarUserMenuActive(false);
                        confirmDialog({
                          acceptLabel: Labels.BUTTON_LOGOUT,
                          rejectLabel: Labels.BUTTON_CANCEL,
                          message: Labels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED,
                          icon: "pi pi-power-off",
                          accept: () => {
                            postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_LOGOUT_KORISNIKA);
                            deleteAuthData();
                          },
                          className: "cy-confirmLogout",
                        });
                      }}
                    >
                      <i className="pi pi-power-off"></i>
                      <span>{Labels.BUTTON_LOGOUT}</span>
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </OutsideClickableComponent>
        </div>
      </div>
      <div>
        <ObavestenjeKorisnikDialog
          isVisiable={isDialogObavestenjeVisiable}
          closeDialogObavestenje={closeDialogObavestenje}
          obavestenjeListNeprocitano={obavestenjeListNeprocitano}
          setObavestenjeListNeprocitano={setObavestenjeListNeprocitano}
          unreadListLoading={unreadListLoading}
          setUnreadListLoading={setUnreadListLoading}
        />
      </div>
      <ConfirmDialog />
    </div>
  );
}
