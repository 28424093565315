import { Location } from "history";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface RouteLeavingGuardLogicalType {
  visibleDialog: boolean;
  closeDialog: () => void;
  disableLeave: (nextLocation: Location) => boolean;
  confirmLeave: () => void;
}

interface RouteLeavingGuardLogicalPropsType {
  leaveDisabled?: boolean;
}

export default function RouteLeavingGuardLogical(props: RouteLeavingGuardLogicalPropsType): RouteLeavingGuardLogicalType {
  const { leaveDisabled } = props;
  const [nextLocation, setNextLocation] = useState<Location>();
  const [leaveConfirmed, setLeaveConfirmed] = useState(false);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const navigate = useNavigate();

  const disableBrowserReload = (event: Event) => {
    leaveDisabled && event.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", disableBrowserReload);

    return () => {
      window.removeEventListener("beforeunload", disableBrowserReload);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveDisabled]);

  useEffect(() => {
    leaveConfirmed && nextLocation && navigate(nextLocation.pathname, { state: nextLocation.state });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveConfirmed, nextLocation]);

  const disableLeave = (nextLocation: Location) => {
    if (!leaveConfirmed && leaveDisabled) {
      setVisibleDialog(true);
      setNextLocation(nextLocation);
      return false;
    }

    return true;
  };

  const confirmLeave = () => {
    setVisibleDialog(false);
    setLeaveConfirmed(true);
  };

  const closeDialog = () => {
    setVisibleDialog(false);
  };

  return { visibleDialog, closeDialog, disableLeave, confirmLeave };
}
