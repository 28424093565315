import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import React from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import Images from "../../../../infrastructure/system/Images";
import { useLabels } from "../../../../Store";
import { setInvalid } from "../../../../infrastructure/system/Utils";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import { useTableData } from "../../../../infrastructure/system/hooks/tables/useTableData";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import { useSifarnikUslugaList } from "../../../../infrastructure/system/hooks/sifarnici/usluga/useSifarnikUslugaList";
import SifarnikUslugaReadDto from "../../../../model/sifarnik-usluga/SifarnikUslugaReadDto";
import { InitialStateType } from "../../hooks/initialState";
import { ReducerType } from "../../hooks/reducer";
import InvalidFieldsType from "../../../../model/InvalidFieldsType";

interface InicijalizacijaUslugeViewPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerType>;
  invalidFieldsUsluga: InvalidFieldsType | undefined;
  setInvalidFieldsUsluga: React.Dispatch<React.SetStateAction<InvalidFieldsType | undefined>>;
  onSubmitUsluga: () => void;
  onDeleteUsluga: UseMutationResult<AxiosResponse, unknown, number, unknown>;
}

export default function InicijalizacijaUslugeView({ state, dispatch, invalidFieldsUsluga, setInvalidFieldsUsluga, onSubmitUsluga, onDeleteUsluga }: InicijalizacijaUslugeViewPropsType) {
  const Labels = useLabels();
  const { first, tableRows, onPage } = useTableData();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const { sifarnikUslugaList, isLoading } = useSifarnikUslugaList();

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.LABEL_NAZIV, filter: false, sortrable: false },
    { columnName: Labels.LABEL_SIFRA, filter: false, sortrable: false },
  ];

  const crudBodyTemplate = (rowUsluga: SifarnikUslugaReadDto) => {
    return (
      <div className="flex justify-content-end">
        {(state?.isUslugaEditing ? rowUsluga.id === state?.uslugaChange?.id : true) && (
          <Button
            className={`${state?.isUslugaEditing && rowUsluga.id === state?.uslugaChange?.id ? "p-button-primary" : "p-button-secondary"} mr-2`}
            icon="pi pi-pencil"
            rounded
            text
            onClick={() => {
              if (!state?.orgJedinicaIsEditing) {
                dispatch({ type: "set_usluga", data: rowUsluga });
                dispatch({ type: "set_is_editing_usluga", data: true });
              }
            }}
          />
        )}
        {!state?.isUslugaEditing && (
          <Button
            className="p-button-secondary"
            icon="pi pi-trash"
            rounded
            text
            onClick={() =>
              confirmDialog({
                acceptLabel: Labels.LABEL_DELETE,
                acceptIcon: "pi pi-trash",
                rejectLabel: Labels.BUTTON_CANCEL,
                rejectClassName: "p-button-outlined",
                rejectIcon: "pi pi-times",
                header: Labels.SIFARNIK_USLUGA_TITLE_DIALOG_DELETE,
                message: <span>{Labels.LABEL_INICIJALIZACIJA_USLUGA_DELETE_CONFIRM_MESSAGE}</span>,
                accept: () => onDeleteUsluga.mutate(rowUsluga.id),
              })
            }
          />
        )}
      </div>
    );
  };

  return (
    <div className="inicijalizacija-usluge-layout flex flex-column xl:flex-row p-6">
      <div className="col-12 xl:col-6 p-0">
        <div className="font-bold text-2xl mb-6 flex align-items-center">
          {Labels.USLUGA}
          <Image src={Images.ICON_BULB} className="ml-4 mt-1 bulb" data-pr-tooltip={Labels.LABEL_INICIJALIZACIJA_USLUGA_TOOLTIP} />
          <Tooltip target=".bulb" />
        </div>
        <div className="col-12 sm:col-12 md:col-11 lg:col-11 xl:col-10 pl-0">
          <div className="grid align-items-center p-0">
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>
                {Labels.LABEL_NAZIV}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.uslugaChange?.naziv ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_usluga", data: { field: "naziv", value: e.target.value } });
                }}
                onBlur={() => {
                  setInvalid(setInvalidFieldsUsluga, "naziv", state?.uslugaChange?.naziv);
                }}
                className={invalidFieldsUsluga?.naziv ? "p-invalid" : ""}
              />
              {invalidFieldsUsluga?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>
                {Labels.LABEL_SIFRA}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.uslugaChange?.sifra ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_usluga", data: { field: "sifra", value: e.target.value } });
                }}
                onBlur={() => {
                  setInvalid(setInvalidFieldsUsluga, "sifra", state?.uslugaChange?.sifra);
                }}
                className={invalidFieldsUsluga?.sifra ? "p-invalid" : ""}
              />
              {invalidFieldsUsluga?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
            <div className="flex justify-content-end w-full pt-5">
              <div className="w-max">
                <Button
                  label={state?.isUslugaEditing ? Labels.BUTTON_CANCEL : Labels.LABEL_OBRISI_UNOS}
                  icon={state?.isUslugaEditing ? "pi pi-times" : "pi pi-trash"}
                  className="mr-5"
                  outlined
                  onClick={() => {
                    dispatch({ type: "set_is_editing_usluga", data: false });
                    dispatch({ type: "set_usluga", data: undefined });
                    setInvalidFieldsUsluga(undefined);
                  }}
                />

                <Button label={state?.isUslugaEditing ? Labels.BUTTON_UPDATE : Labels.LABEL_KREIRAJ} icon="pi pi-save" onClick={onSubmitUsluga} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 xl:col-6 p-0">
        <div className="font-bold text-2xl mb-6 flex align-items-center">{Labels.LABEL_KREIRANE_USLUGE}</div>
        {isLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={false} isVisibleButtonList />
        ) : (
          <DataTable
            value={sifarnikUslugaList}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            className="bg-white"
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={onPage}
          >
            <Column field="naziv" header={Labels.LABEL_NAZIV} />
            <Column field="sifra" header={Labels.LABEL_SIFRA} />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </div>
    </div>
  );
}
