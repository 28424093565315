import moment from "moment";
import { TerminType } from "../../termin/Termin";

interface RadniDanLogicalType {
  satnicaList: Array<TerminType>;
  showNowLine: boolean;
  nowPocetakRadaDiff: number;
  radniDanDuration: number;
}

export default function RadniDanLogical(
  radniDanPocetak: moment.Moment,
  radniDanKraj: moment.Moment,
  zakazaniTerminList: Array<TerminType>,
  radniPeriodList: Array<TerminType>,
  trajanjeTermina: number,
  vertical: boolean,
  makeSatnicaList: (
    zakazaniTerminList: TerminType[],
    radniDanPocetak: moment.Moment,
    radniDanKraj: moment.Moment,
    radniPeriodList: TerminType[],
    trajanjeTermina: number,
    vertical: boolean
  ) => TerminType[]
): RadniDanLogicalType {
  const satnicaList = makeSatnicaList(zakazaniTerminList, radniDanPocetak, radniDanKraj, radniPeriodList, trajanjeTermina, vertical);
  const trenutnoVreme = moment().startOf("minute");
  const nowPocetakRadaDiff = moment.duration(trenutnoVreme.diff(radniDanPocetak)).asMinutes();
  const radniDanDuration = radniDanPocetak.diff(radniDanKraj, "minutes");
  const showNowLine = moment().isSame(radniDanPocetak, "day") && moment().isAfter(radniDanPocetak) && moment().isBefore(radniDanKraj);

  return { satnicaList, showNowLine, nowPocetakRadaDiff, radniDanDuration };
}
