import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { useContext, useEffect } from "react";
import { useLabels } from "../../../Store";
import Endpoint from "../../../infrastructure/system/Endpoint";
import EnumPeriodGenerisanjaSifra from "../../../infrastructure/system/EnumPeriodGenerisanjaSifra";
import EnumPonavljanjeGenerisanjaSifra from "../../../infrastructure/system/EnumPonavljanjeGenerisanjaSifra";
import { TIME_FORMAT, formatDate } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import actionsBrisanjeIzvestaja from "../../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/actionsBrisanjeIzvestaja";
import useFetch from "../../../infrastructure/system/hooks/useFetch";
import actionsZakazaniIzvestaj from "../../../infrastructure/system/hooks/zakazani-izvestaj-reducer/actionsZakazaniIzvestaj";
import GenerisaniIzvestajSmallReadDto from "../../../model/generisaniIzvestaj/GenerisaniIzvestajSmallReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import ZakazanIzvestajReadDto from "../../../model/zakazaniIzvestaj/ZakazanIzvestajReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import { GenerisaniIzvestajContext } from "../GenerisaniIzvestajLogical";
import ZakazaniIzvestajListLogical from "./ZakazaniIzvestajListLogical";

export default function ZakazaniIzvestajList(props: { radniDaniList: Array<EnumBaseReadDto> }) {
  const { radniDaniList } = props;
  const { stateZakazani, dispatchZakazani, dispatchDialog, getZakazaniIzvestajParams } = useContext(GenerisaniIzvestajContext);
  const { paginate, tablePagination, setTablePagination } = ZakazaniIzvestajListLogical();

  const Labels = useLabels();

  const { data } = useFetch<any>(Endpoint.ZAKAZANI_IZVESTAJ_LIST);

  useEffect(() => {
    if (data) {
      dispatchZakazani({ type: actionsZakazaniIzvestaj.SET_IZVESTAJ_LIST, data: data });
    }
    // eslint-disable-next-line
  }, [data]);

  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const columnsProperty: Array<SkeletonTableColumnProperty> = [{ columnName: Labels.GEN_IZVESTAJ_VREME }, { columnName: Labels.GEN_IZVESTAJ_NAZIV }, { columnName: Labels.GEN_IZVESTAJ_AKCIJE }];

  const infoDeleteButtons = (rowGenerisaniIzvestaj: GenerisaniIzvestajSmallReadDto) => {
    return (
      <div className="flex justify-content-end hc-gap-2 m-2">
        {!stateZakazani.editSelected && (
          <>
            <Button
              type="button"
              tooltip={Labels.GEN_IZVESTAJ_TITLE_DIALOG_DETALJI}
              className="p-button-info"
              icon="pi pi-info"
              onClick={() => {
                dispatchDialog({ type: actionsBrisanjeIzvestaja.OPEN_DIALOG, data: rowGenerisaniIzvestaj, flag: "info", from: "zakazani" });
              }}
            />
            <Button
              type="button"
              tooltip={Labels.IZVESTAJ_TITLE_DIALOG_UPDATE}
              className="p-button-warning"
              icon="pi pi-pencil"
              onClick={() => {
                getZakazaniIzvestajParams(rowGenerisaniIzvestaj);
              }}
            />
            <Button
              type="button"
              tooltip={Labels.GEN_IZVESTAJ_TITLE_DIALOG_DELETE}
              className="p-button-danger"
              icon="pi pi-trash"
              onClick={() => {
                dispatchDialog({ type: actionsBrisanjeIzvestaja.OPEN_DIALOG, data: rowGenerisaniIzvestaj, flag: "delete", from: "zakazani" });
                setTablePagination((prev: any) => ({ ...prev, first: 0, page: 1, rows: 10 }));
              }}
            />
          </>
        )}
        {stateZakazani.editSelected && stateZakazani?.selectedIzvestajForEdit?.id === rowGenerisaniIzvestaj.id && <Button className="p-button-secondary p-button-text" icon="pi pi-pencil" disabled />}
      </div>
    );
  };

  const ponavljanjeBody = (izvestaj: ZakazanIzvestajReadDto) => (
    <>
      {izvestaj?.ponavljanje?.naziv}{" "}
      {izvestaj?.ponavljanje?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO &&
        (izvestaj?.period?.sifra === EnumPeriodGenerisanjaSifra.DAN
          ? Labels.LABEL_NA_NIVOU_DANA
          : izvestaj?.period?.sifra === EnumPeriodGenerisanjaSifra.NEDELJA
          ? Labels.LABEL_NA_NIVOU_NEDELJE
          : izvestaj?.period?.sifra === EnumPeriodGenerisanjaSifra.MESEC
          ? Labels.LABEL_NA_NIVOU_MESECA
          : "")}
    </>
  );

  const nazivBody = (izvestaj: ZakazanIzvestajReadDto) => <>{izvestaj?.statistickiIzvestaj?.naziv}</>;

  const emailBody = (izvestaj: ZakazanIzvestajReadDto) => <>{izvestaj?.zakazanIzvestajEmailList?.join(", ")}</>;
  const izvestajSlatiBody = (izvestaj: ZakazanIzvestajReadDto) => (
    <>
      {izvestaj?.period?.sifra === EnumPeriodGenerisanjaSifra.DAN || izvestaj?.ponavljanje?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO
        ? "-"
        : izvestaj?.period?.sifra === EnumPeriodGenerisanjaSifra.NEDELJA
        ? radniDaniList
            .filter((dan: EnumBaseReadDto) => izvestaj?.danNedelja?.split(", ").includes(dan.sifra))
            .map((dan: any) => dan.naziv)
            .join(", ")
        : izvestaj?.danMesec}
    </>
  );

  const vremeBody = (izvestaj: ZakazanIzvestajReadDto) => (
    <>
      {izvestaj.ponavljanje.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO
        ? formatDate(izvestaj.vremeZakazanoJednostruko, TIME_FORMAT)
        : formatDate(izvestaj.vremeZakazanoVisestruko, TIME_FORMAT)}
    </>
  );

  return (
    <>
      {stateZakazani.izvestajListLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} isVisibleButtonList defaultButtonCount={2} />
      ) : (
        <DataTable
          value={paginate(stateZakazani.izvestajList, tablePagination.rows, tablePagination.page)}
          rowHover
          stripedRows
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          lazy
          breakpoint="1260px"
          paginator
          paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
          first={tablePagination.first}
          rows={tablePagination.rows}
          totalRecords={stateZakazani.izvestajList.length}
          onPage={(e) => {
            setTablePagination((prev: any) => ({
              ...prev,
              page: e.page! + 1,
              rows: e.rows,
              first: e.first,
            }));
          }}
        >
          <Column header={Labels.PERIOD_PONAVLJANJA} headerClassName="font-bold" body={ponavljanjeBody} />
          <Column header={Labels.IZVESTAJ_SLATI} body={izvestajSlatiBody} />
          <Column header={Labels.VREME} body={vremeBody} />
          <Column header={Labels.GEN_IZVESTAJ_NAZIV} body={nazivBody} />
          <Column header={Labels.GEN_IZVESTAJ_EMAIL} body={emailBody} />
          <Column className="col-2" body={infoDeleteButtons} />
        </DataTable>
      )}
    </>
  );
}
