import axios from "axios";
import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { EnumController } from "../../../../controllers/enum/EnumController";
import ProtokolControler from "../../../../controllers/protokol/ProtokolController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../../model/SearchBaseDto";
import ProtokolReadDto from "../../../../model/protokol/ProtokolReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";

interface ProtokolListLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  protokolLoading: boolean;
  protokolList: Array<ProtokolReadDto>;
  first: number;
  tableRows: number;
  onPageProtokol: (rows: number, first: number) => void;
  protokolSearchData: SearchBaseDto;
  changeProtokolSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchData: () => void;
  searchProtokolByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  vremenskiOkvirList: Array<EnumBaseReadDto>;
}

export default function ProtokolListLogical(): ProtokolListLogicalType {
  const Labels = useLabels();
  const { showMessage } = useContext(AppContext);

  const [protokolLoading, setProtokolLoading] = useState<boolean>(false);
  const [protokolList, setProtokolList] = useState<Array<ProtokolReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [protokolSearchData, setProtokolSearchData] = useState<SearchBaseDto>({});
  const [vremenskiOkvirList, setVremenskiOkvirList] = useState<Array<EnumBaseReadDto>>([]);

  const { axiosSearchProtokol } = ProtokolControler();
  const { axiosGetEnumVremenskiOkvirList } = EnumController();

  const breadCrumbItems = [
    {
      label: Labels.PROTOKOL_LIST,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const requestProtokolList = axiosSearchProtokol(protokolSearchData);
    const requestVremenskiOkvirList = axiosGetEnumVremenskiOkvirList();
    const startTime = moment();
    setProtokolLoading(true);
    axios
      .all([requestProtokolList, requestVremenskiOkvirList])
      .then(
        axios.spread((responseProtokolList: any, responseEnumVremenskiOkvirList: any) => {
          setProtokolList(responseProtokolList.data.data);
          setVremenskiOkvirList(responseEnumVremenskiOkvirList.data.data);
          setFirst(0);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setProtokolLoading, startTime);
      });
  };

  const changeProtokolSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setProtokolSearchData({
      ...protokolSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchProtokolByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageProtokol = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    breadCrumbItems,
    protokolLoading,
    protokolList,
    first,
    tableRows,
    onPageProtokol,
    protokolSearchData,
    fetchData,
    changeProtokolSearchData,
    searchProtokolByEnter,
    vremenskiOkvirList,
  };
}

export type { ProtokolListLogicalType };
