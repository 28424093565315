import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { numberFormat, tableDateFilter, tableDateFilterGreater, tableDateFilterLess } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import UstanovaPlacanjeReadDto from "../../../../model/placanje/UstanovaPlacanjeReadDto";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import CalendarFilter from "../../calendar-component/CalendarFilter";
import PlacanjeUgovorDialogView from "./PlacanjeUgovorDialogView";
import UstanovaPlacanjeLogical from "./UstanovaplacanjeListLogical";

interface UstanovaPlacanjeViewProps {
  ustanovaId?: number;
  ustanova?: UstanovaReadDto;
}

export default function UstanovaPlacanjeList(props: UstanovaPlacanjeViewProps) {
  const { ustanovaId, ustanova } = props;
  const { placanjaLoading, first, tableRows, placanjeList, placanjaSuperadminLoading, placanjeListSuperadmin, openPlacanje, previewPlacanjaUgovorDialog, isPreviewOpen, closePreview, renderDateTime } =
    UstanovaPlacanjeLogical({
      ustanovaId: ustanovaId,
      ustanovaNaziv: ustanova?.naziv,
    });
  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.OBRACUNSKI_PERIOD_OD, filter: true, sortrable: true },
    { columnName: Labels.OBRACUNSKI_PERIOD_DO, filter: true, sortrable: true },
    { columnName: Labels.DATUM_PLACANJA, filter: true, sortrable: true },
    { columnName: Labels.OBRACUNATI_IZNOS, filter: true, sortrable: true },
    { columnName: Labels.UPLACEN_IZNOS, filter: true, sortrable: true },
    { columnName: Labels.NAPOMENA, filter: true, sortrable: true },
  ];

  const datumOdBody = (rowObavestenje: UstanovaPlacanjeReadDto) => {
    return <div>{renderDateTime(new Date(rowObavestenje.obracunskiPeriodOd))}</div>;
  };
  const datumDoBody = (rowObavestenje: UstanovaPlacanjeReadDto) => {
    return <div>{renderDateTime(new Date(rowObavestenje.obracunskiPeriodDo))}</div>;
  };
  const dateFilterTemplate = (options: any) => {
    return <CalendarFilter options={options} />;
  };

  const crudBodyTemplate = (rowUloga: UstanovaPlacanjeReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          onClick={() => rowUloga && openPlacanje(EntityOperation.UPDATE, rowUloga.id)}
          type="button"
          tooltip={Labels.PLACANJE_TITLE_DIALOG_UPDATE}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
        />
        <Button
          type="button"
          tooltip={Labels.PLACANJE_TITLE_DIALOG_DELETE}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowUloga && openPlacanje(EntityOperation.DELETE, rowUloga.id)}
        />
      </div>
    );
  };
  const obracunatIznosBody = (rowPlacanje: UstanovaPlacanjeReadDto) => {
    let obracunatIznosString: Array<String> = String(rowPlacanje.obracunatiIznos).split(" ");
    return <div>{numberFormat(Number(obracunatIznosString[0]), 2, 2) + " " + obracunatIznosString[1]}</div>;
  };
  const uplacenIznosBody = (rowPlacanje: UstanovaPlacanjeReadDto) => {
    let uplaceniIznosString: Array<String> = String(rowPlacanje.uplacenIznos).split(" ");
    return <div>{numberFormat(Number(uplaceniIznosString[0]), 2, 2) + " " + uplaceniIznosString[1]}</div>;
  };
  return (
    <div className="layout-crud-generic-content">
      {ustanovaId === undefined ? (
        <div>
          {placanjaLoading ? (
            <SkeletonTable dataTableColumnsProperty={columnsProperty} />
          ) : (
            <div>
              <DataTable
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                rowHover
                stripedRows
                filterDisplay="row"
                paginator
                first={first}
                paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
                rows={tableRows}
                value={placanjeList}
              >
                <Column
                  header={Labels.OBRACUNSKI_PERIOD_OD}
                  headerClassName="header-column-bold"
                  field={"obracunskiPeriodOd"}
                  body={datumOdBody}
                  filter
                  filterElement={dateFilterTemplate}
                  filterMatchMode="custom"
                  filterFunction={tableDateFilterLess}
                  sortable
                  showFilterMenu={false}
                />
                <Column
                  header={Labels.OBRACUNSKI_PERIOD_DO}
                  headerClassName="header-column-bold"
                  field={"obracunskiPeriodDo"}
                  body={datumDoBody}
                  filter
                  filterElement={dateFilterTemplate}
                  filterMatchMode="custom"
                  filterFunction={tableDateFilterGreater}
                  sortable
                  showFilterMenu={false}
                />
                <Column
                  header={Labels.DATUM_PLACANJA}
                  sortable
                  filter
                  filterMatchMode="custom"
                  filterElement={dateFilterTemplate}
                  filterFunction={tableDateFilter}
                  showFilterMenu={false}
                  field={"datumPlacanja"}
                />
                <Column header={Labels.OBRACUNATI_IZNOS} sortable filter filterMatchMode="contains" showFilterMenu={false} body={obracunatIznosBody} field={"obracunatiIznos"} />
                <Column header={Labels.UPLACEN_IZNOS} sortable filter filterMatchMode="contains" showFilterMenu={false} body={uplacenIznosBody} field={"uplacenIznos"} />
                <Column header={Labels.NAPOMENA} sortable filter filterMatchMode="contains" showFilterMenu={false} field={"napomena"} />
              </DataTable>
            </div>
          )}
        </div>
      ) : (
        <div>
          {placanjaSuperadminLoading ? (
            <SkeletonTable dataTableColumnsProperty={columnsProperty} isVisibleButtonList defaultButtonCount={2} />
          ) : (
            <div>
              <div className="p-toolbar my-4 p-3">
                <div className="p-toolbar-group-left">
                  <Button
                    className="p-button-success mr-2 orange"
                    tooltip={Labels.ADD_PLACANJE_TITLE_DIALOG_CREATE}
                    onClick={() => {
                      openPlacanje(EntityOperation.CREATE);
                    }}
                  >
                    <span className="p-button-text">{Labels.BUTTON_DODAJ_UPLATU}</span>
                  </Button>
                  <Button
                    className="p-button-success orange"
                    tooltip={Labels.ADD_PLACANJE_ZA_UGOVOR_TITLE_DIALOG_CREATE}
                    onClick={() => {
                      previewPlacanjaUgovorDialog();
                    }}
                  >
                    <span className="p-button-text">{Labels.BUTTON_DODAJ_UPLATU_ZA_UGOVOR}</span>
                  </Button>
                </div>
              </div>
              <DataTable
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                rowHover
                stripedRows
                filterDisplay="row"
                paginator
                first={first}
                paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
                rows={tableRows}
                value={placanjeListSuperadmin}
              >
                <Column
                  header={Labels.OBRACUNSKI_PERIOD_OD}
                  headerClassName="header-column-bold"
                  field={"obracunskiPeriodOd"}
                  body={datumOdBody}
                  filter
                  filterElement={dateFilterTemplate}
                  filterMatchMode="custom"
                  filterFunction={tableDateFilterLess}
                  sortable
                  showFilterMenu={false}
                />
                <Column
                  header={Labels.OBRACUNSKI_PERIOD_DO}
                  headerClassName="header-column-bold"
                  field={"obracunskiPeriodDo"}
                  body={datumDoBody}
                  filter
                  filterElement={dateFilterTemplate}
                  filterMatchMode="custom"
                  filterFunction={tableDateFilterGreater}
                  sortable
                  showFilterMenu={false}
                />
                <Column
                  header={Labels.DATUM_PLACANJA}
                  sortable
                  filter
                  filterMatchMode="custom"
                  filterElement={dateFilterTemplate}
                  filterFunction={tableDateFilter}
                  showFilterMenu={false}
                  field={"datumPlacanja"}
                />
                <Column header={Labels.OBRACUNATI_IZNOS} sortable filter filterMatchMode="contains" body={obracunatIznosBody} showFilterMenu={false} field={"obracunatiIznos"} />
                <Column header={Labels.UPLACEN_IZNOS} sortable filter filterMatchMode="contains" showFilterMenu={false} body={uplacenIznosBody} field={"uplacenIznos"} />
                <Column header={Labels.NAPOMENA} sortable filter filterMatchMode="contains" showFilterMenu={false} field={"napomena"} />
                <Column body={crudBodyTemplate} />
              </DataTable>
            </div>
          )}
          <PlacanjeUgovorDialogView isPreviewOpen={isPreviewOpen} closePreview={closePreview} ustanovaId={ustanovaId} />
        </div>
      )}
    </div>
  );
}
