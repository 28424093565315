import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import UstanovaPlacanjeParamsDto from "../../model/UstanovaPlacanjeParamsDto";
import PlacanjeTrajanjeUgovoraReadDto from "../../model/placanje/PlacanjeTrajanjeUgovoraReadDto";
import UstanovaPlacanjeCreateDto from "../../model/placanje/UstanovaPlacanjeCreateDto";
import UstanovaPlacanjeUpdateDto from "../../model/placanje/UstanovaPlacanjeUpdateDto";

interface PlacanjeControllerType {
  axiosGetAllPlacanjeList: () => Promise<AxiosResponse<any>>;
  axiosGetPlacanjeListByUstanovaID: (ustanovaID: number) => Promise<AxiosResponse<any>>;
  axiosGetObracunatIznos: (ustanovaPlacanjeData: UstanovaPlacanjeParamsDto) => Promise<AxiosResponse<any>>;
  axiosCreatePlacanje: (ustanovaPlacanje: UstanovaPlacanjeCreateDto, ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdatePlacanje: (ustanovaPlacanje: UstanovaPlacanjeUpdateDto, ustanovaId: number, placanjeID: number) => Promise<AxiosResponse<any>>;
  axiosGetPlacanjeByID: (placanjeID: number, ustanovaID: number) => Promise<AxiosResponse<any>>;
  axiosDeletePlacanje: (placanjeID: number, ustanovaID: number) => Promise<AxiosResponse<any>>;
  axiosCreatePlacanjeUgovor: (placanjeUgovor: PlacanjeTrajanjeUgovoraReadDto) => Promise<AxiosResponse<any>>;
}

export default function PlacanjeController(): PlacanjeControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetAllPlacanjeList = (placanjeSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.PLACANJE_LIST, axiosConfig(authData!.token, placanjeSearchData));
  };

  const axiosGetPlacanjeListByUstanovaID = (ustanovaID: number) => {
    return axios.get(`${Endpoint.PLACANJE_LIST_BY_USTANOVA}/${ustanovaID}`, axiosConfig(authData!.token));
  };

  const axiosGetObracunatIznos = (ustanovaPlacanjeData: UstanovaPlacanjeParamsDto) => {
    let datumOd = ustanovaPlacanjeData.datumOd.replace(/['"]+/g, "");
    let datumDo = ustanovaPlacanjeData.datumDo.replace(/['"]+/g, "");
    return axios.get(`${Endpoint.PLACANJE_OBRACUNATI_IZNOS}?ustanovaID=${ustanovaPlacanjeData.ustanovaID}&datumOd=${datumOd}&datumDo=${datumDo}`, axiosConfig(authData!.token));
  };

  const axiosCreatePlacanjeUgovor = (placanjeUgovor: PlacanjeTrajanjeUgovoraReadDto) => {
    return axios.post(`${Endpoint.PLACANJE_TRAJANJE_UGOVORA}`, placanjeUgovor, axiosConfig(authData!.token));
  };

  const axiosCreatePlacanje = (ustanovaPlacanje: UstanovaPlacanjeCreateDto, ustanovaId: number) => {
    return axios.post(`${Endpoint.PLACANJE_LIST_BY_USTANOVA}/${ustanovaId}`, ustanovaPlacanje, axiosConfig(authData!.token));
  };

  const axiosUpdatePlacanje = (ustanovaPlacanje: UstanovaPlacanjeCreateDto, ustanovaId: number, placanjeID: number) => {
    return axios.put(`${Endpoint.PLACANJE_LIST}/${placanjeID}/ustanova/${ustanovaId}`, ustanovaPlacanje, axiosConfig(authData!.token));
  };

  const axiosGetPlacanjeByID = (placanjeID: number, ustanovaID: number) => {
    return axios.get(`${Endpoint.PLACANJE_LIST}/${placanjeID}/ustanova/${ustanovaID}`, axiosConfig(authData!.token));
  };

  const axiosDeletePlacanje = (placanjeID: number, ustanovaID: number) => {
    return axios.delete(`${Endpoint.PLACANJE_LIST}/${placanjeID}/ustanova/${ustanovaID}`, axiosConfig(authData!.token));
  };

  return {
    axiosGetAllPlacanjeList,
    axiosGetPlacanjeListByUstanovaID,
    axiosGetObracunatIznos,
    axiosCreatePlacanje,
    axiosUpdatePlacanje,
    axiosGetPlacanjeByID,
    axiosDeletePlacanje,
    axiosCreatePlacanjeUgovor,
  };
}
