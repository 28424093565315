import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import GrupnoObavestenjeCreateDto from "../../../../model/obavestenja-pacijenti/GrupnoObavestenjeCreateDto";
import { GrupnoObavestenjeReadDto } from "../../../../model/obavestenja-pacijenti/GrupnoObavestenjeReadDto";
import PacijentIncludedSearchDto from "../../../../model/pacijent/PacijentIncludedSearchDto";
import GrupnoObavestenjeSimpleReadDto from "../../../../model/obavestenja-pacijenti/GrupnoObavestenjeSimpleReadDto";

export interface InitialStateAddPatientNotificationType {
  isDialogOpen: boolean;
  operation: string;
  obavestenjeTypeList: Array<EnumBaseReadDto>;
  obavestenjeTypeListLoading: boolean;
  obavestenje: GrupnoObavestenjeCreateDto | GrupnoObavestenjeSimpleReadDto | GrupnoObavestenjeReadDto | any;
  obavestenjeLoading: boolean;
  polList: Array<EnumBaseReadDto> | undefined;
  polListLoading: boolean;
  patientsIncludedLoading: boolean;
  patientsIncludedSearch: PacijentIncludedSearchDto | undefined | any,
}

const InitialStateAddPatientNotification: InitialStateAddPatientNotificationType = {
  isDialogOpen: false,
  operation: "",
  obavestenjeTypeList: [],
  obavestenjeTypeListLoading: false,
  obavestenje: undefined,
  obavestenjeLoading: false,
  polList: [],
  polListLoading: false,
  patientsIncludedLoading: false,
  patientsIncludedSearch: undefined,
};

export default InitialStateAddPatientNotification;