import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { useContext, useEffect, useMemo } from "react";
import { AppContext, useLabels } from "../../../Store";
import Endpoint from "../../../infrastructure/system/Endpoint";
import { DATE_TIME_FORMAT, formatDate } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import actionsBrisanjeIzvestaja from "../../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/actionsBrisanjeIzvestaja";
import actions from "../../../infrastructure/system/hooks/generisani-izvestaj-reducer/actionsGenerisaniIzvestaj";
import useFetch from "../../../infrastructure/system/hooks/useFetch";
import GenerisaniIzvestajSearchDto from "../../../model/generisaniIzvestaj/GenerisaniIzvesajSearchDto";
import GenerisaniIzvestajSmallReadDto from "../../../model/generisaniIzvestaj/GenerisaniIzvestajSmallReadDto";
import StatistickiIzvestajFajlFormatReadDto from "../../../model/izvestaj/StatistickiIzvestajFajlFormatReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import { GenerisaniIzvestajContext } from "../GenerisaniIzvestajLogical";

export default function GenerisaniIzvestajList() {
  const { state, dispatch, fetchData, onExport, dispatchDialog } = useContext(GenerisaniIzvestajContext);
  const { authData } = useContext(AppContext);

  const Labels = useLabels();

  const initSearchData: GenerisaniIzvestajSearchDto = useMemo(() => {
    return { page: state.pagination.page, size: state.pagination.rows };
    // eslint-disable-next-line
  }, []);

  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const { data } = useFetch<any>(Endpoint.GENERISANI_IZVESTAJ_SEARCH, initSearchData);
  const { data: dataCnt } = useFetch<number>(Endpoint.GENERISANI_IZVESTAJ_SEARCH_COUNT);

  useEffect(() => {
    if (data) {
      dispatch({ type: actions.SET_IZVESTAJ_LIST, value: data });
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (dataCnt) {
      dispatch({ type: actions.SET_TOTAL_COUNT, value: dataCnt });
    }
    // eslint-disable-next-line
  }, [dataCnt]);

  const columnsProperty: Array<SkeletonTableColumnProperty> = [{ columnName: Labels.GEN_IZVESTAJ_VREME }, { columnName: Labels.GEN_IZVESTAJ_NAZIV }, { columnName: Labels.GEN_IZVESTAJ_AKCIJE }];

  const infoDeleteButtons = (rowGenerisaniIzvestaj: GenerisaniIzvestajSmallReadDto) => {
    return (
      <div className="flex justify-content-end hc-gap-2 m-2">
        <Button
          type="button"
          tooltip={Labels.GEN_IZVESTAJ_TITLE_DIALOG_DETALJI}
          className="p-button-warning"
          icon="pi pi-info"
          onClick={() => {
            dispatchDialog({ type: actionsBrisanjeIzvestaja.OPEN_DIALOG, data: rowGenerisaniIzvestaj, flag: "info", from: "generisani" });
          }}
        />
        <Button
          type="button"
          tooltip={Labels.GEN_IZVESTAJ_TITLE_DIALOG_DELETE}
          className="p-button-danger"
          icon="pi pi-trash"
          onClick={() => {
            dispatchDialog({ type: actionsBrisanjeIzvestaja.OPEN_DIALOG, data: rowGenerisaniIzvestaj, flag: "delete", from: "generisani" });
          }}
        />
      </div>
    );
  };

  const actionButtons = (generisaniIzvestaj: GenerisaniIzvestajSmallReadDto) => (
    <div className="flex flex-wrap hc-gap-2 m-2">
      {generisaniIzvestaj.statistickiIzvestaj.statistickiIzvestajFajlFormatList.map((statIzvestajFajlFormat: StatistickiIzvestajFajlFormatReadDto) => (
        <Button
          key={statIzvestajFajlFormat.fajlFormatEnum.id}
          className="w-4rem h-2rem justify-content-center"
          onClick={() => {
            onExport(statIzvestajFajlFormat.fajlFormatEnum.naziv, generisaniIzvestaj);
          }}
        >
          {statIzvestajFajlFormat.fajlFormatEnum.naziv}
        </Button>
      ))}
    </div>
  );

  const dateBody = (generisaniIzvestaj: GenerisaniIzvestajSmallReadDto) => <>{formatDate(generisaniIzvestaj.vremeKreiranja, DATE_TIME_FORMAT)}</>;

  const onPage = (e: DataTableStateEvent) => {
    fetchData(false, e.page!, e.rows, state.onlyMine ? authData?.currentRadnik.id : undefined, state.selectedStatistickiIzvestaj);
  };

  return (
    <>
      {state.izvestajListLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} isVisibleButtonList defaultButtonCount={2} />
      ) : (
        <DataTable
          value={state.izvestajList}
          rowHover
          stripedRows
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          paginator
          first={state.pagination.page * state.pagination.rows}
          paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
          rows={state.pagination.rows}
          lazy
          totalRecords={state.totalCount}
          onPage={onPage}
          breakpoint="500px"
        >
          <Column header={Labels.GEN_IZVESTAJ_VREME} headerClassName="font-bold" body={dateBody} />
          <Column header={Labels.GEN_IZVESTAJ_NAZIV} field={"naslov"} />
          <Column header={Labels.GEN_IZVESTAJ_AKCIJE} body={actionButtons} />
          <Column className="col-2" body={infoDeleteButtons} />
        </DataTable>
      )}
    </>
  );
}
