import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import StorageLimitCreateDto from "../../model/licenca/storage-limit/StorageLimitCreateDto";
import StorageLimitReadDto from "../../model/licenca/storage-limit/StorageLimitReadDto";

interface StorageLimitControllerType {
  axiosSearchStorageLimitList: (searchString?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetStorageLimit: (storageLimitId: number) => Promise<AxiosResponse<any>>;
  axiosCreateStorageLimit: (storageLimit: StorageLimitCreateDto) => Promise<AxiosResponse<any>>;
  axiosUpdateStorageLimit: (storageLimit: StorageLimitReadDto) => Promise<AxiosResponse<any>>;
}

export function StorageLimitController(): StorageLimitControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchStorageLimitList = (searchString?: SearchBaseDto) => {
    return axios.get(Endpoint.STORAGE_LIMIT_SEARCH, axiosConfig(authData!.token, searchString));
  };

  const axiosGetStorageLimit = (storageLimitId: number) => {
    return axios.get(`${Endpoint.STORAGE_LIMIT_LIST}/${storageLimitId}`, axiosConfig(authData!.token));
  };

  const axiosCreateStorageLimit = (storageLimit: StorageLimitCreateDto) => {
    return axios.post(`${Endpoint.STORAGE_LIMIT_LIST}`, storageLimit, axiosConfig(authData!.token));
  };

  const axiosUpdateStorageLimit = (storageLimit: StorageLimitReadDto) => {
    return axios.put(`${Endpoint.STORAGE_LIMIT_LIST}/${storageLimit?.id}`, storageLimit, axiosConfig(authData!.token));
  };

  return {
    axiosSearchStorageLimitList,
    axiosCreateStorageLimit,
    axiosGetStorageLimit,
    axiosUpdateStorageLimit,
  };
}
