import moment from "moment";
import { Button } from "primereact/button";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable, DataTableValueArray } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { Toolbar } from "primereact/toolbar";
import { Dispatch, SetStateAction, useContext, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLabels } from "../../../../Store";
import { DATE_FORMAT, DATE_FORMAT_2, DATE_TIME_FORMAT, formatDate, useEffectOnce } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import { useKontaktPlacenList } from "../../../../infrastructure/system/hooks/enum/useKontaktPlacenList";
import { useOrganizacionaJedinicaList } from "../../../../infrastructure/system/hooks/organizaciona-jedinica/useOrganizacionaJedinicaList";
import { usePacijentSimpleList } from "../../../../infrastructure/system/hooks/pacijent/usePacijentSimpleList";
import EnumStatusPlacanjaKontakt from "../../../../model/EnumStatusPlacanjaKontakt";
import KontaktNaplataReadDto from "../../../../model/naplata/KontaktNaplataReadDto";
import { NaplataKontaktListSearchFilters } from "../../../../model/naplata/NaplataKontaktListSearchFilters";
import NaplataReadDto from "../../../../model/naplata/NaplataReadDto";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import { NaplataContext } from "../../NaplataLogical";
import { useKontaktNaplataList } from "../../hooks/useKontaktNaplataList";
import PlacenRacunDialog from "./PlacenRacunDialog";
import SpisakUsluga from "./SpisakUsluga";

interface SpisakProps {
  openPlacenRacunDialog: (id: number) => void;
  showPlacenRacunDialog: boolean;
  setShowPlacenRacunDialog: Dispatch<SetStateAction<boolean>>;
  racunData: NaplataReadDto | undefined;
  prevStepRef: any;
  getRacuniForKontaktAndPrint: (id: number) => void;
}

export default function Spisak(props: SpisakProps) {
  const { openPlacenRacunDialog, showPlacenRacunDialog, setShowPlacenRacunDialog, racunData, prevStepRef, getRacuniForKontaktAndPrint } = props;
  const { state, dispatch, nacinPlacanjaList, nacinPlacanjaListIsLoading } = useContext(NaplataContext);
  const Labels = useLabels();
  const [selectedRows, setSelectedRows] = useState<DataTableValueArray>([]);
  const location = useLocation();
  const [searchFilters, setSearchFilters] = useState<NaplataKontaktListSearchFilters>({
    kontaktPlacen: EnumStatusPlacanjaKontakt.NEPLACENO,
    datumOd: location?.state?.filter?.datumOd ? moment(location?.state?.filter?.datumOd).format(DATE_FORMAT_2) : moment(new Date()).format(DATE_FORMAT_2),
    organizacionaJedinicaID: location?.state?.filter?.organizacionaJedinicaID,
    pacijentID: location?.state?.filter?.pacijentID,
    naplativ: true,
  });
  const datumOdRef = useRef<Calendar>(null);
  const datumDoRef = useRef<Calendar>(null);
  const dt = useRef<DataTable<KontaktNaplataReadDto[]>>(null);

  const { kontaktList, isLoading, onPage, totalRecords, first, size } = useKontaktNaplataList(searchFilters);
  const { pacijentList } = usePacijentSimpleList();
  const { organizacionaJedinicaList } = useOrganizacionaJedinicaList();
  const { kontaktPlacenList } = useKontaktPlacenList();

  useEffectOnce(() => {
    prevStepRef.current = 0;
  });

  const searchToolbar = () => {
    return (
      <>
        <div className="flex flex-row flex-wrap justify-content-start search-wrapper">
          <div className="flex flex-column align-items-start my-2 mr-3">
            <div className="font-bold mb-2">{Labels.LABEL_RACUN_NAPLATIV_NAPLATA}</div>
            <div className="flex align-items-center h-full">
              <InputSwitch checked={searchFilters.naplativ} onChange={(e: InputSwitchChangeEvent) => setSearchFilters({ ...searchFilters, naplativ: e.value ?? false })} />
            </div>
          </div>
          <div className="flex flex-column align-items-start my-2 mr-3 w-2">
            <div className="font-bold mb-2">{Labels.LABEL_STATUS_RACUNA_NAPLATA}</div>
            <Dropdown
              optionLabel="naziv"
              optionValue="sifra"
              options={kontaktPlacenList}
              value={searchFilters?.kontaktPlacen}
              onChange={(e) => {
                setSearchFilters({ ...searchFilters, kontaktPlacen: e.value });
              }}
              emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              className="w-full"
            />
          </div>
          <div className="flex flex-column align-items-start my-2 mr-3 w-2">
            <div className="font-bold mb-2">{Labels.LABEL_AMBULANTA_NAPLATA}</div>
            <Dropdown
              optionLabel="naziv"
              optionValue="id"
              options={organizacionaJedinicaList}
              value={searchFilters.organizacionaJedinicaID}
              onChange={(e) => {
                setSearchFilters({ ...searchFilters, organizacionaJedinicaID: e.value });
              }}
              emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              className="w-full"
              showClear
            />
          </div>
          <div className="flex flex-column align-items-start my-2 mr-3 w-2">
            <div className="font-bold mb-2">{Labels.LABEL_PACIJENT_NAPLATA}</div>
            <div className="flex w-full">
              <Dropdown
                optionLabel="ime"
                optionValue="id"
                options={pacijentList.map((pacijent) => ({ id: pacijent.id, ime: pacijent.ime, prezime: pacijent.prezime, vremeRodjenja: moment(pacijent.vremeRodjenja).format(DATE_FORMAT) }))}
                value={searchFilters.pacijentID}
                onChange={(e) => {
                  setSearchFilters({ ...searchFilters, pacijentID: e.value });
                }}
                emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                className="w-full border-noround-right"
                itemTemplate={(e) => `${e.ime + " " + e.prezime}, ${e.vremeRodjenja}`}
                valueTemplate={(e) => `${e?.ime + " " + e?.prezime}, ${e?.vremeRodjenja}`}
                showClear
                filter
                filterBy="ime,prezime,vremeRodjenja"
              />
            </div>
          </div>
          <div className="flex flex-column align-items-start my-2 mr-3 w-4">
            <div className="font-bold mb-2">{Labels.LABEL_PREGLED_REALIZOVAN_NAPLATA}</div>
            <div className="flex w-12">
              <div className="flex w-6">
                <CalendarComponent
                  ref={datumOdRef}
                  value={searchFilters.datumOd}
                  onChange={(e: CalendarChangeEvent) => {
                    if (e.value as Date) {
                      setSearchFilters({ ...searchFilters, datumOd: moment(e.value as Date).format(DATE_FORMAT_2) });
                      datumOdRef?.current?.hide();
                    }
                  }}
                  className="mr-2"
                  placeholder={Labels.RADNI_PERIOD_COLUMN_DATUM_OD}
                  onClearButtonClick={() => setSearchFilters({ ...searchFilters, datumOd: undefined })}
                />
              </div>
              <div className="flex w-6">
                <CalendarComponent
                  ref={datumDoRef}
                  value={searchFilters.datumDo}
                  onChange={(e: CalendarChangeEvent) => {
                    if (e.value as Date) {
                      setSearchFilters({ ...searchFilters, datumDo: moment(e.value as Date).format(DATE_FORMAT_2) });
                      datumDoRef?.current?.hide();
                    }
                  }}
                  className="ml-2"
                  placeholder={Labels.RADNI_PERIOD_COLUMN_DATUM_DO}
                  onClearButtonClick={() => setSearchFilters({ ...searchFilters, datumDo: undefined })}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const rowExpansionTemplate = (rowKontakt: KontaktNaplataReadDto) => {
    return <SpisakUsluga spisak={[...rowKontakt.kontaktProizvodList, ...rowKontakt.kontaktUslugaList]} />;
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_FULLNAME, filter: false, sortrable: false, headerClassName: "font-bold" },
    { columnName: Labels.LABEL_BROJ_PROTOKOLA_NAPLATA, filter: true, sortrable: true },
    { columnName: Labels.LABEL_LEKAR_NAPLATA, filter: true, sortrable: true },
    { columnName: Labels.LABEL_TIP_PREGLEDA_NAPLATA, filter: true, sortrable: true },
    { columnName: Labels.LABEL_PREGLED_REALIZOVAN_NAPLATA, filter: true, sortrable: true },
  ];

  const datumBodyTemplate = (rowData: KontaktNaplataReadDto) => {
    return formatDate(rowData.vremeOd as Date, DATE_TIME_FORMAT);
  };

  const naplacenInfoBody = (rowData: KontaktNaplataReadDto) => {
    return (
      <div className={`${!rowData.placeno ? "hidden" : "flex align-items-center stampa-racuna gap-3"}`}>
        <Button icon="pi pi-print stampa-racuna-icon" text onClick={() => getRacuniForKontaktAndPrint(rowData.id)} />
        <Button
          icon="pi pi-info-circle"
          className={`p-button-rounded p-button-text p-button-lg`}
          onClick={() => {
            openPlacenRacunDialog(rowData.id);
          }}
        />
      </div>
    );
  };

  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  return (
    <div className="layout-generic-content-list naplata-spisak-table px-4">
      <Toolbar start={searchToolbar} className="border-0 p-0 mb-5" />
      {!nacinPlacanjaListIsLoading && nacinPlacanjaList.length === 0 && (
        <div className="nacin-placanja-message">
          <i className="pi pi-exclamation-triangle mr-2"></i>
          {Labels.LABEL_NACINI_PLACANJA_REQUIRED}
        </div>
      )}
      {isLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} />
      ) : (
        <DataTable
          ref={dt}
          value={state.selectedColumns.length === 0 ? kontaktList : kontaktList?.filter((kontakt) => kontakt.pacijent.id === state.selectedColumns[0].pacijent.id)}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          rowHover
          stripedRows={false}
          showGridlines={false}
          expandedRows={selectedRows}
          onRowToggle={(e) => {
            setSelectedRows(e.data as DataTableValueArray);
          }}
          rowExpansionTemplate={rowExpansionTemplate}
          collapsedRowIcon="pi pi-chevron-down"
          expandedRowIcon="pi pi-chevron-up"
          className="spisakTable"
          scrollable
          onRowClick={(e) => {
            setSelectedRows((prev) => (prev.some((elem) => elem === e.data) ? prev.filter((elem) => elem !== e.data) : [...prev, e.data]));
          }}
          selectionMode="checkbox"
          selection={state.selectedColumns}
          onSelectionChange={(e) => dispatch({ type: "set_selected_columns", data: e.value })}
          lazy
          paginator
          paginatorTemplate={rowsPerPageDropdownTemplateMedium}
          totalRecords={state.selectedColumns.length === 0 ? totalRecords : kontaktList?.filter((kontakt) => kontakt.pacijent.id === state.selectedColumns[0].pacijent.id).length}
          first={first}
          rows={size}
          onPage={onPage}
          showSelectionElement={(rowData) => (!rowData.placeno && searchFilters.naplativ ? true : null)}
          onRowUnselect={(e) => {
            setSelectedRows((prev) => (prev.some((elem) => elem === e.data) ? prev.filter((elem) => elem !== e.data) : [...prev, e.data]));
          }}>
          <Column selectionMode="multiple" className="max-w-4rem" />
          <Column body={(rowData) => `${rowData.pacijent?.ime} ${rowData.pacijent?.prezime}`} headerClassName="font-bold" header={Labels.HEADER_FULLNAME} filter={false} showFilterMenu={false} />
          <Column field={"protokolBroj.kompozitniPrikaz"} headerClassName="font-bold" header={Labels.LABEL_BROJ_PROTOKOLA_NAPLATA} filter={false} showFilterMenu={false} />
          <Column
            body={(rowData) => (rowData.primarniDoktor ? `${rowData.primarniDoktor?.ime} ${rowData.primarniDoktor?.prezime}` : "")}
            headerClassName="font-bold"
            header={Labels.LABEL_LEKAR_NAPLATA}
            filter={false}
            showFilterMenu={false}
          />
          <Column field={"kontaktTip.naziv"} headerClassName="font-bold" header={Labels.LABEL_TIP_PREGLEDA_NAPLATA} filter={false} showFilterMenu={false} />
          <Column body={datumBodyTemplate} headerClassName="font-bold" header={Labels.LABEL_PREGLED_REALIZOVAN_NAPLATA} filter={false} showFilterMenu={false} />
          <Column
            body={naplacenInfoBody}
            align="center"
            headerClassName="font-bold"
            filter={false}
            showFilterMenu={false}
            bodyStyle={{ maxWidth: "5%" }}
            headerStyle={{ maxWidth: "5%" }}
            style={{ maxWidth: "5%" }}
            resizeable
          />
          <Column expander={true} align="center" style={{ maxWidth: "5%" }} />
        </DataTable>
      )}
      <PlacenRacunDialog racunData={racunData} showPlacenRacunDialog={showPlacenRacunDialog} setShowPlacenRacunDialog={setShowPlacenRacunDialog} />
    </div>
  );
}
