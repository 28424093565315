import moment from "moment";
import { CalendarChangeEvent } from "primereact/calendar";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { KeyboardEvent, useContext, useEffect, useReducer, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../Store";
import { EnumController } from "../../controllers/enum/EnumController";
import { OrganizacionaJedinicaController } from "../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { PacijentController } from "../../controllers/pacijent/PacijentController";
import { SifarnikController } from "../../controllers/sifarnik/SifarnikController";
import EnumPol from "../../infrastructure/system/EnumPol";
import MessageType from "../../infrastructure/system/MessageType";
import {
  DATE_FORMAT,
  DATE_FORMAT_2,
  WIDTH_FOR_MOBILE_DEVICE,
  checkEmpty,
  formatDate,
  formatDate2,
  handleAxiosCallError,
  handleScreenSizeChange,
  isEmailFormatValid,
  isTelefonValid,
  skeletonTimeout,
  useEffectOnce,
  validateStringEmpty,
} from "../../infrastructure/system/Utils";
import actionsAddPacijent from "../../infrastructure/system/hooks/dialog-add-pacijent-reducer/actionsAddPacijent";
import initialStateAddPacijent, { InitialStateAddPacijentType } from "../../infrastructure/system/hooks/dialog-add-pacijent-reducer/initialStateAddPacijent";
import reducerAddPacijent from "../../infrastructure/system/hooks/dialog-add-pacijent-reducer/reducerAddPacijent";
import useLogHighLevel from "../../infrastructure/system/hooks/useLogHighLevel";
import EnumTipObavestenja from "../../model/obavestenja-pacijenti/EnumTipObavestenja";
import OrganizacionaJedinicaReadDto from "../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import PacijentReadDto from "../../model/pacijent/PacijentReadDto";
import PacijentSearchDto from "../../model/pacijent/PacijentSearchDto";
import EnumBaseReadDto from "../../model/sifarnik/EnumBaseReadDto";
import SifarnikMkbDto from "../../model/sifarnik/SifarnikMkbDto";

interface PretragaPacijenataLogicalType {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  orgJedinicaList: Array<OrganizacionaJedinicaReadDto>;
  showResults: boolean;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTableColumns: Array<any>;
  setSelectedtableColumns: React.Dispatch<React.SetStateAction<Array<any>>>;
  tableColumns: Array<any>;
  onColumnToggle: (event: MultiSelectChangeEvent) => void;
  first: number;
  setFirst: any;
  tableRows: number;
  onPageFormTable: (rows: number, first: number, page?: number) => void;
  patientList: Array<PacijentReadDto>;
  searchPacijent: (searchObject: PacijentSearchDto) => void;
  searchPatientObject: PacijentSearchDto;
  setSearchPatientObject: (pacijentSearch: PacijentSearchDto) => void;
  searchPacijentByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  onChangeCalendar: (e: CalendarChangeEvent) => void;
  searchPatientLoading: boolean;
  orgJedListLoading: boolean;
  searchSifarnikComplete: (event: { query: string }) => void;
  onChangeAutoComplete: (e: any) => void;
  sifarnikSuggestionsList: Array<any>;
  selectedDijagnoze: Array<any>;
  dijagnozaAutocompleteLoading: boolean;
  mainDivRef: any;
  pacijentCount: number;
  state: InitialStateAddPacijentType;
  dispatch: React.Dispatch<{ type: actionsAddPacijent }>;
  polList: Array<EnumBaseReadDto> | undefined;
  obavestenjeTipList: Array<EnumBaseReadDto> | undefined;
  onCreate: () => void;
  openDialog: () => void;
  searchRef: React.MutableRefObject<HTMLInputElement | null>;
  pacijentExists: boolean;
  setPacijentExists: React.Dispatch<React.SetStateAction<boolean>>;
  warningMessageRef: React.RefObject<HTMLDivElement>;
  checkPacijentExists: () => void;
  datumOdRef: HTMLDivElement | any;
  datumDoRef: HTMLDivElement | any;
}

export default function PretragaPacijenataLogical(): PretragaPacijenataLogicalType {
  const Labels = useLabels();
  const [checked, setChecked] = useState<boolean>(false);
  const mainDivRef: any = useRef();
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [sifarnikSuggestionsList, setSifarnikSuggestionsList] = useState<Array<any>>([]);
  const [orgJedinicaList, setOrgJedinicaList] = useState<Array<OrganizacionaJedinicaReadDto>>([]);
  const [selectedTableColumns, setSelectedtableColumns] = useState<Array<any>>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const { axiosPacijentSearch, axiosPacijentCount, axiosCheckPacijentExists } = PacijentController();
  const { axiosSearchOrganizacionaJedinica } = OrganizacionaJedinicaController();
  const searchRef = useRef<HTMLInputElement>(null);
  const datumOdRef = useRef<HTMLDivElement | any>(null);
  const datumDoRef = useRef<HTMLDivElement | any>(null);

  // TODO : Ovo ne sme ovako... usisacemo sve dijagnoze (ima ih 35k)
  const { axiosSearchSifarnikMKB } = SifarnikController();
  const { axiosGetPolList, axiosGetObavestenjeTipList } = EnumController();
  const { axiosCreatePacijent } = PacijentController();
  const [patientList, setPatientList] = useState<Array<PacijentReadDto>>([]);
  const [pacijentCount, setPacijentCount] = useState<number>(0);
  const [searchPatientObject, setSearchPatientObject] = useState<PacijentSearchDto>({ page: 0, size: 10 });
  const [searchPatientLoading, setSearchPatientLoading] = useState<boolean>(true);
  const [orgJedListLoading, setOrgJedListLoading] = useState<boolean>(true);
  const [selectedDijagnoze, setSelectedDijagnoze] = useState<Array<any>>([]);
  const [dijagnozaAutocompleteLoading, setDijagnozaAutocompleteLoading] = useState<boolean>(false);
  const [dijagnozeWithoutNaziv, setDijagnozeWithoutNaziv] = useState<boolean>(false);
  const { showMessage } = useContext(AppContext);
  const tableColumns: any = [
    { field: "vremeRodjenja", header: Labels.HEADER_DOB },
    { field: "polTrenutni", header: Labels.HEADER_GENDER },
  ];
  const [state, dispatch] = useReducer(reducerAddPacijent, initialStateAddPacijent);
  const [polList, setPolList] = useState<Array<EnumBaseReadDto> | undefined>();
  const [obavestenjeTipList, setObavestenjeTipList] = useState<Array<EnumBaseReadDto> | undefined>();
  const [pacijentExists, setPacijentExists] = useState<boolean>(false);
  const navigate = useNavigate();
  const postLogHighLevel = useLogHighLevel();

  const warningMessageRef = useRef<HTMLDivElement>(null);

  window.addEventListener("resize", () => {
    setDijagnozeWithoutNaziv(!handleScreenSizeChange(mainDivRef, WIDTH_FOR_MOBILE_DEVICE));
  });

  useEffect(() => {
    if (showResults) searchPacijent(searchPatientObject);
    // eslint-disable-next-line
  }, [searchPatientObject]);

  useEffectOnce(() => {
    searchRef.current?.focus();
  });

  useEffect(() => {
    warningMessageRef.current?.scrollIntoView();
  }, [pacijentExists]);

  useEffect(() => {
    checkPacijentExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pacijent?.polTrenutni, state.pacijent?.vremeRodjenja]);

  const checkPacijentExists = () => {
    if (state.pacijent?.ime && state.pacijent.prezime && state.pacijent.polTrenutni && state.pacijent.vremeRodjenja) {
      axiosCheckPacijentExists({
        ime: state.pacijent.ime,
        prezime: state.pacijent.prezime,
        polTrenutniSifra: state.pacijent.polTrenutni.sifra,
        datumRodjenja: formatDate2(state.pacijent.vremeRodjenja),
      })
        .then((res: any) => {
          setPacijentExists(res.data.data.pacijentExists);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns: any = tableColumns.filter((col: any) => selectedColumns?.some((sCol: any) => sCol.field === col.field));
    setSelectedtableColumns(orderedSelectedColumns);
  };

  const parsePatient = (patientList: Array<PacijentReadDto>) => {
    let newPatientList: Array<any> = [];
    patientList.forEach((patient: PacijentReadDto) => {
      let patientObj = {
        ...patient,
        ip: patient?.ime + " " + patient?.prezime,
        polTrenutni: patient?.polTrenutni
          ? patient.polTrenutni?.sifra === EnumPol.MUSKI
            ? Labels.LABEL_MALE
            : patient.polTrenutni?.sifra === EnumPol.ZENSKI
            ? Labels.LABEL_FEMALE
            : Labels.SLASH
          : Labels.SLASH,
        vremeRodjenja: patient?.vremeRodjenja ? moment(patient?.vremeRodjenja).format(DATE_FORMAT) : Labels.SLASH,
      };
      newPatientList.push(patientObj);
    });
    return newPatientList;
  };

  const searchPacijent = (searchObject: PacijentSearchDto) => {
    let startTime = moment(new Date());
    setSearchPatientLoading(true);
    axiosPacijentCount(searchPatientObject)
      .then((res) => {
        setPacijentCount(res.data.data);
      })
      .catch((error: any) => handleAxiosCallError(showMessage, error));
    axiosPacijentSearch(searchObject)
      .then((response: any) => {
        setPatientList(parsePatient(response.data.data));
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSearchPatientLoading, startTime);
      });
  };

  const searchPacijentByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      searchPacijent(searchPatientObject);
      setShowResults(true);
    }
  };

  const searchOrganizacionaJedinica = () => {
    let startTime = moment(new Date());
    setOrgJedListLoading(true);
    axiosSearchOrganizacionaJedinica()
      .then((response: any) => {
        setOrgJedinicaList(response.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setOrgJedListLoading, startTime);
      });
  };

  const getPunNaziv = (dijagnoza: SifarnikMkbDto) => {
    if (dijagnozeWithoutNaziv) {
      return dijagnoza.sifra;
    } else {
      return `${dijagnoza.sifra} - ${dijagnoza.nazivLatinski.substring(0, 100)}${dijagnoza.nazivLatinski.length > 100 ? "..." : ""}`;
    }
  };

  const searchDijagnoza = (searchString: string) => {
    setDijagnozaAutocompleteLoading(true);
    axiosSearchSifarnikMKB({ searchString: searchString })
      .then((response: any) => {
        setSifarnikSuggestionsList(
          response.data.data.map((dijagnoza: SifarnikMkbDto) => ({
            ...dijagnoza,
            punNaziv: getPunNaziv(dijagnoza),
          }))
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setDijagnozaAutocompleteLoading(false);
      });
  };

  useEffectOnce(() => {
    setDijagnozeWithoutNaziv(!handleScreenSizeChange(mainDivRef, WIDTH_FOR_MOBILE_DEVICE));
    setSelectedtableColumns(tableColumns);
    searchOrganizacionaJedinica();
  });

  const onPageFormTable = (rows: number, first: number, page?: number) => {
    setTableRows(rows);
    setFirst(first);
    setSearchPatientObject({ ...searchPatientObject, page: page, size: rows });
  };

  const searchSifarnikComplete = (event: { query: string }) => {
    if (event.query.length >= 3) {
      setDijagnozaAutocompleteLoading(true);
      setTimeout(() => {
        searchDijagnoza(event.query);
      }, 250);
    }
  };

  const onChangeAutoComplete = (e: any) => {
    setSelectedDijagnoze(e.value);
    let dijagnozeArray: any = [];
    e.value.forEach((dijagnoza: any) => {
      dijagnozeArray.push(dijagnoza.id);
    });
    setSearchPatientObject({ ...searchPatientObject, dijagnozaIDList: dijagnozeArray, page: 0 });
    setFirst(0);
  };

  const onChangeCalendar = (e: CalendarChangeEvent) => {
    setSearchPatientObject({ ...searchPatientObject, [e.target.name]: formatDate(e.value as Date | undefined, DATE_FORMAT_2), page: 0 });
    setFirst(0);
  };

  const openDialog = () => {
    dispatch({ type: actionsAddPacijent.OPEN_DIALOG });
    getPolList();
    getObavestenjeTipList();
  };

  const getPolList = () => {
    dispatch({ type: actionsAddPacijent.GENDERS_LOADING, value: true });
    axiosGetPolList()
      .then((res) => {
        setPolList(res.data.data);
        dispatch({ type: actionsAddPacijent.GENDERS_LOADING, value: false });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const getObavestenjeTipList = () => {
    dispatch({ type: actionsAddPacijent.OBAVESTENJE_TIP_LIST_LOADING, value: true });
    axiosGetObavestenjeTipList()
      .then((res) => {
        // setObavestenjeTipList(res.data.data);
        // dispatch({ type: actionsAddPacijent.OBAVESTENJE_TIP_LIST_LOADING, value: false });
        // obrisati ovo dole posle pustanja na test i otkomentarisati kod iznad
        let withoutSms = res.data.data.filter((notificationType: EnumBaseReadDto) => notificationType.sifra !== EnumTipObavestenja.SMS);
        setObavestenjeTipList(withoutSms);
        dispatch({ type: actionsAddPacijent.OBAVESTENJE_TIP_LIST_LOADING, value: false });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const validatePacijent = (pacijent: PacijentReadDto | undefined) => {
    if (validateStringEmpty(pacijent?.ime)) {
      showMessage(MessageType.ERROR, Labels.PACIJENT_IME_OBAVEZAN_PODATAK);
      return false;
    }
    if (validateStringEmpty(pacijent?.prezime)) {
      showMessage(MessageType.ERROR, Labels.PACIJENT_PREZIME_OBAVEZAN_PODATAK);
      return false;
    }
    if (checkEmpty(pacijent?.vremeRodjenja)) {
      showMessage(MessageType.ERROR, Labels.PACIJENT_DATUM_RODJENJA_OBAVEZAN_PODATAK);
      return false;
    }
    if (checkEmpty(pacijent?.polTrenutni)) {
      showMessage(MessageType.ERROR, Labels.PACIJENT_POL_OBAVEZAN_PODATAK);
      return false;
    }
    if (pacijent?.telefon && !isTelefonValid(pacijent.telefon)) {
      showMessage(MessageType.ERROR, Labels.TELEFON_WRONG_FORMAT);
      return false;
    }
    if (pacijent?.email && !isEmailFormatValid(pacijent.email)) {
      showMessage(MessageType.ERROR, Labels.EMAIL_WRONG_FORMAT);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validatePacijent(state.pacijent)) {
      return;
    }

    axiosCreatePacijent(state.pacijent)
      .then((response: any) => {
        setPacijentExists(false);
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_1 +
            response.data.data.ime +
            " " +
            response.data.data.prezime +
            Labels.LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_2 +
            formatDate(response.data.data.vremeRodjenja, DATE_FORMAT) +
            Labels.LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_3 +
            response.data.data.polTrenutni?.naziv
        );
        showMessage(MessageType.SUCCESS, Labels.PACIJENT_USPESNO_KREIRAN);
        navigate(`/pacijent-dosije/${response.data.data.id}`);
        [].forEach.call(document.getElementsByClassName("p-datepicker-mask"), function (element: Element) {
          element.remove();
        });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return {
    checked,
    setChecked,
    orgJedinicaList,
    showResults,
    setShowResults,
    selectedTableColumns,
    setSelectedtableColumns,
    tableColumns,
    onColumnToggle,
    first,
    setFirst,
    tableRows,
    onPageFormTable,
    patientList,
    searchPacijent,
    searchPatientObject,
    setSearchPatientObject,
    searchPacijentByEnter,
    onChangeCalendar,
    searchPatientLoading,
    orgJedListLoading,
    searchSifarnikComplete,
    onChangeAutoComplete,
    sifarnikSuggestionsList,
    selectedDijagnoze,
    dijagnozaAutocompleteLoading,
    mainDivRef,
    pacijentCount,
    state,
    dispatch,
    polList,
    onCreate,
    openDialog,
    obavestenjeTipList,
    searchRef,
    pacijentExists,
    setPacijentExists,
    warningMessageRef,
    checkPacijentExists,
    datumOdRef,
    datumDoRef,
  };
}
