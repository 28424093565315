import moment from "moment";

interface RadniDanGridLogicalType {
  setMinutes: (datum: Date | null) => moment.Moment;
  setHoursAndMinutes: (datum: moment.Moment, hours: number, minutes: number) => moment.Moment;
  hours: Array<number>;
  pocetakRadaHour: number;
  krajRadaHour: number;
  pocetakRadaMinute: number;
  krajRadaMinute: number;
}

interface RadniDanGridLogicalProps {
  radnoVremeOdOrgJed: moment.Moment;
  radnoVremeDoOrgJed: moment.Moment;
  vertical: boolean;
}

const getHours = (startTime: number, endTime: number) => {
  const diff = endTime - startTime;

  return Array.from({ length: diff }, (_, i) => i + startTime);
};

const setMinutes = (datum: Date | null) => {
  return (datum ? moment(datum) : moment()).startOf("minute");
};

const setHoursAndMinutes = (datum: moment.Moment, hours: number, minutes: number) => {
  return moment(datum).set("hour", hours).set("minute", minutes).startOf("minute");
};
export default function RadniDanGridLogical(props: RadniDanGridLogicalProps): RadniDanGridLogicalType {
  const { radnoVremeOdOrgJed, radnoVremeDoOrgJed, vertical } = props;

  const pocetakRadaHour = radnoVremeOdOrgJed.get("hour");
  const pocetakRadaMinute = radnoVremeOdOrgJed.get("minute");
  const krajRadaHour = radnoVremeDoOrgJed.get("hour");
  const krajRadaMinute = radnoVremeDoOrgJed.get("minute");
  const hours = getHours(pocetakRadaHour + (vertical ? 1 : 0), krajRadaHour + (!vertical && krajRadaMinute > 0 ? 1 : 0));

  return { setMinutes, setHoursAndMinutes, pocetakRadaHour, hours, krajRadaHour, pocetakRadaMinute, krajRadaMinute };
}
