enum actionsZakazaniIzvestaj {
  FETCH_PARAMS = "fetch_params",
  FETCH_DATA = "fetch_data",
  CANCEL_EDIT = "cancel_edit",
  SET_IZVESTAJ_LIST = "set_izvestaj_list",
  SET_IZVESTAJ_LIST_LOADING = "set_izvestaj_list_loading",
  EMPTY_FIELDS = "empty_fields",
  ADD_PARAM_VALUE = "add_param_value",
}

export default actionsZakazaniIzvestaj;
