import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { Navigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { setInvalid, tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import PoljeReadDto from "../../../model/polje/PoljeReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import ConfirmComponent from "../../confirm-component/ConfirmComponent";
import DialogComponent from "../../dialog-component/DialogComponent";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CrudFormularLogical from "./CrudFormularLogical";

export default function CrudFormular() {
  const {
    isDisabled,
    navigate,
    poljeList,
    onPageFormTable,
    first,
    tableRows,
    exportData,
    formularId,
    formularChange,
    breadCrumbItems,
    setFormularChange,
    setIndex,
    index,
    formularOperation,
    onCancel,
    onCreate,
    onDelete,
    onUpdate,
    location,
    tipKontaktaId,
    tipKontaktaOperation,
    tipKontaktaNaziv,
    tipKontaktaOptions,
    jasperUploadHandler,
    jasperUploadDisabled,
    setJasperUploadDisabled,
    formularLoading,
    tipKontaktaLoading,
    poljaFormularaLoading,
    formularNaziv,
    openDialog,
    kopirajFormular,
    closeDialogFormularCopy,
    isOpenDialogFormularCopy,
    formularCopyChange,
    setFormularCopyChange,
    ustanovaId,
    ustanovaNaziv,
    invalidFields,
    setInvalidFields,
  } = CrudFormularLogical();
  const Labels = useLabels();
  const dt = useRef<any>(null);
  const jasperUploadRef = useRef<any>(null);
  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();
  const { pristup } = useContext(AppContext);

  const openPolje = (entityOperation: string, polje?: PoljeReadDto) => {
    const poljeId = entityOperation !== EntityOperation.CREATE ? polje?.id : "";
    ustanovaId
      ? navigate(`/crud-ustanova/${ustanovaId}/crud-tip-kontakta/${tipKontaktaId}/crud-formular/${formularId}/crud-polje/${poljeId}`, {
          state: {
            poljeOperation: entityOperation,
            formularOperation: formularOperation,
            tipKontaktaId: tipKontaktaId,
            tipKontaktaOperation: tipKontaktaOperation,
            tipKontaktaNaziv: tipKontaktaNaziv,
            formularNaziv: formularNaziv ?? "",
            ustanovaId: ustanovaId,
            ustanovaNaziv: ustanovaNaziv,
          },
        })
      : navigate(`/crud-formular/${formularId}/crud-polje/${poljeId}`, {
          state: {
            poljeOperation: entityOperation,
            formularOperation: formularOperation,
            tipKontaktaId: tipKontaktaId,
            tipKontaktaOperation: tipKontaktaOperation,
            tipKontaktaNaziv: tipKontaktaNaziv,
            formularNaziv: formularNaziv ?? "",
          },
        });
  };

  if (!formularId && !location.state) {
    return <Navigate to="/formular-list" />;
  }

  const leftContentsToolbar = () => (
    <>
      {formularOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.FORMULAR_TITLE_DIALOG_CREATE}
          disabled={!pristup || tipKontaktaLoading}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {formularOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || formularLoading || tipKontaktaLoading}
          icon="pi pi-pencil"
          tooltip={Labels.FORMULAR_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {formularOperation === EntityOperation.UPDATE && (
        <Button
          disabled={!pristup || formularLoading || tipKontaktaLoading}
          icon="pi pi-copy"
          tooltip={Labels.KOPIRAJ_FORMULAR}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-pencil ml-2"
          onClick={openDialog}
        />
      )}
      {formularOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || formularLoading || tipKontaktaLoading}
          icon="pi pi-trash"
          tooltip={Labels.FORMULAR_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={onCancel} />
    </>
  );

  const leftContents = () => (
    <>
      {!isDisabled && (
        <Button
          className="p-button-success mr-3"
          label={Labels.BUTTON_ADD_POLJE}
          tooltip={Labels.TOOLTIP_CREATE_POLJE}
          tooltipOptions={tooltipOptionsBottom}
          disabled={!pristup || formularOperation !== EntityOperation.UPDATE}
          onClick={() => {
            openPolje(EntityOperation.CREATE);
          }}
        />
      )}
    </>
  );

  const cancelOptions = { className: "class-visibility-hidden" };
  const rightContents = () => (
    <>
      <ExportData disabled={poljaFormularaLoading} data={exportData} name={Labels.POLJE_LIST} dt={dt} />
    </>
  );

  const buttonListTemplate = (rowPolje: PoljeReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.TOOLTIP_UPDATE_POLJE}
          tooltipOptions={tooltipOptionsTop}
          disabled={formularOperation !== EntityOperation.UPDATE}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowPolje && openPolje(EntityOperation.UPDATE, rowPolje)}
        />
        <Button
          type="button"
          tooltip={Labels.TOOLTIP_DELETE_POLJE}
          tooltipOptions={tooltipOptionsTop}
          disabled={!pristup || formularOperation !== EntityOperation.UPDATE}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowPolje && openPolje(EntityOperation.DELETE, rowPolje)}
        />
      </div>
    );
  };

  const onRemoveJasper = () => {
    setJasperUploadDisabled(false);
    setFormularChange({
      ...formularChange!,
      jrDokument: undefined,
    });
    jasperUploadRef.current.clear();
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_PRIKAZNI_REDOSLED, filter: true, sortrable: true },
    { columnName: Labels.HEADER_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.HEADER_SIFRA, filter: true, sortrable: true },
  ];

  const renderFooter = () => {
    return (
      <div className="flex justify-content-end footer-buttons">
        <Button label={Labels.BUTTON_CANCEL} className="p-button-outlined p-button-danger bg-white" onClick={closeDialogFormularCopy} autoFocus />
        <Button label={Labels.KOPIRAJ} onClick={kopirajFormular} className="p-button-success orange" />
      </div>
    );
  };

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_FORM}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>
                    {Labels.FORMULAR_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!formularLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={formularChange?.sifra ?? ""}
                        onChange={(e) => {
                          setFormularChange({
                            ...formularChange!,
                            sifra: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", formularChange?.sifra);
                        }}
                        className={invalidFields?.sifra && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>
                    {Labels.FORMULAR_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!formularLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={formularChange?.naziv ?? ""}
                        onChange={(e) => {
                          setFormularChange({
                            ...formularChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", formularChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>
                    {Labels.LABEL_TIP_KONTAKTA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!formularLoading && !tipKontaktaLoading ? (
                    <>
                      <Dropdown
                        disabled={!pristup || isDisabled || tipKontaktaId !== undefined}
                        options={tipKontaktaOptions}
                        optionLabel="naziv"
                        optionValue="id"
                        filter
                        value={tipKontaktaId ?? formularChange?.kontaktTip?.id ?? ""}
                        onChange={(e) => {
                          setFormularChange({
                            ...formularChange!,
                            kontaktTip: tipKontaktaOptions!.find((x) => x.id === e.target.value)!,
                          });
                          if (invalidFields?.kontaktTip) setInvalidFields((prev: any) => ({ ...prev, kontaktTip: false }));
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={invalidFields?.kontaktTip && "p-invalid"}
                      />
                      {invalidFields?.kontaktTip && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>{Labels.FORMULAR_HTML_PREDLOZAK}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0 mt-2 mb-2">
                  {!formularLoading ? (
                    <InputTextarea
                      rows={3}
                      cols={5}
                      disabled={!pristup || isDisabled}
                      value={formularChange?.htmlPredlozak ?? ""}
                      onChange={(e) => {
                        setFormularChange({
                          ...formularChange!,
                          htmlPredlozak: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>{Labels.FORMULAR_JR_DOCUMENT}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0 mt-2 mb-2">
                  <div className="flex flex-row flex-wrap">
                    {!formularLoading ? (
                      <FileUpload
                        ref={jasperUploadRef}
                        auto
                        mode="basic"
                        accept=".jrxml"
                        name={Labels.FORMULAR_JR_DOCUMENT}
                        disabled={!pristup || isDisabled || jasperUploadDisabled}
                        cancelOptions={cancelOptions}
                        multiple={false}
                        customUpload
                        uploadHandler={(e) => {
                          setJasperUploadDisabled(true);
                          jasperUploadHandler(e);
                        }}
                        chooseLabel={Labels.FORMULAR_CHOOSE_JASPER}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                    {jasperUploadDisabled && (
                      <div className="col-12 sm:col-8 p-fluid p-0 ml-2 mb-2">
                        <div className="flex flex-row flex-wrap">
                          <div className="mr-2 mt-2">{formularChange?.jrDokumentNaziv}</div>
                          <ConfirmComponent
                            tooltip={Labels.FORMULAR_REMOVE_JASPER}
                            disabled={!pristup || isDisabled || !jasperUploadDisabled}
                            buttonClassName="p-button-outlined p-button-danger"
                            buttonIcon="pi pi-trash"
                            acceptClassName="p-button p-component text-white p-button-tertiary"
                            rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
                            confirmIcon="pi pi-exclamation-triangle"
                            confirmTitle={Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_JR_DOKUMENT}
                            onAccept={() => {
                              onRemoveJasper();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
          </TabPanel>
          <TabPanel header={Labels.POLJE_LIST} disabled={formularOperation === EntityOperation.CREATE}>
            <div className="layout-generic-content-list">
              <Toolbar left={leftContents} right={rightContents} />
              <div>
                {poljaFormularaLoading ? (
                  <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={!isDisabled} />
                ) : (
                  <DataTable
                    ref={dt}
                    filterDisplay="row"
                    value={poljeList}
                    paginator
                    first={first}
                    paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
                    rows={tableRows}
                    onPage={(e) => {
                      onPageFormTable(e.rows, e.first);
                    }}
                    emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                    exportFilename={Labels.POLJE_LIST}
                    rowHover
                    stripedRows
                  >
                    <Column field={"prikazniRedosled"} header={Labels.HEADER_PRIKAZNI_REDOSLED} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    <Column field={"naziv"} header={Labels.HEADER_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    <Column field={"sifra"} header={Labels.HEADER_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    {!isDisabled && <Column body={buttonListTemplate} />}
                  </DataTable>
                )}
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Panel>
      <Panel>
        <DialogComponent
          header={Labels.KOPIRAJ_FORMULAR}
          visible={isOpenDialogFormularCopy}
          footer={renderFooter}
          onHide={closeDialogFormularCopy}
          blockScroll={true}
          //className={isMobile ? "w-11" : undefined}
          content={
            <div className="col-8 xl:col-12 lg:col-8 md:col-6 sm:col-12">
              <div className="grid align-items-center p-0">
                <div className="col-4">
                  <div>
                    {Labels.FORMULAR_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-8 p-fluid p-0">
                  {!formularLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={formularCopyChange?.sifra ?? ""}
                      onChange={(e) => {
                        setFormularCopyChange({
                          ...formularCopyChange!,
                          sifra: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-4">
                  <div>
                    {Labels.FORMULAR_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-8 p-fluid p-0">
                  {!formularLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={formularCopyChange?.naziv ?? ""}
                      onChange={(e) => {
                        setFormularCopyChange({
                          ...formularCopyChange!,
                          naziv: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-4">
                  <div>{Labels.LABEL_TIP_KONTAKTA}</div>
                </div>
                <div className="col-8 p-fluid p-0">
                  {!formularLoading && !tipKontaktaLoading ? (
                    <Dropdown
                      disabled={!pristup || isDisabled || tipKontaktaId !== undefined}
                      options={tipKontaktaOptions}
                      optionLabel="naziv"
                      optionValue="id"
                      filter
                      showClear
                      value={tipKontaktaId ?? formularCopyChange?.kontaktTip?.id ?? ""}
                      onChange={(e) => {
                        setFormularCopyChange({
                          ...formularCopyChange!,
                          kontaktTip: tipKontaktaOptions!.find((x) => x.id === e.target.value)!,
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
          }
        />
      </Panel>
      <ConfirmPopup />
    </div>
  );
}
