import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import * as React from "react";
import { useContext, useMemo } from "react";
import { AppContext, useLabels } from "../../Store";
import KontrolaPravaPristupaService from "../../infrastructure/system/KontrolaPravaPristupaService";
import { MINIMAL_PANEL_WIDTH_FOR_PACIJENT_INFO, handleScreenSizeChange } from "../../infrastructure/system/Utils";
import DialogComponent from "../dialog-component/DialogComponent";
import PacijentDosijeLogical from "./PacijentDosijeLogical";
import PacijentDatotekaList from "./dokumentacija/lista-datoteka/PacijentDatotekaList";
import ListaDokumenata from "./dokumentacija/lista-dokumenata/ListaDokumenata";
import PregledKontakata from "./dokumentacija/pregled-nalaza/PregledKontakata";
import PacijentInfo from "./info/PacijentInfo";
import OpstiMedicinskiPodaci from "./opste-medicinski-podaci/OpstiMedicinskiPodaci";
import AdministrativniPodaci from "./podaci-o-pacijentu/administrativni-podaci/AdministrativniPodaci";
import PodaciSmrti from "./podaci/PodaciSmrti";
import PacijentRizik from "./rizik/PacijentRizik";
import ZakazaniPregledi from "./zakazani-pregledi/ZakazaniPregledi";

export default function PacijentDosije() {
  const {
    pacijent,
    setPacijent,
    kontaktList,
    showVerticalPacijentInfo,
    setShowVerticalPacijentInfo,
    mainPanelDiv,
    pacijentAndKontaktListLoading,
    id,
    setKontaktList,
    setShowDeletePacijentDialog,
    showDeletePacijentDialog,
    deletePacijent,
    medicinskiPodaci,
    pacijentPoljePopunjenoListLoading,
    informacijeOPacijentuList,
    fetchMedicinskiPodaciAndInformacijeOPacijentu,
  } = PacijentDosijeLogical();
  const Labels = useLabels();
  const { pristup, authData } = useContext(AppContext);
  const { hasPacijentDelete } = KontrolaPravaPristupaService();

  window.addEventListener("resize", () => {
    setShowVerticalPacijentInfo(handleScreenSizeChange(mainPanelDiv, MINIMAL_PANEL_WIDTH_FOR_PACIJENT_INFO));
  });

  const headerStyles: React.CSSProperties = useMemo(() => {
    return { position: "absolute", right: "3rem" };
  }, []);

  return (
    <div className={`flex ${showVerticalPacijentInfo ? "" : "flex-column"} layout-pacijent-dosije`} ref={mainPanelDiv}>
      <PacijentInfo
        pacijent={pacijent}
        setPacijent={setPacijent}
        showVerticalPacijentInfo={showVerticalPacijentInfo}
        pacijentLoading={pacijentAndKontaktListLoading}
        medicinskiPodaci={medicinskiPodaci}
        pacijentPoljePopunjenoListLoading={pacijentPoljePopunjenoListLoading}
        informacijeOPacijentuList={informacijeOPacijentuList}
      />
      <div className={`w-full ${showVerticalPacijentInfo ? "ml-4" : ""}`}>
        <TabView activeIndex={0} renderActiveOnly>
          <TabPanel header={Labels.DOKUMENTACIJA}>
            <PregledKontakata pacijentAndKontaktListLoading={pacijentAndKontaktListLoading} kontaktList={kontaktList} setKontaktList={setKontaktList} pacijentID={id} />
            <ListaDokumenata />
            <PacijentDatotekaList pacijentID={id} radnikKreiraoId={pacijent?.radnikKreirao.id} kontaktList={kontaktList} />
          </TabPanel>
          <TabPanel disabled={pacijent ? false : true} header={Labels.OPSTI_MEDICINSKI_PODACI}>
            <OpstiMedicinskiPodaci pacijent={pacijent} medicinskiPodaci={medicinskiPodaci} fetchMedicinskiPodaciAndInformacijeOPacijentu={fetchMedicinskiPodaciAndInformacijeOPacijentu} />
          </TabPanel>
          <TabPanel disabled={pacijent ? false : true} header={Labels.PODACI_O_PACIJENTU} className="cy-patientInformation">
            <Panel>
              <TabView activeIndex={0} renderActiveOnly>
                <TabPanel header={Labels.ADMINISTRATIVNI_PODACI}>
                  <AdministrativniPodaci pacijent={pacijent} setPacijent={setPacijent} fetchMedicinskiPodaciAndInformacijeOPacijentu={fetchMedicinskiPodaciAndInformacijeOPacijentu} />
                </TabPanel>
                {/*<TabPanel header={Labels.OSIGURANJE}>*/}
                {/*  <OsiguranjeListView pacijent={pacijent} />*/}
                {/*</TabPanel>*/}
                {/* <TabPanel header={Labels.KONTAKT_OSOBE}>
                  <PacijentKontaktOsobeView />
                </TabPanel> */}
                <TabPanel header={Labels.PODACI_O_SMRTI}>
                  <PodaciSmrti pacijentVremeSmrti={pacijent?.vremeSmrti} radnikKreiraoId={pacijent?.radnikKreirao.id} setPacijent={setPacijent} />
                </TabPanel>
              </TabView>
            </Panel>
            <Button
              className="p-button-danger mt-3"
              label={Labels.BUTTON_PACIJENT_DELETE}
              icon="pi pi-trash"
              onClick={() => setShowDeletePacijentDialog(true)}
              disabled={!pristup || pacijent?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentDelete}
              data-cy="patientDeleteBtn"
            />
          </TabPanel>
          <TabPanel disabled={pacijent ? false : true} header={Labels.ZAKAZANI_PREGLEDI_LABEL}>
            <ZakazaniPregledi />
          </TabPanel>
          <TabPanel headerStyle={headerStyles} headerTemplate={<PacijentRizik id={pacijent?.id} />} />
        </TabView>
      </div>
      <DialogComponent
        header={Labels.PACIJENT_BRISANJE}
        visible={showDeletePacijentDialog}
        onHide={() => setShowDeletePacijentDialog(false)}
        content={
          <div>
            <p>{`${Labels.PERMANENT_DELETE_PART_1} ${pacijent?.ime} ${pacijent?.prezime} ${Labels.PERMANENT_DELETE_PART_2}`}</p>
            <p>{Labels.IRREVERSIBLE_ACTION}</p>
            <p> {Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_PACIJENT}</p>
          </div>
        }
        onAccept={deletePacijent}
        onReject={() => setShowDeletePacijentDialog(false)}
        className="cy-deletePatientConfirmDialog"
      />
    </div>
  );
}
