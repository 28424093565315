import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../../controllers/enum/EnumController";
import EnumBaseReadDto from "../../../../../model/sifarnik/EnumBaseReadDto";
import QueryKeys from "../../../QueryKeys";

export function useNamenaKategorijePopustaList() {
  const { axiosGetNamenaKategorijePopustaList } = EnumController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.NAMENA_KATEGORIJE_POPUSTA],
    queryFn: () => axiosGetNamenaKategorijePopustaList(),
  });

  const namenaKategorijePopustaList: Array<EnumBaseReadDto> = data?.data?.data;

  return { namenaKategorijePopustaList, refetch, isLoading };
}
