const url = process.env.REACT_APP_API_CORE_BASE_URL;
const urlRepository = process.env.REACT_APP_API_REPOSITORY_BASE_URL;
const urlArchive = process.env.REACT_APP_API_ARCHIVE_BASE_URL;
const urlLog = process.env.REACT_APP_API_LOG_BASE_URL;
const urlDocument = process.env.REACT_APP_API_DOCUMENT_BASE_URL;
const urlNotification = process.env.REACT_APP_API_NOTIFICATION_BASE_URL;

const Endpoint = {
  RESET_PASSWORD: url + "/auth/password-reset",

  ROLA_LIST: url + "/sifarnik/rola-list",
  JEZIK_LIST: url + "/sifarnik/jezik-list",
  ORGANIZACIONA_JEDINICA_TIP_LIST: url + "/sifarnik/organizaciona-jedinica-tip-list",
  SVOJINA_TIP_LIST: url + "/sifarnik/svojina-tip-list",
  NIVO_ZDRAVSTVENE_ZASTITE_LIST: url + "/sifarnik/nivo-zdravstvene-zastite-list",
  PODRAZUMEVANA_STRANA_LIST: url + "/sifarnik/pozdrazumevana-strana-list",
  TIP_KOMPONENTE_LIST: url + "/sifarnik/tip-komponente-list",
  RADNI_DAN_LIST: url + "/sifarnik/radni-dan-list",
  ZAKAZAN_TERMIN_STATUS_LIST: url + "/sifarnik/zakazan-termin-status-list",
  POL_LIST: url + "/sifarnik/pol-list",
  TRAJANJE_TERMINA_LIST: url + "/sifarnik/trajanje-termina-list",
  TIP_ZAKAZANOG_TERMINA_LIST: url + "/sifarnik/zakazan-termin-tip-list",
  OSIGURANJE_TIP_LIST: url + "/sifarnik/osiguranje-tip-list",
  PARAMETAR_KATEGORIJA_LIST: url + "/sifarnik/parametar-kategorija-list",
  PACIJENT_POLJE_KATEGORIJA_KATEGORIJA_LIST: url + "/sifarnik/pacijent-polje-kategorija-list",
  APLIKATIVNO_PRAVO_LIST: url + "/sifarnik/aplikativno-pravo-list",
  VRSTA_PRAVA_LIST: url + "/sifarnik/vrsta-prava-list",
  TIP_OBJEKTA_LIST: url + "/sifarnik/tip-objekta-list",
  DOKUMENTACIJA_PERIOD_LIST: url + "/sifarnik/dokumentacija-period-list",
  RADNIK_STATUS_LIST: url + "/sifarnik/radnik-status-list",
  OBAVESTENJE_STATUS_LIST: url + "sifarnik/obavestenje-status-list",
  VALUTA_LIST: url + "/sifarnik-mgmt/sifarnik-valuta-list",
  PORESKA_STOPA_LIST: url + "/sifarnik-mgmt/sifarnik-poreska-stopa-list",
  UPOZORENJE: url + "/upozorenje-mgmt",
  FORMULAR: url + "/formular-mgmt",
  FAJL_FORMAT: url + "/sifarnik/fajl-format-list",

  KONTAKT_LIST: url + "/kontakt/kontakt-list",

  FORMULAR_LIST: url + "/formular-mgmt/formular-list",
  FORMULAR_SEARCH: url + "/formular-mgmt/formular-search",

  PARAMETAR_LIST: url + "/parametar-mgmt/parametar-list",
  PARAMETAR_USTANOVA_LIST: url + "/parametar-mgmt/parametar-ustanova-list",
  SUPERADMIN_PARAMETAR_USTANOVA_LIST: url + "/superadmin/parametar-mgmt/parametar-ustanova-list",

  POLJE_TIP_LIST: url + "/polje-tip-mgmt/polje-tip-list",
  POLJE_TIP_SEARCH: url + "/polje-tip-mgmt/polje-tip-search",
  POLJE_TIP_PACIJENT_POLJE: url + "/polje-tip-mgmt/polje-tip/pacijent-polje",

  PACIJENT_LIST: url + "/pacijent/pacijent-list",
  PACIJENT_SPAJANJE_LIST: url + "/pacijent/pacijent-search-spajanje",
  PACIJENT_MERGE: url + "/pacijent/pacijent-list/pacijent-merge",
  PACIJENT_SPLIT: url + "/pacijent/pacijent-list/pacijent-split",
  PACIJENT_SPLIT_LIST: url + "/pacijent/pacijent-search-razdvajanje",

  PACIJENT_POLJE_LIST: url + "/pacijent-polje-mgmt/pacijent-polje-list",
  PACIJENT_POLJE_SEARCH: url + "/pacijent-polje-mgmt/pacijent-polje-search",
  PACIJENT_POLJE_PACIJENT_POLJE_KATEGORIJA_LIST: url + "/pacijent-polje-mgmt/pacijent-polje-list-kategorija",

  PACIJENT_POLJE_KATEGORIJA: url + "/pacijent-polje-kategorija-mgmt/pacijent-polje-kategorija-list/kategorija",
  PACIJENT_POLJE_KATEGORIJA_LIST: url + "/pacijent-polje-kategorija-mgmt/pacijent-polje-kategorija-list",

  PACIJENT_SMRT_DOKUMENT: urlDocument + "/smrt-pacijenta-datoteka-mgmt/smrt-pacijenta-datoteka-list",

  RADNIK_LIST: url + "/radnik-mgmt/radnik-list",
  RADNIK_SEARCH: url + "/radnik-mgmt/radnik-search",
  RADNIK_MGMT: url + "/radnik-mgmt",

  ORGANIZACIONA_JEDINICA_LIST: url + "/organizaciona-jedinica-mgmt/organizaciona-jedinica-list",
  ORGANIZACIONA_JEDINICA_SEARCH: url + "/organizaciona-jedinica-mgmt/organizaciona-jedinica-search",
  ORGANIZACIONA_JEDINICA_ROOT: url + "/organizaciona-jedinica-mgmt",

  USTANOVA_LIST: url + "/ustanova-mgmt/ustanova-list",
  SUPERADMIN_USTANOVA_LIST: url + "/superadmin/ustanova-mgmt/ustanova-list",
  SUPERADMIN_USTANOVA_SEARCH: url + "/superadmin/ustanova-mgmt/ustanova-search",
  USTANOVA_GRID_LIST: url + "/ustanova-mgmt/grid",
  USTANOVA_PODESAVANJA: url + "/ustanova-mgmt/ustanova-podesavanja",
  SUPERADMIN_USTANOVA_GRID_LIST: url + "/superadmin/ustanova-mgmt/grid",
  USTANOVA_BROJ_NALOGA: url + "/licenca-ustanova-mgmt/broj-naloga-ustanova-list",
  USTANOVA_STORAGE_LIMIT_LIST_USTANOVA: url + "/licenca-ustanova-mgmt/storage-limit-ustanova-list/ustanova",
  USTANOVA_STORAGE_LIMIT_LIST: url + "/licenca-ustanova-mgmt/storage-limit-ustanova-list",
  USTANOVA_BROJ_NALOGA_LIST: url + "/licenca-ustanova-mgmt/broj-naloga-ustanova-list",
  SIFARNIK_PRISTUP_LIST_ENUM: url + "/sifarnik/pristup-list",
  UINICIJALIZACIJA_KORAK_LIST_ENUM: url + "/sifarnik/inicijalizacija-korak-list",

  SUPERADMIN_KONTAKT_TIP: url + "/superadmin-kontakt-tip-mgmt/ustanova-mgmt/ustanova-list",
  TIP_KONTAKTA_LIST: url + "/kontakt-tip-mgmt/kontakt-tip-list",
  TIP_KONTAKTA_LIST_INICIJALIZACIJA: url + "/kontakt-tip-mgmt/kontakt-tip-list/inicijalizacija",
  TIP_KONTAKTA_SEARCH: url + "/kontakt-tip-mgmt/kontakt-tip-search",

  ULOGA_LIST: url + "/uloga-mgmt/uloga-list",
  ULOGA_SEARCH: url + "/uloga-mgmt/uloga-search",
  APLIKATIVNO_PRAVO_SUFIX: "/aplikativno-pravo-list",
  PRAVO_NAD_TIPOM_SUFIX: "/pravo-nad-tipom-list",

  SIFARNIK_KATEGORIJA_KONTAKTA_LIST: url + "/sifarnik-mgmt/sifarnik-kategorija-kontakta-list",
  SIFARNIK_DIJAGNOZA_STATUS_LIST: url + "/sifarnik/dijagnoza-status-list",
  SIFARNIK_USLUGA_LIST: url + "/sifarnik-mgmt/sifarnik-usluga-list",
  SIFARNIK_USLUGA_RFZO_LIST: url + "/sifarnik-mgmt/sifarnik-usluga-rfzo-list",
  SIFARNIK_USLUGA_TIP_LIST: url + "/sifarnik/usluga-tip-list",
  SIFARNIK_USLUGA_SEARCH: url + "/sifarnik-mgmt/sifarnik-usluga-search",
  SIFARNIK_USLUGA_SEARCH_NAZIV_SIFRA: url + "/sifarnik-mgmt/sifarnik-usluga-search-naziv-sifra",
  SIFARNIK_JEDINICA_MERE_LIST: url + "/sifarnik-mgmt/sifarnik-jedinica-mere-list",
  SIFARNIK_PROIZVODA_LIST: url + "/sifarnik-mgmt/sifarnik-proizvoda-list",
  SIFARNIK_PROIZVODA_SEARCH: url + "/sifarnik-mgmt/sifarnik-proizvoda-search",
  SIFARNIK_PROIZVODA_RFZO_LIST: url + "/sifarnik-mgmt/sifarnik-proizvoda-rfzo-list",
  SIFARNIK_PROIZVOD_SEARCH: url + "/sifarnik-mgmt/sifarnik-proizvoda-search",
  SIFARNIK_KATEGORIJA_GLOBALNIH_PARAMETARA: url + "/sifarnik-mgmt/sifarnik-kategorija-globalnih-parametara-list",

  PAKET_LIST: url + "/paket-mgmt/paket-list",

  ZAKAZANI_TERMINI_LIST: url + "/zakazan-termin-mgmt/zakazan-termin-list",
  ZAKAZANI_TERMINI_LIST_STATUS_SUFIX: "/zakazanTerminStatus",
  ZAKAZANI_TERMINI_USLUGA_LIST: url + "/zakazan-termin-mgmt/zakazan-termin-usluga-list",
  ZAKAZAN_TERMIN_LIST_PRINT: url + "/zakazan-termin-mgmt/zakazan-termin-list/zakazan-termin-print",
  ZAKAZANI_TERMINI_PACIJENT: url + "/zakazan-termin-mgmt/future/pacijent",
  ZAKAZAN_TERMIN: url + "/zakazan-termin-mgmt/zakazan-termin",
  ZAKAZAN_TERMIN_DATOTEKA: urlDocument + "/zakazan-termin-datoteka-mgmt/zakazan-termin-datoteka-list",
  ZAKAZAN_TERMIN_DATOTEKA_DOCUMENT: urlDocument + "/zakazan-termin-datoteka-mgmt/zakazan-termin-datoteka-list/document",
  ZAKAZAN_TERMIN_VANREDNI: url + "/zakazan-termin-mgmt/zakazan-termin-list/vanredni-termin",

  PACIJENT_SEARCH: url + "/pacijent/pacijent-search",
  PACIJENT_SEARCH_SIMPLE: url + "/pacijent/pacijent-list-simple",
  PACIJENT_GRUPNO_OBAVESTENJE: url + "/pacijent/grupno-obavestenje",

  FORMULAR_POPUNJEN_LIST: url + "/formular-mgmt/formular-popunjen-list",

  RADNI_PERIOD_LIST: url + "/radni-period-mgmt/radni-period-list",

  OBAVESTENJE_LIST: url + "/obavestenje-mgmt/obavestenje-list",
  OBAVESTENJE_SEARCH: url + "/obavestenje-mgmt/obavestenje-search",
  OBAVESTENJE_READ_LIST: url + "/obavestenje-mgmt/obavestenje-read-list",
  OBAVESTENJE_UNREAD_LIST: url + "/obavestenje-mgmt/obavestenje-unread-list",
  OBAVESTENJE_UNREAD_LIST_COUNT: url + "/obavestenje-mgmt/obavestenje-unread-list/count",
  OBAVESTENJE_READ: url + "/obavestenje-mgmt/obavestenje-list/read",
  OBAVESTENJE_READ_ALL: url + "/obavestenje-mgmt/obavestenje-list/read-all",
  OBAVESTENJE_DATOTEKA_LIST: urlDocument + "/obavestenje-datoteka-mgmt/obavestenje-datoteka-list",
  OBAVESTENJE_DETAILS: url + "/obavestenje-mgmt/obavestenje-details",

  DODATNI_MODUL_SEARCH: url + "/licenca-mgmt/dodatni-modul-search",
  DODATNI_MODUL_LIST: url + "/licenca-mgmt/dodatni-modul-list",
  SIFARNIK_DODATNI_MODUL_LIST: url + "/sifarnik/dodatni-modul-list",
  STORAGE_LIMIT_SEARCH: url + "/licenca-mgmt/storage-limit-search",
  STORAGE_LIMIT_LIST: url + "/licenca-mgmt/storage-limit-list",
  BROJ_NALOGA_SEARCH: url + "/licenca-mgmt/broj-naloga-search",
  BROJ_NALOGA_LIST: url + "/licenca-mgmt/broj-naloga-list",
  JEDINICA_KOLICINE_PODATKA: url + "/sifarnik/jedinica-kolicine-podatka-list",

  //LICENCE USTANOVE

  USTANOVA_DODATNI_MODUL: url + "/licenca-ustanova-mgmt/dodatni-modul-ustanova-list",

  KATEGORIJA_IZVESTAJA_LIST: url + "/izvestaj-mgmt/kategorija-statistickog-izvestaja-list",
  KATEGORIJA_IZVESTAJA_SEARCH: url + "/izvestaj-mgmt/kategorija-statistickog-izvestaja-search",
  KATEGORIJA_STATISTICKIH_IZVESTAJA: url + "/izvestaj-mgmt/kategorija-statistickog-izvestaja",
  IZVESTAJ_SEARCH: url + "/izvestaj-mgmt/izvestaj-search",
  IZVESTAJ_LIST: url + "/izvestaj-mgmt/izvestaj-list",
  IZVESTAJ_PARAMETAR_LIST: url + "/izvestaj-mgmt/izvestaj-parametar-list",
  IZVESTAJ_PARAMETAR_TIP: url + "/sifarnik/izvestaj-parametar-tip-list",
  STATISTICKI_IZVESTAJI_BY_KATEGORIJA: url + "/izvestaj-mgmt/izvestaj-list/kategorija-statistickog-izvestaja",
  IZVESTAJ_PARAMETAR_LIST_END_URL: "izvestaj-parametar-list",

  // GENERISANI IZVESTAJI
  GENERISANI_IZVESTAJ_SEARCH: url + "/generisani-izvestaj/generisani-izvestaj-search",
  GENERISANI_IZVESTAJ_SEARCH_COUNT: url + "/generisani-izvestaj/generisani-izvestaj-count",
  GENERISANI_IZVESTAJ_BY_STAT_IZVESTAJ: url + "/generisani-izvestaj/generisani-izvestaj-list/statisticki-izvestaj",
  GENERISANI_IZVESTAJ_LIST: url + "/generisani-izvestaj/generisani-izvestaj-list",
  GENERISANI_IZVESTAJ_PARAMETAR_LIST: url + "/generisani-izvestaj/generisani-izvestaj-parametar-list",
  GENERISANJE_IZVESTAJA_WEBSOCKET: url + "/reportWebSocket",
  GENERISANJE_IZVESTAJA_LISTENER: "/report-listener",
  GENERISANJE_IZVESTAJA: url + "/generisani-izvestaj/addReportForExecution",
  GENERISANI_IZVESTAJI_EXPORT: url + "/generisani-izvestaj/generatedReport",

  // ZAKAZANI IZVESTAJI
  ZAKAZANI_IZVESTAJ_LIST: url + "/zakazan-izvestaj-mgmt/zakazan-izvestaj-list",
  ZAKAZANI_IZVESTAJI_BY_STAT_IZVESTAJ: url + "/zakazan-izvestaj-mgmt/zakazan-izvestaj-list/izvestaj-list",
  GENERISANJE_ZAKAZANOG_IZVESTAJA: url + "/zakazan-izvestaj-mgmt/zakazan-izvestaj-list/izvestaj-list",
  SIFARNIK_PONAVLJANJE_LIST: url + "/sifarnik/ponavljanje-list",
  SIFARNIK_PERIOD_LIST: url + "/sifarnik/period-list",
  ZAKAZANI_IZVESTAJ_PARAMETAR_LIST: url + "/zakazan-izvestaj-mgmt/zakazan-izvestaj-parametar-list",

  // PROTOKOL

  PROTOKOL_SEARCH: url + "/protokol-mgmt/protokol-search",
  VREMENSKI_OKVIR: url + "/sifarnik/vremenski-okvir-list",
  PROTOKOL_LIST: url + "/protokol-mgmt/protokol-list",
  PROTOKOL_BROJ_LIST: url + "/protokol-mgmt/protokol-broj-list",

  // PLACANJE
  PLACANJE_LIST: url + "/placanje-mgmt/placanje-list",
  PLACANJE_LIST_BY_USTANOVA: url + "/placanje-mgmt/placanje-list/ustanova",
  PLACANJE_OBRACUNATI_IZNOS: url + "/placanje-mgmt/placanje-list/obracunat-iznos",
  PLACANJE_TRAJANJE_UGOVORA: url + "/placanje-mgmt/placanje-list/ustanova/trajanje-ugovora",

  // PRAVILA PLACANJA
  PARAMETAR_GLOBALAN_LIST: url + "/parametar-globalan-mgmt/parametar-globalan-list",

  // FAKTURNI PODACI

  FAKTURNI_PODACI_SUFIX: "fakturni-podaci",

  USTANOVA_EKSTERNI_SISTEM_SUFIX: "ustanova-eksterni-sistem-list",

  ULOGA_LIST_SUFIX: "/uloga-list",

  HELIANT_HOME: "https://heliant.rs/",

  //REPOSITORY
  REPOSITORY_POLJE_TIP_SEARCH: urlRepository + "/polje-tip-mgmt/polje-tip-search",
  REPOSITORY_POLJE_TIP_PUBLIC_SEARCH: urlRepository + "/polje-tip-mgmt/polje-tip-public-search",
  REPOSITORY_POLJE_TIP_LIST: urlRepository + "/polje-tip-mgmt/polje-tip-list",
  REPOSITORY_TIP_KONTAKTA_LIST: urlRepository + "/kontakt-tip-mgmt/kontakt-tip-list",
  REPOSITORY_TIP_KONTAKTA_SEARCH: urlRepository + "/kontakt-tip-mgmt/kontakt-tip-search",
  REPOSITORY_TIP_KONTAKTA_PUBLIC_SEARCH: urlRepository + "/kontakt-tip-mgmt/kontakt-tip-public-search",
  REPOSITORY_FORMULAR_LIST: urlRepository + "/formular-mgmt/formular-list",
  REPOSITORY_FORMULAR_SEARCH: urlRepository + "/formular-mgmt/formular-search",
  REPOSITORY_FORMULAR: urlRepository + "/formular-mgmt",
  REPOSITORY_FORMULAR_PUBLIC_SEARCH: urlRepository + "/formular-mgmt/formular-public-search",
  ENTITY_STATUS_LIST: urlRepository + "/sifarnik/entity-status-list",
  REPOSITORY_KATEGORIJA_KONTAKTA_LIST: urlRepository + "/sifarnik/kategorija-kontakta-list",
  SIFARNIK_MKB_SEARCH: urlRepository + "/sifarnik-mgmt/sifarnik-mkb-search",
  SIFARNIK_MKB_LIST: urlRepository + "/sifarnik-mgmt/sifarnik-mkb-list",
  SIFARNIK_MKB_IMPORT: urlRepository + "/sifarnik-mgmt/import-sifarnik-mkb",
  SIFARNIK_ATRIBUTA_SEARCH: urlRepository + "/sifarnik-mgmt/sifarnik-atributa-rfzo-search",
  SUPERADMIN_SIFARNIK_ATRIBUTA_SEARCH: urlRepository + "/sifarnik-mgmt/superadmin/sifarnik-atributa-rfzo-search",
  SUPERADMIN_SIFARNIK_ATRIBUTA_LIST: urlRepository + "/sifarnik-mgmt/superadmin/sifarnik-atributa-rfzo-list",
  SIFARNIK_PROIZVODA_RFZO_SEARCH: urlRepository + "/sifarnik-mgmt/sifarnik-proizvoda-rfzo-search",
  SUPERADMIN_SIFARNIK_PROIZVODA_RFZO_SEARCH: urlRepository + "/sifarnik-mgmt/superadmin/sifarnik-proizvoda-rfzo-search",
  SUPERADMIN_SIFARNIK_PROIZVODA_RFZO_LIST: urlRepository + "/sifarnik-mgmt/superadmin/sifarnik-proizvoda-rfzo-list",
  SUPERADMIN_SIFARNIK_USLUGA_RFZO_LIST: urlRepository + "/sifarnik-mgmt/superadmin/sifarnik-usluga-rfzo-list",
  SUPERADMIN_SIFARNIK_USLUGA_RFZO_SEARCH: urlRepository + "/sifarnik-mgmt/superadmin/sifarnik-usluga-rfzo-search",
  FAKTOR_RIZIKA_SEARCH: urlRepository + "/faktor-rizika-mgmt/faktor-rizika-search",
  FAKTOR_RIZIKA_LIST: urlRepository + "/faktor-rizika-mgmt/faktor-rizika-list",
  FAKTOR_RIZIKA_IKONICA_SEARCH: urlRepository + "/faktor-rizika-mgmt/faktor-rizika-ikonica-search",
  FAKTOR_RIZIKA_IKONICA_LIST: urlRepository + "/faktor-rizika-mgmt/faktor-rizika-ikonica-list",
  FAKTOR_RIZIKA_LIST_USTANOVA: url + "/faktor-rizika-ustanova-mgmt/faktor-rizika-ustanova-list",
  SIFARNIK_LEKOVA_SEARCH: urlRepository + "/sifarnik-mgmt/sifarnik-lekova-search",
  ISTORIJA_LEKA_CENA: urlRepository + "/sifarnik-mgmt/sifarnik-lekova-list",
  SIFARNIK_LEKOVA_ATRIBUT: urlRepository + "/sifarnik-mgmt/sifarnik-lekova-list/atribut",
  SIFANIK_LEKOVA_ATRIBUT_LIST: urlRepository + "/sifarnik-mgmt/sifarnik-lekova-list/atribut-list",
  SIFARNIK_LEKOVA_SEARCH_GLOBAL: urlRepository + "/sifarnik-mgmt/sifarnik-lekova-search-global",
  SIFARNIK_JEDINICA_MERE: urlRepository + "/sifarnik-mgmt/sifarnik-jedinica-mere/sifarnik-jedinica-mere-list",
  SIFARNIK_SPECIJALIZACIJA_LIST: urlRepository + "/sifarnik-mgmt/sifarnik-specijalizacija-list",
  SIFARNIK_EKSTERNI_SISTEM_LIST: urlRepository + "/sifarnik-mgmt/sifarnik-eksterni-sistem-list",
  REPOSITORY_IZVESTAJ: urlRepository + "/izvestaj-mgmt/izvestaj-list",
  REPOSITORY_IZVESTAJ_PUBLIC_SEARCH: urlRepository + "/izvestaj-mgmt/izvestaj-public-search",
  //ARCHIVE
  ARCHIVE_PACIJENT_POLJE_POPUNJENO_SEARCH: urlArchive + "/pacijent-polje-popunjeno-arhiva/pacijent-polje-popunjeno-arhiva-search",

  //LOG
  LOG_WEB_SERVICE_CALL_SEARCH: urlLog + "/log-web-service-call/log-web-service-call-list/search",
  LOG_WEB_SERVICE_CALL_COUNT_SEARCH: urlLog + "/log-web-service-call/log-web-service-call-list/count-search",
  LOG_WEB_SERVICE_CALL_LIST: urlLog + "/log-web-service-call/log-web-service-call-list",

  LOG_HIGH_LEVEL_LIST: urlLog + "/log-high-level/log-high-level-list",
  LOG_HIGH_LEVEL_LIST_SEARCH_COUNT: urlLog + "/log-high-level/log-high-level-list/search-count",

  //DOCUMENT
  DOCUMENT: urlDocument + "/dokument-mgmt/dokument-list",

  COPY_PUBLIC_SUFIX: "/copyPublic",

  //UVOZ SIFARNIKA
  SIFARNIK_IMPORT_TYPE_LIST: urlRepository + "/sifarnik-importer-mgmt/sifarnik-type-list",
  SIFARNIK_IMPORT_LIST: urlRepository + "/sifarnik-importer-mgmt/sifarnik-importer-list",
  SIFARNIK_IMPORT_WEBSOCKET: urlRepository + "/sifarnik-importer-ws",
  SIFARNIK_IMPORT_LISTENER: "/importer-listener",

  //IZVOZ
  IZVOZ_CREATE: url + "/export-zahtev-mgmt/export-zahtev-list",
  IZVOZ_LIST: url + "/export-zahtev-mgmt/export-zahtev-list",
  IZVOZ_DATOTEKA_LIST: url + "/export-zahtev-mgmt/export-zahtev",
  IZVOZ_LIST_SUPERADMIN: url + "/export-zahtev-mgmt/superadmin/export-zahtev-list/",
  IZVOZ_GENERATE: url + "/export-zahtev-mgmt/superadmin/export-zahtev-list/generisi",

  //RECEPT:
  RECEPT_LIST_KONTAKT: url + "/recept/recept-list/kontakt",
  RECEPT: url + "/recept/recept-list",
  RECEPT_FILL: urlRepository + "/sifarnik-mgmt/dopuni-terapiju",

  // HARD DELETE
  SUPERADMIN_HARD_DELETE_USTANOVA: url + "/superadmin/ustanova-mgmt/ustanova-list/erase",
  HARD_DELETE_WEBSOCKET: url + "/hard-delete-ws",
  HARD_DELETE_LISTENER: "/hard-delete-listener",

  // EXPORT
  EXPORT_WEBSOCKET: url + "/exportWebSocket",
  EXPORT_LISTENER: "/export-listener",

  // PORUKE
  PORUKE_LIST: urlNotification + "/poruka-mgmt/poruka-list",
  PORUKA_DATOTEKA_LIST: urlDocument + "/poruka-datoteka-mgmt/poruka-datoteka-list",

  // CENE
  SIFARNIK_NACINA_PLACANJA_LIST: url + "/sifarnik-mgmt/sifarnik-nacin-placanja-list",
  SIFARNIK_KATEGORIJA_POPUSTA_LIST: url + "/sifarnik-mgmt/sifarnik-kategorija-popusta-list",
  SIFARNIK_KATEGORIJA_POPUSTA_TIP_KATEGORIJE: url + "/sifarnik/tip-kategorije-popusta-list",
  SIFARNIK_KATEGORIJA_POPUSTA_NAMENA_KATEGORIJE: url + "/sifarnik/namena-kategorije-popusta-list",

  // PARTNERI
  PARTNERI_LIST: url + "/partner-mgmt/partner-list",
  PARTNERI_SEARCH: url + "/partner-mgmt/partner-list/partner-search",
  PARTNER_TIP_LIST: url + "/sifarnik/partner-tip-list",

  // NAPLATA
  KONTAKT_NAPLATA_LIST: url + "/kontakt/kontakt-list/kontakt-naplata-list",
  NAPLATA_SIFARNIK_KONTAKT_PLACEN_LIST: url + "/sifarnik/kontakt-placen-list",
  NAPLATA_RACUN_LIST: url + "/racun/racun-list",
  NAPLATA_PROIZVOD_LIST_CENA: url + "/racun/proizvod-list",
  NAPLATA_USLUGA_LIST_CENA: url + "/racun/usluga-list",
  NAPLATA_UPLATILAC_LIST: url + "/sifarnik/uplatilac-list",
  KONTAKT_NAPLATA_LIST_COUNT: url + "/kontakt/kontakt-list/kontakt-naplata-list-count",
  VRSTA_UPLATIOCA_LIST: url + "/sifarnik/vrsta-uplatioca-list",
  NAPLATA_STAMPA_SPECIFIKACIJE_RACUNA: url + "/racun/racun-list/print",

  // OBAVESTENJA ZA PACIJENTE
  SIFARNIK_OBAVESTENJE_TIP_LIST: url + "/sifarnik/obavestenje-tip-list",
  GRUPNO_OBAVESTENJE_LIST: urlNotification + "/grupno-obavestenje-mgmt/grupno-obavestenje-list",

  // OBRASCI STAMPE PROTOKOLA
  OBRASCI_STAMPE_PROTOKOLA_LIST: urlRepository + "/protokol-obrazac-mgmt/protokol-obrazac-list",
  STAMPA_PROTOKOLA: url + "/protokol-mgmt/protokol-list/protokol-print",

  // VREME TRAJANJA KONTAKTA
  VREME_TRAJANJA_KONTAKTA_LIST: url + "/sifarnik/vreme-trajanja-kontakta-list",

  // VRSTA GRAFIKONA RASTA
  VRSTA_GRAFIKONA_RASTA_LIST: url + "/sifarnik/vrsta-grafikona-rasta-list",

  // PERCENTILE KRIVA
  PERCENTILNA_KRIVA_UZRAST_LIST: urlRepository + "/sifarnik/percentilna-kriva-uzrast-list",
  SIFARNIK_PERCENTILNA_KRIVA_SEARCH: urlRepository + "/sifarnik-mgmt/sifarnik-percentilna-kriva-search",

  // USTANOVA RASPORED
  ZAKAZAN_TERMIN_JASPER_USTANOVA_LIST: urlDocument + "/zakazan-termin-pacijent-jasper-ustanova-mgmt/zakazan-termin-pacijent-jasper-ustanova-list",
  SUPERADMIN_ZAKAZAN_TERMIN_JASPER_USTANOVA_LIST: urlDocument + "/zakazan-termin-pacijent-jasper-ustanova-mgmt/zakazan-termin-pacijent-jasper-ustanova-list/ustanova",

  // USLUGA SPECIJALIZACIJE
  USLUGA_LIST: url + "/usluga-mgmt/usluga-list",

  // NADJIMED ZAKAZIVACI
  NADJIMED_ZAKAZIVACI_LIST: url + "/nadjimed/zakazivac/zakazivac-list",
  NADJIMED_TERMIN: url + "/nadjimed/termin",

  // NADJIMED
  NADJIMED: url + "/nadjimed",

  // NADJIMED LOKACIJE
  NADJIMED_LOKACIJE: url + "/nadjimed/location",

  // NADJIMED USLUGE
  NADJIMED_USLUGE: url + "/nadjimed/procedure",

  // NADJIMED DOKTORI
  NADJIMED_DOKTORI: url + "/nadjimed/doctor",

  // statisticki izvestaji repozitorijum
  IZVESTAJ_REPO_SEARCH: urlRepository + "/izvestaj-mgmt/izvestaj-search",
  KATEGORIJA_IZVESTAJA_REPO_SEARCH: urlRepository + "/izvestaj-mgmt/kategorija-statistickog-izvestaja-search",
  IZVESTAJ_REPO_LIST: urlRepository + "/izvestaj-mgmt/izvestaj-list",
  KATEGORIJA_IZVESTAJA_REPO_LIST: urlRepository + "/izvestaj-mgmt/kategorija-statistickog-izvestaja-list",

  // Kursna lista
  KURSNA_LISTA_FOR_VALUTA: url + "/kursna-lista-mgmt/kursna-lista-list",

  // prodajni paketi
  PRODAJNI_PAKETI_SEARCH: url + "/licenca-mgmt/prodaja-paket-search",
  PERIOD_PLACANJA_ENUM_LIST: url + "/sifarnik/period-placanja-list",
  PRODAJNI_PAKETI_LIST: url + "/licenca-mgmt/prodaja-paket-list",

  // inicijalizacija - radnik
  INICIJALIZACIJA_RADNIK_LIST: url + "/radnik-mgmt/radnik-list/inicijalizacija",

  // Globalna datoteka
  GLOBALNA_DATOTEKA_LIST: url + "/globalna-datoteka/globalna-datoteka-list",

  // Verzija
  VERZIJE: url + "/verzije",
};

export default Endpoint;
