import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import ProtokolReadDto from "../../../../model/protokol/ProtokolReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import ProtokolListLogical from "./ProtokolListLogical";

export default function ProtokolListPage() {
  const { protokolLoading, protokolList, first, tableRows, onPageProtokol, protokolSearchData, fetchData, changeProtokolSearchData, searchProtokolByEnter, vremenskiOkvirList } = ProtokolListLogical();

  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const { rowsPerPageDropdownTemplateMini } = PageDropdownTemplate();

  const openProtokol = (entityOperation: string, id?: number) => {
    const idProtokol = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-protokol/${idProtokol}`, {
      state: { protokolOperation: entityOperation },
    });
  };

  const addSearchToolbar = () => (
    <>
      <Button
        label={Labels.BUTTON_ADD_PROTOKOL}
        tooltip={Labels.PROTOKOL_TITLE_DIALOG_CREATE}
        tooltipOptions={tooltipOptionsBottom}
        className="p-button-success mr-3"
        onClick={() => {
          openProtokol(EntityOperation.CREATE);
        }}
        disabled={!pristup}
      />
      <span className="p-float-label mt-4 sm:mt-4 md:mt-0">
        <InputText
          id="searchString"
          name="searchString"
          className="search-input"
          value={protokolSearchData.searchString ?? ""}
          onChange={changeProtokolSearchData}
          onKeyPress={searchProtokolByEnter}
        />
        <label htmlFor="searchString">{Labels.SEARCH_PROTOKOL}</label>
        <Button tooltip={Labels.PROTOKOL_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
      </span>
    </>
  );

  const crudBodyTemplate = (rowProtokol: ProtokolReadDto) => (
    <div className="flex justify-content-end">
      <Button
        type="button"
        tooltip={Labels.PROTOKOL_TITLE_DIALOG_UPDATE}
        tooltipOptions={tooltipOptionsTop}
        className="p-button-warning mr-2"
        icon="pi pi-pencil"
        onClick={() => rowProtokol && openProtokol(EntityOperation.UPDATE, rowProtokol.id)}
      />
      <Button
        type="button"
        tooltip={Labels.PROTOKOL_TITLE_DIALOG_DELETE}
        tooltipOptions={tooltipOptionsTop}
        className="p-button-danger mr-2"
        icon="pi pi-trash"
        onClick={() => rowProtokol && openProtokol(EntityOperation.DELETE, rowProtokol.id)}
        disabled={!pristup}
      />
    </div>
  );

  const statusRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={vremenskiOkvirList}
        optionLabel="naziv"
        optionValue="naziv"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        className="p-column-filter"
        showClear
      />
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.PROTOKOL_PREFIKS, filter: true, sortrable: true },
    { columnName: Labels.PROTOKOL_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.PROTOKOL_VREMENSKI_OKVIR, filter: true, sortrable: true },
    { columnName: Labels.PROTOKOL_POCETNI_BROJ, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <Toolbar left={addSearchToolbar} />
        {protokolLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            value={protokolList}
            paginator
            first={first}
            filterDisplay="row"
            paginatorTemplate={rowsPerPageDropdownTemplateMini as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageProtokol(e.rows, e.first);
            }}
            //exportFilename={Labels.PROTOKOL_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"prefiks"} header={Labels.PROTOKOL_PREFIKS} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"naziv"} header={Labels.PROTOKOL_NAZIV} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"vremenskiOkvirEnum.naziv"} header={Labels.PROTOKOL_VREMENSKI_OKVIR} className="w-2" sortable filter filterElement={statusRowFilterTemplate} showFilterMenu={false} />
            <Column field={"pocetniBroj"} header={Labels.PROTOKOL_POCETNI_BROJ} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"naredniBroj"} header={Labels.PROTOKOL_NAREDNI_BROJ} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
