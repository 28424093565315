import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useLabels } from "../../../Store";
import VerzijaCreateDto from "../../../model/verzija/VerzijaCreateDto";
import CalendarComponent from "../../administracija/calendar-component/CalendarComponent";
import DialogComponent from "../../dialog-component/DialogComponent";
import { useCreateVerzija } from "./hooks/useCreateVerzija";
import { Checkbox } from "primereact/checkbox";
import InvalidFieldsType from "../../../model/InvalidFieldsType";
import { validateStringEmpty } from "../../../infrastructure/system/Utils";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";

interface DialogCreateVerzijaProps {
  visible: boolean;
  closeDialog: () => void;
}

export default function DialogCreateVerzija({ visible, closeDialog }: DialogCreateVerzijaProps) {
  const Labels = useLabels();

  const [verzija, setVerzija] = useState<VerzijaCreateDto>({
    naziv: "",
    datum: new Date(),
    javno: false,
  });

  const [invalidFields, setInvalidFields] = useState<InvalidFieldsType>();

  const verzijaCreateMutation = useCreateVerzija();

  const handleCreateVerzija = () => {
    if (!validateInput(verzija)) return;
    verzijaCreateMutation.mutate(verzija, {
      onSuccess: () => {
        closeDialog();
      },
    });
  };

  const validateInput = (verzija: VerzijaCreateDto) => {
    let isValid = true;
    if (validateStringEmpty(verzija.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isValid = false;
    }

    return isValid;
  };

  const footer = (
    <div className="flex align-items-center justify-content-end">
      <Button label={Labels.BUTTON_CREATE} severity="success" icon="pi pi-plus" onClick={handleCreateVerzija} />
    </div>
  );

  return (
    <DialogComponent
      header="Nova verzija"
      visible={visible}
      onHide={closeDialog}
      content={
        <div className="flex flex-column gap-3">
          <div className="flex flex-column">
            <label htmlFor="naziv">{Labels.LABEL_NAZIV}*</label>
            <InputText
              autoFocus
              id="naziv"
              className={invalidFields?.naziv ? "p-invalid" : ""}
              value={verzija.naziv}
              onChange={(e) => {
                setVerzija((prev) => ({ ...prev, naziv: e.target.value }));
                setInvalidFields((prev) => ({ ...prev, naziv: false }));
              }}
            />
            {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
          </div>
          <div>
            <label htmlFor="datum">{Labels.DATUM_VERZIJE}*</label>
            <CalendarComponent
              onChange={(e) => {
                if (e.value instanceof Date) {
                  const datum = e.value;
                  setVerzija((prev) => ({ ...prev, datum }));
                }
              }}
              value={verzija.datum}
              appendTo={document.body}
              showButtonBar={false}
            />
          </div>
          <div>
            <label htmlFor="javno" className="mr-2">
              Javno*
            </label>
            <Checkbox inputId="javno" checked={verzija.javno} onChange={(e) => setVerzija((prev) => ({ ...prev, javno: e.checked ?? false }))} />
          </div>
        </div>
      }
      footer={footer}
    />
  );
}
