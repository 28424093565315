import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import UstanovaPodesavanjaLogical from "./UstanovaPodesavanjaLogical";
import DialogUstanovaPodesavanjeAutomatskoRazduzivanje from "./dialog/DialogUstanovaPodesavanjeAutomatskoRazduzivanje";

interface UstanovaPodesavanjaViewProps {
  isDisabled: boolean;
}
export default function UstanovaPodesavanjaView(props: UstanovaPodesavanjaViewProps) {
  const { isDisabled } = props;
  const { pristup } = useContext(AppContext);
  const Labels = useLabels();
  const {
    handleUpdate,
    handleCheckPacijentPodsetnik,
    ustanovaPodesavanja,
    setUstanovaPodesavanja,
    handleCheckAutomatskoRazduzivanje,
    isAutomatskoRazduzivanjeDialogOpen,
    closeAutomatskoRazduzivanjeDialog,
  } = UstanovaPodesavanjaLogical();

  return (
    <div className="flex flex-column justify-content-between">
      <div className="flex hc-gap-3 align-items-center">
        <div className="font-bold col-3">{Labels.USTANOVA_PODESAVANJA_PODSETNIK_PACIJENT}</div>
        <InputSwitch checked={ustanovaPodesavanja.podsetnikPacijentVremeSlanja !== null ? true : false} disabled={!pristup || isDisabled} onChange={handleCheckPacijentPodsetnik} />
        {ustanovaPodesavanja.podsetnikPacijentVremeSlanja !== null && (
          <InputNumber
            value={ustanovaPodesavanja.podsetnikPacijentVremeSlanja ?? null}
            showButtons
            onValueChange={(e) =>
              setUstanovaPodesavanja({
                ...ustanovaPodesavanja!,
                podsetnikPacijentVremeSlanja: e.value ?? null,
              })
            }
            min={0}
            max={23}
            suffix=":00h"
          />
        )}
      </div>
      <div className="flex hc-gap-3 align-items-center">
        <div className="font-bold col-3">{Labels.USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA}</div>
        <InputSwitch
          checked={ustanovaPodesavanja.automatskoRazduzivanjePlaniranihUsluga}
          disabled={!pristup || isDisabled}
          onChange={(e) => {
            handleCheckAutomatskoRazduzivanje(e.value ?? false);
          }}
        />
      </div>
      <div className="flex justify-content-end">{!isDisabled && <Button label={Labels.BUTTON_SAVE} disabled={!pristup} className="p-button-success bottom-0 right-0" onClick={handleUpdate} />}</div>
      <DialogUstanovaPodesavanjeAutomatskoRazduzivanje
        ustanovaPodesavanja={ustanovaPodesavanja}
        setUstanovaPodesavanja={setUstanovaPodesavanja}
        isAutomatskoRazduzivanjeDialogOpen={isAutomatskoRazduzivanjeDialogOpen}
        closeAutomatskoRazduzivanjeDialog={closeAutomatskoRazduzivanjeDialog}
      />
    </div>
  );
}
