import { PickListChangeEvent } from "primereact/picklist";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { TipKontaktaController } from "../../../controllers/kontakt/TipKontaktaController";

import { OrganizacionaJedinicaController } from "../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import TipKontaktaOrganizacionaJedinicaReadDto from "../../../model/kontakt/TipKontaktaOrganizacionaJedinicaReadDto";

import { AppContext, useLabels } from "../../../Store";
import OrganizacionaJedinicaReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";

export interface TipKontaktaOrganizacionaJedinicaHolder {
  organizacionaJedinica: OrganizacionaJedinicaReadDto;
}
interface TipKontaktaOrganizacionaJedinicaLogical {
  tipKontaktaOrganizacionaJedinicaList: Array<TipKontaktaOrganizacionaJedinicaReadDto>;
  organizacionaJedinicaList: Array<TipKontaktaOrganizacionaJedinicaHolder>;
  onChangeTipKontaktaOrganizacionaJedinica: (event: PickListChangeEvent) => Promise<void>;
  isBlocked: boolean;
  isLoading: boolean;
}

interface CrudLogicalPropType {
  orgJedOperation?: string;
  tipKontaktaNaziv?: string;
}
interface UseParamsType {
  tipKontaktaId?: string;
}
export default function CrudTipKontaktaOrganizacionaJedinicaLogical(props: CrudLogicalPropType): TipKontaktaOrganizacionaJedinicaLogical {
  const { showMessage } = useContext(AppContext);
  const { orgJedOperation, tipKontaktaNaziv } = props;
  const [tipKontaktaOrganizacionaJedinicaList, setTipKontaktaOrganizacionaJedinicaList] = useState<Array<TipKontaktaOrganizacionaJedinicaReadDto>>([]);
  const [organizacionaJedinicaList, setOrganizacionaJedinicaList] = useState<Array<TipKontaktaOrganizacionaJedinicaHolder>>([]);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const tipKontaktaId: number = Number(useParams<keyof UseParamsType>()["tipKontaktaId"]);
  const { axiosGetTipKontaktaOrganizacionaJedinicaList, axiosCreateTipKontaktaOrganizacionaJedinica, axiosDeleteTipKontaktaOrganizacionaJedinica } = TipKontaktaController();
  const { axiosSearchOrganizacionaJedinica } = OrganizacionaJedinicaController();
  const prevTipKontaktaOperation = useRef<string | undefined>();
  const prevTipKontaktaId = useRef<number | undefined>();
  const postLogHighLevel = useLogHighLevel();

  const Labels = useLabels();

  const getOrganizacionaJedinicaList = useCallback(
    (tipKontaktaOrganizacionaJedinicaList: Array<TipKontaktaOrganizacionaJedinicaReadDto>) => {
      setIsLoading(true);
      axiosSearchOrganizacionaJedinica()
        .then((res: any) => {
          let organizacionaJedinicaListTemp: Array<TipKontaktaOrganizacionaJedinicaHolder> = [];
          const allRadnik = res.data.data;
          allRadnik.forEach((organizacionaJedinica: OrganizacionaJedinicaReadDto) => {
            if (
              tipKontaktaOrganizacionaJedinicaList.filter((tipKontaktaOrganizacionaJedinica: TipKontaktaOrganizacionaJedinicaReadDto) => {
                return tipKontaktaOrganizacionaJedinica.organizacionaJedinica.id === organizacionaJedinica.id;
              }).length === 0
            ) {
              organizacionaJedinicaListTemp.push({ organizacionaJedinica: organizacionaJedinica });
            }
          });
          setOrganizacionaJedinicaList(organizacionaJedinicaListTemp);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosSearchOrganizacionaJedinica, setIsLoading, showMessage]
  );

  const getTipKontaktaOrganizacionaJedinica = useCallback(() => {
    setIsLoading(true);
    axiosGetTipKontaktaOrganizacionaJedinicaList(tipKontaktaId)
      .then((res: any) => {
        setTipKontaktaOrganizacionaJedinicaList(res.data.data);
        getOrganizacionaJedinicaList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setIsLoading(false);
      });
  }, [axiosGetTipKontaktaOrganizacionaJedinicaList, getOrganizacionaJedinicaList, tipKontaktaId, setIsLoading, showMessage]);

  const fetchData = useCallback(() => {
    getTipKontaktaOrganizacionaJedinica();
  }, [getTipKontaktaOrganizacionaJedinica]);

  useEffect(() => {
    if (tipKontaktaId && prevTipKontaktaId.current !== tipKontaktaId && prevTipKontaktaOperation.current !== orgJedOperation && orgJedOperation !== EntityOperation.CREATE) {
      prevTipKontaktaOperation.current = orgJedOperation;
      prevTipKontaktaId.current = tipKontaktaId;
      fetchData();
    }
  }, [fetchData, orgJedOperation, tipKontaktaId]);

  const onChangeTipKontaktaOrganizacionaJedinica = async (event: PickListChangeEvent) => {
    if (isBlocked || isLoading) {
      return;
    }
    setIsBlocked(true);
    if (tipKontaktaOrganizacionaJedinicaList.length < event.target.length) {
      await Promise.all(
        event.target.map(async (tipKontaktaOrganizacionaJedinica: TipKontaktaOrganizacionaJedinicaHolder) => {
          if (
            !tipKontaktaOrganizacionaJedinicaList
              .map((element: TipKontaktaOrganizacionaJedinicaReadDto | undefined) => {
                return element?.organizacionaJedinica;
              })
              .includes(tipKontaktaOrganizacionaJedinica.organizacionaJedinica)
          ) {
            const organizacionaJedinicaId = tipKontaktaOrganizacionaJedinica.organizacionaJedinica?.id;
            await axiosCreateTipKontaktaOrganizacionaJedinica(tipKontaktaId, { kontaktTip: { id: tipKontaktaId }, organizacionaJedinica: { id: organizacionaJedinicaId } })
              .then((res: any) => {
                postLogHighLevel(
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA +
                    tipKontaktaNaziv +
                    Labels.LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA_1 +
                    tipKontaktaOrganizacionaJedinica?.organizacionaJedinica?.naziv
                );
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
              });
          }
        })
      );
      setTipKontaktaOrganizacionaJedinicaList(event.target);
      setOrganizacionaJedinicaList(organizacionaJedinicaList.filter((element: TipKontaktaOrganizacionaJedinicaHolder | undefined) => !event.target.includes(element)));
    } else if (organizacionaJedinicaList.length < event.source.length) {
      await Promise.all(
        event.source.map(async (tipKontaktaOrganizacionaJedinica: TipKontaktaOrganizacionaJedinicaHolder) => {
          if (!organizacionaJedinicaList.includes(tipKontaktaOrganizacionaJedinica)) {
            const organizacionaJedinicaId = tipKontaktaOrganizacionaJedinica.organizacionaJedinica?.id;
            await axiosDeleteTipKontaktaOrganizacionaJedinica(tipKontaktaId, organizacionaJedinicaId)
              .then((res: any) => {
                postLogHighLevel(
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA +
                    tipKontaktaNaziv +
                    Labels.LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA_2 +
                    tipKontaktaOrganizacionaJedinica?.organizacionaJedinica?.naziv
                );
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
              });
          }
        })
      );
      setOrganizacionaJedinicaList(
        organizacionaJedinicaList.concat(tipKontaktaOrganizacionaJedinicaList.filter((element: TipKontaktaOrganizacionaJedinicaHolder | undefined) => !event.target.includes(element)))
      );
      setTipKontaktaOrganizacionaJedinicaList(event.target);
    }
    setIsBlocked(false);
  };

  return {
    onChangeTipKontaktaOrganizacionaJedinica,
    tipKontaktaOrganizacionaJedinicaList,
    organizacionaJedinicaList,
    isBlocked,
    isLoading,
  };
}
