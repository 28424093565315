import { ProgressSpinner } from "primereact/progressspinner";
import { useContext } from "react";
import { InitialStateType } from "../hooks/initialState";
import { ReducerType } from "../hooks/reducer";
import { AppContext, useLabels } from "../../../Store";
import { useNavigate } from "react-router-dom";
import EnumInicijalizacijaKorak from "../../../infrastructure/system/EnumInicijalizacijaKorak";
import { useInicijalizacijaEnumList } from "../../../infrastructure/system/hooks/enum/useInicijalizacijaEnumList";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import RadnikUstanovaReadDto from "../../../model/radnik/RadnikUstanovaReadDto";

interface ProgressOverlayPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerType>;
}

export default function ProgressOverlay({ state, dispatch }: ProgressOverlayPropsType) {
  const { authData, updateAuthData } = useContext(AppContext);
  const Labels = useLabels();
  const navigate = useNavigate();
  const { inicijalizacijaEnumList } = useInicijalizacijaEnumList();
  const initializedStep = inicijalizacijaEnumList?.filter((item: EnumBaseReadDto) => item.sifra === EnumInicijalizacijaKorak.INICIJALIZOVANA);

  const findAndUpdateInitializationStep = (originalData: Array<RadnikUstanovaReadDto>, ustanovaId: number) => {
    const updatedData = originalData?.map((ustanova: RadnikUstanovaReadDto) =>
      ustanova.ustanova.id === ustanovaId ? { ...ustanova, ustanova: { ...ustanova.ustanova, inicijalizacijaKorak: initializedStep[0] } } : ustanova
    );
    return updatedData;
  };

  const onClickAction = () => {
    dispatch({ type: "set_show_finishing_initialization_process_overlay", data: false });
    updateAuthData({
      ...authData,
      token: authData!.token,
      currentRadnik: {
        ...authData!.currentRadnik,
        ustanovaPoslednjaIzabrana: {
          ...authData!.currentRadnik.ustanovaPoslednjaIzabrana,
          inicijalizacijaKorak: initializedStep[0],
        },
        radnikUstanovaList: findAndUpdateInitializationStep(authData?.currentRadnik?.radnikUstanovaList!, authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.id!),
      },
    });
    navigate("/");
  };

  return (
    <div className="progress-overlay" onClick={() => !state?.isFinishingInitializationProcess && onClickAction()}>
      {state?.isFinishingInitializationProcess && (
        <>
          <div className="mb-5">
            <ProgressSpinner strokeWidth={"7px"} />
          </div>
          <div className="white">{Labels.LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_IN_PROGRESS_TEXT_1}</div>
          <div className="white">{Labels.LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_IN_PROGRESS_TEXT_2}</div>
        </>
      )}
      {!state?.isFinishingInitializationProcess && (
        <>
          <div className="mb-5">
            <i className="pi pi-check-circle" />
          </div>
          <div className="white">{Labels.LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_FINISHED_TEXT_1}</div>
          <div className="white">{Labels.LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_FINISHED_TEXT_2}</div>
        </>
      )}
    </div>
  );
}
