import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { EnumController } from "../../../controllers/enum/EnumController";
import { PacijentPoljeController } from "../../../controllers/pacijent-polje/PacijentPoljeController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import PacijentPoljeReadDto from "../../../model/pacijent-polje/PacijentPoljeReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import { AppContext, useLabels } from "./../../../Store";

interface PacijentPoljeListLogicalType {
  pacijentPoljeList: Array<PacijentPoljeReadDto>;
  first: number;
  tableRows: number;
  changePacijentPoljeSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  pacijentPoljeSearchData: SearchBaseDto;
  fetchData: () => void;
  searchPacijentPoljeByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  onPagePacijentPolje: (rows: number, first: number) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  pacijentPoljeLoading: boolean;
  sifarnikVrstaGrafikonaRasta: Array<EnumBaseReadDto>;
}

export default function PacijentPoljeListLogical(): PacijentPoljeListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [pacijentPoljeList, setPacijentPoljeList] = useState<Array<PacijentPoljeReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [pacijentPoljeSearchData, setPacijentPoljeSearchData] = useState<SearchBaseDto>({});
  const [pacijentPoljeLoading, setPacijentPoljeLoading] = useState<boolean>(true);
  const [sifarnikVrstaGrafikonaRasta, setSifarnikVrstaGrafikonaRasta] = useState<Array<EnumBaseReadDto>>([]);

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.PACIJENT_POLJE_LIST,
    },
  ];
  const { axiosSearchPacijentPolje } = PacijentPoljeController();
  const { axiosGetVrstaGrafikonaRastaList } = EnumController();

  useEffectOnce(() => {
    fetchData();
    getSifarnikVrstaGrafikonaRasta();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setPacijentPoljeLoading(true);
    axiosSearchPacijentPolje(pacijentPoljeSearchData)
      .then((res: any) => {
        setPacijentPoljeList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((pacijentPolje: PacijentPoljeReadDto) => {
          exportList.push({
            [Labels.PACIJENT_POLJE_TIP]: pacijentPolje.poljeTip.sifra,
            [Labels.PACIJENT_POLJE_SIFRA]: pacijentPolje.sifra,
            [Labels.PACIJENT_POLJE_NAZIV]: pacijentPolje.naziv,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setPacijentPoljeLoading, startTime);
      });
  };

  const changePacijentPoljeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setPacijentPoljeSearchData({
      ...pacijentPoljeSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchPacijentPoljeByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPagePacijentPolje = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const getSifarnikVrstaGrafikonaRasta = () => {
    axiosGetVrstaGrafikonaRastaList()
      .then((response: any) => {
        setSifarnikVrstaGrafikonaRasta(response.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return {
    pacijentPoljeList,
    first,
    tableRows,
    changePacijentPoljeSearchData,
    pacijentPoljeSearchData,
    fetchData,
    searchPacijentPoljeByEnter,
    onPagePacijentPolje,
    exportData,
    breadCrumbItems,
    pacijentPoljeLoading,
    sifarnikVrstaGrafikonaRasta,
  };
}
