import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../../Store";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import { DATE_FORMAT_2, formatDate, tooltipOptionsBottom } from "../../../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../../../app/skeleton/SkeletonInputItem";
import CalendarComponent from "../../../calendar-component/CalendarComponent";
import CrudUstanovaStorageLimitLogical from "./CrudUstanovaStorageLimitLogical";

export default function CrudUstanovaStorageLimitView() {
  const {
    breadCrumbItems,
    ustanovaStorageLimitChange,
    setUstanovaStorageLimitChange,
    isDisabled,
    ustanovaStorageLimitOperation,
    onCreate,
    onUpdate,
    onCancel,
    storageLimitList,
    ustanovaStorageLimitLoading,
    storageLimitListLoading,
    onDelete,
    vaziOdRef,
    vaziDoRef,
  } = CrudUstanovaStorageLimitLogical();
  const noSkeleton: boolean = ustanovaStorageLimitOperation === EntityOperation.CREATE || !ustanovaStorageLimitLoading;

  const Labels = useLabels();

  const leftContentsToolbar = () => (
    <>
      {ustanovaStorageLimitOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.LICENCNI_MODEL_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {ustanovaStorageLimitOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!noSkeleton}
          icon="pi pi-pencil"
          tooltip={Labels.STORAGE_LIMIT_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {ustanovaStorageLimitOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!noSkeleton}
          icon="pi pi-trash"
          tooltip={Labels.LICENCNI_MODEL_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
          <TabPanel header={Labels.STORAGE_LIMIT}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>
                    {Labels.STORAGE_LIMIT_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>

                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton && !storageLimitListLoading ? (
                    <Dropdown
                      disabled={ustanovaStorageLimitOperation !== EntityOperation.CREATE}
                      value={ustanovaStorageLimitChange?.storageLimit ? ustanovaStorageLimitChange?.storageLimit?.id : null}
                      options={storageLimitList}
                      optionLabel={"naziv"}
                      optionValue={"id"}
                      onChange={(e) => {
                        setUstanovaStorageLimitChange({
                          ...ustanovaStorageLimitChange!,
                          storageLimit: { id: e.target.value },
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>
                    {Labels.VAZI_OD}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <CalendarComponent
                      ref={vaziOdRef}
                      disabled={ustanovaStorageLimitOperation !== EntityOperation.CREATE}
                      showButtonBar={false}
                      name="vaziOd"
                      minDate={new Date()}
                      value={ustanovaStorageLimitChange?.vaziOd}
                      onChange={(e: CalendarChangeEvent) => {
                        setUstanovaStorageLimitChange({
                          ...ustanovaStorageLimitChange!,
                          vaziOd: formatDate(e.value as Date, DATE_FORMAT_2)!,
                        });
                        if (e.target.value) vaziOdRef?.current?.hide();
                      }}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>{Labels.VAZI_DO}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <CalendarComponent
                      ref={vaziDoRef}
                      disabled={isDisabled}
                      showButtonBar={false}
                      name="vaziDo"
                      minDate={new Date()}
                      value={ustanovaStorageLimitChange?.vaziDo}
                      onChange={(e: CalendarChangeEvent) => {
                        setUstanovaStorageLimitChange({
                          ...ustanovaStorageLimitChange!,
                          vaziDo: formatDate(e.value as Date, DATE_FORMAT_2),
                        });
                        if (e.target.value) vaziDoRef?.current?.hide();
                      }}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
