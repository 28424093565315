import moment from "moment";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { KontaktController } from "../../../../controllers/kontakt/KontaktController";
import { SifarnikController } from "../../../../controllers/sifarnik/SifarnikController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { RETRY_WITH_RESPONSE_CODE, checkEmpty, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import KontaktUtrosakReadDto from "../../../../model/kontakt/KontaktUtrosakReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import SifarnikProizvodaReadDto from "../../../../model/sifarnik/sifarnik-proizvoda/SifarnikProizvodaReadDto";

interface KontaktUtrosakLogicalType {
  id: number;
  dataLoading: boolean;
  isOpenUtrosakDialog: boolean;
  closeUtrosakDialog: () => void;
  kontaktUtrosakChange: KontaktUtrosakReadDto | undefined;
  setKontaktUtrosakChange: any;
  onCreateUtrosak: (unsafelyExecute?: boolean) => void;
  onUpdateUtrosak: (unsafelyExecute?: boolean) => void;
  onDeleteUtrosak: () => void;
  sifarnikProizvodaList?: Array<SifarnikProizvodaReadDto> | undefined;
  setIsOpenUtrosakDialog: React.Dispatch<React.SetStateAction<boolean>>;
  searchProizvod: (e: AutoCompleteCompleteEvent) => void;
  utrosciLoading: boolean;
  isOpenConfirmationDialog: boolean;
  closeConfirmationDialog: () => void;
}

interface UseParamsType {
  id?: string;
}

export default function KontaktUtrosakLogical(
  pacijent: PacijentReadDto,
  kontaktUtrosakList: Array<KontaktUtrosakReadDto> | undefined,
  setKontaktUtrosakList: React.Dispatch<React.SetStateAction<Array<KontaktUtrosakReadDto> | undefined>>
): KontaktUtrosakLogicalType {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { axiosGetUtrosakListByKontaktID, axiosUpdateUtrosak, axiosDeleteUtrosak, axiosCreateUtrosak } = KontaktController();
  const { axiosSearchSifarnikProizvod } = SifarnikController();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [isOpenUtrosakDialog, setIsOpenUtrosakDialog] = useState<boolean>(false);
  const [kontaktUtrosakChange, setKontaktUtrosakChange] = useState<KontaktUtrosakReadDto>();
  const id = Number(useParams<keyof UseParamsType>()["id"]);
  const [sifarnikProizvodaList, setSifarnikProizvodaList] = useState<Array<SifarnikProizvodaReadDto>>();
  const [utrosciLoading, setUtrosciLoading] = useState<boolean>(false);
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState<boolean>(false);

  const postLogHighLevel = useLogHighLevel();
  const validateUtrosak = (utrosak?: KontaktUtrosakReadDto) => {
    if (checkEmpty(utrosak?.utrosenaKolicina)) {
      showMessage(MessageType.ERROR, Labels.UTROSAK_BROJ_JE_OBAVEZAN_PODATAK);
      return false;
    }
    return true;
  };

  useEffectOnce(() => {
    fetchData();
  });

  const openConfirmationDialog = () => {
    setIsOpenConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setIsOpenConfirmationDialog(false);
  };

  const fetchData = async () => {
    if (id && !kontaktUtrosakList) {
      let startTime = moment(new Date());
      setDataLoading(true);
      axiosGetUtrosakListByKontaktID(id)
        .then((responseUtrosakList) => {
          setKontaktUtrosakList(responseUtrosakList.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setDataLoading, startTime);
        });
    }
  };

  const onCreateUtrosak = (unsafelyExecute?: boolean) => {
    if (!validateUtrosak(kontaktUtrosakChange)) {
      return;
    }
    if (kontaktUtrosakChange) {
      setShowBlockUI(true);
      axiosCreateUtrosak(id, kontaktUtrosakChange, unsafelyExecute)
        .then((response) => {
          showMessage(MessageType.SUCCESS, Labels.UTROSAK_USPESNO_KREIRAN);
          kontaktUtrosakList && setKontaktUtrosakList([...kontaktUtrosakList, response.data.data]);
          if (isOpenConfirmationDialog) closeConfirmationDialog();
          closeUtrosakDialog();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_UTROSAK_1 +
              kontaktUtrosakChange.sifarnikProizvoda.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_UTROSAK_1 +
              kontaktUtrosakChange.utrosenaKolicina +
              ".",
            pacijent.id
          );
        })
        .catch((error: any) => {
          if (error.response?.data?.code === RETRY_WITH_RESPONSE_CODE) {
            openConfirmationDialog();
          } else {
            handleAxiosCallError(showMessage, error);
          }
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const getPunNaziv = (proizvod: SifarnikProizvodaReadDto) => {
    return `${proizvod.sifra} - ${proizvod.naziv.substring(0, 100)}${proizvod.naziv.length > 100 ? "..." : ""}`;
  };

  const searchProizvod = (e: AutoCompleteCompleteEvent) => {
    if (e.query.length > 2) {
      let startTime = moment(new Date());
      setUtrosciLoading(true);
      axiosSearchSifarnikProizvod({ searchString: e.query })
        .then((res: any) => {
          setSifarnikProizvodaList(
            res.data.data.map((proizvod: SifarnikProizvodaReadDto) => ({
              ...proizvod,
              punNaziv: getPunNaziv(proizvod),
            }))
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setUtrosciLoading, startTime);
        });
    }
  };

  const onUpdateUtrosak = (unsafelyExecute?: boolean) => {
    if (!validateUtrosak(kontaktUtrosakChange)) {
      return;
    }
    if (kontaktUtrosakChange) {
      setShowBlockUI(true);
      axiosUpdateUtrosak(id, kontaktUtrosakChange, unsafelyExecute)
        .then((response) => {
          showMessage(MessageType.SUCCESS, Labels.UTROSAK_USPESNO_IZMENJEN);
          setKontaktUtrosakList(kontaktUtrosakList?.map((u: KontaktUtrosakReadDto) => (u.id === kontaktUtrosakChange?.id ? kontaktUtrosakChange : u)));
          if (isOpenConfirmationDialog) closeConfirmationDialog();
          closeUtrosakDialog();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_UTROSAK_1 +
              kontaktUtrosakChange.sifarnikProizvoda.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_UTROSAK_1 +
              kontaktUtrosakChange.utrosenaKolicina +
              ".",
            pacijent.id
          );
        })
        .catch((error: any) => {
          if (error.response?.data?.code === RETRY_WITH_RESPONSE_CODE) {
            openConfirmationDialog();
          } else {
            handleAxiosCallError(showMessage, error);
          }
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDeleteUtrosak = () => {
    if (kontaktUtrosakChange) {
      setShowBlockUI(true);
      axiosDeleteUtrosak(id, kontaktUtrosakChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.UTROSAK_USPESNO_OBRISAN);
          setKontaktUtrosakList(kontaktUtrosakList?.filter((ku) => ku.id !== kontaktUtrosakChange.id));
          closeUtrosakDialog();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_UTROSAK_1 +
              kontaktUtrosakChange.sifarnikProizvoda.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_UTROSAK_1 +
              kontaktUtrosakChange.utrosenaKolicina +
              ".",
            pacijent.id
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const closeUtrosakDialog = () => {
    setIsOpenUtrosakDialog(false);
  };

  return {
    id,
    dataLoading,
    isOpenUtrosakDialog,
    searchProizvod,
    closeUtrosakDialog,
    kontaktUtrosakChange,
    setKontaktUtrosakChange,
    onCreateUtrosak,
    onUpdateUtrosak,
    onDeleteUtrosak,
    sifarnikProizvodaList,
    setIsOpenUtrosakDialog,
    utrosciLoading,
    isOpenConfirmationDialog,
    closeConfirmationDialog,
  };
}
