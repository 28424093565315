import { Button } from "primereact/button";
import { useLabels } from "../../../../Store";
import GrupnoObavestenjeSimpleReadDto from "../../../../model/obavestenja-pacijenti/GrupnoObavestenjeSimpleReadDto";
import DialogComponent from "../../../dialog-component/DialogComponent";

interface DialogSendObavestenjePropsType {
  obavestenje: GrupnoObavestenjeSimpleReadDto;
  isOpenConfirmationDialog: boolean;
  setIsOpenConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onSend: (obavestenje: GrupnoObavestenjeSimpleReadDto) => void;
}

export default function DialogSendObavestenje(props: DialogSendObavestenjePropsType) {
  const { obavestenje, isOpenConfirmationDialog, setIsOpenConfirmationDialog, onSend } = props;
  const Labels = useLabels();

  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-content-center">
        <Button
          label={Labels.BUTTON_CANCEL}
          icon="pi pi-times"
          className="button-danger mr-5 p-button-outlined"
          onClick={() => {
            setIsOpenConfirmationDialog(false);
          }}
        />
        {obavestenje?.ukupnoPacijenata > 0 && <Button label={Labels.POTVRDI} tooltip={Labels.POTVRDI} icon="pi pi-check" onClick={() => onSend(obavestenje)} />}
      </div>
    );
  };

  return (
    <DialogComponent
      header={""}
      visible={isOpenConfirmationDialog}
      footer={renderFooter}
      onHide={() => {
        setIsOpenConfirmationDialog(false);
      }}
      content={
        <div className="text-center">
          {obavestenje?.ukupnoPacijenata > 0 && (
            <>
              <span className="font-bold">{Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_CONFIRM_MESSAGE}</span>
              <div className="mb-3">
                {Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_BROJ_PRIMALACA}: <span className="poslato-count font-bold">{obavestenje?.ukupnoPacijenata}</span>
              </div>
              <div>{Labels.LABEL_OBAVESTENJE_ZA_PACIJENTE_SEND_NOTE}</div>
            </>
          )}
          {obavestenje?.ukupnoPacijenata === 0 && <span className="font-bold">{Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE_NO_PATIENTS}</span>}
        </div>
      }
    />
  );
}
