import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import KategorijaStatistickogIzvestajReadDto from "../../model/izvestaj/kategorija/KategorijaStatistickogIzvestajaReadDto";

interface KategorijaStatistickogIzvestajaControllerType {
  axiosSearchKategorija: (obavestenjeSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetKategorija: (kategorijaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateKategorija: (kategorijaChange: KategorijaStatistickogIzvestajReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteKategorija: (kategorijaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateKategorija: (kategorijaChange: KategorijaStatistickogIzvestajReadDto) => Promise<AxiosResponse<any>>;
}

export function KategorijaStatistickogIzvestajaController(): KategorijaStatistickogIzvestajaControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchKategorija = (kategorijaSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.KATEGORIJA_IZVESTAJA_SEARCH, axiosConfig(authData!.token, kategorijaSearchData));
  };

  const axiosGetKategorija = (kategorijaId: number) => {
    return axios.get(`${Endpoint.KATEGORIJA_IZVESTAJA_LIST}/${kategorijaId}`, axiosConfig(authData!.token));
  };

  const axiosCreateKategorija = (kategorijaChange: KategorijaStatistickogIzvestajReadDto) => {
    return axios.post(Endpoint.KATEGORIJA_IZVESTAJA_LIST, kategorijaChange ? kategorijaChange : {}, axiosConfig(authData!.token));
  };

  const axiosDeleteKategorija = (kategorijaId: number) => {
    return axios.delete(`${Endpoint.KATEGORIJA_IZVESTAJA_LIST}/${kategorijaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateKategorija = (kategorijaChange: KategorijaStatistickogIzvestajReadDto) => {
    return axios.put(`${Endpoint.KATEGORIJA_IZVESTAJA_LIST}/${kategorijaChange.id}`, kategorijaChange, axiosConfig(authData!.token));
  };

  return {
    axiosSearchKategorija,
    axiosGetKategorija,
    axiosCreateKategorija,
    axiosDeleteKategorija,
    axiosUpdateKategorija,
  };
}
