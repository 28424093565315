import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import { useLabels } from "../../../Store";
import { DATE_TIME_FORMAT, isDateTimeFormatValid, tooltipOptionsBottom, tooltipOptionsRight } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import DialogComponent from "../../dialog-component/DialogComponent";
import CalendarComponent from "../calendar-component/CalendarComponent";
import LogListLogical from "./LogListLogical";

export default function LogListPage() {
  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const {
    showResults,
    setShowResults,
    selectedTableColumns,
    tableColumns,
    onColumnToggle,
    first,
    tableRows,
    onPageFormTable,
    logList,
    searchLog,
    searchLogObject,
    setSearchLogObject,
    onChangeCalendar,
    searchLogLoading,
    searchLogPageReady,
    totalRecords,
    readLog,
    closeLogDialog,
    log,
    setFirst,
    datumOdRef,
    datumDoRef,
  } = LogListLogical();
  const columnsProperty: Array<SkeletonTableColumnProperty> = [{ columnName: Labels.LOG_METADATA_HOSTNAME, bodyClassName: "p-2", headerClassName: "p-2" }];

  const columnComponents =
    selectedTableColumns &&
    selectedTableColumns?.map((col: any) => {
      columnsProperty.push({ columnName: col.header, bodyClassName: "p-2 overflow-hidden hc-text-overflow-ellipsis", headerClassName: "p-2 overflow-hidden" });
      return <Column bodyClassName="p-2 overflow-hidden hc-text-overflow-ellipsis" headerClassName="p-2 overflow-hidden" key={col.field} field={col.field} header={col.header} />;
    });
  const dt = useRef<any>(null);
  const rightContents = () => <Button label={Labels.BUTTON_CLOSE} className="p-button-outlined p-button-tertiary" onClick={closeLogDialog} autoFocus />;
  const footer = (
    <div className="col-12">
      <Toolbar right={rightContents} />
    </div>
  );
  return (
    <div className="layout-pretraga-pacijenata">
      <Panel>
        <Panel>
          <div className="flex flex-wrap md:col-12">
            <div className="col-12 xl:col-3 lg:col-3 md:col-3 sm:col-12"></div>
            <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
              <div className="flex flex-column">
                <div>
                  <div className="flex flex-wrap">
                    <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
                      <CalendarComponent
                        ref={datumOdRef}
                        name="startTime"
                        showTime={true}
                        value={searchLogObject?.startTime}
                        onChange={onChangeCalendar}
                        onInput={(e) => {
                          if (isDateTimeFormatValid(e.currentTarget.value)) datumOdRef?.current?.hide();
                        }}
                        placeholder={Labels.PLACEHOLDER_DATUM_VREME_FORMAT}
                      />
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
                      <CalendarComponent
                        ref={datumDoRef}
                        name="endTime"
                        showTime={true}
                        value={searchLogObject?.endTime}
                        onChange={onChangeCalendar}
                        onInput={(e) => {
                          if (isDateTimeFormatValid(e.currentTarget.value)) datumDoRef?.current?.hide();
                        }}
                        placeholder={Labels.PLACEHOLDER_DATUM_VREME_FORMAT}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
                      <InputText
                        className="inputfield w-full"
                        value={searchLogObject?.requestMethod ?? ""}
                        onChange={(e) => {
                          setSearchLogObject({ ...searchLogObject, requestMethod: e.target.value });
                        }}
                        placeholder={Labels.LOG_RESPONSE_METHOD}
                      />
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
                      <InputNumber
                        className="inputfield w-full"
                        value={searchLogObject?.statusCode ?? undefined}
                        onChange={(e) => {
                          setSearchLogObject({ ...searchLogObject, statusCode: e.value != null ? e.value : undefined });
                        }}
                        placeholder={Labels.LOG_RESPONSE_STATUS_CODE}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12">
                      {searchLogPageReady ? (
                        <Skeleton shape="rectangle" width="4.5rem" height="2.5rem" />
                      ) : (
                        <Button
                          label={Labels.LABEL_PATIENT_SEARCH}
                          tooltip={Labels.LABEL_TOOLTIP_LOG_SEARCH}
                          tooltipOptions={tooltipOptionsBottom}
                          className="p-button-success"
                          onClick={() => {
                            searchLogObject.page = 0;
                            searchLogObject.size = tableRows;
                            searchLog(searchLogObject);
                            setFirst(0);
                            setShowResults(true);
                          }}
                        />
                      )}
                      {showResults && (
                        <Button
                          icon="pi pi-times"
                          tooltip={Labels.LABEL_TOOLTIP_PATIENT_SEARCH_ERASE}
                          tooltipOptions={tooltipOptionsBottom}
                          className="p-button-outlined ml-2"
                          onClick={() => {
                            setShowResults(false);
                            setSearchLogObject({});
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 xl:col-3 lg:col-3 md:col-3 sm:col-12"></div>
          </div>
        </Panel>
        {showResults && (
          <Panel className="mt-2">
            {!searchLogLoading && (
              <MultiSelect
                tooltip={Labels.LABEL_TOOLTIP_CHOOSE_COLUMN}
                tooltipOptions={tooltipOptionsRight}
                value={selectedTableColumns}
                options={tableColumns}
                fixedPlaceholder
                optionLabel="header"
                onChange={onColumnToggle}
                placeholder={Labels.LABEL_SHOW_COLUMNS}
                filter
                className="multiselect-custom mb-2"
                showClear
                maxSelectedLabels={0}
                emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              />
            )}
            <div className="card">
              {searchLogLoading ? (
                <SkeletonTable dataTableColumnsProperty={columnsProperty} />
              ) : (
                <DataTable
                  ref={dt}
                  value={logList}
                  paginator
                  first={first}
                  rows={tableRows}
                  lazy
                  totalRecords={totalRecords}
                  onPage={(e) => {
                    onPageFormTable(e.rows, e.first);
                    searchLogObject.page = e.page;
                    searchLogObject.size = e.rows;
                    searchLog(searchLogObject);
                  }}
                  onRowClick={(e) => {
                    readLog(e.data.id);
                  }}
                  paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
                  emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                  rowHover
                  stripedRows
                >
                  <Column bodyClassName="p-2" headerClassName="p-2" field={"metadataHostname"} header={Labels.LOG_METADATA_HOSTNAME} />
                  {columnComponents}
                </DataTable>
              )}
            </div>
          </Panel>
        )}

        <DialogComponent
          header={Labels.LABEL_DETAILS}
          visible={log.id !== undefined}
          onHide={closeLogDialog}
          footer={footer}
          className="w-8"
          content={
            <div>
              <div className="flex mt-2">
                <div className="col-6 ">
                  <span className="p-float-label">
                    <InputText id="metadataHostname" className="col-12" value={`${log.metadataHostname}`} aria-disabled />
                    <label htmlFor="metadataHostname">{`${Labels.LOG_METADATA_HOSTNAME}: `}</label>
                  </span>
                </div>
                <div className="col-6 ">
                  <span className="p-float-label">
                    <InputText id="metadataPort" className="col-12" value={`${log.metadataPort}`} aria-disabled />
                    <label htmlFor="metadataPort">{`${Labels.LOG_METADATA_PORT}: `}</label>
                  </span>
                </div>
              </div>
              <div className="flex mt-2">
                <div className=" col-6">
                  <span className="p-float-label">
                    <InputText id="requestClientIp" className="col-12" value={`${log.requestClientIp}`} aria-disabled />
                    <label htmlFor="requestClientIp">{`${Labels.LOG_REQUEST_CLIENT_IP}: `}</label>
                  </span>
                </div>
                <div className="col-6">
                  <span className="p-float-label">
                    <InputText id="requestQueryString" className="col-12" value={`${log.requestQueryString}`} aria-disabled />
                    <label htmlFor="requestQueryString">{`${Labels.LOG_REQUEST_QUERY_STRING}: `}</label>
                  </span>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="col-6">
                  <span className="p-float-label">
                    <InputText id="requestTimestamp" className="col-12" value={moment(log?.requestTimestamp).format(DATE_TIME_FORMAT)} aria-disabled />
                    <label htmlFor="requestTimestamp">{`${Labels.LOG_REQUEST_TIMESTAMP}: `}</label>
                  </span>
                </div>
                <div className="col-6">
                  <span className="p-float-label">
                    <InputText id="requestXForwardedFor" className="col-12" value={`${log.requestXForwardedFor}`} aria-disabled />
                    <label htmlFor="requestXForwardedFor">{`${Labels.LOG_REQUEST_X_FORWARDED}: `}</label>
                  </span>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="col-6">
                  <span className="p-float-label">
                    <InputText id="requestMethod" className="col-12" value={`${log.requestMethod}`} aria-disabled />
                    <label htmlFor="requestMethod">{`${Labels.LOG_REQUEST_METHOD}: `}</label>
                  </span>
                </div>
                <div className="col-6">
                  <span className="p-float-label">
                    <InputText id="requestWsEndpoint" className="col-12" value={`${log.requestWsEndpoint}`} aria-disabled />
                    <label htmlFor="requestWsEndpoint">{`${Labels.LOG_REQUEST_WS_ENDPOINT}: `}</label>
                  </span>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="col-6">
                  <span className="p-float-label">
                    <InputTextarea id="requestUserAgent" className="col-12" value={`${log.requestUserAgent}`} aria-disabled autoResize />
                    <label htmlFor="requestUserAgent">{`${Labels.LOG_REQUEST_USER_AGENT}: `}</label>
                  </span>
                </div>
                <div className="col-6">
                  <span className="p-float-label">
                    <InputTextarea id="requestAuthorization" className="col-12" value={`${log.requestAuthorization}`} aria-disabled autoResize />
                    <label htmlFor="requestAuthorization">{`${Labels.LOG_REQUEST_AUTHORIZATION}: `}</label>
                  </span>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="col-6">
                  <span className="p-float-label">
                    <InputText id="responseTimestamp" className="col-12" value={moment(log?.responseTimestamp).format(DATE_TIME_FORMAT)} aria-disabled />
                    <label htmlFor="responseTimestamp">{`${Labels.LOG_RESPONSE_TIMESTAMP}: `}</label>
                  </span>
                </div>
                <div className="col-6">
                  <span className="p-float-label">
                    <InputText id="responseStatusCode" className="col-12" value={`${log.responseStatusCode}`} aria-disabled />
                    <label htmlFor="responseStatusCode">{`${Labels.LOG_RESPONSE_STATUS_CODE}: `}</label>
                  </span>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="col-12">
                  <span className="p-float-label">
                    <InputTextarea id="requestBody" className="col-12" value={`${log.requestBody}`} aria-disabled autoResize />
                    <label htmlFor="requestBody">{`${Labels.LOG_REQUEST_BODY}: `}</label>
                  </span>
                </div>
              </div>

              <div className="flex mt-2">
                <div className="col-12">
                  <span className="p-float-label">
                    <InputTextarea id="responseBody" className="col-12" value={`${log.responseBody}`} autoResize aria-disabled />
                    <label htmlFor="responseBody">{`${Labels.LOG_RESPONSE_BODY}: `}</label>
                  </span>
                </div>
              </div>
            </div>
          }
        ></DialogComponent>
      </Panel>
    </div>
  );
}
