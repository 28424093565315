import { useContext, useRef } from "react";
import { AppContext } from "../../../Store";
import { LogController } from "../../../controllers/log/LogController";
import LogHighLevelCreateDto from "../../../model/log/LogHighLevelCreateDto";
import { formatDate, handleAxiosCallError } from "../Utils";

export default function useLogHighLevel() {
  const { axiosPostLogHighLevel } = LogController();
  const { authData, showMessage } = useContext(AppContext);
  const logHighLevel = useRef<LogHighLevelCreateDto>({
    ustanova: { id: authData?.currentRadnik.ustanovaPoslednjaIzabrana.id },
    radnik: { id: authData!.currentRadnik.id },
    pacijent: { id: undefined },
    vremeAkcije: "",
    opis: "",
  });

  return (opis: string, pacijentId?: number, ustanovaId?: number) => {
    logHighLevel!.current.vremeAkcije = formatDate(new Date())!;
    logHighLevel.current.opis = opis;
    if (pacijentId) {
      logHighLevel.current.pacijent.id = pacijentId;
    }
    if (ustanovaId) {
      logHighLevel.current.ustanova.id = ustanovaId;
    }
    axiosPostLogHighLevel(logHighLevel.current).catch((error: any) => {
      handleAxiosCallError(showMessage, error);
    });
  };
}
