import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { SetStateAction, useMemo } from "react";
import { useLabels } from "../../../../../../Store";
import EnumTipTerapijePrikaz from "../../../../../../model/kontakt/EnumTipTerapijePrikaz";
import ReceptCreateDto from "../../../../../../model/kontakt/ReceptCreateDto";
import LrAtributReadDto from "../../../../../../model/sifarnik-lekova-na-recept/LrAtributReadDto";
import SkeletonAccordionList from "../../../../../app/skeleton/SkeletonAccordionList";
import { stepNameType } from "../KontaktTerapijeDialogLogical";

interface SecondStepType {
  terapijaChange: ReceptCreateDto | undefined;
  setTerapijaChange: any;
  lrAtributList: Array<LrAtributReadDto | undefined>;
  lekoviList: any;
  searchLekovi: (e: AutoCompleteCompleteEvent) => void;
  stepName: any;
  setStepName: React.Dispatch<SetStateAction<stepNameType | undefined>>;
  getAttributListByLekIdAndSifraDijagnoze: (lekId: number, sifraDijagnoze: string) => void;
  isAttributListLoading: boolean;
}

export default function SecondStep(props: SecondStepType) {
  const { terapijaChange, setTerapijaChange, lrAtributList, lekoviList, searchLekovi, stepName, setStepName, getAttributListByLekIdAndSifraDijagnoze, isAttributListLoading } = props;
  const Labels = useLabels();

  const autocompleteStyles = useMemo(() => {
    return { paddingLeft: "2rem", paddingRight: "1rem", width: "100%" };
  }, []);

  return (
    <>
      <h4 className="font-bold mb-6 pl-3">{Labels.KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_TITLE}</h4>
      <div className="terapijaDialog--content">
        <div className="flex flex-row flex-wrap align-items-center">
          <div className="col-3">{Labels.KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_PRETRAGA_LEKA + Labels.SPECIAL_CHAR_REQUIRED}</div>
          <div className="col-9">
            <span className="p-input-icon-left w-full">
              <AutoComplete
                value={terapijaChange?.lek}
                itemTemplate={(e) => e.naziv}
                selectedItemTemplate={(e) => e.naziv}
                suggestions={lekoviList}
                completeMethod={searchLekovi}
                inputStyle={autocompleteStyles}
                field="unosLeka"
                onChange={(e) => {
                  setStepName({
                    ...stepName!,
                    secondStepName: e.value.naziv,
                  });
                  setTerapijaChange({
                    ...terapijaChange,
                    lek: e.value,
                    idRepositoryLek: e.value.id,
                  });
                  getAttributListByLekIdAndSifraDijagnoze(e.value.id, stepName.firstStepName);
                }}
                className="w-100 block"
                minLength={3}
              />
              <i className="pi pi-search " />
            </span>
          </div>
        </div>
        {isAttributListLoading ? (
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-3">
              <SkeletonAccordionList />
            </div>
            <div className="col-9">
              <SkeletonAccordionList />
            </div>
          </div>
        ) : (
          lrAtributList.length > 0 &&
          terapijaChange?.idRepositoryLek !== null &&
          terapijaChange?.idRepositoryLek !== undefined && (
            <div className="flex flex-row flex-wrap align-items-center">
              <div className="col-3">{Labels.KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_ATRIBUT_LEKA}</div>
              <div className="col-9">
                <Dropdown
                  value={terapijaChange?.idRepositoryLrAtribut}
                  options={lrAtributList}
                  optionLabel="naziv"
                  optionValue="id"
                  showClear
                  onChange={(e) => {
                    setTerapijaChange({ ...terapijaChange, idRepositoryLrAtribut: e.value });
                  }}
                  className="p-column-filter"
                />
              </div>
            </div>
          )
        )}
        <div className="flex flex-row flex-wrap">
          <div className="col-3">{Labels.KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_TIP_TERAPIJE + Labels.SPECIAL_CHAR_REQUIRED}</div>
          <div className="col-9">
            {Object.entries(EnumTipTerapijePrikaz).map((key, item) => (
              <div key={item} className="mb-5">
                <RadioButton
                  inputId={key[1]}
                  name="tipTerapije"
                  value={key[0]}
                  checked={terapijaChange?.tipTerapije?.sifra === key[0]}
                  onChange={(e) => {
                    setTerapijaChange({ ...terapijaChange, tipTerapije: { sifra: e.value } });
                  }}
                />
                <label className="cursor-pointer mx-2" htmlFor={key[1]}>
                  {key[1]}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
