import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import RadniPeriodCreateSendDto from "../../model/radni-period/RadniPeriodCreateSendDto";
import RadniPeriodDeleteListDto from "../../model/radni-period/RadniPeriodDeleteListDto";
import RadniPeriodSearchDto from "../../model/radni-period/RadniPeriodSearchDto";
import CommonResponseDto from "../../model/CommonResponseDto";
import RadniPeriodReadDto from "../../model/radni-period/RadniPeriodReadDto";

interface RadniPeriodControllerType {
  axiosGetRadniPeriodList: (radniPeriodSerach: RadniPeriodSearchDto) => Promise<AxiosResponse<CommonResponseDto<RadniPeriodReadDto[]>>>;
  axiosCreateRadniPeriod: (radniPeriodChange: RadniPeriodCreateSendDto) => Promise<AxiosResponse<any>>;
  axiosDeleteRadniPeriodList: (radniPeriodDeleteRequest: RadniPeriodDeleteListDto) => Promise<AxiosResponse<any>>;
  axiosGetRadniPeriodForSpecifiedMinutesPeriod: (minutesPeriod: number, radniPeriodSearchObject: RadniPeriodSearchDto) => Promise<AxiosResponse<CommonResponseDto<RadniPeriodReadDto[]>>>;
}

export function RadniPeriodController(): RadniPeriodControllerType {
  const { RADNI_PERIOD_LIST } = Endpoint;
  const { authData } = useContext(AppContext);

  const axiosGetRadniPeriodList = (radniPeriodSerach: RadniPeriodSearchDto) => {
    return axios.get(RADNI_PERIOD_LIST, axiosConfig(authData!.token, radniPeriodSerach));
  };

  const axiosCreateRadniPeriod = (radniPeriodChange: RadniPeriodCreateSendDto) => {
    return axios.post(RADNI_PERIOD_LIST, radniPeriodChange, axiosConfig(authData!.token));
  };

  const axiosDeleteRadniPeriodList = (radniPeriodDeleteRequest: RadniPeriodDeleteListDto) => {
    return axios.delete(RADNI_PERIOD_LIST, axiosConfig(authData!.token, radniPeriodDeleteRequest));
  };

  const axiosGetRadniPeriodForSpecifiedMinutesPeriod = (minutesPeriod: number, radniPeriodSearchObject: RadniPeriodSearchDto) => {
    return axios.get(`${RADNI_PERIOD_LIST}/following-period/${minutesPeriod}`, axiosConfig(authData!.token, radniPeriodSearchObject));
  };

  return {
    axiosGetRadniPeriodList,
    axiosCreateRadniPeriod,
    axiosDeleteRadniPeriodList,
    axiosGetRadniPeriodForSpecifiedMinutesPeriod,
  };
}
