import { Button } from "primereact/button";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import KontrolaPravaPristupaService from "../../../../infrastructure/system/KontrolaPravaPristupaService";
import { tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import ReceptReadDto from "../../../../model/kontakt/ReceptReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import SkeletonRectangles from "../../../app/skeleton/SkeletonRectangles";
import KontaktTerapijeLogical from "./KontaktTerapijeLogical";
import KontaktTerapijaPreview from "./dialogs/KontaktTerapijaPreview";
import KontaktTerapije from "./dialogs/KontaktTerapije";

interface TerapijeListaProps {
  pacijent: PacijentReadDto;
  receptList: Array<ReceptReadDto> | undefined;
  setReceptList: React.Dispatch<React.SetStateAction<Array<ReceptReadDto> | undefined>>;
}

export default function TerapijeList(props: TerapijeListaProps) {
  const { pacijent, receptList, setReceptList } = props;
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const {
    isOpenTerapijaDialog,
    closeTerapijaDialog,
    openTerapijaDialog,
    handleSubmit,
    kontaktDijagnozaList,
    dataLoading,
    terapijaChange,
    setTerapijaChange,
    deleteTerapija,
    previewTerapija,
    receptPreview,
    isPreviewOpen,
    closePreview,
    lrAtributList,
    lekoviList,
    searchLekovi,
    getAttributListByLekIdAndSifraDijagnoze,
    isAttributListLoading,
    jediniceMereList,
    jedinicaMere,
    getJedinicaMere,
    deleteTerapijaPreview,
    isDeletePreviewOpen,
    printRecept,
    receptListLoading,
  } = KontaktTerapijeLogical(pacijent, receptList, setReceptList);

  const { authData } = useContext(AppContext);
  const { hasReceptRead, hasReceptCreate, hasReceptDelete } = KontrolaPravaPristupaService();

  return (
    <>
      <div className="p-grid p-dir-col layout-generic-content-list">
        {receptListLoading ? (
          <SkeletonRectangles />
        ) : (
          <div className="p-col flex flex-column">
            <div className="box flex flex-row pb-3">
              <div className="col-9 p-0 font-bold align-self-center">{Labels.KONTAKT_PREPISANE_TERAPIJE}</div>
              <div className="col-3 p-0 flex justify-content-end">
                <Button label={Labels.KONTAKT_NOVA_TERAPIJA} className="p-button-success" onClick={() => openTerapijaDialog()} disabled={!pristup || !hasReceptCreate} />
              </div>
            </div>

            <div className="flex flex-column">
              {receptList?.map((recept: ReceptReadDto, key: number) => {
                return (
                  <div key={key} className="flex flex-row flex-wrap justify-content-between align-items-start border-bottom-1 border-300 py-3">
                    <div className="col-9 p-0">
                      <p className="font-bold mb-1">{recept.lek.naziv}</p>
                      <p className="">{recept.dijagnoza.sifarnikMkb.sifraBezTacke + " " + recept.dijagnoza.sifarnikMkb.nazivLatinski}</p>
                    </div>
                    <div className="col-3 flex justify-content-end p-0">
                      <div className="flex flex-row h-2rem">
                        <Button
                          type="button"
                          tooltip={Labels.KONTAKT_TERAPIJA_VIEW}
                          tooltipOptions={tooltipOptionsTop}
                          className="p-button-info mr-2 p-button-small-size"
                          icon="pi pi-eye"
                          onClick={() => previewTerapija(recept)}
                          disabled={!hasReceptRead && recept.radnikKreirao.id !== authData?.currentRadnik.id}
                        />
                        <Button
                          type="button"
                          tooltip={Labels.KONTAKT_TERAPIJA_STAMPA_TOOLTIP}
                          tooltipOptions={tooltipOptionsTop}
                          className="p-button-info mr-2 p-button-small-size"
                          icon="pi pi-print"
                          onClick={() => printRecept(recept.id)}
                          disabled={!pristup || (!hasReceptDelete && recept.radnikKreirao.id !== authData?.currentRadnik.id)}
                        />
                        <Button
                          type="button"
                          tooltip={Labels.KONTAKT_TERAPIJA_DELETE}
                          tooltipOptions={tooltipOptionsTop}
                          className="p-button-danger p-button-small-size"
                          icon="pi pi-trash"
                          onClick={() => deleteTerapijaPreview(recept)}
                          disabled={!pristup || (!hasReceptDelete && recept.radnikKreirao.id !== authData?.currentRadnik.id)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <KontaktTerapije
        isOpenTerapijaDialog={isOpenTerapijaDialog}
        closeTerapijaDialog={closeTerapijaDialog}
        handleSubmit={handleSubmit}
        kontaktDijagnozaList={kontaktDijagnozaList}
        dataLoading={dataLoading}
        terapijaChange={terapijaChange}
        setTerapijaChange={setTerapijaChange}
        lrAtributList={lrAtributList}
        lekoviList={lekoviList}
        searchLekovi={searchLekovi}
        getAttributListByLekIdAndSifraDijagnoze={getAttributListByLekIdAndSifraDijagnoze}
        isAttributListLoading={isAttributListLoading}
        jediniceMereList={jediniceMereList}
        jedinicaMere={jedinicaMere}
        getJedinicaMere={getJedinicaMere}
      />
      <KontaktTerapijaPreview receptPreview={receptPreview} closePreview={closePreview} isPreviewOpen={isPreviewOpen} />
      <KontaktTerapijaPreview receptPreview={receptPreview} closePreview={closePreview} isPreviewOpen={isDeletePreviewOpen} isDeleteDialog={true} deleteTerapija={deleteTerapija} />
    </>
  );
}
