import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { useContext, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import MessageType from "../../../infrastructure/system/MessageType";
import { MAX_FILE_UPLOAD } from "../../../infrastructure/system/Utils";
import GlobalnaDatotekaReadDto from "../../../model/globalna-datoteka/GlobalnaDatotekaReadDto";
import GlobalnaDatotekaUpdateDto from "../../../model/globalna-datoteka/GlobalnaDatotekaUpdateDto";
import { useUpdateGlobalnaDatoteka } from "./hooks/useUpdateGlobalnaDatoteka";

interface DialogGlobalnaDatotekaProps {
  globalnaDatoteka: GlobalnaDatotekaReadDto;
  visible: boolean;
  onHide: () => void;
}
export const DialogGlobalnaDatoteka = ({ visible, onHide, globalnaDatoteka }: DialogGlobalnaDatotekaProps) => {
  const { showMessage } = useContext(AppContext);
  const globalnaDatotekaUpdateMutation = useUpdateGlobalnaDatoteka();
  const Labels = useLabels();
  const [globalnaDatotekaChange, setGlobalnaDatotekaChange] = useState<GlobalnaDatotekaUpdateDto>(globalnaDatoteka);
  const [datotekaNaziv, setDatotekaNaziv] = useState<string>("");
  const fileUploadRef = useRef<FileUpload>(null);

  return (
    <Dialog
      header={`${Labels.GLOBALNA_DATOTEKA_DIALOG_HEADER}: ${globalnaDatoteka.naziv}`}
      visible={visible}
      onHide={onHide}
      footer={
        <Button
          icon="pi pi-pencil"
          label="Izmeni"
          severity="warning"
          onClick={() => {
            globalnaDatotekaUpdateMutation.mutate({ id: globalnaDatoteka.id, globalnaDatoteka: globalnaDatotekaChange }, { onSuccess: () => onHide() });
          }}
        />
      }
    >
      <div className="flex flex-column gap-2">
        <div className="font-semibold">Sadržaj:</div>
        <div className="flex flex-row flex-wrap gap-2 align-items-center">
          <FileUpload
            ref={fileUploadRef}
            auto
            mode="basic"
            accept=".jrxml"
            disabled={!!datotekaNaziv}
            multiple={false}
            customUpload
            uploadHandler={(e) => {
              e.files.forEach((file: File) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                  if (file.size > MAX_FILE_UPLOAD) {
                    showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
                    return;
                  }

                  if (reader.result) {
                    setGlobalnaDatotekaChange({
                      ...globalnaDatotekaChange,
                      sadrzaj: reader.result.toString().substr(reader.result?.toString().indexOf(",") + 1),
                    });
                    setDatotekaNaziv(file.name);
                  }
                };
              });
            }}
            chooseLabel={Labels.GLOBALNA_DATOTEKA_CHOOSE}
          />
          {datotekaNaziv && (
            <Chip
              label={datotekaNaziv}
              className="font-semibold"
              removable={true}
              onRemove={() => {
                if (fileUploadRef.current) fileUploadRef.current.clear();
                setDatotekaNaziv("");
              }}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};
