import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";
import DokumentReadDto from "../../model/dokument/DokumentReadDto";
import EnumBaseMandatoryDto from "../../model/sifarnik/EnumBaseMandatoryDto";
import ZakazanTerminCreateDto from "../../model/zakazan-termin/ZakazanTerminCreateDto";
import ZakazanTerminSearchDto from "../../model/zakazan-termin/ZakazanTerminSearchDto";
import ZakazanTerminDatotekaCreateDto from "../../model/zakazan-termin/datoteke/ZakazanTerminDatotekaCreateDto";
import ZakazanTerminDatotekaReadDto from "../../model/zakazan-termin/datoteke/ZakazanTerminDatotekaReadDto";
import KomentarCreateDto from "../../model/zakazan-termin/komentari/KomentarCreateDto";
import KomentarReadDto from "../../model/zakazan-termin/komentari/KomentarReadDto";
import { AppContext } from "./../../Store";

interface ZakazaniTerminControllerType {
  axiosGetZakazanTerminById: (id: number) => Promise<AxiosResponse<any>>;
  axiosGetZakazaniTermini: (zakazanTerminSearch: ZakazanTerminSearchDto) => Promise<AxiosResponse<CommonResponseDto<any>>>;
  axiosPutIzmenaZakazaniTermini: (id: number, params: ZakazanTerminCreateDto) => Promise<AxiosResponse<any>>;
  axiosPutZakazaniTerminiStatus: (id: number, params: EnumBaseMandatoryDto) => Promise<AxiosResponse<any>>;
  axiosPostKreiranjeZakazaniTermini: (params: ZakazanTerminCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteZakazaniTermini: (id: number) => Promise<AxiosResponse<any>>;
  axiosPatchCloseKontaktList: (body: Array<number>) => Promise<AxiosResponse<any>>;
  axiosPatchDeleteZakazanTerminList: (body: Array<number>) => Promise<AxiosResponse<any>>;
  axiosZakazanTerminChangeDoctor: (radnikId: number, zakazanTerminIdList: Array<number>) => Promise<AxiosResponse<any>>;
  axiosGetZakazaniTerminiPrint: (organizacionaJedinicaID: number, datum: string) => Promise<AxiosResponse<any>>;
  axiosGetCommentsForZakazaniTermin: (zakazanTerminID: number) => Promise<AxiosResponse<CommonResponseDto<KomentarReadDto[]>>>;
  axiosPostCommentForZakazaniTermin: (zakazanTerminID: number, komentar: KomentarCreateDto | undefined) => Promise<AxiosResponse<any>>;
  axiosGetDatotekeForZakazaniTermin: (zakazanTerminID: number) => Promise<AxiosResponse<CommonResponseDto<ZakazanTerminDatotekaReadDto[]>>>;
  axiosPostDatotekaForZakazaniTermin: (datoteka: ZakazanTerminDatotekaCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetDatotekaForZakazanTerminById: (zakazanTerminDatotekaID: number) => Promise<AxiosResponse<any>>;
  axiosGetZakazanTerminUslugaList: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteZakazanTerminUslugaList: (kontaktId: number, zakazanTerminUslugaIdList: Array<number>) => Promise<AxiosResponse<any>>;
  axiosGetZakazanTerminDatotekaListForPacijent: (pacijentID: number) => Promise<AxiosResponse<CommonResponseDto<DokumentReadDto[]>>>;
  axiosPostKreiranjeZakazaniVanredniTermin: (params: ZakazanTerminCreateDto) => Promise<AxiosResponse<any>>;
}

export function ZakazaniTerminController(): ZakazaniTerminControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetZakazanTerminById = (id: number) => {
    return axios.get(`${Endpoint.ZAKAZANI_TERMINI_LIST}/${id}`, axiosConfig(authData!.token));
  };

  const axiosGetZakazaniTermini = (zakazanTerminSearch: ZakazanTerminSearchDto) => {
    return axios.get(Endpoint.ZAKAZANI_TERMINI_LIST, axiosConfig(authData!.token, zakazanTerminSearch));
  };

  const axiosPostKreiranjeZakazaniTermini = (params: ZakazanTerminCreateDto) => {
    return axios.post(Endpoint.ZAKAZANI_TERMINI_LIST, params, axiosConfig(authData!.token));
  };

  const axiosPutIzmenaZakazaniTermini = (id: number, params: ZakazanTerminCreateDto) => {
    return axios.put(`${Endpoint.ZAKAZANI_TERMINI_LIST}/${id}`, params, axiosConfig(authData!.token));
  };

  const axiosPutZakazaniTerminiStatus = (id: number, params: EnumBaseMandatoryDto) => {
    return axios.put(`${Endpoint.ZAKAZANI_TERMINI_LIST}/${id}${Endpoint.ZAKAZANI_TERMINI_LIST_STATUS_SUFIX}`, params, axiosConfig(authData!.token));
  };

  const axiosDeleteZakazaniTermini = (id: number) => {
    return axios.delete(`${Endpoint.ZAKAZANI_TERMINI_LIST}/${id}`, axiosConfig(authData!.token));
  };

  const axiosPatchCloseKontaktList = (body: Array<number>) => {
    return axios.patch(`${Endpoint.ZAKAZANI_TERMINI_LIST}/closeKontaktList`, body, axiosConfig(authData!.token));
  };

  const axiosPatchDeleteZakazanTerminList = (body: Array<number>) => {
    return axios.patch(`${Endpoint.ZAKAZANI_TERMINI_LIST}/deleteZakazanTerminList`, body, axiosConfig(authData!.token));
  };

  const axiosZakazanTerminChangeDoctor = (radnikId: number, zakazanTerminIdList: Array<number>) => {
    return axios.put(`${Endpoint.ZAKAZANI_TERMINI_LIST}/${radnikId}/changeDoctor`, zakazanTerminIdList, axiosConfig(authData!.token));
  };

  const axiosGetZakazaniTerminiPrint = (organizacionaJedinicaID: number, datum: string) => {
    return axios.get(Endpoint.ZAKAZAN_TERMIN_LIST_PRINT, axiosConfig(authData!.token, { organizacionaJedinicaID: organizacionaJedinicaID, datum: datum }));
  };

  const axiosGetCommentsForZakazaniTermin = (zakazanTerminID: number) => {
    return axios.get(`${Endpoint.ZAKAZAN_TERMIN}/${zakazanTerminID}/komentar-list`, axiosConfig(authData!.token));
  };

  const axiosPostCommentForZakazaniTermin = (zakazanTerminID: number, komentar: KomentarCreateDto | undefined) => {
    return axios.post(`${Endpoint.ZAKAZAN_TERMIN}/${zakazanTerminID}/komentar-list`, komentar, axiosConfig(authData!.token));
  };

  const axiosGetDatotekeForZakazaniTermin = (zakazanTerminID: number) => {
    return axios.get(`${Endpoint.ZAKAZAN_TERMIN_DATOTEKA}/${zakazanTerminID}`, axiosConfig(authData!.token));
  };

  const axiosPostDatotekaForZakazaniTermin = (datoteka: ZakazanTerminDatotekaCreateDto) => {
    return axios.post(`${Endpoint.ZAKAZAN_TERMIN}/${datoteka.zakazanTerminID}/datoteka-list`, datoteka, axiosConfig(authData!.token));
  };

  const axiosGetDatotekaForZakazanTerminById = (zakazanTerminDatotekaID: number) => {
    return axios.get(`${Endpoint.ZAKAZAN_TERMIN_DATOTEKA_DOCUMENT}/${zakazanTerminDatotekaID}`, axiosConfig(authData!.token));
  };

  const axiosGetZakazanTerminDatotekaListForPacijent = (pacijentID: number) => {
    return axios.get(`${Endpoint.ZAKAZAN_TERMIN_DATOTEKA}/pacijent/${pacijentID}`, axiosConfig(authData!.token));
  };

  const axiosGetZakazanTerminUslugaList = (kontaktId: number) => {
    return axios.get(`${Endpoint.ZAKAZANI_TERMINI_USLUGA_LIST}/${kontaktId}`, axiosConfig(authData!.token));
  };

  const axiosDeleteZakazanTerminUslugaList = (kontaktId: number, zakazanTerminUslugaIdList: Array<number>) => {
    return axios.delete(
      `${Endpoint.ZAKAZANI_TERMINI_USLUGA_LIST}/${kontaktId}/deleteZakazanTerminUslugaList`,
      axiosConfig(authData!.token, { zakazanTerminUslugaIdList: zakazanTerminUslugaIdList.join(",") })
    );
  };

  const axiosPostKreiranjeZakazaniVanredniTermin = (params: ZakazanTerminCreateDto) => {
    return axios.post(Endpoint.ZAKAZAN_TERMIN_VANREDNI, params, axiosConfig(authData!.token));
  };

  return {
    axiosGetZakazanTerminById,
    axiosGetZakazaniTermini,
    axiosPutIzmenaZakazaniTermini,
    axiosPutZakazaniTerminiStatus,
    axiosPostKreiranjeZakazaniTermini,
    axiosDeleteZakazaniTermini,
    axiosPatchCloseKontaktList,
    axiosPatchDeleteZakazanTerminList,
    axiosZakazanTerminChangeDoctor,
    axiosGetZakazaniTerminiPrint,
    axiosGetCommentsForZakazaniTermin,
    axiosPostCommentForZakazaniTermin,
    axiosGetDatotekeForZakazaniTermin,
    axiosPostDatotekaForZakazaniTermin,
    axiosGetDatotekaForZakazanTerminById,
    axiosGetZakazanTerminUslugaList,
    axiosDeleteZakazanTerminUslugaList,
    axiosGetZakazanTerminDatotekaListForPacijent,
    axiosPostKreiranjeZakazaniVanredniTermin,
  };
}
