import moment from "moment/moment";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useRef } from "react";
import { useJezikList } from "../../../../infrastructure/system/hooks/enum/useJezikList";
import { useRadniDaniList } from "../../../../infrastructure/system/hooks/enum/useRadniDaniList";
import { useTrajanjeTerminaList } from "../../../../infrastructure/system/hooks/enum/useTrajanjeTerminaList";
import useEffectWithoutWarnings from "../../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import { useUstanovaGrid } from "../../../../infrastructure/system/hooks/ustanova/useUstanovaGrid";
import Images from "../../../../infrastructure/system/Images";
import RadnoVremeUtils from "../../../../infrastructure/system/RadnoVremeUtils";
import { createCurrentDateWithTime, getDateDivisibleBy5, setInvalid, STEP_MINUTE, TIME_FORMAT_SECONDS_0 } from "../../../../infrastructure/system/Utils";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import UstanovaGridRadniDanReadDto from "../../../../model/ustanova/UstanovaGridRadniDanReadDto";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";
import { useLabels } from "../../../../Store";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";
import { InitialStateType } from "../../hooks/initialState";
import { ReducerType } from "../../hooks/reducer";
import InvalidFieldsType from "../../../../model/InvalidFieldsType";

interface InicijalizacijaUstanovaViewPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerType>;
  invalidFieldsUstanova: InvalidFieldsType | undefined;
  setInvalidFieldsUstanova: React.Dispatch<React.SetStateAction<InvalidFieldsType | undefined>>;
  ustanova: UstanovaReadDto;
  isUstanovaLoading: boolean;
  onUpdateUstanova: UseMutationResult<AxiosResponse, unknown, void, unknown>;
  validateUstanovaStep: () => boolean;
}

export default function InicijalizacijaUstanovaView(props: InicijalizacijaUstanovaViewPropsType) {
  const { state, dispatch, invalidFieldsUstanova, setInvalidFieldsUstanova, ustanova, isUstanovaLoading, onUpdateUstanova, validateUstanovaStep } = props;
  const Labels = useLabels();
  const vremeOdRef = useRef<Calendar>(null);
  const vremeDoRef = useRef<Calendar>(null);
  const { jezikList, isLoading: isJezikListLoading } = useJezikList();
  const { trajanjeTerminaList, isLoading: isLoadingTrajanjeTerminaList } = useTrajanjeTerminaList();
  const { isLoading: isLoadingRadniDaniList, radniDani, radniDaniMap } = useRadniDaniList();
  const { data: ustanovaGrid, isLoading: isUstanovaGridLoading } = useUstanovaGrid();
  const { getRadnoVremeValue } = RadnoVremeUtils();

  useEffectWithoutWarnings(() => {
    if (ustanova) {
      dispatch({ type: "set_ustanova", data: { ...ustanova } });
      dispatch({ type: "set_ustanova_readonly", data: { ...ustanova } });
    }
  }, [ustanova]);

  useEffectWithoutWarnings(() => {
    if (ustanovaGrid) {
      dispatch({
        type: "set_ustanova_grid",
        data: {
          ...ustanovaGrid,
          ustanovaGridRadniDanList: ustanovaGrid.ustanovaGridRadniDanList?.map((radanDanEntry: UstanovaGridRadniDanReadDto) => radanDanEntry.radniDan.sifra),
        },
      });
      dispatch({
        type: "set_ustanova_grid_readonly",
        data: {
          ...ustanovaGrid,
          ustanovaGridRadniDanList: ustanovaGrid?.ustanovaGridRadniDanList?.map((radanDanEntry: UstanovaGridRadniDanReadDto) => radanDanEntry.radniDan.sifra),
        },
      });
    }
  }, [ustanovaGrid]);

  return (
    <div className="inicijalizacija-ustanova-layout p-6">
      <div className="font-bold text-2xl mb-6 flex align-items-center">
        {Labels.USTANOVA}
        <Image src={Images.ICON_BULB} className="ml-4 mt-1 bulb" data-pr-tooltip={Labels.LABEL_INICIJALIZACIJA_USTANOVA_TOOLTIP} />
        <Tooltip target=".bulb" />
      </div>
      <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8 pl-0">
        <div className="grid align-items-center p-0">
          <div className="col-12 px-0 sm:col-4 sm:px-3">
            <div>
              {Labels.USTANOVA_NAZIV}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {isUstanovaLoading ? (
              <SkeletonInputItem />
            ) : (
              <>
                <InputText
                  value={state?.ustanovaChange?.naziv ?? ""}
                  onChange={(e) => {
                    dispatch({ type: "change_ustanova", data: { field: "naziv", value: e.target.value } });
                    setInvalidFieldsUstanova((prev) => ({ ...prev, naziv: false }));
                  }}
                  onBlur={() => {
                    setInvalid(setInvalidFieldsUstanova, "naziv", state?.ustanovaChange?.naziv);
                  }}
                  className={invalidFieldsUstanova?.naziv ? "p-invalid" : ""}
                />
                {invalidFieldsUstanova?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            )}
          </div>
          <div className="col-12 px-0 sm:col-4 sm:px-3">
            <div>
              {Labels.USTANOVA_NAZIV_KRATKI}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {isUstanovaLoading ? (
              <SkeletonInputItem />
            ) : (
              <>
                <InputText
                  value={state?.ustanovaChange?.nazivKratki ?? ""}
                  onChange={(e) => {
                    dispatch({ type: "change_ustanova", data: { field: "nazivKratki", value: e.target.value } });
                    setInvalidFieldsUstanova((prev) => ({ ...prev, nazivKratki: false }));
                  }}
                  onBlur={() => {
                    setInvalid(setInvalidFieldsUstanova, "nazivKratki", state?.ustanovaChange?.nazivKratki);
                  }}
                  className={invalidFieldsUstanova?.nazivKratki ? "p-invalid" : ""}
                />
                {invalidFieldsUstanova?.nazivKratki && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            )}
          </div>
          <div className="col-12 px-0 sm:col-4 sm:px-3">
            <div>
              {Labels.USTANOVA_RADNO_VREME_OD}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {isUstanovaLoading ? (
              <SkeletonInputItem />
            ) : (
              <>
                <CalendarComponent
                  ref={vremeOdRef}
                  timeOnly
                  viewDate={state?.ustanovaChange?.radnoVremeDo ? getDateDivisibleBy5(createCurrentDateWithTime(state?.ustanovaChange?.radnoVremeDo)) : getDateDivisibleBy5()}
                  value={getRadnoVremeValue(state?.ustanovaChange?.radnoVremeOd)}
                  onChange={(e) => {
                    dispatch({ type: "change_ustanova", data: { field: "radnoVremeOd", value: e.value instanceof Date ? moment(e.value).format(TIME_FORMAT_SECONDS_0) : (e.value as string) } });
                    setInvalidFieldsUstanova((prev) => ({ ...prev, radnoVremeOd: false }));
                  }}
                  onClearButtonClick={() => {
                    setInvalidFieldsUstanova((prev) => ({ ...prev, radnoVremeOd: true }));
                  }}
                  stepMinute={STEP_MINUTE}
                  maxDate={getRadnoVremeValue(state?.ustanovaChange?.radnoVremeDo)}
                  icon="pi pi-clock"
                  onTodayButtonClick={() => {
                    let currentDate = getDateDivisibleBy5(new Date());
                    let maxDate = getRadnoVremeValue(state?.ustanovaChange?.radnoVremeDo);
                    if (maxDate && currentDate > maxDate) {
                      currentDate = maxDate;
                    }
                    dispatch({ type: "change_ustanova", data: { field: "radnoVremeOd", value: moment(currentDate).format(TIME_FORMAT_SECONDS_0) } });
                  }}
                  className={"w-full " + (invalidFieldsUstanova?.radnoVremeOd && "p-invalid")}
                />
                {invalidFieldsUstanova?.radnoVremeOd && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            )}
          </div>
          <div className="col-12 px-0 sm:col-4 sm:px-3">
            <div>
              {Labels.USTANOVA_RADNO_VREME_DO}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {isUstanovaLoading ? (
              <SkeletonInputItem />
            ) : (
              <>
                <CalendarComponent
                  ref={vremeDoRef}
                  timeOnly
                  viewDate={state?.ustanovaChange?.radnoVremeOd ? getDateDivisibleBy5(createCurrentDateWithTime(state?.ustanovaChange?.radnoVremeOd)) : getDateDivisibleBy5()}
                  value={getRadnoVremeValue(state?.ustanovaChange?.radnoVremeDo)}
                  onChange={(e) => {
                    dispatch({ type: "change_ustanova", data: { field: "radnoVremeDo", value: e.value instanceof Date ? moment(e.value).format(TIME_FORMAT_SECONDS_0) : (e.value as string) } });
                    setInvalidFieldsUstanova((prev) => ({ ...prev, radnoVremeDo: false }));
                  }}
                  onClearButtonClick={() => {
                    setInvalidFieldsUstanova((prev) => ({ ...prev, radnoVremeDo: true }));
                  }}
                  stepMinute={STEP_MINUTE}
                  minDate={getRadnoVremeValue(state?.ustanovaChange?.radnoVremeOd)}
                  icon="pi pi-clock"
                  onTodayButtonClick={() => {
                    let currentDate = getDateDivisibleBy5(new Date());
                    let minDate = getRadnoVremeValue(state?.ustanovaChange?.radnoVremeOd);
                    if (minDate && currentDate < minDate) {
                      currentDate = minDate;
                    }
                    dispatch({ type: "change_ustanova", data: { field: "radnoVremeDo", value: moment(getDateDivisibleBy5(new Date())).format(TIME_FORMAT_SECONDS_0) } });
                  }}
                  className={"w-full " + (invalidFieldsUstanova?.radnoVremeDo && "p-invalid")}
                />
                {invalidFieldsUstanova?.radnoVremeDo && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            )}
          </div>
          <div className="col-12 px-0 sm:col-4 sm:px-3">
            <div>
              {Labels.USTANOVA_JEZIK}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {isUstanovaLoading || isJezikListLoading ? (
              <SkeletonInputItem />
            ) : (
              <>
                <Dropdown
                  options={jezikList}
                  value={state?.ustanovaChange?.jezik?.sifra ?? undefined}
                  optionLabel="naziv"
                  optionValue="sifra"
                  onChange={(e) => {
                    dispatch({ type: "change_ustanova", data: { field: "jezik", value: e.value ? jezikList!.find((x: EnumBaseReadDto) => x.sifra === e.target.value) : undefined } });
                    if (e.target.value) {
                      setInvalidFieldsUstanova((prev) => ({ ...prev, jezik: false }));
                    } else {
                      setInvalidFieldsUstanova((prev) => ({ ...prev, jezik: true }));
                    }
                  }}
                  emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                  className={"p-column-filter " + (invalidFieldsUstanova?.jezik && "p-invalid")}
                />
                {invalidFieldsUstanova?.jezik && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            )}
          </div>
          <Divider className="divider" />
          <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
            <div>
              {Labels.USTANOVA_GRID_VREME_TRAJANJA_PREGLEDA}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {isUstanovaGridLoading || isLoadingTrajanjeTerminaList ? (
              <SkeletonInputItem />
            ) : (
              <>
                <Dropdown
                  options={trajanjeTerminaList}
                  resetFilterOnHide
                  value={state?.ustanovaGridChange?.vremeTrajanjaPregledaEnum?.sifra ?? ""}
                  optionLabel="naziv"
                  optionValue="sifra"
                  onChange={(e) => {
                    dispatch({
                      type: "change_ustanova_grid",
                      data: { field: "vremeTrajanjaPregledaEnum", value: e.value ? trajanjeTerminaList!.find((x: EnumBaseReadDto) => x.sifra === e.target.value) : undefined },
                    });
                    setInvalidFieldsUstanova((prev) => ({ ...prev, vremeTrajanjaPregledaEnum: false }));
                  }}
                  emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                  className={invalidFieldsUstanova?.vremeTrajanjaPregledaEnum ? "p-invalid" : ""}
                />
                {invalidFieldsUstanova?.vremeTrajanjaPregledaEnum && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            )}
          </div>
          <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
            <div>
              {Labels.USTANOVA_GRID_RADNI_DANI_U_NEDELJI}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            {isUstanovaGridLoading || isLoadingRadniDaniList ? (
              <SkeletonInputItem />
            ) : (
              <>
                <MultiSelect
                  value={state?.ustanovaGridChange?.ustanovaGridRadniDanList ?? ""}
                  options={radniDani ?? []}
                  onChange={(e) => {
                    let radniDaniSifre: Array<string> = e.value;
                    radniDaniSifre.sort((radniDanSifra1, radniDanSifra2) => (radniDaniMap?.get(radniDanSifra1)?.prikazniRedosled ?? 0) - (radniDaniMap?.get(radniDanSifra2)?.prikazniRedosled ?? 0));
                    dispatch({ type: "change_ustanova_grid", data: { field: "ustanovaGridRadniDanList", value: radniDaniSifre } });
                    setInvalidFieldsUstanova((prev) => ({ ...prev, ustanovaGridRadniDanList: false }));
                  }}
                  optionLabel="naziv"
                  optionValue="sifra"
                  emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                  className={invalidFieldsUstanova?.ustanovaGridRadniDanList ? "p-invalid" : ""}
                />
                {invalidFieldsUstanova?.ustanovaGridRadniDanList && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            )}
          </div>
          <div className="flex justify-content-end w-full pt-5">
            <div className="w-max">
              <Button label={Labels.LABEL_SNIMI} icon="pi pi-save" onClick={() => validateUstanovaStep() && onUpdateUstanova.mutate()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
