import { InitialStateSifarnikCenaType } from "./initialStateSifarnikCena";
import actionsSifarnikCena from "./actionsSifarnikCena";

export default function reducerSifarnikCena(state: InitialStateSifarnikCenaType, action: any) {
  switch (action.type) {
    case actionsSifarnikCena.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
        operation: action.data,
        cena: action.cena,
      };
    }
    case actionsSifarnikCena.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        cena: { valuta: state?.cena?.valuta },
      };
    }
    case actionsSifarnikCena.CENA_CHANGE: {
      return {
        ...state,
        cena: { ...state.cena, [action.data.fieldName]: action.data.value },
      };
    }
    default:
      return state;
  }
}
