import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

interface SifarnikKategorijaPopustaListLogicalType {
  dt: any;
  openSifarnikKategorijaPopusta: (entityOperation: string, id?: number) => void;
  breadCrumbItems: Array<BreadCrumbItemDto>;
}

export default function SifarnikKategorijaPopustaListLogical(): SifarnikKategorijaPopustaListLogicalType {
  const dt = useRef<any>(null);
  const Labels = useLabels();
  const navigate = useNavigate();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA,
    },
  ];

  const openSifarnikKategorijaPopusta = (entityOperation: string, id?: number) => {
    const sifarnikKategorijaPopustaId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-sifarnik-kategorija-popusta/${sifarnikKategorijaPopustaId}`, {
      state: { sifarnikKategorijaPopustaOperation: entityOperation },
    });
  };

  return {
    dt,
    openSifarnikKategorijaPopusta,
    breadCrumbItems,
  };
}
