import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";
import { AppContext } from "./../../Store";
import GlobalnaDatotekaUpdateDto from "../../model/globalna-datoteka/GlobalnaDatotekaUpdateDto";
import GlobalnaDatotekaReadDto from "../../model/globalna-datoteka/GlobalnaDatotekaReadDto";

interface GlobalnaDatotekaControllerType {
  axiosGetGlobalnaDatotekaList: () => Promise<AxiosResponse<CommonResponseDto<GlobalnaDatotekaReadDto[]>>>;
  axiosGetGlobalnaDatoteka: (id: number) => Promise<AxiosResponse<CommonResponseDto<GlobalnaDatotekaReadDto>>>;
  axiosUpdateGlobalnaDatoteka: (id: number, globalnaDatoteka: GlobalnaDatotekaUpdateDto) => Promise<AxiosResponse<CommonResponseDto<GlobalnaDatotekaReadDto>>>;
}

export function GlobalnaDatotekaController(): GlobalnaDatotekaControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetGlobalnaDatotekaList = () => {
    return axios.get(Endpoint.GLOBALNA_DATOTEKA_LIST, axiosConfig(authData!.token));
  };

  const axiosGetGlobalnaDatoteka = (id: number) => {
    return axios.get(`${Endpoint.GLOBALNA_DATOTEKA_LIST}/${id}`, axiosConfig(authData!.token));
  };
  const axiosUpdateGlobalnaDatoteka = (id: number, globalnaDatoteka: GlobalnaDatotekaUpdateDto) => {
    return axios.patch(`${Endpoint.GLOBALNA_DATOTEKA_LIST}/${id}`, globalnaDatoteka, axiosConfig(authData!.token));
  };

  return {
    axiosGetGlobalnaDatotekaList,
    axiosGetGlobalnaDatoteka,
    axiosUpdateGlobalnaDatoteka,
  };
}
