import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTableRowClickEvent } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import KontrolaPravaPristupaService from "../../../../infrastructure/system/KontrolaPravaPristupaService";
import { DATE_TIME_FORMAT, tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import KontaktDosijeSimpleDto from "../../../../model/kontakt/KontaktDosijeSimpleDto";
import RadnikSkracenoReadDto from "../../../../model/radnik/RadnikSkracenoReadDto";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import PregledKontakataLogical from "./PregledKontakataLogical";
import Kontakt from "./kontakt/Kontakt";

interface PregledKontakataPropsType {
  kontaktList: Array<KontaktDosijeSimpleDto>;
  pacijentAndKontaktListLoading: boolean;
  pacijentID: number;
  setKontaktList: React.Dispatch<React.SetStateAction<KontaktDosijeSimpleDto[]>>;
}

export default function PregledKontakata(props: PregledKontakataPropsType) {
  const { kontaktList, pacijentAndKontaktListLoading, pacijentID, setKontaktList } = props;
  const { kontaktSearchParams, setKontaktSearchParams, search, filterData, pregledKontakataDataLoading, kontaktListLoading } = PregledKontakataLogical(pacijentID, setKontaktList);
  const Labels = useLabels();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [scrollHeight, setScrollHeight] = useState<string>("400px");
  const [expandedRows, setExpandedRows] = useState<Array<any>>([]);
  const { authData } = useContext(AppContext);

  const radnikDropdownLabelTemplate = (primarniDoktor: RadnikSkracenoReadDto) => (primarniDoktor.titula ? primarniDoktor.titula + " " : "") + primarniDoktor.ime + " " + primarniDoktor.prezime;

  const radnikDropdownValueTemplate = (primarniDoktor: RadnikSkracenoReadDto) => {
    return primarniDoktor ? (primarniDoktor?.titula ? `${primarniDoktor.titula} ` : ``) + `${primarniDoktor.ime} ${primarniDoktor?.prezime}` : `${Labels.PRIMARNI_DOKTOR}`;
  };

  const { hasKontaktRead } = KontrolaPravaPristupaService();

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: "", headerClassName: "col-1 small-col" },
    { columnName: "", filter: true, headerClassName: "col-2" },
    { columnName: "", filter: true, headerClassName: "col-2" },
    { columnName: "", filter: true, headerClassName: "col-2" },
    { columnName: "", filter: true, headerClassName: "col-2" },
    { columnName: "", filter: true, headerClassName: "col-2" },
    { columnName: "", headerClassName: "col-1 small-col" },
  ];

  const primarniDoktorBody = (rowKontakt: KontaktDosijeSimpleDto) => {
    return (
      <>
        {(rowKontakt?.primarniDoktor?.titula ? rowKontakt?.primarniDoktor?.titula + " " : "") +
          (rowKontakt?.primarniDoktor?.ime ? rowKontakt?.primarniDoktor?.ime + " " : "") +
          (rowKontakt?.primarniDoktor?.prezime ?? "")}
      </>
    );
  };

  const periodBody = (rowKontakt: KontaktDosijeSimpleDto) => {
    return <>{moment(rowKontakt.vremeOd).format(DATE_TIME_FORMAT)}</>;
  };

  const searchButtonHeader = () => {
    return (
      <Button
        label={Labels.PREGLED_NALAZA_PRIKAZI}
        tooltip={Labels.PREGLED_NALAZA_PRIKAZI}
        tooltipOptions={tooltipOptionsBottom}
        className="w-auto p-button-outlined p-button-tertiary"
        onClick={search}
      />
    );
  };
  const rowExpansionTemplate = (rowKontakt: KontaktDosijeSimpleDto) => {
    return <Kontakt kontakt={rowKontakt} />;
  };

  const primarniDoktorFilter = (options: ColumnFilterElementTemplateOptions) => {
    return pregledKontakataDataLoading ? (
      <SkeletonInputItem />
    ) : (
      <Dropdown
        filter
        placeholder={Labels.PRIMARNI_DOKTOR}
        className="w-full"
        options={filterData?.primarniDoktorList}
        optionLabel="ime"
        optionValue="id"
        itemTemplate={radnikDropdownLabelTemplate}
        valueTemplate={radnikDropdownValueTemplate}
        filterBy="titula,ime,prezime"
        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
        onChange={(e) => {
          setKontaktSearchParams({ ...kontaktSearchParams, primarniDoktorID: e.value });
        }}
        value={kontaktSearchParams?.primarniDoktorID ?? undefined}
        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
        disabled={false}
        resetFilterOnHide
        showClear
      />
    );
  };

  const periodFilter = (options: ColumnFilterElementTemplateOptions) => {
    return pregledKontakataDataLoading ? (
      <SkeletonInputItem />
    ) : (
      <Dropdown
        className="w-full"
        placeholder={Labels.LABEL_PERIOD}
        options={filterData?.dokumentacijePeriodList}
        disabled={false}
        resetFilterOnHide
        value={kontaktSearchParams.dokumentacijaPeriodSifra ?? undefined}
        optionLabel="naziv"
        optionValue="sifra"
        showClear
        onChange={(e) => {
          setKontaktSearchParams({ ...kontaktSearchParams, dokumentacijaPeriodSifra: e.value ?? undefined });
        }}
        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
      />
    );
  };

  const kontaktTipFilter = (options: ColumnFilterElementTemplateOptions) => {
    return pregledKontakataDataLoading ? (
      <SkeletonInputItem />
    ) : (
      <Dropdown
        filter
        placeholder={Labels.TIP_KONTAKTA}
        className="w-full"
        options={filterData?.kontaktTipList}
        disabled={false}
        resetFilterOnHide
        value={kontaktSearchParams?.kontaktTipID ?? undefined}
        optionLabel="naziv"
        optionValue="id"
        showClear
        onChange={(e) => {
          setKontaktSearchParams({ ...kontaktSearchParams, kontaktTipID: e.value });
        }}
        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
      />
    );
  };

  const organizacionaJedinicaFilter = (options: ColumnFilterElementTemplateOptions) => {
    return pregledKontakataDataLoading ? (
      <SkeletonInputItem />
    ) : (
      <Dropdown
        placeholder={Labels.LABEL_ORGANIZACIONA_JEDINICA}
        className="w-full"
        filter
        options={filterData?.organizacionaJedinicaList}
        disabled={false}
        resetFilterOnHide
        value={kontaktSearchParams?.organizacionaJedinicaID ?? undefined}
        optionLabel="naziv"
        optionValue="id"
        showClear
        onChange={(e) => {
          setKontaktSearchParams({ ...kontaktSearchParams, organizacionaJedinicaID: e.value });
        }}
        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
      />
    );
  };

  const onRowClick = (e: DataTableRowClickEvent) => {
    const isRowExpanded = expandedRows.some((row) => row.id === e.data.id);
    let newExpandedRows;
    if (isRowExpanded) {
      newExpandedRows = expandedRows.filter((row) => row.id !== e.data.id);
    } else {
      newExpandedRows = [...expandedRows, e.data];
    }

    setExpandedRows(newExpandedRows);
    if (newExpandedRows.length > 0) {
      setScrollHeight("600px");
    } else {
      setScrollHeight("400px");
    }
  };

  return (
    <div className="layout-pregled-nalaza">
      <Panel>
        {!pacijentAndKontaktListLoading && !kontaktListLoading ? (
          <DataTable
            value={kontaktList}
            emptyMessage={Labels.KONTAKT_NEMA_REALIZOVANIH}
            rowHover
            stripedRows
            filterDisplay="row"
            expandedRows={selectedRow}
            onRowToggle={(e) => {
              setSelectedRow(e.data);
              let selectedRowsToArray = e.data?.map !== false && e.data?.map !== true && e.data?.map((elem: any) => elem);
              setExpandedRows(selectedRowsToArray ? selectedRowsToArray : []);
              if (e.data.length) {
                setScrollHeight("600px");
              } else {
                setScrollHeight("400px");
              }
            }}
            rowExpansionTemplate={rowExpansionTemplate}
            collapsedRowIcon="pi pi-caret-right"
            expandedRowIcon="pi pi-caret-down"
            className="pregledKontakta-table"
            scrollable
            scrollHeight={scrollHeight}
            onRowClick={(e) => {
              (hasKontaktRead || e.data.radnikKreirao.id === authData?.currentRadnik.id) &&
                setSelectedRow((prev: any) => (prev ? (prev.filter((elem: any) => elem === e.data).length > 0 ? prev.filter((elem: any) => elem !== e.data) : [...prev, e.data]) : [e.data]));
              onRowClick(e);
            }}
          >
            <Column className="col-1 small-col" expander={(data) => hasKontaktRead || data?.radnikKreirao.id === authData?.currentRadnik.id} />
            <Column className="col-2" body={primarniDoktorBody} filter filterElement={primarniDoktorFilter} showFilterMenu={false} />
            <Column className="col-2" body={periodBody} filter filterElement={periodFilter} showFilterMenu={false} />
            <Column className="col-2" field={"organizacionaJedinica.naziv"} filter filterElement={organizacionaJedinicaFilter} showFilterMenu={false} />
            <Column className="col-2" field={"kontaktTip.naziv"} filter filterElement={kontaktTipFilter} showFilterMenu={false} />
            <Column
              className="col-2"
              field={"protokolBroj.kompozitniPrikaz"}
              filterPlaceholder={Labels.LABEL_PACIJENT_DOSIJE_BROJ_PROTOKOLA}
              filter
              filterMatchMode="contains"
              showFilterMenu={false}
            />
            <Column className="col-1 small-col" filter filterElement={searchButtonHeader} showFilterMenu={false} />
          </DataTable>
        ) : (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} />
        )}
      </Panel>
    </div>
  );
}
