import { useContext, useMemo } from "react";
import { AppContext } from "../../Store";
import EnumAplikativnaPrava from "../../infrastructure/system/EnumAplikativnaPrava";
import { isSifraIncluded } from "../../infrastructure/system/Utils";

interface AplikativnaPravaServiceType {
  isAdminUstanove: boolean;
  isAdminOrgJed: boolean;
  isAdminUlogaIRadnika: boolean;
  isAdminTipovaPregledaFormularaITipovaPolja: boolean;
  isAdminSifarnika: boolean;
  isAdminFaktoraRizika: boolean;
  isAdminObavestenja: boolean;
  isAdminPacijentPolja: boolean;
  isAdminRadnihPerioda: boolean;
  isAdminIzvestaja: boolean;
  isAdminDelovodnihKnjiga: boolean;
  isAdminPaketa: boolean;
  isAdminPartnera: boolean;
  isAdminGrupnihObavestenja: boolean;
  hasPretragaPacijenta: boolean;
  hasZakazivanjeTermina: boolean;
  hasGenerisanjeIzvestaja: boolean;
  hasPregledLoga: boolean;
  hasNaplata: boolean;
  hasSpajanjePacijenata: boolean;
}

export function AplikativnaPravaService(): AplikativnaPravaServiceType {
  const { authData } = useContext(AppContext);
  const { radnikUlogaList, ustanovaPoslednjaIzabrana } = authData!.currentRadnik;
  const {
    isAdminUstanove,
    isAdminOrgJed,
    isAdminUlogaIRadnika,
    hasGenerisanjeIzvestaja,
    hasPregledLoga,
    hasPretragaPacijenta,
    hasZakazivanjeTermina,
    isAdminDelovodnihKnjiga,
    isAdminFaktoraRizika,
    isAdminIzvestaja,
    isAdminObavestenja,
    isAdminPacijentPolja,
    isAdminRadnihPerioda,
    isAdminSifarnika,
    isAdminTipovaPregledaFormularaITipovaPolja,
    isAdminPaketa,
    isAdminPartnera,
    isAdminGrupnihObavestenja,
    hasNaplata,
    hasSpajanjePacijenata,
  }: AplikativnaPravaServiceType = useMemo(() => {
    const aplPravaList =
      radnikUlogaList
        .filter((ru) => ru.uloga.ustanova.id === ustanovaPoslednjaIzabrana.id)
        .flatMap((filteredRU) => filteredRU.uloga.ulogaAplikativnoPravoList)
        .map((aplPravo) => aplPravo.aplikativnoPravo)
        .filter((obj, index, self) => index === self.findIndex((o) => o.id === obj.id)) ?? [];

    return {
      isAdminUstanove: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_USTANOVE),
      isAdminOrgJed: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_ORG_JED),
      isAdminUlogaIRadnika: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_ULOGA_I_RADNIKA),
      isAdminTipovaPregledaFormularaITipovaPolja: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_TIPOVA_PREGLEDA_FORMULARA_I_TIPOVA_POLJA),
      isAdminSifarnika: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_SIFARNIKA),
      isAdminFaktoraRizika: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_FAKTORA_RIZIKA),
      isAdminObavestenja: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_OBAVESTENJA),
      isAdminPacijentPolja: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_PACIJENT_POLJA),
      isAdminRadnihPerioda: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_RADNIH_PERIODA),
      isAdminIzvestaja: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_IZVESTAJA),
      isAdminDelovodnihKnjiga: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_DELOVODNIH_KNJIGA),
      isAdminPaketa: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_PAKETA),
      isAdminPartnera: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_PARTNERA),
      isAdminGrupnihObavestenja: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ADMINISTRACIJA_GRUPNIH_OBAVESTENJA),
      hasPretragaPacijenta: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.PRETRAGA_PACIJENATA),
      hasZakazivanjeTermina: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.ZAKAZIVANJE_TERMINA),
      hasGenerisanjeIzvestaja: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.GENERISANJE_IZVESTAJA),
      hasPregledLoga: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.PREGLED_LOGA),
      hasNaplata: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.NAPLATA),
      hasSpajanjePacijenata: isSifraIncluded(aplPravaList, EnumAplikativnaPrava.SPAJANJE_PACIJENATA),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radnikUlogaList]);

  return {
    isAdminUstanove,
    isAdminOrgJed,
    isAdminUlogaIRadnika,
    hasGenerisanjeIzvestaja,
    hasPregledLoga,
    hasPretragaPacijenta,
    hasZakazivanjeTermina,
    isAdminTipovaPregledaFormularaITipovaPolja,
    isAdminDelovodnihKnjiga,
    isAdminFaktoraRizika,
    isAdminIzvestaja,
    isAdminObavestenja,
    isAdminPacijentPolja,
    isAdminRadnihPerioda,
    isAdminSifarnika,
    isAdminPaketa,
    isAdminPartnera,
    isAdminGrupnihObavestenja,
    hasNaplata,
    hasSpajanjePacijenata,
  };
}
export type { AplikativnaPravaServiceType };
