import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";
import SifarnikNacinPlacanjaCreateDto from "../../model/sifarnik/sifarnik-nacina-placanja/SifarnikNacinPlacanjaCreateDto";
import SifarnikNacinaPlacanjaReadDto from "../../model/sifarnik/sifarnik-nacina-placanja/SifarnikNacinPlacanjaReadDto";
import SifarnikNacinaPlacanjaUpdateDto from "../../model/sifarnik/sifarnik-nacina-placanja/SifarnikNacinPlacanjaUpdateDto";

interface SifarnikNacinaPlacanjaControllerType {
  axiosGetSifarnikNacinaPlacanjaList: () => Promise<AxiosResponse<CommonResponseDto<SifarnikNacinaPlacanjaReadDto[]>>>;
  axiosGetSifarnikNacinaPlacanja: (sifarnikNacinaPlacanjaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateSifarnikNacinaPlacanja: (sifarnikNacinaPlacanjaChange: SifarnikNacinPlacanjaCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteSifarnikNacinaPlacanja: (sifarnikNacinaPlacanjaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateSifarnikNacinaPlacanja: (sifarnikNacinaPlacanja: SifarnikNacinaPlacanjaReadDto) => Promise<AxiosResponse<any>>;
}

export function SifarnikNacinaPlacanjaController(): SifarnikNacinaPlacanjaControllerType {
  const { authData } = useContext(AppContext);
  const { SIFARNIK_NACINA_PLACANJA_LIST } = Endpoint;

  const axiosGetSifarnikNacinaPlacanjaList = () => {
    return axios.get(SIFARNIK_NACINA_PLACANJA_LIST, axiosConfig(authData!.token));
  };

  const axiosGetSifarnikNacinaPlacanja = (sifarnikNacinaPlacanjaId: number) => {
    return axios.get(`${SIFARNIK_NACINA_PLACANJA_LIST}/${sifarnikNacinaPlacanjaId}`, axiosConfig(authData!.token));
  };

  const axiosCreateSifarnikNacinaPlacanja = (sifarnikNacinaPlacanjaChange: SifarnikNacinPlacanjaCreateDto) => {
    return axios.post(`${Endpoint.SIFARNIK_NACINA_PLACANJA_LIST}`, sifarnikNacinaPlacanjaChange, axiosConfig(authData!.token));
  };

  const axiosDeleteSifarnikNacinaPlacanja = (sifarnikNacinaPlacanjaId: number) => {
    return axios.delete(`${Endpoint.SIFARNIK_NACINA_PLACANJA_LIST}/${sifarnikNacinaPlacanjaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateSifarnikNacinaPlacanja = (sifarnikNacinaPlacanja: SifarnikNacinaPlacanjaUpdateDto) => {
    return axios.put(`${Endpoint.SIFARNIK_NACINA_PLACANJA_LIST}/${sifarnikNacinaPlacanja?.id}`, sifarnikNacinaPlacanja, axiosConfig(authData!.token));
  };

  return {
    axiosGetSifarnikNacinaPlacanjaList,
    axiosGetSifarnikNacinaPlacanja,
    axiosCreateSifarnikNacinaPlacanja,
    axiosDeleteSifarnikNacinaPlacanja,
    axiosUpdateSifarnikNacinaPlacanja,
  };
}
