import { useQuery } from "@tanstack/react-query";
import { ZakazaniTerminController } from "../../../../../controllers/zakazani-termin/ZakazaniTerminController";
import QueryKeys from "../../../../../infrastructure/system/QueryKeys";

export function useZakazanTerminDatotekaListForPacijent(pacijentID: number) {
  const { axiosGetZakazanTerminDatotekaListForPacijent } = ZakazaniTerminController();
  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.ZAKAZAN_TERMIN_DATOTEKA_LIST, pacijentID],
    queryFn: () => axiosGetZakazanTerminDatotekaListForPacijent(pacijentID),
  });

  const zakazanTerminDatotekaList = data?.data?.data;

  return { zakazanTerminDatotekaList, refetch, isLoading };
}
