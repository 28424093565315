import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import SifarnikJedinicaMereReadDto from "../../../model/sifarnik/sifarnik-jedinica-mere/SifarnikJedinicaMereReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import SifarnikJedinicaMereListLogical from "./SifarnikJedinicaMereListLogical";

export default function SifarnikJedinicaMereListView() {
  const { exportData, dt, sifarnikJedinicaMereList, first, tableRows, onPage, openSifarnikJedinicaMere, sifarnikJedinicaMereLoading, breadCrumbItems } = SifarnikJedinicaMereListLogical();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const addButton = () => (
    <Button
      label={Labels.SIFARNIK_JEDINICA_MERE_BUTTON_DODAJ}
      tooltip={Labels.SIFARNIK_JEDINICA_MERE_TITLE_CREATE}
      tooltipOptions={tooltipOptionsBottom}
      className="p-button-success mr-3 mb-4 sm:mb-4 md:mb-0"
      onClick={() => {
        openSifarnikJedinicaMere(EntityOperation.CREATE);
      }}
      disabled={!pristup}
    />
  );

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={sifarnikJedinicaMereLoading} data={exportData} name={Labels.SIFARNIK_JEDINICA_MERE} dt={dt} />
      </>
    );
  };

  const crudBodyTemplate = (rowSifarnikJedinicaMere: SifarnikJedinicaMereReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_JEDINICA_MERE_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowSifarnikJedinicaMere && openSifarnikJedinicaMere(EntityOperation.UPDATE, rowSifarnikJedinicaMere.id)}
        />
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_JEDINICA_MERE_TITLE_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowSifarnikJedinicaMere && openSifarnikJedinicaMere(EntityOperation.DELETE, rowSifarnikJedinicaMere.id)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.SIFARNIK_JEDINICA_MERE_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_JEDINICA_MERE_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_JEDINICA_MERE_AKRONIM, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={addButton} right={downloadButton} />
        {sifarnikJedinicaMereLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={sifarnikJedinicaMereList ?? []}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e: DataTablePageEvent) => onPage(e.rows, e.first)}
            exportFilename={Labels.SIFARNIK_JEDINICA_MERE}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.SIFARNIK_JEDINICA_MERE_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.SIFARNIK_JEDINICA_MERE_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"akronim"} header={Labels.SIFARNIK_JEDINICA_MERE_AKRONIM} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
