import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useLabels } from "../../../Store";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

interface ProtokolLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

export default function ProtokolLogical(): ProtokolLogicalType {
  const Labels = useLabels();
  const location = useLocation();
  const [tabIndex] = useState(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);

  const [index, setIndex] = useState(tabIndex);
  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.PROTOKOL_LIST,
    },
  ];
  return {
    breadCrumbItems,
    index,
    setIndex,
  };
}

export type { ProtokolLogicalType };
