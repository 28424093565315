import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";

const LoadingPanel = () => {
  return (
    <Panel className="loading-panel">
      <ProgressSpinner />
    </Panel>
  );
};

export default LoadingPanel;
