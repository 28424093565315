import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import SifarnikUslugaReadDto from "../../../model/sifarnik-usluga/SifarnikUslugaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import SifarnikUslugaListLogical from "./SifarnikUslugaListLogical";

export default function SifarnikUslugaListView() {
  const {
    sifarnikUslugaList,
    first,
    tableRows,
    changeSifarnikUslugaSearchData,
    sifarnikUslugaSearchData,
    fetchData,
    searchSifarnikUslugaByEnter,
    onPageSifarnikUsluga,
    exportData,
    breadCrumbItems,
    sifarnikUslugaLoading,
  } = SifarnikUslugaListLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const openSifarnikUsluga = (entityOperation: string, id?: number) => {
    const sifarnikUslugaId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-sifarnik-usluga/${sifarnikUslugaId}`, {
      state: { sifarnikUslugaOperation: entityOperation },
    });
  };

  const crudBodyTemplate = (rowSifarnikUsluga: SifarnikUslugaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_USLUGA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowSifarnikUsluga && openSifarnikUsluga(EntityOperation.UPDATE, rowSifarnikUsluga.id)}
        />
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_USLUGA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowSifarnikUsluga && openSifarnikUsluga(EntityOperation.DELETE, rowSifarnikUsluga.id)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_SIFARNIK_USLUGA}
          tooltip={Labels.SIFARNIK_USLUGA_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openSifarnikUsluga(EntityOperation.CREATE);
          }}
          disabled={!pristup}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={sifarnikUslugaSearchData?.searchString ?? ""}
            onChange={changeSifarnikUslugaSearchData}
            onKeyPress={searchSifarnikUslugaByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_SIFARNIK_USLUGA}</label>
          <Button tooltip={Labels.SEARCH_SIFARNIK_USLUGA} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={sifarnikUslugaLoading} data={exportData} name={Labels.LABEL_SIFARNIK_USLUGA} dt={dt} />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.SIFARNIK_USLUGA_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_USLUGA_SIFRA, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {sifarnikUslugaLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={sifarnikUslugaList ?? []}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageSifarnikUsluga(e.rows, e.first);
            }}
            exportFilename={Labels.LABEL_SIFARNIK_USLUGA}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.SIFARNIK_USLUGA_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.SIFARNIK_USLUGA_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
