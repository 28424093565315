import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../../Store";
import { DodatniModulController } from "../../../../../controllers/dodatni-modul/DodatniModulController";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../../infrastructure/system/MessageType";
import { checkEmpty, handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../../../model/BreadCrumbItemDto";
import DodatniModulReadDto from "../../../../../model/licenca/dodatni-modul/DodatniModulReadDto";
import UstanovaDodatniModulReadDto from "../../../../../model/ustanova/UstanovaDodatniModulReadDto";
import UstanovaDodatniModulUpdateDto from "../../../../../model/ustanova/UstanovaDodatniModulUpdateDto";

interface CrudDodatniModulUstanovaLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  dodatniModulUstanovaChange: UstanovaDodatniModulReadDto | undefined;
  setDodatniModulUstanovaChange: React.Dispatch<React.SetStateAction<UstanovaDodatniModulReadDto | undefined>>;
  isDisabled: boolean;
  dodatniModulOperation: string;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onCancel: () => void;
  dodatniModulLoading: boolean;
  ustanovaDodatniModulId: number;
  dodatniModulList: Array<DodatniModulReadDto> | undefined;
  vaziOdRef: HTMLDivElement | any;
  vaziDoRef: HTMLDivElement | any;
}

interface UseParamsType {
  ustanovaDodatniModulId: string;
  ustanovaId?: string;
}

export default function CrudDodatniModulLogical(): CrudDodatniModulUstanovaLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const [dodatniModulUstanovaChange, setDodatniModulUstanovaChange] = useState<UstanovaDodatniModulReadDto | undefined>();
  const [dodatniModulOperation, setDodatniModulOperation] = useState(location.state?.dodatniModulOperation || EntityOperation.UPDATE);
  const [isDisabled] = useState(isFormDisabled(dodatniModulOperation));
  const ustanovaDodatniModulId = Number(useParams<keyof UseParamsType>()["ustanovaDodatniModulId"]);
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const [dodatniModulLoading, setDodatniModulLoading] = useState(true);
  const [dodatniModulList, setDodatniModulList] = useState<Array<DodatniModulReadDto> | undefined>();
  const [ustanovaNaziv] = useState<string>(location.state && location.state.ustanovaNaziv !== undefined ? location.state.ustanovaNaziv : "");
  const vaziOdRef = useRef<HTMLDivElement | any>(null);
  const vaziDoRef = useRef<HTMLDivElement | any>(null);
  const { axiosSearchDodatniModulList, axiosUstanovaGetDodatniModul, axiosUstanovaCreateDodatniModul, axiosUstanovaUpdateDodatniModul, axiosUstanovaDeleteDodatniModul } = DodatniModulController();
  const postLogHighLevel = useLogHighLevel();
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.USTANOVA_LIST,
      command: () => {
        navigate(`/crud-ustanova`);
      },
    },
    {
      label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + " " + ustanovaNaziv,
      command: () => {
        navigate(`/crud-ustanova/${ustanovaId}`, {
          state: {
            tabIndex: 1,
            licenceTabIndex: 1,
          },
        });
      },
    },
  ]);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (dmuID?: number, operation?: string) => {
    const newDodatniModulId = dmuID || ustanovaDodatniModulId;
    const startTime = moment(new Date());
    if (newDodatniModulId) {
      axiosUstanovaGetDodatniModul(newDodatniModulId, ustanovaId)
        .then(({ data: { data } }: { data: { data: UstanovaDodatniModulReadDto } }) => {
          setDodatniModulUstanovaChange(data);
          const operationTmp = operation ?? dodatniModulOperation;
          setBreadCrumbItems([
            ...breadCrumbItems,
            {
              label: operationTmp === EntityOperation.UPDATE ? Labels.DODATNI_MODUL_TITLE_UPDATE + data.dodatniModul.naziv : Labels.DODATNI_MODUL_TITLE_DELETE + data.dodatniModul.naziv,
            },
          ]);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setDodatniModulLoading, startTime);
        });
    } else {
      setDodatniModulLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.DODATNI_MODUL_TITLE_CREATE,
        },
      ]);
    }
    axiosSearchDodatniModulList({})
      .then(({ data: { data } }: { data: { data: Array<DodatniModulReadDto> } }) => {
        setDodatniModulList(data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const validateInput = (dodatniModul: UstanovaDodatniModulReadDto | undefined) => {
    if (dodatniModul === undefined || validateStringEmpty(dodatniModul.dodatniModul.naziv)) {
      showMessage(MessageType.ERROR, Labels.DODATNI_MODUL_NAZIV_IS_REQUIRED);
      return false;
    }

    if (!checkEmpty(dodatniModul.vaziDo) && !moment(dodatniModul.vaziDo).isAfter(moment(dodatniModul.vaziOd), "days")) {
      showMessage(MessageType.ERROR, Labels.DODATNI_MODUL_VREME_DO_AFTER_VREME_OD);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validateInput(dodatniModulUstanovaChange)) {
      return;
    }

    if (dodatniModulUstanovaChange) {
      setShowBlockUI(true);
      axiosUstanovaCreateDodatniModul({ ...dodatniModulUstanovaChange, ustanova: { id: ustanovaId } }, ustanovaId)
        .then(({ data: { data } }: { data: { data: UstanovaDodatniModulReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.DODATNI_MODUL_MESSAGE_CREATE_SUCCESS);
          setDodatniModulOperation(EntityOperation.UPDATE);
          fetchData(data.id, EntityOperation.UPDATE);
          navigate(`/crud-ustanova/${ustanovaId}`, {
            state: {
              tabIndex: 1,
              licenceTabIndex: 1,
            },
          });
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });

      postLogHighLevel(
        Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_1 +
          ustanovaNaziv +
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_2 +
          dodatniModulUstanovaChange.dodatniModul.naziv +
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_3 +
          dodatniModulUstanovaChange.vaziOd +
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_4 +
          (dodatniModulUstanovaChange.vaziDo ?? "/")
      );
    }
  };

  const onUpdate = () => {
    if (!validateInput(dodatniModulUstanovaChange)) {
      return;
    }
    if (dodatniModulUstanovaChange) {
      setShowBlockUI(true);
      axiosUstanovaUpdateDodatniModul(dodatniModulUstanovaChange as UstanovaDodatniModulUpdateDto, ustanovaId)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.DODATNI_MODUL_MESSAGE_UPDATE_SUCCESS);
          onCancel();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });

      postLogHighLevel(
        Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_1 +
          ustanovaNaziv +
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_2 +
          dodatniModulUstanovaChange.dodatniModul.naziv +
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_3 +
          dodatniModulUstanovaChange.vaziOd +
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_4 +
          (dodatniModulUstanovaChange.vaziDo ?? "/")
      );
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosUstanovaDeleteDodatniModul(ustanovaDodatniModulId, ustanovaId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.DODATNI_MODUL_MESSAGE_DELETE_SUCCESS);
        onCancel();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });

    postLogHighLevel(
      Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_1 +
        ustanovaNaziv +
        Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_2 +
        dodatniModulUstanovaChange!.dodatniModul.naziv +
        Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_3 +
        dodatniModulUstanovaChange!.vaziOd +
        Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_4 +
        (dodatniModulUstanovaChange!.vaziDo ?? "/")
    );
  };

  const onCancel = () => {
    const breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
    breadCrumb.command && breadCrumb.command();
  };

  return {
    breadCrumbItems,
    dodatniModulUstanovaChange,
    setDodatniModulUstanovaChange,
    isDisabled,
    dodatniModulOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    dodatniModulLoading,
    ustanovaDodatniModulId,
    dodatniModulList,
    vaziOdRef,
    vaziDoRef,
  };
}
