import { AxiosResponse } from "axios";
import moment from "moment/moment";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { EnumController } from "../../../controllers/enum/EnumController";
import { GrupnoObavestenjeController } from "../../../controllers/obavestenja-za-pacijente/GrupnoObavestenjeController";
import { PacijentController } from "../../../controllers/pacijent/PacijentController";
import { SifarnikUslugaController } from "../../../controllers/sifarnik-usluga/SifarnikUslugaController";
import { SifarnikController } from "../../../controllers/sifarnik/SifarnikController";
import EnumPol from "../../../infrastructure/system/EnumPol";
import MessageType from "../../../infrastructure/system/MessageType";
import { checkEmpty, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import actionsAddPatientNotification from "../../../infrastructure/system/hooks/dialog-add-patient-notification/actionsAddPatientNotification";
import InitialStateAddPatientNotification, { InitialStateAddPatientNotificationType } from "../../../infrastructure/system/hooks/dialog-add-patient-notification/initialStateAddPatientNotification";
import reducerAddPatientNotification from "../../../infrastructure/system/hooks/dialog-add-patient-notification/reducerAddPatientNotification";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import EnumObavestenjeStatus from "../../../model/obavestenja-pacijenti/EnumObavestenjeStatus";
import EnumTipObavestenja from "../../../model/obavestenja-pacijenti/EnumTipObavestenja";
import GrupnoObavestenjeSimpleReadDto from "../../../model/obavestenja-pacijenti/GrupnoObavestenjeSimpleReadDto";
import GrupnoObavestenjeUpdateDto from "../../../model/obavestenja-pacijenti/GrupnoObavestenjeUpdateDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SifarnikMkbDto from "../../../model/sifarnik/SifarnikMkbDto";
import SifarnikUslugaDto from "../../../model/sifarnik/SifarnikUslugaDto";

interface ObavestenjaPacijentiListLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  state: InitialStateAddPatientNotificationType;
  dispatch: React.Dispatch<{ type: actionsAddPatientNotification }> | any;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: (obavestenje: GrupnoObavestenjeSimpleReadDto) => void;
  obavestenjaZaSlanjeList: Array<GrupnoObavestenjeSimpleReadDto>;
  obavestenjaZaSlanjeListLoading: boolean;
  obavestenjaPoslatoList: Array<GrupnoObavestenjeSimpleReadDto>;
  obavestenjaPoslatoListLoading: boolean;
  onSend: (obavestenje: GrupnoObavestenjeSimpleReadDto) => void;
  hasMoreObavestenjaZaSlanje: React.MutableRefObject<boolean>;
  hasMoreObavestenjaPoslato: React.MutableRefObject<boolean>;
  fetchObavestenjaZaSlanje: () => void;
  fetchObavestenjaPoslata: () => void;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  obavestenjeZaSlanjeFilters: { obavestenjeTipSifra?: string; datum?: Date | string; searchString?: string };
  setObavestenjeZaSlanjeFilters: React.Dispatch<React.SetStateAction<{ obavestenjeTipSifra?: string; datum?: Date | string; searchString?: string }>>;
  obavestenjePoslatoFilters: { obavestenjeTipSifra?: string; datum?: Date | string; searchString?: string };
  setObavestenjePoslatoFilters: React.Dispatch<React.SetStateAction<{ obavestenjeTipSifra?: string; datum?: Date | string; searchString?: string }>>;
  getPatientIncluded: () => void;
  mkbDijagnozeList: Array<SifarnikMkbDto | undefined>;
  searchMkbDijagnoza: (e: AutoCompleteCompleteEvent) => void;
  searchSifarnikUsluga: (e: AutoCompleteCompleteEvent) => void;
  sifarnikUslugaList: Array<SifarnikUslugaDto>;
  fetchObavestenje: (obavestenjeId: number, action: string, status: EnumBaseReadDto) => void;
  fetchObavestenjePreSlanja: (obavestenje: GrupnoObavestenjeSimpleReadDto) => void;
  scrollPanelRef: any;
  isOpenConfirmationDialog: boolean;
  setIsOpenConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedObavestenje: GrupnoObavestenjeSimpleReadDto;
}

export default function ObavestenjaPacijentiListLogical(): ObavestenjaPacijentiListLogicalType {
  const Labels = useLabels();
  const { showMessage } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducerAddPatientNotification, InitialStateAddPatientNotification);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const [obavestenjaZaSlanjeList, setObavestenjaZaSlanjeList] = useState<Array<GrupnoObavestenjeSimpleReadDto>>([]);
  const [obavestenjaPoslatoList, setObavestenjaPoslatoList] = useState<Array<GrupnoObavestenjeSimpleReadDto>>([]);
  const [obavestenjaZaSlanjeListLoading, setObavestenjaZaSlanjeListLoading] = useState<boolean>(false);
  const [obavestenjaPoslatoListLoading, setObavestenjaPoslatoListLoading] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [obavestenjeZaSlanjeFilters, setObavestenjeZaSlanjeFilters] = useState<{ obavestenjeTipSifra?: string; datum?: Date | string; searchString?: string }>({});
  const [obavestenjePoslatoFilters, setObavestenjePoslatoFilters] = useState<{ obavestenjeTipSifra?: string; datum?: Date | string; searchString?: string }>({});
  const [mkbDijagnozeList, setMkbDijagnozeList] = useState<Array<SifarnikMkbDto | undefined>>([]);
  const [sifarnikUslugaList, setSifarnikUslugaList] = useState<Array<SifarnikUslugaDto>>([]);
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState<boolean>(false);
  const [selectedObavestenje, setSelectedObavestenje] = useState<any>();
  const { axiosGetObavestenjeTipList, axiosGetPolList } = EnumController();
  const { axiosGetGrupnaObavestenja, axiosCreateGrupnoObavestenje, axiosDeleteGrupnoObavestenje, axiosUpdateGrupnoObavestenje, axiosSendGrupnoObavestenje, axiosGetGrupnoObavestenje } =
    GrupnoObavestenjeController();
  const { axiosGetPacijentGrupnoObavestenjeIncluded } = PacijentController();
  const { axiosSearchSifarnikMKB } = SifarnikController();
  const { axiosSearchSifarnikUslugaByNazivOrSifra } = SifarnikUslugaController();
  const postLogHighLevel = useLogHighLevel();
  const pageZaSlanje = useRef(0);
  const pagePoslato = useRef(0);
  const hasMoreObavestenjaZaSlanje = useRef(true);
  const hasMoreObavestenjaPoslato = useRef(true);
  const scrollPanelRef = useRef<any>(null);

  const breadCrumbItems = [
    {
      label: Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE,
    },
  ];

  useEffectOnce(() => {
    fetchObavestenjaTypes();
    getGendersList();
  });

  useEffect(() => {
    pageZaSlanje.current = 0;
    fetchObavestenjaZaSlanje();
    // eslint-disable-next-line
  }, [obavestenjeZaSlanjeFilters]);

  useEffect(() => {
    pagePoslato.current = 0;
    fetchObavestenjaPoslata();
    // eslint-disable-next-line
  }, [obavestenjePoslatoFilters]);

  useEffect(() => {
    scrollPanelRef.current.refresh();
  }, [index]);

  const fetchObavestenjaTypes = () => {
    dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_TYPE_LIST_LOADING, value: true });
    axiosGetObavestenjeTipList()
      .then((response: AxiosResponse) => {
        // dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_TYPE_LIST, value: response.data.data });
        // obrisati ovo dole posle pustanja na test i otkomentarisati kod iznad
        let withoutSms = response.data.data.filter((notificationType: EnumBaseReadDto) => notificationType.sifra !== EnumTipObavestenja.SMS);
        dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_TYPE_LIST, value: withoutSms });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_TYPE_LIST_LOADING, value: false });
      });
  };

  const fetchObavestenjaZaSlanje = () => {
    const startTime = moment(new Date());
    if (pageZaSlanje.current === 0) setObavestenjaZaSlanjeListLoading(true);
    const searchParamsZaSlanje = { obavestenjeStatusSifra: EnumObavestenjeStatus.NA_CEKANJU, page: pageZaSlanje.current, size: 10, ...obavestenjeZaSlanjeFilters };

    axiosGetGrupnaObavestenja(searchParamsZaSlanje)
      .then((responseGrupnaObavestenjaZaSlanje: AxiosResponse) => {
        responseGrupnaObavestenjaZaSlanje.data.data.length === 0 ? (hasMoreObavestenjaZaSlanje.current = false) : (hasMoreObavestenjaZaSlanje.current = true);
        pageZaSlanje.current === 0
          ? setObavestenjaZaSlanjeList(responseGrupnaObavestenjaZaSlanje.data.data)
          : setObavestenjaZaSlanjeList((prevList) => prevList.concat(responseGrupnaObavestenjaZaSlanje.data.data));
        pageZaSlanje.current++;
      })

      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setObavestenjaZaSlanjeListLoading, startTime);
      });
  };

  const fetchObavestenjeZaSlanje = (obavestenjeId: number) => {
    const startTime = moment(new Date());
    if (pageZaSlanje.current === 0) setObavestenjaZaSlanjeListLoading(true);

    axiosGetGrupnoObavestenje(obavestenjeId)
      .then((responseData: AxiosResponse) => {
        setObavestenjaZaSlanjeList((prevList) =>
          prevList
            .filter((elem) => elem.id !== obavestenjeId)
            .concat(responseData.data.data)
            .sort((a, b) => b.id - a.id)
        );
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setObavestenjaZaSlanjeListLoading, startTime);
      });
  };

  const fetchObavestenjaPoslata = () => {
    const startTime = moment(new Date());
    if (pagePoslato.current === 0) setObavestenjaPoslatoListLoading(true);
    const searchParamsPoslato = { obavestenjeStatusSifra: EnumObavestenjeStatus.POSLATO, page: pagePoslato.current, size: 10, ...obavestenjePoslatoFilters };

    axiosGetGrupnaObavestenja(searchParamsPoslato)
      .then((responseGrupnaObavestenjaPoslato) => {
        responseGrupnaObavestenjaPoslato.data.data.length === 0 ? (hasMoreObavestenjaPoslato.current = false) : (hasMoreObavestenjaPoslato.current = true);
        pagePoslato.current === 0
          ? setObavestenjaPoslatoList(responseGrupnaObavestenjaPoslato.data.data)
          : setObavestenjaPoslatoList((prevList) => prevList.concat(responseGrupnaObavestenjaPoslato.data.data));
        pagePoslato.current++;
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setObavestenjaPoslatoListLoading, startTime);
      });
  };

  const validateNewObavestenje = () => {
    let isInvalid = false;

    if (checkEmpty(state?.obavestenje?.obavestenjeTip)) {
      setInvalidFields((prev) => ({ ...prev, obavestenjeTip: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.obavestenje?.sadrzaj)) {
      setInvalidFields((prev) => ({ ...prev, sadrzaj: true }));
      scrollToBottomOfDialog();
      isInvalid = true;
    }
    if (checkEmpty(state?.obavestenje?.naslov)) {
      setInvalidFields((prev) => ({ ...prev, naslov: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const scrollToBottomOfDialog = () => {
    let dialogContent = document.querySelector(".dialogGrupnoObavestenje .p-dialog-content");
    let scrollHeightDialog = dialogContent!.scrollHeight;
    // element jos nije vidljiv, zato ovaj minimalni timeout
    setTimeout(() => {
      dialogContent!.scrollTo(0, scrollHeightDialog);
    }, 0);
  };

  const onCreate = () => {
    if (!validateNewObavestenje()) {
      return;
    }

    axiosCreateGrupnoObavestenje(state?.obavestenje)
      .then(() => {
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_1 + state?.obavestenje?.sadrzaj + Labels.LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_2 + state?.obavestenje?.obavestenjeTip?.naziv
        );
      })
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_CREATE_SUCCESS_MESSAGE);
        dispatch({ type: actionsAddPatientNotification.CLOSE_DIALOG });
        pageZaSlanje.current = 0;
        fetchObavestenjaZaSlanje();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onUpdate = () => {
    if (!validateNewObavestenje() || !state?.obavestenje?.id) {
      return;
    }

    axiosUpdateGrupnoObavestenje(state?.obavestenje)
      .then(() => {
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_GRUPNO_OBAVESTENJE_1 + state?.obavestenje?.sadrzaj + Labels.LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_2 + state?.obavestenje?.obavestenjeTip?.naziv
        );
      })
      .then(() => {
        dispatch({ type: actionsAddPatientNotification.CLOSE_DIALOG });
        showMessage(MessageType.SUCCESS, Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_UPDATE_SUCCESS_MESSAGE);
        pageZaSlanje.current = 0;
        fetchObavestenjaZaSlanje();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onDelete = (obavestenje: GrupnoObavestenjeSimpleReadDto) => {
    if (obavestenje.id) {
      axiosDeleteGrupnoObavestenje(obavestenje.id)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_GRUPNO_OBAVESTENJE_1 + state?.obavestenje?.sadrzaj + Labels.LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_2 + state?.obavestenje?.obavestenjeTip?.naziv
          );
        })
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_DELETE_SUCCESS_MESSAGE);
          if (obavestenje?.obavestenjeStatus?.sifra === EnumObavestenjeStatus.NA_CEKANJU) {
            pageZaSlanje.current = 0;
            fetchObavestenjaZaSlanje();
          }
          if (obavestenje?.obavestenjeStatus?.sifra === EnumObavestenjeStatus.POSLATO) {
            pagePoslato.current = 0;
            fetchObavestenjaPoslata();
          }
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onSend = (obavestenje: GrupnoObavestenjeSimpleReadDto) => {
    if (obavestenje.id) {
      axiosSendGrupnoObavestenje(obavestenje.id)
        .then(() => {
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_SEND_GRUPNO_OBAVESTENJE_1 + obavestenje?.sadrzaj + Labels.LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_2 + obavestenje?.obavestenjeTip?.naziv);
        })
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_MESSAGE);
          pageZaSlanje.current = 0;
          pagePoslato.current = 0;
          fetchObavestenjaZaSlanje();
          fetchObavestenjaPoslata();
          setIsOpenConfirmationDialog(false);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const getGendersList = () => {
    dispatch({ type: actionsAddPatientNotification.GENDER_LIST_LOADING, value: true });
    axiosGetPolList()
      .then((res: AxiosResponse) => {
        dispatch({ type: actionsAddPatientNotification.GENDER_LIST, value: res.data.data });
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        dispatch({ type: actionsAddPatientNotification.GENDER_LIST_LOADING, value: false });
      });
  };

  const getPatientIncluded = () => {
    let filterData = state?.patientsIncludedSearch
      ? {
          obavestenjeTipSifra: state?.patientsIncludedSearch?.obavestenjeTip ?? EnumTipObavestenja.EMAIL,
          polTrenutniSifraList:
            state?.patientsIncludedSearch?.polTrenutniSifraList && state?.patientsIncludedSearch?.polTrenutniSifraList?.length > 0
              ? state?.patientsIncludedSearch?.polTrenutniSifraList?.join(",")
              : null,
          godineOd: state?.patientsIncludedSearch?.godineOd ?? null,
          godineDo: state?.patientsIncludedSearch?.godineDo ?? null,
          sifarnikMkbIDList:
            state?.patientsIncludedSearch?.sifarnikMkbIDList && state?.patientsIncludedSearch?.sifarnikMkbIDList.length > 0 ? state?.patientsIncludedSearch?.sifarnikMkbIDList?.join(",") : [],
          sifarnikUslugaIDList:
            state?.patientsIncludedSearch?.sifarnikUslugaIDList && state?.patientsIncludedSearch?.sifarnikUslugaIDList.length > 0 ? state?.patientsIncludedSearch?.sifarnikUslugaIDList?.join(",") : [],
        }
      : { obavestenjeTipSifra: EnumTipObavestenja.EMAIL, polTrenutniSifraList: [EnumPol.MUSKI, EnumPol.ZENSKI, EnumPol.NIJE_UNET].join(",") };
    if (state?.patientsIncludedSearch?.polTrenutniSifraList?.length === 0) {
      showMessage(MessageType.ERROR, Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_POL_REQUIRED);
      return;
    }
    dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_LIST_LOADING, value: true });
    axiosGetPacijentGrupnoObavestenjeIncluded(filterData)
      .then((response: AxiosResponse) => {
        let responseData = response.data.data;
        dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_CHANGE, fieldName: "ukupnoPacijenata", value: responseData });
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_LIST_LOADING, value: false });
        }, 500);
      });
  };

  const searchMkbDijagnoza = (e: AutoCompleteCompleteEvent) => {
    if (e.query.length > 2) {
      axiosSearchSifarnikMKB({ searchString: e.query })
        .then((res: AxiosResponse) => {
          setMkbDijagnozeList(res.data.data);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const searchSifarnikUsluga = (e: AutoCompleteCompleteEvent) => {
    if (e.query.length > 2) {
      axiosSearchSifarnikUslugaByNazivOrSifra({ searchString: e.query })
        .then((res: AxiosResponse) => {
          setSifarnikUslugaList(res.data.data);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const fetchObavestenjePreSlanja = (obavestenje: GrupnoObavestenjeSimpleReadDto) => {
    dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_LOADING, value: true });
    setSelectedObavestenje(obavestenje);
    let oldValue: GrupnoObavestenjeUpdateDto;
    axiosGetGrupnoObavestenje(obavestenje.id)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        oldValue = responseData;
        let patientChangeDataFromDB = {
          obavestenjeTipSifra: responseData?.obavestenjeTip ?? EnumTipObavestenja.EMAIL,
          polTrenutniSifraList: responseData?.grupnoObavestenjeFilter?.polTrenutniList
            ? responseData?.grupnoObavestenjeFilter?.polTrenutniList?.map((item: any) => item.sifra)
            : [EnumPol.MUSKI, EnumPol.ZENSKI, EnumPol.NIJE_UNET],
          godineOd: responseData?.grupnoObavestenjeFilter?.godineOd ?? null,
          godineDo: responseData?.grupnoObavestenjeFilter?.godineDo ?? null,
          sifarnikMkbIDList: responseData?.grupnoObavestenjeFilter?.dijagnozaList ? responseData?.grupnoObavestenjeFilter?.dijagnozaList?.map((item: any) => item.id) : [],
          sifarnikUslugaIDList: responseData?.grupnoObavestenjeFilter?.uslugaList ? responseData?.grupnoObavestenjeFilter?.uslugaList?.map((item: any) => item.id) : [],
        };
        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "polTrenutniSifraList", value: patientChangeDataFromDB.polTrenutniSifraList });
        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "godineOd", value: patientChangeDataFromDB.godineOd });
        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "godineDo", value: patientChangeDataFromDB.godineDo });
        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "sifarnikMkbIDList", value: patientChangeDataFromDB.sifarnikMkbIDList });
        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "sifarnikUslugaIDList", value: patientChangeDataFromDB.sifarnikUslugaIDList });
        let filterData = {
          obavestenjeTipSifra: patientChangeDataFromDB?.obavestenjeTipSifra?.sifra,
          polTrenutniSifraList: patientChangeDataFromDB?.polTrenutniSifraList.join(","),
          godineOd: patientChangeDataFromDB?.godineOd ?? null,
          godineDo: patientChangeDataFromDB?.godineDo ?? null,
          sifarnikMkbIDList: patientChangeDataFromDB?.sifarnikMkbIDList && patientChangeDataFromDB?.sifarnikMkbIDList?.length > 0 ? patientChangeDataFromDB?.sifarnikMkbIDList?.join(",") : [],
          sifarnikUslugaIDList:
            patientChangeDataFromDB?.sifarnikUslugaIDList && patientChangeDataFromDB?.sifarnikUslugaIDList?.length > 0 ? patientChangeDataFromDB?.sifarnikUslugaIDList?.join(",") : [],
        };
        axiosGetPacijentGrupnoObavestenjeIncluded(filterData)
          .then((response: AxiosResponse) => {
            let responseData = response.data.data;
            dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_CHANGE, fieldName: "ukupnoPacijenata", value: responseData });
            if (oldValue?.ukupnoPacijenata !== responseData) {
              let obavestenje = {
                ...oldValue,
                ukupnoPacijenata: responseData,
              };
              setSelectedObavestenje({
                ...selectedObavestenje!,
                ukupnoPacijenata: responseData,
              });
              axiosUpdateGrupnoObavestenje(obavestenje)
                .then(() => {
                  pageZaSlanje.current = 0;
                  fetchObavestenjeZaSlanje(obavestenje.id);
                })
                .catch((error) => {
                  handleAxiosCallError(showMessage, error);
                })
                .finally(() => {});
            }
          })
          .catch((error) => {
            handleAxiosCallError(showMessage, error);
          });
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_LOADING, value: false });
        setIsOpenConfirmationDialog(true);
      });
  };

  const fetchObavestenje = (obavestenjeId: number, action: string, status: EnumBaseReadDto) => {
    dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_LOADING, value: true });
    let oldValue: GrupnoObavestenjeUpdateDto;
    axiosGetGrupnoObavestenje(obavestenjeId)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        oldValue = responseData;
        dispatch({ type: actionsAddPatientNotification.OPEN_DIALOG, operation: action, obavestenje: { ...state.obavestenje!, ...responseData } });
        let patientChangeDataFromDB = {
          obavestenjeTipSifra: responseData?.obavestenjeTip ?? EnumTipObavestenja.EMAIL,
          polTrenutniSifraList: responseData?.grupnoObavestenjeFilter?.polTrenutniList
            ? responseData?.grupnoObavestenjeFilter?.polTrenutniList?.map((item: any) => item.sifra)
            : [EnumPol.MUSKI, EnumPol.ZENSKI, EnumPol.NIJE_UNET],
          godineOd: responseData?.grupnoObavestenjeFilter?.godineOd ?? null,
          godineDo: responseData?.grupnoObavestenjeFilter?.godineDo ?? null,
          sifarnikMkbIDList: responseData?.grupnoObavestenjeFilter?.dijagnozaList ? responseData?.grupnoObavestenjeFilter?.dijagnozaList?.map((item: any) => item.id) : [],
          sifarnikUslugaIDList: responseData?.grupnoObavestenjeFilter?.uslugaList ? responseData?.grupnoObavestenjeFilter?.uslugaList?.map((item: any) => item.id) : [],
        };
        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "polTrenutniSifraList", value: patientChangeDataFromDB.polTrenutniSifraList });
        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "godineOd", value: patientChangeDataFromDB.godineOd });
        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "godineDo", value: patientChangeDataFromDB.godineDo });
        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "sifarnikMkbIDList", value: patientChangeDataFromDB.sifarnikMkbIDList });
        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "sifarnikUslugaIDList", value: patientChangeDataFromDB.sifarnikUslugaIDList });
        let filterData = {
          obavestenjeTipSifra: patientChangeDataFromDB?.obavestenjeTipSifra?.sifra,
          polTrenutniSifraList: patientChangeDataFromDB?.polTrenutniSifraList.join(","),
          godineOd: patientChangeDataFromDB?.godineOd ?? null,
          godineDo: patientChangeDataFromDB?.godineDo ?? null,
          sifarnikMkbIDList: patientChangeDataFromDB?.sifarnikMkbIDList && patientChangeDataFromDB?.sifarnikMkbIDList?.length > 0 ? patientChangeDataFromDB?.sifarnikMkbIDList?.join(",") : [],
          sifarnikUslugaIDList:
            patientChangeDataFromDB?.sifarnikUslugaIDList && patientChangeDataFromDB?.sifarnikUslugaIDList?.length > 0 ? patientChangeDataFromDB?.sifarnikUslugaIDList?.join(",") : [],
        };
        if (status.sifra !== EnumObavestenjeStatus.NA_CEKANJU) {
          dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_CHANGE, fieldName: "ukupnoPacijenata", value: oldValue.ukupnoPacijenata });
          return;
        }
        axiosGetPacijentGrupnoObavestenjeIncluded(filterData)
          .then((response: AxiosResponse) => {
            let responseData = response.data.data;
            dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_CHANGE, fieldName: "ukupnoPacijenata", value: responseData });
            if (oldValue?.ukupnoPacijenata !== responseData) {
              let obavestenje = {
                ...oldValue,
                ukupnoPacijenata: responseData,
              };
              axiosUpdateGrupnoObavestenje(obavestenje)
                .then(() => {
                  pageZaSlanje.current = 0;
                  fetchObavestenjeZaSlanje(obavestenje.id);
                })
                .catch((error) => {
                  handleAxiosCallError(showMessage, error);
                });
            }
          })
          .catch((error) => {
            handleAxiosCallError(showMessage, error);
          });
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_LOADING, value: false });
      });
  };

  return {
    breadCrumbItems,
    state,
    dispatch,
    invalidFields,
    setInvalidFields,
    onCreate,
    onUpdate,
    onDelete,
    obavestenjaZaSlanjeList,
    obavestenjaZaSlanjeListLoading,
    obavestenjaPoslatoList,
    obavestenjaPoslatoListLoading,
    onSend,
    hasMoreObavestenjaZaSlanje,
    hasMoreObavestenjaPoslato,
    fetchObavestenjaZaSlanje,
    fetchObavestenjaPoslata,
    index,
    setIndex,
    obavestenjeZaSlanjeFilters,
    setObavestenjeZaSlanjeFilters,
    obavestenjePoslatoFilters,
    setObavestenjePoslatoFilters,
    getPatientIncluded,
    mkbDijagnozeList,
    searchMkbDijagnoza,
    searchSifarnikUsluga,
    sifarnikUslugaList,
    fetchObavestenje,
    fetchObavestenjePreSlanja,
    scrollPanelRef,
    isOpenConfirmationDialog,
    setIsOpenConfirmationDialog,
    selectedObavestenje,
  };
}
