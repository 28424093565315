import classNames from "classnames";
import { PropsWithChildren } from "react";
import Images from "../../../infrastructure/system/Images";
import Labels from "../../../infrastructure/system/Labels_sr_RS";
import { getImage } from "../../../infrastructure/system/Utils";
import AppFooter from "../footer/AppFooter";

const AppSkeleton = ({ children }: PropsWithChildren) => {
  const colorScheme = "light";
  const menuTheme = "layout-sidebar-blue";

  const containerClassName = classNames(
    "layout-wrapper",
    {
      "layout-overlay": false,
      "layout-static": false,
      "layout-slim": true,
      "layout-sidebar-dim": false,
      "layout-sidebar-dark": false,
      "layout-mobile-active": false,
      "p-input-filled": false,
      "p-ripple-disabled": true,
    },
    colorScheme === "light" ? menuTheme : ""
  );
  return (
    <div className="layout-message">
      <div className={containerClassName} data-theme={colorScheme}>
        <div className={`layout-content-wrapper`}>
          <div className="layout-topbar">
            <div className="topbar-left">
              <span className="topbar-separator"></span>
              <div className="layout-breadcrumb viewname uppercase">
                <span>{Labels.APPLICATION_NAME}</span>
              </div>
            </div>
          </div>
          {children}
          <AppFooter />
        </div>
        <div className="layout-sidebar app-menu flex flex-column-reverse">
          <div className={`logo`} data-cy="app-logo">
            <img id="app-logo" className="logo-image" src={getImage(false, "", Images.LOGO_HELIANT)} alt={Labels.HELIANT} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSkeleton;
