import moment from "moment";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import EnumPol from "../../../../infrastructure/system/EnumPol";
import KontrolaPravaPristupaService from "../../../../infrastructure/system/KontrolaPravaPristupaService";
import { DATE_FORMAT, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import KontaktUslugaReadDto from "../../../../model/kontakt/KontaktUslugaReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import SifarnikUslugaDto from "../../../../model/sifarnik/SifarnikUslugaRFZODto";
import ZakazanTerminUslugaPlannedReadDto from "../../../../model/zakazan-termin/ZakazanTerminUslugaPlannedReadDto";
import SkeletonRectangles from "../../../app/skeleton/SkeletonRectangles";
import DialogComponent from "../../../dialog-component/DialogComponent";
import KontaktUsluga from "./KontaktUsluga";
import KontaktUslugaLogical from "./KontaktUslugaLogical";

interface KontaktUslugaListProps {
  pacijent: PacijentReadDto;
  kontaktUslugaList: Array<KontaktUslugaReadDto> | undefined;
  setKontaktUslugaList: React.Dispatch<React.SetStateAction<Array<KontaktUslugaReadDto> | undefined>>;
  planiranaUslugaList: ZakazanTerminUslugaPlannedReadDto[];
  setPlaniranaUslugaList: React.Dispatch<React.SetStateAction<ZakazanTerminUslugaPlannedReadDto[]>>;
  sifarnikUslugaList: Array<SifarnikUslugaDto> | undefined;
  setSifarnikUslugaList: React.Dispatch<React.SetStateAction<Array<SifarnikUslugaDto> | undefined>>;
  uslugaTipList: Array<EnumBaseReadDto> | undefined;
  setUslugaTipList: React.Dispatch<React.SetStateAction<Array<EnumBaseReadDto> | undefined>>;
  naplacen: boolean;
}

export default function KontaktUslugaList(props: KontaktUslugaListProps) {
  const Labels = useLabels();
  const { pristup, authData } = useContext(AppContext);
  const [uslugaOperation, setUslugaOperation] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { pacijent, kontaktUslugaList, setKontaktUslugaList, planiranaUslugaList, setPlaniranaUslugaList, setSifarnikUslugaList, setUslugaTipList, sifarnikUslugaList, uslugaTipList, naplacen } =
    props;
  const {
    id,
    closeUslugaDialog,
    isOpenUslugaDialog,
    onCreateUsluge,
    onUpdateUsluge,
    onDeleteUsluge,
    kontaktUslugaChange,
    setKontaktUslugaChange,
    dataLoading,
    setIsOpenUslugaDialog,
    isOpenConfirmationDialog,
    closeConfirmationDialog,
    changeBrojPruzanjaUsluga,
    transferPlannedServicesIntoRealized,
    removePlannedServices,
    uslugaCenaFailedConditionMessage,
    acceptTransferPlannedUslugaIntoRealized,
  } = KontaktUslugaLogical(pacijent, kontaktUslugaList, setKontaktUslugaList, planiranaUslugaList, setPlaniranaUslugaList, sifarnikUslugaList, setSifarnikUslugaList, uslugaTipList, setUslugaTipList);
  // uslugaTipList se trenutno ne koristi videti da li treba da se izbaci

  const { hasKontaktUslugaCreate, hasKontaktUslugaUpdate, hasKontaktUslugaDelete } = KontrolaPravaPristupaService();

  const openKontaktUslugu = (entityOperation: string, kontaktUsluga?: KontaktUslugaReadDto) => {
    setUslugaOperation(entityOperation);
    kontaktUsluga ? setKontaktUslugaChange({ ...kontaktUsluga, kontakt: { id: id } }) : setKontaktUslugaChange({ brojPruzanja: 1, kontakt: { id: id } });
    setIsDisabled(entityOperation === EntityOperation.READ || entityOperation === EntityOperation.DELETE);
    setIsOpenUslugaDialog(true);
  };

  return (
    <div className="p-grid p-dir-col">
      {dataLoading ? (
        <SkeletonRectangles />
      ) : (
        <>
          <KontaktUsluga
            isOpenUslugaDialog={isOpenUslugaDialog}
            closeUslugaDialog={closeUslugaDialog}
            uslugaOperation={uslugaOperation}
            kontaktUslugaChange={kontaktUslugaChange}
            setKontaktUslugaChange={setKontaktUslugaChange}
            onCreateUsluge={onCreateUsluge}
            onUpdateUsluge={onUpdateUsluge}
            onDeleteUsluge={onDeleteUsluge}
            sifarnikUslugaList={sifarnikUslugaList}
            isDisabled={isDisabled}
            uslugaTipList={uslugaTipList}
          />
          <div className="p-col flex flex-column">
            <div className="col-12 p-0 font-bold align-self-center">{Labels.PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA}</div>
            {planiranaUslugaList.map((planiranaUsluga: ZakazanTerminUslugaPlannedReadDto) => {
              return (
                <div className="col-12 p-0" key={planiranaUsluga.id}>
                  <div className="box flex flex-row align-items-center">
                    <div className="col-6 pl-0 pr-0">
                      <div className="flex flex-row h-2rem">
                        <div className="col-9 flex p-0">
                          <div className="flex flex-row">
                            <p>{Labels.USLUGA}</p>
                            <p className="font-bold pl-2">{planiranaUsluga?.sifarnikUsluga.sifra + " " + planiranaUsluga?.sifarnikUsluga.naziv}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 p-0 flex justify-content-end">
                      <div className="flex align-items-center font-bold mr-3">{Labels.USLUGA_BROJ_PRUZANJA}</div>
                      <div className="planned-service-number h-2rem pr-5">
                        <InputNumber
                          value={planiranaUsluga.brojPruzanja}
                          showButtons
                          buttonLayout="horizontal"
                          min={1}
                          max={100}
                          step={1}
                          incrementButtonIcon="pi pi-plus"
                          decrementButtonIcon="pi pi-minus"
                          className="small-input"
                          onChange={(event: InputNumberChangeEvent) => {
                            changeBrojPruzanjaUsluga(planiranaUsluga.id, event.value);
                          }}
                        />
                      </div>

                      <Button
                        icon="pi pi-times"
                        className="p-button-info w-2rem h-2rem mr-3"
                        rounded
                        text
                        raised
                        onClick={() => {
                          removePlannedServices(planiranaUsluga);
                        }}
                      />
                      <Button
                        icon="pi pi-check"
                        className="w-2rem h-2rem"
                        rounded
                        raised
                        onClick={() => {
                          transferPlannedServicesIntoRealized(planiranaUsluga, false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            {planiranaUslugaList && planiranaUslugaList.length > 0 ? (
              <div className="p-0 flex justify-content-end mt-3">
                <Button
                  icon="pi pi-times"
                  label={Labels.PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_UKLONI_SVE}
                  className="p-button-outlined p-button-info align-self-center mr-5"
                  onClick={() => removePlannedServices(undefined)}
                />
                <Button
                  icon="pi pi-check"
                  label={Labels.PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_PREBACI_SVE}
                  className="p-button-info"
                  onClick={() => transferPlannedServicesIntoRealized(undefined, false)}
                />
              </div>
            ) : (
              <div className="mt-3">{Labels.PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_NEMA_PLANIRANIH_USLUGA}</div>
            )}
          </div>
          <Divider className="divider mt-2" />
          <div className="p-col flex flex-column">
            <div className="box flex flex-row">
              <div className="col-9 p-0 font-bold align-self-center">{Labels.USLUGA_LIST_TOKOM_KONTKAKTA}</div>
              <div className="col-3 p-0 flex justify-content-end">
                <Button label={Labels.USLUGA_NOVA} className="p-button-success" onClick={() => openKontaktUslugu(EntityOperation.CREATE)} disabled={!pristup || !hasKontaktUslugaCreate || naplacen} />
              </div>
            </div>
            {kontaktUslugaList?.map((ku: KontaktUslugaReadDto, key: number) => {
              return (
                <div className="box flex flex-row" key={key}>
                  <div className="col-12 pl-0 pr-0">
                    <div className="flex flex-row h-2rem">
                      <div className="col-9 flex p-0">
                        <div className="flex flex-row">
                          <p>{Labels.USLUGA}</p>
                          <p className="font-bold pl-2">{ku?.sifarnikUsluga?.sifra + " " + ku?.sifarnikUsluga?.naziv}</p>
                        </div>
                      </div>
                      <div className="col-3 flex justify-content-end p-0">
                        <div className="flex flex-row">
                          <Button
                            type="button"
                            tooltip={Labels.USLUGA_IZMENI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-warning mr-2"
                            icon="pi pi-pencil"
                            onClick={() => {
                              ku && openKontaktUslugu(EntityOperation.UPDATE, ku);
                            }}
                            disabled={!pristup || (authData?.currentRadnik.id === ku.radnikKreirao.id ? false : !hasKontaktUslugaUpdate) || naplacen}
                          />
                          <Button
                            type="button"
                            tooltip={Labels.USLUGA_OBRISI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-danger"
                            icon="pi pi-trash"
                            onClick={() => {
                              ku && openKontaktUslugu(EntityOperation.DELETE, ku);
                            }}
                            disabled={!pristup || (authData?.currentRadnik.id === ku.radnikKreirao.id ? false : !hasKontaktUslugaDelete) || naplacen}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex flex-row h-2rem">
                      <p>{Labels.USLUGA_TIP}</p>
                      <p className="pl-2">{ku?.uslugaTip?.naziv}</p>
                    </div> */}
                    <div className="flex flex-row h-2rem">
                      <p>{Labels.USLUGA_BROJ_PRUZANJA}</p>
                      <p className="pl-2">{ku?.brojPruzanja}</p>
                    </div>
                    {ku.napomena && (
                      <div className="flex flex-row h-2rem">
                        <p>{Labels.USLUGA_NAPOMENA}</p>
                        <p className="pl-2">{ku.napomena}</p>
                      </div>
                    )}
                    <div className="flex flex-row h-2rem">
                      <p>
                        {ku.radnikKreirao.polTrenutni.sifra === EnumPol.MUSKI
                          ? Labels.USLUGA_PRUZIO_MUSKO
                          : ku.radnikKreirao.polTrenutni.sifra === EnumPol.ZENSKI
                          ? Labels.USLUGA_PRUZIO_ZENSKO
                          : Labels.USLUGA_PRUZIO}
                      </p>
                      <p className="pl-2">
                        {ku.radnikKreirao?.ime} {ku.radnikKreirao?.prezime} {moment(ku.vremePruzanja).format(DATE_FORMAT)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <DialogComponent
        header=""
        visible={isOpenConfirmationDialog}
        onHide={closeConfirmationDialog}
        content={
          <>
            <div className="text-xl font-semibold">{uslugaCenaFailedConditionMessage.length > 1 ? Labels.NO_USLUGA_CENA_LIST_WARNING : Labels.NO_USLUGA_CENA_WARNING}</div>
            {uslugaCenaFailedConditionMessage.length > 0 && (
              <div className="mt-2">
                {uslugaCenaFailedConditionMessage.map((uslugaCenaError: string, index: number) => (
                  <div className="mb-1" key={index}>
                    {Labels.DOT_SIGN} {uslugaCenaError}
                  </div>
                ))}
              </div>
            )}
            {uslugaCenaFailedConditionMessage.length > 1 && <div className="text-xl mt-3 font-semibold">{Labels.NO_USLUGA_CENA_LIST_WARNING_ACCEPT}</div>}
          </>
        }
        footer={
          <div className="layout-pacijent-dosije">
            <Button
              label={Labels.BUTTON_CANCEL}
              className="p-button-outlined p-button-tertiary align-self-center"
              onClick={() => {
                closeConfirmationDialog();
                closeUslugaDialog();
              }}
            />
            <Button
              label={Labels.BUTTON_CONFIRM}
              className="p-button-success"
              onClick={
                uslugaCenaFailedConditionMessage.length === 0
                  ? uslugaOperation === EntityOperation.CREATE
                    ? () => onCreateUsluge(true)
                    : uslugaOperation === EntityOperation.UPDATE
                    ? () => onUpdateUsluge(true)
                    : undefined
                  : () => acceptTransferPlannedUslugaIntoRealized()
              }
              disabled={!pristup}
            />
          </div>
        }
      />
    </div>
  );
}
