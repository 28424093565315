import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { formatPrice, tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import StorageLimitReadDto from "../../../../model/licenca/storage-limit/StorageLimitReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import StorageLimitListLogical from "./StorageLimitListLogical";

export default function StorageLimitListView() {
  const { slModul, storageLimitList, firstModul, tableModulRows, onPageModul, modulLoading, openStorageLimit } = StorageLimitListLogical();

  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.HEADER_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.HEADER_PROSTOR, filter: true, sortrable: true },
    { columnName: Labels.CENA, filter: true, sortrable: true },
  ];

  const crudBodyTemplate = (rowStorageLimit: StorageLimitReadDto) => {
    return (
      <div className="float-right">
        <Button
          type="button"
          tooltip={Labels.STORAGE_LIMIT_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowStorageLimit && openStorageLimit(EntityOperation.UPDATE, rowStorageLimit.id)}
        />
      </div>
    );
  };

  const addButton = () => (
    <Button
      label={Labels.STORAGE_LIMIT_BUTTON_DODAJ}
      tooltip={Labels.STORAGE_LIMIT_TITLE_CREATE}
      tooltipOptions={tooltipOptionsBottom}
      className="p-button-success mr-3"
      onClick={() => {
        openStorageLimit(EntityOperation.CREATE);
      }}
    />
  );

  const crudProstorTemplate = (rowStorageLimit: StorageLimitReadDto) => (
    <>
      {rowStorageLimit.prostor} {rowStorageLimit.jedinicaKolicinePodatkaEnum.sifra}
    </>
  );

  const prostorFilterFunction = (value: number, filter: string) => {
    if (!filter) {
      return true;
    }
    const {
      prostor,
      jedinicaKolicinePodatkaEnum: { sifra },
    }: StorageLimitReadDto = storageLimitList.find((x: StorageLimitReadDto) => x.id === value)!;
    return `${prostor} ${sifra}`.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  };

  const renderCena = (storageLimit: StorageLimitReadDto) => {
    return formatPrice(storageLimit.cena) + " RSD";
  };

  return (
    <div className="layout-generic-content-list">
      <Toolbar start={addButton} />
      {modulLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={undefined} />
      ) : (
        <DataTable
          ref={slModul}
          filterDisplay="row"
          value={storageLimitList}
          paginator
          first={firstModul}
          paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
          rows={tableModulRows}
          onPage={(e) => {
            onPageModul(e.rows, e.first);
          }}
          exportFilename={Labels.FORMULAR_LIST}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          rowHover
          stripedRows
        >
          <Column field={"sifra"} header={Labels.HEADER_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          <Column field={"naziv"} header={Labels.HEADER_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          <Column
            field={"id"}
            body={crudProstorTemplate}
            header={Labels.HEADER_PROSTOR}
            filter
            filterMatchMode="custom"
            filterFunction={prostorFilterFunction}
            showFilterMenu={false}
            sortField={"prostorUBajtovima"}
            sortable
          />
          <Column body={renderCena} header={Labels.CENA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          <Column body={crudBodyTemplate} />
        </DataTable>
      )}
    </div>
  );
}
