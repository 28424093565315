import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useLabels } from "../../Store";
import { tooltipOptionsBottom } from "../../infrastructure/system/Utils";
import ObavestenjeOsnovnoReadDto from "../../model/obavestenje/ObavestenjeOsnovnoReadDto";
import ObavestenjeDatotekaListPreview from "../administracija/obavestenje/ObavestenjeDatotekaListPreview";
import SkeletonAccordionList from "../app/skeleton/SkeletonAccordionList";
import SkeletonRectangleListInRow from "../app/skeleton/SkeletonRecatngleListInRow";
import DialogComponent from "../dialog-component/DialogComponent";
import ObavestenjeKorisnikDialogLogical from "./ObavestenjeKorisnikDialogLogical";

interface ObavestenjeKorisnikDialogProps {
  isVisiable: boolean;
  closeDialogObavestenje: () => void;
  obavestenjeListNeprocitano: Array<ObavestenjeOsnovnoReadDto>;
  unreadListLoading: boolean;
  setObavestenjeListNeprocitano: React.Dispatch<React.SetStateAction<Array<ObavestenjeOsnovnoReadDto>>>;
  setUnreadListLoading: React.Dispatch<boolean>;
}

export default function ObavestenjeKorisnikDialog(props: ObavestenjeKorisnikDialogProps) {
  const { isVisiable, closeDialogObavestenje, obavestenjeListNeprocitano, setObavestenjeListNeprocitano, unreadListLoading, setUnreadListLoading }: ObavestenjeKorisnikDialogProps = props;
  const {
    changeTab,
    activeIndex,
    obavestenjeListProcitano,
    readListLoading,
    renderDateTime,
    readObavestenjeNeprocitano,
    readAllObavestenje,
    neprocitanaObavestenjaCount,
    obavestenjeDatotekaListMap,
    readObavestenjeProcitano,
    handleDatotekaClick,
  } = ObavestenjeKorisnikDialogLogical({ obavestenjeListNeprocitano, setObavestenjeListNeprocitano, unreadListLoading, setUnreadListLoading });
  const Labels = useLabels();

  const renderObavestenjeAccordion = (obavestenje: ObavestenjeOsnovnoReadDto) => {
    const obavestenjeDatotekaList = obavestenjeDatotekaListMap.get(obavestenje.id);
    return (
      <AccordionTab
        key={obavestenje.id}
        header={
          <div className="ml-2">
            <div className="p-dropdown-items">{obavestenje.naslov}</div>
            <br />
            {`${Labels.OBAVESTENJE_KREIRANO} ${renderDateTime(obavestenje.vremeKreiranja)}`}
          </div>
        }
      >
        <p>{obavestenje.sadrzaj}</p>
        {obavestenjeDatotekaList === undefined && <SkeletonRectangleListInRow />}
        {obavestenjeDatotekaListMap && obavestenjeDatotekaList && obavestenjeDatotekaList[0] !== undefined && (
          <ObavestenjeDatotekaListPreview datotekaList={obavestenjeDatotekaList} handleDatotekaClick={handleDatotekaClick} />
        )}
      </AccordionTab>
    );
  };

  return (
    <DialogComponent
      header={Labels.OBAVESTENJE_LIST}
      visible={isVisiable}
      onHide={closeDialogObavestenje}
      withoutFooter={true}
      className={"dialog-right"}
      draggable={false}
      content={
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => {
            changeTab(e.index);
          }}
        >
          <TabPanel header={Labels.OBAVESTENJE_NEPROCITANO}>
            {unreadListLoading ? (
              <SkeletonAccordionList size={5} />
            ) : obavestenjeListNeprocitano.length > 0 ? (
              <div className="layout-generic-content-list">
                <Button
                  icon={`pi pi-check-circle`}
                  tooltip={Labels.OBAVESTENJE_OZNACI_SVE_PROCITANO}
                  tooltipOptions={tooltipOptionsBottom}
                  className={"p-button-success mb-3 " + (neprocitanaObavestenjaCount > 0 ? "" : "button-hidden")}
                  onClick={readAllObavestenje}
                />
                <Accordion
                  onTabOpen={(e) => {
                    readObavestenjeNeprocitano(obavestenjeListNeprocitano[e.index].id);
                  }}
                >
                  {obavestenjeListNeprocitano.map(renderObavestenjeAccordion)}
                </Accordion>
              </div>
            ) : (
              <div>{Labels.OBAVESTENJE_NEMA_NEPROCITANIH}</div>
            )}
          </TabPanel>

          <TabPanel header={Labels.OBAVESTENJE_PROCITANO}>
            {readListLoading ? (
              <SkeletonAccordionList size={5} />
            ) : obavestenjeListProcitano.length > 0 ? (
              <Accordion
                onTabOpen={(e) => {
                  readObavestenjeProcitano(obavestenjeListProcitano[e.index].id);
                }}
              >
                {obavestenjeListProcitano.map(renderObavestenjeAccordion)}
              </Accordion>
            ) : (
              <div>{Labels.OBAVESTENJE_NEMA_PROCITANIH}</div>
            )}
          </TabPanel>
        </TabView>
      }
    />
  );
}
