import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import FaktorRizikaReadDto from "../../../model/faktor-rizika/FaktorRizikaReadDto";
import UstanovaReadDto from "../../../model/ustanova/UstanovaReadDto";
import SkeletonCheckboxItem from "../../app/skeleton/SkeletonCheckboxItem";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import FaktorRizikaAdminLogical from "./FaktorRizikaAdminLogical";
import DialogFaktorRizika from "./dialog-faktor-rizika/DialogFaktorRizikaView";

interface UstanovaFaktoriRizikaViewProps {
  ustanovaId?: number;
  ustanova?: UstanovaReadDto;
}

export default function FaktorRizikaAdminView(props: UstanovaFaktoriRizikaViewProps) {
  const { breadCrumbItems, faktorRizikaList, faktorRizikaListLoading, tableRows, updateFaktorRizikaUstanova, faktorRizikaUstanovaLoading, faktorRizikaUstanovaToDelete, closeDialog } =
    FaktorRizikaAdminLogical();
  const { ustanovaId } = props;
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: "", filter: true, sortrable: false },
    { columnName: Labels.FAKTOR_RIZIKA_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.FAKTOR_RIZIKA_NAZIV, filter: true, sortrable: true },
  ];

  const imageBodyTemplate = (faktorRizika: any) => {
    return (
      <div>
        <Image src={faktorRizika.ikonica.ikonica} />
      </div>
    );
  };

  const crudBodyTemplate = (rowFaktorRizika: FaktorRizikaReadDto) => {
    return (
      <>
        {faktorRizikaUstanovaLoading ? (
          <SkeletonCheckboxItem />
        ) : (
          <div className="float-right">
            <Checkbox disabled={!pristup} onChange={() => updateFaktorRizikaUstanova(rowFaktorRizika, false)} checked={rowFaktorRizika.faktorRizikaUstanovaId !== undefined} />
          </div>
        )}
      </>
    );
  };

  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  return (
    <div className="layout-crud-generic-content">
      {ustanovaId === undefined ? (
        <Panel>
          <BreadCrumb model={breadCrumbItems} className="mb-4" />
          <div className="col-12 p-0 border-none">
            {faktorRizikaListLoading ? (
              <SkeletonTable dataTableColumnsProperty={columnsProperty} isVisibleButtonList defaultButtonCount={1} />
            ) : (
              <DataTable
                className="mt-4"
                value={faktorRizikaList}
                rowHover
                stripedRows
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                paginator
                paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
                rows={tableRows}
              >
                <Column className="col-2" body={crudBodyTemplate} />
                <Column header={Labels.FAKTOR_RIZIKA_SIFRA} sortable field={"sifra"} />
                <Column header={Labels.FAKTOR_RIZIKA_NAZIV} sortable field={"naziv"} />
                <Column body={imageBodyTemplate} />
              </DataTable>
            )}
          </div>
        </Panel>
      ) : null}
      <DialogFaktorRizika visible={faktorRizikaUstanovaToDelete !== undefined} cancel={closeDialog} confirm={() => updateFaktorRizikaUstanova(faktorRizikaUstanovaToDelete!, true)} />
    </div>
  );
}
