import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../../Store";
import { StorageLimitController } from "../../../../../controllers/storage-limit/StorageLimitController";
import UstanovaStorageLimitController from "../../../../../controllers/ustanova/ustanova-storage-limit/UstanovaStorageLimitController";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce } from "../../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../../../model/BreadCrumbItemDto";
import StorageLimitReadDto from "../../../../../model/licenca/storage-limit/StorageLimitReadDto";
import UstanovaStorageLimitCreateDto from "../../../../../model/ustanova/licenca/UstanovaStorageLimitCreateDto";
import UstanovaStorageLimitReadDto from "../../../../../model/ustanova/licenca/UstanovaStorageLimitReadDto";
import UstanovaStorageLimitUpdateDto from "../../../../../model/ustanova/licenca/UstanovaStorageLimitUpdateDto";

interface CrudUstanovaStorageLimitLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  ustanovaStorageLimitChange: UstanovaStorageLimitReadDto | UstanovaStorageLimitCreateDto | undefined;
  setUstanovaStorageLimitChange: React.Dispatch<React.SetStateAction<UstanovaStorageLimitReadDto | UstanovaStorageLimitCreateDto | UstanovaStorageLimitUpdateDto | undefined>>;
  isDisabled: boolean;
  ustanovaStorageLimitOperation: string;
  storageLimitList: Array<StorageLimitReadDto>;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onCancel: () => void;
  ustanovaStorageLimitLoading: boolean;
  storageLimitListLoading: boolean;
  ustanovaId: number;
  vaziOdRef: HTMLDivElement | any;
  vaziDoRef: HTMLDivElement | any;
}

interface UseParamsType {
  ustanovaStorageLimitId?: string;
  ustanovaId?: string;
}

export default function CrudUstanovaStorageLimitLogical(): CrudUstanovaStorageLimitLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const [ustanovaNaziv] = useState<string>(location.state && location.state.ustanovaNaziv !== undefined ? location.state.ustanovaNaziv : "");

  const [ustanovaStorageLimitChange, setUstanovaStorageLimitChange] = useState<UstanovaStorageLimitReadDto | UstanovaStorageLimitCreateDto>();

  const [storageLimitList, setStorageLimitList] = useState<Array<StorageLimitReadDto>>([]);
  const [ustanovaStorageLimitOperation, setUstanovaStorageLimitOperation] = useState(location.state?.ustanovaStorageLimitOperation || EntityOperation.UPDATE);
  const [isDisabled] = useState(isFormDisabled(ustanovaStorageLimitOperation));
  const ustanovaStorageLimitId = Number(useParams<keyof UseParamsType>()["ustanovaStorageLimitId"]);
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const [ustanovaStorageLimitLoading, setUstanovaStorageLimitLoading] = useState(true);
  const [storageLimitListLoading, setStorageLimitListLoading] = useState(true);
  const vaziOdRef = useRef<HTMLDivElement | any>(null);
  const vaziDoRef = useRef<HTMLDivElement | any>(null);
  const { axiosGetUstanovaStorageLimit, axiosUpdateUstanovaStorageLimit, axiosCreateUstanovaStorageLimit, axiosDeleteUstanovaStorageLimit } = UstanovaStorageLimitController();
  const { axiosSearchStorageLimitList } = StorageLimitController();
  const postLogHighLevel = useLogHighLevel();
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.USTANOVA_LIST,
      command: () => {
        navigate(`/crud-ustanova`);
      },
    },
    {
      label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + " " + ustanovaNaziv,
      command: () => {
        navigate(`/crud-ustanova/${ustanovaId}`, {
          state: {
            tabIndex: 1,
            licenceTabIndex: 2,
          },
        });
      },
    },
  ]);

  useEffectOnce(() => {
    fetchData();
    getStorageLimitList();
  });

  const getStorageLimitList = () => {
    const startTime = moment(new Date());

    axiosSearchStorageLimitList()
      .then((res) => {
        setStorageLimitList(res.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setStorageLimitListLoading, startTime);
      });
  };

  const fetchData = (slID?: number, operation?: string) => {
    const newUstanovaStorageLimitId = slID || ustanovaStorageLimitId;
    const startTime = moment(new Date());
    if (newUstanovaStorageLimitId) {
      axiosGetUstanovaStorageLimit(newUstanovaStorageLimitId, ustanovaId)
        .then(({ data: { data } }: { data: { data: UstanovaStorageLimitReadDto } }) => {
          setUstanovaStorageLimitChange(data);
          const operationTmp = operation ?? ustanovaStorageLimitOperation;
          setBreadCrumbItems([
            ...breadCrumbItems,
            {
              label:
                operationTmp === EntityOperation.UPDATE
                  ? Labels.STORAGE_LIMIT_TITLE_UPDATE + data.storageLimit.naziv
                  : operationTmp === EntityOperation.READ
                  ? Labels.STORAGE_LIMIT_TITLE_DETAILS + data.storageLimit.naziv
                  : Labels.STORAGE_LIMIT_TITLE_DELETE + data.storageLimit.naziv,
            },
          ]);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setUstanovaStorageLimitLoading, startTime);
        });
    } else {
      setUstanovaStorageLimitLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.STORAGE_LIMIT_TITLE_CREATE,
        },
      ]);
    }
  };

  const validateInput = (ustanovaStorageLimit: UstanovaStorageLimitReadDto | UstanovaStorageLimitCreateDto | undefined) => {
    if (ustanovaStorageLimit === undefined || !ustanovaStorageLimit.storageLimit) {
      showMessage(MessageType.ERROR, Labels.USTANOVA_STORAGE_LIMIT_IS_REQUIRED);
      return false;
    }
    if (!ustanovaStorageLimit.vaziOd) {
      showMessage(MessageType.ERROR, Labels.USTANOVA_STORAGE_LIMIT_VAZI_OD_IS_REQUIRED);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validateInput(ustanovaStorageLimitChange)) {
      return;
    }

    if (ustanovaStorageLimitChange) {
      setShowBlockUI(true);
      axiosCreateUstanovaStorageLimit(ustanovaStorageLimitChange, ustanovaId)
        .then(({ data: { data } }: { data: { data: UstanovaStorageLimitReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.STORAGE_LIMIT_MESSAGE_CREATE_SUCCESS);
          setUstanovaStorageLimitOperation(EntityOperation.UPDATE);
          fetchData(data.id, EntityOperation.UPDATE);
          navigate(`/crud-ustanova/${ustanovaId}`, {
            state: {
              tabIndex: 1,
              licenceTabIndex: 2,
            },
          });

          setBreadCrumbItems([
            ...breadCrumbItems,
            {
              label: Labels.STORAGE_LIMIT_TITLE_CREATE,
            },
          ]);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });

      postLogHighLevel(
        Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_1 +
          ustanovaNaziv +
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_2 +
          ustanovaStorageLimitChange.storageLimit.id +
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_3 +
          ustanovaStorageLimitChange.vaziOd +
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_4 +
          (ustanovaStorageLimitChange.vaziDo ?? "/")
      );
    }
  };

  const onUpdate = () => {
    if (!validateInput(ustanovaStorageLimitChange)) {
      return;
    }
    if (ustanovaStorageLimitChange) {
      setShowBlockUI(true);
      axiosUpdateUstanovaStorageLimit(
        {
          ...ustanovaStorageLimitChange,
          ustanova: { id: ustanovaId },
        },
        ustanovaId
      )
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.USTANOVA_STORAGE_LIMIT_MESSAGE_UPDATE_SUCCESS);
          onCancel();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });

      postLogHighLevel(
        Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_1 +
          ustanovaNaziv +
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_2 +
          ustanovaStorageLimitChange.storageLimit.id +
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_3 +
          ustanovaStorageLimitChange.vaziOd +
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_4 +
          (ustanovaStorageLimitChange.vaziDo ?? "/")
      );
    }
  };
  const onCancel = () => {
    const breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
    breadCrumb.command && breadCrumb.command();
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeleteUstanovaStorageLimit(ustanovaStorageLimitId, ustanovaId)
      .then(() => {
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_1 +
            ustanovaNaziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_2 +
            ustanovaStorageLimitChange!.storageLimit.id +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_3 +
            ustanovaStorageLimitChange!.vaziOd +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_4 +
            (ustanovaStorageLimitChange!.vaziDo ?? "/")
        );

        showMessage(MessageType.SUCCESS, Labels.LICENCNI_MODEL_MESSAGE_DELETE_SUCCESS);
        onCancel();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return {
    breadCrumbItems,
    ustanovaStorageLimitChange,
    setUstanovaStorageLimitChange,
    isDisabled,
    ustanovaStorageLimitOperation,
    onCreate,
    onUpdate,
    onCancel,
    onDelete,
    ustanovaStorageLimitLoading,
    storageLimitList,
    storageLimitListLoading,
    ustanovaId,
    vaziOdRef,
    vaziDoRef,
  };
}
