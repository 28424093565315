import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { Navigate } from "react-router-dom";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { formatDate, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import CalendarComponent from "../calendar-component/CalendarComponent";
import CrudSifarnikUslugaRfzoLogical from "./CrudSifarnikUslugaRfzoLogical";
import { useLabels } from "../../../Store";

export default function CrudSifarnikUslugaRfzo() {
  const {
    sifarnikUslugaOperation,
    isDisabled,
    sifarnikUslugaChange,
    breadCrumbItems,
    setSifarnikUslugaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    sifarnikId,
    location,
    sifarnikUslugaLoading,
    entityStatusList,
    entityStatusListLoading,
    vaziOdRef,
    vaziDoRef,
  } = CrudSifarnikUslugaRfzoLogical();
  const Labels = useLabels();

  if (!sifarnikId && !location.state) {
    return <Navigate to="/repository-sifarnik-usluga-rfzo" />;
  }

  const leftContentsToolbar = () => (
    <>
      {sifarnikUslugaOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {sifarnikUslugaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={sifarnikUslugaLoading}
          icon="pi pi-pencil"
          tooltip={Labels.SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {sifarnikUslugaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={sifarnikUslugaLoading}
          icon="pi pi-trash"
          tooltip={Labels.SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_SIFARNIK_USLUGA_RFZO}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_USLUGA_RFZO_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={sifarnikUslugaChange?.naziv ?? ""}
                      onChange={(e) => {
                        setSifarnikUslugaChange({
                          ...sifarnikUslugaChange!,
                          naziv: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_USLUGA_RFZO_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={sifarnikUslugaChange?.sifra ?? ""}
                      onChange={(e) => {
                        setSifarnikUslugaChange({
                          ...sifarnikUslugaChange!,
                          sifra: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_USLUGA_RFZO_OPIS}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={sifarnikUslugaChange?.opis ?? ""}
                      onChange={(e) => {
                        setSifarnikUslugaChange({
                          ...sifarnikUslugaChange!,
                          opis: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    <div>{Labels.SIFARNIK_USLUGA_RFZO_MOZE_IMATI_UTROSAK}</div>
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <InputSwitch
                      disabled={isDisabled}
                      checked={sifarnikUslugaChange?.mozeImatiUtrosak ?? false}
                      onChange={(e) => {
                        setSifarnikUslugaChange({
                          ...sifarnikUslugaChange!,
                          mozeImatiUtrosak: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_USLUGA_RFZO_VAZI_OD}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <CalendarComponent
                      ref={vaziOdRef}
                      value={sifarnikUslugaChange?.vaziOd}
                      onChange={(e: CalendarChangeEvent) => {
                        setSifarnikUslugaChange({
                          ...sifarnikUslugaChange!,
                          vaziOd: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                        });
                        if (e.target.value) vaziOdRef?.current?.hide();
                      }}
                      disabled={isDisabled}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_USLUGA_RFZO_VAZI_DO}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <CalendarComponent
                      ref={vaziDoRef}
                      value={sifarnikUslugaChange?.vaziDo}
                      onChange={(e: CalendarChangeEvent) => {
                        setSifarnikUslugaChange({
                          ...sifarnikUslugaChange!,
                          vaziDo: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                        });
                        if (e.target.value) vaziDoRef?.current?.hide();
                      }}
                      disabled={isDisabled}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_USLUGA_RFZO_STATUS}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading && !entityStatusListLoading ? (
                    <Dropdown
                      options={entityStatusList}
                      disabled={isDisabled}
                      showClear
                      value={sifarnikUslugaChange?.entityStatus?.sifra ?? undefined}
                      optionLabel="naziv"
                      optionValue="sifra"
                      onChange={(e) => {
                        setSifarnikUslugaChange({
                          ...sifarnikUslugaChange!,
                          entityStatus: e.value ? { sifra: e.value } : undefined,
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
