import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../controllers/enum/EnumController";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import QueryKeys from "../../QueryKeys";

export function useJezikList() {
  const { axiosGetJezikList } = EnumController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.JEZIK_LIST],
    queryFn: () => axiosGetJezikList(),
  });

  const jezikList: Array<EnumBaseReadDto> = data?.data?.data;

  return { jezikList, refetch, isLoading };
}
