import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { SifarnikJedinicaMereController } from "../../../controllers/sifarnik-jedinica-mere/SifarnikJedinicaMereController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { formatDate2, handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import SifarnikJedinicaMereReadDto from "../../../model/sifarnik/sifarnik-jedinica-mere/SifarnikJedinicaMereReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudSifarnikJedinicaMereLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  sifarnikJedinicaMereChange: SifarnikJedinicaMereReadDto | undefined;
  setSifarnikJedinicaMereChange: React.Dispatch<React.SetStateAction<SifarnikJedinicaMereReadDto | undefined>>;
  isDisabled: boolean;
  sifarnikJedinicaMereOperation: string;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onCancel: () => void;
  sifarnikJedinicaMereLoading: boolean;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  datumOdRef: HTMLDivElement | any;
  datumDoRef: HTMLDivElement | any;
}

interface UseParamsType {
  sifarnikJedinicaMereId?: string;
}

export default function CrudSifarnikJedinicaMereLogical(): CrudSifarnikJedinicaMereLogicalType {
  const { showMessage, authData, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const [sifarnikJedinicaMereChange, setSifarnikJedinicaMereChange] = useState<SifarnikJedinicaMereReadDto | undefined>();
  const [sifarnikJedinicaMereOperation, setSifarnikJedinicaMereOperation] = useState(location.state?.sifarnikJedinicaMereOperation || EntityOperation.UPDATE);
  const [isDisabled] = useState(isFormDisabled(sifarnikJedinicaMereOperation));
  const sifarnikJedinicaMereId = Number(useParams<keyof UseParamsType>()["sifarnikJedinicaMereId"]);
  const [sifarnikJedinicaMereLoading, setSifarnikJedinicaMereLoading] = useState(true);
  const datumOdRef = useRef<HTMLDivElement | any>(null);
  const datumDoRef = useRef<HTMLDivElement | any>(null);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const { axiosGetSifarnikJedinicaMere, axiosCreateSifarnikJedinicaMere, axiosUpdateSifarnikJedinicaMere, axiosDeleteSifarnikJedinicaMere } = SifarnikJedinicaMereController();

  const postLogHighLevel = useLogHighLevel();

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.SIFARNIK_JEDINICA_MERE,
      command: () => {
        navigate("/sifarnik-jedinica-mere-list/");
      },
    },
  ]);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (sjmID?: number, operation?: string) => {
    const newSifarnikJedinicaMereId = sjmID || sifarnikJedinicaMereId;
    let startTime = moment(new Date());
    if (newSifarnikJedinicaMereId) {
      axiosGetSifarnikJedinicaMere(newSifarnikJedinicaMereId)
        .then(({ data: { data } }: { data: { data: SifarnikJedinicaMereReadDto } }) => {
          setSifarnikJedinicaMereChange(data);
          const operationTmp = operation ?? sifarnikJedinicaMereOperation;
          setBreadCrumbItems([
            breadCrumbItems[0],
            {
              label:
                operationTmp === EntityOperation.UPDATE
                  ? Labels.SIFARNIK_JEDINICA_MERE_TITLE_UPDATE + data.naziv
                  : operationTmp === EntityOperation.READ
                  ? Labels.SIFARNIK_JEDINICA_MERE_TITLE_DETAILS + data.naziv
                  : Labels.SIFARNIK_JEDINICA_MERE_TITLE_DELETE + data.naziv,
            },
          ]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setSifarnikJedinicaMereLoading, startTime);
        });
    } else {
      setSifarnikJedinicaMereLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.SIFARNIK_JEDINICA_MERE_TITLE_CREATE,
        },
      ]);
    }
  };

  const validateInput = (sifarnikJedinicaMere: SifarnikJedinicaMereReadDto | undefined) => {
    let isInvalid = false;
    if (sifarnikJedinicaMere === undefined || validateStringEmpty(sifarnikJedinicaMere?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(sifarnikJedinicaMere?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }
    if (!sifarnikJedinicaMere?.vaziOd) {
      setInvalidFields((prev) => ({ ...prev, vaziOd: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(sifarnikJedinicaMereChange)) {
      return;
    }

    if (sifarnikJedinicaMereChange) {
      setShowBlockUI(true);
      axiosCreateSifarnikJedinicaMere({ ...sifarnikJedinicaMereChange, ustanova: authData!.currentRadnik.ustanovaPoslednjaIzabrana })
        .then(({ data: { data } }: { data: { data: SifarnikJedinicaMereReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.SIFARNIK_JEDINICA_MERE_MESSAGE_CREATE_SUCCESS);
          setSifarnikJedinicaMereOperation(EntityOperation.UPDATE);
          navigate("/sifarnik-jedinica-mere-list");
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_1 +
              sifarnikJedinicaMereChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_2 +
              sifarnikJedinicaMereChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_3 +
              data.vaziOd
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(sifarnikJedinicaMereChange)) {
      return;
    }
    if (sifarnikJedinicaMereChange) {
      setShowBlockUI(true);
      axiosUpdateSifarnikJedinicaMere(sifarnikJedinicaMereChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.SIFARNIK_JEDINICA_MERE_MESSAGE_UPDATE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_1 +
              sifarnikJedinicaMereChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_2 +
              sifarnikJedinicaMereChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_3 +
              formatDate2(sifarnikJedinicaMereChange?.vaziOd)
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeleteSifarnikJedinicaMere(sifarnikJedinicaMereId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.SIFARNIK_JEDINICA_MERE_MESSAGE_DELETE_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_1 +
            sifarnikJedinicaMereChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_2 +
            sifarnikJedinicaMereChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_3 +
            sifarnikJedinicaMereChange?.vaziOd
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/sifarnik-jedinica-mere-list`);
    }
  };

  return {
    breadCrumbItems,
    sifarnikJedinicaMereChange,
    setSifarnikJedinicaMereChange,
    isDisabled,
    sifarnikJedinicaMereOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    sifarnikJedinicaMereLoading,
    invalidFields,
    setInvalidFields,
    datumOdRef,
    datumDoRef,
  };
}
