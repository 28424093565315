import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { useState } from "react";
import { useLabels } from "../../../Store";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import { usePagination } from "../../../infrastructure/system/hooks/tables/usePagination";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import GlobalnaDatotekaReadDto from "../../../model/globalna-datoteka/GlobalnaDatotekaReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import { DialogGlobalnaDatoteka } from "./DialogGlobalnaDatoteka";
import { useGlobalnaDatotekaList } from "./hooks/useGlobalnaDatotekaList";

export const GlobalnaDatotekaListView = () => {
  const { globalnaDatotekaList, isLoading } = useGlobalnaDatotekaList();
  const { first, onPage, size } = usePagination();
  const Labels = useLabels();
  const [selectedDatoteka, setSelectedDatoteka] = useState<GlobalnaDatotekaReadDto>();

  const breadCrumbItems: BreadCrumbItemDto[] = [
    {
      label: Labels.GLOBALNE_DATOTEKE,
    },
  ];
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const columnsProperty: SkeletonTableColumnProperty[] = [
    { columnName: Labels.LABEL_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.LABEL_SIFRA, filter: true, sortrable: true },
  ];

  const buttonListTemplate = (datoteka: GlobalnaDatotekaReadDto) => (
    <div className="float-right flex gap-2">
      <Button severity="warning" icon="pi pi-pencil" onClick={() => setSelectedDatoteka(datoteka)} />
    </div>
  );

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        {isLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} isVisibleButtonList />
        ) : (
          <DataTable
            filterDisplay="row"
            value={globalnaDatotekaList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium}
            rows={size}
            onPage={onPage}
            exportFilename={Labels.GLOBALNE_DATOTEKE}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.LABEL_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.LABEL_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column body={buttonListTemplate} />
          </DataTable>
        )}
      </Panel>
      {selectedDatoteka && (
        <DialogGlobalnaDatoteka
          globalnaDatoteka={selectedDatoteka}
          visible={!!selectedDatoteka}
          onHide={() => {
            setSelectedDatoteka(undefined);
          }}
        />
      )}
    </div>
  );
};
