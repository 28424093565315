import { useLabels } from "../../../Store";
import Endpoint from "../../../infrastructure/system/Endpoint";
import Images from "../../../infrastructure/system/Images";

export default function AppFooter() {
  const Labels = useLabels();

  return (
    <div className="layout-footer">
      <div className="footer-logo-container">
        <a href={Endpoint.HELIANT_HOME} target="_blank" rel="noreferrer">
          <img id="footer-logo" src={Images.LOGO_STRIP} alt={Labels.HELIANT} />
        </a>
      </div>
      <a href={Endpoint.HELIANT_HOME} target="_blank" rel="noreferrer">
        <span className="copyright">&#169; {Labels.HELIANT}</span>
      </a>
    </div>
  );
}
