import { Button } from "primereact/button";
import { Dialog, DialogProps } from "primereact/dialog";
import { ReactNode } from "react";
import { useLabels } from "../../Store";

interface DialogComponentProps {
  header: ReactNode | ((props: DialogProps) => ReactNode);
  closable?: boolean;
  content?: ReactNode;
  contentClassName?: string;
  visible: boolean;
  onHide: () => void;
  className?: string;
  footer?: ReactNode | ((props: DialogProps) => ReactNode);
  onAccept?: () => void;
  onReject?: () => void;
  blockScroll?: boolean;
  withoutFooter?: boolean;
  dismissableMask?: boolean;
  draggable?: boolean;
  onShow?: () => void;
}
export default function DialogComponent(props: DialogComponentProps) {
  const { header, closable, visible, onHide, className, footer, content, contentClassName, onAccept, onReject, blockScroll, withoutFooter, dismissableMask, draggable = true, onShow } = props;
  const Labels = useLabels();
  const footerBasic = (
    <div>
      <Button label={Labels.BUTTON_NO} className="p-button-outlined p-button-tertiary" onClick={onReject ?? onHide} autoFocus data-cy="rejectBtn" />
      <Button label={Labels.BUTTON_YES} className="p-button-tertiary text-white" onClick={onAccept ?? onHide} data-cy="acceptBtn" />
    </div>
  );

  return (
    <div>
      <Dialog
        header={header}
        closable={closable ?? true}
        visible={visible}
        dismissableMask={dismissableMask ?? false}
        onHide={onHide}
        className={className ?? "w-4"}
        footer={footer ?? (withoutFooter ? null : footerBasic)}
        blockScroll={blockScroll ?? false}
        draggable={draggable}
        onShow={onShow}
        contentClassName={contentClassName}
      >
        <div>{content}</div>
      </Dialog>
    </div>
  );
}
