import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../controllers/enum/EnumController";
import QueryKeys from "../../QueryKeys";

export function useKontaktPlacenList() {
  const { axiosGetKontaktPlacenList } = EnumController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.KONTAKT_PLACEN_LIST],
    queryFn: () => axiosGetKontaktPlacenList(),
    staleTime: Infinity,
  });

  const kontaktPlacenList = data?.data?.data ?? [];

  return { kontaktPlacenList, refetch, isLoading };
}
