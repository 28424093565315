import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import React, { useContext } from "react";
import { useLabels } from "../../../../Store";
import { formatPrice } from "../../../../infrastructure/system/Utils";
import NaplataReadDto from "../../../../model/naplata/NaplataReadDto";
import RacunNacinPlacanjaReadDto from "../../../../model/naplata/RacunNacinPlacanjaReadDto";
import RacunReadDto from "../../../../model/naplata/RacunReadDto";
import RacunUslugaProizvodDto from "../../../../model/naplata/RacunUslugaProizvodDto";
import StavkaNaplateReadDto from "../../../../model/naplata/StavkaNaplateReadDto";
import { NaplataContext } from "../../NaplataLogical";

interface PlacenRacunDialogPropsType {
  racunData: NaplataReadDto | any;
  showPlacenRacunDialog: boolean;
  setShowPlacenRacunDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlacenRacunDialog = ({ racunData, showPlacenRacunDialog, setShowPlacenRacunDialog }: PlacenRacunDialogPropsType) => {
  const Labels = useLabels();
  const { finalnaValuta } = useContext(NaplataContext);

  const renderHeader = (naziv: string, cena: number) => {
    return (
      <div className="flex flex-row justify-content-between text-l w-full">
        <div>{naziv}</div>
        <div>
          {formatPrice(cena)} {finalnaValuta}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      header={`${Labels.LABEL_UKUPNA_CENA_NAPLATA} ${formatPrice(racunData?.iznos)} ${finalnaValuta}`}
      visible={showPlacenRacunDialog}
      style={{ width: "40vw" }}
      onHide={() => setShowPlacenRacunDialog(false)}
    >
      {racunData?.racunList?.map((racun: RacunReadDto, id: number) => (
        <div key={id}>
          <Divider className="divider" />
          <div className="flex flex-column">
            <div className="flex align-items-center">
              <div className="col-12 px-0 sm:col-4 sm:px-3 p-2">
                <div className="font-bold">{Labels.LABEL_UPLATILAC_NAPLATA}</div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">{racun.uplatilac.naziv}</div>
            </div>
            {racun.racunNacinPlacanjaList.map((nacin: RacunNacinPlacanjaReadDto, id: number) => (
              <div className="flex align-items-center" key={id}>
                <div className="col-12 px-0 sm:col-4 sm:px-3 p-2 font-bold">
                  <div>{nacin.sifarnikNacinPlacanja.naziv}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {formatPrice(nacin.iznos)} {finalnaValuta}
                </div>
              </div>
            ))}
            {racun.napomena && (
              <div className="flex align-items-center">
                <div className="col-12 px-0 sm:col-4 sm:px-3 p-2 font-bold">
                  <div>{Labels.LABEL_NAPOMENA_NAPLATA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">{racun.napomena}</div>
              </div>
            )}
          </div>
        </div>
      ))}
      <div>
        <div className="text-xl font-semibold mt-5">{Labels.LABEL_KONTAKT_USLUGE_UTROSCI_NAPLATA}</div>
        <Divider className="divider" />
        <div className="w-full">
          <Accordion className="bg-white">
            {racunData?.uslugaProizvodList.map((uslugaProizvod: RacunUslugaProizvodDto, id: number) => (
              <AccordionTab key={id} header={renderHeader(uslugaProizvod.naziv, uslugaProizvod.iznos)} className="bg-white">
                <div className="flex flex-column" key={id}>
                  {uslugaProizvod.stavkaNaplateList.map((stavkaNaplate: StavkaNaplateReadDto, id: number) => (
                    <div key={id}>
                      <div className="flex align-items-center">
                        <div className="col-12 px-0 sm:col-4 sm:px-3 p-2">
                          <div className="font-bold">{Labels.LABEL_UPLATILAC_NAPLATA}</div>
                        </div>
                        <div className="col-12 sm:col-8 p-fluid p-0">{stavkaNaplate.uplatilac.naziv}</div>
                      </div>
                      <div className="flex align-items-center">
                        <div className="col-12 px-0 sm:col-4 sm:px-3 p-2">
                          <div className="font-bold">{Labels.LABEL_POPUST_NAPLATA}</div>
                        </div>
                        <div className="col-12 sm:col-8 p-fluid p-0">{stavkaNaplate.kategorijaPopusta ? stavkaNaplate.kategorijaPopusta.naziv : Labels.LABEL_BEZ_POPUSTA}</div>
                      </div>
                      <div className="flex align-items-center">
                        <div className="col-12 px-0 sm:col-4 sm:px-3 p-2">
                          <div className="font-bold">{Labels.LABEL_PLACA_NAPLATA}</div>
                        </div>
                        <div className="col-12 sm:col-8 p-fluid p-0">
                          {stavkaNaplate.placeniProcenat} % = {formatPrice(stavkaNaplate.iznos)} {finalnaValuta}
                        </div>
                      </div>
                      <Divider className="divider" />
                    </div>
                  ))}
                </div>
              </AccordionTab>
            ))}
          </Accordion>
        </div>
        {racunData?.secondaryUslugaProizvodList?.length > 0 && (
          <>
            <div className="text-xl font-semibold mt-5">{Labels.LABEL_DRUGI_KONTAKTI_USLUGE_UTROSCI_NAPLATA}</div>
            <Divider className="divider" />
            <div>
              <Accordion multiple>
                {racunData?.secondaryUslugaProizvodList.map((uslugaProizvod: RacunUslugaProizvodDto, id: number) => (
                  <AccordionTab key={id} header={renderHeader(uslugaProizvod.naziv, uslugaProizvod.iznos)}>
                    <div className="flex flex-column" key={id}>
                      {uslugaProizvod.stavkaNaplateList.map((stavkaNaplate: StavkaNaplateReadDto, id: number) => (
                        <div key={id}>
                          <div className="flex align-items-center">
                            <div className="col-12 px-0 sm:col-4 sm:px-3 p-2">
                              <div className="font-bold">{Labels.LABEL_UPLATILAC_NAPLATA}</div>
                            </div>
                            <div className="col-12 sm:col-8 p-fluid p-0">{stavkaNaplate.uplatilac.naziv}</div>
                          </div>
                          <div className="flex align-items-center">
                            <div className="col-12 px-0 sm:col-4 sm:px-3 p-2">
                              <div className="font-bold">{Labels.LABEL_POPUST_NAPLATA}</div>
                            </div>
                            <div className="col-12 sm:col-8 p-fluid p-0">{stavkaNaplate.kategorijaPopusta ? stavkaNaplate.kategorijaPopusta.naziv : Labels.LABEL_BEZ_POPUSTA}</div>
                          </div>
                          <div className="flex align-items-center">
                            <div className="col-12 px-0 sm:col-4 sm:px-3 p-2">
                              <div className="font-bold">{Labels.LABEL_PLACA_NAPLATA}</div>
                            </div>
                            <div className="col-12 sm:col-8 p-fluid p-0">
                              {stavkaNaplate.placeniProcenat} % = {formatPrice(stavkaNaplate.iznos)} {finalnaValuta}
                            </div>
                          </div>
                          <Divider className="divider" />
                        </div>
                      ))}
                    </div>
                  </AccordionTab>
                ))}
              </Accordion>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default PlacenRacunDialog;
