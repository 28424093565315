import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppContext, useLabels } from "../../Store";
import { UstanovaController } from "../../controllers/ustanova/UstanovaController";
import EnumInicijalizacijaKorak from "../../infrastructure/system/EnumInicijalizacijaKorak";
import MessageType from "../../infrastructure/system/MessageType";
import { useUstanova } from "../../infrastructure/system/hooks/ustanova/useUstanova";
import { checkEmpty, handleAxiosCallError, isEmailFormatValid, isInicijalizacija, useEffectOnce } from "../../infrastructure/system/Utils";
import UstanovaGridUpdateDto from "../../model/ustanova/UstanovaGridUpdateDto";
import UstanovaInicijalizacijaUpdateDto from "../../model/ustanova/UstanovaInicijalizacijaUpdateDto";
import InicijalizacijaUstanoveView from "./InicijalizacijaUstanoveView";
import InitialState from "./hooks/initialState";
import reducer from "./hooks/reducer";
import InvalidFieldsType from "../../model/InvalidFieldsType";
import OrganizacionaJedinicaReadDto from "../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import { OrganizacionaJedinicaController } from "../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { useOrganizacionaJedinicaTipList } from "../../infrastructure/system/hooks/organizaciona-jedinica/useOrganizacionaJedinicaTipList";
import QueryKeys from "../../infrastructure/system/QueryKeys";
import RadnikInicijalizacijaReadDto from "../../model/inicijalizacija/RadnikInicijalizacijaReadDto";
import { RadnikController } from "../../controllers/radnik/RadnikController";
import SifarnikUslugaReadDto from "../../model/sifarnik-usluga/SifarnikUslugaReadDto";
import { SifarnikUslugaController } from "../../controllers/sifarnik-usluga/SifarnikUslugaController";
import { TipKontaktaController } from "../../controllers/kontakt/TipKontaktaController";
import { InicijalizacijaKoraci } from "../../model/InicijalizacijaKoraci";
import { confirmDialog } from "primereact/confirmdialog";

export default function InicijalizacijaUstanoveContainer() {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI, authData } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, InitialState);
  const [invalidFieldsUstanova, setInvalidFieldsUstanova] = useState<InvalidFieldsType | undefined>(undefined);
  const [invalidFieldsOrgJedinica, setInvalidFieldsOrgJedinica] = useState<InvalidFieldsType | undefined>(undefined);
  const [invalidFieldsRadnik, setInvalidFieldsRadnik] = useState<InvalidFieldsType | undefined>(undefined);
  const [invalidFieldsUsluga, setInvalidFieldsUsluga] = useState<InvalidFieldsType | undefined>(undefined);
  const location = useLocation();
  const queryClient = useQueryClient();
  const { axiosUpdateUstanova, axiosUpdateUstanovaGrid, axiosPatchUstanovaInitializationStep } = UstanovaController();
  const { axiosCreateOrganizacionaJedinica, axiosUpdateOrganizacionaJedinica, axiosDeleteOrganizacionaJedinica } = OrganizacionaJedinicaController();
  const { axiosCreateInicijalizacijaRadnik, axiosUpdateInicijalizacijaRadnik, axiosDeleteRadnik } = RadnikController();
  const { axiosCreateSifarnikUsluga, axiosUpdateSifarnikUsluga, axiosDeleteSifarnikUsluga } = SifarnikUslugaController();
  const { organizacionaJedinicaTipList } = useOrganizacionaJedinicaTipList();
  const { axiosCopyKontaktTipWithOrganizacionaJedinica } = TipKontaktaController();
  const { ustanova, isLoading: isUstanovaLoading, refetch: refetchUstanova } = useUstanova();

  useEffectOnce(() => {
    const step =
      isInicijalizacija(authData?.currentRadnik!) && location?.state?.step
        ? location?.state?.step
        : isInicijalizacija(authData?.currentRadnik!) && !location?.state?.step
          ? authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.inicijalizacijaKorak?.id
          : undefined;

    dispatch({ type: "set_step", step: step });
  });

  const validateUstanovaStep = () => {
    let isInvalid = false;

    if (checkEmpty(state.ustanovaChange?.naziv)) {
      setInvalidFieldsUstanova((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (checkEmpty(state.ustanovaChange?.nazivKratki)) {
      setInvalidFieldsUstanova((prev) => ({ ...prev, nazivKratki: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.ustanovaChange?.radnoVremeOd)) {
      setInvalidFieldsUstanova((prev) => ({ ...prev, radnoVremeOd: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.ustanovaChange?.radnoVremeDo)) {
      setInvalidFieldsUstanova((prev) => ({ ...prev, radnoVremeDo: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.ustanovaChange?.jezik)) {
      setInvalidFieldsUstanova((prev) => ({ ...prev, jezik: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.ustanovaGridChange?.vremeTrajanjaPregledaEnum)) {
      setInvalidFieldsUstanova((prev) => ({ ...prev, vremeTrajanjaPregledaEnum: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.ustanovaGridChange?.ustanovaGridRadniDanList) || !state?.ustanovaGridChange?.ustanovaGridRadniDanList.length) {
      setInvalidFieldsUstanova((prev) => ({ ...prev, ustanovaGridRadniDanList: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const openUnsavedChangesDialog = () => {
    confirmDialog({
      message: Labels.LABEL_INICIJALIZACIJA_USTANOVE_UNSAVED_CHANGES_WARNING,
      icon: "pi pi-exclamation-triangle",
      acceptLabel: Labels.YES,
      rejectLabel: Labels.NO,
      accept: () => dispatch({ type: "increment_step" }),
    });
  };

  const continueAction = () => {
    if (state?.step === ustanova?.inicijalizacijaKorak?.id!) {
      switch (state?.step) {
        case 1:
          return validateUstanovaStep() && ustanovaStepAction();
        case 2:
          return orgJedinicaStepAction();
        case 3:
          return radniciStepAction();
        case 4:
          return tipoviPregledaStepAction();
        case 5:
          return uslugeStepAction();
      }
    } else {
      if (state?.step === InicijalizacijaKoraci.ORGANIZACIONE_JEDINICE && !state?.orgJedinicaListCount) {
        showMessage(MessageType.ERROR, Labels.LABEL_INICIJALIZACIJA_ORG_JEDINICA_REQUIRED);
        return;
      }
      if (state?.step === InicijalizacijaKoraci.TIPOVI_PREGLEDA && !state?.tipoviPregledaCount) {
        showMessage(MessageType.ERROR, Labels.LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_REQUIRED);
        return;
      }
      if (
        state?.step === InicijalizacijaKoraci.USTANOVA &&
        (JSON.stringify(state?.ustanovaChange) !== JSON.stringify(state?.ustanovaReadOnly) || JSON.stringify(state?.ustanovaGridChange) !== JSON.stringify(state?.ustanovaGridReadOnly))
      ) {
        openUnsavedChangesDialog();
        return;
      }
      if (state?.step === InicijalizacijaKoraci.TIPOVI_PREGLEDA && Object.values(state.areTipoviPregledaChanged!).some((value) => value === true)) {
        openUnsavedChangesDialog();
        return;
      }
      dispatch({ type: "increment_step" });
    }
  };

  const onUpdateUstanova = useMutation({
    mutationFn: () => {
      setShowBlockUI(true);
      return axiosUpdateUstanova(state.ustanovaChange!);
    },
    onSuccess: () => {
      let ustanovaGridTemp: UstanovaGridUpdateDto = {
        ...state?.ustanovaGridChange!,
        ustanovaGridRadniDanList: state?.ustanovaGridChange?.ustanovaGridRadniDanList.map((radniDanSifra: string) => ({ radniDan: { sifra: radniDanSifra } }))!,
      };
      onUpdateUstanovaGrid.mutate(ustanovaGridTemp, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [QueryKeys.USTANOVA] });
          queryClient.invalidateQueries({ queryKey: [QueryKeys.USTANOVA_GRID] });
          setShowBlockUI(false);
          showMessage(MessageType.SUCCESS, Labels.LABEL_INICIJALIZACIJA_USTANOVA_SUCCESS_MESSAGE);
        },
      });
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
      setShowBlockUI(false);
    },
  });

  const onUpdateUstanovaGrid = useMutation({
    mutationFn: (ustanovaGridChange: UstanovaGridUpdateDto) => axiosUpdateUstanovaGrid(ustanovaGridChange),
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
      setShowBlockUI(false);
    },
  });

  const setInitializationStep = useMutation({
    mutationFn: (stepObject: UstanovaInicijalizacijaUpdateDto) => axiosPatchUstanovaInitializationStep(stepObject),
    onSuccess: () => refetchUstanova(),
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
      setShowBlockUI(false);
    },
  });

  const ustanovaStepAction = () => {
    setShowBlockUI(true);

    setInitializationStep.mutate(
      { inicijalizacijaKorak: { sifra: EnumInicijalizacijaKorak.ORGANIZACIONE_JEDINICE } },
      {
        onSuccess: () => {
          dispatch({ type: "increment_step" });
          setShowBlockUI(false);
        },
      }
    );
  };

  const orgJedinicaStepAction = () => {
    if (state?.orgJedinicaListCount) {
      setShowBlockUI(true);
      setInitializationStep.mutate(
        { inicijalizacijaKorak: { sifra: EnumInicijalizacijaKorak.RADNICI } },
        {
          onSuccess: () => {
            dispatch({ type: "increment_step" });
            setShowBlockUI(false);
          },
        }
      );
    } else {
      showMessage(MessageType.ERROR, Labels.LABEL_INICIJALIZACIJA_ORG_JEDINICA_REQUIRED);
    }
  };

  const radniciStepAction = () => {
    setShowBlockUI(true);
    setInitializationStep.mutate(
      { inicijalizacijaKorak: { sifra: EnumInicijalizacijaKorak.TIPOVI_PREGLEDA } },
      {
        onSuccess: () => {
          dispatch({ type: "increment_step" });
          setShowBlockUI(false);
        },
      }
    );
  };

  const tipoviPregledaStepAction = () => {
    if (state?.tipoviPregledaCount) {
      setShowBlockUI(true);
      setInitializationStep.mutate(
        { inicijalizacijaKorak: { sifra: EnumInicijalizacijaKorak.USLUGE } },
        {
          onSuccess: () => {
            dispatch({ type: "increment_step" });
            setShowBlockUI(false);
          },
        }
      );
    } else {
      showMessage(MessageType.ERROR, Labels.LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_REQUIRED);
    }
  };

  const uslugeStepAction = () => {
    dispatch({ type: "set_show_finishing_initialization_process_overlay", data: true });
    dispatch({ type: "set_is_finishing_initialization_process", data: true });
    setInitializationStep.mutate(
      { inicijalizacijaKorak: { sifra: EnumInicijalizacijaKorak.INICIJALIZOVANA } },
      {
        onSuccess: () => {
          setTimeout(() => {
            // minimalan timeout kako bi se spiner uopste i video u slucaju brzog zavrsetka akcije
            dispatch({ type: "set_is_finishing_initialization_process", data: false });
          }, 500);
        },
      }
    );
  };

  const validateCreateOrgJedinica = () => {
    let isInvalid = false;

    if (checkEmpty(state.orgJedinicaChange?.naziv)) {
      setInvalidFieldsOrgJedinica((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (!state?.orgJedinicaChange?.organizacionaJedinicaRadniDanList?.length) {
      setInvalidFieldsOrgJedinica((prev) => ({ ...prev, radniDani: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreateOrganizacionaJedinica = useMutation({
    mutationFn: (orgJedinicaChange: OrganizacionaJedinicaReadDto) => {
      setShowBlockUI(true);
      const orgJedinica: OrganizacionaJedinicaReadDto = {
        ...orgJedinicaChange,
        dostupanZaEksterneZakazivace: false,
        organizacionaJedinicaTip: organizacionaJedinicaTipList[0],
      };
      return axiosCreateOrganizacionaJedinica(orgJedinica);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ORGANIZACIONA_JEDINICA_LIST] });
      setShowBlockUI(false);
      showMessage(MessageType.SUCCESS, Labels.ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_CREATE_ORGANIZACIONA_JEDINICA_SUCCESS);
      dispatch({ type: "set_organizaciona_jedinica", data: undefined });
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
      setShowBlockUI(false);
    },
  });

  const onUpdateOrganizacionaJedinica = useMutation({
    mutationFn: (orgJedinicaChange: OrganizacionaJedinicaReadDto) => {
      setShowBlockUI(true);
      return axiosUpdateOrganizacionaJedinica(orgJedinicaChange);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ORGANIZACIONA_JEDINICA_LIST] });
      setShowBlockUI(false);
      showMessage(MessageType.SUCCESS, Labels.ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_UPDATE_ORGANIZACIONA_JEDINICA_SUCCESS);
      dispatch({ type: "set_organizaciona_jedinica", data: undefined });
      dispatch({ type: "set_is_editing_org_jedinica", data: false });
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
      setShowBlockUI(false);
    },
  });

  const onDeleteOrganizacionaJedinica = useMutation({
    mutationFn: (orgJedId: number) => axiosDeleteOrganizacionaJedinica(orgJedId),
    onSuccess: () => {
      showMessage(MessageType.SUCCESS, Labels.ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_DELETE_ORGANIZACIONA_JEDINICA_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ORGANIZACIONA_JEDINICA_LIST] });
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
    },
  });

  const onSubmitOrganizacionaJedinica = () => {
    if (!state?.orgJedinicaIsEditing) {
      validateCreateOrgJedinica() && onCreateOrganizacionaJedinica.mutate(state?.orgJedinicaChange!);
    } else {
      validateCreateOrgJedinica() && onUpdateOrganizacionaJedinica.mutate(state?.orgJedinicaChange!);
    }
  };

  const validateRadnik = () => {
    let isInvalid = false;

    if (checkEmpty(state.radnikChange?.ime)) {
      setInvalidFieldsRadnik((prev) => ({ ...prev, ime: true }));
      isInvalid = true;
    }
    if (checkEmpty(state.radnikChange?.prezime)) {
      setInvalidFieldsRadnik((prev) => ({ ...prev, prezime: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.radnikChange?.username)) {
      setInvalidFieldsRadnik((prev) => ({ ...prev, username: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.radnikChange?.email)) {
      setInvalidFieldsRadnik((prev) => ({ ...prev, email: true }));
      isInvalid = true;
    }
    if (!checkEmpty(state?.radnikChange?.email) && !isEmailFormatValid(state?.radnikChange?.email)) {
      setInvalidFieldsRadnik((prev) => ({ ...prev, invalidEmail: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.radnikChange?.polTrenutni)) {
      setInvalidFieldsRadnik((prev) => ({ ...prev, polTrenutni: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.radnikChange?.radnikUlogaList) || !state?.radnikChange?.radnikUlogaList.length) {
      setInvalidFieldsRadnik((prev) => ({ ...prev, radnikUlogaList: true }));
      isInvalid = true;
    }
    if (checkEmpty(state?.radnikChange?.radnikOrganizacionaJedinicaList) || !state?.radnikChange?.radnikOrganizacionaJedinicaList.length) {
      setInvalidFieldsRadnik((prev) => ({ ...prev, radnikOrganizacionaJedinicaList: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreateRadnik = useMutation({
    mutationFn: (radnikChange: RadnikInicijalizacijaReadDto) => {
      setShowBlockUI(true);
      const radnik: RadnikInicijalizacijaReadDto = { ...radnikChange };
      return axiosCreateInicijalizacijaRadnik(radnik);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.INICIJALIZACIJA_RADNIK_LIST] });
      setShowBlockUI(false);
      showMessage(MessageType.SUCCESS, Labels.RADNIK_TITLE_MESSAGE_CREATE_RADNIK_SUCCESS);
      dispatch({ type: "set_radnik", data: undefined });
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
      setShowBlockUI(false);
    },
  });

  const onUpdateRadnik = useMutation({
    mutationFn: (radnik: RadnikInicijalizacijaReadDto) => {
      setShowBlockUI(true);
      return axiosUpdateInicijalizacijaRadnik(radnik);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.INICIJALIZACIJA_RADNIK_LIST] });
      setShowBlockUI(false);
      showMessage(MessageType.SUCCESS, Labels.RADNIK_TITLE_MESSAGE_UPDATE_RADNIK_SUCCESS);
      dispatch({ type: "set_radnik", data: undefined });
      dispatch({ type: "set_is_editing_radnik", data: false });
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
      setShowBlockUI(false);
    },
  });

  const onDeleteRadnik = useMutation({
    mutationFn: (radnikId: number) => axiosDeleteRadnik(radnikId),
    onSuccess: () => {
      showMessage(MessageType.SUCCESS, Labels.RADNIK_TITLE_MESSAGE_DELETE_RADNIK_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.INICIJALIZACIJA_RADNIK_LIST] });
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
    },
  });

  const onSubmitRadnik = () => {
    if (!state?.isRadnikEditing) {
      validateRadnik() && onCreateRadnik.mutate(state?.radnikChange!);
    } else {
      validateRadnik() && onUpdateRadnik.mutate(state?.radnikChange!);
    }
  };

  const validateUsluga = () => {
    let isInvalid = false;

    if (checkEmpty(state.uslugaChange?.naziv)) {
      setInvalidFieldsUsluga((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (checkEmpty(state.uslugaChange?.sifra)) {
      setInvalidFieldsUsluga((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onSubmitUsluga = () => {
    if (!state?.isUslugaEditing) {
      validateUsluga() && onCreateUsluga.mutate(state?.uslugaChange!);
    } else {
      validateUsluga() && onUpdateUsluga.mutate(state?.uslugaChange!);
    }
  };

  const onCreateUsluga = useMutation({
    mutationFn: (uslugaChange: SifarnikUslugaReadDto) => {
      let uslugaCreate = {
        ...uslugaChange,
        ustanova: authData!.currentRadnik.ustanovaPoslednjaIzabrana,
        vaziOd: new Date(),
      };
      setShowBlockUI(true);
      return axiosCreateSifarnikUsluga(uslugaCreate);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.SIFARNIK_USLUGA_LIST] });
      setShowBlockUI(false);
      showMessage(MessageType.SUCCESS, Labels.SIFARNIK_USLUGA_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_SUCCESS);
      dispatch({ type: "set_usluga", data: undefined });
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
      setShowBlockUI(false);
    },
  });

  const onUpdateUsluga = useMutation({
    mutationFn: (usluga: SifarnikUslugaReadDto) => {
      setShowBlockUI(true);
      return axiosUpdateSifarnikUsluga(usluga);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.SIFARNIK_USLUGA_LIST] });
      setShowBlockUI(false);
      showMessage(MessageType.SUCCESS, Labels.SIFARNIK_USLUGA_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_SUCCESS);
      dispatch({ type: "set_usluga", data: undefined });
      dispatch({ type: "set_is_editing_usluga", data: false });
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
      setShowBlockUI(false);
    },
  });

  const onDeleteUsluga = useMutation({
    mutationFn: (uslugaId: number) => axiosDeleteSifarnikUsluga(uslugaId),
    onSuccess: () => {
      showMessage(MessageType.SUCCESS, Labels.SIFARNIK_USLUGA_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.SIFARNIK_USLUGA_LIST] });
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
    },
  });

  const onSaveTipoviPregleda = useMutation({
    mutationFn: () => {
      setShowBlockUI(true);
      return axiosCopyKontaktTipWithOrganizacionaJedinica({ kontaktTipList: state?.tipoviPregleda });
    },
    onSuccess: () => {
      showMessage(MessageType.SUCCESS, Labels.LABEL_TIPOVI_PREGLEDA_INICIJALIZACIJA_UPDATE_SUCCESS_MESSAGE);
      dispatch({ type: "set_tipovi_pregleda", data: [] });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.INICIJALIZACIJA_TIP_PREGLEDA_LIST] });
      setShowBlockUI(false);
    },
    onError: (error) => {
      handleAxiosCallError(showMessage, error);
    },
  });

  return (
    <InicijalizacijaUstanoveView
      state={state}
      dispatch={dispatch}
      invalidFieldsUstanova={invalidFieldsUstanova}
      setInvalidFieldsUstanova={setInvalidFieldsUstanova}
      continueAction={continueAction}
      validateCreateOrgJedinica={validateCreateOrgJedinica}
      invalidFieldsOrgJedinica={invalidFieldsOrgJedinica}
      setInvalidFieldsOrgJedinica={setInvalidFieldsOrgJedinica}
      onSubmitOrganizacionaJedinica={onSubmitOrganizacionaJedinica}
      onDeleteOrganizacionaJedinica={onDeleteOrganizacionaJedinica}
      ustanova={ustanova}
      isUstanovaLoading={isUstanovaLoading}
      onUpdateUstanova={onUpdateUstanova}
      validateUstanovaStep={validateUstanovaStep}
      invalidFieldsRadnik={invalidFieldsRadnik}
      setInvalidFieldsRadnik={setInvalidFieldsRadnik}
      validateRadnik={validateRadnik}
      onSubmitRadnik={onSubmitRadnik}
      onDeleteRadnik={onDeleteRadnik}
      invalidFieldsUsluga={invalidFieldsUsluga}
      setInvalidFieldsUsluga={setInvalidFieldsUsluga}
      onSubmitUsluga={onSubmitUsluga}
      onDeleteUsluga={onDeleteUsluga}
      onSaveTipoviPregleda={onSaveTipoviPregleda}
    />
  );
}
