import moment from "moment";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { formatDate, removeAllMasks, useEffectOnce } from "../../../../infrastructure/system/Utils";
import actionsSifarnikCena from "../../../../infrastructure/system/hooks/dialog-sifarnik-cena-reducer/actionsSifarnikCena";
import { InitialStateSifarnikCenaType } from "../../../../infrastructure/system/hooks/dialog-sifarnik-cena-reducer/initialStateSifarnikCena";
import { usePoreskaStopaList } from "../../../../infrastructure/system/hooks/sifarnici/poreska-stopa/usePoreskaStopaList";
import { useValutaList } from "../../../../infrastructure/system/hooks/sifarnici/valuta-list/useValutaList";
import DialogComponent from "../../../dialog-component/DialogComponent";
import CalendarComponent from "../../calendar-component/CalendarComponent";

interface DialogCenaType {
  state: InitialStateSifarnikCenaType;
  dispatch: React.Dispatch<{ type: actionsSifarnikCena }> | any;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
}

export default function DialogCena(props: DialogCenaType) {
  const { state, dispatch, onCreate, onDelete, onUpdate } = props;
  const Labels = useLabels();

  const { valutaList } = useValutaList();
  const { poreskaStopaList } = usePoreskaStopaList();

  const { authData } = useContext(AppContext);
  const vaziOdRef = useRef<HTMLDivElement | any>(null);
  const vaziDoRef = useRef<HTMLDivElement | any>(null);

  useEffectOnce(() => {
    dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "valuta", value: authData?.currentRadnik.ustanovaPoslednjaIzabrana.valuta } });
  });

  const renderHeader = () => {
    let title;
    if (state.operation === EntityOperation.CREATE) {
      title = Labels.SIFARNIK_USLUGA_DODAJ_CENU;
    } else if (state.operation === EntityOperation.UPDATE) {
      title = Labels.SIFARNIK_USLUGA_IZMENI_CENU;
    } else {
      title = Labels.SIFARNIK_USLUGA_OBRISI_CENU;
    }
    return <div className="flex justify-content-start">{title}</div>;
  };

  const renderFooter = () => {
    let classTemp, icon, label, action;

    if (state.operation === EntityOperation.CREATE) {
      label = Labels.SIFANIK_USLUGA_LABEL_DODAJ;
      icon = "pi pi-plus";
      classTemp = "mr-2 m-1";
      action = onCreate;
    } else if (state.operation === EntityOperation.UPDATE) {
      label = Labels.BUTTON_UPDATE;
      icon = "pi pi-check";
      classTemp = "mr-2 m-1";
      action = onUpdate;
    } else {
      label = Labels.BUTTON_DELETE;
      classTemp = "p-button-danger mr-2 m-1";
      icon = "pi pi-trash";
      action = onDelete;
    }

    return (
      <div className="flex justify-content-end">
        <Button
          label={Labels.BUTTON_CANCEL}
          icon="pi pi-times"
          className="button-danger m-1 p-button-outlined"
          onClick={() => {
            dispatch({ type: actionsSifarnikCena.CLOSE_DIALOG });
            [].forEach.call(document.getElementsByClassName("p-datepicker-mask"), function (element: Element) {
              element.remove();
            });
          }}
        />
        <Button label={label} tooltip={label} className={classTemp} icon={icon} onClick={action} />
      </div>
    );
  };

  const isTodayOrInFuture = () => {
    return moment(state?.cena?.vaziOd).isSame(new Date(), "days") || moment(state?.cena?.vaziOd).isAfter(new Date(), "days");
  };

  return (
    <DialogComponent
      header={renderHeader}
      visible={state.isDialogOpen}
      footer={renderFooter}
      onHide={() => {
        dispatch({ type: actionsSifarnikCena.CLOSE_DIALOG });
        removeAllMasks();
      }}
      blockScroll={true}
      className="w-11 sm:w-8 md:w-8 lg:w-4"
      contentClassName="overflow-y-visible"
      content={
        <div className="col-8 xl:col-12 lg:col-8 md:col-6 sm:col-12">
          <div className="grid align-items-center p-0">
            <div className="col-4">
              <div>
                {Labels.VAZI_OD}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-8 p-fluid p-0">
              <CalendarComponent
                ref={vaziOdRef}
                value={state?.cena?.vaziOd}
                onChange={(e: CalendarChangeEvent) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "vaziOd", value: formatDate(e.value as Date | undefined) } });
                  if (e.target.value) vaziOdRef?.current?.hide();
                }}
                className="w-full"
                onHide={removeAllMasks}
                disabled={state.operation === EntityOperation.DELETE || !isTodayOrInFuture()}
                minDate={new Date()}
                placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
              />
            </div>
            <div className="col-4">
              <div>{Labels.VAZI_DO}</div>
            </div>
            <div className="col-8 p-fluid p-0">
              <CalendarComponent
                ref={vaziDoRef}
                value={state?.cena?.vaziDo}
                onChange={(e: CalendarChangeEvent) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "vaziDo", value: formatDate(e.value as Date | undefined) } });
                  if (e.target.value) vaziDoRef?.current?.hide();
                }}
                className="w-full"
                onHide={removeAllMasks}
                disabled={state.operation === EntityOperation.DELETE}
                placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
              />
            </div>
            <div className="col-4">
              <div>
                {Labels.HEADER_IZNOS}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-8 p-fluid p-0">
              <InputNumber
                disabled={state.operation === EntityOperation.DELETE || !isTodayOrInFuture()}
                value={state?.cena?.iznos}
                onChange={(e) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "iznos", value: e.value } });
                }}
                min={0}
                mode="decimal"
                locale="sr-RS"
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>
            <div className="col-4">
              <div>
                {Labels.SIFARNIK_USLUGA_VALUTA}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-8 p-fluid p-0">
              <Dropdown
                optionLabel="sifra"
                optionValue="id"
                disabled={state.operation !== EntityOperation.CREATE}
                options={valutaList}
                value={state?.cena?.valuta?.id ?? undefined}
                resetFilterOnHide
                onChange={(e) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "valuta", value: { id: e.value } } });
                }}
                emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              />
            </div>
            <div className="col-4">
              <div>
                {Labels.HEADER_PORESKA_STOPA}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-8 p-fluid p-0">
              <Dropdown
                optionLabel="procenat"
                optionValue="id"
                disabled={state.operation === EntityOperation.DELETE || !isTodayOrInFuture()}
                options={poreskaStopaList}
                value={state?.cena?.poreskaStopa?.id}
                resetFilterOnHide
                onChange={(e) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "poreskaStopa", value: { id: e.value } } });
                }}
                emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                itemTemplate={(e) => `${e.procenat} %`}
                valueTemplate={(e) => `${e?.procenat} %`}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}
