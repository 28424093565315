import actionsDodavanjePostojecegRadnikaUstanova from "./actionsDodavanjePostojecegRadnikaUstanova";
import { InitialStateDodavanjePostojecegRadnikaUstanovaType } from "./initialStateDodavanjePostojecegRadnikaUstanova";

export default function reducerDodavanjePostojecegRadnikaUstanova(state: InitialStateDodavanjePostojecegRadnikaUstanovaType, action: any) {
  switch (action.type) {
    case actionsDodavanjePostojecegRadnikaUstanova.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true
      };
    }
    case actionsDodavanjePostojecegRadnikaUstanova.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        radnikSelected: undefined,
        radnikAddList: []
      };
    }
    case actionsDodavanjePostojecegRadnikaUstanova.SET_RADNIK_LIST: {
      return {
        ...state,
        radnikAddList: action.value
      };
    }
    case actionsDodavanjePostojecegRadnikaUstanova.SET_SELECTED_RADNIK: {
      return {
        ...state,
        radnikSelected: action.value
      };
    }
    default:
      return state;
  }
}
