import { PickList } from "primereact/picklist";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import UslugaSpecijalizacijaReadDto from "../../../../model/sifarnik-usluga/UslugaSpecijalizacijaReadDto";
import SifarnikSpecijalizacijaReadDto from "../../../../model/sifarnik/sifarnik-specijalizacija/SifarnikSpecijalizacijaReadDto";
import SkeletonPickListItem from "../../../app/skeleton/SkeletonPickListItem";
import SifarnikUslugaSpecijalizacijeListLogical from "./SifarnikUslugaSpecijalizacijeListLogical";

interface SifarnikUslugaSpecijalizacijeListViewPropsType {
  usluga: string | undefined;
}

export default function SifarnikUslugaSpecijalizacijeListView(props: SifarnikUslugaSpecijalizacijeListViewPropsType) {
  const { usluga } = props;
  const { isLoading, specijalizacijeList, uslugaSpecijalizacijeList, sourceSelection, setSourceSelection, targetSelection, setTargetSelection, onMoveToTarget, onMoveToSource } =
    SifarnikUslugaSpecijalizacijeListLogical(usluga);

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const sourceItemTemplate = (item: SifarnikSpecijalizacijaReadDto | undefined) => {
    if (item === undefined || item === null || isLoading) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{item?.naziv}</h6>;
  };

  const targetItemTemplate = (item: UslugaSpecijalizacijaReadDto | undefined) => {
    if (item === undefined || item === null || isLoading) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{item?.sifarnikSpecijalizacija?.naziv}</h6>;
  };

  return (
    <div className="layout-usluga specijalizacija-picklist">
      <div className={!pristup || isLoading ? "read-only" : ""}>
        <PickList
          source={specijalizacijeList}
          target={uslugaSpecijalizacijeList}
          sourceItemTemplate={sourceItemTemplate}
          targetItemTemplate={targetItemTemplate}
          sourceHeader={Labels.LABEL_SPECIJALIZACIJE_LIST}
          targetHeader={Labels.LABEL_USLUGA_SPECIJALIZACIJE_LIST}
          filterBy="naziv,sifarnikSpecijalizacija.naziv"
          sourceFilterPlaceholder={Labels.LABEL_SPECIJALIZACIJE_SEARCH}
          targetFilterPlaceholder={Labels.LABEL_USLUGA_SPECIJALIZACIJE_SEARCH}
          showSourceControls={false}
          showTargetControls={false}
          sourceSelection={sourceSelection}
          targetSelection={targetSelection}
          onMoveToSource={() => onMoveToSource(targetSelection)}
          onMoveToTarget={() => onMoveToTarget(sourceSelection)}
          onMoveAllToSource={(e) => onMoveToSource(e.value)}
          onMoveAllToTarget={(e) => onMoveToTarget(e.value)}
          onSourceSelectionChange={(e) => setSourceSelection(e.value)}
          onTargetSelectionChange={(e) => setTargetSelection(e.value)}
          metaKeySelection={false}
        />
      </div>
    </div>
  );
}
