import MandatoryBaseEntityDto from "../MandatoryBaseEntityDto";
import PacijentCreateDto from "../pacijent/PacijentCreateDto";
import PacijentUpdateDto from "../pacijent/PacijentUpdateDto";
import EnumBaseMandatoryDto from "../sifarnik/EnumBaseMandatoryDto";
import ZakazanTerminUslugaCreateDto from "./ZakazanTerminUslugaCreateDto";

class ZakazanTermin {
  radnik: MandatoryBaseEntityDto;
  pacijent: PacijentCreateDto | PacijentUpdateDto | undefined;
  organizacionaJedinica: MandatoryBaseEntityDto;
  zakazanTerminStatus: EnumBaseMandatoryDto;
  zakazanTerminTip: EnumBaseMandatoryDto;
  vremeZakazanoOd: string;
  vremeZakazanoDo: string;
  kontaktTip?: MandatoryBaseEntityDto | undefined;
  vremeDolaska?: string | undefined;
  napomena?: string | undefined;
  planiranaUslugaList?: Array<ZakazanTerminUslugaCreateDto> | undefined;

  constructor(
    radnik: MandatoryBaseEntityDto,
    pacijent: MandatoryBaseEntityDto | PacijentUpdateDto | PacijentCreateDto | undefined,
    organizacionaJedinica: MandatoryBaseEntityDto,
    vremeZakazanoOd: string,
    vremeZakazanoDo: string,
    zakazanTerminStatus: EnumBaseMandatoryDto,
    zakazanTerminTip: EnumBaseMandatoryDto,
    kontaktTip?: MandatoryBaseEntityDto,
    vremeDolaska?: string,
    napomena?: string,
    planiranaUslugaList?: Array<ZakazanTerminUslugaCreateDto> | undefined
  ) {
    this.radnik = { id: radnik.id };
    this.pacijent = pacijent;
    this.organizacionaJedinica = { id: organizacionaJedinica.id };
    this.vremeZakazanoOd = vremeZakazanoOd;
    this.vremeZakazanoDo = vremeZakazanoDo;
    this.kontaktTip = kontaktTip && { id: kontaktTip.id };
    this.zakazanTerminStatus = { sifra: zakazanTerminStatus.sifra };
    this.zakazanTerminTip = zakazanTerminTip;
    this.vremeDolaska = vremeDolaska;
    this.napomena = napomena;
    this.planiranaUslugaList = planiranaUslugaList;
  }
}

export default ZakazanTermin;
