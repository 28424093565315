import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import DodatniModulCreateDto from "../../model/licenca/dodatni-modul/DodatniModulCreateDto";
import DodatniModulReadDto from "../../model/licenca/dodatni-modul/DodatniModulReadDto";
import UstanovaDodatniModulCreateDto from "../../model/ustanova/UstanovaDodatniModulCreateDto";
import UstanovaDodatniModulUpdateDto from "../../model/ustanova/UstanovaDodatniModulUpdateDto";

interface DodatniModulControllerType {
  axiosSearchDodatniModulList: (searchString: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetDodatniModul: (dodatniModulId: number) => Promise<AxiosResponse<any>>;
  axiosCreateDodatniModul: (dodatniModulChange: DodatniModulCreateDto) => Promise<AxiosResponse<any>>;
  axiosUpdateDodatniModul: (dodatniModul: DodatniModulReadDto) => Promise<AxiosResponse<any>>;
  axiosUstanovaGetDodatniModulList: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosUstanovaUpdateDodatniModul: (updateDto: UstanovaDodatniModulUpdateDto, ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosUstanovaGetDodatniModul: (ustanovaDodatniModulId: number, ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosUstanovaCreateDodatniModul: (ustanovaDodatniModul: UstanovaDodatniModulCreateDto, ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosUstanovaDeleteDodatniModul: (ustanovaDodatniModulId: number, ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosUstanovaSearchDodatniModul: (searchString: SearchBaseDto) => Promise<AxiosResponse<any>>;
}

export function DodatniModulController(): DodatniModulControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchDodatniModulList = (searchString: SearchBaseDto) => {
    return axios.get(Endpoint.DODATNI_MODUL_SEARCH, axiosConfig(authData!.token, searchString));
  };
  const axiosGetDodatniModul = (dodatniModulId: number) => {
    return axios.get(`${Endpoint.DODATNI_MODUL_LIST}/${dodatniModulId}`, axiosConfig(authData!.token));
  };

  const axiosCreateDodatniModul = (dodatniModulChange: DodatniModulCreateDto) => {
    return axios.post(`${Endpoint.DODATNI_MODUL_LIST}`, dodatniModulChange, axiosConfig(authData!.token));
  };

  const axiosUpdateDodatniModul = (dodatniModul: DodatniModulReadDto) => {
    return axios.put(`${Endpoint.DODATNI_MODUL_LIST}/${dodatniModul?.id}`, dodatniModul, axiosConfig(authData!.token));
  };

  const axiosUstanovaGetDodatniModulList = (ustanovaId: number) => {
    return axios.get(`${Endpoint.USTANOVA_DODATNI_MODUL}/ustanova/${ustanovaId}`, axiosConfig(authData!.token));
  };

  const axiosUstanovaGetDodatniModul = (ustanovaDodModulId: number, ustanovaId: number) => {
    return axios.get(`${Endpoint.USTANOVA_DODATNI_MODUL}/${ustanovaDodModulId}/ustanova/${ustanovaId}`, axiosConfig(authData!.token));
  };

  const axiosUstanovaUpdateDodatniModul = (updateDto: UstanovaDodatniModulUpdateDto, ustanovaId: number) => {
    return axios.put(`${Endpoint.USTANOVA_DODATNI_MODUL}/${updateDto.id}/ustanova/${ustanovaId}`, updateDto, axiosConfig(authData!.token));
  };

  const axiosUstanovaCreateDodatniModul = (ustanovaDodatniModul: UstanovaDodatniModulCreateDto, ustanovaId: number) => {
    return axios.post(`${Endpoint.USTANOVA_DODATNI_MODUL}/ustanova/${ustanovaId}`, ustanovaDodatniModul, axiosConfig(authData!.token));
  };

  const axiosUstanovaDeleteDodatniModul = (dodatniModulId: number, ustanovaId: number) => {
    return axios.delete(`${Endpoint.USTANOVA_DODATNI_MODUL}/${dodatniModulId}/ustanova/${ustanovaId}`, axiosConfig(authData!.token));
  };

  const axiosUstanovaSearchDodatniModul = (searchString: SearchBaseDto) => {
    return axios.get(Endpoint.USTANOVA_DODATNI_MODUL, axiosConfig(authData!.token, searchString));
  };

  return {
    axiosSearchDodatniModulList,
    axiosGetDodatniModul,
    axiosCreateDodatniModul,
    axiosUpdateDodatniModul,
    axiosUstanovaGetDodatniModulList,
    axiosUstanovaUpdateDodatniModul,
    axiosUstanovaGetDodatniModul,
    axiosUstanovaCreateDodatniModul,
    axiosUstanovaDeleteDodatniModul,
    axiosUstanovaSearchDodatniModul,
  };
}
