import axios from "axios";
import { ChangeEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import MessageType from "../../infrastructure/system/MessageType";
import { axiosConfig, handleAxiosCallError } from "../../infrastructure/system/Utils";
import RadnikPasswordChangeDto from "../../model/radnik/RadnikPasswordChangeDto";

interface RadnikProfilPasswordChangeLogicalType {
  changeRadnikData: (e: ChangeEvent<HTMLInputElement>) => void;
  passwordChange: (onCancel: () => void) => void;
}

export default function RadnikProfilPasswordChangeLogical(): RadnikProfilPasswordChangeLogicalType {
  const { showMessage, authData, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const [radnikChangePassword, setRadnikChangePassword] = useState<RadnikPasswordChangeDto>({ currentPassword: "", newPassword: "", confirmPassword: "" });
  const changeRadnikData = (e: ChangeEvent<HTMLInputElement>) => {
    setRadnikChangePassword({
      ...radnikChangePassword!,
      [e.target.name]: e.target.value,
    });
  };

  const validateInput = () => {
    if (radnikChangePassword?.currentPassword === "") {
      showMessage(MessageType.ERROR, Labels.RADNIK_PROFIL_PASSWORD_CHANGE_ERROR, Labels.RADNIK_PROFIL_MESSAGES_OLD_PASSWORD);
      return false;
    }
    if (radnikChangePassword?.newPassword === "") {
      showMessage(MessageType.ERROR, Labels.RADNIK_PROFIL_PASSWORD_CHANGE_ERROR, Labels.RADNIK_PROFIL_MESSAGES_NEW_PASSWORD);
      return false;
    }
    if (radnikChangePassword?.confirmPassword === "") {
      showMessage(MessageType.ERROR, Labels.RADNIK_PROFIL_PASSWORD_CHANGE_ERROR, Labels.RADNIK_PROFIL_MESSAGES_PASSWORD_CONFIRMATION);
      return false;
    }
    if (radnikChangePassword?.confirmPassword !== radnikChangePassword?.newPassword) {
      showMessage(MessageType.ERROR, Labels.RADNIK_PROFIL_PASSWORD_CHANGE_ERROR, Labels.RADNIK_PROFIL_MESSAGES_PASSWORDS_NOT_SAME);
      return false;
    }
    return true;
  };

  const passwordChange = (onCancel: () => void) => {
    if (!validateInput()) {
      return;
    }
    setShowBlockUI(true);
    axios
      .post(`${Endpoint.RADNIK_LIST}/${authData?.currentRadnik.id}/password-change`, radnikChangePassword, axiosConfig(authData!.token))
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.RADNIK_PROFIL_CHANGE_PASSWORD_SUCCESS);
        onCancel();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return { changeRadnikData, passwordChange };
}
