import { useQuery } from "@tanstack/react-query";
import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import QueryKeys from "../../QueryKeys";

export function useOrganizacionaJedinica(id?: number) {
  const { axiosReadOrganizacionaJedinica } = OrganizacionaJedinicaController();

  return useQuery({
    queryKey: [QueryKeys.ORGANIZACIONA_JEDINICA_LIST, id],
    queryFn: () => axiosReadOrganizacionaJedinica(id!).then((res) => res.data.data),
    enabled: !!id,
  });
}
