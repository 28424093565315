import moment from "moment";
import { FileUploadHandlerEvent } from "primereact/fileupload";
import { useContext, useEffect, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { DokumentController } from "../../../../controllers/dokument/DokumentController";
import TerminController from "../../../../controllers/termin/TerminController";
import { ZakazaniTerminController } from "../../../../controllers/zakazani-termin/ZakazaniTerminController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { MAX_FILE_UPLOAD, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import DokumentReadDto from "../../../../model/dokument/DokumentReadDto";
import KontaktDosijeSimpleDto from "../../../../model/kontakt/KontaktDosijeSimpleDto";
import ZakazanTerminDatotekaReadDto from "../../../../model/zakazan-termin/datoteke/ZakazanTerminDatotekaReadDto";

interface DatotekaListLogicalType {
  uploadHandler: (e: FileUploadHandlerEvent) => void;
  datotekaListLoading: boolean;
  setDatotekaListLoading: React.Dispatch<React.SetStateAction<boolean>>;
  datotekaList: Array<DokumentReadDto>;
  deleteDatoteka: (datotekaID: number) => void;
  handleDatotekaClick: (datoteka: DokumentReadDto | ZakazanTerminDatotekaReadDto) => void;
  handleZakazanTerminDatotekaClick: (datoteka: DokumentReadDto | ZakazanTerminDatotekaReadDto) => void;
}

interface DatotekaListLogicalPropsType {
  pacijentID: number;
  kontaktList?: KontaktDosijeSimpleDto[];
}

export default function PacijentDatotekaListLogical(props: DatotekaListLogicalPropsType): DatotekaListLogicalType {
  const { pacijentID, kontaktList } = props;
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { axiosPostDokumentPacijent, axiosGetDokumentListForPacijent, axiosDeleteDokument, axiosGetDokument } = DokumentController();
  const { axiosGetDocumentFromScheduler: axiosGetDocumentsForTermin } = TerminController();
  const { axiosGetDatotekaForZakazanTerminById } = ZakazaniTerminController();
  const [datotekaListLoading, setDatotekaListLoading] = useState<boolean>(true);
  const [datotekaList, setDatotekaList] = useState<Array<DokumentReadDto>>([]);
  const Labels = useLabels();

  useEffect(() => {
    if (kontaktList && kontaktList?.length > 0) {
      setDatotekaList((prevDatotekaList) =>
        prevDatotekaList.map((datoteka) => ({
          ...datoteka,
          kontaktTip: kontaktList.filter((kontakt) => kontakt.id === datoteka.kontaktID).at(0)?.kontaktTip,
        }))
      );
    }
  }, [kontaktList]);

  const fetchPacijentDokumentList = (startTime: moment.Moment) => {
    axiosGetDokumentListForPacijent(pacijentID)
      .then((res: any) => {
        setDatotekaList(
          res.data?.data?.map((datoteka: DokumentReadDto) => ({
            ...datoteka,
            kontaktTip: kontaktList?.filter((kontakt) => kontakt.id === datoteka.kontaktID).at(0)?.kontaktTip,
          }))
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setDatotekaListLoading, startTime);
      });
  };

  function isZakazanTerminDatoteka(datoteka: DokumentReadDto | ZakazanTerminDatotekaReadDto): datoteka is ZakazanTerminDatotekaReadDto {
    return (datoteka as ZakazanTerminDatotekaReadDto).zakazanTerminID !== undefined;
  }

  const handleZakazanTerminDatotekaClick = (datoteka: ZakazanTerminDatotekaReadDto | DokumentReadDto) => {
    if (!isZakazanTerminDatoteka(datoteka)) return;
    if (!datoteka.velicina && datoteka.eksterniZakazanTerminDatotekaID) {
      axiosGetDocumentsForTermin(datoteka.zakazanTerminID, datoteka.eksterniZakazanTerminDatotekaID)
        .then((res) => {
          const link = document.createElement("a");
          const byteChars = atob(res.data.data.sadrzaj);
          const dataArray = new Array(byteChars.length);
          for (let i = 0; i < byteChars.length; i++) {
            dataArray[i] = byteChars.charCodeAt(i);
          }
          const byteArray = new Uint8Array(dataArray);
          let doc = new Blob([byteArray], { type: datoteka.tip });
          const url = URL.createObjectURL(doc);
          link.download = datoteka.naziv;
          link.href = url;
          link.click();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {});
    } else {
      axiosGetDatotekaForZakazanTerminById(datoteka.id)
        .then((res) => {
          const link = document.createElement("a");
          const byteChars = atob(res.data.data.sadrzaj);
          const dataArray = new Array(byteChars.length);
          for (let i = 0; i < byteChars.length; i++) {
            dataArray[i] = byteChars.charCodeAt(i);
          }
          const byteArray = new Uint8Array(dataArray);
          let doc = new Blob([byteArray], { type: datoteka.tip });
          const url = URL.createObjectURL(doc);
          link.download = datoteka.naziv;
          link.href = url;
          link.click();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {});
    }
  };

  const handleDatotekaClick = (dokument: DokumentReadDto | ZakazanTerminDatotekaReadDto) => {
    axiosGetDokument(dokument.id)
      .then((res) => {
        const link = document.createElement("a");
        const byteChars = atob(res.data.data.sadrzaj);
        const dataArray = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
          dataArray[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(dataArray);
        let doc = new Blob([byteArray], { type: dokument.tip });
        const url = URL.createObjectURL(doc);
        link.download = dokument.naziv;
        link.href = url;
        link.click();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {});
  };

  const savePacijentDokument = (file: File, reader: FileReader) => {
    if (reader?.result) {
      axiosPostDokumentPacijent({
        naziv: file.name,
        sadrzaj: reader.result.toString().substr(reader.result.toString().indexOf(",") + 1),
        tip: file.type,
        velicina: file.size,
        pacijentID: pacijentID,
      })
        .then(({ data: { data } }: { data: { data: DokumentReadDto } }) => {
          setDatotekaList((datotekaList) => [...datotekaList, data]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  useEffectOnce(() => {
    let startTime = moment(new Date());
    fetchPacijentDokumentList(startTime);
  });

  const deleteDatoteka = (datotekaID: number) => {
    axiosDeleteDokument(datotekaID)
      .then(() => {
        setDatotekaList((datotkaList) => datotkaList.filter((datoteka) => datoteka.id !== datotekaID));
        showMessage(MessageType.SUCCESS, Labels.MESSAGES_DELETE_DOCUMENT_SUCCESS);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const uploadHandler = (e: FileUploadHandlerEvent) => {
    setShowBlockUI(true);
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }
        if (reader.result) {
          savePacijentDokument(file, reader);
        }
      };
    });
    setShowBlockUI(false);
  };

  return { uploadHandler, datotekaListLoading, setDatotekaListLoading, datotekaList, deleteDatoteka, handleDatotekaClick, handleZakazanTerminDatotekaClick };
}
