import axios, { AxiosResponse } from "axios";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EnumController } from "../../../../controllers/enum/EnumController";
import { IzvestajParametarController } from "../../../../controllers/izvestaj/IzvestajParametarController";

import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../../infrastructure/system/MessageType";
import { checkEmpty, handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import IzvestajParametarEnumStavkaReadDto from "../../../../model/izvestaj/parametar/IzvestajParametarEnumStavkaReadDto";
import IzvestajParametarReadDto from "../../../../model/izvestaj/parametar/IzvestajParametarReadDto";

import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import { AppContext, useLabels } from "../../../../Store";

interface CrudIzvestajParametarLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  izvestajParametarChange: IzvestajParametarReadDto | undefined;
  setIzvestajParametarChange: any;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  isDisabled: boolean;
  izvestajParametarOperation: string;
  izvestajParametarId: number;
  location: any;
  onUpdate: () => void;
  onCancel: () => void;
  onDelete: () => void;
  onCreate: () => void;
  izvestajParametarLoading: boolean;
  izvestajTipLoading: boolean;
  izvestajTipList: Array<EnumBaseReadDto> | undefined;
  onPageFormTable: (rows: number, first: number) => void;
  first: number;
  tableRows: number;
  parametarEnumStavkaList: Array<IzvestajParametarEnumStavkaReadDto>;
  parametarEnumStavkaLoading: boolean;
  navigate: any;
  tipParametraSifra: string;
  openParametarEnumStavka: (entityOperation: string, parametarEnumStavka?: IzvestajParametarEnumStavkaReadDto) => void;
}

interface UseParamsType {
  paramId?: string;
  izvestajId?: string;
}

export default function CrudIzvestajParametarLogical(isSuperAdmin?: boolean): CrudIzvestajParametarLogicalType {
  const location = useLocation();
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);

  const [isDisabled] = useState(isFormDisabled(location.state ? location.state.izvestajParametarOperation : EntityOperation.UPDATE));
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [izvestajParametarOperation] = useState<string>(location.state ? location.state.izvestajParametarOperation : EntityOperation.UPDATE);
  const [parametarNaziv] = useState<string>(location.state ? location.state.parametarNaziv : "");
  const [izvestajNaziv] = useState<string>(location.state ? location.state.izvestajNaziv : "");
  const [izvestajOperation] = useState<string>(location.state ? location.state.izvestajOperation : EntityOperation.UPDATE);

  const [index, setIndex] = useState<number>(tabIndex);
  const [izvestajParametarChange, setIzvestajParametarChange] = useState<IzvestajParametarReadDto>();

  const { axiosUpdateIzvestajParametar, axiosGetIzvestajParametar, axiosGetIzvestajParametarEnumStavkaList } = IzvestajParametarController();
  const {
    axiosSuperadminCreateParametriStatistickogIzvestaja,
    axiosSuperadminGetParametarStatistickogIzvestaja,
    axiosSuperadminUpdateParametarStatistickogIzvestaja,
    axiosSuperadminDeleteParametarStatistickogIzvestaja,
    axiosSuperadminGetIzvestajParametarEnumStavkaList,
  } = SuperadminController();

  const { axiosGetIzvestajParametarTip } = EnumController();

  const [tipParametraSifra, setTipParametraSifra] = useState<string>("");

  const [parametarEnumStavkaList, setParametarEnumStavkaList] = useState<Array<IzvestajParametarEnumStavkaReadDto>>([]);

  const [izvestajTipLoading, setIzvestajTipLoading] = useState<boolean>(false);
  const [parametarEnumStavkaLoading, setParametarEnumStavkaLoading] = useState<boolean>(true);
  const [izvestajParametarLoading, setIzvestajParametarLoading] = useState<boolean>(true);

  const izvestajParametarId = Number(useParams<keyof UseParamsType>()["paramId"]);
  const izvestajId = Number(useParams<keyof UseParamsType>()["izvestajId"]);

  const [izvestajTipList, setIzvestajTipList] = useState<Array<EnumBaseReadDto> | undefined>();
  const navigate = useNavigate();

  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);

  const [breadCrumbItems] = useState<Array<any>>([
    {
      label: Labels.IZVESTAJ_LIST,
      command: () => {
        navigate("/izvestaj-list");
      },
    },
    {
      label: izvestajOperation === EntityOperation.UPDATE ? Labels.IZVESTAJ_TITLE_DIALOG_UPDATE + " " + izvestajNaziv : Labels.IZVESTAJ_TITLE_DIALOG_DELETE,
      command: () => {
        navigate("/crud-izvestaj/" + izvestajId);
      },
    },
    {
      label:
        izvestajParametarOperation === EntityOperation.CREATE
          ? Labels.IZVESTAJ_PARAMETRI_CREATE
          : izvestajParametarOperation === EntityOperation.UPDATE
          ? Labels.IZVESTAJ_PARAMETRI_UPDATE + parametarNaziv
          : izvestajParametarOperation === EntityOperation.READ
          ? Labels.IZVESTAJ_PARAMETRI_LIST + parametarNaziv
          : Labels.IZVESTAJ_PARAMETRI_DELETE + parametarNaziv,
    },
  ]);
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());

    setIzvestajParametarLoading(true);

    axiosGetIzvestajParametarTip()
      .then((res) => {
        setIzvestajTipList(res.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });

    if (izvestajParametarId) {
      const requestIzvestajParametar = isSuperAdmin ? axiosSuperadminGetParametarStatistickogIzvestaja(izvestajId, izvestajParametarId) : axiosGetIzvestajParametar(izvestajParametarId);
      const requestIzvestajParametarEnumStavkaList = isSuperAdmin
        ? axiosSuperadminGetIzvestajParametarEnumStavkaList(izvestajId, izvestajParametarId)
        : axiosGetIzvestajParametarEnumStavkaList(izvestajParametarId);
      axios
        .all([requestIzvestajParametar, requestIzvestajParametarEnumStavkaList])
        .then(
          axios.spread((responseIzvestajParametar: any, responseIzvestajParametarEnumStavkaList: any, responseIzvestajTipList: any) => {
            setIzvestajParametarChange(responseIzvestajParametar.data.data);
            setTipParametraSifra(responseIzvestajParametar.data.data.izvestajParametarTipEnum.sifra);
            setParametarEnumStavkaList(responseIzvestajParametarEnumStavkaList.data.data);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setIzvestajParametarLoading, startTime);
          skeletonTimeout(setParametarEnumStavkaLoading, startTime);
          skeletonTimeout(setIzvestajTipLoading, startTime);
        });
    } else {
      setIzvestajParametarLoading(false);
    }
  };

  const openParametarEnumStavka = (entityOperation: string, parametarEnumStavka?: IzvestajParametarEnumStavkaReadDto) => {
    const parametarEnumStavkaId = entityOperation !== EntityOperation.CREATE ? parametarEnumStavka?.id : "";
    navigate(`/crud-izvestaj/${izvestajId}/crud-parametri-izvestaja/${izvestajParametarId}/parametar-enum-stavka/${parametarEnumStavkaId}`, {
      state: { parametarEnumStavkaOperation: entityOperation, izvestajParametarOperation: izvestajParametarOperation, izvestajNaziv: izvestajNaziv, parametarNaziv: parametarNaziv },
    });
  };

  const validateIzvestajParametar = (izvestajParametar?: IzvestajParametarReadDto) => {
    if (!izvestajParametar?.izvestajParametarTipEnum?.sifra) {
      showMessage(MessageType.ERROR, Labels.MESSAGE_PARAMETAR_TIP_ENUM_REQUIRED);
      return false;
    }
    if (checkEmpty(izvestajParametar?.naziv)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_NAZIV_REQUIRED);
      return false;
    }
    if (checkEmpty(izvestajParametar?.requestName)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_REQUEST_NAME_REQUIRED);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    let newIzvestajParametra = izvestajParametarChange!;
    if (izvestajParametarChange && !izvestajParametarChange?.hasOwnProperty("naziv")) {
      newIzvestajParametra = { ...newIzvestajParametra, naziv: "" };
    }
    if (newIzvestajParametra && !newIzvestajParametra?.hasOwnProperty("requestName")) {
      newIzvestajParametra = { ...newIzvestajParametra, requestName: "" };
    }

    if (!validateIzvestajParametar(newIzvestajParametra)) {
      return;
    }
    if (newIzvestajParametra) {
      setShowBlockUI(true);
      axiosSuperadminCreateParametriStatistickogIzvestaja({ ...newIzvestajParametra, statistickiIzvestaj: { ...newIzvestajParametra.statistickiIzvestaj, id: izvestajId } })
        .then((res: AxiosResponse) => {
          showMessage(MessageType.SUCCESS, Labels.IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DIALOG_UPDATE);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_IZVESTAJ_PARAMETAR_1 +
              izvestajParametarChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_1 +
              res.data.data?.izvestajParametarTipEnum.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_2 +
              izvestajParametarChange?.requestName +
              ". "
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    if (izvestajParametarChange) {
      setShowBlockUI(true);
      axiosSuperadminDeleteParametarStatistickogIzvestaja(izvestajId, izvestajParametarChange?.id)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DELETE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_IZVESTAJ_PARAMETAR_1 +
              izvestajParametarChange.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_1 +
              izvestajParametarChange.izvestajParametarTipEnum.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_2 +
              izvestajParametarChange.requestName +
              ". "
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateIzvestajParametar(izvestajParametarChange)) {
      return;
    }
    if (izvestajParametarChange) {
      setShowBlockUI(true);
      let izvestajParametarTipEnum: EnumBaseReadDto = izvestajParametarChange.izvestajParametarTipEnum;
      let requestName: string = izvestajParametarChange.requestName;
      (isSuperAdmin ? axiosSuperadminUpdateParametarStatistickogIzvestaja(izvestajParametarChange) : axiosUpdateIzvestajParametar(izvestajParametarChange))
        .then(({ data: { data } }: { data: { data: IzvestajParametarReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DIALOG_UPDATE);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_IZVESTAJ_PARAMETAR_1 +
              data?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_1 +
              izvestajParametarTipEnum.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_2 +
              requestName +
              ". "
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command();
    } else {
      navigate(`/crud-izvestaj/${izvestajId}`, { state: { tabIndex: 1, pacijentPoljeOperation: izvestajParametarOperation } });
    }
  };

  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    breadCrumbItems,
    izvestajParametarChange,
    setIzvestajParametarChange,
    setIndex,
    index,
    izvestajParametarId,
    izvestajParametarOperation,
    isDisabled,
    location,
    onUpdate,
    onCancel,
    onDelete,
    onCreate,
    izvestajParametarLoading,
    izvestajTipLoading,
    izvestajTipList,
    onPageFormTable,
    first,
    tableRows,
    parametarEnumStavkaList,
    parametarEnumStavkaLoading,
    navigate,
    tipParametraSifra,
    openParametarEnumStavka,
  };
}
