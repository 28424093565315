import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { VerzijaController } from "../../../../controllers/verzija/VerzijaController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";
import { DATE_TIME_FORMAT_4, handleAxiosCallError, handleAxiosCallSuccess } from "../../../../infrastructure/system/Utils";
import VerzijaCreateDto from "../../../../model/verzija/VerzijaCreateDto";
import moment from "moment";

export function useCreateVerzija() {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosCreateVerzija } = VerzijaController();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (verzija: VerzijaCreateDto) => {
      const verzijaCreate = { ...verzija, datum: moment(verzija.datum).format(DATE_TIME_FORMAT_4) };
      return axiosCreateVerzija(verzijaCreate);
    },
    onSuccess: () => {
      handleAxiosCallSuccess(showMessage, Labels.VERZIJA_CREATE_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.VERZIJA_LIST] });
    },
    onError: (err) => {
      handleAxiosCallError(showMessage, err);
    },
  });
}
