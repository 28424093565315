import moment from "moment/moment";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { PaketController } from "../../../../controllers/paket/PaketController";
import { SifarnikUslugaController } from "../../../../controllers/sifarnik-usluga/SifarnikUslugaController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";

import PaketUslugaCreateDto from "../../../../model/paket/PaketUslugaCreateDto";
import PaketUslugaReadDto from "../../../../model/paket/PaketUslugaReadDto";
import PaketUslugaUpdateDto from "../../../../model/paket/PaketUslugaUpdateDto";
import SifarnikUslugaDto from "../../../../model/sifarnik/SifarnikUslugaDto";
import { AppContext, useLabels } from "../../../../Store";

interface PaketUslugaListLogicalType {
  paketUslugaLoading: boolean;
  paketUslugaList: Array<PaketUslugaReadDto>;
  first: number;
  tableRows: number;
  onPagePaketUsluga: (rows: number, first: number) => void;
  isDialogPaketUslugaOpen: boolean;
  openDialogPaketUsluga: (action: string, data: PaketUslugaReadDto | any) => void;
  closeDialogPaketUsluga: () => void;
  paketUslugaChange: PaketUslugaCreateDto | undefined;
  setPaketUslugaChange: any;
  searchSifarnikUsluga: (e: AutoCompleteCompleteEvent) => void;
  sifarnikUslugaList: Array<SifarnikUslugaDto>;
  dialogAction: string;
  paketUsluga: PaketUslugaReadDto | undefined;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
}

interface UseParamsType {
  paketId?: string;
}

export default function PaketUslugaListLogical(): PaketUslugaListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const paketId = Number(useParams<keyof UseParamsType>()["paketId"]);
  const [paketUslugaLoading, setPaketUslugaLoading] = useState<boolean>(true);
  const [paketUslugaList, setPaketUslugaList] = useState<Array<PaketUslugaReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [isDialogPaketUslugaOpen, setIsDialogPaketUslugaOpen] = useState<boolean>(false);
  const [paketUslugaChange, setPaketUslugaChange] = useState<PaketUslugaCreateDto | undefined>();
  const [sifarnikUslugaList, setSifarnikUslugaList] = useState<Array<SifarnikUslugaDto>>([]);
  const [dialogAction, setDialogAction] = useState<string>("");
  const [paketUsluga, setPaketUsluga] = useState<PaketUslugaReadDto>();

  const { axiosGetUslugeListByPaketId, axiosCreateUslugaForPaket, axiosDeleteUslugaFromPaket, axiosUpdateUslugaFromPaket } = PaketController();
  const { axiosSearchSifarnikUslugaByNazivOrSifra } = SifarnikUslugaController();
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    if (paketId) {
      let startTime = moment(new Date());
      setPaketUslugaLoading(true);
      axiosGetUslugeListByPaketId(paketId)
        .then((res) => {
          setPaketUslugaList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          skeletonTimeout(setPaketUslugaLoading, startTime);
        })
        .finally(() => {
          skeletonTimeout(setPaketUslugaLoading, startTime);
        });
    } else {
      setPaketUslugaLoading(false);
    }
  };

  const onPagePaketUsluga = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const openDialogPaketUsluga = (action: string, data: PaketUslugaReadDto) => {
    setIsDialogPaketUslugaOpen(true);
    setDialogAction(action);
    if (data !== undefined) {
      setPaketUsluga(data);
    }
    if (action === EntityOperation.CREATE && data === undefined) {
      setPaketUslugaChange({
        ...paketUslugaChange!,
        paket: { id: paketId },
        podrazumevanoOdabrana: false,
      });
    } else {
      setPaketUslugaChange({
        ...paketUslugaChange!,
        paket: { id: paketId },
        podrazumevanoOdabrana: data?.podrazumevanoOdabrana,
        prikazniRedosled: data?.prikazniRedosled,
        sifarnikUsluga: { id: data?.sifarnikUsluga.id },
      });
    }
  };

  const closeDialogPaketUsluga = () => {
    setIsDialogPaketUslugaOpen(false);
    setPaketUslugaChange(undefined);
  };

  const searchSifarnikUsluga = (e: AutoCompleteCompleteEvent) => {
    if (e.query.length > 2) {
      axiosSearchSifarnikUslugaByNazivOrSifra({ searchString: e.query })
        .then((res: any) => {
          setSifarnikUslugaList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const validateInput = (usluga?: PaketUslugaCreateDto) => {
    if (!usluga?.sifarnikUsluga?.id) {
      showMessage(MessageType.ERROR, Labels.PAKET_USLUGA_MANDATORY_FIELD);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateInput(paketUslugaChange)) {
      return;
    }
    if (paketUslugaChange && paketId) {
      axiosCreateUslugaForPaket(paketId, paketUslugaChange)
        .then(() => {
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_FOR_PAKET_USLUGA + paketUslugaChange?.sifarnikUslugaFull?.naziv + ".");
        })
        .then(() => {
          closeDialogPaketUsluga();
          showMessage(MessageType.SUCCESS, Labels.PAKET_USLUGA_ADD_USLUGA_SUCCESS);
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onDelete = () => {
    if (paketUsluga?.id) {
      axiosDeleteUslugaFromPaket(paketId, paketUsluga?.id)
        .then(() => {
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_FOR_PAKET_USLUGA + paketUsluga?.sifarnikUsluga?.naziv + ".");
        })
        .then(() => {
          closeDialogPaketUsluga();
          showMessage(MessageType.SUCCESS, Labels.PAKET_USLUGA_DELETE_USLUGA_SUCCESS);
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onUpdate = () => {
    let uslugaUpdate: PaketUslugaUpdateDto | undefined = paketUslugaChange ? { ...paketUslugaChange } : undefined;
    if (uslugaUpdate && paketUsluga?.id) {
      axiosUpdateUslugaFromPaket(paketId, uslugaUpdate, paketUsluga.id)
        .then(() => {
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USLUGA_FOR_PAKET_USLUGA + paketUsluga?.sifarnikUsluga?.naziv + ".");
        })
        .then(() => {
          closeDialogPaketUsluga();
          showMessage(MessageType.SUCCESS, Labels.PAKET_USLUGA_UPDATE_USLUGA_SUCCESS);
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  return {
    paketUslugaLoading,
    paketUslugaList,
    first,
    tableRows,
    onPagePaketUsluga,
    isDialogPaketUslugaOpen,
    openDialogPaketUsluga,
    closeDialogPaketUsluga,
    paketUslugaChange,
    setPaketUslugaChange,
    searchSifarnikUsluga,
    sifarnikUslugaList,
    onCreate,
    dialogAction,
    paketUsluga,
    onDelete,
    onUpdate,
  };
}
