import { AxiosResponse } from "axios";
import moment from "moment";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useContext, useReducer, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import PartnerController from "../../../../controllers/partner/PartnerController";
import { SifarnikUslugaController } from "../../../../controllers/sifarnik-usluga/SifarnikUslugaController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { checkEmpty, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import actionsPartnerCena from "../../../../infrastructure/system/hooks/dialog-partner-cena-reducer/actionsPartnerCena";
import initialStatePartnerCena, { InitialStatePartnerCenaType } from "../../../../infrastructure/system/hooks/dialog-partner-cena-reducer/initialStatePartnerCena";
import reducerPartnerCena from "../../../../infrastructure/system/hooks/dialog-partner-cena-reducer/reducerPartnerCena";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import PartnerCenaCreateDto from "../../../../model/partner/PartnerCenaCreateDto";
import PartnerCenaReadDto from "../../../../model/partner/PartnerCenaReadDto";
import PartnerCenaSearchDto from "../../../../model/partner/PartnerCenaSearchDto";
import PartnerCenaUpdateDto from "../../../../model/partner/PartnerCenaUpdateDto";
import SifarnikUslugaDto from "../../../../model/sifarnik/SifarnikUslugaDto";

interface PartnereCeneListLogicalType {
  state: InitialStatePartnerCenaType;
  dispatch: React.Dispatch<{ type: actionsPartnerCena }> | any;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  searchSifarnikUsluga: (e: AutoCompleteCompleteEvent) => void;
  sifarnikUslugaList: Array<SifarnikUslugaDto>;
  first: number;
  tableRows: number;
  onPagePartnerCene: (rows: number, first: number) => void;
  ceneList: Array<PartnerCenaReadDto>;
  ceneLoading: boolean;
  partnerCeneSearch: PartnerCenaSearchDto | undefined;
  setPartnerCeneSearch: React.Dispatch<React.SetStateAction<any>>;
  searchPartnerCene: () => void;
  partnerId: number;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  vaziOdRef: HTMLDivElement | any;
  vaziDoRef: HTMLDivElement | any;
}

interface UseParamsType {
  partnerId?: string;
}

export default function PartnerCeneListLogical(): PartnereCeneListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const location = useLocation();
  const [state, dispatch] = useReducer(reducerPartnerCena, initialStatePartnerCena);
  const [sifarnikUslugaList, setSifarnikUslugaList] = useState<Array<SifarnikUslugaDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [ceneList, setCeneList] = useState<Array<PartnerCenaReadDto>>([]);
  const [ceneLoading, setCeneLoading] = useState<boolean>(false);
  const [partnerCeneSearch, setPartnerCeneSearch] = useState<PartnerCenaSearchDto | undefined>();
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const vaziOdRef = useRef<HTMLDivElement | any>(null);
  const vaziDoRef = useRef<HTMLDivElement | any>(null);
  const partnerId = Number(useParams<keyof UseParamsType>()["partnerId"]);
  const partnerNaziv = location?.state?.partnerNaziv;
  const { axiosSearchSifarnikUslugaByNazivOrSifra } = SifarnikUslugaController();
  const { axiosCreateCenaForPartner, axiosSearchCeneForPartner, axiosDeleteCenaForPartner, axiosUpdateCenaForPartner } = PartnerController();
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchCene();
  });

  const searchSifarnikUsluga = (e: AutoCompleteCompleteEvent) => {
    if (e.query.length) {
      axiosSearchSifarnikUslugaByNazivOrSifra({ searchString: e.query })
        .then((res: AxiosResponse) => {
          setSifarnikUslugaList(res.data.data);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onPagePartnerCene = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const fetchCene = () => {
    let startTime = moment(new Date());
    setCeneLoading(true);
    if (partnerId) {
      axiosSearchCeneForPartner(partnerId, partnerCeneSearch)
        .then((response: AxiosResponse) => {
          setCeneList(response.data.data);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
          skeletonTimeout(setCeneLoading, startTime);
        })
        .finally(() => {
          skeletonTimeout(setCeneLoading, startTime);
        });
    }
  };

  const validateNovaCena = (cena: PartnerCenaCreateDto | undefined) => {
    let isInvalid = false;

    if (checkEmpty(state.cena?.usluga?.id)) {
      setInvalidFields((prev) => ({ ...prev, usluga: true }));
      isInvalid = true;
    }
    if (checkEmpty(state.cena?.vaziOd)) {
      setInvalidFields((prev) => ({ ...prev, vaziOd: true }));
      isInvalid = true;
    }
    if (state.cena.vaziDo && !moment(state.cena.vaziDo).isAfter(moment(state.cena.vaziOd), "days")) {
      setInvalidFields((prev) => ({ ...prev, vaziDo: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateNovaCena(state.cena)) {
      return;
    }

    axiosCreateCenaForPartner(state.cena)
      .then(() => {
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_1 +
            partnerNaziv +
            Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_2 +
            state?.cena?.sifarnikUslugaFull.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_3 +
            state?.cena?.vaziOd +
            "."
        );
      })
      .then(() => {
        dispatch({ type: actionsPartnerCena.CLOSE_DIALOG });
        showMessage(MessageType.SUCCESS, Labels.CENE_CREATE_SUCCESS_MESSAGE);
        fetchCene();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onDelete = () => {
    if (partnerId) {
      axiosDeleteCenaForPartner(partnerId, state.cena?.id)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CENA_DELETE_PARTNER_1 +
              partnerNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_2 +
              state?.cena?.usluga.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_3 +
              state?.cena?.vaziOd +
              "."
          );
        })
        .then(() => {
          dispatch({ type: actionsPartnerCena.CLOSE_DIALOG });
          showMessage(MessageType.SUCCESS, Labels.CENE_DELETE_SUCCESS_MESSAGE);
          fetchCene();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onUpdate = () => {
    if (partnerId) {
      let cenaPartnerUpdate: PartnerCenaUpdateDto | any = state?.cena
        ? {
            id: state?.cena?.id,
            partner: { id: partnerId },
            vaziOd: state?.cena?.vaziOd,
            vaziDo: state?.cena?.vaziDo,
            kategorijaPopusta: state?.cena?.kategorijaPopusta?.id ? { id: state?.cena?.kategorijaPopusta?.id } : null,
            cena: state?.cena.cena,
            valuta: state?.cena?.valuta?.id ? { id: state?.cena?.valuta?.id } : null,
            poreskaStopa: state?.cena?.poreskaStopa?.id ? { id: state?.cena?.poreskaStopa?.id } : null,
          }
        : undefined;

      axiosUpdateCenaForPartner(cenaPartnerUpdate)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CENA_UPDATE_PARTNER_1 +
              partnerNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_2 +
              state?.cena?.usluga.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_3 +
              state?.cena?.vaziOd +
              "."
          );
        })
        .then(() => {
          dispatch({ type: actionsPartnerCena.CLOSE_DIALOG });
          showMessage(MessageType.SUCCESS, Labels.CENE_UPDATE_SUCCESS_MESSAGE);
          fetchCene();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const searchPartnerCene = () => {
    fetchCene();
  };

  return {
    state,
    dispatch,
    onCreate,
    onDelete,
    onUpdate,
    searchSifarnikUsluga,
    sifarnikUslugaList,
    first,
    tableRows,
    onPagePartnerCene,
    ceneList,
    ceneLoading,
    partnerCeneSearch,
    setPartnerCeneSearch,
    searchPartnerCene,
    partnerId,
    invalidFields,
    setInvalidFields,
    vaziOdRef,
    vaziDoRef,
  };
}
