import jwtDecode from "jwt-decode";
import { useContext, useMemo } from "react";
import { AppContext } from "../../Store";
import EnumPravaPristupa from "./EnumPravaPristupa";
import { isSifraIncluded } from "./Utils";

interface KontrolaPravaPristupaServiceType {
  hasPacijentCreate: boolean;
  hasPacijentDelete: boolean;
  hasPacijentRead: boolean;
  hasPacijentUpdate: boolean;
  hasPacijentView: boolean;
  hasZakazanTerminCreate: boolean;
  hasZakazanTerminDelete: boolean;
  hasZakazanTerminRead: boolean;
  hasZakazanTerminUpdate: boolean;
  hasZakazanTerminView: boolean;
  hasKontaktUslugaCreate: boolean;
  hasKontaktUslugaDelete: boolean;
  hasKontaktUslugaRead: boolean;
  hasKontaktUslugaUpdate: boolean;
  hasKontaktUslugaView: boolean;
  hasKontaktProizvodCreate: boolean;
  hasKontaktProizvodDelete: boolean;
  hasKontaktProizvodRead: boolean;
  hasKontaktProizvodUpdate: boolean;
  hasKontaktProizvodView: boolean;
  hasKontaktDijagnozaCreate: boolean;
  hasKontaktDijagnozaDelete: boolean;
  hasKontaktDijagnozaRead: boolean;
  hasKontaktDijagnozaUpdate: boolean;
  hasKontaktDijagnozaView: boolean;
  hasKontaktPaketCreate: boolean;
  hasKontaktPaketDelete: boolean;
  hasKontaktPaketRead: boolean;
  hasKontaktPaketUpdate: boolean;
  hasKontaktPaketView: boolean;
  hasKontaktCreate: boolean;
  hasKontaktDelete: boolean;
  hasKontaktRead: boolean;
  hasKontaktUpdate: boolean;
  hasKontaktView: boolean;
  hasFormularPopunjenCreate: boolean;
  hasFormularPopunjenDelete: boolean;
  hasFormularPopunjenRead: boolean;
  hasFormularPopunjenUpdate: boolean;
  hasFormularPopunjenView: boolean;
  hasReceptCreate: boolean;
  hasReceptDelete: boolean;
  hasReceptRead: boolean;
  hasReceptUpdate: boolean;
  hasReceptView: boolean;
}

const KontrolaPravaPristupaService = (): KontrolaPravaPristupaServiceType => {
  const { authData } = useContext(AppContext);

  const decodedToken: any = jwtDecode(authData?.token as string);
  const rolaList = decodedToken?.rolaList;

  const {
    hasPacijentCreate,
    hasPacijentDelete,
    hasPacijentRead,
    hasPacijentUpdate,
    hasPacijentView,
    hasZakazanTerminCreate,
    hasZakazanTerminDelete,
    hasZakazanTerminRead,
    hasZakazanTerminUpdate,
    hasZakazanTerminView,
    hasKontaktUslugaCreate,
    hasKontaktUslugaDelete,
    hasKontaktUslugaRead,
    hasKontaktUslugaUpdate,
    hasKontaktUslugaView,
    hasKontaktProizvodCreate,
    hasKontaktProizvodDelete,
    hasKontaktProizvodRead,
    hasKontaktProizvodUpdate,
    hasKontaktProizvodView,
    hasKontaktDijagnozaCreate,
    hasKontaktDijagnozaDelete,
    hasKontaktDijagnozaRead,
    hasKontaktDijagnozaUpdate,
    hasKontaktDijagnozaView,
    hasKontaktPaketCreate,
    hasKontaktPaketDelete,
    hasKontaktPaketRead,
    hasKontaktPaketUpdate,
    hasKontaktPaketView,
    hasKontaktCreate,
    hasKontaktDelete,
    hasKontaktRead,
    hasKontaktUpdate,
    hasKontaktView,
    hasFormularPopunjenCreate,
    hasFormularPopunjenDelete,
    hasFormularPopunjenRead,
    hasFormularPopunjenUpdate,
    hasFormularPopunjenView,
    hasReceptCreate,
    hasReceptDelete,
    hasReceptRead,
    hasReceptUpdate,
    hasReceptView,
  } = useMemo(() => {
    const pravaPristupaList = rolaList.map((rola: any) => ({ sifra: rola }));

    return {
      hasPacijentCreate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.PACIJENT_CREATE),
      hasPacijentDelete: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.PACIJENT_DELETE),
      hasPacijentRead: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.PACIJENT_READ),
      hasPacijentUpdate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.PACIJENT_UPDATE),
      hasPacijentView: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.PACIJENT_VIEW),
      hasZakazanTerminCreate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.ZAKAZAN_TERMIN_CREATE),
      hasZakazanTerminDelete: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.ZAKAZAN_TERMIN_DELETE),
      hasZakazanTerminRead: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.ZAKAZAN_TERMIN_READ),
      hasZakazanTerminUpdate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.ZAKAZAN_TERMIN_UPDATE),
      hasZakazanTerminView: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.ZAKAZAN_TERMIN_VIEW),
      hasKontaktUslugaCreate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_USLUGA_CREATE),
      hasKontaktUslugaDelete: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_USLUGA_DELETE),
      hasKontaktUslugaRead: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_USLUGA_READ),
      hasKontaktUslugaUpdate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_USLUGA_UPDATE),
      hasKontaktUslugaView: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_USLUGA_VIEW),
      hasKontaktProizvodCreate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_PROIZVOD_CREATE),
      hasKontaktProizvodDelete: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_PROIZVOD_DELETE),
      hasKontaktProizvodRead: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_PROIZVOD_READ),
      hasKontaktProizvodUpdate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_PROIZVOD_UPDATE),
      hasKontaktProizvodView: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_PROIZVOD_VIEW),
      hasKontaktDijagnozaCreate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_DIJAGNOZA_CREATE),
      hasKontaktDijagnozaDelete: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_DIJAGNOZA_DELETE),
      hasKontaktDijagnozaRead: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_DIJAGNOZA_READ),
      hasKontaktDijagnozaUpdate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_DIJAGNOZA_UPDATE),
      hasKontaktDijagnozaView: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_DIJAGNOZA_VIEW),
      hasKontaktPaketCreate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_PAKET_CREATE),
      hasKontaktPaketDelete: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_PAKET_DELETE),
      hasKontaktPaketRead: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_PAKET_READ),
      hasKontaktPaketUpdate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_PAKET_UPDATE),
      hasKontaktPaketView: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_PAKET_VIEW),
      hasKontaktCreate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_CREATE),
      hasKontaktDelete: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_DELETE),
      hasKontaktRead: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_READ),
      hasKontaktUpdate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_UPDATE),
      hasKontaktView: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.KONTAKT_VIEW),
      hasFormularPopunjenCreate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.FORMULAR_POPUNJEN_CREATE),
      hasFormularPopunjenDelete: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.FORMULAR_POPUNJEN_DELETE),
      hasFormularPopunjenRead: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.FORMULAR_POPUNJEN_READ),
      hasFormularPopunjenUpdate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.FORMULAR_POPUNJEN_UPDATE),
      hasFormularPopunjenView: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.FORMULAR_POPUNJEN_VIEW),
      hasReceptCreate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.RECEPT_CREATE),
      hasReceptDelete: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.RECEPT_DELETE),
      hasReceptRead: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.RECEPT_READ),
      hasReceptUpdate: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.RECEPT_UPDATE),
      hasReceptView: isSifraIncluded(pravaPristupaList, EnumPravaPristupa.RECEPT_VIEW),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolaList]);

  return {
    hasPacijentCreate,
    hasPacijentDelete,
    hasPacijentRead,
    hasPacijentUpdate,
    hasPacijentView,
    hasZakazanTerminCreate,
    hasZakazanTerminDelete,
    hasZakazanTerminRead,
    hasZakazanTerminUpdate,
    hasZakazanTerminView,
    hasKontaktUslugaCreate,
    hasKontaktUslugaDelete,
    hasKontaktUslugaRead,
    hasKontaktUslugaUpdate,
    hasKontaktUslugaView,
    hasKontaktProizvodCreate,
    hasKontaktProizvodDelete,
    hasKontaktProizvodRead,
    hasKontaktProizvodUpdate,
    hasKontaktProizvodView,
    hasKontaktDijagnozaCreate,
    hasKontaktDijagnozaDelete,
    hasKontaktDijagnozaRead,
    hasKontaktDijagnozaUpdate,
    hasKontaktDijagnozaView,
    hasKontaktPaketCreate,
    hasKontaktPaketDelete,
    hasKontaktPaketRead,
    hasKontaktPaketUpdate,
    hasKontaktPaketView,
    hasKontaktCreate,
    hasKontaktDelete,
    hasKontaktRead,
    hasKontaktUpdate,
    hasKontaktView,
    hasFormularPopunjenCreate,
    hasFormularPopunjenDelete,
    hasFormularPopunjenRead,
    hasFormularPopunjenUpdate,
    hasFormularPopunjenView,
    hasReceptCreate,
    hasReceptDelete,
    hasReceptRead,
    hasReceptUpdate,
    hasReceptView,
  };
};

export default KontrolaPravaPristupaService;
