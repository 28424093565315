import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../../Store";
import { EnumController } from "../../../../controllers/enum/EnumController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import { UlogaController } from "../../../../controllers/uloga/UlogaController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import useCallbackWithoutWarnings from "../../../../infrastructure/system/hooks/useCallbackWithoutWarnings";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import UlogaPravoNadTipomCreateDto from "../../../../model/uloga/UlogaPravoNadTipomCreateDto";
import UlogaPravoNadTipomReadDto from "../../../../model/uloga/UlogaPravoNadTipomReadDto";

interface CrudUlogaPravoNadTipovimaLogicalType {
  vrstaPravaList: Array<EnumBaseReadDto>;
  tipObjektaList: Array<EnumBaseReadDto>;
  grid: Array<Array<VrstaPravaTipObjektaCell>> | undefined;
  setGrid: React.Dispatch<React.SetStateAction<VrstaPravaTipObjektaCell[][] | undefined>>;
  createUlogaPravoNadTipom: (cell: VrstaPravaTipObjektaCell) => Promise<AxiosResponse<any>>;
  deleteUlogaPravoNadTipom: (cell: VrstaPravaTipObjektaCell) => Promise<AxiosResponse<any>>;
  createAllUlogaPravoNadTipomForTipObjekta: (tipObjekta: EnumBaseReadDto) => Promise<AxiosResponse<any>>;
  createAllUlogaPravoNadTipomForVrstaPrava: (vrstaPrava: EnumBaseReadDto) => Promise<AxiosResponse<any>>;
  deleteAllUlogaPravoNadTipomForVrstaPrava: (vrstaPrava: EnumBaseReadDto) => Promise<AxiosResponse<any>>;
  deleteAllUlogaPravoNadTipomForTipObjekta: (tipObjekta: EnumBaseReadDto) => Promise<AxiosResponse<any>>;
  fetchData: () => void;
  vrstaPravaListLoading: boolean;
  tipObjektaListLoading: boolean;
}
interface CrudUlogaPravoNadTipovimaLogicalProps {
  ulogaId?: number;
  ustanovaId?: number;
}
export interface VrstaPravaTipObjektaCell {
  vrstaPrava: EnumBaseReadDto;
  tipObjekta: EnumBaseReadDto;
  check: boolean;
}
export default function CrudUlogaPravoNadTipovimaLogical(props: CrudUlogaPravoNadTipovimaLogicalProps): CrudUlogaPravoNadTipovimaLogicalType {
  const { showMessage } = useContext(AppContext);
  const { axiosGetEnumVrstaPravaList, axiosGetEnumTipObjektaList } = EnumController();
  const { axiosPostUlogaPravoNadTipomList, axiosGetUlogaPravoNadTipomList, axiosDeleteUlogaPravoNadTipom } = UlogaController();
  const { axiosSuperadminPostUlogaPravoNadTipomList, axiosSuperadminGetUlogaPravoNadTipomList, axiosSuperadminDeleteUlogaPravoNadTipom } = SuperadminController();

  const { ulogaId, ustanovaId } = props;
  const [vrstaPravaList, setVrstaPravaList] = useState<Array<EnumBaseReadDto>>([]);
  const [tipObjektaList, setTipObjektaList] = useState<Array<EnumBaseReadDto>>([]);
  const [vrstaPravaListLoading, setVrstaPravaListLoading] = useState<boolean>(true);
  const [tipObjektaListLoading, setTipObjektaListLoading] = useState<boolean>(true);
  const [grid, setGrid] = useState<Array<Array<VrstaPravaTipObjektaCell>> | undefined>();
  const vrstaPravaRef = useRef<Array<EnumBaseReadDto>>([]);
  const tipObjRef = useRef<Array<EnumBaseReadDto>>([]);

  useEffectOnce(() => {
    fetchData();
  });
  const fetchData = () => {
    const requestVrstaPrava = axiosGetEnumVrstaPravaList();
    const requestTipObj = axiosGetEnumTipObjektaList();
    let startTime = moment(new Date());
    setVrstaPravaListLoading(true);
    setTipObjektaListLoading(true);
    axios
      .all([requestVrstaPrava, requestTipObj])
      .then(
        axios.spread((responseVrstaPrava: any, responseTipObj: any) => {
          const vrstaPrava = responseVrstaPrava.data.data;
          const tipObj = responseTipObj.data.data;
          if (vrstaPrava?.length === 0 || tipObj?.length === 0) {
            return;
          }
          vrstaPravaRef.current = vrstaPrava;
          tipObjRef.current = tipObj;
          setVrstaPravaList(vrstaPrava);
          setTipObjektaList(tipObj);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setVrstaPravaListLoading, startTime);
        skeletonTimeout(setTipObjektaListLoading, startTime);
      });
  };
  const getUlogaPravoNadTipom = useCallbackWithoutWarnings(() => {
    if (ulogaId) {
      vrstaPravaRef?.current &&
        tipObjRef?.current &&
        (ustanovaId ? axiosSuperadminGetUlogaPravoNadTipomList(ustanovaId, ulogaId) : axiosGetUlogaPravoNadTipomList(ulogaId))
          .then((res: any) => {
            const response = res.data.data;
            const matrix = tipObjRef?.current?.map((tipObjUloga: EnumBaseReadDto) => {
              return vrstaPravaRef?.current?.map((vrstaPravaUloga: EnumBaseReadDto) => {
                const ulogaPravoNadTipom = response.find(
                  ({ tipObjekta, vrstaPrava }: UlogaPravoNadTipomReadDto) => tipObjekta.sifra === tipObjUloga.sifra && vrstaPrava.sifra === vrstaPravaUloga.sifra
                );
                return { vrstaPrava: vrstaPravaUloga, tipObjekta: tipObjUloga, check: Boolean(ulogaPravoNadTipom) };
              });
            });
            setGrid(matrix);
          })
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
          });
    }
  }, [ulogaId, ustanovaId]);

  useEffect(() => {
    if (ulogaId) {
      getUlogaPravoNadTipom();
    }
  }, [ulogaId, getUlogaPravoNadTipom]);

  const createUlogaPravoNadTipom = (cell: VrstaPravaTipObjektaCell) => {
    const ulogaPravoNadTipomNew: UlogaPravoNadTipomCreateDto = { uloga: { id: ulogaId }, vrstaPrava: { sifra: cell.vrstaPrava.sifra }, tipObjekta: { sifra: cell.tipObjekta.sifra } };
    let requestPostUlogaPravoNadTipomList = ustanovaId
      ? axiosSuperadminPostUlogaPravoNadTipomList(ustanovaId, Number(ulogaId), ulogaPravoNadTipomNew)
      : axiosPostUlogaPravoNadTipomList(Number(ulogaId), ulogaPravoNadTipomNew);
    return requestPostUlogaPravoNadTipomList;
  };

  const createAllUlogaPravoNadTipomForTipObjekta = (tipObjekta: EnumBaseReadDto) => {
    const ulogaPravoNadTipomNew: UlogaPravoNadTipomCreateDto = { uloga: { id: ulogaId }, tipObjekta: { sifra: tipObjekta.sifra } };
    return ustanovaId ? axiosSuperadminPostUlogaPravoNadTipomList(ustanovaId, Number(ulogaId), ulogaPravoNadTipomNew) : axiosPostUlogaPravoNadTipomList(Number(ulogaId), ulogaPravoNadTipomNew);
  };

  const createAllUlogaPravoNadTipomForVrstaPrava = (vrstaPrava: EnumBaseReadDto) => {
    const ulogaPravoNadTipomNew: UlogaPravoNadTipomCreateDto = { uloga: { id: ulogaId }, vrstaPrava: { sifra: vrstaPrava.sifra } };
    return ustanovaId ? axiosSuperadminPostUlogaPravoNadTipomList(ustanovaId, Number(ulogaId), ulogaPravoNadTipomNew) : axiosPostUlogaPravoNadTipomList(Number(ulogaId), ulogaPravoNadTipomNew);
  };

  const deleteUlogaPravoNadTipom = (cell: VrstaPravaTipObjektaCell) => {
    let sifraVrstaPrava: string = cell?.vrstaPrava?.sifra;
    let sifraTipObjekta: string = cell?.tipObjekta?.sifra;
    let requestDeleteUlogaPravoNadTipom = ustanovaId
      ? axiosSuperadminDeleteUlogaPravoNadTipom(ustanovaId, Number(ulogaId), sifraVrstaPrava, sifraTipObjekta)
      : axiosDeleteUlogaPravoNadTipom(Number(ulogaId), sifraVrstaPrava, sifraTipObjekta);
    return requestDeleteUlogaPravoNadTipom;
  };

  const deleteAllUlogaPravoNadTipomForTipObjekta = (tipObjekta: EnumBaseReadDto) => {
    return ustanovaId ? axiosSuperadminDeleteUlogaPravoNadTipom(ustanovaId, Number(ulogaId), undefined, tipObjekta.sifra) : axiosDeleteUlogaPravoNadTipom(Number(ulogaId), undefined, tipObjekta.sifra);
  };

  const deleteAllUlogaPravoNadTipomForVrstaPrava = (vrstaPrava: EnumBaseReadDto) => {
    return ustanovaId ? axiosSuperadminDeleteUlogaPravoNadTipom(ustanovaId, Number(ulogaId), vrstaPrava.sifra, undefined) : axiosDeleteUlogaPravoNadTipom(Number(ulogaId), vrstaPrava.sifra, undefined);
  };

  return {
    vrstaPravaList,
    tipObjektaList,
    grid,
    createUlogaPravoNadTipom,
    deleteUlogaPravoNadTipom,
    fetchData,
    vrstaPravaListLoading,
    tipObjektaListLoading,
    createAllUlogaPravoNadTipomForTipObjekta,
    createAllUlogaPravoNadTipomForVrstaPrava,
    deleteAllUlogaPravoNadTipomForTipObjekta,
    deleteAllUlogaPravoNadTipomForVrstaPrava,
    setGrid,
  };
}
