import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { GlobalnaDatotekaController } from "../../../../controllers/globalna-datoteka/GlobalnaDatotekaController";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleAxiosCallError, handleAxiosCallSuccess } from "../../../../infrastructure/system/Utils";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";
import GlobalnaDatotekaUpdateDto from "../../../../model/globalna-datoteka/GlobalnaDatotekaUpdateDto";

export function useUpdateGlobalnaDatoteka() {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosUpdateGlobalnaDatoteka } = GlobalnaDatotekaController();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; globalnaDatoteka: GlobalnaDatotekaUpdateDto }) => {
      return axiosUpdateGlobalnaDatoteka(params.id, params.globalnaDatoteka);
    },
    onSuccess: () => {
      handleAxiosCallSuccess(showMessage, Labels.GLOBALNA_DATOTEKA_UPDATE_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GLOBALNA_DATOTEKA_LIST] });
    },
    onError: (err) => {
      handleAxiosCallError(showMessage, err);
    },
  });
}
