import moment from "moment";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { PacijentController } from "../../../controllers/pacijent/PacijentController";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError } from "../../../infrastructure/system/Utils";
import PacijentProfilnaSlikaUpdateDto from "../../../model/pacijent/PacijentProfilnaSlikaUpdateDto";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";

interface PacijentInfoLogicalType {
  showPacijentInfo: boolean;
  setShowPacijentInfo: React.Dispatch<React.SetStateAction<boolean>>;
  showOpsteMedicinskeInfo: boolean;
  setShowOpsteMedicinskeInfo: React.Dispatch<React.SetStateAction<boolean>>;
  makeGrid: (array: Array<any>) => Array<any>;
  patchPacijentProfilnaSlika: (img: string) => void;
  pacijentAge: number;
}

interface PacijentInfoType {
  pacijent: PacijentReadDto | undefined;
  setPacijent?: React.Dispatch<React.SetStateAction<PacijentReadDto | undefined>>;
}

export default function PacijentInfoLogical(props: PacijentInfoType): PacijentInfoLogicalType {
  const { pacijent, setPacijent } = props;
  const Labels = useLabels();
  const { showMessage } = useContext(AppContext);
  const { axiosPatchPacijentProfilnaSlika } = PacijentController();
  const [showPacijentInfo, setShowPacijentInfo] = useState<boolean>(false);
  const [showOpsteMedicinskeInfo, setShowOpsteMedicinskeInfo] = useState<boolean>(false);

  const makeGrid = (array: Array<any> | undefined) => {
    if (array) {
      const columns = [...Array(Math.ceil(array?.length / 3))];
      const rows = columns.map((row: any, index: number) => array.slice(index * 3, index * 3 + 3));
      return rows;
    }
    return [];
  };

  const patchPacijentProfilnaSlika = (img: string) => {
    const body: PacijentProfilnaSlikaUpdateDto = { id: pacijent!.id, profilnaSlika: img };
    axiosPatchPacijentProfilnaSlika(body)
      .then(() => {
        setPacijent && setPacijent({ ...pacijent!, profilnaSlika: img });
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_PATCH_PROFILNA_SLIKA);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const pacijentAge = moment().diff(moment(pacijent?.vremeRodjenja), "years");

  return {
    showPacijentInfo,
    setShowPacijentInfo,
    showOpsteMedicinskeInfo,
    setShowOpsteMedicinskeInfo,
    makeGrid,
    patchPacijentProfilnaSlika,
    pacijentAge,
  };
}
