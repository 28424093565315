import { Avatar } from "primereact/avatar";
import { DATE_TIME_FORMAT_5, formatDate2, getImage, stripHTML } from "../../../infrastructure/system/Utils";
import PoslataPorukaReadDto from "../../../model/poruke/PoslataPorukaReadDto";

interface PoslataPorukaListItemProps {
  poslataPoruka: PoslataPorukaReadDto;
  onClick: () => void;
  izabrana: boolean;
}

export default function PoslataPorukaListItem({ poslataPoruka, onClick, izabrana }: PoslataPorukaListItemProps) {
  return (
    <div className={`flex pr-2 min-w-0 align-items-start hc-gap-3 cursor-pointer border-box hover:surface-200 ${izabrana && "izabrana"}`} onClick={onClick}>
      <div className="flex pl-4 align-items-center justify-content-center mt-3 hc-gap-2">
        {poslataPoruka.porukaRadnikList.length > 1 ? (
          <Avatar className="avatar avatarBorder" icon={<i className="pi pi-user-plus"></i>} size="large" shape="circle" />
        ) : poslataPoruka.porukaRadnikList[0].radnik.profilnaSlika ? (
          <Avatar
            className="avatar"
            image={getImage(false, poslataPoruka.porukaRadnikList[0].radnik.profilnaSlika!, undefined)}
            imageAlt={`${poslataPoruka.porukaRadnikList[0].radnik?.ime.charAt(0)}${poslataPoruka.porukaRadnikList[0].radnik?.prezime.charAt(0)}`}
            size="large"
            shape="circle"
          />
        ) : (
          <Avatar
            className="avatarBorder avatar"
            label={`${poslataPoruka.porukaRadnikList[0].radnik?.ime.charAt(0)}${poslataPoruka.porukaRadnikList[0].radnik?.prezime.charAt(0)}`}
            size="large"
            shape="circle"
          />
        )}
      </div>
      <div className="border-bottom-1 py-3 min-w-0 w-full pr-2 border-300">
        <div className="flex justify-content-between align-items-center">
          <p className="inline-block font-bold text-lg mb-0 min-w-0 white-space-nowrap overflow-hidden text-overflow-ellipsis">
            {poslataPoruka.porukaRadnikList.map((porukaRadnik) => `${porukaRadnik.radnik?.titula ?? ""} ${porukaRadnik.radnik.ime} ${porukaRadnik.radnik.prezime}`).join(", ")}
          </p>
          <p className="text-sm mb-0 white-space-nowrap text-600">{formatDate2(poslataPoruka.vremeKreiranja, DATE_TIME_FORMAT_5)}</p>
        </div>
        <p className="mb-0 font-semibold min-w-0 white-space-nowrap overflow-hidden text-overflow-ellipsis">{poslataPoruka.naslov}</p>
        <p className="mb-0 font-semibold text-600 min-w-0 white-space-nowrap overflow-hidden text-overflow-ellipsis">{stripHTML(poslataPoruka.sadrzaj)}</p>
      </div>
    </div>
  );
}
