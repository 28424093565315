import axios from "axios";
import classNames from "classnames";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import { PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext, JwtPayloadType, useLabels } from "../Store";
import { ParametarController } from "../controllers/parametar/ParametarController";
import { RolaService } from "../controllers/rola/RolaService";
import { AplikativnaPravaService } from "../infrastructure/system/AplikativnaPravaService";
import EnumInicijalizacijaKorak from "../infrastructure/system/EnumInicijalizacijaKorak";
import EnumJezik from "../infrastructure/system/EnumJezik";
import Images from "../infrastructure/system/Images";
import KontrolaPravaPristupaService from "../infrastructure/system/KontrolaPravaPristupaService";
import { handleAxiosCallError, isInicijalizacija, useEffectOnce } from "../infrastructure/system/Utils";
import { linkJSX } from "../infrastructure/system/helpers/MenuLinkHelper";
import AppFooter from "./app/footer/AppFooter";
import AppMenu from "./app/menu/AppMenu";
import AppTopbar from "./app/topbar/AppTopbar";
import NonInitializedDialog from "./inicijalizacija-ustanove/non-initialized-dialog/NonInitializedDialog";
import Upozorenje from "./upozorenje/Upozorenje";
import { useUstanovaGrid } from "../infrastructure/system/hooks/ustanova/useUstanovaGrid";
import jwtDecode from "jwt-decode";

export default function Home({ children }: PropsWithChildren) {
  const { deleteAuthData, showMessage, locale, authData, setParametarUstanovaList, message, nadjimed } = useContext(AppContext);
  const Labels = useLabels();
  const { isSuperadmin } = RolaService();
  const {
    isAdminUstanove,
    isAdminOrgJed,
    isAdminUlogaIRadnika,
    isAdminObavestenja,
    isAdminFaktoraRizika,
    isAdminDelovodnihKnjiga,
    isAdminIzvestaja,
    isAdminPacijentPolja,
    isAdminRadnihPerioda,
    isAdminSifarnika,
    isAdminTipovaPregledaFormularaITipovaPolja,
    isAdminPaketa,
    isAdminPartnera,
    isAdminGrupnihObavestenja,
    hasPregledLoga,
    hasGenerisanjeIzvestaja,
    hasPretragaPacijenta,
    hasNaplata,
    hasZakazivanjeTermina,
    hasSpajanjePacijenata,
  } = AplikativnaPravaService();

  const { hasKontaktView } = KontrolaPravaPristupaService();
  const { data: ustanovaGrid } = useUstanovaGrid();

  const { axiosGetParametarUstanovaList } = ParametarController();
  const localeRef = useRef<string>(locale);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (locale !== localeRef.current) {
      localeRef.current = locale;
    }
  }, [locale]);

  const tokenExpired = (token?: string) => {
    if (!token) return true;
    const decodedToken = jwtDecode<JwtPayloadType>(token);
    return Math.floor(new Date().getTime() / 1000) >= decodedToken.exp;
  };

  axios.interceptors.request.use(
    async (config) => {
      const token = authData?.token;

      if (tokenExpired(token)) {
        deleteAuthData();
        return Promise.reject(new Error(Labels.TOKEN_EXPIRED_LABEL));
      }

      if (localeRef.current === EnumJezik.SR_RS) {
        config.params = { ...config.params, language: "sr_RS" };
      } else if (localeRef.current === EnumJezik.SR_RS_LATN) {
        config.params = { ...config.params, language: "sr_RS_latn" };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (response?.status === 401) {
        deleteAuthData();
      }
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        deleteAuthData();
      }
      return Promise.reject(error);
    }
  );

  const [menu, setMenu] = useState<any>([]);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [isNonInitializedDialogVisible, setIsNonInitializedDialogVisible] = useState<boolean>(false);

  const colorScheme = "light";
  const menuTheme = "layout-sidebar-blue";

  const containerClassName = classNames(
    "layout-wrapper",
    {
      "layout-overlay": false,
      "layout-static": false,
      "layout-slim": true,
      "layout-sidebar-dim": false,
      "layout-sidebar-dark": false,
      "layout-mobile-active": staticMenuMobileActive,
      "p-input-filled": false,
      "p-ripple-disabled": true,
    },
    colorScheme === "light" ? menuTheme : ""
  );

  const redirect = (url: string) => {
    setStaticMenuMobileActive(false);
    navigate(url);
  };

  useEffectOnce(() => {
    if (isInicijalizacija(authData?.currentRadnik!) && !isSuperadmin) navigate("/inicijalizacija", { state: { step: authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.inicijalizacijaKorak?.id } });
    if (!isInicijalizacija(authData?.currentRadnik!) && authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.inicijalizacijaKorak?.sifra !== EnumInicijalizacijaKorak.INICIJALIZOVANA) {
      setIsNonInitializedDialogVisible(true);
    }
    fetchParametri();
  });

  useEffect(() => {
    createMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, ustanovaGrid]);

  const onDocumentClick = () => {};

  const createMenu = () => {
    let menu = [];
    if (hasPretragaPacijenta) {
      menu.push({
        label: Labels.MENU_PATIENT_SEARCH,
        icon: "pi pi-fw pi-search",
        command: () => redirect("/pretraga-pacijenata"),
        template: (item: any, options: any) => linkJSX(item, options, "/pretraga-pacijenata"),
      });
    }

    if (hasZakazivanjeTermina) {
      menu.push({
        label: Labels.ZAKAZIVANJE_ZAKAZIVANJE,
        icon: "pi pi-calendar-plus",
        command: () => redirect("/zakazivanje-pregleda"),
        template: (item: any, options: any) => linkJSX(item, options, "/zakazivanje-pregleda"),
      });
    } else if (hasKontaktView) {
      menu.push({
        label: Labels.ZAKAZIVANJE_CEKAONICA,
        icon: "pi pi-users",
        command: () => redirect("/zakazivanje-pregleda"),
        template: (item: any, options: any) => linkJSX(item, options, "/zakazivanje-pregleda"),
      });
    }

    if (hasNaplata) {
      menu.push({
        label: Labels.LABEL_NAPLATA,
        icon: <Image src={Images.ICON_NAPLATA} alt="naplata ikonica" />,
        command: () => redirect("/naplata"),
        template: (item: any, options: any) => linkJSX(item, options, "/naplata"),
      });
    }

    if (
      isAdminUstanove ||
      isAdminOrgJed ||
      isAdminUlogaIRadnika ||
      isAdminObavestenja ||
      isAdminFaktoraRizika ||
      isAdminDelovodnihKnjiga ||
      isAdminIzvestaja ||
      isAdminPacijentPolja ||
      isAdminRadnihPerioda ||
      isAdminSifarnika ||
      isAdminTipovaPregledaFormularaITipovaPolja ||
      isAdminPaketa ||
      isAdminPartnera ||
      isAdminGrupnihObavestenja
    ) {
      menu.push({
        label: Labels.MENU_ADMIN,
        icon: "pi pi-fw pi-shield",
        items: [
          ...(isAdminUstanove
            ? [
                {
                  label: Labels.USTANOVA,
                  icon: "pi pi-fw pi-home",
                  command: () => redirect("/crud-ustanova-admin"),
                  template: (item: any, options: any) => linkJSX(item, options, "/crud-ustanova-admin"),
                },
              ]
            : []),
          ...(isAdminOrgJed
            ? [
                {
                  label: Labels.ORGANIZACIONA_JEDINICA_LIST,
                  icon: "pi pi-fw pi-sitemap",
                  command: () => redirect("/organizaciona-jedinica-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/organizaciona-jedinica-list"),
                },
              ]
            : []),
          ...(isAdminUlogaIRadnika
            ? [
                {
                  label: Labels.ULOGA_LIST,
                  icon: "pi pi-fw pi-users",
                  command: () => redirect("/uloga-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/uloga-list"),
                },
                {
                  label: Labels.RADNIK_LIST,
                  icon: "pi pi-fw pi-user",
                  command: () => redirect("/radnik-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/radnik-list"),
                },
              ]
            : []),
          ...(isAdminTipovaPregledaFormularaITipovaPolja
            ? [
                {
                  label: Labels.TIP_KONTAKTA_LIST,
                  icon: "pi pi-fw pi-folder",
                  command: () => redirect("/tip-kontakta-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/tip-kontakta-list"),
                },
                {
                  label: Labels.POLJE_TIP_LIST,
                  icon: "pi pi-copy",
                  command: () => redirect("/polje-tip-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/polje-tip-list"),
                },
                {
                  label: Labels.FORMULAR_LIST,
                  icon: "pi pi-file",
                  command: () => redirect("/formular-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/formular-list"),
                },
              ]
            : []),
          ...(isAdminPacijentPolja
            ? [
                {
                  label: Labels.PACIJENT_POLJE_LIST,
                  icon: "pi pi-user-edit",
                  command: () => redirect("/pacijent-polje-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/pacijent-polje-list"),
                },
              ]
            : []),
          ...(isAdminRadnihPerioda && !ustanovaGrid?.virtuelniRadniPeriodi
            ? [
                {
                  label: Labels.RADNI_PERIOD_LIST,
                  icon: "pi pi-fw pi-calendar",
                  command: () => redirect("/radni-period-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/radni-period-list"),
                },
              ]
            : []),
          ...(hasSpajanjePacijenata
            ? [
                {
                  label: Labels.LABEL_SPAJANJE_PACIJENATA,
                  icon: "pi pi-fw pi-link",
                  command: () => redirect("/spajanje-pacijenata"),
                  template: (item: any, options: any) => linkJSX(item, options, "/spajanje-pacijenata"),
                },
              ]
            : []),
          ...(isAdminSifarnika || isAdminPaketa
            ? [
                {
                  label: Labels.LABEL_SIFARNIK_LIST,
                  icon: "pi pi-fw pi-list",
                  items: [
                    ...(isAdminSifarnika
                      ? [
                          {
                            label: Labels.LABEL_SIFARNIK_USLUGA,
                            icon: "pi pi-fw pi-list",
                            command: () => redirect("/sifarnik-usluga-list"),
                            template: (item: any, options: any) => linkJSX(item, options, "/sifarnik-usluga-list"),
                          },
                        ]
                      : []),
                    ...(isAdminPaketa
                      ? [
                          {
                            label: Labels.LABEL_PAKETI_USLUGA,
                            icon: "pi pi-fw pi-list",
                            command: () => redirect("/paket-usluga-list"),
                            template: (item: any, options: any) => linkJSX(item, options, "/paket-usluga-list"),
                          },
                        ]
                      : []),
                    ...(isAdminSifarnika
                      ? [
                          {
                            label: Labels.SIFARNIK_JEDINICA_MERE,
                            icon: "pi pi-fw pi-list",
                            command: () => redirect("/sifarnik-jedinica-mere-list"),
                            template: (item: any, options: any) => linkJSX(item, options, "/sifarnik-jedinica-mere-list"),
                          },
                        ]
                      : []),
                    ...(isAdminSifarnika
                      ? [
                          {
                            label: Labels.LABEL_SIFARNIK_PROIZVODA,
                            icon: "pi pi-fw pi-list",
                            command: () => redirect("/sifarnik-proizvoda-list"),
                            template: (item: any, options: any) => linkJSX(item, options, "/sifarnik-proizvoda-list"),
                          },
                        ]
                      : []),
                    ...(isAdminSifarnika
                      ? [
                          {
                            label: Labels.LABEL_SIFARNIK_LEKOVA_NA_RECEPT,
                            icon: "pi pi-fw pi-list",
                            command: () => redirect("/sifarnik-lekova-na-recept-list"),
                            template: (item: any, options: any) => linkJSX(item, options, "/sifarnik-lekova-na-recept-list"),
                          },
                        ]
                      : []),
                    ...(isAdminSifarnika
                      ? [
                          {
                            label: Labels.LABEL_SIFARNIK_NACINI_PLACANJA,
                            icon: "pi pi-fw pi-list",
                            command: () => redirect("/sifarnik-nacina-placanja-list"),
                            template: (item: any, options: any) => linkJSX(item, options, "/sifarnik-nacina-placanja-list"),
                          },
                        ]
                      : []),
                    ...(isAdminSifarnika
                      ? [
                          {
                            label: Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA,
                            icon: "pi pi-fw pi-list",
                            command: () => redirect("/sifarnik-kategorija-popusta-list"),
                            template: (item: any, options: any) => linkJSX(item, options, "/sifarnik-kategorija-popusta-list"),
                          },
                        ]
                      : []),
                  ],
                },
                ...(isAdminPartnera
                  ? [
                      {
                        label: Labels.LABEL_PARTNERI,
                        icon: <Image src={Images.ICON_PARTNERI} alt="partneri ikonica" />,
                        command: () => redirect("/partneri-list"),
                        template: (item: any, options: any) => linkJSX(item, options, "/partneri-list"),
                      },
                    ]
                  : []),
              ]
            : []),
          ...(isAdminObavestenja
            ? [
                {
                  label: Labels.OBAVESTENJE_LIST,
                  icon: "pi pi-fw pi-info-circle",
                  command: () => redirect("/obavestenje-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/obavestenje-list"),
                },
              ]
            : []),
          ...(isAdminGrupnihObavestenja
            ? [
                {
                  label: Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE,
                  icon: "pi pi-fw pi-bell",
                  command: () => redirect("/obavestenja-pacijenti-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/obavestenja-pacijenti-list"),
                },
              ]
            : []),
          ...(isAdminIzvestaja
            ? [
                {
                  label: Labels.IZVESTAJ_LIST,
                  icon: "pi pi-fw pi-chart-bar",
                  command: () => redirect("/izvestaj-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/izvestaj-list"),
                },
              ]
            : []),
          ...(isAdminDelovodnihKnjiga
            ? [
                {
                  label: Labels.PROTOKOL_LIST,
                  icon: "pi pi-fw pi-book",
                  command: () => redirect("/protokol-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/protokol-list"),
                },
              ]
            : []),
          ...(isAdminFaktoraRizika
            ? [
                {
                  label: Labels.FAKTOR_RIZIKA_LIST,
                  icon: "pi pi-fw pi-heart",
                  command: () => redirect("/faktor-rizika-admin"),
                  template: (item: any, options: any) => linkJSX(item, options, "/faktor-rizika-admin"),
                },
              ]
            : []),
          ...(isAdminUstanove
            ? [
                {
                  label: Labels.IZVOZ,
                  icon: "pi pi-fw pi-external-link",
                  command: () => redirect("/izvoz-list"),
                  template: (item: any, options: any) => linkJSX(item, options, "/izvoz-list"),
                },
              ]
            : []),
        ],
      });
    }

    if (hasGenerisanjeIzvestaja) {
      menu.push({
        label: Labels.IZVESTAJ_LIST,
        icon: "pi pi-fw pi-chart-bar",
        command: () => redirect("/generisani-izvestaj-list"),
        template: (item: any, options: any) => linkJSX(item, options, "/generisani-izvestaj-list"),
      });
    }

    if (hasPregledLoga) {
      menu.push({
        label: Labels.LOG_HIGH_LEVEL_LIST,
        icon: "pi pi-fw pi-book",
        command: () => redirect("/log-high-level-list"),
        template: (item: any, options: any) => linkJSX(item, options, "/log-high-level-list"),
      });
    }

    if (isAdminUstanove) {
      menu.push({
        label: Labels.ISTORIJA_VERZIJA,
        icon: <Image src={Images.ISTORIJA_VERZIJA} alt="istorija verzija" />,
        command: () => redirect("/istorija-verzija"),
        template: (item: any, options: any) => linkJSX(item, options, "/istorija-verzija"),
      });
    }

    if (nadjimed) {
      menu.push({
        label: Labels.LABEL_NADJIMED,
        icon: "pi pi-fw pi-search-plus",
        items: [
          {
            label: Labels.LABEL_NADJIMED_VEZE,
            icon: "pi pi-fw pi-link",
            command: () => redirect("/veze"),
            template: (item: any, options: any) => linkJSX(item, options, "/veze"),
          },
        ],
      });
    }

    if (isSuperadmin) {
      menu.push({
        label: Labels.MENU_SUPERADMIN,
        icon: "pi pi-fw pi-lock",
        items: [
          {
            label: Labels.USTANOVA_LIST,
            icon: "pi pi-fw pi-home",
            command: () => redirect("/ustanova-list"),
            template: (item: any, options: any) => linkJSX(item, options, "/ustanova-list"),
          },
          {
            label: Labels.LABEL_REPOZITORIJUM,
            icon: "pi pi-fw pi-angle-down",
            items: [
              {
                label: Labels.TIP_KONTAKTA_LIST,
                icon: "pi pi-fw pi-list",
                command: () => redirect("/repository-tip-kontakta-list"),
                template: (item: any, options: any) => linkJSX(item, options, "/repository-tip-kontakta-list"),
              },
              {
                label: Labels.POLJE_TIP_LIST,
                icon: "pi pi-fw pi-list",
                command: () => redirect("/repository-polje-tip-list"),
                template: (item: any, options: any) => linkJSX(item, options, "/repository-polje-tip-list"),
              },
              {
                label: Labels.FORMULAR_LIST,
                icon: "pi pi-fw pi-list",
                command: () => redirect("/repository-formular-list"),
                template: (item: any, options: any) => linkJSX(item, options, "/repository-formular-list"),
              },
              {
                label: Labels.SIFARNIK_MKB,
                icon: "pi pi-fw pi-list",
                command: () => redirect("/repository-sifarnik-mkb"),
                template: (item: any, options: any) => linkJSX(item, options, "/repository-sifarnik-mkb"),
              },
              {
                label: Labels.SIFARNIK_ATRIBUTA_RFZO,
                icon: "pi pi-fw pi-list",
                command: () => redirect("/repository-sifarnik-atributa-rfzo"),
                template: (item: any, options: any) => linkJSX(item, options, "/repository-sifarnik-atributa-rfzo"),
              },
              {
                label: Labels.SIFARNIK_PROIZVODA_RFZO,
                icon: "pi pi-fw pi-list",
                command: () => redirect("/repository-sifarnik-proizvoda-rfzo"),
                template: (item: any, options: any) => linkJSX(item, options, "/repository-sifarnik-proizvoda-rfzo"),
              },
              {
                label: Labels.SIFARNIK_USLUGA_RFZO,
                icon: "pi pi-fw pi-list",
                command: () => redirect("/repository-sifarnik-usluga-rfzo"),
                template: (item: any, options: any) => linkJSX(item, options, "/repository-sifarnik-usluga-rfzo"),
              },
              {
                label: Labels.LABEL_OBRASCI_STAMPE_PROTOKOLA,
                icon: "pi pi-fw pi-list",
                command: () => redirect("/obrasci-stampe-protokola-list"),
                template: (item: any, options: any) => linkJSX(item, options, "/obrasci-stampe-protokola-list"),
              },
              {
                label: Labels.LABEL_SIFARNIK_SPECIJALIZACIJA,
                icon: "pi pi-fw pi-list",
                command: () => redirect("/repository-sifarnik-specijalizacija"),
                template: (item: any, options: any) => linkJSX(item, options, "/obrasci-stampe-protokola-list"),
              },
              {
                label: Labels.FAKTOR_RIZIKA_LIST,
                icon: "pi pi-fw pi-heart-fill",
                command: () => redirect("/faktor-rizika"),
                template: (item: any, options: any) => linkJSX(item, options, "/faktor-rizika"),
              },
              {
                label: Labels.FAKTOR_RIZIKA_IKONICA_LIST,
                icon: "pi pi-fw pi-heart",
                command: () => redirect("/faktor-rizika-ikonica"),
                template: (item: any, options: any) => linkJSX(item, options, "/faktor-rizika-ikonica"),
              },
              {
                label: Labels.SIFARNIK_IMPORT,
                icon: "pi pi-fw pi-upload",
                command: () => redirect("/sifarnik-import"),
                template: (item: any, options: any) => linkJSX(item, options, "/sifarnik-import"),
              },
              {
                label: Labels.STATISTICKI_IZVESTAJ_LIST,
                icon: "pi pi-fw pi-chart-bar",
                command: () => redirect("/izvestaj-list"),
                template: (item: any, options: any) => linkJSX(item, options, "/izvestaj-list"),
              },
            ],
          },
          {
            label: Labels.GLOBALNI_PARAMETRI,
            icon: "pi pi-fw pi-wrench",
            command: () => redirect("/globalni-parametri"),
            template: (item: any, options: any) => linkJSX(item, options, "/globalni-parametri"),
          },
          {
            label: Labels.GLOBALNE_DATOTEKE,
            icon: "pi pi-fw pi-file-o",
            command: () => redirect("/globalne-datoteke"),
            template: (item: any, options: any) => linkJSX(item, options, "/globalni-parametri"),
          },
          {
            label: Labels.LOG_POZIVA_WS,
            icon: "pi pi-fw pi-list",
            command: () => redirect("/log-list"),
            template: (item: any, options: any) => linkJSX(item, options, "/log-list"),
          },
          {
            label: Labels.UPOZORENJE,
            icon: "pi pi-fw pi-exclamation-triangle",
            command: () => redirect("/upozorenje"),
            template: (item: any, options: any) => linkJSX(item, options, "/upozorenje"),
          },
          {
            label: Labels.LICENCA_LIST,
            icon: "pi pi-fw pi-book",
            command: () => redirect("/licence"),
            template: (item: any, options: any) => linkJSX(item, options, "/licence"),
          },
          {
            label: Labels.IZVOZ,
            icon: "pi pi-fw pi-external-link",
            command: () => redirect("/superadmin-izvoz-list"),
            template: (item: any, options: any) => linkJSX(item, options, "/superadmin-izvoz-list"),
          },
          {
            label: Labels.LABEL_PRODAJNI_PAKETI,
            icon: "pi pi-fw pi-book",
            command: () => redirect("/prodajni-paketi"),
            template: (item: any, options: any) => linkJSX(item, options, "/prodajni-paketi"),
          },
          {
            label: Labels.VERZIJE,
            icon: "pi pi-tags",
            command: () => redirect("/verzije/admin"),
            template: (item: any, options: any) => linkJSX(item, options, "/verzije/admin"),
          },
          { separator: true },
        ],
      });
    }
    // menu.push({ separator: true });
    setMenu(menu);
  };

  const fetchParametri = () => {
    axiosGetParametarUstanovaList()
      .then((response: any) => {
        setParametarUstanovaList(response.data.data.sort((a: any, b: any) => a?.id - b?.id));
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onMenuClick = () => {};

  const onLogoClick = () => {
    if (authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.websiteURL) {
      window.open(authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.websiteURL, "_blank", "noreferrer");
    }
  };

  const onMenuButtonClick = (event: any) => {
    setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
  };

  const onMenuitemClick = () => {
    if (staticMenuMobileActive) setStaticMenuMobileActive(false);
  };

  const onTopbarUserMenuButtonClick = (event: any) => {
    setTopbarUserMenuActive((prevTopbarUserMenuActive) => !prevTopbarUserMenuActive);

    setStaticMenuMobileActive(false);
  };

  const routers = [
    { path: "/radnik-list", name: Labels.RADNIK_LIST },
    { path: "/podesavanja", name: Labels.LABEL_PODESAVANJA },
    {
      path: "/organizaciona-jedinica-list",
      name: Labels.ORGANIZACIONA_JEDINICA_LIST,
    },
    { path: "/radni-period-list", name: Labels.RADNI_PERIOD_LIST },
    {
      path: "/ustanova-list",
      name: Labels.USTANOVA_LIST,
    },
    { path: "/repository-tip-kontakta-list", name: Labels.TIP_KONTAKTA_LIST },
    { path: "/repository-polje-tip-list", name: Labels.POLJE_TIP_LIST },
    { path: "/repository-polje-tip", name: Labels.POLJE_TIP_LIST },
    { path: "/tip-kontakta-list", name: Labels.TIP_KONTAKTA_LIST },
    { path: "/uloga-list", name: Labels.ULOGA_LIST },
    { path: "/formular-list", name: Labels.FORMULAR_LIST },
    { path: "/polje-tip-list", name: Labels.POLJE_TIP_LIST },
    { path: "/polje-tip", name: Labels.POLJE_TIP_LIST },
    { path: "/crud-formular", name: Labels.LABEL_FORM },
    { path: "/crud-radnik", name: Labels.LABEL_RADNIK },
    { path: "/crud-organizaciona-jedinica", name: Labels.LABEL_ORGANIZACIONA_JEDINICA },
    { path: "/crud-ustanova-admin", name: Labels.USTANOVA },
    { path: "/crud-ustanova", name: Labels.USTANOVA },
    { path: "/crud-uloga", name: Labels.LABEL_ULOGA },
    { path: "/crud-tip-kontakta", name: Labels.LABEL_TIP_KONTAKTA },
    { path: "/crud-pacijent-polje", name: Labels.LABEL_PACIJENT_POLJE },
    { path: "/crud-radni-period", name: Labels.LABEL_RADNI_PERIOD },
    { path: "/pacijent-dosije", name: Labels.LABEL_PACIJENT_DOSIJE },
    { path: "/pretraga-pacijenata", name: Labels.MENU_PATIENT_SEARCH },
    { path: "/pacijent-polje-list", name: Labels.PACIJENT_POLJE_LIST },
    { path: "/kontakt", name: Labels.KONTAKT },
    { path: "/zakazivanje-pregleda", name: Labels.ZAKAZIVANJE_ZAKAZIVANJE_PREGLEDA },
    { path: "/sifarnik-usluga-list", name: Labels.LABEL_SIFARNIK_USLUGA },
    { path: "/paket-usluga-list", name: Labels.LABEL_PAKETI_USLUGA },
    { path: "/paket-usluga", name: Labels.LABEL_PAKET_USLUGA },
    { path: "/crud-sifarnik-usluga", name: Labels.LABEL_SIFARNIK_USLUGA },
    { path: "/sifarnik-jedinica-mere-list", name: Labels.SIFARNIK_JEDINICA_MERE },
    { path: "/crud-sifarnik-jedinica-mere", name: Labels.SIFARNIK_JEDINICA_MERE },
    { path: "/sifarnik-proizvoda-list", name: Labels.LABEL_SIFARNIK_PROIZVODA },
    { path: "/crud-sifarnik-proizvoda", name: Labels.LABEL_SIFARNIK_PROIZVODA },
    { path: "/sifarnik-lekova-na-recept-list", name: Labels.LABEL_SIFARNIK_LEKOVA_NA_RECEPT },
    { path: "/log-list", name: Labels.LOG_POZIVA_WS },
    { path: "/upozorenje", name: Labels.UPOZORENJE },
    { path: "/repository-sifarnik-mkb", name: Labels.SIFARNIK_MKB },
    { path: "/repository-sifarnik-atributa-rfzo", name: Labels.SIFARNIK_ATRIBUTA_RFZO },
    { path: "/repository-crud-sifarnik-atributa-rfzo", name: Labels.SIFARNIK_ATRIBUTA_RFZO },
    { path: "/repository-sifarnik-proizvoda-rfzo", name: Labels.SIFARNIK_PROIZVODA_RFZO },
    { path: "/repository-crud-sifarnik-proizvoda-rfzo", name: Labels.SIFARNIK_PROIZVODA_RFZO },
    { path: "/repository-sifarnik-usluga-rfzo", name: Labels.SIFARNIK_USLUGA_RFZO },
    { path: "/repository-crud-sifarnik-usluga-rfzo", name: Labels.SIFARNIK_USLUGA_RFZO },
    { path: "/repository-formular-list", name: Labels.FORMULAR_LIST },
    { path: "/repository-crud-formular", name: Labels.FORMULAR_LIST },
    { path: "/repository-crud-tip-kontakta", name: Labels.LABEL_TIP_KONTAKTA },
    { path: "/obavestenje-list", name: Labels.OBAVESTENJE_LIST },
    { path: "/crud-obavestenje", name: Labels.CRUD_OBAVESTENJE },
    { path: "/izvestaj-list", name: Labels.IZVESTAJ_LIST },
    { path: "/licence", name: Labels.LICENCA_LIST },
    { path: "/crud-dodatni-modul", name: Labels.DODATNI_MODUL },
    { path: "/crud-broj-naloga", name: Labels.BROJ_NALOGA },
    { path: "/crud-ustanova-broj-naloga", name: Labels.USTANOVA_BROJ_NALOGA },
    { path: "/crud-storage-limit", name: Labels.STORAGE_LIMIT },
    { path: "/crud-izvestaj", name: Labels.CRUD_IZVESTAJ },
    { path: "/crud-ustanova-storage-limit", name: Labels.USTANOVA_STORAGE_LIMIT },
    { path: "/generisani-izvestaj-list", name: Labels.IZVESTAJ_LIST },
    { path: "/crud-generisani-izvestaj", name: Labels.CRUD_GENERISANI_IZVESTAJ },
    { path: "/forbidden", name: "" },
    { path: "/protokol-list", name: Labels.PROTOKOL_LIST },
    { path: "/crud-protokol", name: Labels.CRUD_PROTOKOL },
    { path: "/log-high-level-list", name: Labels.LOG_HIGH_LEVEL_LIST },
    { path: "/globalni-parametri", name: Labels.GLOBALNI_PARAMETRI },
    { path: "/faktor-rizika-ikonica", name: Labels.FAKTOR_RIZIKA_IKONICA_LIST },
    { path: "/faktor-rizika", name: Labels.FAKTOR_RIZIKA_LIST },
    { path: "/crud-faktor-rizika", name: Labels.FAKTOR_RIZIKA_LIST },
    { path: "/sifarnik-import", name: Labels.IMPORT_SIFARNIK },
    { path: "/izvoz-list", name: Labels.IZVOZ },
    { path: "/crud-izvoz", name: Labels.IZVOZ },
    { path: "/superadmin-izvoz-list", name: Labels.IZVOZ },
    { path: "/partneri-list", name: Labels.LABEL_PARTNERI },
    { path: "/crud-partner", name: Labels.LABEL_PARTNERI },
    { path: "/sifarnik-nacina-placanja-list", name: Labels.LABEL_SIFARNIK_NACINI_PLACANJA },
    { path: "/crud-sifarnik-nacina-placanja", name: Labels.LABEL_SIFARNIK_NACINI_PLACANJA },
    { path: "/naplata", name: Labels.LABEL_NAPLATA },
    { path: "/sifarnik-kategorija-popusta-list", name: Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA },
    { path: "/crud-sifarnik-kategorija-popusta", name: Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA },
    { path: "/obavestenja-pacijenti-list", name: Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE },
    { path: "/obrasci-stampe-protokola-list", name: Labels.LABEL_OBRASCI_STAMPE_PROTOKOLA },
    { path: "/crud-obrazac-stampe-protokola", name: Labels.LABEL_OBRASCI_STAMPE_PROTOKOLA },
    { path: "/spajanje-pacijenata", name: Labels.LABEL_SPAJANJE_PACIJENATA },
    { path: "/repository-sifarnik-specijalizacija", name: Labels.LABEL_SIFARNIK_SPECIJALIZACIJA },
    { path: "/repository-crud-sifarnik-specijalizacija", name: Labels.LABEL_SIFARNIK_SPECIJALIZACIJA },
    { path: "/veze", name: Labels.LABEL_NADJIMED_VEZE },
    { path: "/crud-kategorija-statistickih-izvestaja", name: Labels.IZVESTAJ_LIST },
    { path: "/prodajni-paketi", name: Labels.LABEL_PRODAJNI_PAKETI },
    { path: "/crud-prodajni-paket", name: Labels.LABEL_PRODAJNI_PAKETI },
    { path: "/inicijalizacija", name: Labels.LABEL_INICIJALIZACIJA_PODESAVANJE_USTANOVE },
    { path: "/globalne-datoteke", name: Labels.GLOBALNE_DATOTEKE },
    { path: "/verzije", name: Labels.VERZIJE },
    { path: "/istorija-verzija", name: Labels.ISTORIJA_VERZIJA },
  ];

  return (
    <div className="layout-message">
      <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
        <div className={`layout-content-wrapper ${isInicijalizacija(authData?.currentRadnik!) && !isSuperadmin ? "layout-inicijalizacija" : ""}`}>
          <AppTopbar
            routers={routers}
            topbarUserMenuActive={topbarUserMenuActive}
            onMenuButtonClick={onMenuButtonClick}
            onTopbarUserMenu={onTopbarUserMenuButtonClick}
            setTopbarUserMenuActive={setTopbarUserMenuActive}
          />
          <div>
            <Toast ref={message} />
          </div>

          <div className={`layout-content ${isMobile ? "p-2" : ""} ${(location?.pathname.includes("/naplata") || location?.pathname.includes("/inicijalizacija")) && !isMobile ? "p-0" : ""}`}>
            <Upozorenje />
            {children}
          </div>
          <AppFooter />
        </div>
        {(!isInicijalizacija(authData?.currentRadnik!) || isSuperadmin) && (
          <AppMenu
            model={menu}
            mobileMenuActive={staticMenuMobileActive}
            onMenuClick={onMenuClick}
            logo={authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.logo ?? ""}
            websiteURL={authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.websiteURL}
            onMenuItemClick={onMenuitemClick}
            onLogoClick={onLogoClick}
          />
        )}
        <NonInitializedDialog isNonInitializedDialogVisible={isNonInitializedDialogVisible} deleteAuthData={deleteAuthData} />
        <div className="layout-mask modal-in"></div>
      </div>
    </div>
  );
}
