import { AxiosResponse } from "axios";
import moment from "moment/moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import { EnumController } from "../../../controllers/enum/EnumController";
import PartnerController from "../../../controllers/partner/PartnerController";
import { SifarnikKategorijaPopustaController } from "../../../controllers/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumPartnerTip from "../../../infrastructure/system/EnumPartnerTip";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import PartnerCreateDto from "../../../model/partner/PartnerCreateDto";
import PartnerReadDto from "../../../model/partner/PartnerReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SifarnikKategorijaPopustaReadDto from "../../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaReadDto";

interface CrudPartnerLogicalType {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  partnerOperation: string;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  isDisabled: boolean;
  partnerLoading: boolean;
  partnerChange: PartnerCreateDto | undefined;
  setPartnerChange: React.Dispatch<React.SetStateAction<PartnerCreateDto | undefined>>;
  popustiList: Array<SifarnikKategorijaPopustaReadDto>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  partnerTipList: EnumBaseReadDto[];
}

interface UseParamsType {
  partnerId?: string;
}

export default function CrudPartnerLogical(): CrudPartnerLogicalType {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const partnerId = Number(useParams<keyof UseParamsType>()["partnerId"]);
  const partnerNaziv = location?.state?.partnerNaziv;
  const [partnerOperation, setPartnerOperation] = useState<string>(location.state ? location.state.partnerOperation : EntityOperation.UPDATE);
  const [index, setIndex] = useState<number>(0);
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.LABEL_PARTNERI,
      command: () => {
        navigate("/partneri-list/");
      },
    },
  ]);

  const [partnerChange, setPartnerChange] = useState<any | undefined>();
  const [isDisabled] = useState<boolean>(isFormDisabled(location.state ? location.state.partnerOperation : EntityOperation.UPDATE));
  const [partnerLoading, setPartnerLoading] = useState<boolean>(false);
  const [partnerTipList, setPartnerTipList] = useState<EnumBaseReadDto[]>([]);
  const [popustiList, setPopustiList] = useState<Array<SifarnikKategorijaPopustaReadDto>>([]);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const { axiosGetSifarnikKategorijaPopustaList } = SifarnikKategorijaPopustaController();
  const { axiosCreatePartner, axiosGetPartnerById, axiosUpdatePartner, axiosDeletePartner } = PartnerController();
  const { axiosGetEnumPartnerTipList } = EnumController();
  const postLogHighLevel = useLogHighLevel();

  const fillBreadCrumbItems = () => {
    setBreadCrumbItems([
      {
        label: Labels.LABEL_PARTNERI,
        command: () => {
          navigate("/partneri-list/");
        },
      },
      {
        label:
          partnerOperation === EntityOperation.UPDATE
            ? Labels.LABEL_PARTNER_UPDATE + partnerNaziv
            : partnerOperation === EntityOperation.CREATE
            ? Labels.LABEL_PARTNER_CREATE
            : Labels.LABEL_PARTNER_DELETE + partnerNaziv,
      },
    ]);
  };

  useEffectOnce(() => {
    fetchData();
    fillBreadCrumbItems();
    fetchPopustiForPartner();
    fetchTipPartneraList();
  });

  const fetchData = (skpID?: number, operation?: string) => {
    const newPartnerid = skpID || partnerId;
    let startTime = moment(new Date());
    setPartnerLoading(true);
    if (newPartnerid) {
      axiosGetPartnerById(newPartnerid)
        .then(({ data: { data } }: { data: { data: PartnerReadDto } }) => {
          setPartnerChange(data);
          const operationTmp = operation ?? partnerOperation;
          setBreadCrumbItems([
            breadCrumbItems[0],
            {
              label: operationTmp === EntityOperation.UPDATE ? Labels.LABEL_PARTNER_UPDATE + data.naziv : Labels.LABEL_PARTNER_DELETE + data.naziv,
            },
          ]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPartnerLoading, startTime);
        });
    } else {
      setPartnerLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.LABEL_PARTNER_CREATE,
        },
      ]);
    }
  };

  const fetchTipPartneraList = () => {
    axiosGetEnumPartnerTipList()
      .then((response: AxiosResponse) => {
        setPartnerTipList(response.data.data);
        if (partnerOperation === EntityOperation.CREATE) setPartnerChange({ ...partnerChange, partnerTip: response.data.data.find((tip: any) => tip.sifra === EnumPartnerTip.PRAVNO_LICE) });
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const fetchPopustiForPartner = () => {
    axiosGetSifarnikKategorijaPopustaList()
      .then((response: AxiosResponse) => {
        setPopustiList(response.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const validateInput = (partner: PartnerCreateDto | undefined) => {
    let isInvalid = false;
    const mailRegex: RegExp = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
    const pibRexex: RegExp = new RegExp(/^\d{9}$/);
    const mbRexex: RegExp = new RegExp(/^\d{8}$/);
    if (partner?.email && partner.email !== undefined && partner.email !== "" && !mailRegex.test(partner.email)) {
      showMessage(MessageType.ERROR, Labels.FAKTURA_MAIL_FORMAT_ERROR);
      return false;
    }
    if (partner?.pib && partner.pib !== undefined && partner.pib !== "" && !pibRexex.test(partner.pib)) {
      showMessage(MessageType.ERROR, Labels.FAKTURA_PIB_FORMAT_ERROR);
      return false;
    }
    if (partner?.maticniBroj && partner.maticniBroj !== undefined && partner.maticniBroj !== "" && !mbRexex.test(partner.maticniBroj)) {
      showMessage(MessageType.ERROR, Labels.FAKTURA_MB_FORMAT_ERROR);
      return false;
    }
    if (partner === undefined || validateStringEmpty(partner?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(partner?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(partnerChange)) {
      return;
    }

    if (partnerChange) {
      setShowBlockUI(true);
      axiosCreatePartner(partnerChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.PARTNER_MESSAGE_CREATE_SUCCESS);
          setPartnerOperation(EntityOperation.UPDATE);
          navigate("/partneri-list");
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_1 + partnerChange?.naziv + Labels.LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_2 + partnerChange?.sifra);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(partnerChange)) {
      return;
    }

    if (partnerChange) {
      axiosUpdatePartner(partnerChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.PARTNER_MESSAGE_UPDATE_SUCCESS);
          onCancel();
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PARTNER_1 + partnerChange?.naziv + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PARTNER_2 + partnerChange?.sifra);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeletePartner(partnerId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.PARTNER_MESSAGE_DELETE_SUCCESS);
        onCancel();
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_PARTNER_1 + partnerChange?.naziv + Labels.LOG_HIGH_LEVEL_MESS_DELETE_PARTNER_2 + partnerChange?.sifra);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else navigate(`/partneri-list`);
  };

  return {
    setIndex,
    index,
    breadCrumbItems,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    isDisabled,
    partnerOperation,
    partnerLoading,
    partnerChange,
    setPartnerChange,
    popustiList,
    invalidFields,
    setInvalidFields,
    partnerTipList,
  };
}
