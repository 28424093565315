import { PickList } from "primereact/picklist";
import { useLabels } from "../../../Store";
import SkeletonPickListItem from "../../app/skeleton/SkeletonPickListItem";
import CrudTipKontaktaOrganizacionaJedinicaLogical, { TipKontaktaOrganizacionaJedinicaHolder } from "./CrudTipKontaktaOrganizacionaJedinicaLogical";

interface CrudTipKontaktaOrganizacionaJedinicaProps {
  isDisabled: boolean;
  tipKontaktaOperation: string;
  tipKontaktaNaziv?: string;
}
export default function CrudTipKontaktaOrganizacionaJedinica(props: CrudTipKontaktaOrganizacionaJedinicaProps) {
  const { isDisabled, tipKontaktaOperation, tipKontaktaNaziv } = props;
  const { tipKontaktaOrganizacionaJedinicaList, organizacionaJedinicaList, onChangeTipKontaktaOrganizacionaJedinica, isBlocked, isLoading } = CrudTipKontaktaOrganizacionaJedinicaLogical({
    orgJedOperation: tipKontaktaOperation,
    tipKontaktaNaziv: tipKontaktaNaziv,
  });
  const Labels = useLabels();
  const itemTemplate = (item: TipKontaktaOrganizacionaJedinicaHolder | undefined) => {
    if (!item || isBlocked) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{`${item.organizacionaJedinica?.naziv}`}</h6>;
  };
  return (
    <div className={"layout-generic-content-list"}>
      <div className={isDisabled || isBlocked || isLoading ? "read-only" : ""}>
        <PickList
          sourceHeader={Labels.ORGANIZACIONA_JEDINICA_LIST}
          targetHeader={Labels.LABEL_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA}
          source={organizacionaJedinicaList}
          target={tipKontaktaOrganizacionaJedinicaList}
          itemTemplate={itemTemplate}
          onChange={onChangeTipKontaktaOrganizacionaJedinica}
          showSourceControls={false}
          showTargetControls={false}
        ></PickList>
      </div>
    </div>
  );
}
