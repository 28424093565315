import { useQuery } from "@tanstack/react-query";
import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import QueryKeys from "../../QueryKeys";

export function useOrganizacionaJedinicaList(searchString?: string) {
  const { axiosSearchOrganizacionaJedinica } = OrganizacionaJedinicaController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.ORGANIZACIONA_JEDINICA_LIST, searchString],
    queryFn: () => axiosSearchOrganizacionaJedinica({ searchString }),
    staleTime: Infinity,
  });

  const organizacionaJedinicaList = data?.data?.data ?? [];

  return { organizacionaJedinicaList, refetch, isLoading };
}
