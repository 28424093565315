import { useQuery } from "@tanstack/react-query";
import QueryKeys from "../../QueryKeys";
import { RadnikController, RadnikControllerType } from "../../../../controllers/radnik/RadnikController";
import RadnikInicijalizacijaReadDto from "../../../../model/inicijalizacija/RadnikInicijalizacijaReadDto";

export function useInicijalizacijaRadnikList() {
  const { axiosGetInicijalizacijaRadnikList }: RadnikControllerType = RadnikController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.INICIJALIZACIJA_RADNIK_LIST],
    queryFn: () => axiosGetInicijalizacijaRadnikList()
  });

  const inicijalizacijaRadnikList: Array<RadnikInicijalizacijaReadDto> = data?.data?.data ?? [];

  return { inicijalizacijaRadnikList, refetch, isLoading };
}
