import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";

import { AxiosResponse } from "axios";
import { SifarnikController } from "../../../../controllers/sifarnik/SifarnikController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import SifarnikEksterniSistemReadDto from "../../../../model/eksterni-sistem/SifarnikEksterniSistemReadDto";
import UstanovaEksterniSistemReadDto from "../../../../model/eksterni-sistem/UstanovaEksterniSistemReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import { AppContext, useLabels } from "../../../../Store";

interface CrudEksterniSistemLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  eksterniSistemChange: UstanovaEksterniSistemReadDto | undefined;
  setEksterniSistemChange: React.Dispatch<React.SetStateAction<UstanovaEksterniSistemReadDto | undefined>>;
  isDisabled: boolean;
  eksterniSistemOperation: string;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onCancel: () => void;
  eksterniSistemLoading: boolean;
  sifarnikEksterniSistemList: Array<SifarnikEksterniSistemReadDto>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  sifarnikEksternihSistemaListLoading: boolean;
}

interface UseParamsType {
  eksterniSistemId?: string;
  ustanovaId?: string;
}

export default function CrudEksterniSistemLogical(): CrudEksterniSistemLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const [eksterniSistemChange, setEksterniSistemChange] = useState<UstanovaEksterniSistemReadDto | undefined>();
  const [eksterniSistemOperation] = useState(location.state?.eksterniSistemOperation || EntityOperation.UPDATE);
  const [isDisabled] = useState(isFormDisabled(eksterniSistemOperation));
  const eksterniSistemId = Number(useParams<keyof UseParamsType>()["eksterniSistemId"]);
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const [eksterniSistemLoading, setEksterniSistemLoading] = useState(true);
  const [sifarnikEksterniSistemList, setSifarnikEksterniSistemList] = useState<Array<EnumBaseReadDto>>([]);
  const [ustanovaNaziv] = useState<string>(location.state && location.state.ustanovaNaziv !== undefined ? location.state.ustanovaNaziv : "");
  const { axiosSuperadminGetUstanovaEksterniSistem, axiosSuperadminCreateUstanovaEksterniSistem, axiosSuperadminUpdateUstanovaEksterniSistem, axiosSuperadminDeleteUstanovaEksterniSistem } =
    SuperadminController();
  const { axiosGetSifarnikEksterniSistemList } = SifarnikController();
  const postLogHighLevel = useLogHighLevel();
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.USTANOVA_LIST,
      command: () => {
        navigate(`/crud-ustanova`);
      },
    },
    {
      label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + ustanovaNaziv,
      command: () => {
        navigate(`/crud-ustanova/${ustanovaId}`, {
          state: {
            tabIndex: 10,
          },
        });
      },
    },
  ]);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const [sifarnikEksternihSistemaListLoading, setSifarnikEksternihSistemaLoading] = useState<boolean>(false);

  useEffectOnce(() => {
    fetchData();
    fetchSifarnikEksternihSistema();
  });

  const fetchData = (dmID?: number, operation?: string) => {
    const newEksterniSistemId = dmID || eksterniSistemId;
    const startTime = moment(new Date());
    if (newEksterniSistemId) {
      axiosSuperadminGetUstanovaEksterniSistem(ustanovaId, newEksterniSistemId)
        .then(({ data: { data } }: { data: { data: UstanovaEksterniSistemReadDto } }) => {
          setEksterniSistemChange(data);
          const operationTmp = operation ?? eksterniSistemOperation;
          setBreadCrumbItems([
            breadCrumbItems[0],
            breadCrumbItems[1],
            {
              label:
                operationTmp === EntityOperation.UPDATE
                  ? Labels.USTANOVA_EKSTERNI_SISTEM_TITLE_UPDATE + data.username
                  : operationTmp === EntityOperation.READ
                  ? Labels.USTANOVA_EKSTERNI_SISTEM_TITLE_DETAILS + data.username
                  : Labels.USTANOVA_EKSTERNI_SISTEM_TITLE_DELETE + data.username,
            },
          ]);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setEksterniSistemLoading, startTime);
        });
    } else {
      setEksterniSistemLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.USTANOVA_EKSTERNI_SISTEM_TITLE_CREATE,
        },
      ]);
    }
  };

  const fetchSifarnikEksternihSistema = () => {
    setSifarnikEksternihSistemaLoading(true);
    axiosGetSifarnikEksterniSistemList()
      .then((response: AxiosResponse) => {
        setSifarnikEksterniSistemList(response.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setSifarnikEksternihSistemaLoading(false);
      });
  };

  const validateInput = (eksterniSistem: UstanovaEksterniSistemReadDto | undefined) => {
    let isInvalid = false;

    if (validateStringEmpty(eksterniSistem?.username)) {
      setInvalidFields((prev) => ({ ...prev, username: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(eksterniSistem?.password)) {
      setInvalidFields((prev) => ({ ...prev, password: true }));
      isInvalid = true;
    }
    if (eksterniSistem === undefined || eksterniSistem.idRepositorySifarnikEksterniSistem === undefined) {
      setInvalidFields((prev) => ({ ...prev, eksterniSistem: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(eksterniSistemChange)) {
      return;
    }

    if (eksterniSistemChange) {
      let naziv = sifarnikEksterniSistemList.find((sifarnikEksterniSistem: EnumBaseReadDto) => {
        return Number(eksterniSistemChange.idRepositorySifarnikEksterniSistem) === Number(sifarnikEksterniSistem.id);
      })?.naziv;
      setShowBlockUI(true);
      axiosSuperadminCreateUstanovaEksterniSistem(ustanovaId, eksterniSistemChange)
        .then(({ data: { data } }: { data: { data: UstanovaEksterniSistemReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.USTANOVA_EKSTERNI_SISTEM_MESSAGE_CREATE_SUCCESS);
          onCancel();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
      postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_EKSTERNI_SISTEM_1 + naziv + Labels.LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_EKSTERNI_SISTEM_2 + eksterniSistemChange.username);
    }
  };

  const onUpdate = () => {
    if (!validateInput(eksterniSistemChange)) {
      return;
    }
    if (eksterniSistemChange) {
      let naziv = sifarnikEksterniSistemList.find((sifarnikEksterniSistem: EnumBaseReadDto) => {
        return Number(eksterniSistemChange.idRepositorySifarnikEksterniSistem) === Number(sifarnikEksterniSistem.id);
      })?.naziv;
      setShowBlockUI(true);
      axiosSuperadminUpdateUstanovaEksterniSistem(ustanovaId, eksterniSistemChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.USTANOVA_EKSTERNI_SISTEM_MESSAGE_UPDATE_SUCCESS);
          onCancel();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
      postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_EKSTERNI_SISTEM_1 + naziv + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_EKSTERNI_SISTEM_2 + eksterniSistemChange.username);
    }
  };
  const onDelete = () => {
    if (eksterniSistemChange) {
      let naziv = sifarnikEksterniSistemList.find((sifarnikEksterniSistem: EnumBaseReadDto) => {
        return Number(eksterniSistemChange.idRepositorySifarnikEksterniSistem) === Number(sifarnikEksterniSistem.id);
      })?.naziv;
      setShowBlockUI(true);
      axiosSuperadminDeleteUstanovaEksterniSistem(ustanovaId, eksterniSistemChange?.id)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.USTANOVA_EKSTERNI_SISTEM_TITLE_MESSAGE_DELETE_USTANOVA_EKSTERNI_SISTEM_SUCCESS);
          onCancel();
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_EKSTERNI_SISTEM_1 + naziv + Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_EKSTERNI_SISTEM_2 + eksterniSistemChange?.username);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      const breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/ustanova-list`, {
        state: { tabIndex: 1 },
      });
    }
  };

  return {
    breadCrumbItems,
    eksterniSistemChange,
    setEksterniSistemChange,
    isDisabled,
    eksterniSistemOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    eksterniSistemLoading,
    sifarnikEksterniSistemList,
    invalidFields,
    setInvalidFields,
    sifarnikEksternihSistemaListLoading,
  };
}
