import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import RadnikReadDto from "../../../model/radnik/RadnikReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import RadnikListLogical from "./RadnikListLogical";

export default function RadnikListPage() {
  const { radnikList, first, tableRows, changeRadnikSearchData, radnikSearchData, fetchData, searchRadnikByEnter, onPageRadnik, exportData, breadCrumbItems, radnikLoading } = RadnikListLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMini } = PageDropdownTemplate();

  const crudBodyTemplate = (rowRadnik: RadnikReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.RADNIK_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowRadnik && openRadnik(EntityOperation.UPDATE, rowRadnik.id)}
        />
        <Button
          type="button"
          tooltip={Labels.RADNIK_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowRadnik && openRadnik(EntityOperation.DELETE, rowRadnik.id)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const openRadnik = (entityOperation: string, id?: number) => {
    const idRadnik = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-radnik/${idRadnik}`, {
      state: { radnikOperation: entityOperation },
    });
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_RADNIK}
          tooltip={Labels.RADNIK_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openRadnik(EntityOperation.CREATE);
          }}
          disabled={!pristup}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText id="searchString" name="searchString" className="search-input" value={radnikSearchData?.searchString ?? ""} onChange={changeRadnikSearchData} onKeyPress={searchRadnikByEnter} />
          <label htmlFor="searchString">{Labels.SEARCH_RADNIK}</label>
          <Button tooltip={Labels.RADNIK_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={radnikLoading} data={exportData} name={Labels.RADNIK_LIST} dt={dt} />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.RADNIK_IME, filter: true, sortrable: true },
    { columnName: Labels.RADNIK_PREZIME, filter: true, sortrable: true },
    { columnName: Labels.RADNIK_USERNAME, filter: true, sortrable: true },
    { columnName: Labels.RADNIK_EMAIL, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {radnikLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            value={radnikList}
            paginator
            first={first}
            filterDisplay="row"
            paginatorTemplate={rowsPerPageDropdownTemplateMini as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageRadnik(e.rows, e.first);
            }}
            exportFilename={Labels.RADNIK_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"ime"} header={Labels.RADNIK_IME} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"prezime"} header={Labels.RADNIK_PREZIME} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"username"} header={Labels.RADNIK_USERNAME} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"email"} header={Labels.RADNIK_EMAIL} className="wrap-line-anywhere" sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
