import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import { DATE_FORMAT, tableDateFilterGreater, tableDateFilterLess, tooltipOptionsBottom, tooltipOptionsTop } from "../../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../../infrastructure/system/hooks/PageDropdownTemplate";
import UstanovaStorageLimitReadDto from "../../../../../model/ustanova/licenca/UstanovaStorageLimitReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../../app/skeleton/SkeletonTable";
import CalendarFilter from "../../../calendar-component/CalendarFilter";
import UstanovaStorageLimitListLogical from "./UstanovaStorageLimitListLogical";
import { useLabels } from "../../../../../Store";

interface UstanovaLicencaProps {
  ustanovaId: number | undefined;
  ustanovaNaziv: string | undefined;
}

export default function UstanovaStorageLimitListView(props: UstanovaLicencaProps) {
  const { ustanovaId, ustanovaNaziv } = props;
  const {
    UstanovaStorageLimitList,
    firstModul,
    tableModulRows,
    onPageModul,
    modulLoading,
    openUstanovaStorageLimit,
    ustanovaStorageLimitSearchData,
    fetchDataAdmin,
    changeUstanovaStorageLimitSearchData,
    searchUstanovaStorageLimitByEnter,
    storageLimitList,
  } = UstanovaStorageLimitListLogical({
    ustanovaId: ustanovaId,
    ustanovaNaziv: ustanovaNaziv,
  });

  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.HEADER_VAZI_OD, filter: true, sortrable: true },
    { columnName: Labels.HEADER_VAZI_DO, filter: true, sortrable: true },
  ];

  const vaziOdBody = (rowUstanovaStorageLimit: UstanovaStorageLimitReadDto) => {
    return <div>{moment(rowUstanovaStorageLimit.vaziOd).format(DATE_FORMAT)}</div>;
  };
  const vaziDoBody = (rowUstanovaStorageLimit: UstanovaStorageLimitReadDto) => {
    return <div>{rowUstanovaStorageLimit?.vaziDo ? moment(rowUstanovaStorageLimit?.vaziDo).format(DATE_FORMAT) : ""}</div>;
  };
  const dateFilterTemplate = (options: any) => <CalendarFilter options={options} />;

  const crudBodyTemplate = (rowUstanovaStorageLimit: UstanovaStorageLimitReadDto) => {
    let today = new Date(new Date().setHours(0, 0, 0, 0));
    let vaziDo = rowUstanovaStorageLimit.vaziDo ? new Date(new Date(rowUstanovaStorageLimit.vaziDo).setHours(0, 0, 0, 0)) : null;

    if (rowUstanovaStorageLimit.id === UstanovaStorageLimitList[0].id && (!vaziDo || vaziDo >= today)) {
      return (
        <div className="flex justify-content-end">
          <Button
            type="button"
            tooltip={Labels.STORAGE_LIMIT_TITLE_UPDATE}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-warning mr-2"
            icon="pi pi-pencil"
            onClick={() => rowUstanovaStorageLimit && openUstanovaStorageLimit(EntityOperation.UPDATE, rowUstanovaStorageLimit.id)}
          />
          <Button
            type="button"
            tooltip={Labels.STORAGE_LIMIT_TITLE_DELETE}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-danger mr-2"
            icon="pi pi-trash"
            onClick={() => rowUstanovaStorageLimit && openUstanovaStorageLimit(EntityOperation.DELETE, rowUstanovaStorageLimit.id)}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const addButton = () =>
    ustanovaId !== undefined ? (
      <Button
        label={Labels.STORAGE_LIMIT_BUTTON_DODAJ}
        tooltip={Labels.STORAGE_LIMIT_TITLE_CREATE}
        tooltipOptions={tooltipOptionsBottom}
        className="p-button-success mr-3"
        onClick={() => {
          openUstanovaStorageLimit(EntityOperation.CREATE);
        }}
      />
    ) : (
      <>
        <span className="p-float-label">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={ustanovaStorageLimitSearchData?.searchString ?? ""}
            onChange={changeUstanovaStorageLimitSearchData}
            onKeyPress={searchUstanovaStorageLimitByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_STORAGE_LIMIT}</label>
        </span>
        <Button tooltip={Labels.STORAGE_LIMIT_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchDataAdmin} />
      </>
    );

  const statusRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={storageLimitList}
        optionLabel="naziv"
        optionValue="naziv"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        className="w-full"
        showClear
      />
    );
  };

  return (
    <div className="layout-generic-content-list">
      <Toolbar left={addButton} />
      {modulLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={ustanovaId !== undefined} defaultButtonCount={2} />
      ) : (
        <DataTable
          filterDisplay="row"
          value={UstanovaStorageLimitList}
          paginator
          first={firstModul}
          paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
          rows={tableModulRows}
          onPage={(e) => {
            onPageModul(e.rows, e.first);
          }}
          exportFilename={Labels.FORMULAR_LIST}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          rowHover
          stripedRows
        >
          <Column field={"storageLimit.naziv"} header={Labels.HEADER_NAZIV} className="w-3" filter showClearButton={false} filterElement={statusRowFilterTemplate} showFilterMenu={false} />
          <Column
            header={Labels.VAZI_OD}
            field={"vaziOd"}
            body={vaziOdBody}
            filter
            filterElement={dateFilterTemplate}
            filterMatchMode="custom"
            filterFunction={tableDateFilterLess}
            sortable
            showFilterMenu={false}
          />
          <Column
            header={Labels.VAZI_DO}
            field={"vaziDo"}
            body={vaziDoBody}
            filter
            filterElement={dateFilterTemplate}
            filterMatchMode="custom"
            filterFunction={tableDateFilterGreater}
            sortable
            showFilterMenu={false}
          />
          {ustanovaId !== undefined && <Column body={crudBodyTemplate} />}
        </DataTable>
      )}
    </div>
  );
}
