import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { Navigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import { setInvalid, tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import PacijentPoljeKategorijaReadDto from "../../../model/pacijent-polje/PacijentPoljeKategorijaReadDto";
import PoljeTipReadDto from "../../../model/poljeTip/PoljeTipReadDto";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CrudPacijentPoljeLogical from "./CrudPacijentPoljeLogical";

export default function CrudPacijentPolje() {
  const {
    pacijentPoljeOperation,
    isDisabled,
    pacijentPoljeChange,
    breadCrumbItems,
    setPacijentPoljeChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    poljeTipList,
    vrstaGrafikonaRastaList,
    pacijentPoljeId,
    location,
    openPacijentPoljeKategorija,
    first,
    tableRows,
    onPageFormTable,
    pacijentPoljeKategorijaList,
    pacijentPoljeLoading,
    poljeTipLoading,
    vrstaGrafikonaRastaLoading,
    invalidFields,
    setInvalidFields,
  } = CrudPacijentPoljeLogical();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const dt = useRef<any>(null);
  const { rowsPerPageDropdownTemplateMini } = PageDropdownTemplate();

  if (!pacijentPoljeId && !location.state) {
    return <Navigate to="/pacijent-polje-list" />;
  }

  const leftContentsToolbar = () => (
    <>
      {pacijentPoljeOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.PACIJENT_POLJE_TITLE_DIALOG_CREATE}
          disabled={!pristup || poljeTipLoading}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {pacijentPoljeOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          icon="pi pi-pencil"
          tooltip={Labels.PACIJENT_POLJE_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          disabled={!pristup || pacijentPoljeLoading || poljeTipLoading}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {pacijentPoljeOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          icon="pi pi-trash"
          tooltip={Labels.PACIJENT_POLJE_TITLE_DIALOG_DELETE}
          disabled={!pristup || pacijentPoljeLoading || poljeTipLoading}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  const leftContents = () => (
    <>
      <Button
        className="p-button-success mr-3"
        label={Labels.BUTTON_ADD_PACIJENT_POLJE_KATEGORIJA}
        tooltip={Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_CREATE}
        tooltipOptions={tooltipOptionsBottom}
        disabled={!pristup || pacijentPoljeOperation !== EntityOperation.UPDATE}
        onClick={() => {
          openPacijentPoljeKategorija(EntityOperation.CREATE);
        }}
      />
    </>
  );

  const buttonListTemplate = (rowPacijentPoljeKategorija: PacijentPoljeKategorijaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          disabled={pacijentPoljeOperation !== EntityOperation.UPDATE}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowPacijentPoljeKategorija && openPacijentPoljeKategorija(EntityOperation.UPDATE, rowPacijentPoljeKategorija)}
        />
        <Button
          type="button"
          tooltip={Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          disabled={!pristup || pacijentPoljeOperation !== EntityOperation.UPDATE}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowPacijentPoljeKategorija && openPacijentPoljeKategorija(EntityOperation.DELETE, rowPacijentPoljeKategorija)}
        />
      </div>
    );
  };

  const obaveznoBodyTemplate = (column: PacijentPoljeKategorijaReadDto) => {
    if (column.obavezno) {
      return Labels.LABEL_COLUMN_TRUE;
    } else {
      return Labels.LABEL_COLUMN_FALSE;
    }
  };

  const readOnlyBodyTemplate = (column: PacijentPoljeKategorijaReadDto) => {
    if (column.readOnly) {
      return Labels.LABEL_COLUMN_TRUE;
    } else {
      return Labels.LABEL_COLUMN_FALSE;
    }
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_CATEGORY, filter: true, sortrable: true },
    { columnName: Labels.HEADER_READ_ONLY, filter: true, sortrable: true },
    { columnName: Labels.HEADER_REQUIRED, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_PACIJENT_POLJE}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.PACIJENT_POLJE_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!pacijentPoljeLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={pacijentPoljeChange?.naziv ?? ""}
                        onChange={(e) => {
                          setPacijentPoljeChange({
                            ...pacijentPoljeChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", pacijentPoljeChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.PACIJENT_POLJE_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!pacijentPoljeLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={pacijentPoljeChange?.sifra ?? ""}
                        onChange={(e) => {
                          setPacijentPoljeChange({
                            ...pacijentPoljeChange!,
                            sifra: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", pacijentPoljeChange?.sifra);
                        }}
                        className={invalidFields?.sifra && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.PACIJENT_POLJE_TIP}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!pacijentPoljeLoading && !poljeTipLoading ? (
                    <>
                      <Dropdown
                        disabled={!pristup || isDisabled}
                        resetFilterOnHide
                        options={poljeTipList}
                        value={pacijentPoljeChange?.poljeTip?.sifra ?? ""}
                        optionLabel="naziv"
                        optionValue="sifra"
                        filter
                        onChange={(e) => {
                          let tip = poljeTipList!.find((x: PoljeTipReadDto) => x.sifra === e.target.value)?.tipKomponenteEnum?.sifra;
                          setPacijentPoljeChange({
                            ...pacijentPoljeChange!,
                            poljeTip: {
                              id: poljeTipList!.find((x: PoljeTipReadDto) => x.sifra === e.target.value)?.id!,
                              sifra: poljeTipList!.find((x: PoljeTipReadDto) => x.sifra === e.target.value)?.sifra!,
                              tipKomponenteEnum: { sifra: poljeTipList!.find((x: PoljeTipReadDto) => x.sifra === e.target.value)?.tipKomponenteEnum?.sifra },
                            },
                            vrstaGrafikonaRasta:
                              tip !== EnumTipKomponente.NUMBER && tip !== EnumTipKomponente.NUMBER_DECIMAL && tip !== EnumTipKomponente.NUMBER_INTEGER
                                ? null
                                : pacijentPoljeChange?.vrstaGrafikonaRasta,
                          });
                          if (e.target.value) {
                            setInvalidFields((prev) => ({ ...prev, poljeTip: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, poljeTip: true }));
                          }
                        }}
                        className={invalidFields?.poljeTip && "p-invalid"}
                        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      />
                      {invalidFields?.poljeTip && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                {(pacijentPoljeChange?.poljeTip?.tipKomponenteEnum?.sifra === EnumTipKomponente.NUMBER ||
                  pacijentPoljeChange?.poljeTip?.tipKomponenteEnum?.sifra === EnumTipKomponente.NUMBER_INTEGER ||
                  pacijentPoljeChange?.poljeTip?.tipKomponenteEnum?.sifra === EnumTipKomponente.NUMBER_DECIMAL) && (
                  <>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>{Labels.PACIJENT_POLJE_VRSTA_GRAFIKONA}</div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      {!pacijentPoljeLoading && !vrstaGrafikonaRastaLoading ? (
                        <>
                          <Dropdown
                            disabled={!pristup || isDisabled}
                            resetFilterOnHide
                            options={vrstaGrafikonaRastaList}
                            value={pacijentPoljeChange?.vrstaGrafikonaRasta?.sifra ?? ""}
                            optionLabel="naziv"
                            optionValue="sifra"
                            filter
                            showClear
                            onChange={(e) => {
                              setPacijentPoljeChange({
                                ...pacijentPoljeChange!,
                                vrstaGrafikonaRasta: e.value ? { sifra: e.value } : undefined,
                              });
                            }}
                            emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                            emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                          />
                        </>
                      ) : (
                        <SkeletonInputItem />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
          </TabPanel>
          <TabPanel header={Labels.PACIJENT_POLJE_KATEGORIJA_LIST} disabled={pacijentPoljeOperation === EntityOperation.CREATE}>
            <div className="layout-generic-content-list">
              {!isDisabled && <Toolbar left={leftContents} />}
              {pacijentPoljeLoading ? (
                <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={!isDisabled} />
              ) : (
                <DataTable
                  ref={dt}
                  filterDisplay="row"
                  value={pacijentPoljeKategorijaList}
                  paginator
                  first={first}
                  paginatorTemplate={rowsPerPageDropdownTemplateMini as PaginatorTemplateOptions}
                  rows={tableRows}
                  onPage={(e) => {
                    onPageFormTable(e.rows, e.first);
                  }}
                  emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                  exportFilename={Labels.POLJE_LIST}
                  rowHover
                  stripedRows
                >
                  <Column field={"kategorija.naziv"} header={Labels.HEADER_CATEGORY} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                  <Column field={"readOnly"} header={Labels.HEADER_READ_ONLY} filter filterMatchMode="contains" showFilterMenu={false} sortable body={readOnlyBodyTemplate} />
                  <Column field={"obavezno"} header={Labels.HEADER_REQUIRED} filter filterMatchMode="contains" showFilterMenu={false} sortable body={obaveznoBodyTemplate} />
                  {!isDisabled && <Column body={buttonListTemplate} />}
                </DataTable>
              )}
            </div>
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
