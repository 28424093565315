import { useContext } from "react";
import { AppContext } from "../../Store";
import { RadnikUlogaHolder } from "../../components/administracija/radnik/uloga/CrudRadnikUlogaLogical";
import EnumRola from "../../infrastructure/system/EnumRola";
import { isSifraIncluded } from "../../infrastructure/system/Utils";

interface RolaServiceType {
  isSuperadmin: boolean;
  isAdmin: boolean;
  isLekar: boolean;
  isSestra: boolean;
}

export function RolaService(): RolaServiceType {
  const { authData } = useContext(AppContext);
  const { radnikUlogaList } = authData!.currentRadnik;

  const makeRolaList = () => radnikUlogaList.map((radnikUloga: RadnikUlogaHolder) => radnikUloga?.uloga?.rola);

  const isSuperadmin = isSifraIncluded(makeRolaList(), EnumRola.SUPERADMIN);
  const isAdmin = isSifraIncluded(makeRolaList(), EnumRola.ADMIN);
  const isLekar = isSifraIncluded(makeRolaList(), EnumRola.LEKAR);
  const isSestra = isSifraIncluded(makeRolaList(), EnumRola.SESTRA);

  return { isSuperadmin, isAdmin, isLekar, isSestra };
}
