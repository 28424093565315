import { useQuery } from "@tanstack/react-query";
import { useLabels } from "../../../../Store";
import { SifarnikKategorijaPopustaController } from "../../../../controllers/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";
import SifarnikKategorijaPopustaReadDto from "../../../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaReadDto";

export function useSifarnikKategorijaPopustaList() {
  const Labels = useLabels();
  const { axiosGetSifarnikKategorijaPopustaList } = SifarnikKategorijaPopustaController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.SIFARNIK_KATEGORIJA_POPUSTA],
    queryFn: () => axiosGetSifarnikKategorijaPopustaList(),
  });

  const sifarnikKategorijaPopustaList: Array<SifarnikKategorijaPopustaReadDto> = data?.data?.data;

  let exportList = new Array<any>();
  sifarnikKategorijaPopustaList?.forEach((sifarnikKategorijaPopusta: SifarnikKategorijaPopustaReadDto) => {
    exportList.push({
      [Labels.SIFARNIK_KATEGORIJA_POPUSTA_NAZIV]: sifarnikKategorijaPopusta.naziv,
      [Labels.SIFARNIK_KATEGORIJA_POPUSTA_SIFRA]: sifarnikKategorijaPopusta.sifra,
      [Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_TIP_KATEGORIJE]: sifarnikKategorijaPopusta.tipKategorijePopusta.naziv,
      [Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_NAMENA_KATEGORIJE]: sifarnikKategorijaPopusta.namenaKategorijePopusta.naziv,
      [Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_POPUST]: sifarnikKategorijaPopusta.popust,
      [Labels.SIFARNIK_KATEGORIJA_POPUSTA_PRIKAZNI_REDOSLED]: sifarnikKategorijaPopusta.prikazniRedosled,
    });
  });

  return { sifarnikKategorijaPopustaList, refetch, isLoading, exportList };
}
