import moment from "moment";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import React, { useRef } from "react";

import { useLabels } from "../../../Store";
import { DATE_TIME_FORMAT_2, isDateTimeFormatValid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import LogHighLevelReadDto from "../../../model/log/LogHighLevelReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import CalendarComponent from "../calendar-component/CalendarComponent";
import LogHighLevelListLogical from "./LogHighLevelListLogical";

export default function LogHighLevelListPage() {
  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const {
    showResults,
    setShowResults,
    first,
    tableRows,
    onPageFormTable,
    logHighLevelList,
    searchLogHighLevel,
    searchLogHighLevelLoading,
    searchLogHighLevelObject,
    setSearchLogHighLevelObject,
    onChangeCalendar,
    searchLogPageReady,
    setFirst,
    pacijentOdabran,
    pacijentSuggestionList,
    pretragaPacijent,
    odabirPacijenta,
    radnikOdabran,
    radnikSuggestionList,
    pretragaRadnik,
    odabirRadnika,
    logHighLevelCount,
    countLogHighLevel,
    vremeOdRef,
    vremeDoRef,
  } = LogHighLevelListLogical();

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.LOG_HIGH_LEVEL_RADNIK, bodyClassName: "p-2", headerClassName: "p-2" },
    { columnName: Labels.LOG_HIGH_LEVEL_PACIJENT, bodyClassName: "p-2", headerClassName: "p-2" },
    { columnName: Labels.LOG_HIGH_LEVEL_VREME_AKCIJE, bodyClassName: "p-2", headerClassName: "p-2" },
    { columnName: Labels.LOG_HIGH_LEVEL_OPIS, bodyClassName: "p-2", headerClassName: "p-2" },
  ];

  const dt = useRef<any>(null);

  const bodyRadnik = (logHL: LogHighLevelReadDto) => {
    return (
      <React.Fragment>
        <Tooltip target=".imeIPrezime" mouseTrack mouseTrackLeft={10} content={"Radnik ID: " + logHL.radnikId.toString()} />
        <div className="imeIPrezime">
          {logHL.imeRadnik} {logHL.prezimeRadnik}
        </div>
      </React.Fragment>
    );
  };

  const bodyPacijent = (logHL: LogHighLevelReadDto) => {
    return (
      <>
        {logHL.imePacijenta} {logHL.prezimePacijenta}
      </>
    );
  };

  const bodyVremeAkcije = (log: LogHighLevelReadDto) => {
    return <> {moment(log?.vremeAkcije).format(DATE_TIME_FORMAT_2)}</>;
  };

  return (
    <div className="layout-pretraga-pacijenata">
      <Panel>
        <Panel>
          <div className="flex flex-wrap md:col-12 ">
            <div className="col-12 xl:col-3 lg:col-3 md:col-3 sm:col-12"></div>
            <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
              <div className="flex flex-column">
                <div className="flex flex-wrap">
                  <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
                    <CalendarComponent
                      ref={vremeOdRef}
                      name="vremeAkcijeOd"
                      showTime={true}
                      value={searchLogHighLevelObject?.vremeAkcijeOd}
                      onChange={onChangeCalendar}
                      onInput={(e) => {
                        if (isDateTimeFormatValid(e.currentTarget.value)) vremeOdRef?.current?.hide();
                      }}
                      placeholder={Labels.PLACEHOLDER_DATUM_VREME_FORMAT}
                    />
                  </div>
                  <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
                    <CalendarComponent
                      ref={vremeDoRef}
                      name="vremeAkcijeDo"
                      showTime={true}
                      value={searchLogHighLevelObject?.vremeAkcijeDo}
                      onChange={onChangeCalendar}
                      onInput={(e) => {
                        if (isDateTimeFormatValid(e.currentTarget.value)) vremeDoRef?.current?.hide();
                      }}
                      placeholder={Labels.PLACEHOLDER_DATUM_VREME_FORMAT}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="col-6 xl:col-6 lg:col-6 md:col-6 sm:col-12 inputfield ">
                    <div className="p-inputgroup ">
                      <span className="p-inputgroup-addon bg-white">
                        <i className="pi pi-search" />
                      </span>
                      <AutoComplete
                        field="ispis"
                        value={radnikOdabran}
                        suggestions={radnikSuggestionList}
                        placeholder={Labels.LISTA_RADNIKA_PRETRAGA_PLACEHOLDER}
                        completeMethod={pretragaRadnik}
                        onChange={odabirRadnika}
                      />
                    </div>
                  </div>

                  <div className="col-6 xl:col-6 lg:col-6 md:col-6 sm:col-12 inputfield ">
                    <div className="p-inputgroup ">
                      <span className="p-inputgroup-addon bg-white">
                        <i className="pi pi-search" />
                      </span>
                      <AutoComplete
                        field="ispis"
                        value={pacijentOdabran}
                        suggestions={pacijentSuggestionList}
                        placeholder={Labels.PRETRAGA_PACIJENTA}
                        completeMethod={pretragaPacijent}
                        onChange={odabirPacijenta}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap mx-3 align-items-center">
                  <InputText
                    className="col-12 inputfield h-full"
                    value={searchLogHighLevelObject?.opis ?? ""}
                    onChange={(e) => {
                      setSearchLogHighLevelObject({ ...searchLogHighLevelObject, opis: e.target.value });
                    }}
                    placeholder={Labels.LOG_HIGH_LEVEL_OPIS}
                  />
                </div>
                <div className="flex flex-wrap mx-3">
                  <div className="col-12 px-0">
                    {searchLogPageReady ? (
                      <Skeleton shape="rectangle" width="4.5rem" height="2.5rem" />
                    ) : (
                      <Button
                        label={Labels.LABEL_PATIENT_SEARCH}
                        tooltip={Labels.LABEL_TOOLTIP_LOG_SEARCH}
                        tooltipOptions={tooltipOptionsBottom}
                        className="p-button-success"
                        onClick={() => {
                          searchLogHighLevelObject.page = 0;
                          searchLogHighLevelObject.size = tableRows;
                          searchLogHighLevel(searchLogHighLevelObject);
                          countLogHighLevel(searchLogHighLevelObject);
                          setFirst(0);
                          setShowResults(true);
                        }}
                      />
                    )}
                    {showResults && (
                      <Button
                        icon="pi pi-times"
                        tooltip={Labels.LABEL_TOOLTIP_PATIENT_SEARCH_ERASE}
                        tooltipOptions={tooltipOptionsBottom}
                        className="p-button-outlined ml-2"
                        onClick={() => {
                          setShowResults(false);
                          setSearchLogHighLevelObject({});
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Panel>
        {showResults && (
          <Panel className="mt-2">
            <div className="card">
              {searchLogHighLevelLoading ? (
                <SkeletonTable dataTableColumnsProperty={columnsProperty} />
              ) : (
                <DataTable
                  ref={dt}
                  value={logHighLevelList}
                  paginator
                  first={first}
                  rows={tableRows}
                  lazy
                  totalRecords={logHighLevelCount}
                  onPage={(e) => {
                    onPageFormTable(e.rows, e.first);
                    searchLogHighLevelObject.page = e.page;
                    searchLogHighLevelObject.size = e.rows;
                    searchLogHighLevel(searchLogHighLevelObject);
                  }}
                  paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
                  emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                  rowHover
                  stripedRows
                >
                  <Column field={"imeRadnik"} header={Labels.LOG_HIGH_LEVEL_RADNIK} body={bodyRadnik} />
                  <Column field={"imePacijenta"} header={Labels.LOG_HIGH_LEVEL_PACIJENT} body={bodyPacijent} />
                  <Column field={"vremeAkcije"} header={Labels.LOG_HIGH_LEVEL_VREME_AKCIJE} body={bodyVremeAkcije} />
                  <Column field={"opis"} header={Labels.LOG_HIGH_LEVEL_OPIS} />
                </DataTable>
              )}
            </div>
          </Panel>
        )}
      </Panel>
    </div>
  );
}
