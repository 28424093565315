const EnumIzvestajParametarTip = {
  TEXT: "TEXT",
  BOOLEAN: "BOOLEAN",
  NUMBER: "NUMBER",
  SET: "SET",
  DATE: "DATE",
  MULTISELECT: "MULTISELECT",
};

export default EnumIzvestajParametarTip;
