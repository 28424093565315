import { Button } from "primereact/button";
import { Dialog, DialogProps } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useLabels } from "../../../../Store";
import RadnikReadDto from "../../../../model/radnik/RadnikReadDto";
import CrudRadnikLogical from "../CrudRadnikLogical";

interface RadnikSelectionDialogProps {
  header: React.ReactNode | ((props: DialogProps) => React.ReactNode);
  visible: boolean;
  onCloseRadnikSelectionDialog: () => void;
  alreadyCreatedRadnik: RadnikReadDto | undefined;
  radnikChange: RadnikReadDto | undefined;
}

export default function RadnikSelectionDialog(props: RadnikSelectionDialogProps) {
  const { header, visible, onCloseRadnikSelectionDialog, alreadyCreatedRadnik, radnikChange } = props;
  const Labels = useLabels();
  const { getPol, getStatus, createRadnikUstanova, updateRadnikAndCreateRadnikUstanova } = CrudRadnikLogical();

  return (
    <div>
      <Dialog header={header} visible={visible} onHide={onCloseRadnikSelectionDialog} className="w-7">
        <div className="flex">
          <div className="col-12">
            <div className="grid align-items-center p-0">
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.ime ?? ""} />
              </div>
              <div className="col-2">
                <div className="text-center">
                  {Labels.RADNIK_IME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={radnikChange?.ime ?? ""} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.prezime ?? ""} />
              </div>
              <div className="col-2">
                <div className="text-center">
                  {Labels.RADNIK_PREZIME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={radnikChange?.prezime ?? ""} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.username ?? ""} />
              </div>
              <div className="col-2">
                <div className="text-center">
                  {Labels.RADNIK_USERNAME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.username ?? ""} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.email ?? ""} />
              </div>
              <div className="col-2">
                <div className="text-center">
                  {Labels.RADNIK_EMAIL}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.email ?? ""} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.titula ?? ""} />
              </div>
              <div className="col-2">
                <div className="text-center">{Labels.RADNIK_TITULA}</div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={radnikChange?.titula ?? ""} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.zanimanje ?? ""} />
              </div>
              <div className="col-2">
                <div className="text-center">{Labels.RADNIK_ZANIMANJE}</div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={radnikChange?.zanimanje ?? ""} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.jmbg ?? ""} />
              </div>
              <div className="col-2">
                <div className="text-center">{Labels.JMBG}</div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={radnikChange?.jmbg ?? ""} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.lbo ?? ""} />
              </div>
              <div className="col-2">
                <div className="text-center">{Labels.HEADER_LBO}</div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={radnikChange?.lbo ?? ""} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.telefon ?? ""} />
              </div>
              <div className="col-2">
                <div className="text-center">{Labels.HEADER_PHONE}</div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={radnikChange?.telefon ?? ""} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={alreadyCreatedRadnik?.idBrojLekara ?? ""} />
              </div>
              <div className="col-2">
                <div className="text-center">{Labels.RADNIK_BROJ_PECATA}</div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={radnikChange?.idBrojLekara ?? ""} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={getStatus(alreadyCreatedRadnik)} />
              </div>
              <div className="col-2">
                <div className="text-center">
                  {Labels.RADNIK_STATUS}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={getStatus(radnikChange)} />
              </div>

              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={getPol(alreadyCreatedRadnik)} />
              </div>
              <div className="col-2">
                <div className="text-center">
                  {Labels.RADNIK_POL}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-5 p-fluid p-0">
                <InputText disabled={true} value={getPol(radnikChange)} />
              </div>

              <div className="col-5 p-fluid p-0 text-center">
                <Button
                  type="button"
                  icon="pi pi-users"
                  label={Labels.RADNIK_PROFIL_ODABERI_PODATKE}
                  onClick={() => createRadnikUstanova(alreadyCreatedRadnik?.id)}
                  className="p-button-success w-6 m-1"
                />
              </div>
              <div className="col-2 text-center">
                <Button type="button" icon="pi pi-times" label={Labels.RADNIK_PROFIL_ODUSTANI_OD_ODABIRA} onClick={() => onCloseRadnikSelectionDialog()} className="p-button-danger" />
              </div>
              <div className="col-5 p-fluid p-0 text-center">
                <Button
                  type="button"
                  icon="pi pi-users"
                  label={Labels.RADNIK_PROFIL_ODABERI_PODATKE}
                  onClick={() => updateRadnikAndCreateRadnikUstanova(alreadyCreatedRadnik?.id, radnikChange)}
                  className="p-button-success w-6 m-1"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
