import { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import { EnumController } from "../../../controllers/enum/EnumController";
import { SifarnikNacinaPlacanjaController } from "../../../controllers/sifarnik-nacina-placanja/SifarnikNacinaPlacanjaController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SifarnikNacinaPlacanjaReadDto from "../../../model/sifarnik/sifarnik-nacina-placanja/SifarnikNacinPlacanjaReadDto";

interface SifarnikNacinaPlacanjaListLogicalType {
  exportData: Array<any>;
  dt: any;
  sifarnikNacinaPlacanjaList: Array<SifarnikNacinaPlacanjaReadDto>;
  first: number;
  tableRows: number;
  onPage: (rows: number, first: number) => void;
  openSifarnikNacinaPlacanja: (entityOperation: string, id?: number) => void;
  sifarnikNacinaPlacanjaLoading: boolean;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  vrstaUplatiocaList: EnumBaseReadDto[];
}

export default function SifarnikNacinaPlacanjaListLogical(): SifarnikNacinaPlacanjaListLogicalType {
  const { showMessage } = useContext(AppContext);
  const [sifarnikNacinaPlacanjaList, setSifarnikNacinaPlacanjaList] = useState<Array<SifarnikNacinaPlacanjaReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const dt = useRef<any>(null);
  const { axiosGetSifarnikNacinaPlacanjaList } = SifarnikNacinaPlacanjaController();
  const [sifarnikNacinaPlacanjaLoading, setSifarnikNacinaPlacanjaLoading] = useState(false);
  const [vrstaUplatiocaList, setVrstaUplatiocaList] = useState<EnumBaseReadDto[]>([]);
  const { axiosGetEnumVrstaUplatiocaList } = EnumController();
  const Labels = useLabels();
  const navigate = useNavigate();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LABEL_SIFARNIK_NACINI_PLACANJA,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setSifarnikNacinaPlacanjaLoading(true);
    axiosGetSifarnikNacinaPlacanjaList()
      .then(({ data: { data } }: { data: { data: Array<SifarnikNacinaPlacanjaReadDto> } }) => {
        setSifarnikNacinaPlacanjaList(data);
        let exportList = new Array<any>();
        data.forEach((sifarnikNacinaPlacanja: SifarnikNacinaPlacanjaReadDto) => {
          exportList.push({
            [Labels.SIFARNIK_NACINA_PLACANJA_NAZIV]: sifarnikNacinaPlacanja.naziv,
            [Labels.SIFARNIK_NACINA_PLACANJA_SIFRA]: sifarnikNacinaPlacanja.sifra,
            [Labels.SIFARNIK_NACINA_PLACANJA_PRIKAZNI_REDOSLED]: sifarnikNacinaPlacanja.prikazniRedosled,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSifarnikNacinaPlacanjaLoading, startTime);
      });

    axiosGetEnumVrstaUplatiocaList()
      .then((response: AxiosResponse) => {
        setVrstaUplatiocaList(response.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onPage = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const openSifarnikNacinaPlacanja = (entityOperation: string, id?: number) => {
    const sifarnikNacinaPlacanjaId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-sifarnik-nacina-placanja/${sifarnikNacinaPlacanjaId}`, {
      state: { sifarnikNacinaPlacanjaOperation: entityOperation, sifarnikNacinaPlacanjaList: sifarnikNacinaPlacanjaList },
    });
  };

  return {
    exportData,
    dt,
    sifarnikNacinaPlacanjaList,
    first,
    tableRows,
    onPage,
    openSifarnikNacinaPlacanja,
    sifarnikNacinaPlacanjaLoading,
    breadCrumbItems,
    vrstaUplatiocaList,
  };
}
