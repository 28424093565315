import moment from "moment/moment";
import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useMemo, useRef } from "react";
import { useLabels } from "../../../../../Store";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import { checkEmpty, formatDate, removeAllMasks, setInvalid } from "../../../../../infrastructure/system/Utils";
import actionsPartnerCena from "../../../../../infrastructure/system/hooks/dialog-partner-cena-reducer/actionsPartnerCena";
import { InitialStatePartnerCenaType } from "../../../../../infrastructure/system/hooks/dialog-partner-cena-reducer/initialStatePartnerCena";
import actionsSifarnikCena from "../../../../../infrastructure/system/hooks/dialog-sifarnik-cena-reducer/actionsSifarnikCena";
import SifarnikPoreskaStopaDto from "../../../../../model/repository/sifarnik-usluga/SifarnikPoreskaStopaDto";
import SifarnikValutaDto from "../../../../../model/repository/sifarnik-usluga/SifarnikValutaDto";
import SifarnikUslugaDto from "../../../../../model/sifarnik/SifarnikUslugaDto";
import SifarnikKategorijaPopustaReadDto from "../../../../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaReadDto";
import DialogComponent from "../../../../dialog-component/DialogComponent";
import ObaveznoPolje from "../../../../obavezno-polje/ObaveznoPolje";
import CalendarComponent from "../../../calendar-component/CalendarComponent";

interface DialogPartnerCenaType {
  valuteList: Array<SifarnikValutaDto>;
  poreskeStopeList: Array<SifarnikPoreskaStopaDto>;
  state: InitialStatePartnerCenaType;
  dispatch: React.Dispatch<{ type: actionsPartnerCena }> | any;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  searchSifarnikUsluga: (e: AutoCompleteCompleteEvent) => void;
  sifarnikUslugaList: Array<SifarnikUslugaDto>;
  popustiList: Array<SifarnikKategorijaPopustaReadDto>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
}

export default function DialogPartnerCena(props: DialogPartnerCenaType) {
  const { valuteList, poreskeStopeList, state, dispatch, onCreate, onDelete, onUpdate, searchSifarnikUsluga, sifarnikUslugaList, popustiList, invalidFields, setInvalidFields } = props;
  const Labels = useLabels();
  const vaziOdRef = useRef<HTMLDivElement | any>(null);
  const vaziDoRef = useRef<HTMLDivElement | any>(null);

  const renderHeader = () => {
    let title;
    if (state.operation === EntityOperation.CREATE) {
      title = Labels.LABEL_ADD_CENA_PARTNER_DIALOG;
    } else if (state.operation === EntityOperation.UPDATE) {
      title = Labels.LABEL_UPDATE_CENA_PARTNER_DIALOG;
    } else {
      title = Labels.LABEL_DELETE_CENA_PARTNER_DIALOG;
    }
    return <div className="flex justify-content-start">{title}</div>;
  };

  const renderFooter = () => {
    let classTemp, icon, label, action;

    if (state.operation === EntityOperation.CREATE) {
      label = Labels.LABEL_DODAJ_PARTNER;
      icon = "pi pi-plus";
      classTemp = "mr-2 m-1";
      action = onCreate;
    } else if (state.operation === EntityOperation.UPDATE) {
      label = Labels.BUTTON_UPDATE;
      icon = "pi pi-check";
      classTemp = "mr-2 m-1";
      action = onUpdate;
    } else {
      label = Labels.BUTTON_DELETE;
      classTemp = "p-button-danger mr-2 m-1";
      icon = "pi pi-trash";
      action = onDelete;
    }

    return (
      <div className="flex justify-content-end">
        <Button
          label={Labels.BUTTON_CANCEL}
          icon="pi pi-times"
          className="button-danger m-1 p-button-outlined"
          onClick={() => {
            dispatch({ type: actionsPartnerCena.CLOSE_DIALOG });
            [].forEach.call(document.getElementsByClassName("p-datepicker-mask"), function (element: Element) {
              element.remove();
            });
            setInvalidFields(undefined);
          }}
        />
        <Button label={label} tooltip={label} className={classTemp} icon={icon} onClick={action} />
      </div>
    );
  };

  const isTodayOrInFuture = () => {
    return moment(state?.cena?.vaziOd).isSame(new Date(), "days") || moment(state?.cena?.vaziOd).isAfter(new Date(), "days");
  };

  const autocompleteStyles = useMemo(() => {
    return { paddingLeft: "2rem", width: "100%" };
  }, []);

  return (
    <DialogComponent
      header={renderHeader}
      visible={state.isDialogOpen}
      footer={renderFooter}
      onHide={() => {
        dispatch({ type: actionsSifarnikCena.CLOSE_DIALOG });
        removeAllMasks();
        setInvalidFields(undefined);
      }}
      blockScroll={true}
      className="w-11 sm:w-8 md:w-8 lg:w-4"
      contentClassName="overflow-y-visible"
      content={
        <div className="col-8 xl:col-12 lg:col-8 md:col-6 sm:col-12">
          <div className="grid align-items-center p-0">
            <div className="col-4">
              {Labels.PAKET_USLUGA_LABEL_USLUGA}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
            <div className="col-8 p-fluid p-0">
              <span className="p-input-icon-left w-full">
                <AutoComplete
                  value={state?.cena?.sifarnikUslugaFull ?? state?.cena?.usluga}
                  itemTemplate={(e) => `${e.sifra} ${e.naziv}` + (e?.cena ? ` (${e.cena} ${e?.valuta?.sifra})` : "")}
                  selectedItemTemplate={(e) => `${e.sifra} ${e.naziv}` + (e?.cena ? ` (${e.cena} ${e?.valuta?.sifra})` : "")}
                  suggestions={sifarnikUslugaList}
                  completeMethod={searchSifarnikUsluga}
                  inputStyle={autocompleteStyles}
                  field="sifarnikUsluga"
                  onChange={(e) => {
                    dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "sifarnikUslugaFull", value: e.value } });
                    dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "usluga", value: { id: e.value.id } } });
                  }}
                  placeholder={
                    state.operation === EntityOperation.DELETE || !isTodayOrInFuture()
                      ? `${state?.cena?.sifarnikUslugaFull?.sifra} ${state?.cena?.sifarnikUslugaFull?.naziv}`
                      : `${Labels.PAKET_USLUGA_UNOS_USLUGE_PLACEHOLDER}`
                  }
                  disabled={state.operation === EntityOperation.DELETE || state.operation === EntityOperation.UPDATE}
                  onBlur={() => {
                    setInvalid(setInvalidFields, "usluga", state.cena?.usluga);
                  }}
                  className={`w-full block ${invalidFields?.usluga ? "p-invalid" : ""}`}
                />
                <i className="pi pi-search p-icon-left" />
              </span>
              {invalidFields?.usluga && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
            <div className="col-4">
              <div>
                {Labels.VAZI_OD}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-8 p-fluid p-0">
              <CalendarComponent
                ref={vaziOdRef}
                value={state?.cena?.vaziOd}
                onChange={(e: CalendarChangeEvent) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "vaziOd", value: formatDate(e.value as Date | undefined) } });
                  setInvalidFields((prev) => ({ ...prev, vaziOd: false }));
                  if (e.target.value) vaziOdRef?.current?.hide();
                }}
                onClearButtonClick={() => {
                  setInvalidFields((prev) => ({ ...prev, vaziOd: true }));
                }}
                onHide={removeAllMasks}
                disabled={state.operation === EntityOperation.DELETE || !isTodayOrInFuture()}
                minDate={new Date()}
                className={`w-full ${invalidFields?.vaziOd ? "p-invalid" : ""}`}
                placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
              />
              {invalidFields?.vaziOd && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
            <div className="col-4">
              <div>{Labels.VAZI_DO}</div>
            </div>
            <div className="col-8 p-fluid p-0">
              <CalendarComponent
                ref={vaziDoRef}
                value={state?.cena?.vaziDo}
                onChange={(e: CalendarChangeEvent) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "vaziDo", value: formatDate(e.value as Date | undefined) } });
                  setInvalidFields((prev) => ({ ...prev, vaziDo: false }));
                  if (e.target.value) vaziDoRef?.current?.hide();
                }}
                className={`w-full ${invalidFields?.vaziDo ? "p-invalid" : ""}`}
                onHide={removeAllMasks}
                minDate={new Date()}
                disabled={state.operation === EntityOperation.DELETE}
                placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
              />
              {invalidFields?.vaziDo && <ObaveznoPolje text={Labels.PARTNER_VAZI_DO_VALIDATION_ERROR_MESSAGE} />}
            </div>
            <div className="col-4">
              <div>{Labels.LABEL_POPUST_CENA_PARTNER_DIALOG}</div>
            </div>
            <div className="col-8 p-fluid p-0">
              <Dropdown
                options={popustiList}
                disabled={
                  state.operation === EntityOperation.DELETE ||
                  !isTodayOrInFuture() ||
                  !checkEmpty(state?.cena?.cena) ||
                  !checkEmpty(state?.cena?.valuta?.id) ||
                  !checkEmpty(state?.cena?.poreskaStopa?.id)
                }
                optionLabel="sifra"
                optionValue="id"
                value={state?.cena?.kategorijaPopusta?.id ?? undefined}
                onChange={(e) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "kategorijaPopusta", value: e.value && { id: e.value } } });
                }}
                emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                showClear
              />
            </div>
            <div className="col-4">
              <div>{Labels.SIFARNIK_USLUGA_VALUTA}</div>
            </div>
            <div className="col-8 p-fluid p-0">
              <Dropdown
                optionLabel="sifra"
                optionValue="id"
                disabled={state.operation === EntityOperation.DELETE || !isTodayOrInFuture() || !checkEmpty(state?.cena?.kategorijaPopusta?.id)}
                options={valuteList}
                value={state?.cena?.valuta?.id ?? undefined}
                resetFilterOnHide
                onChange={(e) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "valuta", value: e.value && { id: e.value } } });
                }}
                emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                showClear
              />
            </div>
            <div className="col-4">
              <div>{Labels.HEADER_IZNOS}</div>
            </div>
            <div className="col-8 p-fluid p-0">
              <InputNumber
                disabled={state.operation === EntityOperation.DELETE || !isTodayOrInFuture() || !checkEmpty(state?.cena?.kategorijaPopusta?.id)}
                value={state?.cena?.cena}
                onChange={(e) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "cena", value: e.value } });
                }}
                min={0}
                mode="decimal"
                locale="sr-RS"
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>
            <div className="col-4">
              <div>{Labels.HEADER_PORESKA_STOPA}</div>
            </div>
            <div className="col-8 p-fluid p-0">
              <Dropdown
                optionLabel="procenat"
                optionValue="id"
                disabled={state.operation === EntityOperation.DELETE || !isTodayOrInFuture() || !checkEmpty(state?.cena?.kategorijaPopusta?.id)}
                options={poreskeStopeList}
                value={state?.cena?.poreskaStopa?.id ?? undefined}
                resetFilterOnHide
                onChange={(e) => {
                  dispatch({ type: actionsSifarnikCena.CENA_CHANGE, data: { fieldName: "poreskaStopa", value: e.value && { id: e.value } } });
                }}
                emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                itemTemplate={(e) => `${e.procenat} %`}
                valueTemplate={(e) => `${e?.procenat} %`}
                showClear
              />
            </div>
          </div>
        </div>
      }
    />
  );
}
