import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { useLabels } from "../../Store";
import PorukeViewLogical from "./PorukeViewLogical";
import NovaPoruka from "./create/NovaPoruka";
import PoslataPorukaListView from "./poslate/PoslataPorukaListView";
import PrimljenaPorukaListView from "./primljene/PrimljenaPorukaListView";

export default function PorukeView() {
  const {
    index,
    setIndex,
    documentUploadHandler,
    attachmentList,
    setAttachmentList,
    onRemoveAttachment,
    radnikList,
    porukaChange,
    setPorukaChange,
    recepients,
    setRecepients,
    addRecepient,
    removeRecepient,
    onCreate,
    onChangeRecepients,
    added,
    setAdded,
    refreshPoruke,
    setRefreshPoruke,
  } = PorukeViewLogical();
  const Labels = useLabels();

  return (
    <div className="layout-generic-content-list poruke-layout">
      <Panel>
        <TabView
          className="mt-3 poruke-tabs flex align-items-start pt-0"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.PORUKE_NOVE} leftIcon={"pi pi-plus-circle"} className="mb-2 nova-poruka-tab">
            <NovaPoruka
              documentUploadHandler={documentUploadHandler}
              attachmentList={attachmentList}
              setAttachmentList={setAttachmentList}
              onRemoveAttachment={onRemoveAttachment}
              radnikList={radnikList}
              porukaChange={porukaChange}
              setPorukaChange={setPorukaChange}
              recepients={recepients}
              setRecepients={setRecepients}
              addRecepient={addRecepient}
              removeRecepient={removeRecepient}
              onCreate={onCreate}
              onChangeRecepients={onChangeRecepients}
              added={added}
              setAdded={setAdded}
            />
          </TabPanel>
          <TabPanel header={Labels.PORUKE_PRIMLJENE} leftIcon={"pi pi-inbox"} className="mb-2 poruka-list-tab">
            <PrimljenaPorukaListView setIndex={setIndex} setPorukaChange={setPorukaChange} setAttachmentList={setAttachmentList} setRecepients={setRecepients} setAdded={setAdded} />
          </TabPanel>
          <TabPanel header={Labels.PORUKE_POSLATE} leftIcon={"pi pi-send"} className="poruka-list-tab">
            <PoslataPorukaListView
              setIndex={setIndex}
              setPorukaChange={setPorukaChange}
              setAttachmentList={setAttachmentList}
              setRecepients={setRecepients}
              setAdded={setAdded}
              refreshPoruke={refreshPoruke}
              setRefreshPoruke={setRefreshPoruke}
            />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
