import { Button } from "primereact/button";
import { ConfirmPopup } from "primereact/confirmpopup";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { tooltipOptionsBottom } from "../../infrastructure/system/Utils";
import ConfirmComponent from "../confirm-component/ConfirmComponent";
import UpozorenjeAdministacijaLogical from "./UpozorenjeAdministracijaLogical";
import { useLabels } from "../../Store";

export default function UpozorenjeAdministracija() {
  const Labels = useLabels();
  const { upozorenjeLocal, setUpozorenjeLocal, onCreate, onDelete, upozorenje } = UpozorenjeAdministacijaLogical();

  const leftContentsToolbar = () => (
    <>
      <Button
        disabled={upozorenjeLocal?.sadrzaj?.length === 0}
        label={Labels.BUTTON_SAVE}
        tooltip={Labels.POSTAVI_UPOZORENJE}
        tooltipOptions={tooltipOptionsBottom}
        icon="pi pi-save"
        className="p-button-success mr-2"
        onClick={onCreate}
      />
      <ConfirmComponent
        tooltip={Labels.OBRISI_UPOZORENJE}
        disabled={upozorenje?.sadrzaj?.length === 0}
        tooltipPosition={tooltipOptionsBottom}
        buttonClassName="p-button-danger"
        buttonIcon="pi pi-trash"
        acceptClassName="p-button p-component text-white p-button-tertiary"
        rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
        confirmIcon="pi pi-exclamation-triangle"
        confirmTitle={Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_UPOZORENJE}
        onAccept={onDelete}
      />
    </>
  );

  return (
    <div className="layout-pretraga-pacijenata">
      <Panel>
        <TabView className="mt-3" renderActiveOnly={false}>
          <TabPanel header={Labels.NOVO_UPOZORENJE}>
            <Toolbar left={leftContentsToolbar} />
            <InputTextarea
              rows={3}
              value={upozorenjeLocal?.sadrzaj ?? ""}
              onChange={(e) => {
                setUpozorenjeLocal({
                  ...upozorenjeLocal,
                  sadrzaj: e.target.value,
                });
              }}
              className="w-full"
            />
          </TabPanel>
        </TabView>
      </Panel>
      <ConfirmPopup />
    </div>
  );
}
