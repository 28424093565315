import SkeletonAccordionList from "./SkeletonAccordionList";

interface SkeletonGrupnoObavestenjeListItemType {
  size?: number;
  heightMessageBox?: string;
  heightButton?: string;
}

export default function SkeletonGrupnoObavestenjeListItem(props: SkeletonGrupnoObavestenjeListItemType) {
  const { size, heightMessageBox, heightButton } = props;

  return (
    <>
      {[...Array(size ?? 1).keys()].map((j: number) => (
        <div className="flex flex-row flex-wrap" key={j}>
          <div className="col-9">
            <SkeletonAccordionList height={heightMessageBox ?? "100px"} />
          </div>
          <div className="col-3">
            <SkeletonAccordionList height={heightButton ?? "35px"} />
          </div>
        </div>
      ))}
    </>
  );
}
