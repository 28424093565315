import { Column } from "primereact/column";
import {DataTable, DataTableValue} from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";

export interface SkeletonTableColumnProperty {
  columnName: string;
  filter?: boolean;
  sortrable?: boolean;
  bodyClassName?: string;
  headerClassName?: string;
}
interface SkeletonTableType {
  dataTableColumnsProperty?: Array<SkeletonTableColumnProperty>;
  hasFilterColumns?: boolean;
  isVisibleButtonList?: boolean;
  hasExpanderColumn?: boolean;
  buttonListTemplate?: () => JSX.Element;
  defaultButtonCount?: number;
}

const loadingTemporaryList: DataTableValue[] = Array.from({ length: 10 });

export default function SkeletonTable(props: SkeletonTableType) {
  const { dataTableColumnsProperty, hasFilterColumns, isVisibleButtonList, hasExpanderColumn, buttonListTemplate, defaultButtonCount = 2 } = props;

  const loadingTableTemplate = () => <Skeleton />;

  const defaultButtonListTemplate = () => {
    return (
      <div className="float-right flex align-items-end">
        {Array.from({ length: defaultButtonCount }, (_, i) => (
          <Skeleton key={i} size="2.5rem" className="mr-2" />
        ))}
      </div>
    );
  };

  const expanderTemplate = () => <Skeleton borderRadius="15px" shape="circle" size="30px" className="mr-2" />;

  return (
    <DataTable value={loadingTemporaryList} filterDisplay={hasFilterColumns ? "row" : undefined}>
      {hasExpanderColumn && <Column expander body={expanderTemplate} />}
      {dataTableColumnsProperty?.map((columnProperty: SkeletonTableColumnProperty) => (
        <Column
          key={columnProperty.columnName}
          header={columnProperty.columnName}
          body={loadingTableTemplate}
          filter={columnProperty.filter ?? false}
          filterMatchMode="contains"
          showFilterMenu={false}
          sortable={columnProperty.sortrable ?? false}
          headerClassName={columnProperty.headerClassName ?? ""}
          bodyClassName={columnProperty.bodyClassName ?? ""}
        />
      ))}
      {isVisibleButtonList && <Column body={buttonListTemplate ?? defaultButtonListTemplate} />}
    </DataTable>
  );
}
