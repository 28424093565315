import { useQuery } from "@tanstack/react-query";
import { SifarnikController } from "../../../../../controllers/sifarnik/SifarnikController";
import SifarnikPoreskaStopaDto from "../../../../../model/repository/sifarnik-usluga/SifarnikPoreskaStopaDto";
import QueryKeys from "../../../QueryKeys";

export function usePoreskaStopaList() {
  const { axiosGetPoreskaStopaList } = SifarnikController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.PORESKA_STOPA_LIST],
    queryFn: () => axiosGetPoreskaStopaList(),
    staleTime: Infinity,
  });

  const poreskaStopaList: SifarnikPoreskaStopaDto[] = data?.data?.data ?? [];

  return { poreskaStopaList, isLoading };
}
