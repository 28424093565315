import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { useMemo } from "react";
import { useLabels } from "../../../../../Store";
import actionsDodavanjePostojecegRadnikaUstanova from "../../../../../infrastructure/system/hooks/dialog-dodavanje-postojeceg-radnika-ustanova-reducer/actionsDodavanjePostojecegRadnikaUstanova";
import { InitialStateDodavanjePostojecegRadnikaUstanovaType } from "../../../../../infrastructure/system/hooks/dialog-dodavanje-postojeceg-radnika-ustanova-reducer/initialStateDodavanjePostojecegRadnikaUstanova";
import RadnikReadDto from "../../../../../model/radnik/RadnikReadDto";
import DialogComponent from "../../../../dialog-component/DialogComponent";

interface DialogDodavanjePostojecegRadnikaType {
  state: InitialStateDodavanjePostojecegRadnikaUstanovaType;
  dispatch: React.Dispatch<{ type: actionsDodavanjePostojecegRadnikaUstanova }> | any;
  searchRadnikAdd: (e: AutoCompleteCompleteEvent) => void;
  searchString: string;
  onRadnikAdd: () => void;
  isDisabled: boolean;
}

export default function DialogDodavanjePostojecegRadnika(props: DialogDodavanjePostojecegRadnikaType) {
  const { state, dispatch, searchRadnikAdd, searchString, onRadnikAdd, isDisabled } = props;
  const Labels = useLabels();

  const renderFooter = () => {
    return (
      <div className="flex justify-content-end layout-generic-content-list">
        <Button
          label={Labels.BUTTON_CANCEL}
          className="p-button-outlined p-button-tertiary align-self-center"
          onClick={() => {
            dispatch({ type: actionsDodavanjePostojecegRadnikaUstanova.CLOSE_DIALOG });
          }}
          autoFocus
        />
        <Button label={Labels.BUTTON_SAVE} className="p-button-success " onClick={onRadnikAdd} disabled={state.radnikSelected?.id === undefined} autoFocus />
      </div>
    );
  };
  const autocompleteStyles = useMemo(() => {
    return { paddingLeft: "2rem", paddingRight: "2rem" };
  }, []);
  const getHighlightedText = (text: string, highlight: string) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: "bold" } : {}}>
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };
  const radnikAddItemTemplate = (radnik: RadnikReadDto) => {
    return (
      <div>
        {getHighlightedText(radnik?.ime + " " + radnik?.prezime, searchString)}
        <span>{radnik?.ustanovaPoslednjaIzabrana?.naziv}</span>
      </div>
    );
  };

  return (
    <DialogComponent
      header={Labels.TITLE_RADNIK_ADD_OTHER}
      visible={state.isDialogOpen}
      footer={renderFooter}
      onHide={() => dispatch({ type: actionsDodavanjePostojecegRadnikaUstanova.CLOSE_DIALOG })}
      content={
        <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view mt-2">
          <div className="grid">
            <div className="col-12">
              <div className="grid align-items-center justfy-content-center p-nogutter">
                <div className="col-12 p-fluid margin-top-bottom-5 p-1 pl-3">
                  <span className="p-input-icon-left">
                    <AutoComplete
                      value={state.radnikSelected}
                      disabled={isDisabled}
                      itemTemplate={radnikAddItemTemplate}
                      selectedItemTemplate={(e) => `${e.ime} ${e.prezime} - ${e.ustanovaPoslednjaIzabrana.naziv}`}
                      suggestions={state.radnikAddList}
                      completeMethod={searchRadnikAdd}
                      inputStyle={autocompleteStyles}
                      field="unosRadnika"
                      onChange={(e) => {
                        dispatch({ type: actionsDodavanjePostojecegRadnikaUstanova.SET_SELECTED_RADNIK, value: e.value });
                      }}
                      minLength={3}
                      className="mt-2"
                    />
                    <i className="pi pi-search " />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}
