interface IconCalendarTableViewProps {
  color?: string;
  height?: string;
}
const IconCalendarTableView = ({ color, height }: IconCalendarTableViewProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      height={height ?? "100%"}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-table"
    >
      <path d="M9 3H5a2 2 0 0 0-2 2v4m6-6h10a2 2 0 0 1 2 2v4M9 3v18m0 0h10a2 2 0 0 0 2-2V9M9 21H5a2 2 0 0 1-2-2V9m0 0h18"></path>
    </svg>
  );
};

export default IconCalendarTableView;
