import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { Navigate } from "react-router-dom";
import { useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import FormularListPage from "../formular/FormularListView";
import CrudTipKontaktaLogical from "./CrudTipKontaktaLogical";
import CrudTipKontaktaOrganizacionaJedinica from "./CrudTipKontaktaOrganizacionaJedinicaView";

export default function CrudTipKontakta() {
  const {
    tipKontaktaOperation,
    isDisabled,
    tipKontaktaChange,
    sifKategorijaKontakta,
    sifarnikVremeTrajanjaKontakta,
    breadCrumbItems,
    setTipKontaktaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    index,
    setIndex,
    tipKontaktaId,
    location,
    kategorijaKontaktaLoading,
    tipKontaktaLoading,
    ustanovaId,
    ustanovaNaziv,
    invalidFields,
    setInvalidFields,
    formularList,
    shouldRefetchFormulars,
  } = CrudTipKontaktaLogical();
  const Labels = useLabels();

  if (!tipKontaktaId && !location.state) {
    return <Navigate to="/formular-list" />;
  }

  const leftContentsToolbar = () => (
    <>
      {tipKontaktaOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          disabled={kategorijaKontaktaLoading}
          tooltip={Labels.TIP_KONTAKTA_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {tipKontaktaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={tipKontaktaLoading || kategorijaKontaktaLoading}
          icon="pi pi-pencil"
          tooltip={Labels.TIP_KONTAKTA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {tipKontaktaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={tipKontaktaLoading || kategorijaKontaktaLoading}
          icon="pi pi-trash"
          tooltip={Labels.TIP_KONTAKTA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={onCancel} />
    </>
  );
  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_TIP_KONTAKTA}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:px-3 sm:pb-3">
                  <div>
                    {Labels.TIP_KONTAKTA_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!tipKontaktaLoading ? (
                    <>
                      <InputText
                        disabled={isDisabled}
                        value={tipKontaktaChange?.sifra ?? ""}
                        onChange={(e) => {
                          setTipKontaktaChange({
                            ...tipKontaktaChange!,
                            sifra: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", tipKontaktaChange?.sifra);
                        }}
                        className={invalidFields?.sifra && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:px-3 sm:pb-3">
                  <div>
                    {Labels.TIP_KONTAKTA_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!tipKontaktaLoading ? (
                    <>
                      <InputText
                        disabled={isDisabled}
                        value={tipKontaktaChange?.naziv ?? ""}
                        onChange={(e) => {
                          setTipKontaktaChange({
                            ...tipKontaktaChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", tipKontaktaChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:px-3 sm:pb-3">
                  <div>
                    {Labels.TIP_KONTAKTA_KATEGORIJA_KONTAKTA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!tipKontaktaLoading && !kategorijaKontaktaLoading ? (
                    <>
                      <Dropdown
                        options={sifKategorijaKontakta}
                        disabled={isDisabled}
                        resetFilterOnHide
                        value={tipKontaktaChange?.sifarnikKategorijaKontakta?.sifra ?? ""}
                        optionLabel="naziv"
                        optionValue="sifra"
                        onChange={(e) => {
                          setTipKontaktaChange({
                            ...tipKontaktaChange!,
                            sifarnikKategorijaKontakta: sifKategorijaKontakta?.filter((skk) => skk.sifra === e.value)[0],
                          });
                          setInvalidFields((prev) => ({ ...prev, kategorija: false }));
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={invalidFields?.kategorija && "p-invalid"}
                      />
                      {invalidFields?.kategorija && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:px-3 sm:pb-3">
                  <div>
                    {Labels.TIP_KONTAKTA_AUTOMATSKO_ZAVRSAVANJE}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!tipKontaktaLoading ? (
                    <InputSwitch
                      disabled={isDisabled}
                      checked={tipKontaktaChange?.automatskoZavrsavanje ?? false}
                      onChange={(e) => {
                        setTipKontaktaChange({
                          ...tipKontaktaChange!,
                          automatskoZavrsavanje: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:px-3 sm:pb-3">
                  <div>{Labels.TIP_KONTAKTA_VREME_TRAJANJA_PREGLEDA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!tipKontaktaLoading && !kategorijaKontaktaLoading ? (
                    <Dropdown
                      options={sifarnikVremeTrajanjaKontakta}
                      disabled={isDisabled}
                      resetFilterOnHide
                      value={tipKontaktaChange?.vremeTrajanjaKontakta?.sifra ?? ""}
                      optionLabel="naziv"
                      optionValue="sifra"
                      onChange={(e) => {
                        setTipKontaktaChange({
                          ...tipKontaktaChange!,
                          vremeTrajanjaKontakta: sifarnikVremeTrajanjaKontakta?.filter((svtk) => svtk.sifra === e.value)[0],
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      showClear
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                {tipKontaktaOperation !== EntityOperation.CREATE && (
                  <>
                    <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:px-3 sm:pb-3">
                      <div>{Labels.TIP_KONTAKTA_PODRAZUMEVANI_FORMULAR}</div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      {!tipKontaktaLoading && !kategorijaKontaktaLoading ? (
                        <Dropdown
                          options={formularList}
                          disabled={isDisabled}
                          resetFilterOnHide
                          value={tipKontaktaChange?.formularPodrazumevani?.sifra ?? ""}
                          optionLabel="naziv"
                          optionValue="sifra"
                          onChange={(e) => {
                            setTipKontaktaChange({
                              ...tipKontaktaChange!,
                              formularPodrazumevani: formularList?.filter((formular) => formular.sifra === e.value)[0],
                            });
                          }}
                          emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                          showClear
                        />
                      ) : (
                        <SkeletonInputItem />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
          </TabPanel>
          <TabPanel header={Labels.FORMULAR_LIST} disabled={tipKontaktaOperation === EntityOperation.CREATE}>
            <FormularListPage
              tipKontaktaOperation={tipKontaktaOperation}
              tipKontaktaId={tipKontaktaChange?.id ? tipKontaktaChange.id : tipKontaktaId}
              tipKontaktaNaziv={tipKontaktaChange?.naziv}
              isDisabled={isDisabled}
              tipKontaktaChange={tipKontaktaChange}
              ustanovaId={ustanovaId}
              ustanovaNaziv={ustanovaNaziv}
              shouldRefetchFormulars={shouldRefetchFormulars}
            />
          </TabPanel>
          {!(ustanovaId ?? false) && (
            <TabPanel header={Labels.ORGANIZACIONA_JEDINICA_LIST} disabled={tipKontaktaOperation === EntityOperation.CREATE}>
              <CrudTipKontaktaOrganizacionaJedinica isDisabled={isDisabled} tipKontaktaOperation={tipKontaktaOperation} />
            </TabPanel>
          )}
        </TabView>
      </Panel>
    </div>
  );
}
