import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Steps } from "primereact/steps";
import { useMemo } from "react";
import { useLabels } from "../../Store";
import Images from "../../infrastructure/system/Images";
import { formatPrice, tooltipOptionsTop } from "../../infrastructure/system/Utils";
import DialogComponent from "../dialog-component/DialogComponent";
import NaplataLogical, { NaplataContext } from "./NaplataLogical";
import Racuni from "./steps/racuni/Racuni";
import Spisak from "./steps/spisak/Spisak";
import Uplatioci from "./steps/uplatioci/Uplatioci";

export default function NaplataView() {
  const Labels = useLabels();
  const {
    state,
    dispatch,
    items,
    updateStavkaValue,
    onNextClickValidation,
    uplatilacList,
    onCreateRacun,
    openPlacenRacunDialog,
    jumpToAccounts,
    fastPayment,
    showPlacenRacunDialog,
    setShowPlacenRacunDialog,
    racunData,
    nextButtonDisabled,
    setNextButtonDisabled,
    prevStepRef,
    invalidInputs,
    setInvalidInputs,
    closeFastPaymentDialog,
    showFastPaymentDialog,
    setShowFastPaymentDialog,
    createFinalRacun,
    finalRacunLoading,
    setFinalRacunLoading,
    nacinPlacanjaList,
    nacinPlacanjaListIsLoading,
    finalnaValuta,
    valutaList,
    getRacuniForKontaktAndPrint,
  } = NaplataLogical();

  const brziNacinPlacanja = useMemo(() => nacinPlacanjaList.find((nacinPlacanja) => nacinPlacanja.brzoPlacanje), [nacinPlacanjaList]);

  const renderStep = () => {
    switch (state.step) {
      case 0:
        return (
          <Spisak
            openPlacenRacunDialog={openPlacenRacunDialog}
            showPlacenRacunDialog={showPlacenRacunDialog}
            setShowPlacenRacunDialog={setShowPlacenRacunDialog}
            racunData={racunData}
            prevStepRef={prevStepRef}
            getRacuniForKontaktAndPrint={getRacuniForKontaktAndPrint}
          />
        );
      case 1:
        return <Uplatioci />;
      case 2:
        return <Racuni prevStepRef={prevStepRef} />;
      default:
        return (
          <Spisak
            openPlacenRacunDialog={openPlacenRacunDialog}
            showPlacenRacunDialog={showPlacenRacunDialog}
            setShowPlacenRacunDialog={setShowPlacenRacunDialog}
            racunData={racunData}
            prevStepRef={prevStepRef}
            getRacuniForKontaktAndPrint={getRacuniForKontaktAndPrint}
          />
        );
    }
  };

  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-content-between">
        <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-outlined p-button-tertiary" onClick={closeFastPaymentDialog} />
        <Button
          label={Labels.LABEL_NAPLATI}
          icon={() => <img className="p-button.p-button-text mr-1" src={Images.ICON_MAPLATA_CASH} alt={Labels.LABEL_BRZA_NAPLATA} />}
          onClick={() => {
            if (onNextClickValidation()) fastPayment();
          }}
        />
      </div>
    );
  };

  return (
    <NaplataContext.Provider
      value={{
        state,
        dispatch,
        updateStavkaValue,
        nextButtonDisabled,
        setNextButtonDisabled,
        invalidInputs,
        setInvalidInputs,
        uplatilacList,
        nacinPlacanjaList,
        nacinPlacanjaListIsLoading,
        finalnaValuta,
      }}
    >
      <div className="naplata flex flex-column justify-content-between">
        <div className="wizard-nav flex flex-row justify-content-center py-4">
          <div className="col-4 relative">
            {state.step > 0 && !state.accountsPaymentOpted && (
              <Button
                className="p-button-primary nav-btn nav-btn-back"
                icon="pi pi-chevron-left"
                disabled={state.step === 0}
                onClick={() => {
                  dispatch({ type: "decrement_step" });
                }}
              />
            )}
            {state.step < 2 && (
              <Button
                className="p-button-primary nav-btn nav-btn-forward"
                icon="pi pi-chevron-right"
                disabled={state.step === 3}
                onClick={() => {
                  if (onNextClickValidation()) {
                    if (state.step === 1) dispatch({ type: "create_racun_from_stavke_naplate", data: { finalnaValuta: valutaList.find((valuta) => valuta.sifra === finalnaValuta)! } });
                    dispatch({ type: "increment_step" });
                  }
                }}
              />
            )}
            <Steps className="wizard-nav__steps" model={items} activeIndex={state.step} readOnly={false} />
          </div>
        </div>
        <div className="flex flex-row justify-content-center">
          <div className="col-12 pt-8 content-wrapper">{renderStep()}</div>
        </div>
        <div className="wizard-footer flex flex-row justify-content-center py-4">
          <Button
            className="p-button-outlined mr-5"
            label={Labels.BUTTON_CANCEL}
            icon="pi pi-times"
            onClick={() => dispatch({ type: "reset_all" })}
            disabled={state.step === 0 && state.selectedColumns.length === 0}
          />
          {state.step === 0 && (
            <Button
              label={Labels.LABEL_UPLATIOCI_NAPLATA}
              className="border mr-4"
              tooltip={Labels.SIFARNIK_NACINA_PLACANJA_UPLATIOCI_TOOLTIP_DESCRIPTION}
              tooltipOptions={tooltipOptionsTop}
              icon={() => <img className=" p-button.p-button-text mr-1" src={Images.ICON_MAPLATA_UPLATIOCI} alt={Labels.LABEL_UPLATIOCI_NAPLATA} />}
              onClick={() => {
                if (onNextClickValidation()) {
                  dispatch({ type: "increment_step" });
                }
              }}
            />
          )}
          {state.step === 0 && (
            <Button
              label={Labels.LABEL_RACUNI_NAPLATA}
              className="border mr-4"
              tooltip={Labels.SIFARNIK_NACINA_PLACANJA_RACUNI_TOOLTIP_DESCRIPTION}
              tooltipOptions={tooltipOptionsTop}
              icon={() => <img className=" p-button.p-button-text mr-1" src={Images.ICON_MAPLATA_RACUNI} alt={Labels.LABEL_RACUNI_NAPLATA} />}
              onClick={() => {
                if (onNextClickValidation()) {
                  jumpToAccounts();
                }
              }}
            />
          )}
          {state.step === 0 && !!brziNacinPlacanja && (
            <Button
              label={Labels.LABEL_BRZA_NAPLATA}
              className="border mr-4"
              tooltip={Labels.SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_TOOLTIP_DESCRIPTION}
              tooltipOptions={tooltipOptionsTop}
              icon={() => <img className=" p-button.p-button-text mr-1" src={Images.ICON_MAPLATA_CASH} alt={Labels.LABEL_BRZA_NAPLATA} />}
              onClick={() => {
                if (onNextClickValidation()) {
                  setFinalRacunLoading(true);
                  setShowFastPaymentDialog(true);
                  createFinalRacun(brziNacinPlacanja);
                }
              }}
            />
          )}
          {state.step !== 0 && (
            <Button
              label={state.step < 2 ? Labels.LABEL_NASTAVI_NAPLATA : Labels.LABEL_SNIMI_NAPLATA}
              icon="pi pi-check"
              onClick={() => {
                if (onNextClickValidation()) {
                  if (state.step < 2) {
                    if (state.step === 1) dispatch({ type: "create_racun_from_stavke_naplate", data: { finalnaValuta: valutaList.find((valuta) => valuta.sifra === finalnaValuta)! } });
                    dispatch({ type: "increment_step" });
                  } else {
                    onCreateRacun();
                  }
                }
              }}
            />
          )}
        </div>
        {showFastPaymentDialog && (
          <DialogComponent
            header={Labels.LABEL_BRZA_NAPLATA}
            visible={showFastPaymentDialog}
            onHide={closeFastPaymentDialog}
            footer={renderFooter}
            className="w-4"
            content={
              <>
                <div className="mb-2">{Labels.SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_ACCEPT_DIALOG_CONTENT}</div>
                <div className="font-bold">
                  {Labels.SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_ACCEPT_DIALOG_CONTENT_2 + " "}
                  {finalRacunLoading ? <ProgressSpinner className="m-0 w-1rem h-1rem" /> : <span className="font-bold">{`${formatPrice(state.racuni?.[0]?.iznos)} ${finalnaValuta} `}</span>}
                </div>
              </>
            }
          />
        )}
      </div>
    </NaplataContext.Provider>
  );
}
