import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SifarnikJedinicaMereCreateDto from "../../model/sifarnik/sifarnik-jedinica-mere/SifarnikJedinicaMereCreateDto";
import SifarnikJedinicaMereReadDto from "../../model/sifarnik/sifarnik-jedinica-mere/SifarnikJedinicaMereReadDto";

interface SifarnikJedinicaMereControllerType {
  axiosGetSifarnikJedinicaMereList: () => Promise<AxiosResponse<any>>;
  axiosGetSifarnikJedinicaMere: (sifarnikJedinicaMereId: number) => Promise<AxiosResponse<any>>;
  axiosCreateSifarnikJedinicaMere: (sifarnikJedinicaMereChange: SifarnikJedinicaMereCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteSifarnikJedinicaMere: (sifarnikJedinicaMereId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateSifarnikJedinicaMere: (sifarnikJedinicaMere: SifarnikJedinicaMereReadDto) => Promise<AxiosResponse<any>>;
  axiosGetSifarnikJedinicaMereListRepository: () => Promise<AxiosResponse<any>>;
  axiosGetJedinicaMereByIdRepository: (jedinicaMereId: number) => Promise<AxiosResponse<any>>;
}

export function SifarnikJedinicaMereController(): SifarnikJedinicaMereControllerType {
  const { authData } = useContext(AppContext);
  const { SIFARNIK_JEDINICA_MERE_LIST, SIFARNIK_JEDINICA_MERE } = Endpoint;

  const axiosGetSifarnikJedinicaMereList = () => {
    return axios.get(SIFARNIK_JEDINICA_MERE_LIST, axiosConfig(authData!.token));
  };

  const axiosGetSifarnikJedinicaMere = (sifarnikJedinicaMereId: number) => {
    return axios.get(`${SIFARNIK_JEDINICA_MERE_LIST}/${sifarnikJedinicaMereId}`, axiosConfig(authData!.token));
  };

  const axiosCreateSifarnikJedinicaMere = (sifarnikJedinicaMereChange: SifarnikJedinicaMereCreateDto) => {
    return axios.post(`${Endpoint.SIFARNIK_JEDINICA_MERE_LIST}`, sifarnikJedinicaMereChange, axiosConfig(authData!.token));
  };

  const axiosDeleteSifarnikJedinicaMere = (sifarnikJedinicaMereId: number) => {
    return axios.delete(`${Endpoint.SIFARNIK_JEDINICA_MERE_LIST}/${sifarnikJedinicaMereId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateSifarnikJedinicaMere = (sifarnikJedinicaMere: SifarnikJedinicaMereReadDto) => {
    return axios.put(`${Endpoint.SIFARNIK_JEDINICA_MERE_LIST}/${sifarnikJedinicaMere?.id}`, sifarnikJedinicaMere, axiosConfig(authData!.token));
  };

  const axiosGetSifarnikJedinicaMereListRepository = () => {
    return axios.get(SIFARNIK_JEDINICA_MERE, axiosConfig(authData!.token));
  };

  const axiosGetJedinicaMereByIdRepository = (jedinicaMereId: number) => {
    return axios.get(`${SIFARNIK_JEDINICA_MERE}/${jedinicaMereId}`, axiosConfig(authData!.token));
  };

  return {
    axiosGetSifarnikJedinicaMereList,
    axiosGetSifarnikJedinicaMere,
    axiosCreateSifarnikJedinicaMere,
    axiosDeleteSifarnikJedinicaMere,
    axiosUpdateSifarnikJedinicaMere,
    axiosGetSifarnikJedinicaMereListRepository,
    axiosGetJedinicaMereByIdRepository,
  };
}
