import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { PoljeTipController } from "../../../controllers/polje-tip/PoljeTipController";
import { RepositoryPoljeTipController } from "../../../controllers/superadmin/repository/polje-tip/RepositoryPoljeTipController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "./../../../model/SearchBaseDto";
import PoljeTipReadDto from "./../../../model/poljeTip/PoljeTipReadDto";

interface PoljeTipListLogicalType {
  poljeTipList: Array<PoljeTipReadDto>;
  first: number;
  tableRows: number;
  firstRepository: number;
  tableRepositoryRows: number;
  changePoljeTipSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  poljeTipSearchData: SearchBaseDto;
  fetchData: () => void;
  searchPoljeTipByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  onPagePoljeTip: (rows: number, first: number) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  poljeTipLoading: boolean;
  repositoryPoljeTipLoading: boolean;
  closeDialog: () => void;
  openDialog: () => void;
  isDialogOpen: boolean;
  repositoryPoljeTipList: Array<PoljeTipReadDto>;
  onPageRepositoryPoljeTip: (rows: number, first: number) => void;
  selectedColumns: Array<PoljeTipReadDto>;
  setSelectedColumns: React.Dispatch<React.SetStateAction<Array<PoljeTipReadDto>>>;
  importPoljeTip: () => void;
}

export default function PoljeTipListLogical(): PoljeTipListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [poljeTipList, setPoljeTipList] = useState<Array<PoljeTipReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [firstRepository, setFirstRepository] = useState(0);
  const [tableRepositoryRows, setTableRepositoryRows] = useState(10);
  const [poljeTipSearchData, setPoljeTipSearchData] = useState<SearchBaseDto>({});
  const [poljeTipLoading, setPoljeTipLoading] = useState<boolean>(true);
  const [repositoryPoljeTipLoading, setRepositoryPoljeTipLoading] = useState<boolean>(true);
  const [repositoryPoljeTipList, setRepositoryPoljeTipList] = useState<Array<PoljeTipReadDto>>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<Array<PoljeTipReadDto>>([]);
  const [breadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.POLJE_TIP_LIST,
    },
  ]);
  const { axiosSearchPoljeTip } = PoljeTipController();
  const { axiosPublicSearchRepositoryPoljeTip, axiosPublicCopyRepositoryPoljeTip } = RepositoryPoljeTipController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const startTime = moment(new Date());
    setPoljeTipLoading(true);
    axiosSearchPoljeTip(poljeTipSearchData)
      .then((res: { data: { data: Array<PoljeTipReadDto> } }) => {
        setPoljeTipList(res.data.data);
        const exportList = new Array<any>();
        res.data.data.forEach((poljeTip: PoljeTipReadDto) => {
          exportList.push({
            [Labels.LABEL_TIP_KOMPONENTE]: poljeTip.tipKomponenteEnum != null ? poljeTip.tipKomponenteEnum.sifra : "",
            [Labels.LABEL_NAZIV]: poljeTip.naziv,
            [Labels.LABEL_SIFRA]: poljeTip.sifra,
          });
          setExportData(exportList);
        });
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setPoljeTipLoading, startTime);
      });
  };

  const changePoljeTipSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setPoljeTipSearchData({
      ...poljeTipSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchPoljeTipByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPagePoljeTip = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const onPageRepositoryPoljeTip = (rows: number, first: number) => {
    setTableRepositoryRows(rows);
    setFirstRepository(first);
  };
  const openDialog = () => {
    setIsDialogOpen(true);
    setRepositoryPoljeTipLoading(true);
    const startTime = moment(new Date());
    axiosPublicSearchRepositoryPoljeTip({})
      .then((res: { data: { data: Array<PoljeTipReadDto> } }) => {
        setRepositoryPoljeTipList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setRepositoryPoljeTipLoading, startTime);
      });
  };
  const importPoljeTip = () => {
    const poljeTipIDList: Array<number> = selectedColumns.map((poljeTip: PoljeTipReadDto) => poljeTip.id);
    axiosPublicCopyRepositoryPoljeTip({ poljeTipIDList })
      .then(() => {
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        closeDialog();
      });
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedColumns([]);
  };

  return {
    poljeTipList,
    first,
    tableRows,
    changePoljeTipSearchData,
    poljeTipSearchData,
    fetchData,
    searchPoljeTipByEnter,
    exportData,
    breadCrumbItems,
    onPagePoljeTip,
    poljeTipLoading,
    repositoryPoljeTipLoading,
    closeDialog,
    openDialog,
    isDialogOpen,
    firstRepository,
    repositoryPoljeTipList,
    tableRepositoryRows,
    onPageRepositoryPoljeTip,
    selectedColumns,
    setSelectedColumns,
    importPoljeTip,
  };
}
