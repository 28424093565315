import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import PoljeTipReadDto from "../../../model/poljeTip/PoljeTipReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import DialogComponent from "../../dialog-component/DialogComponent";
import PoljeTipListLogical from "./PoljeTipListLogical";

export default function PoljeTipListPage() {
  const {
    poljeTipList,
    first,
    tableRows,
    changePoljeTipSearchData,
    poljeTipSearchData,
    fetchData,
    searchPoljeTipByEnter,
    exportData,
    breadCrumbItems,
    onPagePoljeTip,
    poljeTipLoading,
    closeDialog,
    isDialogOpen,
    openDialog,
    repositoryPoljeTipLoading,
    repositoryPoljeTipList,
    firstRepository,
    tableRepositoryRows,
    onPageRepositoryPoljeTip,
    selectedColumns,
    setSelectedColumns,
    importPoljeTip,
  } = PoljeTipListLogical();

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const { pristup } = useContext(AppContext);

  const openPoljeTip = (entityOperation: string, poljeTip?: PoljeTipReadDto) => {
    const poljeTipId = entityOperation !== EntityOperation.CREATE ? poljeTip?.id : "";
    navigate(`/polje-tip/${poljeTipId}`, {
      state: { poljeTipOperation: entityOperation },
    });
  };

  const crudBodyTemplate = (rowPoljeTip: PoljeTipReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.POLJE_TIP_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowPoljeTip && openPoljeTip(EntityOperation.UPDATE, rowPoljeTip)}
        />
        <Button
          type="button"
          tooltip={Labels.POLJE_TIP_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowPoljeTip && openPoljeTip(EntityOperation.DELETE, rowPoljeTip)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const toolbarLeftContent = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_POLJE_TIP}
          tooltip={Labels.POLJE_TIP_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openPoljeTip(EntityOperation.CREATE);
          }}
          disabled={!pristup}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            className="search-input"
            name="searchString"
            value={poljeTipSearchData?.searchString ?? ""}
            onChange={changePoljeTipSearchData}
            onKeyPress={searchPoljeTipByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_POLJE_TIP}</label>
          <Button tooltip={Labels.POLJE_TIP_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const toolbarRightContent = () => {
    return (
      <div className="flex flex-row flex-wrap">
        <Button
          label={Labels.BUTTON_REPOSITORY}
          icon="pi pi-cloud-download"
          tooltip={Labels.IMPORT_POLJE_TIP_REPOSITORY}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-outlined mr-3 bg-white"
          onClick={() => {
            openDialog();
          }}
        />
        <ExportData disabled={poljeTipLoading} data={exportData} name={Labels.POLJE_TIP_LIST} dt={dt} />
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.POLJE_TIP_KOMPONENTA_TIP, filter: true, sortrable: true },
    { columnName: Labels.POLJE_TIP_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.POLJE_TIP_NAZIV, filter: true, sortrable: true },
  ];
  const columnsRepositoryProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.CEKAONICA_COLUMN_SVE },
    { columnName: Labels.POLJE_TIP_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.POLJE_TIP_KOMPONENTA_TIP, filter: true, sortrable: true },
  ];
  const leftDialogContents = () => (
    <Button label={Labels.LABEL_EXPORT} disabled={selectedColumns.length === 0} icon="pi pi-download" className="p-button-outlined p-button-tertiary bg-white" onClick={importPoljeTip} autoFocus />
  );
  const rightDialogContents = () => <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={closeDialog} />;
  const dialogFooter = (
    <div className="col-12">
      <Toolbar left={leftDialogContents} right={rightDialogContents} />
    </div>
  );
  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={toolbarLeftContent} right={toolbarRightContent} />
        {poljeTipLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={poljeTipList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPagePoljeTip(e.rows, e.first);
            }}
            exportFilename={Labels.POLJE_TIP_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"tipKomponenteEnum.naziv"} header={Labels.POLJE_TIP_KOMPONENTA_TIP} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.POLJE_TIP_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"naziv"} header={Labels.POLJE_TIP_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
      <Panel>
        <DialogComponent
          header={Labels.POLJE_TIP_REPOSITORY}
          visible={isDialogOpen}
          onHide={closeDialog}
          footer={dialogFooter}
          className="w-8"
          content={
            <div>
              <div className="card">
                {repositoryPoljeTipLoading ? (
                  <SkeletonTable dataTableColumnsProperty={columnsRepositoryProperty} hasFilterColumns={true} />
                ) : (
                  <DataTable
                    ref={dt}
                    value={repositoryPoljeTipList}
                    first={firstRepository}
                    rows={tableRepositoryRows}
                    filterDisplay="row"
                    className="p-datatable-flex-scrollable"
                    scrollHeight="flex"
                    paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
                    //paginator
                    selectionMode="checkbox"
                    selection={selectedColumns}
                    responsiveLayout="scroll"
                    onSelectionChange={(e) => setSelectedColumns(e.value)}
                    emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                    onPage={(e) => {
                      onPageRepositoryPoljeTip(e.rows, e.first);
                    }}
                    rowHover
                    stripedRows
                  >
                    <Column filterHeaderClassName="pl-3" bodyClassName="w-3rem pl-3" selectionMode="multiple" header={Labels.CEKAONICA_COLUMN_SVE} headerClassName="w-3rem pl-3" />
                    <Column field={"naziv"} header={Labels.POLJE_TIP_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    <Column field={"tipKomponenteEnum.naziv"} header={Labels.POLJE_TIP_KOMPONENTA_TIP} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                  </DataTable>
                )}
              </div>
            </div>
          }
        ></DialogComponent>
      </Panel>
    </div>
  );
}
