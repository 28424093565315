import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../../../Store";
import Endpoint from "../../../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../../../infrastructure/system/Utils";
import SearchBaseDto from "../../../../model/SearchBaseDto";
import FormularCreateDto from "../../../../model/formular/FormularCreateDto";
import FormularPublicCopyDto from "../../../../model/formular/FormularPublicCopyDto";
import FormularReadDto from "../../../../model/formular/FormularReadDto";
import PoljeReadDto from "../../../../model/polje/PoljeReadDto";

interface RepositoryFormularControllerType {
  axiosGetRepositoryFormularList: () => Promise<AxiosResponse<any>>;
  axiosSearchRepositoryFormular: (formularSearchData: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosCreateRepositoryFormular: (formularChange: FormularCreateDto) => Promise<AxiosResponse<any>>;
  axiosUpdateRepositoryFormular: (formularChange: FormularReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteRepositoryFormular: (formularId: number) => Promise<AxiosResponse<any>>;
  axiosGetRepositoryFormular: (formularId: number) => Promise<AxiosResponse<any>>;
  axiosGetRepositoryPoljeList: (formularId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteRepositoryPolje: (formularId: number, poljeId: number) => Promise<AxiosResponse<any>>;
  axiosCreateRepositoryPolje: (formularId: number, polje: PoljeReadDto) => Promise<AxiosResponse<any>>;
  axiosUpdateRepositoryPolje: (formularId: number, poljeChange: PoljeReadDto) => Promise<AxiosResponse<any>>;
  axiosReadRepositoryPolje: (poljeId: number, formularId: number) => Promise<AxiosResponse<any>>;
  axiosGetRepositoryFormularListForKontaktTip: (tipKontaktaId: number) => Promise<AxiosResponse<any>>;
  axiosPublicSearchRepositoryFormular: (searchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosPublicCopyRepositoryFormular: (formularPublicCopy: FormularPublicCopyDto) => Promise<AxiosResponse<any>>;
  axiosGetRepositoryPoljePanelList: (formularId: number) => Promise<AxiosResponse<any>>;
}

export function RepositoryFormularController(): RepositoryFormularControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetRepositoryFormularList = () => {
    return axios.get(Endpoint.REPOSITORY_FORMULAR_LIST, axiosConfig(authData!.token));
  };

  const axiosSearchRepositoryFormular = (formularSearchData: SearchBaseDto) => {
    return axios.get(Endpoint.REPOSITORY_FORMULAR_SEARCH, axiosConfig(authData!.token, formularSearchData));
  };

  const axiosGetRepositoryFormular = (formularId: number) => {
    return axios.get(`${Endpoint.REPOSITORY_FORMULAR_LIST}/${formularId}`, axiosConfig(authData!.token));
  };

  const axiosCreateRepositoryFormular = (formularChange: FormularCreateDto) => {
    return axios.post(`${Endpoint.REPOSITORY_FORMULAR_LIST}`, formularChange ? formularChange : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateRepositoryFormular = (formularChange: FormularReadDto) => {
    return axios.put(`${Endpoint.REPOSITORY_FORMULAR_LIST}/${formularChange.id}`, formularChange, axiosConfig(authData!.token));
  };

  const axiosDeleteRepositoryFormular = (formularId: number) => {
    return axios.delete(`${Endpoint.REPOSITORY_FORMULAR_LIST}/${formularId}`, axiosConfig(authData!.token));
  };

  const axiosGetRepositoryPoljeList = (formularId: number) => {
    return axios.get(`${Endpoint.REPOSITORY_FORMULAR_LIST}/${formularId}/polje-list`, axiosConfig(authData!.token));
  };

  const axiosDeleteRepositoryPolje = (formularId: number, poljeId: number) => {
    return axios.delete(`${Endpoint.REPOSITORY_FORMULAR_LIST}/${formularId}/polje-list/${poljeId}`, axiosConfig(authData!.token));
  };

  const axiosCreateRepositoryPolje = (formularId: number, polje: PoljeReadDto) => {
    return axios.post(`${Endpoint.REPOSITORY_FORMULAR_LIST}/${formularId}/polje-list`, polje ? polje : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateRepositoryPolje = (formularId: number, poljeChange: PoljeReadDto) => {
    return axios.put(`${Endpoint.REPOSITORY_FORMULAR_LIST}/${formularId}/polje-list/${poljeChange.id}`, poljeChange, axiosConfig(authData!.token));
  };

  const axiosReadRepositoryPolje = (poljeId: number, formularId: number) => {
    return axios.get(`${Endpoint.REPOSITORY_FORMULAR_LIST}/${formularId}/polje-list/${poljeId}`, axiosConfig(authData!.token));
  };

  const axiosGetRepositoryFormularListForKontaktTip = (tipKontaktaId: number) => {
    return axios.get(`${Endpoint.REPOSITORY_FORMULAR_LIST}/kontakt-tip/${tipKontaktaId}`, axiosConfig(authData!.token));
  };
  const axiosPublicSearchRepositoryFormular = (searchData?: SearchBaseDto) => {
    return axios.get(Endpoint.REPOSITORY_FORMULAR_PUBLIC_SEARCH, axiosConfig(authData!.token, searchData));
  };
  const axiosPublicCopyRepositoryFormular = (formularPublicCopy: FormularPublicCopyDto) =>
    axios.post(`${Endpoint.REPOSITORY_FORMULAR_LIST}${Endpoint.COPY_PUBLIC_SUFIX}`, formularPublicCopy, axiosConfig(authData!.token));

  const axiosGetRepositoryPoljePanelList = (formularId: number) => {
    return axios.get(`${Endpoint.REPOSITORY_FORMULAR_LIST}/${formularId}/polje-panel-list`, axiosConfig(authData!.token));
  };

  return {
    axiosGetRepositoryFormularList,
    axiosSearchRepositoryFormular,
    axiosCreateRepositoryFormular,
    axiosUpdateRepositoryFormular,
    axiosDeleteRepositoryFormular,
    axiosGetRepositoryFormular,
    axiosGetRepositoryPoljeList,
    axiosDeleteRepositoryPolje,
    axiosCreateRepositoryPolje,
    axiosUpdateRepositoryPolje,
    axiosReadRepositoryPolje,
    axiosGetRepositoryFormularListForKontaktTip,
    axiosPublicCopyRepositoryFormular,
    axiosPublicSearchRepositoryFormular,
    axiosGetRepositoryPoljePanelList,
  };
}
