import { Dropdown } from "primereact/dropdown";
import { useLabels } from "../../../../Store";
import { PoljeType } from "../Polje";

export default function Skupovni(props: PoljeType) {
  const { id, onChange, vrednost, readonly, enumStavke, useVrednostList, vrednostList } = props;
  const Labels = useLabels();

  return (
    <Dropdown
      filter
      disabled={readonly ?? false}
      options={enumStavke}
      showClear
      optionValue="id"
      optionLabel="naziv"
      className="w-full"
      onChange={(e) => {
        onChange({ id: id, vrednost: e.value });
      }}
      value={useVrednostList && vrednostList ? vrednostList[id] ?? null : vrednost ?? null}
      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
    />
  );
}
