import { CheckboxChangeEvent } from "primereact/checkbox";
import { InputNumberChangeEvent } from "primereact/inputnumber";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import KontaktPaketCreateDto from "../../../../../model/kontakt/KontaktPaketCreateDto";

interface KontaktPaketDialogLogicalType {
  addOrRemoveItem: (prevValue: any, e: CheckboxChangeEvent, field: string) => typeof prevValue;
  updateKolicinaUslugeProizvodi: (prevValue: any, uslugaId: number | null, proizvodId: number | null, e: InputNumberChangeEvent) => void;
}

export default function KontaktPaketDialogLogical(
  dialogAction: string,
  kontaktPaketChange: KontaktPaketCreateDto | undefined,
  setKontaktPaketChange: React.SetStateAction<any>
): KontaktPaketDialogLogicalType {
  const addOrRemoveItem = (prevValue: any, e: CheckboxChangeEvent, field: string = "") => {
    if (e.target.checked) {
      if (field === "usluga") {
        return [...prevValue, { id: e.target.value.id, sifarnikUsluga: { id: e.value.sifarnikUsluga.id }, brojPruzanja: e.target.value.brojPruzanja, napomena: e.target.value.napomena }];
      } else {
        return [
          ...prevValue,
          { id: e.target.value.id, sifarnikProizvoda: { id: e.value.sifarnikProizvoda.id }, utrosenaKolicina: e.target.value.podrazumevanaUtrosenaKolicina, napomena: e.target.value.napomena },
        ];
      }
    } else {
      if (dialogAction === EntityOperation.CREATE) {
        return prevValue.filter((item: { id: number }) => item.id !== e.target.value.id);
      } else {
        if (field === "usluga") {
          return prevValue.filter((item: any) => item.sifarnikUsluga.id !== e.target.value.sifarnikUsluga.id);
        } else {
          return prevValue.filter((item: any) => item.sifarnikProizvoda.id !== e.target.value.sifarnikProizvoda.id);
        }
      }
    }
  };

  const updateKolicinaUslugeProizvodi = (prevValue: any, uslugaId: number | null = null, proizvodId: number | null = null, e: InputNumberChangeEvent) => {
    let currentItems = [...prevValue];
    const itemId = uslugaId ? uslugaId : proizvodId;
    if (dialogAction === EntityOperation.CREATE) {
      currentItems.map((item) => {
        if (item.id === itemId) {
          if (uslugaId) {
            item.brojPruzanja = e.value;
          } else {
            item.utrosenaKolicina = e.value;
          }
        }
        return item;
      });
    } else {
      currentItems.map((item) => {
        if (uslugaId && item.sifarnikUsluga.id === itemId) {
          item.brojPruzanja = e.value;
        } else if (proizvodId && item.sifarnikProizvoda.id === itemId) {
          item.utrosenaKolicina = e.value;
        }
        return item;
      });
    }

    if (uslugaId) {
      setKontaktPaketChange({
        ...kontaktPaketChange!,
        kontaktUslugaList: currentItems,
      });
    } else {
      setKontaktPaketChange({
        ...kontaktPaketChange!,
        kontaktProizvodList: currentItems,
      });
    }
  };

  return { addOrRemoveItem, updateKolicinaUslugeProizvodi };
}
