import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useContext, useMemo } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { formatHour } from "../../../../infrastructure/system/Utils";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import RadniPeriodReadDto from "../../../../model/radni-period/RadniPeriodReadDto";
import ZakazanTerminReadDto from "../../../../model/zakazan-termin/ZakazanTerminReadDto";
import { TerminType } from "../termin/Termin";
import RadniDanGridLogical from "./RadniDanGridLogical";
import RadniDan from "./radni-dan/RadniDan";
import EnumTerminTip from "../../../../infrastructure/system/EnumTerminTip";

interface RadniDaniGridProps {
  zakazaniTerminList: Array<ZakazanTerminReadDto>;
  radniPeriodList: Array<RadniPeriodReadDto>;
  trajanjeTermina: number;
  radniDani: Array<moment.Moment>;
  dataLoading: boolean;
  otvoriDialogTermin: (vreme: TerminType) => void;
  vertical: boolean;
  radnoVremeOdOrgJed: moment.Moment;
  radnoVremeDoOrgJed: moment.Moment;
  closeTerminChangeDialog: () => void;
  changeTerminTime: () => void;
  handleDragStart: (event: React.DragEvent<HTMLDivElement>, termin: TerminType) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>, canBeDraggedOver: boolean) => void;
  handleDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>, terminCanBeDropped: boolean, termin: TerminType) => void;
  showTerminChangeDialog: boolean;
  dragging: boolean;
  makeSatnicaList: (
    zakazaniTerminList: TerminType[],
    radniDanPocetak: moment.Moment,
    radniDanKraj: moment.Moment,
    radniPeriodList: TerminType[],
    trajanjeTermina: number,
    vertical: boolean
  ) => TerminType[];
  selectedPacijent?: PacijentReadDto;
}

export default function RadniDaniGrid({
  zakazaniTerminList,
  radniPeriodList,
  trajanjeTermina,
  radniDani,
  dataLoading,
  otvoriDialogTermin,
  vertical,
  radnoVremeOdOrgJed,
  radnoVremeDoOrgJed,
  closeTerminChangeDialog,
  changeTerminTime,
  handleDragStart,
  handleDragOver,
  handleDragLeave,
  handleDrop,
  showTerminChangeDialog,
  dragging,
  makeSatnicaList,
  selectedPacijent,
}: RadniDaniGridProps) {
  const { showBlockUI } = useContext(AppContext);
  const { pocetakRadaHour, pocetakRadaMinute, krajRadaHour, krajRadaMinute, hours, setHoursAndMinutes, setMinutes } = RadniDanGridLogical({
    radnoVremeDoOrgJed,
    radnoVremeOdOrgJed,
    vertical,
  });

  const Labels = useLabels();

  const footer = (
    <div className="flex justify-content-between">
      <Button label={Labels.ZAKAZIVANJE_PROMENA_TERMINA_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={closeTerminChangeDialog} />
      <Button label={Labels.ZAKAZIVANJE_PROMENA_TERMINA_PROMENI} icon="pi pi-check" onClick={changeTerminTime} />
    </div>
  );

  const startHoursStyle = useMemo(() => {
    return { height: (60 - pocetakRadaMinute) * 0.25 + "rem" };
  }, [pocetakRadaMinute]);

  const endHoursStyle = useMemo(() => {
    return { height: krajRadaMinute * 0.25 + "rem" };
  }, [krajRadaMinute]);

  const calculateHeight = (vremeZakazanoOd: Date, vremeZakazanoDo: Date) => {
    const endDate = setHoursAndMinutes(moment(vremeZakazanoOd), krajRadaHour, krajRadaMinute);
    const startDate = setHoursAndMinutes(moment(vremeZakazanoOd), pocetakRadaHour, pocetakRadaMinute);
    return 1.25 * (moment.duration(moment.min(moment(vremeZakazanoDo), endDate).diff(moment.max(moment(vremeZakazanoOd), startDate))).asMinutes() / 5);
  };

  const calculateMarginTop = (vremeZakazanoOd: Date) => {
    const startDate = setHoursAndMinutes(moment(vremeZakazanoOd), pocetakRadaHour, pocetakRadaMinute);
    const diff = Math.max(moment.duration(moment.max(moment(vremeZakazanoOd), startDate).diff(startDate)).asMinutes() / 5);
    return diff * 1.25;
  };
  return (
    <>
      <div className="hc-scheduler-appointments">
        {!showBlockUI && dataLoading ? (
          <div className="termin-loading-spinner-wrapper">
            <ProgressSpinner className="termin-loading-spinner" />
          </div>
        ) : (
          <></>
        )}
        <div className="hc-scheduler-row">
          {vertical && (
            <div className="hours-grid">
              <div className="hour-container" style={startHoursStyle}>
                <div className="hour-label">{formatHour(pocetakRadaHour)}</div>
                <div className="hour-line" />
              </div>

              {hours.map((hour: number) => (
                <div key={hour} className={"hour-container"}>
                  <div className="hour-label">{formatHour(hour)}</div>
                  <div className="hour-line" />
                </div>
              ))}

              <div className="hour-container" style={endHoursStyle}>
                <div className="hour-label">{formatHour(krajRadaHour)}</div>
                <div className="hour-line" />
              </div>
            </div>
          )}

          <div className={`w-full ${vertical && "flex"}`}>
            {radniDani?.map((datum, index) => (
              <div className="hc-scheduler-row-hours-cell relative" key={index}>
                <RadniDan
                  radniPeriodList={radniPeriodList.flatMap((radniPeriod: RadniPeriodReadDto) => {
                    return moment(radniPeriod.periodPocetak).isSame(datum, "day")
                      ? [{ napomena: radniPeriod.napomena, vremeZakazanoOd: setMinutes(radniPeriod.periodPocetak), vremeZakazanoDo: setMinutes(radniPeriod.periodKraj) }]
                      : [];
                  })}
                  radniDanPocetak={setHoursAndMinutes(datum, pocetakRadaHour, pocetakRadaMinute)}
                  radniDanKraj={setHoursAndMinutes(datum, krajRadaHour, krajRadaMinute)}
                  trajanjeTermina={trajanjeTermina}
                  zakazaniTerminList={zakazaniTerminList?.flatMap((zakazaniTermin: ZakazanTerminReadDto) => {
                    return moment(zakazaniTermin.vremeZakazanoOd).isSame(datum, "day")
                      ? [{ ...zakazaniTermin, vremeZakazanoOd: setMinutes(zakazaniTermin?.vremeZakazanoOd as Date), vremeZakazanoDo: setMinutes(zakazaniTermin?.vremeZakazanoDo as Date) }]
                      : [];
                  })}
                  otvoriDialogTermin={otvoriDialogTermin}
                  vertical={vertical}
                  hours={hours}
                  handleDragStart={handleDragStart}
                  handleDragOver={handleDragOver}
                  handleDragLeave={handleDragLeave}
                  handleDrop={handleDrop}
                  dragging={dragging}
                  makeSatnicaList={makeSatnicaList}
                  selectedPacijent={selectedPacijent}
                />
                {vertical &&
                  zakazaniTerminList
                    ?.filter((termin: ZakazanTerminReadDto) => termin.zakazanTerminTip?.sifra === EnumTerminTip.VANREDAN && moment(termin.vremeZakazanoOd).isSame(datum, "day"))
                    .map((termin: ZakazanTerminReadDto, index: number, list: Array<ZakazanTerminReadDto>) => {
                      const isOverlappingVremeOd = list.some((other: ZakazanTerminReadDto, otherIndex: number) => {
                        if (index !== otherIndex) {
                          return moment(termin.vremeZakazanoOd).isBetween(other.vremeZakazanoOd, other.vremeZakazanoDo, undefined, "(]");
                        }
                        return false;
                      });

                      const isOverlappingVremeDo = list.some((other: ZakazanTerminReadDto, otherIndex: number) => {
                        if (index !== otherIndex) {
                          return moment(termin.vremeZakazanoDo).isBetween(other.vremeZakazanoOd, other.vremeZakazanoDo, undefined, "[)");
                        }
                        return false;
                      });

                      return (
                        <div
                          key={termin.id}
                          className={`absolute top-0 right-0 w-1rem border-left-1`}
                          style={{
                            backgroundColor: "#fde9e3",
                            marginTop: `${calculateMarginTop(termin?.vremeZakazanoOd as Date)}rem`,
                            height: `${calculateHeight(termin?.vremeZakazanoOd as Date, termin?.vremeZakazanoDo as Date)}rem`,
                            borderTop: !isOverlappingVremeOd ? "1px solid black" : "",
                            borderBottom: !isOverlappingVremeDo ? "1px solid black" : "",
                          }}
                        ></div>
                      );
                    })}
              </div>
            ))}
          </div>

          <div className="hc-scheduler-row-hours-line" />
        </div>
      </div>
      <Dialog
        visible={showTerminChangeDialog}
        className="dialog-termin-change dialog-termin-change-container"
        closable={false}
        onHide={closeTerminChangeDialog}
        header={Labels.ZAKAZIVANJE_PROMENA_TERMINA_DIALOG_POTVRDA}
        footer={footer}
      />
    </>
  );
}
