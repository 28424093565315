import { PoljeType } from "../Polje";
import { MultiSelect } from "primereact/multiselect";

export default function Multiselect(props: PoljeType) {
  const { id, onChange, vrednost, enumStavke, vrednostList, readonly } = props;

  return (
    <>
      <MultiSelect
        value={(vrednostList && vrednostList[id]) ?? vrednost ?? null}
        onChange={(e) => {
          onChange({ id: id, vrednost: e.value });
        }}
        options={enumStavke}
        optionLabel="naziv"
        display="chip"
        disabled={readonly}
        className="w-full"
      />
    </>
  );
}
