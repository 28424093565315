import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { useMemo } from "react";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import PaketUslugaCreateDto from "../../../../model/paket/PaketUslugaCreateDto";
import PaketUslugaReadDto from "../../../../model/paket/PaketUslugaReadDto";
import SifarnikUslugaDto from "../../../../model/sifarnik/SifarnikUslugaDto";
import DialogComponent from "../../../dialog-component/DialogComponent";

interface DialogPaketUslugaType {
  show: boolean;
  closeDialog: () => void;
  paketUslugaChange: PaketUslugaCreateDto | undefined;
  setPaketUslugaChange: any;
  searchSifarnikUsluga: (e: AutoCompleteCompleteEvent) => void;
  sifarnikUslugaList: Array<SifarnikUslugaDto>;
  dialogAction: string;
  paketUsluga: PaketUslugaReadDto | undefined;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
}

export default function DialogPaketUsluga(props: DialogPaketUslugaType) {
  const Labels = useLabels();
  const { show, closeDialog, paketUslugaChange, setPaketUslugaChange, searchSifarnikUsluga, sifarnikUslugaList, onCreate, dialogAction, paketUsluga, onDelete, onUpdate } = props;

  const renderFooter = () => {
    return (
      <div className="flex justify-content-end">
        <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={closeDialog} />
        {dialogAction === EntityOperation.CREATE && <Button label={Labels.USLUGA_DODAVANJE} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />}
        {dialogAction === EntityOperation.UPDATE && <Button label={Labels.USLUGA_IZMENI} icon="pi pi-pencil" className="p-button-warning" onClick={onUpdate} />}
        {dialogAction === EntityOperation.DELETE && <Button label={Labels.USLUGA_OBRISI} icon="pi pi-trash" className="p-button-danger" onClick={onDelete} />}
      </div>
    );
  };

  const renderTitle = () => {
    switch (dialogAction) {
      case EntityOperation.CREATE:
        return Labels.PAKET_USLUGA_DIALOG_USLUGA_CREATE_TITLE;
      case EntityOperation.UPDATE:
        return Labels.PAKET_USLUGA_DIALOG_USLUGA_UPDATE_TITLE;
      case EntityOperation.DELETE:
        return Labels.PAKET_USLUGA_DIALOG_USLUGA_DELETE_TITLE;
      default:
        return "";
    }
  };

  const autocompleteStyles = useMemo(() => {
    return { paddingLeft: "2rem", width: "100%" };
  }, []);

  return (
    <DialogComponent
      header={renderTitle}
      visible={show}
      footer={renderFooter}
      onHide={closeDialog}
      blockScroll={true}
      className="w-6 lg:w-5 xl:w-4"
      content={
        <>
          <div className="flex flex-column justify-content-center p-3">
            <div className="grid align-items-center p-0 py-3">
              <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3">{Labels.PAKET_USLUGA_LABEL_USLUGA}</div>
              <div className="col-12 md:col-8 p-fluid p-0">
                <span className="p-input-icon-left w-full">
                  <AutoComplete
                    value={paketUslugaChange?.sifarnikUslugaFull}
                    itemTemplate={(e) => `${e.sifra} ${e.naziv}`}
                    selectedItemTemplate={(e) => `${e.sifra} ${e.naziv}`}
                    suggestions={sifarnikUslugaList}
                    completeMethod={searchSifarnikUsluga}
                    inputStyle={autocompleteStyles}
                    field="sifarnikUsluga"
                    onChange={(e) => {
                      setPaketUslugaChange({
                        ...paketUslugaChange!,
                        sifarnikUslugaFull: e.value,
                        sifarnikUsluga: { id: e.value.id },
                      });
                    }}
                    className="w-full block"
                    placeholder={
                      dialogAction === EntityOperation.DELETE || dialogAction === EntityOperation.UPDATE
                        ? `${paketUsluga?.sifarnikUsluga?.sifra} ${paketUsluga?.sifarnikUsluga?.naziv}`
                        : `${Labels.PAKET_USLUGA_UNOS_USLUGE_PLACEHOLDER}`
                    }
                    disabled={dialogAction === EntityOperation.DELETE || dialogAction === EntityOperation.UPDATE}
                    minLength={3}
                  />
                  <i className="pi pi-search p-icon-left" />
                </span>
              </div>
            </div>
            <div className="grid align-items-center p-0 py-3">
              <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3">{Labels.PAKET_USLUGA_LABEL_PRIKAZANI_REDOSLED}</div>
              <div className="col-3 md:col-2 p-fluid p-0">
                <InputNumber
                  value={paketUslugaChange?.prikazniRedosled}
                  onChange={(e) => {
                    setPaketUslugaChange({
                      ...paketUslugaChange!,
                      prikazniRedosled: e.value,
                    });
                  }}
                  disabled={dialogAction === EntityOperation.DELETE}
                />
              </div>
            </div>
            <div className="grid align-items-center p-0 py-3">
              <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3">{Labels.PAKET_USLUGA_LABEL_PODESI}</div>
              <div className="col-12 md:col-8 p-fluid p-0">
                <InputSwitch
                  checked={paketUslugaChange?.podrazumevanoOdabrana ?? false}
                  onChange={(e) => {
                    setPaketUslugaChange({
                      ...paketUslugaChange!,
                      podrazumevanoOdabrana: e.target.value,
                    });
                  }}
                  disabled={dialogAction === EntityOperation.DELETE}
                />
              </div>
            </div>
          </div>
        </>
      }
    />
  );
}
