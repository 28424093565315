import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import SifarnikNacinaPlacanjaReadDto from "../../../model/sifarnik/sifarnik-nacina-placanja/SifarnikNacinPlacanjaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import SifarnikNacinaPlacanjaListLogical from "./SifarnikNacinaPlacanjaListLogical";

export default function SifarnikNacinaPlacanjaListView() {
  const { exportData, dt, sifarnikNacinaPlacanjaList, first, tableRows, onPage, openSifarnikNacinaPlacanja, sifarnikNacinaPlacanjaLoading, breadCrumbItems, vrstaUplatiocaList } =
    SifarnikNacinaPlacanjaListLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const addButton = () => (
    <Button
      label={Labels.SIFARNIK_NACINA_PLACANJA_DODAJ_NACIN_PLACANJA}
      tooltip={Labels.SIFARNIK_NACINA_PLACANJA_TITLE_CREATE}
      tooltipOptions={tooltipOptionsBottom}
      className="p-button-success mr-3 mb-4 sm:mb-4 md:mb-0"
      onClick={() => {
        openSifarnikNacinaPlacanja(EntityOperation.CREATE);
      }}
      disabled={!pristup}
    />
  );

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={sifarnikNacinaPlacanjaLoading} data={exportData} name={Labels.LABEL_SIFARNIK_NACINI_PLACANJA} dt={dt} />
      </>
    );
  };

  const crudBodyTemplate = (rowSifarnikNacinaPlacanja: SifarnikNacinaPlacanjaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_NACINA_PLACANJA_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowSifarnikNacinaPlacanja && openSifarnikNacinaPlacanja(EntityOperation.UPDATE, rowSifarnikNacinaPlacanja.id)}
        />
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_NACINA_PLACANJA_TITLE_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowSifarnikNacinaPlacanja && openSifarnikNacinaPlacanja(EntityOperation.DELETE, rowSifarnikNacinaPlacanja.id)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const brzoPlacanjeBody = (rowSifarnikNacinaPlacanja: SifarnikNacinaPlacanjaReadDto) => {
    return <div>{rowSifarnikNacinaPlacanja.brzoPlacanje ? <i className="pi pi-check text-2xl"></i> : ""}</div>;
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.SIFARNIK_NACINA_PLACANJA_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_NACINA_PLACANJA_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_NACINA_PLACANJA_PRIKAZNI_REDOSLED, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_NACINA_PLACANJA_VRSTA_UPLATIOCA, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE, filter: false, sortrable: false },
  ];

  const vrstaUplatiocaFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={vrstaUplatiocaList.map((e) => {
          return { label: e.naziv, value: e.naziv };
        })}
        onChange={(e) => dt.current.filter(e.value, "vrstaUplatioca.naziv", "equals")}
        className="p-column-filter"
        showClear
      />
    );
  };

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar start={addButton} end={downloadButton} />
        {sifarnikNacinaPlacanjaLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={sifarnikNacinaPlacanjaList ?? []}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e: DataTablePageEvent) => onPage(e.rows, e.first)}
            exportFilename={Labels.LABEL_SIFARNIK_NACINI_PLACANJA}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.SIFARNIK_NACINA_PLACANJA_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.SIFARNIK_NACINA_PLACANJA_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"prikazniRedosled"} header={Labels.SIFARNIK_NACINA_PLACANJA_PRIKAZNI_REDOSLED} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column
              field={"vrstaUplatioca.naziv"}
              header={Labels.SIFARNIK_NACINA_PLACANJA_VRSTA_UPLATIOCA}
              filter
              filterMatchMode="contains"
              showFilterMenu={false}
              sortable
              filterElement={vrstaUplatiocaFilterTemplate}
            />
            <Column body={brzoPlacanjeBody} header={Labels.SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE} />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
