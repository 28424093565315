import axios from "axios";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { KontaktController } from "../../controllers/kontakt/KontaktController";
import { PacijentPoljeController } from "../../controllers/pacijent-polje/PacijentPoljeController";
import { PacijentController } from "../../controllers/pacijent/PacijentController";
import EnumPacijentPoljeKategorija from "../../infrastructure/system/EnumPacijentPoljeKategorija";
import useLogHighLevel from "../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../infrastructure/system/MessageType";
import { handleAxiosCallError, handleScreenSizeChange, MINIMAL_PANEL_WIDTH_FOR_PACIJENT_INFO, skeletonTimeout, useEffectOnce } from "../../infrastructure/system/Utils";
import KontaktDosijeSimpleDto from "../../model/kontakt/KontaktDosijeSimpleDto";
import PacijentPoljePopunjeno from "../../model/pacijent-polje/PacijentPoljePopunjeno";

import PacijentReadDto from "../../model/pacijent/PacijentReadDto";
import { AppContext, useLabels } from "../../Store";

interface PacijentDosijeLogicalType {
  pacijent?: PacijentReadDto;
  setPacijent?: React.Dispatch<React.SetStateAction<PacijentReadDto | undefined>>;
  kontaktList: Array<KontaktDosijeSimpleDto>;
  setKontaktList: React.Dispatch<React.SetStateAction<KontaktDosijeSimpleDto[]>>;
  showVerticalPacijentInfo: boolean;
  setShowVerticalPacijentInfo: React.Dispatch<React.SetStateAction<boolean>>;
  mainPanelDiv: any;
  pacijentAndKontaktListLoading: boolean;
  id: number;
  setShowDeletePacijentDialog: React.Dispatch<React.SetStateAction<boolean>>;
  showDeletePacijentDialog: boolean;
  deletePacijent: () => void;
  medicinskiPodaci: Array<PacijentPoljePopunjeno>;
  informacijeOPacijentuList: Array<PacijentPoljePopunjeno> | undefined;
  pacijentPoljePopunjenoListLoading: boolean;
  fetchMedicinskiPodaciAndInformacijeOPacijentu: () => void;
}
interface useParamsType {
  id?: string;
}
export default function PacijentDosijeLogical(): PacijentDosijeLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { axiosGetPacijentById } = PacijentController();
  const { axiosGetPacijentPoljePopunjeno } = PacijentPoljeController();
  const { axiosKontaktSearchSimple } = KontaktController();
  const { axiosDeletePacijent } = PacijentController();
  const [showVerticalPacijentInfo, setShowVerticalPacijentInfo] = useState<boolean>(true);
  const Labels = useLabels();
  const mainPanelDiv: any = useRef();

  const [pacijent, setPacijent] = useState<PacijentReadDto>();
  const [kontaktList, setKontaktList] = useState<Array<KontaktDosijeSimpleDto>>([]);
  const [medicinskiPodaci, setMedicinskiPodaci] = useState<Array<PacijentPoljePopunjeno>>([]);
  const [showDeletePacijentDialog, setShowDeletePacijentDialog] = useState<boolean>(false);
  const [pacijentAndKontaktListLoading, setPacijentAndKontaktListLoading] = useState<boolean>(true);
  const [informacijeOPacijentuList, setInformacijeOPacijentuList] = useState<Array<PacijentPoljePopunjeno>>();
  const [pacijentPoljePopunjenoListLoading, setPacijentPoljePopunjenoListLoading] = useState<boolean>(true);
  const prevPacijent = useRef<PacijentReadDto | undefined>(undefined);
  const navigate = useNavigate();
  const id: number = Number(useParams<keyof useParamsType>()["id"]);
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_PRISTUP_DOSIJE_PACIJENT, id);
    setShowVerticalPacijentInfo(handleScreenSizeChange(mainPanelDiv, MINIMAL_PANEL_WIDTH_FOR_PACIJENT_INFO));
    const startTime = moment(new Date());
    axios
      .all([axiosGetPacijentById(id), axiosKontaktSearchSimple({ pacijentID: id }), axiosGetPacijentPoljePopunjeno(EnumPacijentPoljeKategorija.OPSTI_MEDICINSKI_PODACI, id)])
      .then(
        axios.spread(async (responsePacijent: any, responseKontaktList, responseMedicinskeInformacije: any) => {
          setPacijent(responsePacijent.data.data);
          setKontaktList(responseKontaktList.data.data);
          setMedicinskiPodaci(responseMedicinskeInformacije.data.data);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setPacijentAndKontaktListLoading, startTime);
      });
  });

  const deletePacijent = () => {
    if (pacijent) {
      setShowBlockUI(true);
      axiosDeletePacijent(pacijent?.id)
        .then(() => {
          setShowDeletePacijentDialog(false);
          showMessage(MessageType.SUCCESS, Labels.PACIJENT_USPESNO_OBRISAN);
          navigate("/");
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const fetchData = useCallback(() => {
    let startTime = moment(new Date());

    pacijent &&
      axiosGetPacijentPoljePopunjeno(EnumPacijentPoljeKategorija.INFORMACIJE_O_PACIJENTU, pacijent?.id)
        .then((responseInformacijeOPacijentu: any) => {
          setInformacijeOPacijentuList(
            responseInformacijeOPacijentu.data.data.sort((a: PacijentPoljePopunjeno, b: PacijentPoljePopunjeno) => a?.pacijentPolje.prikazniRedosled - b?.pacijentPolje.prikazniRedosled)
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPacijentPoljePopunjenoListLoading, startTime);
        });
  }, [axiosGetPacijentPoljePopunjeno, showMessage, pacijent]);

  useEffect(() => {
    if (pacijent && pacijent?.id && prevPacijent.current !== pacijent) {
      fetchData();
      prevPacijent.current = pacijent;
    }
  }, [pacijent, fetchData]);

  const fetchMedicinskiPodaciAndInformacijeOPacijentu = () => {
    axios
      .all([
        axiosGetPacijentPoljePopunjeno(EnumPacijentPoljeKategorija.OPSTI_MEDICINSKI_PODACI, pacijent!.id),
        axiosGetPacijentPoljePopunjeno(EnumPacijentPoljeKategorija.INFORMACIJE_O_PACIJENTU, pacijent!.id),
      ])
      .then(
        axios.spread((responseMedicinskiPodaci, responseInformacijeOPacijentu) => {
          setMedicinskiPodaci(responseMedicinskiPodaci.data.data);
          setInformacijeOPacijentuList(responseInformacijeOPacijentu.data.data);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return {
    pacijent,
    setPacijent,
    kontaktList,
    setKontaktList,
    showVerticalPacijentInfo,
    setShowVerticalPacijentInfo,
    mainPanelDiv,
    pacijentAndKontaktListLoading,
    id,
    setShowDeletePacijentDialog,
    showDeletePacijentDialog,
    deletePacijent,
    medicinskiPodaci,
    informacijeOPacijentuList,
    pacijentPoljePopunjenoListLoading,
    fetchMedicinskiPodaciAndInformacijeOPacijentu,
  };
}
