import axios from "axios";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { SifarnikJedinicaMereController } from "../../../controllers/sifarnik-jedinica-mere/SifarnikJedinicaMereController";
import { SifarnikProizvodaController } from "../../../controllers/sifarnik-proizvoda/SifarnikProizvodaController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { formatDate2, handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import SifarnikJedinicaMereReadDto from "../../../model/sifarnik/sifarnik-jedinica-mere/SifarnikJedinicaMereReadDto";
import SifarnikProizvodaReadDto from "../../../model/sifarnik/sifarnik-proizvoda/SifarnikProizvodaReadDto";
import SifarnikProizvodaRFZOReadDto from "../../../model/sifarnik/sifarnik-proizvoda/SifarnikProizvodaRFZOReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudSifarnikProizvodaLogicalType {
  sifarnikProizvodaOperation: string;
  isDisabled: boolean;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  sifarnikProizvodaChange: SifarnikProizvodaReadDto | undefined;
  setSifarnikProizvodaChange: any;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  sifarnikProizvodaId: number | undefined;
  location: any;
  onPageFormTable: (rows: number, first: number) => void;
  first: number;
  tableRows: number;
  sifarnikProizvodaRFZOList: Array<SifarnikProizvodaRFZOReadDto>;
  sifarnikJedinicaMereList: Array<SifarnikJedinicaMereReadDto>;
  sifarnikProizvodaLoading: boolean;
  sifarnikProizvodaDataOptionsLoading: boolean;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  vaziOdRef: HTMLDivElement | any;
  vaziDoRef: HTMLDivElement | any;
}

interface UseParamsType {
  sifarnikProizvodaId?: string;
}
export default function CrudSifarnikProizvodaLogical(): CrudSifarnikProizvodaLogicalType {
  const { showMessage, authData, setShowBlockUI } = useContext(AppContext);
  const navigate = useNavigate();
  const sifarnikProizvodaId = Number(useParams<keyof UseParamsType>()["sifarnikProizvodaId"]);
  const location = useLocation();
  const [sifarnikProizvodaOperation, setsifarnikProizvodaOperation] = useState(
    location.state && location.state.sifarnikProizvodaOperation !== undefined ? location.state.sifarnikProizvodaOperation : EntityOperation.UPDATE
  );
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [sifarnikProizvodaRFZOList, setSifarnikProizvodaRFZOList] = useState<Array<SifarnikProizvodaRFZOReadDto>>([]);
  const [sifarnikJedinicaMereList, setSifarnikJedinicaMereList] = useState<Array<SifarnikJedinicaMereReadDto>>([]);
  const Labels = useLabels();
  const [isDisabled] = useState(isFormDisabled(sifarnikProizvodaOperation));
  const [sifarnikProizvodaChange, setSifarnikProizvodaChange] = useState<SifarnikProizvodaReadDto | undefined>();
  const [index, setIndex] = useState<number>(tabIndex);
  const { axiosCreateSifarnikProizvoda, axiosUpdateSifarnikProizvoda, axiosDeleteSifarnikProizvoda, axiosReadSifarnikProizvoda, axiosReadSifarnikProizvodaRFZOList } = SifarnikProizvodaController();
  const { axiosGetSifarnikJedinicaMereList } = SifarnikJedinicaMereController();
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [sifarnikProizvodaDataOptionsLoading, setSifarnikProizvodaDataOptionsLoading] = useState(true);
  const [sifarnikProizvodaLoading, setSifarnikProizvodaLoading] = useState(true);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const vaziOdRef = useRef<HTMLDivElement | any>(null);
  const vaziDoRef = useRef<HTMLDivElement | any>(null);

  const postLogHighLevel = useLogHighLevel();

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.LABEL_SIFARNIK_PROIZVODA,
      command: () => {
        navigate("/sifarnik-proizvoda-list/");
      },
    },
  ]);

  useEffectOnce(() => {
    let startTime = moment(new Date());
    if (sifarnikProizvodaOperation === EntityOperation.CREATE) {
      setSifarnikProizvodaChange({
        ...sifarnikProizvodaChange!,
      });
    }

    const requestSifarnikProizvodaRFZO = axiosReadSifarnikProizvodaRFZOList();
    const requestSifarnikJedinicaMere = axiosGetSifarnikJedinicaMereList();
    axios
      .all([requestSifarnikProizvodaRFZO, requestSifarnikJedinicaMere])
      .then(
        axios.spread((responseSifarnikProizvodaRFZO: any, responseSifarnikJedinicaMere: any) => {
          setSifarnikProizvodaRFZOList(responseSifarnikProizvodaRFZO.data.data);
          setSifarnikJedinicaMereList(responseSifarnikJedinicaMere.data.data);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSifarnikProizvodaDataOptionsLoading, startTime);
      });
    fetchData();
  });

  const fetchData = (ppId?: number) => {
    let newSifarnikProizvodaId = ppId ? ppId : sifarnikProizvodaId;
    let startTime = moment(new Date());
    if (newSifarnikProizvodaId) {
      const requestSifarnikProizvoda = axiosReadSifarnikProizvoda(newSifarnikProizvodaId);
      axios
        .all([requestSifarnikProizvoda])
        .then(
          axios.spread((responseSifarnikProizvoda: any) => {
            setSifarnikProizvodaChange(responseSifarnikProizvoda.data.data);
            if (breadCrumbItems.length < 2) {
              setBreadCrumbItems([
                ...breadCrumbItems,
                {
                  label:
                    sifarnikProizvodaOperation === EntityOperation.UPDATE
                      ? Labels.SIFARNIK_PROIZVODA_TITLE_DIALOG_UPDATE + responseSifarnikProizvoda.data.data.naziv
                      : sifarnikProizvodaOperation === EntityOperation.READ
                      ? Labels.SIFARNIK_PROIZVODA_TITLE_DIALOG_DETAILS + responseSifarnikProizvoda.data.data.naziv
                      : Labels.SIFARNIK_PROIZVODA_TITLE_DIALOG_DELETE + responseSifarnikProizvoda.data.data.naziv,
                },
              ]);
            }
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setSifarnikProizvodaLoading, startTime);
        });
    } else {
      setSifarnikProizvodaLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.SIFARNIK_PROIZVODA_TITLE_DIALOG_CREATE,
        },
      ]);
    }
  };

  const validateInput = (sifarnikProizvoda: SifarnikProizvodaReadDto | undefined) => {
    let isInvalid = false;
    if (sifarnikProizvoda === undefined || validateStringEmpty(sifarnikProizvoda?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(sifarnikProizvoda?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }
    if (sifarnikProizvoda?.vaziOd === undefined) {
      setInvalidFields((prev) => ({ ...prev, vaziOd: true }));
      isInvalid = true;
    }
    if (sifarnikProizvoda?.vaziDo !== undefined && sifarnikProizvoda?.vaziOd > sifarnikProizvoda?.vaziDo) {
      setInvalidFields((prev) => ({ ...prev, invalidPeriod: true }));
      isInvalid = true;
    }
    if (sifarnikProizvoda?.sifarnikJedinicaMere === undefined) {
      setInvalidFields((prev) => ({ ...prev, jedinicaMere: true }));
      isInvalid = true;
    }
    if (sifarnikProizvoda === undefined || sifarnikProizvoda?.naziv?.length < 3) {
      setInvalidFields((prev) => ({ ...prev, nazivLength: true }));
      isInvalid = true;
    }
    if (sifarnikProizvoda === undefined || sifarnikProizvoda?.sifra?.length < 3) {
      setInvalidFields((prev) => ({ ...prev, sifraLength: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(sifarnikProizvodaChange)) {
      return;
    }
    if (sifarnikProizvodaChange) {
      setShowBlockUI(true);
      axiosCreateSifarnikProizvoda({ ...sifarnikProizvodaChange, ustanova: authData!.currentRadnik.ustanovaPoslednjaIzabrana })
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.SIFARNIK_PROIZVODA_TITLE_MESSAGE_CREATE_SIFARNIK_PROIZVODA_SUCCESS);
          setsifarnikProizvodaOperation(EntityOperation.UPDATE);
          navigate("/sifarnik-proizvoda-list");
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_1 +
              sifarnikProizvodaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_2 +
              sifarnikProizvodaChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_3 +
              sifarnikProizvodaChange?.sifarnikJedinicaMere.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_4 +
              formatDate2(sifarnikProizvodaChange?.vaziOd)
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(sifarnikProizvodaChange)) {
      return;
    }
    if (sifarnikProizvodaChange) {
      setShowBlockUI(true);
      axiosUpdateSifarnikProizvoda(sifarnikProizvodaChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.SIFARNIK_PROIZVODA_TITLE_MESSAGE_UPDATE_SIFARNIK_PROIZVODA_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_1 +
              sifarnikProizvodaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_2 +
              sifarnikProizvodaChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_3 +
              sifarnikProizvodaChange?.sifarnikJedinicaMere.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_4 +
              sifarnikProizvodaChange?.vaziOd
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeleteSifarnikProizvoda(sifarnikProizvodaId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.SIFARNIK_PROIZVODA_TITLE_MESSAGE_DELETE_SIFARNIK_PROIZVODA_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_1 +
            sifarnikProizvodaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_2 +
            sifarnikProizvodaChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_3 +
            sifarnikProizvodaChange?.sifarnikJedinicaMere.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_4 +
            sifarnikProizvodaChange?.vaziOd
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/sifarnik-proizvoda-list`);
    }
  };
  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    sifarnikProizvodaOperation,
    isDisabled,
    sifarnikProizvodaChange,
    breadCrumbItems,
    setSifarnikProizvodaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    sifarnikProizvodaId,
    location,
    first,
    tableRows,
    onPageFormTable,
    sifarnikProizvodaRFZOList,
    sifarnikJedinicaMereList,
    sifarnikProizvodaDataOptionsLoading,
    sifarnikProizvodaLoading,
    invalidFields,
    setInvalidFields,
    vaziOdRef,
    vaziDoRef,
  };
}
