import { useState, useRef, useEffect, useMemo } from "react";
import { InputText } from "primereact/inputtext";
import RadnikReadDto from "../../model/radnik/RadnikReadDto";
import { Checkbox } from "primereact/checkbox";

interface PropsType {
  options: Array<RadnikReadDto>;
  onChange: (e: any, id: number) => void;
  added: Array<number>;
}

const CustomMultiselect = (props: PropsType) => {
  const divRef = useRef<any>();
  const [showList, setShowList] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const { options, onChange, added } = props;

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowList(false);
        setInput("");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  const divStyles = useMemo(() => {
    return { backgroundColor: "#e3f2fd" };
  }, []);

  return (
    <div className="custom-div">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={input} className="pl-5 w-full" onClick={() => !showList && setShowList(true)} onChange={(e) => setInput(e.target.value)} />
      </span>
      {showList && (
        <div ref={divRef} className="custom-panel">
          <div>
            {options
              .filter((radnik: RadnikReadDto) => `${radnik.ime} ${radnik.prezime}`.toLowerCase().includes(input.toLowerCase()))
              .map((radnik: RadnikReadDto) => (
                <div key={radnik.id} className="custom-row" onClick={(e) => onChange(e, radnik.id)} style={added?.includes(radnik.id) ? divStyles : {}}>
                  <Checkbox checked={added.includes(radnik.id)} inputId={radnik.ime + radnik.id} value={radnik.id} key={radnik.id} className="mr-2" />
                  <label htmlFor={radnik.ime + radnik.id} className="custom-label">
                    {radnik.ime} {radnik.prezime}
                  </label>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomMultiselect;
