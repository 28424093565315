import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { SifarnikController } from "../../../controllers/sifarnik/SifarnikController";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import SifarnikMkbReadDto from "../../../model/sifarnik/sifarnik-mkb/SifarnikMkbReadDto";

interface SifarnikMkbListLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  importSifarnikMkb: any;
  fetchData: () => void;
  searchSifarnikMkbByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  sifarnikMkbSearchData: SearchBaseDto;
  changeSifarnikMkbSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  sifarnikMkbLoading: boolean;
  exportData: Array<any>;
  sifarnikMkbList: Array<SifarnikMkbReadDto>;
  first: number;
  tableRows: number;
  onPageSifarnikMkb: (rows: number, first: number) => void;
}

export default function SifarnikMkbListLogical(): SifarnikMkbListLogicalType {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const [sifarnikMkbSearchData, setSifarnikMkbSearchData] = useState<SearchBaseDto>({});
  const [sifarnikMkbLoading, setSifarnikMkbLoading] = useState(false);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [sifarnikMkbList, setSifarnikMkbList] = useState<Array<SifarnikMkbReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const { axiosSearchSifarnikMKB, axiosImportSifarnikMKB } = SifarnikController();
  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LABEL_SIFARNIK_USLUGA,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setSifarnikMkbLoading(true);
    axiosSearchSifarnikMKB(sifarnikMkbSearchData)
      .then(({ data: { data } }: { data: { data: Array<SifarnikMkbReadDto> } }) => {
        setSifarnikMkbList(data);
        let exportList = new Array<any>();
        data.forEach((sifarnikMkb: SifarnikMkbReadDto) => {
          exportList.push({
            [Labels.SIFARNIK_MKB_SIFRA]: sifarnikMkb.sifra,
            [Labels.SIFARNIK_MKB_NAZIV]: sifarnikMkb.naziv,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSifarnikMkbLoading, startTime);
      });
  };

  const importSifarnikMkb = () => {
    setShowBlockUI(true);
    axiosImportSifarnikMKB()
      .then(() => {
        fetchData();
        showMessage(MessageType.SUCCESS, Labels.IMPORT_SIFARNIK_MKB_SUCCESS);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const searchSifarnikMkbByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const changeSifarnikMkbSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSifarnikMkbSearchData({
      ...sifarnikMkbSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const onPageSifarnikMkb = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    breadCrumbItems,
    importSifarnikMkb,
    fetchData,
    searchSifarnikMkbByEnter,
    sifarnikMkbSearchData,
    changeSifarnikMkbSearchData,
    onPageSifarnikMkb,
    sifarnikMkbLoading,
    exportData,
    tableRows,
    sifarnikMkbList,
    first,
  };
}
