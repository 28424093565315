import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import PaketUslugaReadDto from "../../../../model/paket/PaketUslugaReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import DialogPaketUsluga from "./DialogPaketUsluga";
import PaketUslugaListLogical from "./PaketUslugaListLogical";

export default function PaketUslugaListView() {
  const {
    paketUslugaLoading,
    paketUslugaList,
    first,
    tableRows,
    onPagePaketUsluga,
    isDialogPaketUslugaOpen,
    openDialogPaketUsluga,
    closeDialogPaketUsluga,
    paketUslugaChange,
    setPaketUslugaChange,
    searchSifarnikUsluga,
    sifarnikUslugaList,
    dialogAction,
    paketUsluga,
    onCreate,
    onDelete,
    onUpdate,
  } = PaketUslugaListLogical();

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const { pristup } = useContext(AppContext);

  const crudBodyTemplate = (rowPaketUsluga: PaketUslugaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.USLUGA_IZMENA}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowPaketUsluga && openDialogPaketUsluga(EntityOperation.UPDATE, rowPaketUsluga)}
        />
        <Button
          type="button"
          tooltip={Labels.USLUGA_BRISANJE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowPaketUsluga && openDialogPaketUsluga(EntityOperation.DELETE, rowPaketUsluga)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const toolbarLeftContent = () => {
    return (
      <>
        <Button
          label={Labels.USLUGA_DODAVANJE}
          tooltip={Labels.USLUGA_DODAVANJE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => openDialogPaketUsluga(EntityOperation.CREATE, undefined)}
          disabled={!pristup}
        />
      </>
    );
  };

  const podrazumevanoOdabranaElement = (paketUsluga: PaketUslugaReadDto) => {
    if (paketUsluga.podrazumevanoOdabrana) {
      return <div>{Labels.PAKET_USLUGA_PODRAZUMEVANA_TEXT}</div>;
    } else {
      return <div></div>;
    }
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_PRIKAZNI_REDOSLED, filter: true, sortrable: true },
    { columnName: Labels.HEADER_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.HEADER_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.HEADER_INFO, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <Toolbar left={toolbarLeftContent} />
        {paketUslugaLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={paketUslugaList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPagePaketUsluga(e.rows, e.first);
            }}
            exportFilename={Labels.PAKET_USLUGA_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"prikazniRedosled"} header={Labels.HEADER_PRIKAZNI_REDOSLED} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifarnikUsluga.naziv"} header={Labels.HEADER_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifarnikUsluga.sifra"} header={Labels.HEADER_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"podrazumevanoOdabrana"} header={Labels.HEADER_INFO} filter filterMatchMode="contains" body={podrazumevanoOdabranaElement} showFilterMenu={false} sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
        <DialogPaketUsluga
          show={isDialogPaketUslugaOpen}
          closeDialog={closeDialogPaketUsluga}
          paketUslugaChange={paketUslugaChange}
          setPaketUslugaChange={setPaketUslugaChange}
          searchSifarnikUsluga={searchSifarnikUsluga}
          sifarnikUslugaList={sifarnikUslugaList}
          onCreate={onCreate}
          dialogAction={dialogAction}
          paketUsluga={paketUsluga}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      </Panel>
    </div>
  );
}
