import moment from "moment";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { STEP_MINUTE, TIME_FORMAT, createCurrentDateWithTime, getDateDivisibleBy5, handleKeyboardTimeInputChange, setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import OrganizacionaJedinicaRadniDanReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaRadniDanReadDto";
import OrganizacionaJedinicaReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import SkeletonCheckboxItem from "../../app/skeleton/SkeletonCheckboxItem";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CalendarComponent from "../calendar-component/CalendarComponent";
import CrudOrganizacionaJedinicaLogical from "./CrudOrganizacionaJedinicaLogical";
import CrudOrganizacionaJedinicaKontaktTip from "./kontakt-tip/CrudOrganizacionaJedinicaKontaktTipView";
import CrudOrganizacionaJedinicaRadnik from "./radnik/CrudOrganizacionaJedinicaRadnikView";
import CrudOrganizacionaJedinicaSpecijalizacijeView from "./specijalizacije/CrudOrganizacionaJedinicaSpecijalizacijeView";
export default function CrudOrganizacionaJedinica() {
  const {
    orgJedOperation,
    isDisabled,
    organizacionaJedinicaChange,
    breadCrumbItems,
    setOrganizacionaJedinicaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    nadredjeneOrgJed,
    orgJedId,
    location,
    organizacionaJedinicaTipList,
    ustanovaRadniDanList,
    orgJedLoading,
    orgJedOptionsDataLoading,
    getRadnoVremeValue,
    protokolList,
    invalidFields,
    setInvalidFields,
    vremeOdRef,
    vremeDoRef,
  } = CrudOrganizacionaJedinicaLogical();
  const Labels = useLabels();

  const { pristup, nadjimed } = useContext(AppContext);

  if (!orgJedId && !location.state) {
    return <Navigate to="/organizaciona-jedinica-list" />;
  }

  const leftContentsToolbar = () => (
    <>
      {orgJedOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          disabled={!pristup || orgJedOptionsDataLoading}
          tooltip={Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {orgJedOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || orgJedOptionsDataLoading || orgJedLoading}
          icon="pi pi-pencil"
          tooltip={Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {orgJedOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || orgJedOptionsDataLoading || orgJedLoading}
          icon="pi pi-trash"
          tooltip={Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );
  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_ORGANIZACIONA_JEDINICA}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.ORGANIZACIONA_JEDINICA_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!orgJedLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={organizacionaJedinicaChange?.naziv ?? ""}
                        onChange={(e) => {
                          setOrganizacionaJedinicaChange({
                            ...organizacionaJedinicaChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", organizacionaJedinicaChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.ORGANIZACIONA_JEDINICA_NADREDJENA_ORGANIZACIONA_JEDINICA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!orgJedLoading && !orgJedOptionsDataLoading ? (
                    <Dropdown
                      disabled={!pristup || isDisabled}
                      options={nadredjeneOrgJed.filter((orgJed: OrganizacionaJedinicaReadDto) => orgJed?.id !== organizacionaJedinicaChange?.id)}
                      resetFilterOnHide
                      filter
                      showClear
                      optionLabel="naziv"
                      optionValue="id"
                      value={organizacionaJedinicaChange?.organizacionaJedinicaNadredjena?.id ?? ""}
                      onChange={(e) => {
                        if (e.target.value === undefined) {
                          setOrganizacionaJedinicaChange({
                            ...organizacionaJedinicaChange!,
                            organizacionaJedinicaNadredjena: undefined,
                          });
                        } else {
                          setOrganizacionaJedinicaChange({
                            ...organizacionaJedinicaChange!,
                            organizacionaJedinicaNadredjena: {
                              id: nadredjeneOrgJed!.find((x: OrganizacionaJedinicaReadDto) => x.id === e.target.value)?.id!,
                            },
                          });
                        }
                      }}
                      emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.ORGANIZACIONA_JEDINICA_TIP_ORGANIZACIONE_JEDINICE}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!orgJedLoading && !orgJedOptionsDataLoading ? (
                    <>
                      <Dropdown
                        disabled={!pristup || isDisabled}
                        resetFilterOnHide
                        options={organizacionaJedinicaTipList}
                        value={organizacionaJedinicaChange?.organizacionaJedinicaTip?.sifra ?? ""}
                        optionLabel="naziv"
                        optionValue="sifra"
                        filter
                        showClear
                        onChange={(e) => {
                          setOrganizacionaJedinicaChange({
                            ...organizacionaJedinicaChange!,
                            organizacionaJedinicaTip: {
                              sifra: organizacionaJedinicaTipList!.find((x) => x.sifra === e.target.value)?.sifra!,
                            },
                          });
                          if (e.target.value) {
                            setInvalidFields((prev) => ({ ...prev, tipOrgJedinice: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, tipOrgJedinice: true }));
                          }
                        }}
                        className={invalidFields?.tipOrgJedinice && "p-invalid"}
                        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      />
                      {invalidFields?.tipOrgJedinice && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.ORGANIZACIONA_JEDINICA_RADNI_DANI}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!orgJedLoading && !orgJedOptionsDataLoading ? (
                    <>
                      <MultiSelect
                        value={
                          organizacionaJedinicaChange?.organizacionaJedinicaRadniDanList?.map((organizacionaJedinicaRadniDan: OrganizacionaJedinicaRadniDanReadDto) => {
                            return organizacionaJedinicaRadniDan.radniDan.sifra;
                          }) ?? ""
                        }
                        options={ustanovaRadniDanList ?? []}
                        disabled={!pristup || isDisabled}
                        onChange={(e) => {
                          let organizacionaJedinicaRadniDanList = e.value.map((radniDanSifra: string) => {
                            return { radniDan: { sifra: radniDanSifra } };
                          });
                          setOrganizacionaJedinicaChange({
                            ...organizacionaJedinicaChange!,
                            organizacionaJedinicaRadniDanList: organizacionaJedinicaRadniDanList,
                          });
                          if (e.value.length > 0) {
                            setInvalidFields((prev) => ({ ...prev, radniDani: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, radniDani: true }));
                          }
                        }}
                        className={invalidFields?.radniDani && "p-invalid"}
                        optionLabel="radniDan.naziv"
                        optionValue="radniDan.sifra"
                        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      />
                      {invalidFields?.radniDani && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.ORGANIZACIONA_JEDINICA_ADRESA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!orgJedLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={organizacionaJedinicaChange?.adresa ?? ""}
                      onChange={(e) => {
                        setOrganizacionaJedinicaChange({
                          ...organizacionaJedinicaChange!,
                          adresa: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.ORGANIZACIONA_JEDINICA_TELEFON}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!orgJedLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={organizacionaJedinicaChange?.telefon ?? ""}
                      onChange={(e) => {
                        setOrganizacionaJedinicaChange({
                          ...organizacionaJedinicaChange!,
                          telefon: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.ORGANIZACIONA_JEDINICA_RADNO_VREME_OD}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!orgJedLoading ? (
                    <CalendarComponent
                      ref={vremeOdRef}
                      timeOnly
                      disabled={!pristup || isDisabled}
                      viewDate={organizacionaJedinicaChange?.radnoVremeDo ? getDateDivisibleBy5(createCurrentDateWithTime(organizacionaJedinicaChange?.radnoVremeDo)) : getDateDivisibleBy5()}
                      value={getRadnoVremeValue(organizacionaJedinicaChange?.radnoVremeOd)}
                      onChange={(e) => {
                        setOrganizacionaJedinicaChange({
                          ...organizacionaJedinicaChange!,
                          radnoVremeOd: e.value instanceof Date ? moment(e.value).format(TIME_FORMAT) : e.value,
                        });
                      }}
                      onInput={(e) =>
                        handleKeyboardTimeInputChange(
                          e.currentTarget.value,
                          "radnoVremeOd",
                          organizacionaJedinicaChange,
                          setOrganizacionaJedinicaChange,
                          vremeOdRef,
                          organizacionaJedinicaChange?.radnoVremeDo ? createCurrentDateWithTime(organizacionaJedinicaChange?.radnoVremeDo) : undefined,
                          undefined
                        )
                      }
                      stepMinute={STEP_MINUTE}
                      maxDate={getRadnoVremeValue(organizacionaJedinicaChange?.radnoVremeDo)}
                      icon="pi pi-clock"
                      onTodayButtonClick={() => {
                        let currentDate = getDateDivisibleBy5(new Date());
                        let maxDate = getRadnoVremeValue(organizacionaJedinicaChange?.radnoVremeDo);
                        if (maxDate && currentDate > maxDate) {
                          currentDate = maxDate;
                        }
                        setOrganizacionaJedinicaChange({
                          ...organizacionaJedinicaChange!,
                          radnoVremeOd: moment(currentDate).format(TIME_FORMAT),
                        });
                      }}
                      placeholder={Labels.PLACEHOLDER_VREME_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.ORGANIZACIONA_JEDINICA_RADNO_VREME_DO}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!orgJedLoading ? (
                    <CalendarComponent
                      ref={vremeDoRef}
                      timeOnly
                      disabled={!pristup || isDisabled}
                      viewDate={organizacionaJedinicaChange?.radnoVremeOd ? getDateDivisibleBy5(createCurrentDateWithTime(organizacionaJedinicaChange?.radnoVremeOd)) : getDateDivisibleBy5()}
                      value={getRadnoVremeValue(organizacionaJedinicaChange?.radnoVremeDo)}
                      onChange={(e) => {
                        setOrganizacionaJedinicaChange({
                          ...organizacionaJedinicaChange!,
                          radnoVremeDo: e.value instanceof Date ? moment(e.value).format(TIME_FORMAT) : e.value,
                        });
                      }}
                      onInput={(e) =>
                        handleKeyboardTimeInputChange(
                          e.currentTarget.value,
                          "radnoVremeDo",
                          organizacionaJedinicaChange,
                          setOrganizacionaJedinicaChange,
                          vremeDoRef,
                          undefined,
                          organizacionaJedinicaChange?.radnoVremeOd ? createCurrentDateWithTime(organizacionaJedinicaChange?.radnoVremeOd) : undefined
                        )
                      }
                      stepMinute={STEP_MINUTE}
                      minDate={getRadnoVremeValue(organizacionaJedinicaChange?.radnoVremeOd)}
                      icon="pi pi-clock"
                      onTodayButtonClick={() => {
                        let currentDate = getDateDivisibleBy5(new Date());
                        let minDate = getRadnoVremeValue(organizacionaJedinicaChange?.radnoVremeOd);
                        if (minDate && currentDate < minDate) {
                          currentDate = minDate;
                        }
                        setOrganizacionaJedinicaChange({
                          ...organizacionaJedinicaChange!,
                          radnoVremeDo: moment(currentDate).format(TIME_FORMAT),
                        });
                      }}
                      placeholder={Labels.PLACEHOLDER_VREME_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>{Labels.USTANOVA_PROTOKOL}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!orgJedLoading && !orgJedOptionsDataLoading ? (
                      <Dropdown
                        disabled={!pristup || isDisabled}
                        options={protokolList}
                        showClear
                        value={organizacionaJedinicaChange?.protokol?.id ?? null}
                        optionLabel="naziv"
                        optionValue="id"
                        onChange={(e) => {
                          setOrganizacionaJedinicaChange({
                            ...organizacionaJedinicaChange!,
                            protokol: e.value ? protokolList.filter((protokol) => protokol.id === e.value)[0] : undefined,
                          });
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                </>
                {nadjimed && (
                  <>
                    <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                      <div>{Labels.ORGANIZACIONA_JEDINICA_DOSTUPNA_ZA_EKSTERNE_ZAKAZIVACE}</div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      {!orgJedLoading ? (
                        <Checkbox
                          checked={organizacionaJedinicaChange?.dostupanZaEksterneZakazivace ?? false}
                          onChange={(e) =>
                            setOrganizacionaJedinicaChange({
                              ...organizacionaJedinicaChange!,
                              dostupanZaEksterneZakazivace: e.checked,
                            })
                          }
                        />
                      ) : (
                        <SkeletonCheckboxItem />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
          </TabPanel>
          <TabPanel header={Labels.RADNIK_LIST} disabled={orgJedOperation === EntityOperation.CREATE}>
            <CrudOrganizacionaJedinicaRadnik isDisabled={isDisabled} orgJedOperation={orgJedOperation} orgJedNaziv={organizacionaJedinicaChange?.naziv} />
          </TabPanel>
          <TabPanel header={Labels.TIP_KONTAKTA_LIST} disabled={orgJedOperation === EntityOperation.CREATE}>
            <CrudOrganizacionaJedinicaKontaktTip isDisabled={isDisabled} orgJedOperation={orgJedOperation} orgJedNaziv={organizacionaJedinicaChange?.naziv} />
          </TabPanel>
          <TabPanel header={Labels.ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE} disabled={orgJedOperation === EntityOperation.CREATE}>
            <CrudOrganizacionaJedinicaSpecijalizacijeView orgJedNaziv={organizacionaJedinicaChange?.naziv} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
