import { Button } from "primereact/button";
import { useLabels } from "../../Store";
import { ReactComponent as IconDelete } from "../../infrastructure/images/poruke/delete.svg";
import { ReactComponent as IconForward } from "../../infrastructure/images/poruke/forward.svg";
import { ReactComponent as IconReply } from "../../infrastructure/images/poruke/reply.svg";
import { ReactComponent as IconReplyAll } from "../../infrastructure/images/poruke/reply_all.svg";
import { ReactComponent as IconUnreadMessage } from "../../infrastructure/images/poruke/view_off.svg";
import FileType from "../../infrastructure/system/FileType";
import Images from "../../infrastructure/system/Images";
import { DATE_TIME_FORMAT, formatDate2 } from "../../infrastructure/system/Utils";
import PorukaReadDto from "../../model/poruke/PorukaReadDto";
import DialogDeletePoruka from "./DialogDeletePoruka";
import PorukaLogical from "./PorukaLogical";
import PorukaSadrzaj from "./primljene/PorukaSadrzaj";

interface PorukaProps {
  poruka: PorukaReadDto | undefined;
  setPoruka: React.Dispatch<React.SetStateAction<PorukaReadDto | undefined>>;
  handleUnread?: () => void;
  handleDelete: () => void;
  handleReply?: () => void;
  handleReplyAll?: () => void;
  handleForward: () => void;
}

export default function Poruka({ poruka, setPoruka, handleUnread, handleDelete, handleReply, handleReplyAll, handleForward }: PorukaProps) {
  const { handleDatotekaClick, openDialog, closeDialog, isOpenDeleteDialog } = PorukaLogical();
  const Labels = useLabels();

  const porukaDatotekaListElement = poruka?.porukaDatotekaList.map((datoteka) => (
    <div className="flex cursor-pointer align-items-center p-2 poruke-attachment__added hover:surface-300" key={datoteka.id} onClick={() => handleDatotekaClick(datoteka)}>
      {datoteka?.tip === FileType.doc && <img src={Images.ICON_DOC} alt="doc-icon" className="attachment-icon" />}
      {datoteka?.tip === FileType.docx && <img src={Images.ICON_DOCX} alt="docx-icon" className="attachment-icon" />}
      {datoteka?.tip === FileType.pdf && <img src={Images.ICON_PDF} alt="pdf-icon" className="attachment-icon" />}
      {datoteka?.tip === FileType.ppt && <img src={Images.ICON_PPT} alt="ppt-icon" className="attachment-icon" />}
      {datoteka?.tip === FileType.pptx && <img src={Images.ICON_PPTX} alt="pptx-icon" className="attachment-icon" />}
      {datoteka?.tip === FileType.rar && <img src={Images.ICON_RAR} alt="rar-icon" className="attachment-icon" />}
      {datoteka?.tip === FileType.txt && <img src={Images.ICON_TXT} alt="txt-icon" className="attachment-icon" />}
      {datoteka?.tip === FileType.xls && <img src={Images.ICON_XLS} alt="xls-icon" className="attachment-icon" />}
      {datoteka?.tip === FileType.zip && <img src={Images.ICON_ZIP} alt="zip-icon" className="attachment-icon" />}
      {datoteka?.tip.includes("image") && <img src={Images.ICON_IMAGE} alt="icon" className="attachment-icon" />}
      <span>{datoteka?.naziv}</span>
    </div>
  ));

  const onDelete = () => {
    handleDelete();
    closeDialog();
  };

  return (
    <div className="col-7 p-3 h-full align-self-start">
      <div className="flex justify-content-end">
        <Button className="align-right button-close" icon={<i className="pi pi-times" />} onClick={() => setPoruka(undefined)} />
      </div>
      <div className="border-bottom-1 py-3 border-300">
        <div className="flex justify-content-between">
          <div>
            <div className="flex hc-gap-3 mb-2">
              <div className="font-bold">{Labels.PORUKA_POSILJALAC}</div>
              <div>{`${poruka?.radnikKreirao.titula ?? ""} ${poruka?.radnikKreirao.ime} ${poruka?.radnikKreirao.prezime}`}</div>
            </div>
            <div className="flex hc-gap-3">
              <div className="font-bold">{poruka?.porukaRadnikList.length === 1 ? Labels.PORUKA_PRIMALAC : Labels.PORUKA_PRIMAOCI}</div>
              <div>{poruka?.porukaRadnikList.map((primalac) => `${primalac?.radnik.titula ?? ""} ${primalac?.radnik.ime} ${primalac?.radnik.prezime}`).join(", ")}</div>
            </div>
          </div>
          <div className="text-600 white-space-nowrap">{formatDate2(poruka?.vremeKreiranja, DATE_TIME_FORMAT)}</div>
        </div>
      </div>
      <div className="poruka-body scroll-container">{poruka && <PorukaSadrzaj poruka={poruka} />}</div>
      <div className="flex flex-wrap hc-gap-4 py-3 border-bottom-1 border-300">
        {handleReply && (
          <Button tooltip={Labels.TOOLTIP_PORUKA_REPLY} tooltipOptions={{ position: "bottom" }} icon={<IconReply fill="blue" color="blue" />} className="message-button" onClick={handleReply} />
        )}
        {handleReplyAll && poruka && poruka?.porukaRadnikList.length > 1 && (
          <Button tooltip={Labels.TOOLTIP_PORUKA_REPLY_ALL} tooltipOptions={{ position: "bottom" }} icon={<IconReplyAll />} className="message-button" onClick={handleReplyAll} />
        )}
        <Button tooltip={Labels.TOOLTIP_PORUKA_FORWARD} tooltipOptions={{ position: "bottom" }} icon={<IconForward className="selected" />} className="message-button" onClick={handleForward} />
        {handleUnread && <Button tooltip={Labels.TOOLTIP_PORUKA_UNREAD} tooltipOptions={{ position: "bottom" }} icon={<IconUnreadMessage />} className="message-button" onClick={handleUnread} />}
        <Button tooltip={Labels.TOOLTIP_PORUKA_DELETE} tooltipOptions={{ position: "bottom" }} icon={<IconDelete />} className="message-button" onClick={openDialog} />
      </div>
      <div className="flex flex-wrap py-3 hc-gap-2">{porukaDatotekaListElement}</div>
      <DialogDeletePoruka onDelete={onDelete} isOpenDeleteDialog={isOpenDeleteDialog} closeDeleteDialog={closeDialog} />
    </div>
  );
}
