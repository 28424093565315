import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import ObavestenjeDatotekaCreateDto from "../../model/obavestenje/ObavestenjeDatotekaCreateDto";
import ObavestenjeRadnikCreateDto from "../../model/obavestenje/ObavestenjeRadnikCreateDto";
import ObavestenjeReadDto from "../../model/obavestenje/ObavestenjeReadDto";

interface ObavestenjeControllerType {
  axiosSearchObavestenje: (obavestenjeSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetObavestenje: (obavestenjeId: number) => Promise<AxiosResponse<any>>;
  axiosCreateObavestenje: (obavestenjeChange: any) => Promise<AxiosResponse<any>>;
  axiosCreateCopyObavestenje: (obavestenjeId: number, obavestenjeCopyId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteObavestenje: (obavestenjeId: number) => Promise<AxiosResponse<any>>;
  axiosGetObavestenjeReadList: (obavestenjeId: number) => Promise<AxiosResponse<any>>;
  axiosGetObavestenjeUnreadList: (obavestenjeId: number) => Promise<AxiosResponse<any>>;
  axiosGetObavestenjeUnreadCount: (obavestenjeId: number) => Promise<AxiosResponse<any>>;
  axiosReadObavestenje: (obavestenje: ObavestenjeRadnikCreateDto) => Promise<AxiosResponse<any>>;
  axiosReadAllObavestenje: (obavestenje: Array<ObavestenjeRadnikCreateDto>) => Promise<AxiosResponse<any>>;
  axiosCreateObavestenjeDatoteka: (obavestenjeDatotekaCreate: ObavestenjeDatotekaCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetObavestenjeDatotekaList: (obavestenjeId: number) => Promise<AxiosResponse<any>>;
  axiosGetObavestenjeDetails: (obavestenjeId: number) => Promise<AxiosResponse<any>>;
  axiosGetObavestenjeDatoteka: (obavestenjeDatotekaId: number) => Promise<AxiosResponse<any>>;
}

export function ObavestenjeController(): ObavestenjeControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchObavestenje = (obavestenjeSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.OBAVESTENJE_SEARCH, axiosConfig(authData!.token, obavestenjeSearchData));
  };

  const axiosGetObavestenje = (obavestenjeId: number) => {
    return axios.get(`${Endpoint.OBAVESTENJE_LIST}/${obavestenjeId}`, axiosConfig(authData!.token));
  };

  const axiosCreateObavestenje = (obavestenjeChange: ObavestenjeReadDto) => {
    return axios.post(Endpoint.OBAVESTENJE_LIST, obavestenjeChange ? obavestenjeChange : {}, axiosConfig(authData!.token));
  };

  const axiosCreateCopyObavestenje = (obavestenjeId: number, obavestenjeCopyId: number) => {
    return axios.post(`${Endpoint.OBAVESTENJE_LIST}/${obavestenjeId}/copy-obavestenje/${obavestenjeCopyId}`, {}, axiosConfig(authData!.token));
  };

  const axiosDeleteObavestenje = (obavestenjeId: number) => {
    return axios.delete(`${Endpoint.OBAVESTENJE_LIST}/${obavestenjeId}`, axiosConfig(authData!.token));
  };

  const axiosGetObavestenjeReadList = (radnikId: number) => {
    return axios.get(`${Endpoint.OBAVESTENJE_READ_LIST}`, axiosConfig(authData!.token, { radnikId: radnikId }));
  };

  const axiosGetObavestenjeUnreadList = (radnikId: number) => {
    return axios.get(`${Endpoint.OBAVESTENJE_UNREAD_LIST}`, axiosConfig(authData!.token, { radnikId: radnikId }));
  };

  const axiosGetObavestenjeUnreadCount = (radnikId: number) => {
    return axios.get(`${Endpoint.OBAVESTENJE_UNREAD_LIST_COUNT}`, axiosConfig(authData!.token, { radnikId: radnikId }));
  };

  const axiosReadObavestenje = (obavestenje: ObavestenjeRadnikCreateDto) => {
    return axios.post(Endpoint.OBAVESTENJE_READ, obavestenje, axiosConfig(authData!.token));
  };

  const axiosReadAllObavestenje = (obavestenjeList: Array<ObavestenjeRadnikCreateDto>) => {
    return axios.post(Endpoint.OBAVESTENJE_READ_ALL, obavestenjeList, axiosConfig(authData!.token));
  };

  const axiosCreateObavestenjeDatoteka = (obavestenjeDatotekaCreate: ObavestenjeDatotekaCreateDto) => {
    return axios.post(`${Endpoint.OBAVESTENJE_DATOTEKA_LIST}`, obavestenjeDatotekaCreate ? obavestenjeDatotekaCreate : {}, axiosConfig(authData!.token));
  };

  const axiosGetObavestenjeDatotekaList = (obavestenjeId: number) => {
    return axios.get(`${Endpoint.OBAVESTENJE_DATOTEKA_LIST}/obavestenje/${obavestenjeId}`, axiosConfig(authData!.token));
  };

  const axiosGetObavestenjeDetails = (obavestenjeId: number) => {
    return axios.get(`${Endpoint.OBAVESTENJE_DETAILS}/${obavestenjeId}`, axiosConfig(authData!.token));
  };

  const axiosGetObavestenjeDatoteka = (obavestenjeDatotekaId: number) => {
    return axios.get(`${Endpoint.OBAVESTENJE_DATOTEKA_LIST}/${obavestenjeDatotekaId}`, axiosConfig(authData!.token));
  };

  return {
    axiosSearchObavestenje,
    axiosGetObavestenje,
    axiosCreateObavestenje,
    axiosCreateCopyObavestenje,
    axiosDeleteObavestenje,
    axiosGetObavestenjeReadList,
    axiosGetObavestenjeUnreadList,
    axiosGetObavestenjeUnreadCount,
    axiosReadObavestenje,
    axiosReadAllObavestenje,
    axiosCreateObavestenjeDatoteka,
    axiosGetObavestenjeDatotekaList,
    axiosGetObavestenjeDetails,
    axiosGetObavestenjeDatoteka,
  };
}
