import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import PartnerReadDto from "../../../model/partner/PartnerReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import PartneriListLogical from "./PartneriListLogical";

export default function PartneriListView() {
  const { partneriList, first, tableRows, changePartneriSearchData, partneriSearchData, fetchData, searchPartneriByEnter, onPagePartneri, breadCrumbItems, partneriLoading } = PartneriListLogical();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const openPartner = (entityOperation: string, partner?: PartnerReadDto) => {
    const partnerId = entityOperation !== EntityOperation.CREATE ? partner?.id : "";
    navigate(`/crud-partner/${partnerId}`, {
      state: { partnerOperation: entityOperation, partnerNaziv: partner?.naziv },
    });
  };

  const crudBodyTemplate = (rowPartner: PartnerReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.LABEL_EDIT_PARTNER}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowPartner && openPartner(EntityOperation.UPDATE, rowPartner)}
        />
        <Button
          type="button"
          tooltip={Labels.LABEL_DELETE_PARTNER}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowPartner && openPartner(EntityOperation.DELETE, rowPartner)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.LABEL_ADD_PARTNER}
          tooltip={Labels.LABEL_ADD_PARTNER}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openPartner(EntityOperation.CREATE);
          }}
          disabled={!pristup}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={partneriSearchData?.searchString ?? ""}
            onChange={changePartneriSearchData}
            onKeyPress={searchPartneriByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_PARTNER}</label>
          <Button tooltip={Labels.SEARCH_PARTNER} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [{ columnName: Labels.LABEL_NAZIV, filter: true, sortrable: true }];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} />
        {partneriLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={partneriList ?? []}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPagePartneri(e.rows, e.first);
            }}
            exportFilename={Labels.LABEL_SIFARNIK_USLUGA}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.LABEL_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
