import { useMemo } from "react";

const ObaveznoPolje = (props: { text: string }) => {
  const { text } = props;

  const colorStyle = useMemo(() => {
    return { color: "red" };
  }, []);

  return (
    <small style={colorStyle} id="sifra-required">
      {text}
    </small>
  );
};

export default ObaveznoPolje;
