import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useLabels } from "../../../../Store";
import { DATE_TIME_FORMAT, formatDate } from "../../../../infrastructure/system/Utils";
import KontaktProizvodReadDto from "../../../../model/kontakt/KontaktProizvodReadDto";
import KontaktUslugaReadDto from "../../../../model/kontakt/KontaktUslugaReadDto";

interface SpisakUslugaPropsType {
  spisak: Array<KontaktProizvodReadDto | KontaktUslugaReadDto>;
}

export default function SpisakUsluga(props: SpisakUslugaPropsType) {
  const { spisak } = props;
  const Labels = useLabels();

  const nazivBodyTemplate = (rowData: KontaktProizvodReadDto | KontaktUslugaReadDto | any) => {
    return rowData.sifarnikProizvoda ? rowData.sifarnikProizvoda.naziv : rowData.sifarnikUsluga.naziv;
  };

  const kolicinaBodyTemplate = (rowData: KontaktProizvodReadDto | KontaktUslugaReadDto | any) => {
    return rowData.sifarnikProizvoda ? `${rowData.utrosenaKolicina} ${rowData.sifarnikProizvoda?.sifarnikJedinicaMere?.akronim ?? ""}` : rowData.brojPruzanja;
  };

  const sifraBodyTemplate = (rowData: KontaktProizvodReadDto | KontaktUslugaReadDto | any) => {
    return rowData.sifarnikProizvoda ? rowData.sifarnikProizvoda.sifra : rowData.sifarnikUsluga.sifra;
  };

  const vremePruzanjaTemplate = (rowData: KontaktProizvodReadDto | KontaktUslugaReadDto | any) => {
    return formatDate(rowData.vremeKreiranja as Date, DATE_TIME_FORMAT);
  };

  return (
    <div className="col-12">
      <DataTable value={spisak} emptyMessage={Labels.TABLE_EMPTY_MESSAGE} rowHover stripedRows showGridlines={false} className="spisakUsluga">
        <Column body={sifraBodyTemplate} headerClassName="font-bold" header={Labels.LABEL_SIFRA} filter={false} showFilterMenu={false} />
        <Column body={nazivBodyTemplate} headerClassName="font-bold" header={Labels.LABEL_NAZIV} filter={false} showFilterMenu={false} />
        <Column body={kolicinaBodyTemplate} headerClassName="font-bold" header={Labels.LABEL_KOLICINA_NAPLATA} filter={false} showFilterMenu={false} />
        <Column body={vremePruzanjaTemplate} headerClassName="font-bold" header={Labels.LABEL_VREME_PRUZANJA_NAPLATA} filter={false} showFilterMenu={false} />
      </DataTable>
    </div>
  );
}
