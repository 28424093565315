import axios from "axios";
import moment from "moment";
import { InputSwitchChangeEvent } from "primereact/inputswitch";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import SockJS from "sockjs-client";
import { Client, Message, Subscription, over } from "stompjs";
import { AppContext, useLabels } from "../../../Store";
import ZakazanIzvestajController from "../../../controllers/zakazani-izvestaj/ZakazaniIzvestajController";
import Endpoint from "../../../infrastructure/system/Endpoint";
import EnumPeriodGenerisanjaSifra from "../../../infrastructure/system/EnumPeriodGenerisanjaSifra";
import EnumPonavljanjeGenerisanjaSifra from "../../../infrastructure/system/EnumPonavljanjeGenerisanjaSifra";
import EnumWebSocketPorukaTip from "../../../infrastructure/system/EnumWebSocketPorukaTip";
import MessageType from "../../../infrastructure/system/MessageType";
import { DATE_FORMAT_2, DATE_TIME_FORMAT_6, TIME_FORMAT, TIME_FORMAT_3, handleAxiosCallError, isEmailFormatValid, useEffectOnce } from "../../../infrastructure/system/Utils";
import InitialStateBrisanjeIzvestaja from "../../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/initialStateBrisanjeIzvestaja";
import reducerBrisanjeIzvestaja from "../../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/reducerBrisanjeIzvestaja";
import useCallbackWithoutWarnings from "../../../infrastructure/system/hooks/useCallbackWithoutWarnings";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import actionsZakazaniIzvestaj from "../../../infrastructure/system/hooks/zakazani-izvestaj-reducer/actionsZakazaniIzvestaj";
import InitialStateZakazaniIzvestaj from "../../../infrastructure/system/hooks/zakazani-izvestaj-reducer/initialStateZakazaniIzvestaj";
import reducerZakazaniIzvestaji from "../../../infrastructure/system/hooks/zakazani-izvestaj-reducer/reducerZakazaniIzvestaji";
import WebSocketPayload from "../../../model/WebSocketPayload";
import IzvestajSimpleReadDto from "../../../model/izvestaj/IzvestajSimpleReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import ZakazanIzvestajCreateDto from "../../../model/zakazaniIzvestaj/ZakazanIzvestajCreateDto";
import ZakazanIzvestajUpdateDto from "../../../model/zakazaniIzvestaj/ZakazanIzvestajUpdateDto";
import { GenerisaniIzvestajContext } from "../GenerisaniIzvestajLogical";

export interface ZakazanIzvestajType {
  zakazanChecked: boolean;
  emails: Array<string>;
  ponavljanjeGenerisanja: EnumBaseReadDto | undefined;
  datumOd: Date | undefined;
  datumDo: Date | undefined;
  datumGenerisanja: Date | undefined;
  vremeGenerisanja: Date | undefined;
  vremeGenerisanjaVisestruko: Date | undefined;
  ucestalostPonavljanja: EnumBaseReadDto | undefined;
  selectedDaniUNedelji: Array<EnumBaseReadDto>;
  selectedDaniUMesecu: Array<EnumBaseReadDto>;
  emailValue: string;
}

export default function ZakazaniIzvestajListLogical() {
  const { axiosGetZakazaniIzvestajList, axiosGetZakazaniIzvestajiByStatistickiIzvestaj, axiosEditZakazaniIzvestaj, axiosCreateZakazaniIzvestaj, axiosGetZakazaniIzvestajParameters } =
    ZakazanIzvestajController();
  const { showMessage, authData } = useContext(AppContext);
  const { index } = useContext(GenerisaniIzvestajContext);
  const Labels = useLabels();
  const [stateZakazani, dispatchZakazani] = useReducer(reducerZakazaniIzvestaji, InitialStateZakazaniIzvestaj);
  const [stateDialog, dispatchDialog] = useReducer(reducerBrisanjeIzvestaja, InitialStateBrisanjeIzvestaja);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const [tablePagination, setTablePagination] = useState<any>({
    first: 0,
    page: 1,
    rows: 10,
  });
  const stateRef = useRef<any>();
  let stompClient = useRef<Client>();
  let subscription = useRef<Subscription>();
  const postLogHighLevel = useLogHighLevel();

  const [zakazanIzvestajChange, setZakazanIzvestajChange] = useState<ZakazanIzvestajType>({
    zakazanChecked: false,
    emails: [],
    ponavljanjeGenerisanja: undefined,
    datumOd: undefined,
    datumDo: undefined,
    datumGenerisanja: undefined,
    vremeGenerisanja: undefined,
    vremeGenerisanjaVisestruko: undefined,
    ucestalostPonavljanja: undefined,
    selectedDaniUNedelji: [],
    selectedDaniUMesecu: [],
    emailValue: "",
  });

  useEffectOnce(() => {
    if (index === 1) connect();
    return () => {
      if (stompClient.current?.connected) {
        stompClient.current?.disconnect(() => {});
      }
    };
  });

  useEffect(() => {
    stateRef.current = stateZakazani;
  }, [stateZakazani]);

  const connect = () => {
    let sock = new SockJS(Endpoint.GENERISANJE_IZVESTAJA_WEBSOCKET);
    stompClient.current = over(sock);
    stompClient.current.connect({}, onConnected, onError);
  };

  const onMessageReceived = useCallbackWithoutWarnings(
    (message: Message) => {
      let payload: WebSocketPayload = JSON.parse(message.body);
      if (payload.payload) {
        fetchZakazaniData(stateRef!.current.onlyMine ? authData?.currentRadnik.id : undefined, stateRef!.current.selectedIzvestaj);
        showMessage(MessageType.SUCCESS, payload.poruka);
        subscription.current!.unsubscribe();
        stompClient.current!.disconnect(() => {});
        return;
      }
      if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.ERROR_MSG) {
        showMessage(MessageType.ERROR, payload.poruka);
      } else if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.INFO_MSG) {
        showMessage(MessageType.INFO, payload.poruka);
      } else if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.SUCCESS_MSG) {
        showMessage(MessageType.SUCCESS, payload.poruka);
        fetchZakazaniData(stateRef!.current.onlyMine ? authData?.currentRadnik.id : undefined, stateRef!.current.selectedIzvestaj);
      }
    },
    [stateZakazani]
  );

  const onConnected = () => {
    subscription.current = stompClient.current!.subscribe(`${Endpoint.GENERISANJE_IZVESTAJA_LISTENER}/${authData?.currentRadnik.id}`, onMessageReceived);
  };

  const onError = () => {
    showMessage(MessageType.ERROR, Labels.GENERISANJE_IZVESTAJA_CONNECTION_ERROR);
  };

  const validateInput = (zakazanIzvestajChange: ZakazanIzvestajType | undefined) => {
    let isInvalid = false;
    if (!zakazanIzvestajChange?.ponavljanjeGenerisanja) {
      setInvalidFields((prev: any) => ({ ...prev, ponavljanjeGenerisanja: true }));
      isInvalid = true;
    }
    if (
      zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO &&
      zakazanIzvestajChange.datumOd &&
      zakazanIzvestajChange.datumDo &&
      zakazanIzvestajChange.datumOd > zakazanIzvestajChange.datumDo
    ) {
      setInvalidFields((prev: any) => ({ ...prev, invalidDatum: true }));
      isInvalid = true;
    }
    if (zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && !zakazanIzvestajChange.datumOd) {
      setInvalidFields((prev: any) => ({ ...prev, datumOdVisestruko: true }));
      isInvalid = true;
    }
    if (zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && !zakazanIzvestajChange.datumDo) {
      setInvalidFields((prev: any) => ({ ...prev, datumDoVisestruko: true }));
      isInvalid = true;
    }
    if (zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && !zakazanIzvestajChange.ucestalostPonavljanja) {
      setInvalidFields((prev: any) => ({ ...prev, ucestalost: true }));
      isInvalid = true;
    }
    if (!zakazanIzvestajChange?.vremeGenerisanja && !zakazanIzvestajChange?.vremeGenerisanjaVisestruko) {
      setInvalidFields((prev: any) => ({ ...prev, vreme: true }));
      isInvalid = true;
    }
    if (zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO && !zakazanIzvestajChange.datumGenerisanja) {
      setInvalidFields((prev: any) => ({ ...prev, datumJednostruko: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onChangeZakazani = (e: InputSwitchChangeEvent) => {
    fetchZakazaniData(e.value ? authData?.currentRadnik.id : undefined, stateZakazani.selectedIzvestaj);
  };

  const fetchZakazaniData = (radnikID?: number, statistickiIzvestaj?: IzvestajSimpleReadDto) => {
    const startTime = moment(new Date());
    const searchParams = { radnikID: radnikID };
    dispatchZakazani({ type: actionsZakazaniIzvestaj.SET_IZVESTAJ_LIST_LOADING, data: true });
    const getZakazaniIzvestajRequest = statistickiIzvestaj ? axiosGetZakazaniIzvestajiByStatistickiIzvestaj(statistickiIzvestaj.id, searchParams) : axiosGetZakazaniIzvestajList(searchParams);
    axios
      .all([getZakazaniIzvestajRequest])
      .then(
        axios.spread((getZakazaniIzvestajResponse: any) => {
          const izvestajList = getZakazaniIzvestajResponse.data.data;
          const value = {
            izvestajList: izvestajList,
            selectedIzvestaj: statistickiIzvestaj,
            onlyMine: radnikID ? true : false,
          };
          dispatchZakazani({ type: actionsZakazaniIzvestaj.FETCH_DATA, data: value });
          setTablePagination((prev: any) => ({ ...prev, first: 0, page: 1, rows: 10 }));
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        if (moment(new Date()).diff(startTime) < 300) {
          setTimeout(() => {
            dispatchZakazani({ type: actionsZakazaniIzvestaj.SET_IZVESTAJ_LIST_LOADING, data: false });
          }, 300);
        } else {
          dispatchZakazani({ type: actionsZakazaniIzvestaj.SET_IZVESTAJ_LIST_LOADING, data: false });
        }
      });
  };

  const getZakazaniIzvestajParams = (izvestaj: any) => {
    axiosGetZakazaniIzvestajParameters(izvestaj.id)
      .then((res: any) => {
        dispatchZakazani({ type: actionsZakazaniIzvestaj.FETCH_PARAMS, data: { selected: true, selectedForEdit: izvestaj, formParams: res.data.data } });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const posaljiIzvestajNaZakazivanje = (izvestaj: IzvestajSimpleReadDto | undefined, zakazanIzvestajChange: ZakazanIzvestajType | undefined) => {
    if (!validateInput(zakazanIzvestajChange)) {
      return;
    }
    const emailsObjects = zakazanIzvestajChange?.emails.map((email) => ({ email: email }));
    const daniNedeljaString = zakazanIzvestajChange?.selectedDaniUNedelji.map((dan) => dan.sifra).join(", ");
    const daniMesecString = zakazanIzvestajChange?.selectedDaniUMesecu.join(", ");
    const onlyTime = moment(zakazanIzvestajChange?.vremeGenerisanja).format(TIME_FORMAT_3);
    const onlyDate = moment(zakazanIzvestajChange?.datumGenerisanja).format(DATE_FORMAT_2);

    const izvestajZaZakazivanje: ZakazanIzvestajCreateDto = {
      statistickiIzvestaj: izvestaj,
      zakazanIzvestajEmailList: emailsObjects,
      ponavljanje: zakazanIzvestajChange?.ponavljanjeGenerisanja,
      period: zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO ? zakazanIzvestajChange.ucestalostPonavljanja : undefined,
      danNedelja:
        zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && zakazanIzvestajChange.ucestalostPonavljanja?.sifra === EnumPeriodGenerisanjaSifra.NEDELJA
          ? daniNedeljaString
          : "",
      danMesec:
        zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && zakazanIzvestajChange.ucestalostPonavljanja?.sifra === EnumPeriodGenerisanjaSifra.MESEC
          ? daniMesecString
          : "",
      vremeZakazanoJednostruko: zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO ? onlyDate + "T" + onlyTime : undefined,
      vremeZakazanoVisestruko:
        zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO
          ? moment(zakazanIzvestajChange.vremeGenerisanjaVisestruko).format(DATE_TIME_FORMAT_6)
          : undefined,
      vaziOd: zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO ? moment(zakazanIzvestajChange.datumOd).format(DATE_FORMAT_2) : undefined,
      vaziDo: zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO ? moment(zakazanIzvestajChange.datumDo).format(DATE_FORMAT_2) : undefined,
      params: stateZakazani.paramsValue,
    };

    if (izvestajZaZakazivanje.params?.length === 0) izvestajZaZakazivanje.params = null;
    axiosCreateZakazaniIzvestaj(izvestaj?.id, izvestajZaZakazivanje)
      .then((res: any) => {
        const vremeLog =
          res.data.data.ponavljanje.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO
            ? moment(res.data.data.vremeZakazanoJednostruko).format(TIME_FORMAT)
            : moment(res.data.data.vremeZakazanoVisestruko).format(TIME_FORMAT);
        dispatchZakazani({ type: actionsZakazaniIzvestaj.EMPTY_FIELDS, data: true });
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ1 +
            res.data.data.statistickiIzvestaj.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ2 +
            res.data.data.ponavljanje.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ3 +
            vremeLog
        );
        showMessage(MessageType.SUCCESS, Labels.ZAKAZAN_IZVESTAJ_CREATE);
      })
      .finally(() => {
        fetchZakazaniData(stateZakazani.onlyMine ? authData?.currentRadnik.id : undefined, izvestaj);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const zakaziIzvestaj = (izvestaj: IzvestajSimpleReadDto | undefined, zakazanIzvestajChange: ZakazanIzvestajType | undefined) => {
    if (!stompClient.current?.connected) {
      connect();
      setTimeout(() => {
        posaljiIzvestajNaZakazivanje(izvestaj, zakazanIzvestajChange);
      }, 500);
    } else {
      posaljiIzvestajNaZakazivanje(izvestaj, zakazanIzvestajChange);
    }
  };

  const posaljiIzvestajNaIzmenu = (id: number, izvestaj: IzvestajSimpleReadDto | undefined, zakazanIzvestajChange: ZakazanIzvestajType | undefined) => {
    if (!validateInput(zakazanIzvestajChange)) {
      return;
    }
    const emailsObjects = zakazanIzvestajChange?.emails.map((email) => ({ email: email }));
    const daniNedeljaString = zakazanIzvestajChange?.selectedDaniUNedelji?.map((dan) => dan.sifra).join(", ");
    const daniMesecString = zakazanIzvestajChange?.selectedDaniUMesecu?.join(", ");
    const onlyTime = moment(zakazanIzvestajChange?.vremeGenerisanja).format(TIME_FORMAT_3);
    const onlyDate = moment(zakazanIzvestajChange?.datumGenerisanja).format(DATE_FORMAT_2);

    const izvestajZaIzmenu: ZakazanIzvestajUpdateDto = {
      id: id,
      statistickiIzvestaj: izvestaj,
      zakazanIzvestajEmailList: emailsObjects,
      ponavljanje: zakazanIzvestajChange?.ponavljanjeGenerisanja,
      period: zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO ? zakazanIzvestajChange?.ucestalostPonavljanja : undefined,
      danNedelja:
        zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO &&
        zakazanIzvestajChange?.ucestalostPonavljanja?.sifra === EnumPeriodGenerisanjaSifra.NEDELJA
          ? daniNedeljaString
          : "",
      danMesec:
        zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && zakazanIzvestajChange?.ucestalostPonavljanja?.sifra === EnumPeriodGenerisanjaSifra.MESEC
          ? daniMesecString
          : "",
      vremeZakazanoJednostruko: zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO ? onlyDate + "T" + onlyTime : undefined,
      vremeZakazanoVisestruko:
        zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO
          ? moment(zakazanIzvestajChange?.vremeGenerisanjaVisestruko).format(DATE_TIME_FORMAT_6)
          : undefined,
      vaziOd: zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO ? moment(zakazanIzvestajChange?.datumOd).format(DATE_FORMAT_2) : undefined,
      vaziDo: zakazanIzvestajChange?.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO ? moment(zakazanIzvestajChange?.datumDo).format(DATE_FORMAT_2) : undefined,
      params: stateZakazani.paramsValue,
    };

    if (izvestajZaIzmenu.params?.length === 0) izvestajZaIzmenu.params = null;
    axiosEditZakazaniIzvestaj(id, izvestajZaIzmenu)
      .then((res: any) => {
        dispatchZakazani({ type: actionsZakazaniIzvestaj.CANCEL_EDIT, data: undefined });
        fetchZakazaniData(stateZakazani.onlyMine ? authData?.currentRadnik.id : undefined, stateZakazani.selectedIzvestaj ? izvestaj : undefined);
        const vremeLog =
          res.data.data.ponavljanje.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO
            ? moment(res.data.data.vremeZakazanoJednostruko).format(TIME_FORMAT)
            : moment(res.data.data.vremeZakazanoVisestruko).format(TIME_FORMAT);
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ1 +
            res.data.data.statistickiIzvestaj.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ2 +
            res.data.data.ponavljanje.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ3 +
            vremeLog
        );
        showMessage(MessageType.SUCCESS, Labels.ZAKAZAN_IZVESTAJ_EDIT);
      })
      .catch((error: any) => handleAxiosCallError(showMessage, error));
  };

  const izmeniIzvestaj = (id: number, izvestaj: IzvestajSimpleReadDto | undefined, zakazanIzvestajChange: ZakazanIzvestajType | undefined) => {
    if (!stompClient.current?.connected) {
      connect();
      setTimeout(() => {
        posaljiIzvestajNaIzmenu(id, izvestaj, zakazanIzvestajChange);
      }, 500);
    } else {
      posaljiIzvestajNaIzmenu(id, izvestaj, zakazanIzvestajChange);
    }
  };

  const validateEmailInput = () => {
    let isInvalid = false;
    if (zakazanIzvestajChange.emails.includes(zakazanIzvestajChange.emailValue)) {
      setInvalidFields((prev: any) => ({ ...prev, emailExists: true }));
      isInvalid = true;
    }
    if (zakazanIzvestajChange?.emailValue && !isEmailFormatValid(zakazanIzvestajChange?.emailValue)) {
      setInvalidFields((prev: any) => ({ ...prev, invalidEmail: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const addEmail = () => {
    if (!validateEmailInput()) {
      return;
    }
    let emailsTemp = zakazanIzvestajChange.emails;
    emailsTemp.push(zakazanIzvestajChange.emailValue);
    setZakazanIzvestajChange((prev) => ({ ...prev, emails: emailsTemp, emailValue: "" }));
  };

  const removeEmail = (email: string) => {
    let emailsTemp = zakazanIzvestajChange.emails;
    emailsTemp = emailsTemp.filter((e) => e !== email);
    setZakazanIzvestajChange((prev) => ({ ...prev, emails: emailsTemp }));
  };

  const onCancel = () => {
    setZakazanIzvestajChange((prev) => ({
      ...prev,
      zakazanChecked: false,
      emails: [],
      vremeGenerisanja: undefined,
      vremeGenerisanjaVisestruko: undefined,
      datumGenerisanja: undefined,
      ponavljanjeGenerisanja: undefined,
      selectedDaniUNedelji: [],
      selectedDaniUMesecu: [],
      ucestalostPonavljanja: undefined,
      datumOd: undefined,
      datumDo: undefined,
      emailValue: "",
    }));
  };

  useEffect(() => {
    if (stateZakazani?.emptyFields) {
      onCancel();
      dispatchZakazani({ type: actionsZakazaniIzvestaj.EMPTY_FIELDS, data: false });
    }
    // eslint-disable-next-line
  }, [stateZakazani?.emptyFields]);

  useEffect(() => {
    if (!stateZakazani?.editSelected) {
      setZakazanIzvestajChange((prev) => ({
        ...prev,
        vremeGenerisanja: undefined,
        vremeGenerisanjaVisestruko: undefined,
        datumGenerisanja: undefined,
        datumOd: undefined,
        datumDo: undefined,
        ucestalostPonavljanja: undefined,
        selectedDaniUNedelji: [],
        selectedDaniUMesecu: [],
      }));
    }
    setInvalidFields(undefined);
    // eslint-disable-next-line
  }, [zakazanIzvestajChange.ponavljanjeGenerisanja, zakazanIzvestajChange.zakazanChecked]);

  useEffect(() => {
    if (!stateZakazani?.editSelected) {
      setZakazanIzvestajChange((prev) => ({
        ...prev,
        vremeGenerisanja: undefined,
        vremeGenerisanjaVisestruko: undefined,
        selectedDaniUNedelji: [],
        selectedDaniUMesecu: [],
      }));
    }
    // eslint-disable-next-line
  }, [zakazanIzvestajChange.ucestalostPonavljanja]);

  const paginate = (array: any, page_size: number, page_number: number) => {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  return {
    stateZakazani,
    dispatchZakazani,
    onChangeZakazani,
    fetchZakazaniData,
    stateDialog,
    dispatchDialog,
    getZakazaniIzvestajParams,
    izmeniIzvestaj,
    zakaziIzvestaj,
    addEmail,
    zakazanIzvestajChange,
    setZakazanIzvestajChange,
    removeEmail,
    onCancel,
    invalidFields,
    setInvalidFields,
    paginate,
    tablePagination,
    setTablePagination,
  };
}
