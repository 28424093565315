import { useContext, useEffect, useState } from "react";
import { AppContext, useLabels } from "../../Store";
import { UpozorenjeController } from "../../controllers/upozorenje/UpozorenjeController";
import MessageType from "../../infrastructure/system/MessageType";
import { handleAxiosCallError } from "../../infrastructure/system/Utils";
import UpozorenjeDto from "../../model/upozorenje/UpozorenjeDto";

interface UpozorenjeAdministracijaLogicalType {
  upozorenjeLocal: UpozorenjeDto | undefined;
  setUpozorenjeLocal: React.Dispatch<React.SetStateAction<UpozorenjeDto | undefined>>;
  onCreate: () => void;
  onDelete: () => void;
  upozorenje: UpozorenjeDto | undefined;
}

export default function UpozorenjeAdministracijaLogical(): UpozorenjeAdministracijaLogicalType {
  const { showMessage, setShowBlockUI, upozorenje, setUpozorenje } = useContext(AppContext);
  const [upozorenjeLocal, setUpozorenjeLocal] = useState<UpozorenjeDto | undefined>();
  const { axiosCreateUpozorenje, axiosDeleteUpozorenje } = UpozorenjeController();
  const Labels = useLabels();

  useEffect(() => {
    setUpozorenjeLocal(upozorenje);
  }, [upozorenje]);

  const onCreate = () => {
    setShowBlockUI(true);
    axiosCreateUpozorenje(upozorenjeLocal!)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_ADD_NEW_WARNING_SUCCESS);
        setUpozorenje({ sadrzaj: upozorenjeLocal!.sadrzaj });
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeleteUpozorenje()
      .then(() => {
        setUpozorenjeLocal({ sadrzaj: "" });
        setUpozorenje({ sadrzaj: "" });
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_DELETE_WARNING_SUCCESS);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return {
    upozorenjeLocal,
    setUpozorenjeLocal,
    onCreate,
    onDelete,
    upozorenje,
  };
}
