import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import FormularCreateDto from "../../model/formular/FormularCreateDto";
import FormularPublicCopyDto from "../../model/formular/FormularPublicCopyDto";
import FormularReadDto from "../../model/formular/FormularReadDto";
import TipKontaktaPublicCopyDto from "../../model/kontakt/TipKontaktaPublicCopyDto";
import TipKontaktaReadDto from "../../model/kontakt/TipKontaktaReadDto";
import PoljeReadDto from "../../model/polje/PoljeReadDto";

interface SuperadminKontaktTipControllerType {
  axiosSuperadminGetKontaktTipList: (ustanovaId: number, kontaktTipId?: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminSearchKontaktTipList: (ustanovaId: number, searchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateKontaktTip: (ustanovaId: number, tipKontaktaChange: TipKontaktaReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateKontaktTip: (ustanovaId: number, tipKontaktaChange: TipKontaktaReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteKontaktTip: (ustanovaId: number, tipKontaktaChange: TipKontaktaReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminPublicCopyRepositoryTipKontakta: (ustanovaId: number, tipKontaktaPublicCopy: TipKontaktaPublicCopyDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminSearchFormular: (ustanovaId: number, kontaktTipId: number, formularSearchData: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetFormular: (ustanovaId: number, kontaktTipId: number, formularId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateFormular: (ustanovaId: number, kontaktTipId: number, formularChange: FormularCreateDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateFormular: (ustanovaId: number, kontaktTipId: number, formularChange: FormularReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteFormular: (ustanovaId: number, kontaktTipId: number, formularId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminPublicCopyRepositoryFormular: (ustanovaId: number, kontaktTipId: number, formularPublicCopy: FormularPublicCopyDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetPoljeList: (ustanovaId: number, kontaktTipId: number, formularId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeletePolje: (ustanovaId: number, kontaktTipId: number, formularId: number, poljeId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreatePolje: (ustanovaId: number, kontaktTipId: number, formularId: number, polje: PoljeReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdatePolje: (ustanovaId: number, kontaktTipId: number, formularId: number, poljeChange: PoljeReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminReadPolje: (ustanovaId: number, kontaktTipId: number, poljeId: number, formularId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetPoljePanelList: (ustanovaId: number, kontaktTipId: number, formularId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminSearchPoljeTip: (ustanovaId: number, poljeTipSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetPoljeTipEnumStavkaList: (ustanovaId: number, poljeTipId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminReadPoljeTip: (ustanovaId: number, poljeTipId: number) => Promise<AxiosResponse<any>>;
}
export function SuperadminKontaktTipController(): SuperadminKontaktTipControllerType {
  const { authData } = useContext(AppContext);

  const axiosSuperadminGetKontaktTipList = (ustanovaId: number, kontaktTipId?: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}`, axiosConfig(authData!.token));
  };
  const axiosSuperadminSearchKontaktTipList = (ustanovaId: number, searchData?: SearchBaseDto) => {
    return axios.get(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-search`, axiosConfig(authData!.token, searchData));
  };

  const axiosSuperadminCreateKontaktTip = (ustanovaId: number, tipKontaktaChange: TipKontaktaReadDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list`, tipKontaktaChange ? tipKontaktaChange : {}, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateKontaktTip = (ustanovaId: number, tipKontaktaChange: TipKontaktaReadDto) => {
    return axios.put(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${tipKontaktaChange.id}`, tipKontaktaChange, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteKontaktTip = (ustanovaId: number, tipKontaktaChange: TipKontaktaReadDto) => {
    return axios.delete(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${tipKontaktaChange.id}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminPublicCopyRepositoryTipKontakta = (ustanovaId: number, tipKontaktaPublicCopy: TipKontaktaPublicCopyDto) =>
    axios.post(`${Endpoint.REPOSITORY_TIP_KONTAKTA_LIST}/ustanova-list/${ustanovaId}${Endpoint.COPY_PUBLIC_SUFIX}`, tipKontaktaPublicCopy, axiosConfig(authData!.token));

  const axiosSuperadminSearchFormular = (ustanovaId: number, kontaktTipId: number, formularSearchData: SearchBaseDto) => {
    return axios.get(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-search`, axiosConfig(authData!.token, formularSearchData));
  };
  const axiosSuperadminGetFormular = (ustanovaId: number, kontaktTipId: number, formularId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list/${formularId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateFormular = (ustanovaId: number, kontaktTipId: number, formularChange: FormularCreateDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list`, formularChange ? formularChange : {}, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateFormular = (ustanovaId: number, kontaktTipId: number, formularChange: FormularReadDto) => {
    return axios.put(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list/${formularChange.id}`, formularChange, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteFormular = (ustanovaId: number, kontaktTipId: number, formularId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list/${formularId}`, axiosConfig(authData!.token));
  };
  const axiosSuperadminPublicCopyRepositoryFormular = (ustanovaId: number, kontaktTipId: number, formularPublicCopy: FormularPublicCopyDto) =>
    axios.post(
      `${Endpoint.REPOSITORY_FORMULAR}/ustanova-list/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list${Endpoint.COPY_PUBLIC_SUFIX}`,
      formularPublicCopy,
      axiosConfig(authData!.token)
    );

  const axiosSuperadminGetPoljeList = (ustanovaId: number, kontaktTipId: number, formularId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list/${formularId}/polje-list`, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeletePolje = (ustanovaId: number, kontaktTipId: number, formularId: number, poljeId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list/${formularId}/polje-list/${poljeId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreatePolje = (ustanovaId: number, kontaktTipId: number, formularId: number, polje: PoljeReadDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list/${formularId}/polje-list`, polje ? polje : {}, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdatePolje = (ustanovaId: number, kontaktTipId: number, formularId: number, poljeChange: PoljeReadDto) => {
    return axios.put(
      `${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list/${formularId}/polje-list/${poljeChange.id}`,
      poljeChange,
      axiosConfig(authData!.token)
    );
  };
  const axiosSuperadminReadPolje = (ustanovaId: number, kontaktTipId: number, poljeId: number, formularId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list/${formularId}/polje-list/${poljeId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetPoljePanelList = (ustanovaId: number, kontaktTipId: number, formularId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/kontakt-tip-list/${kontaktTipId}/formular-list/${formularId}/polje-panel-list`, axiosConfig(authData!.token));
  };
  const axiosSuperadminSearchPoljeTip = (ustanovaId: number, poljeTipSearchData?: SearchBaseDto) => {
    return axios.get(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/polje-tip-search`, axiosConfig(authData!.token, poljeTipSearchData));
  };

  const axiosSuperadminGetPoljeTipEnumStavkaList = (ustanovaId: number, poljeTipId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/polje-tip-list/${poljeTipId}/polje-tip-enum-stavka-list`, axiosConfig(authData!.token));
  };

  const axiosSuperadminReadPoljeTip = (ustanovaId: number, poljeTipId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_KONTAKT_TIP}/${ustanovaId}/polje-tip-list/${poljeTipId}`, axiosConfig(authData!.token));
  };
  return {
    axiosSuperadminGetKontaktTipList,
    axiosSuperadminSearchKontaktTipList,
    axiosSuperadminCreateKontaktTip,
    axiosSuperadminUpdateKontaktTip,
    axiosSuperadminDeleteKontaktTip,
    axiosSuperadminPublicCopyRepositoryTipKontakta,
    axiosSuperadminCreateFormular,
    axiosSuperadminDeleteFormular,
    axiosSuperadminSearchFormular,
    axiosSuperadminUpdateFormular,
    axiosSuperadminPublicCopyRepositoryFormular,
    axiosSuperadminGetFormular,
    axiosSuperadminGetPoljeList,
    axiosSuperadminCreatePolje,
    axiosSuperadminDeletePolje,
    axiosSuperadminUpdatePolje,
    axiosSuperadminReadPolje,
    axiosSuperadminGetPoljePanelList,
    axiosSuperadminSearchPoljeTip,
    axiosSuperadminGetPoljeTipEnumStavkaList,
    axiosSuperadminReadPoljeTip,
  };
}
