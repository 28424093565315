import { lazy, Suspense, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import CrudFormular from "./components/administracija/formular/CrudFormularView";
import FormularListPage from "./components/administracija/formular/FormularListView";

import ExportPodatakaAdminView from "./components/administracija/export/ExportPodatakaAdminView";
import CrudIzvestajView from "./components/administracija/izvestaj/CrudIzvestajView";
import IzvestajListPage from "./components/administracija/izvestaj/IzvestajListView";
import CrudIzvestajParametarEnumStavkaView from "./components/administracija/izvestaj/parametar/CrudIzvestajParametarEnumStavkaView";
import CrudIzvestajParametarView from "./components/administracija/izvestaj/parametar/CrudIzvestajParametarView";
import CrudTipKontakta from "./components/administracija/kontakt/CrudTipKontaktaView";
import TipKontaktaListPage from "./components/administracija/kontakt/TipKontaktaListView";
import CrudBrojNalogaView from "./components/administracija/licenca/broj-naloga/CrudBrojNalogaView";
import CrudDodatniModulView from "./components/administracija/licenca/dodatni-modul/CrudDodatniModulView";
import LicencaView from "./components/administracija/licenca/LicencaView";
import CrudStorageLimitView from "./components/administracija/licenca/storage-limit/CrudStorageLimitView";
import LogHighLevelListPage from "./components/administracija/log/LogHighLevelListView";
import LogListPage from "./components/administracija/log/LogListView";
import CrudObavestenjePage from "./components/administracija/obavestenje/CrudObavestenjeView";
import ObavestenjeListPage from "./components/administracija/obavestenje/ObavestenjeListView";
import CrudOrganizacionaJedinica from "./components/administracija/organizaciona-jedinica/CrudOrganizacionaJedinicaView";
import OrganizacionaJedinicaListPage from "./components/administracija/organizaciona-jedinica/OrganizacionaJedinicaListView";
import CrudPacijentPoljeKategorija from "./components/administracija/pacijent-polje-kategorija/CrudPacijentPoljeKategorijaView";
import CrudPacijentPolje from "./components/administracija/pacijent-polje/CrudPacijentPoljeView";
import PacijentPoljeListPage from "./components/administracija/pacijent-polje/PacijentPoljeListView";
import CrudPoljeTipEnumStavkaView from "./components/administracija/polje-tip/CrudPoljeTipEnumStavkaView";
import CrudPoljeTipView from "./components/administracija/polje-tip/CrudPoljeTipView";
import PoljeTipListPage from "./components/administracija/polje-tip/PoljeTipListView";
import CrudRadniPeriod from "./components/administracija/polje-tip/radni-period/CrudRadniPeriodView";
import RadniPeriodListPage from "./components/administracija/polje-tip/radni-period/RadniPeriodListView";
import CrudPolje from "./components/administracija/polje/CrudPoljeView";
import CrudProtokolBrojPage from "./components/administracija/protokol/administracija-brojeva/CrudProtokolBrojView";
import CrudProtokolPage from "./components/administracija/protokol/administracija-knjiga/CrudProtokolView";
import ProtokolView from "./components/administracija/protokol/ProtokolView";
import CrudRadnik from "./components/administracija/radnik/CrudRadnikView";
import RadnikListPage from "./components/administracija/radnik/RadnikListView";

import SifarnikImportView from "./components/administracija/sifarnik-import/SifarnikImportView";
import CrudSifarnikJedinicaMerePage from "./components/administracija/sifarnik-jedinica-mere/CrudSifarnikJedinicaMereView";
import SifarnikJedinicaMereListPage from "./components/administracija/sifarnik-jedinica-mere/SifarnikJedinicaMereListView";
import SifarnikMkbListView from "./components/administracija/sifarnik-mkb/SifarnikMkbListView";
import CrudSifarnikProizvodaRfzo from "./components/administracija/sifarnik-proizvoda-rfzo/CrudSifarnikProizvodaRfzoView";
import SifarnikProizvodaRfzoListView from "./components/administracija/sifarnik-proizvoda-rfzo/SifarnikProizvodaRfzoListView";
import CrudSifarnikProizvoda from "./components/administracija/sifarnik-proizvoda/CrudSifarnikProizvodaView";
import SifarnikProizvodaListView from "./components/administracija/sifarnik-proizvoda/SifarnikProizvodaListView";
import CrudSifarnikUslugaRfzo from "./components/administracija/sifarnik-usluga-rfzo/CrudSifarnikUslugaRfzoView";
import SifarnikUslugaRfzoListView from "./components/administracija/sifarnik-usluga-rfzo/SifarnikUslugaRfzoListView";
import CrudSifarnikUsluga from "./components/administracija/sifarnik-usluga/CrudSifarnikUslugaView";
import SifarnikUslugaListView from "./components/administracija/sifarnik-usluga/SifarnikUslugaListView";
import CrudUloga from "./components/administracija/uloga/CrudUlogaView";
import UlogaListPage from "./components/administracija/uloga/UlogaListView";
import CrudUstanovaAdmin from "./components/administracija/ustanova/admin-ustanova/CrudUstanovaAdminView";
import CrudUstanova from "./components/administracija/ustanova/CrudUstanovaView";
import CrudPlacanje from "./components/administracija/ustanova/placanje/CrudPlacanjeView";
import CrudUstanovaBrojNaloga from "./components/administracija/ustanova/ustanova-licence/broj-naloga/CrudUstanovaBrojNalogaView";
import CrudDodatniModulUstanovaView from "./components/administracija/ustanova/ustanova-licence/dodatni-moduli/CrudUstanovaDodatniModulView";
import CrudUstanovaStorageLimitView from "./components/administracija/ustanova/ustanova-licence/storage-limit/CrudUstanovaStorageLimitView";
import UstanovaListPage from "./components/administracija/ustanova/UstanovaListView";
import ErrorPageView from "./components/error/ErrorPageView";
import Kontakt from "./components/kontakt/Kontakt";
import PacijentDosije from "./components/pacijent-dosije/PacijentDosije";
import PodesavanjaPage from "./components/podesavanja/PodesavanjaView";
import PretragaPacijenataView from "./components/pretraga-pacijenata/PretragaPacijenataView";
import UpozorenjeAdministracija from "./components/upozorenje/UpozorenjeAdministracija";
import ZakazivanjePregledaPage from "./components/zakazivanje-pregleda/ZakazivanjePregledaView";
import { EnumPodrazumevanaStrana, EnumPodrazumevanaStranaURLMap } from "./infrastructure/system/EnumPodrazumevanaStrana";

import EksterniZakazivaciView from "./components/administracija/eksterni-zakazivaci/EksterniZakazivaciView";
import CrudExportView from "./components/administracija/export/CrudExportView";
import FaktorRizikaAdminView from "./components/administracija/faktor-rizika/FaktorRizikaAdminView";
import CrudKategorijaStatistickihIzvestajaView from "./components/administracija/izvestaj/kategorije-statistickih-izvestaja/CrudKategorijaStatistickihIzvestajaView";
import ObavestenjaPacijentiListView from "./components/administracija/obavestenja-pacijenti/ObavestenjaPacijentiListView";
import CrudPaketUslugaView from "./components/administracija/paket/CrudPaketView";
import PaketListView from "./components/administracija/paket/PaketListView";
import CrudPartnerView from "./components/administracija/partneri/CrudPartnerView";
import PartneriListView from "./components/administracija/partneri/PartneriListView";
import CrudSifarnikAtributaRfzo from "./components/administracija/sifarnik-atributa-rfzo/CrudSifarnikAtributaRfzoView";
import SifarnikAtributaRfzoListView from "./components/administracija/sifarnik-atributa-rfzo/SifarnikAtributaRfzoListView";
import CrudSifarnikKategorijePopustaView from "./components/administracija/sifarnik-kategorije-popusta/CrudSifarnikKategorijaPopustaView";
import SifarnikKategorijePopustaListView from "./components/administracija/sifarnik-kategorije-popusta/SifarnikKategorijaPopustaListView";
import SifarnikLekovaNaReceptListView from "./components/administracija/sifarnik-lekova-na-recept/SifarnikLekovaNaReceptListView";
import CrudSifarnikNacinaPlacanjaView from "./components/administracija/sifarnik-nacina-placanja/CrudSifarnikNacinaPlacanjaView";
import SifarnikNacinaPlacanjaListView from "./components/administracija/sifarnik-nacina-placanja/SifarnikNacinaPlacanjaListView";
import SpajanjePacijenataView from "./components/administracija/spajanje-pacijenata/SpajanjePacijenataView";
import CrudEksterniSistemView from "./components/administracija/ustanova/ustanova-eksterni-sistem/CrudEksterniSistemView";
import LoadingPanel from "./components/app/skeleton/LoadingPanel";
import PageForbiddenView from "./components/error/PageForbiddenView";
import GenerisaniIzvestaj from "./components/generisaniIzvestaj/GenerisaniIzvestajView";
import Home from "./components/Home";
import InicijalizacijaUstanoveContainer from "./components/inicijalizacija-ustanove/InicijalizacijaUstanoveContainer";
import NaplataView from "./components/naplata/NaplataView";
import PorukeView from "./components/poruke/PorukeView";
import { RolaService } from "./controllers/rola/RolaService";
import RequireRight from "./infrastructure/system/auth/RequireRight";
import EnumAplikativnaPrava from "./infrastructure/system/EnumAplikativnaPrava";
import EnumRola from "./infrastructure/system/EnumRola";
import { isInicijalizacija } from "./infrastructure/system/Utils";
import { AppContext } from "./Store";
import { GlobalnaDatotekaListView } from "./components/administracija/globalna-datoteka/GlobalnaDatotekaListView";
import VerzijaListAdminView from "./components/superadministracija/verzija/VerzijaListAdminView";
import IstorijaVerzijaView from "./components/administracija/istorija-verzija/IstorijaVerzijaView";

const RepositoryTipKontaktaListPage = lazy(() => import("./components/superadministracija/kontakt/RepositoryTipKontaktaListView"));
const RepositoryCrudTipKontakta = lazy(() => import("./components/superadministracija/kontakt/CrudRepositoryTipKontaktaView"));
const CrudRepositoryPolje = lazy(() => import("./components/superadministracija/polje/CrudRepositoryPoljeView"));
const RepositoryFormularListPage = lazy(() => import("./components/superadministracija/formular/RepositoryFormularListView"));
const CrudRepositoryFormular = lazy(() => import("./components/superadministracija/formular/CrudRepositoryFormularView"));
const CrudProdajniPaketiView = lazy(() => import("./components/superadministracija/prodajni-paketi/CrudProdajniPaketiView"));
const RepositoryCrudPoljeTipPage = lazy(() => import("./components/superadministracija/polje-tip/CrudRepositoryPoljeTipView"));
const ObrasciStampeProtokolaListView = lazy(() => import("./components/superadministracija/obrasci-stampe-protokola/ObrasciStampeProtokolaListView"));
const CrudObrazacStampeProtokolaView = lazy(() => import("./components/superadministracija/obrasci-stampe-protokola/CrudObrazacStampeProtokolaView"));
const FaktorRizikaView = lazy(() => import("./components/superadministracija/faktor-rizika/FaktorRizikaView"));
const CrudFaktorRizikaView = lazy(() => import("./components/superadministracija/faktor-rizika/CrudFaktorRizikaView"));
const CrudFaktorRizikaIkonicaView = lazy(() => import("./components/superadministracija/faktor-rizika-ikonica/CrudFaktorRizikaIkonicaView"));
const PravilaPlacanjaView = lazy(() => import("./components/superadministracija/pravila-placanja/PravilaPlacanjaView"));
const FaktorRizikaIkonicaView = lazy(() => import("./components/superadministracija/faktor-rizika-ikonica/FaktorRizikaIkonicaView"));
const RepositoryCrudPoljeTipEnumStavkaView = lazy(() => import("./components/superadministracija/polje-tip/CrudRepositoryPoljeTipEnumStavkaView"));
const RepositoryPoljeTipListPage = lazy(() => import("./components/superadministracija/polje-tip/RepositoryPoljeTipListView"));
const ExportPodatakaSuperAdminView = lazy(() => import("./components/superadministracija/export/ExportPodatakaSuperAdminView"));
const ProdajniPaketiListView = lazy(() => import("./components/superadministracija/prodajni-paketi/ProdajniPaketiListView"));
const CrudSifarnikSpecijalizacija = lazy(() => import("./components/superadministracija/sifarnik-specijalizacija/CrudSifarnikSpecijalizacija"));
const SifarnikSpecijalizacijaListView = lazy(() => import("./components/superadministracija/sifarnik-specijalizacija/SifarnikSpecijalizacijaListView"));

export default function Content() {
  const { authData } = useContext(AppContext);
  const ustanova = authData?.currentRadnik.ustanovaPoslednjaIzabrana;
  const { isSuperadmin } = RolaService();
  const location = EnumPodrazumevanaStranaURLMap[isSuperadmin ? "SUPERADMIN" : ustanova?.podrazumevanaStrana?.sifra ?? ""];
  const Homepage = isSuperadmin
    ? UstanovaListPage
    : isInicijalizacija(authData?.currentRadnik!)
      ? InicijalizacijaUstanoveContainer
      : location === EnumPodrazumevanaStranaURLMap[EnumPodrazumevanaStrana.ZAKAZIVANJE_PREGLEDA]
        ? ZakazivanjePregledaPage
        : PretragaPacijenataView;

  return (
    <Home>
      <Suspense fallback={<LoadingPanel />}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/forbidden" element={<PageForbiddenView />} />
          <Route path="/podesavanja" element={<PodesavanjaPage />} />
          <Route path="/error" element={<ErrorPageView />} />
          <Route path="/pacijent-dosije/:id" element={<PacijentDosije />} />
          <Route path="/kontakt/:id/:formularId" element={<Kontakt />} />
          <Route path="/kontakt/:id" element={<Kontakt />} />
          <Route path="/poruke" element={<PorukeView />} />
          <Route path="/veze" element={<EksterniZakazivaciView />} />
          <Route path="/inicijalizacija" element={<InicijalizacijaUstanoveContainer />} />
          <Route path="/istorija-verzija" element={<IstorijaVerzijaView />} />

          <Route element={<RequireRight allowedRoles={[EnumRola.SUPERADMIN]} />}>
            <Route path="/repository-crud-tip-kontakta/:tipKontaktaId?" element={<RepositoryCrudTipKontakta />} />
            <Route path="/repository-tip-kontakta-list" element={<RepositoryTipKontaktaListPage />} />
            <Route path="/repository-polje-tip-list" element={<RepositoryPoljeTipListPage />} />
            <Route path="/repository-polje-tip/:poljeTipId?" element={<RepositoryCrudPoljeTipPage />} />
            <Route path="/repository-polje-tip/:poljeTipId/polje-tip-enum-stavka/:poljeTipEnumStavkaId?" element={<RepositoryCrudPoljeTipEnumStavkaView />} />
            <Route path="/repository-crud-formular/:formularId?" element={<CrudRepositoryFormular />} />
            <Route path="/repository-crud-formular/:formularId/repository-crud-polje/:poljeId?" element={<CrudRepositoryPolje />} />
            <Route path="/repository-formular-list" element={<RepositoryFormularListPage />} />
            <Route path="/ustanova-list" element={<UstanovaListPage />} />
            <Route path="/crud-ustanova/:ustanovaId/crud-tip-kontakta/:tipKontaktaId/crud-formular/:formularId?" element={<CrudFormular />} />
            <Route path="/crud-ustanova/:ustanovaId/crud-tip-kontakta/:tipKontaktaId/crud-formular/:formularId/crud-polje/:poljeId?" element={<CrudPolje />} />
            <Route path="/crud-ustanova/:ustanovaId/crud-eksterni-sistem/:eksterniSistemId?/" element={<CrudEksterniSistemView />} />
            <Route path="/crud-ustanova/:ustanovaId/crud-radnik/:radnikId?" element={<CrudRadnik />} />
            <Route path="/crud-ustanova/:ustanovaId?/crud-ustanova-storage-limit/:ustanovaStorageLimitId?" element={<CrudUstanovaStorageLimitView />} />
            <Route path="/crud-ustanova/:ustanovaId?/crud-ustanova-broj-naloga/:ustanovaBrojNalogaId?" element={<CrudUstanovaBrojNaloga />} />
            <Route path="/crud-ustanova/:ustanovaId/crud-ustanova-dodatni-modul/:ustanovaDodatniModulId?" element={<CrudDodatniModulUstanovaView />} />
            <Route path="/crud-ustanova/:ustanovaId/crud-uloga/:ulogaId?" element={<CrudUloga />} />
            <Route path="/crud-ustanova/:ustanovaId?/crud-tip-kontakta/:tipKontaktaId?" element={<CrudTipKontakta />} />
            <Route path="/crud-ustanova/:ustanovaId/crud-placanje" element={<CrudPlacanje />} />
            <Route path="/crud-ustanova/:ustanovaId?" element={<CrudUstanova />} />
            <Route path="/faktor-rizika" element={<FaktorRizikaView />} />
            <Route path="/crud-faktor-rizika/:idFaktorRizika?" element={<CrudFaktorRizikaView />} />
            <Route path="/superadmin-izvoz-list" element={<ExportPodatakaSuperAdminView />} />
            <Route path="/repository-sifarnik-atributa-rfzo" element={<SifarnikAtributaRfzoListView />} />
            <Route path="/repository-crud-sifarnik-atributa-rfzo/:sifarnikId?" element={<CrudSifarnikAtributaRfzo />} />
            <Route path="/repository-sifarnik-mkb" element={<SifarnikMkbListView />} />
            <Route path="/repository-sifarnik-proizvoda-rfzo" element={<SifarnikProizvodaRfzoListView />} />
            <Route path="/repository-crud-sifarnik-proizvoda-rfzo/:sifarnikId?" element={<CrudSifarnikProizvodaRfzo />} />
            <Route path="/repository-sifarnik-usluga-rfzo" element={<SifarnikUslugaRfzoListView />} />
            <Route path="/repository-crud-sifarnik-usluga-rfzo/:sifarnikId?" element={<CrudSifarnikUslugaRfzo />} />
            <Route path="/faktor-rizika-ikonica" element={<FaktorRizikaIkonicaView />} />
            <Route path="/crud-faktor-rizika-ikonica/:idFaktorRizikaIkonica?" element={<CrudFaktorRizikaIkonicaView />} />
            <Route path="/globalni-parametri" element={<PravilaPlacanjaView />} />
            <Route path="/licence" element={<LicencaView />} />
            <Route path="/crud-dodatni-modul/:dodatniModulId?" element={<CrudDodatniModulView />} />
            <Route path="/crud-storage-limit/:storageLimitId?" element={<CrudStorageLimitView />} />
            <Route path="/crud-broj-naloga/:brojNalogaId?" element={<CrudBrojNalogaView />} />
            <Route path="/obrasci-stampe-protokola-list" element={<ObrasciStampeProtokolaListView />} />
            <Route path="/crud-obrazac-stampe-protokola/:obrazacId?" element={<CrudObrazacStampeProtokolaView />} />
            <Route path="/repository-sifarnik-specijalizacija" element={<SifarnikSpecijalizacijaListView />} />
            <Route path="/repository-crud-sifarnik-specijalizacija/:specijalizacijaId?" element={<CrudSifarnikSpecijalizacija />} />
            <Route path="/prodajni-paketi" element={<ProdajniPaketiListView />} />
            <Route path="/crud-prodajni-paket/:prodajniPaketId?" element={<CrudProdajniPaketiView />} />
            <Route path="/log-list" element={<LogListPage />} />
            <Route path="/sifarnik-import" element={<SifarnikImportView />} />
            <Route path="/upozorenje" element={<UpozorenjeAdministracija />} />
            <Route path="/globalne-datoteke" element={<GlobalnaDatotekaListView />} />
            <Route path="/verzije/admin" element={<VerzijaListAdminView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.PRETRAGA_PACIJENATA]} />}>
            <Route path="/pretraga-pacijenata" element={<PretragaPacijenataView />} />
          </Route>

          {/* TODO izbaceno napolje jer je potrebno da dozvoljava pristup i za pravo nad tipom KONTAKT:VIEW, omoguciti taj mehanizam */}
          <Route path="/zakazivanje-pregleda/:pacijentId?" element={<ZakazivanjePregledaPage />} />
          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ZAKAZIVANJE_TERMINA]} />}></Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_USTANOVE]} />}>
            <Route path="/crud-ustanova-admin" element={<CrudUstanovaAdmin />} />
            <Route path="/izvoz-list" element={<ExportPodatakaAdminView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_USTANOVE]} allowedRoles={[EnumRola.SUPERADMIN]} />}>
            <Route path="/crud-ustanova-admin" element={<CrudUstanovaAdmin />} />
            <Route path="/crud-izvoz" element={<CrudExportView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_ORG_JED]} />}>
            <Route path="/organizaciona-jedinica-list" element={<OrganizacionaJedinicaListPage />} />
            <Route path="/crud-organizaciona-jedinica/:orgJedId?" element={<CrudOrganizacionaJedinica />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_ULOGA_I_RADNIKA]} />}>
            <Route path="/uloga-list" element={<UlogaListPage />} />
            <Route path="/crud-uloga/:ulogaId?" element={<CrudUloga />} />
            <Route path="/radnik-list" element={<RadnikListPage />} />
            <Route path="/crud-radnik/:radnikId?" element={<CrudRadnik />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_TIPOVA_PREGLEDA_FORMULARA_I_TIPOVA_POLJA]} />}>
            <Route path="/tip-kontakta-list" element={<TipKontaktaListPage />} />
            <Route path="/crud-tip-kontakta/:tipKontaktaId?" element={<CrudTipKontakta />} />
            <Route path="/formular-list" element={<FormularListPage />} />
            <Route path="/crud-formular/:formularId?" element={<CrudFormular />} />
            <Route path="/polje-tip-list" element={<PoljeTipListPage />} />
            <Route path="/polje-tip/:poljeTipId?" element={<CrudPoljeTipView />} />
            <Route path="/crud-formular/:formularId/crud-polje/:poljeId?" element={<CrudPolje />} />
            <Route path="/polje-tip/:poljeTipId/polje-tip-enum-stavka/:poljeTipEnumStavkaId?" element={<CrudPoljeTipEnumStavkaView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_SIFARNIKA]} />}>
            <Route path="/sifarnik-usluga-list" element={<SifarnikUslugaListView />} />
            <Route path="/crud-sifarnik-usluga/:sifarnikUslugaId?" element={<CrudSifarnikUsluga />} />
            <Route path="/sifarnik-jedinica-mere-list" element={<SifarnikJedinicaMereListPage />} />
            <Route path="/crud-sifarnik-jedinica-mere/:sifarnikJedinicaMereId?" element={<CrudSifarnikJedinicaMerePage />} />
            <Route path="/sifarnik-proizvoda-list" element={<SifarnikProizvodaListView />} />
            <Route path="/crud-sifarnik-proizvoda/:sifarnikProizvodaId?" element={<CrudSifarnikProizvoda />} />
            <Route path="/sifarnik-lekova-na-recept-list" element={<SifarnikLekovaNaReceptListView />} />
            <Route path="/sifarnik-nacina-placanja-list" element={<SifarnikNacinaPlacanjaListView />} />
            <Route path="/crud-sifarnik-nacina-placanja/:sifarnikNacinaPlacanjaId?" element={<CrudSifarnikNacinaPlacanjaView />} />
            <Route path="/sifarnik-kategorija-popusta-list" element={<SifarnikKategorijePopustaListView />} />
            <Route path="/crud-sifarnik-kategorija-popusta/:sifarnikKategorijaPopustaId?" element={<CrudSifarnikKategorijePopustaView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_FAKTORA_RIZIKA]} />}>
            <Route path="/faktor-rizika-admin" element={<FaktorRizikaAdminView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_OBAVESTENJA]} />}>
            <Route path="/obavestenje-list" element={<ObavestenjeListPage />} />
            <Route path="/crud-obavestenje/:obavestenjeId?" element={<CrudObavestenjePage />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_PACIJENT_POLJA]} />}>
            <Route path="/pacijent-polje-list" element={<PacijentPoljeListPage />} />
            <Route path="/crud-pacijent-polje/:pacijentPoljeId?" element={<CrudPacijentPolje />} />
            <Route path="/crud-pacijent-polje/:pacijentPoljeId/crud-pacijent-polje-kategorija/:pacijentPoljeKategorijaId?" element={<CrudPacijentPoljeKategorija />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_RADNIH_PERIODA]} />}>
            <Route path="/radni-period-list" element={<RadniPeriodListPage />} />
            <Route path="/crud-radni-period/:radniPeriodId?" element={<CrudRadniPeriod />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_IZVESTAJA]} allowedRoles={[EnumRola.SUPERADMIN]} />}>
            <Route path="/izvestaj-list" element={<IzvestajListPage />} />
            <Route path="/crud-izvestaj/:izvestajId?" element={<CrudIzvestajView />} />
            <Route path="/crud-izvestaj/:izvestajId?/crud-parametri-izvestaja/:paramId?" element={<CrudIzvestajParametarView />} />
            <Route path="/crud-izvestaj/:izvestajId?/crud-parametri-izvestaja/:paramId?/parametar-enum-stavka/:paramEnumStavkaId?" element={<CrudIzvestajParametarEnumStavkaView />} />
            <Route path="/crud-kategorija-statistickih-izvestaja/:kategorijaId?" element={<CrudKategorijaStatistickihIzvestajaView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_DELOVODNIH_KNJIGA]} />}>
            <Route path="/protokol-list" element={<ProtokolView />} />
            <Route path="/crud-protokol/:protokolId?" element={<CrudProtokolPage />} />
            <Route path="/crud-protokol-broj/:protokolBrojId" element={<CrudProtokolBrojPage />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.GENERISANJE_IZVESTAJA]} />}>
            <Route path="/generisani-izvestaj-list" element={<GenerisaniIzvestaj />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.PREGLED_LOGA]} />}>
            <Route path="/log-high-level-list" element={<LogHighLevelListPage />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_PAKETA]} />}>
            <Route path="/paket-usluga-list" element={<PaketListView />} />
            <Route path="/paket-usluga/:paketId?" element={<CrudPaketUslugaView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_PARTNERA]} />}>
            <Route path="/partneri-list" element={<PartneriListView />} />
            <Route path="/crud-partner/:partnerId?" element={<CrudPartnerView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.ADMINISTRACIJA_GRUPNIH_OBAVESTENJA]} />}>
            <Route path="/obavestenja-pacijenti-list" element={<ObavestenjaPacijentiListView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.NAPLATA]} />}>
            <Route path="/naplata" element={<NaplataView />} />
          </Route>

          <Route element={<RequireRight allowedRights={[EnumAplikativnaPrava.SPAJANJE_PACIJENATA]} />}>
            <Route path="/spajanje-pacijenata" element={<SpajanjePacijenataView />} />
          </Route>

          <Route element={<ErrorPageView />} />
        </Routes>
      </Suspense>
    </Home>
  );
}
