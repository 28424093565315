import { Button } from "primereact/button";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import KontrolaPravaPristupaService from "../../../../infrastructure/system/KontrolaPravaPristupaService";
import { tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import KontaktUtrosakReadDto from "../../../../model/kontakt/KontaktUtrosakReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import SkeletonRectangles from "../../../app/skeleton/SkeletonRectangles";
import DialogComponent from "../../../dialog-component/DialogComponent";
import KontaktUtrosakDialog from "./KontaktUtrosakDialog";
import KontaktUtrosakLogical from "./KontaktUtrosakLogical";

interface KontaktUtrosakListProps {
  pacijent: PacijentReadDto;
  kontaktUtrosakList: Array<KontaktUtrosakReadDto> | undefined;
  setKontaktUtrosakList: React.Dispatch<React.SetStateAction<Array<KontaktUtrosakReadDto> | undefined>>;
  naplacen: boolean;
}

export default function KontaktUtrosakList(props: KontaktUtrosakListProps) {
  const Labels = useLabels();
  const [utrosakOperation, setUtrosakOperation] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { pristup, authData } = useContext(AppContext);

  const { pacijent, kontaktUtrosakList, setKontaktUtrosakList, naplacen } = props;
  const {
    id,
    dataLoading,
    isOpenUtrosakDialog,
    closeUtrosakDialog,
    kontaktUtrosakChange,
    setKontaktUtrosakChange,
    onCreateUtrosak,
    onUpdateUtrosak,
    onDeleteUtrosak,
    sifarnikProizvodaList,
    setIsOpenUtrosakDialog,
    searchProizvod,
    utrosciLoading,
    isOpenConfirmationDialog,
    closeConfirmationDialog,
  } = KontaktUtrosakLogical(pacijent, kontaktUtrosakList, setKontaktUtrosakList);
  const { hasKontaktProizvodCreate, hasKontaktProizvodUpdate, hasKontaktProizvodDelete } = KontrolaPravaPristupaService();

  const openKontaktUtrosak = (entityOperation: string, kontaktUtrosak?: KontaktUtrosakReadDto) => {
    setUtrosakOperation(entityOperation);
    let naziv = "";
    if (kontaktUtrosak) {
      naziv = kontaktUtrosak?.sifarnikProizvoda?.sifra + " " + kontaktUtrosak?.sifarnikProizvoda?.naziv;
    }
    setKontaktUtrosakChange({
      ...kontaktUtrosak!,
      kontakt: { id: id },
      sifarnikProizvoda: { ...kontaktUtrosak?.sifarnikProizvoda, unosUtroska: naziv },
    });

    setIsDisabled(entityOperation === EntityOperation.READ || entityOperation === EntityOperation.DELETE);
    setIsOpenUtrosakDialog(true);
  };

  return (
    <div className="p-grid p-dir-col">
      {dataLoading ? (
        <SkeletonRectangles />
      ) : (
        <>
          <KontaktUtrosakDialog
            isOpenUtrosakDialog={isOpenUtrosakDialog}
            closeUtrosakDialog={closeUtrosakDialog}
            utrosakOperation={utrosakOperation}
            kontaktUtrosakChange={kontaktUtrosakChange}
            setKontaktUtrosakChange={setKontaktUtrosakChange}
            onCreateUtrosak={onCreateUtrosak}
            onUpdateUtrosak={onUpdateUtrosak}
            onDeleteUtrosak={onDeleteUtrosak}
            sifarnikProizvodaList={sifarnikProizvodaList}
            searchProizvod={searchProizvod}
            isDisabled={isDisabled}
            utrosciLoading={utrosciLoading}
          />
          <div className="p-col flex flex-column">
            <div className="box flex flex-row">
              <div className="col-9 p-0 font-bold align-self-center">{Labels.UTROSAK_LIST_TOKOM_KONTAKTA}</div>
              <div className="col-3 p-0 flex justify-content-end">
                <Button
                  label={Labels.UTROSAK_NOVI}
                  className="p-button-success"
                  onClick={() => openKontaktUtrosak(EntityOperation.CREATE)}
                  disabled={!pristup || !hasKontaktProizvodCreate || naplacen}
                />
              </div>
            </div>
            {kontaktUtrosakList?.map((kontaktUtrosak: KontaktUtrosakReadDto, key: number) => {
              return (
                <div className="box flex flex-row" key={key}>
                  <div className="col-12 pl-0 pr-0">
                    <div className="flex flex-row h-2rem">
                      <div className="col-9 flex p-0">
                        <div className="flex flex-row">
                          <p>{Labels.UTROSAK}</p>
                          <p className="font-bold pl-2">{kontaktUtrosak?.sifarnikProizvoda.sifra + " " + kontaktUtrosak?.sifarnikProizvoda?.naziv}</p>
                        </div>
                      </div>
                      <div className="col-3 flex justify-content-end p-0">
                        <div className="flex flex-row">
                          <Button
                            type="button"
                            tooltip={Labels.UTROSAK_IZMENI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-warning mr-2"
                            icon="pi pi-pencil"
                            onClick={() => {
                              kontaktUtrosak && openKontaktUtrosak(EntityOperation.UPDATE, kontaktUtrosak);
                            }}
                            disabled={!pristup || (authData?.currentRadnik.id === kontaktUtrosak.radnikKreirao.id ? false : !hasKontaktProizvodUpdate) || naplacen}
                          />
                          <Button
                            type="button"
                            tooltip={Labels.UTROSAK_OBRISI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-danger"
                            icon="pi pi-trash"
                            onClick={() => {
                              kontaktUtrosak && openKontaktUtrosak(EntityOperation.DELETE, kontaktUtrosak);
                            }}
                            disabled={!pristup || (authData?.currentRadnik.id === kontaktUtrosak.radnikKreirao.id ? false : !hasKontaktProizvodDelete) || naplacen}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row h-2rem">
                      <p>{Labels.UTROSAK_POTROSENA_KOLICINA}</p>
                      <p className="pl-2">{kontaktUtrosak?.utrosenaKolicina}</p>
                    </div>
                    {kontaktUtrosak.napomena && (
                      <div className="flex flex-row h-2rem">
                        <p>{Labels.UTROSAK_NAPOMENA}</p>
                        <p className="pl-2">{kontaktUtrosak.napomena}</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <DialogComponent
        header=""
        visible={isOpenConfirmationDialog}
        onHide={closeConfirmationDialog}
        content={<div className="text-xl font-semibold">{Labels.NO_PROIZVOD_CENA_WARNING}</div>}
        footer={
          <div className="layout-pacijent-dosije">
            <Button
              label={Labels.BUTTON_CANCEL}
              className="p-button-outlined p-button-tertiary align-self-center"
              onClick={() => {
                closeConfirmationDialog();
                closeUtrosakDialog();
              }}
            />
            <Button
              label={Labels.BUTTON_CONFIRM}
              className="p-button-success"
              onClick={utrosakOperation === EntityOperation.CREATE ? () => onCreateUtrosak(true) : utrosakOperation === EntityOperation.UPDATE ? () => onUpdateUtrosak(true) : undefined}
              disabled={!pristup}
            />
          </div>
        }
      />
    </div>
  );
}
