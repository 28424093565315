import { valueMap } from "../../../../components/formular/FormularLogical";
import GenerisaniIzvestajParametarReadDto from "../../../../model/generisaniIzvestaj/GenerisaniIzvestajParametarReadDto";
import IzvestajSimpleReadDto from "../../../../model/izvestaj/IzvestajSimpleReadDto";
import ZakazanIzvestajReadDto from "../../../../model/zakazaniIzvestaj/ZakazanIzvestajReadDto";

export interface InitialStateZakazaniIzvestajType {
  paramsValue: valueMap;
  editSelected: boolean;
  selectedIzvestajForEdit?: any;
  selectedIzvestaj?: IzvestajSimpleReadDto;
  izvestajList: Array<ZakazanIzvestajReadDto>;
  izvestajListLoading: boolean;
  onlyMine: boolean;
  emptyFields: boolean;
  formParams: Array<GenerisaniIzvestajParametarReadDto>;
}

const InitialStateZakazaniIzvestaj: InitialStateZakazaniIzvestajType = {
  izvestajList: [],
  paramsValue: [],
  editSelected: false,
  selectedIzvestajForEdit: undefined,
  selectedIzvestaj: undefined,
  izvestajListLoading: false,
  onlyMine: false,
  emptyFields: false,
  formParams: [],
};

export default InitialStateZakazaniIzvestaj;
