import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import UlogaAplikativnoPravoCreateDto from "../../model/uloga/UlogaAplikativnoPravoCreateDto";
import UlogaPravoNadTipomCreateDto from "../../model/uloga/UlogaPravoNadTipomCreateDto";
import UlogaReadDto from "../../model/uloga/UlogaReadDto";

interface UlogaControllerType {
  axiosSearchUloga: (ulogaSearchData: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosCreateUloga: (ulogaChange: UlogaReadDto) => Promise<AxiosResponse<any>>;
  axiosReadUloga: (ulogaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateUloga: (ulogaChange: UlogaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteUloga: (ulogaId: number) => Promise<AxiosResponse<any>>;
  axiosGetUlogaRadnikList: (ulogaId: number) => Promise<AxiosResponse<any>>;
  axiosPostUlogaAplikativnoPravoList: (ulogaID: number, body: UlogaAplikativnoPravoCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetUlogaAplikativnoPravoList: (ulogaID: number) => Promise<AxiosResponse<any>>;
  axiosDeleteUlogaAplikativnoPravo: (ulogaID: number, sifraAplikativnoPravo: string) => Promise<AxiosResponse<any>>;
  axiosPostUlogaPravoNadTipomList: (ulogaID: number, body: UlogaPravoNadTipomCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetUlogaPravoNadTipomList: (ulogaID: number) => Promise<AxiosResponse<any>>;
  axiosDeleteUlogaPravoNadTipom: (ulogaID: number, sifraVrstaPrava?: string, sifraTipObjekta?: string) => Promise<AxiosResponse<any>>;
}

export function UlogaController(): UlogaControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchUloga = (ulogaSearchData: SearchBaseDto) => {
    return axios.get(Endpoint.ULOGA_SEARCH, axiosConfig(authData!.token, ulogaSearchData));
  };

  const axiosReadUloga = (ulogaId: number) => {
    return axios.get(`${Endpoint.ULOGA_LIST}/${ulogaId}`, axiosConfig(authData!.token));
  };

  const axiosCreateUloga = (ulogaChange: UlogaReadDto) => {
    return axios.post(Endpoint.ULOGA_LIST, ulogaChange ? ulogaChange : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateUloga = (ulogaChange: UlogaReadDto) => {
    return axios.put(`${Endpoint.ULOGA_LIST}/${ulogaChange.id}`, ulogaChange, axiosConfig(authData!.token));
  };

  const axiosDeleteUloga = (ulogaId: number) => {
    return axios.delete(`${Endpoint.ULOGA_LIST}/${ulogaId}`, axiosConfig(authData!.token));
  };

  const axiosGetUlogaRadnikList = (ulogaId: number) => {
    return axios.get(`${Endpoint.ULOGA_LIST}/${ulogaId}/radnik-list`, axiosConfig(authData!.token));
  };

  const axiosPostUlogaAplikativnoPravoList = (ulogaID: number, body: UlogaAplikativnoPravoCreateDto) => {
    return axios.post(`${Endpoint.ULOGA_LIST}/${ulogaID}${Endpoint.APLIKATIVNO_PRAVO_SUFIX}`, body, axiosConfig(authData!.token));
  };

  const axiosGetUlogaAplikativnoPravoList = (ulogaID: number) => {
    return axios.get(`${Endpoint.ULOGA_LIST}/${ulogaID}${Endpoint.APLIKATIVNO_PRAVO_SUFIX}`, axiosConfig(authData!.token));
  };

  const axiosDeleteUlogaAplikativnoPravo = (ulogaID: number, sifraAplikativnoPravo: string) => {
    return axios.delete(`${Endpoint.ULOGA_LIST}/${ulogaID}${Endpoint.APLIKATIVNO_PRAVO_SUFIX}/${sifraAplikativnoPravo}`, axiosConfig(authData!.token));
  };

  const axiosPostUlogaPravoNadTipomList = (ulogaID: number, body: UlogaPravoNadTipomCreateDto) => {
    return axios.post(`${Endpoint.ULOGA_LIST}/${ulogaID}${Endpoint.PRAVO_NAD_TIPOM_SUFIX}`, body, axiosConfig(authData!.token));
  };

  const axiosGetUlogaPravoNadTipomList = (ulogaID: number) => {
    return axios.get(`${Endpoint.ULOGA_LIST}/${ulogaID}${Endpoint.PRAVO_NAD_TIPOM_SUFIX}`, axiosConfig(authData!.token));
  };

  const axiosDeleteUlogaPravoNadTipom = (ulogaID: number, sifraVrstaPrava?: string, sifraTipObjekta?: string) => {
    return axios.delete(`${Endpoint.ULOGA_LIST}/${ulogaID}${Endpoint.PRAVO_NAD_TIPOM_SUFIX}`, axiosConfig(authData!.token, { sifraVrstaPrava, sifraTipObjekta }));
  };

  return {
    axiosSearchUloga,
    axiosCreateUloga,
    axiosUpdateUloga,
    axiosDeleteUloga,
    axiosReadUloga,
    axiosGetUlogaRadnikList,
    axiosPostUlogaAplikativnoPravoList,
    axiosGetUlogaAplikativnoPravoList,
    axiosDeleteUlogaAplikativnoPravo,
    axiosPostUlogaPravoNadTipomList,
    axiosGetUlogaPravoNadTipomList,
    axiosDeleteUlogaPravoNadTipom,
  };
}
