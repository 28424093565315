interface IconSchedulerProps {
  color: string;
  height?: string;
}

const IconScheduler = ({ color, height }: IconSchedulerProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height ?? "100%"} viewBox="0 0 23 24.511">
      <g id="hospital-shield" transform="translate(-1.25 0.251)">
        <path
          id="Path_1091"
          data-name="Path 1091"
          d="M17.25,9a.75.75,0,0,0-.75-.75H14.25V6a.75.75,0,0,0-.75-.75h-3A.75.75,0,0,0,9.75,6V8.25H7.5A.75.75,0,0,0,6.75,9v3a.75.75,0,0,0,.75.75H9.75V15a.75.75,0,0,0,.75.75h3a.75.75,0,0,0,.75-.75V12.75H16.5a.75.75,0,0,0,.75-.75Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1092"
          data-name="Path 1092"
          d="M2.25,3.923v7.614A11.907,11.907,0,0,0,9.882,22.65l1.041.4a3,3,0,0,0,2.154,0l1.041-.4A11.907,11.907,0,0,0,21.75,11.537V3.923a1.486,1.486,0,0,0-.868-1.362A21.7,21.7,0,0,0,12,.75,21.7,21.7,0,0,0,3.118,2.561,1.486,1.486,0,0,0,2.25,3.923Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default IconScheduler;
