import moment from "moment";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Image } from "primereact/image";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import actionsAddPatientNotification from "../../../infrastructure/system/hooks/dialog-add-patient-notification/actionsAddPatientNotification";
import { InitialStateAddPatientNotificationType } from "../../../infrastructure/system/hooks/dialog-add-patient-notification/initialStateAddPatientNotification";
import Images from "../../../infrastructure/system/Images";
import { DATE_FORMAT, stripHTML, TIME_FORMAT, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import EnumObavestenjeStatus from "../../../model/obavestenja-pacijenti/EnumObavestenjeStatus";
import EnumTipObavestenja from "../../../model/obavestenja-pacijenti/EnumTipObavestenja";
import GrupnoObavestenjeSimpleReadDto from "../../../model/obavestenja-pacijenti/GrupnoObavestenjeSimpleReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import { useLabels } from "../../../Store";

interface ObavestenjeItemPropsType {
  obavestenje: GrupnoObavestenjeSimpleReadDto;
  state: InitialStateAddPatientNotificationType;
  dispatch: React.Dispatch<{ type: actionsAddPatientNotification }> | any;
  onDelete: (obavestenje: GrupnoObavestenjeSimpleReadDto) => void;
  fetchObavestenje: (obavestenjeId: number, action: string, status: EnumBaseReadDto) => void;
  fetchObavestenjePreSlanja: (obavestenje: GrupnoObavestenjeSimpleReadDto) => void;
}

export default function ObavestenjeItem(props: ObavestenjeItemPropsType) {
  const { obavestenje, dispatch, onDelete, fetchObavestenje, fetchObavestenjePreSlanja } = props;
  const Labels = useLabels();

  return (
    <div className="flex flex-row flex-wrap" key={obavestenje.id}>
      <div className={`col-12 ${obavestenje?.obavestenjeStatus?.sifra === EnumObavestenjeStatus.NA_CEKANJU ? "sm:col-9" : ""}`}>
        <div
          className={`flex flex-column justify-content-between relative px-3 py-4 notification-list__item ${
            obavestenje?.obavestenjeStatus?.sifra === EnumObavestenjeStatus.NA_CEKANJU && obavestenje?.datumAutomatskogSlanja ? "scheduled" : ""
          }`}
        >
          <div className="flex justify-content-between">
            <div className="notification-list__item--title mb-3 font-bold">{obavestenje.obavestenjeTip.sifra === EnumTipObavestenja.EMAIL && obavestenje.naslov}</div>
            <div className="notification-list__item--title mb-3 flex">
              {obavestenje?.obavestenjeStatus?.sifra === EnumObavestenjeStatus.POSLATO ? (
                <div className="flex mr-1">
                  <Image width={"20px"} src={Images.ICON_DOUBLE_CHECK} /> {Labels.LABEL_OBAVESTENJE_POSLATO_VREME} {moment(obavestenje.vremeSlanja).format(DATE_FORMAT)}{" "}
                  {Labels.LABEL_OBAVESTENJE_KREIRANO_U} {moment(obavestenje.vremeSlanja).format(TIME_FORMAT)}
                </div>
              ) : (
                <>
                  {Labels.LABEL_OBAVESTENJE_KREIRANO_VREME} {moment(obavestenje.vremeKreiranja).format(DATE_FORMAT)} {Labels.LABEL_OBAVESTENJE_KREIRANO_U}{" "}
                  {moment(obavestenje.vremeKreiranja).format(TIME_FORMAT)}
                </>
              )}{" "}
            </div>
          </div>
          <div className="notification-list__item--text mb-3">{stripHTML(obavestenje.sadrzaj)}</div>
          <div className="flex justify-content-between">
            <div className="notification-list__item--type flex align-items-center">
              <i className={`pi ${obavestenje.obavestenjeTip.sifra === EnumTipObavestenja.SMS ? "pi-comment" : "pi-envelope"} mr-2`}></i>
              {obavestenje.obavestenjeTip.sifra === EnumTipObavestenja.SMS ? Labels.LABEL_SMS : Labels.LABEL_EMAIL}
            </div>
            <div className="notification-list__item--type flex align-items-center">
              {obavestenje?.obavestenjeStatus?.sifra !== EnumObavestenjeStatus.POSLATO ? (
                <div className="mr-4">
                  {Labels.LABEL_OBAVESTENJE_POSLEDNJE_AZURIRANO} {moment(obavestenje?.vremePoslednjegAzuriranjaPacijenata).format(DATE_FORMAT)}
                  {` `}
                  {Labels.LABEL_OBAVESTENJE_POSLEDNJE_AZURIRANO_U} {moment(obavestenje?.vremePoslednjegAzuriranjaPacijenata).format(TIME_FORMAT)}
                </div>
              ) : (
                <></>
              )}
              <i className="pi pi-send mr-2"></i> {obavestenje?.ukupnoPacijenata}
            </div>
          </div>
          <div className="notification-list__item--actions px-2">
            <i
              className="pi pi-eye mr-4"
              onClick={() => {
                dispatch({ type: actionsAddPatientNotification.OPEN_DIALOG, operation: EntityOperation.READ });
                fetchObavestenje(obavestenje.id, EntityOperation.READ, obavestenje.obavestenjeStatus);
              }}
            />
            {obavestenje?.obavestenjeStatus?.sifra === EnumObavestenjeStatus.NA_CEKANJU && (
              <i
                className={obavestenje?.datumAutomatskogSlanja == null || new Date(obavestenje?.datumAutomatskogSlanja) > new Date() ? "pi pi-pencil mr-4" : "hidden"}
                onClick={() => {
                  if (obavestenje?.datumAutomatskogSlanja == null || new Date(obavestenje?.datumAutomatskogSlanja) > new Date()) {
                    dispatch({ type: actionsAddPatientNotification.OPEN_DIALOG, operation: EntityOperation.UPDATE });
                    fetchObavestenje(obavestenje.id, EntityOperation.UPDATE, obavestenje.obavestenjeStatus);
                  }
                }}
              />
            )}
            <i
              className="pi pi-trash"
              onClick={() => {
                confirmDialog({
                  className: "dialogDeleteNotification",
                  acceptLabel: Labels.BUTTON_DELETE,
                  acceptIcon: "pi pi-check",
                  rejectLabel: Labels.BUTTON_CANCEL,
                  rejectClassName: "p-button-outlined mr-5",
                  rejectIcon: "pi pi-times",
                  message: <span className="font-bold">{Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_DELETE_CONFIRM_MESSAGE}</span>,
                  accept: () => {
                    onDelete(obavestenje);
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      {obavestenje?.obavestenjeStatus?.sifra === EnumObavestenjeStatus.NA_CEKANJU && !obavestenje.datumAutomatskogSlanja && (
        <div className="col-12 sm:col-3">
          <Button
            icon="pi pi-send"
            label={Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE_POSALJI}
            tooltip={Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE_POSALJI}
            tooltipOptions={tooltipOptionsTop}
            onClick={() => {
              fetchObavestenjePreSlanja(obavestenje);
            }}
          />
        </div>
      )}
      {obavestenje?.obavestenjeStatus?.sifra === EnumObavestenjeStatus.NA_CEKANJU && obavestenje?.datumAutomatskogSlanja && (
        <div className="col-12 sm:col-3">
          <div className="notification-list__scheduled-item-info p-2">
            <div className="mb-1">{Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_TEXT}:</div>
            <div className="mb-1">
              {Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_DATE}: <span className="font-bold">{moment(obavestenje?.datumAutomatskogSlanja).format(DATE_FORMAT)}</span>
            </div>
            <div>
              {Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_TIME}: <span className="font-bold">{moment(obavestenje.datumAutomatskogSlanja).format(TIME_FORMAT)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
