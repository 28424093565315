import { EnumController } from "../../../../controllers/enum/EnumController";
import { useQuery } from "@tanstack/react-query";
import QueryKeys from "../../QueryKeys";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";

export function usePolList() {
  const { axiosGetPolList } = EnumController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.POL_LIST],
    queryFn: () => axiosGetPolList(),
  });

  const polList: Array<EnumBaseReadDto> = data?.data?.data ?? [];

  return { polList, refetch, isLoading };
}
