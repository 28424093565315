import { InputNumber } from "primereact/inputnumber";
import { PoljeType } from "../Polje";

export default function Decimalni(props: PoljeType) {
  const { id, onChange, vrednost, readonly, decimale, useVrednostList, vrednostList } = props;

  return (
    <InputNumber
      disabled={readonly ?? false}
      mode="decimal"
      className="w-full"
      minFractionDigits={1}
      maxFractionDigits={decimale ?? 7}
      onChange={(e) => {
        onChange({ id: id, vrednost: e.value ?? undefined });
      }}
      value={useVrednostList && vrednostList ? vrednostList[id] ?? null : vrednost ?? null}
    />
  );
}
