import axios from "axios";
import moment from "moment";
import { FileUploadHandlerEvent } from "primereact/fileupload";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ObavestenjeController } from "../../../controllers/obavestenje/ObavestenjeController";
import { OrganizacionaJedinicaController } from "../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { UlogaController } from "../../../controllers/uloga/UlogaController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isDropDownFieldDisabled, isFormDisabled, MAX_FILE_UPLOAD, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import ObavestenjeReadDto from "../../../model/obavestenje/ObavestenjeReadDto";
import OrganizacionaJedinicaReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import UlogaReadDto from "../../../model/uloga/UlogaReadDto";
import { AppContext, useLabels } from "../../../Store";

interface ObavestenjeCrudLogicalType {
  obavestenjeChange: ObavestenjeReadDto | undefined;
  setObavestenjeChange: any;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  isDisabled: boolean;
  isDropDownDisabled: boolean;
  breadCrumbItems: Array<any>;
  obavestenjeOperation: string;
  onCancel: () => void;
  onDelete: () => void;
  onCreate: () => void;
  obavestenjeId: number;
  location: any;
  obavestenjeList: Array<ObavestenjeReadDto> | undefined;
  ulogaList: Array<UlogaReadDto> | undefined;
  organizacionaJedinicaList: Array<OrganizacionaJedinicaReadDto> | undefined;
  obavestenjeLoading: boolean;
  uploadHandler: (e: FileUploadHandlerEvent) => void;
  removeDatoteka: (file: File) => void;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
}

interface UseParamsType {
  obavestenjeId?: string;
}
interface Datoteka {
  sadrzaj: string;
  naziv: string;
  tip: string;
  velicina?: number;
}

export default function CrudObavestenjeLogical(): ObavestenjeCrudLogicalType {
  const location = useLocation();
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const obavestenjeId = Number(useParams<keyof UseParamsType>()["obavestenjeId"]);
  const [obavestenjeOperation] = useState<string>(
    location.state && location.state.obavestenjeOperation !== undefined ? location.state.obavestenjeOperation : !isNaN(obavestenjeId) ? EntityOperation.DELETE : EntityOperation.CREATE
  );
  const [isDisabled] = useState<boolean>(isFormDisabled(obavestenjeOperation));
  const [isDropDownDisabled] = useState<boolean>(isDropDownFieldDisabled(obavestenjeOperation));
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [index, setIndex] = useState<number>(tabIndex);
  const [obavestenjeChange, setObavestenjeChange] = useState<ObavestenjeReadDto>();
  const [ulogaList, setUlogaList] = useState<Array<UlogaReadDto> | undefined>();
  const [datotekaList, setDatotekaList] = useState<Array<Datoteka>>([]);
  const [organizacionaJedinicaList, setOrganizacionaJedinicaList] = useState<Array<OrganizacionaJedinicaReadDto> | undefined>();
  const { axiosGetObavestenje, axiosCreateObavestenje, axiosDeleteObavestenje, axiosCreateObavestenjeDatoteka } = ObavestenjeController();
  const { axiosSearchUloga } = UlogaController();
  const { axiosSearchOrganizacionaJedinica } = OrganizacionaJedinicaController();
  const [obavestenjeList] = useState<Array<ObavestenjeReadDto>>([]);
  const [obavestenjeLoading, setObavestenjeLoading] = useState(false);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const navigate = useNavigate();
  const [breadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.OBAVESTENJE_LIST,
      command: () => {
        navigate("/obavestenje-list/");
      },
    },
    {
      label: obavestenjeOperation === EntityOperation.DELETE ? Labels.OBAVESTENJE_TITLE_DIALOG_DELETE : Labels.OBAVESTENJE_TITLE_DIALOG_CREATE,
    },
  ]);
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchData();
  });

  const validateInput = (obavestenje: ObavestenjeReadDto | undefined) => {
    let isInvalid = false;
    if (validateStringEmpty(obavestenje?.naslov)) {
      setInvalidFields((prev) => ({ ...prev, naslov: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(obavestenje?.sadrzaj)) {
      setInvalidFields((prev) => ({ ...prev, sadrzaj: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(obavestenjeChange)) {
      return;
    }
    if (obavestenjeChange) {
      setShowBlockUI(true);
      axiosCreateObavestenje(obavestenjeChange)
        .then(({ data: { data } }: { data: { data: ObavestenjeReadDto } }) => {
          // Zapisuje se kreiranje obavestenja u log high level-u
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_OBAVESTENJE_1 + obavestenjeChange?.naslov + Labels.LOG_HIGH_LEVEL_MESS_CREATE_OBAVESTENJE_2 + obavestenjeChange?.sadrzaj);

          datotekaList.forEach((d: Datoteka) => {
            axiosCreateObavestenjeDatoteka({
              obavestenjeID: data.id,
              sadrzaj: d.sadrzaj,
              naziv: d.naziv,
              tip: d.tip,
              velicina: d.velicina,
            })
              .then(() => {
                showMessage(MessageType.SUCCESS, Labels.OBAVESTENJE_TITLE_MESSAGE_CREATE_OBAVESTENJE_DATOTEKA_SUCCESS);
                navigate(`/obavestenje-list`);
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
              })
              .finally(() => {
                setShowBlockUI(false);
              });
          });
          showMessage(MessageType.SUCCESS, Labels.OBAVESTENJE_TITLE_MESSAGE_CREATE_OBAVESTENJE_SUCCESS);
          navigate(`/obavestenje-list`);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    const requestDeleteObavestenje = axiosDeleteObavestenje(obavestenjeId);
    requestDeleteObavestenje
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.OBAVESTENJE_TITLE_MESSAGE_DELETE_OBAVESTENJE_SUCCESS);
        onCancel();

        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_OBAVESTENJE_1 + obavestenjeChange?.naslov + Labels.LOG_HIGH_LEVEL_MESS_DELETE_OBAVESTENJE_2 + obavestenjeChange?.sadrzaj);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/obavestenje-list`);
    }
  };

  const fetchData = () => {
    fetchUlogaAndOrganizacionaJedinica();

    if (obavestenjeId) {
      const startTime = moment(new Date());
      setObavestenjeLoading(true);
      const requestObavestenje = axiosGetObavestenje(obavestenjeId);
      axios
        .all([requestObavestenje])
        .then(
          axios.spread(({ data: { data } }: { data: { data: ObavestenjeReadDto } }) => {
            setObavestenjeChange(data);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setObavestenjeLoading, startTime);
        });
    }
  };

  const fetchUlogaAndOrganizacionaJedinica = () => {
    const requestUloge = axiosSearchUloga({});
    const requestOrganizacionaJedinica = axiosSearchOrganizacionaJedinica({});
    axios.all([requestUloge, requestOrganizacionaJedinica]).then(
      axios.spread((responseUloge, responseOrganizacioneJedinice) => {
        setUlogaList(responseUloge.data.data);
        setOrganizacionaJedinicaList(responseOrganizacioneJedinice.data.data);
      })
    );
  };

  const uploadHandler = (e: FileUploadHandlerEvent) => {
    setShowBlockUI(true);
    const datotekeLista: Array<Datoteka> = [];
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }
        if (reader.result) {
          datotekeLista.push({
            sadrzaj: reader.result.toString().substr(reader.result.toString().indexOf(",") + 1),
            naziv: file.name,
            tip: file.type,
            velicina: file.size,
          });
        }
      };
    });
    setDatotekaList(datotekeLista);
    setShowBlockUI(false);
  };

  const removeDatoteka = (file: File) => {
    setShowBlockUI(true);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (file.size > MAX_FILE_UPLOAD) {
        showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
        return;
      }
      if (reader.result) {
        let pom = datotekaList;

        let dat: Datoteka = {
          sadrzaj: reader.result.toString().substr(reader.result.toString().indexOf(",") + 1),
          naziv: file.name,
          tip: file.type,
          velicina: file.size,
        };

        let fileRemove = pom.findIndex((e) => {
          return e.naziv === dat.naziv && e.sadrzaj === dat.sadrzaj && e.tip === dat.tip;
        });

        delete pom[fileRemove];
        setDatotekaList(pom);
      }
    };

    setShowBlockUI(false);
  };

  return {
    obavestenjeChange,
    setObavestenjeChange,
    setIndex,
    index,
    isDisabled,
    isDropDownDisabled,
    breadCrumbItems,
    obavestenjeOperation,
    obavestenjeId,
    obavestenjeLoading,
    obavestenjeList,
    organizacionaJedinicaList,
    ulogaList,
    location,
    onCreate,
    onCancel,
    onDelete,
    uploadHandler,
    removeDatoteka,
    invalidFields,
    setInvalidFields,
  };
}
