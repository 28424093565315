import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import FormularCreateDto from "../../model/formular/FormularCreateDto";
import FormularPopunjenReadDto from "../../model/formular/FormularPopunjenReadDto";
import FormularPopunjenSaPoljimaDto from "../../model/formular/FormularPopunjenSaPoljimaDto";
import FormularReadDto from "../../model/formular/FormularReadDto";
import FormularSaPoljimaCreateDto from "../../model/formular/FormularSaPoljimaCreateDto";
import PoljeReadDto from "../../model/polje/PoljeReadDto";
import FormularCopySaPoljimaDto from "../../model/formular/FormularCopySaPoljimaDto";
import FormularSaPoljimaUpdateDto from "../../model/formular/FormularSaPoljimaUpdateDto";

interface FormularControllerType {
  axiosSearchFormular: (formularSearchData: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosCreateFormular: (formularChange: FormularCreateDto) => Promise<AxiosResponse<any>>;
  axiosUpdateFormular: (formularChange: FormularReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteFormular: (formularId: number) => Promise<AxiosResponse<any>>;
  axiosGetFormular: (formularId: number) => Promise<AxiosResponse<any>>;
  axiosGetPoljeList: (formularId: number, kontaktID?: number) => Promise<AxiosResponse<any>>;
  axiosDeletePolje: (formularId: number, poljeId: number) => Promise<AxiosResponse<any>>;
  axiosCreatePolje: (formularId: number, polje: PoljeReadDto) => Promise<AxiosResponse<any>>;
  axiosUpdatePolje: (formularId: number, poljeChange: PoljeReadDto) => Promise<AxiosResponse<any>>;
  axiosReadPolje: (poljeId: number, formularId: number) => Promise<AxiosResponse<any>>;
  axiosGetPoljePopunjenoList: (formularPopunjenId: number) => Promise<AxiosResponse<any>>;
  axiosCreateFormularPopunjen: (formularObj: FormularPopunjenSaPoljimaDto) => Promise<AxiosResponse<any>>;
  axiosUpdateFormularPopunjen: (formularObj: FormularSaPoljimaUpdateDto, formularPopunjenId: number) => Promise<AxiosResponse<any>>;
  axiosGetFormularPrint: (formularPopunjen: FormularPopunjenReadDto) => Promise<AxiosResponse<any>>;
  axiosGetFormularPopunjenWithDokumentList: (pacijentID: number) => Promise<AxiosResponse<any>>;
  axiosCreateFormularPopunjenAndPrint: (formularObj: FormularSaPoljimaCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteFormularPopunjen: (formularPopunjenId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteFormularPopunjenFromDokumentList: (formularPopunjenId: number) => Promise<AxiosResponse<any>>;
  axiosCopyFormularAndPoljeList: (formularSaPoljimaChange: FormularCopySaPoljimaDto) => Promise<AxiosResponse<any>>;
  axiosGetPoljePanelList: (formularId: number) => Promise<AxiosResponse<any>>;
  axiosSearchFormularByKontaktTip: (kontaktTipID: number, searchString?: SearchBaseDto) => Promise<AxiosResponse<any>>;
}

export function FormularController(): FormularControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchFormular = (formularSearchData: SearchBaseDto) => {
    return axios.get(Endpoint.FORMULAR_SEARCH, axiosConfig(authData!.token, formularSearchData));
  };

  const axiosSearchFormularByKontaktTip = (kontaktTipID: number, formularSearchData?: SearchBaseDto) => {
    return axios.get(`${Endpoint.FORMULAR_LIST}/kontakt-tip-list/${kontaktTipID}`, axiosConfig(authData!.token, formularSearchData));
  };

  const axiosGetFormular = (formularId: number) => {
    return axios.get(`${Endpoint.FORMULAR_LIST}/${formularId}`, axiosConfig(authData!.token));
  };

  const axiosCreateFormular = (formularChange: FormularCreateDto) => {
    return axios.post(`${Endpoint.FORMULAR_LIST}`, formularChange ? formularChange : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateFormular = (formularChange: FormularReadDto) => {
    return axios.put(`${Endpoint.FORMULAR_LIST}/${formularChange.id}`, formularChange, axiosConfig(authData!.token));
  };

  const axiosDeleteFormular = (formularId: number) => {
    return axios.delete(`${Endpoint.FORMULAR_LIST}/${formularId}`, axiosConfig(authData!.token));
  };

  const axiosGetPoljeList = (formularId: number, kontaktID?: number) => {
    return axios.get(`${Endpoint.FORMULAR_LIST}/${formularId}/polje-list`, axiosConfig(authData!.token, { kontaktID: kontaktID }));
  };

  const axiosDeletePolje = (formularId: number, poljeId: number) => {
    return axios.delete(`${Endpoint.FORMULAR_LIST}/${formularId}/polje-list/${poljeId}`, axiosConfig(authData!.token));
  };

  const axiosCreatePolje = (formularId: number, polje: PoljeReadDto) => {
    return axios.post(`${Endpoint.FORMULAR_LIST}/${formularId}/polje-list`, polje ? polje : {}, axiosConfig(authData!.token));
  };

  const axiosUpdatePolje = (formularId: number, poljeChange: PoljeReadDto) => {
    return axios.put(`${Endpoint.FORMULAR_LIST}/${formularId}/polje-list/${poljeChange.id}`, poljeChange, axiosConfig(authData!.token));
  };

  const axiosReadPolje = (poljeId: number, formularId: number) => {
    return axios.get(`${Endpoint.FORMULAR_LIST}/${formularId}/polje-list/${poljeId}`, axiosConfig(authData!.token));
  };

  const axiosGetPoljePopunjenoList = (formularPopunjenId: number) => {
    return axios.get(`${Endpoint.FORMULAR_POPUNJEN_LIST}/${formularPopunjenId}/polje-popunjeno-list`, axiosConfig(authData!.token));
  };

  const axiosGetFormularPrint = (formularPopunjen: FormularPopunjenReadDto) => {
    return axios.put(`${Endpoint.FORMULAR_POPUNJEN_LIST}/${formularPopunjen.id}/formular-print`, formularPopunjen, axiosConfig(authData!.token));
  };

  const axiosCreateFormularPopunjen = (formularObj: FormularPopunjenSaPoljimaDto) => {
    return axios.post(Endpoint.FORMULAR_POPUNJEN_LIST, formularObj ? formularObj : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateFormularPopunjen = (formularObj: FormularSaPoljimaUpdateDto, formularPopunjenId: number) => {
    return axios.put(`${Endpoint.FORMULAR_POPUNJEN_LIST}/${formularPopunjenId}`, formularObj ? formularObj : {}, axiosConfig(authData!.token));
  };

  const axiosGetFormularPopunjenWithDokumentList = (pacijentID: number) => {
    return axios.get(`${Endpoint.FORMULAR_POPUNJEN_LIST}/formularPopunjentWithDokumentList/${pacijentID}`, axiosConfig(authData!.token));
  };

  const axiosCreateFormularPopunjenAndPrint = (formularObj: FormularSaPoljimaCreateDto) => {
    return axios.post(`${Endpoint.FORMULAR_POPUNJEN_LIST}/formular-save-and-print`, formularObj ? formularObj : {}, axiosConfig(authData!.token));
  };

  const axiosDeleteFormularPopunjen = (formularPopunjenId: number) => {
    return axios.delete(`${Endpoint.FORMULAR_POPUNJEN_LIST}/${formularPopunjenId}`, axiosConfig(authData!.token));
  };

  const axiosDeleteFormularPopunjenFromDokumentList = (formularPopunjenId: number) => {
    return axios.delete(`${Endpoint.FORMULAR_POPUNJEN_LIST}/${formularPopunjenId}/print`, axiosConfig(authData!.token));
  };

  const axiosCopyFormularAndPoljeList = (formularSaPoljimaChange: FormularCopySaPoljimaDto) => {
    return axios.post(`${Endpoint.FORMULAR_LIST}/copy-formular`, formularSaPoljimaChange ? formularSaPoljimaChange : {}, axiosConfig(authData!.token));
  };

  const axiosGetPoljePanelList = (formularId: number) => {
    return axios.get(`${Endpoint.FORMULAR_LIST}/${formularId}/polje-panel-list`, axiosConfig(authData!.token));
  };

  return {
    axiosSearchFormular,
    axiosCreateFormular,
    axiosUpdateFormular,
    axiosDeleteFormular,
    axiosGetFormular,
    axiosGetPoljeList,
    axiosDeletePolje,
    axiosCreatePolje,
    axiosUpdatePolje,
    axiosReadPolje,
    axiosGetPoljePopunjenoList,
    axiosCreateFormularPopunjen,
    axiosUpdateFormularPopunjen,
    axiosGetFormularPrint,
    axiosGetFormularPopunjenWithDokumentList,
    axiosCreateFormularPopunjenAndPrint,
    axiosDeleteFormularPopunjen,
    axiosDeleteFormularPopunjenFromDokumentList,
    axiosCopyFormularAndPoljeList,
    axiosGetPoljePanelList,
    axiosSearchFormularByKontaktTip,
  };
}
