import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column, ColumnBodyOptions, ColumnEditorOptions } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable, DataTableExpandedRows, DataTableValueArray } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { FormEvent, useContext, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { DATE_FORMAT, checkEmpty, formatDate, handleAxiosCallError, isDateFormatValid, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import { usePagination } from "../../../infrastructure/system/hooks/tables/usePagination";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import VerzijaReadDto from "../../../model/verzija/VerzijaReadDto";
import VerzijaStavkaCreateDto from "../../../model/verzija/VerzijaStavkaCreateDto";
import VerzijaStavkaReadDto from "../../../model/verzija/VerzijaStavkaReadDto";
import VerzijaStavkaUpdateDto from "../../../model/verzija/VerzijaStavkaUpdateDto";
import VerzijaUpdateDto from "../../../model/verzija/VerzijaUpdateDto";
import CalendarComponent from "../../administracija/calendar-component/CalendarComponent";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import DialogCreateVerzija from "./DialogCreateVerzija";
import { useCreateVerzijaStavka } from "./hooks/useCreateVerzijaStavka";
import { useDeleteVerzija } from "./hooks/useDeleteVerzija";
import { useDeleteVerzijaStavka } from "./hooks/useDeleteVerzijaStavka";
import { useUpdateVerzija } from "./hooks/useUpdateVerzija";
import { useUpdateVerzijaStavka } from "./hooks/useUpdateVerzijaStavka";
import { useVerzijaList } from "./hooks/useVerzijaList";

export default function VerzijaListAdminView() {
  const Labels = useLabels();
  const { showMessage } = useContext(AppContext);

  const { first, size, onPage } = usePagination();
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>();
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

  const breadCrumbItems: BreadCrumbItemDto[] = [
    {
      label: Labels.VERZIJE,
    },
  ];
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const columnsProperty: SkeletonTableColumnProperty[] = [{ columnName: Labels.LABEL_NAZIV, filter: true, sortrable: true }];

  const { data: verzijaList, isLoading } = useVerzijaList();
  const verzijaUpdateMutation = useUpdateVerzija();
  const verzijaDeleteMutation = useDeleteVerzija();
  const verzijaStavkaUpdateMutation = useUpdateVerzijaStavka();
  const verzijaStavkaDeleteMutation = useDeleteVerzijaStavka();
  const verzijaStavkaCreateMutation = useCreateVerzijaStavka();

  const calendarRef = useRef<Calendar>(null);

  const handleCreateVerzijaStavka = (e: FormEvent<HTMLFormElement>, verzija: VerzijaReadDto) => {
    e.preventDefault();
    const opisElement = (e?.target as HTMLFormElement).elements.namedItem(`opis-${verzija.id}`) as HTMLInputElement;
    if (checkEmpty(opisElement.value)) {
      handleAxiosCallError(showMessage, Labels.VERZIJA_STAVKA_OPIS_MANDATORY);
      return;
    }
    const verzijaStavka: VerzijaStavkaCreateDto = { opis: opisElement.value };
    verzijaStavkaCreateMutation.mutate({ verzijaId: verzija.id, verzijaStavka });
    opisElement.value = "";
  };

  const handleDeleteVerzija = (verzija: VerzijaReadDto) => {
    confirmDialog({
      className: "dialogDeleteNotification",
      acceptLabel: Labels.BUTTON_DELETE,
      acceptIcon: "pi pi-check",
      rejectLabel: Labels.BUTTON_CANCEL,
      rejectClassName: "p-button-outlined mr-5",
      rejectIcon: "pi pi-times",
      message: <span className="font-bold">{Labels.VERZIJA_DELETE_CONFIRM_MESSAGE}</span>,
      accept: () => {
        verzijaDeleteMutation.mutate(verzija.id);
      },
    });
  };

  const handleDeleteVerzijaStavka = (verzija: VerzijaReadDto, verzijaStavka: VerzijaStavkaReadDto) => {
    confirmDialog({
      className: "dialogDeleteNotification",
      acceptLabel: Labels.BUTTON_DELETE,
      acceptIcon: "pi pi-check",
      rejectLabel: Labels.BUTTON_CANCEL,
      rejectClassName: "p-button-outlined mr-5",
      rejectIcon: "pi pi-times",
      message: <span className="font-bold">{Labels.VERZIJA_STAVKA_DELETE_CONFIRM_MESSAGE}</span>,
      accept: () => {
        verzijaStavkaDeleteMutation.mutate({ verzijaId: verzija.id, verzijaStavkaId: verzijaStavka.id });
      },
    });
  };

  const rowExpansionTemplateDataTable = (verzija: VerzijaReadDto) => {
    return (
      <div className="col-10 ml-8">
        <DataTable
          value={verzija.verzijaStavkaList}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          stripedRows
          editMode="row"
          onRowEditComplete={(e) => {
            const verzijaStavka = e.newData as VerzijaStavkaUpdateDto;
            if (checkEmpty(verzijaStavka.opis)) {
              handleAxiosCallError(showMessage, Labels.VERZIJA_STAVKA_OPIS_MANDATORY);
              return;
            }
            verzijaStavkaUpdateMutation.mutate({ verzijaId: verzija.id, verzijaStavka: verzijaStavka });
          }}
        >
          <Column field={"opis"} header={Labels.VERZIJA_STAVKE} className="col-11" editor={(options) => textAreaEditor(options)} />
          <Column
            rowEditor
            className="col-1"
            body={(rowData: VerzijaStavkaReadDto, options: ColumnBodyOptions) => (
              <div className="flex justify-content-end gap-2">
                {options.rowEditor?.editing ? (
                  <>
                    <Button
                      icon="pi pi-save"
                      severity="info"
                      onClick={(e) => options.rowEditor?.onSaveClick && options.rowEditor?.onSaveClick(e)}
                      tooltip={Labels.BUTTON_SAVE}
                      tooltipOptions={tooltipOptionsTop}
                    />
                    <Button
                      icon="pi pi-times"
                      onClick={(e) => options.rowEditor?.onCancelClick && options.rowEditor?.onCancelClick(e)}
                      tooltip={Labels.BUTTON_CANCEL}
                      tooltipOptions={tooltipOptionsTop}
                      severity="secondary"
                    />
                  </>
                ) : (
                  <>
                    <Button
                      icon="pi pi-pencil"
                      onClick={(e) => options.rowEditor?.onInitClick && options.rowEditor?.onInitClick(e)}
                      tooltip={Labels.BUTTON_UPDATE}
                      tooltipOptions={tooltipOptionsTop}
                      severity="warning"
                    />
                    <Button severity="danger" icon="pi pi-trash" tooltip={Labels.BUTTON_DELETE} tooltipOptions={tooltipOptionsTop} onClick={() => handleDeleteVerzijaStavka(verzija, rowData)} />
                  </>
                )}
              </div>
            )}
          />
        </DataTable>
        <form className="flex py-2 align-items-center justify-content-between gap-8 px-3" onSubmit={(e) => handleCreateVerzijaStavka(e, verzija)}>
          <div className="flex flex-1 align-items-center gap-1">
            <InputTextarea className="mx-0" name={`opis-${verzija.id}`} autoResize />
          </div>
          <Button icon="pi pi-plus" type="submit" tooltip={Labels.DODAJ_VERZIJA_STAVKU} tooltipOptions={tooltipOptionsTop} />
        </form>
      </div>
    );
  };

  const textEditor = (options: ColumnEditorOptions) => {
    return <InputText type="text" value={options.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback!(e.target.value)} />;
  };

  const textAreaEditor = (options: ColumnEditorOptions) => {
    return <InputTextarea className="w-full" autoFocus autoResize value={options.value} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => options.editorCallback!(e.target.value)} />;
  };

  const calendarEditor = (options: ColumnEditorOptions) => {
    return (
      <CalendarComponent
        ref={calendarRef}
        onChange={(e) => {
          if (e.value instanceof Date) {
            options.editorCallback!(e.target.value);
          }
        }}
        value={options.value}
        appendTo={document.body}
        onInput={(e) => {
          if (isDateFormatValid(e.currentTarget.value)) calendarRef?.current?.hide();
        }}
        showButtonBar={false}
        className="w-15rem"
      />
    );
  };

  const checkboxEditor = (options: ColumnEditorOptions) => {
    return <Checkbox checked={options.value} onChange={(e) => options.editorCallback!(e.checked)} />;
  };

  const statusBodyTemplate = (rowData: VerzijaReadDto) => {
    return rowData.javno ? <div className={`status-badge status-javno`}>Javna</div> : <div className={`status-badge status-privatno`}>Privatna</div>;
  };

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar
          start={
            <Button
              label={Labels.DODAJ_VERZIJU}
              className="p-button-success"
              icon="pi pi-plus"
              onClick={() => {
                setIsDialogVisible(true);
              }}
            />
          }
        />
        {isLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} isVisibleButtonList />
        ) : (
          <DataTable
            value={verzijaList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium}
            rowExpansionTemplate={rowExpansionTemplateDataTable}
            expandedRows={expandedRows}
            editMode="row"
            onRowEditComplete={(e) => {
              const verzija = e.newData as VerzijaUpdateDto;
              if (checkEmpty(verzija.naziv)) {
                handleAxiosCallError(showMessage, Labels.VERZIJA_NAZIV_MANDATORY);
                return;
              }
              verzijaUpdateMutation.mutate(verzija);
            }}
            onRowToggle={(e) => setExpandedRows(e.data)}
            dataKey="naziv"
            rows={size}
            onPage={onPage}
            exportFilename={Labels.GLOBALNE_DATOTEKE}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            stripedRows
          >
            <Column className="col-1" expander={true} />
            <Column field={"naziv"} editor={(options) => textEditor(options)} header={Labels.LABEL_NAZIV} showFilterMenu={false} />
            <Column field={"datum"} body={(e: VerzijaReadDto) => formatDate(e.datum, DATE_FORMAT)} editor={(options) => calendarEditor(options)} header={Labels.DATUM_VERZIJE} showFilterMenu={false} />
            <Column field={"javno"} body={(e: VerzijaReadDto) => statusBodyTemplate(e)} editor={(options) => checkboxEditor(options)} header={Labels.STATUS_VERZIJE} />
            <Column
              rowEditor
              className="col-1"
              body={(rowData: VerzijaReadDto, options: ColumnBodyOptions) => (
                <div className="flex justify-content-end gap-2">
                  {options.rowEditor?.editing ? (
                    <>
                      <Button
                        icon="pi pi-save"
                        severity="info"
                        onClick={(e) => options.rowEditor?.onSaveClick && options.rowEditor?.onSaveClick(e)}
                        tooltip={Labels.BUTTON_SAVE}
                        tooltipOptions={tooltipOptionsTop}
                      />
                      <Button
                        icon="pi pi-times"
                        onClick={(e) => options.rowEditor?.onCancelClick && options.rowEditor?.onCancelClick(e)}
                        tooltip={Labels.BUTTON_CANCEL}
                        tooltipOptions={tooltipOptionsTop}
                        severity="secondary"
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        icon="pi pi-pencil"
                        onClick={(e) => options.rowEditor?.onInitClick && options.rowEditor?.onInitClick(e)}
                        tooltip={Labels.BUTTON_UPDATE}
                        tooltipOptions={tooltipOptionsTop}
                        severity="warning"
                      />
                      <Button severity="danger" icon="pi pi-trash" tooltip={Labels.BUTTON_DELETE} tooltipOptions={tooltipOptionsTop} onClick={() => handleDeleteVerzija(rowData)} />
                    </>
                  )}
                </div>
              )}
            />
          </DataTable>
        )}
      </Panel>
      {isDialogVisible && <DialogCreateVerzija visible={isDialogVisible} closeDialog={() => setIsDialogVisible(false)} />}
    </div>
  );
}
