import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import LogHighLevelCreateDto from "../../model/log/LogHighLevelCreateDto";
import LogHighLevelSearchDto from "../../model/log/LogHighLevelSearchDto";

interface LogHighLevelControllerType {
  axiosSearchLogHighLevelList: (searchData: LogHighLevelSearchDto) => Promise<AxiosResponse<any>>;
  axiosCountLogHighLevelList: (searchData: LogHighLevelSearchDto) => Promise<AxiosResponse<any>>;
  axiosPostLogHighLevel: (logHighLevel: LogHighLevelCreateDto, token: string) => Promise<AxiosResponse<any>>;
}

export function LogHighLevelController(): LogHighLevelControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchLogHighLevelList = (searchData: LogHighLevelSearchDto) => {
    return axios.get(Endpoint.LOG_HIGH_LEVEL_LIST, axiosConfig(authData!.token, searchData));
  };
  const axiosCountLogHighLevelList = (searchData: LogHighLevelSearchDto) => {
    return axios.get(Endpoint.LOG_HIGH_LEVEL_LIST_SEARCH_COUNT, axiosConfig(authData!.token, searchData));
  };

  const axiosPostLogHighLevel = (logHighLevel: LogHighLevelCreateDto, token: string) => {
    return axios.post(Endpoint.LOG_HIGH_LEVEL_LIST, logHighLevel, axiosConfig(token));
  };

  return {
    axiosSearchLogHighLevelList,
    axiosCountLogHighLevelList,
    axiosPostLogHighLevel,
  };
}
