import { AxiosResponse } from "axios";
import moment from "moment/moment";
import { useContext, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { SifarnikUslugaController } from "../../../../controllers/sifarnik-usluga/SifarnikUslugaController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { checkEmpty, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import actionsSifarnikCena from "../../../../infrastructure/system/hooks/dialog-sifarnik-cena-reducer/actionsSifarnikCena";
import InitialStateSifarnikCena, { InitialStateSifarnikCenaType } from "../../../../infrastructure/system/hooks/dialog-sifarnik-cena-reducer/initialStateSifarnikCena";
import reducerSifarnikCena from "../../../../infrastructure/system/hooks/dialog-sifarnik-cena-reducer/reducerSifarnikCena";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import UslugaCenaReadDto from "../../../../model/sifarnik-usluga/UslugaCenaReadDto";
import UslugaCenaUpdateDto from "../../../../model/sifarnik-usluga/UslugaCenaUpdateDto";

interface SifarnikUslugaCeneListLogicalType {
  first: number;
  tableRows: number;
  onPageSifarnikUsluga: (rows: number, first: number) => void;
  isOpenDialogCene: boolean;
  setIsOpenDialogCene: any;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  state: InitialStateSifarnikCenaType;
  dispatch: React.Dispatch<{ type: actionsSifarnikCena }> | any;
  sifarnikUslugaCenaList: Array<UslugaCenaReadDto>;
  sifarnikUslugaCenaLoading: boolean;
  itemId: number;
}

interface UseParamsType {
  sifarnikUslugaId?: string;
}

export default function SifarnikUslugaCeneListLogical(): SifarnikUslugaCeneListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [isOpenDialogCene, setIsOpenDialogCene] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducerSifarnikCena, InitialStateSifarnikCena);
  const [sifarnikUslugaCenaList, setSifarnikUslugaCenaList] = useState<Array<UslugaCenaReadDto>>([]);
  const [sifarnikUslugaCenaLoading, setSifarnikUslugaCenaLoading] = useState<boolean>(false);
  const itemId = Number(useParams<keyof UseParamsType>()["sifarnikUslugaId"]);
  const { axiosGetCeneForUsluga, axiosCreateCenaForUsluga, axiosDeleteCenaForUsluga, axiosUpdateCenaForUsluga } = SifarnikUslugaController();
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchCeneUsluga();
  });

  const fetchCeneUsluga = () => {
    if (itemId) {
      let startTime = moment(new Date());
      setSifarnikUslugaCenaLoading(true);
      axiosGetCeneForUsluga(itemId)
        .then((response: AxiosResponse) => {
          setSifarnikUslugaCenaList(response.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          skeletonTimeout(setSifarnikUslugaCenaLoading, startTime);
        })
        .finally(() => {
          skeletonTimeout(setSifarnikUslugaCenaLoading, startTime);
        });
    } else {
      setSifarnikUslugaCenaLoading(false);
    }
  };

  const onPageSifarnikUsluga = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const validateNovaCena = () => {
    if (checkEmpty(state.cena?.usluga.id)) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_EMPTY_USLUGA_ERROR_MESSAGE);
      return false;
    }

    if (checkEmpty(state.cena?.iznos)) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_EMPTY_IZNOS_ERROR_MESSAGE);
      return false;
    }

    if (checkEmpty(state.cena?.valuta?.id)) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_EMPTY_VALUTA_ERROR_MESSAGE);
      return false;
    }

    if (checkEmpty(state.cena?.poreskaStopa?.id)) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_EMPTY_PORESKA_STOPA_ERROR_MESSAGE);
      return false;
    }

    if (checkEmpty(state.cena?.vaziOd)) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_EMPTY_DATUM_OD_ERROR_MESSAGE);
      return false;
    }

    if (state.cena?.vaziDo && !moment(state.cena.vaziDo).isAfter(moment(state.cena?.vaziOd), "days")) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_WRONG_DATUM_DO_ERROR_MESSAGE);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validateNovaCena()) {
      return;
    }

    axiosCreateCenaForUsluga(state.cena)
      .then(() => {
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_USLUGA_1 +
            state.cena?.usluga.id +
            Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_2 +
            state.cena?.vaziOd +
            Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_3 +
            state.cena?.iznos +
            Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_4 +
            state.cena?.valuta?.id +
            Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_5 +
            state.cena?.poreskaStopa?.id +
            "."
        );
      })
      .then(() => {
        dispatch({ type: actionsSifarnikCena.CLOSE_DIALOG });
        showMessage(MessageType.SUCCESS, Labels.CENE_CREATE_SUCCESS_MESSAGE);
        fetchCeneUsluga();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onUpdate = () => {
    let cenaProizvodUpdate: UslugaCenaUpdateDto = state.cena ? { ...state.cena, usluga: { id: itemId } } : undefined;
    if (state.cena.id) {
      axiosUpdateCenaForUsluga(cenaProizvodUpdate)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CENA_UPDATE_USLUGA_1 +
              itemId +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_2 +
              state.cena?.vaziOd +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_3 +
              state.cena?.iznos +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_4 +
              state.cena?.valuta?.id +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_5 +
              state.cena?.poreskaStopa?.id +
              "."
          );
        })
        .then(() => {
          dispatch({ type: actionsSifarnikCena.CLOSE_DIALOG });
          showMessage(MessageType.SUCCESS, Labels.CENE_UPDATE_SUCCESS_MESSAGE);
          fetchCeneUsluga();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onDelete = () => {
    if (state.cena.id) {
      axiosDeleteCenaForUsluga(itemId, state.cena.id)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CENA_DELETE_USLUGA_1 +
              itemId +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_2 +
              state.cena?.vaziOd +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_3 +
              state.cena?.iznos +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_4 +
              state.cena?.valuta?.id +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_5 +
              state.cena?.poreskaStopa?.id +
              "."
          );
        })
        .then(() => {
          dispatch({ type: actionsSifarnikCena.CLOSE_DIALOG });
          showMessage(MessageType.SUCCESS, Labels.CENE_DELETE_SUCCESS_MESSAGE);
          fetchCeneUsluga();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  return {
    first,
    tableRows,
    onPageSifarnikUsluga,
    isOpenDialogCene,
    setIsOpenDialogCene,
    onCreate,
    onUpdate,
    onDelete,
    state,
    dispatch,
    sifarnikUslugaCenaList,
    sifarnikUslugaCenaLoading,
    itemId,
  };
}
