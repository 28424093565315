import moment from "moment";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useContext, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import actionsDodavanjePostojecegRadnikaUstanova from "../../../../infrastructure/system/hooks/dialog-dodavanje-postojeceg-radnika-ustanova-reducer/actionsDodavanjePostojecegRadnikaUstanova";
import initialStateDodavanjePostojecegRadnikaUstanova, {
  InitialStateDodavanjePostojecegRadnikaUstanovaType,
} from "../../../../infrastructure/system/hooks/dialog-dodavanje-postojeceg-radnika-ustanova-reducer/initialStateDodavanjePostojecegRadnikaUstanova";
import reducerDodavanjePostojecegRadnikaUstanova from "../../../../infrastructure/system/hooks/dialog-dodavanje-postojeceg-radnika-ustanova-reducer/reducerDodavanjePostojecegRadnikaUstanova";
import RadnikReadDto from "../../../../model/radnik/RadnikReadDto";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";

interface UstanovaRadniciLogicalType {
  tableRows: number;
  openRadnik: (entityOperation: string, id?: number) => void;
  radniciList: Array<RadnikReadDto>;
  radnikListLoading: boolean;
  searchRadnikAdd: (e: AutoCompleteCompleteEvent) => void;
  searchString: string;
  onRadnikAdd: () => void;
  state: InitialStateDodavanjePostojecegRadnikaUstanovaType;
  dispatch: React.Dispatch<{ type: actionsDodavanjePostojecegRadnikaUstanova }> | any;
}

interface UstanovaRadniciListLogicalProps {
  ustanova?: UstanovaReadDto;
  ustanovaId?: number;
}

export interface UstanovaRadnikHolder {
  radnik: RadnikReadDto;
}

export default function UstanovaRadniciLogical(props: UstanovaRadniciListLogicalProps): UstanovaRadniciLogicalType {
  const { ustanova, ustanovaId } = props;
  const { axiosSuperadminGetUstanovaRadnikList, axiosSuperadminGetOtherUstanovaRadnikList, axiosSuperadminCreateRadnikUstanovaWithExistingRadnik } = SuperadminController();
  const [radniciList, setRadniciList] = useState<Array<RadnikReadDto>>([]);
  const [tableRows] = useState(10);
  const { showMessage } = useContext(AppContext);
  const navigate = useNavigate();
  const [radnikListLoading, setRadnikListLoading] = useState<boolean>(true);
  const Labels = useLabels();
  const [searchString, setSearchString] = useState<string>("");
  const [state, dispatch] = useReducer(reducerDodavanjePostojecegRadnikaUstanova, initialStateDodavanjePostojecegRadnikaUstanova);

  useEffectOnce(() => {
    fetchData();
  });

  const openRadnik = (entityOperation: string, id?: number) => {
    const radnikId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-ustanova/${ustanovaId}/crud-radnik/${radnikId}`, {
      state: { radnikOperation: entityOperation, ustanovaNaziv: ustanova?.naziv },
    });
  };
  const fetchData = () => {
    if (ustanovaId !== undefined) {
      let startTime = moment(new Date());
      setRadnikListLoading(true);
      axiosSuperadminGetUstanovaRadnikList(ustanovaId)
        .then((responseGetUstanovaRadnikList: any) => {
          var radnici: Array<RadnikReadDto> = [];
          responseGetUstanovaRadnikList.data.data.map((radnikUstanova: UstanovaRadnikHolder) => {
            return radnici.push(radnikUstanova.radnik);
          });
          setRadniciList(radnici);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setRadnikListLoading, startTime);
        });
    }
  };

  const searchRadnikAdd = (e: AutoCompleteCompleteEvent) => {
    if (e.query.length > 2 && ustanovaId) {
      setSearchString(e.query);
      axiosSuperadminGetOtherUstanovaRadnikList(ustanovaId, { searchString: e.query })
        .then((res: any) => {
          dispatch({ type: actionsDodavanjePostojecegRadnikaUstanova.SET_RADNIK_LIST, value: res.data.data });
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          //setDijagnozeLoading(false);
        });
    }
  };

  const onRadnikAdd = () => {
    if (state.radnikSelected && state.radnikSelected.id && ustanovaId) {
      axiosSuperadminCreateRadnikUstanovaWithExistingRadnik(ustanovaId, state.radnikSelected.id)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.RADNIK_USPESNO_DODAT);
          dispatch({ type: actionsDodavanjePostojecegRadnikaUstanova.CLOSE_DIALOG });
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  return {
    tableRows,
    openRadnik,
    radniciList,
    radnikListLoading,
    searchRadnikAdd,
    searchString,
    onRadnikAdd,
    state,
    dispatch,
  };
}
