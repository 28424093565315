import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import KontaktTipRepositoryReadDto from "../../../../model/kontakt/KontaktTipRepositoryReadDto";
import OrganizacionaJedinicaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import { InitialStateType } from "../../hooks/initialState";
import { ReducerType } from "../../hooks/reducer";
import { MultiSelect } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";
import { useLabels } from "../../../../Store";
import KontaktTipInicijalizacijaReadDto from "../../../../model/kontakt/KontaktTipInicijalizacijaReadDto";
import useEffectWithoutWarnings from "../../../../infrastructure/system/hooks/useEffectWithoutWarnings";

interface TipPregledaCardType {
  tipPregleda: KontaktTipRepositoryReadDto;
  organizacionaJedinicaList: OrganizacionaJedinicaReadDto[];
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerType>;
  inicijalizacijaTipPregledaList: KontaktTipInicijalizacijaReadDto[];
}
const TipPregledaCard = ({ tipPregleda, organizacionaJedinicaList, state, dispatch, inicijalizacijaTipPregledaList }: TipPregledaCardType) => {
  const Labels = useLabels();

  const checkTipPregledaPromenjeno = () => {
    let tipPregledaInit = inicijalizacijaTipPregledaList.find((tip) => tip.idRepositoryKontaktTip === tipPregleda?.id);
    let tipPregledaCurrent = state?.tipoviPregleda?.find((tip) => tip.idRepositoryKontaktTip === tipPregleda.id);
    if (tipPregledaInit && !tipPregledaCurrent) {
      return true;
    } else if (!tipPregledaInit && tipPregledaCurrent) {
      return true;
    } else if (tipPregledaInit && tipPregledaCurrent) {
      if (tipPregledaInit.organizacionaJedinicaList.length !== tipPregledaCurrent.kontaktTipOrganizacionaJedinicaList.length) {
        return true;
      } else if (
        tipPregledaInit.organizacionaJedinicaList.find(
          (orgJedInit) => !tipPregledaCurrent!.kontaktTipOrganizacionaJedinicaList.find((orgJedCur) => orgJedCur.organizacionaJedinica.id === orgJedInit.id)
        )
      ) {
        return true;
      }
    }
    return false;
  };

  useEffectWithoutWarnings(() => {
    dispatch({ type: "set_tipovi_pregleda_changed", field: tipPregleda.id, value: checkTipPregledaPromenjeno() });
  }, [state.tipoviPregleda]);

  return (
    <div className="flex">
      <Card className="p-0 mt-2 mb-5 w-11">
        <div className="flex flex-column lg:flex-row justify-content-between align-items-start">
          <div className="flex">
            <div>
              <Checkbox
                onChange={(e) => {
                  if (e.checked) {
                    dispatch({
                      type: "change_tipovi_pregleda",
                      data: {
                        idRepositoryKontaktTip: tipPregleda.id,
                        organizacionaJedinicaList: organizacionaJedinicaList.map((orgJed) => ({ organizacionaJedinica: { id: orgJed.id } })),
                        tipPregleda: tipPregleda,
                      },
                    });
                  } else {
                    dispatch({ type: "uncheck_tip_pregleda", data: tipPregleda.id });
                  }
                }}
                checked={!!state?.tipoviPregleda?.find((tip) => tip.idRepositoryKontaktTip === tipPregleda.id)}
              />
            </div>
            <div className="ml-4">
              <div className="font-bold">{tipPregleda.naziv}</div>
              <div className="pt-4 pb-3">{tipPregleda.sifra}</div>
            </div>
          </div>
          <div>
            <span className="mr-2">{Labels.LABEL_UBACI_U}</span>
            <MultiSelect
              value={state?.tipoviPregleda?.find((tip) => tip.idRepositoryKontaktTip === tipPregleda.id)?.kontaktTipOrganizacionaJedinicaList?.map((orgJed) => orgJed.organizacionaJedinica.id)}
              onChange={(e) => dispatch({ type: "add_org_jedinica_to_tip_pregleda", data: { id: tipPregleda.id, organizacionaJedinicaList: e.value.map((item: number) => ({ id: item })) } })}
              options={organizacionaJedinicaList}
              optionLabel="naziv"
              optionValue="id"
              maxSelectedLabels={organizacionaJedinicaList.length - 1}
              className="w-full md:w-20rem"
              disabled={!state?.tipoviPregleda?.find((tip) => tip.idRepositoryKontaktTip === tipPregleda.id)}
              selectedItemsLabel={Labels.LABEL_INICIJALIZACIJA_SVE_ORGANIZACIONE_JEDINICE}
            />
          </div>
        </div>
      </Card>
      {checkTipPregledaPromenjeno() && (
        <div className="height-max">
          <span className="exclamation pi pi-exclamation-triangle text-xl mt-2 ml-2" data-pr-tooltip={Labels.LABEL_TIP_PREGLEDA_SAVE_CONFIRMATION_WARNING} />
          <Tooltip target=".exclamation" />
        </div>
      )}
    </div>
  );
};

export default TipPregledaCard;
