import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../Store";
import RadnikProfilPasswordChangeLogical from "./RadnikProfilPasswordChangeLogical";

interface RadnikProfilPasswordChangeProps {
  onCancel: () => void;
  currentRadnikId: number;
}

export default function RadnikProfilPasswordChange(props: RadnikProfilPasswordChangeProps) {
  const { changeRadnikData, passwordChange } = RadnikProfilPasswordChangeLogical();
  const Labels = useLabels();

  const { onCancel } = props;

  const leftContents = () => (
    <Button
      label={Labels.RADNIK_PROFIL_BUTTON_SAVE}
      tooltip={Labels.RADNIK_PROFIL_BUTTON_SAVE_NEW_PASSWORD}
      className="p-button-primary button-save-password"
      onClick={() => passwordChange(onCancel)}
    />
  );

  const rightContents = () => <Button label={Labels.RADNIK_PROFIL_BUTTON_CANCEL} className="p-button-warning" onClick={() => onCancel()} />;

  return (
    <div className="grid p-fluid layout-radnik">
      <div className="password-panel">
        <div className="col-12 lg:col-6">
          <div className="col-12 panel-registration">
            <div className="grid flex-row p-0 justify-content-center align-content-center password-dialog-width">
              <div className="col-12 login-input-password">
                <label className="edit-radnik-label">{Labels.RADNIK_PROFIL_OLD_PASSWORD}</label>
                <Password name="currentPassword" feedback={false} autoComplete="new-password" onChange={changeRadnikData} />
              </div>

              <div className="col-12 login-input-password">
                <label className="edit-radnik-label">{Labels.RADNIK_PROFIL_NEW_PASSWORD}</label>
                <Password name="newPassword" autoComplete="new-password" onChange={changeRadnikData} />
              </div>

              <div className="col-12 login-input-password">
                <label className="edit-radnik-label">{Labels.RADNIK_PROFIL_CONFIRM_NEW_PASSWORD}</label>
                <Password name="confirmPassword" feedback={false} autoComplete="new-password" onChange={changeRadnikData} />
              </div>
              <div className="col-12 login-button">
                <Toolbar left={leftContents} right={rightContents} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
