import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { KontaktController } from "../../../controllers/kontakt/KontaktController";
import QueryKeys from "../../../infrastructure/system/QueryKeys";
import { usePagination } from "../../../infrastructure/system/hooks/tables/usePagination";
import KontaktNaplataReadDto from "../../../model/naplata/KontaktNaplataReadDto";
import { NaplataKontaktListSearchFilters } from "../../../model/naplata/NaplataKontaktListSearchFilters";

export function useKontaktNaplataList(searchFilters?: NaplataKontaktListSearchFilters) {
  const { axiosGetKontaktNaplataList, axiosGetKontaktNaplataListCount } = KontaktController();

  const { first, page, size, onPage, setPage, setFirst } = usePagination();

  useEffect(() => {
    setFirst(0);
    setPage(0);
  }, [setFirst, setPage]);

  const {
    data,
    refetch: refetchGenerisanIzvestajList,
    isLoading,
  } = useQuery({
    queryKey: [QueryKeys.KONTAKT_NAPLATA_LIST, { page, size }, searchFilters],
    queryFn: () => axiosGetKontaktNaplataList({ page, size, ...searchFilters! }),
  });

  const { data: countData, refetch: refetchGenerisanIzvestajListCount } = useQuery({
    queryKey: [QueryKeys.KONTAKT_NAPLATA_LIST, searchFilters],
    queryFn: () => axiosGetKontaktNaplataListCount(searchFilters),
  });

  const kontaktList: KontaktNaplataReadDto[] = data?.data?.data ?? [];
  const totalRecords: number = countData?.data?.data ?? 0;

  const refetch = () => {
    refetchGenerisanIzvestajList();
    refetchGenerisanIzvestajListCount();
  };

  return { kontaktList, totalRecords, refetch, isLoading, first, onPage, size };
}
