import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";
import DokumentSmrtPacijentaDto from "../../model/pacijent/DokumentPacijentaDto";
import OsiguranjeReadDto from "../../model/pacijent/OsiguranjeReadDto";
import OsiguranjeUpdateDto from "../../model/pacijent/OsiguranjeUpdateDto";
import PacijentCreateDto from "../../model/pacijent/PacijentCreateDto";
import PacijentExistsSearchDto from "../../model/pacijent/PacijentExistsSearchDto";
import PacijentIncludedSearchDto from "../../model/pacijent/PacijentIncludedSearchDto";
import PacijentMergeDto from "../../model/pacijent/PacijentMergeDto";
import PacijentProfilnaSlikaUpdateDto from "../../model/pacijent/PacijentProfilnaSlikaUpdateDto";
import PacijentReadDto from "../../model/pacijent/PacijentReadDto";
import PacijentSearchDto from "../../model/pacijent/PacijentSearchDto";
import PacijentSimpleReadDto from "../../model/pacijent/PacijentSimpleReadDto";
import PacijentSplitDto from "../../model/pacijent/PacijentSplitDto";
import PacijentWithPacijentPoljaPopunjena from "../../model/pacijent/PacijentWithPacijentPoljaPopunjena";
import VremeSmrtiDto from "../../model/pacijent/VremeSmrtiDto";

interface PacijentControllerType {
  axiosGetPacijentById: (id: number) => Promise<AxiosResponse<CommonResponseDto<PacijentReadDto>>>;
  axiosPacijentSearch: (searchObject: PacijentSearchDto) => Promise<AxiosResponse<any>>;
  axiosPacijentCount: (searchObject: PacijentSearchDto) => Promise<AxiosResponse<any>>;
  axiosUpdatePacijent: (pacijent: PacijentReadDto) => Promise<AxiosResponse<any>>;
  axiosCreatePacijent: (pacijent: PacijentReadDto) => Promise<AxiosResponse<any>>;
  axiosDeletePacijent: (pacijentId: number) => Promise<AxiosResponse<any>>;
  axiosGetPacijentKontakList: (id: number) => Promise<AxiosResponse<any>>;
  axiosGetPacijentPoljePopunjenoList: (sifraKategorije: string, id: number) => Promise<AxiosResponse<any>>;
  axiosCreatePacijentPoljaPopunjena: (pacijentPoljaPopunjeno: PacijentWithPacijentPoljaPopunjena, id: number, pacijentPoljeKategorija: string) => Promise<AxiosResponse<any>>;
  axiosGetPacijentFaktoriRizika: (id: number) => Promise<AxiosResponse<any>>;
  axiosPostPacijentFaktoriRizika: (id: number, params: any) => Promise<AxiosResponse<any>>;
  axiosSavePacijentVremeSmrti: (id: number, vremeSmrtiDto: VremeSmrtiDto) => Promise<AxiosResponse<any>>;

  axiosGetPacijentOsiguranjeList: (id: number) => Promise<AxiosResponse<any>>;
  axiosGetPacijentOsiguranjeNewestValid: (id: number) => Promise<AxiosResponse<any>>;
  axiosCreatePacijentOsiguranje: (pacijentId: number, osiguranje: OsiguranjeReadDto) => Promise<AxiosResponse<any>>;
  axiosUpdatePacijentOsiguranje: (pacijentId: number, osiguranje: OsiguranjeUpdateDto) => Promise<AxiosResponse<any>>;
  axiosDeletePacijentOsiguranje: (pacijentId: number, osiguranjeId: number) => Promise<AxiosResponse<any>>;
  axiosPagedGetFormularPopunjenListByFormular: (pacijentId: number, formularId: number, page: number, pageSize: number) => Promise<AxiosResponse<any>>;
  axiosUpdatePacijentWithPacijentPoljePopunjenoList: (pacWithPacPoljaPopunjena: PacijentWithPacijentPoljaPopunjena, id: number, pacijentPoljeKategorija: string) => Promise<AxiosResponse<any>>;
  axiosPatchPacijentProfilnaSlika: (profilnaSlikaChange: PacijentProfilnaSlikaUpdateDto) => Promise<AxiosResponse<any>>;
  axiosGetDokumentSmrtPacijenta: (pacijentID: number) => Promise<AxiosResponse<any>>;
  axiosCreateDokumentSmrtPacijenta: (dokumentPacijent: DokumentSmrtPacijentaDto) => Promise<AxiosResponse<any>>;
  axiosDeleteDokumentSmrtPacijenta: (dokumentID: number) => Promise<AxiosResponse<any>>;
  axiosPacijentSearchSimple: () => Promise<AxiosResponse<CommonResponseDto<PacijentSimpleReadDto[]>>>;
  axiosZakazaniTerminiPacijenta: (pacijentID: number) => Promise<AxiosResponse<any>>;
  axiosGetPacijentGrupnoObavestenjeIncluded: (pacijentObavestenjeIncludedSearch: PacijentIncludedSearchDto) => Promise<AxiosResponse<any>>;
  axiosPacijentUnsubscribe: (token: string) => Promise<AxiosResponse<any>>;
  axiosCheckPacijentExists: (pacijentExistsSearch: PacijentExistsSearchDto) => Promise<AxiosResponse<any>>;
  axiosSearchPacijentSpajanje: (pacijentSpajanjeParams: { ime?: string; prezime?: string; datumRodjenja?: string | Date }) => Promise<AxiosResponse<any>>;
  axiosPacijentMerge: (pacijentMerge: PacijentMergeDto) => Promise<AxiosResponse<any>>;
  axiosPacijentSplit: (pacijentSplit: PacijentSplitDto) => Promise<AxiosResponse<any>>;
  axiosSearchPacijentRazdvajanje: (pacijentRazdvajanjeParams: { ime?: string; prezime?: string; datumRodjenja?: string | Date; page: number; size: number }) => Promise<AxiosResponse<any>>;
}

export function PacijentController(): PacijentControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetPacijentById = (id: number) => {
    return axios.get(`${Endpoint.PACIJENT_LIST}/${id}`, axiosConfig(authData!.token));
  };

  const axiosPacijentSearch = (searchObject: PacijentSearchDto) => {
    return axios.get(Endpoint.PACIJENT_SEARCH, axiosConfig(authData!.token, searchObject));
  };

  const axiosPacijentSearchSimple = () => {
    return axios.get(Endpoint.PACIJENT_SEARCH_SIMPLE, axiosConfig(authData!.token));
  };

  const axiosPacijentCount = (searchObject: PacijentSearchDto) => {
    return axios.get(`${Endpoint.PACIJENT_SEARCH}/count`, axiosConfig(authData!.token, searchObject));
  };

  const axiosCreatePacijent = (pacijent: PacijentCreateDto) => {
    return axios.post(Endpoint.PACIJENT_LIST, pacijent ? pacijent : {}, axiosConfig(authData!.token));
  };

  const axiosUpdatePacijent = (pacijent: PacijentReadDto) => {
    return axios.put(`${Endpoint.PACIJENT_LIST}/${pacijent?.id}`, pacijent, axiosConfig(authData!.token));
  };

  const axiosDeletePacijent = (pacijentId: number) => {
    return axios.delete(`${Endpoint.PACIJENT_LIST}/${pacijentId}`, axiosConfig(authData!.token));
  };

  const axiosGetPacijentKontakList = (id: number) => {
    return axios.get(`${Endpoint.PACIJENT_LIST}/${id}/kontakt-list`, axiosConfig(authData!.token));
  };

  const axiosGetPacijentPoljePopunjenoList = (sifraKategorije: string, id: number) => {
    return axios.get(`${Endpoint.PACIJENT_POLJE_LIST}/${sifraKategorije}/pacijent-polje-popunjeno-list/${id}`, axiosConfig(authData!.token));
  };

  const axiosCreatePacijentPoljaPopunjena = (pacijentPoljaPopunjeno: PacijentWithPacijentPoljaPopunjena, id: number, pacijentPoljeKategorija: string) => {
    return axios.post(`${Endpoint.PACIJENT_LIST}/${id}/pacijent-polje-popunjeno/${pacijentPoljeKategorija}`, pacijentPoljaPopunjeno ? pacijentPoljaPopunjeno : {}, axiosConfig(authData!.token));
  };

  const axiosGetPacijentFaktoriRizika = (id: number) => {
    return axios.get(`${Endpoint.PACIJENT_LIST}/${id}/pacijent-faktor-rizika-list`, axiosConfig(authData!.token));
  };

  const axiosPostPacijentFaktoriRizika = (id: number, params: any) => {
    return axios.post(`${Endpoint.PACIJENT_LIST}/${id}/pacijent-faktor-rizika-list`, params ? params : {}, axiosConfig(authData!.token));
  };

  const axiosSavePacijentVremeSmrti = (id: number, vremeSmrtiDto: VremeSmrtiDto) => {
    return axios.put(`${Endpoint.PACIJENT_LIST}/${id}/vreme-smrti`, vremeSmrtiDto, axiosConfig(authData!.token));
  };

  const axiosGetPacijentOsiguranjeList = (id: number) => {
    return axios.get(`${Endpoint.PACIJENT_LIST}/${id}/osiguranje-list`, axiosConfig(authData!.token));
  };

  const axiosGetPacijentOsiguranjeNewestValid = (id: number) => {
    return axios.get(`${Endpoint.PACIJENT_LIST}/${id}/osiguranje-list/osiguranje`, axiosConfig(authData!.token));
  };

  const axiosCreatePacijentOsiguranje = (pacijentId: number, osiguranje: OsiguranjeReadDto) => {
    return axios.post(`${Endpoint.PACIJENT_LIST}/${pacijentId}/osiguranje-list/`, osiguranje ? osiguranje : {}, axiosConfig(authData!.token));
  };

  const axiosUpdatePacijentOsiguranje = (pacijentId: number, osiguranje: OsiguranjeUpdateDto) => {
    return axios.put(`${Endpoint.PACIJENT_LIST}/${pacijentId}/osiguranje-list/${osiguranje.id}`, osiguranje, axiosConfig(authData!.token));
  };

  const axiosDeletePacijentOsiguranje = (pacijentId: number, osiguranjeId: number) => {
    return axios.delete(`${Endpoint.PACIJENT_LIST}/${pacijentId}/osiguranje-list/${osiguranjeId}`, axiosConfig(authData!.token));
  };
  const axiosPagedGetFormularPopunjenListByFormular = (pacijentId: number, formularId: number, page: number, pageSize: number) => {
    return axios.get(`${Endpoint.PACIJENT_LIST}/${pacijentId}/formular-list/${formularId}/formular-popunjen-list`, axiosConfig(authData!.token, { page: page, pageSize: pageSize }));
  };

  const axiosUpdatePacijentWithPacijentPoljePopunjenoList = (pacWithPacPoljaPopunjena: PacijentWithPacijentPoljaPopunjena, id: number, pacijentPoljeKategorija: string) => {
    return axios.post(`${Endpoint.PACIJENT_LIST}/${id}/pacijent-polje-popunjeno/${pacijentPoljeKategorija}/update-pacijent`, pacWithPacPoljaPopunjena ?? {}, axiosConfig(authData!.token));
  };

  const axiosPatchPacijentProfilnaSlika = (profilnaSlikaChange: PacijentProfilnaSlikaUpdateDto) => {
    return axios.patch(`${Endpoint.PACIJENT_LIST}/profilna-slika`, profilnaSlikaChange, axiosConfig(authData!.token));
  };

  const axiosGetDokumentSmrtPacijenta = (pacijentID: number) => {
    return axios.get(`${Endpoint.PACIJENT_SMRT_DOKUMENT}/pacijent/${pacijentID}`, axiosConfig(authData!.token));
  };

  const axiosDeleteDokumentSmrtPacijenta = (dokumentID: number) => {
    return axios.delete(`${Endpoint.PACIJENT_SMRT_DOKUMENT}/${dokumentID}`, axiosConfig(authData!.token));
  };

  const axiosCreateDokumentSmrtPacijenta = (body: DokumentSmrtPacijentaDto) => {
    return axios.post(`${Endpoint.PACIJENT_SMRT_DOKUMENT}`, body, axiosConfig(authData!.token));
  };

  const axiosZakazaniTerminiPacijenta = (pacijentID: number) => {
    return axios.get(`${Endpoint.ZAKAZANI_TERMINI_PACIJENT}/${pacijentID}`, axiosConfig(authData!.token));
  };

  const axiosGetPacijentGrupnoObavestenjeIncluded = (pacijentObavestenjeIncludedSearch: PacijentIncludedSearchDto) => {
    return axios.get(Endpoint.PACIJENT_GRUPNO_OBAVESTENJE, axiosConfig(authData!.token, pacijentObavestenjeIncludedSearch));
  };

  const axiosPacijentUnsubscribe = (token: string) => {
    return axios.put(`${Endpoint.PACIJENT_LIST}/unsubscribe`, undefined, { params: { token: token } });
  };

  const axiosCheckPacijentExists = (pacijentExistsSearch: PacijentExistsSearchDto) => {
    return axios.get(`${Endpoint.PACIJENT_LIST}/exists`, axiosConfig(authData!.token, pacijentExistsSearch));
  };

  const axiosSearchPacijentSpajanje = (pacijentSpajanjeParams: { ime?: string; prezime?: string; datumRodjenja?: string | Date }) => {
    return axios.get(Endpoint.PACIJENT_SPAJANJE_LIST, axiosConfig(authData!.token, pacijentSpajanjeParams));
  };

  const axiosPacijentMerge = (pacijentMerge: PacijentMergeDto) => {
    return axios.put(Endpoint.PACIJENT_MERGE, pacijentMerge, axiosConfig(authData!.token));
  };

  const axiosPacijentSplit = (pacijentSplit: PacijentSplitDto) => {
    return axios.put(Endpoint.PACIJENT_SPLIT, pacijentSplit, axiosConfig(authData!.token));
  };

  const axiosSearchPacijentRazdvajanje = (pacijentRazdvajanjeParams: { ime?: string; prezime?: string; datumRodjenja?: string | Date; page: number; size: number }) => {
    return axios.get(Endpoint.PACIJENT_SPLIT_LIST, axiosConfig(authData!.token, pacijentRazdvajanjeParams));
  };

  return {
    axiosGetPacijentById,
    axiosPacijentSearch,
    axiosUpdatePacijent,
    axiosCreatePacijent,
    axiosDeletePacijent,
    axiosGetPacijentKontakList,
    axiosGetPacijentPoljePopunjenoList,
    axiosCreatePacijentPoljaPopunjena,
    axiosGetPacijentFaktoriRizika,
    axiosPostPacijentFaktoriRizika,
    axiosSavePacijentVremeSmrti,
    axiosGetPacijentOsiguranjeList,
    axiosGetPacijentOsiguranjeNewestValid,
    axiosCreatePacijentOsiguranje,
    axiosUpdatePacijentOsiguranje,
    axiosDeletePacijentOsiguranje,
    axiosPagedGetFormularPopunjenListByFormular,
    axiosUpdatePacijentWithPacijentPoljePopunjenoList,
    axiosPatchPacijentProfilnaSlika,
    axiosDeleteDokumentSmrtPacijenta,
    axiosGetDokumentSmrtPacijenta,
    axiosCreateDokumentSmrtPacijenta,
    axiosPacijentCount,
    axiosPacijentSearchSimple,
    axiosZakazaniTerminiPacijenta,
    axiosGetPacijentGrupnoObavestenjeIncluded,
    axiosPacijentUnsubscribe,
    axiosCheckPacijentExists,
    axiosSearchPacijentSpajanje,
    axiosPacijentMerge,
    axiosPacijentSplit,
    axiosSearchPacijentRazdvajanje,
  };
}
