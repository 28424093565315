import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useLabels } from "../../../../../Store";
import actions from "../../../../../infrastructure/system/hooks/dialog-brisanje-ustanove-reducer/actions";
import DialogComponent from "../../../../dialog-component/DialogComponent";

interface UstanovaBrisanjeDialogType {
  isOpenDeleteDialog: boolean;
  closeDeleteDialog: () => void;
  page: number;
  checked: boolean;
  dispatch: React.Dispatch<{ type: actions }>;
  onDelete: () => void;
}

export default function UstanovaBrisanjeDialog(props: UstanovaBrisanjeDialogType) {
  const { isOpenDeleteDialog, closeDeleteDialog, page, checked, dispatch, onDelete } = props;
  const Labels = useLabels();

  const renderPage = () => {
    if (page === 0) {
      return <div>{Labels.USTANOVA_BRISANJE_DIALOG_LABEL_1}</div>;
    } else {
      return (
        <div className="flex field-checkbox justify-content-center">
          <Checkbox inputId="potvrdaBrisanja" checked={checked} onChange={(e) => dispatch({ type: actions.ENABLE_DELETE })} />
          <label className="cursor-pointer" htmlFor="potvrdaBrisanja">
            {Labels.USTANOVA_BRISANJE_DIALOG_LABEL_2}
          </label>
        </div>
      );
    }
  };

  const footer = () => {
    return (
      <div className="flex flex-row justify-content-between px-1">
        <Button
          label={Labels.BUTTON_DELETE}
          icon="pi pi-trash"
          className="p-button-danger"
          disabled={page === 1 && !checked}
          onClick={() => {
            if (page === 0) {
              dispatch({ type: actions.SWITCH_PAGE });
            } else {
              onDelete();
            }
          }}
        />
        <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={closeDeleteDialog} />
      </div>
    );
  };

  return <DialogComponent header="" visible={isOpenDeleteDialog} onHide={closeDeleteDialog} className="w-8 sm:w-8 md:w-4" footer={footer} blockScroll={true} content={<>{renderPage()}</>} />;
}
