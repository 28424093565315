import { PickListChangeEvent } from "primereact/picklist";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EnumController } from "../../../../controllers/enum/EnumController";

import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import { UlogaController } from "../../../../controllers/uloga/UlogaController";
import useCallbackWithoutWarnings from "../../../../infrastructure/system/hooks/useCallbackWithoutWarnings";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";

import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import UlogaAplikativnoPravoReadDto from "../../../../model/uloga/UlogaAplikativnoPravoReadDto";
import { AppContext, useLabels } from "../../../../Store";

interface CrudUlogaAplikativnaPravaLogicalType {
  ulogaAplikativnoPravoList: Array<UlogaAplikativnoPravoReadDto>;
  aplikativnoPravoList: Array<UlogaAplikativnoPravoHolderDto>;
  onChangeUlogaAplPravo: (event: PickListChangeEvent) => Promise<void>;
  isBlocked: boolean;
  isLoading: boolean;
}
export interface UlogaAplikativnoPravoHolderDto {
  aplikativnoPravo: EnumBaseReadDto;
}
interface UseParamsType {
  ulogaId?: string;
}
interface CrudUlogaAplikativnaPravaLogicalProps {
  ustanovaId?: number;
  ulogaNaziv?: string;
}
export default function CrudUlogaAplikativnaPravaLogical(props: CrudUlogaAplikativnaPravaLogicalProps): CrudUlogaAplikativnaPravaLogicalType {
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { showMessage } = useContext(AppContext);
  const [ulogaAplikativnoPravoList, setUlogaAplikativnoPravoList] = useState<Array<UlogaAplikativnoPravoReadDto>>([]);
  const [aplikativnoPravoList, setAplikativnoPravoList] = useState<Array<UlogaAplikativnoPravoHolderDto>>([]);
  const { axiosGetEnumAplikativnoPravoList } = EnumController();
  const { axiosPostUlogaAplikativnoPravoList, axiosGetUlogaAplikativnoPravoList, axiosDeleteUlogaAplikativnoPravo } = UlogaController();
  const { axiosSuperadminPostUlogaAplikativnoPravoList, axiosSuperadminGetUlogaAplikativnoPravoList, axiosSuperadminDeleteUlogaAplikativnoPravo } = SuperadminController();
  const { ulogaId } = useParams<keyof UseParamsType>();
  const { ustanovaId, ulogaNaziv } = props;

  const postLogHighLevel = useLogHighLevel();

  const Labels = useLabels();

  const getAplikativnoPravoList = useCallbackWithoutWarnings((haveAplikativnoPravoList: Array<UlogaAplikativnoPravoReadDto>) => {
    setIsLoading(true);
    axiosGetEnumAplikativnoPravoList()
      .then((res: any) => {
        const allAplPravo: Array<EnumBaseReadDto> = res.data.data;
        const aplikativnoPravoListTemp = allAplPravo.reduce((filtered: Array<UlogaAplikativnoPravoHolderDto>, aplPravo: EnumBaseReadDto) => {
          if (!haveAplikativnoPravoList.some((ap: UlogaAplikativnoPravoReadDto) => ap?.aplikativnoPravo.sifra === aplPravo.sifra)) {
            filtered.push({ aplikativnoPravo: aplPravo });
          }
          return filtered;
        }, []);
        setAplikativnoPravoList(aplikativnoPravoListTemp);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getUlogaAplPravo = useCallbackWithoutWarnings(() => {
    if (ulogaId) {
      setIsLoading(true);
      (ustanovaId ? axiosSuperadminGetUlogaAplikativnoPravoList(ustanovaId, Number(ulogaId)) : axiosGetUlogaAplikativnoPravoList(Number(ulogaId)))
        .then((res: any) => {
          const ulogaAplPravo: Array<UlogaAplikativnoPravoReadDto> = res.data.data;
          setUlogaAplikativnoPravoList(ulogaAplPravo);
          getAplikativnoPravoList(ulogaAplPravo);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setIsLoading(false);
        });
    }
  }, [ulogaId, ustanovaId]);

  const fetchData = useCallbackWithoutWarnings(() => {
    getUlogaAplPravo();
  }, [getUlogaAplPravo]);

  useEffect(() => {
    if (ulogaId) {
      fetchData();
    }
  }, [fetchData, ulogaId]);

  const onChangeUlogaAplPravo = async (event: PickListChangeEvent) => {
    if (isBlocked || isLoading) {
      return;
    }
    setIsBlocked(true);
    if (ulogaAplikativnoPravoList.length < event.target.length) {
      await Promise.all(
        event.target.map(async (ulogaAplPravo: UlogaAplikativnoPravoHolderDto) => {
          if (
            !ulogaAplikativnoPravoList
              .map((element: UlogaAplikativnoPravoReadDto) => {
                return element?.aplikativnoPravo;
              })
              .includes(ulogaAplPravo.aplikativnoPravo)
          ) {
            ulogaId &&
              (await (ustanovaId
                ? axiosSuperadminPostUlogaAplikativnoPravoList(ustanovaId, Number(ulogaId), { uloga: { id: Number(ulogaId) }, aplikativnoPravo: { sifra: ulogaAplPravo?.aplikativnoPravo.sifra } })
                : axiosPostUlogaAplikativnoPravoList(Number(ulogaId), { uloga: { id: Number(ulogaId) }, aplikativnoPravo: { sifra: ulogaAplPravo?.aplikativnoPravo.sifra } })
              )
                .then(() => {
                  postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA + ulogaNaziv + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_APLIKATIVNA_PRAVA_1 + ulogaAplPravo.aplikativnoPravo.naziv + ". ");
                })
                .catch((error: any) => {
                  handleAxiosCallError(showMessage, error);
                  setIsBlocked(false);
                }));
          }
        })
      );
      setUlogaAplikativnoPravoList(event.target);
      setAplikativnoPravoList(aplikativnoPravoList.filter((element: UlogaAplikativnoPravoHolderDto) => !event.target.includes(element)));
    } else if (aplikativnoPravoList.length < event.source.length) {
      await Promise.all(
        event.source.map(async (ulogaAplPravo: UlogaAplikativnoPravoHolderDto) => {
          if (!aplikativnoPravoList.includes(ulogaAplPravo)) {
            await (ustanovaId
              ? axiosSuperadminDeleteUlogaAplikativnoPravo(ustanovaId, Number(ulogaId), ulogaAplPravo?.aplikativnoPravo.sifra)
              : axiosDeleteUlogaAplikativnoPravo(Number(ulogaId), ulogaAplPravo?.aplikativnoPravo.sifra)
            )
              .then((res: any) => {
                postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA + ulogaNaziv + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_APLIKATIVNA_PRAVA_2 + ulogaAplPravo.aplikativnoPravo.naziv + ". ");
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
                setIsBlocked(false);
              });
          }
        })
      );
      setAplikativnoPravoList(aplikativnoPravoList.concat(ulogaAplikativnoPravoList.filter((element: UlogaAplikativnoPravoHolderDto) => !event.target.includes(element))));
      setUlogaAplikativnoPravoList(event.target);
    }
    setIsBlocked(false);
  };
  return {
    onChangeUlogaAplPravo,
    ulogaAplikativnoPravoList,
    aplikativnoPravoList,
    isBlocked,
    isLoading,
  };
}
