import moment from "moment";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import { Dispatch, SetStateAction, useRef } from "react";
import { useLabels } from "../../../../Store";
import ZakazanTerminReadDto from "../../../../model/zakazan-termin/ZakazanTerminReadDto";
import { DATE_FORMAT, TIME_FORMAT, tableDateFilter } from "../../../../infrastructure/system/Utils";
import ZakazanTerminStatusi from "../zakazan-termin-statusi/ZakazanTerminStatusi";
import EnumTerminTip from "../../../../infrastructure/system/EnumTerminTip";
import Images from "../../../../infrastructure/system/Images";
import CalendarFilter from "../../../administracija/calendar-component/CalendarFilter";
import { useTableData } from "../../../../infrastructure/system/hooks/tables/useTableData";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import { useOrganizacionaJedinicaKontaktTipList } from "../../../../infrastructure/system/hooks/organizaciona-jedinica/useOrganizacionaJedinicaKontaktTipList";
import OrganizacionaJedinicaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import { TerminType } from "../termin/Termin";

export interface ZakazaniTerminiListViewPropsType {
  zakazaniTerminList: Array<ZakazanTerminReadDto>;
  odabranaOrgJed: OrganizacionaJedinicaReadDto | undefined;
  zakazanTerminStatusList: Array<EnumBaseReadDto>;
  openDialogTermin: (termin: TerminType) => void;
  setVanredni: Dispatch<SetStateAction<boolean>>;
}
export default function ZakazaniTerminiListView(props: ZakazaniTerminiListViewPropsType) {
  const { zakazaniTerminList, odabranaOrgJed, zakazanTerminStatusList, openDialogTermin, setVanredni } = props;
  const Labels = useLabels();
  const { first, tableRows, onPage } = useTableData();
  const { organizacionaJedinicaKontaktTipList } = useOrganizacionaJedinicaKontaktTipList(odabranaOrgJed?.id);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const dt = useRef<any>(null);

  const renderVremeOdDo = (row: ZakazanTerminReadDto) => {
    return moment(row?.vremeZakazanoOd).format(TIME_FORMAT) + " - " + moment(row?.vremeZakazanoDo).format(TIME_FORMAT);
  };

  const renderPacijentFullName = (row: ZakazanTerminReadDto) => {
    return `${row?.pacijent?.ime} ${row?.pacijent?.prezime}`;
  };

  const renderPacijentVremeRodjenja = (row: ZakazanTerminReadDto) => {
    return moment(row?.pacijent?.vremeRodjenja).format(DATE_FORMAT);
  };

  const renderStatus = (row: ZakazanTerminReadDto) => {
    return (
      <div className="flex align-items-center">
        <ZakazanTerminStatusi termin={row?.zakazanTerminStatus} key={row?.zakazanTerminStatus?.id} />
        {row?.eksterniCaseID && (
          <div className="pl-3">
            <Tooltip target={`.termin-${row.id}`} position="top">
              {Labels.LABEL_NAZIV_ZAKAZIVACA}: {row?.partner?.naziv ?? ""}
            </Tooltip>
            <span className={`pi pi-search-plus text-2xl termin-${row.id}`} style={{ color: "#1199a7" }} />
          </div>
        )}
        {row.zakazanTerminTip.sifra === EnumTerminTip.VANREDAN && (
          <div>
            <Tooltip target=".vanredniTermin" position="top" />
            <Image data-pr-tooltip={Labels.ZAKAZIVANJE_PREGLEDA_VANREDNI_TERMIN} className="vanredniTermin ml-6" alt={Labels.VANREDNI} src={Images.VANREDNI_TERMIN} />
          </div>
        )}
      </div>
    );
  };

  const dateFilterTemplate = (options: any) => <CalendarFilter options={options} />;

  const tipTerminaFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        options={[
          { label: Labels.ZAKAZIVANJE_PREGLEDA_REDOVNI_TERMIN, value: EnumTerminTip.REDOVAN },
          { label: Labels.ZAKAZIVANJE_PREGLEDA_VANREDNI_TERMIN, value: EnumTerminTip.VANREDAN },
        ]}
        optionLabel="label"
        optionValue="value"
        value={options.value}
        onChange={(e) => dt.current.filter(e.value, "zakazanTerminTip.naziv", "contains")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const tipPregledaFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        options={organizacionaJedinicaKontaktTipList}
        optionLabel="kontaktTip.naziv"
        optionValue="kontaktTip.naziv"
        value={options.value}
        onChange={(e) => dt.current.filter(e.value, "kontaktTip.naziv", "equals")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const renderStatusForDropdown = (option: EnumBaseReadDto) => {
    return option ? <ZakazanTerminStatusi termin={option} key={option?.id} /> : <></>;
  };

  const terminStatusFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        options={zakazanTerminStatusList ?? []}
        optionLabel="naziv"
        optionValue="naziv"
        value={options.value}
        itemTemplate={renderStatusForDropdown}
        valueTemplate={renderStatusForDropdown}
        onChange={(e) => {
          dt.current.filter(e.value, "zakazanTerminStatus.naziv", "contains");
        }}
        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
        className="p-column-filter terminStatusFilter"
        showClear
      />
    );
  };

  return (
    <DataTable
      ref={dt}
      value={zakazaniTerminList}
      emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
      filterDisplay="row"
      rowHover
      stripedRows
      paginator
      first={first}
      paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
      rows={tableRows}
      onPage={onPage}
      selectionMode="single"
      onRowClick={(e) => {
        openDialogTermin(e.data as TerminType);
        if ((e.data as TerminType).zakazanTerminTip?.sifra === EnumTerminTip.VANREDAN) setVanredni(true);
      }}
    >
      <Column
        header={Labels.CEKAONICA_COLUMN_STATUS}
        field={"zakazanTerminStatus.naziv"}
        body={renderStatus}
        filter
        filterMatchMode="contains"
        showFilterMenu={false}
        filterElement={terminStatusFilterTemplate}
      />
      <Column header={Labels.VREME} body={renderVremeOdDo} />
      <Column header={Labels.LABEL_TIP_TERMINA} field="zakazanTerminTip.naziv" filter filterMatchMode="contains" showFilterMenu={false} filterElement={tipTerminaFilterTemplate} />
      <Column header={Labels.LABEL_TIP_PREGLEDA} field="kontaktTip.naziv" filter filterMatchMode="contains" showFilterMenu={false} filterElement={tipPregledaFilterTemplate} />
      <Column header={Labels.CEKAONICA_COLUMN_PACIJENT} field="pacijent.ime" body={renderPacijentFullName} filter filterMatchMode="contains" showFilterMenu={false} />
      <Column
        header={Labels.DATUM_RODJENJA}
        body={renderPacijentVremeRodjenja}
        field="pacijent.vremeRodjenja"
        filter
        filterElement={dateFilterTemplate}
        filterMatchMode="custom"
        filterFunction={tableDateFilter}
        sortable
        showFilterMenu={false}
      />
      <Column header={Labels.PACIJENT_TELEFON} field="pacijent.telefon" filter filterMatchMode="contains" showFilterMenu={false} />
      <Column header={Labels.LABEL_EMAIL} field="pacijent.email" filter filterMatchMode="contains" showFilterMenu={false} />
    </DataTable>
  );
}
