import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import CrudProtokolBrojLogical from "./CrudProtokolBrojLogical";

export default function CrudProtokolBrojPage() {
  const { breadCrumbItems, protokolBrojChange, onDelete, onCancel, protokolBrojOperation } = CrudProtokolBrojLogical();
  const Labels = useLabels();

  const { pristup } = useContext(AppContext);
  const leftContentsToolbar = () => (
    <>
      {protokolBrojOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          icon="pi pi-trash"
          tooltip={Labels.PROTOKOL_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          disabled={!pristup}
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} tooltip={Labels.BUTTON_CANCEL} tooltipOptions={tooltipOptionsBottom} />
  );

  return (
    <Panel>
      <BreadCrumb model={breadCrumbItems} />
      <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
        <TabPanel header={Labels.PROTOKOL}>
          <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
            <div className="grid align-items-center p-0">
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>{Labels.LABEL_ORGANIZACIONA_JEDINICA}</div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                <InputText disabled={true} value={protokolBrojChange?.organizacionaJedinica.naziv ?? ""} />
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>{Labels.PROTOKOL}</div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                <InputText disabled={true} value={protokolBrojChange?.protokolBroj.protokol.naziv ?? ""} />
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>{Labels.VREME_OD}</div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                <InputText disabled={true} value={protokolBrojChange?.vremeOd?.replace("T", " ") ?? ""} />
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>{Labels.VREME_DO}</div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                <InputText disabled={true} value={protokolBrojChange?.vremeDo?.replace("T", " ") ?? ""} />
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>{Labels.PRIMARNI_PACIJENT}</div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                <InputText disabled={true} value={protokolBrojChange?.pacijent.ime + " " + protokolBrojChange?.pacijent.prezime ?? ""} />
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>{Labels.PROTOKOL_BROJ}</div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                <InputNumber disabled={true} value={protokolBrojChange?.protokolBroj.broj ?? null} />
              </div>
            </div>
          </div>
          <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
        </TabPanel>
      </TabView>
    </Panel>
  );
}
