import { useQuery } from "@tanstack/react-query";
import QueryKeys from "../../QueryKeys";
import { TipKontaktaController } from "../../../../controllers/kontakt/TipKontaktaController";
import KontaktTipInicijalizacijaReadDto from "../../../../model/kontakt/KontaktTipInicijalizacijaReadDto";

export function useInicijalizacijaTipPregledaList() {
  const { axiosGetInicijalizacijaTipKontaktaList } = TipKontaktaController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.INICIJALIZACIJA_TIP_PREGLEDA_LIST],
    queryFn: () => axiosGetInicijalizacijaTipKontaktaList(),
    staleTime: Infinity,
  });

  const inicijalizacijaTipPregledaList: KontaktTipInicijalizacijaReadDto[] = data?.data?.data ?? [];

  return { inicijalizacijaTipPregledaList, refetch, isLoading };
}
