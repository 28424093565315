import moment from "moment";
import { useContext, useEffect, useImperativeHandle, useState } from "react";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import { FormularController } from "../../../controllers/formular/FormularController";
import { KontaktController } from "../../../controllers/kontakt/KontaktController";
import MessageType from "../../../infrastructure/system/MessageType";
import { DATE_TIME_FORMAT_3, NESACUVAN_NALAZ, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import useEffectWithoutWarnings from "../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import NesacuvanNalaz from "../../../model/NesacuvanNalaz/NesacuvanNalaz";
import EnumNesacuvanNalaz from "../../../model/enumFront/EnumNesacuvanNalaz";
import FormularPopunjenReadDto from "../../../model/formular/FormularPopunjenReadDto";
import FormularReadDto from "../../../model/formular/FormularReadDto";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import KontrolaPravaPristupaService from "../../../infrastructure/system/KontrolaPravaPristupaService";
import KontaktReadDto from "../../../model/kontakt/KontaktReadDto";
import { AxiosResponse } from "axios";
import { valueMap } from "../../formular/FormularLogical";
import PoljePopunjenoSkracenoDto from "../../../model/polje/PoljePopunjenoSkracenoReadDto";
import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import FormularSaPoljimaUpdateDto from "../../../model/formular/FormularSaPoljimaUpdateDto";
import FormularSaPoljimaCreateDto from "../../../model/formular/FormularSaPoljimaCreateDto";

interface UnosiLogicalType {
  showNoviUnosDialog: boolean;
  setShowNoviUnosDialog: React.Dispatch<React.SetStateAction<boolean>>;
  izabraniTipUnosa: FormularReadDto | undefined;
  setIzabraniTipUnosa: React.Dispatch<React.SetStateAction<FormularReadDto | undefined>>;
  showNoviUnosPage: boolean;
  setShowNoviUnosPage: React.Dispatch<React.SetStateAction<boolean>>;
  setIzabraniPopunjeniUnos: React.Dispatch<React.SetStateAction<FormularPopunjenReadDto | undefined>>;
  izabraniPopunjeniUnos: FormularPopunjenReadDto | undefined;
  onSaveFormular: () => void;
  maximizeScreen: boolean;
  setMaximizeScreen: React.Dispatch<React.SetStateAction<boolean>>;
  showPrethodniNalaziSabloni: boolean;
  openClosePrethodniSabloniNalazi: (currentState: boolean) => void;
  dataLoading: boolean;
  deleteFormularPopunjen: (id?: number) => void;
  openLeaveDialog: () => void;
  closeLeaveDialog: () => void;
  confirmLeave: () => void;
  visibleLeaveDialog: boolean;
  setVisibleNesacuvanNalazDialog: React.Dispatch<React.SetStateAction<boolean>>;
  visibleNesacuvanNalazDialog: boolean;
  checkLocalStorageForNapustenNalaz: (taskForNegativeAnswer: any) => void;
  nesacuvanNalaz: NesacuvanNalaz | undefined;
  setIzabranNesacuvanNalazOption: React.Dispatch<React.SetStateAction<NesacuvanNalazOptionType | undefined>>;
  izabranNesacuvanNalazOption: NesacuvanNalazOptionType | undefined;
  onConfirmNesacuvanNalazDialog: () => void;
  stampaj: (tipUnosa: FormularReadDto, popunjeniUnos: FormularPopunjenReadDto, poljeVrednostList?: valueMap) => void;
  formatPoljePopunjenoList: (poljePopunjeno: PoljePopunjenoSkracenoDto[]) => valueMap;
}
interface useParamsType {
  id?: string;
}
interface UnosLogicalProps {
  setClickOutsideDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  nesacuvanNalazState: NesacuvanNalaz | undefined;
  setNesacuvanNalazState: React.Dispatch<React.SetStateAction<NesacuvanNalaz | undefined>>;
  izabraniTipUnosaRef: React.MutableRefObject<any>;
  izabraniPopunjeniUnosRef: React.MutableRefObject<any>;
  isFirstRenderNalaz: boolean;
  setIsFirstRenderNalaz: React.Dispatch<React.SetStateAction<boolean>>;
  pacijent: PacijentReadDto;
  kreiraniUnosList: Array<FormularPopunjenReadDto> | undefined;
  setKreiraniUnosList: React.Dispatch<React.SetStateAction<Array<FormularPopunjenReadDto> | undefined>>;
  formularList: Array<FormularReadDto> | undefined;
  kontakt: KontaktReadDto;
}
interface NesacuvanNalazOptionType {
  label: string;
  value: string;
}
export default function UnosiLogical(props: UnosLogicalProps): UnosiLogicalType {
  const {
    setClickOutsideDisabled,
    nesacuvanNalazState,
    izabraniTipUnosaRef,
    izabraniPopunjeniUnosRef,
    isFirstRenderNalaz,
    setIsFirstRenderNalaz,
    setNesacuvanNalazState,
    pacijent,
    kreiraniUnosList,
    setKreiraniUnosList,
    formularList,
    kontakt,
  } = props;
  const id: number = Number(useParams<keyof useParamsType>()["id"]);

  const [showNoviUnosDialog, setShowNoviUnosDialog] = useState<boolean>(false);
  const [izabraniTipUnosa, setIzabraniTipUnosa] = useState<FormularReadDto>();
  const [showNoviUnosPage, setShowNoviUnosPage] = useState<boolean>(false);
  const [izabraniPopunjeniUnos, setIzabraniPopunjeniUnos] = useState<FormularPopunjenReadDto>();
  const { axiosGetPopunjenFormularList } = KontaktController();
  const [maximizeScreen, setMaximizeScreen] = useState<boolean>(false);
  const [showPrethodniNalaziSabloni, setShowPrethodniNalaziSabloni] = useState<boolean>(false);
  const { showMessage, setShowBlockUI, authData } = useContext(AppContext);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [visibleLeaveDialog, setVisibleLeaveDialog] = useState(false);
  const [visibleNesacuvanNalazDialog, setVisibleNesacuvanNalazDialog] = useState(false);
  const [nesacuvanNalaz, setNesacuvanNalaz] = useState<NesacuvanNalaz>();
  const [izabranNesacuvanNalazOption, setIzabranNesacuvanNalazOption] = useState<NesacuvanNalazOptionType>();
  const [taskForNastaviBezCuvanja, setTaskForNastaviBezCuvanja] = useState<() => void>();
  const navigate = useNavigate();
  const location = useLocation();

  const { axiosDeleteFormularPopunjen, axiosGetPoljePopunjenoList, axiosUpdateFormularPopunjen, axiosGetFormularPrint, axiosCreateFormularPopunjenAndPrint } = FormularController();
  const postLogHighLevel = useLogHighLevel();
  const { hasFormularPopunjenUpdate } = KontrolaPravaPristupaService();
  const Labels = useLabels();

  useEffectWithoutWarnings(() => {
    if (location?.state?.fromVirtuelnaCekaonica && location?.state?.podrazumevaniFormularId) {
      setIzabraniTipUnosa(formularList?.filter((formular) => formular.id === location?.state?.podrazumevaniFormularId)[0]);
      setShowNoviUnosPage(true);
      getFormularPopunjenListByKontakt();
    }
  }, [formularList]);

  useImperativeHandle(izabraniTipUnosaRef, () => ({
    izabraniTipUnosa,
  }));

  useImperativeHandle(izabraniPopunjeniUnosRef, () => ({
    izabraniPopunjeniUnos,
  }));

  const getFormularPopunjenListByKontakt = () => {
    if (id) {
      let startTime = moment(new Date());
      setDataLoading(true);
      axiosGetPopunjenFormularList(id)
        .then((response: any) => {
          setKreiraniUnosList(response.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setDataLoading, startTime);
        });
    }
  };

  useEffectOnce(() => {
    if (!kreiraniUnosList) {
      getFormularPopunjenListByKontakt();
    }
  });

  const onSaveFormular = () => {
    postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_UNOSI_1 + izabraniTipUnosa?.naziv + ".", pacijent.id);
    setShowNoviUnosPage(false);
    getFormularPopunjenListByKontakt();
    setClickOutsideDisabled(false);
  };

  //ukoliko se do stranice kontakt dolazi preko nesacuvanog nalaza
  useEffect(() => {
    if (nesacuvanNalazState && isFirstRenderNalaz) {
      setShowNoviUnosPage(true);
      setIzabraniTipUnosa(nesacuvanNalazState.izabraniTipUnosa);
      setIsFirstRenderNalaz(false);
      setIzabraniPopunjeniUnos(nesacuvanNalazState.izabraniPopunjeniUnos);
    }
  }, [nesacuvanNalazState, isFirstRenderNalaz, setIsFirstRenderNalaz]);

  const checkLocalStorageForNapustenNalaz = (taskForNegativeAnswer: () => void) => {
    let nesacuvanNalaz = localStorage.getItem(NESACUVAN_NALAZ);
    if (nesacuvanNalaz !== null) {
      setNesacuvanNalaz(JSON.parse(nesacuvanNalaz));
      setVisibleNesacuvanNalazDialog(true);
      setTaskForNastaviBezCuvanja(() => taskForNegativeAnswer);
    } else {
      taskForNegativeAnswer();
    }
  };

  const onConfirmNesacuvanNalazDialog = () => {
    if (izabranNesacuvanNalazOption?.value === EnumNesacuvanNalaz.NASTAVI_BEZ_CUVANJA) {
      setVisibleNesacuvanNalazDialog(false);
      localStorage.removeItem(NESACUVAN_NALAZ);
      setNesacuvanNalaz(undefined);
      setNesacuvanNalazState(undefined);
      taskForNastaviBezCuvanja && taskForNastaviBezCuvanja();
    } else if (izabranNesacuvanNalazOption?.value === EnumNesacuvanNalaz.OTVORI_NALAZ) {
      navigate(`/kontakt/${nesacuvanNalaz?.kontaktID}`, {
        state: {
          isFirstRenderNalaz: true,
        },
      });
      setVisibleNesacuvanNalazDialog(false);
    }
  };

  const openClosePrethodniSabloniNalazi = (currentState: boolean) => {
    setShowPrethodniNalaziSabloni(!currentState);
  };

  const deleteFormularPopunjen = (id?: number) => {
    setShowBlockUI(true);
    const formularPopunjenId = id ? id : izabraniPopunjeniUnos?.id;

    if (formularPopunjenId) {
      axiosDeleteFormularPopunjen(formularPopunjenId)
        .then(() => {
          openClosePrethodniSabloniNalazi(true);
          setShowNoviUnosPage(false);
          getFormularPopunjenListByKontakt();
          setMaximizeScreen(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_FORMULAR_POPUNJEN_SUCCESS);

          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_UNOSI_1 + izabraniTipUnosa?.naziv + ".", pacijent.id);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const openLeaveDialog = () => {
    setVisibleLeaveDialog(true);
  };

  const closeLeaveDialog = () => {
    setVisibleLeaveDialog(false);
  };

  const confirmLeave = () => {
    setVisibleLeaveDialog(false);
    openClosePrethodniSabloniNalazi(true);
    setShowNoviUnosPage(false);
    setMaximizeScreen(false);
    setClickOutsideDisabled(false);
  };

  const formatPoljePopunjenoList = (poljePopunjeno: PoljePopunjenoSkracenoDto[]) => {
    const poljeVrednost: valueMap = {};
    poljePopunjeno.forEach((poljePopunjeno: PoljePopunjenoSkracenoDto) => {
      if (poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.TEXT || poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.LONG_TEXT) {
        poljeVrednost[poljePopunjeno?.polje?.id] = poljePopunjeno.vrednostString;
      } else if (
        poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.NUMBER_DECIMAL ||
        poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.NUMBER_INTEGER
      ) {
        poljeVrednost[poljePopunjeno?.polje?.id] = poljePopunjeno.vrednostNumber;
      } else if (poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.DATE_TIME) {
        poljeVrednost[poljePopunjeno?.polje?.id] = moment(poljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
      } else if (poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.DATE) {
        poljeVrednost[poljePopunjeno?.polje?.id] = moment(poljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
      } else if (poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.TIME) {
        poljeVrednost[poljePopunjeno?.polje?.id] = moment(poljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
      } else if (poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.BOOLEAN) {
        poljeVrednost[poljePopunjeno?.polje?.id] = poljePopunjeno.vrednostBoolean;
      } else if (poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET || poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET_RADIO) {
        poljeVrednost[poljePopunjeno?.polje?.id] = poljePopunjeno.vrednostEnumeratorItem.id;
      } else if (poljePopunjeno?.polje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET_MULTISELECT) {
        if (poljeVrednost[poljePopunjeno?.polje?.id] !== undefined) {
          poljeVrednost[poljePopunjeno?.polje?.id] = [...poljeVrednost[poljePopunjeno?.polje?.id], poljePopunjeno?.vrednostEnumeratorItem];
        } else {
          poljeVrednost[poljePopunjeno?.polje?.id] = [poljePopunjeno?.vrednostEnumeratorItem];
        }
      }
    });

    return poljeVrednost;
  };

  const stampaj = async (tipUnosa: FormularReadDto, popunjeniUnos: FormularPopunjenReadDto, poljeVrednostList?: valueMap) => {
    const poljeVrednostListTemp = poljeVrednostList ? poljeVrednostList : await axiosGetPoljePopunjenoList(popunjeniUnos.id).then((res) => formatPoljePopunjenoList(res.data.data));

    const idFormular = tipUnosa ? tipUnosa.id : popunjeniUnos?.formular?.id;
    let obj: FormularSaPoljimaUpdateDto | FormularSaPoljimaCreateDto = {
      formular: { id: idFormular },
      polja: poljeVrednostListTemp,
      kontakt: { id: id },
    };

    if (popunjeniUnos && (hasFormularPopunjenUpdate || authData?.currentRadnik.id === popunjeniUnos?.radnikKreirao.id)) {
      setShowBlockUI(true);
      obj = { ...obj, id: popunjeniUnos?.id };
      axiosUpdateFormularPopunjen(obj, popunjeniUnos?.id)
        .then(() => {
          axiosGetFormularPrint(popunjeniUnos)
            .then((response: AxiosResponse) => {
              const byteChars = atob(response.data);
              const dataArray = new Array(byteChars.length);
              for (let i = 0; i < byteChars.length; i++) {
                dataArray[i] = byteChars.charCodeAt(i);
              }
              const byteArray = new Uint8Array(dataArray);
              const file = new Blob([byteArray], { type: "application/pdf" });
              const url = URL.createObjectURL(file);
              const w = window.open(url, "_blank");
              if (w) {
                w!.document.write(
                  "<html><head><title>" +
                    popunjeniUnos.kontakt.pacijent.ime +
                    " " +
                    popunjeniUnos.kontakt.pacijent.prezime +
                    " - " +
                    popunjeniUnos.kontakt.kontaktTip.naziv +
                    "</title></head><body>" +
                    '<embed width="100%" height="100%" name="plugin" src="' +
                    url +
                    '" ' +
                    'type="application/pdf" internalinstanceid="21"></body></html>'
                );
              } else {
                showMessage(MessageType.WARNING, Labels.FORMULAR_POPUP);
              }
            })
            .catch((error) => {
              handleAxiosCallError(showMessage, error);
            })
            .finally(() => {
              openClosePrethodniSabloniNalazi(true);
              onSaveFormular();
              setShowBlockUI(false);
            });
        })
        .catch((error) => {
          setShowBlockUI(false);
          handleAxiosCallError(showMessage, error);
        });
    } else if (popunjeniUnos && !(hasFormularPopunjenUpdate || authData?.currentRadnik.id === popunjeniUnos?.radnikKreirao.id)) {
      axiosGetFormularPrint(popunjeniUnos)
        .then((response: AxiosResponse) => {
          const byteChars = atob(response.data);
          const dataArray = new Array(byteChars.length);
          for (let i = 0; i < byteChars.length; i++) {
            dataArray[i] = byteChars.charCodeAt(i);
          }
          const byteArray = new Uint8Array(dataArray);
          const file = new Blob([byteArray], { type: "application/pdf" });
          const url = URL.createObjectURL(file);
          const w = window.open(url, "_blank");
          if (w) {
            w!.document.write(
              "<html><head><title>" +
                popunjeniUnos.kontakt.pacijent.ime +
                " " +
                popunjeniUnos.kontakt.pacijent.prezime +
                " - " +
                popunjeniUnos.kontakt.kontaktTip.naziv +
                "</title></head><body>" +
                '<embed width="100%" height="100%" name="plugin" src="' +
                url +
                '" ' +
                'type="application/pdf" internalinstanceid="21"></body></html>'
            );
          } else {
            showMessage(MessageType.WARNING, Labels.FORMULAR_POPUP);
          }
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          openClosePrethodniSabloniNalazi(true);
          onSaveFormular();
          setShowBlockUI(false);
        });
    } else if (!popunjeniUnos) {
      setShowBlockUI(true);
      axiosCreateFormularPopunjenAndPrint(obj as FormularSaPoljimaCreateDto)
        .then((response: AxiosResponse) => {
          const byteChars = atob(response.data);
          const dataArray = new Array(byteChars.length);
          for (let i = 0; i < byteChars.length; i++) {
            dataArray[i] = byteChars.charCodeAt(i);
          }
          const byteArray = new Uint8Array(dataArray);
          const file = new Blob([byteArray], { type: "application/pdf" });
          const url = URL.createObjectURL(file);
          const w = window.open(url, "_blank");
          if (w) {
            w!.document.write(
              "<html><head><title>" +
                kontakt.pacijent?.ime +
                " " +
                kontakt.pacijent?.prezime +
                " - " +
                kontakt.kontaktTip?.naziv +
                "</title></head><body>" +
                '<embed width="100%" height="100%" name="plugin" src="' +
                url +
                '" ' +
                'type="application/pdf" internalinstanceid="21"></body></html>'
            );
          } else {
            showMessage(MessageType.WARNING, Labels.FORMULAR_POPUP);
          }
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          openClosePrethodniSabloniNalazi(true);
          onSaveFormular();
          setShowBlockUI(false);
        });
    }
  };

  return {
    showNoviUnosDialog,
    setShowNoviUnosDialog,
    izabraniTipUnosa,
    setIzabraniTipUnosa,
    showNoviUnosPage,
    setShowNoviUnosPage,
    setIzabraniPopunjeniUnos,
    izabraniPopunjeniUnos,
    onSaveFormular,
    maximizeScreen,
    setMaximizeScreen,
    showPrethodniNalaziSabloni,
    openClosePrethodniSabloniNalazi,
    dataLoading,
    deleteFormularPopunjen,
    confirmLeave,
    openLeaveDialog,
    closeLeaveDialog,
    visibleLeaveDialog,
    visibleNesacuvanNalazDialog,
    setVisibleNesacuvanNalazDialog,
    checkLocalStorageForNapustenNalaz,
    nesacuvanNalaz,
    izabranNesacuvanNalazOption,
    setIzabranNesacuvanNalazOption,
    onConfirmNesacuvanNalazDialog,
    stampaj,
    formatPoljePopunjenoList,
  };
}
