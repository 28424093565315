import { useQuery } from "@tanstack/react-query";
import { UstanovaController } from "../../../../controllers/ustanova/UstanovaController";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";
import QueryKeys from "../../QueryKeys";

export function useUstanova() {
  const { axiosReadUstanova } = UstanovaController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.USTANOVA],
    queryFn: () => axiosReadUstanova(),
  });

  const ustanova: UstanovaReadDto = data?.data?.data;

  return { ustanova, refetch, isLoading };
}
