import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import PlacanjeController from "../../../../controllers/placanje/PlacanjeController";
import { DATE_TIME_FORMAT, dateLocalDate, formatPeriod, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import PlacanjeReadDto from "../../../../model/placanje/UstanovaPlacanjeReadDto";

interface UstanovaPlacanjeLogicalType {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  tabIndex: number;
  placanjaLoading: boolean;
  first: number;
  tableRows: number;
  placanjeList: Array<PlacanjeReadDto>;
  placanjeListSuperadmin: Array<PlacanjeReadDto>;
  placanjaSuperadminLoading: boolean;
  openPlacanje: (entityOperation: string, id?: number) => void;
  previewPlacanjaUgovorDialog: () => void;

  isPreviewOpen: boolean;
  closePreview: () => void;
  renderDateTime: (date: Date) => string;
}

interface UstanovaPlacanjeProps {
  ustanovaId: number | undefined;
  ustanovaNaziv: string | undefined;
}

export default function UstanovaPlacanjeLogical(props: UstanovaPlacanjeProps): UstanovaPlacanjeLogicalType {
  const { ustanovaNaziv } = props;
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const location = useLocation();
  const [tabIndex] = useState(location.state && location.state.placanjeTabIndex !== undefined ? location.state.placanjeTabIndex : 0);
  const [index, setIndex] = useState(tabIndex);
  const [placanjaLoading, setPlacanjaLoading] = useState<boolean>(false);
  const [first, setFirst] = useState<number>(0);
  const [tableRows] = useState(10);
  const [placanjeList, setPlacanjeList] = useState<Array<PlacanjeReadDto>>([]);
  const [placanjeListSuperadmin, setPlacanjeListSuperadmin] = useState<Array<PlacanjeReadDto>>([]);
  const { axiosGetAllPlacanjeList, axiosGetPlacanjeListByUstanovaID } = PlacanjeController();
  const [placanjaSuperadminLoading, setPlacanjaSuperadminLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const renderDateTime = (date: Date) => (date ? moment(date).format(DATE_TIME_FORMAT) : "");

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setPlacanjaLoading(true);
    setPlacanjaSuperadminLoading(true);
    if (props.ustanovaId === undefined) {
      axiosGetAllPlacanjeList()
        .then((res: any) => {
          setPlacanjeList(res.data.data);
          res.data.data.forEach((gen: any) => {
            gen.datumPlacanja = dateLocalDate(gen.datumPlacanja);
            gen.obracunatiIznos = gen.obracunatiIznos + Labels.VALUTA;
            gen.uplacenIznos = gen.uplacenIznos + Labels.VALUTA;
          });
          res.data.data.forEach((gen: any) => {
            gen.obracunskiPeriod = formatPeriod(gen.obracunskiPeriod);
          });
          setFirst(0);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPlacanjaLoading, startTime);
        });
    } else {
      axiosGetPlacanjeListByUstanovaID(props.ustanovaId)
        .then((res: any) => {
          setPlacanjeListSuperadmin(res.data.data);
          res.data.data.forEach((gen: any) => {
            gen.datumPlacanja = dateLocalDate(gen.datumPlacanja);
            gen.obracunatiIznos = gen.obracunatiIznos + Labels.VALUTA;
            gen.uplacenIznos = gen.uplacenIznos + Labels.VALUTA;
          });
          res.data.data.forEach((gen: any) => {
            gen.obracunskiPeriod = formatPeriod(gen.obracunskiPeriod);
          });
          setFirst(0);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPlacanjaSuperadminLoading, startTime);
        });
    }
  };

  const previewPlacanjaUgovorDialog = () => {
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    fetchData();
  };

  const openPlacanje = (entityOperation: string, id?: number) => {
    navigate(`/crud-ustanova/${props.ustanovaId}/crud-placanje`, {
      state: { placanjeOperation: entityOperation, ustanovaNaziv: ustanovaNaziv, placanjeID: id },
    });
  };

  return {
    index,
    setIndex,
    tabIndex,
    placanjaLoading,
    first,
    tableRows,
    placanjeList,
    placanjeListSuperadmin,
    placanjaSuperadminLoading,
    openPlacanje,
    previewPlacanjaUgovorDialog,
    isPreviewOpen,
    closePreview,
    renderDateTime,
  };
}
