import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import UpozorenjeDto from "../../model/upozorenje/UpozorenjeDto";
import { AppContext } from "../../Store";

interface UpozorenjeControllerType {
  axiosGetUpozorenje: () => Promise<AxiosResponse<any>>;
  axiosCreateUpozorenje: (upozorenje: UpozorenjeDto) => Promise<AxiosResponse<any>>;
  axiosDeleteUpozorenje: () => Promise<AxiosResponse<any>>;
}

export function UpozorenjeController(): UpozorenjeControllerType {
  const { UPOZORENJE } = Endpoint;
  const { authData } = useContext(AppContext);

  const axiosGetUpozorenje = () => {
    return axios.get(UPOZORENJE, axiosConfig(authData!.token));
  };

  const axiosCreateUpozorenje = (upozorenje: UpozorenjeDto) => {
    return axios.post(UPOZORENJE, upozorenje, axiosConfig(authData!.token));
  };

  const axiosDeleteUpozorenje = () => {
    return axios.delete(UPOZORENJE, axiosConfig(authData!.token));
  };

  return {
    axiosGetUpozorenje,
    axiosCreateUpozorenje,
    axiosDeleteUpozorenje,
  };
}
