import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../../Store";
import { DodatniModulController } from "../../../../../controllers/dodatni-modul/DodatniModulController";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import { DATE_FORMAT_2, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../../infrastructure/system/Utils";
import SearchBaseDto from "../../../../../model/SearchBaseDto";
import DodatniModulReadDto from "../../../../../model/licenca/dodatni-modul/DodatniModulReadDto";
import UstanovaDodatniModulReadDto from "../../../../../model/ustanova/UstanovaDodatniModulReadDto";

interface UstanovaDodatniModulListLogicalType {
  ustanovaDodatniModulList: Array<UstanovaDodatniModulReadDto>;
  first: number;
  tableRows: number;
  onPage: (rows: number, first: number) => void;
  openDodatniModul: (entityOperation: string, id?: number, naziv?: string, vaziDo?: Date) => void;
  dodatniModulListLoading: boolean;
  ustanovaDodatniModulSearchData: SearchBaseDto;
  fetchDataAdmin: () => void;
  changeUstanovaDodatniModulSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  searchUstanovaDodatniModulByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  dodatniModulList: Array<DodatniModulReadDto>;
  hasButtons: (rowDodatniModul: UstanovaDodatniModulReadDto) => boolean;
  dodatniModulExpired: (rowDodatniModul: UstanovaDodatniModulReadDto) => boolean;
  dodatniModulInProgress: (rowDodatniModul: UstanovaDodatniModulReadDto) => boolean;
}

interface UstanovaDodatniModulListLogicalProps {
  ustanovaId?: number | undefined;
  ustanovaNaziv?: string | undefined;
}

export default function UstanovaDodatniModulListLogical(props: UstanovaDodatniModulListLogicalProps): UstanovaDodatniModulListLogicalType {
  const { ustanovaId, ustanovaNaziv } = props;
  const { axiosUstanovaGetDodatniModulList, axiosUstanovaSearchDodatniModul, axiosSearchDodatniModulList } = DodatniModulController();

  const { showMessage } = useContext(AppContext);
  const [ustanovaDodatniModulList, setUstanovaDodatniModulList] = useState<Array<UstanovaDodatniModulReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const navigate = useNavigate();
  const [dodatniModulListLoading, setdodatniModuliListLoading] = useState<boolean>(false);
  const [ustanovaDodatniModulSearchData, setUstanovaDodatniModulSearchData] = useState<SearchBaseDto>({});
  const [dodatniModulList, setDodatniModulList] = useState<Array<DodatniModulReadDto>>([]);

  useEffectOnce(() => {
    fetchDodatniModul();
    if (ustanovaId === undefined) {
      fetchDataAdmin();
    } else {
      fetch();
    }
  });

  const fetchDodatniModul = () => {
    axiosSearchDodatniModulList({})
      .then(({ data: { data } }: { data: { data: Array<DodatniModulReadDto> } }) => {
        setDodatniModulList(data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {});
  };

  const fetch = () => {
    let startTime = moment(new Date());
    setdodatniModuliListLoading(true);
    axiosUstanovaGetDodatniModulList(Number(ustanovaId))
      .then(({ data: { data } }: { data: { data: Array<UstanovaDodatniModulReadDto> } }) => {
        setUstanovaDodatniModulList(data);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setdodatniModuliListLoading, startTime);
      });
  };

  const fetchDataAdmin = () => {
    const startTime = moment(new Date());
    setdodatniModuliListLoading(true);
    axiosUstanovaSearchDodatniModul(ustanovaDodatniModulSearchData)
      .then(({ data: { data } }: { data: { data: Array<UstanovaDodatniModulReadDto> } }) => {
        setUstanovaDodatniModulList(data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setdodatniModuliListLoading, startTime);
      });
  };

  const onPage = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const openDodatniModul = (entityOperation: string, id?: number) => {
    const modulId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-ustanova/${ustanovaId}/crud-ustanova-dodatni-modul/${modulId}`, {
      state: { dodatniModulOperation: entityOperation, ustanovaDodatniModulId: ustanovaId, ustanovaNaziv: ustanovaNaziv },
    });
  };

  const searchUstanovaDodatniModulByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchDataAdmin();
    }
  };

  const changeUstanovaDodatniModulSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setUstanovaDodatniModulSearchData({
      ...ustanovaDodatniModulSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const hasButtons = (rowDodatniModul: UstanovaDodatniModulReadDto) => {
    const ustanovaDodatniModulByCategory = ustanovaDodatniModulList.filter((ustanovaDodatniModul) => ustanovaDodatniModul.dodatniModul.id === rowDodatniModul.dodatniModul.id);
    return ustanovaDodatniModulByCategory[0].id === rowDodatniModul.id;
  };

  const dodatniModulExpired = (rowDodatniModul: UstanovaDodatniModulReadDto) => {
    return moment(rowDodatniModul.vaziDo).isBefore(moment().format(DATE_FORMAT_2));
  };

  const dodatniModulInProgress = (rowDodatniModul: UstanovaDodatniModulReadDto) => {
    return (
      (moment(rowDodatniModul.vaziDo).isSameOrAfter(moment().format(DATE_FORMAT_2)) && moment(rowDodatniModul.vaziOd).isSameOrBefore(moment().format(DATE_FORMAT_2))) ||
      (rowDodatniModul.vaziDo === null && moment(rowDodatniModul.vaziOd).isSameOrBefore(moment().format(DATE_FORMAT_2)))
    );
  };

  return {
    ustanovaDodatniModulList,
    first,
    tableRows,
    onPage,
    openDodatniModul,
    dodatniModulListLoading,
    ustanovaDodatniModulSearchData,
    fetchDataAdmin,
    changeUstanovaDodatniModulSearchData,
    searchUstanovaDodatniModulByEnter,
    dodatniModulList,
    hasButtons,
    dodatniModulExpired,
    dodatniModulInProgress,
  };
}
