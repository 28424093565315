import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { FileUploadHandlerEvent } from "primereact/fileupload";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import { EnumController } from "../../../controllers/enum/EnumController";
import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import { UstanovaController } from "../../../controllers/ustanova/UstanovaController";
import MessageType from "../../../infrastructure/system/MessageType";
import { MAX_FILE_UPLOAD, checkEmpty, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import UstanovaGridRadniDanReadDto from "../../../model/ustanova/UstanovaGridRadniDanReadDto";
import UstanovaGridReadDto from "../../../model/ustanova/UstanovaGridReadDto";
import UstanovaGridUpdateDto from "../../../model/ustanova/UstanovaGridUpdateDto";
import ZakazanTerminPacijentJasperUstanovaCreateDto from "../../../model/ustanova/ZakazanTerminPacijentJasperUstanovaCreateDto";
import ZakazanTerminPacijentJasperUstanovaReadDto from "../../../model/ustanova/ZakazanTerminPacijentJasperUstanovaReadDto";

interface UstanovaGridLogicalType {
  ustanovaGrid: UstanovaGridUpdateDto | undefined;
  setUstanovaGrid: any;
  onSave: () => void;
  radniDani: Array<EnumBaseReadDto>;
  trajanjeTerminaList: Array<EnumBaseReadDto>;
  ustanvovaGridLoading: boolean;
  radniDaniMap: Map<string, EnumBaseReadDto> | undefined;
  documentUploadHandler: (event: FileUploadHandlerEvent) => void;
  zakazanTerminPacijentJasperDocument: ZakazanTerminPacijentJasperUstanovaReadDto | ZakazanTerminPacijentJasperUstanovaCreateDto | undefined;
  setZakazanTerminPacijentJasperDocument: React.Dispatch<React.SetStateAction<ZakazanTerminPacijentJasperUstanovaReadDto | ZakazanTerminPacijentJasperUstanovaCreateDto | undefined>>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
}

interface UseParamsType {
  ustanovaId?: string;
}
export default function UstanovaGridLogical(): UstanovaGridLogicalType {
  const { showMessage, setShowBlockUI, nadjimed } = useContext(AppContext);

  const { ustanovaId } = useParams<keyof UseParamsType>();
  const Labels = useLabels();
  const [ustanovaGrid, setUstanovaGrid] = useState<UstanovaGridUpdateDto | undefined>();
  const { axiosGetRadniDanList, axiosGetTrajanjeTerminaList } = EnumController();
  const { axiosUpdateUstanovaGrid, axiosGetUstanovaGrid, axiosCreateZakazanTerminUstanovaJasper, axiosGetZakazanTerminUstanovaJasper, axiosDeleteZakazanTerminUstanovaJasper } = UstanovaController();
  const {
    axiosSuperadminGetUstanovaGrid,
    axiosSuperadminUpdateUstanovaGrid,
    axiosSuperadminGetZakazanTerminUstanovaJasper,
    axiosSuperadminCreateZakazanTerminUstanovaJasper,
    axiosSuperadminDeleteZakazanTerminUstanovaJasper,
  } = SuperadminController();
  const [radniDani, setRadniDani] = useState<Array<EnumBaseReadDto>>([]);
  const [trajanjeTerminaList, setTrajajanjeTerminaList] = useState<Array<EnumBaseReadDto>>([]);
  const [ustanvovaGridLoading, setUstanovaGridLoading] = useState<boolean>(true);
  const [radniDaniMap, setRadniDaniMap] = useState<Map<string, EnumBaseReadDto>>();
  const [zakazanTerminPacijentJasperDocument, setZakazanTerminPacijentJasperDocument] = useState<ZakazanTerminPacijentJasperUstanovaCreateDto | undefined>();
  const [zakazanTerminPacijentJasperDocumentId, setZakazanTerminPacijentJasperDocumentId] = useState<number | undefined>();
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);

  const postLogHighLevel = useLogHighLevel();

  const validate = () => {
    let isInvalid = false;

    if (nadjimed && checkEmpty(ustanovaGrid?.podrazumevanaDuzinaTrajanjaRezervacijeTermina)) {
      setInvalidFields((prev) => ({ ...prev, podrazumevanaDuzinaTrajanjaRezervacijeTermina: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onSave = () => {
    if (!validate()) {
      return;
    }

    setShowBlockUI(true);

    const ustanovaGridTemp: UstanovaGridUpdateDto | undefined = ustanovaGrid
      ? {
          ...ustanovaGrid,
          ustanovaGridRadniDanList: ustanovaGrid.ustanovaGridRadniDanList
            ? ustanovaGrid.ustanovaGridRadniDanList.map((radniDanSifra: string) => {
                return { radniDan: { sifra: radniDanSifra } };
              })
            : [],
        }
      : undefined;

    const requestUpdateGrid = ustanovaId ? ustanovaGridTemp && axiosSuperadminUpdateUstanovaGrid(ustanovaGridTemp, Number(ustanovaId)) : ustanovaGridTemp && axiosUpdateUstanovaGrid(ustanovaGridTemp);

    requestUpdateGrid &&
      requestUpdateGrid
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.USTANOVA_GRID_TITLE_MESSAGE_UPDATE_USTANOVA_GRID_SUCCESS);

          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_GRID);
        })
        .catch((error: any) => handleAxiosCallError(showMessage, error))
        .finally(() => setShowBlockUI(false));

    if (zakazanTerminPacijentJasperDocumentId && !zakazanTerminPacijentJasperDocument) {
      const requestDeleteJasper = ustanovaId ? axiosSuperadminDeleteZakazanTerminUstanovaJasper(Number(ustanovaId)) : axiosDeleteZakazanTerminUstanovaJasper();
      requestDeleteJasper
        .then(() => {
          setZakazanTerminPacijentJasperDocumentId(undefined);
        })
        .catch((error) => handleAxiosCallError(showMessage, error));
    } else if (zakazanTerminPacijentJasperDocument) {
      const requestJasper = ustanovaId
        ? axiosSuperadminCreateZakazanTerminUstanovaJasper(Number(ustanovaId), zakazanTerminPacijentJasperDocument)
        : axiosCreateZakazanTerminUstanovaJasper(zakazanTerminPacijentJasperDocument);
      requestJasper
        .then((response: AxiosResponse) => {
          setZakazanTerminPacijentJasperDocumentId(response.data.data.id);
          setZakazanTerminPacijentJasperDocument(response.data.data);
        })
        .catch((error) => handleAxiosCallError(showMessage, error));
    }
  };

  useEffectOnce(() => {
    const requestTrajanjeTerminaList = axiosGetTrajanjeTerminaList();
    const requestRadniDanList = axiosGetRadniDanList();
    const requestUstanovaGrid = ustanovaId ? axiosSuperadminGetUstanovaGrid(Number(ustanovaId)) : axiosGetUstanovaGrid();
    const requestJasper = ustanovaId ? axiosSuperadminGetZakazanTerminUstanovaJasper(Number(ustanovaId)) : axiosGetZakazanTerminUstanovaJasper();
    let startTime = moment(new Date());
    setUstanovaGridLoading(true);
    axios
      .all([requestRadniDanList, requestUstanovaGrid, requestTrajanjeTerminaList, requestJasper])
      .then(
        axios.spread((responseRadniDanList: any, responseUstanovaGrid: any, responseTrajanjeTerminaList: any, responseJasper: any) => {
          let ustanovaGridTemp: UstanovaGridReadDto = responseUstanovaGrid.data.data;
          let ustanovaGridRadniDaniListTemp: Array<string> | undefined;
          ustanovaGridRadniDaniListTemp = ustanovaGridTemp?.ustanovaGridRadniDanList?.map((radanDanEntry: UstanovaGridRadniDanReadDto) => {
            return radanDanEntry.radniDan.sifra;
          });
          let radniDaniTemp: Array<EnumBaseReadDto> = responseRadniDanList.data.data.map((radniDan: EnumBaseReadDto) => ({ ...radniDan, naziv: radniDan.naziv.toLowerCase() }));
          let radniDaniMapTemp = new Map<string, EnumBaseReadDto>();
          radniDaniTemp.forEach((radniDan: EnumBaseReadDto) => radniDaniMapTemp.set(radniDan.sifra, { ...radniDan }));
          setRadniDaniMap(radniDaniMapTemp);
          setRadniDani(radniDaniTemp);
          setTrajajanjeTerminaList(responseTrajanjeTerminaList.data.data);
          setUstanovaGrid({ ...ustanovaGridTemp, ustanovaGridRadniDanList: ustanovaGridRadniDaniListTemp ?? [] });
          if (responseJasper.data.data.length > 0) {
            setZakazanTerminPacijentJasperDocument(responseJasper.data.data[0]);
            setZakazanTerminPacijentJasperDocumentId(responseJasper.data.data[0].id);
          }
        })
      )
      .catch((error: any) => handleAxiosCallError(showMessage, error))
      .finally(() => {
        skeletonTimeout(setUstanovaGridLoading, startTime);
      });
  });

  const documentUploadHandler = (e: FileUploadHandlerEvent) => {
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }

        if (reader.result) {
          setZakazanTerminPacijentJasperDocument({
            sadrzaj: reader.result.toString().substr(reader.result?.toString().indexOf(",") + 1),
            naziv: file.name,
            velicina: file.size,
            tip: file.type,
          });
        }
      };
    });
  };

  return {
    ustanovaGrid,
    setUstanovaGrid,
    onSave,
    radniDani,
    trajanjeTerminaList,
    ustanvovaGridLoading,
    radniDaniMap,
    documentUploadHandler,
    zakazanTerminPacijentJasperDocument,
    setZakazanTerminPacijentJasperDocument,
    invalidFields,
    setInvalidFields,
  };
}
