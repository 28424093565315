import { TabViewTabChangeEvent } from "primereact/tabview";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../Store";
import { FormularController } from "../../controllers/formular/FormularController";
import { KontaktController } from "../../controllers/kontakt/KontaktController";
import { PacijentPoljeController } from "../../controllers/pacijent-polje/PacijentPoljeController";
import EnumPacijentPoljeKategorija from "../../infrastructure/system/EnumPacijentPoljeKategorija";
import { NESACUVAN_NALAZ, handleAxiosCallError } from "../../infrastructure/system/Utils";
import useLogHighLevel from "../../infrastructure/system/hooks/useLogHighLevel";
import { KONTAKT_DOKUMENTACIJA_INDEX, KONTAKT_INDEX } from "../../model/IndeksiTabovaNaStrani";
import NesacuvanNalaz from "../../model/NesacuvanNalaz/NesacuvanNalaz";
import FormularPopunjenReadDto from "../../model/formular/FormularPopunjenReadDto";
import FormularReadDto from "../../model/formular/FormularReadDto";
import KontaktDijagnozaReadDto from "../../model/kontakt/KontaktDijagnozaReadDto";
import KontaktPaketReadDto from "../../model/kontakt/KontaktPaketReadDto";
import KontaktReadDto from "../../model/kontakt/KontaktReadDto";
import KontaktUslugaReadDto from "../../model/kontakt/KontaktUslugaReadDto";
import KontaktUtrosakReadDto from "../../model/kontakt/KontaktUtrosakReadDto";
import ReceptReadDto from "../../model/kontakt/ReceptReadDto";
import PacijentPoljePopunjeno from "../../model/pacijent-polje/PacijentPoljePopunjeno";
import EnumBaseReadDto from "../../model/sifarnik/EnumBaseReadDto";
import SifarnikUslugaDto from "../../model/sifarnik/SifarnikUslugaRFZODto";
import ZakazanTerminUslugaPlannedReadDto from "../../model/zakazan-termin/ZakazanTerminUslugaPlannedReadDto";

interface KontaktLogicalType {
  kontakt?: KontaktReadDto;
  formularList: Array<FormularReadDto> | undefined;
  activeIndex: number;
  dokumentacijaActiveIndex: number;
  deleteKontakt: () => void;
  setClickOutsideDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  clickOutsideDisabled: boolean;
  closeLeaveMainTabviewDialog: () => void;
  visibleLeaveMainTabviewDialog: boolean;
  openLeaveMainTabviewDialog: (e: TabViewTabChangeEvent) => void;
  confirmLeaveMainTabview: () => void;
  closeLeaveInnerDialog: () => void;
  visibleLeaveInnerTabviewDialog: boolean;
  openLeaveInnerDialog: (e: TabViewTabChangeEvent) => void;
  confirmLeaveInner: () => void;
  poljeVrednostListRef: React.MutableRefObject<any>;
  nesacuvanNalazState: NesacuvanNalaz | undefined;
  izabraniTipUnosaRef: React.MutableRefObject<any>;
  izabraniPopunjeniUnosRef: React.MutableRefObject<any>;
  isFirstRenderNalaz: boolean;
  setIsFirstRenderNalaz: React.Dispatch<React.SetStateAction<boolean>>;
  setNesacuvanNalazState: React.Dispatch<React.SetStateAction<NesacuvanNalaz | undefined>>;
  kontaktPaketList: Array<KontaktPaketReadDto> | undefined;
  setKontaktPaketList: React.Dispatch<React.SetStateAction<Array<KontaktPaketReadDto> | undefined>>;
  kontaktDijagnozaList: Array<KontaktDijagnozaReadDto> | undefined;
  setKontaktDijagnozaList: React.Dispatch<React.SetStateAction<Array<KontaktDijagnozaReadDto> | undefined>>;
  kontaktUslugaList: Array<KontaktUslugaReadDto> | undefined;
  setKontaktUslugaList: React.Dispatch<React.SetStateAction<Array<KontaktUslugaReadDto> | undefined>>;
  planiranaUslugaList: ZakazanTerminUslugaPlannedReadDto[];
  setPlaniranaUslugaList: React.Dispatch<React.SetStateAction<ZakazanTerminUslugaPlannedReadDto[]>>;
  kontaktUtrosakList: Array<KontaktUtrosakReadDto> | undefined;
  setKontaktUtrosakList: React.Dispatch<React.SetStateAction<Array<KontaktUtrosakReadDto> | undefined>>;
  kreiraniUnosList: Array<FormularPopunjenReadDto> | undefined;
  setKreiraniUnosList: React.Dispatch<React.SetStateAction<Array<FormularPopunjenReadDto> | undefined>>;
  receptList: Array<ReceptReadDto> | undefined;
  setReceptList: React.Dispatch<React.SetStateAction<Array<ReceptReadDto> | undefined>>;
  kontaktDijagnozaPrethodnaList: Array<KontaktDijagnozaReadDto> | undefined;
  setKontaktDijagnozaPrethodnaList: React.Dispatch<React.SetStateAction<Array<KontaktDijagnozaReadDto> | undefined>>;
  sifarnikUslugaList: Array<SifarnikUslugaDto> | undefined;
  setSifarnikUslugaList: React.Dispatch<React.SetStateAction<Array<SifarnikUslugaDto> | undefined>>;
  uslugaTipList: Array<EnumBaseReadDto> | undefined;
  setUslugaTipList: React.Dispatch<React.SetStateAction<Array<EnumBaseReadDto> | undefined>>;
  dijagnozaStatusList: Array<EnumBaseReadDto> | undefined;
  setDijagnozaStatusList: React.Dispatch<React.SetStateAction<Array<EnumBaseReadDto> | undefined>>;
  medicinskiPodaci: Array<PacijentPoljePopunjeno>;
  receptListLoading: boolean;
  setReceptListLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface useParamsType {
  id?: string;
}

export default function KontaktLogical(): KontaktLogicalType {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const id: number = Number(useParams<keyof useParamsType>()["id"]);
  const location = useLocation();
  const firstRowIndex = location?.state?.firstRowIndex;
  const secondRowIndex = location?.state?.secondRowIndex;
  const [nesacuvanNalazState, setNesacuvanNalazState] = useState<NesacuvanNalaz>();
  const [isFirstRenderNalaz, setIsFirstRenderNalaz] = useState(false);
  const [visibleLeaveMainTabviewDialog, setVisibleMainTabviewDialog] = useState(false);
  const [visibleLeaveInnerTabviewDialog, setVisibleInnerTabviewDialog] = useState(false);

  const { axiosGetKontaktByID, axiosDeleteKontakt } = KontaktController();
  const { axiosSearchFormularByKontaktTip } = FormularController();
  const { axiosGetPacijentPoljePopunjeno } = PacijentPoljeController();

  const [kontakt, setKontakt] = useState<KontaktReadDto>();
  const [formularList, setFormularList] = useState<Array<FormularReadDto> | undefined>();
  const [activeIndex, setActiveIndex] = useState(firstRowIndex || KONTAKT_INDEX.DOKUMENTACIJA);
  const [tmpIndex, setTmpIndex] = useState(0);
  const [dokumentacijaActiveIndex, setDokumentacijaActiveIndex] = useState(secondRowIndex || KONTAKT_DOKUMENTACIJA_INDEX.UNOSI);
  const [clickOutsideDisabled, setClickOutsideDisabled] = useState(false);
  const [medicinskiPodaci, setMedicinskiPodaci] = useState<Array<PacijentPoljePopunjeno>>([]);

  const poljeVrednostListRef = useRef<any>(null);
  const izabraniTipUnosaRef = useRef<any>(null);
  const izabraniPopunjeniUnosRef = useRef<any>(null);
  const navigate = useNavigate();

  const [kreiraniUnosList, setKreiraniUnosList] = useState<Array<FormularPopunjenReadDto> | undefined>();
  const [kontaktPaketList, setKontaktPaketList] = useState<Array<KontaktPaketReadDto> | undefined>();
  const [kontaktDijagnozaList, setKontaktDijagnozaList] = useState<Array<KontaktDijagnozaReadDto> | undefined>();
  const [kontaktDijagnozaPrethodnaList, setKontaktDijagnozaPrethodnaList] = useState<Array<KontaktDijagnozaReadDto> | undefined>();
  const [receptList, setReceptList] = useState<Array<ReceptReadDto> | undefined>();
  const [kontaktUslugaList, setKontaktUslugaList] = useState<Array<KontaktUslugaReadDto> | undefined>();
  const [planiranaUslugaList, setPlaniranaUslugaList] = useState<Array<ZakazanTerminUslugaPlannedReadDto>>([]);
  const [kontaktUtrosakList, setKontaktUtrosakList] = useState<Array<KontaktUtrosakReadDto> | undefined>();
  const [sifarnikUslugaList, setSifarnikUslugaList] = useState<Array<SifarnikUslugaDto>>();
  const [uslugaTipList, setUslugaTipList] = useState<Array<EnumBaseReadDto>>();
  const [dijagnozaStatusList, setDijagnozaStatusList] = useState<Array<EnumBaseReadDto>>();
  const [receptListLoading, setReceptListLoading] = useState<boolean>(false);

  const postLogHighLevel = useLogHighLevel();

  const fetchData = () => {
    axiosGetKontaktByID(id)
      .then(async (response: any) => {
        const tempKontakt: KontaktReadDto = response.data.data;
        setKontakt(tempKontakt);
        if (!formularList) {
          await getFormularList(tempKontakt?.kontaktTip?.id);
        }
        axiosGetPacijentPoljePopunjeno(EnumPacijentPoljeKategorija.OPSTI_MEDICINSKI_PODACI, tempKontakt.pacijent.id)
          .then((res: any) => setMedicinskiPodaci(res.data.data))
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
          });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  useEffect(() => {
    fetchData();
    setIsFirstRenderNalaz(location?.state?.isFirstRenderNalaz);
    let nesacuvanNalaz = localStorage.getItem(NESACUVAN_NALAZ);
    if (nesacuvanNalaz !== null) {
      setNesacuvanNalazState(JSON.parse(nesacuvanNalaz));
    }
    if (location?.state?.isFirstRenderNalaz) {
      const state = { ...location.state };
      delete state.isFirstRenderNalaz;
      navigate("", { ...location, state, replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const deleteKontakt = () => {
    setClickOutsideDisabled(false);
    setShowBlockUI(true);
    axiosDeleteKontakt(id)
      .then((response: any) => {
        navigate(`/pacijent-dosije/${kontakt?.pacijent.id}`);
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_1 + kontakt?.kontaktTip.naziv + ".", kontakt?.pacijent.id);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const getFormularList = async (tipKontaktaId: number) => {
    setShowBlockUI(true);
    axiosSearchFormularByKontaktTip(tipKontaktaId)
      .then((res: any) => {
        setFormularList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const closeLeaveMainTabviewDialog = () => {
    setVisibleMainTabviewDialog(false);
  };

  const openLeaveMainTabviewDialog = (e: TabViewTabChangeEvent) => {
    if (clickOutsideDisabled) {
      setTmpIndex(e.index);
      setVisibleMainTabviewDialog(true);
    } else setActiveIndex(e.index);
  };

  const confirmLeaveMainTabview = () => {
    setActiveIndex(tmpIndex);
    setVisibleMainTabviewDialog(false);
    setClickOutsideDisabled(false);
  };

  const closeLeaveInnerDialog = () => {
    setVisibleInnerTabviewDialog(false);
  };

  const openLeaveInnerDialog = (e: TabViewTabChangeEvent) => {
    if (clickOutsideDisabled) {
      setTmpIndex(e.index);
      setVisibleInnerTabviewDialog(true);
    } else setDokumentacijaActiveIndex(e.index);
  };

  const confirmLeaveInner = () => {
    setDokumentacijaActiveIndex(tmpIndex);
    setVisibleInnerTabviewDialog(false);
    setClickOutsideDisabled(false);
  };

  return {
    kontakt,
    formularList,
    activeIndex,
    dokumentacijaActiveIndex,
    deleteKontakt,
    clickOutsideDisabled,
    setClickOutsideDisabled,
    visibleLeaveMainTabviewDialog,
    closeLeaveMainTabviewDialog,
    openLeaveMainTabviewDialog,
    confirmLeaveMainTabview,
    visibleLeaveInnerTabviewDialog,
    openLeaveInnerDialog,
    closeLeaveInnerDialog,
    confirmLeaveInner,
    poljeVrednostListRef,
    nesacuvanNalazState,
    izabraniTipUnosaRef,
    izabraniPopunjeniUnosRef,
    isFirstRenderNalaz,
    setIsFirstRenderNalaz,
    setNesacuvanNalazState,
    kontaktPaketList,
    setKontaktPaketList,
    kontaktDijagnozaList,
    setKontaktDijagnozaList,
    kontaktUslugaList,
    setKontaktUslugaList,
    planiranaUslugaList,
    setPlaniranaUslugaList,
    kontaktUtrosakList,
    setKontaktUtrosakList,
    kreiraniUnosList,
    setKreiraniUnosList,
    receptList,
    setReceptList,
    kontaktDijagnozaPrethodnaList,
    setKontaktDijagnozaPrethodnaList,
    setSifarnikUslugaList,
    setUslugaTipList,
    sifarnikUslugaList,
    uslugaTipList,
    dijagnozaStatusList,
    setDijagnozaStatusList,
    medicinskiPodaci,
    receptListLoading,
    setReceptListLoading,
  };
}
