import { useLocation } from "react-router";
import { useLabels } from "../../../Store";

interface AppBreadcrumbLogicalType {
  getName: (routers: any) => string;
}

export default function AppBreadcrumbLogical(): AppBreadcrumbLogicalType {
  const location = useLocation();

  const Labels = useLabels();

  const getName = (routers: any) => {
    const pathname = location.pathname;

    const name = pathname.replace("/", "");
    if (routers) {
      if (name === "") {
        return Labels.APPLICATION_NAME;
      } else {
        const currentRouter = routers.find((router: any) => pathname.includes(router.path));
        return currentRouter ? currentRouter.name : name;
      }
    }

    return name;
  };

  return { getName };
}
