import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { setInvalid, tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import actionsAddObrazac from "../../../../infrastructure/system/hooks/dialog-add-obrazac-delovodne-knjige/actionsAddObrazac";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";
import CrudProtokolLogical from "./CrudProtokolLogical";
import DialogRepositoryObrasci from "./dialog/DialogRepositoryObrasci";

export default function CrudProtokolPage() {
  const {
    breadCrumbItems,
    protokolLoading,
    protokolChange,
    setProtokolChange,
    isDisabled,
    vremenskiOkvirListLoading,
    vremenskiOkvirList,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    protokolOperation,
    invalidFields,
    setInvalidFields,
    state,
    dispatch,
    fetchRepositoryObrasci,
    jasperUploadHandler,
    getSelectObrazacFromRepo,
  } = CrudProtokolLogical();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const jasperUploadRef = useRef<any>(null);
  const cancelOptions = { className: "class-visibility-hidden" };

  const leftContentsToolbar = () => (
    <>
      {protokolOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          disabled={!pristup || vremenskiOkvirListLoading}
          tooltip={Labels.PROTOKOL_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {protokolOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || vremenskiOkvirListLoading || protokolLoading}
          icon="pi pi-pencil"
          tooltip={Labels.PROTOKOL_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {protokolOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || vremenskiOkvirListLoading || protokolLoading}
          icon="pi pi-trash"
          tooltip={Labels.PROTOKOL_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} tooltip={Labels.BUTTON_CANCEL} tooltipOptions={tooltipOptionsBottom} />
  );

  return (
    <Panel>
      <BreadCrumb model={breadCrumbItems} />
      <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
        <TabPanel header={Labels.PROTOKOL}>
          <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
            <div className="grid align-items-center p-0">
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>
                  {Labels.PROTOKOL_PREFIKS}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                {!protokolLoading ? (
                  <>
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={protokolChange?.prefiks ?? ""}
                      onChange={(e) => {
                        setProtokolChange({
                          ...protokolChange!,
                          prefiks: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        setInvalid(setInvalidFields, "prefiks", protokolChange?.prefiks);
                      }}
                      className={invalidFields?.prefiks && "p-invalid"}
                    />
                    {invalidFields?.prefiks && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                  </>
                ) : (
                  <SkeletonInputItem />
                )}
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>
                  {Labels.PROTOKOL_NAZIV}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                {!protokolLoading ? (
                  <>
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={protokolChange?.naziv ?? ""}
                      onChange={(e) => {
                        setProtokolChange({
                          ...protokolChange!,
                          naziv: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        setInvalid(setInvalidFields, "naziv", protokolChange?.naziv);
                      }}
                      className={invalidFields?.naziv && "p-invalid"}
                    />
                    {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                  </>
                ) : (
                  <SkeletonInputItem />
                )}
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>
                  {Labels.PROTOKOL_VREMENSKI_OKVIR}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                {!protokolLoading || !vremenskiOkvirListLoading ? (
                  <>
                    <Dropdown
                      disabled={!pristup || isDisabled}
                      value={protokolChange?.vremenskiOkvirEnum ?? null}
                      options={vremenskiOkvirList}
                      optionLabel="naziv"
                      onChange={(e) => {
                        setProtokolChange({ ...protokolChange!, vremenskiOkvirEnum: e.target.value });
                        setInvalidFields((prev) => ({ ...prev, vremenskiOkvir: false }));
                      }}
                      className={"p-column-filter " + (invalidFields?.vremenskiOkvir && "p-invalid")}
                    />
                    {invalidFields?.vremenskiOkvir && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                  </>
                ) : (
                  <SkeletonInputItem />
                )}
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>
                  {Labels.PROTOKOL_POCETNI_BROJ}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                {!protokolLoading ? (
                  <>
                    <InputNumber
                      disabled={!pristup || isDisabled}
                      value={protokolChange?.pocetniBroj ?? undefined}
                      onChange={(e) => {
                        setProtokolChange({ ...protokolChange!, pocetniBroj: e.value! });
                      }}
                      min={1}
                      onBlur={() => {
                        setInvalid(setInvalidFields, "pocetniBroj", protokolChange?.pocetniBroj);
                      }}
                      className={invalidFields?.pocetniBroj && "p-invalid"}
                    />
                    {invalidFields?.pocetniBroj && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                  </>
                ) : (
                  <SkeletonInputItem />
                )}
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>
                  {Labels.PROTOKOL_NAREDNI_BROJ}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                {!protokolLoading ? (
                  <>
                    <InputNumber
                      disabled={!pristup || isDisabled}
                      value={protokolChange?.naredniBroj ?? undefined}
                      onChange={(e) => {
                        setProtokolChange({ ...protokolChange!, naredniBroj: e.value! });
                      }}
                      min={1}
                      onBlur={() => {
                        setInvalid(setInvalidFields, "naredniBroj", protokolChange?.naredniBroj);
                      }}
                      className={invalidFields?.naredniBroj && "p-invalid"}
                    />
                    {invalidFields?.naredniBroj && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                  </>
                ) : (
                  <SkeletonInputItem />
                )}
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>
                  {Labels.PROTOKOL_OBRAZAC_STAMPE}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                {!protokolLoading ? (
                  <>
                    <div className={`flex flex-row justify-content-start align-items-center ${invalidFields?.jasperFajl ? "mt-2" : "my-3"}`}>
                      {protokolChange?.jasperFajl && (
                        <Chip
                          label={protokolChange?.jasperFajlNaziv}
                          key={protokolChange?.jasperFajlNaziv}
                          className="mr-2 mb-2 obrazac-chip"
                          removable={pristup && !isDisabled}
                          onRemove={() => {
                            setProtokolChange({
                              ...protokolChange!,
                              jasperFajl: "",
                              jasperFajlNaziv: "",
                              jasperFajlOpis: "",
                            });
                            jasperUploadRef.current?.clear();
                          }}
                          removeIcon="pi pi-times"
                        />
                      )}
                      {!protokolChange?.jasperFajl && (
                        <>
                          <Button
                            icon="pi pi-plus"
                            label={Labels.PROTOKOL_ADD_FROM_REPOSITORY}
                            disabled={!pristup || isDisabled}
                            className="mr-2 w-auto"
                            onClick={() => {
                              dispatch({ type: actionsAddObrazac.OPEN_DIALOG });
                              fetchRepositoryObrasci();
                            }}
                          />
                          <FileUpload
                            ref={jasperUploadRef}
                            auto
                            mode="basic"
                            accept=".jrxml"
                            disabled={!pristup || isDisabled}
                            cancelOptions={cancelOptions}
                            multiple={false}
                            customUpload
                            uploadHandler={(e) => {
                              jasperUploadHandler(e);
                              setInvalidFields((prev) => ({ ...prev, jasperFajl: false }));
                            }}
                            chooseLabel={Labels.PROTOKOL_ADD_FROM_LOCAL}
                          />
                        </>
                      )}
                    </div>
                    {invalidFields?.jasperFajl && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                  </>
                ) : (
                  <SkeletonInputItem />
                )}
              </div>
              <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                <div>{Labels.PROTOKOL_OBRAZAC_STAMPE_OPIS}</div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0">
                {!protokolLoading ? (
                  <InputTextarea
                    disabled={!pristup || isDisabled || !protokolChange?.jasperFajl}
                    value={protokolChange?.jasperFajlOpis ?? ""}
                    onChange={(e) =>
                      setProtokolChange({
                        ...protokolChange!,
                        jasperFajlOpis: e.target.value,
                      })
                    }
                    rows={3}
                    cols={5}
                  />
                ) : (
                  <SkeletonInputItem />
                )}
              </div>
            </div>
          </div>
          <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
          <DialogRepositoryObrasci state={state} dispatch={dispatch} getSelectObrazacFromRepo={getSelectObrazacFromRepo} />
        </TabPanel>
      </TabView>
    </Panel>
  );
}
