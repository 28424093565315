import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import PacijentPoljeKategorijaCreateDto from "../../model/pacijent-polje/PacijentPoljeKategorijaCreateDto";
import PacijentPoljeKategorijaReadDto from "../../model/pacijent-polje/PacijentPoljeKategorijaReadDto";
import PacijentPoljeReadDto from "../../model/pacijent-polje/PacijentPoljeReadDto";
import CommonResponseDto from "../../model/CommonResponseDto";

interface PacijentPoljeControllerType {
  axiosReadPacijentPolje: (id: number) => Promise<AxiosResponse<any>>;
  axiosSearchPacijentPolje: (searchObject: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosUpdatePacijentPolje: (pacijentPolje: PacijentPoljeReadDto) => Promise<AxiosResponse<any>>;
  axiosCreatePacijentPolje: (pacijentPolje: PacijentPoljeReadDto) => Promise<AxiosResponse<any>>;
  axiosDeletePacijentPolje: (pacijentPoljeId: number) => Promise<AxiosResponse<any>>;
  axiosGetPacijenPoljeByKategorija: (kategorija: string) => Promise<AxiosResponse<any>>;
  axiosGetPacijentPoljePopunjeno: (kategorija: string, pacijentId: number) => Promise<AxiosResponse<any>>;
  axiosGetPacijentPoljeKategorijaListByKategorija: (sifraKategorije: string) => Promise<AxiosResponse<CommonResponseDto<PacijentPoljeKategorijaReadDto[]>>>;
  axiosGetPacijentPoljePopunjenoArhivaList: (pacijentPoljeId: number, pacijentId: number) => Promise<AxiosResponse<any>>;
  axiosGetPacijentPoljeKategorijaByPacijentPolje: (pacijentPoljeId: number) => Promise<AxiosResponse<any>>;
  axiosCreatePacijentPoljeKategorija: (pacijentPoljeKategorija: PacijentPoljeKategorijaCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeletePacijentPoljeKategorija: (pacijentPoljeKategorijaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdatePacijentPoljeKategorija: (pacijentPoljeKategorija: PacijentPoljeKategorijaReadDto) => Promise<AxiosResponse<any>>;
  axiosReadPacijentPoljeKategorija: (pacijentPoljeKategorijaId: number) => Promise<AxiosResponse<any>>;
}

export function PacijentPoljeController(): PacijentPoljeControllerType {
  const { authData } = useContext(AppContext);

  const axiosReadPacijentPolje = (id: number) => {
    return axios.get(`${Endpoint.PACIJENT_POLJE_LIST}/${id}`, axiosConfig(authData!.token));
  };

  const axiosSearchPacijentPolje = (searchObject: SearchBaseDto) => {
    return axios.get(Endpoint.PACIJENT_POLJE_SEARCH, axiosConfig(authData!.token, searchObject));
  };

  const axiosCreatePacijentPolje = (pacijentPolje: PacijentPoljeReadDto) => {
    return axios.post(Endpoint.PACIJENT_POLJE_LIST, pacijentPolje ? pacijentPolje : {}, axiosConfig(authData!.token));
  };

  const axiosUpdatePacijentPolje = (pacijentPolje: PacijentPoljeReadDto) => {
    return axios.put(`${Endpoint.PACIJENT_POLJE_LIST}/${pacijentPolje?.id}`, pacijentPolje, axiosConfig(authData!.token));
  };

  const axiosDeletePacijentPolje = (pacijentPoljeId: number) => {
    return axios.delete(`${Endpoint.PACIJENT_POLJE_LIST}/${pacijentPoljeId}`, axiosConfig(authData!.token));
  };

  const axiosGetPacijenPoljeByKategorija = (kategorija: string) => {
    return axios.get(`${Endpoint.PACIJENT_POLJE_PACIJENT_POLJE_KATEGORIJA_LIST}/${kategorija}`, axiosConfig(authData!.token));
  };

  const axiosGetPacijentPoljeKategorijaListByKategorija = (sifraKategorije: string) => {
    return axios.get(`${Endpoint.PACIJENT_POLJE_KATEGORIJA}/${sifraKategorije}`, axiosConfig(authData!.token));
  };

  const axiosGetPacijentPoljeKategorijaByPacijentPolje = (pacijentPoljeId: number) => {
    return axios.get(`${Endpoint.PACIJENT_POLJE_LIST}/${pacijentPoljeId}/pacijent-polje-kategorija-list`, axiosConfig(authData!.token));
  };
  const axiosCreatePacijentPoljeKategorija = (pacijentPoljeKategorija: PacijentPoljeKategorijaCreateDto) => {
    return axios.post(Endpoint.PACIJENT_POLJE_KATEGORIJA_LIST, pacijentPoljeKategorija ? pacijentPoljeKategorija : {}, axiosConfig(authData!.token));
  };

  const axiosReadPacijentPoljeKategorija = (pacijentPoljeKategorijaId: number) => {
    return axios.get(`${Endpoint.PACIJENT_POLJE_KATEGORIJA_LIST}/${pacijentPoljeKategorijaId}`, axiosConfig(authData!.token));
  };

  const axiosDeletePacijentPoljeKategorija = (pacijentPoljeKategorijaId: number) => {
    return axios.delete(`${Endpoint.PACIJENT_POLJE_KATEGORIJA_LIST}/${pacijentPoljeKategorijaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdatePacijentPoljeKategorija = (pacijentPoljeKategorija: PacijentPoljeKategorijaReadDto) => {
    return axios.put(`${Endpoint.PACIJENT_POLJE_KATEGORIJA_LIST}/${pacijentPoljeKategorija?.id}`, pacijentPoljeKategorija, axiosConfig(authData!.token));
  };

  const axiosGetPacijentPoljePopunjeno = (kategorija: string, pacijentId: number) => {
    return axios.get(`${Endpoint.PACIJENT_POLJE_LIST}/${kategorija}/pacijent-polje-popunjeno-list/${pacijentId}`, axiosConfig(authData!.token));
  };
  const axiosGetPacijentPoljePopunjenoArhivaList = (pacijentPoljeId: number, pacijentId: number) => {
    return axios.get(`${Endpoint.ARCHIVE_PACIJENT_POLJE_POPUNJENO_SEARCH}`, axiosConfig(authData!.token, { pacijentPoljeId: pacijentPoljeId, pacijentId: pacijentId }));
  };

  return {
    axiosReadPacijentPolje,
    axiosSearchPacijentPolje,
    axiosUpdatePacijentPolje,
    axiosCreatePacijentPolje,
    axiosDeletePacijentPolje,
    axiosGetPacijenPoljeByKategorija,
    axiosGetPacijentPoljePopunjeno,
    axiosGetPacijentPoljeKategorijaListByKategorija,
    axiosGetPacijentPoljePopunjenoArhivaList,
    axiosGetPacijentPoljeKategorijaByPacijentPolje,
    axiosCreatePacijentPoljeKategorija,
    axiosDeletePacijentPoljeKategorija,
    axiosUpdatePacijentPoljeKategorija,
    axiosReadPacijentPoljeKategorija,
  };
}
