const Labels = {
  SR_RS_LATN: "Latinica",
  SR_RS: "Ћирилица",
  NEW_LINE: "\n",
  TAB: "\t",
  SPACE: " ",
  DASH: " - ",
  SLASH: "/",
  TWO_DOT: ": ",
  DOT_SIGN: "•",

  // Special strings
  SPECIAL_CHAR_REQUIRED: " *",
  SPECIAL_CHAR_COLON: ":",

  // Application
  APPLICATION_NAME: "Heliant Cloud",

  // Common
  COPYRIGHT: "Developed by   ",
  HELIANT: " HELIANT",
  LOGIN: "Prijava",
  FORGOTTEN_PASSWORD: "Zaboravljena lozinka?",
  TOKEN_EXPIRED_LABEL: "Token je istekao",

  // Title labels
  TITLE_LOGIN: "Unesite Vaše pristupne parametre",
  TITLE_RESET_PASSWORD: "Resetovanje lozinke",
  TITLE_SET_NEW_PASSWORD: "Postavljanje nove lozinke",

  // Menu labels
  MENU_ADMIN: "Administracija",
  MENU_SUPERADMIN: "Super administracija",
  MENU_PATIENT_SEARCH: "Pretraga pacijenata",
  MENU_IZVESTAJI: "Izveštaj",

  // Button labels
  BUTTON_CREATE: "Kreiraj",
  BUTTON_CHANGE: "Promeni",
  BUTTON_UPDATE: "Izmeni",
  BUTTON_DETAILS: "Detalji",
  BUTTON_LOGOUT: "Kraj rada",
  BUTTON_SAVE: "Sačuvaj",
  BUTTON_CONFIRM: "Potvrdi",
  BUTTON_CANCEL: "Odustani",
  BUTTON_DELETE: "Obriši",
  BUTTON_DELETE_DATA: "Obriši podatke",
  BUTTON_UPLOAD_IMAGE: "Učitaj sliku",
  BUTTON_RESET_PASSWORD: "Resetuj lozinku",
  BUTTON_ADD_ORGANIZACIONA_JEDINICA: "Dodaj organizacionu jedinicu",
  BUTTON_ADD_USTANOVA: "Dodaj ustanovu",
  BUTTON_ADD_RADNIK: "Dodaj radnika",
  BUTTON_ADD_RADNIK_OTHER: "Dodaj radnika iz druge ustanove",
  BUTTON_ADD_TIP_KONTAKTA: "Dodaj tip pregleda",
  BUTTON_ADD_ULOGA: "Dodaj ulogu",
  BUTTON_ADD_FORMULAR: "Dodaj formular",
  BUTTON_ADD_PACIJENT_POLJE: "Dodaj pacijent polje",
  BUTTON_ADD_PACIJENT_POLJE_KATEGORIJA: "Dodaj kategoriju za pacijent polje",
  BUTTON_YES: "Da",
  BUTTON_NO: "Ne",
  BUTTON_CLOSE: "Zatvori",
  BUTTON_REPOSITORY: "Repozitorijum",
  BUTTON_OVERWRITE: "Prepiši",
  BUTTON_LOAD_MORE: "Učitaj još",
  BUTTON_ADD_OBAVESTENJE: "Dodaj obaveštenje",
  BUTTON_ADD_IZVESTAJ_PARAMETAR: "Dodaj parametar za izveštaj ",
  BUTTON_ADD_USTANOVA_DODATNI_MODUL: "Dodaj modul",
  BUTTON_ADD_PACIJENT: "Dodaj pacijenta",
  BUTTON_LOG_OUT: "Izloguj se",

  DIJAGNOZA: "Dijagnoza",
  DIJAGNOZA_LIST_TOKOM_KONTKAKTA: "Postavljene dijagnoze tokom pregleda",
  DIJAGNOZA_LIST_IZ_PRETHODNIH_PREGLEDA: "Dijagnoze iz prethodnih pregleda",
  DIJAGNOZA_POSTAVLJENA: "Postavljena dijagnoza:",
  DIJAGNOZA_STATUS: "Status dijagnoze:",
  DIJAGNOZA_NAPOMENA: "Napomena:",
  DIJAGNOZA_POSTAVIO: "Postavio/la:",
  DIJAGNOZA_POSTAVIO_MUSKI: "Postavio:",
  DIJAGNOZA_POSTAVIO_ZENSKI: "Postavila:",
  DIJAGNOZA_NOVA: "Nova dijagnoza",
  DIJAGNOZA_IZMENI: "Izmeni dijagnozu",
  DIJAGNOZA_OBRISI: "Obriši dijagnozu",
  DIJAGNOZA_PREPISI: "Prepiši dijagnozu",
  DIJAGNOZA_UNOS: "Unos dijagnoze",
  DIJAGNOZA_UNOS_JE_OBAVEZAN: "Unos dijagnoze je obavezan podatak.",
  DIJAGNOZA_STATUS_JE_OBAVEZAN: "Status dijagnoze je obavezan podatak.",
  DIJAGNOZA_USPESNO_IZMENJENA: "Dijagnoza uspešno izmenjena.",
  DIJAGNOZA_USPESNO_OBRISANA: "Dijagnoza uspešno obrisana.",
  DIJAGNOZA_USPESNO_SACUVANA: "Dijagnoza uspešno sačuvana.",
  DIJAGNOZA_USPESNO_PREPISANA: "Dijagnoza uspešno prepisana.",
  DIJAGNOZA_IZMENA: "Izmena dijagnoze",
  DIJAGNOZA_BRISANJE: "Brisanje dijagnoze",
  DIJAGNOZA_DODAVANJE: "Dodavanje nove dijagnoze",
  DIJAGNOZA_PREGLED: "Pregled dijagnoze",
  DIJAGNOZA_UNOS_PLACEHOLDER: "Unesite naziv ili šifru dijagnoze",

  SEARCH_FORMULAR: "Pretraga formulara ",
  FORMULAR_LIST: "Formulari",
  FORMULAR_TITLE_DIALOG_CREATE: "Kreiranje formulara ",
  FORMULAR_TITLE_DIALOG_UPDATE: "Izmena formulara ",
  FORMULAR_TITLE_DIALOG_DELETE: "Brisanje formulara ",
  FORMULAR_TITLE_DIALOG_DETAILS: "Detalji o formularu ",
  FORMULAR_NAZIV: "Naziv ",
  FORMULAR_SIFRA: "Šifra",
  FORMULAR_NAZIV_IS_REQUIRED: "Naziv je obavezno polje ",
  FORMULAR_SIFRA_IS_REQUIRED: "Šifra je obavezno polje",
  FORMULAR_TIP_PREGLEDA_IS_REQUIRED: "Tip pregleda je obavezno polje",
  FORMULAR_TITLE_MESSAGE_CREATE_FORMULAR_SUCCESS: "Formular je uspešno kreiran ",
  FORMULAR_TITLE_MESSAGE_UPDATE_FORMULAR_SUCCESS: "Formular je uspešno izmenjen ",
  FORMULAR_TITLE_MESSAGE_DELETE_FORMULAR_SUCCESS: "Formular je uspešno izbrisan ",
  FORMULAR_SEARCH: "Pretraga",
  FORMULAR_SUCCESS_SAVE: "Uspešno sačuvan formular",
  FORMULAR_SUCCESS_UPDATE: "Uspešno izmenjen formular",
  FORMULAR_HTML_PREDLOZAK: "HTML predložak ",
  FORMULAR_JR_DOCUMENT: "Jasper dokument ",
  FORMULAR_CHOOSE_JASPER: "Izaberite jasper ",
  FORMULAR_REMOVE_JASPER: "Izbacite jasper ",
  FORMULAR_DELETE_PRINT: "Brisanje dokumenta",
  FORMULAR_POPUNJEN_DELETE: "Brisanje popunjenog formulara",
  FORMULAR_DA_LI_STE_SIGURNI: "Da li ste sigurni da želite da izađete iz pisanja formulara?",
  FORMULAR_ENTITY_STATUS_IS_REQUIRED: "Obavezno je uneti status pregleda za formular.",
  FORMULAR_POPUP: "Da biste odštampali nalaz, potrebno je da dozvolite popup na pretraživaču.",
  IMPORT_FORMULAR_REPOSITORY: "Preuzmi formulare sa repozitorijuma",
  FORMULAR_REPOSITORY: "Repozitorijum formulara",

  // Placeholder labels
  PLACEHOLDER_CHOOSE_ORG_JED: "Izaberite organizacionu jedinicu",
  PLACEHOLDER_DIJAGNOZA: "Dijagnoza",
  PLACEHOLDER_DATE_KONTAKT_FROM: "Datum pregleda od",
  PLACEHOLDER_DATE_KONTAKT_TO: "Datum pregleda do",
  PLACEHOLDER_DATE_VREME_SMRTI: "Vreme smrti",
  PLACEHOLDER_DATUM_FORMAT: "DD.MM.GGGG.",
  PLACEHOLDER_DATUM_VREME_FORMAT: "DD.MM.GGGG. ČČ:MM",
  PLACEHOLDER_VREME_FORMAT: "ČČ:MM",

  LABEL_OD: "od",
  LABEL_DO: "do",
  LABEL_ADDITIONAL_SEARCH: "Dodatna pretraga",
  LABEL_DESCRIPTION: "Opis",
  LABEL_NOTE: "Napomena",
  LABEL_EMAIL: "Imejl",
  LABEL_SMS: "SMS",
  LABEL_IME: "Ime",
  LABEL_PREZIME: "Prezime",
  LABEL_DELETE: "Obriši",
  LABEL_PODESAVANJA: "Podešavanja",
  LABEL_BEFORE: "pre",
  LABEL_MINUTES: "minuta",
  LABEL_HOURS: "časova",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "Da li ste sigurni da želite da završite sa radom?",
  LABEL_SIFARNIK_LIST: "Šifarnici",
  LABEL_SIFRA: "Šifra",
  LABEL_NAZIV: "Naziv",
  LABEL_UPDATE_FORM: "Izmena formulara ",
  LABEL_DELETE_FORM: "Brisanje fromulara ",
  LABEL_DETAILS_FORM: "Detalji formulara ",
  LABEL_UPDATE_FIELD_TYPE: "Izmena tipa polja ",
  LABEL_DELETE_FIELD_TYPE: "Brisanje tipa polja ",
  LABEL_DETAILS_FIELD_TYPE: "Detalji tipa polja ",
  LABEL_UPDATE_FIELD_TYPE_ENUM_STAVKA: "Izmena tipa polja enum stavke ",
  LABEL_CREATE_FIELD_TYPE_ENUM_STAVKA: "Kreiranje tipa polja enum stavke ",
  LABEL_DELETE_FIELD_TYPE_ENUM_STAVKA: "Brisanje tipa polja enum stavke ",
  LABEL_DETAILS_FIELD_TYPE_ENUM_STAVKA: "Detalji tipa polja enum stavke ",
  TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS: "Tip polja je uspešno kreiran.",
  TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS: "Tip polja je uspešno izmenjen.",
  TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS: "Tip polja je uspešno obrisan.",
  TITLE_MESSAGE_CREATE_POLJE_SUCCESS: "Polje je uspešno kreirano.",
  TITLE_MESSAGE_UPDATE_POLJE_SUCCESS: "Polje je uspešno izmenjeno.",
  TITLE_MESSAGE_DELETE_POLJE_SUCCESS: "Polje je uspešno obrisano.",
  TITLE_MESSAGE_DELETE_FORMULAR_POPUNJEN_SUCCESS: "Popunjen formular je uspešno obrisan.",
  TITLE_MESSAGE_DELETE_DOKUMENT_SUCCESS: "Dokument je uspešno obrisan.",
  TITLE_MESSAGE_PATCH_PROFILNA_SLIKA: "Uspešno ste promenili profilnu sliku.",
  TITLE_CHANGE_PROFILE_IMAGE: "Promenite profilnu sliku",
  TITLE_RADNIK_ADD_OTHER: "Dodaj radnika iz druge ustanove",
  LABEL_CREATE_FIELD_TYPE: "Kreiranje tipa polja",
  LABEL_MIN: "Minimum",
  LABEL_MAX: "Maksimum",
  LABEL_MALE: "M",
  LABEL_FEMALE: "Ž",
  LABEL_DECIMALS: "Decimale",
  LABEL_FORMAT: "Format",
  LABEL_SHOW_COLUMNS: "Prikaži kolone",
  LABEL_CREATE: "Kreiraj",
  LABEL_DETAILS: "Detalji",
  LABEL_UNIT: "Jedinica",
  LABEL_OBAVESTENJE: "Obaveštenje",
  LABEL_ORGANIZACIONA_JEDINICA: "Organizaciona jedinica",
  LABEL_ORGANIZACIONA_JEDINICA_RADNIK_LIST: "Radnici organizacione jedinice",
  LABEL_ORGANIZACIONA_JEDINICA_KONTAKT_TIP_LIST: "Tipovi pregleda organizacione jedinice",
  LABEL_RADNIK: "Radnik",
  LABEL_TIP_KONTAKTA: "Tip pregleda",
  LABEL_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA: "Tip pregleda organizaciona jedinica",
  LABEL_TIP_PREGLEDA: "Tip pregleda",
  LABEL_PROMENI_DOKTORA: "Promeni doktora",
  LABEL_ULOGA: "Uloga",
  LABEL_PACIJENT_DOSIJE: "Dosije pacijenta",
  LABEL_PACIJENT_DOSIJE_BROJ_PROTOKOLA: "Broj protokola",
  LABEL_PACIJENT_POLJE: "Pacijent polje",
  LABEL_PACIJENT_POLJE_KATEGORIJA: "Kategorija polja pacijenta",
  LABEL_IME_KONTAKT_OSOBE: "Ime",
  LABEL_PREZIME_KONTAKT_OSOBE: "Prezime",
  LABEL_ODNOS_SA_PACIJENTOM: "Odnos sa pacijentom",
  LABEL_TELEFON_KONTAKT_OSOBE: "Telefon",
  LABEL_POL_KONTAKT_OSOBE: "Pol",
  LABEL_POL_MUSKO: "Muško",
  LABEL_POL_ZENSKO: "Žensko",
  LABEL_RADNIK_ULOGA_LISTA: "Uloge radnika",
  LABEL_ULOGA_RADNIK_LISTA: "Radnici koji imaju ulogu",
  LABEL_ULOGA_APLIKATIVNA_PRAVA: "Aplikativna prava",
  LABEL_ULOGA_IMA_APLIKATIVNA_PRAVA: "Aplikativna prava nad ulogom",
  LABEL_RADNIK_ORGANIZACIONA_JEDINICA_LIST: "Organizacione jedinice radnika",
  LABEL_RADNI_PERIOD: "Radni period",
  LABEL_SIFARNIK: "Šifarnički ",
  LABEL_UPLOADED: "Otpremljeno",
  LABEL_UPLOAD: "Otpremi",
  LABEL_CHOOSE: "Pripremi",
  LABEL_REMOVE_DOCUMENT: "Da li ste sigurni da želite da uklonite datoteku?",
  LABEL_EXPORT: "Preuzmi",
  LABEL_PDF: "PDF",
  LABEL_XLS: "XLS",
  LABEL_CSV: "CSV",
  LABEL_JSON: "JSON",
  LABEL_ENTITY_STATUS: "Status",
  LABEL_FORM: "Formular",
  LABEL_PRIKAZNI_REDOSLED: "Prikazni redosled",
  LABEL_UPDATE_POLJE: "Izmena polja ",
  LABEL_DETAIL_POLJE: "Detalji polja ",
  LABEL_DELETE_POLJE: "Brisanje polja ",
  LABEL_CREATE_POLJE: "Kreiranje polja ",
  LABEL_PATIENT_SEARCH: "Traži",
  LABEL_TOOLTIP_PATIENT_SEARCH: "Pretraga pacijenata",
  LABEL_TOOLTIP_PATIENT_SEARCH_ALL: "Pretraga na osnovu imena i prezimena",
  LABEL_TOOLTIP_PATIENT_SEARCH_ERASE: "Poništi pretragu",
  LABEL_TOOLTIP_CHOOSE_COLUMN: "Izbor kolona za prikaz u tabeli",
  LABEL_TOOLTIP_ADD_PATIENT: "Dodaj pacijenta",
  LABEL_NAPOMENA: "Napomena",
  LABEL_TIP_KOMPONENTE: "Tip komponente",
  LABEL_UCESNICI: "Učesnici",
  LABEL_COLUMN_TRUE: "Da",
  LABEL_COLUMN_FALSE: "Ne",
  TABLE_EMPTY_MESSAGE: "Nema podataka",
  DOWNLOAD_DATA_TABLE: "Preuzmite sadržaj tabele",
  LABEL_PACIJENT_INFORMACIJE: "Informacije o pacijentu",
  TOOLTIP_PACIJENT_INFORMACIJE: "Prikaži informacije o pacijentu",
  TOOLTIP_OPSTI_MEDICINSKI_PODACI: "Prikaži opšte medicinske podatke",
  LABEL_GODINA: "godina",
  LABEL_PERIOD: "Period",
  LABEL_REPOZITORIJUM: "Repozitorijum",
  LABEL_DATATABLE_ROWS_PER_PAGE_DROPWDOWN_ALL: "Sve",
  LABEL_SADRZAJ: "Sadržaj",
  LABEL_VAZI_DO: "Važi do",
  LABEL_DATOTEKE: "Datoteke",
  LABEL_IZVESTAJ: "Izveštaj",
  LABEL_REQUEST_NAME: "Naziv zahteva",
  LABEL_IZVESTAJ_PARAMETAR_NAZIV: "Naziv parametra statističkog izveštaja",
  LABEL_IZVESTAJ_REPOSITORY: "Repozitorijum statističkih izveštaja",

  LABEL_UPDATE_IZVESTAJ_PARAMETAR: "Izmena parametra ",
  LABEL_DELETE_IZVESTAJ_PARAMETAR: "Brisanje parametra ",
  LABEL_DETAILS_IZVESTAJ_PARAMETAR: "Detalji parametra ",
  LABEL_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Izmena stavke ",
  LABEL_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Kreiranje nove stavke ",
  LABEL_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Brisanje stavke ",
  LABEL_DETAILS_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Detalji stavke ",
  TITLE_MESSAGE_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS: "Stavka parametra izveštaja je uspešno kreirana.",
  TITLE_MESSAGE_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS: "Stavka parametra izveštaja je uspešno izmenjena.",
  TITLE_MESSAGE_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS: "Stavka parametra izveštaja je uspešno obrisana.",

  LABEL_REQUIRED_FIELDS: "Polja su obavezna",
  LABEL_REQUIRED_FIELD: "Polje je obavezno",
  MESSAGE_ZABRANJEN_PRISTUP: "Zabranjen pristup",
  LABEL_PRINT: "Štampa",

  //Column header
  HEADER_NAZIV: "Naziv",
  HEADER_SIFRA: "Šifra",
  HEADER_PRIKAZNI_REDOSLED: "Prikazni redosled",
  HEADER_NAPOMENA: "Napomena",
  HEADER_FULLNAME: "Ime i prezime",
  HEADER_NAME: "Ime",
  HEADER_SURNAME: "Prezime",
  HEADER_DOB: "Datum rođenja",
  HEADER_LBO: "LBO",
  HEADER_PHONE: "Kontakt telefon",
  HEADER_GENDER: "Pol",
  HEADER_READ_ONLY: "Samo za čitanje",
  HEADER_REQUIRED: "Obavezno",
  HEADER_CATEGORY: "Kategorija",
  HEADER_VAZI_OD: "Važi od",
  HEADER_VAZI_DO: "Važi do",
  HEADER_BROJ_POLISE: "Broj polise",
  HEADER_PARTNER_PZO: "Partner/PZO",
  HEADER_PROSTOR: "Prostor",
  HEADER_BROJ_NALOGA: "Broj naloga",
  HEADER_INFO: "Info",
  HEADER_PODRAZUMEVANA_KOLICINA: "Podrazumevana količina",
  HEADER_IZNOS: "Iznos",
  HEADER_PORESKA_STOPA: "Poreska stopa",
  HEADER_TIP: "Tip",

  //Messages
  MESSAGES_UPLOAD_IMAGE_ERROR: "Neuspešno učitavanje slike",
  MESSAGES_NO_RESULT_FOUND: "Nema rezultata",
  MESSAGES_SIFRA_REQUIRED: "Šifra je obavezan podatak!",
  MESSAGES_NAZIV_REQUIRED: "Naziv je obavezan podatak!",
  MESSAGES_TIP_POLJA_REQUIRED: "Tip polja je obavezan podatak!",
  MESSAGES_INVALID_FILE_SIZE: "Maksimalna veličina datoteke je 5 MB.",
  MESSAGES_KATEGORIJA_REQUIRED: "Kategorija je obavezan podatak!",
  MESSAGES_OBAVEZNO_REQUIRED: "Obavezno je obavezan podatak!",
  MESSAGES_READONLY_REQUIRED: "Samo za čitanje je obavezan podatak!",
  MESSAGE_SAVE_VREME_SMRTI_SUCCESS: "Uspešno sačuvano vreme smrti.",
  MESSAGE_DELETE_VREME_SMRTI_SUCCESS: "Uspešno obrisano vreme smrti.",
  MESSAGE_ADD_NEW_WARNING_SUCCESS: "Uspešno dodato upozorenje.",
  MESSAGE_DELETE_WARNING_SUCCESS: "Uspešno obrisano upozorenje.",
  MESSAGE_PARAMETAR_TIP_ENUM_REQUIRED: "Tip parametra je obavezan podatak",
  MESSAGES_REQUEST_NAME_REQUIRED: "Naziv zahteva je obavezan podatak",

  // operator
  CONTAIS: "sadrži",
  NOT_CONTAIS: "ne sadrži",
  START_WITH: "počinje sa",
  END_WITH: "završava se",
  CHOOSE: "izabrano",
  NOT_CHOOSE: "nije izabrano",

  //errorPage
  ERROR_PAGE_404: "404",
  ERROR_PAGE_PAGE_NOT_FOUND: "Stranica nije pronađena.",

  ERROR_FORBIDDEN_PAGE: "Nemate pristup ovoj stranici.",

  /* RADNIK */
  SEARCH_RADNIK: "Pretraga radnika",
  RADNIK_LIST: "Radnici",
  RADNIK_IME: "Ime",
  RADNIK_PREZIME: "Prezime",
  RADNIK_USERNAME: "Korisničko ime",
  RADNIK_EMAIL: "Imejl",
  RADNIK_POL: "Pol",
  RADNIK_PASSWORD: "Lozinka",
  RADNIK_SEARCH: "Pretraga",
  RADNIK_TITLE_DIALOG_CREATE: "Kreiranje radnika ",
  RADNIK_TITLE_DIALOG_UPDATE: "Izmena radnika ",
  RADNIK_TITLE_DIALOG_DELETE: "Brisanje radnika ",
  RADNIK_TITLE_DIALOG_DETAILS: "Detalji radnika ",
  RADNIK_TITLE_RESET_PASSWORD: "Započnite proces restarta lozinke",
  RADNIK_TITLE_MESSAGE_DELETE_RADNIK_SUCCESS: "Radnik je uspešno obrisan.",
  RADNIK_TITLE_MESSAGE_CREATE_RADNIK_SUCCESS: "Radnik je uspešno kreiran.",
  RADNIK_TITLE_MESSAGE_UPDATE_RADNIK_SUCCESS: "Radnik je uspešno izmenjen.",
  RADNIK_USERNAME_IS_REQUIRED: "Obavezno je uneti korisničko ime.",
  RADNIK_EMAIL_IS_REQUIRED: "Obavezno je uneti imejl.",
  RADNIK_EMAIL_FORMAT_IS_NOT_VALID: "Imejl format nije validan!",
  RADNIK_POL_IS_REQUIRED: "Obavezno je uneti pol.",
  RADNIK_IME_IS_REQUIRED: "Obavezno je uneti ime",
  RADNIK_PREZIME_IS_REQUIRED: "Obavezno je uneti prezime",
  RADNIK_STATUS_IS_REQUIRED: "Obavezno je uneti status",
  RADNIK_PASSWORD_DEFAULT: "UProcesuKreiranja",
  RADNIK_BROJ_PECATA: "Broj pečata",
  RADNIK_STATUS: "Status",
  RADNIK_TITULA: "Titula",
  RADNIK_ZANIMANJE: "Zanimanje",
  RADNIK_MEJL_ISKORISCEN: "Ovaj mejl je već iskorišćen!",
  RADNIK_DODAT_U_USTANOVU: "Radnik je dodat u ustanovu!",
  RADNIK_JE_IZMENJEN_I_DODAT_U_USTANOVU: "Radnik je izmenjen i dodat u izabranu ustanovu!",
  RADNIK_BIRANJE_PODATAKA: "Izaberite podatke za radnika",
  SAVE_RADNIK_DATA: "Sačuvaj podatke o radniku",
  CANCEL_RADNIK_DATA: "Poništi podatke o radniku",
  RADNIK_WARNING_MAX_BROJ_NALOGA: "Upozorenje! Unet je maksimalan broj radnika.",
  RADNIK_RACUNANJE_NALOGA_ZA_LIMIT: "Računanje naloga za limit",
  RADNIK_USPESNO_DODAT: "Radnik je uspešno dodat na ustanovu",
  RADNIK_KALENDAR_INTEGRACIJA: "Integracija sa kalendarom",
  RADNIK_DOSTUPAN_ZA_EKSTERNE_ZAKAZIVACE: "Dostupan za eksterne zakazivače",

  /*PACIJENT*/
  PACIJENT_IME: "Ime",
  PACIJENT_PREZIME: "Prezime",
  PACIJENT_IME_RODITELJA: "Ime jednog roditelja",
  PACIJENT_POL: "Pol",
  PACIJENT_DATUM_RODJENJA: "Datum rođenja",
  PACIJENT_ADRESA: "Adresa",
  PACIJENT_USPESNO_IZMENJEN: "Pacijent uspešno izmenjen",
  PACIJENT_USPESNO_OBRISAN: "Pacijent uspešno obrisan",
  PACIJENT_USPESNO_KREIRAN: "Pacijent uspešno kreiran",
  PACIJENT_IME_OBAVEZAN_PODATAK: "Ime pacijenta je obavezan podatak",
  PACIJENT_PREZIME_OBAVEZAN_PODATAK: "Prezime pacijenta je obavezan podatak",
  PACIJENT_POL_OBAVEZAN_PODATAK: "Pol pacijenta je obavezan podatak",
  PACIJENT_DATUM_RODJENJA_OBAVEZAN_PODATAK: "Datum rođenja pacijenta je obavezan podatak",
  PACIJENT_BRISANJE: "Brisanje pacijenta",
  BUTTON_PACIJENT_DELETE: "Obriši pacijenta",
  PACIJENT_PRIKAZI: "Prikaži pacijenta",
  PACIJENT_TELEFON: "Broj telefona",
  PACIJENT_EMAIL: "Imejl",
  PACIJENT_OBAVESTENJE_TIP_LIST: "Info o terminima",
  PACIJENT_GRUPNO_OBAVESTENJE_TIP_LIST: "Obaveštenja i promocije",
  PACIJENT_EXISTS_WARNING: "Pacijent sa istim podacima već postoji u našem sistemu. Molimo proverite pre unosa.",
  PACIJENT_EXIST_MALE_GENDER_TEXT: "muškog",
  PACIJENT_EXIST_FEMALE_GENDER_TEXT: "ženskog",
  PACIJENT_EXIST_NOT_SPECIFIED_GENDER_TEXT: "nenaznačenog",
  PACIJENT_EXIST_PATIENT_TEXT: "Pacijent",
  PACIJENT_EXIST_GENDER_TEXT: "pola",
  PACIJENT_EXIST_DATE_TEXT: "datuma rođenja",
  PACIJENT_EXIST_ALREADY_EXIST_TEXT_1: "već postoji u bazi.",
  PACIJENT_EXIST_ALREADY_EXIST_TEXT_2: "Molimo proverite pre dodavanja.",
  PACIJENTI_USPESNO_SPOJENI: "Pacijenti uspešno spojeni.",

  /*Pacijent polje */
  SEARCH_PACIJENT_POLJE: "Pretraga polja pacijenta",
  PACIJENT_POLJE_LIST: "Polja pacijenta",
  PACIJENT_POLJE_NAZIV: "Naziv",
  PACIJENT_POLJE_SIFRA: "Šifra",
  PACIJENT_POLJE_TIP: "Tip",
  PACIJENT_POLJE_VRSTA_GRAFIKONA: "Vrsta grafikona rasta",

  PACIJENT_POLJE_SEARCH: "Pretraga",
  PACIJENT_POLJE_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv",
  PACIJENT_POLJE_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru",
  PACIJENT_POLJE_TIP_IS_REQUIRED: "Obavezno je uneti tip polja",
  PACIJENT_POLJE_TITLE_DIALOG_CREATE: "Kreiranje polja pacijenta ",
  PACIJENT_POLJE_TITLE_DIALOG_UPDATE: "Izmena polja pacijenta ",
  PACIJENT_POLJE_TITLE_DIALOG_DETAILS: "Detalji polja pacijenta ",
  PACIJENT_POLJE_TITLE_DIALOG_DELETE: "Brisanje polja pacijenta ",
  PACIJENT_POLJE_TITLE_MESSAGE_CREATE_PACIJENT_POLJE_SUCCESS: "Polje pacijenta je uspešno kreirano",
  PACIJENT_POLJE_TITLE_MESSAGE_UPDATE_PACIJENT_POLJE_SUCCESS: "Polje pacijenta je uspešno izmenjeno",
  PACIJENT_POLJE_TITLE_MESSAGE_DELETE_PACIJENT_POLJE_SUCCESS: "Polje pacijenta je uspešno obrisano",
  PACIJENT_POLJE_OBAVEZNO_JE_UNETI: "Obavezno je uneti ",

  PACIJENT_POLJE_KATEGORIJA_LIST: "Kategorije polja pacijenta ",
  PACIJENT_POLJE_KATEGORIJA_KATEGORIJA: "Kategorija ",
  PACIJENT_POLJE_KATEGORIJA_OBAVEZNO: "Obavezno ",
  PACIJENT_POLJE_KATEGORIJA_READONLY: "Samo za čitanje ",
  PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_CREATE: "Kreiranje kategorije za polje pacijenta ",
  PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_UPDATE: "Izmena kategorije za polje pacijenta ",
  PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DETAILS: "Detalji kategorije za polje pacijenta ",
  PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DELETE: "Brisanje kategorije za polje pacijenta ",
  PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_CREATE_PACIJENT_POLJE_SUCCESS: "Kategorija za polje pacijenta je uspešno kreirana",
  PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_UPDATE_PACIJENT_POLJE_SUCCESS: "Kategorija za polje pacijenta je uspešno izmenjena",
  PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_DELETE_PACIJENT_POLJE_SUCCESS: "Kategorija za polje pacijenta je uspešno obrisana",

  /*Polje tip */
  POLJE_TIP: "Tip polja",
  POLJE_TIP_LIST: "Tipovi polja",
  POLJE_TIP_NAZIV: "Naziv polja",
  POLJE_TIP_SIFRA: "Šifra polja",
  POLJE_TIP_KOMPONENTA_TIP: "Tip komponente",
  POLJE_TIP_STATUS_POLJA: "Status polja",
  POLJE_TIP_TITLE_DIALOG_CREATE: "Kreiranje tipa polja ",
  POLJE_TIP_TITLE_DIALOG_UPDATE: "Izmena tipa polja",
  POLJE_TIP_TITLE_DIALOG_DELETE: "Brisanje tipa polja",
  POLJE_TIP_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru.",
  POLJE_TIP_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv.",
  POLJE_TIP_KOMPONENTA_TIP_IS_REQUIRED: "Obavezno je uneti tip komponente.",
  POLJE_TIP_STATUS_POLJA_IS_REQUIRED: "Obavezno je uneti status polja.",
  POLJE_TIP_SEARCH: "Pretraga",
  SEARCH_POLJE_TIP: "Pretraga tipova polja",
  BUTTON_ADD_POLJE_TIP: "Dodaj tip polja",

  /*Polje tip enum stavka*/
  POLJE_TIP_ENUM_STAVKA_LIST: "Tipovi polja enum stavka",
  POLJE_TIP_ENUM_STAVKA_NAZIV: "Naziv polja tipa enum stavke",
  POLJE_TIP_ENUM_STAVKA_NAPOMENA: "Napomena polja tipa enum stavke",
  POLJE_TIP_ENUM_STAVKA_OPIS: "Opis polja tipa enum stavke",
  POLJE_TIP_ENUM_STAVKA_BROJNA_VREDNOST: "Brojna vrednost",
  POLJE_TIP_ENUM_STAVKA_PRIKAZNI_REDOSLED: "Prikazni redosled",
  POLJE_TIP_ENUM_STAVKA_TITLE_DIALOG_UPDATE: "Izmena tipa polja enum stavke",
  POLJE_TIP_ENUM_STAVKA_TITLE_DIALOG_DELETE: "Brisanje tipa polja enum stavke",
  BUTTON_ADD_POLJE_TIP_ENUM_STAVKA: "Dodaj tip polja enum stavke",
  TOOLTIP_CREATE_POLJE_TIP_ENUM_STAVKA: "Kreiranje polja tipa enum stavke",

  /* STATISTIČKI IZVEŠTAJ PARAMETAR ENUM STAVKA */
  IZVESTAJ_PARAMETAR_ENUM_STAVKA_LIST: "Stavke parametra izveštaja",
  IZVESTAJ_PARAMETAR_ENUM_STAVKA_NAZIV: "Naziv stavke parametra izveštaja",
  IZVESTAJ_PARAMETAR_ENUM_STAVKA_TITLE_DIALOG_UPDATE: "Izmena stavke parametra izveštaja",
  IZVESTAJ_PARAMETAR_ENUM_STAVKA_TITLE_DIALOG_DELETE: "Brisanje stavke parametra izveštaja",
  BUTTON_ADD_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Dodaj stavku parametra",
  TOOLTIP_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Kreiranje stavke parametra statističkog izveštaja",

  /*Polje tip repository */
  IMPORT_POLJE_TIP_REPOSITORY: "Preuzmi tipove polja sa repozitorijuma",
  POLJE_TIP_REPOSITORY: "Repozitorijum tipova polja",

  /*Polje */
  POLJE_LIST: "Polja formulara",
  POLJE_NAZIV: "Naziv",
  POLJE_SIFRA: "Šifra",
  POLJE_OBAVEZNO: "Obavezno",
  POLJE_TITLE_DIALOG_CREATE: "Kreiranje polja ",
  POLJE_TITLE_DIALOG_UPDATE: "Izmena polja",
  POLJE_TITLE_DIALOG_DELETE: "Brisanje polja",
  POLJE_TITLE_DIALOG_DETAILS: "Detalji polja",
  POLJE_SEARCH: "Pretraga",
  SEARCH_POLJE: "Pretraga polja",
  BUTTON_ADD_POLJE: "Dodaj polje",
  LABEL_POLJE: "Polje formulara",
  POLJE_ENTITY_STATUS_IS_REQUIRED: "Obavezno je uneti status pregleda za polje.",
  POLJE_NADREDJENO: "Nadređeno polje",
  POLJE_SQL_UPIT: "SQL upit",

  /* Tooltip */
  TOOLTIP_CREATE_POLJE: "Kreiranje polja formulara",
  TOOLTIP_UPDATE_POLJE: "Izmena polja formulara",
  TOOLTIP_DELETE_POLJE: "Brisanje polja formulara",

  /* radnik profil */
  RADNIK_PROFIL_CHANGE: "Izmena korisničkog profila",
  RADNIK_PROFIL_CHANGE_EMAIL: "Imejl",
  RADNIK_PROFIL_CHANGE_IME: "Ime",
  RADNIK_PROFIL_CHANGE_PREZIME: "Prezime",
  RADNIK_PROFIL_CHANGE_JEZIK: "Pismo",
  RADNIK_PROFIL_BUTTON_SAVE: "Sačuvaj",
  RADNIK_PROFIL_BUTTON_CHANGE_PASSWORD: "Promena lozinke",
  RADNIK_PROFIL_BUTTON_CANCEL: "Poništi",
  RADNIK_PROFIL_CHANGE_PASSWORD: "Promena lozinke",
  RADNIK_PROFIL_PASSWORD_CHANGE_ERROR: "Greška prilikom promene lozinke.",
  RADNIK_PROFIL_MESSAGES_NEW_PASSWORD: "Morate uneti novu lozinku.",
  RADNIK_PROFIL_MESSAGES_OLD_PASSWORD: "Morate uneti staru lozinku.",
  RADNIK_PROFIL_MESSAGES_PASSWORD_CONFIRMATION: "Morate uneti potvrdu nove lozinke.",
  RADNIK_PROFIL_MESSAGES_PASSWORDS_NOT_SAME: "Nova lozinka i potvrda lozinke nisu iste.",
  RADNIK_PROFIL_BUTTON_SAVE_NEW_PASSWORD: "Sačuvaj novu lozinku",
  RADNIK_PROFIL_OLD_PASSWORD: "Stara lozinka",
  RADNIK_PROFIL_NEW_PASSWORD: "Nova lozinka",
  RADNIK_PROFIL_CONFIRM_NEW_PASSWORD: "Potvrda nove lozinke",
  RADNIK_PROFIL_EDIT_SUCCESS: "Uspešno sačuvane izmene.",
  RADNIK_PROFIL_CHANGE_PASSWORD_SUCCESS: "Uspešno promenjena lozinka.",
  RADNIK_PROFIL_ODABERI_PODATKE: "Odaberi ovog radnika",
  RADNIK_PROFIL_ODUSTANI_OD_ODABIRA: "Odustani",

  /** usluga */
  USLUGA: "Usluga",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA: "Lista planiranih usluga",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_UKLONI_SVE: "Ukloni sve",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_PREBACI_SVE: "Prebaci sve",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_NEMA_PLANIRANIH_USLUGA: "Nema planiranih usluga",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_BRISANJE_POJEDINACNE_USLUGE: "Planirana usluga uspešno obrisana",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_BRISANJE_SVIH_USLUGA: "Planirane usluge uspešno obrisane",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_REALIZOVANO_USPESNO: "Planirane usluge uspešno prebačene u realizovane",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_POJEDINACNA_REALIZOVANA_USPESNO: "Planirana usluga uspešno prebačena u realizovanu",
  USLUGA_LIST_TOKOM_KONTKAKTA: "Lista realizovanih usluga tokom pregleda",
  USLUGA_BROJ_PRUZANJA: "Broj pružanja:",
  USLUGA_NAPOMENA: "Napomena:",
  USLUGA_PRUZIO: "Pružio/la:",
  USLUGA_PRUZIO_MUSKO: "Pružio",
  USLUGA_PRUZIO_ZENSKO: "Pružila",
  USLUGA_NOVA: "Nova usluga",
  USLUGA_IZMENI: "Izmeni uslugu",
  USLUGA_OBRISI: "Obriši uslugu",
  USLUGA_IZMENA: "Izmena usluge",
  USLUGA_BRISANJE: "Brisanje usluge",
  USLUGA_DODAVANJE: "Dodaj uslugu",
  USLUGA_UNOS: "Unos usluge:",
  USLUGA_TIP: "Tip usluge:",
  USLUGA_UNOS_OBAVEZAN: "Unos je obavezan podatak",
  USLUGA_BROJ_PRUZANJA_OBAVEZAN: "Broj pružanja je obavezan podatak",
  USLUGA_USPESNO_IZMENJENA: "Usluga uspešno izmenjena",
  USLUGA_USPESNO_OBRISANA: "Usluga uspešno obrisana",
  USLUGA_USPESNO_SACUVANA: "Usluga uspešno sačuvana",
  NO_USLUGA_CENA_WARNING: "Odabrali ste uslugu koja nema aktivnu cenu i sa njom pregled neće moći da se naplati. Da li ste sigurni da želite da je dodate?",
  NO_USLUGA_CENA_LIST_WARNING: "Odabrali ste usluge koje nemaju aktivnu cenu i sa njima pregled neće moći da se naplati.",
  NO_USLUGA_CENA_LIST_WARNING_ACCEPT: "Da li ste sigurni da želite da ih dodate zajedno sa uslugama koje ste izabrali, a već imaju cenu?",

  /* utrosak */
  UTROSCI: "Utrošci",
  UTROSAK_BRISANJE: "Utrošak uspešno obrisan",
  UTROSAK_DODAVANJE: "Utrošak uspešno dodat",
  UTROSAK_UNOS: "Utrošak uspešno unet",
  UTROSAK_IZMENA: "Utrošak uspešno izmenjen",
  UTROSAK_LIST_TOKOM_KONTAKTA: "Lista utroška tokom pregleda",
  UTROSAK_NOVI: "Dodaj utrošak",
  UTROSAK_IZMENI: "Izmeni utrošak",
  UTROSAK_OBRISI: "Obriši utrošak",
  UTROSAK_BROJ_PRUZANJA: "Broj",
  UTROSAK_NAPOMENA: "Napomena:",
  UTROSAK_BROJ_JE_OBAVEZAN_PODATAK: "Potrošena količina je obavezan podatak",
  UTROSAK_USPESNO_KREIRAN: "Utrošak je uspešno kreiran",
  UTROSAK_USPESNO_IZMENJEN: "Utrošak je uspešno izmenjen",
  UTROSAK_USPESNO_OBRISAN: "Utrošak je uspešno obrisan",
  UTROSAK_DEO_NAZIVA_SIFRE: "Deo naziva ili šifre",
  UTROSAK_POTROSENA_KOLICINA: "Potrošena količina:",
  UTROSAK: "Utrošak:",
  NO_PROIZVOD_CENA_WARNING: "Odabrali ste utrošak koji nema aktivnu cenu i sa njim pregled neće moći da se naplati. Da li ste sigurni da želite da ga dodate?",
  /* ustanova */
  SEARCH_USTANOVA: "Pretraga ustanova",
  USTANOVA_NAZIV: "Naziv",
  USTANOVA_NAZIV_KRATKI: "Naziv kratki",
  USTANOVA_SIFRA: "Šifra",
  USTANOVA_ADRESA: "Adresa",
  USTANOVA_TELEFON: "Telefon",
  USTANOVA_JEZIK: "Pismo",
  USTANOVA_SVOJINA_TIP: "Tip svojine",
  USTANOVA_NIVO_ZDRAVSTVENE_ZASTITE: "Nivo zdravstvene zaštite",
  USTANOVA_PODRAZUMEVANA_STRANA: "Podrazumevana strana",
  USTANOVA_SIFARNIK_VALUTA: "Podrazumevana valuta",
  USTANOVA_GPS_LOKACIJA: "GPS lokacija",
  USTANOVA_FAKS: "Faks",
  USTANOVA_TEKUCI_RACUN: "Tekući račun",
  USTANOVA_PROTOKOL: "Protokol",

  USTANOVA_SEARCH: "Pretraga",
  USTANOVA_LIST: "Ustanove",
  USTANOVA_TITLE_DIALOG_CREATE: "Kreiranje ustanove ",
  USTANOVA_TITLE_DIALOG_UPDATE: "Izmena ustanove ",
  USTANOVA_TITLE_DIALOG_DETAILS: "Detalji o ustanovi ",
  USTANOVA_TITLE_DIALOG_DELETE: "Brisanje ustanove ",
  USTANOVA_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv ustanove.",
  USTANOVA_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru ustanove.",
  USTANOVA_NAZIV_KRATKI_IS_REQUIRED: "Obavezno je uneti kratki naziv ustanove.",
  USTANOVA_TIP_SVOJINE_IS_REQUIRED: "Obavezno je uneti tip svojine.",
  USTANOVA_NIVO_ZDRAVSTVENE_ZASTITE_IS_REQUIRED: "Obavezno je uneti nivo zdravstvene zaštite.",
  USTANOVA_PODRAZUMEVANA_STRANA_IS_REQUIRED: "Obavezno je uneti podrazumevanu stranu.",
  USTANOVA_TITLE_MESSAGE_DELETE_USTANOVA_SUCCESS: "Ustanova je uspešno obrisana.",
  USTANOVA_TITLE_MESSAGE_CREATE_USTANOVA_SUCCESS: "Ustanova je uspešno kreirana.",
  USTANOVA_TITLE_MESSAGE_UPDATE_USTANOVA_SUCCESS: "Ustanova je uspešno izmenjena.",
  USTANOVA_LOGO: "Logo",
  USTANOVA_CHOOSE_LOGO: "Izaberite logo",
  USTANOVA_MAX_SIZE_LOGO: "Maksimalna veličina slike je 5 MB",
  USTANOVA_MAX_FILE_SIZE_OVERLOADED: " datoteka nije odgovarajuće veličine",
  USTANOVA_FILE_TYPE_NOT_SUPPORTED: " nije podržan tip datoteke",
  USTANOVA_GRID_TITLE_MESSAGE_UPDATE_USTANOVA_GRID_SUCCESS: "Podešavanje grida ustanove je uspešno izmenjeno",
  USTANOVA_GRID_VREME_TRAJANJA_PREGLEDA: "Vreme trajanja pregleda",
  USTANOVA_GRID_VANREDNI_TERMINI: "Vanredni termini",
  USTANOVA_GRID_RADNI_DANI_U_NEDELJI: "Radni dani",
  USTANOVA_GRID_ZAKAZIVANJA: "Raspored",
  USTANOVA_GRID_SPISAK_PACIJENATA_JASPER: "Spisak pacijenata jasper dokument",
  USTANOVA_GRID_SPISAK_PACIJENATA_JASPER_CHOOSE: "Izaberite jasper",
  USTANOVA_GRID_OMOGUCENA_REZERVACIJA: "Omogućena rezervacija termina",
  USTANOVA_GRID_PODRAZUMEVANA_DUZINA_TRAJANJA_REZERVACIJE: "Podrazumevana dužina trajanja rezervacije termina",
  USTANOVA_GRID_MAKSIMALNA_DUZINA_TRAJANJA_REZERVACIJE: "Maksimalna dužina trajanja rezervacije termina",
  USTANOVA_GRID_POTREBNI_RADNI_PERIODI_ZA_ZAKAZIVANJE: "Potrebni radni periodi za zakazivanje",
  USTANOVA_LICENCE: "Licence",
  USTANOVA_PARAMETRI: "Podešavanje parametra ustanove",
  USTANOVA_ULOGE: "Uloge",
  USTANOVA_TIP_KONTAKTA: "Tipovi pregleda",
  USTANOVA_RADNICI: "Radnici",
  USTANOVA_WEBSITE_URL: "Veb-sajt adresa",
  USTANOVA_RADNO_VREME_OD: "Radno vreme od",
  USTANOVA_RADNO_VREME_DO: "Radno vreme do",
  USTANOVA_BROJ_NALOGA: "Ustanova broj naloga",
  USTANOVA_BROJ_NALOGA_TITLE_DELETE: "Obriši broj naloga",
  USTANOVA_VAZI_OD_VECA_OD_DANAS: "Važi od ne može biti manje od današnjeg dana",
  USTANOVA_VAZI_OD_VECA_OD_VAZI_DO: "Važi od ne može biti veće od važi do",
  USTANOVA_VREME_OD_REQUIRED: "Obavezno je uneti početak radnog vremena",
  USTANOVA_VREME_DO_REQUIRED: "Obavezno je uneti kraj radnog vremena",
  USTANOVA_LOGO_UPLOAD: "Logo ustanove",
  USTANOVA_PODESAVANJA: "Podešavanja",
  USTANOVA_PODESAVANJA_UPDATE_SUCCESS: "Podešavanja ustanove su uspešno izmenjena",
  USTANOVA_PODESAVANJA_PODSETNIK_PACIJENT: "Vreme slanja mejla podsetnika pacijentu za predstojeći pregled",
  USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA: "Automatsko razduživanje planiranih usluga",
  USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA_POTVRDA: "Potvrda automatskog razduživanja",
  USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA_DIALOG_CONTENT:
    "Uključivanje automatskog razduživanja planiranih usluga znači da se sve planirane usluge, pa i one koje nemaju definisanu cenu, automatski prebacuju u realizovane pri započinjanju pregleda.",
  USTANOVA_NADJIMED: "NađiMeD",
  USTANOVA_NADJIMED_LABEL_CENOVNICI_USLUGA: "Cenovnici usluga",
  USTANOVA_NADJIMED_LABEL_ORGANIZACIONE_JEDINICE: "Organizacione jedinice",
  USTANOVA_NADJIMED_LABEL_DOKTORI: "Doktori",
  USTANOVA_NADJIMED_LABEL_INICIJALIZACIJA: "Inicijalizacija",
  USTANOVA_SINHRONIZUJ: "Sinhronizuj",
  USTANOVA_INICIJALIZUJ: "Inicijalizuj",
  USTANOVA_NADJIMED_LABEL_CENOVNICI_USLUGA_SUCCESS_MESSAGE: "Uspešno ste sinhronizovali cenovnika usluga",
  UUSTANOVA_NADJIMED_LABEL_ORGANIZACIONE_JEDINICE_SUCCESS_MESSAGE: "Uspešno ste sinhronizovali organizacione jedinice",
  USTANOVA_NADJIMED_LABEL_DOKTORI_SUCCESS_MESSAGE: "Uspešno ste sinhronizovali doktore",
  USTANOVA_NADJIMED_LABEL_INICIJALIZACIJA_SUCCESS_MESSAGE: "Uspešna inicijalizacija",

  /* ustanova eksterni sistem */
  USTANOVA_EKSTERNI_SISTEM: "Eksterni sistemi",
  EKSTERNI_SISTEM: "Eksterni sistem",
  BUTTON_ADD_USTANOVA_EKSTERNI_SISTEM: "Dodaj podešavanje za eksterni sistem",
  USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_CREATE: "Kreiraj podešavanje za eksterni sistem",
  USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_UPDATE: "Izmeni podešavanje za eksterni sistem",
  USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_DELETE: "Obriši podešavanje za eksterni sistem",
  USTANOVA_EKSTERNI_SISTEM_TITLE_UPDATE: "Izmena podešavanja eksternog sistema ",
  USTANOVA_EKSTERNI_SISTEM_TITLE_DETAILS: "Detalji podešavanja eksternog sistema ",
  USTANOVA_EKSTERNI_SISTEM_TITLE_DELETE: "Brisanje podešavanja eksternog sistema ",
  USTANOVA_EKSTERNI_SISTEM_TITLE_CREATE: "Kreiranje podešavanja eksternog sistema ",
  USTANOVA_EKSTERNI_SISTEM_EKSTERNI_SISTEM_IS_REQUIRED: "Obavezno je uneti eksterni sistem",
  USTANOVA_EKSTERNI_SISTEM_USERNAME_IS_REQUIRED: "Obavezno je uneti username",
  USTANOVA_EKSTERNI_SISTEM_PASSWORD_IS_REQUIRED: "Obavezno je uneti password",
  USTANOVA_EKSTERNI_SISTEM_MESSAGE_CREATE_SUCCESS: "Podešavanje eksternog sistema je uspešno kreirano",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_EKSTERNI_SISTEM_1: "Kreirano podešavanje za eksterni sistem ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_EKSTERNI_SISTEM_2: " sa username-om ",
  USTANOVA_EKSTERNI_SISTEM_MESSAGE_UPDATE_SUCCESS: "Podešavanje eksternog sistema je uspešno izmenjeno",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_EKSTERNI_SISTEM_1: "Izmenjeno podešavanje za eksterni sistem ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_EKSTERNI_SISTEM_2: " username je sada ",
  USTANOVA_EKSTERNI_SISTEM_TITLE_MESSAGE_DELETE_USTANOVA_EKSTERNI_SISTEM_SUCCESS: "Podešavanje eksternog sistema je uspešno izbrisano",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_EKSTERNI_SISTEM_1: "Obrisano podešavanje za eksterni sistem ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_EKSTERNI_SISTEM_2: " sa username-om ",

  /* organizaciona jedinica */
  SEARCH_ORGANIZACIONA_JEDINICA: "Pretraga organizacionih jedinica",
  ORGANIZACIONA_JEDINICA_NAZIV: "Naziv",
  ORGANIZACIONA_JEDINICA_TIP: "Tip",
  ORGANIZACIONA_JEDINCA_NADREDJENA: "Nadređena organizaciona jedinica",
  ORGANIZACIONA_JEDINICA_ADRESA: "Adresa",
  ORGANIZACIONA_JEDINICA_TELEFON: "Telefon",
  ORGANIZACIONA_JEDINICA_RADNI_DANI: "Radni dani",
  ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE: "Specijalizacije",
  ORGANIZACIONA_JEDINICA_LABEL_SPECIJALIZACIJE_LIST: "Specijalizacije organizacione jedinice",
  ORGANIZACIONA_JEDINICA_LABEL_SPECIJALIZACIJE_LIST_SEARCH: "Pretraga specijalizacija organizacione jedinice",
  ORGANIZACIONA_JEDINICA_DOSTUPNA_ZA_EKSTERNE_ZAKAZIVACE: "Dostupna za eksterne zakazivače",

  ORGANIZACIONA_JEDINICA_SEARCH: "Pretraga",
  ORGANIZACIONA_JEDINICA_LIST: "Organizacione jedinice",
  ORGANIZACIONA_JEDINICA_TITLE_DIALOG_CREATE: "Kreiranje organizacione jedinice ",
  ORGANIZACIONA_JEDINICA_TITLE_DIALOG_UPDATE: "Izmena organizacione jedinice ",
  ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DELETE: "Brisanje organizacione jedinice ",
  ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DETAILS: "Detalji organizacione jedinice ",
  ORGANIZACIONA_JEDINICA_NADREDJENA_ORGANIZACIONA_JEDINICA: "Nadređena organizaciona jedinica",
  ORGANIZACIONA_JEDINICA_TIP_ORGANIZACIONE_JEDINICE: "Tip organizacione jedinice",
  ORGANIZACIONA_JEDINICA_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv.",
  ORGANIZACIONA_JEDINICA_TIP_IS_REQUIRED: "Obavezno je uneti tip.",
  ORGANIZACIONA_JEDINICA_RADNI_DANI_IS_REQUIRED: "Radni dani ne smeju biti prazno polje!",
  ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_CREATE_ORGANIZACIONA_JEDINICA_SUCCESS: "Organizaciona jedinica je uspešno kreirana.",
  ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_UPDATE_ORGANIZACIONA_JEDINICA_SUCCESS: "Organizaciona jedinica je uspešno izmenjena.",
  ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_DELETE_ORGANIZACIONA_JEDINICA_SUCCESS: "Organizaciona jedinica je uspešno obrisana.",
  ORGANIZACIONA_JEDINICA_RADNO_VREME_OD: "Radno vreme od",
  ORGANIZACIONA_JEDINICA_RADNO_VREME_DO: "Radno vreme do",

  /* radni period */
  RADNI_PERIOD_LIST: "Radni periodi",
  RADNI_PERIOD_COLUMN_ORGANIZACIONA_JEDINICA: "Organizaciona jedinica",
  RADNI_PERIOD_COLUMN_RADNIK: "Radnik",
  RADNI_PERIOD_COLUMN_DATUM_OD: "Datum od",
  RADNI_PERIOD_COLUMN_DATUM_DO: "Datum do",
  RADNI_PERIOD_COLUMN_POCETAK_PERIODA: "Početak perioda",
  RADNI_PERIOD_COLUMN_KRAJ_PERIODA: "Kraj perioda",
  RADNI_PERIOD_BUTTON_ADD: "Dodaj radni period",
  RADNI_PERIOD_SEARCH: "Pretraga",
  RADNI_PERIOD_CREATE: "Kreiranje radnog perioda ",
  RADNI_PERIOD_COLUMN_RADNI_DANI: "Radni dani",
  RADNI_PERIOD_VREME_POCETKA: "Vreme početka radnog dana",
  RADNI_PERIOD_VREME_KRAJ: "Vreme kraja radnog dana",
  RADNI_PERIOD_PAUZA_POCETAK: "Vreme početka pauze",
  RADNI_PERIOD_PAUZA_KRAJ: "Vreme kraja pauze",
  RADNI_PERIOD_TRAJANJE_PREGLEDA: "Trajanje pregleda",
  RADNI_PERIOD_OPIS: "Opis",
  RADNI_PERIOD_COLUMN_DATUM: "Datum",
  RADNI_PERIOD_COLUMN_RADNI_DAN: "Radni dan",
  RADNI_PERIOD_DANI_U_NEDELJI_IS_REQUIRED: "Obavezno je uneti dane u nedelji",
  RADNI_PERIOD_TITLE_MESSAGE_CREATE_RADNI_PERIOD_SUCCESS: "Uspešno kreiran radni period",
  RADNI_PERIOD_CONFIRM_DELETE_MESSAGE: "Da li ste sigurni da želite da obrišete radni period?",
  RADNI_PERIOD_CONFIRM_DELETE_SELECTED_MESSAGE: "Da li ste sigurni da želite da obrišete selektovane radne periode?",
  RADNI_PERIOD_TITLE_DELETE_SELECTED: "Obrišite selektovane radne periode",
  RADNI_PERIOD_POGRESNO_RADNO_VREME: "Pogrešno radno vreme uneto",
  RADNI_PERIOD_POGRESNAN_RADNI_PERIOD: "Pogrešan radni period unet",
  RADNI_PERIOD_DATUM_OD_REQUIRED: "Datum od je obavezno polje",
  RADNI_PERIOD_DATUM_DO_REQUIRED: "Datum do je obavezno polje",
  RADNI_PERIOD_VREME_POCETKA_RADNOG_DANA_REQUIRED: "Vreme početka radnog dana je obavezno polje",
  RADNI_PERIOD_VREME_KRAJA_RADNOG_DANA_REQUIRED: "Vreme kraja radnog dana je obavezno polje",
  RADNI_PERIOD_POGRESNA_PAUZA: "Pogrešna pauza uneta",
  RADNI_PERIOD_NEDOSTUPAN_ZA_EKSTERNE_ZAKAZIVACE: "Nedostupan za eksterne zakazivače",

  /* vrsta pregleda */
  TIP_KONTAKTA: "Tip pregleda",
  TIP_KONTAKTA_LIST: "Tipovi pregleda",
  TIP_KONTAKTA_NAZIV: "Naziv",
  TIP_KONTAKTA_SIFRA: "Šifra",
  TIP_KONTAKTA_AUTOMATSKO_ZAVRSAVANJE: "Automatsko završavanje",
  TIP_KONTAKTA_KATEGORIJA_KONTAKTA: "Kategorija pregleda",
  TIP_KONTAKTA_BUTTON_SEARCH: "Pretraga tipova pregleda",
  TIP_KONTAKTA_TITLE_DIALOG_CREATE: "Kreiranje tipa pregleda ",
  TIP_KONTAKTA_TITLE_DIALOG_UPDATE: "Izmena tipa pregleda ",
  TIP_KONTAKTA_TITLE_DIALOG_DELETE: "Brisanje tipa pregleda ",
  TIP_KONTAKTA_TITLE_DIALOG_DETAILS: "Detalji tipa pregleda ",
  TIP_KONTAKTA_TITLE_MESSAGE_DELETE_TIP_KONTAKTA_SUCCESS: "Tip pregleda je uspešno obrisan.",
  TIP_KONTAKTA_TITLE_MESSAGE_CREATE_TIP_KONTAKTA_SUCCESS: "Tip pregleda je uspešno kreiran.",
  TIP_KONTAKTA_TITLE_MESSAGE_UPDATE_TIP_KONTAKTA_SUCCESS: "Tip pregleda je uspešno izmenjen.",
  TIP_KONTAKTA_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv tipa pregleda.",
  TIP_KONTAKTA_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru tipa pregleda.",
  TIP_KONTAKTA_KATEGORIJA_KONTAKTA_IS_REQUIRED: "Obavezno je uneti kategoriju pregleda za tip pregleda.",
  TIP_KONTAKTA_ENTITY_STATUS_IS_REQUIRED: "Obavezno je uneti status pregleda za tip pregleda.",
  TIP_KONTAKTA_VREME_TRAJANJA_PREGLEDA: "Vreme trajanja pregleda",
  TIP_KONTAKTA_PODRAZUMEVANI_FORMULAR: "Podrazumevani formular",

  IMPORT_TIP_KONTAKTA_REPOSITORY: "Preuzmi tip pregleda sa repozitorijuma",
  TIP_KONTAKTA_REPOSITORY: "Repozitorijum tipova pregleda",

  /*uloga*/
  SEARCH_ULOGA: "Pretraga uloga",
  ULOGA_LIST: "Uloge",
  ULOGA_SIFRA: "Šifra",
  ULOGA_NAZIV: "Naziv",
  ULOGA_OPIS: "Opis",
  ULOGA_NAPOMENA: "Napomena",
  ULOGA_ROLA: "Rola",
  ULOGA_SEARCH: "Pretraga",
  ULOGA_TITLE_DIALOG_CREATE: "Kreiranje uloge ",
  ULOGA_TITLE_DIALOG_UPDATE: "Izmena uloge ",
  ULOGA_TITLE_DIALOG_DELETE: "Brisanje uloge ",
  ULOGA_TITLE_DIALOG_DETAILS: "Detalji uloge ",
  ULOGA_TITLE_MESSAGE_DELETE_ULOGA_SUCCESS: "Uloga je uspešno obrisana.",
  ULOGA_TITLE_MESSAGE_CREATE_ULOGA_SUCCESS: "Uloga je uspešno kreirana.",
  ULOGA_TITLE_MESSAGE_UPDATE_ULOGA_SUCCESS: "Uloga je uspešno izmenjena.",
  ULOGA_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv.",
  ULOGA_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru.",
  ULOGA_ROLA_IS_REQUIRED: "Obavezno je uneti rolu",
  USTANOVA_BROJ_NALOGA_BUTTON_DODAJ: "Dodaj novu vezu",
  USTANOVA_BROJ_NALOGA_TITLE_CREATE: "Kreiranje broja naloga",
  USTANOVA_BROJ_NALOGA_TITLE_UPDATE: "Ažuriranje broja naloga",
  USTANOVA_BROJ_NALOGA_IS_REQUIRED: "Broj naloga je obavezan podatak",
  USTANOVA_VAZI_OD_IS_REQUIRED: "Važi od je obavezan podatak",

  // Zakazivanje pregleda
  ZAKAZIVANJE_ZAKAZIVANJE_PREGLEDA: "Zakazivanje pregleda",
  ZAKAZIVANJE_TERMINI: "Termini",
  ZAKAZIVANJE_ZAKAZIVANJE: "Zakazivanje",
  ZAKAZIVANJE_CEKAONICA: "Čekaonica",
  ZAKAZIVANJE_PRETRAGA_PACIJENATA: "Pretraga pacijenata",
  ZAKAZIVANJE_RADNIK_IS_REQUIRED: "Lekar je obavezno polje",
  ZAKAZIVANJE_PREZIME_IS_REQUIRED: "Prezime je obavezno polje",
  ZAKAZIVANJE_IME_IS_REQUIRED: "Ime je obavezno polje",
  ZAKAZIVANJE_TIP_KONTAKTA_IS_REQUIRED: "Tip pregleda je obavezno polje",
  ZAKAZIVANJE_PROMENA_TERMINA_PROMENI: "Promeni",
  ZAKAZIVANJE_PROMENA_TERMINA_ODUSTANI: "Odustani",
  ZAKAZIVANJE_PROMENA_TERMINA_DIALOG_POTVRDA: "Da li ste sigurni da želite da pomerite termin u novo vreme?",
  ZAKAZIVANJE_PROMENA_TERMINA_USPESNO: "Uspešno ste promenili vreme termina!",
  CEKAONICA_COLUMN_SVE: "Sve",
  CEKAONICA_COLUMN_STATUS: "Status",
  CEKAONICA_COLUMN_VREME: "Vreme",
  CEKAONICA_COLUMN_PACIJENT: "Pacijent",
  CEKAONICA_COLUMN_VREME_DOLASKA: "Vreme dolaska",
  CEKAONICA_COLUMN_DATUM_RODJENJA: "Datum rođenja",
  CEKAONICA_COLUMN_DIJAGNOZE: "Dijagnoze",
  CEKAONICA_COLUMN_NALAZ: "Nalazi",
  CEKAONICA_COLUMN_ZAPOCNI_TERMIN: "Započni",
  CEKAONICA_COLUMN_ZAPOCNI_TERMIN_TOOLTIP: "Započni pregled",
  CEKAONICA_COLUMN_ZAVRSI_TERMIN: "Završi",
  CEKAONICA_COLUMN_ZAVRSI_TERMIN_TOOLTIP: "Završi pregled",
  CEKAONICA_PACIJENT_ORDINACIJA: "Ordinacija",
  CEKAONICA_PACIJENT_VREME: "Vreme",
  CEKAONICA_PACIJENT_PACIJENT: "Pacijent",
  CEKAONICA_PACIJENT_DATUM_RODJENJA: "Datum rođenja",
  CEKAONICA_PACIJENT_ADRESA: "Adresa",
  CEKAONICA_PACIJENT_BROJ_TELEFONA: "Broj telefona",
  CEKAONICA_PACIJENT_USLUGA: "Usluga",
  LISTA_RADNIKA_LISTA_DOKTORA: "Lista doktora",
  LISTA_RADNIKA_PRETRAGA_PLACEHOLDER: "Pretraga radnika",
  DA_LI_STE_SIGURNI_ZATVARANJE_KONTAKATA: "Da li ste sigurni da želite da zatvorite izabrane preglede?",
  CEKAONICA_BUTTON_PROMENA_DOKTORA: "Promena doktora",
  CEKAONICA_BUTTON_ZATVARANJE_KONTAKATA: "Zatvaranje kontakata",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE: "Da li ste sigurni da želite da obrišete?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_POPUNJEN_FORMULAR: "Da li ste sigurni da želite da obrišete popunjen formular?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_VREME_SMRTI: "Da li ste sigurni da želite da obrišete vreme smrti?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_JR_DOKUMENT: "Da li ste sigurni da želite da obrišete jasper dokument?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_SABLON: "Da li ste sigurni da želite da obrišete šablon?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_PACIJENT: "Da li ste sigurni da želite da obrišete pacijenta?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_PROFILNU_SLIKU: "Da li ste sigurni da želite da obrišete profilnu sliku?",
  IRREVERSIBLE_ACTION: "Ova akcija je nepovratna!",
  PERMANENT_DELETE_PART_1: "Brisanjem pacijenta",
  PERMANENT_DELETE_PART_2: "trajno se brišu sve informacije o njemu uključujući i svi njegovi pregledi.",
  DELETE_ZAKAZAN_TERMIN: "Obriši termin",
  CONFIRM_DELETE_ZAKAZAN_TERMIN: "Da li ste sigurni da želite da obrišete termin?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_KONTAKT: "Da li ste sigurni da želite da obrišete pregled?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_UPOZORENJE: "Da li ste sigurni da želite da obrišete upozorenje?",
  NESACUVAN_NALAZ: "Nesačuvan nalaz",
  CEKAONICA_COLUMN_ADRESA: "Adresa",
  CEKAONICA_COLUMN_TELEFON: "Broj telefona",
  CEKAONICA_COLUMN_EMAIL: "Imejl",
  CEKAONICA_PACIJENT_EMAIL: "Imejl",
  ZAKAZIVANJE_PREGLEDA_UPOZORENJE_PACIJENT: 'Klikom na "Zakaži" podaci o pacijentu će biti izmenjeni i u dosijeu',
  ZAKAZIVANJE_PREGLEDA_UPDATE_UPOZORENJE_PACIJENT: 'Klikom na "Izmeni" podaci o pacijentu će biti izmenjeni i u dosijeu',
  ZAKAZIVANJE_PREGLEDA_ADD_COMMENT_SUCCESS_MESS: "Uspešno ste dodali komentar.",
  ZAKAZIVANJE_PREGLEDA_ADD_FILE_BUTTON_LABEL: "Učitajte datoteku ili je prevucite u ovo polje",
  ZAKAZIVANJE_PREGLEDA_ADD_FILE_SUCCESS_MESS: "Uspešno ste dodali datoteku.",
  ZAKAZIVANJE_PREGLEDA_REDOVNI_TERMIN: "Redovni termin",
  ZAKAZIVANJE_PREGLEDA_VANREDNI_TERMIN: "Vanredni termin",
  PRIKAZI_NEAKTIVNE_RADNIKE: "Prikaži i neaktivne radnike",
  POCETAK_I_KRAJ_TERMINA_NOT_VALID: "Početak termina mora biti pre kraja termina",
  DATUM_I_VREME_REQUIRED: "Datum i vreme termina su obavezna polja",

  POL: "Pol",
  DATUM_RODJENJA: "Datum rođenja",
  OSIGURANJE: "Osiguranje",
  JMBG: "JMBG",
  UZRAST: "Uzrast",

  DOKUMENTACIJA: "Dokumentacija",
  ARHIVIRANI_PODACI: "arhivirani podaci",
  ISTORIJA_OSIGURANJA: "Istorija osiguranja",
  PODACI_O_PACIJENTU: "Podaci o pacijentu",
  PODACI_O_SMRTI: "Podaci o smrti",
  VREME_SMRTI: "Vreme smrti",
  POLISE_OSIGURANJA: "Polise osiguranja",
  ISPRAVA: "Isprava",
  BROJ_POLISE: "Broj polise",
  OSIGURANJE_TIP: "Tip osiguranja",
  OSIGURANJE_TIP_PRIVATNO: "Privatno",
  OSIGURANJE_TIP_PARTNER: "Partner",
  PRIVATNIK_NAZIV: "Naziv privatnika",
  PARTNER_NAZIV: "Naziv partnera",
  VAZI_OD: "Važi od",
  VAZI_DO: "Važi do",
  PIB: "PIB",
  VREME_POSLEDNJE_IZMENE: "Vreme poslednje izmene",
  VREME_OD: "Vreme od",
  VREME_DO: "Vreme do",
  PODACI_O_KONTAKTU: "Podaci o pregledu",
  PROTOKOL: "Protokol",
  LICNI_PODACI: "Lični podaci",
  PODESAVANJA_ZAKAZIVANJA: "Podešavanje zakazivanja",
  PODESAVANJA_ZAKAZIVANJA_POLJE: "Podešavanje polja",
  USTANOVA: "Ustanova",
  NALAZ: "Nalaz",
  ADMINISTRATIVNI_PODACI: "Administrativni podaci",
  KONTAKT_OSOBE: "Pregled osobe",
  MEDICINSKI_PODACI: "Medicinski podaci",
  PARTNER: "Partner",
  OPSTI_MEDICINSKI_PODACI: "Opšti medicinski podaci",
  NIJE_IZABRAN: "Nije izabran",
  PRIMARNI_DOKTOR: "Primarni doktor",
  PRIMARNI_PACIJENT: "Primarni pacijent",
  VANREDNI: "Vanredni",
  LEGENDA: "Legenda",
  RESET_PASSWORD_POPUP_MESSAGE: "Da li ste sigurni da želite da resetujete lozinku?",
  PRETHODNI_NALAZI: "Prethodni nalazi",
  PRETHODNI_NALAZI_NOT_FOUND: "Nema prethodnih nalaza",
  DOKUMENT: "Dokument",
  CHOOSE_DOCUMENT: " Izaberi dokument",
  REMOVE_DOKUMENT: "Izbacite dokument",
  DOKUMENT_ADD: "Dodaj dokument",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_DOKUMENT: "Da li ste sigurni da želite da obrišete dokument",
  EMAIL_WRONG_FORMAT: "Email je pogrešnog formata",
  TELEFON_WRONG_FORMAT: "Telefon je pogrešnog formata",
  PODACI: "Podaci",
  ZAKAZANI_PREGLEDI_LABEL: "Zakazani pregledi",
  ZAKAZANI_PREGLEDI_VREME_PREGLEDA: "Vreme pregleda",
  ZAKAZANI_PREGLEDI_ORGANIZACIONA_JEDINICA: "Organizaciona jedinica",
  ZAKAZANI_PREGLEDI_TIP_PREGLEDA: "Tip pregleda",
  ZAKAZANI_PREGLEDI_PRIMARNI_DOKTOR: "Primarni doktor",

  SABLON_LIST: "Šabloni",
  SABLON_ISPIS: "Ispis šablona",
  SABLON_CHOOSE_POLJE: "Morate izabrati tekstualno polje za šablon",
  SABLON_ADD: "Dodaj novi šablon",
  SABLON_UPDATE: "Promeni šablon",
  SABLON_NASLOV: "Naslov:",
  SABLON_TEKST: "Tekst:",
  SABLON_NASLOV_IS_REQUIRED: "Naslov je obavezno polje",
  SABLON_TEKST_IS_REQUIRED: "Tekst je obavezno polje",
  SABLON_POLJE_NAZIV: "Naziv polja: ",

  PRETRAGA_PACIJENTA: "Pretraga pacijenta",
  YES: "Da",
  NO: "Ne",
  FORMULAR_NAME: "Naziv formulara",
  OBRISI_KONTAKT_TOOLTIP: "Obriši pregled",
  NAPLATI_KONTAKT_TOOLTIP: "Naplati preglede",
  LISTA_DATOTEKA: "Lista datoteka",
  MESSAGES_DELETE_DOCUMENT_SUCCESS: "Uspešno ste obrisali datoteku.",
  DODAJ_DATOTEKU_PACIJENT_DOSIJE: "Dodaj datoteku u dosije pacijenta",
  DODAJ_DATOTEKU_KONTAKT: "Dodaj datoteku na pregledu",
  LISTA_DATOTEKA_PRAZNA: "Ne postoje otpremljene datoteke",
  DATOTEKE: "Datoteke",
  UPOZORENJE: "Upozorenje",
  POSTAVI_UPOZORENJE: "Postavi upozorenje",
  OBRISI_UPOZORENJE: "Obriši upozorenje",
  NOVO_UPOZORENJE: "Novo upozorenje",

  PREGLED_NALAZA_DIJAGNOZA: "Dijagnoza",
  PREGLED_NALAZA_USLUGA: "Usluga",
  PREGLED_NALAZA_KOLICINA: "količina",
  PREGLED_NALAZA_STAMPAJ: "Štampaj",
  PREGLED_NALAZA_PRISTUP_KONTAKTU: "Pristup pregledu",
  RIZICI: "Rizici",
  PREGLED_NALAZA_PRIKAZI: "Prikaži",

  LISTA_DOKUMENATA_NASLOV: "Lista dokumenata (Izveštaji, uputi...)",
  LISTA_DOKUMENATA_ORG_JED: "Org. jedinica",
  LISTA_DOKUMENATA_DATUM: "Datum",

  ZAKAZI_TERMIN: "Zakaži termin",
  ZAKAZI_VANREDAN_TERMIN: "Zakaži vanredan termin",
  ZAKAZAN_TERMIN: "Zakazan termin",
  ZAKAZAN_VANREDAN_TERMIN: "Zakazan vanredan termin",
  ZAKAZI: "Zakaži",
  LEKAR: "Lekar",
  SESTRA: "Sestra",
  VREME: "Vreme",
  ZAKAZAN_TERMIN_STATUS: "Status termina",
  ZAKAZAN_TERMIN_PLANIRANE_USLUGE: "Planirane usluge",
  ZAKAZAN_TERMIN_PLANIRANE_USLUGE_PRETRAGA_USLUGE: "Pretraži uslugu",
  ZAKAZAN_TERMIN_PLANIRANE_USLUGE_EMPTY_FILTER_MESSAGE: "Nema rezultata pretrage",
  ZAKAZAN_TERMIN_PLANIRANE_USLUGE_EMPTY_OPTIONS_MESSAGE: "Nema pronađenih usluga",
  ZAKAZI_PREGLED: "Zakaži pregled",
  POSTOJECI_PACIJENT: "Postojeći pacijent",
  NOVI_PACIJENT: "Novi pacijent",
  ZAKAZIVANJE_PODACI_O_TERMINU: "Podaci o terminu",
  ZAKAZIVANJE_PODACI_O_PACIJENTU: "Podaci o pacijentu",
  ZAKAZIVANJE_DATOTEKE: "Datoteke",
  ZAKAZIVANJE_KOMENTARI: "Komentari",

  KOPIRAJ: "Kopiraj",
  KOPIRAJ_FORMULAR: "Kopiraj formular",

  DA_LI_STE_SIGURNI_PROMENA_PODRAZUMEVANE_STRANE: "Da li želite da vam se trenutna strana prikazuje nakon prijave na sistem?",

  // pregled
  KONTAKT: "Pregled",
  DIJAGNOZE: "Dijagnoze",
  USLUGE: "Usluge",
  NALAZI: "Nalazi",
  TERAPIJE: "Terapije",
  PAKETI: "Paketi",
  KONTAKT_NOVI_NALAZ: "Novi nalaz",
  KONTAKT_NOVI_UCESNIK: "Novi učesnik",
  KONTAKT_NAPRAVLJENI_NALAZI: "Napravljeni nalazi",
  KONTAKT_ODUSTANI: "Odustani",
  KONTAKT_IDI: "Idi",
  KONTAKT_NAZAD: "Nazad",
  KONTAKT_NALAZ: "Nalaz",
  KONTAKT_DETALJI_PREGLEDA: "Odaberi tip pregleda",
  KONTAKT_KREIRAJ_NOVI_NALAZ: "Kreiraj novi nalaz",
  KONTAKT_KREIRAJ_NOVI_UCESNIK: "Kreiraj novog učesnika",
  KONTAKT_PRETHODNI_NALAZI_SABLONI: "Prethodni nalazi i šabloni",
  KONTAKT_UVECAJ_UNOS: "Uvećaj polje za unos",
  KONTAKT_SMANJI_UNOS: "Smanji polje za unos",
  KONTAKT_SVI_NALAZI: "Svi nalazi",
  SAVE_KONTAKT_PODACI: "Sačuvaj podatke o pregledu",
  KONTAKT_PODACI_USPESNO_IZMENJEN: "Uspešno su izmenjeni podaci o pregledu",
  KONTAKT_UCESNIK_DELETE_SUCCESS: "Uspešno obrisan učesnik sa pregleda",
  KONTAKT_UCESNIK_CREATE_SUCCESS: "Uspešno dodat učesnik na pregled",
  KONTAKT_CONFIRM_DELETE_UCESNIK: "Da li ste sigurni da želite da obrišete učesnika",
  KONTAKT_NOVA_TERAPIJA: "Nova terapija",
  KONTAKT_NOVA_TERAPIJA_CREATE: "Terapija je uspešno kreirana.",
  KONTAKT_PREPISANE_TERAPIJE: "Prepisane terapije",
  KONTAKT_TERAPIJA_DELETE: "Obriši terapiju",
  KONTAKT_TERAPIJA_DELETE_SUCCESS: "Terapija je uspešno obrisana.",
  KONTAKT_TERAPIJA_VIEW: "Pregled terapije",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_DIJAGNOZA: "Dijagnoza",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK: "Lek",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK_ATRIBUT: "Atribut leka",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_TIP_TERAPIJE: "Tip terapije",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PROTOKOLA: "Broj protokola",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_IZDAVANJA: "Broj izdavanja",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_TRAJANJE_TERAPIJE: "Trajanje terapije",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA: "Broj pakovanja",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA_MESEC: ". mesec :",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA_PAKOVANJE: " pakovanja",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA: "Nacin doziranja",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_DNEVNO: " puta dnevno po ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_UJUTRO: " ujutru ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_POPODNE: " popodne ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_UVECE: " uvece",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO: "Jednokratno ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_NA: "Na ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_DANA: " dana",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_PO_POTREBI: "Po potrebi ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_PROIZVOLJNO: "Proizvoljno ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_HITNO_IZDAVANJE: "Hitno izdavanje",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_ZABRANA_TERAPIJSKE_PARALELE: "Zabrana terapijske paralele",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NAPOMENA: "Napomena",
  KONTAKT_TERAPIJA_DIALOG_TITLE: "Terapija",
  KONTAKT_TERAPIJA_DIALOG_FIRST_PAGE_TITLE: "Postavljene dijagnoze",
  KONTAKT_TERAPIJA_DIALOG_FIRST_PAGE_NO_DIAGNOSIS_NOTE: "Nije postavljena nijedna dijagnoza tokom pregleda.",
  KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_TITLE: "Lek i tip terapije",
  KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_PRETRAGA_LEKA: "Pretraga leka",
  KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_ATRIBUT_LEKA: "Atribut leka",
  KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_TIP_TERAPIJE: "Tip terapije",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_TITLE: "Doziranje",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE: "Terapija za",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_DANA: "dana",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_MESECI: "meseci",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_BROJ_PAKOVANJA: "Broj pakovanja",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA: "Način doziranja",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNAKO: "jednako",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO: "kombinovano",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UJUTRU: "ujutru",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_U_PODNE: "u podne",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UVECE: "uveče",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO: "jednokratno",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_NA_SVAKIH: "na svakih",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_PO_POTREBI: "po potrebi",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_PROIZVOLJNO: "proizvoljno",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO_PRUZANJE: "Jednokratno pružanje",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DANA_PO: "dana po",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DATI_PO_POTREBI: "Dati po potrebi",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_JEDINICA_MERE: "Jedinica mere",
  KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_TITLE: "Ostalo",
  KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_IZDAVANJE_LEKA_HITNO: "Izdavanje leka hitno",
  KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_ZABRANA_TERAPIJSKE_PARALELE: "Zabrana terapijske paralele",
  KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_NAPOMENA: "Napomena",
  KONTAKT_TERAPIJA_STAMPA_TOOLTIP: "Štampaj terapiju",
  KONTAKT_TERAPIJA_STAMPA_POPUP_MESSAGE: "Da biste odštampali terapiju, potrebno je da dozvolite popup na pretraživaču.",
  KONTAKT_PAKETI_NOVI_PAKET: "Novi paket",
  KONTAKT_PAKETI_PRUZENI_TOKOM_PREGLEDA: "Paketi pruženi tokom pregleda",
  KONTAKT_PAKETI_LABEL_PAKET: "Paket",
  KONTAKT_PAKETI_LABEL_USLUGE: "Usluge",
  KONTAKT_PAKETI_LABEL_UTROSCI: "Utrošci",
  KONTAKT_PAKETI_LABEL_POSTAVIO: "Postavio/la",
  KONTAKT_PAKETI_LABEL_POSTAVIO_MUSKI: "Postavio",
  KONTAKT_PAKETI_LABEL_POSTAVIO_ZENSKI: "Postavila",
  KONTAKT_PAKETI_LABEL_IZMENI: "Izmeni paket",
  KONTAKT_PAKETI_LABEL_OBRISI: "Obriši paket",
  KONTAKT_PAKETI_DIALOG_LABEL_SNIMI: "Snimi",
  KONTAKT_PAKETI_DIALOG_LABEL_ODABERITE_PAKET: "Odaberite paket usluga",
  KONTAKT_PAKETI_DIALOG_LABEL_USLUGE_U_OKVIRU_PAKETA: "Usluge u okviru paketa",
  KONTAKT_PAKETI_DIALOG_LABEL_UTROSCI_U_OKVIRU_PAKETA: "Utrošci u okviru paketa",
  KONTAKT_PAKETI_DIALOG_CREATE_SUCCESS: "Paket je uspešno dodat.",
  KONTAKT_PAKETI_DELETE_SUCESS: "Paket je uspešno obrisan.",
  KONTAKT_PAKETI_UPDATE_SUCCESS: "Paket je uspešno izmenjen.",
  KONTAKT_PAKET_MANDATORY_FIELD: "Paket je obavezan podatak.",
  NO_PROIZVOD_USLUGA_PAKET_CENA_WARNING: "Odabrali ste uslugu ili utrošak koji nema aktivnu cenu i sa njim pregled neće moći da se naplati. Da li ste sigurni da želite da ga dodate?",

  // FAKTORI RIZIKA
  DUVAN: "Duvan",
  ALKOHOL: "Alkohol",
  SLABA_ISHRANA_VOCE_POVRCE: "Slaba ishrana voćem i povrćem",
  FIZICKA_NEKATIVNOST: "Fizička neaktivnost",
  VISOK_KRVI_PRITISAK: "Visok krvni pritisak",
  VISOK_HOLESTEROL: "Visok holesterol",
  GOJAZNOST: "Gojaznost",
  VISOK_NIVO_SECERA: "Visok nivo šećera u krvi",
  NEUHRANJENOST: "Neuhranjenost",

  CONFIRM_CREATE_OSIGURANJE: "Da li ste sigurni da želite da kreirate novo osiguranje?",
  CONFIRM_UPDATE_OSIGURANJE: "Da li ste sigurni da želite da izmenite postojeće osiguranje?",
  CONFIRM_DELETE_ARHIVIRANO_OSIGURANJE: "Da li ste sigurni da želite da obrišete arhivirano osiguranje?",
  CONFIRM_UPDATE_ARHIVIRANO_OSIGURANJE: "Da li ste sigurni da želite da izmenite datume važenja osiguranja?",
  MESSAGE_CREATE_OSIGURANJE_SUCCESS: "Uspešno ste kreirali osiguranje.",
  MESSAGE_UPDATE_OSIGURANJE_SUCCESS: "Uspešno ste izmenili osiguranje.",
  MESSAGE_DELETE_OSIGURANJE_SUCCESS: "Uspešno ste obrisali osiguranje.",
  MESSAGE_DELETE_ARHIVIRANO_OSIGURANJE_SUCCESS: "Uspešno ste obrisali arhivirano osiguranje.",

  TERMINI_NEDELJNI_PRIKAZ: "Nedeljni prikaz",
  TERMINI_DNEVNI_PRIKAZ: "Dnevni prikaz",

  LABEL_PRAVO_NAD_TIPOVIMA: "Pravo nad tipovima",
  LABEL_TIPOVI_PRAVA: "Tipovi \\ Prava",

  //ADMIN PANEL
  ADMIN_PANEL: "Admin panel",
  PRETRAGA: "Pretraga",
  SEARCH_PARAMETAR: "Pretraga parametara",
  USTANOVA_RADNIK_LIST: "Ustanove radnika",
  PARAMETAR_SAVE_SUCCESS: "Parametri uspešno sačuvani",
  PARAMETAR_SAVE_FAIL_UNDEFINED: "Parametri ne mogu da budu sačuvani, jedan od njihovih parametra nije definisan",

  RADNIK_COPY_TITLE_DIALOG: "Radnik sa istim podešavanjima",
  RADNIK_COPY_TITLE_DIALOG_CREATE: "Kreiraj radnika sa istim podešavanjima",
  RADNIK_COPY_TITLE_DIALOG_CREATE_TOOLTIP: "Kreiraj radnika sa istim ulogama i organizacionim jedinicama kao izabrani radnik",
  RADNIK_COPY_CANCEL: "Poništi kopiranje radnika",

  //SIFARNIK USLUGA
  LABEL_SIFARNIK_USLUGA: "Usluge",
  SIFARNIK_USLUGA_NAZIV: "Naziv usluge",
  SIFARNIK_USLUGA_SIFRA: "Šifra usluge",
  SIFARNIK_USLUGA_NAZIV_ENGLESKI: "Engleski naziv",
  SIFARNIK_USLUGA_OPIS: "Opis",
  SIFARNIK_USLUGA_VAZI_DO: "Važi do",
  SIFARNIK_USLUGA_VAZI_OD: "Važi od",

  SEARCH_SIFARNIK_USLUGA: "Pretraga šifarnika usluga",
  SIFARNIK_USLUGA_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv",
  SIFARNIK_USLUGA_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru",
  SIFARNIK_USLUGA_VAZI_OD_IS_REQUIRED: "Obavezno je uneti važi od",
  SIFARNIK_USLUGA_VAZI_OD_IS_BEFORE_VAZI_DO: "Važi od mora biti pre važi do",
  SIFARNIK_USLUGA_TITLE_DIALOG_CREATE: "Kreiranje usluge ",
  SIFARNIK_USLUGA_TITLE_DIALOG_UPDATE: "Izmena usluge ",
  SIFARNIK_USLUGA_TITLE_DIALOG_DETAILS: "Detalji usluge ",
  SIFARNIK_USLUGA_TITLE_DIALOG_DELETE: "Brisanje usluge ",
  SIFARNIK_USLUGA_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_SUCCESS: "Usluga je uspešno kreirana",
  SIFARNIK_USLUGA_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_SUCCESS: "Usluga je uspešno izmenjena",
  SIFARNIK_USLUGA_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_SUCCESS: "Usluga je uspešno obrisana",
  BUTTON_ADD_SIFARNIK_USLUGA: "Dodaj uslugu",
  SIFARNIK_USLUGA_LABEL_USLUGA: "Usluga",
  SIFARNIK_USLUGA_LABEL_CENA: "Cena",
  SIFARNIK_USLUGA_IZMENA_CENE: "Izmeni cenu",
  SIFARNIK_USLUGA_DODAJ_CENU: "Dodaj novu cenu",
  SIFARNIK_USLUGA_IZMENI_CENU: "Izmeni trenutnu cenu",
  SIFARNIK_USLUGA_OBRISI_CENU: "Obriši cenu",
  SIFARNIK_USLUGA_VALUTA: "Valuta",
  SIFANIK_USLUGA_LABEL_DODAJ: "Dodaj",
  SIFARNIK_USLUGA_SPECIJALIZACIJE: "Specijalizacije",
  SIFARNIK_USLUGA_DOSTUPNA_ZA_EKSTERNE_ZAKAZIVACE: "Dostupna za eksterne zakazivače",

  SIFARNIK_JEDINICA_MERE: "Jedinice mere",
  SIFARNIK_JEDINICA_MERE_BUTTON_DODAJ: "Dodaj jedinicu mere",
  SIFARNIK_JEDINICA_MERE_TITLE_CREATE: "Kreiranje jedinice mere ",
  SIFARNIK_JEDINICA_MERE_TITLE_UPDATE: "Izmena jedinice mere ",
  SIFARNIK_JEDINICA_MERE_TITLE_DETAILS: "Detalji jedinice mere ",
  SIFARNIK_JEDINICA_MERE_TITLE_DELETE: "Brisanje jedinice mere ",
  SIFARNIK_JEDINICA_MERE_NAZIV: "Naziv jedinice mere",
  SIFARNIK_JEDINICA_MERE_SIFRA: "Šifra jedinice mere",
  SIFARNIK_JEDINICA_MERE_AKRONIM: "Akronim jedinice mere",
  SIFARNIK_JEDINICA_MERE_VAZI_OD: "Važi od",
  SIFARNIK_JEDINICA_MERE_VAZI_DO: "Važi do",
  SIFARNIK_JEDINICA_MERE_OPIS: "Opis",
  SIFARNIK_JEDINICA_MERE_MESSAGE_CREATE_SUCCESS: "Jedinica mere je uspešno kreirana",
  SIFARNIK_JEDINICA_MERE_MESSAGE_UPDATE_SUCCESS: "Jedinica mere je uspešno izmenjena",
  SIFARNIK_JEDINICA_MERE_MESSAGE_DELETE_SUCCESS: "Jedinica mere je uspešno obrisana",
  SIFARNIK_JEDINICA_MERE_NAZIV_IS_REQUIRED: "Naziv jedinice mere je obavezan",
  SIFARNIK_JEDINICA_MERE_SIFRA_IS_REQUIRED: "Šifra jedinice mere je obavezna",
  SIFARNIK_JEDINICA_MERE_VAZI_OD_IS_REQUIRED: "Važi od jedinice mere je obavezan",

  // SIFARNIK PROIZVODA
  LABEL_SIFARNIK_PROIZVODA: "Proizvodi",
  SIFARNIK_PROIZVODA_NAZIV: "Naziv proizvoda",
  SIFARNIK_PROIZVODA_SIFRA: "Šifra proizvoda",
  SIFARNIK_PROIZVODA_RFZO_LABEL: "Proizvodi RFZO",
  SIFARNIK_PROIZVODA_NAZIV_ENGLESKI: "Engleski naziv",
  SIFARNIK_PROIZVODA_OPIS: "Opis",
  SIFARNIK_PROIZVODA_VAZI_DO: "Važi do",
  SIFARNIK_PROIZVODA_VAZI_OD: "Važi od",

  SEARCH_SIFARNIK_PROIZVODA: "Pretraga proizvoda",
  SIFARNIK_PROIZVODA_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv",
  SIFARNIK_PROIZVODA_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru",
  SIFARNIK_PROIZVODA_VAZI_OD_IS_REQUIRED: "Obavezno je uneti važi od",
  SIFARNIK_PROIZVODA_VAZI_OD_IS_BEFORE_VAZI_DO: "Važi od mora biti pre važi do",
  SIFARNIK_PROIZVODA_JEDINICA_MERE_IS_REQUIRED: "Obavezno je uneti jedinicu mere",
  SIFARNIK_PROIZVODA_TITLE_DIALOG_CREATE: "Kreiranje proizvoda ",
  SIFARNIK_PROIZVODA_TITLE_DIALOG_UPDATE: "Izmena proizvoda ",
  SIFARNIK_PROIZVODA_TITLE_DIALOG_DETAILS: "Detalji proizvoda ",
  SIFARNIK_PROIZVODA_TITLE_DIALOG_DELETE: "Brisanje proizvoda ",
  SIFARNIK_PROIZVODA_TITLE_MESSAGE_CREATE_SIFARNIK_PROIZVODA_SUCCESS: "Proizvod je uspešno kreiran",
  SIFARNIK_PROIZVODA_TITLE_MESSAGE_UPDATE_SIFARNIK_PROIZVODA_SUCCESS: "Proizvod je uspešno izmenjen",
  SIFARNIK_PROIZVODA_TITLE_MESSAGE_DELETE_SIFARNIK_PROIZVODA_SUCCESS: "Proizvod je uspešno obrisan",
  BUTTON_ADD_SIFARNIK_PROIZVODA: "Dodaj proizvod",
  SIFARNIK_PROIZVODA_NAZIV_MINIMUM_LENGTH: "Minimalna dužina naziva proizvoda je 3",
  SIFARNIK_PROIZVODA_SIFRA_MINIMUM_LENGTH: "Minimalna dužina šifre proizvoda je 3",

  SIFARNIK_PROIZVODA_CENE_LABEL: "Cene",
  SIFARNIK_PROIZVODA_DODAJ_CENU: "Dodaj cenu",
  SIFARNIK_PROIZVODA_IZMENI_CENU: "Izmena cene",
  SIFARNIK_PROIZVODA_OBRISI_CENU: "Brisanje cene",

  // SIFARNIK LEKOVA NA RECEPT
  LABEL_SIFARNIK_LEKOVA_NA_RECEPT: "Lekovi na recept",
  SIFARNIK_LEKOVA_NA_RECEPT_JKL: "JKL",
  SIFARNIK_LEKOVA_NA_RECEPT_NAZIV: "Fabričko ime leka",
  SIFARNIK_LEKOVA_NA_RECEPT_PROIZVODJAC: "Proizvođač",
  SIFARNIK_LEKOVA_NA_RECEPT_ATC_GRUPA: "ATC grupa",
  SIFARNIK_LEKOVA_NA_RECEPT_CENA: "Aktuelna cena",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_DETAILS: "Detalji leka",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY: "Istorija cene leka",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_CENA: "Cena",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_DOZVOLJENE_INDIKACIJE: "Dozvoljene indikacije leka",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_INDIKACIJE: "Indikacije",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_NAPOMENA: "Napomena",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_DODATNIH_INDIKACIJA: "Trenutno nema dodatnih indikacija za ovaj lek.",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_INDIKACIJA: "Trenutno nema indikacija za ovaj lek.",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_NAPOMENA: "Trenutno nema napomena za ovaj lek.",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_CENE: "Trenutno nema istorije cena za ovaj lek.",
  SIFARNIK_LEKOVA_NA_RECEPT_GENERICKI_NAZIV: "INN",
  SIFARNIK_LEKOVA_NA_RECEPT_OBLIK_LEKA: "Oblik leka",

  //SIFARNIK NACINA PLACANJA
  LABEL_SIFARNIK_NACINI_PLACANJA: "Načini plaćanja",
  LABEL_SIFARNIK_NACIN_PLACANJA: "Način plaćanja",
  SIFARNIK_NACINA_PLACANJA_DODAJ_NACIN_PLACANJA: "Dodaj način plaćanja",
  SIFARNIK_NACINA_PLACANJA_TITLE_CREATE: "Kreiranje načina plaćanja",
  SIFARNIK_NACINA_PLACANJA_NAZIV: "Naziv",
  SIFARNIK_NACINA_PLACANJA_SIFRA: "Šifra",
  SIFARNIK_NACINA_PLACANJA_PRIKAZNI_REDOSLED: "Prikazni redosled",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE: "Brzo plaćanje",
  SIFARNIK_NACINA_PLACANJA_TITLE_UPDATE: "Izmena načina plaćanja ",
  SIFARNIK_NACINA_PLACANJA_TITLE_DELETE: "Brisanje načina plaćanja ",
  SIFARNIK_NACINA_PLACANJA_MESSAGE_DELETE_SUCCESS: "Način plaćanja je uspešno obrisan.",
  SIFARNIK_NACINA_PLACANJA_MESSAGE_CREATE_SUCCESS: "Način plaćanja je uspešno kreiran.",
  SIFARNIK_NACINA_PLACANJA_MESSAGE_UPDATE_SUCCESS: "Način plaćanja je uspešno izmenjen.",
  SIFARNIK_NACINA_PLACANJA_VRSTA_UPLATIOCA: "Vrsta uplatioca",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_DIALOG_HEADER: "Potvrda brzog plaćanja",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_DIALOG_CONTENT: "Postoji već odabrani način plaćanja koji se koristi za brzo plaćanje, da li želite da brzo plaćanje bude: ",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_ACCEPT_DIALOG_CONTENT:
    "Da li želite da izvršite brzo plaćanje, koje podrazumeva da je jedini uplatilac pacijent, da plaća 100% od cene, nema popust i za način plaćanja koristi odabran način za brzo plaćanje?",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_ACCEPT_DIALOG_CONTENT_2: "Ukupan iznos:",
  SIFARNIK_NACINA_PLACANJA_UPLATIOCI_TOOLTIP_DESCRIPTION: "Prelazak na drugi korak naplate",
  SIFARNIK_NACINA_PLACANJA_RACUNI_TOOLTIP_DESCRIPTION: "Prelazak na treći korak naplate, podrazumevani uplatilac je pacijent, bez popusta",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_TOOLTIP_DESCRIPTION: "Podrazumevani uplatilac je pacijent, bez popusta uz predefinisani način naplate",

  //SIFARNIK KATEGORIJA POPUSTA
  LABEL_SIFARNIK_KATEGORIJE_POPUSTA: "Kategorije popusta",
  LABEL_SIFARNIK_KATEGORIJA_POPUSTA: "Kategorija popusta",
  SIFARNIK_KATEGORIJA_POPUSTA_DODAJ_KATEGORIJU_POPUSTA: "Dodaj kategoriju popusta",
  SIFARNIK_KATEGORIJA_POPUSTA_TITLE_CREATE: "Kreiranje kategorije popusta",
  SIFARNIK_KATEGORIJA_POPUSTA_NAZIV: "Naziv",
  SIFARNIK_KATEGORIJA_POPUSTA_SIFRA: "Šifra",
  SIFARNIK_KATEGORIJA_POPUSTA_PRIKAZNI_REDOSLED: "Prikazni redosled",
  SIFARNIK_KATEGORIJA_POPUSTA_TITLE_UPDATE: "Izmena kategorije popusta ",
  SIFARNIK_KATEGORIJA_POPUSTA_TITLE_DELETE: "Brisanje kategorije popusta ",
  SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_DELETE_SUCCESS: "Kategorija popusta je uspešno obrisana.",
  SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_CREATE_SUCCESS: "Kategorija popusta je uspešno kreirana.",
  SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_UPDATE_SUCCESS: "Kategorija popusta je uspešno izmenjena.",
  LABEL_SIFARNIK_KATEGORIJE_POPUSTA_TIP_KATEGORIJE: "Tip kategorije popusta",
  LABEL_SIFARNIK_KATEGORIJE_POPUSTA_NAMENA_KATEGORIJE: "Namena kategorije popusta",
  LABEL_SIFARNIK_KATEGORIJE_POPUSTA_POPUST: "Popust",
  LABEL_SIFARNIK_KATEGORIJE_FILTER_TIP_PLACEHOLDER: "Svi tipovi",
  LABEL_SIFARNIK_KATEGORIJE_FILTER_NAMENA_PLACEHOLDER: "Sve namene",
  LABEL_SIFARNIK_KATEGORIJA_POPUSTA_VALUTA: "Valuta",

  //PAKETI USLUGA
  LABEL_PAKETI_USLUGA: "Paketi usluga",
  PAKET_USLUGA_LIST: "Paketi usluga",
  PAKET_USLUGA_SIFRA: "Šifra paketa usluga",
  PAKET_USLUGA_NAZIV: "Naziv paketa usluga",
  PAKET_USLUGA_NAPOMENA: "Napomena paketa usluga",
  PAKET_USLUGA_TITLE_DIALOG_CREATE: "Kreiranje paketa usluga ",
  PAKET_USLUGA_TITLE_DIALOG_UPDATE: "Izmena paketa usluga ",
  PAKET_USLUGA_TITLE_DIALOG_DELETE: "Brisanje paketa usluga ",
  PAKET_USLUGA_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv.",
  PAKET_USLUGA_SEARCH: "Pretraga",
  SEARCH_PAKET_USLUGA: "Pretraga paketa usluga",
  BUTTON_ADD_PAKET_USLUGA: "Dodaj paket usluga",
  LABEL_PAKET_USLUGA: "Paket usluga",
  LABEL_OSNOVNI_PODACI: "Osnovni podaci",
  TOOLTIP_CREATE_PAKET_USLUGA: "Kreiranje paketa usluga",
  TOOLTIP_UPDATE_PAKET_USLUGA: "Izmena paketa usluga",
  TOOLTIP_DELETE_PAKET_USLUGA: "Brisanje paketa usluga",
  PAKET_USLUGA_TITLE_MESSAGE_CREATE_PAKET_SUCCESS: "Paket usluga je uspešno kreiran.",
  PAKET_USLUGA_TITLE_MESSAGE_UPDATE_PAKET_SUCCESS: "Paket usluga je uspešno izmenjen.",
  PAKET_USLUGA_TITLE_MESSAGE_DELETE_PAKET_SUCCESS: "Paket usluga je uspešno obrisan.",
  PAKET_USLUGA_ADD_USLUGA_SUCCESS: "Usluga je uspešno dodata.",
  PAKET_USLUGA_DELETE_USLUGA_SUCCESS: "Usluga je uspešno obrisana.",
  PAKET_USLUGA_UPDATE_USLUGA_SUCCESS: "Usluga je uspešno izmenjena.",
  PAKET_USLUGA_MANDATORY_FIELD: "Usluga je obavezno polje",
  PAKET_USLUGA_PODRAZUMEVANA_TEXT: "Usluga je podešena kao podrazumevana",
  PAKET_USLUGA_NE_PODRAZUMEVANA_TEXT: "Usluga nije podešena kao podrazumevana",
  PAKET_USLUGA_LABEL_USLUGA: "Usluga",
  PAKET_USLUGA_LABEL_PODESI: "Podesi kao podrazumevanu",
  PAKET_USLUGA_LABEL_PRIKAZANI_REDOSLED: "Prikazani redosled",
  PAKET_USLUGA_DIALOG_USLUGA_CREATE_TITLE: "Dodaj uslugu u okviru seta",
  PAKET_USLUGA_DIALOG_USLUGA_UPDATE_TITLE: "Izmeni uslugu u okviru seta",
  PAKET_USLUGA_DIALOG_USLUGA_DELETE_TITLE: "Obriši uslugu u okviru seta",
  PAKET_USLUGA_DIALOG_PROIZVOD_CREATE_TITLE: "Dodaj utrošak u okviru seta",
  PAKET_USLUGA_DIALOG_PROIZVOD_UPDATE_TITLE: "Izmeni utrošak u okviru seta",
  PAKET_USLUGA_DIALOG_PROIZVOD_DELETE_TITLE: "Obriši utrošak u okviru seta",
  PAKET_USLUGA_LABEL_UTROSAK: "Utrošak",
  PAKET_USLUGA_LABEL_PODRAZUMEVANA_KOLICINA: "Podrazumevana količina",
  PAKET_USLUGA_LABEL_PRIKAZNI_REDOSLED: "Prikazni redosled",
  PAKET_USLUGA_LABEL_NAPOMENA: "Napomena",
  PAKET_USLUGA_PROIZVOD_MANDATORY_FIELD: "Utrošak je obavezno polje.",
  PAKET_USLUGA_ADD_PROIZVOD_SUCCESS: "Utrošak je uspešno dodat.",
  PAKET_USLUGA_UPDATE_PROIZVOD_SUCCESS: "Utrošak je uspešno izmenjen.",
  PAKET_USLUGA_DELETE_PROIZVOD_SUCCESS: "Utrošak je uspešno obrisan.",
  PAKET_USLUGA_UNOS_USLUGE_PLACEHOLDER: "Unesite naziv ili šifru usluge",
  PAKET_USLUGA_UNOS_PROIZVODA_PLACEHOLDER: "Unesite naziv ili šifru proizvoda",

  PAKET_ORGANIZACIONA_JEDINICA_TAB: "Organizacione jedinice",
  PAKET_ORGANIZACIONA_JEDINICA_PLACEHOLDER: "Odaberite organizacionu jedinicu za koju želite da vežete paket",
  PAKET_ADD_ORGANIZACIONA_JEDINICA_SUCCESS: "Organizaciona jedinica je uspešno dodata.",
  PAKET_REMOVE_ORGANIZACIONA_JEDINICA_SUCCESS: "Organizaciona jedinica je uspešno izbačena.",
  TOOLTIP_CREATE_PAKET_ORGANIZACIONA_JEDINICA: "Veži organizacionu jedinicu za paket",
  TOOLTIP_DELETE_PAKET_ORGANIZACIONA_JEDINICA: "Izbaci organizacionu jedinicu sa paketa",

  LOG_POZIVA_WS: "Log poziva WS",
  LOG_METADATA_HOSTNAME: "Metadata hostname",
  LOG_METADATA_PORT: "Metadata port",
  LOG_REQUEST_TIMESTAMP: "Request timestamp",
  LOG_REQUEST_CLIENT_IP: "Request client IP",
  LOG_REQUEST_METHOD: "Request method",
  LOG_REQUEST_WS_ENDPOINT: "Request WS endpoint",
  LOG_RESPONSE_TIMESTAMP: "Response timestamp",
  LOG_RESPONSE_METHOD: "Response method",
  LABEL_TOOLTIP_LOG_SEARCH: "Pretraga loga",

  LOG_REQUEST_X_FORWARDED: "Request X forwarded for",
  LOG_REQUEST_QUERY_STRING: "Request query string",
  LOG_REQUEST_AUTHORIZATION: "Request authorization",
  LOG_REQUEST_USER_AGENT: "Request user agent",
  LOG_REQUEST_BODY: "Request body",
  LOG_RESPONSE_STATUS_CODE: "Response status code",
  LOG_RESPONSE_BODY: "Response body",

  LOG_HIGH_LEVEL_LIST: "Pregled log-a",
  LOG_HIGH_LEVEL_ID_RADNIKA: "Id radnika",
  LOG_HIGH_LEVEL_ID_PACIJENTA: "Id pacijenta",
  LOG_HIGH_LEVEL_RADNIK: "Radnik",
  LOG_HIGH_LEVEL_PACIJENT: "Pacijent",
  LOG_HIGH_LEVEL_VREME_AKCIJE: "Vreme akcije",
  LOG_HIGH_LEVEL_OPIS: "Opis",

  SIFARNIK_MKB: "Šifarnik MKB",
  BUTTON_IMPORT_SIFARNIK_MKB: "Učitaj šifarnik",
  TOOLTIP_IMPORT_SIFARNIK_MKB: "Učitaj šifarnik MKB",
  SEARCH_SIFARNIK_MKB: "Pretraga šifarnika MKB",
  SIFARNIK_MKB_NAZIV: "Naziv dijagnoze",
  SIFARNIK_MKB_SIFRA: "Šifra dijagnoze",
  IMPORT_SIFARNIK_MKB_SUCCESS: "Šifarnik je uspešno učitan.",

  SIFARNIK_ATRIBUTA_RFZO: "Šifarnik atributa RFZO",
  BUTTON_IMPORT_SIFARNIK_ATRIBUTA_RFZO: "Učitaj šifarnik",
  TOOLTIP_IMPORT_SIFARNIK_ATRIBUTA_RFZO: "Učitaj šifarnik atributa RFZO",
  SEARCH_SIFARNIK_ATRIBUTA_RFZO: "Pretraga šifarnika atributa RFZO",
  SIFARNIK_ATRIBUTA_RFZO_NAZIV: "Naziv atributa",
  SIFARNIK_ATRIBUTA_RFZO_SIFRA: "Šifra atributa",
  IMPORT_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: "Šifarnik je uspešno učitan.",
  LABEL_SIFARNIK_ATRIBUTA_RFZO: "Atributi RFZO",
  SIFARNIK_ATRIBUTA_RFZO_OPIS: "Opis",
  SIFARNIK_ATRIBUTA_RFZO_FAKTOR_UVECANJA_CENE: "Faktor uvećanja cene",
  SIFARNIK_ATRIBUTA_RFZO_OZNAKA: "Oznaka",
  SIFARNIK_ATRIBUTA_RFZO_ID_ATRIBUTA: "Id atributa",
  SIFARNIK_ATRIBUTA_RFZO_VAZI_DO: "Važi do",
  SIFARNIK_ATRIBUTA_RFZO_VAZI_OD: "Važi od",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_CREATE: "Kreiranje atributa ",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_UPDATE: "Izmena atributa ",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_DETAILS: "Detalji atributa ",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_DELETE: "Brisanje atributa ",
  SIFARNIK_ATRIBUTA_RFZO_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv",
  SIFARNIK_ATRIBUTA_RFZO_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru",
  SIFARNIK_ATRIBUTA_RFZO_STATUS_IS_REQUIRED: "Obavezno je uneti status",
  SIFARNIK_ATRIBUTA_RFZO_FAKTOR_UVECANJA_IS_REQUIRED: "Obavezno je uneti faktor uvećanja",
  SIFARNIK_ATRIBUTA_RFZO_STATUS: "Status",
  SIFARNIK_ATRIBUTA_RFZO_VAZI_OD_IS_REQUIRED: "Obavezno je uneti važi od",
  SIFARNIK_ATRIBUTA_RFZO_VAZI_DO_IS_REQUIRED: "Obavezno je uneti važi do",
  SIFARNIK_ATRIBUTA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO: "Važi od mora biti pre važi do",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: "Atribut je uspešno kreiran",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: "Atribut je uspešno izmenjen",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: "Atribut je uspešno obrisan",

  SIFARNIK_PROIZVODA_RFZO: "Šifarnik proizvoda RFZO",
  BUTTON_IMPORT_SIFARNIK_PROIZVODA_RFZO: "Učitaj šifarnik",
  TOOLTIP_IMPORT_SIFARNIK_PROIZVODA_RFZO: "Učitaj šifarnik proizvoda RFZO",
  SEARCH_SIFARNIK_PROIZVODA_RFZO: "Pretraga šifarnika proizvoda RFZO",
  SIFARNIK_PROIZVODA_RFZO_NAZIV: "Naziv proizvoda",
  SIFARNIK_PROIZVODA_RFZO_SIFRA: "Šifra proizvoda",
  IMPORT_SIFARNIK_PROIZVODA_RFZO_SUCCESS: "Šifarnik je uspešno učitan.",
  LABEL_SIFARNIK_PROIZVODA_RFZO: "Proizvodi RFZO",
  SIFARNIK_PROIZVODA_RFZO_OPIS: "Opis",
  SIFARNIK_PROIZVODA_RFZO_TIP: "Tip",
  SIFARNIK_PROIZVODA_RFZO_OZNAKA: "Oznaka",
  SIFARNIK_PROIZVODA_RFZO_NAZIV_JEDINICE_MERE: "Naziv jedinice mere",
  SIFARNIK_PROIZVODA_RFZO_VAZI_DO: "Važi do",
  SIFARNIK_PROIZVODA_RFZO_VAZI_OD: "Važi od",
  SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_CREATE: "Kreiranje proizvoda ",
  SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_UPDATE: "Izmena proizvoda ",
  SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DETAILS: "Detalji proizvoda ",
  SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DELETE: "Brisanje proizvoda ",
  SIFARNIK_PROIZVODA_RFZO_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv",
  SIFARNIK_PROIZVODA_RFZO_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru",
  SIFARNIK_PROIZVODA_RFZO_STATUS: "Status",
  SIFARNIK_PROIZVODA_RFZO_VAZI_OD_IS_REQUIRED: "Obavezno je uneti važi od",
  SIFARNIK_PROIZVODA_RFZO_VAZI_DO_IS_REQUIRED: "Obavezno je uneti važi do",
  SIFARNIK_PROIZVODA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO: "Važi od mora biti pre važi do",
  SIFARNIK_PROIZVODA_RFZO_STATUS_IS_REQUIRED: "Obavezno je uneti status proizvoda",
  SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_PROIZVODA_RFZO_SUCCESS: "Proizvod je uspešno kreiran",
  SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_PROIZVODA_RFZO_SUCCESS: "Proizvod je uspešno izmenjen",
  SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_PROIZVODA_RFZO_SUCCESS: "Proizvod je uspešno obrisan",

  SIFARNIK_USLUGA_RFZO: "Šifarnik usluga RFZO",
  BUTTON_IMPORT_SIFARNIK_USLUGA_RFZO: "Učitaj šifarnik",
  TOOLTIP_IMPORT_SIFARNIK_USLUGA_RFZO: "Učitaj šifarnik usluga RFZO",
  SEARCH_SIFARNIK_USLUGA_RFZO: "Pretraga šifarnika usluga RFZO",
  SIFARNIK_USLUGA_RFZO_NAZIV: "Naziv usluga",
  SIFARNIK_USLUGA_RFZO_SIFRA: "Šifra usluga",
  IMPORT_SIFARNIK_USLUGA_RFZO_SUCCESS: "Šifarnik je uspešno učitan.",
  LABEL_SIFARNIK_USLUGA_RFZO: "Usluge RFZO",
  SIFARNIK_USLUGA_RFZO_OPIS: "Opis",
  SIFARNIK_USLUGA_RFZO_MOZE_IMATI_UTROSAK: "Može imati utrošak",
  SIFARNIK_USLUGA_RFZO_VAZI_DO: "Važi do",
  SIFARNIK_USLUGA_RFZO_VAZI_OD: "Važi od",
  SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_CREATE: "Kreiranje usluge ",
  SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_UPDATE: "Izmena usluge ",
  SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DETAILS: "Detalji usluge ",
  SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DELETE: "Brisanje usluge ",
  SIFARNIK_USLUGA_RFZO_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv",
  SIFARNIK_USLUGA_RFZO_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru",
  SIFARNIK_USLUGA_RFZO_STATUS: "Status",
  SIFARNIK_USLUGA_RFZO_VAZI_OD_IS_REQUIRED: "Obavezno je uneti važi od",
  SIFARNIK_USLUGA_RFZO_VAZI_DO_IS_REQUIRED: "Obavezno je uneti važi do",
  SIFARNIK_USLUGA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO: "Važi od mora biti pre važi do",
  SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_RFZO_SUCCESS: "Usluga je uspešno kreirana",
  SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_RFZO_SUCCESS: "Usluga je uspešno izmenjena",
  SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_RFZO_SUCCESS: "Usluga je uspešno obrisana",

  SIFARNIK_STATUS_IZBOR_FILTERA: "Izaberite status",

  OBAVESTENJE_LIST: "Obaveštenja",
  OBAVESTENJE_TITLE_DIALOG_CREATE: "Kreiranje obaveštenja ",
  OBAVESTENJE_TITLE_DIALOG_UPDATE: "Izmena obaveštenja ",
  OBAVESTENJE_TITLE_DIALOG_DELETE: "Brisanje obaveštenja ",
  OBAVESTENJE_TITLE_DIALOG_VIEW: "Pregled obaveštenja",
  OBAVESTENJE_TITLE_DIALOG_DETAILS: "Detalji obaveštenja",
  SEARCH_OBAVESTENJE: "Pretraga obaveštenja",
  OBAVESTENJE_SEARCH: "Pretraga",
  OBAVESTENJE_NASLOV: "Naslov",
  OBAVESTENJE_SADRZAJ: "Sadržaj",
  OBAVESTENJE_VAZI_DO: "Važi do",
  OBAVESTENJE_ULOGA: "Obaveštenje za uloge",
  OBAVESTENJE_TITLE_MESSAGE_DELETE_OBAVESTENJE_SUCCESS: "Obaveštenje je uspešno obrisano",
  OBAVESTENJE_TITLE_MESSAGE_CREATE_OBAVESTENJE_SUCCESS: "Obaveštenje je uspešno kreirano",
  OBAVESTENJE_TITLE_MESSAGE_CREATE_OBAVESTENJE_DATOTEKA_SUCCESS: "Datoteke obavestenja su uspešno kreirane",
  OBAVESTENJE_TITLE_MESSAGE_UPDATE_OBAVESTENJE_SUCCESS: "Obaveštenje je uspešno ažurirano",
  OBAVESTENJE_ORGANIZACIONA_JEDINICA: "Obaveštenje za organizacione jedinice",
  OBAVESTENJE_COPY_TITLE_DIALOG: "Obaveštenje sa istim podešavanjima",
  OBAVESTENJE_NASLOV_IS_REQUIRED: "Obavezan je naslov obaveštenja",
  OBAVESTENJE_SADRZAJ_IS_REQUIRED: "Obavezan je sadržaj obaveštenja",
  CRUD_OBAVESTENJE: "OBAVEŠTENJA",
  OBAVESTENJE_PROCITANO: "Pročitano",
  OBAVESTENJE_NEPROCITANO: "Nepročitano",
  OBAVESTENJE_NEMA_PROCITANIH: "Nema pročitanih obaveštenja",
  OBAVESTENJE_NEMA_NEPROCITANIH: "Nema nepročitanih obaveštenja",
  OBAVESTENJE_OZNACI_SVE_PROCITANO: "Označi sve pročitano",
  OBAVESTENJE_SVE_USPESNO_PROCITANO: "Sva obaveštenja su naznačena kao pročitana",
  OBAVESTENJE_KREIRANO: "kreirano:",
  OBAVESTENJE_DATOTEKA_ID_IS_REQUIRED: "Obavezan je identifikator obaveštenja",
  OBAVESTENJE_DATOTAKA_CREATE_SUCCESS: "Datoteka obaveštenja je uspešno kreirana",
  OBAVESTENJE_DATOTEKE: "Datoteke",
  OBAVESTENJE_DATOTEKE_IZABERI: "Izaberi datoteke",
  OBAVESTENJE_DATOTEKE_SACUVAJ: "Sačuvaj",
  OBAVESTENJE_DATOTEKE_PONISTI: "Poništi izbor",

  EMPTY_IZVESTAJI_KATEGORIJA: "Ne postoji nijedan izveštaj u odabranoj kategoriji.",
  BUTTON_GENERISI_IZVESTAJ: "Generiši izveštaj",
  IZVESTAJ_LIST: "Izveštaji",
  SWITCH_LABEL: "Prikaži samo svoje izveštaje",
  DODATNI_MODULI: "Dodatni moduli",
  LICENCA_LIST: "Licence",
  STATISTICKI_IZVESTAJ_LIST: "Statistički izveštaji",
  KATEGORIJA_IZVESTAJA_LIST: "Kategorije izveštaja",
  BUTTON_ADD_KATEGORIJA: "Dodaj kategoriju",
  TOOLTIP_ADD_KATEGORIJA: "Kreiranje kategorije statističkog izveštaja",
  SEARCH_KATEGORIJA: "Pretraga kategorije izveštaja",
  KATEGORIJA_SEARCH: "Pretraga",
  KATEGORIJA_NAZIV: "Naziv",
  KATEGORIJA_SIFRA: "Šifra",
  KATEGORIJA_TITLE_DIALOG_CREATE: "Kreiranje kategorije ",
  KATEGORIJA_TITLE_DIALOG_UPDATE: "Izmena kategorije ",
  KATEGORIJA_TITLE_DIALOG_DELETE: "Brisanje kategorije ",
  CRUD_KATEGORIJA_IZVESTAJ: "IZVEŠTAJI",
  LABEL_KATEGORIJA_STATISTICKOG_IZVESTAJA: "Kategorija statističkog izveštaja",
  LABEL_KATEGORIJA_IZVESTAJA_CREATE: "Kreiranje kategorije izveštaja",
  LABEL_KATEGORIJA_IZVESTAJA_UPDATE: "Izmena kategorije izveštaja",
  LABEL_KATEGORIJA_IZVESTAJA_DELETE: "Brisanje kategorije izveštaja",
  LABEL_IZVESTAJ_PARAMETAR: "Parametar izvestaja",
  KATEGORIJA_NAZIV_IS_REQUIRED: "Obavezan je naziv kategorije statističkog izveštaja",
  KATEGORIJA_SIFRA_IS_REQUIRED: "Obavezna je šifra kategorije statističkog izveštaja",
  KATEGORIJA_TITLE_MESSAGE_CREATE_KATEGORIJA_SUCCESS: "Kategorija statističkog izveštaja je uspešno kreirana",
  KATEGORIJA_TITLE_MESSAGE_UPDATE_KATEGORIJA_SUCCESS: "Kategorija statističkog izveštaja je uspešno izmenjena",
  KATEGORIJA_TITLE_MESSAGE_DELETE_KATEGORIJA_SUCCESS: "Kategorija statističkog izveštaja je uspešno obrisana",
  IZVESTAJ_NAZIV: "Naziv",
  IZVESTAJ_LABEL: "Izaberite izveštaj",
  IZVESTAJ_SIFRA: "Šifra",
  IZVESTAJ_JASPER: "Jasper",
  IZVESTAJ_TIP_GENERISANJA: "Tip generisanja",
  IZVESTAJ_FORMAT: "Format",
  IZVESTAJ_OPIS: "Opis",
  IZVESTAJ_SVE_KATEGORIJE: "Sve kategorije",
  IZVESTAJ_PRIKAZUJE_SE: "Prikazuje se",
  BUTTON_ADD_IZVESTAJ: "Dodaj izveštaj",
  TOOLTIP_ADD_IZVESTAJ: "Kreiranje statističkog izveštaja",
  SEARCH_IZVESTAJ: "Pretraga statističkog izveštaja",
  IZVESTAJ_SEARCH: "Pretraga",
  IZVESTAJ_TITLE_DIALOG_CREATE: "Kreiranje izveštaja",
  IZVESTAJ_TITLE_DIALOG_UPDATE: "Izmena izveštaja",
  IZVESTAJ_TITLE_DIALOG_DELETE: "Brisanje izveštaja",
  IZVESTAJ_PARAMETRI: "Parametri izveštaja",
  IZVESTAJ_PARAMETRI_CREATE: "Kreiranje paremetara izveštaja ",
  IZVESTAJ_PARAMETRI_UPDATE: "Izmena parametara izveštaja ",
  IZVESTAJ_PARAMETRI_DELETE: "Brisanje parametara izveštaja ",
  IZVESTAJ_PARAMETRI_LIST: "Lista parametara izveštaja ",
  CRUD_IZVESTAJ_PARAMETAR: "PARAMETRI IZVEŠTAJA",
  IZVESTAJ_MESSAGE_CREATE_SUCCESS: "Statistički izveštaj je uspešno kreiran",
  IZVESTAJ_MESSAGE_UPDATE_SUCCESS: "Statistički izveštaj je uspešno izmenjen",
  IZVESTAJ_MESSAGE_DELETE_SUCCESS: "Statistički izveštaj je uspešno izbrisan",
  IZVESTAJ_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv",
  IZVESTAJ_KATEGORIJA_IS_REQUIRED: "Obavezno je izabrati kategoriju",
  IZVESTAJ_JASPER_IS_REQUIRED: "Obavezno je uneti jasper",
  IZVESTAJ_FORMAT_IS_REQUIRED: "Obavezno je izabrati makar jedan format",
  IZVESTAJ_CHOOSE_JASPER: "Izaberite jasper",
  IZVESTAJ_REMOVE_JASPER: "Izbacite jasper",
  CRUD_IZVESTAJ: "IZVEŠTAJI",
  IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DELETE_SUCCESS: "Parametar statističkog izveštaja je uspešno obrisan",
  IZVESTAJ_PARAMETAR_TIP: "Tip parametra",
  IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DIALOG_UPDATE: "Parametar statističkog izveštaja je uspešno izmenjen",
  IZVESTAJ_PARAMETAR_TITLE_DIALOG_CREATE: "Parametar statističkog izveštaja je uspešno kreiran",
  IZVESTAJ_PARAMETAR_SQL_UPIT: "SQL upit",
  IZVESTAJ_PARAMETAR_TITLE_DIALOG_UPDATE: "Izmena parametra ",
  KATEGORIJE_STATISTICKIH_IZVESTAJA: "Kategorije statističkih izveštaja",
  POTVRDI: "Potvrdi",
  OTVORITE_NESACUVAN_NALAZ: "otvorite nesačuvani nalaz",
  NASTAVITE_BEZ_CUVANJA_NALAZA: "nastavite bez čuvanja nalaza",
  ZA_PACIJENTA: "Za pacijenta",
  POSTOJI_NESACUVAN_NALAZ: "postoji nesačuvan nalaz",
  DA_LI_ZELITE_DA: "Da li želite da",
  SACUVAJ_I_STAMPAJ: "Sačuvaj i štampaj",

  RADNO_VREME_OD_NOT_EXISTS: "Radno vreme od je obavezno ukoliko ste uneli radno vreme do.",
  RADNO_VREME_DO_NOT_EXISTS: "Radno vreme do je obavezno ukoliko ste uneli radno vreme od.",
  RADNO_VREME_NOT_VALID: "Radno vreme od mora biti pre radnog vremena do!",

  INICIJALNA_VREDNOST: "Inicijalna vrednost",
  TIP_OSIGURANJA_FILTER: "Izaberite tip",
  KREIRANJE_OSIGURANJA: "Kreiranje osiguranja",
  IZMENA_OSIGURANJA: "Izmena osiguranja",
  BRISANJE_OSIGURANJA: "Brisanje osiguranja",
  DODAJ_OSIGURANJE: "Dodaj osiguranje",
  OSIGURANJE_TIP_IS_REQUIRED: "Tip osiguranja je obavezno polje",
  VAZI_OD_IS_REQUIRED: "Važi od je obavezno polje",
  OSIGURANJE_VAZI_DO_AFTER_VAZI_OD: "Važi do datum mora biti posle Važi od datuma",
  CENA: "Cena",

  //LICENCE

  LICENCNI_MODEL_TITLE_DIALOG_UPDATE: "Izmena licencnog modela",
  LICENCNI_MODEL_TITLE_DIALOG_CREATE: "Kreiranje licencnog modela",
  LICENCNI_MODEL_TITLE_DIALOG_DELETE: "Brisanje licencnog modela",
  LICENCNI_MODEL_MESSAGE_CREATE_SUCCESS: "Uspešno kreiran licencni model",
  LICENCNI_MODEL_MESSAGE_UPDATE_SUCCESS: "Uspešno ažuriran licencni model",
  LICENCNI_MODEL_MESSAGE_DELETE_SUCCESS: "Uspešno obrisan licencni model",
  LABEL_LICENCNI_MODEL: "Licencni model",

  DODATNI_MODUL: "Dodatni modul",
  DODATNI_MODUL_BUTTON_DODAJ: "Dodaj dodatni modul",
  DODATNI_MODUL_TITLE_CREATE: "Kreiranje dodatnog modula ",
  DODATNI_MODUL_TITLE_UPDATE: "Izmena dodatnog modula ",
  DODATNI_MODUL_TITLE_DETAILS: "Detalji dodatnog modula ",
  DODATNI_MODUL_TITLE_DELETE: "Brisanje dodatnog modula ",
  DODATNI_MODUL_NAZIV: "Naziv dodatnog modula",
  DODATNI_MODUL_SIFRA: "Šifra dodatnog modula",
  DODATNI_MODUL_OPIS: "Opis",
  DODATNI_MODUL_IKONICA: "Ikonica",
  DODATNI_MODUL_IKONICA_DELETE: "Da li ste sigurni da želite da obrišete ikonicu?",
  DODATNI_MODUL_MESSAGE_CREATE_SUCCESS: "Dodatni modul je uspešno kreiran",
  DODATNI_MODUL_MESSAGE_UPDATE_SUCCESS: "Dodatni modul je uspešno izmenjen",
  DODATNI_MODUL_MESSAGE_DELETE_SUCCESS: "Dodatni modul je uspešno izbrisan",
  DODATNI_MODUL_NAZIV_IS_REQUIRED: "Naziv dodatnog modula je obavezan",
  DODATNI_MODUL_SIFRA_IS_REQUIRED: "Šifra dodatnog modula je obavezna",
  DODATNI_MODUL_VREME_DO_AFTER_VREME_OD: "Vreme do dodatnog modula mora biti posle vremena od",
  MODUL: "Modul",
  MODUL_IZMENA: "Izmena modula",
  MODUL_BRISANJE: "Brisanje modula",
  MODUL_TITLE_DIALOG_CREATE: "Kreiranje modula",
  MODUL_LICENCNI_MODUL: "Licencni modul",
  SEARCH_DODATNI_MODUL: "Pretraga dodatnog mogula",
  DODATNI_MODUL_SEARCH: "Pretraga",

  STORAGE_LIMIT: "Ograničenje skladišta",
  STORAGE_LIMIT_TITLE_UPDATE: "Izmena ogranicenja skladišta ",
  STORAGE_LIMIT_TITLE_DETAILS: "Detalji ograničeja skladišta ",
  STORAGE_LIMIT_TITLE_DELETE: "Brisanje ograničenja skladišta ",
  STORAGE_LIMIT_TITLE_CREATE: "Kreiranje ograničenja skladišta",
  STORAGE_LIMIT_NAZIV: "Naziv ograničenja skladišta ",
  STORAGE_LIMIT_SIFRA: "Šifra ograničenja skladišta ",
  STORAGE_LIMIT_PROSTOR: "Prostor ograničenja skladišta",
  STORAGE_LIMIT_MESSAGE_CREATE_SUCCESS: "Ograničenje skladišta je uspešno kreirano ",
  STORAGE_LIMIT_UPDATE_SUCCESS: "Ograničenje skladišta je uspešno izmenjeno ",
  STORAGE_LIMIT_MESSAGE_DELETE_SUCCESS: "Ograničenje skladišta je uspešno izbrisano ",
  STORAGE_LIMIT_NAZIV_IS_REQUIRED: "Naziv ograničenja skladišta је obavezno ",
  STORAGE_LIMIT_SIFRA_IS_REQUIRED: "Šifra ograničenja skladišta је obavezno ",
  STORAGE_LIMIT_PROSTOR_IS_REQUIRED: "Prostor je obavezno polje, koje mora biti veće od 0",
  STORAGE_LIMIT_JEDINICA_IS_REQUIRED: "Jedinica količine podatka je obavezno polje",
  STORAGE_LIMIT_CENA_IS_REQUIRED: "Cena je obavezno polje, koje ne sme biti negativno",
  STORAGE_LIMIT_BUTTON_DODAJ: "Dodaj ograničenje skladišta ",
  LICENCA_USTANOVA_STORAGE_LIMIT_LIST: "Lista ograničenje skladišta",
  USTANOVA_STORAGE_LIMIT_NAZIV_IS_REQUIRED: "Naziv ogranicenja skladišta ustanove je obavezan",
  USTANOVA_STORAGE_LIMIT_MESSAGE_UPDATE_SUCCESS: "Ograničenje skladišta je uspešno izmenjeno",
  USTANOVA_STORAGE_LIMIT_VAZI_OD_IS_REQUIRED: "Datum početka važenja (važi od) ograničenja skladišta je obavezan podatak",
  USTANOVA_STORAGE_LIMIT: "Ograničenje skladišta ustanove",
  USTANOVA_STORAGE_LIMIT_TITLE_DELETE: "Brisanje ograničenja skladišta ustanove",
  USTANOVA_STORAGE_LIMIT_IS_REQUIRED: "Ograničenje skladišta ustanove je obavezan podatak",
  SEARCH_STORAGE_LIMIT: "Pretraga ograničenja skladišta",
  STORAGE_LIMIT_SEARCH: "Pretraga",

  BROJ_NALOGA: "Broj naloga",
  BROJ_NALOGA_BUTTON_DODAJ: "Dodaj broj naloga",
  BROJ_NALOGA_TITLE_CREATE: "Kreiranje broja naloga",
  BROJ_NALOGA_TITLE_UPDATE: "Izmena broja naloga ",
  BROJ_NALOGA_TITLE_DELETE: "Brisanje broja naloga ",
  SEARCH_BROJ_NALOGA: "Pretraga broja naloga",
  BROJ_NALOGA_SEARCH: "Pretraga",
  BROJ_NALOGA_TITLE_DETAILS: "Detalji broja naloga",
  BROJ_NALOGA_NAZIV: "Naziv broja naloga",
  BROJ_NALOGA_SIFRA: "Šifra broja naloga",
  BROJ_NALOGA_UKUPNO: "Ukupno naloga",
  BROJ_NALOGA_MESSAGE_CREATE_SUCCESS: "Broj naloga je uspešno kreiran",
  BROJ_NALOGA_MESSAGE_UPDATE_SUCCESS: "Broj naloga je uspešno izmenjen",
  BROJ_NALOGA_MESSAGE_DELETE_SUCCESS: "Broj naloga je uspešno izbrisan",
  BROJ_NALOGA_NAZIV_IS_REQUIRED: "Naziv broja naloga je obavezan",
  BROJ_NALOGA_SIFRA_IS_REQUIRED: "Šifra broja naloga je obavezna",
  BROJ_NALOGA_BROJ_IS_REQUIRED: "Ukupan broj naloga je obavezan",
  BROJ_NALOGA_BROJ_VALUE_NOT_VALID: "Broj naloga mora biti veći od 0",

  // PROTOKOL

  PROTOKOL_LIST: "Delovodne knjige",
  PROTOKOL_ADMINISTRIRANJE: "Administriranje delovodnih knjiga",
  PROTOKOL_PREFIKS: "Prefiks",
  PROTOKOL_NAZIV: "Naziv",
  PROTOKOL_VREMENSKI_OKVIR: "Vremenski okvir",
  PROTOKOL_POCETNI_BROJ: "Početni broj",
  PROTOKOL_NAREDNI_BROJ: "Naredni broj",
  BUTTON_ADD_PROTOKOL: "Dodaj protokol",
  PROTOKOL_TITLE_DIALOG_CREATE: "Kreiranje protokola",
  SEARCH_PROTOKOL: "Pretraga protokola",
  PROTOKOL_SEARCH: "Pretraga",
  PROTOKOL_TITLE_DIALOG_UPDATE: "Izmena protokola",
  PROTOKOL_TITLE_DIALOG_DELETE: "Brisanje protokola",
  CRUD_PROTOKOL: "Delovodne knjige",
  PROTOKOL_TITLE_MESSAGE_CREATE_PROTOKOL_SUCCESS: "Protokol je uspešno kreiran",
  PROTOKOL_TITLE_MESSAGE_DELETE_PROTOKOL_SUCCESS: "Protokol je uspešno obrisan",
  PROTOKOL_TITLE_MESSAGE_UPDATE_PROTOKOL_SUCCESS: "Protokol je uspešno izmenjen",
  PROTOKOL_PREFIKS_IS_REQUIRED: "Prefiks protokola je obavezan",
  PROTOKOL_NAZIV_IS_REQUIRED: "Naziv protokola je obavezan",
  PROTOKOL_VREMENSKI_OKVIR_IS_REQUIRED: "Vremenski okvir protokola je obavezan",
  PROTOKOL_POCETNI_BROJ_IS_REQUIRED: "Početni broj protokola je obavezan i mora biti veći od 0",
  PROTOKOL_NAREDNI_BROJ_IS_REQUIRED: "Naredni broj protokola je obavezan i mora biti veći od 0",
  PROTOKOL_ADMINISTRACIJA_BROJEVA: "Administracija brojeva",
  PROTOKOL_BROJ_BRISANJE: "Oslobodi broj protokola",
  PROTOKOL_BROJ: "Broj",
  PROTOKOL_BROJ_TITLE_MESSAGE_DELETE_PROTOKOL_BROJ_SUCCESS: "Broj protokola je uspešno obrisan",
  PROTOKOL_BROJ_PROTOKOL_REQUIRED: "Protokol je obavezan",
  PROTOKOL_BROJ_BROJEVI: "Brojevi",
  PROTOKOL_SELECT: "Izaberite protokol",
  PROTOKOL_OBRAZAC_STAMPE: "Obrazac štampe",
  PROTOKOL_ADD: "Dodaj",
  PROTOKOL_ADD_FROM_REPOSITORY: "Dodaj sa repozitorijuma",
  PROTOKOL_ADD_FROM_LOCAL: "Dodaj lokalnu datoteku",
  PROTOKOL_OBRAZAC_STAMPE_OPIS: "Opis obrasca štampe",
  LABELS_STAMPA_PROTOKOLA: "Štampa protokola",

  // GENERISANI IZVESTAJI
  GENERISANJE_IZVESTAJA: "Generisanje izveštaja",
  GENERISANI_IZVESTAJ_LIST: "Generisani izveštaji",
  GENERISANI_PARAMETRI: "Parametri",
  CRUD_GENERISANI_IZVESTAJ: "Generisani izveštaj",
  GENERISANI_GENERISANI_IZVESTAJI: "Generisani izveštaji",
  GENERISANI_IZVESTAJI_DELETE: "Brisanje generisanog izveštaja",
  GENERISANI_IZVESTAJ_NAZIV: "Naziv",
  GENERISANI_IZVESTAJ_OPIS: "Opis",
  GENERISANI_IZVESTAJ_FILENAME: "Naziv fajla",
  GENERISANI_IZVESTAJ_FILESIZE: "Veličina fajla",
  GENERISANI_IZVESTAJ_FILETYPE: "Tip fajla",
  GENERISANI_IZVESTAJ_VREME_KREIRANJA: "Vreme kreiranja",
  GENERISANI_IZVESTAJ_MESSAGE_DELETE_SUCCESS: "Generisani izveštaj uspešno obrisan.",
  GEN_IZVESTAJ_AKCIJE: "Akcije",
  GEN_IZVESTAJ_VREME: "Vreme",
  GEN_IZVESTAJ_NAZIV: "Naziv",
  GEN_IZVESTAJ_TITLE_DIALOG_PRINT: "Štampanje izveštaja",
  GEN_IZVESTAJ_TITLE_DIALOG_DETALJI: "Detalji izveštaja",
  GEN_IZVESTAJ_TITLE_DIALOG_DELETE: "Brisanje izveštaja",
  GEN_IZVESTAJ_DETALJI: "Detalji izveštaja",
  GEN_IZVESTAJ_PARAMETRI: "Parametri",
  GENERISANJE_IZVESTAJA_CONNECTION_ERROR: "Došlo je do greške prilikom uspostavljanja konekcije sa serverom",
  GENERISANJE_IZVESTAJA_MESSAGE_CREATE_SUCCESS: "Uspešno ste inicirali generisanje izveštaja.",
  GENERISANJE_IZVESTAJA_CLOSE_CONNECTION: "Zatvori konekciju.",
  SVI_GENERISANI_IZVESTAJI: "Svi generisani izveštaji",
  SVI_GENERISANI_IZVESTAJI_STAT_IZVESTAJ: " vezani za statistički izveštaj ",
  GEN_IZVESTAJ_EMAIL: "Imejl",
  PONAVLJANJE_GENERISANJA_IZVESTAJI: "Ponavljanje generisanja",
  PONAVLJANJE_GENERISANJA_JEDNOSTRUKO: "Jednostruko",
  PONAVLJANJE_GENERISANJA_VISESTRUKO: "Višestruko",
  DATUM_GENERISANJA_IZVESTAJA: "Datum generisanja izveštaja",
  VREME_GENERISANJA_IZVESTAJA: "Vreme generisanja izveštaja",
  UCESTALOST_PONAVLJANJA: "Učestalost ponavljanja",
  UCESTALOST_PONAVLJANJA_DAN: "Na nivou dana",
  UCESTALOST_PONAVLJANJA_NEDELJA: "Na nivou nedelje",
  UCESTALOST_PONAVLJANJA_MESEC: "Na nivou meseca",
  IZVESTAJ_SLATI: "Izveštaj slati",
  DAN_U_MESECU: "Dan u mesecu",
  ZAKAZIVANI_IZVESTAJI: "Zakazivani izveštaji",
  GENERISANI_IZVESTAJI_TITLE: "Svi generisani izveštaji vezani za statistički izveštaj evidencija uputa u laboratoriju",
  ZAKAZANI_IZVESTAJI_TITLE: "Svi zakazani izveštaji vezani za statistički izveštaj evidencija uputa u laboratoriju",
  PERIOD_PONAVLJANJA: "Period ponavljanja",
  GEN_IZVESTAJ_EMAIL_POSTOJI: "Upisani imejl je već dodat",
  BUTTON_ZAKAZI_IZVESTAJ: "Zakaži izveštaj",
  BUTTON_IZMENI_IZVESTAJ: "Izmeni izveštaj",
  LABEL_NA_NIVOU_DANA: " - na nivou dana",
  LABEL_NA_NIVOU_NEDELJE: " - na nivou nedelje",
  LABEL_NA_NIVOU_MESECA: " - na nivou meseca",
  ZAKAZIVANJE_IZVESTAJA_POGRESNO_VREME: "Pogrešno vreme važenja uneto",
  ZAKAZIVANJE_IZVESTAJA_PONAVLJANJE_IS_REQUIRED: "Ponavljanje generisanja je obavezno polje",
  ZAKAZIVANJE_IZVESTAJA_UCESTALOST_IS_REQUIRED: "Učestalost ponavljanja je obavezno polje",
  ZAKAZIVANJE_IZVESTAJA_DATUM_VREME_IS_REQUIRED: "Datum i vreme generisanja su obavezna polja",
  ZAKAZAN_IZVESTAJ_DELETE: "Uspešno obrisan zakazani izveštaj",
  ZAKAZAN_IZVESTAJ_CREATE: "Uspešno zakazan izveštaj",
  ZAKAZAN_IZVESTAJ_EDIT: "Uspešno izmenjen zakazani izveštaj",

  // PLACANJE
  PLACANJE_LIST: "Plaćanja",
  OBRACUNSKI_PERIOD_OD: "Početak obračunskog perioda",
  OBRACUNSKI_PERIOD_DO: "Kraj obračunskog perioda",
  DATUM_PLACANJA: "Datum plaćanja",
  OBRACUNATI_IZNOS: "Obračunati iznos",
  UPLACEN_IZNOS: "Uplaćeni iznos",
  NAPOMENA: "Napomena",
  VALUTA: " RSD",
  BUTTON_DODAJ_UPLATU: "Dodaj uplatu",
  BUTTON_DODAJ_UPLATU_ZA_UGOVOR: "Dodaj uplate za ugovor",
  PLACANJE_TITLE_DIALOG_UPDATE: "Izmena uplate",
  PLACANJE_TITLE_DIALOG_DELETE: "Brisanje uplate",
  ADD_PLACANJE_TITLE_DIALOG_CREATE: "Kreiranje uplate",
  ADD_PLACANJE_ZA_UGOVOR_TITLE_DIALOG_CREATE: "Kreiranje uplatа (obračunskih perioda) za vremensko trajanje ugovora",
  USTANOVA_PLACANJE_DROPDOWN_MESEC: "Odaberite mesec",
  USTANOVA_PLACANJE_DROPDOWN_GODINA: "Odaberite godinu",
  USTANOVA_PLACANJE_NAPOMENA: "Napomena",
  OBRACUNATI_IZNOS_RSD: "Obračunati iznos (RSD)",
  UPLACEN_IZNOS_RSD: "Uplaćeni iznos (RSD)",
  PLACANJE_IS_REQUIRED: "Unos obracunskog perioda za plaćanje je obavezan.",
  USTANOVA_PLACANJE_OBRACUNSKI_PERIOD_OD_IS_REQUIRED: "Unos početka obračunskog perioda je obavezan.",
  USTANOVA_PLACANJE_OBRACUNSKI_PERIOD_DO_IS_REQUIRED: "Unos kraja obračunskog perioda je obavezan.",
  PLACANJE_MESSAGE_CREATE_SUCCESS: "Plaćanje uspešno kreirano.",
  PLACANJE_TITLE_CREATE: "Kreiranje plaćanja",
  USTANOVA_PLACANJE_OBAVEZE_IZMIRENE: "Izmirene obaveze",
  USTANOVA_PRISTUP_PRIMARAN: "Primaran pristup",
  USTANOVA_INICIJALIZACIJA_KORAK: "Korak inicijalizacije",
  IZMENA_PLACANJE_LIST: "Izmena plaćanja",
  PLACANJE_MESSAGE_UPDATE_SUCCESS: "Plaćanje uspešno izmenjeno.",
  PLACANJE_MESSAGE_DELETE_SUCCESS: "Plaćanje uspešno obrisano.",
  PLACANJE_UGOVOR_VAZI_OD_IS_REQUIRED: "Datum početka važenja ugovora je obavezno.",
  PLACANJE_UGOVOR_VAZI_DO_IS_REQUIRED: "Datum kraja važenja ugovora je obavezno.",
  PLACANJE_UGOVOR_VAZI_OD: "Datum početaka važenja ugovora",
  PLACANJE_UGOVOR_VAZI_DO: "Datum do kog važi ugovor",
  PLACANJE_UGOVOR_VAZI_OD_VAZI_DO_COMPARE_VALIDATION: "Datum početka važenja ugovora mora biti pre datuma kraja važenja ugovora.",
  USTANOVA_BRISANJE: "Brisanje ustanove",
  USTANOVA_BRISANJE_PODACI_O_USTANOVI: "Podaci o ustanovi",
  USTANOVA_BRISANJE_LABEL_BROJ_RADNIKA: "Broj radnika",
  USTANOVA_BRISANJE_LABEL_BROJ_ORGANIZACIONIH_JEDINICA: "Broj organizacionih jedinica",
  USTANOVA_BRISANJE_LABEL_BROJ_ZAKAZNIH_TERMINA: "Broj zakazanih termina",
  USTANOVA_BRISANJE_LABEL_BROJ_KONTAKATA: "Broj kontakata",
  USTANOVA_BRISANJE_LABEL_BROJ_SNIMLJENIH_DIJAGNOZA: "Broj snimljenih dijagnoza",
  USTANOVA_BRISANJE_LABEL_BROJ_SNIMLJENIH_FORMULARA: "Broj snimljenih formulara",
  USTANOVA_BRISANJE_DIALOG_LABEL_1: "Da li ste sigurni da želite da obrišete ovu Ustanovu? Akcija brisanja je nepovratna, svi podaci biće trajno obrisani bez mogucnosti vraćanja!",
  USTANOVA_BRISANJE_DIALOG_LABEL_2: "Izabrali ste opciju za trajno brisanje! Da biste potvrdili brisanje, štiklirajte checkbox i opcija za brisanje će vam biti dostupna.",
  USTANOVA_BRISANJE_U_TOKU: "Brisanje je u toku ...",
  USTANOVA_BRISANJE_ZAVRSENO: "Brisanje uspešno završeno.",

  // GLOBALNI PARAMETRI
  GLOBALNI_PARAMETRI: "Globalni parametri",
  ADMINISTRACIJA_GLOBALNIH_PARAMETARA: "Administracija globalnih parametara",
  PARAMETAR_GLOBALAN_MESSAGE_UPDATE_SUCCESS: "Pravila plaćanja uspešno izmenjena.",

  // LOG HOGH LEVEL
  LOG_HIGH_LEVEL_MESS_RADNIK: "Radnik sa id = ",
  LOG_HIGH_LEVEL_MESS_USTANOVA: " iz ustanove sa id = ",
  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_1: "Kreirano plaćanje za obračunski period ",
  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_2: " sa obračunatim iznosom od ",
  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_3: " RSD, sa uplaćenim iznosom od ",
  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_4: " RSD i datumom plaćanja ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_1: "Izmenjeno plaćanje za obračunski period ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_2: " sa obračunatim iznosom od ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_3: " RSD, sa uplaćenim iznosom od ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_4: " RSD i datumom plaćanja ",
  LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_1: "Obrisano plaćanje za obračunski period koji pocinje od ",
  LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_2: " sa obračunatim iznosom od ",
  LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_3: " RSD, sa uplaćenim iznosom od ",
  LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_4: " RSD i datumom plaćanja ",
  LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_5: " i zavrsava se ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_SUPERADMIN_1: "Kreirana je ustanova pod nazivom ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_1: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_2: ". Parametri ustanove su: Naziv katki ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_3: ", Pismo ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_4: ", Tip svojine ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_5: ", Nivo zdravstvene zaštite ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_6: ", Podrazumevana strana ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_SUPERADMIN_1: "Obrisana je ustanova pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_SUPERADMIN_1: "Izmenjena je ustanova pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_1: "Kreiran broj naloga pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_3: ", sa ukupnim brojem naloga od ",
  LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_4: " naloga i cenom od ",
  LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_5: " RSD",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_1: "Kreiran nov licencni model po ustanovi ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_2: " sa brojem naloga ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_3: " koji važi od ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_4: " do ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_1: "Izmenjen licencni model po ustanovi ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_2: " sa brojem naloga ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_3: " koji važi od ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_4: " tako da važi do ",
  LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_1: "Kreiran nov dodatni modul pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_3: " sa cenom od ",
  LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_4: " RSD ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DODATNI_MODUL_1: "Izmenjen dodatni modul tako da je naziv modula ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DODATNI_MODUL_2: ", a šifra ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_1: "Kreiran nov dodatni modul po ustanovi ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_2: " sa licencnim modulom ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_3: " koji važi od ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_4: " do ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_1: "Izmenjen dodatni modul po ustanovi ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_2: " sa licencnim modulom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_3: " koji važi od ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_4: " tako da važi do ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_1: "Obrisan dodatni modul po ustanovi ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_2: " sa licencnim modulom ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_3: " koji važi od ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_4: " do ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_1: "Kreirano novo ograničenje skladišta po ustanovi ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_2: " pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_3: " koje važi od ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_4: " do ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_1: "Obrisano ograničenje skladišta po ustanovi ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_2: " pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_3: " koje važi od ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_4: " do ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_1: "Izmenjeno ograničenje skladišta po ustanovi ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_2: " pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_3: " koje važi od ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_4: " tako da važi do ",
  LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_1: "Kreirana uloga pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_3: " kojoj je pridružena rola ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_1: "Izmenjena uloga pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_3: " kojoj je pridružena rola ",
  LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_1: "Obrisana uloga pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_3: " kojoj je pridružena rola ",
  LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_1: "Kreiran je formular pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_3: " za ustanovu čiji je id ",
  LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_4: " čiji je tip kontakta ",
  LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_1: "Izmenjen je formular pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_3: " za ustanovu čiji je id ",
  LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_4: " čiji je tip kontakta ",
  LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_1: "Obrisan je formular pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_3: " za ustanovu čiji je id ",
  LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_4: " čiji je tip kontakta ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_1: "Kreiran je tip polja pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_3: " za koji je tip komponente ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_1: "Obrisan je tip polja pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_3: " za koji je tip komponente ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_1: "Izmenjeno je polje pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_3: " za koji je tip komponente ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_1: "Kreirano je polje sa nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_3: " tipa ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_4: " koje je obavezno ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_5: " koje nije obavezno ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_1: "Obrisano je polje sa nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_3: " tipa ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_4: " koje je obavezno ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_5: " koje nije obavezno ",

  LOG_HIGH_LEVEL_MESS_CREATE_PAKET_USLUGA: "Kreiran je paket usluga pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PAKET_USLUGA: "Izmenjen je paket usluga pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_PAKET_USLUGA: "Obrisan je paket usluga pod nazivom ",

  LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_FOR_PAKET_USLUGA: "Dodata je usluga pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_FOR_PAKET_USLUGA: "Obrisana je usluga pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USLUGA_FOR_PAKET_USLUGA: "Izmenjena je usluga pod nazivom ",

  LOG_HIGH_LEVEL_MESS_CREATE_PROIZVOD_FOR_PAKET_USLUGA: "Dodat je proizvod pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PROIZVOD_FOR_PAKET_USLUGA: "Izmenjen je proizvod pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_PROIZVOD_FOR_PAKET_USLUGA: "Obrisan je proizvod pod nazivom ",

  LOG_HIGH_LEVEL_MESS_CREATE_PAKET_ORGANIZACIONA_JEDINICA_1: "Organizacionoj jedinici pod imenom ",
  LOG_HIGH_LEVEL_MESS_CREATE_PAKET_ORGANIZACIONA_JEDINICA_2: " dodeljen je paket usluga ",
  LOG_HIGH_LEVEL_MESS_DELETE_PAKET_ORGANIZACIONA_JEDINICA_1: "Na organizacionoj jedinici pod imenom ",
  LOG_HIGH_LEVEL_MESS_DELETE_PAKET_ORGANIZACIONA_JEDINICA_2: " uklonjen je paket usluga ",

  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_1: "Izmenjeno je polje sa nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_3: " tipa ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_4: " koje je obavezno ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_5: " koje nije obavezno ",

  LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_KATEGORIJA_1: "Kreirana je kategorija ",
  LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_1: " za polje pacijenta koja je obavezna ",
  LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_2: " za polje pacijenta koja nije obavezna ",
  LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_3: " i može se samo čitati ",
  LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_KATEGORIJA_1: "Obrisana je kategorija ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_KATEGORIJA_1: "Izmenjena je kategorija ",

  LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_1: "Za pacijenta ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_CREATE: " dodato je osiguranje tipa ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_UPDATE: " izmenjeno je osiguranje tipa ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_DELETE: " obrisano je osiguranje tipa ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_3: " koje važi od ",

  LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_1: "Kreirano je polje pacijenta sa nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_3: " tipa ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_1: "Izmenjeno je polje pacijenta sa nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_3: " tipa ",
  LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_1: "Obrisano je polje pacijenta sa nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_3: " tipa ",

  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_1: "Kreirana je organizaciona jedinica pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_2: ". Tip organizacione jedinice je ",
  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_3: ". Radni dani su ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_1: "Izmenjena je organizaciona jedinica pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_2: ". Tip organizacione jedinice je ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_3: ". Radni dani su ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_1: "Obrisana je organizaciona jedinica pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_2: ". Tip organizacione jedinice je ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_3: ". Radni dani su ",

  LOG_HIGH_LEVEL_MESS_CREATE_OBAVESTENJE_1: "Kreirano je obaveštenje sa naslovom ",
  LOG_HIGH_LEVEL_MESS_CREATE_OBAVESTENJE_2: ", čiji je sadržaj: ",

  LOG_HIGH_LEVEL_MESS_DELETE_OBAVESTENJE_1: "Obrisano je obaveštenje sa naslovom ",
  LOG_HIGH_LEVEL_MESS_DELETE_OBAVESTENJE_2: ", čiji je sadržaj: ",

  LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_1: "Kreiran je tip pregleda pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_2: ". Kategorija pregleda je ",
  LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_3: ". Pregled je sa automatskim završavanjem ",
  LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_4: ". Pregled nije sa automatskim zavrsavanjem.",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_1: "Izmenjen je tip pregleda pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_2: ". Kategorija pregleda je ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_3: ". Pregled je sa automatskim završavanjem ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_4: ". Pregled nije sa automatskim zavrsavanjem.",
  LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_1: "Obrisan je tip pregleda pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_2: ". Kategorija pregleda je ",
  LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_3: ". Pregled je sa automatskim završavanjem. ",
  LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_4: ". Pregled nije sa automatskim zavrsavanjem.",

  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA: "Za tip pregleda ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA_1: " je dodata organizaciona jedinica ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA_2: " je uklonjena organizaciona jedinica ",

  LOG_HIGH_LEVEL_MESS_CREATE_KATEGORIJA_IZVESTAJA_1: "Kreirana nova kategorija statističkog izveštaja pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_KATEGORIJA_IZVESTAJA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_KATEGORIJA_IZVESTAJA_1: "Izmenjena kategorija statističkog izveštaja pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_KATEGORIJA_IZVESTAJA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_KATEGORIJA_IZVESTAJA_1: "Obrisana kategorija statističkog izveštaja pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_KATEGORIJA_IZVESTAJA_2: " i šifrom ",

  LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_1: "Izmenjen je broj naloga pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_3: " sa ukupnim brojem naloga od ",
  LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_4: " naloga i cenom od ",
  LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_5: " RSD",

  LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_1: "Kreirano je novo ograničenje skladišta pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_2: ", šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_3: " i prostorom od ",
  LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_4: ", sa cenom od ",
  LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_5: " RSD",

  LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_1: "Izmenjeno je novo ograničenje skladišta pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_2: ", šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_3: " i prostorom od ",
  LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_4: ", sa cenom od ",
  LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_5: " RSD",

  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_1: "Obrisan je licencni model po ustanovi ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_2: " sa brojem naloga ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_3: " koji važi od ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_4: " do ",

  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_1: "Kreiran je novi radnik koji pripada ustanovi ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_2: " sa imenom ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_3: ", prezimenom ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_4: ", email adresom ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_5: ", pod korisničkim imenom ",

  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_1: "Izmenjen je radnik koji pripada ustanovi ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_2: " sa imenom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_3: ", prezimenom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_4: ", email adresom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_5: ", pod korisničkim imenom ",

  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_1: "Obrisan je radnik koji pripada ustanovi ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_2: " sa imenom ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_3: ", prezimenom ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_4: ", email adresom ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_5: ", pod korisničkim imenom ",

  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_1: "Radnik koji pripada ustanovi ",
  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_2: " sa imenom ",
  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_3: ", prezimenom ",
  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_4: ", email adresom ",
  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_5: ", pod korisničkim imenom ",
  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_6: " resetovao je lozinku",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_1: "Kreirana je usluga šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_3: " koja važi od ",

  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_1: "Izmenjena je usluga šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_3: " koja važi od ",

  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_1: "Obrisana je usluga šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_3: " koja važi od ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_1: "Kreirana je jedinica mere šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_3: " koja važi od ",

  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_1: "Izmenjena je jedinica mere šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_3: " koja važi od ",

  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_1: "Obrisana je jedinica mere šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_3: " koja važi od ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_1: "Kreiran je proizvod šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_2: ", šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_3: ", čija je jedinica mere ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_4: " i koji važi od ",

  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_1: "Izmenjen je proizvod šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_2: ", šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_3: ", čija je jedinica mere ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_4: " i koji važi od ",

  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_1: "Obrisan je proizvod šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_2: ", šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_3: ", čija je jedinica mere ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_4: " i koji važi od ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_1: "Kreiran je proizvod rfzo šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_2: ", šifrom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_3: ", sa statusom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_4: " i koji važi od ",

  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_1: "Izmenjen je proizvod rfzo šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_2: ", šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_3: ", sa statusom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_4: " i koji važi od ",

  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_1: "Obrisan je proizvod rfzo šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_2: ", šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_3: ", sa statusom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_4: " i koji važi od ",

  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_CREATE: "Kreiran je atribut rfzo šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_UPDATE: "Izmenjen je atribut rfzo šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_DELETE: "Obrisan je atribut rfzo šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_2: ", šifrom ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_3: ", faktorom uvećanja cene ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_4: ", statusom ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_5: " i koji važi od ",

  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_CREATE: "Kreirana je usluga rfzo šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_UPDATE: "Izmenjena je usluga rfzo šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_DELETE: "Obrisana je usluga rfzo šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_2: ", šifrom ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_3: ", statusom ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_4: " i koja važi od ",

  LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_CREATE: "Kreiran je faktor rizika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_UPDATE: "Izmenjen je faktor rizika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_DELETE: "Obrisan je faktor rizika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_2: " i šifrom ",

  LOG_HIGH_LEVEL_MESS_CREATE_RADNI_PERIOD_1: "Kreiran je radni period za radnika ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_2: ". Vreme trajanja radnog perioda je od ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_3: " do ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_4: " Radni dani su: ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_5: ". Vreme početka ranog dana je ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_6: ", vreme kraja radnog dana je ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_7: ". Vreme trajanja pregleda je ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNI_PERIOD_1: "Obrisan je radni period za radnika ",

  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_ENUM_1: "Kreirano je polje tipa enum stavke pod nazivom ",
  LOG_HIGH_LEVEL_MESS_INFO_POLJE_TIP_ENUM_1: " i sifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_ENUM_1: "Obrisano je polje tipa enum stavke pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_ENUM_1: "Izmenjeno je polje tipa enum stavke pod nazivom ",

  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_1: "Za parametar ",
  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_2: " izveštaja ",
  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_CREATE: " kreirana je enum stavka pod nazivom ",
  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_DELETE: " obrisana je enum stavka pod nazivom ",
  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_UPDATE: " ažurirana je enum stavka pod nazivom ",
  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_4: " i sifrom ",

  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_1: "Izmenjena je ustanova sa nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_2: ". Parametri ustanove su: Naziv kratki: \n",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_3: ", Pismo: ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_4: ", Tip svojine: ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_5: ", Nivo zdravstvene zaštite: ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_6: ", Podrazumevana strana: ",

  LOG_HIGH_LEVEL_MESS_CREATE_REPO_POLJE_1: "Kreirano je polje formulara repozitorijuma pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_REPO_POLJE_1: "Izmenjeno je polje formulara repozitorijuma pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_REPO_POLJE_1: "Obrisano je polje formulara repozitorijuma pod nazivom ",
  LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_3: ". Status polja: ",
  LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_4: ", tip polja: ",
  LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_DIJAGNOZA_1: "Kreirana je dijagnoza sa unosom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_DIJAGNOZA_1: "Izmenjena je dijagnoza sa unosom ",
  LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_DIJAGNOZA_1: "Obrisana je dijagnoza sa unosom",
  LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_DIJAGNOZA_1: " i statusom ",

  LOG_HIGH_LEVEL_MESS_CREATE_DIALOG_TERMIN_1: "Zakazan je termin pregleda tipa ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_1: "Izmenjen je termin pregleda tipa ",
  LOG_HIGH_LEVEL_MESS_DELETE_DIALOG_TERMIN_1: "Obrisan je termin pregleda tipa ",
  LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_1: " u trajanju od ",
  LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_2: " do ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_1: "Dodat je komentar: ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_2: " , na termin pregleda tipa ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_3: " u trajanju od ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_4: " do ",

  LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_UTROSAK_1: "Kreiran je utrošak ",
  LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_UTROSAK_1: "Izmenjen je utrošak ",
  LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_UTROSAK_1: "Obrisan je utrošak ",
  LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_UTROSAK_1: ". Potrošena količina je ",

  LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_USLUGA_1: "Kreirana je usluga ",
  LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_USLUGA_1: "Izmenjena je usluga ",
  LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_USLUGA_1: "Obrisana je usluga ",
  LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_USLUGA_1: ". Broj pružanja usluge je ",
  LOG_HIGH_LEVEL_MESS_CREATE_UNOSI_1: "Sačuvan je nalaz. Izabrani tip unosa nalaza je ",
  LOG_HIGH_LEVEL_MESS_DELETE_UNOSI_1: "Obrisan je nalaz tipa unosa ",

  LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_1: "Obrisan je pregled tipa ",

  LOG_HIGH_LEVEL_MESS_PRISTUP_DOSIJE_PACIJENT: "Pristup dosijeu pacijenta.",
  LOG_HIGH_LEVEL_MESS_LOGOUT_KORISNIKA: "Korisnik se izlogovao.",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_GRID: "Izmena grida ustanove",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_PODESAVANJA: "Izmenjena podešavanja ustanove",

  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_TIP_1: " dodat je tip pregleda ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_TIP_2: " uklonjen je tip pregleda ",

  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_1: " dodat je radnik ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_2: " uklonjen je radnik ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_3: " dodati su radnici ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_4: " uklonjeni su radnici ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA: "Na organizacionoj jedinici ",

  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA: "Za ulogu ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_APLIKATIVNA_PRAVA_1: " је dodatо aplikativnо pravо: ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_APLIKATIVNA_PRAVA_2: " је uklonjenо aplikativnо pravо: ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_RADNIK_1: " je dodat radnik ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_RADNIK_2: " je uklonjen radnik ",

  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_1: "Za radnika ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_2: " je dodata uloga ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_3: " je uklonjena uloga ",

  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_1: "Za radnika ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_2: " je dodata organizaciona jedinica ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_3: " je uklonjena organizaciona jedinica ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_4: " su dodate organizacione jedinice ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_5: " su uklonjene organizacione jedinice ",

  LOG_HIGH_LEVEL_MESS_CREATE_IZVESTAJ_1: "Kreiran je izveštaj pod nazivom ",
  LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_1: ", kategorija izveštaja je ",
  LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_2: ", format: ",
  LOG_HIGH_LEVEL_MESS_DELETE_IZVESTAJ_1: "Obrisan je izveštaj pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_IZVESTAJ_1: "Izmenjen je izveštaj pod nazivom ",

  LOG_HIGH_LEVEL_MESS_CREATE_IZVESTAJ_PARAMETAR_1: "Kreiran je parametar izveštaja pod nazivom ",
  LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_1: ", tip izveštaja je ",
  LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_2: ", naziv zahteva ",
  LOG_HIGH_LEVEL_MESS_DELETE_IZVESTAJ_PARAMETAR_1: "Obrisan je parametar izveštaja pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_IZVESTAJ_PARAMETAR_1: "Izmenjen je parametar izveštaja pod nazivom ",

  LOG_HIGH_LEVEL_MESS_DELETE_GENERISANI_IZVESTAJ_1: "Obrisan je generisani izveštaj vezan za statistički izveštaj pod nazivom ",

  LOG_HIGH_LEVEL_MESS_CREATE_PROTOKOL_1: "Kreiran je protokol pod nazivom ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_1: ", prefiks protokola je ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_2: ", vremenski okvir: ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_3: ", pocetni broj: ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_4: ", naredni broj: ",
  LOG_HIGH_LEVEL_MESS_DELETE_PROTOKOL_1: "Obrisan je protokol pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PROTOKOL_1: "Izmenjen je protokol pod nazivom ",

  LOG_HIGH_LEVEL_MESS_DELETE_PROTOKOL_BROJ_1: "Obrisan je protokol ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_1: ", za pacijenta ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_2: ", vreme od: ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_3: ", broj: ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_4: ", organizacione jedinice ",

  LOG_HIGH_LEVEL_MESS_DELETE_FAKTOR_RIZIKA_1: "Uklonjen je faktor rizika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_FAKTOR_RIZIKA_1: "Dodat je faktor rizika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_INFO_FAKTOR_RIZIKA_1: " sa šifrom ",

  LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_1: "Kreiran je izvoz/export sa datumom od ",
  LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_2: ", sa datumom do ",
  LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_3: ", sa razlogom zahteva ",

  LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_1: "Obrisana je terapija sa id ",
  LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_2: ", sa lekom ",
  LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_3: ", sa dijagnozom ",

  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_UGOVOR_1: "Kreirаne su uplate za vremensko trajanje ugovora od datuma ",
  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_UGOVOR_2: " do datuma ",

  LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_1: "Kreirana je terapija sa dijagnozom sa id ",
  LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_2: ", sa šifrom terapije ",
  LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_3: ", sa nazivom leka ",

  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_CREATE_1: "Kreiran je paket na kontaktu sa id ",
  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_CREATE_2: ", sa paketom proizvoda id ",

  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_1: "Sa kontakta je obrisan paket proizvoda pod nazivom ",
  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_2: ", koji je kreirao radnik ",

  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_UPDATE_1: "Na kontaktu je izmenjen paket proizvoda pod nazivom ",
  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_UPDATE_2: ", koji je kreirao radnik ",

  LOG_HIGH_LEVEL_MESS_GENERISANI_IZVESTAJI_DELETE_1: "Obrisan je generisani izveštaj sa naslovom ",
  LOG_HIGH_LEVEL_MESS_GENERISANI_IZVESTAJI_DELETE_2: ", koji je kreirao radnik ",

  LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_1: "Kreiran je pacijent sa imenom i prezimenom ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_2: ", sa datumom rodjenja ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_3: ", sa polom ",

  LOG_HIGH_LEVEL_MESS_DELETE_ZAKAZAN_IZVESTAJ1: "Na statističkom izveštaju ",
  LOG_HIGH_LEVEL_MESS_DELETE_ZAKAZAN_IZVESTAJ2: ", obrisan je zakazani izveštaj",

  LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ1: "Na statističkom izveštaju ",
  LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ2: ", zakazan je izveštaj sa ponavljanjem generisanja - ",
  LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ3: " i vremenom generisanja - ",

  LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ1: "Na statističkom izveštaju ",
  LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ2: ", izmenjen je izveštaj sa ponavljanjem generisanja - ",
  LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ3: " i vremenom generisanja - ",

  LOG_HIGH_LEVEL_MESS_CREATE_GENERISAN_IZVESTAJ1: "Na statističkom izveštaju ",
  LOG_HIGH_LEVEL_MESS_CREATE_GENERISAN_IZVESTAJ2: ", generisan je novi izveštaj",

  LOG_HIGH_LEVEL_MESS_CENA_CREATE_1: "Kreirana je cena za prozvod sa id ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_USLUGA_1: "Kreirana je cena za uslugu sa id ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_2: ", sa datumom od ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_3: ", sa iznosom ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_4: ", sa valutom ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_5: ", sa poreskom stopom ",
  LOG_HIGH_LEVEL_MESS_CENA_DELETE_1: "Obrisana je cena za prozvod sa id ",
  LOG_HIGH_LEVEL_MESS_CENA_UPDATE_1: "Izmenjena je cena za proizvod sa id ",
  LOG_HIGH_LEVEL_MESS_CENA_DELETE_USLUGA_1: "Obrisana je cena za uslugu sa id ",
  LOG_HIGH_LEVEL_MESS_CENA_UPDATE_USLUGA_1: "Izmenjena je cena za uslugu sa id ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_1: "Kreirana je cena za partnera ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_2: ", sa uslugom ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_3: ", sa datumom važi od ",
  LOG_HIGH_LEVEL_MESS_CENA_DELETE_PARTNER_1: "Obrisana je cena za partnera ",
  LOG_HIGH_LEVEL_MESS_CENA_UPDATE_PARTNER_1: "Izmenjena je cena za partnera ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_NACINA_PLACANJA_1: "Kreiran je način plaćanja šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_NACINA_PLACANJA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_NACINA_PLACANJA_1: "Izmenjen je način plaćanja šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_NACINA_PLACANJA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_NACINA_PLACANJA_1: "Obrisan je način plaćanja šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_NACINA_PLACANJA_2: " i šifrom ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_KATEGORIJA_POPUSTA_1: "Kreirana je kategorija popusta šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_KATEGORIJA_POPUSTA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_KATEGORIJA_POPUSTA_1: "Izmenjena je kategorija popusta šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_KATEGORIJA_POPUSTA_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_KATEGORIJA_POPUSTA_1: "Obrisana je kategorija popusta šifarnika pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_KATEGORIJA_POPUSTA_2: " i šifrom ",

  LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_1: "Kreiran je partner pod nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PARTNER_1: "Izmenjen je partner pod nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PARTNER_2: " i šifrom ",
  LOG_HIGH_LEVEL_MESS_DELETE_PARTNER_1: "Obrisan je partner pod nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_PARTNER_2: " i šifrom ",

  LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_1: "Kreirano je grupno obaveštenje sa sadržajem ",
  LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_2: ", i tipom obaveštenja ",
  LOG_HIGH_LEVEL_MESS_DELETE_GRUPNO_OBAVESTENJE_1: "Obrisano je grupno obaveštenje sa sadržajem ",
  LOG_HIGH_LEVEL_MESS_UPDATE_GRUPNO_OBAVESTENJE_1: "Izmenjeno je grupno obaveštenje sa sadržajem ",
  LOG_HIGH_LEVEL_MESS_SEND_GRUPNO_OBAVESTENJE_1: "Poslato je grupno obaveštenje sa sadržajem ",

  LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_1: "Kreiran je obrazac štampe protokola sa nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_2: ", i opisom ",
  LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_3: ", i jasper fajlom ",
  LOG_HIGH_LEVEL_MESS_DELETE_OBRAZAC_STAMPE_PROTOKOLA_1: "Obrisan je obrazac štampe protokola sa nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_OBRAZAC_STAMPE_PROTOKOLA_1: "Izmenjen je obrazac štampe protokola sa nazivom ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_SPECIJALIZACIJA: "Kreirana je specijalizacija sa nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_SPECIJALIZACIJA: "Izmenjena je specijalizacija sa nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_SPECIJALIZACIJA: "Obrisana je specijalizacija sa nazivom ",

  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJA_1: "Za radnika ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJA_2: " je dodata specijalizacija ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJE_2: " su dodate specijalizacije ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJA_1: "Za radnika ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJA_2: " je uklonjena specijalizacija ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJE_2: " su uklonjene specijalizacije ",

  LOG_HIGH_LEVEL_PACIJENT_USPESNO_SPOJEN_1: "Pacijent sa id: ",
  LOG_HIGH_LEVEL_PACIJENT_USPESNO_SPOJEN_2: " spojen je sa pacijentom sa id: ",

  LOG_HIGH_LEVEL_MESS_RAZDVAJANJE_PACIJENATA_1: "Razdvojeni su pacijenti ",

  LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJA_1: "Za uslugu ",
  LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJA_2: " je dodata specijalizacija ",
  LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJE_2: " su dodate specijalizacije ",
  LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJA_1: "Za uslugu ",
  LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJA_2: " je uklonjena specijalizacija ",
  LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJE_2: " su uklonjene specijalizacije ",

  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_1: "Za organizacionu jedinicu ",
  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_2: " je dodata specijalizacija ",
  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE_2: " su dodate specijalizacije ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_1: "Za organizacionu jedinicu ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_2: " je uklonjena specijalizacija ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE_2: " su uklonjene specijalizacije ",

  LOG_HIGH_LEVEL_MESS_CREATE_PRODAJNI_PAKET_1: "Kreiran je prodajni paket sa nazivom ",
  LOG_HIGH_LEVEL_MESS_CREATE_PRODAJNI_PAKET_2: " i cenom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PRODAJNI_PAKET_1: "Izmenjen je prodajni paket sa nazivom ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PRODAJNI_PAKET_2: " i cenom ",
  LOG_HIGH_LEVEL_MESS_DELETE_PRODAJNI_PAKET_1: "Obrisan je prodajni paket sa nazivom ",
  LOG_HIGH_LEVEL_MESS_DELETE_PRODAJNI_PAKET_2: " i cenom ",

  // FAKTORI RIZIKA
  FAKTOR_RIZIKA_LIST: "Faktori rizika",
  BUTTON_ADD_FAKTOR_RIZIKA: "Dodaj faktor rizika",
  FAKTOR_RIZIKA_TITLE_DIALOG_CREATE: "Kreiranje novog faktora rizika",
  FAKTOR_RIZIKA_TITLE_DIALOG_UPDATE: "Izmena faktora rizika",
  FAKTOR_RIZIKA_TITLE_DIALOG_DELETE: "Brisanje faktora rizika",
  FAKTOR_RIZIKA_SEARCH: "Pretraga faktora rizika",
  FAKTOR_RIZIKA_SIFRA: "Šifra",
  FAKTOR_RIZIKA_NAZIV: "Naziv",
  LABEL_FAKTOR_RIZIKA: "Faktor rizika",
  FAKTOR_RIZIKA_SIFRA_IKONICE: "Ikonica",
  FAKTOR_RIZIKA_TITLE_MESSAGE_UPDATE_FAKTOR_RIZIKA_SUCCESS: "Uspešno izmenjen faktor rizika",
  FAKTOR_RIZIKA_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru.",
  FAKTOR_RIZIKA_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv.",
  FAKTOR_RIZIKA_IKONICA_IS_REQUIRED: "Obavezno je izabrati ikonicu.",
  FAKTOR_RIZIKA_TITLE_MESSAGE_CREATE_FAKTOR_RIZIKA_SUCCESS: "Uspešno kreiran faktor rizika.",
  FAKTOR_RIZIKA_TITLE_MESSAGE_DELETE_FAKTOR_RIZIKA_SUCCESS: "Uspešno obrisan faktor rizika.",
  FAKTOR_RIZIKA_DELETE_DIALOG_MESSAGE: "Postoje pacijenti za koje je vezan faktor rizika koji želite da onemogućite. Da li ste sigurni da želite da nastavite?",

  // FAKTORI RIZIKA IKONICA
  FAKTOR_RIZIKA_IKONICA_LIST: "Ikonice faktora rizika",
  BUTTON_ADD_FAKTOR_RIZIKA_IKONICA: "Dodaj ikonicu faktora rizika",
  FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_CREATE: "Kreiranje nove ikonice faktora rizika",
  FAKTOR_RIZIKA_IKONICA_SEARCH: "Pretraga ikonica faktora rizika",
  FAKTOR_RIZIKA_IKONICA_SIFRA: "Šifra",
  FAKTOR_RIZIKA_IKONICA_NAZIV: "Naziv",
  FAKTOR_RIZIKA_IKONICA_IKONICA: "Ikonica",
  FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_UPDATE: "Izmena ikonice faktora rizika",
  FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_DELETE: "Brisanje ikonice faktora rizika",
  LABEL_FAKTOR_RIZIKA_IKONICA: "Ikonica faktora rizika",
  FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_DELETE_FAKTOR_RIZIKA_IKONICA_SUCCESS: "Uspešno obrisana ikonica faktora rizika.",
  FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_UPDATE_FAKTOR_RIZIKA_IKONICA_SUCCESS: "Uspešno izmenjena ikonica faktora rizika.",
  FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_CREATE_FAKTOR_RIZIKA_IKONICA_SUCCESS: "Uspešno kreirana nova ikonica faktora rizika.",
  FAKTOR_RIZIKA_IKONICA_SIFRA_IS_REQUIRED: "Obavezno je uneti šifru.",
  FAKTOR_RIZIKA_IKONICA_NAZIV_IS_REQUIRED: "Obavezno je uneti naziv.",
  FAKTOR_RIZIKA_IKONICA_IKONICA_IS_REQUIRED: "Obavezno je izabrati ikonicu.",

  // FAKTURNI PODACI
  USTANOVA_FAKTURNI_PODACI: "Fakturni podaci",
  FAKTURA_NAZIV: "Naziv ustanove",
  FAKTURA_ADRESA: "Adresa",
  FAKTURA_MESTO: "Mesto",
  FAKTURA_MB: "Matični broj",
  FAKTURA_PIB: "PIB",
  FAKTURA_MAIL: "Imejl",
  USTANOVA_FAKTURNI_PODACI_TITLE_MESSAGE_UPDATE_USTANOVA_FAKTURNI_PODACI_SUCCESS: "Fakturni podaci uspešno izmenjeni",
  FAKTURA_MAIL_FORMAT_ERROR: "Imejl nije validan",
  FAKTURA_PIB_FORMAT_ERROR: "PIB nije validan",
  FAKTURA_MB_FORMAT_ERROR: "Matični broj nije validan",
  ZAKAZIVANJE_ZA_BUDUCNOST: "Zakazivanje za budućnost",
  NO_IZVESTAJ_PARAMETRI: "Nema parametara za prikazivanje.",

  // UVOZ SIFARNIKA

  SIFARNIK_IMPORT: "Uvoz šifarnika",
  SIFARNIK_IMPORT_INATIATE_SUCCESS: "Uspešno iniciran uvoz šifarnika ",
  SIFARNIK_IMPORT_USPESNO_UVEZEN: "Uspešno uvezen šifarnik ",
  SIFARNIK_IMPORT_U_TOKU: "U toku",
  IMPORT_SIFARNIK: "Uvoz šifarnika",
  SIFARNIK_IMPORT_CONNECTION_ERROR: "Došlo je do greške prilikom uspostavljanja konekcije sa serverom",
  SIFARNIK_IMPORT_STARTED_SUCCESS: "Započet je uvoz šifarnika ",

  // Izvoz/Export
  IZVOZ: "Izvoz",
  IZVOZ_NOVI_ZAHTEV: "Novi zahtev",
  IZVOZ_NOVI_ZAHTEV_KREIRANJE: "Kreiraj novi zahtev",
  IZVOZ_IME_PREZIME_ZAHTEVAOCA: "Ime i prezime zahtevaoca",
  IZVOZ_DATUM_ZAHTEVA: "Datum zahteva",
  IZVOZ_VREME_ZAHTEVA: "Vreme zahteva",
  IZVOZ_PERIOD_OD: "Period od",
  IZVOZ_PERIOD_DO: "Period do",
  IZVOZ_RAZLOG_ZAHTEVA: "Razlog zahteva",
  IZVOZ_DATUM_GENERISANJA: "Datum generisanja",
  IZVOZ_VREME_GENERISANJA: "Vreme generisanja",
  IZVOZ_TITLE_DIALOG_CREATE: "Kreiranje novog izvoza",
  IZVOZ_DIALOG_CREATE_DATUM_POCETKA: "Datum početka:",
  IZVOZ_DIALOG_CREATE_DATUM_ZAVRSETKA: "Datum završetka:",
  IZVOZ_DIALOG_CREATE_RAZLOG: "Razlog:",
  IZVOZ_DIALOG_MESSAGE_CREATE_IZVOZ_SUCCESS: "Zahtev za izvoz je uspesno kreiran.",
  IZVOZ_DIALOG_PERIOD_OD_IS_REQUIRED: "Obavezno je uneti datum početka",
  IZVOZ_DIALOG_PERIOD_DO_IS_REQUIRED: "Obavezno je uneti datum završetka",
  IZVOZ_ZAHTEVANO: "Zahtevano",
  IZVOZ_GENERISANO: "Generisano",
  IZVOZ_GENERISI: "Generisanje izvoza",
  IZVOZ_DOWNLOAD: "Preuzimanje izvoza",
  IZVOZ_VAZI_OD_IS_BEFORE_VAZI_DO: "Datum početka mora biti pre datuma završetka",

  USTANOVA_HARD_DELETE_TITLE_MESSAGE_SUCCESS: "Brisanje ustanove uspešno završeno.",
  WEBSOCKET_CONNECTION_ERROR: "Došlo je do greške prilikom uspostavljanja konekcije sa serverom",
  WEBSOCKET_CLOSE_CONNECTION: "Zatvori konekciju.",

  KONTAKT_NEMA_REALIZOVANIH: "Nema realizovanih pregleda za pacijenta",

  //PORUKE
  PORUKE: "Poruke",
  PORUKE_PRIMLJENE: "Primljene poruke",
  PORUKE_POSLATE: "Poslate poruke",
  PORUKE_NOVE: "Nova poruka",
  PORUKE_PRIMALAC: "Primalac",
  PORUKE_NASLOV: "Naslov",
  PORUKE_SADRZAJ: "Sadržaj",
  PORUKE_DODAJ_DOKUMENT: "Dodaj dokument",
  PORUKE_ISPRAZNI: "Isprazni polja",
  PORUKE_POSALJI: "Pošalji",
  PORUKE_ZAKACI_DOKUMENT: "Zakači dokument",
  PORUKA_CREATE_SUCCESS_MESSAGE: "Poruka je uspešno poslata.",
  PORUKA_CREATE_EMPTY_RECEPIENTS_MESSAGE_ERROR: "Primaoci su obavezno polje.",
  PORUKA_CREATE_EMPTY_MESSAGE_ERROR: "Poruka mora imati naslov.",
  PORUKA_CREATE_EMPTY_SADRZAJ_AND_ATTACHMENT_ERROR: "Poruka mora imati sadržaj ili bar jedan priložen dokument.",
  PORUKA_CREATE_PORUKA_SIZE_ERROR: "Priloženi dokumenti prevazilaze maksimalnu dozvoljenu veličinu od 25MB.",
  PORUKA_SEARCH: "Pretraga",
  PRIKAZI_PORUKU_1: "Molimo",
  PRIKAZI_PORUKU_2: "kliknite",
  PRIKAZI_PORUKU_3: "na poruku za prikaz.",
  PORUKA_POSILJALAC: "Pošiljalac:",
  PORUKA_PRIMALAC: "Primalac:",
  PORUKA_PRIMAOCI: "Primaoci:",
  PORUKE_KRAJ: "Nemate više poruka!",
  TOOLTIP_PORUKA_REPLY: "Odgovori",
  TOOLTIP_PORUKA_REPLY_ALL: "Odgovori svima",
  TOOLTIP_PORUKA_FORWARD: "Prosledi",
  TOOLTIP_PORUKA_UNREAD: "Obeleži nepročitano",
  TOOLTIP_PORUKA_DELETE: "Obriši za sebe",
  PORUKA_DELETE_DIALOG_TEXT: "Da li ste sigurni da želite da obrišete?",
  POCETAK_PROSLEDJENE_PORUKE: "Početak prosleđene poruke:",
  PORUKA_DATUM: "Datum",
  PORUKA_RODITELJ_NASLOV_1: "Dana",
  PORUKA_RODITELJ_NASLOV_2: "u",
  PORUKA_RODITELJ_NASLOV_3: "je napisao/la:",
  PORUKA_RODITELJ_NASLOV_3_MUSKI: "je napisao:",
  PORUKA_RODITELJ_NASLOV_3_ZENSKI: "je napisala:",

  // CENE
  CENE_CREATE_EMPTY_PROIZVOD_ERROR_MESSAGE: "Proizvod je obavezan podataka.",
  CENE_CREATE_EMPTY_USLUGA_ERROR_MESSAGE: "Usluga je obavezan podatak.",
  CENE_CREATE_EMPTY_IZNOS_ERROR_MESSAGE: "Iznos je obavezan podatak.",
  CENE_CREATE_EMPTY_VALUTA_ERROR_MESSAGE: "Valuta je obavezan podatak.",
  CENE_CREATE_EMPTY_PORESKA_STOPA_ERROR_MESSAGE: "Poreska stopa je obavezan podatak.",
  CENE_CREATE_EMPTY_DATUM_OD_ERROR_MESSAGE: "Važi Od datum je obavezan podatak.",
  CENE_CREATE_WRONG_DATUM_DO_ERROR_MESSAGE: "Važi Do datum mora biti posle Važi Od datuma.",
  CENE_CREATE_SUCCESS_MESSAGE: "Uspešno kreirana nova cena.",
  CENE_DELETE_SUCCESS_MESSAGE: "Uspešno obrisana cena.",
  CENE_UPDATE_SUCCESS_MESSAGE: "Uspešno izmenjena cena.",

  // PARTNERI
  LABEL_PARTNERI: "Partneri",
  LABEL_EDIT_PARTNER: "Izmeni partnera",
  LABEL_DELETE_PARTNER: "Obriši partnera",
  LABEL_ADD_PARTNER: "Dodaj partnera",
  SEARCH_PARTNER: "Pretraga partnera",
  LABEL_PARTNER_PODACI: "Podaci o partneru",
  LABEL_PARTNER_CENE: "Cene za partnera",
  LABEL_PARTNER_CREATE: "Kreiranje partnera ",
  LABEL_PARTNER_UPDATE: "Izmena partnera ",
  LABEL_PARTNER_DELETE: "Brisanje partnera ",
  LABEL_TIP_PARTNERA: "Tip",
  LABEL_KATEGORIJA_POLISE_PARTNER: "Kategorija polise",
  LABEL_ADRESA_PARTNER: "Adresa",
  LABEL_POSTANSKI_BROJ_PARTNER: "Poštanski broj",
  LABEL_SEDISTE_PARTNER: "Sedište",
  LABEL_DRZAVA_PARTNER_PARTNER: "Država partner",
  LABEL_ZIRO_RACUN_PARTNER: "Žiro račun",
  LABEL_PIB_PARTNER: "PIB",
  LABEL_MATICNI_BROJ_PARTNER: "Matični broj",
  LABEL_SIFRA_DELATNOSTI_PARTNER: "Šifra delatnosti",
  LABEL_TELEFON_PARTNER: "Telefon",
  LABEL_EMAIL_PARTNER: "Imejl",
  LABEL_BROJ_DANA_VALUTE_PLACANJA_PARTNER: "Broj dana valute plaćanja",
  LABEL_POPUST_PARTNER: "Popust",
  LABEL_STAMPANJE_FISKALNOG_ISECKA_PARTNER: "Štampanje fiskalnog isečka",
  LABEL_PLACANJE_DEVIZE_PARTNER: "Plaćanje u devizama",
  LABEL_DEO_PACIJENT_PARTNER: "Deo koji plaća pacijent",
  LABEL_NAPOMENA_PARTNER: "Napomena",
  LABEL_DODAJ_PARTNER: "Dodaj",
  LABEL_IZMENI_PARTNER: "Izmeni",
  LABEL_OBRISI_PARTNER: "Obriši",
  LABEL_ADD_CENA_PARTNER: "Dodaj cenu",
  LABEL_ADD_CENA_PARTNER_DIALOG: "Dodaj posebnu cenu za partnera",
  LABEL_UPDATE_CENA_PARTNER_DIALOG: "Izmeni cenu za partnera",
  LABEL_DELETE_CENA_PARTNER_DIALOG: "Obriši cenu za partnera",
  LABEL_POPUST_CENA_PARTNER_DIALOG: "Popust",
  LABEL_PLACA_PACIJENT_CENA_PARTNER_DIALOG: "Plaća pacijent (%)",
  LABEL_FIKSNO_PLACA_PACIJENT_CENA_PARTNER_DIALOG: "Fiksno plaća pacijent",
  LABEL_FIKSNO_DODATAK_CENA_PARTNER_DIALOG: "Fiksni dodatak na cenu",
  LABEL_UPDATE_CENA_PARTNER: "Izmeni cenu",
  LABEL_DELETE_CENA_PARTNER: "Obriši cenu",
  LABEL_NAZIV_USLUGE_PARTNER: "Naziv usluge",
  LABEL_VAZI_OD_PARTNER: "Važi od",
  LABEL_VAZI_DO_PARTNER: "Važi do",
  LABEL_PLACA_PACIJENT_PARTNER: "Plaća pacijent",
  LABEL_PLACA_PACIJENT_FIKSNO_PARTNER: "Plaća pacijent fiksno",
  LABEL_CENA_PARTNER: "Cena",
  LABEL_PORESKA_STOPA_PARTNER: "Poreska stopa",
  LABEL_POPUST_OD_PARTNER: "Popust od",
  LABEL_POPUST_DO_PARTNER: "Popust do",
  LABEL_UDEO_PACIJENTA_OD_PARTNER: "Udeo pacijenta od",
  LABEL_UDEO_PACIJENTA_DO_PARTNER: "Udeo pacijenta do",
  LABEL_FIKSNI_UDEO_OD_PARTNER: "Fiksni udeo od",
  LABEL_FIKSNI_UDEO_DO_PARTNER: "Fiksni udeo do",
  LABEL_CENA_OD_PARTNER: "Cena od",
  LABEL_CENA_DO_PARTNER: "Cena do",
  LABEL_DEO_PLACA_PACIJENT: "Deo koji plaća pacijent",
  PARTNER_MESSAGE_CREATE_SUCCESS: "Uspešno ste kreirali partnera.",
  PARTNER_MESSAGE_UPDATE_SUCCESS: "Uspešno ste izmenili partnera.",
  PARTNER_MESSAGE_DELETE_SUCCESS: "Uspešno ste obrisali partnera.",
  PARTNER_VAZI_DO_VALIDATION_ERROR_MESSAGE: "Važi Do mora biti posle datuma Važi Od",
  LABEL_PARTNERI_TIP_PARTNERA: "Tip partnera",

  // NAPLATA
  LABEL_NAPLATA: "Naplata",
  LABEL_SPISAK_NAPLATA: "Spisak",
  LABEL_UPLATIOCI_NAPLATA: "Uplatioci",
  LABEL_RACUNI_NAPLATA: "Računi",
  LABEL_NASTAVI_NAPLATA: "Nastavi",
  LABEL_BRZA_NAPLATA: "Brza naplata",
  LABEL_NAPLATI: "Naplati",
  LABEL_SNIMI_NAPLATA: "Snimi",
  LABEL_BROJ_PROTOKOLA_NAPLATA: "Broj protokola",
  LABEL_PARTNER_NAPLATA: "Partner",
  LABEL_LEKAR_NAPLATA: "Lekar",
  LABEL_TIP_PREGLEDA_NAPLATA: "Tip pregleda",
  LABEL_DATUM_NAPLATA: "Datum pregleda",
  LABEL_PREGLED_REALIZOVAN_NAPLATA: "Pregled realizovan",
  LABEL_AMBULANTA_NAPLATA: "Ambulanta",
  LABEL_PACIJENT_NAPLATA: "Pacijent",
  LABEL_PRIKAZ_NAPLATA: "Prikaz",
  LABEL_STATUS_RACUNA_NAPLATA: "Status računa",
  LABEL_RACUN_NAPLATIV_NAPLATA: "Naplativ",
  LABEL_UPLATILAC_NAPLATA: "Uplatilac",
  LABEL_POPUST_NAPLATA: "Popust",
  LABEL_PLACA_NAPLATA: "Plaća",
  LABEL_NAPOMENA_NAPLATA: "Napomena",
  LABEL_OBRISI_UPLATIOCA: "Obriši uplatioca",
  LABEL_UMANJENJE_RACUNA: "Umanjenje računa",
  LABEL_CEK: "Ček",
  LABEL_GOTOVINA: "Gotovina",
  LABEL_VAUCER: "Vaučer",
  LABEL_PRENOS_NA_RACUN: "Prenos na račun",
  LABEL_INSTANT: "Instant",
  LABEL_DRUGO_BEZGOTOVINSKO: "Drugo bezgotovinsko",
  LABEL_KARTICA: "Kartica",
  LABEL_RACUN_ZA_UPLATIOCA_NAPLATA: "Račun za uplatioca",
  LABEL_REDNI_BROJ_NAPLATA: "Redni broj",
  LABEL_KOLICINA_NAPLATA: "Količina",
  LABEL_VREME_PRUZANJA_NAPLATA: "Vreme pružanja",
  LABEL_BEZ_POPUSTA_NAPLATA: "Bez popusta",
  LABEL_KONTAKT_REQUIRED_NAPLATA: "Morate izabrati pregled",
  LABEL_RACUN_KREIRAN_SUCCESS: "Uspešno kreiran račun",
  LABEL_UKUPNA_CENA_NAPLATA: "Ukupna cena",
  LABEL_KONTAKT_USLUGE_UTROSCI_NAPLATA: "Usluge i utrošci pregleda",
  LABEL_DRUGI_KONTAKTI_USLUGE_UTROSCI_NAPLATA: "Usluge i utrošci sa ostalih kontakata",
  LABEL_BEZ_POPUSTA: "Bez popusta",
  LABEL_ZBIR_PROCENATA_NAPLATA: "Zbir procenata za plaćanje usluge/utroška mora biti 100",
  LABEL_UPLATIOCI_REQUIRED: "Niste popunili sve uplatioce",
  LABEL_IZNOS_RACUNA_REQUIRED: "Niste uneli tačan iznos računa",
  LABEL_UKUPNO_NAPLATA: "Ukupno",
  LABEL_CENA_SA_POPUSTIMA: "Cena sa popustom",
  LABEL_ZBIR_CENA_NAPLATA: "Niste uneli tačan iznos",
  LABEL_PROCENAT_NULA_NAPLATA: "Svi uneti procenti moraju biti veći od 0",
  LABEL_NACINI_PLACANJA_REQUIRED: "Nije moguće izvršiti naplatu jer ne postoje definisani načini plaćanja.",
  LABEL_NAPLATA_PREKOPIRAJ_PREOSTALI_IZNOS: "Prekopiraj preostali iznos",
  LABEL_INFO_OBRACUNATA_CENA: "Cena je izračunata po srednjem kursu NBS na dan ",
  LABEL_WARNING_PROMENA_POPUSTA: "Promenom popusta zanemaruje se partnerova cena za datu uslugu.",
  LABEL_SPECIFIKACIJE_RACUNA_NAPLATA: "Specifikacije računa",
  LABEL_SPECIFIKACIJE_RACUNA_NAPLATA_WARNING_POPUP: "Da biste odštampali specifikaciju računa, potrebno je da dozvolite popup na pretraživaču.",

  // OBAVESTENJA ZA PACIJENTE
  LABEL_OBAVESTENJA_ZA_PACIJENTE: "Obaveštenja za pacijente",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TITLE_ADD: "Dodaj obaveštenje",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TITLE_UPDATE: "Izmeni obaveštenje",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TEKST: "Tekst obaveštenja",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_NASLOV: "Naslov",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_UNESITE_TEKST: "Unesite tekst obaveštenja",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_POSALJI: "Pošalji",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_DELETE_CONFIRM_MESSAGE: "Da li ste sigurni da želite da obrišete obaveštenje?",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_CREATE_SUCCESS_MESSAGE: "Uspešno ste kreirali grupno obaveštenje.",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_EMPTY_DATA: "Nema dodatih grupnih obaveštenja.",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_DELETE_SUCCESS_MESSAGE: "Uspešno ste obrisali grupno obaveštenje.",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_UPDATE_SUCCESS_MESSAGE: "Uspešno ste izmenili grupno obaveštenje.",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_MESSAGE: "Uspešno ste poslali grupno obaveštenje.",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_LABEL: "Obaveštenje je poslato!",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_DATE: "Poslato",
  LABEL_OBAVESTENJA_TAB_ZA_SLANJE: "Za slanje",
  LABEL_OBAVESTENJA_TAB_POSLATA: "Poslata",
  LABEL_VRSTA_OBAVESTENJA: "Vrsta obaveštenja",
  LABEL_DATUM_PRIPREME_OBAVESTENJA: "Datum pripreme",
  LABEL_DATUM_SLANJA_OBAVESTENJA: "Datum slanja",
  LABEL_TEKST_OBAVESTENJA: "Tekst obaveštenja",
  LABEL_OBAVESTENJE_KREIRANO_VREME: "Kreirano",
  LABEL_OBAVESTENJE_POSLATO_VREME: "Poslato",
  LABEL_OBAVESTENJE_KREIRANO_U: "u",
  LABEL_OBAVESTENJE_POSLEDNJE_AZURIRANO_U: "u",
  LABEL_OBAVESTENJE_POSLEDNJE_AZURIRANO: "Ažurirano",
  LABEL_OBAVESTENJE_OBUHVACENO_PACIJENATA: "Obuhvaćeno pacijenata",
  LABEL_OBAVESTENJE_GODINA: "godina",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_CONFIRM_MESSAGE: "Da li ste sigurni da želite da pošaljete obaveštenje?",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_BROJ_PRIMALACA: "Broj primalaca",
  LABEL_OBAVESTENJE_DIJAGNOZA_USLUGA_NOTE: "Pacijent će se naći u listi primaoca ako ima bar jednu dijagnozu i jednu uslugu od odabranih.",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_POL_REQUIRED: "Morate odabrati makar jedan pol",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_DATUM_AUTOMATSKOG_SLANJA: "Datum autom.slanja",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_TEXT: "Poruka će biti poslata",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_DATE: "Datum",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_TIME: "Vreme",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_NO_PATIENTS: "Ne postoji nijedan primalac za slanje obaveštenja",
  LABEL_OBAVESTENJE_ZA_PACIJENTE_SEND_NOTE:
    "Slanje grupnih obaveštenja se vrši kroz funkcionalnost odloženog slanja kako ne bi došlo do zagušenja servisa, te obaveštenje neće biti poslato odmah po izvršenju akcije.",

  // ZAKAZIVANJE PREGLEDA
  LABEL_DODAJ_TERMIN: "Dodaj",

  // OBRASCI STAMPE PROTOKOLA
  LABEL_OBRASCI_STAMPE_PROTOKOLA: "Obrasci štampe protokola",
  LABEL_OBRASCI_STAMPE_PROTOKOLA_OPIS: "Opis",
  LABEL_OBRASCI_STAMPE_PROTOKOLA_NAZIV_JRXML: "Naziv JRXML dokumenta",
  LABEL_ADD_OBRASCI_STAMPE_PROTOKOLA: "Dodaj obrazac",
  LABEL_EDIT_OBRASCI_STAMPE_PROTOKOLA: "Izmeni obrazac",
  LABEL_DELETE_OBRASCI_STAMPE_PROTOKOLA: "Obriši obrazac",
  LABEL_OBRAZAC_STAMPE_CREATE: "Kreiranje obrasca štampe",
  LABEL_OBRAZAC_STAMPE_JRXML: "JRXML dokument",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_CREATE_SUCCESS_MESSAGE: "Uspešno ste kreirali obrazac štampe protokola",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_UPDATE_SUCCESS_MESSAGE: "Uspešno ste izmenili obrazac štampe protokola",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_DELETE_SUCCESS_MESSAGE: "Uspešno ste obrisali obrazac štampe protokola",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_UPDATE: "Izmena obrasca štampe protokola ",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_DELETE: "Brisanje obrasca štampe protokola ",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_CREATE: "Kreiranje obrasca štampe protokola ",

  // SPAJANJE PACIJENATA
  LABEL_SPAJANJE_PACIJENATA: "Spajanje pacijenata",
  LABEL_SPAJANJE_PACIJENATA_PREPISI: "Prepiši",
  LABEL_SPAJANJE_PACIJENATA_IME: "Ime",
  LABEL_SPAJANJE_PACIJENATA_PREZIME: "Prezime",
  LABEL_SPAJANJE_PACIJENATA_DATUM: "Datum rođenja",
  LABEL_SPAJANJE_PACIJENATA_PRETRAZI: "Pretraži",
  LABEL_SPAJANJE_PACIJENATA_SPOJI_PACIJENTE: "Spoji pacijente",
  LABEL_SPAJANJE_PACIJENATA_REZULTAT_PRETRAGE: "Rezultat pretrage ",
  LABEL_SPAJANJE_PACIJENATA_ID_PACIJENTA: "ID pacijenta",
  LABEL_SPAJANJE_PACIJENATA_DA_LI_STE_SIGURNI: "Da li ste sigurni da želite da spojite označene pacijente?",
  LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_1: "Posle spajanja izabranih pacijenata ",
  LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_2: "aktivan ",
  LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_3: "ostaje levi pacijent",
  LABEL_SPAJANJE_PACIJENATA_ARHIVA: "Arhiva spojenih pacijenata",
  LABEL_SPAJANJE_PACIJENATA_VREME_SPAJANJA: "Vreme spajanja",
  LABEL_SPAJANJE_PACIJENATA_TRENUTNI_PACIJENT: "Trenutni pacijent",
  LABEL_SPAJANJE_PACIJENATA_STARI_PACIJENT: "Stari pacijent",
  LABEL_SPAJANJE_PACIJENATA_DOSIJE: "Dosije",
  LABEL_SPAJANJE_PACIJENATA_BUTTON_RAZDVOJ: "Razdvoj",
  LABEL_RAZDVAJANJE_PACIJENATA_SUCCESS_MESSAGE: "Pacijenti uspešno razdvojeni",
  LABEL_RAZDVAJANJE_CONFIRMATION_MESSAGE: "Da li ste sigurni da želite da razvojite pacijente?",

  // SIFARNIK SPECIJALIZACIJA
  LABEL_SIFARNIK_SPECIJALIZACIJA: "Šifarnik specijalizacija",
  LABEL_SIFARNIK_SPECIJALIZACIJA_ADD: "Dodaj specijalizaciju",
  LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE: "Izmeni specijalizaciju",
  LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE: "Obriši specijalizaciju",
  LABEL_SIFARNIK_SPECIJALIZACIJA_CREATE: "Kreiraj specijalizaciju",
  LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE_TITLE: "Izmena specijalizacije ",
  LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE_TITLE: "Brisanje specijalizacije ",
  LABEL_SIFARNIK_SPECIJALIZACIJA_CREATE_SUCCESS_MESSAGE: "Uspešno ste kreirali specijalizaciju",
  LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE_SUCCESS_MESSAGE: "Uspešno ste izmenili specijalizaciju",
  LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE_SUCCESS_MESSAGE: "Uspešno ste obrisali specijalizaciju",
  LABEL_SPECIJALIZACIJE_LIST: "Specijalizacije",
  LABEL_RADNIK_SPECIJALIZACIJE_LIST: "Specijalizacije radnika",
  LABEL_SPECIJALIZACIJE_SEARCH: "Pretraga specijalizacija",
  LABEL_RADNIK_SPECIJALIZACIJE_SEARCH: "Pretraga specijalizacija radnika",
  LABEL_USLUGA_SPECIJALIZACIJE_LIST: "Specijalizacije usluge",
  LABEL_USLUGA_SPECIJALIZACIJE_SEARCH: "Pretraga specijalizacija usluge",

  // PERCENTILNA KRIVA
  LABEL_PERCENTILNA_KRIVA_ODABERITE_UZRAST: "Odaberite uzrast",
  LABEL_PERCENTILNA_KRIVA_Z_SKOROVI: "z-skorovi",
  LABEL_PERCENTILNA_KRIVA_PERCENTILI: "percentili",
  LABEL_PERCENTILNA_KRIVA_POL_NIJE_UNET: "Percentilna kriva se može prikazati samo za muški ili ženski pol",
  LABEL_PERCENTILNA_KRIVA_DATUM_STAMPE: "Datum štampe",

  LABEL_NADJIMED_VEZE: "Veze",
  LABEL_NADJIMED: "NađiMeD",
  LABEL_PARTNERI_PLACEHOLDER: "Odaberite partnera",
  LABEL_DOSTUPNI_ZAKAZIVACI: "Dostupni zakazivači",
  LABEL_EKSTERNI_ZAKAZIVACI_PARTNERI: "Partneri",
  LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE: "Status veze",
  LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_POVEZAN: "POVEZAN",
  LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_ZATRAZENO: "ZATRAŽENO",
  LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_CEKA_NA_POTVRDU: "ČEKA NA POTVRDU",
  LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_NEPOVEZAN: "NEPOVEZAN",
  LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_CONNECT: "Poveži",
  LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_DISCONNECT: "Razveži",
  LABEL_EKSTERNI_ZAKAZIVACI_DA_LI_STE_SIGURNI_RAZVEZIVANJE: "Da li ste sigurni da želite da uklonite vezu sa zakazivačem?",
  LABEL_EKSTERNI_ZAKAZIVACI_DA_LI_STE_SIGURNI_POVEZIVANJE: "Da li ste sigurni da želite da se povežete sa zakazivačem?",
  LABEL_EKSTERNI_ZAKAZIVACI_NEPOVRATNA_AKCIJA: "Nepovratna akcija",
  LABEL_EKSTERNI_ZAKAZIVACI_USPESNO_POVEZIVANJE: "Uspešno ste povezali zakazivače",
  LABEL_EKSTERNI_ZAKAZIVACI_USPESNO_RAZVEZIVANJE: "Uspešno ste razvezali zakazivače",
  LABEL_NAZIV_ZAKAZIVACA: "Zakazivač",
  LABEL_NADJIMED_RADNI_PERIODI: "Radni periodi su obavezni za korišćenje eksternog zakazivanja",

  // PRODAJNI PAKETI
  LABEL_PRODAJNI_PAKETI: "Prodajni paketi",
  LABEL_PRODAJNI_PAKET_ADD: "Dodaj prodajni paket",
  LABEL_PRODAJNI_PAKET_SEARCH: "Pretraga prodajnih paketa",
  LABEL_PRODAJNI_PAKETI_CREATE: "Kreiranje prodajnog paketa",
  LABEL_PRODAJNI_PAKETI_UPDATE: "Izmena prodajnog paketa",
  LABEL_PRODAJNI_PAKETI_DELETE: "Brisanje prodajnog paketa",
  LABEL_PRODAJNI_PAKETI_NAZIV: "Naziv",
  LABEL_PRODAJNI_PAKETI_CENA: "Cena",
  LABEL_PRODAJNI_PAKETI_STATUS: "Status",
  LABEL_PRODAJNI_PAKETI_BROJ_NALOGA: "Broj naloga",
  LABEL_PRODAJNI_PAKETI_STORAGE_LIMIT: "Ograničenje skladišta",
  LABEL_PRODAJNI_PAKETI_PERIOD_PLACANJA: "Period plaćanja",
  LABEL_PRODAJNI_PAKETI_OPIS: "Opis",
  LABEL_PRODAJNI_PAKETI_PREPORUCEN: "Preporučen",
  LABEL_PRODAJNI_PAKETI_JAVAN: "Javan",
  LABEL_PRODAJNI_PAKETI_MESSAGE_CREATE_SUCCESS: "Uspešno ste kreirali prodajni paket",
  LABEL_PRODAJNI_PAKETI_MESSAGE_UPDATE_SUCCESS: "Uspešno ste izmenili prodajni paket",
  LABEL_PRODAJNI_PAKETI_MESSAGE_DELETE_SUCCESS: "Uspešno ste obrisali prodajni paket",

  // INICIJALIZACIJA USTANOVE
  LABEL_INICIJALIZACIJA_PODESAVANJE_USTANOVE: "Podešavanje ustanove",
  LABEL_INICIJALIZACIJA_USTANOVA: "1. Ustanova",
  LABEL_INICIJALIZACIJA_ORG_JEDINICE: "2. Organizacione jedinice",
  LABEL_INICIJALIZACIJA_RADNICI: "3. Radnici",
  LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA: "4. Tipovi pregleda",
  LABEL_INICIJALIZACIJA_USLUGE: "5. Usluge",
  LABEL_BUTTON_PRETHODNI: "Prethodni",
  LABEL_BUTTON_SLEDECI: "Sledeći",
  LABEL_BUTTON_ZAVRSI: "Završi",
  LABEL_INICIJALIZACIJA_DIALOG_TITLE: "Inicijalizacija u toku",
  LABEL_INICIJALIZACIJA_DIALOG_TEXT_1: "Korišćenje ovog naloga biće omogućeno nakon završetka inicijalizacije ustanove.",
  LABEL_INICIJALIZACIJA_DIALOG_TEXT_2: "Molimo pokušajte kasnije.",
  LABEL_INICIJALIZACIJA_USTANOVA_TOOLTIP: "Nakon završetka svih koraka podešavanjima ustanove možete pristupiti kroz Glavni meni/Administracija/Ustanova",
  LABEL_INICIJALIZACIJA_USTANOVA_SUCCESS_MESSAGE: "Uspešno ste sačuvali podatke o ustanovi",
  LABEL_INICIJALIZACIJA_ORG_JEDINICA_TOOLTIP: "Nakon završetka svih koraka podešavanjima ustanove možete pristupiti kroz Glavni meni/Administracija/Organizacione jedinice",
  LABEL_KREIRANE_ORG_JEDINICE: "Kreirane organizacione jedinice",
  LABEL_OBRISI_UNOS: "Obriši unos",
  LABEL_KREIRAJ: "Kreiraj",
  LABEL_INICIJALIZACIJA_ORG_JEDINICA_DELETE_CONFIRM_MESSAGE: "Da li ste sigurni da želite da obrišete organizacionu jedinicu?",
  LABEL_INICIJALIZACIJA_ORG_JEDINICA_REQUIRED: "Potrebno je da kreirate bar jednu organizacionu jedinicu da biste nastavili dalje",
  LABEL_SNIMI: "Snimi",
  LABEL_INICIJALIZACIJA_RADNICI_TOOLTIP: "Nakon završetka svih koraka podešavanjima ustanove možete pristupiti kroz Glavni meni/Administracija/Radnici",
  LABEL_INICIJALIZACIJA_RADNICI_DELETE_CONFIRM_MESSAGE: "Da li ste sigurni da želite da obrišete radnika?",
  LABEL_KREIRANI_RADNICI: "Kreirani radnici",
  LABEL_INICIJALIZACIJA_USLUGA_TOOLTIP: "Nakon završetka svih koraka podešavanjima ustanove možete pristupiti kroz Glavni meni/Administracija/Šifarnici/Usluge",
  LABEL_INICIJALIZACIJA_USLUGA_DELETE_CONFIRM_MESSAGE: "Da li ste sigurni da želite da obrišete uslugu?",
  LABEL_KREIRANE_USLUGE: "Kreirane usluge",
  LABEL_OZNACI_SVE: "Označi sve",
  LABEL_UBACI_U: "Ubaci u: ",
  LABEL_TIP_PREGLEDA_SAVE_CONFIRMATION_WARNING: "Izmena izbora tipa pregleda nije sačuvana.\nKliknite na dugme Snimi da biste sačuvali izmene.",
  LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_IN_PROGRESS_TEXT_1: "Čuvanje podataka u toku.",
  LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_IN_PROGRESS_TEXT_2: "Molimo sačekajte...",
  LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_FINISHED_TEXT_1: "Podaci uspesno sačuvani.",
  LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_FINISHED_TEXT_2: "Nastavite klikom na tamnu površinu.",
  LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_TOOLTIP: "Nakon završetka svih koraka podešavanjima ustanove možete pristupiti kroz Glavni meni/Administracija/Tipovi pregleda",
  LABEL_TIPOVI_PREGLEDA_INICIJALIZACIJA_UPDATE_SUCCESS_MESSAGE: "Uspešno ste izmenili tipove pregleda na ustanovi",
  LABEL_INICIJALIZACIJA_SVE_ORGANIZACIONE_JEDINICE: "Sve organizacione jedinice",
  LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_REQUIRED: "Potrebno je izabrati bar jedan tip pregleda da biste nastavili dalje",
  LABEL_INICIJALIZACIJA_USTANOVE_UNSAVED_CHANGES_WARNING: "Niste snimili izmene. Da li ste sigurni da želite da nastavite?",

  GLOBALNE_DATOTEKE: "Globalne datoteke",
  GLOBALNA_DATOTEKA_UPDATE_SUCCESS: "Sadržaj globalne datoteke je uspešno izmenjen",
  GLOBALNA_DATOTEKA_CHOOSE: "Izaberite datoteku",
  GLOBALNA_DATOTEKA_DIALOG_HEADER: "Izmena sadržaja globalne datoteke",

  LABEL_TIP_TERMINA: "Tip termina",
  VERZIJE: "Verzije",
  VERZIJA_STAVKE: "Stavke verzije",
  DATUM_VERZIJE: "Datum verzije",
  DODAJ_VERZIJU: "Dodaj verziju",
  NOVA_VERZIJA: "Nova verzija",
  VERZIJA_CREATE_SUCCESS: "Verzija je uspešno kreirana",
  VERZIJA_UPDATE_SUCCESS: "Verzija je uspešno izmenjena",
  VERZIJA_DELETE_SUCCESS: "Verzija je uspešno obrisana",
  VERZIJA_DELETE_CONFIRM_MESSAGE: "Da li ste sigurni da želite da obrišete verziju?",
  VERZIJA_STAVKA_CREATE_SUCCESS: "Stavka verzije uspešno kreirana",
  VERZIJA_STAVKA_UPDATE_SUCCESS: "Stavka verzije uspešno izmenjena",
  VERZIJA_STAVKA_DELETE_SUCCESS: "Stavka verzije uspešno obrisana",
  VERZIJA_STAVKA_DELETE_CONFIRM_MESSAGE: "Da li ste sigurni da želite da obrišete stavku verzije?",
  STATUS_VERZIJE: "Status verzije",
  DODAJ_VERZIJA_STAVKU: "Dodaj stavku verzije",
  VERZIJA_STAVKA_OPIS_MANDATORY: "Opis je obavezno polje.",
  VERZIJA_NAZIV_MANDATORY: "Naziv je obavezno polje.",
  ISTORIJA_VERZIJA: "Istorija verzija",

  GRID: "Grid",
  LISTA: "Lista",
  TABELA: "Tabela",
};

export default Labels;
