import axios from "axios";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import { handleAxiosCallError, useEffectOnce } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import OrganizacionaJedinicaSpecijalizacijaCreateDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaSpecijalizacijaCreateDto";
import OrganizacionaJedinicaSpecijalizacijaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaSpecijalizacijaReadDto";
import SifarnikSpecijalizacijaReadDto from "../../../../model/sifarnik/sifarnik-specijalizacija/SifarnikSpecijalizacijaReadDto";

interface CrudOrganizacionaJedinicaSpecijalizacijeLogicalType {
  isLoading: boolean;
  specijalizacijeList: SifarnikSpecijalizacijaReadDto[];
  organizacionaJedinicaSpecijalizacijeList: OrganizacionaJedinicaSpecijalizacijaReadDto[];
  sourceSelection: SifarnikSpecijalizacijaReadDto[];
  setSourceSelection: React.Dispatch<React.SetStateAction<SifarnikSpecijalizacijaReadDto[]>>;
  targetSelection: OrganizacionaJedinicaSpecijalizacijaReadDto[];
  setTargetSelection: React.Dispatch<React.SetStateAction<OrganizacionaJedinicaSpecijalizacijaReadDto[]>>;
  onMoveToTarget: (specijalizacije: SifarnikSpecijalizacijaReadDto[]) => void;
  onMoveToSource: (specijalizacije: OrganizacionaJedinicaSpecijalizacijaReadDto[]) => void;
}

interface UseParamsType {
  orgJedId?: string;
}

export default function CrudOrganizacionaJedinicaSpecijalizacijeLogical(orgJedNaziv: string | undefined): CrudOrganizacionaJedinicaSpecijalizacijeLogicalType {
  const { showMessage } = useContext(AppContext);
  const postLogHighLevel = useLogHighLevel();
  const Labels = useLabels();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [specijalizacijeList, setSpecijalizacijeList] = useState<SifarnikSpecijalizacijaReadDto[]>([]);
  const [organizacionaJedinicaSpecijalizacijeList, setOrganizacionaJedinicaSpecijalizacijeList] = useState<OrganizacionaJedinicaSpecijalizacijaReadDto[]>([]);
  const [sourceSelection, setSourceSelection] = useState<SifarnikSpecijalizacijaReadDto[]>([]);
  const [targetSelection, setTargetSelection] = useState<OrganizacionaJedinicaSpecijalizacijaReadDto[]>([]);

  const orgJedId = Number(useParams<keyof UseParamsType>()["orgJedId"]);

  const { axiosSuperadminGetSifarnikSpecijalizacija } = SuperadminController();
  const { axiosGetSpecijalizacijeList, axiosCreateSpecijalizacijeForOrganizacionaJedinica, axiosDeleteSpecijalizacijeForOrganizacionaJedinica } = OrganizacionaJedinicaController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    setIsLoading(true);
    const requestSpecijalizacijaList = axiosSuperadminGetSifarnikSpecijalizacija();
    const requestOrganizacionaJedinicaSpecijalizacijeList = orgJedId ? axiosGetSpecijalizacijeList(orgJedId) : null;

    axios
      .all([requestSpecijalizacijaList, requestOrganizacionaJedinicaSpecijalizacijeList])
      .then(
        axios.spread((responseSpecijalizacijaList, responseOrganizacionaJedinicaSpecijalizacijeList) => {
          setOrganizacionaJedinicaSpecijalizacijeList(responseOrganizacionaJedinicaSpecijalizacijeList?.data.data ?? []);
          const organizacionaJedinicaSpecijalizacijeIdList = responseOrganizacionaJedinicaSpecijalizacijeList?.data.data.map(
            (specijalizacija: OrganizacionaJedinicaSpecijalizacijaReadDto) => specijalizacija?.idRepositorySifarnikSpecijalizacija
          );
          setSpecijalizacijeList(
            responseSpecijalizacijaList?.data.data.filter((specijalizacija: SifarnikSpecijalizacijaReadDto) => !organizacionaJedinicaSpecijalizacijeIdList?.includes(specijalizacija.id))
          );
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onMoveToTarget = (specijalizacije: SifarnikSpecijalizacijaReadDto[]) => {
    setIsLoading(true);

    let organizacionaJedinicaSpecijalizacijaChangeArray: OrganizacionaJedinicaSpecijalizacijaCreateDto[] = [];
    specijalizacije.forEach((specijalizacija: SifarnikSpecijalizacijaReadDto) => {
      organizacionaJedinicaSpecijalizacijaChangeArray.push({
        idRepositorySifarnikSpecijalizacija: specijalizacija?.id,
      });
    });

    axiosCreateSpecijalizacijeForOrganizacionaJedinica(orgJedId, organizacionaJedinicaSpecijalizacijaChangeArray)
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        const specijalizacijeNazivi = specijalizacije.map((spec) => spec.naziv).join(", ");
        const sigleOrMultipleLabel =
          specijalizacije.length > 1 ? Labels.LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE_2 : Labels.LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_2;
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_1 + orgJedNaziv + sigleOrMultipleLabel + specijalizacijeNazivi + ".");
        fetchData();
        setSourceSelection([]);
        setTargetSelection([]);
      });
  };

  const onMoveToSource = (specijalizacije: OrganizacionaJedinicaSpecijalizacijaReadDto[]) => {
    setIsLoading(true);
    let organizacionaJedinicaSpecijalizacijaChangeArray: OrganizacionaJedinicaSpecijalizacijaCreateDto[] = [];
    specijalizacije.forEach((specijalizacija: OrganizacionaJedinicaSpecijalizacijaReadDto) => {
      organizacionaJedinicaSpecijalizacijaChangeArray.push({
        idRepositorySifarnikSpecijalizacija: specijalizacija?.sifarnikSpecijalizacija?.id,
      });
    });
    let sifarnikSpecijalizacijaIdList: Array<number> = [];
    organizacionaJedinicaSpecijalizacijaChangeArray.map((spec) => sifarnikSpecijalizacijaIdList.push(spec?.idRepositorySifarnikSpecijalizacija!));

    axiosDeleteSpecijalizacijeForOrganizacionaJedinica(orgJedId, [...sifarnikSpecijalizacijaIdList])
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        const specijalizacijeNazivi = specijalizacije.map((spec) => spec?.sifarnikSpecijalizacija?.naziv).join(", ");
        const sigleOrMultipleLabel =
          specijalizacije.length > 1 ? Labels.LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE_2 : Labels.LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_2;
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_1 + orgJedNaziv + sigleOrMultipleLabel + specijalizacijeNazivi + ".");
        fetchData();
        setSourceSelection([]);
        setTargetSelection([]);
      });
  };

  return {
    isLoading,
    specijalizacijeList,
    organizacionaJedinicaSpecijalizacijeList,
    sourceSelection,
    setSourceSelection,
    targetSelection,
    setTargetSelection,
    onMoveToTarget,
    onMoveToSource,
  };
}
