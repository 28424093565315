import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import GrupnoObavestenjeCreateDto from "../../model/obavestenja-pacijenti/GrupnoObavestenjeCreateDto";
import GrupnoObavestenjeUpdateDto from "../../model/obavestenja-pacijenti/GrupnoObavestenjeUpdateDto";

interface GrupnoObavestenjeControllerType {
  axiosGetGrupnaObavestenja: (params?: {
    obavestenjeStatusSifra: string;
    page?: number;
    size?: number;
    obavestenjeTipSifra?: string;
    datum?: Date | string;
    searchString?: string;
  }) => Promise<AxiosResponse<any>>;
  axiosCreateGrupnoObavestenje: (obavestenje: GrupnoObavestenjeCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteGrupnoObavestenje: (obavestenjeId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateGrupnoObavestenje: (obavestenje: GrupnoObavestenjeUpdateDto) => Promise<AxiosResponse<any>>;
  axiosSendGrupnoObavestenje: (obavestenjeId: number) => Promise<AxiosResponse<any>>;
  axiosGetGrupnoObavestenje: (obavestenjeId: number) => Promise<AxiosResponse<any>>;
}

export function GrupnoObavestenjeController(): GrupnoObavestenjeControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetGrupnaObavestenja = (params?: { obavestenjeStatusSifra: string; page?: number; size?: number; obavestenjeTipSifra?: string; datum?: Date | string; searchString?: string }) => {
    return axios.get(Endpoint.GRUPNO_OBAVESTENJE_LIST, axiosConfig(authData!.token, params));
  };

  const axiosCreateGrupnoObavestenje = (obavestenje: GrupnoObavestenjeCreateDto) => {
    return axios.post(Endpoint.GRUPNO_OBAVESTENJE_LIST, obavestenje ? obavestenje : {}, axiosConfig(authData!.token));
  };

  const axiosDeleteGrupnoObavestenje = (obavestenjeId: number) => {
    return axios.delete(`${Endpoint.GRUPNO_OBAVESTENJE_LIST}/${obavestenjeId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateGrupnoObavestenje = (obavestenje: GrupnoObavestenjeUpdateDto) => {
    return axios.put(`${Endpoint.GRUPNO_OBAVESTENJE_LIST}/${obavestenje.id}`, obavestenje, axiosConfig(authData!.token));
  };

  const axiosSendGrupnoObavestenje = (obavestenjeId: number) => {
    return axios.put(`${Endpoint.GRUPNO_OBAVESTENJE_LIST}/send/${obavestenjeId}`, {}, axiosConfig(authData!.token));
  };

  const axiosGetGrupnoObavestenje = (obavestenjeId: number) => {
    return axios.get(`${Endpoint.GRUPNO_OBAVESTENJE_LIST}/${obavestenjeId}`, axiosConfig(authData!.token));
  };

  return {
    axiosGetGrupnaObavestenja,
    axiosCreateGrupnoObavestenje,
    axiosDeleteGrupnoObavestenje,
    axiosUpdateGrupnoObavestenje,
    axiosSendGrupnoObavestenje,
    axiosGetGrupnoObavestenje,
  };
}
