import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Editor } from "primereact/editor";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import { useEffect, useRef } from "react";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { CALENDAR_DATE_FORMAT, formatDate, isDateTimeFormatValid, removeAllMasks } from "../../../../infrastructure/system/Utils";
import actionsAddPatientNotification from "../../../../infrastructure/system/hooks/dialog-add-patient-notification/actionsAddPatientNotification";
import { InitialStateAddPatientNotificationType } from "../../../../infrastructure/system/hooks/dialog-add-patient-notification/initialStateAddPatientNotification";
import EnumTipObavestenja from "../../../../model/obavestenja-pacijenti/EnumTipObavestenja";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import SifarnikMkbDto from "../../../../model/sifarnik/SifarnikMkbDto";
import SifarnikUslugaDto from "../../../../model/sifarnik/SifarnikUslugaDto";
import SkeletonAccordionList from "../../../app/skeleton/SkeletonAccordionList";
import SkeletonCheckboxItem from "../../../app/skeleton/SkeletonCheckboxItem";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import DialogComponent from "../../../dialog-component/DialogComponent";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";
import CalendarComponent from "../../calendar-component/CalendarComponent";

interface DialogObavestenjePacijentType {
  state: InitialStateAddPatientNotificationType;
  dispatch: React.Dispatch<{ type: actionsAddPatientNotification }> | any;
  onCreate: () => void;
  onUpdate: () => void;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  mkbDijagnozeList: Array<SifarnikMkbDto | undefined>;
  searchMkbDijagnoza: (e: AutoCompleteCompleteEvent) => void;
  searchSifarnikUsluga: (e: AutoCompleteCompleteEvent) => void;
  sifarnikUslugaList: Array<SifarnikUslugaDto>;
  getPatientIncluded: () => void;
}

export default function DialogObavestenjePacijent(props: DialogObavestenjePacijentType) {
  const { state, dispatch, onCreate, onUpdate, invalidFields, setInvalidFields, mkbDijagnozeList, searchMkbDijagnoza, sifarnikUslugaList, searchSifarnikUsluga, getPatientIncluded } = props;
  const Labels = useLabels();
  const calendarRef = useRef<HTMLDivElement | any>(null);

  useEffect(() => {
    if (state?.patientsIncludedSearch !== undefined) getPatientIncluded();
    // eslint-disable-next-line
  }, [state?.patientsIncludedSearch]);

  const renderHeader = () => {
    let title = "";
    if (state.operation === EntityOperation.CREATE) {
      title = Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TITLE_ADD;
    } else if (state.operation === EntityOperation.UPDATE) {
      title = Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TITLE_UPDATE;
    }
    return <div className="flex justify-content-start">{title}</div>;
  };

  const renderFooter = () => {
    let classTemp, icon, label, action;

    if (state.operation === EntityOperation.CREATE) {
      label = Labels.LABEL_DODAJ_PARTNER;
      icon = "pi pi-plus";
      classTemp = "mr-2 m-1";
      action = onCreate;
    } else {
      label = Labels.BUTTON_UPDATE;
      icon = "pi pi-check";
      classTemp = "mr-2 m-1";
      action = onUpdate;
    }

    return (
      <div className="flex justify-content-end">
        <Button
          label={state?.operation !== EntityOperation.READ ? Labels.BUTTON_CANCEL : Labels.BUTTON_CLOSE}
          icon="pi pi-times"
          className="button-danger m-1 p-button-outlined"
          onClick={() => {
            dispatch({ type: actionsAddPatientNotification.CLOSE_DIALOG });
            removeAllMasks();
          }}
        />
        {state.operation !== EntityOperation.READ && <Button label={label} tooltip={label} className={classTemp} icon={icon} onClick={action} />}
      </div>
    );
  };

  const renderEditorHeader = () => {
    return (
      <span className="ql-formats">
        <select className="ql-size" defaultValue="medium">
          <option value="medium">Normal</option>
          <option value="large">Subheading</option>
          <option value="huge">Heading</option>
        </select>
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
          <button className="ql-strike" aria-label="Strikethrough"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-align" value="" aria-label="Align left"></button>
          <button className="ql-align" value="center" aria-label="Align center"></button>
          <button className="ql-align" value="right" aria-label="Align right"></button>
          <button className="ql-align" value="justify" aria-label="Align justify"></button>
        </span>
      </span>
    );
  };

  const header = renderEditorHeader();

  const getNextThreeMontsMaxDate = () => {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 3);
    return currentDate;
  };

  return (
    <DialogComponent
      header={renderHeader}
      visible={state.isDialogOpen}
      footer={renderFooter}
      onHide={() => {
        dispatch({ type: actionsAddPatientNotification.CLOSE_DIALOG });
        setInvalidFields(undefined);
        removeAllMasks();
      }}
      blockScroll={true}
      className="w-11 sm:w-8 md:w-8 lg:w-5 dialogGrupnoObavestenje"
      contentClassName="h-60rem"
      content={
        <div className="pt-4">
          <div className="flex flex-row flex-wrap align-items-center">
            <div className="col-12 sm:col-6 p-fluid p-0 flex">
              {state?.obavestenjeTypeListLoading || state?.obavestenjeLoading ? (
                <SkeletonAccordionList />
              ) : (
                state?.obavestenjeTypeList.map((tip: EnumBaseReadDto, key: number) => (
                  <div key={key} className="mr-5 mt-3">
                    <RadioButton
                      inputId={tip?.sifra}
                      value={tip}
                      name="obavestenjeTip"
                      checked={state?.obavestenje?.obavestenjeTip?.sifra === tip?.sifra}
                      onChange={(e) => {
                        dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_CHANGE, fieldName: e.target.name, value: e.value });
                        dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "obavestenjeTipSifra", value: e.value });
                        setInvalidFields((prev) => ({ ...prev, obavestenjeTip: false }));
                      }}
                      disabled={state.operation === EntityOperation.UPDATE || state.operation === EntityOperation.READ}
                      className={invalidFields?.obavestenjeTip && "p-invalid"}
                    />
                    <label className="cursor-pointer mx-2" htmlFor={tip.sifra}>
                      {tip.naziv}
                    </label>
                  </div>
                ))
              )}
            </div>
            {invalidFields?.obavestenjeTip && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            {state?.patientsIncludedLoading || state?.obavestenjeLoading ? (
              <div className="col-12 sm:col-6 p-fluid p-0 flex font-bold justify-content-end">
                {Labels.LABEL_OBAVESTENJE_OBUHVACENO_PACIJENATA + ": "} <ProgressSpinner className="m-0" style={{ width: "1.5rem", height: "1.5rem" }} />
              </div>
            ) : (
              <div className="col-12 sm:col-6 p-fluid p-0 flex font-bold justify-content-end">{Labels.LABEL_OBAVESTENJE_OBUHVACENO_PACIJENATA + ": " + state?.obavestenje?.ukupnoPacijenata}</div>
            )}
          </div>
          <Divider className="divider my-4" />
          <div className="flex flex-row flex-wrap mb-4">
            <div className="col-12 sm:col-3 p-fluid p-0">{Labels.POL}</div>
            <div className="col-12 sm:col-9 p-fluid p-0 flex">
              {state?.polListLoading || state?.obavestenjeLoading ? (
                <SkeletonCheckboxItem />
              ) : (
                state?.polList?.map((pol: EnumBaseReadDto, key: number) => (
                  <div key={key} className="mr-5">
                    <Checkbox
                      inputId={pol.sifra}
                      value={pol.sifra}
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch({
                            type: actionsAddPatientNotification.OBAVESTENJE_CHANGE,
                            fieldName: "grupnoObavestenjeFilter",
                            value: { ...state?.obavestenje?.grupnoObavestenjeFilter, polTrenutniList: state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList?.concat({ sifra: e.target.value }) },
                          });
                          dispatch({
                            type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH,
                            fieldName: "polTrenutniSifraList",
                            value: state?.patientsIncludedSearch?.polTrenutniSifraList?.concat(e.target.value),
                          });
                        } else {
                          dispatch({
                            type: actionsAddPatientNotification.OBAVESTENJE_CHANGE,
                            fieldName: "grupnoObavestenjeFilter",
                            value: {
                              ...state?.obavestenje?.grupnoObavestenjeFilter,
                              polTrenutniList: state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList?.filter((item: any) => item.sifra !== e.target.value),
                            },
                          });
                          dispatch({
                            type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH,
                            fieldName: "polTrenutniSifraList",
                            value: state?.patientsIncludedSearch?.polTrenutniSifraList?.filter((item: any) => item !== e.target.value),
                          });
                        }
                      }}
                      checked={state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList?.some((item: any) => item.sifra === pol.sifra)}
                      disabled={state.operation === EntityOperation.READ}
                    />
                    <label htmlFor={pol.sifra} className="cursor-pointer p-checkbox-label ml-2">
                      {pol.naziv}
                    </label>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="flex flex-row flex-wrap mb-4 align-items-center">
            <div className="col-12 sm:col-3 p-fluid p-0">{Labels.UZRAST}</div>
            <div className="col-12 sm:col-9 p-fluid p-0 flex align-items-center">
              {state?.obavestenjeLoading ? (
                <SkeletonInputItem />
              ) : (
                <>
                  <InputNumber
                    className="small-input"
                    value={state?.obavestenje?.grupnoObavestenjeFilter?.godineOd}
                    onChange={(e) => {
                      dispatch({
                        type: actionsAddPatientNotification.OBAVESTENJE_CHANGE,
                        fieldName: "grupnoObavestenjeFilter",
                        value: { ...state?.obavestenje?.grupnoObavestenjeFilter, godineOd: e.value },
                      });
                      dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "godineOd", value: e.value });
                    }}
                    disabled={state.operation === EntityOperation.READ || state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList.length === 0}
                  />
                  <span className="mx-2">-</span>
                  <InputNumber
                    className="small-input mr-2"
                    value={state?.obavestenje?.grupnoObavestenjeFilter?.godineDo}
                    onChange={(e) => {
                      dispatch({
                        type: actionsAddPatientNotification.OBAVESTENJE_CHANGE,
                        fieldName: "grupnoObavestenjeFilter",
                        value: { ...state?.obavestenje?.grupnoObavestenjeFilter, godineDo: e.value },
                      });
                      dispatch({ type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH, fieldName: "godineDo", value: e.value });
                    }}
                    disabled={state.operation === EntityOperation.READ || state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList.length === 0}
                  />
                  <span>{Labels.LABEL_OBAVESTENJE_GODINA}</span>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-row flex-wrap mb-4 align-items-center">
            <div className="col-12 sm:col-3 p-fluid p-0">{Labels.DIJAGNOZA}</div>
            <div className="col-12 sm:col-9 p-fluid p-0 flex align-items-center">
              {state?.obavestenjeLoading ? (
                <SkeletonInputItem />
              ) : (
                <AutoComplete
                  value={state?.obavestenje?.grupnoObavestenjeFilter?.dijagnozaList ?? ""}
                  itemTemplate={(e) => `${e.sifra ?? ""} ${e.naziv ?? ""}`}
                  selectedItemTemplate={(e) => `${e.sifra ?? ""} ${e.naziv ?? ""}`}
                  suggestions={mkbDijagnozeList}
                  completeMethod={searchMkbDijagnoza}
                  onChange={(e) => {
                    dispatch({
                      type: actionsAddPatientNotification.OBAVESTENJE_CHANGE,
                      fieldName: "grupnoObavestenjeFilter",
                      value: { ...state?.obavestenje?.grupnoObavestenjeFilter, dijagnozaList: e.value },
                    });
                    dispatch({
                      type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH,
                      fieldName: "sifarnikMkbIDList",
                      value: [
                        ...e.value.map((item: { id: number }) => {
                          return item.id;
                        }),
                      ],
                    });
                  }}
                  multiple
                  className="w-full block gray-chipped"
                  inputClassName="py-1"
                  minLength={2}
                  placeholder={Labels.DIJAGNOZA_UNOS_PLACEHOLDER}
                  disabled={state.operation === EntityOperation.READ || state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList.length === 0}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row flex-wrap mb-4 align-items-center">
            <div className="col-12 sm:col-3 p-fluid p-0">{Labels.USLUGA}</div>
            <div className="col-12 sm:col-9 p-fluid p-0 flex align-items-center">
              {state?.obavestenjeLoading ? (
                <SkeletonInputItem />
              ) : (
                <AutoComplete
                  value={state?.obavestenje?.grupnoObavestenjeFilter?.uslugaList}
                  itemTemplate={(e) => `${e.sifra} ${e.naziv}`}
                  selectedItemTemplate={(e) => `${e.sifra} ${e.naziv}`}
                  suggestions={sifarnikUslugaList}
                  completeMethod={searchSifarnikUsluga}
                  field="sifarnikUsluga"
                  onChange={(e) => {
                    dispatch({
                      type: actionsAddPatientNotification.OBAVESTENJE_CHANGE,
                      fieldName: "grupnoObavestenjeFilter",
                      value: { ...state?.obavestenje?.grupnoObavestenjeFilter, uslugaList: e.value },
                    });
                    dispatch({
                      type: actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH,
                      fieldName: "sifarnikUslugaIDList",
                      value: [
                        ...e.value.map((item: { id: number }) => {
                          return item.id;
                        }),
                      ],
                    });
                  }}
                  multiple
                  placeholder={Labels.PAKET_USLUGA_UNOS_USLUGE_PLACEHOLDER}
                  minLength={2}
                  className="w-full block gray-chipped"
                  inputClassName="py-1"
                  disabled={state.operation === EntityOperation.READ || state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList.length === 0}
                />
              )}
            </div>
          </div>
          {state?.obavestenje?.grupnoObavestenjeFilter?.dijagnozaList.length > 0 && state?.obavestenje?.grupnoObavestenjeFilter?.uslugaList.length > 0 && state?.operation !== EntityOperation.READ && (
            <div className="flex flex-row flex-wrap">
              <div className="col-12 sm:col-offset-3 sm:col-9 p-fluid p-0 text-right mb-4">
                <small>{Labels.LABEL_OBAVESTENJE_DIJAGNOZA_USLUGA_NOTE}</small>
              </div>
            </div>
          )}
          <div className="flex flex-row flex-wrap mb-4 align-items-center">
            <div className="col-12 sm:col-3 p-fluid p-0">{Labels.LABEL_OBAVESTENJA_ZA_PACIJENTA_DATUM_AUTOMATSKOG_SLANJA}</div>
            <div className="col-12 sm:col-9 p-fluid p-0 flex align-items-center">
              <CalendarComponent
                ref={calendarRef}
                disabled={state.operation === EntityOperation.READ || state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList.length === 0}
                name="vrednost"
                className="w-6"
                value={state?.obavestenje?.datumAutomatskogSlanja}
                dateFormat={CALENDAR_DATE_FORMAT}
                showTime
                onChange={(e: CalendarChangeEvent) => {
                  dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_CHANGE, fieldName: "datumAutomatskogSlanja", value: formatDate(e.value as Date | undefined) });
                }}
                onInput={(e) => {
                  if (isDateTimeFormatValid(e.currentTarget.value)) calendarRef?.current?.hide();
                }}
                maxDate={getNextThreeMontsMaxDate()}
                minDate={new Date()}
                onHide={removeAllMasks}
                placeholder={Labels.PLACEHOLDER_DATUM_VREME_FORMAT}
                appendTo={document.body}
              />
            </div>
          </div>
          {state?.obavestenje?.datumAutomatskogSlanja && (
            <div className="flex flex-row flex-wrap">
              <div className="col-12 sm:col-offset-3 sm:col-9 p-fluid p-0 mb-4">
                <small>{Labels.LABEL_OBAVESTENJE_ZA_PACIJENTE_SEND_NOTE}</small>
              </div>
            </div>
          )}
          {state?.obavestenje?.obavestenjeTip?.sifra === EnumTipObavestenja.SMS && (
            <div className="flex flex-row flex-wrap">
              <div className="col-12 sm:col-3 p-fluid p-0">{Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TEKST}</div>
              <div className="col-12 sm:col-9 p-fluid p-0 mb-3">
                {state?.obavestenjeLoading ? (
                  <SkeletonInputItem />
                ) : (
                  <>
                    <InputTextarea
                      value={state?.obavestenje?.sadrzaj}
                      name="sadrzaj"
                      onChange={(e) => {
                        dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_CHANGE, fieldName: e.target.name, value: e.target.value });
                        setInvalidFields((prev) => ({ ...prev, sadrzaj: false }));
                      }}
                      rows={10}
                      cols={5}
                      className={"w-full " + (invalidFields?.sadrzaj && "p-invalid")}
                      disabled={state.operation === EntityOperation.READ || state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList.length === 0}
                    />
                    {invalidFields?.sadrzaj && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                  </>
                )}
              </div>
            </div>
          )}
          {state?.obavestenje?.obavestenjeTip?.sifra === EnumTipObavestenja.EMAIL && (
            <>
              <div className="flex flex-row flex-wrap align-items-center">
                <div className="col-12 sm:col-3 p-fluid p-0 mb-4">{Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_NASLOV}</div>
                <div className="col-12 sm:col-9 p-fluid p-0 mb-4">
                  {state?.obavestenjeLoading ? (
                    <SkeletonInputItem />
                  ) : (
                    <InputText
                      value={state?.obavestenje?.naslov ?? ""}
                      name="naslov"
                      onChange={(e) => {
                        dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_CHANGE, fieldName: e.target.name, value: e.target.value });
                        setInvalidFields((prev) => ({ ...prev, naslov: false }));
                      }}
                      disabled={state.operation === EntityOperation.READ || state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList.length === 0}
                    />
                  )}
                  {invalidFields?.naslov && (
                    <div className="relative block">
                      <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />
                    </div>
                  )}
                </div>
                {state?.obavestenjeLoading ? (
                  <SkeletonInputItem />
                ) : (
                  <div className="col-12 p-fluid p-0">
                    <Editor
                      className="h-10rem"
                      headerTemplate={header}
                      value={state?.obavestenje?.sadrzaj ?? ""}
                      placeholder={Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_UNESITE_TEKST}
                      name="sadrzaj"
                      onTextChange={(e) => {
                        dispatch({ type: actionsAddPatientNotification.OBAVESTENJE_CHANGE, fieldName: "sadrzaj", value: e.htmlValue });
                        setInvalidFields((prev) => ({ ...prev, sadrzaj: false }));
                      }}
                      readOnly={state.operation === EntityOperation.READ || state?.obavestenje?.grupnoObavestenjeFilter?.polTrenutniList.length === 0}
                    />
                  </div>
                )}
              </div>
              <div className={`relative block emailContentError ${invalidFields?.sadrzaj ? "visible" : ""}`}>
                <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />
              </div>
            </>
          )}
        </div>
      }
    />
  );
}
