import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../controllers/enum/EnumController";
import QueryKeys from "../../QueryKeys";

export function useUplatilacList() {
  const { axiosGetUplatilacList } = EnumController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.UPLATILAC_LIST],
    queryFn: () => axiosGetUplatilacList(),
    staleTime: Infinity,
  });

  const uplatilacList = data?.data?.data ?? [];

  return { uplatilacList, refetch, isLoading };
}
