import actions from "./actionsGenerisaniIzvestaj";
import { InitialStateGenerisaniIzvestajType } from "./initialStateGenerisaniIzvestaj";

export default function reducerGenerisaniIzvestaj(state: InitialStateGenerisaniIzvestajType, action: any) {
  switch (action.type) {
    case actions.SET_KATEGORIJA_LIST: {
      return {
        ...state,
        kategorijaList: action.value,
        kategorijaListLoading: false,
      };
    }
    case actions.SET_IZVESTAJ_LIST: {
      return {
        ...state,
        izvestajList: action.value,
        izvestajListLoading: false,
      };
    }
    case actions.SET_IZVESTAJ_LIST_LOADING: {
      return {
        ...state,
        izvestajListLoading: action.value,
      };
    }
    case actions.SET_TOTAL_COUNT: {
      return {
        ...state,
        totalCount: action.value,
      };
    }
    case actions.FETCH_DATA: {
      const selectedStatistickiIzvestaj = action.value.selectedStatistickiIzvestaj ?? undefined;
      const pagination = action.value.pagination ?? state.pagination;
      const paramsLoading = selectedStatistickiIzvestaj !== state.selectedStatistickiIzvestaj;
      return {
        ...state,
        izvestajList: action.value.izvestajList,
        selectedStatistickiIzvestaj: selectedStatistickiIzvestaj,
        pagination: pagination,
        onlyMine: action.value.onlyMine,
        paramsLoading: paramsLoading,
        paramsValue: [],
      };
    }
    case actions.ADD_PARAM_VALUE: {
      return {
        ...state,
        paramsValue: {
          ...state.paramsValue,
          [action.value.id]: action.value.value,
        },
      };
    }
    case actions.PARAMS_LOADED: {
      return {
        ...state,
        paramsLoading: false,
      };
    }
    case actions.ADD_NEWLY_GENERATED_REPORT: {
      return {
        ...state,
        //izvestajList: [...state.izvestajList.slice(0, state.izvestajList.length - 1), {id:9999}]
      };
    }
    default:
      return state;
  }
}
