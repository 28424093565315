import { AxiosResponse } from "axios";
import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { EnumController } from "../../../controllers/enum/EnumController";
import { TipKontaktaController } from "../../../controllers/kontakt/TipKontaktaController";
import { SuperadminKontaktTipController } from "../../../controllers/superadmin/SuperadminKontaktTipController";
import { RepositoryTipKontaktaController } from "../../../controllers/superadmin/repository/kontakt/RepositoryTipKontaktaController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import TipKontaktaReadDto from "../../../model/kontakt/TipKontaktaReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import { AppContext, useLabels } from "./../../../Store";

interface TipKontaktaListLogicalType {
  tipKontaktaList: Array<TipKontaktaReadDto>;
  first: number;
  tableRows: number;
  firstRepository: number;
  tableRepositoryRows: number;
  changeTipKontaktaSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  tipKontaktaSearchData: SearchBaseDto;
  fetchData: () => void;
  searchTipKontaktaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  onPageTipKontakta: (rows: number, first: number) => void;
  tipKontaktaListLoading: boolean;
  repositoryTipKontaktaLoading: boolean;
  closeDialog: () => void;
  openDialog: () => void;
  isDialogOpen: boolean;
  repositoryTipKontaktaList: Array<TipKontaktaReadDto>;
  onPageRepositoryTipKontakta: (rows: number, first: number) => void;
  selectedColumns: Array<TipKontaktaReadDto>;
  setSelectedColumns: React.Dispatch<React.SetStateAction<Array<TipKontaktaReadDto>>>;
  importTipKontakta: () => void;
  sifarnikVremeTrajanjaKontakta: Array<EnumBaseReadDto>;
}

export default function TipKontaktaListLogical(ustanovaId?: number): TipKontaktaListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [tipKontaktaList, setTipKontaktaList] = useState<Array<TipKontaktaReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [tipKontaktaSearchData, setTipKontaktaSearchData] = useState<SearchBaseDto>({});
  const { axiosSearchTipKontakta } = TipKontaktaController();
  const { axiosSuperadminSearchKontaktTipList, axiosSuperadminPublicCopyRepositoryTipKontakta } = SuperadminKontaktTipController();

  const { axiosPublicSearchRepositoryTipKontakta, axiosPublicCopyRepositoryTipKontakta } = RepositoryTipKontaktaController();
  const [repositoryTipKontaktaList, setRepositoryTipKontaktaList] = useState<Array<TipKontaktaReadDto>>([]);
  const [tipKontaktaListLoading, setTipKontaktaListLoading] = useState(false);
  const [repositoryTipKontaktaLoading, setRepositoryTipKontaktaLoading] = useState<boolean>(true);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<Array<TipKontaktaReadDto>>([]);
  const [firstRepository, setFirstRepository] = useState(0);
  const [tableRepositoryRows, setTableRepositoryRows] = useState(10);
  const [sifarnikVremeTrajanjaKontakta, setSifarnikVremeTrajanjaKontakta] = useState<Array<EnumBaseReadDto>>([]);
  const { axiosGetVremeTrajanjaKontakta } = EnumController();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.TIP_KONTAKTA_LIST,
    },
  ];
  useEffectOnce(() => {
    fetchData();
    getSifarnikVremeTrajanjaKontakta();
  });

  const fetchData = () => {
    setTipKontaktaListLoading(true);
    const startTime = moment(new Date());
    (ustanovaId ? axiosSuperadminSearchKontaktTipList(ustanovaId, tipKontaktaSearchData) : axiosSearchTipKontakta(tipKontaktaSearchData))
      .then((res: { data: { data: Array<TipKontaktaReadDto> } }) => {
        setTipKontaktaList(res.data.data);
        const exportList = new Array<any>();
        res.data.data.forEach((tipKontakta: TipKontaktaReadDto) => {
          exportList.push({
            [Labels.TIP_KONTAKTA_SIFRA]: tipKontakta.sifra,
            [Labels.TIP_KONTAKTA_NAZIV]: tipKontakta.naziv,
            [Labels.TIP_KONTAKTA_KATEGORIJA_KONTAKTA]: tipKontakta.sifarnikKategorijaKontakta?.naziv,
            [Labels.TIP_KONTAKTA_AUTOMATSKO_ZAVRSAVANJE]: tipKontakta.automatskoZavrsavanje,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setTipKontaktaListLoading, startTime);
      });
  };

  const changeTipKontaktaSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setTipKontaktaSearchData({
      ...tipKontaktaSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchTipKontaktaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageTipKontakta = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedColumns([]);
  };
  const openDialog = () => {
    setIsDialogOpen(true);
    setRepositoryTipKontaktaLoading(true);
    const startTime = moment(new Date());
    axiosPublicSearchRepositoryTipKontakta({})
      .then((res: { data: { data: Array<TipKontaktaReadDto> } }) => {
        setRepositoryTipKontaktaList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setRepositoryTipKontaktaLoading, startTime);
      });
  };
  const importTipKontakta = () => {
    let tipKontaktaIDList: Array<number> = selectedColumns.map((tipKontakta: TipKontaktaReadDto) => tipKontakta.id);
    (ustanovaId ? axiosSuperadminPublicCopyRepositoryTipKontakta(ustanovaId, { kontaktTipIDList: tipKontaktaIDList }) : axiosPublicCopyRepositoryTipKontakta({ kontaktTipIDList: tipKontaktaIDList }))
      .then(() => {
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        closeDialog();
      });
  };
  const onPageRepositoryTipKontakta = (rows: number, first: number) => {
    setTableRepositoryRows(rows);
    setFirstRepository(first);
  };

  const getSifarnikVremeTrajanjaKontakta = () => {
    axiosGetVremeTrajanjaKontakta()
      .then((response: AxiosResponse) => {
        setSifarnikVremeTrajanjaKontakta(response.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return {
    tipKontaktaList,
    first,
    tableRows,
    changeTipKontaktaSearchData,
    tipKontaktaSearchData,
    fetchData,
    searchTipKontaktaByEnter,
    exportData,
    breadCrumbItems,
    onPageTipKontakta,
    tipKontaktaListLoading,
    closeDialog,
    importTipKontakta,
    isDialogOpen,
    onPageRepositoryTipKontakta,
    openDialog,
    repositoryTipKontaktaList,
    repositoryTipKontaktaLoading,
    selectedColumns,
    setSelectedColumns,
    firstRepository,
    tableRepositoryRows,
    sifarnikVremeTrajanjaKontakta,
  };
}
