import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EnumController } from "../../../controllers/enum/EnumController";

import { PoljeTipController } from "../../../controllers/polje-tip/PoljeTipController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import PoljeTipEnumStavkaReadDto from "../../../model/poljeTip/PoljeTipEnumStavkaReadDto";
import PoljeTipReadDto from "../../../model/poljeTip/PoljeTipReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudPoljeTipLogicalType {
  poljeTipId: number;
  poljeTipChange: PoljeTipReadDto | undefined;
  setPoljeTipChange: any;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  isDisabled: boolean;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  poljeTipOperation: string;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  location: any;
  first: number;
  tableRows: number;
  poljeTipEnumStavkaToChange: PoljeTipEnumStavkaReadDto | undefined;
  setPoljeTipEnumStavkaToChange: React.Dispatch<React.SetStateAction<PoljeTipEnumStavkaReadDto | undefined>>;
  onPageFormTable: (rows: number, first: number) => void;
  navigate: any;
  poljeTipEnumStavkaList: Array<PoljeTipEnumStavkaReadDto>;
  componentTypeOptions: Array<EnumBaseReadDto>;
  poljeTipLoading: boolean;
  poljeTipOptionsDataLoading: boolean;
  tipPoljaEnumStavkaLoading: boolean;
  tipPoljaTipKomponenteSifra?: string;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
}

interface UseParamsType {
  poljeTipId?: string;
}

export default function CrudPoljeTipLogical(): CrudPoljeTipLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const [exportData, setExportData] = useState<Array<any>>([]);
  const poljeTipId = Number(useParams<keyof UseParamsType>()["poljeTipId"]);
  const location = useLocation();
  const [isDisabled] = useState<boolean>(isFormDisabled(location.state ? location.state.poljeTipOperation : EntityOperation.UPDATE));
  const [poljeTipChange, setPoljeTipChange] = useState<PoljeTipReadDto | undefined>();
  const [poljeTipEnumStavkaToChange, setPoljeTipEnumStavkaToChange] = useState<PoljeTipEnumStavkaReadDto | undefined>();
  const [poljeTipEnumStavkaList, setPoljeTipEnumStavkaList] = useState<Array<PoljeTipEnumStavkaReadDto>>([]);
  const { axiosReadPoljeTip, axiosCreatePoljeTip, axiosDeletePoljeTip, axiosUpdatePoljeTip, axiosGetPoljeTipEnumStavkaList } = PoljeTipController();
  const navigate = useNavigate();
  const [index, setIndex] = useState<number>(location?.state?.tabIndex ? location?.state?.tabIndex : 0);
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [poljeTipOperation, setPoljeTipOperation] = useState<string>(location.state ? location.state.poljeTipOperation : EntityOperation.UPDATE);
  const { axiosGetTipKomponenteList } = EnumController();
  const [componentTypeOptions, setComponentTypeOptions] = useState<Array<EnumBaseReadDto>>([]);
  const [poljeTipOptionsDataLoading, setPoljeTipOptionsDataLoading] = useState<boolean>(true);
  const [poljeTipLoading, setPoljeTipLoading] = useState<boolean>(true);
  const [tipPoljaEnumStavkaLoading, setTipPoljaEnumStavkaLoading] = useState<boolean>(false);
  const [tipPoljaTipKomponenteSifra, setTipPoljaTipKomponenteSifra] = useState<string>();
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.POLJE_TIP_LIST,
      command: () => {
        navigate("/polje-tip-list");
      },
    },
  ]);
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    axiosGetTipKomponenteList()
      .then((res: any) => {
        setComponentTypeOptions(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setPoljeTipOptionsDataLoading(false);
      });
    fetchData();
  });

  const fetchData = (fId?: number, newOperation?: string | undefined) => {
    let newPoljeTipId = fId ? fId : poljeTipId;
    let operation = newOperation ? newOperation : poljeTipOperation;
    if (newPoljeTipId) {
      let startTime = moment(new Date());

      axiosReadPoljeTip(newPoljeTipId)
        .then((responsePoljeTip) => {
          if (
            responsePoljeTip.data.data.tipKomponenteEnum.sifra === EnumTipKomponente.SET ||
            responsePoljeTip.data.data.tipKomponenteEnum.sifra === EnumTipKomponente.SET_RADIO ||
            responsePoljeTip.data.data.tipKomponenteEnum.sifra === EnumTipKomponente.SET_MULTISELECT
          ) {
            setTipPoljaEnumStavkaLoading(true);
            axiosGetPoljeTipEnumStavkaList(newPoljeTipId)
              .then((responsePoljeTipEnumStavkaList) => {
                setPoljeTipEnumStavkaList(responsePoljeTipEnumStavkaList.data.data);
                let exportList = new Array<any>();
                responsePoljeTipEnumStavkaList.data.data.forEach((poljeTipEnumStavka: PoljeTipEnumStavkaReadDto) => {
                  exportList.push({
                    [Labels.LABEL_NAZIV]: poljeTipEnumStavka.naziv,
                    [Labels.LABEL_SIFRA]: poljeTipEnumStavka.sifra,
                    [Labels.LABEL_PRIKAZNI_REDOSLED]: poljeTipEnumStavka.prikazniRedosled,
                    [Labels.LABEL_NAPOMENA]: poljeTipEnumStavka.napomena,
                  });
                });
                setExportData(exportList);
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
              })
              .finally(() => {
                skeletonTimeout(setTipPoljaEnumStavkaLoading, startTime);
              });
          }
          setPoljeTipChange(responsePoljeTip.data.data);
          setTipPoljaTipKomponenteSifra((responsePoljeTip.data.data as PoljeTipReadDto).tipKomponenteEnum.sifra);
          if (breadCrumbItems.length >= 2) breadCrumbItems.pop();
          setBreadCrumbItems([
            ...breadCrumbItems,
            {
              label:
                operation === EntityOperation.UPDATE
                  ? Labels.LABEL_UPDATE_FIELD_TYPE + responsePoljeTip.data.data.naziv
                  : operation === EntityOperation.READ
                  ? Labels.LABEL_DETAILS_FIELD_TYPE + responsePoljeTip.data.data.naziv
                  : Labels.LABEL_DELETE_FIELD_TYPE + responsePoljeTip.data.data.naziv,
            },
          ]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPoljeTipLoading, startTime);
        });
    } else {
      setPoljeTipLoading(false);
      setTipPoljaEnumStavkaLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.LABEL_CREATE_FIELD_TYPE,
        },
      ]);
    }
  };

  const validateInput = (poljeTip: PoljeTipReadDto | undefined) => {
    let isInvalid = false;
    if (validateStringEmpty(poljeTip?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(poljeTip?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(poljeTip?.tipKomponenteEnum?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, tipKomponente: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(poljeTipChange)) {
      return;
    }
    if (poljeTipChange) {
      setShowBlockUI(true);
      axiosCreatePoljeTip(poljeTipChange)
        .then((res: any) => {
          const isEnumSet = res.data.data.tipKomponenteEnum.sifra === EnumTipKomponente.SET || res.data.data.tipKomponenteEnum.sifra === EnumTipKomponente.SET_RADIO;
          navigate(isEnumSet ? `/polje-tip/${res.data.data.id}` : `/polje-tip-list`);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS);
          if (isEnumSet) {
            setIndex(1);
            setPoljeTipOperation(EntityOperation.UPDATE);
            fetchData(res.data.data.id, EntityOperation.UPDATE);
          }

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_1 +
              poljeTipChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_2 +
              poljeTipChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_3 +
              res.data.data.tipKomponenteEnum.naziv
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    if (poljeTipChange) {
      setShowBlockUI(true);
      axiosDeletePoljeTip(poljeTipChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_1 +
              poljeTipChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_2 +
              poljeTipChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_3 +
              poljeTipChange?.tipKomponenteEnum.naziv
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(poljeTipChange)) {
      return;
    }
    if (poljeTipChange) {
      setShowBlockUI(true);
      axiosUpdatePoljeTip(poljeTipChange)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_1 +
              poljeTipChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_2 +
              poljeTipChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_3 +
              res.data.data.tipKomponenteEnum.naziv
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/polje-tip-list`);
    }
  };

  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    poljeTipId,
    poljeTipChange,
    setPoljeTipChange,
    setIndex,
    index,
    isDisabled,
    exportData,
    breadCrumbItems,
    poljeTipOperation,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    location,
    first,
    tableRows,
    poljeTipEnumStavkaToChange,
    setPoljeTipEnumStavkaToChange,
    onPageFormTable,
    navigate,
    poljeTipEnumStavkaList,
    componentTypeOptions,
    poljeTipLoading,
    poljeTipOptionsDataLoading,
    tipPoljaEnumStavkaLoading,
    tipPoljaTipKomponenteSifra,
    invalidFields,
    setInvalidFields,
  };
}
