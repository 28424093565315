import { PickList } from "primereact/picklist";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import SkeletonPickListItem from "../../../app/skeleton/SkeletonPickListItem";
import CrudRadnikOrganizacionaJedinicaLogical, { RadnikOrganizacionaJedinicaHolder } from "./CrudRadnikOrganizacionaJedinicaLogical";

interface RadnikCrudOrganizacionaJedinicaPropType {
  isDisabled: boolean;
  radnikOperation: string;
  ustanovaId?: number;
  radnikIme?: string;
  radnikPrezime?: string;
}
export default function CrudRadnikOrganizacionaJedinica(props: RadnikCrudOrganizacionaJedinicaPropType) {
  const { isDisabled, radnikOperation, ustanovaId, radnikIme, radnikPrezime } = props;
  const { radnikOrganizacionaJedinicaList, organizacionaJedinicaList, isBlocked, isLoading, sourceSelection, setSourceSelection, targetSelection, setTargetSelection, onMoveToTarget, onMoveToSource } =
    CrudRadnikOrganizacionaJedinicaLogical({
      radnikOperation: radnikOperation,
      ustanovaId: ustanovaId,
      radnikIme: radnikIme,
      radnikPrezime: radnikPrezime,
    });
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const itemTemplate = (item: RadnikOrganizacionaJedinicaHolder | undefined) => {
    if (item === undefined || item === null || isBlocked) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{item.organizacionaJedinica?.naziv}</h6>;
  };

  return (
    <div className="layout-generic-content-list">
      <div className={!pristup || isDisabled || isBlocked || isLoading ? "read-only" : ""}>
        <PickList
          sourceHeader={Labels.ORGANIZACIONA_JEDINICA_LIST}
          targetHeader={Labels.LABEL_RADNIK_ORGANIZACIONA_JEDINICA_LIST}
          source={organizacionaJedinicaList}
          target={radnikOrganizacionaJedinicaList}
          sourceItemTemplate={itemTemplate}
          targetItemTemplate={itemTemplate}
          showSourceControls={false}
          showTargetControls={false}
          sourceSelection={sourceSelection}
          targetSelection={targetSelection}
          onSourceSelectionChange={(e) => setSourceSelection(e.value)}
          onTargetSelectionChange={(e) => setTargetSelection(e.value)}
          onMoveToSource={() => onMoveToSource(targetSelection)}
          onMoveToTarget={() => onMoveToTarget(sourceSelection)}
          onMoveAllToSource={(e) => onMoveToSource(e.value)}
          onMoveAllToTarget={(e) => onMoveToTarget(e.value)}
          metaKeySelection={false}
        />
      </div>
    </div>
  );
}
