import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";
import { useNavigate } from "react-router-dom";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import KategorijaStatistickogIzvestajReadDto from "../../../../model/izvestaj/kategorija/KategorijaStatistickogIzvestajaReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import KategorijeStatistickihIzvestajaListLogical from "./KategorijeStatistickihIzvestajaListLogical";

interface KategorijeStatistickihIzvestajaListViewPropsType {
  isSuperAdmin?: boolean;
}

export default function KategorijeStatistickihIzvestajaListView(props: KategorijeStatistickihIzvestajaListViewPropsType) {
  const { isSuperAdmin } = props;
  const { fetchData, kategorijaSearchData, changeKategorijaSearchData, searchKategorijaByEnter, kategorijaList, first, tableRows, onPageKategorija, kategorijaLoading } =
    KategorijeStatistickihIzvestajaListLogical(isSuperAdmin);

  const Labels = useLabels();
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMini } = PageDropdownTemplate();

  const createSearchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_KATEGORIJA}
          tooltip={Labels.TOOLTIP_ADD_KATEGORIJA}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openKategorija(EntityOperation.CREATE);
          }}
        />
        <span className="p-float-label mt-4 sm:mt-4 md:mt-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={kategorijaSearchData?.searchString ?? ""}
            onChange={changeKategorijaSearchData}
            onKeyPress={searchKategorijaByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_KATEGORIJA}</label>
          <Button tooltip={Labels.KATEGORIJA_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const openKategorija = (entityOperation: string, id?: number, naziv?: string) => {
    const idKategorija = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-kategorija-statistickih-izvestaja/${idKategorija}`, {
      state: { kategorijaOperation: entityOperation, kategorijaNaziv: naziv },
    });
  };

  const crudBodyTemplate = (rowKategorija: KategorijaStatistickogIzvestajReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.KATEGORIJA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowKategorija && openKategorija(EntityOperation.UPDATE, rowKategorija.id, rowKategorija.naziv)}
        />
        <Button
          type="button"
          tooltip={Labels.KATEGORIJA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowKategorija && openKategorija(EntityOperation.DELETE, rowKategorija.id, rowKategorija.naziv)}
        />
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.KATEGORIJA_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.KATEGORIJA_SIFRA, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <div>
        <Toolbar left={createSearchToolbar} />
        {kategorijaLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            value={kategorijaList}
            paginator
            first={first}
            filterDisplay="row"
            paginatorTemplate={rowsPerPageDropdownTemplateMini as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageKategorija(e.rows, e.first);
            }}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.KATEGORIJA_NAZIV} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"sifra"} header={Labels.KATEGORIJA_SIFRA} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </div>
    </div>
  );
}
