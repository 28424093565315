import MandatoryBaseEntityDto from "../../../../model/MandatoryBaseEntityDto";
import KontaktNaplataReadDto from "../../../../model/naplata/KontaktNaplataReadDto";
import RacunCreateDto from "../../../../model/naplata/RacunCreateDto";
import RacunNacinPlacanjaCreateDto from "../../../../model/naplata/RacunNacinPlacanjaCreateDto";
import StavkaNaplate from "../../../../model/naplata/StavkaNaplate";
import PartnerSimpleReadDto from "../../../../model/partner/PartnerSimpleReadDto";
import EnumUplatilacNaplata from "../../EnumUplatilacNaplata";
import InitialStateNaplata from "./initialStateNaplata";

type IncrementStepType = {
  type: "increment_step";
};

type DecrementStepType = {
  type: "decrement_step";
};

type JumpToStepType = {
  type: "jump_to_step";
  data: {
    step: number;
    racun: RacunCreateDto;
  };
};

type AddStavkaNaplateType = {
  type: "add_stavka_naplate";
  data: {
    id: number;
    value: StavkaNaplate;
    itemsType: "proizvodi" | "usluge";
  };
};

type DeleteStavkaNaplateType = {
  type: "delete_stavka_naplate";
  data: {
    itemsType: "proizvodi" | "usluge";
    itemId: number;
    index: number;
  };
};

type UpdateStavkaValueType<T extends keyof StavkaNaplate> = {
  type: "update_stavka_value";
  data: {
    field: T;
    itemsType: "proizvodi" | "usluge";
    itemId: number;
    index: number;
    value: StavkaNaplate[T];
  };
};

type UpdatePartnerType = {
  type: "update_partner";
  data: {
    itemId: number;
    index: number;
    value: PartnerSimpleReadDto;
    itemsType: "proizvodi" | "usluge";
  };
};

type SetInitialItemType = {
  type: "set_initial_item";
  data: {
    id: number;
    item: StavkaNaplate;
    itemsType: "proizvodi" | "usluge";
  };
};

type SetSelectedColumnsType = {
  type: "set_selected_columns";
  data: KontaktNaplataReadDto[];
};

type CreateRacunType = {
  type: "create_racun_from_stavke_naplate";
  data: { finalnaValuta: MandatoryBaseEntityDto };
};

type SetRacunType = {
  type: "set_racun";
  data: RacunCreateDto[];
};

type SetNacinPlacanjaType = {
  type: "set_nacin_placanja";
  data: {
    index: number;
    racunNacinPlacanja: RacunNacinPlacanjaCreateDto;
  };
};

type SetRacunNapomenaType = {
  type: "set_racun_napomena";
  data: {
    index: number;
    napomena: string;
  };
};

type ResetAllType = {
  type: "reset_all";
};

type SetStampanjeType = {
  type: "set_stampanje";
  racunType: "pacijent" | "partner";
  data: boolean;
};

export type NaplataActionType =
  | IncrementStepType
  | DecrementStepType
  | JumpToStepType
  | AddStavkaNaplateType
  | DeleteStavkaNaplateType
  | UpdateStavkaValueType<keyof StavkaNaplate>
  | UpdatePartnerType
  | SetInitialItemType
  | SetSelectedColumnsType
  | CreateRacunType
  | SetRacunType
  | SetNacinPlacanjaType
  | SetRacunNapomenaType
  | ResetAllType
  | SetSelectedColumnsType
  | SetStampanjeType;

export default function reducerNaplata(state = InitialStateNaplata, action: NaplataActionType) {
  switch (action.type) {
    case "increment_step": {
      return {
        ...state,
        step: state.step + 1,
      };
    }
    case "decrement_step": {
      return {
        ...state,
        step: state.step - 1,
      };
    }
    case "jump_to_step": {
      return {
        ...state,
        step: action.data.step,
        racuni: [action.data.racun],
        accountsPaymentOpted: true,
      };
    }
    case "add_stavka_naplate": {
      const { id, value, itemsType } = action.data;
      return {
        ...state,
        [itemsType]: { ...state?.[itemsType], [id]: [...state[itemsType]?.[id], value] },
      };
    }
    case "delete_stavka_naplate": {
      const { itemId, index, itemsType } = action.data;
      const itemsCopy = { ...state[itemsType] };
      if (index !== -1) {
        itemsCopy[itemId].splice(index, 1);
      }
      return {
        ...state,
        [itemsType]: itemsCopy,
      };
    }
    case "update_stavka_value": {
      const { field, itemsType, itemId, value, index } = action.data;
      const itemsCopy = { ...state[itemsType] };
      const valueCopy = [...itemsCopy[itemId]];
      valueCopy[index] = { ...valueCopy[index], [field]: value };
      itemsCopy[itemId] = valueCopy;

      return {
        ...state,
        [itemsType]: itemsCopy,
      };
    }
    case "update_partner": {
      const { itemId, itemsType, value, index } = action.data;
      const itemsCopy = { ...state };
      const valueCopy = [...itemsCopy[itemsType][itemId]];
      valueCopy[index] = { ...valueCopy[index], partner: value };
      itemsCopy[itemsType][itemId] = valueCopy;

      const removePartnerFromItems = (items: { [x: number]: StavkaNaplate[] }) => {
        Object.keys(items).forEach((id) => {
          items[Number(id)] = items[Number(id)].map((item) => {
            if (item.partner && item.partner.id !== value.id) {
              return { ...item, partner: undefined };
            }
            return item;
          });
        });
      };

      removePartnerFromItems(itemsCopy["usluge"]);
      removePartnerFromItems(itemsCopy["proizvodi"]);

      return {
        ...state,
        usluge: itemsCopy["usluge"],
        proizvodi: itemsCopy["proizvodi"],
      };
    }
    case "set_initial_item": {
      const { id, item, itemsType } = action.data;
      return {
        ...state,
        [itemsType]: { ...state?.[itemsType], [id]: [item] },
      };
    }
    case "set_selected_columns": {
      return {
        ...state,
        selectedColumns: action.data,
      };
    }
    case "create_racun_from_stavke_naplate": {
      const pacijent = state.selectedColumns[0].pacijent;
      const stavkeNaplate = Object.values(state.usluge)
        .flatMap((stavkaNaplatePair) => stavkaNaplatePair.map((stavkaNaplate) => stavkaNaplate))
        .concat(Object.values(state.proizvodi).flatMap((stavkaNaplatePair) => stavkaNaplatePair.map((stavkaNaplate) => stavkaNaplate)));

      const partnerStavke = stavkeNaplate.filter((stavkaNaplate) => stavkaNaplate.uplatilac.sifra === EnumUplatilacNaplata.PARTNER && stavkaNaplate.partner);
      const pacijentStavke = stavkeNaplate.filter((stavkaNaplate) => stavkaNaplate.uplatilac.sifra === EnumUplatilacNaplata.PACIJENT);

      let racuni: RacunCreateDto[] = [];

      if (pacijentStavke.length > 0) {
        let racunZaPacijenta: RacunCreateDto = {
          iznos: pacijentStavke.map((pacijentStavkaNaplate) => pacijentStavkaNaplate.iznos).reduce((acc, iznos) => acc + iznos, 0),
          pacijent: pacijent,
          valuta: action.data.finalnaValuta,
          stavkaNaplateList: pacijentStavke,
          racunNacinPlacanjaList: [],
          napomena: "",
        };
        racuni.push(racunZaPacijenta);
      }

      if (partnerStavke.length > 0) {
        const racunZaPartnera: RacunCreateDto = {
          iznos: partnerStavke.map((partnerStavkaNaplate) => partnerStavkaNaplate.iznos).reduce((acc, iznos) => acc + iznos, 0),
          partner: partnerStavke[0].partner,
          valuta: action.data.finalnaValuta,
          stavkaNaplateList: partnerStavke.map((s) => ({
            iznos: s.iznos,
            kontakt: s.kontakt,
            napomena: s.napomena,
            placeniProcenat: s.placeniProcenat,
            kategorijaPopusta: s.kategorijaPopusta,
            kontaktProizvod: s.kontaktProizvod,
            kontaktUsluga: s.kontaktUsluga,
            partnerCena: s.partnerCena,
            proizvodCena: s.proizvodCena,
            uslugaCena: s.uslugaCena,
            kolicina: s.kolicina,
          })),
          racunNacinPlacanjaList: [],
          napomena: "",
        };
        racuni.push(racunZaPartnera);
      }

      return {
        ...state,
        racuni,
      };
    }
    case "set_racun": {
      return {
        ...state,
        racuni: action.data,
      };
    }
    case "set_nacin_placanja": {
      const { index, racunNacinPlacanja } = action.data;
      const updatedRacuni = [...state.racuni];
      const racun = updatedRacuni[index];
      const prevRacunNacinPlacanjaIndex = racun.racunNacinPlacanjaList.findIndex((item) => item.sifarnikNacinPlacanja.id === racunNacinPlacanja.sifarnikNacinPlacanja.id);
      if (prevRacunNacinPlacanjaIndex !== -1) {
        racun.racunNacinPlacanjaList[prevRacunNacinPlacanjaIndex].iznos = racunNacinPlacanja.iznos;
      } else {
        racun.racunNacinPlacanjaList.push(racunNacinPlacanja);
      }

      return {
        ...state,
        racuni: updatedRacuni,
      };
    }
    case "set_racun_napomena": {
      const updatedRacuni = [...state.racuni];
      const racun = updatedRacuni[action.data.index];
      racun.napomena = action.data.napomena;

      return {
        ...state,
        racuni: updatedRacuni,
      };
    }

    case "reset_all": {
      return {
        ...state,
        kontakt: undefined,
        step: 0,
        usluge: [],
        proizvodi: [],
        selectedColumns: [],
        accountsPaymentOpted: false,
        stampanje: {
          pacijent: true,
          partner: true,
        },
      };
    }

    case "set_stampanje": {
      return {
        ...state,
        stampanje: {
          ...state.stampanje,
          [action.racunType]: action.data,
        },
      };
    }
    default:
      return state;
  }
}
