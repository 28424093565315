import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import { useContext } from "react";
import { useLabels } from "../../../../Store";
import EnumUplatilacNaplata from "../../../../infrastructure/system/EnumUplatilacNaplata";
import Images from "../../../../infrastructure/system/Images";
import { DATE_FORMAT, formatPrice } from "../../../../infrastructure/system/Utils";
import useEffectWithoutWarnings from "../../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import { useSrednjiKursForValuta } from "../../../../infrastructure/system/hooks/useSrednjiKursForValuta";
import KontaktUslugaReadDto from "../../../../model/kontakt/KontaktUslugaReadDto";
import KontaktNaplataReadDto from "../../../../model/naplata/KontaktNaplataReadDto";
import StavkaNaplate from "../../../../model/naplata/StavkaNaplate";
import SifarnikKategorijaPopustaReadDto from "../../../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaReadDto";
import { InvalidInputsType, NaplataContext } from "../../NaplataLogical";
import NaplataUtils from "../../NaplataUtils";
import { useUslugaCena } from "../../hooks/useUslugaCena";
import StavkaNaplateUsluga from "./StavkaNaplateUsluga";

interface UplatilacCardUslugaProps {
  kontaktUsluga: KontaktUslugaReadDto;
  kategorijePopustaList: SifarnikKategorijaPopustaReadDto[];
  kontakt: KontaktNaplataReadDto;
}

const UplatilacCardUsluga = (props: UplatilacCardUslugaProps) => {
  const { state, dispatch, invalidInputs, setInvalidInputs, uplatilacList, finalnaValuta } = useContext(NaplataContext);
  const { kontaktUsluga, kategorijePopustaList, kontakt } = props;
  const Labels = useLabels();

  const { calculateUkupnaCenaWithoutPacijentPopust } = NaplataUtils();

  const stavkaNaplatePair = state.usluge?.[kontaktUsluga.id];
  const prvaStavka = stavkaNaplatePair?.[0];
  const selectedPartner = stavkaNaplatePair?.find((usluga) => usluga.partner)?.partner;
  const kolicina = kontaktUsluga.brojPruzanja;
  const { uslugaCena: cena, isLoading: cenaIsLoading } = useUslugaCena(kontaktUsluga.sifarnikUsluga.id, kontakt.vremeOd as string, selectedPartner?.id);

  const valutaForUsluga = cena?.partnerCena?.valuta ? cena?.partnerCena?.valuta : cena?.uslugaCena?.valuta;
  const { kurs: kursUsluge } = useSrednjiKursForValuta(valutaForUsluga?.sifra !== "RSD" ? valutaForUsluga?.id : undefined);

  const emptyDataObject: StavkaNaplate = {
    uplatilac: prvaStavka?.uplatilac?.sifra === EnumUplatilacNaplata.PACIJENT ? uplatilacList[1] : uplatilacList[0],
    placeniProcenat: 100 - prvaStavka?.placeniProcenat,
    iznosBezPacijentPopusta:
      100 - prvaStavka?.placeniProcenat === 0
        ? 0
        : calculateUkupnaCenaWithoutPacijentPopust(state?.usluge?.[kontaktUsluga.id], cena) * kolicina - prvaStavka?.iznos >= 0
        ? calculateUkupnaCenaWithoutPacijentPopust(state?.usluge?.[kontaktUsluga.id], cena) * kolicina - prvaStavka?.iznos
        : 0,
    iznos:
      100 - state?.usluge?.[kontaktUsluga.id]?.[0]?.placeniProcenat === 0
        ? 0
        : calculateUkupnaCenaWithoutPacijentPopust(state?.usluge?.[kontaktUsluga.id], cena) * kolicina - prvaStavka?.iznos >= 0
        ? calculateUkupnaCenaWithoutPacijentPopust(state?.usluge?.[kontaktUsluga.id], cena) * kolicina - prvaStavka?.iznos
        : 0,
    napomena: "",
    kontaktUsluga: kontaktUsluga.sifarnikUsluga && kontaktUsluga,
    kolicina: kolicina,
    kontakt: kontakt,
  };

  useEffectWithoutWarnings(() => {
    const initialValues = {
      uplatilac: uplatilacList[0],
      placeniProcenat: 100,
      napomena: "",
      kontaktUsluga: kontaktUsluga,
      kolicina: kontaktUsluga.brojPruzanja,
      iznosBezPacijentPopusta: 0,
      iznos: 0,
      kontakt: kontakt,
    };
    dispatch({
      type: "set_initial_item",
      data: {
        id: kontaktUsluga.id,
        item: initialValues,
        itemsType: "usluge",
      },
    });
  }, []);

  const renderHeader = (naslov: string, sifra: string) => {
    return (
      <div className="flex flex-row justify-content-between text-xl font-bold w-full align-items-center">
        <div className="flex align-items-center">
          <Image src={Images.ICON_USLUGE} alt="usluge ikonica" className="pr-2" /> {sifra} {naslov}
        </div>
        <div className="flex flex-column align-items-end gap-2">
          {cenaIsLoading ? (
            <Skeleton width="12rem" height="2rem" />
          ) : (
            <div>
              {kolicina} * {formatPrice(calculateUkupnaCenaWithoutPacijentPopust(stavkaNaplatePair, cena) / (kursUsluge ?? 1))} {valutaForUsluga?.sifra}
            </div>
          )}
          {kursUsluge && (
            <div className="text-primary flex gap-2 align-items-center">
              <>
                <i className="pi pi-info-circle icon-info text-primary" data-pr-tooltip={`${Labels.LABEL_INFO_OBRACUNATA_CENA}${moment(new Date()).format(DATE_FORMAT)}`} data-pr-position="bottom" />
                <Tooltip target=".icon-info" />
              </>
              <div>
                {kolicina} * {formatPrice(calculateUkupnaCenaWithoutPacijentPopust(stavkaNaplatePair, cena))} {finalnaValuta}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <Accordion
      key={kontaktUsluga.id}
      activeIndex={0}
      className={`uplatioci-accordion ${
        (invalidInputs.usluge.invalidPercentages.includes(kontaktUsluga.id) ||
          invalidInputs.usluge.invalidSumPrices.includes(kontaktUsluga.id) ||
          invalidInputs.usluge.zeroPercentages.includes(kontaktUsluga.id)) &&
        "invalid-field"
      }`}
    >
      <AccordionTab headerTemplate={() => renderHeader(kontaktUsluga.sifarnikUsluga.naziv, kontaktUsluga.sifarnikUsluga.sifra)}>
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-info"
          onClick={() => {
            dispatch({ type: "add_stavka_naplate", data: { value: emptyDataObject, id: kontaktUsluga.id, itemsType: "usluge" } });
            setInvalidInputs((prev: InvalidInputsType) => ({
              ...prev,
              usluge: { ...prev.usluge, invalidPercentages: prev.usluge.invalidPercentages.filter((item) => item !== kontaktUsluga.id) },
              invalidPayments: [],
            }));
          }}
          disabled={stavkaNaplatePair?.length >= 2 || stavkaNaplatePair?.[0]?.placeniProcenat === 100}
        />
        <div>
          {state.usluge?.[kontaktUsluga.id]?.map((stavkaNaplate: StavkaNaplate, index: number) => (
            <StavkaNaplateUsluga
              key={index}
              index={index}
              cena={cena}
              stavkaNaplate={stavkaNaplate}
              kontaktUsluga={kontaktUsluga}
              kategorijePopustaList={kategorijePopustaList}
              cenaIsLoading={cenaIsLoading}
            />
          ))}
        </div>
      </AccordionTab>
    </Accordion>
  );
};

export default UplatilacCardUsluga;
