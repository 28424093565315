import { InitialStateKontaktPaketDialogType } from "./initialStateKontaktPaketDialog";
import actions from "./actionsKontaktPaketDialog";

export default function reducerKontaktPaketDialog(state: InitialStateKontaktPaketDialogType, action: any) {
  switch (action.type) {
    case actions.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
        aktivniPaketiList: action.value,
      };
    }
    case actions.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        aktivniPaketiList: [],
        usluge: [],
        proizvodi: [],
      };
    }
    case actions.LOAD_USLUGE_PROIZVODI: {
      return {
        ...state,
        proizvodi: action.value_proizvodi,
        usluge: action.value_usluge,
      };
    }
    case actions.LOADING: {
      return {
        ...state,
        loadingUslugeProizvodi: action.value,
      };
    }
    default:
      return state;
  }
}
