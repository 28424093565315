import { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../../../Store";
import TerminController from "../../../../controllers/termin/TerminController";
import { ZakazaniTerminController } from "../../../../controllers/zakazani-termin/ZakazaniTerminController";
import FileType from "../../../../infrastructure/system/FileType";
import Images from "../../../../infrastructure/system/Images";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";
import ZakazanTerminReadDto from "../../../../model/zakazan-termin/ZakazanTerminReadDto";
import ZakazanTerminDatotekaReadDto from "../../../../model/zakazan-termin/datoteke/ZakazanTerminDatotekaReadDto";

interface ZakazivanjeDatotekaProps {
  datoteka: ZakazanTerminDatotekaReadDto;
  zakazanTermin?: ZakazanTerminReadDto;
}
export const ZakazivanjeDatoteka = ({ datoteka, zakazanTermin }: ZakazivanjeDatotekaProps) => {
  const { showMessage } = useContext(AppContext);
  const { axiosGetDocumentFromScheduler } = TerminController();
  const { axiosGetDatotekaForZakazanTerminById } = ZakazaniTerminController();
  const handleDatotekaDownload = (datoteka: ZakazanTerminDatotekaReadDto) => {
    if (zakazanTermin) {
      const request = datoteka.eksterniZakazanTerminDatotekaID
        ? axiosGetDocumentFromScheduler(zakazanTermin.id, datoteka.eksterniZakazanTerminDatotekaID)
        : axiosGetDatotekaForZakazanTerminById(datoteka.id);
      request
        .then((response: AxiosResponse) => {
          const link = document.createElement("a");
          const byteChars = atob(response.data.data.sadrzaj);
          const dataArray = new Array(byteChars.length);
          for (let i = 0; i < byteChars.length; i++) {
            dataArray[i] = byteChars.charCodeAt(i);
          }
          const byteArray = new Uint8Array(dataArray);
          let doc = new Blob([byteArray], { type: datoteka.tip });
          const url = URL.createObjectURL(doc);
          link.download = datoteka.naziv;
          link.href = url;
          link.click();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  return (
    <div className="flex flex-row justify-content-between align-items-center datoteka-item py-2">
      <div className="flex align-items-center pl-2">
        {datoteka?.tip === FileType.doc && <img src={Images.ICON_DOC} alt="doc-icon" className="file-icon" />}
        {datoteka?.tip === FileType.docx && <img src={Images.ICON_DOCX} alt="docx-icon" className="file-icon" />}
        {datoteka?.tip === FileType.pdf && <img src={Images.ICON_PDF} alt="pdf-icon" className="file-icon" />}
        {datoteka?.tip === FileType.ppt && <img src={Images.ICON_PPT} alt="ppt-icon" className="file-icon" />}
        {datoteka?.tip === FileType.pptx && <img src={Images.ICON_PPTX} alt="pptx-icon" className="file-icon" />}
        {datoteka?.tip === FileType.rar && <img src={Images.ICON_RAR} alt="rar-icon" className="file-icon" />}
        {datoteka?.tip === FileType.txt && <img src={Images.ICON_TXT} alt="txt-icon" className="file-icon" />}
        {datoteka?.tip === FileType.xls && <img src={Images.ICON_XLS} alt="xls-icon" className="file-icon" />}
        {datoteka?.tip === FileType.zip && <img src={Images.ICON_ZIP} alt="zip-icon" className="file-icon" />}
        {datoteka?.tip?.includes("image") && <img src={Images.ICON_IMAGE} alt="icon" className="file-icon" />}
        {!datoteka?.tip && <i className="pi pi-file no-file-type" />}
        <span className="ml-3">{datoteka.naziv}</span>
      </div>
      <i className="pi pi-download pr-2 cursor-pointer" onClick={() => handleDatotekaDownload(datoteka)} />
    </div>
  );
};
