import {EnumController} from "../../../../controllers/enum/EnumController";
import {useQuery} from "@tanstack/react-query";
import QueryKeys from "../../QueryKeys";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";

export function useInicijalizacijaEnumList() {
  const { axiosGetEnumInizijalizacijaKorakList } = EnumController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.INICIJALIZACIJA_ENUM_LIST],
    queryFn: () => axiosGetEnumInizijalizacijaKorakList(),
  });

  const inicijalizacijaEnumList: Array<EnumBaseReadDto> = data?.data?.data;

  return { inicijalizacijaEnumList, refetch, isLoading };
}
