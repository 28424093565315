enum actionsAddPatientNotification {
  OPEN_DIALOG = "show",
  CLOSE_DIALOG = "close",
  OBAVESTENJE_TYPE_LIST = "obavestenje_type_list",
  OBAVESTENJE_TYPE_LIST_LOADING = "obavestenje_type_list_loading",
  OBAVESTENJE_CHANGE = "obavestenje_change",
  OBAVESTENJE_DELETE = "obavestenje_delete",
  OBAVESTENJE_LOADING = "obavestenje_loading",
  GENDER_LIST = "gender_list",
  GENDER_LIST_LOADING = "gender_list_loading",
  PATIENTS_INCLUDED_LIST_LOADING = "patients_included_list_loading",
  PATIENTS_INCLUDED_SEARCH = "patients_included_search",
}

export default actionsAddPatientNotification;
