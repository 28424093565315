export default function reducer(state: any, action: any) {
  switch (action.type) {
    case "istorija": {
      return {
        ...state,
        showIstorijuCene: true,
        isDialogOpen: true,
        listaCena: action.value,
        listaIndikacija: [],
        listaDodatnihIndikacija: [],
      }
    }
    case "indikacije": {
      return {
        ...state,
        showIstorijuCene: false,
        isDialogOpen: true,
        listaCena: [],
        listaIndikacija: action.value,
        listaDodatnihIndikacija: action.value_additional,
      }
    }
    case "close": {
      return {
        ...state,
        isDialogOpen: false,
        listaCena: [],
        listaIndikacija: [],
        listaDodatnihIndikacija: []
      }
    }
    default:
      return state;
  }
}