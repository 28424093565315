import axios from "axios";
import moment from "moment";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { EnumController } from "../../../controllers/enum/EnumController";

import { LoginController } from "../../../controllers/login/LoginController";
import { RadnikController } from "../../../controllers/radnik/RadnikController";
import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../infrastructure/system/MessageType";
import {
  checkEmpty,
  handleAxiosCallError,
  handleAxiosCallSuccess,
  isEmailFormatValid,
  isFormDisabled,
  skeletonTimeout,
  useEffectOnce,
  validateStringEmpty,
} from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import { AppContext, useLabels } from "../../../Store";
import RadnikPartialUpdateDto from "../../../model/radnik/RadnikPartialUpdateDto";
import RadnikReadDto from "../../../model/radnik/RadnikReadDto";
import RadnikSkracenoReadDto from "../../../model/radnik/RadnikSkracenoReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";

interface RadnikCrudLogicalType {
  radnikChange: RadnikReadDto | undefined;
  setRadnikChange: any;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  isDisabled: boolean;
  breadCrumbItems: Array<any>;
  onUpdate: () => void;
  radnikOperation: string;
  onCancel: () => void;
  onDelete: () => void;
  onCreate: () => void;
  resetPassword: () => void;
  radnikId: number;
  location: any;
  radnikSearch?: RadnikSearchType;
  radnikList: Array<RadnikReadDto> | undefined;
  searchRadnikAutocomplete: (e: AutoCompleteCompleteEvent) => void;
  changeAutocomplete: (e: NameValuePair) => void;
  cancelCopyRadnik: () => void;
  onCreateCopyRadnik: () => void;
  ustanovaId: number;
  radnikStatusList: Array<EnumBaseReadDto>;
  radnikStatusListLoading: boolean;
  polList: Array<EnumBaseReadDto>;
  polListLoading: boolean;
  radnikLoading: boolean;
  radnikCreateExisting: boolean;
  onCloseRadnikSelectionDialog: () => void;
  getPol: (radnik: RadnikReadDto | undefined) => string;
  getStatus: (radnik: RadnikReadDto | undefined) => string;
  alreadyCreatedRadnik: RadnikReadDto | undefined;
  createRadnikUstanova: (radnikId: number | undefined) => void;
  updateRadnikAndCreateRadnikUstanova: (radnikId: number | undefined, radnik: RadnikReadDto | undefined) => void;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
}

interface UseParamsType {
  radnikId?: string;
  ustanovaId?: string;
}

interface RadnikSearchType {
  searchParam?: RadnikReadDto;
}
interface NameValuePair {
  name: string;
  value: any;
}

export default function CrudRadnikLogical(): RadnikCrudLogicalType {
  const location = useLocation();
  const Labels = useLabels();
  const { showMessage, locale, setShowBlockUI } = useContext(AppContext);
  const [radnikOperation, setRadnikOperation] = useState<string>(location.state && location.state.radnikOperation !== undefined ? location.state.radnikOperation : EntityOperation.UPDATE);
  const [ustanovaNaziv] = useState<string>(location.state && location.state.ustanovaNaziv !== undefined ? location.state.ustanovaNaziv : "");
  const [isDisabled] = useState<boolean>(isFormDisabled(radnikOperation));
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [index, setIndex] = useState<number>(tabIndex);
  const [radnikChange, setRadnikChange] = useState<RadnikReadDto | undefined>();
  const [newRadnik, setNewRadnik] = useState<RadnikReadDto | undefined>();
  const [alreadyCreatedRadnik, setAlreadyCreatedRadnik] = useState<RadnikReadDto | undefined>();
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const { axiosResetPassword } = LoginController();

  const postLogHighLevel = useLogHighLevel();

  const {
    axiosSearchRadnik,
    axiosCreateRadnik,
    axiosCreateCopyRadnik,
    axiosUpdateRadnik,
    axiosDeleteRadnik,
    axiosGetRadnik,
    axiosCreateRadnikUstanovaWithExistingRadnik,
    axiosUpdateRadnikAndCreateRadnikUstanova,
  } = RadnikController();
  const [radnikSearch, setRadnikSearch] = useState<RadnikSearchType>();
  const [radnikList, setRadnikList] = useState<Array<RadnikReadDto> | undefined>();
  const [radnikStatusList, setRadnikStatusList] = useState<Array<EnumBaseReadDto>>([]);
  const [polList, setPolList] = useState<Array<EnumBaseReadDto>>([]);
  const [radnikStatusListLoading, setRadnikStatusListLoading] = useState(true);
  const [polListLoading, setPolListLoading] = useState(true);
  const [radnikLoading, setRadnikLoading] = useState(true);
  const [radnikCreateExisting, setRadnikCreateExisting] = useState<boolean>(false);
  const {
    axiosSuperadminDeleteUstanovaRadnik,
    axiosSuperadminGetUstanovaRadnik,
    axiosSuperadminCreateUstanovaRadnik,
    axiosSuperadminUpdateUstanovaRadnik,
    axiosSuperadminCreateRadnikUstanovaWithExistingRadnik,
    axiosSuperadminUpdateRadnikAndCreateRadnikUstanova,
  } = SuperadminController();
  const { axiosGetEnumRadnikStatusList, axiosGetPolList } = EnumController();
  const radnikId = Number(useParams<keyof UseParamsType>()["radnikId"]);
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const navigate = useNavigate();
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>(
    ustanovaId
      ? [
          {
            label: Labels.USTANOVA_LIST,
            command: () => {
              navigate(`/crud-ustanova`);
            },
          },
          {
            label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + " " + ustanovaNaziv,
            command: () => {
              navigate(`/crud-ustanova/${ustanovaId}`, {
                state: {
                  tabIndex: 7,
                },
              });
            },
          },
        ]
      : [
          {
            label: Labels.RADNIK_LIST,
            command: () => {
              navigate("/radnik-list/");
            },
          },
        ]
  );

  const validateInput = (radnik: RadnikReadDto | undefined) => {
    let isInvalid = false;
    if (validateStringEmpty(radnik?.ime)) {
      setInvalidFields((prev) => ({ ...prev, ime: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(radnik?.prezime)) {
      setInvalidFields((prev) => ({ ...prev, prezime: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(radnik?.username)) {
      setInvalidFields((prev) => ({ ...prev, username: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(radnik?.email)) {
      setInvalidFields((prev) => ({ ...prev, email: true }));
      isInvalid = true;
    }
    if (radnik?.email && !isEmailFormatValid(radnik?.email)) {
      setInvalidFields((prev) => ({ ...prev, invalidEmail: true }));
      isInvalid = true;
    }
    if (!radnik?.radnikStatus) {
      setInvalidFields((prev) => ({ ...prev, status: true }));
      isInvalid = true;
    }
    if (!radnik?.polTrenutni) {
      setInvalidFields((prev) => ({ ...prev, pol: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(radnikChange)) {
      return;
    }
    setShowBlockUI(true);
    const requestCreateRadnik = ustanovaId
      ? axiosSuperadminCreateUstanovaRadnik(ustanovaId, { ...radnikChange, password: Labels.RADNIK_PASSWORD_DEFAULT, passwordConfirm: Labels.RADNIK_PASSWORD_DEFAULT, jezik: { sifra: locale } })
      : axiosCreateRadnik({ ...radnikChange, password: Labels.RADNIK_PASSWORD_DEFAULT, passwordConfirm: Labels.RADNIK_PASSWORD_DEFAULT, jezik: { sifra: locale } });
    requestCreateRadnik
      .then((res: any) => {
        const { data } = res?.data;
        let radnikWithEmailExist: boolean = data?.radnikEmailExist;
        const radnikPrekoracenjaBrojaRadnika: boolean = data?.prekoracenjaBrojaRadnika;
        if (radnikWithEmailExist) {
          setRadnikCreateExisting(true);
          showMessage(MessageType.ERROR, Labels.RADNIK_MEJL_ISKORISCEN);
          setAlreadyCreatedRadnik(data);
        } else {
          setNewRadnik(data);
          setIndex(0);
          ustanovaId
            ? postLogHighLevel(
                Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_1 +
                  ustanovaNaziv +
                  Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_2 +
                  radnikChange!.ime +
                  Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_3 +
                  radnikChange!.prezime +
                  Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_4 +
                  radnikChange?.email +
                  Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_5 +
                  radnikChange?.username,
                undefined,
                ustanovaId
              )
            : postLogHighLevel(
                Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_1 +
                  ustanovaNaziv +
                  Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_2 +
                  radnikChange!.ime +
                  Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_3 +
                  radnikChange!.prezime +
                  Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_4 +
                  radnikChange?.email +
                  Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_5 +
                  radnikChange?.username
              );

          showMessage(MessageType.SUCCESS, `${radnikPrekoracenjaBrojaRadnika ? Labels.RADNIK_WARNING_MAX_BROJ_NALOGA + "\n" : ""} ${Labels.RADNIK_TITLE_MESSAGE_CREATE_RADNIK_SUCCESS}`);
          setRadnikOperation(EntityOperation.UPDATE);
          if (!ustanovaId || ustanovaId === undefined) {
            fetchData(data.id, EntityOperation.UPDATE);
          } else {
            if (breadCrumbItems.length >= 3) breadCrumbItems.pop();
            setBreadCrumbItems([
              ...breadCrumbItems,
              {
                label: Labels.RADNIK_TITLE_DIALOG_UPDATE + data.ime + " " + data.prezime,
              },
            ]);
          }
          navigate(ustanovaId ? `/crud-ustanova/${ustanovaId}/crud-radnik/${data.id}` : `/crud-radnik/${data.id}`);
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCloseRadnikSelectionDialog = () => {
    setRadnikCreateExisting(false);
  };

  const onCreateCopyRadnik = () => {
    if (!validateInput(radnikChange)) {
      return;
    }
    setShowBlockUI(true);
    axiosCreateRadnik({ ...radnikChange, password: Labels.RADNIK_PASSWORD_DEFAULT, passwordConfirm: Labels.RADNIK_PASSWORD_DEFAULT, jezik: { sifra: locale } })
      .then((res1: any) => {
        showMessage(MessageType.SUCCESS, Labels.RADNIK_TITLE_MESSAGE_CREATE_RADNIK_SUCCESS);
        radnikSearch?.searchParam &&
          axiosCreateCopyRadnik(res1.data.data.id, radnikSearch.searchParam?.id)
            .then((res2: any) => {
              const { data } = res2.data;
              setNewRadnik(data);
              setIndex(0);
              setRadnikOperation(EntityOperation.UPDATE);
              fetchData(data.id, EntityOperation.UPDATE);
              navigate(`/crud-radnik/${data.id}`);
              setShowBlockUI(false);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
              setShowBlockUI(false);
            });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const onUpdate = () => {
    if (!validateInput(radnikChange)) {
      return;
    }
    if (radnikChange) {
      setShowBlockUI(true);
      const requestUpdateRadnik = ustanovaId ? axiosSuperadminUpdateUstanovaRadnik(ustanovaId, radnikChange) : axiosUpdateRadnik(radnikChange);
      requestUpdateRadnik
        .then(() => {
          ustanovaId
            ? postLogHighLevel(
                Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_1 +
                  ustanovaNaziv +
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_2 +
                  radnikChange!.ime +
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_3 +
                  radnikChange!.prezime +
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_4 +
                  radnikChange?.email +
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_5 +
                  radnikChange?.username,
                undefined,
                ustanovaId
              )
            : postLogHighLevel(
                Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_1 +
                  ustanovaNaziv +
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_2 +
                  radnikChange!.ime +
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_3 +
                  radnikChange!.prezime +
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_4 +
                  radnikChange?.email +
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_5 +
                  radnikChange?.username
              );

          showMessage(MessageType.SUCCESS, Labels.RADNIK_TITLE_MESSAGE_UPDATE_RADNIK_SUCCESS);
          onCancel();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    const requestUpdateRadnik = ustanovaId ? axiosSuperadminDeleteUstanovaRadnik(ustanovaId, radnikId) : axiosDeleteRadnik(radnikId);
    requestUpdateRadnik
      .then(() => {
        ustanovaId
          ? postLogHighLevel(
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_1 +
                ustanovaNaziv +
                Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_2 +
                radnikChange!.ime +
                Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_3 +
                radnikChange!.prezime +
                Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_4 +
                radnikChange?.email +
                Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_5 +
                radnikChange?.username,
              undefined,
              ustanovaId
            )
          : postLogHighLevel(
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_1 +
                ustanovaNaziv +
                Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_2 +
                radnikChange!.ime +
                Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_3 +
                radnikChange!.prezime +
                Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_4 +
                radnikChange?.email +
                Labels.LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_5 +
                radnikChange?.username
            );

        showMessage(MessageType.SUCCESS, Labels.RADNIK_TITLE_MESSAGE_DELETE_RADNIK_SUCCESS);
        onCancel();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/radnik-list`);
    }
  };

  useEffectOnce(() => {
    let startTime = moment(new Date());
    axiosGetEnumRadnikStatusList()
      .then((response: any) => {
        setRadnikStatusList(response.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setRadnikStatusListLoading(false);
      });
    axiosGetPolList()
      .then((response: any) => {
        setPolList(response.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setPolListLoading, startTime);
      });
    fetchData();
  });

  const fetchData = (rId?: number, newOperation?: string | undefined) => {
    let newRadnikId = rId ? rId : newRadnik ? newRadnik.id : radnikId;
    let startTime = moment(new Date());
    let operation = newOperation ? newOperation : radnikOperation;
    if (newRadnikId) {
      const requestRadnik = ustanovaId ? axiosSuperadminGetUstanovaRadnik(ustanovaId, radnikId) : axiosGetRadnik(newRadnikId);
      axios
        .all([requestRadnik])
        .then(
          axios.spread((responseRadnik: any) => {
            const radnik = ustanovaId ? responseRadnik.data.data.radnik : responseRadnik.data.data;
            setRadnikChange(radnik);
            if (breadCrumbItems.length < 2 || (ustanovaId && breadCrumbItems.length < 3)) {
              setBreadCrumbItems([
                ...breadCrumbItems,
                {
                  label:
                    operation === EntityOperation.UPDATE
                      ? Labels.RADNIK_TITLE_DIALOG_UPDATE + radnik.ime + " " + radnik.prezime
                      : operation === EntityOperation.READ
                      ? Labels.RADNIK_TITLE_DIALOG_DETAILS + radnik.ime + " " + radnik.prezime
                      : Labels.RADNIK_TITLE_DIALOG_DELETE + radnik.ime + " " + radnik.prezime,
                },
              ]);
            } else {
              breadCrumbItems.pop();
              setBreadCrumbItems([
                ...breadCrumbItems,
                {
                  label:
                    operation === EntityOperation.UPDATE
                      ? Labels.RADNIK_TITLE_DIALOG_UPDATE + radnik.ime + " " + radnik.prezime
                      : operation === EntityOperation.READ
                      ? Labels.RADNIK_TITLE_DIALOG_DETAILS + radnik.ime + " " + radnik.prezime
                      : Labels.RADNIK_TITLE_DIALOG_DELETE + radnik.ime + " " + radnik.prezime,
                },
              ]);
            }
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setRadnikLoading, startTime);
        });
    } else {
      setRadnikLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.RADNIK_TITLE_DIALOG_CREATE,
        },
      ]);
      setRadnikChange({
        ...radnikChange!,
        racunajNalog: true,
        integracijaSaKalendarom: false,
        dostupanZaEksterneZakazivace: false,
      });
    }
  };

  const resetPassword = () => {
    setShowBlockUI(true);
    radnikChange &&
      axiosResetPassword(radnikChange.email)
        .then((response: any) => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_1 +
              ustanovaNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_2 +
              radnikChange!.ime +
              Labels.LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_3 +
              radnikChange!.prezime +
              Labels.LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_4 +
              radnikChange?.email +
              Labels.LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_5 +
              radnikChange?.username +
              Labels.LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_6
          );

          handleAxiosCallSuccess(showMessage, response);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
  };

  const searchRadnikAutocomplete = (e: AutoCompleteCompleteEvent) => {
    if (radnikList?.length && checkEmpty(e.query)) {
      setRadnikList([...radnikList]);
      return;
    }

    setTimeout(() => {
      axiosSearchRadnik({ searchString: e.query })
        .then((res: any) => {
          setRadnikList(res.data.data.map((r: RadnikSkracenoReadDto) => ({ ...r, title: `${r?.titula ?? ""} ${r?.ime ?? ""} ${r?.prezime ?? ""}` })));
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }, 250);
  };

  const changeAutocomplete = ({ name, value }: NameValuePair) => {
    setRadnikSearch({
      ...radnikSearch,
      [name]: value ?? undefined,
    });
  };

  const cancelCopyRadnik = () => {
    setRadnikChange(undefined);
    setRadnikSearch(undefined);
  };

  const getPol = (radnik: RadnikReadDto | undefined) => {
    let polNaziv: string = "";
    if (radnik && radnik.polTrenutni) {
      polList?.forEach((pol: EnumBaseReadDto) => {
        if (pol.sifra === radnik.polTrenutni.sifra) {
          polNaziv = pol.naziv;
          return;
        }
      });
    }
    return polNaziv;
  };

  const getStatus = (radnik: RadnikReadDto | undefined) => {
    let statusNaziv: string = "";
    if (radnik && radnik.radnikStatus) {
      radnikStatusList?.forEach((status: EnumBaseReadDto) => {
        if (status.sifra === radnik.radnikStatus.sifra) {
          statusNaziv = status.naziv;
          return;
        }
      });
    }
    return statusNaziv;
  };

  const createRadnikUstanova = (radnikId: number | undefined) => {
    if (radnikId) {
      setShowBlockUI(true);
      const requestCreateRadnikUstanovaWithExistingRadnik = ustanovaId
        ? axiosSuperadminCreateRadnikUstanovaWithExistingRadnik(ustanovaId, radnikId)
        : axiosCreateRadnikUstanovaWithExistingRadnik(radnikId);

      requestCreateRadnikUstanovaWithExistingRadnik
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.RADNIK_DODAT_U_USTANOVU);
          navigate(ustanovaId ? `/crud-ustanova/${ustanovaId}` : `/radnik-list`, {
            state: {
              tabIndex: 5,
            },
          });
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const updateRadnikAndCreateRadnikUstanova = (radnikId: number | undefined, radnik: RadnikReadDto | undefined) => {
    if (radnikId && radnik) {
      let radnikPartialUpdateDto: RadnikPartialUpdateDto = {
        id: radnikId,
        ime: radnik.ime,
        prezime: radnik.prezime,
        titula: radnik?.titula,
        lbo: radnik?.lbo,
        idBrojLekara: radnik?.idBrojLekara,
        zanimanje: radnik?.zanimanje,
        telefon: radnik?.telefon,
        jmbg: radnik?.jmbg,
      };
      setShowBlockUI(true);
      const requestUpdateRadnikAndCreateRadnikUstanova = ustanovaId
        ? axiosSuperadminUpdateRadnikAndCreateRadnikUstanova(ustanovaId, radnikPartialUpdateDto)
        : axiosUpdateRadnikAndCreateRadnikUstanova(radnikPartialUpdateDto);

      requestUpdateRadnikAndCreateRadnikUstanova
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.RADNIK_JE_IZMENJEN_I_DODAT_U_USTANOVU);
          navigate(ustanovaId ? `/crud-ustanova/${ustanovaId}` : `/radnik-list`, {
            state: {
              tabIndex: 5,
            },
          });
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  return {
    radnikChange,
    setRadnikChange,
    setIndex,
    index,
    isDisabled,
    breadCrumbItems,
    onUpdate,
    radnikOperation,
    onCancel,
    onDelete,
    onCreate,
    resetPassword,
    location,
    radnikId,
    radnikSearch,
    radnikList,
    searchRadnikAutocomplete,
    changeAutocomplete,
    cancelCopyRadnik,
    onCreateCopyRadnik,
    ustanovaId,
    radnikStatusList,
    radnikStatusListLoading,
    radnikLoading,
    polList,
    polListLoading,
    radnikCreateExisting,
    onCloseRadnikSelectionDialog,
    getPol,
    getStatus,
    alreadyCreatedRadnik,
    createRadnikUstanova,
    updateRadnikAndCreateRadnikUstanova,
    invalidFields,
    setInvalidFields,
  };
}
