import { Skeleton } from "primereact/skeleton";
import { useMemo } from "react";

interface SkeletonPacijentInfoVerticalType {
  size?: number;
}

export default function SkeletonPacijentInfoVertical(props: SkeletonPacijentInfoVerticalType) {
  const { size } = props;

  const divStyles = useMemo(() => {
    return { width: "150px" };
  }, []);

  return (
    <div className="flex justify-content-center flex-wrap" style={divStyles}>
      <Skeleton shape="circle" borderRadius="40px" size="80px" className="align-items-center justify-content-center mb-2" />
      {[...Array(size ?? 1).keys()].map((j: number) => (
        <Skeleton key={j} height="30px" className="col-12 flex flex-wrap m-1" />
      ))}
    </div>
  );
}
