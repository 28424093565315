import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import PorukaCreateDto from "../../model/poruke/PorukaCreateDto";
import EnumBaseMandatoryDto from "../../model/sifarnik/EnumBaseMandatoryDto";

interface PorukeControllerType {
  axiosPostPoruka: (params: PorukaCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetPorukaUnreadCount: () => Promise<AxiosResponse<any>>;
  axiosGetPrimljenaPorukaList: (params?: { page?: number; size?: number; searchString?: string }) => Promise<AxiosResponse<any>>;
  axiosGetPoslataPorukaList: (params?: { page?: number; size?: number; searchString?: string }) => Promise<AxiosResponse<any>>;
  axiosGetPoruka: (porukaID: number) => Promise<AxiosResponse<any>>;
  axiosUpdatePorukaStatus: (porukaRadnikID: number, porukaStatus: EnumBaseMandatoryDto) => Promise<AxiosResponse<any>>;
  axiosGetPorukaDatoteka: (porukaDatotekaID: number) => Promise<AxiosResponse<any>>;
  axiosGetPorukaDatotekaList: (porukaDatotekaIDList: string) => Promise<AxiosResponse<any>>;
  axiosDeletePoslataPoruka: (porukaID: number) => Promise<AxiosResponse<any>>;
  axiosDeletePrimljenaPoruka: (porukaRadnikID: number) => Promise<AxiosResponse<any>>;
}

export default function PorukeController(): PorukeControllerType {
  const { authData } = useContext(AppContext);

  const axiosPostPoruka = (params: PorukaCreateDto) => {
    return axios.post(Endpoint.PORUKE_LIST, params ? params : {}, axiosConfig(authData!.token));
  };

  const axiosGetPorukaUnreadCount = () => {
    return axios.get(Endpoint.PORUKE_LIST + "/primljene/neprocitane-count", axiosConfig(authData!.token));
  };

  const axiosGetPrimljenaPorukaList = (params?: { page?: number; size?: number; searchString?: string }) => {
    return axios.get(Endpoint.PORUKE_LIST + "/primljene", axiosConfig(authData!.token, params));
  };

  const axiosGetPoslataPorukaList = (params?: { page?: number; size?: number; searchString?: string }) => {
    return axios.get(Endpoint.PORUKE_LIST + "/poslate", axiosConfig(authData!.token, params));
  };

  const axiosGetPoruka = (porukaID: number) => {
    return axios.get(`${Endpoint.PORUKE_LIST}/${porukaID}`, axiosConfig(authData!.token));
  };

  const axiosUpdatePorukaStatus = (porukaRadnikID: number, porukaStatus: EnumBaseMandatoryDto) => {
    return axios.put(`${Endpoint.PORUKE_LIST}/${porukaRadnikID}/change-status`, { porukaStatus }, axiosConfig(authData!.token));
  };

  const axiosGetPorukaDatoteka = (porukaDatotekaID: number) => {
    return axios.get(`${Endpoint.PORUKA_DATOTEKA_LIST}/${porukaDatotekaID}`, axiosConfig(authData!.token));
  };

  const axiosGetPorukaDatotekaList = (porukaDatotekaIDList: string) => {
    return axios.get(`${Endpoint.PORUKA_DATOTEKA_LIST}`, axiosConfig(authData!.token, { notificationPorDatIDList: porukaDatotekaIDList }));
  };

  const axiosDeletePoslataPoruka = (porukaID: number) => {
    return axios.delete(`${Endpoint.PORUKE_LIST}/${porukaID}`, axiosConfig(authData!.token));
  };

  const axiosDeletePrimljenaPoruka = (porukaRadnikID: number) => {
    return axios.delete(`${Endpoint.PORUKE_LIST}/primljene/${porukaRadnikID}`, axiosConfig(authData!.token));
  };

  return {
    axiosPostPoruka,
    axiosGetPorukaUnreadCount,
    axiosGetPrimljenaPorukaList,
    axiosGetPoslataPorukaList,
    axiosGetPoruka,
    axiosUpdatePorukaStatus,
    axiosGetPorukaDatoteka,
    axiosDeletePoslataPoruka,
    axiosDeletePrimljenaPoruka,
    axiosGetPorukaDatotekaList,
  };
}
