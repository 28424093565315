import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import ObavestenjeReadDto from "../../../model/obavestenje/ObavestenjeReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import ObavestenjeListLogical from "./ObavestenjeListLogical";
import DialogObavestenje from "./dialog-obavestenje/DialogObavestenjeView";

export default function ObavestenjeListPage() {
  const {
    obavestenjeList,
    first,
    tableRows,
    selectedObavestenje,
    setSelectedObavestenje,
    changeObavestenjeSearchData,
    obavestenjeSearchData,
    fetchData,
    searchObavestenjeByEnter,
    onPageObavestenje,
    exportData,
    breadCrumbItems,
    obavestenjeLoading,
    closeDialogObavestenje,
    renderDateTime,
  } = ObavestenjeListLogical();

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMini } = PageDropdownTemplate();
  const { pristup } = useContext(AppContext);

  const crudBodyTemplate = (rowObavestenje: ObavestenjeReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.OBAVESTENJE_TITLE_DIALOG_VIEW}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-info mr-2"
          icon="pi pi-eye"
          onClick={() => rowObavestenje && setSelectedObavestenje(rowObavestenje)}
        />
        <Button
          type="button"
          tooltip={Labels.OBAVESTENJE_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowObavestenje && openObavestenje(EntityOperation.DELETE, rowObavestenje.id)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const openObavestenje = (entityOperation: string, id?: number) => {
    const idObavestenje = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-obavestenje/${idObavestenje}`, {
      state: { obavestenjeOperation: entityOperation },
    });
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_OBAVESTENJE}
          tooltip={Labels.OBAVESTENJE_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openObavestenje(EntityOperation.CREATE);
          }}
          disabled={!pristup}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={obavestenjeSearchData?.searchString ?? ""}
            onChange={changeObavestenjeSearchData}
            onKeyPress={searchObavestenjeByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_OBAVESTENJE}</label>
          <Button tooltip={Labels.OBAVESTENJE_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={obavestenjeLoading} data={exportData} name={Labels.OBAVESTENJE_LIST} dt={dt} />
      </>
    );
  };

  const datumBody = (rowObavestenje: ObavestenjeReadDto) => {
    return <div>{renderDateTime(rowObavestenje.vremeVazenja)}</div>;
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.OBAVESTENJE_NASLOV, filter: true, sortrable: true },
    { columnName: Labels.OBAVESTENJE_SADRZAJ, filter: true, sortrable: true },
    { columnName: Labels.OBAVESTENJE_VAZI_DO, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {obavestenjeLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList defaultButtonCount={2} />
        ) : (
          <div className="col-12">
            <DataTable
              ref={dt}
              value={obavestenjeList}
              paginator
              first={first}
              filterDisplay="row"
              paginatorTemplate={rowsPerPageDropdownTemplateMini as PaginatorTemplateOptions}
              rows={tableRows}
              onPage={(e) => {
                onPageObavestenje(e.rows, e.first);
              }}
              exportFilename={Labels.OBAVESTENJE_LIST}
              emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
              rowHover
              stripedRows
            >
              <Column className="col-3" field={"naslov"} header={Labels.OBAVESTENJE_NASLOV} sortable filter filterMatchMode="contains" showFilterMenu={false} />
              <Column className="col-5" field={"sadrzaj"} header={Labels.OBAVESTENJE_SADRZAJ} bodyClassName="sadrzaj-cell" filter filterMatchMode="contains" showFilterMenu={false} />
              <Column className="col-2" body={datumBody} header={Labels.OBAVESTENJE_VAZI_DO} showFilterMenu={false} />
              <Column className="col-2" body={crudBodyTemplate} />
            </DataTable>
          </div>
        )}
      </Panel>
      {selectedObavestenje && <DialogObavestenje obavestenje={selectedObavestenje} closeDialogObavestenje={closeDialogObavestenje} />}
    </div>
  );
}
