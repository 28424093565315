import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Panel } from "primereact/panel";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { DATE_FORMAT, formatDate } from "../../../../infrastructure/system/Utils";
import DokumentReadDto from "../../../../model/dokument/DokumentReadDto";
import ZakazanTerminDatotekaReadDto from "../../../../model/zakazan-termin/datoteke/ZakazanTerminDatotekaReadDto";
import IconScheduler from "../../../app/icons/IconScheduler";

interface DatotetkaListPreviewProps {
  datotekaList: DokumentReadDto[] | ZakazanTerminDatotekaReadDto[];
  deleteDatoteka?: (id: number) => void;
  disabled?: boolean;
  handleDatotekaClick: (datoteka: DokumentReadDto | ZakazanTerminDatotekaReadDto) => void;
}

export default function DatatotekaListPreview({ datotekaList, deleteDatoteka, disabled, handleDatotekaClick }: DatotetkaListPreviewProps) {
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  function isDokumentReadDto(datoteka: DokumentReadDto | ZakazanTerminDatotekaReadDto): datoteka is DokumentReadDto {
    return (datoteka as DokumentReadDto).kontaktTip !== undefined;
  }

  function isZakazanTerminDatoteka(datoteka: DokumentReadDto | ZakazanTerminDatotekaReadDto): datoteka is ZakazanTerminDatotekaReadDto {
    return (datoteka as ZakazanTerminDatotekaReadDto).zakazanTerminID !== undefined;
  }

  return (
    <Panel className="mt-3">
      <div className="w-full">
        {datotekaList.length === 0 && <div>{Labels.LISTA_DATOTEKA_PRAZNA}</div>}
        {datotekaList.map((datoteka: DokumentReadDto | ZakazanTerminDatotekaReadDto, index: number) => {
          return (
            <div key={index} className="flex justify-content-between align-items-center bottom-border py-3 border-300">
              <div className="flex cursor-pointer gap-2 text-primary align-items-center m-0" onClick={() => handleDatotekaClick(datoteka)}>
                <div>{datoteka.naziv}</div>
                {isZakazanTerminDatoteka(datoteka) && datoteka.eksterniZakazanTerminDatotekaID ? <IconScheduler color="var(--surface-600)" height="1.6rem" /> : null}
              </div>
              <div className="flex justify-content-end hc-gap-4 align-items-center">
                {isDokumentReadDto(datoteka) && <p className="m-0">{datoteka.kontaktTip?.naziv}</p>}
                <p className="m-0">{formatDate(datoteka.vremeKreiranja, DATE_FORMAT)}</p>
                {deleteDatoteka && (
                  <i
                    onClick={(e) => {
                      if (pristup && !disabled) {
                        confirmPopup({
                          target: e.currentTarget,
                          message: Labels.LABEL_REMOVE_DOCUMENT,
                          acceptLabel: Labels.BUTTON_YES,
                          rejectLabel: Labels.BUTTON_NO,
                          icon: "pi pi-exclamation-triangle",
                          accept: () => {
                            deleteDatoteka(datoteka.id);
                          },
                          reject: () => {},
                        });
                      }
                    }}
                    className="pi pi-times cursor-pointer delete-attachment-icon m-0"
                  ></i>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ConfirmPopup />
    </Panel>
  );
}
