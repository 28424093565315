import { InitialStateAddObrazacType } from "./initialStateAddObrazac";
import actions from "./actionsAddObrazac";

export default function reducerAddObrazac(state: InitialStateAddObrazacType, action: any) {
  switch (action.type) {
    case actions.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true
      };
    }
    case actions.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        obrasci: [],
        selectedObrazac: undefined,
      };
    }
    case actions.OBRASCI_LIST_LOADING: {
      return {
        ...state,
        obrasciLoading: action.value,
      };
    }
    case actions.OBRASCI_LIST: {
      return {
        ...state,
        obrasci: action.value,
      };
    }
    case actions.SET_SELECTED_OBRAZAC_FROM_REPO: {
      return {
        ...state,
        selectedObrazac: action.value,
      };
    }
    default:
      return state;
  }
}
