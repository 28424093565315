import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent, AutoCompleteSelectEvent, AutoCompleteUnselectEvent } from "primereact/autocomplete";
import { useMemo } from "react";

interface AutocompleteSelectType {
  name?: string;
  value: any;
  suggestions?: any[];
  completeMethod: (e: AutoCompleteCompleteEvent) => void;
  changeData: any;
  fieldName: string;
  placeholder?: string;
  onSelect: (e: AutoCompleteSelectEvent) => void;
  onUnselect: (e: AutoCompleteUnselectEvent) => void;
}

export default function AutocompleteSelect(props: AutocompleteSelectType) {
  const { name, value, suggestions, completeMethod, fieldName, changeData, placeholder, onSelect, onUnselect } = props;

  const change = (e: AutoCompleteChangeEvent) => {
    changeData({
      name: name,
      value: e.value.length > 0 ? e.value[e.value.length - 1] : undefined,
    });
  };

  const divStyles = useMemo(() => {
    return { display: "none" };
  }, []);

  return (
    <AutoComplete
      name={name}
      value={value ? [value] : undefined}
      suggestions={suggestions ?? []}
      className="inputfield w-full"
      inputClassName="inputfield w-full"
      multiple
      completeMethod={completeMethod}
      field={fieldName}
      onChange={change}
      placeholder={placeholder}
      dropdown
      inputStyle={value && divStyles}
      onSelect={onSelect}
      onUnselect={onUnselect}
    />
  );
}
