import { useQuery } from "@tanstack/react-query";
import RacunController from "../../../controllers/naplata/RacunController";
import QueryKeys from "../../../infrastructure/system/QueryKeys";

export function useProizvodCena(proizvodID: number, datumPruzanja: string) {
  const { axiosGetProizvodCena } = RacunController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.PROIZVOD_CENA_LIST, proizvodID, datumPruzanja],
    queryFn: () => axiosGetProizvodCena(proizvodID, datumPruzanja),
  });

  return { proizvodCena: data?.data?.data, isLoading };
}
