import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import IzvestajCreateDto from "../../model/izvestaj/IzvestajCreateDto";
import IzvestajPublicCopyDto from "../../model/izvestaj/IzvestajPublicCopyDto";
import IzvestajReadDto from "../../model/izvestaj/IzvestajReadDto";

interface IzvestajControllerType {
  axiosSearchIzvestaj: (izvestajSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetIzvestaj: (izvestajId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteIzvestaj: (izvestajId: number) => Promise<AxiosResponse<any>>;
  axiosCreateIzvestaj: (izvestaj: IzvestajCreateDto) => Promise<AxiosResponse<any>>;
  axiosUpdateIzvestaj: (izvestaj: IzvestajReadDto) => Promise<AxiosResponse<any>>;
  axiosRepositoryPublicSearchIzvestaj: (izvestajSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosRepositoryPublicCopyIzvestaj: (izvestajPublicCopy: IzvestajPublicCopyDto) => Promise<AxiosResponse<any>>;
}

export default function IzvestajController(): IzvestajControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchIzvestaj = (izvestajSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.IZVESTAJ_SEARCH, axiosConfig(authData!.token, izvestajSearchData));
  };

  const axiosGetIzvestaj = (izvestajId: number) => {
    return axios.get(`${Endpoint.IZVESTAJ_LIST}/${izvestajId}`, axiosConfig(authData!.token));
  };

  const axiosDeleteIzvestaj = (izvestajId: number) => {
    return axios.delete(`${Endpoint.IZVESTAJ_LIST}/${izvestajId}`, axiosConfig(authData!.token));
  };

  const axiosCreateIzvestaj = (izvestaj: IzvestajCreateDto) => {
    return axios.post(Endpoint.IZVESTAJ_LIST, izvestaj, axiosConfig(authData!.token));
  };

  const axiosUpdateIzvestaj = (izvestaj: IzvestajReadDto) => {
    return axios.put(`${Endpoint.IZVESTAJ_LIST}/${izvestaj.id}`, izvestaj, axiosConfig(authData!.token));
  };
  const axiosRepositoryPublicSearchIzvestaj = (izvestajSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.REPOSITORY_IZVESTAJ_PUBLIC_SEARCH, axiosConfig(authData!.token, izvestajSearchData));
  };
  const axiosRepositoryPublicCopyIzvestaj = (izvestajPublicCopy: IzvestajPublicCopyDto) => {
    return axios.post(`${Endpoint.REPOSITORY_IZVESTAJ}${Endpoint.COPY_PUBLIC_SUFIX}`, izvestajPublicCopy, axiosConfig(authData!.token));
  };

  return {
    axiosSearchIzvestaj,
    axiosGetIzvestaj,
    axiosDeleteIzvestaj,
    axiosCreateIzvestaj,
    axiosUpdateIzvestaj,
    axiosRepositoryPublicSearchIzvestaj,
    axiosRepositoryPublicCopyIzvestaj,
  };
}
