import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";
import SearchBaseDto from "../../model/SearchBaseDto";
import OrganizacionaJedinicaKontaktTipCreateDto from "../../model/organizacionaJedinica/OrganizacionaJedinicaKontaktTipCreateDto";
import OrganizacionaJedinicaRadnikCreateDto from "../../model/organizacionaJedinica/OrganizacionaJedinicaRadnikCreateDto";
import OrganizacionaJedinicaReadDto from "../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import OrganizacionaJedinicaSpecijalizacijaCreateDto from "../../model/organizacionaJedinica/OrganizacionaJedinicaSpecijalizacijaCreateDto";
import { AppContext } from "./../../Store";
import OrganizacionaJedinicaKontaktTipReadDto from "../../model/organizacionaJedinica/OrganizacionaJedinicaKontaktTipReadDto";
import OrganizacionaJedinicaWithUstanovaReadDto from "../../model/organizacionaJedinica/OrganizacionaJedinicaWithUstanovaReadDto";
import OrganizacionaJedinicaRadnikReadDto from "../../model/organizacionaJedinica/OrganizacionaJedinicaRadnikReadDto";

interface OrganizacionaJedinicaControllerType {
  axiosSearchOrganizacionaJedinica: (organizacionaJedinicaSearchData?: SearchBaseDto) => Promise<AxiosResponse<CommonResponseDto<OrganizacionaJedinicaReadDto[]>>>;
  axiosCreateOrganizacionaJedinica: (organizacionaJedinicaCreate: OrganizacionaJedinicaReadDto) => Promise<AxiosResponse<any>>;
  axiosReadOrganizacionaJedinica: (organizacionaJedinicaId: number) => Promise<AxiosResponse<CommonResponseDto<OrganizacionaJedinicaWithUstanovaReadDto>>>;
  axiosUpdateOrganizacionaJedinica: (organizacionaJedinicaCreate: OrganizacionaJedinicaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteOrganizacionaJedinica: (organizacionaJedinicaId: number) => Promise<AxiosResponse<any>>;
  axiosGetOrganizacionaJedinicaRadnikList: (organizacionaJedinicaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateOrganizacionaJedinicaRadnik: (organizacionaJedinicaId: number, organizacionaJedinicaRadnikCreate: OrganizacionaJedinicaRadnikCreateDto[]) => Promise<AxiosResponse<any>>;
  axiosDeleteOrganizacionaJedinicaRadnik: (organizacionaJedinicaId: number, radnikIds: Array<number>) => Promise<AxiosResponse<any>>;
  axiosGetOrganizacionaJedinicaRadniDanList: (organizacionaJedinicaId: number) => Promise<AxiosResponse<any>>;
  axiosGetOrganizacionaJedinicaRadnikListByRola: (
    organizacionaJedinicaId: number,
    rolaSifra: string,
    viewNeaktivni?: boolean
  ) => Promise<AxiosResponse<CommonResponseDto<OrganizacionaJedinicaRadnikReadDto[]>>>;
  axiosGetOrganizacionaJedinicaKontaktTipList: (organizacionaJedinicaId: number) => Promise<AxiosResponse<CommonResponseDto<OrganizacionaJedinicaKontaktTipReadDto[]>>>;
  axiosCreateOrganizacionaJedinicaKontaktTip: (organizacionaJedinicaId: number, organizacionaJedinicaKontaktTipCreate: OrganizacionaJedinicaKontaktTipCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteOrganizacionaJedinicaKontaktTip: (organizacionaJedinicaId: number, kontaktTipId: number) => Promise<AxiosResponse<any>>;
  axiosPrintProtokol: (organizacionaJedinicaId: number, datum: string) => Promise<AxiosResponse<any>>;
  axiosGetSpecijalizacijeList: (organizacionaJedinicaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateSpecijalizacijeForOrganizacionaJedinica: (organizacionaJedinicaId: number, specijalizacijaList: Array<OrganizacionaJedinicaSpecijalizacijaCreateDto>) => Promise<AxiosResponse<any>>;
  axiosDeleteSpecijalizacijeForOrganizacionaJedinica: (organizacionaJedinicaId: number, specijalizacijaList: Array<number>) => Promise<AxiosResponse<any>>;
}

export function OrganizacionaJedinicaController(): OrganizacionaJedinicaControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchOrganizacionaJedinica = (organizacionaJedinicaSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.ORGANIZACIONA_JEDINICA_SEARCH, axiosConfig(authData!.token, organizacionaJedinicaSearchData));
  };

  const axiosReadOrganizacionaJedinica = (organizacionaJedinicaId: number) => {
    return axios.get(`${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaId}`, axiosConfig(authData!.token));
  };

  const axiosCreateOrganizacionaJedinica = (organizacionaJedinicaCreate: OrganizacionaJedinicaReadDto) => {
    return axios.post(Endpoint.ORGANIZACIONA_JEDINICA_LIST, organizacionaJedinicaCreate ? organizacionaJedinicaCreate : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateOrganizacionaJedinica = (organizacionaJedinicaChange: OrganizacionaJedinicaReadDto) => {
    return axios.put(`${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaChange.id}`, organizacionaJedinicaChange, axiosConfig(authData!.token));
  };

  const axiosDeleteOrganizacionaJedinica = (organizacionaJedinicaId: number) => {
    return axios.delete(`${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaId}`, axiosConfig(authData!.token));
  };

  const axiosCreateOrganizacionaJedinicaRadnik = (organizacionaJedinicaId: number, organizacionaJedinicaRadnikCreate: OrganizacionaJedinicaRadnikCreateDto[]) => {
    return axios.post(
      `${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaId}/radnik-list`,
      organizacionaJedinicaRadnikCreate ? organizacionaJedinicaRadnikCreate : {},
      axiosConfig(authData!.token)
    );
  };

  const axiosDeleteOrganizacionaJedinicaRadnik = (organizacionaJedinicaId: number, radnikIds: Array<number>) => {
    return axios.delete(`${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaId}/radnik-list`, axiosConfig(authData!.token, { radnikIdList: radnikIds.join(",") }));
  };

  const axiosGetOrganizacionaJedinicaRadniDanList = (organizacionaJedinicaId: number) => {
    return axios.get(`${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaId}/organizaciona-jedinica-radni-dan-list`, axiosConfig(authData!.token));
  };

  const axiosGetOrganizacionaJedinicaRadnikList = (organizacionaJedinicaId: number) => {
    return axios.get(`${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaId}/radnik-list`, axiosConfig(authData!.token));
  };
  const axiosGetOrganizacionaJedinicaRadnikListByRola = (organizacionaJedinicaId: number, rolaSifra: string, viewNeaktivni?: boolean) => {
    return axios.get(`${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaId}/radnik-list/rola/${rolaSifra}`, axiosConfig(authData!.token, { viewNeaktivni }));
  };

  const axiosGetOrganizacionaJedinicaKontaktTipList = (organizacionaJedinicaId: number) => {
    return axios.get(`${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaId}/kontakt-tip-list`, axiosConfig(authData!.token));
  };

  const axiosCreateOrganizacionaJedinicaKontaktTip = (organizacionaJedinicaId: number, organizacionaJedinicaKontaktTipCreate: OrganizacionaJedinicaKontaktTipCreateDto) => {
    return axios.post(
      `${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaId}/kontakt-tip-list`,
      organizacionaJedinicaKontaktTipCreate ? organizacionaJedinicaKontaktTipCreate : {},
      axiosConfig(authData!.token)
    );
  };

  const axiosDeleteOrganizacionaJedinicaKontaktTip = (organizacionaJedinicaId: number, kontaktTipId: number) => {
    return axios.delete(`${Endpoint.ORGANIZACIONA_JEDINICA_LIST}/${organizacionaJedinicaId}/kontakt-tip-list/${kontaktTipId}`, axiosConfig(authData!.token));
  };

  const axiosPrintProtokol = (organizacionaJedinicaID: number, datum: string) => {
    return axios.get(`${Endpoint.STAMPA_PROTOKOLA}`, axiosConfig(authData!.token, { organizacionaJedinicaID: organizacionaJedinicaID, datum: datum }));
  };

  const axiosGetSpecijalizacijeList = (organizacionaJedinicaId: number) => {
    return axios.get(`${Endpoint.ORGANIZACIONA_JEDINICA_ROOT}/${organizacionaJedinicaId}/specijalizacija-list`, axiosConfig(authData!.token));
  };

  const axiosCreateSpecijalizacijeForOrganizacionaJedinica = (organizacionaJedinicaId: number, specijalizacijaList: Array<OrganizacionaJedinicaSpecijalizacijaCreateDto>) => {
    return axios.post(`${Endpoint.ORGANIZACIONA_JEDINICA_ROOT}/${organizacionaJedinicaId}/specijalizacija-list`, { list: specijalizacijaList }, axiosConfig(authData!.token));
  };

  const axiosDeleteSpecijalizacijeForOrganizacionaJedinica = (organizacionaJedinicaId: number, specijalizacijaList: Array<number>) => {
    return axios.delete(
      `${Endpoint.ORGANIZACIONA_JEDINICA_ROOT}/${organizacionaJedinicaId}/specijalizacija-list`,
      axiosConfig(authData!.token, { idSpecijalizacijaList: specijalizacijaList.join(",") })
    );
  };

  return {
    axiosSearchOrganizacionaJedinica,
    axiosCreateOrganizacionaJedinica,
    axiosUpdateOrganizacionaJedinica,
    axiosDeleteOrganizacionaJedinica,
    axiosReadOrganizacionaJedinica,
    axiosCreateOrganizacionaJedinicaRadnik,
    axiosDeleteOrganizacionaJedinicaRadnik,
    axiosGetOrganizacionaJedinicaRadnikList,
    axiosGetOrganizacionaJedinicaRadniDanList,
    axiosGetOrganizacionaJedinicaRadnikListByRola,
    axiosGetOrganizacionaJedinicaKontaktTipList,
    axiosCreateOrganizacionaJedinicaKontaktTip,
    axiosDeleteOrganizacionaJedinicaKontaktTip,
    axiosPrintProtokol,
    axiosGetSpecijalizacijeList,
    axiosCreateSpecijalizacijeForOrganizacionaJedinica,
    axiosDeleteSpecijalizacijeForOrganizacionaJedinica,
  };
}
