import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { UstanovaController } from "../../../controllers/ustanova/UstanovaController";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import UstanovaPodesavanjaReadDto from "../../../model/ustanova/UstanovaPodesavanjaReadDto";

interface UstanovaPodesavanjaLogicalType {
  handleUpdate: () => void;
  handleCheckPacijentPodsetnik: () => void;
  ustanovaPodesavanja: UstanovaPodesavanjaReadDto;
  setUstanovaPodesavanja: React.Dispatch<React.SetStateAction<UstanovaPodesavanjaReadDto>>;
  handleCheckAutomatskoRazduzivanje: (newValue: boolean) => void;
  isAutomatskoRazduzivanjeDialogOpen: boolean;
  closeAutomatskoRazduzivanjeDialog: () => void;
}

export default function UstanovaPodesavanjaLogical(): UstanovaPodesavanjaLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const postLogHighLevel = useLogHighLevel();
  const [isAutomatskoRazduzivanjeDialogOpen, setIsAutomatskoRazduzivanjeDialogOpen] = useState<boolean>(false);
  const [ustanovaPodesavanja, setUstanovaPodesavanja] = useState<UstanovaPodesavanjaReadDto>({ podsetnikPacijentVremeSlanja: null, automatskoRazduzivanjePlaniranihUsluga: false });

  const { axiosGetUstanovaPodesavanja, axiosUpdateUstanovaPodesavanja } = UstanovaController();

  useEffectOnce(() => {
    fetchData();
  });

  function fetchData() {
    axiosGetUstanovaPodesavanja()
      .then((res: any) => {
        setUstanovaPodesavanja(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  }

  function handleUpdate() {
    axiosUpdateUstanovaPodesavanja(ustanovaPodesavanja)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.USTANOVA_PODESAVANJA_UPDATE_SUCCESS);
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_PODESAVANJA);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  }

  function handleCheckPacijentPodsetnik() {
    if (ustanovaPodesavanja.podsetnikPacijentVremeSlanja !== null) {
      setUstanovaPodesavanja({
        ...ustanovaPodesavanja!,
        podsetnikPacijentVremeSlanja: null,
      });
    } else {
      setUstanovaPodesavanja({
        ...ustanovaPodesavanja!,
        podsetnikPacijentVremeSlanja: 18,
      });
    }
  }

  const handleCheckAutomatskoRazduzivanje = (newValue: boolean) => {
    if (newValue) {
      openAutomatskoRazduzivanjeDialog();
    } else {
      setUstanovaPodesavanja({
        ...ustanovaPodesavanja!,
        automatskoRazduzivanjePlaniranihUsluga: newValue,
      });
    }
  };

  const openAutomatskoRazduzivanjeDialog = () => {
    setIsAutomatskoRazduzivanjeDialogOpen(true);
  };

  const closeAutomatskoRazduzivanjeDialog = () => {
    setIsAutomatskoRazduzivanjeDialogOpen(false);
  };

  return {
    handleUpdate,
    handleCheckPacijentPodsetnik,
    ustanovaPodesavanja,
    setUstanovaPodesavanja,
    handleCheckAutomatskoRazduzivanje,
    isAutomatskoRazduzivanjeDialogOpen,
    closeAutomatskoRazduzivanjeDialog,
  };
}
