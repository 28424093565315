import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { ZakazaniTerminController } from "../../../../controllers/zakazani-termin/ZakazaniTerminController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";
import { handleAxiosCallError, handleAxiosCallSuccess } from "../../../../infrastructure/system/Utils";
import ZakazanTerminDatotekaCreateDto from "../../../../model/zakazan-termin/datoteke/ZakazanTerminDatotekaCreateDto";

export function useCreateDatotekaForZakazanTermin(zakazanTerminID?: number) {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosPostDatotekaForZakazaniTermin } = ZakazaniTerminController();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (datoteka: ZakazanTerminDatotekaCreateDto) => {
      return axiosPostDatotekaForZakazaniTermin(datoteka);
    },
    onSuccess: () => {
      handleAxiosCallSuccess(showMessage, Labels.ZAKAZIVANJE_PREGLEDA_ADD_FILE_SUCCESS_MESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ZAKAZAN_TERMIN_DATOTEKA_LIST, { zakazanTerminID }] });
    },
    onError: (err) => {
      handleAxiosCallError(showMessage, err);
    },
  });
}
