import { useLabels } from "../../../../Store";
import { Image } from "primereact/image";
import { Tooltip } from "primereact/tooltip";
import Images from "../../../../infrastructure/system/Images";
import { Checkbox } from "primereact/checkbox";
import TipPregledaCard from "./TipPregledaCard";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { useRepositoryTipPregledaList } from "../../../../infrastructure/system/hooks/tip-pregleda/useRepositoryTipPregledaList";
import { useOrganizacionaJedinicaList } from "../../../../infrastructure/system/hooks/organizaciona-jedinica/useOrganizacionaJedinicaList";
import { useInicijalizacijaTipPregledaList } from "../../../../infrastructure/system/hooks/tip-pregleda/useInicijalizacijaTipPregledaList";
import { InitialStateType } from "../../hooks/initialState";
import { ReducerType } from "../../hooks/reducer";
import { AxiosResponse } from "axios";
import { UseMutationResult } from "@tanstack/react-query";
import useEffectWithoutWarnings from "../../../../infrastructure/system/hooks/useEffectWithoutWarnings";

interface InicijalizacijaTipoviPregledaViewType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerType>;
  onSaveTipoviPregleda: UseMutationResult<AxiosResponse, unknown, void, unknown>;
}

const InicijalizacijaTipoviPregledaView = (props: InicijalizacijaTipoviPregledaViewType) => {
  const { state, dispatch, onSaveTipoviPregleda } = props;
  const Labels = useLabels();
  const { repositoryTipPregledaList } = useRepositoryTipPregledaList();
  const { organizacionaJedinicaList } = useOrganizacionaJedinicaList();
  const { inicijalizacijaTipPregledaList, isLoading } = useInicijalizacijaTipPregledaList();

  const getOrganizacionaJedinicaList = (kontaktTipId: number) => {
    const inicijalizacijaKontaktTipOrgJedList = inicijalizacijaTipPregledaList.find((inicijalizacijaTip) => inicijalizacijaTip.idRepositoryKontaktTip === kontaktTipId)?.organizacionaJedinicaList;
    return inicijalizacijaKontaktTipOrgJedList?.map((tip) => ({ organizacionaJedinica: tip })) ?? [];
  };

  useEffectWithoutWarnings(() => {
    if (inicijalizacijaTipPregledaList && !isLoading && repositoryTipPregledaList.length > 0) {
      dispatch({ type: "set_tipovi_pregleda_count", data: inicijalizacijaTipPregledaList.length });
      const inicijalizacijaTipIdList = inicijalizacijaTipPregledaList.map((tip) => tip.idRepositoryKontaktTip);
      repositoryTipPregledaList
        .filter((tip) => inicijalizacijaTipIdList.includes(tip.id))
        .map((tip) =>
          dispatch({
            type: "change_tipovi_pregleda",
            data: {
              idRepositoryKontaktTip: tip.id,
              organizacionaJedinicaList: getOrganizacionaJedinicaList(tip.id),
              tipPregleda: tip,
            },
          })
        );
    }
  }, [inicijalizacijaTipPregledaList, repositoryTipPregledaList]);

  return (
    <div className="inicijalizacija-tipovi-pregleda-layout p-6">
      <div className="font-bold text-2xl mb-6 flex align-items-center">
        {Labels.TIP_KONTAKTA_LIST}
        <Image src={Images.ICON_BULB} className="ml-4 mt-1 bulb" data-pr-tooltip={Labels.LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_TOOLTIP} />
        <Tooltip target=".bulb" />
      </div>
      <div className="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-10 pl-0">
        <div className="p-3">
          <Checkbox
            inputId="selectAll"
            className="ml-1"
            onChange={(e) => {
              e.checked
                ? dispatch({ type: "select_all_tipovi_pregleda", data: { tipPregledaList: repositoryTipPregledaList, organizacionaJedinicaList: organizacionaJedinicaList } })
                : dispatch({ type: "uncheck_all_tipovi_pregleda" });
            }}
            checked={state?.selectAllTipoviPregleda}
          />
          <label htmlFor="selectAll" className="ml-4 cursor-pointer">
            <span className="font-bold">{Labels.LABEL_OZNACI_SVE}</span>
          </label>
        </div>
        <ScrollPanel className="scrollpanel">
          <div className="w-12 xl:w-11 pl-1">
            {repositoryTipPregledaList.map((tipPregleda) => (
              <TipPregledaCard
                key={tipPregleda.id}
                tipPregleda={tipPregleda}
                organizacionaJedinicaList={organizacionaJedinicaList}
                state={state}
                dispatch={dispatch}
                inicijalizacijaTipPregledaList={inicijalizacijaTipPregledaList}
              />
            ))}
          </div>
        </ScrollPanel>

        <div className="flex justify-content-center w-11 pt-5">
          <div className="w-max">
            <Button label={Labels.LABEL_SNIMI} icon="pi pi-save" onClick={() => onSaveTipoviPregleda.mutate()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InicijalizacijaTipoviPregledaView;
