import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useLabels } from "../../../../../Store";
import actionsAddObrazac from "../../../../../infrastructure/system/hooks/dialog-add-obrazac-delovodne-knjige/actionsAddObrazac";
import { InitialStateAddObrazacType } from "../../../../../infrastructure/system/hooks/dialog-add-obrazac-delovodne-knjige/initialStateAddObrazac";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../../app/skeleton/SkeletonTable";
import DialogComponent from "../../../../dialog-component/DialogComponent";

interface DialogRepositoryObrasciPropsType {
  state: InitialStateAddObrazacType;
  dispatch: React.Dispatch<{ type: actionsAddObrazac }> | any;
  getSelectObrazacFromRepo: () => void;
}

export default function DialogRepositoryObrasci(props: DialogRepositoryObrasciPropsType) {
  const { state, dispatch, getSelectObrazacFromRepo } = props;
  const Labels = useLabels();

  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-content-end">
        <Button
          label={Labels.BUTTON_CANCEL}
          tooltip={Labels.BUTTON_CANCEL}
          icon="pi pi-times"
          className="button-danger mr-3 p-button-outlined"
          onClick={() => dispatch({ type: actionsAddObrazac.CLOSE_DIALOG })}
        />
        <Button label={Labels.PROTOKOL_ADD} tooltip={Labels.PROTOKOL_ADD} icon="pi pi-plus" onClick={() => getSelectObrazacFromRepo()} />
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.LABEL_NAZIV, filter: false, sortrable: false },
    { columnName: Labels.LABEL_OBRASCI_STAMPE_PROTOKOLA_OPIS, filter: true, sortrable: false },
  ];

  return (
    <DialogComponent
      header={Labels.LABEL_ADD_OBRASCI_STAMPE_PROTOKOLA}
      visible={state.isDialogOpen}
      onHide={() => dispatch({ type: actionsAddObrazac.CLOSE_DIALOG })}
      footer={renderFooter}
      className="w-10 sm:w-8 md:w-8 lg:w-5 xl:w-5"
      content={
        <>
          {state?.obrasciLoading ? (
            <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
          ) : (
            <DataTable
              value={state.obrasci ?? []}
              emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
              rowHover
              stripedRows
              selectionMode="radiobutton"
              selection={state.selectedObrazac}
              onSelectionChange={(e) => {
                dispatch({ type: actionsAddObrazac.SET_SELECTED_OBRAZAC_FROM_REPO, value: e.value });
              }}
            >
              <Column selectionMode="single" headerStyle={{ width: "3em" }} />
              <Column field={"naziv"} className="pl-0" header={Labels.LABEL_NAZIV} filter={false} showFilterMenu={false} sortable={false} />
              <Column field={"opis"} className="pl-0" header={Labels.LABEL_OBRASCI_STAMPE_PROTOKOLA_OPIS} filter={false} showFilterMenu={false} sortable={false} />
            </DataTable>
          )}
        </>
      }
    />
  );
}
