import { InputTextarea } from "primereact/inputtextarea";
import { PoljeType } from "../Polje";

export default function TekstDugacak(props: PoljeType) {
  const { id, onChange, vrednost, readonly, onClick, useVrednostList, vrednostList } = props;

  return (
    <InputTextarea
      disabled={readonly ?? false}
      rows={5}
      autoResize
      className="w-full"
      onChange={(e) => {
        onChange({ id: id, vrednost: e.target.value });
      }}
      value={useVrednostList && vrednostList ? vrednostList[id] ?? "" : vrednost ?? ""}
      onClick={() => onClick && onClick(id)}
    />
  );
}
