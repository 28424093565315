import axios from "axios";
import moment from "moment";
import { InputSwitchChangeEvent } from "primereact/inputswitch";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import SockJS from "sockjs-client";
import { Client, Message, Subscription, over } from "stompjs";
import { AppContext, useLabels } from "../../../Store";
import GenerisaniIzvestajController from "../../../controllers/generisani-izvestaj/GenerisaniIzvestajController";
import Endpoint from "../../../infrastructure/system/Endpoint";
import EnumFajlFormat from "../../../infrastructure/system/EnumFajlFormat";
import EnumWebSocketPorukaTip from "../../../infrastructure/system/EnumWebSocketPorukaTip";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import InitialStateBrisanjeIzvestaja from "../../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/initialStateBrisanjeIzvestaja";
import reducerBrisanjeIzvestaja from "../../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/reducerBrisanjeIzvestaja";
import actions from "../../../infrastructure/system/hooks/generisani-izvestaj-reducer/actionsGenerisaniIzvestaj";
import initialState from "../../../infrastructure/system/hooks/generisani-izvestaj-reducer/initialStateGenerisaniIzvestaj";
import reducerGenerisaniIzvestaj from "../../../infrastructure/system/hooks/generisani-izvestaj-reducer/reducerGenerisaniIzvestaj";
import useCallbackWithoutWarnings from "../../../infrastructure/system/hooks/useCallbackWithoutWarnings";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import InitialStateZakazaniIzvestaj from "../../../infrastructure/system/hooks/zakazani-izvestaj-reducer/initialStateZakazaniIzvestaj";
import reducerZakazaniIzvestaji from "../../../infrastructure/system/hooks/zakazani-izvestaj-reducer/reducerZakazaniIzvestaji";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import WebSocketPayload from "../../../model/WebSocketPayload";
import GenerisaniIzvestajCreateDto from "../../../model/generisaniIzvestaj/GenerisaniIzvestajCreateDto";
import GenerisaniIzvestajSmallReadDto from "../../../model/generisaniIzvestaj/GenerisaniIzvestajSmallReadDto";
import IzvestajSimpleReadDto from "../../../model/izvestaj/IzvestajSimpleReadDto";

export default function GenerisaniIzvestajListLogical() {
  const { axiosGetGenerisaniIzvestajList, axiosGetGenerisaniIzvestajListCount, axiosGetGenerisaniIzvestajExport, axiosCreateGenerisaniIzvestaj } = GenerisaniIzvestajController();

  const { showMessage, authData } = useContext(AppContext);
  const Labels = useLabels();
  const [state, dispatch] = useReducer(reducerGenerisaniIzvestaj, initialState);
  const [stateDialog, dispatchDialog] = useReducer(reducerBrisanjeIzvestaja, InitialStateBrisanjeIzvestaja);
  const [stateZakazani, dispatchZakazani] = useReducer(reducerZakazaniIzvestaji, InitialStateZakazaniIzvestaj);

  const stateRef = useRef<any>();
  let stompClient = useRef<Client>();
  let subscription = useRef<Subscription>();
  const [index, setIndex] = useState<number>(0);
  const postLogHighLevel = useLogHighLevel();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.IZVESTAJ_LIST,
    },
  ];

  useEffectOnce(() => {
    if (index === 0) connect();
    return () => {
      if (stompClient.current?.connected) {
        stompClient.current?.disconnect(() => {});
      }
    };
  });

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const connect = () => {
    let sock = new SockJS(Endpoint.GENERISANJE_IZVESTAJA_WEBSOCKET);
    stompClient.current = over(sock);
    stompClient.current.connect({}, onConnected, onError);
  };

  const onMessageReceived = useCallbackWithoutWarnings(
    (message: Message) => {
      let payload: WebSocketPayload = JSON.parse(message.body);
      if (payload.payload) {
        fetchData(
          true,
          stateRef!.current.pagination.page,
          stateRef!.current.pagination.rows,
          stateRef!.current.onlyMine ? authData?.currentRadnik.id : undefined,
          stateRef!.current.selectedStatistickiIzvestaj
        );
        showMessage(MessageType.SUCCESS, payload.poruka);
        subscription.current!.unsubscribe();
        stompClient.current!.disconnect(() => {});
        return;
      }
      if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.ERROR_MSG) {
        showMessage(MessageType.ERROR, payload.poruka);
      } else if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.INFO_MSG) {
        showMessage(MessageType.INFO, payload.poruka);
      } else if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.SUCCESS_MSG) {
        showMessage(MessageType.SUCCESS, payload.poruka);
        fetchData(
          true,
          stateRef!.current.pagination.page,
          stateRef!.current.pagination.rows,
          stateRef!.current.onlyMine ? authData?.currentRadnik.id : undefined,
          stateRef!.current.selectedStatistickiIzvestaj
        );
      }
    },
    [state]
  );

  const onConnected = () => {
    subscription.current = stompClient.current!.subscribe(`${Endpoint.GENERISANJE_IZVESTAJA_LISTENER}/${authData?.currentRadnik.id}`, onMessageReceived);
  };

  const onError = () => {
    showMessage(MessageType.ERROR, Labels.GENERISANJE_IZVESTAJA_CONNECTION_ERROR);
  };

  const onChange = (e: InputSwitchChangeEvent) => {
    fetchData(true, 0, state.pagination.rows, e.value ? authData?.currentRadnik.id : undefined, state.selectedStatistickiIzvestaj);
  };

  const fetchData = (fetchTotalCount: boolean, page: number, size: number, radnikID?: number, statistickiIzvestaj?: IzvestajSimpleReadDto) => {
    const startTime = moment(new Date());
    const searchParams = { page: page, size: size, radnikID: radnikID, statistickiIzvestajID: statistickiIzvestaj?.id };
    dispatch({ type: actions.SET_IZVESTAJ_LIST_LOADING, value: true });
    const getGenerisaniIzvestajRequest = axiosGetGenerisaniIzvestajList(searchParams);
    const getGenerisaniIzvestajCountRequest = fetchTotalCount ? axiosGetGenerisaniIzvestajListCount({ radnikID: radnikID, statistickiIzvestajID: statistickiIzvestaj?.id }) : undefined;
    axios
      .all([getGenerisaniIzvestajRequest, getGenerisaniIzvestajCountRequest])
      .then(
        axios.spread((getGenerisaniIzvestajResponse: any, getGenerisaniIzvestajCountResponse: any) => {
          const izvestajList = getGenerisaniIzvestajResponse.data.data;
          const value = {
            izvestajList: izvestajList,
            selectedStatistickiIzvestaj: statistickiIzvestaj,
            pagination: { page: page, rows: size },
            onlyMine: radnikID ? true : false,
          };
          dispatch({ type: actions.FETCH_DATA, value: value });
          let count = getGenerisaniIzvestajCountResponse?.data.data;
          if (count !== undefined && count !== null) {
            dispatch({ type: actions.SET_TOTAL_COUNT, value: count });
          }
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        if (moment(new Date()).diff(startTime) < 300) {
          setTimeout(() => {
            dispatch({ type: actions.SET_IZVESTAJ_LIST_LOADING, value: false });
          }, 300);
        } else {
          dispatch({ type: actions.SET_IZVESTAJ_LIST_LOADING, value: false });
        }
      });
  };

  const onExport = (format: string, generisaniIzvestaj: GenerisaniIzvestajSmallReadDto) => {
    axiosGetGenerisaniIzvestajExport(format.toLowerCase(), generisaniIzvestaj.id)
      .then((res) => {
        const link = document.createElement("a");
        let doc = new Blob();
        const linkDownload = generisaniIzvestaj?.naslov ?? generisaniIzvestaj?.statistickiIzvestaj.naziv;
        switch (format.toLowerCase()) {
          case EnumFajlFormat.PDF:
            doc = new Blob([res.data], { type: "application/pdf" });
            link.download = `${linkDownload}.pdf`;
            break;
          case EnumFajlFormat.TXT:
            doc = new Blob([res.data], { type: "text/plain" });
            link.download = `${linkDownload}.txt`;
            break;
          case EnumFajlFormat.CSV:
            doc = new Blob([res.data], { type: "text/plain" });
            link.download = `${linkDownload}.csv`;
            break;
          case EnumFajlFormat.XLS:
            doc = new Blob([res.data], { type: "application/xls" });
            link.download = `${linkDownload}.xls`;
            break;
          case EnumFajlFormat.XLSX:
            doc = new Blob([res.data], { type: "application/xlsx" });
            link.download = `${linkDownload}.xlsx`;
            break;
        }
        const url = URL.createObjectURL(doc);
        link.href = url;
        link.click();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {});
  };

  const posaljiIzvestajNaGenerisanje = () => {
    const izvestajToBeGenerated: GenerisaniIzvestajCreateDto = {
      statistickiIzvestaj: {
        id: state.selectedStatistickiIzvestaj.id,
      },
      params: state.paramsValue,
    };
    if (izvestajToBeGenerated.params?.length === 0) izvestajToBeGenerated.params = null;
    axiosCreateGenerisaniIzvestaj(izvestajToBeGenerated)
      .then(() => {
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_GENERISAN_IZVESTAJ1 + state.selectedStatistickiIzvestaj.naziv + Labels.LOG_HIGH_LEVEL_MESS_CREATE_GENERISAN_IZVESTAJ2);
      })
      .catch((error: any) => handleAxiosCallError(showMessage, error));
  };

  const generisiIzvestaj = () => {
    if (!stompClient.current?.connected) {
      connect();
      setTimeout(() => {
        posaljiIzvestajNaGenerisanje();
      }, 500);
    } else {
      posaljiIzvestajNaGenerisanje();
    }
  };

  return {
    breadCrumbItems,
    state,
    dispatch,
    onChange,
    fetchData,
    onExport,
    generisiIzvestaj,
    stateDialog,
    dispatchDialog,
    index,
    setIndex,
    stateZakazani,
    dispatchZakazani,
  };
}
