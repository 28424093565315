import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { ChangeEvent, useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumTipKategorijePopusta from "../../../infrastructure/system/EnumTipKategorijePopusta";
import { setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import { useNamenaKategorijePopustaList } from "../../../infrastructure/system/hooks/sifarnici/namena-kategorije-popusta/useNamenaKategorijePopustaList";
import { useTipKategorijePopustaList } from "../../../infrastructure/system/hooks/sifarnici/tip-kategorije-popusta/useTipKategorijePopustaList";
import { useValutaList } from "../../../infrastructure/system/hooks/sifarnici/valuta-list/useValutaList";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CrudSifarnikKategorijaPopustaLogical from "./CrudSifarnikKategorijaPopustaLogical";

export default function CrudSifarnikKategorijaPopustaView() {
  const {
    breadCrumbItems,
    sifarnikKategorijaPopustaChange,
    setSifarnikKategorijaPopustaChange,
    isDisabled,
    sifarnikKategorijaPopustaOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    invalidFields,
    setInvalidFields,
    isLoading,
    validateInput,
  } = CrudSifarnikKategorijaPopustaLogical();
  const Labels = useLabels();
  const { pristup, authData } = useContext(AppContext);
  const { tipKategorijePopustaList, isLoading: tipKategorijePopustaListLoading } = useTipKategorijePopustaList();
  const { namenaKategorijePopustaList, isLoading: namenaKategorijePopustaListLoading } = useNamenaKategorijePopustaList();
  const { valutaList } = useValutaList();

  const leftContentsToolbar = () => (
    <>
      {sifarnikKategorijaPopustaOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.SIFARNIK_KATEGORIJA_POPUSTA_TITLE_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => validateInput(sifarnikKategorijaPopustaChange) && onCreate.mutate()}
          disabled={!pristup}
        />
      )}
      {sifarnikKategorijaPopustaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || isLoading}
          icon="pi pi-pencil"
          tooltip={Labels.SIFARNIK_KATEGORIJA_POPUSTA_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={() => validateInput(sifarnikKategorijaPopustaChange) && onUpdate.mutate()}
        />
      )}
      {sifarnikKategorijaPopustaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || isLoading}
          icon="pi pi-trash"
          tooltip={Labels.SIFARNIK_KATEGORIJA_POPUSTA_TITLE_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={() => onDelete.mutate()}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
          <TabPanel header={Labels.LABEL_SIFARNIK_KATEGORIJA_POPUSTA}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_KATEGORIJA_POPUSTA_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!isLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={sifarnikKategorijaPopustaChange?.naziv ?? ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setSifarnikKategorijaPopustaChange({
                            ...sifarnikKategorijaPopustaChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", sifarnikKategorijaPopustaChange?.naziv);
                        }}
                        className={invalidFields?.naziv ? "p-invalid" : ""}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_KATEGORIJA_POPUSTA_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!isLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={sifarnikKategorijaPopustaChange?.sifra ?? ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setSifarnikKategorijaPopustaChange({
                            ...sifarnikKategorijaPopustaChange!,
                            sifra: e.target.value,
                          })
                        }
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", sifarnikKategorijaPopustaChange?.sifra);
                        }}
                        className={invalidFields?.sifra ? "p-invalid" : ""}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_TIP_KATEGORIJE}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!isLoading || !tipKategorijePopustaListLoading ? (
                    <>
                      <Dropdown
                        disabled={!pristup || isDisabled}
                        options={tipKategorijePopustaList}
                        optionLabel="naziv"
                        value={sifarnikKategorijaPopustaChange?.tipKategorijePopusta ?? ""}
                        onChange={(e) => {
                          setSifarnikKategorijaPopustaChange({
                            ...sifarnikKategorijaPopustaChange!,
                            tipKategorijePopusta: e.target.value,
                            valuta: e.target.value.sifra === EnumTipKategorijePopusta.FIKSNO ? authData?.currentRadnik?.ustanovaPoslednjaIzabrana.valuta : undefined,
                          });
                          setInvalidFields((prev) => ({ ...prev, tipKategorije: false }));
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={invalidFields?.tipKategorije ? "p-invalid" : ""}
                      />
                      {invalidFields?.tipKategorije && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_NAMENA_KATEGORIJE}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!isLoading || !namenaKategorijePopustaListLoading ? (
                    <>
                      <Dropdown
                        disabled={!pristup || isDisabled}
                        options={namenaKategorijePopustaList}
                        optionLabel="naziv"
                        value={sifarnikKategorijaPopustaChange?.namenaKategorijePopusta ?? ""}
                        onChange={(e) => {
                          setSifarnikKategorijaPopustaChange({
                            ...sifarnikKategorijaPopustaChange!,
                            namenaKategorijePopusta: e.target.value,
                          });
                          setInvalidFields((prev) => ({ ...prev, namenaKategorije: false }));
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={invalidFields?.namenaKategorije ? "p-invalid" : ""}
                      />
                      {invalidFields?.namenaKategorije && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_POPUST}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!isLoading ? (
                    <>
                      <InputNumber
                        value={sifarnikKategorijaPopustaChange?.popust ?? undefined}
                        minFractionDigits={0}
                        maxFractionDigits={5}
                        disabled={!pristup || isDisabled}
                        onChange={(e) => {
                          setSifarnikKategorijaPopustaChange({
                            ...sifarnikKategorijaPopustaChange!,
                            popust: e.value!,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "popust", sifarnikKategorijaPopustaChange?.popust);
                        }}
                        className={invalidFields?.popust ? "p-invalid" : ""}
                      />
                      {invalidFields?.popust && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                {sifarnikKategorijaPopustaChange?.tipKategorijePopusta?.sifra === EnumTipKategorijePopusta.FIKSNO && (
                  <>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>
                        {Labels.LABEL_SIFARNIK_KATEGORIJA_POPUSTA_VALUTA}
                        {Labels.SPECIAL_CHAR_REQUIRED}
                      </div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      {!isLoading ? (
                        <Dropdown
                          disabled={!pristup || isDisabled}
                          optionLabel="sifra"
                          optionValue="id"
                          options={valutaList}
                          value={sifarnikKategorijaPopustaChange?.valuta?.id ?? undefined}
                          onChange={(e) => {
                            setSifarnikKategorijaPopustaChange({
                              ...sifarnikKategorijaPopustaChange!,
                              valuta: { id: e.value! },
                            });
                          }}
                          emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        />
                      ) : (
                        <SkeletonInputItem />
                      )}
                    </div>
                  </>
                )}
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_KATEGORIJA_POPUSTA_PRIKAZNI_REDOSLED}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!isLoading ? (
                    <>
                      <InputNumber
                        value={sifarnikKategorijaPopustaChange?.prikazniRedosled ?? undefined}
                        disabled={!pristup || isDisabled}
                        onChange={(e) => {
                          setSifarnikKategorijaPopustaChange({
                            ...sifarnikKategorijaPopustaChange!,
                            prikazniRedosled: e.value!,
                          });
                        }}
                      />
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
