import { useContext, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import PlacanjeController from "../../../../controllers/placanje/PlacanjeController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { formatPeriod, handleAxiosCallError } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import PlacanjeTrajanjeUgovoraReadDto from "../../../../model/placanje/PlacanjeTrajanjeUgovoraReadDto";

interface PlacanjeUgovorDialogLogicalType {
  placanjeTrajanjeUgovoraChange: PlacanjeTrajanjeUgovoraReadDto | undefined;
  setPlacanjeTrajanjeUgovoraChange: React.Dispatch<React.SetStateAction<PlacanjeTrajanjeUgovoraReadDto | undefined>>;
  savePlacanjaUgovor: () => void;
  datumOdRef: HTMLDivElement | any;
  datumDoRef: HTMLDivElement | any;
}

interface PlacanjeUgovorDialogProps {
  closePreview: () => void;
  ustanovaId: number;
}

export default function PlacanjeUgovorDialogLogical(props: PlacanjeUgovorDialogProps): PlacanjeUgovorDialogLogicalType {
  const [placanjeTrajanjeUgovoraChange, setPlacanjeTrajanjeUgovoraChange] = useState<PlacanjeTrajanjeUgovoraReadDto>();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const datumOdRef = useRef<HTMLDivElement | any>(null);
  const datumDoRef = useRef<HTMLDivElement | any>(null);

  const { closePreview, ustanovaId } = props;
  const { axiosCreatePlacanjeUgovor } = PlacanjeController();
  const postLogHighLevel = useLogHighLevel();

  const validateInput = (placanjeTrajanjeUgovora: PlacanjeTrajanjeUgovoraReadDto | undefined) => {
    if (placanjeTrajanjeUgovora === undefined) {
      showMessage(MessageType.ERROR, Labels.PLACANJE_IS_REQUIRED);
      return false;
    }
    if (placanjeTrajanjeUgovora.ugovorVaziOd === undefined) {
      showMessage(MessageType.ERROR, Labels.PLACANJE_UGOVOR_VAZI_OD_IS_REQUIRED);
      return false;
    }
    if (placanjeTrajanjeUgovora.ugovorVaziDo === undefined) {
      showMessage(MessageType.ERROR, Labels.PLACANJE_UGOVOR_VAZI_DO_IS_REQUIRED);
      return false;
    }

    const date1 = new Date(placanjeTrajanjeUgovora.ugovorVaziOd);
    const date2 = new Date(placanjeTrajanjeUgovora.ugovorVaziDo);
    if (date2 < date1) {
      showMessage(MessageType.ERROR, Labels.PLACANJE_UGOVOR_VAZI_OD_VAZI_DO_COMPARE_VALIDATION);
      return false;
    }

    return true;
  };

  const savePlacanjaUgovor = () => {
    if (!validateInput(placanjeTrajanjeUgovoraChange)) {
      return;
    }

    if (placanjeTrajanjeUgovoraChange) {
      setShowBlockUI(true);
      axiosCreatePlacanjeUgovor(placanjeTrajanjeUgovoraChange)
        .then(({ data: { data } }: { data: { data: PlacanjeTrajanjeUgovoraReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.PLACANJE_MESSAGE_CREATE_SUCCESS);
          closePreview();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_UGOVOR_1 +
              formatPeriod(new Date(placanjeTrajanjeUgovoraChange.ugovorVaziOd)) +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_UGOVOR_2 +
              formatPeriod(new Date(placanjeTrajanjeUgovoraChange.ugovorVaziDo)),
            undefined,
            ustanovaId
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  return {
    savePlacanjaUgovor,
    placanjeTrajanjeUgovoraChange,
    setPlacanjeTrajanjeUgovoraChange,
    datumOdRef,
    datumDoRef,
  };
}
