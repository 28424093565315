import { SetStateAction, useCallback, useState } from "react";
import { useLabels } from "../../../../../Store";
import EnumTipTerapijePrikaz from "../../../../../model/kontakt/EnumTipTerapijePrikaz";
import ReceptCreateDto from "../../../../../model/kontakt/ReceptCreateDto";

export interface stepNameType {
  firstStepName: string;
  secondStepName: string;
  thirdStepName: string;
}

export interface isChecked {
  isJednakoChecked: boolean;
  isKombinovanoChecked: boolean;
  isJednokratnoChecked: boolean;
  isNaSvakihChecked: boolean;
  isPoPotrebiChecked: boolean;
  isProizvoljnoChecked: boolean;
}

interface KontaktTerapijeDialogLogicalType {
  page: any;
  setPage: React.Dispatch<SetStateAction<number>>;
  stepName: any;
  setStepName: React.Dispatch<SetStateAction<stepNameType | undefined>>;
  isChecked: any;
  setIsChecked: React.Dispatch<SetStateAction<isChecked | undefined>>;
  isNextButtonDisabled: (page: number) => boolean | undefined;
  scrollTop: () => void;
  getSifraStringFromTerapija: (sifra: string) => string;
  getNacinDoziranjaString: (isChecked: isChecked, akronim: string | undefined) => string | undefined;
}

export default function KontaktTerapijeDialogLogical(terapijaChange: ReceptCreateDto | undefined): KontaktTerapijeDialogLogicalType {
  const [page, setPage] = useState<number>(0);
  const [stepName, setStepName] = useState<stepNameType>();
  const [isChecked, setIsChecked] = useState<isChecked>();
  const Labels = useLabels();

  const isNextButtonDisabled = useCallback(
    (page: number) => {
      switch (page) {
        case 0:
          return terapijaChange?.dijagnoza?.id !== undefined && terapijaChange?.dijagnoza?.id !== null;
        case 1:
          return (
            terapijaChange?.idRepositoryLek !== undefined && terapijaChange?.idRepositoryLek !== null && terapijaChange?.tipTerapije?.sifra !== undefined && terapijaChange?.tipTerapije?.sifra !== null
          );
        case 2:
          return (
            terapijaChange?.brojPakovanja !== undefined &&
            terapijaChange?.brojPakovanja !== null &&
            terapijaChange?.jedinicaMere !== undefined &&
            terapijaChange?.jedinicaMere !== null &&
            ((terapijaChange?.tipTerapije?.sifra === getSifraStringFromTerapija(EnumTipTerapijePrikaz.AKUTNA) &&
              terapijaChange?.akutnaBrojDana !== undefined &&
              terapijaChange?.akutnaBrojDana !== null) ||
              (terapijaChange?.tipTerapije?.sifra === getSifraStringFromTerapija(EnumTipTerapijePrikaz.HRONICNA) &&
                terapijaChange?.brojIzdavanja !== undefined &&
                terapijaChange?.brojIzdavanja !== null &&
                terapijaChange?.hronicnaBrojMeseci !== undefined &&
                terapijaChange?.hronicnaBrojMeseci !== null) ||
              (terapijaChange?.tipTerapije?.sifra === getSifraStringFromTerapija(EnumTipTerapijePrikaz.NARKOTIK) &&
                terapijaChange?.brojProtokola !== undefined &&
                terapijaChange?.brojProtokola !== null)) &&
            ((isChecked?.isJednakoChecked &&
              terapijaChange?.brojUzimanjaTokomDana !== undefined &&
              terapijaChange?.brojUzimanjaTokomDana !== null &&
              terapijaChange?.dnevnaKolicina !== undefined &&
              terapijaChange?.dnevnaKolicina !== null) ||
              (isChecked?.isKombinovanoChecked &&
                terapijaChange?.jutarnjaKolicina !== undefined &&
                terapijaChange?.jutarnjaKolicina !== null &&
                terapijaChange?.popodnevnaKolicina !== undefined &&
                terapijaChange?.popodnevnaKolicina !== null &&
                terapijaChange?.vecernjaKolicina !== undefined &&
                terapijaChange?.vecernjaKolicina !== null) ||
              (isChecked?.isJednokratnoChecked && terapijaChange?.jednokratnaKolicina !== undefined && terapijaChange?.jednokratnaKolicina !== null) ||
              (isChecked?.isNaSvakihChecked &&
                terapijaChange?.naKolikoDana !== undefined &&
                terapijaChange?.naKolikoDana !== null &&
                terapijaChange?.kolicinaNaViseDana !== undefined &&
                terapijaChange?.kolicinaNaViseDana !== null) ||
              (isChecked?.isPoPotrebiChecked && terapijaChange?.kolicinaPoPotrebi !== undefined && terapijaChange?.kolicinaPoPotrebi !== null) ||
              (isChecked?.isProizvoljnoChecked && terapijaChange?.proizvoljnaKolicina !== undefined && terapijaChange?.proizvoljnaKolicina !== null))
          );
        case 3:
          return true;
        default:
          return false;
      }
    },
    [
      terapijaChange?.dijagnoza?.id,
      terapijaChange?.idRepositoryLek,
      terapijaChange?.tipTerapije?.sifra,
      terapijaChange?.brojPakovanja,
      terapijaChange?.jedinicaMere,
      terapijaChange?.akutnaBrojDana,
      terapijaChange?.brojIzdavanja,
      terapijaChange?.hronicnaBrojMeseci,
      terapijaChange?.brojProtokola,
      terapijaChange?.brojUzimanjaTokomDana,
      terapijaChange?.dnevnaKolicina,
      terapijaChange?.jutarnjaKolicina,
      terapijaChange?.popodnevnaKolicina,
      terapijaChange?.vecernjaKolicina,
      terapijaChange?.jednokratnaKolicina,
      terapijaChange?.naKolikoDana,
      terapijaChange?.kolicinaNaViseDana,
      terapijaChange?.kolicinaPoPotrebi,
      terapijaChange?.proizvoljnaKolicina,
      isChecked?.isJednakoChecked,
      isChecked?.isKombinovanoChecked,
      isChecked?.isJednokratnoChecked,
      isChecked?.isNaSvakihChecked,
      isChecked?.isPoPotrebiChecked,
      isChecked?.isProizvoljnoChecked,
    ]
  );

  /**
   * Potrebno za nize rezolucije,
   * content modala dobija scroll i prilikom prelaska sa strane na stranu ako je prethodna scrolled i naredna ce biti
   */
  const scrollTop = () => {
    document.getElementsByClassName("p-dialog-content")[0].scrollTop = 0;
  };

  const getSifraStringFromTerapija = (sifra: string) => {
    return Object.keys(EnumTipTerapijePrikaz)[Object.values(EnumTipTerapijePrikaz).indexOf(sifra)];
  };

  const getNacinDoziranjaString = (isChecked: isChecked, akronim: string | undefined) => {
    if (isChecked.isJednakoChecked) {
      return terapijaChange?.brojUzimanjaTokomDana + " x " + terapijaChange?.dnevnaKolicina + " " + akronim;
    } else if (isChecked.isKombinovanoChecked) {
      return (
        terapijaChange?.jutarnjaKolicina +
        " " +
        akronim +
        " " +
        Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UJUTRU +
        ", " +
        terapijaChange?.popodnevnaKolicina +
        " " +
        akronim +
        " " +
        Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_U_PODNE +
        ", " +
        terapijaChange?.vecernjaKolicina +
        " " +
        akronim +
        " " +
        Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UVECE
      );
    } else if (isChecked.isJednokratnoChecked) {
      return Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO_PRUZANJE + " " + terapijaChange?.jednokratnaKolicina + akronim;
    } else if (isChecked.isNaSvakihChecked) {
      return (
        Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_NA +
        terapijaChange?.naKolikoDana +
        " " +
        Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DANA_PO +
        " " +
        terapijaChange?.kolicinaNaViseDana +
        akronim
      );
    } else if (isChecked.isPoPotrebiChecked) {
      return Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DATI_PO_POTREBI + " " + terapijaChange?.kolicinaPoPotrebi + akronim;
    } else if (isChecked.isProizvoljnoChecked) {
      return terapijaChange?.proizvoljnaKolicina;
    } else {
      return "";
    }
  };

  return {
    page,
    setPage,
    stepName,
    setStepName,
    isChecked,
    setIsChecked,
    isNextButtonDisabled,
    scrollTop,
    getSifraStringFromTerapija,
    getNacinDoziranjaString,
  };
}
