import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import UstanovaGridUpdateDto from "../../model/ustanova/UstanovaGridUpdateDto";
import UstanovaPodrazumevanaStranaUpdateDto from "../../model/ustanova/UstanovaPodrazumevanaStranaUpdateDto";
import UstanovaReadDto from "../../model/ustanova/UstanovaReadDto";
import { AppContext } from "./../../Store";
import UstanovaPodesavanjaReadDto from "../../model/ustanova/UstanovaPodesavanjaReadDto";
import ZakazanTerminPacijentJasperUstanovaCreateDto from "../../model/ustanova/ZakazanTerminPacijentJasperUstanovaCreateDto";
import UstanovaInicijalizacijaUpdateDto from "../../model/ustanova/UstanovaInicijalizacijaUpdateDto";
import CommonResponseDto from "../../model/CommonResponseDto";
import UstanovaGridReadDto from "../../model/ustanova/UstanovaGridReadDto";

interface UstanovaControllerType {
  axiosUstanovaDataFromId: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosPatchUstanovaPodrazumevanaStrana: (podrazumevanaStranaChange: UstanovaPodrazumevanaStranaUpdateDto) => Promise<AxiosResponse<any>>;
  axiosReadUstanova: () => Promise<AxiosResponse<any>>;
  axiosUpdateUstanova: (ustanovaChange: UstanovaReadDto) => Promise<AxiosResponse<any>>;
  axiosGetUstanovaGrid: () => Promise<AxiosResponse<CommonResponseDto<UstanovaGridReadDto>>>;
  axiosUpdateUstanovaGrid: (ustanovaGridChange: UstanovaGridUpdateDto) => Promise<AxiosResponse<any>>;
  axiosGetUstanovaPodesavanja: () => Promise<AxiosResponse<any>>;
  axiosUpdateUstanovaPodesavanja: (ustanovaPodesavanjaChange: UstanovaPodesavanjaReadDto) => Promise<AxiosResponse<any>>;
  axiosCreateZakazanTerminUstanovaJasper: (jasperDokument: ZakazanTerminPacijentJasperUstanovaCreateDto | undefined) => Promise<AxiosResponse<any>>;
  axiosGetZakazanTerminUstanovaJasper: () => Promise<AxiosResponse<any>>;
  axiosDeleteZakazanTerminUstanovaJasper: () => Promise<AxiosResponse<any>>;
  axiosGetUstanovaDataFromIdForAdministrator: (ustanovaId: number) => Promise<AxiosResponse>;
  axiosPatchUstanovaInitializationStep: (ustanovaInitializationStep: UstanovaInicijalizacijaUpdateDto) => Promise<AxiosResponse>;
}

export function UstanovaController(): UstanovaControllerType {
  const { authData } = useContext(AppContext);

  const axiosReadUstanova = () => {
    return axios.get(`${Endpoint.USTANOVA_LIST}`, axiosConfig(authData!.token));
  };
  const axiosUpdateUstanova = (ustanovaChange: UstanovaReadDto) => {
    return axios.put(`${Endpoint.USTANOVA_LIST}`, ustanovaChange, axiosConfig(authData!.token));
  };
  const axiosUstanovaDataFromId = (ustanovaId: number) => {
    return axios.get(`${Endpoint.USTANOVA_LIST}/${ustanovaId}/organizaciona-jedinica`, axiosConfig(authData!.token));
  };
  const axiosPatchUstanovaPodrazumevanaStrana = (podrazumevanaStranaChange: UstanovaPodrazumevanaStranaUpdateDto) => {
    return axios.patch(`${Endpoint.USTANOVA_LIST}/podrazumevana-strana`, podrazumevanaStranaChange, axiosConfig(authData!.token));
  };
  const axiosGetUstanovaGrid = () => {
    return axios.get(`${Endpoint.USTANOVA_GRID_LIST}/`, axiosConfig(authData!.token));
  };
  const axiosUpdateUstanovaGrid = (ustanovaGridChange: UstanovaGridUpdateDto) => {
    return axios.put(`${Endpoint.USTANOVA_GRID_LIST}`, ustanovaGridChange, axiosConfig(authData!.token));
  };

  const axiosGetUstanovaPodesavanja = () => {
    return axios.get(`${Endpoint.USTANOVA_PODESAVANJA}/`, axiosConfig(authData!.token));
  };

  const axiosUpdateUstanovaPodesavanja = (ustanovaPodesavanja: UstanovaPodesavanjaReadDto) => {
    return axios.put(`${Endpoint.USTANOVA_PODESAVANJA}/`, ustanovaPodesavanja, axiosConfig(authData!.token));
  };

  const axiosCreateZakazanTerminUstanovaJasper = (jasperDokument: ZakazanTerminPacijentJasperUstanovaCreateDto | undefined) => {
    return axios.post(Endpoint.ZAKAZAN_TERMIN_JASPER_USTANOVA_LIST, jasperDokument, axiosConfig(authData!.token));
  };

  const axiosGetZakazanTerminUstanovaJasper = () => {
    return axios.get(Endpoint.ZAKAZAN_TERMIN_JASPER_USTANOVA_LIST, axiosConfig(authData!.token));
  };

  const axiosDeleteZakazanTerminUstanovaJasper = () => {
    return axios.delete(Endpoint.ZAKAZAN_TERMIN_JASPER_USTANOVA_LIST, axiosConfig(authData!.token));
  };

  const axiosGetUstanovaDataFromIdForAdministrator = (ustanovaId: number) => {
    return axios.get(`${Endpoint.USTANOVA_LIST}/${ustanovaId}/organizaciona-jedinica/administrator`, axiosConfig(authData!.token));
  };

  const axiosPatchUstanovaInitializationStep = (ustanovaInitializationStep: UstanovaInicijalizacijaUpdateDto) => {
    return axios.patch(`${Endpoint.USTANOVA_LIST}/inicijalizacija`, ustanovaInitializationStep, axiosConfig(authData!.token));
  };

  return {
    axiosUstanovaDataFromId,
    axiosPatchUstanovaPodrazumevanaStrana,
    axiosGetUstanovaGrid,
    axiosUpdateUstanovaGrid,
    axiosReadUstanova,
    axiosUpdateUstanova,
    axiosGetUstanovaPodesavanja,
    axiosUpdateUstanovaPodesavanja,
    axiosCreateZakazanTerminUstanovaJasper,
    axiosGetZakazanTerminUstanovaJasper,
    axiosDeleteZakazanTerminUstanovaJasper,
    axiosGetUstanovaDataFromIdForAdministrator,
    axiosPatchUstanovaInitializationStep,
  };
}
