import moment from "moment";
import { AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { CalendarChangeEvent } from "primereact/calendar";
import { useContext, useRef, useState } from "react";
import { AppContext } from "../../../Store";
import { LogHighLevelController } from "../../../controllers/log/LogHighLevelController";
import { PacijentController } from "../../../controllers/pacijent/PacijentController";
import { RadnikController } from "../../../controllers/radnik/RadnikController";
import { DATE_FORMAT, DATE_TIME_FORMAT_3, formatDate, handleAxiosCallError, skeletonTimeout } from "../../../infrastructure/system/Utils";
import LogHighLevelReadDto from "../../../model/log/LogHighLevelReadDto";
import LogHighLevelSearchDto from "../../../model/log/LogHighLevelSearchDto";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import RadnikSkracenoDto from "../../../model/radnik/RadnikSkracenoDto";
import RadnikSkracenoReadDto from "../../../model/radnik/RadnikSkracenoReadDto";

interface PacijentIspisDto extends PacijentReadDto {
  ispis: string;
}

interface RadnikIspisDto extends RadnikSkracenoReadDto {
  ispis: string;
}

interface LogHighLevelListLogicalType {
  showResults: boolean;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  first: number;
  tableRows: number;
  onPageFormTable: (rows: number, first: number, page?: number) => void;
  logHighLevelList: Array<LogHighLevelReadDto>;
  searchLogHighLevel: (searchObject: LogHighLevelSearchDto) => void;
  searchLogHighLevelObject: LogHighLevelSearchDto;
  setSearchLogHighLevelObject: (logSearch: LogHighLevelSearchDto) => void;

  searchLogHighLevelLoading: boolean;
  onChangeCalendar: (e: CalendarChangeEvent) => void;
  searchLogPageReady: boolean;

  pacijentOdabran: PacijentReadDto | undefined;
  pacijentSuggestionList: Array<PacijentIspisDto>;

  pretragaPacijent: ({ query }: AutoCompleteCompleteEvent) => void;
  odabirPacijenta: (e: AutoCompleteChangeEvent) => void;

  radnikSuggestionList: Array<RadnikIspisDto>;
  pretragaRadnik: ({ query }: AutoCompleteCompleteEvent) => void;
  radnikOdabran: RadnikSkracenoDto | undefined;
  odabirRadnika: (e: AutoCompleteChangeEvent) => void;

  logHighLevelCount: number;
  setLogHighLevelCount: React.Dispatch<React.SetStateAction<number>>;
  countLogHighLevel: (searchObject: LogHighLevelSearchDto) => void;

  setFirst: React.Dispatch<React.SetStateAction<number>>;
  vremeOdRef: HTMLDivElement | any;
  vremeDoRef: HTMLDivElement | any;
}

export default function LogHighLevelListLogical(): LogHighLevelListLogicalType {
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [logHighLevelList, setLogHighLevelList] = useState<Array<LogHighLevelReadDto>>([]);
  const [logHighLevelCount, setLogHighLevelCount] = useState<number>(0);
  const vremeOdRef = useRef<HTMLDivElement | any>(null);
  const vremeDoRef = useRef<HTMLDivElement | any>(null);

  const [searchLogHighLevelLoading, setSearchLogHighLevelLoading] = useState<boolean>(false);
  const { axiosSearchLogHighLevelList, axiosCountLogHighLevelList } = LogHighLevelController();
  const [searchLogHighLevelObject, setSearchLogHighLevelObject] = useState<LogHighLevelSearchDto>({});

  const [pacijentOdabran, setPacijentOdabran] = useState<PacijentIspisDto>();

  const [pacijentSuggestionList, setPacijentSuggestionList] = useState<Array<PacijentIspisDto>>([]);
  const { axiosPacijentSearch } = PacijentController();

  const { axiosSearchRadnik } = RadnikController();
  const [radnikSuggestionList, setRadnikSuggestionList] = useState<Array<RadnikIspisDto>>([]);
  const [radnikOdabran, setRadnikOdabran] = useState<RadnikSkracenoDto>();

  const { showMessage } = useContext(AppContext);
  const [searchLogPageReady] = useState<boolean>(false);

  const parseLog = (logHighLevelList: Array<LogHighLevelReadDto>) => {
    let newLogList: Array<LogHighLevelReadDto> = [];
    logHighLevelList.forEach((log: LogHighLevelReadDto) => {
      let logObj = {
        ...log,
        id: log?.radnikId,
        imeRadnik: log?.imeRadnik,
        prezimeRadnik: log?.prezimeRadnik,
        pacijentId: log?.pacijentId,
        imePacijenta: log?.imePacijenta,
        prezimePacijenta: log?.prezimePacijenta,
        ustanovaId: log?.ustanovaId,
        vremeAkcije: log?.vremeAkcije,
        opis: log?.opis,
      };
      newLogList.push(logObj);
    });
    return newLogList;
  };

  const pretragaPacijent = ({ query }: AutoCompleteCompleteEvent) => {
    setTimeout(() => {
      axiosPacijentSearch({ basicSearch: query })
        .then((response: any) => {
          setPacijentSuggestionList(
            response.data.data.map((pacijent: PacijentReadDto) => ({
              ...pacijent,
              ispis: `${pacijent.ime} ${pacijent.prezime}  ${pacijent.vremeRodjenja ? " - " + moment(pacijent.vremeRodjenja).format(DATE_FORMAT) : ""}`,
            }))
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }, 250);
  };
  const pretragaRadnik = ({ query }: AutoCompleteCompleteEvent) => {
    setTimeout(() => {
      axiosSearchRadnik({ searchString: query })
        .then((response: any) => {
          setRadnikSuggestionList(
            response.data.data.map((radnik: RadnikSkracenoDto) => ({
              ...radnik,
              ispis: `${radnik.ime} ${radnik.prezime}  ${" (" + radnik.email + ")"}`,
            }))
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }, 250);
  };

  const odabirPacijenta = (e: AutoCompleteChangeEvent) => {
    setPacijentOdabran(e.target.value);

    setSearchLogHighLevelObject({ ...searchLogHighLevelObject, pacijentID: e.target.value ? e.target.value.id : undefined });
  };

  const odabirRadnika = (e: AutoCompleteChangeEvent) => {
    setRadnikOdabran(e.target.value);
    setSearchLogHighLevelObject({ ...searchLogHighLevelObject, radnikID: e.target.value ? e.target.value.id : undefined });
  };

  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };
  const onChangeCalendar = (e: CalendarChangeEvent) => {
    setSearchLogHighLevelObject({ ...searchLogHighLevelObject, [e.target.name]: formatDate(e.value as Date | undefined, DATE_TIME_FORMAT_3) });
  };

  const searchLogHighLevel = (searchObject: LogHighLevelSearchDto) => {
    let startTime = moment(new Date());
    setSearchLogHighLevelLoading(true);
    axiosSearchLogHighLevelList(searchObject)
      .then((response: any) => {
        setLogHighLevelList(parseLog(response.data.data));
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSearchLogHighLevelLoading, startTime);
      });
  };

  const countLogHighLevel = (searchObject: LogHighLevelSearchDto) => {
    let startTime = moment(new Date());
    setSearchLogHighLevelLoading(true);

    axiosCountLogHighLevelList(searchObject)
      .then((response: any) => {
        setLogHighLevelCount(response.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSearchLogHighLevelLoading, startTime);
      });
  };

  return {
    showResults,
    setShowResults,
    first,
    tableRows,
    onPageFormTable,
    logHighLevelList,
    searchLogHighLevel,
    searchLogHighLevelLoading,
    searchLogHighLevelObject,
    setSearchLogHighLevelObject,
    onChangeCalendar,
    searchLogPageReady,
    setFirst,
    pacijentOdabran,
    pacijentSuggestionList,
    pretragaPacijent,
    odabirPacijenta,
    radnikSuggestionList,
    radnikOdabran,
    pretragaRadnik,
    odabirRadnika,
    logHighLevelCount,
    setLogHighLevelCount,
    countLogHighLevel,
    vremeOdRef,
    vremeDoRef,
  };
}
