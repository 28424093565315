import moment from "moment";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { DATE_TIME_FORMAT_2, formatDate } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import KontaktProtokolBrojResponseReadDto from "../../../../model/kontakt/KontaktProtokolBrojResponseReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import CalendarComponent from "../../calendar-component/CalendarComponent";
import ProtokolBrojListLogical from "./ProtokolBrojListLogical";

export default function ProtokolBrojListPage() {
  const {
    protokolBrojList,
    protokolBrojListLoading,
    protokolBrojSearch,
    protokolList,
    organizacionaJedinicaList,
    first,
    tableRows,
    onPage,
    openProtokolBroj,
    dt,
    setProtokolBrojSearch,
    searchKontaktProtokolBroj,
    datumOdRef,
    datumDoRef,
  } = ProtokolBrojListLogical();

  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMini } = PageDropdownTemplate();
  const { pristup } = useContext(AppContext);
  const vaziOdBody = (rowKontaktProtokolBroj: KontaktProtokolBrojResponseReadDto) => {
    return <div>{moment(rowKontaktProtokolBroj.vremeOd).format(DATE_TIME_FORMAT_2)}</div>;
  };
  const vaziDoBody = (rowKontaktProtokolBroj: KontaktProtokolBrojResponseReadDto) => {
    return <div>{rowKontaktProtokolBroj.vremeDo ? moment(rowKontaktProtokolBroj.vremeDo).format(DATE_TIME_FORMAT_2) : ""}</div>;
  };
  const pacijentBody = (rowKontaktProtokolBroj: KontaktProtokolBrojResponseReadDto) => {
    return <div>{rowKontaktProtokolBroj.pacijent.ime + " " + rowKontaktProtokolBroj.pacijent.prezime}</div>;
  };

  const searchToolbar = () => {
    return (
      <div className="flex flex-row flex-wrap">
        <div className="flex align-items-center my-2">
          <div className="font-bold float-label mr-3 mb-3 md:mb-0">
            <div>{Labels.PROTOKOL + Labels.SPECIAL_CHAR_REQUIRED}</div>
          </div>
          <div className="p-fluid p-0 mr-3 mb-3 md:mb-0">
            <div>
              <Dropdown
                value={protokolBrojSearch?.protokol ?? ""}
                options={protokolList}
                optionLabel="naziv"
                optionValue="id"
                placeholder={Labels.PROTOKOL_SELECT}
                onChange={(e: DropdownChangeEvent) => {
                  setProtokolBrojSearch({
                    ...protokolBrojSearch!,
                    protokol: e.value,
                  });
                }}
                emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              />
            </div>
          </div>
        </div>
        <div className="flex align-items-center my-2">
          <div className="font-bold float-label mr-3 mb-3 md:mb-0">
            <div>{Labels.LABEL_ORGANIZACIONA_JEDINICA}</div>
          </div>
          <div className="p-fluid p-0 mr-3 mb-3 md:mb-0">
            <div>
              <Dropdown
                value={protokolBrojSearch?.organizacionaJedinica ?? ""}
                options={organizacionaJedinicaList}
                optionLabel="naziv"
                optionValue="id"
                showClear={protokolBrojSearch?.organizacionaJedinica != null}
                onChange={(e: DropdownChangeEvent) => {
                  setProtokolBrojSearch({
                    ...protokolBrojSearch!,
                    organizacionaJedinica: e.value,
                  });
                }}
                emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              />
            </div>
          </div>
        </div>
        <div className="flex align-items-center my-2">
          <div className="font-bold float-label mr-3 mb-3 md:mb-0">
            <div>{Labels.RADNI_PERIOD_COLUMN_DATUM_OD}</div>
          </div>
          <div className="mr-3 mb-3 md:mb-0">
            <CalendarComponent
              ref={datumOdRef}
              showButtonBar={false}
              name="datumOd"
              value={protokolBrojSearch?.vremeOd ?? ""}
              onChange={(e: CalendarChangeEvent) => {
                setProtokolBrojSearch({
                  ...protokolBrojSearch!,
                  vremeOd: formatDate(e.value as Date | undefined, DATE_TIME_FORMAT_2)!,
                });
                if (e.target.value) datumOdRef?.current?.hide();
              }}
              placeholder={Labels.RADNI_PERIOD_COLUMN_DATUM_OD}
            />
          </div>
        </div>
        <div className="flex align-items-center my-2">
          <div className="font-bold float-label mr-3 mb-3 md:mb-0">
            <div>{Labels.RADNI_PERIOD_COLUMN_DATUM_DO}</div>
          </div>
          <div className="mr-3 mb-3 md:mb-0">
            <CalendarComponent
              ref={datumDoRef}
              showButtonBar={false}
              name="datumDo"
              value={protokolBrojSearch?.vremeDo ?? ""}
              onChange={(e: CalendarChangeEvent) => {
                setProtokolBrojSearch({
                  ...protokolBrojSearch!,
                  vremeDo: formatDate(e.value as Date | undefined, DATE_TIME_FORMAT_2)!,
                });
                if (e.target.value) datumDoRef?.current?.hide();
              }}
              placeholder={Labels.RADNI_PERIOD_COLUMN_DATUM_DO}
            />
          </div>
        </div>
        <div className="flex align-items-center my-2">
          <div className="font-bold float-label mr-3 mb-3 md:mb-0">
            <div>{Labels.PROTOKOL_BROJ_BROJEVI}</div>
          </div>
          <div className="mr-3">
            <InputNumber
              className="small-number-input"
              value={protokolBrojSearch?.brojOd ?? null}
              onChange={(e) => {
                setProtokolBrojSearch({
                  ...protokolBrojSearch!,
                  brojOd: e.value!,
                });
              }}
            />
          </div>
          <div className="font-bold float-label mr-3">
            <div>-</div>
          </div>
          <div className="mr-3">
            <InputNumber
              className="small-number-input"
              value={protokolBrojSearch?.brojDo ?? null}
              onChange={(e) => {
                setProtokolBrojSearch({
                  ...protokolBrojSearch!,
                  brojDo: e.value!,
                });
              }}
            />
          </div>
          <Button
            tooltip={Labels.RADNI_PERIOD_SEARCH}
            icon="pi pi-search"
            className="search-button"
            onClick={() => {
              searchKontaktProtokolBroj();
            }}
          />
        </div>
      </div>
    );
  };

  const crudBodyTemplate = (rowKontaktPorotkolBroj: KontaktProtokolBrojResponseReadDto) => {
    return (
      <>
        <div className="float-right">
          <Button
            type="button"
            tooltip={Labels.PROTOKOL_BROJ_BRISANJE}
            className="p-button-danger mr-2"
            icon="pi pi-trash"
            onClick={() => {
              rowKontaktPorotkolBroj && openProtokolBroj(EntityOperation.DELETE, rowKontaktPorotkolBroj);
            }}
            disabled={!pristup}
          />
        </div>
      </>
    );
  };
  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.LABEL_ORGANIZACIONA_JEDINICA, filter: true, sortrable: true },
    { columnName: Labels.PROTOKOL, filter: true, sortrable: true },
    { columnName: Labels.VREME_OD, filter: true, sortrable: true },
    { columnName: Labels.VREME_DO, filter: true, sortrable: true },
    { columnName: Labels.PRIMARNI_PACIJENT, filter: true, sortrable: true },
    { columnName: Labels.PROTOKOL_BROJ, filter: true, sortrable: true },
  ];
  return (
    <>
      <Toolbar left={searchToolbar} />
      <div className="layout-generic-content-list">
        {protokolBrojListLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={protokolBrojList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMini as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e: DataTablePageEvent) => onPage(e.rows, e.first)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"organizacionaJedinica.naziv"} header={Labels.LABEL_ORGANIZACIONA_JEDINICA} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"protokolBroj.protokol.naziv"} header={Labels.PROTOKOL} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"vremeOd"} body={vaziOdBody} header={Labels.VREME_OD} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"vremeDo"} body={vaziDoBody} header={Labels.VREME_DO} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"pacijent"} body={pacijentBody} header={Labels.PRIMARNI_PACIJENT} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"protokolBroj.broj"} header={Labels.PROTOKOL_BROJ} sortable filter filterMatchMode="contains" showFilterMenu={false} />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </div>
    </>
  );
}
