import { valueMap } from "../../../../components/formular/FormularLogical";
import GenerisaniIzvestajSmallReadDto from "../../../../model/generisaniIzvestaj/GenerisaniIzvestajSmallReadDto";
import IzvestajSimpleReadDto from "../../../../model/izvestaj/IzvestajSimpleReadDto";
import KategorijaStatistickogIzvestajReadDto from "../../../../model/izvestaj/kategorija/KategorijaStatistickogIzvestajaReadDto";

export interface InitialStateGenerisaniIzvestajType {
  izvestajList: Array<GenerisaniIzvestajSmallReadDto>;
  izvestajListLoading: boolean;
  kategorijaList: Array<KategorijaStatistickogIzvestajReadDto>;
  kategorijaListLoading: boolean;
  pagination: { page: number; rows: number };
  totalCount: number;
  onlyMine: boolean;
  selectedStatistickiIzvestaj?: IzvestajSimpleReadDto;
  paramsLoading: boolean;
  paramsValue: valueMap;
}

const InitialStateGenerisaniIzvestaj: InitialStateGenerisaniIzvestajType = {
  izvestajList: [],
  izvestajListLoading: true,
  kategorijaList: [],
  kategorijaListLoading: true,
  pagination: { page: 0, rows: 10 },
  totalCount: 0,
  onlyMine: false,
  selectedStatistickiIzvestaj: undefined,
  paramsLoading: true,
  paramsValue: [],
};

export default InitialStateGenerisaniIzvestaj;
