import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useLabels } from "../../../../Store";
import OrganizacionaJedinicaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import { InitialStateType } from "../../hooks/initialState";
import { ReducerType } from "../../hooks/reducer";
import { useTableData } from "../../../../infrastructure/system/hooks/tables/useTableData";
import { PaginatorTemplateOptions } from "primereact/paginator";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";

interface KreiraneOrgJediniceListViewPropsType {
  organizacionaJedinicaList: OrganizacionaJedinicaReadDto[];
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerType>;
  onDeleteOrganizacionaJedinica: UseMutationResult<AxiosResponse, unknown, number, unknown>;
  isLoading: boolean;
}

const KreiraneOrgJediniceListView = ({ organizacionaJedinicaList, state, dispatch, onDeleteOrganizacionaJedinica, isLoading }: KreiraneOrgJediniceListViewPropsType) => {
  const Labels = useLabels();
  const { first, tableRows, onPage } = useTableData();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const crudBodyTemplate = (rowOrganizacionaJedinica: OrganizacionaJedinicaReadDto) => {
    return (
      <div className="flex justify-content-end">
        {(state?.orgJedinicaIsEditing ? rowOrganizacionaJedinica.id === state?.orgJedinicaChange?.id : true) && (
          <Button
            className={`${state?.orgJedinicaIsEditing && rowOrganizacionaJedinica.id === state?.orgJedinicaChange?.id ? "p-button-primary" : "p-button-secondary"} mr-2`}
            icon="pi pi-pencil"
            rounded
            text
            onClick={() => {
              if (!state?.orgJedinicaIsEditing) {
                dispatch({ type: "set_organizaciona_jedinica", data: rowOrganizacionaJedinica });
                dispatch({ type: "set_is_editing_org_jedinica", data: true });
              }
            }}
          />
        )}
        {!state?.orgJedinicaIsEditing && (
          <Button
            className="p-button-secondary"
            icon="pi pi-trash"
            rounded
            text
            onClick={() =>
              confirmDialog({
                acceptLabel: Labels.LABEL_DELETE,
                acceptIcon: "pi pi-trash",
                rejectLabel: Labels.BUTTON_CANCEL,
                rejectClassName: "p-button-outlined",
                rejectIcon: "pi pi-times",
                header: Labels.ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DELETE,
                message: <span>{Labels.LABEL_INICIJALIZACIJA_ORG_JEDINICA_DELETE_CONFIRM_MESSAGE}</span>,
                accept: () => onDeleteOrganizacionaJedinica.mutate(rowOrganizacionaJedinica.id),
              })
            }
          />
        )}
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.LABEL_NAZIV, filter: false, sortrable: false },
    { columnName: Labels.LABEL_SIFRA, filter: false, sortrable: false },
  ];

  return (
    <>
      {isLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={false} isVisibleButtonList />
      ) : (
        <DataTable
          value={organizacionaJedinicaList}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          className="bg-white"
          paginator
          first={first}
          paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
          rows={tableRows}
          onPage={onPage}
        >
          <Column field="naziv" header={Labels.LABEL_NAZIV} />
          <Column field="sifra" header={Labels.LABEL_SIFRA} />
          <Column body={crudBodyTemplate} />
        </DataTable>
      )}
    </>
  );
};

export default KreiraneOrgJediniceListView;
