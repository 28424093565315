import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import SifarnikUslugaReadDto from "../../model/sifarnik-usluga/SifarnikUslugaReadDto";
import UslugaCenaCreateDto from "../../model/sifarnik-usluga/UslugaCenaCreateDto";
import UslugaCenaUpdateDto from "../../model/sifarnik-usluga/UslugaCenaUpdateDto";
import UslugaSpecijalizacijaCreateDto from "../../model/sifarnik-usluga/UslugaSpecijalizacijaCreateDto";

interface SifarnikUslugaControllerType {
  axiosReadSifarnikUsluga: (id: number) => Promise<AxiosResponse<any>>;
  axiosSearchSifarnikUsluga: (searchObject: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosUpdateSifarnikUsluga: (sifarnikUsluga: SifarnikUslugaReadDto) => Promise<AxiosResponse<any>>;
  axiosCreateSifarnikUsluga: (sifarnikUsluga: SifarnikUslugaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteSifarnikUsluga: (sifarnikUslugaId: number) => Promise<AxiosResponse<any>>;
  axiosGetSifarnikUslugaList: () => Promise<AxiosResponse<any>>;
  axiosReadSifarnikUslugaRFZOList: () => Promise<AxiosResponse<any>>;
  axiosSearchSifarnikUslugaByNazivOrSifra: (searchObject: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetCeneForUsluga: (sifarnikUslugaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateCenaForUsluga: (cenaUsluge: UslugaCenaCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteCenaForUsluga: (sifarnikUslugaId: number, cenaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateCenaForUsluga: (cenaUsluge: UslugaCenaUpdateDto) => Promise<AxiosResponse<any>>;
  axiosGetSpecijalizacijeForUsluga: (uslugaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateUslugaSpecijalizacije: (uslugaId: number, uslugaSpecijalizacijaList: Array<UslugaSpecijalizacijaCreateDto>) => Promise<AxiosResponse<any>>;
  axiosDeleteUslugaSpecijalizacija: (uslugaId: number, specijalizacijaID: Array<number>) => Promise<AxiosResponse<any>>;
}

export function SifarnikUslugaController(): SifarnikUslugaControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetSifarnikUslugaList = () => {
    return axios.get(Endpoint.SIFARNIK_USLUGA_LIST, axiosConfig(authData!.token));
  };

  const axiosReadSifarnikUsluga = (id: number) => {
    return axios.get(`${Endpoint.SIFARNIK_USLUGA_LIST}/${id}`, axiosConfig(authData!.token));
  };

  const axiosSearchSifarnikUsluga = (searchObject: SearchBaseDto) => {
    return axios.get(Endpoint.SIFARNIK_USLUGA_SEARCH, axiosConfig(authData!.token, searchObject));
  };

  const axiosCreateSifarnikUsluga = (sifarnikUsluga: SifarnikUslugaReadDto) => {
    return axios.post(Endpoint.SIFARNIK_USLUGA_LIST, sifarnikUsluga ? sifarnikUsluga : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateSifarnikUsluga = (sifarnikUsluga: SifarnikUslugaReadDto) => {
    return axios.put(`${Endpoint.SIFARNIK_USLUGA_LIST}/${sifarnikUsluga?.id}`, sifarnikUsluga, axiosConfig(authData!.token));
  };

  const axiosDeleteSifarnikUsluga = (sifarnikUslugaId: number) => {
    return axios.delete(`${Endpoint.SIFARNIK_USLUGA_LIST}/${sifarnikUslugaId}`, axiosConfig(authData!.token));
  };

  const axiosReadSifarnikUslugaRFZOList = () => {
    return axios.get(`${Endpoint.SIFARNIK_USLUGA_RFZO_LIST}`, axiosConfig(authData!.token));
  };

  const axiosSearchSifarnikUslugaByNazivOrSifra = (searchObject: SearchBaseDto) => {
    return axios.get(Endpoint.SIFARNIK_USLUGA_SEARCH_NAZIV_SIFRA, axiosConfig(authData!.token, searchObject));
  };

  const axiosGetCeneForUsluga = (sifarnikUslugaId: number) => {
    return axios.get(`${Endpoint.SIFARNIK_USLUGA_LIST}/${sifarnikUslugaId}/usluga-cena-list`, axiosConfig(authData!.token));
  };

  const axiosCreateCenaForUsluga = (cenaUsluge: UslugaCenaCreateDto) => {
    return axios.post(`${Endpoint.SIFARNIK_USLUGA_LIST}/${cenaUsluge.usluga.id}/usluga-cena-list`, cenaUsluge ? cenaUsluge : {}, axiosConfig(authData!.token));
  };

  const axiosDeleteCenaForUsluga = (sifarnikUslugaId: number, cenaId: number) => {
    return axios.delete(`${Endpoint.SIFARNIK_USLUGA_LIST}/${sifarnikUslugaId}/usluga-cena-list/${cenaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateCenaForUsluga = (cenaUsluge: UslugaCenaUpdateDto) => {
    return axios.put(`${Endpoint.SIFARNIK_USLUGA_LIST}/${cenaUsluge.usluga.id}/usluga-cena-list/${cenaUsluge.id}`, cenaUsluge, axiosConfig(authData!.token));
  };

  const axiosGetSpecijalizacijeForUsluga = (uslugaId: number) => {
    return axios.get(`${Endpoint.USLUGA_LIST}/${uslugaId}/specijalizacija-list`, axiosConfig(authData!.token));
  };

  const axiosCreateUslugaSpecijalizacije = (uslugaId: number, uslugaSpecijalizacijaList: Array<UslugaSpecijalizacijaCreateDto>) => {
    return axios.post(`${Endpoint.USLUGA_LIST}/${uslugaId}/specijalizacija-list`, { uslugaSpecijalizacijaList: uslugaSpecijalizacijaList }, axiosConfig(authData!.token));
  };

  const axiosDeleteUslugaSpecijalizacija = (uslugaId: number, specijalizacijaID: Array<number>) => {
    return axios.delete(`${Endpoint.USLUGA_LIST}/${uslugaId}/specijalizacija-list/`, axiosConfig(authData!.token, { specijalizacijaID: specijalizacijaID.join(",") }));
  };

  return {
    axiosReadSifarnikUsluga,
    axiosSearchSifarnikUsluga,
    axiosUpdateSifarnikUsluga,
    axiosCreateSifarnikUsluga,
    axiosDeleteSifarnikUsluga,
    axiosGetSifarnikUslugaList,
    axiosReadSifarnikUslugaRFZOList,
    axiosSearchSifarnikUslugaByNazivOrSifra,
    axiosGetCeneForUsluga,
    axiosCreateCenaForUsluga,
    axiosDeleteCenaForUsluga,
    axiosUpdateCenaForUsluga,
    axiosGetSpecijalizacijeForUsluga,
    axiosCreateUslugaSpecijalizacije,
    axiosDeleteUslugaSpecijalizacija,
  };
}
