import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import { useNavigate } from "react-router";
import { useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import UstanovaReadDto from "../../../model/ustanova/UstanovaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import UstanovaListLogical from "./UstanovaListLogical";

export default function UstanovaListPage() {
  const { ustanovaList, first, tableRows, changeUstanovaSearchData, ustanovaSearchData, fetchData, searchUstanovaByEnter, onPageUstanova, exportData, breadCrumbItems, ustanovaListLoading } =
    UstanovaListLogical();

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();

  const openUstanova = (entityOperation: string, id?: number) => {
    const idUstanova = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-ustanova/${idUstanova}`, {
      state: { ustanovaOperation: entityOperation },
    });
  };

  const crudBodyTemplate = (rowUstanova: UstanovaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.USTANOVA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowUstanova && openUstanova(EntityOperation.UPDATE, rowUstanova.id)}
        />
      </div>
    );
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_USTANOVA}
          tooltip={Labels.USTANOVA_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openUstanova(EntityOperation.CREATE);
          }}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={ustanovaSearchData?.searchString ?? ""}
            onChange={changeUstanovaSearchData}
            onKeyPress={searchUstanovaByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_USTANOVA}</label>
          <Button tooltip={Labels.USTANOVA_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={ustanovaListLoading} data={exportData} name={Labels.USTANOVA_LIST} dt={dt} />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.USTANOVA_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.USTANOVA_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.USTANOVA_ADRESA, filter: true, sortrable: true },
    { columnName: Labels.USTANOVA_TELEFON, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {ustanovaListLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList defaultButtonCount={1} />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={ustanovaList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageUstanova(e.rows, e.first);
            }}
            exportFilename={Labels.USTANOVA_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.USTANOVA_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.USTANOVA_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"adresa"} header={Labels.USTANOVA_ADRESA} filter filterMatchMode="contains" showFilterMenu={false} className="wrap-line-anywhere" sortable />
            <Column field={"telefon"} header={Labels.USTANOVA_TELEFON} filter filterMatchMode="contains" showFilterMenu={false} className="wrap-line-anywhere" sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
