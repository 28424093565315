import { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { SifarnikProizvodaController } from "../../../../controllers/sifarnik-proizvoda/SifarnikProizvodaController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { checkEmpty, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import actionsSifarnikCena from "../../../../infrastructure/system/hooks/dialog-sifarnik-cena-reducer/actionsSifarnikCena";
import InitialStateSifarnikCena, { InitialStateSifarnikCenaType } from "../../../../infrastructure/system/hooks/dialog-sifarnik-cena-reducer/initialStateSifarnikCena";
import reducerSifarnikCena from "../../../../infrastructure/system/hooks/dialog-sifarnik-cena-reducer/reducerSifarnikCena";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import ProizvodCenaReadDto from "../../../../model/sifarnik-proizvoda/ProizvodCenaReadDto";
import ProizvodCenaUpdateDto from "../../../../model/sifarnik-proizvoda/ProizvodCenaUpdateDto";

interface CrudSifarnikProizvodaCeneListLogicalType {
  ceneLoading: boolean;
  first: number;
  tableRows: number;
  onPageCeneProizvoda: (rows: number, first: number) => void;
  state: InitialStateSifarnikCenaType;
  dispatch: React.Dispatch<{ type: actionsSifarnikCena }> | any;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  itemId: number;
  sifarnikProizvodaCenaList: Array<ProizvodCenaReadDto>;
  sifarnikProizvodaCenaLoading: boolean;
}

interface UseParamsType {
  sifarnikProizvodaId?: string;
}

export default function CrudSifarnikProizvodaCeneListLogical(): CrudSifarnikProizvodaCeneListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [ceneLoading] = useState<boolean>(false);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [state, dispatch] = useReducer(reducerSifarnikCena, InitialStateSifarnikCena);
  const [sifarnikProizvodaCenaList, setSifarnikProizvodaCenaList] = useState<Array<ProizvodCenaReadDto>>([]);
  const [sifarnikProizvodaCenaLoading, setSifarnikProizvodaCenaLoading] = useState<boolean>(false);
  const itemId = Number(useParams<keyof UseParamsType>()["sifarnikProizvodaId"]);
  const { axiosCreateCenaForProizvod, axiosDeleteCenaForProzivod, axiosGetCeneForProizvod, axiosUpdateCenaForProizvod } = SifarnikProizvodaController();
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchCeneProizvoda();
  });

  const fetchCeneProizvoda = () => {
    if (itemId) {
      let startTime = moment(new Date());
      setSifarnikProizvodaCenaLoading(true);
      axiosGetCeneForProizvod(itemId)
        .then((response: AxiosResponse) => {
          setSifarnikProizvodaCenaList(response.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          skeletonTimeout(setSifarnikProizvodaCenaLoading, startTime);
        })
        .finally(() => {
          skeletonTimeout(setSifarnikProizvodaCenaLoading, startTime);
        });
    } else {
      setSifarnikProizvodaCenaLoading(false);
    }
  };

  const onPageCeneProizvoda = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const validateNovaCena = () => {
    if (checkEmpty(state.cena?.proizvod.id)) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_EMPTY_PROIZVOD_ERROR_MESSAGE);
      return false;
    }

    if (checkEmpty(state.cena?.iznos)) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_EMPTY_IZNOS_ERROR_MESSAGE);
      return false;
    }

    if (checkEmpty(state.cena?.valuta?.id)) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_EMPTY_VALUTA_ERROR_MESSAGE);
      return false;
    }

    if (checkEmpty(state.cena?.poreskaStopa?.id)) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_EMPTY_PORESKA_STOPA_ERROR_MESSAGE);
      return false;
    }

    if (checkEmpty(state.cena?.vaziOd)) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_EMPTY_DATUM_OD_ERROR_MESSAGE);
      return false;
    }

    if (state.cena?.vaziDo && !moment(state.cena.vaziDo).isAfter(moment(state.cena?.vaziOd), "days")) {
      showMessage(MessageType.ERROR, Labels.CENE_CREATE_WRONG_DATUM_DO_ERROR_MESSAGE);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validateNovaCena()) {
      return;
    }

    axiosCreateCenaForProizvod(state.cena)
      .then(() => {
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_1 +
            state.cena?.proizvod.id +
            Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_2 +
            state.cena?.vaziOd +
            Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_3 +
            state.cena?.iznos +
            Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_4 +
            state.cena?.valuta?.id +
            Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_5 +
            state.cena?.poreskaStopa?.id +
            "."
        );
      })
      .then(() => {
        dispatch({ type: actionsSifarnikCena.CLOSE_DIALOG });
        showMessage(MessageType.SUCCESS, Labels.CENE_CREATE_SUCCESS_MESSAGE);
        fetchCeneProizvoda();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onUpdate = () => {
    let cenaProizvodUpdate: ProizvodCenaUpdateDto = state.cena ? { ...state.cena, proizvod: { id: itemId } } : undefined;
    if (state.cena.id) {
      axiosUpdateCenaForProizvod(cenaProizvodUpdate)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CENA_UPDATE_1 +
              itemId +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_2 +
              state.cena?.vaziOd +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_3 +
              state.cena?.iznos +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_4 +
              state.cena?.valuta?.id +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_5 +
              state.cena?.poreskaStopa?.id +
              "."
          );
        })
        .then(() => {
          dispatch({ type: actionsSifarnikCena.CLOSE_DIALOG });
          showMessage(MessageType.SUCCESS, Labels.CENE_UPDATE_SUCCESS_MESSAGE);
          fetchCeneProizvoda();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onDelete = () => {
    if (state.cena.id) {
      axiosDeleteCenaForProzivod(itemId, state.cena.id)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CENA_DELETE_1 +
              itemId +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_2 +
              state.cena?.vaziOd +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_3 +
              state.cena?.iznos +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_4 +
              state.cena?.valuta?.id +
              Labels.LOG_HIGH_LEVEL_MESS_CENA_CREATE_5 +
              state.cena?.poreskaStopa?.id +
              "."
          );
        })
        .then(() => {
          dispatch({ type: actionsSifarnikCena.CLOSE_DIALOG });
          showMessage(MessageType.SUCCESS, Labels.CENE_DELETE_SUCCESS_MESSAGE);
          fetchCeneProizvoda();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  return {
    ceneLoading,
    first,
    tableRows,
    onPageCeneProizvoda,
    state,
    dispatch,
    onCreate,
    onDelete,
    onUpdate,
    itemId,
    sifarnikProizvodaCenaList,
    sifarnikProizvodaCenaLoading,
  };
}
