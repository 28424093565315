import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";

export interface InitialStateAddPacijentType {
  isDialogOpen: boolean;
  pacijent: PacijentReadDto | any;
  gendersLoading: boolean;
  obavestenjeTipListLoading: boolean;
}

const InitialStateAddPacijent: InitialStateAddPacijentType = {
  isDialogOpen: false,
  pacijent: {},
  gendersLoading: false,
  obavestenjeTipListLoading: false,
};

export default InitialStateAddPacijent;
