import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../../Store";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import { DATE_FORMAT_2, formatDate, tooltipOptionsBottom } from "../../../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../../../app/skeleton/SkeletonInputItem";
import CalendarComponent from "../../../calendar-component/CalendarComponent";
import CrudDodatniModulUstanovaLogical from "./CrudUstanovaDodatniModulLogical";

export default function CrudDodatniModulUstanovaView() {
  const {
    breadCrumbItems,
    dodatniModulUstanovaChange,
    setDodatniModulUstanovaChange,
    isDisabled,
    dodatniModulOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    dodatniModulLoading,
    dodatniModulList,
    vaziOdRef,
    vaziDoRef,
  } = CrudDodatniModulUstanovaLogical();
  const Labels = useLabels();

  const leftContentsToolbar = () => (
    <>
      {dodatniModulOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.DODATNI_MODUL_TITLE_CREATE} tooltipOptions={tooltipOptionsBottom} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {dodatniModulOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={dodatniModulLoading}
          icon="pi pi-pencil"
          tooltip={Labels.DODATNI_MODUL_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {dodatniModulOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          icon="pi pi-trash"
          tooltip={Labels.LICENCNI_MODEL_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
          <TabPanel header={Labels.DODATNI_MODUL}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>
                    {Labels.MODUL_LICENCNI_MODUL}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!dodatniModulLoading ? (
                    <Dropdown
                      disabled={dodatniModulOperation !== EntityOperation.CREATE}
                      optionLabel="naziv"
                      optionValue="id"
                      options={dodatniModulList}
                      value={dodatniModulUstanovaChange?.dodatniModul?.id ?? ""}
                      onChange={(e) => {
                        setDodatniModulUstanovaChange({
                          ...dodatniModulUstanovaChange!,
                          dodatniModul: dodatniModulList!.find((x) => x.id === e.target.value)!,
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>
                    {Labels.VAZI_OD}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!dodatniModulLoading ? (
                    <CalendarComponent
                      ref={vaziOdRef}
                      disabled={dodatniModulOperation !== EntityOperation.CREATE}
                      minDate={dodatniModulOperation === EntityOperation.CREATE ? new Date() : undefined}
                      showButtonBar={false}
                      name="vaziOd"
                      value={dodatniModulUstanovaChange?.vaziOd ?? ""}
                      onChange={(e: CalendarChangeEvent) => {
                        setDodatniModulUstanovaChange({
                          ...dodatniModulUstanovaChange!,
                          vaziOd: formatDate(e.value as Date | undefined, DATE_FORMAT_2)!,
                        });
                        if (e.target.value) vaziOdRef?.current?.hide();
                      }}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 sm:col-4 sm:px-3">
                  <div>
                    {Labels.VAZI_DO}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!dodatniModulLoading ? (
                    <CalendarComponent
                      ref={vaziDoRef}
                      disabled={isDisabled}
                      minDate={
                        dodatniModulOperation === EntityOperation.CREATE ? new Date() : dodatniModulOperation === EntityOperation.UPDATE ? new Date(dodatniModulUstanovaChange!.vaziOd) : undefined
                      }
                      showButtonBar={false}
                      name="vaziDo"
                      value={dodatniModulUstanovaChange?.vaziDo ?? ""}
                      onChange={(e: CalendarChangeEvent) => {
                        setDodatniModulUstanovaChange({
                          ...dodatniModulUstanovaChange!,
                          vaziDo: formatDate(e.target.value as Date, DATE_FORMAT_2)!,
                        });
                        if (e.target.value) vaziDoRef?.current?.hide();
                      }}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar start={leftContentsToolbar} end={rightContentsToolbar} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
