import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { useMemo } from "react";
import { useLabels } from "../../../../Store";
import SLekUcesceDijagnozaReadDto from "../../../../model/sifarnik-lekova-na-recept/SLekUcesceDijagnozaReadDto";
import SifarnikIndikacijaNapomenaReadDto from "../../../../model/sifarnik-lekova-na-recept/SifarnikIndikacijaNapomenaReadDto";
import SifarnikLekovaPriceHistoryReadDto from "../../../../model/sifarnik-lekova-na-recept/SifarnikLekovaPriceHistoryReadDto";
import SifarnikLekovaReadDto from "../../../../model/sifarnik-lekova-na-recept/SifarnikLekovaReadDto";
import DialogComponent from "../../../dialog-component/DialogComponent";

interface DialogIstorijaType {
  lek: SifarnikLekovaReadDto | undefined;
  closeDialog: () => void;
  show: boolean;
  isLoading: boolean;
  listaCena?: Array<SifarnikLekovaPriceHistoryReadDto>;
  renderDate: (date: Date) => string;
  listaIndikacija?: Array<SifarnikIndikacijaNapomenaReadDto>;
  listaDodatnihIndikacija?: Array<SLekUcesceDijagnozaReadDto>;
  showIstorijuCene: boolean;
}

export default function DialogIstorijaIndikacije(props: DialogIstorijaType) {
  const Labels = useLabels();
  const { lek, closeDialog, show, isLoading, listaCena, renderDate, listaIndikacija, listaDodatnihIndikacija, showIstorijuCene } = props;

  const buttonStyles = useMemo(() => {
    return { color: "#f26235" };
  }, []);

  const renderFooter = () => {
    return (
      <div className="flex justify-content-end">
        <Button label={Labels.BUTTON_CLOSE} className="p-button-outlined p-button-danger bg-white" onClick={closeDialog} style={buttonStyles} autoFocus />
      </div>
    );
  };

  return (
    <DialogComponent
      header={lek?.naziv ?? ""}
      visible={show}
      footer={renderFooter}
      onHide={closeDialog}
      blockScroll={true}
      className={showIstorijuCene ? "w-8 md:w-8 lg:w-4 xl:w-4" : "w-11"}
      content={
        <>
          {showIstorijuCene ? (
            <>
              <h6 className="font-bold">{Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY}</h6>
              <div className="scrollable-wrapper">
                {isLoading ? (
                  <Skeleton size="31rem" />
                ) : (
                  listaCena?.map((cena: SifarnikLekovaPriceHistoryReadDto) => (
                    <div className="flex justify-content-start" key={cena.id}>
                      <div className="col-8">
                        {renderDate(cena.vaziOd)} - {renderDate(cena.vaziDo)}
                      </div>
                      <div className="col-4">
                        {Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_CENA}: {cena.cena}
                      </div>
                    </div>
                  ))
                )}
                {listaCena?.length === 0 && <div className="p-3">{Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_CENE}</div>}
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-row flex-wrap">
                <div className="col-12 sm:col-12 md:col-12 lg:col-4 xl-col-4">
                  <h6 className="font-bold">{Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_DOZVOLJENE_INDIKACIJE}</h6>
                  <div className="scrollable-wrapper overflow-x-hidden">
                    {isLoading ? (
                      <Skeleton size="30rem" />
                    ) : (
                      listaDodatnihIndikacija?.map((dodatneIndikacije: SLekUcesceDijagnozaReadDto) => (
                        <div className="flex justify-content-between" key={dodatneIndikacije.id}>
                          <div className="col-8">{dodatneIndikacije.opisDijagnoze}</div>
                          <div className="col-4 flex justify-content-end">{dodatneIndikacije.maxKolicina}</div>
                        </div>
                      ))
                    )}
                    {listaDodatnihIndikacija?.length === 0 && <div className="p-3">{Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_DODATNIH_INDIKACIJA}</div>}
                  </div>
                </div>
                {isLoading ? (
                  <>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-4 xl-col-4">
                      <h6 className="font-bold">{Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_INDIKACIJE}</h6>
                      <div className="scrollable-wrapper overflow-x-hidden">
                        <Skeleton size="30rem" />
                      </div>
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-4 xl-col-4">
                      <h6 className="font-bold">{Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_NAPOMENA}</h6>
                      <div className="scrollable-wrapper overflow-x-hidden">
                        <Skeleton size="30rem" />
                      </div>
                    </div>
                  </>
                ) : (
                  // posle konsultacija sa Sofijom dogovoreno da ce uvek postojati samo jedna indikacija i napomena po leku
                  // tako da ovde ne radimo mapiranje vec uzimamo samo prvi zapis u slucaju da postoji vise greskom unetih
                  <>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-4 xl-col-4">
                      <h6 className="font-bold">{Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_INDIKACIJE}</h6>
                      <div className="scrollable-wrapper">
                        <div className="p-3">
                          {listaIndikacija?.[0]?.indikacija}
                          {listaIndikacija?.length === 0 && <div>{Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_INDIKACIJA}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-4 xl-col-4">
                      <h6 className="font-bold">{Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_NAPOMENA}</h6>
                      <div className="scrollable-wrapper">
                        <div className="p-3">
                          {listaIndikacija?.[0]?.napomena}
                          {listaIndikacija?.length === 0 && <div>{Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_NAPOMENA}</div>}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      }
    />
  );
}
