import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EnumPacijentPoljeKategorija from "../../../../infrastructure/system/EnumPacijentPoljeKategorija";
import KontrolaPravaPristupaService from "../../../../infrastructure/system/KontrolaPravaPristupaService";
import { DATE_FORMAT_2, formatDate, setInvalid } from "../../../../infrastructure/system/Utils";
import PacijentGrupnoObavestenjeServisDto from "../../../../model/pacijent/PacijentGrupnoObavestenjeServisDto";
import PacijentObavestenjeServisDto from "../../../../model/pacijent/PacijentObavestenjeServisDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";
import PacijentView from "../../../pacijent/PacijentPoljeView";
import AdministrativniPodaciLogical from "./AdministrativniPodaciLogical";

interface AdministrativniPodaciProps {
  pacijent?: PacijentReadDto;
  setPacijent?: React.Dispatch<React.SetStateAction<PacijentReadDto | undefined>>;
  fetchMedicinskiPodaciAndInformacijeOPacijentu: () => void;
}
export default function AdministrativniPodaci(props: AdministrativniPodaciProps) {
  const Labels = useLabels();
  const { pacijent, setPacijent, fetchMedicinskiPodaciAndInformacijeOPacijentu } = props;
  const { pristup, authData } = useContext(AppContext);
  const {
    pacijentChange,
    setPacijentChange,
    polList,
    updatePacijent,
    pacijentRef,
    pacijentLoading,
    administrativniPodaciLoading,
    setAdministrativniPodaciLoading,
    invalidFields,
    setInvalidFields,
    obavestenjeTipList,
    datumRef,
  } = AdministrativniPodaciLogical({
    pacijent,
    setPacijent,
  });
  const { hasPacijentUpdate } = KontrolaPravaPristupaService();

  return (
    <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
      <div className="col-9 flex flex-column hc-gap-3">
        <div className="flex col-12 p-0 align-items-center justify-content-between">
          <div className="align-items-start justify-content-start font-bold p-0">
            {Labels.PACIJENT_IME}
            {Labels.SPECIAL_CHAR_REQUIRED}
          </div>
          <div className="flex flex-column col-10 p-0 align-items-start justify-content-between">
            {!pacijentLoading ? (
              <>
                <InputText
                  className={"w-full " + (invalidFields?.ime && "p-invalid")}
                  value={pacijentChange?.ime ?? ""}
                  onChange={(e) => {
                    setPacijentChange({
                      ...pacijentChange!,
                      ime: e.target.value,
                    });
                  }}
                  disabled={!pristup || pacijentChange?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                  onBlur={() => {
                    setInvalid(setInvalidFields, "ime", pacijentChange?.ime);
                  }}
                />
                {invalidFields?.ime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
        <div className="flex col-12 p-0 align-items-center justify-content-between">
          <div className="font-bold">
            {Labels.PACIJENT_PREZIME}
            {Labels.SPECIAL_CHAR_REQUIRED}
          </div>
          <div className="flex flex-column col-10 p-0">
            {!pacijentLoading ? (
              <>
                <InputText
                  className={"w-full " + (invalidFields?.prezime && "p-invalid")}
                  value={pacijentChange?.prezime ?? ""}
                  onChange={(e) => {
                    setPacijentChange({
                      ...pacijentChange!,
                      prezime: e.target.value,
                    });
                  }}
                  disabled={!pristup || pacijentChange?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                  onBlur={() => {
                    setInvalid(setInvalidFields, "prezime", pacijentChange?.prezime);
                  }}
                />
                {invalidFields?.prezime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
        <div className="flex col-12 p-0 align-items-center justify-content-between">
          <div className="font-bold">{Labels.PACIJENT_IME_RODITELJA}</div>
          <div className="flex flex-column col-10 p-0">
            {!pacijentLoading ? (
              <>
                <InputText
                  className="w-full"
                  value={pacijentChange?.imeRoditelja ?? ""}
                  onChange={(e) => {
                    setPacijentChange({
                      ...pacijentChange!,
                      imeRoditelja: e.target.value,
                    });
                  }}
                  disabled={!pristup || pacijentChange?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                />
              </>
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
        <div className="flex col-12 p-0 align-items-center justify-content-between">
          <div className="font-bold">
            {Labels.PACIJENT_POL}
            {Labels.SPECIAL_CHAR_REQUIRED}
          </div>
          <div className="flex flex-row col-10 px-0 py-2 align-items-start justify-content-start">
            {!pacijentLoading ? (
              <>
                {" "}
                {polList?.map((pol: EnumBaseReadDto, key: number) => (
                  <div key={key} className="">
                    <RadioButton
                      className={"p-radiobutton " + (invalidFields?.pol && "p-invalid")}
                      inputId={pol?.sifra}
                      name="pol"
                      value={pol}
                      checked={pacijentChange?.polTrenutni?.sifra === pol.sifra}
                      onChange={(e) => {
                        setPacijentChange({ ...pacijentChange, polTrenutni: e.value });
                      }}
                      disabled={!pristup || pacijentChange?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                    />
                    <label className="cursor-pointer ml-2 mr-4" htmlFor={pol.sifra}>
                      {pol.naziv}
                    </label>
                  </div>
                ))}
                {invalidFields?.pol && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
        <div className="flex col-12 p-0 align-items-center justify-content-between">
          <div className="font-bold">
            {Labels.PACIJENT_DATUM_RODJENJA}
            {Labels.SPECIAL_CHAR_REQUIRED}
          </div>
          <div className="flex flex-column col-10 p-0 align-items-start justify-content-start">
            {!pacijentLoading ? (
              <>
                <CalendarComponent
                  ref={datumRef}
                  disabled={!pristup || pacijentChange?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                  value={pacijentChange?.vremeRodjenja}
                  onChange={(e: CalendarChangeEvent) => {
                    setPacijentChange({ ...pacijentChange, vremeRodjenja: formatDate(e.value as Date | undefined, DATE_FORMAT_2) });
                    setInvalidFields((prev) => ({ ...prev, vremeRodjenja: false }));
                    if (e.target.value) datumRef?.current?.hide();
                  }}
                  onClearButtonClick={() => {
                    setInvalidFields((prev) => ({ ...prev, vremeRodjenja: true }));
                    setPacijentChange({ ...pacijentChange, vremeRodjenja: undefined });
                  }}
                  className={"w-full " + (invalidFields?.vremeRodjenja && "p-invalid")}
                  placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                />
                {invalidFields?.vremeRodjenja && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
        <div className="flex col-12 p-0 align-items-center justify-content-between">
          <div className="align-items-start justify-content-start font-bold p-0">{Labels.PACIJENT_TELEFON}</div>
          <div className="col-10 p-0 align-items-center justify-content-start">
            {!pacijentLoading ? (
              <InputText
                className="w-full"
                value={pacijentChange?.telefon ?? ""}
                onChange={(e) => {
                  setPacijentChange({
                    ...pacijentChange!,
                    telefon: e.target.value,
                  });
                }}
                disabled={!pristup || pacijentChange?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
        <div className="flex col-12 p-0 align-items-center justify-content-start">
          <div className="flex col-2 align-items-start justify-content-start font-bold p-0">{Labels.PACIJENT_EMAIL}</div>
          <div className="flex col-10 p-0 align-items-center justify-content-start">
            {!pacijentLoading ? (
              <InputText
                className="w-full"
                value={pacijentChange?.email ?? ""}
                onChange={(e) => {
                  setPacijentChange({
                    ...pacijentChange!,
                    email: e.target.value,
                  });
                }}
                disabled={!pristup || pacijentChange?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
        <div className="flex col-12 p-0 align-items-center justify-content-between">
          <div className="font-bold">{Labels.PACIJENT_ADRESA}</div>
          <div className="flex col-10 p-0 align-items-center justify-content-start">
            {!pacijentLoading ? (
              <InputText
                className="w-full"
                value={pacijentChange?.adresa ?? ""}
                onChange={(e) => {
                  setPacijentChange({
                    ...pacijentChange!,
                    adresa: e.target.value,
                  });
                }}
                disabled={!pristup || pacijentChange?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
        <div className="flex col-12 p-0 align-items-center justify-content-between">
          <div className="font-bold">{Labels.PACIJENT_OBAVESTENJE_TIP_LIST}</div>
          <div className="col-10 p-0">
            {!pacijentLoading ? (
              <MultiSelect
                value={pacijentChange?.pacijentObavestenjeServisList?.map((pacijentObavestenjeServis: PacijentObavestenjeServisDto) => pacijentObavestenjeServis.obavestenjeTip) ?? ""}
                options={obavestenjeTipList ?? []}
                disabled={!pristup}
                onChange={(e) => {
                  setPacijentChange({
                    ...pacijentChange,
                    pacijentObavestenjeServisList: e.value.map((obavestenjeTip: EnumBaseReadDto) => ({ obavestenjeTip })),
                  });
                }}
                optionLabel="naziv"
                optionValue="obavestenjeTip.sifra"
                className="w-full"
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
        <div className="flex col-12 p-0 align-items-center justify-content-between">
          <div className="font-bold">{Labels.PACIJENT_GRUPNO_OBAVESTENJE_TIP_LIST}</div>
          <div className="col-10 p-0">
            {!pacijentLoading ? (
              <MultiSelect
                value={
                  pacijentChange?.pacijentGrupnoObavestenjeServisList?.map((pacijentGrupnoObavestenjeServis: PacijentGrupnoObavestenjeServisDto) => pacijentGrupnoObavestenjeServis.obavestenjeTip) ??
                  ""
                }
                options={obavestenjeTipList ?? []}
                disabled={!pristup}
                onChange={(e) => {
                  setPacijentChange({
                    ...pacijentChange,
                    pacijentGrupnoObavestenjeServisList: e.value.map((obavestenjeTip: EnumBaseReadDto) => ({ obavestenjeTip })),
                  });
                }}
                optionLabel="naziv"
                optionValue="obavestenjeTip.sifra"
                className="w-full"
              />
            ) : (
              <SkeletonInputItem />
            )}
          </div>
        </div>
        <PacijentView
          pacijentRef={pacijentRef}
          kategorijaEnum={EnumPacijentPoljeKategorija.ADMINISTRATIVNI_PODACI}
          pacijent={pacijent}
          setPoljePopunjenoLoading={setAdministrativniPodaciLoading}
          fetchMedicinskiPodaciAndInformacijeOPacijentu={fetchMedicinskiPodaciAndInformacijeOPacijentu}
        />
        <div className="flex col-12 p-0 align-items-center justify-content-end mt-2">
          <Button
            disabled={!pristup || administrativniPodaciLoading || pacijentLoading || pacijentChange?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
            label={Labels.BUTTON_SAVE}
            className="p-button-success"
            onClick={updatePacijent}
          />
        </div>
      </div>
    </div>
  );
}
