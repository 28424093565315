import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";
import KontaktCreateDto from "../../model/kontakt/KontaktCreateDto";
import KontaktDijagnozaReadDto from "../../model/kontakt/KontaktDijagnozaReadDto";
import KontaktPaketCreateDto from "../../model/kontakt/KontaktPaketCreateDto";
import KontaktPaketUpdateDto from "../../model/kontakt/KontaktPaketUpdateDto";
import KontaktReadDto from "../../model/kontakt/KontaktReadDto";
import KontaktSearchDto from "../../model/kontakt/KontaktSearchDto";
import KontaktUcesnikCreateDto from "../../model/kontakt/KontaktUcesnikCreateDto";
import KontaktUslugaReadDto from "../../model/kontakt/KontaktUslugaReadDto";
import KontaktUslugaSmallCreateDto from "../../model/kontakt/KontaktUslugaSmallCreateDto";
import KontaktUtrosakReadDto from "../../model/kontakt/KontaktUtrosakReadDto";
import KontaktNaplataReadDto from "../../model/naplata/KontaktNaplataReadDto";
import { NaplataKontaktListSearchFilters } from "../../model/naplata/NaplataKontaktListSearchFilters";

interface KontaktControllerType {
  axiosGetKontaktByID: (id: number) => Promise<AxiosResponse<any>>;
  axiosUpdateKontakt: (kontaktChange: KontaktReadDto) => Promise<AxiosResponse<any>>;
  axiosGetDijagnozaListByKontaktID: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosGetPrethodneDijagnozeListByKontaktID: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateDijagnozu: (kontaktId: number, dijagnozaChange: KontaktDijagnozaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteDijagnozu: (kontaktId: number, dijagnozaDelete: KontaktDijagnozaReadDto) => Promise<AxiosResponse<any>>;
  axiosCreateDijagnozu: (kontaktId: number, dijagnozaCreate: KontaktDijagnozaReadDto) => Promise<AxiosResponse<any>>;
  axiosPostCreateKontakt: (kontaktChange: KontaktCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetUslugaListByKontaktID: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateUslugu: (kontaktId: number, uslugaChange: KontaktUslugaReadDto, unsafelyExecute?: boolean) => Promise<AxiosResponse<any>>;
  axiosDeleteUslugu: (kontaktId: number, uslugaChange: KontaktUslugaReadDto) => Promise<AxiosResponse<any>>;
  axiosCreateUslugu: (kontaktId: number, uslugaCreate: KontaktUslugaReadDto, unsafelyExecute?: boolean) => Promise<AxiosResponse<any>>;
  axiosGetPopunjenFormularList: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosGetPopunjenFormular: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosGetKontaktUcesnikByKontaktID: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteKontaktUcensikByKontaktIDAndKontaktUcesnikID: (kontaktId: number, kontaktUcesnikId: number) => Promise<AxiosResponse<any>>;
  axiosPostKontaktUcesnik: (kontaktUcesnikCreate: KontaktUcesnikCreateDto) => Promise<AxiosResponse<any>>;
  axiosKontaktSearch: (searchObject: KontaktSearchDto) => Promise<AxiosResponse<any>>;
  axiosDeleteKontakt: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosGetUtrosakListByKontaktID: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateUtrosak: (kontaktId: number, utrosakChange: KontaktUtrosakReadDto, unsafelyExecute?: boolean) => Promise<AxiosResponse<any>>;
  axiosDeleteUtrosak: (kontaktId: number, utrosakChange: KontaktUtrosakReadDto) => Promise<AxiosResponse<any>>;
  axiosCreateUtrosak: (kontaktId: number, utrosakCreate: KontaktUtrosakReadDto, unsafelyExecute?: boolean) => Promise<AxiosResponse<any>>;
  axiosGetPaketListByKontaktId: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosPostKontaktPaket: (paket: KontaktPaketCreateDto | undefined, kontaktId: number, unsafelyExecute?: boolean) => Promise<AxiosResponse<any>>;
  axiosDeletePaketFromKontakt: (kontaktId: number, kontaktPaketId: number) => Promise<AxiosResponse<any>>;
  axiosUpdatePaketFromKontakt: (kontaktId: number, kontaktPaketId: number, paketChange: KontaktPaketUpdateDto, unsafelyExecute?: boolean) => Promise<AxiosResponse<any>>;
  axiosKontaktSearchSimple: (searchObject: KontaktSearchDto) => Promise<AxiosResponse<any>>;
  axiosGetKontaktNaplataList: (params: { page?: number; size?: number } & NaplataKontaktListSearchFilters) => Promise<AxiosResponse<CommonResponseDto<KontaktNaplataReadDto[]>>>;
  axiosGetKontaktNaplataListCount: (searchFilters: any) => Promise<AxiosResponse<CommonResponseDto<number>>>;
  axiosCreateKontaktUslugaFromZakazanTerminUsluga: (kontaktId: number, kontaktUslugaCreateDtoList: Array<KontaktUslugaSmallCreateDto>, unsafelyExecute?: boolean) => Promise<AxiosResponse<any, any>>;
}

export function KontaktController(): KontaktControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetKontaktByID = (id: number) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/${id}`, axiosConfig(authData!.token));
  };

  const axiosUpdateKontakt = (kontaktChange: KontaktReadDto) => {
    return axios.put(`${Endpoint.KONTAKT_LIST}/${kontaktChange.id}`, kontaktChange, axiosConfig(authData!.token));
  };

  const axiosPostCreateKontakt = (kontaktChange: KontaktCreateDto) => {
    return axios.post(Endpoint.KONTAKT_LIST, kontaktChange, axiosConfig(authData!.token));
  };

  const axiosGetDijagnozaListByKontaktID = (kontaktId: number) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/${kontaktId}/dijagnoza-list`, axiosConfig(authData!.token));
  };

  const axiosGetPrethodneDijagnozeListByKontaktID = (kontaktId: number) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/${kontaktId}/prethodna-dijagnoza-list`, axiosConfig(authData!.token));
  };

  const axiosUpdateDijagnozu = (kontaktId: number, dijagnozaChange: KontaktDijagnozaReadDto) => {
    return axios.put(`${Endpoint.KONTAKT_LIST}/${kontaktId}/dijagnoza-list/${dijagnozaChange.id}`, dijagnozaChange, axiosConfig(authData!.token));
  };

  const axiosDeleteDijagnozu = (kontaktId: number, dijagnozaDelete: KontaktDijagnozaReadDto) => {
    return axios.delete(`${Endpoint.KONTAKT_LIST}/${kontaktId}/dijagnoza-list/${dijagnozaDelete.id}`, axiosConfig(authData!.token));
  };

  const axiosCreateDijagnozu = (kontaktId: number, dijagnozaCreate: KontaktDijagnozaReadDto) => {
    return axios.post(`${Endpoint.KONTAKT_LIST}/${kontaktId}/dijagnoza-list`, dijagnozaCreate ? dijagnozaCreate : {}, axiosConfig(authData!.token));
  };

  const axiosGetUslugaListByKontaktID = (kontaktId: number) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/${kontaktId}/usluga-list`, axiosConfig(authData!.token));
  };

  const axiosUpdateUslugu = (kontaktId: number, uslugaChange: KontaktUslugaReadDto, unsafelyExecute?: boolean) => {
    return axios.put(`${Endpoint.KONTAKT_LIST}/${kontaktId}/usluga-list/${uslugaChange.id}`, uslugaChange, axiosConfig(authData!.token, { unsafelyExecute }));
  };

  const axiosDeleteUslugu = (kontaktId: number, uslugaDelete: KontaktUslugaReadDto) => {
    return axios.delete(`${Endpoint.KONTAKT_LIST}/${kontaktId}/usluga-list/${uslugaDelete.id}`, axiosConfig(authData!.token));
  };

  const axiosCreateUslugu = (kontaktId: number, uslugaCreate: KontaktUslugaReadDto, unsafelyExecute?: boolean) => {
    return axios.post(`${Endpoint.KONTAKT_LIST}/${kontaktId}/usluga-list`, uslugaCreate ? uslugaCreate : {}, axiosConfig(authData!.token, { unsafelyExecute }));
  };

  const axiosCreateKontaktUslugaFromZakazanTerminUsluga = (kontaktId: number, kontaktUslugaCreateDtoList: Array<KontaktUslugaSmallCreateDto>, unsafelyExecute?: boolean) => {
    return axios.post(`${Endpoint.KONTAKT_LIST}/${kontaktId}/planned-usluga-list-to-realized`, kontaktUslugaCreateDtoList, axiosConfig(authData!.token, { unsafelyExecute }));
  };

  const axiosGetPopunjenFormularList = (kontaktId: number) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/${kontaktId}/formular-popunjen-list`, axiosConfig(authData!.token));
  };

  const axiosGetPopunjenFormular = (formularPopunjenId: number) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/formular-popunjen-list/${formularPopunjenId}`, axiosConfig(authData!.token));
  };

  const axiosGetKontaktUcesnikByKontaktID = (kontaktId: number) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/${kontaktId}/ucesnik-list`, axiosConfig(authData!.token));
  };

  const axiosDeleteKontaktUcensikByKontaktIDAndKontaktUcesnikID = (kontaktId: number, kontaktUcesnikId: number) => {
    return axios.delete(`${Endpoint.KONTAKT_LIST}/${kontaktId}/ucesnik-list/${kontaktUcesnikId}`, axiosConfig(authData!.token));
  };

  const axiosPostKontaktUcesnik = (kontaktUcesnikCreate: KontaktUcesnikCreateDto) => {
    return axios.post(`${Endpoint.KONTAKT_LIST}/ucesnik-list`, kontaktUcesnikCreate ? kontaktUcesnikCreate : {}, axiosConfig(authData!.token));
  };

  const axiosKontaktSearch = (searchObject: KontaktSearchDto) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/kontakt-search`, axiosConfig(authData!.token, searchObject));
  };

  const axiosDeleteKontakt = (kontaktId: number) => {
    return axios.delete(`${Endpoint.KONTAKT_LIST}/${kontaktId}`, axiosConfig(authData!.token));
  };

  const axiosGetUtrosakListByKontaktID = (kontaktId: number) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/${kontaktId}/proizvod-list`, axiosConfig(authData!.token));
  };

  const axiosUpdateUtrosak = (kontaktId: number, utrosakChange: KontaktUtrosakReadDto, unsafelyExecute?: boolean) => {
    return axios.put(`${Endpoint.KONTAKT_LIST}/${kontaktId}/proizvod-list/${utrosakChange.id}`, utrosakChange, axiosConfig(authData!.token, { unsafelyExecute }));
  };

  const axiosDeleteUtrosak = (kontaktId: number, utrosakDelete: KontaktUtrosakReadDto) => {
    return axios.delete(`${Endpoint.KONTAKT_LIST}/${kontaktId}/proizvod-list/${utrosakDelete.id}`, axiosConfig(authData!.token));
  };

  const axiosCreateUtrosak = (kontaktId: number, utrosakCreate: KontaktUtrosakReadDto, unsafelyExecute?: boolean) => {
    return axios.post(`${Endpoint.KONTAKT_LIST}/${kontaktId}/proizvod-list`, utrosakCreate ? utrosakCreate : {}, axiosConfig(authData!.token, { unsafelyExecute }));
  };

  const axiosGetPaketListByKontaktId = (kontaktId: number) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/${kontaktId}/paket-list`, axiosConfig(authData!.token));
  };

  const axiosPostKontaktPaket = (paket: KontaktPaketCreateDto | undefined, kontaktId: number, unsafelyExecute?: boolean) => {
    return axios.post(`${Endpoint.KONTAKT_LIST}/${kontaktId}/paket-list`, paket ? paket : {}, axiosConfig(authData!.token, { unsafelyExecute }));
  };

  const axiosDeletePaketFromKontakt = (kontaktId: number, kontaktPaketId: number) => {
    return axios.delete(`${Endpoint.KONTAKT_LIST}/${kontaktId}/paket-list/${kontaktPaketId}`, axiosConfig(authData!.token));
  };

  const axiosUpdatePaketFromKontakt = (kontaktId: number, kontaktPaketId: number, paketChange: KontaktPaketUpdateDto, unsafelyExecute?: boolean) => {
    return axios.put(`${Endpoint.KONTAKT_LIST}/${kontaktId}/paket-list/${kontaktPaketId}`, paketChange, axiosConfig(authData!.token, { unsafelyExecute }));
  };

  const axiosKontaktSearchSimple = (searchObject: KontaktSearchDto) => {
    return axios.get(`${Endpoint.KONTAKT_LIST}/kontakt-search-simple`, axiosConfig(authData!.token, searchObject));
  };

  const axiosGetKontaktNaplataListCount = (searchFilters: NaplataKontaktListSearchFilters) => {
    return axios.get(Endpoint.KONTAKT_NAPLATA_LIST_COUNT, axiosConfig(authData!.token, searchFilters));
  };

  const axiosGetKontaktNaplataList = (params: { page?: number; size?: number } & NaplataKontaktListSearchFilters) => {
    return axios.get(Endpoint.KONTAKT_NAPLATA_LIST, axiosConfig(authData!.token, params));
  };

  return {
    axiosGetKontaktByID,
    axiosUpdateKontakt,
    axiosPostCreateKontakt,
    axiosGetDijagnozaListByKontaktID,
    axiosGetPrethodneDijagnozeListByKontaktID,
    axiosUpdateDijagnozu,
    axiosDeleteDijagnozu,
    axiosCreateDijagnozu,
    axiosGetUslugaListByKontaktID,
    axiosUpdateUslugu,
    axiosDeleteUslugu,
    axiosCreateUslugu,
    axiosCreateKontaktUslugaFromZakazanTerminUsluga,
    axiosGetPopunjenFormularList,
    axiosGetPopunjenFormular,
    axiosGetKontaktUcesnikByKontaktID,
    axiosDeleteKontaktUcensikByKontaktIDAndKontaktUcesnikID,
    axiosPostKontaktUcesnik,
    axiosKontaktSearch,
    axiosDeleteKontakt,
    axiosGetUtrosakListByKontaktID,
    axiosUpdateUtrosak,
    axiosDeleteUtrosak,
    axiosCreateUtrosak,
    axiosGetPaketListByKontaktId,
    axiosPostKontaktPaket,
    axiosDeletePaketFromKontakt,
    axiosUpdatePaketFromKontakt,
    axiosKontaktSearchSimple,
    axiosGetKontaktNaplataListCount,
    axiosGetKontaktNaplataList,
  };
}
