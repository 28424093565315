import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Link } from "react-router-dom";
import { useLabels } from "../../../Store";
import EnumPacijentInfoPrikaz from "../../../infrastructure/system/EnumPacijentInfoPrikaz";
import EnumPol from "../../../infrastructure/system/EnumPol";
import Images from "../../../infrastructure/system/Images";
import { DATE_FORMAT, getFieldValue, showPatientFullName, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import PacijentPoljePopunjeno from "../../../model/pacijent-polje/PacijentPoljePopunjeno";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import ProtokolBrojReadDto from "../../../model/protokol/ProtokolBrojReadDto";
import SkeletonPacijentInfoHorizontal from "../../app/skeleton/SkeletonPacijentInfoHorizontal";
import SkeletonPacijentInfoVertical from "../../app/skeleton/SkeletonPacijentInfoVertical";
import AvatarUpload from "../../avatar-upload/AvatarUpload";
import PacijentInfoLogical from "./PacijentInfoLogical";

interface PacijentInfoType {
  pacijent?: PacijentReadDto;
  setPacijent?: React.Dispatch<React.SetStateAction<PacijentReadDto | undefined>>;
  prikaz?: string;
  showVerticalPacijentInfo?: boolean;
  pacijentLoading?: boolean;
  protokolBroj?: ProtokolBrojReadDto;
  medicinskiPodaci: Array<PacijentPoljePopunjeno>;
  informacijeOPacijentuList?: Array<PacijentPoljePopunjeno> | undefined;
  pacijentPoljePopunjenoListLoading?: boolean;
}

export default function PacijentInfo(props: PacijentInfoType) {
  const { pacijent, setPacijent, prikaz, showVerticalPacijentInfo, pacijentLoading, protokolBroj, medicinskiPodaci, informacijeOPacijentuList, pacijentPoljePopunjenoListLoading } = props;
  const Labels = useLabels();
  const { showPacijentInfo, setShowPacijentInfo, showOpsteMedicinskeInfo, setShowOpsteMedicinskeInfo, makeGrid, patchPacijentProfilnaSlika, pacijentAge } = PacijentInfoLogical({
    pacijent,
    setPacijent
  });

  const getPol = (pacijent: PacijentReadDto) => {
    switch (pacijent.polTrenutni.sifra) {
      case EnumPol.MUSKI:
        return Labels.LABEL_POL_MUSKO;
      case EnumPol.ZENSKI:
        return Labels.LABEL_POL_ZENSKO;
      case EnumPol.NIJE_UNET:
        return Labels.SLASH;
    }
  };

  const getGenderImage = () => {
    switch (pacijent?.polTrenutni.sifra) {
      case EnumPol.MUSKI:
        return Images.GENDER_MALE;
      case EnumPol.ZENSKI:
        return Images.GENDER_FEMALE;
      default:
        return undefined;
    }
  };

  const editProfilePicture = (img: string) => patchPacijentProfilnaSlika(img);

  const datumRodjenja = (className: string) => {
    return <div className={className}>{pacijent?.vremeRodjenja ? `${moment(pacijent.vremeRodjenja).format(DATE_FORMAT)} (${pacijentAge} ${Labels.LABEL_GODINA})` : "/"}</div>;
  };

  if (prikaz === EnumPacijentInfoPrikaz.HORIZONTALNO && pacijent) {
    return (
      <div className="flex pl-2">
        <div className="flex justify-content-center">
          <AvatarUpload ime={pacijent.ime} prezime={pacijent.prezime} imagePath={pacijent.profilnaSlika} saveCB={editProfilePicture} className="image" />
        </div>
        <div className="ml-3">
          <Link to={`/pacijent-dosije/${pacijent.id}`}>
            <span className="ml-1 text-lg ">{showPatientFullName(pacijent)}</span>
          </Link>
          <div className="flex flex-row white-space-nowrap">
            <div className="flex flex-row ">
              {getGenderImage() && <img src={getGenderImage()} alt="medical_kit" className="pol-image" />}
              <div className="font-bold mx-2 flex align-items-center justify-content-center">{Labels.POL}</div>
              <div className="mr-2 flex align-items-center justify-content-center">{getPol(pacijent)}</div>
              <div className="border-right-1 border-400 mr-1"></div>
            </div>
            <div className="flex flex-row ">
              <img src={Images.CALENDAR} alt="medical_kit" className="ml-2" />
              <div className="font-bold mx-2 flex align-items-center justify-content-center ">{Labels.DATUM_RODJENJA}</div>
              {datumRodjenja("mr-2 flex align-items-center justify-content-center")}
              <div className="border-right-1 border-400 mr-1" />
            </div>
            {protokolBroj && (
              <div className="flex flex-row ">
                <div className="font-bold mx-2 flex align-items-center justify-content-center ">{Labels.PROTOKOL_BROJ}</div>
                <div className="mr-2 flex align-items-center justify-content-center">{protokolBroj?.kompozitniPrikaz}</div>
                <div className="border-right-1 border-400 mr-1" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {showVerticalPacijentInfo && (
        <Card className="layout-pacijent-info">
          {!pacijentLoading && pacijent && !pacijentPoljePopunjenoListLoading && (
            <>
              <div className="flex justify-content-center">
                <AvatarUpload ime={pacijent.ime} prezime={pacijent.prezime} imagePath={pacijent.profilnaSlika} saveCB={editProfilePicture} className="horizontal-view-image" />
              </div>
              <div className="text-center mt-2 text-xl">{showPatientFullName(pacijent)}</div>
              {datumRodjenja("text-center my-2")}
              <div className="text-center">
                <Button
                  label={Labels.ZAKAZI_PREGLED}
                  icon="pi pi-calendar"
                  onClick={() => {
                    window.open(`/zakazivanje-pregleda/${pacijent.id}`, "_blank", "noopener,noreferrer");
                  }}
                  className="mb-4"
                />
              </div>
              <Accordion activeIndex={0}>
                <AccordionTab header={Labels.LABEL_PACIJENT_INFORMACIJE}>
                  {informacijeOPacijentuList?.map((element: PacijentPoljePopunjeno, index: number) => (
                    <div key={index}>
                      <p className="font-bold mb-0">{element.pacijentPolje.naziv}</p>
                      <p className="mb-2">{getFieldValue(element)}</p>
                    </div>
                  ))}
                </AccordionTab>
                <AccordionTab header={Labels.OPSTI_MEDICINSKI_PODACI}>
                  {medicinskiPodaci?.map((element: PacijentPoljePopunjeno, index: number) => (
                    <div key={index}>
                      <p className="font-bold mb-0">{element.pacijentPolje.naziv}</p>
                      <p className="mb-2">{getFieldValue(element)}</p>
                    </div>
                  ))}
                </AccordionTab>
              </Accordion>
            </>
          )}
          {(pacijentLoading || pacijentPoljePopunjenoListLoading) && <SkeletonPacijentInfoVertical size={4} />}
        </Card>
      )}
      {!showVerticalPacijentInfo && !pacijentPoljePopunjenoListLoading && !pacijentLoading && pacijent && (
        <div className="flex flex-column col-12 justify-content-start align-items-center p-0">
          <div className="flex col-12 justify-content-start align-items-center px-3 py-0">
            <AvatarUpload ime={pacijent.ime} prezime={pacijent.prezime} imagePath={pacijent.profilnaSlika} saveCB={editProfilePicture} className="horizontal-view-image" />
            <div className="ml-2">
              <div className="text-center text-xl">{showPatientFullName(pacijent)}</div>
              {datumRodjenja("text-center")}
            </div>
            <Button
              label={Labels.LABEL_PACIJENT_INFORMACIJE}
              tooltip={Labels.TOOLTIP_PACIJENT_INFORMACIJE}
              tooltipOptions={tooltipOptionsBottom}
              className="link-like-button ml-4"
              icon={`pi ${showPacijentInfo ? "pi-angle-right" : "pi-angle-down"}`}
              onClick={() => {
                setShowPacijentInfo(!showPacijentInfo);
                setShowOpsteMedicinskeInfo(false);
              }}
            />
            <Button
              label={Labels.OPSTI_MEDICINSKI_PODACI}
              tooltip={Labels.TOOLTIP_OPSTI_MEDICINSKI_PODACI}
              tooltipOptions={tooltipOptionsBottom}
              className="link-like-button ml-4"
              icon={`pi ${showOpsteMedicinskeInfo ? "pi-angle-right" : "pi-angle-down"}`}
              onClick={() => {
                setShowOpsteMedicinskeInfo(!showOpsteMedicinskeInfo);
                setShowPacijentInfo(false);
              }}
            />
            <div className="flex">
              <Button
                label={Labels.ZAKAZI_PREGLED}
                icon="pi pi-calendar"
                onClick={() => {
                  window.open(`/zakazivanje-pregleda/${pacijent.id}`, "_blank", "noopener,noreferrer");
                }}
                className="ml-4"
              />
            </div>
          </div>
          <div className="flex col-12 px-3 py-1">
            {showPacijentInfo && (
              <div className="info-panel flex">
                {makeGrid(informacijeOPacijentuList ?? [])?.map((column: Array<PacijentPoljePopunjeno>, idx: number) => (
                  <div className="flex flex-column mr-5" key={idx}>
                    {column.map((polje: PacijentPoljePopunjeno, index: number) => {
                      return (
                        <div key={index}>
                          {polje && polje?.pacijentPolje && (
                            <>
                              <p className="font-bold mb-0">{polje?.pacijentPolje?.naziv ?? ""}</p>
                              <p className="mb-2">{getFieldValue(polje ?? null)}</p>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            )}
            {showOpsteMedicinskeInfo && (
              <div className="info-panel flex">
                {makeGrid(medicinskiPodaci ?? [])?.map((column: Array<PacijentPoljePopunjeno>, idx: number) => (
                  <div className="flex flex-column mr-5" key={idx}>
                    {column.map((polje: PacijentPoljePopunjeno, index: number) => {
                      return (
                        <div key={index}>
                          {polje && polje?.pacijentPolje && (
                            <>
                              <p className="font-bold mb-0">{polje?.pacijentPolje?.naziv ?? ""}</p>
                              <p className="mb-2">{getFieldValue(polje ?? null)}</p>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {!showVerticalPacijentInfo && (pacijentLoading || pacijentPoljePopunjenoListLoading) && <SkeletonPacijentInfoHorizontal size={3} />}
    </>
  );
}
