import { useEffect, useRef } from "react";

function useOutsideClick(ref: any, onClick: Function) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClick();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClick]);
}

export default function OutsideClickableComponent(props: any) {
    const { onClick } = props;
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, onClick);

    return <div ref={wrapperRef}>{props.children}</div>;
}