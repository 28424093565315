import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { Navigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import { setInvalid, tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import PoljeTipEnumStavkaReadDto from "../../../model/poljeTip/PoljeTipEnumStavkaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CrudPoljeTipLogical from "./CrudPoljeTipLogical";

export default function CrudPoljeTip() {
  const {
    isDisabled,
    navigate,
    poljeTipEnumStavkaList,
    onPageFormTable,
    first,
    tableRows,
    exportData,
    poljeTipId,
    poljeTipChange,
    breadCrumbItems,
    setPoljeTipChange,
    setIndex,
    index,
    poljeTipOperation,
    onCancel,
    onCreate,
    onDelete,
    onUpdate,
    location,
    componentTypeOptions,
    poljeTipLoading,
    poljeTipOptionsDataLoading,
    tipPoljaEnumStavkaLoading,
    tipPoljaTipKomponenteSifra,
    invalidFields,
    setInvalidFields,
  } = CrudPoljeTipLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const dt = useRef<any>(null);
  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();

  if (!poljeTipId && !location.state) {
    return <Navigate to="/polje-tip-list" />;
  }

  const openPoljeTipEnumStavka = (entityOperation: string, poljeTipEnumStavka?: PoljeTipEnumStavkaReadDto) => {
    const poljeTipEnumStavkaId = entityOperation !== EntityOperation.CREATE ? poljeTipEnumStavka?.id : "";
    navigate(`/polje-tip/${poljeTipId}/polje-tip-enum-stavka/${poljeTipEnumStavkaId}`, {
      state: { poljeTipEnumStavkaOperation: entityOperation, poljeTipOperation: poljeTipOperation },
    });
  };

  const leftContentsToolbar = () => (
    <>
      {poljeTipOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          disabled={!pristup || poljeTipOptionsDataLoading}
          tooltip={Labels.LABEL_CREATE_FIELD_TYPE}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {poljeTipOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || poljeTipOptionsDataLoading || poljeTipLoading}
          tooltip={Labels.LABEL_UPDATE_FIELD_TYPE}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {poljeTipOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || poljeTipOptionsDataLoading || poljeTipLoading}
          tooltip={Labels.LABEL_DELETE_FIELD_TYPE}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={onCancel} />
    </>
  );

  const leftContents = () => (
    <>
      {!isDisabled && (
        <Button
          className="p-button-success"
          label={Labels.BUTTON_ADD_POLJE_TIP_ENUM_STAVKA}
          tooltip={Labels.TOOLTIP_CREATE_POLJE_TIP_ENUM_STAVKA}
          tooltipOptions={tooltipOptionsBottom}
          disabled={!pristup || poljeTipOperation !== EntityOperation.UPDATE}
          onClick={() => {
            openPoljeTipEnumStavka(EntityOperation.CREATE);
          }}
        />
      )}
    </>
  );

  const rightContents = () => (
    <>
      <ExportData disabled={!pristup || tipPoljaEnumStavkaLoading} data={exportData} name={Labels.POLJE_TIP_LIST} dt={dt} />
    </>
  );

  const tipKomponenteSetAndSetRadioList = [EnumTipKomponente.SET, EnumTipKomponente.SET_RADIO, EnumTipKomponente.SET_MULTISELECT];
  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.HEADER_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.HEADER_PRIKAZNI_REDOSLED, filter: true, sortrable: true },
    { columnName: Labels.HEADER_NAPOMENA, filter: true, sortrable: true },
  ];

  const buttonListTemplate = (rowPoljeTipEnumStavka: PoljeTipEnumStavkaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.POLJE_TIP_ENUM_STAVKA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          disabled={poljeTipOperation !== EntityOperation.UPDATE}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowPoljeTipEnumStavka && openPoljeTipEnumStavka(EntityOperation.UPDATE, rowPoljeTipEnumStavka)}
        />
        <Button
          type="button"
          tooltip={Labels.POLJE_TIP_ENUM_STAVKA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          disabled={!pristup || poljeTipOperation !== EntityOperation.UPDATE}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowPoljeTipEnumStavka && openPoljeTipEnumStavka(EntityOperation.DELETE, rowPoljeTipEnumStavka)}
        />
      </div>
    );
  };

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.POLJE_TIP}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.LABEL_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!poljeTipLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={poljeTipChange?.sifra ?? ""}
                        onChange={(e) => {
                          setPoljeTipChange({
                            ...poljeTipChange!,
                            sifra: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", poljeTipChange?.sifra);
                        }}
                        className={invalidFields?.sifra && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.LABEL_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!poljeTipLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={poljeTipChange?.naziv ?? ""}
                        onChange={(e) => {
                          setPoljeTipChange({
                            ...poljeTipChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", poljeTipChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.POLJE_TIP_KOMPONENTA_TIP}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!poljeTipLoading && !poljeTipOptionsDataLoading ? (
                    <>
                      <Dropdown
                        disabled={!pristup || isDisabled}
                        options={componentTypeOptions}
                        optionLabel="naziv"
                        optionValue="sifra"
                        filter
                        showClear
                        value={poljeTipChange?.tipKomponenteEnum?.sifra ?? ""}
                        onChange={(e) => {
                          setPoljeTipChange({
                            ...poljeTipChange!,
                            tipKomponenteEnum: {
                              sifra: componentTypeOptions!.find((x) => x.sifra === e.target.value)?.sifra!,
                            },
                          });
                          if (e.target.value) {
                            setInvalidFields((prev) => ({ ...prev, tipKomponente: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, tipKomponente: true }));
                          }
                        }}
                        className={invalidFields?.tipKomponente && "p-invalid"}
                        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      />
                      {invalidFields?.tipKomponente && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.LABEL_DESCRIPTION}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0 mt-2 mb-2">
                  {!poljeTipLoading ? (
                    <InputTextarea
                      rows={3}
                      cols={5}
                      disabled={!pristup || isDisabled}
                      value={poljeTipChange?.opis ?? ""}
                      onChange={(e) => {
                        setPoljeTipChange({
                          ...poljeTipChange!,
                          opis: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.LABEL_NOTE}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0 mt-2 mb-2">
                  {!poljeTipLoading ? (
                    <InputTextarea
                      rows={3}
                      cols={5}
                      disabled={!pristup || isDisabled}
                      value={poljeTipChange?.napomena ?? ""}
                      onChange={(e) => {
                        setPoljeTipChange({
                          ...poljeTipChange!,
                          napomena: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.LABEL_MIN}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!poljeTipLoading ? (
                    <InputNumber
                      disabled={!pristup || isDisabled}
                      value={poljeTipChange?.minimum ?? undefined}
                      onChange={(e) => {
                        setPoljeTipChange({
                          ...poljeTipChange!,
                          minimum: e.value ?? undefined,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.LABEL_MAX}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!poljeTipLoading ? (
                    <InputNumber
                      disabled={!pristup || isDisabled}
                      value={poljeTipChange?.maksimum ?? undefined}
                      onChange={(e) => {
                        setPoljeTipChange({
                          ...poljeTipChange!,
                          maksimum: e.value ?? undefined,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.LABEL_DECIMALS}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!poljeTipLoading ? (
                    <InputNumber
                      disabled={!pristup || isDisabled}
                      value={poljeTipChange?.decimale ?? undefined}
                      onChange={(e) => {
                        setPoljeTipChange({
                          ...poljeTipChange!,
                          decimale: e.value ?? undefined,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.LABEL_FORMAT}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!poljeTipLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={poljeTipChange?.format ?? ""}
                      onChange={(e) => {
                        setPoljeTipChange({
                          ...poljeTipChange!,
                          format: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.LABEL_UNIT}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!poljeTipLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={poljeTipChange?.jedinica ?? ""}
                      onChange={(e) => {
                        setPoljeTipChange({
                          ...poljeTipChange!,
                          jedinica: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
          </TabPanel>
          <TabPanel
            header={Labels.POLJE_TIP_ENUM_STAVKA_LIST}
            disabled={
              poljeTipOperation === EntityOperation.CREATE ||
              !tipKomponenteSetAndSetRadioList.includes(tipPoljaTipKomponenteSifra ?? "") ||
              !tipKomponenteSetAndSetRadioList.includes(poljeTipChange?.tipKomponenteEnum?.sifra ?? "")
            }
          >
            <div className="layout-generic-content-list">
              <Toolbar left={leftContents} right={rightContents} />
              <div>
                {tipPoljaEnumStavkaLoading ? (
                  <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={!isDisabled} />
                ) : (
                  <DataTable
                    ref={dt}
                    filterDisplay="row"
                    value={poljeTipEnumStavkaList}
                    paginator
                    first={first}
                    paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
                    rows={tableRows}
                    onPage={(e) => {
                      onPageFormTable(e.rows, e.first);
                    }}
                    emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                    exportFilename={Labels.POLJE_TIP_ENUM_STAVKA_LIST}
                    rowHover
                    stripedRows
                  >
                    <Column field={"naziv"} header={Labels.HEADER_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    <Column field={"sifra"} header={Labels.HEADER_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    <Column field={"prikazniRedosled"} header={Labels.HEADER_PRIKAZNI_REDOSLED} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    <Column field={"napomena"} header={Labels.HEADER_NAPOMENA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    {!isDisabled && <Column body={buttonListTemplate} />}
                  </DataTable>
                )}
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
