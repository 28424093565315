import actions from "./actionsZakazaniIzvestaj";
import { InitialStateZakazaniIzvestajType } from "./initialStateZakazaniIzvestaj";

export default function reducerZakazaniIzvestaji(state: InitialStateZakazaniIzvestajType, action: { type: actions; data?: any }) {
  switch (action.type) {
    case actions.FETCH_PARAMS: {
      return {
        ...state,
        paramsValue: action.data.parameters,
        editSelected: action.data.selected,
        selectedIzvestajForEdit: action.data.selectedForEdit,
        formParams: action.data.formParams,
      };
    }
    case actions.ADD_PARAM_VALUE: {
      return {
        ...state,
        paramsValue: {
          ...state.paramsValue,
          [action.data.id]: action.data.value,
        },
      };
    }
    case actions.CANCEL_EDIT: {
      return {
        ...state,
        paramsValue: [],
        editSelected: false,
        selectedIzvestajForEdit: undefined,
      };
    }
    case actions.SET_IZVESTAJ_LIST: {
      return {
        ...state,
        izvestajList: action.data,
      };
    }
    case actions.SET_IZVESTAJ_LIST_LOADING: {
      return {
        ...state,
        izvestajListLoading: action.data,
      };
    }
    case actions.FETCH_DATA: {
      const selectedStatistickiIzvestaj = action.data.selectedIzvestaj ?? undefined;
      const paramsLoading = selectedStatistickiIzvestaj !== state.selectedIzvestaj;
      return {
        ...state,
        izvestajList: action.data.izvestajList,
        selectedIzvestaj: selectedStatistickiIzvestaj,
        onlyMine: action.data.onlyMine,
        paramsLoading: paramsLoading,
        paramsValue: [],
      };
    }
    case actions.EMPTY_FIELDS: {
      return {
        ...state,
        emptyFields: action.data,
      };
    }
    default:
      return state;
  }
}
