import { Skeleton } from "primereact/skeleton";

interface SkeletonPacijentInfoHorizontalType {
  size?: number;
}

export default function SkeletonPacijentInfoHorizontal(props: SkeletonPacijentInfoHorizontalType) {
  const { size } = props;

  return (
    <div className="flex col-12 align-items-center justify-content-between p-0">
      <Skeleton height="30px" shape="circle" borderRadius="35px" size="70px" className="mr-2" />
      {[...Array(size ?? 1).keys()].map((j: number) => (
        <Skeleton key={j} height="30px" className="col flex m-2" />
      ))}
    </div>
  );
}
