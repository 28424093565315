import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { formatPrice, tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import BrojNalogaReadDto from "../../../../model/licenca/broj-naloga/BrojNalogaReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import BrojNalogaListLogical from "./BrojNalogaListLogical";

export default function BrojNalogaListView() {
  const { brojNalogaSearchData, changeBrojNalogaSearchData, searchBrojNalogaByEnter, fetchData, brojNalogaList, brojNalogaLoading, firstBrojNaloga, brojNalogaRows, onPageBrojNaloga, openBrojNaloga } =
    BrojNalogaListLogical();
  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const leftToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BROJ_NALOGA_BUTTON_DODAJ}
          tooltip={Labels.BROJ_NALOGA_TITLE_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openBrojNaloga(EntityOperation.CREATE);
          }}
        />
        <span className="p-float-label mt-4 sm:mt-4 md:mt-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={brojNalogaSearchData?.searchString ?? ""}
            onChange={changeBrojNalogaSearchData}
            onKeyPress={searchBrojNalogaByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_BROJ_NALOGA}</label>
          <Button tooltip={Labels.BROJ_NALOGA_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const crudBodyTemplate = (rowBrojNaloga: BrojNalogaReadDto) => {
    return (
      <div className="float-right">
        <Button
          type="button"
          tooltip={Labels.BROJ_NALOGA_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowBrojNaloga && openBrojNaloga(EntityOperation.UPDATE, rowBrojNaloga.id)}
        />
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.HEADER_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.HEADER_BROJ_NALOGA, filter: true, sortrable: true },
    { columnName: Labels.CENA, filter: true, sortrable: true },
  ];

  const renderCena = (brojNaloga: BrojNalogaReadDto) => {
    return formatPrice(brojNaloga.cena) + " RSD";
  };

  return (
    <div className="layout-generic-content-list">
      <Toolbar start={leftToolbar} />
      {brojNalogaLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList defaultButtonCount={2} />
      ) : (
        <DataTable
          filterDisplay="row"
          value={brojNalogaList}
          paginator
          first={firstBrojNaloga}
          paginatorTemplate={rowsPerPageDropdownTemplateMedium}
          rows={brojNalogaRows}
          onPage={(e) => {
            onPageBrojNaloga(e.rows, e.first);
          }}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          rowHover
          stripedRows
        >
          <Column field={"sifra"} header={Labels.HEADER_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          <Column field={"naziv"} header={Labels.HEADER_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          <Column field={"broj"} header={Labels.HEADER_BROJ_NALOGA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          <Column body={renderCena} header={Labels.CENA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          <Column body={crudBodyTemplate} />
        </DataTable>
      )}
    </div>
  );
}
