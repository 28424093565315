import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { formatDate, setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CalendarComponent from "../calendar-component/CalendarComponent";
import CrudSifarnikProizvodaLogical from "./CrudSifarnikProizvodaLogical";
import CrudSifarnikProizvodaCeneListView from "./cene/CrudSifarnikProizvodaCeneListView";

export default function CrudSifarnikProizvoda() {
  const {
    sifarnikProizvodaOperation,
    isDisabled,
    sifarnikProizvodaChange,
    breadCrumbItems,
    setSifarnikProizvodaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    sifarnikProizvodaId,
    location,
    sifarnikProizvodaRFZOList,
    sifarnikJedinicaMereList,
    sifarnikProizvodaLoading,
    sifarnikProizvodaDataOptionsLoading,
    invalidFields,
    setInvalidFields,
    vaziOdRef,
    vaziDoRef,
  } = CrudSifarnikProizvodaLogical();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  if (!sifarnikProizvodaId && !location.state) {
    return <Navigate to="/sifarnik-proizvoda-list" />;
  }

  const leftContentsToolbar = () => (
    <>
      {sifarnikProizvodaOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          disabled={!pristup || sifarnikProizvodaDataOptionsLoading}
          tooltip={Labels.SIFARNIK_PROIZVODA_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {sifarnikProizvodaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || (sifarnikProizvodaDataOptionsLoading && sifarnikProizvodaLoading)}
          icon="pi pi-pencil"
          tooltip={Labels.SIFARNIK_PROIZVODA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {sifarnikProizvodaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || (sifarnikProizvodaDataOptionsLoading && sifarnikProizvodaLoading)}
          icon="pi pi-trash"
          tooltip={Labels.SIFARNIK_PROIZVODA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_SIFARNIK_PROIZVODA}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_PROIZVODA_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikProizvodaLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={sifarnikProizvodaChange?.naziv ?? ""}
                        onChange={(e) => {
                          setSifarnikProizvodaChange({
                            ...sifarnikProizvodaChange!,
                            naziv: e.target.value,
                          });
                          if (e.target.value.length > 2) setInvalidFields((prev) => ({ ...prev, nazivLength: false }));
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", sifarnikProizvodaChange?.naziv);
                        }}
                        className={(invalidFields?.naziv || invalidFields?.nazivLength) && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      {invalidFields?.naziv && invalidFields?.nazivLength && ". "}
                      {invalidFields?.nazivLength && <ObaveznoPolje text={Labels.SIFARNIK_PROIZVODA_NAZIV_MINIMUM_LENGTH} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_PROIZVODA_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikProizvodaLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={sifarnikProizvodaChange?.sifra ?? ""}
                        onChange={(e) => {
                          setSifarnikProizvodaChange({
                            ...sifarnikProizvodaChange!,
                            sifra: e.target.value,
                          });
                          if (e.target.value.length > 2) setInvalidFields((prev) => ({ ...prev, sifraLength: false }));
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", sifarnikProizvodaChange?.sifra);
                        }}
                        className={(invalidFields?.sifra || invalidFields?.sifraLength) && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      {invalidFields?.sifra && invalidFields?.sifraLength && ". "}
                      {invalidFields?.sifraLength && <ObaveznoPolje text={Labels.SIFARNIK_PROIZVODA_SIFRA_MINIMUM_LENGTH} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_PROIZVODA_RFZO_LABEL}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikProizvodaLoading && !sifarnikProizvodaDataOptionsLoading ? (
                    <Dropdown
                      disabled={!pristup || isDisabled}
                      options={sifarnikProizvodaRFZOList}
                      optionLabel="naziv"
                      optionValue="id"
                      filter
                      showClear
                      value={sifarnikProizvodaChange?.sifarnikProizvodaRFZOId ?? ""}
                      onChange={(e) => {
                        setSifarnikProizvodaChange({
                          ...sifarnikProizvodaChange!,
                          sifarnikProizvodaRFZOId: sifarnikProizvodaRFZOList!.find((x) => x.id === e.target.value)?.id,
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_JEDINICA_MERE}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikProizvodaLoading && !sifarnikProizvodaDataOptionsLoading ? (
                    <>
                      <Dropdown
                        disabled={!pristup || isDisabled}
                        options={sifarnikJedinicaMereList}
                        optionLabel="naziv"
                        optionValue="id"
                        filter
                        showClear
                        value={sifarnikProizvodaChange?.sifarnikJedinicaMere?.id ?? ""}
                        onChange={(e) => {
                          setSifarnikProizvodaChange({
                            ...sifarnikProizvodaChange!,
                            sifarnikJedinicaMere: sifarnikJedinicaMereList!.find((x) => x.id === e.target.value),
                          });
                          if (e.target.value) {
                            setInvalidFields((prev) => ({ ...prev, jedinicaMere: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, jedinicaMere: true }));
                          }
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={"p-column-filter " + (invalidFields?.jedinicaMere && "p-invalid")}
                      />
                      {invalidFields?.jedinicaMere && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_PROIZVODA_NAZIV_ENGLESKI}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikProizvodaLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={sifarnikProizvodaChange?.nazivEngleski ?? ""}
                      onChange={(e) => {
                        setSifarnikProizvodaChange({
                          ...sifarnikProizvodaChange!,
                          nazivEngleski: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_PROIZVODA_OPIS}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikProizvodaLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={sifarnikProizvodaChange?.opis ?? ""}
                      onChange={(e) => {
                        setSifarnikProizvodaChange({
                          ...sifarnikProizvodaChange!,
                          opis: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_PROIZVODA_VAZI_OD}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikProizvodaLoading ? (
                    <>
                      <CalendarComponent
                        ref={vaziOdRef}
                        value={sifarnikProizvodaChange?.vaziOd}
                        onChange={(e: CalendarChangeEvent) => {
                          setSifarnikProizvodaChange({
                            ...sifarnikProizvodaChange!,
                            vaziOd: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                          });
                          setInvalidFields((prev) => ({ ...prev, vaziOd: false, invalidPeriod: false }));
                          if (e.target.value) vaziOdRef?.current?.hide();
                        }}
                        disabled={!pristup || isDisabled}
                        onClearButtonClick={() => {
                          setInvalidFields((prev) => ({ ...prev, vaziOd: true }));
                          setSifarnikProizvodaChange({
                            ...sifarnikProizvodaChange!,
                            vaziOd: undefined,
                          });
                        }}
                        className={"w-full " + ((invalidFields?.vaziOd || invalidFields?.invalidPeriod) && "p-invalid")}
                        placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                      />
                      {invalidFields?.vaziOd && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_PROIZVODA_VAZI_DO}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikProizvodaLoading ? (
                    <>
                      <CalendarComponent
                        ref={vaziDoRef}
                        value={sifarnikProizvodaChange?.vaziDo}
                        onChange={(e: CalendarChangeEvent) => {
                          setSifarnikProizvodaChange({
                            ...sifarnikProizvodaChange!,
                            vaziDo: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                          });
                          setInvalidFields((prev) => ({ ...prev, invalidPeriod: false }));
                          if (e.target.value) vaziDoRef?.current?.hide();
                        }}
                        disabled={!pristup || isDisabled}
                        className={"w-full " + (invalidFields?.invalidPeriod && "p-invalid")}
                        placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                      />
                      {invalidFields?.invalidPeriod && <ObaveznoPolje text={Labels.SIFARNIK_PROIZVODA_VAZI_OD_IS_BEFORE_VAZI_DO} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
          </TabPanel>
          <TabPanel header={Labels.SIFARNIK_PROIZVODA_CENE_LABEL} disabled={sifarnikProizvodaOperation === EntityOperation.CREATE}>
            <CrudSifarnikProizvodaCeneListView />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
