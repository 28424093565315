import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { SifarnikNacinaPlacanjaController } from "../../../controllers/sifarnik-nacina-placanja/SifarnikNacinaPlacanjaController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import { AxiosResponse } from "axios";
import { EnumController } from "../../../controllers/enum/EnumController";
import EnumVrstaUplatioca from "../../../infrastructure/system/EnumVrstaUplatioca";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SifarnikNacinPlacanjaReadDto from "../../../model/sifarnik/sifarnik-nacina-placanja/SifarnikNacinPlacanjaReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudSifarnikNacinaPlacanjaLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  sifarnikNacinPlacanjaChange: SifarnikNacinPlacanjaReadDto | undefined;
  setSifarnikNacinPlacanjaChange: React.Dispatch<React.SetStateAction<SifarnikNacinPlacanjaReadDto | undefined>>;
  isDisabled: boolean;
  sifarnikNacinPlacanjaOperation: string;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onCancel: () => void;
  sifarnikNacinPlacanjaLoading: boolean;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  vrstaUplatiocaList: EnumBaseReadDto[];
  showQuickPaymentDialog: boolean;
  hideQuickPaymentDialog: () => void;
  updateSifarnikNacinPlacanja: () => void;
  createSifarnikNacinPlacanja: () => void;
}

interface UseParamsType {
  sifarnikNacinaPlacanjaId?: string;
}

export default function CrudSifarnikNacinaPlacanjaLogical(): CrudSifarnikNacinaPlacanjaLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const [sifarnikNacinPlacanjaChange, setSifarnikNacinPlacanjaChange] = useState<SifarnikNacinPlacanjaReadDto | undefined>();
  const [sifarnikNacinPlacanjaOperation, setSifarnikNacinPlacanjaOperation] = useState(location.state?.sifarnikNacinaPlacanjaOperation || EntityOperation.UPDATE);
  const [isDisabled] = useState(isFormDisabled(sifarnikNacinPlacanjaOperation));
  const sifarnikNacinaPlacanjaId = Number(useParams<keyof UseParamsType>()["sifarnikNacinaPlacanjaId"]);
  const [sifarnikNacinPlacanjaLoading, setSifarnikNacinPlacanjaLoading] = useState(true);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const [vrstaUplatiocaList, setVrstaUplatiocaList] = useState<EnumBaseReadDto[]>([]);
  const { axiosGetSifarnikNacinaPlacanja, axiosDeleteSifarnikNacinaPlacanja, axiosCreateSifarnikNacinaPlacanja, axiosUpdateSifarnikNacinaPlacanja } = SifarnikNacinaPlacanjaController();
  const { axiosGetEnumVrstaUplatiocaList } = EnumController();
  const postLogHighLevel = useLogHighLevel();

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.LABEL_SIFARNIK_NACINI_PLACANJA,
      command: () => {
        navigate("/sifarnik-nacina-placanja-list/");
      },
    },
  ]);
  const [showQuickPaymentDialog, setShowQuickPaymentDialog] = useState<boolean>(false);

  useEffectOnce(() => {
    fetchData();
    getVrstaUplatiocaList();
  });

  const fetchData = (snpID?: number, operation?: string) => {
    const newSifarnikNacinaPlacanjaId = snpID || sifarnikNacinaPlacanjaId;
    let startTime = moment(new Date());
    if (newSifarnikNacinaPlacanjaId) {
      axiosGetSifarnikNacinaPlacanja(newSifarnikNacinaPlacanjaId)
        .then(({ data: { data } }: { data: { data: SifarnikNacinPlacanjaReadDto } }) => {
          setSifarnikNacinPlacanjaChange(data);
          const operationTmp = operation ?? sifarnikNacinPlacanjaOperation;
          setBreadCrumbItems([
            breadCrumbItems[0],
            {
              label: operationTmp === EntityOperation.UPDATE ? Labels.SIFARNIK_NACINA_PLACANJA_TITLE_UPDATE + data.naziv : Labels.SIFARNIK_NACINA_PLACANJA_TITLE_DELETE + data.naziv,
            },
          ]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setSifarnikNacinPlacanjaLoading, startTime);
        });
    } else {
      setSifarnikNacinPlacanjaLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.SIFARNIK_NACINA_PLACANJA_TITLE_CREATE,
        },
      ]);
    }
  };

  const getVrstaUplatiocaList = () => {
    axiosGetEnumVrstaUplatiocaList()
      .then((response: AxiosResponse) => {
        setVrstaUplatiocaList(response.data.data);
        if (sifarnikNacinPlacanjaOperation === EntityOperation.CREATE)
          setSifarnikNacinPlacanjaChange((prev: any) => ({ ...prev, vrstaUplatioca: response.data.data.find((vrsta: any) => vrsta.sifra === EnumVrstaUplatioca.ZA_OBA) }));
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const validateInput = (sifarnikNacinaPlacanja: SifarnikNacinPlacanjaReadDto | undefined) => {
    let isInvalid = false;
    if (sifarnikNacinaPlacanja === undefined || validateStringEmpty(sifarnikNacinaPlacanja?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(sifarnikNacinaPlacanja?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const validateMaxOneQuickPaymentExist = () => {
    if (!sifarnikNacinPlacanjaChange?.brzoPlacanje) {
      return true;
    }

    const sifarnikNacinaPlacanjaListTmp = location.state.sifarnikNacinaPlacanjaList;
    const numberOfQuickPayment = sifarnikNacinaPlacanjaListTmp.reduce((count: number, sifarnikNacinaPlacanja: SifarnikNacinPlacanjaReadDto) => {
      if (sifarnikNacinaPlacanja.id === sifarnikNacinPlacanjaChange?.id) {
        return count + (sifarnikNacinPlacanjaChange.brzoPlacanje ? 1 : 0);
      } else {
        return count + (sifarnikNacinaPlacanja.brzoPlacanje ? 1 : 0);
      }
    }, 0);

    return sifarnikNacinPlacanjaOperation === EntityOperation.UPDATE ? numberOfQuickPayment < 2 : numberOfQuickPayment < 1;
  };

  const onCreate = () => {
    if (validateMaxOneQuickPaymentExist()) {
      createSifarnikNacinPlacanja();
    } else {
      setShowQuickPaymentDialog(true);
    }
  };

  const createSifarnikNacinPlacanja = () => {
    if (!validateInput(sifarnikNacinPlacanjaChange)) {
      return;
    }

    if (sifarnikNacinPlacanjaChange) {
      setShowBlockUI(true);
      axiosCreateSifarnikNacinaPlacanja({ ...sifarnikNacinPlacanjaChange, brzoPlacanje: sifarnikNacinPlacanjaChange.brzoPlacanje ?? false })
        .then(({ data: { data } }: { data: { data: SifarnikNacinPlacanjaReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.SIFARNIK_NACINA_PLACANJA_MESSAGE_CREATE_SUCCESS);
          setSifarnikNacinPlacanjaOperation(EntityOperation.UPDATE);
          navigate("/sifarnik-nacina-placanja-list");
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_NACINA_PLACANJA_1 +
              sifarnikNacinPlacanjaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_NACINA_PLACANJA_2 +
              sifarnikNacinPlacanjaChange?.sifra
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
          if (showQuickPaymentDialog) setShowQuickPaymentDialog(false);
        });
    }
  };

  const onUpdate = () => {
    if (validateMaxOneQuickPaymentExist()) {
      updateSifarnikNacinPlacanja();
    } else {
      setShowQuickPaymentDialog(true);
    }
  };

  const updateSifarnikNacinPlacanja = () => {
    if (!validateInput(sifarnikNacinPlacanjaChange)) {
      return;
    }

    if (sifarnikNacinPlacanjaChange) {
      setShowBlockUI(true);
      axiosUpdateSifarnikNacinaPlacanja(sifarnikNacinPlacanjaChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.SIFARNIK_NACINA_PLACANJA_MESSAGE_UPDATE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_NACINA_PLACANJA_1 +
              sifarnikNacinPlacanjaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_NACINA_PLACANJA_2 +
              sifarnikNacinPlacanjaChange?.sifra
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
          if (showQuickPaymentDialog) setShowQuickPaymentDialog(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeleteSifarnikNacinaPlacanja(sifarnikNacinaPlacanjaId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.SIFARNIK_NACINA_PLACANJA_MESSAGE_DELETE_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_NACINA_PLACANJA_1 +
            sifarnikNacinPlacanjaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_NACINA_PLACANJA_2 +
            sifarnikNacinPlacanjaChange?.sifra
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/sifarnik-nacina-placanja-list`);
    }
  };

  const hideQuickPaymentDialog = () => {
    setShowQuickPaymentDialog(false);
  };

  return {
    breadCrumbItems,
    sifarnikNacinPlacanjaChange,
    setSifarnikNacinPlacanjaChange,
    isDisabled,
    sifarnikNacinPlacanjaOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    sifarnikNacinPlacanjaLoading,
    invalidFields,
    setInvalidFields,
    vrstaUplatiocaList,
    showQuickPaymentDialog,
    hideQuickPaymentDialog,
    updateSifarnikNacinPlacanja,
    createSifarnikNacinPlacanja,
  };
}
