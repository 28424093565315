import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { RolaService } from "../../../../controllers/rola/RolaService";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import CrudPoljeTipEnumStavkaLogical from "./CrudIzvestajParametarEnumStavkaLogical";

export default function CrudPoljeTipEnumStavka() {
  const { isSuperadmin } = RolaService();
  const { parametarEnumStavkaChange, setParametarEnumStavkaChange, setIndex, index, isDisabled, breadCrumbItems, parametarEnumStavkaOperation, onCreate, onDelete, onUpdate, onCancel } =
    CrudPoljeTipEnumStavkaLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const leftContentsToolbar = () => (
    <>
      {parametarEnumStavkaOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} disabled={!pristup} tooltip={Labels.LABEL_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {parametarEnumStavkaOperation === EntityOperation.UPDATE && (
        <Button label={Labels.BUTTON_UPDATE} disabled={!pristup} tooltip={Labels.LABEL_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA} icon="pi pi-pencil" className="p-button-warning" onClick={onUpdate} />
      )}
      {parametarEnumStavkaOperation === EntityOperation.DELETE && (
        <Button label={Labels.BUTTON_DELETE} disabled={!pristup} tooltip={Labels.LABEL_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA} icon="pi pi-trash" className="p-button-danger" onClick={onDelete} />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div>
      <Panel>
        <div className="layout-crud-field-type">
          <BreadCrumb model={breadCrumbItems} />
          <TabView
            className="mt-3"
            renderActiveOnly={false}
            activeIndex={index}
            onTabChange={(e) => {
              setIndex(e.index);
            }}
          >
            <TabPanel header={Labels.IZVESTAJ_PARAMETAR_ENUM_STAVKA_LIST}>
              <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
                <div className="grid align-items-center p-0">
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.LABEL_SIFRA}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    <InputText
                      disabled={!pristup || isDisabled || !isSuperadmin}
                      value={parametarEnumStavkaChange?.sifra ?? ""}
                      onChange={(e) => {
                        setParametarEnumStavkaChange({
                          ...parametarEnumStavkaChange!,
                          sifra: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.POLJE_TIP_ENUM_STAVKA_NAZIV}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={parametarEnumStavkaChange?.naziv ?? ""}
                      onChange={(e) => {
                        setParametarEnumStavkaChange({
                          ...parametarEnumStavkaChange!,
                          naziv: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <Toolbar start={leftContentsToolbar} end={rightContentsToolbar}></Toolbar>
            </TabPanel>
          </TabView>
        </div>
      </Panel>
    </div>
  );
}
