import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { I18n } from "i18n-js";
import jwtDecode from "jwt-decode";
import moment from "moment";
import "moment/locale/sr";
import "moment/locale/sr-cyrl";
import { Toast } from "primereact/toast";
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "react-oauth2-pkce";
import AuthData from "./AuthData";
import { LogHighLevelController } from "./controllers/log/LogHighLevelController";
import { RadnikController } from "./controllers/radnik/RadnikController";
import EnumJezik from "./infrastructure/system/EnumJezik";
import EnumPristup from "./infrastructure/system/EnumPristup";
import LabelsContext, { LabelsType } from "./infrastructure/system/LabelsContext";
import SR_RS from "./infrastructure/system/Labels_sr_RS";
import SR_RS_LATN from "./infrastructure/system/Labels_sr_RS_latn";
import { MESSAGE_DURATION_IN_MILLISECONDS, NESACUVAN_NALAZ, ODABRANA_ORGANIZACIONA_JEDINICA_STORAGE_ITEM, formatDate, handleAxiosCallError, isPublicRoute } from "./infrastructure/system/Utils";
import OrganizacionaJedinicaCvorDto from "./model/organizacionaJedinica/OrganizacionaJedinicaCvorDto";
import ParametarUstanovaReadDto from "./model/parametar/ParametarUstanovaReadDto";
import UpozorenjeDto from "./model/upozorenje/UpozorenjeDto";

interface AppContextProps {
  authData: AuthData | undefined;
  updateAuthData: (authData: AuthData) => void;
  deleteAuthData: () => void;
  showMessage: (summary: string, detail: string, severity?: string) => void;
  showBlockUI: boolean;
  setShowBlockUI: (value: boolean) => void;
  t: (scope: any, options?: any) => string;
  locale: string;
  setLocale: React.Dispatch<React.SetStateAction<string>>;
  parametarUstanovaList: Array<ParametarUstanovaReadDto> | undefined;
  setParametarUstanovaList: React.Dispatch<React.SetStateAction<Array<ParametarUstanovaReadDto> | undefined>>;
  odabranaOrganizacionaJedinicaList: Array<OrganizacionaJedinicaCvorDto>;
  updateOdabranaOrganizacionaJedinicaList: (odabranaOrgJedList: Array<OrganizacionaJedinicaCvorDto>) => void;
  upozorenje: UpozorenjeDto | undefined;
  setUpozorenje: React.Dispatch<React.SetStateAction<UpozorenjeDto | undefined>>;
  neprocitanaObavestenjaCount: number;
  setNeprocitanaObavestenjaCount: React.Dispatch<React.SetStateAction<number>>;
  pristup: boolean | undefined;
  message: any;
  neprocitanePorukeCount: number;
  setNeprocitanePorukeCount: React.Dispatch<React.SetStateAction<number>>;
  nadjimed: boolean;
  Labels: LabelsType;
}

const i18n = new I18n({
  SR_RS_LATN,
  SR_RS,
});

i18n.enableFallback = true;

export const AppContext = createContext({} as AppContextProps);

const getJezik = (authData: AuthData | undefined) => {
  if (!authData?.currentRadnik || authData.currentRadnik.jezik.sifra === EnumJezik.SR_RS_LATN) {
    moment.locale("sr");
    return EnumJezik.SR_RS_LATN;
  }
  moment.locale("sr_cyrl");
  return EnumJezik.SR_RS;
};

const proveraPristupa = (authData: AuthData | undefined) => {
  const ustanovaPoslednjaIzabrana = authData?.currentRadnik?.ustanovaPoslednjaIzabrana;
  const pristup = ustanovaPoslednjaIzabrana?.pristupPrimaran ?? ustanovaPoslednjaIzabrana?.ustanovaPristup?.pristup;
  if (!pristup) return false;
  if (pristup.sifra !== EnumPristup.PUN) return false;
  return true;
};

export const useAuthData = () => {
  const { authData } = useContext(AppContext);
  //TODO Throw if authData is undefined?
  return authData;
};

export const useLabels = () => {
  const { Labels } = useContext(AppContext);
  return Labels;
};

export interface JwtPayloadType {
  aud: string;
  authData: { radnikId: number; tenantId: number; username: string };
  exp: number;
  iat: number;
  iss: "string";
  nbf: number;
  rolaList: string[];
  scope: string[];
  sub: string;
}

const LOCAL_STORAGE_AUTH = "heliant-core-app-auth";

const Store = ({ children }: PropsWithChildren) => {
  const localStorageAuth = localStorage.getItem(LOCAL_STORAGE_AUTH);
  const localStorageOrgJedList = localStorage.getItem(ODABRANA_ORGANIZACIONA_JEDINICA_STORAGE_ITEM);
  const [authData, setAuthData] = useState<AuthData | undefined>(localStorageAuth ? JSON.parse(localStorageAuth) : undefined);
  const [locale, setLocale] = useState(getJezik(authData));
  const { Labels } = LabelsContext((scope: any, options: any) => i18n.translate(scope, { locale, ...options }));
  const message = useRef<Toast>(null);

  const [showBlockUI, setShowBlockUI] = useState(false);
  const [parametarUstanovaList, setParametarUstanovaList] = useState<Array<ParametarUstanovaReadDto> | undefined>();
  const [odabranaOrganizacionaJedinicaList, setOdabranaOrganizacionaJedinicaList] = useState<Array<OrganizacionaJedinicaCvorDto>>(localStorageOrgJedList ? JSON.parse(localStorageOrgJedList) : []);
  const [upozorenje, setUpozorenje] = useState<UpozorenjeDto>();
  const [neprocitanaObavestenjaCount, setNeprocitanaObavestenjaCount] = useState<number>(0);
  const [neprocitanePorukeCount, setNeprocitanePorukeCount] = useState<number>(0);
  const [pristup, setPristup] = useState<boolean>(proveraPristupa(authData));
  const [nadjimed, setNadjimed] = useState<boolean>(authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.nadjimed ?? false);

  const { axiosPostLogHighLevel } = LogHighLevelController();

  const { authService } = useAuth();

  const { axiosReadRadnik } = RadnikController();

  useEffect(() => {
    if (authData) return;
    if (authService.getAuthTokens().access_token) {
      const token = authService.getAuthTokens().access_token;
      const jwtPayload = jwtDecode<JwtPayloadType>(token);
      if (jwtPayload) {
        loadUser(jwtPayload.authData.radnikId, token);
        let odabranaOrgJedList = localStorage.getItem(ODABRANA_ORGANIZACIONA_JEDINICA_STORAGE_ITEM);
        if (odabranaOrgJedList !== null) {
          setOdabranaOrganizacionaJedinicaList(JSON.parse(odabranaOrgJedList));
        }
      }
    } else {
      setTimeout(() => {
        !isPublicRoute(window.location.pathname) && authService.authorize();
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUser = (radnikId: number, token: string) => {
    axiosReadRadnik(radnikId, token)
      .then((res) => {
        updateAuthData({
          token: token,
          currentRadnik: res.data.data,
        });
        if (res.data.data.jezik.sifra) {
          setLocale(res.data.data.jezik.sifra);
        }
        if (token !== localStorage.getItem("logged")) {
          localStorage.setItem("logged", token);
          axiosPostLogHighLevel(
            {
              ustanova: { id: res.data.data.ustanovaPoslednjaIzabrana.id },
              radnik: { id: res.data.data.id },
              pacijent: { id: undefined },
              vremeAkcije: formatDate(new Date())!,
              opis: "Korisnik se ulogovao.",
            },
            token
          ).catch((error) => {
            handleAxiosCallError(showMessage, error);
          });
        }
      })
      .catch((error) => {
        deleteAuthData();
        handleAxiosCallError(showMessage, error);
      });
    setBlockUI(false);
  };

  const showMessage = async (severity: string, summary: string, detail?: string) => {
    try {
      message?.current?.show({
        // @ts-ignore
        severity: severity,
        summary: summary,
        detail: detail ? detail : "",
        closable: true,
        life: MESSAGE_DURATION_IN_MILLISECONDS,
      });
    } catch (e) {
      window.location.replace("/error");
    }
  };

  const setBlockUI = (value: boolean) => {
    if (value) {
      setShowBlockUI(value);
    } else {
      setTimeout(() => {
        setShowBlockUI(value);
      }, 500);
    }
  };

  const updateAuthData = (authData: AuthData) => {
    setAuthData(authData);
    setLocale(getJezik(authData));
    setPristup(proveraPristupa(authData));
    setNadjimed(authData.currentRadnik?.ustanovaPoslednjaIzabrana?.nadjimed ?? false);
    localStorage.setItem(LOCAL_STORAGE_AUTH, JSON.stringify(authData));
  };

  const deleteAuthData = async () => {
    localStorage.removeItem(ODABRANA_ORGANIZACIONA_JEDINICA_STORAGE_ITEM);
    localStorage.removeItem(NESACUVAN_NALAZ);
    localStorage.removeItem(LOCAL_STORAGE_AUTH);
    setAuthData(undefined);
    await authService.logout(true);
  };

  const updateOdabranaOrganizacionaJedinicaList = (odabranaOrgJedList: Array<OrganizacionaJedinicaCvorDto>) => {
    localStorage.setItem(ODABRANA_ORGANIZACIONA_JEDINICA_STORAGE_ITEM, JSON.stringify(odabranaOrgJedList));
    setOdabranaOrganizacionaJedinicaList(odabranaOrgJedList);
  };

  // Create a client and add error handling to be in one place!
  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
        },
      },
      queryCache: new QueryCache({
        onError: (error: any) => {
          if (error?.response?.status !== 401) handleAxiosCallError(showMessage, error);
        },
      }),
    });
    // eslint-disable-next-line
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider
        value={{
          authData: authData,
          updateAuthData,
          deleteAuthData,
          showMessage,
          showBlockUI: showBlockUI,
          setShowBlockUI: setBlockUI,
          t: (scope: any, options: any) => i18n.translate(scope, { locale, ...options }),
          locale: locale,
          setLocale,
          parametarUstanovaList,
          setParametarUstanovaList,
          odabranaOrganizacionaJedinicaList,
          updateOdabranaOrganizacionaJedinicaList,
          upozorenje,
          setUpozorenje,
          neprocitanaObavestenjaCount,
          setNeprocitanaObavestenjaCount,
          pristup,
          message,
          neprocitanePorukeCount,
          setNeprocitanePorukeCount,
          nadjimed,
          Labels,
        }}
      >
        {children}
      </AppContext.Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" /> */}
    </QueryClientProvider>
  );
};

export default Store;
