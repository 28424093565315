import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { ChangeEvent, useRef } from "react";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import ConfirmComponent from "../../../confirm-component/ConfirmComponent";
import CrudDodatniModulLogical from "./CrudDodatniModulLogical";

export default function CrudDodatniModulView() {
  const { breadCrumbItems, dodatniModulChange, setDodatniModulChange, isDisabled, dodatniModulOperation, onCreate, onUpdate, onCancel, dodatniModulLoading, sifarnikDodatniModulList } =
    CrudDodatniModulLogical();
  const Labels = useLabels();
  const fileUploadRef = useRef<FileUpload>(null);

  const leftContentsToolbar = () => (
    <>
      {dodatniModulOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.DODATNI_MODUL_TITLE_CREATE} tooltipOptions={tooltipOptionsBottom} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {dodatniModulOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={dodatniModulLoading}
          icon="pi pi-pencil"
          tooltip={Labels.DODATNI_MODUL_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
          <TabPanel header={Labels.DODATNI_MODUL}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.DODATNI_MODUL_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!dodatniModulLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={dodatniModulChange?.naziv ?? ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setDodatniModulChange({
                          ...dodatniModulChange!,
                          naziv: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.DODATNI_MODUL_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!dodatniModulLoading ? (
                    <Dropdown
                      className="w-full"
                      options={sifarnikDodatniModulList}
                      disabled={isDisabled}
                      optionLabel="naziv"
                      optionValue="sifra"
                      value={dodatniModulChange?.tip ? dodatniModulChange?.tip.sifra : undefined}
                      onChange={(e: DropdownChangeEvent) =>
                        setDodatniModulChange({
                          ...dodatniModulChange!,
                          tip: sifarnikDodatniModulList.filter((dodatniModul) => dodatniModul.sifra === e.target.value)[0],
                        })
                      }
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.CENA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!dodatniModulLoading ? (
                    <InputNumber
                      disabled={isDisabled || dodatniModulOperation === EntityOperation.UPDATE}
                      value={dodatniModulChange?.cena}
                      onChange={(e) => {
                        setDodatniModulChange({
                          ...dodatniModulChange!,
                          cena: e.value ?? 0,
                        });
                      }}
                      min={0}
                      mode="decimal"
                      locale="sr-RS"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      suffix=" RSD"
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.DODATNI_MODUL_OPIS}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!dodatniModulLoading ? (
                    <InputTextarea
                      rows={3}
                      cols={5}
                      className="mt-2"
                      disabled={isDisabled}
                      value={dodatniModulChange?.opis ?? ""}
                      onChange={(e) => {
                        setDodatniModulChange({
                          ...dodatniModulChange!,
                          opis: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3 ">
                  <div>{Labels.DODATNI_MODUL_IKONICA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {dodatniModulOperation === EntityOperation.CREATE ? (
                    <FileUpload
                      className="mt-3 w-full"
                      accept=".svg"
                      multiple={false}
                      auto
                      customUpload
                      ref={fileUploadRef}
                      onRemove={() => {
                        setDodatniModulChange({
                          ...dodatniModulChange!,
                          ikonica: undefined,
                        });
                      }}
                      onSelect={(e) => {
                        if (e.files !== null) {
                          var file = e.files[0];
                          var reader = new FileReader();
                          reader.onload = function (event) {
                            setDodatniModulChange({
                              ...dodatniModulChange!,
                              ikonica: event.target !== undefined && event.target?.result !== null ? Buffer.from(event.target!.result as string).toString("base64") : "",
                            });
                          };
                          reader.readAsText(file);
                        }
                      }}
                    />
                  ) : (
                    <div>
                      {dodatniModulOperation === EntityOperation.UPDATE ? (
                        <div className="mt-3 p-0">
                          <Image src={dodatniModulChange?.ikonica ?? ""} />
                          <div className="flex flex-row flex-wrap align-items-center">
                            <FileUpload
                              ref={fileUploadRef}
                              className="mt-2"
                              accept=".svg"
                              mode="basic"
                              customUpload
                              auto
                              onSelect={(e) => {
                                if (e.files !== null) {
                                  var file = e.files[0];
                                  var reader = new FileReader();
                                  reader.onload = function (event) {
                                    setDodatniModulChange({
                                      ...dodatniModulChange!,
                                      ikonica:
                                        event.target !== undefined && event.target?.result !== null
                                          ? "data:image/svg+xml;base64," + Buffer.from(event.target!.result as string).toString("base64")
                                          : "",
                                    });
                                  };
                                  reader.readAsText(file);
                                }
                              }}
                            />
                            {dodatniModulChange?.ikonica && (
                              <div className="ml-2 mt-2">
                                <ConfirmComponent
                                  disabled={isDisabled}
                                  buttonIcon="pi pi-trash"
                                  acceptClassName="p-button p-component text-white p-button-tertiary"
                                  rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
                                  confirmIcon="pi pi-exclamation-triangle"
                                  confirmTitle={Labels.DODATNI_MODUL_IKONICA_DELETE}
                                  onAccept={() => {
                                    setDodatniModulChange({
                                      ...dodatniModulChange!,
                                      ikonica: undefined,
                                    });
                                    fileUploadRef.current?.clear();
                                  }}
                                />
                                <ConfirmPopup />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        dodatniModulChange?.ikonica && (
                          <div className="flex flex-row flex-wrap">
                            <div className="mt-3 p-0">
                              <Image src={dodatniModulChange?.ikonica ?? ""} />
                            </div>
                            <ConfirmComponent
                              disabled={!isDisabled}
                              buttonIcon="pi pi-trash"
                              acceptClassName="p-button p-component text-white p-button-tertiary"
                              rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
                              confirmIcon="pi pi-exclamation-triangle"
                              confirmTitle={Labels.DODATNI_MODUL_IKONICA_DELETE}
                              onAccept={() => {
                                setDodatniModulChange({
                                  ...dodatniModulChange!,
                                  ikonica: undefined,
                                });
                                fileUploadRef.current?.clear();
                              }}
                            />
                            <ConfirmPopup />
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} className="flex" />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
