import { InitialStateAddPatientNotificationType } from "./initialStateAddPatientNotification";
import actionsAddPatientNotification from "./actionsAddPatientNotification";

export default function reducerAddPatientNotification(state: InitialStateAddPatientNotificationType, action: any) {
  switch (action.type) {
    case actionsAddPatientNotification.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
        operation: action.operation,
        obavestenje: action.obavestenje,
      };
    }
    case actionsAddPatientNotification.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        operation: "",
        obavestenje: undefined,
      };
    }
    case actionsAddPatientNotification.OBAVESTENJE_TYPE_LIST: {
      return {
        ...state,
        obavestenjeTypeList: action.value,
      };
    }
    case actionsAddPatientNotification.OBAVESTENJE_TYPE_LIST_LOADING: {
      return {
        ...state,
        obavestenjeTypeListLoading: action.value,
      };
    }
    case actionsAddPatientNotification.OBAVESTENJE_CHANGE: {
      return {
        ...state,
        obavestenje: { ...state.obavestenje, [action.fieldName]: action.value },
      };
    }
    case actionsAddPatientNotification.OBAVESTENJE_DELETE: {
      return {
        ...state,
        obavestenje: action.value,
      };
    }
    case actionsAddPatientNotification.OBAVESTENJE_LOADING: {
      return {
        ...state,
        obavestenjeLoading: action.value,
      };
    }
    case actionsAddPatientNotification.GENDER_LIST: {
      return {
        ...state,
        polList: action.value,
      };
    }
    case actionsAddPatientNotification.GENDER_LIST_LOADING: {
      return {
        ...state,
        polListLoading: action.value,
      };
    }
    case actionsAddPatientNotification.PATIENTS_INCLUDED_LIST_LOADING: {
      return {
        ...state,
        patientsIncludedLoading: action.value,
      };
    }
    case actionsAddPatientNotification.PATIENTS_INCLUDED_SEARCH: {
      return {
        ...state,
        patientsIncludedSearch: { ...state.patientsIncludedSearch, [action.fieldName]: action.value },
      };
    }
    default:
      return state;
  }
}
