import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import TipKontaktaReadDto from "../../../model/kontakt/TipKontaktaReadDto";
import UstanovaReadDto from "../../../model/ustanova/UstanovaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import DialogComponent from "../../dialog-component/DialogComponent";
import TipKontaktaListLogical from "./TipKontaktaListLogical";
interface TipKontaktaListProps {
  ustanovaId?: number;
  ustanova?: UstanovaReadDto;
  isDisabled?: boolean;
}
export default function TipKontaktaListPage(props: TipKontaktaListProps) {
  const { ustanovaId, isDisabled, ustanova } = props;

  const {
    tipKontaktaList,
    first,
    tableRows,
    changeTipKontaktaSearchData,
    tipKontaktaSearchData,
    fetchData,
    searchTipKontaktaByEnter,
    exportData,
    breadCrumbItems,
    onPageTipKontakta,
    tipKontaktaListLoading,
    closeDialog,
    firstRepository,
    importTipKontakta,
    isDialogOpen,
    onPageRepositoryTipKontakta,
    openDialog,
    repositoryTipKontaktaList,
    repositoryTipKontaktaLoading,
    selectedColumns,
    setSelectedColumns,
    tableRepositoryRows,
    sifarnikVremeTrajanjaKontakta,
  } = TipKontaktaListLogical(ustanovaId);

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const { pristup } = useContext(AppContext);

  const openTipKontakta = (entityOperation: string, id?: number, tipKontakta?: TipKontaktaReadDto) => {
    const idTipKontakta = entityOperation !== EntityOperation.CREATE ? id : "";
    ustanovaId
      ? navigate(`/crud-ustanova/${ustanovaId}/crud-tip-kontakta/${idTipKontakta}`, {
          state: { tipKontaktaOperation: entityOperation, ustanovaNaziv: ustanova?.naziv },
        })
      : navigate(`/crud-tip-kontakta/${idTipKontakta}`, {
          state: { tipKontaktaOperation: entityOperation, tipKontakta: tipKontakta },
        });
  };
  const crudBodyTemplate = (rowTipKontakta: TipKontaktaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.TIP_KONTAKTA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowTipKontakta && openTipKontakta(EntityOperation.UPDATE, rowTipKontakta.id, rowTipKontakta)}
          disabled={!pristup || (isDisabled ?? false)}
        />
        <Button
          type="button"
          tooltip={Labels.TIP_KONTAKTA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowTipKontakta && openTipKontakta(EntityOperation.DELETE, rowTipKontakta.id, rowTipKontakta)}
          disabled={!pristup || (isDisabled ?? false)}
        />
      </div>
    );
  };

  const automatskoZavrsavanjeBodyTemplate = (column: TipKontaktaReadDto) => {
    if (column.automatskoZavrsavanje) {
      return Labels.BUTTON_YES;
    } else {
      return Labels.BUTTON_NO;
    }
  };

  const automatskoZavrsavanjeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={[
          { label: Labels.BUTTON_YES, value: true },
          { label: Labels.BUTTON_NO, value: false },
        ]}
        onChange={(e) => dt.current.filter(e.value, "automatskoZavrsavanje", "equals")}
        placeholder={Labels.CEKAONICA_COLUMN_SVE}
        className="p-column-filter"
        showClear
      />
    );
  };

  const vremeTrajanjaFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={sifarnikVremeTrajanjaKontakta.map((item) => {
          return { label: item.naziv, value: item.naziv };
        })}
        onChange={(e) => dt.current.filter(e.value, "vremeTrajanjaKontakta.naziv", "equals")}
        placeholder={Labels.CEKAONICA_COLUMN_SVE}
        className="p-column-filter"
        showClear
      />
    );
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_TIP_KONTAKTA}
          tooltip={Labels.TIP_KONTAKTA_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openTipKontakta(EntityOperation.CREATE);
          }}
          disabled={!pristup || (isDisabled ?? false)}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={tipKontaktaSearchData?.searchString ?? ""}
            onChange={changeTipKontaktaSearchData}
            onKeyPress={searchTipKontaktaByEnter}
          />
          <label htmlFor="searchString">{Labels.TIP_KONTAKTA_BUTTON_SEARCH}</label>
          <Button tooltip={Labels.TIP_KONTAKTA_BUTTON_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const downloadButton = () => {
    return (
      <div className="flex flex-row flex-wrap">
        <Button
          label={Labels.BUTTON_REPOSITORY}
          icon="pi pi-cloud-download"
          tooltip={Labels.IMPORT_TIP_KONTAKTA_REPOSITORY}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-outlined mr-3 bg-white"
          onClick={() => {
            openDialog();
          }}
        />
        <ExportData disabled={tipKontaktaListLoading} data={exportData} name={Labels.TIP_KONTAKTA_LIST} dt={dt} />
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.TIP_KONTAKTA_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.TIP_KONTAKTA_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.TIP_KONTAKTA_KATEGORIJA_KONTAKTA, filter: true, sortrable: true },
    { columnName: Labels.TIP_KONTAKTA_AUTOMATSKO_ZAVRSAVANJE, filter: true, sortrable: true },
    { columnName: Labels.TIP_KONTAKTA_VREME_TRAJANJA_PREGLEDA, filter: true, sortrable: false },
  ];
  const leftDialogContents = () => (
    <Button label={Labels.LABEL_EXPORT} disabled={selectedColumns.length === 0} icon="pi pi-download" className="p-button-outlined p-button-tertiary bg-white" onClick={importTipKontakta} autoFocus />
  );
  const rightDialogContents = () => <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={closeDialog} />;
  const dialogFooter = (
    <div className="col-12">
      <Toolbar left={leftDialogContents} right={rightDialogContents} />
    </div>
  );
  const columnsRepositoryProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.CEKAONICA_COLUMN_SVE },
    { columnName: Labels.TIP_KONTAKTA_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.TIP_KONTAKTA_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.TIP_KONTAKTA_KATEGORIJA_KONTAKTA, filter: true, sortrable: true },
  ];
  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {tipKontaktaListLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={tipKontaktaList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageTipKontakta(e.rows, e.first);
            }}
            exportFilename={Labels.TIP_KONTAKTA_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"sifra"} header={Labels.TIP_KONTAKTA_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"naziv"} header={Labels.TIP_KONTAKTA_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifarnikKategorijaKontakta.naziv"} header={Labels.TIP_KONTAKTA_KATEGORIJA_KONTAKTA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column
              field={"automatskoZavrsavanje"}
              header={Labels.TIP_KONTAKTA_AUTOMATSKO_ZAVRSAVANJE}
              filter
              showFilterMenu={false}
              sortable
              body={automatskoZavrsavanjeBodyTemplate}
              filterElement={automatskoZavrsavanjeFilterTemplate}
            />
            <Column
              field={"vremeTrajanjaKontakta.naziv"}
              header={Labels.TIP_KONTAKTA_VREME_TRAJANJA_PREGLEDA}
              filter
              filterElement={vremeTrajanjaFilterTemplate}
              showFilterMenu={false}
              sortable={false}
            />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
      <Panel>
        <DialogComponent
          header={Labels.TIP_KONTAKTA_REPOSITORY}
          visible={isDialogOpen}
          onHide={closeDialog}
          footer={dialogFooter}
          className="w-8"
          content={
            <div>
              <div className="card">
                {repositoryTipKontaktaLoading ? (
                  <SkeletonTable dataTableColumnsProperty={columnsRepositoryProperty} hasFilterColumns={true} />
                ) : (
                  <DataTable
                    ref={dt}
                    value={repositoryTipKontaktaList}
                    first={firstRepository}
                    rows={tableRepositoryRows}
                    filterDisplay="row"
                    className="p-datatable-flex-scrollable"
                    scrollHeight="flex"
                    paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
                    //paginator
                    selectionMode="checkbox"
                    selection={selectedColumns}
                    responsiveLayout="scroll"
                    onSelectionChange={(e) => setSelectedColumns(e.value)}
                    emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                    onPage={(e) => {
                      onPageRepositoryTipKontakta(e.rows, e.first);
                    }}
                    rowHover
                    stripedRows
                  >
                    <Column filterHeaderClassName="pl-3" bodyClassName="w-3rem pl-3" selectionMode="multiple" header={Labels.CEKAONICA_COLUMN_SVE} headerClassName="w-3rem pl-3" />
                    <Column field={"sifra"} header={Labels.TIP_KONTAKTA_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    <Column field={"naziv"} header={Labels.TIP_KONTAKTA_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    <Column field={"kategorijaKontaktaEnum.naziv"} header={Labels.TIP_KONTAKTA_KATEGORIJA_KONTAKTA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                  </DataTable>
                )}
              </div>
            </div>
          }
        ></DialogComponent>
      </Panel>
    </div>
  );
}
