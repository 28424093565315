import moment from "moment/moment";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { DATE_FORMAT, checkEmpty, formatDate, formatPrice, tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import actionsPartnerCena from "../../../../infrastructure/system/hooks/dialog-partner-cena-reducer/actionsPartnerCena";
import { usePoreskaStopaList } from "../../../../infrastructure/system/hooks/sifarnici/poreska-stopa/usePoreskaStopaList";
import { useValutaList } from "../../../../infrastructure/system/hooks/sifarnici/valuta-list/useValutaList";
import PartnerCenaReadDto from "../../../../model/partner/PartnerCenaReadDto";
import SifarnikKategorijaPopustaReadDto from "../../../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import CalendarComponent from "../../calendar-component/CalendarComponent";
import PartnerCeneListLogical from "./PartnerCeneListLogical";
import DialogPartnerCena from "./dialog/DialogPartnerCena";

interface PartnerCeneListViewType {
  popustiList: Array<SifarnikKategorijaPopustaReadDto>;
}

export default function PartnerCeneListView(props: PartnerCeneListViewType) {
  const { popustiList } = props;
  const {
    state,
    dispatch,
    onCreate,
    onDelete,
    onUpdate,
    searchSifarnikUsluga,
    sifarnikUslugaList,
    first,
    tableRows,
    onPagePartnerCene,
    ceneList,
    ceneLoading,
    partnerCeneSearch,
    setPartnerCeneSearch,
    searchPartnerCene,
    partnerId,
    invalidFields,
    setInvalidFields,
    vaziOdRef,
    vaziDoRef,
  } = PartnerCeneListLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const dt = useRef<any>(null);

  const { valutaList } = useValutaList();
  const { poreskaStopaList } = usePoreskaStopaList();

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.LABEL_ADD_CENA_PARTNER}
          tooltip={Labels.LABEL_ADD_CENA_PARTNER}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            dispatch({ type: actionsPartnerCena.OPEN_DIALOG, data: EntityOperation.CREATE, cena: { ...state.cena!, partner: { id: partnerId } } });
          }}
          disabled={!pristup}
        />
        <div className="block mt-4 font-bold">{Labels.PRETRAGA}</div>
        <div className="flex flex-row flex-wrap search-wrapper">
          <div className="flex align-items-start my-2 mr-3 search-wrapper__item">
            <InputText
              placeholder={Labels.LABEL_NAZIV_USLUGE_PARTNER}
              value={partnerCeneSearch?.nazivUsluge}
              onChange={(e) => {
                setPartnerCeneSearch({
                  ...partnerCeneSearch!,
                  nazivUsluge: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex align-items-start my-2 mr-3 search-wrapper__item">
            <CalendarComponent
              ref={vaziOdRef}
              placeholder={Labels.LABEL_VAZI_OD_PARTNER}
              value={partnerCeneSearch?.vaziOd}
              onChange={(e: CalendarChangeEvent) => {
                setPartnerCeneSearch({
                  ...partnerCeneSearch!,
                  vaziOd: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                });
                if (e.target.value) vaziOdRef?.current?.hide();
              }}
            />
          </div>
          <div className="flex align-items-start my-2 mr-3 search-wrapper__item">
            <CalendarComponent
              ref={vaziDoRef}
              placeholder={Labels.LABEL_VAZI_DO_PARTNER}
              value={partnerCeneSearch?.vaziDo}
              onChange={(e: CalendarChangeEvent) => {
                setPartnerCeneSearch({
                  ...partnerCeneSearch!,
                  vaziDo: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                });
                if (e.target.value) vaziDoRef?.current?.hide();
              }}
            />
          </div>
          <div className="flex flex-column align-items-center my-2 mr-3 search-wrapper__item">
            <InputNumber
              placeholder={Labels.LABEL_CENA_OD_PARTNER}
              value={partnerCeneSearch?.cenaOd ?? null}
              onChange={(e) => {
                setPartnerCeneSearch({
                  ...partnerCeneSearch!,
                  cenaOd: e.value,
                });
              }}
              className="mb-2"
            />
            <InputNumber
              placeholder={Labels.LABEL_CENA_DO_PARTNER}
              value={partnerCeneSearch?.cenaDo ?? null}
              onChange={(e) => {
                setPartnerCeneSearch({
                  ...partnerCeneSearch!,
                  cenaDo: e.value,
                });
              }}
            />
          </div>
          <div className="flex align-items-start my-2 mr-3 search-wrapper__item">
            <Dropdown
              optionLabel="procenat"
              optionValue="id"
              options={poreskaStopaList}
              value={partnerCeneSearch?.poreskaStopa ?? null}
              onChange={(e) => {
                setPartnerCeneSearch({
                  ...partnerCeneSearch!,
                  poreskaStopa: e.target.value,
                });
              }}
              emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              itemTemplate={(e) => `${e.procenat} %`}
              valueTemplate={(e) => `${e?.procenat ?? Labels.LABEL_PORESKA_STOPA_PARTNER} %`}
              placeholder={Labels.LABEL_PORESKA_STOPA_PARTNER}
              className="w-13rem"
              showClear
            />
          </div>
          <div className="flex align-items-start my-2 mr-3 search-wrapper__item">
            <Button className="p-button" icon="pi pi-search" label={Labels.PRETRAGA} onClick={searchPartnerCene} />
          </div>
        </div>
      </>
    );
  };

  const crudBodyTemplate = (cena: PartnerCenaReadDto) => {
    return (
      <div className="flex justify-content-end">
        {(cena.vaziDo === null || moment(cena.vaziDo).isAfter(new Date(), "days")) && (
          <Button
            type="button"
            tooltip={Labels.LABEL_UPDATE_CENA_PARTNER}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-warning mr-2"
            icon="pi pi-pencil"
            onClick={() => cena && dispatch({ type: actionsPartnerCena.OPEN_DIALOG, data: EntityOperation.UPDATE, cena: { ...state.cena!, ...cena } })}
          />
        )}
        {(moment(cena.vaziOd).isSame(new Date(), "days") || moment(cena.vaziOd).isAfter(new Date(), "days")) && (
          <Button
            type="button"
            tooltip={Labels.LABEL_DELETE_CENA_PARTNER}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-danger mr-2"
            icon="pi pi-trash"
            onClick={() => cena && dispatch({ type: actionsPartnerCena.OPEN_DIALOG, data: EntityOperation.DELETE, cena: { ...state.cena!, ...cena } })}
            disabled={!pristup}
          />
        )}
      </div>
    );
  };

  const renderPoreskaStopa = (cena: PartnerCenaReadDto) => {
    let poreskaStopa = poreskaStopaList.filter((item) => item.id === cena.poreskaStopa?.id);
    return <span>{!checkEmpty(poreskaStopa[0]?.procenat) ? poreskaStopa[0]?.procenat + " %" : ""}</span>;
  };

  const renderIznos = (cena: PartnerCenaReadDto) => {
    let valuta = valutaList.filter((item) => item.id === cena.valuta?.id);
    return <span>{cena.cena ? formatPrice(cena.cena) + " " + valuta[0]?.sifra : ""}</span>;
  };

  const vaziOd = (cena: PartnerCenaReadDto) => {
    return <div>{moment(cena.vaziOd).format(DATE_FORMAT)}</div>;
  };

  const vaziDo = (cena: PartnerCenaReadDto) => {
    return cena.vaziDo ? <div>{moment(cena.vaziDo).format(DATE_FORMAT)}</div> : <div></div>;
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.LABEL_NAZIV_USLUGE_PARTNER, filter: false, sortrable: true },
    { columnName: Labels.LABEL_VAZI_OD_PARTNER, filter: false, sortrable: true },
    { columnName: Labels.LABEL_VAZI_DO_PARTNER, filter: false, sortrable: true },
    { columnName: Labels.LABEL_POPUST_PARTNER, filter: false, sortrable: true },
    { columnName: Labels.LABEL_CENA_PARTNER, filter: false, sortrable: true },
    { columnName: Labels.LABEL_PORESKA_STOPA_PARTNER, filter: false, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list partner-cene-table">
      <Toolbar start={searchToolbar} className="mb-5" />
      {ceneLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
      ) : (
        <DataTable
          ref={dt}
          value={ceneList ?? []}
          paginator
          first={first}
          paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
          rows={tableRows}
          onPage={(e: DataTablePageEvent) => onPagePartnerCene(e.rows, e.first)}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          rowHover
          stripedRows
        >
          <Column field={"usluga.naziv"} header={Labels.LABEL_NAZIV_USLUGE_PARTNER} sortable showFilterMenu={false} />
          <Column field={"vaziOd"} body={vaziOd} header={Labels.LABEL_VAZI_OD_PARTNER} sortable showFilterMenu={false} />
          <Column field={"vaziDo"} body={vaziDo} header={Labels.LABEL_VAZI_DO_PARTNER} sortable showFilterMenu={false} />
          <Column field={"kategorijaPopusta.naziv"} header={Labels.LABEL_POPUST_PARTNER} sortable showFilterMenu={false} />
          <Column field={"cena"} body={renderIznos} header={Labels.LABEL_CENA_PARTNER} sortable showFilterMenu={false} />
          <Column field={"poreskaStopa"} body={renderPoreskaStopa} header={Labels.LABEL_PORESKA_STOPA_PARTNER} sortable showFilterMenu={false} />
          <Column body={crudBodyTemplate} />
        </DataTable>
      )}
      <DialogPartnerCena
        valuteList={valutaList}
        poreskeStopeList={poreskaStopaList}
        state={state}
        dispatch={dispatch}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
        searchSifarnikUsluga={searchSifarnikUsluga}
        sifarnikUslugaList={sifarnikUslugaList}
        popustiList={popustiList}
        invalidFields={invalidFields}
        setInvalidFields={setInvalidFields}
      />
    </div>
  );
}
