import { Divider } from "primereact/divider";
import { useContext } from "react";
import { useLabels } from "../../../../Store";
import EnumUplatilacNaplata from "../../../../infrastructure/system/EnumUplatilacNaplata";
import { formatPrice } from "../../../../infrastructure/system/Utils";
import { NaplataContext } from "../../NaplataLogical";

const UplatiociInfoPanel = () => {
  const Labels = useLabels();
  const { state, finalnaValuta } = useContext(NaplataContext);

  const stavkeNaplate = Object.values(state.usluge)
    .flatMap((stavkaNaplatePair) => stavkaNaplatePair.map((stavkaNaplate) => stavkaNaplate))
    .concat(Object.values(state.proizvodi).flatMap((stavkaNaplatePair) => stavkaNaplatePair.map((stavkaNaplate) => stavkaNaplate)));

  const partnerCene = stavkeNaplate
    .filter((stavkaNaplate) => stavkaNaplate.uplatilac?.sifra === EnumUplatilacNaplata.PARTNER && stavkaNaplate.partner)
    .map((partnerStavkaNaplate) => partnerStavkaNaplate.iznos)
    .reduce((acc, iznos) => acc + iznos, 0);

  const pacijentCene = stavkeNaplate
    .filter((stavkaNaplate) => stavkaNaplate.uplatilac?.sifra === EnumUplatilacNaplata.PACIJENT)
    .map((partnerStavkaNaplate) => partnerStavkaNaplate.iznos)
    .reduce((acc, iznos) => acc + iznos, 0);

  return (
    <div className="uplatioci-prices">
      <div className="text-l font-bold ukupno-row">
        <span>{Labels.LABEL_UKUPNO_NAPLATA}:</span>{" "}
        <span>
          {formatPrice(partnerCene + pacijentCene)} {finalnaValuta}
        </span>
      </div>
      <Divider className="divider" />
      <div className="text-l font-bold ukupno-row">
        <span>{Labels.LABEL_PACIJENT_NAPLATA}:</span>{" "}
        <span>
          {formatPrice(pacijentCene)} {finalnaValuta}
        </span>
      </div>
      <div className="text-l font-bold ukupno-row">
        <span>{Labels.LABEL_PARTNER_NAPLATA}:</span>{" "}
        <span>
          {formatPrice(partnerCene)} {finalnaValuta}
        </span>
      </div>
    </div>
  );
};

export default UplatiociInfoPanel;
