import moment from "moment/moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import PartnerController from "../../../controllers/partner/PartnerController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";

interface PartneriListLogicalType {
  partneriList: Array<any>;
  first: number;
  tableRows: number;
  changePartneriSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  partneriSearchData: SearchBaseDto;
  fetchData: () => void;
  searchPartneriByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  onPagePartneri: (rows: number, first: number) => void;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  partneriLoading: boolean;
}

export default function PartneriListLogical(): PartneriListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [partneriList, setPartneriList] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [partneriSearchData, setPartneriSearchData] = useState<SearchBaseDto>({});
  const [partneriLoading, setPartneriLoading] = useState(false);
  const { axiosSearchPartneri } = PartnerController();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LABEL_PARTNERI,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setPartneriLoading(true);
    axiosSearchPartneri(partneriSearchData)
      .then((res: any) => {
        setPartneriList(res.data.data);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setPartneriLoading, startTime);
      });
  };

  const changePartneriSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setPartneriSearchData({
      ...partneriSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchPartneriByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPagePartneri = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    partneriList,
    first,
    tableRows,
    changePartneriSearchData,
    partneriSearchData,
    fetchData,
    searchPartneriByEnter,
    onPagePartneri,
    breadCrumbItems,
    partneriLoading,
  };
}
