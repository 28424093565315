import axios from "axios";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { AppContext } from "../../../../Store";
import { EnumController } from "../../../../controllers/enum/EnumController";
import { PacijentController } from "../../../../controllers/pacijent/PacijentController";
import { checkEmpty, handleAxiosCallError, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../../infrastructure/system/Utils";
import EnumTipObavestenja from "../../../../model/obavestenja-pacijenti/EnumTipObavestenja";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";

interface AdministrativniPodaciLogicalType {
  pacijentChange: PacijentReadDto | undefined;
  setPacijentChange: any;
  polList: Array<EnumBaseReadDto> | undefined;
  updatePacijent: () => void;
  pacijentRef: any;
  pacijentLoading: boolean;
  administrativniPodaciLoading: boolean;
  setAdministrativniPodaciLoading: React.Dispatch<React.SetStateAction<boolean>>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  obavestenjeTipList: Array<EnumBaseReadDto> | undefined;
  datumRef: HTMLDivElement | any;
}

interface AdministrativniPodaciLogicalProps {
  pacijent?: PacijentReadDto;
  setPacijent?: React.Dispatch<React.SetStateAction<PacijentReadDto | undefined>>;
}

export default function AdministrativniPodaciLogical(props: AdministrativniPodaciLogicalProps): AdministrativniPodaciLogicalType {
  const { showMessage } = useContext(AppContext);
  const { pacijent, setPacijent } = props;
  const { axiosGetPacijentById } = PacijentController();
  const { axiosGetPolList, axiosGetObavestenjeTipList } = EnumController();
  const [pacijentChange, setPacijentChange] = useState<PacijentReadDto>();
  const [polList, setPolList] = useState<Array<EnumBaseReadDto>>();
  const [obavestenjeTipList, setObavestenjeTipList] = useState<Array<EnumBaseReadDto>>();
  const [pacijentLoading, setPacijentLoading] = useState(true);
  const [administrativniPodaciLoading, setAdministrativniPodaciLoading] = useState(true);
  const pacijentRef = useRef<any>();
  const datumRef = useRef<HTMLDivElement | any>(null);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = async () => {
    let startTime = moment(new Date());
    if (pacijent?.id)
      axios
        .all([axiosGetPacijentById(pacijent?.id), axiosGetPolList(), axiosGetObavestenjeTipList()])
        .then(
          axios.spread((responseGetPacijentById: any, responsePolList: any, responseObavestenjeTipList: any) => {
            setPacijentChange(responseGetPacijentById.data.data);
            setPolList(responsePolList.data.data);
            // setObavestenjeTipList(responseObavestenjeTipList.data.data);
            // obrisati ovo dole posle pustanja na test i otkomentarisati kod iznad
            let withoutSms = responseObavestenjeTipList.data.data.filter((notificationType: EnumBaseReadDto) => notificationType.sifra !== EnumTipObavestenja.SMS);
            setObavestenjeTipList(withoutSms);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPacijentLoading, startTime);
        });
  };

  const updatePacijent = () => {
    if (!validatePacijent(pacijentChange)) {
      return;
    }
    if (setPacijent) setPacijent(pacijentChange);
    pacijentRef.current.onSave(pacijentChange);
  };

  const validatePacijent = (pacijent?: PacijentReadDto) => {
    let isInvalid = false;
    if (pacijent === undefined || validateStringEmpty(pacijent?.ime)) {
      setInvalidFields((prev: any) => ({ ...prev, ime: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(pacijent?.prezime)) {
      setInvalidFields((prev: any) => ({ ...prev, prezime: true }));
      isInvalid = true;
    }
    if (checkEmpty(pacijent?.polTrenutni)) {
      setInvalidFields((prev: any) => ({ ...prev, pol: true }));
      isInvalid = true;
    }
    if (checkEmpty(pacijent?.vremeRodjenja)) {
      setInvalidFields((prev: any) => ({ ...prev, vremeRodjenja: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  return {
    pacijentChange,
    setPacijentChange,
    polList,
    pacijentRef,
    updatePacijent,
    pacijentLoading,
    administrativniPodaciLoading,
    setAdministrativniPodaciLoading,
    invalidFields,
    setInvalidFields,
    obavestenjeTipList,
    datumRef,
  };
}
