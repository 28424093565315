import axios from "axios";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { DataTableFilterEvent, DataTableFilterMeta, DataTableFilterMetaData } from "primereact/datatable";
import { useContext, useEffect, useReducer, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { SifarnikLekovaNaReceptController } from "../../../controllers/sifarnik-lekova-na-recept/SifarnikLekovaNaReceptController";
import { DATE_FORMAT, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import initialState from "../../../infrastructure/system/hooks/dialog-istorija-indikacije-reducer/initialState";
import reducer from "../../../infrastructure/system/hooks/dialog-istorija-indikacije-reducer/reducer";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SifarnikLekovaReadDto from "../../../model/sifarnik-lekova-na-recept/SifarnikLekovaReadDto";
import SifarnikLekovaSearchDto from "../../../model/sifarnik/sifarnik-lek/SifarnikLekovaSearchDto";

interface SifarnikLekovaNaReceptListLogicalType {
  sifarnikLekovaNaReceptList: Array<SifarnikLekovaReadDto>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  first: number;
  tableRows: number;
  sifarnikLekovaLoading: boolean;
  exportData: Array<any>;
  odabranSifarnikLekova: SifarnikLekovaReadDto | undefined;
  setOdabranSifarnikLekova: React.Dispatch<React.SetStateAction<SifarnikLekovaReadDto | undefined>>;
  onPageLek: (rows: number, first: number, page?: number) => void;
  closeDialog: () => void;
  openDialog: (id: number, actionType: string) => void;
  isLoading: boolean;
  renderDate: (date: Date) => string;
  state: any;
  sifarnikLekovaCount: number;
  onFilterHandler: (e: DataTableFilterEvent) => void;
  filters: DataTableFilterMeta;
}

export default function SifarnikLekovaNaReceptListLogical(): SifarnikLekovaNaReceptListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [sifarnikLekovaLoading, setSifarnikLekovaLoading] = useState(false);
  const [sifarnikLekovaNaReceptList, setSifarnikLekovaNaReceptList] = useState<Array<SifarnikLekovaReadDto>>([]);
  const [odabranSifarnikLekova, setOdabranSifarnikLekova] = useState<SifarnikLekovaReadDto | undefined>();
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [sifarnikLekovaCount, setSifarnikLekovaCount] = useState<number>(0);
  const { axiosSearchSifarnikLekovaNaReceptList, axiosGetIstorijuCeneLeka, axiosGetIndikacijeLeka, axiosCountSifarnikLekovaNaReceptList, axiosGetDodatneIndikacijeLeka } =
    SifarnikLekovaNaReceptController();
  const [sifarnikLekovaSearchDto, setsifarnikLekovaSearchDto] = useState<SifarnikLekovaSearchDto>({ page: 0, size: 10 });
  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LABEL_SIFARNIK_LEKOVA_NA_RECEPT,
    },
  ];
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    sifra: { value: null, matchMode: FilterMatchMode.CONTAINS },
    naziv: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "proizvodjac.naziv": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "atc.sifra": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "atc.naziv": { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  useEffectOnce(() => {
    setFirst(0);
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setSifarnikLekovaLoading(true);
    axiosCountSifarnikLekovaNaReceptList(sifarnikLekovaSearchDto)
      .then((res) => {
        setSifarnikLekovaCount(res.data.data);
      })
      .catch((error: any) => handleAxiosCallError(showMessage, error));
    axiosSearchSifarnikLekovaNaReceptList(sifarnikLekovaSearchDto)
      .then((res) => {
        setSifarnikLekovaNaReceptList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((lek: SifarnikLekovaReadDto) => {
          exportList.push({
            [Labels.SIFARNIK_LEKOVA_NA_RECEPT_JKL]: lek.sifra,
            [Labels.SIFARNIK_LEKOVA_NA_RECEPT_NAZIV]: lek.naziv,
            [Labels.SIFARNIK_LEKOVA_NA_RECEPT_PROIZVODJAC]: lek.proizvodjac.naziv,
            [Labels.SIFARNIK_LEKOVA_NA_RECEPT_ATC_GRUPA]: lek.atc.sifra,
            [Labels.SIFARNIK_LEKOVA_NA_RECEPT_CENA]: lek.cena,
          });
        });
        setExportData(exportList);
      })
      .catch((error: any) => handleAxiosCallError(showMessage, error))
      .finally(() => {
        skeletonTimeout(setSifarnikLekovaLoading, startTime);
        setFilters({
          sifra: { value: sifarnikLekovaSearchDto.jkl, matchMode: FilterMatchMode.CONTAINS },
          naziv: { value: sifarnikLekovaSearchDto.naziv, matchMode: FilterMatchMode.CONTAINS },
          "proizvodjac.naziv": { value: sifarnikLekovaSearchDto.proizvodjac, matchMode: FilterMatchMode.CONTAINS },
          "atc.sifra": { value: sifarnikLekovaSearchDto.atc, matchMode: FilterMatchMode.CONTAINS },
          "atc.naziv": { value: sifarnikLekovaSearchDto.inn, matchMode: FilterMatchMode.CONTAINS },
        });
      });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sifarnikLekovaSearchDto]);

  const onPageLek = (rows: number, first: number, page?: number) => {
    setTableRows(rows);
    setFirst(first);
    setsifarnikLekovaSearchDto({ ...sifarnikLekovaSearchDto, page: page, size: rows });
  };

  const closeDialog = () => {
    dispatch({ type: "close" });
  };

  const openDialog = (lekId: number, actionType: string) => {
    setIsLoading(true);
    const startTime = moment(new Date());
    const requestIndikacije = axiosGetIndikacijeLeka(lekId);
    const requestDodatneIndikacije = axiosGetDodatneIndikacijeLeka(lekId);
    actionType === "istorija"
      ? axiosGetIstorijuCeneLeka(lekId)
          .then((res: { data: { data: any } }) => {
            dispatch({ type: actionType, value: res.data.data });
          })
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
          })
          .finally(() => {
            skeletonTimeout(setIsLoading, startTime);
          })
      : axios
          .all([requestIndikacije, requestDodatneIndikacije])
          .then(
            axios.spread((responseIndikacije, responseDodatneIndikacije) => {
              dispatch({ type: actionType, value: responseIndikacije.data.data, value_additional: responseDodatneIndikacije.data.data });
            })
          )
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
          })
          .finally(() => {
            skeletonTimeout(setIsLoading, startTime);
          });
  };

  const renderDate = (date: Date) => (date ? moment(date).format(DATE_FORMAT) : "");
  const onFilterHandler = (e: DataTableFilterEvent) => {
    let sifarnikLekovaSearchDto: SifarnikLekovaSearchDto = {};
    if (!isNaN((e.filters?.sifra as DataTableFilterMetaData).value)) {
      sifarnikLekovaSearchDto.jkl = (e.filters?.sifra as DataTableFilterMetaData).value;
    } else {
      sifarnikLekovaSearchDto.jkl = undefined;
    }
    sifarnikLekovaSearchDto.naziv = (e.filters?.naziv as DataTableFilterMetaData).value;
    sifarnikLekovaSearchDto.proizvodjac = (e.filters["proizvodjac.naziv"] as DataTableFilterMetaData).value;
    sifarnikLekovaSearchDto.atc = (e.filters["atc.sifra"] as DataTableFilterMetaData).value;
    sifarnikLekovaSearchDto.inn = (e.filters["atc.naziv"] as DataTableFilterMetaData).value;
    sifarnikLekovaSearchDto.page = 0;
    sifarnikLekovaSearchDto.size = tableRows;
    setFirst(0);
    setFilters(e.filters);

    setsifarnikLekovaSearchDto(sifarnikLekovaSearchDto);
  };

  return {
    breadCrumbItems,
    sifarnikLekovaLoading,
    sifarnikLekovaNaReceptList,
    first,
    tableRows,
    exportData,
    odabranSifarnikLekova,
    setOdabranSifarnikLekova,
    onPageLek,
    closeDialog,
    openDialog,
    isLoading,
    renderDate,
    state,
    sifarnikLekovaCount,
    onFilterHandler,
    filters,
  };
}
