import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumTipKategorijePopusta from "../../../infrastructure/system/EnumTipKategorijePopusta";
import { formatPrice, tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import { useNamenaKategorijePopustaList } from "../../../infrastructure/system/hooks/sifarnici/namena-kategorije-popusta/useNamenaKategorijePopustaList";
import { useTipKategorijePopustaList } from "../../../infrastructure/system/hooks/sifarnici/tip-kategorije-popusta/useTipKategorijePopustaList";
import { useTableData } from "../../../infrastructure/system/hooks/tables/useTableData";
import SifarnikKategorijaPopustaReadDto from "../../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import SifarnikKategorijaPopustaListLogical from "./SifarnikKategorijaPopustaListLogical";
import { useSifarnikKategorijaPopustaList } from "./hooks/useSifarnikKategorijaPopustaList";

export default function SifarnikKategorijaPopustaListView() {
  const { dt, openSifarnikKategorijaPopusta, breadCrumbItems } = SifarnikKategorijaPopustaListLogical();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const { first, tableRows, onPage } = useTableData();
  const { sifarnikKategorijaPopustaList, isLoading, exportList: exportData } = useSifarnikKategorijaPopustaList();
  const { tipKategorijePopustaList } = useTipKategorijePopustaList();
  const { namenaKategorijePopustaList } = useNamenaKategorijePopustaList();

  const addButton = () => (
    <Button
      label={Labels.SIFARNIK_KATEGORIJA_POPUSTA_DODAJ_KATEGORIJU_POPUSTA}
      tooltip={Labels.SIFARNIK_KATEGORIJA_POPUSTA_TITLE_CREATE}
      tooltipOptions={tooltipOptionsBottom}
      className="p-button-success mr-3 mb-4 sm:mb-4 md:mb-0"
      onClick={() => {
        openSifarnikKategorijaPopusta(EntityOperation.CREATE);
      }}
      disabled={!pristup}
    />
  );

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={isLoading} data={exportData} name={Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA} dt={dt} />
      </>
    );
  };

  const renderPopust = (kategorijaPopusta: SifarnikKategorijaPopustaReadDto) => {
    if (kategorijaPopusta.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.FIKSNO) return <span>{formatPrice(kategorijaPopusta.popust)}</span>;
    return <span>{kategorijaPopusta.popust + "%"}</span>;
  };

  const crudBodyTemplate = (rowSifarnikKategorijaPopusta: SifarnikKategorijaPopustaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_KATEGORIJA_POPUSTA_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowSifarnikKategorijaPopusta && openSifarnikKategorijaPopusta(EntityOperation.UPDATE, rowSifarnikKategorijaPopusta.id)}
        />
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_KATEGORIJA_POPUSTA_TITLE_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowSifarnikKategorijaPopusta && openSifarnikKategorijaPopusta(EntityOperation.DELETE, rowSifarnikKategorijaPopusta.id)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.SIFARNIK_KATEGORIJA_POPUSTA_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_KATEGORIJA_POPUSTA_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_TIP_KATEGORIJE, filter: true, sortrable: true },
    { columnName: Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_NAMENA_KATEGORIJE, filter: true, sortrable: true },
    { columnName: Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_POPUST, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_KATEGORIJA_POPUSTA_PRIKAZNI_REDOSLED, filter: true, sortrable: true },
  ];

  const tipKategorijePopustaFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={tipKategorijePopustaList?.map((e) => {
          return { label: e.naziv, value: e.naziv };
        })}
        onChange={(e) => dt.current.filter(e.value, "tipKategorijePopusta.naziv", "equals")}
        className="p-column-filter"
        showClear
        placeholder={Labels.LABEL_SIFARNIK_KATEGORIJE_FILTER_TIP_PLACEHOLDER}
      />
    );
  };
  const namenaKategorijePopustaFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={namenaKategorijePopustaList?.map((e) => {
          return { label: e.naziv, value: e.naziv };
        })}
        onChange={(e) => dt.current.filter(e.value, "namenaKategorijePopusta.naziv", "equals")}
        className="p-column-filter"
        showClear
        placeholder={Labels.LABEL_SIFARNIK_KATEGORIJE_FILTER_NAMENA_PLACEHOLDER}
      />
    );
  };
  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={addButton} right={downloadButton} />
        {isLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={sifarnikKategorijaPopustaList ?? []}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={onPage}
            exportFilename={Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.SIFARNIK_KATEGORIJA_POPUSTA_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.SIFARNIK_KATEGORIJA_POPUSTA_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column
              field={"tipKategorijePopusta.naziv"}
              header={Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_TIP_KATEGORIJE}
              filter
              filterMatchMode="contains"
              showFilterMenu={false}
              sortable
              filterElement={tipKategorijePopustaFilterTemplate}
            />
            <Column
              field={"namenaKategorijePopusta.naziv"}
              header={Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_NAMENA_KATEGORIJE}
              filter
              filterMatchMode="contains"
              showFilterMenu={false}
              sortable
              filterElement={namenaKategorijePopustaFilterTemplate}
            />
            <Column body={renderPopust} header={Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA_POPUST} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"prikazniRedosled"} header={Labels.SIFARNIK_KATEGORIJA_POPUSTA_PRIKAZNI_REDOSLED} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
