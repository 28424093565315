import { Editor } from "primereact/editor";
import EnumPol from "../../../infrastructure/system/EnumPol";
import { DATE_FORMAT, formatDate2, TIME_FORMAT } from "../../../infrastructure/system/Utils";
import PorukaReadDto from "../../../model/poruke/PorukaReadDto";
import { useLabels } from "../../../Store";

interface PorukaSadrzajProps {
  poruka: PorukaReadDto;
  parent?: boolean;
}

export default function PorukaSadrzaj({ poruka, parent }: PorukaSadrzajProps) {
  const Labels = useLabels();

  return parent ? (
    <div className="ml-1 text-primary">
      <p className="mt-3 mb-1 text-primary">
        {`${Labels.PORUKA_RODITELJ_NASLOV_1} ${formatDate2(poruka?.vremeKreiranja, DATE_FORMAT)} ${Labels.PORUKA_RODITELJ_NASLOV_2} ${formatDate2(poruka?.vremeKreiranja, TIME_FORMAT)}
        ${poruka?.radnikKreirao.titula ?? ""} ${poruka?.radnikKreirao.ime} ${poruka?.radnikKreirao.prezime} 
        ${
          poruka.radnikKreirao.polTrenutni?.sifra === EnumPol.MUSKI
            ? Labels.PORUKA_RODITELJ_NASLOV_3_MUSKI
            : poruka.radnikKreirao.polTrenutni?.sifra === EnumPol.ZENSKI
            ? Labels.PORUKA_RODITELJ_NASLOV_3_ZENSKI
            : Labels.PORUKA_RODITELJ_NASLOV_3
        }`}
      </p>
      <div className="border-left-2 border-primary text-600 pl-2">
        <Editor value={poruka.sadrzaj} readOnly showHeader={false} className="border-none" />
        {poruka.porukaRoditelj && <PorukaSadrzaj poruka={poruka.porukaRoditelj} parent />}
      </div>
    </div>
  ) : (
    <div>
      <h2 className="mt-3 overflow-hidden text-overflow-ellipsis">{poruka?.naslov}</h2>
      <Editor value={poruka.sadrzaj} readOnly showHeader={false} className="border-none" />
      {poruka.porukaRoditelj && <PorukaSadrzaj poruka={poruka.porukaRoditelj} parent />}
    </div>
  );
}
