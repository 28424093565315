import { BreadCrumb } from "primereact/breadcrumb";
import { Dropdown } from "primereact/dropdown";
import { SplitButton } from "primereact/splitbutton";
import { forwardRef, Ref, useContext, useImperativeHandle } from "react";
import { selectOrganizacionaJedinica } from "../../../infrastructure/system/Utils";
import OrganizacionaJedinicaCvorDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaCvorDto";
import UstanovaReadDto from "../../../model/ustanova/UstanovaReadDto";
import { AppContext, useLabels } from "../../../Store";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import OrganizacionaJedinica from "./OrganizacionaJedinica";
import UstanovaNavbarLogical from "./UstanovaNavbarLogical";

const chevronRight = <span className="p-breadcrumb-chevron pi pi-chevron-right align-self-center ml-1 mr-1" />;

interface OrganizacioneJediniceNavbarType {
  printProtokolOrZakazaniTerminiList?: (isProtokol: boolean) => void;
  showPrint?: boolean;
}

const OrganizacioneJediniceNavbar = forwardRef((props: OrganizacioneJediniceNavbarType, ref: Ref<any>) => {
  const Labels = useLabels();
  const { printProtokolOrZakazaniTerminiList, showPrint } = props;
  const { updateOdabranaOrganizacionaJedinicaList, odabranaOrganizacionaJedinicaList } = useContext(AppContext);
  const { radnikUstanovaList, ustanova, ustanovaOrgJedListLoading, changeUstanova, ustanovaOrgJedList } = UstanovaNavbarLogical();

  useImperativeHandle(ref, () => ({
    ustanovaOrgJedList,
  }));

  if (!ustanovaOrgJedList || ustanovaOrgJedListLoading) {
    return (
      <div className="flex align-items-center">
        <SkeletonInputItem width="w-2" />
      </div>
    );
  }

  const BCradnikUstanova = () => (
    <>
      {radnikUstanovaList &&
        (radnikUstanovaList.length === 1 ? (
          <div className="ml-0">{ustanovaOrgJedList?.ustanova?.naziv}</div>
        ) : (
          <Dropdown
            options={radnikUstanovaList}
            optionValue="sifra"
            optionLabel="naziv"
            value={ustanova?.sifra ?? ""}
            onChange={(e) => {
              let selectedUstanova = radnikUstanovaList!.find((x: UstanovaReadDto) => x.sifra === e.target.value);
              selectedUstanova && changeUstanova(selectedUstanova);
            }}
            emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
          />
        ))}
    </>
  );

  const BCfirstOdabranaOrgJed = () => (
    <div className="flex align-items-center">
      <Dropdown
        options={ustanovaOrgJedList?.organizacioneJedinice}
        resetFilterOnHide
        filter
        value={odabranaOrganizacionaJedinicaList.length > 0 ? odabranaOrganizacionaJedinicaList[0].organizacionaJedinica.id : undefined}
        optionLabel="organizacionaJedinica.naziv"
        optionValue="organizacionaJedinica.id"
        onChange={(e) => {
          let tempSelectedOrgJed = ustanovaOrgJedList?.organizacioneJedinice.find((orgJed: OrganizacionaJedinicaCvorDto) => orgJed.organizacionaJedinica.id === e.value);
          const orgJedArray = tempSelectedOrgJed ? selectOrganizacionaJedinica([], tempSelectedOrgJed) : [];
          updateOdabranaOrganizacionaJedinicaList(orgJedArray);
        }}
        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
      />
      {odabranaOrganizacionaJedinicaList?.[0]?.podredjeneOrganizacioneJedinice.length > 0 && (
        <OrganizacionaJedinica chevronRight={chevronRight} id={1} organizacionaJedinica={odabranaOrganizacionaJedinicaList[0]} vrednosti={odabranaOrganizacionaJedinicaList} />
      )}
    </div>
  );

  const renderPrintButton = () => {
    const printItems = [
      {
        label: Labels.PROTOKOL,
        command: () => (printProtokolOrZakazaniTerminiList ? printProtokolOrZakazaniTerminiList(true) : null),
        visible:
          odabranaOrganizacionaJedinicaList.length > 0 && odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1]?.organizacionaJedinica?.protokol?.jasperFajl?.length > 0,
      },
      {
        label: Labels.ZAKAZIVANJE_CEKAONICA,
        command: () => (printProtokolOrZakazaniTerminiList ? printProtokolOrZakazaniTerminiList(false) : null),
      },
    ];

    return <SplitButton label={Labels.LABEL_PRINT} icon="pi pi-print" className="print-btn" model={printItems} disabled={!odabranaOrganizacionaJedinicaList?.[0]?.organizacionaJedinica?.id} />;
  };

  return (
    <div className="flex flex-wrap align-items-center justify-content-between layout-org-jed-navbar mb-2">
      <BreadCrumb
        className={`py-2 col-12 ${showPrint ? "md:col-9" : ""}`}
        model={[{ template: BCradnikUstanova }, ...(ustanovaOrgJedList?.organizacioneJedinice?.length > 0 ? [{ template: BCfirstOdabranaOrgJed }] : [])]}
      />
      {showPrint && (
        <div className="col-12 md:col-2 mt-2 md:mt-0 py-1 pl-0 md:pl-3 lg:pl-4 flex justify-content-start md:justify-content-end align-items-center print-button">{renderPrintButton()}</div>
      )}
    </div>
  );
});

export default OrganizacioneJediniceNavbar;
