import moment from "moment";
import { useMemo } from "react";
import EnumTerminTip from "../../../../../infrastructure/system/EnumTerminTip";
import { formatHour } from "../../../../../infrastructure/system/Utils";
import PacijentReadDto from "../../../../../model/pacijent/PacijentReadDto";
import Termin, { TerminType } from "../../termin/Termin";
import RadniDanLogical from "./RadniDanLogical";

interface RadniDanProps {
  radniDanPocetak: moment.Moment;
  radniDanKraj: moment.Moment;
  zakazaniTerminList: Array<TerminType>;
  radniPeriodList: Array<TerminType>;
  trajanjeTermina: number;
  otvoriDialogTermin: (vreme: TerminType) => void;
  vertical: boolean;
  hours: Array<number>;
  handleDragStart: (event: React.DragEvent<HTMLDivElement>, termin: TerminType) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>, canBeDraggedOver: boolean) => void;
  handleDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>, terminCanBeDropped: boolean, termin: TerminType) => void;
  dragging: boolean;
  makeSatnicaList: (
    zakazaniTerminList: TerminType[],
    radniDanPocetak: moment.Moment,
    radniDanKraj: moment.Moment,
    radniPeriodList: TerminType[],
    trajanjeTermina: number,
    vertical: boolean
  ) => TerminType[];
  selectedPacijent?: PacijentReadDto;
}

export default function RadniDan({
  radniDanPocetak,
  radniDanKraj,
  zakazaniTerminList,
  radniPeriodList,
  trajanjeTermina,
  otvoriDialogTermin,
  vertical,
  hours,
  handleDragStart,
  handleDragOver,
  handleDragLeave,
  handleDrop,
  dragging,
  makeSatnicaList,
  selectedPacijent,
}: RadniDanProps) {
  const { satnicaList, showNowLine, nowPocetakRadaDiff, radniDanDuration } = RadniDanLogical(
    radniDanPocetak,
    radniDanKraj,
    zakazaniTerminList,
    radniPeriodList,
    trajanjeTermina,
    vertical,
    makeSatnicaList
  );

  const currentTimeLineStyle = useMemo(() => {
    return {
      display: "block",
      marginTop: radniDanDuration * 0.25 + nowPocetakRadaDiff * 0.25 + "rem",
    };
  }, [radniDanDuration, nowPocetakRadaDiff]);

  const currentTimeDotStyle = useMemo(() => {
    return {
      top: nowPocetakRadaDiff * 0.25 + "rem",
    };
  }, [nowPocetakRadaDiff]);

  return vertical ? (
    <>
      {satnicaList?.map((termin: TerminType) => (
        <Termin
          key={termin?.zakazanTerminTip?.sifra === EnumTerminTip.VANREDAN ? termin?.id : termin.vremeZakazanoOd.toString()}
          termin={termin}
          otvoriDialogTermin={otvoriDialogTermin}
          vertical={vertical}
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDragLeave={handleDragLeave}
          handleDrop={handleDrop}
          dragging={dragging}
          selectedPacijent={selectedPacijent}
        />
      ))}
      {showNowLine && (
        <div className="hc-scheduler-appointment hc-scheduler-appointments-row">
          <div style={currentTimeLineStyle}>
            <div className="hc-scheduler-timeline-line" />
          </div>
          <div className="hc-scheduler-timeline" style={currentTimeDotStyle} />
        </div>
      )}
    </>
  ) : (
    <div className="mb-4 border-noround-bottom border-bottom-1 border-gray-500">
      {hours?.map((hour: number) => (
        <div className=" w-full flex" key={hour}>
          <div className="align-self-center font-bold mr-4 w-4rem">{`${formatHour(hour)} - ${formatHour(hour + 1)}`}</div>

          <div className="grid align-items-center p-0 w-full mr-0 ml-0 mt-0">
            {satnicaList
              .filter(
                (satnica: TerminType) =>
                  satnica.vremeZakazanoOd.get("hour") === hour ||
                  (satnica.vremeZakazanoOd.get("hour") < hour && satnica.vremeZakazanoDo.get("hour") >= hour && satnica.vremeZakazanoDo.get("minute") !== 0)
              )
              .map((termin: TerminType) => (
                <div
                  className={
                    hour > termin.vremeZakazanoOd.get("hour") && hour < termin.vremeZakazanoDo.get("hour")
                      ? `col-12 p-0 `
                      : hour === termin.vremeZakazanoOd.get("hour") && termin.vremeZakazanoOd.get("hour") !== termin.vremeZakazanoDo.get("hour")
                      ? `col-${(60 - termin.vremeZakazanoOd.get("minute")) / 5} p-0`
                      : hour === termin.vremeZakazanoDo.get("hour") && hour > termin.vremeZakazanoOd.get("hour")
                      ? `col-${termin.vremeZakazanoDo.get("minute") / 5} p-0`
                      : `col-${(moment.duration(termin.vremeZakazanoDo.diff(termin.vremeZakazanoOd)).asMinutes() % 60) / 5} p-0 `
                  }
                  key={termin?.zakazanTerminTip?.sifra === EnumTerminTip.VANREDAN ? termin?.id : termin.vremeZakazanoOd.toString()}
                >
                  <Termin
                    key={termin?.zakazanTerminTip?.sifra === EnumTerminTip.VANREDAN ? termin?.id : termin.vremeZakazanoOd.toString()}
                    termin={termin}
                    otvoriDialogTermin={otvoriDialogTermin}
                    vertical={vertical}
                  />
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}
