import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { useLabels } from "../../../Store";
import DialogComponent from "../../dialog-component/DialogComponent";
import PretragaPacijenata from "./PretragaPacijenata";
import RezultatPretrage from "./RezultatPretrage";
import SpajanjePacijenataLogical from "./SpajanjePacijenataLogical";
import RazdvajanjePacijenataView from "./razdvajanje-pacijenata/RazdvajanjePacijenataView";

const SpajanjePacijenataView = () => {
  const {
    breadCrumbItems,
    leftPatientSearchValues,
    setLeftPatientSearchValues,
    rightPatientSearchValues,
    setRightPatientSearchValues,
    prepisiPodatke,
    showMergePatients,
    setShowMergePatients,
    leftSelected,
    setLeftSelected,
    rightSelected,
    setRightSelected,
    searchPatients,
    leftPatientsList,
    rightPatientsList,
    leftFirst,
    setLeftFirst,
    leftTableRows,
    rightFirst,
    setRightFirst,
    rightTableRows,
    onPageSpajanje,
    leftSearchTableVisible,
    rightSearchTableVisible,
    leftResultsLoading,
    rightResultsLoading,
    mergePatients,
  } = SpajanjePacijenataLogical();
  const Labels = useLabels();

  return (
    <div className="layout-generic-content-list spajanje-pacijenata-layout">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <TabView className="mt-3" renderActiveOnly={false}>
          <TabPanel header={Labels.LABEL_SPAJANJE_PACIJENATA}>
            <div className="flex flex-row justify-content-between my-4">
              <div className="flex justify-content-start flex-1 mr-5">
                <PretragaPacijenata
                  patient={leftPatientSearchValues}
                  setPatient={setLeftPatientSearchValues}
                  searchPatients={searchPatients}
                  tableRows={leftTableRows}
                  setFirst={setLeftFirst}
                  isLeftTable
                />
              </div>
              <div className="flex flex-column align-items-center flex-none mx-1 xl:mx-5">
                <Button icon="pi pi-arrow-right" className="p-button-outlined" onClick={prepisiPodatke} />
                <span className="font-bold mt-2">{Labels.LABEL_SPAJANJE_PACIJENATA_PREPISI}</span>
              </div>
              <div className="flex justify-content-end flex-1 ml-5">
                <PretragaPacijenata patient={rightPatientSearchValues} setPatient={setRightPatientSearchValues} searchPatients={searchPatients} tableRows={rightTableRows} setFirst={setRightFirst} />
              </div>
            </div>
            <div className="flex flex-row justify-content-between mt-7">
              {leftSearchTableVisible ? (
                <div className="flex flex-column justify-content-start flex-1 mr-5">
                  <div className="text-xl pb-5 font-bold">{Labels.LABEL_SPAJANJE_PACIJENATA_REZULTAT_PRETRAGE} 1</div>
                  <RezultatPretrage
                    selected={leftSelected}
                    setSelected={setLeftSelected}
                    data={leftPatientsList}
                    rightSelected={rightSelected}
                    isLeftTable
                    first={leftFirst}
                    tableRows={leftTableRows}
                    onPageSpajanje={onPageSpajanje}
                    resultsLoading={leftResultsLoading}
                  />
                </div>
              ) : (
                <div className="flex flex-column justify-content-start flex-1 mr-5"></div>
              )}
              {rightPatientsList.length > 0 && leftPatientsList.length > 0 ? (
                <div className="flex flex-column align-items-center flex-none justify-content-center mx-1 xl:mx-5">
                  <Button icon="pi pi-link" className="p-button-outlined" onClick={() => setShowMergePatients(true)} disabled={!leftSelected || !rightSelected} />
                  <span className="font-bold mt-2">{Labels.LABEL_SPAJANJE_PACIJENATA_SPOJI_PACIJENTE}</span>
                </div>
              ) : (
                <div className="flex flex-column align-items-center flex-none mx-1 xl:mx-5"></div>
              )}
              {rightSearchTableVisible ? (
                <div className="flex flex-column flex-1 ml-5">
                  <div className="text-xl pb-5 font-bold">{Labels.LABEL_SPAJANJE_PACIJENATA_REZULTAT_PRETRAGE} 2</div>
                  <RezultatPretrage
                    selected={rightSelected}
                    setSelected={setRightSelected}
                    data={rightPatientsList}
                    leftSelected={leftSelected}
                    first={rightFirst}
                    tableRows={rightTableRows}
                    onPageSpajanje={onPageSpajanje}
                    resultsLoading={rightResultsLoading}
                  />
                </div>
              ) : (
                <div className="flex flex-column flex-1 ml-5"></div>
              )}
            </div>
          </TabPanel>
          <TabPanel header={Labels.LABEL_SPAJANJE_PACIJENATA_ARHIVA}>
            <RazdvajanjePacijenataView />
          </TabPanel>
        </TabView>
      </Panel>
      <DialogComponent
        header=""
        content={
          <div className="text-center">
            <div className="text-lg font-bold mb-4">{Labels.LABEL_SPAJANJE_PACIJENATA_DA_LI_STE_SIGURNI}</div>
            <div className="text-lg text-warning">{Labels.NAPOMENA}:</div>
            <div className="text-warning">
              {Labels.LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_1}
              <b>{Labels.LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_2}</b>
              {Labels.LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_3}:
            </div>
            <div className="text-warning">{leftSelected?.id}</div>
          </div>
        }
        visible={showMergePatients}
        onHide={() => setShowMergePatients(false)}
        footer={
          <div className="flex justify-content-center">
            <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-outlined mr-4" onClick={() => setShowMergePatients(false)} />
            <Button label={Labels.POTVRDI} icon="pi pi-check" className="ml-4" onClick={mergePatients} />
          </div>
        }
      />
    </div>
  );
};

export default SpajanjePacijenataView;
