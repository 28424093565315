import { Button } from "primereact/button";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from "primereact/multiselect";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../Store";
import { checkEmpty, hrefForDownloadAttachment } from "../../../infrastructure/system/Utils";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ConfirmComponent from "../../confirm-component/ConfirmComponent";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import UstanovaGridLogical from "./UstanovaGridLogical";
interface UstanovaGridViewProps {
  isDisabled: boolean;
}
export default function UstanovaGridView(props: UstanovaGridViewProps) {
  const Labels = useLabels();
  const {
    ustanovaGrid,
    setUstanovaGrid,
    onSave,
    radniDani,
    trajanjeTerminaList,
    ustanvovaGridLoading,
    radniDaniMap,
    documentUploadHandler,
    zakazanTerminPacijentJasperDocument,
    setZakazanTerminPacijentJasperDocument,
    invalidFields,
    setInvalidFields,
  } = UstanovaGridLogical();

  const { isDisabled } = props;
  const { pristup, nadjimed } = useContext(AppContext);
  const noSkeleton: boolean = !ustanvovaGridLoading;
  const radniDaniTemplate = (item: any) => {
    if (ustanovaGrid?.ustanovaGridRadniDanList[0] === item) {
      return <>{radniDaniMap && radniDaniMap.get(item)?.naziv}</>;
    } else {
      return <>{radniDaniMap && ", " + radniDaniMap.get(item)?.naziv}</>;
    }
  };
  const cancelOptions = { className: "class-visibility-hidden" };
  const documentUploadRef = useRef<any>(null);

  return (
    <div className="">
      <div className="grid align-items-center p-0">
        <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
          <div className="font-bold">{Labels.USTANOVA_GRID_VREME_TRAJANJA_PREGLEDA}</div>
        </div>
        <div className="col-12 sm:col-8 p-fluid p-0">
          {noSkeleton ? (
            <Dropdown
              options={trajanjeTerminaList}
              resetFilterOnHide
              value={ustanovaGrid?.vremeTrajanjaPregledaEnum?.sifra ?? ""}
              disabled={!pristup || isDisabled}
              optionLabel="naziv"
              optionValue="sifra"
              onChange={(e) => {
                setUstanovaGrid({
                  ...ustanovaGrid!,
                  vremeTrajanjaPregledaEnum: {
                    sifra: trajanjeTerminaList!.find((x: EnumBaseReadDto) => x.sifra === e.target.value)?.sifra!,
                  },
                });
              }}
              emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
            />
          ) : (
            <SkeletonInputItem />
          )}
        </div>
        <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
          <div className="font-bold">{Labels.USTANOVA_GRID_VANREDNI_TERMINI}</div>
        </div>
        <div className="col-12 sm:col-8 p-fluid p-0">
          {noSkeleton ? (
            <InputSwitch
              checked={ustanovaGrid?.vanredniTermini ?? false}
              disabled={!pristup || isDisabled}
              onChange={(e) => {
                setUstanovaGrid({
                  ...ustanovaGrid!,
                  vanredniTermini: e.target.value,
                });
              }}
            />
          ) : (
            <SkeletonInputItem />
          )}
        </div>
        <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
          <div className="font-bold">{Labels.USTANOVA_GRID_RADNI_DANI_U_NEDELJI}</div>
        </div>
        <div className="col-12 sm:col-8 p-fluid p-0">
          {noSkeleton ? (
            <MultiSelect
              value={ustanovaGrid?.ustanovaGridRadniDanList ?? ""}
              options={radniDani ?? []}
              disabled={!pristup || isDisabled}
              onChange={(e) => {
                let radniDaniSifre: Array<string> = e.value;
                radniDaniSifre.sort((radniDanSifra1, radniDanSifra2) => (radniDaniMap?.get(radniDanSifra1)?.prikazniRedosled ?? 0) - (radniDaniMap?.get(radniDanSifra2)?.prikazniRedosled ?? 0));
                setUstanovaGrid({
                  ...ustanovaGrid!,
                  ustanovaGridRadniDanList: radniDaniSifre,
                });
              }}
              optionLabel="naziv"
              optionValue="sifra"
              emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              selectedItemTemplate={radniDaniTemplate}
            />
          ) : (
            <SkeletonInputItem />
          )}
        </div>
        <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
          <div className="font-bold">{Labels.USTANOVA_GRID_OMOGUCENA_REZERVACIJA}</div>
        </div>
        <div className="col-12 sm:col-8 p-fluid p-0">
          {noSkeleton ? (
            <InputSwitch
              checked={ustanovaGrid?.omogucenaRezervacijaTermina ?? !checkEmpty(ustanovaGrid?.podrazumevanaDuzinaTrajanjaRezervacijeTermina)}
              disabled={!pristup || isDisabled || nadjimed}
              onChange={(e) => {
                if (!e.target.value) {
                  setUstanovaGrid({
                    ...ustanovaGrid!,
                    omogucenaRezervacijaTermina: e.target.value,
                    podrazumevanaDuzinaTrajanjaRezervacijeTermina: null,
                  });
                } else {
                  setUstanovaGrid({
                    ...ustanovaGrid!,
                    omogucenaRezervacijaTermina: e.target.value,
                    podrazumevanaDuzinaTrajanjaRezervacijeTermina: 30,
                  });
                }
              }}
            />
          ) : (
            <SkeletonInputItem />
          )}
        </div>
        {(ustanovaGrid?.omogucenaRezervacijaTermina || ustanovaGrid?.podrazumevanaDuzinaTrajanjaRezervacijeTermina || nadjimed) && (
          <>
            <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
              <div className="font-bold">
                {nadjimed ? Labels.USTANOVA_GRID_PODRAZUMEVANA_DUZINA_TRAJANJA_REZERVACIJE + Labels.SPECIAL_CHAR_REQUIRED : Labels.USTANOVA_GRID_PODRAZUMEVANA_DUZINA_TRAJANJA_REZERVACIJE}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              {noSkeleton ? (
                <>
                  <InputNumber
                    value={ustanovaGrid?.podrazumevanaDuzinaTrajanjaRezervacijeTermina ?? null}
                    disabled={!pristup || isDisabled}
                    onChange={(e) => {
                      setUstanovaGrid({
                        ...ustanovaGrid!,
                        podrazumevanaDuzinaTrajanjaRezervacijeTermina: e.value,
                      });
                      setInvalidFields((prev) => ({ ...prev, podrazumevanaDuzinaTrajanjaRezervacijeTermina: false }));
                    }}
                    className={invalidFields?.podrazumevanaDuzinaTrajanjaRezervacijeTermina && "p-invalid"}
                  />
                  {invalidFields?.podrazumevanaDuzinaTrajanjaRezervacijeTermina && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                </>
              ) : (
                <SkeletonInputItem />
              )}
            </div>
          </>
        )}
        {nadjimed && (
          <>
            <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
              <div className="font-bold">{Labels.USTANOVA_GRID_MAKSIMALNA_DUZINA_TRAJANJA_REZERVACIJE}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              {noSkeleton ? (
                <InputNumber
                  value={ustanovaGrid?.maksimalnaDuzinaTrajanjaRezervacijeTermina ?? null}
                  disabled={!pristup || isDisabled}
                  onChange={(e) => {
                    setUstanovaGrid({
                      ...ustanovaGrid!,
                      maksimalnaDuzinaTrajanjaRezervacijeTermina: e.value,
                    });
                  }}
                />
              ) : (
                <SkeletonInputItem />
              )}
            </div>
          </>
        )}
        <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
          <div className="font-bold">{Labels.USTANOVA_GRID_SPISAK_PACIJENATA_JASPER}</div>
        </div>
        <div className="col-12 sm:col-8 p-fluid p-0">
          {noSkeleton ? (
            <div className="flex flex-row flex-wrap">
              <FileUpload
                ref={documentUploadRef}
                auto
                mode="basic"
                accept=".jrxml"
                name={Labels.USTANOVA_GRID_SPISAK_PACIJENATA_JASPER}
                cancelOptions={cancelOptions}
                multiple={false}
                customUpload
                uploadHandler={(e) => {
                  documentUploadHandler(e);
                  documentUploadRef.current.clear();
                }}
                chooseLabel={Labels.USTANOVA_GRID_SPISAK_PACIJENATA_JASPER_CHOOSE}
                disabled={!pristup || isDisabled || (zakazanTerminPacijentJasperDocument && zakazanTerminPacijentJasperDocument?.naziv?.length > 0)}
              />
              {zakazanTerminPacijentJasperDocument && (
                <div className="col-8 p-fluid p-0 ml-2 mb-2">
                  <div className="flex flex-row flex-wrap">
                    <div className="mr-2 mt-2">
                      <a
                        className="cursor-pointer p-link "
                        href={hrefForDownloadAttachment(zakazanTerminPacijentJasperDocument.tip, zakazanTerminPacijentJasperDocument.sadrzaj)}
                        download={zakazanTerminPacijentJasperDocument.naziv}
                      >
                        {zakazanTerminPacijentJasperDocument.naziv}
                      </a>
                    </div>
                    <ConfirmComponent
                      disabled={!pristup}
                      tooltip={Labels.REMOVE_DOKUMENT}
                      buttonClassName="p-button-outlined p-button-danger"
                      buttonIcon="pi pi-trash"
                      acceptClassName="p-button p-component text-white p-button-tertiary"
                      rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
                      confirmIcon="pi pi-exclamation-triangle"
                      confirmTitle={Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_DOKUMENT}
                      onAccept={() => {
                        setZakazanTerminPacijentJasperDocument(undefined);
                        documentUploadRef.current.clear();
                      }}
                    />
                    <ConfirmPopup />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <SkeletonInputItem />
          )}
        </div>
        <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
          <div className="font-bold">{Labels.USTANOVA_GRID_POTREBNI_RADNI_PERIODI_ZA_ZAKAZIVANJE}</div>
        </div>
        <div className="col-12 sm:col-8 p-fluid p-0 mt-1">
          {noSkeleton ? (
            <div>
              <InputSwitch
                checked={!ustanovaGrid?.virtuelniRadniPeriodi ?? false}
                disabled={!pristup || isDisabled || nadjimed}
                onChange={(e) => {
                  setUstanovaGrid({
                    ...ustanovaGrid!,
                    virtuelniRadniPeriodi: !e.target.value,
                  });
                }}
              />
              {nadjimed && <Button icon="pi pi-info-circle" className="ml-2 p-button-rounded p-button-text p-button-lg" tooltip={Labels.LABEL_NADJIMED_RADNI_PERIODI} />}
            </div>
          ) : (
            <SkeletonInputItem />
          )}
        </div>
      </div>
      <div className="flex justify-content-end">
        {!isDisabled && <Button label={Labels.BUTTON_SAVE} disabled={!pristup || !noSkeleton} className="p-button-success bottom-0 right-0" onClick={onSave} />}
      </div>
    </div>
  );
}
