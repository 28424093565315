import { Skeleton } from "primereact/skeleton";

interface SkeletonListItemType {
  size?: number;
}

export default function SkeletonListItem(props: SkeletonListItemType) {
  const { size } = props;

  return (
    <div>
      {[...Array(size ?? 1).keys()].map((j: number) => {
        return (
          <div key={j} className="flex flex-wrap mb-2">
            <Skeleton className="p-mr-2" borderRadius="15px" width="4rem" height="4rem" />
            <div className="col-10 flex flex-wrap p-0">
              <Skeleton height="1rem" width="70rem" />
              <Skeleton className="mb-2" height="1rem" width="40rem" />
            </div>
          </div>
        );
      })}
    </div>
  );
}
