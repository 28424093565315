import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import FormularReadDto from "../../../model/formular/FormularReadDto";
import FormularSimpleReadDto from "../../../model/formular/FormularSimpleReadDto";
import TipKontaktaReadDto from "../../../model/kontakt/TipKontaktaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import DialogComponent from "../../dialog-component/DialogComponent";
import FormularListLogical from "./FormularListLogical";

interface FormularListPageProp {
  tipKontaktaOperation?: string;
  tipKontaktaId?: number;
  tipKontaktaNaziv?: string;
  isDisabled?: boolean;
  tipKontaktaChange?: TipKontaktaReadDto;
  ustanovaId?: number;
  ustanovaNaziv?: string;
  shouldRefetchFormulars?: boolean;
}

export default function FormularListPage(prop: FormularListPageProp) {
  const { tipKontaktaOperation, tipKontaktaId, tipKontaktaNaziv, isDisabled, tipKontaktaChange, ustanovaId, ustanovaNaziv, shouldRefetchFormulars } = prop;
  const {
    formularList,
    first,
    tableRows,
    changeFormularSearchData,
    formularSearchData,
    fetchData,
    searchFormularByEnter,
    exportData,
    breadCrumbItems,
    onPageFormular,
    formularLoading,
    closeDialog,
    firstRepository,
    isDialogOpen,
    openDialog,
    repositoryFormularLoading,
    selectedColumns,
    setSelectedColumns,
    tableRepositoryRows,
    repositoryFormularList,
    onPageRepositoryFormular,
    importFormular,
    tipKontaktaOptions,
    selectedTipoviKontakta,
    setSelectedTipoviKontakta,
    tipKontaktaLoading,
  } = FormularListLogical(tipKontaktaOperation, ustanovaId, tipKontaktaId, shouldRefetchFormulars);

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const { pristup } = useContext(AppContext);

  const openForm = (entityOperation: string, id?: number, formularNaziv?: string) => {
    const idForm = entityOperation !== EntityOperation.CREATE ? id : "";
    ustanovaId
      ? navigate(`/crud-ustanova/${ustanovaId}/crud-tip-kontakta/${tipKontaktaId}/crud-formular/${idForm}`, {
          state: {
            formularOperation: entityOperation,
            tipKontaktaOperation: tipKontaktaOperation,
            tipKontaktaId: tipKontaktaId,
            tipKontaktaNaziv: tipKontaktaNaziv,
            formularNaziv: formularNaziv,
            ustanovaNaziv: ustanovaNaziv,
          },
        })
      : navigate(`/crud-formular/${idForm}`, {
          state: {
            formularOperation: entityOperation,
            tipKontaktaOperation: tipKontaktaOperation,
            tipKontaktaId: tipKontaktaId,
            tipKontaktaNaziv: tipKontaktaNaziv,
            formularNaziv: formularNaziv,
          },
        });
  };
  const crudBodyTemplate = (rowFormular: FormularReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.FORMULAR_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          disabled={tipKontaktaOperation !== undefined && tipKontaktaOperation === EntityOperation.READ}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowFormular && openForm(EntityOperation.UPDATE, rowFormular.id, rowFormular.naziv)}
        />
        <Button
          type="button"
          tooltip={Labels.FORMULAR_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          disabled={!pristup || (tipKontaktaOperation !== undefined && tipKontaktaOperation === EntityOperation.READ)}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowFormular && openForm(EntityOperation.DELETE, rowFormular.id, rowFormular.naziv)}
        />
      </div>
    );
  };

  const searchToolbar = () => {
    return (
      <>
        {!isDisabled && (
          <Button
            label={Labels.BUTTON_ADD_FORMULAR}
            tooltip={Labels.FORMULAR_TITLE_DIALOG_CREATE}
            tooltipOptions={tooltipOptionsBottom}
            disabled={!pristup || (tipKontaktaOperation !== undefined && tipKontaktaOperation === EntityOperation.READ)}
            className="p-button-success mr-3"
            onClick={() => {
              openForm(EntityOperation.CREATE);
            }}
          />
        )}
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={formularSearchData?.searchString ?? ""}
            onChange={changeFormularSearchData}
            onKeyPress={searchFormularByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_FORMULAR}</label>
          <Button tooltip={Labels.FORMULAR_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };
  const leftDialogContents = () => (
    <Button
      label={Labels.LABEL_EXPORT}
      disabled={selectedColumns.length === 0 || (selectedTipoviKontakta.length === 0 && !tipKontaktaId)}
      icon="pi pi-download"
      className="p-button-outlined p-button-tertiary bg-white"
      onClick={() => importFormular(tipKontaktaId)}
      autoFocus
    />
  );
  const rightDialogContents = () => <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={closeDialog} />;

  const renderHeader = () => {
    return (
      <div className="flex flex-column justify-content-between">
        <div>{Labels.FORMULAR_REPOSITORY}</div>
        <div className="flex pt-3 align-items-center">
          <div className="text-base mr-3">
            {Labels.LABEL_TIP_KONTAKTA}
            {Labels.SPECIAL_CHAR_REQUIRED}
          </div>
          <div className="w-10">
            <MultiSelect
              disabled={tipKontaktaLoading || !!tipKontaktaOperation}
              value={!!tipKontaktaOperation ? [tipKontaktaChange] : selectedTipoviKontakta}
              options={!!tipKontaktaOperation ? [tipKontaktaChange] : tipKontaktaOptions}
              optionLabel="naziv"
              onChange={(e: MultiSelectChangeEvent) => {
                setSelectedTipoviKontakta(e.value);
              }}
              className="w-2"
            />
          </div>
        </div>
      </div>
    );
  };

  const dialogFooter = (
    <div className="col-12">
      <Toolbar left={leftDialogContents} right={rightDialogContents} />
    </div>
  );
  const downloadButton = () => {
    let exportList = new Array<any>();
    if (formularList !== undefined && formularList !== null) {
      formularList.forEach((formular: FormularSimpleReadDto) => {
        exportList.push({
          [Labels.FORMULAR_NAZIV]: formular.naziv,
        });
      });
    }
    return (
      <div className="flex flex-row flex-wrap">
        <Button
          label={Labels.BUTTON_REPOSITORY}
          icon="pi pi-cloud-download"
          tooltip={Labels.IMPORT_FORMULAR_REPOSITORY}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-outlined mr-3 bg-white"
          onClick={() => {
            openDialog();
          }}
        />
        <ExportData disabled={formularLoading && !pristup} data={exportList.length > 0 ? exportList : exportData} name={Labels.FORMULAR_LIST} dt={dt} />
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.LABEL_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.FORMULAR_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.TIP_KONTAKTA, filter: true, sortrable: true },
  ];
  const columnsRepositoryProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.CEKAONICA_COLUMN_SVE },
    { columnName: Labels.LABEL_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.FORMULAR_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.TIP_KONTAKTA_KATEGORIJA_KONTAKTA, filter: true, sortrable: true },
  ];
  return (
    <div className="layout-generic-content-list">
      <Panel>
        {tipKontaktaOperation === undefined && <BreadCrumb model={breadCrumbItems} className="mb-3" />}
        <Toolbar left={searchToolbar} right={downloadButton} />
        {formularLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={!isDisabled} />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={formularList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageFormular(e.rows, e.first);
            }}
            exportFilename={Labels.FORMULAR_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"sifra"} header={Labels.FORMULAR_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"naziv"} header={Labels.FORMULAR_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"kontaktTip.naziv"} header={Labels.TIP_KONTAKTA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            {!isDisabled && <Column body={crudBodyTemplate} />}
          </DataTable>
        )}
      </Panel>
      <Panel>
        <DialogComponent
          header={renderHeader}
          visible={isDialogOpen}
          onHide={closeDialog}
          footer={dialogFooter}
          className="w-8"
          content={
            <div>
              <div className="card">
                {repositoryFormularLoading ? (
                  <SkeletonTable dataTableColumnsProperty={columnsRepositoryProperty} hasFilterColumns={true} />
                ) : (
                  <DataTable
                    ref={dt}
                    value={repositoryFormularList}
                    first={firstRepository}
                    rows={tableRepositoryRows}
                    filterDisplay="row"
                    className="p-datatable-flex-scrollable"
                    scrollHeight="flex"
                    paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
                    //paginator
                    selectionMode="checkbox"
                    selection={selectedColumns}
                    responsiveLayout="scroll"
                    onSelectionChange={(e) => setSelectedColumns(e.value)}
                    emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                    onPage={(e) => {
                      onPageRepositoryFormular(e.rows, e.first);
                    }}
                    rowHover
                    stripedRows
                  >
                    <Column filterHeaderClassName="pl-3" bodyClassName="w-3rem pl-3" selectionMode="multiple" header={Labels.CEKAONICA_COLUMN_SVE} headerClassName="w-3rem pl-3" />
                    <Column field={"sifra"} header={Labels.FORMULAR_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    <Column field={"naziv"} header={Labels.TIP_KONTAKTA_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                    <Column field={"sifarnikKategorijaKontakta.naziv"} header={Labels.TIP_KONTAKTA_KATEGORIJA_KONTAKTA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                  </DataTable>
                )}
              </div>
            </div>
          }
        ></DialogComponent>
      </Panel>
    </div>
  );
}
