import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { Dispatch, SetStateAction, useContext, useEffect, useRef } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { PacijentController } from "../../../../controllers/pacijent/PacijentController";
import EnumPacijentPoljeKategorija from "../../../../infrastructure/system/EnumPacijentPoljeKategorija";
import { formatDate2, handleAxiosCallError, removeAllMasks, setInvalid } from "../../../../infrastructure/system/Utils";
import { useObavestenjeTipList } from "../../../../infrastructure/system/hooks/enum/useObavestenjeTipList";
import { usePolList } from "../../../../infrastructure/system/hooks/enum/usePolList";
import { usePacijentPoljeKategorijaList } from "../../../../infrastructure/system/hooks/pacijent-polje-kategorija/usePacijentPoljeKategorijaList";
import PacijentCreateDto from "../../../../model/pacijent/PacijentCreateDto";
import PoljeVrednostDto from "../../../../model/polje/PoljeVrednostDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import Polje from "../../../formular/polja/Polje";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";

interface DialogTerminNoviPacijentTabProps {
  noviPacijent?: PacijentCreateDto;
  setNoviPacijent: Dispatch<SetStateAction<PacijentCreateDto | undefined>>;
  invalidFields: { [field: string]: boolean } | undefined;
  setInvalidFields: Dispatch<SetStateAction<{ [field: string]: boolean } | undefined>>;
  setPacijentExists: Dispatch<SetStateAction<boolean>>;
}

export const DialogTerminNoviPacijentTab = (props: DialogTerminNoviPacijentTabProps) => {
  const { noviPacijent, setNoviPacijent, invalidFields, setInvalidFields, setPacijentExists } = props;
  const { pristup, showMessage } = useContext(AppContext);
  const Labels = useLabels();

  const calendarRef = useRef<Calendar>(null);

  const { axiosCheckPacijentExists } = PacijentController();
  const { polList } = usePolList();
  const { obavestenjeTipList } = useObavestenjeTipList();
  const { pacijentPoljeKategorijaList } = usePacijentPoljeKategorijaList(EnumPacijentPoljeKategorija.ZAKAZIVANJE);

  const onChange = (e: PoljeVrednostDto) => {
    const newValue: any = {
      ...noviPacijent?.polja,
      [e.id]: e.vrednost ? e.vrednost : null,
    };
    setNoviPacijent((prevNoviPacijent) => ({ ...prevNoviPacijent!, polja: newValue }));
  };

  useEffect(() => {
    checkPacijentExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noviPacijent?.polTrenutni, noviPacijent?.vremeRodjenja]);

  const checkPacijentExists = () => {
    if (noviPacijent?.ime && noviPacijent.prezime && noviPacijent.polTrenutni && noviPacijent.vremeRodjenja) {
      axiosCheckPacijentExists({
        ime: noviPacijent.ime,
        prezime: noviPacijent.prezime,
        polTrenutniSifra: noviPacijent.polTrenutni.sifra,
        datumRodjenja: formatDate2(noviPacijent.vremeRodjenja as Date),
      })
        .then((res: any) => {
          setPacijentExists(res.data.data.pacijentExists);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  return (
    <div className="flex flex-column hc-gap-3 align-items-center py-4">
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.HEADER_NAME + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="p-fluid col-9 p-0">
          <InputText
            value={noviPacijent?.ime ?? ""}
            onChange={(e) => {
              setNoviPacijent({
                ...noviPacijent,
                ime: e.target.value,
              });
            }}
            disabled={!pristup}
            onBlur={() => {
              setInvalid(setInvalidFields, "ime", noviPacijent?.ime);
              checkPacijentExists();
            }}
            className={invalidFields?.ime ? "p-invalid" : ""}
          />
          {invalidFields?.ime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
        </div>
      </div>

      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.HEADER_SURNAME + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={noviPacijent?.prezime ?? ""}
            onChange={(e) => {
              setNoviPacijent({
                ...noviPacijent,
                prezime: e.target.value,
              });
            }}
            disabled={!pristup}
            onBlur={() => {
              setInvalid(setInvalidFields, "prezime", noviPacijent?.prezime);
              checkPacijentExists();
            }}
            className={invalidFields?.prezime ? "p-invalid" : ""}
          />
          {invalidFields?.prezime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_IME_RODITELJA}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={noviPacijent?.imeRoditelja ?? ""}
            onChange={(e) => {
              setNoviPacijent({
                ...noviPacijent,
                imeRoditelja: e.target.value,
              });
            }}
            disabled={!pristup}
            onBlur={() => {
              checkPacijentExists();
            }}
          />
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_POL + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="col-9 p-fluid p-0 flex flex-column">
          <div className="flex flex-row">
            {polList?.map((pol: EnumBaseReadDto, key: number) => (
              <div key={key}>
                <RadioButton
                  className={"p-radiobutton " + (invalidFields?.pol && "p-invalid")}
                  inputId={pol?.sifra}
                  name="pol"
                  value={pol}
                  checked={noviPacijent?.polTrenutni?.sifra === pol.sifra}
                  onChange={(e) => {
                    setNoviPacijent({ ...noviPacijent, polTrenutni: e.value });
                    setInvalidFields((prev) => ({ ...prev, pol: false }));
                  }}
                  disabled={!pristup}
                />
                <label className="cursor-pointer mx-2" htmlFor={pol.sifra}>
                  {pol.naziv}
                </label>
              </div>
            ))}
          </div>
          <div className="flex flex-row">{invalidFields?.pol && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}</div>
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.DATUM_RODJENJA + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="col-9 p-fluid p-0">
          <CalendarComponent
            ref={calendarRef}
            value={noviPacijent?.vremeRodjenja}
            onChange={(e: CalendarChangeEvent) => {
              setNoviPacijent({
                ...noviPacijent,
                vremeRodjenja: formatDate2(e.value as Date | undefined),
              });
              setInvalidFields((prev) => ({ ...prev, vremeRodjenja: false }));
              if (e.value as Date) calendarRef?.current?.hide();
            }}
            onClearButtonClick={() => {
              setInvalidFields((prev) => ({ ...prev, vremeRodjenja: true }));
              setNoviPacijent({ ...noviPacijent, vremeRodjenja: undefined });
            }}
            className={"w-full " + (invalidFields?.vremeRodjenja && "p-invalid")}
            baseZIndex={3000}
            disabled={!pristup}
            onHide={removeAllMasks}
            appendTo={document.body}
          />
          {invalidFields?.vremeRodjenja && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_TELEFON}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={noviPacijent?.telefon ?? ""}
            onChange={(e) => {
              setNoviPacijent({
                ...noviPacijent,
                telefon: e.target.value,
              });
              setInvalidFields((prev) => ({ ...prev, invalidTelefon: false }));
            }}
            disabled={!pristup}
            className={invalidFields?.invalidTelefon ? "p-invalid" : ""}
          />
          {invalidFields?.invalidTelefon && <ObaveznoPolje text={Labels.TELEFON_WRONG_FORMAT} />}
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_EMAIL}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={noviPacijent?.email ?? ""}
            onChange={(e) => {
              setNoviPacijent({
                ...noviPacijent,
                email: e.target.value,
              });
              setInvalidFields((prev) => ({ ...prev, invalidEmail: false }));
            }}
            disabled={!pristup}
            className={invalidFields?.invalidEmail ? "p-invalid" : ""}
          />
          {invalidFields?.invalidEmail && <ObaveznoPolje text={Labels.EMAIL_WRONG_FORMAT} />}
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_ADRESA}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={noviPacijent?.adresa ?? ""}
            onChange={(e) => {
              setNoviPacijent({
                ...noviPacijent,
                adresa: e.target.value,
              });
            }}
            disabled={!pristup}
          />
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_OBAVESTENJE_TIP_LIST}</div>
        <div className="col-9 p-fluid p-0">
          <MultiSelect
            value={noviPacijent?.pacijentObavestenjeServisList?.map((pacijentObavestenjeServis) => pacijentObavestenjeServis.obavestenjeTip) ?? ""}
            options={obavestenjeTipList ?? []}
            disabled={!pristup}
            onChange={(e) => {
              setNoviPacijent({
                ...noviPacijent,
                pacijentObavestenjeServisList: e.value.map((obavestenjeTip: EnumBaseReadDto) => ({ obavestenjeTip })),
              });
            }}
            optionLabel="naziv"
            optionValue="obavestenjeTip.sifra"
            emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
          />
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_GRUPNO_OBAVESTENJE_TIP_LIST}</div>
        <div className="col-9 p-fluid p-0">
          <MultiSelect
            value={noviPacijent?.pacijentGrupnoObavestenjeServisList?.map((pacijentGrupnoObavestenjeServis) => pacijentGrupnoObavestenjeServis.obavestenjeTip) ?? ""}
            options={obavestenjeTipList ?? []}
            disabled={!pristup}
            onChange={(e) => {
              setNoviPacijent({
                ...noviPacijent,
                pacijentGrupnoObavestenjeServisList: e.value.map((obavestenjeTip: EnumBaseReadDto) => ({ obavestenjeTip })),
              });
            }}
            optionLabel="naziv"
            optionValue="obavestenjeTip.sifra"
            emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
          />
        </div>
      </div>
      {pacijentPoljeKategorijaList.map((pacijentPoljeKategorija, index: number) => {
        return (
          <div className="w-full" key={index}>
            <Polje
              key={pacijentPoljeKategorija.pacijentPolje.id}
              id={pacijentPoljeKategorija.pacijentPolje.id}
              naziv={pacijentPoljeKategorija.pacijentPolje.naziv}
              tip={pacijentPoljeKategorija.pacijentPolje.poljeTip.tipKomponenteEnum.sifra}
              obavezno={pacijentPoljeKategorija.obavezno}
              enumStavke={pacijentPoljeKategorija.pacijentPolje.poljeTip.poljeTipEnumStavkaList}
              format={pacijentPoljeKategorija.pacijentPolje.poljeTip.format}
              decimale={pacijentPoljeKategorija.pacijentPolje.poljeTip.decimale}
              vrednost={noviPacijent?.polja ? noviPacijent?.polja[pacijentPoljeKategorija.pacijentPolje.id] : undefined}
              onChange={onChange}
              readonly={!pristup || pacijentPoljeKategorija.readOnly}
              colSize={9}
            />
          </div>
        );
      })}
    </div>
  );
};
