import { useQuery } from "@tanstack/react-query";
import { ZakazaniTerminController } from "../../../../controllers/zakazani-termin/ZakazaniTerminController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";
import ZakazanTerminReadDto from "../../../../model/zakazan-termin/ZakazanTerminReadDto";
import { useContext } from "react";
import { AppContext } from "../../../../Store";

export function useZakazanTerminKomentarList(zakazanTermin?: ZakazanTerminReadDto) {
  const { axiosGetCommentsForZakazaniTermin } = ZakazaniTerminController();
  const { nadjimed } = useContext(AppContext);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.ZAKAZAN_TERMIN_KOMENTAR_LIST, { zakazanTerminId: zakazanTermin?.id }],
    queryFn: () => axiosGetCommentsForZakazaniTermin(zakazanTermin?.id!),
    enabled: !!zakazanTermin && !!zakazanTermin.eksterniCaseID && nadjimed,
  });

  const zakazanTerminKomentarList = data?.data?.data ?? [];

  return { zakazanTerminKomentarList, isLoading };
}
