import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../../Store";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import { DATE_FORMAT_2, formatDate, tooltipOptionsBottom } from "../../../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../../../app/skeleton/SkeletonInputItem";
import CalendarComponent from "../../../calendar-component/CalendarComponent";
import CrudUstanovaBrojNalogaLogical from "./CrudUstanovaBrojNalogaLogical";

export default function CrudUstanovaBrojNaloga() {
  const {
    breadCrumbItems,
    index,
    isDisabled,
    brojNalogaList,
    setIndex,
    onUpdate,
    onCancel,
    onCreate,
    onDelete,
    ustanovaBrojNalogaChange,
    brojNalogaLoading,
    ustanovaBrojNalogaOperation,
    setUstanovaBrojNalogaChange,
    vaziOdRef,
    vaziDoRef,
  } = CrudUstanovaBrojNalogaLogical();
  const noSkeleton: boolean = ustanovaBrojNalogaOperation === EntityOperation.CREATE || !brojNalogaLoading;
  const Labels = useLabels();

  const leftContentsToolbar = () => (
    <>
      {ustanovaBrojNalogaOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.LICENCNI_MODEL_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {ustanovaBrojNalogaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!noSkeleton}
          icon="pi pi-pencil"
          tooltip={Labels.LICENCNI_MODEL_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {ustanovaBrojNalogaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!noSkeleton}
          icon="pi pi-trash"
          tooltip={Labels.LICENCNI_MODEL_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={onCancel} />
    </>
  );
  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_LICENCNI_MODEL}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.BROJ_NALOGA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <Dropdown
                      disabled={ustanovaBrojNalogaOperation !== EntityOperation.CREATE}
                      value={ustanovaBrojNalogaChange?.brojNaloga?.id}
                      options={brojNalogaList}
                      optionLabel={"naziv"}
                      optionValue={"id"}
                      onChange={(e) => {
                        setUstanovaBrojNalogaChange({
                          ...ustanovaBrojNalogaChange!,
                          brojNaloga: { id: e.target.value },
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.VAZI_OD}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <CalendarComponent
                      ref={vaziOdRef}
                      disabled={ustanovaBrojNalogaOperation !== EntityOperation.CREATE}
                      showButtonBar={false}
                      name="vaziOd"
                      minDate={new Date()}
                      value={ustanovaBrojNalogaChange?.vaziOd}
                      onChange={(e: CalendarChangeEvent) => {
                        setUstanovaBrojNalogaChange({
                          ...ustanovaBrojNalogaChange!,
                          vaziOd: formatDate(e.value as Date, DATE_FORMAT_2)!,
                        });
                        if (e.target.value) vaziOdRef?.current?.hide();
                      }}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.VAZI_DO}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <CalendarComponent
                      ref={vaziDoRef}
                      disabled={isDisabled || ustanovaBrojNalogaOperation === EntityOperation.DELETE}
                      showButtonBar={false}
                      minDate={new Date()}
                      name="vaziDo"
                      value={ustanovaBrojNalogaChange?.vaziDo}
                      onChange={(e: CalendarChangeEvent) => {
                        setUstanovaBrojNalogaChange({
                          ...ustanovaBrojNalogaChange!,
                          vaziDo: formatDate(e.value as Date, DATE_FORMAT_2),
                        });
                        if (e.target.value) vaziDoRef?.current?.hide();
                      }}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar start={leftContentsToolbar} end={rightContentsToolbar}></Toolbar>
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
