import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import IzvozCreateDto from "../../model/izvoz/IzvozCreateDto";
import { AppContext } from "./../../Store";

interface IzvozControllerType {
  axiosPostKreiranjeIzvoza: (params: IzvozCreateDto) => Promise<AxiosResponse<any>>;
  axiosSearchIzvoz: (izvozSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetIzvozDatoteka: (exportZahtevID: number) => Promise<AxiosResponse<any>>;
  axiosSearchIzvozSuperAdmin: (izvozSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGenerateExport: (exportZahtevID: number) => Promise<AxiosResponse<any>>;
}

export function IzvozController(): IzvozControllerType {
  const { authData } = useContext(AppContext);

  const axiosPostKreiranjeIzvoza = (params: IzvozCreateDto) => {
    return axios.post(Endpoint.IZVOZ_CREATE, params, axiosConfig(authData!.token));
  };

  const axiosSearchIzvoz = (izvozSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.IZVOZ_LIST, axiosConfig(authData!.token, izvozSearchData));
  };
  const axiosGetIzvozDatoteka = (exportZahtevID: number) => {
    return axios.get(`${Endpoint.IZVOZ_DATOTEKA_LIST}/${exportZahtevID}/ustanova`, axiosConfig(authData!.token));
  };

  const axiosSearchIzvozSuperAdmin = (izvozSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.IZVOZ_LIST_SUPERADMIN, axiosConfig(authData!.token, izvozSearchData));
  };

  const axiosGenerateExport = (exportZahtevID: number) => {
    return axios.post(`${Endpoint.IZVOZ_GENERATE}/${exportZahtevID}`, undefined, axiosConfig(authData!.token));
  };

  return {
    axiosPostKreiranjeIzvoza,
    axiosSearchIzvoz,
    axiosGetIzvozDatoteka,
    axiosSearchIzvozSuperAdmin,
    axiosGenerateExport,
  };
}
