import axios from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { EnumController } from "../../../controllers/enum/EnumController";
import { PacijentPoljeController } from "../../../controllers/pacijent-polje/PacijentPoljeController";
import { PoljeTipController } from "../../../controllers/polje-tip/PoljeTipController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";

import PacijentPoljeKategorijaReadDto from "../../../model/pacijent-polje/PacijentPoljeKategorijaReadDto";
import PacijentPoljeReadDto from "../../../model/pacijent-polje/PacijentPoljeReadDto";
import PoljeTipReadDto from "../../../model/poljeTip/PoljeTipReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudPacijentPoljeLogicalType {
  pacijentPoljeOperation: string;
  isDisabled: boolean;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  pacijentPoljeChange: PacijentPoljeReadDto | undefined;
  setPacijentPoljeChange: any;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  pacijentPoljeId: number | undefined;
  location: any;
  poljeTipList: Array<PoljeTipReadDto>;
  vrstaGrafikonaRastaList: Array<EnumBaseReadDto>;
  openPacijentPoljeKategorija: (entityOperation: string, pacijentPoljeKategorija?: PacijentPoljeKategorijaReadDto) => void;
  onPageFormTable: (rows: number, first: number) => void;
  first: number;
  tableRows: number;
  pacijentPoljeKategorijaList: Array<PacijentPoljeKategorijaReadDto>;
  pacijentPoljeLoading: boolean;
  poljeTipLoading: boolean;
  vrstaGrafikonaRastaLoading: boolean;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
}

interface UseParamsType {
  pacijentPoljeId?: string;
}
export default function CrudPacijentPoljeLogical(): CrudPacijentPoljeLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const navigate = useNavigate();
  const pacijentPoljeId = Number(useParams<keyof UseParamsType>()["pacijentPoljeId"]);
  const location = useLocation();
  const [pacijentPoljeOperation, setpacijentPoljeOperation] = useState(
    location.state && location.state.pacijentPoljeOperation !== undefined ? location.state.pacijentPoljeOperation : EntityOperation.UPDATE
  );
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const Labels = useLabels();
  const [isDisabled] = useState(isFormDisabled(pacijentPoljeOperation));
  const [pacijentPoljeChange, setPacijentPoljeChange] = useState<PacijentPoljeReadDto | undefined>();
  const [index, setIndex] = useState<number>(tabIndex);
  const [poljeTipList, setPoljeTipLista] = useState<Array<PoljeTipReadDto>>([]);
  const [vrstaGrafikonaRastaList, setVrstaGrafikonaRastaList] = useState<Array<EnumBaseReadDto>>([]);
  const { axiosCreatePacijentPolje, axiosUpdatePacijentPolje, axiosDeletePacijentPolje, axiosReadPacijentPolje, axiosGetPacijentPoljeKategorijaByPacijentPolje } = PacijentPoljeController();
  const { axiosGetVrstaGrafikonaRastaList } = EnumController();
  const { axiosGetPoljeTipForPacijentPolje } = PoljeTipController();
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [pacijentPoljeKategorijaList, setPacijentPoljeKategorijaList] = useState<Array<PacijentPoljeKategorijaReadDto>>([]);
  const [pacijentPoljeLoading, setPacijentPoljeLoading] = useState<boolean>(true);
  const [poljeTipLoading, setPoljeTipLoading] = useState<boolean>(true);
  const [vrstaGrafikonaRastaLoading, setVrstaGrafikonaRastaLoading] = useState<boolean>(true);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.PACIJENT_POLJE_LIST,
      command: () => {
        navigate("/pacijent-polje-list/");
      },
    },
  ]);
  const openPacijentPoljeKategorija = (entityOperation: string, pacijentPoljeKategorija?: PacijentPoljeKategorijaReadDto) => {
    const pacijentPoljeKategorijaId = entityOperation !== EntityOperation.CREATE ? pacijentPoljeKategorija?.id : "";
    navigate(`/crud-pacijent-polje/${pacijentPoljeId}/crud-pacijent-polje-kategorija/${pacijentPoljeKategorijaId}`, {
      state: {
        pacijentPoljeKategorijaOperation: entityOperation,
        pacijentPoljeOperation: pacijentPoljeOperation,
      },
    });
  };
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    if (pacijentPoljeOperation === EntityOperation.CREATE) {
      setPacijentPoljeChange({
        ...pacijentPoljeChange!,
      });
    }

    fetchData();
    fetchPoljeTipAndVrstaGrafikonaRastaList();
  });
  const fetchData = (ppId?: number, newOperation?: string | undefined) => {
    let startTime = moment(new Date());

    let newPacijentPoljeId = ppId ? ppId : pacijentPoljeId;
    let operation = newOperation ? newOperation : pacijentPoljeOperation;

    if (newPacijentPoljeId) {
      setPacijentPoljeLoading(true);

      const requestPacijentPolje = axiosReadPacijentPolje(newPacijentPoljeId);
      const requestPacijentPoljeKategorija = axiosGetPacijentPoljeKategorijaByPacijentPolje(newPacijentPoljeId);
      axios
        .all([requestPacijentPolje, requestPacijentPoljeKategorija])
        .then(
          axios.spread((responsePacijentPolje: any, responcePacijentPoljeKategorija: any) => {
            setPacijentPoljeChange(responsePacijentPolje.data.data);
            setPacijentPoljeKategorijaList(responcePacijentPoljeKategorija.data.data);
            if (breadCrumbItems.length >= 2) breadCrumbItems.pop();
            setBreadCrumbItems([
              ...breadCrumbItems,
              {
                label:
                  operation === EntityOperation.UPDATE
                    ? Labels.PACIJENT_POLJE_TITLE_DIALOG_UPDATE + responsePacijentPolje.data.data.naziv
                    : operation === EntityOperation.READ
                    ? Labels.PACIJENT_POLJE_TITLE_DIALOG_DETAILS + responsePacijentPolje.data.data.naziv
                    : Labels.PACIJENT_POLJE_TITLE_DIALOG_DELETE + responsePacijentPolje.data.data.naziv,
              },
            ]);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPacijentPoljeLoading, startTime);
        });
    } else {
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.PACIJENT_POLJE_TITLE_DIALOG_CREATE,
        },
      ]);
      setPacijentPoljeLoading(false);
    }
  };

  const fetchPoljeTipAndVrstaGrafikonaRastaList = () => {
    let startTime = moment(new Date());
    const requestPoljeTip = axiosGetPoljeTipForPacijentPolje();
    const requestVrstaGrafikonaRasta = axiosGetVrstaGrafikonaRastaList();
    axios
      .all([requestPoljeTip, requestVrstaGrafikonaRasta])
      .then(
        axios.spread((responsePoljeTip, responseVrstaGrafikonaRasta) => {
          setPoljeTipLista(responsePoljeTip.data.data);
          setVrstaGrafikonaRastaList(responseVrstaGrafikonaRasta.data.data);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setPoljeTipLoading, startTime);
        skeletonTimeout(setVrstaGrafikonaRastaLoading, startTime);
      });
  };

  const validateInput = (pacijentPolje: PacijentPoljeReadDto | undefined) => {
    let isInvalid = false;
    if (validateStringEmpty(pacijentPolje?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(pacijentPolje?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(pacijentPolje?.poljeTip?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, poljeTip: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(pacijentPoljeChange)) {
      return;
    }
    if (pacijentPoljeChange) {
      setShowBlockUI(true);
      axiosCreatePacijentPolje(pacijentPoljeChange)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.PACIJENT_POLJE_TITLE_MESSAGE_CREATE_PACIJENT_POLJE_SUCCESS);
          setpacijentPoljeOperation(EntityOperation.UPDATE);
          fetchData(res.data.data.id, EntityOperation.UPDATE);
          navigate(`/crud-pacijent-polje/${res.data.data.id}`);

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_1 +
              pacijentPoljeChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_2 +
              pacijentPoljeChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_3 +
              res.data.data.poljeTip.naziv
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(pacijentPoljeChange)) {
      return;
    }
    if (pacijentPoljeChange) {
      setShowBlockUI(true);
      axiosUpdatePacijentPolje(pacijentPoljeChange)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.PACIJENT_POLJE_TITLE_MESSAGE_UPDATE_PACIJENT_POLJE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_1 +
              pacijentPoljeChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_2 +
              pacijentPoljeChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_3 +
              res.data.data.poljeTip.naziv
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeletePacijentPolje(pacijentPoljeId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.PACIJENT_POLJE_TITLE_MESSAGE_DELETE_PACIJENT_POLJE_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_1 +
            pacijentPoljeChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_2 +
            pacijentPoljeChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_3 +
            pacijentPoljeChange?.poljeTip.naziv
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/pacijent-polje-list`);
    }
  };
  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    pacijentPoljeOperation,
    isDisabled,
    pacijentPoljeChange,
    breadCrumbItems,
    setPacijentPoljeChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    pacijentPoljeId,
    location,
    poljeTipList,
    vrstaGrafikonaRastaList,
    openPacijentPoljeKategorija,
    first,
    tableRows,
    onPageFormTable,
    pacijentPoljeKategorijaList,
    pacijentPoljeLoading,
    poljeTipLoading,
    vrstaGrafikonaRastaLoading,
    invalidFields,
    setInvalidFields,
  };
}
