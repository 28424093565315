import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { ChangeEvent } from "react";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import CrudStorageLimitLogical from "./CrudStorageLimitLogical";

export default function CrudStorageLimitView() {
  const { breadCrumbItems, storageLimitChange, setStorageLimitChange, isDisabled, storageLimitOperation, onCreate, onUpdate, onCancel, storageLimitLoading, jedinicaKolicinePodatkaList } =
    CrudStorageLimitLogical();
  const Labels = useLabels();

  const leftContentsToolbar = () => (
    <>
      {storageLimitOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.STORAGE_LIMIT_TITLE_CREATE} tooltipOptions={tooltipOptionsBottom} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {storageLimitOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={storageLimitLoading}
          icon="pi pi-pencil"
          tooltip={Labels.STORAGE_LIMIT_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
          <TabPanel header={Labels.STORAGE_LIMIT}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.STORAGE_LIMIT_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!storageLimitLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={storageLimitChange?.naziv ?? ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setStorageLimitChange({
                          ...storageLimitChange!,
                          naziv: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.STORAGE_LIMIT_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!storageLimitLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={storageLimitChange?.sifra ?? ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setStorageLimitChange({
                          ...storageLimitChange!,
                          sifra: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.STORAGE_LIMIT_PROSTOR}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!storageLimitLoading ? (
                    <>
                      <InputNumber
                        disabled={isDisabled || storageLimitOperation === EntityOperation.UPDATE}
                        value={storageLimitChange && storageLimitChange.prostor}
                        onChange={(e) => {
                          setStorageLimitChange({
                            ...storageLimitChange!,
                            prostor: e.value ?? undefined, //promenjen je i prostor u StorageLimitReadDto da bi ovo moglo da prodje, dodato je undefined
                          });
                        }}
                        className="mr-2"
                      />
                      <div className="ml-2 w-7rem">
                        <Dropdown
                          disabled={isDisabled || storageLimitOperation === EntityOperation.UPDATE}
                          options={jedinicaKolicinePodatkaList}
                          optionLabel="sifra"
                          value={storageLimitChange?.jedinicaKolicinePodatkaEnum ?? undefined}
                          className="w-full"
                          onChange={(e) => {
                            setStorageLimitChange({
                              ...storageLimitChange!,
                              jedinicaKolicinePodatkaEnum: e.target.value ?? undefined,
                            });
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-4">
                  <div>
                    {Labels.CENA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!storageLimitLoading ? (
                    <InputNumber
                      disabled={isDisabled || storageLimitOperation === EntityOperation.UPDATE}
                      value={storageLimitChange?.cena}
                      onChange={(e) => {
                        setStorageLimitChange({
                          ...storageLimitChange!,
                          cena: e.value ?? 0,
                        });
                      }}
                      min={0}
                      mode="decimal"
                      locale="sr-RS"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      suffix=" RSD"
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar start={leftContentsToolbar} end={rightContentsToolbar} className="flex" />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
