import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { formatPrice, tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import DodatniModulReadDto from "../../../../model/licenca/dodatni-modul/DodatniModulReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import CrudDodatniModulListLogical from "./DodatniModulListLogical";

export default function DodatniModulListView() {
  const { dodatniModulList, firstModul, tableModulRows, onPageModul, modulLoading, openDodatniModul } = CrudDodatniModulListLogical();

  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.HEADER_TIP, filter: true, sortrable: true },
    { columnName: Labels.CENA, filter: true, sortrable: true },
  ];
  const crudBodyTemplate = (rowDodatniModul: DodatniModulReadDto) => {
    return (
      <div className="float-right">
        <Button
          type="button"
          tooltip={Labels.DODATNI_MODUL_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowDodatniModul && openDodatniModul(EntityOperation.UPDATE, rowDodatniModul.id)}
        />
      </div>
    );
  };
  const addButton = () => (
    <Button
      label={Labels.DODATNI_MODUL_BUTTON_DODAJ}
      tooltip={Labels.DODATNI_MODUL_TITLE_CREATE}
      tooltipOptions={tooltipOptionsBottom}
      className="p-button-success mr-3"
      onClick={() => {
        openDodatniModul(EntityOperation.CREATE);
      }}
    />
  );

  const renderCena = (dodatniModul: DodatniModulReadDto) => {
    return formatPrice(dodatniModul.cena) + " RSD";
  };

  return (
    <div className="layout-generic-content-list">
      <Toolbar left={addButton} />
      {modulLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={undefined} />
      ) : (
        <DataTable
          //ref={dtModul}
          filterDisplay="row"
          value={dodatniModulList}
          paginator
          first={firstModul}
          paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
          rows={tableModulRows}
          onPage={(e) => {
            onPageModul(e.rows, e.first);
          }}
          exportFilename={Labels.FORMULAR_LIST}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          rowHover
          stripedRows
        >
          <Column field={"naziv"} header={Labels.HEADER_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          <Column field={"tip.naziv"} header={Labels.HEADER_TIP} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          <Column body={renderCena} header={Labels.CENA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
          <Column body={crudBodyTemplate} />
        </DataTable>
      )}
    </div>
  );
}
