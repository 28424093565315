import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { Navigate } from "react-router-dom";
import { useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { formatDate, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import CalendarComponent from "../calendar-component/CalendarComponent";
import CrudSifarnikAtributaRfzoLogical from "./CrudSifarnikAtributaRfzoLogical";

export default function CrudSifarnikAtributaRfzo() {
  const {
    sifarnikAtributaOperation,
    isDisabled,
    sifarnikAtributaChange,
    breadCrumbItems,
    setSifarnikAtributaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    sifarnikId,
    location,
    sifarnikAtributaLoading,
    entityStatusList,
    entityStatusListLoading,
    vaziOdRef,
    vaziDoRef,
  } = CrudSifarnikAtributaRfzoLogical();

  const Labels = useLabels();

  if (!sifarnikId && !location.state) {
    return <Navigate to="/repository-sifarnik-atributa-rfzo" />;
  }

  const leftContentsToolbar = () => (
    <>
      {sifarnikAtributaOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {sifarnikAtributaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={sifarnikAtributaLoading}
          icon="pi pi-pencil"
          tooltip={Labels.SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {sifarnikAtributaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={sifarnikAtributaLoading}
          icon="pi pi-trash"
          tooltip={Labels.SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_SIFARNIK_ATRIBUTA_RFZO}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_ATRIBUTA_RFZO_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikAtributaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={sifarnikAtributaChange?.naziv ?? ""}
                      onChange={(e) => {
                        setSifarnikAtributaChange({
                          ...sifarnikAtributaChange!,
                          naziv: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_ATRIBUTA_RFZO_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikAtributaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={sifarnikAtributaChange?.sifra ?? ""}
                      onChange={(e) => {
                        setSifarnikAtributaChange({
                          ...sifarnikAtributaChange!,
                          sifra: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_ATRIBUTA_RFZO_OPIS}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikAtributaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={sifarnikAtributaChange?.opis ?? ""}
                      onChange={(e) => {
                        setSifarnikAtributaChange({
                          ...sifarnikAtributaChange!,
                          opis: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    <div>
                      {Labels.SIFARNIK_ATRIBUTA_RFZO_FAKTOR_UVECANJA_CENE}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikAtributaLoading ? (
                    <InputNumber
                      disabled={isDisabled}
                      value={sifarnikAtributaChange?.faktorUvecanjaCene}
                      mode="decimal"
                      maxFractionDigits={12}
                      onChange={(e) => {
                        setSifarnikAtributaChange({
                          ...sifarnikAtributaChange!,
                          faktorUvecanjaCene: e.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_ATRIBUTA_RFZO_OZNAKA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikAtributaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={sifarnikAtributaChange?.oznaka ?? ""}
                      onChange={(e) => {
                        setSifarnikAtributaChange({
                          ...sifarnikAtributaChange!,
                          oznaka: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_ATRIBUTA_RFZO_ID_ATRIBUTA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikAtributaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={sifarnikAtributaChange?.IDAtributa ?? ""}
                      onChange={(e) => {
                        setSifarnikAtributaChange({
                          ...sifarnikAtributaChange!,
                          IDAtributa: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_ATRIBUTA_RFZO_VAZI_OD}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikAtributaLoading ? (
                    <CalendarComponent
                      ref={vaziOdRef}
                      value={sifarnikAtributaChange?.vaziOd}
                      onChange={(e: CalendarChangeEvent) => {
                        setSifarnikAtributaChange({
                          ...sifarnikAtributaChange!,
                          vaziOd: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                        });
                        if (e.target.value) vaziOdRef?.current?.hide();
                      }}
                      disabled={isDisabled}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_ATRIBUTA_RFZO_VAZI_DO}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikAtributaLoading ? (
                    <CalendarComponent
                      ref={vaziDoRef}
                      value={sifarnikAtributaChange?.vaziDo}
                      onChange={(e: CalendarChangeEvent) => {
                        setSifarnikAtributaChange({
                          ...sifarnikAtributaChange!,
                          vaziDo: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                        });
                        if (e.target.value) vaziDoRef?.current?.hide();
                      }}
                      disabled={isDisabled}
                      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_ATRIBUTA_RFZO_STATUS}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikAtributaLoading && !entityStatusListLoading ? (
                    <Dropdown
                      options={entityStatusList}
                      disabled={isDisabled}
                      showClear
                      value={sifarnikAtributaChange?.entityStatus?.sifra ?? undefined}
                      optionLabel="naziv"
                      optionValue="sifra"
                      onChange={(e) => {
                        setSifarnikAtributaChange({
                          ...sifarnikAtributaChange!,
                          entityStatus: e.value ? { sifra: e.value } : undefined,
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
