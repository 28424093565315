import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import PoljeTipEnumStavkaReadDto from "../../model/poljeTip/PoljeTipEnumStavkaReadDto";
import PoljeTipReadDto from "../../model/poljeTip/PoljeTipReadDto";
import { AppContext } from "./../../Store";

interface PoljeTipControllerType {
  axiosSearchPoljeTip: (poljeTipSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosCreatePoljeTip: (poljeTipCreate: PoljeTipReadDto) => Promise<AxiosResponse<any>>;
  axiosReadPoljeTip: (poljeTipId: number) => Promise<AxiosResponse<any>>;
  axiosUpdatePoljeTip: (poljeTipChange: PoljeTipReadDto) => Promise<AxiosResponse<any>>;
  axiosDeletePoljeTip: (poljeTipChange: PoljeTipReadDto) => Promise<AxiosResponse<any>>;
  axiosGetPoljeTip: (poljeTipId: number) => Promise<AxiosResponse<any>>;
  axiosGetPoljeTipEnumStavkaList: (poljeTipId: number) => Promise<AxiosResponse<any>>;
  axiosCreatePoljeTipEnumStavka: (poljeTipId: number, poljeTipEnumStavkaCreate: PoljeTipEnumStavkaReadDto) => Promise<AxiosResponse<any>>;
  axiosReadPoljeTipEnumStavka: (poljeTipId: number, poljeTipEnumStavkaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdatePoljeTipEnumStavka: (poljeTipId: number, poljeTipEnumStavkaChange: PoljeTipEnumStavkaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeletePoljeTipEnumStavka: (poljeTipId: number, poljeTipEnumStavkaId: number) => Promise<AxiosResponse<any>>;
  axiosGetPoljeTipForPacijentPolje: () => Promise<AxiosResponse<any>>;
}

export function PoljeTipController(): PoljeTipControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchPoljeTip = (poljeTipSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.POLJE_TIP_SEARCH, axiosConfig(authData!.token, poljeTipSearchData));
  };

  const axiosGetPoljeTipForPacijentPolje = () => {
    return axios.get(Endpoint.POLJE_TIP_PACIJENT_POLJE, axiosConfig(authData!.token));
  };

  const axiosReadPoljeTip = (poljeTipId: number) => {
    return axios.get(`${Endpoint.POLJE_TIP_LIST}/${poljeTipId}`, axiosConfig(authData!.token));
  };

  const axiosCreatePoljeTip = (poljeTipCreate: PoljeTipReadDto) => {
    return axios.post(Endpoint.POLJE_TIP_LIST, poljeTipCreate ? poljeTipCreate : {}, axiosConfig(authData!.token));
  };

  const axiosUpdatePoljeTip = (poljeTipChange: PoljeTipReadDto) => {
    return axios.put(`${Endpoint.POLJE_TIP_LIST}/${poljeTipChange.id}`, poljeTipChange, axiosConfig(authData!.token));
  };

  const axiosDeletePoljeTip = (poljeTipChange: PoljeTipReadDto) => {
    return axios.delete(`${Endpoint.POLJE_TIP_LIST}/${poljeTipChange!.id}`, axiosConfig(authData!.token));
  };

  const axiosGetPoljeTip = (poljeTipId: number) => {
    return axios.get(`${Endpoint.POLJE_TIP_LIST}/${poljeTipId}`, axiosConfig(authData!.token));
  };

  const axiosGetPoljeTipEnumStavkaList = (poljeTipId: number) => {
    return axios.get(`${Endpoint.POLJE_TIP_LIST}/${poljeTipId}/polje-tip-enum-stavka-list`, axiosConfig(authData!.token));
  };

  const axiosReadPoljeTipEnumStavka = (poljeTipId: number, poljeTipEnumStavkaId: number) => {
    return axios.get(`${Endpoint.POLJE_TIP_LIST}/${poljeTipId}/polje-tip-enum-stavka-list/${poljeTipEnumStavkaId}`, axiosConfig(authData!.token));
  };

  const axiosCreatePoljeTipEnumStavka = (poljeTipId: number, poljeTipEnumStavkaCreate: PoljeTipEnumStavkaReadDto) => {
    return axios.post(`${Endpoint.POLJE_TIP_LIST}/${poljeTipId}/polje-tip-enum-stavka-list`, poljeTipEnumStavkaCreate ? poljeTipEnumStavkaCreate : {}, axiosConfig(authData!.token));
  };

  const axiosUpdatePoljeTipEnumStavka = (poljeTipId: number, poljeTipEnumStavkaChange: PoljeTipEnumStavkaReadDto) => {
    return axios.put(`${Endpoint.POLJE_TIP_LIST}/${poljeTipId}/polje-tip-enum-stavka-list/${poljeTipEnumStavkaChange!.id}`, poljeTipEnumStavkaChange, axiosConfig(authData!.token));
  };

  const axiosDeletePoljeTipEnumStavka = (poljeTipId: number, poljeTipEnumStavkaId: number) => {
    return axios.delete(`${Endpoint.POLJE_TIP_LIST}/${poljeTipId}/polje-tip-enum-stavka-list/${poljeTipEnumStavkaId}`, axiosConfig(authData!.token));
  };

  return {
    axiosSearchPoljeTip,
    axiosCreatePoljeTip,
    axiosReadPoljeTip,
    axiosDeletePoljeTip,
    axiosUpdatePoljeTip,
    axiosGetPoljeTip,
    axiosGetPoljeTipEnumStavkaList,
    axiosCreatePoljeTipEnumStavka,
    axiosReadPoljeTipEnumStavka,
    axiosUpdatePoljeTipEnumStavka,
    axiosDeletePoljeTipEnumStavka,
    axiosGetPoljeTipForPacijentPolje,
  };
}
