import { useLocation } from "react-router-dom";
import {useContext} from "react";
import ErrorPageView from "../error/ErrorPageView";
import InicijalizacijaAppMenu from "./InicijalizacijaAppMenu";
import InicijalizacijaUstanovaView from "./steps/ustanova/InicijalizacijaUstanovaView";
import { InitialStateType } from "./hooks/initialState";
import { ReducerType } from "./hooks/reducer";
import InicijalizacijaOrgJedinicaView from "./steps/organizaciona-jedinica/InicijalizacijaOrgJedinicaView";
import InvalidFieldsType from "../../model/InvalidFieldsType";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import UstanovaReadDto from "../../model/ustanova/UstanovaReadDto";
import InicijalizacijaRadniciView from "./steps/radnici/InicijalizacijaRadniciView";
import InicijalizacijaUslugeView from "./steps/usluge/InicijalizacijaUslugeView";
import InicijalizacijaTipoviPregledaView from "./steps/tipovi-pregleda/InicijalizacijaTipoviPregledaView";
import ProgressOverlay from "./progress-overlay/ProgressOverlay";
import { isInicijalizacija } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface InicijalizacijaUstanoveViewPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerType>;
  invalidFieldsUstanova: InvalidFieldsType | undefined;
  setInvalidFieldsUstanova: React.Dispatch<React.SetStateAction<InvalidFieldsType | undefined>>;
  continueAction: () => void;
  validateCreateOrgJedinica: () => boolean;
  invalidFieldsOrgJedinica: InvalidFieldsType | undefined;
  setInvalidFieldsOrgJedinica: React.Dispatch<React.SetStateAction<InvalidFieldsType | undefined>>;
  onSubmitOrganizacionaJedinica: () => void;
  onDeleteOrganizacionaJedinica: UseMutationResult<AxiosResponse, unknown, number, unknown>;
  ustanova: UstanovaReadDto;
  isUstanovaLoading: boolean;
  onUpdateUstanova: UseMutationResult<AxiosResponse, unknown, void, unknown>;
  validateUstanovaStep: () => boolean;
  invalidFieldsRadnik: InvalidFieldsType | undefined;
  setInvalidFieldsRadnik: React.Dispatch<React.SetStateAction<InvalidFieldsType | undefined>>;
  validateRadnik: () => boolean;
  onSubmitRadnik: () => void;
  onDeleteRadnik: UseMutationResult<AxiosResponse, unknown, number, unknown>;
  invalidFieldsUsluga: InvalidFieldsType | undefined;
  setInvalidFieldsUsluga: React.Dispatch<React.SetStateAction<InvalidFieldsType | undefined>>;
  onSubmitUsluga: () => void;
  onDeleteUsluga: UseMutationResult<AxiosResponse, unknown, number, unknown>;
  onSaveTipoviPregleda: UseMutationResult<AxiosResponse, unknown, void, unknown>;
}

const InicijalizacijaUstanoveView = (props: InicijalizacijaUstanoveViewPropsType) => {
  const {
    state,
    dispatch,
    invalidFieldsUstanova,
    setInvalidFieldsUstanova,
    continueAction,
    invalidFieldsOrgJedinica,
    setInvalidFieldsOrgJedinica,
    onDeleteOrganizacionaJedinica,
    onSubmitOrganizacionaJedinica,
    ustanova,
    isUstanovaLoading,
    onUpdateUstanova,
    validateUstanovaStep,
    invalidFieldsRadnik,
    setInvalidFieldsRadnik,
    validateRadnik,
    onSubmitRadnik,
    onDeleteRadnik,
    invalidFieldsUsluga,
    setInvalidFieldsUsluga,
    onSubmitUsluga,
    onDeleteUsluga,
    onSaveTipoviPregleda,
  } = props;
  const { authData } = useContext(AppContext);
  const location = useLocation();

  const renderStep = () => {
    switch (state?.step) {
      case 1:
        return (
          <InicijalizacijaUstanovaView
            state={state}
            dispatch={dispatch}
            invalidFieldsUstanova={invalidFieldsUstanova}
            setInvalidFieldsUstanova={setInvalidFieldsUstanova}
            ustanova={ustanova}
            isUstanovaLoading={isUstanovaLoading}
            onUpdateUstanova={onUpdateUstanova}
            validateUstanovaStep={validateUstanovaStep}
          />
        );
      case 2:
        return (
          <InicijalizacijaOrgJedinicaView
            state={state}
            dispatch={dispatch}
            invalidFieldsOrgJedinica={invalidFieldsOrgJedinica}
            setInvalidFieldsOrgJedinica={setInvalidFieldsOrgJedinica}
            onSubmit={onSubmitOrganizacionaJedinica}
            onDeleteOrganizacionaJedinica={onDeleteOrganizacionaJedinica}
          />
        );
      case 3:
        return (
          <InicijalizacijaRadniciView
            state={state}
            dispatch={dispatch}
            invalidFieldsRadnik={invalidFieldsRadnik}
            setInvalidFieldsRadnik={setInvalidFieldsRadnik}
            validateRadnik={validateRadnik}
            onSubmitRadnik={onSubmitRadnik}
            onDeleteRadnik={onDeleteRadnik}
          />
        );
      case 4:
        return <InicijalizacijaTipoviPregledaView state={state} dispatch={dispatch} onSaveTipoviPregleda={onSaveTipoviPregleda} />;
      case 5:
        return (
          <InicijalizacijaUslugeView
            state={state}
            dispatch={dispatch}
            invalidFieldsUsluga={invalidFieldsUsluga}
            setInvalidFieldsUsluga={setInvalidFieldsUsluga}
            onSubmitUsluga={onSubmitUsluga}
            onDeleteUsluga={onDeleteUsluga}
          />
        );
      default:
        return <ErrorPageView />;
    }
  };

  return (
    <div className="inicijalizacija-container">
      {location?.state?.step && isInicijalizacija(authData?.currentRadnik!) && <InicijalizacijaAppMenu state={state} dispatch={dispatch} continueAction={continueAction} ustanova={ustanova} />}
      <div className="bg-white">{renderStep()}</div>
      {state?.showFinishingInitializationProcessOverlay && <ProgressOverlay state={state} dispatch={dispatch} />}
    </div>
  );
};

export default InicijalizacijaUstanoveView;
