import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PaketController } from "../../../controllers/paket/PaketController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import PaketCreateDto from "../../../model/paket/PaketCreateDto";
import PaketReadDto from "../../../model/paket/PaketReadDto";
import PaketUpdateDto from "../../../model/paket/PaketUpdateDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudPaketLogicalType {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  paketOperation: string;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  isDisabled: boolean;
  paketLoading: boolean;
  paketChange: PaketReadDto | undefined;
  setPaketChange: any;
}

interface UseParamsType {
  paketId?: string;
}

export default function CrudPaketLogical(): CrudPaketLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const navigate = useNavigate();
  const [exportData] = useState<Array<any>>([]);
  const location = useLocation();
  const paketId = Number(useParams<keyof UseParamsType>()["paketId"]);
  const [paketNaziv] = useState(location?.state?.paketNaziv);
  const [paketOperation, setPaketOperation] = useState<string>(location.state ? location.state.paketOperation : EntityOperation.UPDATE);
  const [index, setIndex] = useState<number>(0);
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.LABEL_PAKETI_USLUGA,
      command: () => {
        navigate("/paket-usluga-list/");
      },
    },
  ]);

  const [paketChange, setPaketChange] = useState<PaketReadDto | undefined>();

  const [isDisabled] = useState<boolean>(isFormDisabled(location.state ? location.state.paketOperation : EntityOperation.UPDATE));
  const [paketLoading, setPaketLoading] = useState<boolean>(true);

  const { axiosReadPaket, axiosCreatePaket, axiosUpdatePaket, axiosDeletePaket } = PaketController();

  const postLogHighLevel = useLogHighLevel();

  const fillBreadCrumbItems = () => {
    setBreadCrumbItems([
      {
        label: Labels.LABEL_PAKETI_USLUGA,
        command: () => {
          navigate("/paket-usluga-list/");
        },
      },
      {
        label:
          paketOperation === EntityOperation.UPDATE
            ? Labels.PAKET_USLUGA_TITLE_DIALOG_UPDATE + paketNaziv
            : paketOperation === EntityOperation.CREATE
            ? Labels.PAKET_USLUGA_TITLE_DIALOG_CREATE
            : Labels.PAKET_USLUGA_TITLE_DIALOG_DELETE + paketNaziv,
      },
    ]);
  };

  useEffectOnce(() => {
    fetchData();
    fillBreadCrumbItems();
  });

  const fetchData = async (pId?: number | undefined, newOperation?: string | undefined) => {
    let idPaketa = pId ? pId : paketId;
    let operation = newOperation ? newOperation : paketOperation;
    if (idPaketa) {
      let startTime = moment(new Date());
      axiosReadPaket(idPaketa)
        .then((res) => {
          setPaketChange(res.data.data);
          if (breadCrumbItems.length >= 2) breadCrumbItems.pop();
          setBreadCrumbItems([
            ...breadCrumbItems,
            {
              label:
                operation === EntityOperation.UPDATE
                  ? Labels.PAKET_USLUGA_TITLE_DIALOG_UPDATE + res.data.data.naziv
                  : operation === EntityOperation.CREATE
                  ? Labels.PAKET_USLUGA_TITLE_DIALOG_CREATE
                  : Labels.PAKET_USLUGA_TITLE_DIALOG_DELETE + res.data.data.naziv,
            },
          ]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          skeletonTimeout(setPaketLoading, startTime);
        })
        .finally(() => {
          skeletonTimeout(setPaketLoading, startTime);
        });
    } else {
      setPaketLoading(false);
    }
  };

  const validateInput = (paket?: PaketReadDto) => {
    if (validateStringEmpty(paket?.naziv)) {
      showMessage(MessageType.ERROR, Labels.FORMULAR_NAZIV_IS_REQUIRED);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateInput(paketChange)) {
      return;
    }
    let paketCreate: PaketCreateDto | undefined = paketChange ? { ...paketChange } : undefined;
    if (paketCreate) {
      setShowBlockUI(true);
      axiosCreatePaket(paketCreate)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.PAKET_USLUGA_TITLE_MESSAGE_CREATE_PAKET_SUCCESS);
          fetchData(res.data.data.id, EntityOperation.UPDATE);
          setPaketOperation(EntityOperation.UPDATE);
          navigate(`/paket-usluga/${res.data.data.id}`, { state: { paketOperation: EntityOperation.UPDATE } });
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_PAKET_USLUGA + paketChange?.naziv + ".");
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };
  const onUpdate = () => {
    if (!validateInput(paketChange)) {
      return;
    }
    let paketUpdate: PaketUpdateDto | undefined = paketChange ? { ...paketChange } : undefined;
    if (paketUpdate) {
      setShowBlockUI(true);
      axiosUpdatePaket(paketUpdate)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.PAKET_USLUGA_TITLE_MESSAGE_UPDATE_PAKET_SUCCESS);
          onCancel();
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PAKET_USLUGA + paketChange?.naziv + ".");
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeletePaket(paketId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.PAKET_USLUGA_TITLE_MESSAGE_DELETE_PAKET_SUCCESS);
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_PAKET_USLUGA + paketChange?.naziv + ".");
        onCancel();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];

      breadCrumb.command && breadCrumb.command();
    } else navigate(`/paket-usluga-list`);
  };

  return {
    setIndex,
    index,
    exportData,
    breadCrumbItems,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    isDisabled,
    paketOperation,
    paketLoading,
    paketChange,
    setPaketChange,
  };
}
