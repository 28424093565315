import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import PlacanjeController from "../../../../controllers/placanje/PlacanjeController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../infrastructure/system/MessageType";
import { formatPeriod, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import UstanovaPlacanjeParamsDto from "../../../../model/UstanovaPlacanjeParamsDto";
import ObracunatIznosReadDto from "../../../../model/placanje/ObracunatIznosReadDto";
import UstanovaPlacanjeCreateDto from "../../../../model/placanje/UstanovaPlacanjeCreateDto";
import UstanovaPlacanjeReadDto from "../../../../model/placanje/UstanovaPlacanjeReadDto";

interface UstanovaPlacanjeIzmenaLogicalType {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  placanjeChange: UstanovaPlacanjeReadDto | UstanovaPlacanjeCreateDto | undefined;
  setPlacanjeChange: React.Dispatch<React.SetStateAction<UstanovaPlacanjeReadDto | UstanovaPlacanjeCreateDto | undefined>>;
  placanjeLoading: boolean;
  onCancel: () => void;
  obracunatIznos: ObracunatIznosReadDto | undefined;
  placanjeOperation: string;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  checkedObavezaIzmirena: boolean;
  setCheckedObavezaIzmirena: any;
  getLastDayOfMonth: (date: string) => Date;
  placanjeObracunatiIznosLoading: boolean;
  datumRef: HTMLDivElement | any;
}

interface UseParamsType {
  ustanovaId?: string;
}

export default function UstanovaIzmenaPlacanjeLogical(): UstanovaPlacanjeIzmenaLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const location = useLocation();
  const [ustanovaNaziv] = useState<string>(location.state && location.state.ustanovaNaziv !== undefined ? location.state.ustanovaNaziv : "");
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const [placanjeID] = useState<string>(location.state && location.state.placanjeID !== undefined ? location.state.placanjeID : "");
  const Labels = useLabels();
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [index, setIndex] = useState(tabIndex);
  const navigate = useNavigate();
  const [placanjeChange, setPlacanjeChange] = useState<UstanovaPlacanjeReadDto | UstanovaPlacanjeCreateDto>();
  const [placanjeLoading, setPlacanjeLoading] = useState<boolean>(false);
  const { axiosGetPlacanjeByID, axiosGetObracunatIznos, axiosCreatePlacanje, axiosUpdatePlacanje, axiosDeletePlacanje } = PlacanjeController();
  const [obracunatIznos, setObracunatIznos] = useState<ObracunatIznosReadDto>();
  const [checkedObavezaIzmirena, setCheckedObavezaIzmirena] = useState<boolean>(false);
  const [placanjeOperation] = useState<string>(location.state && location.state.placanjeOperation !== undefined ? location.state.placanjeOperation : EntityOperation.UPDATE);
  const [ustanovaPlacanjeData, setUstanovaPlacanjeData] = useState<UstanovaPlacanjeParamsDto>();
  const [placanjeObracunatiIznosLoading, setPlacanjeObracunatiIznosLoading] = useState<boolean>(false);
  const getObracunOdRef = useRef<string>("");
  const getObracunDoRef = useRef<string>("");
  const datumRef = useRef<HTMLDivElement | any>(null);
  const postLogHighLevel = useLogHighLevel();
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.USTANOVA_LIST,
      command: () => {
        navigate(`/crud-ustanova`);
      },
    },
    {
      label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + ustanovaNaziv,
      command: () => {
        navigate(`/crud-ustanova/${ustanovaId}`, {
          state: {
            tabIndex: 2,
          },
        });
      },
    },
  ]);

  function getLastDayOfMonth(date: string) {
    let datePom = new Date(date);
    return new Date(datePom.getFullYear(), datePom.getMonth() + 1, 0);
  }

  useEffectOnce(() => {
    setUstanovaPlacanjeData({
      ustanovaID: -1,
      datumOd: "",
      datumDo: "",
    });
    setObracunatIznos({
      obracunatiIznos: 0,
    });
    fetchData();
  });

  const fetchData = () => {
    if (placanjeID === "") {
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.PLACANJE_TITLE_CREATE,
        },
      ]);
    }
    if (placanjeID) {
      if (placanjeOperation === EntityOperation.UPDATE) {
        setBreadCrumbItems([
          ...breadCrumbItems,
          {
            label: Labels.PLACANJE_TITLE_DIALOG_UPDATE,
          },
        ]);
      } else {
        setBreadCrumbItems([
          ...breadCrumbItems,
          {
            label: Labels.PLACANJE_TITLE_DIALOG_DELETE,
          },
        ]);
      }
      setPlacanjeLoading(true);
      let startTime = moment(new Date());
      axiosGetPlacanjeByID(parseInt(placanjeID), ustanovaId)
        .then((res: any) => {
          setPlacanjeChange(res.data.data);
          setCheckedObavezaIzmirena(res.data.data.obavezaIzmirena);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPlacanjeLoading, startTime);
        });
    }
  };

  useEffect(() => {
    if (
      placanjeChange !== undefined &&
      placanjeChange?.obracunskiPeriodOd !== undefined &&
      placanjeChange?.obracunskiPeriodDo !== undefined &&
      placanjeChange.obracunskiPeriodDo !== "" &&
      (getObracunOdRef.current !== placanjeChange?.obracunskiPeriodOd || getObracunDoRef.current !== placanjeChange?.obracunskiPeriodDo)
    ) {
      let startTime = moment(new Date());
      setPlacanjeObracunatiIznosLoading(true);
      getObracunOdRef.current = placanjeChange?.obracunskiPeriodOd;
      getObracunDoRef.current = placanjeChange?.obracunskiPeriodDo;
      ustanovaPlacanjeData!.ustanovaID = ustanovaId;
      ustanovaPlacanjeData!.datumOd = placanjeChange.obracunskiPeriodOd;
      ustanovaPlacanjeData!.datumDo = placanjeChange.obracunskiPeriodDo;
      axiosGetObracunatIznos(ustanovaPlacanjeData!)
        .then((res: any) => {
          setObracunatIznos(res.data.data);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPlacanjeObracunatiIznosLoading, startTime);
        });
    }
  }, [placanjeChange?.obracunskiPeriodOd, placanjeChange?.obracunskiPeriodDo, placanjeChange, showMessage, ustanovaId, ustanovaPlacanjeData, axiosGetObracunatIznos]);

  const validateInput = (ustanovaPlacanje: UstanovaPlacanjeReadDto | UstanovaPlacanjeCreateDto | undefined) => {
    if (ustanovaPlacanje === undefined) {
      showMessage(MessageType.ERROR, Labels.PLACANJE_IS_REQUIRED);
      return false;
    }
    if (ustanovaPlacanje.obracunskiPeriodOd === undefined) {
      showMessage(MessageType.ERROR, Labels.USTANOVA_PLACANJE_OBRACUNSKI_PERIOD_OD_IS_REQUIRED);
      return false;
    }
    if (ustanovaPlacanje.obracunskiPeriodOd === undefined) {
      showMessage(MessageType.ERROR, Labels.USTANOVA_PLACANJE_OBRACUNSKI_PERIOD_DO_IS_REQUIRED);
      return false;
    }

    return true;
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/crud-ustanova/${ustanovaId}`, {
        state: {
          tabIndex: 2,
        },
      });
    }
  };

  const onDelete = () => {
    if (placanjeChange !== undefined) {
      axiosDeletePlacanje(parseInt(placanjeID), ustanovaId)
        .then(({ data: { data } }: { data: { data: UstanovaPlacanjeReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.PLACANJE_MESSAGE_DELETE_SUCCESS);
          navigate(`/crud-ustanova/${ustanovaId}`, {
            state: {
              tabIndex: 2,
            },
          });
          setBreadCrumbItems([
            ...breadCrumbItems,
            {
              label: Labels.PLACANJE_TITLE_DIALOG_DELETE,
            },
          ]);

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_1 +
              formatPeriod(new Date(placanjeChange.obracunskiPeriodOd)) +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_5 +
              formatPeriod(new Date(placanjeChange.obracunskiPeriodDo)) +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_2 +
              placanjeChange.obracunatiIznos +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_3 +
              placanjeChange.uplacenIznos +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_4 +
              placanjeChange.datumPlacanja
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCreate = () => {
    if (!validateInput(placanjeChange)) {
      return;
    }
    placanjeChange!.obracunatiIznos = obracunatIznos?.obracunatiIznos !== undefined ? obracunatIznos?.obracunatiIznos : 0;
    placanjeChange!.obavezaIzmirena = checkedObavezaIzmirena;
    placanjeChange!.uplacenIznos = placanjeChange!.uplacenIznos !== undefined ? placanjeChange!.uplacenIznos : 0;

    if (placanjeChange) {
      setShowBlockUI(true);
      axiosCreatePlacanje(placanjeChange, ustanovaId)
        .then(({ data: { data } }: { data: { data: UstanovaPlacanjeReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.PLACANJE_MESSAGE_CREATE_SUCCESS);
          navigate(`/crud-ustanova/${ustanovaId}`, {
            state: {
              tabIndex: 2,
            },
          });
          setBreadCrumbItems([
            ...breadCrumbItems,
            {
              label: Labels.PLACANJE_TITLE_CREATE,
            },
          ]);

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_1 +
              formatPeriod(new Date(placanjeChange.obracunskiPeriodOd)) +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_5 +
              formatPeriod(new Date(placanjeChange.obracunskiPeriodDo)) +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_2 +
              placanjeChange.obracunatiIznos +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_3 +
              placanjeChange.uplacenIznos +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_4 +
              placanjeChange.datumPlacanja
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (placanjeChange !== undefined) {
      placanjeChange.obracunatiIznos = obracunatIznos!.obracunatiIznos;
      axiosUpdatePlacanje(placanjeChange, ustanovaId, parseInt(placanjeID))
        .then(({ data: { data } }: { data: { data: UstanovaPlacanjeReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.PLACANJE_MESSAGE_UPDATE_SUCCESS);
          navigate(`/crud-ustanova/${ustanovaId}`, {
            state: {
              tabIndex: 2,
            },
          });
          setBreadCrumbItems([
            ...breadCrumbItems,
            {
              label: Labels.PLACANJE_TITLE_DIALOG_UPDATE,
            },
          ]);

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_1 +
              formatPeriod(new Date(placanjeChange.obracunskiPeriodOd)) +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_5 +
              formatPeriod(new Date(placanjeChange.obracunskiPeriodDo)) +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_2 +
              placanjeChange.obracunatiIznos +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_3 +
              placanjeChange.uplacenIznos +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_4 +
              placanjeChange.datumPlacanja
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  return {
    index,
    setIndex,
    breadCrumbItems,
    placanjeChange,
    setPlacanjeChange,
    placanjeLoading,
    onCancel,
    obracunatIznos,
    placanjeOperation,
    onCreate,
    checkedObavezaIzmirena,
    setCheckedObavezaIzmirena,
    onUpdate,
    onDelete,
    getLastDayOfMonth,
    placanjeObracunatiIznosLoading,
    datumRef,
  };
}
