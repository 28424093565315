import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import { RolaService } from "../../../controllers/rola/RolaService";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumRola from "../../../infrastructure/system/EnumRola";
import { isSifraIncluded, setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import SkeletonCheckboxItem from "../../app/skeleton/SkeletonCheckboxItem";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import AutocompleteSelect from "../../autocomplete-component/AutocompleteSelect";
import ConfirmComponent from "../../confirm-component/ConfirmComponent";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CrudRadnikLogical from "./CrudRadnikLogical";
import CrudRadnikOrganizacionaJedinica from "./organizaciona-jedinica/CrudRadnikOrganizacionaJedinicaView";
import RadnikSelectionDialog from "./radnik-dialog/RadnikSelectionDialog";
import CrudRadnikSpecijalizacijeView from "./specijalizacije/CrudRadnikSpecijalizacijeView";
import { RadnikUlogaHolder } from "./uloga/CrudRadnikUlogaLogical";
import CrudRadnikUloga from "./uloga/CrudRadnikUlogaView";
import CrudRadnikUstanova from "./ustanova/CrudRadnikUstanovaView";

export default function CrudRadnik() {
  const {
    radnikChange,
    setRadnikChange,
    setIndex,
    index,
    isDisabled,
    breadCrumbItems,
    radnikOperation,
    onCancel,
    onDelete,
    onCreate,
    onUpdate,
    resetPassword,
    location,
    radnikId,
    radnikSearch,
    radnikList,
    searchRadnikAutocomplete,
    changeAutocomplete,
    cancelCopyRadnik,
    onCreateCopyRadnik,
    ustanovaId,
    radnikStatusList,
    radnikLoading,
    radnikStatusListLoading,
    polList,
    polListLoading,
    radnikCreateExisting,
    onCloseRadnikSelectionDialog,
    alreadyCreatedRadnik,
    invalidFields,
    setInvalidFields,
  } = CrudRadnikLogical();
  const Labels = useLabels();
  const { pristup, nadjimed } = useContext(AppContext);
  const { isSuperadmin, isAdmin } = RolaService();

  if (!radnikId && !location.state) {
    return <Navigate to="/radnik-list" />;
  }

  const leftContentsToolbar = () => (
    <>
      {radnikOperation === EntityOperation.CREATE && !radnikSearch?.searchParam && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.RADNIK_TITLE_DIALOG_CREATE} tooltipOptions={tooltipOptionsBottom} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {isNaN(ustanovaId) && radnikOperation === EntityOperation.CREATE && radnikSearch?.searchParam && (
        <>
          <Button
            label={Labels.RADNIK_COPY_TITLE_DIALOG_CREATE}
            tooltip={Labels.RADNIK_COPY_TITLE_DIALOG_CREATE_TOOLTIP}
            tooltipOptions={tooltipOptionsBottom}
            icon="pi pi-plus"
            className={"p-button-warning"}
            onClick={onCreateCopyRadnik}
            disabled={!pristup}
          />
          <Button
            label={Labels.RADNIK_PROFIL_BUTTON_CANCEL}
            tooltip={Labels.RADNIK_COPY_CANCEL}
            tooltipOptions={tooltipOptionsBottom}
            icon="pi pi-times"
            className="p-button-info ml-2"
            onClick={cancelCopyRadnik}
          />
        </>
      )}
      {radnikOperation === EntityOperation.UPDATE && (
        <>
          <Button
            label={Labels.BUTTON_UPDATE}
            disabled={!pristup || radnikLoading}
            icon="pi pi-pencil"
            tooltip={Labels.RADNIK_TITLE_DIALOG_UPDATE}
            tooltipOptions={tooltipOptionsBottom}
            className="p-button-warning"
            onClick={onUpdate}
          />
          {(isSuperadmin || isAdmin) && (
            <ConfirmComponent
              buttonClassName="p-button-info ml-2"
              tooltipPosition={tooltipOptionsBottom}
              tooltip={Labels.RADNIK_TITLE_RESET_PASSWORD}
              buttonLabel={Labels.BUTTON_RESET_PASSWORD}
              buttonIcon="pi pi-refresh"
              confirmTitle={Labels.RESET_PASSWORD_POPUP_MESSAGE}
              disabled={radnikLoading}
              onAccept={() => {
                resetPassword();
              }}
            />
          )}
        </>
      )}
      {radnikOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || radnikLoading}
          icon="pi pi-trash"
          tooltip={Labels.RADNIK_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger  sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0" onClick={onCancel} />;

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_RADNIK}>
            <div className="flex">
              <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
                <div className="grid align-items-center p-0">
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>
                      {Labels.RADNIK_IME}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <>
                        <InputText
                          disabled={!pristup || isDisabled}
                          value={radnikChange?.ime ?? ""}
                          onChange={(e) => {
                            setRadnikChange({
                              ...radnikChange!,
                              ime: e.target.value,
                            });
                          }}
                          onBlur={() => {
                            setInvalid(setInvalidFields, "ime", radnikChange?.ime);
                          }}
                          className={invalidFields?.ime && "p-invalid"}
                        />
                        {invalidFields?.ime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      </>
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>
                      {Labels.RADNIK_PREZIME}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <>
                        <InputText
                          disabled={!pristup || isDisabled}
                          value={radnikChange?.prezime ?? ""}
                          onChange={(e) => {
                            setRadnikChange({
                              ...radnikChange!,
                              prezime: e.target.value,
                            });
                          }}
                          onBlur={() => {
                            setInvalid(setInvalidFields, "prezime", radnikChange?.prezime);
                          }}
                          className={invalidFields?.prezime && "p-invalid"}
                        />
                        {invalidFields?.prezime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      </>
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>
                      {Labels.RADNIK_USERNAME}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <>
                        <InputText
                          disabled={!pristup || isDisabled}
                          value={radnikChange?.username ?? ""}
                          onChange={(e) => {
                            setRadnikChange({
                              ...radnikChange!,
                              username: e.target.value,
                            });
                          }}
                          onBlur={() => {
                            setInvalid(setInvalidFields, "username", radnikChange?.username);
                          }}
                          className={invalidFields?.username && "p-invalid"}
                        />
                        {invalidFields?.username && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      </>
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>
                      {Labels.RADNIK_EMAIL}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <>
                        <InputText
                          disabled={!pristup || isDisabled}
                          value={radnikChange?.email ?? ""}
                          onChange={(e) => {
                            setRadnikChange({
                              ...radnikChange!,
                              email: e.target.value,
                            });
                            setInvalidFields((prev) => ({ ...prev, invalidEmail: false }));
                          }}
                          onBlur={() => {
                            setInvalid(setInvalidFields, "email", radnikChange?.email);
                          }}
                          className={(invalidFields?.email || invalidFields?.invalidEmail) && "p-invalid"}
                        />
                        {invalidFields?.email && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                        {invalidFields?.invalidEmail && <ObaveznoPolje text={Labels.EMAIL_WRONG_FORMAT} />}
                      </>
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>{Labels.RADNIK_TITULA}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={radnikChange?.titula ?? ""}
                        onChange={(e) => {
                          setRadnikChange({
                            ...radnikChange!,
                            titula: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>{Labels.RADNIK_ZANIMANJE}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={radnikChange?.zanimanje ?? ""}
                        onChange={(e) => {
                          setRadnikChange({
                            ...radnikChange!,
                            zanimanje: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>{Labels.JMBG}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={radnikChange?.jmbg ?? ""}
                        onChange={(e) => {
                          setRadnikChange({
                            ...radnikChange!,
                            jmbg: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>{Labels.HEADER_LBO}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={radnikChange?.lbo ?? ""}
                        onChange={(e) => {
                          setRadnikChange({
                            ...radnikChange!,
                            lbo: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>{Labels.HEADER_PHONE}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={radnikChange?.telefon ?? ""}
                        onChange={(e) => {
                          setRadnikChange({
                            ...radnikChange!,
                            telefon: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>{Labels.RADNIK_BROJ_PECATA}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={radnikChange?.idBrojLekara ?? ""}
                        onChange={(e) => {
                          setRadnikChange({
                            ...radnikChange!,
                            idBrojLekara: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>
                      {Labels.RADNIK_STATUS}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading && !radnikStatusListLoading ? (
                      <>
                        <Dropdown
                          options={radnikStatusList}
                          disabled={!pristup || isDisabled}
                          showClear
                          value={radnikChange?.radnikStatus?.sifra ?? undefined}
                          optionLabel="naziv"
                          optionValue="sifra"
                          onChange={(e) => {
                            setRadnikChange({
                              ...radnikChange!,
                              radnikStatus: e.value ? { sifra: e.value } : undefined,
                            });
                            if (e.target.value) {
                              setInvalidFields((prev) => ({ ...prev, status: false }));
                            } else {
                              setInvalidFields((prev) => ({ ...prev, status: true }));
                            }
                          }}
                          emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                          className={invalidFields?.status && "p-invalid"}
                        />
                        {invalidFields?.status && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      </>
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>
                      {Labels.RADNIK_POL}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading && !polListLoading ? (
                      <>
                        <Dropdown
                          options={polList}
                          disabled={!pristup || isDisabled}
                          showClear
                          value={radnikChange?.polTrenutni?.sifra ?? undefined}
                          optionLabel="naziv"
                          optionValue="sifra"
                          onChange={(e) => {
                            setRadnikChange({
                              ...radnikChange!,
                              polTrenutni: e.value ? { sifra: e.value } : undefined,
                            });
                            if (e.target.value) {
                              setInvalidFields((prev) => ({ ...prev, pol: false }));
                            } else {
                              setInvalidFields((prev) => ({ ...prev, pol: true }));
                            }
                          }}
                          emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                          className={invalidFields?.pol && "p-invalid"}
                        />
                        {invalidFields?.pol && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      </>
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>{Labels.RADNIK_RACUNANJE_NALOGA_ZA_LIMIT}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <Checkbox
                        inputId="binary"
                        onChange={(e) => {
                          setRadnikChange({
                            ...radnikChange,
                            racunajNalog: e.checked,
                          });
                        }}
                        checked={radnikChange?.racunajNalog ?? false}
                        disabled={!isSuperadmin}
                      />
                    ) : (
                      <SkeletonCheckboxItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                    <div>{Labels.RADNIK_KALENDAR_INTEGRACIJA}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!radnikLoading ? (
                      <Checkbox
                        checked={radnikChange?.integracijaSaKalendarom ?? false}
                        onChange={(e) =>
                          setRadnikChange({
                            ...radnikChange!,
                            integracijaSaKalendarom: e.checked,
                          })
                        }
                      />
                    ) : (
                      <SkeletonCheckboxItem />
                    )}
                  </div>
                  {(nadjimed || (!isNaN(ustanovaId) && radnikChange?.ustanovaPoslednjaIzabrana?.nadjimed)) &&
                    radnikOperation === EntityOperation.UPDATE &&
                    isSifraIncluded(radnikChange?.radnikUlogaList?.map((radnikUloga: RadnikUlogaHolder) => radnikUloga?.uloga?.rola), EnumRola.LEKAR) && (
                      <>
                        <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                          <div>{Labels.RADNIK_DOSTUPAN_ZA_EKSTERNE_ZAKAZIVACE}</div>
                        </div>
                        <div className="col-12 sm:col-8 p-fluid p-0">
                          {!radnikLoading ? (
                            <Checkbox
                              checked={radnikChange?.dostupanZaEksterneZakazivace ?? false}
                              onChange={(e) =>
                                setRadnikChange({
                                  ...radnikChange!,
                                  dostupanZaEksterneZakazivace: e.checked,
                                })
                              }
                            />
                          ) : (
                            <SkeletonCheckboxItem />
                          )}
                        </div>
                      </>
                    )}
                </div>
              </div>
              {isNaN(ustanovaId) && radnikOperation === EntityOperation.CREATE && (
                <div className="col-4">
                  <div className="grid align-items-center justfy-content-center ">
                    <div className="col-12 p-fluid pt-1 pl-3">
                      <label>{Labels.RADNIK_COPY_TITLE_DIALOG}</label>
                      <AutocompleteSelect
                        name="searchParam"
                        value={radnikSearch?.searchParam}
                        suggestions={radnikList}
                        completeMethod={searchRadnikAutocomplete}
                        changeData={changeAutocomplete}
                        onSelect={() => {}}
                        onUnselect={() => {}}
                        fieldName="title"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
          </TabPanel>

          <TabPanel header={Labels.ULOGA_LIST} disabled={radnikOperation === EntityOperation.CREATE}>
            <CrudRadnikUloga
              isDisabled={isDisabled}
              radnikOperation={radnikOperation}
              ustanovaId={ustanovaId}
              radnikIme={radnikChange?.ime}
              radnikPrezime={radnikChange?.prezime}
              radnikChange={radnikChange}
              setRadnikChange={setRadnikChange}
            />
          </TabPanel>

          <TabPanel header={Labels.ORGANIZACIONA_JEDINICA_LIST} disabled={radnikOperation === EntityOperation.CREATE}>
            <CrudRadnikOrganizacionaJedinica isDisabled={isDisabled} radnikOperation={radnikOperation} ustanovaId={ustanovaId} radnikIme={radnikChange?.ime} radnikPrezime={radnikChange?.prezime} />
          </TabPanel>

          <TabPanel header={Labels.LABEL_SPECIJALIZACIJE_LIST} disabled={radnikOperation === EntityOperation.CREATE}>
            <CrudRadnikSpecijalizacijeView isDisabled={isDisabled} radnikOperation={radnikOperation} ustanovaId={ustanovaId} radnik={`${radnikChange?.ime} ${radnikChange?.prezime}`} />
          </TabPanel>

          {isNaN(ustanovaId) && isSuperadmin && (
            <TabPanel header={Labels.USTANOVA_LIST} disabled={radnikOperation === EntityOperation.CREATE}>
              <CrudRadnikUstanova isDisabled={isDisabled} radnikOperation={radnikOperation} />
            </TabPanel>
          )}
        </TabView>
      </Panel>

      <RadnikSelectionDialog
        header={Labels.RADNIK_BIRANJE_PODATAKA}
        visible={radnikCreateExisting}
        onCloseRadnikSelectionDialog={onCloseRadnikSelectionDialog}
        alreadyCreatedRadnik={alreadyCreatedRadnik}
        radnikChange={radnikChange}
      />
      <ConfirmPopup />
    </div>
  );
}
