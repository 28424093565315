import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import UstanovaZakazivacCreateDto from "../../model/eksterni-zakazivac/UstanovaZakazivacCreateDto";

interface EksterniZakazivaciControllerType {
  axiosGetUstanovaZakazivacList: () => Promise<AxiosResponse<any>>;
  axiosConnectSchedulerAndProvider: (zakazivacId: number, ustanovaZakazivacCreateDto: any) => Promise<AxiosResponse<any, any>>;
  axiosDisconnectSchedulerAndProvider: (zakazivacId: number) => Promise<AxiosResponse<any, any>>;
}

export default function EksterniZakazivaciController(): EksterniZakazivaciControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetUstanovaZakazivacList = () => {
    return axios.get(Endpoint.NADJIMED_ZAKAZIVACI_LIST, axiosConfig(authData!.token));
  };

  const axiosConnectSchedulerAndProvider = (zakazivacId: number, ustanovaZakazivac: UstanovaZakazivacCreateDto) => {
    return axios.post(`${Endpoint.NADJIMED_ZAKAZIVACI_LIST}/${zakazivacId}`, ustanovaZakazivac, axiosConfig(authData!.token));
  };

  const axiosDisconnectSchedulerAndProvider = (zakazivacId: number) => {
    return axios.delete(`${Endpoint.NADJIMED_ZAKAZIVACI_LIST}/${zakazivacId}`, axiosConfig(authData!.token));
  };

  return {
    axiosGetUstanovaZakazivacList,
    axiosConnectSchedulerAndProvider,
    axiosDisconnectSchedulerAndProvider,
  };
}
