import { useQuery } from "@tanstack/react-query";
import { GlobalnaDatotekaController } from "../../../../controllers/globalna-datoteka/GlobalnaDatotekaController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";

export function useGlobalnaDatotekaList() {
  const { axiosGetGlobalnaDatotekaList } = GlobalnaDatotekaController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GLOBALNA_DATOTEKA_LIST],
    queryFn: () => axiosGetGlobalnaDatotekaList(),
  });

  const globalnaDatotekaList = data?.data?.data ?? [];

  return { globalnaDatotekaList, isLoading };
}
