import axios from "axios";
import React, { useContext, useReducer, useRef, useState } from "react";
import { Client } from "stompjs";
import { AppContext, useLabels } from "../../Store";
import { EnumController } from "../../controllers/enum/EnumController";
import { handleAxiosCallError, useEffectOnce } from "../../infrastructure/system/Utils";
import actionsBrisanjeIzvestaja from "../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/actionsBrisanjeIzvestaja";
import InitialStateBrisanjeIzvestaja from "../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/initialStateBrisanjeIzvestaja";
import reducerBrisanjeIzvestaja from "../../infrastructure/system/hooks/dialog-brisanje-izvestaja-reducer/reducerBrisanjeIzvestaja";
import actions from "../../infrastructure/system/hooks/generisani-izvestaj-reducer/actionsGenerisaniIzvestaj";
import { InitialStateGenerisaniIzvestajType } from "../../infrastructure/system/hooks/generisani-izvestaj-reducer/initialStateGenerisaniIzvestaj";
import actionsZakazaniIzvestaj from "../../infrastructure/system/hooks/zakazani-izvestaj-reducer/actionsZakazaniIzvestaj";
import InitialStateZakazaniIzvestaj, { InitialStateZakazaniIzvestajType } from "../../infrastructure/system/hooks/zakazani-izvestaj-reducer/initialStateZakazaniIzvestaj";
import reducerZakazaniIzvestaji from "../../infrastructure/system/hooks/zakazani-izvestaj-reducer/reducerZakazaniIzvestaji";
import BreadCrumbItemDto from "../../model/BreadCrumbItemDto";
import GenerisaniIzvestajSmallReadDto from "../../model/generisaniIzvestaj/GenerisaniIzvestajSmallReadDto";
import IzvestajSimpleReadDto from "../../model/izvestaj/IzvestajSimpleReadDto";
import EnumBaseReadDto from "../../model/sifarnik/EnumBaseReadDto";
import { ZakazanIzvestajType } from "./zakazani-izvestaji/ZakazaniIzvestajListLogical";

interface GenerisaniIzvestajContextProps {
  state: InitialStateGenerisaniIzvestajType;
  dispatch: React.Dispatch<{ type: actions; value: any }>;
  fetchData: (fetchTotalCount: boolean, page: number, size: number, radnikID?: number, statistickiIzvestaj?: IzvestajSimpleReadDto) => void;
  onExport: (format: string, generisaniIzvestaj: GenerisaniIzvestajSmallReadDto) => void;
  generisiIzvestaj: () => void;
  dispatchDialog: React.Dispatch<{ type: actionsBrisanjeIzvestaja; data: any; flag: string; from: string }>;
  zakaziIzvestaj: (izvestaj: IzvestajSimpleReadDto | undefined, zakazanIzvestajChange: ZakazanIzvestajType | undefined) => void;
  izmeniIzvestaj: (id: number, izvestaj: IzvestajSimpleReadDto | undefined, zakazanIzvestajChange: ZakazanIzvestajType | undefined) => void;
  stateZakazani: InitialStateZakazaniIzvestajType;
  dispatchZakazani: React.Dispatch<{ type: actionsZakazaniIzvestaj; data: any }>;
  fetchZakazaniData: (radnikID?: number, statistickiIzvestaj?: IzvestajSimpleReadDto) => void;
  getZakazaniIzvestajParams: (izvestaj: GenerisaniIzvestajSmallReadDto) => void;
  zakazanIzvestajChange: ZakazanIzvestajType;
  setZakazanIzvestajChange: React.Dispatch<React.SetStateAction<ZakazanIzvestajType>>;
  addEmail: () => void;
  onCancel: () => void;
  removeEmail: (email: string) => void;
  index: number;
}

export const GenerisaniIzvestajContext = React.createContext({} as GenerisaniIzvestajContextProps);

export default function GenerisaniIzvestajLogical() {
  const { axiosGetEnumPonavljanjeList, axiosGetEnumPeriodList, axiosGetRadniDanList } = EnumController();

  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [stateDialog, dispatchDialog] = useReducer(reducerBrisanjeIzvestaja, InitialStateBrisanjeIzvestaja);
  const [stateZakazani, dispatchZakazani] = useReducer(reducerZakazaniIzvestaji, InitialStateZakazaniIzvestaj);

  let stompClient = useRef<Client>();
  const [index, setIndex] = useState<number>(0);
  const [ponavljanjeList, setPonavljanjeList] = useState<EnumBaseReadDto[]>([]);
  const [periodList, setPeriodList] = useState<EnumBaseReadDto[]>([]);
  const [radniDaniList, setRadniDaniList] = useState<EnumBaseReadDto[]>([]);

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.IZVESTAJ_LIST,
    },
  ];

  useEffectOnce(() => {
    fetchInputOptions();
    return () => {
      if (stompClient.current?.connected) {
        stompClient.current?.disconnect(() => {});
      }
    };
  });

  const fetchInputOptions = () => {
    const requestPonavljanjeList = axiosGetEnumPonavljanjeList();
    const requestPeriodList = axiosGetEnumPeriodList();
    const requestRadniDaniList = axiosGetRadniDanList();
    axios
      .all([requestPonavljanjeList, requestPeriodList, requestRadniDaniList])
      .then(
        axios.spread((responsePonavljanjeList: any, responsePeriodList: any, responseRadniDaniList: any) => {
          setPonavljanjeList(responsePonavljanjeList.data.data);
          setPeriodList(responsePeriodList.data.data);
          setRadniDaniList(responseRadniDaniList.data.data);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return {
    breadCrumbItems,
    stateDialog,
    dispatchDialog,
    index,
    setIndex,
    stateZakazani,
    dispatchZakazani,
    ponavljanjeList,
    periodList,
    radniDaniList,
    fetchInputOptions,
  };
}
