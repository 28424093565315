import { Button } from "primereact/button";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../../Store";
import EnumTipTerapijePrikaz from "../../../../../model/kontakt/EnumTipTerapijePrikaz";
import ReceptReadDto from "../../../../../model/kontakt/ReceptReadDto";
import DialogComponent from "../../../../dialog-component/DialogComponent";

interface KontaktTerapijaPreviewType {
  isPreviewOpen: boolean;
  closePreview: () => void;
  receptPreview: ReceptReadDto | undefined;
  isDeleteDialog?: boolean;
  deleteTerapija?: (recept: ReceptReadDto) => void;
}

export default function KontaktTerapijaPreview(props: KontaktTerapijaPreviewType) {
  const { isPreviewOpen, closePreview, receptPreview, isDeleteDialog, deleteTerapija } = props;
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const renderFooter = () => {
    return (
      <div className="flex justify-content-end layout-pacijent-dosije">
        <Button label={Labels.BUTTON_CLOSE} className="p-button-outlined p-button-danger bg-white hc-color-orange" onClick={closePreview} autoFocus />
        {isDeleteDialog && (
          <Button
            label={Labels.BUTTON_DELETE}
            tooltip={Labels.KONTAKT_TERAPIJA_DELETE}
            className="p-button-success"
            onClick={() => {
              receptPreview && deleteTerapija && deleteTerapija(receptPreview);
            }}
            disabled={!pristup}
          />
        )}
      </div>
    );
  };

  const renderTrajanjeTerapije = (receptPreview: ReceptReadDto | undefined) => {
    if (receptPreview?.akutnaBrojDana) {
      return receptPreview?.akutnaBrojDana + " " + Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_DANA;
    } else if (receptPreview?.hronicnaBrojMeseci) {
      return receptPreview?.hronicnaBrojMeseci + " " + Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_MESECI;
    } else {
      return "";
    }
  };

  const renderNacinDoziranja = (receptPreview: ReceptReadDto | undefined) => {
    if (receptPreview?.dnevnaKolicina) {
      return receptPreview?.brojUzimanjaTokomDana + " x " + receptPreview?.dnevnaKolicina + ", ";
    } else if (receptPreview?.jutarnjaKolicina && receptPreview.popodnevnaKolicina && receptPreview.vecernjaKolicina) {
      return (
        receptPreview?.jutarnjaKolicina +
        Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_UJUTRO +
        receptPreview.popodnevnaKolicina +
        Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_POPODNE +
        receptPreview.vecernjaKolicina +
        Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_UVECE +
        ", "
      );
    } else if (receptPreview?.jednokratnaKolicina) {
      return Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO_PRUZANJE + " " + receptPreview?.jednokratnaKolicina + ", ";
    } else if (receptPreview?.naKolikoDana && receptPreview?.kolicinaNaViseDana) {
      return (
        Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_NA +
        receptPreview?.naKolikoDana +
        " " +
        Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DANA_PO +
        " " +
        receptPreview?.kolicinaNaViseDana +
        ", "
      );
    } else if (receptPreview?.kolicinaPoPotrebi) {
      return Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_PO_POTREBI + " " + receptPreview?.kolicinaPoPotrebi + ", ";
    } else if (receptPreview?.proizvoljnaKolicina) {
      return Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_PROIZVOLJNO + receptPreview.proizvoljnaKolicina + ", ";
    } else {
      return "";
    }
  };

  return (
    <DialogComponent
      header={""}
      visible={isPreviewOpen}
      footer={renderFooter}
      onHide={closePreview}
      blockScroll={true}
      className="w-11 md:w-9 lg:w-5 xl:w-6"
      content={
        <>
          {receptPreview?.dijagnoza.sifarnikMkb.nazivLatinski && (
            <div className="flex flex-row flex-wrap">
              <div className="col-12 pb-0 sm:pb-3 sm:col-4 font-bold">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_DIJAGNOZA}:</div>
              <div className="col-12 sm:col-8">{receptPreview?.dijagnoza.sifarnikMkb?.sifraBezTacke + " " + receptPreview?.dijagnoza.sifarnikMkb?.nazivLatinski}</div>
            </div>
          )}
          {receptPreview?.lek?.naziv && (
            <div className="flex flex-row flex-wrap">
              <div className="col-12 pb-0 sm:pb-3 sm:col-4 font-bold">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK}:</div>
              <div className="col-12 sm:col-8">{receptPreview?.lek.naziv}</div>
            </div>
          )}
          {receptPreview?.lrAtribut?.naziv && (
            <div className="flex flex-row flex-wrap">
              <div className="col-12 pb-0 sm:pb-3 sm:col-4 font-bold">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK_ATRIBUT}:</div>
              <div className="col-12 sm:col-8">{receptPreview?.lrAtribut.naziv}</div>
            </div>
          )}
          {receptPreview?.tipTerapije?.naziv && (
            <div className="flex flex-row flex-wrap">
              <div className="col-12 pb-0 sm:pb-3 sm:col-4 font-bold">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_TIP_TERAPIJE}:</div>
              <div className="col-12 sm:col-8">{receptPreview?.tipTerapije.naziv}</div>
            </div>
          )}
          {receptPreview?.tipTerapije?.naziv === EnumTipTerapijePrikaz.NARKOTIK && (
            <div className="flex flex-row flex-wrap">
              <div className="col-12 pb-0 sm:pb-3 sm:col-4 font-bold">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PROTOKOLA}:</div>
              <div className="col-12 sm:col-8">{receptPreview?.brojProtokola}</div>
            </div>
          )}
          {receptPreview?.tipTerapije?.naziv === EnumTipTerapijePrikaz.HRONICNA && (
            <div className="flex flex-row flex-wrap">
              <div className="col-12 pb-0 sm:pb-3 sm:col-4 font-bold">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_IZDAVANJA}:</div>
              <div className="col-12 sm:col-8">{receptPreview?.brojIzdavanja}</div>
            </div>
          )}
          <div className="flex flex-row flex-wrap">
            <div className="col-12 pb-0 sm:pb-3 sm:col-4 font-bold">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_HITNO_IZDAVANJE}:</div>
            <div className="col-12 sm:col-8">{receptPreview?.hitnoIzdavanje ? Labels.YES : Labels.NO}</div>
          </div>
          <div className="flex flex-row flex-wrap">
            <div className="col-12 pb-0 sm:pb-3 sm:col-4 font-bold">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_ZABRANA_TERAPIJSKE_PARALELE}:</div>
            <div className="col-12 sm:col-8">{receptPreview?.terapijskaParalelaZabranjena ? Labels.YES : Labels.NO}</div>
          </div>
          {receptPreview?.jedinicaMereFull && (
            <div className="flex flex-row flex-wrap">
              <div className="col-12 pb-0 sm:pb-3 sm:col-4 font-bold">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_TITLE}:</div>
              <div className="col-12 sm:col-8">
                {receptPreview?.jedinicaMereFull.akronim +
                  ", " +
                  renderNacinDoziranja(receptPreview) +
                  renderTrajanjeTerapije(receptPreview) +
                  (receptPreview?.napomena ? ", " + receptPreview?.napomena : "")}
              </div>
            </div>
          )}
        </>
      }
    />
  );
}
