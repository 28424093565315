import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../Store";
import KontrolaPravaPristupaService from "../../../infrastructure/system/KontrolaPravaPristupaService";
import { addSrLocal, formatDate, hrefForDownloadAttachment, isDateTimeFormatValid } from "../../../infrastructure/system/Utils";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import CalendarComponent from "../../administracija/calendar-component/CalendarComponent";
import ConfirmComponent from "../../confirm-component/ConfirmComponent";
import PodaciSmrtiLogical from "./PodaciSmrtiLogical";

interface PodaciSmrtiType {
  pacijentVremeSmrti?: string;
  radnikKreiraoId?: number;
  setPacijent?: React.Dispatch<React.SetStateAction<PacijentReadDto | undefined>>;
}

export default function PodaciSmrti(props: PodaciSmrtiType) {
  const Labels = useLabels();
  const { pristup, authData } = useContext(AppContext);
  const { pacijentVremeSmrti, radnikKreiraoId, setPacijent } = props;
  const { vremeSmrti, setVremeSmrti, savePodaciSmrti, deletePodaciSmrti, documentUploadHandler, onRemoveDocument, dokumentSmrtPacijenta, calendarRef } = PodaciSmrtiLogical({
    pacijentVremeSmrti,
    setPacijent,
  });
  const documentUploadRef = useRef<any>(null);
  const { hasPacijentUpdate } = KontrolaPravaPristupaService();
  const cancelOptions = { className: "class-visibility-hidden" };
  addSrLocal();
  const rightContent = () => (
    <div className="flex justify-content-end">
      <ConfirmComponent
        disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
        buttonLabel={Labels.BUTTON_DELETE_DATA}
        buttonClassName="p-button-outlined p-button-success"
        acceptClassName="p-button p-component text-white p-button-tertiary"
        rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
        confirmTitle={Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_VREME_SMRTI}
        confirmIcon="pi pi-exclamation-triangle"
        onAccept={() => deletePodaciSmrti()}
      />
      <Button
        label={Labels.BUTTON_SAVE}
        className="p-button-success ml-2"
        onClick={() => savePodaciSmrti()}
        disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
      />
    </div>
  );
  return (
    <div className="col-8 xl-8 lg-8 md-6 sm-12">
      <div className="grid align-center nogutter">
        <div className="col-4 font-bold">
          <div>{Labels.VREME_SMRTI}</div>
        </div>
        <div className="col-8 p-fluid">
          <CalendarComponent
            ref={calendarRef}
            value={vremeSmrti?.vremeSmrti}
            onChange={(e: CalendarChangeEvent) => setVremeSmrti({ vremeSmrti: formatDate(e.value as Date | undefined) })}
            onInput={(e) => {
              if (isDateTimeFormatValid(e.currentTarget.value)) calendarRef?.current?.hide();
            }}
            placeholder={Labels.PLACEHOLDER_VREME_FORMAT}
            showTime={true}
            disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
            maxDate={new Date()}
          />
        </div>
        <div className="col-4 font-bold">
          <div>{Labels.DOKUMENT}</div>
        </div>
        <div className="col-8 p-fluid">
          <FileUpload
            ref={documentUploadRef}
            auto
            mode="basic"
            name={Labels.PODACI_O_SMRTI}
            cancelOptions={cancelOptions}
            multiple={false}
            customUpload
            //emptyTemplate={emptyTemplate}
            uploadHandler={(e) => {
              documentUploadHandler(e);
              documentUploadRef.current.clear();
            }}
            chooseLabel={Labels.CHOOSE_DOCUMENT}
            disabled={!pristup || radnikKreiraoId === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
          />
          {dokumentSmrtPacijenta && (
            <div className="col-8 p-fluid p-0 ml-2 mb-2">
              <div className="flex flex-row flex-wrap">
                <div className="mr-2 mt-2">
                  <a className="cursor-pointer p-link " href={hrefForDownloadAttachment(dokumentSmrtPacijenta.tip, dokumentSmrtPacijenta.sadrzaj)} download={dokumentSmrtPacijenta.naziv}>
                    {dokumentSmrtPacijenta.naziv}
                  </a>
                </div>
                <ConfirmComponent
                  disabled={!pristup}
                  tooltip={Labels.REMOVE_DOKUMENT}
                  buttonClassName="p-button-outlined p-button-danger"
                  buttonIcon="pi pi-trash"
                  acceptClassName="p-button p-component text-white p-button-tertiary"
                  rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
                  confirmIcon="pi pi-exclamation-triangle"
                  confirmTitle={Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_DOKUMENT}
                  onAccept={() => {
                    onRemoveDocument(documentUploadRef);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {rightContent()}
    </div>
  );
}
