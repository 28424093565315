import { Button } from "primereact/button";
import { isMobile } from "react-device-detect";
import { DATE_FORMAT, DATE_FORMAT_3, DAY_IN_WEEK_FORMAT } from "../../../../infrastructure/system/Utils";
import { useRef } from "react";
import { Calendar } from "primereact/calendar";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import moment from "moment";

export interface CalendarWeekViewType {
  selectedDate?: moment.Moment;
  setSelectedDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  dates: Array<moment.Moment>;
  leftButton: () => void;
  rigthButton: () => void;
  parseDatesForGrid: (datesArray: Array<moment.Moment>) => Array<moment.Moment>;
  dateSelectEnabled?: boolean;
  tabelarniPrikaz?: boolean;
  setDatesWithFirstDate?: (firstDate: moment.Moment, newNumOfDays?: number, newDisplayType?: number, isTabelarni?: boolean) => void;
}

export default function CalendarWeekView(props: CalendarWeekViewType) {
  const { dates, selectedDate, setSelectedDate, leftButton, rigthButton, parseDatesForGrid, dateSelectEnabled, tabelarniPrikaz, setDatesWithFirstDate } = props;
  const calendarRef = useRef<Calendar>(null);

  return (
    <div className="hc-scheduler-calendar">
      <div className="hc-scheduler-calendar-buttons">
        <Button icon="pi" className="p-button-rounded p-button-outlined p-button-plain" onClick={leftButton} />
        <Button icon="pi" className="p-button-rounded p-button-outlined p-button-plain" onClick={rigthButton} />
      </div>
      <div className={`hc-scheduler-calendar-days ${isMobile ? "padding-right-50px" : ""}`} id="daysDiv">
        {parseDatesForGrid(dates)?.map(
          (date: moment.Moment, index: number) =>
            date && (
              <div key={index}>
                <div
                  className={`inline-block justify-content-center align-items-center p-${isMobile ? "0" : "3"} calendar-week-date ${dateSelectEnabled ? "cursor-pointer" : ""}
                  ${date.format(DATE_FORMAT_3) === selectedDate?.format(DATE_FORMAT_3) && "selected-date"}`}
                  onClick={() => {
                    if (dateSelectEnabled && !tabelarniPrikaz) setSelectedDate(date);
                    if (dateSelectEnabled && tabelarniPrikaz) calendarRef?.current && calendarRef?.current?.show();
                  }}
                >
                  <h6 className="capitalize m-0">{date.format(DAY_IN_WEEK_FORMAT)}</h6>
                  <h6 className="text-center m-0">{date.format(DATE_FORMAT)}</h6>
                </div>
              </div>
            )
        )}
      </div>
      {tabelarniPrikaz && (
        <CalendarComponent
          ref={calendarRef}
          onChange={(e) => {
            setSelectedDate(moment(e.value as Date));
            setDatesWithFirstDate && setDatesWithFirstDate(moment(e.value as Date), undefined, undefined, true);
          }}
          appendTo={document.getElementById("daysDiv")}
          className="hidden-calendar-input"
          showButtonBar={false}
          panelClassName="panel-calendar-input"
        />
      )}
    </div>
  );
}
