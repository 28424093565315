import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import UstanovaEksterniSistemReadDto from "../../../../model/eksterni-sistem/UstanovaEksterniSistemReadDto";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import UstanovaEksterniSistemListLogical from "./UstanovaEksterniSistemListLogical";

interface ustanovaEksterniSistemListProps {
  isDisabled: boolean;
  ustanova?: UstanovaReadDto;
}

export default function UstanovaEksterniSistemListView(props: ustanovaEksterniSistemListProps) {
  const { isDisabled, ustanova } = props;
  const { eksterniSistemList, dt, first, tableRows, onPage, openEksterniSistem, eksterniSistemListLoading } = UstanovaEksterniSistemListLogical({ ustanova: ustanova });
  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_USTANOVA_EKSTERNI_SISTEM}
          tooltip={Labels.USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openEksterniSistem(EntityOperation.CREATE);
          }}
        />
      </>
    );
  };

  const crudBodyTemplate = (rowEksterniSistem: UstanovaEksterniSistemReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowEksterniSistem && openEksterniSistem(EntityOperation.UPDATE, rowEksterniSistem.id)}
        />
        <Button
          type="button"
          tooltip={Labels.USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => {
            rowEksterniSistem && openEksterniSistem(EntityOperation.DELETE, rowEksterniSistem.id);
          }}
        />
      </div>
    );
  };
  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.RADNIK_USERNAME, filter: true, sortrable: true },
    { columnName: Labels.EKSTERNI_SISTEM, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        {!isDisabled && <Toolbar left={searchToolbar} />}
        {eksterniSistemListLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={!isDisabled} />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={eksterniSistemList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e: DataTablePageEvent) => onPage(e.rows, e.first)}
            exportFilename={Labels.USTANOVA_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"username"} header={Labels.RADNIK_USERNAME} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"naziv"} header={Labels.EKSTERNI_SISTEM} filter filterMatchMode="contains" showFilterMenu={false} sortable />

            {!isDisabled && <Column body={crudBodyTemplate} />}
          </DataTable>
        )}
        <Toolbar />
      </Panel>
    </div>
  );
}
