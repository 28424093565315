import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { VerzijaController } from "../../../../controllers/verzija/VerzijaController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";
import { handleAxiosCallError, handleAxiosCallSuccess } from "../../../../infrastructure/system/Utils";
import VerzijaStavkaUpdateDto from "../../../../model/verzija/VerzijaStavkaUpdateDto";

export function useUpdateVerzijaStavka() {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosUpdateVerzijaStavka } = VerzijaController();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ verzijaId, verzijaStavka }: { verzijaId: number; verzijaStavka: VerzijaStavkaUpdateDto }) => {
      return axiosUpdateVerzijaStavka(verzijaId, verzijaStavka.id, verzijaStavka);
    },
    onSuccess: () => {
      handleAxiosCallSuccess(showMessage, Labels.VERZIJA_STAVKA_UPDATE_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.VERZIJA_LIST] });
    },
    onError: (err) => {
      handleAxiosCallError(showMessage, err);
    },
  });
}
