import { useQuery } from "@tanstack/react-query";
import { PacijentPoljeController } from "../../../../controllers/pacijent-polje/PacijentPoljeController";
import QueryKeys from "../../QueryKeys";

export function usePacijentPoljeKategorijaList(sifraKategorija: string) {
  const { axiosGetPacijentPoljeKategorijaListByKategorija } = PacijentPoljeController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.PACIJENT_POLJE_KATEGORIJA_LIST, sifraKategorija],
    queryFn: () => axiosGetPacijentPoljeKategorijaListByKategorija(sifraKategorija),
  });

  const pacijentPoljeKategorijaList = data?.data?.data ?? [];

  return { pacijentPoljeKategorijaList, isLoading };
}
