import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../../Store";
import Endpoint from "../../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../../infrastructure/system/Utils";
import { default as SearchBaseDto } from "../../../model/SearchBaseDto";
import UstanovaStorageLimitCreateDto from "../../../model/ustanova/licenca/UstanovaStorageLimitCreateDto";
import UstanovaStorageLimitUpdateDto from "../../../model/ustanova/licenca/UstanovaStorageLimitUpdateDto";

interface UstanovaStorageLimitControllerType {
  axiosGetUstanovaStorageLimitList: (UstanovaId: number) => Promise<AxiosResponse<any>>;
  axiosGetUstanovaStorageLimit: (UstanovaStorageLimitId: number, ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateUstanovaStorageLimit: (UstanovaStorageLimit: UstanovaStorageLimitCreateDto, ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateUstanovaStorageLimit: (UstanovaStorageLimit: UstanovaStorageLimitUpdateDto, ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSearchUstanovaStorageLimit: (storageLimitSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosDeleteUstanovaStorageLimit: (ustanovaStorageLimitId: number, ustanovaId: number) => Promise<AxiosResponse<any>>;
}

export default function UstanovaStorageLimitController(): UstanovaStorageLimitControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetUstanovaStorageLimitList = (UstanovaId: number) => {
    return axios.get(`${Endpoint.USTANOVA_STORAGE_LIMIT_LIST_USTANOVA}/${UstanovaId}`, axiosConfig(authData!.token));
  };

  const axiosGetUstanovaStorageLimit = (UstanovaStorageLimitId: number, ustanovaId: number) => {
    return axios.get(`${Endpoint.USTANOVA_STORAGE_LIMIT_LIST}/${UstanovaStorageLimitId}/ustanova/${ustanovaId}`, axiosConfig(authData!.token));
  };

  const axiosCreateUstanovaStorageLimit = (UstanovaStorageLimit: UstanovaStorageLimitCreateDto, ustanovaId: number) => {
    return axios.post(`${Endpoint.USTANOVA_STORAGE_LIMIT_LIST}/ustanova/${ustanovaId}`, UstanovaStorageLimit, axiosConfig(authData!.token));
  };

  const axiosUpdateUstanovaStorageLimit = (UstanovaStorageLimit: UstanovaStorageLimitUpdateDto, ustanovaId: number) => {
    return axios.put(`${Endpoint.USTANOVA_STORAGE_LIMIT_LIST}/${UstanovaStorageLimit.id}/ustanova/${ustanovaId}`, UstanovaStorageLimit, axiosConfig(authData!.token));
  };

  const axiosSearchUstanovaStorageLimit = (storageLimitSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.USTANOVA_STORAGE_LIMIT_LIST, axiosConfig(authData!.token, storageLimitSearchData));
  };
  const axiosDeleteUstanovaStorageLimit = (ustanovaStorageLimitId: number, ustanovaId: number) => {
    return axios.delete(`${Endpoint.USTANOVA_STORAGE_LIMIT_LIST}/${ustanovaStorageLimitId}/ustanova/${ustanovaId}`, axiosConfig(authData!.token));
  };

  return {
    axiosGetUstanovaStorageLimitList,
    axiosCreateUstanovaStorageLimit,
    axiosGetUstanovaStorageLimit,
    axiosUpdateUstanovaStorageLimit,
    axiosSearchUstanovaStorageLimit,
    axiosDeleteUstanovaStorageLimit,
  };
}
