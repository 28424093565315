import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";

interface LoginControllerType {
  axiosResetPassword: (email: string) => Promise<AxiosResponse<any>>;
}

export function LoginController(): LoginControllerType {
  const { authData } = useContext(AppContext);

  const axiosResetPassword = (email: string) => {
    return axios.post(`${Endpoint.RESET_PASSWORD}/${email}`, {}, axiosConfig(authData!.token));
  };

  return { axiosResetPassword };
}
