import { useQuery } from "@tanstack/react-query";
import { ZakazaniTerminController } from "../../../../../controllers/zakazani-termin/ZakazaniTerminController";
import QueryKeys from "../../../../../infrastructure/system/QueryKeys";
import { useContext } from "react";
import { AppContext } from "../../../../../Store";

export function useZakazanTerminDatotekaListForZakazanTermin(zakazanTerminID: number | undefined) {
  const { axiosGetDatotekeForZakazaniTermin } = ZakazaniTerminController();
  const { nadjimed } = useContext(AppContext);
  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.ZAKAZAN_TERMIN_DATOTEKA_LIST, { zakazanTerminID }],
    queryFn: () => axiosGetDatotekeForZakazaniTermin(zakazanTerminID!),
    enabled: !!zakazanTerminID && nadjimed,
  });

  const zakazanTerminDatotekaList = data?.data?.data ?? [];

  return { zakazanTerminDatotekaList, refetch, isLoading };
}
