import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";
import Images from "../../../../infrastructure/system/Images";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import { usePolList } from "../../../../infrastructure/system/hooks/enum/usePolList";
import { useUlogaList } from "../../../../infrastructure/system/hooks/uloga/useUlogaList";
import { useOrganizacionaJedinicaList } from "../../../../infrastructure/system/hooks/organizaciona-jedinica/useOrganizacionaJedinicaList";
import React, { useContext } from "react";
import { useInicijalizacijaRadnikList } from "../../../../infrastructure/system/hooks/radnik/useInicijalizacijaRadnikList";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import { InitialStateType } from "../../hooks/initialState";
import { ReducerType } from "../../hooks/reducer";
import RadnikInicijalizacijaReadDto from "../../../../model/inicijalizacija/RadnikInicijalizacijaReadDto";
import RadnikUlogaSimpleReadDto from "../../../../model/radnik/RadnikUlogaSimpleReadDto";
import InvalidFieldsType from "../../../../model/InvalidFieldsType";
import { AppContext, useLabels } from "../../../../Store";
import { setInvalid } from "../../../../infrastructure/system/Utils";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { useTableData } from "../../../../infrastructure/system/hooks/tables/useTableData";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";

interface InicijalizacijaRadniciViewPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerType>;
  invalidFieldsRadnik: InvalidFieldsType | undefined;
  setInvalidFieldsRadnik: React.Dispatch<React.SetStateAction<InvalidFieldsType | undefined>>;
  validateRadnik: () => boolean;
  onSubmitRadnik: () => void;
  onDeleteRadnik: UseMutationResult<AxiosResponse, unknown, number, unknown>;
}

export default function InicijalizacijaRadniciView({
  state,
  dispatch,
  invalidFieldsRadnik,
  setInvalidFieldsRadnik,
  validateRadnik,
  onSubmitRadnik,
  onDeleteRadnik,
}: InicijalizacijaRadniciViewPropsType) {
  const Labels = useLabels();
  const { authData } = useContext(AppContext);
  const { polList, isLoading: isLoadingPolList } = usePolList();
  const { isLoading: isUlogaListLoading, uloge } = useUlogaList();
  const { organizacionaJedinicaList, isLoading: isLoadingOrganizacionaJedinicaList } = useOrganizacionaJedinicaList();
  const { inicijalizacijaRadnikList, isLoading: isLoadingInicijalizacijaRadnikList } = useInicijalizacijaRadnikList();
  const { first, tableRows, onPage } = useTableData();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const crudBodyTemplate = (rowRadnik: RadnikInicijalizacijaReadDto) => {
    return (
      <div className="flex justify-content-end">
        {(state?.isRadnikEditing ? rowRadnik.id === state?.radnikChange?.id : true) && (
          <Button
            className={`${state?.isRadnikEditing && rowRadnik.id === state?.radnikChange?.id ? "p-button-primary" : "p-button-secondary"} mr-2`}
            icon="pi pi-pencil"
            rounded
            text
            onClick={() => {
              if (!state?.isRadnikEditing) {
                dispatch({
                  type: "set_radnik",
                  data: {
                    ...rowRadnik,
                    // @ts-ignore
                    radnikUlogaList: rowRadnik.radnikUlogaList?.map((uloga) => {
                      // @ts-ignore
                      return { id: uloga.uloga?.id };
                    }),
                    // @ts-ignore
                    radnikOrganizacionaJedinicaList: rowRadnik.radnikOrganizacionaJedinicaList?.map((orgJedinica) => {
                      return { id: orgJedinica?.organizacionaJedinica?.id };
                    }),
                  },
                });
                dispatch({ type: "set_is_editing_radnik", data: true });
              }
            }}
          />
        )}
        {!state?.isRadnikEditing && authData?.currentRadnik?.id !== rowRadnik?.id && (
          <Button
            className="p-button-secondary"
            icon="pi pi-trash"
            rounded
            text
            onClick={() =>
              confirmDialog({
                acceptLabel: Labels.LABEL_DELETE,
                acceptIcon: "pi pi-trash",
                rejectLabel: Labels.BUTTON_CANCEL,
                rejectClassName: "p-button-outlined",
                rejectIcon: "pi pi-times",
                header: Labels.RADNIK_TITLE_DIALOG_DELETE,
                message: <span>{Labels.LABEL_INICIJALIZACIJA_RADNICI_DELETE_CONFIRM_MESSAGE}</span>,
                accept: () => onDeleteRadnik.mutate(rowRadnik.id),
              })
            }
          />
        )}
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.LABEL_IME, filter: false, sortrable: false },
    { columnName: Labels.LABEL_PREZIME, filter: false, sortrable: false },
  ];

  return (
    <div className="flex flex-column xl:flex-row p-6">
      <div className="col-12 xl:col-6 p-0">
        <div className="font-bold text-2xl mb-6 flex align-items-center">
          {Labels.LABEL_RADNIK}
          <Image src={Images.ICON_BULB} className="ml-4 mt-1 bulb" data-pr-tooltip={Labels.LABEL_INICIJALIZACIJA_RADNICI_TOOLTIP} />
          <Tooltip target=".bulb" />
        </div>
        <div className="col-12 sm:col-12 md:col-11 lg:col-11 xl:col-10 pl-0">
          <div className="grid align-items-center p-0">
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>
                {Labels.RADNIK_IME}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.radnikChange?.ime ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_radnik", data: { field: "ime", value: e.target.value } });
                }}
                onBlur={() => {
                  setInvalid(setInvalidFieldsRadnik, "ime", state?.radnikChange?.ime);
                }}
                className={invalidFieldsRadnik?.ime ? "p-invalid" : ""}
              />
              {invalidFieldsRadnik?.ime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>
                {Labels.RADNIK_PREZIME}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.radnikChange?.prezime ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_radnik", data: { field: "prezime", value: e.target.value } });
                }}
                onBlur={() => {
                  setInvalid(setInvalidFieldsRadnik, "prezime", state?.radnikChange?.prezime);
                }}
                className={invalidFieldsRadnik?.prezime ? "p-invalid" : ""}
              />
              {invalidFieldsRadnik?.prezime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>
                {Labels.RADNIK_USERNAME}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.radnikChange?.username ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_radnik", data: { field: "username", value: e.target.value } });
                }}
                onBlur={() => {
                  setInvalid(setInvalidFieldsRadnik, "username", state?.radnikChange?.username);
                }}
                className={invalidFieldsRadnik?.username ? "p-invalid" : ""}
              />
              {invalidFieldsRadnik?.username && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>
                {Labels.RADNIK_EMAIL}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.radnikChange?.email ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_radnik", data: { field: "email", value: e.target.value } });
                  setInvalidFieldsRadnik((prev) => ({ ...prev, invalidEmail: false }));
                  setInvalidFieldsRadnik((prev) => ({ ...prev, email: false }));
                }}
                onBlur={() => {
                  setInvalid(setInvalidFieldsRadnik, "email", state?.radnikChange?.email);
                }}
                className={invalidFieldsRadnik?.email || invalidFieldsRadnik?.invalidEmail ? "p-invalid" : ""}
              />
              {invalidFieldsRadnik?.email && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              {invalidFieldsRadnik?.invalidEmail && <ObaveznoPolje text={Labels.EMAIL_WRONG_FORMAT} />}
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>
                {Labels.RADNIK_POL}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              {!isLoadingPolList ? (
                <>
                  <Dropdown
                    options={polList}
                    showClear
                    value={state?.radnikChange?.polTrenutni?.sifra ?? undefined}
                    optionLabel="naziv"
                    optionValue="sifra"
                    onChange={(e) => {
                      dispatch({ type: "change_radnik", data: { field: "polTrenutni", value: e.value ? { sifra: e.value } : undefined } });
                      if (e.target.value) {
                        setInvalidFieldsRadnik((prev) => ({ ...prev, polTrenutni: false }));
                      } else {
                        setInvalidFieldsRadnik((prev) => ({ ...prev, polTrenutni: true }));
                      }
                    }}
                    emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    className={invalidFieldsRadnik?.polTrenutni ? "p-invalid" : ""}
                  />
                  {invalidFieldsRadnik?.polTrenutni && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                </>
              ) : (
                <SkeletonInputItem />
              )}
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>
                {Labels.LABEL_ULOGA}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              {!isUlogaListLoading ? (
                <>
                  <MultiSelect
                    value={state?.radnikChange?.radnikUlogaList?.map((uloga: RadnikUlogaSimpleReadDto) => uloga.id) ?? undefined}
                    options={uloge ?? []}
                    onChange={(e) => {
                      let ulogaList = e.value.map((ulogaId: number) => ({
                        id: ulogaId,
                      }));
                      dispatch({ type: "change_radnik", data: { field: "radnikUlogaList", value: ulogaList } });
                      setInvalidFieldsRadnik((prev) => ({ ...prev, radnikUlogaList: false }));
                    }}
                    className={invalidFieldsRadnik?.radnikUlogaList ? "p-invalid" : ""}
                    optionLabel="naziv"
                    optionValue="id"
                    emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                  />
                  {invalidFieldsRadnik?.radnikUlogaList && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                </>
              ) : (
                <SkeletonInputItem />
              )}
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>
                {Labels.LABEL_ORGANIZACIONA_JEDINICA}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              {!isLoadingOrganizacionaJedinicaList ? (
                <>
                  <MultiSelect
                    value={
                      state?.radnikChange?.radnikOrganizacionaJedinicaList?.map((organizacionaJedinica) => {
                        return organizacionaJedinica.id;
                      }) ?? ""
                    }
                    optionValue="id"
                    options={organizacionaJedinicaList}
                    optionLabel="naziv"
                    onChange={(e) => {
                      let organizacionaJedinicaRadnikList = e.value.map((organizacionaJedinicaId: number) => {
                        return { id: organizacionaJedinicaId };
                      });
                      dispatch({ type: "change_radnik", data: { field: "radnikOrganizacionaJedinicaList", value: organizacionaJedinicaRadnikList } });
                      setInvalidFieldsRadnik((prev) => ({ ...prev, radnikOrganizacionaJedinicaList: false }));
                    }}
                    className={invalidFieldsRadnik?.radnikOrganizacionaJedinicaList ? "p-invalid" : ""}
                  />
                  {invalidFieldsRadnik?.radnikOrganizacionaJedinicaList && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                </>
              ) : (
                <SkeletonInputItem />
              )}
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>{Labels.RADNIK_TITULA}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.radnikChange?.titula ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_radnik", data: { field: "titula", value: e.target.value } });
                }}
              />
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>{Labels.RADNIK_ZANIMANJE}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.radnikChange?.zanimanje ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_radnik", data: { field: "zanimanje", value: e.target.value } });
                }}
              />
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>{Labels.JMBG}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.radnikChange?.jmbg ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_radnik", data: { field: "jmbg", value: e.target.value } });
                }}
              />
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>{Labels.HEADER_LBO}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.radnikChange?.lbo ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_radnik", data: { field: "lbo", value: e.target.value } });
                }}
              />
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
              <div>{Labels.HEADER_PHONE}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <InputText
                value={state?.radnikChange?.telefon ?? ""}
                onChange={(e) => {
                  dispatch({ type: "change_radnik", data: { field: "telefon", value: e.target.value } });
                }}
              />
            </div>
            <div className="flex justify-content-end w-full pt-5">
              <div className="w-max">
                <Button
                  label={state?.isRadnikEditing ? Labels.BUTTON_CANCEL : Labels.LABEL_OBRISI_UNOS}
                  icon={state?.isRadnikEditing ? "pi pi-times" : "pi pi-trash"}
                  className="mr-5"
                  outlined
                  onClick={() => {
                    dispatch({ type: "set_is_editing_radnik", data: false });
                    dispatch({ type: "set_radnik", data: undefined });
                    setInvalidFieldsRadnik(undefined);
                  }}
                />

                <Button label={state?.isRadnikEditing ? Labels.BUTTON_UPDATE : Labels.LABEL_KREIRAJ} icon="pi pi-save" onClick={onSubmitRadnik} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 xl:col-6 p-0">
        <div className="font-bold text-2xl mb-6 flex align-items-center">{Labels.LABEL_KREIRANI_RADNICI}</div>
        {isLoadingInicijalizacijaRadnikList ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={false} isVisibleButtonList />
        ) : (
          <DataTable
            value={inicijalizacijaRadnikList}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            className="bg-white"
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={onPage}
          >
            <Column field="ime" header={Labels.LABEL_IME} />
            <Column field="prezime" header={Labels.LABEL_PREZIME} />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </div>
    </div>
  );
}
