import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SablonCreateDto from "../../model/sablon/SablonCreateDto";
import SablonReadDto from "../../model/sablon/SablonReadDto";

interface SablonControllerType {
  axiosGetSablonList: (poljeID: number) => Promise<AxiosResponse<any>>;
  axiosUpdateSablon: (poljeID: number, sablonChange: SablonReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteSablon: (poljeID: number, sablodID: number) => Promise<AxiosResponse<any>>;
  axiosCreateSablon: (poljeID: number, sablon: SablonCreateDto) => Promise<AxiosResponse<any>>;
}

export default function SablonController(): SablonControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetSablonList = (poljeID: number) => {
    return axios.get(`${Endpoint.FORMULAR}/polje-list/${poljeID}/sablon-list`, axiosConfig(authData!.token));
  };

  const axiosCreateSablon = (poljeID: number, sablon: SablonCreateDto) => {
    return axios.post(`${Endpoint.FORMULAR}/polje-list/${poljeID}/sablon-list`, sablon, axiosConfig(authData!.token));
  };

  const axiosUpdateSablon = (poljeID: number, sablonChange: SablonReadDto) => {
    return axios.put(`${Endpoint.FORMULAR}/polje-list/${poljeID}/sablon-list/${sablonChange.id}`, sablonChange, axiosConfig(authData!.token));
  };

  const axiosDeleteSablon = (poljeID: number, sablodID: number) => {
    return axios.delete(`${Endpoint.FORMULAR}/polje-list/${poljeID}/sablon-list/${sablodID}`, axiosConfig(authData!.token));
  };
  return { axiosCreateSablon, axiosDeleteSablon, axiosUpdateSablon, axiosGetSablonList };
}
