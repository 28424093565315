import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import ZakazanIzvestajCreateDto from "../../model/zakazaniIzvestaj/ZakazanIzvestajCreateDto";
import ZakazanIzvestajUpdateDto from "../../model/zakazaniIzvestaj/ZakazanIzvestajUpdateDto";

interface ZakazanIzvestajControllerType {
  axiosGetZakazaniIzvestajList: (params?: { radnikID?: number }) => Promise<AxiosResponse<any>>;
  axiosGetZakazaniIzvestajByID: (zakazanIzvestajID: number) => Promise<AxiosResponse<any>>;
  axiosGetZakazaniIzvestajiByStatistickiIzvestaj: (izvestajID: number, params?: { radnikID?: number }) => Promise<AxiosResponse<any>>;
  axiosCreateZakazaniIzvestaj: (izvestajID: number | undefined, izvestaj: ZakazanIzvestajCreateDto) => Promise<AxiosResponse<any>>;
  axiosEditZakazaniIzvestaj: (zakazanIzvestajID: number, izvestaj: ZakazanIzvestajUpdateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteZakazaniIzvestaj: (zakazanIzvestajID: number) => Promise<AxiosResponse<any>>;
  axiosGetZakazaniIzvestajParameters: (zakazanIzvestajID: number) => Promise<AxiosResponse<any>>;
}

export default function ZakazanIzvestajController(): ZakazanIzvestajControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetZakazaniIzvestajList = (params?: { radnikID?: number }) => {
    return axios.get(Endpoint.ZAKAZANI_IZVESTAJ_LIST, axiosConfig(authData!.token, params));
  };

  const axiosGetZakazaniIzvestajByID = (zakazanIzvestajID: number) => {
    return axios.get(`${Endpoint.ZAKAZANI_IZVESTAJ_LIST}/${zakazanIzvestajID}`, axiosConfig(authData!.token));
  };

  const axiosGetZakazaniIzvestajiByStatistickiIzvestaj = (izvestajID: number, params?: { radnikID?: number }) => {
    return axios.get(`${Endpoint.ZAKAZANI_IZVESTAJI_BY_STAT_IZVESTAJ}/${izvestajID}`, axiosConfig(authData!.token, params));
  };

  const axiosCreateZakazaniIzvestaj = (izvestajID: number | undefined, izvestaj: ZakazanIzvestajCreateDto) => {
    return axios.post(`${Endpoint.GENERISANJE_ZAKAZANOG_IZVESTAJA}/${izvestajID}`, izvestaj, axiosConfig(authData!.token));
  };

  const axiosEditZakazaniIzvestaj = (zakazanIzvestajID: number, izvestaj: ZakazanIzvestajUpdateDto) => {
    return axios.put(`${Endpoint.ZAKAZANI_IZVESTAJ_LIST}/${zakazanIzvestajID}`, izvestaj, axiosConfig(authData!.token));
  };

  const axiosDeleteZakazaniIzvestaj = (zakazanIzvestajID: number) => {
    return axios.delete(`${Endpoint.ZAKAZANI_IZVESTAJ_LIST}/${zakazanIzvestajID}`, axiosConfig(authData!.token));
  };

  const axiosGetZakazaniIzvestajParameters = (zakazanIzvestajID: number) => {
    return axios.get(`${Endpoint.ZAKAZANI_IZVESTAJ_PARAMETAR_LIST}/${zakazanIzvestajID}`, axiosConfig(authData!.token));
  };

  return {
    axiosGetZakazaniIzvestajList,
    axiosGetZakazaniIzvestajByID,
    axiosGetZakazaniIzvestajiByStatistickiIzvestaj,
    axiosCreateZakazaniIzvestaj,
    axiosEditZakazaniIzvestaj,
    axiosDeleteZakazaniIzvestaj,
    axiosGetZakazaniIzvestajParameters,
  };
}
