const EnumPravaPristupa = {
  PACIJENT_CREATE: "PACIJENT:CREATE",
  PACIJENT_DELETE: "PACIJENT:DELETE",
  PACIJENT_READ: "PACIJENT:READ",
  PACIJENT_UPDATE: "PACIJENT:UPDATE",
  PACIJENT_VIEW: "PACIJENT:VIEW",
  ZAKAZAN_TERMIN_CREATE: "ZAKAZAN_TERMIN:CREATE",
  ZAKAZAN_TERMIN_DELETE: "ZAKAZAN_TERMIN:DELETE",
  ZAKAZAN_TERMIN_READ: "ZAKAZAN_TERMIN:READ",
  ZAKAZAN_TERMIN_UPDATE: "ZAKAZAN_TERMIN:UPDATE",
  ZAKAZAN_TERMIN_VIEW: "ZAKAZAN_TERMIN:VIEW",
  KONTAKT_USLUGA_CREATE: "KONTAKT_USLUGA:CREATE",
  KONTAKT_USLUGA_DELETE: "KONTAKT_USLUGA:DELETE",
  KONTAKT_USLUGA_READ: "KONTAKT_USLUGA:READ",
  KONTAKT_USLUGA_UPDATE: "KONTAKT_USLUGA:UPDATE",
  KONTAKT_USLUGA_VIEW: "KONTAKT_USLUGA:VIEW",
  KONTAKT_PROIZVOD_CREATE: "KONTAKT_PROIZVOD:CREATE",
  KONTAKT_PROIZVOD_DELETE: "KONTAKT_PROIZVOD:DELETE",
  KONTAKT_PROIZVOD_READ: "KONTAKT_PROIZVOD:READ",
  KONTAKT_PROIZVOD_UPDATE: "KONTAKT_PROIZVOD:UPDATE",
  KONTAKT_PROIZVOD_VIEW: "KONTAKT_PROIZVOD:VIEW",
  KONTAKT_DIJAGNOZA_CREATE: "KONTAKT_DIJAGNOZA:CREATE",
  KONTAKT_DIJAGNOZA_DELETE: "KONTAKT_DIJAGNOZA:DELETE",
  KONTAKT_DIJAGNOZA_READ: "KONTAKT_DIJAGNOZA:READ",
  KONTAKT_DIJAGNOZA_UPDATE: "KONTAKT_DIJAGNOZA:UPDATE",
  KONTAKT_DIJAGNOZA_VIEW: "KONTAKT_DIJAGNOZA:VIEW",
  KONTAKT_PAKET_CREATE: "KONTAKT_PAKET:CREATE",
  KONTAKT_PAKET_DELETE: "KONTAKT_PAKET:DELETE",
  KONTAKT_PAKET_READ: "KONTAKT_PAKET:READ",
  KONTAKT_PAKET_UPDATE: "KONTAKT_PAKET:UPDATE",
  KONTAKT_PAKET_VIEW: "KONTAKT_PAKET:VIEW",
  KONTAKT_CREATE: "KONTAKT:CREATE",
  KONTAKT_DELETE: "KONTAKT:DELETE",
  KONTAKT_READ: "KONTAKT:READ",
  KONTAKT_UPDATE: "KONTAKT:UPDATE",
  KONTAKT_VIEW: "KONTAKT:VIEW",
  FORMULAR_POPUNJEN_CREATE: "FORMULAR_POPUNJEN:CREATE",
  FORMULAR_POPUNJEN_DELETE: "FORMULAR_POPUNJEN:DELETE",
  FORMULAR_POPUNJEN_READ: "FORMULAR_POPUNJEN:READ",
  FORMULAR_POPUNJEN_UPDATE: "FORMULAR_POPUNJEN:UPDATE",
  FORMULAR_POPUNJEN_VIEW: "FORMULAR_POPUNJEN:VIEW",
  RECEPT_CREATE: "RECEPT:CREATE",
  RECEPT_DELETE: "RECEPT:DELETE",
  RECEPT_READ: "RECEPT:READ",
  RECEPT_UPDATE: "RECEPT:UPDATE",
  RECEPT_VIEW: "RECEPT:VIEW",
};

export default EnumPravaPristupa;
