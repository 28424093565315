import { Dropdown } from "primereact/dropdown";
import { AppContext, useLabels } from "../../../Store";
import { selectOrganizacionaJedinica } from "../../../infrastructure/system/Utils";
import OrganizacionaJedinicaCvorDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaCvorDto";
import { useContext } from "react";

interface OrganizacionaJedinicaType {
  id: number;
  organizacionaJedinica: OrganizacionaJedinicaCvorDto;
  vrednosti: Array<OrganizacionaJedinicaCvorDto>;
  chevronRight: JSX.Element;
}

export default function OrganizacionaJedinica(props: OrganizacionaJedinicaType) {
  const { id, organizacionaJedinica, vrednosti, chevronRight } = props;
  const { updateOdabranaOrganizacionaJedinicaList } = useContext(AppContext);
  const Labels = useLabels();

  return (
    <>
      {chevronRight}
      <Dropdown
        options={organizacionaJedinica.podredjeneOrganizacioneJedinice}
        resetFilterOnHide
        filter
        value={vrednosti ? vrednosti[id] : undefined}
        optionLabel="organizacionaJedinica.naziv"
        onChange={(e) => {
          updateOdabranaOrganizacionaJedinicaList(selectOrganizacionaJedinica(vrednosti, e.value, id));
        }}
        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
      />
      {vrednosti && vrednosti[id]?.podredjeneOrganizacioneJedinice.length > 0 && (
        <OrganizacionaJedinica chevronRight={chevronRight} id={id + 1} organizacionaJedinica={vrednosti[id]} vrednosti={vrednosti} />
      )}
    </>
  );
}
