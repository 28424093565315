import moment from "moment";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useRef } from "react";
import { useLabels } from "../../../Store";
import { DATE_FORMAT_2 } from "../../../infrastructure/system/Utils";
import CalendarComponent from "../calendar-component/CalendarComponent";

interface ObavestenjaFiltersPropsType {
  filters: { obavestenjeTipSifra?: string; datum?: Date | string; searchString?: string };
  setFilters: React.Dispatch<React.SetStateAction<{ obavestenjeTipSifra?: string; datum?: Date | string; searchString?: string }>>;
  obavestenjeTypeList: any[];
  poslato?: boolean;
}

const ObavestenjaFilters = (props: ObavestenjaFiltersPropsType) => {
  const { filters, setFilters, obavestenjeTypeList, poslato } = props;
  const Labels = useLabels();
  const datumRef = useRef<Calendar>(null);

  return (
    <div className={`flex flex-row flex-wrap justify-content-between pl-3 col-12 sm:col-9 w-12 ${poslato ? "" : "sm:w-10 md:w-8 lg:w-8 xl:w-6"}`}>
      <div className={`flex flex-row flex-wrap justify-content-between pr-2 ${poslato ? "w-11" : "w-9"}`}>
        <div className="filter flex flex-column align-items-start my-2 mr-1">
          <Dropdown
            optionLabel="naziv"
            optionValue="sifra"
            options={obavestenjeTypeList}
            value={filters?.obavestenjeTipSifra}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, obavestenjeTipSifra: e.value }));
            }}
            emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
            className="w-full"
            placeholder={Labels.LABEL_VRSTA_OBAVESTENJA}
            showClear
          />
        </div>
        <div className="filter flex flex-column align-items-start my-2 mr-1">
          <CalendarComponent
            ref={datumRef}
            value={filters?.datum}
            onChange={(e: CalendarChangeEvent) => {
              if (e.value as Date) {
                setFilters((prev) => ({ ...prev, datum: moment(e.value as Date).format(DATE_FORMAT_2) }));
                if (e.value) datumRef?.current?.hide();
              }
            }}
            placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
            onClearButtonClick={() => setFilters({ ...filters, datum: undefined })}
          />
        </div>
        <div className="filter flex flex-column align-items-start my-2 ml-1">
          <span className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              id="searchString"
              name="searchString"
              value={filters?.searchString ?? ""}
              onChange={(e) => setFilters((prev) => ({ ...prev, searchString: e.target.value }))}
              className="w-full"
              placeholder={Labels.LABEL_TEKST_OBAVESTENJA}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ObavestenjaFilters;
