import { CalendarChangeEvent } from "primereact/calendar";
import { useRef } from "react";
import { useLabels } from "../../../../Store";
import { CALENDAR_DATE_FORMAT, formatDate, isDateTimeFormatValid } from "../../../../infrastructure/system/Utils";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import { PoljeType } from "../Polje";

export default function DatumskoVremenski(props: PoljeType) {
  const { id, onChange, vrednost, readonly, format, vrednostList, useVrednostList } = props;
  const calendarRef = useRef<HTMLDivElement | any>(null);
  const Labels = useLabels();

  return (
    <CalendarComponent
      ref={calendarRef}
      disabled={readonly ?? false}
      name="vrednost"
      className="w-full"
      value={useVrednostList && vrednostList ? vrednostList[id] : vrednost}
      dateFormat={format ?? CALENDAR_DATE_FORMAT}
      showTime
      onChange={(e: CalendarChangeEvent) => onChange({ id: id, vrednost: formatDate(e.value as Date | undefined) })}
      onInput={(e) => {
        if (isDateTimeFormatValid(e.currentTarget.value)) calendarRef?.current?.hide();
      }}
      placeholder={Labels.PLACEHOLDER_DATUM_VREME_FORMAT}
    />
  );
}
