import { ScrollPanel } from "primereact/scrollpanel";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLabels } from "../../../Store";
import GrupnoObavestenjeSimpleReadDto from "../../../model/obavestenja-pacijenti/GrupnoObavestenjeSimpleReadDto";
import SkeletonGrupnoObavestenjeListItem from "../../app/skeleton/SkeletonGrupnoObavestenjeListItem";
import ObavestenjaFilters from "./ObavestenjaFilters";
import ObavestenjeItem from "./ObavestenjeItem";

const ObavestenjaPoslataTab = (props: any) => {
  const {
    obavestenjePoslatoFilters,
    setObavestenjePoslatoFilters,
    state,
    dispatch,
    obavestenjaPoslatoListLoading,
    obavestenjaPoslatoList,
    fetchObavestenjaPoslata,
    hasMoreObavestenjaPoslato,
    onDelete,
    fetchObavestenje,
    fetchObavestenjePreSlanja,
    scrollPanelRef,
  } = props;
  const Labels = useLabels();

  document.querySelector(".notification-list.notification-list-sent .p-scrollpanel-wrapper .p-scrollpanel-content")?.setAttribute("id", "notificationListSent");

  return (
    <div className="flex flex-column align-items-center">
      <div className="w-12 sm:w-10 md:w-8 lg:w-8 xl:w-5">
        <ObavestenjaFilters filters={obavestenjePoslatoFilters} setFilters={setObavestenjePoslatoFilters} obavestenjeTypeList={state.obavestenjeTypeList} poslato />
        <ScrollPanel className="notification-list notification-list-sent w-11" ref={scrollPanelRef}>
          {obavestenjaPoslatoListLoading ? (
            <SkeletonGrupnoObavestenjeListItem size={5} />
          ) : obavestenjaPoslatoList?.length === 0 ? (
            <div className="flex align-items-center justify-content-center h-full">{Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE_EMPTY_DATA}</div>
          ) : (
            <InfiniteScroll
              dataLength={obavestenjaPoslatoList.length}
              next={fetchObavestenjaPoslata}
              hasMore={hasMoreObavestenjaPoslato.current}
              loader={<SkeletonGrupnoObavestenjeListItem />}
              scrollableTarget="notificationListSent"
              scrollThreshold={1}
              className="overflow-hidden"
            >
              {obavestenjaPoslatoList?.map((obavestenje: GrupnoObavestenjeSimpleReadDto) => (
                <ObavestenjeItem
                  key={obavestenje.id}
                  obavestenje={obavestenje}
                  state={state}
                  dispatch={dispatch}
                  onDelete={onDelete}
                  fetchObavestenje={fetchObavestenje}
                  fetchObavestenjePreSlanja={fetchObavestenjePreSlanja}
                />
              ))}
            </InfiniteScroll>
          )}
        </ScrollPanel>
      </div>
    </div>
  );
};

export default ObavestenjaPoslataTab;
