import axios from "axios";
import moment from "moment";
import { CalendarChangeEvent } from "primereact/calendar";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { useContext, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { LogController } from "../../../controllers/log/LogController";
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_3, formatDate, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import LogWebServiceCallReadDto from "../../../model/log/LogWebServiceCallReadDto";
import LogWebServiceCallSearchDto from "../../../model/log/LogWebServiceCallSearchDto";
import LogWebServiceCallShortReadDto from "../../../model/log/LogWebServiceCallShortReadDto";
interface LogListLogicalType {
  showResults: boolean;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTableColumns: Array<any>;
  setSelectedtableColumns: React.Dispatch<React.SetStateAction<Array<any>>>;
  tableColumns: Array<any>;
  onColumnToggle: (event: MultiSelectChangeEvent) => void;
  first: number;
  tableRows: number;
  onPageFormTable: (rows: number, first: number, page?: number) => void;
  logList: Array<LogWebServiceCallShortReadDto>;
  searchLog: (searchObject: LogWebServiceCallSearchDto) => void;
  searchLogObject: LogWebServiceCallSearchDto;
  setSearchLogObject: (pacijentSearch: LogWebServiceCallSearchDto) => void;
  onChangeCalendar: (e: CalendarChangeEvent) => void;
  searchLogLoading: boolean;
  searchLogPageReady: boolean;
  totalRecords: number;
  readLog: (id: number) => void;
  closeLogDialog: () => void;
  log: LogWebServiceCallReadDto;
  setFirst: React.Dispatch<React.SetStateAction<number>>;
  datumOdRef: HTMLDivElement | any;
  datumDoRef: HTMLDivElement | any;
}

export default function LogListLogical(): LogListLogicalType {
  const Labels = useLabels();
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [selectedTableColumns, setSelectedtableColumns] = useState<Array<any>>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const { axiosSearchLogList, axiosCountSearchLogList, axiosReadLog } = LogController();
  const [logList, setLogList] = useState<Array<LogWebServiceCallShortReadDto>>([]);
  const [log, setLog] = useState<LogWebServiceCallReadDto>({});
  const [searchLogObject, setSearchLogObject] = useState<LogWebServiceCallSearchDto>({});
  const [searchLogLoading, setSearchLogLoading] = useState<boolean>(false);
  const [searchLogPageReady] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const datumOdRef = useRef<HTMLDivElement | any>(null);
  const datumDoRef = useRef<HTMLDivElement | any>(null);

  const { showMessage } = useContext(AppContext);
  const tableColumns: any = [
    { field: "requestTimestamp", header: Labels.LOG_REQUEST_TIMESTAMP },
    { field: "requestClientIp", header: Labels.LOG_REQUEST_CLIENT_IP },
    { field: "requestMethod", header: Labels.LOG_REQUEST_METHOD },
    { field: "requestWsEndpoint", header: Labels.LOG_REQUEST_WS_ENDPOINT },
    { field: "responseTimestamp", header: Labels.LOG_RESPONSE_TIMESTAMP },
  ];
  const parseLog = (logList: Array<LogWebServiceCallShortReadDto>) => {
    let newLogList: Array<LogWebServiceCallShortReadDto> = [];
    logList.forEach((log: LogWebServiceCallShortReadDto) => {
      let logObj = {
        ...log,
        metadataHostname: log?.metadataHostname,
        requestClientIp: log?.requestClientIp,
        requestMethod: log?.requestMethod,
        requestWsEndpoint: log?.requestWsEndpoint,
        requestTimestamp: log?.requestTimestamp ? moment(log?.requestTimestamp).format(DATE_TIME_FORMAT) : Labels.SLASH,
        responseTimestamp: log?.responseTimestamp ? moment(log?.responseTimestamp).format(DATE_TIME_FORMAT) : Labels.SLASH,
      };
      newLogList.push(logObj);
    });
    return newLogList;
  };
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns: any = tableColumns.filter((col: any) => selectedColumns?.some((sCol: any) => sCol.field === col.field));
    setSelectedtableColumns(orderedSelectedColumns);
  };

  const searchLog = (searchObject: LogWebServiceCallSearchDto) => {
    let startTime = moment(new Date());
    setSearchLogLoading(true);
    const requestSearchLogList = axiosSearchLogList(searchObject);
    const requestCountSearchLogList = axiosCountSearchLogList({
      startTime: searchObject.startTime,
      endTime: searchObject.endTime,
      requestMethod: searchObject.requestMethod,
      statusCode: searchLogObject.statusCode,
    });
    axios
      .all([requestSearchLogList, requestCountSearchLogList])
      .then(
        axios.spread((responseSearchLogList: any, responseCountSearchLogList: any) => {
          setTotalRecords(responseCountSearchLogList.data.data);
          setLogList(parseLog(responseSearchLogList.data.data));
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSearchLogLoading, startTime);
      });
  };

  useEffectOnce(() => {
    setSelectedtableColumns(tableColumns);
  });
  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const onChangeCalendar = (e: CalendarChangeEvent) => setSearchLogObject({ ...searchLogObject, [e.target.name]: formatDate(e.value as Date | undefined, DATE_TIME_FORMAT_3) });

  const readLog = (id: number) => {
    axiosReadLog(id)
      .then((res: any) => {
        setLog(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const closeLogDialog = () => {
    setLog({});
  };

  return {
    showResults,
    setShowResults,
    selectedTableColumns,
    setSelectedtableColumns,
    tableColumns,
    onColumnToggle,
    first,
    tableRows,
    onPageFormTable,
    logList,
    searchLog,
    searchLogObject,
    setSearchLogObject,
    onChangeCalendar,
    searchLogLoading,
    searchLogPageReady,
    totalRecords,
    readLog,
    closeLogDialog,
    log,
    setFirst,
    datumOdRef,
    datumDoRef,
  };
}
