import { Accordion, AccordionTab, AccordionTabChangeEvent } from "primereact/accordion";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { useLabels } from "../../../Store";
import { DATE_TIME_FORMAT_FULL, formatDate } from "../../../infrastructure/system/Utils";
import FormularPopunjenSaPoljimaDto from "../../../model/formular/FormularPopunjenSaPoljimaDto";
import PoljeReadDto from "../../../model/polje/PoljeReadDto";
import { valueMap } from "../FormularLogical";
import PrikazFormulara from "../PrikazFormulara";
import PrethodniNalaziListLogical from "./PrethodniNalaziListLogical";
interface PrethodniNalaziListProp {
  formularId: number;
  formularPopunjenId?: number;
  pacijentId: number;
  poljeList: Array<PoljeReadDto>;
  changePoljaVrednosti: (poljaVrednosti: valueMap) => void;
}
export default function PrethodniNalaziList(props: PrethodniNalaziListProp) {
  const { poljeList, formularId, pacijentId, changePoljaVrednosti, formularPopunjenId } = props;
  const Labels = useLabels();
  const { dataLoading, activeIndex, setActiveIndex, formularPopunjenList, onChange, formularPopunjenVrednostList, noMoreData, moreDataLoading, loadMore } = PrethodniNalaziListLogical({
    formularId,
    formularPopunjenId,
    pacijentId,
    changePoljaVrednosti,
  });
  const prethodniNalaziAccordionHeader = (element: FormularPopunjenSaPoljimaDto) => (
    <div className="flex flex-column">
      <div className="flex font-bold align-items-center text-center w-full">
        {Labels.LEKAR}: {element.kontakt?.primarniDoktor ? element.kontakt.primarniDoktor.ime + " " + element.kontakt.primarniDoktor.prezime : Labels.SLASH}{" "}
        {formatDate(element.vremeKreiranja, DATE_TIME_FORMAT_FULL)}-{element.kontakt.organizacionaJedinica.naziv}
      </div>
      <div className="flex align-items-center justify-content-center text-center w-full">
        {Labels.VREME_POSLEDNJE_IZMENE}:{formatDate(element.vremePoslednjeIzmene, DATE_TIME_FORMAT_FULL) ?? Labels.SLASH}
      </div>
    </div>
  );

  return (
    <div>
      {!dataLoading && (
        <>
          <Accordion activeIndex={activeIndex} className="mb-3" onTabChange={(e: AccordionTabChangeEvent) => setActiveIndex(e.index)}>
            {formularPopunjenList?.map((element: FormularPopunjenSaPoljimaDto, index: number) => {
              return (
                <AccordionTab headerTemplate={() => prethodniNalaziAccordionHeader(element)} key={index}>
                  <div>
                    <div className="flex w-full justify-content-between">
                      <div className="flex font-bold align-self-center">{Labels.KONTAKT_NALAZ}:</div>
                      <div className="flex">
                        <Button
                          label={Labels.BUTTON_OVERWRITE}
                          className="p-button-outlined border-none p-button-success button-hover-ignore p-2"
                          icon="pi pi-copy"
                          onClick={() => onChange(formularPopunjenVrednostList[index])}
                        />
                      </div>
                    </div>
                    <span>
                      <PrikazFormulara poljeVrednostList={formularPopunjenVrednostList[index]} poljeList={poljeList} />
                    </span>
                  </div>
                </AccordionTab>
              );
            })}
          </Accordion>
          {formularPopunjenList.length === 0 && <div>{Labels.PRETHODNI_NALAZI_NOT_FOUND}</div>}
        </>
      )}
      {!noMoreData && !dataLoading && (
        <div className="flex w-full justify-content-center">
          {moreDataLoading && (
            <div className="mt-1 flex align-items-center">
              <ProgressSpinner className="w-3rem h-3rem" />
            </div>
          )}
          {!moreDataLoading && (
            <div className="flex">
              <Button label={Labels.BUTTON_LOAD_MORE} className="p-button-outlined border-none p-button-success button-hover-ignore" onClick={loadMore} />
            </div>
          )}
        </div>
      )}
      {dataLoading && (
        <div className="w-full h-full flex align-items-center">
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
}
