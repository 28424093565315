import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { ChangeEvent } from "react";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";
import CrudEksterniSistemLogical from "./CrudEksterniSistemLogical";

export default function CrudEksterniSistemView() {
  const {
    breadCrumbItems,
    eksterniSistemChange,
    setEksterniSistemChange,
    isDisabled,
    eksterniSistemOperation,
    onCreate,
    onUpdate,
    onCancel,
    eksterniSistemLoading,
    sifarnikEksterniSistemList,
    onDelete,
    invalidFields,
    setInvalidFields,
    sifarnikEksternihSistemaListLoading,
  } = CrudEksterniSistemLogical();
  const Labels = useLabels();

  const leftContentsToolbar = () => (
    <>
      {eksterniSistemOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {eksterniSistemOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={eksterniSistemLoading}
          icon="pi pi-pencil"
          tooltip={Labels.DODATNI_MODUL_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {eksterniSistemOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={eksterniSistemLoading}
          icon="pi pi-trash"
          tooltip={Labels.DODATNI_MODUL_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
          <TabPanel header={Labels.EKSTERNI_SISTEM}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.RADNIK_USERNAME}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!eksterniSistemLoading ? (
                    <>
                      <InputText
                        disabled={isDisabled}
                        value={eksterniSistemChange?.username ?? ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setEksterniSistemChange({
                            ...eksterniSistemChange!,
                            username: e.target.value,
                          });
                          setInvalidFields((prev: any) => ({ ...prev, username: false }));
                        }}
                        className={invalidFields?.username && "p-invalid"}
                      />
                      {invalidFields?.username && <ObaveznoPolje text={Labels.USTANOVA_EKSTERNI_SISTEM_USERNAME_IS_REQUIRED} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.RADNIK_PASSWORD}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!eksterniSistemLoading ? (
                    <>
                      <InputText
                        disabled={isDisabled}
                        value={eksterniSistemChange?.password ?? ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setEksterniSistemChange({
                            ...eksterniSistemChange!,
                            password: e.target.value,
                          });
                          setInvalidFields((prev: any) => ({ ...prev, password: false }));
                        }}
                        className={invalidFields?.password && "p-invalid"}
                      />
                      {invalidFields?.password && <ObaveznoPolje text={Labels.USTANOVA_EKSTERNI_SISTEM_PASSWORD_IS_REQUIRED} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.DODATNI_MODUL_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!eksterniSistemLoading || sifarnikEksternihSistemaListLoading ? (
                    <>
                      <Dropdown
                        className={"w-full " + (invalidFields?.eksterniSistem && "p-invalid")}
                        options={sifarnikEksterniSistemList}
                        disabled={isDisabled || eksterniSistemOperation !== EntityOperation.CREATE}
                        optionLabel="naziv"
                        optionValue="id"
                        value={eksterniSistemChange?.idRepositorySifarnikEksterniSistem ? Number(eksterniSistemChange?.idRepositorySifarnikEksterniSistem) : undefined}
                        onChange={(e: DropdownChangeEvent) => {
                          setEksterniSistemChange({
                            ...eksterniSistemChange!,
                            idRepositorySifarnikEksterniSistem: sifarnikEksterniSistemList.filter((eksterniSistem) => eksterniSistem.id === e.target.value)[0].id,
                          });
                          setInvalidFields((prev: any) => ({ ...prev, eksterniSistem: false }));
                        }}
                      />
                      {invalidFields?.eksterniSistem && <ObaveznoPolje text={Labels.USTANOVA_EKSTERNI_SISTEM_EKSTERNI_SISTEM_IS_REQUIRED} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} className="flex" />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
