import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import LogHighLevelCreateDto from "../../model/log/LogHighLevelCreateDto";
import LogWebServiceCallSearchDto from "../../model/log/LogWebServiceCallSearchDto";

interface LogControllerType {
  axiosSearchLogList: (searchData: LogWebServiceCallSearchDto) => Promise<AxiosResponse<any>>;
  axiosCountSearchLogList: (searchData: LogWebServiceCallSearchDto) => Promise<AxiosResponse<any>>;
  axiosReadLog: (id: number) => Promise<AxiosResponse<any>>;
  axiosPostLogHighLevel: (logHighLevel: LogHighLevelCreateDto) => Promise<AxiosResponse<any>>;
}

export function LogController(): LogControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchLogList = (searchData: LogWebServiceCallSearchDto) => {
    return axios.get(Endpoint.LOG_WEB_SERVICE_CALL_SEARCH, axiosConfig(authData!.token, searchData));
  };

  const axiosCountSearchLogList = (searchData: LogWebServiceCallSearchDto) => {
    return axios.get(Endpoint.LOG_WEB_SERVICE_CALL_COUNT_SEARCH, axiosConfig(authData!.token, searchData));
  };

  const axiosReadLog = (id: number) => {
    return axios.get(`${Endpoint.LOG_WEB_SERVICE_CALL_LIST}/${id}`, axiosConfig(authData!.token));
  };

  const axiosPostLogHighLevel = (logHighLevel: LogHighLevelCreateDto) => {
    return axios.post(Endpoint.LOG_HIGH_LEVEL_LIST, logHighLevel, axiosConfig(authData!.token));
  };

  return {
    axiosSearchLogList,
    axiosCountSearchLogList,
    axiosReadLog,
    axiosPostLogHighLevel,
  };
}
