import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { VerzijaController } from "../../../../controllers/verzija/VerzijaController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";
import { DATE_TIME_FORMAT_4, handleAxiosCallError, handleAxiosCallSuccess } from "../../../../infrastructure/system/Utils";
import VerzijaUpdateDto from "../../../../model/verzija/VerzijaUpdateDto";

export function useUpdateVerzija() {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosUpdateVerzija } = VerzijaController();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (verzija: VerzijaUpdateDto) => {
      const verzijaUpdate = { ...verzija, datum: moment(verzija.datum).format(DATE_TIME_FORMAT_4) };
      return axiosUpdateVerzija(verzijaUpdate);
    },
    onSuccess: () => {
      handleAxiosCallSuccess(showMessage, Labels.VERZIJA_UPDATE_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.VERZIJA_LIST] });
    },
    onError: (err) => {
      handleAxiosCallError(showMessage, err);
    },
  });
}
