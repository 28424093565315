import { FileUploadHandlerEvent } from "primereact/fileupload";
import { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PacijentController } from "../../../controllers/pacijent/PacijentController";
import MessageType from "../../../infrastructure/system/MessageType";
import { MAX_FILE_UPLOAD, handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import DokumentSmrtPacijentaDto from "../../../model/pacijent/DokumentPacijentaDto";
import VremeSmrtiDto from "../../../model/pacijent/VremeSmrtiDto";

import { AxiosResponse } from "axios";
import { AppContext, useLabels } from "../../../Store";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";

interface PodaciSmrtiLogicalType {
  vremeSmrti?: VremeSmrtiDto;
  setVremeSmrti: any;
  savePodaciSmrti: () => void;
  deletePodaciSmrti: () => void;
  documentUploadHandler: (event: FileUploadHandlerEvent) => void;
  onRemoveDocument: (documentUploadRef: React.MutableRefObject<any>) => void;
  dokumentSmrtPacijenta?: DokumentSmrtPacijentaDto;
  calendarRef: HTMLDivElement | any;
}

interface UseParamsType {
  id: string;
}

interface PodaciSmrtiType {
  pacijentVremeSmrti?: string;
  setPacijent?: React.Dispatch<React.SetStateAction<PacijentReadDto | undefined>>;
}

export default function PodaciSmrtiLogical(props: PodaciSmrtiType): PodaciSmrtiLogicalType {
  const { pacijentVremeSmrti, setPacijent } = props;
  const [vremeSmrti, setVremeSmrti] = useState<VremeSmrtiDto>({ vremeSmrti: pacijentVremeSmrti ? pacijentVremeSmrti : null });
  const [dokumentSmrtPacijenta, setDokumentSmrtPacijenta] = useState<DokumentSmrtPacijentaDto | undefined>();
  const calendarRef = useRef<HTMLDivElement | any>(null);

  const { id } = useParams<keyof UseParamsType>();
  const { axiosSavePacijentVremeSmrti, axiosGetDokumentSmrtPacijenta, axiosDeleteDokumentSmrtPacijenta, axiosCreateDokumentSmrtPacijenta } = PacijentController();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const [dokumentSmrtPacijentaID, setDokumentSmrtPacijentaID] = useState<number | undefined>();
  const onRemoveDocument = (documentUploadRef: React.MutableRefObject<any>) => {
    setDokumentSmrtPacijenta(undefined);

    documentUploadRef.current.clear();
  };
  useEffectOnce(() => {
    axiosGetDokumentSmrtPacijenta(Number(id))
      .then((response: any) => {
        if (response.data.data.length > 0) {
          setDokumentSmrtPacijenta(response.data.data[0]);
          setDokumentSmrtPacijentaID(response.data.data[0].id);
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  });
  const savePodaciSmrti = async () => {
    setShowBlockUI(true);
    if (dokumentSmrtPacijentaID && dokumentSmrtPacijenta?.id === undefined) {
      await axiosDeleteDokumentSmrtPacijenta(dokumentSmrtPacijentaID)
        .then(() => {})
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
      if (dokumentSmrtPacijenta) {
        await axiosCreateDokumentSmrtPacijenta(dokumentSmrtPacijenta)
          .then((response: any) => {
            setDokumentSmrtPacijenta(response.data.data);
            setDokumentSmrtPacijentaID(response.data.data?.id);
          })
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
          });
      } else {
        setDokumentSmrtPacijenta(undefined);
        setDokumentSmrtPacijentaID(undefined);
      }
    } else if (dokumentSmrtPacijentaID === undefined && dokumentSmrtPacijenta) {
      await axiosCreateDokumentSmrtPacijenta(dokumentSmrtPacijenta)
        .then((response: any) => {
          setDokumentSmrtPacijenta(response.data.data);
          setDokumentSmrtPacijentaID(response.data.data?.id);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    axiosSavePacijentVremeSmrti(Number(id), vremeSmrti)
      .then((response: AxiosResponse) => {
        if (setPacijent) setPacijent(response.data.data);
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_SAVE_VREME_SMRTI_SUCCESS);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const deletePodaciSmrti = () => {
    setShowBlockUI(true);
    if (dokumentSmrtPacijentaID) {
      axiosDeleteDokumentSmrtPacijenta(dokumentSmrtPacijentaID)
        .then(() => {
          setDokumentSmrtPacijenta(undefined);
          setDokumentSmrtPacijentaID(undefined);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    axiosSavePacijentVremeSmrti(Number(id), { vremeSmrti: null })
      .then((response: AxiosResponse) => {
        if (setPacijent) setPacijent(response.data.data);
        setVremeSmrti({ vremeSmrti: null });
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_DELETE_VREME_SMRTI_SUCCESS);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const documentUploadHandler = (e: FileUploadHandlerEvent) => {
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }

        if (reader.result) {
          setDokumentSmrtPacijenta({
            sadrzaj: reader.result.toString().substr(reader.result?.toString().indexOf(",") + 1),
            naziv: file.name,
            velicina: file.size,
            tip: file.type,
            pacijentID: Number(id),
          });
        }
      };
    });
  };
  return { vremeSmrti, setVremeSmrti, savePodaciSmrti, deletePodaciSmrti, documentUploadHandler, onRemoveDocument, dokumentSmrtPacijenta, calendarRef };
}
