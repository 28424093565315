import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";

interface TerminControllerType {
  axiosGetDocumentFromScheduler: (zakazaniTerminID: number, eksterniZakazanTerminDatotekaID: number) => Promise<AxiosResponse<any, any>>;
}

export default function TerminController(): TerminControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetDocumentFromScheduler = (zakazaniTerminID: number, eksterniZakazanTerminDatotekaID: number) => {
    return axios.get(`${Endpoint.ZAKAZAN_TERMIN}/${zakazaniTerminID}/nadjimed-documents/${eksterniZakazanTerminDatotekaID}`, axiosConfig(authData!.token));
  };

  return { axiosGetDocumentFromScheduler };
}
