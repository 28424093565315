import axios, { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { KontaktController } from "../../../../controllers/kontakt/KontaktController";
import { PaketController } from "../../../../controllers/paket/PaketController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../infrastructure/system/MessageType";
import { RETRY_WITH_RESPONSE_CODE, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import actions from "../../../../infrastructure/system/hooks/dialog-dodavanje-paketa-reducer/actionsKontaktPaketDialog";
import initialState, { InitialStateKontaktPaketDialogType } from "../../../../infrastructure/system/hooks/dialog-dodavanje-paketa-reducer/initialStateKontaktPaketDialog";
import reducerKontaktPaketDialog from "../../../../infrastructure/system/hooks/dialog-dodavanje-paketa-reducer/reducerKontaktPaketDialog";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import KontaktPaketCreateDto from "../../../../model/kontakt/KontaktPaketCreateDto";
import KontaktPaketReadDto from "../../../../model/kontakt/KontaktPaketReadDto";
import KontaktPaketUpdateDto from "../../../../model/kontakt/KontaktPaketUpdateDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";

interface UseParamsType {
  id?: string;
}

interface KontaktPaketLogicalType {
  dataLoading: boolean;
  state: InitialStateKontaktPaketDialogType;
  dispatch: React.Dispatch<{ type: actions }>;
  openDialog: (action: string, data: KontaktPaketReadDto | any) => void;
  closeDialog: () => void;
  loadUslugeProizvodi: (paketId: number) => void;
  kontaktPaketChange: KontaktPaketCreateDto | undefined;
  setKontaktPaketChange: React.SetStateAction<any>;
  onCreate: (unsafelyExecute?: boolean) => void;
  onDelete: (kontaktPaket: KontaktPaketReadDto) => void;
  dialogAction: string;
  kontaktPaket: KontaktPaketReadDto | undefined;
  setKontaktPaket: React.SetStateAction<any>;
  onUpdate: (unsafelyExecute?: boolean) => void;
  isOpenConfirmationDialog: boolean;
  closeConfirmationDialog: () => void;
}

export default function KontaktPaketLogical(
  pacijent: PacijentReadDto,
  kontaktPaketList: Array<KontaktPaketReadDto> | undefined,
  setKontaktPaketList: React.Dispatch<React.SetStateAction<Array<KontaktPaketReadDto> | undefined>>
): KontaktPaketLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const id = Number(useParams<keyof UseParamsType>()["id"]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducerKontaktPaketDialog, initialState);
  const [kontaktPaketChange, setKontaktPaketChange] = useState<KontaktPaketCreateDto>();
  const [dialogAction, setDialogAction] = useState<string>("");
  const [kontaktPaket, setKontaktPaket] = useState<KontaktPaketReadDto>();

  const { axiosGetPaketListByKontaktId, axiosPostKontaktPaket, axiosDeletePaketFromKontakt, axiosUpdatePaketFromKontakt } = KontaktController();
  const { axiosGetPaketList, axiosGetUslugeListByPaketId, axiosGetProizvodiListByPaketId } = PaketController();

  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState<boolean>(false);

  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    if (!kontaktPaketList) {
      fetchData();
    }
  });

  const fetchData = () => {
    if (id) {
      let startTime = moment(new Date());
      setDataLoading(true);
      axiosGetPaketListByKontaktId(id)
        .then((res: any) => {
          setKontaktPaketList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setDataLoading, startTime);
        });
    }
  };

  const openConfirmationDialog = () => {
    setIsOpenConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setIsOpenConfirmationDialog(false);
  };

  const openDialog = (action: string, data: KontaktPaketReadDto) => {
    setDialogAction(action);
    if (data !== undefined) {
      setKontaktPaket(data);
    }
    if (action === EntityOperation.CREATE && data === undefined) {
      setKontaktPaketChange({
        ...kontaktPaketChange!,
        kontakt: { id: id },
        kontaktUslugaList: [],
        kontaktProizvodList: [],
      });
    } else {
      loadUslugeProizvodi(data?.paket.id);
      setKontaktPaketChange({
        ...kontaktPaketChange!,
        id: data?.id,
        kontakt: { id: id },
        paket: { id: data?.paket.id },
        kontaktUslugaList: data?.kontaktUslugaList,
        kontaktProizvodList: data?.kontaktProizvodList,
      });
    }

    axiosGetPaketList()
      .then((res: AxiosResponse<any>) => {
        dispatch({ type: actions.OPEN_DIALOG, value: res.data.data });
      })
      .catch((error: AxiosError) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {});
  };

  const closeDialog = () => {
    dispatch({ type: actions.CLOSE_DIALOG });
    setKontaktPaket(undefined);
    setKontaktPaketChange(undefined);
  };

  const loadUslugeProizvodi = (paketId: number) => {
    const requestUsluge = axiosGetUslugeListByPaketId(paketId);
    const requestProizvodi = axiosGetProizvodiListByPaketId(paketId);
    dispatch({ type: actions.LOADING, value: true });
    axios
      .all([requestUsluge, requestProizvodi])
      .then(
        axios.spread((responseUsluge, responseProizvodi) => {
          dispatch({ type: actions.LOAD_USLUGE_PROIZVODI, value_usluge: responseUsluge.data.data, value_proizvodi: responseProizvodi.data.data });
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        dispatch({ type: actions.LOADING, value: false });
      });
  };

  const validateInput = (kontaktPaket?: KontaktPaketCreateDto) => {
    if (!kontaktPaket?.paket?.id) {
      showMessage(MessageType.ERROR, Labels.KONTAKT_PAKET_MANDATORY_FIELD);
      return false;
    }
    return true;
  };

  const onCreate = (unsafelyExecute?: boolean) => {
    if (!validateInput(kontaktPaketChange)) {
      return;
    }
    axiosPostKontaktPaket(kontaktPaketChange, id, unsafelyExecute)
      .then(() => {
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_CREATE_1 + kontaktPaketChange?.kontakt?.id + Labels.LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_CREATE_1 + kontaktPaketChange?.paket?.id,
          pacijent.id
        );
      })
      .then(() => {
        if (isOpenConfirmationDialog) closeConfirmationDialog();
        closeDialog();
        showMessage(MessageType.SUCCESS, Labels.KONTAKT_PAKETI_DIALOG_CREATE_SUCCESS);
        fetchData();
      })
      .catch((error: any) => {
        if (error.response?.data?.code === RETRY_WITH_RESPONSE_CODE) {
          openConfirmationDialog();
        } else {
          handleAxiosCallError(showMessage, error);
        }
      });
  };

  const onDelete = (kontaktPaket: KontaktPaketReadDto) => {
    if (kontaktPaket?.id) {
      axiosDeletePaketFromKontakt(id, kontaktPaket?.id)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_1 +
              kontaktPaket?.paket?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_1 +
              kontaktPaket?.radnikKreirao.ime +
              " " +
              kontaktPaket?.radnikKreirao.prezime +
              "."
          );
        })
        .then(() => {
          if (isOpenConfirmationDialog) closeConfirmationDialog();
          closeDialog();
          showMessage(MessageType.SUCCESS, Labels.KONTAKT_PAKETI_DELETE_SUCESS);
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onUpdate = (unsafelyExecute?: boolean) => {
    let kontaktPaketUpdate: KontaktPaketUpdateDto | undefined = kontaktPaketChange ? { ...kontaktPaketChange } : undefined;
    if (kontaktPaketUpdate && kontaktPaket?.id) {
      axiosUpdatePaketFromKontakt(id, kontaktPaket.id, kontaktPaketUpdate, unsafelyExecute)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_1 +
              kontaktPaket?.paket?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_1 +
              kontaktPaket?.radnikKreirao.ime +
              " " +
              kontaktPaket?.radnikKreirao.prezime +
              ".",
            pacijent.id
          );
        })
        .then(() => {
          if (isOpenConfirmationDialog) closeConfirmationDialog();
          closeDialog();
          showMessage(MessageType.SUCCESS, Labels.KONTAKT_PAKETI_UPDATE_SUCCESS);
          fetchData();
        })
        .catch((error: any) => {
          if (error.response?.data?.code === RETRY_WITH_RESPONSE_CODE) {
            openConfirmationDialog();
          } else {
            handleAxiosCallError(showMessage, error);
          }
        });
    }
  };

  return {
    dataLoading,
    state,
    dispatch,
    openDialog,
    closeDialog,
    loadUslugeProizvodi,
    kontaktPaketChange,
    setKontaktPaketChange,
    onCreate,
    onDelete,
    dialogAction,
    kontaktPaket,
    setKontaktPaket,
    onUpdate,
    isOpenConfirmationDialog,
    closeConfirmationDialog,
  };
}
