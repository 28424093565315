import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { RadnikController } from "../../../../controllers/radnik/RadnikController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import OrganizacionaJedinicaRadnikCreateDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaRadnikCreateDto";
import OrganizacionaJedinicaRadnikReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaRadnikReadDto";
import RadnikOrganizacionaJedinicaCreateDto from "../../../../model/radnik/RadnikOrganizacionaJedinicaCreateDto";
import RadnikReadDto from "../../../../model/radnik/RadnikReadDto";
import RadnikSkracenoReadDto from "../../../../model/radnik/RadnikSkracenoReadDto";

export interface OrganizacionaJedinicaRadnikHolder {
  radnik: RadnikSkracenoReadDto;
}
interface OrganizacionaJedinicaRadnikLogical {
  organizacionaJedinicaRadnikList: Array<OrganizacionaJedinicaRadnikReadDto | undefined>;
  radnikList: Array<OrganizacionaJedinicaRadnikHolder>;
  isBlocked: boolean;
  isLoading: boolean;
  sourceSelection: Array<OrganizacionaJedinicaRadnikHolder>;
  setSourceSelection: React.Dispatch<React.SetStateAction<Array<OrganizacionaJedinicaRadnikHolder>>>;
  targetSelection: Array<OrganizacionaJedinicaRadnikReadDto>;
  setTargetSelection: React.Dispatch<React.SetStateAction<Array<OrganizacionaJedinicaRadnikReadDto>>>;
  onMoveToTarget: (radnici: OrganizacionaJedinicaRadnikHolder[]) => void;
  onMoveToSource: (radnikOrgJedinice: OrganizacionaJedinicaRadnikReadDto[]) => void;
}
interface UseParamsType {
  orgJedId?: string;
}
export default function CrudOrganizacionaJedinicaRadnikLogical(orgJedOperation: string, orgJedNaziv?: string): OrganizacionaJedinicaRadnikLogical {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();

  const [organizacionaJedinicaRadnikList, setOrganizacionaJedinicaRadnikList] = useState<Array<OrganizacionaJedinicaRadnikReadDto>>([]);
  const [radnikList, setRadnikList] = useState<Array<OrganizacionaJedinicaRadnikHolder>>([]);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sourceSelection, setSourceSelection] = useState<Array<OrganizacionaJedinicaRadnikHolder>>([]);
  const [targetSelection, setTargetSelection] = useState<Array<OrganizacionaJedinicaRadnikReadDto>>([]);
  const { axiosSearchRadnik } = RadnikController();
  const { axiosGetOrganizacionaJedinicaRadnikList, axiosCreateOrganizacionaJedinicaRadnik, axiosDeleteOrganizacionaJedinicaRadnik } = OrganizacionaJedinicaController();
  const orgJedId: number = Number(useParams<keyof UseParamsType>()["orgJedId"]);
  const prevOrgJedOperation = useRef<string | undefined>(undefined);
  const prevOorgJedId = useRef<number | undefined>(undefined);

  const postLogHighLevel = useLogHighLevel();

  const getRadnikList = useCallback(
    (organizacionaJedinicaRadnikList: Array<OrganizacionaJedinicaRadnikReadDto>) => {
      setIsLoading(true);
      axiosSearchRadnik({})
        .then((res: any) => {
          let radnikListTemp: Array<OrganizacionaJedinicaRadnikHolder> = [];
          const allRadnik = res.data.data;
          allRadnik.forEach((radnik: RadnikReadDto) => {
            if (
              organizacionaJedinicaRadnikList.filter((radnikOrganizacionaJedinica: OrganizacionaJedinicaRadnikReadDto) => {
                return radnikOrganizacionaJedinica.radnik.id === radnik.id;
              }).length === 0
            ) {
              radnikListTemp.push({ radnik: radnik });
            }
          });
          setRadnikList(radnikListTemp);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosSearchRadnik, setIsLoading, showMessage]
  );
  const getOrganizacionaJedinicaRadnik = useCallback(() => {
    if (orgJedId !== null && orgJedId !== undefined) {
      setIsLoading(true);
      axiosGetOrganizacionaJedinicaRadnikList(orgJedId)
        .then((res: any) => {
          setOrganizacionaJedinicaRadnikList(res.data.data);
          getRadnikList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setIsLoading(false);
        });
    }
  }, [axiosGetOrganizacionaJedinicaRadnikList, getRadnikList, orgJedId, setIsLoading, showMessage]);

  const fetchData = useCallback(() => {
    getOrganizacionaJedinicaRadnik();
  }, [getOrganizacionaJedinicaRadnik]);

  useEffect(() => {
    if (orgJedId && prevOorgJedId.current !== orgJedId && prevOrgJedOperation.current !== orgJedOperation && orgJedOperation !== EntityOperation.CREATE) {
      prevOrgJedOperation.current = orgJedOperation;
      prevOorgJedId.current = orgJedId;
      fetchData();
    }
  }, [fetchData, orgJedOperation, orgJedId]);

  const onMoveToTarget = (radnici: OrganizacionaJedinicaRadnikHolder[]) => {
    setIsLoading(true);
    setIsBlocked(true);

    let radnikOrgJedinicaChangeArray: OrganizacionaJedinicaRadnikCreateDto[] = [];
    radnici.forEach((radnik: OrganizacionaJedinicaRadnikHolder) => {
      radnikOrgJedinicaChangeArray.push({
        radnik: { id: radnik?.radnik?.id },
        organizacionaJedinica: { id: orgJedId },
      });
    });
    //
    axiosCreateOrganizacionaJedinicaRadnik(orgJedId, radnikOrgJedinicaChangeArray)
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        const radniciImena = radnici.map((radnik) => radnik?.radnik?.ime + " " + radnik?.radnik?.prezime).join(", ");
        const sigleOrMultipleLabel = radnici.length > 1 ? Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_3 : Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_1;
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA + orgJedNaziv + sigleOrMultipleLabel + radniciImena + ".");
        fetchData();
        setSourceSelection([]);
        setTargetSelection([]);
        setIsBlocked(false);
        setIsLoading(false);
      });
  };

  const onMoveToSource = (radnikOrgJedinice: OrganizacionaJedinicaRadnikReadDto[]) => {
    setIsLoading(true);
    setIsBlocked(true);

    let radnikOrgJedinicaChangeArray: RadnikOrganizacionaJedinicaCreateDto[] = [];
    radnikOrgJedinice.forEach((radnikOrgJedinica: OrganizacionaJedinicaRadnikReadDto) => {
      radnikOrgJedinicaChangeArray.push({
        radnik: { id: radnikOrgJedinica?.radnik?.id },
        organizacionaJedinica: { id: radnikOrgJedinica?.organizacionaJedinica?.id },
      });
    });
    let radniciIdList: Array<number> = [];

    radnikOrgJedinicaChangeArray.map((orgJedinica) => radniciIdList.push(orgJedinica?.radnik?.id!));

    axiosDeleteOrganizacionaJedinicaRadnik(orgJedId, [...radniciIdList])
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        const radniciImena = radnikOrgJedinice.map((radnik) => radnik?.radnik?.ime + " " + radnik?.radnik?.prezime).join(", ");
        const sigleOrMultipleLabel =
          radnikOrgJedinice.length > 1 ? Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_4 : Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_2;
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA + orgJedNaziv + sigleOrMultipleLabel + radniciImena + ".");
        fetchData();
        setSourceSelection([]);
        setTargetSelection([]);
        setIsBlocked(false);
        setIsLoading(false);
      });
  };

  return {
    organizacionaJedinicaRadnikList,
    radnikList,
    isBlocked,
    isLoading,
    sourceSelection,
    setSourceSelection,
    targetSelection,
    setTargetSelection,
    onMoveToTarget,
    onMoveToSource,
  };
}
