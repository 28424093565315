import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { ParametarKategorijaSaParametrimaUpdateDto } from "../../model/parametar/ParametarKategorijaSaParametrimaUpdateDto";

interface ParametarControllerType {
  axiosGetParametarList: () => Promise<AxiosResponse<any>>;
  axiosUpdateParameterListByKategorija: (parametarUstanova: ParametarKategorijaSaParametrimaUpdateDto) => Promise<AxiosResponse<any>>;
  axiosGetParametarUstanovaList: () => Promise<AxiosResponse<any>>;
}

export function ParametarController(): ParametarControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetParametarUstanovaList = () => {
    return axios.get(`${Endpoint.PARAMETAR_USTANOVA_LIST}`, axiosConfig(authData!.token));
  };

  const axiosGetParametarList = () => {
    return axios.get(`${Endpoint.PARAMETAR_LIST}`, axiosConfig(authData!.token));
  };

  const axiosUpdateParameterListByKategorija = (parametarUstanova: ParametarKategorijaSaParametrimaUpdateDto) => {
    return axios.put(`${Endpoint.PARAMETAR_USTANOVA_LIST}/update`, parametarUstanova, axiosConfig(authData!.token));
  };

  return {
    axiosGetParametarList,
    axiosUpdateParameterListByKategorija,
    axiosGetParametarUstanovaList,
  };
}
