import RadnikReadDto from "../../../../model/radnik/RadnikReadDto";

export interface InitialStateDodavanjePostojecegRadnikaUstanovaType {
  isDialogOpen: boolean;
  radnikSelected: RadnikReadDto | undefined;
  radnikAddList: Array<RadnikReadDto>;
}

const InitialStateDodavanjePostojecegRadnikaUstanova: InitialStateDodavanjePostojecegRadnikaUstanovaType = {
  isDialogOpen: false,
  radnikSelected: undefined,
  radnikAddList: []
};

export default InitialStateDodavanjePostojecegRadnikaUstanova;
