import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useLabels } from "../../../../../Store";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import actionsKontaktPaketDialog from "../../../../../infrastructure/system/hooks/dialog-dodavanje-paketa-reducer/actionsKontaktPaketDialog";
import KontaktPaketCreateDto from "../../../../../model/kontakt/KontaktPaketCreateDto";
import KontaktPaketReadDto from "../../../../../model/kontakt/KontaktPaketReadDto";
import PaketProizvodReadDto from "../../../../../model/paket/PaketProizvodReadDto";
import PaketReadDto from "../../../../../model/paket/PaketReadDto";
import PaketUslugaReadDto from "../../../../../model/paket/PaketUslugaReadDto";
import SkeletonAccordionList from "../../../../app/skeleton/SkeletonAccordionList";
import DialogComponent from "../../../../dialog-component/DialogComponent";
import KontaktPaketDialogLogical from "./KontaktPaketDialogLogical";

interface KontantPaketDialogType {
  isOpenDialog: boolean;
  closeDialog: () => void;
  aktivniPaketiList: Array<PaketReadDto>;
  dispatch: React.Dispatch<{ type: actionsKontaktPaketDialog }>;
  loadUslugeProizvodi: (paketId: number) => void;
  proizvodi: Array<PaketProizvodReadDto>;
  usluge: Array<PaketUslugaReadDto>;
  kontaktPaketChange: KontaktPaketCreateDto | undefined;
  setKontaktPaketChange: React.SetStateAction<any>;
  onCreate: () => void;
  loadingUslugeProizvodi: boolean;
  dialogAction: string;
  kontaktPaket: KontaktPaketReadDto | undefined;
  setKontaktPaket: React.SetStateAction<any>;
  onUpdate: () => void;
}

export default function KontaktPaketDialog(props: KontantPaketDialogType) {
  const {
    isOpenDialog,
    closeDialog,
    aktivniPaketiList,
    loadUslugeProizvodi,
    proizvodi,
    usluge,
    kontaktPaketChange,
    setKontaktPaketChange,
    onCreate,
    loadingUslugeProizvodi,
    dialogAction,
    kontaktPaket,
    setKontaktPaket,
    onUpdate,
  } = props;
  const { addOrRemoveItem, updateKolicinaUslugeProizvodi } = KontaktPaketDialogLogical(dialogAction, kontaktPaketChange, setKontaktPaketChange);
  const Labels = useLabels();

  const footer = () => {
    return (
      <div className="flex flex-row justify-content-end px-1">
        <Button label={Labels.BUTTON_CANCEL} className="p-button-outlined p-button-tertiary align-self-center" onClick={closeDialog} />
        {dialogAction === EntityOperation.CREATE && <Button label={Labels.KONTAKT_PAKETI_DIALOG_LABEL_SNIMI} className="p-button-success orange" onClick={() => onCreate()} />}
        {dialogAction === EntityOperation.UPDATE && <Button label={Labels.KONTAKT_PAKETI_LABEL_IZMENI} className="p-button-success orange" onClick={() => onUpdate()} />}
      </div>
    );
  };

  return (
    <DialogComponent
      header=""
      visible={isOpenDialog}
      onHide={closeDialog}
      className="w-11 sm:w-10 md:w-10 dialogKontaktPaket"
      footer={footer}
      blockScroll={true}
      content={
        <>
          <div className="flex flex-row flex-wrap">
            <div className="col-12 md:col-8 lg:col-6">
              <Dropdown
                value={kontaktPaketChange?.paket?.id}
                options={aktivniPaketiList}
                optionLabel="naziv"
                optionValue="id"
                onChange={(e) => {
                  loadUslugeProizvodi(e.target.value);
                  setKontaktPaketChange({
                    ...kontaktPaketChange!,
                    paket: { id: e.target.value },
                  });
                }}
                className="p-column-filter font-semibold"
                placeholder="Odaberite paket usluga"
                disabled={dialogAction === EntityOperation.UPDATE}
                filter
                filterBy="naziv"
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap">
            <div className="col-12 sm:col-12 md:col-12 lg:col-5">
              <div className="mb-4 font-semibold">{Labels.KONTAKT_PAKETI_DIALOG_LABEL_USLUGE_U_OKVIRU_PAKETA}:</div>
              <div className="scrollable-wrapper p-2 overflow-hidden">
                {loadingUslugeProizvodi ? (
                  <SkeletonAccordionList size={7} />
                ) : (
                  usluge.map((usluga: PaketUslugaReadDto) => (
                    <div key={usluga.id} className="mb-2 flex flex-row align-items-center justify-content-between">
                      <div className="col-9 p-0">
                        <Checkbox
                          inputId={usluga.sifarnikUsluga.naziv}
                          checked={
                            dialogAction === EntityOperation.CREATE
                              ? !!kontaktPaketChange?.kontaktUslugaList?.find((usluge: any) => usluge.id === usluga.id)
                              : !!kontaktPaket?.kontaktUslugaList?.find((usluge: any) => usluge.sifarnikUsluga.id === usluga.sifarnikUsluga.id)
                          }
                          onChange={(e) => {
                            setKontaktPaketChange({
                              ...kontaktPaketChange!,
                              kontaktUslugaList: addOrRemoveItem(kontaktPaketChange?.kontaktUslugaList, e, "usluga"),
                            });
                            if (dialogAction === EntityOperation.UPDATE) {
                              setKontaktPaket({
                                ...kontaktPaket!,
                                kontaktUslugaList: addOrRemoveItem(kontaktPaket?.kontaktUslugaList, e, "usluga"),
                              });
                            }
                          }}
                          value={usluga}
                        />
                        <label htmlFor={usluga.sifarnikUsluga.naziv} className="cursor-pointer p-checkbox-label pl-1 font-semibold">
                          {usluga.sifarnikUsluga.naziv}
                        </label>
                      </div>
                      <div className="col-3 p-0">
                        <InputNumber
                          value={kontaktPaket?.kontaktUslugaList?.find((usluge: any) => usluge.sifarnikUsluga.id === usluga.sifarnikUsluga.id)?.brojPruzanja}
                          disabled={
                            !kontaktPaketChange?.kontaktUslugaList?.find((usluge: any) => usluge.id === usluga.id) &&
                            !kontaktPaket?.kontaktUslugaList?.find((usluge: any) => usluge.sifarnikUsluga.id === usluga.sifarnikUsluga.id)
                          }
                          onChange={(e) => {
                            updateKolicinaUslugeProizvodi(kontaktPaketChange?.kontaktUslugaList, dialogAction === EntityOperation.CREATE ? usluga.id : usluga.sifarnikUsluga.id, null, e);
                          }}
                          inputId="brojPruzanja"
                          className="small-input"
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="col-12 sm:col-12 md:col-12 lg:col-7">
              <div className="mb-4 font-semibold">{Labels.KONTAKT_PAKETI_DIALOG_LABEL_UTROSCI_U_OKVIRU_PAKETA}:</div>
              <div className="scrollable-wrapper p-2">
                {loadingUslugeProizvodi ? (
                  <SkeletonAccordionList size={7} />
                ) : (
                  proizvodi.map((proizvod: PaketProizvodReadDto) => (
                    <div key={proizvod.id} className="mb-2 flex flex-row flex-wrap align-items-center justify-content-between">
                      <div className="col-12 sm:col-12 lg:col-9 p-0 mb-3 sm:mb-3 lg:mb-0">
                        <Checkbox
                          inputId={proizvod.sifarnikProizvoda.naziv}
                          checked={
                            !!kontaktPaketChange?.kontaktProizvodList?.find((proizvodi: any) => proizvodi.id === proizvod.id) ||
                            !!kontaktPaket?.kontaktProizvodList?.find((proizvodi: any) => proizvodi.sifarnikProizvoda.id === proizvod.sifarnikProizvoda.id)
                          }
                          onChange={(e) => {
                            setKontaktPaketChange({
                              ...kontaktPaketChange!,
                              kontaktProizvodList: addOrRemoveItem(kontaktPaketChange?.kontaktProizvodList, e, "proizvod"),
                            });
                            if (dialogAction === EntityOperation.UPDATE) {
                              setKontaktPaket({
                                ...kontaktPaket!,
                                kontaktProizvodList: addOrRemoveItem(kontaktPaket?.kontaktProizvodList, e, "proizvod"),
                              });
                            }
                          }}
                          value={proizvod}
                        />
                        <label htmlFor={proizvod.sifarnikProizvoda.naziv} className="cursor-pointer p-checkbox-label pl-1 font-semibold">
                          {proizvod.sifarnikProizvoda.naziv}
                        </label>
                      </div>
                      <div className="col-12 s:col-12 lg:col-3 p-0 font-semibold">
                        <InputNumber
                          value={
                            kontaktPaket?.kontaktProizvodList?.find((proizvodi: any) => proizvodi.sifarnikProizvoda.id === proizvod.sifarnikProizvoda.id)?.utrosenaKolicina ??
                            proizvod.podrazumevanaUtrosenaKolicina
                          }
                          disabled={
                            !kontaktPaketChange?.kontaktProizvodList?.find((proizvodi: any) => proizvodi.id === proizvod.id) &&
                            !kontaktPaket?.kontaktProizvodList?.find((proizvodi: any) => proizvodi.sifarnikProizvoda.id === proizvod.sifarnikProizvoda.id)
                          }
                          onChange={(e) => {
                            updateKolicinaUslugeProizvodi(kontaktPaketChange?.kontaktProizvodList, null, dialogAction === EntityOperation.CREATE ? proizvod.id : proizvod.sifarnikProizvoda.id, e);
                          }}
                          className="small-input"
                        />{" "}
                        {proizvod?.sifarnikProizvoda?.sifarnikJedinicaMere?.akronim}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      }
    />
  );
}
