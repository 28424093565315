import { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { EnumController } from "../../../controllers/enum/EnumController";
import EnumPercentilnaKrivaTip from "../../../infrastructure/system/EnumPercentilnaKrivaTip";
import EnumPercentilnaKrivaUzrast from "../../../infrastructure/system/EnumPercentilnaKrivaUzrast";
import EnumVrstaGrafikonaRasta from "../../../infrastructure/system/EnumVrstaGrafikonaRasta";
import { DATE_FORMAT, handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import useEffectWithoutWarnings from "../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import PacijentPoljePopunjeno from "../../../model/pacijent-polje/PacijentPoljePopunjeno";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SifarnikPercentilnaKrivaReadDto from "../../../model/sifarnik/SifarnikPercentilnaKrivaReadDto";

interface PercentileKrivaDialogLogicalType {
  graphOptions: GraphType[];
  selectedGraph: GraphType;
  setSelectedGraph: React.Dispatch<React.SetStateAction<GraphType>>;
  uzrastList: EnumBaseReadDto[];
  setUzrastList: React.Dispatch<React.SetStateAction<EnumBaseReadDto[]>>;
  selectedUzrast: EnumBaseReadDto;
  setSelectedUzrast: React.Dispatch<React.SetStateAction<EnumBaseReadDto>>;
  setClosingChange: React.Dispatch<React.SetStateAction<boolean>>;
  fullPath: string | undefined;
  setFullPath: React.Dispatch<React.SetStateAction<string | undefined>>;
  dotCoordinates: Array<DotCoordinatesType>;
  setDotCoordinates: React.Dispatch<React.SetStateAction<Array<DotCoordinatesType>>>;
}

interface GraphType {
  naziv: string;
  sifra: string;
}

export interface DotCoordinatesType {
  x: number | undefined;
  y: number | undefined;
}

const PercentileKrivaDialogLogical = (
  vrstaGrafikona: string | undefined,
  pacijentPoljeArhivaList: any,
  percentilnaKrivaList: SifarnikPercentilnaKrivaReadDto[],
  medicinskiPodaci: Array<PacijentPoljePopunjeno>,
  pacijent?: PacijentReadDto
): PercentileKrivaDialogLogicalType => {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosGetPercentilnaKrivaUzrastList } = EnumController();
  const [selectedGraph, setSelectedGraph] = useState<GraphType>({ naziv: Labels.LABEL_PERCENTILNA_KRIVA_Z_SKOROVI, sifra: EnumPercentilnaKrivaTip.ZSCORE });
  const [uzrastList, setUzrastList] = useState<EnumBaseReadDto[]>([]);
  const [selectedUzrast, setSelectedUzrast] = useState<EnumBaseReadDto>(uzrastList[0]);
  const [closingChange, setClosingChange] = useState<boolean>(false);
  const [fullPath, setFullPath] = useState<string | undefined>(undefined);
  const [dotCoordinates, setDotCoordinates] = useState<Array<DotCoordinatesType>>([]);

  const graphOptions = [
    { naziv: Labels.LABEL_PERCENTILNA_KRIVA_Z_SKOROVI, sifra: EnumPercentilnaKrivaTip.ZSCORE },
    { naziv: Labels.LABEL_PERCENTILNA_KRIVA_PERCENTILI, sifra: EnumPercentilnaKrivaTip.PERCENT },
  ];

  const calculateDotXPosition = (startX: number, months: number, monthSince: number, stepX: number) => {
    return startX + (months - monthSince) * stepX;
  };

  const calculateDotYPosition = (startY: number, yValue: number, yValueSince: number, stepY: number) => {
    return startY - (yValue - yValueSince) * stepY;
  };

  useEffectWithoutWarnings(() => {
    const trenutnaVrednost = medicinskiPodaci.find((podatak: PacijentPoljePopunjeno) => podatak?.pacijentPolje?.vrstaGrafikonaRasta?.sifra === vrstaGrafikona);
    const trenutnaVrednostDatum = moment(trenutnaVrednost?.vremePoslednjeIzmene ? trenutnaVrednost.vremePoslednjeIzmene : trenutnaVrednost?.vremeKreiranja).format(DATE_FORMAT);

    setDotCoordinates([]);
    const percentilnaKriva = percentilnaKrivaList?.find(
      (element: SifarnikPercentilnaKrivaReadDto) =>
        element.percentilnaKrivaPol.sifra === pacijent?.polTrenutni.sifra &&
        element.percentilnaKrivaUzrast.sifra === selectedUzrast?.sifra &&
        element.percentilnaKrivatip.sifra === selectedGraph?.sifra &&
        element.vrstaGrafikonaRasta.sifra === vrstaGrafikona
    );

    const getImage = async () => {
      setShowBlockUI(true);
      try {
        const response = await import(`../../../infrastructure/images/percentil-kriva/${percentilnaKriva?.fajlPutanja}`);
        setFullPath(response.default);
        setShowBlockUI(false);
      } catch (error) {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      }
    };

    if (percentilnaKriva?.fajlPutanja) {
      getImage();
    }

    const uzrastFilter =
      selectedUzrast?.sifra === EnumPercentilnaKrivaUzrast.UZRAST_0_2
        ? { od: 0, do: 24 }
        : selectedUzrast?.sifra === EnumPercentilnaKrivaUzrast.UZRAST_2_5
        ? { od: 24, do: 60 }
        : selectedUzrast?.sifra === EnumPercentilnaKrivaUzrast.UZRAST_5_10
        ? { od: 60, do: 120 }
        : selectedUzrast?.sifra === EnumPercentilnaKrivaUzrast.UZRAST_5_19
        ? { od: 60, do: 228 }
        : null;

    pacijentPoljeArhivaList.map((polje: any) => calculateCoordinates(polje.vremeArhiva.split(" ")[0], polje.vrednost, uzrastFilter, percentilnaKriva!));
    if (trenutnaVrednost) calculateCoordinates(trenutnaVrednostDatum, trenutnaVrednost.vrednostNumber, uzrastFilter, percentilnaKriva!);
  }, [selectedUzrast, selectedGraph, vrstaGrafikona, closingChange, pacijentPoljeArhivaList]);

  const calculateCoordinates = (dateString: string, vrednost: number, uzrastFilter: any, percentilnaKriva: SifarnikPercentilnaKrivaReadDto) => {
    const parts = dateString.split(".");
    const dan = parseInt(parts[0], 10);
    const mesec = parseInt(parts[1], 10) - 1;
    const godina = parseInt(parts[2], 10);

    const datumPromene = new Date(godina, mesec, dan);
    const datumRodjenja = new Date(pacijent?.vremeRodjenja!);
    const godineRazlika = datumPromene.getFullYear() - datumRodjenja.getFullYear();
    let meseciRazlika = datumPromene.getMonth() - datumRodjenja.getMonth();
    let daniRazlika = datumPromene.getDate() - datumRodjenja.getDate();

    if (daniRazlika < 0) {
      const brojDanaUProslomMesecu = new Date(datumPromene.getFullYear(), datumPromene.getMonth(), 0).getDate();
      daniRazlika += brojDanaUProslomMesecu;
      meseciRazlika--;
    }

    const brojMeseciPriUnosu = godineRazlika * 12 + meseciRazlika + daniRazlika / 30;

    if (uzrastFilter?.od < brojMeseciPriUnosu && uzrastFilter?.do > brojMeseciPriUnosu && percentilnaKriva?.nulaY < vrednost && percentilnaKriva?.endY > vrednost) {
      let xCoord: number = calculateDotXPosition(percentilnaKriva?.startX!, brojMeseciPriUnosu, percentilnaKriva?.nulaX!, percentilnaKriva?.stepX!);
      let yCoord: number = calculateDotYPosition(percentilnaKriva?.startY!, vrednost, percentilnaKriva?.nulaY!, percentilnaKriva?.stepY!);

      setDotCoordinates((prev: DotCoordinatesType[]) => [
        ...prev,
        {
          x: xCoord,
          y: yCoord,
        },
      ]);
    }
  };

  const getPercentilnaKrivaUzrastList = () => {
    axiosGetPercentilnaKrivaUzrastList()
      .then((response: AxiosResponse) => {
        setUzrastList(response.data.data);
        const trenutniDatum = new Date();
        const datumRodjenja = new Date(pacijent?.vremeRodjenja!);
        const godineRazlika = trenutniDatum.getFullYear() - datumRodjenja.getFullYear();
        let meseciRazlika = godineRazlika * 12 + trenutniDatum.getMonth() - datumRodjenja.getMonth();

        const getSifraUzrastaPacijenta = () => {
          if (meseciRazlika <= 24) return EnumPercentilnaKrivaUzrast.UZRAST_0_2;
          else if (meseciRazlika <= 60) return EnumPercentilnaKrivaUzrast.UZRAST_2_5;
          else if (vrstaGrafikona === EnumVrstaGrafikonaRasta.TEZINA && meseciRazlika <= 120) return EnumPercentilnaKrivaUzrast.UZRAST_5_10;
          else if (vrstaGrafikona !== EnumVrstaGrafikonaRasta.TEZINA && meseciRazlika <= 228) return EnumPercentilnaKrivaUzrast.UZRAST_5_19;
          else return "";
        };

        const sifraUzrastaPacijenta = getSifraUzrastaPacijenta();
        const pacijentUzrast = response.data.data.find((uzrast: EnumBaseReadDto) => uzrast.sifra === sifraUzrastaPacijenta);
        if (pacijentUzrast) {
          setSelectedUzrast(pacijentUzrast);
        } else {
          setSelectedUzrast(response.data.data[0]);
        }
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  useEffectOnce(() => {
    getPercentilnaKrivaUzrastList();
  });

  return {
    graphOptions,
    selectedGraph,
    setSelectedGraph,
    uzrastList,
    setUzrastList,
    selectedUzrast,
    setSelectedUzrast,
    fullPath,
    setFullPath,
    dotCoordinates,
    setDotCoordinates,
    setClosingChange,
  };
};

export default PercentileKrivaDialogLogical;
