import axios from "axios";
import moment from "moment";
import { FileUploadHandlerEvent } from "primereact/fileupload";
import { useContext, useRef, useState } from "react";
import SockJS from "sockjs-client";
import { Client, Message, over } from "stompjs";
import { AppContext, useLabels } from "../../../Store";
import { SifarnikImportController } from "../../../controllers/sifarnik-import/SifarnikImportController";
import Endpoint from "../../../infrastructure/system/Endpoint";
import EnumStatusUvoza from "../../../infrastructure/system/EnumStatusUvoza";
import EnumWebSocketPorukaTip from "../../../infrastructure/system/EnumWebSocketPorukaTip";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import WebSocketPayload from "../../../model/WebSocketPayload";
import SifarnikImportReadDto from "../../../model/sifarnik-import/SifarnikImportReadDto";
import SifarnikTipDto from "../../../model/sifarnik-import/SifarnikTipDto";

interface SifarnikImportLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  sifarnikTipLoading: boolean;
  sifarnikTipList: Array<SifarnikTipDto>;
  sifarnikImportList: Array<SifarnikImportReadDto>;
  uploadHandler: (e: FileUploadHandlerEvent, tipId: number) => void;
}

export default function SifarnikImportLogical(): SifarnikImportLogicalType {
  const Labels = useLabels();
  const { showMessage } = useContext(AppContext);
  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.SIFARNIK_IMPORT,
    },
  ];

  let stompClient = useRef<Client>();

  const { axiosSearchSifarnikTypeList, axiosInitiateSifarnikImport, axiosGetSifarnikImportList } = SifarnikImportController();
  const [sifarnikTipList, setSifarnikTipList] = useState<Array<SifarnikTipDto>>([]);
  const [sifarnikImportList, setSifarnikImportList] = useState<Array<SifarnikImportReadDto>>([]);
  const [sifarnikTipLoading, setSifarnikTipLoading] = useState<boolean>(false);

  useEffectOnce(() => {
    fetchData();
    return () => {
      if (stompClient?.current?.connected) {
        stompClient.current.disconnect(() => {});
      }
    };
  });

  const fetchData = () => {
    const startTime = moment(new Date());
    setSifarnikTipLoading(true);
    const searchSifarnikTypeList = axiosSearchSifarnikTypeList({});
    const getSifarnikImportList = axiosGetSifarnikImportList();
    axios
      .all([searchSifarnikTypeList, getSifarnikImportList])
      .then(
        axios.spread((searchSifarnikTypeListResponse: any, getSifarnikImportListResponse: any) => {
          setSifarnikTipList(searchSifarnikTypeListResponse.data.data);
          setSifarnikImportList(getSifarnikImportListResponse.data.data);
          if (getSifarnikImportListResponse.data.data.filter((sifarnikImport: SifarnikImportReadDto) => sifarnikImport.statusUvozaEnum.sifra === EnumStatusUvoza.U_TOKU).length > 0) connect();
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSifarnikTipLoading, startTime);
      });
  };

  const connect = () => {
    let sock = new SockJS(Endpoint.SIFARNIK_IMPORT_WEBSOCKET);
    stompClient.current = over(sock);
    stompClient.current.connect({}, onConnected, onError);
  };

  const onConnected = () => {
    stompClient.current!.subscribe(Endpoint.SIFARNIK_IMPORT_LISTENER, onMessageReceived);
  };

  const onError = () => {
    showMessage(MessageType.ERROR, Labels.SIFARNIK_IMPORT_CONNECTION_ERROR);
  };

  const onMessageReceived = (message: Message) => {
    let payload: WebSocketPayload = JSON.parse(message.body);
    var payloadData: SifarnikImportReadDto = payload.payload;
    payloadData.uspesanImport = payloadData.statusUvozaEnum.sifra === EnumStatusUvoza.USPESNO_UVEZEN;
    setSifarnikImportList((prevState) => {
      if (prevState.filter((sifarnikImport: SifarnikImportReadDto) => sifarnikImport.statusUvozaEnum.sifra === EnumStatusUvoza.U_TOKU).length === 1) stompClient.current!.disconnect(() => {});
      return [...prevState.filter((sifarnikImport) => sifarnikImport.sifarnikTip.id !== payloadData.sifarnikTip.id), payloadData];
    });
    // if (payloadData.uspesanImport) showMessage(MessageType.SUCCESS, Labels.SIFARNIK_IMPORT_USPESNO_UVEZEN + payloadData.fileName);

    if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.ERROR_MSG) {
      showMessage(MessageType.ERROR, payload.poruka);
    } else if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.INFO_MSG) {
      showMessage(MessageType.INFO, payload.poruka);
    } else if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.SUCCESS_MSG) {
      showMessage(MessageType.SUCCESS, payload.poruka);
    }
  };

  const uploadHandler = (e: FileUploadHandlerEvent, tipId: number) => {
    const reader = new FileReader();
    const file: File = e.files[0];
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (reader.result) {
        if (!stompClient.current?.connected) {
          connect();
        }
        showMessage(MessageType.SUCCESS, Labels.SIFARNIK_IMPORT_INATIATE_SUCCESS + file.name);
        axiosInitiateSifarnikImport({ fileName: file.name, fileContent: reader.result.toString().substr(reader.result.toString().indexOf(",") + 1), sifarnikTip: { id: tipId } })
          .then(() => {
            showMessage(MessageType.SUCCESS, Labels.SIFARNIK_IMPORT_STARTED_SUCCESS + file.name);
          })
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
          });
      }
    };
  };

  return {
    breadCrumbItems,
    sifarnikTipLoading,
    sifarnikTipList,
    sifarnikImportList,
    uploadHandler,
  };
}
