const EnumFajlFormat = {
  XLS: "xls",
  XLSX: "xlsx",
  PDF: "pdf",
  TXT: "txt",
  CSV: "csv",
  XML: "xml",
};

export default EnumFajlFormat;
