import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import CommonResponseDto from "../../model/CommonResponseDto";
import SearchBaseDto from "../../model/SearchBaseDto";
import PartnerCenaCreateDto from "../../model/partner/PartnerCenaCreateDto";
import PartnerCenaSearchDto from "../../model/partner/PartnerCenaSearchDto";
import PartnerCenaUpdateDto from "../../model/partner/PartnerCenaUpdateDto";
import PartnerCreateDto from "../../model/partner/PartnerCreateDto";
import PartnerReadDto from "../../model/partner/PartnerReadDto";

interface PartnerControllerType {
  axiosCreatePartner: (partner: PartnerCreateDto) => Promise<AxiosResponse<any>>;
  axiosSearchPartneri: (searchObject: SearchBaseDto) => Promise<AxiosResponse<CommonResponseDto<PartnerReadDto[]>>>;
  axiosGetPartnerById: (partnerId: number) => Promise<AxiosResponse<any>>;
  axiosUpdatePartner: (partner: PartnerReadDto) => Promise<AxiosResponse<any>>;
  axiosDeletePartner: (partnerId: number) => Promise<AxiosResponse<any>>;
  axiosCreateCenaForPartner: (cena: PartnerCenaCreateDto) => Promise<AxiosResponse<any>>;
  axiosSearchCeneForPartner: (partnerId: number, searchPartnerCene: PartnerCenaSearchDto | undefined) => Promise<AxiosResponse<any>>;
  axiosDeleteCenaForPartner: (partnerId: number, cenaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateCenaForPartner: (cena: PartnerCenaUpdateDto) => Promise<AxiosResponse<any>>;
}

export default function PartnerController(): PartnerControllerType {
  const { authData } = useContext(AppContext);

  const axiosCreatePartner = (partner: PartnerCreateDto) => {
    return axios.post(`${Endpoint.PARTNERI_LIST}`, partner, axiosConfig(authData!.token));
  };

  const axiosSearchPartneri = (searchObject: SearchBaseDto) => {
    return axios.get(Endpoint.PARTNERI_SEARCH, axiosConfig(authData!.token, searchObject));
  };

  const axiosGetPartnerById = (partnerId: number) => {
    return axios.get(`${Endpoint.PARTNERI_LIST}/${partnerId}`, axiosConfig(authData!.token));
  };

  const axiosUpdatePartner = (partner: PartnerReadDto) => {
    return axios.put(`${Endpoint.PARTNERI_LIST}/${partner.id}`, partner, axiosConfig(authData!.token));
  };

  const axiosDeletePartner = (partnerId: number) => {
    return axios.delete(`${Endpoint.PARTNERI_LIST}/${partnerId}`, axiosConfig(authData!.token));
  };

  const axiosCreateCenaForPartner = (cena: PartnerCenaCreateDto) => {
    return axios.post(`${Endpoint.PARTNERI_LIST}/${cena.partner.id}/partner-cena-list`, cena ? cena : {}, axiosConfig(authData!.token));
  };

  const axiosSearchCeneForPartner = (partnerId: number, searchPartnerCene: PartnerCenaSearchDto | undefined) => {
    return axios.get(`${Endpoint.PARTNERI_LIST}/${partnerId}/partner-cena-search`, axiosConfig(authData!.token, searchPartnerCene ? searchPartnerCene : {}));
  };

  const axiosDeleteCenaForPartner = (partnerId: number, cenaId: number) => {
    return axios.delete(`${Endpoint.PARTNERI_LIST}/${partnerId}/partner-cena-list/${cenaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateCenaForPartner = (cena: PartnerCenaUpdateDto) => {
    return axios.put(`${Endpoint.PARTNERI_LIST}/${cena.partner.id}/partner-cena-list/${cena.id}`, cena ? cena : {}, axiosConfig(authData!.token));
  };

  return {
    axiosCreatePartner,
    axiosSearchPartneri,
    axiosGetPartnerById,
    axiosUpdatePartner,
    axiosDeletePartner,
    axiosCreateCenaForPartner,
    axiosSearchCeneForPartner,
    axiosDeleteCenaForPartner,
    axiosUpdateCenaForPartner,
  };
}
