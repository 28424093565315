import { AxiosResponse } from "axios";
import moment from "moment/moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { KategorijaStatistickogIzvestajaController } from "../../../../controllers/izvestaj/KategorijaStatistickogIzvestajaController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import KategorijaStatistickogIzvestajReadDto from "../../../../model/izvestaj/kategorija/KategorijaStatistickogIzvestajaReadDto";

interface CrudKategorijaStatistickihIzvestajaLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  location: any;
  kategorijaId: number;
  kategorijaOperation: string;
  isDisabled: boolean;
  kategorijaLoading: boolean;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onCancel: () => void;
  kategorijaChange?: KategorijaStatistickogIzvestajReadDto;
  setKategorijaChange: React.Dispatch<React.SetStateAction<KategorijaStatistickogIzvestajReadDto | undefined>>;
}

interface UseParamsType {
  kategorijaId?: string;
}

export default function CrudKategorijaStatistickihIzvestajaLogical(isSuperAdmin?: boolean): CrudKategorijaStatistickihIzvestajaLogicalType {
  const location = useLocation();
  const navigate = useNavigate();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const kategorijaId = Number(useParams<keyof UseParamsType>()["kategorijaId"]);
  const kategorijaNaziv = location.state && location.state.kategorijaNaziv !== undefined ? location.state.kategorijaNaziv : "";
  const kategorijaOperation: string =
    location.state && location.state.kategorijaOperation !== undefined ? location.state.kategorijaOperation : !isNaN(kategorijaId) ? EntityOperation.DELETE : EntityOperation.CREATE;
  const [isDisabled] = useState<boolean>(isFormDisabled(kategorijaOperation));
  const [kategorijaLoading, setKategorijaLoading] = useState(false);
  const [kategorijaChange, setKategorijaChange] = useState<KategorijaStatistickogIzvestajReadDto>();
  const { axiosGetKategorija, axiosCreateKategorija, axiosDeleteKategorija, axiosUpdateKategorija } = KategorijaStatistickogIzvestajaController();
  const {
    axiosSuperadminCreateKategorijaStatistickihIzvestaja,
    axiosSuperadminUpdateKategorijaStatistickihIzvestaja,
    axiosSuperadminGetKategorijaStatistickihIzvestaja,
    axiosSuperadminDeleteKategorijaStatistickihIzvestaja,
  } = SuperadminController();

  const postLogHighLevel = useLogHighLevel();

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.IZVESTAJ_LIST,
      command: () => {
        navigate("/izvestaj-list", { state: { activeIndex: 1 } });
      },
    },
  ]);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    if (kategorijaId) {
      const startTime = moment(new Date());
      setKategorijaLoading(true);
      (isSuperAdmin ? axiosSuperadminGetKategorijaStatistickihIzvestaja(kategorijaId) : axiosGetKategorija(kategorijaId))
        .then((res: AxiosResponse) => {
          setKategorijaChange(res.data.data);
          setBreadCrumbItems([
            breadCrumbItems[0],
            {
              label: kategorijaOperation === EntityOperation.UPDATE ? Labels.LABEL_KATEGORIJA_IZVESTAJA_UPDATE + " " + kategorijaNaziv : Labels.LABEL_KATEGORIJA_IZVESTAJA_DELETE,
            },
          ]);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setKategorijaLoading, startTime);
        });
    } else {
      setBreadCrumbItems([
        breadCrumbItems[0],
        {
          label: Labels.LABEL_KATEGORIJA_IZVESTAJA_CREATE,
        },
      ]);
    }
  };

  const validateInput = (kategorija: KategorijaStatistickogIzvestajReadDto | undefined) => {
    if (validateStringEmpty(kategorija?.naziv)) {
      showMessage(MessageType.ERROR, Labels.KATEGORIJA_NAZIV_IS_REQUIRED);
      return false;
    }
    if (validateStringEmpty(kategorija?.sifra)) {
      showMessage(MessageType.ERROR, Labels.KATEGORIJA_SIFRA_IS_REQUIRED);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateInput(kategorijaChange)) {
      return;
    }
    if (kategorijaChange) {
      setShowBlockUI(true);
      (isSuperAdmin ? axiosSuperadminCreateKategorijaStatistickihIzvestaja(kategorijaChange) : axiosCreateKategorija(kategorijaChange))
        .then(() => {
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_KATEGORIJA_IZVESTAJA_1 + kategorijaChange.naziv + Labels.LOG_HIGH_LEVEL_MESS_CREATE_KATEGORIJA_IZVESTAJA_2 + kategorijaChange.sifra);
          showMessage(MessageType.SUCCESS, Labels.KATEGORIJA_TITLE_MESSAGE_CREATE_KATEGORIJA_SUCCESS);
          navigate("/izvestaj-list", { state: { activeIndex: 1 } });
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(kategorijaChange)) {
      return;
    }
    if (kategorijaChange) {
      setShowBlockUI(true);
      (isSuperAdmin ? axiosSuperadminUpdateKategorijaStatistickihIzvestaja(kategorijaChange) : axiosUpdateKategorija(kategorijaChange))
        .then(() => {
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_KATEGORIJA_IZVESTAJA_1 + kategorijaChange.naziv + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_KATEGORIJA_IZVESTAJA_2 + kategorijaChange.sifra);
          showMessage(MessageType.SUCCESS, Labels.KATEGORIJA_TITLE_MESSAGE_UPDATE_KATEGORIJA_SUCCESS);
          navigate("/izvestaj-list", { state: { activeIndex: 1 } });
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    (isSuperAdmin ? axiosSuperadminDeleteKategorijaStatistickihIzvestaja(kategorijaChange!.id) : axiosDeleteKategorija(kategorijaChange!.id))
      .then(() => {
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_KATEGORIJA_IZVESTAJA_1 + kategorijaChange!.naziv + Labels.LOG_HIGH_LEVEL_MESS_DELETE_KATEGORIJA_IZVESTAJA_2 + kategorijaChange!.sifra);
        showMessage(MessageType.SUCCESS, Labels.KATEGORIJA_TITLE_MESSAGE_DELETE_KATEGORIJA_SUCCESS);
        navigate("/izvestaj-list", { state: { activeIndex: 1 } });
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate("/izvestaj-list");
    }
  };

  return {
    breadCrumbItems,
    location,
    kategorijaId,
    kategorijaOperation,
    isDisabled,
    kategorijaLoading,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    kategorijaChange,
    setKategorijaChange,
  };
}
