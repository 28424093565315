import { useQueryClient } from "@tanstack/react-query";
import { MenuItem } from "primereact/menuitem";
import React, { Dispatch, SetStateAction, useContext, useReducer, useRef, useState } from "react";
import { AppContext, useLabels } from "../../Store";
import RacunController from "../../controllers/naplata/RacunController";
import EnumUplatilacNaplata from "../../infrastructure/system/EnumUplatilacNaplata";
import MessageType from "../../infrastructure/system/MessageType";
import QueryKeys from "../../infrastructure/system/QueryKeys";
import { checkEmpty, createFileFromBase64, handleAxiosCallError } from "../../infrastructure/system/Utils";
import { useUplatilacList } from "../../infrastructure/system/hooks/enum/useUplatilacList";
import initialStateNaplata, { InitialStateNaplataType } from "../../infrastructure/system/hooks/naplata-wizard-reducer/initialStateNaplata";
import reducerNaplata, { NaplataActionType } from "../../infrastructure/system/hooks/naplata-wizard-reducer/reducerNaplata";
import { useSifarnikNacinPlacanjaList } from "../../infrastructure/system/hooks/sifarnici/nacin-placanja/useSifarnikNacinPlacanjaList";
import { useValutaList } from "../../infrastructure/system/hooks/sifarnici/valuta-list/useValutaList";
import useLogHighLevel from "../../infrastructure/system/hooks/useLogHighLevel";
import NaplataReadDto from "../../model/naplata/NaplataReadDto";
import RacunCreateDto from "../../model/naplata/RacunCreateDto";
import StavkaNaplate from "../../model/naplata/StavkaNaplate";
import SifarnikValutaDto from "../../model/repository/sifarnik-usluga/SifarnikValutaDto";
import EnumBaseReadDto from "../../model/sifarnik/EnumBaseReadDto";
import SifarnikNacinaPlacanjaReadDto from "../../model/sifarnik/sifarnik-nacina-placanja/SifarnikNacinPlacanjaReadDto";
import EnumFajlFormat from "../../infrastructure/system/EnumFajlFormat";
import RacunReadDto from "../../model/naplata/RacunReadDto";

interface NaplataLogicalType {
  state: InitialStateNaplataType;
  dispatch: Dispatch<NaplataActionType>;
  items: MenuItem[];
  updateStavkaValue: <T extends keyof StavkaNaplate>(field: T, itemId: number, value: StavkaNaplate[T], index: number, itemsType: "usluge" | "proizvodi") => void;
  onNextClickValidation: () => boolean | undefined;
  uplatilacList: EnumBaseReadDto[];
  racunData: NaplataReadDto | undefined;
  onCreateRacun: () => void;
  openPlacenRacunDialog: (id: number) => void;
  jumpToAccounts: () => Promise<void>;
  fastPayment: () => Promise<void>;
  showPlacenRacunDialog: boolean;
  setShowPlacenRacunDialog: Dispatch<SetStateAction<boolean>>;
  nextButtonDisabled: boolean;
  setNextButtonDisabled: Dispatch<SetStateAction<boolean>>;
  prevStepRef: any;
  invalidInputs: InvalidInputsType;
  setInvalidInputs: Dispatch<SetStateAction<InvalidInputsType>>;
  closeFastPaymentDialog: () => void;
  showFastPaymentDialog: boolean;
  setShowFastPaymentDialog: Dispatch<SetStateAction<boolean>>;
  createFinalRacun: (brziNacinPlacanja: SifarnikNacinaPlacanjaReadDto) => void;
  finalRacunLoading: boolean;
  setFinalRacunLoading: Dispatch<SetStateAction<boolean>>;
  nacinPlacanjaList: SifarnikNacinaPlacanjaReadDto[];
  nacinPlacanjaListIsLoading: boolean;
  finalnaValuta: string;
  valutaList: SifarnikValutaDto[];
  printRacun: (racuni?: RacunReadDto[]) => void;
  getRacuniForKontaktAndPrint: (id: number) => void;
}

interface NaplataContextProps {
  state: InitialStateNaplataType;
  dispatch: Dispatch<NaplataActionType>;
  updateStavkaValue: <T extends keyof StavkaNaplate>(field: T, itemId: number, value: StavkaNaplate[T], index: number, itemsType: "usluge" | "proizvodi") => void;
  nextButtonDisabled: boolean;
  setNextButtonDisabled: Dispatch<SetStateAction<boolean>>;
  invalidInputs: InvalidInputsType;
  setInvalidInputs: Dispatch<SetStateAction<InvalidInputsType>>;
  uplatilacList: EnumBaseReadDto[];
  nacinPlacanjaList: SifarnikNacinaPlacanjaReadDto[];
  nacinPlacanjaListIsLoading: boolean;
  finalnaValuta: string;
}

export interface InvalidInputsType {
  proizvodi: {
    invalidPercentages: number[];
    invalidSumPrices: number[];
    zeroPercentages: number[];
  };
  usluge: {
    invalidPercentages: number[];
    invalidSumPrices: number[];
    zeroPercentages: number[];
  };
  invalidPayments: number[];
}

export const NaplataContext = React.createContext({} as NaplataContextProps);

export default function NaplataLogical(): NaplataLogicalType {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI, authData } = useContext(AppContext);
  const queryClient = useQueryClient();
  const postLogHighLevel = useLogHighLevel();
  const [state, dispatch] = useReducer(reducerNaplata, initialStateNaplata);
  const { axiosGetUslugaCena, axiosGetProizvodCena } = RacunController();
  const [racunData, setRacunData] = useState<NaplataReadDto>();
  const [showPlacenRacunDialog, setShowPlacenRacunDialog] = useState<boolean>(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);
  const [showFastPaymentDialog, setShowFastPaymentDialog] = useState<boolean>(false);
  const [invalidInputs, setInvalidInputs] = useState<InvalidInputsType>({
    proizvodi: {
      invalidPercentages: [],
      zeroPercentages: [],
      invalidSumPrices: [],
    },
    usluge: {
      invalidPercentages: [],
      zeroPercentages: [],
      invalidSumPrices: [],
    },
    invalidPayments: [],
  });
  const [finalRacunLoading, setFinalRacunLoading] = useState<boolean>(false);
  const prevStepRef = useRef(undefined);
  const finalnaValuta = authData?.currentRadnik.finalnaValuta!;

  const { axiosGetRacunForKontakt, axiosCreateRacun, axiosPrintRacun } = RacunController();

  const { uplatilacList } = useUplatilacList();
  const { nacinPlacanjaList, isLoading: nacinPlacanjaListIsLoading } = useSifarnikNacinPlacanjaList();
  const { valutaList } = useValutaList();

  const items = [
    {
      label: Labels.LABEL_SPISAK_NAPLATA,
      className: state.step > 0 ? "visited" : "",
    },
    {
      label: Labels.LABEL_UPLATIOCI_NAPLATA,
      className: state.step > 1 ? "visited" : "",
    },
    {
      label: Labels.LABEL_RACUNI_NAPLATA,
      className: state.step > 2 ? "visited" : "",
    },
  ];

  const updateStavkaValue = <T extends keyof StavkaNaplate>(field: T, itemId: number, value: StavkaNaplate[T], index: number, itemsType: "usluge" | "proizvodi") => {
    dispatch({ type: "update_stavka_value", data: { field, itemId, value, index, itemsType } });
  };

  const onNextClickValidation = () => {
    if (state.step === 0 && state.selectedColumns.length === 0) {
      showMessage(MessageType.ERROR, Labels.LABEL_KONTAKT_REQUIRED_NAPLATA);
      return false;
    }

    if (state.step === 1) {
      if (!checkPercentageSum()) {
        showMessage(MessageType.ERROR, Labels.LABEL_ZBIR_PROCENATA_NAPLATA);
        return false;
      }
      if (!checkRequiredFields()) {
        showMessage(MessageType.ERROR, Labels.LABEL_UPLATIOCI_REQUIRED);
        return false;
      }
      if (!checkIsPercentageZero()) {
        showMessage(MessageType.ERROR, Labels.LABEL_PROCENAT_NULA_NAPLATA);
        return false;
      }
    }
    if (state.step === 2 && !checkSumRacunNacinPlacanja()) {
      showMessage(MessageType.ERROR, Labels.LABEL_IZNOS_RACUNA_REQUIRED);
      return false;
    }

    return true;
  };

  const checkPercentageSum = (): boolean => {
    const invalidPercentagesIdsUsluge = Object.keys(state.usluge).filter((key) => {
      const sum = state.usluge[parseInt(key)].reduce((acc, stavkaNaplate) => acc + stavkaNaplate.placeniProcenat, 0);
      return sum !== 100;
    });

    const invalidPercentagesIdsProizvodi = Object.keys(state.proizvodi).filter((key) => {
      const sum = state.proizvodi[parseInt(key)].reduce((acc, stavkaNaplate) => acc + stavkaNaplate.placeniProcenat, 0);
      return sum !== 100;
    });

    setInvalidInputs((prev: InvalidInputsType) => ({ ...prev, usluge: { ...prev.usluge, invalidPercentages: invalidPercentagesIdsUsluge.map((item) => parseInt(item)) } }));
    setInvalidInputs((prev: InvalidInputsType) => ({ ...prev, proizvodi: { ...prev.proizvodi, invalidPercentages: invalidPercentagesIdsProizvodi.map((item) => parseInt(item)) } }));
    return invalidPercentagesIdsProizvodi.concat(invalidPercentagesIdsUsluge).length === 0;
  };

  const checkIsPercentageZero = (): boolean => {
    const zeroPercentageIdsUsluge: number[] = [];
    const zeroPercentageIdsProizvodi: number[] = [];

    for (const key in state.usluge) {
      if (state.usluge.hasOwnProperty(key)) {
        const stavkaNaplatePair = state.usluge[key];
        for (const stavkaNaplate of stavkaNaplatePair) {
          if (stavkaNaplate.placeniProcenat === 0) {
            zeroPercentageIdsUsluge.push(parseInt(key));
            break;
          }
        }
      }
    }

    for (const key in state.proizvodi) {
      if (state.proizvodi.hasOwnProperty(key)) {
        const stavkaNaplatePair = state.proizvodi[key];
        for (const stavkaNaplate of stavkaNaplatePair) {
          if (stavkaNaplate.placeniProcenat === 0) {
            zeroPercentageIdsProizvodi.push(parseInt(key));
            break;
          }
        }
      }
    }
    setInvalidInputs((prev: InvalidInputsType) => ({ ...prev, usluge: { ...prev.usluge, zeroPercentages: zeroPercentageIdsUsluge } }));
    setInvalidInputs((prev: InvalidInputsType) => ({ ...prev, usluge: { ...prev.proizvodi, zeroPercentages: zeroPercentageIdsProizvodi } }));
    return zeroPercentageIdsUsluge.concat(zeroPercentageIdsProizvodi).length === 0;
  };

  const checkRequiredFields = (): boolean => {
    return Object.values(state.usluge)
      .concat(Object.values(state.proizvodi))
      .every((stavkaNaplatePair) =>
        stavkaNaplatePair.every(
          (stavkaNaplate) => !!stavkaNaplate.uplatilac && !!stavkaNaplate.placeniProcenat && (stavkaNaplate.uplatilac.sifra === EnumUplatilacNaplata.PARTNER ? !!stavkaNaplate.partner : true)
        )
      );
  };

  const checkSumRacunNacinPlacanja = () => {
    let isValid = true;
    state.racuni.forEach((racun, index) => {
      if (Math.round(racun.iznos * 1e6) / 1e6 !== Math.round(racun.racunNacinPlacanjaList.map((racunNacinPlacanja) => racunNacinPlacanja.iznos).reduce((acc, sum) => acc + sum, 0) * 1e6) / 1e6) {
        setInvalidInputs((prev: InvalidInputsType) => ({ ...prev, invalidPayments: [...prev.invalidPayments, index] }));
        isValid = false;
      }
    });
    return isValid;
  };
  
  const printRacun = async (racuni?: RacunReadDto[]) => {
    const pacijentRacunID = racuni?.find((racun) => racun?.uplatilac.sifra === EnumUplatilacNaplata.PACIJENT)?.id;
    const partnerRacunID = racuni?.find((racun) => racun?.uplatilac.sifra === EnumUplatilacNaplata.PARTNER)?.id;

    const params: { pacijentRacunID?: number; partnerRacunID?: number } = {};

    if (state.stampanje.pacijent && !checkEmpty(pacijentRacunID)) {
      params.pacijentRacunID = pacijentRacunID;
    }
    if (state.stampanje.partner && !checkEmpty(partnerRacunID)) {
      params.partnerRacunID = partnerRacunID;
    }

    if (Object.keys(params).length > 0) {
      setShowBlockUI(true);
      axiosPrintRacun(params)
        .then((res) => {
          const file = createFileFromBase64(EnumFajlFormat.PDF, res.data.data);
          const url = URL.createObjectURL(file);
          const w = window.open(url);
          if (w) {
            w!.document.write(
              "<html><head><title>" +
                Labels.LABEL_SPECIFIKACIJE_RACUNA_NAPLATA +
                "</title></head><body>" +
                '<embed width="100%" height="100%" name="plugin" src="' +
                url +
                '" ' +
                'type="application/pdf" internalinstanceid="21"></body></html>',
            );
            dispatch({ type: "reset_all" });
          } else {
            showMessage(MessageType.WARNING, Labels.LABEL_SPECIFIKACIJE_RACUNA_NAPLATA_WARNING_POPUP);
          }
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCreateRacun = () => {
    setShowBlockUI(true);
    axiosCreateRacun(state.racuni)
      .then((response) => {
        dispatch({ type: "reset_all" });
        showMessage(MessageType.SUCCESS, Labels.LABEL_RACUN_KREIRAN_SUCCESS);
        postLogHighLevel(Labels.LABEL_RACUN_KREIRAN_SUCCESS);
        queryClient.invalidateQueries({ queryKey: [QueryKeys.KONTAKT_NAPLATA_LIST] });

        if (state.stampanje.pacijent || state.stampanje.partner) {
          printRacun(response.data.data);
        }
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const openPlacenRacunDialog = (id: number) => {
    axiosGetRacunForKontakt(id)
      .then((response) => {
        setRacunData(response.data.data);
        setShowPlacenRacunDialog(true);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const calculateAllExpense = async () => {
    const selectedColumns = state?.selectedColumns;

    let stavkeNaplateUsluge: StavkaNaplate[] = [];
    let stavkeNaplateProizvodi: StavkaNaplate[] = [];

    for (const kontakt of selectedColumns) {
      for (const kontaktUsluga of kontakt.kontaktUslugaList) {
        try {
          const response = await axiosGetUslugaCena(kontaktUsluga.sifarnikUsluga.id, { datumPruzanja: kontakt.vremeOd as string });
          const cenaUsluge = response.data.data?.uslugaCena?.iznosLocalCurrency;
          const brojPruzanja = kontaktUsluga.brojPruzanja;
          const stavkaNaplate: StavkaNaplate = {
            uplatilac: uplatilacList[0],
            placeniProcenat: 100,
            napomena: "",
            kontaktUsluga: kontaktUsluga,
            kolicina: brojPruzanja,
            kontakt: kontakt,
            uslugaCena: response.data.data?.uslugaCena,
            iznosBezPacijentPopusta: cenaUsluge * brojPruzanja,
            iznos: cenaUsluge * brojPruzanja,
          };
          stavkeNaplateUsluge.push(stavkaNaplate);
        } catch (error) {
          handleAxiosCallError(showMessage, error);
        }
      }
      for (const kontaktProizvod of kontakt.kontaktProizvodList) {
        try {
          const response = await axiosGetProizvodCena(kontaktProizvod.sifarnikProizvoda.id, kontakt.vremeOd as string);
          const cenaProizvoda = response.data.data?.iznosLocalCurrency;
          const utrosenaKolicina = kontaktProizvod.utrosenaKolicina;
          const stavkaNaplate: StavkaNaplate = {
            uplatilac: uplatilacList[0],
            placeniProcenat: 100,
            napomena: "",
            kontaktProizvod: kontaktProizvod,
            kolicina: utrosenaKolicina,
            proizvodCena: response.data.data,
            iznosBezPacijentPopusta: cenaProizvoda * utrosenaKolicina,
            iznos: cenaProizvoda * utrosenaKolicina,
            kontakt: kontakt,
          };
          stavkeNaplateProizvodi.push(stavkaNaplate);
        } catch (error) {
          handleAxiosCallError(showMessage, error);
        }
      }
    }

    const stavkaNaplateList = stavkeNaplateUsluge.concat(stavkeNaplateProizvodi);

    const racun: RacunCreateDto = {
      stavkaNaplateList: stavkaNaplateList,
      racunNacinPlacanjaList: [],
      pacijent: selectedColumns[0].pacijent,
      valuta: valutaList.find((valuta) => valuta.sifra === finalnaValuta)!,
      napomena: "",
      iznos: stavkaNaplateList.map((stavkaNaplate) => stavkaNaplate.iznos).reduce((sum, acc) => sum + acc, 0),
    };
    return racun;
  };

  const jumpToAccounts = async () => {
    await calculateAllExpense().then((res) => {
      dispatch({ type: "jump_to_step", data: { step: 2, racun: res } });
    });
  };

  const fastPayment = async () => {
    onCreateRacun();
    setShowFastPaymentDialog(false);
  };

  const closeFastPaymentDialog = () => {
    setShowFastPaymentDialog(false);
  };

  const createFinalRacun = (brziNacinPlacanja: SifarnikNacinaPlacanjaReadDto) => {
    calculateAllExpense().then((res) => {
      dispatch({ type: "set_racun", data: [res] });
      dispatch({ type: "set_nacin_placanja", data: { index: 0, racunNacinPlacanja: { sifarnikNacinPlacanja: brziNacinPlacanja, iznos: res.iznos } } });
      setFinalRacunLoading(false);
    });
  };

  const getRacuniForKontaktAndPrint = async (id: number) => {
    try {
      const response = await axiosGetRacunForKontakt(id);
      const racuni = response.data.data?.racunList;
      printRacun(racuni);
    } catch (error) {
      handleAxiosCallError(showMessage, error);
    }
  };

  return {
    state,
    dispatch,
    items,
    updateStavkaValue,
    onNextClickValidation,
    uplatilacList,
    racunData,
    onCreateRacun,
    openPlacenRacunDialog,
    jumpToAccounts,
    fastPayment,
    showPlacenRacunDialog,
    setShowPlacenRacunDialog,
    nextButtonDisabled,
    setNextButtonDisabled,
    prevStepRef,
    invalidInputs,
    setInvalidInputs,
    closeFastPaymentDialog,
    showFastPaymentDialog,
    setShowFastPaymentDialog,
    createFinalRacun,
    finalRacunLoading,
    setFinalRacunLoading,
    nacinPlacanjaList,
    nacinPlacanjaListIsLoading,
    finalnaValuta,
    valutaList,
    printRacun,
    getRacuniForKontaktAndPrint,
  };
}
