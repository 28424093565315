import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { formatDate, setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import SkeletonCheckboxItem from "../../app/skeleton/SkeletonCheckboxItem";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CalendarComponent from "../calendar-component/CalendarComponent";
import CrudSifarnikUslugaLogical from "./CrudSifarnikUslugaLogical";
import SifarnikUslugaCeneListView from "./cene/SifarnikUslugaCeneListView";
import SifarnikUslugaSpecijalizacijeListView from "./specijalizacije/SifarnikUslugaSpecijalizacijeListView";

export default function CrudSifarnikUsluga() {
  const {
    sifarnikUslugaOperation,
    isDisabled,
    sifarnikUslugaChange,
    breadCrumbItems,
    setSifarnikUslugaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    sifarnikUslugaId,
    location,
    sifarnikUslugaRFZOList,
    sifarnikUslugaLoading,
    sifarnikUslugaRFZOListLoading,
    invalidFields,
    setInvalidFields,
    vaziOdRef,
    vaziDoRef,
  } = CrudSifarnikUslugaLogical();
  const Labels = useLabels();
  const { pristup, nadjimed } = useContext(AppContext);

  if (!sifarnikUslugaId && !location.state) {
    return <Navigate to="/sifarnik-usluga-list" />;
  }

  const leftContentsToolbar = () => (
    <>
      {sifarnikUslugaOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          disabled={!pristup || sifarnikUslugaRFZOListLoading}
          tooltip={Labels.SIFARNIK_USLUGA_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {sifarnikUslugaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || sifarnikUslugaLoading || sifarnikUslugaRFZOListLoading}
          icon="pi pi-pencil"
          tooltip={Labels.SIFARNIK_USLUGA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {sifarnikUslugaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || sifarnikUslugaLoading || sifarnikUslugaRFZOListLoading}
          icon="pi pi-trash"
          tooltip={Labels.SIFARNIK_USLUGA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_SIFARNIK_USLUGA}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_USLUGA_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={sifarnikUslugaChange?.naziv ?? ""}
                        onChange={(e) => {
                          setSifarnikUslugaChange({
                            ...sifarnikUslugaChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", sifarnikUslugaChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_USLUGA_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={sifarnikUslugaChange?.sifra ?? ""}
                        onChange={(e) => {
                          setSifarnikUslugaChange({
                            ...sifarnikUslugaChange!,
                            sifra: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", sifarnikUslugaChange?.sifra);
                        }}
                        className={invalidFields?.sifra && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_USLUGA_RFZO}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaRFZOListLoading && !sifarnikUslugaLoading ? (
                    <Dropdown
                      disabled={!pristup || isDisabled}
                      options={sifarnikUslugaRFZOList}
                      optionLabel="naziv"
                      optionValue="id"
                      filter
                      showClear
                      value={sifarnikUslugaChange?.idRepositorySifarnikUslugaRFZO ?? ""}
                      onChange={(e) => {
                        setSifarnikUslugaChange({
                          ...sifarnikUslugaChange!,
                          idRepositorySifarnikUslugaRFZO: sifarnikUslugaRFZOList!.find((x) => x.id === e.target.value)?.id,
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_USLUGA_NAZIV_ENGLESKI}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={sifarnikUslugaChange?.nazivEngleski ?? ""}
                      onChange={(e) => {
                        setSifarnikUslugaChange({
                          ...sifarnikUslugaChange!,
                          nazivEngleski: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_USLUGA_OPIS}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={sifarnikUslugaChange?.opis ?? ""}
                      onChange={(e) => {
                        setSifarnikUslugaChange({
                          ...sifarnikUslugaChange!,
                          opis: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_USLUGA_VAZI_OD}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <>
                      <CalendarComponent
                        ref={vaziOdRef}
                        value={sifarnikUslugaChange?.vaziOd}
                        onChange={(e: CalendarChangeEvent) => {
                          setSifarnikUslugaChange({
                            ...sifarnikUslugaChange!,
                            vaziOd: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                          });
                          setInvalidFields((prev) => ({ ...prev, vaziOd: false }));
                          if (e.target.value) vaziOdRef?.current?.hide();
                        }}
                        disabled={!pristup || isDisabled}
                        onClearButtonClick={() => {
                          setInvalidFields((prev) => ({ ...prev, vaziOd: true }));
                          setSifarnikUslugaChange({
                            ...sifarnikUslugaChange!,
                            vaziOd: undefined,
                          });
                        }}
                        className={"w-full " + ((invalidFields?.vaziOd || invalidFields?.invalidPeriod) && "p-invalid")}
                        placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                      />
                      {invalidFields?.vaziOd && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_USLUGA_VAZI_DO}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikUslugaLoading ? (
                    <>
                      <CalendarComponent
                        ref={vaziDoRef}
                        value={sifarnikUslugaChange?.vaziDo}
                        onChange={(e: CalendarChangeEvent) => {
                          setSifarnikUslugaChange({
                            ...sifarnikUslugaChange!,
                            vaziDo: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                          });
                          setInvalidFields((prev) => ({ ...prev, invalidPeriod: false }));
                          if (e.target.value) vaziDoRef?.current?.hide();
                        }}
                        disabled={!pristup || isDisabled}
                        className={"w-full " + (invalidFields?.invalidPeriod && "p-invalid")}
                        placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                      />
                      {invalidFields?.invalidPeriod && <ObaveznoPolje text={Labels.SIFARNIK_USLUGA_VAZI_OD_IS_BEFORE_VAZI_DO} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                {nadjimed && (
                  <>
                    <div className="col-12 px-0 pb-2 pt-4 sm:col-4 sm:col-4 sm:px-3">
                      <div>{Labels.SIFARNIK_USLUGA_DOSTUPNA_ZA_EKSTERNE_ZAKAZIVACE}</div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      {!sifarnikUslugaLoading ? (
                        <Checkbox
                          checked={sifarnikUslugaChange?.dostupanZaEksterneZakazivace ?? false}
                          onChange={(e) =>
                            setSifarnikUslugaChange({
                              ...sifarnikUslugaChange!,
                              dostupanZaEksterneZakazivace: e.checked,
                            })
                          }
                        />
                      ) : (
                        <SkeletonCheckboxItem />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Toolbar start={leftContentsToolbar} end={rightContentsToolbar}></Toolbar>
          </TabPanel>
          <TabPanel header={Labels.SIFARNIK_PROIZVODA_CENE_LABEL} disabled={sifarnikUslugaOperation === EntityOperation.CREATE}>
            <SifarnikUslugaCeneListView />
          </TabPanel>
          <TabPanel header={Labels.SIFARNIK_USLUGA_SPECIJALIZACIJE} disabled={sifarnikUslugaOperation === EntityOperation.CREATE}>
            <SifarnikUslugaSpecijalizacijeListView usluga={sifarnikUslugaChange?.naziv} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
