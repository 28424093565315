import axios from "axios";
import { useContext, useEffect, useReducer, useRef } from "react";
import { AppContext } from "../../../Store";
import { axiosConfig, handleAxiosCallError } from "../Utils";

interface State<T> {
  data?: T;
  error?: any;
}

type Cache<T> = { [url: string]: T };

// discriminated union type
type Action<T> = { type: "loading" } | { type: "fetched"; payload: T } | { type: "error"; payload: any };

/**
 * Custom hook for fetching data trough API's url
 *
 * @param url API's url
 * @param params API's params (optional)
 * @returns data if API call was successful, Error object if it wasnt
 */
function useFetch<T = unknown>(url?: string, params?: Object): State<T> {
  const cache = useRef<Cache<T>>({});
  const { authData, showMessage } = useContext(AppContext);

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false);

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
  };

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case "loading":
        return { ...initialState };
      case "fetched":
        return { ...initialState, data: action.payload };
      case "error":
        return { ...initialState, error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    // Do nothing if the url is not given
    if (!url) return;

    cancelRequest.current = false;

    const fetchData = async () => {
      dispatch({ type: "loading" });

      // If a cache exists for this url, return it
      if (cache.current[url]) {
        dispatch({ type: "fetched", payload: cache.current[url] });
        return;
      }

      axios
        .get(url, axiosConfig(authData!.token, params))
        .then((res: any) => {
          cache.current[url] = res.data.data;
          if (cancelRequest.current) return;

          dispatch({ type: "fetched", payload: res.data.data });
        })
        .catch((error: any) => {
          if (cancelRequest.current) return;
          handleAxiosCallError(showMessage, error);
          dispatch({ type: "error", payload: error });
        });
    };

    void fetchData();

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return state;
}

export default useFetch;
