import moment from "moment";
import { addLocale, locale } from "primereact/api";
import { EffectCallback, useEffect } from "react";
import OrganizacionaJedinicaCvorDto from "../../model/organizacionaJedinica/OrganizacionaJedinicaCvorDto";
import EntityOperation from "./EnumEntityOperation";
import EnumJezik from "./EnumJezik";
import EnumTipKomponente from "./EnumTipKomponente";
import MessageType from "./MessageType";
import PacijentReadDto from "../../model/pacijent/PacijentReadDto";
import RadnikReadDto from "../../model/radnik/RadnikReadDto";
import EnumInicijalizacijaKorak from "./EnumInicijalizacijaKorak";
import { RadnikUlogaHolder } from "../../components/administracija/radnik/uloga/CrudRadnikUlogaLogical";
import EnumRola from "./EnumRola";
import EnumFajlFormat from "./EnumFajlFormat";

const LOCAL_STORAGE_AUTH_KEY = "heliant-cloud-app-auth";

const useEffectOnce = (effect: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

const tooltipOptionsTop: any = { position: "top", hideDelay: 50 };
const tooltipOptionsBottom: any = { position: "bottom" };
const tooltipOptionsRight: any = { position: "right" };

const isPublicRoute = (route: string) => {
  return route.startsWith("/public") || route.startsWith("/auth");
};

const stringChecker = (s: string | undefined | null) => {
  return s ? s : "";
};

const axiosConfig = (token: string, params?: Object, responseType?: Object) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: params ? params : {},
    responseType: responseType ? (responseType as "text") : undefined,
  };
};

const hrefForDownloadAttachment = (fileType: string, base64Attachment: string) => {
  return "data:" + fileType + ";base64," + base64Attachment;
};

const isFormDisabled = (operation: string) => {
  let returnValue = true;

  if (!operation) return true;

  switch (operation) {
    case EntityOperation.CREATE:
    case EntityOperation.UPDATE:
      returnValue = false;
      break;
    case EntityOperation.READ:
    case EntityOperation.DELETE:
      returnValue = true;
      break;
    default:
      returnValue = true;
      break;
  }

  return returnValue;
};

const isDropDownFieldDisabled = (operation: string) => {
  let returnValue = true;

  if (!operation) return true;

  switch (operation) {
    case EntityOperation.CREATE:
      returnValue = false;
      break;
    case EntityOperation.READ:
    case EntityOperation.DELETE:
    case EntityOperation.UPDATE:
      returnValue = true;
      break;
    default:
      returnValue = true;
      break;
  }

  return returnValue;
};

const isCreateForm = (operation: string) => operation === EntityOperation.CREATE;

const dateAndTime = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_TIME_FORMAT);
  } else {
    return "Lose zadat datum";
  }
};

const date = (UTCDate: string) => {
  return UTCDate ? moment(UTCDate).format(DATE_FORMAT) : "";
};

const dateLocalDate = (LocalDate: string | undefined) => {
  return LocalDate ? moment(LocalDate).format(DATE_FORMAT) : "";
};

const time = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(TIME_FORMAT_2);
  } else {
    return "Lose zadat datum";
  }
};

const dateTimeBefore = (date: any, dateNow: any, Labels: any) => {
  const now = moment(dateNow);
  const durationAsMinutes = moment.duration(now.diff(date)).asMinutes();
  if (durationAsMinutes < 60) {
    return Labels.LABEL_BEFORE + " " + Math.floor(durationAsMinutes + 1) + " " + Labels.LABEL_MINUTES;
  } else if (durationAsMinutes < 24 * 60) {
    return Labels.LABEL_BEFORE + " " + Math.floor(durationAsMinutes / 60) + " " + Labels.LABEL_HOURS;
  } else {
    return moment(date).format(DATE_TIME_FORMAT);
  }
};

const imageExtention = (base64: string) => {
  if (base64) {
    switch (base64.charAt(0)) {
      case "/":
        return "jpg";
      case "i":
        return "png";
      case "R":
        return "gif";
      case "U":
        return "webp";
    }
  }
};

const getImage = (needUri: boolean, source: string, replaceImage: any) => {
  return source ? (needUri ? { uri: `data:image/${imageExtention(source)};base64,${source}` } : `data:image/${imageExtention(source)};base64,${source}`) : replaceImage;
};

const showActivityDuration = (seconds: number) => {
  return seconds ? new Date(seconds * 1000).toISOString().substr(11, 8) : "";
};

const handleAxiosCallSuccess = (showMessage: Function, response: any) => {
  if (response.data && response.data.data.successes) {
    showMessage(
      MessageType.SUCCESS,
      undefined,
      response.data.data.successes.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.SUCCESS, undefined, response.toString());
  }
};

const handleAxiosCallWarning = (showMessage: Function, warnings: any) => {
  if (warnings) {
    showMessage(
      MessageType.WARNING,
      undefined,
      warnings.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.ERROR, undefined, warnings.toString());
  }
};

const handleAxiosCallError = (showMessage: Function, error: any) => {
  if (error.response && error.response.data.errors) {
    error.response.data.errors.length = 5;
    showMessage(
      MessageType.ERROR,
      undefined,
      error.response.data.errors.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.ERROR, undefined, error.toString());
  }
};

const getFieldValue = (field: any) => {
  if (field) {
    switch (field?.pacijentPolje?.poljeTip?.tipKomponenteEnum?.sifra) {
      case EnumTipKomponente.TEXT:
      case EnumTipKomponente.LONG_TEXT:
        return field.vrednostString?.length < 25 ? field.vrednostString : `${field.vrednostString?.substring(0, 25)}...`;
      case EnumTipKomponente.BOOLEAN:
        return field.vrednostBoolean;
      case EnumTipKomponente.NUMBER_INTEGER:
      case EnumTipKomponente.NUMBER_DECIMAL:
        return field.vrednostNumber;
      case EnumTipKomponente.DATE_TIME:
        return field.vrednostNumber;
      case EnumTipKomponente.SET:
        return field.vrednostEnumeratorItem?.naziv;
      case EnumTipKomponente.SET_RADIO:
        return field.vrednostEnumeratorItem?.naziv;
      default:
        return null;
    }
  }
};

const hexToRGB = (hex: any, alpha: any) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

const jezikList = (Labels?: any) => [
  { id: EnumJezik.SR_RS_LATN, name: Labels.SR_RS_LATN },
  { id: EnumJezik.SR_RS, name: Labels.SR_RS },
];

const isSifraIncluded = (itemList: any, sifra: any) => itemList?.some((x: any) => x.sifra === sifra);

const meterToKilometer = (value: number, decimal: number) => {
  return Number((value / 1000).toFixed(decimal));
};

const skeletonTimeout = (setLoadingState: React.Dispatch<React.SetStateAction<boolean>>, startTime: moment.Moment, miliseconds: number = 500) => {
  if (moment(new Date()).diff(startTime) < miliseconds) {
    setTimeout(() => {
      setLoadingState(false);
    }, miliseconds);
  } else {
    setLoadingState(false);
  }
};
// Ova funkcija postavlja Array<OrganizacionaJedinicaCvorDto> koji se koristi za OrgJedNavbar,
// prvi parametaer setOdabranaOrgJed  je sama funkcija za setovanje niza org.jed u navbar-u
// drugi parametar currentSelectionArray je trenutna vrednost niza cvorova tj organizacionih jedinica navbara
// treci parametar newSelection je selektovan cvor(u dropbox-u kada se selektuje cvor)
// cetvrti parametar currentPosition govori na kom nivou stabla je selektovan cvor/OrganizacionaJedinica
const selectOrganizacionaJedinica = (currentSelectionArray: Array<OrganizacionaJedinicaCvorDto>, newSelection: OrganizacionaJedinicaCvorDto, currentPosition?: number) => {
  let tempSelectionArray: Array<OrganizacionaJedinicaCvorDto> = currentPosition ? [...currentSelectionArray.slice(0, currentPosition)] : [...currentSelectionArray];
  let tempSelection: OrganizacionaJedinicaCvorDto = newSelection;
  while (tempSelection.podredjeneOrganizacioneJedinice.length === 1) {
    tempSelectionArray.push(tempSelection);
    tempSelection = tempSelection.podredjeneOrganizacioneJedinice[0];
  }
  tempSelectionArray.push(tempSelection);
  return tempSelectionArray;
};

const operatorNumber = [
  { id: 1, name: ">" },
  { id: 2, name: "<" },
  { id: 3, name: "=" },
  { id: 4, name: "!=" },
  { id: 5, name: "<=" },
  { id: 6, name: ">=" },
];

const operatorText = (Labels: any) => [
  { id: 1, name: "=" },
  { id: 2, name: "!=" },
  { id: 3, name: Labels.CONTAIS },
  { id: 4, name: Labels.NOT_CONTAIS },
  { id: 5, name: Labels.START_WITH },
  { id: 6, name: Labels.END_WITH },
];

const operatorSet = (Labels: any) => [
  { id: 1, name: Labels.CHOOSE },
  { id: 2, name: Labels.NOT_CHOOSE },
];

const DATE_TIME_FORMAT = "DD.MM.YYYY. HH:mm";
const DATE_TIME_FORMAT_FULL = "DD.MM.YYYY. HH:mm:ss";
const DATE_FORMAT = "DD.MM.YYYY.";
const DATE_FORMAT_2 = "YYYY-MM-DD";
const DATE_FORMAT_3 = "MM.DD.YYYY.";
const CALENDAR_DATE_FORMAT = "dd.mm.yy.";
const DATE_TIME_FORMAT_2 = "YYYY-MM-DD HH:mm:ss";
const DATE_TIME_FORMAT_3 = "YYYY-MM-DDTHH:mm:ss";
const DATE_TIME_FORMAT_4 = "YYYY-MM-DDT00:00:00";
const DATE_TIME_FORMAT_5 = "DD.MM.YY HH:mm";
const DATE_TIME_FORMAT_6 = "YYYY-MM-DD[T]HH:mm:ss.SSS";
const DAY_DATE_FORMAT = "dddd D. MMMM YYYY.";
const DAY_IN_WEEK_FORMAT = "dddd";
const SHORT_MONTH_NAME_FORMAT = "MMM";
const TIME_FORMAT = "HH:mm";
const TIME_FORMAT_2 = "h:mm";
const TIME_FORMAT_3 = "HH:mm:ss.SSS";
const TIME_FORMAT_SECONDS_0 = "HH:mm:00";
const HOUR_FORMAT = "HH";
const FORM_CCF = "pmt";
const FORM_DEATH_REPORT = "pul";
const MAX_FILE_UPLOAD = 5242880;
const MAX_PORUKA_DATOTEKA_LIST_SIZE = 1024 * 1024 * 25;
const ODABRANA_ORGANIZACIONA_JEDINICA_STORAGE_ITEM = "odabrana-organizaciona-jedinica";
const NESACUVAN_NALAZ = "nesacuvan-nalaz";
const PERIOD_FORMAT = "YYYY/MMMM";
const RETRY_WITH_RESPONSE_CODE = 449;
const WARNING_CODE = 230;

const createCurrentDateWithTime = (timeHH_MM: string) => {
  const currentDate = new Date();
  currentDate.setHours(Number(timeHH_MM.substring(0, 2)));
  currentDate.setMinutes(Number(timeHH_MM.substring(3, 5)));
  return currentDate;
};
const getDateDivisibleBy5 = (date: Date = new Date()) => new Date(date?.setMinutes(Math.ceil(date?.getMinutes() / 5) * 5));

const getHoursFromTime = (time: string) => {
  const hours = time.substring(0, 2);
  return isNaN(Number(hours)) || time === "" ? undefined : Number(hours);
};

const getMinutesFromTime = (time: string) => {
  const minutes = time.substring(3, 5);
  return isNaN(Number(minutes)) || time === "" ? undefined : Number(minutes);
};

function setTimeToDate(dateString: Date, timeString: string) {
  const existingDate = new Date(dateString);
  const [hours, minutes] = timeString.split(":");

  existingDate.setHours(parseInt(hours));
  existingDate.setMinutes(parseInt(minutes));
  existingDate.setSeconds(0);

  return existingDate;
}

const addSrLocal = () => {
  addLocale("sr", {
    firstDayOfWeek: 1,
    dayNames: ["Nedelja", "Ponedeljak", "Utorak", "Sreda", "Četvrtak", "Petak", "Subota"],
    dayNamesShort: ["Ned", "Pon", "Uto", "Sre", "Čet", "Pet", "Sub"],
    dayNamesMin: ["N", "P", "U", "S ", "Č", "P ", "S"],
    monthNames: ["Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
    weekHeader: "Sedmica",
    //firstDay: 1,
    //isRTL: false,
    //showMonthAfterYear: false,
    //yearSuffix: "",
    //timeOnlyTitle: "Samo Vreme",
    //timeText: "Vreme",
    // hourText: "Sat",
    // minuteText: "Minuta",
    // secondText: "Sekunda",
    //currentText: "Danas",
    //ampm: false,
    // month: "Mesec",
    // week: "sedmica",
    // day: "Dan",
    // allDayText: "Celi Dan",
    clear: "Poništi",
    today: "Danas",
  });
  addLocale("sr-cyrilic", {
    firstDayOfWeek: 1,
    dayNames: ["Недеља", "Понедељак", "Уторак", "Среда", "Четвртак", "Петак", "Субота"],
    dayNamesShort: ["Нед", "Пон", "Уто", "Сре", "Чет", "Пет", "Суб"],
    dayNamesMin: ["Н", "П", "У", "С ", "Ч", "П ", "С"],
    monthNames: ["Јануар", "Фебруар", "Март", "Април", "Мај", "Јун", "Јул", "Август", "Септембар", "Октобар", "Новембар", "Децембар"],
    monthNamesShort: ["Јан", "Феб", "Мар", "Апр", "Мај", "Јун", "Јул", "Авг", "Сеп", "Окт", "Нов", "Дец"],
    weekHeader: "Седмица",
    //firstDay: 1,
    //isRTL: false,
    //showMonthAfterYear: false,
    //yearSuffix: "",
    //timeOnlyTitle: "Само време",
    //timeText: "Време",
    // hourText: "Сат",
    // minuteText: "Минута",
    // secondText: "Секунда",
    //currentText: "Данас",
    //ampm: false,
    // month: "Месец",
    // week: "седмица",
    // day: "Дан",
    // allDayText: "Цели Дан",
    clear: "Поништи",
    today: "Данас",
  });
  locale("sr");
  locale("sr-cyrilic");
};

const formatPeriod = (LocalDate: Date) => {
  return LocalDate ? moment(LocalDate).format(PERIOD_FORMAT) : "";
};

const formatDate = (dateValue?: Date, format = DATE_TIME_FORMAT_3) => (dateValue ? moment(new Date(dateValue)).format(format) : undefined);

const formatDate2 = (dateValue?: Date, format = DATE_FORMAT_2) => (dateValue ? moment(new Date(dateValue)).format(format) : "");

const terminStatusFieldType = ["scheduled", "confirmed", "arrived", "started", "finished", "reserved", "no_show"];

const MINIMAL_PANEL_WIDTH_FOR_PACIJENT_INFO = 1200;
const WIDTH_FOR_MOBILE_DEVICE = 750;
const handleScreenSizeChange = (ref: any, panelWidth: number) => {
  return ref?.current?.clientWidth! > panelWidth;
};

const radnoVremeOrgJediniceGlobal = {
  radnoVremeHOd: 7,
  radnoVremeHDo: 20,
};

const formatHour = (hour: number) => {
  return hour.toLocaleString("sr-RS", { minimumIntegerDigits: 2 });
};

const validateStringEmpty = (str: string | undefined) => !str || str.trim() === "";

const validEmailRegex: RegExp = /^(?=.{1,64})[A-Za-z0-9\\+_-]+(\.[A-Za-z0-9\\+_-]+)*@[A-Za-z0-9-+]+(\.[A-Za-z0-9-+]+)*(\.[A-Za-z]{2,})$/;
const isEmailFormatValid = (email: string | undefined) => {
  if (email?.match(validEmailRegex)) {
    return true;
  }
  return false;
};

function isTelefonValid(telefon: string | undefined) {
  const validPhoneRegex: RegExp = /^\+?\d+$/;
  return telefon?.match(validPhoneRegex) ? true : false;
}

const isDateTimeFormatValid = (str?: string) => {
  const validDateTimeRegex: RegExp = /^(0?[1-9]|[1-2][0-9]|3[0-1])\.(0?[1-9]|1[0-2])\.\d{4}\. (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;
  return str?.match(validDateTimeRegex);
};

const isDateFormatValid = (str: string = "") => {
  const validDateRegex: RegExp = /^(0?[1-9]|[1-2][0-9]|3[0-1])\.(0?[1-9]|1[0-2])\.\d{4}\.$/;
  return validDateRegex.test(str);
};

const handleKeyboardTimeInputChange = (
  value: string,
  field: string,
  state: any,
  setState: React.Dispatch<React.SetStateAction<any>>,
  ref?: HTMLDivElement | any,
  maxDate?: Date,
  minDate?: Date,
  timeFormat = true,
  divisibleBy5 = true,
  formatDateNeeded = false
) => {
  if (/^(0?[1-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
    const [hours, minutes] = value.split(":");
    const newDate = new Date();
    newDate.setHours(parseInt(hours, 10));
    newDate.setMinutes(parseInt(minutes, 10));
    let finalDate: Date | undefined | string = divisibleBy5 ? getDateDivisibleBy5(newDate) : newDate;
    finalDate = formatDateNeeded ? formatDate(newDate) : newDate;
    if (minDate && maxDate && newDate > minDate && newDate < maxDate) {
      setState({
        ...state!,
        [field]: timeFormat ? moment(finalDate).format(TIME_FORMAT) : finalDate,
      });
      ref?.current?.hide();
    }
    if (minDate && !maxDate && newDate > minDate) {
      setState({
        ...state!,
        [field]: timeFormat ? moment(finalDate).format(TIME_FORMAT) : finalDate,
      });
      ref?.current?.hide();
    }
    if (!minDate && maxDate && newDate < maxDate) {
      setState({
        ...state!,
        [field]: timeFormat ? moment(finalDate).format(TIME_FORMAT) : finalDate,
      });
      ref?.current?.hide();
    }
    if (!minDate && !maxDate) {
      setState({
        ...state!,
        [field]: timeFormat ? moment(finalDate).format(TIME_FORMAT) : finalDate,
      });
      ref?.current?.hide();
    }
  }
};

const STEP_MINUTE = 5;

const checkEmpty = (variable: any) => {
  if (variable === undefined || variable === null || variable === "") {
    return true;
  }
  return false;
};

const MESSAGE_DURATION_IN_MILLISECONDS = 4000;

/**
 * metoda za formatiranje brojeva,
 * pored samog broja kao argumente prihvata min i max fraction digits vezano za decimale
 * source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
const numberFormat = (value: number, minimumFractionDigits: number, maximumFractionDigits: number) =>
  new Intl.NumberFormat("sr-RS", {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);

const getErrorMessage = (error: any) => {
  return error.response && error.response.data.errors ? error.response.data.errors.map((e: any) => e.message + "\n") : error.toString();
};

const tableDateFilter = (value: string, filter: Date, filterType?: string) => {
  if (filter) {
    const valueDateObj = new Date(value);
    const valueDate = valueDateObj?.toLocaleString().split(",")[0];
    const filterDate = filter?.toLocaleString().split(",")[0];
    if (filterType === undefined) {
      return valueDate === filterDate;
    } else if (filterType === "less") {
      return new Date(filterDate) <= new Date(valueDate);
    } else if (filterType === "greater") {
      return new Date(filterDate) >= new Date(valueDate);
    }
  }
  return true;
};
const tableDateFilterLess = (value: string, filter: Date) => {
  return tableDateFilter(value, filter, "less");
};
const tableDateFilterGreater = (value: string, filter: Date) => {
  return tableDateFilter(value, filter, "greater");
};

function stripHTML(value: string) {
  let el = document.createElement("div");
  el.innerHTML = value;
  return el.textContent || el.innerText || "";
}

const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

const removeAllMasks = () => {
  let arr = document.getElementsByClassName("p-datepicker-mask");
  for (let i = 0; i < arr.length; i++) {
    arr[i].classList.add("hidden");
  }
};

const setInvalid = (setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>, field: string, value: any) => {
  if (!value || value === "") {
    setInvalidFields((prev: any) => ({ ...prev, [field]: true }));
  }
  if (value && value !== "") {
    setInvalidFields((prev: any) => ({ ...prev, [field]: false }));
  }
};

const formatPrice = (price: number) => {
  return price?.toLocaleString("de-DE", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 6 });
};

/**
 *
 * @param cyrText - string
 *
 * najprostija moguca metoda/konverter za cirilica -> latinica potrebe
 */
const cyr_to_lat = (cyrText: string) => {
  const cyr: Object | any = {
    А: "A",
    Б: "B",
    В: "V",
    Г: "G",
    Д: "D",
    Ђ: "Đ",
    Е: "E",
    Ж: "Ž",
    З: "Z",
    И: "I",
    Ј: "J",
    К: "K",
    Л: "L",
    Љ: "Lj",
    М: "M",
    Н: "N",
    Њ: "Nj",
    О: "O",
    П: "P",
    Р: "R",
    С: "S",
    Т: "T",
    Ћ: "Ć",
    У: "U",
    Ф: "F",
    Х: "H",
    Ц: "C",
    Ч: "Č",
    Џ: "Dz",
    Ш: "Š",
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    ђ: "đ",
    е: "e",
    ж: "ž",
    з: "z",
    и: "i",
    ј: "j",
    к: "k",
    л: "l",
    љ: "lj",
    м: "m",
    н: "n",
    њ: "nj",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    ћ: "ć",
    у: "u",
    ф: "f",
    х: "h",
    ц: "c",
    ч: "č",
    џ: "dž",
    ш: "š",
  };
  return cyrText
    .split("")
    .map((char: string) => cyr[char] || char)
    .join("");
};

const showPatientFullName = (patient: PacijentReadDto) => {
  return patient?.ime + " " + (patient?.imeRoditelja ? "(" + patient?.imeRoditelja + ")" : "") + " " + patient.prezime;
};

const isInicijalizacija = (radnik: RadnikReadDto) => {
  const radnikRolaList = radnik?.radnikUlogaList.map((radnikUloga: RadnikUlogaHolder) => radnikUloga?.uloga?.rola);
  const inicijalizacijaKorak = radnik?.ustanovaPoslednjaIzabrana?.inicijalizacijaKorak;
  return inicijalizacijaKorak?.sifra !== EnumInicijalizacijaKorak.INICIJALIZOVANA && isSifraIncluded(radnikRolaList, EnumRola.ADMIN);
};

const createFileFromBase64 = (type: string, base64: string) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  let blob;
  switch (type) {
    case EnumFajlFormat.PDF:
      blob = new Blob([bytes], { type: "application/pdf" });
      break;
    case EnumFajlFormat.TXT:
      blob = new Blob([bytes], { type: "text/plain" });
      break;
    case EnumFajlFormat.CSV:
      blob = new Blob([bytes], { type: "text/csv" });
      break;
    case EnumFajlFormat.XLS:
      blob = new Blob([bytes], { type: "application/xls" });
      break;
    case EnumFajlFormat.XLSX:
      blob = new Blob([bytes], { type: "application/xlsx" });
      break;
    default:
      blob = new Blob([bytes], { type: "application/pdf" });
      break;
  }
  return blob;
};

export {
  useEffectOnce,
  isPublicRoute,
  getImage,
  showActivityDuration,
  imageExtention,
  dateTimeBefore,
  stringChecker,
  isFormDisabled,
  isDropDownFieldDisabled,
  isCreateForm,
  dateAndTime,
  date,
  dateLocalDate,
  time,
  axiosConfig,
  hrefForDownloadAttachment,
  operatorNumber,
  operatorText,
  operatorSet,
  CALENDAR_DATE_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT_FULL,
  DATE_FORMAT_2,
  DATE_FORMAT_3,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_2,
  DATE_TIME_FORMAT_3,
  DATE_TIME_FORMAT_4,
  DATE_TIME_FORMAT_5,
  DATE_TIME_FORMAT_6,
  DAY_DATE_FORMAT,
  DAY_IN_WEEK_FORMAT,
  SHORT_MONTH_NAME_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_2,
  TIME_FORMAT_3,
  TIME_FORMAT_SECONDS_0,
  FORM_CCF,
  FORM_DEATH_REPORT,
  MAX_FILE_UPLOAD,
  MAX_PORUKA_DATOTEKA_LIST_SIZE,
  handleAxiosCallError,
  handleAxiosCallWarning,
  handleAxiosCallSuccess,
  meterToKilometer,
  isSifraIncluded,
  jezikList,
  hexToRGB,
  addSrLocal,
  formatDate,
  formatPeriod,
  terminStatusFieldType,
  getFieldValue,
  tooltipOptionsTop,
  tooltipOptionsBottom,
  tooltipOptionsRight,
  handleScreenSizeChange,
  MINIMAL_PANEL_WIDTH_FOR_PACIJENT_INFO,
  radnoVremeOrgJediniceGlobal,
  LOCAL_STORAGE_AUTH_KEY,
  ODABRANA_ORGANIZACIONA_JEDINICA_STORAGE_ITEM,
  formatHour,
  skeletonTimeout,
  selectOrganizacionaJedinica,
  NESACUVAN_NALAZ,
  WIDTH_FOR_MOBILE_DEVICE,
  createCurrentDateWithTime,
  getDateDivisibleBy5,
  getHoursFromTime,
  getMinutesFromTime,
  STEP_MINUTE,
  HOUR_FORMAT,
  validateStringEmpty,
  checkEmpty,
  isEmailFormatValid,
  MESSAGE_DURATION_IN_MILLISECONDS,
  formatDate2,
  numberFormat,
  getErrorMessage,
  tableDateFilter,
  tableDateFilterLess,
  tableDateFilterGreater,
  stripHTML,
  camelize,
  removeAllMasks,
  setInvalid,
  formatPrice,
  RETRY_WITH_RESPONSE_CODE,
  WARNING_CODE,
  isTelefonValid,
  cyr_to_lat,
  showPatientFullName,
  isDateTimeFormatValid,
  handleKeyboardTimeInputChange,
  isInicijalizacija,
  setTimeToDate,
  isDateFormatValid,
  createFileFromBase64,
};

export const getScrollNumber = () => {
  let num;
  let resolution = window.innerWidth;
  if (resolution > 1660) {
    num = 6;
  } else if (resolution <= 1660 && resolution > 1460) {
    num = 5;
  } else if (resolution <= 1460 && resolution > 1260) {
    num = 4;
  } else if (resolution <= 1260 && resolution > 1060) {
    num = 3;
  } else if (resolution <= 1060 && resolution > 860) {
    num = 2;
  } else {
    num = 1;
  }
  return num;
};
