import moment, { Moment } from "moment";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../Store";
import { OrganizacionaJedinicaController } from "../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { RolaService } from "../../controllers/rola/RolaService";
import { ZakazaniTerminController } from "../../controllers/zakazani-termin/ZakazaniTerminController";
import EnumRola from "../../infrastructure/system/EnumRola";
import { formatDate2, handleAxiosCallError } from "../../infrastructure/system/Utils";
import { useOrganizacionaJedinicaRadnikList } from "../../infrastructure/system/hooks/organizaciona-jedinica/useOrganizacionaJedinicaRadnikList";
import { usePacijent } from "../../infrastructure/system/hooks/pacijent/usePacijent";
import { ZAKAZIVANJE_PREGLEDA_INDEX } from "../../model/IndeksiTabovaNaStrani";
import PacijentReadDto from "../../model/pacijent/PacijentReadDto";
import RadnikSkracenoReadDto from "../../model/radnik/RadnikSkracenoReadDto";

interface ZakazivanjePregledaLogicalType {
  selectedDate?: Moment;
  setSelectedDate: Dispatch<SetStateAction<Moment>>;
  dates: Moment[];
  dateBack: () => void;
  dateForward: () => void;
  radnikList: Array<RadnikSkracenoReadDto>;
  radnikListIsLoading: boolean;
  selectedRadnik: RadnikSkracenoReadDto | undefined;
  setSelectedRadnik: Dispatch<SetStateAction<RadnikSkracenoReadDto | undefined>>;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  filterDatesForGrid: (datesArray: Array<Moment>) => Array<any>;
  selectedPacijent?: PacijentReadDto;
  printProtokolOrZakazaniTerminiList: (isProtokol: boolean) => void;
  prikaziNeaktivne: boolean;
  setPrikaziNeaktivne: Dispatch<SetStateAction<boolean>>;
}

interface UseParamsType {
  pacijentId?: string;
}

export default function ZakazivanjePregledaLogical(): ZakazivanjePregledaLogicalType {
  const { showMessage, setShowBlockUI, authData, odabranaOrganizacionaJedinicaList } = useContext(AppContext);
  const { axiosPrintProtokol } = OrganizacionaJedinicaController();
  const [dates, setDates] = useState<Moment[]>(Array.from({ length: 7 }, (_: any, index: number) => moment(moment()).add(index - moment().day() + 1, "days")));
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [selectedRadnik, setSelectedRadnik] = useState<RadnikSkracenoReadDto>();
  const [prikaziNeaktivne, setPrikaziNeaktivne] = useState<boolean>(false);
  const pacijentId = useParams<keyof UseParamsType>()["pacijentId"];

  const { isLekar } = RolaService();
  const [activeIndex, setActiveIndex] = useState(isLekar && !pacijentId ? ZAKAZIVANJE_PREGLEDA_INDEX.CEKAONICA : ZAKAZIVANJE_PREGLEDA_INDEX.ZAKAZIVANJE);
  const { axiosGetZakazaniTerminiPrint } = ZakazaniTerminController();

  const { pacijent: selectedPacijent } = usePacijent(pacijentId ? Number(pacijentId) : undefined);
  const odabranaOrgJed = odabranaOrganizacionaJedinicaList && odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1];
  const { data: orgJedRadnikListServer, isLoading: radnikListIsLoading } = useOrganizacionaJedinicaRadnikList(odabranaOrgJed?.organizacionaJedinica.id, EnumRola.LEKAR, prikaziNeaktivne);
  const radnikList = orgJedRadnikListServer?.map((orgJedRadnik) => orgJedRadnik.radnik) ?? [];

  useEffect(() => {
    if (odabranaOrganizacionaJedinicaList.length > 0 && odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1]?.podredjeneOrganizacioneJedinice?.length === 0) {
      if (orgJedRadnikListServer) {
        const selectCurrentRadnik = radnikList.find((element: RadnikSkracenoReadDto) => authData?.currentRadnik.id === element.id);
        setSelectedRadnik(selectCurrentRadnik ?? radnikList[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgJedRadnikListServer]);

  const dateBack = () => {
    const momentNow = moment(dates[0]);
    const datesNew = Array.from({ length: 7 }, (_: any, index: number) => moment(momentNow).subtract(7 - index, "days"));
    setDates(datesNew);
  };

  const dateForward = () => {
    const momentNow = moment(dates[6]);
    const datesNew = Array.from({ length: 7 }, (_: any, index: number) => moment(momentNow).add(index + 1, "days"));
    setDates(datesNew);
  };

  const filterDatesForGrid = (datesArray: Array<Moment>) => {
    let filteredDates: Array<Moment> = datesArray;

    if (datesArray.length > 1 && odabranaOrganizacionaJedinicaList.length > 0) {
      const validWeekdays = odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1].organizacionaJedinica.organizacionaJedinicaRadniDanList.map(
        (ojRadniDan) => ojRadniDan.radniDan.danUNedelji
      );

      filteredDates = datesArray.filter((date: Moment) => {
        const dayOfWeek = date.toDate().getDay();
        return validWeekdays.includes(dayOfWeek) || (dayOfWeek === 0 && validWeekdays.includes(7));
      });
    }

    return filteredDates.sort((a, b) => a.valueOf() - b.valueOf());
  };

  const printProtokolOrZakazaniTerminiList = (isProtokol: boolean) => {
    const orgJedinicaID = odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1]?.organizacionaJedinica?.id;
    const request = isProtokol ? axiosPrintProtokol : axiosGetZakazaniTerminiPrint;
    setShowBlockUI(true);
    request(orgJedinicaID, formatDate2(selectedDate?.toDate()))
      .then((response) => {
        const byteChars = atob(response.data);
        const dataArray = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
          dataArray[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(dataArray);
        const file = new Blob([byteArray], { type: "application/pdf" });
        const url = URL.createObjectURL(file);
        const w = window.open(url, "_blank");
        if (w) {
          w!.document.write(`<embed width="100%" height="100%" name="plugin" src="${url}" type="application/pdf" internalinstanceid="21"></body></html>`);
        }
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return {
    selectedDate,
    setSelectedDate,
    dates,
    dateBack,
    dateForward,
    radnikList,
    selectedRadnik,
    setSelectedRadnik,
    activeIndex,
    setActiveIndex,
    filterDatesForGrid,
    selectedPacijent,
    printProtokolOrZakazaniTerminiList,
    prikaziNeaktivne,
    setPrikaziNeaktivne,
    radnikListIsLoading,
  };
}
