import moment from "moment";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import UstanovaFakturniPodaciReadDto from "../../../../model/ustanova/UstanovaFakturniPodaciReadDto";

interface UstanovaFakturniPodaciLogicalType {
  fakturniPodaciLoading: boolean;
  fakturniPodaci: UstanovaFakturniPodaciReadDto | undefined;
  setFakturniPodaci: React.Dispatch<React.SetStateAction<UstanovaFakturniPodaciReadDto | undefined>>;
  onSave: () => void;
}

interface UseParamsType {
  ustanovaId?: string;
}

export default function UstanovaFakturniPodaciLogical(): UstanovaFakturniPodaciLogicalType {
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const { axiosSuperadminGetUstanovaFakturniPodaci, axiosSuperadminUpdateUstanovaFakturniPodaci } = SuperadminController();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();

  const [fakturniPodaciLoading, setFakturniPodaciLoading] = useState(true);
  const [fakturniPodaci, setFakturniPodaci] = useState<UstanovaFakturniPodaciReadDto>();

  useEffectOnce(() => {
    const startTime = moment();
    axiosSuperadminGetUstanovaFakturniPodaci(ustanovaId)
      .then(({ data: { data } }: { data: { data: UstanovaFakturniPodaciReadDto } }) => {
        setFakturniPodaci(data);
      })
      .catch((error: any) => handleAxiosCallError(showMessage, error))
      .finally(() => {
        skeletonTimeout(setFakturniPodaciLoading, startTime);
      });
  });

  const validate = (fakturniPodaci: UstanovaFakturniPodaciReadDto) => {
    const mailRegex: RegExp = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
    const pibRexex: RegExp = new RegExp(/^\d{9}$/);
    const mbRexex: RegExp = new RegExp(/^\d{8}$/);
    if (fakturniPodaci.fakturaEmail && fakturniPodaci.fakturaEmail !== undefined && fakturniPodaci.fakturaEmail !== "" && !mailRegex.test(fakturniPodaci.fakturaEmail)) {
      showMessage(MessageType.ERROR, Labels.FAKTURA_MAIL_FORMAT_ERROR);
      return false;
    }
    if (fakturniPodaci.fakturaPIB && fakturniPodaci.fakturaPIB !== undefined && fakturniPodaci.fakturaPIB !== "" && !pibRexex.test(fakturniPodaci.fakturaPIB)) {
      showMessage(MessageType.ERROR, Labels.FAKTURA_PIB_FORMAT_ERROR);
      return false;
    }
    if (fakturniPodaci.fakturaMB && fakturniPodaci.fakturaMB !== undefined && fakturniPodaci.fakturaMB !== "" && !mbRexex.test(fakturniPodaci.fakturaMB)) {
      showMessage(MessageType.ERROR, Labels.FAKTURA_MB_FORMAT_ERROR);
      return false;
    }
    return true;
  };

  const onSave = () => {
    if (!validate(fakturniPodaci!)) {
      return;
    }
    setShowBlockUI(true);
    axiosSuperadminUpdateUstanovaFakturniPodaci(ustanovaId, fakturniPodaci!)
      .then((res: any) => showMessage(MessageType.SUCCESS, Labels.USTANOVA_FAKTURNI_PODACI_TITLE_MESSAGE_UPDATE_USTANOVA_FAKTURNI_PODACI_SUCCESS))
      .catch((error: any) => handleAxiosCallError(showMessage, error))
      .finally(() => setShowBlockUI(false));
  };

  return { fakturniPodaciLoading, fakturniPodaci, setFakturniPodaci, onSave };
}
