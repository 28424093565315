import { RadioButton } from "primereact/radiobutton";
import { SetStateAction } from "react";
import { useLabels } from "../../../../../../Store";
import KontaktDijagnozaReadDto from "../../../../../../model/kontakt/KontaktDijagnozaReadDto";
import ReceptCreateDto from "../../../../../../model/kontakt/ReceptCreateDto";
import SkeletonAccordionList from "../../../../../app/skeleton/SkeletonAccordionList";
import { stepNameType } from "../KontaktTerapijeDialogLogical";

interface FirstStepType {
  kontaktDijagnozaList: Array<KontaktDijagnozaReadDto>;
  dataLoading: boolean;
  terapijaChange: ReceptCreateDto | undefined;
  setTerapijaChange: any;
  stepName: any;
  setStepName: React.Dispatch<SetStateAction<stepNameType | undefined>>;
  page: any;
  setPage: React.Dispatch<SetStateAction<number>>;
}

export default function FirstStep(props: FirstStepType) {
  const { kontaktDijagnozaList, dataLoading, terapijaChange, setTerapijaChange, stepName, setStepName, page, setPage } = props;
  const Labels = useLabels();

  return (
    <>
      <h4 className="font-bold mb-6 pl-3">{Labels.KONTAKT_TERAPIJA_DIALOG_FIRST_PAGE_TITLE}</h4>
      <div className="terapijaDialog--content pl-3">
        {dataLoading ? (
          <SkeletonAccordionList size={4} />
        ) : (
          <>
            {kontaktDijagnozaList.map((dijagnoza: KontaktDijagnozaReadDto, key: number) => (
              <div key={key} className="mb-5 flex">
                <RadioButton
                  inputId={key.toString()}
                  name="dijagnoza"
                  value={dijagnoza.id}
                  checked={terapijaChange?.dijagnoza?.id === dijagnoza.id}
                  onChange={(e) => {
                    setTerapijaChange({ ...terapijaChange, dijagnoza: { id: e.value } });
                    setStepName({
                      ...stepName!,
                      firstStepName: dijagnoza.sifarnikMkb.sifraBezTacke,
                    });
                    // dodato cisto da promena ne bude momentalna, da user vidi sta je odabrao
                    setTimeout(() => {
                      setPage(page + 1);
                    }, 500);
                  }}
                />
                <label className="cursor-pointer mx-2" htmlFor={key.toString()}>
                  {dijagnoza.sifarnikMkb?.sifraBezTacke + " " + dijagnoza.sifarnikMkb?.nazivLatinski}
                </label>
              </div>
            ))}
            {kontaktDijagnozaList.length === 0 && <span>{Labels.KONTAKT_TERAPIJA_DIALOG_FIRST_PAGE_NO_DIAGNOSIS_NOTE}</span>}
          </>
        )}
      </div>
    </>
  );
}
