import moment from "moment";
import { Button } from "primereact/button";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import EnumPol from "../../../../infrastructure/system/EnumPol";
import KontrolaPravaPristupaService from "../../../../infrastructure/system/KontrolaPravaPristupaService";
import { DATE_FORMAT, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import KontaktDijagnozaReadDto from "../../../../model/kontakt/KontaktDijagnozaReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import SkeletonRectangles from "../../../app/skeleton/SkeletonRectangles";
import KontaktDijagnoza from "./KontaktDijagnoza";
import KontaktDijagnozaLogical from "./KontaktDijagnozaLogical";

interface DijagnozaListaProps {
  pacijent: PacijentReadDto;
  kontaktDijagnozaList: Array<KontaktDijagnozaReadDto> | undefined;
  setKontaktDijagnozaList: React.Dispatch<React.SetStateAction<Array<KontaktDijagnozaReadDto> | undefined>>;
  kontaktDijagnozaPrethodnaList: Array<KontaktDijagnozaReadDto> | undefined;
  setKontaktDijagnozaPrethodnaList: React.Dispatch<React.SetStateAction<Array<KontaktDijagnozaReadDto> | undefined>>;
  dijagnozaStatusList: Array<EnumBaseReadDto> | undefined;
  setDijagnozaStatusList: React.Dispatch<React.SetStateAction<Array<EnumBaseReadDto> | undefined>>;
}

export default function DijagnozaList(props: DijagnozaListaProps) {
  const { pacijent, kontaktDijagnozaList, setKontaktDijagnozaList, kontaktDijagnozaPrethodnaList, setKontaktDijagnozaPrethodnaList, dijagnozaStatusList, setDijagnozaStatusList } = props;
  const Labels = useLabels();
  const { pristup, authData } = useContext(AppContext);
  const [dijagnozaOperation, setDijagnozaOperation] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const {
    id,
    openDijagnozaDialog,
    closeDijagnozaDialog,
    isOpenDijagnozaDialog,
    kopirajPrethodnuDijagnozu,
    onCreateDijagnoze,
    onUpdateDijagnoze,
    onDeleteDijagnoze,
    kontaktDijagnozaChange,
    setKontaktDijagnozaChange,
    searchMkbDijagnoza,
    setIsTrenutnaDijagnozaOpenDialog,
    dataLoading,
    mkbDijagnozeList,
    mainDivRef,
    dijagnozeLoading,
  } = KontaktDijagnozaLogical(pacijent, kontaktDijagnozaList, setKontaktDijagnozaList, kontaktDijagnozaPrethodnaList, setKontaktDijagnozaPrethodnaList, dijagnozaStatusList, setDijagnozaStatusList);
  const { hasKontaktDijagnozaCreate, hasKontaktDijagnozaUpdate, hasKontaktDijagnozaDelete } = KontrolaPravaPristupaService();

  const openKontaktDijagnozu = (entityOperation: string, kontaktDijagnoza?: KontaktDijagnozaReadDto, isTrenutnaDijagnozaOpen?: boolean) => {
    setDijagnozaOperation(entityOperation);
    let naziv = "";
    if (kontaktDijagnoza) {
      naziv = kontaktDijagnoza?.sifarnikMkb?.sifra + " " + kontaktDijagnoza?.sifarnikMkb?.nazivLatinski;
    }
    setKontaktDijagnozaChange({
      ...kontaktDijagnoza!,
      sifarnikMkb: { ...kontaktDijagnoza?.sifarnikMkb, unosDijagnoze: naziv },
      ...(isTrenutnaDijagnozaOpen ? { kontakt: { id } } : {}),
      ...(kontaktDijagnoza?.dijagnozaStatus ? { dijagnozaStatus: kontaktDijagnoza?.dijagnozaStatus } : dijagnozaStatusList ? { dijagnozaStatus: dijagnozaStatusList[0] } : {}),
    });

    setIsDisabled(entityOperation === EntityOperation.READ || entityOperation === EntityOperation.DELETE);
    setIsTrenutnaDijagnozaOpenDialog(isTrenutnaDijagnozaOpen ?? false);
    openDijagnozaDialog();
  };

  const kopirajDijagnozu = (dt?: any) => {
    dt.kontakt.id = id;
    kopirajPrethodnuDijagnozu(dt);
  };

  return (
    <div className="p-grid p-dir-col" ref={mainDivRef}>
      {dataLoading ? (
        <SkeletonRectangles />
      ) : (
        <>
          {kontaktDijagnozaChange && (
            <KontaktDijagnoza
              isOpenDijagnozaDialog={isOpenDijagnozaDialog}
              closeDijagnozaDialog={closeDijagnozaDialog}
              dijagnozaOperation={dijagnozaOperation}
              kontaktDijagnozaChange={kontaktDijagnozaChange}
              setKontaktDijagnozaChange={setKontaktDijagnozaChange}
              onCreateDijagnoze={onCreateDijagnoze}
              onUpdateDijagnoze={onUpdateDijagnoze}
              onDeleteDijagnoze={onDeleteDijagnoze}
              dijagnozaStatusList={dijagnozaStatusList}
              isDisabled={isDisabled}
              searchMkbDijagnoza={searchMkbDijagnoza}
              mkbDijagnozeList={mkbDijagnozeList}
              dijagnozeLoading={dijagnozeLoading}
            />
          )}
          <div className="p-col flex flex-column">
            <div className="box flex flex-row">
              <div className="col-9 p-0 font-bold align-self-center">{Labels.DIJAGNOZA_LIST_TOKOM_KONTKAKTA}</div>
              <div className="col-3 p-0 flex justify-content-end">
                <Button
                  label={Labels.DIJAGNOZA_NOVA}
                  className="p-button-success"
                  onClick={() => openKontaktDijagnozu(EntityOperation.CREATE, undefined, true)}
                  disabled={!pristup || !hasKontaktDijagnozaCreate}
                />
              </div>
            </div>

            {kontaktDijagnozaList?.map((dt: KontaktDijagnozaReadDto, key: number) => {
              return (
                <div className="box flex flex-row" key={key}>
                  <div className="col-12 pl-0 pr-0">
                    <div className="flex flex-row">
                      <div className="col-9 flex p-0">
                        <div className="flex flex-row">
                          <p>{Labels.DIJAGNOZA_POSTAVLJENA}</p>
                          <p className="font-bold pl-2">{dt.sifarnikMkb?.sifraBezTacke + " " + dt.sifarnikMkb?.nazivLatinski}</p>
                        </div>
                      </div>
                      <div className="col-3 flex justify-content-end p-0">
                        <div className="flex flex-row h-2rem">
                          <Button
                            type="button"
                            tooltip={Labels.DIJAGNOZA_IZMENI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-warning mr-2 p-button-small-size"
                            icon="pi pi-pencil"
                            onClick={() => dt && openKontaktDijagnozu(EntityOperation.UPDATE, dt, true)}
                            disabled={authData?.currentRadnik.id === dt.radnikKreirao.id ? false : !hasKontaktDijagnozaUpdate}
                          />
                          <Button
                            type="button"
                            tooltip={Labels.DIJAGNOZA_OBRISI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-danger p-button-small-size"
                            icon="pi pi-trash"
                            onClick={() => dt && openKontaktDijagnozu(EntityOperation.DELETE, dt, true)}
                            disabled={!pristup || authData?.currentRadnik.id === dt.radnikKreirao.id ? false : !hasKontaktDijagnozaDelete}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <p>{Labels.DIJAGNOZA_STATUS}</p>
                      <p className="pl-2">{dt.dijagnozaStatus?.naziv}</p>
                    </div>
                    {dt.napomena && (
                      <div className="flex flex-row">
                        <p>{Labels.DIJAGNOZA_NAPOMENA}</p>
                        <p className="pl-2">{dt.napomena}</p>
                      </div>
                    )}
                    <div className="flex flex-row">
                      <p>
                        {dt.radnikKreirao.polTrenutni?.sifra === EnumPol.MUSKI
                          ? Labels.DIJAGNOZA_POSTAVIO_MUSKI
                          : dt.radnikKreirao.polTrenutni?.sifra === EnumPol.ZENSKI
                          ? Labels.DIJAGNOZA_POSTAVIO_ZENSKI
                          : Labels.DIJAGNOZA_POSTAVIO}
                      </p>
                      <p className="pl-2">
                        {dt.radnikKreirao?.ime} {dt.radnikKreirao?.prezime} {moment(dt.vremeKreiranja).format(DATE_FORMAT)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <hr></hr>
          </div>
          <div className="p-col flex flex-column">
            <div className="box flex flex-row">
              <div className="col-9 pl-0 pr-0 font-bold align-self-center">{Labels.DIJAGNOZA_LIST_IZ_PRETHODNIH_PREGLEDA}</div>
            </div>
            {kontaktDijagnozaPrethodnaList?.map((dt: KontaktDijagnozaReadDto, key: number) => {
              return (
                <div className="box flex flex-row" key={key}>
                  <div className="col-12 pl-0 pr-0">
                    <div className="flex flex-row">
                      <div className="col-9 flex p-0">
                        <div className="flex flex-row">
                          <p>{Labels.DIJAGNOZA_POSTAVLJENA}</p>
                          <p className="font-bold pl-2">{dt.sifarnikMkb?.sifraBezTacke + " " + dt.sifarnikMkb?.nazivLatinski}</p>
                        </div>
                      </div>
                      <div className="col-3 flex justify-content-end p-0 h-2rem">
                        <div className="flex flex-row">
                          <Button
                            type="button"
                            tooltip={Labels.DIJAGNOZA_PREPISI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-info mr-2 p-button-small-size"
                            icon="pi pi-copy"
                            onClick={() => dt && kopirajDijagnozu(dt)}
                            disabled={!pristup || !hasKontaktDijagnozaCreate}
                          />
                          <Button
                            type="button"
                            tooltip={Labels.DIJAGNOZA_IZMENI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-warning mr-2 p-button-small-size"
                            icon="pi pi-pencil"
                            onClick={() => dt && openKontaktDijagnozu(EntityOperation.UPDATE, dt, false)}
                            disabled={authData?.currentRadnik.id === dt.radnikKreirao.id ? false : !hasKontaktDijagnozaUpdate}
                          />
                          <Button
                            type="button"
                            tooltip={Labels.DIJAGNOZA_OBRISI}
                            tooltipOptions={tooltipOptionsTop}
                            className="p-button-danger p-button-small-size"
                            icon="pi pi-trash"
                            onClick={() => dt && openKontaktDijagnozu(EntityOperation.DELETE, dt, false)}
                            disabled={!pristup || authData?.currentRadnik.id === dt.radnikKreirao.id ? false : !hasKontaktDijagnozaDelete}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <p>{Labels.DIJAGNOZA_STATUS}</p>
                      <p className="pl-2">{dt.dijagnozaStatus?.naziv}</p>
                    </div>
                    {dt.napomena && (
                      <div className="flex flex-row">
                        <p>{Labels.DIJAGNOZA_NAPOMENA}</p>
                        <p className="pl-2">{dt.napomena}</p>
                      </div>
                    )}
                    <div className="flex flex-row">
                      <p>
                        {dt.radnikKreirao.polTrenutni?.sifra === EnumPol.MUSKI
                          ? Labels.DIJAGNOZA_POSTAVIO_MUSKI
                          : dt.radnikKreirao.polTrenutni?.sifra === EnumPol.ZENSKI
                          ? Labels.DIJAGNOZA_POSTAVIO_ZENSKI
                          : Labels.DIJAGNOZA_POSTAVIO}
                      </p>
                      <p className="pl-2">{dt.radnikKreirao?.ime + " " + dt.radnikKreirao?.prezime + " " + moment(dt.vremeKreiranja).format(DATE_FORMAT)}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
