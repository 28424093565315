import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../../infrastructure/system/Utils";
import SearchBaseDto from "../../../model/SearchBaseDto";
import UstanovaBrojNalogaCreateDto from "../../../model/ustanova/licenca/UstanovaBrojNalogaCreateDto";
import UstanovaBrojNalogaReadDto from "../../../model/ustanova/licenca/UstanovaBrojNalogaReadDto";
import { AppContext } from "../../../Store";

interface UstanovaBrojNalogaControllerType {
  axiosGetUstanovaBrojNalogaList: (ustanovaId: number | undefined) => Promise<AxiosResponse<any>>;
  axiosUpdateUstanovaBrojNaloga: (ustanovaBrojNalogaChange: UstanovaBrojNalogaReadDto, ustanovaID: number) => Promise<AxiosResponse<any>>;
  axiosReadUstanovaBrojNaloga: (ustanovaBrojNalogaId: number, ustanovaID: number) => Promise<AxiosResponse<any>>;
  axiosCreateUstanovaBrojNaloga: (ustanovaBrojNalogaChange: UstanovaBrojNalogaCreateDto, ustanovaID: number) => Promise<AxiosResponse<any>>;
  axiosDeleteUstanovaBrojNaloga: (ustnaovaBrojNalogaId: number, ustanovaID: number) => Promise<AxiosResponse<any>>;
  axiosSearchUstanovaBrojNaloga: (brojNalogaSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
}

export function UstanovaBrojNalogaController(): UstanovaBrojNalogaControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetUstanovaBrojNalogaList = (ustanovaID: number | undefined) => {
    return axios.get(`${Endpoint.USTANOVA_BROJ_NALOGA}/ustanova/${ustanovaID}`, axiosConfig(authData!.token));
  };

  const axiosUpdateUstanovaBrojNaloga = (ustanovaBrojNalogaChange: UstanovaBrojNalogaReadDto, ustanovaID: number) => {
    return axios.put(`${Endpoint.USTANOVA_BROJ_NALOGA}/${ustanovaBrojNalogaChange?.id}/ustanova/${ustanovaID}`, ustanovaBrojNalogaChange, axiosConfig(authData!.token));
  };

  const axiosReadUstanovaBrojNaloga = (ustanovaBrojNalogaId: number, ustanovaID: number) => {
    return axios.get(`${Endpoint.USTANOVA_BROJ_NALOGA}/${ustanovaBrojNalogaId}/ustanova/${ustanovaID}`, axiosConfig(authData!.token));
  };

  const axiosCreateUstanovaBrojNaloga = (ustanovaBrojNalogaChange: UstanovaBrojNalogaCreateDto, ustanovaID: number) => {
    return axios.post(`${Endpoint.USTANOVA_BROJ_NALOGA}/ustanova/${ustanovaID}`, ustanovaBrojNalogaChange, axiosConfig(authData!.token));
  };

  const axiosDeleteUstanovaBrojNaloga = (ustanovaBrojNalogaId: number, ustanovaID: number) => {
    return axios.delete(`${Endpoint.USTANOVA_BROJ_NALOGA}/${ustanovaBrojNalogaId}/ustanova/${ustanovaID}`, axiosConfig(authData!.token));
  };

  const axiosSearchUstanovaBrojNaloga = (brojNalogaSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.USTANOVA_BROJ_NALOGA_LIST, axiosConfig(authData!.token, brojNalogaSearchData));
  };

  return {
    axiosGetUstanovaBrojNalogaList,
    axiosUpdateUstanovaBrojNaloga,
    axiosReadUstanovaBrojNaloga,
    axiosCreateUstanovaBrojNaloga,
    axiosDeleteUstanovaBrojNaloga,
    axiosSearchUstanovaBrojNaloga,
  };
}
