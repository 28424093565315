import { useQuery } from "@tanstack/react-query";
import { SifarnikNacinaPlacanjaController } from "../../../../../controllers/sifarnik-nacina-placanja/SifarnikNacinaPlacanjaController";
import QueryKeys from "../../../QueryKeys";

export function useSifarnikNacinPlacanjaList() {
  const { axiosGetSifarnikNacinaPlacanjaList } = SifarnikNacinaPlacanjaController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.SIFARNIK_NACIN_PLACANJA_LIST],
    queryFn: () => axiosGetSifarnikNacinaPlacanjaList(),
  });

  const nacinPlacanjaList = data?.data?.data ?? [];

  return { nacinPlacanjaList, refetch, isLoading };
}
