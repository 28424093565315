import moment, { Moment } from "moment";
import { OverlayPanel } from "primereact/overlaypanel";
import { useMemo, useRef } from "react";
import { useLabels } from "../../../../Store";
import EnumZakazanTerminStatus from "../../../../infrastructure/system/EnumZakazanTerminStatus";
import { DATE_FORMAT, TIME_FORMAT, formatDate, showPatientFullName, terminStatusFieldType } from "../../../../infrastructure/system/Utils";
import KontaktReadDto from "../../../../model/kontakt/KontaktReadDto";
import TipKontaktaReadDto from "../../../../model/kontakt/TipKontaktaReadDto";
import OrganizacionaJedinicaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import PartnerReadDto from "../../../../model/partner/PartnerReadDto";
import RadnikSkracenoReadDto from "../../../../model/radnik/RadnikSkracenoReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import EnumTerminTip from "../../../../infrastructure/system/EnumTerminTip";

export interface TerminType {
  id?: number;
  radnik?: RadnikSkracenoReadDto;
  pacijent?: PacijentReadDto;
  kontakt?: KontaktReadDto;
  kontaktTip?: TipKontaktaReadDto;
  organizacionaJedinica?: OrganizacionaJedinicaReadDto;
  zakazanTerminStatus?: EnumBaseReadDto;
  zakazanTerminTip?: EnumBaseReadDto;
  vremeZakazanoOd: Moment;
  vremeZakazanoDo: Moment;
  vremeDolaska?: Date;
  napomena?: String;
  disabledClick?: boolean;
  eksterniZakazivacID?: number;
  partner?: PartnerReadDto;
  eksterniCaseID?: string;
  draggingStatus?: string;
}

interface TerminProps {
  termin: TerminType;
  otvoriDialogTermin: (vreme: TerminType) => void;
  vertical: boolean;
  handleDragStart?: (event: React.DragEvent<HTMLDivElement>, termin: TerminType) => void;
  handleDragOver?: (event: React.DragEvent<HTMLDivElement>, canBeDraggedOver: boolean) => void;
  handleDragLeave?: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDrop?: (event: React.DragEvent<HTMLDivElement>, terminCanBeDropped: boolean, termin: TerminType) => void;
  dragging?: boolean;
  selectedPacijent?: PacijentReadDto;
}

export default function Termin({ termin, otvoriDialogTermin, vertical, handleDragStart, handleDragOver, handleDragLeave, handleDrop, dragging, selectedPacijent }: TerminProps) {
  const Labels = useLabels();
  const trajanjeTermina = moment.duration(termin.vremeZakazanoDo.diff(termin.vremeZakazanoOd)).asMinutes();
  const overlayPanelAppointment: any = useRef([]);
  const isTerminWithStatus: boolean = !!termin.zakazanTerminStatus;
  const isTerminDisabled: boolean = !!termin.disabledClick;
  const isTerminDraggable: boolean = isTerminWithStatus && termin.zakazanTerminStatus?.sifra !== EnumZakazanTerminStatus.NIJE_DOSAO && vertical && !termin.kontakt;
  const isTerminDroppable: boolean = !(isTerminWithStatus || isTerminDisabled);

  const divStyle = useMemo(() => {
    return { height: `${1.25 * (trajanjeTermina / 5)}rem` };
  }, [trajanjeTermina]);

  return (
    <>
      <div
        id={termin.id?.toString()}
        className={`py-2 appointment-border
          hc-scheduler-appointment ${
            termin.zakazanTerminTip?.sifra !== EnumTerminTip.VANREDAN && `hc-scheduler-appointment--${terminStatusFieldType[(termin?.zakazanTerminStatus?.id ?? (0 as number)) - 1]}`
          } 
          ${dragging && termin.draggingStatus && "dragging-status-stripes--" + termin.draggingStatus} 
          ${!vertical ? `no-border` : ""}
          ${(!vertical || termin.vremeZakazanoDo.isBefore(moment(new Date()))) && "hc-scheduler-appointment--past"}
          ${!termin.disabledClick && !(termin?.pacijent && selectedPacijent && selectedPacijent?.id !== termin?.pacijent?.id) && "cursor-pointer"}`}
        style={vertical ? divStyle : undefined}
        onMouseEnter={(e) => {
          termin?.id && overlayPanelAppointment?.current?.show(e);
        }}
        onMouseLeave={() => {
          termin?.id && overlayPanelAppointment?.current?.hide();
        }}
        onClick={() => {
          termin?.id && overlayPanelAppointment?.current?.hide();
          if (!termin.disabledClick && termin) {
            if (termin?.pacijent && selectedPacijent && selectedPacijent?.id !== termin?.pacijent?.id) return;
            otvoriDialogTermin(termin);
          }
        }}
        aria-haspopup
        aria-controls="overlay_panel_appointment"
        draggable={isTerminDraggable}
        onDragStart={handleDragStart && ((e) => handleDragStart(e, termin))}
        onDragOver={handleDragOver && ((e) => handleDragOver(e, isTerminDroppable))}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop && ((e) => handleDrop(e, isTerminDroppable, termin))}
      >
        <div className={`w-full text-center align-self-center ${termin.disabledClick && "unavailable"}`}>
          <div className={`relative w-full text-center align-self-center ${termin.disabledClick && "unavailable"}`}>
            {termin?.eksterniCaseID && <span className="pi pi-search-plus text-2xl pl-3 absolute left-0" style={{ color: "#1199a7" }} />}
            <div className="m-auto">{`${moment(termin.vremeZakazanoOd).format(TIME_FORMAT)} - ${moment(termin.vremeZakazanoDo).format(TIME_FORMAT)}`}</div>
          </div>
        </div>
      </div>
      {!dragging && (
        <OverlayPanel ref={overlayPanelAppointment} id="overlay_panel_appointment" className="overlaypanel-dark">
          {termin?.pacijent?.prezime && <div>{`${Labels.CEKAONICA_PACIJENT_ORDINACIJA}: ${termin?.organizacionaJedinica?.naziv}`}</div>}
          {termin?.pacijent?.ime && termin?.pacijent?.prezime && <div>{`${Labels.CEKAONICA_COLUMN_PACIJENT}: ${showPatientFullName(termin?.pacijent)}`}</div>}
          {termin?.vremeDolaska && <div>{`${Labels.CEKAONICA_COLUMN_VREME_DOLASKA}: ${formatDate(termin?.vremeDolaska, TIME_FORMAT)}`}</div>}
          {termin?.pacijent?.vremeRodjenja && <div>{`${Labels.CEKAONICA_COLUMN_DATUM_RODJENJA}: ${formatDate(termin?.pacijent?.vremeRodjenja as Date, DATE_FORMAT)}`}</div>}
          {termin?.pacijent?.adresa && <div>{`${Labels.CEKAONICA_COLUMN_ADRESA}: ${termin?.pacijent?.adresa}`}</div>}
          {termin?.pacijent?.telefon && <div>{`${Labels.CEKAONICA_COLUMN_TELEFON}: ${termin?.pacijent?.telefon}`}</div>}
          {termin?.pacijent?.email && <div>{`${Labels.CEKAONICA_COLUMN_EMAIL}: ${termin?.pacijent?.email}`}</div>}
          {termin?.eksterniCaseID && <div>{`${Labels.LABEL_NAZIV_ZAKAZIVACA}: ${termin?.partner?.naziv}`}</div>}
        </OverlayPanel>
      )}
    </>
  );
}
