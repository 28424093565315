import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../Store";
import { BrojNalogaController } from "../../../../controllers/broj-naloga/BrojNalogaController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import SearchBaseDto from "../../../../model/SearchBaseDto";
import BrojNalogaReadDto from "../../../../model/licenca/broj-naloga/BrojNalogaReadDto";

interface BrojNalogaListLogicalType {
  brojNalogaSearchData: SearchBaseDto;
  changeBrojNalogaSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  searchBrojNalogaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  fetchData: () => void;
  brojNalogaList: Array<BrojNalogaReadDto>;
  brojNalogaLoading: boolean;
  firstBrojNaloga: number;
  brojNalogaRows: number;
  onPageBrojNaloga: (rows: number, first: number) => void;
  openBrojNaloga: (entityOperation: string, id?: number) => void;
}

export default function BrojNalogaListLogical(): BrojNalogaListLogicalType {
  const { showMessage } = useContext(AppContext);
  const { axiosSearchBrojNalogaList } = BrojNalogaController();
  const navigate = useNavigate();
  const [brojNalogaSearchData, setBrojNalogaSearchData] = useState<SearchBaseDto>({});
  const [brojNalogaList, setBrojNalogaList] = useState<Array<BrojNalogaReadDto>>([]);
  const [brojNalogaLoading, setBrojNalogaLoading] = useState<boolean>(false);
  const [firstBrojNaloga, setFirstBrojNaloga] = useState(0);
  const [brojNalogaRows, setBrojNalogaRows] = useState(10);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const startTime = moment(new Date());
    setBrojNalogaLoading(true);
    axiosSearchBrojNalogaList(brojNalogaSearchData)
      .then(({ data: { data } }: { data: { data: Array<BrojNalogaReadDto> } }) => {
        setBrojNalogaList(data);
        setFirstBrojNaloga(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setBrojNalogaLoading, startTime);
      });
  };

  const changeBrojNalogaSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setBrojNalogaSearchData({
      ...brojNalogaSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchBrojNalogaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageBrojNaloga = (rows: number, first: number) => {
    setBrojNalogaRows(rows);
    setFirstBrojNaloga(first);
  };

  const openBrojNaloga = (entityOperation: string, id?: number) => {
    const brojNalogaId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-broj-naloga/${brojNalogaId}`, {
      state: { brojNalogaOperation: entityOperation },
    });
  };

  return {
    brojNalogaSearchData,
    changeBrojNalogaSearchData,
    searchBrojNalogaByEnter,
    fetchData,
    brojNalogaList,
    brojNalogaLoading,
    firstBrojNaloga,
    brojNalogaRows,
    onPageBrojNaloga,
    openBrojNaloga,
  };
}
