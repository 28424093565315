import { useContext, useState } from "react";
import { useParams } from "react-router";
import { AppContext } from "../../../Store";
import { PacijentController } from "../../../controllers/pacijent/PacijentController";
import { handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import ZakazanTerminFutureDto from "../../../model/zakazan-termin/ZakazanTerminFutureDto";

interface ZakazaniPreglediLogicalType {
  zakazaniTerminiList: Array<ZakazanTerminFutureDto>;
  zakazaniTerminiListLoading: boolean;
}

interface useParamsType {
  id?: string;
}

export default function ZakazaniPreglediLogical(): ZakazaniPreglediLogicalType {
  const { showMessage } = useContext(AppContext);
  const [zakazaniTerminiList, setZakazaniTerminiList] = useState<Array<ZakazanTerminFutureDto>>([]);
  const [zakazaniTerminiListLoading, setZakazaniTerminiListLoading] = useState<boolean>(false);
  const id: number = Number(useParams<keyof useParamsType>()["id"]);
  const { axiosZakazaniTerminiPacijenta } = PacijentController();

  useEffectOnce(() => {
    fetchZakazaniTermini();
  });

  const fetchZakazaniTermini = () => {
    setZakazaniTerminiListLoading(true);
    axiosZakazaniTerminiPacijenta(id)
      .then((response) => {
        setZakazaniTerminiList(response.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setZakazaniTerminiListLoading(false);
      });
  };

  return {
    zakazaniTerminiList,
    zakazaniTerminiListLoading,
  };
}
