import { CalendarChangeEvent } from "primereact/calendar";
import { useRef } from "react";
import { useLabels } from "../../../../Store";
import { CALENDAR_DATE_FORMAT, formatDate } from "../../../../infrastructure/system/Utils";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import { PoljeType } from "../Polje";

export default function Datumski(props: PoljeType) {
  const { id, onChange, vrednost, readonly, format, vrednostList, useVrednostList } = props;
  const datumRef = useRef<HTMLDivElement | any>(null);
  const Labels = useLabels();

  return (
    <CalendarComponent
      ref={datumRef}
      disabled={readonly ?? false}
      name="vrednost"
      className="w-full"
      value={useVrednostList && vrednostList ? vrednostList[id] : vrednost}
      dateFormat={format ?? CALENDAR_DATE_FORMAT}
      onChange={(e: CalendarChangeEvent) => {
        onChange({ id: id, vrednost: formatDate(e.value as Date | undefined) });
        if (e.target.value) datumRef?.current?.hide();
      }}
      placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
    />
  );
}
