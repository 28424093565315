import { useQuery } from "@tanstack/react-query";
import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import QueryKeys from "../../QueryKeys";

export function useOrganizacionaJedinicaRadnikList(organizacionaJedinicaID?: number, rolaSifra?: string, viewNeaktivni?: boolean) {
  const { axiosGetOrganizacionaJedinicaRadnikListByRola } = OrganizacionaJedinicaController();

  return useQuery({
    queryKey: [QueryKeys.ORGANIZACIONA_JEDINICA_LIST, organizacionaJedinicaID, QueryKeys.RADNIK_LIST, { rolaSifra, viewNeaktivni }],
    queryFn: () => axiosGetOrganizacionaJedinicaRadnikListByRola(organizacionaJedinicaID!, rolaSifra!, viewNeaktivni).then((res) => res.data.data),
    enabled: !!organizacionaJedinicaID && !!rolaSifra,
  });
}
