import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import { RolaService } from "../../../controllers/rola/RolaService";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import RepositoryLogical from "../../../infrastructure/system/RepositoryLogical";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import DialogComponent from "../../dialog-component/DialogComponent";
import IzvestajListLogical from "./IzvestajListLogical";
import KategorijeStatistickihIzvestajaListView from "./kategorije-statistickih-izvestaja/KategorijeStatistickihIzvestajaListView";

export default function IzvestajListPage() {
  const { isSuperadmin } = RolaService();
  const {
    breadCrumbItems,
    izvestajLoading,
    first,
    tableRows,
    kategorijaList,
    izvestajList,
    changeIzvestajSearchData,
    searchIzvestajByEnter,
    izvestajSearchData,
    fetchData,
    closeDialog,
    isDialogOpen,
    openDialog,
    repositoryIzvestajLoading,
    repositoryIzvestajList,
    selectedKategorijaStatistickogIzvestaja,
    setSelectedKategorijaStatistickogIzvestaja,
    selectedColumns,
    setSelectedColumns,
    importStatistickiIzvestaj,
    firstRepository,
    tableRepositoryRows,
    onPageRepositoryIzvestaj,
    onPageIzvestaj,
  } = IzvestajListLogical(isSuperadmin);

  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMini } = PageDropdownTemplate();
  const navigate = useNavigate();
  const location = useLocation();
  const index = location?.state?.activeIndex ? location?.state?.activeIndex : 0;
  const { pristup } = useContext(AppContext);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const { statusBodyTemplate, statusRowFilterTemplate } = RepositoryLogical();

  const createSearchToolbar = () => {
    return (
      <>
        {isSuperadmin && (
          <Button
            label={Labels.BUTTON_ADD_IZVESTAJ}
            tooltip={Labels.TOOLTIP_ADD_IZVESTAJ}
            tooltipOptions={tooltipOptionsBottom}
            className="p-button-success mr-3"
            onClick={() => {
              openIzvestaj(EntityOperation.CREATE);
            }}
            disabled={!pristup}
          />
        )}
        <span className="p-float-label mt-4 sm:mt-4 md:mt-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={izvestajSearchData?.searchString ?? ""}
            onChange={changeIzvestajSearchData}
            onKeyPress={searchIzvestajByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_IZVESTAJ}</label>
          <Button tooltip={Labels.IZVESTAJ_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };
  const downloadButton = () => {
    return (
      <div className="flex flex-row flex-wrap">
        <Button
          label={Labels.BUTTON_REPOSITORY}
          icon="pi pi-cloud-download"
          tooltip={Labels.IMPORT_FORMULAR_REPOSITORY}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-outlined mr-3 bg-white"
          onClick={() => {
            openDialog();
          }}
        />
      </div>
    );
  };
  const crudBodyTemplate = (rowIzvestaj: any) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.IZVESTAJ_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowIzvestaj && openIzvestaj(EntityOperation.UPDATE, rowIzvestaj.id, rowIzvestaj.naziv)}
        />
        <Button
          type="button"
          tooltip={Labels.IZVESTAJ_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowIzvestaj && openIzvestaj(EntityOperation.DELETE, rowIzvestaj.id)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const kategorijaRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={kategorijaList}
        optionLabel="naziv"
        optionValue="naziv"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        placeholder={Labels.IZVESTAJ_SVE_KATEGORIJE}
        className="p-column-filter"
        showClear
      />
    );
  };

  const openIzvestaj = (entityOperation: string, id?: number, naziv?: String) => {
    const idIzvestaj = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-izvestaj/${idIzvestaj}`, {
      state: { izvestajOperation: entityOperation, izvestajNaziv: naziv },
    });
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_CATEGORY, filter: true, sortrable: false },
    { columnName: Labels.IZVESTAJ_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.IZVESTAJ_OPIS, filter: true, sortrable: true },
  ];

  const renderHeader = () => {
    return (
      <div className="flex flex-column justify-content-between">
        <div>{Labels.LABEL_IZVESTAJ_REPOSITORY}</div>
        <div className="flex pt-3 align-items-center">
          <div className="text-base mr-3">
            {Labels.LABEL_KATEGORIJA_STATISTICKOG_IZVESTAJA}
            {Labels.SPECIAL_CHAR_REQUIRED}
          </div>
          <div className="w-10">
            <MultiSelect
              value={selectedKategorijaStatistickogIzvestaja}
              options={kategorijaList}
              optionLabel="naziv"
              onChange={(e: MultiSelectChangeEvent) => {
                setSelectedKategorijaStatistickogIzvestaja(e.value);
              }}
              className="w-2"
            />
          </div>
        </div>
      </div>
    );
  };

  const leftDialogContents = () => (
    <Button
      label={Labels.LABEL_EXPORT}
      disabled={selectedColumns.length === 0 || selectedKategorijaStatistickogIzvestaja.length === 0}
      icon="pi pi-download"
      className="p-button-outlined p-button-tertiary bg-white"
      onClick={() => importStatistickiIzvestaj()}
      autoFocus
    />
  );

  const rightDialogContents = () => <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={closeDialog} />;

  const dialogFooter = (
    <div className="col-12">
      <Toolbar start={leftDialogContents} end={rightDialogContents} />
    </div>
  );
  const columnsRepositoryProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.CEKAONICA_COLUMN_SVE },
    { columnName: Labels.IZVESTAJ_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.IZVESTAJ_OPIS, filter: false, sortrable: false },
    { columnName: Labels.LABEL_KATEGORIJA_STATISTICKOG_IZVESTAJA, filter: true, sortrable: true },
  ];
  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <TabView activeIndex={index} renderActiveOnly>
          <TabPanel header={Labels.STATISTICKI_IZVESTAJ_LIST}>
            <div>
              <Toolbar start={createSearchToolbar} end={!isSuperadmin && downloadButton} />
              {izvestajLoading ? (
                <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
              ) : (
                <div className="col-12">
                  <DataTable
                    value={izvestajList}
                    paginator
                    first={first}
                    filterDisplay="row"
                    paginatorTemplate={rowsPerPageDropdownTemplateMini}
                    rows={tableRows}
                    onPage={(e) => {
                      onPageIzvestaj(e.rows, e.first);
                    }}
                    emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                    rowHover
                    stripedRows
                  >
                    <Column field={"kategorijaStatistickogIzvestaja.naziv"} header={Labels.HEADER_CATEGORY} className="w-2" filter filterElement={kategorijaRowFilterTemplate} showFilterMenu={false} />
                    <Column className="col-3" field={"naziv"} header={Labels.IZVESTAJ_NAZIV} sortable filter filterMatchMode="contains" showFilterMenu={false} />
                    <Column className="col-3" field={"opis"} header={Labels.IZVESTAJ_OPIS} sortable filter filterMatchMode="contains" showFilterMenu={false} />
                    {isSuperadmin && (
                      <Column
                        className="col-3"
                        field="entityStatus.sifra"
                        body={statusBodyTemplate}
                        header={Labels.LABEL_ENTITY_STATUS}
                        filter
                        filterElement={statusRowFilterTemplate}
                        showFilterMenu={false}
                      />
                    )}
                    <Column className="col-1" body={crudBodyTemplate} />
                  </DataTable>
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel header={Labels.KATEGORIJE_STATISTICKIH_IZVESTAJA}>
            <KategorijeStatistickihIzvestajaListView isSuperAdmin={isSuperadmin} />
          </TabPanel>
        </TabView>
      </Panel>
      <DialogComponent
        header={renderHeader}
        visible={isDialogOpen}
        onHide={closeDialog}
        footer={dialogFooter}
        className="w-8"
        content={
          <div>
            <div className="card">
              {repositoryIzvestajLoading ? (
                <SkeletonTable dataTableColumnsProperty={columnsRepositoryProperty} hasFilterColumns={true} />
              ) : (
                <DataTable
                  value={repositoryIzvestajList}
                  first={firstRepository}
                  rows={tableRepositoryRows}
                  filterDisplay="row"
                  className="p-datatable-flex-scrollable"
                  scrollHeight="flex"
                  paginatorTemplate={rowsPerPageDropdownTemplateMedium}
                  selectionMode="checkbox"
                  selection={selectedColumns}
                  onSelectionChange={(e) => setSelectedColumns(e.value)}
                  emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                  onPage={(e) => {
                    onPageRepositoryIzvestaj(e.rows, e.first);
                  }}
                  rowHover
                  stripedRows
                >
                  <Column filterHeaderClassName="pl-3" bodyClassName="w-3rem pl-3" selectionMode="multiple" header={Labels.CEKAONICA_COLUMN_SVE} headerClassName="w-3rem pl-3" />
                  <Column field={"naziv"} header={Labels.IZVESTAJ_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                  <Column field={"opis"} header={Labels.IZVESTAJ_OPIS} showFilterMenu={false} />
                  <Column field={"kategorijaStatistickogIzvestaja.naziv"} header={Labels.LABEL_KATEGORIJA_STATISTICKOG_IZVESTAJA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                </DataTable>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}
