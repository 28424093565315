import { Skeleton } from "primereact/skeleton";

interface SkeletonCircleListInRowType {
  size?: number;
}

export default function SkeletonCircleListInRow(props: SkeletonCircleListInRowType) {
  const { size } = props;

  return (
    <div className="flex">
      {[...Array(size ?? 1).keys()].map((j: number) => (
        <Skeleton key={j} borderRadius="15px" shape="circle" size="30px" className="mr-2" />
      ))}
    </div>
  );
}
