import { PickList } from "primereact/picklist";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { checkEmpty } from "../../../../infrastructure/system/Utils";
import SkeletonPickListItem from "../../../app/skeleton/SkeletonPickListItem";
import CrudRadnikUstanovaLogical, { RadnikUstanovaHolder } from "./CrudRadnikUstanovaLogical";

interface CrudRadnikUstanovaPropType {
  isDisabled: boolean;
  radnikOperation: string;
}
export default function CrudRadnikUstanova(props: CrudRadnikUstanovaPropType) {
  const { isDisabled, radnikOperation } = props;
  const { radnikUstanovaList, ustanovaList, onChangeRadnikUstanova, isBlocked, isLoading } = CrudRadnikUstanovaLogical(radnikOperation);
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const itemTemplate = (item: RadnikUstanovaHolder) => {
    if (checkEmpty(item) || isBlocked) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{item.ustanova?.naziv}</h6>;
  };

  return (
    <div className="layout-generic-content-list">
      <div className={!pristup || isDisabled || isBlocked || isLoading ? "read-only" : ""}>
        <PickList
          sourceHeader={Labels.USTANOVA_LIST}
          targetHeader={Labels.USTANOVA_RADNIK_LIST}
          source={ustanovaList}
          target={radnikUstanovaList}
          itemTemplate={itemTemplate}
          showSourceControls={false}
          showTargetControls={false}
          onChange={onChangeRadnikUstanova}
        ></PickList>
      </div>
    </div>
  );
}
