import { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { IzvestajParametarController } from "../../../../controllers/izvestaj/IzvestajParametarController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import { default as IzvestajParametarReadDto, default as IzvestajParametriReadDto } from "../../../../model/izvestaj/parametar/IzvestajParametarReadDto";

interface IzvestajParametarListLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  izvestajId: string | undefined;
  location: any;
  paramId: number | undefined;
  isDisabled: boolean;
  parametarList: Array<IzvestajParametriReadDto>;
  parametarOperation: string;
  navigate: any;
  first: number;
  onPageParametriIzvestaja: (rows: number, first: number) => void;
  parametarIzvestajaLoading: boolean;
  tableRows: number;
}

interface UseParamsType {
  paramId?: string;
  izvestajId?: string;
}

export default function IzvestajParametarListLogical(isSuperadmin?: boolean): IzvestajParametarListLogicalType {
  const location = useLocation();
  const navigate = useNavigate();
  const { showMessage } = useContext(AppContext);

  const Labels = useLabels();

  const paramId = Number(useParams<keyof UseParamsType>()["paramId"]);
  const { izvestajId }: UseParamsType = useParams<keyof UseParamsType>();

  const [parametarOperation] = useState<string>(
    location.state && location.state.parametarOperation !== undefined ? location.state.parametarOperation : !isNaN(paramId) ? EntityOperation.DELETE : EntityOperation.CREATE
  );
  const [isDisabled] = useState<boolean>(isFormDisabled(parametarOperation));
  const [parametarList, setParametarList] = useState<Array<IzvestajParametarReadDto>>([]);
  const [parametarIzvestajaLoading, setParametarIzvestajaLoading] = useState(false);
  const [tableRows, setTableRows] = useState<number>(10);
  const [first, setFirst] = useState<number>(0);

  const { axiosGetIzvestajParametarList } = IzvestajParametarController();
  const { axiosSuperadminGetParametriStatistickogIzvestaja } = SuperadminController();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.IZVESTAJ_LIST,
    },
  ];

  const onPageParametriIzvestaja = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };
  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = async () => {
    if (izvestajId) {
      let startTime = moment(new Date());
      setParametarIzvestajaLoading(true);
      await (isSuperadmin ? axiosSuperadminGetParametriStatistickogIzvestaja(Number(izvestajId)) : axiosGetIzvestajParametarList(Number(izvestajId)))
        .then((res: AxiosResponse) => {
          setParametarList(res.data.data);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setParametarIzvestajaLoading, startTime);
        });
    }
  };

  return {
    izvestajId,
    location,
    paramId,
    parametarOperation,
    first,
    onPageParametriIzvestaja,
    navigate,
    parametarIzvestajaLoading,
    parametarList,
    isDisabled,
    tableRows,
    breadCrumbItems,
  };
}
