import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import { Navigate } from "react-router-dom";
import { useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { addSrLocal, formatDate, isDateTimeFormatValid, setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import ObavestenjeOrganizacionaJedinicaReadDto from "../../../model/obavestenje/ObavestenjeOrganizacionaJedinicaReadDto";
import ObavestenjeUlogaReadDto from "../../../model/obavestenje/ObavestenjeUlogaReadDto";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CalendarComponent from "../calendar-component/CalendarComponent";
import CrudObavestenjeLogical from "./CrudObavestenjeLogical";

export default function CrudObavestenjePage() {
  const {
    obavestenjeChange,
    setObavestenjeChange,
    obavestenjeId,
    obavestenjeOperation,
    breadCrumbItems,
    index,
    location,
    setIndex,
    onCreate,
    obavestenjeLoading,
    ulogaList,
    organizacionaJedinicaList,
    isDisabled,
    isDropDownDisabled,
    onCancel,
    onDelete,
    uploadHandler,
    removeDatoteka,
    invalidFields,
    setInvalidFields,
  } = CrudObavestenjeLogical();
  addSrLocal();
  const Labels = useLabels();

  const chooseOptions = { label: Labels.LABEL_CHOOSE, icon: "pi pi-fw pi-plus", className: "p-button-outlined p-button-tertiary align-self-center" };
  const uploadOptions = { className: "hidden" };
  const cancelOptions = { className: "hidden" };
  const fu: any = useRef(null);
  const calendarRef = useRef<any>(null);

  if (!obavestenjeId && !location.state) {
    return <Navigate to="/obavestenje-list" />;
  }

  const leftContentsToolbar = () => (
    <>
      {obavestenjeOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.RADNIK_TITLE_DIALOG_CREATE} tooltipOptions={tooltipOptionsBottom} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}

      {obavestenjeOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={obavestenjeLoading}
          icon="pi pi-trash"
          tooltip={Labels.OBAVESTENJE_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );
  const rightContentsToolbar = () => <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={onCancel} />;
  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_OBAVESTENJE}>
            <div className="flex">
              <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
                <div className="grid align-items-center p-0">
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.OBAVESTENJE_NASLOV}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!obavestenjeLoading ? (
                      <>
                        <InputText
                          disabled={isDisabled}
                          value={obavestenjeChange?.naslov ?? ""}
                          onChange={(e) => {
                            setObavestenjeChange({
                              ...obavestenjeChange!,
                              naslov: e.target.value,
                            });
                          }}
                          onBlur={() => {
                            setInvalid(setInvalidFields, "naslov", obavestenjeChange?.naslov);
                          }}
                          className={invalidFields?.naslov && "p-invalid"}
                        />
                        {invalidFields?.naslov && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      </>
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.OBAVESTENJE_SADRZAJ}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!obavestenjeLoading ? (
                      <>
                        <InputText
                          disabled={isDisabled}
                          value={obavestenjeChange?.sadrzaj ?? ""}
                          onChange={(e) => {
                            setObavestenjeChange({
                              ...obavestenjeChange!,
                              sadrzaj: e.target.value,
                            });
                          }}
                          onBlur={() => {
                            setInvalid(setInvalidFields, "sadrzaj", obavestenjeChange?.sadrzaj);
                          }}
                          className={invalidFields?.sadrzaj && "p-invalid"}
                        />
                        {invalidFields?.sadrzaj && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      </>
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>{Labels.OBAVESTENJE_VAZI_DO}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!obavestenjeLoading ? (
                      <CalendarComponent
                        ref={calendarRef}
                        disabled={isDisabled}
                        showTime={true}
                        showButtonBar={false}
                        name="vremeVazenja"
                        value={obavestenjeChange?.vremeVazenja}
                        onChange={(e: CalendarChangeEvent) => {
                          setObavestenjeChange({
                            ...obavestenjeChange!,
                            vremeVazenja: formatDate(e.value as Date | undefined),
                          });
                        }}
                        onInput={(e) => {
                          if (isDateTimeFormatValid(e.currentTarget.value)) calendarRef?.current?.hide();
                        }}
                        placeholder={Labels.PLACEHOLDER_DATUM_VREME_FORMAT}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>{Labels.OBAVESTENJE_ULOGA}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!obavestenjeLoading ? (
                      <MultiSelect
                        value={
                          obavestenjeChange?.obavestenjeUlogaList?.map((ulogaObavestenje: ObavestenjeUlogaReadDto) => {
                            return ulogaObavestenje.uloga.id;
                          }) ?? ""
                        }
                        optionValue="id"
                        disabled={isDropDownDisabled}
                        options={ulogaList}
                        optionLabel="naziv"
                        onChange={(e) => {
                          let ulogaObavestenjeList = e.value.map((ulogaId: number) => {
                            return { uloga: { id: ulogaId } };
                          });
                          setObavestenjeChange({
                            ...obavestenjeChange!,
                            obavestenjeUlogaList: ulogaObavestenjeList,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>{Labels.OBAVESTENJE_ORGANIZACIONA_JEDINICA}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!obavestenjeLoading ? (
                      <MultiSelect
                        value={
                          obavestenjeChange?.obavestenjeOrganizacionaJedinicaList?.map((organizacionaJedinicaObavestenje: ObavestenjeOrganizacionaJedinicaReadDto) => {
                            return organizacionaJedinicaObavestenje.organizacionaJedinica.id;
                          }) ?? ""
                        }
                        optionValue="id"
                        disabled={isDropDownDisabled}
                        options={organizacionaJedinicaList}
                        optionLabel="naziv"
                        onChange={(e) => {
                          let organizacionaJedinicaObavestenjeList = e.value.map((organizacionaJedinicaId: number) => {
                            return { organizacionaJedinica: { id: organizacionaJedinicaId } };
                          });
                          setObavestenjeChange({
                            ...obavestenjeChange!,
                            obavestenjeOrganizacionaJedinicaList: organizacionaJedinicaObavestenjeList,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>{Labels.OBAVESTENJE_DATOTEKE}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    <FileUpload
                      name={Labels.OBAVESTENJE_DATOTEKE}
                      ref={fu}
                      chooseOptions={chooseOptions}
                      uploadOptions={uploadOptions}
                      cancelOptions={cancelOptions}
                      customUpload
                      auto
                      uploadHandler={(e) => {
                        uploadHandler(e);
                      }}
                      multiple
                      onRemove={(e) => {
                        let pom: any = e;

                        removeDatoteka(pom.file);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
