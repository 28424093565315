import { useContext } from "react";
import { AppContext } from "../../Store";
import KontrolaPravaPristupaService from "../../infrastructure/system/KontrolaPravaPristupaService";
import PacijentPoljeKategorijaReadDto from "../../model/pacijent-polje/PacijentPoljeKategorijaReadDto";
import PacijentReadDto from "../../model/pacijent/PacijentReadDto";
import SkeletonPoljeVrednostInRow from "../app/skeleton/SkeletonPoljeVrednostInRow";
import Polje from "../formular/polja/Polje";
import PacijentPoljeLogical from "./PacijentPoljeLogical";

interface PacijentPoljeViewType {
  kategorijaEnum: string;
  pacijent?: PacijentReadDto;
  pacijentRef?: any;
  setPoljePopunjenoLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchMedicinskiPodaciAndInformacijeOPacijentu: () => void;
}

export default function PacijentPoljeView(props: PacijentPoljeViewType) {
  const { kategorijaEnum, pacijent, pacijentRef, setPoljePopunjenoLoading, fetchMedicinskiPodaciAndInformacijeOPacijentu } = props;
  const { pacijentPoljeList, onChange, pacijentPoljePopunjeno, pacijentPoljeListLoading } = PacijentPoljeLogical({
    kategorijaEnum,
    pacijent,
    pacijentRef,
    setPoljePopunjenoLoading,
    fetchMedicinskiPodaciAndInformacijeOPacijentu,
  });
  const { pristup, authData } = useContext(AppContext);
  const { hasPacijentUpdate } = KontrolaPravaPristupaService();
  return (
    <>
      {!pacijentPoljeListLoading ? (
        <div className="w-full flex flex-column hc-gap-3 justify-content-between">
          {pacijentPoljeList
            .sort((a: PacijentPoljeKategorijaReadDto, b: PacijentPoljeKategorijaReadDto) => a?.pacijentPolje?.prikazniRedosled - b?.pacijentPolje?.prikazniRedosled)
            ?.map((polje: PacijentPoljeKategorijaReadDto, key: number) => {
              return (
                <Polje
                  key={polje.id}
                  id={polje.pacijentPolje.id}
                  naziv={polje.pacijentPolje.naziv}
                  tip={polje.pacijentPolje.poljeTip.tipKomponenteEnum.sifra}
                  obavezno={polje.obavezno}
                  readonly={polje.readOnly || !pristup || (pacijent?.radnikKreirao.id === authData?.currentRadnik.id && !hasPacijentUpdate)}
                  enumStavke={polje.pacijentPolje.poljeTip.poljeTipEnumStavkaList}
                  format={polje.pacijentPolje.poljeTip.format}
                  decimale={polje.pacijentPolje.poljeTip.decimale}
                  vrednost={pacijentPoljePopunjeno[polje.pacijentPolje.id]}
                  onChange={onChange}
                  bold={true}
                />
              );
            })}
        </div>
      ) : (
        <div>
          <SkeletonPoljeVrednostInRow size={3} />
        </div>
      )}
    </>
  );
}
