import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { useRef } from "react";
import { useLabels } from "../../../Store";
import CalendarComponent from "../calendar-component/CalendarComponent";
import { PatientType } from "./SpajanjePacijenataLogical";

interface PretragaPacijenataPropsType {
  patient: PatientType | undefined;
  setPatient: React.Dispatch<React.SetStateAction<PatientType | undefined>>;
  searchPatients: (searchParams: PatientType, tableRrows: number, page: number, isLeftTable: boolean) => void;
  tableRows: number;
  isLeftTable?: boolean;
  setFirst: React.Dispatch<React.SetStateAction<number>>;
  isSplitting?: boolean;
}

const PretragaPacijenata = (props: PretragaPacijenataPropsType) => {
  const { patient, setPatient, searchPatients, tableRows, isLeftTable, setFirst, isSplitting } = props;
  const Labels = useLabels();
  const datumRef = useRef<HTMLDivElement | any>(null);

  return (
    <div className="w-12">
      <div className="grid align-items-center">
        <div className="col-12 px-0 sm:col-3 sm:px-3">
          <div>{Labels.LABEL_SPAJANJE_PACIJENATA_IME + Labels.SPECIAL_CHAR_COLON}</div>
        </div>
        <div className="col-12 sm:col-9 p-fluid">
          <InputText
            value={patient?.ime ?? ""}
            onChange={(e) => {
              setPatient({
                ...patient!,
                ime: e.target.value.length > 0 ? e.target.value : undefined,
              });
            }}
          />
        </div>
      </div>
      <div className="grid align-items-center mt-2">
        <div className="col-12 px-0 sm:col-3 sm:px-3">
          <div>{Labels.LABEL_SPAJANJE_PACIJENATA_PREZIME + Labels.SPECIAL_CHAR_COLON}</div>
        </div>
        <div className="col-12 sm:col-9 p-fluid">
          <InputText
            value={patient?.prezime ?? ""}
            onChange={(e) => {
              setPatient({
                ...patient!,
                prezime: e.target.value.length > 0 ? e.target.value : undefined,
              });
            }}
          />
        </div>
      </div>
      <div className="grid align-items-center mt-2 mb-5">
        <div className="col-12 px-0 sm:col-3 sm:px-3">
          <div>{Labels.LABEL_SPAJANJE_PACIJENATA_DATUM + Labels.SPECIAL_CHAR_COLON}</div>
        </div>
        <div className="col-12 sm:col-9 p-fluid">
          <CalendarComponent
            ref={datumRef}
            name="datumOd"
            value={patient?.datumRodjenja}
            onChange={(e: CalendarChangeEvent) => {
              setPatient({
                ...patient!,
                datumRodjenja: e.value as Date,
              });
              if (e.target.value) datumRef?.current?.hide();
            }}
            className="w-full"
            onClearButtonClick={() =>
              setPatient({
                ...patient!,
                datumRodjenja: undefined,
              })
            }
          />
        </div>
      </div>
      <div className="px-0 sm:px-12 flex justify-content-end">
        {isSplitting && <Button label={Labels.RADNIK_PROFIL_BUTTON_CANCEL} className="p-button-outlined mr-5" onClick={() => setPatient(undefined)} />}
        <Button
          label={Labels.LABEL_SPAJANJE_PACIJENATA_PRETRAZI}
          className="p-button-info px-5"
          onClick={() => {
            searchPatients(patient!, tableRows, 0, isLeftTable ?? false);
            setFirst(0);
          }}
          disabled={!patient?.ime?.trim() && !patient?.prezime?.trim() && !patient?.datumRodjenja}
        />
      </div>
    </div>
  );
};

export default PretragaPacijenata;
