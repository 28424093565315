import { PickListChangeEvent } from "primereact/picklist";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { RadnikController } from "../../../../controllers/radnik/RadnikController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import { UlogaController } from "../../../../controllers/uloga/UlogaController";
import useCallbackWithoutWarnings from "../../../../infrastructure/system/hooks/useCallbackWithoutWarnings";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";

import RadnikSkracenoReadDto from "../../../../model/radnik/RadnikSkracenoReadDto";
import UlogaRadnikReadDto from "../../../../model/uloga/UlogaRadnikReadDto";
import UstanovaRadnikReadDto from "../../../../model/ustanova/UstanovaRadnikReadDto";
import { AppContext, useLabels } from "../../../../Store";

interface CrudUlogaRadnikLogicalType {
  ulogaRadnikList: Array<UlogaRadnikReadDto>;
  radnikList: Array<UlogaRadnikHolder | undefined>;
  onChangeUlogaRadnik: (event: PickListChangeEvent) => Promise<void>;
  isBlocked: boolean;
  isLoading: boolean;
}

export interface UlogaRadnikHolder {
  radnik: RadnikSkracenoReadDto;
}
interface UseParamsType {
  ulogaId?: string;
}

interface CrudUlogaRadnikLogicalPropType {
  ustanovaId?: number;
  ulogaNaziv?: string;
}

export default function CrudUlogaRadnikLogical(props: CrudUlogaRadnikLogicalPropType): CrudUlogaRadnikLogicalType {
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { ustanovaId, ulogaNaziv } = props;
  const { showMessage } = useContext(AppContext);
  const [ulogaRadnikList, setUlogaRadnikList] = useState<Array<UlogaRadnikReadDto>>([]);
  const [radnikList, setRadnikList] = useState<Array<UlogaRadnikHolder | undefined>>([]);
  const { axiosCreateRadnikUloga, axiosDeleteRadnikUloga, axiosSearchRadnik } = RadnikController();
  const { axiosGetUlogaRadnikList } = UlogaController();
  const { axiosSuperadminGetUlogaRadnikList, axiosSuperadminCreateRadnikUloga, axiosSuperadminDeleteRadnikUloga, axiosSuperadminGetUstanovaRadnikList } = SuperadminController();
  const { ulogaId } = useParams<keyof UseParamsType>();

  const postLogHighLevel = useLogHighLevel();
  const Labels = useLabels();

  const getRadnikList = useCallbackWithoutWarnings(
    (radnikUlogaList: Array<UlogaRadnikReadDto>) => {
      setIsLoading(true);
      let requestRadnik = ustanovaId ? axiosSuperadminGetUstanovaRadnikList(ustanovaId) : axiosSearchRadnik({});
      requestRadnik
        .then((res: any) => {
          let radnikListTemp: Array<UlogaRadnikHolder> = [];

          const allRadnik = ustanovaId
            ? (res.data.data as Array<UstanovaRadnikReadDto>).map((element: UstanovaRadnikReadDto) => {
                return element?.radnik;
              })
            : res.data.data;

          allRadnik.forEach((radnik: RadnikSkracenoReadDto) => {
            if (
              radnikUlogaList.filter((radnikUloga: UlogaRadnikHolder) => {
                return radnikUloga.radnik.id === radnik.id;
              }).length === 0
            ) {
              radnikListTemp.push({ radnik: radnik });
            }
          });
          setRadnikList(radnikListTemp);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [ustanovaId]
  );

  const getUlogaRadnik = useCallbackWithoutWarnings(() => {
    if (ulogaId !== null && ulogaId !== undefined) {
      setIsLoading(true);
      let requestGetUlogaRadnikList = ustanovaId ? axiosSuperadminGetUlogaRadnikList(ustanovaId, Number(ulogaId)) : axiosGetUlogaRadnikList(Number(ulogaId));
      requestGetUlogaRadnikList
        .then((res: any) => {
          const ulogaRadnikList: Array<UlogaRadnikReadDto> = res.data.data;

          setUlogaRadnikList(ulogaRadnikList);
          getRadnikList(ulogaRadnikList);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setIsLoading(false);
        });
    }
  }, [ulogaId, ustanovaId]);

  const fetchData = useCallbackWithoutWarnings(() => {
    getUlogaRadnik();
  }, [getUlogaRadnik]);

  useEffect(() => {
    if (ulogaId) {
      fetchData();
    }
  }, [fetchData, ulogaId]);

  const onChangeUlogaRadnik = async (event: PickListChangeEvent) => {
    if (isBlocked || isLoading) {
      return;
    }
    setIsBlocked(true);
    if (ulogaRadnikList.length < event.target.length) {
      await Promise.all(
        event.target.map(async (ulogaRadnik: UlogaRadnikHolder) => {
          if (
            !ulogaRadnikList
              .map((element: UlogaRadnikReadDto) => {
                return element?.radnik;
              })
              .includes(ulogaRadnik.radnik)
          ) {
            const radnikId: number = ulogaRadnik.radnik?.id;
            let requestCreateRadnikUloga =
              ulogaId &&
              (ustanovaId ? axiosSuperadminCreateRadnikUloga(ustanovaId, radnikId, Number(ulogaId)) : axiosCreateRadnikUloga(radnikId, { radnik: { id: radnikId }, uloga: { id: Number(ulogaId) } }));
            requestCreateRadnikUloga &&
              (await requestCreateRadnikUloga
                .then((res: any) => {
                  postLogHighLevel(
                    Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA + ulogaNaziv + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_RADNIK_1 + ulogaRadnik.radnik.ime + " " + ulogaRadnik.radnik.prezime + ". "
                  );
                })
                .catch((error: any) => {
                  handleAxiosCallError(showMessage, error);
                  setIsBlocked(false);
                }));
          }
        })
      );
      setUlogaRadnikList(event.target);
      setRadnikList(radnikList.filter((element: UlogaRadnikHolder | undefined) => element && !(event.target as Array<UlogaRadnikHolder>).includes(element)));
    } else if (radnikList.length < event.source.length) {
      await Promise.all(
        event.source.map(async (ulogaRadnik: UlogaRadnikHolder) => {
          if (!radnikList.includes(ulogaRadnik)) {
            const radnikId: number = ulogaRadnik.radnik?.id;
            let requestDeleteRadnikUloga = ustanovaId ? axiosSuperadminDeleteRadnikUloga(ustanovaId, radnikId, Number(ulogaId)) : axiosDeleteRadnikUloga(radnikId, Number(ulogaId));
            await requestDeleteRadnikUloga
              .then((res: any) => {
                postLogHighLevel(
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA + ulogaNaziv + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_RADNIK_2 + ulogaRadnik.radnik.ime + " " + ulogaRadnik.radnik.prezime + ". "
                );
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
                setIsBlocked(false);
              });
          }
        })
      );
      setRadnikList(radnikList.concat(ulogaRadnikList.filter((element: UlogaRadnikHolder | undefined) => element && !(event.target as Array<UlogaRadnikHolder>).includes(element))));
      setUlogaRadnikList(event.target);
    }
    setIsBlocked(false);
  };
  return {
    onChangeUlogaRadnik,
    ulogaRadnikList,
    radnikList,
    isBlocked,
    isLoading,
  };
}
