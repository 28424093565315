import { BreadCrumb } from "primereact/breadcrumb";
import { InputSwitch } from "primereact/inputswitch";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useMemo } from "react";
import { useLabels } from "../../Store";
import GenerisaniIzvestajKategorija from "./GenerisaniIzvestajKategorijaView";
import GenerisaniIzvestajLogical, { GenerisaniIzvestajContext } from "./GenerisaniIzvestajLogical";
import StatistickiIzvestajParametarInput from "./StatistickiIzvestajParametarInputView";
import DialogGenerisaniIzvestaj from "./dialog-gen-izvestaj/DialogGenerisaniIzvestaj";
import GenerisaniIzvestajListLogical from "./generisani-izvestaji/GenerisaniIzvestajListLogical";
import GenerisaniIzvestajList from "./generisani-izvestaji/GenerisaniIzvestajListView";
import ZakazaniIzvestajListLogical from "./zakazani-izvestaji/ZakazaniIzvestajListLogical";
import ZakazaniIzvestajList from "./zakazani-izvestaji/ZakazaniIzvestajListView";

export default function GenerisaniIzvestaj() {
  const { periodList, ponavljanjeList, radniDaniList, breadCrumbItems, stateDialog, dispatchDialog } = GenerisaniIzvestajLogical();

  const { state, dispatch, fetchData, onChange, generisiIzvestaj, onExport, index, setIndex } = GenerisaniIzvestajListLogical();

  const {
    izmeniIzvestaj,
    stateZakazani,
    dispatchZakazani,
    fetchZakazaniData,
    onChangeZakazani,
    getZakazaniIzvestajParams,
    zakaziIzvestaj,
    zakazanIzvestajChange,
    setZakazanIzvestajChange,
    addEmail,
    removeEmail,
    onCancel,
    invalidFields,
    setInvalidFields,
  } = ZakazaniIzvestajListLogical();
  const Labels = useLabels();

  const leftToolbarContent = useMemo(
    () => <div className="mr-3 font-bold">{index === 0 ? Labels.GENERISANI_IZVESTAJI_TITLE : Labels.ZAKAZANI_IZVESTAJI_TITLE}</div>,
    // eslint-disable-next-line
    [index]
  );

  const rightToolbarContent = (
    <div className="flex ">
      <span className="flex align-items-center justify-content-center mr-3 font-bold">{Labels.SWITCH_LABEL}</span>
      <InputSwitch checked={state.onlyMine} onChange={onChange} />
    </div>
  );

  const rightToolbarZakazaniContent = (
    <div className="flex ">
      <span className="flex align-items-center justify-content-center mr-3 font-bold">{Labels.SWITCH_LABEL}</span>
      <InputSwitch checked={stateZakazani.onlyMine} onChange={onChangeZakazani} />
    </div>
  );

  return (
    <GenerisaniIzvestajContext.Provider
      value={{
        state,
        dispatch,
        fetchData,
        onExport,
        generisiIzvestaj,
        dispatchDialog,
        izmeniIzvestaj,
        zakaziIzvestaj,
        stateZakazani,
        dispatchZakazani,
        fetchZakazaniData,
        getZakazaniIzvestajParams,
        zakazanIzvestajChange,
        setZakazanIzvestajChange,
        addEmail,
        onCancel,
        removeEmail,
        index,
      }}
    >
      <div className="layout-generic-content-list layout-izvestaj">
        <Panel>
          <BreadCrumb model={breadCrumbItems} className="mb-3" />
          <div className="flex flex-column md:flex-row">
            <GenerisaniIzvestajKategorija />

            <div className="col-12 flex flex-column hc-gap-4 md:col-9">
              <p className="p-card-title">{state.selectedStatistickiIzvestaj && Labels.IZVESTAJ_PARAMETRI}</p>
              <div>
                {(state.selectedStatistickiIzvestaj || stateZakazani.editSelected || stateZakazani.selectedIzvestajForEdit) && (
                  <StatistickiIzvestajParametarInput
                    periodList={periodList}
                    ponavljanjeList={ponavljanjeList}
                    radniDaniList={radniDaniList}
                    invalidFields={invalidFields}
                    setInvalidFields={setInvalidFields}
                  />
                )}
              </div>
              <TabView
                className="mt-3"
                renderActiveOnly={false}
                activeIndex={index}
                onTabChange={(e) => {
                  setIndex(e.index);
                }}
              >
                <TabPanel header={Labels.GENERISANI_IZVESTAJ_LIST.toUpperCase()}>
                  <Toolbar className="border-none" left={leftToolbarContent} right={rightToolbarContent} />
                  <div className="col-12 p-0 border-none">
                    <GenerisaniIzvestajList />
                  </div>
                </TabPanel>
                <TabPanel header={Labels.ZAKAZIVANI_IZVESTAJI.toUpperCase()}>
                  <Toolbar className="border-none" left={leftToolbarContent} right={rightToolbarZakazaniContent} />
                  <div className="col-12 p-0 border-none">
                    <ZakazaniIzvestajList radniDaniList={radniDaniList} />
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
          <DialogGenerisaniIzvestaj state={stateDialog} dispatch={dispatchDialog} />
        </Panel>
      </div>
    </GenerisaniIzvestajContext.Provider>
  );
}
