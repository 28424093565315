const ZAKAZIVANJE_PREGLEDA_INDEX = {
  ZAKAZIVANJE: 0,
  CEKAONICA: 1,
};

const KONTAKT_INDEX = {
  DOKUMENTACIJA: 0,
  OPSTI_MEDICINSKI_PODACI: 1,
  PODACI_O_PACIJENTU: 2,
};

const KONTAKT_DOKUMENTACIJA_INDEX = {
  UNOSI: 0,
  DIJAGNOZE: 1,
  TERAPIJE: 2,
  USLUGE: 3,
  UTROSCI: 4,
  PAKETI: 5,
};

const UNOS_INDEX = {
  PRETHODNI_NALAZI: 0,
  SABLONI: 1,
};

const GENERISANI_IZVESTAJ_INDEX = {
  PARAMETRI: 0,
  GENERISANI_IZVESTAJI: 1,
};

const PORUKE_INDEX = {
  PORUKE_NOVE: 0,
  PORUKE_PRIMLJENE: 1,
  PORUKE_POSLATE: 2,
};

export { GENERISANI_IZVESTAJ_INDEX, KONTAKT_DOKUMENTACIJA_INDEX, KONTAKT_INDEX, PORUKE_INDEX, UNOS_INDEX, ZAKAZIVANJE_PREGLEDA_INDEX };
