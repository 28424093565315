//import { Prompt } from "react-router-dom";
import KontaktReadDto from "../../model/kontakt/KontaktReadDto";
import FormularLeaveDialog from "../formular/formular-leave-dialog/FormularLeaveDialog";
import RouteLeavingGuardLogical from "./RouteLeavingGuardLogical";

interface RouteLeavingGuardProps {
  leaveDisabled?: boolean;
  poljeVrednostListRef: React.MutableRefObject<any>;
  kontakt: KontaktReadDto;
  izabraniTipUnosaRef: React.MutableRefObject<any>;
  izabraniPopunjeniUnosRef: React.MutableRefObject<any>;
}

// TODO: naci nacin kako da se hendla prompt jer u novoj verziji rutera nema tog property-a, koliko vidim user je svakako promptovan i bez ovoga

export default function RouteLeavingGuard(props: RouteLeavingGuardProps) {
  const { leaveDisabled, poljeVrednostListRef, kontakt, izabraniTipUnosaRef, izabraniPopunjeniUnosRef } = props;
  const {
    visibleDialog,
    closeDialog,
    //disableLeave,
    confirmLeave,
  } = RouteLeavingGuardLogical({ leaveDisabled });

  return (
    <>
      <FormularLeaveDialog
        izabraniPopunjeniUnosRef={izabraniPopunjeniUnosRef}
        izabraniTipUnosaRef={izabraniTipUnosaRef}
        kontakt={kontakt}
        poljeVrednostListRef={poljeVrednostListRef}
        visibleDialog={visibleDialog}
        closeDialog={closeDialog}
        confirmLeave={confirmLeave}
      />
      {/*<Prompt message={disableLeave} />*/}
    </>
  );
}
