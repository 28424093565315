import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import SifarnikAtributaRfzoReadDto from "../../../model/sifarnik/sifarnik-atributa-rfzo/SifarnikAtributaRfzoReadDto";

interface SifarnikAtributaRfzoLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  fetchData: () => void;
  searchSifarnikAtributaRfzoByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  sifarnikAtributaRfzoSearchData: SearchBaseDto;
  changeSifarnikAtributaRfzoSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  sifarnikAtributaRfzoLoading: boolean;
  exportData: Array<any>;
  sifarnikAtributaRfzoList: Array<SifarnikAtributaRfzoReadDto>;
  first: number;
  tableRows: number;
  onPageSifarnikAtributaRfzo: (rows: number, first: number) => void;
}

export default function SifarnikAtributaRfzoLogical(): SifarnikAtributaRfzoLogicalType {
  const Labels = useLabels();
  const { showMessage } = useContext(AppContext);
  const [sifarnikAtributaRfzoSearchData, setSifarnikAtributaRfzoSearchData] = useState<SearchBaseDto>({});
  const [sifarnikAtributaRfzoLoading, setSifarnikAtributaRfzoLoading] = useState(false);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [sifarnikAtributaRfzoList, setSifarnikAtributaRfzoList] = useState<Array<SifarnikAtributaRfzoReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const { axiosSuperadminSearchSifarnikAtributaRFZO } = SuperadminController();
  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.SIFARNIK_ATRIBUTA_RFZO,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setSifarnikAtributaRfzoLoading(true);
    axiosSuperadminSearchSifarnikAtributaRFZO(sifarnikAtributaRfzoSearchData)
      .then(({ data: { data } }: { data: { data: Array<SifarnikAtributaRfzoReadDto> } }) => {
        setSifarnikAtributaRfzoList(data);
        let exportList = new Array<any>();
        data.forEach((sifarnikAtributaRfzo: SifarnikAtributaRfzoReadDto) => {
          exportList.push({
            [Labels.SIFARNIK_ATRIBUTA_RFZO_SIFRA]: sifarnikAtributaRfzo.sifra,
            [Labels.SIFARNIK_ATRIBUTA_RFZO_NAZIV]: sifarnikAtributaRfzo.naziv,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSifarnikAtributaRfzoLoading, startTime);
      });
  };

  const searchSifarnikAtributaRfzoByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const changeSifarnikAtributaRfzoSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSifarnikAtributaRfzoSearchData({
      ...sifarnikAtributaRfzoSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const onPageSifarnikAtributaRfzo = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    breadCrumbItems,
    fetchData,
    searchSifarnikAtributaRfzoByEnter,
    sifarnikAtributaRfzoSearchData,
    changeSifarnikAtributaRfzoSearchData,
    onPageSifarnikAtributaRfzo,
    sifarnikAtributaRfzoLoading,
    exportData,
    tableRows,
    sifarnikAtributaRfzoList,
    first,
  };
}
