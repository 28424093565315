import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import UlogaReadDto from "../../../model/uloga/UlogaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import UlogaListLogical from "./UlogaListLogical";

export default function UlogaListPage() {
  const { ulogaList, first, tableRows, changeUlogaSearchData, ulogaSearchData, fetchData, searchUlogaByEnter, exportData, breadCrumbItems, onPageUloga, ulogaLoading, enumRolaList } =
    UlogaListLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMini } = PageDropdownTemplate();

  const openUloga = (entityOperation: string, id?: number) => {
    const idUloga = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-uloga/${idUloga}`, {
      state: { ulogaOperation: entityOperation },
    });
  };

  const crudBodyTemplate = (rowUloga: UlogaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.ULOGA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowUloga && openUloga(EntityOperation.UPDATE, rowUloga.id)}
        />
        <Button
          type="button"
          tooltip={Labels.ULOGA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => {
            rowUloga && openUloga(EntityOperation.DELETE, rowUloga.id);
          }}
          disabled={!pristup}
        />
      </div>
    );
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_ULOGA}
          tooltip={Labels.ULOGA_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openUloga(EntityOperation.CREATE);
          }}
          disabled={!pristup}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText id="searchString" name="searchString" className="search-input" value={ulogaSearchData?.searchString ?? ""} onChange={changeUlogaSearchData} onKeyPress={searchUlogaByEnter} />
          <label htmlFor="searchString">{Labels.SEARCH_ULOGA}</label>
          <Button tooltip={Labels.ULOGA_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };
  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={ulogaLoading} data={exportData} name={Labels.ULOGA_LIST} dt={dt} />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.ULOGA_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.ULOGA_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.ULOGA_OPIS, filter: true, sortrable: true },
    { columnName: Labels.ULOGA_NAPOMENA, filter: true, sortrable: true },
    { columnName: Labels.ULOGA_ROLA, filter: true, sortrable: true },
  ];

  const ulogaFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={enumRolaList.map((e) => {
          return { label: e.naziv, value: e.naziv };
        })}
        onChange={(e) => dt.current.filter(e.value, "rola.naziv", "equals")}
        className="p-column-filter"
        showClear
      />
    );
  };

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {ulogaLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={ulogaList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMini as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageUloga(e.rows, e.first);
            }}
            exportFilename={Labels.ULOGA_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.ULOGA_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.ULOGA_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"opis"} header={Labels.ULOGA_OPIS} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"napomena"} header={Labels.ULOGA_NAPOMENA} filter filterMatchMode="contains" showFilterMenu={false} className="wrap-line-anywhere" sortable />
            <Column field={"rola.naziv"} header={Labels.ULOGA_ROLA} filter filterMatchMode="contains" showFilterMenu={false} sortable filterElement={ulogaFilterTemplate} />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
