import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IzvestajParametarController } from "../../../../controllers/izvestaj/IzvestajParametarController";

import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, useEffectOnce, validateStringEmpty } from "../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import IzvestajParametarEnumStavkaCreateDto from "../../../../model/izvestaj/parametar/IzvestajParametarEnumStavkaCreateDto";
import IzvestajParametarEnumStavkaReadDto from "../../../../model/izvestaj/parametar/IzvestajParametarEnumStavkaReadDto";
import IzvestajParametarEnumStavkaUpdateDto from "../../../../model/izvestaj/parametar/IzvestajParametarEnumStavkaUpdateDto";
import IzvestajParametarReadDto from "../../../../model/izvestaj/parametar/IzvestajParametarReadDto";

import { AxiosResponse } from "axios";
import { RolaService } from "../../../../controllers/rola/RolaService";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import { AppContext, useLabels } from "../../../../Store";

interface CrudPoljeTipEnumStavkaLogicalType {
  parametarEnumStavkaChange: IzvestajParametarEnumStavkaReadDto | IzvestajParametarEnumStavkaCreateDto | IzvestajParametarEnumStavkaUpdateDto | undefined;
  setParametarEnumStavkaChange: any;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  isDisabled: boolean;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  parametarEnumStavkaOperation: string;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
}

interface UseParamsType {
  izvestajId?: string;
  paramId?: string;
  paramEnumStavkaId?: string;
}

export default function CrudPoljeTipEnumStavkaLogical(): CrudPoljeTipEnumStavkaLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const [exportData] = useState<Array<any>>([]);

  const izvestajId = Number(useParams<keyof UseParamsType>()["izvestajId"]);
  const parametarId = Number(useParams<keyof UseParamsType>()["paramId"]);
  const parametarEnumStavkaId = Number(useParams<keyof UseParamsType>()["paramEnumStavkaId"]);

  const location = useLocation();
  const [index, setIndex] = useState<number>(0);
  const navigate = useNavigate();
  const [isDisabled] = useState(isFormDisabled(location.state ? location.state.parametarEnumStavkaOperation : EntityOperation.UPDATE));
  const [izvestajParametarOperation] = useState(location.state ? location.state.izvestajParametarOperation : "");

  const [parametarEnumStavkaChange, setParametarEnumStavkaChange] = useState<any>();

  const [parametarNaziv, setParametarNaziv] = useState(location.state && location.state.parametarNaziv !== undefined ? location.state.parametarNaziv : "");

  const [izvestajNaziv] = useState(location.state && location.state.izvestajNaziv !== undefined ? location.state.izvestajNaziv : "");
  const [currentParametar, setCurrentParametar] = useState<IzvestajParametarReadDto | undefined>();
  const [parametarEnumStavkaOperation] = useState<string>(location.state ? location.state.parametarEnumStavkaOperation : EntityOperation.UPDATE);

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.IZVESTAJ_LIST,
      command: () => {
        navigate("/izvestaj-list");
      },
    },
    {
      label: Labels.IZVESTAJ_TITLE_DIALOG_UPDATE + " " + izvestajNaziv,
      command: () => {
        navigate(`/crud-izvestaj/${izvestajId}`, {
          state: {
            izvestajNaziv: izvestajNaziv,
          },
        });
      },
    },
  ]);
  const { axiosGetIzvestajParametar, axiosGetIzvestajParametarEnumStavka, axiosUpdateIzvestajParametarEnumStavka } = IzvestajParametarController();
  const {
    axiosSuperadminGetParametarStatistickogIzvestaja,
    axiosSuperadminGetIzvestajParametarEnumStavka,
    axiosSuperadminDeleteIzvestajParametarEnumStavka,
    axiosSuperadminUpdateIzvestajParametarEnumStavka,
    axiosSuperadminCreateIzvestajParametarEnumStavka,
  } = SuperadminController();
  const { isSuperadmin } = RolaService();

  const postLogHighLevel = useLogHighLevel();
  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = async () => {
    setShowBlockUI(true);
    (isSuperadmin ? axiosSuperadminGetParametarStatistickogIzvestaja(izvestajId, parametarId) : axiosGetIzvestajParametar(parametarId))
      .then((res) => {
        setCurrentParametar(res.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
    if (parametarEnumStavkaId) {
      (isSuperadmin ? axiosSuperadminGetIzvestajParametarEnumStavka(izvestajId, parametarId, parametarEnumStavkaId) : axiosGetIzvestajParametarEnumStavka(parametarId, parametarEnumStavkaId))
        .then((res: AxiosResponse) => {
          setParametarNaziv(res.data.data.statistickiIzvestajParametar.naziv);
          setParametarEnumStavkaChange(res.data.data);
          setBreadCrumbItems([
            ...breadCrumbItems,
            {
              label:
                izvestajParametarOperation === EntityOperation.UPDATE
                  ? Labels.LABEL_UPDATE_IZVESTAJ_PARAMETAR + parametarNaziv
                  : izvestajParametarOperation === EntityOperation.READ
                  ? Labels.LABEL_DETAILS_IZVESTAJ_PARAMETAR + parametarNaziv
                  : Labels.LABEL_DELETE_IZVESTAJ_PARAMETAR + parametarNaziv,

              command: () => {
                navigate(`/crud-izvestaj/${izvestajId}/crud-parametri-izvestaja/${parametarId}`, {
                  state: { tabIndex: 1, izvestajParametarOperation, izvestajNaziv: izvestajNaziv, parametarNaziv },
                });
              },
            },
            {
              label:
                parametarEnumStavkaOperation === EntityOperation.UPDATE
                  ? Labels.LABEL_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA + res.data.data.naziv
                  : parametarEnumStavkaOperation === EntityOperation.READ
                  ? Labels.LABEL_DETAILS_IZVESTAJ_PARAMETAR_ENUM_STAVKA + res.data.data.naziv
                  : Labels.LABEL_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA + res.data.data.naziv,
            },
          ]);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    } else {
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.LABEL_UPDATE_IZVESTAJ_PARAMETAR + parametarNaziv,
          command: () => {
            navigate(`/crud-izvestaj/${izvestajId}/crud-parametri-izvestaja/${parametarId}`, {
              state: { tabIndex: 1, izvestajParametarOperation, izvestajNaziv: izvestajNaziv, parametarNaziv },
            });
          },
        },
        {
          label: Labels.LABEL_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA,
        },
      ]);
    }
  };

  const onCreate = () => {
    if (!validateParametarEnumStavka(parametarEnumStavkaChange)) {
      return;
    }
    if (parametarEnumStavkaChange && currentParametar) {
      parametarEnumStavkaChange.statistickiIzvestajParametar = currentParametar;
    }

    if (parametarEnumStavkaChange) {
      setShowBlockUI(true);
      axiosSuperadminCreateIzvestajParametarEnumStavka(izvestajId, parametarId, parametarEnumStavkaChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS);
          onCancel();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_1 +
              parametarNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_2 +
              izvestajNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_CREATE +
              parametarEnumStavkaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_4 +
              parametarEnumStavkaChange?.sifra +
              "."
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    if (parametarEnumStavkaChange) {
      setShowBlockUI(true);
      axiosSuperadminDeleteIzvestajParametarEnumStavka(izvestajId, parametarId, parametarEnumStavkaId)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS);
          onCancel();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_1 +
              parametarNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_2 +
              izvestajNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_DELETE +
              parametarEnumStavkaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_4 +
              parametarEnumStavkaChange?.sifra +
              "."
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const validateParametarEnumStavka = (parametarEnumStavka?: IzvestajParametarEnumStavkaReadDto) => {
    if (validateStringEmpty(parametarEnumStavka?.sifra)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_SIFRA_REQUIRED);
      return false;
    }
    if (validateStringEmpty(parametarEnumStavka?.naziv)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_NAZIV_REQUIRED);
      return false;
    }
    return true;
  };

  const onUpdate = () => {
    if (!validateParametarEnumStavka(parametarEnumStavkaChange)) {
      return;
    }
    if (parametarEnumStavkaChange && currentParametar) {
      parametarEnumStavkaChange.statistickiIzvestajParametar = currentParametar;
    }
    if (parametarEnumStavkaChange) {
      setShowBlockUI(true);
      (isSuperadmin
        ? axiosSuperadminUpdateIzvestajParametarEnumStavka(izvestajId, parametarEnumStavkaChange)
        : axiosUpdateIzvestajParametarEnumStavka(parametarEnumStavkaChange, parametarId, parametarEnumStavkaId)
      )
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_1 +
              parametarNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_2 +
              izvestajNaziv +
              Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_UPDATE +
              parametarEnumStavkaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_4 +
              parametarEnumStavkaChange?.sifra +
              "."
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/crud-izvestaj/${izvestajId}/crud-parametri-izvestaja/${parametarId}`, { state: { tabIndex: 1, parametarOperation: izvestajParametarOperation } });
    }
  };

  return {
    parametarEnumStavkaChange,
    setParametarEnumStavkaChange,
    setIndex,
    index,
    isDisabled,
    exportData,
    breadCrumbItems,
    parametarEnumStavkaOperation,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
  };
}
