import { SifarnikUslugaController } from "../../../../../controllers/sifarnik-usluga/SifarnikUslugaController";
import { useQuery } from "@tanstack/react-query";
import QueryKeys from "../../../QueryKeys";
import SearchBaseDto from "../../../../../model/SearchBaseDto";
import SifarnikUslugaReadDto from "../../../../../model/sifarnik-usluga/SifarnikUslugaReadDto";

export function useSifarnikUslugaList(searchParam?: SearchBaseDto) {
  const { axiosSearchSifarnikUsluga } = SifarnikUslugaController();

  const { data, isLoading, refetch } = useQuery({
    queryKey: [QueryKeys.SIFARNIK_USLUGA_LIST],
    queryFn: () => axiosSearchSifarnikUsluga(searchParam ?? {}),
  });

  const sifarnikUslugaList: Array<SifarnikUslugaReadDto> = data?.data?.data ?? [];

  return { sifarnikUslugaList, refetch, isLoading };
}
