import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../Store";
import { FormularController } from "../../controllers/formular/FormularController";
import EnumTipKomponente from "../../infrastructure/system/EnumTipKomponente";
import MessageType from "../../infrastructure/system/MessageType";
import { NESACUVAN_NALAZ, handleAxiosCallError } from "../../infrastructure/system/Utils";
import { UNOS_INDEX } from "../../model/IndeksiTabovaNaStrani";
import NesacuvanNalaz from "../../model/NesacuvanNalaz/NesacuvanNalaz";
import FormularPopunjenReadDto from "../../model/formular/FormularPopunjenReadDto";
import KontaktReadDto from "../../model/kontakt/KontaktReadDto";
import PoljePopunjenoSkracenoDto from "../../model/polje/PoljePopunjenoSkracenoReadDto";
import PoljeReadDto from "../../model/polje/PoljeReadDto";
import PoljeVrednostDto from "../../model/polje/PoljeVrednostDto";
import { PoljeType } from "./polja/Polje";
import { AxiosResponse } from "axios";

interface FormularLogicalType {
  poljeList: Array<PoljeReadDto>;
  onChange: (poljeVrednost: PoljeVrednostDto) => void;
  save: () => void;
  poljeVrednostList?: valueMap;
  setPoljeVrednostList: React.Dispatch<React.SetStateAction<valueMap>>;
  activeIndex: number;
  selectedPoljeId?: number;
  selectedPoljeNaziv: string | undefined;
  onPoljeClick: (id: number) => void;
  poljeListLoading: boolean;
  poljeVrednostListLoading: boolean;
  poljeTypeList: Array<PoljeType>;
}

export type valueMap = {
  [key: string]: any;
};
interface FormularLogicalProps {
  formularId: number;
  formularPopunjen?: FormularPopunjenReadDto;
  afterSave: () => void;
  openClosePrethodniSabloniNalazi: (openClose: boolean) => void;
  setClickOutsideDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  nesacuvanNalazState: NesacuvanNalaz | undefined;
  setNesacuvanNalazState: React.Dispatch<React.SetStateAction<NesacuvanNalaz | undefined>>;
  kontakt: KontaktReadDto;
  setDisabledActionButtons: React.Dispatch<React.SetStateAction<boolean>>;
  formatPoljePopunjenoList: (poljePopunjeno: PoljePopunjenoSkracenoDto[]) => valueMap;
}
export default function FormularLogical(props: FormularLogicalProps): FormularLogicalType {
  const {
    formularId,
    formularPopunjen,
    afterSave,
    openClosePrethodniSabloniNalazi,
    setClickOutsideDisabled,
    nesacuvanNalazState,
    setNesacuvanNalazState,
    kontakt,
    setDisabledActionButtons,
    formatPoljePopunjenoList,
  } = props;
  const { id }: any = useParams();
  const Labels = useLabels();
  const [poljeList, setPoljeList] = useState<Array<PoljeReadDto>>([]);
  const [poljeVrednostList, setPoljeVrednostList] = useState<valueMap | undefined>(nesacuvanNalazState?.poljeVrednostList ?? undefined);
  const [activeIndex] = useState<number>(UNOS_INDEX.PRETHODNI_NALAZI);
  const [selectedPoljeId, setSelectedPoljeId] = useState<number | undefined>();
  const [selectedPoljeNaziv, setSelectedPoljeNaziv] = useState<string | undefined>();
  const [poljeListLoading, setPoljeListLoading] = useState<boolean>(true);
  const [poljeVrednostListLoading, setPoljeVrednostListLoading] = useState<boolean>(false);
  const [isInsertedInitValue, setIsInsertedInitValue] = useState(false);
  const [poljeTypeList, setPoljeTypeList] = useState<Array<PoljeType>>([]);
  const [isPoljeVrednostFiltered, setIsPoljeVrednostFiltered] = useState(false);

  const navigate = useNavigate();
  const { axiosGetPoljeList, axiosGetPoljePopunjenoList, axiosCreateFormularPopunjen, axiosUpdateFormularPopunjen } = FormularController();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const poljeVrednostRef = useRef<valueMap | undefined>(poljeVrednostList);

  const getPoljeTypeList = (poljeNadredjenoId: number, poljeList: Array<PoljeReadDto>) => {
    let poljeListTemp = poljeList.filter((polje) => polje?.poljeNadredjeno && polje?.poljeNadredjeno?.id === poljeNadredjenoId);
    let poljeTypeListTemp: Array<PoljeType> = [];

    poljeListTemp.forEach((polje: PoljeReadDto) => {
      let poljeTypeLocal: Array<PoljeType> | undefined;
      if (polje.poljeTip.tipKomponenteEnum.sifra === EnumTipKomponente.PANEL) {
        poljeTypeLocal = getPoljeTypeList(polje.id, poljeList);
      }
      poljeTypeListTemp = [
        ...poljeTypeListTemp,
        {
          id: polje.id,
          naziv: polje.naziv,
          tip: polje.poljeTip.tipKomponenteEnum.sifra,
          obavezno: polje.obavezno,
          enumStavke: polje.poljeTip.poljeTipEnumStavkaList,
          format: polje.poljeTip.format,
          decimale: polje.poljeTip.decimale,
          loading: poljeVrednostListLoading,
          vrednost:
            polje.poljeTip.tipKomponenteEnum.sifra === EnumTipKomponente.BOOLEAN && poljeVrednostList === undefined
              ? false
              : polje.poljeTip.tipKomponenteEnum.sifra === EnumTipKomponente.BOOLEAN && poljeVrednostList !== undefined && poljeVrednostList[polje.id] === undefined
                ? (poljeVrednostList[polje.id] = false)
                : poljeVrednostList && poljeVrednostList[polje.id],
          poljeList: poljeTypeLocal,
          onChange: onChange,
          onClick: onPoljeClick,
        },
      ];
    });
    return poljeTypeListTemp;
  };

  const getAllFieldList = () => {
    setPoljeListLoading(true);
    const id = formularId ? formularId : formularPopunjen?.formular.id;
    const kontaktID = kontakt?.id;
    if (id)
      axiosGetPoljeList(id, kontaktID)
        .then((res: AxiosResponse) => {
          let poljeListTemp: Array<PoljeReadDto> = res.data.data;
          let poljeTypeListTemp: Array<PoljeType> = [];
          poljeListTemp.forEach((polje: PoljeReadDto) => {
            if (polje.poljeNadredjeno === undefined || polje.poljeNadredjeno === null) {
              poljeTypeListTemp = [
                ...poljeTypeListTemp,
                {
                  id: polje.id,
                  naziv: polje.naziv,
                  tip: polje.poljeTip.tipKomponenteEnum.sifra,
                  obavezno: polje.obavezno,
                  enumStavke: polje.poljeTip.poljeTipEnumStavkaList,
                  format: polje.poljeTip.format,
                  decimale: polje.poljeTip.decimale,
                  loading: poljeVrednostListLoading,
                  vrednost:
                    polje.poljeTip.tipKomponenteEnum.sifra === EnumTipKomponente.BOOLEAN && poljeVrednostList === undefined
                      ? false
                      : polje.poljeTip.tipKomponenteEnum.sifra === EnumTipKomponente.BOOLEAN && poljeVrednostList !== undefined && poljeVrednostList[polje.id] === undefined
                        ? (poljeVrednostList[polje.id] = false)
                        : poljeVrednostList && poljeVrednostList[polje.id],
                  poljeList: getPoljeTypeList(polje.id, poljeListTemp),
                  onChange: onChange,
                  onClick: onPoljeClick,
                },
              ];
            }
          });
          poljeListTemp.forEach((polje: PoljeReadDto) => {
            if (polje.poljeTip.tipKomponenteEnum.sifra === EnumTipKomponente.SET || polje.poljeTip.tipKomponenteEnum.sifra === EnumTipKomponente.SET_RADIO) {
              if (polje.inicijalnaVrednostEnum !== undefined && polje.inicijalnaVrednostEnum !== null) {
                if (!polje.poljeTip.poljeTipEnumStavkaList.some((value) => value.id === polje.inicijalnaVrednostEnum)) {
                  polje.inicijalnaVrednostEnum = undefined;
                }
              }
            }
          });
          setPoljeList(poljeListTemp);
          setPoljeTypeList(poljeTypeListTemp);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setPoljeListLoading(false);
        });
  };

  const getPoljePopunjenoList = () => {
    let poljeVrednost: valueMap = {};

    if (formularPopunjen && !nesacuvanNalazState) {
      setPoljeVrednostListLoading(true);
      axiosGetPoljePopunjenoList(formularPopunjen.id)
        .then((res: AxiosResponse) => {
          poljeVrednost = formatPoljePopunjenoList(res.data.data);
          setPoljeVrednostList(poljeVrednost);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setPoljeVrednostListLoading(false);
          setDisabledActionButtons(false);
        });
    }
  };

  useEffect(() => {
    if (poljeVrednostList && !poljeListLoading && !isPoljeVrednostFiltered) {
      let poljeIdList = poljeList.map((polje) => {
        return polje.id.toString();
      });
      let poljeVrednostListNew = Object.entries(poljeVrednostList);
      poljeVrednostListNew = poljeVrednostListNew.filter(([key, value]) => poljeIdList.includes(key));
      setPoljeVrednostList(Object.fromEntries(poljeVrednostListNew));
      setIsPoljeVrednostFiltered(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poljeVrednostList, poljeList, poljeListLoading]);

  const insertInitValue = (poljeList: PoljeReadDto[]) => {
    const poljeVrednostListWithInitValue: valueMap = {};
    setIsInsertedInitValue(true);
    poljeList.forEach((polje) => {
      if (polje.inicijalnaVrednostString !== null) {
        poljeVrednostListWithInitValue[polje.id] = polje.inicijalnaVrednostString;
      } else if (polje.inicijalnaVrednostBroj !== null) {
        poljeVrednostListWithInitValue[polje.id] = polje.inicijalnaVrednostBroj;
      } else if (polje.inicijalnaVrednostBoolean !== null) {
        poljeVrednostListWithInitValue[polje.id] = polje.inicijalnaVrednostBoolean;
      } else if (polje.inicijalnaVrednostDatum !== null) {
        poljeVrednostListWithInitValue[polje.id] = polje.inicijalnaVrednostDatum;
      } else if (polje.inicijalnaVrednostEnum !== null) {
        poljeVrednostListWithInitValue[polje.id] = polje.inicijalnaVrednostEnum;
      }
    });
    setPoljeVrednostList(poljeVrednostListWithInitValue);
  };

  useEffect(() => {
    if (poljeVrednostList === undefined && poljeList.length > 0 && !isInsertedInitValue && !formularPopunjen) {
      insertInitValue(poljeList);
    }
  }, [poljeVrednostList, poljeList, isInsertedInitValue, formularPopunjen]);
  useEffect(() => {
    poljeVrednostRef.current = poljeVrednostList;
  }, [poljeVrednostList]);

  const onChange = (poljeVrednost: PoljeVrednostDto) => {
    setClickOutsideDisabled && setClickOutsideDisabled(true);
    const newValue: valueMap = {
      ...poljeVrednostRef.current,
      [poljeVrednost.id]: poljeVrednost.vrednost,
    };
    setPoljeVrednostList(newValue);
  };

  const save = () => {
    const idFormular = formularId ? formularId : formularPopunjen?.formular?.id;
    let obj: any = {
      formular: { id: idFormular },
      polja: poljeVrednostList ?? {},
      kontakt: { id: id },
    };
    setShowBlockUI(true);
    if (formularPopunjen?.id) {
      obj = { ...obj, id: formularPopunjen?.id };
      axiosUpdateFormularPopunjen(obj, formularPopunjen?.id)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.FORMULAR_SUCCESS_UPDATE);
          openClosePrethodniSabloniNalazi(true);
          afterSave();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setNesacuvanNalazState(undefined);
          setShowBlockUI(false);
          localStorage.removeItem(NESACUVAN_NALAZ);
          navigate(`/kontakt/${kontakt.id}`);
        });
    } else {
      axiosCreateFormularPopunjen(obj)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.FORMULAR_SUCCESS_SAVE);
          openClosePrethodniSabloniNalazi(true);
          afterSave();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setNesacuvanNalazState(undefined);
          setShowBlockUI(false);
          localStorage.removeItem(NESACUVAN_NALAZ);
          navigate(`/kontakt/${kontakt.id}`);
        });
    }
  };

  const onPoljeClick = (id: number) => {
    setSelectedPoljeId(id);
    setSelectedPoljeNaziv(poljeList.find((polje: PoljeReadDto) => polje.id === id)?.naziv);
  };

  useEffect(() => {
    getAllFieldList();
    getPoljePopunjenoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    poljeList,
    onChange,
    save,
    poljeVrednostList,
    activeIndex,
    onPoljeClick,
    selectedPoljeId,
    selectedPoljeNaziv,
    setPoljeVrednostList,
    poljeListLoading,
    poljeVrednostListLoading,
    poljeTypeList,
  };
}
