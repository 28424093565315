import { useQuery } from "@tanstack/react-query";
import { PacijentController } from "../../../../controllers/pacijent/PacijentController";
import QueryKeys from "../../QueryKeys";

export function usePacijentSimpleList() {
  const { axiosPacijentSearchSimple } = PacijentController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.PACIJENT_LIST],
    queryFn: axiosPacijentSearchSimple,
  });

  const pacijentList = data?.data?.data ?? [];

  return { pacijentList, refetch, isLoading };
}
