import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { VerzijaController } from "../../../../controllers/verzija/VerzijaController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";
import { handleAxiosCallError, handleAxiosCallSuccess } from "../../../../infrastructure/system/Utils";

export function useDeleteVerzijaStavka() {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosDeleteVerzijaStavka } = VerzijaController();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ verzijaId, verzijaStavkaId }: { verzijaId: number; verzijaStavkaId: number }) => {
      return axiosDeleteVerzijaStavka(verzijaId, verzijaStavkaId);
    },
    onSuccess: () => {
      handleAxiosCallSuccess(showMessage, Labels.VERZIJA_STAVKA_DELETE_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.VERZIJA_LIST] });
    },
    onError: (err) => {
      handleAxiosCallError(showMessage, err);
    },
  });
}
