import ProtokolObrazacReadDto from "../../../../model/obrasi-stampe-protokola/ProtokolObrazacReadDto";

export interface InitialStateAddObrazacType {
  isDialogOpen: boolean;
  obrasciLoading: boolean;
  obrasci: Array<ProtokolObrazacReadDto>;
  selectedObrazac: ProtokolObrazacReadDto | undefined;
}

const InitialStateAddObrazac: InitialStateAddObrazacType = {
  isDialogOpen: false,
  obrasciLoading: false,
  obrasci: [],
  selectedObrazac: undefined
};

export default InitialStateAddObrazac;
