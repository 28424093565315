import moment from "moment";
import { forwardRef, useContext, useMemo, useRef } from "react";
import { AppContext, useLabels } from "../../../Store";
import EnumPercentilnaKrivaPol from "../../../infrastructure/system/EnumPercentilnaKrivaPol";
import Images from "../../../infrastructure/system/Images";
import { DATE_FORMAT, DATE_TIME_FORMAT, getImage } from "../../../infrastructure/system/Utils";
import useEffectWithoutWarnings from "../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import { DotCoordinatesType } from "./PercentileKrivaDialogLogical";

interface PercentilnaKrivaCanvasPropsType {
  path: string | undefined;
  dotCoordinates: Array<DotCoordinatesType>;
  pacijent?: PacijentReadDto;
}

const PercentilnaKrivaCanvas = forwardRef((props: PercentilnaKrivaCanvasPropsType, ref: any) => {
  const { path, dotCoordinates, pacijent } = props;
  const { authData } = useContext(AppContext);
  const Labels = useLabels();
  const canvasRef = useRef<any>(null);

  useEffectWithoutWarnings(() => {
    if (path) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const graphImage = new Image();
      graphImage.src = path;
      graphImage.onload = () => {
        ctx.drawImage(graphImage, 0, 0, canvas.width, canvas.height);

        dotCoordinates.map((coordinate: any) => drawDot(ctx, coordinate.x, coordinate.y, 5, pacijent?.polTrenutni.sifra === EnumPercentilnaKrivaPol.MUSKI ? "#2f97db" : "#e75eb7"));
      };
    }
  }, [path, dotCoordinates]);

  const drawDot = (ctx: any, x: number, y: number, radius: number, color: string) => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.fill();
  };

  const pacijentAge = moment().diff(moment(pacijent?.vremeRodjenja), "years");

  const datumRodjenja = (className: string) => {
    return <div className={className}>{pacijent?.vremeRodjenja ? `${moment(pacijent.vremeRodjenja).format(DATE_FORMAT)} (${pacijentAge} ${Labels.LABEL_GODINA})` : "/"}</div>;
  };

  // ovi stilovi moraju da budu inline a ne klase, jer plugin nece raditi ako stavimo copyStyles na true (misli se na klase)
  const headerImageAndDateStyles: React.CSSProperties = useMemo(() => {
    return { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginRight: "2rem", marginLeft: "2rem" };
  }, []);

  const patientNameStyles: React.CSSProperties = useMemo(() => {
    return { textAlign: "center", marginBottom: "1rem" };
  }, []);

  const patientAgeStyles: React.CSSProperties = useMemo(() => {
    return { textAlign: "center", marginBottom: "3rem" };
  }, []);

  const canvasStyle: React.CSSProperties = useMemo(() => {
    return { display: "flex", justifyContent: "space-around" };
  }, []);

  const imgStyle: React.CSSProperties = useMemo(() => {
    return { width: "5rem" };
  }, []);

  return (
    <div ref={ref}>
      <style type="text/css" media="print">
        {
          "\
            @page { size: landscape; margin: 0; }\
          "
        }
      </style>
      <div className="hidden" style={headerImageAndDateStyles}>
        <img className="hidden" style={imgStyle} src={getImage(false, authData?.currentRadnik?.ustanovaPoslednjaIzabrana?.logo ?? "", Images.LOGO_HELIANT)} alt={Labels.HELIANT} />
        <div>
          <h2 className="hidden" style={patientNameStyles}>
            {pacijent?.ime + " " + pacijent?.prezime}
          </h2>
          <h2 className="hidden" style={patientAgeStyles}>
            {datumRodjenja("")}
          </h2>
        </div>
        <div>
          <h2 className="hidden" style={patientNameStyles}>
            {Labels.LABEL_PERCENTILNA_KRIVA_DATUM_STAMPE}
          </h2>
          <h2 className="hidden" style={patientAgeStyles}>
            {moment(new Date()).format(DATE_TIME_FORMAT)}
          </h2>
        </div>
      </div>
      <div style={canvasStyle}>
        <canvas ref={canvasRef} width={900} height={600} />
      </div>
    </div>
  );
});

export default PercentilnaKrivaCanvas;
