import moment from "moment";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { EnumController } from "../../../../controllers/enum/EnumController";
import { StorageLimitController } from "../../../../controllers/storage-limit/StorageLimitController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import StorageLimitReadDto from "../../../../model/licenca/storage-limit/StorageLimitReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";

interface CrudStorageLimitLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  storageLimitChange: StorageLimitReadDto | undefined;
  setStorageLimitChange: React.Dispatch<React.SetStateAction<StorageLimitReadDto | undefined>>;
  isDisabled: boolean;
  storageLimitOperation: string;
  onCreate: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  storageLimitLoading: boolean;
  jedinicaKolicinePodatkaList: Array<EnumBaseReadDto>;
}

interface UseParamsType {
  storageLimitId?: string;
}

export default function CrudStorageLimitLogical(): CrudStorageLimitLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const [storageLimitChange, setStorageLimitChange] = useState<StorageLimitReadDto | undefined>();
  const [storageLimitOperation] = useState(location.state?.storageLimitOperation || EntityOperation.UPDATE);
  const [isDisabled] = useState(isFormDisabled(storageLimitOperation));
  const storageLimitId = Number(useParams<keyof UseParamsType>()["storageLimitId"]);
  const [storageLimitLoading, setStorageLimitLoading] = useState(true);
  const [jedinicaKolicinePodatkaList, setJedinicaKolicinePodatkaList] = useState<Array<EnumBaseReadDto>>([]);
  const { axiosGetStorageLimit, axiosCreateStorageLimit, axiosUpdateStorageLimit } = StorageLimitController();
  const { axiosGetEnumJedinicaKolicinePodatkaList } = EnumController();
  const postLogHighLevel = useLogHighLevel();
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.LICENCA_LIST,
      command: () => {
        navigate("/licence", { state: { tabIndex: 2 } });
      },
    },
  ]);

  useEffectOnce(() => {
    fetchJedinicaKolicinePodatkaList();
    fetchData();
  });

  const fetchJedinicaKolicinePodatkaList = () => {
    axiosGetEnumJedinicaKolicinePodatkaList()
      .then(({ data: { data } }: { data: { data: Array<EnumBaseReadDto> } }) => {
        setJedinicaKolicinePodatkaList(data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        //skeletonTimeout(setStorageLimitLoading, startTime);
      });
  };

  const fetchData = (slID?: number, operation?: string) => {
    const newStorageLimitId = slID || storageLimitId;
    const startTime = moment(new Date());
    if (newStorageLimitId) {
      axiosGetStorageLimit(newStorageLimitId)
        .then(({ data: { data } }: { data: { data: StorageLimitReadDto } }) => {
          setStorageLimitChange(data);
          const operationTmp = operation ?? storageLimitOperation;
          setBreadCrumbItems([
            breadCrumbItems[0],
            {
              label:
                operationTmp === EntityOperation.UPDATE
                  ? Labels.STORAGE_LIMIT_TITLE_UPDATE + data.naziv
                  : operationTmp === EntityOperation.READ
                  ? Labels.STORAGE_LIMIT_TITLE_DETAILS + data.naziv
                  : Labels.STORAGE_LIMIT_TITLE_DELETE + data.naziv,
            },
          ]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setStorageLimitLoading, startTime);
        });
    } else {
      setStorageLimitLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.STORAGE_LIMIT_TITLE_CREATE,
        },
      ]);
    }
  };

  const validateInput = (storageLimit: StorageLimitReadDto | undefined) => {
    if (storageLimit === undefined || validateStringEmpty(storageLimit.naziv)) {
      showMessage(MessageType.ERROR, Labels.STORAGE_LIMIT_NAZIV_IS_REQUIRED);
      return false;
    }
    if (validateStringEmpty(storageLimit.sifra)) {
      showMessage(MessageType.ERROR, Labels.STORAGE_LIMIT_SIFRA_IS_REQUIRED);
      return false;
    }
    if (storageLimit.prostor === undefined || storageLimit.prostor <= 0) {
      showMessage(MessageType.ERROR, Labels.STORAGE_LIMIT_PROSTOR_IS_REQUIRED);
      return false;
    }

    if (storageLimit.jedinicaKolicinePodatkaEnum === undefined) {
      showMessage(MessageType.ERROR, Labels.STORAGE_LIMIT_JEDINICA_IS_REQUIRED);
      return false;
    }

    if (storageLimit.cena === undefined || storageLimit.cena < 0) {
      showMessage(MessageType.ERROR, Labels.STORAGE_LIMIT_CENA_IS_REQUIRED);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validateInput(storageLimitChange)) {
      return;
    }

    if (storageLimitChange) {
      setShowBlockUI(true);
      axiosCreateStorageLimit(storageLimitChange)
        .then(({ data: { data } }: { data: { data: StorageLimitReadDto } }) => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_1 +
              storageLimitChange.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_2 +
              storageLimitChange.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_3 +
              storageLimitChange.prostor +
              " " +
              storageLimitChange.jedinicaKolicinePodatkaEnum.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_4 +
              storageLimitChange.cena +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_5
          );

          showMessage(MessageType.SUCCESS, Labels.STORAGE_LIMIT_MESSAGE_CREATE_SUCCESS);
          onCancel();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(storageLimitChange)) {
      return;
    }
    if (storageLimitChange) {
      setShowBlockUI(true);
      axiosUpdateStorageLimit(storageLimitChange)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_1 +
              storageLimitChange.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_2 +
              storageLimitChange.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_3 +
              storageLimitChange.prostor +
              " " +
              storageLimitChange.jedinicaKolicinePodatkaEnum.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_4 +
              storageLimitChange.cena +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_5
          );

          showMessage(MessageType.SUCCESS, Labels.DODATNI_MODUL_MESSAGE_UPDATE_SUCCESS);
          onCancel();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };
  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      const breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/licence`, {
        state: { tabIndex: 2 },
      });
    }
  };

  return {
    breadCrumbItems,
    storageLimitChange,
    setStorageLimitChange,
    isDisabled,
    storageLimitOperation,
    onCreate,
    onUpdate,
    onCancel,
    storageLimitLoading,
    jedinicaKolicinePodatkaList,
  };
}
