import moment from "moment";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { ProgressSpinner } from "primereact/progressspinner";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { PacijentController } from "../../controllers/pacijent/PacijentController";
import successImage from "../../infrastructure/images/unsubscribe/accept.png";
import unsubscribeImage from "../../infrastructure/images/unsubscribe/mail-unsub.png";
import { skeletonTimeout } from "../../infrastructure/system/Utils";
import SkeletonRectangles from "../app/skeleton/SkeletonRectangles";

export default function Unsubscribe() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token: string | null = queryParams.get("token");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [step, setStep] = useState<number>(0);

  const { axiosPacijentUnsubscribe } = PacijentController();

  const unsubscribe = () => {
    let startTime = moment(new Date());
    setLoading(true);
    axiosPacijentUnsubscribe(token!)
      .then(() => {
        setStep(1);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        skeletonTimeout(setLoading, startTime);
      });
  };

  const onClose = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  const firstScreen = () => {
    return (
      <div className="text-center">
        <Image src={unsubscribeImage} alt="Unsubscribe" />
        <div className="text-2xl font-bold pt-4">Da li ste sigurni da želite da se odjavite?</div>
        <div className="text-lg py-3">Ako se odjavite, prestaćete da dobijate imejlove vezane za promocije.</div>
        <div className="pt-3 flex flex-column-reverse justify-content-center m-auto w-8rem sm:justify-content-around sm:flex-row sm:w-7">
          <Button label="Odustani" icon="pi pi-times" className="button-danger" onClick={onClose} />
          <Button label="Odjavi se" icon="pi pi-check" className="p-button-outlined p-button-info mb-2 sm:mb-0" onClick={unsubscribe} />
        </div>
      </div>
    );
  };

  const secondScreen = () => {
    return (
      <div className="text-center">
        <Image src={successImage} alt="Success" />
        <div className="text-2xl font-bold pt-4">Uspešno ste se odjavili.</div>
      </div>
    );
  };

  const content = () => {
    return loading ? <SkeletonRectangles /> : error ? <div className="text-2xl pt-6">{"Došlo je do greške"}</div> : step === 0 ? firstScreen() : secondScreen();
  };

  return (
    <div className="flex flex-column h-screen justify-content-center align-items-center py-8">
      <>
        {loading ? <ProgressSpinner className="mt-8" /> : error ? <i className="pi pi-times-circle mt-8" style={{ fontSize: "6em", color: "red" }} /> : null}
        <div className="px-8">{content()}</div>
      </>
    </div>
  );
}
