import { Skeleton } from "primereact/skeleton";

const SkeletonPorukaListItem = () => {
  return (
    <div className="flex px-2 w-full min-w-0 align-items-start hc-gap-3 cursor-pointer border-box">
      <div className="flex w-full align-items-center justify-content-center hc-gap-2">
        <Skeleton className="mb-2 avatar ml-3" width="3rem" height="3rem" borderRadius="50%"></Skeleton>
        <div className="py-3 min-w-0 w-full px-2">
          <Skeleton className="mb-2"></Skeleton>
          <Skeleton className="mb-2" height="3rem"></Skeleton>
        </div>
      </div>
    </div>
  );
};

export default SkeletonPorukaListItem;
