import { useContext } from "react";
import { useSifarnikKategorijaPopustaList } from "../../../administracija/sifarnik-kategorije-popusta/hooks/useSifarnikKategorijaPopustaList";
import { NaplataContext } from "../../NaplataLogical";
import UplatilacCardProizvod from "./UplatilacCardProizvod";
import UplatilacCardUsluga from "./UplatilacCardUsluga";
import UplatiociInfoPanel from "./UplatiociInfoPanel";

const Uplatioci = () => {
  const { state } = useContext(NaplataContext);
  const { sifarnikKategorijaPopustaList } = useSifarnikKategorijaPopustaList();

  return (
    <div className="layout-generic-content-list uplatioci flex flex-row justify-content-center">
      <div className="sticky-div">
        <UplatiociInfoPanel />
      </div>
      <div>
        {state?.selectedColumns?.map((kontakt) => (
          <div key={kontakt.id}>
            {kontakt.kontaktUslugaList.map((kontaktUsluga) => (
              <UplatilacCardUsluga key={`kontaktUsluga-${kontaktUsluga.id}`} kontakt={kontakt} kontaktUsluga={kontaktUsluga} kategorijePopustaList={sifarnikKategorijaPopustaList} />
            ))}
            {kontakt.kontaktProizvodList.map((kontaktProizvod) => (
              <UplatilacCardProizvod key={`kontaktProizvod-${kontaktProizvod.id}`} kontakt={kontakt} kontaktProizvod={kontaktProizvod} kategorijePopustaList={sifarnikKategorijaPopustaList} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Uplatioci;
