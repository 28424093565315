import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { RadnikController } from "../../../controllers/radnik/RadnikController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import RadnikReadDto from "../../../model/radnik/RadnikReadDto";

interface RadnikListLogicalType {
  radnikList: Array<RadnikReadDto>;
  first: number;
  tableRows: number;
  selectedRadnik: RadnikReadDto | undefined;
  setSelectedRadnik: React.Dispatch<React.SetStateAction<RadnikReadDto | undefined>>;
  changeRadnikSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  radnikSearchData: SearchBaseDto;
  fetchData: () => void;
  searchRadnikByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  dialogRef: any;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  onPageRadnik: (rows: number, first: number) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  radnikLoading: boolean;
}

export default function RadnikListLogical(): RadnikListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [radnikList, setRadnikList] = useState<Array<RadnikReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [selectedRadnik, setSelectedRadnik] = useState<RadnikReadDto>();
  const [radnikSearchData, setRadnikSearchData] = useState<SearchBaseDto>({});
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const { axiosSearchRadnik } = RadnikController();
  const [radnikLoading, setRadnikLoading] = useState<boolean>(false);

  const breadCrumbItems = [
    {
      label: Labels.RADNIK_LIST,
    },
  ];
  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedRadnik?: number) => {
    let startTime = moment(new Date());
    setRadnikLoading(true);
    axiosSearchRadnik(radnikSearchData)
      .then((res: any) => {
        setRadnikList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((radnik: RadnikReadDto) => {
          exportList.push({ [Labels.LABEL_IME]: radnik.ime, [Labels.LABEL_PREZIME]: radnik.prezime, [Labels.RADNIK_USERNAME]: radnik.username, [Labels.LABEL_EMAIL]: radnik.email });
        });
        setExportData(exportList);
        if (idSelectedRadnik) {
          res.data.data.forEach((radnik: RadnikReadDto) => {
            if (idSelectedRadnik === radnik.id) {
              setSelectedRadnik(radnik);
            }
          });
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setRadnikLoading, startTime);
      });
  };

  const changeRadnikSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setRadnikSearchData({
      ...radnikSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchRadnikByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageRadnik = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    radnikList,
    first,
    tableRows,
    selectedRadnik,
    setSelectedRadnik,
    changeRadnikSearchData,
    radnikSearchData,
    fetchData,
    searchRadnikByEnter,
    dialogRef,
    index,
    setIndex,
    onPageRadnik,
    exportData,
    breadCrumbItems,
    radnikLoading,
  };
}
