import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import UstanovaEksterniSistemReadDto from "../../model/eksterni-sistem/UstanovaEksterniSistemReadDto";
import IzvestajReadDto from "../../model/izvestaj/IzvestajReadDto";
import KategorijaStatistickogIzvestajReadDto from "../../model/izvestaj/kategorija/KategorijaStatistickogIzvestajaReadDto";
import IzvestajParametarCreateDto from "../../model/izvestaj/parametar/IzvestajParametarCreateDto";
import IzvestajParametarEnumStavkaUpdateDto from "../../model/izvestaj/parametar/IzvestajParametarEnumStavkaUpdateDto";
import IzvestajParametarReadDto from "../../model/izvestaj/parametar/IzvestajParametarReadDto";
import { ParametarKategorijaSaParametrimaUpdateDto } from "../../model/parametar/ParametarKategorijaSaParametrimaUpdateDto";
import ProdajaPaketCreateDto from "../../model/prodajni-paketi/ProdajaPaketCreateDto";
import RadnikOrganizacionaJedinicaCreateDto from "../../model/radnik/RadnikOrganizacionaJedinicaCreateDto";
import RadnikPartialUpdateDto from "../../model/radnik/RadnikPartialUpdateDto";
import RadnikReadDto from "../../model/radnik/RadnikReadDto";
import RadnikSpecijalizacijaCreateDto from "../../model/radnik/RadnikSpecijalizacijaCreateDto";
import SifarnikAtributaRfzoCreateDto from "../../model/sifarnik/sifarnik-atributa-rfzo/SifarnikAtributaRfzoCreateDto";
import SifarnikAtributaRfzoReadDto from "../../model/sifarnik/sifarnik-atributa-rfzo/SifarnikAtributaRfzoReadDto";
import SifarnikProizvodaRfzoCreateDto from "../../model/sifarnik/sifarnik-proizvoda-rfzo/SifarnikProizvodaRfzoCreateDto";
import SifarnikProizvodaRfzoReadDto from "../../model/sifarnik/sifarnik-proizvoda-rfzo/SifarnikProizvodaRfzoReadDto";
import SifarnikSpecijalizacijaCreateDto from "../../model/sifarnik/sifarnik-specijalizacija/SifarnikSpecijalizacijaCreateDto";
import SifarnikSpecijalizacijaReadDto from "../../model/sifarnik/sifarnik-specijalizacija/SifarnikSpecijalizacijaReadDto";
import SifarnikUslugaRfzoCreateDto from "../../model/sifarnik/sifarnik-usluga-rfzo/SifarnikUslugaRfzoCreateDto";
import SifarnikUslugaRFZOReadDto from "../../model/sifarnik/sifarnik-usluga-rfzo/SifarnikUslugaRfzoReadDto";
import UlogaAplikativnoPravoCreateDto from "../../model/uloga/UlogaAplikativnoPravoCreateDto";
import UlogaPravoNadTipomCreateDto from "../../model/uloga/UlogaPravoNadTipomCreateDto";
import UlogaReadDto from "../../model/uloga/UlogaReadDto";
import UstanovaFakturniPodaciReadDto from "../../model/ustanova/UstanovaFakturniPodaciReadDto";
import UstanovaGridUpdateDto from "../../model/ustanova/UstanovaGridUpdateDto";
import UstanovaReadDto from "../../model/ustanova/UstanovaReadDto";
import ZakazanTerminPacijentJasperUstanovaCreateDto from "../../model/ustanova/ZakazanTerminPacijentJasperUstanovaCreateDto";
import { AppContext } from "./../../Store";

interface SuperadminControllerType {
  axiosSuperadminGetUstanovaGrid: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateUstanovaGrid: (ustanovaGridChange: UstanovaGridUpdateDto, ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminReadUstanova: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateUstanova: (ustanovaCreate: UstanovaReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateUstanova: (ustanovaChange: UstanovaReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminSearchUstanova: (ustanovaSearchData: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetParametarUstanovaList: (ustanovaID: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateParameterListByKategorija: (ustanovaId: number, updateObj: ParametarKategorijaSaParametrimaUpdateDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUstanovaGetUlogaList: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminUstanovaReadUloga: (ustanovaId: number, ulogaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminUstanovaUpdateUloga: (ustanovaId: number, ulogaChange: UlogaReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUstanovaCreateUloga: (ustanovaId: number, ulogaCreate: UlogaReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUstanovaDeleteUloga: (ustanovaId: number, ulogaId: number) => Promise<AxiosResponse<any>>;

  axiosSuperadminGetUstanovaRadnikList: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteUstanovaRadnik: (ustanovaId: number, radnikId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetUstanovaRadnik: (ustanovaId: number, radnikId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateUstanovaRadnik: (ustanovaId: number, radnik: RadnikReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateUstanovaRadnik: (ustanovaId: number, radnik: any) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetRadnikUlogaList: (ustanovaId: number, radnikId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteRadnikUloga: (ustanovaId: number, radnikId: number, ulogaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateRadnikUloga: (ustanovaId: number, radnikId: number, ulogaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateRadnikOrganizacionaJedinica: (ustanovaId: number, radnikId: number, organizacioneJedinice: RadnikOrganizacionaJedinicaCreateDto[]) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteRadnikOrganizacionaJedinica: (ustanovaId: number, radnikId: number, organizacionaJedinicaIds: Array<number>) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetRadnikOrganizacionaJedinicaList: (ustanovaId: number, radnikId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetUstanovaOrganizacionaJedinicaList: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetUlogaRadnikList: (ustanovaId: number, ulogaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminPostUlogaAplikativnoPravoList: (ustanovaId: number, ulogaID: number, body: UlogaAplikativnoPravoCreateDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetUlogaAplikativnoPravoList: (ustanovaId: number, ulogaID: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteUlogaAplikativnoPravo: (ustanovaId: number, ulogaID: number, sifraAplikativnoPravo: string) => Promise<AxiosResponse<any>>;
  axiosSuperadminPostUlogaPravoNadTipomList: (ustanovaId: number, ulogaID: number, body: UlogaPravoNadTipomCreateDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetUlogaPravoNadTipomList: (ustanovaId: number, ulogaID: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteUlogaPravoNadTipom: (ustanovaId: number, ulogaID: number, sifraVrstaPrava?: string, sifraTipObjekta?: string) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateRadnikUstanovaWithExistingRadnik: (ustanovaId: number, radnikId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateRadnikAndCreateRadnikUstanova: (ustanovaId: number, radnikPartialUpdateDto: RadnikPartialUpdateDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetSifarnikAtributaRFZO: (sifarnikAtributaRfzoId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminSearchSifarnikAtributaRFZO: (searchString: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateSifarnikAtributaRFZO: (sifarnikCreate: SifarnikAtributaRfzoCreateDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateSifarnikAtributaRFZO: (sifarnikChange: SifarnikAtributaRfzoReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteSifarnikAtributaRFZO: (sifarnikId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetSifarnikProizvodaRFZO: (sifarnikProizvodaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminSearchSifarnikProizvodaRFZO: (searchString: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateSifarnikProizvodaRFZO: (sifarnikCreate: SifarnikProizvodaRfzoCreateDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateSifarnikProizvodaRFZO: (sifarnikChange: SifarnikProizvodaRfzoReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteSifarnikProizvodaRFZO: (sifarnikId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateSifarnikUslugaRFZO: (sifarnikCreate: SifarnikUslugaRfzoCreateDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateSifarnikUslugaRFZO: (sifarnikChange: SifarnikUslugaRFZOReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteSifarnikUslugaRFZO: (sifarnikId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetSifarnikUslugaRFZO: (sifarnikUslugaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminSearchSifarnikUslugaRFZO: (searchString: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetUstanovaFakturniPodaci: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateUstanovaFakturniPodaci: (ustanovaId: number, fakturniPodaci: UstanovaFakturniPodaciReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetUstanovaEntityCount: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminHardDeleteUstanova: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetOtherUstanovaRadnikList: (ustanovaId: number, searchString: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetSifarnikSpecijalizacija: () => Promise<AxiosResponse<any>>;
  axiosSuperadminGetSifarnikSpecijalizacijaById: (sifarnikSpecijalizacijaID: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateSpecijalizacija: (specijalizacija: SifarnikSpecijalizacijaCreateDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateSpecijalizacija: (specijalizacija: SifarnikSpecijalizacijaReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteSpecijalizacija: (specijalizacijaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateZakazanTerminUstanovaJasper: (ustanovaId: number, jasperDokument: ZakazanTerminPacijentJasperUstanovaCreateDto | undefined) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetZakazanTerminUstanovaJasper: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteZakazanTerminUstanovaJasper: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetRadnikSpecijalizacijeList: (ustanovaId: number, radnikID: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateRadnikSpecijalizacija: (ustanovaId: number, radnikID: number, radnikSpecijalizacijaList: Array<RadnikSpecijalizacijaCreateDto>) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteRadnikSpecijalizacija: (ustanovaId: number, radnikID: number, specijalizacijaID: Array<number>) => Promise<AxiosResponse<any>>;
  axiosSuperadminSinhronizujCenovnikeUsluga: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminSinhronizujOrganizacioneJedinice: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminSinhronizujDoktore: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminInicijalizujNadjimed: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetUstanovaEksterniSistemList: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminCreateUstanovaEksterniSistem: (ustanovaId: number, ustanovaEksternisistem: UstanovaEksterniSistemReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminUpdateUstanovaEksterniSistem: (ustanovaId: number, ustanovaEksternisistem: UstanovaEksterniSistemReadDto) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetUstanovaEksterniSistem: (ustanovaId: number, ustanovaEksterniSistemId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminDeleteUstanovaEksterniSistem: (ustanovaId: number, ustanovaEksterniSistemId: number) => Promise<AxiosResponse<any>>;
  axiosSuperadminGetStatistickiIzvestajList: (searchString?: SearchBaseDto) => Promise<AxiosResponse>;
  axiosSuperadminGetKategorijeStatistickihIzvestajaList: (searchString?: SearchBaseDto) => Promise<AxiosResponse>;
  axiosSuperadminCreateKategorijaStatistickihIzvestaja: (kategorijaStatistickogIzvestaja: KategorijaStatistickogIzvestajReadDto) => Promise<AxiosResponse>;
  axiosSuperadminUpdateKategorijaStatistickihIzvestaja: (kategorijaStatistickogIzvestaja: KategorijaStatistickogIzvestajReadDto) => Promise<AxiosResponse>;
  axiosSuperadminGetKategorijaStatistickihIzvestaja: (kategorijaId: number) => Promise<AxiosResponse>;
  axiosSuperadminDeleteKategorijaStatistickihIzvestaja: (kategorijaId: number) => Promise<AxiosResponse>;
  axiosSuperadminCreateStatistickiIzvestaj: (statistickiIzvestaj: IzvestajReadDto | undefined) => Promise<AxiosResponse>;
  axiosSuperadminUpdateStatistickiIzvestaj: (statistickiIzvestaj: IzvestajReadDto | undefined) => Promise<AxiosResponse>;
  axiosSuperadminGetStatistickiIzvestaj: (izvestajId: number) => Promise<AxiosResponse>;
  axiosSuperadminDeleteStatistickiIzvestaj: (izvestajId: number) => Promise<AxiosResponse>;
  axiosSuperadminGetParametriStatistickogIzvestaja: (izvestajId: number) => Promise<AxiosResponse>;
  axiosSuperadminCreateParametriStatistickogIzvestaja: (izvestajParametar: IzvestajParametarReadDto) => Promise<AxiosResponse>;
  axiosSuperadminGetParametarStatistickogIzvestaja: (izvestajId: number, izvestajParametarId: number) => Promise<AxiosResponse>;
  axiosSuperadminUpdateParametarStatistickogIzvestaja: (izvestajParametar: IzvestajParametarReadDto) => Promise<AxiosResponse>;
  axiosSuperadminDeleteParametarStatistickogIzvestaja: (izvestajId: number, izvestajParametarId: number) => Promise<AxiosResponse>;
  axiosSuperadminGetIzvestajParametarEnumStavkaList: (izvestajId: number, izvestajParametarId: number) => Promise<AxiosResponse>;
  axiosSuperadminGetIzvestajParametarEnumStavka: (izvestajId: number, izvestajParametarId: number, izvestajParametarEnumStavkaId: number) => Promise<AxiosResponse>;
  axiosSuperadminDeleteIzvestajParametarEnumStavka: (izvestajId: number, izvestajParametarId: number, izvestajParametarEnumStavkaId: number) => Promise<AxiosResponse>;
  axiosSuperadminUpdateIzvestajParametarEnumStavka: (izvestajId: number, izvestajParametarEnumStavka: IzvestajParametarEnumStavkaUpdateDto) => Promise<AxiosResponse>;
  axiosSuperadminCreateIzvestajParametarEnumStavka: (izvestajId: number, izvestajParametarId: number, izvestajParametar: IzvestajParametarCreateDto) => Promise<AxiosResponse>;
  axiosSuperadminGetProdajniPaketList: (searchString?: SearchBaseDto) => Promise<AxiosResponse>;
  axiosSuperadminCreateProdajniPaket: (prodajniPaket: ProdajaPaketCreateDto | undefined) => Promise<AxiosResponse>;
  axiosSuperadminGetProdajniPaket: (prodajniPaketId: number) => Promise<AxiosResponse>;
  axiosSuperadminUpdateProdajniPaket: (prodajniPaketId: number, prodajniPaket: ProdajaPaketCreateDto) => Promise<AxiosResponse>;
  axiosSuperadminDeleteProdajniPaket: (prodajniPaketId: number) => Promise<AxiosResponse>;
}

export function SuperadminController(): SuperadminControllerType {
  const { authData } = useContext(AppContext);

  const axiosSuperadminReadUstanova = (ustanovaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateUstanova = (ustanovaCreate: UstanovaReadDto) => {
    return axios.post(Endpoint.SUPERADMIN_USTANOVA_LIST, ustanovaCreate ? ustanovaCreate : {}, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateUstanova = (ustanovaChange: UstanovaReadDto) => {
    return axios.put(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaChange.id}`, ustanovaChange, axiosConfig(authData!.token));
  };

  const axiosSuperadminSearchUstanova = (ustanovaSearchData: SearchBaseDto) => {
    return axios.get(Endpoint.SUPERADMIN_USTANOVA_SEARCH, axiosConfig(authData!.token, ustanovaSearchData));
  };

  const axiosSuperadminGetUstanovaGrid = (ustanovaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_GRID_LIST}/${ustanovaId}`, axiosConfig(authData!.token));
  };
  const axiosSuperadminUpdateUstanovaGrid = (ustanovaGridChange: UstanovaGridUpdateDto, ustanovaId: number) => {
    return axios.put(`${Endpoint.SUPERADMIN_USTANOVA_GRID_LIST}/${ustanovaId}`, ustanovaGridChange, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetParametarUstanovaList = (ustanovaID: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_PARAMETAR_USTANOVA_LIST}/${ustanovaID}`, axiosConfig(authData!.token));
  };
  const axiosSuperadminUpdateParameterListByKategorija = (ustanovaId: number, updateObj: ParametarKategorijaSaParametrimaUpdateDto) => {
    return axios.put(`${Endpoint.SUPERADMIN_PARAMETAR_USTANOVA_LIST}/update/${ustanovaId}`, updateObj, axiosConfig(authData!.token));
  };

  const axiosSuperadminUstanovaGetUlogaList = (ustanovaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminUstanovaReadUloga = (ustanovaId: number, ulogaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}/${ulogaId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminUstanovaUpdateUloga = (ustanovaId: number, ulogaChange: UlogaReadDto) => {
    return axios.put(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}/${ulogaChange.id}`, ulogaChange, axiosConfig(authData!.token));
  };

  const axiosSuperadminUstanovaCreateUloga = (ustanovaId: number, ulogaCreate: UlogaReadDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}`, ulogaCreate ? ulogaCreate : {}, axiosConfig(authData!.token));
  };

  const axiosSuperadminUstanovaDeleteUloga = (ustanovaId: number, ulogaId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}/${ulogaId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetUlogaRadnikList = (ustanovaId: number, ulogaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}/${ulogaId}/radnik-list`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetUstanovaRadnikList = (ustanovaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list`, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteUstanovaRadnik = (ustanovaId: number, radnikId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetUstanovaRadnik = (ustanovaId: number, radnikId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateUstanovaRadnik = (ustanovaId: number, radnik: RadnikReadDto) => {
    return axios.put(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnik.id}`, radnik, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateUstanovaRadnik = (ustanovaId: number, radnik: RadnikReadDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list`, radnik, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetRadnikUlogaList = (ustanovaId: number, radnikId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikId}/uloga-list`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateRadnikUloga = (ustanovaId: number, radnikId: number, ulogaId: number) => {
    return axios.post(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikId}/uloga-list/${ulogaId}`, {}, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteRadnikUloga = (ustanovaId: number, radnikId: number, ulogaId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikId}/uloga-list/${ulogaId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetRadnikOrganizacionaJedinicaList = (ustanovaId: number, radnikId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikId}/organizaciona-jedinica-list`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateRadnikOrganizacionaJedinica = (ustanovaId: number, radnikId: number, organizacioneJedinice: RadnikOrganizacionaJedinicaCreateDto[]) => {
    return axios.post(
      `${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikId}/organizaciona-jedinica-list`,
      organizacioneJedinice ? organizacioneJedinice : {},
      axiosConfig(authData!.token)
    );
  };

  const axiosSuperadminDeleteRadnikOrganizacionaJedinica = (ustanovaId: number, radnikId: number, organizacionaJedinicaIds: Array<number>) => {
    return axios.delete(
      `${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikId}/organizaciona-jedinica-list`,
      axiosConfig(authData!.token, { organizacionaJedinicaIDList: organizacionaJedinicaIds.join(",") })
    );
  };

  const axiosSuperadminGetUstanovaOrganizacionaJedinicaList = (ustanovaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/organizaciona-jedinica-list`, axiosConfig(authData!.token));
  };

  const axiosSuperadminPostUlogaAplikativnoPravoList = (ustanovaId: number, ulogaID: number, body: UlogaAplikativnoPravoCreateDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}/${ulogaID}${Endpoint.APLIKATIVNO_PRAVO_SUFIX}`, body, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetUlogaAplikativnoPravoList = (ustanovaId: number, ulogaID: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}/${ulogaID}${Endpoint.APLIKATIVNO_PRAVO_SUFIX}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteUlogaAplikativnoPravo = (ustanovaId: number, ulogaID: number, sifraAplikativnoPravo: string) => {
    return axios.delete(
      `${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}/${ulogaID}${Endpoint.APLIKATIVNO_PRAVO_SUFIX}/${sifraAplikativnoPravo}`,
      axiosConfig(authData!.token)
    );
  };

  const axiosSuperadminPostUlogaPravoNadTipomList = (ustanovaId: number, ulogaID: number, body: UlogaPravoNadTipomCreateDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}/${ulogaID}${Endpoint.PRAVO_NAD_TIPOM_SUFIX}`, body, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetUlogaPravoNadTipomList = (ustanovaId: number, ulogaID: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}/${ulogaID}${Endpoint.PRAVO_NAD_TIPOM_SUFIX}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteUlogaPravoNadTipom = (ustanovaId: number, ulogaID: number, sifraVrstaPrava?: string, sifraTipObjekta?: string) => {
    return axios.delete(
      `${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}${Endpoint.ULOGA_LIST_SUFIX}/${ulogaID}${Endpoint.PRAVO_NAD_TIPOM_SUFIX}`,
      axiosConfig(authData!.token, { sifraVrstaPrava, sifraTipObjekta })
    );
  };

  const axiosSuperadminCreateRadnikUstanovaWithExistingRadnik = (ustanovaId: number, radnikId: number) => {
    return axios.post(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikId}`, {}, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateRadnikAndCreateRadnikUstanova = (ustanovaId: number, radnikPartialUpdateDto: RadnikPartialUpdateDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/updateRadnikAndCreateRadnikUstanova`, radnikPartialUpdateDto, axiosConfig(authData!.token));
  };

  const axiosSuperadminSearchSifarnikAtributaRFZO = (searchString: SearchBaseDto) => {
    return axios.get(Endpoint.SUPERADMIN_SIFARNIK_ATRIBUTA_SEARCH, axiosConfig(authData!.token, searchString));
  };

  const axiosSuperadminGetSifarnikAtributaRFZO = (sifarnikAtributaRfzoId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_SIFARNIK_ATRIBUTA_LIST}/${sifarnikAtributaRfzoId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateSifarnikAtributaRFZO = (sifarnikCreate: SifarnikAtributaRfzoCreateDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_SIFARNIK_ATRIBUTA_LIST}`, sifarnikCreate, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateSifarnikAtributaRFZO = (sifarnikChange: SifarnikAtributaRfzoReadDto) => {
    return axios.put(`${Endpoint.SUPERADMIN_SIFARNIK_ATRIBUTA_LIST}/${sifarnikChange.id}`, sifarnikChange, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteSifarnikAtributaRFZO = (sifarnikId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_SIFARNIK_ATRIBUTA_LIST}/${sifarnikId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminSearchSifarnikProizvodaRFZO = (searchString: SearchBaseDto) => {
    return axios.get(Endpoint.SUPERADMIN_SIFARNIK_PROIZVODA_RFZO_SEARCH, axiosConfig(authData!.token, searchString));
  };

  const axiosSuperadminGetSifarnikProizvodaRFZO = (sifarnikProizvodaRfzoId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_SIFARNIK_PROIZVODA_RFZO_LIST}/${sifarnikProizvodaRfzoId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateSifarnikProizvodaRFZO = (sifarnikCreate: SifarnikProizvodaRfzoCreateDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_SIFARNIK_PROIZVODA_RFZO_LIST}`, sifarnikCreate, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateSifarnikProizvodaRFZO = (sifarnikChange: SifarnikProizvodaRfzoReadDto) => {
    return axios.put(`${Endpoint.SUPERADMIN_SIFARNIK_PROIZVODA_RFZO_LIST}/${sifarnikChange.id}`, sifarnikChange, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteSifarnikProizvodaRFZO = (sifarnikId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_SIFARNIK_PROIZVODA_RFZO_LIST}/${sifarnikId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetSifarnikUslugaRFZO = (idRepositorySifarnikUslugaRFZO: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_SIFARNIK_USLUGA_RFZO_LIST}/${idRepositorySifarnikUslugaRFZO}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateSifarnikUslugaRFZO = (sifarnikCreate: SifarnikUslugaRfzoCreateDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_SIFARNIK_USLUGA_RFZO_LIST}`, sifarnikCreate, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateSifarnikUslugaRFZO = (sifarnikChange: SifarnikUslugaRFZOReadDto) => {
    return axios.put(`${Endpoint.SUPERADMIN_SIFARNIK_USLUGA_RFZO_LIST}/${sifarnikChange.id}`, sifarnikChange, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteSifarnikUslugaRFZO = (sifarnikId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_SIFARNIK_USLUGA_RFZO_LIST}/${sifarnikId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminSearchSifarnikUslugaRFZO = (searchString: SearchBaseDto) => {
    return axios.get(Endpoint.SUPERADMIN_SIFARNIK_USLUGA_RFZO_SEARCH, axiosConfig(authData!.token, searchString));
  };

  const axiosSuperadminGetUstanovaFakturniPodaci = (ustanovaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/${Endpoint.FAKTURNI_PODACI_SUFIX}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateUstanovaFakturniPodaci = (ustanovaId: number, fakturniPodaci: UstanovaFakturniPodaciReadDto) => {
    return axios.put(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/${Endpoint.FAKTURNI_PODACI_SUFIX}`, fakturniPodaci, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetUstanovaEntityCount = (ustanovaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/entity-count`, axiosConfig(authData!.token));
  };

  const axiosSuperadminHardDeleteUstanova = (ustanovaId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_HARD_DELETE_USTANOVA}/${ustanovaId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetOtherUstanovaRadnikList = (ustanovaId: number, searchString: SearchBaseDto) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/radnik-add-search`, axiosConfig(authData!.token, searchString));
  };

  const axiosSuperadminGetSifarnikSpecijalizacija = () => {
    return axios.get(Endpoint.SIFARNIK_SPECIJALIZACIJA_LIST, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetSifarnikSpecijalizacijaById = (sifarnikSpecijalizacijaID: number) => {
    return axios.get(`${Endpoint.SIFARNIK_SPECIJALIZACIJA_LIST}/${sifarnikSpecijalizacijaID}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateSpecijalizacija = (specijalizacija: SifarnikSpecijalizacijaCreateDto) => {
    return axios.post(Endpoint.SIFARNIK_SPECIJALIZACIJA_LIST, specijalizacija, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateSpecijalizacija = (specijalizacija: SifarnikSpecijalizacijaReadDto) => {
    return axios.put(`${Endpoint.SIFARNIK_SPECIJALIZACIJA_LIST}/${specijalizacija.id}`, specijalizacija, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteSpecijalizacija = (specijalizacijaId: number) => {
    return axios.delete(`${Endpoint.SIFARNIK_SPECIJALIZACIJA_LIST}/${specijalizacijaId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateZakazanTerminUstanovaJasper = (ustanovaId: number, jasperDokument: ZakazanTerminPacijentJasperUstanovaCreateDto | undefined) => {
    return axios.post(`${Endpoint.SUPERADMIN_ZAKAZAN_TERMIN_JASPER_USTANOVA_LIST}/${ustanovaId}`, jasperDokument, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetZakazanTerminUstanovaJasper = (ustanovaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_ZAKAZAN_TERMIN_JASPER_USTANOVA_LIST}/${ustanovaId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteZakazanTerminUstanovaJasper = (ustanovaId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_ZAKAZAN_TERMIN_JASPER_USTANOVA_LIST}/${ustanovaId}`, axiosConfig(authData!.token));
  };
  const axiosSuperadminGetRadnikSpecijalizacijeList = (ustanovaId: number, radnikID: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikID}/specijalizacija-list`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateRadnikSpecijalizacija = (ustanovaId: number, radnikID: number, radnikSpecijalizacijaList: Array<RadnikSpecijalizacijaCreateDto>) => {
    return axios.post(
      `${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikID}/specijalizacija-list`,
      { radnikSpecijalizacijaList: radnikSpecijalizacijaList },
      axiosConfig(authData!.token)
    );
  };

  const axiosSuperadminDeleteRadnikSpecijalizacija = (ustanovaId: number, radnikID: number, specijalizacijaID: Array<number>) => {
    return axios.delete(
      `${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/radnik-list/${radnikID}/specijalizacija-list/`,
      axiosConfig(authData!.token, { specijalizacijaID: specijalizacijaID.join(",") })
    );
  };

  const axiosSuperadminInicijalizujNadjimed = (ustanovaId: number) => {
    return axios.post(`${Endpoint.NADJIMED}/init`, {}, axiosConfig(authData!.token, { ustanovaID: ustanovaId }));
  };

  const axiosSuperadminSinhronizujCenovnikeUsluga = (ustanovaId: number) => {
    return axios.put(`${Endpoint.NADJIMED}/procedure/sync`, {}, axiosConfig(authData!.token, { ustanovaID: ustanovaId }));
  };

  const axiosSuperadminSinhronizujOrganizacioneJedinice = (ustanovaId: number) => {
    return axios.put(`${Endpoint.NADJIMED}/location/sync`, {}, axiosConfig(authData!.token, { ustanovaID: ustanovaId }));
  };

  const axiosSuperadminSinhronizujDoktore = (ustanovaId: number) => {
    return axios.put(`${Endpoint.NADJIMED}/doctor/sync`, {}, axiosConfig(authData!.token, { ustanovaID: ustanovaId }));
  };

  const axiosSuperadminGetUstanovaEksterniSistemList = (ustanovaId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/${Endpoint.USTANOVA_EKSTERNI_SISTEM_SUFIX}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateUstanovaEksterniSistem = (ustanovaId: number, ustanovaEksternisistem: UstanovaEksterniSistemReadDto) => {
    return axios.post(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/${Endpoint.USTANOVA_EKSTERNI_SISTEM_SUFIX}`, ustanovaEksternisistem, axiosConfig(authData!.token));
  };
  const axiosSuperadminUpdateUstanovaEksterniSistem = (ustanovaId: number, ustanovaEksternisistem: UstanovaEksterniSistemReadDto) => {
    return axios.put(
      `${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/${Endpoint.USTANOVA_EKSTERNI_SISTEM_SUFIX}/${ustanovaEksternisistem.id}`,
      ustanovaEksternisistem,
      axiosConfig(authData!.token)
    );
  };
  const axiosSuperadminGetUstanovaEksterniSistem = (ustanovaId: number, ustanovaEksterniSistemId: number) => {
    return axios.get(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/${Endpoint.USTANOVA_EKSTERNI_SISTEM_SUFIX}/${ustanovaEksterniSistemId}`, axiosConfig(authData!.token));
  };
  const axiosSuperadminDeleteUstanovaEksterniSistem = (ustanovaId: number, ustanovaEksterniSistemId: number) => {
    return axios.delete(`${Endpoint.SUPERADMIN_USTANOVA_LIST}/${ustanovaId}/${Endpoint.USTANOVA_EKSTERNI_SISTEM_SUFIX}/${ustanovaEksterniSistemId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetStatistickiIzvestajList = (searchString?: SearchBaseDto) => {
    return axios.get(Endpoint.IZVESTAJ_REPO_SEARCH, axiosConfig(authData!.token, searchString));
  };

  const axiosSuperadminGetKategorijeStatistickihIzvestajaList = (searchString?: SearchBaseDto) => {
    return axios.get(Endpoint.KATEGORIJA_IZVESTAJA_REPO_SEARCH, axiosConfig(authData!.token, searchString));
  };

  const axiosSuperadminCreateKategorijaStatistickihIzvestaja = (kategorijaStatistickogIzvestaja: KategorijaStatistickogIzvestajReadDto) => {
    return axios.post(Endpoint.KATEGORIJA_IZVESTAJA_REPO_LIST, kategorijaStatistickogIzvestaja ? kategorijaStatistickogIzvestaja : {}, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateKategorijaStatistickihIzvestaja = (kategorijaStatistickogIzvestaja: KategorijaStatistickogIzvestajReadDto) => {
    return axios.put(`${Endpoint.KATEGORIJA_IZVESTAJA_REPO_LIST}/${kategorijaStatistickogIzvestaja?.id}`, kategorijaStatistickogIzvestaja, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetKategorijaStatistickihIzvestaja = (kategorijaId: number) => {
    return axios.get(`${Endpoint.KATEGORIJA_IZVESTAJA_REPO_LIST}/${kategorijaId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteKategorijaStatistickihIzvestaja = (kategorijaId: number) => {
    return axios.delete(`${Endpoint.KATEGORIJA_IZVESTAJA_REPO_LIST}/${kategorijaId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateStatistickiIzvestaj = (statistickiIzvestaj: IzvestajReadDto | undefined) => {
    return axios.post(Endpoint.IZVESTAJ_REPO_LIST, statistickiIzvestaj ? statistickiIzvestaj : {}, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateStatistickiIzvestaj = (statistickiIzvestaj: IzvestajReadDto | undefined) => {
    return axios.put(`${Endpoint.IZVESTAJ_REPO_LIST}/${statistickiIzvestaj?.id}`, statistickiIzvestaj, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetStatistickiIzvestaj = (izvestajId: number) => {
    return axios.get(`${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteStatistickiIzvestaj = (izvestajId: number) => {
    return axios.delete(`${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetParametriStatistickogIzvestaja = (izvestajId: number) => {
    return axios.get(`${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajId}/izvestaj-parametar-list`, axiosConfig(authData!.token));
  };

  const axiosSuperadminCreateParametriStatistickogIzvestaja = (izvestajParametar: IzvestajParametarReadDto) => {
    return axios.post(`${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajParametar?.statistickiIzvestaj?.id}/izvestaj-parametar-list`, izvestajParametar, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetParametarStatistickogIzvestaja = (izvestajId: number, izvestajParametarId: number) => {
    return axios.get(`${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajId}/izvestaj-parametar-list/${izvestajParametarId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateParametarStatistickogIzvestaja = (izvestajParametar: IzvestajParametarReadDto) => {
    return axios.put(`${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajParametar?.statistickiIzvestaj?.id}/izvestaj-parametar-list/${izvestajParametar?.id}`, izvestajParametar, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteParametarStatistickogIzvestaja = (izvestajId: number, izvestajParametarId: number) => {
    return axios.delete(`${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajId}/izvestaj-parametar-list/${izvestajParametarId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetIzvestajParametarEnumStavkaList = (izvestajId: number, izvestajParametarId: number) => {
    return axios.get(`${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajId}/izvestaj-parametar-list/${izvestajParametarId}/izvestaj-parametar-enum-stavka-list`, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetIzvestajParametarEnumStavka = (izvestajId: number, izvestajParametarId: number, izvestajParametarEnumStavkaId: number) => {
    return axios.get(
      `${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajId}/izvestaj-parametar-list/${izvestajParametarId}/izvestaj-parametar-enum-stavka-list/${izvestajParametarEnumStavkaId}`,
      axiosConfig(authData!.token)
    );
  };

  const axiosSuperadminDeleteIzvestajParametarEnumStavka = (izvestajId: number, izvestajParametarId: number, izvestajParametarEnumStavkaId: number) => {
    return axios.delete(
      `${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajId}/izvestaj-parametar-list/${izvestajParametarId}/izvestaj-parametar-enum-stavka-list/${izvestajParametarEnumStavkaId}`,
      axiosConfig(authData!.token)
    );
  };

  const axiosSuperadminUpdateIzvestajParametarEnumStavka = (izvestajId: number, izvestajParametarEnumStavka: IzvestajParametarEnumStavkaUpdateDto) => {
    return axios.put(
      `${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajId}/izvestaj-parametar-list/${izvestajParametarEnumStavka?.statistickiIzvestajParametar?.id}/izvestaj-parametar-enum-stavka-list/${izvestajParametarEnumStavka?.id}`,
      izvestajParametarEnumStavka,
      axiosConfig(authData!.token)
    );
  };

  const axiosSuperadminCreateIzvestajParametarEnumStavka = (izvestajId: number, izvestajParametarId: number, izvestajParametar: IzvestajParametarCreateDto) => {
    return axios.post(
      `${Endpoint.IZVESTAJ_REPO_LIST}/${izvestajId}/izvestaj-parametar-list/${izvestajParametarId}/izvestaj-parametar-enum-stavka-list`,
      izvestajParametar,
      axiosConfig(authData!.token)
    );
  };

  const axiosSuperadminGetProdajniPaketList = (searchString?: SearchBaseDto) => {
    return axios.get(Endpoint.PRODAJNI_PAKETI_SEARCH, axiosConfig(authData!.token, searchString));
  };

  const axiosSuperadminCreateProdajniPaket = (prodajniPaket: ProdajaPaketCreateDto | undefined) => {
    return axios.post(Endpoint.PRODAJNI_PAKETI_LIST, prodajniPaket, axiosConfig(authData!.token));
  };

  const axiosSuperadminGetProdajniPaket = (prodajniPaketId: number) => {
    return axios.get(`${Endpoint.PRODAJNI_PAKETI_LIST}/${prodajniPaketId}`, axiosConfig(authData!.token));
  };

  const axiosSuperadminUpdateProdajniPaket = (prodajniPaketId: number, prodajniPaket: ProdajaPaketCreateDto) => {
    return axios.put(`${Endpoint.PRODAJNI_PAKETI_LIST}/${prodajniPaketId}`, prodajniPaket, axiosConfig(authData!.token));
  };

  const axiosSuperadminDeleteProdajniPaket = (prodajniPaketId: number) => {
    return axios.delete(`${Endpoint.PRODAJNI_PAKETI_LIST}/${prodajniPaketId}`, axiosConfig(authData!.token));
  };

  return {
    axiosSuperadminGetUstanovaGrid,
    axiosSuperadminUpdateUstanovaGrid,
    axiosSuperadminCreateUstanova,
    axiosSuperadminReadUstanova,
    axiosSuperadminUpdateUstanova,
    axiosSuperadminSearchUstanova,
    axiosSuperadminGetParametarUstanovaList,
    axiosSuperadminUpdateParameterListByKategorija,
    axiosSuperadminUstanovaGetUlogaList,
    axiosSuperadminUstanovaReadUloga,
    axiosSuperadminUstanovaUpdateUloga,
    axiosSuperadminUstanovaCreateUloga,
    axiosSuperadminUstanovaDeleteUloga,
    axiosSuperadminGetUstanovaRadnikList,
    axiosSuperadminDeleteUstanovaRadnik,
    axiosSuperadminGetUstanovaRadnik,
    axiosSuperadminUpdateUstanovaRadnik,
    axiosSuperadminCreateUstanovaRadnik,
    axiosSuperadminGetRadnikUlogaList,
    axiosSuperadminCreateRadnikUloga,
    axiosSuperadminDeleteRadnikUloga,
    axiosSuperadminCreateRadnikOrganizacionaJedinica,
    axiosSuperadminDeleteRadnikOrganizacionaJedinica,
    axiosSuperadminGetRadnikOrganizacionaJedinicaList,
    axiosSuperadminGetUstanovaOrganizacionaJedinicaList,
    axiosSuperadminGetUlogaRadnikList,
    axiosSuperadminPostUlogaAplikativnoPravoList,
    axiosSuperadminGetUlogaAplikativnoPravoList,
    axiosSuperadminDeleteUlogaAplikativnoPravo,
    axiosSuperadminPostUlogaPravoNadTipomList,
    axiosSuperadminGetUlogaPravoNadTipomList,
    axiosSuperadminDeleteUlogaPravoNadTipom,
    axiosSuperadminCreateRadnikUstanovaWithExistingRadnik,
    axiosSuperadminUpdateRadnikAndCreateRadnikUstanova,
    axiosSuperadminSearchSifarnikAtributaRFZO,
    axiosSuperadminGetSifarnikAtributaRFZO,
    axiosSuperadminCreateSifarnikAtributaRFZO,
    axiosSuperadminUpdateSifarnikAtributaRFZO,
    axiosSuperadminDeleteSifarnikAtributaRFZO,
    axiosSuperadminSearchSifarnikProizvodaRFZO,
    axiosSuperadminGetSifarnikProizvodaRFZO,
    axiosSuperadminCreateSifarnikProizvodaRFZO,
    axiosSuperadminUpdateSifarnikProizvodaRFZO,
    axiosSuperadminDeleteSifarnikProizvodaRFZO,
    axiosSuperadminCreateSifarnikUslugaRFZO,
    axiosSuperadminGetSifarnikUslugaRFZO,
    axiosSuperadminDeleteSifarnikUslugaRFZO,
    axiosSuperadminUpdateSifarnikUslugaRFZO,
    axiosSuperadminSearchSifarnikUslugaRFZO,
    axiosSuperadminGetUstanovaFakturniPodaci,
    axiosSuperadminUpdateUstanovaFakturniPodaci,
    axiosSuperadminGetUstanovaEntityCount,
    axiosSuperadminHardDeleteUstanova,
    axiosSuperadminGetOtherUstanovaRadnikList,
    axiosSuperadminGetSifarnikSpecijalizacija,
    axiosSuperadminGetSifarnikSpecijalizacijaById,
    axiosSuperadminCreateSpecijalizacija,
    axiosSuperadminUpdateSpecijalizacija,
    axiosSuperadminDeleteSpecijalizacija,
    axiosSuperadminGetZakazanTerminUstanovaJasper,
    axiosSuperadminCreateZakazanTerminUstanovaJasper,
    axiosSuperadminDeleteZakazanTerminUstanovaJasper,
    axiosSuperadminCreateRadnikSpecijalizacija,
    axiosSuperadminDeleteRadnikSpecijalizacija,
    axiosSuperadminGetRadnikSpecijalizacijeList,
    axiosSuperadminSinhronizujCenovnikeUsluga,
    axiosSuperadminSinhronizujDoktore,
    axiosSuperadminSinhronizujOrganizacioneJedinice,
    axiosSuperadminInicijalizujNadjimed,
    axiosSuperadminCreateUstanovaEksterniSistem,
    axiosSuperadminGetUstanovaEksterniSistem,
    axiosSuperadminGetUstanovaEksterniSistemList,
    axiosSuperadminUpdateUstanovaEksterniSistem,
    axiosSuperadminDeleteUstanovaEksterniSistem,
    axiosSuperadminGetStatistickiIzvestajList,
    axiosSuperadminGetKategorijeStatistickihIzvestajaList,
    axiosSuperadminCreateKategorijaStatistickihIzvestaja,
    axiosSuperadminUpdateKategorijaStatistickihIzvestaja,
    axiosSuperadminGetKategorijaStatistickihIzvestaja,
    axiosSuperadminDeleteKategorijaStatistickihIzvestaja,
    axiosSuperadminCreateStatistickiIzvestaj,
    axiosSuperadminUpdateStatistickiIzvestaj,
    axiosSuperadminGetStatistickiIzvestaj,
    axiosSuperadminDeleteStatistickiIzvestaj,
    axiosSuperadminGetParametriStatistickogIzvestaja,
    axiosSuperadminCreateParametriStatistickogIzvestaja,
    axiosSuperadminGetParametarStatistickogIzvestaja,
    axiosSuperadminUpdateParametarStatistickogIzvestaja,
    axiosSuperadminDeleteParametarStatistickogIzvestaja,
    axiosSuperadminGetIzvestajParametarEnumStavkaList,
    axiosSuperadminGetIzvestajParametarEnumStavka,
    axiosSuperadminDeleteIzvestajParametarEnumStavka,
    axiosSuperadminUpdateIzvestajParametarEnumStavka,
    axiosSuperadminCreateIzvestajParametarEnumStavka,
    axiosSuperadminGetProdajniPaketList,
    axiosSuperadminCreateProdajniPaket,
    axiosSuperadminGetProdajniPaket,
    axiosSuperadminUpdateProdajniPaket,
    axiosSuperadminDeleteProdajniPaket,
  };
}
