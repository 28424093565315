import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import SifarnikImportCreateDto from "../../model/sifarnik-import/SifarnikImportCreateDto";

interface SifarnikImportControllerType {
  axiosSearchSifarnikTypeList: (searchObject: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosInitiateSifarnikImport: (sifarnikImport: SifarnikImportCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetSifarnikImportList: () => Promise<AxiosResponse<any>>;
}

export function SifarnikImportController(): SifarnikImportControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchSifarnikTypeList = (searchObject: SearchBaseDto) => {
    return axios.get(Endpoint.SIFARNIK_IMPORT_TYPE_LIST, axiosConfig(authData!.token, searchObject));
  };

  const axiosInitiateSifarnikImport = (sifarnikImport: SifarnikImportCreateDto) => {
    return axios.post(Endpoint.SIFARNIK_IMPORT_LIST, sifarnikImport, axiosConfig(authData!.token));
  };

  const axiosGetSifarnikImportList = () => {
    return axios.get(Endpoint.SIFARNIK_IMPORT_LIST, axiosConfig(authData!.token));
  };

  return {
    axiosSearchSifarnikTypeList,
    axiosInitiateSifarnikImport,
    axiosGetSifarnikImportList,
  };
}
