import axios from "axios";
import moment from "moment";
import { FileUploadHandlerEvent } from "primereact/fileupload";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormularController } from "../../../controllers/formular/FormularController";
import { TipKontaktaController } from "../../../controllers/kontakt/TipKontaktaController";

import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, MAX_FILE_UPLOAD, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import FormularCreateDto from "../../../model/formular/FormularCreateDto";
import FormularReadDto from "../../../model/formular/FormularReadDto";
import FormularCopySaPoljimaDto from "../../../model/formular/FormularCopySaPoljimaDto";
import TipKontaktaReadDto from "../../../model/kontakt/TipKontaktaReadDto";

import { SuperadminKontaktTipController } from "../../../controllers/superadmin/SuperadminKontaktTipController";
import PoljeReadDto from "../../../model/polje/PoljeReadDto";
import { AppContext, useLabels } from "../../../Store";

interface FormularCrudLogicalType {
  formularId: string | undefined;
  formularOperation: string;
  isDisabled: boolean;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  formularChange: FormularReadDto | undefined;
  location: any;
  setFormularChange: any;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  first: number;
  tableRows: number;
  poljeToChange: PoljeReadDto | undefined;
  setPoljeToChange: any;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  onPageFormTable: (rows: number, first: number) => void;
  navigate: any;
  exportData: Array<any>;
  poljeList: Array<PoljeReadDto>;
  tipKontaktaId: number | undefined;
  tipKontaktaNaziv: string | undefined;
  tipKontaktaOperation: string | undefined;
  tipKontaktaOptions: Array<TipKontaktaReadDto>;
  jasperUploadHandler: (event: FileUploadHandlerEvent) => void;
  jasperUploadDisabled: boolean;
  setJasperUploadDisabled: any;
  formularLoading: boolean;
  tipKontaktaLoading: boolean;
  poljaFormularaLoading: boolean;
  formularNaziv?: string;
  kopirajFormular: () => void;
  openDialog: () => void;
  closeDialogFormularCopy: () => void;
  isOpenDialogFormularCopy: boolean;
  formularCopyChange: FormularReadDto | undefined;
  setFormularCopyChange: any;
  ustanovaId?: number;
  ustanovaNaziv?: string;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
}

interface UseParamsType {
  formularId?: string;
  ustanovaId?: string;
}

export default function CrudFormularLogical(): FormularCrudLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const [formularChange, setFormularChange] = useState<FormularReadDto | undefined>();
  const navigate = useNavigate();
  const { formularId }: UseParamsType = useParams<keyof UseParamsType>();
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const location = useLocation();
  const [formularOperation, setFormularOperation] = useState(location.state && location.state.formularOperation !== undefined ? location.state.formularOperation : EntityOperation.UPDATE);
  const [tabIndex] = useState(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [tipKontaktaId] = useState<number | undefined>(location?.state?.tipKontaktaId);
  const [ustanovaNaziv] = useState<string | undefined>(location?.state?.ustanovaNaziv);
  const [tipKontaktaOperation] = useState(location?.state?.tipKontaktaOperation);
  const [tipKontaktaNaziv] = useState(location?.state?.tipKontaktaNaziv);
  const [formularNaziv] = useState(location?.state?.formularNaziv);
  const [isDisabled] = useState<boolean>(isFormDisabled(formularOperation));
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [poljeToChange, setPoljeToChange] = useState<PoljeReadDto | undefined>();
  const [index, setIndex] = useState(tabIndex);
  const [newFormular, setNewFormular] = useState<FormularReadDto | undefined>();
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [poljeList, setPoljeList] = useState<Array<PoljeReadDto>>([]);
  const [tipKontaktaOptions, setTipKontaktaOptions] = useState<Array<TipKontaktaReadDto>>([]);
  const { axiosSearchTipKontakta } = TipKontaktaController();
  const [jasperUploadDisabled, setJasperUploadDisabled] = useState<boolean>(false);
  const [formularLoading, setFormularLoading] = useState<boolean>(true);
  const [tipKontaktaLoading, setTipKontaktaLoading] = useState<boolean>(true);
  const [poljaFormularaLoading, setPoljaFormularaLoading] = useState(false);

  const [isOpenDialogFormularCopy, setIsOpenDialogFormularCopy] = useState<boolean>(false);
  const [formularCopyChange, setFormularCopyChange] = useState<FormularReadDto | undefined>();

  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);

  const postLogHighLevel = useLogHighLevel();

  const closeDialogFormularCopy = () => {
    setIsOpenDialogFormularCopy(false);
  };

  const openDialog = () => {
    setIsOpenDialogFormularCopy(true);
    setFormularCopyChange(formularChange);
  };

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>(
    ustanovaId
      ? [
          {
            label: Labels.USTANOVA_LIST,
            command: () => {
              navigate(`/crud-ustanova`);
            },
          },
          {
            label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + " " + ustanovaNaziv,
            command: () => {
              navigate(`/crud-ustanova/${ustanovaId}`, {
                state: {
                  tabIndex: 6,
                },
              });
            },
          },
          {
            label:
              tipKontaktaOperation === EntityOperation.UPDATE
                ? Labels.TIP_KONTAKTA_TITLE_DIALOG_UPDATE + tipKontaktaNaziv
                : tipKontaktaOperation === EntityOperation.READ
                  ? Labels.TIP_KONTAKTA_TITLE_DIALOG_DETAILS + tipKontaktaNaziv
                  : tipKontaktaOperation === EntityOperation.CREATE
                    ? Labels.TIP_KONTAKTA_TITLE_DIALOG_CREATE
                    : Labels.TIP_KONTAKTA_TITLE_DIALOG_DELETE + tipKontaktaNaziv,
            command: () => {
              navigate(`/crud-ustanova/${ustanovaId}/crud-tip-kontakta/` + tipKontaktaId, {
                state: {
                  tipKontaktaOperation: tipKontaktaOperation,
                  tipKontaktaId: tipKontaktaId,
                  tipKontaktaNaziv: tipKontaktaNaziv,
                  tabIndex: 1,
                  ustanovaNaziv: ustanovaNaziv,
                  ustanovaId: ustanovaId,
                },
              });
            },
          },
          {
            label:
              formularOperation === EntityOperation.UPDATE
                ? Labels.FORMULAR_TITLE_DIALOG_UPDATE + formularNaziv
                : formularOperation === EntityOperation.READ
                  ? Labels.FORMULAR_TITLE_DIALOG_DETAILS + formularNaziv
                  : formularOperation === EntityOperation.CREATE
                    ? Labels.FORMULAR_TITLE_DIALOG_CREATE
                    : Labels.FORMULAR_TITLE_DIALOG_DELETE + formularNaziv,
            command: () => {},
          },
        ]
      : tipKontaktaId
        ? [
            {
              label: Labels.TIP_KONTAKTA_LIST,
              command: () => {
                navigate("/tip-kontakta-list/");
              },
            },
            {
              label:
                tipKontaktaOperation === EntityOperation.UPDATE
                  ? Labels.TIP_KONTAKTA_TITLE_DIALOG_UPDATE + tipKontaktaNaziv
                  : tipKontaktaOperation === EntityOperation.READ
                    ? Labels.TIP_KONTAKTA_TITLE_DIALOG_DETAILS + tipKontaktaNaziv
                    : tipKontaktaOperation === EntityOperation.CREATE
                      ? Labels.TIP_KONTAKTA_TITLE_DIALOG_CREATE
                      : Labels.TIP_KONTAKTA_TITLE_DIALOG_DELETE + tipKontaktaNaziv,
              command: () => {
                navigate("/crud-tip-kontakta/" + tipKontaktaId, {
                  state: {
                    tipKontaktaOperation: tipKontaktaOperation,
                    tipKontaktaId: tipKontaktaId,
                    tipKontaktaNaziv: tipKontaktaNaziv,
                    tabIndex: 1,
                  },
                });
              },
            },
            {
              label:
                formularOperation === EntityOperation.UPDATE
                  ? Labels.FORMULAR_TITLE_DIALOG_UPDATE + formularNaziv
                  : formularOperation === EntityOperation.READ
                    ? Labels.FORMULAR_TITLE_DIALOG_DETAILS + formularNaziv
                    : formularOperation === EntityOperation.CREATE
                      ? Labels.FORMULAR_TITLE_DIALOG_CREATE
                      : Labels.FORMULAR_TITLE_DIALOG_DELETE + formularNaziv,
              command: () => {},
            },
          ]
        : [
            {
              label: Labels.FORMULAR_LIST,
              command: () => {
                navigate("/formular-list/");
              },
            },
          ]
  );

  const { axiosCreateFormular, axiosUpdateFormular, axiosDeleteFormular, axiosGetFormular, axiosGetPoljeList, axiosCopyFormularAndPoljeList } = FormularController();
  const { axiosSuperadminCreateFormular, axiosSuperadminUpdateFormular, axiosSuperadminDeleteFormular, axiosSuperadminGetFormular, axiosSuperadminSearchKontaktTipList, axiosSuperadminGetPoljeList } =
    SuperadminKontaktTipController();

  useEffectOnce(() => {
    fetchData();
    let startTime = moment(new Date());
    (ustanovaId ? axiosSuperadminSearchKontaktTipList(ustanovaId) : axiosSearchTipKontakta())
      .then((response: any) => {
        setTipKontaktaOptions(response.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setTipKontaktaLoading, startTime);
      });
  });
  const fetchData = (fId?: number, breadCrumbSlice?: boolean, newOperation?: string | undefined) => {
    let newFormId = fId ? fId : newFormular ? newFormular.id : formularId;
    let operation = newOperation ? newOperation : formularOperation;
    if (newFormId) {
      let startTime = moment(new Date());
      setPoljaFormularaLoading(true);

      const requestForm = ustanovaId && tipKontaktaId ? axiosSuperadminGetFormular(ustanovaId, tipKontaktaId, Number(newFormId)) : axiosGetFormular(Number(newFormId));
      const requestPoljeList = ustanovaId && tipKontaktaId ? axiosSuperadminGetPoljeList(ustanovaId, tipKontaktaId, Number(newFormId)) : axiosGetPoljeList(Number(newFormId));
      axios
        .all([requestForm, requestPoljeList])
        .then(
          axios.spread((responseForm: any, responsePoljeList: any) => {
            setPoljeList(responsePoljeList ? responsePoljeList.data.data : []);
            if (tipKontaktaId !== undefined) responseForm.data.data.kontaktTip = { id: tipKontaktaId };
            setFormularChange(responseForm.data.data);
            let exportList = new Array<any>();
            responsePoljeList &&
              responsePoljeList.data.data.forEach((polje: PoljeReadDto) => {
                exportList.push({
                  [Labels.LABEL_SIFRA]: polje.sifra,
                  [Labels.LABEL_NAZIV]: polje.naziv,
                });
              });
            if (responseForm.data.data.jrDokument !== undefined && responseForm.data.data.jrDokument !== null && responseForm.data.data.jrDokument !== "") {
              setJasperUploadDisabled(true);
            }
            setExportData(exportList);
            let tempBreadCrumbItems = breadCrumbItems;
            if (tipKontaktaId !== undefined) {
              tempBreadCrumbItems = ustanovaId
                ? [
                    {
                      label: Labels.USTANOVA_LIST,
                      command: () => {
                        navigate(`/crud-ustanova`);
                      },
                    },
                    {
                      label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + " " + ustanovaNaziv,
                      command: () => {
                        navigate(`/crud-ustanova/${ustanovaId}`, {
                          state: {
                            tabIndex: 6,
                          },
                        });
                      },
                    },
                    {
                      label:
                        tipKontaktaOperation === EntityOperation.UPDATE
                          ? Labels.TIP_KONTAKTA_TITLE_DIALOG_UPDATE + tipKontaktaNaziv
                          : tipKontaktaOperation === EntityOperation.READ
                            ? Labels.TIP_KONTAKTA_TITLE_DIALOG_DETAILS + tipKontaktaNaziv
                            : tipKontaktaOperation === EntityOperation.CREATE
                              ? Labels.TIP_KONTAKTA_TITLE_DIALOG_CREATE
                              : Labels.TIP_KONTAKTA_TITLE_DIALOG_DELETE + tipKontaktaNaziv,
                      command: () => {
                        navigate(`/crud-ustanova/${ustanovaId}/crud-tip-kontakta/` + tipKontaktaId, {
                          state: {
                            tipKontaktaOperation: tipKontaktaOperation,
                            tipKontaktaId: tipKontaktaId,
                            tipKontaktaNaziv: tipKontaktaNaziv,
                            tabIndex: 1,
                            ustanovaNaziv: ustanovaNaziv,
                            ustanovaId: ustanovaId,
                          },
                        });
                      },
                    },
                  ]
                : [
                    {
                      label: Labels.TIP_KONTAKTA_LIST,
                      command: () => {
                        navigate("/tip-kontakta-list/");
                      },
                    },
                    {
                      label:
                        tipKontaktaOperation === EntityOperation.UPDATE
                          ? Labels.TIP_KONTAKTA_TITLE_DIALOG_UPDATE + tipKontaktaNaziv
                          : tipKontaktaOperation === EntityOperation.READ
                            ? Labels.TIP_KONTAKTA_TITLE_DIALOG_DETAILS + tipKontaktaNaziv
                            : tipKontaktaOperation === EntityOperation.CREATE
                              ? Labels.TIP_KONTAKTA_TITLE_DIALOG_CREATE
                              : Labels.TIP_KONTAKTA_TITLE_DIALOG_DELETE + tipKontaktaNaziv,
                      command: () => {
                        navigate("/crud-tip-kontakta/" + tipKontaktaId, {
                          state: {
                            tipKontaktaOperation: tipKontaktaOperation,
                            tipKontaktaId: tipKontaktaId,
                            tipKontaktaNaziv: tipKontaktaNaziv,
                            tabIndex: 1,
                          },
                        });
                      },
                    },
                    {
                      label:
                        formularOperation === EntityOperation.UPDATE
                          ? Labels.FORMULAR_TITLE_DIALOG_UPDATE + formularNaziv
                          : formularOperation === EntityOperation.READ
                            ? Labels.FORMULAR_TITLE_DIALOG_DETAILS + formularNaziv
                            : formularOperation === EntityOperation.CREATE
                              ? Labels.FORMULAR_TITLE_DIALOG_CREATE
                              : Labels.FORMULAR_TITLE_DIALOG_DELETE + formularNaziv,
                      command: () => {},
                    },
                  ];
            }
            if (tempBreadCrumbItems.length >= (ustanovaId ? 4 : 2)) tempBreadCrumbItems.pop();
            setBreadCrumbItems([
              ...tempBreadCrumbItems,
              {
                label:
                  operation === EntityOperation.UPDATE
                    ? Labels.FORMULAR_TITLE_DIALOG_UPDATE + responseForm.data.data.naziv
                    : operation === EntityOperation.READ
                      ? Labels.FORMULAR_TITLE_DIALOG_DETAILS + responseForm.data.data.naziv
                      : Labels.FORMULAR_TITLE_DIALOG_DELETE + responseForm.data.data.naziv,
              },
            ]);

            if (breadCrumbSlice) {
              tempBreadCrumbItems = breadCrumbItems.slice(0, breadCrumbItems.length - 1);
              setBreadCrumbItems([
                ...tempBreadCrumbItems,
                {
                  label:
                    formularOperation === EntityOperation.UPDATE
                      ? Labels.FORMULAR_TITLE_DIALOG_UPDATE + responseForm.data.data.naziv
                      : formularOperation === EntityOperation.READ
                        ? Labels.FORMULAR_TITLE_DIALOG_DETAILS + responseForm.data.data.naziv
                        : Labels.FORMULAR_TITLE_DIALOG_DELETE + responseForm.data.data.naziv,
                },
              ]);
            }
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPoljaFormularaLoading, startTime);
          skeletonTimeout(setFormularLoading, startTime);
        });
    } else {
      if (!tipKontaktaId) {
        setBreadCrumbItems([
          ...breadCrumbItems,
          {
            label: Labels.FORMULAR_TITLE_DIALOG_CREATE,
          },
        ]);
      }
      setFormularLoading(false);
    }
  };

  const validateInput = (formular?: FormularReadDto) => {
    let isInvalid = false;
    if (validateStringEmpty(formular?.sifra)) {
      setInvalidFields((prev: any) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(formular?.naziv)) {
      setInvalidFields((prev: any) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (!formular?.kontaktTip && !tipKontaktaId) {
      setInvalidFields((prev: any) => ({ ...prev, kontaktTip: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(formularChange)) {
      return;
    }
    let formularChangeTemp: FormularCreateDto | undefined = formularChange ? { ...formularChange } : undefined;

    if (tipKontaktaId && formularChangeTemp) {
      formularChangeTemp.kontaktTip = { id: tipKontaktaId };
    }
    if (formularChangeTemp) {
      setShowBlockUI(true);
      (ustanovaId && tipKontaktaId ? axiosSuperadminCreateFormular(ustanovaId, tipKontaktaId, formularChangeTemp) : axiosCreateFormular(formularChangeTemp))
        .then((res: any) => {
          setNewFormular(res.data.data);
          setIndex(0);
          showMessage(MessageType.SUCCESS, Labels.FORMULAR_TITLE_MESSAGE_CREATE_FORMULAR_SUCCESS);
          setFormularOperation(EntityOperation.UPDATE);
          fetchData(res.data.data.id, false, EntityOperation.UPDATE);
          navigate(ustanovaId ? `/crud-ustanova/${ustanovaId}/crud-tip-kontakta/${tipKontaktaId}/crud-formular/${res.data.data.id}` : `/crud-formular/${res.data.data.id}`);
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_1 + formularChange?.naziv + Labels.LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_2 + formularChange?.sifra + ".");
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(formularChange)) {
      return;
    }
    if (formularChange) {
      setShowBlockUI(true);
      (ustanovaId && tipKontaktaId ? axiosSuperadminUpdateFormular(ustanovaId, tipKontaktaId, formularChange) : axiosUpdateFormular(formularChange))
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.FORMULAR_TITLE_MESSAGE_UPDATE_FORMULAR_SUCCESS);
          onCancel();
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_1 + formularChange?.naziv + Labels.LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_2 + formularChange?.sifra + ".");
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    (ustanovaId && tipKontaktaId ? axiosSuperadminDeleteFormular(ustanovaId, tipKontaktaId, Number(formularId)) : axiosDeleteFormular(Number(formularId)))
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.FORMULAR_TITLE_MESSAGE_DELETE_FORMULAR_SUCCESS);
        onCancel();
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_1 + formularChange?.naziv + Labels.LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_2 + formularChange?.sifra + ".");
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];

      breadCrumb.command && breadCrumb.command();
    } else navigate(`/formular-list`);
  };

  const kopirajFormular = () => {
    if (!validateInput(formularCopyChange)) {
      return;
    }
    let formularChangeTemp = {} as FormularCopySaPoljimaDto;
    if (formularCopyChange !== undefined) {
      formularChangeTemp.formular = formularCopyChange;
      formularChangeTemp.poljeList = poljeList;
    }
    formularChangeTemp.formular.htmlPredlozak = formularChange?.htmlPredlozak;
    formularChangeTemp.formular.jrDokument = formularChange?.jrDokument;
    formularChangeTemp.formular.jrDokumentNaziv = formularChange?.jrDokumentNaziv;

    if (formularChangeTemp) {
      setShowBlockUI(true);
      axiosCopyFormularAndPoljeList(formularChangeTemp)
        .then((res: any) => {
          setNewFormular(res.data.data);
          setIndex(0);
          showMessage(MessageType.SUCCESS, Labels.FORMULAR_TITLE_MESSAGE_CREATE_FORMULAR_SUCCESS);
          setFormularOperation(EntityOperation.UPDATE);
          fetchData(res.data.data.id);
          navigate(`/crud-formular/${res.data.data.id}`);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
          setIsOpenDialogFormularCopy(false);
          closeDialogFormularCopy();
        });
    }
  };

  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const jasperUploadHandler = (e: FileUploadHandlerEvent) => {
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }

        if (reader.result) {
          setFormularChange({
            ...formularChange!,
            jrDokument: reader.result.toString().substr(reader.result?.toString().indexOf(",") + 1),
            jrDokumentNaziv: file.name,
          });
        }
      };
    });
  };

  return {
    formularId,
    formularOperation,
    isDisabled,
    formularChange,
    location,
    breadCrumbItems,
    setFormularChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    first,
    tableRows,
    poljeToChange,
    setPoljeToChange,
    setIndex,
    index,
    onPageFormTable,
    navigate,
    exportData,
    poljeList,
    tipKontaktaId,
    tipKontaktaNaziv,
    tipKontaktaOperation,
    tipKontaktaOptions,
    jasperUploadHandler,
    jasperUploadDisabled,
    setJasperUploadDisabled,
    formularLoading,
    tipKontaktaLoading,
    poljaFormularaLoading,
    formularNaziv,
    kopirajFormular,
    closeDialogFormularCopy,
    openDialog,
    isOpenDialogFormularCopy,
    formularCopyChange,
    setFormularCopyChange,
    ustanovaId,
    ustanovaNaziv,
    invalidFields,
    setInvalidFields,
  };
}
