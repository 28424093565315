import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { EnumController } from "../../../controllers/enum/EnumController";
import { RolaService } from "../../../controllers/rola/RolaService";
import { UlogaController } from "../../../controllers/uloga/UlogaController";
import EnumRola from "../../../infrastructure/system/EnumRola";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import UlogaReadDto from "../../../model/uloga/UlogaReadDto";

interface UlogaListLogicalType {
  ulogaList: Array<UlogaReadDto>;
  first: number;
  tableRows: number;
  changeUlogaSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  ulogaSearchData: SearchBaseDto;
  fetchData: () => void;
  searchUlogaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  onPageUloga: (rows: number, first: number) => void;
  ulogaLoading: boolean;
  isSuperadmin: boolean;
  enumRolaList: Array<EnumBaseReadDto>;
}

export default function UlogaListLogical(): UlogaListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [ulogaList, setUlogaList] = useState<Array<UlogaReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [ulogaSearchData, setUlogaSearchData] = useState<SearchBaseDto>({});
  const { axiosSearchUloga } = UlogaController();
  const [ulogaLoading, setUlogaLoading] = useState<boolean>(false);
  const { isSuperadmin } = RolaService();
  const [enumRolaList, setEnumRolaList] = useState<Array<EnumBaseReadDto>>([]);
  const { axiosGetRolaList } = EnumController();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.ULOGA_LIST,
    },
  ];
  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setUlogaLoading(true);

    axiosSearchUloga(ulogaSearchData)
      .then((res: any) => {
        const ulogaList = isSuperadmin ? res.data.data : res.data.data.filter((uloga: UlogaReadDto) => uloga.rola.sifra !== EnumRola.SUPERADMIN);
        setUlogaList(ulogaList);
        let exportList = new Array<any>();
        ulogaList.forEach((uloga: UlogaReadDto) => {
          exportList.push({
            [Labels.LABEL_NAZIV]: uloga.naziv,
            [Labels.LABEL_SIFRA]: uloga.sifra,
            [Labels.ULOGA_OPIS]: uloga.opis,
            [Labels.ULOGA_NAPOMENA]: uloga.napomena,
            [Labels.ULOGA_ROLA]: uloga.rola.naziv,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setUlogaLoading, startTime);
      });

    axiosGetRolaList()
      .then((response: any) => {
        const enumRolaList = isSuperadmin ? response.data.data : response.data.data.filter((enumRola: EnumBaseReadDto) => enumRola.sifra !== EnumRola.SUPERADMIN);
        setEnumRolaList(enumRolaList);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const changeUlogaSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setUlogaSearchData({
      ...ulogaSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchUlogaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageUloga = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    ulogaList,
    first,
    tableRows,
    changeUlogaSearchData,
    ulogaSearchData,
    fetchData,
    searchUlogaByEnter,
    exportData,
    breadCrumbItems,
    onPageUloga,
    ulogaLoading,
    isSuperadmin,
    enumRolaList,
  };
}
