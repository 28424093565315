import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import { useNavigate } from "react-router";
import { useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import RepositoryLogical from "../../../infrastructure/system/RepositoryLogical";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import SifarnikProizvodaRfzoReadDto from "../../../model/sifarnik/sifarnik-proizvoda-rfzo/SifarnikProizvodaRfzoReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import SifarnikProizvodaRfzoListLogical from "./SifarnikProizvodaRfzoListLogical";

export default function SifarnikProizvodaRfzoListView() {
  const {
    breadCrumbItems,
    fetchData,
    sifarnikProizvodaRfzoSearchData,
    searchSifarnikProizvodaRfzoByEnter,
    changeSifarnikProizvodaRfzoSearchData,
    sifarnikProizvodaRfzoLoading,
    exportData,
    sifarnikProizvodaRfzoList,
    first,
    tableRows,
    onPageSifarnikProizvodaRfzo,
  } = SifarnikProizvodaRfzoListLogical();

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const { statusBodyTemplate, statusRowFilterTemplate } = RepositoryLogical();

  const openSifarnikProizvoda = (entityOperation: string, id?: number) => {
    const sifarnikProizvodaId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/repository-crud-sifarnik-proizvoda-rfzo/${sifarnikProizvodaId}`, {
      state: { sifarnikProizvodaOperation: entityOperation },
    });
  };

  const crudBodyTemplate = (rowSifarnikProizvoda: SifarnikProizvodaRfzoReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowSifarnikProizvoda && openSifarnikProizvoda(EntityOperation.UPDATE, rowSifarnikProizvoda.id)}
        />
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowSifarnikProizvoda && openSifarnikProizvoda(EntityOperation.DELETE, rowSifarnikProizvoda.id)}
        />
      </div>
    );
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_CREATE}
          tooltip={Labels.SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openSifarnikProizvoda(EntityOperation.CREATE);
          }}
        />
        <span className="p-float-label">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={sifarnikProizvodaRfzoSearchData?.searchString ?? ""}
            onChange={changeSifarnikProizvodaRfzoSearchData}
            onKeyPress={searchSifarnikProizvodaRfzoByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_SIFARNIK_PROIZVODA_RFZO}</label>
        </span>
        <Button tooltip={Labels.SEARCH_SIFARNIK_PROIZVODA_RFZO} icon="pi pi-search" className="search-button" onClick={fetchData} />
      </>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={sifarnikProizvodaRfzoLoading} data={exportData} name={Labels.LABEL_SIFARNIK_PROIZVODA_RFZO} dt={dt} />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.SIFARNIK_PROIZVODA_RFZO_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_PROIZVODA_RFZO_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.SIFARNIK_PROIZVODA_RFZO_STATUS, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {sifarnikProizvodaRfzoLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={sifarnikProizvodaRfzoList ?? []}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageSifarnikProizvodaRfzo(e.rows, e.first);
            }}
            exportFilename={Labels.LABEL_SIFARNIK_USLUGA}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.SIFARNIK_PROIZVODA_RFZO_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.SIFARNIK_PROIZVODA_RFZO_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column
              field={"entityStatus.sifra"}
              header={Labels.SIFARNIK_PROIZVODA_RFZO_STATUS}
              body={statusBodyTemplate}
              className="w-3"
              filter
              filterElement={statusRowFilterTemplate}
              showFilterMenu={false}
              sortable
            />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
