import { useQuery } from "@tanstack/react-query";
import { UstanovaController } from "../../../../controllers/ustanova/UstanovaController";
import QueryKeys from "../../QueryKeys";

export function useUstanovaGrid() {
  const { axiosGetUstanovaGrid } = UstanovaController();

  return useQuery({
    queryKey: [QueryKeys.USTANOVA_GRID],
    queryFn: () => axiosGetUstanovaGrid().then((res) => res.data.data),
  });
}
