import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { SetStateAction } from "react";
import { useLabels } from "../../../../../../Store";
import EnumTipTerapijePrikaz from "../../../../../../model/kontakt/EnumTipTerapijePrikaz";
import ReceptCreateDto from "../../../../../../model/kontakt/ReceptCreateDto";
import SifarnikJedinicaMereReadDto from "../../../../../../model/repository/jedinice-mere/SifarnikJedinicaMereReadDto";
import { isChecked } from "../KontaktTerapijeDialogLogical";

interface ThirdStepType {
  terapijaChange: ReceptCreateDto | undefined;
  setTerapijaChange: any;
  isChecked: any;
  setIsChecked: React.Dispatch<SetStateAction<isChecked | undefined>>;
  getSifraStringFromTerapija: (sifra: string) => string;
  jediniceMereList: Array<SifarnikJedinicaMereReadDto>;
  getJedinicaMere: (jedinicaMereId: number) => void;
}

export default function ThirdStep(props: ThirdStepType) {
  const { terapijaChange, setTerapijaChange, isChecked, setIsChecked, getSifraStringFromTerapija, jediniceMereList, getJedinicaMere } = props;
  const Labels = useLabels();

  return (
    <>
      <h4 className="font-bold mb-6 pl-3">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_TITLE}</h4>
      <div className="terapijaDialog--content page-3">
        <div className="flex flex-row flex-wrap align-items-center">
          {terapijaChange?.tipTerapije?.sifra === getSifraStringFromTerapija(EnumTipTerapijePrikaz.NARKOTIK) && (
            <>
              <div className="col-3">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PROTOKOLA + Labels.SPECIAL_CHAR_REQUIRED}</div>
              <div className="col-9">
                <span className="w-50">
                  <InputText
                    className="block"
                    value={terapijaChange?.brojProtokola ?? ""}
                    onChange={(e) => {
                      setTerapijaChange({
                        ...terapijaChange!,
                        brojProtokola: e.target.value,
                      });
                    }}
                  />
                </span>
              </div>
            </>
          )}
          {terapijaChange?.tipTerapije?.sifra === getSifraStringFromTerapija(EnumTipTerapijePrikaz.HRONICNA) && (
            <>
              <div className="col-3">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_IZDAVANJA + Labels.SPECIAL_CHAR_REQUIRED}</div>
              <div className="col-9">
                <InputNumber
                  className="w-50"
                  value={terapijaChange?.brojIzdavanja}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      brojIzdavanja: e.value,
                    });
                  }}
                />
              </div>
              <div className="col-3">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE + Labels.SPECIAL_CHAR_REQUIRED}</div>
              <div className="col-9 flex align-items-center">
                <Dropdown
                  value={terapijaChange?.hronicnaBrojMeseci}
                  options={[
                    { label: 2, value: 2 },
                    { label: 3, value: 3 },
                    { label: 4, value: 4 },
                    { label: 5, value: 5 },
                    { label: 6, value: 6 },
                  ]}
                  optionLabel="label"
                  optionValue="value"
                  onChange={(e) => {
                    setTerapijaChange({ ...terapijaChange, hronicnaBrojMeseci: e.value });
                  }}
                  className="w-50"
                />
                <span className="ml-2 raw-text">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_MESECI}</span>
              </div>
            </>
          )}
          {terapijaChange?.tipTerapije?.sifra === getSifraStringFromTerapija(EnumTipTerapijePrikaz.AKUTNA) && (
            <>
              <div className="col-3">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE + Labels.SPECIAL_CHAR_REQUIRED}</div>
              <div className="col-9">
                <InputNumber
                  className="small-input"
                  value={terapijaChange?.akutnaBrojDana}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      akutnaBrojDana: e.value,
                    });
                  }}
                />
                <span className="ml-2 raw-text">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_DANA}</span>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-row flex-wrap align-items-center">
          <div className="col-3">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_BROJ_PAKOVANJA + Labels.SPECIAL_CHAR_REQUIRED}</div>
          <div className="col-9">
            <InputNumber
              className="small-input"
              value={terapijaChange?.brojPakovanja}
              onChange={(e) => {
                setTerapijaChange({
                  ...terapijaChange!,
                  brojPakovanja: e.value,
                });
              }}
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap align-items-center">
          <div className="col-3">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_JEDINICA_MERE + Labels.SPECIAL_CHAR_REQUIRED}</div>
          <div className="col-9">
            <Dropdown
              value={terapijaChange?.jedinicaMere}
              options={jediniceMereList}
              optionLabel="akronim"
              optionValue="id"
              onChange={(e) => {
                setTerapijaChange({ ...terapijaChange, jedinicaMere: e.value });
                getJedinicaMere(e.value);
              }}
              className="w-50"
              id="jedinicaMere"
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap">
          <div className="col-3">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA + Labels.SPECIAL_CHAR_REQUIRED}</div>
          <div className="col-9">
            <div className="flex flex-row align-items-center mb-3">
              <div className="radio-wrapper">
                <RadioButton
                  inputId="jednako"
                  name="tipTerapije"
                  value={""}
                  checked={isChecked?.isJednakoChecked}
                  onChange={(e) => {
                    setIsChecked({
                      ...isChecked!,
                      isJednakoChecked: e.target.checked,
                      isNaSvakihChecked: false,
                      isJednokratnoChecked: false,
                      isKombinovanoChecked: false,
                      isPoPotrebiChecked: false,
                      isProizvoljnoChecked: false,
                    });
                    setTerapijaChange({
                      ...terapijaChange,
                      jutarnjaKolicina: null,
                      popodnevnaKolicina: null,
                      vecernjaKolicina: null,
                      jednokratnaKolicina: null,
                      naKolikoDana: null,
                      kolicinaNaViseDana: null,
                      kolicinaPoPotrebi: null,
                      proizvoljnaKolicina: null,
                    });
                  }}
                />
                <label className="cursor-pointer mx-2" htmlFor="jednako">
                  {Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNAKO}
                </label>
              </div>
              <div className="input-wrapper">
                <InputNumber
                  className="small-input"
                  disabled={!isChecked?.isJednakoChecked}
                  value={isChecked?.isJednakoChecked ? terapijaChange?.brojUzimanjaTokomDana : null}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      brojUzimanjaTokomDana: e.value,
                    });
                  }}
                />
                <span className="mx-2 raw-text">{Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_DNEVNO}</span>
              </div>
              <div className="input-wrapper">
                <InputNumber
                  className="small-input"
                  disabled={!isChecked?.isJednakoChecked}
                  value={isChecked?.isJednakoChecked ? terapijaChange?.dnevnaKolicina : null}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      dnevnaKolicina: e.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row align-items-center mb-3">
              <div className="radio-wrapper">
                <RadioButton
                  inputId="kombinovano"
                  name="tipTerapije"
                  value={""}
                  checked={isChecked?.isKombinovanoChecked}
                  onChange={(e) => {
                    setIsChecked({
                      ...isChecked!,
                      isKombinovanoChecked: e.target.checked,
                      isJednakoChecked: false,
                      isJednokratnoChecked: false,
                      isNaSvakihChecked: false,
                      isPoPotrebiChecked: false,
                      isProizvoljnoChecked: false,
                    });
                    setTerapijaChange({
                      ...terapijaChange,
                      brojUzimanjaTokomDana: null,
                      dnevnaKolicina: null,
                      jednokratnaKolicina: null,
                      naKolikoDana: null,
                      kolicinaNaViseDana: null,
                      kolicinaPoPotrebi: null,
                      proizvoljnaKolicina: null,
                    });
                  }}
                />
                <label className="cursor-pointer mx-2" htmlFor="kombinovano">
                  {Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO}
                </label>
              </div>
              <div className="input-wrapper">
                <InputNumber
                  className="small-input"
                  disabled={!isChecked?.isKombinovanoChecked}
                  id="brojUzimanjaTokomDana"
                  value={isChecked?.isKombinovanoChecked ? terapijaChange?.jutarnjaKolicina : null}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      jutarnjaKolicina: e.value,
                    });
                  }}
                />
                <span className="mx-2 raw-text">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UJUTRU}</span>
              </div>
              <div className="input-wrapper">
                <InputNumber
                  className="small-input"
                  disabled={!isChecked?.isKombinovanoChecked}
                  value={isChecked?.isKombinovanoChecked ? terapijaChange?.popodnevnaKolicina : null}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      popodnevnaKolicina: e.value,
                    });
                  }}
                />
                <span className="mx-2 raw-text">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_U_PODNE}</span>
              </div>
              <div className="input-wrapper">
                <InputNumber
                  className="small-input"
                  disabled={!isChecked?.isKombinovanoChecked}
                  value={isChecked?.isKombinovanoChecked ? terapijaChange?.vecernjaKolicina : null}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      vecernjaKolicina: e.value,
                    });
                  }}
                />
                <span className="ml-2 raw-text">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UVECE}</span>
              </div>
            </div>
            <div className="flex flex-row align-items-center mb-3">
              <div className="radio-wrapper">
                <RadioButton
                  inputId="jednokratno"
                  name="tipTerapije"
                  value={""}
                  checked={isChecked?.isJednokratnoChecked}
                  onChange={(e) => {
                    setIsChecked({
                      ...isChecked!,
                      isJednokratnoChecked: e.target.checked,
                      isJednakoChecked: false,
                      isKombinovanoChecked: false,
                      isNaSvakihChecked: false,
                      isPoPotrebiChecked: false,
                      isProizvoljnoChecked: false,
                    });
                    setTerapijaChange({
                      ...terapijaChange,
                      brojUzimanjaTokomDana: null,
                      dnevnaKolicina: null,
                      jutarnjaKolicina: null,
                      popodnevnaKolicina: null,
                      vecernjaKolicina: null,
                      naKolikoDana: null,
                      kolicinaNaViseDana: null,
                      kolicinaPoPotrebi: null,
                      proizvoljnaKolicina: null,
                    });
                  }}
                />
                <label className="cursor-pointer mx-2" htmlFor="jednokratno">
                  {Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO}
                </label>
              </div>
              <div className="input-wrapper">
                <InputNumber
                  className="small-input"
                  disabled={!isChecked?.isJednokratnoChecked}
                  id="jednokratnaKolicina"
                  value={isChecked?.isJednokratnoChecked ? terapijaChange?.jednokratnaKolicina : null}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      jednokratnaKolicina: e.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row align-items-center mb-3">
              <div className="radio-wrapper">
                <RadioButton
                  inputId="naSvakih"
                  name="tipTerapije"
                  value={""}
                  checked={isChecked?.isNaSvakihChecked}
                  onChange={(e) => {
                    setIsChecked({
                      ...isChecked!,
                      isNaSvakihChecked: e.target.checked,
                      isJednakoChecked: false,
                      isKombinovanoChecked: false,
                      isJednokratnoChecked: false,
                      isPoPotrebiChecked: false,
                      isProizvoljnoChecked: false,
                    });
                    setTerapijaChange({
                      ...terapijaChange,
                      brojUzimanjaTokomDana: null,
                      dnevnaKolicina: null,
                      jutarnjaKolicina: null,
                      popodnevnaKolicina: null,
                      vecernjaKolicina: null,
                      jednokratnaKolicina: null,
                      kolicinaPoPotrebi: null,
                      proizvoljnaKolicina: null,
                    });
                  }}
                />
                <label className="cursor-pointer mx-2" htmlFor="naSvakih">
                  {Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_NA_SVAKIH}
                </label>
              </div>
              <div className="input-wrapper">
                <InputNumber
                  className="small-input"
                  disabled={!isChecked?.isNaSvakihChecked}
                  value={isChecked?.isNaSvakihChecked ? terapijaChange?.naKolikoDana : null}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      naKolikoDana: e.value,
                    });
                  }}
                />
                <span className="raw-text mx-2">{Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_DANA}</span>
              </div>
              <div className="input-wrapper">
                <InputNumber
                  className="small-input"
                  disabled={!isChecked?.isNaSvakihChecked}
                  value={isChecked?.isNaSvakihChecked ? terapijaChange?.kolicinaNaViseDana : null}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      kolicinaNaViseDana: e.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row align-items-center mb-3">
              <div className="radio-wrapper">
                <RadioButton
                  inputId="poPotrebi"
                  name="tipTerapije"
                  value={""}
                  checked={isChecked?.isPoPotrebiChecked}
                  onChange={(e) => {
                    setIsChecked({
                      ...isChecked!,
                      isPoPotrebiChecked: e.target.checked,
                      isJednakoChecked: false,
                      isKombinovanoChecked: false,
                      isJednokratnoChecked: false,
                      isNaSvakihChecked: false,
                      isProizvoljnoChecked: false,
                    });
                    setTerapijaChange({
                      ...terapijaChange,
                      brojUzimanjaTokomDana: null,
                      dnevnaKolicina: null,
                      jutarnjaKolicina: null,
                      popodnevnaKolicina: null,
                      vecernjaKolicina: null,
                      jednokratnaKolicina: null,
                      naKolikoDana: null,
                      kolicinaNaViseDana: null,
                      proizvoljnaKolicina: null,
                    });
                  }}
                />
                <label className="cursor-pointer mx-2" htmlFor="poPotrebi">
                  {Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_PO_POTREBI}
                </label>
              </div>
              <div className="input-wrapper">
                <InputNumber
                  className="small-input"
                  disabled={!isChecked?.isPoPotrebiChecked}
                  value={isChecked?.isPoPotrebiChecked ? terapijaChange?.kolicinaPoPotrebi : null}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      kolicinaPoPotrebi: e.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row align-items-center">
              <div className="radio-wrapper">
                <RadioButton
                  inputId="proizvoljno"
                  name="tipTerapije"
                  value={""}
                  checked={isChecked?.isProizvoljnoChecked}
                  onChange={(e) => {
                    setIsChecked({
                      ...isChecked!,
                      isProizvoljnoChecked: e.target.checked,
                      isJednakoChecked: false,
                      isPoPotrebiChecked: false,
                      isKombinovanoChecked: false,
                      isJednokratnoChecked: false,
                      isNaSvakihChecked: false,
                    });
                    setTerapijaChange({
                      ...terapijaChange,
                      brojUzimanjaTokomDana: null,
                      dnevnaKolicina: null,
                      jutarnjaKolicina: null,
                      popodnevnaKolicina: null,
                      vecernjaKolicina: null,
                      jednokratnaKolicina: null,
                      naKolikoDana: null,
                      kolicinaNaViseDana: null,
                      kolicinaPoPotrebi: null,
                    });
                  }}
                />
                <label className="cursor-pointer mx-2" htmlFor="proizvoljno">
                  {Labels.KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_PROIZVOLJNO}
                </label>
              </div>
              <div className="input-wrapper">
                <InputText
                  className="block w-full"
                  disabled={!isChecked?.isProizvoljnoChecked}
                  value={terapijaChange?.proizvoljnaKolicina ?? ""}
                  onChange={(e) => {
                    setTerapijaChange({
                      ...terapijaChange!,
                      proizvoljnaKolicina: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
