import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber, InputNumberChangeEvent, InputNumberValueChangeEvent } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Skeleton } from "primereact/skeleton";
import { useContext, useState } from "react";
import { useLabels } from "../../../../Store";
import EnumNamenaKategorijePopusta from "../../../../infrastructure/system/EnumNamenaKategorijePopusta";
import EnumTipKategorijePopusta from "../../../../infrastructure/system/EnumTipKategorijePopusta";
import EnumUplatilacNaplata from "../../../../infrastructure/system/EnumUplatilacNaplata";
import { formatPrice } from "../../../../infrastructure/system/Utils";
import useEffectWithoutWarnings from "../../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import KontaktProizvodOsnovnoReadDto from "../../../../model/kontakt/KontaktProizvodOsnovnoReadDto";
import StavkaNaplate from "../../../../model/naplata/StavkaNaplate";
import ProizvodCenaReadDto from "../../../../model/sifarnik-proizvoda/ProizvodCenaReadDto";
import SifarnikKategorijaPopustaReadDto from "../../../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaReadDto";
import { useSifarnikKategorijaPopustaItem } from "../../../administracija/sifarnik-kategorije-popusta/hooks/useSifarnikKategorijaPopustaItem";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import { NaplataContext } from "../../NaplataLogical";
import NaplataUtils from "../../NaplataUtils";
import { usePartnerList } from "../../hooks/usePartnerList";

interface StavkaNaplateProizvodProps {
  index: number;
  kontaktProizvod: KontaktProizvodOsnovnoReadDto;
  cena?: ProizvodCenaReadDto;
  kategorijePopustaList: SifarnikKategorijaPopustaReadDto[];
  stavkaNaplate: StavkaNaplate;
  cenaIsLoading: boolean;
}

const StavkaNaplateProizvod = (props: StavkaNaplateProizvodProps) => {
  const { index, stavkaNaplate, cena, kategorijePopustaList, kontaktProizvod, cenaIsLoading } = props;
  const { state, uplatilacList, updateStavkaValue, setInvalidInputs, invalidInputs, dispatch, finalnaValuta } = useContext(NaplataContext);
  const [selectedPopust, setSelectedPopust] = useState<SifarnikKategorijaPopustaReadDto | undefined>(stavkaNaplate.kategorijaPopusta);
  const Labels = useLabels();
  const { partnerList: partnerListForProizvod } = usePartnerList();
  const { sifarnikKategorijaPopusta } = useSifarnikKategorijaPopustaItem(selectedPopust?.id);
  const { calculateCenaForProizvod } = NaplataUtils();

  const stavkeNaplate = Object.values(state.usluge)
    .flatMap((stavkaNaplatePair) => stavkaNaplatePair.map((stavkaNaplate) => stavkaNaplate))
    .concat(Object.values(state.proizvodi).flatMap((stavkaNaplatePair) => stavkaNaplatePair.map((stavkaNaplate) => stavkaNaplate)));
  const partnerStavkaNaplate = stavkeNaplate?.find((stavkaNaplate) => stavkaNaplate?.uplatilac?.sifra === EnumUplatilacNaplata.PARTNER && !!stavkaNaplate?.partner);

  useEffectWithoutWarnings(() => {
    updateStavkaValue("kategorijaPopusta", kontaktProizvod.id, sifarnikKategorijaPopusta, index, "proizvodi");
  }, [sifarnikKategorijaPopusta]);

  useEffectWithoutWarnings(() => {
    updateStavkaValue("iznos", kontaktProizvod.id, calculateCenaForProizvod(index, state?.proizvodi?.[kontaktProizvod.id], cena), index, "proizvodi");
    updateStavkaValue("iznosBezPacijentPopusta", kontaktProizvod.id, calculateCenaForProizvod(index, state.proizvodi[kontaktProizvod.id], cena, true), index, "proizvodi");
    if (state.proizvodi?.[kontaktProizvod.id].length > 1 && stavkaNaplate?.uplatilac?.sifra === EnumUplatilacNaplata.PARTNER) {
      const otherIndex = index === 0 ? 1 : 0;
      updateStavkaValue("iznos", kontaktProizvod.id, calculateCenaForProizvod(otherIndex, state?.proizvodi?.[kontaktProizvod.id], cena), otherIndex, "proizvodi");
      updateStavkaValue("iznosBezPacijentPopusta", kontaktProizvod.id, calculateCenaForProizvod(otherIndex, state.proizvodi[kontaktProizvod.id], cena, true), otherIndex, "proizvodi");
    }
  }, [stavkaNaplate.kategorijaPopusta, stavkaNaplate.placeniProcenat]);

  useEffectWithoutWarnings(() => {
    updateStavkaValue("iznos", kontaktProizvod.id, calculateCenaForProizvod(index, state?.proizvodi?.[kontaktProizvod.id], cena), index, "proizvodi");
    updateStavkaValue("iznosBezPacijentPopusta", kontaktProizvod.id, calculateCenaForProizvod(index, state.proizvodi[kontaktProizvod.id], cena, true), index, "proizvodi");
    updateStavkaValue("proizvodCena", kontaktProizvod.id, cena, index, "proizvodi");
  }, [cena]);

  const onChangeFixPrice = (e: InputNumberValueChangeEvent, index: number) => {
    let value = e.value ?? 0;
    const procenat = value / (stavkaNaplate.iznosBezPacijentPopusta / stavkaNaplate.placeniProcenat);
    updateStavkaValue("placeniProcenat", kontaktProizvod.id, procenat, index, "proizvodi");
    autoChangeOtherPercentages(index, procenat);
  };

  const autoChangeOtherPercentages = (index: number, procenat: number) => {
    if (state.proizvodi?.[kontaktProizvod.id].length > 1) {
      const otherIndex = index === 0 ? 1 : 0;
      updateStavkaValue("placeniProcenat", kontaktProizvod.id, 100 - procenat, otherIndex, "proizvodi");
    }
  };

  const handlePlacaProcenatChange = (e: InputNumberChangeEvent) => {
    let value = e.value ?? 0;
    if (value > 100) value = 100;
    updateStavkaValue("placeniProcenat", kontaktProizvod.id, value, index, "proizvodi");
    autoChangeOtherPercentages(index, value);
  };

  return (
    <div key={index} className="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
      <div className="grid align-items-center p-0 pt-2">
        <div className="col-12 px-0 sm:col-4">
          <div>{Labels.LABEL_UPLATILAC_NAPLATA}</div>
        </div>
        <div className="col-12 sm:col-8 p-fluid p-0">
          <Dropdown
            options={uplatilacList.filter(
              (uplatilac) =>
                !state.proizvodi?.[kontaktProizvod.id].some((stNaplate) => uplatilac.sifra === stNaplate?.uplatilac?.sifra) ||
                state.proizvodi?.[kontaktProizvod.id]?.[index]?.uplatilac?.sifra === uplatilac?.sifra
            )}
            optionLabel="naziv"
            value={stavkaNaplate.uplatilac}
            onChange={(e) => {
              updateStavkaValue("uplatilac", kontaktProizvod.id, e.target.value, index, "proizvodi");
              updateStavkaValue("partner", kontaktProizvod.id, undefined, index, "proizvodi");
              setSelectedPopust(undefined);
            }}
            emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
            className="w-full"
          />
        </div>
      </div>
      {stavkaNaplate.uplatilac?.sifra === EnumUplatilacNaplata.PARTNER && (
        <div className="grid align-items-center p-0 pt-2">
          <div className="col-12 px-0 sm:col-4">
            <div>{Labels.PARTNER}</div>
          </div>
          <div className="col-12 sm:col-8 p-fluid p-0">
            <Dropdown
              options={
                partnerStavkaNaplate && partnerStavkaNaplate !== stavkaNaplate ? partnerListForProizvod.filter((partner) => partner.id === partnerStavkaNaplate?.partner?.id) : partnerListForProizvod
              }
              optionLabel="naziv"
              value={stavkaNaplate.partner}
              onChange={(e) => {
                dispatch({ type: "update_partner", data: { itemId: kontaktProizvod.id, value: e.target.value, index, itemsType: "proizvodi" } });
              }}
              emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              className="w-full"
            />
          </div>
        </div>
      )}
      <div className="grid align-items-center p-0 pt-2">
        <div className="col-12 px-0 sm:col-4">
          <div>{Labels.LABEL_PLACA_NAPLATA}</div>
        </div>
        <div className="col-12 sm:col-8 p-fluid p-0 flex justify-content-between align-items-center">
          <InputNumber
            value={stavkaNaplate.placeniProcenat}
            onChange={handlePlacaProcenatChange}
            showButtons
            buttonLayout="horizontal"
            inputClassName="border-noround-right"
            decrementButtonClassName="hidden"
            incrementButtonClassName="p-button-primary pointer-events-none"
            incrementButtonIcon="pi pi-percentage"
            useGrouping={false}
            min={0}
            max={100}
            disabled={stavkaNaplate?.uplatilac?.sifra === EnumUplatilacNaplata.PARTNER && !stavkaNaplate?.partner}
          />
          <span className="text-xl pr-2 pl-2">=</span>
          {cenaIsLoading ? (
            <SkeletonInputItem height="2.5rem" />
          ) : (
            <InputNumber
              value={stavkaNaplate.iznosBezPacijentPopusta}
              onValueChange={(e) => {
                onChangeFixPrice(e, index);
                setInvalidInputs((prev) => ({ ...prev, invalidSumPrices: invalidInputs.proizvodi.invalidSumPrices.filter((item: any) => item !== kontaktProizvod.id) }));
              }}
              min={0}
              max={stavkaNaplate.iznosBezPacijentPopusta / (stavkaNaplate.placeniProcenat / 100)}
              mode="decimal"
              locale="sr-RS"
              minFractionDigits={2}
              maxFractionDigits={6}
              suffix={` ${finalnaValuta}`}
              disabled={stavkaNaplate?.uplatilac?.sifra === EnumUplatilacNaplata.PARTNER && !stavkaNaplate?.partner}
            />
          )}
        </div>
      </div>
      <div className="grid align-items-center p-0 pt-2">
        <div className="col-12 px-0 sm:col-4">
          <div>{Labels.LABEL_POPUST_NAPLATA}</div>
        </div>
        <div className="col-12 sm:col-8 p-fluid p-0">
          <Dropdown
            options={
              stavkaNaplate?.uplatilac?.sifra === EnumUplatilacNaplata.PACIJENT
                ? kategorijePopustaList?.filter((kategorija) => kategorija.namenaKategorijePopusta.sifra === EnumNamenaKategorijePopusta.SVI)
                : kategorijePopustaList
            }
            dataKey="id"
            optionLabel="naziv"
            itemTemplate={(option: SifarnikKategorijaPopustaReadDto) =>
              `${option.sifra} - ${option.naziv} (${option.popust} ${option.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.PROCENAT ? `%` : option?.valuta?.sifra})`
            }
            valueTemplate={(option, props) => {
              return option ? (
                `${option.sifra} - ${option.naziv} (${option.popust} ${option.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.PROCENAT ? `%` : option?.valuta?.sifra})`
              ) : (
                <span>{props.placeholder}</span>
              );
            }}
            placeholder={Labels.LABEL_BEZ_POPUSTA_NAPLATA}
            value={stavkaNaplate.kategorijaPopusta}
            onChange={(e) => {
              setSelectedPopust(e.target.value);
            }}
            emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
            className="w-full"
            showClear
            disabled={stavkaNaplate?.uplatilac?.sifra === EnumUplatilacNaplata.PARTNER && !stavkaNaplate?.partner}
          />
        </div>
      </div>
      <div className="grid align-items-center p-0 pt-2">
        <div className="flex align-self-start col-12 px-0 sm:col-4">
          <div>{Labels.LABEL_NAPOMENA_NAPLATA}</div>
        </div>
        <div className="col-12 sm:col-8 p-fluid p-0 pt-2">
          <InputTextarea
            rows={5}
            cols={30}
            value={stavkaNaplate.napomena}
            onChange={(e) => {
              updateStavkaValue("napomena", kontaktProizvod.id, e.target.value, index, "proizvodi");
            }}
            autoResize
          />
        </div>
      </div>
      <div className="grid align-items-center p-0 pt-2">
        <div className="flex align-self-start col-12 px-0 sm:col-4">{Labels.LABEL_CENA_SA_POPUSTIMA}</div>
        <div className="col-12 sm:col-8 p-fluid p-0 pt-2 flex align-items-center justify-content-between">
          <div>
            {cenaIsLoading ? (
              <Skeleton width="10rem" height="2rem" />
            ) : (
              <div className="text-xl">
                {formatPrice(stavkaNaplate.iznos)} {finalnaValuta}
              </div>
            )}
          </div>
          <div className="flex flex-row-reverse align-items-center">
            <Button
              label={Labels.LABEL_OBRISI_UPLATIOCA}
              onClick={() => {
                dispatch({ type: "delete_stavka_naplate", data: { itemsType: "proizvodi", itemId: kontaktProizvod.id, index } });
              }}
              icon="pi pi-trash"
              iconPos="right"
              className="p-button-secondary p-button-text"
              disabled={state.proizvodi?.[kontaktProizvod.id].length <= 1}
            />
          </div>
        </div>
      </div>
      {index !== state.proizvodi?.[kontaktProizvod.id].length - 1 && <Divider className="divider" />}
    </div>
  );
};

export default StavkaNaplateProizvod;
