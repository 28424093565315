import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../controllers/enum/EnumController";
import QueryKeys from "../../QueryKeys";

export function useZakazanTerminTipList() {
  const { axiosGetTipZakazanogTerminaList } = EnumController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.ZAKAZAN_TERMIN_TIP_LIST],
    queryFn: () => axiosGetTipZakazanogTerminaList(),
  });

  const zakazanTerminTipList = data?.data?.data ?? [];

  return { zakazanTerminTipList, isLoading };
}
