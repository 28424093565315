import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../Store";
import { KontaktController } from "../../../../controllers/kontakt/KontaktController";
import Endpoint from "../../../../infrastructure/system/Endpoint";
import { handleAxiosCallError, skeletonTimeout } from "../../../../infrastructure/system/Utils";
import useFetch from "../../../../infrastructure/system/hooks/useFetch";
import KontaktDosijeSimpleDto from "../../../../model/kontakt/KontaktDosijeSimpleDto";
import KontaktFilterData from "../../../../model/kontakt/KontaktFilterData";
import KontaktSearchDto from "../../../../model/kontakt/KontaktSearchDto";

interface PregledKontakataLogicalType {
  activeIndex: number | undefined;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  kontaktSearchParams: KontaktSearchDto;
  setKontaktSearchParams: React.Dispatch<React.SetStateAction<KontaktSearchDto>>;
  search: () => void;
  pregledKontakataDataLoading: boolean;
  filterData: KontaktFilterData | undefined;
  kontaktListLoading: boolean;
  setKontaktListLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PregledKontakataLogical(pacijentID: number, setKontaktList: React.Dispatch<React.SetStateAction<KontaktDosijeSimpleDto[]>>): PregledKontakataLogicalType {
  const { axiosKontaktSearchSimple } = KontaktController();
  const { showMessage } = useContext(AppContext);
  const [activeIndex, setActiveIndex] = useState<number>();
  const [kontaktSearchParams, setKontaktSearchParams] = useState<KontaktSearchDto>({ pacijentID: pacijentID });
  const [pregledKontakataDataLoading, setPregledKontakataDataLoading] = useState<boolean>(true);
  const [kontaktListLoading, setKontaktListLoading] = useState(false);

  const { data: filterData } = useFetch<KontaktFilterData>(`${Endpoint.KONTAKT_LIST}/kontakt-filter-list`, { pacijentID: pacijentID });

  useEffect(() => {
    const startTime = moment(new Date());
    if (filterData) skeletonTimeout(setPregledKontakataDataLoading, startTime);
  }, [filterData]);

  const search = () => {
    setKontaktListLoading(true);
    const startTime = moment(new Date());
    axiosKontaktSearchSimple(kontaktSearchParams)
      .then((responseKontaktList: any) => {
        setKontaktList(responseKontaktList.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setKontaktListLoading, startTime);
      });
  };

  return {
    activeIndex,
    setActiveIndex,
    kontaktSearchParams,
    setKontaktSearchParams,
    search,
    pregledKontakataDataLoading,
    filterData,
    kontaktListLoading,
    setKontaktListLoading,
  };
}
