import { terminStatusFieldType } from "../../../../infrastructure/system/Utils";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";

interface ZakazanTerminStatusiType {
  termin?: EnumBaseReadDto;
}

export default function ZakazanTerminStatusi(props: ZakazanTerminStatusiType) {
  const { termin } = props;
  return (
    <div className={`hc-scheduler`}>
      <span className={`circle circle-color--${terminStatusFieldType[(termin?.id ?? (0 as number)) - 1]}`} />
      {termin?.naziv}
    </div>
  );
}
