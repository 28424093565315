import { useLabels } from "../../../../Store";
import ObavestenjeReadDto from "../../../../model/obavestenje/ObavestenjeReadDto";
import SkeletonRectangles from "../../../app/skeleton/SkeletonRectangles";
import DialogComponent from "../../../dialog-component/DialogComponent";
import ObavestenjeDatotekaListPreview from "../ObavestenjeDatotekaListPreview";
import DialogObavestenjeLogical from "./DialogObavestenjeLogical";

interface DialogObavestenjeType {
  obavestenje: ObavestenjeReadDto;
  closeDialogObavestenje: () => void;
}

export default function DialogObavestenje(props: DialogObavestenjeType) {
  const Labels = useLabels();

  const { obavestenje, closeDialogObavestenje } = props;

  const { renderDateTimeFormatFull, renderUloge, renderOrganizacioneJedinice, datotekaList, obavestenjeDetails, setObavestenjeDetails, obavestenjeDetailsLoading, handleDatotekaClick } =
    DialogObavestenjeLogical({
      obavestenje,
    });

  const renderFooter = () => {
    return <div className="flex justify-content-between"></div>;
  };

  const closeDialog = () => {
    setObavestenjeDetails(undefined);
    closeDialogObavestenje();
  };

  return (
    <DialogComponent
      header={obavestenje?.naslov ?? ""}
      visible={obavestenje !== undefined}
      footer={renderFooter}
      onHide={closeDialog}
      blockScroll={true}
      //className={"w-11"}
      content={
        <div className="grid">
          <div className="col-3 font-bold">
            <div>{Labels.OBAVESTENJE_SADRZAJ}</div>
          </div>
          <div className="col-8">
            <div>{obavestenje?.sadrzaj ?? ""}</div>
          </div>
          {obavestenje?.vremeVazenja && <div className="col-3 font-bold">{Labels.OBAVESTENJE_VAZI_DO}</div>}
          {obavestenje?.vremeVazenja && <div className="col-8">{renderDateTimeFormatFull(obavestenje.vremeVazenja)}</div>}
          <div className="col-3 font-bold">
            <div>{Labels.ULOGA_LIST}</div>
          </div>
          <div className="col-8 display-linebreak">
            <div>{obavestenjeDetailsLoading ? <SkeletonRectangles /> : renderUloge(obavestenjeDetails?.obavestenjeUlogaList)}</div>
          </div>
          <div className="col-3 font-bold">
            <div>{Labels.ORGANIZACIONA_JEDINICA_LIST}</div>
          </div>
          <div className="col-8 display-linebreak">
            <div>{obavestenjeDetailsLoading ? <SkeletonRectangles /> : renderOrganizacioneJedinice(obavestenjeDetails?.obavestenjeOrganizacionaJedinicaList)}</div>
          </div>
          <div className="col-3 font-bold">
            <div>{Labels.DATOTEKE}</div>
          </div>
          <div className="col-8">
            <ObavestenjeDatotekaListPreview datotekaList={datotekaList} handleDatotekaClick={handleDatotekaClick} />
          </div>
        </div>
      }
    />
  );
}
