import { useContext, useReducer, useRef, useState } from "react";
import { useNavigate } from "react-router";
import SockJS from "sockjs-client";
import { Client, Message, Subscription, over } from "stompjs";
import { AppContext, useLabels } from "../../../../Store";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import Endpoint from "../../../../infrastructure/system/Endpoint";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../../infrastructure/system/Utils";
import actions from "../../../../infrastructure/system/hooks/dialog-brisanje-ustanove-reducer/actions";
import initialState, { InitialStateBrisanjeUstanoveType } from "../../../../infrastructure/system/hooks/dialog-brisanje-ustanove-reducer/initialStateBrisanjeUstanove";
import reducerBrisanjeUstanova from "../../../../infrastructure/system/hooks/dialog-brisanje-ustanove-reducer/reducerBrisanjeUstanova";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import WebSocketPayload from "../../../../model/WebSocketPayload";
import UstanovaEntityCountReadDto from "../../../../model/ustanova/UstanovaEntityCountReadDto";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";

interface UstanovaBrisanjeLogicalProps {
  ustanova?: UstanovaReadDto;
  ustanovaId?: number;
}

interface UstanovaBrisanjeLogicalType {
  ustanovaEntityCountList: UstanovaEntityCountReadDto | undefined;
  openDeleteDialog: () => void;
  closeDeleteDialog: () => void;
  state: InitialStateBrisanjeUstanoveType;
  dispatch: React.Dispatch<{ type: actions }>;
  onDelete: () => void;
  brisanjeList: Array<EntityBeingDeleted>;
}

interface EntityBeingDeleted {
  id: number;
  message: string;
  finished: boolean;
}

export default function UstanovaBrisanjeLogical(props: UstanovaBrisanjeLogicalProps): UstanovaBrisanjeLogicalType {
  const { ustanovaId, ustanova } = props;
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [ustanovaEntityCountList, setUstanovaEntityCountList] = useState<UstanovaEntityCountReadDto>();
  const [brisanjeList, setBrisanjeList] = useState<Array<EntityBeingDeleted>>([]);
  const [state, dispatch] = useReducer(reducerBrisanjeUstanova, initialState);
  const navigate = useNavigate();

  const { axiosSuperadminGetUstanovaEntityCount, axiosSuperadminHardDeleteUstanova } = SuperadminController();

  let stompClient = useRef<Client>();
  let subscription = useRef<Subscription>();
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchData();
    connect();
    return () => {
      if (stompClient.current?.connected) {
        stompClient.current?.disconnect(() => {});
      }
    };
  });

  const fetchData = () => {
    if (ustanovaId !== undefined) {
      axiosSuperadminGetUstanovaEntityCount(ustanovaId)
        .then((res: any) => {
          setUstanovaEntityCountList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const connect = () => {
    let sock = new SockJS(Endpoint.HARD_DELETE_WEBSOCKET);
    stompClient.current = over(sock);
    stompClient.current.connect({}, onConnected, onError);
  };

  const onError = () => {
    showMessage(MessageType.ERROR, Labels.WEBSOCKET_CONNECTION_ERROR);
  };

  const onConnected = () => {
    subscription.current = stompClient.current!.subscribe(Endpoint.HARD_DELETE_LISTENER, onMessageReceived);
  };

  const onMessageReceived = (message: Message) => {
    let payload: WebSocketPayload = JSON.parse(message.body);

    if (payload.poruka === Labels.WEBSOCKET_CLOSE_CONNECTION) {
      subscription.current!.unsubscribe();
      stompClient.current!.disconnect(() => {});
      return;
    }
    if (payload.enumWebSocketPorukaTip === "INFO_MSG") {
      setBrisanjeList((prevBrisanjeList) => {
        //Ako je slucajno SUCCESS poruka dosla pre INFO poruke
        if (prevBrisanjeList.some((el) => el.id === payload.payload)) {
          return prevBrisanjeList.map((entity) => {
            if (entity.id === payload?.payload) {
              return { id: entity.id, message: payload.poruka, finished: true };
            }
            return entity;
          });
        } else {
          return [...prevBrisanjeList, { id: payload.payload, message: payload.poruka, finished: false }];
        }
      });
    } else if (payload.enumWebSocketPorukaTip === "SUCCESS_MSG") {
      setBrisanjeList((prevBrisanjeList) => {
        //Ako je slucajno SUCCESS poruka dosla pre INFO poruke
        if (prevBrisanjeList.every((el) => el.id !== payload.payload)) {
          return [...prevBrisanjeList, { id: payload.payload, message: payload.poruka, finished: false }];
        } else {
          return prevBrisanjeList.map((entity) => {
            if (entity.id === payload?.payload) {
              return { id: entity.id, message: entity.message, finished: true };
            }
            return entity;
          });
        }
      });
    }
  };

  const openDeleteDialog = () => {
    dispatch({ type: actions.OPEN_DIALOG });
  };

  const closeDeleteDialog = () => {
    dispatch({ type: actions.CLOSE_DIALOG });
  };

  const onDelete = () => {
    dispatch({ type: actions.START_DELETE });
    closeDeleteDialog();
    if (ustanovaId !== undefined) {
      if (!stompClient.current?.connected) {
        connect();
      }
      axiosSuperadminHardDeleteUstanova(ustanovaId)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.USTANOVA_HARD_DELETE_TITLE_MESSAGE_SUCCESS);
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_SUPERADMIN_1 +
              ustanova?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_1 +
              ustanova?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_2 +
              ustanova?.nazivKratki +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_3 +
              ustanova?.jezik.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_4 +
              ustanova?.svojinaTip.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_5 +
              ustanova?.nivoZdravstveneZastite.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_6 +
              ustanova?.podrazumevanaStrana.naziv +
              "."
          );
          navigate("/ustanova-list");
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  return {
    ustanovaEntityCountList,
    openDeleteDialog,
    closeDeleteDialog,
    state,
    dispatch,
    onDelete,
    brisanjeList,
  };
}
