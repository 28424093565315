import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { useLabels } from "../../../../Store";
import UstanovaNadjimedLogical from "./UstanovaNadjimedLogical";

interface UstanovaViewType {
  ustanovaId?: number;
  isDisabled: boolean;
}

export default function UstanovaNadjimedView(props: UstanovaViewType) {
  const { ustanovaId, isDisabled } = props;
  const Labels = useLabels();
  const {
    inicijalizuj,
    sinhronizujCenovnikeUsluga,
    sinhronizujDoktore,
    sinhronizujOrganizacioneJedinice,
    doktoriSyncInProgress,
    organizacioneJedinceSyncInProgress,
    cenovnikUslugaSyncInProgress,
    inicijalizacijaInProgress,
  } = UstanovaNadjimedLogical({ ustanovaId: ustanovaId });

  return (
    <div className="previewItems flex flex-column hc-gap-3">
      <div className="flex flex-row justify-content-start align-items-center p-0">
        <div className="col-2">{Labels.USTANOVA_NADJIMED_LABEL_INICIJALIZACIJA}</div>
        <div className="col-10">
          <Button label={Labels.USTANOVA_INICIJALIZUJ} className="w-8rem" onClick={inicijalizuj} disabled={isDisabled || inicijalizacijaInProgress} />
          {inicijalizacijaInProgress && <ProgressSpinner className="w-2rem h-2rem ml-3" strokeWidth="4" animationDuration="2s" />}
        </div>
      </div>
      <div className="flex flex-row justify-content-between align-items-center p-0">
        <div className="col-2">{Labels.USTANOVA_NADJIMED_LABEL_CENOVNICI_USLUGA}</div>
        <div className="col-10">
          <Button label={Labels.USTANOVA_SINHRONIZUJ} className="w-8rem" onClick={sinhronizujCenovnikeUsluga} disabled={isDisabled || cenovnikUslugaSyncInProgress} />
          {cenovnikUslugaSyncInProgress && <ProgressSpinner className="w-2rem h-2rem ml-3" strokeWidth="4" animationDuration="2s" />}
        </div>
      </div>
      <div className="flex flex-row justify-content-between align-items-center p-0">
        <div className="col-2">{Labels.USTANOVA_NADJIMED_LABEL_ORGANIZACIONE_JEDINICE}</div>
        <div className="col-10">
          <Button label={Labels.USTANOVA_SINHRONIZUJ} className="w-8rem" onClick={sinhronizujOrganizacioneJedinice} disabled={isDisabled || organizacioneJedinceSyncInProgress} />
          {organizacioneJedinceSyncInProgress && <ProgressSpinner className="w-2rem h-2rem ml-3" strokeWidth="4" animationDuration="2s" />}
        </div>
      </div>
      <div className="flex flex-row justify-content-between align-items-center p-0">
        <div className="col-2">{Labels.USTANOVA_NADJIMED_LABEL_DOKTORI}</div>
        <div className="col-10">
          <Button label={Labels.USTANOVA_SINHRONIZUJ} className="w-8rem" onClick={sinhronizujDoktore} disabled={isDisabled || doktoriSyncInProgress} />
          {doktoriSyncInProgress && <ProgressSpinner className="w-2rem h-2rem ml-3" strokeWidth="4" animationDuration="2s" />}
        </div>
      </div>
    </div>
  );
}
