import { Avatar } from "primereact/avatar";
import EnumPorukaStatus from "../../../infrastructure/system/EnumPorukaStatus";
import { DATE_TIME_FORMAT_5, formatDate2, getImage, stripHTML } from "../../../infrastructure/system/Utils";
import PorukaRadnikReadDto from "../../../model/poruke/PorukaRadnikReadDto";

interface PrimljenaPorukaListItemProps {
  primljenaPoruka: PorukaRadnikReadDto;
  onClick: () => void;
  izabrana: boolean;
}

export default function PrimljenaPorukaListItem({ primljenaPoruka, onClick, izabrana }: PrimljenaPorukaListItemProps) {
  return (
    <div className={`flex pr-2 min-w-0 align-items-start hc-gap-3 cursor-pointer border-box hover:surface-200 ${izabrana && "izabrana"}`} onClick={onClick}>
      <div className="flex pl-4 relative align-items-center justify-content-center mt-3 hc-gap-2">
        <div className={`absolute left-0 unread-circle ${primljenaPoruka.porukaStatus.sifra === EnumPorukaStatus.NEPROCITANA && "bg-primary"}`}></div>
        {primljenaPoruka.poruka.radnikKreirao.profilnaSlika ? (
          <Avatar
            className="avatar"
            image={getImage(false, primljenaPoruka.poruka.radnikKreirao.profilnaSlika!, undefined)}
            imageAlt={`${primljenaPoruka.poruka.radnikKreirao?.ime.charAt(0)}${primljenaPoruka.poruka.radnikKreirao?.prezime.charAt(0)}`}
            size="large"
            shape="circle"
          />
        ) : (
          <Avatar
            className="avatarBorder avatar"
            label={`${primljenaPoruka.poruka.radnikKreirao?.ime.charAt(0)}${primljenaPoruka.poruka.radnikKreirao?.prezime.charAt(0)}`}
            size="large"
            shape="circle"
          />
        )}
      </div>
      <div className="border-bottom-1 py-3 min-w-0 w-full pr-2 border-300">
        <div className="flex justify-content-between align-items-center">
          <p className="font-bold text-lg mb-0 min-w-0 white-space-nowrap overflow-hidden text-overflow-ellipsis">{`${primljenaPoruka.poruka.radnikKreirao.titula ?? ""} ${
            primljenaPoruka.poruka.radnikKreirao.ime
          } ${primljenaPoruka.poruka.radnikKreirao.prezime}`}</p>
          <p className={`text-sm mb-0 white-space-nowrap text-600 ${primljenaPoruka.porukaStatus.sifra === EnumPorukaStatus.NEPROCITANA ? "font-bold" : "text-600"}`}>
            {formatDate2(primljenaPoruka.poruka.vremeKreiranja, DATE_TIME_FORMAT_5)}
          </p>
        </div>
        <p className="mb-0 font-semibold white-space-nowrap overflow-hidden text-overflow-ellipsis">{primljenaPoruka.poruka.naslov}</p>
        <p className="mb-0 font-semibold text-600 min-w-0 white-space-nowrap overflow-hidden text-overflow-ellipsis">{stripHTML(primljenaPoruka.poruka.sadrzaj)}</p>
      </div>
    </div>
  );
}
