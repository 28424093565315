import { useQuery } from "@tanstack/react-query";
import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import QueryKeys from "../../QueryKeys";

export function useOrganizacionaJedinicaKontaktTipList(organizacionaJedinicaID?: number) {
  const { axiosGetOrganizacionaJedinicaKontaktTipList } = OrganizacionaJedinicaController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.ORGANIZACIONA_JEDINICA_LIST, organizacionaJedinicaID, QueryKeys.KONTAKT_TIP_LIST],
    queryFn: () => axiosGetOrganizacionaJedinicaKontaktTipList(organizacionaJedinicaID!),
    enabled: !!organizacionaJedinicaID,
  });

  const organizacionaJedinicaKontaktTipList = data?.data?.data ?? [];

  return { organizacionaJedinicaKontaktTipList, isLoading };
}
