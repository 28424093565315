import OrganizacionaJedinicaReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import UstanovaGridUpdateDto from "../../../model/ustanova/UstanovaGridUpdateDto";
import UstanovaReadDto from "../../../model/ustanova/UstanovaReadDto";
import RadnikInicijalizacijaReadDto from "../../../model/inicijalizacija/RadnikInicijalizacijaReadDto";
import SifarnikUslugaReadDto from "../../../model/sifarnik-usluga/SifarnikUslugaReadDto";
import KontaktTipRepositoryDto from "../../../model/kontakt/KontaktTipRepositoryDto";

export interface InitialStateType {
  step?: number;
  ustanovaChange: UstanovaReadDto | undefined;
  ustanovaReadOnly: UstanovaReadDto | undefined;
  ustanovaGridChange: UstanovaGridUpdateDto | undefined;
  ustanovaGridReadOnly: UstanovaGridUpdateDto | undefined;
  orgJedinicaChange?: OrganizacionaJedinicaReadDto;
  orgJedinicaIsEditing: boolean;
  orgJedinicaListCount?: number;
  radnikChange: RadnikInicijalizacijaReadDto | undefined;
  isRadnikEditing: boolean;
  uslugaChange: SifarnikUslugaReadDto | undefined;
  isUslugaEditing: boolean;
  tipoviPregleda: KontaktTipRepositoryDto[];
  selectAllTipoviPregleda: boolean;
  showFinishingInitializationProcessOverlay: boolean;
  isFinishingInitializationProcess: boolean;
  tipoviPregledaCount: number;
  areTipoviPregledaChanged: { [key: number]: boolean } | undefined;
}

const initialState: InitialStateType = {
  step: undefined,
  ustanovaChange: undefined,
  ustanovaReadOnly: undefined,
  ustanovaGridChange: undefined,
  ustanovaGridReadOnly: undefined,
  orgJedinicaChange: undefined,
  orgJedinicaIsEditing: false,
  orgJedinicaListCount: undefined,
  radnikChange: undefined,
  isRadnikEditing: false,
  uslugaChange: undefined,
  isUslugaEditing: false,
  tipoviPregleda: [],
  selectAllTipoviPregleda: false,
  showFinishingInitializationProcessOverlay: false,
  isFinishingInitializationProcess: false,
  tipoviPregledaCount: 0,
  areTipoviPregledaChanged: undefined,
};

export default initialState;
