import { useQuery } from "@tanstack/react-query";
import { SifarnikKategorijaPopustaController } from "../../../../controllers/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";
import SifarnikKategorijaPopustaReadDto from "../../../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaReadDto";

export function useSifarnikKategorijaPopustaItem(id?: number) {
  const { axiosGetSifarnikKategorijaPopusta } = SifarnikKategorijaPopustaController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.SIFARNIK_KATEGORIJA_POPUSTA, id],
    queryFn: () => (id ? axiosGetSifarnikKategorijaPopusta(id) : null),
    enabled: !!id,
  });

  const sifarnikKategorijaPopusta: SifarnikKategorijaPopustaReadDto = data?.data?.data;

  return { sifarnikKategorijaPopusta, refetch, isLoading };
}
