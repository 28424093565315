import { Button } from "primereact/button";
import { confirmPopup } from "primereact/confirmpopup";
import { TooltipPassThroughOptions } from "primereact/tooltip";
import { useRef } from "react";
import { useLabels } from "../../Store";

interface ConfirmComponentProps {
  buttonLabel?: string;
  buttonClassName?: string;
  buttonIcon?: string;
  confirmTitle: string;
  confirmIcon?: string;
  disabled?: boolean;
  tooltip?: string;
  tooltipPosition?: TooltipPassThroughOptions | any;
  acceptClassName?: string;
  rejectClassName?: string;
  onAccept: () => void;
  onReject?: () => void;
}

export default function ConfirmComponent(props: ConfirmComponentProps) {
  const {
    buttonLabel,
    buttonClassName,
    confirmTitle,
    disabled,
    buttonIcon,
    confirmIcon = "pi pi-exclamation-triangle",
    onAccept,
    tooltip,
    tooltipPosition,
    acceptClassName,
    rejectClassName,
    onReject,
  } = props;
  const Labels = useLabels();
  const ref = useRef<any>();

  const confirm = (event: any) => {
    confirmPopup({
      target: event.currentTarget,
      message: confirmTitle,
      icon: confirmIcon,
      acceptLabel: Labels.BUTTON_YES,
      rejectLabel: Labels.BUTTON_NO,
      acceptClassName: acceptClassName,
      rejectClassName: rejectClassName,
      accept: onAccept,
      reject: onReject,
    });
  };

  return (
    <Button
      ref={ref}
      disabled={disabled ?? false}
      label={buttonLabel ?? ""}
      tooltipOptions={tooltipPosition ?? undefined}
      icon={buttonIcon ?? ""}
      tooltip={tooltip ?? ""}
      className={buttonClassName ?? ""}
      onClick={confirm}
    />
  );
}
