import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { ObavestenjeController } from "../../../controllers/obavestenje/ObavestenjeController";
import { DATE_TIME_FORMAT, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import ObavestenjeReadDto from "../../../model//obavestenje/ObavestenjeReadDto";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";

interface ObavestenjeListLogicalType {
  obavestenjeList: Array<ObavestenjeReadDto>;
  first: number;
  tableRows: number;
  selectedObavestenje: ObavestenjeReadDto | undefined;
  setSelectedObavestenje: React.Dispatch<React.SetStateAction<ObavestenjeReadDto | undefined>>;
  changeObavestenjeSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  obavestenjeSearchData: SearchBaseDto;
  fetchData: () => void;
  searchObavestenjeByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  dialogRef: any;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  onPageObavestenje: (rows: number, first: number) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  obavestenjeLoading: boolean;
  closeDialogObavestenje: () => void;
  renderDateTime: (date: Date) => string;
}

const renderDateTime = (date: Date) => (date ? moment(date).format(DATE_TIME_FORMAT) : "");

export default function ObavestenjeListLogical(): ObavestenjeListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [obavestenjeList, setObavestenjeList] = useState<Array<ObavestenjeReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [selectedObavestenje, setSelectedObavestenje] = useState<ObavestenjeReadDto>();
  const [obavestenjeSearchData, setObavestenjeSearchData] = useState<SearchBaseDto>({});
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const { axiosSearchObavestenje } = ObavestenjeController();
  const [obavestenjeLoading, setObavestenjeLoading] = useState<boolean>(false);

  const breadCrumbItems = [
    {
      label: Labels.OBAVESTENJE_LIST,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedObavestenje?: number) => {
    let startTime = moment(new Date());
    setObavestenjeLoading(true);
    axiosSearchObavestenje(obavestenjeSearchData)
      .then((res: any) => {
        let exportList = new Array<any>();
        res.data.data.forEach((obavestenje: ObavestenjeReadDto) => {
          exportList.push({ [Labels.LABEL_NAZIV]: obavestenje.naslov, [Labels.LABEL_SADRZAJ]: obavestenje.sadrzaj, [Labels.LABEL_VAZI_DO]: renderDateTime(obavestenje.vremeVazenja) });
        });
        setExportData(exportList);
        setObavestenjeList(res.data.data);
        if (idSelectedObavestenje) {
          res.data.data.forEach((obavestenje: ObavestenjeReadDto) => {
            if (idSelectedObavestenje === obavestenje.id) {
              setSelectedObavestenje(obavestenje);
            }
          });
        }
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setObavestenjeLoading, startTime);
      });
  };

  const changeObavestenjeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setObavestenjeSearchData({
      ...obavestenjeSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchObavestenjeByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageObavestenje = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const closeDialogObavestenje = () => {
    setSelectedObavestenje(undefined);
  };

  return {
    obavestenjeList,
    first,
    tableRows,
    selectedObavestenje,
    setSelectedObavestenje,
    changeObavestenjeSearchData,
    obavestenjeSearchData,
    fetchData,
    searchObavestenjeByEnter,
    dialogRef,
    index,
    setIndex,
    onPageObavestenje,
    exportData,
    breadCrumbItems,
    obavestenjeLoading,
    closeDialogObavestenje,
    renderDateTime,
  };
}
