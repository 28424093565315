import { useLabels } from "../../Store";

export default function PageForbiddenView() {
  const Labels = useLabels();

  return (
    <div className="flex flex-1 align-items-center justify-content-center">
      <div className="flex flex-column align-items-center">
        <p className="text-8xl font-bold">403</p>
        <p className="text-4xl font-semibold">{Labels.ERROR_FORBIDDEN_PAGE}</p>
      </div>
    </div>
  );
}
