export interface InitialStateBrisanjeUstanoveType {
  isDialogOpen: boolean;
  isCheckedBrisanje: boolean;
  page: number;
  brisanjeZapoceto: boolean;
}

const InitialStateBrisanjeUstanove: InitialStateBrisanjeUstanoveType = {
  isDialogOpen: false,
  isCheckedBrisanje: false,
  page: 0,
  brisanjeZapoceto: false,
};

export default InitialStateBrisanjeUstanove;
