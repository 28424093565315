import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Store";
import { PacijentController } from "../../../controllers/pacijent/PacijentController";
import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import { handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import FormularPopunjenSaPoljimaDto from "../../../model/formular/FormularPopunjenSaPoljimaDto";
import PoljePopunjenoSkracenoDto from "../../../model/polje/PoljePopunjenoSkracenoReadDto";
import { valueMap } from "../FormularLogical";

interface PrethodniNalaziListLogicalProp {
  formularId: number;
  formularPopunjenId?: number;
  pacijentId: number;
  changePoljaVrednosti: (poljaVrednosti: valueMap) => void;
}

interface PrethodniNalaziListLogicalType {
  dataLoading: boolean;
  activeIndex: number | number[];
  setActiveIndex: React.Dispatch<React.SetStateAction<number | number[]>>;
  formularPopunjenList: Array<FormularPopunjenSaPoljimaDto>;
  onChange: (poljaVrednosti: valueMap) => void;
  page: number;
  formularPopunjenVrednostList: Array<any>;
  noMoreData: boolean;
  moreDataLoading: boolean;
  loadMore: () => void;
}

export default function PrethodniNalaziListLogical(props: PrethodniNalaziListLogicalProp): PrethodniNalaziListLogicalType {
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [moreDataLoading, setMoreDataLoading] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | number[]>(0);
  const [formularPopunjenList, setFormularPopunjenList] = useState<Array<FormularPopunjenSaPoljimaDto>>([]);
  const { axiosPagedGetFormularPopunjenListByFormular } = PacijentController();
  const [page, setPage] = useState<number>(0);
  const { pacijentId, formularId, changePoljaVrednosti, formularPopunjenId }: PrethodniNalaziListLogicalProp = props;
  const [noMoreData, setNoMoreData] = useState<boolean>(false);
  const { showMessage } = useContext(AppContext);
  const [formularPopunjenVrednostList, setFormularPopunjenVrednostList] = useState<Array<any>>([]);
  const pageSize = 3;
  const onChange = (poljaVrednosti: valueMap) => {
    changePoljaVrednosti(poljaVrednosti);
  };
  const getPoljePopunjenoVrednost = (poljePopunjeno: PoljePopunjenoSkracenoDto) => {
    switch (poljePopunjeno.polje.poljeTip.tipKomponenteEnum.sifra) {
      case EnumTipKomponente.TEXT:
      case EnumTipKomponente.LONG_TEXT:
        return poljePopunjeno.vrednostString;
      case EnumTipKomponente.NUMBER_DECIMAL:
      case EnumTipKomponente.NUMBER_INTEGER:
        return poljePopunjeno.vrednostNumber;

      case EnumTipKomponente.BOOLEAN:
        return poljePopunjeno.vrednostBoolean;
      case EnumTipKomponente.DATE_TIME:
        return poljePopunjeno.vrednostDatetime;
      case EnumTipKomponente.SET:
      case EnumTipKomponente.SET_RADIO:
        return poljePopunjeno.vrednostEnumeratorItem.id;
    }
  };
  const fetchData = () => {
    if (!noMoreData) {
      if (page === 0) {
        setDataLoading(true);
      }
      axiosPagedGetFormularPopunjenListByFormular(pacijentId, formularId, page, pageSize)
        .then((result: any) => {
          let formularPopunjenListTemp = [...formularPopunjenList];
          formularPopunjenListTemp.push(...result.data.data.filter((element: FormularPopunjenSaPoljimaDto) => element.id !== formularPopunjenId));
          setFormularPopunjenList(formularPopunjenListTemp);
          if (result.data.data.length === pageSize) {
            setPage(page + 1);
          } else {
            setNoMoreData(true);
          }
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setDataLoading(false);
          setMoreDataLoading(false);
        })
        .finally(() => {});
    }
  };
  useEffect(() => {
    if (formularPopunjenList) {
      let formularPopunjenVrednostListTemp: Array<any> = [];
      formularPopunjenList.forEach((formularPopunjen: FormularPopunjenSaPoljimaDto, index: number) => {
        let poljaVrednostList: valueMap = {};
        formularPopunjen?.poljePopunjenoList?.forEach((poljePopunjeno: PoljePopunjenoSkracenoDto) => {
          poljaVrednostList[poljePopunjeno.polje.id] = getPoljePopunjenoVrednost(poljePopunjeno);
        });
        formularPopunjenVrednostListTemp[index] = poljaVrednostList;
      });
      setDataLoading(false);
      setMoreDataLoading(false);
      setFormularPopunjenVrednostList(formularPopunjenVrednostListTemp);
    }
  }, [formularPopunjenList]);
  useEffectOnce(() => {
    fetchData();
  });
  const loadMore = () => {
    setMoreDataLoading(true);
    fetchData();
  };
  return { dataLoading, activeIndex, setActiveIndex, formularPopunjenList, onChange, page, formularPopunjenVrednostList, noMoreData, moreDataLoading, loadMore };
}
