import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { useCallback, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useLabels } from "../../../Store";
import EnumPercentilnaKrivaUzrast from "../../../infrastructure/system/EnumPercentilnaKrivaUzrast";
import EnumVrstaGrafikonaRasta from "../../../infrastructure/system/EnumVrstaGrafikonaRasta";
import PacijentPoljePopunjeno from "../../../model/pacijent-polje/PacijentPoljePopunjeno";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SifarnikPercentilnaKrivaReadDto from "../../../model/sifarnik/SifarnikPercentilnaKrivaReadDto";
import DialogComponent from "../../dialog-component/DialogComponent";
import PercentileKrivaDialogLogical from "./PercentileKrivaDialogLogical";
import PercentilnaKrivaCanvas from "./PercentilnaKrivaCanvas";

interface PercentileKrivaDialogType {
  visible: boolean;
  onHide: () => void;
  vrstaGrafikona: string | undefined;
  pacijent?: PacijentReadDto;
  pacijentPoljeArhivaList: Array<any>;
  percentilnaKrivaList: SifarnikPercentilnaKrivaReadDto[];
  setPercentilnaKrivaList: React.Dispatch<React.SetStateAction<SifarnikPercentilnaKrivaReadDto[]>>;
  medicinskiPodaci: Array<PacijentPoljePopunjeno>;
}

const PercentileKrivaDialog = (props: PercentileKrivaDialogType) => {
  const { visible, onHide, vrstaGrafikona, pacijent, pacijentPoljeArhivaList, percentilnaKrivaList, setPercentilnaKrivaList, medicinskiPodaci } = props;
  const { graphOptions, selectedGraph, setSelectedGraph, uzrastList, selectedUzrast, setSelectedUzrast, fullPath, setFullPath, dotCoordinates, setClosingChange } = PercentileKrivaDialogLogical(
    vrstaGrafikona,
    pacijentPoljeArhivaList,
    percentilnaKrivaList,
    medicinskiPodaci,
    pacijent
  );
  const Labels = useLabels();
  const componentRef = useRef(null);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Percentilna kriva",
    removeAfterPrint: true,
    copyStyles: false,
    pageStyle: `@media print {
      body {
        padding: 10px
      }
    }`,
  });

  return (
    <DialogComponent
      className="lg:w-auto"
      header={
        <div className="flex align-items-center flex-column sm:flex-row">
          <div className="flex align-items-center">
            <div>
              <div className="text-base">{Labels.LABEL_PERCENTILNA_KRIVA_ODABERITE_UZRAST}</div>
            </div>
            <Dropdown
              value={selectedUzrast}
              options={uzrastList.filter((uzrast: EnumBaseReadDto) =>
                vrstaGrafikona === EnumVrstaGrafikonaRasta.TEZINA ? uzrast.sifra !== EnumPercentilnaKrivaUzrast.UZRAST_5_19 : uzrast.sifra !== EnumPercentilnaKrivaUzrast.UZRAST_5_10
              )}
              onChange={(e) => {
                setSelectedUzrast(e.value);
                setFullPath(undefined);
              }}
              className="ml-3"
              optionLabel="naziv"
            />
          </div>
          <div className="ml-3">
            <SelectButton
              value={selectedGraph}
              options={graphOptions}
              optionLabel="naziv"
              onChange={(e) => {
                setSelectedGraph(e.value);
                setFullPath(undefined);
              }}
              unselectable={false}
            />
          </div>
          <div>
            <Button icon="pi pi-print" className="p-button-outlined ml-3" onClick={handlePrint} />
          </div>
        </div>
      }
      visible={visible}
      onHide={() => {
        onHide();
        setSelectedUzrast(uzrastList[0]);
        setSelectedGraph(graphOptions[0]);
        setFullPath(undefined);
        setClosingChange((prev) => !prev);
        setPercentilnaKrivaList([]);
      }}
      withoutFooter
      content={
        <div className="text-center">
          <PercentilnaKrivaCanvas path={fullPath} dotCoordinates={dotCoordinates} pacijent={pacijent} ref={componentRef} />
        </div>
      }
    />
  );
};

export default PercentileKrivaDialog;
