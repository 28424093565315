import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { ChangeEvent, useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import DialogComponent from "../../dialog-component/DialogComponent";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CrudSifarnikNacinaPlacanjaLogical from "./CrudSifarnikNacinaPlacanjaLogical";

export default function CrudSifarnikNacinaPlacanjaView() {
  const {
    breadCrumbItems,
    sifarnikNacinPlacanjaChange,
    setSifarnikNacinPlacanjaChange,
    isDisabled,
    sifarnikNacinPlacanjaOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    sifarnikNacinPlacanjaLoading,
    invalidFields,
    setInvalidFields,
    vrstaUplatiocaList,
    showQuickPaymentDialog,
    hideQuickPaymentDialog,
    updateSifarnikNacinPlacanja,
    createSifarnikNacinPlacanja,
  } = CrudSifarnikNacinaPlacanjaLogical();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const leftContentsToolbar = () => (
    <>
      {sifarnikNacinPlacanjaOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.SIFARNIK_NACINA_PLACANJA_TITLE_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
          disabled={!pristup}
        />
      )}
      {sifarnikNacinPlacanjaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || sifarnikNacinPlacanjaLoading}
          icon="pi pi-pencil"
          tooltip={Labels.SIFARNIK_NACINA_PLACANJA_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {sifarnikNacinPlacanjaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || sifarnikNacinPlacanjaLoading}
          icon="pi pi-trash"
          tooltip={Labels.SIFARNIK_NACINA_PLACANJA_TITLE_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-content-center">
        <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-outlined p-button-tertiary" onClick={hideQuickPaymentDialog} />
        <Button
          label={Labels.BUTTON_CONFIRM}
          icon="pi pi-check"
          className="button-danger ml-4"
          onClick={sifarnikNacinPlacanjaOperation === EntityOperation.CREATE ? createSifarnikNacinPlacanja : updateSifarnikNacinPlacanja}
        />
      </div>
    );
  };

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
          <TabPanel header={Labels.LABEL_SIFARNIK_NACIN_PLACANJA}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_NACINA_PLACANJA_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikNacinPlacanjaLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={sifarnikNacinPlacanjaChange?.naziv ?? ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setSifarnikNacinPlacanjaChange({
                            ...sifarnikNacinPlacanjaChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", sifarnikNacinPlacanjaChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_NACINA_PLACANJA_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikNacinPlacanjaLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={sifarnikNacinPlacanjaChange?.sifra ?? ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setSifarnikNacinPlacanjaChange({
                            ...sifarnikNacinPlacanjaChange!,
                            sifra: e.target.value,
                          })
                        }
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", sifarnikNacinPlacanjaChange?.sifra);
                        }}
                        className={invalidFields?.sifra && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_NACINA_PLACANJA_PRIKAZNI_REDOSLED}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikNacinPlacanjaLoading ? (
                    <>
                      <InputNumber
                        value={sifarnikNacinPlacanjaChange?.prikazniRedosled ?? undefined}
                        disabled={!pristup || isDisabled}
                        onChange={(e) => {
                          setSifarnikNacinPlacanjaChange({
                            ...sifarnikNacinPlacanjaChange!,
                            prikazniRedosled: e.value!,
                          });
                        }}
                      />
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_NACINA_PLACANJA_VRSTA_UPLATIOCA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikNacinPlacanjaLoading ? (
                    <Dropdown
                      options={vrstaUplatiocaList}
                      disabled={isDisabled}
                      value={sifarnikNacinPlacanjaChange?.vrstaUplatioca ?? ""}
                      optionLabel="naziv"
                      onChange={(e) => {
                        setSifarnikNacinPlacanjaChange({
                          ...sifarnikNacinPlacanjaChange!,
                          vrstaUplatioca: e.value!,
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikNacinPlacanjaLoading ? (
                    <InputSwitch
                      disabled={isDisabled}
                      checked={sifarnikNacinPlacanjaChange?.brzoPlacanje ?? false}
                      onChange={(e) => {
                        setSifarnikNacinPlacanjaChange({
                          ...sifarnikNacinPlacanjaChange!,
                          brzoPlacanje: e.target.value!,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar start={leftContentsToolbar} end={rightContentsToolbar} />
          </TabPanel>
        </TabView>
      </Panel>

      <DialogComponent
        header={Labels.SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_DIALOG_HEADER}
        visible={showQuickPaymentDialog}
        onHide={hideQuickPaymentDialog}
        footer={renderFooter}
        className="w-4"
        content={
          <div>
            <div>
              {Labels.SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_DIALOG_CONTENT} {sifarnikNacinPlacanjaChange?.naziv}?
            </div>
          </div>
        }
      />
    </div>
  );
}
