import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { ChangeEvent, useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CalendarComponent from "../calendar-component/CalendarComponent";
import CrudSifarnikJedinicaMereLogical from "./CrudSifarnikJedinicaMereLogical";

export default function CrudSifarnikJedinicaMereView() {
  const {
    breadCrumbItems,
    sifarnikJedinicaMereChange,
    setSifarnikJedinicaMereChange,
    isDisabled,
    sifarnikJedinicaMereOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    sifarnikJedinicaMereLoading,
    invalidFields,
    setInvalidFields,
    datumOdRef,
    datumDoRef,
  } = CrudSifarnikJedinicaMereLogical();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const leftContentsToolbar = () => (
    <>
      {sifarnikJedinicaMereOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.SIFARNIK_JEDINICA_MERE_TITLE_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
          disabled={!pristup}
        />
      )}
      {sifarnikJedinicaMereOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || sifarnikJedinicaMereLoading}
          icon="pi pi-pencil"
          tooltip={Labels.SIFARNIK_JEDINICA_MERE_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {sifarnikJedinicaMereOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || sifarnikJedinicaMereLoading}
          icon="pi pi-trash"
          tooltip={Labels.SIFARNIK_JEDINICA_MERE_TITLE_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
          <TabPanel header={Labels.SIFARNIK_JEDINICA_MERE}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_JEDINICA_MERE_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikJedinicaMereLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={sifarnikJedinicaMereChange?.naziv ?? ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setSifarnikJedinicaMereChange({
                            ...sifarnikJedinicaMereChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", sifarnikJedinicaMereChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_JEDINICA_MERE_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikJedinicaMereLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={sifarnikJedinicaMereChange?.sifra ?? ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setSifarnikJedinicaMereChange({
                            ...sifarnikJedinicaMereChange!,
                            sifra: e.target.value,
                          })
                        }
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", sifarnikJedinicaMereChange?.sifra);
                        }}
                        className={invalidFields?.sifra && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_JEDINICA_MERE_AKRONIM}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikJedinicaMereLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={sifarnikJedinicaMereChange?.akronim ?? ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setSifarnikJedinicaMereChange({
                          ...sifarnikJedinicaMereChange!,
                          akronim: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.SIFARNIK_JEDINICA_MERE_VAZI_OD}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikJedinicaMereLoading ? (
                    <>
                      <CalendarComponent
                        ref={datumOdRef}
                        disabled={!pristup || isDisabled}
                        showButtonBar={false}
                        name="datumOd"
                        value={sifarnikJedinicaMereChange?.vaziOd}
                        onChange={(e: CalendarChangeEvent) => {
                          setSifarnikJedinicaMereChange({
                            ...sifarnikJedinicaMereChange!,
                            vaziOd: e.value as Date,
                          });
                          setInvalidFields((prev) => ({ ...prev, vaziOd: false }));
                          if (e.target.value) datumOdRef?.current?.hide();
                        }}
                        {...(sifarnikJedinicaMereChange?.vaziDo ? { maxDate: new Date(sifarnikJedinicaMereChange?.vaziDo) } : {})}
                        className={"w-full " + (invalidFields?.vaziOd && "p-invalid")}
                      />
                      {invalidFields?.vaziOd && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_JEDINICA_MERE_VAZI_DO}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikJedinicaMereLoading ? (
                    <CalendarComponent
                      ref={datumDoRef}
                      disabled={!pristup || isDisabled}
                      name="datumOd"
                      value={sifarnikJedinicaMereChange?.vaziDo}
                      onChange={(e: CalendarChangeEvent) => {
                        setSifarnikJedinicaMereChange({
                          ...sifarnikJedinicaMereChange!,
                          vaziDo: e.value as Date,
                        });
                        if (e.target.value) datumDoRef?.current?.hide();
                      }}
                      {...(sifarnikJedinicaMereChange?.vaziOd ? { minDate: new Date(sifarnikJedinicaMereChange?.vaziOd) } : {})}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.SIFARNIK_JEDINICA_MERE_OPIS}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!sifarnikJedinicaMereLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={sifarnikJedinicaMereChange?.opis ?? ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setSifarnikJedinicaMereChange({
                          ...sifarnikJedinicaMereChange!,
                          opis: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
