import EnumTipKategorijePopusta from "../../infrastructure/system/EnumTipKategorijePopusta";
import EnumUplatilacNaplata from "../../infrastructure/system/EnumUplatilacNaplata";
import StavkaNaplate from "../../model/naplata/StavkaNaplate";
import PartnerCenaAndUslugaCenaReadDto from "../../model/naplata/PartnerCenaAndUslugaCenaReadDto";
import ProizvodCenaReadDto from "../../model/sifarnik-proizvoda/ProizvodCenaReadDto";

const NaplataUtils = () => {
  const calculateUkupnaCenaWithoutPacijentPopust = (stavkeNaplate: StavkaNaplate[], cena?: PartnerCenaAndUslugaCenaReadDto): number => {
    if (!cena) return 0;
    const partnerStavkaNaplate = stavkeNaplate?.find((stavkaNaplate) => stavkaNaplate.uplatilac.sifra === EnumUplatilacNaplata.PARTNER && stavkaNaplate.partner);
    if (partnerStavkaNaplate) {
      if (partnerStavkaNaplate?.kategorijaPopusta) {
        if (partnerStavkaNaplate?.kategorijaPopusta?.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.PROCENAT) {
          return normalizePrice(cena.uslugaCena?.iznosLocalCurrency - (partnerStavkaNaplate?.kategorijaPopusta.popust / 100) * cena.uslugaCena?.iznosLocalCurrency);
        }
        if (partnerStavkaNaplate?.kategorijaPopusta?.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.FIKSNO && partnerStavkaNaplate.kategorijaPopusta.popustLocalCurrency) {
          return normalizePrice(cena.uslugaCena?.iznosLocalCurrency - partnerStavkaNaplate?.kategorijaPopusta.popustLocalCurrency);
        }
      } else if (cena.partnerCena.kategorijaPopusta && cena.partnerCena.id) {
        if (cena.partnerCena.kategorijaPopusta.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.PROCENAT) {
          return normalizePrice(cena.uslugaCena?.iznosLocalCurrency - (cena.partnerCena.kategorijaPopusta.popust / 100) * cena.uslugaCena?.iznosLocalCurrency);
        }
        if (cena.partnerCena.kategorijaPopusta.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.FIKSNO && cena.partnerCena.kategorijaPopusta.popustLocalCurrency) {
          return normalizePrice(cena.uslugaCena?.iznosLocalCurrency - cena.partnerCena.kategorijaPopusta.popustLocalCurrency);
        }
      }
    }

    if (cena?.partnerCena && cena.partnerCena.cenaLocalCurrency) {
      return cena.partnerCena.cenaLocalCurrency;
    }
    return cena.uslugaCena.iznosLocalCurrency;
  };

  const calculateUkupnaProizvodCena = (stavkeNaplate: StavkaNaplate[], cena?: ProizvodCenaReadDto): number => {
    if (!cena) return 0;
    const partnerStavkaNaplate = stavkeNaplate?.find((stavkaNaplate) => stavkaNaplate?.uplatilac?.sifra === EnumUplatilacNaplata.PARTNER && stavkaNaplate?.partner);
    if (partnerStavkaNaplate?.kategorijaPopusta?.popust) {
      if (partnerStavkaNaplate?.kategorijaPopusta?.tipKategorijePopusta?.sifra === EnumTipKategorijePopusta.PROCENAT) {
        return normalizePrice(cena.iznosLocalCurrency * (1 - partnerStavkaNaplate?.kategorijaPopusta?.popust / 100));
      }
      if (partnerStavkaNaplate?.kategorijaPopusta?.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.FIKSNO && partnerStavkaNaplate.kategorijaPopusta?.popustLocalCurrency) {
        return normalizePrice(cena.iznosLocalCurrency - partnerStavkaNaplate?.kategorijaPopusta?.popustLocalCurrency);
      }
    }
    return cena.iznosLocalCurrency;
  };

  const calculateCenaForProizvod = (index: number, stavkeNaplate: StavkaNaplate[], cena?: ProizvodCenaReadDto, bezDodatnogPopusta?: boolean): number => {
    const ukupnaCenaBezPopustaPacijenta = calculateUkupnaProizvodCena(stavkeNaplate, cena);
    const stavkaNaplate = stavkeNaplate[index];

    switch (stavkaNaplate?.uplatilac?.sifra) {
      case EnumUplatilacNaplata.PACIJENT:
        if (stavkaNaplate.kategorijaPopusta?.popust && !bezDodatnogPopusta) {
          if (stavkaNaplate.kategorijaPopusta?.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.PROCENAT) {
            return normalizePrice(ukupnaCenaBezPopustaPacijenta * (stavkaNaplate.placeniProcenat / 100) * stavkaNaplate.kolicina * (1 - stavkaNaplate.kategorijaPopusta.popust / 100));
          }
          if (stavkaNaplate.kategorijaPopusta?.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.FIKSNO && stavkaNaplate.kategorijaPopusta.popustLocalCurrency) {
            return normalizePrice((ukupnaCenaBezPopustaPacijenta * (stavkaNaplate.placeniProcenat / 100) - stavkaNaplate.kategorijaPopusta.popustLocalCurrency) * stavkaNaplate.kolicina);
          }
        }
        return normalizePrice(ukupnaCenaBezPopustaPacijenta * (stavkaNaplate.placeniProcenat / 100) * stavkaNaplate.kolicina);
      case EnumUplatilacNaplata.PARTNER:
        return normalizePrice(ukupnaCenaBezPopustaPacijenta * (stavkaNaplate.placeniProcenat / 100) * stavkaNaplate.kolicina);
      default:
        return 0;
    }
  };

  const calculateCena = (index: number, stavkeNaplate: StavkaNaplate[], cena?: PartnerCenaAndUslugaCenaReadDto, bezDodatnogPopusta?: boolean): number => {
    const ukupnaCenaBezPopustaPacijenta = calculateUkupnaCenaWithoutPacijentPopust(stavkeNaplate, cena);
    const stavkaNaplate = stavkeNaplate[index];

    switch (stavkaNaplate?.uplatilac?.sifra) {
      case EnumUplatilacNaplata.PACIJENT:
        if (stavkaNaplate.kategorijaPopusta?.popust && !bezDodatnogPopusta) {
          if (stavkaNaplate.kategorijaPopusta?.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.PROCENAT) {
            return normalizePrice(ukupnaCenaBezPopustaPacijenta * (stavkaNaplate.placeniProcenat / 100) * stavkaNaplate.kolicina * (1 - stavkaNaplate.kategorijaPopusta.popust / 100));
          }
          if (stavkaNaplate.kategorijaPopusta?.tipKategorijePopusta.sifra === EnumTipKategorijePopusta.FIKSNO && stavkaNaplate.kategorijaPopusta.popustLocalCurrency) {
            return normalizePrice((ukupnaCenaBezPopustaPacijenta * (stavkaNaplate.placeniProcenat / 100) - stavkaNaplate.kategorijaPopusta.popustLocalCurrency) * stavkaNaplate.kolicina);
          }
        }
        return normalizePrice(ukupnaCenaBezPopustaPacijenta * (stavkaNaplate.placeniProcenat / 100) * stavkaNaplate.kolicina);
      case EnumUplatilacNaplata.PARTNER:
        return normalizePrice(ukupnaCenaBezPopustaPacijenta * (stavkaNaplate.placeniProcenat / 100) * stavkaNaplate.kolicina);
      default:
        return 0;
    }
  };

  const normalizePrice = (price: number) => {
    return price > 0 ? price : 0;
  };

  return {
    calculateUkupnaCenaWithoutPacijentPopust,
    calculateCena,
    calculateCenaForProizvod,
    calculateUkupnaProizvodCena,
  };
};

export default NaplataUtils;
