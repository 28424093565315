import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { useLabels } from "../../../../../Store";
import KontaktDijagnozaReadDto from "../../../../../model/kontakt/KontaktDijagnozaReadDto";
import ReceptCreateDto from "../../../../../model/kontakt/ReceptCreateDto";
import SifarnikJedinicaMereReadDto from "../../../../../model/repository/jedinice-mere/SifarnikJedinicaMereReadDto";
import LrAtributReadDto from "../../../../../model/sifarnik-lekova-na-recept/LrAtributReadDto";
import DialogComponent from "../../../../dialog-component/DialogComponent";
import KontaktTerapijeDialogLogical from "./KontaktTerapijeDialogLogical";
import FirstStep from "./form-pages/FirstStep";
import FourthStep from "./form-pages/FourthStep";
import SecondStep from "./form-pages/SecondStep";
import ThirdStep from "./form-pages/ThirdStep";

interface KontaktTerapijeType {
  isOpenTerapijaDialog: boolean;
  closeTerapijaDialog: () => void;
  handleSubmit: () => void;
  kontaktDijagnozaList: Array<KontaktDijagnozaReadDto>;
  dataLoading: boolean;
  terapijaChange: ReceptCreateDto | undefined;
  setTerapijaChange: any;
  lrAtributList: Array<LrAtributReadDto>;
  lekoviList: any;
  searchLekovi: (e: AutoCompleteCompleteEvent) => void;
  getAttributListByLekIdAndSifraDijagnoze: (lekId: number, sifraDijagnoze: string) => void;
  isAttributListLoading: boolean;
  jediniceMereList: Array<SifarnikJedinicaMereReadDto>;
  jedinicaMere: SifarnikJedinicaMereReadDto | undefined;
  getJedinicaMere: (jedinicaMereId: number) => void;
}

export default function KontaktTerapije(props: KontaktTerapijeType) {
  const {
    isOpenTerapijaDialog,
    closeTerapijaDialog,
    handleSubmit,
    kontaktDijagnozaList,
    dataLoading,
    terapijaChange,
    setTerapijaChange,
    lrAtributList,
    lekoviList,
    searchLekovi,
    getAttributListByLekIdAndSifraDijagnoze,
    isAttributListLoading,
    jediniceMereList,
    jedinicaMere,
    getJedinicaMere,
  } = props;
  const Labels = useLabels();
  const { page, setPage, stepName, setStepName, isChecked, setIsChecked, isNextButtonDisabled, scrollTop, getSifraStringFromTerapija, getNacinDoziranjaString } =
    KontaktTerapijeDialogLogical(terapijaChange);
  const headerToolbar = () => (
    <div className="flex flex-row flex-wrap">
      <div className="col-12">
        <div className="p-dialog-title">{Labels.KONTAKT_TERAPIJA_DIALOG_TITLE}</div>
      </div>
      <div className="col-12 p-0 flex flex-row flex-wrap">
        <div className="col-3 pr-2">
          <span className={`step ${page >= 0 ? "active" : ""}`}></span>
          <span className="step-name pt-2">{stepName?.firstStepName}</span>
        </div>
        <div className="col-3 pl-0 pr-2">
          <span className={`step ${page >= 1 ? "active" : ""}`}></span>
          <span className="step-name">{stepName?.secondStepName}</span>
        </div>
        <div className="col-3 pl-0 pr-2">
          <span className={`step ${page >= 2 ? "active" : ""}`}></span>
          <span className="step-name">{stepName?.thirdStepName}</span>
        </div>
        <div className="col-3 pl-0 pr-0">
          <span className={`step ${page >= 3 ? "active" : ""}`}></span>
        </div>
      </div>
    </div>
  );

  const footer = (
    <div className="flex flex-row justify-content-between px-1">
      <Button
        className="p-button-primary p-2 nav-btn"
        icon="pi pi-arrow-left"
        disabled={page === 0 || (kontaktDijagnozaList.length === 1 && page === 1)}
        onClick={() => {
          setPage(page - 1);
          scrollTop();
        }}
      />
      <Button
        className="p-button-primary p-2 nav-btn"
        icon={`pi ${page === 3 ? "pi-check" : "pi-arrow-right"}`}
        disabled={!isNextButtonDisabled(page)}
        onClick={() => {
          if (page !== 3) {
            setPage(page + 1);
            if (page === 2) {
              setStepName({
                ...stepName,
                thirdStepName: getNacinDoziranjaString(isChecked, jedinicaMere?.akronim),
              });
            }
          } else {
            handleSubmit();
          }
          scrollTop();
        }}
      />
    </div>
  );

  const renderStep = () => {
    switch (page) {
      case 0:
        return (
          <FirstStep
            kontaktDijagnozaList={kontaktDijagnozaList}
            dataLoading={dataLoading}
            terapijaChange={terapijaChange}
            setTerapijaChange={setTerapijaChange}
            stepName={stepName}
            setStepName={setStepName}
            page={page}
            setPage={setPage}
          />
        );
      case 1:
        return (
          <SecondStep
            terapijaChange={terapijaChange}
            setTerapijaChange={setTerapijaChange}
            lrAtributList={lrAtributList}
            lekoviList={lekoviList}
            searchLekovi={searchLekovi}
            stepName={stepName}
            setStepName={setStepName}
            getAttributListByLekIdAndSifraDijagnoze={getAttributListByLekIdAndSifraDijagnoze}
            isAttributListLoading={isAttributListLoading}
          />
        );
      case 2:
        return (
          <ThirdStep
            terapijaChange={terapijaChange}
            setTerapijaChange={setTerapijaChange}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            getSifraStringFromTerapija={getSifraStringFromTerapija}
            jediniceMereList={jediniceMereList}
            getJedinicaMere={getJedinicaMere}
          />
        );
      case 3:
        return <FourthStep terapijaChange={terapijaChange} setTerapijaChange={setTerapijaChange} />;
      default:
        return (
          <FirstStep
            kontaktDijagnozaList={kontaktDijagnozaList}
            dataLoading={dataLoading}
            terapijaChange={terapijaChange}
            setTerapijaChange={setTerapijaChange}
            stepName={stepName}
            setStepName={setStepName}
            page={page}
            setPage={setPage}
          />
        );
    }
  };

  return (
    <DialogComponent
      header={headerToolbar}
      visible={isOpenTerapijaDialog}
      onHide={() => {
        closeTerapijaDialog();
      }}
      onShow={() => {
        setPage(0);
        setStepName({
          ...stepName,
          firstStepName: "",
          secondStepName: "",
          thirdStepName: "",
        });
        setIsChecked({
          ...isChecked,
          isJednakoChecked: false,
          isNaSvakihChecked: false,
          isJednokratnoChecked: false,
          isKombinovanoChecked: false,
          isPoPotrebiChecked: false,
          isProizvoljnoChecked: false,
        });
        scrollTop();
      }}
      className="terapijaDialog w-11 sm:w-11 md:w-8"
      footer={page !== 0 ? footer : ""}
      blockScroll={true}
      content={<>{renderStep()}</>}
    />
  );
}
