import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import moment from "moment/moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";
import { useRef } from "react";
import { useOrganizacionaJedinicaList } from "../../../../infrastructure/system/hooks/organizaciona-jedinica/useOrganizacionaJedinicaList";
import useEffectWithoutWarnings from "../../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import Images from "../../../../infrastructure/system/Images";
import RadnoVremeUtils from "../../../../infrastructure/system/RadnoVremeUtils";
import { createCurrentDateWithTime, getDateDivisibleBy5, STEP_MINUTE, TIME_FORMAT } from "../../../../infrastructure/system/Utils";
import InvalidFieldsType from "../../../../model/InvalidFieldsType";
import OrganizacionaJedinicaRadniDanReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaRadniDanReadDto";
import { useLabels } from "../../../../Store";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";
import { InitialStateType } from "../../hooks/initialState";
import { ReducerType } from "../../hooks/reducer";
import KreiraneOrgJediniceListView from "./KreiraneOrgJediniceListView";
import { useUstanovaGridRadniDan } from "../../../../infrastructure/system/hooks/ustanova/useUstanovaGridRadniDan";

interface InicijalizacijaOrgJedinicaViewPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerType>;
  invalidFieldsOrgJedinica?: InvalidFieldsType;
  setInvalidFieldsOrgJedinica: React.Dispatch<React.SetStateAction<InvalidFieldsType | undefined>>;
  onDeleteOrganizacionaJedinica: UseMutationResult<AxiosResponse, unknown, number, unknown>;
  onSubmit: () => void;
}

export default function InicijalizacijaOrgJedinicaView(props: InicijalizacijaOrgJedinicaViewPropsType) {
  const { state, dispatch, invalidFieldsOrgJedinica, setInvalidFieldsOrgJedinica, onDeleteOrganizacionaJedinica, onSubmit } = props;
  const Labels = useLabels();
  const vremeOdRef = useRef<Calendar>(null);
  const vremeDoRef = useRef<Calendar>(null);
  const { organizacionaJedinicaList, isLoading: isLoadingOrgJedList } = useOrganizacionaJedinicaList();
  const { getRadnoVremeValue } = RadnoVremeUtils();
  const { radniDaniGrid, isLoading: isLoadingRadniDaniGrid } = useUstanovaGridRadniDan();

  useEffectWithoutWarnings(() => {
    if (!isLoadingOrgJedList && organizacionaJedinicaList) dispatch({ type: "set_org_jedinica_count", data: organizacionaJedinicaList.length });
  }, [organizacionaJedinicaList]);

  return (
    <div className="flex flex-column xl:flex-row p-6">
      <div className="flex-1">
        <div className="font-bold text-2xl mb-6 flex align-items-center">
          {Labels.LABEL_ORGANIZACIONA_JEDINICA}
          <Image src={Images.ICON_BULB} className="ml-4 mt-1 bulb" data-pr-tooltip={Labels.LABEL_INICIJALIZACIJA_ORG_JEDINICA_TOOLTIP} />
          <Tooltip target=".bulb" />
        </div>
        <div className="col-12 sm:col-12 md:col-11 lg:col-11 xl:col-10 pl-0">
          <div className="grid align-items-center p-0">
            <div className="col-12 px-0 sm:col-4 sm:px-3">
              <div>
                {Labels.LABEL_NAZIV}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <>
                <InputText
                  value={state?.orgJedinicaChange?.naziv ?? ""}
                  onChange={(e) => {
                    dispatch({ type: "change_organizaciona_jedinica", data: { field: "naziv", value: e.target.value } });
                    setInvalidFieldsOrgJedinica((prev) => ({ ...prev, naziv: false }));
                  }}
                  className={invalidFieldsOrgJedinica?.naziv ? "p-invalid" : ""}
                />
                {invalidFieldsOrgJedinica?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </>
            </div>
            <div className="col-12 px-0 sm:col-4 sm:px-3">
              <div>{Labels.ORGANIZACIONA_JEDINCA_NADREDJENA}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              {isLoadingOrgJedList ? (
                <SkeletonInputItem />
              ) : (
                <>
                  <Dropdown
                    options={organizacionaJedinicaList.filter((orgJed) => orgJed.id !== state?.orgJedinicaChange?.id)}
                    value={state?.orgJedinicaChange?.organizacionaJedinicaNadredjena?.id ?? undefined}
                    optionLabel="naziv"
                    optionValue="id"
                    onChange={(e) => {
                      dispatch({ type: "change_organizaciona_jedinica", data: { field: "organizacionaJedinicaNadredjena", value: organizacionaJedinicaList.find((orgJed) => orgJed.id === e.value) } });
                    }}
                    emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    className="p-column-filter"
                    showClear
                  />
                </>
              )}
            </div>
            <div className="col-12 px-0 sm:col-4 sm:px-3">
              <div>
                {Labels.ORGANIZACIONA_JEDINICA_RADNI_DANI}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              {isLoadingRadniDaniGrid ? (
                <SkeletonInputItem />
              ) : (
                <>
                  <MultiSelect
                    value={
                      state?.orgJedinicaChange?.organizacionaJedinicaRadniDanList?.map(
                        (organizacionaJedinicaRadniDan: OrganizacionaJedinicaRadniDanReadDto) => organizacionaJedinicaRadniDan.radniDan.sifra
                      ) ?? undefined
                    }
                    options={radniDaniGrid ?? []}
                    onChange={(e) => {
                      let organizacionaJedinicaRadniDanList = e.value.map((radniDanSifra: string) => ({
                        radniDan: {
                          sifra: radniDanSifra,
                        },
                      }));
                      dispatch({ type: "change_organizaciona_jedinica", data: { field: "organizacionaJedinicaRadniDanList", value: organizacionaJedinicaRadniDanList } });
                      setInvalidFieldsOrgJedinica((prev) => ({ ...prev, radniDani: false }));
                    }}
                    className={invalidFieldsOrgJedinica?.radniDani ? "p-invalid" : ""}
                    optionLabel="naziv"
                    optionValue="sifra"
                    emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                  />
                  {invalidFieldsOrgJedinica?.radniDani && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                </>
              )}
            </div>
            <div className="col-12 px-0 sm:col-4 sm:px-3">
              <div>{Labels.ORGANIZACIONA_JEDINICA_RADNO_VREME_OD}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <CalendarComponent
                ref={vremeOdRef}
                timeOnly
                viewDate={state?.orgJedinicaChange?.radnoVremeDo ? getDateDivisibleBy5(createCurrentDateWithTime(state?.orgJedinicaChange?.radnoVremeDo)) : getDateDivisibleBy5()}
                value={getRadnoVremeValue(state?.orgJedinicaChange?.radnoVremeOd)}
                onChange={(e) => {
                  dispatch({ type: "change_organizaciona_jedinica", data: { field: "radnoVremeOd", value: e.value instanceof Date ? moment(e.value).format(TIME_FORMAT) : e.value } });
                }}
                stepMinute={STEP_MINUTE}
                maxDate={getRadnoVremeValue(state?.orgJedinicaChange?.radnoVremeDo)}
                icon="pi pi-clock"
                onTodayButtonClick={() => {
                  let currentDate = getDateDivisibleBy5(new Date());
                  let maxDate = getRadnoVremeValue(state?.orgJedinicaChange?.radnoVremeDo);
                  if (maxDate && currentDate > maxDate) {
                    currentDate = maxDate;
                  }
                  dispatch({ type: "change_organizaciona_jedinica", data: { field: "radnoVremeOd", value: moment(currentDate).format(TIME_FORMAT) } });
                }}
                className="w-full"
              />
            </div>
            <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
              <div>{Labels.ORGANIZACIONA_JEDINICA_RADNO_VREME_DO}</div>
            </div>
            <div className="col-12 sm:col-8 p-fluid p-0">
              <CalendarComponent
                ref={vremeDoRef}
                timeOnly
                viewDate={state?.orgJedinicaChange?.radnoVremeOd ? getDateDivisibleBy5(createCurrentDateWithTime(state?.orgJedinicaChange?.radnoVremeOd)) : getDateDivisibleBy5()}
                value={getRadnoVremeValue(state?.orgJedinicaChange?.radnoVremeDo)}
                onChange={(e) => {
                  dispatch({ type: "change_organizaciona_jedinica", data: { field: "radnoVremeDo", value: e.value instanceof Date ? moment(e.value).format(TIME_FORMAT) : e.value } });
                }}
                stepMinute={STEP_MINUTE}
                minDate={getRadnoVremeValue(state?.orgJedinicaChange?.radnoVremeOd)}
                icon="pi pi-clock"
                onTodayButtonClick={() => {
                  let currentDate = getDateDivisibleBy5(new Date());
                  let minDate = getRadnoVremeValue(state?.orgJedinicaChange?.radnoVremeOd);
                  if (minDate && currentDate < minDate) {
                    currentDate = minDate;
                  }
                  dispatch({ type: "change_organizaciona_jedinica", data: { field: "radnoVremeDo", value: moment(getDateDivisibleBy5(new Date())).format(TIME_FORMAT) } });
                }}
                className="w-full"
              />
            </div>
            <div className="flex justify-content-end w-full pt-5">
              <div className="w-max">
                <Button
                  label={state?.orgJedinicaIsEditing ? Labels.BUTTON_CANCEL : Labels.LABEL_OBRISI_UNOS}
                  icon={state?.orgJedinicaIsEditing ? "pi pi-times" : "pi pi-trash"}
                  className="mr-5"
                  outlined
                  onClick={() => {
                    dispatch({ type: "set_is_editing_org_jedinica", data: false });
                    setInvalidFieldsOrgJedinica(undefined);
                    dispatch({ type: "set_organizaciona_jedinica", data: undefined });
                  }}
                />

                <Button label={state?.orgJedinicaIsEditing ? Labels.BUTTON_UPDATE : Labels.LABEL_KREIRAJ} icon="pi pi-save" onClick={onSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="font-bold text-2xl mb-6 flex align-items-center">{Labels.LABEL_KREIRANE_ORG_JEDINICE}</div>
        <KreiraneOrgJediniceListView
          organizacionaJedinicaList={organizacionaJedinicaList}
          state={state}
          dispatch={dispatch}
          onDeleteOrganizacionaJedinica={onDeleteOrganizacionaJedinica}
          isLoading={isLoadingOrgJedList}
        />
      </div>
    </div>
  );
}
