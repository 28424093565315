import { InitialStateAddPacijentType } from "./initialStateAddPacijent";
import actions from "./actionsAddPacijent";

export default function reducerAddPacijent(state: InitialStateAddPacijentType, action: any) {
  switch (action.type) {
    case actions.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
      };
    }
    case actions.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
        pacijent: {},
      };
    }
    case actions.PACIJENT_CHANGE: {
      return {
        ...state,
        pacijent: { ...state.pacijent, [action.fieldName]: action.value },
      };
    }
    case actions.GENDERS_LOADING: {
      return {
        ...state,
        gendersLoading: action.value,
      };
    }
    case actions.OBAVESTENJE_TIP_LIST_LOADING: {
      return {
        ...state,
        obavestenjeTipListLoading: action.value,
      };
    }
    default:
      return state;
  }
}
