import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CrudUlogaLogical from "./CrudUlogaLogical";
import CrudUlogaAplikativnaPravaView from "./aplikativna-prava/CrudUlogaAplikativnaPravaView";
import CrudUlogaPravoNadTipovimaView from "./pravo-nad-tipovima/CrudUlogaPravoNadTipovimaView";
import CrudUlogaRadnik from "./radnik/CrudUlogaRadnikView";

export default function CrudUloga() {
  const {
    enumRolaList,
    ulogaOperation,
    isDisabled,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    ulogaChange,
    setUlogaChange,
    breadCrumbItems,
    index,
    setIndex,
    ulogaId,
    location,
    ustanovaId,
    ulogaLoading,
    invalidFields,
    setInvalidFields,
  } = CrudUlogaLogical();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const noSkeleton: boolean = ulogaOperation === EntityOperation.CREATE || !ulogaLoading;

  if (!ulogaId && !location.state) {
    return <Navigate to="/uloga-list" />;
  }

  const leftContentsToolbar = () => (
    <>
      {ulogaOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.ULOGA_TITLE_DIALOG_CREATE} tooltipOptions={tooltipOptionsBottom} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {ulogaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || !noSkeleton}
          icon="pi pi-pencil"
          tooltip={Labels.ULOGA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {ulogaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || !noSkeleton}
          icon="pi pi-trash"
          tooltip={Labels.ULOGA_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="button-danger" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_ULOGA}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.ULOGA_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={ulogaChange?.sifra ?? ""}
                        onChange={(e) => {
                          setUlogaChange({
                            ...ulogaChange!,
                            sifra: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", ulogaChange?.sifra);
                        }}
                        className={invalidFields?.sifra && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.ULOGA_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={ulogaChange?.naziv ?? ""}
                        onChange={(e) => {
                          setUlogaChange({
                            ...ulogaChange!,
                            naziv: e.target.value,
                          });
                          setInvalidFields((prev) => ({ ...prev, naziv: false }));
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", ulogaChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.ULOGA_OPIS}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={ulogaChange?.opis ?? ""}
                      onChange={(e) => {
                        setUlogaChange({
                          ...ulogaChange!,
                          opis: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.ULOGA_NAPOMENA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={ulogaChange?.napomena ?? ""}
                      onChange={(e) => {
                        setUlogaChange({
                          ...ulogaChange!,
                          napomena: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.ULOGA_ROLA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <Dropdown
                        disabled={!pristup || isDisabled}
                        value={ulogaChange?.rola?.sifra ?? ""}
                        options={enumRolaList}
                        optionLabel={"naziv"}
                        optionValue={"sifra"}
                        onChange={(e) => {
                          setUlogaChange({
                            ...ulogaChange!,
                            rola: { sifra: e.target.value },
                          });
                          setInvalidFields((prev) => ({ ...prev, rola: false }));
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={invalidFields?.rola && "p-invalid"}
                      />
                      {invalidFields?.rola && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
          </TabPanel>

          <TabPanel header={Labels.RADNIK_LIST} disabled={ulogaOperation === EntityOperation.CREATE}>
            <CrudUlogaRadnik isDisabled={isDisabled} ustanovaId={ustanovaId} ulogaNaziv={ulogaChange?.naziv} />
          </TabPanel>

          <TabPanel header={Labels.LABEL_ULOGA_APLIKATIVNA_PRAVA} disabled={ulogaOperation === EntityOperation.CREATE}>
            <CrudUlogaAplikativnaPravaView isDisabled={isDisabled} ustanovaId={ustanovaId} ulogaNaziv={ulogaChange?.naziv} />
          </TabPanel>

          <TabPanel header={Labels.LABEL_PRAVO_NAD_TIPOVIMA} disabled={ulogaOperation === EntityOperation.CREATE}>
            <CrudUlogaPravoNadTipovimaView isDisabled={isDisabled} ulogaId={ulogaChange?.id} ustanovaId={ustanovaId} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
