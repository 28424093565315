import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { ListBox } from "primereact/listbox";
import { useLabels } from "../../../../Store";
import PaketOrganizacionaJedinicaReadDto from "../../../../model/paket/PaketOrganizacionaJedinicaReadDto";
import PaketReadDto from "../../../../model/paket/PaketReadDto";
import SkeletonPickListItem from "../../../app/skeleton/SkeletonPickListItem";
import PaketOrganizacionaJedinicaLogical from "./PaketOrganizacionaJedinicaLogical";

interface PaketOrganizacionaJedinicaProps {
  paket: PaketReadDto | undefined;
}

export default function PaketOrganizacionaJedinicaListView(props: PaketOrganizacionaJedinicaProps) {
  const { paket } = props;

  const { paketOrgJedList, paketOrgJedLoading, orgJedListLoading, filteredOrgJedList, selectedOrgJed, setSelectedOrgJed, onCreate, onDelete } = PaketOrganizacionaJedinicaLogical(paket);
  const Labels = useLabels();

  const itemTemplate = (item: PaketOrganizacionaJedinicaReadDto) => {
    return (
      <div className="flex flex-column hc-gap-1 hover:none">
        <div className="flex align-items-center justify-content-between surface-a">
          <span>{item.organizacionaJedinica.naziv}</span>
          <Button icon="pi pi-trash" className="p-button-danger" onClick={() => onDelete(item)} tooltip={Labels.TOOLTIP_DELETE_PAKET_ORGANIZACIONA_JEDINICA} />
        </div>
      </div>
    );
  };

  return (
    <div className="layout-generic-content-list flex flex-column">
      <div className="col-12 flex flex-column hc-gap-5 pt-5 sm:col-8 lg:col-6 xl:col-5">
        <div className="flex hc-gap-5 flex-shrink-1">
          {orgJedListLoading ? (
            <SkeletonPickListItem />
          ) : (
            <Dropdown
              options={filteredOrgJedList}
              optionLabel="naziv"
              placeholder={Labels.PAKET_ORGANIZACIONA_JEDINICA_PLACEHOLDER}
              className="font-bold w-full min-w-0"
              value={selectedOrgJed}
              onChange={(e) => setSelectedOrgJed(e.value)}
            />
          )}
          <Button icon="pi pi-plus" className="flex-shrink-0" disabled={selectedOrgJed === undefined} onClick={onCreate} tooltip={Labels.TOOLTIP_CREATE_PAKET_ORGANIZACIONA_JEDINICA} />
        </div>
        {paketOrgJedLoading ? <SkeletonPickListItem /> : <ListBox options={paketOrgJedList} itemTemplate={itemTemplate} className="w-full font-bold" emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND} />}
      </div>
    </div>
  );
}
