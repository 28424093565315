import { InitialStateType } from "./initialState";
import UstanovaReadDto from "../../../model/ustanova/UstanovaReadDto";
import UstanovaGridUpdateDto from "../../../model/ustanova/UstanovaGridUpdateDto";
import OrganizacionaJedinicaReadDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import RadnikInicijalizacijaReadDto from "../../../model/inicijalizacija/RadnikInicijalizacijaReadDto";
import SifarnikUslugaReadDto from "../../../model/sifarnik-usluga/SifarnikUslugaReadDto";
import KontaktTipRepositoryReadDto from "../../../model/kontakt/KontaktTipRepositoryReadDto";
import MandatoryBaseEntityDto from "../../../model/MandatoryBaseEntityDto";
import KontaktTipOrganizacionaJedinicaCopyPublicDto from "../../../model/kontakt/KontaktTipOrganizacionaJedinicaCopyPublicDto";
import KontaktTipRepositoryDto from "../../../model/kontakt/KontaktTipRepositoryDto";

type SetStepType = {
  type: "set_step";
  step: number;
};

type IncrementStepType = {
  type: "increment_step";
};

type DecrementStepType = {
  type: "decrement_step";
};

type SetUstanova = {
  type: "set_ustanova";
  data: UstanovaReadDto | undefined;
};

type SetUstanovaGrid = {
  type: "set_ustanova_grid";
  data: UstanovaGridUpdateDto | undefined;
};
type SetUstanovaReadOnly = {
  type: "set_ustanova_readonly";
  data: UstanovaReadDto | undefined;
};

type SetUstanovaGridReadOnly = {
  type: "set_ustanova_grid_readonly";
  data: UstanovaGridUpdateDto | undefined;
};

type ChangeUstanova = {
  type: "change_ustanova";
  data: {
    field: string;
    value: number | string | Object | undefined;
  };
};

type ChangeUstanovaGrid = {
  type: "change_ustanova_grid";
  data: {
    field: string;
    value: number | string | Object | undefined;
  };
};

type SetOrganizacionaJedinica = {
  type: "set_organizaciona_jedinica";
  data?: OrganizacionaJedinicaReadDto;
};

type ChangeOrganizacionaJedinica = {
  type: "change_organizaciona_jedinica";
  data: {
    field: string;
    value: any;
  };
};
type SetIsEditingOrgJedinica = {
  type: "set_is_editing_org_jedinica";
  data: boolean;
};
type SetOrgJedinicaListCount = {
  type: "set_org_jedinica_count";
  data: number;
};
type SetTipoviPregleda = {
  type: "set_tipovi_pregleda";
  data: KontaktTipRepositoryDto[];
};
type ChangeTipoviPregleda = {
  type: "change_tipovi_pregleda";
  data: { idRepositoryKontaktTip: number; organizacionaJedinicaList: KontaktTipOrganizacionaJedinicaCopyPublicDto[]; tipPregleda: KontaktTipRepositoryReadDto };
};

type SetRadnik = {
  type: "set_radnik";
  data?: RadnikInicijalizacijaReadDto;
};
type ChangeRadnik = {
  type: "change_radnik";
  data: {
    field: string;
    value: any;
  };
};
type SetIsEditingRadnik = {
  type: "set_is_editing_radnik";
  data: boolean;
};
type AddOrgJedToTipPregleda = {
  type: "add_org_jedinica_to_tip_pregleda";
  data: { id: number; organizacionaJedinicaList: MandatoryBaseEntityDto[] };
};
type UncheckTipPregleda = {
  type: "uncheck_tip_pregleda";
  data: number;
};
type UncheckAllTipoviPregleda = {
  type: "uncheck_all_tipovi_pregleda";
};
type SelectAllTipoviPregleda = {
  type: "select_all_tipovi_pregleda";
  data: { tipPregledaList: KontaktTipRepositoryReadDto[]; organizacionaJedinicaList: OrganizacionaJedinicaReadDto[] };
};

type SetUsluga = {
  type: "set_usluga";
  data?: SifarnikUslugaReadDto;
};
type ChangeUsluga = {
  type: "change_usluga";
  data: {
    field: string;
    value: any;
  };
};
type SetIsEditingUsluga = {
  type: "set_is_editing_usluga";
  data: boolean;
};

type SetIsFinishingInitializationProcess = {
  type: "set_is_finishing_initialization_process";
  data: boolean;
};
type SetShowFinishingInitializationProcessOverlay = {
  type: "set_show_finishing_initialization_process_overlay";
  data: boolean;
};
type SetTipoviPregledaCountType = {
  type: "set_tipovi_pregleda_count";
  data: number;
};
type AreTipoviPregledaChanged = {
  type: "set_tipovi_pregleda_changed";
  field: number;
  value: boolean;
};

export type ReducerType =
  | SetStepType
  | IncrementStepType
  | DecrementStepType
  | SetUstanova
  | SetUstanovaGrid
  | ChangeUstanova
  | ChangeUstanovaGrid
  | SetOrganizacionaJedinica
  | ChangeOrganizacionaJedinica
  | SetIsEditingOrgJedinica
  | SetOrgJedinicaListCount
  | SetRadnik
  | ChangeRadnik
  | SetIsEditingRadnik
  | SetUsluga
  | ChangeUsluga
  | SetIsEditingUsluga
  | SetTipoviPregleda
  | ChangeTipoviPregleda
  | AddOrgJedToTipPregleda
  | UncheckTipPregleda
  | SelectAllTipoviPregleda
  | UncheckAllTipoviPregleda
  | SetIsFinishingInitializationProcess
  | SetShowFinishingInitializationProcessOverlay
  | SetTipoviPregledaCountType
  | SetUstanovaReadOnly
  | SetUstanovaGridReadOnly
  | AreTipoviPregledaChanged;

export default function reducer(state: InitialStateType, action: ReducerType) {
  switch (action.type) {
    case "set_step": {
      return {
        ...state,
        step: action.step,
      };
    }
    case "increment_step": {
      return {
        ...state,
        step: state.step! + 1,
      };
    }
    case "decrement_step": {
      return {
        ...state,
        step: state.step! - 1,
      };
    }
    case "set_ustanova": {
      return {
        ...state,
        ustanovaChange: action.data,
      };
    }
    case "set_ustanova_grid": {
      return {
        ...state,
        ustanovaGridChange: action.data,
      };
    }
    case "set_ustanova_readonly": {
      return {
        ...state,
        ustanovaReadOnly: action.data,
      };
    }
    case "set_ustanova_grid_readonly": {
      return {
        ...state,
        ustanovaGridReadOnly: action.data,
      };
    }
    case "change_ustanova": {
      return {
        ...state,
        ustanovaChange: { ...state.ustanovaChange!, [action.data.field]: action.data.value },
      };
    }
    case "change_ustanova_grid": {
      return {
        ...state,
        ustanovaGridChange: { ...state.ustanovaGridChange!, [action.data.field]: action.data.value },
      };
    }
    case "set_organizaciona_jedinica": {
      return {
        ...state,
        orgJedinicaChange: action.data,
      };
    }
    case "change_organizaciona_jedinica": {
      return {
        ...state,
        orgJedinicaChange: { ...state.orgJedinicaChange!, [action.data.field]: action.data.value },
      };
    }
    case "set_is_editing_org_jedinica": {
      return {
        ...state,
        orgJedinicaIsEditing: action.data,
      };
    }
    case "set_org_jedinica_count": {
      return {
        ...state,
        orgJedinicaListCount: action.data,
      };
    }
    case "set_tipovi_pregleda": {
      return {
        ...state,
        tipoviPregleda: action.data,
      };
    }
    case "change_tipovi_pregleda": {
      return {
        ...state,
        tipoviPregleda: [
          ...state?.tipoviPregleda!,
          { ...action.data.tipPregleda, idRepositoryKontaktTip: action.data.idRepositoryKontaktTip, kontaktTipOrganizacionaJedinicaList: action.data.organizacionaJedinicaList },
        ],
      };
    }
    case "add_org_jedinica_to_tip_pregleda": {
      const tipoviPregledaCopy = [...state.tipoviPregleda];
      const selectedTipPregleda = tipoviPregledaCopy.find((tip) => tip.idRepositoryKontaktTip === action.data.id);
      if (selectedTipPregleda) selectedTipPregleda.kontaktTipOrganizacionaJedinicaList = action.data.organizacionaJedinicaList.map((orgJed) => ({ organizacionaJedinica: orgJed }));

      return {
        ...state,
        tipoviPregleda: tipoviPregledaCopy,
      };
    }
    case "uncheck_tip_pregleda": {
      const tipoviPregleda = state?.tipoviPregleda.filter((tip) => tip.idRepositoryKontaktTip !== action.data);

      return {
        ...state,
        tipoviPregleda: tipoviPregleda,
        selectAllTipoviPregleda: false,
      };
    }
    case "uncheck_all_tipovi_pregleda": {
      return {
        ...state,
        tipoviPregleda: [],
        selectAllTipoviPregleda: false,
      };
    }
    case "set_radnik": {
      return {
        ...state,
        radnikChange: action.data,
      };
    }
    case "change_radnik": {
      return {
        ...state,
        radnikChange: { ...state?.radnikChange!, [action.data.field]: action.data.value },
      };
    }
    case "set_is_editing_radnik": {
      return {
        ...state,
        isRadnikEditing: action.data,
      };
    }
    case "select_all_tipovi_pregleda": {
      const organizacioneJedinice = action.data.organizacionaJedinicaList.map((orgJed) => ({ organizacionaJedinica: { id: orgJed.id } }));
      const tipoviPregleda = action.data.tipPregledaList.map((tip) => ({ ...tip, idRepositoryKontaktTip: tip.id, kontaktTipOrganizacionaJedinicaList: organizacioneJedinice }));

      return {
        ...state,
        tipoviPregleda: tipoviPregleda,
        selectAllTipoviPregleda: true,
      };
    }
    case "set_usluga": {
      return {
        ...state,
        uslugaChange: action.data,
      };
    }
    case "change_usluga": {
      return {
        ...state,
        uslugaChange: { ...state?.uslugaChange!, [action.data.field]: action.data.value },
      };
    }
    case "set_is_editing_usluga": {
      return {
        ...state,
        isUslugaEditing: action.data,
      };
    }
    case "set_is_finishing_initialization_process": {
      return {
        ...state,
        isFinishingInitializationProcess: action.data,
      };
    }
    case "set_show_finishing_initialization_process_overlay": {
      return {
        ...state,
        showFinishingInitializationProcessOverlay: action.data,
      };
    }
    case "set_tipovi_pregleda_count": {
      return {
        ...state,
        tipoviPregledaCount: action.data,
      };
    }
    case "set_tipovi_pregleda_changed": {
      return {
        ...state,
        areTipoviPregledaChanged: { ...state.areTipoviPregledaChanged!, [action.field]: action.value },
      };
    }
  }
}
