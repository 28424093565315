interface IconGridViewProps {
  color?: string;
  height?: string;
}

const IconCalendarGridView = ({ color, height }: IconGridViewProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      height={height ?? "100%"}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-grid"
    >
      <rect x="3" y="3" width="7" height="7"></rect>
      <rect x="14" y="3" width="7" height="7"></rect>
      <rect x="14" y="14" width="7" height="7"></rect>
      <rect x="3" y="14" width="7" height="7"></rect>
    </svg>
  );
};

export default IconCalendarGridView;
