import { Button } from "primereact/button";
import { Image } from "primereact/image";
import DialogComponent from "../../dialog-component/DialogComponent";
import { useLabels } from "../../../Store";
import Images from "../../../infrastructure/system/Images";

interface NonInitializedDialogPropsType {
  isNonInitializedDialogVisible: boolean;
  deleteAuthData: () => void;
}

export default function NonInitializedDialog({ isNonInitializedDialogVisible, deleteAuthData }: NonInitializedDialogPropsType) {
  const Labels = useLabels();

  return (
    <DialogComponent
      className="w-10 md-w-6 lg:w-6 xl:w-4"
      header={""}
      visible={isNonInitializedDialogVisible}
      onHide={() => {}}
      closable={false}
      content={
        <>
          <div className="mb-4 text-center"><Image src={Images.ICON_MONITOR} /></div>
          <div className="bold text-2xl text-center mb-4">{Labels.LABEL_INICIJALIZACIJA_DIALOG_TITLE}</div>
          <div>{Labels.LABEL_INICIJALIZACIJA_DIALOG_TEXT_1}</div>
          <div className="mb-4">{Labels.LABEL_INICIJALIZACIJA_DIALOG_TEXT_2}</div>
        </>
      }
      footer={
        <div className="text-center">
          <Button icon="pi pi-times" className="p-button-outlined" label={Labels.BUTTON_LOG_OUT} onClick={deleteAuthData} />
        </div>
      }
    />
  );
}
