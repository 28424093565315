import { Skeleton } from "primereact/skeleton";

interface SkeletonAccordionListType {
  size?: number;
  height?: string;
}

export default function SkeletonAccordionList(props: SkeletonAccordionListType) {
  const { size, height } = props;

  return (
    <>
      {[...Array(size ?? 1).keys()].map((j: number) => (
        <Skeleton key={j} height={height ?? "15px"} className="col-12 mt-3" />
      ))}
    </>
  );
}
