import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import { SifarnikJedinicaMereController } from "../../../controllers/sifarnik-jedinica-mere/SifarnikJedinicaMereController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SifarnikJedinicaMereReadDto from "../../../model/sifarnik/sifarnik-jedinica-mere/SifarnikJedinicaMereReadDto";

interface SifarniкJedinicaMereListLogicalType {
  exportData: Array<any>;
  dt: any;
  sifarnikJedinicaMereList: Array<SifarnikJedinicaMereReadDto>;
  first: number;
  tableRows: number;
  onPage: (rows: number, first: number) => void;
  openSifarnikJedinicaMere: (entityOperation: string, id?: number) => void;
  sifarnikJedinicaMereLoading: boolean;
  breadCrumbItems: Array<BreadCrumbItemDto>;
}

export default function SifarnikJedinicaMereListLogical(): SifarniкJedinicaMereListLogicalType {
  const { showMessage } = useContext(AppContext);
  const [sifarnikJedinicaMereList, setSifarnikJedinicaMereList] = useState<Array<SifarnikJedinicaMereReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const dt = useRef<any>(null);
  const { axiosGetSifarnikJedinicaMereList } = SifarnikJedinicaMereController();
  const [sifarnikJedinicaMereLoading, setSifarnikJedinicaMereLoading] = useState(false);

  const Labels = useLabels();
  const navigate = useNavigate();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.SIFARNIK_JEDINICA_MERE,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setSifarnikJedinicaMereLoading(true);
    axiosGetSifarnikJedinicaMereList()
      .then(({ data: { data } }: { data: { data: Array<SifarnikJedinicaMereReadDto> } }) => {
        setSifarnikJedinicaMereList(data);
        let exportList = new Array<any>();
        data.forEach((sifarnikJedinicaMere: SifarnikJedinicaMereReadDto) => {
          exportList.push({
            [Labels.SIFARNIK_JEDINICA_MERE_NAZIV]: sifarnikJedinicaMere.naziv,
            [Labels.SIFARNIK_JEDINICA_MERE_SIFRA]: sifarnikJedinicaMere.sifra,
            [Labels.SIFARNIK_JEDINICA_MERE_AKRONIM]: sifarnikJedinicaMere.akronim,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSifarnikJedinicaMereLoading, startTime);
      });
  };

  const onPage = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const openSifarnikJedinicaMere = (entityOperation: string, id?: number) => {
    const sifarnikJedinicaMereId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-sifarnik-jedinica-mere/${sifarnikJedinicaMereId}`, {
      state: { sifarnikJedinicaMereOperation: entityOperation },
    });
  };

  return {
    exportData,
    dt,
    sifarnikJedinicaMereList,
    first,
    tableRows,
    onPage,
    openSifarnikJedinicaMere,
    sifarnikJedinicaMereLoading,
    breadCrumbItems,
  };
}
