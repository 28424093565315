import { ScrollPanel } from "primereact/scrollpanel";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLabels } from "../../../Store";
import GrupnoObavestenjeSimpleReadDto from "../../../model/obavestenja-pacijenti/GrupnoObavestenjeSimpleReadDto";
import SkeletonGrupnoObavestenjeListItem from "../../app/skeleton/SkeletonGrupnoObavestenjeListItem";
import ObavestenjaFilters from "./ObavestenjaFilters";
import ObavestenjeItem from "./ObavestenjeItem";

const ObavestenjaZaSlanjeTab = (props: any) => {
  const {
    obavestenjeZaSlanjeFilters,
    setObavestenjeZaSlanjeFilters,
    state,
    dispatch,
    obavestenjaZaSlanjeListLoading,
    obavestenjaZaSlanjeList,
    fetchObavestenjaZaSlanje,
    hasMoreObavestenjaZaSlanje,
    onDelete,
    fetchObavestenje,
    fetchObavestenjePreSlanja,
  } = props;
  const Labels = useLabels();

  document.querySelector(".notification-list.notification-list-not-sent .p-scrollpanel-wrapper .p-scrollpanel-content")?.setAttribute("id", "notificationListNotSent");

  return (
    <div className="flex flex-column align-items-center">
      <ObavestenjaFilters filters={obavestenjeZaSlanjeFilters} setFilters={setObavestenjeZaSlanjeFilters} obavestenjeTypeList={state.obavestenjeTypeList} />
      <ScrollPanel className="notification-list notification-list-not-sent w-12 sm:w-10 md:w-8 lg:w-8 xl:w-6">
        {obavestenjaZaSlanjeListLoading ? (
          <SkeletonGrupnoObavestenjeListItem size={5} />
        ) : obavestenjaZaSlanjeList?.length === 0 ? (
          <div className="flex align-items-center justify-content-center h-full">{Labels.LABEL_OBAVESTENJA_ZA_PACIJENTE_EMPTY_DATA}</div>
        ) : (
          <InfiniteScroll
            dataLength={obavestenjaZaSlanjeList.length}
            next={fetchObavestenjaZaSlanje}
            hasMore={hasMoreObavestenjaZaSlanje.current}
            loader={<SkeletonGrupnoObavestenjeListItem />}
            scrollableTarget="notificationListNotSent"
            scrollThreshold={1}
            className="overflow-hidden"
          >
            {obavestenjaZaSlanjeList?.map((obavestenje: GrupnoObavestenjeSimpleReadDto) => (
              <ObavestenjeItem
                key={obavestenje.id}
                obavestenje={obavestenje}
                state={state}
                dispatch={dispatch}
                onDelete={onDelete}
                fetchObavestenje={fetchObavestenje}
                fetchObavestenjePreSlanja={fetchObavestenjePreSlanja}
              />
            ))}
          </InfiniteScroll>
        )}
      </ScrollPanel>
    </div>
  );
};

export default ObavestenjaZaSlanjeTab;
