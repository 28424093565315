import moment from "moment";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import { Navigate } from "react-router-dom";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import Images from "../../../infrastructure/system/Images";
import {
  STEP_MINUTE,
  TIME_FORMAT,
  createCurrentDateWithTime,
  getDateDivisibleBy5,
  getImage,
  handleKeyboardTimeInputChange,
  setInvalid,
  tooltipOptionsBottom,
} from "../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import CalendarComponent from "../calendar-component/CalendarComponent";
import CrudUstanovaLogical from "./CrudUstanovaLogical";
import UstanovaPlacanjeList from "./placanje/UstanovaPlacanjeListView";
import UstanovaFakturniPodaciView from "./ustanova-fakturni-podaci/UstanovaFakturniPodaciView";
import UstanovaLicencePage from "./ustanova-licence/UstanovaLicenceView";
import UstanovaRadnikListView from "./ustanova-radnici/UstanovaRadnikListView";
//import UstanovaParametriView from "./ustanova-parametri/UstanovaParametriView";
import { useLabels } from "../../../Store";
import { useValutaList } from "../../../infrastructure/system/hooks/sifarnici/valuta-list/useValutaList";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import TipKontaktaListPage from "../kontakt/TipKontaktaListView";
import UstanovaGridView from "./UstanovaGridView";
import UstanovaBrisanjeView from "./ustanova-brisanje/UstanovaBrisanjeView";
import UstanovaEksterniSistemListView from "./ustanova-eksterni-sistem/UstanovaEksterniSistemListView";
import UstanovaNadjimedView from "./ustanova-nadjimed/UstanovaNadjimedView";
import UstanovaUlogaListView from "./ustanova-uloga/UstanovaUlogaListView";

export default function CrudUstanova() {
  const {
    ustanovaOperation,
    isDisabled,
    isCreateDialogOpen,
    ustanovaChange,
    breadCrumbItems,
    setUstanovaChange,
    onCreate,
    onUpdate,
    onCancel,
    setIndex,
    index,
    ustanovaId,
    location,
    jezikList,
    svojinaTipList,
    nivoZdravstveneZastiteList,
    podrazumevanaStranaList,
    ustanovaLoading,
    getRadnoVremeValue,
    protokolList,
    pristupList,
    inizijalizacijaKorakList,
    invalidFields,
    setInvalidFields,
    logoUploadHandler,
    vremeOdRef,
    vremeDoRef,
  } = CrudUstanovaLogical();

  const Labels = useLabels();
  const noSkeleton: boolean = ustanovaOperation === EntityOperation.CREATE || !ustanovaLoading;
  const logoUploadRef = useRef<any>(null);
  const cancelOptions = { className: "class-visibility-hidden" };
  const { valutaList } = useValutaList();

  if (!ustanovaId && !location.state) {
    return <Navigate to="/ustanova-list" />;
  }

  const leftContentsToolbar = () => (
    <>
      {ustanovaOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.USTANOVA_TITLE_DIALOG_CREATE} tooltipOptions={tooltipOptionsBottom} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {ustanovaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!noSkeleton}
          icon="pi pi-pencil"
          tooltip={Labels.USTANOVA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );
  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.USTANOVA}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.USTANOVA_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <InputText
                        disabled={isDisabled}
                        value={ustanovaChange?.sifra ?? ""}
                        onChange={(e) => {
                          setUstanovaChange({
                            ...ustanovaChange!,
                            sifra: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "sifra", ustanovaChange?.sifra);
                        }}
                        className={invalidFields?.sifra && "p-invalid"}
                      />
                      {invalidFields?.sifra && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.USTANOVA_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <InputText
                        disabled={isDisabled}
                        value={ustanovaChange?.naziv ?? ""}
                        onChange={(e) => {
                          setUstanovaChange({
                            ...ustanovaChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", ustanovaChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.USTANOVA_NAZIV_KRATKI}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <InputText
                        disabled={isDisabled}
                        value={ustanovaChange?.nazivKratki ?? ""}
                        onChange={(e) => {
                          setUstanovaChange({
                            ...ustanovaChange!,
                            nazivKratki: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "nazivKratki", ustanovaChange?.nazivKratki);
                        }}
                        className={invalidFields?.nazivKratki && "p-invalid"}
                      />
                      {invalidFields?.nazivKratki && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.USTANOVA_ADRESA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <InputText
                      disabled={isDisabled}
                      value={ustanovaChange?.adresa ?? ""}
                      onChange={(e) => {
                        setUstanovaChange({
                          ...ustanovaChange!,
                          adresa: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.USTANOVA_TELEFON}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <InputText
                      disabled={isDisabled}
                      value={ustanovaChange?.telefon ?? ""}
                      onChange={(e) => {
                        setUstanovaChange({
                          ...ustanovaChange!,
                          telefon: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.USTANOVA_RADNO_VREME_OD}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <CalendarComponent
                        ref={vremeOdRef}
                        timeOnly
                        viewDate={ustanovaChange?.radnoVremeDo ? getDateDivisibleBy5(createCurrentDateWithTime(ustanovaChange?.radnoVremeDo)) : getDateDivisibleBy5()}
                        value={getRadnoVremeValue(ustanovaChange?.radnoVremeOd)}
                        onChange={(e) => {
                          setUstanovaChange({
                            ...ustanovaChange!,
                            radnoVremeOd: e.value instanceof Date ? moment(e.value).format(TIME_FORMAT) : e.value,
                          });
                          setInvalidFields((prev) => ({ ...prev, radnoVremeOd: false }));
                        }}
                        onInput={(e) =>
                          handleKeyboardTimeInputChange(
                            e.currentTarget.value,
                            "radnoVremeOd",
                            ustanovaChange,
                            setUstanovaChange,
                            vremeOdRef,
                            ustanovaChange?.radnoVremeDo ? createCurrentDateWithTime(ustanovaChange?.radnoVremeDo) : undefined,
                            undefined
                          )
                        }
                        onClearButtonClick={() => {
                          setInvalidFields((prev) => ({ ...prev, radnoVremeOd: true }));
                        }}
                        stepMinute={STEP_MINUTE}
                        maxDate={getRadnoVremeValue(ustanovaChange?.radnoVremeDo)}
                        icon="pi pi-clock"
                        onTodayButtonClick={() => {
                          let currentDate = getDateDivisibleBy5(new Date());
                          let maxDate = getRadnoVremeValue(ustanovaChange?.radnoVremeDo);
                          if (maxDate && currentDate > maxDate) {
                            currentDate = maxDate;
                          }
                          setUstanovaChange({
                            ...ustanovaChange!,
                            radnoVremeOd: moment(currentDate).format(TIME_FORMAT),
                          });
                        }}
                        className={"w-full " + (invalidFields?.radnoVremeOd && "p-invalid")}
                      />
                      {invalidFields?.radnoVremeOd && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.USTANOVA_RADNO_VREME_DO}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <CalendarComponent
                        ref={vremeDoRef}
                        timeOnly
                        viewDate={ustanovaChange?.radnoVremeOd ? getDateDivisibleBy5(createCurrentDateWithTime(ustanovaChange?.radnoVremeOd)) : getDateDivisibleBy5()}
                        value={getRadnoVremeValue(ustanovaChange?.radnoVremeDo)}
                        onChange={(e) => {
                          setUstanovaChange({
                            ...ustanovaChange!,
                            radnoVremeDo: e.value instanceof Date ? moment(e.value).format(TIME_FORMAT) : e.value,
                          });
                          setInvalidFields((prev) => ({ ...prev, radnoVremeDo: false }));
                        }}
                        onInput={(e) =>
                          handleKeyboardTimeInputChange(
                            e.currentTarget.value,
                            "radnoVremeDo",
                            ustanovaChange,
                            setUstanovaChange,
                            vremeDoRef,
                            undefined,
                            ustanovaChange?.radnoVremeOd ? createCurrentDateWithTime(ustanovaChange?.radnoVremeOd) : undefined
                          )
                        }
                        onClearButtonClick={() => {
                          setInvalidFields((prev) => ({ ...prev, radnoVremeDo: true }));
                        }}
                        stepMinute={STEP_MINUTE}
                        minDate={getRadnoVremeValue(ustanovaChange?.radnoVremeOd)}
                        icon="pi pi-clock"
                        onTodayButtonClick={() => {
                          let currentDate = getDateDivisibleBy5(new Date());
                          let minDate = getRadnoVremeValue(ustanovaChange?.radnoVremeOd);
                          if (minDate && currentDate < minDate) {
                            currentDate = minDate;
                          }
                          setUstanovaChange({
                            ...ustanovaChange!,
                            radnoVremeDo: moment(currentDate).format(TIME_FORMAT),
                          });
                        }}
                        className={"w-full " + (invalidFields?.radnoVremeDo && "p-invalid")}
                      />
                      {invalidFields?.radnoVremeDo && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.USTANOVA_WEBSITE_URL}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <InputText
                      disabled={isDisabled}
                      value={ustanovaChange?.websiteURL ?? ""}
                      onChange={(e) => {
                        setUstanovaChange({
                          ...ustanovaChange!,
                          websiteURL: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.USTANOVA_JEZIK}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <Dropdown
                        disabled={isDisabled}
                        options={jezikList}
                        value={ustanovaChange?.jezik?.sifra ?? undefined}
                        optionLabel="naziv"
                        optionValue="sifra"
                        onChange={(e) => {
                          setUstanovaChange({
                            ...ustanovaChange!,
                            jezik: e.value ? { sifra: e.value } : undefined,
                          });
                          if (e.target.value) {
                            setInvalidFields((prev) => ({ ...prev, jezik: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, jezik: true }));
                          }
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={"p-column-filter " + (invalidFields?.jezik && "p-invalid")}
                      />
                      {invalidFields?.jezik && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.USTANOVA_SVOJINA_TIP}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <Dropdown
                        disabled={isDisabled}
                        options={svojinaTipList}
                        value={ustanovaChange?.svojinaTip?.sifra ?? undefined}
                        optionLabel="naziv"
                        optionValue="sifra"
                        onChange={(e) => {
                          setUstanovaChange({
                            ...ustanovaChange!,
                            svojinaTip: e.value ? { sifra: e.value } : undefined,
                          });
                          if (e.target.value) {
                            setInvalidFields((prev) => ({ ...prev, svojinaTip: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, svojinaTip: true }));
                          }
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={"p-column-filter " + (invalidFields?.svojinaTip && "p-invalid")}
                      />
                      {invalidFields?.svojinaTip && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.USTANOVA_NIVO_ZDRAVSTVENE_ZASTITE}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <Dropdown
                        disabled={isDisabled}
                        options={nivoZdravstveneZastiteList}
                        value={ustanovaChange?.nivoZdravstveneZastite?.sifra ?? undefined}
                        optionLabel="naziv"
                        optionValue="sifra"
                        onChange={(e) => {
                          setUstanovaChange({
                            ...ustanovaChange!,
                            nivoZdravstveneZastite: e.value ? { sifra: e.value } : undefined,
                          });
                          if (e.target.value) {
                            setInvalidFields((prev) => ({ ...prev, nivoZastite: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, nivoZastite: true }));
                          }
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={"p-column-filter " + (invalidFields?.nivoZastite && "p-invalid")}
                      />
                      {invalidFields?.nivoZastite && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.USTANOVA_PODRAZUMEVANA_STRANA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <Dropdown
                        disabled={isDisabled}
                        options={podrazumevanaStranaList}
                        value={ustanovaChange?.podrazumevanaStrana?.sifra ?? undefined}
                        optionLabel="naziv"
                        optionValue="sifra"
                        onChange={(e) => {
                          setUstanovaChange({
                            ...ustanovaChange!,
                            podrazumevanaStrana: e.value ? { sifra: e.value } : undefined,
                          });
                          if (e.target.value) {
                            setInvalidFields((prev) => ({ ...prev, podrazumevanaStrana: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, podrazumevanaStrana: true }));
                          }
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={"p-column-filter " + (invalidFields?.podrazumevanaStrana && "p-invalid")}
                      />
                      {invalidFields?.podrazumevanaStrana && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.USTANOVA_SIFARNIK_VALUTA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <>
                      <Dropdown
                        disabled={isDisabled}
                        options={valutaList}
                        value={ustanovaChange?.valuta?.id ?? undefined}
                        optionLabel="sifra"
                        optionValue="id"
                        onChange={(e) => {
                          setUstanovaChange({
                            ...ustanovaChange!,
                            valuta: e.value ? { id: e.value } : undefined,
                          });
                          if (e.target.value) {
                            setInvalidFields((prev) => ({ ...prev, valuta: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, valuta: true }));
                          }
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        className={"p-column-filter " + (invalidFields?.valuta && "p-invalid")}
                      />
                      {invalidFields?.valuta && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.USTANOVA_PRISTUP_PRIMARAN}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <Dropdown
                      disabled={isDisabled}
                      options={pristupList}
                      showClear
                      value={ustanovaChange?.pristupPrimaran?.sifra ?? undefined}
                      optionLabel="naziv"
                      optionValue="sifra"
                      onChange={(e) => {
                        setUstanovaChange({
                          ...ustanovaChange!,
                          pristupPrimaran: e.value ? { sifra: e.value } : undefined,
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.USTANOVA_INICIJALIZACIJA_KORAK}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <Dropdown
                      disabled={isDisabled}
                      options={inizijalizacijaKorakList}
                      value={ustanovaChange?.inicijalizacijaKorak?.sifra ?? undefined}
                      optionLabel="naziv"
                      optionValue="sifra"
                      onChange={(e) => {
                        setUstanovaChange({
                          ...ustanovaChange!,
                          inicijalizacijaKorak: e.value ? { sifra: e.value } : undefined,
                        });
                      }}
                      emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                {ustanovaOperation !== EntityOperation.CREATE && (
                  <>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>{Labels.USTANOVA_PROTOKOL}</div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      {noSkeleton ? (
                        <Dropdown
                          disabled={isDisabled}
                          options={protokolList}
                          showClear
                          value={ustanovaChange?.protokol?.id ?? null}
                          optionLabel="naziv"
                          optionValue="id"
                          onChange={(e) => {
                            setUstanovaChange({
                              ...ustanovaChange!,
                              protokol: e.value ? protokolList.filter((protokol) => protokol.id === e.value)[0] : undefined,
                            });
                          }}
                          emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        />
                      ) : (
                        <SkeletonInputItem />
                      )}
                    </div>
                  </>
                )}
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.USTANOVA_GPS_LOKACIJA}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <InputText
                      disabled={isDisabled}
                      value={ustanovaChange?.gpsLocation ?? ""}
                      onChange={(e) => {
                        setUstanovaChange({
                          ...ustanovaChange!,
                          gpsLocation: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.USTANOVA_TEKUCI_RACUN}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <InputText
                      disabled={isDisabled}
                      value={ustanovaChange?.tekuciRacun ?? ""}
                      onChange={(e) => {
                        setUstanovaChange({
                          ...ustanovaChange!,
                          tekuciRacun: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.USTANOVA_FAKS}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {noSkeleton ? (
                    <InputText
                      disabled={isDisabled}
                      value={ustanovaChange?.faks ?? ""}
                      onChange={(e) => {
                        setUstanovaChange({
                          ...ustanovaChange!,
                          faks: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.USTANOVA_LOGO}</div>
                </div>
                <div className="col-12 sm:col-8 p-0">
                  {noSkeleton ? (
                    <div className="logo-upload-wrapper relative inline-block pt-2">
                      {ustanovaChange?.logo && (
                        <>
                          <Image
                            className="logo-upload-image-wrapper"
                            imageClassName="h-6rem w-6rem flex justify-content-center align-items-center"
                            src={getImage(false, ustanovaChange?.logo!, Images.LOGO_HELIANT)}
                            alt="Image Text"
                          />
                          <Button
                            className="p-button logo-remove-icon"
                            icon="pi pi-trash"
                            onClick={() => {
                              setUstanovaChange({
                                ...ustanovaChange!,
                                logo: "",
                              });
                              logoUploadRef.current?.clear();
                            }}
                          />
                        </>
                      )}
                      {!ustanovaChange?.logo && (
                        <>
                          <div className="h-6rem w-6rem no-logo-uploaded text-center">{Labels.USTANOVA_LOGO_UPLOAD}</div>
                          <div className="flex flex-row align-items-center logo-upload-icon-row">
                            <FileUpload
                              ref={logoUploadRef}
                              auto
                              accept={".jpg, .gif, .png, .jpeg"}
                              mode="basic"
                              multiple={false}
                              customUpload
                              uploadHandler={(e) => {
                                logoUploadHandler(e);
                                logoUploadRef.current?.clear();
                              }}
                              disabled={isDisabled}
                              className="logo-uploader"
                              chooseOptions={{ icon: "pi pi-plus mr-0", iconOnly: true, className: "logo-upload-icon p-0" }}
                              cancelOptions={cancelOptions}
                            />
                            <span className="ml-2">{Labels.USTANOVA_MAX_SIZE_LOGO}</span>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
          </TabPanel>
          {!isCreateDialogOpen && (
            <TabPanel header={Labels.USTANOVA_LICENCE}>
              <UstanovaLicencePage ustanovaId={ustanovaId} ustanova={ustanovaChange} />
            </TabPanel>
          )}
          {!isCreateDialogOpen && (
            <TabPanel header={Labels.PLACANJE_LIST}>
              <UstanovaPlacanjeList ustanovaId={ustanovaId} ustanova={ustanovaChange} />
            </TabPanel>
          )}
          {!isCreateDialogOpen && (
            <TabPanel header={Labels.USTANOVA_FAKTURNI_PODACI}>
              <UstanovaFakturniPodaciView isDisabled={isDisabled} />
            </TabPanel>
          )}
          {!isCreateDialogOpen && (
            <TabPanel header={Labels.USTANOVA_GRID_ZAKAZIVANJA}>
              <UstanovaGridView isDisabled={isDisabled} />
            </TabPanel>
          )}

          {!isCreateDialogOpen && (
            <TabPanel header={Labels.USTANOVA_ULOGE}>
              <UstanovaUlogaListView isDisabled={isDisabled} ustanova={ustanovaChange} />
            </TabPanel>
          )}
          {!isCreateDialogOpen && (
            <TabPanel header={Labels.USTANOVA_TIP_KONTAKTA}>
              <TipKontaktaListPage isDisabled={isDisabled} ustanovaId={ustanovaId} ustanova={ustanovaChange} />
            </TabPanel>
          )}
          {!isCreateDialogOpen && (
            <TabPanel header={Labels.USTANOVA_RADNICI}>
              <UstanovaRadnikListView isDisabled={isDisabled} ustanovaId={ustanovaId} ustanova={ustanovaChange} />
            </TabPanel>
          )}
          {!isCreateDialogOpen && (
            <TabPanel header={Labels.USTANOVA_BRISANJE}>
              <UstanovaBrisanjeView isDisabled={isDisabled} ustanovaId={ustanovaId} ustanova={ustanovaChange} />
            </TabPanel>
          )}
          {!isCreateDialogOpen && ustanovaChange?.nadjimed && (
            <TabPanel header={Labels.USTANOVA_NADJIMED}>
              <UstanovaNadjimedView ustanovaId={ustanovaId} isDisabled={isDisabled} />
            </TabPanel>
          )}
          {!isCreateDialogOpen && (
            <TabPanel header={Labels.USTANOVA_EKSTERNI_SISTEM}>
              <UstanovaEksterniSistemListView isDisabled={isDisabled} />
            </TabPanel>
          )}
        </TabView>
      </Panel>
    </div>
  );
}
