import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import SifarnikLekovaSearchDto from "../../model/sifarnik/sifarnik-lek/SifarnikLekovaSearchDto";

interface SifarnikLekovaNaReceptControllerType {
  axiosSearchSifarnikLekovaNaReceptList: (sifarnikLekovaSearchDto: SifarnikLekovaSearchDto) => Promise<AxiosResponse<any>>;
  axiosGetIstorijuCeneLeka: (lekId: number) => Promise<AxiosResponse<any>>;
  axiosGetIndikacijeLeka: (lekId: number) => Promise<AxiosResponse<any>>;
  axiosCountSifarnikLekovaNaReceptList: (sifarnikLekovaSearchDto: SifarnikLekovaSearchDto) => Promise<AxiosResponse<any>>;
  axiosGetDodatneIndikacijeLeka: (lekId: number) => Promise<AxiosResponse<any>>;
  axiosGetLekById: (lekId: number) => Promise<AxiosResponse<any>>;
  axiosGetLrAtributById: (atributId: number) => Promise<AxiosResponse<any>>;
  axiosGetLrAtributList: () => Promise<AxiosResponse<any>>;
  axiosSearchSifarnikLekovaNaReceptGlobal: (searchData: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetLrAtributListByLekIdAndSifraDijagnoze: (lekId: number, sifraDijagnoze: string) => Promise<AxiosResponse<any>>;
}

export function SifarnikLekovaNaReceptController(): SifarnikLekovaNaReceptControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchSifarnikLekovaNaReceptList = (sifarnikLekovaSearchDto: SifarnikLekovaSearchDto) => {
    return axios.get(`${Endpoint.SIFARNIK_LEKOVA_SEARCH}`, axiosConfig(authData!.token, sifarnikLekovaSearchDto));
  };

  const axiosGetIstorijuCeneLeka = (lekId: number) => {
    return axios.get(`${Endpoint.ISTORIJA_LEKA_CENA}/${lekId}/price-history-list`, axiosConfig(authData!.token));
  };

  const axiosGetIndikacijeLeka = (lekId: number) => {
    return axios.get(`${Endpoint.ISTORIJA_LEKA_CENA}/${lekId}/sifarnik-indikacija-napomena-list`, axiosConfig(authData!.token));
  };

  const axiosGetDodatneIndikacijeLeka = (lekId: number) => {
    return axios.get(`${Endpoint.ISTORIJA_LEKA_CENA}/${lekId}/lek-dijagnoza-list`, axiosConfig(authData!.token));
  };
  const axiosCountSifarnikLekovaNaReceptList = (sifarnikLekovaSearchDto: SifarnikLekovaSearchDto) => {
    return axios.get(`${Endpoint.SIFARNIK_LEKOVA_SEARCH}/count`, axiosConfig(authData!.token, sifarnikLekovaSearchDto));
  };

  const axiosGetLekById = (lekId: number) => {
    return axios.get(`${Endpoint.ISTORIJA_LEKA_CENA}/${lekId}`, axiosConfig(authData!.token));
  };

  const axiosGetLrAtributById = (atributId: number) => {
    return axios.get(`${Endpoint.SIFARNIK_LEKOVA_ATRIBUT}/${atributId}`, axiosConfig(authData!.token));
  };

  const axiosGetLrAtributList = () => {
    return axios.get(`${Endpoint.SIFANIK_LEKOVA_ATRIBUT_LIST}`, axiosConfig(authData!.token));
  };

  const axiosSearchSifarnikLekovaNaReceptGlobal = (searchData: SearchBaseDto) => {
    return axios.get(`${Endpoint.SIFARNIK_LEKOVA_SEARCH_GLOBAL}`, axiosConfig(authData!.token, searchData));
  };

  const axiosGetLrAtributListByLekIdAndSifraDijagnoze = (lekId: number, sifraDijagnoze: string) => {
    return axios.get(`${Endpoint.SIFANIK_LEKOVA_ATRIBUT_LIST}/${lekId}?sifraDijagnoza=${sifraDijagnoze}`, axiosConfig(authData!.token));
  };

  return {
    axiosSearchSifarnikLekovaNaReceptList,
    axiosGetIstorijuCeneLeka,
    axiosGetIndikacijeLeka,
    axiosGetDodatneIndikacijeLeka,
    axiosCountSifarnikLekovaNaReceptList,
    axiosGetLekById,
    axiosGetLrAtributById,
    axiosGetLrAtributList,
    axiosSearchSifarnikLekovaNaReceptGlobal,
    axiosGetLrAtributListByLekIdAndSifraDijagnoze,
  };
}
