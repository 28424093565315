import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EnumControllerRepository } from "../../../controllers/enum/EnumControllerRepository";
import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumEntityStatus from "../../../infrastructure/system/EnumEntityStatus";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SifarnikUslugaRfzoCreateDto from "../../../model/sifarnik/sifarnik-usluga-rfzo/SifarnikUslugaRfzoCreateDto";
import SifarnikUslugaRfzoReadDto from "../../../model/sifarnik/sifarnik-usluga-rfzo/SifarnikUslugaRfzoReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudSifarnikUslugaRfzoLogicalType {
  sifarnikUslugaOperation: string;
  isDisabled: boolean;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  sifarnikUslugaChange: SifarnikUslugaRfzoReadDto | undefined;
  setSifarnikUslugaChange: any;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  sifarnikId: number | undefined;
  location: any;
  onPageFormTable: (rows: number, first: number) => void;
  first: number;
  tableRows: number;
  sifarnikUslugaLoading: boolean;
  entityStatusList: Array<EnumBaseReadDto>;
  entityStatusListLoading: boolean;
  vaziOdRef: HTMLDivElement | any;
  vaziDoRef: HTMLDivElement | any;
}

interface UseParamsType {
  sifarnikId?: string;
}

export default function CrudSifarnikUslugaRfzoLogical(): CrudSifarnikUslugaRfzoLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const navigate = useNavigate();
  const sifarnikId = Number(useParams<keyof UseParamsType>()["sifarnikId"]);
  const location = useLocation();
  const [sifarnikUslugaOperation, setSifarnikUslugaOperation] = useState(
    location.state && location.state.sifarnikUslugaOperation !== undefined ? location.state.sifarnikUslugaOperation : EntityOperation.UPDATE
  );
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const Labels = useLabels();
  const [isDisabled] = useState(isFormDisabled(sifarnikUslugaOperation));
  const [sifarnikUslugaChange, setSifarnikUslugaChange] = useState<SifarnikUslugaRfzoReadDto | undefined>();
  const [index, setIndex] = useState<number>(tabIndex);
  const { axiosSuperadminCreateSifarnikUslugaRFZO, axiosSuperadminUpdateSifarnikUslugaRFZO, axiosSuperadminDeleteSifarnikUslugaRFZO, axiosSuperadminGetSifarnikUslugaRFZO } = SuperadminController();
  const { axiosGetEntityStatusList } = EnumControllerRepository();
  const [first, setFirst] = useState<number>(0);
  const [tableRows, setTableRows] = useState<number>(10);
  const [sifarnikUslugaLoading, setSifarnikUslugaLoading] = useState<boolean>(true);
  const [entityStatusList, setEntityStatusList] = useState<Array<EnumBaseReadDto>>([]);
  const [entityStatusListLoading, setEntityStatusListLoading] = useState<boolean>(true);
  const vaziOdRef = useRef<HTMLDivElement | any>(null);
  const vaziDoRef = useRef<HTMLDivElement | any>(null);

  const postLogHighLevel = useLogHighLevel();

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.SIFARNIK_USLUGA_RFZO,
      command: () => {
        navigate("/repository-sifarnik-usluga-rfzo");
      },
    },
  ]);

  const fetchData = (sifarnikUslugaRFZOID?: number) => {
    const newSifarnikId = sifarnikUslugaRFZOID ? sifarnikUslugaRFZOID : sifarnikId;
    const startTime = moment(new Date());
    if (newSifarnikId) {
      axiosSuperadminGetSifarnikUslugaRFZO(newSifarnikId)
        .then(({ data: { data } }: { data: { data: SifarnikUslugaRfzoReadDto } }) => {
          setSifarnikUslugaChange(data);
          if (breadCrumbItems.length < 2) {
            setBreadCrumbItems([
              ...breadCrumbItems,
              {
                label:
                  sifarnikUslugaOperation === EntityOperation.UPDATE
                    ? Labels.SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_UPDATE + data.naziv
                    : sifarnikUslugaOperation === EntityOperation.READ
                    ? Labels.SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DETAILS + data.naziv
                    : Labels.SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DELETE + data.naziv,
              },
            ]);
          } else if (breadCrumbItems.length === 2) {
            setBreadCrumbItems([
              breadCrumbItems[0],
              {
                label: Labels.SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_UPDATE + data.naziv,
              },
            ]);
          }
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setSifarnikUslugaLoading, startTime);
        });
    } else {
      setSifarnikUslugaLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_CREATE,
        },
      ]);
    }
  };

  useEffectOnce(() => {
    const startTime = moment(new Date());
    axiosGetEntityStatusList()
      .then(({ data: { data: entityStatusList } }: { data: { data: Array<EnumBaseReadDto> } }) => {
        setEntityStatusList(entityStatusList);
        if (sifarnikUslugaOperation === EntityOperation.CREATE) {
          entityStatusList?.forEach((enumStatus: EnumBaseReadDto) => {
            if (enumStatus.sifra === EnumEntityStatus.WORK_IN_PROGRESS) {
              setSifarnikUslugaChange({ ...sifarnikUslugaChange!, entityStatus: enumStatus });
            }
          });
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setEntityStatusListLoading, startTime);
      });

    fetchData();
  });

  const validateInput = (sifarnikUsluga: SifarnikUslugaRfzoReadDto | undefined) => {
    if (validateStringEmpty(sifarnikUsluga?.naziv)) {
      showMessage(MessageType.ERROR, Labels.SIFARNIK_USLUGA_RFZO_NAZIV_IS_REQUIRED);
      return false;
    }

    if (validateStringEmpty(sifarnikUsluga?.sifra)) {
      showMessage(MessageType.ERROR, Labels.SIFARNIK_USLUGA_RFZO_SIFRA_IS_REQUIRED);
      return false;
    }

    if (!sifarnikUsluga?.vaziOd) {
      showMessage(MessageType.ERROR, Labels.SIFARNIK_USLUGA_RFZO_VAZI_OD_IS_REQUIRED);
      return false;
    }

    if (sifarnikUsluga?.vaziDo && sifarnikUsluga?.vaziOd > sifarnikUsluga?.vaziDo) {
      showMessage(MessageType.ERROR, Labels.SIFARNIK_USLUGA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateInput(sifarnikUslugaChange)) {
      return;
    }

    setShowBlockUI(true);
    let newSifarnikUslugaRFZO: SifarnikUslugaRfzoCreateDto | undefined = sifarnikUslugaChange;
    if (newSifarnikUslugaRFZO && !newSifarnikUslugaRFZO?.hasOwnProperty("mozeImatiUtrosak")) {
      newSifarnikUslugaRFZO = { ...newSifarnikUslugaRFZO, mozeImatiUtrosak: false };
    }

    axiosSuperadminCreateSifarnikUslugaRFZO(newSifarnikUslugaRFZO!)
      .then(
        ({
          data: {
            data: { id },
          },
        }: {
          data: { data: { id: number } };
        }) => {
          showMessage(MessageType.SUCCESS, Labels.SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_RFZO_SUCCESS);
          setSifarnikUslugaOperation(EntityOperation.UPDATE);
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_CREATE +
              sifarnikUslugaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_2 +
              sifarnikUslugaChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_3 +
              sifarnikUslugaChange?.entityStatus.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_4 +
              sifarnikUslugaChange?.vaziOd +
              "."
          );
          navigate("/repository-sifarnik-usluga-rfzo");
        }
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onUpdate = () => {
    if (!validateInput(sifarnikUslugaChange)) {
      return;
    }

    setShowBlockUI(true);
    axiosSuperadminUpdateSifarnikUslugaRFZO(sifarnikUslugaChange!)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_RFZO_SUCCESS);
        onCancel();

        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_UPDATE +
            sifarnikUslugaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_2 +
            sifarnikUslugaChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_3 +
            sifarnikUslugaChange?.entityStatus.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_4 +
            sifarnikUslugaChange?.vaziOd +
            "."
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosSuperadminDeleteSifarnikUslugaRFZO(sifarnikId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_RFZO_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_DELETE +
            sifarnikUslugaChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_2 +
            sifarnikUslugaChange?.sifra +
            Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_3 +
            sifarnikUslugaChange?.entityStatus.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_4 +
            sifarnikUslugaChange?.vaziOd +
            "."
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      const breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/repository-sifarnik-usluga-rfzo`);
    }
  };
  const onPageFormTable = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    sifarnikUslugaOperation,
    isDisabled,
    sifarnikUslugaChange,
    breadCrumbItems,
    setSifarnikUslugaChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    sifarnikId,
    location,
    first,
    tableRows,
    onPageFormTable,
    sifarnikUslugaLoading,
    entityStatusList,
    entityStatusListLoading,
    vaziOdRef,
    vaziDoRef,
  };
}
