import { Carousel } from "primereact/carousel";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { useRef, useState } from "react";
import { useLabels } from "../../../Store";
import { getImage, getScrollNumber } from "../../../infrastructure/system/Utils";
import useEffectWithoutWarnings from "../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import RadnikSkracenoReadDto from "../../../model/radnik/RadnikSkracenoReadDto";
import Radnik from "../termini/radnik/Radnik";

const responsiveOptions = [
  {
    breakpoint: "1660px",
    numVisible: 5,
    numScroll: 5,
  },
  {
    breakpoint: "1460px",
    numVisible: 4,
    numScroll: 4,
  },
  {
    breakpoint: "1260px",
    numVisible: 3,
    numScroll: 3,
  },
  {
    breakpoint: "1060px",
    numVisible: 2,
    numScroll: 2,
  },
  {
    breakpoint: "860px",
    numVisible: 1,
    numScroll: 1,
  },
];

interface RadnikListType {
  radnikList: Array<RadnikSkracenoReadDto>;
  radnikListIsLoading: boolean;
  selectedRadnik: RadnikSkracenoReadDto | undefined;
  setSelectedRadnik: React.Dispatch<React.SetStateAction<RadnikSkracenoReadDto | undefined>>;
  prikaziNeaktivne: boolean;
  setPrikaziNeaktivne: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RadnikList(props: RadnikListType) {
  const Labels = useLabels();
  const { radnikList, radnikListIsLoading, selectedRadnik, setSelectedRadnik, prikaziNeaktivne, setPrikaziNeaktivne } = props;
  const [radnikSearch, setRadnikSearch] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const radnikFilteredList =
    radnikSearch.length > 0 ? radnikList.filter((radnik: RadnikSkracenoReadDto) => (radnik.ime + " " + radnik.prezime).toUpperCase().includes(radnikSearch.toUpperCase())) : radnikList;

  const scrollNumber = getScrollNumber();
  const radnikCarouselRef = useRef<HTMLDivElement>(null);

  const headerTemplate = () => {
    return (
      <div className="p-panel-header py-2">
        <span className="p-panel-title">{Labels.LISTA_RADNIKA_LISTA_DOKTORA}</span>
        <div className="grid mt-0 pr-3">
          <div className="flex align-items-center mr-3">
            <span className="mr-3">{Labels.PRIKAZI_NEAKTIVNE_RADNIKE}</span>
            <InputSwitch checked={prikaziNeaktivne} onChange={(e) => setPrikaziNeaktivne(e.value!)} />
          </div>
          <span className="p-input-icon-left ml-2">
            <i className="pi pi-search" />
            <InputText value={radnikSearch} onChange={(e) => setRadnikSearch(e.target.value)} placeholder={Labels.LISTA_RADNIKA_PRETRAGA_PLACEHOLDER} />
          </span>
        </div>
      </div>
    );
  };

  const itemTemplate = (radnik: RadnikSkracenoReadDto) => {
    const selectedClass = radnik.id === selectedRadnik?.id ? "selectedRadnik" : "";
    return (
      <div className={`radnikItem ${selectedClass}`} key={radnik.id} onClick={() => setSelectedRadnik(radnik)}>
        <Radnik slika={getImage(false, radnik.profilnaSlika!, undefined)} ime={radnik.ime} prezime={radnik.prezime} titula={radnik.titula} neaktivan={radnik?.viewNeaktivni} />
      </div>
    );
  };

  useEffectWithoutWarnings(() => {
    getPage();
  }, [radnikList]);

  const getPage = () => {
    let pageCarousel = 0;
    if (radnikList.length) {
      const selectedRadnikIndex = radnikList?.findIndex((radnik: RadnikSkracenoReadDto) => radnik.id === selectedRadnik?.id);
      let scrollNumber = getScrollNumber();
      pageCarousel = Math.floor(selectedRadnikIndex / scrollNumber);
    }
    setPage(pageCarousel);
  };

  const carouselRef = useRef<Carousel>(null);

  return (
    <Panel headerTemplate={headerTemplate}>
      <div ref={radnikCarouselRef}>
        {radnikListIsLoading ? (
          <Skeleton height="78px" />
        ) : (
          <Carousel
            ref={carouselRef}
            numScroll={scrollNumber}
            numVisible={scrollNumber}
            value={radnikFilteredList}
            responsiveOptions={responsiveOptions}
            itemTemplate={itemTemplate}
            indicatorsContentClassName="lista-doktora"
            page={page}
          />
        )}
      </div>
    </Panel>
  );
}
