import { AxiosResponse } from "axios";
import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import IzvestajController from "../../../controllers/izvestaj/IzvestajController";
import { KategorijaStatistickogIzvestajaController } from "../../../controllers/izvestaj/KategorijaStatistickogIzvestajaController";
import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import IzvestajSimpleReadDto from "../../../model/izvestaj/IzvestajSimpleReadDto";
import KategorijaStatistickogIzvestajSimpleReadDto from "../../../model/izvestaj/kategorija/KategorijaStatistickogIzvestajaReadDto";

interface IzvestajListLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  izvestajLoading: boolean;
  first: number;
  tableRows: number;
  kategorijaList: Array<KategorijaStatistickogIzvestajSimpleReadDto>;
  izvestajList: Array<IzvestajSimpleReadDto>;
  changeIzvestajSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  searchIzvestajByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  izvestajSearchData: SearchBaseDto;
  fetchData: () => void;
  onPageIzvestaj: (rows: number, first: number) => void;
  closeDialog: () => void;
  openDialog: () => void;
  isDialogOpen: boolean;
  repositoryIzvestajLoading: boolean;
  repositoryIzvestajList?: Array<IzvestajSimpleReadDto>;
  selectedKategorijaStatistickogIzvestaja: Array<KategorijaStatistickogIzvestajSimpleReadDto>;
  setSelectedKategorijaStatistickogIzvestaja: React.Dispatch<React.SetStateAction<Array<KategorijaStatistickogIzvestajSimpleReadDto>>>;
  selectedColumns: Array<IzvestajSimpleReadDto>;
  setSelectedColumns: React.Dispatch<React.SetStateAction<Array<IzvestajSimpleReadDto>>>;
  importStatistickiIzvestaj: () => void;
  firstRepository: number;
  tableRepositoryRows: number;
  onPageRepositoryIzvestaj: (rows: number, first: number) => void;
}

export default function IzvestajListLogical(isSuperAdmin?: boolean): IzvestajListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();

  const [izvestajLoading, setIzvestajLoading] = useState<boolean>(false);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [kategorijaList, setKategorijaList] = useState<Array<KategorijaStatistickogIzvestajSimpleReadDto>>([]);
  const [izvestajSearchData, setIzvestajSearchData] = useState<SearchBaseDto>({});
  const [izvestajList, setIzvestajList] = useState<Array<IzvestajSimpleReadDto>>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<Array<IzvestajSimpleReadDto>>([]);
  const [selectedKategorijaStatistickogIzvestaja, setSelectedKategorijaStatistickogIzvestaja] = useState<Array<KategorijaStatistickogIzvestajSimpleReadDto>>([]);
  const [repositoryIzvestajLoading, setRepositoryIzvestajLoading] = useState<boolean>(true);
  const [repositoryIzvestajList, setRepositoryIzvestajList] = useState<Array<IzvestajSimpleReadDto>>([]);
  const [firstRepository, setFirstRepository] = useState(0);
  const [tableRepositoryRows, setTableRepositoryRows] = useState(10);

  const { axiosSearchKategorija } = KategorijaStatistickogIzvestajaController();
  const { axiosSearchIzvestaj, axiosRepositoryPublicSearchIzvestaj, axiosRepositoryPublicCopyIzvestaj } = IzvestajController();
  const { axiosSuperadminGetStatistickiIzvestajList, axiosSuperadminGetKategorijeStatistickihIzvestajaList } = SuperadminController();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.IZVESTAJ_LIST,
    },
  ];

  useEffectOnce(() => {
    (isSuperAdmin ? axiosSuperadminGetKategorijeStatistickihIzvestajaList() : axiosSearchKategorija())
      .then((res: AxiosResponse) => {
        setKategorijaList(res.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {});
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setIzvestajLoading(true);
    (isSuperAdmin ? axiosSuperadminGetStatistickiIzvestajList(izvestajSearchData) : axiosSearchIzvestaj(izvestajSearchData))
      .then((res: AxiosResponse) => {
        setIzvestajList(res.data.data);
        setFirst(0);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setIzvestajLoading, startTime);
      });
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedColumns([]);
    setSelectedKategorijaStatistickogIzvestaja([]);
  };
  const importStatistickiIzvestaj = () => {
    let statistickiIzvestajIdList: Array<number> = selectedColumns.map((izvestaj: IzvestajSimpleReadDto) => izvestaj.id);
    let kategorijaStastistickogIzvestajaIdList: Array<number> = selectedKategorijaStatistickogIzvestaja.map(
      (kategorijaStatistickogIzvestaja: KategorijaStatistickogIzvestajSimpleReadDto) => kategorijaStatistickogIzvestaja.id
    );
    axiosRepositoryPublicCopyIzvestaj({ statistickiIzvestajIdList: statistickiIzvestajIdList, kategorijaStastistickogIzvestajaIdList: kategorijaStastistickogIzvestajaIdList })
      .then(() => {
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        closeDialog();
      });
  };
  const openDialog = () => {
    setIsDialogOpen(true);
    setRepositoryIzvestajLoading(true);
    const startTime = moment(new Date());
    axiosRepositoryPublicSearchIzvestaj({})
      .then((res: { data: { data: Array<IzvestajSimpleReadDto> } }) => {
        setRepositoryIzvestajList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setRepositoryIzvestajLoading, startTime);
      });
  };
  const changeIzvestajSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setIzvestajSearchData({
      ...izvestajSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchIzvestajByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageIzvestaj = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };
  const onPageRepositoryIzvestaj = (rows: number, first: number) => {
    setTableRepositoryRows(rows);
    setFirstRepository(first);
  };
  return {
    closeDialog,
    isDialogOpen,
    openDialog,
    repositoryIzvestajLoading,
    repositoryIzvestajList,
    breadCrumbItems,
    izvestajLoading,
    first,
    tableRows,
    kategorijaList,
    izvestajList,
    changeIzvestajSearchData,
    searchIzvestajByEnter,
    izvestajSearchData,
    fetchData,
    onPageIzvestaj,
    selectedKategorijaStatistickogIzvestaja,
    setSelectedKategorijaStatistickogIzvestaja,
    selectedColumns,
    setSelectedColumns,
    importStatistickiIzvestaj,
    firstRepository,
    tableRepositoryRows,
    onPageRepositoryIzvestaj,
  };
}
