import { AxiosResponse } from "axios";
import moment from "moment/moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { KategorijaStatistickogIzvestajaController } from "../../../../controllers/izvestaj/KategorijaStatistickogIzvestajaController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../../model/SearchBaseDto";
import KategorijaStatistickogIzvestajReadDto from "../../../../model/izvestaj/kategorija/KategorijaStatistickogIzvestajaReadDto";

interface KategorijaStatistickihIzvestajaListLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  fetchData: () => void;
  kategorijaSearchData: SearchBaseDto;
  changeKategorijaSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  searchKategorijaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  kategorijaList: Array<KategorijaStatistickogIzvestajReadDto>;
  first: number;
  tableRows: number;
  onPageKategorija: (rows: number, first: number) => void;
  kategorijaLoading: boolean;
}

export default function KategorijeStatistickihIzvestajaListLogical(isSuperAdmin?: boolean): KategorijaStatistickihIzvestajaListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const breadCrumbItems = [
    {
      label: Labels.IZVESTAJ_LIST,
    },
  ];

  const [kategorijaSearchData, setKategorijaSearchData] = useState<SearchBaseDto>({});
  const [kategorijaList, setKategorijaList] = useState<Array<KategorijaStatistickogIzvestajReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [kategorijaLoading, setKategorijaLoading] = useState<boolean>(false);

  const { axiosSearchKategorija } = KategorijaStatistickogIzvestajaController();
  const { axiosSuperadminGetKategorijeStatistickihIzvestajaList } = SuperadminController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setKategorijaLoading(true);
    (isSuperAdmin ? axiosSuperadminGetKategorijeStatistickihIzvestajaList(kategorijaSearchData) : axiosSearchKategorija(kategorijaSearchData))
      .then((res: AxiosResponse) => {
        setKategorijaList(res.data.data);
        setFirst(0);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setKategorijaLoading, startTime);
      });
  };

  const changeKategorijaSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setKategorijaSearchData({
      ...kategorijaSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchKategorijaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageKategorija = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    breadCrumbItems,
    fetchData,
    kategorijaSearchData,
    changeKategorijaSearchData,
    searchKategorijaByEnter,
    kategorijaList,
    first,
    tableRows,
    onPageKategorija,
    kategorijaLoading,
  };
}
