const QueryKeys = {
  SIFARNIK_KATEGORIJA_POPUSTA: "sifarnikKategorijaPopustaList",
  TIP_KATEGORIJE_POPUSTA: "tipKategorijePopustaList",
  NAMENA_KATEGORIJE_POPUSTA: "namenaKategorijePopustaList",
  ZAKAZAN_TERMIN_DATOTEKA_LIST: "zakazanTerminDatotekaList",
  USLUGA_CENA_LIST: "uslugaCenaList",
  PROIZVOD_CENA_LIST: "proizvodCenaList",
  PARTNER_LIST: "partnerList",
  UPLATILAC_LIST: "uplatilacList",
  KONTAKT_NAPLATA_LIST: "kontaktNaplataList",
  PACIJENT_LIST: "pacijentList",
  KONTAKT_PLACEN_LIST: "kontaktPlacenList",
  ORGANIZACIONA_JEDINICA_LIST: "organizacionaJedinicaList",
  ORGANIZACIONA_JEDINICA_TIP_LIST: "organizacionaJedinicaTipList",
  RADNIK_LIST: "radnikList",
  KONTAKT_TIP_LIST: "kontaktTipList",
  SIFARNIK_NACIN_PLACANJA_LIST: "sifarnikNacinPlacanjaList",
  VALUTA_LIST: "valutaList",
  PORESKA_STOPA_LIST: "poreskaStopaList",
  PRODAJNI_PAKETI_LIST: "prodajniPaketiList",
  BROJ_NALOGA_LIST: "brojNalogaList",
  STORAGE_LIMIT_LIST: "storageLimitList",
  ENUM_PERIOD_PLACANJA_LIST: "enumPeriodPlacanjaList",
  PRODAJNI_PAKET: "prodajniPaket",
  RADNI_DAN_LIST: "radniDanList",
  TRAJANJE_TERMINA_LIST: "trajanjeTerminaList",
  JEZIK_LIST: "jezikList",
  USTANOVA: "ustanova",
  USTANOVA_GRID: "ustanovaGrid",
  POL_LIST: "polList",
  ZAKAZAN_TERMIN_TIP_LIST: "zakazanTerminTipList",
  OBAVESTENJE_TIP_LIST: "obavestenjeTipList",
  ULOGA_LIST: "ulogaList",
  INICIJALIZACIJA_RADNIK_LIST: "inicijalizacijaRadnikList",
  SIFARNIK_USLUGA_LIST: "sifarnikUslugaList",
  TIP_PREGLEDA_LIST: "tipPregledaList",
  INICIJALIZACIJA_TIP_PREGLEDA_LIST: "inicijalizacijaTipPregledaList",
  INICIJALIZACIJA_ENUM_LIST: "inicijalizacijaEnumList",
  PARAMETAR_GLOBALAN_LIST: "parametarGlobalanList",
  SIFARNIK_KATEGORIJA_GLOBALNIH_PARAMETARA: "sifarnikKategorijaGlobalnihParametaraList",
  GLOBALNA_DATOTEKA_LIST: "globalnaDatotekaList",
  PACIJENT_POLJE_KATEGORIJA_LIST: "pacijentPoljeKategorijaList",
  ZAKAZAN_TERMIN_LIST: "zakazanTerminList",
  ZAKAZAN_TERMIN_KOMENTAR_LIST: "zakazanTerminKomentarList",
  VERZIJA_LIST: "verzijaList",
  ZAKAZAN_TERMIN_STATUS_LIST: "zakazanTerminStatusList",
};

export default QueryKeys;
