import { InputNumber } from "primereact/inputnumber";
import { PoljeType } from "../Polje";

export default function Celobrojni(props: PoljeType) {
  const { id, onChange, vrednost, readonly, vrednostList, useVrednostList } = props;

  return (
    <InputNumber
      disabled={readonly ?? false}
      className="w-full"
      onChange={(e) => {
        onChange({ id: id, vrednost: e.value ?? undefined });
      }}
      value={useVrednostList && vrednostList ? vrednostList[id] ?? null : vrednost ?? null}
    />
  );
}
