import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import FaktorRizikaIkonicaReadDto from "../../model/faktor-rizika/FaktorRizikaIkonicaReadDto";
import FaktorRizikaIkonicaUpdateDto from "../../model/faktor-rizika/FaktorRizikaIkonicaUpdateDto";
import FaktorRizikaReadDto from "../../model/faktor-rizika/FaktorRizikaReadDto";
import FaktorRizikaUpdateDto from "../../model/faktor-rizika/FaktorRizikaUpdateDto";
import FaktorRizikaUstanovaReadDto from "../../model/faktor-rizika/FaktorRizikaUstanovaReadDto";

interface FaktorRizikaControllerType {
  axiosGetAllFaktorRizika: (faktorRizikaSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosGetFaktorRizikaById: (faktorRizikaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateFaktorRizika: (faktorRizikaId: number, faktorRizikaChange: FaktorRizikaUpdateDto) => Promise<AxiosResponse<any>>;
  axiosGetAllFaktorRizikaIkonica: (faktorRizikaIkonicaSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosCreateFaktorRizika: (faktorRizikaChange: FaktorRizikaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteFaktorRizika: (faktorRizikaId: number) => Promise<AxiosResponse<any>>;
  axiosGetFaktorRizikaIkonicaById: (faktorRizikaIkonicaId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteFaktorRizikaIkonica: (faktorRizikaIkonicaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateFaktorRizikaIkonica: (faktorRizikaIkonicaId: number, faktorRizikaIkonicaChange: FaktorRizikaIkonicaUpdateDto) => Promise<AxiosResponse<any>>;
  axiosCreateFaktorRizikaIkonica: (faktorRizikaIkonicaChange: FaktorRizikaIkonicaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteFaktorRizikaUstanova: (faktorRizikaUstanovaId: number, checkBeforeDelete?: boolean) => Promise<AxiosResponse<any>>;
  axiosGetAllFaktorRizikaUstanova: () => Promise<AxiosResponse<any>>;
  axiosCreateFaktorRizikaUstanova: (faktorRizikaUstanova: FaktorRizikaUstanovaReadDto) => Promise<AxiosResponse<any>>;
}

export default function FaktorRizikaController(): FaktorRizikaControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetAllFaktorRizika = (faktorRizikaSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.FAKTOR_RIZIKA_SEARCH, axiosConfig(authData!.token, faktorRizikaSearchData));
  };

  const axiosGetFaktorRizikaById = (faktorRizikaId: number) => {
    return axios.get(`${Endpoint.FAKTOR_RIZIKA_LIST}/${faktorRizikaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateFaktorRizika = (faktorRizikaId: number, faktorRizikaChange: FaktorRizikaUpdateDto) => {
    return axios.put(`${Endpoint.FAKTOR_RIZIKA_LIST}/${faktorRizikaId}`, faktorRizikaChange, axiosConfig(authData!.token));
  };

  const axiosGetAllFaktorRizikaIkonica = (faktorRizikaIkonicaSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.FAKTOR_RIZIKA_IKONICA_SEARCH, axiosConfig(authData!.token, faktorRizikaIkonicaSearchData));
  };

  const axiosCreateFaktorRizika = (faktorRizikaChange: FaktorRizikaReadDto) => {
    return axios.post(Endpoint.FAKTOR_RIZIKA_LIST, faktorRizikaChange, axiosConfig(authData!.token));
  };

  const axiosDeleteFaktorRizika = (faktorRizikaId: number) => {
    return axios.delete(`${Endpoint.FAKTOR_RIZIKA_LIST}/${faktorRizikaId}`, axiosConfig(authData!.token));
  };

  const axiosGetFaktorRizikaIkonicaById = (faktorRizikaIkonicaId: number) => {
    return axios.get(`${Endpoint.FAKTOR_RIZIKA_IKONICA_LIST}/${faktorRizikaIkonicaId}`, axiosConfig(authData!.token));
  };

  const axiosDeleteFaktorRizikaIkonica = (faktorRizikaIkonicaId: number) => {
    return axios.delete(`${Endpoint.FAKTOR_RIZIKA_IKONICA_LIST}/${faktorRizikaIkonicaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateFaktorRizikaIkonica = (faktorRizikaIkonicaId: number, faktorRizikaIkonicaChange: FaktorRizikaIkonicaUpdateDto) => {
    return axios.put(`${Endpoint.FAKTOR_RIZIKA_IKONICA_LIST}/${faktorRizikaIkonicaId}`, faktorRizikaIkonicaChange, axiosConfig(authData!.token));
  };

  const axiosCreateFaktorRizikaIkonica = (faktorRizikaIkonicaChange: FaktorRizikaIkonicaReadDto) => {
    return axios.post(Endpoint.FAKTOR_RIZIKA_IKONICA_LIST, faktorRizikaIkonicaChange, axiosConfig(authData!.token));
  };

  const axiosDeleteFaktorRizikaUstanova = (faktorRizikaUstanovaId: number, checkBeforeDelete?: boolean) => {
    return axios.delete(`${Endpoint.FAKTOR_RIZIKA_LIST_USTANOVA}/${faktorRizikaUstanovaId}`, axiosConfig(authData!.token, { checkBeforeDelete: checkBeforeDelete }));
  };

  const axiosGetAllFaktorRizikaUstanova = () => {
    return axios.get(Endpoint.FAKTOR_RIZIKA_LIST_USTANOVA, axiosConfig(authData!.token));
  };

  const axiosCreateFaktorRizikaUstanova = (faktorRizikaUstanova: FaktorRizikaUstanovaReadDto) => {
    return axios.post(Endpoint.FAKTOR_RIZIKA_LIST_USTANOVA, faktorRizikaUstanova, axiosConfig(authData!.token));
  };
  return {
    axiosGetAllFaktorRizika,
    axiosGetFaktorRizikaById,
    axiosUpdateFaktorRizika,
    axiosGetAllFaktorRizikaIkonica,
    axiosCreateFaktorRizika,
    axiosDeleteFaktorRizika,
    axiosGetFaktorRizikaIkonicaById,
    axiosDeleteFaktorRizikaIkonica,
    axiosUpdateFaktorRizikaIkonica,
    axiosCreateFaktorRizikaIkonica,
    axiosDeleteFaktorRizikaUstanova,
    axiosGetAllFaktorRizikaUstanova,
    axiosCreateFaktorRizikaUstanova,
  };
}
