import moment, { Moment } from "moment";
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { PacijentController } from "../../../../controllers/pacijent/PacijentController";
import { RadniPeriodController } from "../../../../controllers/radni-period/RadniPeriodController";
import { ZakazaniTerminController } from "../../../../controllers/zakazani-termin/ZakazaniTerminController";
import EnumPacijentPoljeKategorija from "../../../../infrastructure/system/EnumPacijentPoljeKategorija";
import EnumTerminTip from "../../../../infrastructure/system/EnumTerminTip";
import EnumTipKomponente from "../../../../infrastructure/system/EnumTipKomponente";
import {
  DATE_TIME_FORMAT_2,
  DATE_TIME_FORMAT_3,
  TIME_FORMAT,
  formatDate,
  formatDate2,
  handleAxiosCallError,
  isEmailFormatValid,
  isTelefonValid,
  useEffectOnce,
} from "../../../../infrastructure/system/Utils";
import { useZakazanTerminTipList } from "../../../../infrastructure/system/hooks/enum/useZakazanTerminTipList";
import { usePacijentPoljeKategorijaList } from "../../../../infrastructure/system/hooks/pacijent-polje-kategorija/usePacijentPoljeKategorijaList";
import { useSifarnikUslugaList } from "../../../../infrastructure/system/hooks/sifarnici/usluga/useSifarnikUslugaList";
import useEffectWithoutWarnings from "../../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import MandatoryBaseEntityDto from "../../../../model/MandatoryBaseEntityDto";
import TipKontaktaReadDto from "../../../../model/kontakt/TipKontaktaReadDto";
import OrganizacionaJedinicaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import PacijentPoljeKategorijaReadDto from "../../../../model/pacijent-polje/PacijentPoljeKategorijaReadDto";
import PacijentPoljePopunjeno from "../../../../model/pacijent-polje/PacijentPoljePopunjeno";
import PacijentCreateDto from "../../../../model/pacijent/PacijentCreateDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import PacijentUpdateDto from "../../../../model/pacijent/PacijentUpdateDto";
import { default as SifarnikUslugaDto, default as SifarnikUslugaReadDto } from "../../../../model/sifarnik-usluga/SifarnikUslugaReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import ZakazanTermin from "../../../../model/zakazan-termin/ZakazanTermin";
import ZakazanTerminCreateDto from "../../../../model/zakazan-termin/ZakazanTerminCreateDto";
import ZakazanTerminReadDto from "../../../../model/zakazan-termin/ZakazanTerminReadDto";
import ZakazanTerminUslugaReadDto from "../../../../model/zakazan-termin/ZakazanTerminUslugaReadDto";
import ZakazanTerminDatotekaReadDto from "../../../../model/zakazan-termin/datoteke/ZakazanTerminDatotekaReadDto";
import KomentarReadDto from "../../../../model/zakazan-termin/komentari/KomentarReadDto";
import { valueMap } from "../../../formular/FormularLogical";
import { useZakazanTerminDatotekaListForZakazanTermin } from "../../../pacijent-dosije/dokumentacija/lista-datoteka/hooks/useZakazanTerminDatotekaForZakazanTermin";
import { DropdownOptionType } from "../TerminiLogical";
import { useZakazanTerminKomentarList } from "../hooks/useZakazanTerminKomentarList";
import { TerminType } from "../termin/Termin";

interface DialogTerminLogicalType {
  pacijentOdabran?: PacijentReadDto;
  setPacijentOdabran: Dispatch<SetStateAction<PacijentReadDto | undefined>>;
  actionSubmit: () => void;
  noviPacijent?: PacijentCreateDto;
  setNoviPacijent: Dispatch<SetStateAction<PacijentCreateDto | undefined>>;
  kontaktTipList: Array<TipKontaktaReadDto>;
  zakazanTerminLocal: ZakazanTerminReadDto | undefined;
  zakazanTerminTipList: Array<EnumBaseReadDto>;
  brisanjeZakazanogTermin: () => void;
  pacijentId: string | undefined;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  pacijentIzmenjen: boolean;
  invalidFields: { [field: string]: boolean } | undefined;
  setInvalidFields: Dispatch<SetStateAction<{ [field: string]: boolean } | undefined>>;
  pacijentExists: boolean;
  setPacijentExists: Dispatch<SetStateAction<boolean>>;
  warningMessageRef: RefObject<HTMLDivElement>;
  pacijentInfoRef: RefObject<HTMLDivElement>;
  zakazanTerminKomentarList: Array<KomentarReadDto>;
  zakazanTerminDatotekaList: Array<ZakazanTerminDatotekaReadDto>;
  sifarnikUslugaList: SifarnikUslugaDto[];
  chosenUslugaList: Array<SifarnikUslugaDto>;
  addUslugaInChosenUslugaList: (usluga: SifarnikUslugaReadDto) => void;
  removeUslugaFromChosenUslugaList: (usluga: SifarnikUslugaReadDto) => void;
  fillPacijentPolja: (pacijent: MandatoryBaseEntityDto) => void;
  pacijentRef: MutableRefObject<PacijentCreateDto | undefined>;
  datum?: Date;
  setDatum: Dispatch<SetStateAction<Date | undefined>>;
  setZakazanTerminLocal: Dispatch<SetStateAction<ZakazanTerminReadDto | undefined>>;
  terminInfoRef: RefObject<HTMLDivElement>;
}
interface DialogTerminLogicalProps {
  zakazanTermin?: ZakazanTerminReadDto;
  dohvatiTermine: () => void;
  closeDialogTermin: () => void;
  trajanjeTermina: number;
  vanredni: boolean;
  zakazanTerminStatusList: Array<EnumBaseReadDto>;
  odabranaOrgJed: OrganizacionaJedinicaReadDto | undefined;
  zakazanTerminVremeDoOptions: Array<DropdownOptionType>;
  setZakazanTerminVremeDoOptions: Dispatch<SetStateAction<Array<DropdownOptionType>>>;
  prikaziNeaktivne: boolean;
  setVanredni: React.Dispatch<React.SetStateAction<boolean>>;
  isVirtuelniRadniPeriodi?: boolean;
  makeSatnicaList: (
    zakazaniTerminList: TerminType[],
    radniDanPocetak: moment.Moment,
    radniDanKraj: moment.Moment,
    radniPeriodList: TerminType[],
    trajanjeTermina: number,
    vertical: boolean
  ) => TerminType[];
  zakazanTerminList?: ZakazanTerminReadDto[];
  radnoVremeOdOrgJed?: moment.Moment;
  radnoVremeDoOrgJed?: moment.Moment;
  kontaktTipList: TipKontaktaReadDto[];
}

interface UseParamsType {
  pacijentId?: string;
}

export default function DialogTerminLogical(props: DialogTerminLogicalProps): DialogTerminLogicalType {
  const {
    zakazanTermin,
    dohvatiTermine,
    closeDialogTermin,
    setVanredni,
    vanredni,
    zakazanTerminStatusList,
    odabranaOrgJed,
    zakazanTerminVremeDoOptions,
    setZakazanTerminVremeDoOptions,
    prikaziNeaktivne,
    isVirtuelniRadniPeriodi,
    makeSatnicaList,
    zakazanTerminList,
    radnoVremeOdOrgJed,
    radnoVremeDoOrgJed,
    trajanjeTermina,
    kontaktTipList,
  } = props;
  const { axiosCheckPacijentExists, axiosGetPacijentPoljePopunjenoList, axiosGetPacijentById } = PacijentController();
  const { axiosGetZakazanTerminById, axiosPostKreiranjeZakazaniTermini, axiosDeleteZakazaniTermini, axiosPutIzmenaZakazaniTermini, axiosPostKreiranjeZakazaniVanredniTermin } =
    ZakazaniTerminController();
  const Labels = useLabels();
  const pacijentId = useParams<keyof UseParamsType>()["pacijentId"];

  const [pacijentOdabran, setPacijentOdabran] = useState<PacijentReadDto>();
  const [noviPacijent, setNoviPacijent] = useState<PacijentCreateDto>();
  const [zakazanTerminLocal, setZakazanTerminLocal] = useState<ZakazanTerminReadDto | undefined>(zakazanTermin);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean }>();
  const [pacijentExists, setPacijentExists] = useState<boolean>(false);
  const [datum, setDatum] = useState<Date>();

  const warningMessageRef = useRef<HTMLDivElement>(null);
  const pacijentRef = useRef<PacijentCreateDto>();
  const pacijentInfoRef = useRef<HTMLDivElement>(null);
  const terminInfoRef = useRef<HTMLDivElement>(null);
  const { axiosGetRadniPeriodForSpecifiedMinutesPeriod } = RadniPeriodController();

  const [chosenUslugaList, setChosenUslugaList] = useState<Array<SifarnikUslugaDto>>([]);

  const { zakazanTerminTipList } = useZakazanTerminTipList();
  const { pacijentPoljeKategorijaList } = usePacijentPoljeKategorijaList(EnumPacijentPoljeKategorija.ZAKAZIVANJE);
  const { sifarnikUslugaList } = useSifarnikUslugaList();
  const { zakazanTerminKomentarList } = useZakazanTerminKomentarList(zakazanTerminLocal);
  const { zakazanTerminDatotekaList } = useZakazanTerminDatotekaListForZakazanTermin(zakazanTerminLocal?.id);

  const navigate = useNavigate();

  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const postLogHighLevel = useLogHighLevel();

  let replacer = (_: any, value: any) => (value === null || value === "" ? undefined : value);

  function fillPacijentPolja(pacijent: MandatoryBaseEntityDto) {
    if (!pacijent.id) return;
    axiosGetPacijentPoljePopunjenoList(EnumPacijentPoljeKategorija.ZAKAZIVANJE, pacijent.id).then((res) => {
      const poljeVrednost: valueMap = {};
      res.data.data.forEach((pacijentPoljePopunjeno: PacijentPoljePopunjeno) => {
        if (
          pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.TEXT ||
          pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.LONG_TEXT
        ) {
          poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostString;
        } else if (
          pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.NUMBER_DECIMAL ||
          pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.NUMBER_INTEGER
        ) {
          poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostNumber;
        } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.DATE_TIME) {
          poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
        } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.DATE) {
          poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
        } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.TIME) {
          poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
        } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.BOOLEAN) {
          poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostBoolean;
        } else if (
          pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET ||
          pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET_RADIO
        ) {
          poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno?.vrednostEnumeratorItem?.id;
        } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET_MULTISELECT) {
          if (poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] !== undefined) {
            poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = [...poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id], pacijentPoljePopunjeno?.vrednostEnumeratorItem];
          } else {
            poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = [pacijentPoljePopunjeno?.vrednostEnumeratorItem];
          }
        }
      });
      if (Object.keys(poljeVrednost).length) {
        setPacijentOdabran((prevPacijentOdabran) => ({ ...prevPacijentOdabran!, polja: poljeVrednost }));
        if (pacijentRef.current) pacijentRef.current.polja = poljeVrednost;
      } else {
        setPacijentOdabran((prevPacijentOdabran) => ({ ...prevPacijentOdabran!, polja: {} }));
        if (pacijentRef.current) pacijentRef.current.polja = poljeVrednost;
      }
    });
  }

  const pacijentIzmenjen =
    pacijentRef.current !== undefined &&
    pacijentOdabran !== undefined &&
    JSON.stringify(JSON.parse(JSON.stringify(pacijentRef.current), replacer)) !== JSON.stringify(JSON.parse(JSON.stringify(pacijentOdabran), replacer));

  useEffectOnce(() => {
    if (pacijentId) {
      axiosGetPacijentById(Number(pacijentId))
        .then((res) => {
          setPacijentOdabran(res.data.data);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  });

  useEffect(() => {
    if (zakazanTermin?.id) {
      axiosGetZakazanTerminById(zakazanTermin.id)
        .then((response) => {
          const data = response.data.data;
          pacijentRef.current = data.pacijent;
          setPacijentOdabran(data.pacijent);
          fillPacijentPolja(data.pacijent);
          setZakazanTerminLocal({
            ...zakazanTermin,
            kontakt: data.kontakt,
            radnikKreirao: data.radnikKreirao,
            eksterniZakazivacID: data.eksterniZakazivacID,
            partner: data.partner,
            eksterniCaseID: data.eksterniCaseID,
            kontaktTip: data.kontaktTip,
            zakazanTerminStatus: data.zakazanTerminStatus || zakazanTerminStatusList[0],
            radnik: data.radnik,
          });

          if (data.planiranaUslugaList?.length > 0) {
            let planiraneUslugeList: Array<SifarnikUslugaDto> = [];
            data.planiranaUslugaList.forEach((zakazanTerminUsluga: ZakazanTerminUslugaReadDto) => {
              planiraneUslugeList.push(zakazanTerminUsluga.sifarnikUsluga);
            });
            setChosenUslugaList(planiraneUslugeList);
          }
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    } else {
      setZakazanTerminLocal(zakazanTermin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zakazanTermin?.id, zakazanTerminStatusList]);

  useEffect(() => {
    setInvalidFields(undefined);
    setNoviPacijent(undefined);
    setPacijentOdabran(undefined);
  }, [activeIndex]);

  useEffect(() => {
    warningMessageRef.current?.scrollIntoView();
  }, [pacijentExists]);

  useEffectWithoutWarnings(() => {
    if (
      zakazanTerminVremeDoOptions?.length > 0 &&
      (!zakazanTerminVremeDoOptions.some((option) => moment(option.value).format(TIME_FORMAT) === moment(zakazanTerminLocal?.vremeZakazanoDo).format(TIME_FORMAT)) ||
        !zakazanTerminLocal?.id ||
        (zakazanTerminLocal.kontaktTip.vremeTrajanjaKontakta !== undefined && zakazanTerminLocal.kontaktTip.id !== zakazanTermin?.kontaktTip.id))
    ) {
      setZakazanTerminLocal((prev) => ({ ...prev!, vremeZakazanoDo: zakazanTerminVremeDoOptions[0].value }));
    }
  }, [zakazanTerminVremeDoOptions]);

  useEffect(() => {
    checkPacijentExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noviPacijent?.polTrenutni, noviPacijent?.vremeRodjenja]);

  const createDropdownOptionsForKontaktTipVremeTrajanja = (period: number, minutesPeriod: number) => {
    const dropdownOptions: DropdownOptionType[] = [];
    const brojVremeDo = (minutesPeriod ?? 180) / period;
    const brojVremeDoRounded = Math.floor(brojVremeDo);

    for (let index = 0; index < brojVremeDoRounded; index++) {
      const dropdownOption = {
        label: moment(zakazanTerminLocal?.vremeZakazanoOd)
          .add((index + 1) * period, "minutes")
          .format(TIME_FORMAT),
        value: moment(zakazanTerminLocal?.vremeZakazanoOd)
          .add((index + 1) * period, "minutes")
          .toDate(),
      };
      dropdownOptions.push(dropdownOption);
    }
    if (zakazanTerminLocal?.id && !dropdownOptions.some((option) => option.label === moment(zakazanTerminLocal?.vremeZakazanoDo).format(TIME_FORMAT))) {
      const existingOption = {
        label: moment(zakazanTerminLocal?.vremeZakazanoDo).format(TIME_FORMAT),
        value: moment(zakazanTerminLocal?.vremeZakazanoDo).toDate(),
      };
      dropdownOptions.push(existingOption);
    }
    const sortedOptions = dropdownOptions.sort((a, b) => {
      return a.value.getTime() - b.value.getTime();
    });

    return sortedOptions;
  };

  useEffectWithoutWarnings(() => {
    if (zakazanTerminLocal?.radnik && zakazanTerminLocal.kontaktTip && !vanredni) {
      if (isVirtuelniRadniPeriodi) {
        radnoVremeOdOrgJed && radnoVremeDoOrgJed && zakazanTerminLocal.vremeZakazanoOd && createVremeDoOptionsFromCalendar(zakazanTerminLocal, radnoVremeOdOrgJed, radnoVremeDoOrgJed);
      } else {
        createVremeDoOptionsFromApi(zakazanTerminLocal);
      }
    }
  }, [zakazanTerminLocal?.radnik, zakazanTerminLocal?.kontaktTip, zakazanTerminLocal?.vremeZakazanoOd, isVirtuelniRadniPeriodi]);

  const createVremeDoOptionsFromApi = (zakazanTerminLocal: ZakazanTerminReadDto) => {
    const radniPeriodSearch = {
      organizacionaJedinicaID: odabranaOrgJed?.id,
      radnikID: zakazanTerminLocal.radnik?.id,
      datumOd: formatDate(zakazanTerminLocal.vremeZakazanoOd as Date, DATE_TIME_FORMAT_3),
      viewNeaktivni: prikaziNeaktivne && zakazanTerminLocal.radnik?.viewNeaktivni,
    };

    if (radniPeriodSearch.organizacionaJedinicaID && radniPeriodSearch.radnikID && radniPeriodSearch.datumOd) {
      const minutesToMidnight = moment(radniPeriodSearch.datumOd).endOf("day").diff(moment(radniPeriodSearch.datumOd), "minutes");
      axiosGetRadniPeriodForSpecifiedMinutesPeriod(Math.min(minutesToMidnight, 180), radniPeriodSearch)
        .then((response) => {
          const radniPeriodiFromApi = response.data.data.map((termin) => {
            return { label: moment(termin.periodKraj).format(TIME_FORMAT), value: moment(termin.periodKraj).toDate() };
          });

          if (!zakazanTerminLocal.kontaktTip.vremeTrajanjaKontakta) {
            setZakazanTerminVremeDoOptions([...radniPeriodiFromApi]);
            return;
          }

          const lastItemEndTime = response.data.data.length > 0 ? response.data.data.slice(-1)[0].periodKraj : undefined;
          const zakazanOd = moment(zakazanTerminLocal?.vremeZakazanoOd);
          const zakazanDo = moment(lastItemEndTime);
          const dropdownOptions = createDropdownOptionsForKontaktTipVremeTrajanja(zakazanTerminLocal?.kontaktTip?.vremeTrajanjaKontakta?.vrednost, zakazanDo.diff(zakazanOd, "minutes"));
          setZakazanTerminVremeDoOptions(dropdownOptions);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const createVremeDoOptionsFromCalendar = (zakazanTermin: ZakazanTerminReadDto, radnoVremeOd: Moment, radnoVremeDo: Moment) => {
    const ztList = zakazanTerminList?.map((zt) => ({ ...zt, vremeZakazanoOd: moment(zt.vremeZakazanoOd), vremeZakazanoDo: moment(zt.vremeZakazanoDo) })) ?? [];

    const satnica = makeSatnicaList(
      ztList,
      moment(moment(zakazanTermin?.vremeZakazanoOd).hour(radnoVremeOd.hour()).minute(radnoVremeOd.minute()).toDate()),
      moment(moment(zakazanTermin?.vremeZakazanoOd).hour(radnoVremeDo.hour()).minute(radnoVremeDo.minute()).toDate()),
      [],
      trajanjeTermina,
      true
    );
    const slotoviInNextThreeHours = satnica.filter(
      (termin) =>
        termin.vremeZakazanoDo.isAfter(moment(zakazanTermin?.vremeZakazanoOd)) && termin.vremeZakazanoDo.diff(moment(zakazanTermin?.vremeZakazanoOd), "minutes") <= 180 && !termin.disabledClick
    );
    const nextZakazanTermin = slotoviInNextThreeHours.find((slot) => !!slot.id && slot.id !== zakazanTermin?.id);
    const slotoviPreSledecegZakazanogTermina = nextZakazanTermin
      ? slotoviInNextThreeHours.filter((slot) => slot.vremeZakazanoDo.isSameOrBefore(moment(nextZakazanTermin?.vremeZakazanoOd)))
      : slotoviInNextThreeHours;

    if (!zakazanTermin?.kontaktTip?.vremeTrajanjaKontakta) {
      setZakazanTerminVremeDoOptions(
        slotoviPreSledecegZakazanogTermina.map((termin) => ({ label: moment(termin.vremeZakazanoDo).format(TIME_FORMAT), value: moment(termin.vremeZakazanoDo).toDate() }))
      );
      return;
    }
    const lastItemEndTime = slotoviPreSledecegZakazanogTermina.at(-1);
    const zakazanOd = moment(zakazanTermin?.vremeZakazanoOd);
    const zakazanDo = moment(lastItemEndTime?.vremeZakazanoDo);
    const dropdownOptions = createDropdownOptionsForKontaktTipVremeTrajanja(zakazanTermin.kontaktTip.vremeTrajanjaKontakta.vrednost, zakazanDo.diff(zakazanOd, "minutes"));
    setZakazanTerminVremeDoOptions(dropdownOptions);
  };

  const checkPacijentExists = () => {
    if (noviPacijent?.ime && noviPacijent.prezime && noviPacijent.polTrenutni && noviPacijent.vremeRodjenja) {
      axiosCheckPacijentExists({
        ime: noviPacijent.ime,
        prezime: noviPacijent.prezime,
        polTrenutniSifra: noviPacijent.polTrenutni.sifra,
        datumRodjenja: formatDate2(noviPacijent.vremeRodjenja as Date),
      })
        .then((res) => {
          setPacijentExists(res.data.data.pacijentExists);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const validateInput = (params: ZakazanTerminCreateDto) => {
    const { radnik, pacijent } = params;
    let isInvalid = false;

    if (!radnik?.id) {
      setInvalidFields((prev) => ({ ...prev, radnik: true }));
      isInvalid = true;
    }
    if (activeIndex === 0 && !(pacijent as PacijentUpdateDto)) {
      setInvalidFields((prev) => ({ ...prev, pacijent: true }));
      isInvalid = true;
    }
    if (!(pacijent as PacijentUpdateDto)?.ime) {
      setInvalidFields((prev) => ({ ...prev, ime: true }));
      isInvalid = true;
    }
    if (!(pacijent as PacijentUpdateDto)?.prezime) {
      setInvalidFields((prev) => ({ ...prev, prezime: true }));
      isInvalid = true;
    }
    if (!(pacijent as PacijentUpdateDto)?.polTrenutni) {
      setInvalidFields((prev) => ({ ...prev, pol: true }));
      isInvalid = true;
    }
    if (!(pacijent as PacijentUpdateDto)?.vremeRodjenja) {
      setInvalidFields((prev) => ({ ...prev, vremeRodjenja: true }));
      isInvalid = true;
    }
    if ((pacijent as PacijentUpdateDto)?.email && !isEmailFormatValid((pacijent as PacijentUpdateDto)?.email)) {
      setInvalidFields((prev) => ({ ...prev, invalidEmail: true }));
      isInvalid = true;
    }
    if ((pacijent as PacijentUpdateDto)?.telefon && !isTelefonValid((pacijent as PacijentUpdateDto)?.telefon)) {
      setInvalidFields((prev) => ({ ...prev, invalidTelefon: true }));
      isInvalid = true;
    }
    if (vanredni && !zakazanTerminLocal?.vremeZakazanoOd) {
      setInvalidFields((prev) => ({ ...prev, vanredniVremeOd: true }));
      isInvalid = true;
    }
    if (vanredni && !zakazanTerminLocal?.vremeZakazanoDo) {
      setInvalidFields((prev) => ({ ...prev, vanredniVremeDo: true }));
      isInvalid = true;
    }
    if (vanredni && zakazanTerminLocal?.vremeZakazanoOd && zakazanTerminLocal?.vremeZakazanoDo && zakazanTerminLocal?.vremeZakazanoOd >= zakazanTerminLocal?.vremeZakazanoDo) {
      setInvalidFields((prev) => ({ ...prev, vremeEquals: true }));
      isInvalid = true;
    }

    if (
      vanredni &&
      (!zakazanTerminLocal?.vremeZakazanoOd ||
        !zakazanTerminLocal?.vremeZakazanoDo ||
        (zakazanTerminLocal?.vremeZakazanoOd && zakazanTerminLocal?.vremeZakazanoDo && zakazanTerminLocal?.vremeZakazanoOd >= zakazanTerminLocal?.vremeZakazanoDo))
    ) {
      terminInfoRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (
      !(pacijent as PacijentUpdateDto)?.ime ||
      !(pacijent as PacijentUpdateDto)?.prezime ||
      !(pacijent as PacijentUpdateDto)?.polTrenutni ||
      !(pacijent as PacijentUpdateDto)?.vremeRodjenja ||
      ((pacijent as PacijentUpdateDto)?.email && !isEmailFormatValid((pacijent as PacijentUpdateDto)?.email)) ||
      ((pacijent as PacijentUpdateDto)?.telefon && !isTelefonValid((pacijent as PacijentUpdateDto)?.telefon))
    ) {
      pacijentInfoRef?.current?.scrollIntoView({ behavior: "smooth" });
    }

    return !isInvalid;
  };

  const filterPacijentReadOnly = (pacijentPoljeList: Array<PacijentPoljeKategorijaReadDto>, pacijent?: PacijentReadDto) => {
    if (pacijent && pacijent.polja) {
      pacijentPoljeList.forEach((pacijentPolje: PacijentPoljeKategorijaReadDto) => {
        if (pacijentPolje.readOnly && pacijent.polja && pacijent.polja[pacijentPolje.pacijentPolje.id] !== undefined) {
          delete pacijent.polja[pacijentPolje.pacijentPolje.id];
        }
      });
    }
    return pacijent;
  };
  const kreirajTermin = () => {
    const zakazanTerminCreate: ZakazanTerminCreateDto = {
      radnik: zakazanTerminLocal?.radnik,
      pacijent: activeIndex === 0 && pacijentOdabran?.id ? filterPacijentReadOnly(pacijentPoljeKategorijaList, pacijentOdabran) : noviPacijent,
      organizacionaJedinica: odabranaOrgJed,
      vremeZakazanoOd: zakazanTerminLocal?.vremeZakazanoOd ? moment(zakazanTerminLocal.vremeZakazanoOd).format(DATE_TIME_FORMAT_3) : undefined,
      vremeZakazanoDo: zakazanTerminLocal?.vremeZakazanoDo ? moment(zakazanTerminLocal.vremeZakazanoDo).format(DATE_TIME_FORMAT_3) : undefined,
      zakazanTerminStatus: zakazanTerminLocal?.zakazanTerminStatus,
      zakazanTerminTip: zakazanTerminTipList.find((zt) => zt.sifra === (vanredni ? EnumTerminTip.VANREDAN : EnumTerminTip.REDOVAN))!,
      kontaktTip: zakazanTerminLocal?.kontaktTip,
      planiranaUslugaList: chosenUslugaList.map((chosenUsluga) => ({ sifarnikUsluga: { id: chosenUsluga.id } })),
    };

    if (!validateInput(zakazanTerminCreate) || !zakazanTerminCreate.pacijent) {
      return;
    }

    setShowBlockUI(true);
    vanredni
      ? axiosPostKreiranjeZakazaniVanredniTermin(zakazanTerminCreate)
          .then(() => {
            reset();
            postLogHighLevel(
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_DIALOG_TERMIN_1 +
                zakazanTerminLocal?.kontaktTip?.naziv +
                Labels.LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_1 +
                moment(zakazanTerminCreate?.vremeZakazanoOd ?? new Date()).format(DATE_TIME_FORMAT_2) +
                Labels.LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_2 +
                moment(zakazanTerminCreate?.vremeZakazanoDo ?? new Date()).format(DATE_TIME_FORMAT_2) +
                ".",
              zakazanTerminCreate.pacijent?.id
            );
          })
          .catch((error) => {
            handleAxiosCallError(showMessage, error);
          })
          .finally(() => {
            setShowBlockUI(false);
            setVanredni(false);
          })
      : axiosPostKreiranjeZakazaniTermini(zakazanTerminCreate)
          .then(() => {
            reset();
            postLogHighLevel(
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_DIALOG_TERMIN_1 +
                zakazanTerminLocal?.kontaktTip?.naziv +
                Labels.LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_1 +
                moment(zakazanTerminCreate?.vremeZakazanoOd ?? new Date()).format(DATE_TIME_FORMAT_2) +
                Labels.LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_2 +
                moment(zakazanTerminCreate?.vremeZakazanoDo ?? new Date()).format(DATE_TIME_FORMAT_2) +
                ".",
              zakazanTerminCreate.pacijent?.id
            );
          })
          .catch((error) => {
            handleAxiosCallError(showMessage, error);
          })
          .finally(() => {
            setShowBlockUI(false);
          });
  };

  const izmeniTermin = () => {
    const zakazanTerminUpdate: ZakazanTerminCreateDto = {
      radnik: zakazanTerminLocal?.radnik,
      pacijent: activeIndex === 0 && pacijentOdabran?.id ? filterPacijentReadOnly(pacijentPoljeKategorijaList, pacijentOdabran) : noviPacijent,
      organizacionaJedinica: odabranaOrgJed,
      vremeZakazanoOd: zakazanTerminLocal?.vremeZakazanoOd ? moment(zakazanTerminLocal.vremeZakazanoOd).format(DATE_TIME_FORMAT_3) : undefined,
      vremeZakazanoDo: zakazanTerminLocal?.vremeZakazanoDo ? moment(zakazanTerminLocal.vremeZakazanoDo).format(DATE_TIME_FORMAT_3) : undefined,
      zakazanTerminStatus: zakazanTerminLocal?.zakazanTerminStatus,
      zakazanTerminTip: zakazanTerminTipList.find((zt) => zt.sifra === (vanredni ? EnumTerminTip.VANREDAN : EnumTerminTip.REDOVAN))!,
      kontaktTip: zakazanTerminLocal?.kontaktTip,
      planiranaUslugaList: chosenUslugaList.map((chosenUsluga) => ({ sifarnikUsluga: { id: chosenUsluga.id } })),
    };

    if (!validateInput(zakazanTerminUpdate)) {
      return;
    }

    setShowBlockUI(true);
    axiosPutIzmenaZakazaniTermini(Number(zakazanTerminLocal?.id), zakazanTerminUpdate)
      .then(() => {
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_1 +
            zakazanTerminLocal?.kontaktTip?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_1 +
            moment(zakazanTerminUpdate?.vremeZakazanoOd ?? new Date()).format(DATE_TIME_FORMAT_2) +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_2 +
            moment(zakazanTerminUpdate?.vremeZakazanoDo ?? new Date()).format(DATE_TIME_FORMAT_2) +
            ".",
          zakazanTerminUpdate.pacijent?.id
        );
        reset();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const actionSubmit = () => {
    if (zakazanTermin?.id) {
      izmeniTermin();
    } else {
      kreirajTermin();
    }
  };

  const reset = () => {
    if (pacijentId) {
      navigate("/zakazivanje-pregleda");
    }
    closeDialogTermin();
    dohvatiTermine();
  };

  const brisanjeZakazanogTermin = () => {
    if (zakazanTerminLocal) {
      const zakazanTerminDelete = new ZakazanTermin(
        zakazanTerminLocal?.radnik!,
        pacijentOdabran?.id ? { id: pacijentOdabran.id } : noviPacijent!,
        odabranaOrgJed!,
        moment(zakazanTerminLocal?.vremeZakazanoOd ?? new Date()).format(DATE_TIME_FORMAT_3),
        moment(zakazanTerminLocal?.vremeZakazanoDo ?? new Date()).format(DATE_TIME_FORMAT_3),
        zakazanTerminLocal?.zakazanTerminStatus!,
        zakazanTerminTipList.find((zt: EnumBaseReadDto) => zt.sifra === (vanredni ? EnumTerminTip.VANREDAN : EnumTerminTip.REDOVAN))!,
        zakazanTerminLocal?.kontaktTip
      );
      setShowBlockUI(true);
      axiosDeleteZakazaniTermini(zakazanTerminLocal.id)
        .then(() => {
          closeDialogTermin();
          dohvatiTermine();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_DIALOG_TERMIN_1 +
              zakazanTerminLocal?.kontaktTip?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_1 +
              moment(zakazanTerminDelete?.vremeZakazanoOd ?? new Date()).format(DATE_TIME_FORMAT_2) +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_2 +
              moment(zakazanTerminDelete?.vremeZakazanoDo ?? new Date()).format(DATE_TIME_FORMAT_2) +
              ".",
            zakazanTerminDelete?.pacijent?.id
          );
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const addUslugaInChosenUslugaList = (usluga: SifarnikUslugaReadDto) => {
    const uslugaNotInList = chosenUslugaList.every((sifarnikUslugaDto: SifarnikUslugaDto) => sifarnikUslugaDto.id !== usluga.id);
    if (uslugaNotInList) {
      setChosenUslugaList([...chosenUslugaList, usluga]);
    }
  };

  const removeUslugaFromChosenUslugaList = (usluga: SifarnikUslugaReadDto) => {
    const chosenUslugaListTmp = chosenUslugaList.filter((e) => e.naziv !== usluga.naziv);
    setChosenUslugaList(chosenUslugaListTmp);
  };

  return {
    pacijentOdabran,
    setPacijentOdabran,
    actionSubmit,
    noviPacijent,
    setNoviPacijent,
    kontaktTipList,
    zakazanTerminLocal,
    zakazanTerminTipList,
    brisanjeZakazanogTermin,
    pacijentId,
    activeIndex,
    setActiveIndex,
    pacijentIzmenjen,
    invalidFields,
    setInvalidFields,
    pacijentExists,
    setPacijentExists,
    warningMessageRef,
    pacijentInfoRef,
    zakazanTerminKomentarList,
    zakazanTerminDatotekaList,
    sifarnikUslugaList,
    chosenUslugaList,
    addUslugaInChosenUslugaList,
    removeUslugaFromChosenUslugaList,
    fillPacijentPolja,
    pacijentRef,
    datum,
    setDatum,
    setZakazanTerminLocal,
    terminInfoRef,
  };
}
