import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import ReceptCreateDto from "../../model/kontakt/ReceptCreateDto";
import TerapijaFillerSearchDto from "../../model/kontakt/TerapijaFillerSearchDto";

interface ReceptControllerType {
  axiosGetReceptListByKontaktID: (kontaktId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteRecept: (receptId: number) => Promise<AxiosResponse<any>>;
  axiosPostRecept: (params: ReceptCreateDto) => Promise<AxiosResponse<any>>;
  axiosDopuniTerapiju: (terapijaFillerSearchDto: TerapijaFillerSearchDto) => Promise<AxiosResponse<any>>;
  axiosGetReceptPrint: (receptId: number) => Promise<AxiosResponse>;
}

export function ReceptController(): ReceptControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetReceptListByKontaktID = (kontaktId: number) => {
    return axios.get(`${Endpoint.RECEPT_LIST_KONTAKT}/${kontaktId}`, axiosConfig(authData!.token));
  };

  const axiosDeleteRecept = (receptId: number) => {
    return axios.delete(`${Endpoint.RECEPT}/${receptId}`, axiosConfig(authData!.token));
  };

  const axiosPostRecept = (params: ReceptCreateDto) => {
    return axios.post(Endpoint.RECEPT, params ? params : {}, axiosConfig(authData!.token));
  };

  const axiosDopuniTerapiju = (terapijaFillerSearchDto: TerapijaFillerSearchDto) => {
    return axios.get(`${Endpoint.RECEPT_FILL}`, axiosConfig(authData!.token, terapijaFillerSearchDto));
  };

  const axiosGetReceptPrint = (receptId: number) => {
    return axios.get(`${Endpoint.RECEPT}/${receptId}/print`, axiosConfig(authData!.token));
  };

  return {
    axiosGetReceptListByKontaktID,
    axiosDeleteRecept,
    axiosPostRecept,
    axiosDopuniTerapiju,
    axiosGetReceptPrint,
  };
}
