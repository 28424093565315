import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { SifarnikUslugaController } from "../../../controllers/sifarnik-usluga/SifarnikUslugaController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import SifarnikUslugaReadDto from "../../../model/sifarnik-usluga/SifarnikUslugaReadDto";
import { AppContext, useLabels } from "./../../../Store";

interface SifarnikUslugaListLogicalType {
  sifarnikUslugaList: Array<SifarnikUslugaReadDto>;
  first: number;
  tableRows: number;
  changeSifarnikUslugaSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  sifarnikUslugaSearchData: SearchBaseDto;
  fetchData: () => void;
  searchSifarnikUslugaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  onPageSifarnikUsluga: (rows: number, first: number) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  sifarnikUslugaLoading: boolean;
}

export default function SifarnikUslugaListLogical(): SifarnikUslugaListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [sifarnikUslugaList, setSifarnikUslugaList] = useState<Array<SifarnikUslugaReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [sifarnikUslugaSearchData, setSifarnikUslugaSearchData] = useState<SearchBaseDto>({});
  const [sifarnikUslugaLoading, setSifarnikUslugaLoading] = useState(false);

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LABEL_SIFARNIK_USLUGA,
    },
  ];
  const { axiosSearchSifarnikUsluga } = SifarnikUslugaController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setSifarnikUslugaLoading(true);
    axiosSearchSifarnikUsluga(sifarnikUslugaSearchData)
      .then((res: any) => {
        setSifarnikUslugaList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((sifarnikUsluga: SifarnikUslugaReadDto) => {
          exportList.push({
            [Labels.SIFARNIK_USLUGA_SIFRA]: sifarnikUsluga.sifra,
            [Labels.SIFARNIK_USLUGA_NAZIV]: sifarnikUsluga.naziv,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSifarnikUslugaLoading, startTime);
      });
  };

  const changeSifarnikUslugaSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSifarnikUslugaSearchData({
      ...sifarnikUslugaSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchSifarnikUslugaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageSifarnikUsluga = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    sifarnikUslugaList,
    first,
    tableRows,
    changeSifarnikUslugaSearchData,
    sifarnikUslugaSearchData,
    fetchData,
    searchSifarnikUslugaByEnter,
    onPageSifarnikUsluga,
    exportData,
    breadCrumbItems,
    sifarnikUslugaLoading,
  };
}
