import { Calendar } from "primereact/calendar";
import { RefObject, useContext, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import ProtokolControler from "../../../../controllers/protokol/ProtokolController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { useEffectOnce } from "../../../../infrastructure/system/Utils";
import KontaktProtokolBrojRequestReadDto from "../../../../model/kontakt/KontaktProtokolBrojRequestReadDto";
import KontaktProtokolBrojResponseReadDto from "../../../../model/kontakt/KontaktProtokolBrojResponseReadDto";
import OrganizacionaJedinicaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import ProtokolReadDto from "../../../../model/protokol/ProtokolReadDto";

interface ProtokolBrojListLogicalType {
  protokolBrojList: Array<KontaktProtokolBrojResponseReadDto>;
  protokolBrojListLoading: boolean;
  protokolBrojSearch: KontaktProtokolBrojRequestReadDto | undefined;
  protokolList: Array<ProtokolReadDto>;
  organizacionaJedinicaList: Array<OrganizacionaJedinicaReadDto>;
  setProtokolBrojSearch: React.Dispatch<React.SetStateAction<KontaktProtokolBrojRequestReadDto | undefined>>;
  openProtokolBroj: (entityOperation: string, data: KontaktProtokolBrojResponseReadDto) => void;
  first: number;
  tableRows: number;
  dt: any;
  fetchProtokol: () => void;
  searchKontaktProtokolBroj: () => void;
  onPage: (rows: number, first: number) => void;
  datumOdRef: RefObject<Calendar>;
  datumDoRef: RefObject<Calendar>;
}

export default function ProtokolBrojListLogical(): ProtokolBrojListLogicalType {
  const location = useLocation();
  const { showMessage } = useContext(AppContext);

  const [protokolBrojList, setProtokolBrojList] = useState<Array<KontaktProtokolBrojResponseReadDto>>([]);
  const [protokolBrojListLoading, setProtokolBrojListLoading] = useState<boolean>(false);
  const [protokolBrojSearch, setProtokolBrojSearch] = useState<KontaktProtokolBrojRequestReadDto | undefined>(location.state?.protokolBrojSearch);
  const [protokolList, setProtokolList] = useState<Array<ProtokolReadDto>>([]);
  const [organizacionaJedinicaList, setOrganizacionaJedinicaList] = useState<Array<OrganizacionaJedinicaReadDto>>([]);
  const navigate = useNavigate();
  const { axiosGetKontaktBrojProtokolaList, axiosSearchProtokol } = ProtokolControler();
  const { axiosSearchOrganizacionaJedinica } = OrganizacionaJedinicaController();
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const dt = useRef<any>(null);
  const datumOdRef = useRef<Calendar>(null);
  const datumDoRef = useRef<Calendar>(null);

  const Labels = useLabels();

  useEffectOnce(() => {
    fetchProtokol();
    fetchOrganizacionaJedinica();
    protokolBrojSearch && searchKontaktProtokolBroj();
  });

  const fetchProtokol = () => {
    axiosSearchProtokol().then(({ data: { data } }: { data: { data: Array<ProtokolReadDto> } }) => {
      setProtokolList(data);
    });
  };

  const fetchOrganizacionaJedinica = () => {
    axiosSearchOrganizacionaJedinica().then(({ data: { data } }: { data: { data: Array<OrganizacionaJedinicaReadDto> } }) => {
      setOrganizacionaJedinicaList(data);
    });
  };

  const onPage = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const validateInput = (protokolBrojSearch: KontaktProtokolBrojRequestReadDto | undefined) => {
    if (protokolBrojSearch === undefined || protokolBrojSearch.protokol === undefined) {
      showMessage(MessageType.ERROR, Labels.PROTOKOL_BROJ_PROTOKOL_REQUIRED);
      return false;
    }
    return true;
  };
  const searchKontaktProtokolBroj = () => {
    if (!validateInput(protokolBrojSearch)) {
      return;
    }
    setProtokolBrojListLoading(true);
    axiosGetKontaktBrojProtokolaList(protokolBrojSearch!)
      .then(({ data: { data } }: { data: { data: Array<KontaktProtokolBrojResponseReadDto> } }) => {
        setProtokolBrojList(data);
      })
      .finally(() => {
        setProtokolBrojListLoading(false);
      });
  };

  const openProtokolBroj = (entityOperation: string, data: KontaktProtokolBrojResponseReadDto) => {
    navigate(`/crud-protokol-broj/${data.protokolBroj.id}`, {
      state: { protokolOperation: entityOperation, protokolBrojChange: data, protokolBrojSearch: protokolBrojSearch },
    });
  };

  return {
    protokolBrojList,
    protokolBrojListLoading,
    protokolBrojSearch,
    protokolList,
    organizacionaJedinicaList,
    setProtokolBrojSearch,
    openProtokolBroj,
    first,
    tableRows,
    dt,
    fetchProtokol,
    searchKontaktProtokolBroj,
    onPage,
    datumOdRef,
    datumDoRef,
  };
}
