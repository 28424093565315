import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import PaketProizvodReadDto from "../../../../model/paket/PaketProizvodReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import DialogPaketProizvod from "./DialogPaketProizvod";
import PaketProizvodListLogical from "./PaketProizvodListLogical";

export default function PaketProizvodkListView() {
  const {
    paketProizvodLoading,
    paketProizvodList,
    first,
    tableRows,
    onPagePaketProizvod,
    isDialogPaketProizvodOpen,
    openDialogPaketProizvod,
    closeDialogPaketProizvod,
    paketProizvodChange,
    setPaketProizvodChange,
    searchSifarnikProizvoda,
    sifarnikProizvodaList,
    dialogAction,
    paketProizvod,
    onCreate,
    onDelete,
    onUpdate,
  } = PaketProizvodListLogical();

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const { pristup } = useContext(AppContext);

  const crudBodyTemplate = (rowPaketProizvod: PaketProizvodReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.UTROSAK_IZMENI}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowPaketProizvod && openDialogPaketProizvod(EntityOperation.UPDATE, rowPaketProizvod)}
          disabled={!pristup}
        />
        <Button
          type="button"
          tooltip={Labels.UTROSAK_OBRISI}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowPaketProizvod && openDialogPaketProizvod(EntityOperation.DELETE, rowPaketProizvod)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const toolbarLeftContent = () => {
    return (
      <>
        <Button
          label={Labels.UTROSAK_NOVI}
          tooltip={Labels.UTROSAK_NOVI}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => openDialogPaketProizvod(EntityOperation.CREATE, undefined)}
          disabled={!pristup}
        />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_PRIKAZNI_REDOSLED, filter: true, sortrable: true },
    { columnName: Labels.HEADER_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.HEADER_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.HEADER_PODRAZUMEVANA_KOLICINA, filter: true, sortrable: true },
    { columnName: Labels.HEADER_NAPOMENA, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <Toolbar left={toolbarLeftContent} />
        {paketProizvodLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={paketProizvodList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPagePaketProizvod(e.rows, e.first);
            }}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"prikazniRedosled"} header={Labels.HEADER_PRIKAZNI_REDOSLED} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifarnikProizvoda.naziv"} header={Labels.HEADER_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifarnikProizvoda.sifra"} header={Labels.HEADER_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"podrazumevanaUtrosenaKolicina"} header={Labels.HEADER_PODRAZUMEVANA_KOLICINA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"napomena"} header={Labels.HEADER_NAPOMENA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
        <DialogPaketProizvod
          show={isDialogPaketProizvodOpen}
          closeDialog={closeDialogPaketProizvod}
          paketProizvodChange={paketProizvodChange}
          setPaketProizvodChange={setPaketProizvodChange}
          searchSifarnikProizvoda={searchSifarnikProizvoda}
          sifarnikProizvodaList={sifarnikProizvodaList}
          dialogAction={dialogAction}
          paketProizvod={paketProizvod}
          onCreate={onCreate}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      </Panel>
    </div>
  );
}
