import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toolbar } from "primereact/toolbar";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../Store";
import EntityOperation from "../../infrastructure/system/EnumEntityOperation";
import { tableDateFilter, tableDateFilterGreater, tableDateFilterLess, tooltipOptionsBottom, tooltipOptionsTop } from "../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../infrastructure/system/hooks/PageDropdownTemplate";
import IzvozReadDto from "../../model/izvoz/IzvozReadDto";
import CalendarFilter from "../administracija/calendar-component/CalendarFilter";
import SkeletonTable, { SkeletonTableColumnProperty } from "../app/skeleton/SkeletonTable";
import ExportPodatakaLogical from "./ExportListLogical";

interface ExportListProps {
  isSuperAdmin: boolean;
}

export default function ExportList(props: ExportListProps) {
  const { isSuperAdmin } = props;
  const {
    breadCrumbItems,
    izvozLoading,
    first,
    tableRows,
    onPageIzvoz,
    izvozList,
    dialogRef,
    renderDate,
    renderVremeKreiranja,
    renderVremeGenerisanja,
    renderImePrezime,
    onIzvozDownload,
    onIzvozGenerate,
  } = ExportPodatakaLogical(isSuperAdmin);

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMini } = PageDropdownTemplate();

  const createIzvoz = () => {
    navigate(`/crud-izvoz`, {
      state: { izvozOperation: EntityOperation.CREATE },
    });
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.IZVOZ_NOVI_ZAHTEV}
          tooltip={Labels.IZVOZ_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            createIzvoz();
          }}
          disabled={!pristup}
        />
      </>
    );
  };

  const datumPeriodOd = (izvoz: IzvozReadDto) => {
    return <div>{renderDate(izvoz.periodOd)}</div>;
  };

  const datumPeriodDo = (izvoz: IzvozReadDto) => {
    return <div>{renderDate(izvoz.periodDo)}</div>;
  };
  const dateFilterTemplate = (options: any) => <CalendarFilter options={options} />;

  const spinnerStyle = useMemo(() => {
    return { width: "2.5rem", height: "2.5rem" };
  }, []);

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.USTANOVA, filter: true, sortrable: true },
    { columnName: Labels.IZVOZ_IME_PREZIME_ZAHTEVAOCA, filter: true, sortrable: true },
    { columnName: Labels.IZVOZ_ZAHTEVANO, filter: true, sortrable: true },
    { columnName: Labels.IZVOZ_PERIOD_OD, filter: true, sortrable: true },
    { columnName: Labels.IZVOZ_PERIOD_DO, filter: true, sortrable: true },
    { columnName: Labels.IZVOZ_RAZLOG_ZAHTEVA, filter: true, sortrable: true },
    { columnName: isSuperAdmin ? Labels.IZVOZ_GENERISANO : Labels.IZVOZ_DATUM_GENERISANJA, filter: true, sortrable: true },
  ];

  let columnsPropertyWithoutUstanova: Array<SkeletonTableColumnProperty> = [];
  if (!isSuperAdmin) {
    columnsPropertyWithoutUstanova = columnsProperty.filter((item) => item.columnName !== Labels.USTANOVA);
  }

  const crudBodyTemplate = (rowExportZahtev: IzvozReadDto) => {
    return (
      <div className="flex justify-content-end">
        {rowExportZahtev.vremeGenerisanja && !rowExportZahtev.downloadUToku && (
          <Button
            type="button"
            tooltip={Labels.IZVOZ_DOWNLOAD}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-info mr-2"
            icon="pi pi-download"
            onClick={() => onIzvozDownload(rowExportZahtev)}
            disabled={!pristup}
          />
        )}
        {rowExportZahtev.downloadUToku && <ProgressSpinner style={spinnerStyle} className="mr-2" strokeWidth="4" animationDuration="2s" />}
        {!rowExportZahtev.vremeGenerisanja && !rowExportZahtev.generisanjeUToku && isSuperAdmin && (
          <Button
            type="button"
            tooltip={Labels.IZVOZ_GENERISI}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-success mr-2"
            icon="pi pi-fw pi-external-link"
            onClick={() => onIzvozGenerate(rowExportZahtev)}
            disabled={!pristup}
          />
        )}
        {rowExportZahtev.generisanjeUToku && <ProgressSpinner style={spinnerStyle} className="mr-2" strokeWidth="4" animationDuration="2s" />}
      </div>
    );
  };

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        {!isSuperAdmin && <Toolbar left={searchToolbar} />}
        {izvozLoading ? (
          <SkeletonTable dataTableColumnsProperty={!isSuperAdmin ? columnsPropertyWithoutUstanova : columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dialogRef}
            filterDisplay="row"
            value={izvozList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMini as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageIzvoz(e.rows, e.first);
            }}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"ustanova.naziv"} header={Labels.USTANOVA} filter filterMatchMode="contains" showFilterMenu={false} sortable hidden={!isSuperAdmin} />
            <Column field={"radnikKreirao.ime"} body={renderImePrezime} header={Labels.IZVOZ_IME_PREZIME_ZAHTEVAOCA} filter filterMatchMode="contains" showFilterMenu={false} sortable />

            <Column
              header={isSuperAdmin ? Labels.IZVOZ_ZAHTEVANO : Labels.IZVOZ_DATUM_ZAHTEVA}
              field={"vremeKreiranja"}
              body={renderVremeKreiranja}
              filter
              filterElement={dateFilterTemplate}
              filterMatchMode="custom"
              filterFunction={tableDateFilter}
              sortable
              showFilterMenu={false}
            />
            <Column
              header={Labels.IZVOZ_PERIOD_OD}
              field={"periodOd"}
              body={datumPeriodOd}
              filter
              filterElement={dateFilterTemplate}
              filterMatchMode="custom"
              filterFunction={tableDateFilterLess}
              sortable
              showFilterMenu={false}
            />
            <Column
              header={Labels.IZVOZ_PERIOD_DO}
              field={"periodDo"}
              body={datumPeriodDo}
              filter
              filterElement={dateFilterTemplate}
              filterMatchMode="custom"
              filterFunction={tableDateFilterGreater}
              sortable
              showFilterMenu={false}
              className="wrap-line-anywhere"
            />
            <Column field={"razlogZahteva"} header={Labels.IZVOZ_RAZLOG_ZAHTEVA} filter filterMatchMode="contains" showFilterMenu={false} className="wrap-line-anywhere" sortable />
            <Column
              header={isSuperAdmin ? Labels.IZVOZ_GENERISANO : Labels.IZVOZ_DATUM_GENERISANJA}
              field={"vremeGenerisanja"}
              body={renderVremeGenerisanja}
              filter
              filterElement={dateFilterTemplate}
              filterMatchMode="custom"
              filterFunction={tableDateFilter}
              sortable
              showFilterMenu={false}
              className="wrap-line-anywhere"
            />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
