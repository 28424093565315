import axios from "axios";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { SifarnikUslugaController } from "../../../../controllers/sifarnik-usluga/SifarnikUslugaController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import { handleAxiosCallError, useEffectOnce } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import UslugaSpecijalizacijaCreateDto from "../../../../model/sifarnik-usluga/UslugaSpecijalizacijaCreateDto";
import UslugaSpecijalizacijaReadDto from "../../../../model/sifarnik-usluga/UslugaSpecijalizacijaReadDto";
import SifarnikSpecijalizacijaReadDto from "../../../../model/sifarnik/sifarnik-specijalizacija/SifarnikSpecijalizacijaReadDto";

interface SifarnikUslugaSpecijalizacijeListLogicalType {
  isLoading: boolean;
  specijalizacijeList: SifarnikSpecijalizacijaReadDto[];
  uslugaSpecijalizacijeList: UslugaSpecijalizacijaReadDto[];
  sourceSelection: SifarnikSpecijalizacijaReadDto[];
  setSourceSelection: React.Dispatch<React.SetStateAction<SifarnikSpecijalizacijaReadDto[]>>;
  targetSelection: UslugaSpecijalizacijaReadDto[];
  setTargetSelection: React.Dispatch<React.SetStateAction<UslugaSpecijalizacijaReadDto[]>>;
  onMoveToTarget: (specijalizacije: SifarnikSpecijalizacijaReadDto[]) => void;
  onMoveToSource: (specijalizacije: UslugaSpecijalizacijaReadDto[]) => void;
}

interface UseParamsType {
  sifarnikUslugaId?: string;
}

export default function SifarnikUslugaSpecijalizacijeListLogical(usluga: string | undefined): SifarnikUslugaSpecijalizacijeListLogicalType {
  const { showMessage } = useContext(AppContext);
  const postLogHighLevel = useLogHighLevel();
  const Labels = useLabels();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [specijalizacijeList, setSpecijalizacijeList] = useState<SifarnikSpecijalizacijaReadDto[]>([]);
  const [uslugaSpecijalizacijeList, setUslugaSpecijalizacijeList] = useState<UslugaSpecijalizacijaReadDto[]>([]);
  const [sourceSelection, setSourceSelection] = useState<SifarnikSpecijalizacijaReadDto[]>([]);
  const [targetSelection, setTargetSelection] = useState<UslugaSpecijalizacijaReadDto[]>([]);

  const uslugaId = Number(useParams<keyof UseParamsType>()["sifarnikUslugaId"]);

  const { axiosSuperadminGetSifarnikSpecijalizacija } = SuperadminController();
  const { axiosGetSpecijalizacijeForUsluga, axiosCreateUslugaSpecijalizacije, axiosDeleteUslugaSpecijalizacija } = SifarnikUslugaController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    setIsLoading(true);
    const requestSpecijalizacijaList = axiosSuperadminGetSifarnikSpecijalizacija();
    const requestUslugaSpecijalizacijeList = uslugaId ? axiosGetSpecijalizacijeForUsluga(uslugaId) : null;

    axios
      .all([requestSpecijalizacijaList, requestUslugaSpecijalizacijeList])
      .then(
        axios.spread((responseSpecijalizacijaList, responseUslugaSpecijalizacijeList) => {
          setUslugaSpecijalizacijeList(responseUslugaSpecijalizacijeList?.data.data ?? []);
          const uslugaSpecijalizacijeIdList = responseUslugaSpecijalizacijeList?.data.data.map((specijalizacija: UslugaSpecijalizacijaReadDto) => specijalizacija?.idRepositorySifarnikSpecijalizacija);
          setSpecijalizacijeList(responseSpecijalizacijaList?.data.data.filter((specijalizacija: SifarnikSpecijalizacijaReadDto) => !uslugaSpecijalizacijeIdList?.includes(specijalizacija.id)));
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onMoveToTarget = (specijalizacije: SifarnikSpecijalizacijaReadDto[]) => {
    setIsLoading(true);

    let uslugaSpecijalizacijaChangeArray: UslugaSpecijalizacijaCreateDto[] = [];
    specijalizacije.forEach((specijalizacija: SifarnikSpecijalizacijaReadDto) => {
      uslugaSpecijalizacijaChangeArray.push({
        usluga: { id: uslugaId },
        sifarnikSpecijalizacija: { id: specijalizacija?.id },
      });
    });

    axiosCreateUslugaSpecijalizacije(uslugaId, uslugaSpecijalizacijaChangeArray)
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        const specijalizacijeNazivi = specijalizacije.map((spec) => spec.naziv).join(", ");
        const sigleOrMultipleLabel = specijalizacije.length > 1 ? Labels.LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJE_2 : Labels.LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJA_2;
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJA_1 + usluga + sigleOrMultipleLabel + specijalizacijeNazivi + ".");
        fetchData();
        setSourceSelection([]);
        setTargetSelection([]);
      });
  };

  const onMoveToSource = (specijalizacije: UslugaSpecijalizacijaReadDto[]) => {
    setIsLoading(true);

    let uslugaSpecijalizacijaChangeArray: UslugaSpecijalizacijaCreateDto[] = [];
    specijalizacije.forEach((specijalizacija: UslugaSpecijalizacijaReadDto) => {
      uslugaSpecijalizacijaChangeArray.push({
        usluga: { id: uslugaId },
        sifarnikSpecijalizacija: { id: specijalizacija?.sifarnikSpecijalizacija?.id },
      });
    });
    let sifarnikSpecijalizacijaIdList: Array<number> = [];

    uslugaSpecijalizacijaChangeArray.map((spec) => sifarnikSpecijalizacijaIdList.push(spec?.sifarnikSpecijalizacija?.id!));

    axiosDeleteUslugaSpecijalizacija(uslugaId, [...sifarnikSpecijalizacijaIdList])
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        const specijalizacijeNazivi = specijalizacije.map((spec) => spec?.sifarnikSpecijalizacija?.naziv).join(", ");
        const sigleOrMultipleLabel = specijalizacije.length > 1 ? Labels.LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJE_2 : Labels.LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJA_2;
        postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJA_1 + usluga + sigleOrMultipleLabel + specijalizacijeNazivi + ".");
        fetchData();
        setSourceSelection([]);
        setTargetSelection([]);
      });
  };

  return {
    isLoading,
    specijalizacijeList,
    uslugaSpecijalizacijeList,
    sourceSelection,
    setSourceSelection,
    targetSelection,
    setTargetSelection,
    onMoveToTarget,
    onMoveToSource,
  };
}
