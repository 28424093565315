import { KeyboardEvent, useContext, useRef, useState } from "react";
import PorukeController from "../../../controllers/poruke/PorukeController";
import EnumPorukaStatus from "../../../infrastructure/system/EnumPorukaStatus";
import { DATE_TIME_FORMAT, formatDate2, handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import PorukaCreateDto from "../../../model/poruke/PorukaCreateDto";
import PorukaRadnikCreateDto from "../../../model/poruke/PorukaRadnikCreateDto";
import PorukaRadnikReadDto from "../../../model/poruke/PorukaRadnikReadDto";
import PorukaReadDto from "../../../model/poruke/PorukaReadDto";
import RadnikSkracenoPorukeReadDto from "../../../model/radnik/RadnikSkracenoPorukeReadDto";
import { AppContext, useLabels } from "./../../../Store";

interface PrimljenaPorukaListLogicalType {
  fetchData: () => void;
  primljenaPorukaList: Array<PorukaRadnikReadDto>;
  porukaSearchString: string;
  setPorukaSearchString: React.Dispatch<React.SetStateAction<string>>;
  searchPorukaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  izabranaPorukaRadnik: PorukaRadnikReadDto | undefined;
  poruka: PorukaReadDto | undefined;
  setPoruka: React.Dispatch<React.SetStateAction<PorukaReadDto | undefined>>;
  handlePorukaClick: (poruka: PorukaRadnikReadDto) => void;
  handleUnread: () => void;
  handleDelete: () => void;
  handleReply: () => void;
  handleReplyAll: () => void;
  handleForward: () => void;
  hasMoreMessages: React.MutableRefObject<boolean>;
  searchPoruka: () => void;
}

export interface PrimljenaPorukaListLogicalParams {
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  setAttachmentList: React.Dispatch<React.SetStateAction<Array<any>>>;
  setPorukaChange: React.Dispatch<React.SetStateAction<PorukaCreateDto | undefined>>;
  setRecepients: React.Dispatch<React.SetStateAction<Array<any>>>;
  setAdded: React.Dispatch<React.SetStateAction<number[]>>;
}

export default function PrimljenaPorukaListLogical(params: PrimljenaPorukaListLogicalParams): PrimljenaPorukaListLogicalType {
  const { setIndex, setAttachmentList, setPorukaChange, setRecepients, setAdded } = params;
  const { authData, showMessage, setNeprocitanePorukeCount } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosGetPrimljenaPorukaList, axiosUpdatePorukaStatus, axiosDeletePrimljenaPoruka, axiosGetPoruka, axiosGetPorukaDatotekaList, axiosGetPorukaUnreadCount } = PorukeController();
  const [primljenaPorukaList, setPrimljenaPorukaList] = useState<Array<PorukaRadnikReadDto>>([]);
  const page = useRef(0);
  const hasMoreMessages = useRef(true);
  const [porukaSearchString, setPorukaSearchString] = useState<string>("");
  const [porukaSearchStringOld, setPorukaSearchStringOld] = useState<string>("");
  const [izabranaPorukaRadnik, setIzabranaPorukaRadnik] = useState<PorukaRadnikReadDto | undefined>();
  const [poruka, setPoruka] = useState<PorukaReadDto>();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const searchParams = { page: page.current, size: 20, searchString: porukaSearchString };
    axiosGetPrimljenaPorukaList(searchParams)
      .then((res: any) => {
        res.data.data.length === 0 ? (hasMoreMessages.current = false) : (hasMoreMessages.current = true);
        page.current === 0 ? setPrimljenaPorukaList(res.data.data) : setPrimljenaPorukaList((prevList) => prevList.concat(res.data.data));
        page.current++;
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const searchPoruka = () => {
    if (porukaSearchString !== porukaSearchStringOld) page.current = 0;
    fetchData();
    setPorukaSearchStringOld(porukaSearchString);
  };

  const searchPorukaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      if (porukaSearchString !== porukaSearchStringOld) page.current = 0;
      fetchData();
      setPorukaSearchStringOld(porukaSearchString);
    }
  };

  const handlePorukaClick = (porukaRadnik: PorukaRadnikReadDto) => {
    if (porukaRadnik.porukaStatus.sifra === EnumPorukaStatus.NEPROCITANA) {
      axiosUpdatePorukaStatus(porukaRadnik.id, { sifra: EnumPorukaStatus.PROCITANA })
        .then((res) => {
          const updatedPrimljenaPorukaList = primljenaPorukaList.map((poruka) => {
            if (poruka.id === res.data.data.id) {
              return res.data.data;
            }
            return poruka;
          });
          setPrimljenaPorukaList(updatedPrimljenaPorukaList);
          axiosGetPorukaUnreadCount()
            .then((res: any) => {
              setNeprocitanePorukeCount(res.data.data);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
            });
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    setIzabranaPorukaRadnik(porukaRadnik);

    axiosGetPoruka(porukaRadnik.poruka.id)
      .then((res) => {
        setPoruka(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const handleUnread = () => {
    if (izabranaPorukaRadnik) {
      axiosUpdatePorukaStatus(izabranaPorukaRadnik.id, { sifra: EnumPorukaStatus.NEPROCITANA })
        .then((res) => {
          const updatedPrimljenaPorukaList = primljenaPorukaList.map((poruka) => {
            if (poruka.id === res.data.data.id) {
              return res.data.data;
            }
            return poruka;
          });
          setPrimljenaPorukaList(updatedPrimljenaPorukaList);
          axiosGetPorukaUnreadCount()
            .then((res: any) => {
              setNeprocitanePorukeCount(res.data.data);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
            });
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    setIzabranaPorukaRadnik(undefined);
    setPoruka(undefined);
  };

  const handleDelete = () => {
    if (izabranaPorukaRadnik) {
      axiosDeletePrimljenaPoruka(izabranaPorukaRadnik.id)
        .then(() => {
          setPrimljenaPorukaList((prevPorukaList) => prevPorukaList.filter((poruka) => poruka.id !== izabranaPorukaRadnik.id));
          setIzabranaPorukaRadnik(undefined);
          setPoruka(undefined);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const handleReply = () => {
    setPorukaChange({
      naslov: `RE: ${poruka?.naslov}`,
      sadrzaj: "",
      porukaRoditelj: { id: poruka?.id },
      porukaRadnikList: [{ radnik: { idCoreRadnik: poruka!.radnikKreirao.idCoreRadnik } }],
    });
    setRecepients([poruka!.radnikKreirao]);
    setAdded([poruka!.radnikKreirao.idCoreRadnik]);
    setIndex(0);
  };

  const handleReplyAll = () => {
    const recepients: Array<RadnikSkracenoPorukeReadDto> = poruka!.porukaRadnikList
      .map((pr) => ({
        id: pr.radnik.id,
        ime: pr.radnik.ime,
        prezime: pr.radnik.prezime,
        email: pr.radnik.email,
        idCoreRadnik: pr.radnik.idCoreRadnik,
        username: pr.radnik.username,
        titula: pr.radnik.titula,
        zanimanje: pr.radnik.zanimanje,
        polTrenutni: pr.radnik.polTrenutni,
      }))
      .filter((f) => f.idCoreRadnik !== poruka?.radnikKreirao.idCoreRadnik && f.idCoreRadnik !== authData?.currentRadnik.id)
      .concat([poruka!.radnikKreirao]);

    const prList: Array<PorukaRadnikCreateDto> = recepients.map((recepient) => ({ radnik: { idCoreRadnik: recepient.idCoreRadnik } }));
    const added: Array<number> = recepients.map((recepient) => recepient.idCoreRadnik);

    setPorukaChange({
      naslov: `RE: ${poruka?.naslov}`,
      sadrzaj: "",
      porukaRoditelj: { id: poruka?.id },
      porukaRadnikList: prList,
    });
    setRecepients(recepients);
    setAdded(added);
    setIndex(0);
  };

  const handleForward = () => {
    const sadrzaj = poruka?.sadrzaj
      ? `<br><br>
      <b>${Labels.POCETAK_PROSLEDJENE_PORUKE}</b><br>
      <b>${Labels.PORUKA_POSILJALAC}</b> ${poruka.radnikKreirao?.titula ?? ""} ${poruka.radnikKreirao.ime} ${poruka.radnikKreirao.prezime}<br>
      <b>${Labels.PORUKE_NASLOV}:</b> ${poruka.naslov}<br>
      <b>${Labels.PORUKA_DATUM}:</b> ${formatDate2(poruka.vremeKreiranja, DATE_TIME_FORMAT)}<br>
      <b>${poruka?.porukaRadnikList.length === 1 ? Labels.PORUKA_PRIMALAC : Labels.PORUKA_PRIMAOCI}</b> ${poruka?.porukaRadnikList
        .map((primalac) => `${primalac?.radnik.titula ?? ""} ${primalac?.radnik.ime} ${primalac?.radnik.prezime}`)
        .join(", ")}<br><br>
        ${poruka.sadrzaj}`
      : "";
    setPorukaChange({
      naslov: `FWD: ${poruka?.naslov}`,
      sadrzaj: sadrzaj,
      porukaRadnikList: [],
    });
    setIndex(0);
    if (poruka?.porukaDatotekaList) {
      axiosGetPorukaDatotekaList(poruka?.porukaDatotekaList.map((porukaDatoteka) => porukaDatoteka.id).join(","))
        .then((res: any) => {
          const updatedPorukaDatotekaList = poruka.porukaDatotekaList.map((porukaDatoteka) => ({
            ...porukaDatoteka,
            sadrzaj: res.data.data.find((pd: any) => pd.idNotificationPorukaDatoteka === porukaDatoteka.id).sadrzaj,
          }));
          setPorukaChange((prevPorukaChange) => ({ ...prevPorukaChange!, porukaDatotekaList: updatedPorukaDatotekaList }));
          setAttachmentList(updatedPorukaDatotekaList);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    } else {
      setAttachmentList([]);
    }
    setRecepients([]);
    setAdded([]);
  };

  return {
    fetchData,
    primljenaPorukaList,
    porukaSearchString,
    setPorukaSearchString,
    searchPorukaByEnter,
    izabranaPorukaRadnik,
    poruka,
    setPoruka,
    handlePorukaClick,
    handleUnread,
    handleDelete,
    hasMoreMessages,
    searchPoruka,
    handleReply,
    handleReplyAll,
    handleForward,
  };
}
