import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Column } from "primereact/column";
import { ConfirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import RadniPeriodReadDto from "../../../../model/radni-period/RadniPeriodReadDto";
import RadnikSkracenoReadDto from "../../../../model/radnik/RadnikSkracenoReadDto";
import ExportData from "../../../app/export/ExportData";
import SkeletonPanelItem from "../../../app/skeleton/SkeletonPanelItem";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import ConfirmComponent from "../../../confirm-component/ConfirmComponent";
import OrganizacioneJediniceNavbar from "../../../zakazivanje-pregleda/organizacione-jedinice-navbar/OrganizacioneJediniceNavbar";
import CalendarComponent from "../../calendar-component/CalendarComponent";
import RadniPeriodListLogical, { RadniPeriodDan } from "./RadniPeriodListLogical";

export default function RadniPeriodListPage() {
  const {
    breadCrumbItems,
    odabranaOrgJedList,
    radnikList,
    selectedRadnik,
    setSelectedRadnik,
    datumOd,
    setDatumOd,
    datumDo,
    setDatumDo,
    radniPeriodList,
    first,
    tableRows,
    onPageRadniPeriod,
    searchData,
    exportData,
    odabranaOrgJed,
    setExpandedRows,
    expandedRows,
    getTime,
    selectedRows,
    setSelectedRows,
    deleteRadniPeriodList,
    ustanovaOrgJedCallback,
    radniPeriodLoading,
    radnikListLoading,
    datumOdRef,
    datumDoRef,
  } = RadniPeriodListLogical();
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const navigate = useNavigate();
  const dt = useRef<any>(null);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const renderImePrezime = (radnik?: RadnikSkracenoReadDto) => `${radnik?.ime} ${radnik?.prezime}`;

  const searchToolbar = () => {
    return (
      <>
        <div className="font-bold float-label mr-3">
          <div>{Labels.RADNI_PERIOD_COLUMN_RADNIK}</div>
        </div>
        <div className="p-fluid p-0 mr-3">
          <div>
            <Dropdown
              value={selectedRadnik}
              options={radnikList}
              optionLabel="ime"
              itemTemplate={renderImePrezime}
              valueTemplate={renderImePrezime}
              onChange={(e: DropdownChangeEvent) => {
                setSelectedRadnik(e.value);
              }}
              emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
            />
          </div>
        </div>
        <div className="font-bold float-label mr-3">
          <div>{Labels.RADNI_PERIOD_COLUMN_DATUM_OD}</div>
        </div>
        <div className="mr-3">
          <CalendarComponent
            ref={datumOdRef}
            showButtonBar={false}
            name="datumOd"
            value={datumOd}
            onChange={(e: CalendarChangeEvent) => {
              setDatumOd(e.value as Date);
              if (e.target.value) datumOdRef?.current?.hide();
            }}
            placeholder={Labels.RADNI_PERIOD_COLUMN_DATUM_OD}
          />
        </div>
        <div className="font-bold float-label mr-3">
          <div>{Labels.RADNI_PERIOD_COLUMN_DATUM_DO}</div>
        </div>
        <div className="mr-3">
          <CalendarComponent
            ref={datumDoRef}
            showButtonBar={false}
            name="datumDo"
            value={datumDo}
            onChange={(e: CalendarChangeEvent) => {
              setDatumDo(e.value as Date);
              if (e.target.value) datumDoRef?.current?.hide();
            }}
            placeholder={Labels.RADNI_PERIOD_COLUMN_DATUM_DO}
          />
        </div>
        <Button tooltip={Labels.RADNI_PERIOD_SEARCH} icon="pi pi-search" className="search-button" onClick={searchData} />
      </>
    );
  };

  const addRadniPeriodButton = () => {
    return (
      <Button
        label={Labels.RADNI_PERIOD_BUTTON_ADD}
        tooltip={Labels.RADNI_PERIOD_CREATE}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-success mr-3 mb-4 sm:mb-4 md:mb-0"
        onClick={() => openRadniPeriod(EntityOperation.CREATE)}
        disabled={!pristup}
      />
    );
  };

  const obrisiSelektovano = () => {
    return (
      <ConfirmComponent
        buttonIcon="pi pi-trash"
        buttonClassName="p-button-danger"
        buttonLabel={Labels.RADNI_PERIOD_TITLE_DELETE_SELECTED}
        confirmTitle={Labels.RADNI_PERIOD_CONFIRM_DELETE_SELECTED_MESSAGE}
        confirmIcon="pi pi-exclamation-triangle"
        acceptClassName="p-button p-component text-white p-button-tertiary"
        rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
        onAccept={() => {
          const params = new Array<number>();
          selectedRows.forEach((selectedRow: RadniPeriodReadDto) => params.push(selectedRow.id));
          deleteRadniPeriodList(params, selectedRows);
        }}
        disabled={!pristup}
      />
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={radniPeriodLoading} data={exportData} name={Labels.RADNI_PERIOD_LIST} dt={dt} />
      </>
    );
  };

  const crudBodyTemplate = (rowRadniPeriod: RadniPeriodReadDto) => {
    return (
      <div className="float-right">
        <ConfirmComponent
          buttonIcon="pi pi-trash"
          buttonClassName="p-button-danger"
          confirmTitle={Labels.RADNI_PERIOD_CONFIRM_DELETE_MESSAGE}
          confirmIcon="pi pi-exclamation-triangle"
          acceptClassName="p-button p-component text-white p-button-tertiary"
          rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
          onAccept={() => {
            const params = new Array<number>();
            params.push(rowRadniPeriod.id);
            deleteRadniPeriodList(params, [rowRadniPeriod]);
          }}
          disabled={!pristup}
        />
      </div>
    );
  };

  const openRadniPeriod = (entityOperation: string, id?: number) => {
    const idRadniPeriod = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-radni-period/${idRadniPeriod}`, {
      state: {
        odabranaOrgJedList: odabranaOrgJedList,
        selectedRadnik: selectedRadnik,
      },
    });
  };

  const rowExpansionTemplate = (data: RadniPeriodDan) => {
    const tableData = data.nizPerioda;

    return (
      <div className="col-10 ml-8">
        {selectedRows.length > 0 && <Toolbar left={obrisiSelektovano} />}
        <DataTable
          value={tableData}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          selectionMode="checkbox"
          rowHover
          stripedRows
        >
          <Column selectionMode="multiple" className="col-1" />
          <Column field={"periodPocetak"} body={(e) => getTime(e.periodPocetak)} header={Labels.RADNI_PERIOD_COLUMN_POCETAK_PERIODA} sortable className="col-2" />
          <Column field={"periodKraj"} body={(e) => getTime(e.periodKraj)} header={Labels.RADNI_PERIOD_COLUMN_KRAJ_PERIODA} className="col-2" sortable />
          <Column field={"napomena"} className="wrap-line-anywhere" header={Labels.RADNI_PERIOD_OPIS} />
          <Column body={crudBodyTemplate} />
        </DataTable>
      </div>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.RADNI_PERIOD_COLUMN_RADNIK, filter: true, sortrable: true },
    { columnName: Labels.RADNI_PERIOD_COLUMN_ORGANIZACIONA_JEDINICA, filter: true, sortrable: true },
    { columnName: Labels.RADNI_PERIOD_COLUMN_DATUM, filter: true, sortrable: true },
    { columnName: Labels.RADNI_PERIOD_COLUMN_RADNI_DAN, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <div className="my-3">
          <OrganizacioneJediniceNavbar ref={ustanovaOrgJedCallback} />
        </div>
        {radnikListLoading ? <SkeletonPanelItem height="5rem" /> : radnikList?.length > 0 && <Toolbar left={searchToolbar} />}
        <Toolbar left={addRadniPeriodButton} right={downloadButton} className="my-3" />

        {radnikList?.length > 0 &&
          (radniPeriodLoading ? (
            <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={false} hasExpanderColumn />
          ) : (
            <DataTable
              dataKey="datum"
              ref={dt}
              value={radniPeriodList}
              paginator
              first={first}
              paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
              rows={tableRows}
              onPage={(e) => {
                onPageRadniPeriod(e.rows, e.first);
              }}
              exportFilename={Labels.RADNI_PERIOD_LIST}
              emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
              expandedRows={expandedRows}
              onRowExpand={(e) => {
                setExpandedRows({ [e.data.datum]: true });
                setSelectedRows([]);
              }}
              onRowCollapse={(e) => {
                setExpandedRows({});
                setSelectedRows([]);
              }}
              rowExpansionTemplate={rowExpansionTemplate}
              rowHover
              stripedRows
            >
              <Column expander />
              <Column field={"radnik"} body={() => renderImePrezime(selectedRadnik)} header={Labels.RADNI_PERIOD_COLUMN_RADNIK} />
              <Column field={"orgJedinica"} body={() => odabranaOrgJed?.organizacionaJedinica.naziv} header={Labels.RADNI_PERIOD_COLUMN_ORGANIZACIONA_JEDINICA} />
              <Column field={"datum"} header={Labels.RADNI_PERIOD_COLUMN_DATUM} />
              <Column field={"radniDan"} header={Labels.RADNI_PERIOD_COLUMN_RADNI_DAN} />
            </DataTable>
          ))}
      </Panel>
      <ConfirmPopup />
    </div>
  );
}
