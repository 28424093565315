import { useQuery } from "@tanstack/react-query";
import RacunController from "../../../controllers/naplata/RacunController";
import QueryKeys from "../../../infrastructure/system/QueryKeys";

export function usePartnerListForUsluga(uslugaID: number) {
  const { axiosGetPartnerListForUsluga } = RacunController();

  const { data } = useQuery({
    queryKey: [QueryKeys.PARTNER_LIST, { uslugaID }],
    queryFn: () => axiosGetPartnerListForUsluga(uslugaID),
  });

  return { partnerList: data?.data?.data ?? [] };
}
