const Labels = {
  SR_RS_LATN: "Латиница",
  SR_RS: "Ћирилица",
  NEW_LINE: "\n",
  TAB: "\t",
  SPACE: " ",
  DASH: " - ",
  SLASH: "/",
  TWO_DOT: ": ",
  DOT_SIGN: "•",

  // Special strings
  SPECIAL_CHAR_REQUIRED: " *",
  SPECIAL_CHAR_COLON: ":",

  // Application
  APPLICATION_NAME: "Heliant Cloud",

  // Common
  COPYRIGHT: "Developed by   ",
  HELIANT: " HELIANT",
  LOGIN: "Пријава",
  FORGOTTEN_PASSWORD: "Заборављена лозинка?",
  TOKEN_EXPIRED_LABEL: "Токен је истекао",

  // Title labels
  TITLE_LOGIN: "Унесите Ваше приступне параметре",
  TITLE_RESET_PASSWORD: "Ресетовање лозинке",
  TITLE_SET_NEW_PASSWORD: "Постављање нове лозинке",

  // Menu labels
  MENU_ADMIN: "Администрација",
  MENU_SUPERADMIN: "Супер администрација",
  MENU_PATIENT_SEARCH: "Претрага пацијената",
  MENU_IZVESTAJI: "Извештај",

  // Button labels
  BUTTON_CREATE: "Креирај",
  BUTTON_CHANGE: "Промени",
  BUTTON_UPDATE: "Измени",
  BUTTON_DETAILS: "Детаљи",
  BUTTON_LOGOUT: "Крај рада",
  BUTTON_SAVE: "Сачувај",
  BUTTON_CONFIRM: "Потврди",
  BUTTON_CANCEL: "Одустани",
  BUTTON_DELETE: "Обриши",
  BUTTON_DELETE_DATA: "Обриши податке",
  BUTTON_UPLOAD_IMAGE: "Учитај слику",
  BUTTON_RESET_PASSWORD: "Ресетуј лозинку",
  BUTTON_ADD_ORGANIZACIONA_JEDINICA: "Додај организациону јединицу",
  BUTTON_ADD_USTANOVA: "Додај установу",
  BUTTON_ADD_RADNIK: "Додај радника",
  BUTTON_ADD_RADNIK_OTHER: "Додај радника из друге установе",
  BUTTON_ADD_TIP_KONTAKTA: "Додај тип прегледа",
  BUTTON_ADD_ULOGA: "Додај улогу",
  BUTTON_ADD_FORMULAR: "Додај формулар",
  BUTTON_ADD_PACIJENT_POLJE: "Додај пацијент поље",
  BUTTON_ADD_PACIJENT_POLJE_KATEGORIJA: "Додај категорију за пацијент поље",
  BUTTON_YES: "Да",
  BUTTON_NO: "Не",
  BUTTON_CLOSE: "Затвори",
  BUTTON_REPOSITORY: "Репозиторијум",
  BUTTON_OVERWRITE: "Препиши",
  BUTTON_LOAD_MORE: "Учитај још",
  BUTTON_ADD_OBAVESTENJE: "Додај обавештење",
  BUTTON_ADD_IZVESTAJ_PARAMETAR: "Додај параметар за извештај",
  BUTTON_ADD_USTANOVA_DODATNI_MODUL: "Додај модул",
  BUTTON_ADD_PACIJENT: "Додај пацијентa",
  BUTTON_LOG_OUT: "Излогуј се",

  DIJAGNOZA: "Дијагноза",
  DIJAGNOZA_LIST_TOKOM_KONTKAKTA: "Постављене дијагнозе током прегледа",
  DIJAGNOZA_LIST_IZ_PRETHODNIH_PREGLEDA: "Дијагнозе из претходних прегледа",
  DIJAGNOZA_POSTAVLJENA: "Постављена дијагноза:",
  DIJAGNOZA_STATUS: "Статус дијагнозе:",
  DIJAGNOZA_NAPOMENA: "Напомена:",
  DIJAGNOZA_POSTAVIO: "Поставио/ла:",
  DIJAGNOZA_POSTAVIO_MUSKI: "Поставио:",
  DIJAGNOZA_POSTAVIO_ZENSKI: "Поставила:",
  DIJAGNOZA_NOVA: "Нова дијагноза",
  DIJAGNOZA_IZMENI: "Измени дијагнозу",
  DIJAGNOZA_OBRISI: "Обриши дијагнозу",
  DIJAGNOZA_PREPISI: "Препиши дијагнозу",
  DIJAGNOZA_UNOS: "Унос дијагнозе",
  DIJAGNOZA_UNOS_JE_OBAVEZAN: "Унос дијагнозе је обавезан податак.",
  DIJAGNOZA_STATUS_JE_OBAVEZAN: "Статус дијагнозе је обавезан податак.",
  DIJAGNOZA_USPESNO_IZMENJENA: "Дијагноза успешно измењена.",
  DIJAGNOZA_USPESNO_OBRISANA: "Дијагноза успешно обрисана.",
  DIJAGNOZA_USPESNO_SACUVANA: "Дијагноза успешно сачувана.",
  DIJAGNOZA_USPESNO_PREPISANA: "Дијагноза успешно преписана.",
  DIJAGNOZA_IZMENA: "Измена дијагнозе",
  DIJAGNOZA_BRISANJE: "Брисање дијагнозе",
  DIJAGNOZA_DODAVANJE: "Додавање нове дијагнозе",
  DIJAGNOZA_PREGLED: "Преглед дијагнозе",
  DIJAGNOZA_UNOS_PLACEHOLDER: "Унесите назив или шифру дијагнозе",

  SEARCH_FORMULAR: "Претрага формулара ",
  FORMULAR_LIST: "Формулари",
  FORMULAR_TITLE_DIALOG_CREATE: "Креирање формулара ",
  FORMULAR_TITLE_DIALOG_UPDATE: "Измена формулара ",
  FORMULAR_TITLE_DIALOG_DELETE: "Брисање формулара ",
  FORMULAR_TITLE_DIALOG_DETAILS: "Детаљи о формулару ",
  FORMULAR_NAZIV: "Назив ",
  FORMULAR_SIFRA: "Шифра",
  FORMULAR_NAZIV_IS_REQUIRED: "Назив је обавезно поље ",
  FORMULAR_SIFRA_IS_REQUIRED: "Шифра је обавезно поље",
  FORMULAR_TIP_PREGLEDA_IS_REQUIRED: "Тип прегледа је обавезно поље",
  FORMULAR_TITLE_MESSAGE_CREATE_FORMULAR_SUCCESS: "Формулар је успешно креиран ",
  FORMULAR_TITLE_MESSAGE_UPDATE_FORMULAR_SUCCESS: "Формулар је успешно измењен ",
  FORMULAR_TITLE_MESSAGE_DELETE_FORMULAR_SUCCESS: "Формулар је успешно избрисан ",
  FORMULAR_SEARCH: "Претрага",
  FORMULAR_SUCCESS_SAVE: "Успешно сачуван формулар",
  FORMULAR_SUCCESS_UPDATE: "Успешно измењен формулар",
  FORMULAR_HTML_PREDLOZAK: "HTML предложак ",
  FORMULAR_JR_DOCUMENT: "Jasper документ ",
  FORMULAR_CHOOSE_JASPER: "Изаберите jasper ",
  FORMULAR_REMOVE_JASPER: "Избаците jasper ",
  FORMULAR_DELETE_PRINT: "Брисање документа",
  FORMULAR_POPUNJEN_DELETE: "Брисање попуњеног формулара",
  FORMULAR_DA_LI_STE_SIGURNI: "Да ли сте сигурни да желите да изађете из писања формулара?",
  FORMULAR_ENTITY_STATUS_IS_REQUIRED: "Обавезно је унети статус прегледа за формулар.",
  FORMULAR_POPUP: "Да бисте одштампали налаз, потребно је да дозволите попуп на претраживачу.",
  IMPORT_FORMULAR_REPOSITORY: "Преузми формуларе са репозиторијума",
  FORMULAR_REPOSITORY: "Репозиторијум формулара",

  // Placeholder labels
  PLACEHOLDER_CHOOSE_ORG_JED: "Изаберите организациону јединицу",
  PLACEHOLDER_DIJAGNOZA: "Дијагноза",
  PLACEHOLDER_DATE_KONTAKT_FROM: "Датум прегледа од",
  PLACEHOLDER_DATE_KONTAKT_TO: "Датум прегледа до",
  PLACEHOLDER_DATE_VREME_SMRTI: "Време смрти",
  PLACEHOLDER_DATUM_FORMAT: "ДД.ММ.ГГГГ.",
  PLACEHOLDER_DATUM_VREME_FORMAT: "ДД.ММ.ГГГГ. ЧЧ:ММ",
  PLACEHOLDER_VREME_FORMAT: "ЧЧ:ММ",

  LABEL_OD: "од",
  LABEL_DO: "до",
  LABEL_ADDITIONAL_SEARCH: "Додатна претрага",
  LABEL_DESCRIPTION: "Опис",
  LABEL_NOTE: "Напомена",
  LABEL_EMAIL: "Имејл",
  LABEL_SMS: "SMS",
  LABEL_IME: "Име",
  LABEL_PREZIME: "Презиме",
  LABEL_DELETE: "Обриши",
  LABEL_PODESAVANJA: "Подешавања",
  LABEL_BEFORE: "пре",
  LABEL_MINUTES: "минута",
  LABEL_HOURS: "часова",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "Да ли сте сигурни да желите да завршите са радом?",
  LABEL_SIFARNIK_LIST: "Шифарници",
  LABEL_SIFRA: "Шифра",
  LABEL_NAZIV: "Назив",
  LABEL_UPDATE_FORM: "Измена формулара ",
  LABEL_DELETE_FORM: "Брисање фромулара ",
  LABEL_DETAILS_FORM: "Детаљи формулара ",
  LABEL_UPDATE_FIELD_TYPE: "Измена типа поља ",
  LABEL_DELETE_FIELD_TYPE: "Брисање типа поља ",
  LABEL_DETAILS_FIELD_TYPE: "Детаљи типа поља ",
  LABEL_UPDATE_FIELD_TYPE_ENUM_STAVKA: "Измена типа поља енум ставке ",
  LABEL_CREATE_FIELD_TYPE_ENUM_STAVKA: "Креирање типа поља енум ставке ",
  LABEL_DELETE_FIELD_TYPE_ENUM_STAVKA: "Брисање типа поља енум ставке ",
  LABEL_DETAILS_FIELD_TYPE_ENUM_STAVKA: "Детаљи типа поља енум ставке ",
  TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS: "Тип поља је успешно креиран.",
  TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS: "Тип поља је успешно измењен.",
  TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS: "Тип поља је успешно обрисан.",
  TITLE_MESSAGE_CREATE_POLJE_SUCCESS: "Поље је успешно креирано.",
  TITLE_MESSAGE_UPDATE_POLJE_SUCCESS: "Поље је успешно измењено.",
  TITLE_MESSAGE_DELETE_POLJE_SUCCESS: "Поље је успешно обрисано.",
  TITLE_MESSAGE_DELETE_FORMULAR_POPUNJEN_SUCCESS: "Попуњен формулар је успешно обрисан.",
  TITLE_MESSAGE_DELETE_DOKUMENT_SUCCESS: "Документ је успешно обрисан.",
  TITLE_MESSAGE_PATCH_PROFILNA_SLIKA: "Успешно сте променили профилну слику.",
  TITLE_CHANGE_PROFILE_IMAGE: "Промените профилну слику",
  TITLE_RADNIK_ADD_OTHER: "Додај радника из друге установе",
  LABEL_CREATE_FIELD_TYPE: "Креирање типа поља",
  LABEL_MIN: "Минимум",
  LABEL_MAX: "Максимум",
  LABEL_MALE: "М",
  LABEL_FEMALE: "Ж",
  LABEL_DECIMALS: "Децимале",
  LABEL_FORMAT: "Формат",
  LABEL_SHOW_COLUMNS: "Прикажи колоне",
  LABEL_CREATE: "Креирај",
  LABEL_DETAILS: "Детаљи",
  LABEL_UNIT: "Јединица",
  LABEL_OBAVESTENJE: "Обавештење",
  LABEL_ORGANIZACIONA_JEDINICA: "Организациона јединица",
  LABEL_ORGANIZACIONA_JEDINICA_RADNIK_LIST: "Радници организационе јединице",
  LABEL_ORGANIZACIONA_JEDINICA_KONTAKT_TIP_LIST: "Типови прегледа организационе јединице",
  LABEL_RADNIK: "Радник",
  LABEL_TIP_KONTAKTA: "Тип прегледа",
  LABEL_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA: "Тип контакта организациона јединица",
  LABEL_TIP_PREGLEDA: "Тип прегледа",
  LABEL_PROMENI_DOKTORA: "Промени доктора",
  LABEL_ULOGA: "Улога",
  LABEL_PACIJENT_DOSIJE: "Досије пацијента",
  LABEL_PACIJENT_DOSIJE_BROJ_PROTOKOLA: "Број протокола",
  LABEL_PACIJENT_POLJE: "Пацијент поље",
  LABEL_PACIJENT_POLJE_KATEGORIJA: "Категорија поља пацијента",
  LABEL_IME_KONTAKT_OSOBE: "Име",
  LABEL_PREZIME_KONTAKT_OSOBE: "Презиме",
  LABEL_ODNOS_SA_PACIJENTOM: "Однос са пацијентом",
  LABEL_TELEFON_KONTAKT_OSOBE: "Телефон",
  LABEL_POL_KONTAKT_OSOBE: "Пол",
  LABEL_POL_MUSKO: "Мушко",
  LABEL_POL_ZENSKO: "Женско",
  LABEL_RADNIK_ULOGA_LISTA: "Улоге радника",
  LABEL_ULOGA_RADNIK_LISTA: "Радници који имају улогу",
  LABEL_ULOGA_APLIKATIVNA_PRAVA: "Апликативна права",
  LABEL_ULOGA_IMA_APLIKATIVNA_PRAVA: "Апликативна права над улогом",
  LABEL_RADNIK_ORGANIZACIONA_JEDINICA_LIST: "Организационе јединице радника",
  LABEL_RADNI_PERIOD: "Радни период",
  LABEL_SIFARNIK: "Шифарнички ",
  LABEL_UPLOADED: "Отпремљено",
  LABEL_UPLOAD: "Отпреми",
  LABEL_CHOOSE: "Припреми",
  LABEL_REMOVE_DOCUMENT: "Да ли сте сигурни да желите да уклоните датотеку?",
  LABEL_EXPORT: "Преузми",
  LABEL_PDF: "PDF",
  LABEL_XLS: "XLS",
  LABEL_CSV: "CSV",
  LABEL_JSON: "JSON",
  LABEL_ENTITY_STATUS: "Статус",
  LABEL_FORM: "Формулар",
  LABEL_PRIKAZNI_REDOSLED: "Приказни редослед",
  LABEL_UPDATE_POLJE: "Измена поља ",
  LABEL_DETAIL_POLJE: "Детаљи поља ",
  LABEL_DELETE_POLJE: "Брисање поља ",
  LABEL_CREATE_POLJE: "Креирање поља ",
  LABEL_PATIENT_SEARCH: "Тражи",
  LABEL_TOOLTIP_PATIENT_SEARCH: "Претрага пацијената",
  LABEL_TOOLTIP_PATIENT_SEARCH_ALL: "Претрага на основу имена и презимена",
  LABEL_TOOLTIP_PATIENT_SEARCH_ERASE: "Поништи претрагу",
  LABEL_TOOLTIP_CHOOSE_COLUMN: "Избор колона за приказ у табели",
  LABEL_TOOLTIP_ADD_PATIENT: "Додај пацијентa",
  LABEL_NAPOMENA: "Напомена",
  LABEL_TIP_KOMPONENTE: "Тип компоненте",
  LABEL_UCESNICI: "Учесници",
  LABEL_COLUMN_TRUE: "Да",
  LABEL_COLUMN_FALSE: "Не",
  TABLE_EMPTY_MESSAGE: "Нема података",
  DOWNLOAD_DATA_TABLE: "Преузмите садржај табеле",
  LABEL_PACIJENT_INFORMACIJE: "Информације о пацијенту",
  TOOLTIP_PACIJENT_INFORMACIJE: "Прикажи информације о пацијенту",
  TOOLTIP_OPSTI_MEDICINSKI_PODACI: "Прикажи опште медицинске податке",
  LABEL_GODINA: "година",
  LABEL_PERIOD: "Период",
  LABEL_REPOZITORIJUM: "Репозиторијум",
  LABEL_DATATABLE_ROWS_PER_PAGE_DROPWDOWN_ALL: "Све",
  LABEL_SADRZAJ: "Садржај",
  LABEL_VAZI_DO: "Важи до",
  LABEL_DATOTEKE: "Датотеке",
  LABEL_IZVESTAJ: "Извештај",
  LABEL_REQUEST_NAME: "Назив захтева",
  LABEL_IZVESTAJ_PARAMETAR_NAZIV: "Назив параметра статистичког извештаја",
  LABEL_IZVESTAJ_REPOSITORY: "Репозиторијум статистичких извештаја",

  LABEL_UPDATE_IZVESTAJ_PARAMETAR: "Измена параметра ",
  LABEL_DELETE_IZVESTAJ_PARAMETAR: "Брисање параметра ",
  LABEL_DETAILS_IZVESTAJ_PARAMETAR: "Детаљи параметра ",
  LABEL_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Измена ставке ",
  LABEL_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Креирање нове ставке ",
  LABEL_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Брисање ставке ",
  LABEL_DETAILS_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Детаљи ставке ",
  TITLE_MESSAGE_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS: "Ставка параметра извештаја је успешно креирана.",
  TITLE_MESSAGE_UPDATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS: "Ставка параметра извештаја је успешно измењена.",
  TITLE_MESSAGE_DELETE_IZVESTAJ_PARAMETAR_ENUM_STAVKA_SUCCESS: "Ставка параметра извештаја је успешно обрисана.",

  LABEL_REQUIRED_FIELDS: "Поља су обавезна",
  LABEL_REQUIRED_FIELD: "Поље је обавезно",
  MESSAGE_ZABRANJEN_PRISTUP: "Забрањен приступ",
  LABEL_PRINT: "Штампа",

  //Column header
  HEADER_NAZIV: "Назив",
  HEADER_SIFRA: "Шифра",
  HEADER_PRIKAZNI_REDOSLED: "Приказни редослед",
  HEADER_NAPOMENA: "Напомена",
  HEADER_FULLNAME: "Име и презиме",
  HEADER_NAME: "Име",
  HEADER_SURNAME: "Презиме",
  HEADER_DOB: "Датум рођења",
  HEADER_LBO: "ЛБО",
  HEADER_PHONE: "Контакт телефон",
  HEADER_GENDER: "Пол",
  HEADER_READ_ONLY: "Само за читање",
  HEADER_REQUIRED: "Обавезно",
  HEADER_CATEGORY: "Категорија",
  HEADER_VAZI_OD: "Важи од",
  HEADER_VAZI_DO: "Важи до",
  HEADER_BROJ_POLISE: "Број полисе",
  HEADER_PARTNER_PZO: "Партнер/ПЗО",
  HEADER_PROSTOR: "Простор",
  HEADER_BROJ_NALOGA: "Број налога",
  HEADER_INFO: "Инфо",
  HEADER_PODRAZUMEVANA_KOLICINA: "Подразумевана количина",
  HEADER_IZNOS: "Износ",
  HEADER_PORESKA_STOPA: "Пореска стопа",
  HEADER_TIP: "Тип",

  //Messages
  MESSAGES_UPLOAD_IMAGE_ERROR: "Неуспешно учитавање слике",
  MESSAGES_NO_RESULT_FOUND: "Нема резултата",
  MESSAGES_SIFRA_REQUIRED: "Шифра је обавезан податак!",
  MESSAGES_NAZIV_REQUIRED: "Назив је обавезан податак!",
  MESSAGES_TIP_POLJA_REQUIRED: "Тип поља је обавезан податак!",
  MESSAGES_INVALID_FILE_SIZE: "Максимална величина датотеке је 5 MB.",
  MESSAGES_KATEGORIJA_REQUIRED: "Категорија је обавезан податак!",
  MESSAGES_OBAVEZNO_REQUIRED: "Обавезно је обавезан податак!",
  MESSAGES_READONLY_REQUIRED: "Само за читање је обавезан податак!",
  MESSAGE_SAVE_VREME_SMRTI_SUCCESS: "Успешно сачувано време смрти.",
  MESSAGE_DELETE_VREME_SMRTI_SUCCESS: "Успешно обрисано време смрти.",
  MESSAGE_ADD_NEW_WARNING_SUCCESS: "Успешно додато упозорење.",
  MESSAGE_DELETE_WARNING_SUCCESS: "Успешно обрисано упозорење.",
  MESSAGE_PARAMETAR_TIP_ENUM_REQUIRED: "Тип параметра је обавезан податак.",
  MESSAGES_REQUEST_NAME_REQUIRED: "Назив захтева је обавезан податак",

  // operator
  CONTAIS: "садржи",
  NOT_CONTAIS: "не садржи",
  START_WITH: "почиње са",
  END_WITH: "завршава се",
  CHOOSE: "изабрано",
  NOT_CHOOSE: "није изабрано",

  //errorPage
  ERROR_PAGE_404: "404",
  ERROR_PAGE_PAGE_NOT_FOUND: "Страница није пронађена.",

  ERROR_FORBIDDEN_PAGE: "Немате приступ овој страници.",

  /* RADNIK */
  SEARCH_RADNIK: "Претрага радника",
  RADNIK_LIST: "Радници",
  RADNIK_IME: "Име",
  RADNIK_PREZIME: "Презиме",
  RADNIK_USERNAME: "Корисничко име",
  RADNIK_EMAIL: "Имејл",
  RADNIK_POL: "Пол",
  RADNIK_PASSWORD: "Лозинка",
  RADNIK_SEARCH: "Претрага",
  RADNIK_TITLE_DIALOG_CREATE: "Креирање радника ",
  RADNIK_TITLE_DIALOG_UPDATE: "Измена радника ",
  RADNIK_TITLE_DIALOG_DELETE: "Брисање радника ",
  RADNIK_TITLE_DIALOG_DETAILS: "Детаљи радника ",
  RADNIK_TITLE_RESET_PASSWORD: "Започните процес рестарта лозинке",
  RADNIK_TITLE_MESSAGE_DELETE_RADNIK_SUCCESS: "Радник је успешно обрисан.",
  RADNIK_TITLE_MESSAGE_CREATE_RADNIK_SUCCESS: "Радник је успешно креиран.",
  RADNIK_TITLE_MESSAGE_UPDATE_RADNIK_SUCCESS: "Радник је успешно измењен.",
  RADNIK_USERNAME_IS_REQUIRED: "Обавезно је унети корисничко име.",
  RADNIK_EMAIL_IS_REQUIRED: "Обавезно је унети имејл.",
  RADNIK_EMAIL_FORMAT_IS_NOT_VALID: "Имејл формат није валидан!",
  RADNIK_POL_IS_REQUIRED: "Обавезно је унети пол.",
  RADNIK_IME_IS_REQUIRED: "Обавезно је унети име",
  RADNIK_PREZIME_IS_REQUIRED: "Обавезно је унети презиме",
  RADNIK_STATUS_IS_REQUIRED: "Обавезно је унети статус",
  RADNIK_PASSWORD_DEFAULT: "УПроцесуКреирања",
  RADNIK_BROJ_PECATA: "Број печата",
  RADNIK_STATUS: "Статус",
  RADNIK_TITULA: "Титула",
  RADNIK_ZANIMANJE: "Занимање",
  RADNIK_MEJL_ISKORISCEN: "Овај мејл је већ искоришћен!",
  RADNIK_DODAT_U_USTANOVU: "Радник је додат у установу!",
  RADNIK_JE_IZMENJEN_I_DODAT_U_USTANOVU: "Радник је измењен и додат у изабрану установу!",
  RADNIK_BIRANJE_PODATAKA: "Изаберите податке за радника",
  SAVE_RADNIK_DATA: "Сачувај податке о раднику",
  CANCEL_RADNIK_DATA: "Поништи податке о раднику",
  RADNIK_WARNING_MAX_BROJ_NALOGA: "Упозорење! Унет је максималан број радника.",
  RADNIK_RACUNANJE_NALOGA_ZA_LIMIT: "Рачунање налога за лимит",
  RADNIK_USPESNO_DODAT: "Радник је успешно додат на установу",
  RADNIK_KALENDAR_INTEGRACIJA: "Интеграција са календаром",
  RADNIK_DOSTUPAN_ZA_EKSTERNE_ZAKAZIVACE: "Доступан за екстерне заказиваче",

  /*PACIJENT*/
  PACIJENT_IME: "Име",
  PACIJENT_PREZIME: "Презиме",
  PACIJENT_IME_RODITELJA: "Име једног родитеља",
  PACIJENT_POL: "Пол",
  PACIJENT_DATUM_RODJENJA: "Датум рођења",
  PACIJENT_ADRESA: "Адреса",
  PACIJENT_USPESNO_IZMENJEN: "Пацијент успешно измењен",
  PACIJENT_USPESNO_OBRISAN: "Пацијент успешно обрисан",
  PACIJENT_USPESNO_KREIRAN: "Пацијент успешно креиран",
  PACIJENT_IME_OBAVEZAN_PODATAK: "Име пацијента је обавезан податак",
  PACIJENT_PREZIME_OBAVEZAN_PODATAK: "Презиме пацијента је обавезан податак",
  PACIJENT_POL_OBAVEZAN_PODATAK: "Пол пацијента је обавезан податак",
  PACIJENT_DATUM_RODJENJA_OBAVEZAN_PODATAK: "Датум рођења пацијента је обавезан податак",
  PACIJENT_BRISANJE: "Брисање пацијента",
  BUTTON_PACIJENT_DELETE: "Обриши пацијента",
  PACIJENT_PRIKAZI: "Прикажи пацијента",
  PACIJENT_TELEFON: "Број телефона",
  PACIJENT_EMAIL: "Имејл",
  PACIJENT_OBAVESTENJE_TIP_LIST: "Инфо о терминима",
  PACIJENT_GRUPNO_OBAVESTENJE_TIP_LIST: "Обавештења и промоције",
  PACIJENT_EXISTS_WARNING: "Пацијент са истим подацима већ постоји у нашем систему. Молимо проверите пре уноса.",
  PACIJENT_EXIST_MALE_GENDER_TEXT: "мушког",
  PACIJENT_EXIST_FEMALE_GENDER_TEXT: "женског",
  PACIJENT_EXIST_NOT_SPECIFIED_GENDER_TEXT: "неназначеног",
  PACIJENT_EXIST_PATIENT_TEXT: "Пацијент",
  PACIJENT_EXIST_GENDER_TEXT: "пола",
  PACIJENT_EXIST_DATE_TEXT: "датума рођења",
  PACIJENT_EXIST_ALREADY_EXIST_TEXT_1: "већ постоји у бази.",
  PACIJENT_EXIST_ALREADY_EXIST_TEXT_2: "Молимо проверите пре додавања.",
  PACIJENTI_USPESNO_SPOJENI: "Пацијенти успешно спојени.",

  /*Pacijent polje */
  SEARCH_PACIJENT_POLJE: "Претрага поља пацијента",
  PACIJENT_POLJE_LIST: "Поља пацијента",
  PACIJENT_POLJE_NAZIV: "Назив",
  PACIJENT_POLJE_SIFRA: "Шифра",
  PACIJENT_POLJE_TIP: "Тип",
  PACIJENT_POLJE_VRSTA_GRAFIKONA: "Врста графикона раста",

  PACIJENT_POLJE_SEARCH: "Претрага",
  PACIJENT_POLJE_NAZIV_IS_REQUIRED: "Обавезно је унети назив",
  PACIJENT_POLJE_SIFRA_IS_REQUIRED: "Обавезно је унети шифру",
  PACIJENT_POLJE_TIP_IS_REQUIRED: "Обавезно је унети тип поља",
  PACIJENT_POLJE_TITLE_DIALOG_CREATE: "Креирање поља пацијента ",
  PACIJENT_POLJE_TITLE_DIALOG_UPDATE: "Измена поља пацијента ",
  PACIJENT_POLJE_TITLE_DIALOG_DETAILS: "Детаљи поља пацијента ",
  PACIJENT_POLJE_TITLE_DIALOG_DELETE: "Брисање поља пацијента ",
  PACIJENT_POLJE_TITLE_MESSAGE_CREATE_PACIJENT_POLJE_SUCCESS: "Поље пацијента је успешно креирано",
  PACIJENT_POLJE_TITLE_MESSAGE_UPDATE_PACIJENT_POLJE_SUCCESS: "Поље пацијента је успешно измењено",
  PACIJENT_POLJE_TITLE_MESSAGE_DELETE_PACIJENT_POLJE_SUCCESS: "Поље пацијента је успешно обрисано",
  PACIJENT_POLJE_OBAVEZNO_JE_UNETI: "Обавезно је унети ",

  PACIJENT_POLJE_KATEGORIJA_LIST: "Категорије поља пацијента ",
  PACIJENT_POLJE_KATEGORIJA_KATEGORIJA: "Категорија ",
  PACIJENT_POLJE_KATEGORIJA_OBAVEZNO: "Обавезно ",
  PACIJENT_POLJE_KATEGORIJA_READONLY: "Само за читање ",
  PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_CREATE: "Креирање категорије за поље пацијента ",
  PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_UPDATE: "Измена категорије за поље пацијента ",
  PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DETAILS: "Детаљи категорије за поље пацијента ",
  PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DELETE: "Брисање категорије за поље пацијента ",
  PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_CREATE_PACIJENT_POLJE_SUCCESS: "Категорија за поље пацијента је успешно креирана",
  PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_UPDATE_PACIJENT_POLJE_SUCCESS: "Категорија за поље пацијента је успешно измењена",
  PACIJENT_POLJE_KATEGORIJA_TITLE_MESSAGE_DELETE_PACIJENT_POLJE_SUCCESS: "Категорија за поље пацијента је успешно обрисана",

  /*Polje tip */
  POLJE_TIP: "Тип поља",
  POLJE_TIP_LIST: "Типови поља",
  POLJE_TIP_NAZIV: "Назив поља",
  POLJE_TIP_SIFRA: "Шифра поља",
  POLJE_TIP_KOMPONENTA_TIP: "Тип компоненте",
  POLJE_TIP_STATUS_POLJA: "Статус поља",
  POLJE_TIP_TITLE_DIALOG_CREATE: "Креирање типа поља ",
  POLJE_TIP_TITLE_DIALOG_UPDATE: "Измена типа поља",
  POLJE_TIP_TITLE_DIALOG_DELETE: "Брисање типа поља",
  POLJE_TIP_SIFRA_IS_REQUIRED: "Обавезно је унети шифру.",
  POLJE_TIP_NAZIV_IS_REQUIRED: "Обавезно је унети назив.",
  POLJE_TIP_KOMPONENTA_TIP_IS_REQUIRED: "Обавезно је унети тип компоненте.",
  POLJE_TIP_STATUS_POLJA_IS_REQUIRED: "Обавезно је унети статус поља.",
  POLJE_TIP_SEARCH: "Претрага",
  SEARCH_POLJE_TIP: "Претрага типова поља",
  BUTTON_ADD_POLJE_TIP: "Додај тип поља",

  /*Polje tip enum stavka*/
  POLJE_TIP_ENUM_STAVKA_LIST: "Типови поља енум ставка",
  POLJE_TIP_ENUM_STAVKA_NAZIV: "Назив поља типа енум ставке",
  POLJE_TIP_ENUM_STAVKA_NAPOMENA: "Напомена поља типа енум ставке",
  POLJE_TIP_ENUM_STAVKA_OPIS: "Опис поља типа енум ставке",
  POLJE_TIP_ENUM_STAVKA_BROJNA_VREDNOST: "Бројна вредност",
  POLJE_TIP_ENUM_STAVKA_PRIKAZNI_REDOSLED: "Приказни редослед",
  POLJE_TIP_ENUM_STAVKA_TITLE_DIALOG_UPDATE: "Измена типа поља енум ставке",
  POLJE_TIP_ENUM_STAVKA_TITLE_DIALOG_DELETE: "Брисање типа поља енум ставке",
  BUTTON_ADD_POLJE_TIP_ENUM_STAVKA: "Додај тип поља енум ставке",
  TOOLTIP_CREATE_POLJE_TIP_ENUM_STAVKA: "Креирање поља типа енум ставке",

  /* STATISTIČKI IZVEŠTAJ PARAMETAR ENUM STAVKA */
  IZVESTAJ_PARAMETAR_ENUM_STAVKA_LIST: "Ставке параметра извештаја",
  IZVESTAJ_PARAMETAR_ENUM_STAVKA_NAZIV: "Назив ставке параметра извештаја",
  IZVESTAJ_PARAMETAR_ENUM_STAVKA_TITLE_DIALOG_UPDATE: "Измена ставке параметра извештаја",
  IZVESTAJ_PARAMETAR_ENUM_STAVKA_TITLE_DIALOG_DELETE: "Брисање ставке параметра извештаја",
  BUTTON_ADD_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Додај ставку параметра",
  TOOLTIP_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA: "Креирање ставке параметра статистичког извештаја",

  /*Polje tip repository */
  IMPORT_POLJE_TIP_REPOSITORY: "Преузми типове поља са репозиторијума",
  POLJE_TIP_REPOSITORY: "Репозиторијум типова поља",

  /*Polje */
  POLJE_LIST: "Поља формулара",
  POLJE_NAZIV: "Назив",
  POLJE_SIFRA: "Шифра",
  POLJE_OBAVEZNO: "Обавезно",
  POLJE_TITLE_DIALOG_CREATE: "Креирање поља ",
  POLJE_TITLE_DIALOG_UPDATE: "Измена поља",
  POLJE_TITLE_DIALOG_DELETE: "Брисање поља",
  POLJE_TITLE_DIALOG_DETAILS: "Детаљи поља",
  POLJE_SEARCH: "Претрага",
  SEARCH_POLJE: "Претрага поља",
  BUTTON_ADD_POLJE: "Додај поље",
  LABEL_POLJE: "Поље формулара",
  POLJE_ENTITY_STATUS_IS_REQUIRED: "Обавезно је унети статус прегледа за поље.",
  POLJE_NADREDJENO: "Надређено поље",
  POLJE_SQL_UPIT: "SQL упит",

  /* Tooltip */
  TOOLTIP_CREATE_POLJE: "Креирање поља формулара",
  TOOLTIP_UPDATE_POLJE: "Измена поља формулара",
  TOOLTIP_DELETE_POLJE: "Брисање поља формулара",

  /* radnik profil */
  RADNIK_PROFIL_CHANGE: "Измена корисничког профила",
  RADNIK_PROFIL_CHANGE_EMAIL: "Имејл",
  RADNIK_PROFIL_CHANGE_IME: "Име",
  RADNIK_PROFIL_CHANGE_PREZIME: "Презиме",
  RADNIK_PROFIL_CHANGE_JEZIK: "Писмо",
  RADNIK_PROFIL_BUTTON_SAVE: "Сачувај",
  RADNIK_PROFIL_BUTTON_CHANGE_PASSWORD: "Промена лозинке",
  RADNIK_PROFIL_BUTTON_CANCEL: "Поништи",
  RADNIK_PROFIL_CHANGE_PASSWORD: "Промена лозинке",
  RADNIK_PROFIL_PASSWORD_CHANGE_ERROR: "Грешка приликом промене лозинке.",
  RADNIK_PROFIL_MESSAGES_NEW_PASSWORD: "Морате унети нову лозинку.",
  RADNIK_PROFIL_MESSAGES_OLD_PASSWORD: "Морате унети стару лозинку.",
  RADNIK_PROFIL_MESSAGES_PASSWORD_CONFIRMATION: "Морате унети потврду нове лозинке.",
  RADNIK_PROFIL_MESSAGES_PASSWORDS_NOT_SAME: "Нова лозинка и потврда лозинке нису исте.",
  RADNIK_PROFIL_BUTTON_SAVE_NEW_PASSWORD: "Сачувај нову лозинку",
  RADNIK_PROFIL_OLD_PASSWORD: "Стара лозинка",
  RADNIK_PROFIL_NEW_PASSWORD: "Нова лозинка",
  RADNIK_PROFIL_CONFIRM_NEW_PASSWORD: "Потврда нове лозинке",
  RADNIK_PROFIL_EDIT_SUCCESS: "Успешно сачуване измене.",
  RADNIK_PROFIL_CHANGE_PASSWORD_SUCCESS: "Успешно промењена лозинка.",
  RADNIK_PROFIL_ODABERI_PODATKE: "Одабери овог радника",
  RADNIK_PROFIL_ODUSTANI_OD_ODABIRA: "Одустани",

  /** usluga */
  USLUGA: "Услуга",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA: "Листа планираних услуга",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_UKLONI_SVE: "Уклони све",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_PREBACI_SVE: "Пребаци sve",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_NEMA_PLANIRANIH_USLUGA: "Нема планираних услуга",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_BRISANJE_POJEDINACNE_USLUGE: "Планирана услуга успешно обрисана",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_REALIZOVANO_USPESNO: "Планиранe услугe успешно пребаченe у реализовану",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_POJEDINACNA_REALIZOVANA_USPESNO: "Планирана услуга успешно пребачена у реализовану",
  PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_BRISANJE_SVIH_USLUGA: "Планиранe услугe успешно обрисанe",
  USLUGA_LIST_TOKOM_KONTKAKTA: "Листа реализованих услуга током прегледа",
  USLUGA_BROJ_PRUZANJA: "Број пружања:",
  USLUGA_NAPOMENA: "Напомена:",
  USLUGA_PRUZIO: "Пружио/ла:",
  USLUGA_PRUZIO_MUSKO: "Пружио",
  USLUGA_PRUZIO_ZENSKO: "Пружила",
  USLUGA_NOVA: "Нова услуга",
  USLUGA_IZMENI: "Измени услугу",
  USLUGA_OBRISI: "Обриши услугу",
  USLUGA_IZMENA: "Измена услуге",
  USLUGA_BRISANJE: "Брисање услуге",
  USLUGA_DODAVANJE: "Додај услугу",
  USLUGA_UNOS: "Унос услуге:",
  USLUGA_TIP: "Тип услуге:",
  USLUGA_UNOS_OBAVEZAN: "Унос је обавезан податак",
  USLUGA_BROJ_PRUZANJA_OBAVEZAN: "Број пружања је обавезан податак",
  USLUGA_USPESNO_IZMENJENA: "Услуга успешно измењена",
  USLUGA_USPESNO_OBRISANA: "Услуга успешно обрисана",
  USLUGA_USPESNO_SACUVANA: "Услуга успешно сачувана",
  NO_USLUGA_CENA_WARNING: "Одабрали сте услугу која нема активну цену и са њом преглед неће моћи да се наплати. Да ли сте сигурни да желите да је додате?",
  NO_USLUGA_CENA_LIST_WARNING: "Одабрали сте услуге које немају активну цену и са њима преглед неће моћи да се наплати.",
  NO_USLUGA_CENA_LIST_WARNING_ACCEPT: "Да ли сте сигурни да желите да их додате заједно са услугама које сте изабрали, а већ имају цену?",

  /* utrosak */
  UTROSCI: "Утрошци",
  UTROSAK_BRISANJE: "Утрошак успешно обрисан",
  UTROSAK_DODAVANJE: "Утрошак успешно додат",
  UTROSAK_UNOS: "Утрошак успешно унет",
  UTROSAK_IZMENA: "Утрошак успешно измењен",
  UTROSAK_LIST_TOKOM_KONTAKTA: "Листа утрошка током прегледа",
  UTROSAK_NOVI: "Додај утрошак",
  UTROSAK_IZMENI: "Измени утрошак",
  UTROSAK_OBRISI: "Обриши утрошак",
  UTROSAK_BROJ_PRUZANJA: "Број",
  UTROSAK_NAPOMENA: "Напомена:",
  UTROSAK_BROJ_JE_OBAVEZAN_PODATAK: "Потрошена количина је обавезан податак",
  UTROSAK_USPESNO_KREIRAN: "Утрошак је успешно креиран",
  UTROSAK_USPESNO_IZMENJEN: "Утрошак је успешно измењен",
  UTROSAK_USPESNO_OBRISAN: "Утрошак је успешно обрисан",
  UTROSAK_DEO_NAZIVA_SIFRE: "Део назива или шифре",
  UTROSAK_POTROSENA_KOLICINA: "Потрошена количина:",
  UTROSAK: "Утрошак:",
  NO_PROIZVOD_CENA_WARNING: "Одабрали сте утрошак који нема активну цену и са њим преглед неће моћи да се наплати. Да ли сте сигурни да желите да га додате?",

  /* ustanova */
  SEARCH_USTANOVA: "Претрага установа",
  USTANOVA_NAZIV: "Назив",
  USTANOVA_NAZIV_KRATKI: "Назив кратки",
  USTANOVA_SIFRA: "Шифра",
  USTANOVA_ADRESA: "Адреса",
  USTANOVA_TELEFON: "Телефон",
  USTANOVA_JEZIK: "Писмо",
  USTANOVA_SVOJINA_TIP: "Тип својине",
  USTANOVA_NIVO_ZDRAVSTVENE_ZASTITE: "Ниво здравствене заштите",
  USTANOVA_PODRAZUMEVANA_STRANA: "Подразумевана страна",
  USTANOVA_SIFARNIK_VALUTA: "Подразумевана валута",
  USTANOVA_GPS_LOKACIJA: "ГПС локација",
  USTANOVA_FAKS: "Факс",
  USTANOVA_TEKUCI_RACUN: "Текући рачун",
  USTANOVA_PROTOKOL: "Протокол",

  USTANOVA_SEARCH: "Претрага",
  USTANOVA_LIST: "Установе",
  USTANOVA_TITLE_DIALOG_CREATE: "Креирање установе ",
  USTANOVA_TITLE_DIALOG_UPDATE: "Измена установе ",
  USTANOVA_TITLE_DIALOG_DETAILS: "Детаљи о установи ",
  USTANOVA_TITLE_DIALOG_DELETE: "Брисање установе ",
  USTANOVA_NAZIV_IS_REQUIRED: "Обавезно је унети назив установе.",
  USTANOVA_SIFRA_IS_REQUIRED: "Обавезно је унети шифру установе.",
  USTANOVA_NAZIV_KRATKI_IS_REQUIRED: "Обавезно је унети кратки назив установе.",
  USTANOVA_TIP_SVOJINE_IS_REQUIRED: "Обавезно је унети тип својине.",
  USTANOVA_NIVO_ZDRAVSTVENE_ZASTITE_IS_REQUIRED: "Обавезно је унети ниво здравствене заштите.",
  USTANOVA_PODRAZUMEVANA_STRANA_IS_REQUIRED: "Обавезно је унети подразумевану страну.",
  USTANOVA_TITLE_MESSAGE_DELETE_USTANOVA_SUCCESS: "Установа је успешно обрисана.",
  USTANOVA_TITLE_MESSAGE_CREATE_USTANOVA_SUCCESS: "Установа је успешно креирана.",
  USTANOVA_TITLE_MESSAGE_UPDATE_USTANOVA_SUCCESS: "Установа је успешно измењена.",
  USTANOVA_LOGO: "Лого",
  USTANOVA_CHOOSE_LOGO: "Изаберите лого",
  USTANOVA_MAX_SIZE_LOGO: "Максимална величина слике је 5 MB",
  USTANOVA_MAX_FILE_SIZE_OVERLOADED: " датотека није одговарајуће величине",
  USTANOVA_FILE_TYPE_NOT_SUPPORTED: " није подржан тип датотеке",
  USTANOVA_GRID_TITLE_MESSAGE_UPDATE_USTANOVA_GRID_SUCCESS: "Подешавање грида установе је успешно измењено",
  USTANOVA_GRID_VREME_TRAJANJA_PREGLEDA: "Време трајања прегледа",
  USTANOVA_GRID_VANREDNI_TERMINI: "Ванредни термини",
  USTANOVA_GRID_RADNI_DANI_U_NEDELJI: "Радни дани у недељи",
  USTANOVA_GRID_ZAKAZIVANJA: "Распоред",
  USTANOVA_GRID_SPISAK_PACIJENATA_JASPER: "Списак пацијената јаспер документ",
  USTANOVA_GRID_SPISAK_PACIJENATA_JASPER_CHOOSE: "Изаберите јаспер",
  USTANOVA_GRID_OMOGUCENA_REZERVACIJA: "Омогућена резервација термина",
  USTANOVA_GRID_PODRAZUMEVANA_DUZINA_TRAJANJA_REZERVACIJE: "Подразумевана дужина трајања резервације термина",
  USTANOVA_GRID_MAKSIMALNA_DUZINA_TRAJANJA_REZERVACIJE: "Максимална дужина трајања резервације термина",
  USTANOVA_GRID_POTREBNI_RADNI_PERIODI_ZA_ZAKAZIVANJE: "Потребни радни периоди за заказивање",
  USTANOVA_LICENCE: "Лиценце",
  USTANOVA_PARAMETRI: "Подешавање параметра установе",
  USTANOVA_ULOGE: "Улоге",
  USTANOVA_TIP_KONTAKTA: "Типови прегледа",
  USTANOVA_RADNICI: "Радници",
  USTANOVA_WEBSITE_URL: "Веб-сајт адреса",
  USTANOVA_RADNO_VREME_OD: "Радно време од",
  USTANOVA_RADNO_VREME_DO: "Радно време до",
  USTANOVA_BROJ_NALOGA: "Установа број налога",
  USTANOVA_BROJ_NALOGA_BUTTON_DODAJ: "Додај нову везу",
  USTANOVA_BROJ_NALOGA_TITLE_CREATE: "Креирање броја налога",
  USTANOVA_BROJ_NALOGA_TITLE_UPDATE: "Ажурирај број налога",
  USTANOVA_BROJ_NALOGA_TITLE_DELETE: "Обриши број налога",
  USTANOVA_BROJ_NALOGA_IS_REQUIRED: "Број налога је обавезан податак",
  USTANOVA_VAZI_OD_IS_REQUIRED: "Важи од је обавезан податак",
  USTANOVA_VAZI_OD_VECA_OD_DANAS: "Важи од не сме бити мање од данашњег дана",
  USTANOVA_VAZI_OD_VECA_OD_VAZI_DO: "Важи од не може бити веће од важи до",
  USTANOVA_STORAGE_LIMIT_IS_REQUIRED: "Ограничење складишта установе је обавезан податак ",
  USTANOVA_VREME_OD_REQUIRED: "Обавезно је унети почетак радног времена",
  USTANOVA_VREME_DO_REQUIRED: "Обавезно је унети крај радног времена",
  USTANOVA_LOGO_UPLOAD: "Лого установе",
  USTANOVA_PODESAVANJA: "Подешавања",
  USTANOVA_PODESAVANJA_UPDATE_SUCCESS: "Подешавања установе су успешно измењена",
  USTANOVA_PODESAVANJA_PODSETNIK_PACIJENT: "Време слања мејла подсетника пацијенту за предстојећи преглед",
  USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA: "Аутоматско раздуживање планираних услуга",
  USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA_POTVRDA: "Потврда аутоматског раздуживања",
  USTANOVA_PODESAVANJA_AUTOMATSKO_RAZDUZIVANJE_PLANIRANIH_USLUGA_DIALOG_CONTENT:
    "Укључивање аутоматског раздуживања планираних услуга значи да се све планиране услуге, па и оне које немају дефинисану цену, аутоматски пребацују у реализоване при започињању прегледа.",
  USTANOVA_NADJIMED: "НађиМеД",
  USTANOVA_NADJIMED_LABEL_CENOVNICI_USLUGA: "Ценовници услуга",
  USTANOVA_NADJIMED_LABEL_ORGANIZACIONE_JEDINICE: "Организационе јединице",
  USTANOVA_NADJIMED_LABEL_DOKTORI: "Доктори",
  USTANOVA_NADJIMED_LABEL_INICIJALIZACIJA: "Иницијализација",
  USTANOVA_SINHRONIZUJ: "Синхронизуј",
  USTANOVA_INICIJALIZUJ: "Иницијализуј",
  USTANOVA_NADJIMED_LABEL_CENOVNICI_USLUGA_SUCCESS_MESSAGE: "Успешно сте синхронизовали ценовника услуга",
  UUSTANOVA_NADJIMED_LABEL_ORGANIZACIONE_JEDINICE_SUCCESS_MESSAGE: "Успешно сте синхронизовали организационе јединице",
  USTANOVA_NADJIMED_LABEL_DOKTORI_SUCCESS_MESSAGE: "Успешно сте синхронизовали докторе",
  USTANOVA_NADJIMED_LABEL_INICIJALIZACIJA_SUCCESS_MESSAGE: "Успешна иницијализација",

  /* ustanova eksterni sistem */
  USTANOVA_EKSTERNI_SISTEM: "Екстерни системи",
  EKSTERNI_SISTEM: "Екстерни систем",
  BUTTON_ADD_USTANOVA_EKSTERNI_SISTEM: "Додај подешавање за екстерни систем",
  USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_CREATE: "Креирај подесавање за екстерни систем",
  USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_UPDATE: "Измени подешавање за екстерни систем",
  USTANOVA_EKSTERNI_SISTEM_TITLE_DIALOG_DELETE: "Обриши подешавање за екстерни систем",
  USTANOVA_EKSTERNI_SISTEM_TITLE_UPDATE: "Измена подешавања екстерног система ",
  USTANOVA_EKSTERNI_SISTEM_TITLE_DETAILS: "Детаљи подешавања екстерног система ",
  USTANOVA_EKSTERNI_SISTEM_TITLE_DELETE: "Брисање подешавања екстерног система ",
  USTANOVA_EKSTERNI_SISTEM_TITLE_CREATE: "Креирање подешавања екстерног система ",
  USTANOVA_EKSTERNI_SISTEM_EKSTERNI_SISTEM_IS_REQUIRED: "Обавезно је унети екстерни систем",
  USTANOVA_EKSTERNI_SISTEM_USERNAME_IS_REQUIRED: "Обавезно је унети username",
  USTANOVA_EKSTERNI_SISTEM_PASSWORD_IS_REQUIRED: "Обавезно је унети password",
  USTANOVA_EKSTERNI_SISTEM_MESSAGE_CREATE_SUCCESS: "Подешавање екстерног система је успешно креирано",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_EKSTERNI_SISTEM_1: "Креирано подешавање за екстерни систем",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_EKSTERNI_SISTEM_2: "са username-ом ",
  USTANOVA_EKSTERNI_SISTEM_MESSAGE_UPDATE_SUCCESS: "Подешавање екстерног система је успешно измењено",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_EKSTERNI_SISTEM_1: "Измењено подешавање за екстерни систем",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_EKSTERNI_SISTEM_2: " username је сада",
  USTANOVA_EKSTERNI_SISTEM_TITLE_MESSAGE_DELETE_USTANOVA_EKSTERNI_SISTEM_SUCCESS: "Подешавање екстерног система је успешно избрисано",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_EKSTERNI_SISTEM_1: "Обрисано подешавање за екстерни систем ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_EKSTERNI_SISTEM_2: " са username-ом ",

  /* organizaciona jedinica */
  SEARCH_ORGANIZACIONA_JEDINICA: "Претрага организационих јединица",
  ORGANIZACIONA_JEDINICA_NAZIV: "Назив",
  ORGANIZACIONA_JEDINICA_TIP: "Тип",
  ORGANIZACIONA_JEDINCA_NADREDJENA: "Надређена организациона јединица",
  ORGANIZACIONA_JEDINICA_ADRESA: "Адреса",
  ORGANIZACIONA_JEDINICA_TELEFON: "Телефон",
  ORGANIZACIONA_JEDINICA_RADNI_DANI: "Радни дани",
  ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE: "Специјализације",
  ORGANIZACIONA_JEDINICA_LABEL_SPECIJALIZACIJE_LIST: "Специјализације организационе јединице",
  ORGANIZACIONA_JEDINICA_LABEL_SPECIJALIZACIJE_LIST_SEARCH: "Претрага специјализација организационе јединице",
  ORGANIZACIONA_JEDINICA_DOSTUPNA_ZA_EKSTERNE_ZAKAZIVACE: "Доступна за екстерне заказиваче",

  ORGANIZACIONA_JEDINICA_SEARCH: "Претрага",
  ORGANIZACIONA_JEDINICA_LIST: "Организационе јединице",
  ORGANIZACIONA_JEDINICA_TITLE_DIALOG_CREATE: "Креирање организационе јединице ",
  ORGANIZACIONA_JEDINICA_TITLE_DIALOG_UPDATE: "Измена организационе јединице ",
  ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DELETE: "Брисање организационе јединице ",
  ORGANIZACIONA_JEDINICA_TITLE_DIALOG_DETAILS: "Детаљи организационе јединице ",
  ORGANIZACIONA_JEDINICA_NADREDJENA_ORGANIZACIONA_JEDINICA: "Надређена организациона јединица",
  ORGANIZACIONA_JEDINICA_TIP_ORGANIZACIONE_JEDINICE: "Тип организационе јединице",
  ORGANIZACIONA_JEDINICA_NAZIV_IS_REQUIRED: "Обавезно је унети назив.",
  ORGANIZACIONA_JEDINICA_TIP_IS_REQUIRED: "Обавезно је унети тип.",
  ORGANIZACIONA_JEDINICA_RADNI_DANI_IS_REQUIRED: "Радни дани не смеју бити празно поље!",
  ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_CREATE_ORGANIZACIONA_JEDINICA_SUCCESS: "Организациона јединица је успешно креирана.",
  ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_UPDATE_ORGANIZACIONA_JEDINICA_SUCCESS: "Организациона јединица је успешно измењена.",
  ORGANIZACIONA_JEDINICA_TITLE_MESSAGE_DELETE_ORGANIZACIONA_JEDINICA_SUCCESS: "Организациона јединица је успешно обрисана.",
  ORGANIZACIONA_JEDINICA_RADNO_VREME_OD: "Радно време од",
  ORGANIZACIONA_JEDINICA_RADNO_VREME_DO: "Радно време до",

  /* radni period */
  RADNI_PERIOD_LIST: "Радни периоди",
  RADNI_PERIOD_COLUMN_ORGANIZACIONA_JEDINICA: "Организациона јединица",
  RADNI_PERIOD_COLUMN_RADNIK: "Радник",
  RADNI_PERIOD_COLUMN_DATUM_OD: "Датум од",
  RADNI_PERIOD_COLUMN_DATUM_DO: "Датум до",
  RADNI_PERIOD_COLUMN_POCETAK_PERIODA: "Почетак периода",
  RADNI_PERIOD_COLUMN_KRAJ_PERIODA: "Крај периода",
  RADNI_PERIOD_BUTTON_ADD: "Додај радни период",
  RADNI_PERIOD_SEARCH: "Претрага",
  RADNI_PERIOD_CREATE: "Креирање радног периода ",
  RADNI_PERIOD_COLUMN_RADNI_DANI: "Радни дани у недељи",
  RADNI_PERIOD_VREME_POCETKA: "Време почетка радног дана",
  RADNI_PERIOD_VREME_KRAJ: "Време краја радног дана",
  RADNI_PERIOD_PAUZA_POCETAK: "Време почетка паузе",
  RADNI_PERIOD_PAUZA_KRAJ: "Време краја паузе",
  RADNI_PERIOD_TRAJANJE_PREGLEDA: "Трајање прегледа",
  RADNI_PERIOD_OPIS: "Опис",
  RADNI_PERIOD_COLUMN_DATUM: "Датум",
  RADNI_PERIOD_COLUMN_RADNI_DAN: "Радни дан",
  RADNI_PERIOD_DANI_U_NEDELJI_IS_REQUIRED: "Обавезно је унети дане у недељи",
  RADNI_PERIOD_TITLE_MESSAGE_CREATE_RADNI_PERIOD_SUCCESS: "Успешно креиран радни период",
  RADNI_PERIOD_CONFIRM_DELETE_MESSAGE: "Да ли сте сигурни да желите да обришете радни период?",
  RADNI_PERIOD_CONFIRM_DELETE_SELECTED_MESSAGE: "Да ли сте сигурни да желите да обришете селектоване радне периоде?",
  RADNI_PERIOD_TITLE_DELETE_SELECTED: "Обришите селектоване радне периоде",
  RADNI_PERIOD_POGRESNO_RADNO_VREME: "Погрешно радно време унето",
  RADNI_PERIOD_POGRESNAN_RADNI_PERIOD: "Погрешан радни период унет",
  RADNI_PERIOD_DATUM_OD_REQUIRED: "Датум од је обавезно поље",
  RADNI_PERIOD_DATUM_DO_REQUIRED: "Датум до је обавезно поље",
  RADNI_PERIOD_VREME_POCETKA_RADNOG_DANA_REQUIRED: "Време почетка радног дана је обавезно поље",
  RADNI_PERIOD_VREME_KRAJA_RADNOG_DANA_REQUIRED: "Време краја радног дана је обавезно поље",
  RADNI_PERIOD_POGRESNA_PAUZA: "Погрешна пауза унета",
  RADNI_PERIOD_NEDOSTUPAN_ZA_EKSTERNE_ZAKAZIVACE: "Недоступан за екстерне заказиваче",

  /* vrsta pregleda */
  TIP_KONTAKTA: "Тип прегледа",
  TIP_KONTAKTA_LIST: "Типови прегледа",
  TIP_KONTAKTA_NAZIV: "Назив",
  TIP_KONTAKTA_SIFRA: "Шифра",
  TIP_KONTAKTA_AUTOMATSKO_ZAVRSAVANJE: "Аутоматско завршавање",
  TIP_KONTAKTA_KATEGORIJA_KONTAKTA: "Категорија прегледа",
  TIP_KONTAKTA_BUTTON_SEARCH: "Претрага типова прегледа",
  TIP_KONTAKTA_TITLE_DIALOG_CREATE: "Креирање типа прегледа ",
  TIP_KONTAKTA_TITLE_DIALOG_UPDATE: "Измена типа прегледа ",
  TIP_KONTAKTA_TITLE_DIALOG_DELETE: "Брисање типа прегледа ",
  TIP_KONTAKTA_TITLE_DIALOG_DETAILS: "Детаљи типа прегледа ",
  TIP_KONTAKTA_TITLE_MESSAGE_DELETE_TIP_KONTAKTA_SUCCESS: "Тип прегледа је успешно обрисан.",
  TIP_KONTAKTA_TITLE_MESSAGE_CREATE_TIP_KONTAKTA_SUCCESS: "Тип прегледа је успешно креиран.",
  TIP_KONTAKTA_TITLE_MESSAGE_UPDATE_TIP_KONTAKTA_SUCCESS: "Тип прегледа је успешно измењен.",
  TIP_KONTAKTA_NAZIV_IS_REQUIRED: "Обавезно је унети назив типа прегледа.",
  TIP_KONTAKTA_SIFRA_IS_REQUIRED: "Обавезно је унети шифру типа прегледа.",
  TIP_KONTAKTA_KATEGORIJA_KONTAKTA_IS_REQUIRED: "Обавезно је унети категорију прегледа за тип прегледа.",
  TIP_KONTAKTA_ENTITY_STATUS_IS_REQUIRED: "Обавезно је унети статус прегледа за тип прегледа.",
  TIP_KONTAKTA_VREME_TRAJANJA_PREGLEDA: "Време трајања прегледа",
  TIP_KONTAKTA_PODRAZUMEVANI_FORMULAR: "Подразумевани формулар",

  IMPORT_TIP_KONTAKTA_REPOSITORY: "Преузми тип прегледа са репозиторијума",
  TIP_KONTAKTA_REPOSITORY: "Репозиторијум типова прегледа",

  /*uloga*/
  SEARCH_ULOGA: "Претрага улога",
  ULOGA_LIST: "Улоге",
  ULOGA_SIFRA: "Шифра",
  ULOGA_NAZIV: "Назив",
  ULOGA_OPIS: "Опис",
  ULOGA_NAPOMENA: "Напомена",
  ULOGA_ROLA: "Рола",
  ULOGA_SEARCH: "Претрага",
  ULOGA_TITLE_DIALOG_CREATE: "Креирање улоге ",
  ULOGA_TITLE_DIALOG_UPDATE: "Измена улоге ",
  ULOGA_TITLE_DIALOG_DELETE: "Брисање улоге ",
  ULOGA_TITLE_DIALOG_DETAILS: "Детаљи улоге ",
  ULOGA_TITLE_MESSAGE_DELETE_ULOGA_SUCCESS: "Улога је успешно обрисана.",
  ULOGA_TITLE_MESSAGE_CREATE_ULOGA_SUCCESS: "Улога је успешно креирана.",
  ULOGA_TITLE_MESSAGE_UPDATE_ULOGA_SUCCESS: "Улога  је успешно измењена.",
  ULOGA_NAZIV_IS_REQUIRED: "Обавезно је унети назив.",
  ULOGA_SIFRA_IS_REQUIRED: "Обавезно је унети шифру.",
  ULOGA_ROLA_IS_REQUIRED: "Обавезно је унети ролу",

  // Zakazivanje pregleda
  ZAKAZIVANJE_ZAKAZIVANJE_PREGLEDA: "Заказивање прегледа",
  ZAKAZIVANJE_TERMINI: "Термини",
  ZAKAZIVANJE_ZAKAZIVANJE: "Заказивање",
  ZAKAZIVANJE_CEKAONICA: "Чекаоница",
  ZAKAZIVANJE_PRETRAGA_PACIJENATA: "Претрага пацијената",
  ZAKAZIVANJE_RADNIK_IS_REQUIRED: "Лекар је обавезно поље",
  ZAKAZIVANJE_PREZIME_IS_REQUIRED: "Презиме је обавезно поље",
  ZAKAZIVANJE_IME_IS_REQUIRED: "Име је обавезно поље",
  ZAKAZIVANJE_TIP_KONTAKTA_IS_REQUIRED: "Тип прегледа је обавезно поље",
  ZAKAZIVANJE_PROMENA_TERMINA_PROMENI: "Промени",
  ZAKAZIVANJE_PROMENA_TERMINA_ODUSTANI: "Одустани",
  ZAKAZIVANJE_PROMENA_TERMINA_DIALOG_POTVRDA: "Да ли сте сигурни да желите да померите термин у ново време?",
  ZAKAZIVANJE_PROMENA_TERMINA_USPESNO: "Успешно сте променили време термина!",
  ZAKAZI_PREGLED: "Закажи преглед",
  CEKAONICA_COLUMN_SVE: "Све",
  CEKAONICA_COLUMN_STATUS: "Статус",
  CEKAONICA_COLUMN_VREME: "Време",
  CEKAONICA_COLUMN_PACIJENT: "Пацијент",
  CEKAONICA_COLUMN_VREME_DOLASKA: "Време доласка",
  CEKAONICA_COLUMN_DATUM_RODJENJA: "Датум рођења",
  CEKAONICA_COLUMN_DIJAGNOZE: "Дијагнозе",
  CEKAONICA_COLUMN_NALAZ: "Налази",
  CEKAONICA_COLUMN_ZAPOCNI_TERMIN: "Започни",
  CEKAONICA_COLUMN_ZAPOCNI_TERMIN_TOOLTIP: "Започни преглед",
  CEKAONICA_COLUMN_ZAVRSI_TERMIN: "Заврши",
  CEKAONICA_COLUMN_ZAVRSI_TERMIN_TOOLTIP: "Заврши преглед",
  CEKAONICA_PACIJENT_ORDINACIJA: "Ординација",
  CEKAONICA_PACIJENT_VREME: "Време",
  CEKAONICA_PACIJENT_PACIJENT: "Пацијент",
  CEKAONICA_PACIJENT_DATUM_RODJENJA: "Датум рођења",
  CEKAONICA_PACIJENT_ADRESA: "Адреса",
  CEKAONICA_PACIJENT_BROJ_TELEFONA: "Број телефона",
  CEKAONICA_PACIJENT_USLUGA: "Услуга",
  LISTA_RADNIKA_LISTA_DOKTORA: "Листа доктора",
  LISTA_RADNIKA_PRETRAGA_PLACEHOLDER: "Претрага радника",
  DA_LI_STE_SIGURNI_ZATVARANJE_KONTAKATA: "Да ли сте сигурни да желите да затворите изабране прегледе?",
  CEKAONICA_BUTTON_PROMENA_DOKTORA: "Промена доктора",
  CEKAONICA_BUTTON_ZATVARANJE_KONTAKATA: "Затварање контаката",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE: "Да ли сте сигурни да желите да обришете?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_POPUNJEN_FORMULAR: "Да ли сте сигурни да желите да обришете попуњен формулар?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_VREME_SMRTI: "Да ли сте сигурни да желите да обришете време смрти?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_JR_DOKUMENT: "Да ли сте сигурни да желите да обришете jasper документ?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_SABLON: "Да ли сте сигурни да желите да обришете шаблон?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_PACIJENT: "Да ли сте сигурни да желите да обришете пацијента?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_PROFILNU_SLIKU: "Да ли сте сигурни да желите да обришете профилну слику?",
  IRREVERSIBLE_ACTION: "Ова акција је неповратна!",
  PERMANENT_DELETE_PART_1: "Брисањем пацијента",
  PERMANENT_DELETE_PART_2: "трајно се бришу све информације о њему укључујући и сви његови прегледи.",
  DELETE_ZAKAZAN_TERMIN: "Обриши термин",
  CONFIRM_DELETE_ZAKAZAN_TERMIN: "Да ли сте сигурни да желите да обришете термин?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_KONTAKT: "Да ли сте сигурни да желите да обришете преглед?",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_UPOZORENJE: "Да ли сте сигурни да желите да обришете упозорење?",
  NESACUVAN_NALAZ: "Несачуван налаз",
  CEKAONICA_COLUMN_ADRESA: "Адреса",
  CEKAONICA_COLUMN_TELEFON: "Број телефона",
  CEKAONICA_COLUMN_EMAIL: "Имејл",
  CEKAONICA_PACIJENT_EMAIL: "Имејл",
  ZAKAZIVANJE_PREGLEDA_UPOZORENJE_PACIJENT: 'Кликом на "Закажи" подаци о пацијенту ће бити измењени и у досијеу',
  ZAKAZIVANJE_PREGLEDA_UPDATE_UPOZORENJE_PACIJENT: 'Кликом на "Измени" подаци о пацијенту ће бити измењени и у досијеу',
  ZAKAZIVANJE_PREGLEDA_ADD_COMMENT_SUCCESS_MESS: "Успешно сте додали коментар.",
  ZAKAZIVANJE_PREGLEDA_ADD_FILE_BUTTON_LABEL: "Учитајте датотеку или је превуците у ово поље",
  ZAKAZIVANJE_PREGLEDA_ADD_FILE_SUCCESS_MESS: "Успешно сте додали датотеку.",
  ZAKAZIVANJE_PREGLEDA_REDOVNI_TERMIN: "Редовни термин",
  ZAKAZIVANJE_PREGLEDA_VANREDNI_TERMIN: "Ванредни термин",
  PRIKAZI_NEAKTIVNE_RADNIKE: "Прикажи и неактивне раднике",
  POCETAK_I_KRAJ_TERMINA_NOT_VALID: "Почетак термина мора бити пре краја термина",
  DATUM_I_VREME_REQUIRED: "Датум и време термина су обавезна поља",

  POL: "Пол",
  DATUM_RODJENJA: "Датум рођења",
  OSIGURANJE: "Осигурање",
  JMBG: "ЈМБГ",
  UZRAST: "Узраст",

  DOKUMENTACIJA: "Документација",
  ARHIVIRANI_PODACI: "архивирани подаци",
  ISTORIJA_OSIGURANJA: "Историја осигурања",
  PODACI_O_PACIJENTU: "Подаци о пацијенту",
  PODACI_O_SMRTI: "Подаци о смрти",
  VREME_SMRTI: "Време смрти",
  POLISE_OSIGURANJA: "Полисе осигурања",
  ISPRAVA: "Исправа",
  BROJ_POLISE: "Број полисе",
  OSIGURANJE_TIP: "Тип осигурања",
  OSIGURANJE_TIP_PRIVATNO: "Приватно",
  OSIGURANJE_TIP_PARTNER: "Партнер",
  PRIVATNIK_NAZIV: "Назив приватника",
  PARTNER_NAZIV: "Назив партнера",
  VAZI_OD: "Важи од",
  VAZI_DO: "Важи до",
  PIB: "ПИБ",
  VREME_POSLEDNJE_IZMENE: "Време последње измене",
  VREME_OD: "Време од",
  VREME_DO: "Време до",
  PODACI_O_KONTAKTU: "Подаци о прегледу",
  PROTOKOL: "Протокол",
  LICNI_PODACI: "Лични подаци",
  PODESAVANJA_ZAKAZIVANJA: "Подешавање заказивања",
  PODESAVANJA_ZAKAZIVANJA_POLJE: "Подешавање поља",
  USTANOVA: "Установа",
  NALAZ: "Налаз",
  ADMINISTRATIVNI_PODACI: "Административни подаци",
  KONTAKT_OSOBE: "Преглед особе",
  MEDICINSKI_PODACI: "Медицински подаци",
  PARTNER: "Партнер",
  OPSTI_MEDICINSKI_PODACI: "Општи медицински подаци",
  NIJE_IZABRAN: "Није изабран",
  PRIMARNI_DOKTOR: "Примарни доктор",
  PRIMARNI_PACIJENT: "Примарни пацијент",
  VANREDNI: "Ванредни",
  LEGENDA: "Легенда",
  RESET_PASSWORD_POPUP_MESSAGE: "Да ли сте сигурни да желите да ресетујете лозинку?",
  DOKUMENT: "Документ",
  CHOOSE_DOCUMENT: "Изабери документ",
  REMOVE_DOKUMENT: "Избаците документ",
  DOKUMENT_ADD: "Додај документ",
  DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_DOKUMENT: "Да ли сте сигурни да желите да обришете документ",
  EMAIL_WRONG_FORMAT: "Email је погрешног формата",
  TELEFON_WRONG_FORMAT: "Телефон је погрешног формата",
  PODACI: "Подаци",
  ZAKAZANI_PREGLEDI_LABEL: "Заказани прегледи",
  ZAKAZANI_PREGLEDI_VREME_PREGLEDA: "Време прегледа",
  ZAKAZANI_PREGLEDI_ORGANIZACIONA_JEDINICA: "Организациона јединица",
  ZAKAZANI_PREGLEDI_TIP_PREGLEDA: "Тип прегледа",
  ZAKAZANI_PREGLEDI_PRIMARNI_DOKTOR: "Примарни доктор",

  PRETHODNI_NALAZI: "Претходни налази",
  PRETHODNI_NALAZI_NOT_FOUND: "Нема претходних налаза",
  SABLON_LIST: "Шаблони",
  SABLON_ISPIS: "Испис шаблона",
  SABLON_CHOOSE_POLJE: "Морате изабрати текстуално поље за шаблон",
  SABLON_ADD: "Додај нови шаблон",
  SABLON_UPDATE: "Промени шаблон",
  SABLON_NASLOV: "Наслов:",
  SABLON_TEKST: "Текст:",
  SABLON_NASLOV_IS_REQUIRED: "Наслов је обавезно поље",
  SABLON_TEKST_IS_REQUIRED: "Текст је обавезно поље",
  SABLON_POLJE_NAZIV: "Назив поља: ",

  PRETRAGA_PACIJENTA: "Претрага пацијента",
  YES: "Да",
  NO: "Не",
  FORMULAR_NAME: "Назив формулара",
  OBRISI_KONTAKT_TOOLTIP: "Обриши преглед",
  NAPLATI_KONTAKT_TOOLTIP: "Наплати прегледе",
  LISTA_DATOTEKA: "Листа датотека",
  MESSAGES_DELETE_DOCUMENT_SUCCESS: "Успешно сте обрисали датотеку.",
  DODAJ_DATOTEKU_PACIJENT_DOSIJE: "Додај датотеку у досије пацијента",
  DODAJ_DATOTEKU_KONTAKT: "Додај датотеку на прегледу",
  LISTA_DATOTEKA_PRAZNA: "Не постоје отпремљене датотеке",
  DATOTEKE: "Датотеке",
  UPOZORENJE: "Упозорење",
  POSTAVI_UPOZORENJE: "Постави упозорење",
  OBRISI_UPOZORENJE: "Обриши упозорење",
  NOVO_UPOZORENJE: "Ново упозорење",

  PREGLED_NALAZA_DIJAGNOZA: "Дијагноза",
  PREGLED_NALAZA_USLUGA: "Услуга",
  PREGLED_NALAZA_KOLICINA: "количина",
  PREGLED_NALAZA_STAMPAJ: "Штампај",
  PREGLED_NALAZA_PRISTUP_KONTAKTU: "Приступ прегледу",
  RIZICI: "Ризици",
  PREGLED_NALAZA_PRIKAZI: "Прикажи",

  LISTA_DOKUMENATA_NASLOV: "Листа докумената (Извештаји, упути...)",
  LISTA_DOKUMENATA_ORG_JED: "Орг. јединица",
  LISTA_DOKUMENATA_DATUM: "Датум",

  ZAKAZI_TERMIN: "Закажи термин",
  ZAKAZI_VANREDAN_TERMIN: " Закажи ванредан термин",
  ZAKAZAN_TERMIN: "Заказан термин",
  ZAKAZAN_VANREDAN_TERMIN: "Заказан ванредан термин",
  ZAKAZI: "Закажи",
  LEKAR: "Лекар",
  SESTRA: "Сестра",
  VREME: "Време",
  ZAKAZAN_TERMIN_STATUS: "Статус термина",
  ZAKAZAN_TERMIN_PLANIRANE_USLUGE: "Планиране услуге",
  ZAKAZAN_TERMIN_PLANIRANE_USLUGE_PRETRAGA_USLUGE: "Претражи услугу",
  ZAKAZAN_TERMIN_PLANIRANE_USLUGE_EMPTY_FILTER_MESSAGE: "Нема резултата претраге",
  ZAKAZAN_TERMIN_PLANIRANE_USLUGE_EMPTY_OPTIONS_MESSAGE: "Нема пронађених услуга",
  POSTOJECI_PACIJENT: "Постојећи пацијент",
  NOVI_PACIJENT: "Нови пацијент",
  ZAKAZIVANJE_PODACI_O_TERMINU: "Подаци о термину",
  ZAKAZIVANJE_PODACI_O_PACIJENTU: "Подаци о пацијенту",
  ZAKAZIVANJE_DATOTEKE: "Датотеке",
  ZAKAZIVANJE_KOMENTARI: "Коментари",

  KOPIRAJ: "Копирај",
  KOPIRAJ_FORMULAR: "Копирај формулар",

  DA_LI_STE_SIGURNI_PROMENA_PODRAZUMEVANE_STRANE: "Да ли желите да вам се тренутна страна приказује након пријаве на систем?",

  // pregled
  KONTAKT: "Преглед",
  DIJAGNOZE: "Дијагнозе",
  USLUGE: "Услуге",
  NALAZI: "Налази",
  TERAPIJE: "Терапије",
  PAKETI: "Пакети",
  KONTAKT_NOVI_NALAZ: "Нови налаз",
  KONTAKT_NOVI_UCESNIK: "Нови учесник",
  KONTAKT_NAPRAVLJENI_NALAZI: "Направљени налази",
  KONTAKT_ODUSTANI: "Одустани",
  KONTAKT_IDI: "Иди",
  KONTAKT_NAZAD: "Назад",
  KONTAKT_NALAZ: "Налаз",
  KONTAKT_DETALJI_PREGLEDA: "Одабери тип прегледа",
  KONTAKT_KREIRAJ_NOVI_NALAZ: "Креирај нови налаз",
  KONTAKT_KREIRAJ_NOVI_UCESNIK: "Креирај новог учесника",
  KONTAKT_PRETHODNI_NALAZI_SABLONI: "Претходни налази и шаблони",
  KONTAKT_UVECAJ_UNOS: "Увећај поље за унос",
  KONTAKT_SMANJI_UNOS: "Смањи поље за унос",
  KONTAKT_SVI_NALAZI: "Сви налази",
  SAVE_KONTAKT_PODACI: "Сачувај податке о прегледу",
  KONTAKT_PODACI_USPESNO_IZMENJEN: "Успешно су измењени подаци о прегледу",
  KONTAKT_UCESNIK_DELETE_SUCCESS: "Успешно обрисан учесник са прегледа",
  KONTAKT_UCESNIK_CREATE_SUCCESS: "Успешно додат учесник на преглед",
  KONTAKT_CONFIRM_DELETE_UCESNIK: "Да ли сте сигурни да желите да обришете учесника",
  KONTAKT_NOVA_TERAPIJA: "Нова терапија",
  KONTAKT_NOVA_TERAPIJA_CREATE: "Терапија је успешно креирана.",
  KONTAKT_PREPISANE_TERAPIJE: "Преписане терапије",
  KONTAKT_TERAPIJA_DELETE: "Обриши терапију",
  KONTAKT_TERAPIJA_DELETE_SUCCESS: "Терапија је успешно обрисана.",
  KONTAKT_TERAPIJA_VIEW: "Преглед терапије",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_DIJAGNOZA: "Дијагноза",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK: "Лек",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK_ATRIBUT: "Атрибут лека",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_TIP_TERAPIJE: "Тип терапије",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PROTOKOLA: "Број паковања",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_IZDAVANJA: "Број издавања",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_TRAJANJE_TERAPIJE: "Трајање терапије",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA: "Број паковања",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA_MESEC: ". месец :",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_BROJ_PAKOVANJA_PAKOVANJE: " паковања",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA: "Начин дозирања",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_DNEVNO: " пута дневно по ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_UJUTRO: " ујутру  ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_POPODNE: " поподне ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_UVECE: " увече",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO: "Једнократно ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_NA: "На ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_DANA: " дана",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_PO_POTREBI: "По потреби ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NACIN_DOZIRANJA_PROIZVOLJNO: "Произвољно ",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_HITNO_IZDAVANJE: "Хитно издавање",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_ZABRANA_TERAPIJSKE_PARALELE: "Забрана терапијске паралеле",
  KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_NAPOMENA: "Напомена",
  KONTAKT_TERAPIJA_DIALOG_TITLE: "Терапија",
  KONTAKT_TERAPIJA_DIALOG_FIRST_PAGE_TITLE: "Постављене дијагнозе",
  KONTAKT_TERAPIJA_DIALOG_FIRST_PAGE_NO_DIAGNOSIS_NOTE: "Није постављена ниједна дијагноза током прегледа.",
  KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_TITLE: "Лек и тип терапије",
  KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_PRETRAGA_LEKA: "Претрага лека",
  KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_ATRIBUT_LEKA: "Атрибут лека",
  KONTAKT_TERAPIJA_DIALOG_SECOND_PAGE_TIP_TERAPIJE: "Тип терапије",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_TITLE: "Дозирање",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE: "Терапија за",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_DANA: "дана",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_DUZINA_TRAJANJA_TERAPIJE_MESECI: "месеци",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_BROJ_PAKOVANJA: "Број паковања",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA: "Начин дозирања",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNAKO: "једнако",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO: "комбиновано",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UJUTRU: "ујутру",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_U_PODNE: "у подне",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_KOMBINOVANO_UVECE: "увече",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO: "једнократно",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_NA_SVAKIH: "на сваких",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_PO_POTREBI: "по потреби",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_PROIZVOLJNO: "произвољно",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_JEDNOKRATNO_PRUZANJE: "Једнократно пружање",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DANA_PO: "дана по",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_NACIN_DOZIRANJA_DATI_PO_POTREBI: "Дати по потреби",
  KONTAKT_TERAPIJA_DIALOG_THIRD_PAGE_LABEL_JEDINICA_MERE: "Јединица мере",
  KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_TITLE: "Остало",
  KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_IZDAVANJE_LEKA_HITNO: "Издавање лека хитно",
  KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_ZABRANA_TERAPIJSKE_PARALELE: "Забрана терапијске паралеле",
  KONTAKT_TERAPIJA_DIALOG_FOURTH_PAGE_LABEL_NAPOMENA: "Напомена",
  KONTAKT_TERAPIJA_STAMPA_TOOLTIP: "Штампај терапију",
  KONTAKT_TERAPIJA_STAMPA_POPUP_MESSAGE: "Да бисте одштампали терапију, потребно је да дозволите попуп на претраживачу.",
  KONTAKT_PAKETI_NOVI_PAKET: "Нови пакет",
  KONTAKT_PAKETI_PRUZENI_TOKOM_PREGLEDA: "Пакети пружени током прегледа",
  KONTAKT_PAKETI_LABEL_PAKET: "Пакет",
  KONTAKT_PAKETI_LABEL_USLUGE: "Услуге",
  KONTAKT_PAKETI_LABEL_UTROSCI: "Утрошци",
  KONTAKT_PAKETI_LABEL_POSTAVIO: "Поставио/ла",
  KONTAKT_PAKETI_LABEL_POSTAVIO_MUSKI: "Поставио",
  KONTAKT_PAKETI_LABEL_POSTAVIO_ZENSKI: "Поставила",
  KONTAKT_PAKETI_LABEL_IZMENI: "Измени пакет",
  KONTAKT_PAKETI_LABEL_OBRISI: "Обриши пакет",
  KONTAKT_PAKETI_DIALOG_LABEL_SNIMI: "Сними",
  KONTAKT_PAKETI_DIALOG_LABEL_ODABERITE_PAKET: "Одаберите пакет услуга",
  KONTAKT_PAKETI_DIALOG_LABEL_USLUGE_U_OKVIRU_PAKETA: "Услуге у оквиру пакета",
  KONTAKT_PAKETI_DIALOG_LABEL_UTROSCI_U_OKVIRU_PAKETA: "Утрошци у оквиру пакета",
  KONTAKT_PAKETI_DIALOG_CREATE_SUCCESS: "Пакет је успешно додат.",
  KONTAKT_PAKETI_DELETE_SUCESS: "Пакет је успешно обрисан.",
  KONTAKT_PAKETI_UPDATE_SUCCESS: "Пакет је успешно измењен.",
  KONTAKT_PAKET_MANDATORY_FIELD: "Пакет је обавезан податак.",
  NO_PROIZVOD_USLUGA_PAKET_CENA_WARNING: "Одабрали сте услугу или утрошак који нема активну цену и са њим преглед неће моћи да се наплати. Да ли сте сигурни да желите да га додате?",

  // FAKTORI RIZIKA
  DUVAN: "Дуван",
  ALKOHOL: "Алкохол",
  SLABA_ISHRANA_VOCE_POVRCE: "Слаба исхрана воћем и поврћем",
  FIZICKA_NEKATIVNOST: "Физичка неактивност",
  VISOK_KRVI_PRITISAK: "Висок крвни притисак",
  VISOK_HOLESTEROL: "Висок холестерол",
  GOJAZNOST: "Гојазност",
  VISOK_NIVO_SECERA: "Висок ниво шећера у крви",
  NEUHRANJENOST: "Неухрањеност",

  CONFIRM_CREATE_OSIGURANJE: "Да ли сте сигурни да желите да креирате ново осигурање?",
  CONFIRM_UPDATE_OSIGURANJE: "Да ли сте сигурни да желите да измените постојеће осигурање?",
  CONFIRM_DELETE_ARHIVIRANO_OSIGURANJE: "Да ли сте сигурни да желите да обришете архивирано осигурање?",
  CONFIRM_UPDATE_ARHIVIRANO_OSIGURANJE: "Да ли сте сигурни да желите да измените датуме важења осигурања?",
  MESSAGE_CREATE_OSIGURANJE_SUCCESS: "Успешно сте креирали осигурање.",
  MESSAGE_UPDATE_OSIGURANJE_SUCCESS: "Успешно сте изменили осигурање.",
  MESSAGE_DELETE_OSIGURANJE_SUCCESS: "Успешно сте обрисали осигурање.",
  MESSAGE_DELETE_ARHIVIRANO_OSIGURANJE_SUCCESS: "Успешно сте обрисали архивирано осигурање.",

  TERMINI_NEDELJNI_PRIKAZ: "Недељни приказ",
  TERMINI_DNEVNI_PRIKAZ: "Дневни приказ",

  LABEL_PRAVO_NAD_TIPOVIMA: "Право над типовима",
  LABEL_TIPOVI_PRAVA: "Типови \\ Права",

  //ADMIN PANEL
  ADMIN_PANEL: "Админ панел",
  PRETRAGA: "Претрага",
  SEARCH_PARAMETAR: "Претрага параметара",
  USTANOVA_RADNIK_LIST: "Установе радника",
  PARAMETAR_SAVE_SUCCESS: "Параметри успешно сачувани",
  PARAMETAR_SAVE_FAIL_UNDEFINED: "Параметри не могу да буду сачувани, један од њихових параметра није дефинисан",

  RADNIK_COPY_TITLE_DIALOG: "Радник са истим подешавањима",
  RADNIK_COPY_TITLE_DIALOG_CREATE: "Креирај радника са истим подешавањима",
  RADNIK_COPY_TITLE_DIALOG_CREATE_TOOLTIP: "Креирај радника са истим улогама и организационим јединицама као изабрани радник",
  RADNIK_COPY_CANCEL: "Поништи копирање радника",

  //SIFARNIK USLUGA
  LABEL_SIFARNIK_USLUGA: "Услугe",
  SIFARNIK_USLUGA_NAZIV: "Назив услуге",
  SIFARNIK_USLUGA_SIFRA: "Шифра услуге",
  SIFARNIK_USLUGA_NAZIV_ENGLESKI: "Енглески назив",
  SIFARNIK_USLUGA_OPIS: "Опис",
  SIFARNIK_USLUGA_VAZI_DO: "Важи до",
  SIFARNIK_USLUGA_VAZI_OD: "Важи од",

  SEARCH_SIFARNIK_USLUGA: "Претрага шифарника услуга",
  SIFARNIK_USLUGA_NAZIV_IS_REQUIRED: "Обавезно је унети назив",
  SIFARNIK_USLUGA_SIFRA_IS_REQUIRED: "Обавезно је унети шифру",
  SIFARNIK_USLUGA_VAZI_OD_IS_REQUIRED: "Обавезно је унети важи od",
  SIFARNIK_USLUGA_VAZI_OD_IS_BEFORE_VAZI_DO: "Важи од мора бити пре важи до",
  SIFARNIK_USLUGA_TITLE_DIALOG_CREATE: "Креирање услуге ",
  SIFARNIK_USLUGA_TITLE_DIALOG_UPDATE: "Измена услуге ",
  SIFARNIK_USLUGA_TITLE_DIALOG_DETAILS: "Детаљи услуге ",
  SIFARNIK_USLUGA_TITLE_DIALOG_DELETE: "Брисање услуге ",
  SIFARNIK_USLUGA_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_SUCCESS: "Услуга је успешно креирана",
  SIFARNIK_USLUGA_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_SUCCESS: "Услуга је успешно измењена",
  SIFARNIK_USLUGA_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_SUCCESS: "Услуга је успешно обрисана",
  BUTTON_ADD_SIFARNIK_USLUGA: "Додај услугу",
  SIFARNIK_USLUGA_LABEL_USLUGA: "Услуга",
  SIFARNIK_USLUGA_LABEL_CENA: "Цена",
  SIFARNIK_USLUGA_IZMENA_CENE: "Измени цену",
  SIFARNIK_USLUGA_DODAJ_CENU: "Додај нову цену",
  SIFARNIK_USLUGA_IZMENI_CENU: "Измени тренутну цену",
  SIFARNIK_USLUGA_OBRISI_CENU: "Обриши цену",
  SIFARNIK_USLUGA_VALUTA: "Валута",
  SIFANIK_USLUGA_LABEL_DODAJ: "Додај",
  SIFARNIK_USLUGA_SPECIJALIZACIJE: "Специјализације",
  SIFARNIK_USLUGA_DOSTUPNA_ZA_EKSTERNE_ZAKAZIVACE: "Доступна за екстерне заказиваче",

  SIFARNIK_JEDINICA_MERE: "Јединице мере",
  SIFARNIK_JEDINICA_MERE_BUTTON_DODAJ: "Додај јединицу мере",
  SIFARNIK_JEDINICA_MERE_TITLE_CREATE: "Креирање јединице мере ",
  SIFARNIK_JEDINICA_MERE_TITLE_UPDATE: "Измена јединице мере ",
  SIFARNIK_JEDINICA_MERE_TITLE_DETAILS: "Детаљи јединице мере ",
  SIFARNIK_JEDINICA_MERE_TITLE_DELETE: "Брисање јединице мере ",
  SIFARNIK_JEDINICA_MERE_NAZIV: "Назив јединице мере",
  SIFARNIK_JEDINICA_MERE_SIFRA: "Шифра јединице мере",
  SIFARNIK_JEDINICA_MERE_AKRONIM: "Акроним јединице мере",
  SIFARNIK_JEDINICA_MERE_VAZI_OD: "Важи од",
  SIFARNIK_JEDINICA_MERE_VAZI_DO: "Важи до",
  SIFARNIK_JEDINICA_MERE_OPIS: "Опис",
  SIFARNIK_JEDINICA_MERE_MESSAGE_CREATE_SUCCESS: "Јединица мере је успешно креирана",
  SIFARNIK_JEDINICA_MERE_MESSAGE_UPDATE_SUCCESS: "Јединица мере је успешно измењена",
  SIFARNIK_JEDINICA_MERE_MESSAGE_DELETE_SUCCESS: "Јединица мере је успешно обрисана",
  SIFARNIK_JEDINICA_MERE_NAZIV_IS_REQUIRED: "Назив јединице мере је обавезан",
  SIFARNIK_JEDINICA_MERE_SIFRA_IS_REQUIRED: "Шифра јединице мере је обавезна",
  SIFARNIK_JEDINICA_MERE_VAZI_OD_IS_REQUIRED: "Важи од јединице мере је обавезан",

  // SIFARNIK PROIZVODA
  LABEL_SIFARNIK_PROIZVODA: "Производи",
  SIFARNIK_PROIZVODA_NAZIV: "Назив производа",
  SIFARNIK_PROIZVODA_SIFRA: "Шифра производа",
  SIFARNIK_PROIZVODA_RFZO_LABEL: "Производи РФЗО",
  SIFARNIK_PROIZVODA_NAZIV_ENGLESKI: "Енглески назив",
  SIFARNIK_PROIZVODA_OPIS: "Опис",
  SIFARNIK_PROIZVODA_VAZI_DO: "Важи до",
  SIFARNIK_PROIZVODA_VAZI_OD: "Важи од",

  SEARCH_SIFARNIK_PROIZVODA: "Претрага производа",
  SIFARNIK_PROIZVODA_NAZIV_IS_REQUIRED: "Обавезно је унети назив",
  SIFARNIK_PROIZVODA_SIFRA_IS_REQUIRED: "Обавезно је унети шифру",
  SIFARNIK_PROIZVODA_VAZI_OD_IS_REQUIRED: "Обавезно је унети важи од",
  SIFARNIK_PROIZVODA_VAZI_OD_IS_BEFORE_VAZI_DO: "Важи од мора бити пре важи до",
  SIFARNIK_PROIZVODA_JEDINICA_MERE_IS_REQUIRED: "Обавезно је унети јединицу мере",
  SIFARNIK_PROIZVODA_TITLE_DIALOG_CREATE: "Креирање производа ",
  SIFARNIK_PROIZVODA_TITLE_DIALOG_UPDATE: "Измена производа ",
  SIFARNIK_PROIZVODA_TITLE_DIALOG_DETAILS: "Детаљи производа ",
  SIFARNIK_PROIZVODA_TITLE_DIALOG_DELETE: "Брисање производа ",
  SIFARNIK_PROIZVODA_TITLE_MESSAGE_CREATE_SIFARNIK_PROIZVODA_SUCCESS: "Производ је успешно креиран",
  SIFARNIK_PROIZVODA_TITLE_MESSAGE_UPDATE_SIFARNIK_PROIZVODA_SUCCESS: "Производ је успешно измењен",
  SIFARNIK_PROIZVODA_TITLE_MESSAGE_DELETE_SIFARNIK_PROIZVODA_SUCCESS: "Производ је успешно обрисан",
  BUTTON_ADD_SIFARNIK_PROIZVODA: "Додај производ",
  SIFARNIK_PROIZVODA_NAZIV_MINIMUM_LENGTH: "Минимална дужина назива производа је 3",
  SIFARNIK_PROIZVODA_SIFRA_MINIMUM_LENGTH: "Минимална дужина шифре производа је 3",

  SIFARNIK_PROIZVODA_CENE_LABEL: "Цене",
  SIFARNIK_PROIZVODA_DODAJ_CENU: "Додај цену",
  SIFARNIK_PROIZVODA_IZMENI_CENU: "Измена цене",
  SIFARNIK_PROIZVODA_OBRISI_CENU: "Брисање цене",

  // SIFARNIK LEKOVA NA RECEPT
  LABEL_SIFARNIK_LEKOVA_NA_RECEPT: "Лекови на рецепт",
  SIFARNIK_LEKOVA_NA_RECEPT_JKL: "ЈКЛ",
  SIFARNIK_LEKOVA_NA_RECEPT_NAZIV: "Фабричко име лека",
  SIFARNIK_LEKOVA_NA_RECEPT_PROIZVODJAC: "Произвођач",
  SIFARNIK_LEKOVA_NA_RECEPT_ATC_GRUPA: "АТЦ група",
  SIFARNIK_LEKOVA_NA_RECEPT_CENA: "Актуелна цена",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_DETAILS: "Детаљи лека",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY: "Историја цене лека",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_CENA: "Цена",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_DOZVOLJENE_INDIKACIJE: "Дозвољене индикације лека",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_INDIKACIJE: "Индикације",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_NAPOMENA: "Напомена",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_DODATNIH_INDIKACIJA: "Тренутно нема додатних индикација за овај лек.",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_INDIKACIJA: "Тренутно нема индикација за овај лек.",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_NAPOMENA: "Тренутно нема напомена за овај лек.",
  SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY_BEZ_CENE: "Тренутно нема историје цена за овај лек.",
  SIFARNIK_LEKOVA_NA_RECEPT_GENERICKI_NAZIV: "ИНН",
  SIFARNIK_LEKOVA_NA_RECEPT_OBLIK_LEKA: "Облик лека",

  //SIFARNIK NACINA PLACANJA
  LABEL_SIFARNIK_NACINI_PLACANJA: "Начини плаћања",
  LABEL_SIFARNIK_NACIN_PLACANJA: "Начин плаћања",
  SIFARNIK_NACINA_PLACANJA_DODAJ_NACIN_PLACANJA: "Додај начин плаћања",
  SIFARNIK_NACINA_PLACANJA_TITLE_CREATE: "Креирање начина плаћања",
  SIFARNIK_NACINA_PLACANJA_NAZIV: "Назив",
  SIFARNIK_NACINA_PLACANJA_SIFRA: "Шифра",
  SIFARNIK_NACINA_PLACANJA_PRIKAZNI_REDOSLED: "Приказни редослед",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE: "Брзо плаћање",
  SIFARNIK_NACINA_PLACANJA_TITLE_UPDATE: "Измена начина плаћања ",
  SIFARNIK_NACINA_PLACANJA_TITLE_DELETE: "Брисање начина плаћања ",
  SIFARNIK_NACINA_PLACANJA_MESSAGE_DELETE_SUCCESS: "Начин плаћања је успешно обрисан",
  SIFARNIK_NACINA_PLACANJA_MESSAGE_CREATE_SUCCESS: "Начин плаћања је успешно креиран.",
  SIFARNIK_NACINA_PLACANJA_MESSAGE_UPDATE_SUCCESS: "Начин плаћања је успешно измењен.",
  SIFARNIK_NACINA_PLACANJA_VRSTA_UPLATIOCA: "Врста уплатиоца",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_DIALOG_HEADER: "Потврда брзог плаћања",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_DIALOG_CONTENT: "Постоји већ одабрани начин плаћања који се користи за брзо плаћање, да ли желите да брзо плаћање буде: ",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_ACCEPT_DIALOG_CONTENT:
    "Да ли желите да извршите брзо плаћање, које подразумева да је једини уплатилац пацијент, да плаћа 100% од цене, нема попуст и за начин плаћања користи одабран начин за брзо плаћање?",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_ACCEPT_DIALOG_CONTENT_2: "Укупан износ:",
  SIFARNIK_NACINA_PLACANJA_UPLATIOCI_TOOLTIP_DESCRIPTION: "Прелазак на други корак наплате",
  SIFARNIK_NACINA_PLACANJA_RACUNI_TOOLTIP_DESCRIPTION: "Прелазак на трећи корак наплате, подразумевани уплатилац је пацијент, без попуста",
  SIFARNIK_NACINA_PLACANJA_BRZO_PLACANJE_TOOLTIP_DESCRIPTION: "Подразумевани уплатилац је пацијент, без попуста уз предефинисани начин наплате",

  //SIFARNIK KATEGORIJA POPUSTA
  LABEL_SIFARNIK_KATEGORIJE_POPUSTA: "Категорије попуста",
  LABEL_SIFARNIK_KATEGORIJA_POPUSTA: "Категорија попуста",
  SIFARNIK_KATEGORIJA_POPUSTA_DODAJ_KATEGORIJU_POPUSTA: "Додај категорију попуста",
  SIFARNIK_KATEGORIJA_POPUSTA_TITLE_CREATE: "Креирање категорије попуста",
  SIFARNIK_KATEGORIJA_POPUSTA_NAZIV: "Назив",
  SIFARNIK_KATEGORIJA_POPUSTA_SIFRA: "Шифра",
  SIFARNIK_KATEGORIJA_POPUSTA_PRIKAZNI_REDOSLED: "Приказни редослед",
  SIFARNIK_KATEGORIJA_POPUSTA_TITLE_UPDATE: "Измена категорије попуста ",
  SIFARNIK_KATEGORIJA_POPUSTA_TITLE_DELETE: "Брисање категорије попуста ",
  SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_DELETE_SUCCESS: "Категорија попуста је успешно обрисана",
  SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_CREATE_SUCCESS: "Категорија попуста је успешно креирана.",
  SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_UPDATE_SUCCESS: "Категорија попуста је успешно измењена.",
  LABEL_SIFARNIK_KATEGORIJE_POPUSTA_TIP_KATEGORIJE: "Тип категорије попуста",
  LABEL_SIFARNIK_KATEGORIJE_POPUSTA_NAMENA_KATEGORIJE: "Намена категорије попуста",
  LABEL_SIFARNIK_KATEGORIJE_POPUSTA_POPUST: "Попуст",
  LABEL_SIFARNIK_KATEGORIJE_FILTER_TIP_PLACEHOLDER: "Сви типови",
  LABEL_SIFARNIK_KATEGORIJE_FILTER_NAMENA_PLACEHOLDER: "Све намене",
  LABEL_SIFARNIK_KATEGORIJA_POPUSTA_VALUTA: "Валута",

  //PAKETI USLUGA
  LABEL_PAKETI_USLUGA: "Пакети услуга",
  PAKET_USLUGA_LIST: "Пакети услуга",
  PAKET_USLUGA_SIFRA: "Шифра пакета услуга",
  PAKET_USLUGA_NAZIV: "Назив пакета услуга",
  PAKET_USLUGA_NAPOMENA: "Напомена пакета услуга",
  PAKET_USLUGA_TITLE_DIALOG_CREATE: "Креирање пакета услуга ",
  PAKET_USLUGA_TITLE_DIALOG_UPDATE: "Измена пакета услуга ",
  PAKET_USLUGA_TITLE_DIALOG_DELETE: "Брисање пакета услуга ",
  PAKET_USLUGA_NAZIV_IS_REQUIRED: "Обавезно је унети назив.",
  PAKET_USLUGA_SEARCH: "Претрага",
  SEARCH_PAKET_USLUGA: "Претрага пакета услуга",
  BUTTON_ADD_PAKET_USLUGA: "Додај пакет услуга",
  LABEL_PAKET_USLUGA: "Пакет услуга",
  LABEL_OSNOVNI_PODACI: "Основни подаци",
  TOOLTIP_CREATE_PAKET_USLUGA: "Креирање пакета услуга",
  TOOLTIP_UPDATE_PAKET_USLUGA: "Измена пакета услуга",
  TOOLTIP_DELETE_PAKET_USLUGA: "Брисање пакета услуга",
  PAKET_USLUGA_TITLE_MESSAGE_CREATE_PAKET_SUCCESS: "Пакет услуга је успешно креиран.",
  PAKET_USLUGA_TITLE_MESSAGE_UPDATE_PAKET_SUCCESS: "Пакет услуга је успешно измењен.",
  PAKET_USLUGA_TITLE_MESSAGE_DELETE_PAKET_SUCCESS: "Пакет услуга је успешно обрисан.",
  PAKET_USLUGA_ADD_USLUGA_SUCCESS: "Услуга је успешно додата.",
  PAKET_USLUGA_DELETE_USLUGA_SUCCESS: "Услуга је успешно обрисана.",
  PAKET_USLUGA_UPDATE_USLUGA_SUCCESS: "Услуга је успешно измењена.",
  PAKET_USLUGA_MANDATORY_FIELD: "Услуга је обавезно поље",
  PAKET_USLUGA_PODRAZUMEVANA_TEXT: "Услуга је подешена као подразумевана",
  PAKET_USLUGA_NE_PODRAZUMEVANA_TEXT: "Услуга није подешена као подразумевана",
  PAKET_USLUGA_LABEL_USLUGA: "Услуга",
  PAKET_USLUGA_LABEL_PODESI: "Подеси као подразумевану",
  PAKET_USLUGA_LABEL_PRIKAZANI_REDOSLED: "Приказани редослед",
  PAKET_USLUGA_DIALOG_USLUGA_CREATE_TITLE: "Додај услугу у оквиру сета",
  PAKET_USLUGA_DIALOG_USLUGA_UPDATE_TITLE: "Измени услугу у оквиру сета",
  PAKET_USLUGA_DIALOG_USLUGA_DELETE_TITLE: "Обриши услугу у оквиру сета",
  PAKET_USLUGA_DIALOG_PROIZVOD_CREATE_TITLE: "Додај утрошак у оквиру сета",
  PAKET_USLUGA_DIALOG_PROIZVOD_UPDATE_TITLE: "Измени утрошак у оквиру сета",
  PAKET_USLUGA_DIALOG_PROIZVOD_DELETE_TITLE: "Обриши утрошак у оквиру сета",
  PAKET_USLUGA_LABEL_UTROSAK: "Утрошак",
  PAKET_USLUGA_LABEL_PODRAZUMEVANA_KOLICINA: "Подразумевана количина",
  PAKET_USLUGA_LABEL_PRIKAZNI_REDOSLED: "Приказни редослед",
  PAKET_USLUGA_LABEL_NAPOMENA: "Напомена",
  PAKET_USLUGA_PROIZVOD_MANDATORY_FIELD: "Утрошак је обавезно поље.",
  PAKET_USLUGA_ADD_PROIZVOD_SUCCESS: "Утрошак је успешно додат.",
  PAKET_USLUGA_UPDATE_PROIZVOD_SUCCESS: "Утрошак је успешно измењен.",
  PAKET_USLUGA_DELETE_PROIZVOD_SUCCESS: "Утрошак је успешно обрисан.",
  PAKET_USLUGA_UNOS_USLUGE_PLACEHOLDER: "Унесите назив или шифру услуге",
  PAKET_USLUGA_UNOS_PROIZVODA_PLACEHOLDER: "Унесите назив или шифру производа",

  PAKET_ORGANIZACIONA_JEDINICA_TAB: "Организационе јединице",
  PAKET_ORGANIZACIONA_JEDINICA_PLACEHOLDER: "Одаберите организациону јединицу за коју желите да вежете пакет",
  PAKET_ADD_ORGANIZACIONA_JEDINICA_SUCCESS: "Организациона јединица је успешно додата.",
  PAKET_REMOVE_ORGANIZACIONA_JEDINICA_SUCCESS: "Организациона јединица је успешно избачена.",
  TOOLTIP_CREATE_PAKET_ORGANIZACIONA_JEDINICA: "Вежи организациону јединицу за пакет",
  TOOLTIP_DELETE_PAKET_ORGANIZACIONA_JEDINICA: "Избаци организациону јединицу са пакета",

  LOG_POZIVA_WS: "Лог позива WS",
  LOG_METADATA_HOSTNAME: "Metadata hostname",
  LOG_METADATA_PORT: "Metadata port",
  LOG_REQUEST_TIMESTAMP: "Request timestamp",
  LOG_REQUEST_CLIENT_IP: "Request client IP",
  LOG_REQUEST_METHOD: "Request method",
  LOG_REQUEST_WS_ENDPOINT: "Request WS endpoint",
  LOG_RESPONSE_TIMESTAMP: "Response timestamp",
  LOG_RESPONSE_METHOD: "Response method",
  LABEL_TOOLTIP_LOG_SEARCH: "Претрага лога",

  LOG_REQUEST_X_FORWARDED: "Request X forwarded for",
  LOG_REQUEST_QUERY_STRING: "Request query string",
  LOG_REQUEST_AUTHORIZATION: "Request authorization",
  LOG_REQUEST_USER_AGENT: "Request user agent",
  LOG_REQUEST_BODY: "Request body",
  LOG_RESPONSE_STATUS_CODE: "Response status code",
  LOG_RESPONSE_BODY: "Response body",

  LOG_HIGH_LEVEL_LIST: "Преглед лог-а",
  LOG_HIGH_LEVEL_ID_RADNIKA: "Ид радника",
  LOG_HIGH_LEVEL_ID_PACIJENTA: "Ид пацијента",
  LOG_HIGH_LEVEL_RADNIK: "Радник",
  LOG_HIGH_LEVEL_PACIJENT: "Пацијент",
  LOG_HIGH_LEVEL_VREME_AKCIJE: "Време акције",
  LOG_HIGH_LEVEL_OPIS: "Опис",

  SIFARNIK_MKB: "Шифарник МКБ",
  BUTTON_IMPORT_SIFARNIK_MKB: "Учитај шифарник",
  TOOLTIP_IMPORT_SIFARNIK_MKB: "Учитај шифарник МКБ",
  SEARCH_SIFARNIK_MKB: "Претрага шифарника МКБ",
  SIFARNIK_MKB_NAZIV: "Назив дијагнозе",
  SIFARNIK_MKB_SIFRA: "Шифра дијагнозе",
  IMPORT_SIFARNIK_MKB_SUCCESS: "Шифарник је успешно учитан.",

  SIFARNIK_ATRIBUTA_RFZO: "Шифарник атрибута РФЗО",
  BUTTON_IMPORT_SIFARNIK_ATRIBUTA_RFZO: "Учитај шифарник",
  TOOLTIP_IMPORT_SIFARNIK_ATRIBUTA_RFZO: "Учитај шифарник атрибута РФЗО",
  SEARCH_SIFARNIK_ATRIBUTA_RFZO: "Претрага шифарника атрибута РФЗО",
  SIFARNIK_ATRIBUTA_RFZO_NAZIV: "Назив атрибута",
  SIFARNIK_ATRIBUTA_RFZO_SIFRA: "Шифра атрибута",
  IMPORT_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: "Шифарник је успешно учитан.",
  LABEL_SIFARNIK_ATRIBUTA_RFZO: "Атрибути РФЗО",
  SIFARNIK_ATRIBUTA_RFZO_OPIS: "Опис",
  SIFARNIK_ATRIBUTA_RFZO_FAKTOR_UVECANJA_CENE: "Фактор увећања цене",
  SIFARNIK_ATRIBUTA_RFZO_OZNAKA: "Ознака",
  SIFARNIK_ATRIBUTA_RFZO_ID_ATRIBUTA: "Ид атрибута",
  SIFARNIK_ATRIBUTA_RFZO_VAZI_DO: "Важи до",
  SIFARNIK_ATRIBUTA_RFZO_VAZI_OD: "Важи од",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_CREATE: "Креирање атрибута РФЗО ",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_UPDATE: "Измена атрибута РФЗО ",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_DETAILS: "Детаљи атрибута РФЗО ",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_DIALOG_DELETE: "Брисање атрибута РФЗО ",
  SIFARNIK_ATRIBUTA_RFZO_NAZIV_IS_REQUIRED: "Обавезно је унети назив",
  SIFARNIK_ATRIBUTA_RFZO_SIFRA_IS_REQUIRED: "Обавезно је унети шифру",
  SIFARNIK_ATRIBUTA_RFZO_STATUS_IS_REQUIRED: "Обавезно је унети статус",
  SIFARNIK_ATRIBUTA_RFZO_FAKTOR_UVECANJA_IS_REQUIRED: "Обавезно је унети фактор увећања",
  SIFARNIK_ATRIBUTA_RFZO_STATUS: "Статус",
  SIFARNIK_ATRIBUTA_RFZO_VAZI_OD_IS_REQUIRED: "Обавезно је унети важи од",
  SIFARNIK_ATRIBUTA_RFZO_VAZI_DO_IS_REQUIRED: "Обавезно је унети важи дo",
  SIFARNIK_ATRIBUTA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO: "Важи од мора бити пре важи до",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: "Атрибут је успешно креиран",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: "Атрибут је успешно измењен",
  SIFARNIK_ATRIBUTA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_ATRIBUTA_RFZO_SUCCESS: "Атрибут је успешно обрисан",

  SIFARNIK_PROIZVODA_RFZO: "Шифарник производа РФЗО",
  BUTTON_IMPORT_SIFARNIK_PROIZVODA_RFZO: "Учитај шифарник",
  TOOLTIP_IMPORT_SIFARNIK_PROIZVODA_RFZO: "Учитај шифарник производа РФЗО",
  SEARCH_SIFARNIK_PROIZVODA_RFZO: "Претрага шифарника производа РФЗО",
  SIFARNIK_PROIZVODA_RFZO_NAZIV: "Назив производа",
  SIFARNIK_PROIZVODA_RFZO_SIFRA: "Шифра производа",
  IMPORT_SIFARNIK_PROIZVODA_RFZO_SUCCESS: "Шифарник је успешно учитан.",
  LABEL_SIFARNIK_PROIZVODA_RFZO: "Производи РФЗО",
  SIFARNIK_PROIZVODA_RFZO_OPIS: "Опис",
  SIFARNIK_PROIZVODA_RFZO_TIP: "Тип",
  SIFARNIK_PROIZVODA_RFZO_OZNAKA: "Ознака",
  SIFARNIK_PROIZVODA_RFZO_NAZIV_JEDINICE_MERE: "Назив јединице мере",
  SIFARNIK_PROIZVODA_RFZO_VAZI_DO: "Важи до",
  SIFARNIK_PROIZVODA_RFZO_VAZI_OD: "Важи од",
  SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_CREATE: "Креирање производа РФЗО ",
  SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_UPDATE: "Измена производа РФЗО ",
  SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DETAILS: "Детаљи производа РФЗО ",
  SIFARNIK_PROIZVODA_RFZO_TITLE_DIALOG_DELETE: "Брисање производа РФЗО ",
  SIFARNIK_PROIZVODA_RFZO_NAZIV_IS_REQUIRED: "Обавезно је унети назив",
  SIFARNIK_PROIZVODA_RFZO_SIFRA_IS_REQUIRED: "Обавезно је унети шифру",
  SIFARNIK_PROIZVODA_RFZO_STATUS: "Status",
  SIFARNIK_PROIZVODA_RFZO_VAZI_OD_IS_REQUIRED: "Обавезно је унети важи од",
  SIFARNIK_PROIZVODA_RFZO_VAZI_DO_IS_REQUIRED: "Обавезно је унети важи дo",
  SIFARNIK_PROIZVODA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO: "Важи од мора бити пре важи до",
  SIFARNIK_PROIZVODA_RFZO_STATUS_IS_REQUIRED: "Обавезно је унети статус производа",
  SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_PROIZVODA_RFZO_SUCCESS: "Производ је успешно креиран",
  SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_PROIZVODA_RFZO_SUCCESS: "Производ је успешно измењен",
  SIFARNIK_PROIZVODA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_PROIZVODA_RFZO_SUCCESS: "Производ је успешно обрисан",

  SIFARNIK_USLUGA_RFZO: "Шифарник услуге РФЗО",
  BUTTON_IMPORT_SIFARNIK_USLUGA_RFZO: "Учитај шифарник",
  TOOLTIP_IMPORT_SIFARNIK_USLUGA_RFZO: "Учитај шифарник услуге РФЗО",
  SEARCH_SIFARNIK_USLUGA_RFZO: "Претрага шифарника услуге РФЗО",
  SIFARNIK_USLUGA_RFZO_NAZIV: "Назив услуге",
  SIFARNIK_USLUGA_RFZO_SIFRA: "Шифра услуге",
  IMPORT_SIFARNIK_USLUGA_RFZO_SUCCESS: "Шифарник је успешно учитан.",
  LABEL_SIFARNIK_USLUGA_RFZO: "Услуге РФЗО",
  SIFARNIK_USLUGA_RFZO_OPIS: "Опис",
  SIFARNIK_USLUGA_RFZO_MOZE_IMATI_UTROSAK: "Може имати утрошак",
  SIFARNIK_USLUGA_RFZO_VAZI_DO: "Важи до",
  SIFARNIK_USLUGA_RFZO_VAZI_OD: "Важи од",
  SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_CREATE: "Креирање услуге РФЗО ",
  SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_UPDATE: "Измена услуге РФЗО ",
  SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DETAILS: "Детаљи услуге РФЗО ",
  SIFARNIK_USLUGA_RFZO_TITLE_DIALOG_DELETE: "Брисање услуге РФЗО ",
  SIFARNIK_USLUGA_RFZO_NAZIV_IS_REQUIRED: "Обавезно је унети назив",
  SIFARNIK_USLUGA_RFZO_SIFRA_IS_REQUIRED: "Обавезно је унети шифру",
  SIFARNIK_USLUGA_RFZO_STATUS: "Status",
  SIFARNIK_USLUGA_RFZO_VAZI_OD_IS_REQUIRED: "Обавезно је унети важи од",
  SIFARNIK_USLUGA_RFZO_VAZI_DO_IS_REQUIRED: "Обавезно је унети важи дo",
  SIFARNIK_USLUGA_RFZO_VAZI_OD_IS_BEFORE_VAZI_DO: "Важи од мора бити пре важи до",
  SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_CREATE_SIFARNIK_USLUGA_RFZO_SUCCESS: "Услуга је успешно креирана",
  SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_UPDATE_SIFARNIK_USLUGA_RFZO_SUCCESS: "Услуга је успешно измењена",
  SIFARNIK_USLUGA_RFZO_TITLE_MESSAGE_DELETE_SIFARNIK_USLUGA_RFZO_SUCCESS: "Услуга је успешно обрисана",

  SIFARNIK_STATUS_IZBOR_FILTERA: "Изаберите статус",

  OBAVESTENJE_LIST: "Обавештења",
  OBAVESTENJE_TITLE_DIALOG_CREATE: "Креирање обавештења ",
  OBAVESTENJE_TITLE_DIALOG_UPDATE: "Измена обавештења ",
  OBAVESTENJE_TITLE_DIALOG_DELETE: "Брисање обавештења ",
  OBAVESTENJE_TITLE_DIALOG_VIEW: "Преглед обавештења",
  OBAVESTENJE_TITLE_DIALOG_DETAILS: "Детаљи обавештења",
  SEARCH_OBAVESTENJE: "Претрага обавештења",
  OBAVESTENJE_SEARCH: "Претрага",
  OBAVESTENJE_NASLOV: "Наслов",
  OBAVESTENJE_SADRZAJ: "Садржај",
  OBAVESTENJE_VAZI_DO: "Важи до",
  OBAVESTENJE_ULOGA: "Обавештење за улоге",
  OBAVESTENJE_TITLE_MESSAGE_DELETE_OBAVESTENJE_SUCCESS: "Обавештење је успешно обрисано",
  OBAVESTENJE_TITLE_MESSAGE_CREATE_OBAVESTENJE_SUCCESS: "Обавештење је успешно креирано",
  OBAVESTENJE_TITLE_MESSAGE_CREATE_OBAVESTENJE_DATOTEKA_SUCCESS: "Датотеке обавештења су креиране",
  OBAVESTENJE_TITLE_MESSAGE_UPDATE_OBAVESTENJE_SUCCESS: "Обавештење је успешно ажурирано",
  OBAVESTENJE_ORGANIZACIONA_JEDINICA: "Обавештење за организационе јединице",
  OBAVESTENJE_COPY_TITLE_DIALOG: "Обавештење са истим подешавањима",
  OBAVESTENJE_NASLOV_IS_REQUIRED: "Обавезан је наслов обавештења",
  OBAVESTENJE_SADRZAJ_IS_REQUIRED: "Обавезан је садржај обавештења",
  CRUD_OBAVESTENJE: "ОБАВЕШТЕЊА",
  OBAVESTENJE_PROCITANO: "Прочитано",
  OBAVESTENJE_NEPROCITANO: "Непрочитано",
  OBAVESTENJE_NEMA_PROCITANIH: "Нема прочитаних обававештења",
  OBAVESTENJE_NEMA_NEPROCITANIH: "Нема непрочитаних обававештења",
  OBAVESTENJE_OZNACI_SVE_PROCITANO: "Означи све прочитано",
  OBAVESTENJE_SVE_USPESNO_PROCITANO: "Сва обавештења су назначена као прочитана",
  OBAVESTENJE_KREIRANO: "креирано:",
  OBAVESTENJE_DATOTEKA_ID_IS_REQUIRED: "Обавезно је унети идфентификатор обавештења",
  OBAVESTENJE_DATOTAKA_CREATE_SUCCESS: "Датотека обавештења је успешно креирана",
  OBAVESTENJE_DATOTEKE: "Датотеке",
  OBAVESTENJE_DATOTEKE_IZABERI: "Изабери датотеке",
  OBAVESTENJE_DATOTEKE_SACUVAJ: "Сачувај",
  OBAVESTENJE_DATOTEKE_PONISTI: "Поништи избор",

  IZVESTAJ_LIST: "Извештаји",
  STATISTICKI_IZVESTAJ_LIST: "Статистички извештаји",
  DODATNI_MODULI: "Додатни модули",
  LICENCA_LIST: "Лиценце",
  GENERISANJE_IZVESTAJA_LIST: "Статистички извештаји",
  KATEGORIJA_IZVESTAJA_LIST: "Категорије извештаја",
  BUTTON_ADD_KATEGORIJA: "Додај категорију",
  TOOLTIP_ADD_KATEGORIJA: "Креирање категорије статистичког извештаја",
  SEARCH_KATEGORIJA: "Претрага категорије извештаја",
  KATEGORIJA_SEARCH: "Претрага",
  KATEGORIJA_NAZIV: "Назив",
  KATEGORIJA_SIFRA: "Шифра",
  KATEGORIJA_TITLE_DIALOG_CREATE: "Креирање категорије ",
  KATEGORIJA_TITLE_DIALOG_UPDATE: "Измена категорије ",
  KATEGORIJA_TITLE_DIALOG_DELETE: "Брисање категорије ",
  CRUD_KATEGORIJA_IZVESTAJ: "ИЗВЕШТАЈИ",
  LABEL_KATEGORIJA_STATISTICKOG_IZVESTAJA: "Категорија статистичког извештаја",
  LABEL_KATEGORIJA_IZVESTAJA_CREATE: "Креирање категорије извештаја",
  LABEL_KATEGORIJA_IZVESTAJA_UPDATE: "Измена категорије извештаја",
  LABEL_KATEGORIJA_IZVESTAJA_DELETE: "Брисање категорије извештаја",
  LABEL_IZVESTAJ_PARAMETAR: "Параметар извештаја",
  KATEGORIJA_NAZIV_IS_REQUIRED: "Обавезан је назив категорије статистичког извештаја",
  KATEGORIJA_SIFRA_IS_REQUIRED: "Обавезна је шифра категорије статистичког извештаја",
  KATEGORIJA_TITLE_MESSAGE_CREATE_KATEGORIJA_SUCCESS: "Категорија статистичког извештаја је успешно креирана",
  KATEGORIJA_TITLE_MESSAGE_UPDATE_KATEGORIJA_SUCCESS: "Категорија статистичког извештаја је успешно измењена",
  KATEGORIJA_TITLE_MESSAGE_DELETE_KATEGORIJA_SUCCESS: "Категорија статистичког извештаја је успешно обрисана",
  IZVESTAJ_NAZIV: "Назив",
  IZVESTAJ_LABEL: "Изаберите извештај",
  EMPTY_IZVESTAJI_KATEGORIJA: "Не постоји ниједан извештај у одабраној категорији.",
  BUTTON_GENERISI_IZVESTAJ: "Генериши извештај",
  SWITCH_LABEL: "Прикажи само своје извештаје",
  IZVESTAJ_SIFRA: "Шифра",
  IZVESTAJ_JASPER: "Џаспер",
  IZVESTAJ_TIP_GENERISANJA: "Тип генерисања",
  IZVESTAJ_FORMAT: "Формат",
  IZVESTAJ_OPIS: "Опис",
  IZVESTAJ_SVE_KATEGORIJE: "Све категорије",
  IZVESTAJ_PRIKAZUJE_SE: "Приказује се",
  BUTTON_ADD_IZVESTAJ: "Додај извештај",
  TOOLTIP_ADD_IZVESTAJ: "Креирање статистичког извештаја",
  SEARCH_IZVESTAJ: "Претрага статистичког извештаја",
  IZVESTAJ_SEARCH: "Претрага",
  IZVESTAJ_TITLE_DIALOG_CREATE: "Креирање извештаја",
  IZVESTAJ_TITLE_DIALOG_UPDATE: "Измена извештаја",
  IZVESTAJ_TITLE_DIALOG_DELETE: "Брисање извештаја",
  IZVESTAJ_PARAMETRI: "Параметри извештаја",
  IZVESTAJ_PARAMETRI_CREATE: "Креирање параметара извештаја ",
  IZVESTAJ_PARAMETRI_UPDATE: "Измена параметара извештаја ",
  IZVESTAJ_PARAMETRI_DELETE: "Брисање параметара извештаја ",
  IZVESTAJ_PARAMETRI_LIST: "Листа параметара извештаја ",
  CRUD_IZVESTAJ_PARAMETAR: "ПАРАМЕТРИ ИЗВЕШТАЈА",
  IZVESTAJ_MESSAGE_CREATE_SUCCESS: "Статистички извештај је успешно креиран",
  IZVESTAJ_MESSAGE_UPDATE_SUCCESS: "Статистички извештај је успешно измењен",
  IZVESTAJ_MESSAGE_DELETE_SUCCESS: "Статистички извештај је успешно избрисан",
  IZVESTAJ_NAZIV_IS_REQUIRED: "Обавезно је унети назив",
  IZVESTAJ_KATEGORIJA_IS_REQUIRED: "Обавезно је изабрати категорију",
  IZVESTAJ_JASPER_IS_REQUIRED: "Обавезно је унети jasper",
  IZVESTAJ_FORMAT_IS_REQUIRED: "Обавезно је изабрати макар један формат",
  IZVESTAJ_CHOOSE_JASPER: "Изаберите jasper",
  IZVESTAJ_REMOVE_JASPER: "Избаците jasper",
  CRUD_IZVESTAJ: "ИЗВЕШТАЈИ",
  IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DELETE_SUCCESS: "Параметар извештаја је успешно обрисан ",
  IZVESTAJ_PARAMETAR_TIP: "Тип параметра",
  IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DIALOG_UPDATE: "Параметар статистичког извештаја је успешно измењен",
  IZVESTAJ_PARAMETAR_TITLE_DIALOG_CREATE: "Параметар статистичког извештаја је успешно креиран",
  IZVESTAJ_PARAMETAR_SQL_UPIT: "SQL упит",
  IZVESTAJ_PARAMETAR_TITLE_DIALOG_UPDATE: "Измена параметра ",
  KATEGORIJE_STATISTICKIH_IZVESTAJA: "Категорије статистичких извештаја",

  POTVRDI: "Потврди",
  OTVORITE_NESACUVAN_NALAZ: "отворите несачуван налаз",
  NASTAVITE_BEZ_CUVANJA_NALAZA: "наставите без чувања налаза",
  ZA_PACIJENTA: "За пацијента",
  POSTOJI_NESACUVAN_NALAZ: "постоји несачуван налаз",
  DA_LI_ZELITE_DA: "Да ли желите да",
  SACUVAJ_I_STAMPAJ: "Сачувај и штампај",

  RADNO_VREME_OD_NOT_EXISTS: "Радно време од је обавезно уколико сте унели радно време до.",
  RADNO_VREME_DO_NOT_EXISTS: "Радно време до је обавезно уколико сте унели радно време од.",
  RADNO_VREME_NOT_VALID: "Радно време од мора бити пре радног времена до!",

  INICIJALNA_VREDNOST: "Иницијална вредност",
  TIP_OSIGURANJA_FILTER: "Изаберите тип",
  KREIRANJE_OSIGURANJA: "Креирање осигурања",
  IZMENA_OSIGURANJA: "Измена осигурања",
  BRISANJE_OSIGURANJA: "Брисање осигурања",
  DODAJ_OSIGURANJE: "Додај осигурање",
  OSIGURANJE_TIP_IS_REQUIRED: "Тип осигурања је обавезно поље",
  VAZI_OD_IS_REQUIRED: "Важи од је обавезно поље",
  OSIGURANJE_VAZI_DO_AFTER_VAZI_OD: "Важи до датум мора бити после Важи од датума",
  CENA: "Цена",

  //LICENCE

  LICENCNI_MODEL_TITLE_DIALOG_UPDATE: "Измена лиценцног модела",
  LICENCNI_MODEL_TITLE_DIALOG_CREATE: "Креирање лиценцног модела",
  LICENCNI_MODEL_TITLE_DIALOG_DELETE: "Брисање лиценцног модела",
  LICENCNI_MODEL_MESSAGE_CREATE_SUCCESS: "Успешно креиран лиценцни модел",
  LICENCNI_MODEL_MESSAGE_UPDATE_SUCCESS: "Успешно ажуриран лиценцни модел",
  LICENCNI_MODEL_MESSAGE_DELETE_SUCCESS: "Успешно обрисан лиценцни модел",
  LABEL_LICENCNI_MODEL: "Лиценцни модел",

  DODATNI_MODUL: "Додатни модул",
  DODATNI_MODUL_BUTTON_DODAJ: "Додај додатни модул",
  DODATNI_MODUL_TITLE_CREATE: "Креирање додатног модула ",
  DODATNI_MODUL_TITLE_UPDATE: "Измена додатног модула ",
  DODATNI_MODUL_TITLE_DETAILS: "Детаљи додатног модула ",
  DODATNI_MODUL_TITLE_DELETE: "Брисање додатног модула ",
  DODATNI_MODUL_NAZIV: "Назив додатног модула",
  DODATNI_MODUL_SIFRA: "Шифра додатног модула",
  DODATNI_MODUL_OPIS: "Опис",
  DODATNI_MODUL_IKONICA: "Иконица",
  DODATNI_MODUL_IKONICA_DELETE: "Да ли сте сигурни да желите да обришете иконицу?",
  DODATNI_MODUL_MESSAGE_CREATE_SUCCESS: "Додатни модул је успешно креиран",
  DODATNI_MODUL_MESSAGE_UPDATE_SUCCESS: "Додатни модул је успешно измењен",
  DODATNI_MODUL_MESSAGE_DELETE_SUCCESS: "Додатни модул је успешно обрисан",
  DODATNI_MODUL_NAZIV_IS_REQUIRED: "Назив додатног модула је обавезан",
  DODATNI_MODUL_SIFRA_IS_REQUIRED: "Шифра додатног модула је обавезна",
  DODATNI_MODUL_VREME_DO_AFTER_VREME_OD: "Време до додатног модула мора бити после времена од",
  MODUL: "Модул",
  MODUL_IZMENA: "Измена модула",
  MODUL_BRISANJE: "Брисање модула",
  MODUL_TITLE_DIALOG_CREATE: "Креирање модула",
  MODUL_LICENCNI_MODUL: "Лиценцни модул",
  SEARCH_DODATNI_MODUL: "Претрага додатног модула",
  DODATNI_MODUL_SEARCH: "Претрага",

  STORAGE_LIMIT: "Ограничење складишта",
  STORAGE_LIMIT_TITLE_UPDATE: "Измена ограничења складишта ",
  STORAGE_LIMIT_TITLE_DETAILS: "Детаљи ограничењна складишта ",
  STORAGE_LIMIT_TITLE_DELETE: "Брисање ограничења складишта ",
  STORAGE_LIMIT_TITLE_CREATE: "Креирање ограничења складишта",
  STORAGE_LIMIT_NAZIV: "Назив ограничења складишта ",
  STORAGE_LIMIT_SIFRA: "Шифра oграничења складишта",
  STORAGE_LIMIT_PROSTOR: "Простор ограничења складишта",
  STORAGE_LIMIT_MESSAGE_CREATE_SUCCESS: "Ограничење складишта је успешно креирано",
  STORAGE_LIMIT_UPDATE_SUCCESS: "Ограничење складишта је успешно измењено",
  STORAGE_LIMIT_MESSAGE_DELETE_SUCCESS: "Ограничење складишта је успешно избрисано",
  STORAGE_LIMIT_NAZIV_IS_REQUIRED: "Назив ограничења складишта је обавезно",
  STORAGE_LIMIT_SIFRA_IS_REQUIRED: "Шифра ограничења складишта је обавезна",
  STORAGE_LIMIT_PROSTOR_IS_REQUIRED: "Простор је обавезно поље, које мора бити веће од 0",
  STORAGE_LIMIT_JEDINICA_IS_REQUIRED: "Јединица количине податка је обавезно поље",
  STORAGE_LIMIT_CENA_IS_REQUIRED: "Цена је обавезно поље, које не сме бити негативно",
  STORAGE_LIMIT_BUTTON_DODAJ: "Додај ограничење складишта",
  LICENCA_USTANOVA_STORAGE_LIMIT_LIST: "Листа ограничење складишта",
  USTANOVA_STORAGE_LIMIT_NAZIV_IS_REQUIRED: "Назив ограничења складишта установе",
  USTANOVA_STORAGE_LIMIT_MESSAGE_UPDATE_SUCCESS: "Ограничење складишта је успешно измењено",
  USTANOVA_STORAGE_LIMIT_VAZI_OD_IS_REQUIRED: "Датум почетка важења (датум од) ограничења складишта је обавезан податак",
  USTANOVA_STORAGE_LIMIT: "Ограничење складишта установе",
  USTANOVA_STORAGE_LIMIT_TITLE_DELETE: "Брисање ограничења складишта установе ",
  USTANOVA_PRISTUP_PRIMARAN: "Примаран приступ",
  USTANOVA_INICIJALIZACIJA_KORAK: "Корак иницијализације",
  SEARCH_STORAGE_LIMIT: "Претрага ограничења складишта",
  STORAGE_LIMIT_SEARCH: "Претрага",
  USTANOVA_BRISANJE: "Брисање установe",
  USTANOVA_BRISANJE_PODACI_O_USTANOVI: "Подаци о установи",
  USTANOVA_BRISANJE_LABEL_BROJ_RADNIKA: "Број радника",
  USTANOVA_BRISANJE_LABEL_BROJ_ORGANIZACIONIH_JEDINICA: "Број организационих јединица",
  USTANOVA_BRISANJE_LABEL_BROJ_ZAKAZNIH_TERMINA: "Број заказаних термина",
  USTANOVA_BRISANJE_LABEL_BROJ_KONTAKATA: "Број контаката",
  USTANOVA_BRISANJE_LABEL_BROJ_SNIMLJENIH_DIJAGNOZA: "Број снимљених дијагноза",
  USTANOVA_BRISANJE_LABEL_BROJ_SNIMLJENIH_FORMULARA: "Број снимљених формулара",
  USTANOVA_BRISANJE_DIALOG_LABEL_1: "Да ли сте сигурни да желите да обришете ову Установу? Акција брисања је неповратна, сви подаци биће трајно обрисани без могуцности враћања!",
  USTANOVA_BRISANJE_DIALOG_LABEL_2: "Изабрали сте опцију за трајно брисање! Да бисте потврдили брисање, штиклирајте checkbox и опција за брисање ће вам бити доступна.",
  USTANOVA_BRISANJE_U_TOKU: "Брисање је у току ...",
  USTANOVA_BRISANJE_ZAVRSENO: "Брисање успешно завршено.",

  BROJ_NALOGA: "Број налога",
  BROJ_NALOGA_BUTTON_DODAJ: "Додај број налога",
  BROJ_NALOGA_TITLE_CREATE: "Креирање броја налога",
  BROJ_NALOGA_TITLE_UPDATE: "Измена броја налога ",
  BROJ_NALOGA_TITLE_DELETE: "Брисање броја налога ",
  SEARCH_BROJ_NALOGA: "Претрага броја налога",
  BROJ_NALOGA_SEARCH: "Претрага",
  BROJ_NALOGA_TITLE_DETAILS: "Детаљи броја налога",
  BROJ_NALOGA_NAZIV: "Naziv броја налога",
  BROJ_NALOGA_SIFRA: "Šifra броја налога",
  BROJ_NALOGA_UKUPNO: "Укупно налога",
  BROJ_NALOGA_MESSAGE_CREATE_SUCCESS: "Број налога је успешно креиран",
  BROJ_NALOGA_MESSAGE_UPDATE_SUCCESS: "Број налога је успешно измењен",
  BROJ_NALOGA_MESSAGE_DELETE_SUCCESS: "Број налога је успешно обрисан",
  BROJ_NALOGA_NAZIV_IS_REQUIRED: "Назив броја налога је обавезан",
  BROJ_NALOGA_SIFRA_IS_REQUIRED: "Шифра броја налога је обавезна",
  BROJ_NALOGA_BROJ_IS_REQUIRED: "Укупан броја налога je обавезан",
  BROJ_NALOGA_BROJ_VALUE_NOT_VALID: "Број налога мора бити већи од 0",

  // PROTOKOL

  PROTOKOL_LIST: "Деловодне књиге",
  PROTOKOL_ADMINISTRIRANJE: "Администрирање деловодних књига",
  PROTOKOL_PREFIKS: "Префикс",
  PROTOKOL_NAZIV: "Назив",
  PROTOKOL_VREMENSKI_OKVIR: "Временски оквир",
  PROTOKOL_POCETNI_BROJ: "Почетни број",
  PROTOKOL_NAREDNI_BROJ: "Наредни број",
  BUTTON_ADD_PROTOKOL: "Додај протокол",
  PROTOKOL_TITLE_DIALOG_CREATE: "Креирање протокола",
  SEARCH_PROTOKOL: "Претрага протокола",
  PROTOKOL_SEARCH: "Претрага",
  PROTOKOL_TITLE_DIALOG_UPDATE: "Измена протокола",
  PROTOKOL_TITLE_DIALOG_DELETE: "Брисање протокола",
  CRUD_PROTOKOL: "Деловодне књиге",
  PROTOKOL_TITLE_MESSAGE_CREATE_PROTOKOL_SUCCESS: "Протокол је успешно креиран",
  PROTOKOL_TITLE_MESSAGE_DELETE_PROTOKOL_SUCCESS: "Протокол је успешно обриса",
  PROTOKOL_TITLE_MESSAGE_UPDATE_PROTOKOL_SUCCESS: "Протокол је успешно измењен",
  PROTOKOL_PREFIKS_IS_REQUIRED: "Префикс протокола је обавезан",
  PROTOKOL_NAZIV_IS_REQUIRED: "Назив протокола је обавезан",
  PROTOKOL_VREMENSKI_OKVIR_IS_REQUIRED: "Временски оквир протокола је обавезан",
  PROTOKOL_POCETNI_BROJ_IS_REQUIRED: "Почетни број протокола је обавезан и мора бити већи од 0",
  PROTOKOL_NAREDNI_BROJ_IS_REQUIRED: "Наредни број протокола је обавезан и мора бити већи од 0",
  PROTOKOL_ADMINISTRACIJA_BROJEVA: "Администрација бројева",
  PROTOKOL_BROJ_BRISANJE: "Ослободи броја протокола",
  PROTOKOL_BROJ: "Број",
  PROTOKOL_BROJ_AKTIVAN: "Активан",
  PROTOKOL_BROJ_TITLE_MESSAGE_DELETE_PROTOKOL_BROJ_SUCCESS: "Број протокола је успешно обрисан",
  PROTOKOL_BROJ_PROTOKOL_REQUIRED: "Протокол је обавезан",
  PROTOKOL_BROJ_BROJEVI: "Бројеви",
  PROTOKOL_SELECT: "Изаберите протокол",
  PROTOKOL_OBRAZAC_STAMPE: "Образац штампе",
  PROTOKOL_ADD: "Додај",
  PROTOKOL_ADD_FROM_REPOSITORY: "Додај са репозиторијума",
  PROTOKOL_ADD_FROM_LOCAL: "Додај локалну датотеку",
  PROTOKOL_OBRAZAC_STAMPE_OPIS: "Опис обрасца штампе",
  LABELS_STAMPA_PROTOKOLA: "Штампа протокола",

  // GENERISANI IZVESTAJI
  GENERISANI_IZVESTAJ_MESSAGE_DELETE_SUCCESS: "Генерисани извештај успешно обрисан.",
  CRUD_GENERISANI_IZVESTAJ: "Генерисани извештај",
  GENERISANJE_IZVESTAJA: "Генерисање извештајa",
  GENERISANI_IZVESTAJ_LIST: "Генерисани извештаји",
  GENERISANI_IZVESTAJI_DELETE: "Брисање генерисаног извештаја",
  GENERISANI_IZVESTAJ_NAZIV: "Назив",
  GENERISANI_IZVESTAJ_OPIS: "Опис",
  GENERISANI_IZVESTAJ_FILENAME: "Назив фајла",
  GENERISANI_IZVESTAJ_FILESIZE: "Величина фајла",
  GENERISANI_IZVESTAJ_FILETYPE: "Тип фајла",
  GENERISANI_IZVESTAJ_VREME_KREIRANJA: "Време креирања",
  GEN_IZVESTAJ_PARAMETRI: "Параметри",
  GEN_IZVESTAJ_TITLE_DIALOG_PRINT: "Штампање извештаја",
  GEN_IZVESTAJ_TITLE_DIALOG_DETALJI: "Детаљи извештаја",
  GEN_IZVESTAJ_TITLE_DIALOG_DELETE: "Брисање извештаја",
  GEN_IZVESTAJ_AKCIJE: "Акције",
  GEN_IZVESTAJ_VREME: "Време",
  GEN_IZVESTAJ_NAZIV: "Назив",
  GEN_IZVESTAJ_DETALJI: "Детаљи извештаја",
  GENERISANJE_IZVESTAJA_CONNECTION_ERROR: "Дошло је до грешке приликом успостављања конекције са сервером",
  GENERISANJE_IZVESTAJA_MESSAGE_CREATE_SUCCESS: "Успешно сте иницирали генерисање извештаја.",
  SVI_GENERISANI_IZVESTAJI: "Сви генерисани извештаји",
  SVI_GENERISANI_IZVESTAJI_STAT_IZVESTAJ: " везани за статистички извештај ",
  GEN_IZVESTAJ_EMAIL: "Имејл",
  PONAVLJANJE_GENERISANJA_IZVESTAJI: "Понављање генерисања",
  PONAVLJANJE_GENERISANJA_JEDNOSTRUKO: "Једноструко",
  PONAVLJANJE_GENERISANJA_VISESTRUKO: "Вишеструко",
  DATUM_GENERISANJA_IZVESTAJA: "Датум генерисања извештаја",
  VREME_GENERISANJA_IZVESTAJA: "Време генерисања извештаја",
  UCESTALOST_PONAVLJANJA: "Учесталост понављања",
  UCESTALOST_PONAVLJANJA_DAN: "На нивоу дана",
  UCESTALOST_PONAVLJANJA_NEDELJA: "На нивоу недење",
  UCESTALOST_PONAVLJANJA_MESEC: "На нивоу месеца",
  IZVESTAJ_SLATI: "Извештај слати",
  DAN_U_MESECU: "Дан у месецу",
  ZAKAZIVANI_IZVESTAJI: "Заказивани извештаји",
  GENERISANI_IZVESTAJI_TITLE: "Сви генерисани извештаји везани за статистички извештај евиденција упута у лабораторију",
  ZAKAZANI_IZVESTAJI_TITLE: "Сви заказани извештаји везани за статистички извештај евиденција упута у лабораторију",
  PERIOD_PONAVLJANJA: "Период понављања",
  GEN_IZVESTAJ_EMAIL_POSTOJI: "Уписани имејл је већ додат",
  BUTTON_ZAKAZI_IZVESTAJ: "Закажи извештај",
  BUTTON_IZMENI_IZVESTAJ: "Измени извештај",
  LABEL_NA_NIVOU_DANA: " - на нивоу дана",
  LABEL_NA_NIVOU_NEDELJE: " - на нивоу недеље",
  LABEL_NA_NIVOU_MESECA: " - на нивоу месеца",
  ZAKAZIVANJE_IZVESTAJA_POGRESNO_VREME: "Погрешно време важења унето",
  ZAKAZIVANJE_IZVESTAJA_PONAVLJANJE_IS_REQUIRED: "Понављање генерисања је обавезно поље",
  ZAKAZIVANJE_IZVESTAJA_UCESTALOST_IS_REQUIRED: "Учесталост понављања је обавезно поље",
  ZAKAZIVANJE_IZVESTAJA_DATUM_VREME_IS_REQUIRED: "Датум и време генерисања су обавезна поља",
  ZAKAZAN_IZVESTAJ_DELETE: "Успешно обрисан заказани извештај",
  ZAKAZAN_IZVESTAJ_CREATE: "Успешно заказан извештај",
  ZAKAZAN_IZVESTAJ_EDIT: "Успешно измењен заказани извештај",

  // PLACANJE
  PLACANJE_LIST: "Плаћања",
  OBRACUNSKI_PERIOD_OD: "Почетак обрачунког периода",
  OBRACUNSKI_PERIOD_DO: "Крај обрачунског периода",

  DATUM_PLACANJA: "Датум плаћања",
  OBRACUNATI_IZNOS: "Обрачунати износ",
  UPLACEN_IZNOS: "Уплаћени износ",
  NAPOMENA: "Напомена",
  VALUTA: " РСД",
  BUTTON_DODAJ_UPLATU: "Додај уплату",
  BUTTON_DODAJ_UPLATU_ZA_UGOVOR: "Додај уплате за уговор",
  PLACANJE_TITLE_DIALOG_UPDATE: "Измена уплате",
  PLACANJE_TITLE_DIALOG_DELETE: "Брисање уплате",
  ADD_PLACANJE_TITLE_DIALOG_CREATE: "Креирање уплате",
  ADD_PLACANJE_ZA_UGOVOR_TITLE_DIALOG_CREATE: "Креирање уплата (обрачунских периода) за временско важње уговора",
  USTANOVA_PLACANJE_DROPDOWN_MESEC: "Одаберите месец",
  USTANOVA_PLACANJE_DROPDOWN_GODINA: "Одаберите годину",
  USTANOVA_PLACANJE_NAPOMENA: "Напомена",
  OBRACUNATI_IZNOS_RSD: "Обрачунати износ (РСД)",
  UPLACEN_IZNOS_RSD: "Уплаћени износ (РСД)",
  PLACANJE_IS_REQUIRED: "Унос обрачунског периода за плаћање је обавезан.",
  USTANOVA_PLACANJE_OBRACUNSKI_PERIOD_OD_IS_REQUIRED: "Унос почетка обрачунског периода је обавезан.",
  USTANOVA_PLACANJE_OBRACUNSKI_PERIOD_DO_IS_REQUIRED: "Унос краја обрачунског периода је обавезан.",
  PLACANJE_MESSAGE_CREATE_SUCCESS: "Плаћање успешно креирано.",
  PLACANJE_TITLE_CREATE: "Креирање плаћања",
  USTANOVA_PLACANJE_OBAVEZE_IZMIRENE: "Измирене обавезе",
  IZMENA_PLACANJE_LIST: "Измена плаћања",
  PLACANJE_MESSAGE_UPDATE_SUCCESS: "Плаћање успешно измењено.",
  PLACANJE_MESSAGE_DELETE_SUCCESS: "Плаћање успешно обрисано.",
  PLACANJE_UGOVOR_VAZI_OD_IS_REQUIRED: "Датум почетка важења уговора је обавезно.",
  PLACANJE_UGOVOR_VAZI_DO_IS_REQUIRED: "Датум краја важења уговора је обавезно.",
  PLACANJE_UGOVOR_VAZI_OD: "Датум почетака важења уговора",
  PLACANJE_UGOVOR_VAZI_DO: "Датум до ког важи уговор",
  PLACANJE_UGOVOR_VAZI_OD_VAZI_DO_COMPARE_VALIDATION: "Датум почетка важења уговора мора бити пре датума краја важења уговора.",

  // GLOBALNI PARAMETRI
  GLOBALNI_PARAMETRI: "Глобални параметри",
  ADMINISTRACIJA_GLOBALNIH_PARAMETARA: "Администрација глобалних параметара",
  PARAMETAR_GLOBALAN_MESSAGE_UPDATE_SUCCESS: "Правила плаћања успешно измењена.",

  // LOG HIGH LEVEL
  LOG_HIGH_LEVEL_MESS_RADNIK: "Радник са ид = ",
  LOG_HIGH_LEVEL_MESS_USTANOVA: "из установе са ид = ",
  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_1: "Креирано плаћање за обрачунски период ",
  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_2: " са обрачунатим износом од ",
  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_3: " РСД, са уплаћеним износом од ",
  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_4: " РСД и датумом плаћања ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_1: "Измењено плаћање за обрачунски период ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_2: " са обрачунатим износом од ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_3: " РСД, са уплаћеним износом од ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PLACANJE_4: " РСД и датумом плаћања ",
  LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_1: "Обрисано плаћање за обрачунски период који почиње ",
  LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_2: " са обрачунатим износом од ",
  LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_3: " РСД, са уплаћеним износом од ",
  LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_4: " РСД и датумом плаћања ",
  LOG_HIGH_LEVEL_MESS_DELETE_PLACANJE_5: " и завршава се ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_SUPERADMIN_1: "Креирана је установа под називом ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_1: " и шифром ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_2: ". Параметри установе су: Назив кратки ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_3: ", Језик ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_4: ", Тип својине ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_5: ", Ниво здравствене заштите ",
  LOG_HIGH_LEVEL_MESS_INFO_USTANOVA_SUPERADMIN_6: ", Подразумевана страна ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_SUPERADMIN_1: "Обрисана је установа под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_SUPERADMIN_1: "Измењена је установа под називом ",

  LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_1: "Креиран број налога под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_3: ", са укупним бројем налога од ",
  LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_4: " налога и ценом од ",
  LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_5: " РСД",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_1: "Креиран нов лиценцни модел по установи ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_2: " са бројем налога ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_3: " који важи од ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_BROJ_NALOGA_4: " до ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_1: "Измењен лиценцни модел по установи ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_2: " са бројем налога ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_3: " који важи од ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_BROJ_NALOGA_4: " тако да важи до ",
  LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_1: "Креиран нов додатни модул под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_3: " са ценом од ",
  LOG_HIGH_LEVEL_MESS_CREATE_DODATNI_MODUL_4: " РСД",
  LOG_HIGH_LEVEL_MESS_UPDATE_DODATNI_MODUL_1: "Измењен додатни модул тако да је назив модула ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DODATNI_MODUL_2: ", а шифра ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_1: "Креиран нов додатни модул по установи ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_2: " са лиценцним модулом ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_3: " који важи од ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_DODATNI_MODUL_4: " до ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_1: "Измењен додатни модул по установи ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_2: " са лиценцним модулом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_3: " који важи од ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_DODATNI_MODUL_4: " тако да важи до ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_1: "Обрисан додатни модул по установи ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_2: " са лиценцним модулом ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_3: " који важи од ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_DODATNI_MODUL_4: " до ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_1: "Креирано ново ограничење складишта по установи ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_2: " под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_3: " које важи од ",
  LOG_HIGH_LEVEL_MESS_CREATE_USTANOVA_STORAGE_LIMIT_4: " до ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_1: "Измењено ограничење складишта по установи ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_2: " под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_3: " које важи од ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_STORAGE_LIMIT_4: " тако да важи до ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_1: "Обрисано ограничење складишта по установи ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_2: " под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_3: " које важи од ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_STORAGE_LIMIT_4: " до ",
  LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_1: "Креирана улога под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_ULOGA_3: " којој је придружена рола ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_1: "Измењена улога под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_3: " којој је придружена рола ",
  LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_1: "Обрисана улога под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_ULOGA_3: " којој је придружена рола ",
  LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_1: "Креиран је формулар под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_3: " за установу чији је ид ",
  LOG_HIGH_LEVEL_MESS_CREATE_FORMULAR_4: " чији је тип контакта ",
  LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_1: "Измењен је формулар под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_3: " за установу чији је ид ",
  LOG_HIGH_LEVEL_MESS_UPDATE_FORMULAR_4: " чији је тип контакта ",
  LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_1: "Обрисан је формулар под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_3: " за установу чији је ид ",
  LOG_HIGH_LEVEL_MESS_DELETE_FORMULAR_4: " чији је тип контакта ",

  LOG_HIGH_LEVEL_MESS_CREATE_PAKET_USLUGA: "Креиран је пакет услуга под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PAKET_USLUGA: "Измењен је пакет услуга под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_PAKET_USLUGA: "Обрисан је пакет услуга под називом ",

  LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_FOR_PAKET_USLUGA: "Додата је услуга под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_FOR_PAKET_USLUGA: "Обрисана је услуга под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USLUGA_FOR_PAKET_USLUGA: "Измењена је услуга под називом ",

  LOG_HIGH_LEVEL_MESS_CREATE_PROIZVOD_FOR_PAKET_USLUGA: "Додат је производ под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PROIZVOD_FOR_PAKET_USLUGA: "Измењен је производ под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_PROIZVOD_FOR_PAKET_USLUGA: "Обрисан је производ под називом ",

  LOG_HIGH_LEVEL_MESS_CREATE_PAKET_ORGANIZACIONA_JEDINICA_1: "Организационој јединици под именом ",
  LOG_HIGH_LEVEL_MESS_CREATE_PAKET_ORGANIZACIONA_JEDINICA_2: " додељен је пакет услуга ",
  LOG_HIGH_LEVEL_MESS_DELETE_PAKET_ORGANIZACIONA_JEDINICA_1: "На организационој јединици под именом ",
  LOG_HIGH_LEVEL_MESS_DELETE_PAKET_ORGANIZACIONA_JEDINICA_2: " уклоњен је пакет услуга ",

  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_1: "Креиран је тип поља под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_2: " и шифром ",

  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_3: " за који је тип компоненте ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_1: "Обрисан је тип поља под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_3: " за који је тип компоненте ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_1: "Измењен је тип поља под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_3: " за који је тип компоненте ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_1: "Креирано је поље са називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_3: " типа ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_4: " које је обавезно ",
  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_5: " које је није обавезно ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_1: "Обрисано је поље са називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_3: " типа ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_4: " које је обавезно ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_5: " које је није обавезно ",

  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_1: "Измењено је поље са називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_3: " типа ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_4: " које је обавезно ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_5: " које је није обавезно ",

  LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_KATEGORIJA_1: "Креирана је категорија ",
  LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_1: " за поље пацијента која је обавезна ",
  LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_2: " за поље пацијента која није обавезна ",
  LOG_HIGH_LEVEL_MESS_INFO_PACIJENT_POLJE_KATEGORIJA_3: " и може се само читати",
  LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_KATEGORIJA_1: "Измењена је категорија ",
  LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_KATEGORIJA_1: "Обрисана је категорија ",

  LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_1: "За пацијента ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_CREATE: " додато је осигуранје типа ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_UPDATE: " измењено је осигурање типа ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_DELETE: " обрисано је осигуранје типа ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_OSIGURANJE_3: " које важи од ",

  LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_1: "Креирано је поље пацијента са називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_PACIJENT_POLJE_3: " типа ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_1: "Креирано је поље пацијента са називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PACIJENT_POLJE_3: " типа ",
  LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_1: "Креирано је поље пацијента са називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_PACIJENT_POLJE_3: " типа ",

  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_1: "Креирана је организациона јединица под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_2: ". Тип организационе јединице је ",
  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_3: ". Радни дани су ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_1: "Измењена је организациона јединица под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_2: ". Тип организационе јединице је ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_3: ". Радни дани су ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_1: "Обрисана је организациона јединица под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_2: ". Тип организационе јединице је ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_3: ". Радни дани су ",

  LOG_HIGH_LEVEL_MESS_CREATE_OBAVESTENJE_1: "Креирано је обавештење са насловом ",
  LOG_HIGH_LEVEL_MESS_CREATE_OBAVESTENJE_2: ", чији је садржај: ",
  LOG_HIGH_LEVEL_MESS_DELETE_OBAVESTENJE_1: "Обрисано је обавештење са насловом ",
  LOG_HIGH_LEVEL_MESS_DELETE_OBAVESTENJE_2: ", чији је садржај: ",

  LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_1: "Креиран је тип прегледа под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_2: ". Категорија прегледа је ",
  LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_3: ". Преглед је са аутоматским завршавањем. ",
  LOG_HIGH_LEVEL_MESS_CREATE_TIP_KONTAKTA_4: ". Преглед није са аутоматским завршавањем. ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_1: "Измењен је тип прегледа под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_2: ". Категорија прегледа је ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_3: ". Преглед је са аутоматским завршавањем. ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_4: ". Преглед није са аутоматским завршавањем. ",
  LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_1: "Обрисан је тип прегледа под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_2: ". Категорија прегледа је ",
  LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_3: ". Преглед је са аутоматским завршавањем. ",
  LOG_HIGH_LEVEL_MESS_DELETE_TIP_KONTAKTA_4: ". Преглед није са аутоматским завршавањем. ",

  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA: "За тип прегледа ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA_1: " је додата организациона јединица ",
  LOG_HIGH_LEVEL_MESS_UPDATE_TIP_KONTAKTA_ORGANIZACIONA_JEDINICA_2: " је уклоњена организациона јединица ",

  LOG_HIGH_LEVEL_MESS_CREATE_KATEGORIJA_IZVESTAJA_1: "Креирана нова категорија статистичког извештаја под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_KATEGORIJA_IZVESTAJA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_KATEGORIJA_IZVESTAJA_1: "Измењена категорија статистичког извештаја под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_KATEGORIJA_IZVESTAJA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_KATEGORIJA_IZVESTAJA_1: "Обрисана категорија статистичког извештаја под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_KATEGORIJA_IZVESTAJA_2: " и шифром ",

  LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_1: "Измењен је број налога под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_3: " са укупним бројем налога од ",
  LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_4: " налога и ценом од ",
  LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_5: " РСД",

  LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_1: " Креирано је ново ограничење складишта под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_2: ", шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_3: " и простором од ",
  LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_4: ", са ценом од ",
  LOG_HIGH_LEVEL_MESS_CREATE_OGRANICENJE_SKLADISTA_5: " РСД",

  LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_1: "Измењено је ново ограничење складишта под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_2: ", шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_3: " и простором од ",
  LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_4: ", са ценом од ",
  LOG_HIGH_LEVEL_MESS_UPDATE_OGRANICENJE_SKLADISTA_5: " РСД",

  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_1: "Обрисан је лиценцни модел по установи ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_2: " са бројем налога ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_3: " који важи од ",
  LOG_HIGH_LEVEL_MESS_DELETE_USTANOVA_BROJ_NALOGA_4: " до ",

  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_1: "Креиран је нови радник који припада установи ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_2: " са именом ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_3: ", презименом ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_4: ", мејл адресом ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_5: ", под корисничким именом ",

  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_1: "Измењен је радник који припада установи ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_2: " са именом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_3: ", презименом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_4: ", мејл адресом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_5: ", под корисничким именом ",

  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_1: "Обрисан је радник који припада установи ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_2: " са именом ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_3: ", презименом ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_4: ", мејл адресом ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_5: ", под корисничким именом ",

  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_1: "Радник који припада установи ",
  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_2: " са именом ",
  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_3: ", презименом ",
  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_4: ", мејл адресом ",
  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_5: ", под корисничким именом ",
  LOG_HIGH_LEVEL_MESS_RESET_PASS_RADNIK_6: " ресетовао је лозинку",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_1: "Креирана је услуга шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_USLUGA_3: " која важи од ",

  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_1: "Измењена је услуга шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_USLUGA_3: " која важи од ",

  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_1: "Обрисана је услуга шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_USLUGA_3: " која важи од ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_1: "Креирана је јединица мере шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_JEDINICA_MERE_3: " која важи од ",

  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_1: "Измењена је јединица мере шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_JEDINICA_MERE_3: " која важи од ",

  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_1: "Обрисана је јединица мере шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_JEDINICA_MERE_3: " која важи од ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_1: "Креиран је производ шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_2: ", шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_3: ", чија је јединца мере ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_4: " и која важи од ",

  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_1: "Измењен је производ шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_2: ", шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_3: ", чија је јединца мере ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_4: " и која важи од ",

  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_1: "Обрисан је производ шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_2: ", шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_3: ", чија је јединца мере ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_4: " и која важи од ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_1: "Креиран је производ рфзо шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_2: ", шифром ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_3: ", са статусом ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_PROIZVODA_RFZO_4: " и која важи од ",

  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_1: "Измењен је производ рфзо шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_2: ", шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_3: ", са статусом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_PROIZVODA_RFZO_4: " и која важи од ",

  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_1: "Обрисан је производ рфзо шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_2: ", шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_3: ", са статусом ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_PROIZVODA_RFZO_4: " и која важи од ",

  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_CREATE: "Креиран је атрибут рфзо шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_UPDATE: "Измењен је атрибут рфзо шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_1_DELETE: "Обрисан је атрибут рфзо шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_2: ", шифром ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_3: ", фактором увећања цене ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_4: ", статусом ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_ATRIBUTA_RFZO_5: " и који важи од ",

  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_CREATE: "Креирана је услуга рфзо шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_UPDATE: "Креиран је услуга рфзо шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_1_DELETE: "Креиран је услуга рфзо шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_2: ", шифром ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_3: ", статусом ",
  LOG_HIGH_LEVEL_MESS_SIFARNIK_USLUGA_RFZO_4: " и која важи од ",

  LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_CREATE: "Креиран је фактор ризика под називом ",
  LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_UPDATE: "Измењен је фактор ризика под називом ",
  LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_1_DELETE: "Обрисан је фактор ризика под називом ",
  LOG_HIGH_LEVEL_MESS_FAKTOR_RIZIKA_2: " и шифром ",

  LOG_HIGH_LEVEL_MESS_CREATE_RADNI_PERIOD_1: "Креиран је радни период за радника ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_2: ". Време трајања радног периода је од ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_3: " до ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_4: " Радни дани су: ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_5: ". Време почетка радног дана је ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_6: ", време краја радног дана је ",
  LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_7: ". Време трајања прегледа је ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNI_PERIOD_1: "Обрисан је радни период за радника чији је ",

  LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_ENUM_1: "Креирано је поље типа енум ставке под називом",
  LOG_HIGH_LEVEL_MESS_INFO_POLJE_TIP_ENUM_1: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_ENUM_1: "Обрисано је поље типа енум ставке под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_ENUM_1: "Измењено је поље типа енум ставке под називом ",

  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_1: "За параметар ",
  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_2: " статистичког извештаја ",
  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_CREATE: " креирана је енум ставка под називом ",
  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_DELETE: " обрисана је енум ставка под називом ",
  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_3_UPDATE: " ажурирана је енум ставка под називом ",
  LOG_HIGH_LEVEL_MESS_IZVESTAJ_PARAMETAR_ENUM_4: " и шифром ",

  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_1: "Измењена је установа са називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_2: " Параметри установе су: Назив кратки: ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_3: ", Језик: ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_4: ", Тип својине: ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_5: ", Ниво здравствене заштите: ",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_6: ", Подразумевана страна: ",

  LOG_HIGH_LEVEL_MESS_CREATE_REPO_POLJE_1: "Креирано је поље формулара репозиторијума под називом",
  LOG_HIGH_LEVEL_MESS_UPDATE_REPO_POLJE_1: "Измењено је поље формулара репозиторијума под називом",
  LOG_HIGH_LEVEL_MESS_DELETE_REPO_POLJE_1: "Обрисано је поље формулара репозиторијума под називом",
  LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_3: ". Статус поља: ",
  LOG_HIGH_LEVEL_MESS_INFO_REPO_POLJE_4: ", тип поља: ",
  LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_DIJAGNOZA_1: "Креирана је дијагноза са уносом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_DIJAGNOZA_1: "Измењена је дијагноза са уносом ",
  LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_DIJAGNOZA_1: "Обрисана је дијагноза са уносом ",
  LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_DIJAGNOZA_1: " и статусом ",

  LOG_HIGH_LEVEL_MESS_CREATE_DIALOG_TERMIN_1: "Заказан је термин прегледа типа ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_1: "Измењен је термин прегледа типа ",
  LOG_HIGH_LEVEL_MESS_DELETE_DIALOG_TERMIN_1: "Обрисан је термин прегледа типа ",
  LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_1: " у трајању од ",
  LOG_HIGH_LEVEL_MESS_INFO_DIALOG_TERMIN_2: " до ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_1: "Додат је коментар ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_2: " , на термин прегледа типа ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_3: " у трајању од ",
  LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_4: " до ",

  LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_UTROSAK_1: "Креиран је утрошак ",
  LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_UTROSAK_1: "Измењен је утрошак ",
  LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_UTROSAK_1: "Обрисан је утрошак ",
  LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_UTROSAK_1: ".Потрошена количина је ",

  LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_USLUGA_1: "Креирана је услуга ",
  LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_USLUGA_1: "Измењена је услуга ",
  LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_USLUGA_1: "Обрисана је услуга ",
  LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_USLUGA_1: ". Број пружања услуге је ",
  LOG_HIGH_LEVEL_MESS_CREATE_UNOSI_1: "Сачуван је налаз. Изабрани тип уноса је ",
  LOG_HIGH_LEVEL_MESS_DELETE_UNOSI_1: "Обрисан је налаз типа уноса ",

  LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_1: "Обрисан је преглед типа ",

  LOG_HIGH_LEVEL_MESS_PRISTUP_DOSIJE_PACIJENT: "Приступ досијеу пацијента.",
  LOG_HIGH_LEVEL_MESS_LOGOUT_KORISNIKA: "Корисник се излоговао.",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_GRID: "Измена грида установе.",
  LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_PODESAVANJA: "Измењена подешавања установе",

  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_TIP_1: " додат је тип прегледа ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_TIP_2: " уклоњен је тип прегледа ",

  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_1: " додат је радник ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_2: " уклоњен је радник ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_3: " додати су радници ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_RADNIK_4: " уклоњени су радници ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA: "На организационој јединици ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA: "Измењена је улога ",

  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA: "За улогу ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_RADNIK_1: " је додат радник ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_RADNIK_2: " је уклоњен радник ",

  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_APLIKATIVNA_PRAVA_1: " је додато апликативно право ",
  LOG_HIGH_LEVEL_MESS_UPDATE_ULOGA_APLIKATIVNA_PRAVA_2: " је уклоњен апликативно право ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_1: "За радника ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_2: " је додата улога ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ULOGA_3: " је уклоњена улога ",

  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_1: "За радника ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_2: " је додата организациона јединица ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_3: " је уклоњена организациона јединица ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_4: " су додате организационе јединице ",
  LOG_HIGH_LEVEL_MESS_UPDATE_RADNIK_ORGANIZACIONA_JEDINICA_5: " су уклоњенe организационe јединицe ",

  LOG_HIGH_LEVEL_MESS_CREATE_IZVESTAJ_1: "Креиран је извештај под називом ",
  LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_1: ", категорија извештаја је ",

  LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_2: ", формат: ",
  LOG_HIGH_LEVEL_MESS_DELETE_IZVESTAJ_1: "Обрисан је извештај под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_IZVESTAJ_1: "Измењен је извештај под називом ",

  LOG_HIGH_LEVEL_MESS_CREATE_IZVESTAJ_PARAMETAR_1: "Креиран је параметар извештаја под називом ",
  LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_1: ", тип извештаја ",
  LOG_HIGH_LEVEL_MESS_INFO_IZVESTAJ_PARAMETAR_2: ", назив захтева ",
  LOG_HIGH_LEVEL_MESS_DELETE_IZVESTAJ_PARAMETAR_1: "Обрисан је параметар извештаја под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_IZVESTAJ_PARAMETAR_1: "Измењен је параметар извештаја под називом ",

  LOG_HIGH_LEVEL_MESS_DELETE_GENERISANI_IZVESTAJ_1: "Обрисан је генерисани извештај везан за статистички извештај под називом ",

  LOG_HIGH_LEVEL_MESS_CREATE_PROTOKOL_1: "Креиран је протокол извештаја под називом ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_1: ", префикс протокола је ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_2: ", временски оквир : ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_3: ", почетни број ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_4: ", наредни број: ",
  LOG_HIGH_LEVEL_MESS_DELETE_PROTOKOL_1: "Обрисан је протокол под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PROTOKOL_1: "Измењен је протокол под називом ",

  LOG_HIGH_LEVEL_MESS_DELETE_PROTOKOL_BROJ_1: "Обрисан је протокол ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_1: ", за пацијента ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_2: ", време од: ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_3: ", број: ",
  LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_4: ", организационе јединице ",

  LOG_HIGH_LEVEL_MESS_DELETE_FAKTOR_RIZIKA_1: "Уклоњен је фактор ризика под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_FAKTOR_RIZIKA_1: "Додат је фактор ризика под називом ",
  LOG_HIGH_LEVEL_MESS_INFO_FAKTOR_RIZIKA_1: " са шифром ",

  LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_1: "Креиран је извоз/екsпорт са датумом од ",
  LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_2: ", са датумом до ",
  LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_3: ", са разлогом захтева ",

  LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_1: "Обрисана је терапија са ид ",
  LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_2: ", са лекom ",
  LOG_HIGH_LEVEL_MESS_TERAPIJA_DELETE_3: ", са дијагнозом ",

  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_UGOVOR_1: "Креирана су плаћања за уговор у трајању од датума ",
  LOG_HIGH_LEVEL_MESS_CREATE_PLACANJE_UGOVOR_2: " до датума ",

  LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_1: "Kреирана је терапија са дијагнозом са ид ",
  LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_2: ", са шифром терапије ",
  LOG_HIGH_LEVEL_MESS_TERAPIJA_CREATE_3: ", са називом лека ",

  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_CREATE_1: "Kреиран је пакет на контакту са ид ",
  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_CREATE_2: ", са пакетом производа ид ",

  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_1: "Са контакта је обрисан пакет производа под називом ",
  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_DELETE_2: " , који је креирао радник ",

  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_UPDATE_1: "На контакту је измењен пакет производа под називом ",
  LOG_HIGH_LEVEL_MESS_KONTAKT_PAKET_UPDATE_2: ", који је креирао радник ",

  LOG_HIGH_LEVEL_MESS_GENERISANI_IZVESTAJI_DELETE_1: "Обрисан је генерисани извештај са насловом ",
  LOG_HIGH_LEVEL_MESS_GENERISANI_IZVESTAJI_DELETE_2: ", који је креирао радник ",

  LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_1: "Креиран је пацијент са именом и презименом ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_2: ", са датумом рођења ",
  LOG_HIGH_LEVEL_MESS_PACIJENT_CREATE_3: ", са полом ",

  LOG_HIGH_LEVEL_MESS_DELETE_ZAKAZAN_IZVESTAJ1: "На статистичком извештају ",
  LOG_HIGH_LEVEL_MESS_DELETE_ZAKAZAN_IZVESTAJ2: ", обрисан је заказани извештај",

  LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ1: "На статистичком извештају ",
  LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ2: ", заказан је извештај са понављањем генерисања - ",
  LOG_HIGH_LEVEL_MESS_CREATE_ZAKAZAN_IZVESTAJ3: " и временом генерисања - ",

  LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ1: "На статистичком извештају ",
  LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ2: ", измењен је извештај са понављањем генерисања - ",
  LOG_HIGH_LEVEL_MESS_EDIT_ZAKAZAN_IZVESTAJ3: " и временом генерисања - ",

  LOG_HIGH_LEVEL_MESS_CREATE_GENERISAN_IZVESTAJ1: "На статистичком извештају ",
  LOG_HIGH_LEVEL_MESS_CREATE_GENERISAN_IZVESTAJ2: ", генерисан је нови извештај",

  LOG_HIGH_LEVEL_MESS_CENA_CREATE_1: "Креирана је цена за прозвод са ид ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_USLUGA_1: "Креирана је цена за услугу са ид ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_2: ", са датумом од ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_3: ", са износом ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_4: ", са валутом ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_5: ", са пореском стопом ",
  LOG_HIGH_LEVEL_MESS_CENA_DELETE_1: "Oбрисанa jе цена за прозвод са ид ",
  LOG_HIGH_LEVEL_MESS_CENA_UPDATE_1: "Измењенa jе цена за прозвод са ид ",
  LOG_HIGH_LEVEL_MESS_CENA_DELETE_USLUGA_1: "Oбрисанa jе цена за услугу са ид ",
  LOG_HIGH_LEVEL_MESS_CENA_UPDATE_USLUGA_1: "Измењенa jе цена за услугу са ид ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_1: "Креирана је цена за партнера ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_2: ", са услугом ",
  LOG_HIGH_LEVEL_MESS_CENA_CREATE_PARTNER_3: ", са датумом важи од ",
  LOG_HIGH_LEVEL_MESS_CENA_DELETE_PARTNER_1: "Oбрисанa jе цена за партнера ",
  LOG_HIGH_LEVEL_MESS_CENA_UPDATE_PARTNER_1: "Измењенa jе цена за партнера ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_NACINA_PLACANJA_1: "Креиран је начин плаћања шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_NACINA_PLACANJA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_NACINA_PLACANJA_1: "Измењен је начин плаћања шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_NACINA_PLACANJA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_NACINA_PLACANJA_1: "Обрисан је начин плаћања шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_NACINA_PLACANJA_2: " и шифром ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_KATEGORIJA_POPUSTA_1: "Креирана је категорија попуста шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_KATEGORIJA_POPUSTA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_KATEGORIJA_POPUSTA_1: "Измењена је категорија попуста шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_KATEGORIJA_POPUSTA_2: " и шифром ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_KATEGORIJA_POPUSTA_1: "Обрисана је категорија попуста шифарника под називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_KATEGORIJA_POPUSTA_2: " и шифром ",

  LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_1: "Креиран је партнер под називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_PARTNER_2: " и шифром ",

  LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_1: "Креирано је групно обавештење са садржајем ",
  LOG_HIGH_LEVEL_MESS_CREATE_GRUPNO_OBAVESTENJE_2: ", и типом обавештења ",
  LOG_HIGH_LEVEL_MESS_DELETE_GRUPNO_OBAVESTENJE_1: "Обрисанo је групно обавештење са садржајем ",
  LOG_HIGH_LEVEL_MESS_UPDATE_GRUPNO_OBAVESTENJE_1: "Измењенo је групно обавештење са садржајем ",
  LOG_HIGH_LEVEL_MESS_SEND_GRUPNO_OBAVESTENJE_1: "Послато је групно обавештење са садржајем ",

  LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_1: "Креиран је образац штампе протокола са називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_2: ", и описом ",
  LOG_HIGH_LEVEL_MESS_CREATE_OBRAZAC_STAMPE_PROTOKOLA_3: ", и јаспер фајлом ",
  LOG_HIGH_LEVEL_MESS_DELETE_OBRAZAC_STAMPE_PROTOKOLA_1: "Обрисан је образац штампе протокола са називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_OBRAZAC_STAMPE_PROTOKOLA_1: "Измењен је образац штампе протокола са називом ",

  LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_SPECIJALIZACIJA: "Креирана је специјализација са називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_SPECIJALIZACIJA: "Измењена је специјализација са називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_SPECIJALIZACIJA: "Обрисана је специјализација са називом ",

  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJA_1: "За радника ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJA_2: " је додата специјализација ",
  LOG_HIGH_LEVEL_MESS_CREATE_RADNIK_SPECIJALIZACIJE_2: " су додате специјализације ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJA_1: "За радника ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJA_2: " је уклоњена специјализација ",
  LOG_HIGH_LEVEL_MESS_DELETE_RADNIK_SPECIJALIZACIJE_2: " су уклоњене специјализације ",

  LOG_HIGH_LEVEL_PACIJENT_USPESNO_SPOJEN_1: "Пацијент са id: ",
  LOG_HIGH_LEVEL_PACIJENT_USPESNO_SPOJEN_2: " спојен је са пацијентом са id: ",

  LOG_HIGH_LEVEL_MESS_RAZDVAJANJE_PACIJENATA_1: "Раздвојени су пацијенти ",

  LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJA_1: "За услугу ",
  LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJA_2: " је додата специјализација ",
  LOG_HIGH_LEVEL_MESS_CREATE_USLUGA_SPECIJALIZACIJE_2: " су додате специјализације ",
  LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJA_1: "За услугу ",
  LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJA_2: " је уклоњена специјализација ",
  LOG_HIGH_LEVEL_MESS_DELETE_USLUGA_SPECIJALIZACIJE_2: " су уклоњене специјализације ",

  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_1: "За организациону јединицу ",
  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_2: " је додата специјализација ",
  LOG_HIGH_LEVEL_MESS_CREATE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE_2: " су додате специјализације ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_1: "За организациону јединицу ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJA_2: " је уклоњена специјализација ",
  LOG_HIGH_LEVEL_MESS_DELETE_ORGANIZACIONA_JEDINICA_SPECIJALIZACIJE_2: " су уклоњене специјализације ",

  LOG_HIGH_LEVEL_MESS_CREATE_PRODAJNI_PAKET_1: "Креиран је продајни пакет са називом ",
  LOG_HIGH_LEVEL_MESS_CREATE_PRODAJNI_PAKET_2: " и ценом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PRODAJNI_PAKET_1: "Измењен је продајни пакет са називом ",
  LOG_HIGH_LEVEL_MESS_UPDATE_PRODAJNI_PAKET_2: " и ценом ",
  LOG_HIGH_LEVEL_MESS_DELETE_PRODAJNI_PAKET_1: "Обрисан је продајни пакет са називом ",
  LOG_HIGH_LEVEL_MESS_DELETE_PRODAJNI_PAKET_2: " и ценом ",

  // FAKTORI RIZIKA
  FAKTOR_RIZIKA_LIST: "Фактори ризика",
  BUTTON_ADD_FAKTOR_RIZIKA: "Додај фактор ризика",
  FAKTOR_RIZIKA_TITLE_DIALOG_CREATE: "Креирање новог фактора ризика",
  FAKTOR_RIZIKA_TITLE_DIALOG_UPDATE: "Измена фактора ризика",
  FAKTOR_RIZIKA_TITLE_DIALOG_DELETE: "Брисање фактора ризика",
  FAKTOR_RIZIKA_SEARCH: "Претрага фактора ризика",
  FAKTOR_RIZIKA_SIFRA: "Шифра",
  FAKTOR_RIZIKA_NAZIV: "Назив",
  LABEL_FAKTOR_RIZIKA: "Фактор ризика",
  FAKTOR_RIZIKA_SIFRA_IKONICE: "Иконица",
  FAKTOR_RIZIKA_TITLE_MESSAGE_UPDATE_FAKTOR_RIZIKA_SUCCESS: "Успешно измењен фактор ризика",
  FAKTOR_RIZIKA_SIFRA_IS_REQUIRED: "Обавезно је унети шифру.",
  FAKTOR_RIZIKA_NAZIV_IS_REQUIRED: "Обавезно је унети назив.",
  FAKTOR_RIZIKA_IKONICA_IS_REQUIRED: "Обавезно је изабрати иконицу.",
  FAKTOR_RIZIKA_TITLE_MESSAGE_CREATE_FAKTOR_RIZIKA_SUCCESS: "Успешно креиран фактор ризика.",
  FAKTOR_RIZIKA_TITLE_MESSAGE_DELETE_FAKTOR_RIZIKA_SUCCESS: "Успешно обрисан фактор ризика.",
  FAKTOR_RIZIKA_DELETE_DIALOG_MESSAGE: "Постоје пацијенти за које је везан фактор ризика који желите да онемогућите. Да ли сте сигурни да желите да наставите?",

  //FAKTORI RIZIKA IKONICA
  FAKTOR_RIZIKA_IKONICA_LIST: "Иконице фактора ризика",
  BUTTON_ADD_FAKTOR_RIZIKA_IKONICA: "Додај иконицу фактора ризика",
  FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_CREATE: "Креирање нове иконице фактора ризика",
  FAKTOR_RIZIKA_IKONICA_SEARCH: "Претрага иконица фактора ризика",
  FAKTOR_RIZIKA_IKONICA_SIFRA: "Шифра",
  FAKTOR_RIZIKA_IKONICA_NAZIV: "Назив",
  FAKTOR_RIZIKA_IKONICA_IKONICA: "Иконица",
  FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_UPDATE: "Измена иконице фактора ризика",
  FAKTOR_RIZIKA_IKONICA_TITLE_DIALOG_DELETE: "Брисање иконице фактора ризика",
  LABEL_FAKTOR_RIZIKA_IKONICA: "Иконица фактора ризика",
  FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_DELETE_FAKTOR_RIZIKA_SUCCESS: "Успешно обрисана иконица фактора ризика",
  FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_UPDATE_FAKTOR_RIZIKA_IKONICA_SUCCESS: "Успешно измењена иконица фактора ризика.",
  FAKTOR_RIZIKA_IKONICA_TITLE_MESSAGE_CREATE_FAKTOR_RIZIKA_IKONICA_SUCCESS: "Успешно креирана иконица фактора ризика.",
  FAKTOR_RIZIKA_IKONICA_SIFRA_IS_REQUIRED: "Обавезно је унети шифру.",
  FAKTOR_RIZIKA_IKONICA_NAZIV_IS_REQUIRED: "Обавезно је унети назив.",
  FAKTOR_RIZIKA_IKONICA_IKONICA_IS_REQUIRED: "Обавезно је изабрати иконицу.",

  // Fakturni podaci
  USTANOVA_FAKTURNI_PODACI: "Фактурни подаци",
  FAKTURA_NAZIV: "Назив установе",
  FAKTURA_ADRESA: "Адреса",
  FAKTURA_MESTO: "Место",
  FAKTURA_MB: "Матични број",
  FAKTURA_PIB: "ПИБ",
  FAKTURA_MAIL: "Имејл",
  USTANOVA_FAKTURNI_PODACI_TITLE_MESSAGE_UPDATE_USTANOVA_FAKTURNI_PODACI_SUCCESS: "Фактурни подаци успешно измењени",
  FAKTURA_MAIL_FORMAT_ERROR: "Имејл није валидан",
  FAKTURA_PIB_FORMAT_ERROR: "ПИБ није валидан",
  FAKTURA_MB_FORMAT_ERROR: "Матични број није валидан",
  ZAKAZIVANJE_ZA_BUDUCNOST: "Заказивање за будућност",
  NO_IZVESTAJ_PARAMETRI: "Нема параметара за приказивање.",

  // Uvoz sifarnika
  SIFARNIK_IMPORT: "Увоз шифарника",
  SIFARNIK_IMPORT_INATIATE_SUCCESS: "Успешно инициран увоз шифарника ",
  SIFARNIK_IMPORT_USPESNO_UVEZEN: "Успешно увезен шифарник ",
  SIFARNIK_IMPORT_U_TOKU: "У току",
  IMPORT_SIFARNIK: "Увоз шифарника",
  SIFARNIK_IMPORT_CONNECTION_ERROR: "Дошло је до грешке приликом успостављања конекције са сервером",
  SIFARNIK_IMPORT_STARTED_SUCCESS: "Започет је увоз шифарника ",

  // Izvoz/Export
  IZVOZ: "Извоз",
  IZVOZ_NOVI_ZAHTEV: "Нови захтев",
  IZVOZ_NOVI_ZAHTEV_KREIRANJE: "Креирај нови захтев",
  IZVOZ_IME_PREZIME_ZAHTEVAOCA: "Име и презиме захтеваоца",
  IZVOZ_DATUM_ZAHTEVA: "Датум захтева",
  IZVOZ_VREME_ZAHTEVA: "Време захтева",
  IZVOZ_PERIOD_OD: "Период од",
  IZVOZ_PERIOD_DO: "Период до",
  IZVOZ_RAZLOG_ZAHTEVA: "Разлог захтева",
  IZVOZ_DATUM_GENERISANJA: "Датум генерисања",
  IZVOZ_VREME_GENERISANJA: "Време генерисања",
  IZVOZ_TITLE_DIALOG_CREATE: "Креирање новог извоза",
  IZVOZ_DIALOG_CREATE_DATUM_POCETKA: "Датум почетка",
  IZVOZ_DIALOG_CREATE_DATUM_ZAVRSETKA: "Датум завршетка",
  IZVOZ_DIALOG_CREATE_RAZLOG: "Разлог",
  IZVOZ_DIALOG_MESSAGE_CREATE_IZVOZ_SUCCESS: "Извоз је успешно креиран.",
  IZVOZ_DIALOG_PERIOD_OD_IS_REQUIRED: "Обавезно је унети датум почетка",
  IZVOZ_DIALOG_PERIOD_DO_IS_REQUIRED: "Обавезно је унети датум завршетка",
  IZVOZ_ZAHTEVANO: "Захтевано",
  IZVOZ_GENERISANO: "Генерисано",
  IZVOZ_GENERISI: "Генерисање извоза",
  IZVOZ_DOWNLOAD: "Скидање извоза",
  IZVOZ_VAZI_OD_IS_BEFORE_VAZI_DO: "Датум почетка мора бити пре датума завршетка",

  USTANOVA_HARD_DELETE_TITLE_MESSAGE_SUCCESS: "Брисање установе успешно завршено.",
  WEBSOCKET_CONNECTION_ERROR: "Дошло је до грешке приликом успостављања конекције са сервером",
  WEBSOCKET_CLOSE_CONNECTION: "Затвори конекцију.",

  KONTAKT_NEMA_REALIZOVANIH: "Нема реализованих прегледа за пацијента",

  //PORUKE
  PORUKE: "Поруке",
  PORUKE_PRIMLJENE: "Примљене поруке",
  PORUKE_POSLATE: "Послате поруке",
  PORUKE_NOVE: "Нова порука",
  PORUKE_PRIMALAC: "Прималац",
  PORUKE_NASLOV: "Наслов",
  PORUKE_SADRZAJ: "Садржај",
  PORUKE_DODAJ_DOKUMENT: "Додај документ",
  PORUKE_ISPRAZNI: "Испразни поља",
  PORUKE_POSALJI: "Пошаљи",
  PORUKE_ZAKACI_DOKUMENT: "Закачи документ",
  PORUKA_CREATE_SUCCESS_MESSAGE: "Порука је успешно послата.",
  PORUKA_CREATE_EMPTY_RECEPIENTS_MESSAGE_ERROR: "Примаоци су обавезно поље.",
  PORUKA_CREATE_EMPTY_MESSAGE_ERROR: "Порука мора имати наслов.",
  PORUKA_CREATE_EMPTY_SADRZAJ_AND_ATTACHMENT_ERROR: "Порука мора имати садржај или бар један приложен документ.",
  PORUKA_CREATE_PORUKA_SIZE_ERROR: "Приложени документи превазилазе максималну дозвољену величину од 25MB.",
  PORUKA_SEARCH: "Претрага",
  PRIKAZI_PORUKU_1: "Молимо",
  PRIKAZI_PORUKU_2: "кликните",
  PRIKAZI_PORUKU_3: "на поруку за приказ.",
  PORUKA_POSILJALAC: "Пошиљалац:",
  PORUKA_PRIMALAC: "Прималац:",
  PORUKA_PRIMAOCI: "Примаоци:",
  PORUKE_KRAJ: "Немате више порука!",
  TOOLTIP_PORUKA_REPLY: "Одговори",
  TOOLTIP_PORUKA_REPLY_ALL: "Одговори свима",
  TOOLTIP_PORUKA_FORWARD: "Проследи",
  TOOLTIP_PORUKA_UNREAD: "Обележи непрочитано",
  TOOLTIP_PORUKA_DELETE: "Обриши за себе",
  PORUKA_DELETE_DIALOG_TEXT: "Да ли сте сигурни да желите да обришете?",
  POCETAK_PROSLEDJENE_PORUKE: "Почетак прослеђене поруке:",
  PORUKA_DATUM: "Датум",
  PORUKA_RODITELJ_NASLOV_1: "Дана",
  PORUKA_RODITELJ_NASLOV_2: "у",
  PORUKA_RODITELJ_NASLOV_3: "је написао/ла:",
  PORUKA_RODITELJ_NASLOV_3_MUSKI: "је написао:",
  PORUKA_RODITELJ_NASLOV_3_ZENSKI: "је написала:",

  // CENE
  CENE_CREATE_EMPTY_PROIZVOD_ERROR_MESSAGE: "Производ је обавезан података.",
  CENE_CREATE_EMPTY_USLUGA_ERROR_MESSAGE: "Услуга je obavezan podatak.",
  CENE_CREATE_EMPTY_IZNOS_ERROR_MESSAGE: "Износ је обавезан податак.",
  CENE_CREATE_EMPTY_VALUTA_ERROR_MESSAGE: "Валута је обавезан податак.",
  CENE_CREATE_EMPTY_PORESKA_STOPA_ERROR_MESSAGE: "Пореска стопа је обавезан податак.",
  CENE_CREATE_EMPTY_DATUM_OD_ERROR_MESSAGE: "Важи Од датум је обавезан податак.",
  CENE_CREATE_WRONG_DATUM_DO_ERROR_MESSAGE: "Важи До датум мора бити после Важи Од датума.",
  CENE_CREATE_SUCCESS_MESSAGE: "Успешно креирана нова цена.",
  CENE_DELETE_SUCCESS_MESSAGE: "Успешно обрисана цена.",
  CENE_UPDATE_SUCCESS_MESSAGE: "Успешно измењенa цена.",

  // PARTNERI
  LABEL_PARTNERI: "Партнери",
  LABEL_EDIT_PARTNER: "Измени партнера",
  LABEL_DELETE_PARTNER: "Обриши партнера",
  LABEL_ADD_PARTNER: "Додај партнера",
  SEARCH_PARTNER: "Претрага партнера",
  LABEL_PARTNER_PODACI: "Подаци о партнеру",
  LABEL_PARTNER_CENE: "Цене за партнера",
  LABEL_PARTNER_CREATE: "Креирање партнера ",
  LABEL_PARTNER_UPDATE: "Измена партнера ",
  LABEL_PARTNER_DELETE: "Брисање партнера ",
  LABEL_TIP_PARTNERA: "Тип",
  LABEL_KATEGORIJA_POLISE_PARTNER: "Категорија полисе",
  LABEL_ADRESA_PARTNER: "Адреса",
  LABEL_POSTANSKI_BROJ_PARTNER: "Поштански број",
  LABEL_SEDISTE_PARTNER: "Седиште",
  LABEL_DRZAVA_PARTNER_PARTNER: "Држава партнер",
  LABEL_ZIRO_RACUN_PARTNER: "Жиро рачун",
  LABEL_PIB_PARTNER: "ПИБ",
  LABEL_MATICNI_BROJ_PARTNER: "Матични броj",
  LABEL_SIFRA_DELATNOSTI_PARTNER: "Шифра делатности",
  LABEL_TELEFON_PARTNER: "Телефон",
  LABEL_EMAIL_PARTNER: "Имејл",
  LABEL_BROJ_DANA_VALUTE_PLACANJA_PARTNER: "Број дана валуте плаћања",
  LABEL_POPUST_PARTNER: "Попуст",
  LABEL_STAMPANJE_FISKALNOG_ISECKA_PARTNER: "Штампање фискалног исечка",
  LABEL_PLACANJE_DEVIZE_PARTNER: "Плаћање у девизама",
  LABEL_DEO_PACIJENT_PARTNER: "Део који плаћа пацијент",
  LABEL_NAPOMENA_PARTNER: "Напомена",
  LABEL_DODAJ_PARTNER: "Додај",
  LABEL_IZMENI_PARTNER: "Измени",
  LABEL_OBRISI_PARTNER: "Обриши",
  LABEL_ADD_CENA_PARTNER: "Додај цену",
  LABEL_ADD_CENA_PARTNER_DIALOG: "Додај посебну цену за партнера",
  LABEL_UPDATE_CENA_PARTNER_DIALOG: "Измени цену за партнера",
  LABEL_DELETE_CENA_PARTNER_DIALOG: "Обриши цену за партнера",
  LABEL_POPUST_CENA_PARTNER_DIALOG: "Попуст",
  LABEL_PLACA_PACIJENT_CENA_PARTNER_DIALOG: "Плаћа пацијент (%)",
  LABEL_FIKSNO_PLACA_PACIJENT_CENA_PARTNER_DIALOG: "Фиксно плаћа пацијент",
  LABEL_FIKSNO_DODATAK_CENA_PARTNER_DIALOG: "Фиксни додатак на цену",
  LABEL_UPDATE_CENA_PARTNER: "Измени цену",
  LABEL_DELETE_CENA_PARTNER: "Обриши цену",
  LABEL_NAZIV_USLUGE_PARTNER: "Назив услуге",
  LABEL_VAZI_OD_PARTNER: "Важи Од",
  LABEL_VAZI_DO_PARTNER: "Важи До",
  LABEL_PLACA_PACIJENT_PARTNER: "Плаћа пацијент",
  LABEL_PLACA_PACIJENT_FIKSNO_PARTNER: "Плаћа пацијент фиксно",
  LABEL_CENA_PARTNER: "Цена",
  LABEL_PORESKA_STOPA_PARTNER: "Пореска стопа",
  LABEL_POPUST_OD_PARTNER: "Попуст од",
  LABEL_POPUST_DO_PARTNER: "Попуст до",
  LABEL_UDEO_PACIJENTA_OD_PARTNER: "Удео пацијента од",
  LABEL_UDEO_PACIJENTA_DO_PARTNER: "Удео пацијента до",
  LABEL_FIKSNI_UDEO_OD_PARTNER: "Фиксни удео од",
  LABEL_FIKSNI_UDEO_DO_PARTNER: "Фиксни удео дo",
  LABEL_CENA_OD_PARTNER: "Цена од",
  LABEL_CENA_DO_PARTNER: "Цена дo",
  LABEL_DEO_PLACA_PACIJENT: "Део који плаћа пацијент",
  PARTNER_MESSAGE_CREATE_SUCCESS: "Успешно сте креирали партнера.",
  PARTNER_MESSAGE_UPDATE_SUCCESS: "Успешно сте изменили партнера.",
  PARTNER_MESSAGE_DELETE_SUCCESS: "Успешно сте обрисали партнера.",
  PARTNER_VAZI_DO_VALIDATION_ERROR_MESSAGE: "Важи До мора бити после датума Важи Од",
  LABEL_PARTNERI_TIP_PARTNERA: "Тип партнера",

  // NAPLATA
  LABEL_NAPLATA: "Наплата",
  LABEL_SPISAK_NAPLATA: "Списак",
  LABEL_UPLATIOCI_NAPLATA: "Уплатиоци",
  LABEL_RACUNI_NAPLATA: "Рачуни",
  LABEL_NASTAVI_NAPLATA: "Настави",
  LABEL_BRZA_NAPLATA: "Брза наплата",
  LABEL_NAPLATI: "Наплати",
  LABEL_SNIMI_NAPLATA: "Сними",
  LABEL_BROJ_PROTOKOLA_NAPLATA: "Број протокола",
  LABEL_PARTNER_NAPLATA: "Партнер",
  LABEL_LEKAR_NAPLATA: "Лекар",
  LABEL_TIP_PREGLEDA_NAPLATA: "Тип прегледа",
  LABEL_DATUM_NAPLATA: "Датум прегледа",
  LABEL_PREGLED_REALIZOVAN_NAPLATA: "Преглед реализован",
  LABEL_AMBULANTA_NAPLATA: "Амбуланта",
  LABEL_PACIJENT_NAPLATA: "Пацијент",
  LABEL_PRIKAZ_NAPLATA: "Приказ",
  LABEL_STATUS_RACUNA_NAPLATA: "Статус рачуна",
  LABEL_RACUN_NAPLATIV_NAPLATA: "Наплатив",
  LABEL_UPLATILAC_NAPLATA: "Уплатилац",
  LABEL_POPUST_NAPLATA: "Попуст",
  LABEL_PLACA_NAPLATA: "Плаћа",
  LABEL_NAPOMENA_NAPLATA: "Напомена",
  LABEL_OBRISI_UPLATIOCA: "Обриши уплатиоца",
  LABEL_UMANJENJE_RACUNA: "Умањење рачуна",
  LABEL_CEK: "Чек",
  LABEL_GOTOVINA: "Готовина",
  LABEL_VAUCER: "Ваучер",
  LABEL_PRENOS_NA_RACUN: "Пренос на рачун",
  LABEL_INSTANT: "Инстант",
  LABEL_DRUGO_BEZGOTOVINSKO: "Друго безготовинско",
  LABEL_KARTICA: "Картица",
  LABEL_RACUN_ZA_UPLATIOCA_NAPLATA: "Рачун за уплатиоца",
  LABEL_REDNI_BROJ_NAPLATA: "Редни број",
  LABEL_KOLICINA_NAPLATA: "Количина",
  LABEL_VREME_PRUZANJA_NAPLATA: "Време пружања",
  LABEL_BEZ_POPUSTA_NAPLATA: "Без попуста",
  LABEL_KONTAKT_REQUIRED_NAPLATA: "Морате изабрати преглед",
  LABEL_RACUN_KREIRAN_SUCCESS: "Успешно креиран рачун",
  LABEL_UKUPNA_CENA_NAPLATA: "Укупна цена",
  LABEL_KONTAKT_USLUGE_UTROSCI_NAPLATA: "Услуге и утрошци прегледа",
  LABEL_DRUGI_KONTAKTI_USLUGE_UTROSCI_NAPLATA: "Услуге и утрошци са осталих контаката",
  LABEL_BEZ_POPUSTA: "Без попуста",
  LABEL_ZBIR_PROCENATA_NAPLATA: "Збир процената за плаћање услуге/утрошка мора бити 100",
  LABEL_UPLATIOCI_REQUIRED: "Нисте попунили све уплатиоце",
  LABEL_IZNOS_RACUNA_REQUIRED: "Нисте унели тачан износ рачуна",
  LABEL_UKUPNO_NAPLATA: "Укупно",
  LABEL_CENA_SA_POPUSTIMA: "Цена са попустом",
  LABEL_ZBIR_CENA_NAPLATA: "Нисте унели тачан износ",
  LABEL_PROCENAT_NULA_NAPLATA: "Сви унети проценти морају бити већи од 0",
  LABEL_NACINI_PLACANJA_REQUIRED: "Није могуће извршити наплату јер не постоје дефинисани начини плаћања.",
  LABEL_NAPLATA_PREKOPIRAJ_PREOSTALI_IZNOS: "Прекопирај преостали износ",
  LABEL_INFO_OBRACUNATA_CENA: "Цена је израчуната по средњем курсу НБС на дан ",
  LABEL_WARNING_PROMENA_POPUSTA: "Одабиром попуста занемарује се партнерова цена за услугу.",
  LABEL_SPECIFIKACIJE_RACUNA_NAPLATA: "Спецификације рачуна",
  LABEL_SPECIFIKACIJE_RACUNA_NAPLATA_WARNING_POPUP: "Да бисте одштампали спецификацију рачуна, потребно је да дозволите попуп на претраживачу.",

  // OBAVESTENJA ZA PACIJENTE
  LABEL_OBAVESTENJA_ZA_PACIJENTE: "Обавештења за пацијенте",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TITLE_ADD: "Додај обавештење",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TITLE_UPDATE: "Измени обавештење",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_TEKST: "Текст обавештења",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_NASLOV: "Наслов",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_DIALOG_UNESITE_TEKST: "Унесите текст обавештења",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_POSALJI: "Пошаљи",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_DELETE_CONFIRM_MESSAGE: "Да ли сте сигурни да желите да обришете обавештење?",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_CREATE_SUCCESS_MESSAGE: "Успешно сте креирали групно обавештење",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_EMPTY_DATA: "Нема додатих групних обавештења.",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_DELETE_SUCCESS_MESSAGE: "Успешно сте обрисали групно обавештење.",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_UPDATE_SUCCESS_MESSAGE: "Успешно сте изменили групно обавештење.",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_MESSAGE: "Успешно сте послали групно обавештење.",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_LABEL: "Обавештење је послато!",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_SUCCESS_DATE: "Послато",
  LABEL_OBAVESTENJA_TAB_ZA_SLANJE: "За слање",
  LABEL_OBAVESTENJA_TAB_POSLATA: "Послата",
  LABEL_VRSTA_OBAVESTENJA: "Врста обавештења",
  LABEL_DATUM_PRIPREME_OBAVESTENJA: "Датум припреме",
  LABEL_DATUM_SLANJA_OBAVESTENJA: "Датум слања",
  LABEL_TEKST_OBAVESTENJA: "Текст обавештења",
  LABEL_OBAVESTENJE_KREIRANO_VREME: "Креирано",
  LABEL_OBAVESTENJE_POSLATO_VREME: "Послато",
  LABEL_OBAVESTENJE_KREIRANO_U: "у",
  LABEL_OBAVESTENJE_POSLEDNJE_AZURIRANO_U: "у",
  LABEL_OBAVESTENJE_POSLEDNJE_AZURIRANO: "Ажурирано",
  LABEL_OBAVESTENJE_OBUHVACENO_PACIJENATA: "Обухваћено пацијената",
  LABEL_OBAVESTENJE_GODINA: "година",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_SEND_CONFIRM_MESSAGE: "Да ли сте сигурни да желите да пошаљете обавештење?",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_BROJ_PRIMALACA: "Број прималаца",
  LABEL_OBAVESTENJE_DIJAGNOZA_USLUGA_NOTE: "Пацијент ће се наћи у листи примаоца ако има бар једну дијагнозу и једну услугу од одабраних.",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_POL_REQUIRED: "Морате одабрати макар један пол",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_DATUM_AUTOMATSKOG_SLANJA: "Датум аутом.слања",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_TEXT: "Порука ће бити послата",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_DATE: "Датум",
  LABEL_OBAVESTENJA_ZA_PACIJENTA_AUTOMATSKO_SLANJE_TIME: "Време",
  LABEL_OBAVESTENJA_ZA_PACIJENTE_NO_PATIENTS: "Не постоји ниједан прималац за слање обавештења",
  LABEL_OBAVESTENJE_ZA_PACIJENTE_SEND_NOTE:
    "Слање групних обавештења се врши кроз функционалност одложеног слања како не би дошло до загушења сервиса, те обавештење неће бити послато одмах по извршењу акције.",

  // ZAKAZIVANJE PREGLEDA
  LABEL_DODAJ_TERMIN: "Додај",

  // OBRASCI STAMPE PROTOKOLA
  LABEL_OBRASCI_STAMPE_PROTOKOLA: "Обрасци штампе протокола",
  LABEL_OBRASCI_STAMPE_PROTOKOLA_OPIS: "Опис",
  LABEL_OBRASCI_STAMPE_PROTOKOLA_NAZIV_JRXML: "Назив ЈРXМЛ документа",
  LABEL_ADD_OBRASCI_STAMPE_PROTOKOLA: "Додај образац",
  LABEL_EDIT_OBRASCI_STAMPE_PROTOKOLA: "Измени образац",
  LABEL_DELETE_OBRASCI_STAMPE_PROTOKOLA: "Обриши образац",
  LABEL_OBRAZAC_STAMPE_CREATE: "Креирање обрасца штампе",
  LABEL_OBRAZAC_STAMPE_JRXML: "ЈРXМЛ документ",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_CREATE_SUCCESS_MESSAGE: "Успешно сте креирали образац штампе протокола",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_UPDATE_SUCCESS_MESSAGE: "Успешно сте изменили образац штампе протокола",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_DELETE_SUCCESS_MESSAGE: "Успешно сте обрисали образац штампе протокола",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_UPDATE: "Измена обрасца штампе протокола ",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_DELETE: "Брисање обрасца штампе протокола ",
  LABEL_OBRAZAC_STAMPE_PROTOKOLA_TITLE_CREATE: "Креирање обрасца штампе протокола ",

  // SPAJANJE PACIJENATA
  LABEL_SPAJANJE_PACIJENATA: "Спајање пацијената",
  LABEL_SPAJANJE_PACIJENATA_PREPISI: "Препиши",
  LABEL_SPAJANJE_PACIJENATA_IME: "Име",
  LABEL_SPAJANJE_PACIJENATA_PREZIME: "Презиме",
  LABEL_SPAJANJE_PACIJENATA_DATUM: "Датум рођења",
  LABEL_SPAJANJE_PACIJENATA_PRETRAZI: "Претражи",
  LABEL_SPAJANJE_PACIJENATA_SPOJI_PACIJENTE: "Споји пацијенте",
  LABEL_SPAJANJE_PACIJENATA_REZULTAT_PRETRAGE: "Резултат претраге ",
  LABEL_SPAJANJE_PACIJENATA_ID_PACIJENTA: "ID пацијента",
  LABEL_SPAJANJE_PACIJENATA_DA_LI_STE_SIGURNI: "Да ли сте сигурни да желите да спојите означене пацијенте?",
  LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_1: "После спајања изабраних пацијената ",
  LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_2: "активан ",
  LABEL_SPAJANJE_PACIJENATA_AKTIVAN_PACIJENT_3: "остаје леви пацијент",
  LABEL_SPAJANJE_PACIJENATA_ARHIVA: "Архива спојених пацијената",
  LABEL_SPAJANJE_PACIJENATA_VREME_SPAJANJA: "Време спајања",
  LABEL_SPAJANJE_PACIJENATA_TRENUTNI_PACIJENT: "Тренутни пацијент",
  LABEL_SPAJANJE_PACIJENATA_STARI_PACIJENT: "Стари пацијент",
  LABEL_SPAJANJE_PACIJENATA_DOSIJE: "Досије",
  LABEL_SPAJANJE_PACIJENATA_BUTTON_RAZDVOJ: "Раздвој",
  LABEL_RAZDVAJANJE_PACIJENATA_SUCCESS_MESSAGE: "Пацијенти успешно раздвојени",
  LABEL_RAZDVAJANJE_CONFIRMATION_MESSAGE: "Да ли сте сигурни да желите да развојите пацијенте?",

  // SIFARNIK SPECIJALIZACIJA
  LABEL_SIFARNIK_SPECIJALIZACIJA: "Шифарник специјализација",
  LABEL_SIFARNIK_SPECIJALIZACIJA_ADD: "Додај специјализацију",
  LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE: "Измени специјализацију",
  LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE: "Обриши специјализацију",
  LABEL_SIFARNIK_SPECIJALIZACIJA_CREATE: "Креирај специјализацију",
  LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE_TITLE: "Измена специјализације ",
  LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE_TITLE: "Брисање специјализације ",
  LABEL_SIFARNIK_SPECIJALIZACIJA_CREATE_SUCCESS_MESSAGE: "Успешно сте креирали специјализацију",
  LABEL_SIFARNIK_SPECIJALIZACIJA_UPDATE_SUCCESS_MESSAGE: "Успешно сте изменили специјализацију",
  LABEL_SIFARNIK_SPECIJALIZACIJA_DELETE_SUCCESS_MESSAGE: "Успешно сте обрисали специјализацију",
  LABEL_SPECIJALIZACIJE_LIST: "Специјализације",
  LABEL_RADNIK_SPECIJALIZACIJE_LIST: "Специјализације радника",
  LABEL_SPECIJALIZACIJE_SEARCH: "Претрага специјализација",
  LABEL_RADNIK_SPECIJALIZACIJE_SEARCH: "Претрага специјализација радника",
  LABEL_USLUGA_SPECIJALIZACIJE_LIST: "Специјализације услуге",
  LABEL_USLUGA_SPECIJALIZACIJE_SEARCH: "Претрага специјализација услуге",

  // PERCENTILNA KRIVA
  LABEL_PERCENTILNA_KRIVA_ODABERITE_UZRAST: "Одаберите узраст",
  LABEL_PERCENTILNA_KRIVA_Z_SKOROVI: "з-скорови",
  LABEL_PERCENTILNA_KRIVA_PERCENTILI: "перцентили",
  LABEL_PERCENTILNA_KRIVA_POL_NIJE_UNET: "Перцентилна крива се може приказати само за мушки или женски пол",
  LABEL_PERCENTILNA_KRIVA_DATUM_STAMPE: "Датум штампе",

  LABEL_NADJIMED_VEZE: "Везе",
  LABEL_NADJIMED: "НађиМеД",
  LABEL_PARTNERI_PLACEHOLDER: "Одаберите партнера",
  LABEL_DOSTUPNI_ZAKAZIVACI: "Доступни заказивачи",
  LABEL_EKSTERNI_ZAKAZIVACI_PARTNERI: "Партнери",
  LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE: "Статус везе",
  LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_POVEZAN: "ПОВЕЗАН",
  LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_ZATRAZENO: "ЗАТРАЖЕНО",
  LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_CEKA_NA_POTVRDU: "ЧЕКА НА ПОТВРДУ",
  LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_NEPOVEZAN: "НЕПОВЕЗАН",
  LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_CONNECT: "Повежи",
  LABEL_EKSTERNI_ZAKAZIVACI_BUTTON_DISCONNECT: "Развежи",
  LABEL_EKSTERNI_ZAKAZIVACI_DA_LI_STE_SIGURNI_RAZVEZIVANJE: "Да ли сте сигурни да желите да уклоните везу са заказивачем?",
  LABEL_EKSTERNI_ZAKAZIVACI_DA_LI_STE_SIGURNI_POVEZIVANJE: "Да ли сте сигурни да желите да се повежете са заказивачем?",
  LABEL_EKSTERNI_ZAKAZIVACI_NEPOVRATNA_AKCIJA: "Неповратна акција",
  LABEL_EKSTERNI_ZAKAZIVACI_USPESNO_POVEZIVANJE: "Успешно сте повезали заказиваче",
  LABEL_EKSTERNI_ZAKAZIVACI_USPESNO_RAZVEZIVANJE: "Успешно сте развезали заказиваче",
  LABEL_NAZIV_ZAKAZIVACA: "Заказивач",
  LABEL_NADJIMED_RADNI_PERIODI: "Радни периоди су обавезни за коришћење екстерног заказивања",

  // PRODAJNI PAKETI
  LABEL_PRODAJNI_PAKETI: "Продајни пакети",
  LABEL_PRODAJNI_PAKET_ADD: "Додај продајни пакет",
  LABEL_PRODAJNI_PAKET_SEARCH: "Претрага продајних пакета",
  LABEL_PRODAJNI_PAKETI_CREATE: "Креирање продајног пакета",
  LABEL_PRODAJNI_PAKETI_UPDATE: "Измена продајног пакета",
  LABEL_PRODAJNI_PAKETI_DELETE: "Брисање продајног пакета",
  LABEL_PRODAJNI_PAKETI_NAZIV: "Назив",
  LABEL_PRODAJNI_PAKETI_CENA: "Цена",
  LABEL_PRODAJNI_PAKETI_STATUS: "Статус",
  LABEL_PRODAJNI_PAKETI_BROJ_NALOGA: "Број налога",
  LABEL_PRODAJNI_PAKETI_STORAGE_LIMIT: "Ограничење складишта",
  LABEL_PRODAJNI_PAKETI_PERIOD_PLACANJA: "Период плаћања",
  LABEL_PRODAJNI_PAKETI_OPIS: "Опис",
  LABEL_PRODAJNI_PAKETI_PREPORUCEN: "Препоручен",
  LABEL_PRODAJNI_PAKETI_JAVAN: "Јаван",
  LABEL_PRODAJNI_PAKETI_MESSAGE_CREATE_SUCCESS: "Успешно сте креирали продајни пакет",
  LABEL_PRODAJNI_PAKETI_MESSAGE_UPDATE_SUCCESS: "Успешно сте изменили продајни пакет",
  LABEL_PRODAJNI_PAKETI_MESSAGE_DELETE_SUCCESS: "Успешно сте обрисали продајни пакет",

  // INICIJALIZACIJA USTANOVE
  LABEL_INICIJALIZACIJA_PODESAVANJE_USTANOVE: "Подешавање установе",
  LABEL_INICIJALIZACIJA_USTANOVA: "1. Установа",
  LABEL_INICIJALIZACIJA_ORG_JEDINICE: "2. Организационе јединице",
  LABEL_INICIJALIZACIJA_RADNICI: "3. Радници",
  LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA: "4. Типови прегледа",
  LABEL_INICIJALIZACIJA_USLUGE: "5. Услуге",
  LABEL_BUTTON_PRETHODNI: "Претходни",
  LABEL_BUTTON_SLEDECI: "Следећи",
  LABEL_BUTTON_ZAVRSI: "Заврши",
  LABEL_INICIJALIZACIJA_DIALOG_TITLE: "Иницијализација у току",
  LABEL_INICIJALIZACIJA_DIALOG_TEXT_1: "Коришћење овог налога биће омогућено након завршетка иницијализације установе.",
  LABEL_INICIJALIZACIJA_DIALOG_TEXT_2: "Молимо покушајте касније.",
  LABEL_INICIJALIZACIJA_USTANOVA_TOOLTIP: "Након завршетка свих корака подешавањима установе можете приступити кроз Главни мени/Администрација/Установа",
  LABEL_INICIJALIZACIJA_USTANOVA_SUCCESS_MESSAGE: "Успешно сте сачували податке о установи",
  LABEL_INICIJALIZACIJA_ORG_JEDINICA_TOOLTIP: "Након завршетка свих корака подешавањима установе можете приступити кроз Главни мени/Администрација/Организационе јединице",
  LABEL_KREIRANE_ORG_JEDINICE: "Креиране организационе јединице",
  LABEL_OBRISI_UNOS: "Обриши унос",
  LABEL_KREIRAJ: "Креирај",
  LABEL_INICIJALIZACIJA_ORG_JEDINICA_DELETE_CONFIRM_MESSAGE: "Да ли сте сигурни да желите да обришете организациону јединицу?",
  LABEL_INICIJALIZACIJA_ORG_JEDINICA_REQUIRED: "Потребно је да креирате бар једну организациону јединицу да бисте наставили даље",
  LABEL_SNIMI: "Сними",
  LABEL_INICIJALIZACIJA_RADNICI_TOOLTIP: "Након завршетка свих корака подешавањима установе можете приступити кроз Главни мени/Администрација/Радници",
  LABEL_INICIJALIZACIJA_RADNICI_DELETE_CONFIRM_MESSAGE: "Да ли сте сигурни да желите да обришете радника?",
  LABEL_KREIRANI_RADNICI: "Креирани радници",
  LABEL_INICIJALIZACIJA_USLUGA_TOOLTIP: "Након завршетка свих корака подешавањима установе можете приступити кроз Главни мени/Администрација/Шифарници/Услуге",
  LABEL_INICIJALIZACIJA_USLUGA_DELETE_CONFIRM_MESSAGE: "Да ли сте сигурни да желите да обришете услугу?",
  LABEL_KREIRANE_USLUGE: "Креиранe услугe",
  LABEL_OZNACI_SVE: "Означи све",
  LABEL_UBACI_U: "Убаци у: ",
  LABEL_TIP_PREGLEDA_SAVE_CONFIRMATION_WARNING: "Измена избора типа прегледа није сачувана.\nКликните на дугме Сними да бисте сачували измене.",
  LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_IN_PROGRESS_TEXT_1: "Чување података у току.",
  LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_IN_PROGRESS_TEXT_2: "Молимо сачекајте...",
  LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_FINISHED_TEXT_1: "Подаци успешно сачувани.",
  LABEL_INICIJALIZACIJA_USLUGE_OVERLAY_FINISHED_TEXT_2: "Наставите кликом на тамну површину.",
  LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_TOOLTIP: "Након завршетка свих корака подешавањима установе можете приступити кроз Главни мени/Администрација/Типови прегледа",
  LABEL_TIPOVI_PREGLEDA_INICIJALIZACIJA_UPDATE_SUCCESS_MESSAGE: "Успешно сте изменили типове прегледа на установи",
  LABEL_INICIJALIZACIJA_SVE_ORGANIZACIONE_JEDINICE: "Све организационе јединице",
  LABEL_INICIJALIZACIJA_TIPOVI_PREGLEDA_REQUIRED: "Потребно је изабрати бар један тип прегледа да бисте наставили даље",
  LABEL_INICIJALIZACIJA_USTANOVE_UNSAVED_CHANGES_WARNING: "Нисте снимили измене. Да ли сте сигурни да желите да наставите?",

  GLOBALNE_DATOTEKE: "Глобалне датотеке",
  GLOBALNA_DATOTEKA_UPDATE_SUCCESS: "Садржај глобалне датотеке је успешно измењен",
  GLOBALNA_DATOTEKA_CHOOSE: "Изаберите датотеку",
  GLOBALNA_DATOTEKA_DIALOG_HEADER: "Измена садржаја глобалне датотеке",

  LABEL_TIP_TERMINA: "Тип термина",
  VERZIJE: "Верзије",
  VERZIJA_STAVKE: "Ставке верзије",
  DATUM_VERZIJE: "Датум верзије",
  DODAJ_VERZIJU: "Додај верзију",
  NOVA_VERZIJA: "Нова верзија",
  VERZIJA_CREATE_SUCCESS: "Верзија је успешно креирана",
  VERZIJA_UPDATE_SUCCESS: "Верзија је успешно измењена",
  VERZIJA_DELETE_SUCCESS: "Верзија је успешно обрисана",
  VERZIJA_DELETE_CONFIRM_MESSAGE: "Да ли сте сигурни да желите да обришете верзију?",
  VERZIJA_STAVKA_CREATE_SUCCESS: "Ставка верзије успешно креирана",
  VERZIJA_STAVKA_UPDATE_SUCCESS: "Ставка верзије успешно измењена",
  VERZIJA_STAVKA_DELETE_SUCCESS: "Ставка верзије успешно обрисана",
  VERZIJA_STAVKA_DELETE_CONFIRM_MESSAGE: "Да ли сте сигурни да желите да обришете ставку верзије?",
  STATUS_VERZIJE: "Статус верзије",
  DODAJ_VERZIJA_STAVKU: "Додај ставку верзије",
  VERZIJA_STAVKA_OPIS_MANDATORY: "Опис је обавезно поље.",
  VERZIJA_NAZIV_MANDATORY: "Назив је обавезно поље.",
  ISTORIJA_VERZIJA: "Историја верзија",

  GRID: "Грид",
  LISTA: "Листа",
  TABELA: "Табела",
};

export default Labels;
