import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../../Store";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import { DATE_FORMAT, tableDateFilterGreater, tableDateFilterLess, tooltipOptionsBottom, tooltipOptionsTop } from "../../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../../infrastructure/system/hooks/PageDropdownTemplate";
import UstanovaDodatniModulReadDto from "../../../../../model/ustanova/UstanovaDodatniModulReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../../app/skeleton/SkeletonTable";
import CalendarFilter from "../../../calendar-component/CalendarFilter";
import UstanovaDodatniModulListLogical from "./UstanovaDodatniModulListLogical";

interface ustanovaDodatniModulListProps {
  ustanovaId?: number | undefined;
  ustanovaNaziv?: string | undefined;
}

export default function UstanovaDodatniModulListView(props: ustanovaDodatniModulListProps) {
  const { ustanovaId, ustanovaNaziv } = props;
  const {
    ustanovaDodatniModulList,
    first,
    tableRows,
    onPage,
    openDodatniModul,
    dodatniModulListLoading,
    ustanovaDodatniModulSearchData,
    fetchDataAdmin,
    changeUstanovaDodatniModulSearchData,
    searchUstanovaDodatniModulByEnter,
    dodatniModulList,
    hasButtons,
    dodatniModulExpired,
    dodatniModulInProgress,
  } = UstanovaDodatniModulListLogical({ ustanovaId: ustanovaId, ustanovaNaziv: ustanovaNaziv });
  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();

  const vaziOdBody = (rowUstanovaDodatniModul: UstanovaDodatniModulReadDto) => {
    return <div>{moment(rowUstanovaDodatniModul.vaziOd).format(DATE_FORMAT)}</div>;
  };
  const vaziDoBody = (rowUstanovaDodatniModul: UstanovaDodatniModulReadDto) => {
    return <div>{rowUstanovaDodatniModul.vaziDo ? moment(rowUstanovaDodatniModul.vaziDo).format(DATE_FORMAT) : ""}</div>;
  };

  const dateFilterTemplate = (options: any) => <CalendarFilter options={options} />;

  const searchToolbar = () => {
    return ustanovaId !== undefined ? (
      <>
        <Button
          label={Labels.BUTTON_ADD_USTANOVA_DODATNI_MODUL}
          tooltip={Labels.MODUL_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openDodatniModul(EntityOperation.CREATE);
          }}
        />
      </>
    ) : (
      <>
        <span className="p-float-label">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={ustanovaDodatniModulSearchData?.searchString ?? ""}
            onChange={changeUstanovaDodatniModulSearchData}
            onKeyPress={searchUstanovaDodatniModulByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_DODATNI_MODUL}</label>
        </span>
        <Button tooltip={Labels.DODATNI_MODUL_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchDataAdmin} />
      </>
    );
  };

  const crudBodyTemplate = (rowDodatniModul: UstanovaDodatniModulReadDto) => {
    return (
      <>
        {hasButtons(rowDodatniModul) === true && (
          <div className="flex justify-content-end">
            <Button
              type="button"
              tooltip={Labels.MODUL_IZMENA}
              tooltipOptions={tooltipOptionsTop}
              className="p-button-warning mr-2"
              icon="pi pi-pencil"
              disabled={dodatniModulExpired(rowDodatniModul)}
              onClick={() => rowDodatniModul && openDodatniModul(EntityOperation.UPDATE, rowDodatniModul.id)}
            />
            <Button
              type="button"
              tooltip={Labels.MODUL_BRISANJE}
              tooltipOptions={tooltipOptionsTop}
              className="p-button-danger mr-2"
              icon="pi pi-trash"
              disabled={dodatniModulInProgress(rowDodatniModul) || dodatniModulExpired(rowDodatniModul)}
              onClick={() => {
                rowDodatniModul && openDodatniModul(EntityOperation.DELETE, rowDodatniModul.id);
              }}
            />
          </div>
        )}
      </>
    );
  };

  const statusRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={dodatniModulList}
        optionLabel="naziv"
        optionValue="naziv"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        className="w-full"
        showClear
      />
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.MODUL, filter: true },
    { columnName: Labels.VAZI_OD, filter: true, sortrable: true },
    { columnName: Labels.VAZI_DO, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      {true && <Toolbar left={searchToolbar} />}
      {dodatniModulListLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={ustanovaId !== undefined} defaultButtonCount={2} />
      ) : (
        <DataTable
          filterDisplay="row"
          value={ustanovaDodatniModulList}
          paginator
          first={first}
          paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
          rows={tableRows}
          onPage={(e: DataTablePageEvent) => onPage(e.rows, e.first)}
          exportFilename={Labels.USTANOVA_LIST}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          rowHover
          stripedRows
        >
          <Column field={"dodatniModul.naziv"} header={Labels.MODUL} className="w-3" filter showClearButton={false} filterElement={statusRowFilterTemplate} showFilterMenu={false} />
          <Column
            header={Labels.VAZI_OD}
            field={"vaziOd"}
            body={vaziOdBody}
            filter
            filterElement={dateFilterTemplate}
            filterMatchMode="custom"
            filterFunction={tableDateFilterLess}
            sortable
            showFilterMenu={false}
          />
          <Column
            header={Labels.VAZI_DO}
            field={"vaziDo"}
            body={vaziDoBody}
            filter
            filterElement={dateFilterTemplate}
            filterMatchMode="custom"
            filterFunction={tableDateFilterGreater}
            sortable
            showFilterMenu={false}
          />
          {ustanovaId !== undefined && <Column body={crudBodyTemplate} />}
        </DataTable>
      )}
    </div>
  );
}
