import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { RolaService } from "../../../../controllers/rola/RolaService";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import { default as IzvestajParametarReadDto, default as IzvestajParametriReadDto } from "../../../../model/izvestaj/parametar/IzvestajParametarReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import IzvestajParametarListLogical from "./IzvestajParametarListLogical";

interface IzvestajParametarProps {
  izvestajNaziv: string | undefined;
  izvestajOperation?: string;
}

export default function IzvestajParametarListVeiw(props: IzvestajParametarProps) {
  const { isSuperadmin } = RolaService();
  const { isDisabled, navigate, parametarList, parametarIzvestajaLoading, first, tableRows, onPageParametriIzvestaja, izvestajId } = IzvestajParametarListLogical(isSuperadmin);
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const dt = useRef<any>(null);
  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();

  const openParametar = (entityOperation: string, param?: IzvestajParametarReadDto) => {
    const paramId = entityOperation !== EntityOperation.CREATE ? param?.id : "";
    navigate(`/crud-izvestaj/${izvestajId}/crud-parametri-izvestaja/${paramId}`, {
      state: {
        izvestajParametarOperation: entityOperation,
        parametarNaziv: param?.naziv,
        izvestajNaziv: props.izvestajNaziv,
        izvestajOperation: props.izvestajOperation,
      },
    });
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_PRIKAZNI_REDOSLED, filter: true, sortrable: true },
    { columnName: Labels.HEADER_NAZIV, filter: true, sortrable: true },
  ];

  const crudBodyTemplate = (rowParametar: IzvestajParametriReadDto) => {
    return (
      <div className="float-right">
        <Button
          type="button"
          tooltip={Labels.IZVESTAJ_PARAMETRI_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowParametar && openParametar(EntityOperation.UPDATE, rowParametar)}
        />
        {isSuperadmin && (
          <Button
            type="button"
            tooltip={Labels.IZVESTAJ_PARAMETRI_DELETE}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-danger mr-2"
            icon="pi pi-trash"
            onClick={() => rowParametar && openParametar(EntityOperation.DELETE, rowParametar)}
            disabled={!pristup}
          />
        )}
      </div>
    );
  };

  const leftContents = () => (
    <Button
      className="p-button-success mr-3"
      label={Labels.BUTTON_ADD_IZVESTAJ_PARAMETAR}
      tooltipOptions={tooltipOptionsBottom}
      onClick={() => {
        openParametar(EntityOperation.CREATE);
      }}
      disabled={!pristup}
    />
  );

  return (
    <div className="layout-generic-content-list">
      <Panel>
        {isSuperadmin && <Toolbar left={leftContents} />}
        <div>
          {parametarIzvestajaLoading ? (
            <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={!isDisabled} />
          ) : (
            <div className="col-12">
              <DataTable
                ref={dt}
                filterDisplay="row"
                value={parametarList ?? []}
                paginator
                first={first}
                paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
                rows={tableRows}
                onPage={(e) => {
                  onPageParametriIzvestaja(e.rows, e.first);
                }}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                exportFilename={Labels.IZVESTAJ_PARAMETRI_LIST}
                rowHover
                stripedRows
              >
                <Column field={"prikazniRedosled"} header={Labels.HEADER_PRIKAZNI_REDOSLED} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                <Column field={"naziv"} header={Labels.HEADER_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />

                {!isDisabled && <Column body={crudBodyTemplate} />}
              </DataTable>
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
}
