import moment from "moment/moment";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { PaketController } from "../../../../controllers/paket/PaketController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, skeletonTimeout } from "../../../../infrastructure/system/Utils";
import useCallbackWithoutWarnings from "../../../../infrastructure/system/hooks/useCallbackWithoutWarnings";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import OrganizacionaJedinicaReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaReadDto";
import PaketOrganizacionaJedinicaCreateDto from "../../../../model/paket/PaketOrganizacionaJedinicaCreateDto";
import PaketOrganizacionaJedinicaReadDto from "../../../../model/paket/PaketOrganizacionaJedinicaReadDto";
import PaketReadDto from "../../../../model/paket/PaketReadDto";

interface PaketOrganizacionaJedinicaLogicalType {
  paketOrgJedLoading: boolean;
  orgJedListLoading: boolean;
  paketOrgJedList: Array<PaketOrganizacionaJedinicaReadDto>;
  filteredOrgJedList: Array<OrganizacionaJedinicaReadDto>;
  selectedOrgJed: OrganizacionaJedinicaReadDto | undefined;
  setSelectedOrgJed: Dispatch<SetStateAction<OrganizacionaJedinicaReadDto | undefined>>;
  onCreate: () => void;
  onDelete: (paketOrgJed: PaketOrganizacionaJedinicaReadDto) => void;
}

interface UseParamsType {
  paketId?: string;
}

export default function PaketOrganizacionaJedinicaLogical(paket: PaketReadDto | undefined): PaketOrganizacionaJedinicaLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const paketId = Number(useParams<keyof UseParamsType>()["paketId"]);
  const Labels = useLabels();
  const [paketOrgJedList, setPaketOrgJedList] = useState<Array<PaketOrganizacionaJedinicaReadDto>>([]);
  const [orgJedListLoading, setOrgJedListLoading] = useState<boolean>(true);
  const [orgJedList, setOrgJedList] = useState<Array<OrganizacionaJedinicaReadDto>>([]);
  const [selectedOrgJed, setSelectedOrgJed] = useState<OrganizacionaJedinicaReadDto>();
  const [filteredOrgJedList, setFilteredOrgJedList] = useState<Array<OrganizacionaJedinicaReadDto>>([]);
  const [paketOrgJedLoading, setPaketOrgJedLoading] = useState<boolean>(true);
  const { axiosGetOrgJedListByPaketId, axiosAddOrganizacionaJedinicaToPaket, axiosRemoveOrganizacionaJedinicaFromPaket } = PaketController();
  const { axiosSearchOrganizacionaJedinica } = OrganizacionaJedinicaController();
  const postLogHighLevel = useLogHighLevel();

  //Popunjava dropdown sa organizacionim jedinicama koje nisu vec vezane za dati paket
  useEffect(() => {
    const presentList = paketOrgJedList.map((x) => x.organizacionaJedinica);
    const filteredList = orgJedList.filter((orgJed) => !presentList.some((presentJed) => presentJed.id === orgJed.id));
    setFilteredOrgJedList(filteredList);
  }, [orgJedList, paketOrgJedList, paketId]);

  const fetchData = useCallbackWithoutWarnings(() => {
    let startTime = moment(new Date());
    setPaketOrgJedLoading(true);
    setOrgJedListLoading(true);
    axiosGetOrgJedListByPaketId(paketId)
      .then((res) => {
        setPaketOrgJedList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        skeletonTimeout(setPaketOrgJedLoading, startTime);
      })
      .finally(() => {
        skeletonTimeout(setPaketOrgJedLoading, startTime);
      });

    axiosSearchOrganizacionaJedinica()
      .then((res) => {
        setOrgJedList(res.data.data);
        skeletonTimeout(setOrgJedListLoading, startTime);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  }, [paketId]);

  useEffect(() => {
    if (paketId) {
      fetchData();
    }
  }, [paketId, fetchData]);

  const onCreate = () => {
    const paketOrganizacionaJedinicaCreate: PaketOrganizacionaJedinicaCreateDto = {
      paket: { id: paketId },
      organizacionaJedinica: { id: selectedOrgJed?.id },
    };
    setShowBlockUI(true);
    axiosAddOrganizacionaJedinicaToPaket(paketId, paketOrganizacionaJedinicaCreate)
      .then(() => {
        fetchData();
        showMessage(MessageType.SUCCESS, Labels.PAKET_ADD_ORGANIZACIONA_JEDINICA_SUCCESS);
        setSelectedOrgJed(undefined);

        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_PAKET_ORGANIZACIONA_JEDINICA_1 + selectedOrgJed?.naziv + Labels.LOG_HIGH_LEVEL_MESS_CREATE_PAKET_ORGANIZACIONA_JEDINICA_2 + paket?.naziv + "."
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onDelete = (paketOrganizacionaJedinica: PaketOrganizacionaJedinicaReadDto) => {
    if (paketId && paketOrganizacionaJedinica.id) {
      setShowBlockUI(true);
      axiosRemoveOrganizacionaJedinicaFromPaket(paketId, paketOrganizacionaJedinica.id)
        .then(() => {
          fetchData();
          showMessage(MessageType.SUCCESS, Labels.PAKET_REMOVE_ORGANIZACIONA_JEDINICA_SUCCESS);

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_PAKET_ORGANIZACIONA_JEDINICA_1 +
              paketOrganizacionaJedinica.organizacionaJedinica?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_PAKET_ORGANIZACIONA_JEDINICA_2 +
              paket?.naziv +
              "."
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  return {
    paketOrgJedLoading,
    orgJedListLoading,
    paketOrgJedList,
    filteredOrgJedList,
    selectedOrgJed,
    setSelectedOrgJed,
    onCreate,
    onDelete,
  };
}
