import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ProtokolControler from "../../../../controllers/protokol/ProtokolController";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import KontaktProtokolBrojRequestReadDto from "../../../../model/kontakt/KontaktProtokolBrojRequestReadDto";
import KontaktProtokolBrojResponseReadDto from "../../../../model/kontakt/KontaktProtokolBrojResponseReadDto";

import { AppContext, useLabels } from "../../../../Store";

interface CrudProtokolBrojLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  protokolBrojChange: KontaktProtokolBrojResponseReadDto | undefined;
  protokolBrojSearch: KontaktProtokolBrojRequestReadDto | undefined;
  setProtokolBrojChange: React.Dispatch<React.SetStateAction<KontaktProtokolBrojResponseReadDto | undefined>>;
  onDelete: () => void;
  onCancel: () => void;
  protokolBrojOperation: string;
}

export default function CrudProtokolBrojLogical(): CrudProtokolBrojLogicalType {
  const navigate = useNavigate();
  const location = useLocation();
  const { showMessage, setShowBlockUI } = useContext(AppContext);

  const [protokolBrojChange, setProtokolBrojChange] = useState<KontaktProtokolBrojResponseReadDto | undefined>(location.state.protokolBrojChange);
  const [protokolBrojSearch] = useState<KontaktProtokolBrojRequestReadDto | undefined>(location.state.protokolBrojSearch);
  const [protokolBrojOperation] = useState<string>(location.state.protokolOperation);
  const { axiosDeleteProtokolBroj } = ProtokolControler();

  const Labels = useLabels();

  const postLogHighLevel = useLogHighLevel();

  const [breadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.PROTOKOL_LIST,
      command: () => {
        navigate(`/protokol-list`, {
          state: { tabIndex: 1, protokolBrojSearch: protokolBrojSearch },
        });
      },
    },
    {
      label: Labels.PROTOKOL_BROJ_BRISANJE,
    },
  ]);

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeleteProtokolBroj(protokolBrojChange!.protokolBroj.id)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.PROTOKOL_BROJ_TITLE_MESSAGE_DELETE_PROTOKOL_BROJ_SUCCESS);
        onCancel();

        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_PROTOKOL_BROJ_1 +
            protokolBrojChange?.protokolBroj.protokol.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_4 +
            protokolBrojChange?.organizacionaJedinica.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_1 +
            protokolBrojChange?.pacijent.ime +
            " " +
            protokolBrojChange?.pacijent.prezime +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_2 +
            protokolBrojChange?.vremeOd +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_BROJ_3 +
            protokolBrojChange?.protokolBroj.broj,
          protokolBrojChange!.pacijent.id
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/protokol-list`);
    }
  };

  return {
    breadCrumbItems,
    protokolBrojChange,
    protokolBrojSearch,
    setProtokolBrojChange,
    onDelete,
    onCancel,
    protokolBrojOperation,
  };
}
