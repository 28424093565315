import { PickListChangeEvent } from "primereact/picklist";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { TipKontaktaController } from "../../../../controllers/kontakt/TipKontaktaController";

import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";
import TipKontaktaReadDto from "../../../../model/kontakt/TipKontaktaReadDto";

import OrganizacionaJedinicaKontaktTipReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaKontaktTipReadDto";
import { AppContext, useLabels } from "../../../../Store";

export interface OrganizacionaJedinicaKontaktTipHolder {
  kontaktTip: TipKontaktaReadDto;
}
interface OrganizacionaJedinicaKontaktTipLogicalType {
  organizacionaJedinicaKontaktTipList: Array<OrganizacionaJedinicaKontaktTipReadDto | undefined>;
  kontaktTipList: Array<OrganizacionaJedinicaKontaktTipHolder>;
  onChangeOrganizacionaJedinicaKontaktTip: (event: PickListChangeEvent) => Promise<void>;
  isBlocked: boolean;
  isLoading: boolean;
}
interface UseParamsType {
  orgJedId?: string;
}

export default function OrganizacionaJedinicaKontaktTipLogical(orgJedOperation: string, orgJedNaziv?: string): OrganizacionaJedinicaKontaktTipLogicalType {
  const { showMessage } = useContext(AppContext);
  const [organizacionaJedinicaKontaktTipList, setOrganizacionaJedinicaKontaktTipList] = useState<Array<OrganizacionaJedinicaKontaktTipReadDto>>([]);
  const [kontaktTipList, setKontaktTipList] = useState<Array<OrganizacionaJedinicaKontaktTipHolder>>([]);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const orgJedId: number = Number(useParams<keyof UseParamsType>()["orgJedId"]);
  const prevOrgJedOperation = useRef<string | undefined>(undefined);
  const prevOorgJedId = useRef<number | undefined>(undefined);
  const { axiosGetOrganizacionaJedinicaKontaktTipList, axiosCreateOrganizacionaJedinicaKontaktTip, axiosDeleteOrganizacionaJedinicaKontaktTip } = OrganizacionaJedinicaController();
  const { axiosSearchTipKontakta } = TipKontaktaController();

  const Labels = useLabels();

  const postLogHighLevel = useLogHighLevel();

  const getKontaktTipList = useCallback(
    (organizacionaJedinicaKontaktTipList: Array<OrganizacionaJedinicaKontaktTipReadDto>) => {
      setIsLoading(true);
      axiosSearchTipKontakta({})
        .then((res: any) => {
          let kontaktTipListTemp: Array<OrganizacionaJedinicaKontaktTipHolder> = [];
          const allKontaktTip = res.data.data;
          allKontaktTip.forEach((kontaktTip: TipKontaktaReadDto) => {
            if (
              organizacionaJedinicaKontaktTipList.filter((orgJedinicaKontaktTip: OrganizacionaJedinicaKontaktTipReadDto) => {
                return orgJedinicaKontaktTip.kontaktTip.id === kontaktTip.id;
              }).length === 0
            ) {
              kontaktTipListTemp.push({ kontaktTip: kontaktTip });
            }
          });
          setKontaktTipList(kontaktTipListTemp);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosSearchTipKontakta, setIsLoading, showMessage]
  );

  const getOrganizacionaJedinicaKontaktTip = useCallback(() => {
    if (orgJedId !== null && orgJedId !== undefined) {
      setIsLoading(true);
      axiosGetOrganizacionaJedinicaKontaktTipList(orgJedId)
        .then((res: any) => {
          setOrganizacionaJedinicaKontaktTipList(res.data.data);
          getKontaktTipList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setIsLoading(false);
        });
    }
  }, [axiosGetOrganizacionaJedinicaKontaktTipList, getKontaktTipList, orgJedId, setIsLoading, showMessage]);

  const fetchData = useCallback(() => {
    getOrganizacionaJedinicaKontaktTip();
  }, [getOrganizacionaJedinicaKontaktTip]);

  useEffect(() => {
    if (orgJedId && prevOorgJedId.current !== orgJedId && prevOrgJedOperation.current !== orgJedOperation && orgJedOperation !== EntityOperation.CREATE) {
      prevOrgJedOperation.current = orgJedOperation;
      prevOorgJedId.current = orgJedId;
      fetchData();
    }
  }, [fetchData, orgJedOperation, orgJedId]);

  const onChangeOrganizacionaJedinicaKontaktTip = async (event: PickListChangeEvent) => {
    if (isBlocked || isLoading) {
      return;
    }
    setIsBlocked(true);
    if (organizacionaJedinicaKontaktTipList.length < event.target.length) {
      await Promise.all(
        event.target.map(async (kontaktTipOrganizacionaJedinica: OrganizacionaJedinicaKontaktTipHolder) => {
          if (
            !organizacionaJedinicaKontaktTipList
              .map((element: OrganizacionaJedinicaKontaktTipReadDto | undefined) => {
                return element?.kontaktTip;
              })
              .includes(kontaktTipOrganizacionaJedinica.kontaktTip)
          ) {
            const kontaktTipId = kontaktTipOrganizacionaJedinica.kontaktTip?.id;
            await axiosCreateOrganizacionaJedinicaKontaktTip(orgJedId, { organizacionaJedinica: { id: orgJedId }, kontaktTip: { id: kontaktTipId } })
              .then((res: any) => {
                postLogHighLevel(
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA +
                    orgJedNaziv +
                    Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_TIP_1 +
                    kontaktTipOrganizacionaJedinica.kontaktTip.naziv +
                    "."
                );
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
              });
          }
        })
      );
      setOrganizacionaJedinicaKontaktTipList(event.target);
      setKontaktTipList(kontaktTipList.filter((element: OrganizacionaJedinicaKontaktTipHolder) => !event.target.includes(element)));
    } else if (kontaktTipList.length < event.source.length) {
      await Promise.all(
        event.source.map(async (kontaktTipOrganizacionaJedinica: OrganizacionaJedinicaKontaktTipHolder) => {
          if (!kontaktTipList.includes(kontaktTipOrganizacionaJedinica)) {
            const kontaktTipId = kontaktTipOrganizacionaJedinica.kontaktTip?.id;
            await axiosDeleteOrganizacionaJedinicaKontaktTip(orgJedId, kontaktTipId)
              .then(() => {
                postLogHighLevel(
                  Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA +
                    orgJedNaziv +
                    Labels.LOG_HIGH_LEVEL_MESS_UPDATE_ORGANIZACIONA_JEDINICA_TIP_2 +
                    kontaktTipOrganizacionaJedinica.kontaktTip.naziv +
                    "."
                );
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
              });
          }
        })
      );
      setKontaktTipList(kontaktTipList.concat(organizacionaJedinicaKontaktTipList.filter((element: OrganizacionaJedinicaKontaktTipHolder) => !event.target.includes(element))));
      setOrganizacionaJedinicaKontaktTipList(event.target);
    }
    setIsBlocked(false);
  };

  return {
    organizacionaJedinicaKontaktTipList,
    onChangeOrganizacionaJedinicaKontaktTip,
    kontaktTipList,
    isBlocked,
    isLoading,
  };
}
