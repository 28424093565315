import { Accordion, AccordionTab } from "primereact/accordion";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { useLabels } from "../../../../Store";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import SkeletonAccordionList from "../../../app/skeleton/SkeletonAccordionList";
import ListaDocumenataLogical from "./ListaDocumenataLogical";
import Dokument from "./dokument/Dokument";

export default function ListaDokumenata() {
  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();
  const {
    selectedFormularPopunjen,
    setSelectedFormularPopunjen,
    shiftFormularPopunjenBack,
    shiftFormularPopunjenForward,
    formularPopunjenWithDokumentList,
    renderImePrezime,
    renderOrd,
    renderFormName,
    renderDateTime,
    handlePrint,
    deleteDokument,
    dokumentListLoading,
    onPageFormTable,
    first,
    tableRows,
  } = ListaDocumenataLogical();

  const headerTemplate = (naslov: string) => <div className="col-offset-1 col-9 flex align-items-center justify-content-center p-0 h-2rem">{`${naslov}`}</div>;

  return (
    <div className="layout-lista_dokumenata">
      <Panel className="mt-2">
        {!dokumentListLoading ? (
          <Accordion collapseIcon="pi pi-caret-down" expandIcon="pi pi-caret-right">
            <AccordionTab key={0} headerTemplate={() => headerTemplate(Labels.LISTA_DOKUMENATA_NASLOV)}>
              <DataTable
                selectionMode="single"
                selection={selectedFormularPopunjen}
                onSelectionChange={(e) => setSelectedFormularPopunjen(e.value)}
                className="mt-2"
                filterDisplay="row"
                value={formularPopunjenWithDokumentList}
                paginator
                first={first}
                onPage={(e) => {
                  onPageFormTable(e.rows, e.first);
                }}
                rows={tableRows}
                paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                rowHover
                stripedRows
              >
                <Column body={renderDateTime} header={Labels.LISTA_DOKUMENATA_DATUM} />
                <Column body={renderOrd} header={Labels.LISTA_DOKUMENATA_ORG_JED} />
                <Column body={renderImePrezime} header={Labels.LABEL_RADNIK} />
                <Column body={renderFormName} header={Labels.LABEL_NAZIV} />
              </DataTable>
              <Dokument
                selectedFormularPopunjen={selectedFormularPopunjen}
                shiftFormularPopunjenBack={shiftFormularPopunjenBack}
                shiftFormularPopunjenForward={shiftFormularPopunjenForward}
                handlePrint={handlePrint}
                deleteDokument={deleteDokument}
              />
            </AccordionTab>
          </Accordion>
        ) : (
          <SkeletonAccordionList size={1} />
        )}
      </Panel>
    </div>
  );
}
