import axios from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import FaktorRizikaController from "../../../controllers/faktor-rizika/FaktorRizikaController";

import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import FaktorRizikaReadDto from "../../../model/faktor-rizika/FaktorRizikaReadDto";
import FaktorRizikaUstanovaReadDto from "../../../model/faktor-rizika/FaktorRizikaUstanovaReadDto";

import SearchBaseDto from "../../../model/SearchBaseDto";
import { AppContext, useLabels } from "../../../Store";

interface FaktorRizikaAdminLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  faktorRizikaSearchData: SearchBaseDto;
  fetchData: () => void;
  faktorRizikaList: Array<FaktorRizikaReadDto>;
  faktorRizikaListLoading: boolean;
  tableRows: number;
  updateFaktorRizikaUstanova: (faktorRizika: FaktorRizikaReadDto, deleteFromDialog: Boolean) => void;
  faktorRizikaUstanovaLoading: boolean;
  faktorRizikaUstanovaToDelete: FaktorRizikaReadDto | undefined;
  closeDialog: () => void;
}

export default function FaktorRizikaAdminLogical(): FaktorRizikaAdminLogicalType {
  const Labels = useLabels();
  const { showMessage } = useContext(AppContext);
  const [faktorRizikaSearchData] = useState<SearchBaseDto>({});
  const [faktorRizikaUstanovaLoading, setFaktorRizikaUstanovaLoading] = useState<boolean>(false);
  const [faktorRizikaList, setFaktorRizikaList] = useState<Array<FaktorRizikaReadDto>>([]);
  const { axiosGetAllFaktorRizika, axiosGetAllFaktorRizikaUstanova, axiosDeleteFaktorRizikaUstanova, axiosCreateFaktorRizikaUstanova } = FaktorRizikaController();
  const [faktorRizikaListLoading, setFaktorRizikaListLoading] = useState<boolean>(false);
  const [tableRows] = useState(10);
  const [faktorRizikaUstanovaToDelete, setFaktorRizikaUstanovaToDelete] = useState<FaktorRizikaReadDto>();
  const postLogHighLevel = useLogHighLevel();

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.FAKTOR_RIZIKA_LIST,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const updateFaktorRizikaUstanova = (faktorRizika: FaktorRizikaReadDto, deleteFromDialog: Boolean) => {
    setFaktorRizikaUstanovaLoading(true);
    if (faktorRizika.faktorRizikaUstanovaId !== undefined) {
      (deleteFromDialog ? axiosDeleteFaktorRizikaUstanova(faktorRizika.faktorRizikaUstanovaId) : axiosDeleteFaktorRizikaUstanova(faktorRizika.faktorRizikaUstanovaId, true))
        .then((res: any) => {
          if (res.status === 200) {
            setFaktorRizikaUstanovaToDelete(undefined);
            let faktorRizikaIndex = faktorRizikaList.findIndex((faktorRizikaTemp: FaktorRizikaReadDto) => faktorRizikaTemp.id === faktorRizika.id);
            faktorRizikaList[faktorRizikaIndex].faktorRizikaUstanovaId = undefined;
            setFaktorRizikaList([...faktorRizikaList]);
            postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_FAKTOR_RIZIKA_1 + faktorRizika.naziv + Labels.LOG_HIGH_LEVEL_MESS_INFO_FAKTOR_RIZIKA_1 + faktorRizika.sifra + ".");
          } else if (res.status === 202) {
            setFaktorRizikaUstanovaToDelete(faktorRizika);
          }
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setFaktorRizikaUstanovaLoading(false);
        });
    } else {
      axiosCreateFaktorRizikaUstanova({ idRepositoryFaktorRizika: faktorRizika.id })
        .then((res: any) => {
          let faktorRizikaUstanova: FaktorRizikaUstanovaReadDto = res.data.data;
          let faktorRizikaIndex = faktorRizikaList.findIndex((faktorRizikaTemp: FaktorRizikaReadDto) => faktorRizikaTemp.id === faktorRizika.id);
          faktorRizikaList[faktorRizikaIndex].faktorRizikaUstanovaId = faktorRizikaUstanova.id;
          setFaktorRizikaList([...faktorRizikaList]);
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_FAKTOR_RIZIKA_1 + faktorRizika.naziv + Labels.LOG_HIGH_LEVEL_MESS_INFO_FAKTOR_RIZIKA_1 + faktorRizika.sifra + ". ");
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setFaktorRizikaUstanovaLoading(false);
        });
    }
  };

  const fetchData = () => {
    let startTime = moment(new Date());
    setFaktorRizikaListLoading(true);

    const getFaktorRizika = axiosGetAllFaktorRizika(faktorRizikaSearchData);
    const getFaktorRizikaUstanova = axiosGetAllFaktorRizikaUstanova();
    axios
      .all([getFaktorRizika, getFaktorRizikaUstanova])
      .then(
        axios.spread((getFaktorRizikaResponse: any, getFaktorRizikaUstanovaResponse: any) => {
          let faktorRizikaList: Array<FaktorRizikaReadDto> = getFaktorRizikaResponse.data.data;
          let faktorRizikaUstanovaList: Array<FaktorRizikaUstanovaReadDto> = getFaktorRizikaUstanovaResponse.data.data;
          faktorRizikaUstanovaList.forEach((faktorRizikaUstanova: FaktorRizikaUstanovaReadDto) => {
            let faktorRizikaIndex = faktorRizikaList.findIndex((faktorRizika: FaktorRizikaReadDto) => faktorRizika.id === faktorRizikaUstanova.idRepositoryFaktorRizika);
            if (faktorRizikaIndex !== -1) {
              faktorRizikaList[faktorRizikaIndex].faktorRizikaUstanovaId = faktorRizikaUstanova.id;
            }
          });
          setFaktorRizikaList(getFaktorRizikaResponse.data.data);
          getFaktorRizikaResponse.data.data.forEach((element: any) => {
            element.ikonica.ikonica = "data:image/svg+xml;base64," + element.ikonica.ikonica;
          });
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setFaktorRizikaListLoading, startTime);
      });
  };

  const closeDialog = () => {
    setFaktorRizikaUstanovaToDelete(undefined);
  };

  return {
    breadCrumbItems,
    faktorRizikaSearchData,
    fetchData,
    faktorRizikaList,
    faktorRizikaListLoading,
    tableRows,
    updateFaktorRizikaUstanova,
    faktorRizikaUstanovaLoading,
    faktorRizikaUstanovaToDelete,
    closeDialog,
  };
}
