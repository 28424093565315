import axios from "axios";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { PoljeTipController } from "../../../controllers/polje-tip/PoljeTipController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";

import PoljeTipEnumStavkaReadDto from "../../../model/poljeTip/PoljeTipEnumStavkaReadDto";
import PoljeTipReadDto from "../../../model/poljeTip/PoljeTipReadDto";
import { AppContext, useLabels } from "../../../Store";

interface CrudPoljeTipEnumStavkaLogicalType {
  poljeTipEnumStavkaChange: PoljeTipEnumStavkaReadDto | undefined;
  setPoljeTipEnumStavkaChange: any;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  isDisabled: boolean;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  poljeTipEnumStavkaOperation: string;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
}

interface UseParamsType {
  poljeTipEnumStavkaId?: string;
  poljeTipId?: string;
}

export default function CrudPoljeTipEnumStavkaLogical(): CrudPoljeTipEnumStavkaLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const [exportData] = useState<Array<any>>([]);
  const poljeTipId = Number(useParams<keyof UseParamsType>()["poljeTipId"]);
  const poljeTipEnumStavkaId = Number(useParams<keyof UseParamsType>()["poljeTipEnumStavkaId"]);
  const location = useLocation();
  const [index, setIndex] = useState<number>(0);
  const navigate = useNavigate();
  const [isDisabled] = useState(isFormDisabled(location.state ? location.state.poljeTipEnumStavkaOperation : EntityOperation.UPDATE));
  const [poljeTipOperation] = useState(location.state ? location.state.poljeTipOperation : "");
  const [poljeTipEnumStavkaChange, setPoljeTipEnumStavkaChange] = useState<PoljeTipEnumStavkaReadDto | undefined>();
  var poljeNaziv = "";
  const [currentPoljeTip, setCurrentPoljeTip] = useState<PoljeTipReadDto | undefined>();
  const [poljeTipEnumStavkaOperation] = useState<string>(location.state ? location.state.poljeTipEnumStavkaOperation : EntityOperation.UPDATE);

  const postLogHighLevel = useLogHighLevel();

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.POLJE_TIP_LIST,
      command: () => {
        navigate("/polje-tip-list");
      },
    },
  ]);

  const { axiosReadPoljeTipEnumStavka, axiosCreatePoljeTipEnumStavka, axiosDeletePoljeTipEnumStavka, axiosUpdatePoljeTipEnumStavka, axiosGetPoljeTip } = PoljeTipController();
  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = async () => {
    setShowBlockUI(true);
    if (poljeTipEnumStavkaId) {
      await axiosReadPoljeTipEnumStavka(poljeTipId, poljeTipEnumStavkaId)
        .then((res: any) => {
          poljeNaziv = res.data.data.naziv;
          setPoljeTipEnumStavkaChange(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    }
    const requestFieldType = axiosGetPoljeTip(poljeTipId);
    axios
      .all([requestFieldType])
      .then(
        axios.spread((responseFieldType: any) => {
          setCurrentPoljeTip(responseFieldType.data.data);
          if (breadCrumbItems.length < 2) {
            setBreadCrumbItems([
              ...breadCrumbItems,
              {
                label:
                  poljeTipOperation === EntityOperation.UPDATE
                    ? Labels.LABEL_UPDATE_FIELD_TYPE + responseFieldType.data.data.naziv
                    : poljeTipOperation === EntityOperation.READ
                    ? Labels.LABEL_DETAILS_FIELD_TYPE + responseFieldType.data.data.naziv
                    : Labels.LABEL_DELETE_FIELD_TYPE + responseFieldType.data.data.naziv,
                command: () => {
                  navigate(`/polje-tip/${poljeTipId}`, {
                    state: { tabIndex: 1, poljeTipOperation: poljeTipOperation },
                  });
                },
              },
              {
                label:
                  poljeTipEnumStavkaOperation === EntityOperation.UPDATE
                    ? Labels.LABEL_UPDATE_FIELD_TYPE_ENUM_STAVKA + poljeNaziv
                    : poljeTipEnumStavkaOperation === EntityOperation.READ
                    ? Labels.LABEL_DETAILS_FIELD_TYPE_ENUM_STAVKA + poljeNaziv
                    : poljeTipEnumStavkaOperation === EntityOperation.CREATE
                    ? Labels.LABEL_CREATE_FIELD_TYPE_ENUM_STAVKA
                    : Labels.LABEL_DELETE_FIELD_TYPE_ENUM_STAVKA + poljeNaziv,
              },
            ]);
          }
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const validatePoljeTipEnumStavka = (poljeTipEnumStavka?: PoljeTipEnumStavkaReadDto) => {
    if (validateStringEmpty(poljeTipEnumStavka?.sifra)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_SIFRA_REQUIRED);
      return false;
    }
    if (validateStringEmpty(poljeTipEnumStavka?.naziv)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_NAZIV_REQUIRED);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validatePoljeTipEnumStavka(poljeTipEnumStavkaChange)) {
      return;
    }
    if (poljeTipEnumStavkaChange && currentPoljeTip) {
      poljeTipEnumStavkaChange.poljeTip = currentPoljeTip;
    }
    if (poljeTipEnumStavkaChange) {
      setShowBlockUI(true);
      axiosCreatePoljeTipEnumStavka(poljeTipId, poljeTipEnumStavkaChange)
        .then((response: any) => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_POLJE_TIP_ENUM_1 + poljeTipEnumStavkaChange?.naziv + Labels.LOG_HIGH_LEVEL_MESS_INFO_POLJE_TIP_ENUM_1 + poljeTipEnumStavkaChange?.sifra + "."
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    if (poljeTipEnumStavkaChange) {
      setShowBlockUI(true);
      axiosDeletePoljeTipEnumStavka(poljeTipId, poljeTipEnumStavkaChange?.id)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_POLJE_TIP_ENUM_1 + poljeTipEnumStavkaChange?.naziv + Labels.LOG_HIGH_LEVEL_MESS_INFO_POLJE_TIP_ENUM_1 + poljeTipEnumStavkaChange?.sifra + "."
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validatePoljeTipEnumStavka(poljeTipEnumStavkaChange)) {
      return;
    }
    if (poljeTipEnumStavkaChange && currentPoljeTip) {
      poljeTipEnumStavkaChange.poljeTip = currentPoljeTip;
    }
    if (poljeTipEnumStavkaChange) {
      setShowBlockUI(true);
      axiosUpdatePoljeTipEnumStavka(poljeTipId, poljeTipEnumStavkaChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_TIP_ENUM_1 + poljeTipEnumStavkaChange?.naziv + Labels.LOG_HIGH_LEVEL_MESS_INFO_POLJE_TIP_ENUM_1 + poljeTipEnumStavkaChange?.sifra + "."
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/polje-tip/${poljeTipId}`, { state: { tabIndex: 1, poljeTipOperation: poljeTipOperation } });
    }
  };

  return {
    poljeTipEnumStavkaChange,
    setPoljeTipEnumStavkaChange,
    setIndex,
    index,
    isDisabled,
    exportData,
    breadCrumbItems,
    poljeTipEnumStavkaOperation,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
  };
}
