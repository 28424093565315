import { PickList } from "primereact/picklist";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import RadnikSpecijalizacijaReadDto from "../../../../model/radnik/RadnikSpecijalizacijaReadDto";
import SifarnikSpecijalizacijaReadDto from "../../../../model/sifarnik/sifarnik-specijalizacija/SifarnikSpecijalizacijaReadDto";
import SkeletonPickListItem from "../../../app/skeleton/SkeletonPickListItem";
import CrudRadnikSpecijalizacijeLogical from "./CrudRadnikSpecijalizacijeLogical";

interface CrudRadnikSpecijalizacijePropsType {
  isDisabled: boolean;
  radnikOperation: string;
  radnik: string;
  ustanovaId?: number;
}

const CrudRadnikSpecijalizacijeView = (props: CrudRadnikSpecijalizacijePropsType) => {
  const { isDisabled, radnik, ustanovaId } = props;
  const { specijalizacijeList, radnikSpecijalizacijeList, isLoading, onMoveToSource, onMoveToTarget, sourceSelection, setSourceSelection, targetSelection, setTargetSelection } =
    CrudRadnikSpecijalizacijeLogical(radnik, ustanovaId);
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const sourceItemTemplate = (item: SifarnikSpecijalizacijaReadDto | undefined) => {
    if (item === undefined || item === null || isLoading) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{item?.naziv}</h6>;
  };

  const targetItemTemplate = (item: RadnikSpecijalizacijaReadDto | undefined) => {
    if (item === undefined || item === null || isLoading) {
      return <SkeletonPickListItem />;
    }
    return <h6 className="mb-2">{item?.sifarnikSpecijalizacija?.naziv}</h6>;
  };

  return (
    <div className="layout-radnik specijalizacija-picklist">
      <div className={!pristup || isDisabled || isLoading ? "read-only" : ""}>
        <PickList
          source={specijalizacijeList}
          target={radnikSpecijalizacijeList}
          sourceItemTemplate={sourceItemTemplate}
          targetItemTemplate={targetItemTemplate}
          sourceHeader={Labels.LABEL_SPECIJALIZACIJE_LIST}
          targetHeader={Labels.LABEL_RADNIK_SPECIJALIZACIJE_LIST}
          filterBy="naziv,sifarnikSpecijalizacija.naziv"
          sourceFilterPlaceholder={Labels.LABEL_SPECIJALIZACIJE_SEARCH}
          targetFilterPlaceholder={Labels.LABEL_RADNIK_SPECIJALIZACIJE_SEARCH}
          showSourceControls={false}
          showTargetControls={false}
          sourceSelection={sourceSelection}
          targetSelection={targetSelection}
          onMoveToSource={() => onMoveToSource(targetSelection)}
          onMoveToTarget={() => onMoveToTarget(sourceSelection)}
          onMoveAllToSource={(e) => onMoveToSource(e.value)}
          onMoveAllToTarget={(e) => onMoveToTarget(e.value)}
          onSourceSelectionChange={(e) => setSourceSelection(e.value)}
          onTargetSelectionChange={(e) => setTargetSelection(e.value)}
          metaKeySelection={false}
        />
      </div>
    </div>
  );
};

export default CrudRadnikSpecijalizacijeView;
