import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { SuperadminController } from "../../../controllers/superadmin/SuperadminController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import SifarnikUslugaRfzoReadDto from "../../../model/sifarnik/sifarnik-usluga-rfzo/SifarnikUslugaRfzoReadDto";

interface SifarnikUslugaRfzoLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  fetchData: () => void;
  searchSifarnikUslugaRfzoByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  sifarnikUslugaRfzoSearchData: SearchBaseDto;
  changeSifarnikUslugaRfzoSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  sifarnikUslugaRfzoLoading: boolean;
  exportData: Array<any>;
  sifarnikUslugaRfzoList: Array<SifarnikUslugaRfzoReadDto>;
  first: number;
  tableRows: number;
  onPageSifarnikUslugaRfzo: (rows: number, first: number) => void;
}

export default function SifarnikUslugaRfzoLogical(): SifarnikUslugaRfzoLogicalType {
  const Labels = useLabels();
  const { showMessage } = useContext(AppContext);
  const [sifarnikUslugaRfzoSearchData, setSifarnikUslugaRfzoSearchData] = useState<SearchBaseDto>({});
  const [sifarnikUslugaRfzoLoading, setSifarnikUslugaRfzoLoading] = useState(false);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [sifarnikUslugaRfzoList, setSifarnikUslugaRfzoList] = useState<Array<SifarnikUslugaRfzoReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const { axiosSuperadminSearchSifarnikUslugaRFZO } = SuperadminController();
  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.SIFARNIK_USLUGA_RFZO,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const startTime = moment(new Date());
    setSifarnikUslugaRfzoLoading(true);
    axiosSuperadminSearchSifarnikUslugaRFZO(sifarnikUslugaRfzoSearchData)
      .then(({ data: { data } }: { data: { data: Array<SifarnikUslugaRfzoReadDto> } }) => {
        setSifarnikUslugaRfzoList(data);
        const exportList = new Array<any>();
        data.forEach((sifarnikUslugaRfzo: SifarnikUslugaRfzoReadDto) => {
          exportList.push({
            [Labels.SIFARNIK_USLUGA_RFZO_SIFRA]: sifarnikUslugaRfzo.sifra,
            [Labels.SIFARNIK_USLUGA_RFZO_NAZIV]: sifarnikUslugaRfzo.naziv,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSifarnikUslugaRfzoLoading, startTime);
      });
  };

  const searchSifarnikUslugaRfzoByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const changeSifarnikUslugaRfzoSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSifarnikUslugaRfzoSearchData({
      ...sifarnikUslugaRfzoSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const onPageSifarnikUslugaRfzo = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    breadCrumbItems,
    fetchData,
    searchSifarnikUslugaRfzoByEnter,
    sifarnikUslugaRfzoSearchData,
    changeSifarnikUslugaRfzoSearchData,
    onPageSifarnikUslugaRfzo,
    sifarnikUslugaRfzoLoading,
    exportData,
    tableRows,
    sifarnikUslugaRfzoList,
    first,
  };
}
