import { useQuery } from "@tanstack/react-query";
import { VerzijaController } from "../../../../controllers/verzija/VerzijaController";
import QueryKeys from "../../../../infrastructure/system/QueryKeys";

export function useVerzijaList() {
  const { axiosGetVerzijaList: axiosGetVerzije } = VerzijaController();

  return useQuery({
    queryKey: [QueryKeys.VERZIJA_LIST],
    queryFn: () => axiosGetVerzije().then((res) => res.data),
  });
}
