import moment from "moment";
import "moment/locale/sr";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { OverlayPanel } from "primereact/overlaypanel";
import { Panel } from "primereact/panel";
import { SplitButton } from "primereact/splitbutton";
import { useRef, useState } from "react";
import { useLabels } from "../../../Store";
import EnumZakazanTerminStatus from "../../../infrastructure/system/EnumZakazanTerminStatus";
import { DAY_DATE_FORMAT, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import EnumTerminPrikaz from "../../../model/enumFront/EnumTerminPrikaz";
import OrganizacionaJedinicaCvorDto from "../../../model/organizacionaJedinica/OrganizacionaJedinicaCvorDto";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import RadnikSkracenoReadDto from "../../../model/radnik/RadnikSkracenoReadDto";
import EnumRadniDanDto from "../../../model/sifarnik/EnumRadniDanDto";
import IconCalendarGridView from "../../app/icons/IconCalendarGridView";
import IconCalendarListView from "../../app/icons/IconCalendarListView";
import IconCalendarTableView from "../../app/icons/IconCalendarTableView";
import SkeletonPanelItem from "../../app/skeleton/SkeletonPanelItem";
import TerminiLogical from "./TerminiLogical";
import CalendarWeekView from "./calendar-week-view/CalendarWeekView";
import DialogTermin from "./dialog-termin/DialogTermin";
import RadniDaniGrid from "./radni-dan-grid/RadniDaniGrid";
import ZakazanTerminStatusi from "./zakazan-termin-statusi/ZakazanTerminStatusi";
import ZakazaniTerminiListView from "./zakazani-termini-table/ZakazaniTerminiListView";

interface TerminiType {
  odabranaOrgJed: Array<OrganizacionaJedinicaCvorDto>;
  radnikList: Array<RadnikSkracenoReadDto>;
  selectedRadnik: RadnikSkracenoReadDto;
  filterDatesForGrid: (datesArray: Array<moment.Moment>) => Array<moment.Moment>;
  selectedPacijent?: PacijentReadDto;
  prikaziNeaktivne: boolean;
}

export default function Termini(props: TerminiType) {
  const { odabranaOrgJed, radnikList, selectedRadnik, filterDatesForGrid, selectedPacijent, prikaziNeaktivne } = props;
  const {
    selectedDate,
    setSelectedDate,
    dates,
    legendOP,
    dateBack,
    dateForward,
    changeNumOfDays,
    displayType,
    setDisplayType,
    vanredni,
    setVanredni,
    verticalDisplay,
    setVerticalDisplay,
    terminiList,
    trajanjeTermina,
    dohvatiTermine,
    terminiDataLoading,
    daysForRenderOnGrid,
    radniPeriodList,
    radnoVremeOdOrgJed,
    radnoVremeDoOrgJed,
    zakazanTerminVremeDoOptions,
    setZakazanTerminVremeDoOptions,
    closeTerminChangeDialog,
    changeTerminTime,
    handleDragStart,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    showTerminChangeDialog,
    dragging,
    makeSatnicaList,
    dialogFromGrid,
    setDialogFromGrid,
    zakazanTerminVremeOdOptions,
    openDialogTermin,
    closeDialogTermin,
    dialogTerminVisible,
    setDialogTerminVisible,
    odabranZakazanTermin,
    setOdabranZakazanTermin,
    tabelarniPrikaz,
    setTabelarniPrikaz,
    ustanovaGrid,
    zakazanTerminStatusList,
    kontaktTipList,
    setDatesWithFirstDate,
  } = TerminiLogical({ odabranaOrgJed, selectedRadnik, prikaziNeaktivne });
  const Labels = useLabels();

  const displayTypes = [
    { label: Labels.TERMINI_NEDELJNI_PRIKAZ, value: EnumTerminPrikaz.NEDELJNI, numOfDays: 7 },
    { label: Labels.TERMINI_DNEVNI_PRIKAZ, value: EnumTerminPrikaz.DNEVNI, numOfDays: 1 },
  ];

  const changeTypeOfDisplay = (e: DropdownChangeEvent) => {
    const newDisplayType = displayTypes.find(({ value }) => value === e.value);
    setDisplayType(e.value);
    newDisplayType && changeNumOfDays(newDisplayType?.numOfDays, newDisplayType?.value);
  };

  const dodajZakazanTerminButtonRef = useRef<any>(null);
  const [dodajZakazanTerminButtonOverlayVisible, setDodajZakazanTerminButtonOverlayVisible] = useState<boolean>(false);

  const headerTemplate = () => {
    return (
      <div className={`p-panel-header py-${tabelarniPrikaz ? "3" : "2"}`}>
        <span className={`p-panel-title py-${tabelarniPrikaz ? "1" : "0"}`} id="pr_id_1_header">
          <div className="flex">
            <b>{Labels.ZAKAZIVANJE_TERMINI}</b>
            <Divider layout="vertical" className="p-0" />
            <div>
              <b>
                {selectedRadnik?.ime} {selectedRadnik?.prezime}
              </b>
              <span>{`, ${selectedDate?.format(DAY_DATE_FORMAT)}`}</span>
            </div>
          </div>
        </span>

        {!tabelarniPrikaz && (
          <span className="grid m-0 mr-1">
            <Dropdown value={displayType} options={displayTypes} onChange={changeTypeOfDisplay} className="mx-2" emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND} disabled={tabelarniPrikaz} />
          </span>
        )}
      </div>
    );
  };

  const items = [
    {
      label: Labels.ZAKAZIVANJE_PREGLEDA_REDOVNI_TERMIN,
      command: () => {
        setOdabranZakazanTermin({
          radnik: selectedRadnik,
          organizacionaJedinica: odabranaOrgJed[odabranaOrgJed.length - 1].organizacionaJedinica,
          zakazanTerminStatus: zakazanTerminStatusList.find((status) => status.sifra === EnumZakazanTerminStatus.ZAKAZAN),
          kontaktTip: kontaktTipList[0],
        });
        setVanredni(false);
        setDialogFromGrid(false);
        setDialogTerminVisible(true);
      },
    },
    {
      label: Labels.ZAKAZIVANJE_PREGLEDA_VANREDNI_TERMIN,
      command: () => {
        setOdabranZakazanTermin({
          radnik: selectedRadnik,
          organizacionaJedinica: odabranaOrgJed[odabranaOrgJed.length - 1].organizacionaJedinica,
          vremeZakazanoOd: undefined,
          vremeZakazanoDo: undefined,
          zakazanTerminStatus: zakazanTerminStatusList.find((status) => status.sifra === EnumZakazanTerminStatus.ZAKAZAN),
          kontaktTip: kontaktTipList[0],
        });
        setVanredni(true);
        setDialogTerminVisible(true);
      },
    },
  ];

  if (!ustanovaGrid) return <></>;

  return (
    <Panel headerTemplate={headerTemplate} className="mt-3">
      <div className="flex justify-content-between">
        <span>
          <div className=" flex justify-content-center">
            <SplitButton
              ref={dodajZakazanTerminButtonRef}
              label={Labels.LABEL_DODAJ_TERMIN}
              icon="pi pi-plus"
              className="p-button-raised"
              tooltip={Labels.ZAKAZI_TERMIN}
              menuButtonClassName="hidden"
              model={items}
              rounded
              onShow={() => setDodajZakazanTerminButtonOverlayVisible(true)}
              onHide={() => setDodajZakazanTerminButtonOverlayVisible(false)}
              onClick={() => {
                if (dodajZakazanTerminButtonRef.current) {
                  if (!dodajZakazanTerminButtonOverlayVisible) {
                    dodajZakazanTerminButtonRef.current.show();
                  } else {
                    dodajZakazanTerminButtonRef.current.hide();
                  }
                }
              }}
            />
          </div>
        </span>

        <span className="flex align-items-center">
          <Button
            className={`mr-2 change-view-btn p-button-${!verticalDisplay ? "secondary" : "tertiary"} p-button-outlined font-bold border-noround`}
            onClick={() => {
              const dnevniDisplayType = displayTypes.find(({ value }) => value === displayType);
              dnevniDisplayType && changeNumOfDays(dnevniDisplayType?.numOfDays, dnevniDisplayType?.value);
              setVerticalDisplay(true);
              setTabelarniPrikaz(false);
            }}
            tooltip={Labels.GRID}
            tooltipOptions={tooltipOptionsBottom}
          >
            <IconCalendarGridView color={`p-button-${!verticalDisplay ? "secondary" : "tertiary"}`}/>
          </Button>
          <Button
            className={`mr-2 change-view-btn p-button-${!verticalDisplay && !tabelarniPrikaz ? "tertiary" : "secondary"} p-button-outlined font-bold border-noround`}
            onClick={() => {
              const dnevniDisplayType = displayTypes.find(({ value }) => value === displayType);
              dnevniDisplayType && changeNumOfDays(dnevniDisplayType?.numOfDays, dnevniDisplayType?.value);
              setVerticalDisplay(false);
              setTabelarniPrikaz(false);
            }}
            tooltip={Labels.LISTA}
            tooltipOptions={tooltipOptionsBottom}
          >
            <IconCalendarListView color={`p-button-${!verticalDisplay ? "tertiary" : "secondary"}`}/>
          </Button>
          <Button
            className={`p-button-outlined change-view-btn p-button-${tabelarniPrikaz ? "tertiary" : "secondary"} border-noround`}
            onClick={() => {
              setTabelarniPrikaz(true);
              setVerticalDisplay(false);
              changeNumOfDays(displayTypes[1].numOfDays, displayTypes[1].value, true);
            }}
            tooltip={Labels.TABELA}
            tooltipOptions={tooltipOptionsBottom}
          >
              <IconCalendarTableView color={`p-button-${!verticalDisplay ? "tertiary" : "secondary"}`}/>
          </Button>
          <Button
            label={Labels.LEGENDA}
            onMouseEnter={(e) => legendOP.current?.toggle(e)}
            onMouseLeave={(e) => legendOP.current?.toggle(e)}
            aria-haspopup
            aria-controls="overlay_panel_legend"
            className="p-button-text p-button-tertiary font-bold px-0 ml-3"
          />
        </span>
      </div>
      <OverlayPanel ref={legendOP} id="overlay_panel_legend" className="overlaypanel-dark">
        {zakazanTerminStatusList?.map((termin) => <ZakazanTerminStatusi key={termin.id} termin={termin} />)}
      </OverlayPanel>
      <div className={`hc-scheduler hc-scheduler--${verticalDisplay || tabelarniPrikaz ? "v" : "h pt-3"}`}>
        <CalendarWeekView
          selectedDate={selectedDate}
          parseDatesForGrid={filterDatesForGrid}
          setSelectedDate={setSelectedDate}
          dates={dates}
          leftButton={dateBack}
          rigthButton={dateForward}
          dateSelectEnabled={tabelarniPrikaz}
          tabelarniPrikaz={tabelarniPrikaz}
          setDatesWithFirstDate={setDatesWithFirstDate}
        />
        {!radnoVremeOdOrgJed || !radnoVremeDoOrgJed ? (
          <SkeletonPanelItem />
        ) : tabelarniPrikaz ? (
          <ZakazaniTerminiListView
            zakazaniTerminList={terminiList}
            odabranaOrgJed={odabranaOrgJed && odabranaOrgJed?.length > 0 ? odabranaOrgJed[odabranaOrgJed.length - 1].organizacionaJedinica : undefined}
            zakazanTerminStatusList={zakazanTerminStatusList}
            openDialogTermin={openDialogTermin}
            setVanredni={setVanredni}
          />
        ) : (
          <RadniDaniGrid
            vertical={verticalDisplay}
            trajanjeTermina={trajanjeTermina}
            zakazaniTerminList={terminiList}
            radniDani={
              displayType === 1 ? dates?.filter((datum: moment.Moment) => daysForRenderOnGrid?.some((dan: { radniDan: EnumRadniDanDto }) => datum.weekday() + 1 === dan?.radniDan?.danUNedelji)) : dates
            }
            otvoriDialogTermin={openDialogTermin}
            dataLoading={terminiDataLoading}
            radniPeriodList={radniPeriodList}
            radnoVremeOdOrgJed={radnoVremeOdOrgJed}
            radnoVremeDoOrgJed={radnoVremeDoOrgJed}
            closeTerminChangeDialog={closeTerminChangeDialog}
            changeTerminTime={changeTerminTime}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDragLeave={handleDragLeave}
            handleDrop={handleDrop}
            showTerminChangeDialog={showTerminChangeDialog}
            dragging={dragging}
            makeSatnicaList={makeSatnicaList}
            selectedPacijent={selectedPacijent}
          />
        )}
      </div>
      {dialogTerminVisible && (
        <DialogTermin
          setZakazanTermin={setOdabranZakazanTermin}
          zakazanTermin={odabranZakazanTermin}
          closeDialogTermin={closeDialogTermin}
          dohvatiTermine={dohvatiTermine}
          trajanjeTermina={trajanjeTermina}
          radnoVremeOdOrgJed={radnoVremeOdOrgJed}
          radnoVremeDoOrgJed={radnoVremeDoOrgJed}
          vanredni={vanredni}
          setVanredni={setVanredni}
          radnikList={radnikList}
          zakazanTerminStatusList={zakazanTerminStatusList}
          zakazanTerminVremeDoOptions={zakazanTerminVremeDoOptions}
          setZakazanTerminVremeDoOptions={setZakazanTerminVremeDoOptions}
          odabranaOrgJed={odabranaOrgJed && odabranaOrgJed?.length > 0 ? odabranaOrgJed[odabranaOrgJed.length - 1].organizacionaJedinica : undefined}
          selectedPacijent={selectedPacijent}
          ustanovaGrid={ustanovaGrid}
          dialogFromGrid={dialogFromGrid}
          zakazanTerminVremeOdOptions={zakazanTerminVremeOdOptions}
          prikaziNeaktivne={prikaziNeaktivne}
          isVirtuelniRadniPeriodi={ustanovaGrid?.virtuelniRadniPeriodi}
          makeSatnicaList={makeSatnicaList}
          zakazanTerminList={terminiList}
          kontaktTipList={kontaktTipList}
        />
      )}
    </Panel>
  );
}
