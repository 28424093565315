import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import { useContext } from "react";
import { useLabels } from "../../../../Store";
import EnumUplatilacNaplata from "../../../../infrastructure/system/EnumUplatilacNaplata";
import Images from "../../../../infrastructure/system/Images";
import { DATE_FORMAT, formatPrice } from "../../../../infrastructure/system/Utils";
import useEffectWithoutWarnings from "../../../../infrastructure/system/hooks/useEffectWithoutWarnings";
import { useSrednjiKursForValuta } from "../../../../infrastructure/system/hooks/useSrednjiKursForValuta";
import KontaktProizvodOsnovnoReadDto from "../../../../model/kontakt/KontaktProizvodOsnovnoReadDto";
import KontaktNaplataReadDto from "../../../../model/naplata/KontaktNaplataReadDto";
import StavkaNaplate from "../../../../model/naplata/StavkaNaplate";
import SifarnikKategorijaPopustaReadDto from "../../../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaReadDto";
import { InvalidInputsType, NaplataContext } from "../../NaplataLogical";
import NaplataUtils from "../../NaplataUtils";
import { useProizvodCena } from "../../hooks/useProizvodCena";
import StavkaNaplateProizvod from "./StavkaNaplateProizvod";

interface UplatilacDetailsPropsType {
  kontaktProizvod: KontaktProizvodOsnovnoReadDto;
  kategorijePopustaList: Array<SifarnikKategorijaPopustaReadDto>;
  kontakt: KontaktNaplataReadDto;
}

const UplatilacCardProizvod = (props: UplatilacDetailsPropsType) => {
  const { state, dispatch, invalidInputs, setInvalidInputs, uplatilacList, finalnaValuta } = useContext(NaplataContext);
  const { kontaktProizvod, kategorijePopustaList, kontakt } = props;
  const Labels = useLabels();

  const { calculateUkupnaProizvodCena } = NaplataUtils();

  const { proizvodCena: cena, isLoading: cenaIsLoading } = useProizvodCena(kontaktProizvod.sifarnikProizvoda.id, kontakt.vremeOd as string);

  const kolicina = kontaktProizvod.utrosenaKolicina;

  const stavkaNaplatePair = state.proizvodi?.[kontaktProizvod.id];
  const prvaStavka = stavkaNaplatePair?.[0];

  const emptyDataObject: StavkaNaplate = {
    uplatilac: prvaStavka?.uplatilac?.sifra === EnumUplatilacNaplata.PACIJENT ? uplatilacList[1] : uplatilacList[0],
    placeniProcenat: 100 - prvaStavka?.placeniProcenat,
    iznosBezPacijentPopusta:
      100 - prvaStavka?.placeniProcenat === 0
        ? 0
        : calculateUkupnaProizvodCena(state?.proizvodi?.[kontaktProizvod.id], cena) * kolicina - prvaStavka?.iznos >= 0
        ? calculateUkupnaProizvodCena(state?.proizvodi?.[kontaktProizvod.id], cena) * kolicina - prvaStavka?.iznos
        : 0,
    iznos:
      100 - state?.proizvodi?.[kontaktProizvod.id]?.[0]?.placeniProcenat === 0
        ? 0
        : calculateUkupnaProizvodCena(state?.proizvodi?.[kontaktProizvod.id], cena) * kolicina - prvaStavka?.iznos >= 0
        ? calculateUkupnaProizvodCena(state?.proizvodi?.[kontaktProizvod.id], cena) * kolicina - prvaStavka?.iznos
        : 0,
    napomena: "",
    kontakt: kontakt,
    kontaktProizvod: kontaktProizvod,
    kolicina: kolicina,
  };

  useEffectWithoutWarnings(() => {
    const initialValues = {
      uplatilac: uplatilacList[0],
      placeniProcenat: 100,
      napomena: "",
      kontaktProizvod: kontaktProizvod,
      kolicina: kolicina,
      iznosBezPacijentPopusta: 0,
      iznos: 0,
      kontakt: kontakt,
    };
    dispatch({
      type: "set_initial_item",
      data: {
        id: kontaktProizvod.id,
        item: initialValues,
        itemsType: "proizvodi",
      },
    });
  }, []);

  const { kurs } = useSrednjiKursForValuta(cena?.valuta?.sifra !== "RSD" ? cena?.valuta?.id : undefined);

  const renderHeader = (naslov: string, sifra: string) => {
    return (
      <div className="flex flex-row justify-content-between text-xl font-bold w-full align-items-center">
        <div className="flex align-items-center">
          <Image src={Images.ICON_UTROSCI} alt="utrosci ikonica" className="pr-2" /> {sifra} {naslov}
        </div>
        <div className="flex flex-column align-items-end gap-2">
          {cenaIsLoading ? (
            <Skeleton width="12rem" height="2rem" />
          ) : (
            <div>
              {kolicina} * {formatPrice(calculateUkupnaProizvodCena(stavkaNaplatePair, cena) / (kurs ?? 1))} {cena?.valuta.sifra}
            </div>
          )}
          {kurs && (
            <div className="text-primary flex gap-2 align-items-center">
              <>
                <i className="pi pi-info-circle icon-info text-primary" data-pr-tooltip={`${Labels.LABEL_INFO_OBRACUNATA_CENA}${moment(new Date()).format(DATE_FORMAT)}`} data-pr-position="bottom" />
                <Tooltip target=".icon-info" />
              </>
              <div>
                {kolicina} * {formatPrice(calculateUkupnaProizvodCena(stavkaNaplatePair, cena))} {finalnaValuta}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Accordion
      key={kontaktProizvod.id}
      activeIndex={0}
      className={`uplatioci-accordion ${
        (invalidInputs.proizvodi.invalidPercentages.includes(kontaktProizvod.id) ||
          invalidInputs.proizvodi.invalidSumPrices.includes(kontaktProizvod.id) ||
          invalidInputs.proizvodi.zeroPercentages.includes(kontaktProizvod.id)) &&
        "invalid-field"
      }`}
    >
      <AccordionTab headerTemplate={() => renderHeader(kontaktProizvod.sifarnikProizvoda.naziv, kontaktProizvod.sifarnikProizvoda.sifra)}>
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-info"
          onClick={() => {
            dispatch({ type: "add_stavka_naplate", data: { value: emptyDataObject, id: kontaktProizvod.id, itemsType: "proizvodi" } });
            setInvalidInputs((prev: InvalidInputsType) => ({
              ...prev,
              proizvodi: { ...prev.proizvodi, invalidPercentages: prev.proizvodi.invalidPercentages.filter((item) => item !== kontaktProizvod.id) },
              invalidPayments: [],
            }));
          }}
          disabled={stavkaNaplatePair?.length >= 2 || stavkaNaplatePair?.[0]?.placeniProcenat === 100}
        />
        <div>
          <div>
            {state.proizvodi?.[kontaktProizvod.id]?.map((stavkaNaplate: StavkaNaplate, index: number) => (
              <StavkaNaplateProizvod
                key={index}
                index={index}
                cena={cena}
                stavkaNaplate={stavkaNaplate}
                kontaktProizvod={kontaktProizvod}
                kategorijePopustaList={kategorijePopustaList}
                cenaIsLoading={cenaIsLoading}
              />
            ))}
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  );
};

export default UplatilacCardProizvod;
