import { AxiosResponse } from "axios";
import moment from "moment";
import { FileUploadHandlerEvent } from "primereact/fileupload";
import { useContext, useReducer, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { EnumController } from "../../../../controllers/enum/EnumController";
import ProtokolControler from "../../../../controllers/protokol/ProtokolController";
import { ProtokolObrazacController } from "../../../../controllers/superadmin/repository/obraci-stampe-protokola/ProtokolObrazacController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../infrastructure/system/MessageType";
import { MAX_FILE_UPLOAD, checkEmpty, handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../../infrastructure/system/Utils";
import actionsAddObrazac from "../../../../infrastructure/system/hooks/dialog-add-obrazac-delovodne-knjige/actionsAddObrazac";
import initialStateAddObrazac, { InitialStateAddObrazacType } from "../../../../infrastructure/system/hooks/dialog-add-obrazac-delovodne-knjige/initialStateAddObrazac";
import reducerAddObrazac from "../../../../infrastructure/system/hooks/dialog-add-obrazac-delovodne-knjige/reducerAddObrazac";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import ProtokolObrazacReadDto from "../../../../model/obrasi-stampe-protokola/ProtokolObrazacReadDto";
import ProtokolReadDto from "../../../../model/protokol/ProtokolReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";

interface CrudProtokolLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  protokolLoading: boolean;
  protokolChange: ProtokolReadDto | undefined;
  setProtokolChange: React.Dispatch<React.SetStateAction<ProtokolReadDto | undefined>>;
  isDisabled: boolean;
  vremenskiOkvirListLoading: boolean;
  vremenskiOkvirList: Array<EnumBaseReadDto>;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  protokolOperation: string;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  state: InitialStateAddObrazacType;
  dispatch: React.Dispatch<{ type: actionsAddObrazac }>;
  fetchRepositoryObrasci: () => void;
  jasperUploadHandler: (event: FileUploadHandlerEvent) => void;
  getSelectObrazacFromRepo: () => void;
}

interface UseParamsType {
  protokolId?: string;
}

export default function CrudProtokolLogical(): CrudProtokolLogicalType {
  const Labels = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const { showMessage, setShowBlockUI } = useContext(AppContext);

  const protokolId = Number(useParams<keyof UseParamsType>()["protokolId"]);
  const [protokolOperation, setProtokolOperation] = useState<string>(
    location.state && location.state.protokolOperation !== undefined ? location.state.protokolOperation : !isNaN(protokolId) ? EntityOperation.DELETE : EntityOperation.CREATE
  );
  const [isDisabled] = useState<boolean>(isFormDisabled(protokolOperation));

  const [protokolLoading, setProtokolLoading] = useState(false);
  const [protokolChange, setProtokolChange] = useState<ProtokolReadDto>();
  const [vremenskiOkvirListLoading, setVremenskiOkvirListLoading] = useState(false);
  const [vremenskiOkvirList, setVremenskiOkvirList] = useState<Array<EnumBaseReadDto>>([]);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const [state, dispatch] = useReducer(reducerAddObrazac, initialStateAddObrazac);

  const { axiosGetProtokol, axiosCreateProtokol, axiosDeleteProtokol, axiosUpdateProtokol } = ProtokolControler();
  const { axiosGetEnumVremenskiOkvirList } = EnumController();
  const { axiosGetObrasci, axiosGetProtokolObrazac } = ProtokolObrazacController();

  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchData();
    fetchFormData();
  });

  const [breadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.PROTOKOL_LIST,
      command: () => {
        navigate(`/protokol-list/`, {
          state: { tabIndex: 0 },
        });
      },
    },
    {
      label:
        protokolOperation === EntityOperation.DELETE
          ? Labels.PROTOKOL_TITLE_DIALOG_DELETE
          : protokolOperation === EntityOperation.UPDATE
          ? Labels.PROTOKOL_TITLE_DIALOG_UPDATE
          : Labels.PROTOKOL_TITLE_DIALOG_CREATE,
    },
  ]);

  const fetchData = (pID?: number) => {
    const protokolID = pID || protokolId;
    const startTime = moment(new Date());
    setProtokolLoading(true);
    if (protokolID) {
      axiosGetProtokol(protokolID)
        .then((res) => {
          setProtokolChange(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setProtokolLoading, startTime);
        });
    } else {
      setProtokolLoading(false);
    }
  };

  const fetchFormData = () => {
    const startTime = moment(new Date());
    setVremenskiOkvirListLoading(true);
    axiosGetEnumVremenskiOkvirList()
      .then((res: any) => {
        setVremenskiOkvirList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setVremenskiOkvirListLoading, startTime);
      });
  };

  const validateInput = () => {
    let isInvalid = false;
    if (validateStringEmpty(protokolChange?.prefiks)) {
      setInvalidFields((prev) => ({ ...prev, prefiks: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(protokolChange?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (!protokolChange?.vremenskiOkvirEnum) {
      setInvalidFields((prev) => ({ ...prev, vremenskiOkvir: true }));
      isInvalid = true;
    }
    if (protokolChange?.pocetniBroj === null || protokolChange?.pocetniBroj === undefined || protokolChange?.pocetniBroj < 1) {
      setInvalidFields((prev) => ({ ...prev, pocetniBroj: true }));
      isInvalid = true;
    }
    if (protokolChange?.naredniBroj === null || protokolChange?.naredniBroj === undefined || protokolChange?.naredniBroj < 1) {
      setInvalidFields((prev) => ({ ...prev, naredniBroj: true }));
      isInvalid = true;
    }
    if (checkEmpty(protokolChange?.jasperFajl)) {
      setInvalidFields((prev) => ({ ...prev, jasperFajl: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput()) {
      return;
    }
    if (protokolChange) {
      setShowBlockUI(true);
      axiosCreateProtokol(protokolChange!)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.PROTOKOL_TITLE_MESSAGE_CREATE_PROTOKOL_SUCCESS);
          setProtokolOperation(EntityOperation.UPDATE);
          navigate("/protokol-list");
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_PROTOKOL_1 +
              res.data.data.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_1 +
              res.data.data.prefiks +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_2 +
              protokolChange?.vremenskiOkvirEnum.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_3 +
              protokolChange?.pocetniBroj +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_4 +
              protokolChange?.naredniBroj
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDelete = () => {
    setShowBlockUI(true);
    axiosDeleteProtokol(protokolId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.PROTOKOL_TITLE_MESSAGE_DELETE_PROTOKOL_SUCCESS);
        onCancel();
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_PROTOKOL_1 +
            protokolChange?.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_1 +
            protokolChange?.prefiks +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_2 +
            protokolChange?.vremenskiOkvirEnum.naziv +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_3 +
            protokolChange?.pocetniBroj +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_4 +
            protokolChange?.naredniBroj
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const onUpdate = () => {
    if (!validateInput()) {
      return;
    }
    if (protokolChange) {
      setShowBlockUI(true);
      axiosUpdateProtokol(protokolChange!)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.PROTOKOL_TITLE_MESSAGE_UPDATE_PROTOKOL_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PROTOKOL_1 +
              res.data.data.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_1 +
              res.data.data.prefiks +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_2 +
              protokolChange?.vremenskiOkvirEnum.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_3 +
              protokolChange?.pocetniBroj +
              Labels.LOG_HIGH_LEVEL_MESS_INFO_PROTOKOL_4 +
              protokolChange?.naredniBroj
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/protokol-list`);
    }
  };

  const fetchRepositoryObrasci = () => {
    dispatch({ type: actionsAddObrazac.OBRASCI_LIST_LOADING, value: true });
    axiosGetObrasci()
      .then((response: AxiosResponse) => {
        dispatch({ type: actionsAddObrazac.OBRASCI_LIST, value: response.data.data });
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        dispatch({ type: actionsAddObrazac.OBRASCI_LIST_LOADING, value: false });
      });
  };

  const jasperUploadHandler = (e: FileUploadHandlerEvent) => {
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }
        if (reader.result) {
          setProtokolChange({
            ...protokolChange!,
            jasperFajl: reader.result.toString().substr(reader.result?.toString().indexOf(",") + 1),
            jasperFajlNaziv: file.name,
          });
        }
      };
    });
  };

  const getSelectObrazacFromRepo = () => {
    if (state?.selectedObrazac?.id) {
      axiosGetProtokolObrazac(state?.selectedObrazac?.id)
        .then(({ data: { data } }: { data: { data: ProtokolObrazacReadDto } }) => {
          setProtokolChange({
            ...protokolChange!,
            jasperFajl: data.jasperFajl,
            jasperFajlNaziv: data.jasperFajlNaziv,
            jasperFajlOpis: data.opis,
          });
          dispatch({ type: actionsAddObrazac.CLOSE_DIALOG });
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  return {
    breadCrumbItems,
    protokolLoading,
    protokolChange,
    setProtokolChange,
    isDisabled,
    vremenskiOkvirListLoading,
    vremenskiOkvirList,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    protokolOperation,
    invalidFields,
    setInvalidFields,
    state,
    dispatch,
    fetchRepositoryObrasci,
    jasperUploadHandler,
    getSelectObrazacFromRepo,
  };
}
