import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import ProizvodCenaCreateDto from "../../model/sifarnik-proizvoda/ProizvodCenaCreateDto";
import ProizvodCenaUpdateDto from "../../model/sifarnik-proizvoda/ProizvodCenaUpdateDto";
import SifarnikProizvodaReadDto from "../../model/sifarnik/sifarnik-proizvoda/SifarnikProizvodaReadDto";

interface SifarnikProizvodaControllerType {
  axiosReadSifarnikProizvoda: (id: number) => Promise<AxiosResponse<any>>;
  axiosSearchSifarnikProizvoda: (searchObject: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosUpdateSifarnikProizvoda: (sifarnikProizvoda: SifarnikProizvodaReadDto) => Promise<AxiosResponse<any>>;
  axiosCreateSifarnikProizvoda: (sifarnikProizvoda: SifarnikProizvodaReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteSifarnikProizvoda: (sifarnikProizvodaId: number) => Promise<AxiosResponse<any>>;
  axiosGetSifarnikProizvodaList: () => Promise<AxiosResponse<any>>;
  axiosReadSifarnikProizvodaRFZOList: () => Promise<AxiosResponse<any>>;
  axiosGetCeneForProizvod: (sifarnikProizvodaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateCenaForProizvod: (cenaProizvoda: ProizvodCenaCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteCenaForProzivod: (sifarnikProizvodaId: number, cenaId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateCenaForProizvod: (cenaProizvoda: ProizvodCenaUpdateDto) => Promise<AxiosResponse<any>>;
}

export function SifarnikProizvodaController(): SifarnikProizvodaControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetSifarnikProizvodaList = () => {
    return axios.get(Endpoint.SIFARNIK_PROIZVODA_LIST, axiosConfig(authData!.token));
  };

  const axiosReadSifarnikProizvoda = (id: number) => {
    return axios.get(`${Endpoint.SIFARNIK_PROIZVODA_LIST}/${id}`, axiosConfig(authData!.token));
  };

  const axiosSearchSifarnikProizvoda = (searchObject: SearchBaseDto) => {
    return axios.get(Endpoint.SIFARNIK_PROIZVODA_SEARCH, axiosConfig(authData!.token, searchObject));
  };

  const axiosCreateSifarnikProizvoda = (sifarnikProizvoda: SifarnikProizvodaReadDto) => {
    return axios.post(Endpoint.SIFARNIK_PROIZVODA_LIST, sifarnikProizvoda ? sifarnikProizvoda : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateSifarnikProizvoda = (sifarnikProizvoda: SifarnikProizvodaReadDto) => {
    return axios.put(`${Endpoint.SIFARNIK_PROIZVODA_LIST}/${sifarnikProizvoda?.id}`, sifarnikProizvoda, axiosConfig(authData!.token));
  };

  const axiosDeleteSifarnikProizvoda = (sifarnikProizvodaId: number) => {
    return axios.delete(`${Endpoint.SIFARNIK_PROIZVODA_LIST}/${sifarnikProizvodaId}`, axiosConfig(authData!.token));
  };

  const axiosReadSifarnikProizvodaRFZOList = () => {
    return axios.get(`${Endpoint.SIFARNIK_PROIZVODA_RFZO_LIST}`, axiosConfig(authData!.token));
  };

  const axiosGetCeneForProizvod = (sifarnikProizvodaId: number) => {
    return axios.get(`${Endpoint.SIFARNIK_PROIZVODA_LIST}/${sifarnikProizvodaId}/proizvod-cena-list`, axiosConfig(authData!.token));
  };

  const axiosCreateCenaForProizvod = (cenaProizvoda: ProizvodCenaCreateDto) => {
    return axios.post(`${Endpoint.SIFARNIK_PROIZVODA_LIST}/${cenaProizvoda.proizvod.id}/proizvod-cena-list`, cenaProizvoda ? cenaProizvoda : {}, axiosConfig(authData!.token));
  };

  const axiosDeleteCenaForProzivod = (sifarnikProizvodaId: number, cenaId: number) => {
    return axios.delete(`${Endpoint.SIFARNIK_PROIZVODA_LIST}/${sifarnikProizvodaId}/proizvod-cena-list/${cenaId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateCenaForProizvod = (cenaProizvoda: ProizvodCenaUpdateDto) => {
    return axios.put(`${Endpoint.SIFARNIK_PROIZVODA_LIST}/${cenaProizvoda.proizvod.id}/proizvod-cena-list/${cenaProizvoda.id}`, cenaProizvoda, axiosConfig(authData!.token));
  };

  return {
    axiosReadSifarnikProizvoda,
    axiosSearchSifarnikProizvoda,
    axiosUpdateSifarnikProizvoda,
    axiosCreateSifarnikProizvoda,
    axiosDeleteSifarnikProizvoda,
    axiosGetSifarnikProizvodaList,
    axiosReadSifarnikProizvodaRFZOList,
    axiosGetCeneForProizvod,
    axiosCreateCenaForProizvod,
    axiosDeleteCenaForProzivod,
    axiosUpdateCenaForProizvod,
  };
}
