import axios from "axios";
import { RadioButtonChangeEvent } from "primereact/radiobutton";
import { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../Store";
import { EnumController } from "../../controllers/enum/EnumController";
import Endpoint from "../../infrastructure/system/Endpoint";
import MessageType from "../../infrastructure/system/MessageType";
import { axiosConfig, handleAxiosCallError, useEffectOnce } from "../../infrastructure/system/Utils";
import RadnikReadDto from "../../model/radnik/RadnikReadDto";
import EnumBaseReadDto from "../../model/sifarnik/EnumBaseReadDto";

interface RadnikProfilLogicalType {
  changeRadnikData: (e: ChangeEvent<HTMLInputElement> | RadioButtonChangeEvent) => void;
  editRadnik: () => void;
  radnikChange: RadnikReadDto;
  setRadnikChange: any;
  cancelEdit: () => void;
  dialogClose: () => void;
  dialogVisible: () => void;
  displayDialog: boolean;
  _handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleScale: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  setEditorRef: (editor: any) => void;
  avatar: any;
  avatarChangeVisible: boolean;
  avatarVisible: () => void;
  avatarNotVisible: () => void;
  jezikList: Array<EnumBaseReadDto>;
  polList: Array<EnumBaseReadDto>;
}

export default function RadnikProfileLogical(): RadnikProfilLogicalType {
  const { updateAuthData, showMessage, authData, setShowBlockUI, setLocale, locale } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosGetJezikList, axiosGetPolList } = EnumController();
  const [radnikChange, setRadnikChange] = useState<RadnikReadDto>(authData!.currentRadnik);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [polList, setPolList] = useState<Array<EnumBaseReadDto>>([]);
  const [avatarChangeVisible, setAvatarChangeVisible] = useState(false);
  const [jezikList, setJezikList] = useState<Array<EnumBaseReadDto>>([]);
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState<any>({
    cropperOpen: false,
    img: null,
    zoom: 1,
    croppedImg: "",
  });
  const [editor, setEditor] = useState<any>();

  useEffectOnce(() => {
    axios
      .all([axiosGetJezikList(), axiosGetPolList()])
      .then(
        axios.spread((responseJezikList: any, responsePolList: any) => {
          setJezikList(responseJezikList.data.data);
          setPolList(responsePolList.data.data);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  });

  const changeRadnikData = (e: ChangeEvent<HTMLInputElement> | RadioButtonChangeEvent) => {
    setRadnikChange({
      ...radnikChange,
      [e.target.name]: e.target.value,
    });
  };

  const _handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAvatar({ ...avatar, img: e.target.files ? e.target.files[0] : "" });
  };

  const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
    const scale = parseFloat(e.target.value);
    setAvatar({ ...avatar, zoom: scale });
  };

  const handleSave = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      editProfilePicture(croppedImg.substr(croppedImg.indexOf(",") + 1));
      setAvatarChangeVisible(false);
    }
  };

  const setEditorRef = (editor: any) => {
    setEditor(editor);
  };

  const editRadnik = () => {
    setShowBlockUI(true);
    axios
      .put(`${Endpoint.RADNIK_LIST}/${radnikChange?.id}`, radnikChange, axiosConfig(authData!.token))
      .then((response: any) => {
        let newRadnik = response.data.data;
        updateAuthData({
          token: authData!.token,
          currentRadnik: newRadnik,
        });
        if (locale !== newRadnik.jezik.sifra) {
          setLocale(newRadnik.jezik.sifra);
        }
        showMessage(MessageType.SUCCESS, Labels.RADNIK_PROFIL_EDIT_SUCCESS);
        navigate(-1);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const cancelEdit = () => {
    setRadnikChange(authData!.currentRadnik);
  };

  const dialogClose = () => {
    setDisplayDialog(false);
  };

  const dialogVisible = () => {
    setDisplayDialog(true);
  };

  const editProfilePicture = (img: string) => {
    setRadnikChange({ ...radnikChange!, profilnaSlika: img });
  };

  const avatarVisible = () => {
    setAvatarChangeVisible(true);
  };

  const avatarNotVisible = () => {
    setAvatarChangeVisible(false);
  };

  return {
    changeRadnikData,
    editRadnik,
    radnikChange,
    setRadnikChange,
    cancelEdit,
    dialogClose,
    dialogVisible,
    displayDialog,
    _handleFileChange,
    handleScale,
    handleSave,
    setEditorRef,
    avatar,
    avatarChangeVisible,
    avatarVisible,
    avatarNotVisible,
    jezikList,
    polList,
  };
}
