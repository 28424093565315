import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EnumPercentilnaKrivaPol from "../../../infrastructure/system/EnumPercentilnaKrivaPol";
import EnumVrstaGrafikonaRasta from "../../../infrastructure/system/EnumVrstaGrafikonaRasta";
import Images from "../../../infrastructure/system/Images";
import KontrolaPravaPristupaService from "../../../infrastructure/system/KontrolaPravaPristupaService";
import MessageType from "../../../infrastructure/system/MessageType";
import PacijentPoljeKategorijaReadDto from "../../../model/pacijent-polje/PacijentPoljeKategorijaReadDto";
import PacijentPoljePopunjeno from "../../../model/pacijent-polje/PacijentPoljePopunjeno";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import SkeletonListItem from "../../app/skeleton/SkeletonListItem";
import DialogComponent from "../../dialog-component/DialogComponent";
import Polje from "../../formular/polja/Polje";
import OpstiMedicinskiPodaciLogical from "./OpstiMedicinskiPodaciLogical";
import PercentileKrivaDialog from "./PercentileKrivaDialog";
import SkeletonAccordionList from "../../app/skeleton/SkeletonAccordionList";

interface OpstiMedicinskiPodaciProps {
  pacijent?: PacijentReadDto;
  medicinskiPodaci: Array<PacijentPoljePopunjeno>;
  fetchMedicinskiPodaciAndInformacijeOPacijentu?: () => void;
}
export default function OpstiMedicinskiPodaci(props: OpstiMedicinskiPodaciProps) {
  const Labels = useLabels();
  const { pacijent, medicinskiPodaci, fetchMedicinskiPodaciAndInformacijeOPacijentu } = props;
  const { pristup, authData, showMessage } = useContext(AppContext);
  const {
    pacijentPoljeList,
    kreirajArhiviraniPodaciDialog,
    setKreirajArhiviraniPodaciDialog,
    getArhiviraniPodaci,
    pacijentPoljeArhivaList,
    pacijentPolje,
    onChange,
    pacijentPoljePopunjeno,
    onSave,
    dataLoading,
    percentileDialog,
    setPercentileDialog,
    vrstaGrafikona,
    setVrstaGrafikona,
    getSifarnikPercentilnaKrivaList,
    percentilnaKrivaList,
    setPercentilnaKrivaList,
    arhiviraniPodaciLoading,
  } = OpstiMedicinskiPodaciLogical({ pacijent, medicinskiPodaci, fetchMedicinskiPodaciAndInformacijeOPacijentu });
  const { hasPacijentUpdate } = KontrolaPravaPristupaService();

  return (
    <div className="layout-opsti-medicinski-podaci">
      {dataLoading ? (
        <SkeletonListItem size={2} />
      ) : (
        <>
          <Panel>
            {pacijentPoljeList?.map((pacijentPoljeTwoElement: any, index: number) => {
              return (
                <div className="flex flex-wrap" key={index}>
                  {pacijentPoljeTwoElement.map((pacijentPoljeKategorija: PacijentPoljeKategorijaReadDto, index2: number) => {
                    return (
                      <div className="xl:w-6 lg:w-full md:w-full sm:w-full" key={index2}>
                        <div className="flex">
                          <div className="col-10 xl:col-10 lg:col-9 md:col-9 sm:col-9">
                            <Polje
                              key={pacijentPoljeKategorija.pacijentPolje.id}
                              id={pacijentPoljeKategorija.pacijentPolje.id}
                              naziv={pacijentPoljeKategorija.pacijentPolje.naziv}
                              tip={pacijentPoljeKategorija.pacijentPolje.poljeTip.tipKomponenteEnum.sifra}
                              obavezno={pacijentPoljeKategorija.obavezno}
                              enumStavke={pacijentPoljeKategorija.pacijentPolje.poljeTip.poljeTipEnumStavkaList}
                              format={pacijentPoljeKategorija.pacijentPolje.poljeTip.format}
                              decimale={pacijentPoljeKategorija.pacijentPolje.poljeTip.decimale}
                              vrednost={pacijentPoljePopunjeno[pacijentPoljeKategorija.pacijentPolje.id]}
                              onChange={onChange}
                              readonly={!pristup || pacijent?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                              colSize={9}
                            />
                          </div>
                          <div className="flex align-items-center">
                            <img
                              src={Images.OPSTI_MEDICINSKI_PODACI_ISTORIJA}
                              alt="history"
                              onClick={() => pacijent && getArhiviraniPodaci(pacijentPoljeKategorija.pacijentPolje, pacijent?.id, true)}
                            />
                          </div>
                          {(pacijentPoljeKategorija.pacijentPolje?.vrstaGrafikonaRasta?.sifra === EnumVrstaGrafikonaRasta.VISINA ||
                            pacijentPoljeKategorija.pacijentPolje?.vrstaGrafikonaRasta?.sifra === EnumVrstaGrafikonaRasta.TEZINA ||
                            pacijentPoljeKategorija.pacijentPolje?.vrstaGrafikonaRasta?.sifra === EnumVrstaGrafikonaRasta.BMI) && (
                            <div className="flex align-items-center ml-2">
                              <Button
                                icon="pi pi-chart-bar"
                                className="p-button-outlined p-button-plain"
                                onClick={() => {
                                  if (pacijent?.polTrenutni.sifra === EnumPercentilnaKrivaPol.MUSKI || pacijent?.polTrenutni.sifra === EnumPercentilnaKrivaPol.ZENSKI) {
                                    setPercentileDialog(true);
                                    setVrstaGrafikona(pacijentPoljeKategorija.pacijentPolje.vrstaGrafikonaRasta.sifra);
                                    pacijent && getArhiviraniPodaci(pacijentPoljeKategorija.pacijentPolje, pacijent?.id);
                                    pacijent && getSifarnikPercentilnaKrivaList(pacijent?.polTrenutni.sifra, pacijentPoljeKategorija.pacijentPolje.vrstaGrafikonaRasta.sifra);
                                  } else {
                                    showMessage(MessageType.INFO, Labels.LABEL_PERCENTILNA_KRIVA_POL_NIJE_UNET);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div className="flex justify-content-end">
              {pacijentPoljeList.length > 0 && (
                <Button
                  label={Labels.BUTTON_SAVE}
                  className="p-button-success ml-2"
                  onClick={onSave}
                  disabled={!pristup || pacijent?.radnikKreirao.id === authData?.currentRadnik.id ? false : !hasPacijentUpdate}
                />
              )}
            </div>
          </Panel>
          <DialogComponent
            header={`${pacijentPolje?.naziv} - ${Labels.ARHIVIRANI_PODACI}`}
            visible={kreirajArhiviraniPodaciDialog}
            onHide={() => setKreirajArhiviraniPodaciDialog(false)}
            withoutFooter
            content={
              <>
                {arhiviraniPodaciLoading ? (
                  <div className="w-10">
                    <SkeletonAccordionList size={5} />
                  </div>
                ) : (
                  <>
                    {pacijentPoljeArhivaList.length === 0 && <div>{Labels.TABLE_EMPTY_MESSAGE}</div>}
                    {pacijentPoljeArhivaList?.map((pacijentPoljePopunjenoArchive: any, index: number) => (
                      <div key={index}>{`${pacijentPoljePopunjenoArchive.vremeArhiva} - ${pacijentPoljePopunjenoArchive.vrednost}`}</div>
                    ))}
                  </>
                )}
              </>
            }
          />
          {percentilnaKrivaList.length > 0 && (
            <PercentileKrivaDialog
              visible={percentileDialog}
              onHide={() => setPercentileDialog(false)}
              vrstaGrafikona={vrstaGrafikona}
              pacijent={pacijent}
              pacijentPoljeArhivaList={pacijentPoljeArhivaList}
              percentilnaKrivaList={percentilnaKrivaList}
              setPercentilnaKrivaList={setPercentilnaKrivaList}
              medicinskiPodaci={medicinskiPodaci}
            />
          )}
        </>
      )}
    </div>
  );
}
