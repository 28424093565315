import { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import { PacijentPoljeController } from "../../../controllers/pacijent-polje/PacijentPoljeController";
import { PacijentController } from "../../../controllers/pacijent/PacijentController";
import { SifarnikController } from "../../../controllers/sifarnik/SifarnikController";
import EnumPacijentPoljeKategorija from "../../../infrastructure/system/EnumPacijentPoljeKategorija";
import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import MessageType from "../../../infrastructure/system/MessageType";
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_3, checkEmpty, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import PacijentPoljeKategorijaReadDto from "../../../model/pacijent-polje/PacijentPoljeKategorijaReadDto";
import PacijentPoljePopunjeno from "../../../model/pacijent-polje/PacijentPoljePopunjeno";
import PacijentPoljeReadDto from "../../../model/pacijent-polje/PacijentPoljeReadDto";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import PoljeVrednostDto from "../../../model/polje/PoljeVrednostDto";
import SifarnikPercentilnaKrivaReadDto from "../../../model/sifarnik/SifarnikPercentilnaKrivaReadDto";
import { valueMap } from "../../formular/FormularLogical";

interface OpstiMedicinskiPodaciLogicalType {
  pacijentPoljeList: Array<Array<PacijentPoljeKategorijaReadDto>>;
  kreirajArhiviraniPodaciDialog: boolean;
  setKreirajArhiviraniPodaciDialog: React.Dispatch<React.SetStateAction<boolean>>;
  getArhiviraniPodaci: (pacijentPolje: PacijentPoljeReadDto, pacijentID: number, openDialog?: boolean) => void;
  pacijentPoljeArhivaList: Array<any>;
  pacijentPolje: PacijentPoljeReadDto | undefined;
  onChange: (poljeVrednost: PoljeVrednostDto) => void;
  pacijentPoljePopunjeno: valueMap;
  onSave: () => void;
  dataLoading: boolean;
  percentileDialog: boolean;
  setPercentileDialog: React.Dispatch<React.SetStateAction<boolean>>;
  vrstaGrafikona: string | undefined;
  setVrstaGrafikona: React.Dispatch<React.SetStateAction<string | undefined>>;
  percentilnaKrivaList: SifarnikPercentilnaKrivaReadDto[];
  setPercentilnaKrivaList: React.Dispatch<React.SetStateAction<SifarnikPercentilnaKrivaReadDto[]>>;
  getSifarnikPercentilnaKrivaList: (pol: string, vrstaGrafikona: string) => void;
  arhiviraniPodaciLoading: boolean;
}
interface OpstiMedicinskiPodaciLogicalProps {
  pacijent?: PacijentReadDto;
  medicinskiPodaci: Array<PacijentPoljePopunjeno>;
  fetchMedicinskiPodaciAndInformacijeOPacijentu?: () => void;
}
export default function OpstiMedicinskiPodaciLogical(props: OpstiMedicinskiPodaciLogicalProps): OpstiMedicinskiPodaciLogicalType {
  const [pacijentPoljeList, setPacijentPoljeList] = useState<Array<Array<PacijentPoljeKategorijaReadDto>>>([]);
  const [pacijentPolje, setPacijentPolje] = useState<PacijentPoljeReadDto>();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { axiosGetPacijentPoljeKategorijaListByKategorija, axiosGetPacijentPoljePopunjenoArhivaList } = PacijentPoljeController();
  const { axiosCreatePacijentPoljaPopunjena } = PacijentController();
  const { axiosSearchSifarnikPercentilnaKrivaList } = SifarnikController();
  const [kreirajArhiviraniPodaciDialog, setKreirajArhiviraniPodaciDialog] = useState<boolean>(false);
  const [pacijentPoljeArhivaList, setPacijentPoljeArhivaList] = useState<Array<any>>([]);
  const [pacijentPoljePopunjeno, setPacijentPoljePopunjeno] = useState<valueMap>({});
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [percentileDialog, setPercentileDialog] = useState<boolean>(false);
  const [vrstaGrafikona, setVrstaGrafikona] = useState<string | undefined>(undefined);
  const [percentilnaKrivaList, setPercentilnaKrivaList] = useState<SifarnikPercentilnaKrivaReadDto[]>([]);
  const [arhiviraniPodaciLoading, setArhiviraniPodaciLoading] = useState<boolean>(false);
  const { pacijent, medicinskiPodaci, fetchMedicinskiPodaciAndInformacijeOPacijentu } = props;
  const Labels = useLabels();

  useEffectOnce(() => {
    getPoljeList(EnumPacijentPoljeKategorija.OPSTI_MEDICINSKI_PODACI);
    let poljeVrednost: valueMap = {};

    medicinskiPodaci.forEach((pacijentPoljePopunjeno: PacijentPoljePopunjeno) => {
      if (
        pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.TEXT ||
        pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.LONG_TEXT
      ) {
        poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostString;
      } else if (
        pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.NUMBER_DECIMAL ||
        pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.NUMBER_INTEGER
      ) {
        poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostNumber;
      } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.DATE_TIME) {
        poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
      } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.DATE) {
        poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
      } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.TIME) {
        poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
      } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.BOOLEAN) {
        poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostBoolean;
      } else if (
        pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET ||
        pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET_RADIO
      ) {
        poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno?.vrednostEnumeratorItem?.id;
      }
    });
    setPacijentPoljePopunjeno(poljeVrednost);
  });

  const getPoljeList = async (category: string) => {
    let startTime = moment(new Date());
    setDataLoading(true);
    if (pacijent?.id)
      axiosGetPacijentPoljeKategorijaListByKategorija(category)
        .then((responsePacijentPoljeKategorijaByKategorija: AxiosResponse) => {
          const rows = responsePacijentPoljeKategorijaByKategorija.data.data.reduce(function (rows: any[][], key: any, index: number) {
            return (index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows;
          }, []);
          setPacijentPoljeList(rows);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setDataLoading, startTime);
        });
  };

  const getArhiviraniPodaci = (pacijentPolje: PacijentPoljeReadDto, pacijentID: number, openDialog?: boolean) => {
    openDialog && setKreirajArhiviraniPodaciDialog(true);
    setPacijentPolje(pacijentPolje);
    let poljeVrednost: Array<any> = [];
    setArhiviraniPodaciLoading(true);
    axiosGetPacijentPoljePopunjenoArhivaList(pacijentPolje.id, pacijentID)
      .then((response: AxiosResponse) => {
        response.data.data.forEach((poljePopunjenoArhiva: any) => {
          let newPoljeVrednost = { vremeArhiva: moment(poljePopunjenoArhiva.vremeArhiva).format(DATE_TIME_FORMAT) };
          if (poljePopunjenoArhiva.vrednostString !== null) {
            poljeVrednost.push({ ...newPoljeVrednost, vrednost: poljePopunjenoArhiva.vrednostString });
          } else if (poljePopunjenoArhiva.vrednostNumber !== null) {
            poljeVrednost.push({ ...newPoljeVrednost, vrednost: poljePopunjenoArhiva.vrednostNumber });
          } else if (poljePopunjenoArhiva.vrednostDatetime !== null) {
            poljeVrednost.push({ ...newPoljeVrednost, vrednost: moment(poljePopunjenoArhiva.vrednostDatetime).format(DATE_TIME_FORMAT) });
          } else if (poljePopunjenoArhiva.vrednostBoolean !== null) {
            poljeVrednost.push({ ...newPoljeVrednost, vrednost: poljePopunjenoArhiva.vrednostBoolean });
          } else if (poljePopunjenoArhiva.vrednostEnumeratorItem !== null) {
            poljeVrednost.push({ ...newPoljeVrednost, vrednost: poljePopunjenoArhiva.vrednostEnumeratorItem });
          }
        });
        setPacijentPoljeArhivaList(poljeVrednost);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setArhiviraniPodaciLoading(false);
      });
  };

  const onChange = (e: PoljeVrednostDto) => {
    const newValue: any = {
      ...pacijentPoljePopunjeno,
      [e.id]: e.vrednost,
    };
    setPacijentPoljePopunjeno(newValue);
  };
  const validateInput = () => {
    let validInput: boolean = true;
    pacijentPoljeList.forEach((pacijentPoljeKategorijaArray: Array<PacijentPoljeKategorijaReadDto>) => {
      pacijentPoljeKategorijaArray.forEach((pacijentPoljeKategorija: PacijentPoljeKategorijaReadDto) => {
        if (pacijentPoljeKategorija.obavezno) {
          if (!pacijentPoljePopunjeno || checkEmpty(pacijentPoljePopunjeno[pacijentPoljeKategorija?.pacijentPolje?.id]) || pacijentPoljePopunjeno[pacijentPoljeKategorija?.pacijentPolje?.id] === "") {
            showMessage(MessageType.ERROR, Labels.PACIJENT_POLJE_OBAVEZNO_JE_UNETI + pacijentPoljeKategorija?.pacijentPolje?.naziv);
            validInput = false;
            return;
          }
        }
      });
    });

    return validInput;
  };

  const onSave = () => {
    if (pacijent) {
      const saveObj = {
        pacijent: {
          id: pacijent?.id,
        },
        polja: pacijentPoljePopunjeno,
      };
      setShowBlockUI(true);
      if (!validateInput()) {
        setShowBlockUI(false);
        return;
      }
      axiosCreatePacijentPoljaPopunjena(saveObj, pacijent?.id, EnumPacijentPoljeKategorija.OPSTI_MEDICINSKI_PODACI)
        .then(() => {
          getPoljeList(EnumPacijentPoljeKategorija.OPSTI_MEDICINSKI_PODACI);
          showMessage(MessageType.SUCCESS, Labels.PACIJENT_USPESNO_IZMENJEN);
          if (fetchMedicinskiPodaciAndInformacijeOPacijentu) fetchMedicinskiPodaciAndInformacijeOPacijentu();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const getSifarnikPercentilnaKrivaList = (pol: string, vrstaGrafikona: string) => {
    setShowBlockUI(true);
    axiosSearchSifarnikPercentilnaKrivaList(pol, vrstaGrafikona)
      .then((response: AxiosResponse) => {
        setPercentilnaKrivaList(response.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return {
    pacijentPoljeList,
    kreirajArhiviraniPodaciDialog,
    setKreirajArhiviraniPodaciDialog,
    getArhiviraniPodaci,
    pacijentPoljeArhivaList,
    pacijentPolje,
    onChange,
    pacijentPoljePopunjeno,
    onSave,
    dataLoading,
    percentileDialog,
    setPercentileDialog,
    vrstaGrafikona,
    setVrstaGrafikona,
    percentilnaKrivaList,
    setPercentilnaKrivaList,
    getSifarnikPercentilnaKrivaList,
    arhiviraniPodaciLoading,
  };
}
