import { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../Store";
import { RadnikController } from "../../../controllers/radnik/RadnikController";
import { UstanovaController } from "../../../controllers/ustanova/UstanovaController";
import EnumInicijalizacijaKorak from "../../../infrastructure/system/EnumInicijalizacijaKorak";
import { handleAxiosCallError, selectOrganizacionaJedinica, skeletonTimeout } from "../../../infrastructure/system/Utils";
import UstanovaOrganizacionaJedinicaReadDto from "../../../model/ustanova/UstanovaOrganizacionaJedinicaReadDto";
import UstanovaReadDto from "../../../model/ustanova/UstanovaReadDto";
import useEffectWithoutWarnings from "../../../infrastructure/system/hooks/useEffectWithoutWarnings";

interface UstanovaNavbarLogicalType {
  radnikUstanovaList: Array<UstanovaReadDto> | undefined;
  ustanova: UstanovaReadDto | undefined;
  setUstanova: React.Dispatch<React.SetStateAction<UstanovaReadDto | undefined>>;
  changeUstanova: (ustanova: UstanovaReadDto) => void;
  ustanovaOrgJedList?: UstanovaOrganizacionaJedinicaReadDto;
  setUstanovaOrgJedList: React.Dispatch<React.SetStateAction<UstanovaOrganizacionaJedinicaReadDto | undefined>>;
  ustanovaOrgJedListLoading: boolean;
}

export default function UstanovaNavbarLogical(): UstanovaNavbarLogicalType {
  const { authData, updateAuthData, showMessage, updateOdabranaOrganizacionaJedinicaList, odabranaOrganizacionaJedinicaList } = useContext(AppContext);
  const radnikUstanovaList = authData?.currentRadnik.radnikUstanovaList
    .filter((ustanova) => ustanova?.ustanova?.inicijalizacijaKorak?.sifra === EnumInicijalizacijaKorak.INICIJALIZOVANA)
    .map((radnikUstanova) => radnikUstanova.ustanova);
  const [ustanova, setUstanova] = useState<UstanovaReadDto | undefined>(authData?.currentRadnik.ustanovaPoslednjaIzabrana);
  const { axiosChangeUstanovaPoslednjeIzabrana } = RadnikController();
  const { axiosUstanovaDataFromId, axiosGetUstanovaDataFromIdForAdministrator } = UstanovaController();

  const [ustanovaOrgJedList, setUstanovaOrgJedList] = useState<UstanovaOrganizacionaJedinicaReadDto>();
  const [ustanovaOrgJedListLoading, setUstanovaOrgJedListLoading] = useState<boolean>(true);

  const firstLoadedRef = useRef(false);

  const changeUstanova = (ustanova: UstanovaReadDto) => {
    axiosChangeUstanovaPoslednjeIzabrana(ustanova.id)
      .then((res: AxiosResponse) => {
        updateAuthData({
          token: authData!.token,
          currentRadnik: res.data.data,
        });
        setUstanova(ustanova);
      })
      .catch((err) => handleAxiosCallError(showMessage, err));
  };

  useEffect(() => {
    if (ustanova) {
      const ustanovaDataRequest = window.location.pathname.includes("radni") ? axiosGetUstanovaDataFromIdForAdministrator(ustanova?.id) : axiosUstanovaDataFromId(ustanova?.id);
      let startTime = moment(new Date());
      ustanovaDataRequest
        .then(({ data: { data } }: { data: { data: UstanovaOrganizacionaJedinicaReadDto } }) => {
          setUstanovaOrgJedList(data);
          firstLoadedRef.current = true;
        })
        .catch((err) => handleAxiosCallError(showMessage, err))
        .finally(() => {
          skeletonTimeout(setUstanovaOrgJedListLoading, startTime);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ustanova]);

  useEffectWithoutWarnings(() => {
    // u slucaju da nema odabrane org jedinice u storage, a u listi postoji samo jedna, selektujemo tu
    if (ustanovaOrgJedList?.organizacioneJedinice.length === 1 && odabranaOrganizacionaJedinicaList?.length === 0) {
      const orgJedArray = ustanovaOrgJedList.organizacioneJedinice[0] ? selectOrganizacionaJedinica([], ustanovaOrgJedList.organizacioneJedinice[0]) : [];
      updateOdabranaOrganizacionaJedinicaList(orgJedArray);
    }
  }, [ustanovaOrgJedList]);

  return {
    radnikUstanovaList,
    ustanovaOrgJedList,
    setUstanovaOrgJedList,
    ustanova,
    setUstanova,
    changeUstanova,
    ustanovaOrgJedListLoading,
  };
}
