import { Button } from "primereact/button";
import { useLabels } from "../../../../Store";
import { tooltipOptionsTop } from "../../../../infrastructure/system/Utils";

interface NazivType {
  naziv?: string;
  opis?: string;
  obavezno?: boolean;
}

export default function Naziv(props: NazivType) {
  const { naziv, opis, obavezno } = props;
  const Labels = useLabels();

  return (
    <div className="flex align-items-center justify-content-between font-bold p-0">
      {naziv}
      {opis && <Button icon="pi pi-info-circle" className="p-button-sm p-button-outlined p-button-rounded p-button-info p-button-text" tooltip={opis} tooltipOptions={tooltipOptionsTop} />}
      {obavezno && Labels.SPECIAL_CHAR_REQUIRED}
    </div>
  );
}
