import moment from "moment/moment";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { PaketController } from "../../../../controllers/paket/PaketController";
import { SifarnikProizvodaController } from "../../../../controllers/sifarnik-proizvoda/SifarnikProizvodaController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";

import PaketProizvodCreateDto from "../../../../model/paket/PaketProizvodCreateDto";
import PaketProizvodReadDto from "../../../../model/paket/PaketProizvodReadDto";
import PaketProizvodUpdateDto from "../../../../model/paket/PaketProizvodUpdateDto";
import SifarnikProizvodaReadDto from "../../../../model/sifarnik/sifarnik-proizvoda/SifarnikProizvodaReadDto";
import { AppContext, useLabels } from "../../../../Store";

interface PaketProizvodListLogicalType {
  paketProizvodLoading: boolean;
  paketProizvodList: Array<PaketProizvodReadDto>;
  first: number;
  tableRows: number;
  onPagePaketProizvod: (rows: number, first: number) => void;
  isDialogPaketProizvodOpen: boolean;
  openDialogPaketProizvod: (action: string, data: PaketProizvodReadDto | any) => void;
  closeDialogPaketProizvod: () => void;
  paketProizvodChange: PaketProizvodCreateDto | undefined;
  setPaketProizvodChange: any;
  searchSifarnikProizvoda: (e: AutoCompleteCompleteEvent) => void;
  sifarnikProizvodaList: Array<SifarnikProizvodaReadDto>;
  dialogAction: string;
  paketProizvod: PaketProizvodReadDto | undefined;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
}

interface UseParamsType {
  paketId?: string;
}

export default function PaketProizvodListLogical(): PaketProizvodListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const paketId = Number(useParams<keyof UseParamsType>()["paketId"]);
  const [paketProizvodLoading, setPaketProizvodLoading] = useState<boolean>(true);
  const [paketProizvodList, setPaketProizvodList] = useState<Array<PaketProizvodReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [isDialogPaketProizvodOpen, setIsDialogPaketProizvodOpen] = useState<boolean>(false);
  const [paketProizvodChange, setPaketProizvodChange] = useState<PaketProizvodCreateDto | undefined>();
  const [sifarnikProizvodaList, setSifarnikProizvodaList] = useState<Array<SifarnikProizvodaReadDto>>([]);
  const [dialogAction, setDialogAction] = useState<string>("");
  const [paketProizvod, setPaketProizvod] = useState<PaketProizvodReadDto>();

  const { axiosGetProizvodiListByPaketId, axiosCreateProizvodForPaket, axiosUpdateProizvodFromPaket, axiosDeleteProizvodFromPaket } = PaketController();
  const { axiosSearchSifarnikProizvoda } = SifarnikProizvodaController();
  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    if (paketId) {
      let startTime = moment(new Date());
      setPaketProizvodLoading(true);
      axiosGetProizvodiListByPaketId(paketId)
        .then((res) => {
          setPaketProizvodList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          skeletonTimeout(setPaketProizvodLoading, startTime);
        })
        .finally(() => {
          skeletonTimeout(setPaketProizvodLoading, startTime);
        });
    } else {
      setPaketProizvodLoading(false);
    }
  };

  const onPagePaketProizvod = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const openDialogPaketProizvod = (action: string, data: PaketProizvodReadDto) => {
    setIsDialogPaketProizvodOpen(true);
    setDialogAction(action);
    if (data !== undefined) {
      setPaketProizvod(data);
    }
    if (action === EntityOperation.CREATE && data === undefined) {
      setPaketProizvodChange({
        ...paketProizvodChange!,
        paket: { id: paketId },
      });
    } else {
      setPaketProizvodChange({
        ...paketProizvodChange!,
        paket: { id: paketId },
        podrazumevanaUtrosenaKolicina: data?.podrazumevanaUtrosenaKolicina,
        prikazniRedosled: data?.prikazniRedosled,
        sifarnikProizvoda: { id: data?.sifarnikProizvoda.id },
        napomena: data?.napomena,
      });
    }
  };

  const closeDialogPaketProizvod = () => {
    setIsDialogPaketProizvodOpen(false);
    setPaketProizvodChange(undefined);
  };

  const searchSifarnikProizvoda = (e: AutoCompleteCompleteEvent) => {
    if (e.query.length > 2) {
      axiosSearchSifarnikProizvoda({ searchString: e.query })
        .then((res: any) => {
          setSifarnikProizvodaList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const validateInput = (proizvod?: PaketProizvodCreateDto) => {
    if (!proizvod?.sifarnikProizvoda?.id) {
      showMessage(MessageType.ERROR, Labels.PAKET_USLUGA_PROIZVOD_MANDATORY_FIELD);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateInput(paketProizvodChange)) {
      return;
    }
    if (paketProizvodChange && paketId) {
      axiosCreateProizvodForPaket(paketId, paketProizvodChange)
        .then(() => {
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_CREATE_PROIZVOD_FOR_PAKET_USLUGA + paketProizvodChange?.sifarnikProizvodaFull?.naziv + ".");
        })
        .then(() => {
          closeDialogPaketProizvod();
          showMessage(MessageType.SUCCESS, Labels.PAKET_USLUGA_ADD_PROIZVOD_SUCCESS);
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onUpdate = () => {
    let proizvodUpdate: PaketProizvodUpdateDto | undefined = paketProizvodChange ? { ...paketProizvodChange } : undefined;
    if (proizvodUpdate && paketProizvod?.id) {
      axiosUpdateProizvodFromPaket(paketId, proizvodUpdate, paketProizvod.id)
        .then(() => {
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_UPDATE_PROIZVOD_FOR_PAKET_USLUGA + paketProizvod?.sifarnikProizvoda?.naziv + ".");
        })
        .then(() => {
          closeDialogPaketProizvod();
          showMessage(MessageType.SUCCESS, Labels.PAKET_USLUGA_UPDATE_PROIZVOD_SUCCESS);
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onDelete = () => {
    if (paketProizvod?.id) {
      axiosDeleteProizvodFromPaket(paketId, paketProizvod?.id)
        .then(() => {
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_DELETE_PROIZVOD_FOR_PAKET_USLUGA + paketProizvod?.sifarnikProizvoda?.naziv + ".");
        })
        .then(() => {
          closeDialogPaketProizvod();
          showMessage(MessageType.SUCCESS, Labels.PAKET_USLUGA_DELETE_PROIZVOD_SUCCESS);
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  return {
    paketProizvodLoading,
    paketProizvodList,
    first,
    tableRows,
    onPagePaketProizvod,
    isDialogPaketProizvodOpen,
    openDialogPaketProizvod,
    closeDialogPaketProizvod,
    paketProizvodChange,
    setPaketProizvodChange,
    searchSifarnikProizvoda,
    sifarnikProizvodaList,
    dialogAction,
    paketProizvod,
    onCreate,
    onDelete,
    onUpdate,
  };
}
