import moment from "moment";
import { Calendar } from "primereact/calendar";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Store";
import { CALENDAR_DATE_FORMAT } from "../../../infrastructure/system/Utils";

const CalendarFilter = (props: { options: any }) => {
  const { locale } = useContext(AppContext);
  const { options } = props;
  const [filterDate, setFilterDate] = useState<Date | Date[] | string | null | undefined>();

  useEffect(() => {
    const date = filterDate?.toLocaleString("en-GB").slice(0, 10);
    if (moment(date, "DD/MM/YYYY", true).isValid()) {
      return options.filterApplyCallback(filterDate, options.index, options?.filterType);
    }
    // eslint-disable-next-line
  }, [filterDate]);

  return (
    <Calendar
      value={options.value}
      showIcon
      onChange={(e) => {
        setFilterDate(e.value);
      }}
      dateFormat={CALENDAR_DATE_FORMAT}
      mask="99.99.9999."
      locale={locale === "SR_RS" ? "sr-cyrilic" : "sr"}
    />
  );
};

export default CalendarFilter;
