import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Image } from "primereact/image";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { useContext } from "react";
import { useLabels } from "../../../../Store";
import EnumPartnerTip from "../../../../infrastructure/system/EnumPartnerTip";
import EnumVrstaUplatioca from "../../../../infrastructure/system/EnumVrstaUplatioca";
import Images from "../../../../infrastructure/system/Images";
import { formatPrice, useEffectOnce } from "../../../../infrastructure/system/Utils";
import RacunCreateDto from "../../../../model/naplata/RacunCreateDto";
import SifarnikNacinaPlacanjaReadDto from "../../../../model/sifarnik/sifarnik-nacina-placanja/SifarnikNacinPlacanjaReadDto";
import { InvalidInputsType, NaplataContext } from "../../NaplataLogical";
import { Checkbox } from "primereact/checkbox";

interface RacuniPropsType {
  prevStepRef: any;
}

const Racuni = ({ prevStepRef }: RacuniPropsType) => {
  const Labels = useLabels();
  const { state, dispatch, invalidInputs, setInvalidInputs, nacinPlacanjaList, finalnaValuta } = useContext(NaplataContext);

  useEffectOnce(() => {
    prevStepRef.current = 2;
  });

  const fillPrice = (racun: RacunCreateDto, nacinPlacanja: SifarnikNacinaPlacanjaReadDto, index: number) => {
    const iznos: number =
      racun.iznos -
      racun.racunNacinPlacanjaList
        .filter((racunNacinPlacanja) => racunNacinPlacanja.sifarnikNacinPlacanja.id !== nacinPlacanja.id)
        .map((filteredRacunNacinPlacanja) => filteredRacunNacinPlacanja.iznos)
        .reduce((acc, iznos) => acc + iznos, 0);

    dispatch({ type: "set_nacin_placanja", data: { index, racunNacinPlacanja: { iznos, sifarnikNacinPlacanja: nacinPlacanja } } });
  };

  return (
    <div className="layout-generic-content-list racuni flex flex-column justify-content-center align-items-center">
      {state.racuni?.map((racun, index: number) => (
        <div key={index} className={`racuni-form-div ${invalidInputs.invalidPayments.includes(index) && "invalid-field"}`}>
          <div className="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
            <div className="flex flex-row justify-content-between text-xl font-bold w-full">
              <div>
                {Labels.LABEL_RACUN_ZA_UPLATIOCA_NAPLATA} - {racun?.pacijent ? Labels.LABEL_PACIJENT_NAPLATA : Labels.PARTNER}
              </div>
              <div>
                <div>
                  {formatPrice(racun.iznos)} {finalnaValuta}
                </div>
              </div>
            </div>
            <Divider className="divider" />
            {racun.iznos > 0 &&
              nacinPlacanjaList
                .filter((nacinPlacanja) => {
                  if (racun.pacijent) {
                    return nacinPlacanja.vrstaUplatioca.sifra !== EnumVrstaUplatioca.ZA_PRAVNO_LICE;
                  }
                  if (racun.partner && racun.partner?.partnerTip?.sifra === EnumPartnerTip.FIZICKO_LICE) {
                    return nacinPlacanja.vrstaUplatioca.sifra !== EnumVrstaUplatioca.ZA_PRAVNO_LICE;
                  }
                  if (racun.partner && racun.partner?.partnerTip?.sifra === EnumPartnerTip.PRAVNO_LICE) {
                    return nacinPlacanja.vrstaUplatioca.sifra !== EnumVrstaUplatioca.ZA_FIZICKO_LICE;
                  }
                  return false;
                })
                .map((nacinPlacanja) => (
                  <div key={nacinPlacanja.id} className="grid align-items-center p-0 pt-2">
                    <div className="col-12 px-0 sm:col-4">
                      <div>{nacinPlacanja.naziv}</div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <InputNumber
                        value={racun.racunNacinPlacanjaList?.find((racunNacinPlacanja) => racunNacinPlacanja.sifarnikNacinPlacanja.id === nacinPlacanja.id)?.iznos}
                        onChange={(e) => {
                          dispatch({ type: "set_nacin_placanja", data: { index: index, racunNacinPlacanja: { iznos: e.value ?? 0, sifarnikNacinPlacanja: nacinPlacanja } } });
                          setInvalidInputs((prev: InvalidInputsType) => ({ ...prev, invalidPayments: [...prev.invalidPayments.filter((invalidPayment) => invalidPayment !== index)] }));
                        }}
                        mode="decimal"
                        locale="sr-RS"
                        minFractionDigits={2}
                        maxFractionDigits={6}
                        suffix={` ${finalnaValuta}`}
                        min={0}
                        inputClassName="border-noround-right"
                      />

                      <Button
                        icon={<Image className="flex align-items-center justify-content-center" width={"15px"} src={Images.ICON_NAPLATA_DOPUNA} />}
                        className="border-noround-left"
                        tooltip={Labels.LABEL_NAPLATA_PREKOPIRAJ_PREOSTALI_IZNOS}
                        onClick={() => {
                          setInvalidInputs((prev: InvalidInputsType) => ({ ...prev, invalidPayments: [...prev.invalidPayments.filter((invalidPayment) => invalidPayment !== index)] }));
                          fillPrice(racun, nacinPlacanja, index);
                        }}
                      />
                    </div>
                  </div>
                ))}
            <div className="grid align-items-center p-0 pt-2">
              <div className="flex align-self-start col-12 px-0 sm:col-4">
                <div>{Labels.LABEL_NAPOMENA_NAPLATA}</div>
              </div>
              <div className="col-12 sm:col-8 p-fluid p-0 pt-2">
                <InputTextarea rows={5} cols={30} value={racun.napomena} onChange={(e) => dispatch({ type: "set_racun_napomena", data: { index, napomena: e.target.value } })} autoResize />
              </div>
            </div>
            <div className="grid justify-content-end p-0 pt-2">
              <div className="flex align-items-center gap-2 mt-4 stampa-racuna">
                <Checkbox
                  onClick={(e) => {
                    const racunType = racun?.pacijent ? "pacijent" : "partner";
                    dispatch({ type: "set_stampanje", racunType, data: e.checked! });
                  }}
                  checked={racun?.pacijent ? state.stampanje.pacijent : state.stampanje.partner}
                  className="stampa-racuna-checkbox"
                />
                <span className="font-semibold">{Labels.LABEL_SPECIFIKACIJE_RACUNA_NAPLATA}</span>
                <i className="pi pi-print stampa-racuna-icon"></i>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Racuni;
