import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { ChangeEvent } from "react";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import { CrudBrojNalogaLogical } from "./CrudBrojNalogaLogical";

export default function CrudBrojNalogaView() {
  const { breadCrumbItems, brojNalogaLoading, brojNalogaChange, setBrojNalogaChange, isDisabled, brojNalogaOperation, onCreate, onUpdate, onCancel } = CrudBrojNalogaLogical();

  const Labels = useLabels();

  const leftContentsToolbar = () => (
    <>
      {brojNalogaOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.BROJ_NALOGA_TITLE_CREATE} tooltipOptions={tooltipOptionsBottom} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {brojNalogaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={brojNalogaLoading}
          icon="pi pi-pencil"
          tooltip={Labels.BROJ_NALOGA_TITLE_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView className="mt-3" renderActiveOnly={false} activeIndex={0}>
          <TabPanel header={Labels.BROJ_NALOGA}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.BROJ_NALOGA_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!brojNalogaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={brojNalogaChange?.naziv ?? ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setBrojNalogaChange({
                          ...brojNalogaChange!,
                          naziv: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.BROJ_NALOGA_SIFRA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!brojNalogaLoading ? (
                    <InputText
                      disabled={isDisabled}
                      value={brojNalogaChange?.sifra ?? ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setBrojNalogaChange({
                          ...brojNalogaChange!,
                          sifra: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.BROJ_NALOGA_UKUPNO}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!brojNalogaLoading ? (
                    <InputNumber
                      disabled={isDisabled || brojNalogaOperation === EntityOperation.UPDATE}
                      value={brojNalogaChange?.broj}
                      onChange={(e) => {
                        setBrojNalogaChange({
                          ...brojNalogaChange!,
                          broj: e.value ?? undefined,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.CENA}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!brojNalogaLoading ? (
                    <InputNumber
                      disabled={isDisabled || brojNalogaOperation === EntityOperation.UPDATE}
                      value={brojNalogaChange?.cena}
                      onChange={(e) => {
                        setBrojNalogaChange({
                          ...brojNalogaChange!,
                          cena: e.value ?? 0,
                        });
                      }}
                      min={0}
                      mode="decimal"
                      locale="sr-RS"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      suffix=" RSD"
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} className="flex" />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
