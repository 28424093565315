import { Accordion, AccordionTab, AccordionTabChangeEvent } from "primereact/accordion";
import { Button } from "primereact/button";
import { ConfirmPopup } from "primereact/confirmpopup";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { Tooltip } from "primereact/tooltip";
import { useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import Images from "../../../infrastructure/system/Images";
import PoljeVrednostDto from "../../../model/polje/PoljeVrednostDto";
import SablonReadDto from "../../../model/sablon/SablonReadDto";
import DialogComponent from "../../dialog-component/DialogComponent";
import SablonListLogical from "./SablonListLogical";
interface SablonListType {
  idPolje?: number;
  vrednostPolje?: string;
  nazivPolje?: string;
  changePoljeVrednost: (poljeVrednost: PoljeVrednostDto) => void;
}
export default function SablonList(props: SablonListType) {
  const { idPolje, nazivPolje } = props;
  const Labels = useLabels();
  const {
    sablonList,
    dataLoading,
    activeIndex,
    setActiveIndex,
    onChange,
    addSablonDialogShow,
    showUpdateSablonDialog,
    showAddSablonDialog,
    hideAddSablonDialog,
    sablon,
    setSablon,
    onCreate,
    onUpdate,
    isCreating,
    sablonOperation,
    confirmDelete,
  } = SablonListLogical({
    ...props,
  });
  const sablonAccordionHeader = (element: SablonReadDto) => <div className="font-bold align-items-center text-center w-full">{element.naslov}</div>;
  const footerSablonDialog = () => {
    return (
      <div className="layout-formular">
        {isCreating && <ProgressSpinner className=" h-2rem" />}
        <Button
          label={Labels.BUTTON_SAVE}
          disabled={isCreating}
          className="p-button-success-custom"
          onClick={() => {
            sablonOperation === EntityOperation.CREATE ? onCreate() : onUpdate();
          }}
        />
      </div>
    );
  };
  return (
    <div>
      {!dataLoading && idPolje !== undefined && (
        <>
          <Button label={Labels.SABLON_ADD} className="p-button-outlined border-none p-button-success button-hover-ignore mb-2 p-2" icon="pi pi-plus" onClick={() => showAddSablonDialog()} />
          <div className="font-bold mb-2">
            {Labels.SABLON_POLJE_NAZIV}
            {nazivPolje}
          </div>
          <Accordion activeIndex={activeIndex} className="mb-3" onTabChange={(e: AccordionTabChangeEvent) => setActiveIndex(e.index)}>
            {sablonList?.map((element: SablonReadDto, index: number) => {
              return (
                <AccordionTab headerTemplate={() => sablonAccordionHeader(element)} key={index}>
                  <div>
                    <div className="flex flex-row-reverse w-full justify-content-between">
                      <div className="flex">
                        <Tooltip target={`.overwrite`} position="bottom">
                          {Labels.BUTTON_OVERWRITE}
                        </Tooltip>
                        <img src={Images.KOPIRAJ} alt={Labels.BUTTON_OVERWRITE} className="cursor-pointer overwrite icon-image margin-right-20" onClick={() => onChange(element.tekst)} />
                        <Tooltip target={`.change`} position="bottom">
                          {Labels.BUTTON_CHANGE}
                        </Tooltip>
                        <img src={Images.IZMENI} alt={Labels.BUTTON_CHANGE} className="cursor-pointer change icon-image margin-right-20" onClick={() => showUpdateSablonDialog(element)} />
                        <Tooltip target={`.delete`} position="bottom">
                          {Labels.BUTTON_DELETE}
                        </Tooltip>
                        <img src={Images.OBRISI} alt={Labels.BUTTON_DELETE} className="cursor-pointer delete icon-image" onClick={(event: any) => confirmDelete(event, element.id)} />
                      </div>
                    </div>
                    <span>
                      {element.tekst.split("\n").map((str) => (
                        <p>{str}</p>
                      ))}
                    </span>
                  </div>
                </AccordionTab>
              );
            })}
          </Accordion>
        </>
      )}
      {dataLoading && idPolje !== undefined && (
        <div className="w-full h-full flex align-items-center ">
          <ProgressSpinner />
        </div>
      )}
      {idPolje === undefined && <div className="w-full h-full flex align-items-center ">{Labels.SABLON_CHOOSE_POLJE}</div>}
      <DialogComponent
        header={sablonOperation === EntityOperation.CREATE ? Labels.SABLON_ADD : Labels.SABLON_UPDATE}
        visible={addSablonDialogShow}
        onHide={hideAddSablonDialog}
        footer={footerSablonDialog}
        className="w-5"
        content={
          <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
            <div className="grid">
              <div className="col-12">
                <div className="grid align-items-center justfy-content-center p-nogutter">
                  <div className="font-bold col-12 pl-1">
                    {Labels.SABLON_NASLOV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="grid align-items-center justfy-content-center p-nogutter">
                  <div className="col-12 p-fluid margin-top-bottom-5 p-1 pl-1">
                    <InputText value={sablon?.naslov ?? ""} onChange={(e) => setSablon({ ...sablon!, naslov: e.target.value })} />
                  </div>
                </div>
                <div className="grid align-items-center justfy-content-center p-nogutter">
                  <div className="font-bold col-12 pl-1 mt-3">
                    {Labels.SABLON_TEKST}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="grid align-items-center justfy-content-center p-nogutter">
                  <div className="col-12 p-fluid margin-top-bottom-5 p-1 pl-1">
                    <InputTextarea cols={100} rows={25} value={sablon?.tekst ?? ""} onChange={(e) => setSablon({ ...sablon!, tekst: e.target.value })} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <ConfirmPopup />
    </div>
  );
}
