import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import { useLabels } from "../../../Store";
import { formatPrice } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import SifarnikLekovaReadDto from "../../../model/sifarnik-lekova-na-recept/SifarnikLekovaReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import SifarnikLekovaNaReceptListLogical from "./SifarnikLekovaNaReceptListLogical";
import DialogIstorijaIndikacije from "./dialog-istorija/DialogIstorijaIndikacijeView";

export default function SifarnikLekovaNaReceptListView() {
  const {
    breadCrumbItems,
    sifarnikLekovaLoading,
    first,
    tableRows,
    exportData,
    sifarnikLekovaNaReceptList,
    odabranSifarnikLekova,
    setOdabranSifarnikLekova,
    onPageLek,
    closeDialog,
    openDialog,
    isLoading,
    renderDate,
    state,
    sifarnikLekovaCount,
    onFilterHandler,
    filters,
  } = SifarnikLekovaNaReceptListLogical();

  const Labels = useLabels();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const dt = useRef<any>(null);
  const tableRef = useRef<any>();

  const crudBodyTemplate = (rowSifarnikLekova: SifarnikLekovaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_DETAILS}
          className="p-button mr-2"
          icon="pi pi-exclamation-triangle"
          onClick={() => {
            openDialog(rowSifarnikLekova.id, "indikacije");
            setOdabranSifarnikLekova(rowSifarnikLekova);
          }}
        />
        <Button
          type="button"
          tooltip={Labels.SIFARNIK_LEKOVA_NA_RECEPT_DIALOG_HISTORY}
          className="p-button mr-2"
          icon="pi pi-history"
          onClick={() => {
            openDialog(rowSifarnikLekova.id, "istorija");
            setOdabranSifarnikLekova(rowSifarnikLekova);
          }}
        />
      </div>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={sifarnikLekovaLoading} data={exportData} name={Labels.LABEL_SIFARNIK_LEKOVA_NA_RECEPT} dt={dt} />
      </>
    );
  };

  const cenaTemplate = (rowSifarnikLekova: SifarnikLekovaReadDto) => <div>{rowSifarnikLekova.cena ? formatPrice(rowSifarnikLekova.cena) + Labels.VALUTA : ""}</div>;

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.SIFARNIK_LEKOVA_NA_RECEPT_JKL, filter: true, sortrable: false },
    { columnName: Labels.SIFARNIK_LEKOVA_NA_RECEPT_NAZIV, filter: true, sortrable: false },
    { columnName: Labels.SIFARNIK_LEKOVA_NA_RECEPT_PROIZVODJAC, filter: true, sortrable: false },
    { columnName: Labels.SIFARNIK_LEKOVA_NA_RECEPT_ATC_GRUPA, filter: true, sortrable: false },
    { columnName: Labels.SIFARNIK_LEKOVA_NA_RECEPT_GENERICKI_NAZIV, filter: true, sortrable: false },
    { columnName: Labels.SIFARNIK_LEKOVA_NA_RECEPT_OBLIK_LEKA, filter: false, sortrable: false },
    { columnName: Labels.SIFARNIK_LEKOVA_NA_RECEPT_CENA, filter: false, sortrable: true },
  ];
  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar right={downloadButton} />
        {sifarnikLekovaLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={tableRef}
            filterDisplay="row"
            lazy
            value={sifarnikLekovaNaReceptList ?? []}
            paginator
            first={first}
            rows={tableRows}
            filters={filters}
            totalRecords={sifarnikLekovaCount}
            onFilter={onFilterHandler}
            globalFilterFields={["nasiframe", "naziv", "proizvodjac.naziv", "atc.sifra"]}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            onPage={(e) => onPageLek(e.rows, e.first, e.page)}
            exportFilename={Labels.LABEL_SIFARNIK_LEKOVA_NA_RECEPT}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"sifra"} header={Labels.SIFARNIK_LEKOVA_NA_RECEPT_JKL} filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"naziv"} header={Labels.SIFARNIK_LEKOVA_NA_RECEPT_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"proizvodjac.naziv"} header={Labels.SIFARNIK_LEKOVA_NA_RECEPT_PROIZVODJAC} filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"atc.sifra"} header={Labels.SIFARNIK_LEKOVA_NA_RECEPT_ATC_GRUPA} filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"atc.naziv"} header={Labels.SIFARNIK_LEKOVA_NA_RECEPT_GENERICKI_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} />
            <Column field={"oblik"} header={Labels.SIFARNIK_LEKOVA_NA_RECEPT_OBLIK_LEKA} />
            <Column body={cenaTemplate} header={Labels.SIFARNIK_LEKOVA_NA_RECEPT_CENA} />

            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
        <DialogIstorijaIndikacije
          lek={odabranSifarnikLekova}
          closeDialog={closeDialog}
          show={state.isDialogOpen}
          isLoading={isLoading}
          listaCena={state.listaCena}
          renderDate={renderDate}
          listaIndikacija={state.listaIndikacija}
          listaDodatnihIndikacija={state.listaDodatnihIndikacija}
          showIstorijuCene={state.showIstorijuCene}
        />
      </Panel>
    </div>
  );
}
