import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../../Store";
import { BrojNalogaController } from "../../../../../controllers/broj-naloga/BrojNalogaController";
import { UstanovaBrojNalogaController } from "../../../../../controllers/ustanova/ustanova-broj-naloga/UstanovaBrojNalogaController";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../../infrastructure/system/Utils";
import SearchBaseDto from "../../../../../model/SearchBaseDto";
import BrojNalogaReadDto from "../../../../../model/licenca/broj-naloga/BrojNalogaReadDto";
import UstanovaBrojNalogaReadDto from "../../../../../model/ustanova/licenca/UstanovaBrojNalogaReadDto";

interface UstanovaBrojNalogaListLogicalType {
  openUstanovaBrojNaloga: (entityOperation: string, id?: number) => void;
  ustanovaBrojNalogaLoading: boolean;
  ustanovaBrojNalogaList: Array<UstanovaBrojNalogaReadDto>;
  firstBrojNaloga: number;
  ustanovaBrojNalogaRows: number;
  fetchData: () => void;
  onPageUstanovaBrojNaloga: (rows: number, first: number) => void;
  ustanovaBrojNalogaSearchData: SearchBaseDto;
  fetchDataAdmin: () => void;
  changeUstanovaBrojNalogaSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  searchUstanovaBrojNalogaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  brojNalogaList: Array<BrojNalogaReadDto>;
}

interface UstanovaLicencaProps {
  ustanovaId: number | undefined;
  ustanovaNaziv: string | undefined;
}

export default function UstanovaBrojNalogaListLogical(props: UstanovaLicencaProps): UstanovaBrojNalogaListLogicalType {
  const navigate = useNavigate();
  const { showMessage } = useContext(AppContext);
  const { ustanovaId, ustanovaNaziv } = props;
  const [ustanovaBrojNalogaLoading, setUstanovaBrojNalogaLoading] = useState<boolean>(false);
  const { axiosGetUstanovaBrojNalogaList, axiosSearchUstanovaBrojNaloga } = UstanovaBrojNalogaController();
  const { axiosSearchBrojNalogaList } = BrojNalogaController();
  const [firstBrojNaloga, setFirstBrojNaloga] = useState(0);
  const [ustanovaBrojNalogaRows, setUstanovaBrojNalogaRows] = useState(10);
  const [ustanovaBrojNalogaList, setUstanovaBrojNalogaList] = useState<Array<UstanovaBrojNalogaReadDto>>([]);
  const [ustanovaBrojNalogaSearchData, setUstanovaBrojNalogaSearchData] = useState<SearchBaseDto>({});
  const [brojNalogaList, setBrojNalogaList] = useState<Array<BrojNalogaReadDto>>([]);

  const openUstanovaBrojNaloga = (entityOperation: string, id?: number) => {
    const ustanovaBrojNalogaId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-ustanova/${ustanovaId}/crud-ustanova-broj-naloga/${ustanovaBrojNalogaId}`, {
      state: { ustanovaBrojNalogaOperation: entityOperation, ustanovaNaziv: ustanovaNaziv },
    });
  };

  useEffectOnce(() => {
    fetchBrojNaloga();
    if (ustanovaId === undefined) {
      fetchDataAdmin();
    } else {
      fetchData();
    }
  });

  const fetchBrojNaloga = () => {
    axiosSearchBrojNalogaList({})
      .then(({ data: { data } }: { data: { data: Array<BrojNalogaReadDto> } }) => {
        setBrojNalogaList(data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {});
  };

  const fetchDataAdmin = () => {
    const startTime = moment(new Date());
    setUstanovaBrojNalogaLoading(true);
    axiosSearchUstanovaBrojNaloga(ustanovaBrojNalogaSearchData)
      .then(({ data: { data } }: { data: { data: Array<UstanovaBrojNalogaReadDto> } }) => {
        setUstanovaBrojNalogaList(data);
        setFirstBrojNaloga(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setUstanovaBrojNalogaLoading, startTime);
      });
  };

  const fetchData = () => {
    const startTime = moment(new Date());
    setUstanovaBrojNalogaLoading(true);
    axiosGetUstanovaBrojNalogaList(ustanovaId)
      .then(({ data: { data } }: { data: { data: Array<UstanovaBrojNalogaReadDto> } }) => {
        setUstanovaBrojNalogaList(data);
        setFirstBrojNaloga(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setUstanovaBrojNalogaLoading, startTime);
      });
  };

  const onPageUstanovaBrojNaloga = (rows: number, first: number) => {
    setUstanovaBrojNalogaRows(rows);
    setFirstBrojNaloga(first);
  };

  const searchUstanovaBrojNalogaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchDataAdmin();
    }
  };

  const changeUstanovaBrojNalogaSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setUstanovaBrojNalogaSearchData({
      ...ustanovaBrojNalogaSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  return {
    openUstanovaBrojNaloga,
    ustanovaBrojNalogaLoading,
    ustanovaBrojNalogaList,
    firstBrojNaloga,
    ustanovaBrojNalogaRows,
    fetchData,
    onPageUstanovaBrojNaloga,
    ustanovaBrojNalogaSearchData,
    fetchDataAdmin,
    changeUstanovaBrojNalogaSearchData,
    searchUstanovaBrojNalogaByEnter,
    brojNalogaList,
  };
}
