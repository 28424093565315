import { decode as base64_decode } from "base-64";
import { Button } from "primereact/button";
import { useContext, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../../../Store";
import { tooltipOptionsBottom } from "../../../../../infrastructure/system/Utils";
import FormularPopunjenReadDto from "../../../../../model/formular/FormularPopunjenReadDto";

interface DokumentType {
  selectedFormularPopunjen: FormularPopunjenReadDto | undefined;
  shiftFormularPopunjenBack: (item: FormularPopunjenReadDto) => void;
  shiftFormularPopunjenForward: (item: FormularPopunjenReadDto) => void;
  handlePrint: (content: string, isHtml: boolean) => boolean;
  deleteDokument: (selectedFormularPopunjen: FormularPopunjenReadDto) => void;
}
export default function Dokument(props: DokumentType) {
  const { shiftFormularPopunjenBack, shiftFormularPopunjenForward, selectedFormularPopunjen, handlePrint, deleteDokument } = props;
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const [iframeHeight, setIframeHeight] = useState<string>();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const onLoadIFrame = () => {
    setIframeHeight(iframeRef.current?.contentWindow?.document.body.scrollHeight! + 20 + "px");
  };

  return (
    <>
      {selectedFormularPopunjen && (
        <div className="flex flex-wrap flex col-12 flex-column">
          <div className="flex col-12 justify-content-center align-items-center p-0">
            <div className="col-2 flex align-items-center justify-content-start">
              <Button
                icon="pi pi-chevron-left"
                className="p-button-rounded p-button-outlined p-button-tertiary mr-2"
                onClick={() => {
                  shiftFormularPopunjenBack(selectedFormularPopunjen);
                }}
              />
              <Button
                icon="pi pi-chevron-right"
                className="p-button-rounded p-button-outlined p-button-tertiary"
                onClick={() => {
                  shiftFormularPopunjenForward(selectedFormularPopunjen);
                }}
              />
            </div>
            <div className="col-8 flex align-items-center justify-content-center font-bold text-2xl">{`${selectedFormularPopunjen?.formular?.naziv ?? ""}`}</div>
            <div className="col-2 flex align-items-center justify-content-end">
              <Button
                label={Labels.PREGLED_NALAZA_STAMPAJ}
                tooltip={Labels.PREGLED_NALAZA_STAMPAJ}
                tooltipOptions={tooltipOptionsBottom}
                className="p-button-outlined p-button-tertiary align-self-center"
                onClick={() => {
                  selectedFormularPopunjen?.fileContent ? handlePrint(selectedFormularPopunjen?.fileContent, false) : handlePrint(selectedFormularPopunjen?.tekst, true);
                }}
              />
              <Button
                icon="pi pi-trash"
                className="p-button-outlined nalaz-sablon-button p-button-tertiary ml-2"
                tooltip={Labels.FORMULAR_DELETE_PRINT}
                onClick={() => {
                  deleteDokument(selectedFormularPopunjen);
                }}
                disabled={!pristup}
              />
            </div>
          </div>

          <div className="flex flex-wrap justify-content-center align-items-center">
            <div className="flex flex-wrap w-full">
              {selectedFormularPopunjen?.fileContent ? (
                <div className="flex flex-wrap w-full">
                  <embed src={`data:application/pdf;base64,${base64_decode(selectedFormularPopunjen?.fileContent)}`} type="application/pdf" width="100%" height="600px" />
                </div>
              ) : (
                <iframe ref={iframeRef} onLoad={onLoadIFrame} height={iframeHeight} className="w-full" title={selectedFormularPopunjen?.fileName} srcDoc={selectedFormularPopunjen?.tekst} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
