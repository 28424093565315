import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { Navigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { RolaService } from "../../../../controllers/rola/RolaService";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import EnumIzvestajParametarTip from "../../../../infrastructure/system/EnumIzvestajParametarTip";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import IzvestajParametarEnumStavkaReadDto from "../../../../model/izvestaj/parametar/IzvestajParametarEnumStavkaReadDto";
import SkeletonInputItem from "../../../app/skeleton/SkeletonInputItem";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import CrudIzvestajParametarLogical from "./CrudIzvestajParametarLogical";

export default function CrudIzvestajParametarView() {
  const { isSuperadmin } = RolaService();
  const {
    breadCrumbItems,
    izvestajParametarChange,
    setIzvestajParametarChange,
    setIndex,
    index,
    izvestajParametarId,
    izvestajParametarOperation,
    isDisabled,
    location,
    onUpdate,
    onCancel,
    onDelete,
    onCreate,
    izvestajParametarLoading,
    izvestajTipList,
    onPageFormTable,
    first,
    tableRows,
    parametarEnumStavkaList,
    parametarEnumStavkaLoading,
    tipParametraSifra,
    openParametarEnumStavka,
  } = CrudIzvestajParametarLogical(isSuperadmin);

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const dt = useRef<any>(null);

  if (!izvestajParametarId && !location.state) {
    return <Navigate to="/izvestaj-list" />;
  }

  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.HEADER_SIFRA, filter: true, sortrable: true },
  ];

  const leftContentsToolbar = () => (
    <>
      {izvestajParametarOperation === EntityOperation.CREATE && isSuperadmin && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.IZVESTAJ_PARAMETAR_TITLE_DIALOG_CREATE}
          disabled={!pristup || izvestajParametarLoading}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {izvestajParametarOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DIALOG_UPDATE}
          disabled={!pristup || izvestajParametarLoading}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {izvestajParametarOperation === EntityOperation.DELETE && isSuperadmin && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.IZVESTAJ_PARAMETAR_TITLE_MESSAGE_DELETE_SUCCESS}
          disabled={!pristup || izvestajParametarLoading}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const tipKomponenteSetAndSetRadioList = [EnumIzvestajParametarTip.SET, EnumIzvestajParametarTip.MULTISELECT];

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  const leftButtonEnumTab = () => (
    <>
      {!isDisabled && isSuperadmin && (
        <Button
          className="p-button-success"
          label={Labels.BUTTON_ADD_IZVESTAJ_PARAMETAR_ENUM_STAVKA}
          tooltip={Labels.TOOLTIP_CREATE_IZVESTAJ_PARAMETAR_ENUM_STAVKA}
          tooltipOptions={tooltipOptionsBottom}
          disabled={!pristup || izvestajParametarOperation !== EntityOperation.UPDATE}
          onClick={() => {
            openParametarEnumStavka(EntityOperation.CREATE);
          }}
        />
      )}
    </>
  );

  const buttonListTemplate = (rowParametarEnumStavka: IzvestajParametarEnumStavkaReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.IZVESTAJ_PARAMETAR_ENUM_STAVKA_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          disabled={izvestajParametarOperation !== EntityOperation.UPDATE}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowParametarEnumStavka && openParametarEnumStavka(EntityOperation.UPDATE, rowParametarEnumStavka)}
        />
        {isSuperadmin && (
          <Button
            type="button"
            tooltip={Labels.IZVESTAJ_PARAMETAR_ENUM_STAVKA_TITLE_DIALOG_DELETE}
            tooltipOptions={tooltipOptionsTop}
            disabled={!pristup || izvestajParametarOperation !== EntityOperation.UPDATE}
            className="p-button-danger mr-2"
            icon="pi pi-trash"
            onClick={() => rowParametarEnumStavka && openParametarEnumStavka(EntityOperation.DELETE, rowParametarEnumStavka)}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <Panel>
        <div className="layout-crud-generic-content">
          <BreadCrumb model={breadCrumbItems} />
          <TabView
            className="mt-3"
            renderActiveOnly={false}
            activeIndex={index}
            onTabChange={(e) => {
              setIndex(e.index);
            }}
          >
            <TabPanel header={Labels.LABEL_IZVESTAJ_PARAMETAR}>
              <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8 tab-view">
                <div className="grid align-items-center p-0">
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.IZVESTAJ_PARAMETAR_TIP}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!izvestajParametarLoading ? (
                      <Dropdown
                        optionLabel="naziv"
                        optionValue="sifra"
                        options={izvestajTipList}
                        disabled={!pristup || isDisabled || !isSuperadmin}
                        value={izvestajParametarChange?.izvestajParametarTipEnum?.sifra ?? ""}
                        onChange={(e) => {
                          setIzvestajParametarChange({
                            ...izvestajParametarChange!,
                            izvestajParametarTipEnum: { sifra: e.value },
                          });
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.LABEL_IZVESTAJ_PARAMETAR_NAZIV}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!izvestajParametarLoading ? (
                      <InputText
                        value={izvestajParametarChange?.naziv ?? ""}
                        disabled={!pristup || isDisabled}
                        onChange={(e) => {
                          setIzvestajParametarChange({
                            ...izvestajParametarChange!,
                            naziv: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.LABEL_REQUEST_NAME}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!izvestajParametarLoading ? (
                      <InputText
                        value={izvestajParametarChange?.requestName ?? ""}
                        disabled={!pristup || isDisabled || !isSuperadmin}
                        onChange={(e) => {
                          setIzvestajParametarChange({
                            ...izvestajParametarChange!,
                            requestName: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>{Labels.LABEL_PRIKAZNI_REDOSLED}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!izvestajParametarLoading ? (
                      <InputNumber
                        value={izvestajParametarChange?.prikazniRedosled ?? undefined}
                        disabled={!pristup || isDisabled}
                        onChange={(e) => {
                          setIzvestajParametarChange({
                            ...izvestajParametarChange!,
                            prikazniRedosled: e.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>{Labels.IZVESTAJ_PARAMETAR_SQL_UPIT}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0 mt-2 mb-2">
                    {!izvestajParametarLoading ? (
                      <InputTextarea
                        rows={3}
                        cols={5}
                        value={izvestajParametarChange?.sqlUpit ?? ""}
                        disabled={!pristup || isDisabled || !isSuperadmin}
                        onChange={(e) => {
                          setIzvestajParametarChange({
                            ...izvestajParametarChange!,
                            sqlUpit: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                </div>
              </div>
              <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
            </TabPanel>

            {izvestajParametarOperation === EntityOperation.CREATE ||
            !tipKomponenteSetAndSetRadioList.includes(tipParametraSifra ?? "") ||
            !tipKomponenteSetAndSetRadioList.includes(izvestajParametarChange?.izvestajParametarTipEnum.sifra ?? "") ? null : (
              <TabPanel header={Labels.IZVESTAJ_PARAMETAR_ENUM_STAVKA_LIST}>
                <div className="layout-generic-content-list">
                  <Toolbar left={leftButtonEnumTab} />
                  <div>
                    {parametarEnumStavkaLoading ? (
                      <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={!isDisabled} />
                    ) : (
                      <DataTable
                        ref={dt}
                        filterDisplay="row"
                        value={parametarEnumStavkaList}
                        paginator
                        first={first}
                        paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
                        rows={tableRows}
                        onPage={(e) => {
                          onPageFormTable(e.rows, e.first);
                        }}
                        emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                        rowHover
                        stripedRows
                      >
                        <Column field={"sifra"} header={Labels.HEADER_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                        <Column field={"naziv"} header={Labels.HEADER_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
                        {!isDisabled && <Column body={buttonListTemplate} />}
                      </DataTable>
                    )}
                  </div>
                </div>
              </TabPanel>
            )}
          </TabView>
        </div>
      </Panel>
    </div>
  );
}
