import moment from "moment";
import { Button } from "primereact/button";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Toolbar } from "primereact/toolbar";
import { useContext, useEffect, useMemo, useRef } from "react";
import { AppContext, useLabels } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import EnumIzvestajParametarTip from "../../infrastructure/system/EnumIzvestajParametarTip";
import EnumPeriodGenerisanjaSifra from "../../infrastructure/system/EnumPeriodGenerisanjaSifra";
import EnumPonavljanjeGenerisanjaSifra from "../../infrastructure/system/EnumPonavljanjeGenerisanjaSifra";
import { STEP_MINUTE, getDateDivisibleBy5, handleKeyboardTimeInputChange } from "../../infrastructure/system/Utils";
import actions from "../../infrastructure/system/hooks/generisani-izvestaj-reducer/actionsGenerisaniIzvestaj";
import useFetch from "../../infrastructure/system/hooks/useFetch";
import actionsZakazaniIzvestaj from "../../infrastructure/system/hooks/zakazani-izvestaj-reducer/actionsZakazaniIzvestaj";
import IzvestajParametarReadDto from "../../model/izvestaj/parametar/IzvestajParametarReadDto";
import CalendarComponent from "../administracija/calendar-component/CalendarComponent";
import SkeletonAccordionList from "../app/skeleton/SkeletonAccordionList";
import ObaveznoPolje from "../obavezno-polje/ObaveznoPolje";
import { GenerisaniIzvestajContext } from "./GenerisaniIzvestajLogical";

export default function StatistickiIzvestajParametarInput(props: any) {
  const { periodList, ponavljanjeList, radniDaniList, invalidFields, setInvalidFields } = props;
  const Labels = useLabels();
  const { locale } = useContext(AppContext);
  const { state, dispatch, generisiIzvestaj, zakaziIzvestaj, izmeniIzvestaj, stateZakazani, dispatchZakazani, zakazanIzvestajChange, setZakazanIzvestajChange, addEmail, onCancel, removeEmail } =
    useContext(GenerisaniIzvestajContext);

  const izvestajId = stateZakazani.editSelected ? stateZakazani?.selectedIzvestajForEdit?.statistickiIzvestaj?.id : state?.selectedStatistickiIzvestaj?.id;
  const { data } = useFetch<Array<IzvestajParametarReadDto>>(`${Endpoint.IZVESTAJ_LIST}/${izvestajId}/${Endpoint.IZVESTAJ_PARAMETAR_LIST_END_URL}`);
  const startTime = useMemo(() => (state.selectedStatistickiIzvestaj ? moment(new Date()) : undefined), [state.selectedStatistickiIzvestaj]);
  const datumOdRef = useRef<HTMLDivElement | any>(null);
  const datumDoRef = useRef<HTMLDivElement | any>(null);
  const datumGenerisanjaRef = useRef<HTMLDivElement | any>(null);
  const vremeGenerisanjaRef = useRef<HTMLDivElement | any>(null);

  useEffect(() => {
    if (data) {
      data.forEach((izvestajParametar: IzvestajParametarReadDto) => {
        if (izvestajParametar.izvestajParametarTipEnum.sifra === EnumIzvestajParametarTip.BOOLEAN) {
          dispatch({ type: actions.ADD_PARAM_VALUE, value: { id: izvestajParametar.id, value: false } });
          dispatchZakazani({ type: actionsZakazaniIzvestaj.ADD_PARAM_VALUE, data: { id: izvestajParametar.id, value: false } });
        }
      });
      if (moment(new Date()).diff(startTime) < 500) {
        setTimeout(() => {
          dispatch({ type: actions.PARAMS_LOADED, value: moment(new Date()) });
        }, 500);
      } else {
        dispatch({ type: actions.PARAMS_LOADED, value: moment(new Date()) });
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (stateZakazani.editSelected) {
      const fullVaziOd = stateZakazani?.selectedIzvestajForEdit?.vaziOd && new Date(stateZakazani?.selectedIzvestajForEdit?.vaziOd);
      const fullVaziDo = stateZakazani?.selectedIzvestajForEdit?.vaziOd && new Date(stateZakazani?.selectedIzvestajForEdit?.vaziDo);
      const fullVremeGenerisanja = new Date(stateZakazani?.selectedIzvestajForEdit?.vremeZakazanoJednostruko);
      const fullVremeGenerisanjaVisestruko = new Date(stateZakazani?.selectedIzvestajForEdit?.vremeZakazanoVisestruko);
      const danMesecArray = stateZakazani?.selectedIzvestajForEdit?.danMesec?.split(", ");
      const dani = radniDaniList.filter((dan: any) => stateZakazani?.selectedIzvestajForEdit?.danNedelja?.split(", ").includes(dan.sifra));

      setZakazanIzvestajChange((prev) => ({
        ...prev,
        emails: stateZakazani?.selectedIzvestajForEdit?.zakazanIzvestajEmailList,
        vremeGenerisanja: stateZakazani?.selectedIzvestajForEdit?.vremeZakazanoJednostruko && fullVremeGenerisanja,
        vremeGenerisanjaVisestruko: stateZakazani?.selectedIzvestajForEdit?.vremeZakazanoVisestruko && fullVremeGenerisanjaVisestruko,
        datumGenerisanja: stateZakazani?.selectedIzvestajForEdit?.vremeZakazanoJednostruko && new Date(stateZakazani?.selectedIzvestajForEdit?.vremeZakazanoJednostruko),
        ponavljanjeGenerisanja: stateZakazani.selectedIzvestajForEdit.ponavljanje,
        selectedDaniUNedelji: dani,
        selectedDaniUMesecu: danMesecArray,
        ucestalostPonavljanja: stateZakazani?.selectedIzvestajForEdit?.period,
        datumOd: fullVaziOd,
        datumDo: fullVaziDo,
      }));
    } else {
      onCancel();
    }

    if (stateZakazani.editSelected) {
      stateZakazani.formParams.forEach((element: any) => {
        switch (element.statistickiIzvestajParametar.izvestajParametarTipEnum.sifra) {
          case EnumIzvestajParametarTip.SET:
            changeParamValue(element.statistickiIzvestajParametar.id, element.vrednostSet);
            break;
          case EnumIzvestajParametarTip.TEXT:
            changeParamValue(element.statistickiIzvestajParametar.id, element.vrednostText);
            break;
          case EnumIzvestajParametarTip.NUMBER:
            changeParamValue(element.statistickiIzvestajParametar.id, element.vrednostNumber);
            break;
          case EnumIzvestajParametarTip.BOOLEAN:
            changeParamValue(element.statistickiIzvestajParametar.id, element.vrednostBoolean);
            break;
          case EnumIzvestajParametarTip.DATE:
            changeParamValue(element.statistickiIzvestajParametar.id, new Date(element.vrednostDate));
            break;
          case EnumIzvestajParametarTip.MULTISELECT:
            changeParamValue(element.statistickiIzvestajParametar.id, element.statistickiIzvestajParametarEnumStavkaList);
            break;
          default:
            break;
        }
      });
    }

    // eslint-disable-next-line
  }, [stateZakazani.editSelected]);

  const changeParamValue = (id: number, value: any) => {
    dispatch({ type: actions.ADD_PARAM_VALUE, value: { id, value } });
    dispatchZakazani({ type: actionsZakazaniIzvestaj.ADD_PARAM_VALUE, data: { id, value } });
  };

  const createInputForType = (izvestajParametar: IzvestajParametarReadDto) => {
    switch (izvestajParametar.izvestajParametarTipEnum.sifra) {
      case EnumIzvestajParametarTip.TEXT:
        return (
          <InputText
            id={izvestajParametar.naziv + "_" + izvestajParametar.id}
            className="w-full"
            onChange={(e) => {
              changeParamValue(izvestajParametar.id, e.target.value);
            }}
            value={stateZakazani?.paramsValue && stateZakazani?.paramsValue[izvestajParametar.id]}
          />
        );
      case EnumIzvestajParametarTip.BOOLEAN:
        return (
          <Checkbox
            checked={stateZakazani?.paramsValue && stateZakazani?.paramsValue[izvestajParametar?.id]}
            onChange={(e) => {
              changeParamValue(izvestajParametar.id, e.checked);
            }}
          />
        );
      case EnumIzvestajParametarTip.DATE:
        return (
          <Calendar
            onChange={(e) => {
              changeParamValue(izvestajParametar.id, e.value);
            }}
            value={stateZakazani?.paramsValue && new Date(stateZakazani?.paramsValue[izvestajParametar.id])}
            locale={locale === "SR_RS" ? "sr-cyrilic" : "sr"}
          />
        );
      case EnumIzvestajParametarTip.NUMBER:
        return (
          <InputNumber
            id={izvestajParametar.naziv + "_" + izvestajParametar.id}
            className="w-full"
            onChange={(e) => {
              changeParamValue(izvestajParametar.id, e.value);
            }}
            value={stateZakazani?.paramsValue && stateZakazani?.paramsValue[izvestajParametar.id]}
          />
        );
      case EnumIzvestajParametarTip.SET:
        return (
          <Dropdown
            className="w-full"
            options={izvestajParametar.statistickiIzvestajParametarEnumStavkaList}
            optionLabel="naziv"
            onChange={(e) => {
              changeParamValue(izvestajParametar.id, e.value);
            }}
            value={stateZakazani?.paramsValue && stateZakazani?.paramsValue[izvestajParametar.id]}
          />
        );
      case EnumIzvestajParametarTip.MULTISELECT:
        return (
          <MultiSelect
            className="w-full"
            options={izvestajParametar.statistickiIzvestajParametarEnumStavkaList}
            optionLabel="naziv"
            onChange={(e) => {
              changeParamValue(izvestajParametar.id, e.value);
            }}
            value={stateZakazani?.paramsValue && stateZakazani.paramsValue[izvestajParametar.id]}
          />
        );
    }
  };

  const generisiIzvestajButtons = (
    <div>
      {(zakazanIzvestajChange.ponavljanjeGenerisanja ||
        zakazanIzvestajChange.emailValue.length > 0 ||
        zakazanIzvestajChange.emails.length > 0 ||
        zakazanIzvestajChange.datumGenerisanja ||
        zakazanIzvestajChange.vremeGenerisanja ||
        zakazanIzvestajChange.vremeGenerisanjaVisestruko ||
        zakazanIzvestajChange.selectedDaniUMesecu?.length > 0 ||
        zakazanIzvestajChange.selectedDaniUNedelji?.length > 0 ||
        stateZakazani?.editSelected) && (
        <Button
          className="p-button-outlined mr-4"
          label={Labels.BUTTON_CANCEL}
          icon="pi pi-times"
          disabled={false}
          onClick={() => {
            onCancel();
            dispatchZakazani({ type: actionsZakazaniIzvestaj.CANCEL_EDIT, data: undefined });
          }}
        />
      )}
      {!zakazanIzvestajChange.zakazanChecked && !stateZakazani?.editSelected && <Button label={Labels.BUTTON_GENERISI_IZVESTAJ} icon="pi pi-file" disabled={false} onClick={generisiIzvestaj} />}
      {zakazanIzvestajChange.zakazanChecked && !stateZakazani?.editSelected && (
        <Button
          label={Labels.BUTTON_ZAKAZI_IZVESTAJ}
          icon="pi pi-file"
          disabled={false}
          onClick={() => {
            zakaziIzvestaj(stateZakazani.selectedIzvestaj, zakazanIzvestajChange);
          }}
        />
      )}
      {stateZakazani?.editSelected && (
        <Button
          label={Labels.BUTTON_IZMENI_IZVESTAJ}
          icon="pi pi-file"
          disabled={false}
          onClick={() => {
            izmeniIzvestaj(stateZakazani.selectedIzvestajForEdit.id, stateZakazani.selectedIzvestajForEdit.statistickiIzvestaj, zakazanIzvestajChange);
          }}
        />
      )}
    </div>
  );

  const zakazivanjeZaBuducnostPanel = useMemo(() => {
    return (
      <div className="col-8 p-0 border-bottom-1 border-primary pb-3 mt-5">
        {!stateZakazani.editSelected && (
          <div className="grid justify-between align-center ">
            <div className="col-4 flex align-items-center">
              <label>{Labels.ZAKAZIVANJE_ZA_BUDUCNOST}</label>
            </div>
            <div className="col-8">
              <InputSwitch checked={zakazanIzvestajChange.zakazanChecked ?? false} onChange={(e) => setZakazanIzvestajChange((prev) => ({ ...prev, zakazanChecked: e.value ?? false }))} />
            </div>
          </div>
        )}
        {(zakazanIzvestajChange.zakazanChecked || stateZakazani.editSelected) && (
          <div className="grid justify-between align-center ">
            <div className="col-4 flex align-items-center">
              <label>{Labels.RADNIK_EMAIL}</label>
            </div>
            <div className="col-8 flex">
              <div className="flex flex-auto flex-column">
                <InputText
                  className={"mr-3 " + ((invalidFields?.invalidEmail || invalidFields?.emailExists) && "p-invalid")}
                  value={zakazanIzvestajChange.emailValue}
                  onChange={(e) => {
                    setZakazanIzvestajChange((prev) => ({ ...prev, emailValue: e.target.value }));
                    setInvalidFields((prev: any) => ({ ...prev, invalidEmail: false, emailExists: false }));
                  }}
                />
                {invalidFields?.invalidEmail && <ObaveznoPolje text={Labels.EMAIL_WRONG_FORMAT} />}
                {invalidFields?.emailExists && <ObaveznoPolje text={Labels.GEN_IZVESTAJ_EMAIL_POSTOJI} />}
              </div>
              <div>
                <Button icon="pi pi-plus" disabled={zakazanIzvestajChange.emailValue.length === 0} onClick={addEmail} />
              </div>
            </div>
            <div className="col-8 flex align-items-center flex-wrap ml-auto pt-0">
              {zakazanIzvestajChange.emails.map((email: string) => (
                <Chip label={email} key={email} className="mr-2 mb-2 email-chip" removable onRemove={() => removeEmail(email)} />
              ))}
            </div>
          </div>
        )}
      </div>
    );
    // eslint-disable-next-line
  }, [zakazanIzvestajChange.zakazanChecked, zakazanIzvestajChange.emailValue, zakazanIzvestajChange.emails, stateZakazani.editSelected, invalidFields]);

  const zakazivanjeZaBuducnostInputs = useMemo(() => {
    return (
      <div className="col-8 p-0 border-primary pb-3 mt-5">
        <div className="grid justify-between align-center ">
          <div className="col-4 flex align-items-center">
            <label>{Labels.PONAVLJANJE_GENERISANJA_IZVESTAJI}</label>
          </div>
          <div className="col-8">
            <Dropdown
              className={"w-full " + (invalidFields?.ponavljanjeGenerisanja && "p-invalid")}
              options={ponavljanjeList}
              optionLabel="naziv"
              value={zakazanIzvestajChange.ponavljanjeGenerisanja}
              onChange={(e) => {
                setZakazanIzvestajChange((prev) => ({ ...prev, ponavljanjeGenerisanja: e.value }));
                setInvalidFields((prev: any) => ({ ...prev, ponavljanjeGenerisanja: false }));
              }}
            />
            {invalidFields?.ponavljanjeGenerisanja && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
          </div>
        </div>
        {zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && (
          <div className="grid justify-between align-center ">
            <div className="col-4 flex align-items-center">
              <label>{Labels.VAZI_OD}</label>
            </div>
            <div className="col-8">
              <CalendarComponent
                ref={datumOdRef}
                showButtonBar={false}
                name="datumOd"
                value={zakazanIzvestajChange.datumOd}
                onChange={(e: CalendarChangeEvent) => {
                  setZakazanIzvestajChange((prev) => ({ ...prev, datumOd: e.value as Date, datumDo: undefined }));
                  setInvalidFields((prev: any) => ({ ...prev, datumOdVisestruko: false, invalidDatum: false }));
                  if (e.target.value) datumOdRef?.current?.hide();
                }}
                className={(invalidFields?.invalidDatum || invalidFields?.datumOdVisestruko) && "p-invalid"}
                minDate={new Date()}
                placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
              />
              {invalidFields?.datumOdVisestruko && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
          </div>
        )}
        {zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && (
          <div className="grid justify-between align-center ">
            <div className="col-4 flex align-items-center">
              <label>{Labels.VAZI_DO}</label>
            </div>
            <div className="col-8">
              <CalendarComponent
                ref={datumDoRef}
                showButtonBar={false}
                name="datumDo"
                value={zakazanIzvestajChange.datumDo}
                onChange={(e: CalendarChangeEvent) => {
                  setZakazanIzvestajChange((prev) => ({ ...prev, datumDo: e.value as Date }));
                  setInvalidFields((prev: any) => ({ ...prev, datumDoVisestruko: false, invalidDatum: false }));
                  if (e.target.value) datumDoRef?.current?.hide();
                }}
                className={(invalidFields?.invalidDatum || invalidFields?.datumDoVisestruko) && "p-invalid"}
                minDate={new Date()}
                placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
              />
              {invalidFields?.datumDoVisestruko && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              {invalidFields?.invalidDatum && <ObaveznoPolje text={Labels.ZAKAZIVANJE_IZVESTAJA_POGRESNO_VREME} />}
            </div>
          </div>
        )}
        {zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO && (
          <div className="grid justify-between align-center ">
            <div className="col-4 flex align-items-center">
              <label>{Labels.DATUM_GENERISANJA_IZVESTAJA}</label>
            </div>
            <div className="col-8">
              <CalendarComponent
                ref={datumGenerisanjaRef}
                showButtonBar={false}
                name="datumGenerisanja"
                value={zakazanIzvestajChange.datumGenerisanja}
                onChange={(e: CalendarChangeEvent) => {
                  setZakazanIzvestajChange((prev) => ({ ...prev, datumGenerisanja: e.value as Date }));
                  setInvalidFields((prev: any) => ({ ...prev, datumJednostruko: false }));
                  if (e.target.value) datumGenerisanjaRef?.current?.hide();
                }}
                className={invalidFields?.datumJednostruko && "p-invalid"}
                minDate={new Date()}
                placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
              />
              {invalidFields?.datumJednostruko && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
          </div>
        )}
        {zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && (
          <div className="grid justify-between align-center ">
            <div className="col-4 flex align-items-center">
              <label>{Labels.UCESTALOST_PONAVLJANJA}</label>
            </div>
            <div className="col-8">
              <Dropdown
                className={"w-full " + (invalidFields?.ucestalost && "p-invalid")}
                options={periodList}
                optionLabel="naziv"
                value={zakazanIzvestajChange.ucestalostPonavljanja}
                onChange={(e) => {
                  setZakazanIzvestajChange((prev) => ({ ...prev, ucestalostPonavljanja: e.value }));
                  setInvalidFields((prev: any) => ({ ...prev, ucestalost: false }));
                }}
              />
              {invalidFields?.ucestalost && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
          </div>
        )}
        {zakazanIzvestajChange.ucestalostPonavljanja?.sifra === EnumPeriodGenerisanjaSifra.NEDELJA &&
          zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && (
            <div className="grid justify-between align-center ">
              <div className="col-4 flex align-items-center">
                <label>{Labels.IZVESTAJ_SLATI}</label>
              </div>
              <div className="col-8">
                <MultiSelect
                  className="w-full"
                  value={zakazanIzvestajChange.selectedDaniUNedelji}
                  optionLabel="naziv"
                  options={radniDaniList}
                  onChange={(e) => {
                    setZakazanIzvestajChange((prev) => ({ ...prev, selectedDaniUNedelji: e.value }));
                  }}
                />
              </div>
            </div>
          )}
        {zakazanIzvestajChange.ucestalostPonavljanja?.sifra === EnumPeriodGenerisanjaSifra.MESEC &&
          zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.VISESTRUKO && (
            <div className="grid justify-between align-center ">
              <div className="col-4 flex align-items-center">
                <label>{Labels.DAN_U_MESECU}</label>
              </div>
              <div className="col-8">
                <MultiSelect
                  className="w-full"
                  value={zakazanIzvestajChange.selectedDaniUMesecu}
                  options={Array.from({ length: 31 }, (_, i) => i + 1).map(String)}
                  onChange={(e: MultiSelectChangeEvent) => {
                    setZakazanIzvestajChange((prev) => ({ ...prev, selectedDaniUMesecu: e.value }));
                  }}
                />
              </div>
            </div>
          )}
        <div className="grid justify-between align-center ">
          <div className="col-4 flex align-items-center">
            <label>{Labels.VREME_GENERISANJA_IZVESTAJA}</label>
          </div>
          <div className="col-8">
            <CalendarComponent
              ref={vremeGenerisanjaRef}
              timeOnly
              name="vremeGenerisanja"
              viewDate={
                zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO
                  ? getDateDivisibleBy5(zakazanIzvestajChange.vremeGenerisanja)
                  : getDateDivisibleBy5(zakazanIzvestajChange.vremeGenerisanjaVisestruko)
              }
              value={
                zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO
                  ? zakazanIzvestajChange.vremeGenerisanja
                  : zakazanIzvestajChange.vremeGenerisanjaVisestruko
              }
              onChange={(e: CalendarChangeEvent) => {
                if (zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO) {
                  setZakazanIzvestajChange((prev) => ({ ...prev, vremeGenerisanja: e.value ? getDateDivisibleBy5(e.value as Date) : undefined }));
                } else {
                  setZakazanIzvestajChange((prev) => ({ ...prev, vremeGenerisanjaVisestruko: e.value ? getDateDivisibleBy5(e.value as Date) : undefined }));
                }
                setInvalidFields((prev: any) => ({ ...prev, vreme: false }));
              }}
              onInput={(e) => {
                if (zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO) {
                  handleKeyboardTimeInputChange(e.currentTarget.value, "vremeGenerisanja", zakazanIzvestajChange, setZakazanIzvestajChange, vremeGenerisanjaRef, undefined, undefined, false);
                } else {
                  handleKeyboardTimeInputChange(e.currentTarget.value, "vremeGenerisanjaVisestruko", zakazanIzvestajChange, setZakazanIzvestajChange, vremeGenerisanjaRef, undefined, undefined, false);
                }
              }}
              stepMinute={STEP_MINUTE}
              icon="pi pi-clock"
              onTodayButtonClick={() => {
                if (zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO) {
                  setZakazanIzvestajChange((prev) => ({ ...prev, vremeGenerisanja: getDateDivisibleBy5(new Date()) }));
                } else {
                  setZakazanIzvestajChange((prev) => ({ ...prev, vremeGenerisanjaVisestruko: getDateDivisibleBy5(new Date()) }));
                }
                setInvalidFields((prev: any) => ({ ...prev, vreme: false }));
              }}
              onClearButtonClick={() => {
                if (zakazanIzvestajChange.ponavljanjeGenerisanja?.sifra === EnumPonavljanjeGenerisanjaSifra.JEDNOSTRUKO) {
                  setZakazanIzvestajChange((prev) => ({ ...prev, vremeGenerisanja: undefined }));
                } else {
                  setZakazanIzvestajChange((prev) => ({ ...prev, vremeGenerisanjaVisestruko: undefined }));
                }
                setInvalidFields((prev: any) => ({ ...prev, vreme: true }));
              }}
              className={invalidFields?.vreme && "p-invalid"}
              placeholder={Labels.PLACEHOLDER_VREME_FORMAT}
            />
            {invalidFields?.vreme && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line
  }, [
    zakazanIzvestajChange.ponavljanjeGenerisanja,
    zakazanIzvestajChange.ucestalostPonavljanja,
    zakazanIzvestajChange.selectedDaniUNedelji,
    zakazanIzvestajChange.selectedDaniUMesecu,
    zakazanIzvestajChange.vremeGenerisanja,
    zakazanIzvestajChange.vremeGenerisanjaVisestruko,
    zakazanIzvestajChange.datumGenerisanja,
    invalidFields,
  ]);

  if (state.paramsLoading)
    return (
      <div className="col-12 p-0 border-bottom-1 pb-3 border-primary md:col-8">
        <SkeletonAccordionList />
        <SkeletonAccordionList />
        <SkeletonAccordionList />
      </div>
    );

  if (data?.length === 0)
    return (
      <>
        <div className="col-12 p-0 border-bottom-1 pb-3 border-primary md:col-8">{Labels.NO_IZVESTAJ_PARAMETRI}</div>
        {zakazivanjeZaBuducnostPanel}
        {(zakazanIzvestajChange.zakazanChecked || stateZakazani.editSelected) && zakazivanjeZaBuducnostInputs}
        <Toolbar className="col-8 pr-0 my-6 border-none bg-white" right={generisiIzvestajButtons} />
      </>
    );

  return (
    <>
      <div className="col-12 p-0 border-bottom-1 pb-3 border-primary md:col-8">
        {data?.map((izvestajParametar: IzvestajParametarReadDto) => (
          <div className="grid justify-between align-center" key={izvestajParametar.id}>
            <div className="col-4 flex align-items-center">{izvestajParametar.naziv}</div>
            <div className="col-8">{createInputForType(izvestajParametar)}</div>
          </div>
        ))}
      </div>
      {zakazivanjeZaBuducnostPanel}
      {(zakazanIzvestajChange.zakazanChecked || stateZakazani.editSelected) && zakazivanjeZaBuducnostInputs}
      <Toolbar className="col-8 pr-0 my-6 border-none bg-white" right={generisiIzvestajButtons} />
    </>
  );
}
