import axios from "axios";
import moment from "moment";
import { FileUploadHandlerEvent } from "primereact/fileupload";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../../Store";
import { EnumController } from "../../../../controllers/enum/EnumController";
import ProtokolControler from "../../../../controllers/protokol/ProtokolController";
import { UstanovaController } from "../../../../controllers/ustanova/UstanovaController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../infrastructure/system/MessageType";
import RadnoVremeUtils from "../../../../infrastructure/system/RadnoVremeUtils";
import { MAX_FILE_UPLOAD, handleAxiosCallError, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import ProtokolReadDto from "../../../../model/protokol/ProtokolReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import EnumPodrazumevanaStranaDto from "../../../../model/sifarnik/EnumPodrazumevanaStranaDto";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";

interface UstanovaCrudLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  ustanovaOperation: string;
  isDisabled: boolean;
  onUpdate: () => void;
  onCancel: () => void;
  ustanovaChange: UstanovaReadDto | undefined;
  setUstanovaChange: any;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  jezikList: Array<EnumBaseReadDto>;
  svojinaTipList: Array<EnumBaseReadDto>;
  nivoZdravstveneZastiteList: Array<EnumBaseReadDto>;
  podrazumevanaStranaList: Array<EnumPodrazumevanaStranaDto>;
  ustanovaLoading: boolean;
  getRadnoVremeValue: (radnoVreme: string | undefined) => Date | undefined;
  protokolList: Array<ProtokolReadDto>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  logoUploadHandler: (event: FileUploadHandlerEvent) => void;
  vremeOdRef: HTMLDivElement | any;
  vremeDoRef: HTMLDivElement | any;
}

export default function CrudUstanovaAdminLogical(): UstanovaCrudLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [ustanovaOperation] = useState<string>(EntityOperation.UPDATE);
  const [tabIndex] = useState<number>(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const Labels = useLabels();
  //TODO ne radi nista, obrisati
  const [isDisabled] = useState<boolean>(false); //Ko zna zasta se ovo koristilo, pre je proveravalo da li je ustanovaID dato, a bez nje ne moze ni load ni nista
  const [ustanovaChange, setUstanovaChange] = useState<UstanovaReadDto | undefined>();
  const [index, setIndex] = useState<number>(tabIndex);
  const { axiosUpdateUstanova, axiosReadUstanova } = UstanovaController();
  const { axiosGetSvojinaTipList, axiosGetNivoZdravstveneZastiteList, axiosGetJezikList, axiosGetPodrazumevanaStranaList } = EnumController();
  const { axiosSearchProtokol } = ProtokolControler();
  const [jezikList, setJezikList] = useState<Array<EnumBaseReadDto>>([]);
  const [svojinaTipList, setSvojinaTipList] = useState<Array<EnumBaseReadDto>>([]);
  const [nivoZdravstveneZastiteList, setNivoZdravstveneZastiteList] = useState<Array<EnumBaseReadDto>>([]);
  const [ustanovaLoading, setUstanovaLoading] = useState<boolean>(true);
  const [podrazumevanaStranaList, setPodrazumevanaStranaList] = useState<Array<EnumPodrazumevanaStranaDto>>([]);
  const { getRadnoVremeValue } = RadnoVremeUtils();
  const [protokolList, setProtokolList] = useState<Array<ProtokolReadDto>>([]);
  const vremeOdRef = useRef<HTMLDivElement | any>(null);
  const vremeDoRef = useRef<HTMLDivElement | any>(null);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);

  const postLogHighLevel = useLogHighLevel();
  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.USTANOVA,
    },
  ];

  const getEnumList = () => {
    const requestSvojinaTipList = axiosGetSvojinaTipList();
    const requestJezikList = axiosGetJezikList();
    const requestNivoZdravstveneZastiteList = axiosGetNivoZdravstveneZastiteList();
    const requestPodrazumevanaStranaList = axiosGetPodrazumevanaStranaList();
    axios
      .all([requestSvojinaTipList, requestJezikList, requestNivoZdravstveneZastiteList, requestPodrazumevanaStranaList])
      .then(
        axios.spread((responseSvojinaTipList: any, responseJezikList: any, responseNivoZdravstveneZastiteList: any, responsePodrazumevanaStranaList: any) => {
          setSvojinaTipList(responseSvojinaTipList.data.data);
          setJezikList(responseJezikList.data.data);
          setNivoZdravstveneZastiteList(responseNivoZdravstveneZastiteList.data.data);
          setPodrazumevanaStranaList(responsePodrazumevanaStranaList.data.data);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const getProtokolList = () => {
    axiosSearchProtokol()
      .then((response: any) => {
        setProtokolList(response.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  useEffectOnce(() => {
    getEnumList();
    getProtokolList();
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setUstanovaLoading(true);
    const requestUstanova = axiosReadUstanova();
    axios
      .all([requestUstanova])
      .then(
        axios.spread((responseUstanova: any) => {
          setUstanovaChange(responseUstanova.data.data);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setUstanovaLoading, startTime);
      });
  };

  const validateInput = (ustanova: UstanovaReadDto | undefined) => {
    let isInvalid = false;
    if (validateStringEmpty(ustanova?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(ustanova?.nazivKratki)) {
      setInvalidFields((prev) => ({ ...prev, nazivKratki: true }));
      isInvalid = true;
    }
    if (!ustanova?.jezik) {
      setInvalidFields((prev) => ({ ...prev, jezik: true }));
      isInvalid = true;
    }
    if (!ustanova?.svojinaTip) {
      setInvalidFields((prev) => ({ ...prev, svojinaTip: true }));
      isInvalid = true;
    }
    if (!ustanova?.nivoZdravstveneZastite) {
      setInvalidFields((prev) => ({ ...prev, nivoZastite: true }));
      isInvalid = true;
    }
    if (!ustanova?.podrazumevanaStrana) {
      setInvalidFields((prev) => ({ ...prev, podrazumevanaStrana: true }));
      isInvalid = true;
    }
    if (!ustanova?.valuta) {
      setInvalidFields((prev) => ({ ...prev, valuta: true }));
      isInvalid = true;
    }
    if (!ustanova?.radnoVremeOd) {
      setInvalidFields((prev) => ({ ...prev, radnoVremeOd: true }));
      isInvalid = true;
    }
    if (!ustanova?.radnoVremeDo) {
      setInvalidFields((prev) => ({ ...prev, radnoVremeDo: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onUpdate = () => {
    if (!validateInput(ustanovaChange)) {
      return;
    }
    if (ustanovaChange) {
      setShowBlockUI(true);
      axiosUpdateUstanova(ustanovaChange)
        .then((res) => {
          showMessage(MessageType.SUCCESS, Labels.USTANOVA_TITLE_MESSAGE_UPDATE_USTANOVA_SUCCESS);

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_1 +
              ustanovaChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_2 +
              ustanovaChange?.nazivKratki +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_3 +
              res.data.data.jezik.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_4 +
              res.data.data.svojinaTip.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_5 +
              res.data.data.nivoZdravstveneZastite.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_USTANOVA_ADMIN_6 +
              res.data.data.podrazumevanaStrana.naziv +
              "."
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCancel = () => {
    navigate(`/`);
  };

  const logoUploadHandler = (e: FileUploadHandlerEvent) => {
    e.files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > MAX_FILE_UPLOAD) {
          showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE);
          return;
        }
        if (reader.result) {
          setUstanovaChange({
            ...ustanovaChange!,
            logo: reader.result?.toString().substr(reader.result?.toString().indexOf(",") + 1)!,
          });
        }
      };
    });
  };

  return {
    breadCrumbItems,
    ustanovaOperation,
    isDisabled,
    ustanovaChange,
    setUstanovaChange,
    onUpdate,
    onCancel,
    setIndex,
    index,
    jezikList,
    svojinaTipList,
    nivoZdravstveneZastiteList,
    podrazumevanaStranaList,
    ustanovaLoading,
    getRadnoVremeValue,
    protokolList,
    invalidFields,
    setInvalidFields,
    logoUploadHandler,
    vremeOdRef,
    vremeDoRef,
  };
}
