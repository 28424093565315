import { useQuery } from "@tanstack/react-query";
import RacunController from "../../../controllers/naplata/RacunController";
import QueryKeys from "../../../infrastructure/system/QueryKeys";

export function useUslugaCena(uslugaID: number, datumPruzanja: string, partnerID?: number) {
  const { axiosGetUslugaCena } = RacunController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.USLUGA_CENA_LIST, uslugaID, datumPruzanja, partnerID],
    queryFn: () => axiosGetUslugaCena(uslugaID, { datumPruzanja, partnerID }),
  });

  return { uslugaCena: data?.data?.data, isLoading };
}
