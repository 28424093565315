import { Skeleton } from "primereact/skeleton";

interface SkeletonRectangleListInRowType {
  size?: number;
}

export default function SkeletonRectangleListInRow(props: SkeletonRectangleListInRowType) {
  const { size } = props;

  return (
    <div className="flex flex-wrap col-12 justify-content-between p-0 mb-2">
      {[...Array(size ?? 1).keys()].map((j: number) => (
        <Skeleton key={j} height="30px" className="flex flex-wrap col mx-2" />
      ))}
    </div>
  );
}
