import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CrudPacijentPoljeKategorijaLogical from "./CrudPacijentPoljeKategorijaLogical";

export default function CrudPacijentPoljeKategorija() {
  const {
    pacijentPoljeKategorijaChange,
    setPacijentPoljeKategorijaChange,
    setIndex,
    index,
    isDisabled,
    breadCrumbItems,
    pacijentPoljeKategorijaOperation,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    kategorijaList,
    pacijentPoljeKategorijaLoading,
    kategorijaOptionsLoading,
    invalidFields,
    setInvalidFields,
  } = CrudPacijentPoljeKategorijaLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const leftContentsToolbar = () => (
    <>
      {pacijentPoljeKategorijaOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_CREATE}
          disabled={!pristup || kategorijaOptionsLoading}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {pacijentPoljeKategorijaOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_UPDATE}
          disabled={!pristup || pacijentPoljeKategorijaLoading || kategorijaOptionsLoading}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {pacijentPoljeKategorijaOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.PACIJENT_POLJE_KATEGORIJA_TITLE_DIALOG_DELETE}
          disabled={!pristup || pacijentPoljeKategorijaLoading || kategorijaOptionsLoading}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div>
      <Panel>
        <div className="layout-crud-generic-content">
          <BreadCrumb model={breadCrumbItems} />
          <TabView
            className="mt-3"
            renderActiveOnly={false}
            activeIndex={index}
            onTabChange={(e) => {
              setIndex(e.index);
            }}
          >
            <TabPanel header={Labels.LABEL_PACIJENT_POLJE_KATEGORIJA}>
              <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
                <div className="grid align-items-center p-0">
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.PACIJENT_POLJE_KATEGORIJA_KATEGORIJA}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!pacijentPoljeKategorijaLoading && !kategorijaOptionsLoading ? (
                      <>
                        <Dropdown
                          disabled={!pristup || isDisabled}
                          optionLabel="naziv"
                          optionValue="sifra"
                          options={kategorijaList}
                          value={pacijentPoljeKategorijaChange?.kategorija?.sifra ?? ""}
                          filter
                          filterBy="naziv"
                          onChange={(e) => {
                            setPacijentPoljeKategorijaChange({
                              ...pacijentPoljeKategorijaChange!,
                              kategorija: { sifra: e.value },
                            });
                            setInvalidFields((prev) => ({ ...prev, kategorija: false }));
                          }}
                          className={invalidFields?.kategorija && "p-invalid"}
                          emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                          emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        />
                        {invalidFields?.kategorija && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      </>
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.PACIJENT_POLJE_KATEGORIJA_OBAVEZNO}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!pacijentPoljeKategorijaLoading ? (
                      <InputSwitch
                        disabled={!pristup || isDisabled}
                        checked={pacijentPoljeKategorijaChange?.obavezno ?? false}
                        onChange={(e) => {
                          setPacijentPoljeKategorijaChange({
                            ...pacijentPoljeKategorijaChange!,
                            obavezno: e.target.value,
                            readOnly: e.target.value ? false : pacijentPoljeKategorijaChange?.readOnly,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.PACIJENT_POLJE_KATEGORIJA_READONLY}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!pacijentPoljeKategorijaLoading ? (
                      <InputSwitch
                        disabled={!pristup || isDisabled}
                        checked={pacijentPoljeKategorijaChange?.readOnly ?? false}
                        onChange={(e) => {
                          setPacijentPoljeKategorijaChange({
                            ...pacijentPoljeKategorijaChange!,
                            readOnly: e.target.value,
                            obavezno: e.target.value ? false : pacijentPoljeKategorijaChange?.obavezno,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                </div>
              </div>
              <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
            </TabPanel>
          </TabView>
        </div>
      </Panel>
    </div>
  );
}
