import { Button } from "primereact/button";
import { useLabels } from "../../Store";
import DialogComponent from "../dialog-component/DialogComponent";

interface DialogDeletePorukaProps {
  isOpenDeleteDialog: boolean;
  closeDeleteDialog: () => void;
  onDelete: () => void;
}

export default function DialogDeletePoruka(props: DialogDeletePorukaProps) {
  const { isOpenDeleteDialog, closeDeleteDialog, onDelete } = props;
  const Labels = useLabels();

  const content = () => {
    return <p className="text-2xl font-semibold">{Labels.PORUKA_DELETE_DIALOG_TEXT}</p>;
  };

  const footer = () => {
    return (
      <div className="flex flex-row justify-content-end px-1">
        <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button p-button-outlined mr-6" onClick={closeDeleteDialog} />
        <Button
          label={Labels.BUTTON_DELETE}
          icon="pi pi-trash"
          className=""
          onClick={() => {
            onDelete();
          }}
        />
      </div>
    );
  };

  return <DialogComponent header="" visible={isOpenDeleteDialog} onHide={closeDeleteDialog} className="w-8 sm:w-8 md:w-4" footer={footer} blockScroll={true} content={<>{content()}</>} />;
}
