import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import { IzvozController } from "../../../controllers/izvoz/IzvozController";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, validateStringEmpty } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import IzvozCreateDto from "../../../model/izvoz/IzvozCreateDto";

interface ExportCrudLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  onCancel: () => void;
  onCreate: () => void;
  izvozCreate: IzvozCreateDto | undefined;
  setIzvozCreate: any;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  datumOdRef: HTMLDivElement | any;
  datumDoRef: HTMLDivElement | any;
}

export default function CrudExportLogical(): ExportCrudLogicalType {
  const Labels = useLabels();
  const navigate = useNavigate();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const [izvozCreate, setIzvozCreate] = useState<IzvozCreateDto>();
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const datumOdRef = useRef<HTMLDivElement | any>(null);
  const datumDoRef = useRef<HTMLDivElement | any>(null);
  const { axiosPostKreiranjeIzvoza } = IzvozController();
  const postLogHighLevel = useLogHighLevel();
  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.IZVOZ,
      command: () => {
        navigate("/izvoz-list");
      },
    },
    {
      label: Labels.IZVOZ_TITLE_DIALOG_CREATE,
    },
  ];

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/izvoz-list`);
    }
  };

  const validateInput = (izvoz: IzvozCreateDto | undefined) => {
    let isInvalid = false;
    if (izvoz?.periodOd === undefined || validateStringEmpty(izvoz?.periodOd.toString())) {
      setInvalidFields((prev) => ({ ...prev, periodOd: true }));
      isInvalid = true;
    }
    if (izvoz?.periodDo === undefined || validateStringEmpty(izvoz?.periodDo.toString())) {
      setInvalidFields((prev) => ({ ...prev, periodDo: true }));
      isInvalid = true;
    }
    if (izvoz?.periodDo !== undefined && izvoz.periodOd > izvoz.periodDo) {
      setInvalidFields((prev) => ({ ...prev, invalidPeriod: true }));
      isInvalid = true;
    }
    return !isInvalid;
  };

  const onCreate = () => {
    if (!validateInput(izvozCreate)) {
      return;
    }

    if (izvozCreate) {
      setShowBlockUI(true);
      axiosPostKreiranjeIzvoza(izvozCreate)
        .then(({ data: { data } }: { data: { data: IzvozCreateDto } }) => {
          // Zapisuje se kreiranje izvoza u log high level-u
          postLogHighLevel(Labels.LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_1 + izvozCreate?.periodOd + Labels.LOG_HIGH_LEVEL_MESS_IZVOZ_CREATE_2 + izvozCreate?.periodDo);

          showMessage(MessageType.SUCCESS, Labels.IZVOZ_DIALOG_MESSAGE_CREATE_IZVOZ_SUCCESS);
          navigate(`/izvoz-list`);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  return {
    breadCrumbItems,
    onCancel,
    onCreate,
    izvozCreate,
    setIzvozCreate,
    invalidFields,
    setInvalidFields,
    datumOdRef,
    datumDoRef,
  };
}
