import moment from "moment";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../Store";
import EnumPacijentInfoPrikaz from "../../infrastructure/system/EnumPacijentInfoPrikaz";
import KontrolaPravaPristupaService from "../../infrastructure/system/KontrolaPravaPristupaService";
import { DATE_TIME_FORMAT, tooltipOptionsBottom } from "../../infrastructure/system/Utils";
import ConfirmComponent from "../confirm-component/ConfirmComponent";
import FormularLeaveDialog from "../formular/formular-leave-dialog/FormularLeaveDialog";
import PacijentInfo from "../pacijent-dosije/info/PacijentInfo";
import OpstiMedicinskiPodaci from "../pacijent-dosije/opste-medicinski-podaci/OpstiMedicinskiPodaci";
import PacijentRizik from "../pacijent-dosije/rizik/PacijentRizik";
import KontaktDatotekaList from "./KontaktDatotekaListView";
import KontaktLogical from "./KontaktLogical";
import KontaktDijagnozaList from "./dokumentacija/dijagnoze/KontaktDijagnozaList";
import KontaktPaketList from "./dokumentacija/paketi/KontaktPaketList";
import KontaktTerapijeList from "./dokumentacija/terapije/KontaktTerapijeList";
import KontaktUslugaList from "./dokumentacija/usluge/KontaktUslugaList";
import KontaktUtrosakList from "./dokumentacija/utrosci/KontaktUtrosakList";
import UnosList from "./unos/UnosList";

export default function Kontakt() {
  const Labels = useLabels();
  const {
    kontakt,
    formularList,
    activeIndex,
    dokumentacijaActiveIndex,
    deleteKontakt,
    clickOutsideDisabled,
    setClickOutsideDisabled,
    visibleLeaveMainTabviewDialog,
    closeLeaveMainTabviewDialog,
    openLeaveMainTabviewDialog,
    confirmLeaveMainTabview,
    visibleLeaveInnerTabviewDialog,
    closeLeaveInnerDialog,
    openLeaveInnerDialog,
    confirmLeaveInner,
    poljeVrednostListRef,
    nesacuvanNalazState,
    izabraniTipUnosaRef,
    izabraniPopunjeniUnosRef,
    isFirstRenderNalaz,
    setIsFirstRenderNalaz,
    setNesacuvanNalazState,
    kontaktPaketList,
    setKontaktPaketList,
    kontaktDijagnozaList,
    setKontaktDijagnozaList,
    kontaktUslugaList,
    setKontaktUslugaList,
    planiranaUslugaList,
    setPlaniranaUslugaList,
    kontaktUtrosakList,
    setKontaktUtrosakList,
    kreiraniUnosList,
    setKreiraniUnosList,
    receptList,
    setReceptList,
    kontaktDijagnozaPrethodnaList,
    setKontaktDijagnozaPrethodnaList,
    setSifarnikUslugaList,
    setUslugaTipList,
    sifarnikUslugaList,
    uslugaTipList,
    dijagnozaStatusList,
    setDijagnozaStatusList,
    medicinskiPodaci,
  } = KontaktLogical();
  const navigate = useNavigate();
  const { pristup, authData } = useContext(AppContext);

  const { hasKontaktDelete } = KontrolaPravaPristupaService();

  if (!kontakt) return null;

  const verticalBar = <div className="border-right-1 border-400 mx-2" />;

  const headerTemplateDeleteKontaktButton = () => {
    return (
      <React.Fragment>
        <div className="flex">
          <ConfirmComponent
            tooltip={Labels.OBRISI_KONTAKT_TOOLTIP}
            buttonClassName="p-button-danger"
            buttonIcon="pi pi-trash"
            acceptClassName="p-button p-component text-white p-button-tertiary"
            rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
            confirmIcon="pi pi-exclamation-triangle"
            tooltipPosition={tooltipOptionsBottom}
            confirmTitle={Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_KONTAKT}
            onAccept={() => {
              deleteKontakt();
            }}
            disabled={!pristup || (!hasKontaktDelete && authData?.currentRadnik.id !== kontakt.radnikKreirao.id)}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="layout-pacijent-dosije">
      <FormularLeaveDialog
        izabraniPopunjeniUnosRef={izabraniPopunjeniUnosRef}
        kontakt={kontakt}
        poljeVrednostListRef={poljeVrednostListRef}
        visibleDialog={visibleLeaveMainTabviewDialog}
        closeDialog={closeLeaveMainTabviewDialog}
        confirmLeave={confirmLeaveMainTabview}
        izabraniTipUnosaRef={izabraniTipUnosaRef}
      />
      <FormularLeaveDialog
        izabraniPopunjeniUnosRef={izabraniPopunjeniUnosRef}
        kontakt={kontakt}
        poljeVrednostListRef={poljeVrednostListRef}
        visibleDialog={visibleLeaveInnerTabviewDialog}
        closeDialog={closeLeaveInnerDialog}
        confirmLeave={confirmLeaveInner}
        izabraniTipUnosaRef={izabraniTipUnosaRef}
      />
      <Panel className="mb-4 ml-4 mr-3 padding-none">
        <div className="flex">
          {`${kontakt?.primarniDoktor?.titula ?? ""} ${kontakt?.primarniDoktor?.ime ?? ""} ${kontakt?.primarniDoktor?.prezime ?? ""}`}
          {kontakt?.primarniDoktor ? verticalBar : ""}
          {moment(kontakt.vremeOd).format(DATE_TIME_FORMAT)}
          {verticalBar}
          {kontakt.organizacionaJedinica.naziv}
          {verticalBar}
          {kontakt.kontaktTip.naziv}
        </div>
      </Panel>
      <div className="ml-5 mb-5 flex justify-content-between ">
        <PacijentInfo pacijent={kontakt.pacijent} protokolBroj={kontakt.protokolBroj} prikaz={EnumPacijentInfoPrikaz.HORIZONTALNO} medicinskiPodaci={medicinskiPodaci} />
        <div className="flex">
          <Button
            label={Labels.LABEL_NAPLATA}
            className="p-button-outlined p-button-tertiary mr-3 w-9rem"
            tooltipOptions={tooltipOptionsBottom}
            tooltip={Labels.NAPLATI_KONTAKT_TOOLTIP}
            onClick={() => {
              navigate("/naplata", {
                state: {
                  filter: {
                    organizacionaJedinicaID: kontakt?.organizacionaJedinica?.id,
                    pacijentID: kontakt?.pacijent?.id,
                    datumOd: kontakt?.vremeOd,
                  },
                },
              });
            }}
          />
          <Button
            label={Labels.LABEL_PACIJENT_DOSIJE}
            className="p-button-outlined p-button-tertiary mr-3"
            onClick={() => {
              navigate(`/pacijent-dosije/${kontakt.pacijent.id}`);
            }}
          />
        </div>
      </div>
      <div className="flex">
        <div className="ml-4 w-full">
          <TabView className="d-inline-flex" activeIndex={activeIndex} onTabChange={openLeaveMainTabviewDialog} renderActiveOnly>
            <TabPanel header={Labels.DOKUMENTACIJA}>
              <Panel>
                <TabView activeIndex={dokumentacijaActiveIndex} onTabChange={openLeaveInnerDialog} renderActiveOnly>
                  <TabPanel header={Labels.NALAZI}>
                    <UnosList
                      poljeVrednostListRef={poljeVrednostListRef}
                      clickOutsideDisabled={clickOutsideDisabled}
                      setClickOutsideDisabled={setClickOutsideDisabled}
                      formularList={formularList}
                      kontakt={kontakt}
                      nesacuvanNalazState={nesacuvanNalazState}
                      izabraniTipUnosaRef={izabraniTipUnosaRef}
                      izabraniPopunjeniUnosRef={izabraniPopunjeniUnosRef}
                      isFirstRenderNalaz={isFirstRenderNalaz}
                      setIsFirstRenderNalaz={setIsFirstRenderNalaz}
                      setNesacuvanNalazState={setNesacuvanNalazState}
                      pacijent={kontakt.pacijent}
                      kreiraniUnosList={kreiraniUnosList}
                      setKreiraniUnosList={setKreiraniUnosList}
                    />
                  </TabPanel>
                  <TabPanel header={Labels.DIJAGNOZE}>
                    <KontaktDijagnozaList
                      pacijent={kontakt.pacijent}
                      kontaktDijagnozaList={kontaktDijagnozaList}
                      setKontaktDijagnozaList={setKontaktDijagnozaList}
                      kontaktDijagnozaPrethodnaList={kontaktDijagnozaPrethodnaList}
                      setKontaktDijagnozaPrethodnaList={setKontaktDijagnozaPrethodnaList}
                      dijagnozaStatusList={dijagnozaStatusList}
                      setDijagnozaStatusList={setDijagnozaStatusList}
                    />
                  </TabPanel>
                  <TabPanel header={Labels.TERAPIJE}>
                    <KontaktTerapijeList pacijent={kontakt.pacijent} receptList={receptList} setReceptList={setReceptList} />
                  </TabPanel>
                  <TabPanel header={Labels.USLUGE}>
                    <KontaktUslugaList
                      pacijent={kontakt.pacijent}
                      kontaktUslugaList={kontaktUslugaList}
                      setKontaktUslugaList={setKontaktUslugaList}
                      planiranaUslugaList={planiranaUslugaList}
                      setPlaniranaUslugaList={setPlaniranaUslugaList}
                      setSifarnikUslugaList={setSifarnikUslugaList}
                      sifarnikUslugaList={sifarnikUslugaList}
                      setUslugaTipList={setUslugaTipList}
                      uslugaTipList={uslugaTipList}
                      naplacen={kontakt.naplacen}
                    />
                  </TabPanel>
                  <TabPanel header={Labels.UTROSCI}>
                    <KontaktUtrosakList pacijent={kontakt.pacijent} kontaktUtrosakList={kontaktUtrosakList} setKontaktUtrosakList={setKontaktUtrosakList} naplacen={kontakt.naplacen} />
                  </TabPanel>
                  <TabPanel header={Labels.PAKETI}>
                    <KontaktPaketList pacijent={kontakt.pacijent} kontaktPaketList={kontaktPaketList} setKontaktPaketList={setKontaktPaketList} naplacen={kontakt.naplacen} />
                  </TabPanel>
                  <TabPanel header={Labels.DATOTEKE}>
                    <KontaktDatotekaList kontakt={kontakt} />
                  </TabPanel>
                  <TabPanel headerClassName="absolute hc-right-1rem" headerTemplate={headerTemplateDeleteKontaktButton} />
                </TabView>
              </Panel>
            </TabPanel>
            <TabPanel header={Labels.OPSTI_MEDICINSKI_PODACI}>
              <OpstiMedicinskiPodaci pacijent={kontakt.pacijent} medicinskiPodaci={medicinskiPodaci} />
            </TabPanel>
            <TabPanel headerClassName="absolute  hc-right-1rem" headerTemplate={<PacijentRizik id={kontakt?.pacijent?.id} />} />
          </TabView>
        </div>
      </div>
    </div>
  );
}
