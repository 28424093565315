import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../Store";
import FileType from "../../../infrastructure/system/FileType";
import Images from "../../../infrastructure/system/Images";
import { tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import PorukaCreateDto from "../../../model/poruke/PorukaCreateDto";
import PorukaDatotekaCreateDto from "../../../model/poruke/PorukaDatotekaCreateDto";
import RadnikReadDto from "../../../model/radnik/RadnikReadDto";
import CustomMultiselect from "../../custom-multiselect/CustomMultiselect";

interface NovaPorukaType {
  documentUploadHandler: (event: FileUploadHandlerEvent) => void;
  attachmentList: Array<PorukaDatotekaCreateDto | undefined>;
  setAttachmentList: React.Dispatch<React.SetStateAction<Array<any>>>;
  onRemoveAttachment: (attachmentName: string | undefined) => void;
  radnikList: Array<RadnikReadDto>;
  porukaChange: PorukaCreateDto | undefined;
  setPorukaChange: React.Dispatch<React.SetStateAction<any>>;
  recepients: Array<RadnikReadDto>;
  setRecepients: React.Dispatch<React.SetStateAction<Array<any>>>;
  addRecepient: (ids: Array<number>) => void;
  removeRecepient: (recepientId: number) => void;
  onCreate: () => void;
  onChangeRecepients: (e: any, id: number) => void;
  added: number[];
  setAdded: React.Dispatch<React.SetStateAction<number[]>>;
}

export default function NovaPoruka(props: NovaPorukaType) {
  const {
    documentUploadHandler,
    attachmentList,
    setAttachmentList,
    onRemoveAttachment,
    radnikList,
    porukaChange,
    setPorukaChange,
    recepients,
    setRecepients,
    removeRecepient,
    onCreate,
    onChangeRecepients,
    added,
    setAdded,
  } = props;
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const documentUploadRef = useRef<any>(null);

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <select className="ql-size" defaultValue="medium">
          <option value="medium">Normal</option>
          <option value="large">Subheading</option>
          <option value="huge">Heading</option>
        </select>
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
          <button className="ql-strike" aria-label="Strikethrough"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-align" value="" aria-label="Align left"></button>
          <button className="ql-align" value="center" aria-label="Align center"></button>
          <button className="ql-align" value="right" aria-label="Align right"></button>
          <button className="ql-align" value="justify" aria-label="Align justify"></button>
        </span>

        <span className="ql-formats">
          <button className="ql-script" value="sub"></button>
          <button className="ql-script" value="super"></button>
        </span>
      </span>
    );
  };

  const header = renderHeader();

  const porukaDatotekaListByteSize = () => {
    if (porukaChange && porukaChange.porukaDatotekaList && porukaChange.porukaDatotekaList.length > 0) {
      const porukaSize = porukaChange?.porukaDatotekaList?.map((porukaDatoteka) => porukaDatoteka.velicina).reduce((acc, currentValue) => acc + currentValue, 0);
      if (porukaSize > 1024 * 512) return (porukaSize / (1024 * 1024)).toFixed(1) + "MB";
      return Math.ceil(porukaSize / 1024) + "KB";
    }
    return "0KB";
  };

  return (
    <>
      <div className="flex flex-row flex-wrap align-items-start mb-3 mt-0 mx-0 primaoci-wrapper">
        <div className="flex align-items-center mt-2 primaoci-input">
          <div className="font-bold">{Labels.PORUKE_PRIMALAC}:</div>
          <div className="ml-2">
            <span className="p-input-icon-left">
              <CustomMultiselect options={radnikList} onChange={onChangeRecepients} added={added} />
            </span>
          </div>
        </div>
        <div className="flex flex-wrap align-items-center primaoci-list">
          {recepients.length > 0 &&
            recepients.map((recepient: RadnikReadDto) => (
              <div className="poruke-attachment__added ml-2 mt-2" key={recepient.id}>
                <div className="flex align-items-center p-2">
                  <span>{recepient?.ime + " " + recepient?.prezime}</span>
                  <i className="pi pi-times-circle ml-2 remove-attachment-icon" onClick={() => removeRecepient(recepient.id)}></i>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="flex align-items-center mb-2">
        <div className="font-bold">{Labels.PORUKE_NASLOV}:</div>
        <div className="flex-grow-1">
          <InputText
            value={porukaChange?.naslov ?? ""}
            onChange={(e) => {
              setPorukaChange({
                ...porukaChange!,
                naslov: e.target.value,
              });
            }}
            className="inputWithoutBorder w-full"
          />
        </div>
      </div>
      <div>
        <hr></hr>
      </div>
      <div className="mb-3">
        <Editor
          className="poruke-editor"
          headerTemplate={header}
          value={porukaChange?.sadrzaj ?? ""}
          onTextChange={(e) => {
            setPorukaChange((prevState: PorukaCreateDto | undefined) => ({ ...prevState, sadrzaj: e.htmlValue ?? "" }));
          }}
        />
      </div>
      <div className="grid justify-content-end m-0">
        <Button
          label={Labels.PORUKE_ISPRAZNI}
          disabled={!pristup}
          tooltip={Labels.PORUKE_ISPRAZNI}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-times"
          className="p-button p-button-outlined mr-3"
          onClick={() => {
            setPorukaChange(undefined);
            setAttachmentList([]);
            setRecepients([]);
            setAdded([]);
          }}
        />
        <Button label={Labels.PORUKE_POSALJI} icon="pi pi-send" className="button-danger" disabled={!pristup} onClick={onCreate} />
      </div>
      <div>
        <hr></hr>
      </div>
      <div className="mr-2 mt-2 flex align-items-center hc-gap-3">
        <FileUpload
          ref={documentUploadRef}
          auto
          mode="basic"
          multiple={true}
          customUpload
          uploadHandler={(e) => {
            documentUploadHandler(e);
            documentUploadRef.current.clear();
          }}
          disabled={!pristup}
          className="poruke-attachment"
          chooseLabel={Labels.PORUKE_DODAJ_DOKUMENT}
          chooseOptions={{ icon: "pi pi-paperclip" }}
        />
        <div className="text-sm text-600 hover:text-danger">{porukaDatotekaListByteSize()} / 25MB</div>
      </div>
      <div className="flex flex-row flex-wrap overflow-x-hidden justify-content-start align-items-center">
        {attachmentList.length > 0 &&
          attachmentList.map((item: PorukaDatotekaCreateDto | undefined, index: number) => (
            <div className="poruke-attachment__added mr-2 mt-2" key={index}>
              <div className="flex align-items-center p-2">
                {item?.tip === FileType.doc && <img src={Images.ICON_DOC} alt="doc-icon" className="attachment-icon" />}
                {item?.tip === FileType.docx && <img src={Images.ICON_DOCX} alt="docx-icon" className="attachment-icon" />}
                {item?.tip === FileType.pdf && <img src={Images.ICON_PDF} alt="pdf-icon" className="attachment-icon" />}
                {item?.tip === FileType.ppt && <img src={Images.ICON_PPT} alt="ppt-icon" className="attachment-icon" />}
                {item?.tip === FileType.pptx && <img src={Images.ICON_PPTX} alt="pptx-icon" className="attachment-icon" />}
                {item?.tip === FileType.rar && <img src={Images.ICON_RAR} alt="rar-icon" className="attachment-icon" />}
                {item?.tip === FileType.txt && <img src={Images.ICON_TXT} alt="txt-icon" className="attachment-icon" />}
                {item?.tip === FileType.xls && <img src={Images.ICON_XLS} alt="xls-icon" className="attachment-icon" />}
                {item?.tip === FileType.zip && <img src={Images.ICON_ZIP} alt="zip-icon" className="attachment-icon" />}
                {item?.tip.includes("image") && <img src={Images.ICON_IMAGE} alt="icon" className="attachment-icon" />}
                <span>{item?.naziv}</span>
                <i className="pi pi-times-circle ml-2 remove-attachment-icon" onClick={() => onRemoveAttachment(item?.naziv)}></i>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
