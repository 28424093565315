import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { useContext, useMemo } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import KontaktDijagnozaReadDto from "../../../../model/kontakt/KontaktDijagnozaReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import SifarnikMkbDto from "../../../../model/sifarnik/SifarnikMkbDto";
import DialogComponent from "../../../dialog-component/DialogComponent";

interface KontaktDijagnozaProps {
  isOpenDijagnozaDialog: boolean;
  closeDijagnozaDialog: () => void;
  kontaktDijagnozaChange: KontaktDijagnozaReadDto;
  setKontaktDijagnozaChange: any;
  dijagnozaOperation?: string;
  isDisabled: boolean;
  onCreateDijagnoze: () => void;
  onUpdateDijagnoze: () => void;
  onDeleteDijagnoze: () => void;
  dijagnozaStatusList?: Array<EnumBaseReadDto>;
  searchMkbDijagnoza: (e: AutoCompleteCompleteEvent) => void;
  mkbDijagnozeList: Array<SifarnikMkbDto>;
  dijagnozeLoading: boolean;
}
export default function KontaktDijagnoza(props: KontaktDijagnozaProps) {
  const {
    isOpenDijagnozaDialog,
    closeDijagnozaDialog,
    kontaktDijagnozaChange,
    setKontaktDijagnozaChange,
    dijagnozaOperation,
    isDisabled,
    onCreateDijagnoze,
    onUpdateDijagnoze,
    onDeleteDijagnoze,
    dijagnozaStatusList,
    searchMkbDijagnoza,
    mkbDijagnozeList,
    dijagnozeLoading,
  } = props;
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const headerToolbar = () => (
    <div className="font-bold p-0">
      {dijagnozaOperation === EntityOperation.CREATE
        ? Labels.DIJAGNOZA_DODAVANJE
        : dijagnozaOperation === EntityOperation.UPDATE
        ? Labels.DIJAGNOZA_IZMENA
        : dijagnozaOperation === EntityOperation.DELETE
        ? Labels.DIJAGNOZA_BRISANJE
        : ""}
    </div>
  );

  const footer = (
    <div className="layout-pacijent-dosije">
      <Button label={Labels.BUTTON_CANCEL} className="p-button-outlined p-button-tertiary align-self-center" onClick={closeDijagnozaDialog} />
      <Button
        label={
          dijagnozaOperation === EntityOperation.CREATE
            ? Labels.BUTTON_SAVE
            : dijagnozaOperation === EntityOperation.UPDATE
            ? Labels.BUTTON_SAVE
            : dijagnozaOperation === EntityOperation.DELETE
            ? Labels.BUTTON_DELETE
            : ""
        }
        tooltipOptions={tooltipOptionsBottom}
        className="p-button-success"
        onClick={
          dijagnozaOperation === EntityOperation.CREATE
            ? onCreateDijagnoze
            : dijagnozaOperation === EntityOperation.UPDATE
            ? onUpdateDijagnoze
            : dijagnozaOperation === EntityOperation.DELETE
            ? onDeleteDijagnoze
            : undefined
        }
        disabled={!pristup}
      />
    </div>
  );

  const autocompleteStyle = useMemo(() => {
    return { paddingLeft: "2rem", paddingRight: "2rem" };
  }, []);

  return (
    <DialogComponent
      header={headerToolbar}
      visible={isOpenDijagnozaDialog}
      onHide={closeDijagnozaDialog}
      className="layout-kontakt-dijagnoza"
      footer={footer}
      content={
        <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
          <div className="grid">
            <div className="col-12">
              <div className="grid align-items-center justfy-content-center p-nogutter">
                <div className="font-bold col-12">
                  {Labels.DIJAGNOZA_UNOS}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                  {dijagnozeLoading && <ProgressSpinner className="w-3rem h-1rem" strokeWidth="4" animationDuration="1s" />}
                </div>
              </div>
              <div className="grid align-items-center justfy-content-center p-nogutter">
                <div className="col-12 p-fluid margin-top-bottom-5 p-1 pl-3">
                  <span className="p-input-icon-left">
                    <AutoComplete
                      value={kontaktDijagnozaChange?.sifarnikMkb ?? ""}
                      disabled={!pristup || isDisabled || dijagnozaOperation === EntityOperation.UPDATE}
                      itemTemplate={(e) => e.punNaziv}
                      selectedItemTemplate={(e) => `${e.sifra ?? ""} ${e.nazivLatinski ?? ""}`}
                      suggestions={mkbDijagnozeList}
                      completeMethod={searchMkbDijagnoza}
                      inputStyle={autocompleteStyle}
                      field="unosDijagnoze"
                      onChange={(e) => {
                        setKontaktDijagnozaChange({
                          ...kontaktDijagnozaChange!,
                          sifarnikMkb: e.value,
                          sifarnikMkbId: e.value.id,
                        });
                      }}
                      minLength={3}
                      placeholder={Labels.DIJAGNOZA_UNOS_PLACEHOLDER}
                    />
                    <i className="pi pi-search " />
                    {kontaktDijagnozaChange?.sifarnikMkb && kontaktDijagnozaChange?.sifarnikMkb.unosDijagnoze !== "" && (
                      <i
                        className={`pi pi-times p-icon-right ${dijagnozaOperation === EntityOperation.UPDATE ? "p-disabled" : ""}`}
                        onClick={() => {
                          setKontaktDijagnozaChange({ ...kontaktDijagnozaChange!, sifarnikMkb: undefined });
                        }}
                      />
                    )}
                  </span>
                </div>
              </div>
              <div className="grid align-items-center justfy-content-center p-nogutter">
                <div className="font-bold col-12 mt-3">
                  {Labels.DIJAGNOZA_STATUS}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="grid align-items-center justfy-content-center p-nogutter">
                <div className="col-12 p-fluid margin-top-bottom-5 p-1 pl-3">
                  <Dropdown
                    optionLabel="naziv"
                    optionValue="id"
                    disabled={!pristup || isDisabled}
                    options={dijagnozaStatusList}
                    value={kontaktDijagnozaChange?.dijagnozaStatus?.id}
                    resetFilterOnHide
                    onChange={(e) => {
                      setKontaktDijagnozaChange({
                        ...kontaktDijagnozaChange!,
                        dijagnozaStatus: dijagnozaStatusList!.find((x: EnumBaseReadDto) => x.id === e.value),
                      });
                    }}
                    emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                  />
                </div>
              </div>
              <div className="grid align-items-center justfy-content-center p-nogutter">
                <div className="font-bold col-12 mt-3">{Labels.DIJAGNOZA_NAPOMENA}</div>
              </div>
              <div className="grid align-items-center justfy-content-center p-nogutter">
                <div className="col-12 p-fluid margin-top-bottom-5 p-1 pl-3">
                  <InputTextarea
                    rows={3}
                    cols={5}
                    disabled={!pristup || isDisabled}
                    value={kontaktDijagnozaChange?.napomena}
                    onChange={(e) => {
                      setKontaktDijagnozaChange({
                        ...kontaktDijagnozaChange!,
                        napomena: e.target.value ? e.target.value : "",
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}
