import { useQuery } from "@tanstack/react-query";
import QueryKeys from "../../QueryKeys";
import { UlogaController } from "../../../../controllers/uloga/UlogaController";
import UlogaReadDto from "../../../../model/uloga/UlogaReadDto";

export function useUlogaList() {
  const { axiosSearchUloga } = UlogaController();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.ULOGA_LIST],
    queryFn: () => axiosSearchUloga({}),
  });

  const ulogaList: Array<UlogaReadDto> = data?.data?.data ?? [];
  const uloge: Array<UlogaReadDto> = ulogaList?.map((uloga: UlogaReadDto) => ({ ...uloga, naziv: uloga.naziv.toLowerCase()}));

  return { ulogaList, refetch, isLoading, uloge };
}
