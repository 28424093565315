import { Button } from "primereact/button";
import { useLabels } from "../../../Store";
import { NESACUVAN_NALAZ } from "../../../infrastructure/system/Utils";
import KontaktReadDto from "../../../model/kontakt/KontaktReadDto";
import DialogComponent from "../../dialog-component/DialogComponent";

interface FormularLeaveDialogProps {
  visibleDialog: boolean;
  closeDialog: () => void;
  confirmLeave: () => void;
  poljeVrednostListRef: React.MutableRefObject<any>;
  kontakt: KontaktReadDto;
  izabraniTipUnosaRef: React.MutableRefObject<any>;
  izabraniPopunjeniUnosRef: React.MutableRefObject<any>;
}

export default function FormularLeaveDialog(props: FormularLeaveDialogProps) {
  const { visibleDialog, closeDialog, confirmLeave, poljeVrednostListRef, kontakt, izabraniTipUnosaRef, izabraniPopunjeniUnosRef } = props;
  const Labels = useLabels();

  const onConfirm = () => {
    if (poljeVrednostListRef.current) {
      localStorage.setItem(
        NESACUVAN_NALAZ,
        JSON.stringify({
          poljeVrednostList: poljeVrednostListRef.current,
          pacijentIme: kontakt.pacijent.ime,
          pacijentPrezime: kontakt.pacijent.prezime,
          kontaktID: kontakt.id,
          kontaktTipNaziv: kontakt.kontaktTip.naziv,
          izabraniTipUnosa: izabraniTipUnosaRef?.current?.izabraniTipUnosa,
          izabraniPopunjeniUnos: izabraniPopunjeniUnosRef?.current?.izabraniPopunjeniUnos,
        })
      );
    }
    confirmLeave();
  };

  const footer = () => {
    return (
      <div className="layout-pacijent-dosije">
        <div className="flex justify-content-between">
          <Button label={Labels.BUTTON_NO} className="p-button-outlined hc-color-orange" onClick={closeDialog} autoFocus />
          <Button label={Labels.BUTTON_YES} className="hr-create-ucesnik" onClick={onConfirm} />
        </div>
      </div>
    );
  };

  return (
    <DialogComponent
      visible={visibleDialog}
      onHide={closeDialog}
      header={""}
      footer={footer}
      content={
        <div>
          <div className="font-bold">
            <div> {Labels.FORMULAR_DA_LI_STE_SIGURNI}</div>
          </div>
        </div>
      }
    />
  );
}
