import { EffectCallback, useEffect } from "react";

/**
 *
 * @param effect - metoda koju cemo zvati kao u regularnom useEffectu
 * @param dependencies - array dependecy-ja
 *
 * ovaj custom hook se koristi u slucajevima kada imamo dependency array, ali zelimo da izbegnemo dodavanje svih dependency-ja i warning u console-i
 */
const useEffectWithoutWarnings = (effect: EffectCallback, dependencies: Array<any>) => {
  // eslint-disable-next-line
  return useEffect(effect, dependencies);
};

export default useEffectWithoutWarnings;
