import { useContext, useState } from "react";
import { AppContext } from "../../Store";
import PorukeController from "../../controllers/poruke/PorukeController";
import { handleAxiosCallError } from "../../infrastructure/system/Utils";
import PorukaDatotekaReadDto from "../../model/poruke/PorukaDatotekaReadDto";

interface PorukaLogicalType {
  handleDatotekaClick: (porukaDatoteka: PorukaDatotekaReadDto) => void;
  isOpenDeleteDialog: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

export default function PorukaLogical(): PorukaLogicalType {
  const { showMessage } = useContext(AppContext);

  const { axiosGetPorukaDatoteka } = PorukeController();
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);

  function openDialog() {
    setIsOpenDeleteDialog(true);
  }

  function closeDialog() {
    setIsOpenDeleteDialog(false);
  }

  const handleDatotekaClick = (porukaDatoteka: PorukaDatotekaReadDto) => {
    axiosGetPorukaDatoteka(porukaDatoteka.id)
      .then((res) => {
        const link = document.createElement("a");
        const byteChars = atob(res.data.data.sadrzaj);
        const dataArray = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
          dataArray[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(dataArray);
        let doc = new Blob([byteArray], { type: porukaDatoteka.tip });
        const url = URL.createObjectURL(doc);
        link.download = porukaDatoteka.naziv;
        link.href = url;
        link.click();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {});
  };

  return { handleDatotekaClick, isOpenDeleteDialog, closeDialog, openDialog };
}
