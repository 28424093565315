import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { useMemo } from "react";
import { useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import PaketProizvodCreateDto from "../../../../model/paket/PaketProizvodCreateDto";
import PaketProizvodReadDto from "../../../../model/paket/PaketProizvodReadDto";
import SifarnikProizvodaReadDto from "../../../../model/sifarnik/sifarnik-proizvoda/SifarnikProizvodaReadDto";
import DialogComponent from "../../../dialog-component/DialogComponent";

interface DialogPaketProizvodType {
  show: boolean;
  closeDialog: () => void;
  paketProizvodChange: PaketProizvodCreateDto | undefined;
  setPaketProizvodChange: any;
  searchSifarnikProizvoda: (e: AutoCompleteCompleteEvent) => void;
  sifarnikProizvodaList: Array<SifarnikProizvodaReadDto>;
  dialogAction: string;
  paketProizvod: PaketProizvodReadDto | undefined;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
}

export default function DialogPaketProizvod(props: DialogPaketProizvodType) {
  const Labels = useLabels();
  const { show, closeDialog, paketProizvodChange, setPaketProizvodChange, searchSifarnikProizvoda, sifarnikProizvodaList, onCreate, dialogAction, paketProizvod, onDelete, onUpdate } = props;

  const renderFooter = () => {
    return (
      <div className="flex justify-content-end">
        <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={closeDialog} />
        {dialogAction === EntityOperation.CREATE && <Button label={Labels.UTROSAK_NOVI} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />}
        {dialogAction === EntityOperation.UPDATE && <Button label={Labels.UTROSAK_IZMENI} icon="pi pi-pencil" className="p-button-warning" onClick={onUpdate} />}
        {dialogAction === EntityOperation.DELETE && <Button label={Labels.UTROSAK_OBRISI} icon="pi pi-trash" className="p-button-danger" onClick={onDelete} />}
      </div>
    );
  };

  const renderTitle = () => {
    switch (dialogAction) {
      case EntityOperation.CREATE:
        return Labels.PAKET_USLUGA_DIALOG_PROIZVOD_CREATE_TITLE;
      case EntityOperation.UPDATE:
        return Labels.PAKET_USLUGA_DIALOG_PROIZVOD_UPDATE_TITLE;
      case EntityOperation.DELETE:
        return Labels.PAKET_USLUGA_DIALOG_PROIZVOD_DELETE_TITLE;
      default:
        return "";
    }
  };

  const autocompleteStyles = useMemo(() => {
    return { paddingLeft: "2rem", width: "100%" };
  }, []);

  return (
    <DialogComponent
      header={renderTitle}
      visible={show}
      footer={renderFooter}
      onHide={closeDialog}
      blockScroll={true}
      className="w-6 lg:w-5 xl:w-5"
      content={
        <>
          <div className="flex flex-column justify-content-center p-3">
            <div className="grid align-items-center p-0 py-3">
              <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3">{Labels.PAKET_USLUGA_LABEL_UTROSAK}</div>
              <div className="col-12 md:col-8 p-fluid p-0">
                <span className="p-input-icon-left w-full">
                  <AutoComplete
                    value={paketProizvodChange?.sifarnikProizvodaFull}
                    itemTemplate={(e) => `${e.sifra} ${e.naziv}`}
                    selectedItemTemplate={(e) => `${e.sifra} ${e.naziv}`}
                    suggestions={sifarnikProizvodaList}
                    completeMethod={searchSifarnikProizvoda}
                    inputStyle={autocompleteStyles}
                    field="sifarnikProizvoda"
                    onChange={(e) => {
                      setPaketProizvodChange({
                        ...paketProizvodChange!,
                        sifarnikProizvodaFull: e.value,
                        sifarnikProizvoda: { id: e.value.id },
                      });
                    }}
                    className="w-full block"
                    placeholder={
                      dialogAction === EntityOperation.DELETE || dialogAction === EntityOperation.UPDATE
                        ? `${paketProizvod?.sifarnikProizvoda?.sifra} ${paketProizvod?.sifarnikProizvoda?.naziv}`
                        : `${Labels.PAKET_USLUGA_UNOS_PROIZVODA_PLACEHOLDER}`
                    }
                    disabled={dialogAction === EntityOperation.DELETE || dialogAction === EntityOperation.UPDATE}
                    minLength={3}
                  />
                  <i className="pi pi-search p-icon-left" />
                </span>
              </div>
            </div>
            <div className="grid align-items-center p-0 py-3">
              <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3">{Labels.PAKET_USLUGA_LABEL_PODRAZUMEVANA_KOLICINA}</div>
              <div className="col-3 md:col-2 p-fluid p-0">
                <InputNumber
                  value={paketProizvodChange?.podrazumevanaUtrosenaKolicina}
                  onChange={(e) => {
                    setPaketProizvodChange({
                      ...paketProizvodChange!,
                      podrazumevanaUtrosenaKolicina: e.value,
                    });
                  }}
                  disabled={dialogAction === EntityOperation.DELETE}
                />
              </div>
            </div>
            <div className="grid align-items-center p-0 py-3">
              <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3">{Labels.PAKET_USLUGA_LABEL_PRIKAZNI_REDOSLED}</div>
              <div className="col-3 md:col-2 p-fluid p-0">
                <InputNumber
                  value={paketProizvodChange?.prikazniRedosled}
                  onChange={(e) => {
                    setPaketProizvodChange({
                      ...paketProizvodChange!,
                      prikazniRedosled: e.value,
                    });
                  }}
                  disabled={dialogAction === EntityOperation.DELETE}
                />
              </div>
            </div>
            <div className="grid align-items-center p-0 py-3">
              <div className="col-12 px-0 pb-2 pt-4 md:py-3 md:col-4 md:px-3">{Labels.PAKET_USLUGA_LABEL_NAPOMENA}</div>
              <div className="col-12 md:col-8 p-fluid p-0">
                <InputTextarea
                  disabled={dialogAction === EntityOperation.DELETE}
                  value={paketProizvodChange?.napomena ?? ""}
                  onChange={(e) => {
                    setPaketProizvodChange({
                      ...paketProizvodChange!,
                      napomena: e.target.value,
                    });
                  }}
                  rows={5}
                />
              </div>
            </div>
          </div>
        </>
      }
    />
  );
}
