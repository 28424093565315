import { AxiosResponse } from "axios";
import { decode as base64_decode } from "base-64";
import moment from "moment/moment";
import { useContext, useEffect, useRef, useState } from "react";
import SockJS from "sockjs-client";
import { Client, Message, Subscription, over } from "stompjs";
import { AppContext, useLabels } from "../../Store";
import { IzvozController } from "../../controllers/izvoz/IzvozController";
import Endpoint from "../../infrastructure/system/Endpoint";
import EnumWebSocketPorukaTip from "../../infrastructure/system/EnumWebSocketPorukaTip";
import MessageType from "../../infrastructure/system/MessageType";
import { DATE_FORMAT, DATE_TIME_FORMAT, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../model/BreadCrumbItemDto";
import WebSocketPayload from "../../model/WebSocketPayload";
import IzvozDatotekaReadDto from "../../model/izvoz/IzvozDatotekaReadDto";
import IzvozReadDto from "../../model/izvoz/IzvozReadDto";

interface ExportPodatakaLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  izvozLoading: boolean;
  first: number;
  tableRows: number;
  onPageIzvoz: (rows: number, first: number) => void;
  onIzvozDownload: (izvoz: IzvozReadDto) => void;
  onIzvozGenerate: (izvoz: IzvozReadDto) => void;
  izvozList: Array<IzvozReadDto>;
  dialogRef: any;
  renderDate: (date: Date) => string;
  renderVremeKreiranja: (column: IzvozReadDto) => string;
  renderVremeGenerisanja: (column: IzvozReadDto) => string;
  renderImePrezime: (column: IzvozReadDto) => string | undefined;
}

export default function ExportPodatakaLogical(isSuperAdmin: boolean): ExportPodatakaLogicalType {
  const Labels = useLabels();
  const { showMessage } = useContext(AppContext);
  const [izvozLoading, setIzvozLoading] = useState<boolean>(false);
  const { axiosSearchIzvozSuperAdmin, axiosGetIzvozDatoteka, axiosSearchIzvoz, axiosGenerateExport } = IzvozController();
  const [izvozList, setIzvozList] = useState<Array<IzvozReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const dialogRef = useRef<any>();

  let stompClient = useRef<Client>();
  let subscription = useRef<Subscription>();
  let izvorListRef = useRef<Array<IzvozReadDto>>(izvozList);
  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.IZVOZ,
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  useEffect(() => {
    izvorListRef.current = izvozList;
  }, [izvozList]);
  const fetchData = () => {
    let startTime = moment(new Date());
    const action = isSuperAdmin ? axiosSearchIzvozSuperAdmin : axiosSearchIzvoz;
    setIzvozLoading(true);
    action()
      .then((res: AxiosResponse) => {
        setIzvozList(res.data.data);
        setFirst(0);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setIzvozLoading, startTime);
      });
    isSuperAdmin && connect();
    return () => {
      if (stompClient.current?.connected) {
        stompClient.current?.disconnect(() => {});
      }
    };
  };

  const onPageIzvoz = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  const renderDate = (date: Date) => (date ? moment(date).format(DATE_FORMAT) : "");

  const renderVremeKreiranja = (column: IzvozReadDto) => moment(column?.vremeKreiranja).format(DATE_TIME_FORMAT);

  const renderVremeGenerisanja = (column: IzvozReadDto) => (column.vremeGenerisanja ? moment(column?.vremeGenerisanja).format(DATE_TIME_FORMAT) : "");

  const renderImePrezime = (column: IzvozReadDto) => {
    if (column?.radnikKreirao) {
      return `${column?.radnikKreirao?.ime} ${column?.radnikKreirao?.prezime}`;
    }
  };

  const handleDownloadUToku = (uToku: boolean, izvoz: IzvozReadDto) => {
    let izvozListTemp = izvozList.map((izvozTemp: IzvozReadDto) => {
      if (izvozTemp.id === izvoz.id) {
        izvozTemp.downloadUToku = uToku;
      }
      return izvozTemp;
    });
    setIzvozList(izvozListTemp);
  };

  const onIzvozDownload = (izvoz: IzvozReadDto) => {
    handleDownloadUToku(true, izvoz);
    axiosGetIzvozDatoteka(izvoz.id)
      .then((res: AxiosResponse) => {
        let izvozDatoteka: IzvozDatotekaReadDto = res.data.data;
        const byteChars = atob(base64_decode(izvozDatoteka.sadrzaj));
        const dataArray = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
          dataArray[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(dataArray);
        const file = new Blob([byteArray], { type: "application/zip" });
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${izvozDatoteka.naziv}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode && link.parentNode.removeChild(link);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        // stavljen minimalni timeout kako ne bi poskakivao UI
        setTimeout(() => {
          handleDownloadUToku(false, izvoz);
        }, 700);
      });
  };

  const onIzvozGenerate = (izvoz: IzvozReadDto) => {
    let izvozListTemp = izvozList.map((izvozTemp: IzvozReadDto) => {
      if (izvozTemp.id === izvoz.id) {
        izvozTemp.generisanjeUToku = true;
      }
      return izvozTemp;
    });
    setIzvozList(izvozListTemp);
    axiosGenerateExport(izvoz.id)
      .then((res: AxiosResponse) => {})
      .catch((error) => {
        let izvozListTemp = izvozList.map((izvozTemp: IzvozReadDto) => {
          if (izvozTemp.id === izvoz.id) {
            izvozTemp.generisanjeUToku = false;
          }
          return izvozTemp;
        });
        setIzvozList(izvozListTemp);
        handleAxiosCallError(showMessage, error);
      });
  };
  const connect = () => {
    let sock = new SockJS(Endpoint.EXPORT_WEBSOCKET);
    stompClient.current = over(sock);
    stompClient.current.connect({}, onConnected, onError);
  };

  const onError = () => {
    showMessage(MessageType.ERROR, Labels.WEBSOCKET_CONNECTION_ERROR);
  };

  const onConnected = () => {
    subscription.current = stompClient.current!.subscribe(Endpoint.EXPORT_LISTENER, onMessageReceived);
  };

  const onMessageReceived = (message: Message) => {
    let payload: WebSocketPayload = JSON.parse(message.body);

    let izvozListTemp = izvorListRef.current.map((izvoz: IzvozReadDto) => {
      if (payload.payload?.exportZahtevID && payload.payload?.exportZahtevID === izvoz.id) {
        if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.SUCCESS_MSG) {
          izvoz.generisanjeUToku = false;
          izvoz.vremeGenerisanja = payload.payload.vremeGenerisanja;
        } else if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.ERROR_MSG) {
          izvoz.generisanjeUToku = false;
        }
      }
      return izvoz;
    });

    if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.ERROR_MSG) {
      showMessage(MessageType.ERROR, payload.poruka);
      setIzvozList(izvozListTemp);
    } else if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.INFO_MSG) {
      showMessage(MessageType.INFO, payload.poruka);
    } else if (payload.enumWebSocketPorukaTip === EnumWebSocketPorukaTip.SUCCESS_MSG) {
      showMessage(MessageType.SUCCESS, payload.poruka);
      setIzvozList(izvozListTemp);
    }
    if (payload.poruka === Labels.WEBSOCKET_CLOSE_CONNECTION) {
      subscription.current!.unsubscribe();
      stompClient.current!.disconnect(() => {});
      return;
    }
  };

  return {
    breadCrumbItems,
    izvozLoading,
    first,
    tableRows,
    onPageIzvoz,
    izvozList,
    dialogRef,
    renderDate,
    renderVremeKreiranja,
    renderVremeGenerisanja,
    renderImePrezime,
    onIzvozDownload,
    onIzvozGenerate,
  };
}
