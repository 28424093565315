import { PickListChangeEvent } from "primereact/picklist";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { AppContext } from "../../../../Store";
import { RadnikController } from "../../../../controllers/radnik/RadnikController";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";
import RadnikUstanovaReadDto from "../../../../model/radnik/RadnikUstanovaReadDto";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";

interface CrudRadnikUstanovaType {
  radnikUstanovaList: Array<RadnikUstanovaReadDto>;
  ustanovaList: Array<RadnikUstanovaHolder>;
  onChangeRadnikUstanova: (event: PickListChangeEvent) => Promise<void>;
  isBlocked: boolean;
  isLoading: boolean;
}

interface UseParamsType {
  radnikId?: string;
}
export interface RadnikUstanovaHolder {
  ustanova: UstanovaReadDto;
}
export default function CrudRadnikUstanovaLogical(radnikOperation: string): CrudRadnikUstanovaType {
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [radnikUstanovaList, setRadnikUstanovaList] = useState<Array<RadnikUstanovaReadDto>>([]);
  const [ustanovaList, setUstanovaList] = useState<Array<RadnikUstanovaHolder>>([]);
  const { showMessage } = useContext(AppContext);
  const { axiosGetRadnikUstanovaList, axiosCreateRadnikUstanova } = RadnikController();
  const { axiosSuperadminSearchUstanova, axiosSuperadminDeleteUstanovaRadnik } = SuperadminController();
  const radnikId: number = Number(useParams<keyof UseParamsType>()["radnikId"]);
  const prevRadnikId = useRef<number | undefined>(undefined);
  const prevRadnikOperation = useRef<string | undefined>(undefined);

  const getUstanovaList = useCallback(
    (radnikUstanovaList: Array<RadnikUstanovaReadDto>) => {
      setIsLoading(true);
      axiosSuperadminSearchUstanova({})
        .then((res: any) => {
          let ustanovaListTemp: Array<RadnikUstanovaHolder> = [];
          const allUstanova = res.data.data;
          allUstanova.forEach((ustanova: UstanovaReadDto) => {
            if (
              radnikUstanovaList.filter((radnikUstanova: RadnikUstanovaReadDto) => {
                return radnikUstanova.ustanova.id === ustanova.id;
              }).length === 0
            ) {
              ustanovaListTemp.push({ ustanova: ustanova });
            }
          });

          setUstanovaList(ustanovaListTemp);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosSuperadminSearchUstanova, setIsLoading, showMessage]
  );

  const getRadnikUstanova = useCallback(() => {
    if (radnikId !== null && radnikId !== undefined) {
      setIsLoading(true);
      axiosGetRadnikUstanovaList(radnikId)
        .then((res: any) => {
          let radnikUstanovaList: Array<RadnikUstanovaReadDto> = res.data.data;

          setRadnikUstanovaList(radnikUstanovaList);

          getUstanovaList(radnikUstanovaList);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setIsLoading(false);
        });
    }
  }, [axiosGetRadnikUstanovaList, getUstanovaList, radnikId, setIsLoading, showMessage]);

  const fetchData = useCallback(() => {
    getRadnikUstanova();
  }, [getRadnikUstanova]);

  useEffect(() => {
    if (radnikId && prevRadnikId.current !== radnikId && prevRadnikOperation.current !== radnikOperation && radnikOperation !== EntityOperation.CREATE) {
      prevRadnikOperation.current = radnikOperation;
      prevRadnikId.current = radnikId;
      fetchData();
    }
  }, [fetchData, radnikId, radnikOperation]);

  const onChangeRadnikUstanova = async (event: PickListChangeEvent) => {
    if (isBlocked || isLoading) {
      return;
    }
    setIsBlocked(true);
    if (radnikUstanovaList.length < event.target.length) {
      await Promise.all(
        event.target.map(async (radnikUstanova: RadnikUstanovaHolder) => {
          if (
            !radnikUstanovaList
              .map((element: RadnikUstanovaReadDto) => {
                return element?.ustanova;
              })
              .includes(radnikUstanova.ustanova)
          ) {
            const ustanovaId = radnikUstanova.ustanova?.id;
            await axiosCreateRadnikUstanova(radnikId, { radnik: { id: radnikId }, ustanova: { id: ustanovaId } })
              .then((res: any) => {})
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
              });
          }
        })
      );
      setRadnikUstanovaList(event.target);
      setUstanovaList(ustanovaList.filter((element?: RadnikUstanovaHolder) => !event.target.includes(element)));
    } else if (ustanovaList.length < event.source.length) {
      await Promise.all(
        event.source.map(async (radnikUstanova: RadnikUstanovaHolder) => {
          if (!ustanovaList.includes(radnikUstanova)) {
            await axiosSuperadminDeleteUstanovaRadnik(radnikUstanova.ustanova.id, radnikId)
              .then((res: any) => {})
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
              });
          }
        })
      );
      let radnikUstanovaListTemp: Array<RadnikUstanovaReadDto> = radnikUstanovaList.filter((element: RadnikUstanovaReadDto) => !event.target.includes(element));

      setUstanovaList(ustanovaList.concat(radnikUstanovaListTemp));
      setRadnikUstanovaList(event.target);
    }
    setIsBlocked(false);
  };

  return { radnikUstanovaList, ustanovaList, onChangeRadnikUstanova, isBlocked, isLoading };
}
