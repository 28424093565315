interface IconCalendarListViewProps {
  color?: string;
  height?: string;
}

const IconCalendarListView = ({ color, height }: IconCalendarListViewProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      height={height ?? "100%"}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-list"
    >
      <line x1="8" y1="6" x2="21" y2="6"></line>
      <line x1="8" y1="12" x2="21" y2="12"></line>
      <line x1="8" y1="18" x2="21" y2="18"></line>
      <line x1="3" y1="6" x2="3.01" y2="6"></line>
      <line x1="3" y1="12" x2="3.01" y2="12"></line>
      <line x1="3" y1="18" x2="3.01" y2="18"></line>
    </svg>
  );
};

export default IconCalendarListView;
