import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { ObavestenjeController } from "../../../../controllers/obavestenje/ObavestenjeController";
import { DATE_TIME_FORMAT_FULL, handleAxiosCallError, skeletonTimeout } from "../../../../infrastructure/system/Utils";
import ObavestenjeDatotekaReadDto from "../../../../model/obavestenje/ObavestenjeDatotekaReadDto";
import ObavestenjeDetailsDto from "../../../../model/obavestenje/ObavestenjeDetailsDto";
import ObavestenjeOrganizacionaJedinicaReadDto from "../../../../model/obavestenje/ObavestenjeOrganizacionaJedinicaReadDto";
import ObavestenjeReadDto from "../../../../model/obavestenje/ObavestenjeReadDto";
import ObavestenjeUlogaReadDto from "../../../../model/obavestenje/ObavestenjeUlogaReadDto";

interface DialogObavestenjeLogicalType {
  renderDateTimeFormatFull: (date: Date) => string;
  renderUloge: (obavestenjeUlogaList: Array<ObavestenjeUlogaReadDto> | undefined) => string;
  renderOrganizacioneJedinice: (obavestenjeOrganizacionaJedinicaList: Array<ObavestenjeOrganizacionaJedinicaReadDto> | undefined) => string;
  datotekaList: Array<ObavestenjeDatotekaReadDto>;
  obavestenjeDetails: ObavestenjeDetailsDto | undefined;
  setObavestenjeDetails: React.Dispatch<React.SetStateAction<ObavestenjeDetailsDto | undefined>>;
  obavestenjeDetailsLoading: boolean;
  handleDatotekaClick: (datoteka: ObavestenjeDatotekaReadDto) => void;
}

export default function DialogObavestenjeLogical({ obavestenje }: { obavestenje: ObavestenjeReadDto }): DialogObavestenjeLogicalType {
  const { showMessage } = useContext(AppContext);
  const [datotekaList, setDatotekaList] = useState([]);
  const [obavestenjeDetails, setObavestenjeDetails] = useState<ObavestenjeDetailsDto>();
  const [obavestenjeDetailsLoading, setObavestenjeDetailsLoading] = useState(true);
  const { axiosGetObavestenjeDatotekaList, axiosGetObavestenjeDetails, axiosGetObavestenjeDatoteka } = ObavestenjeController();
  const obavestenjeIdRef = useRef<number>(-1);
  useEffect(() => {
    if (obavestenje?.id && obavestenjeIdRef.current !== obavestenje?.id) {
      obavestenjeIdRef.current = obavestenje.id;
      let startTime = moment();
      setObavestenjeDetailsLoading(true);
      const requestObavestenjeDatotekaList = axiosGetObavestenjeDatotekaList(obavestenje.id);
      const requestObavestenjeDetails = axiosGetObavestenjeDetails(obavestenje.id);
      axios
        .all([requestObavestenjeDatotekaList, requestObavestenjeDetails])
        .then(
          axios.spread((responseObavestenjeDatotekaList: any, responseObavestenjeDetails: any) => {
            setDatotekaList(responseObavestenjeDatotekaList.data.data);
            setObavestenjeDetails(responseObavestenjeDetails.data.data);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setObavestenjeDetailsLoading, startTime);
        });
    }
  }, [obavestenje, axiosGetObavestenjeDatotekaList, showMessage, axiosGetObavestenjeDetails]);

  const Labels = useLabels();
  const renderDateTimeFormatFull = (date: Date) => (date ? moment(date).format(DATE_TIME_FORMAT_FULL) : "");

  const renderUloge = (obavestenjeUlogaList: Array<ObavestenjeUlogaReadDto> | undefined) => {
    let returnVal: string = "";
    obavestenjeUlogaList?.forEach((obavestenjeUloga) => {
      returnVal += obavestenjeUloga ? obavestenjeUloga.uloga.naziv + "\n" : 0;
    });
    if (returnVal === "") {
      returnVal = Labels.CEKAONICA_COLUMN_SVE;
    }
    return returnVal;
  };

  const renderOrganizacioneJedinice = (obavestenjeOrganizacionaJedinicaList: Array<ObavestenjeOrganizacionaJedinicaReadDto> | undefined) => {
    let returnVal: string = "";
    obavestenjeOrganizacionaJedinicaList?.forEach((obavestenjeOJ) => {
      returnVal += obavestenjeOJ ? obavestenjeOJ.organizacionaJedinica.naziv + "\n" : 0;
    });
    if (returnVal === "") {
      returnVal = Labels.CEKAONICA_COLUMN_SVE;
    }
    return returnVal;
  };

  const handleDatotekaClick = (datoteka: ObavestenjeDatotekaReadDto) => {
    axiosGetObavestenjeDatoteka(datoteka.id)
      .then((res) => {
        const link = document.createElement("a");
        const byteChars = atob(res.data.data.sadrzaj);
        const dataArray = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
          dataArray[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(dataArray);
        let doc = new Blob([byteArray], { type: datoteka.tip });
        const url = URL.createObjectURL(doc);
        link.download = datoteka.naziv;
        link.href = url;
        link.click();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {});
  };

  return {
    renderDateTimeFormatFull,
    renderUloge,
    renderOrganizacioneJedinice,
    datotekaList,
    obavestenjeDetails,
    setObavestenjeDetails,
    obavestenjeDetailsLoading,
    handleDatotekaClick,
  };
}
