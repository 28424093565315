import { AxiosResponse } from "axios";
import moment from "moment";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { useContext, useEffect, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { handleAxiosCallError, skeletonTimeout } from "../../../../infrastructure/system/Utils";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import UlogaPravoNadTipomReadDto from "../../../../model/uloga/UlogaPravoNadTipomReadDto";
import SkeletonCheckboxItem from "../../../app/skeleton/SkeletonCheckboxItem";
import SkeletonPanelItem from "../../../app/skeleton/SkeletonPanelItem";
import CrudUlogaPravoNadTipovimaLogical, { VrstaPravaTipObjektaCell } from "./CrudUlogaPravoNadTipovimaLogical";

const TYPE = {
  CELL: "cell",
  ROW: "row",
  COLUMN: "column",
};
interface CrudUlogaPravoNadTipovimaViewProps {
  ulogaId?: number;
  isDisabled: boolean;
  ustanovaId?: number;
}
export default function CrudUlogaPravoNadTipovimaView(props: CrudUlogaPravoNadTipovimaViewProps) {
  const { isDisabled, ulogaId, ustanovaId } = props;
  const {
    vrstaPravaList,
    tipObjektaList,
    grid,
    createUlogaPravoNadTipom,
    deleteUlogaPravoNadTipom,
    tipObjektaListLoading,
    vrstaPravaListLoading,
    createAllUlogaPravoNadTipomForTipObjekta,
    createAllUlogaPravoNadTipomForVrstaPrava,
    deleteAllUlogaPravoNadTipomForTipObjekta,
    deleteAllUlogaPravoNadTipomForVrstaPrava,
    setGrid,
  } = CrudUlogaPravoNadTipovimaLogical({
    ulogaId: ulogaId,
    ustanovaId: ustanovaId,
  });
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const noSkeleton: boolean = !tipObjektaListLoading && !vrstaPravaListLoading;
  const [loadingData, setLoadingData] = useState<boolean>(true);
  // if (grid !== undefined) {
  //   skeletonTimeout(setLoadingData, moment(new Date()), 300);
  // }
  useEffect(() => {
    skeletonTimeout(setLoadingData, moment(new Date()), 300);
  }, [grid]);
  return (
    <div className={isDisabled ? "read-only" : ""}>
      <div className="grid flex-column">
        <div className="col">
          {noSkeleton ? (
            <>
              <div className="grid mt-0 text-center">
                <div className="col shadow-1">{Labels.LABEL_TIPOVI_PRAVA}</div>
                {vrstaPravaList?.map((vrstaPrava: EnumBaseReadDto, i: number) => (
                  <CheckboxCustom
                    createUlogaPravoNadTipom={createUlogaPravoNadTipom}
                    createAllUlogaPravoNadTipomForTipObjekta={createAllUlogaPravoNadTipomForTipObjekta}
                    createAllUlogaPravoNadTipomForVrstaPrava={createAllUlogaPravoNadTipomForVrstaPrava}
                    deleteUlogaPravoNadTipom={deleteUlogaPravoNadTipom}
                    deleteAllUlogaPravoNadTipomForVrstaPrava={deleteAllUlogaPravoNadTipomForVrstaPrava}
                    deleteAllUlogaPravoNadTipomForTipObjekta={deleteAllUlogaPravoNadTipomForTipObjekta}
                    ulogaId={ulogaId}
                    key={i}
                    isDisabled={!pristup || isDisabled}
                    label={vrstaPrava.naziv}
                    type={TYPE.COLUMN}
                    grid={grid}
                    setGrid={setGrid}
                    identifier={i}
                    sifra={vrstaPrava.sifra}
                    loadingData={loadingData}
                    setLoadingData={setLoadingData}
                  />
                ))}
              </div>
              {tipObjektaList?.map((tipObjekta: EnumBaseReadDto, i: number) => {
                return (
                  <div key={i} className="grid mt-0 text-center ">
                    <CheckboxCustom
                      createUlogaPravoNadTipom={createUlogaPravoNadTipom}
                      createAllUlogaPravoNadTipomForTipObjekta={createAllUlogaPravoNadTipomForTipObjekta}
                      createAllUlogaPravoNadTipomForVrstaPrava={createAllUlogaPravoNadTipomForVrstaPrava}
                      deleteUlogaPravoNadTipom={deleteUlogaPravoNadTipom}
                      deleteAllUlogaPravoNadTipomForVrstaPrava={deleteAllUlogaPravoNadTipomForVrstaPrava}
                      deleteAllUlogaPravoNadTipomForTipObjekta={deleteAllUlogaPravoNadTipomForTipObjekta}
                      ulogaId={ulogaId}
                      key={i}
                      isDisabled={!pristup || isDisabled}
                      label={tipObjekta.naziv}
                      type={TYPE.ROW}
                      grid={grid}
                      setGrid={setGrid}
                      identifier={i}
                      sifra={tipObjekta.sifra}
                      loadingData={loadingData}
                      setLoadingData={setLoadingData}
                    />
                    {[...Array(vrstaPravaList?.length ?? 0).keys()].map((j: number) => {
                      return (
                        <CheckboxCustom
                          createUlogaPravoNadTipom={createUlogaPravoNadTipom}
                          createAllUlogaPravoNadTipomForTipObjekta={createAllUlogaPravoNadTipomForTipObjekta}
                          createAllUlogaPravoNadTipomForVrstaPrava={createAllUlogaPravoNadTipomForVrstaPrava}
                          deleteUlogaPravoNadTipom={deleteUlogaPravoNadTipom}
                          deleteAllUlogaPravoNadTipomForVrstaPrava={deleteAllUlogaPravoNadTipomForVrstaPrava}
                          deleteAllUlogaPravoNadTipomForTipObjekta={deleteAllUlogaPravoNadTipomForTipObjekta}
                          key={j}
                          isDisabled={!pristup || isDisabled}
                          ulogaId={ulogaId}
                          type={TYPE.CELL}
                          grid={grid}
                          setGrid={setGrid}
                          identifier={i}
                          identifierString={i + ":" + j}
                          loadingData={loadingData}
                          setLoadingData={setLoadingData}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </>
          ) : (
            <SkeletonPanelItem />
          )}
        </div>
      </div>
    </div>
  );
}

interface CheckboxCustomProps {
  type: string;
  label?: string;
  grid?: Array<Array<VrstaPravaTipObjektaCell>>;
  setGrid: React.Dispatch<React.SetStateAction<VrstaPravaTipObjektaCell[][] | undefined>>;
  sifra?: string;
  ulogaId?: number;
  identifier: number;
  identifierString?: String;
  createUlogaPravoNadTipom: (cell: VrstaPravaTipObjektaCell) => Promise<AxiosResponse<any>>;
  createAllUlogaPravoNadTipomForTipObjekta: (tipObjekta: EnumBaseReadDto) => Promise<AxiosResponse<any>>;
  createAllUlogaPravoNadTipomForVrstaPrava: (vrstaPrava: EnumBaseReadDto) => Promise<AxiosResponse<any>>;
  deleteUlogaPravoNadTipom: (cell: VrstaPravaTipObjektaCell) => Promise<AxiosResponse<any>>;
  deleteAllUlogaPravoNadTipomForVrstaPrava: (vrstaPrava: EnumBaseReadDto) => Promise<AxiosResponse<any>>;
  deleteAllUlogaPravoNadTipomForTipObjekta: (tipObjekta: EnumBaseReadDto) => Promise<AxiosResponse<any>>;
  loadingData: boolean;
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
}

function CheckboxCustom(props: CheckboxCustomProps) {
  const {
    label,
    type,
    identifierString,
    identifier,
    grid,
    sifra,
    createUlogaPravoNadTipom,
    deleteUlogaPravoNadTipom,
    loadingData,
    setLoadingData,
    isDisabled,
    createAllUlogaPravoNadTipomForTipObjekta,
    createAllUlogaPravoNadTipomForVrstaPrava,
    deleteAllUlogaPravoNadTipomForTipObjekta,
    deleteAllUlogaPravoNadTipomForVrstaPrava,
    setGrid,
  } = props;
  const getYX = () => {
    if (!identifierString) return { x: 0, y: 0 };
    const coordinates = identifierString.split(":");
    return { y: Number(coordinates[0]), x: Number(coordinates[1]) };
  };

  const { showMessage } = useContext(AppContext);

  const getValue = () => {
    if (!grid || grid.length === 0) return false;
    switch (type) {
      case TYPE.CELL:
        const { y, x } = getYX();
        return grid[y][x].check;
      case TYPE.ROW:
        return !grid[identifier].some((cell: VrstaPravaTipObjektaCell) => !cell.check);
      case TYPE.COLUMN:
        return !grid.some((row: Array<VrstaPravaTipObjektaCell>) => !row.some((cell: VrstaPravaTipObjektaCell) => cell.vrstaPrava.sifra === sifra && cell.check));
      default:
        console.error("Undefined type");
        return false;
    }
  };

  const handleOnChange = (e: CheckboxChangeEvent) => {
    setLoadingData(true);
    const { checked } = e.target;
    if (!grid || grid.length === 0) {
      return;
    }
    switch (type) {
      case TYPE.CELL:
        const { y, x } = getYX();
        if (checked) {
          createUlogaPravoNadTipom(grid[y][x])
            .then((res: any) => {
              const ulogaPravoList = res.data.data;
              const vrstaPravaList = ulogaPravoList.map((ulogaPravo: UlogaPravoNadTipomReadDto) => ulogaPravo.vrstaPrava);
              vrstaPravaList.forEach((vrstaPrava: EnumBaseReadDto) => {
                setGrid((prevGrid) => {
                  const newGrid = [...prevGrid!];
                  newGrid[y][vrstaPrava.id - 1].check = checked;
                  return newGrid;
                });
              });
              skeletonTimeout(setLoadingData, moment(new Date()), 300);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
            });
        } else {
          deleteUlogaPravoNadTipom(grid[y][x])
            .then((res: any) => {
              const ulogaPravoList = res.data.data;
              const vrstaPravaList = ulogaPravoList.map((ulogaPravo: UlogaPravoNadTipomReadDto) => ulogaPravo.vrstaPrava);
              vrstaPravaList.forEach((pravo: EnumBaseReadDto) => {
                setGrid((prevGrid) => {
                  const newGrid = [...prevGrid!];
                  newGrid[y][pravo.id - 1].check = checked ? checked : false;
                  return newGrid;
                });
              });
              skeletonTimeout(setLoadingData, moment(new Date()), 300);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
            });
        }
        break;
      case TYPE.ROW:
        if (checked) {
          createAllUlogaPravoNadTipomForTipObjekta([...grid][identifier][0].tipObjekta)
            .then((res: any) => {
              const ulogaPravoList = res.data.data;

              checkBoxesForUlogaPravoList(ulogaPravoList, true);

              skeletonTimeout(setLoadingData, moment(new Date()), 300);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
            });
        } else {
          deleteAllUlogaPravoNadTipomForTipObjekta([...grid][identifier][0].tipObjekta)
            .then((res: any) => {
              const ulogaPravoList = res.data.data;

              checkBoxesForUlogaPravoList(ulogaPravoList, false);

              skeletonTimeout(setLoadingData, moment(new Date()), 300);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
            });
        }
        break;
      case TYPE.COLUMN:
        if (checked) {
          createAllUlogaPravoNadTipomForVrstaPrava([...grid][0][identifier].vrstaPrava)
            .then((res: any) => {
              const ulogaPravoList = res.data.data;

              checkBoxesForUlogaPravoList(ulogaPravoList, true);

              skeletonTimeout(setLoadingData, moment(new Date()), 300);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
            });
        } else {
          deleteAllUlogaPravoNadTipomForVrstaPrava([...grid][0][identifier].vrstaPrava)
            .then((res: any) => {
              const ulogaPravoList: UlogaPravoNadTipomReadDto[] = res.data.data;

              checkBoxesForUlogaPravoList(ulogaPravoList, false);

              skeletonTimeout(setLoadingData, moment(new Date()), 300);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
            });
        }
        break;
      default:
        console.error("Undefined type");
    }
  };

  function checkBoxesForUlogaPravoList(ulogaPravoList: UlogaPravoNadTipomReadDto[], checked: boolean) {
    const vrstaPravaList: EnumBaseReadDto[] = ulogaPravoList
      .map((ulogaPravo: UlogaPravoNadTipomReadDto) => ulogaPravo.vrstaPrava)
      .filter((obj: EnumBaseReadDto, index: number, self: Array<EnumBaseReadDto>) => self.findIndex((o) => o.id === obj.id) === index);

    const tipObjektaList: EnumBaseReadDto[] = ulogaPravoList
      .map((ulogaPravo: UlogaPravoNadTipomReadDto) => ulogaPravo.tipObjekta)
      .filter((obj: EnumBaseReadDto, index: number, self: Array<EnumBaseReadDto>) => self.findIndex((o) => o.id === obj.id) === index);

    vrstaPravaList.forEach((vrstaPrava: EnumBaseReadDto) => {
      tipObjektaList.forEach((tipObjekta: EnumBaseReadDto) => {
        setGrid((prevGrid) => {
          const newGrid = [...prevGrid!];
          newGrid[tipObjekta.id - 1][vrstaPrava.id - 1].check = checked;
          return newGrid;
        });
      });
    });
  }

  return (
    <>
      {loadingData ? (
        <div className={"col shadow-1 " + (type === TYPE.ROW ? "text-left" : "")}>
          <div className=" p-checkbox">
            <SkeletonCheckboxItem />
          </div>
          {label && (
            <label htmlFor={label} className="cursor-pointer p-checkbox-label text-left pl-1 ">
              {label}
            </label>
          )}
        </div>
      ) : (
        <div className={"col shadow-1 " + (type === TYPE.ROW ? "text-left flex" : "")}>
          <Checkbox inputId={label} disabled={loadingData || isDisabled} checked={getValue()} onChange={handleOnChange} />
          {label && (
            <label htmlFor={label} className="cursor-pointer p-checkbox-label pl-1">
              {label}
            </label>
          )}
        </div>
      )}
    </>
  );
}
