import moment from "moment";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { RadioButton } from "primereact/radiobutton";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import KontrolaPravaPristupaService from "../../../infrastructure/system/KontrolaPravaPristupaService";
import { DATE_TIME_FORMAT, tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import NesacuvanNalaz from "../../../model/NesacuvanNalaz/NesacuvanNalaz";
import EnumNesacuvanNalaz from "../../../model/enumFront/EnumNesacuvanNalaz";
import FormularPopunjenReadDto from "../../../model/formular/FormularPopunjenReadDto";
import FormularReadDto from "../../../model/formular/FormularReadDto";
import KontaktReadDto from "../../../model/kontakt/KontaktReadDto";
import PacijentReadDto from "../../../model/pacijent/PacijentReadDto";
import SkeletonRectangles from "../../app/skeleton/SkeletonRectangles";
import DialogComponent from "../../dialog-component/DialogComponent";
import Unos from "./Unos";
import UnosiLogical from "./UnosiLogical";
import { confirmPopup } from "primereact/confirmpopup";

interface UnosListType {
  formularList: Array<FormularReadDto> | undefined;
  kontakt: KontaktReadDto;
  setClickOutsideDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  clickOutsideDisabled: boolean;
  poljeVrednostListRef: React.MutableRefObject<any>;
  nesacuvanNalazState: NesacuvanNalaz | undefined;
  izabraniTipUnosaRef: React.MutableRefObject<any>;
  izabraniPopunjeniUnosRef: React.MutableRefObject<any>;
  isFirstRenderNalaz: boolean;
  setIsFirstRenderNalaz: React.Dispatch<React.SetStateAction<boolean>>;
  setNesacuvanNalazState: React.Dispatch<React.SetStateAction<NesacuvanNalaz | undefined>>;
  pacijent: PacijentReadDto;
  kreiraniUnosList: Array<FormularPopunjenReadDto> | undefined;
  setKreiraniUnosList: React.Dispatch<React.SetStateAction<Array<FormularPopunjenReadDto> | undefined>>;
}

export default function UnosList(props: UnosListType) {
  const {
    formularList,
    kontakt,
    clickOutsideDisabled,
    setClickOutsideDisabled,
    poljeVrednostListRef,
    nesacuvanNalazState,
    izabraniTipUnosaRef,
    izabraniPopunjeniUnosRef,
    isFirstRenderNalaz,
    setIsFirstRenderNalaz,
    setNesacuvanNalazState,
    pacijent,
    kreiraniUnosList,
    setKreiraniUnosList,
  } = props;
  const {
    showNoviUnosDialog,
    setShowNoviUnosDialog,
    izabraniTipUnosa,
    setIzabraniTipUnosa,
    showNoviUnosPage,
    setShowNoviUnosPage,
    setIzabraniPopunjeniUnos,
    izabraniPopunjeniUnos,
    onSaveFormular,
    maximizeScreen,
    setMaximizeScreen,
    showPrethodniNalaziSabloni,
    openClosePrethodniSabloniNalazi,
    dataLoading,
    deleteFormularPopunjen,
    openLeaveDialog,
    closeLeaveDialog,
    visibleLeaveDialog,
    confirmLeave,
    nesacuvanNalaz,
    setVisibleNesacuvanNalazDialog,
    visibleNesacuvanNalazDialog,
    checkLocalStorageForNapustenNalaz,
    izabranNesacuvanNalazOption,
    setIzabranNesacuvanNalazOption,
    onConfirmNesacuvanNalazDialog,
    stampaj,
    formatPoljePopunjenoList,
  } = UnosiLogical({
    setClickOutsideDisabled,
    nesacuvanNalazState,
    setNesacuvanNalazState,
    izabraniTipUnosaRef,
    izabraniPopunjeniUnosRef,
    isFirstRenderNalaz,
    setIsFirstRenderNalaz,
    pacijent,
    kreiraniUnosList,
    setKreiraniUnosList,
    formularList,
    kontakt,
  });

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const { hasFormularPopunjenCreate } = KontrolaPravaPristupaService();

  const footer = (
    <div className="layout-pacijent-dosije">
      <Button
        label={Labels.KONTAKT_ODUSTANI}
        className="p-button-outlined p-button-tertiary align-self-center"
        onClick={() => {
          setShowNoviUnosDialog(false);
        }}
      />
      <Button
        label={Labels.KONTAKT_IDI}
        className="p-button-success"
        onClick={() => {
          setShowNoviUnosPage(true);
          setShowNoviUnosDialog(false);
        }}
        disabled={izabraniTipUnosa === undefined}
      />
    </div>
  );

  const nesacuvanNalazfooter = (
    <div className="layout-pacijent-dosije">
      <Button
        label={Labels.KONTAKT_ODUSTANI}
        className="p-button-outlined p-button-tertiary align-self-center"
        onClick={() => {
          setVisibleNesacuvanNalazDialog(false);
        }}
      />
      <Button
        label={Labels.POTVRDI}
        className="p-button-success"
        onClick={() => {
          onConfirmNesacuvanNalazDialog();
        }}
        disabled={!pristup || izabranNesacuvanNalazOption === undefined}
      />
    </div>
  );

  const nesacuvanNalazOptionList = [
    { label: Labels.OTVORITE_NESACUVAN_NALAZ, value: EnumNesacuvanNalaz.OTVORI_NALAZ },
    { label: Labels.NASTAVITE_BEZ_CUVANJA_NALAZA, value: EnumNesacuvanNalaz.NASTAVI_BEZ_CUVANJA },
  ];

  return (
    <div>
      {dataLoading ? (
        <SkeletonRectangles />
      ) : (
        <>
          {!showNoviUnosPage && (
            <>
              <div className="flex flex-column w-full p-0">
                <div className="flex justify-content-between align-items-center w-full p-0">
                  <div className="flex col-2 p-0 align-items-center justify-content-start font-bold">{Labels.KONTAKT_NAPRAVLJENI_NALAZI}</div>
                  <div className="flex col-2 p-0 align-items-center justify-content-end">
                    <Button
                      label={Labels.KONTAKT_NOVI_NALAZ}
                      tooltip={Labels.KONTAKT_KREIRAJ_NOVI_NALAZ}
                      tooltipOptions={tooltipOptionsBottom}
                      className="p-button-success"
                      onClick={() => {
                        checkLocalStorageForNapustenNalaz(() => {
                          setIzabraniPopunjeniUnos(undefined);
                          if (formularList?.length === 1) {
                            setIzabraniTipUnosa(formularList[0]);
                            setShowNoviUnosPage(true);
                          } else {
                            setShowNoviUnosDialog(true);
                          }
                        });
                      }}
                      disabled={!pristup || !hasFormularPopunjenCreate}
                    />
                  </div>
                </div>
                <div className="flex mt-2 flex-column w-full p-0">
                  {kreiraniUnosList &&
                    kreiraniUnosList.length > 0 &&
                    kreiraniUnosList.map((unos: FormularPopunjenReadDto, index: number) => {
                      return (
                        <div key={index}>
                          <div className="flex justify-content-between align-items-center w-full p-0 mt-2">
                            <div className="flex col-fixed p-0">
                              <div className="">
                                <div className="flex col-12 p-0 align-items-center">
                                  {Labels.NALAZ}: <span className="ml-2 font-bold">{unos?.formular?.naziv ?? ""}</span>
                                </div>
                                <div className="flex col-fixed p-0 align-items-center justify-content-start">
                                  <div className="flex p-0 align-items-center justify-content-start mr-2">{`${moment(unos?.vremeKreiranja ?? new Date()).format(DATE_TIME_FORMAT)}`}</div>
                                  <div className="flex p-0 align-items-center justify-content-start">
                                    {`${unos?.radnikKreirao?.titula ?? ""} ${unos?.radnikKreirao?.ime ?? ""} ${unos?.radnikKreirao?.prezime ?? ""}`}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex col-4 p-0 align-items-center justify-content-end">
                              <div className="col-3 flex justify-content-end p-0">
                                <div className="flex flex-row h-2rem">
                                  <Button
                                    type="button"
                                    tooltip={Labels.BUTTON_UPDATE}
                                    tooltipOptions={tooltipOptionsTop}
                                    className="p-button-warning mr-2 p-button-small-size"
                                    icon="pi pi-pencil"
                                    onClick={() => {
                                      checkLocalStorageForNapustenNalaz(() => {
                                        setIzabraniTipUnosa(unos.formular);
                                        setIzabraniPopunjeniUnos(unos);
                                        setShowNoviUnosPage(true);
                                      });
                                    }}
                                  />
                                  <Button
                                    type="button"
                                    tooltip={Labels.PREGLED_NALAZA_STAMPAJ}
                                    tooltipOptions={tooltipOptionsTop}
                                    className="p-button-info mr-2 p-button-small-size"
                                    icon="pi pi-print"
                                    onClick={() => stampaj(unos?.formular, unos)}
                                    disabled={!unos?.formular.jrDokument && !unos?.formular?.htmlPredlozak}
                                  />
                                  <Button
                                    type="button"
                                    tooltip={Labels.ISTORIJA_VERZIJA}
                                    tooltipOptions={tooltipOptionsTop}
                                    className="p-button-secondary mr-2 p-button-small-size"
                                    icon="pi pi-history"
                                    onClick={() => {}}
                                    disabled
                                    visible={false}
                                  />
                                  <Button
                                    type="button"
                                    tooltip={Labels.BUTTON_DELETE}
                                    tooltipOptions={tooltipOptionsTop}
                                    className="p-button-danger p-button-small-size"
                                    icon="pi pi-trash"
                                    onClick={(event) => {
                                      confirmPopup({
                                        target: event.currentTarget,
                                        message: Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_POPUNJEN_FORMULAR,
                                        icon: "pi pi-exclamation-triangle",
                                        acceptClassName: "p-button p-component text-white p-button-tertiary",
                                        rejectClassName: "p-button p-component p-button-outlined p-button-tertiary",
                                        acceptLabel: Labels.YES,
                                        rejectLabel: Labels.NO,
                                        accept: () => deleteFormularPopunjen(unos.id),
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <Divider />
                        </div>
                      );
                    })}
                </div>
              </div>
              <DialogComponent
                header={Labels.KONTAKT_NOVI_NALAZ}
                visible={showNoviUnosDialog}
                onHide={() => setShowNoviUnosDialog(false)}
                footer={footer}
                content={
                  <>
                    {formularList?.map((unos: FormularReadDto) => {
                      return (
                        <div key={unos.id} className="mt-2">
                          <RadioButton
                            inputId={unos?.sifra}
                            name="category"
                            value={unos}
                            onChange={(e) => setIzabraniTipUnosa(e.value)}
                            checked={izabraniTipUnosa?.sifra === unos?.sifra}
                            disabled={!pristup || false}
                          />
                          <label htmlFor={unos?.sifra} className="cursor-pointer ml-2">
                            {unos.naziv}
                          </label>
                        </div>
                      );
                    })}
                  </>
                }
              />
              <DialogComponent
                header={Labels.NESACUVAN_NALAZ}
                visible={visibleNesacuvanNalazDialog}
                onHide={() => setVisibleNesacuvanNalazDialog(false)}
                footer={nesacuvanNalazfooter}
                content={
                  <>
                    {`${Labels.ZA_PACIJENTA} ${nesacuvanNalaz?.pacijentIme} ${nesacuvanNalaz?.pacijentPrezime} ${Labels.POSTOJI_NESACUVAN_NALAZ} ${nesacuvanNalaz?.izabraniTipUnosa.naziv}. ${Labels.DA_LI_ZELITE_DA}`}
                    {nesacuvanNalazOptionList.map((nesacuvanNalazOption, index) => {
                      return (
                        <div key={index} className="mt-2">
                          <RadioButton
                            inputId={nesacuvanNalazOption.value.toString()}
                            name="label"
                            value={nesacuvanNalazOption}
                            onChange={(e) => setIzabranNesacuvanNalazOption(e.value)}
                            checked={izabranNesacuvanNalazOption?.value === nesacuvanNalazOption.value}
                            disabled={false}
                          />
                          <label htmlFor={nesacuvanNalazOption.value.toString()} className="cursor-pointer ml-2">
                            {nesacuvanNalazOption.label}
                          </label>
                        </div>
                      );
                    })}
                  </>
                }
              />
            </>
          )}
          {showNoviUnosPage && izabraniTipUnosa && (
            <Unos
              openLeaveDialog={openLeaveDialog}
              closeLeaveDialog={closeLeaveDialog}
              visibleLeaveDialog={visibleLeaveDialog}
              confirmLeave={confirmLeave}
              clickOutsideDisabled={clickOutsideDisabled}
              setClickOutsideDisabled={setClickOutsideDisabled}
              kontakt={kontakt}
              maximizeScreen={maximizeScreen}
              setMaximizeScreen={setMaximizeScreen}
              onSave={onSaveFormular}
              formularPopunjen={izabraniPopunjeniUnos}
              setShowNoviUnosPage={setShowNoviUnosPage}
              izabraniTipUnosa={izabraniTipUnosa}
              showPrethodniNalaziSabloni={showPrethodniNalaziSabloni}
              openClosePrethodniSabloniNalazi={openClosePrethodniSabloniNalazi}
              deleteFormularPopunjen={deleteFormularPopunjen}
              poljeVrednostListRef={poljeVrednostListRef}
              nesacuvanNalazState={nesacuvanNalazState}
              izabraniTipUnosaRef={izabraniTipUnosaRef}
              izabraniPopunjeniUnosRef={izabraniPopunjeniUnosRef}
              setNesacuvanNalazState={setNesacuvanNalazState}
              stampaj={stampaj}
              formatPoljePopunjenoList={formatPoljePopunjenoList}
            />
          )}
        </>
      )}
    </div>
  );
}
