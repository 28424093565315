import moment from "moment";
import { useContext } from "react";
import { AppContext, useLabels } from "../../Store";
import MessageType from "./MessageType";
import { createCurrentDateWithTime } from "./Utils";

interface RadnoVremeUtilsType {
  getRadnoVremeValue: (radnoVreme: string | undefined) => Date | undefined;
  validateRadnoVreme: (radnoVremeOd: Date | null, radnoVremeDo: Date | null) => boolean;
}

export default function RadnoVremeUtils(): RadnoVremeUtilsType {
  const Labels = useLabels();
  const { showMessage } = useContext(AppContext);

  const getRadnoVremeValue = (radnoVreme: string | undefined) => {
    return radnoVreme ? createCurrentDateWithTime(radnoVreme) : undefined;
  };

  const validateRadnoVreme = (radnoVremeOd: Date | null, radnoVremeDo: Date | null) => {
    if (!radnoVremeOd && radnoVremeDo) {
      showMessage(MessageType.ERROR, Labels.RADNO_VREME_OD_NOT_EXISTS);
      return true;
    }

    if (radnoVremeOd && !radnoVremeDo) {
      showMessage(MessageType.ERROR, Labels.RADNO_VREME_DO_NOT_EXISTS);
      return true;
    }

    if (radnoVremeOd && radnoVremeDo && !moment(radnoVremeOd).isBefore(radnoVremeDo)) {
      showMessage(MessageType.ERROR, Labels.RADNO_VREME_NOT_VALID);
      return true;
    }

    return false;
  };

  return {
    getRadnoVremeValue,
    validateRadnoVreme,
  };
}
