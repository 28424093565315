import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { Toolbar } from "primereact/toolbar";
import AvatarEditor from "react-avatar-editor";
import { useLabels } from "../../Store";
import { imageExtention } from "../../infrastructure/system/Utils";
import EnumBaseReadDto from "../../model/sifarnik/EnumBaseReadDto";
import DialogComponent from "../dialog-component/DialogComponent";
import RadnikProfilLogical from "./RadnikProfilLogical";
import RadnikProfilPasswordChange from "./RadnikProfilPasswordChangeView";

export default function RadnikProfilePage() {
  const {
    changeRadnikData,
    editRadnik,
    radnikChange,
    setRadnikChange,
    cancelEdit,
    dialogClose,
    dialogVisible,
    displayDialog,
    _handleFileChange,
    handleScale,
    handleSave,
    setEditorRef,
    avatar,
    avatarChangeVisible,
    avatarVisible,
    avatarNotVisible,
    jezikList,
    polList,
  } = RadnikProfilLogical();

  const Labels = useLabels();

  const leftContents = () => (
    <>
      <Button label={Labels.RADNIK_PROFIL_BUTTON_SAVE} tooltip={Labels.SAVE_RADNIK_DATA} className="p-button-success button-save" onClick={editRadnik} />
      <Button label={Labels.RADNIK_PROFIL_BUTTON_CHANGE_PASSWORD} tooltip={Labels.TITLE_SET_NEW_PASSWORD} className="p-button-primary button-for-password" onClick={dialogVisible} />
    </>
  );

  const rightContents = () => <Button label={Labels.RADNIK_PROFIL_BUTTON_CANCEL} tooltip={Labels.CANCEL_RADNIK_DATA} className="p-button-danger button-cancel-update" onClick={cancelEdit} />;
  return (
    <div className="layout-radnik">
      <Panel header={Labels.RADNIK_PROFIL_CHANGE} className="panel-width">
        <div className="grid ">
          <div className="xl:col-4 lg:col-4 md:col-4 sm:col-12 col-12 justify-content-center flex">
            {!avatarChangeVisible && (
              <div className="hc-sm:w-8 hc-xl:w-full hc-lg:w-full hc-md:w-full w-8 cursor-pointer">
                {radnikChange && radnikChange?.profilnaSlika ? (
                  <Avatar
                    image={`data:image/${imageExtention(radnikChange.profilnaSlika!)};base64,${radnikChange.profilnaSlika}`}
                    size="xlarge"
                    shape="circle"
                    className="w-full avatar"
                    onClick={avatarVisible}
                    imageAlt={`${radnikChange?.ime.charAt(0)}${radnikChange?.prezime.charAt(0)}`}
                  />
                ) : (
                  <Avatar className="avatarBorder w-full avatar" onClick={avatarVisible} label={`${radnikChange?.ime.charAt(0)}${radnikChange?.prezime.charAt(0)}`} size="xlarge" shape="circle" />
                )}
              </div>
            )}

            {avatarChangeVisible && (
              <div>
                <div className="grid justify-content-center">
                  <AvatarEditor
                    className="mb-3 avatar-editor-size"
                    ref={setEditorRef}
                    image={avatar.img}
                    width={200}
                    height={200}
                    border={50}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={avatar.zoom}
                    rotate={0}
                  />
                </div>
                <br />
                <div className="grid justify-content-center">
                  <input hidden id="file" type="file" accept="image/*" onChange={_handleFileChange} />
                  <Button className="button-upload-image btn-small">
                    <label htmlFor="file">{Labels.BUTTON_UPLOAD_IMAGE}</label>
                  </Button>

                  <Button
                    className="button-save-picture btn-small"
                    label={Labels.BUTTON_SAVE}
                    onClick={() => {
                      handleSave();
                    }}
                  ></Button>
                  <Button className="button-cancel-picture btn-small" label={Labels.BUTTON_CANCEL} onClick={avatarNotVisible}></Button>
                </div>
                <br />
                <div className="grid justify-content-center">
                  <input name="scale" type="range" onChange={handleScale} min={avatar.allowZoomOut ? "0.1" : "1"} max="2" step="0.01" defaultValue="1" />
                </div>
              </div>
            )}
          </div>
          <div className="xl:col-4 lg:col-4 md:col-4 sm:col-12 col-12 radnik-data">
            <div className="mb-4">
              <label className="edit-radnik-label">{Labels.RADNIK_PROFIL_CHANGE_EMAIL}</label>
              <InputText name="email" className="col-12" value={radnikChange.email ?? ""} disabled={true} />
            </div>
            <div className="mb-4">
              <label className="edit-radnik-label">{Labels.RADNIK_PROFIL_CHANGE_IME}</label>
              <InputText name="ime" className="col-12" value={radnikChange.ime ?? ""} onChange={changeRadnikData} />
            </div>
            <div className="mb-4">
              <label className="edit-radnik-label">{Labels.RADNIK_PROFIL_CHANGE_PREZIME}</label>
              <InputText name="prezime" className="col-12" value={radnikChange.prezime ?? ""} onChange={changeRadnikData} />
            </div>
            <div className="mb-4">
              <label className="edit-radnik-label">{Labels.HEADER_PHONE}</label>
              <InputText name="telefon" className="col-12" value={radnikChange.telefon ?? ""} onChange={changeRadnikData} />
            </div>
            <div className="mb-4">
              <label className="edit-radnik-label">{Labels.RADNIK_TITULA}</label>
              <InputText name="titula" className="col-12" value={radnikChange.titula ?? ""} onChange={changeRadnikData} />
            </div>
            <div className="mb-4">
              <label className="edit-radnik-label">{Labels.RADNIK_ZANIMANJE}</label>
              <InputText name="zanimanje" className="col-12" value={radnikChange.zanimanje ?? ""} onChange={changeRadnikData} />
            </div>
          </div>
          <div className="xl:col-4 lg:col-4 md:col-4 sm:col-12 col-12 radnik-data">
            <div className="mb-4">
              <label className="edit-radnik-label">{Labels.JMBG}</label>
              <InputText name="jmbg" className="col-12" value={radnikChange.jmbg ?? ""} onChange={changeRadnikData} />
            </div>
            <div className="mb-4">
              <label className="edit-radnik-label">{Labels.HEADER_LBO}</label>
              <InputText name="lbo" className="col-12" value={radnikChange.lbo ?? ""} onChange={changeRadnikData} />
            </div>
            <div className="mb-4">
              <label className="edit-radnik-label">{Labels.RADNIK_BROJ_PECATA}</label>
              <InputText name="idBrojLekara" className="col-12" value={radnikChange.idBrojLekara ?? ""} onChange={changeRadnikData} />
            </div>
            <div className="mb-3">
              <label className="edit-radnik-label-radioButton">{Labels.RADNIK_POL}</label>
              <div className="grid p-3">
                {polList.map((pol: EnumBaseReadDto) => (
                  <div key={pol.id}>
                    <RadioButton inputId={pol.sifra} name="polTrenutni" value={pol} checked={radnikChange.polTrenutni.id === pol.id} onChange={changeRadnikData} />
                    <label className="cursor-pointer mr-3 ml-1" htmlFor={pol.sifra}>
                      {pol.naziv}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <label className="edit-radnik-label-radioButton">{Labels.RADNIK_PROFIL_CHANGE_JEZIK}</label>
              <div className="grid p-3">
                {jezikList.map((jezik: EnumBaseReadDto) => (
                  <div key={jezik.id}>
                    <RadioButton inputId={jezik.sifra} name="jezik" value={jezik} checked={radnikChange.jezik.id === jezik.id} onChange={changeRadnikData} />
                    <label className="cursor-pointer mr-3 ml-1" htmlFor={jezik.sifra}>
                      {jezik.naziv}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <Checkbox
                inputId="integracija"
                checked={radnikChange?.integracijaSaKalendarom ?? false}
                onChange={(e) =>
                  setRadnikChange({
                    ...radnikChange!,
                    integracijaSaKalendarom: e.checked,
                  })
                }
              />
              <label htmlFor="integracija" className="cursor-pointer mr-3 ml-1">
                {Labels.RADNIK_KALENDAR_INTEGRACIJA}
              </label>
            </div>
          </div>
          <div className="col-12">
            <Toolbar left={leftContents} right={rightContents} />
          </div>
        </div>
        <DialogComponent
          header={Labels.RADNIK_PROFIL_CHANGE_PASSWORD + " (" + radnikChange.email + ")"}
          visible={displayDialog}
          onHide={dialogClose}
          className="password-dialog"
          content={<RadnikProfilPasswordChange currentRadnikId={radnikChange.id} onCancel={dialogClose} />}
          withoutFooter
        />
      </Panel>
    </div>
  );
}
