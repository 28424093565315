import AppBreadcrumbLogical from "./AppBreadcrumbLogical";

interface AppBreadcrumbType {
  routers: any;
}

export default function AppBreadcrumb(props: AppBreadcrumbType) {
  const { routers } = props;

  const { getName } = AppBreadcrumbLogical();

  return <span>{getName(routers)}</span>;
}
