import { useQuery } from "@tanstack/react-query";
import { SifarnikController } from "../../../../../controllers/sifarnik/SifarnikController";
import SifarnikValutaDto from "../../../../../model/repository/sifarnik-usluga/SifarnikValutaDto";
import QueryKeys from "../../../QueryKeys";

export function useValutaList() {
  const { axiosGetValutaList } = SifarnikController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.VALUTA_LIST],
    queryFn: () => axiosGetValutaList(),
    staleTime: Infinity,
  });

  const valutaList: SifarnikValutaDto[] = data?.data?.data ?? [];

  return { valutaList, isLoading };
}
