import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import SearchBaseDto from "../../model/SearchBaseDto";
import RadnikOrganizacionaJedinicaCreateDto from "../../model/radnik/RadnikOrganizacionaJedinicaCreateDto";
import RadnikPartialUpdateDto from "../../model/radnik/RadnikPartialUpdateDto";
import RadnikReadDto from "../../model/radnik/RadnikReadDto";
import RadnikUlogaCreateDto from "../../model/radnik/RadnikUlogaCreateDto";
import RadnikUstanovaCreateDto from "../../model/radnik/RadnikUstanovaCreateDto";
import RadnikSpecijalizacijaCreateDto from "../../model/radnik/RadnikSpecijalizacijaCreateDto";
import RadnikInicijalizacijaReadDto from "../../model/inicijalizacija/RadnikInicijalizacijaReadDto";

interface RadnikControllerType {
  axiosSearchRadnik: (radnikSearchData?: SearchBaseDto) => Promise<AxiosResponse<any>>;
  axiosSearchRadnikOrgJedRola: (organizacionaJedinicaId: number, enumRolaSifra: string) => Promise<AxiosResponse<any>>;
  axiosCreateRadnik: (radnikChange: any) => Promise<AxiosResponse<any>>;
  axiosCreateCopyRadnik: (radnikId: number, radnikCopyId: number) => Promise<AxiosResponse<any>>;
  axiosGetRadnik: (radnikId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateRadnik: (radnikChange: RadnikReadDto) => Promise<AxiosResponse<any>>;
  axiosDeleteRadnik: (radnikId: number) => Promise<AxiosResponse<any>>;
  axiosReadRadnik: (radnikId: number, token: string) => Promise<AxiosResponse<any>>;
  axiosGetRadnikUlogaList: (radnikId: number) => Promise<AxiosResponse<any>>;
  axiosCreateRadnikUloga: (radnikId: number, radnikUloga: RadnikUlogaCreateDto) => Promise<AxiosResponse<any>>;
  axiosDeleteRadnikUloga: (radnikId: number, ulogaId: number) => Promise<AxiosResponse<any>>;
  axiosGetRadnikOrganizacionaJedinicaList: (radnikId: number) => Promise<AxiosResponse<any>>;
  axiosCreateRadnikOrganizacionaJedinica: (radnikId: number, radnikOrganizacionaJedinica: RadnikOrganizacionaJedinicaCreateDto[]) => Promise<AxiosResponse<any>>;
  axiosDeleteRadnikOrganizacionaJedinica: (radnikId: number, organizacionaJedinicaIds: Array<number>) => Promise<AxiosResponse<any>>;
  axiosGetRadnikUstanovaList: (radnikId: number) => Promise<AxiosResponse<any>>;
  axiosCreateRadnikUstanova: (radnikID: number, radnikUstanova: RadnikUstanovaCreateDto) => Promise<AxiosResponse<any>>;
  axiosChangeUstanovaPoslednjeIzabrana: (ustanovaId: number) => Promise<AxiosResponse<any>>;
  axiosCreateRadnikUstanovaWithExistingRadnik: (radnikId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateRadnikAndCreateRadnikUstanova: (radnikChange: RadnikPartialUpdateDto) => Promise<AxiosResponse<any>>;
  axiosGetRadnikSpecijalizacijeList: (radnikID: number) => Promise<AxiosResponse<any>>;
  axiosCreateRadnikSpecijalizacija: (radnikID: number, radnikSpecijalizacijaList: Array<RadnikSpecijalizacijaCreateDto>) => Promise<AxiosResponse<any>>;
  axiosDeleteRadnikSpecijalizacija: (radnikID: number, specijalizacijaID: Array<number>) => Promise<AxiosResponse<any>>;
  axiosGetInicijalizacijaRadnikList: () => Promise<AxiosResponse>;
  axiosCreateInicijalizacijaRadnik: (radnik: RadnikInicijalizacijaReadDto) => Promise<AxiosResponse>;
  axiosUpdateInicijalizacijaRadnik: (radnik: RadnikInicijalizacijaReadDto) => Promise<AxiosResponse>;
}

export function RadnikController(): RadnikControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchRadnik = (radnikSearchData?: SearchBaseDto) => {
    return axios.get(Endpoint.RADNIK_SEARCH, axiosConfig(authData!.token, radnikSearchData));
  };

  const axiosSearchRadnikOrgJedRola = (organizacionaJedinicaId: number, enumRolaSifra: string) => {
    return axios.get(`${Endpoint.RADNIK_LIST}/radnik-search-rola/${organizacionaJedinicaId}/${enumRolaSifra}`, axiosConfig(authData!.token));
  };

  const axiosGetRadnik = (radnikId: number) => {
    return axios.get(`${Endpoint.RADNIK_LIST}/${radnikId}`, axiosConfig(authData!.token));
  };

  const axiosReadRadnik = (radnikId: number, token: string) => {
    return axios.get(`${Endpoint.RADNIK_LIST}/${radnikId}`, axiosConfig(token));
  };

  const axiosCreateRadnik = (radnikChange: RadnikReadDto) => {
    return axios.post(Endpoint.RADNIK_LIST, radnikChange ? radnikChange : {}, axiosConfig(authData!.token));
  };

  const axiosCreateCopyRadnik = (radnikId: number, radnikCopyId: number) => {
    return axios.post(`${Endpoint.RADNIK_LIST}/${radnikId}/copy-radnik/${radnikCopyId}`, {}, axiosConfig(authData!.token));
  };

  const axiosUpdateRadnik = (radnikChange: RadnikReadDto) => {
    return axios.put(`${Endpoint.RADNIK_LIST}/${radnikChange.id}`, radnikChange, axiosConfig(authData!.token));
  };

  const axiosDeleteRadnik = (radnikId: number) => {
    return axios.delete(`${Endpoint.RADNIK_LIST}/${radnikId}`, axiosConfig(authData!.token));
  };

  const axiosGetRadnikUlogaList = (radnikId: number) => {
    return axios.get(`${Endpoint.RADNIK_LIST}/${radnikId}/uloga-list`, axiosConfig(authData!.token));
  };

  const axiosCreateRadnikUloga = (radnikId: number, radnikUloga: RadnikUlogaCreateDto) => {
    return axios.post(`${Endpoint.RADNIK_LIST}/${radnikId}/uloga-list`, radnikUloga ? radnikUloga : {}, axiosConfig(authData!.token));
  };

  const axiosDeleteRadnikUloga = (radnikId: number, ulogaId: number) => {
    return axios.delete(`${Endpoint.RADNIK_LIST}/${radnikId}/uloga-list/${ulogaId}`, axiosConfig(authData!.token));
  };

  const axiosGetRadnikOrganizacionaJedinicaList = (radnikId: number) => {
    return axios.get(`${Endpoint.RADNIK_LIST}/${radnikId}/organizaciona-jedinica-list`, axiosConfig(authData!.token));
  };

  const axiosCreateRadnikOrganizacionaJedinica = (radnikId: number, radnikOrganizacionaJedinica: RadnikOrganizacionaJedinicaCreateDto[]) => {
    return axios.post(`${Endpoint.RADNIK_LIST}/${radnikId}/organizaciona-jedinica-list`, radnikOrganizacionaJedinica ? radnikOrganizacionaJedinica : {}, axiosConfig(authData!.token));
  };

  const axiosDeleteRadnikOrganizacionaJedinica = (radnikId: number, organizacionaJedinicaIds: Array<number>) => {
    return axios.delete(`${Endpoint.RADNIK_LIST}/${radnikId}/organizaciona-jedinica-list`, axiosConfig(authData!.token, { organizacionaJedinicaIDList: organizacionaJedinicaIds.join(",") }));
  };

  const axiosGetRadnikUstanovaList = (radnikId: number) => {
    return axios.get(`${Endpoint.RADNIK_LIST}/${radnikId}/ustanova-list`, axiosConfig(authData!.token));
  };

  const axiosCreateRadnikUstanova = (radnikID: number, radnikUstanova: RadnikUstanovaCreateDto) => {
    return axios.post(`${Endpoint.RADNIK_LIST}/${radnikID}/ustanova-list`, radnikUstanova ? radnikUstanova : {}, axiosConfig(authData!.token));
  };

  const axiosChangeUstanovaPoslednjeIzabrana = (ustanovaId: number) => {
    return axios.put(`${Endpoint.RADNIK_LIST}/changeUstanovaPoslednjaIzabrana/${ustanovaId}`, {}, axiosConfig(authData!.token));
  };

  const axiosCreateRadnikUstanovaWithExistingRadnik = (radnikId: number) => {
    return axios.post(`${Endpoint.RADNIK_LIST}/${radnikId}`, {}, axiosConfig(authData!.token));
  };

  const axiosUpdateRadnikAndCreateRadnikUstanova = (radnikChange: RadnikPartialUpdateDto) => {
    return axios.post(`${Endpoint.RADNIK_LIST}/updateRadnikAndCreateRadnikUstanova`, radnikChange, axiosConfig(authData!.token));
  };

  const axiosGetRadnikSpecijalizacijeList = (radnikID: number) => {
    return axios.get(`${Endpoint.RADNIK_LIST}/${radnikID}/specijalizacija-list`, axiosConfig(authData!.token));
  };

  const axiosCreateRadnikSpecijalizacija = (radnikID: number, radnikSpecijalizacijaList: Array<RadnikSpecijalizacijaCreateDto>) => {
    return axios.post(`${Endpoint.RADNIK_LIST}/${radnikID}/specijalizacija-list`, { radnikSpecijalizacijaList: radnikSpecijalizacijaList }, axiosConfig(authData!.token));
  };

  const axiosDeleteRadnikSpecijalizacija = (radnikID: number, specijalizacijaID: Array<number>) => {
    return axios.delete(`${Endpoint.RADNIK_LIST}/${radnikID}/specijalizacija-list/`, axiosConfig(authData!.token, { specijalizacijaID: specijalizacijaID.join(",") }));
  };

  const axiosGetInicijalizacijaRadnikList = () => {
    return axios.get(Endpoint.INICIJALIZACIJA_RADNIK_LIST, axiosConfig(authData!.token));
  };

  const axiosCreateInicijalizacijaRadnik = (radnik: RadnikInicijalizacijaReadDto) => {
    return axios.post(Endpoint.INICIJALIZACIJA_RADNIK_LIST, radnik, axiosConfig(authData!.token));
  };

  const axiosUpdateInicijalizacijaRadnik = (radnik: RadnikInicijalizacijaReadDto) => {
    return axios.put(`${Endpoint.RADNIK_LIST}/${radnik.id}/inicijalizacija`, radnik, axiosConfig(authData!.token));
  };

  return {
    axiosSearchRadnik,
    axiosSearchRadnikOrgJedRola,
    axiosCreateRadnik,
    axiosCreateCopyRadnik,
    axiosUpdateRadnik,
    axiosDeleteRadnik,
    axiosGetRadnik,
    axiosReadRadnik,
    axiosGetRadnikUlogaList,
    axiosCreateRadnikUloga,
    axiosDeleteRadnikUloga,
    axiosGetRadnikOrganizacionaJedinicaList,
    axiosCreateRadnikOrganizacionaJedinica,
    axiosDeleteRadnikOrganizacionaJedinica,
    axiosGetRadnikUstanovaList,
    axiosCreateRadnikUstanova,
    axiosChangeUstanovaPoslednjeIzabrana,
    axiosCreateRadnikUstanovaWithExistingRadnik,
    axiosUpdateRadnikAndCreateRadnikUstanova,
    axiosGetRadnikSpecijalizacijeList,
    axiosCreateRadnikSpecijalizacija,
    axiosDeleteRadnikSpecijalizacija,
    axiosGetInicijalizacijaRadnikList,
    axiosCreateInicijalizacijaRadnik,
    axiosUpdateInicijalizacijaRadnik,
  };
}

export type { RadnikControllerType };
