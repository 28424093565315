import { TabPanel, TabView } from "primereact/tabview";
import { useLabels } from "../../Store";
import RadnikProfilePage from "../radnik-profil/RadnikProfilView";

export default function PodesavanjaPage() {
  const Labels = useLabels();
  return (
    <div className="layout-podesavanja">
      <TabView activeIndex={0} renderActiveOnly>
        <TabPanel header={Labels.LICNI_PODACI}>
          <RadnikProfilePage />
        </TabPanel>
        {/* podesavanje zakazivanja - izbrisano, jer nije zavrseno */}
        {/* <TabPanel header={Labels.PODESAVANJA_ZAKAZIVANJA}>
          <Panel>
            <TabView activeIndex={0} renderActiveOnly>
              <TabPanel header={Labels.PODESAVANJA_ZAKAZIVANJA_POLJE}> </TabPanel>
            </TabView>
          </Panel>
        </TabPanel> */}
      </TabView>
    </div>
  );
}
