import moment from "moment";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import { SifarnikProizvodaController } from "../../../controllers/sifarnik-proizvoda/SifarnikProizvodaController";
import { handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import SearchBaseDto from "../../../model/SearchBaseDto";
import SifarnikProizvodaReadDto from "../../../model/sifarnik/sifarnik-proizvoda/SifarnikProizvodaReadDto";
import { AppContext, useLabels } from "./../../../Store";

interface SifarnikProizvodaListLogicalType {
  sifarnikProizvodaList: Array<SifarnikProizvodaReadDto>;
  first: number;
  tableRows: number;
  changeSifarnikProizvodaSearchData: (e: ChangeEvent<HTMLInputElement>) => void;
  sifarnikProizvodaSearchData: SearchBaseDto;
  fetchData: () => void;
  searchSifarnikProizvodaByEnter: (event: KeyboardEvent<HTMLInputElement>) => void;
  onPageSifarnikProizvoda: (rows: number, first: number) => void;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  sifarnikProizvodaLoading: boolean;
}

export default function SifarnikProizvodaListLogical(): SifarnikProizvodaListLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [sifarnikProizvodaList, setSifarnikProizvodaList] = useState<Array<SifarnikProizvodaReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [sifarnikProizvodaSearchData, setSifarnikProizvodaSearchData] = useState<SearchBaseDto>({});
  const [sifarnikProizvodaLoading, setSifarnikProizvodaLoading] = useState(false);

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LABEL_SIFARNIK_PROIZVODA,
    },
  ];
  const { axiosSearchSifarnikProizvoda } = SifarnikProizvodaController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let startTime = moment(new Date());
    setSifarnikProizvodaLoading(true);
    axiosSearchSifarnikProizvoda(sifarnikProizvodaSearchData)
      .then((res: any) => {
        setSifarnikProizvodaList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((sifarnikProizvoda: SifarnikProizvodaReadDto) => {
          exportList.push({
            [Labels.SIFARNIK_PROIZVODA_SIFRA]: sifarnikProizvoda.sifra,
            [Labels.SIFARNIK_PROIZVODA_NAZIV]: sifarnikProizvoda.naziv,
          });
        });
        setExportData(exportList);
        setFirst(0);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setSifarnikProizvodaLoading, startTime);
      });
  };

  const changeSifarnikProizvodaSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSifarnikProizvodaSearchData({
      ...sifarnikProizvodaSearchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchSifarnikProizvodaByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      fetchData();
    }
  };

  const onPageSifarnikProizvoda = (rows: number, first: number) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    sifarnikProizvodaList,
    first,
    tableRows,
    changeSifarnikProizvodaSearchData,
    sifarnikProizvodaSearchData,
    fetchData,
    searchSifarnikProizvodaByEnter,
    onPageSifarnikProizvoda,
    exportData,
    breadCrumbItems,
    sifarnikProizvodaLoading,
  };
}
