import moment from "moment";
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { Dispatch, MutableRefObject, SetStateAction, useContext, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { PacijentController } from "../../../../controllers/pacijent/PacijentController";
import EnumPacijentPoljeKategorija from "../../../../infrastructure/system/EnumPacijentPoljeKategorija";
import { DATE_FORMAT, formatDate2, handleAxiosCallError, removeAllMasks, setInvalid } from "../../../../infrastructure/system/Utils";
import { useObavestenjeTipList } from "../../../../infrastructure/system/hooks/enum/useObavestenjeTipList";
import { usePolList } from "../../../../infrastructure/system/hooks/enum/usePolList";
import { usePacijentPoljeKategorijaList } from "../../../../infrastructure/system/hooks/pacijent-polje-kategorija/usePacijentPoljeKategorijaList";
import PacijentCreateDto from "../../../../model/pacijent/PacijentCreateDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import PoljeVrednostDto from "../../../../model/polje/PoljeVrednostDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import ZakazanTerminReadDto from "../../../../model/zakazan-termin/ZakazanTerminReadDto";
import CalendarComponent from "../../../administracija/calendar-component/CalendarComponent";
import Polje from "../../../formular/polja/Polje";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";

interface DialogTerminPostojeciPacijentTabProps {
  pacijentOdabran?: PacijentReadDto;
  setPacijentOdabran: Dispatch<SetStateAction<PacijentReadDto | undefined>>;
  invalidFields: { [field: string]: boolean } | undefined;
  setInvalidFields: Dispatch<SetStateAction<{ [field: string]: boolean } | undefined>>;
  pacijentId?: number;
  zakazanTerminLocal?: ZakazanTerminReadDto;
  pacijentRef: MutableRefObject<PacijentCreateDto | undefined>;
  fillPacijentPolja: (pacijent: PacijentReadDto) => void;
}

export const DialogTerminPostojeciPacijentTab = (props: DialogTerminPostojeciPacijentTabProps) => {
  const { pacijentOdabran, setPacijentOdabran, invalidFields, setInvalidFields, pacijentId, pacijentRef, fillPacijentPolja, zakazanTerminLocal } = props;
  const { pristup, showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const calendarRef = useRef<Calendar>(null);

  const pacijentUnosDisabled = !pacijentOdabran?.id;

  const { axiosPacijentSearch } = PacijentController();

  const [pacijentSuggestionList, setPacijentSuggestionList] = useState<PacijentReadDto[]>([]);
  const { polList } = usePolList();
  const { obavestenjeTipList } = useObavestenjeTipList();
  const { pacijentPoljeKategorijaList } = usePacijentPoljeKategorijaList(EnumPacijentPoljeKategorija.ZAKAZIVANJE);

  const onPoljeChange = (e: PoljeVrednostDto) => {
    if (pacijentOdabran?.id) {
      const newValue = {
        ...pacijentOdabran?.polja,
        [e.id]: e.vrednost ? e.vrednost : null,
      };
      setPacijentOdabran((prevPacijentOdabran) => ({ ...prevPacijentOdabran!, polja: newValue }));
    }
  };

  const pretragaPacijent = ({ query }: AutoCompleteCompleteEvent) => {
    setTimeout(() => {
      axiosPacijentSearch({ basicSearch: query })
        .then((response) => {
          setPacijentSuggestionList(response.data.data);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }, 250);
  };

  const odabirPacijenta = (e: AutoCompleteChangeEvent) => {
    setPacijentOdabran(e.target.value);

    pacijentRef.current = e.target.value;
    if (e.target.value !== undefined && e.target.value?.id !== undefined) {
      fillPacijentPolja(e.target.value);
    }
    setInvalidFields(undefined);
  };

  return (
    <div className="flex flex-column hc-gap-3 align-items-center py-4">
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PRETRAGA_PACIJENTA}</div>
        <div className="col-9 p-fluid p-0 flex">
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon bg-white">
              <i className="pi pi-search" />
            </span>
            <AutoComplete
              value={pacijentOdabran}
              selectedItemTemplate={(e) => `${e.ime} ${e.prezime} - ${moment(e.vremeRodjenja).format(DATE_FORMAT)}`}
              itemTemplate={(e) => `${e.ime} ${e.prezime} - ${moment(e.vremeRodjenja).format(DATE_FORMAT)}`}
              suggestions={pacijentSuggestionList}
              placeholder={Labels.PRETRAGA_PACIJENTA}
              completeMethod={pretragaPacijent}
              onChange={odabirPacijenta}
              disabled={!pristup || !!zakazanTerminLocal?.id || !!pacijentId || zakazanTerminLocal?.radnik?.viewNeaktivni}
            />
            <Button
              className="display-button"
              icon="pi pi-user"
              disabled={!pacijentOdabran?.id}
              onClick={() => {
                window.open(`${window.location.origin}/pacijent-dosije/${pacijentOdabran?.id}`);
              }}
              tooltip={Labels.PACIJENT_PRIKAZI}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.HEADER_NAME + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={pacijentOdabran?.ime ?? ""}
            onChange={(e) => {
              setPacijentOdabran({
                ...pacijentOdabran!,
                ime: e.target.value,
              });
            }}
            disabled={!pristup || pacijentUnosDisabled || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
            onBlur={() => {
              setInvalid(setInvalidFields, "ime", pacijentOdabran?.ime);
            }}
            className={invalidFields?.ime ? "p-invalid" : ""}
          />
          {invalidFields?.ime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.HEADER_SURNAME + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={pacijentOdabran?.prezime ?? ""}
            onChange={(e) => {
              setPacijentOdabran({
                ...pacijentOdabran!,
                prezime: e.target.value,
              });
            }}
            disabled={!pristup || pacijentUnosDisabled || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
            onBlur={() => {
              setInvalid(setInvalidFields, "prezime", pacijentOdabran?.prezime);
            }}
            className={invalidFields?.prezime ? "p-invalid" : ""}
          />
          {invalidFields?.prezime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_IME_RODITELJA}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={pacijentOdabran?.imeRoditelja ?? ""}
            onChange={(e) => {
              setPacijentOdabran({
                ...pacijentOdabran!,
                imeRoditelja: e.target.value,
              });
            }}
            disabled={!pristup || pacijentUnosDisabled || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
          />
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_POL + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="col-9 p-fluid p-0 flex flex-column">
          <div className="flex flex-row">
            {polList?.map((pol: EnumBaseReadDto, key: number) => (
              <div key={key}>
                <RadioButton
                  className={"p-radiobutton " + (invalidFields?.pol && "p-invalid")}
                  inputId={pol?.sifra}
                  name="polTrenutni"
                  value={pol}
                  onChange={(e) => {
                    setPacijentOdabran({
                      ...pacijentOdabran!,
                      polTrenutni: e.target.value,
                    });
                    setInvalidFields((prev) => ({ ...prev, pol: false }));
                  }}
                  checked={pacijentOdabran?.polTrenutni?.sifra === pol.sifra}
                  disabled={!pristup || pacijentUnosDisabled || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
                />
                <label className="cursor-pointer mx-2" htmlFor={pol.sifra}>
                  {pol.naziv}
                </label>
              </div>
            ))}
          </div>
          <div className="flex flex-row c-12">{invalidFields?.pol && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}</div>
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.DATUM_RODJENJA + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="col-9 p-fluid p-0">
          <CalendarComponent
            ref={calendarRef}
            value={pacijentOdabran?.vremeRodjenja}
            onChange={(e: CalendarChangeEvent) => {
              setPacijentOdabran({
                ...pacijentOdabran!,
                vremeRodjenja: formatDate2(e.value as Date | undefined),
              });
              setInvalidFields((prev) => ({ ...prev, vremeRodjenja: false }));
              if (e.value as Date) calendarRef?.current?.hide();
            }}
            onClearButtonClick={() => {
              setInvalidFields((prev) => ({ ...prev, vremeRodjenja: true }));
              setPacijentOdabran({ ...pacijentOdabran!, vremeRodjenja: undefined });
            }}
            className={"w-full " + (invalidFields?.vremeRodjenja && "p-invalid")}
            baseZIndex={3000}
            disabled={!pristup || pacijentUnosDisabled || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
            onHide={removeAllMasks}
            appendTo={document.body}
          />
          {invalidFields?.vremeRodjenja && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_TELEFON}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={pacijentOdabran?.telefon ?? ""}
            onChange={(e) => {
              setPacijentOdabran({
                ...pacijentOdabran!,
                telefon: e.target.value,
              });
              setInvalidFields((prev) => ({ ...prev, invalidTelefon: false }));
            }}
            disabled={!pristup || pacijentUnosDisabled || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
            className={invalidFields?.invalidTelefon ? "p-invalid" : ""}
          />
          {invalidFields?.invalidTelefon && <ObaveznoPolje text={Labels.TELEFON_WRONG_FORMAT} />}
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_EMAIL}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={pacijentOdabran?.email ?? ""}
            onChange={(e) => {
              setPacijentOdabran({
                ...pacijentOdabran!,
                email: e.target.value,
              });
              setInvalidFields((prev) => ({ ...prev, invalidEmail: false }));
            }}
            disabled={!pristup || pacijentUnosDisabled || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
            className={invalidFields?.invalidEmail ? "p-invalid" : ""}
          />
          {invalidFields?.invalidEmail && <ObaveznoPolje text={Labels.EMAIL_WRONG_FORMAT} />}
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_ADRESA}</div>
        <div className="col-9 p-fluid p-0">
          <InputText
            value={pacijentOdabran?.adresa ?? ""}
            onChange={(e) => {
              setPacijentOdabran({
                ...pacijentOdabran!,
                adresa: e.target.value,
              });
            }}
            disabled={!pristup || pacijentUnosDisabled || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
          />
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_OBAVESTENJE_TIP_LIST}</div>
        <div className="col-9 p-fluid p-0">
          <MultiSelect
            value={pacijentOdabran?.pacijentObavestenjeServisList?.map((pacijentObavestenjeServis) => pacijentObavestenjeServis.obavestenjeTip) ?? ""}
            options={obavestenjeTipList ?? []}
            disabled={!pristup || pacijentUnosDisabled || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
            onChange={(e) => {
              setPacijentOdabran({
                ...pacijentOdabran!,
                pacijentObavestenjeServisList: e.value.map((obavestenjeTip: EnumBaseReadDto) => ({ obavestenjeTip })),
              });
            }}
            optionLabel="naziv"
            optionValue="obavestenjeTip.sifra"
          />
        </div>
      </div>
      <div className="flex w-full justify-content-between align-items-center">
        <div className="pl-0 font-bold">{Labels.PACIJENT_GRUPNO_OBAVESTENJE_TIP_LIST}</div>
        <div className="col-9 p-fluid p-0">
          <MultiSelect
            value={pacijentOdabran?.pacijentGrupnoObavestenjeServisList?.map((pacijentGrupnoObavestenjeServis) => pacijentGrupnoObavestenjeServis.obavestenjeTip) ?? ""}
            options={obavestenjeTipList ?? []}
            disabled={!pristup || pacijentUnosDisabled || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
            onChange={(e) => {
              setPacijentOdabran({
                ...pacijentOdabran!,
                pacijentGrupnoObavestenjeServisList: e.value.map((obavestenjeTip: EnumBaseReadDto) => ({ obavestenjeTip })),
              });
            }}
            optionLabel="naziv"
            optionValue="obavestenjeTip.sifra"
          />
        </div>
      </div>
      {pacijentPoljeKategorijaList.map((pacijentPoljeKategorija, index: number) => {
        return (
          <div className="w-full" key={index}>
            <Polje
              key={pacijentPoljeKategorija.pacijentPolje.id}
              id={pacijentPoljeKategorija.pacijentPolje.id}
              naziv={pacijentPoljeKategorija.pacijentPolje.naziv}
              tip={pacijentPoljeKategorija.pacijentPolje.poljeTip.tipKomponenteEnum.sifra}
              obavezno={pacijentPoljeKategorija.obavezno}
              enumStavke={pacijentPoljeKategorija.pacijentPolje.poljeTip.poljeTipEnumStavkaList}
              format={pacijentPoljeKategorija.pacijentPolje.poljeTip.format}
              decimale={pacijentPoljeKategorija.pacijentPolje.poljeTip.decimale}
              vrednost={pacijentOdabran?.polja ? pacijentOdabran?.polja[pacijentPoljeKategorija.pacijentPolje.id] : undefined}
              onChange={onPoljeChange}
              readonly={!pristup || pacijentUnosDisabled || pacijentPoljeKategorija.readOnly || zakazanTerminLocal?.radnik?.viewNeaktivni || !!zakazanTerminLocal?.kontakt}
              colSize={9}
            />
          </div>
        );
      })}
    </div>
  );
};
