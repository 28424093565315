import axios from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EnumController } from "../../../controllers/enum/EnumController";
import { FormularController } from "../../../controllers/formular/FormularController";

import { AppContext, useLabels } from "../../../Store";
import { PoljeTipController } from "../../../controllers/polje-tip/PoljeTipController";
import { SuperadminKontaktTipController } from "../../../controllers/superadmin/SuperadminKontaktTipController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import PoljeReadDto from "../../../model/polje/PoljeReadDto";
import PoljeTipEnumStavkaReadDto from "../../../model/poljeTip/PoljeTipEnumStavkaReadDto";
import PoljeTipReadDto from "../../../model/poljeTip/PoljeTipReadDto";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";

interface CrudPoljeLogicalType {
  poljeChange: PoljeReadDto | undefined;
  setPoljeChange: React.Dispatch<React.SetStateAction<PoljeReadDto | undefined>>;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  isDisabled: boolean;
  exportData: Array<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  poljeOperation: string;
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onCancel: () => void;
  componentPoljeTipList: Array<ComponentPoljeTip>;
  poljeLoading: boolean;
  poljeOptionsLoading: boolean;
  selectedTipKomponente: EnumBaseReadDto | undefined;
  poljeTipEnumStavkaList: PoljeTipEnumStavkaReadDto[];
  resetInicijalnaVrednostAndSetPoljeTip: (poljeTipID: number) => void;
  getPoljeTip: (poljeTipId: number) => void;
  poljePanelList: Array<PoljeReadDto>;
  poljePanelOptionsLoading: boolean;
}

interface UseParamsType {
  poljeId?: string;
  formularId?: string;
  ustanovaId?: string;
}
export interface LabelValueId {
  label: string;
  value: number;
}
export interface ComponentPoljeTip {
  label: string;
  list: Array<LabelValueId>;
}

export default function CrudPoljeLogical(): CrudPoljeLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const [exportData] = useState<Array<any>>([]);
  const location = useLocation();
  const formularId = Number(useParams<keyof UseParamsType>()["formularId"]);
  const ustanovaId = Number(useParams<keyof UseParamsType>()["ustanovaId"]);
  const [formularOperation] = useState<string>(location.state ? location.state.formularOperation : "");
  const { poljeId } = useParams<keyof UseParamsType>();
  const [isDisabled] = useState<boolean>(isFormDisabled(location.state ? location.state.poljeOperation : EntityOperation.UPDATE));
  const [poljeChange, setPoljeChange] = useState<PoljeReadDto>();
  const [componentPoljeTipList, setComponentPoljeTipList] = useState<Array<ComponentPoljeTip>>([]);
  const navigate = useNavigate();
  const [index, setIndex] = useState<number>(0);
  const [poljeOperation] = useState<string>(location.state ? location.state.poljeOperation : EntityOperation.UPDATE);
  const [tipKontaktaId] = useState<number | undefined>(location?.state?.tipKontaktaId);
  const [tipKontaktaOperation] = useState<string | undefined>(location?.state?.tipKontaktaOperation);
  const [ustanovaNaziv] = useState<string | undefined>(location?.state?.ustanovaNaziv);
  const [poljeLoading, setPoljeLoading] = useState<boolean>(true);
  const [poljeOptionsLoading, setPoljeOptionsLoading] = useState<boolean>(true);
  const [tipKontaktaNaziv] = useState<string | undefined>(location?.state?.tipKontaktaNaziv);
  const [formularNaziv] = useState<string | undefined>(location?.state?.formularNaziv);
  const [selectedTipKomponente, setSelectedTipKomponente] = useState<EnumBaseReadDto>();
  const [poljeTipEnumStavkaList, setPoljeTipEnumStavkaList] = useState<Array<PoljeTipEnumStavkaReadDto>>([]);
  const [poljePanelList, setPoljePanelList] = useState<Array<PoljeReadDto>>([]);
  const [poljePanelOptionsLoading, setPoljePanelOptionsLoading] = useState<boolean>(true);
  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.FORMULAR_LIST,
      command: () => {
        navigate("/formular-list");
      },
    },
  ]);
  var poljeNaziv = "";

  const { axiosCreatePolje, axiosDeletePolje, axiosUpdatePolje, axiosGetFormular, axiosReadPolje, axiosGetPoljePanelList } = FormularController();
  const {
    axiosSuperadminCreatePolje,
    axiosSuperadminDeletePolje,
    axiosSuperadminUpdatePolje,
    axiosSuperadminReadPolje,
    axiosSuperadminGetFormular,
    axiosSuperadminGetPoljePanelList,
    axiosSuperadminSearchPoljeTip,
    axiosSuperadminGetPoljeTipEnumStavkaList,
    axiosSuperadminReadPoljeTip,
  } = SuperadminKontaktTipController();
  const { axiosSearchPoljeTip, axiosReadPoljeTip, axiosGetPoljeTipEnumStavkaList } = PoljeTipController();
  const { axiosGetTipKomponenteList } = EnumController();

  const postLogHighLevel = useLogHighLevel();

  useEffectOnce(() => {
    fetchData();
  });
  const validatePoljeEnum = (polje: PoljeReadDto) => {
    if (
      polje.poljeTip.tipKomponenteEnum.sifra === EnumTipKomponente.SET ||
      polje.poljeTip.tipKomponenteEnum.sifra === EnumTipKomponente.SET_RADIO ||
      polje.poljeTip.tipKomponenteEnum.sifra === EnumTipKomponente.SET_MULTISELECT
    ) {
      if (!polje.poljeTip.poljeTipEnumStavkaList.some((value) => value.id === polje.inicijalnaVrednostEnum)) {
        polje.inicijalnaVrednostEnum = undefined;
      }
    }
  };
  const fetchData = async () => {
    let startTime = moment(new Date());
    if (poljeId) {
      let startTime = moment(new Date());
      await (ustanovaId && tipKontaktaId ? axiosSuperadminReadPolje(ustanovaId, tipKontaktaId, Number(poljeId), formularId) : axiosReadPolje(Number(poljeId), formularId))
        .then((res: any) => {
          poljeNaziv = res.data.data.naziv;
          let polje: PoljeReadDto = res.data.data;
          validatePoljeEnum(polje);
          setPoljeChange(polje);
          setSelectedTipKomponente(polje.poljeTip.tipKomponenteEnum);
          setPoljeTipEnumStavkaList(polje.poljeTip.poljeTipEnumStavkaList);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPoljeLoading, startTime);
        });
    } else {
      setPoljeLoading(false);
    }
    const requestForm = ustanovaId && tipKontaktaId ? axiosSuperadminGetFormular(ustanovaId, tipKontaktaId, formularId) : axiosGetFormular(formularId);
    const requestFieldTypes = ustanovaId ? axiosSuperadminSearchPoljeTip(ustanovaId) : axiosSearchPoljeTip();
    const requestComponentTypeOptions = axiosGetTipKomponenteList();
    const requestPoljePanelOptions = ustanovaId && tipKontaktaId ? axiosSuperadminGetPoljePanelList(ustanovaId, tipKontaktaId, formularId) : axiosGetPoljePanelList(formularId);
    axios
      .all([requestComponentTypeOptions, requestForm, requestFieldTypes, requestPoljePanelOptions])
      .then(
        axios.spread(({ data: { data: responseComponentTypeOptions } }, { data: { data: responseForm } }, { data: { data: responseFieldTypes } }, { data: { data: responsePoljePanelOptions } }) => {
          let groupList: Array<ComponentPoljeTip> = [];
          responseComponentTypeOptions.forEach((componentTypeOption: EnumBaseReadDto) => {
            const fieldTypeGroupedList = responseFieldTypes?.filter((x: PoljeTipReadDto) => x.tipKomponenteEnum.sifra === componentTypeOption.sifra);
            if (fieldTypeGroupedList?.length !== 0) {
              groupList.push({
                label: componentTypeOption.naziv,
                list: fieldTypeGroupedList.map((element: PoljeTipReadDto) => {
                  return { label: element.naziv, value: element.id };
                }),
              });
            }
            setComponentPoljeTipList(groupList);
            setPoljePanelList(responsePoljePanelOptions);
            let tempBreadCrumbItems = breadCrumbItems;
            if (tipKontaktaId !== undefined) {
              tempBreadCrumbItems = ustanovaId
                ? [
                    {
                      label: Labels.USTANOVA_LIST,
                      command: () => {
                        navigate(`/crud-ustanova`);
                      },
                    },
                    {
                      label: Labels.USTANOVA_TITLE_DIALOG_UPDATE + " " + ustanovaNaziv,
                      command: () => {
                        navigate(`/crud-ustanova/${ustanovaId}`, {
                          state: {
                            tabIndex: 6,
                          },
                        });
                      },
                    },
                    {
                      label:
                        tipKontaktaOperation === EntityOperation.UPDATE
                          ? Labels.TIP_KONTAKTA_TITLE_DIALOG_UPDATE + tipKontaktaNaziv
                          : tipKontaktaOperation === EntityOperation.READ
                          ? Labels.TIP_KONTAKTA_TITLE_DIALOG_DETAILS + tipKontaktaNaziv
                          : tipKontaktaOperation === EntityOperation.CREATE
                          ? Labels.TIP_KONTAKTA_TITLE_DIALOG_CREATE
                          : Labels.TIP_KONTAKTA_TITLE_DIALOG_DELETE + tipKontaktaNaziv,
                      command: () => {
                        navigate(`/crud-ustanova/${ustanovaId}/crud-tip-kontakta/` + tipKontaktaId, {
                          state: {
                            tipKontaktaOperation: tipKontaktaOperation,
                            tipKontaktaId: tipKontaktaId,
                            tipKontaktaNaziv: tipKontaktaNaziv,
                            tabIndex: 1,
                            ustanovaNaziv: ustanovaNaziv,
                            ustanovaId: ustanovaId,
                          },
                        });
                      },
                    },
                  ]
                : [
                    {
                      label: Labels.TIP_KONTAKTA_LIST,
                      command: () => {
                        navigate("/tip-kontakta-list/");
                      },
                    },
                    {
                      label:
                        tipKontaktaOperation === EntityOperation.UPDATE
                          ? Labels.TIP_KONTAKTA_TITLE_DIALOG_UPDATE + tipKontaktaNaziv
                          : tipKontaktaOperation === EntityOperation.READ
                          ? Labels.TIP_KONTAKTA_TITLE_DIALOG_DETAILS + tipKontaktaNaziv
                          : tipKontaktaOperation === EntityOperation.CREATE
                          ? Labels.TIP_KONTAKTA_TITLE_DIALOG_CREATE
                          : Labels.TIP_KONTAKTA_TITLE_DIALOG_DELETE + tipKontaktaNaziv,
                      command: () => {
                        navigate("/crud-tip-kontakta/" + tipKontaktaId, {
                          state: {
                            tipKontaktaOperation: tipKontaktaOperation,
                            tipKontaktaId: tipKontaktaId,
                            tipKontaktaNaziv: tipKontaktaNaziv,
                          },
                        });
                      },
                    },
                  ];
            }
            if (breadCrumbItems.length < 2) {
              setBreadCrumbItems([
                ...tempBreadCrumbItems,
                ustanovaId
                  ? {
                      label:
                        formularOperation === EntityOperation.UPDATE
                          ? Labels.FORMULAR_TITLE_DIALOG_UPDATE + formularNaziv
                          : formularOperation === EntityOperation.READ
                          ? Labels.FORMULAR_TITLE_DIALOG_DETAILS + formularNaziv
                          : formularOperation === EntityOperation.CREATE
                          ? Labels.FORMULAR_TITLE_DIALOG_CREATE
                          : Labels.FORMULAR_TITLE_DIALOG_DELETE + formularNaziv,
                      command: () => {
                        navigate(`/crud-ustanova/${ustanovaId}/crud-tip-kontakta/${tipKontaktaId}/crud-formular/${formularId}`, {
                          state: {
                            tabIndex: 1,
                            formularOperation: formularOperation,
                            tipKontaktaOperation: tipKontaktaOperation,
                            tipKontaktaNaziv: tipKontaktaNaziv,
                            tipKontaktaId: tipKontaktaId,
                            formularNaziv: formularNaziv,
                            ustanovaId: ustanovaId,
                            ustanovaNaziv: ustanovaNaziv,
                          },
                        });
                      },
                    }
                  : {
                      label:
                        formularOperation === EntityOperation.UPDATE
                          ? Labels.LABEL_UPDATE_FORM + responseForm.naziv
                          : formularOperation === EntityOperation.READ
                          ? Labels.LABEL_DETAILS_FORM + responseForm.naziv
                          : Labels.LABEL_DELETE_FORM + responseForm.naziv,
                      command: () => {
                        navigate(`/crud-formular/${formularId}`, {
                          state: {
                            tabIndex: 1,
                            formularOperation: formularOperation,
                            tipKontaktaOperation: tipKontaktaOperation,
                            tipKontaktaNaziv: tipKontaktaNaziv,
                            tipKontaktaId: tipKontaktaId,
                            formularNaziv: formularNaziv,
                          },
                        });
                      },
                    },
                {
                  label:
                    poljeOperation === EntityOperation.UPDATE
                      ? Labels.LABEL_UPDATE_POLJE + poljeNaziv
                      : poljeOperation === EntityOperation.READ
                      ? Labels.LABEL_DETAIL_POLJE + poljeNaziv
                      : poljeOperation === EntityOperation.CREATE
                      ? Labels.LABEL_CREATE_POLJE
                      : Labels.LABEL_DELETE_POLJE + poljeNaziv,
                },
              ]);
            }
          });
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setPoljeOptionsLoading, startTime);
        skeletonTimeout(setPoljePanelOptionsLoading, startTime);
      });
  };

  const getPoljeTip = (poljeTipId: number) => {
    if (poljeTipId !== undefined) {
      const poljeTipRequest = ustanovaId ? axiosSuperadminReadPoljeTip(ustanovaId, poljeTipId) : axiosReadPoljeTip(poljeTipId);
      const poljeTipEnumStavkaListRequest = ustanovaId ? axiosSuperadminGetPoljeTipEnumStavkaList(ustanovaId, poljeTipId) : axiosGetPoljeTipEnumStavkaList(poljeTipId);
      axios
        .all([poljeTipRequest, poljeTipEnumStavkaListRequest])
        .then(
          axios.spread((poljeTipResponse: any, poljeTipEnumStavkaListResponse: any) => {
            setSelectedTipKomponente(poljeTipResponse.data.data.tipKomponenteEnum);
            setPoljeTipEnumStavkaList(poljeTipEnumStavkaListResponse.data.data);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const resetInicijalnaVrednostAndSetPoljeTip = (poljeTipID: number) => {
    var naziv = "/";
    for (let i = 0; i < componentPoljeTipList.length; i++) {
      var temp = componentPoljeTipList[i].list.find((x: LabelValueId) => x.value === poljeTipID);
      if (temp) {
        naziv = temp.label;
        break;
      }
    }
    let poljeChangeCopy = { ...poljeChange!, poljeTip: { id: poljeTipID, naziv: naziv } as PoljeTipReadDto };
    if (poljeChange?.inicijalnaVrednostString !== null) {
      delete poljeChangeCopy.inicijalnaVrednostString;
    } else if (poljeChange?.inicijalnaVrednostBoolean !== null) {
      delete poljeChangeCopy.inicijalnaVrednostBoolean;
    } else if (poljeChange?.inicijalnaVrednostBroj !== null) {
      delete poljeChangeCopy?.inicijalnaVrednostBroj;
    } else if (poljeChange.inicijalnaVrednostDatum !== null) {
      delete poljeChangeCopy.inicijalnaVrednostDatum;
    } else if (poljeChange?.inicijalnaVrednostEnum !== null) {
      delete poljeChangeCopy.inicijalnaVrednostEnum;
    }
    setPoljeChange(() => ({ ...poljeChangeCopy }));
  };

  const validatePolje = (polje?: PoljeReadDto) => {
    if (validateStringEmpty(polje?.sifra)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_SIFRA_REQUIRED);
      return false;
    }
    if (validateStringEmpty(polje?.naziv)) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_NAZIV_REQUIRED);
      return false;
    }
    if (!polje?.poljeTip) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_TIP_POLJA_REQUIRED);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validatePolje(poljeChange)) {
      return;
    }
    let newPolje = poljeChange;

    if (newPolje && !newPolje?.hasOwnProperty("obavezno")) {
      newPolje = { ...newPolje, obavezno: false };
    }
    if (newPolje) {
      setShowBlockUI(true);
      (ustanovaId && tipKontaktaId ? axiosSuperadminCreatePolje(ustanovaId, tipKontaktaId, formularId, newPolje) : axiosCreatePolje(formularId, newPolje))
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_CREATE_POLJE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_POLJE_1 +
              poljeChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_POLJE_2 +
              poljeChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_POLJE_3 +
              poljeChange?.poljeTip.naziv +
              (poljeChange?.obavezno ? Labels.LOG_HIGH_LEVEL_MESS_CREATE_POLJE_4 : Labels.LOG_HIGH_LEVEL_MESS_CREATE_POLJE_5)
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };
  const onDelete = () => {
    if (poljeChange) {
      setShowBlockUI(true);
      (ustanovaId && tipKontaktaId ? axiosSuperadminDeletePolje(ustanovaId, tipKontaktaId, formularId, poljeChange.id) : axiosDeletePolje(formularId, poljeChange.id))
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_POLJE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_POLJE_1 +
              poljeChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_POLJE_2 +
              poljeChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_DELETE_POLJE_3 +
              poljeChange?.poljeTip.naziv +
              (poljeChange?.obavezno ? Labels.LOG_HIGH_LEVEL_MESS_DELETE_POLJE_4 : Labels.LOG_HIGH_LEVEL_MESS_DELETE_POLJE_5)
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };
  const onUpdate = () => {
    if (!validatePolje(poljeChange)) {
      return;
    }
    if (poljeChange) {
      setShowBlockUI(true);
      (ustanovaId && tipKontaktaId ? axiosSuperadminUpdatePolje(ustanovaId, tipKontaktaId, formularId, poljeChange) : axiosUpdatePolje(formularId, poljeChange))
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_UPDATE_POLJE_SUCCESS);
          onCancel();
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_1 +
              poljeChange?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_2 +
              poljeChange?.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_3 +
              poljeChange?.poljeTip.naziv +
              (poljeChange?.obavezno ? Labels.LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_4 : Labels.LOG_HIGH_LEVEL_MESS_UPDATE_POLJE_5)
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };
  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/crud-formular/${formularId}`, { state: { tabIndex: 1, formularOperation: formularOperation } });
    }
  };

  return {
    poljeChange,
    setPoljeChange,
    setIndex,
    index,
    isDisabled,
    exportData,
    breadCrumbItems,
    poljeOperation,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    componentPoljeTipList,
    poljeLoading,
    poljeOptionsLoading,
    selectedTipKomponente,
    poljeTipEnumStavkaList,
    resetInicijalnaVrednostAndSetPoljeTip,
    getPoljeTip,
    poljePanelList,
    poljePanelOptionsLoading,
  };
}
