const EnumInicijalizacijaKorak = {
  USTANOVA: "USTANOVA",
  ORGANIZACIONE_JEDINICE: "ORGANIZACIONE_JEDINICE",
  RADNICI: "RADNICI",
  TIPOVI_PREGLEDA: "TIPOVI_PREGLEDA",
  USLUGE: "USLUGE",
  INICIJALIZOVANA: "INICIJALIZOVANA",
};

export default EnumInicijalizacijaKorak;
