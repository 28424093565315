import axios from "axios";
import moment from "moment";
import { RefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { EnumController } from "../../../../controllers/enum/EnumController";
import { OrganizacionaJedinicaController } from "../../../../controllers/organizaciona-jedinica/OrganizacionaJedinicaController";
import { RadniPeriodController } from "../../../../controllers/radni-period/RadniPeriodController";
import { UstanovaController } from "../../../../controllers/ustanova/UstanovaController";
import EnumRola from "../../../../infrastructure/system/EnumRola";
import MessageType from "../../../../infrastructure/system/MessageType";
import { DATE_FORMAT_2, TIME_FORMAT, formatDate, formatDate2, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";

import { Calendar } from "primereact/calendar";
import { AppContext, useLabels } from "../../../../Store";
import OrganizacionaJedinicaRadnikReadDto from "../../../../model/organizacionaJedinica/OrganizacionaJedinicaRadnikReadDto";
import RadniPeriodCreateDto from "../../../../model/radni-period/RadniPeriodCreateDto";
import RadniPeriodCreateSendDto from "../../../../model/radni-period/RadniPeriodCreateSendDto";
import RadnikSkracenoReadDto from "../../../../model/radnik/RadnikSkracenoReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import UstanovaGridReadDto from "../../../../model/ustanova/UstanovaGridReadDto";

interface CrudRadniPeriodLogicalType {
  radniPeriodChange: RadniPeriodCreateDto | undefined;
  setRadniPeriodChange: React.Dispatch<React.SetStateAction<RadniPeriodCreateDto | undefined>>;
  breadCrumbItems: Array<BreadCrumbItem>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  radnikList: Array<RadnikSkracenoReadDto> | undefined;
  setSelectedRadnik: React.Dispatch<React.SetStateAction<RadnikSkracenoReadDto | undefined>>;
  selectedRadnik: RadnikSkracenoReadDto | undefined;
  ustanovaGrid: UstanovaGridReadDto | undefined;
  trajanjeTerminaList: Array<EnumBaseReadDto> | undefined;
  pregledTrajanje: EnumBaseReadDto | undefined;
  setPregledTrajanje: React.Dispatch<React.SetStateAction<EnumBaseReadDto | undefined>>;
  onCancel: () => void;
  onCreate: () => void;
  ustanovaOrgJedCallback: (odabranaOrgJedRefTmp: any) => void;
  radnikListLoading: boolean;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  datumOdRef: RefObject<Calendar>;
  datumDoRef: RefObject<Calendar>;
  vremeOdRef: RefObject<Calendar>;
  vremeDoRef: RefObject<Calendar>;
  pauzaOdRef: RefObject<Calendar>;
  pauzaDoRef: RefObject<Calendar>;
}

interface BreadCrumbItem {
  label: string;
  command?: () => void;
}

const dateTimeToDate = (dateTime: Date) => moment(dateTime).format(DATE_FORMAT_2);

const getTime = (date: Date) => moment(date).format(TIME_FORMAT);

export default function CrudRadniPeriodLogical(): CrudRadniPeriodLogicalType {
  const location = useLocation();
  const navigate = useNavigate();
  const Labels = useLabels();
  const { showMessage, setShowBlockUI, odabranaOrganizacionaJedinicaList } = useContext(AppContext);
  const [radniPeriodChange, setRadniPeriodChange] = useState<RadniPeriodCreateDto | undefined>();
  const [tabIndex] = useState(location.state?.tabIndex || 0);
  const [index, setIndex] = useState(tabIndex);
  const [radnikList, setRadnikList] = useState<Array<RadnikSkracenoReadDto>>();
  const [selectedRadnik, setSelectedRadnik] = useState<RadnikSkracenoReadDto>();
  const [ustanovaGrid, setUstanovaGrid] = useState<UstanovaGridReadDto>();
  const [trajanjeTerminaList, setTrajanjeTerminaList] = useState<Array<EnumBaseReadDto> | undefined>();
  const [pregledTrajanje, setPregledTrajanje] = useState<EnumBaseReadDto | undefined>();
  const [radnikListLoading, setRadnikListLoading] = useState<boolean>(true);
  const [invalidFields, setInvalidFields] = useState<{ [field: string]: boolean | any } | undefined>(undefined);
  const orgJedRef = useRef<any>(null);
  const datumOdRef = useRef<Calendar>(null);
  const datumDoRef = useRef<Calendar>(null);
  const vremeOdRef = useRef<Calendar>(null);
  const vremeDoRef = useRef<Calendar>(null);
  const pauzaOdRef = useRef<Calendar>(null);
  const pauzaDoRef = useRef<Calendar>(null);

  const postLogHighLevel = useLogHighLevel();

  const { authData } = useContext(AppContext);
  const ustanova = authData?.currentRadnik.ustanovaPoslednjaIzabrana;

  const ustanovaOrgJedCallback = useCallback((odabranaOrgJedRefTmp: any) => {
    if (odabranaOrgJedRefTmp) {
      orgJedRef.current = odabranaOrgJedRefTmp;
    }
  }, []);

  function setInitialVremePocetka() {
    let timeParts = ustanova?.radnoVremeOd?.split(":")!;
    if (odabranaOrganizacionaJedinicaList[0]) {
      const orgJed = odabranaOrganizacionaJedinicaList.slice(-1)[0].organizacionaJedinica;
      if (orgJed.radnoVremeOd) timeParts = orgJed.radnoVremeOd.split(":");
    }
    if (timeParts === undefined) {
      return undefined;
    }
    const vremePocetka = new Date();
    vremePocetka.setHours(parseInt(timeParts[0]));
    vremePocetka.setMinutes(parseInt(timeParts[1]));
    return vremePocetka;
  }

  function setInitialVremeKraja() {
    let timeParts = ustanova?.radnoVremeDo?.split(":")!;
    if (odabranaOrganizacionaJedinicaList[0]) {
      const orgJed = odabranaOrganizacionaJedinicaList.slice(-1)[0].organizacionaJedinica;
      if (orgJed.radnoVremeDo) timeParts = orgJed.radnoVremeDo.split(":");
    }
    if (timeParts === undefined) {
      return undefined;
    }
    const vremeKraja = new Date();
    vremeKraja.setHours(parseInt(timeParts[0]));
    vremeKraja.setMinutes(parseInt(timeParts[1]));
    return vremeKraja;
  }

  function setInitialRadniDanList() {
    if (odabranaOrganizacionaJedinicaList[0]) {
      const orgJed = odabranaOrganizacionaJedinicaList.slice(-1)[0].organizacionaJedinica;
      return orgJed.organizacionaJedinicaRadniDanList.map((orgJedRadniDan) => orgJedRadniDan.radniDan);
    }
    return [];
  }

  useEffect(() => {
    setRadniPeriodChange((radniPeriod) => ({
      ...radniPeriod!,
      vremePocetkaRadnogDana: setInitialVremePocetka(),
      vremeKrajaRadnogDana: setInitialVremeKraja(),
      radniDanList: setInitialRadniDanList(),
      nedostupanZaEksterneZakazivace: false,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odabranaOrganizacionaJedinicaList]);

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/radni-period-list`);
    }
  };

  const validateInput = (radniPeriodChange: RadniPeriodCreateSendDto) => {
    let isInvalid = false;
    if (radniPeriodChange === undefined || radniPeriodChange?.radniDanList === undefined || radniPeriodChange?.radniDanList.length === 0) {
      setInvalidFields((prev) => ({ ...prev, radniDani: true }));
      isInvalid = true;
    }
    if (!radniPeriodChange?.vremePocetkaRadnogDana) {
      setInvalidFields((prev) => ({ ...prev, vremePocetka: true }));
      isInvalid = true;
    }
    if (!radniPeriodChange?.vremeKrajaRadnogDana) {
      setInvalidFields((prev) => ({ ...prev, vremeKraja: true }));
      isInvalid = true;
    }
    if (!radniPeriodChange?.periodPocetak) {
      setInvalidFields((prev) => ({ ...prev, periodPocetak: true }));
      isInvalid = true;
    }
    if (!radniPeriodChange?.periodKraj) {
      setInvalidFields((prev) => ({ ...prev, periodKraj: true }));
      isInvalid = true;
    }
    if (radniPeriodChange?.vremePocetkaRadnogDana && radniPeriodChange?.vremeKrajaRadnogDana && radniPeriodChange?.vremePocetkaRadnogDana > radniPeriodChange?.vremeKrajaRadnogDana) {
      setInvalidFields((prev) => ({ ...prev, invalidVreme: true }));
      isInvalid = true;
    }
    if (radniPeriodChange?.periodPocetak && radniPeriodChange?.periodKraj && radniPeriodChange?.periodPocetak > radniPeriodChange?.periodKraj) {
      setInvalidFields((prev) => ({ ...prev, invalidPeriod: true }));
      isInvalid = true;
    }
    if (radniPeriodChange.vremePocetkaPauze || radniPeriodChange.vremeKrajaPauze) {
      if (!radniPeriodChange.vremePocetkaPauze || !radniPeriodChange.vremeKrajaPauze) {
        setInvalidFields((prev) => ({ ...prev, invalidPauza: true }));
        isInvalid = true;
      } else if (radniPeriodChange.vremePocetkaPauze > radniPeriodChange.vremeKrajaPauze) {
        setInvalidFields((prev) => ({ ...prev, invalidPauza: true }));
        isInvalid = true;
      }
    }
    return !isInvalid;
  };

  const onCreate = () => {
    const radniPeriodCreate: RadniPeriodCreateSendDto = {
      periodPocetak: radniPeriodChange?.periodPocetak ? dateTimeToDate(radniPeriodChange.periodPocetak) : undefined,
      periodKraj: radniPeriodChange?.periodKraj ? dateTimeToDate(radniPeriodChange.periodKraj) : undefined,
      vremePocetkaRadnogDana: radniPeriodChange?.vremePocetkaRadnogDana ? getTime(radniPeriodChange.vremePocetkaRadnogDana) : undefined,
      vremeKrajaRadnogDana: radniPeriodChange?.vremeKrajaRadnogDana ? getTime(radniPeriodChange.vremeKrajaRadnogDana) : undefined,
      vremePocetkaPauze: radniPeriodChange?.vremePocetkaPauze && getTime(radniPeriodChange.vremePocetkaPauze),
      vremeKrajaPauze: radniPeriodChange?.vremeKrajaPauze && getTime(radniPeriodChange.vremeKrajaPauze),
      napomena: radniPeriodChange?.napomena,
      radniDanList: radniPeriodChange?.radniDanList,
      pregledTrajanje,
      radnikID: selectedRadnik?.id,
      organizacionaJedinicaID: odabranaOrgJed?.organizacionaJedinica.id,
      nedostupanZaEksterneZakazivace: radniPeriodChange?.nedostupanZaEksterneZakazivace ?? false,
    };
    if (!validateInput(radniPeriodCreate)) {
      return;
    }

    setShowBlockUI(true);

    axiosCreateRadniPeriod(radniPeriodCreate)
      .then((res: any) => {
        showMessage(MessageType.SUCCESS, Labels.RADNI_PERIOD_TITLE_MESSAGE_CREATE_RADNI_PERIOD_SUCCESS);
        navigate(`/radni-period-list`);
        postLogHighLevel(
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_RADNI_PERIOD_1 +
            selectedRadnik?.ime +
            " " +
            selectedRadnik?.prezime +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_2 +
            formatDate2(radniPeriodChange?.periodPocetak) +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_3 +
            formatDate2(radniPeriodChange?.periodKraj) +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_4 +
            radniPeriodChange?.radniDanList?.map((e: EnumBaseReadDto) => e.naziv) +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_5 +
            formatDate(radniPeriodChange?.vremePocetkaRadnogDana, TIME_FORMAT) +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_6 +
            formatDate(radniPeriodChange?.vremeKrajaRadnogDana, TIME_FORMAT) +
            Labels.LOG_HIGH_LEVEL_MESS_INFO_RADNI_PERIOD_7 +
            pregledTrajanje?.naziv +
            " min."
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const breadCrumbItems = [
    {
      label: Labels.RADNI_PERIOD_LIST,
      command: () => {
        navigate("/radni-period-list/");
      },
    },
    {
      label: Labels.RADNI_PERIOD_CREATE,
    },
  ];

  const { axiosGetUstanovaGrid } = UstanovaController();
  const { axiosGetOrganizacionaJedinicaRadnikListByRola } = OrganizacionaJedinicaController();
  const { axiosGetTrajanjeTerminaList } = EnumController();
  const { axiosCreateRadniPeriod } = RadniPeriodController();

  const odabranaOrgJed = odabranaOrganizacionaJedinicaList && odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1];

  useEffectOnce(() => {
    fetchData();
  });

  useEffect(() => {
    if (
      odabranaOrganizacionaJedinicaList &&
      odabranaOrganizacionaJedinicaList.length > 0 &&
      odabranaOrganizacionaJedinicaList[odabranaOrganizacionaJedinicaList.length - 1]?.podredjeneOrganizacioneJedinice?.length === 0
    ) {
      if (odabranaOrgJed && ustanovaGrid && trajanjeTerminaList) {
        setShowBlockUI(true);
        setRadnikListLoading(true);
        let startTime = moment(new Date());

        axiosGetOrganizacionaJedinicaRadnikListByRola(odabranaOrgJed?.organizacionaJedinica.id, EnumRola.LEKAR)
          .then(({ data: { data } }: { data: { data: Array<OrganizacionaJedinicaRadnikReadDto> } }) => {
            let radnikListTmp = data.map((orgJedRadnik: OrganizacionaJedinicaRadnikReadDto) => orgJedRadnik.radnik);
            setRadnikList(radnikListTmp);
            let viewSelectedRadnik = location.state.selectedRadnik;
            if (viewSelectedRadnik && radnikListTmp.filter((radnik) => radnik.id === viewSelectedRadnik?.id)) {
              setSelectedRadnik(viewSelectedRadnik);
            } else {
            }
            setSelectedRadnik(radnikListTmp[0]);
          })
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
          })
          .finally(() => {
            setShowBlockUI(false);
            skeletonTimeout(setRadnikListLoading, startTime);
          });
      } else {
        setRadnikList(undefined);
        setRadnikListLoading(false);
      }
    } else {
      setRadnikListLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odabranaOrganizacionaJedinicaList, odabranaOrgJed, ustanovaGrid, trajanjeTerminaList]);

  const fetchData = () => {
    setShowBlockUI(true);

    const requestUstanovaGrid = axiosGetUstanovaGrid();
    const requestTrajanjeTermina = axiosGetTrajanjeTerminaList();
    axios
      .all([requestUstanovaGrid, requestTrajanjeTermina])
      .then(
        axios.spread(({ data: { data: ustanovaGridData } }, { data: { data: trajanjeTerminaList } }) => {
          setUstanovaGrid(ustanovaGridData);
          setPregledTrajanje(ustanovaGridData.vremeTrajanjaPregledaEnum);
          setTrajanjeTerminaList(trajanjeTerminaList);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return {
    breadCrumbItems,
    index,
    setIndex,
    radnikList,
    selectedRadnik,
    setSelectedRadnik,
    ustanovaGrid,
    trajanjeTerminaList,
    pregledTrajanje,
    setPregledTrajanje,
    radniPeriodChange,
    setRadniPeriodChange,
    onCancel,
    onCreate,
    ustanovaOrgJedCallback,
    radnikListLoading,
    invalidFields,
    setInvalidFields,
    datumOdRef,
    datumDoRef,
    vremeOdRef,
    vremeDoRef,
    pauzaOdRef,
    pauzaDoRef,
  };
}
