import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { useContext, useMemo } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom } from "../../../../infrastructure/system/Utils";
import KontaktUtrosakReadDto from "../../../../model/kontakt/KontaktUtrosakReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import SifarnikProizvodaReadDto from "../../../../model/sifarnik/sifarnik-proizvoda/SifarnikProizvodaReadDto";
import DialogComponent from "../../../dialog-component/DialogComponent";

interface KontaktUtrosakDialogProps {
  closeUtrosakDialog: () => void;
  isOpenUtrosakDialog: boolean;
  utrosakOperation?: string;
  kontaktUtrosakChange: KontaktUtrosakReadDto | undefined;
  setKontaktUtrosakChange: any;
  onCreateUtrosak: () => void;
  onDeleteUtrosak: () => void;
  onUpdateUtrosak: () => void;
  sifarnikProizvodaList?: Array<SifarnikProizvodaReadDto>;
  isDisabled: boolean;
  utrosakTipList?: Array<EnumBaseReadDto>;
  searchProizvod: (e: AutoCompleteCompleteEvent) => void;
  utrosciLoading: boolean;
}
export default function KontaktUtrosakDialog(props: KontaktUtrosakDialogProps) {
  const {
    searchProizvod,
    sifarnikProizvodaList,
    isOpenUtrosakDialog,
    closeUtrosakDialog,
    utrosakOperation,
    kontaktUtrosakChange,
    setKontaktUtrosakChange,
    onCreateUtrosak,
    onUpdateUtrosak,
    onDeleteUtrosak,
    isDisabled,
    utrosciLoading,
  } = props;
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const headerToolbar = () => (
    <div className="font-bold p-0">
      {utrosakOperation === EntityOperation.CREATE
        ? Labels.UTROSAK_NOVI
        : utrosakOperation === EntityOperation.UPDATE
        ? Labels.UTROSAK_IZMENI
        : utrosakOperation === EntityOperation.DELETE
        ? Labels.UTROSAK_OBRISI
        : ""}
    </div>
  );
  const footer = (
    <div className="layout-pacijent-dosije">
      <Button label={Labels.BUTTON_CANCEL} className="p-button-outlined p-button-tertiary align-self-center" onClick={closeUtrosakDialog} />
      <Button
        label={
          utrosakOperation === EntityOperation.CREATE
            ? Labels.BUTTON_SAVE
            : utrosakOperation === EntityOperation.UPDATE
            ? Labels.BUTTON_UPDATE
            : utrosakOperation === EntityOperation.DELETE
            ? Labels.BUTTON_DELETE
            : ""
        }
        tooltipOptions={tooltipOptionsBottom}
        className="p-button-success"
        onClick={
          utrosakOperation === EntityOperation.CREATE
            ? () => onCreateUtrosak()
            : utrosakOperation === EntityOperation.UPDATE
            ? () => onUpdateUtrosak()
            : utrosakOperation === EntityOperation.DELETE
            ? onDeleteUtrosak
            : undefined
        }
        disabled={!pristup}
      />
    </div>
  );

  const autocompleteStyles = useMemo(() => {
    return { paddingLeft: "2rem", paddingRight: "2rem" };
  }, []);

  return (
    <DialogComponent
      header={headerToolbar}
      visible={isOpenUtrosakDialog}
      onHide={closeUtrosakDialog}
      footer={footer}
      className="layout-kontakt-utrosak"
      content={
        <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
          <div className="grid">
            <div className="col-12">
              <div className="grid">
                <div className="font-bold col-12 pl-1">
                  {Labels.UTROSAK_DEO_NAZIVA_SIFRE}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                  {utrosciLoading && <ProgressSpinner className="w-3rem h-1rem" strokeWidth="4" animationDuration="1s" />}
                </div>
              </div>
              <div className="grid align-items-center justfy-content-center p-nogutter">
                <div className="col-12 p-fluid margin-top-bottom-5 p-1">
                  <span className="p-input-icon-left">
                    <AutoComplete
                      value={kontaktUtrosakChange?.sifarnikProizvoda}
                      disabled={!pristup || isDisabled}
                      itemTemplate={(e) => e.punNaziv}
                      selectedItemTemplate={(e) => `${e.sifra ?? ""} ${e.naziv ?? ""}`}
                      suggestions={sifarnikProizvodaList}
                      completeMethod={searchProizvod}
                      inputStyle={autocompleteStyles}
                      field="unosUtroska"
                      onChange={(e) => {
                        setKontaktUtrosakChange({
                          ...kontaktUtrosakChange!,
                          sifarnikProizvoda: e.value,
                          sifarnikProizvodaId: e.value.id,
                        });
                      }}
                      minLength={3}
                    />
                    <i className="pi pi-search " />
                    {kontaktUtrosakChange?.sifarnikProizvoda && kontaktUtrosakChange?.sifarnikProizvoda.naziv !== "" && (
                      <i
                        className="pi pi-times p-icon-right"
                        onClick={() => {
                          setKontaktUtrosakChange({ ...kontaktUtrosakChange!, sifarnikProizvoda: undefined });
                        }}
                      />
                    )}
                  </span>
                </div>
              </div>
              <div className="grid">
                <div className="font-bold col-12 pl-1 mt-3">
                  {Labels.UTROSAK_POTROSENA_KOLICINA}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="grid">
                <div className="flex col-12 p-1 align-items-center">
                  <div className="flex col-20 p-1 align-items-center">
                    <InputNumber
                      disabled={!pristup || isDisabled}
                      value={kontaktUtrosakChange?.utrosenaKolicina}
                      onChange={(e) => {
                        setKontaktUtrosakChange({
                          ...kontaktUtrosakChange!,
                          utrosenaKolicina: e.value ?? undefined,
                        });
                      }}
                    />
                  </div>
                  <div className="flex col-2 align-items-start font-bold p-1">{kontaktUtrosakChange?.sifarnikProizvoda?.sifarnikJedinicaMere?.naziv}</div>
                </div>
              </div>
              <div className="grid">
                <div className="font-bold col-12 pl-1 mt-3">{Labels.DIJAGNOZA_NAPOMENA}</div>
              </div>
              <div className="grid">
                <div className="col-12 p-fluid p-1 pl-1">
                  <InputTextarea
                    rows={3}
                    cols={5}
                    disabled={!pristup || isDisabled}
                    value={kontaktUtrosakChange?.napomena ?? ""}
                    onChange={(e) => {
                      setKontaktUtrosakChange({
                        ...kontaktUtrosakChange!,
                        napomena: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}
