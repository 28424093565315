import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../Store";
import { RolaService } from "../../../controllers/rola/RolaService";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { setInvalid, tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import EnumBaseReadDto from "../../../model/sifarnik/EnumBaseReadDto";
import SkeletonCheckboxItem from "../../app/skeleton/SkeletonCheckboxItem";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import ConfirmComponent from "../../confirm-component/ConfirmComponent";
import ObaveznoPolje from "../../obavezno-polje/ObaveznoPolje";
import CrudIzvestajLogical from "./CrudIzvestajLogical";
import IzvestajParametarListVeiw from "./parametar/IzvestajParametarListView";

export default function CrudIzvestajView() {
  const { isSuperadmin } = RolaService();
  const {
    breadCrumbItems,
    index,
    setIndex,
    izvestajLoading,
    isDisabled,
    izvestajChange,
    setIzvestajChange,
    kategorijaIzvestajaList,
    fajlFormatList,
    fajlFormatOnCheck,
    onFajlFormatChange,
    kategorijeLoading,
    formatLoading,
    izvestajOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    invalidFields,
    setInvalidFields,
    entityStatusList,
    entityStatusListLoading,
    jasperUploadDisabled,
    setJasperUploadDisabled,
    jasperUploadHandler,
  } = CrudIzvestajLogical(isSuperadmin);

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const jasperUploadRef = useRef<any>(null);

  const cancelOptions = { className: "class-visibility-hidden" };

  const onRemoveJasper = () => {
    setJasperUploadDisabled(false);
    setIzvestajChange({
      ...izvestajChange!,
      jasperFajl: undefined,
    });
    jasperUploadRef.current.clear();
  };

  const leftContentsToolbar = () => (
    <>
      {izvestajOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          disabled={!pristup || izvestajLoading || kategorijeLoading || formatLoading}
          tooltip={Labels.IZVESTAJ_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={onCreate}
        />
      )}
      {izvestajOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          disabled={!pristup || izvestajLoading || kategorijeLoading || formatLoading}
          icon="pi pi-pencil"
          tooltip={Labels.IZVESTAJ_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {izvestajOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          disabled={!pristup || izvestajLoading || kategorijeLoading || formatLoading}
          icon="pi pi-trash"
          tooltip={Labels.IZVESTAJ_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_IZVESTAJ}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.KATEGORIJA_IZVESTAJA_LIST}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!izvestajLoading && !kategorijeLoading ? (
                    <>
                      <Dropdown
                        value={izvestajChange?.kategorijaStatistickogIzvestaja?.id ?? null}
                        options={kategorijaIzvestajaList}
                        disabled={!pristup}
                        optionLabel="naziv"
                        optionValue="id"
                        onChange={(e) => {
                          setIzvestajChange({
                            ...izvestajChange,
                            kategorijaStatistickogIzvestaja: e.value ? kategorijaIzvestajaList.filter((kategorija) => kategorija.id === e.value)[0] : undefined,
                          });
                          if (e.target.value) {
                            setInvalidFields((prev) => ({ ...prev, kategorija: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, kategorija: true }));
                          }
                        }}
                        className={"p-column-filter " + (invalidFields?.kategorija && "p-invalid")}
                      />
                      {invalidFields?.kategorija && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.IZVESTAJ_NAZIV}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!izvestajLoading ? (
                    <>
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={izvestajChange?.naziv ?? ""}
                        onChange={(e) => {
                          setIzvestajChange({
                            ...izvestajChange!,
                            naziv: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setInvalid(setInvalidFields, "naziv", izvestajChange?.naziv);
                        }}
                        className={invalidFields?.naziv && "p-invalid"}
                      />
                      {invalidFields?.naziv && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </>
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
                {isSuperadmin && (
                  <>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>
                        {Labels.IZVESTAJ_JASPER}
                        {Labels.SPECIAL_CHAR_REQUIRED}
                      </div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0 mt-2 mb-2">
                      <div className="flex flex-row flex-wrap">
                        {!izvestajLoading ? (
                          <FileUpload
                            ref={jasperUploadRef}
                            auto
                            mode="basic"
                            accept=".jrxml"
                            name={"jasperFajl"}
                            disabled={!pristup || isDisabled || jasperUploadDisabled}
                            cancelOptions={cancelOptions}
                            multiple={false}
                            customUpload
                            uploadHandler={(e) => {
                              setJasperUploadDisabled(true);
                              jasperUploadHandler(e);
                              setInvalidFields((prev) => ({ ...prev, jasper: false }));
                            }}
                            chooseLabel={Labels.IZVESTAJ_CHOOSE_JASPER}
                          />
                        ) : (
                          <SkeletonInputItem />
                        )}
                        {jasperUploadDisabled && (
                          <div className="col-12 sm:col-8 p-fluid p-0 ml-2 mb-2">
                            <div className="flex flex-row flex-wrap">
                              <div className="mr-2 mt-2">{izvestajChange?.jasperFajlNaziv}</div>
                              <ConfirmComponent
                                tooltip={Labels.IZVESTAJ_REMOVE_JASPER}
                                disabled={!pristup || isDisabled || !jasperUploadDisabled}
                                buttonClassName="p-button-outlined p-button-danger"
                                buttonIcon="pi pi-trash"
                                acceptClassName="p-button p-component text-white p-button-tertiary"
                                rejectClassName="p-button p-component p-button-outlined p-button-tertiary"
                                confirmIcon="pi pi-exclamation-triangle"
                                confirmTitle={Labels.DA_LI_STE_SIGURNI_ZELITE_DA_OBRISETE_JR_DOKUMENT}
                                onAccept={() => {
                                  onRemoveJasper();
                                  setInvalidFields((prev) => ({ ...prev, jasper: true }));
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {invalidFields?.jasper && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </div>
                  </>
                )}
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.IZVESTAJ_PRIKAZUJE_SE}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!izvestajLoading ? (
                    <Checkbox
                      inputId="binary"
                      checked={izvestajChange?.prikazujeSe ?? false}
                      disabled={!pristup}
                      onChange={(e) => {
                        setIzvestajChange({
                          ...izvestajChange!,
                          prikazujeSe: e.checked,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonCheckboxItem />
                  )}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>
                    {Labels.IZVESTAJ_FORMAT}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0 flex flex-column flex-wrap">
                  <div className="flex flex-row">
                    {!izvestajLoading && !formatLoading ? (
                      fajlFormatList.map((fajlFormat: EnumBaseReadDto) => (
                        <div key={fajlFormat.id} className="pr-2">
                          <Checkbox
                            inputId={fajlFormat.naziv}
                            disabled={!pristup || isDisabled}
                            checked={fajlFormatOnCheck(fajlFormat)}
                            onChange={(e) => {
                              onFajlFormatChange(e.checked ?? false, e.value);
                              setInvalidFields((prev) => ({ ...prev, format: false }));
                            }}
                            value={fajlFormat.sifra}
                          />
                          <label htmlFor={fajlFormat.naziv} className="cursor-pointer p-checkbox-label pl-1">
                            {fajlFormat.naziv}
                          </label>
                        </div>
                      ))
                    ) : (
                      <>
                        {[...Array(5).keys()].map((j: number) => (
                          <div className="pr-4" key={j}>
                            <SkeletonCheckboxItem />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  {invalidFields?.format && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                </div>
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  <div>{Labels.IZVESTAJ_OPIS}</div>
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!izvestajLoading ? (
                    <>
                      <InputTextarea
                        disabled={!pristup || isDisabled}
                        value={izvestajChange?.opis ?? ""}
                        onChange={(e) => {
                          setIzvestajChange({
                            ...izvestajChange!,
                            opis: e.target.value,
                          });
                        }}
                        rows={3}
                      />
                    </>
                  ) : (
                    <SkeletonCheckboxItem />
                  )}
                </div>
                {isSuperadmin && (
                  <>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>
                        {Labels.LABEL_ENTITY_STATUS}
                        {Labels.SPECIAL_CHAR_REQUIRED}
                      </div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      {!entityStatusListLoading ? (
                        <>
                          <Dropdown
                            options={entityStatusList}
                            disabled={isDisabled}
                            resetFilterOnHide
                            value={izvestajChange?.entityStatus?.sifra ?? ""}
                            optionLabel="naziv"
                            optionValue="sifra"
                            onChange={(e) => {
                              setIzvestajChange({
                                ...izvestajChange!,
                                entityStatus: { sifra: e.value },
                              });
                              setInvalidFields((prev) => ({ ...prev, entityStatus: false }));
                            }}
                            className={invalidFields?.entityStatus && "p-invalid"}
                            emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                          />
                          {invalidFields?.entityStatus && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                        </>
                      ) : (
                        <SkeletonInputItem />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />
          </TabPanel>
          <TabPanel header={Labels.IZVESTAJ_PARAMETRI_LIST} disabled={izvestajOperation === EntityOperation.CREATE}>
            <IzvestajParametarListVeiw izvestajNaziv={izvestajChange?.naziv} izvestajOperation={izvestajOperation} />
          </TabPanel>
        </TabView>
      </Panel>
      <ConfirmPopup />
    </div>
  );
}
