import PaketReadDto from "../../../../model/paket/PaketReadDto";
import PaketProizvodReadDto from "../../../../model/paket/PaketProizvodReadDto";
import PaketUslugaReadDto from "../../../../model/paket/PaketUslugaReadDto";

export interface InitialStateKontaktPaketDialogType {
  isDialogOpen: boolean;
  aktivniPaketiList: Array<PaketReadDto>;
  proizvodi: Array<PaketProizvodReadDto>;
  usluge: Array<PaketUslugaReadDto>;
  loadingUslugeProizvodi: boolean;
}

const InitialStateKontaktPaketDialog: InitialStateKontaktPaketDialogType = {
  isDialogOpen: false,
  aktivniPaketiList: [],
  proizvodi: [],
  usluge: [],
  loadingUslugeProizvodi: false,
};

export default InitialStateKontaktPaketDialog;
