import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { tooltipOptionsBottom, tooltipOptionsTop } from "../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";
import PacijentPoljeReadDto from "../../../model/pacijent-polje/PacijentPoljeReadDto";
import ExportData from "../../app/export/ExportData";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../app/skeleton/SkeletonTable";
import PacijentPoljeListLogical from "./PacijentPoljeListLogical";

export default function PacijentPoljeListPage() {
  const {
    pacijentPoljeList,
    first,
    tableRows,
    changePacijentPoljeSearchData,
    pacijentPoljeSearchData,
    fetchData,
    searchPacijentPoljeByEnter,
    onPagePacijentPolje,
    exportData,
    breadCrumbItems,
    pacijentPoljeLoading,
    sifarnikVrstaGrafikonaRasta,
  } = PacijentPoljeListLogical();

  const Labels = useLabels();
  const dt = useRef<any>(null);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();
  const { pristup } = useContext(AppContext);

  const openPacijentPolje = (entityOperation: string, id?: number) => {
    const pacijentPoljeId = entityOperation !== EntityOperation.CREATE ? id : "";
    navigate(`/crud-pacijent-polje/${pacijentPoljeId}`, {
      state: { pacijentPoljeOperation: entityOperation },
    });
  };

  const crudBodyTemplate = (rowPacijentPolje: PacijentPoljeReadDto) => {
    return (
      <div className="flex justify-content-end">
        <Button
          type="button"
          tooltip={Labels.PACIJENT_POLJE_TITLE_DIALOG_UPDATE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-warning mr-2"
          icon="pi pi-pencil"
          onClick={() => rowPacijentPolje && openPacijentPolje(EntityOperation.UPDATE, rowPacijentPolje.id)}
        />
        <Button
          type="button"
          tooltip={Labels.PACIJENT_POLJE_TITLE_DIALOG_DELETE}
          tooltipOptions={tooltipOptionsTop}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          onClick={() => rowPacijentPolje && openPacijentPolje(EntityOperation.DELETE, rowPacijentPolje.id)}
          disabled={!pristup}
        />
      </div>
    );
  };

  const searchToolbar = () => {
    return (
      <>
        <Button
          label={Labels.BUTTON_ADD_PACIJENT_POLJE}
          tooltip={Labels.PACIJENT_POLJE_TITLE_DIALOG_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openPacijentPolje(EntityOperation.CREATE);
          }}
          disabled={!pristup}
        />
        <span className="p-float-label my-4 sm:my-4 md:my-0">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={pacijentPoljeSearchData?.searchString ?? ""}
            onChange={changePacijentPoljeSearchData}
            onKeyPress={searchPacijentPoljeByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_PACIJENT_POLJE}</label>
          <Button tooltip={Labels.PACIJENT_POLJE_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchData} />
        </span>
      </>
    );
  };

  const downloadButton = () => {
    return (
      <>
        <ExportData disabled={pacijentPoljeLoading} data={exportData} name={Labels.PACIJENT_POLJE_LIST} dt={dt} />
      </>
    );
  };

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.PACIJENT_POLJE_NAZIV, filter: true, sortrable: true },
    { columnName: Labels.PACIJENT_POLJE_SIFRA, filter: true, sortrable: true },
    { columnName: Labels.PACIJENT_POLJE_TIP, filter: true, sortrable: true },
    { columnName: Labels.PACIJENT_POLJE_VRSTA_GRAFIKONA, filter: true, sortrable: false },
  ];

  const vrstaGrafikonaRastaFilter = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={sifarnikVrstaGrafikonaRasta.map((item) => {
          return { label: item.naziv, value: item.naziv };
        })}
        onChange={(e) => dt.current.filter(e.value, "vrstaGrafikonaRasta.naziv", "equals")}
        placeholder={Labels.CEKAONICA_COLUMN_SVE}
        className="p-column-filter"
        showClear
      />
    );
  };

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <Toolbar left={searchToolbar} right={downloadButton} />
        {pacijentPoljeLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={pacijentPoljeList ?? []}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPagePacijentPolje(e.rows, e.first);
            }}
            exportFilename={Labels.PACIJENT_POLJE_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column field={"naziv"} header={Labels.PACIJENT_POLJE_NAZIV} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"sifra"} header={Labels.PACIJENT_POLJE_SIFRA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"poljeTip.naziv"} header={Labels.PACIJENT_POLJE_TIP} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column field={"vrstaGrafikonaRasta.naziv"} header={Labels.PACIJENT_POLJE_VRSTA_GRAFIKONA} filter filterElement={vrstaGrafikonaRastaFilter} showFilterMenu={false} sortable={false} />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
