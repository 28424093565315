import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import EnumTipKomponente from "../../../infrastructure/system/EnumTipKomponente";
import { formatDate } from "../../../infrastructure/system/Utils";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import CalendarComponent from "../calendar-component/CalendarComponent";
import CrudPoljeLogical from "./CrudPoljeLogical";

export default function CrudPolje() {
  const {
    poljeChange,
    componentPoljeTipList,
    setPoljeChange,
    setIndex,
    index,
    isDisabled,
    breadCrumbItems,
    poljeOperation,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    poljeLoading,
    poljeOptionsLoading,
    selectedTipKomponente,
    poljeTipEnumStavkaList,
    getPoljeTip,
    resetInicijalnaVrednostAndSetPoljeTip,
    poljePanelList,
    poljePanelOptionsLoading,
  } = CrudPoljeLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const leftContentsToolbar = () => (
    <>
      {poljeOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} disabled={!pristup || poljeOptionsLoading} tooltip={Labels.TOOLTIP_CREATE_POLJE} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {poljeOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.TOOLTIP_UPDATE_POLJE}
          disabled={!pristup || poljeLoading || poljeOptionsLoading}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={onUpdate}
        />
      )}
      {poljeOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.TOOLTIP_DELETE_POLJE}
          disabled={!pristup || poljeLoading || poljeOptionsLoading}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={onDelete}
        />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div>
      <Panel>
        <div className="layout-crud-generic-content">
          <BreadCrumb model={breadCrumbItems} />
          <TabView
            className="mt-3"
            renderActiveOnly={false}
            activeIndex={index}
            onTabChange={(e) => {
              setIndex(e.index);
            }}
          >
            <TabPanel header={Labels.LABEL_POLJE}>
              <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
                <div className="grid align-items-center p-0">
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.LABEL_SIFRA}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!poljeLoading ? (
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={poljeChange?.sifra ?? ""}
                        onChange={(e) => {
                          setPoljeChange({
                            ...poljeChange!,
                            sifra: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.LABEL_NAZIV}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!poljeLoading ? (
                      <InputText
                        disabled={!pristup || isDisabled}
                        value={poljeChange?.naziv ?? ""}
                        onChange={(e) => {
                          setPoljeChange({
                            ...poljeChange!,
                            naziv: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>{Labels.POLJE_NADREDJENO}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!poljeLoading && !poljePanelOptionsLoading ? (
                      <Dropdown
                        disabled={!pristup || isDisabled}
                        optionLabel="naziv"
                        optionValue="id"
                        showClear
                        options={poljePanelList}
                        value={poljeChange?.poljeNadredjeno?.id ?? ""}
                        filter
                        onChange={(e) => {
                          setPoljeChange({
                            ...poljeChange!,
                            poljeNadredjeno: poljePanelList.find((polje) => polje.id === e.target.value),
                          });
                        }}
                        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>{Labels.LABEL_PRIKAZNI_REDOSLED}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!poljeLoading ? (
                      <InputNumber
                        disabled={!pristup || isDisabled}
                        value={poljeChange?.prikazniRedosled ?? undefined}
                        onChange={(e) => {
                          setPoljeChange({
                            ...poljeChange!,
                            prikazniRedosled: e.value != null ? e.value : undefined,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>
                      {Labels.POLJE_TIP}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!poljeLoading && !poljeOptionsLoading ? (
                      <Dropdown
                        disabled={!pristup || isDisabled}
                        optionLabel="label"
                        optionValue="value"
                        options={componentPoljeTipList}
                        optionGroupLabel="label"
                        optionGroupChildren="list"
                        value={poljeChange?.poljeTip?.id ?? ""}
                        filter
                        filterBy="label"
                        onChange={(e) => {
                          getPoljeTip(e.value);
                          resetInicijalnaVrednostAndSetPoljeTip(e.value);
                        }}
                        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                    <div>{Labels.POLJE_OBAVEZNO}</div>
                  </div>
                  <div className="col-12 sm:col-8 p-fluid p-0">
                    {!poljeLoading ? (
                      <InputSwitch
                        disabled={!pristup || isDisabled}
                        checked={poljeChange?.obavezno ?? false}
                        onChange={(e) => {
                          setPoljeChange({
                            ...poljeChange!,
                            obavezno: e.target.value ?? undefined,
                          });
                        }}
                      />
                    ) : (
                      <SkeletonInputItem />
                    )}
                  </div>
                  {(selectedTipKomponente?.sifra === EnumTipKomponente.TEXT || selectedTipKomponente?.sifra === EnumTipKomponente.LONG_TEXT) && (
                    <>
                      <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                        <div>{Labels.INICIJALNA_VREDNOST}</div>
                      </div>
                      <div className="col-12 sm:col-8 p-fluid p-0">
                        {!poljeLoading ? (
                          <InputTextarea
                            rows={3}
                            cols={5}
                            disabled={!pristup || isDisabled}
                            value={poljeChange?.inicijalnaVrednostString ?? ""}
                            onChange={(e) => {
                              setPoljeChange({
                                ...poljeChange!,
                                inicijalnaVrednostString: e.target.value,
                              });
                            }}
                          />
                        ) : (
                          <SkeletonInputItem />
                        )}
                      </div>
                    </>
                  )}
                  {selectedTipKomponente?.sifra === EnumTipKomponente.BOOLEAN && (
                    <>
                      <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                        <div>{Labels.INICIJALNA_VREDNOST}</div>
                      </div>
                      <div className="col-12 sm:col-8 p-fluid p-0">
                        {!poljeLoading ? (
                          <InputSwitch
                            disabled={!pristup || isDisabled}
                            checked={poljeChange?.inicijalnaVrednostBoolean ?? false}
                            onChange={(e) => {
                              setPoljeChange({
                                ...poljeChange!,
                                inicijalnaVrednostBoolean: e.target.value ?? undefined,
                              });
                            }}
                          />
                        ) : (
                          <SkeletonInputItem />
                        )}
                      </div>
                    </>
                  )}
                  {(selectedTipKomponente?.sifra === EnumTipKomponente.NUMBER_INTEGER || selectedTipKomponente?.sifra === EnumTipKomponente.NUMBER_DECIMAL) && (
                    <>
                      <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                        <div>{Labels.INICIJALNA_VREDNOST}</div>
                      </div>
                      <div className="col-12 sm:col-8 p-fluid p-0">
                        {!poljeLoading ? (
                          <InputNumber
                            disabled={!pristup || isDisabled}
                            value={poljeChange?.inicijalnaVrednostBroj ?? undefined}
                            onChange={(e) => {
                              setPoljeChange({
                                ...poljeChange!,
                                inicijalnaVrednostBroj: e.value ?? undefined,
                              });
                            }}
                          />
                        ) : (
                          <SkeletonInputItem />
                        )}
                      </div>
                    </>
                  )}
                  {(selectedTipKomponente?.sifra === EnumTipKomponente.SET || selectedTipKomponente?.sifra === EnumTipKomponente.SET_RADIO) && (
                    <>
                      <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                        <div>{Labels.INICIJALNA_VREDNOST}</div>
                      </div>
                      <div className="col-12 sm:col-8 p-fluid p-0">
                        {!poljeLoading ? (
                          <Dropdown
                            showClear
                            disabled={!pristup || isDisabled}
                            optionLabel="naziv"
                            optionValue="id"
                            options={poljeTipEnumStavkaList}
                            value={poljeChange?.inicijalnaVrednostEnum ?? undefined}
                            onChange={(e) => {
                              setPoljeChange({
                                ...poljeChange!,
                                inicijalnaVrednostEnum: e.value,
                              });
                            }}
                          />
                        ) : (
                          <SkeletonInputItem />
                        )}
                      </div>
                    </>
                  )}
                  {selectedTipKomponente?.sifra === EnumTipKomponente.DATE_TIME && (
                    <>
                      <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                        <div>{Labels.INICIJALNA_VREDNOST}</div>
                      </div>
                      <div className="col-12 sm:col-8 p-fluid p-0">
                        {!poljeLoading ? (
                          <CalendarComponent
                            showTime
                            value={poljeChange?.inicijalnaVrednostDatum ?? undefined}
                            onChange={(e: CalendarChangeEvent) => {
                              setPoljeChange({
                                ...poljeChange!,
                                inicijalnaVrednostDatum: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                              });
                            }}
                            disabled={!pristup || isDisabled}
                          />
                        ) : (
                          <SkeletonInputItem />
                        )}
                      </div>
                    </>
                  )}
                  {selectedTipKomponente?.sifra === EnumTipKomponente.DATE && (
                    <>
                      <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                        <div>{Labels.INICIJALNA_VREDNOST}</div>
                      </div>
                      <div className="col-12 sm:col-8 p-fluid p-0">
                        {!poljeLoading ? (
                          <CalendarComponent
                            value={poljeChange?.inicijalnaVrednostDatum ?? undefined}
                            onChange={(e: CalendarChangeEvent) => {
                              setPoljeChange({
                                ...poljeChange!,
                                inicijalnaVrednostDatum: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                              });
                            }}
                            disabled={!pristup || isDisabled}
                          />
                        ) : (
                          <SkeletonInputItem />
                        )}
                      </div>
                    </>
                  )}
                  {selectedTipKomponente?.sifra === EnumTipKomponente.TIME && (
                    <>
                      <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                        <div>{Labels.INICIJALNA_VREDNOST}</div>
                      </div>
                      <div className="col-12 sm:col-8 p-fluid p-0">
                        {!poljeLoading ? (
                          <CalendarComponent
                            value={poljeChange?.inicijalnaVrednostDatum ?? undefined}
                            timeOnly
                            onChange={(e: CalendarChangeEvent) => {
                              setPoljeChange({
                                ...poljeChange!,
                                inicijalnaVrednostDatum: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined),
                              });
                            }}
                            disabled={!pristup || isDisabled}
                            icon="pi pi-clock"
                          />
                        ) : (
                          <SkeletonInputItem />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
            </TabPanel>
          </TabView>
        </div>
      </Panel>
    </div>
  );
}
