import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { AppContext, useLabels } from "../../Store";
import KontrolaPravaPristupaService from "../../infrastructure/system/KontrolaPravaPristupaService";
import MessageType from "../../infrastructure/system/MessageType";
import { addSrLocal, tooltipOptionsBottom, tooltipOptionsRight } from "../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../infrastructure/system/hooks/PageDropdownTemplate";
import actionsAddPacijent from "../../infrastructure/system/hooks/dialog-add-pacijent-reducer/actionsAddPacijent";
import CalendarComponent from "../administracija/calendar-component/CalendarComponent";
import SkeletonInputItem from "../app/skeleton/SkeletonInputItem";
import SkeletonTable, { SkeletonTableColumnProperty } from "../app/skeleton/SkeletonTable";
import PretragaPacijenataLogical from "./PretragaPacijenataLogical";
import DialogAddPacijent from "./dialog/DialogAddPacijent";

export default function PretragaPacijenataView() {
  const Labels = useLabels();
  const { pristup, showMessage, authData } = useContext(AppContext);
  const navigate = useNavigate();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const dt = useRef<any>(null);
  const {
    checked,
    setChecked,
    orgJedinicaList,
    showResults,
    setShowResults,
    selectedTableColumns,
    tableColumns,
    onColumnToggle,
    first,
    setFirst,
    tableRows,
    onPageFormTable,
    patientList,
    searchPacijent,
    searchPatientObject,
    setSearchPatientObject,
    searchPacijentByEnter,
    onChangeCalendar,
    searchPatientLoading,
    orgJedListLoading,
    searchSifarnikComplete,
    onChangeAutoComplete,
    sifarnikSuggestionsList,
    selectedDijagnoze,
    dijagnozaAutocompleteLoading,
    mainDivRef,
    pacijentCount,
    state,
    dispatch,
    polList,
    obavestenjeTipList,
    onCreate,
    openDialog,
    searchRef,
    pacijentExists,
    setPacijentExists,
    warningMessageRef,
    checkPacijentExists,
    datumOdRef,
    datumDoRef,
  } = PretragaPacijenataLogical();
  addSrLocal();

  const { hasZakazanTerminCreate, hasPacijentCreate, hasPacijentRead } = KontrolaPravaPristupaService();

  const columnsProperty: Array<SkeletonTableColumnProperty> = [{ columnName: Labels.HEADER_FULLNAME, bodyClassName: "p-2", headerClassName: "p-2" }];

  const columnComponents =
    selectedTableColumns &&
    selectedTableColumns?.map((col: any) => {
      columnsProperty.push({ columnName: col.header, bodyClassName: "p-2 overflow-hidden hc-text-overflow-ellipsis", headerClassName: "p-2 overflow-hidden" });
      return <Column bodyClassName="p-2 overflow-hidden hc-text-overflow-ellipsis" headerClassName="p-2 overflow-hidden" key={col.field} field={col.field} header={col.header} />;
    });

  const autocompleteItemTemplate = (e: any) => {
    return e.punNaziv;
  };

  return (
    <div className="layout-pretraga-pacijenata" ref={mainDivRef}>
      <Panel>
        <Panel>
          <div className="flex flex-wrap md:col-12 justify-content-center">
            <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
              <div className="flex flex-column">
                <div className="col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12">
                  <span className="p-input-icon-left display-style">
                    <i className="pi pi-search" />
                    <InputText
                      className="inputfield w-full"
                      ref={searchRef}
                      value={searchPatientObject?.basicSearch ?? ""}
                      onChange={(e) => {
                        setSearchPatientObject({ ...searchPatientObject, basicSearch: e.target.value, page: 0 });
                        setFirst(0);
                      }}
                      placeholder={Labels.LABEL_TOOLTIP_PATIENT_SEARCH_ALL}
                      tooltipOptions={tooltipOptionsBottom}
                      onKeyPress={searchPacijentByEnter}
                      data-cy="patientBasicSearchInput"
                    />
                  </span>
                </div>
                <div>
                  <div className="flex field-checkbox justify-content-center">
                    <Checkbox inputId="binary" checked={checked} onChange={(e) => setChecked(e.checked ?? false)} className="mr-1" />
                    <label className="cursor-pointer" htmlFor="binary">
                      {Labels.LABEL_ADDITIONAL_SEARCH}
                    </label>
                  </div>
                </div>
                <div>
                  {checked && (
                    <>
                      <div className="flex flex-wrap">
                        {orgJedListLoading ? (
                          <SkeletonInputItem height="2.5rem" />
                        ) : (
                          <Dropdown
                            value={searchPatientObject?.organizacionaJedinicaID ?? ""}
                            options={orgJedinicaList}
                            onChange={(e) => {
                              setSearchPatientObject({ ...searchPatientObject, organizacionaJedinicaID: e.value, page: 0 });
                              setFirst(0);
                            }}
                            optionLabel="naziv"
                            optionValue="id"
                            placeholder={Labels.PLACEHOLDER_CHOOSE_ORG_JED}
                            className="dropdown-style inputfield w-full"
                            showClear
                            emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                          />
                        )}
                      </div>

                      <div className="flex flex-wrap relative justify-content-center">
                        <AutoComplete
                          value={selectedDijagnoze}
                          suggestions={sifarnikSuggestionsList}
                          completeMethod={searchSifarnikComplete}
                          className="autocomplete-style inputfield w-full"
                          placeholder={Labels.PLACEHOLDER_DIJAGNOZA}
                          field="sifra"
                          itemTemplate={(e) => autocompleteItemTemplate(e)}
                          multiple
                          onChange={(e) => {
                            onChangeAutoComplete(e);
                          }}
                        />

                        <div className="float-right   absolute  dijagnoza-spinner">
                          {dijagnozaAutocompleteLoading && (
                            <svg className="p-progress-spinner-svg dijagnoza-spinner-svg" viewBox="5 5 10 10">
                              <circle className="p-progress-spinner-circle" cx="10" cy="10" r="4" fill="none" strokeWidth="1" strokeMiterlimit="20"></circle>
                            </svg>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
                          <CalendarComponent
                            ref={datumOdRef}
                            name="datumKontaktaOd"
                            value={searchPatientObject?.datumKontaktaOd}
                            onChange={(e: CalendarChangeEvent) => {
                              onChangeCalendar(e);
                              if (e.target.value) datumOdRef?.current?.hide();
                            }}
                            placeholder={Labels.PLACEHOLDER_DATE_KONTAKT_FROM}
                          />
                        </div>
                        <div className="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12">
                          <CalendarComponent
                            ref={datumDoRef}
                            name="datumKontaktaDo"
                            value={searchPatientObject?.datumKontaktaDo}
                            onChange={(e: CalendarChangeEvent) => {
                              onChangeCalendar(e);
                              if (e.target.value) datumDoRef?.current?.hide();
                            }}
                            placeholder={Labels.PLACEHOLDER_DATE_KONTAKT_TO}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 xl:col-3 lg:col-3 md:col-3 sm:col-12">
              <div className="flex flex-wrap flex-column sm:align-content-center md:align-content-start lg:align-content-start align-content-center">
                <div className={showResults ? "flex col-fixed  lg:w-8" : "flex col-fixed lg:w-5 md:w-6 sm:w-2 sm:px-0"}>
                  <Button
                    label={Labels.LABEL_PATIENT_SEARCH}
                    tooltip={Labels.LABEL_TOOLTIP_PATIENT_SEARCH}
                    tooltipOptions={tooltipOptionsBottom}
                    className="p-button-success flex-shrink-0"
                    onClick={() => {
                      searchPacijent(searchPatientObject);
                      setShowResults(true);
                    }}
                    data-cy="searchPatient"
                  />
                  {showResults && (
                    <Button
                      icon="pi pi-times"
                      tooltip={Labels.LABEL_TOOLTIP_PATIENT_SEARCH_ERASE}
                      tooltipOptions={tooltipOptionsBottom}
                      className="p-button-outlined ml-2 flex-shrink-0"
                      onClick={() => {
                        setShowResults(false);
                        setSearchPatientObject({ page: searchPatientObject.page, size: searchPatientObject.size });
                      }}
                    />
                  )}
                  <Button
                    label={Labels.BUTTON_ADD_PACIJENT}
                    tooltip={Labels.LABEL_TOOLTIP_ADD_PATIENT}
                    tooltipOptions={tooltipOptionsBottom}
                    className="p-button-info flex-shrink-0 ml-2"
                    icon="pi pi-plus"
                    onClick={() => {
                      openDialog();
                    }}
                    data-cy="pretragaPacijenataAddModalBtn"
                    disabled={!hasPacijentCreate}
                  />
                </div>
              </div>
            </div>
          </div>
        </Panel>
        {showResults && (
          <Panel className="mt-2">
            {!searchPatientLoading && (
              <MultiSelect
                tooltip={Labels.LABEL_TOOLTIP_CHOOSE_COLUMN}
                tooltipOptions={tooltipOptionsRight}
                value={selectedTableColumns}
                options={tableColumns}
                fixedPlaceholder
                optionLabel="header"
                onChange={onColumnToggle}
                placeholder={Labels.LABEL_SHOW_COLUMNS}
                filter
                className="multiselect-custom mb-2"
                showClear
                maxSelectedLabels={0}
                emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              />
            )}
            <div className="card">
              {searchPatientLoading ? (
                <SkeletonTable dataTableColumnsProperty={columnsProperty} isVisibleButtonList={false} />
              ) : (
                <DataTable
                  ref={dt}
                  value={patientList}
                  lazy
                  paginator
                  first={first}
                  rows={tableRows}
                  onPage={(e) => {
                    onPageFormTable(e.rows, e.first, e.page);
                  }}
                  totalRecords={pacijentCount}
                  paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
                  emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                  onRowClick={(e) => {
                    if (hasPacijentRead || e.data.radnikKreirao.id === authData?.currentRadnik.id) {
                      navigate(`/pacijent-dosije/${e.data.id}`);
                    } else {
                      showMessage(MessageType.ERROR, Labels.MESSAGE_ZABRANJEN_PRISTUP);
                    }
                  }}
                  rowHover
                  stripedRows
                  className="cy-patientsTable"
                >
                  <Column bodyClassName="p-2" headerClassName="p-2" field={"ip"} header={Labels.HEADER_FULLNAME} />
                  {columnComponents}
                  <Column
                    body={(row) => (
                      <div className="flex justify-content-end">
                        <Button
                          label={Labels.ZAKAZI_PREGLED}
                          icon="pi pi-calendar"
                          onClick={() => {
                            window.open(`/zakazivanje-pregleda/${row.id}`, "_blank", "noopener,noreferrer");
                          }}
                          disabled={!pristup || !hasZakazanTerminCreate}
                        />
                      </div>
                    )}
                  />
                </DataTable>
              )}
            </div>
          </Panel>
        )}
      </Panel>
      <DialogAddPacijent
        isDialogOpen={state.isDialogOpen}
        closeDialog={() => {
          [].forEach.call(document.getElementsByClassName("p-datepicker-mask"), function (element: Element) {
            element.remove();
          });
          dispatch({ type: actionsAddPacijent.CLOSE_DIALOG });
          setPacijentExists(false);
        }}
        polList={polList}
        polListLoading={state.gendersLoading}
        obavestenjeTipList={obavestenjeTipList}
        obavestenjeTipListLoading={state.obavestenjeTipListLoading}
        pacijent={state.pacijent}
        dispatch={dispatch}
        onCreate={onCreate}
        pacijentExists={pacijentExists}
        warningMessageRef={warningMessageRef}
        checkPacijentExists={checkPacijentExists}
      />
    </div>
  );
}
