import axios from "axios";
import moment from "moment";
import { CheckboxChangeEvent } from "primereact/checkbox";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../Store";
import FaktorRizikaController from "../../../controllers/faktor-rizika/FaktorRizikaController";
import { PacijentController } from "../../../controllers/pacijent/PacijentController";
import { handleAxiosCallError, skeletonTimeout } from "../../../infrastructure/system/Utils";
import FaktorRizikaReadDto from "../../../model/faktor-rizika/FaktorRizikaReadDto";
import FaktorRizikaUstanovaReadDto from "../../../model/faktor-rizika/FaktorRizikaUstanovaReadDto";
import PacijentFaktorRizikaUstanovaReadDto from "../../../model/faktor-rizika/PacijentFaktorRizikaUstanovaReadDto";

interface PacijentRizikLogicalType {
  changeRizikList: (e: CheckboxChangeEvent) => void;
  rizikList: Array<any>;
  saveFaktoriRizika: () => void;
  op: any;
  openOverlay: (e: React.MouseEvent<HTMLElement>) => void;
  closeOverlay: () => void;
  rizikListLoading: boolean;
  faktorRizikaList: Array<FaktorRizikaReadDto>;
}
interface PacijentRizikLogicalProps {
  pacijentId?: number;
}
export interface FaktorRizikaExtendedReadDto extends FaktorRizikaReadDto {
  postoji?: boolean;
}
export default function PacijentRizikLogical(props: PacijentRizikLogicalProps): PacijentRizikLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const op = useRef<any>();
  const { pacijentId } = props;
  const { axiosGetPacijentFaktoriRizika, axiosPostPacijentFaktoriRizika } = PacijentController();
  const { axiosGetAllFaktorRizika, axiosGetAllFaktorRizikaUstanova } = FaktorRizikaController();
  const [faktorRizikaList, setFaktorRizikaList] = useState<Array<FaktorRizikaExtendedReadDto>>([]);
  const [rizikList, setRizikList] = useState([]);
  const [rizikListLoading, setRizikListLoading] = useState<boolean>(true);

  const fetchData = async () => {
    if (pacijentId) {
      let startTime = moment(new Date());

      setRizikListLoading(true);
      const getFaktorRizika = axiosGetAllFaktorRizika({});
      const getFaktorRizikaUstanova = axiosGetAllFaktorRizikaUstanova();
      let faktorRizikaList: Array<FaktorRizikaReadDto> = [];
      await axios
        .all([getFaktorRizika, getFaktorRizikaUstanova])
        .then(
          axios.spread((getFaktorRizikaResponse: any, getFaktorRizikaUstanovaResponse: any) => {
            faktorRizikaList = getFaktorRizikaResponse.data.data;
            let faktorRizikaUstanovaList: Array<FaktorRizikaUstanovaReadDto> = getFaktorRizikaUstanovaResponse.data.data;
            faktorRizikaUstanovaList.forEach((faktorRizikaUstanova: FaktorRizikaUstanovaReadDto) => {
              let faktorRizikaIndex = faktorRizikaList.findIndex((faktorRizika: FaktorRizikaReadDto) => faktorRizika.id === faktorRizikaUstanova.idRepositoryFaktorRizika);
              if (faktorRizikaIndex !== -1) {
                faktorRizikaList[faktorRizikaIndex].faktorRizikaUstanovaId = faktorRizikaUstanova.id;
              }
            });
            faktorRizikaList = faktorRizikaList.filter((faktorRizika: FaktorRizikaExtendedReadDto) => faktorRizika.faktorRizikaUstanovaId !== undefined);

            getFaktorRizikaResponse.data.data.forEach((element: any) => {
              element.ikonica.ikonica = "data:image/svg+xml;base64," + element.ikonica.ikonica;
            });
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });

      axiosGetPacijentFaktoriRizika(pacijentId)
        .then((response: any) => {
          let rizikIdList = response.data.data.map((rizik: PacijentFaktorRizikaUstanovaReadDto) => rizik?.faktorRizikaUstanova?.idRepositoryFaktorRizika);
          rizikIdList = rizikIdList.filter((element: any) => element !== undefined);
          const newRizikList: any = [];
          faktorRizikaList.forEach((rizik: FaktorRizikaExtendedReadDto) => {
            if (rizikIdList.includes(rizik.id)) {
              newRizikList.push(rizik);
              rizik.postoji = true;
            }
          });
          setFaktorRizikaList(faktorRizikaList);
          setRizikList(newRizikList);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setRizikListLoading, startTime);
        });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pacijentId]);

  const updateOdabraniRizikList = () => {
    const rizikIdList = rizikList?.map((rizik: any) => rizik.id);
    const newRizikList: any = faktorRizikaList.map((rizik: any) => {
      return { ...rizik, postoji: rizikIdList.includes(rizik.id) };
    });
    setFaktorRizikaList(newRizikList);
  };

  useEffect(() => {
    updateOdabraniRizikList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rizikList]);

  const saveFaktoriRizika = () => {
    const params: any = {
      rizikList: faktorRizikaList
        .filter((r: any) => r.postoji)
        .map((rizik: FaktorRizikaExtendedReadDto) => ({
          idRepositoryFaktorRizika: rizik.id,
        })),
    };

    pacijentId &&
      axiosPostPacijentFaktoriRizika(pacijentId, params)
        .then((response: any) => {
          fetchData();
          closeOverlay();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
  };

  const changeRizikList = (e: CheckboxChangeEvent) => {
    const newRizikList: any = faktorRizikaList.map((rizik: any) => {
      return rizik.naziv === e.value ? { ...rizik, postoji: e.checked } : rizik;
    });
    setFaktorRizikaList(newRizikList);
  };

  const openOverlay = (e: React.MouseEvent<HTMLElement>) => {
    op.current.show(e);
  };

  const closeOverlay = () => {
    op.current.hide();
    updateOdabraniRizikList();
  };

  return { changeRizikList, rizikList, saveFaktoriRizika, op, openOverlay, closeOverlay, rizikListLoading, faktorRizikaList };
}
