import moment from "moment";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { BrojNalogaController } from "../../../../controllers/broj-naloga/BrojNalogaController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, skeletonTimeout, useEffectOnce, validateStringEmpty } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../../model/BreadCrumbItemDto";
import BrojNalogaReadDto from "../../../../model/licenca/broj-naloga/BrojNalogaReadDto";

interface CrudBrojNalogaLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  brojNalogaLoading: boolean;
  brojNalogaChange: BrojNalogaReadDto | undefined;
  setBrojNalogaChange: React.Dispatch<React.SetStateAction<any>>;
  isDisabled: boolean;
  brojNalogaOperation: string;
  onCreate: () => void;
  onUpdate: () => void;
  onCancel: () => void;
}

interface UseParamsType {
  brojNalogaId?: string;
}

export function CrudBrojNalogaLogical(): CrudBrojNalogaLogicalType {
  const Labels = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const { showMessage, setShowBlockUI } = useContext(AppContext);

  const { axiosGetBrojNaloga, axiosCreateBrojNaloga, axiosUpdateBrojNaloga } = BrojNalogaController();
  const postLogHighLevel = useLogHighLevel();

  const [brojNalogaLoading, setBrojNalogaLoading] = useState(true);
  const [brojNalogaChange, setBrojNalogaChange] = useState<BrojNalogaReadDto | undefined>();
  const [brojNalogaOperation] = useState(location.state?.brojNalogaOperation || EntityOperation.UPDATE);
  const [isDisabled] = useState(isFormDisabled(brojNalogaOperation));
  const brojNalogaId = Number(useParams<keyof UseParamsType>()["brojNalogaId"]);

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.LICENCA_LIST,
      command: () => {
        navigate("/licence", { state: { tabIndex: 0 } });
      },
    },
  ]);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (bnID?: number, operation?: string) => {
    const newBrojNalogaId = bnID || brojNalogaId;
    const startTime = moment(new Date());

    if (newBrojNalogaId) {
      axiosGetBrojNaloga(newBrojNalogaId)
        .then(({ data: { data } }: { data: { data: BrojNalogaReadDto } }) => {
          setBrojNalogaChange(data);
          const operationTmp = operation ?? brojNalogaOperation;
          setBreadCrumbItems([
            breadCrumbItems[0],
            {
              label:
                operationTmp === EntityOperation.UPDATE
                  ? Labels.BROJ_NALOGA_TITLE_UPDATE + data.naziv
                  : operationTmp === EntityOperation.READ
                  ? Labels.BROJ_NALOGA_TITLE_DETAILS + data.naziv
                  : Labels.BROJ_NALOGA_TITLE_DELETE + data.naziv,
            },
          ]);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setBrojNalogaLoading, startTime);
        });
    } else {
      setBrojNalogaLoading(false);
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.BROJ_NALOGA_TITLE_CREATE,
        },
      ]);
    }
  };

  const validateInput = (brojNaloga: BrojNalogaReadDto | undefined) => {
    if (brojNaloga === undefined || validateStringEmpty(brojNaloga.naziv)) {
      showMessage(MessageType.ERROR, Labels.BROJ_NALOGA_NAZIV_IS_REQUIRED);
      return false;
    }
    if (validateStringEmpty(brojNaloga.sifra)) {
      showMessage(MessageType.ERROR, Labels.BROJ_NALOGA_SIFRA_IS_REQUIRED);
      return false;
    }

    if (brojNaloga.broj === undefined) {
      showMessage(MessageType.ERROR, Labels.BROJ_NALOGA_BROJ_IS_REQUIRED);
      return false;
    }
    if (brojNaloga.broj <= 0) {
      showMessage(MessageType.ERROR, Labels.BROJ_NALOGA_BROJ_VALUE_NOT_VALID);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateInput(brojNalogaChange)) {
      return;
    }

    if (brojNalogaChange) {
      setShowBlockUI(true);
      axiosCreateBrojNaloga(brojNalogaChange)
        .then(({ data: { data } }: { data: { data: BrojNalogaReadDto } }) => {
          showMessage(MessageType.SUCCESS, Labels.BROJ_NALOGA_MESSAGE_CREATE_SUCCESS);
          onCancel();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_1 +
              brojNalogaChange.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_2 +
              brojNalogaChange.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_3 +
              brojNalogaChange.broj +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_4 +
              brojNalogaChange.cena +
              Labels.LOG_HIGH_LEVEL_MESS_CREATE_BROJ_NALOGA_5
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onUpdate = () => {
    if (!validateInput(brojNalogaChange)) {
      return;
    }
    if (brojNalogaChange) {
      setShowBlockUI(true);
      axiosUpdateBrojNaloga(brojNalogaChange)
        .then(() => {
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_1 +
              brojNalogaChange.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_2 +
              brojNalogaChange.sifra +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_3 +
              brojNalogaChange.broj +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_4 +
              brojNalogaChange.cena +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_BROJ_NALOGA_5
          );
          showMessage(MessageType.SUCCESS, Labels.BROJ_NALOGA_MESSAGE_UPDATE_SUCCESS);
          onCancel();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      const breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/licence`, {
        state: { tabIndex: 0 },
      });
    }
  };

  return {
    breadCrumbItems,
    brojNalogaLoading,
    brojNalogaChange,
    setBrojNalogaChange,
    isDisabled,
    brojNalogaOperation,
    onCreate,
    onUpdate,
    onCancel,
  };
}
