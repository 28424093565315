import axios from "axios";
import moment from "moment";
import { useContext, useImperativeHandle, useState } from "react";
import { AppContext, useLabels } from "../../Store";
import { PacijentPoljeController } from "../../controllers/pacijent-polje/PacijentPoljeController";
import { PacijentController } from "../../controllers/pacijent/PacijentController";
import EnumPacijentPoljeKategorija from "../../infrastructure/system/EnumPacijentPoljeKategorija";
import EnumTipKomponente from "../../infrastructure/system/EnumTipKomponente";
import MessageType from "../../infrastructure/system/MessageType";
import { DATE_TIME_FORMAT_3, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../infrastructure/system/Utils";
import PacijentPoljeKategorijaReadDto from "../../model/pacijent-polje/PacijentPoljeKategorijaReadDto";
import PacijentPoljePopunjeno from "../../model/pacijent-polje/PacijentPoljePopunjeno";
import PacijentReadDto from "../../model/pacijent/PacijentReadDto";
import PoljeVrednostDto from "../../model/polje/PoljeVrednostDto";
import { valueMap } from "../formular/FormularLogical";

interface PacijentPoljeLogicalType {
  pacijentPoljeList: Array<PacijentPoljeKategorijaReadDto>;
  onChange: (e: PoljeVrednostDto) => void;
  pacijentPoljePopunjeno: valueMap;
  pacijentPoljeListLoading: boolean;
}

interface PacijentPoljeLogicalProps {
  pacijent?: PacijentReadDto;
  setPoljePopunjenoLoading: any;
  pacijentRef: any;
  kategorijaEnum: string;
  fetchMedicinskiPodaciAndInformacijeOPacijentu: () => void;
}

export default function PacijentPoljeLogical(props: PacijentPoljeLogicalProps): PacijentPoljeLogicalType {
  const { kategorijaEnum, pacijent, pacijentRef, setPoljePopunjenoLoading, fetchMedicinskiPodaciAndInformacijeOPacijentu } = props;
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const Labels = useLabels();
  const { axiosGetPacijentPoljeKategorijaListByKategorija } = PacijentPoljeController();
  const { axiosCreatePacijentPoljaPopunjena, axiosGetPacijentPoljePopunjenoList, axiosUpdatePacijentWithPacijentPoljePopunjenoList } = PacijentController();
  const [pacijentPoljeList, setPacijentPoljeList] = useState<Array<PacijentPoljeKategorijaReadDto>>([]);
  const [pacijentPoljePopunjeno, setPacijentPoljePopunjeno] = useState<valueMap>({});
  const [pacijentPoljeListLoading, setPacijentPoljeListLoading] = useState<boolean>(true);

  useEffectOnce(() => {
    fetchData();
  });

  useImperativeHandle(pacijentRef, () => ({
    onSave: (pacijent: PacijentReadDto) => {
      onSave(pacijent);
    },
  }));

  const fetchData = async () => {
    let startTime = moment(new Date());
    if (pacijent?.id)
      axios
        .all([axiosGetPacijentPoljeKategorijaListByKategorija(kategorijaEnum), axiosGetPacijentPoljePopunjenoList(kategorijaEnum, pacijent?.id)])
        .then(
          axios.spread((responsePacijentPoljeKategorijaByKategorija: any, responseGetPacijentPoljePopunjenoList: any) => {
            setPacijentPoljeList(responsePacijentPoljeKategorijaByKategorija.data.data);
            let poljeVrednost: valueMap = {};

            responseGetPacijentPoljePopunjenoList.data.data.forEach((pacijentPoljePopunjeno: PacijentPoljePopunjeno) => {
              if (
                pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.TEXT ||
                pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.LONG_TEXT
              ) {
                poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostString;
              } else if (
                pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.NUMBER_DECIMAL ||
                pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.NUMBER_INTEGER
              ) {
                poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostNumber;
              } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.DATE_TIME) {
                poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
              } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.DATE) {
                poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
              } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.TIME) {
                poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = moment(pacijentPoljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT_3);
              } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.BOOLEAN) {
                poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno.vrednostBoolean;
              } else if (
                pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET ||
                pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET_RADIO
              ) {
                poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = pacijentPoljePopunjeno?.vrednostEnumeratorItem?.id;
              } else if (pacijentPoljePopunjeno?.pacijentPolje?.poljeTip?.tipKomponenteEnum.sifra === EnumTipKomponente.SET_MULTISELECT) {
                if (poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] !== undefined) {
                  poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = [...poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id], pacijentPoljePopunjeno?.vrednostEnumeratorItem];
                } else {
                  poljeVrednost[pacijentPoljePopunjeno?.pacijentPolje?.id] = [pacijentPoljePopunjeno?.vrednostEnumeratorItem];
                }
              }
            });
            setPacijentPoljePopunjeno(poljeVrednost);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          skeletonTimeout(setPacijentPoljeListLoading, startTime);
          skeletonTimeout(setPoljePopunjenoLoading, startTime);
        });
  };

  const onChange = (e: PoljeVrednostDto) => {
    const newValue: valueMap = {
      ...pacijentPoljePopunjeno,
      [e.id]: e.vrednost,
    };
    setPacijentPoljePopunjeno(newValue);
  };

  const validateInput = () => {
    let validInput: boolean = true;
    pacijentPoljeList.forEach((pacijentPoljeKategorija: PacijentPoljeKategorijaReadDto) => {
      if (pacijentPoljeKategorija.obavezno) {
        if (
          pacijentPoljePopunjeno === undefined ||
          pacijentPoljePopunjeno[pacijentPoljeKategorija?.pacijentPolje?.id] === undefined ||
          pacijentPoljePopunjeno[pacijentPoljeKategorija?.pacijentPolje?.id] === "" ||
          pacijentPoljePopunjeno[pacijentPoljeKategorija?.pacijentPolje?.id] === null
        ) {
          showMessage(MessageType.ERROR, Labels.PACIJENT_POLJE_OBAVEZNO_JE_UNETI + pacijentPoljeKategorija?.pacijentPolje?.naziv);
          validInput = false;
          return;
        }
      }
    });

    return validInput;
  };

  const onSave = (pacijent: PacijentReadDto) => {
    setShowBlockUI(true);
    if (!validateInput()) {
      setShowBlockUI(false);
      return;
    }
    let pacijentPoljaPopunjenjaUpdate = Object.keys(pacijentPoljePopunjeno)
      .filter((key: string) => {
        let pacijentPoljeKategorijaTemp = pacijentPoljeList.find((pacijentPoljeKategorija: PacijentPoljeKategorijaReadDto) => {
          return pacijentPoljeKategorija?.pacijentPolje?.id === Number(key);
        });
        return pacijentPoljeKategorijaTemp && !pacijentPoljeKategorijaTemp?.readOnly;
      })
      .reduce((obj, key) => {
        return { ...obj, [key]: pacijentPoljePopunjeno[Number(key)] === undefined || pacijentPoljePopunjeno[Number(key)] === "" ? null : pacijentPoljePopunjeno[Number(key)] };
      }, {});
    if (kategorijaEnum === EnumPacijentPoljeKategorija.ADMINISTRATIVNI_PODACI) {
      axiosUpdatePacijentWithPacijentPoljePopunjenoList({ pacijent: pacijent, polja: pacijentPoljaPopunjenjaUpdate }, pacijent?.id, EnumPacijentPoljeKategorija.ADMINISTRATIVNI_PODACI)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.PACIJENT_USPESNO_IZMENJEN);
          fetchMedicinskiPodaciAndInformacijeOPacijentu();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    } else {
      axiosCreatePacijentPoljaPopunjena({ pacijent: { id: pacijent?.id }, polja: pacijentPoljaPopunjenjaUpdate }, pacijent?.id, kategorijaEnum)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.PACIJENT_USPESNO_IZMENJEN);
          fetchMedicinskiPodaciAndInformacijeOPacijentu();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  return { pacijentPoljeList, onChange, pacijentPoljePopunjeno, pacijentPoljeListLoading };
}
