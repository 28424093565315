import { Route, Routes } from "react-router";
import Unsubscribe from "./components/public/Unsubscribe";

export default function PublicContent() {
  return (
    <Routes>
      <Route path="/public/unsubscribe" element={<Unsubscribe />} />
    </Routes>
  );
}
