import moment from "moment";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { RefObject, useContext, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import Images from "../../../../infrastructure/system/Images";
import { DATE_FORMAT, DATE_TIME_FORMAT_2, TIME_FORMAT, checkEmpty } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import ZakazanTerminReadDto from "../../../../model/zakazan-termin/ZakazanTerminReadDto";
import KomentarCreateDto from "../../../../model/zakazan-termin/komentari/KomentarCreateDto";
import KomentarReadDto from "../../../../model/zakazan-termin/komentari/KomentarReadDto";
import { useCreateKomentarForZakazanTermin } from "../hooks/useCreateKomentarForZakazanTermin";

interface ZakazivanjeKomentarSectionProps {
  commentsRef: RefObject<HTMLDivElement>;
  zakazanTermin: ZakazanTerminReadDto | undefined;
  komentarList: KomentarReadDto[];
}

export const ZakazivanjeKomentarSection = ({ commentsRef, zakazanTermin, komentarList }: ZakazivanjeKomentarSectionProps) => {
  const { authData } = useContext(AppContext);
  const Labels = useLabels();
  const createKomentarMutation = useCreateKomentarForZakazanTermin(zakazanTermin?.id);
  const postLogHighLevel = useLogHighLevel();

  const [komentarChange, setKomentarChange] = useState<KomentarCreateDto>();

  const kreirajKomentar = () => {
    if (zakazanTermin?.id && komentarChange) {
      createKomentarMutation.mutate(komentarChange, {
        onSuccess: () => {
          setKomentarChange(undefined);
          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_1 +
              komentarChange?.komentar +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_2 +
              zakazanTermin.kontaktTip?.naziv +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_3 +
              moment(zakazanTermin?.vremeZakazanoOd ?? new Date()).format(DATE_TIME_FORMAT_2) +
              Labels.LOG_HIGH_LEVEL_MESS_UPDATE_DIALOG_TERMIN_COMMENT_4 +
              moment(zakazanTermin?.vremeZakazanoDo ?? new Date()).format(DATE_TIME_FORMAT_2) +
              ".",
            zakazanTermin.pacijent?.id
          );
        },
      });
    }
  };

  return (
    <div>
      <h5 className="mb-4" ref={commentsRef}>
        {Labels.ZAKAZIVANJE_KOMENTARI}
      </h5>
      <div className="termin-comments">
        <div className="flex flex-row justify-content-between">
          <div className="col-11 p-0">
            <InputTextarea
              className="w-full"
              rows={5}
              value={komentarChange?.komentar ?? ""}
              onChange={(e) => {
                setKomentarChange({
                  ...komentarChange!,
                  komentar: e.target.value,
                  komentarPostavio: authData?.currentRadnik?.ime + " " + authData?.currentRadnik?.prezime,
                });
              }}
            />
          </div>
          <div className="col-1 p-0 flex justify-content-end">
            <Button icon="pi pi-send" className="p-button-rounded" disabled={!komentarChange?.komentar.length} onClick={() => kreirajKomentar()} />
          </div>
        </div>
        {komentarList?.map((komentar, index) => (
          <div className="comment-wrapper flex flex-row justify-content-start align-items-start mt-4 col-11" key={index}>
            <div className="comment-icon mr-3">
              {checkEmpty(komentar?.nazivZakazivaca) && <img src={Images.ICON_PROVIDER} alt={"provider icon"} />}
              {!checkEmpty(komentar?.nazivZakazivaca) && <img src={Images.ICON_SCHEDULER} alt={"scheduler icon"} />}
            </div>
            <div>
              <div className="mb-3 font-bold">{komentar.komentarPostavio}</div>
              <div className="mb-3">{komentar.komentar}</div>
              <div className="flex flex-row justify-content-start">
                <span className="mr-3">{moment(komentar.vremePostavljanjaKomentara).format(DATE_FORMAT)}</span>
                <span>{moment(komentar.vremePostavljanjaKomentara).format(TIME_FORMAT)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
