import moment from "moment";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useLabels } from "../../../../../Store";
import Endpoint from "../../../../../infrastructure/system/Endpoint";
import KontrolaPravaPristupaService from "../../../../../infrastructure/system/KontrolaPravaPristupaService";
import { DATE_TIME_FORMAT, getErrorMessage, tooltipOptionsBottom } from "../../../../../infrastructure/system/Utils";
import useFetch from "../../../../../infrastructure/system/hooks/useFetch";
import FormularPopunjenSmallDto from "../../../../../model/formular/FormularPopunjenSmallDto";
import KontaktDijagnozaMkbDto from "../../../../../model/kontakt/KontaktDijagnozaMkbDto";
import KontaktDosijeDetailsDto from "../../../../../model/kontakt/KontaktDosijeDetailsDto";
import KontaktDosijeSimpleDto from "../../../../../model/kontakt/KontaktDosijeSimpleDto";
import KontaktPaketReadDto from "../../../../../model/kontakt/KontaktPaketReadDto";
import KontaktProizvodReadDto from "../../../../../model/kontakt/KontaktProizvodReadDto";
import KontaktUslugaReadDto from "../../../../../model/kontakt/KontaktUslugaReadDto";
import ReceptReadDto from "../../../../../model/kontakt/ReceptReadDto";
import PoljePopunjenoSkracenoDto from "../../../../../model/polje/PoljePopunjenoSkracenoReadDto";
import SkeletonRectangles from "../../../../app/skeleton/SkeletonRectangles";
import KontaktDetailsLogical from "./KontaktDetailsLogical";

interface KontaktPropsType {
  kontakt: KontaktDosijeSimpleDto;
}

export default function Kontakt(props: KontaktPropsType) {
  const { kontakt } = props;
  const Labels = useLabels();
  const navigate = useNavigate();
  const url: string = `${Endpoint.KONTAKT_LIST}/kontakt-search-details`;
  const { data, error } = useFetch<KontaktDosijeDetailsDto>(url, { kontaktID: kontakt.id });
  const { authData } = useContext(AppContext);
  const { hasKontaktDijagnozaView, hasKontaktUslugaView, hasKontaktProizvodView, hasKontaktPaketView, hasFormularPopunjenRead } = KontrolaPravaPristupaService();
  const { receptList } = KontaktDetailsLogical({ kontaktID: kontakt.id });
  const getPoljePopunjenoNameAndValueList = (poljePopunjenoList: Array<PoljePopunjenoSkracenoDto>) => {
    let poljePopunjenoNameAndValueList: Array<String> = [];
    poljePopunjenoList.forEach((poljePopunjeno: PoljePopunjenoSkracenoDto) => {
      if (poljePopunjeno.vrednostString) {
        poljePopunjenoNameAndValueList.push(`${poljePopunjeno.polje.naziv}: ${poljePopunjeno.vrednostString}`);
      } else if (poljePopunjeno.vrednostBoolean !== null) {
        poljePopunjenoNameAndValueList.push(`${poljePopunjeno.polje.naziv}: ${poljePopunjeno.vrednostBoolean ? Labels.YES : Labels.NO}`);
      } else if (poljePopunjeno.vrednostDatetime !== null) {
        poljePopunjenoNameAndValueList.push(`${poljePopunjeno.polje.naziv}: ${moment(poljePopunjeno.vrednostDatetime).format(DATE_TIME_FORMAT)}`);
      } else if (poljePopunjeno.vrednostEnumeratorItem !== null) {
        poljePopunjenoNameAndValueList.push(`${poljePopunjeno.polje.naziv}: ${poljePopunjeno.vrednostEnumeratorItem.naziv}`);
      } else if (poljePopunjeno.vrednostNumber !== null) {
        poljePopunjenoNameAndValueList.push(`${poljePopunjeno.polje.naziv}: ${poljePopunjeno.vrednostNumber}`);
      } else if (poljePopunjeno.vrednostSifarnik !== null) {
        poljePopunjenoNameAndValueList.push(`${poljePopunjeno.polje.naziv}: ${poljePopunjeno.vrednostSifarnik}`);
      } else {
        poljePopunjenoNameAndValueList.push(`${poljePopunjeno.polje.naziv}: `);
      }
    });

    return poljePopunjenoNameAndValueList.join(", ");
  };

  const generateSkeleton = () => {
    return (
      <div>
        <div className="text-center">
          <Button
            label={Labels.PREGLED_NALAZA_PRISTUP_KONTAKTU}
            tooltip={Labels.PREGLED_NALAZA_PRISTUP_KONTAKTU}
            tooltipOptions={tooltipOptionsBottom}
            className="mt-3 text-center kontaktButton orange font-bold p-button-outlined p-button-tertiary"
            icon="pi pi-arrow-right"
            iconPos="right"
            onClick={() => navigate(`/kontakt/${kontakt.id}`)}
          />
        </div>
        <div className="bottom-border pt-3 pb-3">
          <b>{`${Labels.PREGLED_NALAZA_DIJAGNOZA}: `}</b>
          <br />
          <br />
          <SkeletonRectangles />
        </div>
        <div className="bottom-border pt-3 pb-3 display-linebreak">
          <b> {`${Labels.NALAZ}: `}</b>
          <br />
          <br />
          <SkeletonRectangles />
        </div>
        <div className="bottom-border pt-3 pb-3">
          <b>{`${Labels.PREGLED_NALAZA_USLUGA}: `}</b>
          <br />
          <br />
          <SkeletonRectangles />
        </div>
      </div>
    );
  };

  if (error) return <p>{getErrorMessage(error)}</p>;
  if (!data) return generateSkeleton();
  return (
    <div className="col-12">
      <div className="text-center">
        <Button
          label={Labels.PREGLED_NALAZA_PRISTUP_KONTAKTU}
          tooltip={Labels.PREGLED_NALAZA_PRISTUP_KONTAKTU}
          tooltipOptions={tooltipOptionsBottom}
          className="text-center kontaktButton orange font-bold p-button-outlined p-button-tertiary"
          icon="pi pi-arrow-right"
          iconPos="right"
          onClick={() => navigate(`/kontakt/${kontakt.id}`)}
        />
      </div>
      {data?.kontaktDijagnozaList.length > 0 && (
        <div className="bottom-border pt-3 pb-3">
          <b>{`${Labels.PREGLED_NALAZA_DIJAGNOZA}: `}</b>
          {data.kontaktDijagnozaList
            .filter((kontaktDijagnoza: any) => hasKontaktDijagnozaView || kontaktDijagnoza.radnikKreirao.id === authData?.currentRadnik.id)
            .map((kontaktDijagnoza: KontaktDijagnozaMkbDto, index: number) => (
              <div key={index}>{kontaktDijagnoza.sifra + " - " + kontaktDijagnoza.naziv + " - " + Labels.DIJAGNOZA_STATUS + Labels.SPACE + kontaktDijagnoza.dijagnozaStatus.naziv}</div>
            ))}
        </div>
      )}
      {receptList && receptList.length > 0 && (
        <div className="bottom-border pt-3 pb-3">
          <b>{`${Labels.TERAPIJE}: `}</b>
          {receptList
            .filter((recept: ReceptReadDto) => hasKontaktDijagnozaView || recept.radnikKreirao.id === authData?.currentRadnik.id)
            .map((recept: ReceptReadDto, index: number) => (
              <div key={index}>
                {Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_TIP_TERAPIJE}
                {Labels.TWO_DOT}
                {recept?.tipTerapije.naziv}
                <br />
                {Labels.KONTAKT_TERAPIJA_VIEW_DIALOG_LABEL_LEK}
                {Labels.TWO_DOT}
                {recept?.lek.naziv}
              </div>
            ))}
        </div>
      )}
      {data?.kontaktFormularPopunjenList.length > 0 && (
        <div className="bottom-border pt-3 pb-3 display-linebreak">
          <b> {`${Labels.NALAZ}: `}</b>
          {data.kontaktFormularPopunjenList
            .filter((formularPopunjen: any) => hasFormularPopunjenRead || formularPopunjen.radnikKreirao.id === authData?.currentRadnik.id)
            .map((formularPopunjen: FormularPopunjenSmallDto, index: number) => (
              <div key={index}>
                <div>{Labels.FORMULAR_NAME + Labels.TWO_DOT + Labels.SPACE + formularPopunjen.formular.naziv}</div>
                <div>{getPoljePopunjenoNameAndValueList(formularPopunjen.poljePopunjenoList)}</div>
              </div>
            ))}
        </div>
      )}
      {data?.kontaktUslugaList.length > 0 && (
        <div className="bottom-border pt-3 pb-3">
          <b>{`${Labels.PREGLED_NALAZA_USLUGA}: `}</b>
          {data.kontaktUslugaList
            .filter((kontaktUsluga: any) => hasKontaktUslugaView || kontaktUsluga.radnikKreirao.id === authData?.currentRadnik.id)
            .map((kontaktUsluga: KontaktUslugaReadDto, index: number) => (
              <div key={index}>
                {kontaktUsluga.sifarnikUsluga.sifra +
                  Labels.SPACE +
                  kontaktUsluga.sifarnikUsluga.naziv +
                  (kontaktUsluga.brojPruzanja ? Labels.SPACE + Labels.PREGLED_NALAZA_KOLICINA + Labels.TWO_DOT + Labels.SPACE + kontaktUsluga.brojPruzanja : "")}
              </div>
            ))}
        </div>
      )}

      {data?.kontaktProizvodList.length > 0 && (
        <div className="bottom-border pt-3 pb-3">
          <b>{`${Labels.UTROSCI}: `}</b>
          {data.kontaktProizvodList
            .filter((kontaktProizvod: any) => hasKontaktProizvodView || kontaktProizvod.radnikKreirao.id === authData?.currentRadnik.id)
            .map((kontaktProizvod: KontaktProizvodReadDto, index: number) => (
              <div key={index}>
                {kontaktProizvod.sifarnikProizvoda.sifra +
                  Labels.SPACE +
                  kontaktProizvod.sifarnikProizvoda.naziv +
                  (kontaktProizvod.utrosenaKolicina ? Labels.SPACE + Labels.UTROSAK_POTROSENA_KOLICINA.toLocaleLowerCase() + Labels.SPACE + kontaktProizvod.utrosenaKolicina : "")}
              </div>
            ))}
        </div>
      )}
      {data?.kontaktPaketList.length > 0 && (
        <div className="bottom-border pt-3 pb-3">
          <b>{`${Labels.PAKETI}: `}</b>
          {data.kontaktPaketList
            .filter((kontaktPaket: any) => hasKontaktPaketView || kontaktPaket.radnikKreirao.id === authData?.currentRadnik.id)
            .map((kontaktPaket: KontaktPaketReadDto, index: number) => (
              <div key={index}>{kontaktPaket.paket.sifra + Labels.SPACE + kontaktPaket.paket.naziv}</div>
            ))}
        </div>
      )}
    </div>
  );
}
