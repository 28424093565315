import axios from "axios";
import { Dispatch, MutableRefObject, SetStateAction, useContext, useMemo, useRef, useState } from "react";
import { AppContext, useLabels } from "../../../Store";
import EksterniZakazivaciController from "../../../controllers/eksterno-zakazivanje/EksterniZakazivaciController";
import PartnerController from "../../../controllers/partner/PartnerController";
import EnumStatusVezeZakazivaca from "../../../infrastructure/system/EnumStatusVezeZakazivaca";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import UstanovaZakazivacCreateDto from "../../../model/eksterni-zakazivac/UstanovaZakazivacCreateDto";
import ZakazivacReadDto from "../../../model/eksterni-zakazivac/ZakazivacReadDto";
import PartnerSimpleReadDto from "../../../model/partner/PartnerSimpleReadDto";

interface EksterniZakazivaciLocigalType {
  eksterniZakazivacRef: MutableRefObject<any>;
  breadCrumbItems: Array<BreadCrumbItemDto>;
  zakazivaciLoading: boolean;
  eksterniZakazivaci: Array<ZakazivacReadDto>;
  partnerList: Array<PartnerSimpleReadDto>;
  setPartnerForZakazivac: (partnerId: number, zakazivacId: number) => void;
  showActionDialog: boolean;
  setShowActionDialog: Dispatch<SetStateAction<boolean>>;
  openActionDialog: (eksterniZakazivac: ZakazivacReadDto) => void;
  isButtonConnect: (eksterniZakazivac: ZakazivacReadDto) => boolean;
  statusBodyTemplateValues: (eksterniZakazivac: ZakazivacReadDto) => {
    zakazivacStatusClassName: string;
    zakazivacStatusLabel: string;
  };
  isConnectingExternalSchedulerOption: boolean;
  selectedeEksterniZakazivac: ZakazivacReadDto | undefined;
  connectSchedulerAndProvider: () => void;
  disconnectSchedulerAndProvider: () => void;
}

export default function EksterniZakazivaciLogical(): EksterniZakazivaciLocigalType {
  const { showMessage } = useContext(AppContext);
  const [zakazivaciLoading, setZakazivaciLoading] = useState<boolean>(true);
  const [eksterniZakazivaci, setEksterniZakazivaci] = useState<Array<ZakazivacReadDto>>([]);
  const [partnerList, setPartnerList] = useState<Array<PartnerSimpleReadDto>>([]);
  const { axiosGetUstanovaZakazivacList, axiosConnectSchedulerAndProvider, axiosDisconnectSchedulerAndProvider } = EksterniZakazivaciController();
  const { axiosSearchPartneri } = PartnerController();
  const eksterniZakazivacRef = useRef<any>(null);
  const [showActionDialog, setShowActionDialog] = useState<boolean>(false);
  const [selectedeEksterniZakazivac, setSelectedeEksterniZakazivac] = useState<ZakazivacReadDto>();
  const Labels = useLabels();

  const fetchData = () => {
    const getUstanovaZakazivacList = axiosGetUstanovaZakazivacList();
    const getSearchPartnerList = axiosSearchPartneri({});

    axios
      .all([getUstanovaZakazivacList, getSearchPartnerList])
      .then(
        axios.spread((getUstanovaZakazivacListResponse: any, getSearchPartnerListResponse: any) => {
          setEksterniZakazivaci(getUstanovaZakazivacListResponse.data.data);
          setPartnerList(getSearchPartnerListResponse.data.data);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setZakazivaciLoading(false);
      });
  };

  useEffectOnce(() => {
    fetchData();
  });

  const connectSchedulerAndProvider = () => {
    setZakazivaciLoading(true);

    const ustanovaZakazivacCreateDto: UstanovaZakazivacCreateDto = {
      eksterniZakazivacID: selectedeEksterniZakazivac!.scheduler.id,
      partnerID: selectedeEksterniZakazivac!.partner?.id!,
      naziv: selectedeEksterniZakazivac!.scheduler.name,
    };

    axiosConnectSchedulerAndProvider(selectedeEksterniZakazivac!.scheduler.id, ustanovaZakazivacCreateDto)
      .then(() => {
        fetchData();
        showMessage(MessageType.SUCCESS, Labels.LABEL_EKSTERNI_ZAKAZIVACI_USPESNO_POVEZIVANJE);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setZakazivaciLoading(false);
        setShowActionDialog(false);
      });
  };

  const disconnectSchedulerAndProvider = () => {
    setZakazivaciLoading(true);

    axiosDisconnectSchedulerAndProvider(selectedeEksterniZakazivac!.scheduler.id)
      .then(() => {
        fetchData();
        showMessage(MessageType.SUCCESS, Labels.LABEL_EKSTERNI_ZAKAZIVACI_USPESNO_RAZVEZIVANJE);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setZakazivaciLoading(false);
        setShowActionDialog(false);
      });
  };

  const setPartnerForZakazivac = (partnerId: number, zakazivacId: number) => {
    const zakazivacIndex = eksterniZakazivaci.findIndex((zakazivacReadDto: ZakazivacReadDto) => zakazivacReadDto.scheduler.id === zakazivacId);
    const partner = partnerList.find((partner: PartnerSimpleReadDto) => partner.id === partnerId);
    let eksterniZakazivaciTmp = [...eksterniZakazivaci];
    eksterniZakazivaciTmp[zakazivacIndex].partner = partner;
    setEksterniZakazivaci(eksterniZakazivaciTmp);
  };

  const openActionDialog = (eksterniZakazivac: ZakazivacReadDto) => {
    setSelectedeEksterniZakazivac(eksterniZakazivac);
    setShowActionDialog(true);
  };

  const getStatusVeze = (eksterniZakazivac: ZakazivacReadDto) => {
    const schedulerConfirmed: boolean = eksterniZakazivac.schedulerConfirmed;
    const providerConfirmed: boolean = eksterniZakazivac.providerConfirmed;
    let statusVezeZakazivaca = "";

    if (schedulerConfirmed) {
      if (providerConfirmed) statusVezeZakazivaca = EnumStatusVezeZakazivaca.POVEZAN;
      else statusVezeZakazivaca = EnumStatusVezeZakazivaca.CEKA_NA_POTVRDU;
    } else {
      if (providerConfirmed) statusVezeZakazivaca = EnumStatusVezeZakazivaca.ZATRAZENO;
      else statusVezeZakazivaca = EnumStatusVezeZakazivaca.NEPOVEZAN;
    }

    return statusVezeZakazivaca;
  };

  const isConnectingExternalSchedulerOption = useMemo(() => {
    let whichConnectingExternalSchedulerOption: boolean = false;
    if (selectedeEksterniZakazivac) {
      const statusExternalSchedule: string = getStatusVeze(selectedeEksterniZakazivac);
      whichConnectingExternalSchedulerOption = statusExternalSchedule === EnumStatusVezeZakazivaca.CEKA_NA_POTVRDU || statusExternalSchedule === EnumStatusVezeZakazivaca.NEPOVEZAN;
    }
    return whichConnectingExternalSchedulerOption;
  }, [selectedeEksterniZakazivac]);

  const breadCrumbItems: Array<BreadCrumbItemDto> = [
    {
      label: Labels.LABEL_NADJIMED,
    },
    {
      label: Labels.LABEL_NADJIMED_VEZE,
    },
  ];

  const isButtonConnect = (eksterniZakazivac: ZakazivacReadDto) => {
    const statusVeze: string = getStatusVeze(eksterniZakazivac!);
    return statusVeze === EnumStatusVezeZakazivaca.CEKA_NA_POTVRDU || statusVeze === EnumStatusVezeZakazivaca.NEPOVEZAN;
  };

  const getLabel = (labels: any, labelKey: string) => {
    return labels[labelKey];
  };

  const statusBodyTemplateValues = (eksterniZakazivac: ZakazivacReadDto) => {
    const zakazivacStatus: string = getStatusVeze(eksterniZakazivac);
    const zakazivacStatusClassName: string = "status-" + zakazivacStatus.toLowerCase();
    const zakazivacStatusLabelBase: string = "LABEL_EKSTERNI_ZAKAZIVACI_STATUS_VEZE_";
    const zakazivacStatusLabel: string = getLabel(Labels, zakazivacStatusLabelBase + zakazivacStatus);

    return { zakazivacStatusClassName: zakazivacStatusClassName, zakazivacStatusLabel: zakazivacStatusLabel };
  };

  return {
    eksterniZakazivacRef,
    breadCrumbItems,
    zakazivaciLoading,
    eksterniZakazivaci,
    partnerList,
    setPartnerForZakazivac,
    showActionDialog,
    setShowActionDialog,
    openActionDialog,
    isButtonConnect,
    statusBodyTemplateValues,
    isConnectingExternalSchedulerOption,
    selectedeEksterniZakazivac,
    connectSchedulerAndProvider,
    disconnectSchedulerAndProvider,
  };
}
