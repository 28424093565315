import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Panel } from "primereact/panel";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import ObavestenjeDatotekaReadDto from "../../../model/obavestenje/ObavestenjeDatotekaReadDto";

interface ObavestenjeDatotetkaListPreviewProps {
  datotekaList: ObavestenjeDatotekaReadDto[];
  deleteDatoteka?: (id: number) => void;
  disabled?: boolean;
  handleDatotekaClick: (datoteka: ObavestenjeDatotekaReadDto) => void;
}

export default function ObavestenjeDatotekaListPreview({ datotekaList, deleteDatoteka, disabled, handleDatotekaClick }: ObavestenjeDatotetkaListPreviewProps) {
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  return (
    <Panel className="mt-3">
      <div className="w-full">
        {datotekaList.length === 0 && <div>{Labels.LISTA_DATOTEKA_PRAZNA}</div>}
        {datotekaList.map((datoteka: ObavestenjeDatotekaReadDto, index: number) => {
          return (
            <div key={index} className="flex justify-content-between align-items-center bottom-border py-3 border-300">
              <div className="flex cursor-pointer gap-2 text-primary align-items-center m-0" onClick={() => handleDatotekaClick(datoteka)}>
                <div>{datoteka.naziv}</div>
              </div>
              <div className="flex justify-content-end hc-gap-4 align-items-center">
                {deleteDatoteka && (
                  <i
                    onClick={(e) => {
                      if (pristup && !disabled) {
                        confirmPopup({
                          target: e.currentTarget,
                          message: Labels.LABEL_REMOVE_DOCUMENT,
                          acceptLabel: Labels.BUTTON_YES,
                          rejectLabel: Labels.BUTTON_NO,
                          icon: "pi pi-exclamation-triangle",
                          accept: () => {
                            deleteDatoteka(datoteka.id);
                          },
                          reject: () => {},
                        });
                      }
                    }}
                    className="pi pi-times cursor-pointer delete-attachment-icon m-0"
                  ></i>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ConfirmPopup />
    </Panel>
  );
}
