import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import React from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom/client";
import { AuthProvider, AuthService } from "react-oauth2-pkce";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.scss";
import BlockUI from "./BlockUI";
import Store from "./Store";

const authService = new AuthService({
  clientId: process.env.REACT_APP_AUTH_CONFIG_CLIENT_ID || "",
  location: window.location,
  provider: process.env.REACT_APP_API_AUTHORIZATION_SERVER_BASE_URL + "/oauth2" || "",
  redirectUri: process.env.REACT_APP_AUTH_SPA_BASE_URL || window.location.origin,
  logoutEndpoint: process.env.REACT_APP_API_AUTHORIZATION_SERVER_BASE_URL + "/logout" || "",
  scopes: ["openid"],
});

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <AuthProvider authService={authService}>
    <Store>
      <BrowserRouter>
        <BlockUI />
        <App />
      </BrowserRouter>
    </Store>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
