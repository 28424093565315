import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { useLabels } from "../../../../../Store";
import EntityOperation from "../../../../../infrastructure/system/EnumEntityOperation";
import { DATE_FORMAT, tableDateFilterGreater, tableDateFilterLess, tooltipOptionsBottom, tooltipOptionsTop } from "../../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../../infrastructure/system/hooks/PageDropdownTemplate";
import UstanovaBrojNalogaReadDto from "../../../../../model/ustanova/licenca/UstanovaBrojNalogaReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../../app/skeleton/SkeletonTable";
import CalendarFilter from "../../../calendar-component/CalendarFilter";
import UstanovaBrojNalogaListLogical from "./UstanovaBrojNalogaListLogical";

interface UstanovaLicencaProps {
  ustanovaId: number | undefined;
  ustanovaNaziv: string | undefined;
}
export default function UstanovaBrojNalogaListView(props: UstanovaLicencaProps) {
  const { ustanovaId, ustanovaNaziv } = props;
  const {
    ustanovaBrojNalogaList,
    firstBrojNaloga,
    ustanovaBrojNalogaLoading,
    openUstanovaBrojNaloga,
    ustanovaBrojNalogaRows,
    onPageUstanovaBrojNaloga,
    ustanovaBrojNalogaSearchData,
    fetchDataAdmin,
    changeUstanovaBrojNalogaSearchData,
    searchUstanovaBrojNalogaByEnter,
    brojNalogaList,
  } = UstanovaBrojNalogaListLogical({ ustanovaId: ustanovaId, ustanovaNaziv: ustanovaNaziv });

  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const Labels = useLabels();
  const leftToolbar = () => {
    return ustanovaId !== undefined ? (
      <>
        <Button
          label={Labels.BROJ_NALOGA_BUTTON_DODAJ}
          tooltip={Labels.BROJ_NALOGA_TITLE_CREATE}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => {
            openUstanovaBrojNaloga(EntityOperation.CREATE);
          }}
        />
      </>
    ) : (
      <>
        <span className="p-float-label">
          <InputText
            id="searchString"
            name="searchString"
            className="search-input"
            value={ustanovaBrojNalogaSearchData?.searchString ?? ""}
            onChange={changeUstanovaBrojNalogaSearchData}
            onKeyPress={searchUstanovaBrojNalogaByEnter}
          />
          <label htmlFor="searchString">{Labels.SEARCH_BROJ_NALOGA}</label>
          <Button tooltip={Labels.BROJ_NALOGA_SEARCH} icon="pi pi-search" className="search-button" onClick={fetchDataAdmin} />
        </span>
      </>
    );
  };

  const vaziOdBody = (rowUstanovaBrojNaloga: UstanovaBrojNalogaReadDto) => {
    return <div>{rowUstanovaBrojNaloga.vaziOd ? moment(rowUstanovaBrojNaloga.vaziOd).format(DATE_FORMAT) : ""}</div>;
  };

  const vaziDoBody = (rowUstanovaBrojNaloga: UstanovaBrojNalogaReadDto) => {
    return <div>{rowUstanovaBrojNaloga.vaziDo ? moment(rowUstanovaBrojNaloga.vaziDo).format(DATE_FORMAT) : ""}</div>;
  };
  const crudBodyTemplate = (rowUstanovaBrojNaloga: UstanovaBrojNalogaReadDto) => {
    let today = new Date(new Date().setHours(0, 0, 0, 0));
    let vaziDo = rowUstanovaBrojNaloga.vaziDo ? new Date(new Date(rowUstanovaBrojNaloga.vaziDo).setHours(0, 0, 0, 0)) : null;

    if (rowUstanovaBrojNaloga.id === ustanovaBrojNalogaList[0].id && (!vaziDo || vaziDo >= today)) {
      return (
        <div className="flex justify-content-end">
          <Button
            type="button"
            tooltip={Labels.USTANOVA_BROJ_NALOGA_TITLE_UPDATE}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-warning mr-2"
            icon="pi pi-pencil"
            onClick={() => rowUstanovaBrojNaloga && openUstanovaBrojNaloga(EntityOperation.UPDATE, rowUstanovaBrojNaloga.id)}
          />
          <Button
            type="button"
            tooltip={Labels.USTANOVA_BROJ_NALOGA_TITLE_DELETE}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-danger mr-2"
            icon="pi pi-trash"
            onClick={() => rowUstanovaBrojNaloga && openUstanovaBrojNaloga(EntityOperation.DELETE, rowUstanovaBrojNaloga.id)}
          />
        </div>
      );
    } else {
      <></>;
    }
  };

  const statusRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={brojNalogaList}
        optionLabel="naziv"
        optionValue="naziv"
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        className="w-full"
        showClear
      />
    );
  };

  const dateFilterTemplate = (options: any) => <CalendarFilter options={options} />;

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.BROJ_NALOGA, filter: true },
    { columnName: Labels.VAZI_OD, filter: true, sortrable: true },
    { columnName: Labels.VAZI_DO, filter: true, sortrable: true },
  ];
  return (
    <div className="layout-generic-content-list">
      <Toolbar start={leftToolbar} />
      {ustanovaBrojNalogaLoading ? (
        <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList={ustanovaId !== undefined} defaultButtonCount={2} />
      ) : (
        <DataTable
          filterDisplay="row"
          value={ustanovaBrojNalogaList}
          paginator
          first={firstBrojNaloga}
          paginatorTemplate={rowsPerPageDropdownTemplateMedium}
          rows={ustanovaBrojNalogaRows}
          onPage={(e) => {
            onPageUstanovaBrojNaloga(e.rows, e.first);
          }}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          rowHover
          stripedRows
        >
          <Column field={"brojNaloga.naziv"} header={Labels.BROJ_NALOGA} className="w-3" filter showClearButton={false} filterElement={statusRowFilterTemplate} showFilterMenu={false} />
          <Column
            header={Labels.VAZI_OD}
            field={"vaziOd"}
            body={vaziOdBody}
            filter
            filterElement={dateFilterTemplate}
            filterMatchMode="custom"
            filterFunction={tableDateFilterLess}
            sortable
            showFilterMenu={false}
          />
          <Column
            header={Labels.VAZI_DO}
            field={"vaziDo"}
            body={vaziDoBody}
            filter
            filterElement={dateFilterTemplate}
            filterMatchMode="custom"
            filterFunction={tableDateFilterGreater}
            sortable
            showFilterMenu={false}
          />
          {ustanovaId !== undefined && <Column body={crudBodyTemplate} />}
        </DataTable>
      )}
    </div>
  );
}
