import { PickList } from "primereact/picklist";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import RadnikReadDto from "../../../../model/radnik/RadnikReadDto";
import SkeletonPickListItem from "../../../app/skeleton/SkeletonPickListItem";
import CrudRadnikUlogaLogical, { RadnikUlogaHolder } from "./CrudRadnikUlogaLogical";
interface RadnikCrudUlogaPropType {
  isDisabled: boolean;
  radnikOperation: string;
  ustanovaId?: number;
  radnikIme?: string;
  radnikPrezime?: string;
  radnikChange: RadnikReadDto | undefined;
  setRadnikChange: React.Dispatch<React.SetStateAction<RadnikReadDto | undefined>>;
}
export default function CrudRadnikUloga(props: RadnikCrudUlogaPropType) {
  const { isDisabled, radnikOperation, ustanovaId, radnikIme, radnikPrezime, radnikChange, setRadnikChange } = props;
  const { radnikUlogaList, ulogaList, onChangeRadnikUloga, isBlocked, isLoading } = CrudRadnikUlogaLogical({
    radnikOperation: radnikOperation,
    ustanovaId: ustanovaId,
    radnikIme: radnikIme,
    radnikPrezime: radnikPrezime,
    radnikChange: radnikChange,
    setRadnikChange: setRadnikChange,
  });
  const Labels = useLabels();
  const { pristup } = useContext(AppContext);
  const itemTemplate = (item: RadnikUlogaHolder | undefined) => {
    if (item === undefined || item === null || isBlocked) {
      return <SkeletonPickListItem key={0} />;
    }

    return (
      <h6 className="mb-2" key={item.uloga.id}>
        {item.uloga?.naziv}
      </h6>
    );
  };
  return (
    <div className="layout-generic-content-list">
      <div className={!pristup || isDisabled || isBlocked || isLoading ? "read-only" : ""}>
        <PickList
          sourceHeader={Labels.ULOGA_LIST}
          targetHeader={Labels.LABEL_RADNIK_ULOGA_LISTA}
          source={ulogaList}
          target={radnikUlogaList}
          itemTemplate={itemTemplate}
          onChange={onChangeRadnikUloga}
          showSourceControls={false}
          showTargetControls={false}
        ></PickList>
      </div>
    </div>
  );
}
