import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";

interface EnumControllerRepositoryType {
  axiosGetEntityStatusList: () => Promise<AxiosResponse<any>>;
  axiosGetRepositoryKategorijaKontaktaList: () => Promise<AxiosResponse<any>>;
}

export function EnumControllerRepository(): EnumControllerRepositoryType {
  const { authData } = useContext(AppContext);

  const axiosGetEntityStatusList = () => axios.get(Endpoint.ENTITY_STATUS_LIST, axiosConfig(authData!.token));

  const axiosGetRepositoryKategorijaKontaktaList = () => axios.get(Endpoint.REPOSITORY_KATEGORIJA_KONTAKTA_LIST, axiosConfig(authData!.token));

  return { axiosGetEntityStatusList, axiosGetRepositoryKategorijaKontaktaList };
}
