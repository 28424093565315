import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../controllers/enum/EnumController";
import QueryKeys from "../../QueryKeys";

export function useZakazanTerminStatusList() {
  const { axiosGetZakazanTerminStatusList } = EnumController();

  return useQuery({
    queryKey: [QueryKeys.ZAKAZAN_TERMIN_STATUS_LIST],
    queryFn: () => axiosGetZakazanTerminStatusList().then((res) => res.data.data),
  });
}
