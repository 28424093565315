import { useQuery } from "@tanstack/react-query";
import { EnumController } from "../../../../controllers/enum/EnumController";
import QueryKeys from "../../QueryKeys";
import EnumTipObavestenja from "../../../../model/obavestenja-pacijenti/EnumTipObavestenja";

export function useObavestenjeTipList() {
  const { axiosGetObavestenjeTipList } = EnumController();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.OBAVESTENJE_TIP_LIST],
    queryFn: () => axiosGetObavestenjeTipList(),
  });

  //TODO skinuti filter kada se osposobe obavestenja tipa SMS
  const obavestenjeTipList = data?.data?.data.filter((obavestenjeTip) => obavestenjeTip.sifra !== EnumTipObavestenja.SMS) ?? [];

  return { obavestenjeTipList, isLoading };
}
