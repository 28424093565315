import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../../Store";
import { KontaktController } from "../../../../controllers/kontakt/KontaktController";
import { SifarnikUslugaController } from "../../../../controllers/sifarnik-usluga/SifarnikUslugaController";
import { SifarnikController } from "../../../../controllers/sifarnik/SifarnikController";
import { ZakazaniTerminController } from "../../../../controllers/zakazani-termin/ZakazaniTerminController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { RETRY_WITH_RESPONSE_CODE, checkEmpty, handleAxiosCallError, skeletonTimeout, useEffectOnce } from "../../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../../infrastructure/system/hooks/useLogHighLevel";
import KontaktUslugaReadDto from "../../../../model/kontakt/KontaktUslugaReadDto";
import KontaktUslugaSmallCreateDto from "../../../../model/kontakt/KontaktUslugaSmallCreateDto";
import PacijentReadDto from "../../../../model/pacijent/PacijentReadDto";
import EnumBaseReadDto from "../../../../model/sifarnik/EnumBaseReadDto";
import SifarnikUslugaDto from "../../../../model/sifarnik/SifarnikUslugaRFZODto";
import ZakazanTerminUslugaPlannedReadDto from "../../../../model/zakazan-termin/ZakazanTerminUslugaPlannedReadDto";
import ZakazanTerminUslugaReadDto from "../../../../model/zakazan-termin/ZakazanTerminUslugaReadDto";

interface KontaktUslugaLogicalType {
  id: number;
  closeUslugaDialog: () => void;
  isOpenUslugaDialog: boolean;
  onCreateUsluge: (unsafelyExecute?: boolean) => void;
  onUpdateUsluge: (unsafelyExecute?: boolean) => void;
  onDeleteUsluge: () => void;
  sifarnikUslugaList: Array<SifarnikUslugaDto> | undefined;
  kontaktUslugaChange: KontaktUslugaReadDto | undefined;
  setKontaktUslugaChange: any;
  uslugaTipList: Array<EnumBaseReadDto> | undefined;
  dataLoading: boolean;
  setIsOpenUslugaDialog: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenConfirmationDialog: boolean;
  closeConfirmationDialog: () => void;
  changeBrojPruzanjaUsluga: (planiranaUslugaId: number, newValue: number | null) => void;
  transferPlannedServicesIntoRealized: (plannedScheduledTerminService?: ZakazanTerminUslugaPlannedReadDto, unsafelyExecute?: boolean) => Promise<void>;
  removePlannedServices: (plannedScheduledTerminService: ZakazanTerminUslugaPlannedReadDto | undefined) => void;
  uslugaCenaFailedConditionMessage: string[];
  acceptTransferPlannedUslugaIntoRealized: () => void;
}

interface UseParamsType {
  id?: string;
}

export default function KontaktUslugaLogical(
  pacijent: PacijentReadDto,
  kontaktUslugaList: Array<KontaktUslugaReadDto> | undefined,
  setKontaktUslugaList: React.Dispatch<React.SetStateAction<Array<KontaktUslugaReadDto> | undefined>>,
  planiranaUslugaList: ZakazanTerminUslugaPlannedReadDto[],
  setPlaniranaUslugaList: React.Dispatch<React.SetStateAction<ZakazanTerminUslugaPlannedReadDto[]>>,
  sifarnikUslugaList: Array<SifarnikUslugaDto> | undefined,
  setSifarnikUslugaList: React.Dispatch<React.SetStateAction<Array<SifarnikUslugaDto> | undefined>>,
  uslugaTipList: Array<EnumBaseReadDto> | undefined,
  setUslugaTipList: React.Dispatch<React.SetStateAction<Array<EnumBaseReadDto> | undefined>>
): KontaktUslugaLogicalType {
  const Labels = useLabels();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const id = Number(useParams<keyof UseParamsType>()["id"]);
  const { axiosGetUslugaListByKontaktID, axiosUpdateUslugu, axiosDeleteUslugu, axiosCreateUslugu, axiosCreateKontaktUslugaFromZakazanTerminUsluga } = KontaktController();
  const { axiosGetZakazanTerminUslugaList, axiosDeleteZakazanTerminUslugaList } = ZakazaniTerminController();

  const { axiosGetEnumUslugaTipList } = SifarnikController();
  const { axiosGetSifarnikUslugaList } = SifarnikUslugaController();
  const [isOpenUslugaDialog, setIsOpenUslugaDialog] = useState<boolean>(false);
  const [kontaktUslugaChange, setKontaktUslugaChange] = useState<KontaktUslugaReadDto>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState<boolean>(false);
  const [chosenPlannedUslugaToRealize, setChosenPlannedUslugaToRealize] = useState<ZakazanTerminUslugaPlannedReadDto>();
  const [uslugaCenaFailedConditionMessage, setUslugaCenaFailedConditionMessage] = useState<Array<string>>([]);

  const postLogHighLevel = useLogHighLevel();
  useEffectOnce(() => {
    fetchData();
  });

  const fetchUslugaAndZakazanTerminUslugaList = async () => {
    axios
      .all([axiosGetUslugaListByKontaktID(id), axiosGetZakazanTerminUslugaList(id)])
      .then(
        axios.spread((uslugaListByKontaktID: AxiosResponse, zakazanTerminUslugaList: AxiosResponse) => {
          setKontaktUslugaList(uslugaListByKontaktID.data.data);

          setPlaniranaUslugaList(
            zakazanTerminUslugaList.data.data.map((zakazanTerminUsluga: ZakazanTerminUslugaReadDto) => ({
              id: zakazanTerminUsluga.id,
              sifarnikUsluga: zakazanTerminUsluga.sifarnikUsluga,
              brojPruzanja: 1,
            }))
          );
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const fetchData = async () => {
    if (id) {
      if (!kontaktUslugaList) {
        let startTime = moment(new Date());
        setDataLoading(true);
        await fetchUslugaAndZakazanTerminUslugaList().finally(() => skeletonTimeout(setDataLoading, startTime));
      }

      if (!uslugaTipList || !sifarnikUslugaList) {
        axios
          .all([axiosGetEnumUslugaTipList(), axiosGetSifarnikUslugaList()])
          .then(
            axios.spread((responseUslugaTipList: any, responseSifarnikUslugaList: any) => {
              setUslugaTipList(responseUslugaTipList.data.data);
              setSifarnikUslugaList(responseSifarnikUslugaList.data.data);
            })
          )
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
          });
      }
    }
  };

  const closeUslugaDialog = () => {
    setIsOpenUslugaDialog(false);
  };

  const openConfirmationDialog = () => {
    setIsOpenConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setIsOpenConfirmationDialog(false);

    if (uslugaCenaFailedConditionMessage.length > 1) {
      setUslugaCenaFailedConditionMessage([]);
    }
  };

  const validateUsluga = (usluga?: KontaktUslugaReadDto) => {
    if (checkEmpty(usluga?.brojPruzanja)) {
      showMessage(MessageType.ERROR, Labels.USLUGA_BROJ_PRUZANJA_OBAVEZAN);
      return false;
    }
    if (checkEmpty(usluga?.sifarnikUsluga?.id)) {
      showMessage(MessageType.ERROR, Labels.USLUGA_UNOS_OBAVEZAN);
      return false;
    }
    return true;
  };

  const onUpdateUsluge = (unsafelyExecute?: boolean) => {
    if (!validateUsluga(kontaktUslugaChange)) {
      return;
    }
    if (kontaktUslugaChange) {
      setShowBlockUI(true);
      axiosUpdateUslugu(id, kontaktUslugaChange, unsafelyExecute)
        .then((response) => {
          showMessage(MessageType.SUCCESS, Labels.USLUGA_USPESNO_IZMENJENA);
          setKontaktUslugaList(kontaktUslugaList?.map((u: KontaktUslugaReadDto) => (u.id === kontaktUslugaChange?.id ? kontaktUslugaChange : u)));
          if (isOpenConfirmationDialog) closeConfirmationDialog();
          closeUslugaDialog();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_UPDATE_KONTAKT_USLUGA_1 + kontaktUslugaChange.sifarnikUsluga.naziv + Labels.LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_USLUGA_1 + kontaktUslugaChange.brojPruzanja + ".",
            pacijent.id
          );
        })
        .catch((error) => {
          if (error.response?.data?.code === RETRY_WITH_RESPONSE_CODE) {
            openConfirmationDialog();
          } else {
            handleAxiosCallError(showMessage, error);
          }
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onCreateUsluge = (unsafelyExecute?: boolean) => {
    if (!validateUsluga(kontaktUslugaChange)) {
      return;
    }
    if (kontaktUslugaChange) {
      setShowBlockUI(true);
      axiosCreateUslugu(id, kontaktUslugaChange, unsafelyExecute)
        .then((response) => {
          showMessage(MessageType.SUCCESS, Labels.USLUGA_USPESNO_SACUVANA);
          kontaktUslugaList && setKontaktUslugaList([...kontaktUslugaList, response.data.data]);
          if (isOpenConfirmationDialog) closeConfirmationDialog();
          closeUslugaDialog();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_CREATE_KONTAKT_USLUGA_1 + kontaktUslugaChange.sifarnikUsluga.naziv + Labels.LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_USLUGA_1 + kontaktUslugaChange.brojPruzanja + ".",
            pacijent.id
          );
        })
        .catch((error) => {
          if (error.response?.data?.code === RETRY_WITH_RESPONSE_CODE) {
            openConfirmationDialog();
          } else {
            handleAxiosCallError(showMessage, error);
          }
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const onDeleteUsluge = () => {
    if (kontaktUslugaChange) {
      setShowBlockUI(true);
      axiosDeleteUslugu(id, kontaktUslugaChange)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.USLUGA_USPESNO_OBRISANA);
          setKontaktUslugaList(kontaktUslugaList?.filter((ku) => ku.id !== kontaktUslugaChange.id));
          closeUslugaDialog();

          postLogHighLevel(
            Labels.LOG_HIGH_LEVEL_MESS_DELETE_KONTAKT_USLUGA_1 + kontaktUslugaChange.sifarnikUsluga.naziv + Labels.LOG_HIGH_LEVEL_MESS_INFO_KONTAKT_USLUGA_1 + kontaktUslugaChange.brojPruzanja + ".",
            pacijent.id
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  const changeBrojPruzanjaUsluga = (planiranaUslugaId: number, newValue: number | null) => {
    if (newValue) {
      let planiranaUslugaListTmp = [...planiranaUslugaList];
      const planiranaUslugaIndex = planiranaUslugaListTmp.findIndex((plUsluga) => plUsluga.id === planiranaUslugaId);
      planiranaUslugaListTmp[planiranaUslugaIndex].brojPruzanja = newValue;
      setPlaniranaUslugaList(planiranaUslugaListTmp);
    }
  };

  const acceptTransferPlannedUslugaIntoRealized = () => {
    if (chosenPlannedUslugaToRealize) {
      transferPlannedServicesIntoRealized(chosenPlannedUslugaToRealize, true);
    } else {
      transferPlannedServicesIntoRealized(undefined, true);
    }
  };

  const transferPlannedServicesIntoRealized = async (plannedScheduledTerminService?: ZakazanTerminUslugaPlannedReadDto, unsafelyExecute?: boolean) => {
    let realizedScheduledTerminService: Array<KontaktUslugaSmallCreateDto> = [];

    if (plannedScheduledTerminService) {
      let oneRealizedScheduledTerminService: KontaktUslugaSmallCreateDto = {
        sifarnikUsluga: { id: plannedScheduledTerminService.sifarnikUsluga.id },
        brojPruzanja: plannedScheduledTerminService.brojPruzanja,
      };
      realizedScheduledTerminService.push(oneRealizedScheduledTerminService);
    } else {
      realizedScheduledTerminService = planiranaUslugaList.map((planiranaUsluga: ZakazanTerminUslugaPlannedReadDto) => {
        return {
          sifarnikUsluga: { id: planiranaUsluga.sifarnikUsluga.id },
          brojPruzanja: planiranaUsluga.brojPruzanja,
        };
      });
    }

    let startTime = moment(new Date());
    if (unsafelyExecute) {
      setDataLoading(true);
    } else {
      setShowBlockUI(true);
    }

    axiosCreateKontaktUslugaFromZakazanTerminUsluga(id, realizedScheduledTerminService, unsafelyExecute)
      .then(() => {
        setChosenPlannedUslugaToRealize(undefined);
        showMessage(
          MessageType.SUCCESS,
          plannedScheduledTerminService ? Labels.PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_POJEDINACNA_REALIZOVANA_USPESNO : Labels.PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_REALIZOVANO_USPESNO
        );
        fetchUslugaAndZakazanTerminUslugaList();
        closeConfirmationDialog();
      })
      .catch((error) => {
        if (error.response?.data?.code === RETRY_WITH_RESPONSE_CODE) {
          setChosenPlannedUslugaToRealize(plannedScheduledTerminService);
          let uslugaDoesNotHAveCenaErrorList = error.response.data.errors.map((e: any) => e.message + Labels.NEW_LINE);
          setUslugaCenaFailedConditionMessage(uslugaDoesNotHAveCenaErrorList);
          openConfirmationDialog();
        } else {
          handleAxiosCallError(showMessage, error);
        }
      })
      .finally(() => {
        if (unsafelyExecute) {
          skeletonTimeout(setDataLoading, startTime);
        } else {
          setShowBlockUI(false);
        }
      });
  };

  const removePlannedServices = (plannedScheduledTerminService?: ZakazanTerminUslugaPlannedReadDto) => {
    let zakazanTerminUslugaIdList: Array<number> = plannedScheduledTerminService
      ? [plannedScheduledTerminService.id]
      : planiranaUslugaList.map((planiranaUsluga: ZakazanTerminUslugaPlannedReadDto) => planiranaUsluga.id);

    let startTime = moment(new Date());
    setDataLoading(true);
    axiosDeleteZakazanTerminUslugaList(id, zakazanTerminUslugaIdList)
      .then(() => {
        showMessage(
          MessageType.SUCCESS,
          plannedScheduledTerminService ? Labels.PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_BRISANJE_POJEDINACNE_USLUGE : Labels.PLANIRANA_USLUGA_LIST_TOKOM_KONTKAKTA_BRISANJE_SVIH_USLUGA
        );
        setPlaniranaUslugaList(
          plannedScheduledTerminService ? planiranaUslugaList.filter((planiranaUsluga: ZakazanTerminUslugaPlannedReadDto) => planiranaUsluga.id !== plannedScheduledTerminService.id) : []
        );
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        skeletonTimeout(setDataLoading, startTime);
      });
  };

  return {
    id,
    closeUslugaDialog,
    isOpenUslugaDialog,
    onCreateUsluge,
    onUpdateUsluge,
    onDeleteUsluge,
    sifarnikUslugaList,
    kontaktUslugaChange,
    setKontaktUslugaChange,
    uslugaTipList,
    dataLoading,
    setIsOpenUslugaDialog,
    isOpenConfirmationDialog,
    closeConfirmationDialog,
    changeBrojPruzanjaUsluga,
    transferPlannedServicesIntoRealized,
    removePlannedServices,
    uslugaCenaFailedConditionMessage,
    acceptTransferPlannedUslugaIntoRealized,
  };
}
