import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useContext, useRef } from "react";
import { AppContext, useLabels } from "../../../../Store";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import { DATE_FORMAT, formatPrice, tableDateFilter, tooltipOptionsBottom, tooltipOptionsTop } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import actionsSifarnikCena from "../../../../infrastructure/system/hooks/dialog-sifarnik-cena-reducer/actionsSifarnikCena";
import ProizvodCenaReadDto from "../../../../model/sifarnik-proizvoda/ProizvodCenaReadDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import CalendarFilter from "../../calendar-component/CalendarFilter";
import CrudSifarnikProizvodaCeneListLogical from "./CrudSifarnikProizvodaCeneListLogical";
import DialogCena from "./DialogCena";

export default function CrudSifarnikProizvodaCeneListView() {
  const { first, tableRows, onPageCeneProizvoda, state, dispatch, onCreate, onUpdate, onDelete, itemId, sifarnikProizvodaCenaList, sifarnikProizvodaCenaLoading } =
    CrudSifarnikProizvodaCeneListLogical();
  const Labels = useLabels();
  const dt = useRef<any>(null);
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();
  const { pristup } = useContext(AppContext);

  const crudBodyTemplate = (rowCena: ProizvodCenaReadDto) => {
    return (
      <div className="flex justify-content-end">
        {(rowCena.vaziDo === null || moment(rowCena.vaziDo).isAfter(new Date(), "days")) && (
          <Button
            type="button"
            tooltip={Labels.SIFARNIK_PROIZVODA_IZMENI_CENU}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-warning mr-2"
            icon="pi pi-pencil"
            onClick={() => rowCena && dispatch({ type: actionsSifarnikCena.OPEN_DIALOG, data: EntityOperation.UPDATE, cena: { ...state.cena!, ...rowCena } })}
          />
        )}
        {(moment(rowCena.vaziOd).isSame(new Date(), "days") || moment(rowCena.vaziOd).isAfter(new Date(), "days")) && (
          <Button
            type="button"
            tooltip={Labels.SIFARNIK_PROIZVODA_OBRISI_CENU}
            tooltipOptions={tooltipOptionsTop}
            className="p-button-danger mr-2"
            icon="pi pi-trash"
            onClick={() => rowCena && dispatch({ type: actionsSifarnikCena.OPEN_DIALOG, data: EntityOperation.DELETE, cena: { ...state.cena!, ...rowCena } })}
            disabled={!pristup}
          />
        )}
      </div>
    );
  };

  const toolbarLeftContent = () => {
    return (
      <>
        <Button
          label={Labels.SIFARNIK_PROIZVODA_DODAJ_CENU}
          tooltip={Labels.SIFARNIK_PROIZVODA_DODAJ_CENU}
          tooltipOptions={tooltipOptionsBottom}
          className="p-button-success mr-3"
          onClick={() => dispatch({ type: actionsSifarnikCena.OPEN_DIALOG, data: EntityOperation.CREATE, cena: { ...state.cena!, proizvod: { id: itemId } } })}
          disabled={!pristup}
        />
      </>
    );
  };

  const renderPoreskaStopa = (cena: ProizvodCenaReadDto) => {
    return <span>{cena.poreskaStopa.procenat + " %"}</span>;
  };

  const renderIznos = (iznos: ProizvodCenaReadDto) => {
    return <span>{formatPrice(iznos.iznos) + " " + iznos.valuta.sifra}</span>;
  };

  const vaziOd = (cena: ProizvodCenaReadDto) => {
    return <div>{moment(cena.vaziOd).format(DATE_FORMAT)}</div>;
  };

  const vaziDo = (cena: ProizvodCenaReadDto) => {
    return cena.vaziDo ? <div>{moment(cena.vaziDo).format(DATE_FORMAT)}</div> : <div></div>;
  };

  const dateFilterTemplate = (options: any) => <CalendarFilter options={options} />;

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: Labels.HEADER_VAZI_OD, filter: true, sortrable: true },
    { columnName: Labels.HEADER_VAZI_DO, filter: true, sortrable: true },
    { columnName: Labels.HEADER_IZNOS, filter: true, sortrable: true },
    { columnName: Labels.HEADER_PORESKA_STOPA, filter: true, sortrable: true },
  ];

  return (
    <div className="layout-generic-content-list">
      <Panel>
        <Toolbar left={toolbarLeftContent} />
        {sifarnikProizvodaCenaLoading ? (
          <SkeletonTable dataTableColumnsProperty={columnsProperty} hasFilterColumns={true} isVisibleButtonList />
        ) : (
          <DataTable
            ref={dt}
            filterDisplay="row"
            value={sifarnikProizvodaCenaList}
            paginator
            first={first}
            paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
            rows={tableRows}
            onPage={(e) => {
              onPageCeneProizvoda(e.rows, e.first);
            }}
            exportFilename={Labels.PAKET_USLUGA_LIST}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            rowHover
            stripedRows
          >
            <Column
              field={"vaziOd"}
              body={vaziOd}
              header={Labels.HEADER_VAZI_OD}
              filter
              showFilterMenu={false}
              filterElement={dateFilterTemplate}
              filterMatchMode="custom"
              filterFunction={tableDateFilter}
              sortable
            />
            <Column
              field={"vaziDo"}
              body={vaziDo}
              header={Labels.HEADER_VAZI_DO}
              filter
              showFilterMenu={false}
              filterElement={dateFilterTemplate}
              filterMatchMode="custom"
              filterFunction={tableDateFilter}
              sortable
            />
            <Column body={renderIznos} header={Labels.HEADER_IZNOS} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column body={renderPoreskaStopa} header={Labels.HEADER_PORESKA_STOPA} filter filterMatchMode="contains" showFilterMenu={false} sortable />
            <Column body={crudBodyTemplate} />
          </DataTable>
        )}
      </Panel>
      <DialogCena state={state} dispatch={dispatch} onCreate={onCreate} onUpdate={onUpdate} onDelete={onDelete} />
    </div>
  );
}
