import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { AppContext, useLabels } from "../../../Store";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import SkeletonInputItem from "../../app/skeleton/SkeletonInputItem";
import CrudPaketLogical from "./CrudPaketLogical";
import PaketOrganizacionaJedinicaListView from "./paket-organizaciona-jedinica/PaketOrganizacionaJedinicaView";
import PaketProizvodkListView from "./paket-proizvod/PaketProizvodkListView";
import PaketUslugaListView from "./paket-usluga/PaketUslugaListView";

export default function CrudPaketUslugaView() {
  const { paketOperation, paketChange, setPaketChange, paketLoading, breadCrumbItems, onCreate, onUpdate, onDelete, onCancel, index, setIndex, isDisabled } = CrudPaketLogical();

  const Labels = useLabels();
  const { pristup } = useContext(AppContext);

  const leftContentsToolbar = () => (
    <>
      {paketOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} disabled={!pristup} tooltip={Labels.TOOLTIP_CREATE_PAKET_USLUGA} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {paketOperation === EntityOperation.UPDATE && (
        <Button label={Labels.BUTTON_UPDATE} tooltip={Labels.TOOLTIP_UPDATE_PAKET_USLUGA} disabled={!pristup || paketLoading} icon="pi pi-pencil" className="p-button-warning" onClick={onUpdate} />
      )}
      {paketOperation === EntityOperation.DELETE && (
        <Button label={Labels.BUTTON_DELETE} tooltip={Labels.TOOLTIP_DELETE_PAKET_USLUGA} disabled={!pristup || paketLoading} icon="pi pi-trash" className="p-button-danger" onClick={onDelete} />
      )}
    </>
  );

  const rightContentsToolbar = () => <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />;

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_OSNOVNI_PODACI}>
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_SIFRA}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!paketLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={paketChange?.sifra ?? ""}
                      onChange={(e) => {
                        setPaketChange({
                          ...paketChange!,
                          sifra: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                  {Labels.LABEL_NAZIV}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!paketLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={paketChange?.naziv ?? ""}
                      onChange={(e) => {
                        setPaketChange({
                          ...paketChange!,
                          naziv: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>

                <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.LABEL_NAPOMENA}</div>
                <div className="col-12 sm:col-8 p-fluid p-0">
                  {!paketLoading ? (
                    <InputText
                      disabled={!pristup || isDisabled}
                      value={paketChange?.napomena ?? ""}
                      onChange={(e) => {
                        setPaketChange({
                          ...paketChange!,
                          napomena: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <SkeletonInputItem />
                  )}
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
          </TabPanel>
          <TabPanel header={Labels.USLUGE} disabled={paketOperation === EntityOperation.CREATE}>
            <PaketUslugaListView />
          </TabPanel>
          <TabPanel header={Labels.UTROSCI} disabled={paketOperation === EntityOperation.CREATE}>
            <PaketProizvodkListView />
          </TabPanel>
          <TabPanel header={Labels.PAKET_ORGANIZACIONA_JEDINICA_TAB} disabled={paketOperation === EntityOperation.CREATE}>
            <PaketOrganizacionaJedinicaListView paket={paketChange} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
