import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useMemo, useRef } from "react";
import { useLabels } from "../../../Store";
import { tooltipOptionsBottom } from "../../../infrastructure/system/Utils";
import ExportDataLogical from "./ExportDataLogical";

interface ExportDataType {
  data: any;
  name: any;
  dt: any;
  disabled?: boolean;
}

export default function ExportData(props: ExportDataType) {
  const { exportPdf, exportXls, exportCsv, exportJson } = ExportDataLogical();
  const { data, name, dt, disabled } = props;
  const Labels = useLabels();
  const menu = useRef<any>(null);
  const items = [
    {
      label: Labels.LABEL_PDF,
      icon: "pi pi-file-pdf",
      command: () => {
        exportPdf(data, name);
      },
    },
    {
      label: Labels.LABEL_CSV,
      icon: "pi pi-file-o",
      command: () => {
        exportCsv(data, dt, name);
      },
    },
    {
      label: Labels.LABEL_XLS,
      icon: "pi pi-file-excel",
      command: () => {
        exportXls(data, name);
      },
    },
    {
      label: Labels.LABEL_JSON,
      icon: "pi pi-copy",
      command: () => {
        exportJson(data, name);
      },
    },
  ];

  const buttonStyles = useMemo(() => {
    return { color: "#f26235" };
  }, []);

  return (
    <div>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <Button
        label={Labels.LABEL_EXPORT}
        disabled={disabled}
        icon="pi pi-file"
        tooltip={Labels.DOWNLOAD_DATA_TABLE}
        tooltipOptions={tooltipOptionsBottom}
        onClick={(event: any) => menu.current.toggle(event)}
        className="p-button-outlined p-button-danger bg-white"
        style={buttonStyles}
        aria-controls="popup_menu"
        aria-haspopup
      />
    </div>
  );
}
