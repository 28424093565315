import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../../../Store";
import Endpoint from "../../../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../../../infrastructure/system/Utils";
import ProtokolObrazacCreateDto from "../../../../model/obrasi-stampe-protokola/ProtokolObrazacCreateDto";
import ProtokolObrazacUpdateDto from "../../../../model/obrasi-stampe-protokola/ProtokolObrazacUpdateDto";

interface ProtokolObrazacControllerType {
  axiosGetObrasci: () => Promise<AxiosResponse<any>>;
  axiosCreateProtokolObrazac: (protokolObrazac: ProtokolObrazacCreateDto) => Promise<AxiosResponse<any>>;
  axiosGetProtokolObrazac: (protokolObrazacId: number) => Promise<AxiosResponse<any>>;
  axiosDeleteProtokolObrazac: (protokolObrazacId: number) => Promise<AxiosResponse<any>>;
  axiosUpdateProtokolObrazac: (protokolObrazac: ProtokolObrazacUpdateDto) => Promise<AxiosResponse<any>>;
}

export function ProtokolObrazacController(): ProtokolObrazacControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetObrasci = () => {
    return axios.get(Endpoint.OBRASCI_STAMPE_PROTOKOLA_LIST, axiosConfig(authData!.token));
  };

  const axiosCreateProtokolObrazac = (protokolObrazac: ProtokolObrazacCreateDto) => {
    return axios.post(Endpoint.OBRASCI_STAMPE_PROTOKOLA_LIST, protokolObrazac, axiosConfig(authData!.token));
  };

  const axiosGetProtokolObrazac = (protokolObrazacId: number) => {
    return axios.get(`${Endpoint.OBRASCI_STAMPE_PROTOKOLA_LIST}/${protokolObrazacId}`, axiosConfig(authData!.token));
  };

  const axiosDeleteProtokolObrazac = (protokolObrazacId: number) => {
    return axios.delete(`${Endpoint.OBRASCI_STAMPE_PROTOKOLA_LIST}/${protokolObrazacId}`, axiosConfig(authData!.token));
  };

  const axiosUpdateProtokolObrazac = (protokolObrazac: ProtokolObrazacUpdateDto) => {
    return axios.put(`${Endpoint.OBRASCI_STAMPE_PROTOKOLA_LIST}/${protokolObrazac.id}`, protokolObrazac, axiosConfig(authData!.token));
  };

  return {
    axiosGetObrasci,
    axiosCreateProtokolObrazac,
    axiosGetProtokolObrazac,
    axiosDeleteProtokolObrazac,
    axiosUpdateProtokolObrazac,
  };
}
