import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { useLabels } from "../../../../Store";
import UstanovaReadDto from "../../../../model/ustanova/UstanovaReadDto";
import UstanovaLicenceLogical from "./UstanovaLicenceLogical";
import UstanovaBrojNalogaListView from "./broj-naloga/UstanovaBrojNalogaListView";
import UstanovaDodatniModulListView from "./dodatni-moduli/UstanovaDodatniModulListView";
import UstanovaStorageLimitListView from "./storage-limit/UstanovaStorageLimitListView";

interface UstanovaLicenceViewProps {
  ustanova?: UstanovaReadDto;
  ustanovaId?: number;
}

export default function UstanovaLicencePage(props: UstanovaLicenceViewProps) {
  const { ustanova, ustanovaId } = props;
  const { index, setIndex } = UstanovaLicenceLogical();
  const Labels = useLabels();

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <TabView
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.BROJ_NALOGA}>
            <UstanovaBrojNalogaListView ustanovaId={ustanovaId} ustanovaNaziv={ustanova?.naziv} />
          </TabPanel>
          <TabPanel header={Labels.DODATNI_MODULI}>
            <UstanovaDodatniModulListView ustanovaId={ustanovaId} ustanovaNaziv={ustanova?.naziv} />
          </TabPanel>
          <TabPanel header={Labels.STORAGE_LIMIT}>
            <UstanovaStorageLimitListView ustanovaId={ustanovaId} ustanovaNaziv={ustanova?.naziv} />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
