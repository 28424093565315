import { useContext, useState } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { SuperadminController } from "../../../../controllers/superadmin/SuperadminController";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";

interface UstanovaNadjimedLogicalType {
  sinhronizujDoktore: () => void;
  sinhronizujOrganizacioneJedinice: () => void;
  sinhronizujCenovnikeUsluga: () => void;
  inicijalizuj: () => void;
  doktoriSyncInProgress: boolean;
  organizacioneJedinceSyncInProgress: boolean;
  cenovnikUslugaSyncInProgress: boolean;
  inicijalizacijaInProgress: boolean;
}

interface UstanovaNadjimedLogicalProps {
  ustanovaId?: number;
}

export default function UstanovaNadjimedLogical(props: UstanovaNadjimedLogicalProps): UstanovaNadjimedLogicalType {
  const { ustanovaId } = props;
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const [doktoriSyncInProgress, setDoktoriSyncInProgress] = useState<boolean>(false);
  const [organizacioneJedinceSyncInProgress, setOrganizacioneJedinceSyncInProgress] = useState<boolean>(false);
  const [cenovnikUslugaSyncInProgress, setCenovnikUslugaSyncInProgress] = useState<boolean>(false);
  const [inicijalizacijaInProgress, setInicijalizacijaInProgress] = useState<boolean>(false);
  const { axiosSuperadminSinhronizujCenovnikeUsluga, axiosSuperadminSinhronizujDoktore, axiosSuperadminSinhronizujOrganizacioneJedinice, axiosSuperadminInicijalizujNadjimed } = SuperadminController();

  const sinhronizujCenovnikeUsluga = () => {
    setCenovnikUslugaSyncInProgress(true);
    axiosSuperadminSinhronizujCenovnikeUsluga(ustanovaId!)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.USTANOVA_NADJIMED_LABEL_CENOVNICI_USLUGA_SUCCESS_MESSAGE);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setTimeout(() => {
          setCenovnikUslugaSyncInProgress(false);
        }, 500);
      });
  };

  const sinhronizujOrganizacioneJedinice = () => {
    setOrganizacioneJedinceSyncInProgress(true);
    axiosSuperadminSinhronizujOrganizacioneJedinice(ustanovaId!)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.UUSTANOVA_NADJIMED_LABEL_ORGANIZACIONE_JEDINICE_SUCCESS_MESSAGE);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setTimeout(() => {
          setOrganizacioneJedinceSyncInProgress(false);
        }, 500);
      });
  };

  const sinhronizujDoktore = () => {
    setDoktoriSyncInProgress(true);
    axiosSuperadminSinhronizujDoktore(ustanovaId!)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.USTANOVA_NADJIMED_LABEL_DOKTORI_SUCCESS_MESSAGE);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setTimeout(() => {
          setDoktoriSyncInProgress(false);
        }, 500);
      });
  };

  const inicijalizuj = () => {
    setInicijalizacijaInProgress(true);
    axiosSuperadminInicijalizujNadjimed(ustanovaId!)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.USTANOVA_NADJIMED_LABEL_INICIJALIZACIJA_SUCCESS_MESSAGE);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setTimeout(() => {
          setInicijalizacijaInProgress(false);
        }, 500);
      });
  };

  return {
    sinhronizujDoktore,
    sinhronizujCenovnikeUsluga,
    sinhronizujOrganizacioneJedinice,
    inicijalizuj,
    doktoriSyncInProgress,
    organizacioneJedinceSyncInProgress,
    cenovnikUslugaSyncInProgress,
    inicijalizacijaInProgress,
  };
}
