import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnBodyOptions } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { Image } from "primereact/image";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { Row } from "primereact/row";
import { useLabels } from "../../../../Store";
import Images from "../../../../infrastructure/system/Images";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../../../infrastructure/system/Utils";
import PageDropdownTemplate from "../../../../infrastructure/system/hooks/PageDropdownTemplate";
import SpajanjePacijenataArhivaDto from "../../../../model/pacijent/SpajanjePacijenataArhivaDto";
import SkeletonTable, { SkeletonTableColumnProperty } from "../../../app/skeleton/SkeletonTable";
import PretragaPacijenata from "../PretragaPacijenata";
import RazdvajanjePacijenataLogical from "./RazdvajanjePacijenataLogical";

const RazdvajanjePacijenataView = () => {
  const Labels = useLabels();
  const { patientSearchValues, setPatientSearchValues, searchPatients, resultsLoading, patientsList, tableRows, onPage, first, setFirst, splitPatients, searchTableVisible } =
    RazdvajanjePacijenataLogical();
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  const columnsProperty: Array<SkeletonTableColumnProperty> = [
    { columnName: "", filter: false, sortrable: false },
    { columnName: Labels.HEADER_FULLNAME, filter: false, sortrable: false },
    { columnName: Labels.LABEL_SPAJANJE_PACIJENATA_DATUM, filter: false, sortrable: false },
    { columnName: Labels.HEADER_FULLNAME, filter: false, sortrable: false },
    { columnName: Labels.LABEL_SPAJANJE_PACIJENATA_DATUM, filter: false, sortrable: false },
    { columnName: Labels.LABEL_SPAJANJE_PACIJENATA_VREME_SPAJANJA, filter: false, sortrable: false },
    { columnName: Labels.LABEL_SPAJANJE_PACIJENATA_DOSIJE, filter: false, sortrable: false },
    { columnName: "", filter: false, sortrable: false },
  ];

  const actionBodyTemplate = (rowData: SpajanjePacijenataArhivaDto) => {
    return <Button icon="pi pi-user" className="p-button-rounded p-button-info p-button-text" onClick={() => window.open(`/pacijent-dosije/${rowData.pacijentNovi.id}`, "_blank")} />;
  };

  const splitButtonBodyTemplate = (rowData: SpajanjePacijenataArhivaDto) => {
    return (
      <Button
        label={Labels.LABEL_SPAJANJE_PACIJENATA_BUTTON_RAZDVOJ}
        className="p-button-info"
        onClick={() => {
          confirmDialog({
            className: "splittingConfirmationDialog",
            acceptLabel: Labels.BUTTON_CONFIRM,
            acceptIcon: "pi pi-check",
            rejectLabel: Labels.BUTTON_CANCEL,
            rejectClassName: "p-button-outlined mr-5",
            rejectIcon: "pi pi-times",
            message: <span className="font-bold">{Labels.LABEL_RAZDVAJANJE_CONFIRMATION_MESSAGE}</span>,
            accept: () => {
              splitPatients(rowData);
            },
          });
        }}
      />
    );
  };

  const stariPacijentfullNameTemplate = (rowData: SpajanjePacijenataArhivaDto) => {
    return `${rowData.pacijentStari.ime} ${rowData.pacijentStari.prezime}`;
  };

  const noviPacijentfullNameTemplate = (rowData: SpajanjePacijenataArhivaDto) => {
    return `${rowData.pacijentNovi.ime} ${rowData.pacijentNovi.prezime}`;
  };

  const vremeRodjenjaNoviBodyTemplate = (rowData: SpajanjePacijenataArhivaDto) => {
    return `${moment(rowData?.pacijentNovi.vremeRodjenja).format(DATE_FORMAT)}`;
  };

  const vremeRodjenjaStariBodyTemplate = (rowData: SpajanjePacijenataArhivaDto) => {
    return `${moment(rowData?.pacijentStari.vremeRodjenja).format(DATE_FORMAT)}`;
  };

  const vremeSpajanjaBodyTemplate = (rowData: SpajanjePacijenataArhivaDto) => {
    return `${moment(rowData?.vremeKreiranja).format(DATE_TIME_FORMAT)}`;
  };

  const trenutniPacijentColumnHeader = () => (
    <>
      <Image src={Images.ICON_CURRENT_PATIENT} width={"15px"} /> {Labels.LABEL_SPAJANJE_PACIJENATA_TRENUTNI_PACIJENT}
    </>
  );

  const stariPacijentColumnHeader = () => (
    <>
      <Image src={Images.ICON_OLD_PATIENT} width={"15px"} /> {Labels.LABEL_SPAJANJE_PACIJENATA_STARI_PACIJENT}
    </>
  );

  let headerGroup = (
    <ColumnGroup>
      <Row>
        <Column />
        <Column header={trenutniPacijentColumnHeader} colSpan={2} />
        <Column header={stariPacijentColumnHeader} colSpan={5} />
      </Row>
      <Row>
        <Column />
        <Column header={Labels.HEADER_FULLNAME} />
        <Column header={Labels.LABEL_SPAJANJE_PACIJENATA_DATUM} />
        <Column header={Labels.HEADER_FULLNAME} />
        <Column header={Labels.LABEL_SPAJANJE_PACIJENATA_DATUM} />
        <Column header={Labels.LABEL_SPAJANJE_PACIJENATA_VREME_SPAJANJA} />
        <Column header={Labels.LABEL_SPAJANJE_PACIJENATA_DOSIJE} />
        <Column />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="flex flex-column justify-content-center align-items-center">
      <div className="w-5 my-5">
        <PretragaPacijenata patient={patientSearchValues} setPatient={setPatientSearchValues} searchPatients={searchPatients} tableRows={tableRows} setFirst={setFirst} isSplitting />
      </div>
      {searchTableVisible && (
        <div className="w-12 xl:w-12">
          {resultsLoading ? (
            <SkeletonTable hasFilterColumns={false} dataTableColumnsProperty={columnsProperty} isVisibleButtonList={true} defaultButtonCount={1} />
          ) : (
            <DataTable
              value={patientsList}
              dataKey="id"
              emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              responsiveLayout="stack"
              paginator
              paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
              first={first}
              rows={tableRows}
              onPage={(e: DataTablePageEvent) => {
                onPage(e.rows, e.first, e.page!);
              }}
              className="spajanje-rezultati-pretrage"
              headerColumnGroup={headerGroup}
            >
              <Column body={(rowData: SpajanjePacijenataArhivaDto, e: ColumnBodyOptions) => `${e.rowIndex + 1}.`} />
              <Column body={noviPacijentfullNameTemplate} header={Labels.HEADER_FULLNAME} />
              <Column body={vremeRodjenjaNoviBodyTemplate} header={Labels.LABEL_SPAJANJE_PACIJENATA_DATUM} />
              <Column body={stariPacijentfullNameTemplate} header={Labels.HEADER_FULLNAME} />
              <Column body={vremeRodjenjaStariBodyTemplate} header={Labels.LABEL_SPAJANJE_PACIJENATA_DATUM} />
              <Column body={vremeSpajanjaBodyTemplate} header={Labels.LABEL_SPAJANJE_PACIJENATA_VREME_SPAJANJA} />
              <Column body={actionBodyTemplate} bodyClassName="p-0" header={Labels.LABEL_SPAJANJE_PACIJENATA_DOSIJE} />
              <Column body={splitButtonBodyTemplate} bodyClassName="p-0" />
            </DataTable>
          )}
        </div>
      )}
    </div>
  );
};

export default RazdvajanjePacijenataView;
