import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { CalendarChangeEvent } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { ChangeEvent, useContext } from "react";
import { AppContext, useLabels } from "../../../../Store";
import { STEP_MINUTE, getDateDivisibleBy5, handleKeyboardTimeInputChange } from "../../../../infrastructure/system/Utils";
import RadnikSkracenoReadDto from "../../../../model/radnik/RadnikSkracenoReadDto";
import UstanovaGridRadniDanReadDto from "../../../../model/ustanova/UstanovaGridRadniDanReadDto";
import ObaveznoPolje from "../../../obavezno-polje/ObaveznoPolje";
import OrganizacioneJediniceNavbar from "../../../zakazivanje-pregleda/organizacione-jedinice-navbar/OrganizacioneJediniceNavbar";
import CalendarComponent from "../../calendar-component/CalendarComponent";
import CrudRadniPeriodLogical from "./CrudRadniPeriodLogical";
const getRadniDani = (radniDaniTmp?: Array<UstanovaGridRadniDanReadDto>) =>
  radniDaniTmp?.map((radniDanTmp: UstanovaGridRadniDanReadDto) => radniDanTmp.radniDan).sort((radniDan1, radniDan2) => radniDan1.id - radniDan2.id);

export default function CrudRadniPeriod() {
  const Labels = useLabels();
  const { nadjimed } = useContext(AppContext);
  const {
    breadCrumbItems,
    index,
    setIndex,
    radnikList,
    setSelectedRadnik,
    selectedRadnik,
    ustanovaGrid,
    trajanjeTerminaList,
    pregledTrajanje,
    setPregledTrajanje,
    radniPeriodChange,
    setRadniPeriodChange,
    onCancel,
    onCreate,
    ustanovaOrgJedCallback,
    radnikListLoading,
    invalidFields,
    setInvalidFields,
    datumOdRef,
    datumDoRef,
    vremeOdRef,
    vremeDoRef,
    pauzaOdRef,
    pauzaDoRef,
  } = CrudRadniPeriodLogical();

  const renderImePrezime = (radnik?: RadnikSkracenoReadDto) => `${radnik?.ime} ${radnik?.prezime}`;

  const leftContentsToolbar = () => (
    <Button label={Labels.BUTTON_CREATE} tooltip={Labels.RADNI_PERIOD_CREATE} tooltipOptions={{ position: "bottom" }} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button" onClick={onCancel} />
    </>
  );

  return (
    <div className="layout-crud-generic-content">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="mb-3" />
        <TabView
          className="mt-3"
          renderActiveOnly={false}
          activeIndex={index}
          onTabChange={(e) => {
            setIndex(e.index);
          }}
        >
          <TabPanel header={Labels.LABEL_RADNI_PERIOD}>
            <OrganizacioneJediniceNavbar ref={ustanovaOrgJedCallback} />
            <div className="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
              <div className="grid align-items-center p-0">
                {radnikList && !radnikListLoading && radnikList.length > 0 && (
                  <>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>
                        {Labels.RADNI_PERIOD_COLUMN_RADNIK}
                        {Labels.SPECIAL_CHAR_REQUIRED}
                      </div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Dropdown
                        value={selectedRadnik}
                        options={radnikList ?? []}
                        optionLabel="ime"
                        itemTemplate={renderImePrezime}
                        valueTemplate={renderImePrezime}
                        onChange={(e: DropdownChangeEvent) => {
                          setSelectedRadnik(e.value);
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      />
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      {Labels.RADNI_PERIOD_COLUMN_DATUM_OD}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <CalendarComponent
                        ref={datumOdRef}
                        showButtonBar={false}
                        name="datumOd"
                        value={radniPeriodChange?.periodPocetak}
                        onChange={(e: CalendarChangeEvent) => {
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            periodPocetak: e.value as Date,
                          });
                          setInvalidFields((prev) => ({ ...prev, periodPocetak: false, invalidPeriod: false }));
                          if (e.target.value) datumOdRef?.current?.hide();
                        }}
                        className={"w-full " + ((invalidFields?.periodPocetak || invalidFields?.invalidPeriod) && "p-invalid")}
                        placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                      />
                      {invalidFields?.periodPocetak && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      {Labels.RADNI_PERIOD_COLUMN_DATUM_DO}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <CalendarComponent
                        ref={datumDoRef}
                        showButtonBar={false}
                        name="datumDo"
                        value={radniPeriodChange?.periodKraj}
                        onChange={(e: CalendarChangeEvent) => {
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            periodKraj: e.value as Date,
                          });
                          setInvalidFields((prev) => ({ ...prev, periodKraj: false, invalidPeriod: false }));
                          if (e.target.value) datumDoRef?.current?.hide();
                        }}
                        className={"w-full " + ((invalidFields?.periodKraj || invalidFields?.invalidPeriod) && "p-invalid")}
                        placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                      />
                      {invalidFields?.periodKraj && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      {invalidFields?.invalidPeriod && <ObaveznoPolje text={Labels.RADNI_PERIOD_POGRESNAN_RADNI_PERIOD} />}
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>
                        {Labels.RADNI_PERIOD_COLUMN_RADNI_DANI}
                        {Labels.SPECIAL_CHAR_REQUIRED}
                      </div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <MultiSelect
                        value={radniPeriodChange?.radniDanList}
                        options={getRadniDani(ustanovaGrid?.ustanovaGridRadniDanList)}
                        optionLabel="naziv"
                        onChange={(e: MultiSelectChangeEvent) => {
                          setRadniPeriodChange({ ...radniPeriodChange!, radniDanList: e.value });
                          if (e.value.length > 0) {
                            setInvalidFields((prev) => ({ ...prev, radniDani: false }));
                          } else {
                            setInvalidFields((prev) => ({ ...prev, radniDani: true }));
                          }
                        }}
                        className={invalidFields?.radniDani && "p-invalid"}
                        placeholder={Labels.PLACEHOLDER_VREME_FORMAT}
                      />
                      {invalidFields?.radniDani && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      {Labels.RADNI_PERIOD_VREME_POCETKA}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <CalendarComponent
                        ref={vremeOdRef}
                        timeOnly
                        showButtonBar={false}
                        name="vremePocetkaRadnogDana"
                        viewDate={getDateDivisibleBy5(radniPeriodChange?.vremeKrajaRadnogDana)}
                        value={radniPeriodChange?.vremePocetkaRadnogDana}
                        onChange={(e: CalendarChangeEvent) => {
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            vremePocetkaRadnogDana: e.value ? getDateDivisibleBy5(e.value as Date) : undefined,
                          });
                          setInvalidFields((prev) => ({ ...prev, vremePocetka: false, invalidVreme: false }));
                        }}
                        onInput={(e) =>
                          handleKeyboardTimeInputChange(
                            e.currentTarget.value,
                            e.currentTarget.name,
                            radniPeriodChange,
                            setRadniPeriodChange,
                            vremeOdRef,
                            radniPeriodChange?.vremeKrajaRadnogDana,
                            undefined,
                            false
                          )
                        }
                        onClearButtonClick={() => {
                          setInvalidFields((prev) => ({ ...prev, vremePocetka: true }));
                        }}
                        stepMinute={STEP_MINUTE}
                        maxDate={radniPeriodChange?.vremeKrajaRadnogDana}
                        icon="pi pi-clock"
                        onTodayButtonClick={() => {
                          let currentDate = getDateDivisibleBy5(new Date());
                          let maxDate = radniPeriodChange?.vremeKrajaRadnogDana;
                          if (maxDate && currentDate > maxDate) {
                            currentDate = maxDate;
                          }
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            vremePocetkaRadnogDana: currentDate,
                          });
                        }}
                        clearButtonClassName="hidden"
                        todayButtonClassName="m-auto text-600"
                        className={"w-full " + ((invalidFields?.vremePocetka || invalidFields?.invalidVreme) && "p-invalid")}
                        placeholder={Labels.PLACEHOLDER_VREME_FORMAT}
                      />
                      {invalidFields?.vremePocetka && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      {Labels.RADNI_PERIOD_VREME_KRAJ}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <CalendarComponent
                        ref={vremeDoRef}
                        timeOnly
                        showButtonBar={false}
                        name="vremeKrajaRadnogDana"
                        viewDate={getDateDivisibleBy5(radniPeriodChange?.vremePocetkaRadnogDana)}
                        value={radniPeriodChange?.vremeKrajaRadnogDana}
                        onChange={(e: CalendarChangeEvent) => {
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            vremeKrajaRadnogDana: e.value ? getDateDivisibleBy5(e.value as Date) : undefined,
                          });
                          setInvalidFields((prev) => ({ ...prev, vremeKraja: false, invalidVreme: false }));
                        }}
                        onInput={(e) =>
                          handleKeyboardTimeInputChange(
                            e.currentTarget.value,
                            e.currentTarget.name,
                            radniPeriodChange,
                            setRadniPeriodChange,
                            vremeDoRef,
                            undefined,
                            radniPeriodChange?.vremePocetkaRadnogDana,
                            false
                          )
                        }
                        onClearButtonClick={() => {
                          setInvalidFields((prev) => ({ ...prev, vremeKraja: true }));
                        }}
                        stepMinute={STEP_MINUTE}
                        minDate={radniPeriodChange?.vremePocetkaRadnogDana}
                        icon="pi pi-clock"
                        onTodayButtonClick={() => {
                          let currentDate = getDateDivisibleBy5(new Date());
                          let minDate = radniPeriodChange?.vremePocetkaRadnogDana;
                          if (minDate && currentDate < minDate) {
                            currentDate = minDate;
                          }
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            vremeKrajaRadnogDana: currentDate,
                          });
                        }}
                        clearButtonClassName="hidden"
                        todayButtonClassName="m-auto text-600"
                        className={"w-full " + ((invalidFields?.vremeKraja || invalidFields?.invalidVreme) && "p-invalid")}
                      />
                      {invalidFields?.vremeKraja && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
                      {invalidFields?.invalidVreme && <ObaveznoPolje text={Labels.RADNI_PERIOD_POGRESNO_RADNO_VREME} />}
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.RADNI_PERIOD_PAUZA_POCETAK}</div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <CalendarComponent
                        ref={pauzaOdRef}
                        timeOnly
                        name="vremePocetkaPauze"
                        value={radniPeriodChange?.vremePocetkaPauze}
                        viewDate={getDateDivisibleBy5(radniPeriodChange?.vremeKrajaPauze ?? radniPeriodChange?.vremePocetkaRadnogDana)}
                        onChange={(e: CalendarChangeEvent) => {
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            vremePocetkaPauze: e.value ? getDateDivisibleBy5(e.value as Date) : undefined,
                          });
                        }}
                        onInput={(e) =>
                          handleKeyboardTimeInputChange(
                            e.currentTarget.value,
                            e.currentTarget.name,
                            radniPeriodChange,
                            setRadniPeriodChange,
                            pauzaOdRef,
                            radniPeriodChange?.vremeKrajaPauze || radniPeriodChange?.vremeKrajaRadnogDana,
                            radniPeriodChange?.vremePocetkaRadnogDana,
                            false
                          )
                        }
                        stepMinute={STEP_MINUTE}
                        maxDate={radniPeriodChange?.vremeKrajaPauze || radniPeriodChange?.vremeKrajaRadnogDana}
                        minDate={radniPeriodChange?.vremePocetkaRadnogDana}
                        disabled={!radniPeriodChange?.vremePocetkaRadnogDana || !radniPeriodChange?.vremeKrajaRadnogDana}
                        onClearButtonClick={() => {
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            vremePocetkaPauze: undefined,
                          });
                        }}
                        icon="pi pi-clock"
                        onTodayButtonClick={() => {
                          let currentDate = getDateDivisibleBy5(new Date());
                          let maxDate = radniPeriodChange?.vremeKrajaPauze || radniPeriodChange?.vremeKrajaRadnogDana;
                          let minDate = radniPeriodChange?.vremePocetkaRadnogDana;
                          if (maxDate && currentDate > maxDate) {
                            currentDate = maxDate;
                          }
                          if (minDate && currentDate < minDate) {
                            currentDate = minDate;
                          }
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            vremePocetkaPauze: currentDate,
                          });
                        }}
                        clearButtonClassName="m-auto text-600"
                        todayButtonClassName="hidden"
                        className={invalidFields?.invalidPauza && "p-invalid"}
                      />
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">{Labels.RADNI_PERIOD_PAUZA_KRAJ}</div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <CalendarComponent
                        ref={pauzaDoRef}
                        timeOnly
                        name="vremeKrajaPauze"
                        viewDate={getDateDivisibleBy5(radniPeriodChange?.vremePocetkaPauze ?? radniPeriodChange?.vremeKrajaRadnogDana)}
                        value={radniPeriodChange?.vremeKrajaPauze}
                        onChange={(e: CalendarChangeEvent) => {
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            vremeKrajaPauze: e.value ? getDateDivisibleBy5(e.value as Date) : undefined,
                          });
                        }}
                        onInput={(e) =>
                          handleKeyboardTimeInputChange(
                            e.currentTarget.value,
                            e.currentTarget.name,
                            radniPeriodChange,
                            setRadniPeriodChange,
                            pauzaDoRef,
                            radniPeriodChange?.vremeKrajaRadnogDana,
                            radniPeriodChange?.vremePocetkaPauze || radniPeriodChange?.vremePocetkaRadnogDana,
                            false
                          )
                        }
                        stepMinute={STEP_MINUTE}
                        minDate={radniPeriodChange?.vremePocetkaPauze || radniPeriodChange?.vremePocetkaRadnogDana}
                        maxDate={radniPeriodChange?.vremeKrajaRadnogDana}
                        disabled={!radniPeriodChange?.vremePocetkaRadnogDana || !radniPeriodChange?.vremeKrajaRadnogDana}
                        onClearButtonClick={() => {
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            vremeKrajaPauze: undefined,
                          });
                        }}
                        icon="pi pi-clock"
                        onTodayButtonClick={() => {
                          let currentDate = getDateDivisibleBy5(new Date());
                          let maxDate = radniPeriodChange?.vremeKrajaRadnogDana;
                          let minDate = radniPeriodChange?.vremePocetkaPauze || radniPeriodChange?.vremePocetkaRadnogDana;
                          if (maxDate && currentDate > maxDate) {
                            currentDate = maxDate;
                          }
                          if (minDate && currentDate < minDate) {
                            currentDate = minDate;
                          }
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            vremeKrajaPauze: currentDate,
                          });
                        }}
                        clearButtonClassName="m-auto text-600"
                        todayButtonClassName="hidden"
                        className={invalidFields?.invalidPauza && "p-invalid"}
                      />
                      {invalidFields?.invalidPauza && <ObaveznoPolje text={Labels.RADNI_PERIOD_POGRESNA_PAUZA} />}
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>
                        {Labels.RADNI_PERIOD_TRAJANJE_PREGLEDA}
                        {Labels.SPECIAL_CHAR_REQUIRED}
                      </div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Dropdown
                        value={pregledTrajanje}
                        options={trajanjeTerminaList}
                        optionLabel="naziv"
                        onChange={(e: DropdownChangeEvent) => {
                          setPregledTrajanje(e.value);
                        }}
                        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                      />
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>{Labels.RADNI_PERIOD_OPIS}</div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <InputText
                        value={radniPeriodChange?.napomena}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setRadniPeriodChange({
                            ...radniPeriodChange!,
                            napomena: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {nadjimed && (
                      <>
                        <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                          <div>{Labels.RADNI_PERIOD_NEDOSTUPAN_ZA_EKSTERNE_ZAKAZIVACE}</div>
                        </div>
                        <div className="col-12 sm:col-8 p-fluid p-0">
                          <Checkbox
                            checked={radniPeriodChange?.nedostupanZaEksterneZakazivace ?? false}
                            onChange={(e) =>
                              setRadniPeriodChange({
                                ...radniPeriodChange!,
                                nedostupanZaEksterneZakazivace: e.checked!,
                              })
                            }
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                {radnikListLoading && (
                  <>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>
                        <Skeleton></Skeleton>
                      </div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <div>
                        <Skeleton></Skeleton>
                      </div>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 sm:col-8 p-fluid p-0">
                      <Skeleton></Skeleton>
                    </div>
                    <div className="col-12 px-0 pb-2 pt-4 sm:py-3 sm:col-4 sm:px-3">
                      <Skeleton></Skeleton>
                    </div>
                  </>
                )}
              </div>
            </div>
            {radnikList && radnikList.length > 0 && <Toolbar left={leftContentsToolbar} right={rightContentsToolbar} />}
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
