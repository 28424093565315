import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, useLabels } from "../../../Store";
import { SifarnikKategorijaPopustaController } from "../../../controllers/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import MessageType from "../../../infrastructure/system/MessageType";
import QueryKeys from "../../../infrastructure/system/QueryKeys";
import { checkEmpty, isFormDisabled, validateStringEmpty } from "../../../infrastructure/system/Utils";
import useLogHighLevel from "../../../infrastructure/system/hooks/useLogHighLevel";
import BreadCrumbItemDto from "../../../model/BreadCrumbItemDto";
import InvalidFieldsType from "../../../model/InvalidFieldsType";
import SifarnikKategorijaPopustaCreateDto from "../../../model/sifarnik/sifarnik-kategorija-popusta/SifarnikKategorijaPopustaCreateDto";
import { useSifarnikKategorijaPopustaItem } from "./hooks/useSifarnikKategorijaPopustaItem";

interface CrudSifarnikKategorijaPopustaLogicalType {
  breadCrumbItems: Array<BreadCrumbItemDto>;
  sifarnikKategorijaPopustaChange: SifarnikKategorijaPopustaCreateDto | undefined;
  setSifarnikKategorijaPopustaChange: React.Dispatch<React.SetStateAction<SifarnikKategorijaPopustaCreateDto | undefined>>;
  isDisabled: boolean;
  sifarnikKategorijaPopustaOperation: string;
  onCreate: UseMutationResult<AxiosResponse, unknown, void, unknown>;
  onUpdate: UseMutationResult<AxiosResponse, unknown, void, unknown>;
  onDelete: UseMutationResult<AxiosResponse, unknown, void, unknown>;
  onCancel: () => void;
  invalidFields: InvalidFieldsType | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<InvalidFieldsType | undefined>>;
  isLoading: boolean;
  validateInput: (sifarnikKategorijaPopusta: SifarnikKategorijaPopustaCreateDto | undefined) => boolean;
}

interface UseParamsType {
  sifarnikKategorijaPopustaId?: string;
}

export default function CrudSifarnikKategorijaPopustaLogical(): CrudSifarnikKategorijaPopustaLogicalType {
  const { showMessage } = useContext(AppContext);
  const Labels = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const [sifarnikKategorijaPopustaChange, setSifarnikKategorijaPopustaChange] = useState<SifarnikKategorijaPopustaCreateDto | undefined>();
  const [sifarnikKategorijaPopustaOperation, setSifarnikKategorijaPopustaOperation] = useState(location.state?.sifarnikKategorijaPopustaOperation || EntityOperation.UPDATE);
  const [isDisabled] = useState(isFormDisabled(sifarnikKategorijaPopustaOperation));
  const sifarnikKategorijaPopustaId = Number(useParams<keyof UseParamsType>()["sifarnikKategorijaPopustaId"]);
  const [invalidFields, setInvalidFields] = useState<InvalidFieldsType | undefined>(undefined);
  const { axiosCreateSifarnikKategorijaPopusta, axiosUpdateSifarnikKategorijaPopusta, axiosDeleteSifarnikKategorijaPopusta } = SifarnikKategorijaPopustaController();
  const { sifarnikKategorijaPopusta, isLoading } = useSifarnikKategorijaPopustaItem(sifarnikKategorijaPopustaId);
  const queryClient = useQueryClient();

  const postLogHighLevel = useLogHighLevel();

  const [breadCrumbItems, setBreadCrumbItems] = useState<Array<BreadCrumbItemDto>>([
    {
      label: Labels.LABEL_SIFARNIK_KATEGORIJE_POPUSTA,
      command: () => {
        navigate("/sifarnik-kategorija-popusta-list/");
      },
    },
  ]);

  useEffect(() => {
    if (sifarnikKategorijaPopusta) {
      setSifarnikKategorijaPopustaChange({ ...sifarnikKategorijaPopusta, valuta: sifarnikKategorijaPopusta.valuta });
      createBreadCrumb();
    } //eslint-disable-next-line
  }, [sifarnikKategorijaPopusta]);

  const createBreadCrumb = (skpID?: number, operation?: string) => {
    const newSifarnikKategorijaPopustaId = skpID || sifarnikKategorijaPopustaId;
    if (newSifarnikKategorijaPopustaId) {
      const operationTmp = operation ?? sifarnikKategorijaPopustaOperation;
      setBreadCrumbItems([
        breadCrumbItems[0],
        {
          label:
            operationTmp === EntityOperation.UPDATE
              ? Labels.SIFARNIK_KATEGORIJA_POPUSTA_TITLE_UPDATE + sifarnikKategorijaPopusta?.naziv
              : Labels.SIFARNIK_KATEGORIJA_POPUSTA_TITLE_DELETE + sifarnikKategorijaPopusta?.naziv,
        },
      ]);
    } else {
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.SIFARNIK_KATEGORIJA_POPUSTA_TITLE_CREATE,
        },
      ]);
      setSifarnikKategorijaPopustaChange(undefined);
    }
  };

  const validateInput = (sifarnikKategorijaPopusta: SifarnikKategorijaPopustaCreateDto | undefined) => {
    let isInvalid = false;
    if (sifarnikKategorijaPopusta === undefined || validateStringEmpty(sifarnikKategorijaPopusta?.naziv)) {
      setInvalidFields((prev) => ({ ...prev, naziv: true }));
      isInvalid = true;
    }
    if (validateStringEmpty(sifarnikKategorijaPopusta?.sifra)) {
      setInvalidFields((prev) => ({ ...prev, sifra: true }));
      isInvalid = true;
    }
    if (!sifarnikKategorijaPopustaChange?.tipKategorijePopusta) {
      setInvalidFields((prev) => ({ ...prev, tipKategorije: true }));
      isInvalid = true;
    }
    if (!sifarnikKategorijaPopustaChange?.namenaKategorijePopusta) {
      setInvalidFields((prev) => ({ ...prev, namenaKategorije: true }));
      isInvalid = true;
    }
    if (checkEmpty(sifarnikKategorijaPopusta?.popust)) {
      setInvalidFields((prev) => ({ ...prev, popust: true }));
      isInvalid = true;
    }

    return !isInvalid;
  };

  const onCreate = useMutation({
    mutationFn: () => axiosCreateSifarnikKategorijaPopusta({ ...sifarnikKategorijaPopustaChange! }),
    onSuccess: () => {
      showMessage(MessageType.SUCCESS, Labels.SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_CREATE_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.SIFARNIK_KATEGORIJA_POPUSTA] });
      setSifarnikKategorijaPopustaOperation(EntityOperation.UPDATE);
      navigate("/sifarnik-kategorija-popusta-list");
      postLogHighLevel(
        Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_KATEGORIJA_POPUSTA_1 +
          sifarnikKategorijaPopustaChange?.naziv +
          Labels.LOG_HIGH_LEVEL_MESS_CREATE_SIFARNIK_KATEGORIJA_POPUSTA_2 +
          sifarnikKategorijaPopustaChange?.sifra
      );
    },
  });

  const onUpdate = useMutation({
    mutationFn: () => axiosUpdateSifarnikKategorijaPopusta({ ...sifarnikKategorijaPopustaChange!, id: sifarnikKategorijaPopustaId }),
    onSuccess: () => {
      showMessage(MessageType.SUCCESS, Labels.SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_UPDATE_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.SIFARNIK_KATEGORIJA_POPUSTA] });
      onCancel();
      postLogHighLevel(
        Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_KATEGORIJA_POPUSTA_1 +
          sifarnikKategorijaPopustaChange?.naziv +
          Labels.LOG_HIGH_LEVEL_MESS_UPDATE_SIFARNIK_KATEGORIJA_POPUSTA_2 +
          sifarnikKategorijaPopustaChange?.sifra
      );
    },
  });

  const onDelete = useMutation({
    mutationFn: () => axiosDeleteSifarnikKategorijaPopusta(sifarnikKategorijaPopustaId),
    onSuccess: () => {
      showMessage(MessageType.SUCCESS, Labels.SIFARNIK_KATEGORIJA_POPUSTA_MESSAGE_DELETE_SUCCESS);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.SIFARNIK_KATEGORIJA_POPUSTA] });
      onCancel();
      postLogHighLevel(
        Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_KATEGORIJA_POPUSTA_1 +
          sifarnikKategorijaPopustaChange?.naziv +
          Labels.LOG_HIGH_LEVEL_MESS_DELETE_SIFARNIK_KATEGORIJA_POPUSTA_2 +
          sifarnikKategorijaPopustaChange?.sifra
      );
    },
  });

  const onCancel = () => {
    if (breadCrumbItems.length > 1) {
      let breadCrumb = breadCrumbItems[breadCrumbItems.length - 2];
      breadCrumb.command && breadCrumb.command();
    } else {
      navigate(`/sifarnik-kategorija-popusta-list`);
    }
  };

  return {
    breadCrumbItems,
    sifarnikKategorijaPopustaChange,
    setSifarnikKategorijaPopustaChange,
    isDisabled,
    sifarnikKategorijaPopustaOperation,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    invalidFields,
    setInvalidFields,
    isLoading,
    validateInput,
  };
}
