import axios from "axios";
import { Panel } from "primereact/panel";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig, handleAxiosCallError } from "../../infrastructure/system/Utils";
import UpozorenjeDto from "../../model/upozorenje/UpozorenjeDto";
import { AppContext } from "../../Store";

export default function Upozorenje() {
  const { upozorenje, setUpozorenje, authData, showMessage } = useContext(AppContext);

  const location = useLocation();

  useEffect(() => {
    if (authData?.token) {
      axios
        .get(Endpoint.UPOZORENJE, axiosConfig(authData.token))
        .then(({ data: { data } }: { data: { data: UpozorenjeDto } }) => {
          setUpozorenje(data);
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!upozorenje?.sadrzaj) {
    return null;
  }

  return (
    <Panel className="mb-2 top-border">
      <span className="col-12 p-0 text-lg font-bold color-upozorenje">{upozorenje.sadrzaj}</span>
    </Panel>
  );
}
